<ng-container [ngSwitch]="strInboundStatus">
  <div class="col-lg-12 content table-responsive">
    <!-- UnProcessed Fax -->
    <ng-container *ngSwitchCase="inboundStatusIds.unprocessedFax">
      <div *ngIf="!isRefresh">
        <table id="tblUnprocessedFax" datatable [dtOptions]="dtInboundOptionsUnProcessedFax"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorting'">Sorting</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <!-- <td
                [matTooltip]="'Expand Notes'"
                class="dt-control"
                (click)="handleTableCellClick($event, data.patientNotes)"
              ></td> -->
              <!-- <td  [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'"   (click)="data.patientNotes?.length > 0 && handleTableCellClickV1($event, data)"   class="table-cell">
                <div class="notes-count-container">
                  <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
              </div>
                <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>

              </td> -->
              <!-- [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" -->
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': ''" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count" [matTooltip]="data.patientNotes?.length || 0">({{
                      data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon [matTooltip]="'Expand Notes'" *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon [matTooltip]="'Collapse Notes'" *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu"
                  [matTooltip]="'Open Options'">more_vert</mat-icon>
                <mat-checkbox
                [disabled]="disableLockCheckbox"
                  *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox
                [disabled]="disableLockCheckbox"
                *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Unlock File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <!-- <button mat-menu-item (click)="openAddNotesPopUp(data.defaultFaxId,data.patientId,data.docId,false)">
                  Add Note
                </button> -->
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, true)" [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data?.isCsrFaxLockedStatus === 2
                    ">
                    Add Call Note
                  </button>
                  <mat-divider></mat-divider>
                  <a [class.disabled]="
                      isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                    " [routerLink]="
                      '/patient_preview_tiff_document/' +
                      data.defaultFaxId +
                      '/' +
                      data.docId
                    " target="_blank" class="preview-link">
                    <button mat-menu-item>Fax Preview</button>
                  </a>
                  <!-- <button mat-menu-item (click)="
                    openNewSetup(
                      data.docId,
                      data.branchId,
                      data.blobName,
                      data.defaultFaxId
                    )
                  ">
                  New Setup
                </button> -->
                  <!-- || !data?.isTIFFConverted  -->
                  <a [routerLink]="
                      '/new_setup/' + data.docId + '/' + selectedNode + '/' + data.inboundShortCode
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>
                      <!-- [disabled]="data.isSorted === true && data.isNewSetupDone === true" -->
                      {{
                      data.isNewSetupDone === false ||
                      data.isNewSetupDone === null
                      ? "New Setup"
                      : "Sorting"
                      }}
                    </button>
                  </a>
                  <button (click)="OpenMapUserModel($event, data)" mat-menu-item
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Map User (Other Faxes)
                  </button>
                  <mat-divider></mat-divider>

                  <!-- !data.isTIFFConverted || -->
                  <!--  -->
                  <div  [matTooltip]="data.docPageCount < 2 ? 'Single Page has not allowed Split.' : (data?.isCsrFaxLockedStatus === 2 ? 'Locked By Another User' : '')">
                    <button  [disabled]="
                    data.docPageCount < 2 ||
                    data?.isCsrFaxLockedStatus === 2
                  " (click)="
                    openSplitDocumentModal(
                      data.docId,
                      data.defaultDocumentId,
                      data.defaultFaxId,
                      data.blobName
                    )
                  " mat-menu-item>
                  Split
                </button>
                  </div>


                  <button mat-menu-item (click)="joinFaxModal(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Join
                  </button>

                  <a [routerLink]="[
                      '/emailprintletter/' +
                        (data.patientId == null ? defaultGuid : data.patientId)
                    ]" target="_blank" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>Email/Print Letter</button>
                  </a>
                  <mat-divider></mat-divider>

                  <button (click)="
                      newSetupMoveTo(
                        data.docId,
                        data.inboundStatusId,
                        inboundStatusIds.pending,
                        false,
                        inboundStatusIds.unprocessedFax
                      )
                    " mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Pending
                  </button>
                  <!-- <mat-divider></mat-divider> -->
                  <button (click)="
                      newSetupMoveTo(
                        data.docId,
                        data.inboundStatusId,
                        inboundStatusIds.faxIssues,
                        false,
                        inboundStatusIds.unprocessedFax
                      )
                    " mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Fax Issue
                  </button>
                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>
                  <button (click)="
                      newSetupMoveTo(
                        data.docId,
                        data.inboundStatusId,
                        inboundStatusIds.missingDocuments,
                        false,
                        inboundStatusIds.unprocessedFax
                      )
                    " mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Missing Documents
                  </button>

                  <button (click)="
                      newSetupMoveTo(
                        data.docId,
                        data.inboundStatusId,
                        inboundStatusIds.recycleBin,
                        false,
                        inboundStatusIds.unprocessedFax
                      )
                    " mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Delete (Recycle bin)
                  </button>
                  <mat-divider></mat-divider>

                  <button (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    " mat-menu-item>
                    View Patient Notes
                  </button>

                  <button (click)="viewPatientLedger(data?.patientId)" [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    " mat-menu-item>
                    View Patient Ledger
                  </button>

                  <a [routerLink]="
                      '/view_document_tiff/' + data.docId + '/' + true
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }" [class.disabled]="
                      isEmpty(data.docId) || data?.isSorted === false
                    ">
                    <button [disabled]="
                        isEmpty(data.docId) || data?.isSorted === false
                      " mat-menu-item>
                      View Document Image
                    </button>
                  </a>
                  <mat-divider></mat-divider>
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === inboundStatusIds.unprocessedFax ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId,inboundStatusIds.unprocessedFax)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.unprocessedFax
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                <span [matTooltip]="data?.tat || '-'">
                  {{ data?.tat || "-" }}
                </span>
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')">
                <!-- <a [class.disabled]="isEmpty(data.blobName)" [routerLink]="'/patient_preview_document/' + data.blobName + '/' + data.defaultFaxId" target="_blank" class="preview-link" >{{ data?.defaultFaxId || '-'}}</a> -->
                <a [matTooltip]="data?.defaultFaxId || '-'" [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')"><span
                  [matTooltip]="data?.documentName || '-'">
                  {{ data?.documentName || "-" }}
                </span>
              </td>

              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')">
                <span [matTooltip]="data.docPageCount || '-'">{{ data.docPageCount || "-" }}</span>

              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadedDate)" class="text-center">
                <span [matTooltip]="data?.uploadedDate">{{ data?.uploadedDate }}</span>

              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModificationTime)" class="text-center">
                <span [matTooltip]="data?.lastModificationTime">{{
                  data?.lastModificationTime }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')">
                <span [matTooltip]="data?.patientName || '-'">{{ data?.patientName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')">
                <span [matTooltip]="data.priPolicyName || '-'">{{ data.priPolicyName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')">
                <span [matTooltip]="data.secPolicyName || '-'">{{ data.secPolicyName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')">
                <span [matTooltip]="data.terPolicyName || '-'">{{ data.terPolicyName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')">
                <span [matTooltip]="data.csrName || '-'">{{ data?.csrName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')">
                <span [matTooltip]="data?.uploadType || '-'">{{ data.uploadType || "-" }}</span>
              </td>
              <td>
                <span
                  [matTooltip]="data.isSorted !== null && data.isSorted !== undefined && data.isSorted !== '' ? data.isSorted ? 'Yes' : 'No' : '-'">
                  {{
                  data.isSorted !== null &&
                  data.isSorted !== undefined &&
                  data.isSorted !== ''
                  ? data.isSorted
                  ? "Yes"
                  : "No"
                  : "-"
                  }}
                </span>



              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')">
                <span [matTooltip]="data?.practice || '-'">{{ data?.practice || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')">
                <span [matTooltip]="data?.lastModifierBy || '-'">{{ data?.lastModifierBy || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')">
                <span [matTooltip]="data?.insuranceGroup || '-'">{{ data?.insuranceGroup || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right">
                <span [matTooltip]="data?.agingsDays || '-'">{{ data.agingsDays || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')">
                <span [matTooltip]="data?.splitFromFaxId || '-'">{{ data?.splitFromFaxId || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')">
                <span [matTooltip]="data?.joinedFaxIds || '-'">{{ data?.joinedFaxIds || "-" }}</span>
              </td>
              <td>
                <mat-checkbox [matTooltip]="data.isStat ? 'Cancel STAT Fax' : 'Add to STAT Fax' " color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"><span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span></mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorting" name="search-sorting" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>

              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Provider" name="search-provider" />
            </th> -->

              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Documents" name="search-documents" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Fax Issues -->
    <ng-container *ngSwitchCase="inboundStatusIds.faxIssues">
      <div *ngIf="!isRefresh">
        <table id="tblFaxIssues" datatable [dtOptions]="dtInboundOptionsFaxIssues"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th>
                <mat-icon> wrap_text</mat-icon>
              </th>
              <th>
                <mat-icon>apps</mat-icon>
              </th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary  Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorting'">Sorting</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu"
                  [matTooltip]="'Open Options'">more_vert</mat-icon>
                <mat-checkbox [disabled]="disableLockCheckbox"
                  *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>

                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    <!-- [disabled]="
                    data.patientId === defaultGuid ||
                    data.patientId === null ||
                    data.patientId === ''
                  " -->
                    Add Note
                  </button>
                  <mat-divider></mat-divider>

                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>

                  <a [class.disabled]="
                      isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                    " [routerLink]="
                      '/patient_preview_tiff_document/' +
                      data.defaultFaxId +
                      '/' +
                      data.docId
                    " target="_blank" class="preview-link">
                    <button mat-menu-item>Fax Preview</button>
                  </a>

                  <a [routerLink]="
                      '/new_setup/' + data.docId + '/' + selectedNode+ '/' + data.inboundShortCode
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>New Setup</button>
                  </a>

                  <button (click)="OpenMapUserModel($event, data)" mat-menu-item
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Map User (Other Faxes)
                  </button>
                  <mat-divider></mat-divider>

                  <div  [matTooltip]="data.docPageCount < 2 ? 'Single Page has not allowed Split.' : (data?.isCsrFaxLockedStatus === 2 ? 'Locked By Another User' : '')">
                    <button  [disabled]="
                    data.docPageCount < 2 ||
                    data?.isCsrFaxLockedStatus === 2
                  " (click)="
                    openSplitDocumentModal(
                      data.docId,
                      data.defaultDocumentId,
                      data.defaultFaxId,
                      data.blobName
                    )
                  " mat-menu-item>
                  Split
                </button>
                  </div>
                  <button mat-menu-item (click)="joinFaxModal(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Join
                  </button>

                  <a [routerLink]="[
                      '/emailprintletter/' +
                        (data.patientId == null ? defaultGuid : data.patientId)
                    ]" target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>Email/Print Letter</button>
                  </a>

                  <mat-divider></mat-divider>
                  <button (click)="
                      newSetupMoveTo(
                        data.docId,
                        data.inboundStatusId,
                        inboundStatusIds.pending,
                        false,
                        inboundStatusIds.faxIssues
                      )
                    " mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Pending
                  </button>
                  <mat-divider></mat-divider>

                  <button mat-menu-item (click)="checkListModel(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                  <button (click)="
                      newSetupMoveTo(
                        data.docId,
                        data.inboundStatusId,
                        inboundStatusIds.missingDocuments,
                        false,
                        inboundStatusIds.faxIssues
                      )
                    " mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Missing Documents
                  </button>

                  <!-- (click)="openOtherFaxModal(data.docId, data.defaultFaxId)" -->

                  <button (click)="
                      newSetupMoveTo(
                        data.docId,
                        data.inboundStatusId,
                        inboundStatusIds.recycleBin,
                        false,
                        inboundStatusIds.faxIssues
                      )
                    " mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Delete (Recycle bin)
                  </button>
                  <mat-divider></mat-divider>
                  <button (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    " mat-menu-item>
                    View Patient Notes
                  </button>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === inboundStatusIds.faxIssues
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId,inboundStatusIds.faxIssues)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.faxIssues
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>

              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>

              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>
              <td>
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"><span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span></mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th>
                <mat-icon> wrap_text</mat-icon>
              </th>
              <th>
                <mat-icon>apps</mat-icon>
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-tat" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-aging" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th>
                <mat-icon>lock</mat-icon>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Confirmation -->
    <ng-container *ngSwitchCase="inboundStatusIds.confirmation">
      <div *ngIf="!isRefresh">
        <table id="tblConfirmation" datatable [dtOptions]="dtInboundOptionsConfirmation"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <!-- <th >Documents</th> -->
              <!-- <th >CSR</th> -->
              <th [matTooltip]="'INS Group'">INS Group</th>
              <th [matTooltip]="'Aging Days'">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu"
                  [matTooltip]="'Open Options'">more_vert</mat-icon>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
                    View Patient Ledger
                  </button>
                  <button mat-menu-item (click)="
                      newSetupMoveTo(
                        data.docId,
                        data.inboundStatusId,
                        inboundStatusIds.confirmation,
                        true,
                        inboundStatusIds.confirmation
                      )
                    " [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Mark Confirmed
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    ">
                    View Patient Notes
                  </button>

                  <button mat-menu-item (click)="checkListModel(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>
                  <!-- MarkConfirmed(data.docId) -->
                  <mat-divider></mat-divider>
                  <a [routerLink]="
                      '/new_setup/' + data.docId + '/' + selectedNode+ '/' + data.inboundShortCode
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>Sorting</button>
                  </a>
                  <mat-divider></mat-divider>
                  <!-- <button
                    [matMenuTriggerFor]="moveTo"
                    mat-menu-item
                    [disabled]="data?.isCsrFaxLockedStatus === 2"
                  >
                    Move To
                  </button> -->
                  <mat-divider></mat-divider>
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <!-- <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button
                      mat-menu-item
                      [disabled]="
                        bin.shortCodeId === inboundStatusIds.confirmation ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      "
                      [value]="bin.id"
                      (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId, inboundStatusIds.confirmation)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.confirmation
                            )
                      "
                    >
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu> -->
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data.tat }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>

              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>

              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>
              <td [matTooltip]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat" (change)="isStatUpdate($event, data)">
                  <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span>
                </mat-checkbox>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Verification -->
    <ng-container *ngSwitchCase="inboundStatusIds.verification">
      <div *ngIf="!isRefresh">
        <table id="tblVerification" datatable [dtOptions]="dtInboundOptionsVerification"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'Verfication Status'">Verfication Status</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'Service Type'">Service Type</th>
              <!-- <th >Documents</th> -->
              <!-- <th >CSR</th> -->
              <th [matTooltip]="'INS Group'">INS Group</th>
              <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu"
                  [matTooltip]="'Open Options'">more_vert</mat-icon>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>

                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    <!-- [disabled]="
                data.patientId === defaultGuid ||
                data.patientId === null ||
                data.patientId === ''
              " -->
                    Add Note
                  </button>
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, true)" [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data?.isCsrFaxLockedStatus === 2
                    ">
                    Add Call Note
                  </button>
                  <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
                    View Patient Ledger
                  </button>
                  <mat-divider></mat-divider>
                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>
                  <!-- ((data.priVerificationStatus === 'New' ||
                data.priVerificationStatus === null) &&
                (data.secVerificationStatus === 'New' ||
                  data.secVerificationStatus === null) &&
                (data.terVerificationStatus === 'New' ||
                  data.terVerificationStatus === null)) -->
                  <button mat-menu-item [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data.priVerificationStatus === 'New' ||
                        data.priVerificationStatus === 'new' ||
                        isEmpty(data.priVerificationStatus)
                    " (click)="viewVerificationVerifyBinById(data)">
                    View Verification
                  </button>
                  <!-- <button mat-menu-item [disabled]="
                    data.patientId === defaultGuid ||
                    data.patientId === null ||
                    data.patientId === ''
                  " (click)="
                    openAddNotesPopUp(
                      data.defaultFaxId,
                      data.patientId,
                      data.docId,
                      true
                    )
                  ">
                  Add Call Note
                </button> -->

                  <!-- !(
                  (data.priVerificationStatus === 'New' ||
                    data.priVerificationStatus === null) &&
                  (data.secVerificationStatus === 'New' ||
                    data.secVerificationStatus === null) &&
                  (data.terVerificationStatus === 'New' ||
                    data.terVerificationStatus === null)
                ) -->
                  <button mat-menu-item (click)="EditPolicy(data)" [disabled]="
                      data.patientId === defaultGuid ||
                      data?.isCsrFaxLockedStatus === 2 ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data.patientId === 'undefined' ||
                      !(
                        data.priVerificationStatus === 'New' ||
                        data.priVerificationStatus === 'new' ||
                        data.priVerificationStatus === null ||
                        data.priVerificationStatus === ''
                      )
                    ">
                    Do Verification
                  </button>
                  <mat-divider></mat-divider>

                  <button mat-menu-item (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    ">
                    View Patient Notes
                  </button>

                  <a [routerLink]="
                      '/view_document_tiff/' + data.docId + '/' + true
                    " target="_blank" class="preview-link" [class.disabled]="
                      isEmpty(data.docId) || data?.isSorted === false
                    ">
                    <button [disabled]="
                        isEmpty(data.docId) || data?.isSorted === false
                      " mat-menu-item>
                      View Document Image
                    </button>
                  </a>

                  <mat-divider></mat-divider>

                  <button mat-menu-item (click)="checkListModel(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>
                  <!-- openPolicyModal(data.patientId, data.policyId, data.docId) -->

                  <!-- <a [routerLink]="'/do_verification/' + data.blobName + '/' + data.defaultFaxId" target="_blank"
                  class="preview-link">
                  <button mat-menu-item>Do Verification</button>
                </a> -->

                  <!-- <a
                  [routerLink]="
                    '/view_document_tiff/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  "
                  target="_blank"
                  class="preview-link"
                  [class.disabled]="isEmpty(data.docId)"
                >
                  <button [disabled]="isEmpty(data.docId)" mat-menu-item>
                    View Document Image V1
                  </button>
                </a> -->

                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                  <mat-divider></mat-divider>
                  <a [routerLink]="
                      '/new_setup/' + data.docId + '/' + selectedNode+ '/' + data.inboundShortCode
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <!-- [ngbTooltip]="data?.isTIFFConverted ? '' : 'Tiff image conversion in progress' " -->
                    <button mat-menu-item>Sorting</button>
                  </a>
                  <mat-divider></mat-divider>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === inboundStatusIds.verification ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId,inboundStatusIds.verification)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.verification
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>

              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate}}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="
                    '/patientCreateEditTab/' + data.patientId + '/' + data.docId
                  " target="_blank" [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.priVerificationStatus || '-')
                " [matTooltip]="data?.priVerificationStatus || '-'">
                <span *ngIf="
                    data?.priVerificationStatus ===
                      lstVerificationSttaus.done ||
                    data?.priVerificationStatus === lstVerificationSttausV1.done
                  ">Done</span>
                <span *ngIf="
                    data?.priVerificationStatus ===
                      lstVerificationSttaus.pending ||
                    data?.priVerificationStatus ===
                      lstVerificationSttausV1.pending
                  ">Pending</span>
                <span *ngIf="
                    data?.priVerificationStatus ===
                      lstVerificationSttaus.unabletoverify ||
                    data?.priVerificationStatus ===
                      lstVerificationSttausV1.unabletoverify
                  ">Unable To Verify</span>
                <span *ngIf="
                    data?.priVerificationStatus === lstVerificationSttaus.new ||
                    data?.priVerificationStatus === lstVerificationSttausV1.new
                  ">New</span>
                <span class="text-red" *ngIf="(data?.priVerificationStatus!==lstVerificationSttaus.new)&&
    (data?.priVerificationStatus!==lstVerificationSttausV1.new) &&
    (data?.priVerificationStatus!==lstVerificationSttaus.unabletoverify)&&
    (data?.priVerificationStatus!==lstVerificationSttausV1.unabletoverify)&&
    (data?.priVerificationStatus!==lstVerificationSttaus.pending)&&
    (data?.priVerificationStatus!==lstVerificationSttausV1.pending)&&
    (data?.priVerificationStatus!==lstVerificationSttaus.done)&&
    (data?.priVerificationStatus!==lstVerificationSttausV1.done)">{{data?.priVerificationStatus}}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.serviceTypeName || '-')"
                [matTooltip]="data?.serviceTypeName || '-'">
                {{ data?.serviceTypeName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <!-- <td >{{ data.defaultDocumentId }}</td> -->
              <!-- <td >{{ data.csrName }}</td> -->
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat" (change)="isStatUpdate($event, data)">
                  <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span>
                </mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>

              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Chart Id"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Verfication Status"
                  name="search-VerficationStatus" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Service Type"
                  name="search-service-type" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Verification Issues -->
    <ng-container *ngSwitchCase="inboundStatusIds.verificationIssues">
      <div *ngIf="!isRefresh">
        <table id="tblVerificationIssues" datatable [dtOptions]="dtInboundOptionsVerificationIssues"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-right">Received</th>
              <th [matTooltip]="'Modified'" class="text-right">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'Verfication Status'">Verfication Status</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <!-- <th >Documents</th> -->
              <!-- <th >CSR</th> -->
              <th [matTooltip]="'INS Group'">INS Group</th>
              <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matTooltip]="'Open Options'"
                  [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>

                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, true)" [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data?.isCsrFaxLockedStatus === 2
                    ">
                    Add Call Note
                  </button>
                  <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
                    View Patient Ledger
                  </button>
                  <button mat-menu-item [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      (data.priVerificationStatus === 'New' ||
                      isEmpty(data.priVerificationStatus)) &&
                        (data.secVerificationStatus === 'New' ||
                        isEmpty(data.secVerificationStatus)) &&
                        (data.terVerificationStatus === 'New' ||
                        isEmpty(data.terVerificationStatus))
                    " (click)="viewVerificationById(data)">
                    View Verification
                  </button>
                  <mat-divider></mat-divider>
                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    ">
                    View Patient Notes
                  </button>
                  <a [routerLink]="
                      '/view_document_tiff/' + data.docId + '/' + true
                    " target="_blank" class="preview-link" [class.disabled]="
                      isEmpty(data.docId) || data?.isSorted === false
                    ">
                    <button [disabled]="
                        isEmpty(data.docId) || data?.isSorted === false
                      " mat-menu-item>
                      View Document Image
                    </button>
                  </a>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="checkListModel(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>

                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                  <mat-divider></mat-divider>
                  <a [routerLink]="
                      '/new_setup/' + data.docId + '/' + selectedNode+ '/' + data.inboundShortCode
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>Sorting</button>
                  </a>
                  <mat-divider></mat-divider>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <!-- (click)="bin.shortCodeId === inboundStatusIds.callBack ? callback(data.docId, bin.shortCodeId) : (bin.shortCodeId ===inboundStatusIds.pending ? AddNotesPopUp(data.defaultFaxId, data.patientId, data.docId, inboundStatusIds.pending) : updateInboundStatus(data.docId, bin.shortCodeId, data.inboundStatusId))"> -->
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId ===
                          inboundStatusIds.verificationIssues ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId,inboundStatusIds.verificationIssues)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.verificationIssues
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>

              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>

              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.priVerificationStatus || '-')
                " [matTooltip]="data?.priVerificationStatus || '-'">
                <span *ngIf="
                    data?.priVerificationStatus ===
                      lstVerificationSttaus.done ||
                    data?.priVerificationStatus === lstVerificationSttausV1.done
                  ">Done</span>
                <span *ngIf="
                    data?.priVerificationStatus ===
                      lstVerificationSttaus.pending ||
                    data?.priVerificationStatus ===
                      lstVerificationSttausV1.pending
                  ">Pending</span>
                <span *ngIf="
                    data?.priVerificationStatus ===
                      lstVerificationSttaus.unabletoverify ||
                    data?.priVerificationStatus ===
                      lstVerificationSttausV1.unabletoverify
                  ">Unable To Verify</span>
                <span *ngIf="
                    data?.priVerificationStatus === lstVerificationSttaus.new ||
                    data?.priVerificationStatus === lstVerificationSttausV1.new
                  ">New</span>
                <span class="text-red" *ngIf="(data?.priVerificationStatus!==lstVerificationSttaus.new)&&
                (data?.priVerificationStatus!==lstVerificationSttausV1.new) &&
                (data?.priVerificationStatus!==lstVerificationSttaus.unabletoverify)&&
                (data?.priVerificationStatus!==lstVerificationSttausV1.unabletoverify)&&
                (data?.priVerificationStatus!==lstVerificationSttaus.pending)&&
                (data?.priVerificationStatus!==lstVerificationSttausV1.pending)&&
                (data?.priVerificationStatus!==lstVerificationSttaus.done)&&
                (data?.priVerificationStatus!==lstVerificationSttausV1.done)">{{data?.priVerificationStatus}}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>
              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <!-- <td >{{ data.defaultDocumentId }}</td> -->
              <!-- <td >{{ data.csrName }}</td> -->
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"> <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span> </mat-checkbox>
                <!-- <mat-checkbox color="primary"  [(ngModel)]="data.isStat" (change)="isStatUpdate($event,data)"></mat-checkbox> -->
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Verfication Status"
                  name="search-VerficationStatus" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Pre-Cert -->
    <ng-container *ngSwitchCase="inboundStatusIds.preCert">
      <div *ngIf="!isRefresh">
        <table id="tblPreCert" datatable [dtOptions]="dtInboundOptionsPreCert"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'Auth Status'">Auth Status</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc CheckList'">Doc CheckList</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'Service Type'">Service Type</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu"
                  [matTooltip]="'Open Options'">more_vert</mat-icon>

                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>

                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, true)" [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data?.isCsrFaxLockedStatus === 2
                    ">
                    Add Call Note
                  </button>
                  <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
                    View Patient Ledger
                  </button>
                  <button mat-menu-item [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      (data.priVerificationStatus === 'New' ||
                      isEmpty(data.priVerificationStatus)) &&
                        (data.secVerificationStatus === 'New' ||
                        isEmpty(data.secVerificationStatus)) &&
                        (data.terVerificationStatus === 'New' ||
                        isEmpty(data.terVerificationStatus))
                    " (click)="viewVerificationById(data)">
                    View Verification
                  </button>
                  <mat-divider></mat-divider>
                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data.authStatusName === 'Yet to Start' ||
                      data.authStatusName === null ||
                      data.authStatusName === ''
                    " (click)="viewprecert(data)">
                    View Pre-Cert
                  </button>
                  <button mat-menu-item [disabled]="
                      data?.isCsrFaxLockedStatus === 2 ||
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      (data.authStatusName !== 'Yet to Start' &&
                        data.authStatusName !== null &&
                        data.authStatusName !== '')
                    " (click)="doprecert(data)">
                    Do Pre-Cert
                  </button>

                  <mat-divider></mat-divider>

                  <button mat-menu-item (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    ">
                    View Patient Notes
                  </button>

                  <a [routerLink]="
                      '/view_document_tiff/' + data.docId + '/' + true
                    " target="_blank" class="preview-link" [class.disabled]="
                      isEmpty(data.docId) || data?.isSorted === false
                    ">
                    <button [disabled]="
                        isEmpty(data.docId) || data?.isSorted === false
                      " mat-menu-item>
                      View Document Image
                    </button>
                  </a>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="checkListModel(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                  <mat-divider></mat-divider>
                  <a [routerLink]="
                      '/new_setup/' + data.docId + '/' + selectedNode+ '/' + data.inboundShortCode
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>Sorting</button>
                  </a>
                  <mat-divider></mat-divider>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === inboundStatusIds.preCert ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId,inboundStatusIds.preCert)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.preCert
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>
              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <!-- <td >
              {{
              data?.uploadedDate
              ? (data?.uploadedDate | date : "MM/dd/yyyy hh:mm a" : "en_US")
              : "-"
              }}
            </td> -->
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.authStatusName || '-')" [matTooltip]="data?.authStatusName || '-'">
                {{ data.authStatusName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.serviceTypeName || '-')"
                [matTooltip]="data?.serviceTypeName || '-'">
                {{ data?.serviceTypeName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <!-- <td >{{ data.defaultDocumentId }}</td> -->
              <!-- <td >{{ data.csrName }}</td> -->
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"> <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span> </mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Auth Status"
                  name="search-AuthStatus" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Provider" name="search-provider" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Service Type"
                  name="search-service-type" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Documents" name="search-documents" />
            </th>
            <th >
              <input matInput class="textbox-border-class" type="text" placeholder="CSR" name="search-csr" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Medicare -->
    <ng-container *ngSwitchCase="inboundStatusIds.medicare">
      <div *ngIf="!isRefresh">
        <table id="tblMedicare" datatable [dtOptions]="dtInboundOptionsMedicare"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'Medicare Status'">Medicare Status</th>
              <th [matTooltip]="'Checklist Id'">Checklist Id</th>
              <th [matTooltip]="'Prepared By'">Prepared By</th>
              <th [matTooltip]="'Prepared Date'" class="text-center">
                Prepared Date
              </th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'Service Type'">Service Type</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <th title="Aging Days" class="text-right">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matTooltip]="'Open Options'"
                  [matMenuTriggerFor]="menu">more_vert</mat-icon>

                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>

                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, true)" [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data?.isCsrFaxLockedStatus === 2
                    ">
                    Add Call Note
                  </button>
                  <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
                    View Patient Ledger
                  </button>
                  <button mat-menu-item [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      (data.priVerificationStatus === 'New' ||
                      isEmpty(data.priVerificationStatus)) &&
                        (data.secVerificationStatus === 'New' ||
                        isEmpty(data.secVerificationStatus)) &&
                        (data.terVerificationStatus === 'New' ||
                        isEmpty(data.terVerificationStatus))
                    " (click)="viewVerificationById(data)">
                    View Verification
                  </button>
                  <mat-divider></mat-divider>
                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>

                  <a [routerLink]="
                      '/do_checklist/' +
                      data.patientId +
                      '/' +
                      '/' +
                      data.medicareCheckListId
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link':
                        data.medicareStatus !== 'New' ||
                        data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>Do Checklist</button>
                  </a>

                  <a [routerLink]="
                      '/view_checklist/' +
                      data.patientId +
                      '/' +
                      true +
                      '/' +
                      data.medicareCheckListId
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link':
                        data.medicareStatus === 'New' ||
                        data?.medicareCheckListId === null
                    }">
                    <button mat-menu-item>View Checklist</button>
                  </a>
                  <mat-divider></mat-divider>

                  <button mat-menu-item (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    ">
                    View Patient Notes
                  </button>

                  <a [routerLink]="
                      '/view_document_tiff/' + data.docId + '/' + true
                    " target="_blank" class="preview-link" [class.disabled]="
                      isEmpty(data.docId) || data?.isSorted === false
                    ">
                    <button [disabled]="
                        isEmpty(data.docId) || data?.isSorted === false
                      " mat-menu-item>
                      View Document Image
                    </button>
                  </a>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="checkListModel(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                  <mat-divider></mat-divider>
                  <a [routerLink]="
                      '/new_setup/' + data.docId + '/' + selectedNode+ '/' + data.inboundShortCode
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>Sorting</button>
                  </a>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === inboundStatusIds.medicare ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId,inboundStatusIds.medicare)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.medicare
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>

              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>

              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.medicareStatus || '-')" [matTooltip]="data?.medicareStatus || '-'">
                {{ data?.medicareStatus || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.checklistId || '-')" [matTooltip]="data?.checklistId || '-'">
                {{ data?.checklistId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.preparedby || '-')" [matTooltip]="data?.preparedby || '-'">
                {{ data?.preparedby || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.prepareddate)
                " [matTooltip]="data?.prepareddate" class="text-center">
                {{ data?.prepareddate }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <!-- <td >{{ data.providerName }}</td> -->
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.serviceTypeName || '-')"
                [matTooltip]="data?.serviceTypeName || '-'">
                {{ data?.serviceTypeName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <!-- <td >{{ data.defaultDocumentId }}</td> -->
              <!-- <td >{{ data.csrName }}</td> -->
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"> <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span> </mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Medicare Status"
                  name="search-MedicareStatus" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CheckList Id"
                  name="search-CheckListId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Prepared By"
                  name="search-PreparedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Prepared Date"
                  name="search-PreparedDate" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorting" name="search-sorting" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Provider" name="search-provider" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Service Type"
                  name="search-service-type" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Disapproved -->
    <ng-container *ngSwitchCase="inboundStatusIds.disapproved">
      <div *ngIf="!isRefresh">
        <table id="tblDisapproved" datatable [dtOptions]="dtInboundOptionsDisapproved"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'Medicare Status'">Medicare Status</th>
              <th [matTooltip]="'Checklist Id'">Checklist Id</th>
              <th [matTooltip]="'Prepared By'">Prepared By</th>
              <th [matTooltip]="'Prepared Date'" class="text-center">
                Prepared Date
              </th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <th [matTooltip]="'Service Type'">Service Type</th>
              <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matTooltip]="'Open Options'"
                  [matMenuTriggerFor]="menu">more_vert</mat-icon>

                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>

                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, true)" [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data?.isCsrFaxLockedStatus === 2
                    ">
                    Add Call Note
                  </button>
                  <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
                    View Patient Ledger
                  </button>
                  <button mat-menu-item [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      (data.priVerificationStatus === 'New' ||
                      isEmpty(data.priVerificationStatus)) &&
                        (data.secVerificationStatus === 'New' ||
                        isEmpty(data.secVerificationStatus)) &&
                        (data.terVerificationStatus === 'New' ||
                        isEmpty(data.terVerificationStatus))
                    " (click)="viewVerificationById(data)">
                    View Verification
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item [disabled]="true">Do Checklist</button>
                  <a [routerLink]="
                      '/view_checklist/' +
                      data.patientId +
                      '/' +
                      true +
                      '/' +
                      data.medicareCheckListId
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link':
                        data?.medicareStatus === null ||
                        data?.medicareCheckListId === null
                    }">
                    <button mat-menu-item>View Checklist</button>
                  </a>
                  <mat-divider></mat-divider>
                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>

                  <button mat-menu-item (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    ">
                    View Patient Notes
                  </button>

                  <a [routerLink]="
                      '/view_document_tiff/' + data.docId + '/' + true
                    " target="_blank" class="preview-link" [class.disabled]="
                      isEmpty(data.docId) || data?.isSorted === false
                    ">
                    <button [disabled]="
                        isEmpty(data.docId) || data?.isSorted === false
                      " mat-menu-item>
                      View Document Image
                    </button>
                  </a>

                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="checkListModel(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === inboundStatusIds.disapproved ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId,inboundStatusIds.disapproved)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.disapproved
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>

              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.medicareStatus || '-')" [matTooltip]="data?.medicareStatus || '-'">
                {{ data.medicareStatus || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.checklistId || '-')" [matTooltip]="data?.checklistId || '-'">
                {{ data?.checklistId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.preparedby || '-')" [matTooltip]="data?.preparedby || '-'">
                {{ data?.preparedby || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.prepareddate)
                " [matTooltip]="data?.prepareddate" class="text-center">
                {{ data?.prepareddate }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>

              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.serviceTypeName || '-')"
                [matTooltip]="data?.serviceTypeName || '-'">
                {{ data?.serviceTypeName || "-" }}
              </td>
              <!-- <td >{{ data.defaultDocumentId }}</td> -->
              <!-- <td >{{ data.csrName }}</td> -->
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"> <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span> </mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Medicare Status"
                  name="search-MedicareStatus" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CheckList Id"
                  name="search-CheckListId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Prepared By"
                  name="search-PreparedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Prepared Date"
                  name="search-PreparedDate" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Provider" name="search-provider" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Service Type"
                  name="search-service-type" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Documents" name="search-documents" />
            </th>-->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Missing Documents -->
    <ng-container *ngSwitchCase="inboundStatusIds.missingDocuments">
      <div *ngIf="!isRefresh">
        <table id="tblMissingDocuments" datatable [dtOptions]="dtInboundOptionsMissingDocuments"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Folder'">Fax Folder</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'Status'">Status</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <th [matTooltip]="'Aging Days'">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matTooltip]="'Open Options'"
                  [matMenuTriggerFor]="menu">more_vert</mat-icon>

                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>

                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, true)" [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data?.isCsrFaxLockedStatus === 2
                    ">
                    Add Call Note
                  </button>
                  <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
                    View Patient Ledger
                  </button>
                  <button mat-menu-item [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      (data.priVerificationStatus === 'New' ||
                      isEmpty(data.priVerificationStatus)) &&
                        (data.secVerificationStatus === 'New' ||
                        isEmpty(data.secVerificationStatus)) &&
                        (data.terVerificationStatus === 'New' ||
                        isEmpty(data.terVerificationStatus))
                    " (click)="viewVerificationById(data)">
                    View Verification
                  </button>

                  <mat-divider></mat-divider>
                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    ">
                    View Patient Notes
                  </button>

                  <a [routerLink]="
                      '/view_document_tiff/' + data.docId + '/' + true
                    " target="_blank" class="preview-link" [class.disabled]="
                      isEmpty(data.docId) || data?.isSorted === false
                    ">
                    <button [disabled]="
                        isEmpty(data.docId) || data?.isSorted === false
                      " mat-menu-item>
                      View Document Image
                    </button>
                  </a>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="checkListModel(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === inboundStatusIds.missingDocuments
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId, inboundStatusIds.missingDocuments)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.missingDocuments
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #emailReminder="matMenu">
                  <button mat-menu-item>Confirmation Fax</button>
                  <button mat-menu-item>Unable to Reach</button>
                  <button mat-menu-item>Pre-Auth Confirmation</button>
                  <button mat-menu-item>Follow Up Delay</button>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.inboundDocPrevStatus || '-')" [matTooltip]="data?.inboundDocPrevStatus || '-'">
                {{ data.inboundDocPrevStatus ? data.inboundDocPrevStatus : "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>
              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.status || '-')" [matTooltip]="data?.status || '-'">
                {{ data.status || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"> <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span> </mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Fax Folder"
                  name="search-faxFolder" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="ChartId" name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Patient"
                  name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Status" name="search-Status" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Provider" name="search-provider" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Documents" name="search-documents" />
            </th>-->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Ready -->
    <ng-container *ngSwitchCase="inboundStatusIds.ready">
      <div *ngIf="!isRefresh">
        <table id="tblReady" datatable [dtOptions]="dtInboundOptionsReady"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'Service Type'">Service Type</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matTooltip]="'Open Options'"
                  [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>

                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, true)" [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data?.isCsrFaxLockedStatus === 2
                    ">
                    Add Call Note
                  </button>
                  <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
                    View Patient Ledger
                  </button>
                  <button mat-menu-item [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      (data.priVerificationStatus === 'New' ||
                      isEmpty(data.priVerificationStatus)) &&
                        (data.secVerificationStatus === 'New' ||
                        isEmpty(data.secVerificationStatus)) &&
                        (data.terVerificationStatus === 'New' ||
                        isEmpty(data.terVerificationStatus))
                    " (click)="viewVerificationById(data)">
                    View Verification
                  </button>
                  <mat-divider></mat-divider>
                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>
                  <button [matMenuTriggerFor]="emailReminder" mat-menu-item
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Email Reminder
                  </button>
                  <mat-divider></mat-divider>

                  <a [routerLink]="
                      '/orders/saleOrder/' + data.patientId + '/' + defaultGuid + '/'+'0'
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>View Allowable</button>
                  </a>

                  <!-- <button mat-menu-item>View Allowable</button> -->
                  <!-- <mat-divider></mat-divider> -->
                  <a [routerLink]="
                      '/settings/calender/' + data.patientId + '/' + data.docId
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }" [class.disabled]="
                      isEmpty(data.patientId) || isEmpty(data.docId)
                    ">
                    <button [disabled]="
                        isEmpty(data.patientId) || isEmpty(data.docId)
                      " mat-menu-item>
                      Schedule
                    </button>
                  </a>
                  <mat-divider></mat-divider>
                  <!-- <button mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">Send Calendly Invite</button> -->

                  <button mat-menu-item (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    ">
                    View Patient Notes
                  </button>
                  <a [routerLink]="
                      '/view_document_tiff/' + data.docId + '/' + true
                    " target="_blank" class="preview-link" [class.disabled]="
                      isEmpty(data.docId) || data?.isSorted === false
                    ">
                    <button [disabled]="
                        isEmpty(data.docId) || data?.isSorted === false
                      " mat-menu-item>
                      View Document Image
                    </button>
                  </a>
                  <a [routerLink]="
                      '/view_checklist/' +
                      data.patientId +
                      '/' +
                      true +
                      '/' +
                      data.medicareCheckListId
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link':
                        data.medicareStatus === 'New' ||
                        data.medicareCheckListId === null
                    }">
                    <button mat-menu-item>View Checklist</button>
                  </a>

                  <mat-divider></mat-divider>

                  <button mat-menu-item (click)="checkListModelIsView(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>

                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                  <mat-divider></mat-divider>
                  <a [routerLink]="
                      '/new_setup/' + data.docId + '/' + selectedNode+ '/' + data.inboundShortCode
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>Sorting</button>
                  </a>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === inboundStatusIds.ready ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId,inboundStatusIds.ready)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.ready
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #emailReminder="matMenu">
                  <a [routerLink]="['/emailprintletter/' + data.patientId+'/Confirmation Fax']" target="_blank">
                    <button mat-menu-item>Confirmation Fax</button>
                  </a>
                  <a [routerLink]="['/emailprintletter/' + data.patientId + '/Unable to Reach']" target="_blank">
                    <button mat-menu-item>Unable to Reach</button>
                  </a>
                  <a [routerLink]="['/emailprintletter/' + data.patientId+ '/Pre-Auth Confirmation']" target="_blank">
                    <button mat-menu-item>Pre-Auth Confirmation</button>
                  </a>
                  <a [routerLink]="['/emailprintletter/' + data.patientId+'/Follow Up Delay']" target="_blank">
                    <button mat-menu-item>Follow Up Delay</button>
                  </a>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>

              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.serviceTypeName || '-')"
                [matTooltip]="data?.serviceTypeName || '-'">
                {{ data?.serviceTypeName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"> <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span> </mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Provider" name="search-provider" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Service Type"
                  name="search-service-type" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Documents" name="search-documents" />
            </th>-->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Scheduled -->
    <ng-container *ngSwitchCase="inboundStatusIds.scheduled">
      <div *ngIf="!isRefresh">
        <table id="tblSchedule" datatable [dtOptions]="dtInboundOptionsScheduled"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'Service Type'">Service Type</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
              <th [matTooltip]="'Scheduled Date'">Scheduled Date</th>
              <th [matTooltip]="'Technician'">Technician</th>
              <th [matTooltip]="'Status'">Status</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matTooltip]="'Open Options'"
                  [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, true)" [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data?.isCsrFaxLockedStatus === 2
                    ">
                    Add Call Note
                  </button>
                  <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
                    View Patient Ledger
                  </button>
                  <button mat-menu-item [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      ((data.priVerificationStatus === 'New' ||
                        data.priVerificationStatus === null) &&
                        (data.secVerificationStatus === 'New' ||
                          data.secVerificationStatus === null) &&
                        (data.terVerificationStatus === 'New' ||
                          data.terVerificationStatus === null))
                    " (click)="viewVerificationById(data)">
                    View Verification
                  </button>
                  <button mat-menu-item [disabled]="
                    data.patientId === defaultGuid ||
                    data.patientId === null ||
                    data.patientId === '' ||
                    data.authStatusName === 'Yet to Start' ||
                    data.authStatusName === null ||
                    data.authStatusName === ''
                  " (click)="viewprecertV1(data)">
                    View Pre-Cert
                  </button>
                  <a [routerLink]="
                  '/view_checklist/' +
                  data.patientId +
                  '/' +
                  true +
                  '/' +
                  data.medicareCheckListId
                " target="_blank" class="preview-link" [ngClass]="{
                  'disabled-link':
                    data.medicareStatus === 'New' ||
                    data.medicareCheckListId === null
                }">
                    <button mat-menu-item>View Checklist</button>
                  </a>
                  <mat-divider></mat-divider>

                  <!-- <mat-divider></mat-divider> -->

                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>
                  <a [routerLink]="['/emailprintletter/' + data.patientId]" target="_blank" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>Resend Email Reminder</button>
                  </a>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    ">
                    View Patient Notes
                  </button>

                  <a [routerLink]="
                      '/view_document_tiff/' + data.docId + '/' + true
                    " target="_blank" class="preview-link" [class.disabled]="
                      isEmpty(data.docId) || data?.isSorted === false
                    ">
                    <button [disabled]="
                        isEmpty(data.docId) || data?.isSorted === false
                      " mat-menu-item>
                      View Document Image
                    </button>
                  </a>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="checkListModelIsView(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                  <mat-divider></mat-divider>
                  <a [routerLink]="
                      '/new_setup/' + data.docId + '/' + selectedNode+ '/' + data.inboundShortCode
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>Sorting</button>
                  </a>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === inboundStatusIds.scheduled ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId,inboundStatusIds.scheduled)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.scheduled
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>
              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.serviceTypeName || '-')"
                [matTooltip]="data?.serviceTypeName || '-'">
                {{ data?.serviceTypeName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <!-- <td >{{ data.defaultDocumentId }}</td> -->
              <!-- <td >{{ data.csrName }}</td> -->
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.scheduledOn)
                " [matTooltip]="data?.scheduledOn" class="text-center">
                {{ data?.scheduledOn }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.scheduledToName || '-')"
                [matTooltip]="data?.scheduledToName || '-'">
                {{ data.scheduledToName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.scheduleStatus || '-')" [matTooltip]="data?.scheduleStatus || '-'">
                {{ data.scheduleStatus || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"> <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span> </mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Provider" name="search-provider" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Service Type"
                  name="search-service-type" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Documents" name="search-documents" />
            </th>-->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Scheduled Date"
                  name="search-ScheduledDate" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Technician"
                  name="search-Technician" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Status" name="search-Status" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Completed -->
    <ng-container *ngSwitchCase="inboundStatusIds.completed">
      <div *ngIf="!isRefresh">
        <table id="tblCompleted" datatable [dtOptions]="dtInboundOptionsCompleted"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <!-- <th >Documents</th>
            <th >CSR</th> -->
              <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matTooltip]="'Open Options'"
                  [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, true)" [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data?.isCsrFaxLockedStatus === 2
                    ">
                    Add Call Note
                  </button>
                  <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
                    View Patient Ledger
                  </button>

                  <mat-divider></mat-divider>
                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>
                  <!-- <button (click)="
                newSetupMoveTo(
                  data.docId,
                  data.inboundStatusId,
                  inboundStatusIds.scheduled,
                  false
                )
              " mat-menu-item>
              Schedule
            </button> -->
                  <a [routerLink]="
                      '/settings/calender/' + data.patientId + '/' + data.docId
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }" [class.disabled]="
                      isEmpty(data.patientId) || isEmpty(data.docId)
                    ">
                    <button [disabled]="
                        isEmpty(data.patientId) || isEmpty(data.docId)
                      " mat-menu-item>
                      Schedule
                    </button>
                  </a>
                  <mat-divider></mat-divider>
                  <button mat-menu-item [disabled]="
                    data.patientId === defaultGuid ||
                    data.patientId === null ||
                    data.patientId === '' ||
                    ((data.priVerificationStatus === 'New' ||
                      data.priVerificationStatus === null) &&
                      (data.secVerificationStatus === 'New' ||
                        data.secVerificationStatus === null) &&
                      (data.terVerificationStatus === 'New' ||
                        data.terVerificationStatus === null))
                  " (click)="viewVerificationById(data)">
                    View Verification
                  </button>
                  <button mat-menu-item [disabled]="
                  data.patientId === defaultGuid ||
                  data.patientId === null ||
                  data.patientId === '' ||
                  data.authStatusName === 'Yet to Start' ||
                  data.authStatusName === null ||
                  data.authStatusName === ''
                " (click)="viewprecertV1(data)">
                    View Pre-Cert
                  </button>
                  <a [routerLink]="
                '/view_checklist/' +
                data.patientId +
                '/' +
                true +
                '/' +
                data.medicareCheckListId
              " target="_blank" class="preview-link" [ngClass]="{
                'disabled-link':
                  data.medicareStatus === 'New' ||
                 isEmpty(data.medicareCheckListId)
              }">
                    <button mat-menu-item>View Checklist</button>
                  </a>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    ">
                    View Patient Notes
                  </button>

                  <a [routerLink]="
                      '/view_document_tiff/' + data.docId + '/' + true
                    " target="_blank" class="preview-link" [class.disabled]="
                      isEmpty(data.docId) || data?.isSorted === false
                    ">
                    <button [disabled]="
                        isEmpty(data.docId) || data?.isSorted === false
                      " mat-menu-item>
                      View Document Image
                    </button>
                  </a>
                  <mat-divider></mat-divider>
                  <!-- <a [routerLink]=" '/orders/saleOrder/' " target="_blank" class="preview-link">
                  <button mat-menu-item>
                    Start New Order
                  </button>
                </a> -->
                  <a [routerLink]="
                      '/orders/saleOrder/' +
                      data.patientId +
                      '/' +
                      defaultGuid +
                      '/' +
                      '0'
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>Start New Order</button>
                  </a>
                  <mat-divider></mat-divider>
                   <button mat-menu-item (click)="checkListModelIsView(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                  <mat-divider></mat-divider>
                  <a [routerLink]="
                      '/new_setup/' + data.docId + '/' + selectedNode+ '/' + data.inboundShortCode
                    " target="_blank" class="preview-link" [ngClass]="{
                      'disabled-link': data?.isCsrFaxLockedStatus === 2
                    }">
                    <button mat-menu-item>Sorting</button>
                  </a>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === inboundStatusIds.completed ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId,inboundStatusIds.completed)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.completed
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>
              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"> <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span> </mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Provider" name="search-provider" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Documents" name="search-documents" />
            </th>-->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Pending -->
    <ng-container *ngSwitchCase="inboundStatusIds.pending">
      <div *ngIf="!isRefresh">
        <table id="tblPending" datatable [dtOptions]="dtInboundOptionsPending"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <!-- <th >Documents</th>
            <th >CSR</th> -->
              <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matTooltip]="'Open Options'"
                  [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, true)" [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data?.isCsrFaxLockedStatus === 2
                    ">
                    Add Call Note
                  </button>
                  <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
                    View Patient Ledger
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      (data.priVerificationStatus === 'New' ||
                      isEmpty(data.priVerificationStatus)) &&
                        (data.secVerificationStatus === 'New' ||
                        isEmpty(data.secVerificationStatus)) &&
                        (data.terVerificationStatus === 'New' ||
                        isEmpty(data.terVerificationStatus))
                    " (click)="viewVerificationById(data)">
                    View Verification
                  </button>
                  <mat-divider></mat-divider>
                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    ">
                    View Patient Notes
                  </button>

                  <a [routerLink]="
                      '/view_document_tiff/' + data.docId + '/' + true
                    " target="_blank" class="preview-link" [class.disabled]="
                      isEmpty(data.docId) || data?.isSorted === false
                    ">
                    <button [disabled]="
                        isEmpty(data.docId) || data?.isSorted === false
                      " mat-menu-item>
                      View Document Image
                    </button>
                  </a>
                  <mat-divider></mat-divider>

                  <button mat-menu-item (click)="checkListModel(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>
                  <!-- <mat-divider></mat-divider> -->
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="bin.shortCodeId === inboundStatusIds.pending" [value]="bin.id"
                      (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId,inboundStatusIds.pending)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.pending
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>
              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"> <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span> </mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Provider" name="search-provider" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Documents" name="search-documents" />
            </th>-->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Call Back -->
    <ng-container *ngSwitchCase="inboundStatusIds.callBack">
      <div *ngIf="!isRefresh">
        <table id="tblCallBack" datatable [dtOptions]="dtInboundOptionsCallBack"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <!-- <th >Documents</th>
            <th >CSR</th> -->
              <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matTooltip]="'Open Options'"
                  [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, true)" [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      data?.isCsrFaxLockedStatus === 2
                    ">
                    Add Call Note
                  </button>
                  <button mat-menu-item (click)="viewPatientLedger(data?.patientId)">
                    View Patient Ledger
                  </button>
                  <!-- <mat-divider></mat-divider> -->
                  <!-- ((data.priVerificationStatus === 'New' ||
                  data.priVerificationStatus === null) &&
                  (data.secVerificationStatus === 'New' ||
                    data.secVerificationStatus === null) &&
                  (data.terVerificationStatus === 'New' ||
                    data.terVerificationStatus === null)) -->
                  <button mat-menu-item [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === '' ||
                      (data.priVerificationStatus === 'New' ||
                      isEmpty(data.priVerificationStatus)) &&
                        (data.secVerificationStatus === 'New' ||
                        isEmpty(data.secVerificationStatus)) &&
                        (data.terVerificationStatus === 'New' ||
                        isEmpty(data.terVerificationStatus))
                    " (click)="viewVerificationById(data)">
                    View Verification
                  </button>
                  <mat-divider></mat-divider>

                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>
                  <button (click)="
                      newSetupMoveTo(
                        data.docId,
                        data.inboundStatusId,
                        inboundStatusIds.scheduled,
                        false,
                        inboundStatusIds.callBack
                      )
                    " mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Schedule
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    ">
                    View Patient Notes
                  </button>
                  <a [routerLink]="
                      '/view_document_tiff/' + data.docId + '/' + true
                    " target="_blank" class="preview-link" [class.disabled]="
                      isEmpty(data.docId) || data?.isSorted === false
                    ">
                    <button [disabled]="
                        isEmpty(data.docId) || data?.isSorted === false
                      " mat-menu-item>
                      View Document Image
                    </button>
                  </a>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="checkListModel(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>
                  <!-- <mat-divider></mat-divider> -->
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>

                <!-- <mat-menu #locations="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let loc of locationList"
                  onclick="fnChangeLocation(loc.id,'bin')"
                >
                  {{ loc.organizationUnitName }}
                </button>
              </mat-menu> -->

                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === inboundStatusIds.callBack ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId,inboundStatusIds.callBack)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.callBack
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>
              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"><span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span></mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Provider" name="search-provider" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Documents" name="search-documents" />
            </th>-->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>

    <!-- Archive -->
    <ng-container *ngSwitchCase="inboundStatusIds.archive">
      <div *ngIf="!isRefresh">
        <table id="tblArchive" datatable [dtOptions]="dtInboundOptionsArchive"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <!-- <th >Documents</th>
            <th >CSR</th> -->
              <th [matTooltip]="'Aging Days'">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matTooltip]="'Open Options'"
                  [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    " mat-menu-item>
                    View Patient Notes
                  </button>
                  <button mat-menu-item (click)="checkListModel(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>
                  <mat-divider></mat-divider>
                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === inboundStatusIds.archive ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId, inboundStatusIds.archive)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.archive
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>
              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>
              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"><span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span></mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Provider" name="search-provider" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Documents" name="search-documents" />
            </th>-->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Recycle Bin -->
    <ng-container *ngSwitchCase="inboundStatusIds.recycleBin">
      <div *ngIf="!isRefresh">
        <table id="tblRecycleBin" datatable [dtOptions]="dtInboundOptionsRecycleBin"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <!-- <th >Documents</th>
            <th >CSR</th> -->
              <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matTooltip]="'Open Options'"
                  [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    " mat-menu-item>
                    View Patient Notes
                  </button>
                  <button mat-menu-item (click)="undeleteFax(data.docId)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Undelete
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="checkListModel(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Document Checklist
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === inboundStatusIds.recycleBin ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId, inboundStatusIds.recycleBin)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              inboundStatusIds.recycleBin
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>
              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>

              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>
              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"><span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span></mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="TAT"
                  name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Provider" name="search-provider" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Documents" name="search-documents" />
            </th>-->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
    <!-- Main - My fax-->
    <ng-container *ngSwitchCase="default">
      <!-- <div >
        <mat-radio-group  [(ngModel)]="selectedOption"  (change)="getFaxFileterList()">
            <mat-radio-button class="Reminders pl-3"  [value]="MyFaxfilter.All"><b><span class="fontresize">All
                        </span></b></mat-radio-button>
            <mat-radio-button class="Reminders" [checked]="true" [value]="MyFaxfilter.Pending"><b><span class="fontresize">My Fax Pending
                        </span></b></mat-radio-button>
            <mat-radio-button class="Reminders" [value]="MyFaxfilter.Completed"><b><span
                        class="fontresize">My Fax Completed</span></b></mat-radio-button>

        </mat-radio-group>

    </div> -->
      <div class="card card-body">
        <div *ngIf="!isRefresh">
          <table id="tblDefault" datatable [dtOptions]="dtInboundOptionsDefault"
            class="tbl hover row-border hover w-100 display">
            <thead>
              <tr>
                <th>
                  <mat-icon> wrap_text</mat-icon>
                </th>
                <th>
                  <mat-icon>apps</mat-icon>
                </th>
                <th [matTooltip]="'TAT'">TAT</th>
                <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
                <th [matTooltip]="'Document Name'">Document Name</th>
                <th [matTooltip]="'Pages'" class="text-right">Pages</th>
                <th [matTooltip]="'Received'" class="text-center">Received</th>
                <th [matTooltip]="'Modified'" class="text-center">Modified</th>
                <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
                <th [matTooltip]="'Patient Name'">Patient</th>
                <th [matTooltip]="'Location'">Location</th>
                <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
                <th [matTooltip]="'Secondary  Insurance'">Secondary Ins</th>
                <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
                <th [matTooltip]="'CSR Name'">CSR Name</th>
                <th [matTooltip]="'Location'">Location</th>
                <th [matTooltip]="'Upload Type'">Upload Type</th>
                <th [matTooltip]="'Sorting'">Sorting</th>
                <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
                <th [matTooltip]="'Practice'">Practice</th>
                <th [matTooltip]="'Completed By'">Completed By</th>
                <th [matTooltip]="'Completed Date'">Completed Date</th>
                <th [matTooltip]="'Modified By'">Modified By</th>
                <th [matTooltip]="'INS Group'">INS Group</th>
                <th [matTooltip]="'Aging Days'" class="text-right">
                  Aging Days
                </th>
                <th [matTooltip]="'Split'">Split</th>
                <th [matTooltip]="'Join'">Join</th>
                <th [matTooltip]="'STAT'">STAT</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of inboundTableData" [ngClass]="{
                  stat_highlight:
                    data.isStat === 1 || data.successfulStatUpdate,
                  'row-highlighted': this.tblSelectedFaxId === data.docId
                }">
                <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                  [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                    data.patientNotes?.length > 0 &&
                      handleTableCellClickV1($event, data)
                  ">
                  <div class="d-flex flex-row">
                    <div class="notes-count-container">
                      <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                    </div>
                    <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                    <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                  </div>
                </td>
                <td>
                  <mat-icon style="cursor: pointer !important" [matTooltip]="'Open Options'"
                    [matMenuTriggerFor]="menu">more_vert</mat-icon>
                  <mat-checkbox *ngIf="
                      data.isCsrFaxLockedStatus !== 1 &&
                      data.isCsrFaxLockedStatus !== 2
                    " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"
                    [disabled]="roleName === 'CSR'"></mat-checkbox>
                  <mat-checkbox *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                    (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                  <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                    [matTooltip]="'File Already Locked'">lock</mat-icon>

                  <mat-menu class="custom-mat-menu" #menu="matMenu">
                    <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                      [disabled]="data?.isCsrFaxLockedStatus === 2">
                      Add Note
                    </button>
                    <button (click)="
                        openViewNotesPopup(
                          data.patientId,
                          data.defaultFaxId,
                          data.docId
                        )
                      " [disabled]="
                        data.patientId === defaultGuid ||
                        data.patientId === null ||
                        data.patientId === ''
                      " mat-menu-item>
                      View Patient Notes
                    </button>
                    <button mat-menu-item (click)="OpenMapUserModel($event, data)"
                      [disabled]="data?.isCsrFaxLockedStatus === 2">
                      Send To another user
                    </button>
                    <div  [matTooltip]="data.docPageCount < 2 ? 'Single Page has not allowed Split.' : (data?.isCsrFaxLockedStatus === 2 ? 'Locked By Another User' : '')">
                      <button  [disabled]="
                      data.docPageCount < 2 ||
                      data?.isCsrFaxLockedStatus === 2
                    " (click)="
                      openSplitDocumentModal(
                        data.docId,
                        data.defaultDocumentId,
                        data.defaultFaxId,
                        data.blobName
                      )
                    " mat-menu-item>
                    Split
                  </button>
                    </div>

                    <button mat-menu-item (click)="joinFaxModal(data)" [disabled]="data?.isCsrFaxLockedStatus === 2">
                      Join
                    </button>
                    <a [routerLink]="
                        '/new_setup/' +
                        data.docId +
                        '/' +
                        selectedNode +
                        '/' +
                        true+ '/' + data.inboundShortCode
                      " target="_blank" class="preview-link" [ngClass]="{
                        'disabled-link': data?.isCsrFaxLockedStatus === 2
                      }">
                      <button mat-menu-item>Sorting</button>
                    </a>
                    <button (click)="
                        newSetupMoveTo(
                          data.docId,
                          data.inboundStatusId,
                          inboundStatusIds.recycleBin,
                          false,
                          data.inboundShortCode
                        )
                      " mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                      Delete (Recycle bin)
                    </button>
                    <mat-divider></mat-divider>
                    <button (click)="
                      newSetupMoveTo(
                        data.docId,
                        data.inboundStatusId,
                        inboundStatusIds.myFaxPending,
                        false,
                        data.inboundShortCode,
                        MyFaxSelection
                      )
                    " mat-menu-item
                      [disabled]="data?.isCsrFaxLockedStatus === 2||MyFaxSelection===1||data.currentShortCodeId===inboundStatusIds.myFaxPending">
                      Pending (My Fax)
                    </button>
                    <button (click)="
                    newSetupMoveTo(
                      data.docId,
                      data.inboundStatusId,
                      inboundStatusIds.myFaxCompleted,
                      false,
                      data.inboundShortCode,
                      MyFaxSelection
                    )
                  " mat-menu-item
                      [disabled]="data?.isCsrFaxLockedStatus === 2||MyFaxSelection===2||data.currentShortCodeId===inboundStatusIds.myFaxCompleted">
                      Completed (My Fax)
                    </button>
                    <mat-divider></mat-divider>
                    <button (click)="
                        newSetupMoveTo(
                          data.docId,
                          data.inboundStatusId,
                          inboundStatusIds.missingDocuments,
                          false,
                          data.inboundShortCode
                        )
                      " mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                      Missing Documents
                    </button>
                    <button mat-menu-item (click)="
                        newSetupMoveTo(
                          data.docId,
                          data.inboundStatusId,
                          '001',
                          false,
                          data.inboundShortCode
                        )
                      " [disabled]="data?.isCsrFaxLockedStatus === 2">
                      Unprocessed Fax
                    </button>
                    <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                      Move To
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                      Change Location
                    </button>
                  </mat-menu>
                  <mat-menu #locations="matMenu" >
                    <ng-container *ngFor="let branch of locationList" style="min-height: 30px !important;">
                      <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                        [disabled]="branch.id === data.branchId" [value]="branch.id">
                        {{ branch.organizationUnitName }}
                      </button>
                    </ng-container>
                  </mat-menu>
                  <mat-menu #moveTo="matMenu">
                    <ng-container *ngFor="let bin of binList">
                      <button mat-menu-item [disabled]="
                          bin.shortCodeId === data.inboundShortCode||
                          bin.shortCodeId === inboundStatusIds.unprocessedFax
                        " [value]="bin.id" (click)="
                          bin.shortCodeId === inboundStatusIds.callBack
                            ? callback(data.docId, bin.shortCodeId,data.inboundShortCode)
                            : newSetupMoveTo(
                                data.docId,
                                data.inboundStatusId,
                                bin.shortCodeId,
                                false,
                                data.inboundShortCode
                              )
                        ">
                        {{ bin.inboundStatus }}
                      </button>
                    </ng-container>
                  </mat-menu>
                </td>
                <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                  [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                  {{ data?.tat || "-" }}
                </td>
                <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                  [matTooltip]="data?.defaultFaxId || '-'">
                  <a [class.disabled]="
                      isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                    " [routerLink]="
                      '/patient_preview_tiff_document/' +
                      data.defaultFaxId +
                      '/' +
                      data.docId
                    " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
                </td>
                <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                  {{ data?.documentName || "-" }}
                </td>
                <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                  [matTooltip]="data.docPageCount || '-'">
                  {{ data.docPageCount || "-" }}
                </td>
                <td (dblclick)="
                    onRowDoubleClick(data?.uploadedDate)
                  " [matTooltip]="data?.uploadedDate" class="text-center">
                  {{ data?.uploadedDate }}
                </td>
                <td (dblclick)="
                    onRowDoubleClick(
                      data?.lastModificationTime
                    )
                  " [matTooltip]="data?.lastModificationTime" class="text-center">
                  {{ data?.lastModificationTime }}
                </td>
                <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                  <a [class.disabled]="
                      isEmpty(data.chartId) || isEmpty(data.patientId)
                    " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                    [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
                </td>
                <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                  {{ data?.patientName || "-" }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.branchName || '-')" [matTooltip]="data?.branchName || '-'">
                  {{ data?.branchName || "-" }}
                </td>
                <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                  {{ data.priPolicyName || "-" }}
                </td>
                <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                  {{ data.secPolicyName || "-" }}
                </td>
                <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                  {{ data.terPolicyName || "-" }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                  {{ data?.csrName || "-" }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                  <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
                </td>
                <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                  {{ data.uploadType || "-" }}
                </td>
                <td>
                  {{
                  data.isSorted !== null &&
                  data.isSorted !== undefined &&
                  data.isSorted !== ""
                  ? data.isSorted
                  ? "Yes"
                  : "No"
                  : "-"
                  }}
                </td>
                <td>
                  {{
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ""
                  ? data.isDocumentCheckList
                  ? "Yes"
                  : "No"
                  : "-"
                  }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                  {{ data?.practice || "-" }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.completedByName || '-')"
                [matTooltip]="data?.completedByName || '-'">
                {{ data?.completedByName || "-" }}
              </td>
              <td (dblclick)="
                    onRowDoubleClick(data?.completedDate)
                  " [matTooltip]="data?.completedDate" class="text-center">
                  {{ data?.completedDate }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')"
                  [matTooltip]="data?.lastModifierBy || '-'">
                  {{ data?.lastModifierBy || "-" }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')"
                  [matTooltip]="data?.insuranceGroup || '-'">
                  {{ data?.insuranceGroup || "-" }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                  [matTooltip]="data?.agingsDays || '-'">
                  {{ data.agingsDays || "-" }}
                </td>

                <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')"
                  [matTooltip]="data?.splitFromFaxId || '-'">
                  {{ data?.splitFromFaxId || "-" }}
                </td>
                <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                  {{ data?.joinedFaxIds || "-" }}
                </td>
                <td>
                  <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                    (change)="isStatUpdate($event, data)"><span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span></mat-checkbox>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>
                  <mat-icon> wrap_text</mat-icon>
                </th>
                <th>
                  <mat-icon>apps</mat-icon>
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-tat" />
                </th>
                <th>
                  <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                    name="search-faxid" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                    name="search-documents" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Pages" name="search-pages" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Received"
                    name="search-received" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Modified"
                    name="search-modified" />
                </th>
                <th>
                  <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                    name="search-chartId" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Patient"
                    name="search-patient" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Location"
                    name="search-Location" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                    name="search-primaryIns" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                    name="search-secondaryIns" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                    name="search-tertiaryIns" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                    name="search-uploadType" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                    name="search-docchecklist" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                    name="search-practice" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Completed By"
                    name="search-completedBy" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Completed Date"
                    name="search-completedDate" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                    name="search-modifiedBy" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                    name="search-insGroup" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Aging Days"
                    name="search-aging" />
                </th>

                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
                </th>

                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
                </th>
                <th>
                  <mat-icon>lock</mat-icon>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </ng-container>
    <!-- Default-->
    <ng-container *ngSwitchDefault>
      <div *ngIf="!isRefresh">
        <table id="tblSwitchDefault" datatable [dtOptions]="dtInboundOptionsngSwitchDefault"
          class="tbl hover row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'TAT'">TAT</th>
              <th [matTooltip]="'Fax Id'" class="custom-width">Fax Id</th>
              <th [matTooltip]="'Document Name'">Document Name</th>
              <th [matTooltip]="'Pages'" class="text-right">Pages</th>
              <th [matTooltip]="'Received'" class="text-center">Received</th>
              <th [matTooltip]="'Modified'" class="text-center">Modified</th>
              <th [matTooltip]="'ChartId'" class="custom-width">Chart Id</th>
              <th [matTooltip]="'Patient Name'">Patient</th>
              <th [matTooltip]="'Primary Insurance'">Primary Ins</th>
              <th [matTooltip]="'Secondary Insurance'">Secondary Ins</th>
              <th [matTooltip]="'Tertiary Insurance'">Tertiary Ins</th>
              <th [matTooltip]="'CSR Name'">CSR Name</th>
              <th [matTooltip]="'Location'">Location</th>
              <th [matTooltip]="'Upload Type'">Upload Type</th>
              <th [matTooltip]="'Sorted'">Sorted</th>
              <th [matTooltip]="'Doc Checklist'">Doc Checklist</th>
              <th [matTooltip]="'Practice'">Practice</th>
              <!-- <th >Provider</th> -->
              <th [matTooltip]="'Modified By'">Modified By</th>
              <th [matTooltip]="'INS Group'">INS Group</th>
              <!-- <th >Documents</th>
            <th >CSR</th> -->
              <th [matTooltip]="'Aging Days'" class="text-right">Aging Days</th>
              <th [matTooltip]="'Split'">Split</th>
              <th [matTooltip]="'Join'">Join</th>
              <th [matTooltip]="'STAT'">STAT</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inboundTableData" [ngClass]="{
                stat_highlight: data.isStat === 1 || data.successfulStatUpdate,
                'row-highlighted': this.tblSelectedFaxId === data.docId
              }" (click)="fnSelectedRow(data.docId, data.patientId)">
              <td [ngClass]="data.patientNotes?.length > 0 ? 'cursor_pointer': 'disabled'"
                [matTooltip]="data.isOpen ? 'Collapse Notes' : 'Expand Notes'" (click)="
                  data.patientNotes?.length > 0 &&
                    handleTableCellClickV1($event, data)
                ">
                <div class="d-flex flex-row">
                  <div class="notes-count-container">
                    <span class="notes-count">({{ data.patientNotes?.length || 0 }})</span>
                  </div>
                  <mat-icon *ngIf="!data.isOpen">chevron_right</mat-icon>
                  <mat-icon *ngIf="data.isOpen">expand_more</mat-icon>
                </div>
              </td>
              <td>
                <mat-icon style="cursor: pointer !important" [matTooltip]="'Open Options'"
                  [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="
                    data.isCsrFaxLockedStatus !== 1 &&
                    data.isCsrFaxLockedStatus !== 2
                  " (change)="addRemoveChk($event, data)" [matTooltip]="'Lock File'"></mat-checkbox>
                <mat-checkbox [disabled]="disableLockCheckbox" *ngIf="data.isCsrFaxLockedStatus === 1" checked="true"
                  (change)="addRemoveChk($event, data)" [matTooltip]="'Locked File'"></mat-checkbox>
                <mat-icon *ngIf="data.isCsrFaxLockedStatus === 2" style="color: red"
                  [matTooltip]="'File Already Locked'">lock</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                  <!-- <button mat-menu-item (click)="OpenModelPopup(data.docId)">
                  Send To another user
                </button>
                <button (click)="
                    openSplitDocumentModal(data.docId, data.defaultDocumentId)
                  " mat-menu-item>
                  Split
                </button>
                <button (click)="deleteFax(data.docId)" mat-menu-item>
                  Recycle Bin
                </button>

                <mat-divider></mat-divider>
                <button (click)="
                    updateInboundStatus(
                      data.docId,
                      inboundStatusIds.missingDocuments, data.inboundStatusId
                    )
                  " mat-menu-item>
                  Missing Documents
                </button> -->
                  <!-- <button mat-menu-item (click)="checkListModel(data)">
                  Document Checklist
                </button> -->
                  <!-- <button (click)="opensortModal(data)" mat-menu-item>
                  Sorting
                </button> -->
                  <!-- <mat-divider></mat-divider>-->
                  <button mat-menu-item (click)="openAddNotesPopUpV1($event, data, false)"
                    [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Add Note
                  </button>
                  <button (click)="
                      openViewNotesPopup(
                        data.patientId,
                        data.defaultFaxId,
                        data.docId
                      )
                    " [disabled]="
                      data.patientId === defaultGuid ||
                      data.patientId === null ||
                      data.patientId === ''
                    " mat-menu-item>
                    View Patient Notes
                  </button>
                  <button [matMenuTriggerFor]="moveTo" mat-menu-item [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Move To
                  </button>
                  <!-- <mat-divider></mat-divider> -->
                  <button mat-menu-item [matMenuTriggerFor]="locations" [disabled]="data?.isCsrFaxLockedStatus === 2">
                    Change Location
                  </button>
                </mat-menu>
                <mat-menu #locations="matMenu">
                  <ng-container *ngFor="let branch of locationList">
                    <button (click)="updateLocation(data.docId, branch.id)" mat-menu-item
                      [disabled]="branch.id === data.branchId" [value]="branch.id">
                      {{ branch.organizationUnitName }}
                    </button>
                  </ng-container>
                </mat-menu>
                <mat-menu #moveTo="matMenu">
                  <ng-container *ngFor="let bin of binList">
                    <button mat-menu-item [disabled]="
                        bin.shortCodeId === data.inboundShortCode ||
                        bin.shortCodeId === inboundStatusIds.unprocessedFax
                      " [value]="bin.id" (click)="
                        bin.shortCodeId === inboundStatusIds.callBack
                          ? callback(data.docId, bin.shortCodeId,data.inboundShortCode)
                          : newSetupMoveTo(
                              data.docId,
                              data.inboundStatusId,
                              bin.shortCodeId,
                              false,
                              data.inboundShortCode
                            )
                      ">
                      {{ bin.inboundStatus }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.tat || '-')" [matTooltip]="data?.tat || '-'"
                [ngStyle]="{ color: getValueColor(data.isTatDone) }">
                {{ data?.tat || "-" }}
              </td>
              <td class="custom-width" (dblclick)="onRowDoubleClick(data?.defaultFaxId || '-')"
                [matTooltip]="data?.defaultFaxId || '-'">
                <a [class.disabled]="
                    isEmpty(data.docId) || isEmpty(data.defaultFaxId)
                  " [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.defaultFaxId +
                    '/' +
                    data.docId
                  " target="_blank" class="preview-link">{{ data?.defaultFaxId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">
                {{ data?.documentName || "-" }}
              </td>
              <td class="text-right" (dblclick)="onRowDoubleClick(data?.docPageCount || '-')"
                [matTooltip]="data.docPageCount || '-'">
                {{ data.docPageCount || "-" }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.uploadedDate)
                " [matTooltip]="data?.uploadedDate" class="text-center">
                {{ data?.uploadedDate }}
              </td>
              <td (dblclick)="
                  onRowDoubleClick(data?.lastModificationTime)
                " [matTooltip]="data?.lastModificationTime" class="text-center">
                {{ data?.lastModificationTime }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.chartId || '-')" class="custom-width">
                <a [class.disabled]="
                    isEmpty(data.chartId) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartId || '-'">{{ data.chartId || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">
                {{ data?.patientName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.priPolicyName || '-')" [matTooltip]="data.priPolicyName || '-'">
                {{ data.priPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.secPolicyName || '-')" [matTooltip]="data.secPolicyName || '-'">
                {{ data.secPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.terPolicyName || '-')" [matTooltip]="data.terPolicyName || '-'">
                {{ data.terPolicyName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.csrName || '-')" [matTooltip]="data?.csrName || '-'">
                {{ data?.csrName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.branchName || '-')">
                <span [matTooltip]="data.branchName || '-'">{{ data?.branchName || "-" }}</span>
              </td>
              <td (dblclick)="onRowDoubleClick(data?.uploadType || '-')" [matTooltip]="data?.uploadType || '-'">
                {{ data.uploadType || "-" }}
              </td>
              <td>
                {{
                data.isSorted !== null &&
                data.isSorted !== undefined &&
                data.isSorted !== ""
                ? data.isSorted
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td [title]="
                  data.isDocumentCheckList !== null &&
                  data.isDocumentCheckList !== undefined &&
                  data.isDocumentCheckList !== ''
                    ? data.isDocumentCheckList
                      ? 'Yes'
                      : 'No'
                    : '-'
                ">
                {{
                data.isDocumentCheckList !== null &&
                data.isDocumentCheckList !== undefined &&
                data.isDocumentCheckList !== ""
                ? data.isDocumentCheckList
                ? "Yes"
                : "No"
                : "-"
                }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.practice || '-')" [matTooltip]="data?.practice || '-'">
                {{ data?.practice || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.lastModifierBy || '-')" [matTooltip]="data?.lastModifierBy || '-'">
                {{ data?.lastModifierBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.insuranceGroup || '-')" [matTooltip]="data?.insuranceGroup || '-'">
                {{ data?.insuranceGroup || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.agingsDays || '-')" class="text-right"
                [matTooltip]="data?.agingsDays || '-'">
                {{ data.agingsDays || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data?.splitFromFaxId || '-')" [matTooltip]="data?.splitFromFaxId || '-'">
                {{ data?.splitFromFaxId || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data?.joinedFaxIds || '-')" [matTooltip]="data?.joinedFaxIds || '-'">
                {{ data?.joinedFaxIds || "-" }}
              </td>
              <td>
                <mat-checkbox color="primary" [(ngModel)]="data.isStat"
                  (change)="isStatUpdate($event, data)"><span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span></mat-checkbox>
              </td>
            </tr>
          </tbody>
          <!-- Footer -->
          <tfoot>
            <tr>
              <th><mat-icon> wrap_text</mat-icon></th>
              <th><mat-icon>apps</mat-icon></th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="TAT" name="search-TAT" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="Fax Id"
                  name="search-faxid" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Document Name"
                  name="search-documents" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Pages" name="search-pages" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Received"
                  name="search-received" />
              </th>
              <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Modified"
                  name="search-modified" />
              </th>
              <th>
                <input matInput class="textbox-border-class custom-width" type="text" placeholder="ChartId"
                  name="search-chartId" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient" name="search-patient" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Primary Ins"
                  name="search-primaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Secondary Ins"
                  name="search-secondaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Ins"
                  name="search-tertiaryIns" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR Name" name="search-csr" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Location" name="search-location" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Upload Type"
                  name="search-uploadType" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Sorted" name="search-Sorted" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Doc Checklist"
                  name="search-docchecklist" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Practice"
                  name="search-practice" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Provider" name="search-provider" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                  name="search-modifiedBy" />
              </th>
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="INS Group"
                  name="search-insGroup" />
              </th>
              <!-- <th >
              <input matInput class="textbox-border-class" type="text" placeholder="Documents" name="search-documents" />
            </th> -->
              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Aging Days" name="search-tat" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Split" name="search-split" />
              </th>

              <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Join" name="search-join" />
              </th>
              <th><mat-icon>lock</mat-icon></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
  </div>

  <hr />

  <ng-container *ngIf="strInboundStatus !== 'Default'">
    <div class="row mt-3 mb-2 ml-1" *ngIf="strInboundStatus != inboundStatusIds.unprocessedFax">
      <!-- Product Exchange Table-->
      <div class="col-lg-3">
        <h3 class="app-custom-color font-weight-bold">CALL DETAILS</h3>
        <div class="card mb-2 elevated-div content">
          <div class="table-responsive" *ngIf="!isLoading">
            <app-call-details-table [tableData]="calllogTableData"></app-call-details-table>
          </div>
        </div>
      </div>

      <div class="col-lg-9">
        <h3 class="app-custom-color font-weight-bold">PATIENT DETAILS</h3>
        <div class="card mb-2 elevated-div content">
          <form [formGroup]="patientForm">
            <div class="row cusPadd">
              <mat-form-field class="col-lg-4">
                <mat-label>Patient Name</mat-label>
                <input autocomplete="new-txtEmail" matInput formControlName="patientName" type="text" readonly
                  (focus)="passInputValue($event)" />
              </mat-form-field>
              <mat-form-field class="col-lg-4">
                <mat-label>Chart No</mat-label>
                <input autocomplete="new-txtEmail" matInput formControlName="chartNo" type="text" readonly
                  (click)="passInputValue($event)" />
              </mat-form-field>
              <mat-form-field class="col-lg-4">
                <mat-label>DOB</mat-label>
                <input matInput type="text" formControlName="dob" readonly (click)="passInputValue($event)" />
              </mat-form-field>
              <mat-form-field class="col-lg-4">
                <mat-label>Email</mat-label>
                <input matInput type="text" formControlName="email" readonly (click)="passInputValue($event)" />
              </mat-form-field>
              <mat-form-field class="col-lg-4">
                <mat-label>Cell</mat-label>
                <input matInput type="text" formControlName="cell" readonly (click)="passInputValue($event)" />
              </mat-form-field>
              <mat-form-field class="col-lg-4">
                <mat-label>Work No</mat-label>
                <input matInput type="text" formControlName="workNo" readonly (click)="passInputValue($event)" />
              </mat-form-field>
            </div>
            <div class="row cusPadd">
              <mat-form-field class="col-lg-12">
                <mat-label>Address</mat-label>
                <textarea matInput class="no-resize" formControlName="address" rows="2" readonly
                  (click)="passInputValue($event)"></textarea>
              </mat-form-field>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isRefresh">
      <div class="col-12">
        <div class="d-flex justify-content-center" *ngIf="isRefresh">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
