import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { dtInboundOptions } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { InventoryAdjustmentService } from '../item-proxy/item-management/optimization/inventory-adjustment.service';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { ProductDetailsService } from '../item-proxy/item-management/optimization';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import {
  CreateUpdateAdjustmentItemDTO,
  CreateUpdateInventoryAdjustmentDTO,
} from '../item-proxy/item-management/optimization/dto';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { Subscription } from 'rxjs';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';

@Component({
  selector: 'app-adjustment',
  templateUrl: './adjustment.component.html',
  styleUrls: ['./adjustment.component.scss'],
})
export class AdjustmentComponent implements OnInit {
  adjustmentForm: FormGroup;
  AdjustmentTableForm: FormGroup;

  // dtInboundOptions: any = {
  //   responsive: true,
  //   filter: false,
  //   paging: false,
  //   order: [],
  //   scrollX: '200px',
  //   scrollCollapse: true,
  //   // pageLength: 10,
  //   // lengthMenu: [10, 25, 50, 100, 200],
  //   columnDefs: [{ targets: [0, 2, 3, 4], orderable: false }],
  // };
  dtInboundOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    searching: false,
    buttons: [],
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],

    columnDefs: [{ targets: [0, 2, 3, 4], orderable: false }],
  };
  lstAdjustment: any;
  lstProductCode: any;
  isReasonValidator: boolean = false;
  isPysical: boolean = false;
  filteredAdjustmentList: any;
  subscription$: Subscription[] = [];
  isSubmit: boolean = false;
  loadTable: boolean = true;
  productDetailsList: any;
  txtSerialNo: any;
  txtQuantity: any;
  txtPhysical: any;
  searchControl = new FormControl();
  selectserialNo: string;
  selectQuantity: string;
  editMode: boolean = false;
  selectPhysical: string;
  loadAdjust: any;
  isEmpty: any;
  productDes: any;
  ltproducts: any;
  filteredOptions: any;
  constructor(
    private formBuilder: FormBuilder,
    private InventoryAdjustmentService: InventoryAdjustmentService,
    private toastr: ToastrService,
    private communicationService: CommunicationService,
    private productDetailsService: ProductDetailsService,
    private mmorderService: MmOrderService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      adjustId: string[];
    } = {
      adjustId: [],
    }
  ) {
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.filterProducts();
      });
  }

  ngOnInit(): void {
    this.adjustmentForm = this.formBuilder.group({
      AdjustmentReason: new FormControl('', [Validators.required]),
      ReasonText: new FormControl(''),
      txtNotes: new FormControl(''),
      txtAdjustmentReason: new FormControl(''),
    });
    this.AdjustmentTableForm = this.formBuilder.group({
      drpProdCode: new FormControl('', [Validators.required]),
      txtProdCode: new FormControl(''),
      txtSerialNo: new FormControl('', [Validators.required]),
      txtPhysical: new FormControl(null),
      txtQuantity: new FormControl(''),
    });
    this.loadProducts();
    this.loadDropDown();
    if (this.data.adjustId) {
      this.loadAdjustData();
    }
  }
  //Method To Search the Products in Dropdown
  filterProducts(): void {
    const searchValue = this.searchControl.value.toLowerCase();
    this.filteredOptions = this.ltproducts.filter((option) =>
      option.productName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  //Load Products
  loadProducts() {
    const productsList = this.mmorderService.getProductDetails().subscribe(
      (response) => {
        this.ltproducts = response;
        this.filteredOptions = [...this.ltproducts];
      },
      (err) => {}
    );
    this.subscription$.push(productsList);
  }

  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }

  loadDropDown() {
    const tranferStatus =
      this.InventoryAdjustmentService.getAllAdjustmentReasonList().subscribe(
        (response) => {
          this.lstAdjustment = response;
          this.lstAdjustment = response.sort((a, b) =>
            a.adjustmentReason.localeCompare(b.adjustmentReason)
          );
          this.filteredAdjustmentList = this.adjustmentForm
            ?.get('txtAdjustmentReason')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstAdjustment.filter((option) =>
                  option?.adjustmentReason
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        }
      );

    // const getProduct = this.productDetailsService.getList(new PagedAndSortedResultRequestDto).subscribe(response =>{
    //   this.lstProductCode = response.items

    // this.productDetailsList = this.AdjustmentTableForm
    // ?.get('txtProdCode')
    // ?.valueChanges.pipe(
    //   startWith(''),
    //   map((value) =>
    //     this.lstProductCode.filter((option) =>
    //       option?.productCode
    //         ?.toLowerCase()
    //         ?.includes(value?.toLowerCase() ?? '')
    //     )
    //   )
    // );
    // })
  }

  loadAdjustData() {
    this.loadTable = false;
    this.InventoryAdjustmentService.getInventoryAdjustmentItemDetailsByGInventory(
      this.data.adjustId
    ).subscribe(
      (response) => {
        this.loadTable = true;
        // let data = response.adjustmentItemList[0]
        this.loadAdjust = response.adjustmentItemList;
        // this.AdjustmentTableForm.patchValue({
        //   drpProdCode: data.productId,
        //   txtSerialNo: data.serialLotNumber,
        //   txtPhysical: data.physical,
        //   txtQuantity: data.qoh

        // })
      },
      (err) => {
        this.loadTable = true;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  MainProduct: string = '';
  clearMainProduct() {
    this.MainProduct = '';

    this.adjustmentForm.patchValue({ txtAdjustmentReason: '' });
  }

  product: string = '';
  clearProduct() {
    this.product = '';

    // this.AdjustmentTableForm.patchValue({ txtProdCode: "" });
  }

  onAdjustmentReason(event) {
    if (event.value.adjustmentReason == 'Other') {
      this.isReasonValidator = true;

      this.adjustmentForm.controls['ReasonText'].setValidators(
        Validators.required
      );

      this.adjustmentForm.controls['ReasonText'].updateValueAndValidity();
    } else if (event.value.adjustmentReason == 'Physical Count') {
      this.isPysical = true;

      // this.AdjustmentTableForm.controls['txtPhysical'].setValidators(
      //   Validators.required
      // );

      // this.AdjustmentTableForm.controls['txtPhysical'].updateValueAndValidity();
    } else {
      this.isPysical = false;
      this.isReasonValidator = false;

      this.adjustmentForm.controls['ReasonText'].clearValidators();

      this.adjustmentForm.controls['ReasonText'].reset();

      // Trigger validation update
      this.adjustmentForm.controls['ReasonText'].updateValueAndValidity();

      // physial count validation
      // this.AdjustmentTableForm.controls['txtPhysical'].clearValidators()

      // this.AdjustmentTableForm.controls['txtPhysical'].reset();

      // Trigger validation update
      // this.AdjustmentTableForm.controls['txtPhysical'].updateValueAndValidity();
    }
  }

  selectRowserialNo() {
    this.selectserialNo = 'data';
    this.editMode = true;
  }

  selectRowQuantity() {
    this.selectQuantity = 'data';
    this.editMode = true;
  }

  selectRowselectPhysical() {
    this.selectPhysical = 'data';
    this.editMode = true;
  }

  onDropdownSelect(event) {
    this.productDes = event.value;
  }

  saveDetails() {
    const tenantId = localStorage?.getItem('tenantId') ?? '';
    const userId = localStorage?.getItem('userId') ?? '';

    let adjustmentItemList: CreateUpdateAdjustmentItemDTO[] = [
      {
        mmRefInventoryAdjustmentItemId: 0,
        mmRefInventoryAdjustmentId: 0,
        inventoryAdjustmentId: '00000000-0000-0000-0000-000000000000',
        productId: this.productDes,
        qoh: this.txtQuantity,
        newQOH: 0,
        ssId: '00000000-0000-0000-0000-000000000000',
        serialLotNumber: this.txtSerialNo ? this.txtSerialNo : null,
        mmInventoryId: defaultGuid,
        productName: '',
        lotSerialNo: this.txtSerialNo,
        physical: this.txtPhysical != '' ? this.txtPhysical : 0,
        userId: '',
        reasonText: '',
        unitCost: 0,
        processed: 0,
      },
    ];

    let saveDetailsValue: CreateUpdateInventoryAdjustmentDTO = {
      mmRefInventoryAdjustmentId: 0,
      inventoryLocationId: '00000000-0000-0000-0000-000000000000',
      invAdjReasonId: this.adjustmentForm.value.AdjustmentReason.id,
      reasonText: this.adjustmentForm.value.ReasonText,
      submittedBy: userId,
      tenantId: tenantId,
      adjustmentItemList: this.loadAdjust,
    };

    this.InventoryAdjustmentService.create(saveDetailsValue).subscribe(
      (response) => {
        this.toastr.success('Saved Successfully', 'Success');
        this.communicationService.triggerReloadStockTable();
        this.dialog.closeAll();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
}
