<!-- Work In Progress -->
<!-- <div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-0">Patient / Work In Progress</h3>
        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0"> -->
<!-- <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Username"
                [hidden]="dateRangStatus != true"
                aria-label="Username"
                aria-describedby="basic-addon1"
                name="daterange"
                ngxDaterangepickerMd
                [(ngModel)]="selectedRange"
                id="calendar-input"
                [showCustomRangeLabel]="true"
                [alwaysShowCalendars]="true"
                [linkedCalendars]="true"
                [ranges]="ranges"
                [showClearButton]="true"
                [locale]="calendarLocale"
                [placeholder]="calendarPlaceholder"
                [keepCalendarOpeningWithRange]="true"
                [minDate]="minDate"
                [maxDate]="maxDate"
                (datesUpdated)="datesUpdateds($event)"
              />
            </div>
            <span class="input-group-text bg-color mr-2" id="basic-addon1"
              ><label style="margin: auto" for="calendar-input"
                ><i class="fa fa-filter cursor" aria-hidden="true"></i></label
            ></span> -->
<!-- <li> -->
<!-- <button  (click)="getScheduledTasksList(); $event.preventDefault()" mat-raised-button
              class="mr-2 buttonColor" [disabled]='scheduledData.length === 0'>Scheduled Tasks</button> -->
<!-- <button
                class="mr-2 buttonColor"
                [disabled]="
                  dataSource &&
                  dataSource?.data &&
                  dataSource?.data?.length === 0
                "
                mat-raised-button
                (click)="
                  exporter.exportTable('xlsx', {
                    fileName: 'WIP List',
                    sheet: 'WIPList',
                    Props: { Author: 'QSecure' }
                  })
                "
              >
                Export
              </button> -->
<!-- <li class="breadcrumb-item mt-2 pl-2">Task List</li>
            <li class="breadcrumb-item mt-2">Work In Progress</li> -->
<!-- </li>
          </ol>
        </div>
      </div>
    </div> -->

<!-- <div class="col-lg-12"> -->
<!-- Filters -->
<!-- <form [formGroup]="wipFilterForm">
        <div class="col-lg-12 pt-0">
          <div class="row mt-0">
            <mat-form-field class="col-2">
              <mat-label>Users</mat-label>
              <mat-select
                multiple
                #userSelect
                formControlName="txtUser"
                [(value)]="userFilterValue"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtUserFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  #allUsersSelected
                  [value]="0"
                  (click)="toggleAllUsersSelection()"
                  >Select All Users</mat-option
                >
                <mat-option
                  *ngFor="let users of filteredUsers | async"
                  [value]="users?.userId"
                >
                  {{ users?.userDesignation }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-2">
              <mat-label>Status</mat-label>
              <mat-select
                multiple
                formControlName="txtStatus"
                [(value)]="statusFilterValue"
                #statusSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtStatusFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  #allStatusSelected
                  [value]="0"
                  (click)="toggleAllStatusSelection()"
                  >Select All Status</mat-option
                >
                <mat-option
                  *ngFor="let status of filteredStatus | async"
                  [value]="status?.status"
                >
                  {{ status?.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="col-2">
              <button
                class="mr-2 buttonColor"
                (click)="filterWIPList()"
                mat-button
              >
                Search
              </button>
              <button mat-button (click)="resetForm()" class="resetclr mr-2">
                Reset
              </button>
            </div>
          </div>
        </div>
      </form>

      <div class="col-12 pb-5">
        <form [formGroup]="wipSearchForm"> -->
<!-- <div class="example-container mat-elevation-z4"> -->
<!-- <div
            class="table table-responsive example-container mat-elevation-z4 pt-0 mt-0"
          >
            <table
              mat-table
              [dataSource]="dataSource"
              matTableExporter
              #exporter="matTableExporter"
              matSort
              class="pt-0 mt-0"
              style="margin-left: 15px !important"
            > -->
<!-- Document Id -->
<!-- <ng-container matColumnDef="defaultDocumentId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                > -->
<!-- Doc Id -->
<!-- <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="documentId"
                      autocomplete="off"
                    />
                    <mat-placeholder class="center"> Doc Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a
                    matTooltip="Click to View Document"
                    class="eyeCursorclass"
                    [routerLink]="
                      '/ViewDocumentPerviewByBlobId/' +
                      element?.blobName +
                      '/' +
                      5
                    "
                    target="_blank"
                  >
                    {{ element?.defaultDocumentId }}
                  </a>
                </td>
              </ng-container> -->
<!-- isStat-->
<!-- <ng-container matColumnDef="isStat">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>STAT</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    {{ element.isStat == 1 ? "Yes" : "No" }}
                  </ng-container>
                </td>
              </ng-container> -->

<!-- WIP ID-->
<!-- <ng-container matColumnDef="wipId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                > -->
<!-- WIP Id -->
<!-- <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="wipId"
                      autocomplete="off"
                    />
                    <mat-placeholder class="center">WIP Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> -->
<!-- <a
                    (click)="
                      scheduleWIPDetailsById(element); $event.preventDefault()
                    "
                    matTooltip="Click to Schedule"
                    class="eyeCursorclass"
                    target="_blank"
                    rel="noopener"
                  >
                </a> -->

<!-- <a
                    matTooltip="Click to View WIP List"
                    class="eyeCursorclass"
                    (click)="openWipDetailsModal(element.id, element.wipId)"
                  >
                    {{ element?.wipId }}
                  </a>
                </td>
              </ng-container> -->
<!--Patient Id-->
<!-- <ng-container matColumnDef="defaultPatientId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                > -->
<!-- Patient Id -->
<!-- <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="defaultPatientId"
                      autocomplete="off"
                    />
                    <mat-placeholder class="center">Patient Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a
                    matTooltip="Click to View Patient"
                    class="eyeCursorclass"
                    [routerLink]="
                      '/patientCreateEditTab/' +
                      element?.patientId +
                      '/' +
                      element?.id
                    "
                    target="_blank"
                  >
                    {{ element?.defaultPatientId }}
                  </a>
                </td>
              </ng-container> -->
<!--Patient Name-->
<!-- <ng-container matColumnDef="patientName">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                > -->
<!-- Patient Name -->
<!-- <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="patientName"
                      autocomplete="off"
                    />
                    <mat-placeholder class="center"
                      >Patient Name</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.patientName }}
                </td>
              </ng-container> -->
<!-- Assigned By-->
<!-- <ng-container matColumnDef="assignedBy">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="assignedBy"
                      autocomplete="off"
                    />
                    <mat-placeholder class="center"
                      >Assigned By</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.assignedByName ? element?.assignedByName : "-" }}
                </td>
              </ng-container> -->
<!--CSR-->
<!-- <ng-container matColumnDef="csr">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                > -->
<!-- <mat-checkbox #isAssinged></mat-checkbox> -->
<!-- Assigned By -->
<!-- <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="csr"
                      autocomplete="off"
                    />
                    <mat-placeholder class="center"> CSR</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.csrName ? element?.csrName : "-" }}
                </td>
              </ng-container> -->
<!-- Agent Name-->
<!-- <ng-container matColumnDef="agentName">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                > -->
<!-- Agent -->
<!-- <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="agentName"
                      autocomplete="off"
                    />
                    <mat-placeholder class="center">
                      Agent Name</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.agentName ? element?.agentName : "-" }}
                </td>
              </ng-container> -->
<!-- Assigned Date -->
<!-- <ng-container matColumnDef="assignedDate">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                > -->
<!-- Assigned Date -->
<!-- <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="assignedDate"
                      autocomplete="off"
                    />
                    <mat-placeholder class="center"
                      >Assigned Date</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.assignedDate | date : "MM/dd/yyyy" : "en_US" }}
                </td>
              </ng-container> -->
<!--WIP Status-->
<!-- <ng-container matColumnDef="wipStatus">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                > -->
<!-- Wip Status -->
<!-- <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="wipStatus"
                      autocomplete="off"
                    />
                    <mat-placeholder class="center">Wip Status</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div [ngSwitch]="element?.wipStatus">
                    <div *ngSwitchCase="'yetToStart'">Yet To Start</div>
                    <div *ngSwitchCase="'hold'">Hold</div>
                    <div *ngSwitchCase="'inProcess'">In Process</div>
                    <div *ngSwitchCase="'completed'">Completed</div>
                    <div *ngSwitchCase="'NewSetupAssigned'">
                      New Setup Assigned
                    </div>
                    <div *ngSwitchCase="'NewSetupPending'">
                      New Setup Pending
                    </div>
                    <div *ngSwitchCase="'NewSetupInprogress'">
                      New Setup InProgress
                    </div>
                    <div *ngSwitchCase="'NewSetupCompleted'">
                      New Setup Completed
                    </div>
                    <div *ngSwitchCase="'VerificationAssigned'">
                      Verification Assigned
                    </div>
                    <div *ngSwitchCase="'VerificationPending'">
                      Verification Pending
                    </div>
                    <div *ngSwitchCase="'VerificationInprogress'">
                      Verification InProgress
                    </div>
                    <div *ngSwitchCase="'VerificationCompleted'">
                      Verification Completed
                    </div>
                    <div *ngSwitchCase="'AuthorizationAssigned'">
                      Authorization Assigned
                    </div>
                    <div *ngSwitchCase="'AuthorizationPending'">
                      Authorization Pending
                    </div>
                    <div *ngSwitchCase="'AuthorizationInprogress'">
                      Authorization InProgress
                    </div>
                    <div *ngSwitchCase="'AuthorizationCompleted'">
                      Authorization Completed
                    </div>
                    <div *ngSwitchDefault></div>
                  </div> -->
<!-- {{ element?.wipStatus }} -->
<!-- </td>
              </ng-container> -->
<!--Options-->
<!-- <ng-container matColumnDef="action"> -->
<!-- <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  Action
                </th>
                <td mat-cell *matCellDef="let element"> -->
<!-- <div [ngSwitch]="element?.wipStatus">
                    <div *ngSwitchCase="'yetToStart'">
                      <button mat-mini-fab class="wip-status-color">
                        <mat-icon
                          matTooltip="Click To Start"
                          (click)="statusUpdate(element?.id, 'inProcess')"
                          class="wip-success-status-color"
                          disabled
                          >play_circle_outline</mat-icon
                        >
                      </button>
                    </div>
                    <div *ngSwitchCase="'inProcess'">
                      <button mat-mini-fab class="wip-status-color">
                        <mat-icon
                          matTooltip="Click To Pause"
                          (click)="statusUpdate(element?.id, 'hold')"
                          class="wip-info-status-color"
                          disabled
                          >pause_circle_outline</mat-icon
                        >
                      </button>
                    </div>
                    <div *ngSwitchCase="'hold'">
                      <button mat-mini-fab class="wip-status-color">
                        <mat-icon
                          matTooltip="Click To Resume"
                          (click)="statusUpdate(element?.id, 'inProcess')"
                          class="wip-success-status-color"
                          disabled
                          >play_circle_outline</mat-icon
                        >
                      </button>
                      <button mat-mini-fab class="wip-status-color">
                        <mat-icon
                          matTooltip="Click To Complete"
                          (click)="statusUpdate(element?.id, 'completed')"
                          class="wip-danger-status-color"
                          disabled
                          >check_circle_outline</mat-icon
                        >
                      </button>
                    </div>
                    <div *ngSwitchCase="'completed'"></div>
                    <div *ngSwitchDefault></div>
                  </div> -->
<!-- <div class="row">
                    <button mat-mini-fab class="wip-status-color">
                      <mat-icon
                        (click)="
                          openWipAllocationModal(
                            element.id,
                            element.documentId,
                            element.patientId,
                            element.wipId
                          )
                        "
                        class="custom-assign-icon"
                        matTooltip="Click To Assign"
                        >supervisor_account</mat-icon
                      >
                    </button>
                    <button mat-mini-fab class="wip-status-color pr-2">
                      <mat-icon
                        matTooltip="Click To Complete"
                        class="wip-danger-status-color"
                        disabled
                        (click)="updateWipComplete(element.id)"
                        >check_circle_outline</mat-icon
                      >
                    </button>
                  </div>
                </td>
              </ng-container> -->
<!-- No Records-->
<!-- <ng-container matColumnDef="noRecords">
                <td
                  class="text-align-center"
                  mat-footer-cell
                  *matFooterCellDef
                  colspan="6"
                >
                  <div *ngIf="!isLoading">
                    {{ "No Records Found" }}
                  </div>
                  <div [hidden]="!isLoading">
                    <mat-spinner [diameter]="220"></mat-spinner>
                  </div>
                </td>
              </ng-container> -->

<!-- <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: arrDisplayedColumns"
              ></tr>
            </table>
            <div
              *ngIf="
                !(
                  dataSource &&
                  dataSource?.data &&
                  dataSource?.data?.length !== 0
                )
              "
              class="text-align-center"
            >
              <div class="message py-3" *ngIf="!isLoading">
                {{ "No Records Found" }}
              </div> -->
<!-- <div class="py-3" [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div> -->
<!-- <div class="py-3" [hidden]="!isLoading">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </div>
            <mat-paginator
              [pageSizeOptions]="[10, 20, 50, 100]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->

<!-- <ng-template #myDialog>
  <h2 matDialogTitle>Comments</h2>
  <mat-dialog-content>
    <div class="row">
      <div class="col-12 pb-5">
        <div
          class="table table-responsive example-container mat-elevation-z4 mb-0 pb-0"
        >
          <table
            mat-table
            id="wipCommentsTable"
            class="pt-0 mt-0"
            matTableExporter
            #exporter="matTableExporter"
            [hiddenColumns]="[14]"
            [dataSource]="dataSourceComments"
          >
            <ng-container matColumnDef="comments">
              <th class="header" mat-header-cell *matHeaderCellDef>Comments</th>
              <td mat-cell *matCellDef="let element">
                {{ element.comments }}
              </td>
            </ng-container>

            <ng-container matColumnDef="userName">
              <th class="header" mat-header-cell *matHeaderCellDef>
                User Name
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.userName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="commentsDate">
              <th class="header" mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element?.commentsDate }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="arrDisplayedCommentsColumns"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: arrDisplayedCommentsColumns"
            ></tr>
          </table>
          <div
            *ngIf="
              !(
                dataSource &&
                dataSource?.data &&
                dataSource?.data?.length !== 0
              )
            "
            class="no-records text-align-center"
          >
            <div class="message py-3" *ngIf="!isLoading">
              {{ "No Records Found" }}
            </div>
            <div class="py-3" [hidden]="!isLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </div>
        </div>
        <mat-paginator
          #MatPaginator
          [length]="dataSourceComments?.data?.length"
          class="mat-elevation-z2"
          [pageSize]="10"
          [pageIndex]="0"
          showFirstLastButtons
          [pageSizeOptions]="[5, 10, 25, 100]"
        >
        </mat-paginator>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="warn" matDialogClose>Close</button>
  </mat-dialog-actions>
</ng-template> -->

<div class="container-fluid pl-4 pr-4">
  <div class="row bg">
    <div class="col-lg-6">
      <h3 class="font-weight-bolder mb-0"><b>Patient / Work In Progress</b></h3>
    </div>
    <div class="col-lg-6 text-right">
      <form [formGroup]="branchForm">
        <mat-form-field class="pr-3">
          <mat-label>Branch</mat-label>
          <mat-select
            #selectBranch
            formControlName="drpBranch"
            multiple
            [(value)]="organizationUnitName"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No Matches found"
                formControlName="txtBranch"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option #selectAllBranch (click)="allBranchSelect()" value="0"
              >All</mat-option
            >

            <mat-option
              *ngFor="let branch of brnachList | async"
              [value]="branch.id"
            >
              {{ branch.organizationUnitName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 pb-2">
      <ul
        class="card card-body"
        ngbNav
        #nav="ngbNav"
        justify="justified"
        class="alert-success nav nav-pills nav-fill"
        style="border-bottom: 3px solid rgb(201, 201, 201)"
      >
        <li
          ngbNavItem="NewSetup"
          [class.selected-tab]="nav.activeId === 'NewSetup'"
        >
          <a ngbNavLink (click)="changeTab('NewSetup')"><b>New Setup</b></a>
        </li>
        <li
          ngbNavItem="Supplies"
          [class.selected-tab]="nav.activeId === 'Supplies'"
        >
          <a ngbNavLink id="tab-3" (click)="changeTab('Supplies')"
            ><b>Supplies</b></a
          >
        </li>
      </ul>
    </div>
  </div>
  <br>
  <mat-accordion>
    <mat-expansion-panel (toggle)="step" [expanded]="step" (afterExpand)="toggle()" (closed)="toggleV2()">
        <mat-expansion-panel-header>
      <label class="wipCountHeader customThemeClass"
        ><b>WIP Status Count</b></label>
    </mat-expansion-panel-header>
  <div class="row">
    <div class="col-xl-12 pb-2">
      <div class="row">
        <div class="col-lg-3">
          <div class="candidates-feature-info shadow-sm border-10">
            <div class="row">
              <div class="col-lg-12">
                <div class="candidates-info-contents">
                  <mat-card class="text-left"
                    ><b>Yet To Start - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="candidates-info-contents">
                  <mat-card class="text-left"
                    ><b>Hold - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="candidates-info-contents">
                  <mat-card class="text-left"
                    ><b>Completed - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="candidates-feature-info shadow-sm border-10">
            <div class="row">
              <label class="wipCountHeader customThemeClass"
                ><b>New Setup</b></label
              >
              <div class="col-lg-12">
                <div class="candidates-info-content">
                  <mat-card class="text-left"
                    ><b>Assigned - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="candidates-info-content">
                  <mat-card class="text-left"
                    ><b>Pending - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="candidates-info-content">
                  <mat-card class="text-left"
                    ><b>Completed - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="candidates-feature-info shadow-sm border-10">
            <div class="row">
              <label class="wipCountHeader customThemeClass"
                ><b>Verification</b></label
              >
              <div class="col-lg-12">
                <div class="candidates-info-content">
                  <mat-card class="text-left"
                    ><b>Assigned - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="candidates-info-content">
                  <mat-card class="text-left"
                    ><b>Pending - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="candidates-info-content">
                  <mat-card class="text-left"
                    ><b>Completed - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="candidates-feature-info shadow-sm border-10">
            <div class="row">
              <label class="wipCountHeader customThemeClass"
                ><b>Authorization</b></label
              >
              <div class="col-lg-12">
                <div class="candidates-info-content">
                  <mat-card class="text-left"
                    ><b>Assigned - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="candidates-info-content">
                  <mat-card class="text-left"
                    ><b>Pending - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="candidates-info-content">
                  <mat-card class="text-left"
                    ><b>Completed - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="candidates-feature-info shadow-sm border-10">
            <div class="row">
              <label class="wipCountHeader customThemeClass"
                ><b>Order</b></label
              >
              <div class="col-lg-12">
                <div class="candidates-info-content">
                  <mat-card class="text-left"
                    ><b>Assigned - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="candidates-info-content">
                  <mat-card class="text-left"
                    ><b>Pending - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="candidates-info-content">
                  <mat-card class="text-left"
                    ><b>Completed - </b><b class="customThemeClass">10</b>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
</mat-accordion>
  <hr />
  <div class="row">
    <div class="col-xl-12 pb-4">
      <!-- NEW SETUP TAB-->
      <div *ngIf="activeTab === 'NewSetup'">
        <!-- Filter -->
        <div class="pb-4 float-left">
          <form [formGroup]="wipFilterForm">
            <div class="col-lg-12 pt-0">
              <div class="row mt-0">
                <mat-form-field class="pr-3">
                  <mat-label>Users</mat-label>
                  <mat-select
                    multiple
                    #userSelect
                    formControlName="txtUser"
                    [(value)]="userFilterValue"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtUserFilter"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      #allUsersSelected
                      [value]="0"
                      (click)="toggleAllUsersSelection()"
                      >Select All Users</mat-option
                    >
                    <mat-option
                      *ngFor="let users of filteredUsers | async"
                      [value]="users?.userId"
                    >
                      {{ users?.userDesignation }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="pr-3">
                  <mat-label>Status</mat-label>
                  <mat-select
                    multiple
                    formControlName="txtStatus"
                    [(value)]="statusFilterValue"
                    #statusSelect
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtWipStatus"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      #allStatusSelected
                      [value]="0"
                      (click)="toggleAllStatusSelection()"
                      >Select All Status</mat-option
                    >
                    <mat-option
                      *ngFor="let status of filteredWipStatus | async"
                      [value]="status?.id"
                    >
                      {{ status?.wipStatus }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="pr-3 pt-1">
                  <button
                    class="buttonColor font-size-14 mr-2"
                    (click)="filterNewSetupWIPList()"
                    mat-button
                  >
                    Search
                  </button>
                  <button
                    mat-button
                    (click)="resetNewSetupForm()"
                    class="resetclr mr-2"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="pb-4 float-right">
          <button
            matTooltip="Click To Assign"
            mat-button
            (click)="openMultipleWIPAllocationModal()"
            class="buttonColor mr-2 font-size-10"
            [disabled]="newSetupCount === 0 ? true : false"
          >
            <mat-icon class="mb-1">supervisor_account</mat-icon>
            Allot WIP
          </button>
          <button
            class="mr-2 buttonColor"
            [disabled]="
              dataSource && dataSource?.data && dataSource?.data?.length === 0
            "
            mat-raised-button
            (click)="
              exporter.exportTable('xlsx', {
                fileName: 'WIP List New Setup',
                sheet: 'AuthorizationList',
                Props: { Author: 'QSecure' }
              })
            "
          >
            Export
          </button>
        </div>
        <!-- New Setup Table -->
        <form [formGroup]="wipSearchForm">
          <div
            class="table table-responsive example-container mat-elevation-z4 pt-0 mt-0"
          >
            <table
              mat-table
              [dataSource]="dataSource"
              [hiddenColumns]="[0]"
              matTableExporter
              #exporter="matTableExporter"
              matSort
              class="pt-0 mt-0"
              style="margin-left: 15px !important"
            >
              <!-- Document Id -->
              <ng-container matColumnDef="defaultDocumentId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Doc Id -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="documentId"
                      autocomplete="new-documentId"
                    />
                    <mat-placeholder class="center"> Doc Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a
                    matTooltip="Click to View Document"
                    class="eyeCursorclass"
                    [routerLink]="
                      '/ViewDocumentPerviewByBlobId/' +
                      element?.blobName +
                      '/' +
                      5
                    "
                    target="_blank"
                  >
                    {{ element?.defaultDocumentId }}
                  </a>
                </td>
              </ng-container>
              <!-- isStat-->
              <ng-container matColumnDef="isStat">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>STAT</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    {{ element.isStat == 1 ? "Yes" : "No" }}
                  </ng-container>
                </td>
              </ng-container>

              <!-- WIP ID-->
              <ng-container matColumnDef="wipId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- WIP Id -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="wipId"
                      autocomplete="new-wipId"
                    />
                    <mat-placeholder class="center">WIP Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a
                    matTooltip="Click to View WIP List"
                    class="eyeCursorclass"
                    (click)="openWipDetailsModal(element.id, element.wipId)"
                  >
                    {{ element?.wipId }}
                  </a>
                </td>
              </ng-container>
              <!--Patient Id-->
              <ng-container matColumnDef="defaultPatientId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Patient Id -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="defaultPatientId"
                      autocomplete="new-defaultPatientId"
                    />
                    <mat-placeholder class="center">Patient Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a
                    matTooltip="Click to View Patient"
                    class="eyeCursorclass"
                    [routerLink]="
                      '/patientCreateEditTab/' +
                      element?.patientId +
                      '/' +
                      element?.id
                    "
                    target="_blank"
                  >
                    {{ element?.defaultPatientId }}
                  </a>
                </td>
              </ng-container>
              <!--Patient Name-->
              <ng-container matColumnDef="patientName">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Patient Name -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="patientName"
                      autocomplete="new-patientName"
                    />
                    <mat-placeholder class="center"
                      >Patient Name</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.patientName }}
                </td>
              </ng-container>
              <!-- Agent Name-->
              <ng-container matColumnDef="agentName">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Agent -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="agentName"
                      autocomplete="new-agentName"
                    />
                    <mat-placeholder class="center">
                      Agent Name</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.agentName ? element?.agentName : "-" }}
                </td>
              </ng-container>
              <!--CSR-->
              <ng-container matColumnDef="csr">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- <mat-checkbox #isAssinged></mat-checkbox> -->
                  <!-- Assigned By -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="csr"
                      autocomplete="new-csr"
                    />
                    <mat-placeholder class="center"> CSR</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.csrName ? element?.csrName : "-" }}
                </td>
              </ng-container>
              <!-- Branch -->
              <ng-container matColumnDef="branch">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="branch"
                    />
                    <mat-placeholder class="center">Branch</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">-</td>
              </ng-container>
              <!-- Assigned By-->
              <ng-container matColumnDef="assignedBy">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="assignedBy"
                      autocomplete="new-assignedBy"
                    />
                    <mat-placeholder class="center"
                      >Assigned By</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.assignedByName ? element?.assignedByName : "-" }}
                </td>
              </ng-container>
              <!-- Assigned Date -->
              <ng-container matColumnDef="assignedDate">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Assigned Date -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="assignedDate"
                      autocomplete="new-assignedDate"
                    />
                    <mat-placeholder class="center"
                      >Assigned Date</mat-placeholder
                    >
                    <mat-error
                      *ngIf="
                        wipSearchForm?.get('assignedDate')?.errors?.dateVaidator
                      "
                    >
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.assignedDate | date : "MM/dd/yyyy" : "en_US" }}
                </td>
              </ng-container>
              <!--WIP Status-->
              <ng-container matColumnDef="wipStatus">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Wip Status -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="wipStatus"
                      autocomplete="new-wipStatus"
                    />
                    <mat-placeholder class="center">Wip Status</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <!-- <div [ngSwitch]="element?.wipStatus">
                    <div *ngSwitchCase="'yetToStart'">Yet To Start</div>
                    <div *ngSwitchCase="'hold'">Hold</div>
                    <div *ngSwitchCase="'inProcess'">In Process</div>
                    <div *ngSwitchCase="'completed'">Completed</div>
                    <div *ngSwitchCase="'NewSetupAssigned'">
                      New Setup Assigned
                    </div>
                    <div *ngSwitchCase="'NewSetupPending'">
                      New Setup Pending
                    </div>
                    <div *ngSwitchCase="'NewSetupInprogress'">
                      New Setup InProgress
                    </div>
                    <div *ngSwitchCase="'NewSetupCompleted'">
                      New Setup Completed
                    </div>
                    <div *ngSwitchCase="'VerificationAssigned'">
                      Verification Assigned
                    </div>
                    <div *ngSwitchCase="'VerificationPending'">
                      Verification Pending
                    </div>
                    <div *ngSwitchCase="'VerificationInprogress'">
                      Verification InProgress
                    </div>
                    <div *ngSwitchCase="'VerificationCompleted'">
                      Verification Completed
                    </div>
                    <div *ngSwitchCase="'AuthorizationAssigned'">
                      Authorization Assigned
                    </div>
                    <div *ngSwitchCase="'AuthorizationPending'">
                      Authorization Pending
                    </div>
                    <div *ngSwitchCase="'AuthorizationInprogress'">
                      Authorization InProgress
                    </div>
                    <div *ngSwitchCase="'AuthorizationCompleted'">
                      Authorization Completed
                    </div>
                    <div *ngSwitchDefault></div>
                  </div> -->
                  {{ element?.wipStatusName }}
                </td>
              </ng-container>
              <!--Options-->
              <ng-container matColumnDef="option" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  Options
                </th>
                <td mat-cell *matCellDef="let element">
                  <!-- <div [ngSwitch]="element?.wipStatus">
                <div *ngSwitchCase="'yetToStart'">
                  <button mat-mini-fab class="wip-status-color">
                    <mat-icon
                      matTooltip="Click To Start"
                      (click)="statusUpdate(element?.id, 'inProcess')"
                      class="wip-success-status-color"
                      disabled
                      >play_circle_outline</mat-icon
                    >
                  </button>
                </div>
                <div *ngSwitchCase="'inProcess'">
                  <button mat-mini-fab class="wip-status-color">
                    <mat-icon
                      matTooltip="Click To Pause"
                      (click)="statusUpdate(element?.id, 'hold')"
                      class="wip-info-status-color"
                      disabled
                      >pause_circle_outline</mat-icon
                    >
                  </button>
                </div>
                <div *ngSwitchCase="'hold'">
                  <button mat-mini-fab class="wip-status-color">
                    <mat-icon
                      matTooltip="Click To Resume"
                      (click)="statusUpdate(element?.id, 'inProcess')"
                      class="wip-success-status-color"
                      disabled
                      >play_circle_outline</mat-icon
                    >
                  </button>
                  <button mat-mini-fab class="wip-status-color">
                    <mat-icon
                      matTooltip="Click To Complete"
                      (click)="statusUpdate(element?.id, 'completed')"
                      class="wip-danger-status-color"
                      disabled
                      >check_circle_outline</mat-icon
                    >
                  </button>
                </div>
                <div *ngSwitchCase="'completed'"></div>
                <div *ngSwitchDefault></div>
              </div> -->
                  <div class="row">
                    <button mat-mini-fab class="wip-status-color">
                      <mat-icon
                        (click)="
                          openWipAllocationModal(
                            element.id,
                            element.documentId,
                            element.patientId,
                            element.wipId
                          )
                        "
                        class="custom-assign-icon"
                        matTooltip="Click To Assign"
                        >supervisor_account</mat-icon
                      >
                    </button>
                    <button mat-mini-fab class="wip-status-color pr-2">
                      <mat-icon
                        matTooltip="Click To Complete"
                        class="wip-danger-status-color"
                        disabled
                        (click)="updateWipComplete(element.id)"
                        >check_circle_outline</mat-icon
                      >
                    </button>
                  </div>
                </td>
              </ng-container>
              <!--Action-->
              <ng-container matColumnDef="action" sticky>
                <th class="header" mat-header-cell *matHeaderCellDef>
                  <!-- <mat-checkbox
                    class="mt-2"
                    (change)="selectNewSetupAll($event)"
                  >
                  </mat-checkbox> -->
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox
                    id="{{ element?.id }}"
                    [checked]="element?.isSelected"
                    class="mr-3 mt-2"
                    (change)="selectNewSetupWip($event, element?.id)"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>
              <!-- No Records-->
              <ng-container matColumnDef="noRecords">
                <td
                  class="text-align-center"
                  mat-footer-cell
                  *matFooterCellDef
                  colspan="6"
                >
                  <div *ngIf="!isLoading">
                    {{ "No Records Found" }}
                  </div>
                  <div [hidden]="!isLoading">
                    <mat-spinner [diameter]="220"></mat-spinner>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: arrDisplayedColumns"
              ></tr>
            </table>
            <div
              *ngIf="
                !(
                  dataSource &&
                  dataSource?.data &&
                  dataSource?.data?.length !== 0
                )
              "
              class="text-align-center"
            >
              <div class="message py-3" *ngIf="!isLoading">
                {{ "No Records Found" }}
              </div>
              <div class="py-3" [hidden]="!isLoading">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </div>
            <mat-paginator
              [pageSizeOptions]="[10, 20, 50, 100]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </form>
      </div>

      <!-- SUPPLIES TAB -->
      <div *ngIf="activeTab === 'Supplies'">
        <div class="pb-3 float-right">
          <button
            matTooltip="Click To Assign"
            mat-button
            class="buttonColor mr-2 font-size-10"
            [disabled]="suppliesCount === 0 ? true : false"
          >
            <mat-icon class="mb-1">supervisor_account</mat-icon>
            Allot WIP
          </button>
          <button
            class="mr-2 buttonColor"
            [disabled]="
              dataSourceSupplies &&
              dataSourceSupplies?.data &&
              dataSourceSupplies?.data?.length === 0
            "
            mat-raised-button
            (click)="
              exporter.exportTable('xlsx', {
                fileName: 'Supplies WIP List',
                sheet: 'suppliesWIPList',
                Props: { Author: 'QSecure' }
              })
            "
          >
            Export
          </button>
        </div>

        <!-- form -->
        <div class="pb-4 float-left">
          <form [formGroup]="wipSuppliesFilterForm">
            <div class="col-lg-12 pt-0">
              <div class="row mt-0">
                <mat-form-field class="pr-3">
                  <mat-label>Users</mat-label>
                  <mat-select
                    multiple
                    #userSelect
                    formControlName="txtSuppliesUser"
                    [(value)]="suppliesUserFilterValue"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtSuppliesUserFilter"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      #allUsersSelected
                      [value]="0"
                      (click)="toggleAllUsersSelection()"
                      >Select All Users</mat-option
                    >
                    <mat-option
                      *ngFor="let users of suppliesFilteredUsers | async"
                      [value]="users?.userId"
                    >
                      {{ users?.userDesignation }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="pr-3">
                  <mat-label>Status</mat-label>
                  <mat-select
                    multiple
                    formControlName="txtSuppliesStatus"
                    [(value)]="suppliesStatusFilterValue"
                    #statusSelect
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtSuppliesStatusFilter"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      #allStatusSelected
                      [value]="0"
                      (click)="toggleAllStatusSelection()"
                      >Select All Status</mat-option
                    >
                    <mat-option
                      *ngFor="let status of filteredWipStatus | async"
                      [value]="status?.id"
                    >
                      {{ status?.wipStatus }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="pr-3">
                  <button
                    class="buttonColor font-size-14 mr-2"
                    (click)="filterSuppliesList()"
                    mat-button
                  >
                    Search
                  </button>
                  <button
                    mat-button
                    (click)="resetSuppliesForm()"
                    class="resetclr mr-2"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div
          class="table table-responsive example-container mat-elevation-z4 pt-0 mt-0"
        >
          <form [formGroup]="wipSuppliesSearchForm">
            <table
              mat-table
              [dataSource]="dataSourceSupplies"
              matTableExporter
              #exporter="matTableExporter"
              matSort
              [hiddenColumns]="[0]"
              class="pt-0 mt-0"
              style="margin-left: 15px !important"
            >
              <!-- sale order Id -->
              <ng-container matColumnDef="sDefaultSaleOrderId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- saleOrder Id -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="sDefaultSaleOrderId"
                      autocomplete="new-sDefaultSaleOrderId"
                    />
                    <mat-placeholder class="center">
                      Sale Order Id</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a
                    matTooltip="Click to View  Order"
                    class="eyeCursorclass"
                    [routerLink]="
                      '/orders/saleorderCreateEditTab/' +
                      element?.patientId +
                      '/' +
                      element?.saleOrderId
                    "
                    target="_blank"
                  >
                    {{ element?.defaultSaleOrderId }}
                  </a>
                </td>
              </ng-container>
              <!-- isStat-->
              <ng-container matColumnDef="isStat">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>STAT</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    {{ element.isStat == 1 ? "Yes" : "No" }}
                  </ng-container>
                </td>
              </ng-container>

              <!-- WIP ID-->
              <ng-container matColumnDef="sWipId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- WIP Id -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="sWipId"
                      autocomplete="new-sWipId"
                    />
                    <mat-placeholder class="center">WIP Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <!-- <a
                        (click)="
                          scheduleWIPDetailsById(element); $event.preventDefault()
                        "
                        matTooltip="Click to Schedule"
                        class="eyeCursorclass"
                        target="_blank"
                        rel="noopener"
                      >
                    </a> -->

                  <a
                    matTooltip="Click to View WIP List"
                    class="eyeCursorclass"
                    (click)="openWipDetailsModal(element.id, element.wipId)"
                  >
                    {{ element?.wipId }}
                  </a>
                </td>
              </ng-container>
              <!--Patient Id-->
              <ng-container matColumnDef="sDefaultPatientId" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Patient Id -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="sDefaultPatientId"
                      autocomplete="new-sDefaultPatientId"
                    />
                    <mat-placeholder class="center">Patient Id</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a
                    matTooltip="Click to View Patient"
                    class="eyeCursorclass"
                    [routerLink]="
                      '/patientCreateEditTab/' +
                      element?.patientId +
                      '/' +
                      element?.id
                    "
                    target="_blank"
                  >
                    {{ element?.defaultPatientId }}
                  </a>
                </td>
              </ng-container>
              <!--Branch-->
              <ng-container matColumnDef="sbranch">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="sbranch"
                      autocomplete="new-sbranch"
                    />
                    <mat-placeholder class="center">Branch</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">-</td>
              </ng-container>
              <!--Patient Name-->
              <ng-container matColumnDef="sPatientName">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Patient Name -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="sPatientName"
                      autocomplete="new-sPatientName"
                    />
                    <mat-placeholder class="center"
                      >Patient Name</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.patientName }}
                </td>
              </ng-container>
              <!-- Assigned By-->
              <ng-container matColumnDef="sAssignedBy">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="sAssignedBy"
                      autocomplete="new-sAssignedBy"
                    />
                    <mat-placeholder class="center"
                      >Assigned By</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.assignedByName ? element?.assignedByName : "-" }}
                </td>
              </ng-container>
              <!--CSR-->
              <ng-container matColumnDef="sCsr">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- <mat-checkbox #isAssinged></mat-checkbox> -->
                  <!-- Assigned By -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="sCsr"
                      autocomplete="new-sCsr"
                    />
                    <mat-placeholder class="center"> CSR</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.csrName ? element?.csrName : "-" }}
                </td>
              </ng-container>
              <!-- Agent Name-->
              <ng-container matColumnDef="sAgentName">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Agent -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="sAgentName"
                      autocomplete="new-sAgentName"
                    />
                    <mat-placeholder class="center">
                      Agent Name</mat-placeholder
                    >
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.agentName ? element?.agentName : "-" }}
                </td>
              </ng-container>
              <!-- Assigned Date -->
              <ng-container matColumnDef="sAssignedDate">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Assigned Date -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="sAssignedDate"
                      autocomplete="new-sAssignedDate"
                    />
                    <mat-placeholder class="center"
                      >Assigned Date</mat-placeholder
                    >
                    <mat-error
                      *ngIf="
                        wipSuppliesSearchForm?.get('sAssignedDate')?.errors
                          ?.dateVaidator
                      "
                    >
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element?.assignedDate | date : "MM/dd/yyyy" : "en_US" }}
                </td>
              </ng-container>
              <!--WIP Status-->
              <ng-container matColumnDef="sWipStatus">
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  <!-- Wip Status -->
                  <mat-form-field class="filter">
                    <input
                      matInput
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                      formControlName="sWipStatus"
                      autocomplete="new-sWipStatus"
                    />
                    <mat-placeholder class="center">Wip Status</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div [ngSwitch]="element?.wipStatus">
                    <div *ngSwitchCase="'yetToStart'">Yet To Start</div>
                    <div *ngSwitchCase="'hold'">Hold</div>
                    <div *ngSwitchCase="'inProcess'">In Process</div>
                    <div *ngSwitchCase="'completed'">Completed</div>
                    <div *ngSwitchCase="'NewSetupAssigned'">
                      New Setup Assigned
                    </div>
                    <div *ngSwitchCase="'NewSetupPending'">
                      New Setup Pending
                    </div>
                    <div *ngSwitchCase="'NewSetupInprogress'">
                      New Setup InProgress
                    </div>
                    <div *ngSwitchCase="'NewSetupCompleted'">
                      New Setup Completed
                    </div>
                    <div *ngSwitchCase="'VerificationAssigned'">
                      Verification Assigned
                    </div>
                    <div *ngSwitchCase="'VerificationPending'">
                      Verification Pending
                    </div>
                    <div *ngSwitchCase="'VerificationInprogress'">
                      Verification InProgress
                    </div>
                    <div *ngSwitchCase="'VerificationCompleted'">
                      Verification Completed
                    </div>
                    <div *ngSwitchCase="'AuthorizationAssigned'">
                      Authorization Assigned
                    </div>
                    <div *ngSwitchCase="'AuthorizationPending'">
                      Authorization Pending
                    </div>
                    <div *ngSwitchCase="'AuthorizationInprogress'">
                      Authorization InProgress
                    </div>
                    <div *ngSwitchCase="'AuthorizationCompleted'">
                      Authorization Completed
                    </div>
                    <div *ngSwitchDefault></div>
                  </div>
                  <!-- {{ element?.wipStatus }} -->
                </td>
              </ng-container>
              <!--Options-->
              <ng-container matColumnDef="option" sticky>
                <th
                  class="header"
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                >
                  Options
                </th>
                <td mat-cell *matCellDef="let element">
                  <!-- <div [ngSwitch]="element?.wipStatus">
                        <div *ngSwitchCase="'yetToStart'">
                          <button mat-mini-fab class="wip-status-color">
                            <mat-icon
                              matTooltip="Click To Start"
                              (click)="statusUpdate(element?.id, 'inProcess')"
                              class="wip-success-status-color"
                              disabled
                              >play_circle_outline</mat-icon
                            >
                          </button>
                        </div>
                        <div *ngSwitchCase="'inProcess'">
                          <button mat-mini-fab class="wip-status-color">
                            <mat-icon
                              matTooltip="Click To Pause"
                              (click)="statusUpdate(element?.id, 'hold')"
                              class="wip-info-status-color"
                              disabled
                              >pause_circle_outline</mat-icon
                            >
                          </button>
                        </div>
                        <div *ngSwitchCase="'hold'">
                          <button mat-mini-fab class="wip-status-color">
                            <mat-icon
                              matTooltip="Click To Resume"
                              (click)="statusUpdate(element?.id, 'inProcess')"
                              class="wip-success-status-color"
                              disabled
                              >play_circle_outline</mat-icon
                            >
                          </button>
                          <button mat-mini-fab class="wip-status-color">
                            <mat-icon
                              matTooltip="Click To Complete"
                              (click)="statusUpdate(element?.id, 'completed')"
                              class="wip-danger-status-color"
                              disabled
                              >check_circle_outline</mat-icon
                            >
                          </button>
                        </div>
                        <div *ngSwitchCase="'completed'"></div>
                        <div *ngSwitchDefault></div>
                      </div> -->
                  <div class="row">
                    <button mat-mini-fab class="wip-status-color">
                      <mat-icon
                        (click)="
                          openWipAllocationModal(
                            element.id,
                            element.documentId,
                            element.patientId,
                            element.wipId
                          )
                        "
                        class="custom-assign-icon"
                        matTooltip="Click To Assign"
                        >supervisor_account</mat-icon
                      >
                    </button>
                    <button mat-mini-fab class="wip-status-color pr-2">
                      <mat-icon
                        matTooltip="Click To Complete"
                        class="wip-danger-status-color"
                        disabled
                        (click)="updateWipComplete(element.id)"
                        >check_circle_outline</mat-icon
                      >
                    </button>
                  </div>
                </td>
              </ng-container>
              <!--Action-->
              <ng-container matColumnDef="action" sticky>
                <th class="header" mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    class="mt-2"
                    (change)="selectSuppliesAll($event)"
                  >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox
                    [checked]="element?.isSelected"
                    class="mr-3 mt-2"
                    (change)="selectSuppliesWip($event, element?.id)"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>
              <!-- No Records-->
              <ng-container matColumnDef="noRecords">
                <td
                  class="text-align-center"
                  mat-footer-cell
                  *matFooterCellDef
                  colspan="6"
                >
                  <div *ngIf="!isLoading">
                    {{ "No Records Found" }}
                  </div>
                  <div [hidden]="!isLoading">
                    <mat-spinner [diameter]="220"></mat-spinner>
                  </div>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="arrDisplayedColumnsSupplies"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: arrDisplayedColumnsSupplies"
              ></tr>
            </table>
            <div
              *ngIf="
                !(
                  dataSourceSupplies &&
                  dataSourceSupplies?.data &&
                  dataSourceSupplies?.data?.length !== 0
                )
              "
              class="text-align-center"
            >
              <div class="message py-3" *ngIf="!isLoadingSupplies">
                {{ "No Records Found" }}
              </div>
              <div class="py-3" [hidden]="!isLoadingSupplies">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </div>
            <mat-paginator
              [pageSizeOptions]="[10, 20, 50, 100]"
              showFirstLastButtons
            ></mat-paginator>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
