<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <div class="page-title-box page-title-left pb-0">
          <h3 class="font-weight-bolder mb-3">
            <b class="customThemeClass">Master / Payor</b>
          </h3>
        </div>
        <div class="page-title-box page-title-right pb-0">
          <div class="d-flex align-items-center">
            <form class="form-horizontal" [formGroup]="searchForm">
              <mat-form-field class="col-12">
                <mat-label> Search Payor </mat-label>
                <input
                  type="text"
                  matInput
                  (ngModelChange)="statusFilter()"
                  formControlName="sSearch"
                  placeholder="PayorCode/PayorName"
                />
              </mat-form-field>
            </form>
            <mat-form-field class="p-2">
              <mat-label>Mode<span class="asterisk"> * </span></mat-label>
              <mat-select [(ngModel)]="drpModeType" (selectionChange)="statusFilter()">
                <mat-option
                    *ngFor="let mode of eligibilityModeDetails"
                    [value]="mode.id"
                  >
                    {{ mode.value }}
                  </mat-option>
                <!-- <mat-option (click)="statusFilter()" [value]="1"
                  >pVerify
                </mat-option>
                <mat-option (click)="statusFilter()" [value]="2"
                  >Zermid
                </mat-option>
                <mat-option (click)="statusFilter()" [value]="3"
                  >ChangeHealthCare
                </mat-option>
                <mat-option (click)="statusFilter()" [value]="4"
                  >Manual
                </mat-option> -->
              </mat-select>
            </mat-form-field>
            <mat-radio-group
              [(ngModel)]="selectedOption"
              class="radio-buttons"
              (change)="statusFilter()"
            >
              <mat-radio-button [value]="0" class="active"
                ><b>All</b></mat-radio-button
              >
              <mat-radio-button [value]="1" class="active p-2" [checked]="true"
                ><b>Active</b></mat-radio-button
              >
              <mat-radio-button [value]="2" class="active"
                ><b>Inactive</b></mat-radio-button
              >
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card card-body">
    <mat-accordion class="spaces">
      <mat-expansion-panel
        [expanded]="step"
        (afterExpand)="toggle()"
        (closed)="toggleV2()"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <b>
              <ng-container
                *ngIf="
                  payorId === '';
                  then thenTitleTemplate;
                  else elseTitleTemplate
                "
              ></ng-container>
              <ng-template #thenTitleTemplate> Add Payor </ng-template>
              <ng-template #elseTitleTemplate> Edit Payor </ng-template>
            </b>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-card-content>
          <form [formGroup]="payorForm">
            <div class="row">
              <mat-form-field class="col-3">
                <mat-label
                  >Eligibility Mode<span class="asterisk">*</span></mat-label
                >
                <mat-select
                  (selectionChange)="onSelect($event)"
                  [(ngModel)]="EligibilityPayor"
                  formControlName="selectEligibility"
                >
                  <mat-option
                    *ngFor="let mode of eligibilityModeDetails"
                    [value]="mode.id"
                  >
                    {{ mode.value }}
                  </mat-option>
                  <!-- <mat-option  [value]="1">
                      pVerify
                    </mat-option>
                    <mat-option  [value]="2">
                      Zermid
                    </mat-option>
                    <mat-option  [value]="3">
                      ChangeHealthCare
                    </mat-option> -->
                </mat-select>
                <mat-error
                  *ngIf="
                    payorForm?.get('selectEligibility')?.touched &&
                    payorForm?.get('selectEligibility')?.errors
                  "
                >
                  Insurance is a required field!
                </mat-error>
                <button
                  mat-button
                  (click)="pVerifyPayor = ''; $event.stopPropagation()"
                  *ngIf="payorForm?.get('selectEligibility')?.value"
                  matSuffix
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label
                  >Payor Code <span class="asterisk">*</span></mat-label
                >
                <input
                  autocomplete="new-txtPayorID"
                  matInput
                  maxlength="7"
                  formControlName="txtPayorID"
                  type="text"
                  pattern="[a-zA-Z0-9]*"
                />
                <mat-error
                  *ngIf="
                    payorForm?.get('txtPayorID')?.touched &&
                    payorForm?.get('txtPayorID')?.errors?.required
                  "
                >
                  Payor code is a required field!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label
                  >Payor Name <span class="asterisk">*</span></mat-label
                >
                <input
                  autocomplete="new-txtPayorName"
                  matInput
                  maxlength="75"
                  formControlName="txtPayorName"
                  type="text"
                />
                <mat-error
                  *ngIf="
                    payorForm?.get('txtPayorName')?.touched &&
                    payorForm?.get('txtPayorName')?.errors?.required
                  "
                >
                  Payor Name is a required field!
                </mat-error>
                <mat-error
                  *ngIf="
                    payorForm?.get('txtPayorName')?.touched &&
                    payorForm?.get('txtPayorName')?.errors
                  "
                >
                  Enter only characters!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label
                  >Insurance Category<span class="asterisk">*</span></mat-label
                >
                <mat-select
                  (selectionChange)="onSelect($event)"
                  formControlName="InsCategory"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      [formControl]="InsCategFilterControl"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let Ins of InsurenceCategoryList"
                    [value]="Ins.id"
                  >
                    {{ Ins.insuranceCategory }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    payorForm?.get('InsCategory')?.touched &&
                    payorForm?.get('InsCategory')?.errors?.required
                  "
                >
                  Insurance is a required field!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Code<span class="asterisk">*</span></mat-label>
                <input
                  autocomplete="new-txtPayorCode"
                  matInput
                  maxlength="40"
                  formControlName="txtPayorCode"
                  pattern="[A-Za-z\s!@#$%^&*()-_=+`~{}[\]|;:'\,.<>?]*"
                  type="text"
                />
                <mat-error
                  *ngIf="
                    payorForm?.get('txtPayorCode')?.touched &&
                    payorForm?.get('txtPayorCode')?.errors?.required
                  "
                >
                  Code is a required field!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label
                  >Fax Number <span class="asterisk">*</span></mat-label
                >
                <input
                  formControlName="txtFaxNumber"
                  matInput
                  maxlength="40"
                  prefix="+1-"
                  mask="(000)-000-0000"
                  type="text"
                  autocomplete="new-txtFaxNumber"
                />
                <mat-error
                  *ngIf="
                    payorForm?.get('txtFaxNumber')?.touched &&
                    payorForm?.get('txtFaxNumber')?.errors?.required
                  "
                >
                  Enter Minimum 10 Digits!
                </mat-error>
                <mat-error
                  *ngIf="
                    payorForm?.get('txtFaxNumber')?.errors?.isFaxNumberExists
                  "
                >
                  Fax Number is already taken!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label> Email Id <span class="asterisk">*</span></mat-label>
                <input
                  autocomplete="new-txtEmail"
                  matInput
                  maxlength="40"
                  formControlName="txtEmail"
                  type="text"
                  pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                  [textMask]="{ mask: emailMask }"
                />

                <mat-error
                  *ngIf="
                    payorForm?.get('txtEmail')?.touched &&
                    payorForm?.get('txtEmail')?.errors?.required
                  "
                >
                  Email Address is a required field!
                </mat-error>
                <mat-error
                  *ngIf="
                    payorForm?.get('txtEmail')?.touched &&
                    payorForm?.get('txtEmail')?.errors?.pattern
                  "
                >
                  Please Enter a Valid Email Address
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label
                  >Address -1 <span class="asterisk">*</span>
                </mat-label>
                <input
                  autocomplete="new-txtAddress"
                  maxlength="100"
                  formControlName="txtAddress"
                  rows="3"
                  matInput
                  oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');"
                />
                <mat-error
                  *ngIf="
                    payorForm?.get('txtAddress')?.touched &&
                    payorForm?.get('txtAddress')?.errors?.required
                  "
                >
                  Address -1 is a required field!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label>Address -2</mat-label>
                <input
                  autocomplete="new-txtAddress2"
                  maxlength="100"
                  formControlName="txtAddress2"
                  rows="3"
                  matInput
                  oninput="this.value = this.value.trim(); this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');"
                />
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label>State <span class="asterisk">*</span></mat-label>
                <mat-select
                  formControlName="drpState"
                  (selectionChange)="onChangeCity($event.value, '')"
                  [(value)]="State"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="drpStateFilter"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let state of filteredStates | async"
                    [value]="state.stateShortName"
                  >
                    {{ state.stateName }}
                  </mat-option>
                </mat-select>
                <!-- <button mat-button (click)="State='';$event.stopPropagation()" *ngIf="State" (click)="clearState()"
              matSuffix mat-icon-button>
              <mat-icon>close</mat-icon>
            </button> -->
                <mat-error
                  *ngIf="
                    payorForm?.get('drpState')?.touched &&
                    payorForm?.get('drpState')?.errors?.required
                  "
                >
                  State is a required field!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>City <span class="asterisk">*</span></mat-label>
                <mat-select
                  formControlName="drpCity"
                  [(value)]="City"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="drpCityFilter"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let city of filteredCities | async"
                    [value]="city.cityName"
                  >
                    {{ city.cityName }}
                  </mat-option>
                </mat-select>
                <!-- <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix (click)="clearCity()"
              mat-icon-button>
              <mat-icon>close</mat-icon>
            </button> -->
                <mat-error
                  *ngIf="
                    payorForm?.get('drpCity')?.touched &&
                    payorForm?.get('drpCity')?.errors?.required
                  "
                >
                  City is a required field!
                </mat-error>
              </mat-form-field>
              <!-- Zip Code -->
              <mat-form-field class="col-3">
                <mat-label> Zip Code <span class="asterisk">*</span></mat-label>
                <input
                  autocomplete="new-txtPostalCode"
                  matInput
                  maxlength="10"
                  formControlName="txtPostalCode"
                  pattern="[0-9-]*"
                  type="text"
                  class="zip-code-input"
                />
                <mat-error
                  *ngIf="
                    payorForm?.get('txtPostalCode')?.touched &&
                    payorForm?.get('txtPostalCode')?.errors?.pattern
                  "
                >
                  Enter only Numbers!
                </mat-error>
                <mat-error
                  *ngIf="
                    payorForm?.get('txtPostalCode')?.touched &&
                    payorForm?.get('txtPostalCode')?.errors?.required
                  "
                >
                  <!-- ZipCode is a required field! -->
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Contact Name </mat-label>
                <input
                  autocomplete="new-txtContactName"
                  matInput
                  maxlength="40"
                  formControlName="txtContactName"
                  type="text"
                />
                <mat-error
                  *ngIf="
                    payorForm?.get('txtContactName')?.touched &&
                    payorForm?.get('txtContactName')?.errors
                  "
                >
                  Enter only characters!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Phone Number </mat-label>
                <input
                  autocomplete="new-txtPhoneNumber"
                  matInput
                  prefix="+1-"
                  mask="(000)-000-0000"
                  maxlength="40"
                  placeholder="Enter Phone Number"
                  formControlName="txtPhoneNumber"
                  type="text"
                />
                <mat-error
                  *ngIf="
                    payorForm?.get('txtPhoneNumber')?.touched &&
                    payorForm?.get('txtPhoneNumber')?.errors
                  "
                >
                  Enter Minimum 10 Digits!
                </mat-error>
                <mat-error
                  *ngIf="
                    payorForm?.get('txtPhoneNumber')?.touched &&
                    payorForm?.get('txtPhoneNumber')?.errors?.required
                  "
                >
                  Phone Number is a required field!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>WrtOff Code </mat-label>
                <input
                  autocomplete="new-txtWrtOff"
                  oninput="this.value = this.value.toUpperCase()"
                  formControlName="txtWrtOff"
                  pattern="[A-Za-z]*"
                  maxlength="10"
                  matInput
                />
                <!-- pattern="[A-Za-z]*" only characters allowed -->
                <mat-error
                  *ngIf="
                    payorForm?.get('txtWrtOff')?.touched &&
                    payorForm?.get('txtWrtOff')?.errors?.required
                  "
                >
                  WrtOff Code is a required field!
                </mat-error>
                <mat-error
                  *ngIf="
                    payorForm?.get('txtWrtOff')?.touched &&
                    payorForm?.get('txtWrtOff')?.errors?.pattern
                  "
                >
                  Enter only characters!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>pmtCode</mat-label>
                <input
                  autocomplete="new-txtpmtCode"
                  oninput="this.value = this.value.toUpperCase()"
                  formControlName="txtpmtCode"
                  pattern="[A-Za-z]*"
                  maxlength="10"
                  matInput
                />
                <mat-error
                  *ngIf="
                    payorForm?.get('txtpmtCode')?.touched &&
                    payorForm?.get('txtpmtCode')?.errors?.required
                  "
                >
                  pmtCode is a required field!
                </mat-error>
                <mat-error
                  *ngIf="
                    payorForm?.get('txtpmtCode')?.touched &&
                    payorForm?.get('txtpmtCode')?.errors?.pattern
                  "
                >
                  Enter only characters!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label> Insurance Type Code</mat-label>
                <input
                  autocomplete="new-txtInsCode"
                  matInput
                  maxlength="5"
                  formControlName="txtInsCode"
                  type="text"
                />
                <mat-error
                  *ngIf="
                    payorForm?.get('txtInsCode')?.touched &&
                    payorForm?.get('txtInsCode')?.errors?.pattern
                  "
                >
                  Enter only characters!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label> Cliam Filling Indicator</mat-label>
                <input
                  autocomplete="new-txtClaimIndicator"
                  matInput
                  maxlength="5"
                  formControlName="txtClaimIndicator"
                  type="text"
                />
                <mat-error
                  *ngIf="
                    payorForm?.get('txtClaimIndicator')?.touched &&
                    payorForm?.get('txtClaimIndicator')?.errors?.pattern
                  "
                >
                  Enter only characters!
                </mat-error>
              </mat-form-field>
              <div class="col-3 pt-3">
                <mat-slide-toggle formControlName="isChecklistRequired"
                  >Required checklist</mat-slide-toggle
                >
              </div>
              <div class="col-3 pt-3">
                <mat-slide-toggle formControlName="isMedicare"
                  >Is Medicare</mat-slide-toggle
                >
              </div>
              <div class="col-12 pt-3 text-center">
                <button
                  mat-button
                  class="buttonColor mb-2 mr-2"
                  [disabled]="saveButtonHide || !!payorForm.invalid"
                  (click)="savePayor()"
                >
                  Save
                  <!-- <mat-icon *ngIf='isShowSpinner'>
                  <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                </mat-icon> -->
                </button>
                <button
                  mat-button
                  class="resetclr buttonColor mb-2 mr-2"
                  (click)="resetPayorForm()"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="spaces mt-3" [hidden]="lstPayerId.length === 0">
      <mat-expansion-panel
        [expanded]="step1"
        (afterExpand)="SecToggle()"
        (closed)="secToggleV2()"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <b>
              <ng-container
                *ngIf="
                  payorId === '';
                  then thenTitleTemplate1;
                  else thenTitleTemplate1
                "
              ></ng-container>
              <ng-template #thenTitleTemplate1>
                Insurance Category Name
              </ng-template>
              <ng-template #elseTitleTemplate1>
                Edit Insurance Category Name
              </ng-template>
            </b>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-card-content>
          <form [formGroup]="payorInsuranceForm">
            <div class="row">
              <mat-form-field class="col-3">
                <mat-label
                  >Insurance Category<span class="asterisk">*</span></mat-label
                >
                <mat-select
                  (selectionChange)="onSelect($event)"
                  formControlName="InsCategoryV1"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      [formControl]="InsCategFilterControl"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let Ins of InsurenceCategoryList"
                    [value]="Ins.id"
                  >
                    {{ Ins.insuranceCategory }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    payorInsuranceForm?.get('InsCategoryV1')?.touched &&
                    payorInsuranceForm?.get('InsCategoryV1')?.errors
                  "
                >
                  Insurance is a required field!
                </mat-error>
              </mat-form-field>
              <div class="col-6">
                <button
                  mat-button
                  class="buttonColor mb-2 mr-2"
                  [disabled]="saveButtonHideIns || !!payorInsuranceForm.invalid"
                  (click)="saveInsuranceCategory()"
                >
                  Save
                  <!-- <mat-icon *ngIf='isShowSpinner'>
                  <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                </mat-icon> -->
                </button>
                <button
                  mat-button
                  class="resetclr buttonColor mb-2 mr-2"
                  (click)="resetPayorInsuranceForm()"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="card card-body mt-3">
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-center" *ngIf="isLoading">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif"/>
          </div>
          <div class="container-fluid">
            <app-master-payor
              (isLoadTbl)="statusFilter()"
              (edit)="editPayor($event)"
              (lstPayerIds)="lstPayer($event)"
              *ngIf="!isLoading"
              [payerTableData]="payerTableData"
            ></app-master-payor>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
