<h2 mat-dialog-title>
    <mat-toolbar class="buttonColor">
      <div class="align-right-end">Notifications List</div>
      
    </mat-toolbar>
  </h2>
  <mat-dialog-content>
  
    <ng-container *ngIf="notificationList?.length!==0; else elseTemplate">
 
      <mat-list class="notify-list-width ">
        <mat-list-item class="mt-1 pb-0 notify" *ngFor="let notify of notificationList">
          <div id="{{notify?.id}}" mat-line class="m-0 ">
            <span>
              {{ notify?.defaultPatientId }}
            </span>
            <span *ngIf="notify?.subject">-</span>
            <span>
              {{ notify?.subject }}
            </span>
            
          </div>
          <!-- <div mat-line>
            <span class="text-below-left">
              <span class="text-muted" matTooltip="Prdouct Category" matTooltipPosition="before"> {{ notify?.categoryName
                }}</span>
              <span *ngIf="notify?.brandName">-</span>
              <span class="text-muted" matTooltip="Prdouct Brand" matTooltipPosition="before"> {{ notify?.brandName }}
              </span>
              <span class="text-muted pl-5 date"> {{notify?.creationTime | date :'MM/dd/yyyy h:mm a' :'en_US'}} </span>
            </span>
          </div>
          <button mat-icon-button class="float-right mb-2 mr-2" (click)="readNotifyById(notify)" matTooltip="Mark As Read"
            matTooltipPosition="before">
            <mat-icon>clear_all</mat-icon>
          </button>
          <button mat-icon-button class="float-right mb-2" (click)="dismissNotifyById(notify)"
            matTooltip="Mark As Dismissed" matTooltipPosition="before">
            <mat-icon>cancel</mat-icon>
          </button> -->
        </mat-list-item>
      </mat-list>
    </ng-container>
    <ng-template #elseTemplate>
      <div class="hstack gap-3">
        <mat-list class="notify-list-width">
          <mat-list-item role="listitem">
            <b> There Are No New Notifications Now...</b>
            <mat-icon>notifications_none</mat-icon>
          </mat-list-item>
        </mat-list>
      </div>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
  