import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-assign-document-tab',
  templateUrl: './assign-document-tab.component.html',
  styleUrls: ['./assign-document-tab.component.scss'],
})
export class AssignDocumentTabComponent implements OnInit {
  documentId: string;
  blobName: string;
  constructor(
    private inboundDocumentService: InboundDocumentService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((response) => {
      if (response?.get('documentId')) {
        this.documentId = response?.get('documentId') ?? '';
      }
      if (response?.get('blobName')) {
        this.blobName = response?.get('blobName') ?? '';
      }
    });
  }
  approveDocument() {
    Swal.fire({
      title: 'Are you sure you want Process the Document?',
      text: 'Approve!',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.value) {
        const approveDocument = this.inboundDocumentService
          .approveInboundDocumentByGInboundDocumentId(this.documentId)
          .subscribe(
            (response) => {
              this.toastr.success('Inbound Process Completed!');
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
      }
    });
  }
}
