import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MailTemplateDetailsService, MailTemplateMasterService } from '../admin-proxy/platform-app-management/rcm/platform-management/email-template';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { defaultGuid } from '../document-perviewer/document-perviewer.component';
import { HttpErrorResponse } from '@angular/common/http';
import emailMask from 'text-mask-addons/dist/emailMask';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CreateUpdateMailTemplateDetailsDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/email-template/dto';
import { RxPreviewDocumentComponent } from 'projects/order/src/app/rx-preview-document/rx-preview-document.component';
import { MatDialog } from '@angular/material/dialog';
import { PracticeManagementService } from 'projects/patient/src/app/patient-proxy/practice-management';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';

@Component({
  selector: 'app-rx-email-print-letter',
  templateUrl: './rx-email-print-letter.component.html',
  styleUrls: ['./rx-email-print-letter.component.scss']
})
export class RxEmailPrintLetterComponent implements OnInit {
  EmailForm: FormGroup;
  emailMask: any;
  editorContent: string;
  saveButtonHide: boolean;
  patientId: string;
  subscription$: Subscription[] = [];
  filteredTemplate: any;
  lstTemplate: any;
  templateId: any;
  lstPractices: any;
  isRxView: boolean = false;
  filteredPractices: any;
  constructor(private title: Title, private formBuilder: FormBuilder,
    public InboundDocumentEmailService: InboundDocumentService,
     private toastr: ToastrService,
     private activatedRoute: ActivatedRoute,
     private mailTemplateMasterService: MailTemplateMasterService,
     private dialog: MatDialog,
     private mailTemplateDetailsService:  MailTemplateDetailsService,
     private router: Router,
     private practiceManagementService: PracticeManagementService
    ) { }
     name = 'Email/PrintLetter';
     htmlContent = '';
     config: AngularEditorConfig = {
       editable: true,
       spellcheck: true,
       height: '35rem',
       minHeight: '5rem',
       placeholder: 'Enter text here...',
       translate: 'no',
       defaultParagraphSeparator: 'p',
       defaultFontName: 'Arial',
       toolbarHiddenButtons: [
         [ 'insertImage','insertVideo'], // hide  video icons
       ],
       // toolbarHiddenButtons: [
       //   ['bold']
       // ],
       customClasses: [
         {
           name: "quote",
           class: "quote",
         },
         {
           name: 'redText',
           class: 'redText'
         },
         {
           name: "titleText",
           class: "titleText",
           tag: "h1",
         },
       ]
     };
  ngOnInit(): void {
    this.title.setTitle('Qsecure | Email-PrintLetter');
    this.emailMask = emailMask;
    this.EmailForm = this.formBuilder.group({
      txtEmail: new FormControl("", [Validators.required]),
      editorContent: new FormControl("", [Validators.required]),
      drpTemplate: new FormControl("",[Validators.required]),
      txtSubject: new FormControl('',[Validators.required]),
      txtTemplate: new FormControl(''),
      txtPracticeFilter: new FormControl(''),
      drpPracticeManagement: new FormControl('',[Validators.required])
    });

    this.loadTemplate()
    this.loadPracticeManagements()

    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.patientId =  response.get('patientId')?? defaultGuid;

        // this.defaultFaxId = response.get('defaultFaxId') ?? defaultGuid;

      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(activatedRoute);
  }

  fnSendEmail() {
    this.saveButtonHide = true;
    // const patientID = this.patientId;
    const patientID= defaultGuid;
    const sContent = this.EmailForm.get('editorContent').value;
    const sToAddress = this.EmailForm.get('txtEmail').value;
    if (!sContent) {
      console.error('Content is empty');
      return;
    }
    const templateDetails: CreateUpdateMailTemplateDetailsDTO ={
      templateId: this.templateId.id,
      toEmail: this.EmailForm.value.txtEmail.toLowerCase(),
      subject: this.EmailForm.value.txtSubject?? '',
      isActive: 0,
      isMailSent: true,
      tenantId: defaultGuid,
      documentId: defaultGuid,
      patientId: this.patientId,
      mailContent: this.EmailForm.value.editorContent,
      mailstatusReason: ''
    }

    this.mailTemplateDetailsService.create(templateDetails)
      .subscribe(
        (response) => {
          this.saveButtonHide = false;
          if (response) {
            this.toastr.success('Successfully Send Mail', 'Success');
            this.EmailForm.reset();
          } else {
            this.toastr.error('Failed to send email', 'Error');
            this.EmailForm.reset();
          }
        },
        (error) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = error;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }


  loadTemplate(){
    const template = this.mailTemplateMasterService.getStatusBasedMailTemplateList(1).subscribe(response => {
      this.lstTemplate = response

      this.filteredTemplate = this.EmailForm
      .get('txtTemplate')
      .valueChanges.pipe(
        startWith(''),
        map((value) =>
          this.lstTemplate?.filter((option) =>
            option?.templateName
              ?.toLowerCase()
              ?.includes(value?.toLowerCase() ?? '')
          )
        )
      );
  },
  (err) => {}
);

  }

  selectTemaplate(event){
    this.isRxView = true;
     this.templateId = event.value
    const getTemplate = {
      templateId: this.templateId.id,
      patientId: (this.patientId === null ? defaultGuid : this.patientId )
    }

    this.mailTemplateMasterService.getMailContentByDTO(getTemplate).subscribe(response => {
      this.EmailForm.patchValue({
        editorContent: response.mailContent,
        txtEmail: response.email ?? '',
      })

    },(err) => {
      const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
    })
  }

  backToRxReport(){
    this.router.navigate(['/Rx-Report'])
  }
    //Method to load the Practice Managements
    loadPracticeManagements() {
      const practiceList = this.practiceManagementService
        .getListV1(new PagedAndSortedResultRequestDto())
        .subscribe(
          (response) => {
            this.lstPractices = response;
            this.filteredPractices = this.EmailForm
              .get('txtPracticeFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.lstPractices?.filter((option) =>
                    option?.practiceName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {}
        );
    }

    reset(){
      this.isRxView = false;
      this.EmailForm.reset()
      this.EmailForm.patchValue({
        txtEmail: '',
        drpTemplate: '',
        txtSubject: '',
        editorContent: ''
      })
      this.clearValidationErrors()
    }

      // Clear validation errors without triggering validation.
  clearValidationErrors() {
    Object.keys(this.EmailForm.controls).forEach((key) => {
      this.EmailForm.get(key).setErrors(null);
    });
  }

  previewRXDocument(){
    const dialogRef = this.dialog.open(RxPreviewDocumentComponent, {
      height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId

        // documentData: ,
        viewType: false,
        isRxView: true

      },
    });
  }

}
