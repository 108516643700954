import { ListService } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { WindowRef } from 'angular-disable-browser-back-button';
import { MasterProviderService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-provider.service';
import { DefaultPracticeDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { InventoryProviderDetailsComponent } from '../inventory-provider-details/inventory-provider-details.component';
import { ToastrService } from "ngx-toastr";
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-inventory-provider',
  templateUrl: './inventory-provider.component.html',
  styleUrls: ['./inventory-provider.component.scss'],
  providers: [WindowRef, DatePipe, ListService]
})
export class InventoryProviderComponent implements OnInit , OnDestroy {

  public strPageType: string = "Provider";

  providerTableDataList:any;
  // insuranceId: string = "";
  providerId: string = "";
  providerForm: FormGroup;
  @Input() patientID: string = '';
  tblNotesReponse: any[] = [];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('MatPaginator', { static: false }) paginator: MatPaginator;
  // drpCity: CityDTO[];
  // drpState: StateDTO[];
  providerData: MasterProviderDTO[] = [];
  drpDefaultPractice: DefaultPracticeDTO[];
  dataSource = new MatTableDataSource();
  isLoading: boolean = true;
  isInsuranceCreatePermission: boolean = false;
  isInsuranceUpdatePermission: boolean = false;
  isInsuranceDeletePermission: boolean = false;
  isShownSaveButton: boolean = false;
  isShownEditButton: boolean = false;
  isShownDeleteButton: boolean = false;
  insuranceTableData: any[] = [];
  isShownUpdateIconForProvider: boolean = true;
  isDeleteForProvider: boolean = true;
  private window: Window;
  selectedOption: number = 1;
  displayedColumns: string[] = [ 'Options','npi', 'fullName', 'phone', 'homeAddress',];

  providerSearchForm: FormGroup;
  providerSearchTableData: any[] = [];
  subscription$: Subscription[] = [];
  constructor(
    public dialog: MatDialog,
    public Table: TableService,
    private providerTable: MasterProviderService,
    private deleteService: MasterProviderService,
    public title: Title,
    public list: ListService,
    private formBuilder: FormBuilder, private windowRef: WindowRef,
    private dateValidator: DateValidator,
    private reportService: ReportService,
    private toastr: ToastrService,
    private communicationService: CommunicationService,
    private commonService: CommonService
  ) {

    this.communicationService.masterProviderMethod$.subscribe(() => {
      this.getTableDate(this.selectedOption);
    });


    this.window = this.windowRef.nativeWindow;

    this.providerForm = this.formBuilder.group({
      id: '',
      code: '',
      npi: '',
      payorId: '',
      fullName: '',
      phone: '',
      homeAddress: '',
      lastname : '',

    })

    const valueChanges: Subscription = this.providerForm.valueChanges.subscribe((value: MasterProviderDTO) => {
      let filter: MasterProviderDTO = {

        code: value.code.trim().toLowerCase(),
        npi: value.npi.trim().toLowerCase(),
        payorId: value.payorId.trim().toLowerCase(),
        fullName: value.fullName.trim().toLowerCase(),
        lastName: value.lastName ? value.lastName.trim().toLocaleLowerCase() : '-',
        phone: value.phone.trim().toLowerCase(),
        homeAddress: value.homeAddress.trim().toLowerCase(),

      };
      if (this.providerForm.valid) {
        this.dataSource.filter = JSON.stringify(filter);
        this.filtertable(filter);
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(valueChanges);



  }

  ngOnInit(): void {
    this.selectedOption=1;
    this.getTableDate(this.selectedOption);
    this.title.setTitle("Qsecure | Provider");
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  alphaOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }
  statusFilterV1(number:number) {
      this.getTableDate(number);
  }

  filtertable(filter) {

    let npi = filter.npi;
    let code = filter.code;
    let payorId = filter.payorId;
    let fullName = filter.fullName;
    let phone = filter.phone;
    let homeAddress = filter.homeAddress;



    this.providerData = [];
    const searchProviderList = this.providerTable.searchProviderBySNpiAndSCodeAndSPayorCodeAndSFullnameAndSphoneAndSAddress
      (npi, code, payorId, fullName, phone, homeAddress).subscribe(data => {

        this.providerData = [];
        data && data.items.forEach(element => {


          this.providerData.push({
            npi: element?.npi,
            code: element?.code,
            payorId: element?.payorId,
            fullName: element?.fullName,
            lastName: element.lastName ? element.lastName.trim().toLocaleLowerCase() : '-',
            phone: element?.phone,
            homeAddress: element?.homeAddress,
            id: element.id
          });

          // })
          this.isLoading = false;

          this.dataSource = new MatTableDataSource(this.providerData);

          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }, error => {
          this.isLoading = false;
          this.dataSource = new MatTableDataSource(this.providerData);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        })

      }, err => {

      })
    this.subscription$.push(searchProviderList);
    this.dataSource = new MatTableDataSource(this.providerData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  getTableDate(selectedOption: number) {
    this.isLoading = true;
    this.providerData = [];
    const providerLists = this.providerTable.getListWithStatusFilter(selectedOption).subscribe(response => {

      response?.items?.forEach(element => {
        this.providerData.push({
          npi: element?.npi,
          code: element?.code,
          payorId: element?.payorId,
          fullName: element?.fullName,
          lastName: element?.lastName,
          phone: element?.phone,
          homeAddress: element?.homeAddress,
          id: element.id
        });
      })
      this.providerTableDataList=response;
      this.providerTableDataList = this.providerTableDataList.map((data) => {
        return {
          ...data,
          creationTime: this.commonService.getFormattedDateTimeZone(
            data.creationTime
          ),
          lastModificationTime: this.commonService.getFormattedDateTimeZone(
            data.lastModificationTime
          ),
        };
});
      this.isLoading = false;

      // this.dataSource = new MatTableDataSource(this.providerData);
      // this.dataSource.sort = this.sort;
      // this.dataSource.paginator = this.paginator;

      // this.providerData=  this.providerData.sort((a, b) => { return new Date(a?.creationTime).getTime() - new Date(b?.creationTime).getTime() }).reverse();
      // this.Table.setproviderTable(this.providerData);
      // this.isLoading = false;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      // this.isLoading = false;
      // this.dataSource = new MatTableDataSource(this.providerData);
      // this.dataSource.sort = this.sort;
      // this.dataSource.paginator = this.paginator;
    })
    this.subscription$.push(providerLists);

  }
  openProviderModal() {
    const dialogRef = this.dialog.open(InventoryProviderDetailsComponent, {
      disableClose: true
      // data: { providerID: this.providerID }
    });

    const closeModal = dialogRef.afterClosed().subscribe(() => {
      this.getTableDate(this.selectedOption);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeModal);
    //
  }
  ViewProvider(record?: any) {
    const dialogRef = this.dialog.open(InventoryProviderDetailsComponent, {
      disableClose: true,
      data: { providerId: record }
    });

    const closeModal = dialogRef.afterClosed().subscribe(() => {
      this.getTableDate(this.selectedOption);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(closeModal);
  }

  /// delete provide api implement
  deleteProvider(providerId: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
      allowOutsideClick: false,

    }).then(result => {
      if (result.value) {
        const providerDelete = this.deleteService.delete(providerId).subscribe(response => {
          this.getTableDate(this.selectedOption);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(providerDelete);
      }
    });
  }

  exportTableData() {
    const searchFormValue = this.providerForm?.value;

    // return false;
    const exportReport = this.reportService.exportProviderReportsByLstProviderReports(this.dataSource.data).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Inventory-Provider") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Exported Successfully','Success')
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      // Swal.fire({
      //   icon: 'info',
      //   text: errorMesg
      // });
      this.toastr.info(errorMesg)
    });
    this.subscription$.push(exportReport);
  }
}

export interface MasterProviderDTO {
  id?: string;
  code?: string;
  npi?: string;
  payorId?: string;
  fullName?: string;
  phone?: string;
  homeAddress?: string;
  lastName?: string;
  shortCodeId?:string;
}


