<!--Resupply Bin-->
<div
  class="table-responsive"
  *ngIf="activeTabBin === MyQueueBinStatus.Resupply"
>
  <table
    #tableOrderContainer
    datatable
    [dtOptions]="dtDocumentBinTableOptions1"
    class="row-border hover w-100 display dataTables_scroll"
  >
    <thead>
      <tr>
        <th><mat-icon>apps</mat-icon></th>
        <th [matTooltip]="'Order No'">Order No</th>
        <th [matTooltip]="'Chart Id'">Chart Id</th>
        <th [matTooltip]="'Patient Name'">Patient Name</th>
        <th [matTooltip]="'Supplies Date'">Supplies Date</th>
        <th [matTooltip]="'Insurance'">Insurance</th>
        <th [matTooltip]="'Order Date'">Order Date</th>
        <th [matTooltip]="'Order Status'">Order Status</th>
        <th>Primary Insurance</th>
        <th>Last Mask Date</th>
        <th>Points</th>
        <th>Exchange / Return</th>
        <th>Billing Order Type</th>
        <th>Payment Status</th>
        <th>Hello Sign Status</th>
        <th>Created By</th>
        <th>Created Date</th>
        <th>Modified By</th>
        <th>Modified Date</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let document of orderTableData">
        <td class="no-select">
          <mat-icon
            class="pointer toggle-icon"
            (click)="getTotalInboundData($event, document)"
            >add</mat-icon
          >

          <mat-icon
            style="cursor: pointer !important"
            [matMenuTriggerFor]="menu"
            >more_vert</mat-icon
          >
          <mat-menu class="custom-mat-menu" #menu="matMenu">
            <button
              (click)="
                openViewNotesPopup(
                  document.patientId,
                  document.defaultFaxId,
                  document.docId
                )
              "
              [disabled]="isEmpty(document.patientId)"
              mat-menu-item
            >
              View Patient Notes
            </button>
            <button
              mat-menu-item
              (click)="viewPatientLedger(document?.patientId)"
              [disabled]="isEmpty(document.patientId)"
            >
              View Patient Ledger
            </button>
          </mat-menu>
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.orderNo)"
          [matTooltip]="document?.orderNo || '-'"
        >
          <a
            target="_blank"
            [class.disabled]="
              isEmpty(document.orderNo) || isEmpty(document.patientId)
            "
            [routerLink]="
              '/orders/saleOrder/' +
              document.patientId +
              '/' +
              document.orderId +
              '/' +
              '0'
            "
            class="preview-link"
          >
            {{ document?.orderNo || "-" }}
          </a>
        </td>

        <td
          (dblclick)="onRowDoubleClick(document.chartId || '-')"
          class="custom-width"
        >
          <a
            [class.disabled]="
              isEmpty(document.chartId) || isEmpty(document.patientId)
            "
            class="preview-link"
            [routerLink]="'/patientCreateEditTab/' + document.patientId"
            target="_blank"
            [matTooltip]="document.chartId || '-'"
            >{{ document.chartId || "-" }}</a
          >
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.patientname)"
          [matTooltip]="document?.patientname || '-'"
        >
          {{ document?.patientname || "-" }}
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.suppliesDate)"
          [matTooltip]="document?.suppliesDate"
        >
          {{ document?.suppliesDate }}
        </td>
        <td
          (dblclick)="onRowDoubleClick(document?.primaryInsName)"
          [matTooltip]="document?.primaryInsName || '-'"
        >
          {{ document?.primaryInsName || "-" }}
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.orderDate)"
          [matTooltip]="document?.orderDate || '-'"
        >
          {{ document?.orderDate || "-" }}
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.orderStatusName)"
          [matTooltip]="document?.orderStatusName || '-'"
        >
          {{ document?.orderStatusName || "-" }}
        </td>

        <td>{{ document?.primaryInsName || "-" }}</td>
        <td>{{ document?.lastMaskDate || "-" }}</td>
        <td>{{ document?.orderPointsCAL || "-" }}</td>
        <td>{{ document?.exchangeOrReturn || "-" }}</td>
        <td>{{ document?.billingOrderType || "-" }}</td>
        <td>{{ document?.paymentStatus || "-" }}</td>
        <td>{{ document?.helloSignStatus || "-" }}</td>
        <td>{{ document?.createdby || "-" }}</td>
        <td>{{ document?.createdDate || "-" }}</td>
        <td>{{ document?.modifiedBy || "-" }}</td>
        <td>{{ document?.modifiedDate || "-" }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!--SaleOrder Bin-->
<div
  class="table-responsive"
  *ngIf="activeTabBin === MyQueueBinStatus.SaleOrder"
>
  <table
    #tableOrderContainer
    datatable
    [dtOptions]="dtDocumentBinTableOptions1"
    class="row-border hover w-100 display dataTables_scroll"
  >
    <thead>
      <tr>
        <th><mat-icon>apps</mat-icon></th>
        <th [matTooltip]="'Order No'">Order No</th>
        <th [matTooltip]="'Chart Id'">Chart Id</th>
        <th [matTooltip]="'Patient Name'">Patient Name</th>
        <th [matTooltip]="'Supplies Date'">Supplies Date</th>
        <th [matTooltip]="'Insurance'">Insurance</th>
        <th [matTooltip]="'Order Date'">Order Date</th>
        <th [matTooltip]="'Order Status'">Order Status</th>

        <th>Primary Insurance</th>
        <th>Last Mask Date</th>
        <th>Points</th>
        <th>Exchange / Return</th>
        <th>Billing Order Type</th>
        <th>Payment Status</th>
        <th>Hello Sign Status</th>
        <th>Created By</th>
        <th>Created Date</th>
        <th>Modified By</th>
        <th>Modified Date</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let document of orderTableData">
        <td class="no-select">
          <mat-icon
            class="pointer toggle-icon"
            (click)="getTotalInboundData($event, document)"
            >add</mat-icon
          >

          <mat-icon
            style="cursor: pointer !important"
            [matMenuTriggerFor]="menu"
            >more_vert</mat-icon
          >
          <mat-menu class="custom-mat-menu" #menu="matMenu">
            <button
              (click)="
                openViewNotesPopup(
                  document.patientId,
                  document.defaultFaxId,
                  document.docId
                )
              "
              [disabled]="isEmpty(document.patientId)"
              mat-menu-item
            >
              View Patient Notes
            </button>
            <button
              mat-menu-item
              (click)="viewPatientLedger(document?.patientId)"
              [disabled]="isEmpty(document.patientId)"
            >
              View Patient Ledger
            </button>
          </mat-menu>
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.mmRefereceOrderId)"
          [matTooltip]="document?.mmRefereceOrderId || '-'"
        >
          <a
            target="_blank"
            [class.disabled]="
              isEmpty(document.mmRefereceOrderId) || isEmpty(document.patientId)
            "
            [routerLink]="
              '/orders/saleOrder/' +
              document.patientId +
              '/' +
              document.id +
              '/' +
              '0'
            "
            class="preview-link"
          >
            {{ document?.mmRefereceOrderId || "-" }}
          </a>
        </td>

        <td
          (dblclick)="onRowDoubleClick(document.chartNo || '-')"
          class="custom-width"
        >
          <a
            [class.disabled]="
              isEmpty(document.chartNo) || isEmpty(document.patientId)
            "
            class="preview-link"
            [routerLink]="'/patientCreateEditTab/' + document.patientId"
            target="_blank"
            [matTooltip]="document.chartNo || '-'"
            >{{ document.chartNo || "-" }}</a
          >
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.patientName)"
          [matTooltip]="document?.patientName || '-'"
        >
          {{ document?.patientName || "-" }}
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.suppliesDate)"
          [matTooltip]="document?.suppliesDate"
        >
          {{ document?.suppliesDate }}
        </td>
        <td
          (dblclick)="onRowDoubleClick(document?.policyName)"
          [matTooltip]="document?.policyName || '-'"
        >
          {{ document?.policyName || "-" }}
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.orderDate)"
          [matTooltip]="document?.orderDate"
        >
          {{ document?.orderDate }}
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.orderStatusName)"
          [matTooltip]="document?.orderStatusName || '-'"
        >
          {{ document?.orderStatusName || "-" }}
        </td>

        <td>{{ document?.policyName || "-" }}</td>
        <td>{{ document?.lastMaskProcessDate }}</td>
        <td>{{ document?.orderPointsCAL || "-" }}</td>
        <td>{{ document?.exchangeOrReturn || "-" }}</td>
        <td>{{ document?.billingordertype || "-" }}</td>
        <td>{{ document?.paymentStatus || "-" }}</td>
        <td>{{ document?.hellosignStatus || "-" }}</td>
        <td>{{ document?.createdBy || "-" }}</td>
        <td>{{ document?.creationTime || "-" }}</td>
        <td>{{ document?.modifiedBy || "-" }}</td>
        <td>{{ document?.modifiedOn || "-" }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!--Shipping Bin-->
<div
  class="table-responsive"
  *ngIf="activeTabBin === MyQueueBinStatus.Shipping"
>
  <table
    #tableOrderContainer
    datatable
    [dtOptions]="dtDocumentBinTableOptions2"
    class="row-border hover w-100 display dataTables_scroll"
  >
    <thead>
      <tr>
        <th><mat-icon>apps</mat-icon></th>
        <th [matTooltip]="'Order No'">Order No</th>
        <th [matTooltip]="'Chart Id'">Chart Id</th>
        <th [matTooltip]="'Patient Name'">Patient Name</th>
        <th [matTooltip]="'Supplies Date'">Supplies Date</th>
        <th [matTooltip]="'Insurance'">Insurance / Cash</th>
        <th [matTooltip]="'Status'">Status</th>

        <th>Location</th>
        <th>Shipping Status</th>
        <th>CSR Name</th>
        <th>Created By</th>
        <th>Created Date</th>
        <th>Shipping Method</th>
        <th>Exchange</th>
        <th>Early Ship</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let document of orderTableData">
        <td class="no-select">
          <mat-icon
            class="pointer toggle-icon"
            (click)="getTotalInboundData($event, document)"
            >add</mat-icon
          >

          <mat-icon
            style="cursor: pointer !important"
            [matMenuTriggerFor]="menu"
            >more_vert</mat-icon
          >
          <mat-menu class="custom-mat-menu" #menu="matMenu">
            <button
              (click)="
                openViewNotesPopup(
                  document.patientId,
                  document.defaultFaxId,
                  document.docId
                )
              "
              [disabled]="isEmpty(document.patientId)"
              mat-menu-item
            >
              View Patient Notes
            </button>
            <button
              mat-menu-item
              (click)="viewPatientLedger(document?.patientId)"
              [disabled]="isEmpty(document.patientId)"
            >
              View Patient Ledger
            </button>
          </mat-menu>
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.mmRefereceOrderId)"
          [matTooltip]="document?.mmRefereceOrderId || '-'"
        >
          <a
            [class.disabled]="
              isEmpty(document.mmRefereceOrderId) || isEmpty(document.patientId)
            "
            target="_blank"
            [routerLink]="
              '/orders/saleOrder/' +
              document.patientId +
              '/' +
              document.orderId +
              '/' +
              '0'
            "
            class="preview-link"
          >
            {{ document?.mmRefereceOrderId || "-" }}
          </a>
        </td>

        <td
          (dblclick)="onRowDoubleClick(document.chartno || '-')"
          class="custom-width"
        >
          <a
            [class.disabled]="
              isEmpty(document.chartno) || isEmpty(document.patientId)
            "
            class="preview-link"
            [routerLink]="'/patientCreateEditTab/' + document.patientId"
            target="_blank"
            [matTooltip]="document.chartno || '-'"
            >{{ document.chartno || "-" }}</a
          >
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.fullName)"
          [matTooltip]="document?.fullName || '-'"
        >
          {{ document?.fullName || "-" }}
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.suppliesdate)"
          [matTooltip]="document?.suppliesdate"
        >
          {{ document?.suppliesdate }}
        </td>
        <td
          (dblclick)="onRowDoubleClick(document?.priPlanName)"
          [matTooltip]="document?.priPlanName || '-'"
        >
          {{ document?.priPlanName || "-" }}
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.orderStatus)"
          [matTooltip]="document?.orderStatus || '-'"
        >
          {{ document?.orderStatus || "-" }}
        </td>

        <td>{{ document?.location || "-" }}</td>
        <td>{{ document?.shippingStatus || "-" }}</td>
        <td>{{ document?.csrName || "-" }}</td>
        <td>{{ document?.createdByName || "-" }}</td>
        <td>{{ document?.createdDate || "-" }}</td>
        <td>{{ document?.shippingMethod || "-" }}</td>
        <td>{{ document?.isExchangeOrder || "-" }}</td>
        <td>{{ document?.earlyShip || "-" }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!--Billing Bin-->
<div class="table-responsive" *ngIf="activeTabBin === MyQueueBinStatus.Billing">
  <table
    #tableOrderContainer
    datatable
    [dtOptions]="dtDocumentBinTableOptions3"
    class="row-border hover w-100 display dataTables_scroll"
  >
    <thead>
      <tr>
        <th><mat-icon>apps</mat-icon></th>
        <th [matTooltip]="'Order No'">Order No</th>
        <th [matTooltip]="'Chart Id'">Chart Id</th>
        <th [matTooltip]="'Patient Name'">Patient Name</th>
        <th [matTooltip]="'Supplies Date'">Supplies Date</th>
        <th [matTooltip]="'Insurance'">Insurance / Cash</th>
        <th [matTooltip]="'Status'">Status</th>

        <th>Location</th>
        <th>Shipping Method</th>
        <th>Comments</th>
        <th>Email</th>
        <th>Created Date</th>
        <th>Shipping Status</th>
        <th>Patient Balance</th>
        <th>Modified By</th>
        <th>Reference Ticket Id</th>
        <th>Since</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let document of orderTableData">
        <td class="no-select">
          <mat-icon
            class="pointer toggle-icon"
            (click)="getTotalInboundData($event, document)"
            >add</mat-icon
          >

          <mat-icon
            style="cursor: pointer !important"
            [matMenuTriggerFor]="menu"
            >more_vert</mat-icon
          >

          <mat-menu class="custom-mat-menu" #menu="matMenu">
            <button
              (click)="
                openViewNotesPopup(
                  document.patientId,
                  document.defaultFaxId,
                  document.docId
                )
              "
              [disabled]="isEmpty(document.patientId)"
              mat-menu-item
            >
              View Patient Notes
            </button>
            <button
              mat-menu-item
              (click)="viewPatientLedger(document?.patientId)"
              [disabled]="isEmpty(document.patientId)"
            >
              View Patient Ledger
            </button>
          </mat-menu>
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.mmRefereceOrderId)"
          [matTooltip]="document?.mmRefereceOrderId || '-'"
        >
          <a
            target="_blank"
            [class.disabled]="
              isEmpty(document.mmRefereceOrderId) || isEmpty(document.patientId)
            "
            [routerLink]="
              '/orders/saleOrder/' +
              document.patientId +
              '/' +
              document.orderId +
              '/' +
              '0'
            "
            class="preview-link"
          >
            {{ document?.mmRefereceOrderId || "-" }}
          </a>
        </td>

        <td
          (dblclick)="onRowDoubleClick(document.chartNo || '-')"
          class="custom-width"
        >
          <a
            [class.disabled]="
              isEmpty(document.chartNo) || isEmpty(document.patientId)
            "
            class="preview-link"
            [routerLink]="'/patientCreateEditTab/' + document.patientId"
            target="_blank"
            [matTooltip]="document.chartNo || '-'"
            >{{ document.chartNo || "-" }}</a
          >
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.fullName)"
          [matTooltip]="document?.fullName || '-'"
        >
          {{ document?.fullName || "-" }}
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.suppliesDate)"
          [matTooltip]="document?.suppliesDate"
        >
          {{ document?.suppliesDate }}
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.insuranceName)"
          [matTooltip]="document?.insuranceName || '-'"
        >
          {{ document?.insuranceName || "-" }}
        </td>

        <td
          (dblclick)="onRowDoubleClick(document?.orderStatus)"
          [matTooltip]="document?.orderStatus || '-'"
        >
          {{ document?.orderStatus || "-" }}
        </td>

        <td>{{ document?.state || "-" }}</td>
        <td>{{ document?.shippingMethod || "-" }}</td>
        <td>{{ document?.shippingComments || "-" }}</td>
        <td>{{ document?.emailId || "-" }}</td>
        <td>{{ document?.createdDate || "-" }}</td>
        <td>{{ document?.shippingStatus || "-" }}</td>
        <td>{{ document?.patientBalance || "-" }}</td>
        <td>{{ document?.lastModifyByName || "-" }}</td>
        <td>{{ document?.referenceOrderId || "-" }}</td>
        <td>{{ document?.backOrderSince || "-" }}</td>
      </tr>
    </tbody>
  </table>
</div>
