<table id="tblReminderList" datatable [dtOptions]="dtReminderTableOptions"
    class="tbl hover row-border hover w-100 display">
    <thead>
        <tr>
            <th><mat-icon> wrap_text</mat-icon></th>
            <th><mat-icon>apps</mat-icon></th>
            <th [matTooltip]="'Notes Type'">Notes Type</th>
            <th [matTooltip]="'Patient Name'">Patient Name</th>
            <th [matTooltip]="'Chart No'">Chart No</th>
            <th [matTooltip]="'Assigned To'">Assigned To</th>
            <th [matTooltip]="'Due Date'" class="text-center">Due Date</th>
            <th [matTooltip]="'User Name'">User Name</th>
            <th [matTooltip]="'Description'">Description</th>
            <th [matTooltip]="'Priority'">Priority</th>
            <th [matTooltip]="'Status'">Status</th>
            <th [matTooltip]="'Completed'">Completed</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of remindertbldata">
            <td [matTooltip]="'Expand Notes'" class="dt-control"
                (click)="handleTableCellClick($event, data?.description)">
            </td>
            <td [matTooltip]="'Open Options'">
                <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                    <button mat-menu-item (click)="UpdateReminder(data?.id,false)"
                        [disabled]="data?.status === 'Completed' || data.isEdit === 0">Edit</button>
                    <button mat-menu-item (click)="DeleteReminder(data?.id)"
                        [disabled]="data?.isEdit === 0">Delete</button>
                    <mat-divider></mat-divider>
                    <button mat-menu-item (click)="completeReminder(data?.id)"
                        [disabled]="data?.status === 'Completed'">Complete</button>
                </mat-menu>
            </td>
            <td (dblclick)="onRowDoubleClick(data?.note?.split('-')[0] || '-')" [matTooltip]="data?.note?.split('-')[0] || '-'"> {{ data?.note?.split('-')[0]
            || '-' }} </td>
            <!-- <td (dblclick)="onRowDoubleClick(data.note || '-')" [matTooltip]="data.note || '-'"> {{
                data.note
                || '-' }} </td> -->
            <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'"> {{
                data?.patientName || '-' }}
            </td>

            <td (dblclick)="onRowDoubleClick(data?.chartId || '-')">
                <a [class.disabled]="isEmpty(data?.chartId) || isEmpty(data?.patientId)" class="preview-link"
                    [routerLink]="'/patientCreateEditTab/' + data?.patientId" target="_blank"
                    [matTooltip]="data?.chartId || '-'">{{ data?.chartId || '-'}}</a>
            </td>

            <td (dblclick)="onRowDoubleClick(data?.assignedUserName || '-')"
                [matTooltip]="data?.assignedUserName || '-'"> {{ data?.assignedUserName ||
                '-' }} </td>
            <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.sDueDate))"
                [matTooltip]="data?.sDueDate ? (data?.sDueDate | date : 'MM/dd/yyyy') : '-'"
                [ngClass]="{'past-due-row': isPastDue(data?.sDueDate) && data?.status !== 'Completed'}"
                class="text-center">
                <!--  [ngClass]="{'past-due': isPastDue(data?.dueDate)}" -->
                {{ data?.sDueDate ? (data?.sDueDate | date : 'MM/dd/yyyy') : '-' }}
            </td>
            <td (dblclick)="onRowDoubleClick(data?.userName || '-')" [matTooltip]="data?.userName || '-'"> {{
                data?.userName || '-' }} </td>
            <td (dblclick)="onRowDoubleClick(data?.description || '-')" [matTooltip]="data?.description || '-'">{{
                data?.description || '-' }}</td>
            <td (dblclick)="onRowDoubleClick(data?.priority || '-')" [matTooltip]="data?.priority || '-'">{{
                data?.priority || '-' }}</td>
            <!-- [ngClass]="{'completed-priority': data.priority === 'Completed'}" -->
            <td (dblclick)="onRowDoubleClick(data?.status || '-')" [matTooltip]="data?.status || '-'">{{
                data?.status ||'-' }}</td>
            <td (dblclick)="onRowDoubleClick(getFormattedDate(data?.completedDate))"
                [matTooltip]="data?.completedDate ? (data?.completedDate | date : 'MM/dd/yyyy') : '-'">{{
                data?.completedDate ? (data?.completedDate | date :
                'MM/dd/yyyy') : '-' }}</td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th><mat-icon> wrap_text</mat-icon></th>
            <th><mat-icon>apps</mat-icon></th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Notes Type"
                    name="search-NotesType" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient Name"
                    name="search-PatientName" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Chart No" name="search-ChartNo" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Assigned To"
                    name="search-AssignedTo" />
            </th>
            <th>
                <input matInput class="textbox-border-class text-center" type="text" placeholder="Due Date" name="search-DueDate" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="User Name"
                    name="search-UserName" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Description"
                    name="search-Description" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Priority"
                    name="search-Priority" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Status" name="search-Status" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Completed"
                    name="search-Completed" />
            </th>
        </tr>
    </tfoot>
</table>
