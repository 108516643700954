import { ReferralPracticeListComponent } from './referral-practice-list/referral-practice-list.component';
import { CoreModule, storageFactory } from '@abp/ng.core';
import { registerLocale } from '@abp/ng.core/locale';
import {
  AgmCoreModule,
  GoogleMapsAPIWrapper,
  LazyMapsAPILoader,
  MarkerManager,
} from '@agm/core';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  ErrorStateMatcher,
  MatNativeDateModule,
  MatOptionModule,
  MatPseudoCheckboxModule,
  MatRippleModule,
  MAT_DATE_LOCALE,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, UrlSerializer } from '@angular/router';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
// import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import {
  AuthConfig,
  OAuthModule,
  OAuthModuleConfig,
  OAuthStorage,
  ValidationHandler,
} from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AdminModule } from 'projects/admin/src/app/app.module';
import { billingState } from 'projects/billing/src/app/billing-store/billing.state';
import { HorizontolModule } from 'projects/layout/src/app/app.module';
import { OrderModule } from 'projects/order/src/app/app.module';
import { orderState } from 'projects/order/src/app/order-store/order.state';
import { reminderState } from 'projects/order/src/app/order-store/reminder.state';
import { onTimeScheduleModule } from 'projects/schedule/src/app/app.module';
import { SharedModule } from 'projects/shared/src/app/shared.module';
import { ShippingModule } from 'projects/shipping/src/app/app.module';

import { environment, GoogleAPIKey } from '../environments/environment';
import { AddAuthComponent } from './add-auth/add-auth.component';
import { AddNotesComponent } from './add-notes/add-notes.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CustomUrlSerializer } from './custom-url-serializer';
import { HttpInterceptorService } from './http-interceptor-service';
import { PatientAddPolicyComponent } from './patient-add-policy/patient-add-policy.component';
import { PatientAuthMenuComponent } from './patient-auth-menu/patient-auth-menu.component';
import { PatientAuthComponent } from './patient-auth/patient-auth.component';
import { PatientClinicalComponent } from './patient-clinical/patient-clinical.component';
import { PatientContactComponent } from './patient-contact/patient-contact.component';
import { PatientCreateEditComponent } from './patient-create-edit/patient-create-edit.component';
import { PatientDoctorComponent } from './patient-doctor/patient-doctor.component';
import { PatientDocumentComponent } from './patient-document/patient-document.component';
import { PatientEligibilityHistoryComponent } from './patient-eligibility-history/patient-eligibility-history.component';
import { PatientHistoryComponent } from './patient-history/patient-history.component';
import { PatientIcd10Component } from './patient-icd10/patient-icd10.component';
import { PatientIcd9Component } from './patient-icd9/patient-icd9.component';
import { PatientInsurnaceComponent } from './patient-insurnace/patient-insurnace.component';
import { PatientNotesComponent } from './patient-notes/patient-notes.component';
import { PatientNpiDoctorComponent } from './patient-npi-doctor/patient-npi-doctor.component';
import { PatientOrderStatusComponent } from './patient-order-status/patient-order-status.component';
import { PatientOrderComponent } from './patient-order/patient-order.component';
import { PatientPersonalComponent } from './patient-personal/patient-personal.component';
import { PatientPverifyComponent } from './patient-pverify/patient-pverify.component';
import { PatientSearchComponent } from './patient-search/patient-search.component';
import { PatientState } from './patient-store/patient.state';
import { PatientVerificationComponent } from './patient-verification/patient-verification.component';
import { PatientVerifyMenuComponent } from './patient-verify-menu/patient-verify-menu.component';
import { PatientVerifySearchComponent } from './patient-verify-search/patient-verify-search.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import {
  DocumentPreviewComponent,
  UserTaskListComponent,
} from './user-task-list/user-task-list.component';
import { PatientImportComponent } from './patient-import/patient-import.component';
import { PatientVImportComponent } from './patient-v-import/patient-v-import.component';
import { ReferralPracticeComponent } from './referral-practice/referral-practice.component';
import { PracticeManagementComponent } from './practice-management/practice-management.component';
import { ReferralFaxLogComponent } from './referral-fax-log/referral-fax-log.component';
import { ReferralFaxNumberComponent } from './referral-fax-number/referral-fax-number.component';
import { PracticePhysiciansComponent } from './practice-physicians/practice-physicians.component';
import { PracticeAddDoctorComponent } from './practice-add-doctor/practice-add-doctor.component';
import { ImportMisReportComponent } from './import-mis-report/import-mis-report.component';
import { ImportPivotTableComponent } from './import-pivot-table/import-pivot-table.component';
import { PatientSourcemdPopupComponent } from './patient-sourcemd-popup/patient-sourcemd-popup.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule, FirebaseAppConfig } from '@angular/fire';
import { NotificationsState } from 'projects/layout/src/app/notiifcation/notifications.state';
import { PatientsTabComponent } from './patients-tab/patients-tab.component';
import { PatientClinicalTabComponent } from './patient-clinical-tab/patient-clinical-tab.component';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { PatientSearchTabComponent } from './patient-search-tab/patient-search-tab.component';
import { PatientCreateEditTabComponent } from './patient-create-edit-tab/patient-create-edit-tab.component';
import { MatTreeModule } from '@angular/material/tree';
import { DocumentFollowupComponent } from './document-followup/document-followup.component';
import { OrderFollowupComponent } from './order-followup/order-followup.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { PatientClaimsComponent } from './patient-claims/patient-claims.component';
import { AddNoteTabComponent } from './add-note-tab/add-note-tab.component';
import { PatientAddPolicyTabComponent } from './patient-add-policy-tab/patient-add-policy-tab.component';
import { PatientInsuranceTabComponent } from './patient-insurance-tab/patient-insurance-tab.component';
import { PatientManualVerificationTabComponent } from './patient-manual-verification-tab/patient-manual-verification-tab.component';
import { PatientNotesTabComponent } from './patient-notes-tab/patient-notes-tab.component';
import { PatientOrderTabComponent } from './patient-order-tab/patient-order-tab.component';
import { PatientPverifyTabComponent } from './patient-pverify-tab/patient-pverify-tab.component';
import { SummaryComponent } from './summary/summary.component';
import { PatientNotesModalComponent } from './patient-notes-modal/patient-notes-modal.component';
import { DocumentTabComponent } from './document-tab/document-tab.component';
import { PatientEligibilityListTabComponent } from './patient-eligibility-list-tab/patient-eligibility-list-tab.component';
import { ColorCodesMasterComponent } from './color-codes-master/color-codes-master.component';
import {
  MAT_COLOR_FORMATS,
  NgxMatColorPickerModule,
  NGX_MAT_COLOR_FORMATS,
} from '@angular-material-components/color-picker';
import { CommonNotesSearchComponent } from './common-notes-search/common-notes-search.component';
import { TaskListPageTabComponent } from './task-list-page-tab/task-list-page-tab.component';
import { DxDataGridModule } from 'devextreme-angular';
import { TaskViewerComponent } from './task-viewer/task-viewer.component';
import { ScheduleTaskComponent } from './schedule-task/schedule-task.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ScheduledTaskListComponent } from './scheduled-task-list/scheduled-task-list.component';
import { UserTaskReportTabComponent } from './user-task-report-tab/user-task-report-tab.component';
import { PatientAuthorizationTabComponent } from './patient-authorization-tab/patient-authorization-tab.component';
import { MatChipsModule } from '@angular/material/chips';
import { ToastrModule } from 'ngx-toastr';
import { PatientClaimComponent } from './patient-claim/patient-claim.component';
import { PatientOrdersComponent } from './patient-orders/patient-orders.component';
import { CreateEditProductTabComponent } from 'projects/inventory/src/app/create-edit-product-tab.component/create-edit-product-tab.component';
import { ResupplyProductTabComponent } from 'projects/inventory/src/app/resupply-product-tab/resupply-product-tab.component';
import { DocumentSortingComponent } from 'projects/admin/src/app/document-sorting/document-sorting.component';
import { WipAllocationModalComponent } from './wip-allocation-modal/wip-allocation-modal.component';
import { WipListModalComponent } from './wip-list-modal/wip-list-modal.component';
import { AddDocNpiComponent } from './add-doc-npi/add-doc-npi.component';
import { NpiDetailsComponent } from './npi-details/npi-details.component';
import { PatientAuthorizationDetailsComponent } from './patient-authorization-details/patient-authorization-details.component';
import { PatientAuthorizationListComponent } from './patient-authorization-list/patient-authorization-list.component';
import { PatientAuthSaveFormComponent } from './patient-auth-save-form/patient-auth-save-form.component';
import { TimerComponent } from './timer/timer.component';
import { StatusComponent } from './status/status.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CommonNotesSearchV1Component } from './common-notes-search-v1/common-notes-search-v1.component';
import { WipAllocationModalV2Component } from './wip-allocation-modal-v2/wip-allocation-modal-v2.component';
import { DataTablesModule } from 'angular-datatables';
import { CustomerServicePoolComponent } from './customer-service-pool/customer-service-pool.component';
import { NewsetupModalComponent } from './newsetup-modal/newsetup-modal.component';
import { PatientAuthSaveUpdateFormComponent } from './patient-auth-save-update-form/patient-auth-save-update-form.component';
import { PatientListV1Component } from './patient-list-v1/patient-list-v1.component';
import { AdminDashboardPatientManualVerificationComponent } from './admin-dashboard-patient-manual-verification/admin-dashboard-patient-manual-verification.component';
import { VerificationComponent } from './verification/verification.component';
import { MIVerificationComponent } from './miverification/miverification.component';
import { MedicareCheckListComponent } from './medicare-check-list/medicare-check-list.component';
import { MedicareQuestionModelComponent } from './medicare-question-model/medicare-question-model.component';
import { MedicareCheckFinalModelComponent } from './medicare-check-final-model/medicare-check-final-model.component';
import { CallbackComponent } from './callback/callback.component';
import { PracticeManagementV2Component } from './practice-management-v2/practice-management-v2.component';
import { PatientManualVerificationViewComponent } from './patient-manual-verification-view/patient-manual-verification-view.component';
import { NewsetupMovetoComponent } from './newsetup-moveto/newsetup-moveto.component';
import { PatientDetailsComponent } from './patient-details/patient-details.component';
import { ToggleSwitchComponent } from 'projects/admin/src/app/toggle-switch/toggle-switch.component';
import { NewVerificationComponent } from './newVerification/newVerification.component';
import { MyverficationV1Component } from './myverfication-v1/myverfication-v1.component';
import { MiverificationListComponent } from './miverification-list/miverification-list.component';
import { PracticeManagementListComponent } from './practice-management-list/practice-management-list.component';
import { PatientListTableComponent } from './patient-search-tab/patient-list-table/patient-list-table.component';
import { PatientListV2Component } from './patient-list-v2/patient-list-v2.component';
import { AddPracticeManagementComponent } from './add-practice-management/add-practice-management.component';
import { PatientListTableV2Component } from './patient-list-v2/patient-list-table-v2/patient-list-table-v2.component';
import { PracticeManagementPhysicianComponent } from './practice-management-physician/practice-management-physician.component';
import { PracticeManagementRequirementsComponent } from './practice-management-requirements/practice-management-requirements.component';
import { PracticeManagementMoreInfoComponent } from './practice-management-more-info/practice-management-more-info.component';
import { PracticeManagementNotesComponent } from './practice-management-notes/practice-management-notes.component';
import { PracticeManagementFaxlogComponent } from './practice-management-faxlog/practice-management-faxlog.component';
import { PracticeManagementFaxnumberComponent } from './practice-management-faxnumber/practice-management-faxnumber.component';
import { PracticeAddPhysicianComponent } from './practice-add-physician/practice-add-physician.component';
import { PatientManagementTableComponent } from './patient-list-v1/patient-management-table/patient-management-table.component';
import { ReportPracticeManagementComponent } from './report-practice-management/report-practice-management.component';
import { TatPracticeManagementComponent } from './tat-practice-management/tat-practice-management.component';
import { PhysicianPracticeManagementComponent } from './physician-practice-management/physician-practice-management.component';
import { PatientdetailsPracticeManagementComponent } from './patientdetails-practice-management/patientdetails-practice-management.component';
import { PracticeManagementV1Component } from './practice-management-v1/practice-management-v1.component';
import { MyverficationtableComponent } from './myverfication-v1/myverficationtable/myverficationtable.component';
import { AddPracticeNotesComponent } from './add-practice-notes/add-practice-notes.component';
import { MedicareCheckListTableComponent } from './medicare-check-list/medicare-check-list-table/medicare-check-list-table.component';
import { PatientAuthorizationTableComponent } from './patient-authorization-table/patient-authorization-table.component';
import { DatepickerDialogComponent } from './patient-list-v1/patient-management-table/datepicker-dialog.component';
import { PatientNpiDoctorTableComponent } from './patient-npi-doctor-table/patient-npi-doctor-table.component';
import { MasterMedicareChecklistComponent } from './master-medicare-checklist/master-medicare-checklist.component';
import { MmOnDemandComponent } from './master-medicare-checklist/mm-on-demand/mm-on-demand.component';
import { MmFaxProcessComponent } from './master-medicare-checklist/mm-fax-process/mm-fax-process.component';
import { MmPendingComponent } from './master-medicare-checklist/mm-pending/mm-pending.component';
import { MmApprovedDisapprovedComponent } from './master-medicare-checklist/mm-approved-disapproved/mm-approved-disapproved.component';
import { MasterVerification_doVerificationComponent } from './masterVerification_doVerification/masterVerification_doVerification.component';
import { UpdatePracticePhysicianComponent } from './update-practice-physician/update-practice-physician.component';
import { PatientListModelComponent } from './patient-list-model/patient-list-model.component';
import { PatientNewInsuranceReviewComponent } from './patient-new-insurance-review/patient-new-insurance-review.component';
import { PatientNewInsuranceTableComponent } from './patient-new-insurance-table/patient-new-insurance-table.component';
import { PatientInsuranceHistoryComponent } from './patient-insurance-history/patient-insurance-history.component';
import { PatientInsuranceHistoryTableComponent } from './patient-insurance-history-table/patient-insurance-history-table.component';
import { MmApprovedDisapprovedNotesComponent } from './mm-approved-disapproved-notes/mm-approved-disapproved-notes.component';
import { NewSetupDocTiffViewerComponent } from './newsetup-modal/new-setup-doc-tiff-viewer/new-setup-doc-tiff-viewer.component';
import { PracticeFaxNumberTableComponent } from './practice-fax-number-table/practice-fax-number-table.component';
import { PatientListModelTableComponent } from './patient-list-model/patient-list-model-table/patient-list-model-table.component';
import { PatientInsuranceTabTableComponent } from './patient-insurance-tab/patient-insurance-tab-table/patient-insurance-tab-table.component';
import { OndemandOnlineVerificationComponent } from './ondemand-online-verification/ondemand-online-verification.component';
import { MasterVerification_doAuthorizationComponent } from './masterVerification_doAuthorization/masterVerification_doAuthorization.component';
import { PatientEligibilityHistoryListComponent } from './patient-eligibility-history-list/patient-eligibility-history-list.component';
import { InboundSortingPendingComponent } from './patient-list-v2/inbound-sorting-pending/inbound-sorting-pending.component';
import { InboundSortingPendingTableComponent } from './patient-list-v2/inbound-sorting-pending/inbound-sorting-pending-table/inbound-sorting-pending-table.component';
import { InboundBatchSortingComponent } from './inbound-batch-sorting/inbound-batch-sorting.component';
import { PracticeManagementViewPatientListComponent } from './practice-management-view-patient-list/practice-management-view-patient-list.component';
import { PracticeViewPatientListTableComponent } from './practice-management-view-patient-list/practice-view-patient-list-table/practice-view-patient-list-table.component';
import { InboundBatchSortingTiffComponent } from './inbound-batch-sorting-tiff/inbound-batch-sorting-tiff.component';
import { PatientDocumentTiffSinglePreviewComponent } from './patient-auth-save-update-form/patient-document-tiff-single-preview/patient-document-tiff-single-preview.component';
import { PatientSearchNewsetupComponent } from './newsetup-modal/patient-search-newsetup/patient-search-newsetup.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { PatientSearchNewsetupTableComponent } from './newsetup-modal/patient-search-newsetup/patient-search-newsetup-table/patient-search-newsetup-table.component';
import { TextConversationComponent } from './text-conversation/text-conversation.component';
import { TextConversationMasterComponent } from './text-conversation-master/text-conversation-master.component';
import { TextConversationAdminComponent } from './text-conversation-admin/text-conversation-admin.component';
import { TextConversationPatientDetailsComponent } from './text-conversation-patient-details/text-conversation-patient-details.component';
import { TextConversationCsrComponent } from './text-conversation-csr/text-conversation-csr.component';
import { TemplateCreationComponent } from './template-creation/template-creation.component';
import { TemplateCreationTableComponent } from './template-creation/template-creation-table/template-creation-table.component';
import { ViewTemplateComponent } from './template-creation/view-template/view-template.component';
import { MasterResupplyComponent } from './master-resupply/master-resupply.component';
import { MasterResupplyTableComponent } from './master-resupply/master-resupply-table/master-resupply-table.component';
import { MasterDoctorComponent } from './master-doctor/master-doctor.component';
import { MasterDoctorTableComponent } from './master-doctor/master-doctor-table/master-doctor-table.component';


const providers: any[] = [];
const firebaseConfig: FirebaseAppConfig = {
  apiKey: 'AIzaSyCC22MHvVm6kDQetncVgYYnHEce6zivnLg',
  authDomain: 'elevated-surge-309905.firebaseapp.com',
  projectId: 'elevated-surge-309905',
  storageBucket: 'elevated-surge-309905.appspot.com',
  messagingSenderId: '769276800215',
  appId: '1:769276800215:web:bafff1efb20b997b9b1492',
  measurementId: '769276800215',
};

@NgModule({
  declarations: [	
    AppComponent,
    ColorCodesMasterComponent,
    PatientPersonalComponent,
    PatientSearchComponent,
    PatientCreateEditComponent,
    PatientContactComponent,
    PatientClinicalComponent,
    PatientDoctorComponent,
    PatientNpiDoctorComponent,
    PatientIcd9Component,
    PatientIcd10Component,
    PatientVerifyMenuComponent,
    PatientAuthMenuComponent,
    TaskListPageTabComponent,
    PatientVerifySearchComponent,
    PatientInsurnaceComponent,
    PatientAddPolicyComponent,
    PatientVerificationComponent,
    PatientEligibilityHistoryComponent,
    PatientPverifyComponent,
    PatientHistoryComponent,
    PatientOrderComponent,
    PatientOrderStatusComponent,
    PatientDocumentComponent,
    PatientOrderComponent,
    UserTaskReportTabComponent,
    ConfirmationDialogComponent,
    AddAuthComponent,
    PatientAuthComponent,
    UserTaskListComponent,
    DocumentPreviewComponent,
    PatientNotesComponent,
    AddNotesComponent,
    PatientImportComponent,
    PatientVImportComponent,
    ReferralPracticeListComponent,
    ReferralPracticeComponent,
    PracticeManagementComponent,
    ReferralFaxLogComponent,
    ReferralFaxNumberComponent,
    PracticePhysiciansComponent,
    PracticeAddDoctorComponent,
    ImportMisReportComponent,
    ImportPivotTableComponent,
    PatientSourcemdPopupComponent,
    PatientsTabComponent,
    PatientClinicalTabComponent,
    PatientSearchTabComponent,
    PatientCreateEditTabComponent,
    DocumentFollowupComponent,
    OrderFollowupComponent,
    PatientClaimsComponent,
    AddNoteTabComponent,
    PatientAddPolicyTabComponent,
    PatientInsuranceTabComponent,
    PatientManualVerificationTabComponent,
    PatientNotesTabComponent,
    PatientOrderTabComponent,
    PatientPverifyTabComponent,
    SummaryComponent,
    PatientNotesModalComponent,
    DocumentTabComponent,
    PatientEligibilityListTabComponent,
    CommonNotesSearchComponent,
    TaskViewerComponent,
    ScheduleTaskComponent,
    ScheduledTaskListComponent,
    PatientAuthorizationTabComponent,
    PatientClaimComponent,
    PatientOrdersComponent,
    CreateEditProductTabComponent,
    ResupplyProductTabComponent,
    DocumentSortingComponent,
    WipAllocationModalComponent,
    WipListModalComponent,
    AddDocNpiComponent,
    NpiDetailsComponent,
    PatientAuthorizationDetailsComponent,
    PatientAuthorizationListComponent,
    PatientAuthSaveFormComponent,
    TimerComponent,
    // StatusComponent,
    CommonNotesSearchV1Component,
    WipAllocationModalV2Component,
    CustomerServicePoolComponent,
    NewsetupModalComponent,
    PatientAuthSaveUpdateFormComponent,
    PatientListV1Component,
    AdminDashboardPatientManualVerificationComponent,
    VerificationComponent,
    MIVerificationComponent,
    MedicareCheckListComponent,
    MedicareQuestionModelComponent,
    MedicareCheckFinalModelComponent,
    CallbackComponent,
    PracticeManagementV2Component,
    PatientManualVerificationViewComponent,
      NewsetupMovetoComponent,
      PatientDetailsComponent,
      NewVerificationComponent,
      MyverficationV1Component,
      MiverificationListComponent,
      PracticeManagementListComponent,
      PatientListTableComponent,
      PatientListV2Component,
      AddPracticeManagementComponent,
      PatientListTableV2Component,
      PatientManagementTableComponent,
      PracticeManagementPhysicianComponent,
      PracticeManagementRequirementsComponent,
      PracticeManagementMoreInfoComponent,
      PracticeManagementNotesComponent,
      PracticeManagementFaxlogComponent,
      PracticeManagementFaxnumberComponent,
      PracticeAddPhysicianComponent,
      ReportPracticeManagementComponent,
      TatPracticeManagementComponent,
      PhysicianPracticeManagementComponent,
      PatientdetailsPracticeManagementComponent,
      PracticeManagementV1Component,
      MyverficationtableComponent,
      AddPracticeNotesComponent,
      MedicareCheckListTableComponent,
      PatientAuthorizationTableComponent,
      DatepickerDialogComponent,
      PatientNpiDoctorTableComponent,
      MasterMedicareChecklistComponent,
      MasterVerification_doVerificationComponent,
      MmApprovedDisapprovedComponent,
      MmFaxProcessComponent,
      MmOnDemandComponent,
      MmPendingComponent,
      UpdatePracticePhysicianComponent,
      PatientListModelComponent,
      PatientNewInsuranceReviewComponent,
      PatientNewInsuranceTableComponent,
      PatientInsuranceHistoryComponent,
      PatientInsuranceHistoryTableComponent,
      MmApprovedDisapprovedNotesComponent,
      NewSetupDocTiffViewerComponent,
      PracticeFaxNumberTableComponent,
      PatientListModelTableComponent,
      PatientInsuranceTabTableComponent,
      OndemandOnlineVerificationComponent,
      MasterVerification_doAuthorizationComponent,
      PatientEligibilityHistoryListComponent,
      InboundSortingPendingComponent,
      InboundSortingPendingTableComponent,
      InboundBatchSortingComponent,
      PracticeManagementViewPatientListComponent,
      PracticeViewPatientListTableComponent,
      InboundBatchSortingTiffComponent,
      PatientDocumentTiffSinglePreviewComponent,
      PatientSearchNewsetupComponent,
      PatientSearchNewsetupTableComponent,
      TextConversationComponent,
      TextConversationMasterComponent,
      TextConversationAdminComponent,
      TextConversationPatientDetailsComponent,
      TextConversationCsrComponent,
      TemplateCreationComponent,
      TemplateCreationTableComponent,
      ViewTemplateComponent,
      MasterResupplyComponent,
      MasterResupplyTableComponent,
      MasterDoctorComponent,
      MasterDoctorTableComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DragDropModule,
    MatChipsModule,
    DxDataGridModule,
    ReactiveFormsModule,
    RouterModule,
    MatTreeModule,
    NgxExtendedPdfViewerModule,
    MatSlideToggleModule,
    BrowserAnimationsModule,
    NgxMaterialTimepickerModule,
    HorizontolModule.forRoot(),
    // BackButtonDisableModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatBadgeModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    SharedModule,
    NgbModule,
    NgbNavModule,
    MatExpansionModule,
    MatListModule,
    MatButtonModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatTableModule,
    MatSnackBarModule,
    MatDialogModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatOptionModule,
    MatDatepickerModule,
    MatCardModule,
    MatPseudoCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatRadioModule,
    NgxMatSelectSearchModule,
    NgxMatDatetimePickerModule,
    MatMenuModule,
    DataTablesModule,
    NgxMatTimepickerModule,
    NgxMatColorPickerModule,
    NgxMatNativeDateModule,
    NgxMaskModule.forRoot({ showMaskTyped: true }),
    NgxsFormPluginModule.forRoot(),
    NgxsModule.forRoot([
      PatientState,
      orderState,
      billingState,
      reminderState,
      NotificationsState,
    ]),
    TextMaskModule,
    NgSelectModule,
    CoreModule.forRoot({ environment, registerLocaleFn: registerLocale() }),
    OAuthModule.forRoot(),
    AdminModule.forRoot(),
    HorizontolModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: true,
      closeButton: true,
    }),
    OrderModule,
    ShippingModule,
    onTimeScheduleModule,
    MatSortModule,
    MatProgressBarModule,
    MatTableExporterModule,
    NgxDaterangepickerMd.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: GoogleAPIKey,
      language: 'en',
      libraries: ['places', 'drawing', 'geometry'],
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  exports: [],
  providers: [
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    APP_ROUTE_PROVIDER,
    { provide: MAT_DATE_LOCALE, useValue: 'en-US', multi: true },
    { provide: AuthConfig, useValue: environment.oAuthConfig },
    { provide: OAuthModuleConfig, useValue: OAuthModuleConfig },
    { provide: ValidationHandler, useClass: JwksValidationHandler },
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    //{ provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    GoogleMapsAPIWrapper,
    MarkerManager,
    LazyMapsAPILoader,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

@NgModule({})
export class PatientModule {
  static forChild(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers,
    };
  }
}
