<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <mat-accordion #firstAccordion="matAccordion">
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <ng-container>
                <b>Existing Patient List Search</b>
              </ng-container>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row mt-1">
            <div class="col-lg-12 pb-5">
              <form [formGroup]="personalForm">
                <mat-form-field class="filter">
                  <input
                    matInput
                    formControlName="patientName"
                    autocomplete="new-patientName"
                  />
                  <mat-placeholder class="center"
                    >General Search</mat-placeholder
                  >
                </mat-form-field>
                <div class="example-container mat-elevation-z4">
                  <table
                    mat-table
                    [dataSource]="dataSource"
                    matTableExporter
                    #exporter="matTableExporter"
                    matSort
                    [hiddenColumns]="[2]"
                    class="table mat-elevation-z8"
                  >
                    <ng-container matColumnDef="patientName">
                      <th
                        class="header"
                        mat-header-cell
                        mat-sort-header
                        *matHeaderCellDef
                      >
                        Patient Name
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <a
                          matTooltip="Click to View"
                          class="eyeCursorclass"
                          [routerLink]="
                            '/patientCreateEditTab/' + element?.patientId
                          "
                          target="_blank"
                        >
                          {{ element?.patientName }}
                        </a>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="defaultPatientId">
                      <th
                        class="header"
                        mat-header-cell
                        mat-sort-header
                        *matHeaderCellDef
                      >
                        Patient Id
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <a
                          matTooltip="Click to View"
                          class="eyeCursorclass"
                          [routerLink]="
                            '/patientCreateEditTab/' + element?.patientId
                          "
                          target="_blank"
                        >
                          {{ element?.defaultPatientId }}
                        </a>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="dob">
                      <th
                        class="header"
                        mat-header-cell
                        mat-sort-header
                        *matHeaderCellDef
                      >
                        Date of Birth
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element?.dob }}
                        <!-- | date : "MM/dd/yyyy" : "en_US" }} -->
                      </td>
                    </ng-container>
                    <tr
                      mat-header-row
                      *matHeaderRowDef="patientSearchTable"
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: patientSearchTable"
                    ></tr>
                  </table>
                  <div
                    *ngIf="
                      !(
                        dataSource &&
                        dataSource?.data &&
                        dataSource?.data?.length !== 0
                      )
                    "
                    class="text-align-center"
                  >
                    <div class="message py-3" *ngIf="!isLoading">
                      {{ "No Records Found" }}
                    </div>
                    <div class="py-3" [hidden]="!isLoading">
                      <mat-spinner [diameter]="220"></mat-spinner>
                    </div>
                  </div>
                  <mat-paginator
                    [pageSizeOptions]="[5, 10, 50, 100]"
                    showFirstLastButtons
                  ></mat-paginator>
                </div>
              </form>
            </div>
          </div>
          <!-- <div class="row mt-1">
            <div class="col-lg-12 pb-5">
              <form [formGroup]="personalForm">
                <div class="example-container mat-elevation-z4">
                  <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort
                    [hiddenColumns]="[2]" class="table mat-elevation-z8">
                    <ng-container matColumnDef="patientName">
                      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input matInput formControlName="patientName" autocomplete="off" />
                          <mat-placeholder class="center">Patient Name</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element?.patientName }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="defaultPatientId">
                      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input matInput formControlName="defaultPatientId" autocomplete="off" />
                          <mat-placeholder class="center">Patient Id</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <a matTooltip="Click to View" class="eyeCursorclass"
                          [routerLink]="'/patientCreateEditTab/'+element?.patientId" target="_blank">
                          {{ element?.defaultPatientId }}
                        </a>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="ssn">
                      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input matInput formControlName="ssn" autocomplete="off" />
                          <mat-placeholder class="center">SSN</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element?.ssn }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="dob">
                      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <mat-label>Date of Birth</mat-label>
                          <input matInput formControlName="dob" placeholder="(MM/DD/YYYY)" autocomplete="off" />
                          <mat-error *ngIf="
                              personalForm?.get('dob')?.touched &&
                              personalForm?.get('dob')?.errors?.dateVaidator
                            ">
                            Enter Valid date
                          </mat-error>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element?.dob | date : "MM/dd/yyyy" : "en_US" }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input matInput formControlName="email" autocomplete="off" />
                          <mat-placeholder class="center">Email Address</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element?.emailId }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="mobile">
                      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input matInput formControlName="mobile" autocomplete="off" />
                          <mat-placeholder class="center">Mobile</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element?.mobile }}
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="patientSearchTable"></tr>
                    <tr mat-row *matRowDef="let row; columns: patientSearchTable"></tr>
                  </table>
                  <div *ngIf="
                      !(
                        dataSource &&
                        dataSource?.data &&
                        dataSource?.data?.length !== 0
                      )
                    " class="text-align-center">
                    <div class="message py-3" *ngIf="!isLoading">
                      {{ "No Records Found" }}
                    </div>
                    <div class="py-3" [hidden]="!isLoading">
                      <mat-spinner [diameter]="220"></mat-spinner>
                    </div>
                  </div>
                  <mat-paginator [pageSizeOptions]="[5, 10, 50, 100]" showFirstLastButtons></mat-paginator>
                </div>
              </form>
            </div>
          </div> -->
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
