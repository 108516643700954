import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { forkJoin, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import {
  OrderDropdowns,
  PatientDropdowns,
} from '../dropdown-proxy/dropdown-management/dropdowns';
import {
  ChartDTO,
  OrderMasterDropdownDTO,
  PayablePercentDTO,
  PlanTypeDTO,
  TypeOfPlanDTO,
  YearTypeDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/order';
import {
  PatientMasterDropdownDTO,
  PayorLevelDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import {
  OrderMasterDropdownService,
  PatientMasterDropdownService,
} from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import {
  PatientPersonalDTO,
  PatientPolicyDTO,
  CreateUpdatePatientVerificationDTO,
} from '../patient-proxy/patient-optimization/dto';
import { PatientPersonalService } from '../patient-proxy/patient-optimization/patient-personal.service';
import { PatientPolicyService } from '../patient-proxy/patient-optimization/patient-policy.service';
import { PatientVerificationService } from '../patient-proxy/patient-optimization/patient-verification.service';
import {
  InsuranceType,
  VerifyType,
  verifyTypeOptions,
} from '../patient-proxy/patient/enumeration-data';
import { VerificationStatus } from '../patient-proxy/patient/enumeration-data/verification-status.enum';
import { ToastrService } from 'ngx-toastr';

import { drpPolicesDTO } from '../patient-verification/patient-verification.component';
import { PatientManualVerificationTabComponent } from '../patient-manual-verification-tab/patient-manual-verification-tab.component';

@Component({
  selector: 'app-admin-dashboard-patient-manual-verification',
  templateUrl: './admin-dashboard-patient-manual-verification.component.html',
  styleUrls: ['./admin-dashboard-patient-manual-verification.component.scss']
})
export class AdminDashboardPatientManualVerificationComponent implements OnInit {

  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  patientId: string = defaultGuid;
  policyId: string = defaultGuid;
  verificationId: string = defaultGuid;
  Instype:any='';
  verificationForm: FormGroup;
  defaultPatientId: string = '';

  plan: string = '';
  payorPlan: string = '';
  verfnAMethod: string = '';
  notesText = '';
  type: string = '';
  typeOfPlan: string = '';
  payablePercentType: string = '';
  payablePercentType1: string = '';
  payablePercentType2: string = '';
  drpPayorLevel: string;
  drpPayorName: string;
  drpPolicyNo: string;
  drpPhoneNo: string;
  verifiedMethod: string;
  isShowSpinner: boolean = false;
  isPatientLockedByCurrentUser: boolean = true;
  payorLevel: PayorLevelDTO[];
  drpChart: ChartDTO[] = [];
  drpPlan: TypeOfPlanDTO[] = [];
  drpPayable: PayablePercentDTO[] = [];
  drpPolices: drpPolicesDTO[] = [];
  drpPlanType: PlanTypeDTO[] = [];
  drpYearType: YearTypeDTO[] = [];
  drpPolicies: PatientPolicyDTO[] = [];
  subscription$: Subscription[] = [];
  userName:string='';

  constructor(
    private formBuilder: FormBuilder,
    private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    private patientDropdownService: PatientMasterDropdownService,
    private orderDropdownService: OrderMasterDropdownService,
    public manualVerificationDialog: MatDialogRef<PatientManualVerificationTabComponent>,
    private patientPersonalService: PatientPersonalService,
    private patientPolicyService: PatientPolicyService,
    private patientVerificationService: PatientVerificationService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      verificationId: string;
      policyId: string;
      savehidden?:boolean;
      Instype?:any;
    } = {
      patientId: defaultGuid,
      verificationId: defaultGuid,
      policyId: defaultGuid,
      savehidden:false,
      Instype:'',
    }
  ) {

  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {
  //   if(this.data.savehidden===true){
  //   this.getInsVerificationByID();
  // }


     // Retrieve the data from localStorage
     const localStorageData = localStorage.getItem('smrtlkDls');
     // Parse the JSON data
     const parsedData = JSON.parse(localStorageData);
     // Access the 'name' property
     this.userName = parsedData?.name || '';
    this.createVerificationForm();

    this.patientId = this.data?.patientId ?? defaultGuid;
    this.policyId = this.data?.policyId ?? defaultGuid;
    this.verificationId = this.data?.verificationId ?? defaultGuid;
    this.patientId && this.patientId !== defaultGuid && this.getDropdown();
    this.Instype = this.data?.Instype ?? '' ;

    // this.getInsVerificationByID();



  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  getInsVerificationByID() {
    this.isShowSpinner = true;
    // this.patientVerificationService.getVerificationDetailsByPatientIdByPatientId(this.data.patientId).subscribe(
    this.patientVerificationService.getLastVerificationDataByPatientIdByPatientIDAndType(this.patientId, this.Instype).subscribe(
      (response) => {

        const patientInfo = response[0];

        this.verifiedMethod = patientInfo.vMethod;

        // this.fiscalYear = patientInfo?.fascalYearStart ? patientInfo?.fascalYearStart : this.verificationForm.value.txtFascalYearStart

        const savedPayorLevel: string = this.payorLevel?.filter(
          (x) =>
            (x?.payorLevelName?.toLowerCase() ?? '') ===
            (patientInfo?.plan?.toLowerCase() ?? '')
        )?.[0]?.payorLevelName;


        savedPayorLevel &&
          this.payorValueChangeDropdown(patientInfo?.planName);
        this.verificationForm.patchValue({
          txtName: patientInfo?.name,
            txtDateOfBirth: patientInfo.dateofBirth,
            txtCommentsNotes: patientInfo?.commentsOrNotes,
            txtPlan: savedPayorLevel,
            txtPlanName: patientInfo?.planName,
            txtPlanPhone: patientInfo?.planPhone,
            txtPolicy: patientInfo?.policy,
            txtGroup: patientInfo?.groupNo,
            txtEffectiveDate: patientInfo?.effectiveDate,
            txtType: patientInfo?.typeOfPolicy,
            txtFascalYearStart: patientInfo?.fascalYearStart,
            txtEnd: patientInfo?.fascalYearEnd,
            chkMedicare: patientInfo?.medicareCMNonFile,
            chkPatient: patientInfo?.isPatientHomeHealthEpisode,
            chkFamilyDeductible: patientInfo?.familyDeductCoversIndividual,
            txtCmnCode: patientInfo?.cmnCode,
            drpTypeOfPlan: patientInfo?.typeOfPlan,
            chkIndividual: patientInfo?.isIndividual,
            chkOutOfNetwork: patientInfo?.isOutOfNetwork,
            chkFamily: patientInfo?.isFamily,
            txtDeductibleIndividual: patientInfo?.deductible,
            txtDeductibleOutOfNetwork:
              patientInfo?.oonDeductible,
            txtDeductibleFamily: patientInfo?.familyDeductible,
            txtDeductibleMetIndividual: patientInfo?.deductibleMet,
            txtDeductibleMetOutOfNetwork:
              patientInfo?.oonDeductibleMet,
            txtDeductibleMetFamily: patientInfo?.familyDeductibleMet,
            drpPayableIndividual: patientInfo?.payableAt,
            drpPayableOutOfNetwork: patientInfo?.oonPayableAt,
            drpPayableFamily: patientInfo?.familyPayableAt,
            txtExpenseIndividual: patientInfo?.outOfPocket,
            txtExpenseOutOfNetwork:
              patientInfo?.outOfPocketMet,
            txtExpenseFamily: patientInfo?.familyOutOfPocket,
            txtExpenseMetIndividual:
              patientInfo?.familyOutOfPocketMet,
            txtExpenseMetOutOfNetwork:
              patientInfo?.oonDeductible,
            txtExpenseMetFamily: patientInfo?.oonDeductibleMet,
            chkExpenseIndividual:
              patientInfo?.doesOutOfPocketIncludeDed,
            chkExpenseOutOfNetwork:
              patientInfo?.doesOutOfPocketIncludeDed,
            chkExpenseFamily: patientInfo?.doesFamilyOutOfPocketIncludeDed,
            chkPrecertRequired: patientInfo?.isPrecertRequired,
            txtPhoneCall: patientInfo?.phoneNumber,
            chkReferralRequired: patientInfo?.referralRequired,
            txtPcp: patientInfo?.pcp,
            chkPredetermination: patientInfo?.predetermination,
            txtPreNotes: patientInfo?.predeterminationNotes,
            txtLifetimeMax: patientInfo?.lifetimeMaxBenefit,
            txtLifetimeMaxMet: patientInfo?.lifetimeBenefitUsed,
            chkDmePartsCovered: patientInfo?.dmeCoverage,
            chkDmeReplacementUnit: patientInfo?.dmeUnitReplacement,
            txtDmeAnnualLimit: patientInfo?.dmeLimitPerYear,
            txtDmeLimitMet: patientInfo?.dmeMax,
            txtRequestedBy: patientInfo?.requestedBy,
            txtPrimaryPayor: patientInfo?.primaryPayor,
            txtContact: patientInfo?.contact,
            txtCallTrackingNumber: patientInfo?.callTrackingNumber,
            txtPolicyHolderName: patientInfo?.policyHolderName,
            txtPolicyHolderDob: patientInfo?.policyHolderDob,
            chkNetwork: patientInfo?.isNetwork,
            txtClaimAddress: patientInfo?.claimAddress,
            txtPlanCob: patientInfo?.planCob,
            txtCoverageCriteria: patientInfo?.coverageCriteria,
            txtQtyLimits: patientInfo?.qtyLimits,
            txtFrequency: patientInfo?.frequency,
            txtRent: patientInfo?.rent,
            txtPaRequired: patientInfo?.paRequired,
            txtCodesRequirePa: patientInfo?.codesRequirePa,
            txtSameOrSimilarInfo: patientInfo?.sameOrSimilarInfo,
            txtSpokeWith: patientInfo?.spokeWith,
            drpVerifiedBy: patientInfo?.verifiedBy,
            txtDateVerified: patientInfo?.verifiedOn,
            drpPreparedBy: patientInfo?.approvedBy,
            txtPreparedOn: patientInfo?.approvedOn,
            rbtnVerificationStatus: patientInfo?.verificationStatus,
            txtDeo: patientInfo?.deo,
            txtcommentsToTech: patientInfo?.commentToTech,
            txtVerfnAMethod: patientInfo?.vMethod,
            // txtDeductibleRemainingFamily:
            //   patientInfo?.de,
            // txtDeductibleRemainingOutOfNetwork:
            //   patientInfo?.deductibleRemaining?.outOfNetwork,
            // txtDeductibleRemainingIndividual:
            //   patientInfo?.deductibleRemaining?.individual,
            // txtRemainingIndividual:
            //   patientInfo?.outOfPocketRemaining?.individual,
            // txtRemainingFamily: patientInfo?.outOfPocketRemaining?.family,
            // txtRemainingOutOfNetwork:
            //   patientInfo?.outOfPocketRemaining?.outOfNetwork,
            txtCoPayment: patientInfo.coPayment,
            txtCoInsurance: patientInfo.coInsurance,
            chkActive: patientInfo?.isActive,
        });
        if (
          this.verificationForm.value.txtFascalYearStart != null ||
          this.verificationForm.value.txtFascalYearStart != '' ||
          this.verificationForm.value.txtFascalYearStart != undefined
        ) {
          this.isGroupData = true;
        } else {
          this.isGroupData = false;
        }

        this.isShowSpinner = false;
      },
      (err) => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  isGroupData: boolean = false;
  onChange() {
    // this.verificationForm.controls['txtGroup'].setValidators(
    //   Validators.required
    // );
    // this.verificationForm.controls['txtEnd'].setValidators(Validators.required);
    // this.isGroupData = true;
  }

  // valueChangeLoadDropdown(type: string) {
  //   // this.drpPolicies = [];
  //   this.patientPolicyService
  //     .getActivePolicyByPatientIdByPatientId(this.patientId)
  //     .subscribe(
  //       (response) => {
  //         const policyDetails: PatientPolicyDTO[] = response;
  //         this.drpPolicies =
  //           policyDetails?.filter(
  //             (x) =>
  //               (x?.payorLevel?.toLowerCase() ?? '') ===
  //               (type?.toLowerCase() ?? '')
  //           ) ?? [];
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  // }

  payorValueChangeDropdown(payorName: string) {
    const savedPolicy =
      this.drpPolicies?.filter(
        (x) =>
          (x?.payorName?.toLowerCase() ?? '') ===
          (payorName?.toLowerCase() ?? '')
      )?.[0] ?? null;
    this.verificationForm.patchValue({
      txtPolicy: savedPolicy?.policyNo ?? '',
      txtPlanPhone: savedPolicy?.phone ?? '',
    });
  }
  //Manual Verification Form Load
  createVerificationForm() {
    const currentDate = new Date().toISOString().split('T')[0];
    this.verificationForm = this.formBuilder.group({
      txtName: new FormControl('', [this.textValidators.isTextCheck]),
      txtDateOfBirth: new FormControl('', [Validators.required]),
      txtCommentsNotes: new FormControl(''),
      txtPlan: new FormControl('', Validators.required),
      txtPlanName: new FormControl('', [Validators.required]),
      txtPlanPhone: new FormControl('', [this.numberValidators.isNumberCheck]),
      txtPolicy: new FormControl('', [Validators.required]),
      txtGroup: new FormControl(''),
      txtEffectiveDate: new FormControl('', [Validators.required]),
      txtType: new FormControl(null, [Validators.required]),
      txtFascalYearStart: new FormControl(''),
      txtEnd: new FormControl(''),
      chkMedicare: new FormControl(''),
      chkPatient: new FormControl(''),
      chkFamily: new FormControl(''),
      txtCmnCode: new FormControl('', [this.textValidators.isTextCheck]),
      drpTypeOfPlan: new FormControl(null, [Validators.required]),
      chkIndividual: new FormControl(''),
      chkOutOfNetwork: new FormControl(''),
      chkFamilyDeductible: new FormControl(''),
      txtDeductibleIndividual: new FormControl(''),
      txtDeductibleOutOfNetwork: new FormControl(''),
      txtDeductibleFamily: new FormControl(''),
      txtDeductibleMetIndividual: new FormControl(''),
      txtDeductibleMetOutOfNetwork: new FormControl(''),
      txtDeductibleMetFamily: new FormControl(''),
      drpPayableIndividual: new FormControl(null),
      drpPayableOutOfNetwork: new FormControl(null),
      drpPayableFamily: new FormControl(null),
      txtExpenseIndividual: new FormControl(''),
      txtExpenseOutOfNetwork: new FormControl(''),
      txtExpenseFamily: new FormControl(''),
      txtExpenseMetIndividual: new FormControl(''),
      txtExpenseMetOutOfNetwork: new FormControl(''),
      txtExpenseMetFamily: new FormControl(''),
      chkExpenseIndividual: new FormControl(''),
      chkExpenseOutOfNetwork: new FormControl(''),
      chkExpenseFamily: new FormControl(''),
      chkPrecertRequired: [true],
      txtPhoneCall: new FormControl('', [this.numberValidators.isNumberCheck]),
      chkReferralRequired: new FormControl(''),
      txtPcp: new FormControl('', [this.textValidators.isTextCheck]),
      chkPredetermination: new FormControl(''),
      txtPreNotes: new FormControl(''),
      txtLifetimeMax: new FormControl(''),
      txtLifetimeMaxMet: new FormControl(''),
      chkDmePartsCovered: new FormControl(''),
      chkDmeReplacementUnit: new FormControl(''),
      txtDmeAnnualLimit: new FormControl(''),
      txtDeductibleRemainingFamily: new FormControl(''),
      txtDeductibleRemainingIndividual: new FormControl(''),
      txtDeductibleRemainingOutOfNetwork: new FormControl(''),
      txtRemainingIndividual: new FormControl(''),
      txtRemainingFamily: new FormControl(''),
      txtRemainingOutOfNetwork: new FormControl(''),
      txtCoPayment: new FormControl(''),
      txtCoInsurance: new FormControl(''),
      txtDmeLimitMet: new FormControl(''),
      txtRequestedBy: new FormControl('', [this.textValidators.isTextCheck]),
      txtPrimaryPayor: new FormControl('', [this.textValidators.isTextCheck]),
      txtContact: new FormControl('', [this.numberValidators.isNumberCheck]),
      txtCallTrackingNumber: new FormControl(''),
      txtPolicyHolderName: new FormControl('', [
        this.textValidators.isTextCheck,
      ]),
      txtPolicyHolderDob: new FormControl(''),
      chkNetwork: new FormControl(''),
      txtClaimAddress: new FormControl(''),
      txtPlanCob: new FormControl(''),
      txtCoverageCriteria: new FormControl('', [
        this.textValidators.isTextCheck,
      ]),
      txtQtyLimits: new FormControl('', [this.textValidators.isTextCheck]),
      txtFrequency: new FormControl('', [this.textValidators.isTextCheck]),
      txtRent: new FormControl('', [this.textValidators.isTextCheck]),
      txtPaRequired: new FormControl('', [this.textValidators.isTextCheck]),
      txtCodesRequirePa: new FormControl('', [this.textValidators.isTextCheck]),
      txtSameOrSimilarInfo: new FormControl('', [
        this.textValidators.isTextCheck,
      ]),
      txtSpokeWith: new FormControl('', [this.textValidators.isTextCheck]),
      drpVerifiedBy: new FormControl(this.userName, [
        // this.textValidators.isTextCheck,
        Validators.required,
      ]),
      txtDateVerified: new FormControl(currentDate, [Validators.required]),
      drpPreparedBy: new FormControl('', [this.textValidators.isTextCheck]),
      txtPreparedOn: new FormControl(''),
      rbtnVerificationStatus: new FormControl('', [Validators.required]),
      txtDeo: new FormControl('', this.numberValidators.isNumberCheck),
      txtcommentsToTech: new FormControl(''),
      txtVerfnAMethod: new FormControl('Manual', [Validators.required]),
      chkActive: new FormControl(''),
    });
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() - 1);
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
  }

  //Load Dropdowns
  getDropdown() {
    const orderDropdownInput: OrderDropdowns[] = [
      OrderDropdowns.Charts,
      OrderDropdowns.TypeOfPlans,
      OrderDropdowns.PlanTypes,
      OrderDropdowns.PayablePercents,
      OrderDropdowns.YearTypes,
    ];
    const orderDropdown =
      this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput);
    //To get the Patient and Policy Details
    //Patient Details
    const patientDetails = this.patientPersonalService.get(this.patientId);
    //To get and update patient Dropdowns
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.PayorLevels,
    ];
    const patietDropdown =
      this.patientDropdownService.getPatientDropdownsByInput(
        patientDropdownInput
      );

    const activePolicyDropdown =
      this.patientPolicyService.getPatientPolicyByPatientPolicyIdByPatientIdAndPolicyId(
        this.patientId,
        this.policyId
      );
    const combinedObs = forkJoin([
      orderDropdown,
      patientDetails,
      patietDropdown,
      activePolicyDropdown,
    ]);
    combinedObs.subscribe(
      (response) => {
        const responseData: OrderMasterDropdownDTO = response?.[0];
        this.drpChart = responseData?.charts;
        this.drpPlan = responseData?.typeOfPlans;
        this.drpPayable = responseData?.payablePercents;
        this.drpPlanType = responseData?.planTypes;
        this.drpYearType = responseData?.yearTypes;

        const patientDetails: PatientPersonalDTO = response?.[1];
        this.defaultPatientId = patientDetails?.defaultPatientId;
        // this.verificationForm.patchValue({
        //   txtName: patientDetails?.fullName,
        //   txtDateOfBirth: patientDetails?.dateOfBirth,
        // });

        const drpdownResponse: PatientMasterDropdownDTO = response?.[2];
        this.payorLevel = drpdownResponse?.payorLevels;
        const policyDetails: PatientPolicyDTO[] = response?.[3];
        this.drpPolicies = response[3];
        response[3].forEach((element) => {
          this.drpPolicyNo = element.policyNo;
          this.drpPayorLevel = element.payorLevel;
          this.drpPayorName =
            element.payorId + ' ' + '/' + ' ' + element.payorName;
        });
        // this.drpPolicies = policyDetails?.filter(x => (x?.payorLevel?.toLowerCase() ?? "") === (InsuranceType.Primary?.toLowerCase() ?? "")) ?? [];
        // this.verificationForm.patchValue({
        //   txtPlan: this.drpPayorLevel,
        //   txtPlanName: this.drpPayorName,
        // });
        // this.verificationForm.patchValue({
        //   txtPolicy: this.drpPolicyNo,
        //   txtPlanPhone: this.drpPhoneNo,
        // });
        // this.verificationId !== defaultGuid && this.getInsVerificationByID();
        this.getInsVerificationByID();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //To Save the Verification Details
  // saveInsuranceVerify() {
  //   this.isShowSpinner = true;
  //   const savedData: CreateUpdatePatientVerificationDTO = {
  //     verificationId: this.verificationId ?? defaultGuid,
  //     patientId: this.patientId ?? defaultGuid,
  //     policyId: this.policyId ?? defaultGuid,
  //     payorId:
  //       this.drpPolicies?.filter(
  //         (x) => x?.payorName === this.verificationForm.value?.txtPlanName
  //       )?.[0]?.id ?? defaultGuid,
  //     chart: this.defaultPatientId,
  //     name: this.verificationForm.value?.txtName ?? '',
  //     address: this.verificationForm.value?.txtName ?? '',
  //     dateofBirth: this.verificationForm.value?.txtDateOfBirth ?? '',
  //     commentsOrNotes: this.verificationForm.value?.txtCommentsNotes ?? '',
  //     plan: this.verificationForm.value?.txtPlan ?? '',
  //     planName: this.verificationForm.value?.txtPlanName ?? '',
  //     planPhone: this.verificationForm.value?.txtPlanPhone ?? '',
  //     policy: this.verificationForm.value?.txtPolicy ?? '',
  //     groupName: this.verificationForm.value?.txtGroup ?? '',
  //     effectiveDate: this.verificationForm.value?.txtEffectiveDate ?? '',
  //     typeOfPolicy: this.verificationForm.value?.txtType ?? '',
  //     fascalYearStart: this.verificationForm.value?.txtFascalYearStart ?? '',
  //     end: this.verificationForm.value?.txtEnd ?? '',
  //     isMedicareCmn: +this.verificationForm.value?.chkMedicare ?? 0,
  //     isPatientHome: +this.verificationForm.value?.chkPatient ?? 0,
  //     isFamilyDeductible:
  //       +this.verificationForm.value?.chkFamilyDeductible ?? 0,
  //     cmnCode: this.verificationForm.value?.txtCmnCode ?? '',
  //     typeOfPlan: this.verificationForm.value?.drpTypeOfPlan ?? '',
  //     isIndividual: +this.verificationForm.value?.chkIndividual ?? 0,
  //     isOutOfNetwork: +this.verificationForm.value?.chkOutOfNetwork ?? 0,
  //     isFamily: +this.verificationForm.value?.chkFamily ?? 0,
  //     planDeductible: {
  //       individual: this.verificationForm.value?.txtDeductibleIndividual ?? '',
  //       outOfNetwork:
  //         this.verificationForm.value?.txtDeductibleOutOfNetwork ?? '',
  //       family: this.verificationForm.value?.txtDeductibleFamily ?? '',
  //     },
  //     deductibleMet: {
  //       individual:
  //         this.verificationForm.value?.txtDeductibleMetIndividual ?? '',
  //       outOfNetwork:
  //         this.verificationForm.value?.txtDeductibleMetOutOfNetwork ?? '',
  //       family: this.verificationForm.value?.txtDeductibleMetFamily ?? '',
  //     },
  //     payablePercent: {
  //       individual: this.verificationForm.value?.drpPayableIndividual ?? '',
  //       outOfNetwork: this.verificationForm.value?.drpPayableOutOfNetwork ?? '',
  //       family: this.verificationForm.value?.drpPayableFamily ?? '',
  //     },
  //     outOfPocketExpense: {
  //       individual: this.verificationForm.value?.txtExpenseIndividual ?? '',
  //       outOfNetwork: this.verificationForm.value?.txtExpenseOutOfNetwork ?? '',
  //       family: this.verificationForm.value?.txtExpenseFamily ?? '',
  //     },
  //     outOfPocketExpenseMet: {
  //       individual: this.verificationForm.value?.txtExpenseMetIndividual ?? '',
  //       outOfNetwork:
  //         this.verificationForm.value?.txtExpenseMetOutOfNetwork ?? '',
  //       family: this.verificationForm.value?.txtExpenseMetFamily ?? '',
  //     },
  //     outOfPocketIncludeDeductible: {
  //       individual: +(this.verificationForm.value?.chkExpenseIndividual ?? 0),
  //       outOfNetwork: +(
  //         this.verificationForm.value?.chkExpenseOutOfNetwork ?? 0
  //       ),
  //       family: +(this.verificationForm.value?.chkExpenseFamily ?? 0),
  //     },
  //     outOfPocketRemaining: {
  //       individual: this.verificationForm.value?.txtRemainingIndividual ?? '',
  //       family: this.verificationForm.value?.txtRemainingFamily ?? '',
  //       outOfNetwork:
  //         this.verificationForm.value?.txtRemainingOutOfNetwork ?? '',
  //     },
  //     deductibleRemaining: {
  //       family: this.verificationForm.value?.txtDeductibleRemainingFamily ?? '',
  //       individual:
  //         this.verificationForm.value?.txtDeductibleRemainingIndividual ?? '',
  //       outOfNetwork:
  //         this.verificationForm.value?.txtDeductibleRemainingOutOfNetwork,
  //     },
  //     isPrecertRequired: +this.verificationForm.value?.chkPrecertRequired ?? 0,
  //     phone: this.verificationForm.value?.txtPhoneCall ?? '',
  //     isReferralRequired:
  //       +this.verificationForm.value?.chkReferralRequired ?? 0,
  //     pcp: this.verificationForm.value?.txtPcp ?? '',
  //     isPredetermination:
  //       +this.verificationForm.value?.chkPredetermination ?? 0,
  //     preNotes: this.verificationForm.value?.txtPreNotes ?? '',
  //     lifeTimeMax: this.verificationForm.value?.txtLifetimeMax ?? '',
  //     lifeTimeMaxMet: this.verificationForm.value?.txtLifetimeMaxMet ?? '',
  //     isDmePartsCovered: +this.verificationForm.value?.chkDmePartsCovered ?? 0,
  //     isDmeReplaceMentUnit:
  //       +this.verificationForm.value?.chkDmeReplacementUnit ?? 0,
  //     dmeAnnualLimit: this.verificationForm.value?.txtDmeAnnualLimit ?? '',
  //     dmeLimitMet: this.verificationForm.value?.txtDmeLimitMet ?? '',
  //     requestedBy: this.verificationForm.value?.txtRequestedBy ?? '',
  //     primaryPayor: this.verificationForm.value?.txtPrimaryPayor ?? '',
  //     contact: this.verificationForm.value?.txtContact ?? '',
  //     callTrackingNumber:
  //       this.verificationForm.value?.txtCallTrackingNumber ?? '',
  //     policyHolderName: this.verificationForm.value?.txtPolicyHolderName ?? '',
  //     policyHolderDob: this.verificationForm.value?.txtPolicyHolderDob ?? '',
  //     isNetwork: +this.verificationForm.value?.chkNetwork ?? 0,
  //     claimAddress: this.verificationForm.value?.txtClaimAddress ?? '',
  //     planCob: this.verificationForm.value?.txtPlanCob ?? '',
  //     coverageCriteria: this.verificationForm.value?.txtCoverageCriteria ?? '',
  //     qtyLimits: this.verificationForm.value?.txtQtyLimits ?? '',
  //     frequency: this.verificationForm.value?.txtFrequency ?? '',
  //     rent: this.verificationForm.value?.txtRent ?? '',
  //     paRequired: this.verificationForm.value?.txtPaRequired ?? '',
  //     codesRequirePa: this.verificationForm.value?.txtCodesRequirePa ?? '',
  //     sameOrSimilarInfo:
  //       this.verificationForm.value?.txtSameOrSimilarInfo ?? '',
  //     spokeWith: this.verificationForm.value?.txtSpokeWith ?? '',
  //     verifiedBy: this.verificationForm.value?.drpVerifiedBy ?? '',
  //     dateVerified: this.verificationForm.value?.txtDateVerified ?? '',
  //     preparedBy: this.verificationForm.value?.drpPreparedBy ?? '',
  //     preparedOn: this.verificationForm.value?.txtPreparedOn ?? '',
  //     verificationStatus:
  //       this.verificationForm.value?.rbtnVerificationStatus ?? '',
  //     deo: this.verificationForm.value?.txtDeo ?? '',
  //     moveToAuthStatus: '',
  //     commentsToTech: this.verificationForm.value?.txtcommentsToTech ?? '',
  //     verifiedMethod: this.verificationForm.value?.txtVerfnAMethod ?? '',
  //     coPayment: this.verificationForm.value?.txtCoPayment ?? '',
  //     coInsurance: this.verificationForm.value?.txtCoInsurance ?? '',
  //     isActive: +this.verificationForm.value?.chkActive ?? 0,

  //     // txtDeductibleRemainingFamily: new FormControl(''),
  //     // txtDeductibleRemainingIndividual: new FormControl(''),
  //     // txtDeductibleRemainingOutOfNetwork: new FormControl(''),
  //     // txtRemainingIndividual: new FormControl(''),
  //     // txtRemainingFamily: new FormControl(''),
  //     // txtRemainingOutOfNetwork: new FormControl(''),
  //     // txtCoPayment: new FormControl(''),
  //     // txtCoInsurance: new FormControl(''),

  //     verifiedTypeMethod: 'Manual',
  //     eligibilityMode: 1,
  //   };
  //   if (this.verificationId !== defaultGuid) {
  //     this.patientVerificationService
  //       .update(this.verificationId, savedData)
  //       .subscribe(
  //         (response) => {
  //           // Swal.fire({
  //           //   icon: 'success',
  //           //   text: "Saved Successfully",
  //           // });
  //           this.toastr.success('Saved Successfully', 'Success');
  //           this.isShowSpinner = false;
  //           this.manualVerificationDialog.close();
  //         },
  //         (err) => {
  //           this.isShowSpinner = false;
  //           const data: HttpErrorResponse = err;
  //           Swal.fire({
  //             icon: 'info',
  //             text: data?.error?.error?.message,
  //           });
  //         }
  //       );
  //   } else {
  //     this.patientVerificationService.create(savedData).subscribe(
  //       (response) => {
  //         // Swal.fire({
  //         //   icon: 'success',
  //         //   text: "Saved Successfully",
  //         // });
  //         this.toastr.success('Saved Successfully', 'Success');
  //         this.isShowSpinner = false;
  //         this.manualVerificationDialog.close();
  //       },
  //       (err) => {
  //         this.isShowSpinner = false;
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   }
  // }

  // testFunc() {

  // }

  //#region Clear Dropdowns
  clearVerifyMethod() {
    this.verfnAMethod = '';
    this.verificationForm.patchValue({ txtVerfnAMethod: '' });
  }
  clearPlan() {
    // this.plan = '';
    // this.verificationForm.patchValue({ txtPlan: '' });
  }
  clearPayorPlan() {
    // this.payorPlan = '';
    // this.verificationForm.patchValue({ txtPlanName: '' });
  }
  clearType() {
    // this.type = '';
    // this.verificationForm.patchValue({ txtType: '' });
  }
  clearTypeOfPlan() {
    // this.typeOfPlan = '';
    // this.verificationForm.patchValue({ drpTypeOfPlan: '' });
  }
  clearPayablePercentType() {
    // this.payablePercentType = '';
    // this.verificationForm.patchValue({ drpPayableIndividual: '' });
  }
  clearPayablePercentType1() {
    // this.payablePercentType1 = '';
    // this.verificationForm.patchValue({ drpPayableFamily: '' });
  }
  clearPayablePercentType2() {
    // this.payablePercentType2 = '';
    // this.verificationForm.patchValue({ drpPayableOutOfNetwork: '' });
  }

  //#endregion

}
