import { Component, OnInit, Input, Inject, ViewChild, } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from '@angular/common/http';
import { AddReminderComponent } from 'projects/admin/src/app/add-reminder/add-reminder.component';
import { reminderTableData, dtReminderTableOptions, dtReminderTableOptionsv1 } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { ReminderService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/remainder/reminder.service';
import { DataTableDirective } from 'angular-datatables';
import { ReminderFilter } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/reminder-filter.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunicationService } from '../../../services/communication.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-patient-reminder-dashboard-table',
  templateUrl: './patient-reminder-dashboard-table.component.html',
  styleUrls: ['./patient-reminder-dashboard-table.component.scss']
})
export class PatientReminderDashboardTableComponent implements OnInit {

  @Input() tableData:any;
  @Input() selectedNode: any;
  @Input() lblDefaultPatientId: string = '';

  vDescription: any;
  selectedRowIndex: number;
  myTable: DataTables.Api;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  remindertbldata:any;
  dtReminderTableOptions: any = {
    responsive: true,
    // stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [0, 1,], orderable: false },
      { targets: [0, 1, 2, -1], className: 'dt-fixed-column' },
      {
        targets: [5, 10],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        filename: 'Reminders',
        text: 'Export',
        exportOptions: {
          columns: ':visible:not(:nth-child(1),:nth-child(2))',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))',
      }
    ],
  };

  constructor(
    private dialog: MatDialog,
    public matDialog: MatDialog,
    private reminderService: ReminderService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reminderid: any;
    } = {
        reminderid: defaultGuid,
      },
  ) { }

  ngOnInit(): void {
    this.dtReminderTableOptions.buttons[0].filename =
    'Reminders' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.remindertbldata=this.tableData;
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }


  //Update Remainder Model
  UpdateReminder(reminderid: string, status: boolean) {

    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '35vw',
      data: {
        noteType: 'patient',
        reminderid: reminderid,
        remindertitle: 'Update Reminder',
        status: status,
        selectedNode: this.selectedNode,
        lblDefaultPatientId: this.lblDefaultPatientId,
      },
    };

    const dialogRef = this.dialog.open(AddReminderComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.GetReminderFilterList();
        // this.communicationService.triggerGetListReminderDashboard();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    // this.$subscription.push(closeDialog);
  }
  
  ///Delete Reminder
  DeleteReminder(id: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.reminderService.enableDisableReminderById(id).subscribe(response => {

          this.toastr.success('Deleted Successfully', 'Success');

          // this.GetReminderFilterList();
          this.communicationService.triggerGetListReminderDashboard();
        }, err => {
          const data: HttpErrorResponse = err;

          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }

  ///Complete Reminder
  completeReminder(id: string) {
    Swal.fire({
      title: 'Are you sure you want to Complete Reminder?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Completed it!'
    }).then(result => {
      if (result.value) {
        this.reminderService.updateReminderStatus(id).subscribe(
          (response) => {
          this.toastr.success('Reminder Completed Successfully', 'Success');
          // this.GetReminderFilterList();
          this.communicationService.triggerGetListReminderDashboard();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          const message = JSON.parse(data?.error);
          Swal.fire({
            icon: 'info',
            text: message?.error?.message,
          });
        });
      }
    });
  }
  //Open Description(Notes) Model
  openDescription(description: string) {
    this.vDescription = description;
  }
  setSelectedRow(index: number): void {
    this.selectedRowIndex = index;
  }

  //Row Click Event UnProcessed Table
  handleTableCellClick(e: Event, notesData: any): void {
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }
  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, notesData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;

    if (tr) {
      const row = this.myTable.row(tr);

      if (row.child.isShown()) {
        row.child.hide();
      } else {
        row.child(this.format(row.data(), notesData)).show();
      }
    }
  }

  format(d: any, notesData: any): string {
    let returnString: string = '';

    if (Array.isArray(notesData)) {
      notesData.forEach(function (value) {
        returnString += '<tr class="border-bottom" style="white-space: pre-wrap !important;"><td>' + value + '</td></tr>';
      });
    } else if (typeof notesData === 'string') {
      returnString += '<tr class="border-bottom" style="white-space: pre-wrap !important;"><td>' + notesData + '</td></tr>';
    } else {
      console.error('notesData is neither an array nor a string:', notesData);
    }

    return returnString;
  }

  //Copy Text When Double click On Row
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  getFormattedDate(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      : '-';
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  isPastDue(date: string): boolean {
    const currentDate = new Date();
    const dueDate = new Date(date);
    dueDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);    
    return dueDate < currentDate;
  }
  
}
