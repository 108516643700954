import { error } from './../../../../admin/src/app/official/official.component';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PatientNotesService } from '../patient-proxy/patient-optimization';
import { CreateUpdatePatientNotesDTO } from '../patient-proxy/patient-optimization/dto';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NoteType } from 'projects/order/src/app/order-proxy/order-management/inventory/dto';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { PagedAndSortedResultRequestDto, RestService } from '@abp/ng.core';
import { InboundStatusService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound-status/inbound-status.service';
import { Router } from '@angular/router';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { noWhitespaceValidator } from '../add-note-tab/add-note-tab.component';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { Subscription } from 'rxjs';
import { MasterNotesService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { InboundStatusShortCode } from 'projects/shared/src/app/data/common-data';

@Component({
  selector: 'app-newsetup-moveto',
  templateUrl: './newsetup-moveto.component.html',
  styleUrls: ['./newsetup-moveto.component.css']
})
export class NewsetupMovetoComponent implements OnInit {
  @Output() reloadTableEvent = new EventEmitter(); //Event Emitter to reload the table
  @Output() reloadTreeEvent = new EventEmitter(); //Event Emitter to reload the tree
  @Output() selectNode = new EventEmitter();
  moveToForm: FormGroup;
  NoteTypeControl=new FormControl();
  inboundStatusIds = InboundStatusShortCode;
  notesText: string = '';
  notesId:string;
  moveToData:any;
  isDisabled:boolean=false;
  saveButton:boolean=false;
  isdrpLoading: boolean;
  isShowSpinner: boolean = false;
  listNoteType: any;
  drpNotetype: any;
  noteType: any;
  noteTypeId: any;
  isNewSetupV1:boolean =false;
  constructor( private inboundStatusService: InboundStatusService,
    private toastr: ToastrService,
    private notesService: MasterNotesService,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private router: Router,
    private InboundDocService: InboundDocumentService,
    private communicationService: CommunicationService,
    public dialog: MatDialog,
    public MoveToDialogRef: MatDialogRef<NewsetupMovetoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
    docId: string,
    inboundStatusId: string ,
    moveToId:number,
    shortCodeId:string,
    isNewSetup:boolean,
    selectedNode:any,
    isMarkConfirm?:boolean,
    currentShortCode?:string} = {
      docId: defaultGuid,
      inboundStatusId: defaultGuid,
      moveToId:0,
      selectedNode:defaultGuid,
      shortCodeId:defaultGuid,
      isMarkConfirm:false,
      isNewSetup:false,
      currentShortCode:''
    }) { }
    subscription$: Subscription[] = [];
  ngOnInit() {
    // console.log(this.data);
    // console.log('this.data');
    this.moveToForm = this.formBuilder.group({
      drpSearchStatus: new FormControl("",[ Validators.required]),
      txtComment: new FormControl("",[ Validators.required, noWhitespaceValidator ]),
      drpNotetype:new FormControl("",[ Validators.required]),
    });
    this.isNewSetupV1=this.data?.isNewSetup;
    // this.Reset();
    if(this.data.moveToId===1){
        this.moveToData = [
          { shortCodeId: this.inboundStatusIds.missingDocuments, inboundStatus: 'Missing Document' },
          { shortCodeId: this.inboundStatusIds.faxIssues, inboundStatus: 'Fax issue' },
          { shortCodeId: this.inboundStatusIds.pending, inboundStatus: 'Pending' }
        ];
    }else{
      if(this.data.shortCodeId===this.inboundStatusIds.confirmation){
        this.moveToData = [
          { shortCodeId: this.inboundStatusIds.confirmation, inboundStatus: 'Confirmation' },
        ]
        this.moveToForm.patchValue({ drpSearchStatus: this.data.shortCodeId});
      }
      else if(this.data.shortCodeId===this.inboundStatusIds.unprocessedFax){
        this.moveToData = [
          { shortCodeId: this.inboundStatusIds.unprocessedFax, inboundStatus: 'UnprocessedFax' },
        ]
        this.moveToForm.patchValue({ drpSearchStatus: this.data.shortCodeId});
      }
      else if(this.data.shortCodeId===this.inboundStatusIds.missingDocuments||this.data.shortCodeId===this.inboundStatusIds.pending ){
        this.moveToData = [
          { shortCodeId: this.inboundStatusIds.missingDocuments, inboundStatus: 'Missing Document' },
          { shortCodeId: this.inboundStatusIds.pending, inboundStatus: 'Pending' }
        ];
        this.moveToForm.patchValue({ drpSearchStatus: this.data.shortCodeId});
      }else if(this.data.shortCodeId===this.inboundStatusIds.myFaxPending||this.data.shortCodeId===this.inboundStatusIds.myFaxCompleted){
        this.moveToData = [
        { shortCodeId: this.inboundStatusIds.myFaxPending, inboundStatus: 'My Fax Pending' },
        { shortCodeId: this.inboundStatusIds.myFaxCompleted, inboundStatus: 'My Fax completed' }
        ];
      }
      else{
      const saveNotes=this.inboundStatusService.MoveToDetails(
        ).subscribe(
        (response) => {
         this.moveToData=response;
        });
      }
        this.isDisabled=true;

    }

    this.moveToForm.patchValue({ drpSearchStatus: this.data.shortCodeId});
    this.noteTypeDetails();
    this.NoteTypeControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpNotetype = this.listNoteType.filter((dx) =>
        dx.noteType.toLowerCase().includes(FilterText)
      );
    });
    }
  Reset(){
    this.moveToForm .patchValue({
      txtComment: '',
    });
  }
  noteTypeDetails(){
    this.isdrpLoading = true;
    const NoteType = this.notesService
    this.notesService.getDrpNoteTypes().subscribe(
    // .getListV1(new PagedAndSortedResultRequestDto())
    //   .subscribe(
        (response) => {
          this.isdrpLoading = false;
          this.listNoteType=response;
          this.drpNotetype=this.listNoteType;
        },
        (err) => {
          this.isdrpLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          // this.subscription$.push(NoteType);
        }
      );
  }
  // updateCharacterCount() {
  //   // Ensure the text length does not exceed the maximum length
  //   if (this.notesText?.length > 5000) {
  //     this.notesText = this.notesText?.substr(0, 5000);
  //   }
  // }
   //Update Character Count for TextArea
   updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > maxlength) {
      this.notesText = this.notesText?.substr(0, maxlength);
    }
  }
  saveNotes() {
    this.isShowSpinner = true;
  this.saveButton=true;
     this.noteType=this.moveToForm.value.drpNotetype.split('/')[1];
      this.noteTypeId=this.moveToForm.value.drpNotetype.split('/')[0];
    if(this.data.isMarkConfirm===true){
      const getInboundTableData = this.InboundDocService.updateMarkconfirmed(
        this.data.docId,
        this.moveToForm.value.txtComment,
        this.noteTypeId,
        this.noteType,
      ).subscribe(
        (response) => {
        }, (err) => {
          this.saveButton=false;
          this.isShowSpinner = false;
          if(err.error.text==='Updated Successfully!'){
          this.toastr.success('Marked as confirmed successfully.', 'Success');
          }else{
            this.toastr.error(err.error.text, 'error');
          }
          this.dialog.closeAll();
          this.Reset();
          // this.selectNode.emit({ node: this.data.selectedNode });
          this.communicationService.triggerFunctionCall(this.data.selectedNode);
           //this.reloadTableEvent.emit();

        });


    }else{
      const saveNotes=this.inboundStatusService.newSetupNotesSave(
        this.data.docId,
       this.moveToForm.value.drpSearchStatus,
        this.data.inboundStatusId,
        this.data.currentShortCode,
        this.moveToForm.value.txtComment,
        this.noteTypeId,
        this.noteType,
        ).subscribe(
        (response) => {
          // const responseObject = JSON.parse(response);
          if(response.statusCode===200){
           this.toastr.success('Saved Successfully','Success');
           this.saveButton=false;
           this.isShowSpinner = false;
        // this.communicationService.triggerFunctionCall(this.data.selectedNode);
        this.communicationService.triggerFunctionCallTreeOnly(this.data.selectedNode);
        if(this.isNewSetupV1===true){
        this.communicationService.triggerMoveToFunction();
        }
        // this.dialog.closeAll();
        this.MoveToDialogRef.close(response);
        this.Reset();
         }
        else{
          Swal.fire({
            icon: 'info',
            text: response?.message,
          });
          this.saveButton=false;
          this.isShowSpinner = false;
        } },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.saveButton=false;
          this.isShowSpinner = false;
        });
          // this.Reset();

    }
    // this.communicationService.triggerFunctionCall(this.data.selectedNode);
  }

}
