import type { CreateUpdateProductCategoryDTO, ProductCategoryDTO, ProductCategorySearchDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductCategoryService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateProductCategoryDTO) =>
    this.restService.request<any, ProductCategoryDTO>({
      method: 'POST',
      url: '/api/ItemApp/product-category',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/product-category/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, ProductCategoryDTO>({
      method: 'GET',
      url: `/api/ItemApp/product-category/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductCategoryDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-category',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateProductCategoryDTO) =>
    this.restService.request<any, ProductCategoryDTO>({
      method: 'PUT',
      url: `/api/ItemApp/product-category/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  searchProductCategoryByInput = (input: ProductCategorySearchDTO) =>
    this.restService.request<any, PagedResultDto<ProductCategoryDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-category/search-product-category',
      params: { productCategoryName: input.productCategoryName, status: input.status, createdDate: input.createdDate },
    },
     { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}
}
