import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  PatientMasterDropdownDTO,
  SaleOrRentDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import {
  CPTOxygenDTO,
  CreateUpdateItemAuthDetailsDTO,
  CreateUpdatePatientAuthorizationDTO,
  PatientAuthCodeDetailsDTO,
  ProviderNPIdetailsDTO,
} from '../patient-proxy/patient-optimization/dto';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, filter } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ProductDetailsService } from 'projects/inventory/src/app/item-proxy/item-management/optimization';
import { MatSelect } from '@angular/material/select';
import {
  OrderMasterDropdownService,
  PatientMasterDropdownService,
} from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import {
  OrderDropdowns,
  PatientDropdowns,
} from '../dropdown-proxy/dropdown-management/dropdowns';
import {
  PatientAuthorizationService,
  PatientPersonalService,
} from '../patient-proxy/patient-optimization';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { TableService } from 'projects/shared/src/app/table.service';
import { PatientAuthorizationDetailsComponent } from '../patient-authorization-details/patient-authorization-details.component';
import { ToastrService } from 'ngx-toastr';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { ItemEligibilityRulesService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { OrderMasterDropdownDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/order';
import { DatePipe } from '@angular/common';
import { PatientDocumentTiffSinglePreviewComponent } from './patient-document-tiff-single-preview/patient-document-tiff-single-preview.component';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
@Component({
  selector: 'app-patient-auth-save-update-form',
  templateUrl: './patient-auth-save-update-form.component.html',
  styleUrls: ['./patient-auth-save-update-form.component.scss'],
})
export class PatientAuthSaveUpdateFormComponent implements OnInit {
  @Input() patientId: string;
  @Input() isView: boolean;
  @Input() isSupplies: boolean;
  @Input() docId: string;
  @Input() faxId: string;
  @Input() selectedNode: any;
  @Input() verificationId: string;
  @Input() authorizationId: string;
  @Input() chartId: string;
  @Input() authStatusName: string;
  @Input() isMultiAuth: boolean;
  @Input() resethidden:boolean;
  @Input() savehide:boolean;
  @Output() reload = new EventEmitter<string>();
  @ViewChild('documentViewerDialog') documentViewerDialog =
    {} as TemplateRef<string>;
  @ViewChild(PatientAuthorizationDetailsComponent)
  PatientAuthorizationDetailsComponent: PatientAuthorizationDetailsComponent;
  @ViewChild('allDocumentSelected') private allDocumentSelected: MatOption;
  @ViewChild('documentSelect') documentSelect: MatSelect;
  public filteredDocuments: Observable<any[]> | undefined;
  finalPdfBase64: string = '';
  tiffBase64: any[];
  selectedStatus: string = '';
  documentsValue: string[] = [];
  lstDocs: any[] = [];
  lstDocument: any[] = [];
  authStatus: string;
  pdfHeight: string = '100%';
  ltProducts: any;
  typeofPlanId: string;
  ltAuthStatus: any;
  AuthStatusDetails: any;
  isShowSpinner: boolean = false;
  drpPlanTypes: any;
  referenceValue: string;
  statusValue: string;
  filteredItemTypes: Observable<any[]>;
  authorizationForm: FormGroup;
  complianceForm: FormGroup;
  cptCodeForm: FormGroup;
  suppliesForm: FormGroup;
  oxygenForm: FormGroup;
  diaginosisForm: FormGroup;
  txtSearchStatus = new FormControl();
  txtSearchDocument = new FormControl();
  startDate: Date;
  startDateeV1: string;
  endDate: Date;
  endDateV1: string;
  complianceOption: boolean = false;
  // cptcode:string='';
  //itemsList: FormArray;
  notesText = '';
  @ViewChild('itemSelect') itemSelect: MatSelect;
  drpPlanName: any[]; //Plan name Dropdown
  planName: string; //Plan name Value
  planType: string; //PlanType Value
  viewBtnSpinner: boolean = false;
  drpItemCodes: any;
  item: string[] = [];
  sale: string;
  tableData: CreateUpdateItemAuthDetailsDTO[] = [];
  dialogRef: any;
  drpSaleOrRents: SaleOrRentDTO[] = [];
  subscription$: Subscription[] = [];
  step: boolean;
  patientInfo: any;
  tenantId: string;
  patientDetails: any;
  dialogRef1: MatDialogRef<string, any>;
  AuthId: string;
  lstProductItems: any;
  lstCptCodes: any;
  lstDiagnosisCode: any = [];
  lstOxygen: any;
  lstSupplies: any = [];
  suppliedIds: PatientAuthCodeDetailsDTO[] = [];
  diagnosisdIds: PatientAuthCodeDetailsDTO[] = [];
  isCptRental: boolean = false;
  isOxygenRental: boolean = false;
  authNotes: string[] = [];
  dataSelectDays: any;
  drpSelectDaysSearch: any;
  SelectDaysControl = new FormControl();
  drpSelectDays: any;

  dataSelectMonth: any;
  drpSelectMonthSearch: any;
  SelectMonthControl = new FormControl();
  ptanNumber: string = '';
  tpi: string = '';
  SelectMonthReferralControl = new FormControl();
  drpSelectMonthReferral: any;
  dataSelectMonthReferral: any;
  isDoPreCertLoading: any;
  PlanTypeControl = new FormControl();
  drpPlanTypeSearch: any;
  planTypeId: string;
  constructor(
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private datepipe: DatePipe,
    private patientDropdownService: PatientMasterDropdownService,
    private patientAuthService: PatientAuthorizationService,
    private dialg: MatDialogRef<PatientAuthSaveUpdateFormComponent>,
    private matPDTSPDialog: MatDialog,
    private toastr: ToastrService,
    private personalService: PatientPersonalService,
    private patientPersonal: PatientPersonalService,
    private dropdownService: OrderMasterDropdownService,
    public dialog: MatDialog,
    private communicationService: CommunicationService,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      documentId?:string;
      defaultFaxId?:string;
    } = {
      documentId:null,
      defaultFaxId:'-'
    },
  ) {
    this.loaderService.getLoaderState().subscribe((isDoPreCertLoading) => {
      this.isDoPreCertLoading = isDoPreCertLoading;
    });
  }

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') ?? '';

    this.initializeForms();
    // if(!this.isEmpty(this.authorizationId)){
    this.getPatientAuthDetails();
    //  }
    this.loadDropdowns();
    this.loadMachineCode();
    this.txtSearchStatus.valueChanges.subscribe((dxTypeFilterText) => {
      dxTypeFilterText = dxTypeFilterText.toLowerCase();
      this.ltAuthStatus = this.AuthStatusDetails.filter((dx) =>
        dx.authStatus.toLowerCase().includes(dxTypeFilterText)
      );
    });
    this.txtSearchDocument.valueChanges.subscribe((dxTypeFilterText) => {
      dxTypeFilterText = dxTypeFilterText.toLowerCase();
      this.lstDocs = this.lstDocument.filter((dx) =>
        dx.fileNameType.toLowerCase().includes(dxTypeFilterText)
      );
    });
    this.getSelectDaysdrp();
    this.getRentalMonthdrp();

    //Select Days DropDowm Serch
    this.SelectDaysControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.dataSelectDays = this.drpSelectDaysSearch.filter((dx) =>
        dx.daysName.toLowerCase().includes(FilterText)
      );
    });

    //Select Month DropDowm Serch
    this.SelectMonthControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.dataSelectMonth = this.drpSelectMonthSearch.filter((dx) =>
        dx.monName.toLowerCase().includes(FilterText)
      );
    });

    //Select Referral Month DropDowm Serch
    this.SelectMonthReferralControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.dataSelectMonthReferral = this.drpSelectMonthReferral.filter((dx) =>
        dx.monName.toLowerCase().includes(FilterText)
      );
    });

    //Select Referral Month DropDowm Serch
    this.PlanTypeControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpPlanTypes = this.drpPlanTypeSearch.filter((dx) =>
        dx.planType.toLowerCase().includes(FilterText)
      );
    });
  }

  // Compliance Select Days Drop Down
  getSelectDaysdrp() {
    this.dataSelectDays = [
      { daysName: '30 Days', daysID: 30 },
      { daysName: '60 Days', daysID: 60 },
      { daysName: '90 Days', daysID: 90 },
      { daysName: '120 Days', daysID: 120 },
      { daysName: '150 Days', daysID: 150 },
      { daysName: '180 Days', daysID: 180 },
      { daysName: '240 Days', daysID: 240 },
      { daysName: '365 Days', daysID: 365 },
    ];
    this.drpSelectDaysSearch = this.dataSelectDays;
  }
  getMinimumEndDate(): Date | null {
    const startDateValue = this.authorizationForm.controls.txtStartDate.value;
    if (startDateValue instanceof Date) {
      const minDate = new Date(startDateValue);
      minDate.setDate(minDate.getDate() + 1); // Minimum date is one day after txtStartDate
      return minDate;
    }
    return null;
  }

  // Renetal Month [CPT Codes for Machine] Select Days Drop Down
  getRentalMonthdrp() {
    this.dataSelectMonth = [
      { monName: '1', monID: '1' },
      { monName: '2', monID: '2' },
      { monName: '3', monID: '3' },
      { monName: '4', monID: '4' },
      { monName: '5', monID: '5' },
      { monName: '6', monID: '6' },
      { monName: '7', monID: '7' },
      { monName: '8', monID: '8' },
      { monName: '9', monID: '9' },
      { monName: '10', monID: '10' },
      { monName: '11', monID: '11' },
      { monName: '12', monID: '12' },
      { monName: '13', monID: '13' },
    ];

    this.dataSelectMonthReferral = [
      { monName: '1', monID: '1' },
      { monName: '2', monID: '2' },
      { monName: '3', monID: '3' },
      { monName: '4', monID: '4' },
      { monName: '5', monID: '5' },
      { monName: '6', monID: '6' },
      { monName: '7', monID: '7' },
      { monName: '8', monID: '8' },
      { monName: '9', monID: '9' },
      { monName: '10', monID: '10' },
      { monName: '11', monID: '11' },
      { monName: '12', monID: '12' },
      { monName: '13', monID: '13' },
    ];
    this.drpSelectMonthSearch = this.dataSelectMonth;
    this.drpSelectMonthReferral = this.dataSelectMonthReferral;
  }
  getPatientAuthDetails() {
    this.loaderService.showLoader();
    this.personalService
      .patientDocumentAuthDetailsV1NewByGAuthId(this.authorizationId)
      .subscribe(
        (response) => {
          this.patientInfo = response;
          this.loadAuthDetails(this.patientInfo);
          this.lstDocument = this.patientInfo?.lstDocs;
          this.lstDocs = this.lstDocument;
          this.loaderService.hideLoader();
        },
        (err) => {
          this.loaderService.hideLoader();
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }
  checkMinLength(input: string){
    if(input===''||input.length===0){
      return ;
    }
    if(this.authorizationForm.controls.txtStartDate.value >=
      this.authorizationForm.controls.txtEndDate.value  ){
      this.authorizationForm
      .get('txtEndDate')
      .setErrors({ invalidDate: true })
    }
  }
  //To Load the Auth Details
  loadAuthDetails(patientInfo: ProviderNPIdetailsDTO) {
    this.ptanNumber = patientInfo?.ptan || '';
    this.tpi = patientInfo?.tpi || '';
    this.planTypeId=patientInfo?.typeofPlanId || '';
    this.authorizationForm.patchValue({
      drpReference: patientInfo?.authDetails?.reference || '',
      drpStatus: patientInfo?.authDetails?.authStatus || '',
      txtSpokeWith: patientInfo?.authDetails?.spokeWith || '',
      txtFaxNumber: patientInfo?.faxNumber || '',
      txtAuthId: patientInfo?.authDetails?.authId || null,
      txtPlanName: patientInfo?.policyName || '',
      txtPlanType: patientInfo?.typeofPlanId || '',
      txtStartDate: patientInfo?.authDetails?.startDate || '',
      txtEndDate: patientInfo?.authDetails?.endDate || '',
      drpDocument: patientInfo?.authDetails?.sortedDocsId || '',
      // drpItem:
      //   patientInfo?.authDetails?.itemAuthDetails &&
      //   patientInfo?.authDetails?.itemAuthDetails?.length > 0
      //     ? patientInfo?.authDetails?.itemAuthDetails?.[0]?.itemName
      //     : '',
      txtUnits:
        patientInfo?.resupplyItems && patientInfo?.resupplyItems?.length > 0
          ? patientInfo?.resupplyItems?.[0]?.units || ''
          : '',
      drpSaleOrRent:
        patientInfo?.resupplyItems && patientInfo?.resupplyItems?.length > 0
          ? patientInfo?.resupplyItems?.[0]?.saleOrRent || ''
          : '',
      txtNotes: patientInfo?.note || '',
    });

    this.referenceValue = patientInfo?.authDetails?.reference || '';
    this.notesText = patientInfo?.note || '';
    this.docId = this.patientInfo?.authDetails?.documentId || this.data?.documentId || '';
    this.documentsValue = patientInfo?.authDetails.sortedDocsId || [];

    this.complianceForm.patchValue({
      complianceYes: patientInfo?.isCompliance ?? false,
      // isCompliance:this.complianceForm.value.complianceNo,
      complianceDays: patientInfo?.complianceDate,
      // txtSelectDays:
      // complianceFirstMonth:patientInfo.isAfterFirstMonth,
      // complianceThirdMonth:patientInfo.isDuringThirdMonth,
      // complianceOther:patientInfo.other,
    });
    if (patientInfo?.isAfterFirstMonth === true) {
      this.complianceForm.patchValue({
        complianceFirstMonth: 1,
      });
    } else if (patientInfo?.isDuringThirdMonth === true) {
      this.complianceForm.patchValue({
        complianceFirstMonth: 2,
      });
    } else if (patientInfo?.other === true) {
      this.complianceForm.patchValue({
        complianceFirstMonth: 3,
      });
    }
    if (
      patientInfo?.isCompliance === true &&
      this.complianceForm.value.complianceFirstMonth === null
    ) {
      this.complianceOption = true;
    }
    this.onStatusChange(patientInfo.authStatus);
    this.onReferenceChange();
    const authFileContent = patientInfo.authDetails?.authFileContent;
    const cptCodeDetails = patientInfo?.cptPrecercodes;
    const suppliesDetails = patientInfo?.supplies;
    const oxygenDetails = patientInfo?.oxygenPrecercodes;
    const diagnosisiDetails = patientInfo?.diagnosis;

    // Check if authFileContent is not null or undefined
    if (authFileContent !== null && authFileContent !== undefined) {
      const base64Data = authFileContent.split(',')[1];
      this.finalPdfBase64 = base64Data || '';
      this.documentsValue = patientInfo?.authDetails.sortedDocsId || [];
      this.referenceValue = patientInfo?.authDetails.reference || '';
      this.notesText = patientInfo?.authDetails?.note || '';
      // this.tableData = patientInfo?.authDetails?.itemAuthDetails || [];
      this.tableData = [];
      for (const item of this.tableData) {
        const fields = this.fb.group({
          lblItemId: [item.itemId, []],
          lblItemName: [item.itemName, []],
          txtItemName: [item.itemName, []],
          txtUnit: [item.units, Validators.required],
          drpSaleOrRent: [item.saleOrRent, Validators.required],
        });
        this.fieldsArray.push(fields);
      }
    }
    if (cptCodeDetails !== null && cptCodeDetails !== undefined) {
      if (!this.isEmpty(cptCodeDetails.preCertCodeId)) {
        this.isCptRental = true;
      }
      this.cptCodeForm.patchValue({
        cptcodes: cptCodeDetails.preCertCodeId,
        cptRental: cptCodeDetails.isRental,
        cptRentalMonth: cptCodeDetails?.rentalMonth?.toString(),
      });
    }
    if (
      suppliesDetails !== null &&
      suppliesDetails !== undefined &&
      suppliesDetails.length != 0
    ) {
      suppliesDetails.forEach((element) => {
        this.lstSupplies.find(
          (item) => item.id == element.preCertCodeId
        ).isTrue = true;
        let value = this.lstSupplies.find(
          (item) => item.id == element.preCertCodeId
        );
        let supply: PatientAuthCodeDetailsDTO = {
          preCertCodeId: value.id,
        };
        this.suppliedIds.push(supply);
      });
    }
    if (oxygenDetails !== null && oxygenDetails !== undefined) {
      if (!this.isEmpty(oxygenDetails.preCertCodeId)) {
        this.isOxygenRental = true;
      }
      this.oxygenForm.patchValue({
        oxygencode: oxygenDetails.preCertCodeId,
        oxygenRental: oxygenDetails.isRental,
        oxygenrentalMonth: oxygenDetails?.rentalMonth?.toString(),
      });
    }
    if (
      diagnosisiDetails !== null &&
      diagnosisiDetails !== undefined &&
      diagnosisiDetails.length != 0
    ) {
      diagnosisiDetails.forEach((element) => {
        this.lstDiagnosisCode.find(
          (item) => item.id == element.preCertCodeId
        ).isTrue = true;
        let value = this.lstDiagnosisCode.find(
          (item) => item.id == element.preCertCodeId
        );
        let diagnosis: PatientAuthCodeDetailsDTO = {
          preCertCodeId: value.id,
        };
        this.diagnosisdIds.push(diagnosis);
      });
    }
  }

  openPatientDocumentTiffSinglePreviewModal() {
    const dialogRef = this.matPDTSPDialog.open(
      PatientDocumentTiffSinglePreviewComponent,
      {
        disableClose: true,
        minWidth: '67vw',
        width: '50vw',
        data: { base64: this.tiffBase64, patientInfo: this.patientInfo },
      }
    );
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {},
      (err) => {
        const data: HttpErrorResponse = err;
        // console.warn(data?.error?.error?.message, 'Error');
        // this.toastr.error(data?.error?.error?.message, 'Error');
      }
    );
    this.subscription$.push(closeDialog);
  }

  //To open the Document Viewer with Merged Selected Documents
  openDocumentViewer() {
    this.viewBtnSpinner = true;
    // let value = this.documentsValue.filter((a) => a != '0' && a != undefined);
    let doctypeId = this.authorizationForm.value.drpDocument;
    const personal = this.patientPersonal
      .getBase64TiffSortedByDocs(doctypeId)
      .subscribe(
        // getAuthDocument
        (response) => {
          // let file = JSON.parse(response);
          // this.finalPdfBase64 = response[0].file;
          this.tiffBase64 = response;
          this.viewBtnSpinner = false;

          this.openPatientDocumentTiffSinglePreviewModal();
          // this.dialogRef1 = this.dialog.open(this.documentViewerDialog, {
          //   // minHeight: '95vh',
          //   minWidth: '67vw',
          //   width: '50vw',
          //   // height: '95vh',
          //   disableClose: true,
          // });
        },
        () => {
          this.toastr.error('Something Went Wrong!');
          this.viewBtnSpinner = false;
          // this.dialogRef1.close();
        }
      );

    this.subscription$.push(personal);
  }
  // Load Dropdowns
  loadDropdowns() {
    //To Load status
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.SaleOrRent,
      PatientDropdowns.AuthStatus,
    ];
    const getPatientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe((response: PatientMasterDropdownDTO) => {
        this.drpSaleOrRents = response.saleOrRents;
        this.AuthStatusDetails = response.lstAuthStatus;
        this.ltAuthStatus = this.AuthStatusDetails;
      });
    this.subscription$.push(getPatientDropdown);
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [OrderDropdowns.PlanTypes];
    //Load Plan Types
    const planTypes = this.dropdownService
      .getOrderDropdownsByInput(orderDropdownInput)
      .subscribe((stateResponse) => {
        let response: OrderMasterDropdownDTO = stateResponse;
        this.drpPlanTypes = response?.planTypes;

        this.drpPlanTypeSearch = this.drpPlanTypes;
      });
    this.subscription$.push(planTypes);
  }
  loadMachineCode() {
    const getAuthCode = this.patientAuthService
      .getAuthMachineCodeReport()
      .subscribe(
        (response) => {
          this.lstCptCodes = response.cptCodes;
          let DiagnosisCode = response.diagnosisCode;
          this.lstOxygen = response.oxygen;
          let supplies = response.supplies;
          supplies.forEach((element) => {
            let supValue = {
              id: element.id,
              code: element.code,
              isTrue: false,
            };
            this.lstSupplies.push(supValue);
          });
          DiagnosisCode.forEach((element) => {
            let diaValue = {
              id: element.id,
              code: element.code,
              isTrue: false,
            };
            this.lstDiagnosisCode.push(diaValue);
          });
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'error',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getAuthCode);
  }
  //Select All Documents
  toggleAllDocumentsSelection() {
    if (this.allDocumentSelected.selected) {
      this.documentSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.documentSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  //Initialize Form Method
  initializeForms() {
    this.authorizationForm = this.formBuilder.group({
      drpReference: new FormControl('', [Validators.required]),
      drpStatus: new FormControl('', [Validators.required]),
      drpDocument: new FormControl(''),
      selectDocument: new FormControl(''),
      txtAuthId: new FormControl(null),
      txtPlanName: new FormControl(''),
      txtPlanType: new FormControl(''),
      txtStartDate: new FormControl(null),
      txtEndDate: new FormControl(null),
      drpItem: new FormControl(''),
      txtUnits: new FormControl(''),
      txtSaleOrRent: new FormControl(''),
      chkIsFollowUp: new FormControl(''),
      txtNotes: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      referenceValue: new FormControl(''),
      statusValue: new FormControl(''),
      txtSpokeWith: new FormControl(''),
      txtFaxNumber: new FormControl(''),
      itemsList: this.fb.array([]),
      txtPlanTypeRef: new FormControl(''),
    });
    this.complianceForm = this.formBuilder.group({
      complianceYes: new FormControl(''),
      complianceNo: new FormControl(false),
      complianceDays: new FormControl(''),
      complianceFirstMonth: new FormControl(false),
      complianceThirdMonth: new FormControl(false),
      complianceOther: new FormControl(false),
      txtSelectDays: new FormControl(''),
    });

    this.cptCodeForm = this.formBuilder.group({
      cptcodes: new FormControl(null),
      cptRental: new FormControl(false),
      cptRentalMonth: new FormControl(null),
      txtSelectMonth: new FormControl(''),
    });
    this.suppliesForm = this.formBuilder.group({
      suppliesChkbox: new FormControl(''),
    });
    this.oxygenForm = this.formBuilder.group({
      oxygencode: new FormControl(null),
      oxygenRental: new FormControl(false),
      oxygenrentalMonth: new FormControl(null),
      txtSelectMonthReferral: new FormControl(''),
    });
    this.diaginosisForm = this.formBuilder.group({
      diagnosisChkbox: new FormControl(null),
    });
  }
  //On Reference Change Function
  onReferenceChange() {
    const reference = this.authorizationForm.get('drpReference').value;
    this.authorizationForm.get('txtSpokeWith').disable();
    this.authorizationForm.get('txtFaxNumber').disable();
    this.authorizationForm.get('drpDocument').disable();
    if (reference === 'Call') {
      this.authorizationForm.get('txtSpokeWith').enable();
      if (this.selectedStatus !== 'No Precert') {
        this.authorizationForm?.controls?.txtSpokeWith?.setValidators([
          Validators.required,
          noWhitespaceValidator,
        ]);
      } else {
        this.authorizationForm?.controls?.txtSpokeWith?.setValidators([
          Validators.nullValidator,
        ]);
      }
    } else if (reference === 'Fax') {
      this.authorizationForm.get('txtFaxNumber').enable();
      this.authorizationForm.get('drpDocument').enable();
      if (this.selectedStatus !== 'No Precert') {
        this.authorizationForm?.controls?.txtFaxNumber?.setValidators([
          Validators.required,
        ]);
        this.authorizationForm?.controls?.drpDocument?.setValidators([
          Validators.required,
        ]);
      } else {
        this.authorizationForm?.controls?.txtFaxNumber?.setValidators([
          Validators.nullValidator,
        ]);
        this.authorizationForm?.controls?.drpDocument?.setValidators([
          Validators.nullValidator,
        ]);
      }
    } else {
      this.authorizationForm?.controls?.txtSpokeWith?.setValidators([
        Validators.nullValidator,
      ]);
      this.authorizationForm?.controls?.txtFaxNumber?.setValidators([
        Validators.nullValidator,
      ]);
      this.authorizationForm?.controls?.drpDocument?.setValidators([
        Validators.nullValidator,
      ]);
    }
  }
  onStatusChange(event: any) {
    if (event?.source === undefined) {
      this.selectedStatus = event;
    } else {
      this.selectedStatus = event.source.selected.viewValue;
    }
    if (
      this.selectedStatus === 'Approved' ||
      this.selectedStatus === 'Pre-Determination Approved'
    ) {
      //Validation Conditions based on Auth Status
      this.authorizationForm?.controls?.txtAuthId?.setValidators([
        Validators.required,
        noWhitespaceValidator,
      ]);
      this.authorizationForm?.controls?.txtStartDate?.setValidators([
        Validators.required,
      ]);
      this.authorizationForm?.controls?.txtEndDate?.setValidators([
        Validators.required,
      ]);
      this.authorizationForm?.controls?.txtPlanName?.setValidators([
        Validators.required,
      ]);
      this.authorizationForm?.controls?.txtAuthId?.updateValueAndValidity();
      this.authorizationForm?.controls?.txtPlanName?.updateValueAndValidity();
      this.authorizationForm?.controls?.txtStartDate?.updateValueAndValidity();
      this.authorizationForm?.controls?.txtEndDate?.updateValueAndValidity();
    } else if (this.selectedStatus === 'No Precert') {
      this.authorizationForm?.controls?.txtSpokeWith?.setValidators([
        Validators.nullValidator,
      ]);
      this.authorizationForm?.controls?.txtFaxNumber?.setValidators([
        Validators.nullValidator,
      ]);
      this.authorizationForm?.controls?.drpDocument?.setValidators([
        Validators.nullValidator,
      ]);
      this.authorizationForm?.controls?.txtSpokeWith?.updateValueAndValidity();
      this.authorizationForm?.controls?.txtFaxNumber?.updateValueAndValidity();
      this.authorizationForm?.controls?.drpDocument?.updateValueAndValidity();
      this.authorizationForm?.controls?.txtAuthId?.setValidators([
        Validators.nullValidator,
      ]);
      this.authorizationForm?.controls?.txtStartDate?.setValidators([
        Validators.nullValidator,
      ]);
      this.authorizationForm?.controls?.txtEndDate?.setValidators([
        Validators.nullValidator,
      ]);
      this.authorizationForm?.controls?.txtAuthId?.updateValueAndValidity();
      this.authorizationForm?.controls?.txtStartDate?.updateValueAndValidity();
      this.authorizationForm?.controls?.txtEndDate?.updateValueAndValidity();
    } else {
      this.authorizationForm?.controls?.txtAuthId?.setValidators([
        Validators.nullValidator,
      ]);
      this.authorizationForm?.controls?.txtStartDate?.setValidators([
        Validators.nullValidator,
      ]);
      this.authorizationForm?.controls?.txtEndDate?.setValidators([
        Validators.nullValidator,
      ]);
      this.authorizationForm?.controls?.txtPlanName?.setValidators([
        Validators.nullValidator,
      ]);
      this.authorizationForm?.controls?.txtAuthId?.updateValueAndValidity();
      this.authorizationForm?.controls?.txtStartDate?.updateValueAndValidity();
      this.authorizationForm?.controls?.txtEndDate?.updateValueAndValidity();
      this.authorizationForm?.controls?.txtPlanName?.updateValueAndValidity();
      const reference = this.authorizationForm.get('drpReference').value;
      if (reference === 'Call') {
        this.authorizationForm?.controls?.txtSpokeWith?.setValidators([
          Validators.required,
          noWhitespaceValidator,
        ]);
        this.authorizationForm?.controls?.txtSpokeWith?.updateValueAndValidity();
      } else if (reference === 'Fax') {
        this.authorizationForm?.controls?.txtFaxNumber?.setValidators([
          Validators.required,
        ]);
        this.authorizationForm?.controls?.drpDocument?.setValidators([
          Validators.required,
        ]);
        this.authorizationForm?.controls?.txtFaxNumber?.updateValueAndValidity();
        this.authorizationForm?.controls?.drpDocument?.updateValueAndValidity();
      } else {
        this.authorizationForm?.controls?.txtSpokeWith?.setValidators([
          Validators.nullValidator,
        ]);
        this.authorizationForm?.controls?.txtFaxNumber?.setValidators([
          Validators.nullValidator,
        ]);
        this.authorizationForm?.controls?.drpDocument?.setValidators([
          Validators.nullValidator,
        ]);
      }
    }
  }
  resetForm() {
    // if (!this.isSupplies) {
    //   this.fieldsArray.clear();
    //   this.authorizationForm.reset();
    // } else if (this.isSupplies) {
    this.authorizationForm.get('txtAuthId').setValue('');
    //  this.authorizationForm.get('txtPlanType').setValue(this.planTypeId);
    this.authorizationForm.get('txtStartDate').setValue(null);
    this.authorizationForm.get('txtEndDate').setValue(null);
    this.authorizationForm.get('txtNotes').setValue('');
    this.authorizationForm.get('drpReference').setValue('');
    this.authorizationForm.get('drpStatus').setValue('');
    this.authorizationForm.get('drpItem').setValue('');
    this.authorizationForm.get('txtFaxNumber').setValue('');
    this.authorizationForm.get('txtSpokeWith').setValue('');
    this.authorizationForm.get('drpDocument').setValue([]);
    this.fieldsArray.controls.forEach((control) => {
      control.get('txtUnit').setValue(''); // or control.get('field2').setValue(null) to set it to null
      control.get('drpSaleOrRent').setValue(''); // or control.get('field3').setValue(null) to set it to null
    });
    this.complianceForm.reset();
    this.complianceForm.get('complianceYes').setValue(false);
    this.cptCodeForm.reset();
    this.suppliesForm.reset();
    this.oxygenForm.reset();
    this.diaginosisForm.reset();
    this.isCptRental = false;
    this.isOxygenRental=false;
    // }
    this.referenceValue = '';
    this.finalPdfBase64 = '';
    this.documentsValue = [];
    this.notesText = '';
    // this.planType = '';
    // this.planName = '';
    this.tableData = this.tableData;
  }
  //Save Authorization
  saveAuthorization(patientId: string, wipId: string) {
    this.isShowSpinner = true;
    if (this.patientInfo.authDetails != null) {
      this.authorizationId = this.patientInfo.authDetails.authorizationId;
    } else {
      this.authorizationId = defaultGuid;
    }
    if (this.authorizationForm.value.txtPlanType === '') {
      this.typeofPlanId = null;
    } else {
      this.typeofPlanId = this.authorizationForm.value.txtPlanType;
    }
    let cptCode: CPTOxygenDTO = {
      preCertCodeId: this.cptCodeForm.value.cptcodes ?? null,
      isRental: this.cptCodeForm.value.cptRental ?? false,
      rentalMonth: this.cptCodeForm.value.cptRentalMonth ?? 0,
    };
    let supply: PatientAuthCodeDetailsDTO = {
      preCertCodeId: this.suppliesForm.value.suppliesChkbox ?? null,
    };
    let oxygenPrecercodes: CPTOxygenDTO = {
      preCertCodeId: this.oxygenForm.value.oxygencode ?? null,
      isRental: this.oxygenForm.value.oxygenRental ?? false,
      rentalMonth: this.oxygenForm.value.oxygenrentalMonth ?? 0,
    };
    let diagnosis: PatientAuthCodeDetailsDTO = {
      preCertCodeId: this.diaginosisForm.value.diagnosisChkbox,
    };
    this.authNotes.push(this.authorizationForm.value.txtNotes);
    const formValue = this.authorizationForm.value;
    const txtStartDate = this.datepipe.transform(
      formValue.txtStartDate,
      'yyyy-MM-dd'
    );
    const txtEndDate = this.datepipe.transform(
      formValue.txtEndDate,
      'yyyy-MM-dd'
    );
    let saveData: CreateUpdatePatientAuthorizationDTO = {
      patientId: patientId,
      policyId: this.patientInfo.authDetails.policyId,
      documentId: this.patientInfo.authDetails.documentId,
      authorizationId: this.authorizationId,
      authStatus: this.authorizationForm.value.drpStatus ?? '',
      authId: this.authorizationForm.value.txtAuthId ?? null,
      startDate: txtStartDate,
      endDate: txtEndDate,
      // authAmount: this.patientInfo.authAmount ?? '',
      authFileContent: this.finalPdfBase64 ?? null,
      // itemAuthDetails: this.tableData ?? null,
      note: this.authorizationForm.value.txtNotes ?? '',
      // used: '0',
      planName: this.authorizationForm.value.txtPlanName ?? '',
      planType: null,
      isExpired: 0,
      tenantId: this.tenantId,
      reference: this.authorizationForm.value.drpReference ?? '',
      spokeWith: this.authorizationForm.value.txtSpokeWith ?? '',
      faxNumber: this.authorizationForm.value.txtFaxNumber ?? '',
      authorizationNotes: this.authNotes ?? [],
      wipId: wipId ?? null,
      sortedDocsId: this.authorizationForm.value.drpDocument ?? null,
      // sortedDocsId: this.authorizationForm.value.drpDocument?.map((document: any) => document.id) || null,
      orderId: [],
      // authDocsId: [],
      // notificationMessage: this.authorizationForm.value.txtNotes ?? '',
      typeofPlanId: this.typeofPlanId,
      isCompliance: this.complianceForm.value.complianceYes,
      // isCompliance:this.complianceForm.value.complianceNo,
      complianceDate:
        this.complianceForm.value.complianceDays == undefined ||
        this.complianceForm.value.complianceDays == ''
          ? null
          : this.complianceForm.value.complianceDays,
      isAfterFirstMonth:
        this.complianceForm.value.complianceFirstMonth === 1 ? true : false,
      isDuringThirdMonth:
        this.complianceForm.value.complianceFirstMonth === 2 ? true : false,
      other:
        this.complianceForm.value.complianceFirstMonth === 3 ? true : false,
      cptPrecercodes: cptCode ?? null,
      supplies: this.suppliedIds ?? [],
      oxygenPrecercodes: oxygenPrecercodes ?? null,
      diagnosis: this.diagnosisdIds ?? [],
    };
    if (
      this.patientInfo.authDetails != null &&
      this.patientInfo.authDetails.id !== null &&
      this.patientInfo.authDetails.id !== defaultGuid &&
      this.patientInfo.authDetails.id !== ''
    ) {
      this.patientAuthService
        .update(this.patientInfo.authDetails.id, saveData)
        .subscribe(
          (response) => {
            this.authStatus = response.authStatusName;
            this.toastr.success('Auth Updated Successfully!');
            this.isShowSpinner = false;
            this.communicationService.triggerFunctionCall(this.selectedNode);
            if (this.isMultiAuth === true) {
              this.reload.emit(response.authorizationId);
            } else {
              this.dialg.close();
            }
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'error',
              text: data?.error?.error?.message,
            });
            this.isShowSpinner = false;
          }
        );
    } else {
      this.patientAuthService.create(saveData).subscribe(
        (response) => {
          this.authStatus = response.authStatusName;
          this.patientInfo.authDetails = response;
          this.toastr.success('Auth Saved Successfully!');
          this.isShowSpinner = false;
          this.communicationService.triggerFunctionCall(this.selectedNode);
          if (this.isMultiAuth === true) {
            this.reload.emit(response.authorizationId);
          } else {
            this.dialg.close();
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'error',
            text: data?.error?.error?.message,
          });
          this.isShowSpinner = false;
        }
      );
    }
  }
  //Send Fax
  sendFax() {
    this.toastr.success('Fax Sent Successfully!');
    this.dialogRef1.close();
  }
  get fieldsArray() {
    return this.authorizationForm.get('itemsList') as FormArray;
  }
  closePreview() {
    this.dialogRef1.close();
  }
  checkEndDate() {
    const startDate = this.authorizationForm.get('txtStartDate').value;
    const endDate = this.authorizationForm.get('txtEndDate').value;
    if (endDate && new Date(startDate) >= new Date(endDate)) {
      this.authorizationForm.patchValue({
        txtEndDate: '',
      });
    }
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  suppliesChange(event: MatSlideToggleChange, id: string) {
    if (event.checked) {
      let supply: PatientAuthCodeDetailsDTO = {
        preCertCodeId: id,
      };
      this.suppliedIds.push(supply);
    } else {
      let index = this.suppliedIds.findIndex((d) => d.preCertCodeId === id);
      this.suppliedIds.splice(index, 1);
    }
  }
  disgnosisChange(event: MatSlideToggleChange, id: string) {
    if (event.checked) {
      let diagnosis: PatientAuthCodeDetailsDTO = {
        preCertCodeId: id,
      };
      this.diagnosisdIds.push(diagnosis);
    } else {
      let index = this.diagnosisdIds.findIndex((d) => d.preCertCodeId === id);
      this.diagnosisdIds.splice(index, 1);
    }
  }
  cptCodeCheck() {
    this.isCptRental = true;
  }
  oxygenCodeCheck() {
    this.isOxygenRental = true;
  }
  isCompliaceNo() {
    this.complianceOption = false;
    this.complianceForm.patchValue({
      complianceFirstMonth: false,
      complianceThirdMonth: false,
      complianceOther: false,
    });
  }
  isComplianceYes() {
    this.complianceOption = true;
    !this.complianceForm.value.complianceFirstMonth &&
      this.complianceForm.patchValue({
        complianceFirstMonth: 1,
      });
  }
  isRentalTrueCPT(event: MatSlideToggleChange) {
    if (event.checked === false) {
      this.cptCodeForm.patchValue({
        cptRentalMonth: 0,
      });
    }
  }
  isRentalTrueOxygen(event: MatSlideToggleChange) {
    if (event.checked === false) {
      this.oxygenForm.patchValue({
        oxygenrentalMonth: 0,
      });
    }
  }
  selectCompliance() {
    this.complianceOption = false;
    // if(num===1){
    //  this.complianceForm.patchValue({
    //   complianceThirdMonth:false,
    //   complianceOther:false,
    //  })
    // } else if(num===2){
    //   this.complianceForm.patchValue({
    //     complianceFirstMonth:false,
    //     // complianceThirdMonth:true,
    //     complianceOther:false,
    //    })
    // } else{
    //   this.complianceForm.patchValue({
    //     complianceFirstMonth:false,
    //     complianceThirdMonth:false,
    //     // complianceOther:true,
    //    })
    // }
  }
  clearComplianceDays() {
    this.complianceForm.patchValue({
      complianceDays: null,
    });
  }
  clearCptRental() {
    this.cptCodeForm.patchValue({
      cptRentalMonth: null,
    });
  }
  clearOxygenrental() {
    this.oxygenForm.patchValue({
      oxygenrentalMonth: null,
    });
  }
  clearRefrence() {
    this.authorizationForm.patchValue({
      drpReference: '',
    });
  }
  clearStatus() {
    this.authorizationForm.patchValue({
      drpStatus: '',
    });
  }
  clearPlantype() {
    this.authorizationForm.patchValue({
      txtPlanType: '',
    });
  }
  clearDocument() {
    this.authorizationForm.patchValue({
      drpDocument: '',
    });
  }
  viewFaxDocument(){
    const url = '/#/patient_preview_tiff_document/' + this.data?.defaultFaxId + '/' + this.docId;
        window.open(url, '_blank');
  }
}
