<div class="container-fluid">
  <!-- Headers -->
  <div class="row mt-2">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="d-flex align-items-center justify-content-between">
        <div class="row px-3 ml-1">
          <!-- <button mat-icon-button class="buttonColor"> -->
          <mat-icon
            matTooltip="Back"
            type="button"
            (click)="backToOrder()"
            class="bg-lightseagreen mr-2 text-center"
            >keyboard_arrow_left</mat-icon
          >
          <!-- </button> -->
          <h3
            class="font-weight-bolder header-font mb-0"
            style="margin-top: 2px"
          >
            Patient / Sale Order
          </h3>
        </div>

        <!-- Buttons -->
        <div>
          <!-- New Order -->
          <!-- isFormChanged && -->
          <button
            [disabled]="isEmpty(patientId)"
            type="button"
            matTooltip="New Order"
            (click)="navigateToOrderPage(defauldGuid, defauldGuid)"
            mat-raised-button
            class="buttonColor mr-2 px-2 mat-raised-small-button"
          >
            <mat-icon>add</mat-icon>
            <!-- Save -->
          </button>
          <!-- [disabled]="isFormChanged" -->
          <!-- <button
            type="button"
            mat-raised-button
            matTooltip="Save Order"
            (click)="saveOrder()"
            [disabled]="
              !productId ||
              !(!orderForm?.invalid && orderForm?.dirty) ||
              saveButtonEnabled ||
              addressValidation ||
              isSaveBtnDisabled ||
              isAddressEdit
            "
            class="buttonColor mr-2"
          >
            <mat-icon>save</mat-icon>
          </button> -->
          <!-- <button mat-raised-button (click)="test()" class="buttonColor">Test</button> -->
          <!-- Save -->
          <button
            type="button"
            mat-raised-button
            matTooltip="Save Order"
            (click)="saveOrder()"
            [disabled]="
              patientId === defaultGuid ||
              isSaveBtnDisabled ||
              saveButtonEnabled
            "
            class="buttonColor mr-2 mat-raised-small-button px-2"
          >
            <mat-icon>save</mat-icon>
          </button>

          <!-- Exchange -->
          <button
            type="button"
            matTooltip="Exchange"
            [disabled]="!exchangeButtonEnabled"
            mat-raised-button
            (click)="exchangeOrder()"
            class="buttonColor mr-2 mat-raised-small-button px-2"
          >
            <mat-icon>sync</mat-icon> Exchange
          </button>
          <!-- Return -->
          <button
            type="button"
            matTooltip="Return"
            [disabled]="!returnButtonEnabled"
            mat-raised-button
            (click)="returnOrder()"
            class="buttonColor mr-2 mat-raised-small-button px-2"
          >
            <mat-icon>keyboard_return</mat-icon> Return
          </button>
          <!-- <button class="buttonColor" (click)="test()" mat-raised-button>Test</button> -->
          <!-- Shipping -->
          <button
            type="button"
            matTooltip="Shipping"
            mat-raised-button
            [disabled]="
              !productId ||
              orderForm?.invalid ||
              saveButtonEnabled ||
              addressValidation ||
              isSaveBtnDisabled ||
              itemfieldsValidation ||
              isAddressEdit ||
              orderForm.get('chkCashOrder').value === true ||
              itemsList[0].mmProductId === '' ||
              itemsList[0].mmProductId === null ||
              itemsList[0].mmProductId === undefined
            "
            class="buttonColor mr-2 mat-raised-small-button px-2"
            (click)="overrideShippingModal()"
          >
            <img
              class="img-responsive pr-1"
              src="assets/Order Page Icons/Shipping.png"
              alt=""
              height="14"
            />
            Shipping
          </button>

          <!-- (itemsList[0].mmProductId !=='' || itemsList[0].mmProductId !==null ||itemsList[0].mmProductId !==undefined) -->
          <!-- Billing -->
          <button
            type="button"
            matTooltip="Billing"
            mat-raised-button
            [disabled]="
              !productId ||
              !(!orderForm?.invalid && orderForm?.dirty) ||
              saveButtonEnabled ||
              addressValidation ||
              isSaveBtnDisabled ||
              itemfieldsValidation ||
              isAddressEdit ||
              orderForm.get('chkCashOrder').value === true ||
              itemsList[0].mmProductId === '' ||
              itemsList[0].mmProductId === null ||
              itemsList[0].mmProductId === undefined ||
              isExchangedOrder
            "
            class="buttonColor mr-2 mat-raised-small-button px-2"
            (click)="moveOrderToBilling()"
          >
            <img
              class="img-responsive pr-1"
              src="assets/Order Page Icons/Billing.png"
              alt=""
              height="14"
            />
            Billing
          </button>

          <!-- (itemsList[0].mmProductId !=='' || itemsList[0].mmProductId !==null ||itemsList[0].mmProductId !==undefined) -->
          <!-- Ticket -->
          <button
            [disabled]="orderId === defaultGuid"
            type="button"
            matTooltip="Ticket"
            mat-raised-button
            class="buttonColor mr-2 mat-raised-small-button px-2"
            (click)="getPrintTicketDetails()"
          >
            <img
              class="img-responsive pr-1"
              src="assets/Order Page Icons/Ticket.png"
              alt=""
              height="14"
            />
            Ticket
          </button>
          <!-- Ledger -->
          <button
            type="button"
            matTooltip="Ledger"
            [disabled]="this.patientId === defaultGuid"
            mat-raised-button
            (click)="viewPatientLedgerPopup()"
            class="buttonColor mr-2 mat-raised-small-button px-2"
          >
            <img
              class="img-responsive pr-1"
              src="assets/Order Page Icons/Ledger.png"
              alt=""
              height="14"
            />
            Ledger
          </button>
          <!-- Rem Req -->
          <button
            [disabled]="
              !productId ||
              !(!orderForm?.invalid && orderForm?.dirty) ||
              saveButtonEnabled ||
              addressValidation
            "
            matTooltip="RemReq"
            type="button"
            mat-raised-button
            class="buttonColor mr-2 mat-raised-small-button px-2"
            (click)="remRequisition()"
          >
            <img
              class="img-responsive pr-1"
              src="assets/Order Page Icons/RemReq.png"
              alt=""
              height="14"
            />
            RemReq
          </button>

          <!-- View Notes -->
          <button
            type="button"
            matTooltip="View Notes"
            (click)="openViewNotesPopup()"
            mat-raised-button
            [disabled]="patientId === defaultGuid"
            class="buttonColor mr-2 mat-raised-small-button px-2"
          >
            <mat-icon class="f-18 mr-1">visibility</mat-icon>View Notes
          </button>

          <!-- View Document Image -->
          <button
            type="button"
            [matTooltip]="
              isDocumentSorted ? 'View Document' : 'Document Not Sorted'
            "
            (click)="viewPatientDocument()"
            mat-raised-button
            [disabled]="
              patientId === defaultGuid ||
              isDocumentSorted == false
            "
            class="buttonColor mr-2 mat-raised-small-button px-2"
          >
            <mat-icon class="rotate f-18 mr-1 mb-1">note</mat-icon>View Document
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
        <div class="card mb-2 mt-3 p-2 curved-card">
          <form [formGroup]="orderForm">
            <!-- Patient Details -->
            <div class="row">
              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 mb-2 col-xl-12 d-flex justify-content-between"
              >
                <p class="header-text pl-2 pt-2 mb-2 small-text">
                  Patient Details
                </p>
                <!-- Patient Details button -->
                <!-- <button
                  type="button"
                  [disabled]="!isPatientSelected"
                  (click)="getPatientDetails()"
                  mat-raised-button
                  class="buttonColor mt-2 mr-2 mat-raised-small-button px-2"
                >
                  <mat-icon class="mr-1 f-18">remove_red_eye</mat-icon>Patient
                  Details
                </button> -->
              </div>
            </div>

            <!-- Chart Id -->
            <mat-form-field class="col-8 col-sm-8 col-md-4 col-lg-4 col-xl-4">
              <mat-label> Chart No <span class="asterisk">*</span></mat-label>

              <mat-select
                (selectionChange)="onChartIdChange($event)"
                (openedChange)="onPatinetDropdownOpenChange($event)"
                formControlName="drpChartId"
                #singleSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    (keyup)="
                      searchPatient(
                        $event,
                        $event.target.value,
                        singleSelect.value
                      )
                    "
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtChartFilter"
                    (click)="clearPatientdrp()"
                  ></ngx-mat-select-search>
                </mat-option>

                <!-- <mat-option
                    *ngFor="let chart of filteredChartIds | async"
                    [value]="chart.patientId"
                  >
                    {{ chart.idAndNameAndDOB }}
                  </mat-option> -->
                <mat-option
                  *ngFor="let chart of lstChartNos"
                  [value]="chart.patientId"
                >
                  {{ chart.ddValue }}
                </mat-option>
              </mat-select>

              <button
                [disabled]="!isPatientSelected"
                matTooltip="Click to View Patient Details"
                (click)="getPatientDetails(); $event.stopPropagation()"
                type="button"
                mat-button
                class="icon-success"
                matSuffix
                mat-icon-button
              >
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </mat-form-field>

            <!-- Birth Date -->
            <mat-form-field class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <mat-label> Birth Date </mat-label>
              <input
                autocomplete="new-txtBirthdate"
                [max]="BirthdateMaxDate"
                formControlName="txtBirthdate"
                matInput
                maxlength="40"
                type="text"
                placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtBirthdate"
                [min]="BirthdateMinDate"
                readonly
              />
              <mat-datepicker-toggle
                [hidden]="true"
                matSuffix
                [for]="txtBirthdate"
              >
              </mat-datepicker-toggle>
              <mat-datepicker #txtBirthdate> </mat-datepicker>
              <!--<mat-error
                  *ngIf="
                    orderForm?.get('txtBirthdate')?.touched &&
                    orderForm?.get('txtBirthdate')?.errors &&
                    !(BirthdateMinDate <= orderForm?.get('txtBirthdate')?.value)
                  "
                >
                  Enter Valid date
                </mat-error>
                <mat-error
                  *ngIf="
                    orderForm?.get('txtBirthdate')?.touched &&
                    orderForm?.get('txtBirthdate')?.errors?.required
                  "
                >
                  Birth Date is a required field!
                </mat-error>
                <mat-error
                  *ngIf="
                    BirthdateMinDate <= orderForm?.get('txtBirthdate')?.value &&
                    !(
                      orderForm?.get('txtBirthdate')?.touched &&
                      orderForm?.get('txtBirthdate')?.errors?.required
                    )
                  "
                >
                  Enter Past Dates Only!
                </mat-error> -->
            </mat-form-field>

            <!-- Last Name-->
            <mat-form-field class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <mat-label> Last Name </mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtLastName"
                type="text"
                autocomplete="new-txtLastName"
              />
              <mat-error
                class="spanAlignment"
                *ngIf="
                  orderForm?.get('txtLastName')?.touched &&
                  orderForm?.get('txtLastName')?.errors?.required
                "
              >
                Last Name is a required field!
              </mat-error>
            </mat-form-field>

            <!-- First Name -->
            <mat-form-field class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <mat-label> First Name </mat-label>
              <input
                readonly
                matInput
                maxlength="40"
                formControlName="txtFirstName"
                type="text"
                autocomplete="new-txtFirstName"
              />
              <mat-error
                class="spanAlignment"
                *ngIf="
                  orderForm?.get('txtFirstName')?.touched &&
                  orderForm?.get('txtFirstName')?.errors?.required
                "
              >
                First Name is a required field!
              </mat-error>
            </mat-form-field>

            <!-- Middle Name -->
            <mat-form-field class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <mat-label>Middle Name</mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                formControlName="txtMiddleName"
                type="text"
                autocomplete="new-txtMiddleName"
              />
            </mat-form-field>
            <!-- <div class="col-12"></div> -->
            <mat-divider class="my-2"></mat-divider>
            <div class="d-flex pl-2 pr-2 justify-content-between">
              <p class="header-text small-text">Delivery Address</p>
              <div>
                <span
                  *ngIf="isAddressEdit"
                  class="text-danger blinking-text small-text mr-2"
                >
                  Please Save the delivery address!
                </span>
                <!-- Verified-->
                <mat-checkbox
                  formControlName="chkVerified"
                  [(ngModel)]="isChecked"
                  class="mr-2 mt-2 text-center align-middle"
                  [disabled]="patientValidation()"
                >
                  <p
                    class="mb-0 dd-text"
                    [ngClass]="{
                      'invalid-checkbox':
                        orderForm?.get('chkVerified').invalid &&
                        orderForm?.get('chkVerified')?.touched
                    }"
                  >
                    Verified <span class="asterisk">*</span>
                  </p>
                </mat-checkbox>
                <!-- Use Billing Address -->
                <!-- <mat-checkbox
                    [disabled]="!isPatientSelected"
                    (change)="loadBillingAddress($event)"
                    formControlName="chkBillingAddress"
                    class="mr-2 mt-2 text-center align-middle"
                  >
                    <p class="mb-0 text-wrap dd-text">
                      Use Billing Address
                    </p>
                  </mat-checkbox> -->

                <mat-checkbox
                  [disabled]="!isPatientSelected"
                  (change)="loadBillingAddress($event)"
                  formControlName="chkBillingAddress"
                  class="mr-2 mt-2 text-center align-middle"
                >
                  <p class="mb-0 dd-text">
                    Use Billing Address <span class="asterisk"></span>
                  </p>
                </mat-checkbox>
                <button
                  [matTooltip]="
                    isAddressEdit
                      ? 'Save/Update Delivery Address'
                      : 'Edit Delivery Address'
                  "
                  [disabled]="
                    !isPatientSelected ||
                    saveButtonEnabled ||
                    (isAddressEdit && addressValidation) ||
                    orderForm?.get('txtCellNo')?.errors ||
                    orderForm?.get('txtEmail')?.errors
                  "
                  (click)="editDeliveryAddress()"
                  type="button"
                  class="buttonColor mr-2 mat-raised-small-button px-2"
                  mat-raised-button
                >
                  <mat-icon *ngIf="!isAddressEdit">edit</mat-icon>
                  <mat-icon *ngIf="isAddressEdit">check</mat-icon>
                </button>
                <button
                  type="button"
                  [matTooltip]="'Select Delivery Address'"
                  [disabled]="!isPatientSelected || saveButtonEnabled"
                  (click)="viewAddressList()"
                  class="buttonColor mr-2 mat-raised-small-button px-2"
                  mat-raised-button
                >
                  <img
                    class="img-responsive pr-1 mb-1"
                    src="assets/Order Page Icons/Select Address.png"
                    alt=""
                    height="17"
                  />
                </button>
                <button
                  [matTooltip]="'Verify Delivery Address'"
                  [disabled]="
                    !isPatientSelected || addressValidation || saveButtonEnabled
                  "
                  (click)="verifyAddress()"
                  type="button"
                  class="buttonColor mat-raised-small-button px-2"
                  mat-raised-button
                >
                  <img
                    class="img-responsive pr-1 mb-1"
                    src="assets/Order Page Icons/Verify Address 1.png"
                    alt=""
                    height="17"
                  />
                </button>
              </div>
            </div>

            <!-- Name Line -->
            <mat-form-field
              class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3"
              [hidden]="true"
            >
              <mat-label>Name Line</mat-label>
              <input maxelength="" formControlName="txtNameLine" matInput />
            </mat-form-field>
            <!-- Description -->
            <mat-form-field
              class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3"
              [hidden]="true"
            >
              <mat-label>Description</mat-label>
              <input formControlName="txtDescription" matInput />
            </mat-form-field>

            <!--Address -->
            <mat-form-field class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <mat-label>Address <span class="asterisk">*</span></mat-label>
              <textarea
                matInput
                rows="3"
                autocomplete="new-txtAddress"
                maxlength="200"
                class="textarea-resize-none"
                formControlName="txtAddress"
                [(ngModel)]="shippingAddress"
                (input)="updateCharacterCount(200, 'address')"
                type="text"
              ></textarea>
              <div class="char-count">{{ shippingAddress?.length }} / 200</div>
            </mat-form-field>

            <!-- Country-->
            <mat-form-field class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <mat-label>Country <span class="asterisk">*</span></mat-label>
              <mat-select
                formControlName="txtCountry"
                [(value)]="country"
                #singleSelect
                (selectionChange)="onChangeState($event.value)"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtCountryFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let country of filteredCountries | async"
                  [value]="country.countryShortName"
                >
                  {{ country.countryName }}
                </mat-option>
              </mat-select>
              <!-- <button
                  mat-button
                  (click)="State = ''; $event.stopPropagation()"
                  *ngIf="State"
                  (click)="clearState()"
                  matSuffix
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button> -->
            </mat-form-field>
            <!-- State-->
            <mat-form-field class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <mat-label>State <span class="asterisk">*</span></mat-label>
              <mat-select
                formControlName="txtState"
                [(value)]="State"
                #singleSelect
                (selectionChange)="onChangeCity($event.value, country)"
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtStateFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let state of filteredStates | async"
                  [value]="state.stateShortName"
                >
                  {{ state.stateName }}
                </mat-option>
              </mat-select>
              <!-- <button
                  mat-button
                  (click)="State = ''; $event.stopPropagation()"
                  *ngIf="State"
                  (click)="clearState()"
                  matSuffix
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button> -->
            </mat-form-field>
            <!-- City-->
            <mat-form-field class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <mat-label>City <span class="asterisk">*</span></mat-label>
              <mat-select
                formControlName="txtCity"
                [(value)]="City"
                #singleSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtCityFilter"
                    hideClearSearchButton="true"
                    [noEntriesFoundLabel]="cityDrpDownMessage()"
                    (focusout)="addNewCity()"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let city of filteredCities | async"
                  [value]="city.cityName"
                >
                  {{ city.cityName }}
                </mat-option>
              </mat-select>
              <!-- <button
                  mat-button
                  (click)="City = ''; $event.stopPropagation()"
                  *ngIf="City"
                  matSuffix
                  (click)="clearCity()"
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button> -->
            </mat-form-field>

            <!-- Zip Code -->
            <mat-form-field class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <mat-label>Zip Code <span class="asterisk">*</span></mat-label>
              <input
                matInput
                pattern="[0-9-]*"
                class="zip-code-input"
                minlength="5"
                maxlength="10"
                formControlName="txtPostalCode"
                type="text"
              />
              <mat-error
                *ngIf="
                  orderForm?.get('txtPostalCode')?.touched &&
                  orderForm?.get('txtPostalCode')?.errors?.pattern
                "
              >
                Enter only Numbers!
              </mat-error>
            </mat-form-field>

            <!-- Home No -->
            <mat-form-field class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <mat-label> Home No </mat-label>
              <input
                matInput
                maxlength="40"
                formControlName="txtHomeNo"
                type="text"
                mask="(000)-000-0000"
                prefix="{{ phoneCode }}-"
              />
            </mat-form-field>

            <!-- Email-->
            <mat-form-field class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <mat-label
                >Email
                <span
                  *ngIf="orderForm.get('chkCashOrder').value === true"
                  class="asterisk"
                  >*</span
                ></mat-label
              >
              <input
                matInput
                pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                [textMask]="{ mask: emailMask }"
                maxlength="40"
                type="text"
                formControlName="txtEmail"
              />
              <mat-error
                class="spanAlignment"
                *ngIf="orderForm?.get('txtEmail')?.errors?.pattern"
              >
                Enter a valid email!
              </mat-error>
              <mat-error
                class="spanAlignment"
                *ngIf="
                  orderForm?.get('txtEmail')?.touched &&
                  orderForm?.get('txtEmail')?.errors?.required
                "
              >
                Email is required!
              </mat-error>
            </mat-form-field>

            <!-- Cell No-->
            <mat-form-field class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <mat-label>
                Cell<span
                  *ngIf="orderForm.get('chkCashOrder').value === true"
                  class="asterisk"
                  >*</span
                ></mat-label
              >
              <input
                matInput
                maxlength="40"
                formControlName="txtCellNo"
                type="text"
                mask="(000)-000-0000"
                prefix="{{ phoneCode }}-"
              />
              <mat-error
                class="spanAlignment"
                *ngIf="
                  orderForm?.get('txtCellNo')?.touched &&
                  orderForm?.get('txtCellNo')?.errors?.required
                "
              >
                Cell Number is required!
              </mat-error>
              <mat-error
                class="spanAlignment"
                *ngIf="
                  orderForm?.get('txtCellNo')?.errors &&
                  !orderForm?.get('txtCellNo')?.errors?.required
                "
              >
                Enter Valid Cell Number!
              </mat-error>
            </mat-form-field>

            <mat-divider class="my-2"></mat-divider>

            <div class="row">
              <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-flex">
                <p class="header-text pl-2 pt-2 mb-2 small-text">
                  Insurance Details
                </p>
                <!-- Patient Details button -->
                <mat-checkbox
                  (change)="cashOrder($event)"
                  class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 pt-2"
                  formControlName="chkCashOrder"
                  [disabled]="cashOrderValidation()"
                >
                  <p class="mb-0 mt-1 text-wrap dd-text">Cash Order</p>
                </mat-checkbox>
              </div>
              <div class="col-6 text-right mb-1">
                <button
                  [matTooltip]="
                    faxId === null ||
                    faxId === undefined ||
                    faxId === '' ||
                    faxId === defaultGuid
                      ? 'Document Not Avaliable'
                      : 'Click Go To Do Sorting'
                  "
                  mat-button
                  class="buttonColor font-size-10 ml-2 p-2 dd-text"
                  (click)="openDoSorting()"
                  *ngIf="!isDocumentSorted && isPatientSelected"
                  [disabled]="
                    faxId === null ||
                    faxId === undefined ||
                    faxId === '' ||
                    faxId === defaultGuid
                  "
                >
                  <img
                    class="img-responsive mb-1 mr-1"
                    height="10"
                    src="../assets/patient_list_icons/Do_Sorting.png"
                    [class.disabled-link]="
                      faxId === null ||
                      faxId === undefined ||
                      faxId === '' ||
                      faxId === defaultGuid
                    "
                  />
                  Do Sorting
                </button>
              </div>
            </div>
            <!-- Insurance Details -->
            <p class="header-text pl-2 pt-2 mb-2 small-text"></p>
            <!--Primary Insurance-->
            <mat-form-field class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <mat-label
                >Primary Insurance
                <span
                  *ngIf="orderForm.get('chkCashOrder').value !== true"
                  class="asterisk"
                  >*</span
                ></mat-label
              >

              <mat-select
                [(ngModel)]="priVerificationId"
                formControlName="drpPrimaryInsurance"
                [disabled]="patientValidation()"
                [ngClass]="
                  !orderForm.value.chkCashOrder &&
                  patientId !== defauldGuid &&
                  priVerificationId === defaultGuid
                    ? 'highlight-Input'
                    : ''
                "
                [matTooltip]="
                  !orderForm.value.chkCashOrder &&
                  patientId !== defauldGuid &&
                  priVerificationId === defaultGuid
                    ? 'Primary verification pending'
                    : ''
                "
              >
                <mat-select-trigger>
                  {{ getPayerName(orderForm.get("drpPrimaryInsurance").value) }}
                </mat-select-trigger>
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtPrimaryInsuranceFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let verification of filteredVerifications | async"
                  [value]="verification.id"
                >
                  {{ verification.payorNameandDate }}
                </mat-option>
              </mat-select>
              <button
                matTooltip="Click to View Primary Insurance"
                (click)="
                  openLastVerification('Primary'); $event.stopPropagation()
                "
                type="button"
                [disabled]="
                  orderForm.get('drpPrimaryInsurance').value == defaultGuid ||
                  orderForm.get('drpPrimaryInsurance').value == '' ||
                  orderForm.get('drpPrimaryInsurance').value == undefined ||
                  orderForm.get('chkCashOrder').value === true
                "
                mat-button
                class="icon-success"
                matSuffix
                mat-icon-button
              >
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </mat-form-field>

            <!--Secondary Insurance-->
            <mat-form-field class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <mat-label>Secondary Insurance</mat-label>
              <input
                readonly
                [ngClass]="
                  !orderForm.value.chkCashOrder &&
                  secondaryVerificationStatus &&
                  secVerificationId != defaultGuid
                    ? 'highlight-Input'
                    : ''
                "
                [matTooltip]="
                  secondaryVerificationStatus && !orderForm.value.chkCashOrder
                    ? 'Verification Pending'
                    : ''
                "
                maxlength="100"
                formControlName="txtSecondaryInsurance"
                matInput
                placeholder="Secondary Insurance"
              />
              <button
                class="icon-success"
                type="button"
                [disabled]="
                  orderForm.get('txtSecondaryInsurance').value == defaultGuid ||
                  orderForm.get('txtSecondaryInsurance').value == '' ||
                  orderForm.get('txtSecondaryInsurance').value == undefined ||
                  orderForm.get('txtSecondaryInsurance').value == null ||
                  orderForm.get('txtSecondaryInsurance').value.toLowerCase() ==
                    'none' ||
                  orderForm.get('chkCashOrder').value === true
                "
                [matTooltip]="
                  !orderForm.value.chkCashOrder && secondaryVerificationStatus
                    ? ''
                    : 'Click to View Secondary Insurance'
                "
                (click)="openLastVerification('secondary')"
                mat-button
                matSuffix
                mat-icon-button
              >
                <mat-icon>remove_red_eye</mat-icon>
              </button>
              <!-- <button
                  type="button"
                  (click)="openPverifyModal()"
                  mat-button
                  matSuffix
                  class="icon-success"
                  mat-icon-button
                >
                  <mat-icon>add_box</mat-icon>
                </button> -->
            </mat-form-field>

            <!--Checklist-->
            <mat-form-field class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <mat-label>Checklist</mat-label>
              <input
                readonly
                maxlength="100"
                formControlName="txtChecklist"
                matInput
                placeholder="Checklist"
              />
              <a
                class="icon-success preview-link"
                mat-button
                [routerLink]="
                  '/view_checklist/' +
                  patientId +
                  '/' +
                  true +
                  '/' +
                  true +
                  '/' +
                  medicareChecklistId
                "
                [disabled]="medicareChecklistId == defaultGuid"
                target="_blank"
                matSuffix
                type="button"
                mat-icon-button
              >
                <mat-icon matTooltip="Click to View Checklist"
                  >remove_red_eye</mat-icon
                >
              </a>
            </mat-form-field>

            <!--Authorization-->
            <mat-form-field class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <mat-label>Authorization</mat-label>
              <mat-select formControlName="drpAuthorization">
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtAuthorizationFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let auth of filteredAuth | async"
                  [value]="auth.id"
                >
                  {{ auth.authId }}
                </mat-option>
              </mat-select>
              <button
                (click)="viewAuthorization(); $event.stopPropagation()"
                matTooltip="Click to View Authorization"
                type="button"
                [disabled]="
                  orderForm.get('drpAuthorization').value == defaultGuid
                "
                mat-button
                class="icon-success"
                matSuffix
                mat-icon-button
              >
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </mat-form-field>

            <!-- RX Date -->
            <mat-form-field class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <mat-label> RX Date <span class="asterisk">*</span></mat-label>
              <input
                [max]="BirthdateMaxDate"
                formControlName="txtRXDate"
                matInput
                maxlength="40"
                type="text"
                placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtRXDate"
                [min]="BirthdateMinDate"
              />
              <mat-datepicker-toggle matSuffix [for]="txtRXDate">
              </mat-datepicker-toggle>
              <mat-datepicker #txtRXDate> </mat-datepicker>
              <button
                type="button"
                class="cls-rxbutton"
                mat-button
                [disabled]="
                  this.patientId == defaultGuid || isDocumentSorted == false
                "
                [matTooltip]="
                  isDocumentSorted
                    ? 'Click to Select RX Document'
                    : 'Document Not Sorted'
                "
                matSuffix
                mat-icon-button
                (click)="selectRXDocument()"
              >
                <!-- <mat-icon class="clr-lightseagreen">info</mat-icon> -->
                {{ ltRXDocument?.length }}
              </button>
              <mat-error
                *ngIf="
                  orderForm?.get('txtRXDate')?.touched &&
                  orderForm?.get('txtRXDate')?.errors &&
                  !(BirthdateMinDate <= orderForm?.get('txtRXDate')?.value)
                "
              >
                Enter Valid and Past Date;
              </mat-error>
              <!-- <mat-error
                *ngIf="
                  orderForm?.get('txtRXDate')?.touched &&
                  orderForm?.get('txtRXDate')?.errors?.required
                "
              >
                RX Date is a required field!
              </mat-error>
              <mat-error
                *ngIf="
                  BirthdateMinDate <= orderForm?.get('txtRXDate')?.value &&
                  !(
                    orderForm?.get('txtRXDate')?.touched &&
                    orderForm?.get('txtRXDate')?.errors?.required
                  )
                "
              >
                Enter Past Dates Only!
              </mat-error> -->
            </mat-form-field>

            <!-- Order Date -->
            <mat-form-field class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <mat-label> Order Date <span class="asterisk">*</span></mat-label>
              <input
                formControlName="txtOrderDate"
                matInput
                maxlength="40"
                (dateChange)="onOrderDateChange()"
                type="text"
                placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtOrderDate"
              />
              <mat-datepicker-toggle matSuffix [for]="txtOrderDate">
              </mat-datepicker-toggle>
              <mat-datepicker #txtOrderDate> </mat-datepicker>

              <mat-error
                *ngIf="
                  orderForm?.get('txtOrderDate')?.touched &&
                  orderForm?.get('txtOrderDate')?.errors &&
                  !(BirthdateMinDate <= orderForm?.get('txtOrderDate')?.value)
                "
              >
                Enter Valid date
              </mat-error>
              <mat-error
                *ngIf="
                  orderForm?.get('txtOrderDate')?.touched &&
                  orderForm?.get('txtOrderDate')?.errors?.required
                "
              >
                Order Date is a required field!
              </mat-error>
              <mat-error
                *ngIf="
                  BirthdateMinDate <= orderForm?.get('txtOrderDate')?.value &&
                  !(
                    orderForm?.get('txtOrderDate')?.touched &&
                    orderForm?.get('txtOrderDate')?.errors?.required
                  )
                "
              >
                Enter Past Dates Only!
              </mat-error>
            </mat-form-field>

            <!-- DOS -->
            <mat-form-field class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <mat-label> DOS (Same as Order Date) </mat-label>
              <input
                autocomplete="new-DOS"
                formControlName="txtDOS"
                matInput
                maxlength="40"
                type="text"
                placeholder="(MM/DD/YYYY)"
                [matDatepicker]="txtDOS"
              />
              <mat-datepicker-toggle matSuffix [for]="txtDOS">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDOS> </mat-datepicker>
            </mat-form-field>

            <div class="row">
              <div
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex"
              >
                <!--Comments to Patient -->
                <mat-form-field
                  class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
                >
                  <mat-label>Comments to Patient </mat-label>
                  <textarea
                    class="textarea-resize-none"
                    matInput
                    rows="4"
                    autocomplete="new-txtPatientComments"
                    maxlength="5000"
                    formControlName="txtPatientComments"
                    [(ngModel)]="patientComments"
                    (input)="updateCharacterCount(5000, 'patientComments')"
                    type="text"
                  ></textarea>
                  <div class="char-count">
                    {{ patientComments?.length }} / 5000
                  </div>
                </mat-form-field>

                <!--Comments to Shipping -->
                <mat-form-field
                  class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
                >
                  <mat-label>Comments to Shipping </mat-label>
                  <textarea
                    class="textarea-resize-none"
                    matInput
                    rows="4"
                    autocomplete="new-txtShippingComments"
                    maxlength="5000"
                    formControlName="txtShippingComments"
                    [(ngModel)]="shippingComments"
                    (input)="updateCharacterCount(5000, 'shippingComments')"
                    type="text"
                  ></textarea>
                  <div class="char-count">
                    {{ shippingComments?.length }} / 5000
                  </div>
                </mat-form-field>

                <!--Comments to Billing -->
                <mat-form-field
                  class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
                >
                  <mat-label>Comments to Billing </mat-label>
                  <textarea
                    class="textarea-resize-none"
                    matInput
                    rows="4"
                    autocomplete="new-txtBillingComments"
                    maxlength="5000"
                    formControlName="txtBillingComments"
                    [(ngModel)]="billingComments"
                    (input)="updateCharacterCount(5000, 'billingComments')"
                    type="text"
                  ></textarea>
                  <div class="char-count">
                    {{ billingComments?.length }} / 5000
                  </div>
                </mat-form-field>
              </div>
            </div>

            <mat-divider class="my-2"></mat-divider>
            <!-- Other Details -->
            <p class="header-text pl-2 pt-2 mb-2 small-text">Other Details</p>
            <!-- CSR -->
            <mat-form-field class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <mat-label> CSR <span class="asterisk">*</span> </mat-label>

              <mat-select formControlName="drpCSR">
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtCSRFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let csr of filteredUsers | async"
                  [value]="csr.userId"
                >
                  {{ csr.name }}
                </mat-option>
                <!-- <mat-option [value]="0">Value 1</mat-option> -->
              </mat-select>
            </mat-form-field>

            <!-- Location -->
            <mat-form-field class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <mat-label> Location <span class="asterisk">*</span> </mat-label>

              <mat-select formControlName="drpLocation">
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtLocationFilter"
                  ></ngx-mat-select-search>
                </mat-option>

                <mat-option
                  *ngFor="let branch of filteredLocations | async"
                  [value]="branch.id"
                >
                  {{ branch.organizationUnitName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- PAP Therapy Checkbox-->
            <mat-checkbox
              class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3"
              formControlName="chkPAPTherapy"
            >
              <p class="mb-0 text-wrap dd-text">
                PAP Therapy is beneficial and Using equipment as prescribed
              </p>
            </mat-checkbox>

            <!-- Resupply -->
            <mat-checkbox
              class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3"
              formControlName="chkResupply"
            >
              <p class="mb-0 mt-1 pb-1 dd-text">Resupply</p>
            </mat-checkbox>

            <!-- Shipping Method-->
            <mat-form-field class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <mat-label
                >Shipping Method<span class="asterisk">*</span></mat-label
              >
              <mat-select
                formControlName="drpShippingMethod"
                (selectionChange)="selectShipping($event.value)"
                #singleSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtShippingMethodFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  [value]="option.id"
                  *ngFor="let option of filteredShippingMethods | async"
                >
                  {{ option.description }}
                </mat-option>
              </mat-select>

              <mat-error
                class="spanAlignment"
                *ngIf="
                  orderForm?.get('drpShippingMethod')?.touched &&
                  orderForm?.get('drpShippingMethod')?.errors?.required
                "
              >
                Shipping Method field is required!
              </mat-error>
            </mat-form-field>

            <!-- Ordering Provider -->
            <mat-form-field class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <mat-label
                >Ordering Provider<span class="asterisk">*</span></mat-label
              >
              <mat-select formControlName="drpProvider" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    hideClearSearchButton="true"
                    (keyup)="reloadPhysican($event.target.value, false)"
                    formControlName="txtProviderFilter"
                  ></ngx-mat-select-search>
                </mat-option>

                <mat-option
                  *ngFor="let doctor of filteredProviders | async"
                  [value]="doctor.id"
                >
                  {{ doctor.doctorName }}
                </mat-option>
              </mat-select>

              <mat-error
                class="spanAlignment"
                *ngIf="
                  orderForm?.get('drpProvider')?.touched &&
                  orderForm?.get('drpProvider')?.errors?.required
                "
              >
                Provider field is required!
              </mat-error>
            </mat-form-field>

            <!-- Practice Management -->
            <mat-form-field class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <mat-label>Practice Management</mat-label>
              <!-- <span class="asterisk">*</span> -->
              <mat-select formControlName="drpPracticeManagement" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtPracticeFilter"
                  ></ngx-mat-select-search>
                </mat-option>

                <mat-option
                  [value]="option.id"
                  *ngFor="let option of filteredPractices | async"
                >
                  {{ option.practiceName }}
                </mat-option>
              </mat-select>

              <mat-error
                class="spanAlignment"
                *ngIf="
                  orderForm?.get('drpProvider')?.touched &&
                  orderForm?.get('drpProvider')?.errors?.required
                "
              >
                Provider field is required!
              </mat-error>
            </mat-form-field>

            <!-- Preferred Brand -->
            <mat-form-field class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3">
              <mat-label> Preferred Brand </mat-label>
              <input
                matInput
                maxlength="40"
                formControlName="txtPreferredBrand"
                type="text"
                autocomplete="new-txtPreferredBrand"
              />
              <mat-error
                class="spanAlignment"
                *ngIf="
                  orderForm?.get('txtPreferredBrand')?.touched &&
                  orderForm?.get('txtPreferredBrand')?.errors?.required
                "
              >
                Preferred Brand is a required field!
              </mat-error>
            </mat-form-field>

            <!--Comments to Tech -->
            <mat-form-field class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <mat-label>Comments to Tech </mat-label>
              <textarea
                class="textarea-resize-none"
                matInput
                rows="4"
                autocomplete="new-txtTechComments"
                maxlength="5000"
                formControlName="txtTechComments"
                [(ngModel)]="techComments"
                (input)="updateCharacterCount(5000, 'techComments')"
                type="text"
              ></textarea>
              <div class="char-count">{{ techComments?.length }} / 5000</div>
            </mat-form-field>
            <!-- <button (click)="check()">Check</button> -->
            <!-- Rental -->
            <mat-checkbox
              class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 mt-2"
              formControlName="chkRental"
              [disabled]="rentalCheck()"
            >
              Rental
            </mat-checkbox>
            <!-- (!isRental) || showSettingsCount !=1  || isCashOrder || patientValidation() -->

            <!-- [attr.disabled]="true" -->
            <!-- [disabled]="true" -->
            <!-- [class.mat-checkbox-disabled]="true" -->
            <!-- patientValidation() || showSettingsCount !=1 -->

            <!-- [disableRipple]="(!isRental) || showSettingsCount !=1  || isCashOrder || patientValidation()" -->
            <!-- (!isRental) || showSettingsCount !=1  || isCashOrder || patientValidation() -->
            <!-- isRental != true || showSettingsCount !=1  || isCashOrder || patientValidation() -->

            <!-- showSettingsCount  == null || showSettingsCount == undefined || showSettingsCount <= 0 || showSettingsCount>=2 -->
            <!-- <p class="mb-0 mt-1 pb-1 dd-text">Rental </p> -->

            <!-- Months -->
            <!--  -->
            <mat-form-field
              *ngIf="orderForm.value.chkRental"
              class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3"
            >
              <mat-label>Month<span class="asterisk">*</span></mat-label
              ><input
                matInput
                maxlength="2"
                min="2"
                max="13"
                formControlName="txtmoths"
                type="number"
                autocomplete="new-txtmonth"
                (focusout)="fnCheckMaxMonthVlaue()"
              />
              <button
                [disabled]="
                  orderForm.get('txtmoths').value == null ||
                  orderForm.get('txtmoths').value <= 0 ||
                  orderForm.get('txtmoths').value == undefined ||
                  rentalAllowable == null ||
                  rentalAllowable == undefined ||
                  rentalAllowable <= 0
                "
                class="icon-success"
                type="button"
                (click)="viewMonthlyAmount(orderForm.get('txtmoths').value)"
                mat-button
                matSuffix
                mat-icon-button
              >
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </mat-form-field>
          </form>
        </div>
      </div>
      <!-- Right Side Details -->
      <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <form [formGroup]="orderDetailsForm">
          <div class="card curved-card mt-3">
            <div
              class="card-header curved-card-header bg-white p-3 d-flex justify-content-between"
            >
              <p class="header-text pl-1 mb-0">Details</p>
              <span
              (click)="redirect(orderDetails.originalOrderId)"
                *ngIf="isExchangedOrder"
                matTooltip="Click to View Original Order"
                class="rounded-border d-flex justify-content-center align-items-center p-1 claim-status-label cursor-pointer"
              >
                Exchange Order - {{orderDetails.originalMMOrderId}}
              </span>
            </div>
            <div class="card-body">
              <!-- Order No -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-1 w-100"
              >
                <mat-label> Order No </mat-label>
                <input
                  matInput
                  maxlength="40"
                  formControlName="txtOrderNo"
                  type="text"
                  autocomplete="new-txtOrderNo"
                  readonly
                />
              </mat-form-field>

              <!-- Fax ID -->
              <mat-form-field
                [hidden]="true"
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-1 w-100"
              >
                <mat-label> Fax Id </mat-label>
                <input
                  matInput
                  maxlength="20"
                  formControlName="txtFaxID"
                  type="text"
                  autocomplete="new-txtFaxID"
                  readonly
                />
              </mat-form-field>

              <!-- Points -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-1 w-100"
              >
                <mat-label> Points </mat-label>
                <input
                  matInput
                  maxlength="20"
                  formControlName="txtPoints"
                  type="text"
                  autocomplete="new-txtPoints"
                  readonly
                />
              </mat-form-field>

              <!-- Status -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-1 w-100"
              >
                <mat-label> Status </mat-label>
                <input
                  matInput
                  maxlength="40"
                  formControlName="txtStatus"
                  type="text"
                  autocomplete="new-txtStatus"
                  readonly
                />
              </mat-form-field>

              <!-- Alerts -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-1 w-100"
              >
                <mat-label> Alerts</mat-label>
                <textarea
                  class="textarea-resize-none"
                  matInput
                  rows="2"
                  autocomplete="new-txtAlerts"
                  maxlength="200"
                  readonly
                  formControlName="txtAlerts"
                  [(ngModel)]="alertsText"
                  (input)="updateCharacterCount(200, 'alertsText')"
                  type="text"
                ></textarea>
                <div class="char-count">{{ alertsText?.length }} / 200</div>
              </mat-form-field>
            </div>
          </div>

          <div class="card curved-card mt-2">
            <div class="card-header curved-card-header bg-white p-3">
              <p class="header-text pl-1 mb-0">Order Info</p>
            </div>
            <div class="card-body">
              <!-- Deductible -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-1 w-100"
              >
                <mat-label>Deductible ($)</mat-label>
                <input
                  matInput
                  maxlength="20"
                  formControlName="txtDeductible"
                  type="text"
                  autocomplete="new-txtDeductible"
                  readonly
                />
              </mat-form-field>

              <!-- Copay -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-1 w-100"
              >
                <mat-label>Copay (%)</mat-label>
                <input
                  matInput
                  maxlength="20"
                  formControlName="txtCopay"
                  type="text"
                  autocomplete="new-txtCopay"
                  readonly
                />
              </mat-form-field>
              <!-- Estimated Allowable -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-1 w-100"
              >
                <mat-label>Estimated Allowable ($)</mat-label>
                <input
                  matInput
                  maxlength="20"
                  formControlName="txtEstimatedAllowable"
                  type="text"
                  autocomplete="new-estimatedAllowable"
                  readonly
                />
              </mat-form-field>
              <!-- Estimated Copay -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-1 w-100"
              >
                <mat-label>Estimated Copay ($)</mat-label>
                <input
                  matInput
                  maxlength="20"
                  formControlName="txtEstimatedCopay"
                  type="text"
                  autocomplete="new-estimatedCopay"
                  readonly
                />
              </mat-form-field>

              <!-- Total Accessory -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-1 w-100"
              >
                <mat-label>Total Accessory ($)</mat-label>
                <input
                  matInput
                  maxlength="20"
                  formControlName="txtTotalAccessory"
                  type="text"
                  autocomplete="new-totalAccessory"
                  readonly
                />
              </mat-form-field>
              <!-- Shipping Fee -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-1 w-100"
              >
                <mat-label>Shipping Fee ($)</mat-label>
                <input
                  matInput
                  maxlength="20"
                  formControlName="txtShippingFee"
                  type="text"
                  autocomplete="new-shippingFee"
                  readonly
                />
              </mat-form-field>

              <!-- Net Total -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-1 w-100"
              >
                <mat-label>Net Total ($)</mat-label>
                <input
                  matInput
                  maxlength="20"
                  formControlName="txtNetTotal"
                  type="text"
                  autocomplete="new-netTotal"
                  readonly
                />
              </mat-form-field>
            </div>
          </div>

          <div class="card curved-card mt-2 mb-3">
            <div class="card-header curved-card-header bg-white p-3">
              <p class="header-text pl-1 mb-0">Payment Info</p>
            </div>
            <div class="card-body">
              <!-- Past Due Balance -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 w-100"
              >
                <mat-label>Past Due Balance ($)</mat-label>
                <input
                  matInput
                  maxlength="20"
                  formControlName="txtPastDueBalance"
                  type="text"
                  autocomplete="new-pastDueBalance"
                  readonly
                />
              </mat-form-field>

              <!-- Estimated Amount Due -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 w-100"
              >
                <mat-label>Estimated Amount Due ($)</mat-label>
                <input
                  matInput
                  maxlength="20"
                  formControlName="txtEstimateAmountDue"
                  type="text"
                  autocomplete="new-estimatedAmountDue"
                  readonly
                />
              </mat-form-field>

              <!-- Balance -->
              <mat-form-field
                class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 w-100"
              >
                <mat-label>Balance ($)</mat-label>
                <input
                  matInput
                  maxlength="20"
                  formControlName="txtBalance"
                  type="text"
                  autocomplete="new-txtBalance"
                  readonly
                />
              </mat-form-field>

              <!-- [disabled]="productDisabled || paymentValidation" -->
              <!-- Buttons -->
              <div class="row d-flex justify-content-around">
                <div>
                  <button
                    matTooltip="Process Payment"
                    mat-raised-button
                    class="buttonColor mat-raised-small-button px-2"
                    [disabled]="
                      !productId ||
                      !(!orderForm?.invalid && orderForm?.dirty) ||
                      saveButtonEnabled ||
                      addressValidation ||
                      isSaveBtnDisabled ||
                      isAddressEdit ||
                      orderDetailsForm?.get('txtTotalAccessory')?.value <= 0 ||
                      isMailSent === 1 ||
                      orderForm.get('chkCashOrder').value != true
                    "
                    (click)="openPaymentOptions()"
                  >
                    Process Payment
                  </button>
                </div>
                <div>
                  <button
                    matTooltip="Send Invoice"
                    mat-raised-button
                    class="buttonColor mat-raised-small-button px-2"
                  >
                    Send Invoice
                  </button>
                </div>
              </div>

              <!-- Invoice Summary -->
              <!-- <mat-checkbox
                [disabled]="productDisabled"
                formControlName="chkInvoiceSummary"
                class="mt-1 text-center py-2 align-middle"
              >
                <p class="mb-0">Invoice Summary</p>
              </mat-checkbox> -->
              <!-- Send Invoice Reminder Button -->
              <!-- <div class="d-flex justify-content-center">
                <button
                  [disabled]="productDisabled"
                  mat-raised-button
                  class="buttonColor"
                >
                  Send Invoice Reminder
                </button>
              </div> -->
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="col-12 pb-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <!-- Item Details -->
    <div class="card curved-card">
      <div class="card-header curved-card-header p-0 bg-white">
        <p class="header-text pl-3 pt-2 mb-2 small-text">Item Details</p>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-center">
          <!--*ngIf="orderForm?.invalid" -->
          <p class="text-danger mb-0 fw-bolder" *ngIf="false">
            <b>Please fill mandatory fields to add Items</b>
          </p>
        </div>
        <div class="table table-responsive">
          <!--[hidden]="orderForm?.invalid" -->
          <table
            datatable
            #itemTable
            class="row-border hover w-100 display"
            [dtOptions]="dtItemTableOptions"
            [dtTrigger]="dtTrigger"
          >
            <thead>
              <tr>
                <th>sortNumber</th>
                <th><mat-icon>apps</mat-icon></th>
                <th [matTooltip]="'Product Code'">
                  Product Code<span class="asterisk">*</span>
                </th>
                <th [matTooltip]="'Description'">Description</th>
                <th [matTooltip]="'Size'">Size</th>
                <th [matTooltip]="'Qty'" class="text-right">Qty</th>
                <th [matTooltip]="'Serial No'">Serial No</th>
                <th [matTooltip]="'QtyOnHand'" class="text-right">QtyOnHand</th>
                <th [matTooltip]="'AwaitDlvry'" class="text-right">
                  AwaitDlvry
                </th>
                <th [matTooltip]="'PendpatOrder'" class="text-right">
                  PendpatOrder
                </th>
                <th [matTooltip]="'Allowable'" class="text-right">
                  Allowable ($)
                </th>
                <th [matTooltip]="'Accessory'" class="text-right">
                  Accessory ($)
                </th>
                <th [matTooltip]="'Points'" class="text-right">Points</th>
                <th [matTooltip]="'Discount %'" class="text-right">
                  Discount %
                </th>
                <th [matTooltip]="'Exchange'">Exchange</th>
                <th [matTooltip]="'Return'">Return</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of itemsList">
                <!-- SortNumber-->
                <td>{{ data.sortNumber }}</td>
                <!-- Options -->
                <td>
                  <button
                    type="button"
                    [matTooltip]="'Remove Product'"
                    class="options"
                    (click)="removeProduct(data.mmProductId, data.sortNumber)"
                    [disabled]="isDisableRemoveItem"
                    [ngClass]="{ 'disabled-link': isDisableRemoveItem }"
                    mat-icon-button
                  >
                    <mat-icon class="text-danger mb-2">close</mat-icon>
                  </button>
                  <button
                    [matTooltip]="'Machine Settings'"
                    type="button"
                    *ngIf="data?.showSettings == 1"
                    mat-icon-button
                    (click)="
                      getMachineSettings(data.mmProductId, data.categoryName)
                    "
                    class="options"
                  >
                    <mat-icon class="lightseagreen mb-2">settings</mat-icon>
                  </button>
                </td>
                <!-- Product Code -->
                <td
                  [matTooltip]="data?.productCode"
                  [ngClass]="{ isStockMinQty: data.sMinStock === true }"
                >
                  <mat-select
                    style="width: 80px"
                    (selectionChange)="
                      loadProductDetails(
                        orderForm.get('drpChartId').value,
                        data,
                        drpProductCode.value,
                        txtQuantity.value
                      )
                    "
                    #drpProductCode
                    [(value)]="data.mmProductId"
                    placeholder="Product Code"
                    class="custom-dropdown dd-text"
                    [disabled]="
                      patientValidation() ||
                      (orderForm.get('chkCashOrder').value != true &&
                        (orderForm.get('drpPrimaryInsurance').value ===
                          defaultGuid ||
                          orderForm.get('drpPrimaryInsurance').value === null ||
                          orderForm.get('drpPrimaryInsurance').value === '' ||
                          orderForm.get('drpPrimaryInsurance').value ===
                            undefined)) ||
                      isDisableRemoveItem
                    "
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        [formControl]="searchControl"
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        (keyup)="filterProducts()"
                      ></ngx-mat-select-search
                    ></mat-option>

                    <mat-option
                      *ngFor="let products of filteredOptions"
                      [ngClass]="{
                        isStockMinQty: products.sMinStock === true
                      }"
                      [value]="products.productId"
                    >
                      {{ products.productName }}
                    </mat-option>
                  </mat-select>
                </td>
                <td
                  [matTooltip]="data?.productDescription || ''"
                  [ngClass]="{ isStockMinQty: data.sMinStock === true }"
                >
                  {{ data.productDescription }}
                </td>
                <!-- Size -->
                <td [matTooltip]="'Size'">
                  <mat-select
                    style="width: 80px"
                    #drpSize
                    [disabled]="data.mmProductId == '' || isDisableRemoveItem"
                    [(value)]="data.sizeId"
                    placeholder="Size"
                    class="custom-dropdown dd-text"
                    (selectionChange)="selectSize(data)"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        [formControl]="searchSize"
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        (keyup)="filterSize()"
                      ></ngx-mat-select-search
                    ></mat-option>

                    <mat-option
                      *ngFor="let mask of filteredSize"
                      [value]="mask.id"
                    >
                      {{ mask.description }}
                    </mat-option>
                  </mat-select>
                </td>
                <!-- Quantity -->
                <td [matTooltip]="data?.qty || '1'" class="text-right">
                  <input
                    maxlength="8"
                    [disabled]="data.mmProductId == ''"
                    class="textbox-border-class dd-text text-right"
                    [(ngModel)]="data.qty"
                    #txtQuantity="ngModel"
                    required
                    (focus)="getCurrentValue(data.qty)"
                    (focusout)="updateQty(data, data.qty)"
                    (keypress)="onKeyPress($event)"
                    matInput
                    type="text"
                    autocomplete="off"
                    [readonly]="data?.showSettings == 1 || isDisableRemoveItem"
                  />
                  <div
                    class="text-danger dd-text text-center"
                    *ngIf="!txtQuantity.valid && txtQuantity.touched"
                  >
                    Required!
                  </div>
                  <div
                    class="text-danger dd-text text-error text-center"
                    *ngIf="
                      txtQuantity.value === '0' ||
                      txtQuantity.value === null ||
                      txtQuantity.value === ''
                    "
                  >
                    Enter Minimum Quantity!
                  </div>
                </td>
                <!-- Serial No -->
                <td [matTooltip]="'Serial No'">
                  <!-- <mat-select
                    [disabled]="productDisabled"
                    style="width: 90px"
                    #drpSerialNo
                    [(value)]="data.serialLotNo"
                    placeholder="Serial No"
                    class="custom-dropdown dd-text"
                  >
                    <mat-option
                      *ngFor="let serialNo of data.ltSerialLotNos"
                      [value]="serialNo.id"
                    >
                      {{ serialNo.lotSerialNo }}
                    </mat-option>
                  </mat-select> -->

                  <input
                    maxlength="20"
                    [disabled]="data.mmProductId == ''"
                    class="textbox-border-class dd-text text-right"
                    [(ngModel)]="data.serialLotNo"
                    #txtSerialNo="ngModel"
                    required
                    matInput
                    [readonly]="isDisableRemoveItem"
                    type="text"
                  />
                </td>
                <td
                  [matTooltip]="data?.patientQtyOnHand || '0'"
                  class="text-right"
                >
                  {{ data.patientQtyOnHand }}
                </td>
                <td [matTooltip]="data?.awaitDlvry || '0'" class="text-right">
                  {{ data.awaitDlvry }}
                </td>
                <td [matTooltip]="data?.pendpalOrder || '0'" class="text-right">
                  {{ data.pendpalOrder }}
                </td>
                <td [matTooltip]="data?.allowable || '0'" class="text-right">
                  {{ data.allowable ? toFixed(data.allowable) : "-" }}
                </td>
                <td [matTooltip]="data?.accessory || '0'" class="text-right">
                  <!-- <input
                  [disabled]="productDisabled"
                  maxlength="8"
                  class="textbox-border-class"
                  [(ngModel)]="data.accessory"
                  #txtQuantity="ngModel"
                  required
                  placeholder="Accessory"
                  (focusout)="updateAccessory(data)"
                  (keypress)="onKeyPress($event)"
                  matInput
                  type="text"
                /> -->
                  {{ data.accessory ? toFixed(data.accessory) : "-" }}
                </td>
                <td [matTooltip]="data?.points_Cal || '0'" class="text-right">
                  {{ data.points_Cal }}
                </td>
                <td
                  [matTooltip]="data?.discountPercent || '0'"
                  class="text-right"
                >
                  <input
                    maxlength="8"
                    class="textbox-border-class text-right"
                    [(ngModel)]="data.discountPercent"
                    #txtDiscount="ngModel"
                    required
                    placeholder="Discount Percent"
                    (focus)="getCurrentValue(data.discountPercent)"
                    (focusout)="updateAccessory(data, data.discountPercent)"
                    (keypress)="numbersAndfloatOnly($event)"
                    (paste)="onDiscountPaste($event)"
                    matInput
                    [readonly]="
                      !isDisCount(data?.allowable) || isDisableRemoveItem
                    "
                    type="text"
                  />
                  <div
                    class="text-danger dd-text text-error text-center"
                    *ngIf="checkDiscount(data.discountPercent)"
                  >
                    Invalid Percentage
                  </div>
                </td>
                <td>
                  <mat-checkbox [(ngModel)]="data.exchange"></mat-checkbox>
                </td>
                <td>
                  <mat-checkbox [(ngModel)]="data.return"></mat-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div
    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
    *ngIf="frequentBoughtItems.length > 0"
  >
    <p class="header-text pl-2 pt-2 mb-2 small-text">Frequently Added Items</p>
    <div class="row row-cols-5" *ngIf="!isloadFrequentBoughtItems">
      <ng-container *ngFor="let data of frequentBoughtItems">
        <div class="col">
          <div class="card mb-3 mt-2">
            <div class="card-body p-2">
              <div [ngbTooltip]="data?.productCode || '-'" class="mydiv">
                <p
                  (dblclick)="onRowDoubleClick(data?.productCode || '-')"
                  class="my-p-tag"
                >
                  <b class="my-p-tag">Product Code:</b>
                  {{ data?.productCode || "-" }}
                </p>
              </div>
              <div [ngbTooltip]="data?.productName || '-'" class="mydiv">
                <p
                  (dblclick)="onRowDoubleClick(data?.productName || '-')"
                  class="my-p-tag"
                >
                  <b class="my-p-tag">Description:</b>
                  {{ data?.productName || "-" }}
                </p>
              </div>
              <div [ngbTooltip]="data?.orderedCount || '-'" class="mydiv">
                <p
                  (dblclick)="onRowDoubleClick(data?.orderedCount || '-')"
                  class="my-p-tag"
                >
                  <b class="my-p-tag">Purchased times:</b>
                  {{ data?.orderedCount || "-" }}
                </p>
              </div>
              <div [ngbTooltip]="data?.unitCost || '-'" class="mydiv">
                <p
                  (dblclick)="onRowDoubleClick(data?.unitCost || '-')"
                  class="my-p-tag"
                >
                  <b class="my-p-tag">Amount($):</b> {{ data?.unitCost || "0" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      class="d-flex justify-content-center"
      *ngIf="isloadFrequentBoughtItems"
    >
      <img
        class="img-responsive pb-2 mr-1"
        src="assets/gifs/Infinity-loader.gif"
      />
    </div>
  </div>
</div>
<app-loader-component *ngIf="isOrderPageLoading"></app-loader-component>
