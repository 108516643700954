import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
// import {
//    inboundTableData,
// } from '../admin-dashboard/dashboard-data';
import { DataTableDirective } from 'angular-datatables';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CheckListMasterBinService,
  PatientDocumentCheckListService,
} from '../admin-proxy/platform-app-management/rcm/platform-management/check-list-master-and-patient-document-check-list';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { ProductCategoryDetailsService } from 'projects/inventory/src/app/item-proxy/item-management/optimization';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { defaultGuid } from '../document-perviewer/document-perviewer.component';
import { create } from 'domain';
import { element } from 'protractor';
import { InboundStatusService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound-status/inbound-status.service';
import { OrganizationUnitDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { InboundStatusDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound-status/dto';
import { Subscription, throwError } from 'rxjs';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import {
  DocumentCheckListRows,
  DocumentCheckListRowsText,
  DocumentCheckListRowsTextForAPI,
} from 'projects/shared/src/app/enums/allenums.enum';
import { EventEmitter, Output } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { ProductService } from 'projects/inventory/src/app/item-proxy/item-management/items/product.service';
// import { DashboardService } from '../admin-proxy/platform-app-management/rcm/platform-management/dashboard';
@Component({
  selector: 'app-document-check-list',
  templateUrl: './document-check-list.component.html',
  styleUrls: ['./document-check-list.component.scss'],
})
export class DocumentCheckListComponent implements OnInit {
  drpdownType: any;
  selectOption = 'Select';
  productDtails: any;
  selectedRow: any;
  loadTable: boolean = true;
  selectRowCodes: any;
  selectRowTypes: any;
  selectRowpressure: any;
  selectRowDates: any;
  selectType: any;
  docID: string;
  editMode = false;
  cardDetails: any;
  tblSelectedOrderId: string;
  tblItemSelectedRowId: string;
  inboundTableData: any;
  locationList: OrganizationUnitDTO[] = []; //Location Menu List
  binList: InboundStatusDTO[] = []; //Bin Menu List
  $subscription: Subscription[] = [];
  checkListData: any;
  dtInboundOptions = dtInboundOptions;
  viewNotes: any = [];
  savedNoteDetails: string;
  lastSave5Notes: any[] = [];
  textArray: {
    flag: DocumentCheckListRows;
    descriptionId: string;
    text: string;
  }[] = [];
  FinalText: string;
  DocumentCheckListRows = DocumentCheckListRows;
  // @Input() viewNotes: { key: string; value: string }[] = [];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  docCheckList: FormGroup;
  docCheckListTableDetailsForm: FormGroup;
  faxId: any;
  tenantId: any;
  sessionUserName: string;
  currentText = '';
  StartWord: string;
  isLoading = false;
  isNewSetupV1: boolean = false;
  // subscription: Subscription[] = [];
  @Output() closed = new EventEmitter();
  @ViewChild('Docpicker') Docpicker: MatDatepicker<Date>;
  chartID: any;
  patientName: string;
  defaultPatientId: any;
  maxDate: Date;
  isDocument: boolean = false;
  isPlanSaveV1: boolean = false;
  isShowSpinner: boolean = false;
  TypeControl = new FormControl();
  TypeControlV1=new FormControl();
  productDtailsV1: any=[];
  drpdownTypeV1: any=[];
  closeBtnDisabled: boolean = false;
  DATE: RegExp;
  DAYS: number[];
  agoOneYear:Date;
  fiveYearAgo:Date;
  isCheckUpdate:boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private patientDocCheckList: PatientDocumentCheckListService,
    private ProductCategoryDetailsService: ProductCategoryDetailsService,
    public notesDialogRef: MatDialogRef<DocumentCheckListComponent>,
    private CheckListMasterBinService: CheckListMasterBinService,
    private inboundStatusService: InboundStatusService,
    private inboundDocumentService: InboundDocumentService,
    private branchService: OrganizationUnitService,
    public DialogRef: MatDialogRef<DocumentCheckListComponent>,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private communicationService: CommunicationService,
    private productservice: ProductService,
    // private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      documentId: string;
      faxId: string;
      isNewSetup: boolean;
      isPlanSave: boolean;
      isViewCheckList:boolean;
    } = {
      documentId: defaultGuid,
      faxId: '',
      isNewSetup: false,
      isPlanSave: false,
      isViewCheckList:false
    }
  ) {
    this.maxDate = new Date();
    this.maxDate.setHours(23, 59, 59); // Set the time to the end of the day
  }

  ngOnInit(): void {
  this.isCheckUpdate = this.data?.isViewCheckList === true ? true : false;
  this.DATE = /^(\d\d)-(\d\d)-(\d\d\d\d)$/
  this.DAYS = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.sessionUserName = localStorage.getItem('name') ?? '';
    this.docID = this.data?.documentId ?? defaultGuid;
    this.loadPatientDetails();
    this.faxId = this.data?.faxId;
    this.isNewSetupV1 = this.data?.isNewSetup;
    this.isPlanSaveV1 = this.data?.isPlanSave;
    this.docCheckList = this.formBuilder.group({
      // txtNotes: new FormControl('', [Validators.required]),
      // txtNotes: new FormControl('', [Validators.required, Validators.pattern(/^\S+$/)]),
      // txtNotes: new FormControl('', [Validators.required, Validators.pattern(/^(save|enter|\S+)$/)]),
      // txtNotes: new FormControl('', [Validators.required, Validators.pattern(/^\S+$/)]),
      txtNotes: new FormControl('', [
        Validators.required,
        Validators.pattern(/\S/),
      ]),
    });
    this.docCheckListTableDetailsForm=this.formBuilder.group({
      txtNoteType: new FormControl(''),
      txtNoteTypesV1: new FormControl(''),
    })
    this.setStartWord();
    this.getDocCheckList();

    //Type Drop Down
    this.TypeControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.productDtails = this.drpdownType.filter((dx) =>
        dx.categoryName.toLowerCase().includes(FilterText)
      );
    });

       //Type Drop Down
       this.TypeControlV1.valueChanges.subscribe((FilterText) => {
        FilterText = FilterText.toLowerCase();
        this.productDtailsV1 = this.drpdownTypeV1.filter((dx) =>
          dx.categoryName.toLowerCase().includes(FilterText)
        );
      });


    this.productDtailsV1 = [
      { categoryName: 'Home Sleep Apnea Testing (HST)' },
      { categoryName: 'Diagnostic sleep study (night1)' },
      { categoryName: 'PAP titration study (night2)' },
      { categoryName: 'Split night sleep study' }
    ];
this.drpdownTypeV1=this.productDtailsV1;
       var oneYearAgo = new Date();
       oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
       this.agoOneYear = oneYearAgo;

       var fiveYearAgo = new Date();
       fiveYearAgo.setFullYear(fiveYearAgo.getFullYear() - 5);
       this.fiveYearAgo = fiveYearAgo;

  }

  ngOnDestroy(): void {
    if (this.$subscription) {
      // Iterate through each subscription and unsubscribe
      this.$subscription.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    let restrictedKeyCodes: number[] = [

    ]; // ,190,110,189,69,187,109, 107, 106, 111,   40, 38,
    if (restrictedKeyCodes.includes(event.keyCode)) {
      event.preventDefault(); // Prevent 'e' from being entered
    }
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  loadPatientDetails() {
    const getPatientDetails = this.inboundDocumentService
      .getPatientDemographicsDetails(this.docID)
      .subscribe(
        (response) => {
          this.chartID = response?.chartId || '';
          this.patientName = (
            (response?.firstName || '') +
            ' ' +
            (response?.middleName || '') +
            ' ' +
            (response?.lastName || '')
          ).trim();
          this.defaultPatientId = response?.defaultPatientId || '';
        },
        (err) => {
          const data: HttpErrorResponse = err;
          // this.toastr.error(data?.error?.error?.message, 'Error');
          console.warn(data?.error?.error?.message);
        }
      );
  }

  setStartWord() {
    this.StartWord = this.sessionUserName + ':' + this.getToday() + '-';
  }

  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  async saveDetails(data) {
    this.isDocument = true;
    this.isCheckUpdate=true;
    try {
      let saveDetails = {
        descriptionId: data.descriptionId ?? '',
        optinal: data.optinal ?? null,
        valid: data.valid ?? null,
        signature: data.signature ?? null,
        type: data.type ?? null,
        typeName:data.typeName??null,
        pressureSettings:
          data.pressureSettings !== null &&
          data.pressureSettings !== undefined
          // && data.pressureSettings <= 0
            ? data.pressureSettings.toString()
            : '',
        // notes: data.notes?? '',
        code: data?.code ?? '',
        doctorPrintName: data?.doctorPrintName ?? null,
        date: (data.date===''? null : data.date),
      };

      this.patientDocCheckList.checkLisUpdate(this.checkListData.id, saveDetails).pipe(catchError((error) => {
            this.toastr.error('An error occurred. Please try again later.','Error');
            this.isCheckUpdate=false;
            return throwError(error);
          })
        )
        .subscribe((res) => {
          // this.toastr.success('Updated sucessfully', 'Update');
          this.showNotification('Updated sucessfully');
          this.isCheckUpdate=false;
        });
    } catch (error) {
      this.isCheckUpdate=false;
      console.error('Error:', error);
    } finally {
    }
  }

  // getDocCheckList() {
  //   this.loadTable = false;
  //   this.ProductCategoryDetailsService.getList(
  //     new PagedAndSortedResultRequestDto()
  //   ).subscribe(
  //     (res) => {
  //       this.productDtails = res.items;
  //       this.drpdownType = this.productDtails;
  //       this.afterAPicall();
  //     },
  //     (err) => {
  //       this.loadTable = true;
  //     }
  //   );
  //   this.isLoading = true;
  // }

  getDocCheckList() {
    this.loadTable = false;
    this.productservice.getproductcategorylistdrp().subscribe(
      (response) => {
        this.productDtails = response;
        this.drpdownType = this.productDtails;
        this.afterAPicall();
      },
      (err) => {
        this.loadTable = true;
      }
    );
    this.isLoading = true;
  }

  afterAPicall() {
    this.CheckListMasterBinService.getDocumentDetailsByGDocId(
      this.docID
    ).subscribe(
      (response) => {
        this.checkListData = response?.items[0];
        this.loadTable = true;
        this.inboundTableData = response?.items[0]['documentCheckList'];
        this.cardDetails = response?.items;
        if (response.items[0]['lstChecklistNotes'].length > 0) {
          // const joinedNotes =
          //   response.items[0]['lstChecklistNotes'].join('<br>');
          const joinedNotes = response?.items[0]['lstChecklistNotes']
            .join('<br>')
            .replace(/\n/g, '<br>');
          this.savedNoteDetails = joinedNotes;
        } else {
          this.savedNoteDetails = '';
        }
        this.isLoading = false;
        // Patch Selected Option
        this.inboundTableData.forEach((element) => {
          let productName = this.productDtails.filter(
            (product) => product.categoryName === element.typeName
          );
          element.selectedOption = productName[0];
          if(productName.length===0){
            let productName = this.productDtailsV1.filter(
              (product) => product.categoryName === element.typeName
            );
            element.selectedOption = productName[0];
          }
        });
        //  // Patch Selected Option
        //  this.inboundTableData.forEach((element) => {
        //   let productName = this.productDtailsV1.filter(
        //     (product) => product.categoryName === element.typeName
        //   );
        //   element.selectedOption = productName[0];
        // });
        this.checkListData.checkListnotes = response.items[0].notes;
        this.checkListData.id = response.items[0].id;
      },
      (err) => {
        this.loadTable = true;
        this.isLoading = false;
      }
    );
  }
  selectRow(cell: any) {
    this.selectedRow = cell;
    this.editMode = true;
  }
  selectRowType(cell: any) {
    this.selectRowTypes = cell;
    this.editMode = true;
  }

  selectRowpressureSettings(cell: any) {
    this.selectRowpressure = cell;
    this.editMode = true;
  }
  selectRowDate(cell: any) {
    this.selectRowDates = cell;
    this.editMode = true;
  }
  selectRowCode(cell: any) {
    this.selectRowCodes = cell;
    this.editMode = true;
  }

  toggleEditMode() {
    if (!this.editMode) {
      this.selectedRow = null;
    }
    this.editMode = !this.editMode;
  }

  isDateValidFilter = (date: Date | null): boolean => {
    // Return true if the date is valid; otherwise, return false
    return date instanceof Date && !isNaN(date.getTime());
  };

  getDateWithOutTime(date: Date) {
    const currentDate = date;
    const formattedDate = `${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${currentDate
      .getDate()
      .toString()
      .padStart(2, '0')}/${currentDate.getFullYear()}`;
    return formattedDate;
  }

  getToday() {
    const currentDate = new Date();
    let hours = currentDate.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    const formattedDate = `${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${currentDate
      .getDate()
      .toString()
      .padStart(2, '0')}/${currentDate.getFullYear()} ${hours
      .toString()
      .padStart(2, '0')}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}:${currentDate
      .getSeconds()
      .toString()
      .padStart(2, '0')} ${ampm}`;

    return formattedDate;
  }

  getUTC() {
    const currentDate = new Date();
    const hours = currentDate.getUTCHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    const formattedHours = hours % 12 || 12;

    const formattedDate = `${(currentDate.getUTCMonth() + 1)
      .toString()
      .padStart(2, '0')}/${currentDate
      .getUTCDate()
      .toString()
      .padStart(2, '0')}/${currentDate.getUTCFullYear()} ${formattedHours
      .toString()
      .padStart(2, '0')}:${currentDate
      .getUTCMinutes()
      .toString()
      .padStart(2, '0')}:${currentDate
      .getUTCSeconds()
      .toString()
      .padStart(2, '0')} ${ampm}`;

    return formattedDate;
  }

  onCheckboxChangeV1(data: any, flag: number) {
    this.setNotesText(data, flag, {});
  }

  onDropdownSelect(selectedOption: any, data: any) {
    data.type = selectedOption.productCategortyId??null;
    data.typeName =selectedOption.categoryName;
    data.selectedOption = selectedOption;
    this.selectType = selectedOption.categoryName;
    this.setNotesText(data, DocumentCheckListRows.Type, selectedOption);
  }

  onDropdownSelectV1(data: any) {
    this.selectType = data.selectedOption;
    this.setNotesText(data, DocumentCheckListRows.Type, data.selectedOption);
  }

  numberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event?.which ? event?.which : event?.keyCode;
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 44 || charCode > 45)
    ) {
      return false;
    }
    return true;
  }
  onChange(data: any, flag: number) {
    this.setNotesText(data, flag, {});
  }
  onChangeDate(data: any, event: any,InputValue?:any) {
    const valueInput =InputValue.trim();
    const inputValue = this.datePipe.transform(data.date, 'MM/dd/yyyy');
    const agofiveYear = this.datePipe.transform(this.fiveYearAgo, 'MM/dd/yyyy');
    const agoOneYear = this.datePipe.transform(this.agoOneYear, 'MM/dd/yyyy');
    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    if (inputValue !== '' && !dateRegex.test(inputValue) && inputValue !== null) {
      // Invalid date format, you can handle this as per your requirements
      this.toastr.warning(
        'Invalid date format. Please enter a valid date..',
        'Warning'
      );
      // this.toastr.warning('Empty/null Date has been updated.', 'Success');
      // this.showNotification('Empty/null Date has been updated.');
      data.date = '';
      // return;
    }
    const inputDate = new Date(inputValue);
    const oneYearAgo = new Date(agoOneYear);
    const fiveYearago = new Date(agofiveYear);
    if (inputDate < this.maxDate && inputDate >= (data.description==='Prescription'?fiveYearago:oneYearAgo) ) {

      // return;
      // Handle the case where the input date is greater than the maximum date
    }else{
      if (inputValue !== '' && valueInput !=='' ) {
        if(data.description==='Prescription'){
          this.toastr.warning('Valid and Past Five year date only allowed', 'Warning');
        }else{
      this.toastr.warning('Valid and Past one year date only allowed', 'Warning');
        }
      // this.toastr.warning('Future date has not allowed', 'Warning');
      data.date = '';
      return;
      }
    }
   if(valueInput !==''){
    this.setNotesText(data, DocumentCheckListRows.Date, {});
   }
  }
   date(str: string): boolean {
    // full-date from http://tools.ietf.org/html/rfc3339#section-5.6
    const matches: string[] | null = this.DATE.exec(str)
    if (!matches) return false
    const year: number = +matches[1]
    const month: number = +matches[2]
    const day: number = +matches[3]
    return (
      month >= 1 &&
      month <= 12 &&
      day >= 1 &&
      day <= (month === 2 && this.isLeapYear(year) ? 29 : this.DAYS[month])
    )
  }
   isLeapYear(year: number): boolean {
    // https://tools.ietf.org/html/rfc3339#appendix-C
    return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)
  }

  setNotesText(data: any, flag: number, selectedOption: any) {
    // Create a function to dynamically generate text based on the flag by Manikandan - AD18060700
    const generateText = (flag: number, data: any) => {
      const rowText = DocumentCheckListRowsText[flag] || 'Unknown Row';
      let propertyName = '';
      let rowTextValue;
      if (flag === DocumentCheckListRows.Type) {
        rowTextValue = selectedOption.categoryName || 'Unknown'; // Vo
        // rowTextValue = selectedOption || 'Unknown';
      } else if (flag === DocumentCheckListRows.Date) {
        propertyName = DocumentCheckListRowsTextForAPI[flag].trim() || 'Unknown';
        if (
          data[propertyName] != null &&
          data[propertyName] !== undefined &&
          data[propertyName] !== ''
        ) {
          rowTextValue = this.getDateWithOutTime(data[propertyName]) || '-';
        } else {
          rowTextValue = '-';
        }
      }
      else if(flag === DocumentCheckListRows.Pressure_Settings){
        propertyName = DocumentCheckListRowsTextForAPI[flag] || 'Unknown';
        rowTextValue = data[propertyName].trim() || '-';
      }
      else {
        propertyName = DocumentCheckListRowsTextForAPI[flag] || 'Unknown';
        if (data[propertyName] !== null && data[propertyName] !== undefined) {
          rowTextValue = data[propertyName].toString().trim();
        } else {
          rowTextValue = '';
        }
      }
      return ` (${data.description} : ${rowText}:${rowTextValue}). `;
    };

    const existingItemIndex = this.textArray.findIndex(
      (item) => item.flag === flag && item.descriptionId === data.descriptionId
    );

    if (existingItemIndex !== -1) {
      // Item with the flag already exists, update it
      this.textArray[existingItemIndex] = {
        flag: flag,
        descriptionId: data.descriptionId,
        text: generateText(flag, data),
      };
    } else {
      // Item with the flag doesn't exist, push a new item
      this.textArray.push({
        flag: flag,
        descriptionId: data.descriptionId,
        text: generateText(flag, data),
      });
    }
    this.FinalText = this.textArray.map((item) => item.text).join('');
    // this.docCheckList.get('txtNotes').setValue(this.StartWord + this.FinalText);
    this.docCheckList.get('txtNotes').setValue(this.FinalText);
    this.saveDetails(data);
  }

  async addNotes(isModelClose = 0) {
    this.isDocument = true;
    this.isShowSpinner = true;
    // let currentDateTime = this.getToday();
    // let currentUTCTime: string = new Date().toUTCString();
    let currentUTCTime = this.getUTC();
    if (
      this.docCheckList.value.txtNotes !== '' &&
      this.docCheckList.value.txtNotes !== null
    ) {
      const addNotes = this.patientDocCheckList
        .updateCheckListNotesByGpatientIdAndGDocumentIdAndSCommentsAndGPatientChecklistIdAndSCreatedDateV1(
          this.checkListData && this.checkListData.patientId
            ? this.checkListData.patientId
            : defaultGuid,
          this.docID,
          this.docCheckList.value.txtNotes.trim(),
          (this.checkListData && this.checkListData.id) || defaultGuid,
          // currentUTCTime
        )
        .pipe(
          catchError((error) => {
            this.isShowSpinner = false;
            console.error('Error occurred:', error);
            // Optionally, you can rethrow the error if you want the calling code to handle it as well
            return throwError(error);
          })
        )
        .subscribe((response) => {
          // this.getDocCheckList();
          this.isShowSpinner = false;
          if (response.checkListNotes.length > 0) {
            const joinedNotes = (response.checkListNotes).join('<br>').replace(/\n/g, '<br>');
            this.savedNoteDetails = joinedNotes;
            this.lastSave5Notes = (response.commonNotes).slice(-5).reverse();
            if (isModelClose === 1) {
              this.notesDialogRef.close(this.lastSave5Notes);
            }
          } else {
            this.isShowSpinner = false;
            this.savedNoteDetails = '';
            if (isModelClose === 1) {
              this.notesDialogRef.close([]);
            }
          }

          this.showNotification('Notes Added sucessfully');
          this.isShowSpinner = false;
          this.setStartWord();
          this.docCheckList.patchValue({
            txtNotes: '',
          });

          this.docCheckList.markAsPristine();
          this.docCheckList.markAsUntouched();
          this.textArray = [];
        });
      this.$subscription.push(addNotes);
    } else {
      this.isShowSpinner = false;
      if (isModelClose === 1) {
        if (this.lastSave5Notes.length === 0) {
          this.notesDialogRef.close(-1);
        } else {
          this.notesDialogRef.close(this.lastSave5Notes);
        }
      }
    }
    // this.closed.emit({ patientNotes: ['sdfsdf','sdfsdfsdf']});
  }

  onCloseClick(): void {
    // this.notesDialogRef.close([]);
    this.closeBtnDisabled=true;
    if (this.isNewSetupV1 === true) {
      this.communicationService.triggerNewSetupFunction(this.isDocument);
    } else {
      if (this.isPlanSaveV1 === true) {
        this.communicationService.triggerNewSetupPlanSaveFunction(
          this.isDocument
        );
      }
    }

    this.addNotes(1);
    // Add any additional logic you want here
  }
  skipDocChecklist(){
    this.isDocument = true;
    if (this.isNewSetupV1 === true) {
      this.communicationService.triggerNewSetupFunction(this.isDocument);
    } else {
      if (this.isPlanSaveV1 === true) {
        this.communicationService.triggerNewSetupPlanSaveFunction(
          this.isDocument
        );
      }
    }
    this.addNotes(1);
  }


  //Row Click Event UnProcessed Table
  handleTableCellClick(e: Event, notesData: any): void {
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }
  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, notesData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;
    if (tr) {
      const row = this.myTable.row(tr);
      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        row.child(this.format(row.data(), notesData)).show();
      }
    }
  }
  //Add Dynamic Notes Row in UnProcessed Table
  format(d: any, notesData: any) {
    let returnString: string = '';
    notesData?.forEach(function (value) {
      returnString += '<tr class="border-bottom"><td>' + value + '</td></tr>';
    });
    return returnString;
  }

  viewFaxDocument(){
    const url = '/#/patient_preview_tiff_document/' + this.faxId + '/' + this.docID;
        window.open(url, '_blank');
  }
  
 isEmpty(value: any): boolean {
  return (
    value === null ||
    value === undefined ||
    value === '' ||
    value === defaultGuid
  );
}
  // onInputMouseDown(event: any) {

  //   event.target.focus();
  // }
  // gpatientId: string, gDocumentId: string, sComments: string, gPatientChecklistId: string
}

export const dtInboundOptions: any = {
  responsive: true,
  searching: false, // Set to false to remove the search bar
  lengthChange: false, // Set to false to remove the page length menu
  paging: false,
  info: false,
  // pageLength: 10,  // You can set the default number of entries per page here
  // columnDefs: [{ targets: [0, 1, 2, 3, 8, 9], orderable: false }],
  columns: [
    // { orderable: false }, // Column 0
    { orderable: false }, // Column 1
    { orderable: false }, // Column 2
    { orderable: false }, // Column 3
    { orderable: false }, // Column 4
    { orderable: false }, // Column 5
    { orderable: false }, // Column 6
    { orderable: false }, // Column 7
    { orderable: false }, // Column 8
    { orderable: false }, // Column 9
    // Add more entries for other columns if needed
  ],
};
