import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { MasterNotesService } from '../order-proxy/order-management/inventory';
import { NoteType } from '../order-proxy/order-management/inventory/dto';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { SupplyArchiveItemsDTO } from '../order-proxy/order-management/order-optimization/dto/models';
import { MmOrderService } from '../order-proxy/order-management/order-optimization/mm-order.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-resupply-inactive-notes',
  templateUrl: './resupply-inactive-notes.component.html',
  styleUrls: ['./resupply-inactive-notes.component.scss']
})
export class ResupplyInactiveNotesComponent implements OnInit {
  notesForm: FormGroup;
  isNoteLoading: boolean = false;
  NoteTypeControl = new FormControl();
  drpNotetype: any;
  listNoteType: any;
  notesText = '';
  isShowSpinner: boolean = false;
  defaultNotetypeId: string;
  defaultNotetype: string;
  minDate: Date;
  patientId: string;
  orderId: string;

  @Output() isLoadTbl = new EventEmitter<boolean>();
  @Output() isInactiveLoadTbl = new EventEmitter<boolean>();
  constructor(
    private fb: FormBuilder,
    private notesService: MasterNotesService,
    private mmOrderService: MmOrderService,
    private toastr: ToastrService,
    public notesDialogRef: MatDialogRef<ResupplyInactiveNotesComponent>,
    private communicationService : CommunicationService,
    private datepipe : DatePipe,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      headingTxt: string;
      noteType: NoteType;
      chartId: string;
      isNextCallDateshow?: boolean;
      orderId: string;
      patientId: string;
      currentMonth: boolean,
      enitreMonth: boolean,
      isCurrentSuplies: boolean,
      isActivePatient:boolean,
      isMovetoArchive:boolean,
      nextCallDate:string,
    } = {
        headingTxt: defaultGuid,
        noteType: NoteType.general,
        chartId: '',
        isNextCallDateshow: false,
        orderId: defaultGuid,
        patientId: defaultGuid,
        currentMonth: true,
        enitreMonth: true,
        isCurrentSuplies: true,
        isActivePatient: false,
        isMovetoArchive: false,
        nextCallDate:'',
      },
  ) {

  }

  ngOnInit() {

    this.notesForm = this.fb.group({
      drpNotetype: new FormControl('', [Validators.required]),
      txtNoteType: new FormControl(''),
      chkIsCallNote: new FormControl('', [Validators.required]),
      txtDescription: new FormControl('', [Validators.required, noWhitespaceValidator]),
      txtNextCalldate: new FormControl('', [Validators.required]),
    });

    this.noteTypeDetails();
    //Note Type
    this.NoteTypeControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpNotetype = this.listNoteType.filter((dx) =>
        dx.noteType.toLowerCase().includes(FilterText)
      );
    });
    this.minDate = new Date();
    // this.minDate=currentDate.getDate() + 1
    this.minDate.setDate(this.minDate.getDate() + 1);
    if (this.data.isNextCallDateshow !== true) {
      const primaryInsurance = this.notesForm.get('txtNextCalldate');
      primaryInsurance.clearValidators();
      primaryInsurance.updateValueAndValidity();
    }
    if (this.data) {
      this.patientId = this.data?.patientId;
      this.orderId = this.data?.orderId;
    }
  }
  //GET Note Type Drop Down
  noteTypeDetails() {
    this.isNoteLoading = true;
    const NoteTypeDrp = this.notesService
      .getDrpNoteTypes()
      .subscribe(
        (response) => {
          this.isNoteLoading = false;
          this.listNoteType = response;
          this.drpNotetype = this.listNoteType;
          this.defaultNotetype = this.listNoteType.filter(x => x.noteType == this.data.noteType)[0]?.noteType;
          this.defaultNotetypeId = this.listNoteType.filter(x => x.noteType == this.data.noteType)[0]?.id;
          this.notesForm.patchValue({
            drpNotetype: this.defaultNotetypeId + '/' + this.defaultNotetype
          })
        },
        (err) => {
          this.isNoteLoading = false;
          // const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
  }
  //Update Character Count for TextArea
  updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > maxlength) {
      this.notesText = this.notesText?.substr(0, maxlength);
    }
  }

  SaveActiveNote() {
    this.isShowSpinner = true;

    const vnextCallDate = this.notesForm.value.txtNextCalldate || this.data?.nextCallDate;
    const formattedDate = this.datepipe.transform(vnextCallDate, 'yyyy-MM-dd');

   if(this.data.isMovetoArchive===true || this.data.isActivePatient==true){
    const activeInactiveNotes = {
      patientID: this.patientId,
      // orderId: this.orderId,
      isCallNote: this.notesForm.value.chkIsCallNote,
      // noteType: this.notesForm.value.drpNotetype.split('/')[1],
      notesTypeId: this.notesForm.value.drpNotetype.split('/')[0],
      description: this.notesForm.value.txtDescription,
      // inactivationType: this.data?.currentMonth == true ? 1 : 0,
      isPatientActive: this.data?.isMovetoArchive === true ? false : true,
      // nextCallDate: this.notesForm.value.txtNextCalldate || new Date(),
      nextCallDate: formattedDate || new Date(),
    };
    this.mmOrderService.patientActiveStatusNotes(activeInactiveNotes).subscribe(
      (response) => {
        this.isShowSpinner = false;
        this.toastr.success('Updated Successfully!', 'Success');
        this.communicationService.triggerResupplyEvent(2);
        this.notesDialogRef.close();
      },
      (err) => {

        const data: HttpErrorResponse = err;
        if(data.status ===200){
          this.isShowSpinner = false;
          this.toastr.success('Updated Successfully!', 'Success');
          this.communicationService.triggerResupplyEvent(2);
          this.notesDialogRef.close();
        }else{
          this.isShowSpinner = false;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
      });
   }else
  {
    const selectedDate = this.notesForm.value.txtNextCalldate || new Date();
    const selectedDateV1 = this.datepipe.transform(selectedDate,'yyyy-MM-dd');
    const activeNotesInput: SupplyArchiveItemsDTO = {
      patientId: this.patientId,
      orderId: this.orderId,
      isCallNote: this.notesForm.value.chkIsCallNote,
      noteType: this.notesForm.value.drpNotetype.split('/')[1],
      noteTypeId: this.notesForm.value.drpNotetype.split('/')[0],
      description: this.notesForm.value.txtDescription,
      inactivationType: this.data?.currentMonth == true ? 1 : 0,
      nextCallDate: selectedDateV1,
      // nextCallDate: this.notesForm.value.txtNextCalldate || new Date(),
    };
    if (this.data?.isCurrentSuplies == false) {
    this.mmOrderService.updateInactivateSuppliesByInput(activeNotesInput).subscribe(
      (response) => {
        this.isShowSpinner = false;
        this.toastr.success('Supplies Activeted Successfully!', 'Success');
        this.communicationService.triggerResupplyEvent(1);
        this.notesDialogRef.close();
      },
      (err) => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }else{
      this.mmOrderService.supplyArchiveItemsv1ByInput(activeNotesInput).subscribe(
        (response) => {
          this.isShowSpinner = false;
          this.toastr.success('Supplies Inactiveted Successfully!', 'Success');
          this.communicationService.triggerResupplyEvent(2);
          this.notesDialogRef.close();
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
    }
  }
  }
  resetNotes() {
    this.notesForm.reset({
      drpNotetype: this.defaultNotetypeId + '/' + this.defaultNotetype,
      txtNoteType: '',
      chkIsCallNote: null,
      txtDescription: '',
      txtNextCalldate: ''
    });
  }
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

}
