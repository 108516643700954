import { DatePipe } from '@angular/common';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { BillingDenialService } from '../../billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/billing-denial.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlossaryDetailsService } from '../../claim-proxy/claim-processing-management/rcm/billing-management/optimization/claim/glossary-details.service';
import { GlossaryDTO } from '../../claim-proxy/claim-processing-management/rcm/billing-management/optimization/dto/models';
import { HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { BillingDenialSearchDTO } from '../../billing-proxy/billing-management/rcm/billing-management/optimization/dto/models';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClaimViewTabComponent } from '../../claim-view-tab/claim-view-tab.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-billing-denials',
  templateUrl: './billing-denials.component.html',
  styleUrls: ['./billing-denials.component.scss'],
})
export class BillingDenialsComponent implements OnInit {
  denialSearchForm: FormGroup;
  isLoading: boolean = true;
  defaultGuid = defaultGuid;
  DataList: any[];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  invoiceTable: DataTables.Api;
  billingDenialsTable: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    initComplete: () => {
      this.expandTableNotes();
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    // columnDefs: [{ targets: 1, orderable: false }],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Denials List',
        exportOptions: {
          columns: ':not(:first-child)', // Skip the first column
        },
      },
    ],
  };
  drpPayorId: any;
  payerId: string = defaultGuid;
  loadPayerDropDownAPICall: Subscription;
  loadGlossaryDropDownAPICall: Subscription;
  loadDenialsTableAPICall: Subscription;
  public filteredPrimaryPayors: Observable<string[]> | undefined;
  isdrpLoading: boolean;
  selectPayorList: any;
  drpDenialId: any;
  isloadingGlossaryDropDownData: boolean = false;
  filteredreasonCode: Observable<GlossaryDTO[]>;
  lstReasonCode: any;
  @ViewChild('denialSelect') denialSelect: MatSelect;
  private subscription: Subscription;
  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private billingDenialService: BillingDenialService,
    private glossaryDetailsService: GlossaryDetailsService,
    // private loaderService: LoaderService,
    private commonService: CommonService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    const { firstDate, lastDate } = this.getFirstAndLastDatesOfCurrentMonth();

    this.billingDenialsTable.buttons[0].filename =
      'Denials List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.initializeForms();
    this.denialSearchForm.patchValue({
      txtDOSFromDate: firstDate,
      txtDOSEndDate: lastDate
    });
    this.loadGlossaryDropDown();
    this.loadPayerDropDown('');
    this.getTableData();
  }
  ngOnDestroy(): void {
    if (this.loadPayerDropDownAPICall) {
      this.loadPayerDropDownAPICall.unsubscribe();
    }
    if (this.loadGlossaryDropDownAPICall) {
      this.loadGlossaryDropDownAPICall.unsubscribe();
    }
    if (this.loadDenialsTableAPICall) {
      this.loadDenialsTableAPICall.unsubscribe();
    }
    // Clean up the event listener if the component is destroyed
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    document.removeEventListener('keydown', this.handleKeydown);
  }

  ngAfterViewInit() {
    // Listen to when the dropdown is opened or closed
    this.subscription = this.denialSelect.openedChange.subscribe(opened => {
      if (opened) {
        // Add a keydown listener to the entire document when the dropdown is open
        document.addEventListener('keydown', this.handleKeydown);
      } else {
        // Remove the keydown listener when the dropdown is closed
        document.removeEventListener('keydown', this.handleKeydown);
      }
    });
  }

  handleKeydown = (event: KeyboardEvent) => {
    // Prevent Ctrl + A only if the dropdown is open
    if (event.ctrlKey && event.key === 'a') {
      event.preventDefault(); // Prevent the default Ctrl+A (Select All) action
      
    }
  }
  // ngAfterViewInit() {
  //   // Listen for when the dropdown panel opens
  //   this.denialSelect.openedChange.subscribe(opened => {
  //     if (opened) {
  //       const panel = this.denialSelect.panel.nativeElement;

  //       // Add keydown listener to the panel
  //       panel.addEventListener('keydown', (event: KeyboardEvent) => {
  //         console.log('ngAfterViewInit');
  //         console.log('event');
  //         console.log(event);
  //         this.handleKeydown(event);
  //       });
  //     }
  //   });
  // }

  // handleKeyboardEvent(event: KeyboardEvent) {
  //   // Check if Ctrl + A is pressed

  //   if (event.ctrlKey && event.key === 'a') {
  //     event.preventDefault();  // Prevent the default Ctrl+A action (select all)
  //     console.log('ctl+a - not allowed');
  //   }else{
  //     console.log('not ctl+a');
  //   }
  // }

  // handleKeyboardEvent(event: KeyboardEvent) {
  //   // Block Ctrl + A combination
  //   if (event.ctrlKey && event.key === 'a') {
  //     event.preventDefault();  // Prevent default Ctrl+A (select all) action
  //     console.log('Ctrl+A has been blocked.');
  //   }else{
  //         console.log('not ctl+a');
  //       }
  // }


  // handleKeydown(event: KeyboardEvent) {
  //   // Disable Ctrl + A (Select All)
  //   if (event.ctrlKey && event.key === 'a') {
  //     event.preventDefault();
  //     console.log('Ctrl+A has been disabled for this dropdown.');
  //   }else{
  //     console.log('not ctl+a');
  //   }
  // }

  // // Function to handle keydown events in the dropdown
  // handleKeydown(event: KeyboardEvent) {
  //   // Check if Ctrl + A is pressed
  //   console.log('Check if Ctrl + A is pressed ?')
  //   console.log(event)

  //   if (event.ctrlKey && event.key === 'a') {
  //     event.preventDefault();  // Prevent the default Ctrl + A behavior
  //     console.log('Ctrl+A has been disabled for this dropdown.');
  //     return false;
  //   }
  // }


  initializeForms() {
    this.denialSearchForm = this.fb.group({
      drpDenial: new FormControl(''),
      txtDenialFilter: new FormControl(''),
      txtCreatedFromDate: new FormControl(''),
      txtCreatedEndDate: new FormControl(''),
      drpPayer: new FormControl(''),
      txtPayerFilter: new FormControl(''),
      drpStatus: new FormControl(''),
      txtStatusFilter: new FormControl(''),
      txtDOSFromDate: new FormControl(''),
      txtDOSEndDate: new FormControl(''),
    });
  }

     // Function to get the first and last dates of the current month
     getFirstAndLastDatesOfCurrentMonth(): { firstDate: Date; lastDate: Date } {
      const today = new Date();
      const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      return { firstDate, lastDate };
    }


  // openClaimForm(
  //   orderId: string,
  //   patientId: string,
  //   chartNo: string,
  //   patientName: string,
  //   defaultSaleOrderId: string,
  //   claimId: string,
  //   isClaimBatch: boolean,
  //   isResubmit?: boolean
  // ) {
  //   const dialogRef = this.dialog.open(ClaimViewTabComponent, {
  //     disableClose: true,
  //     data: {
  //       orderId: orderId,
  //       patientId: patientId,
  //       defaultSaleOrderId: defaultSaleOrderId,
  //       claimId: claimId,
  //       isClaimBatch: isClaimBatch,
  //       fullName: patientName,
  //       chartNo: chartNo,
  //       isResubmit: isResubmit ?? false,
  //     },
  //   });

  //   // const closeDialog = dialogRef.afterClosed().subscribe(
  //   //   (claimDetails: any) => {
  //   //     if (isResubmit) {
  //   //       this.communicationService.functionLoadClaimStatusList();
  //   //     }
  //   //     // console.log('claimDetails')
  //   //     // console.log(claimDetails)
  //   //     // // && item.policyId === claimDetails.insurancePlanName
  //   //     // const existingItemIndex = this.lstClaimProcessList.findIndex(
  //   //     //   (item) => item.mmOrderId === claimDetails.orderId
  //   //     // );
  //   //     // if (existingItemIndex !== -1) {
  //   //     //   // Item with the flag already exists, update it
  //   //     //   this.lstClaimProcessList[existingItemIndex].claimId=claimDetails.id;
  //   //     //   this.lstClaimProcessList[existingItemIndex].status='No Pending Data';
  //   //     //   this.lstClaimProcessList[existingItemIndex].selected=true;
  //   //     //   let x = this.lstClaimProcessList.filter((dx) =>
  //   //     //     dx.selected === true
  //   //     //   );
  //   //     //   this.totalSelectedOrders++;
  //   //     //   this.claimIdArray.push(claimDetails.id)
  //   //     //   this.countsUpdate.emit({ claimId : claimDetails.id,status:true,total:null});
  //   //     // }
  //   //   },
  //   //   (err) => {
  //   //     // const data: HttpErrorResponse = err;
  //   //     // console.warn(data?.error?.error?.message)
  //   //     // Swal.fire({
  //   //     //   icon: 'info',
  //   //     //   text: data?.error?.error?.message,
  //   //     // });
  //   //   }
  //   // );

  //   // dialogRef.afterClosed().subscribe(() => {
  //   //   // this.loadClaimProcessList()

  //   //   //  this.lstClaimProcessList.forEach(x => {
  //   //   //  if(x.claimId != defaultGuid){
  //   //   //   this.claimIdArray.push(x.claimId)
  //   //   //   x.selected = true

  //   //   //  }
  //   //   // })

  //   // });
  // }


  openClaimForm(
    orderId: string,
    patientId: string,
    defaultSaleOrderId: string,
    claimId: string,
    chartNo: string,
    fullName: string
  ) {
    const dialogRef = this.dialog.open(ClaimViewTabComponent, {
      // minwidth: '65%',
      // minheight: '60%',
      disableClose: true,

      data: {
        orderId: orderId,
        patientId: patientId,
        defaultSaleOrderId: defaultSaleOrderId,
        claimId: claimId,
        chartNo: chartNo,
        fullName: fullName,
        isClaimBatch: true,
      },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      // (claimDetails: any) => {
      //   // && item.policyId === claimDetails.insurancePlanName
      //   const existingItemIndex = this.lstClaimProcessList.findIndex(
      //     (item) => item.mmOrderId === claimDetails.orderId
      //   );
      //   this.allCheckboxdisabled = false;
      //   if (existingItemIndex !== -1) {
      //     // Item with the flag already exists, update it
      //     this.lstClaimProcessList[existingItemIndex].claimId = claimDetails.id;
      //     this.lstClaimProcessList[existingItemIndex].status =
      //       'No Pending Data';
      //     // this.lstClaimProcessList[existingItemIndex].selected=true;

      //     let x = this.lstClaimProcessList.filter((dx) => dx.selected === true);
      //     this.totalSelectedOrders++;
      //     this.claimIdArray.push(claimDetails.id);
      //     this.countsUpdate.emit({
      //       claimId: claimDetails.id,
      //       status: true,
      //       total: null,
      //     });
      //     this.loadtable.emit();
      //   }
      // },
      // (err) => {
      //   // const data: HttpErrorResponse = err;
      //   // console.warn(data?.error?.error?.message)
      //   // Swal.fire({
      //   //   icon: 'info',
      //   //   text: data?.error?.error?.message,
      //   // });
      // }
    );

    // dialogRef.afterClosed().subscribe(() => {
    //   // this.loadClaimProcessList()

    //   //  this.lstClaimProcessList.forEach(x => {
    //   //  if(x.claimId != defaultGuid){
    //   //   this.claimIdArray.push(x.claimId)
    //   //   x.selected = true

    //   //  }
    //   // })

    // });
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this._snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  drpDownMessage() {
    if (this.isdrpLoading === false) {
      return 'No Matches found';
    } else {
      return 'Loading...';
    }
  }

  expandTableNotes(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.rows().every((rowIdx, tableLoop, rowLoop) => {
        const row = dtInstance.row(rowIdx);
        const rowData = row.data();
        let denialNote = this.DataList.find(
          (a) => a.paymentId == rowData[0]
        ).glossaryDescription;
        if (denialNote != null && denialNote != '') {
          row.child(this.format(denialNote)).show();
        }
      });
    });
  }

  format(data: any) {
    let returnString: string = '';
    returnString += ' <p class="mb-0 italic">' + data + '</p>';
    return returnString;
  }
  clearAll(){
    const { firstDate, lastDate } = this.getFirstAndLastDatesOfCurrentMonth();
    this.denialSearchForm.reset();
    this.denialSearchForm.patchValue({
      txtDOSFromDate: firstDate,
      txtDOSEndDate: lastDate
    });
    this.getTableData();
  }

  clearStatus() {
    this.denialSearchForm.patchValue({
      drpStatus: null,
    });
    this.getTableData();
  }
  clearCreatedDate() {
    this.denialSearchForm.patchValue({
      txtCreatedFromDate: null,
      txtCreatedEndDate: null,
    });
    // setTimeout(() => this.getTableData(),500);
    this.getTableData();
  }
  focusOutCreated(){

     let txtCreatedFromDate = this.datepipe.transform(
      this.denialSearchForm.value.txtCreatedFromDate || null,
      'yyyy-MM-dd'
    );
    if(txtCreatedFromDate !== this.txtCreatedFromDate) {
      this.getTableData();
    }
  }
  focusOutDOS(){
    let txtDOSFromDate = this.datepipe.transform(
      this.denialSearchForm.value.txtDOSFromDate || null,
      'yyyy-MM-dd'
    );
    if(txtDOSFromDate !== this.txtDOSFromDate) {
      this.getTableData();
    }
  }
  clearPayer() {
    this.denialSearchForm.patchValue({
      drpPayer: null,
    });
    this.getTableData();
  }
  clearDenial() {
    this.denialSearchForm.patchValue({
      drpDenial: null,
    });
    this.getTableData();
  }
  clearDOS() {
    this.denialSearchForm.patchValue({
      txtDOSFromDate: null,
      txtDOSEndDate: null,
    });
    setTimeout(() => this.getTableData(),500);
  }

  isPayerDropdownOpen = false;
  onPayerDropdownOpenChange(isOpen: boolean) {
    this.isPayerDropdownOpen = isOpen;
    if (this.isPayerDropdownOpen) {
      // this.invoiceSearchForm.value.txtInvoiceFilter || null
      this.loadPayerDropDown(
        ''
      );
    }
  }



  onSelectPrimaryPayor(event: any) {
    this.getTableData();
    // const payorList = event.value;
    // let splitValue = payorList.split('/');
    // this.primaryPayorId = splitValue[0];
    // this.checkPayorUniqness(this.primaryPayorId);
  }

  onSelectDenial(event: any) {
    // const denialList = event.value;
    this.getTableData();
    // let splitValue = payorList.split('/');
    // this.primaryPayorId = splitValue[0];
    // this.checkPayorUniqness(this.primaryPayorId);
  }
  oldPayer : string;
  loadPayerDropDown(value: string, eventKey?: string) {
    if (
      eventKey !== 'ArrowDown' &&
      eventKey !== 'ArrowUp' &&
      eventKey !== 'ArrowRight' &&
      eventKey !== 'ArrowLeft'
      // &&
      // value == ''
      // && value != this.oldPayer
    ) {
      this.oldPayer=value;
      //Get Payer list
      this.isdrpLoading = true;
      if (this.loadPayerDropDownAPICall) {
        this.loadPayerDropDownAPICall.unsubscribe();
      }
      let eligibilityMode = 4;

      // iMode: number, sSearch: string, gPayor: string, ExistingPayors: string[]

      this.loadPayerDropDownAPICall = this.billingDenialService
        .getdrpPayerByIModeAndSSearchAndGPayorAndExistingPayors(
          eligibilityMode,
          value,
          this.payerId,
          this.denialSearchForm.value.drpPayer ? this.denialSearchForm.value.drpPayer : []
        )
        .subscribe((response) => {
          this.selectPayorList = response;
          this.isdrpLoading = false;
          this.selectPayorList = response.map((x) => {
            x['payerShortCodeList'] =
              (x?.payerCode === null ||
              x?.payerCode === '' ||
              (x?.payerCode).toLowerCase() == 'null'
                ? '-'
                : x?.payerCode) +
              ' ' +
              '/' +
              ' ' +
              x?.payerName +
              ' ' +
              '/' +
              ' ' +
              (x?.code === null ||
              x?.code === '' ||
              (x?.code).toLowerCase() == 'null'
                ? '-'
                : x?.code);
            return x;
          });

          this.filteredPrimaryPayors = this.denialSearchForm
            .get('txtPayerFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.selectPayorList?.filter((option) =>
                  option?.payerShortCodeList
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        });
    }else{
    }
  }

  loadGlossaryDropDown() {
    this.isloadingGlossaryDropDownData = true;
    try {
      if (this.loadGlossaryDropDownAPICall) {
        this.loadGlossaryDropDownAPICall.unsubscribe();
      }
      this.loadGlossaryDropDownAPICall = this.glossaryDetailsService
        .drpGlossary()
        .subscribe(
          (response) => {
            if (response.items.length > 0) {
              this.lstReasonCode = response?.items;
              this.filteredreasonCode = this.denialSearchForm
                .get('txtDenialFilter')
                .valueChanges.pipe(
                  startWith(''),
                  map((value) =>
                    this.lstReasonCode?.filter((option) =>
                      option?.glossaryCode
                        ?.toLowerCase()
                        ?.includes(value?.toLowerCase() ?? '')
                    )
                  )
                );
            }

            setTimeout(() => {
              this.isloadingGlossaryDropDownData = false;
            }, 500);
          },
          (error) => {
            console.error('Error:', error);
            setTimeout(() => {
              this.isloadingGlossaryDropDownData = false;
            }, 500);
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isloadingGlossaryDropDownData = false;
      }, 500);
    } finally {
    }
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }
txtCreatedFromDate : any;
txtDOSFromDate : any;

processString(input: string): string {
  const parts = input.split('/');

  // If the string was split, remove the first part and return the remaining
  if (parts.length > 1) {
    return parts.slice(1).join(', ').trim();
  }

  // If no split happened, return the input as is
  return input.trim();
}


  getTableData() {
    this.txtCreatedFromDate = this.datepipe.transform(
      this.denialSearchForm.value.txtCreatedFromDate || null,
      'yyyy-MM-dd'
    );
    let txtCreatedEndDate = this.datepipe.transform(
      this.denialSearchForm.value.txtCreatedEndDate || null,
      'yyyy-MM-dd'
    );
    this.txtDOSFromDate = this.datepipe.transform(
      this.denialSearchForm.value.txtDOSFromDate || null,
      'yyyy-MM-dd'
    );
    let txtDOSEndDate = this.datepipe.transform(
      this.denialSearchForm.value.txtDOSEndDate || null,
      'yyyy-MM-dd'
    );

    if (
      ((this.txtCreatedFromDate &&
        txtCreatedEndDate &&
        this.txtCreatedFromDate <= txtCreatedEndDate) ||
        (!this.txtCreatedFromDate && !txtCreatedEndDate)) &&
      ((this.txtDOSFromDate && txtDOSEndDate && this.txtDOSFromDate <= txtDOSEndDate) ||
        (!this.txtDOSFromDate && !txtDOSEndDate))
    ) {
      this.isLoading = true;
      try {
        if (this.loadDenialsTableAPICall) {
          this.loadDenialsTableAPICall.unsubscribe();
        }


        // reasonCode?: string;
        // glossaryId: string[];
        // dtFromDate?: string;
        // dtToDate?: string;
        // dtDosFromDate?: string;
        // dtDosToDate?: string;
        // payerName?: string;
        // status?: string;
        // payerIds: string[];


        let input: BillingDenialSearchDTO = {
          glossaryId: this.denialSearchForm.value.drpDenial ? this.denialSearchForm.value.drpDenial : [] ,
          dtFromDate: this.txtCreatedFromDate,
          dtToDate: txtCreatedEndDate,
          dtDosFromDate: this.txtDOSFromDate,
          dtDosToDate: txtDOSEndDate,
          // status: this.denialSearchForm.value.drpStatus || null,
          payerIds: this.denialSearchForm.value.drpPayer ? this.denialSearchForm.value.drpPayer : []
        };
        this.loadDenialsTableAPICall = this.billingDenialService
          .getDenialReportsByInput(input)
          .subscribe(
            (response) => {
              this.DataList = response;
              this.DataList = this.DataList.map((billing) => {
                return {
                  ...billing,
                  dosDate: this.commonService.getFormattedDateZone(
                    billing.dtDos
                  ),
                  dob: this.commonService.getFormattedDateZone(billing.dob),
                  creationTime: this.commonService.getFormattedDateTimeZone(
                    billing.creationTime
                  ),
                  hcpcCode : this.processString(billing.hcpcCode),
                  status: 'Paid',
                  statusFgColor: '#fff',
                  statusBgColor: '#16aeff',
                };
              });
              // this.tblShpSelectedRow(
              //   this.DataList?.[0]?.rentalId,
              //   this.DataList?.[0]
              // );
              setTimeout(() => {
                this.isLoading = false;
              }, 500);
            },
            (err) => {
              this.DataList = [];
              const data: HttpErrorResponse = err;
              console.error('Unexpected error:', data?.error?.error?.message);
              setTimeout(() => {
                this.isLoading = false;
              }, 500);
            }
          );
        // this.tableData$ = getTableData;
      } catch (error) {
        console.error('Unexpected error:', error);
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      } finally {
      }
    } else {
      this._snackBar.open(
        'Select Start / End Date!',
        'Close',
        {
          duration: 2000,
        }
      );
      this.isLoading = false;
    }
  }
}
