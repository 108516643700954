import { FlatTreeControl } from '@angular/cdk/tree';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import {
  customErrorMessage,
  defaultGuid,
} from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { DiagnosisCode10Service, PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import {
  BranchOfficeDTO,
  DiagnosisCode10DTO,
  DocumentTypeDTO,
  GenderDTO,
  MachineTypeDTO,
  ReviewReasonDTO,
  ReviewStatusDTO,
  SortingTypeDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientDocumentService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import {
  DocumentGroupDTO,
  PatientDocumentBlobDTO,
  UpdateDocumentBlobDTO,
  CreateUpdateMedicareDocDTO,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import {
  ComplainceDTO,
  DemographicsDTO,
  DiagnosticSleepStudyDTO,
  HomeSleepTestDTO,
  InitialFaceToFaceDTO,
  PatientDTO,
  PatientDocumentDTO,
  PatientsDTO,
  PrescriptionDTO,
  SavePatientDocumentBlobDTO,
  SleepStudyDTO,
  TitrationSleepStudyDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto';
import { ToastrService } from 'ngx-toastr';
import {
  DocumentMaxandTypeLimits,
  DocumentUploadTypes,
} from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { combineLatest, defer, from, Observable, of, Subscription } from 'rxjs';
import {
  concatMap,
  groupBy,
  map,
  mergeMap,
  reduce,
  startWith,
  takeLast,
  tap,
  toArray,
} from 'rxjs/operators';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PatientDoctorComponent } from 'projects/patient/src/app/patient-doctor/patient-doctor.component';
import { MatDialog } from '@angular/material/dialog';
import { DoctorService } from 'projects/patient/src/app/patient-proxy/patient';
import { doctorInterface } from 'projects/billing/src/app/billing-claim-info/billing-claim-info.component';
import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { MatSelect } from '@angular/material/select';
import { NumberValidators } from '../../validations/number-validator';
import { TextValidators } from '../../validations/text-validator';
import { baseUrl } from 'projects/patient/src/environments/environment';
import { PatientIcd10Component } from 'projects/patient/src/app/patient-icd10/patient-icd10.component';
import { planInterface } from 'projects/patient/src/app/patient-add-policy/patient-add-policy.component';
import {
  AuthCategoryDTO,
  AuthVisitsDTO,
  TypeOfPlanDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order';
import { SortedData } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/upload-file/dto';
import { DXTypes } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/dxtypes.enum';
import { SetupTypes } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/setup-types.enum';
import { SortedDataDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/file-upload/dto';
import { MasterRulesDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/tenants/dto';
import { ReminderService } from 'projects/admin/src/app/dashboard/reminder.service';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { MasterRulesService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/tenant';

let listOfDocuments: PatientDocumentBlobDTO[] = [];
@Component({
  selector: 'app-patient-common-document-tab',
  templateUrl: './patient-common-document-tab.component.html',
  styleUrls: ['./patient-common-document-tab.component.scss'],
  providers: [ListService],
})
export class PatientCommonDocumentTabComponent implements OnInit, OnDestroy {
  //#region Declarations
  @Output() saveDocumentFormState: EventEmitter<any> = new EventEmitter<any>();
  @Output() patientIdEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> =
    new EventEmitter<string>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatAccordion, { static: false }) accordion: MatAccordion;

  @ViewChild(MatSort, { static: false }) mergeSort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) mergePaginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  panelOpenState: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  mergeDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  arrUnsortedDocumentList: PatientDocumentBlobDTO[] = [];
  arrMergeDocumentList: PatientDocumentBlobDTO[] = [];
  arrSortedDocumentList: DocumentListType[] = [];
  drpDocumentTypeLoop: DocumentTypeDTO[] = [];
  drpReviewStatusLoop: ReviewStatusDTO[] = [];
  documentGroupedByType: Partial<DocumentGroupIngListType>[] = [];
  documentGroup: DocumentGroupDTO[] = [];
  drpReviewReasonLoop: ReviewReasonDTO[] = [];
  drpBranchLoop: BranchOfficeDTO[] = [];
  drpDocumentUploadType: SortingTypeDTO[] = [];
  drpIsActiveStatus: any[] = [];
  subscription$: Subscription[] = [];
  @Input() patientId: string = defaultGuid;
  isShowProcessBar: boolean = false;
  myDate = new Date();
  isNewPatient: any;
  tenantId: string = defaultGuid;
  @Input() viewDocViewer: boolean = true;
  @Input() editPatientValue: any;
  documentTypes: string[] = [
    'ALL',
    'FACE TO FACE',
    'RX',
    'SLEEP STUDY REPORT',
    'INSURANCE VERIFICATION',
    'COMPLIANCE REPORT',
    'CORRESPONDENCE',
    'DEMOGRAPHICS',
    'OTHERS',
  ];
  pdfHeight: string = '100vh';
  displayedColumns: string[] = [
  
    'documentType',
    'fileName',
    'documentStartDate',
    'documentEndDate',
    'documentStatus',
    'options',

  ];
  document: string;
  documentForm: FormGroup;
  documentSearchForm: FormGroup;
  show: boolean = false;
  isEditBtn: boolean = false;
  showErrorForFileLength: boolean = false;
  showErrorForFileType: boolean;
  showExtensionInfo: string = '';
  showErrorForExtension: boolean = false;
  isDocumentForButtonDisable: boolean = false;
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';
  filesname: string[] = [];
  intCheckedDocuments: number = 0;
  isDocumentEditSaveButtonHidden: boolean = true;
  @Input() strPdfSource: string = '';
  isShowSpinner: boolean = false;
  strSelectedPdfPath: string = '';
  selectedDocumentforUpdate: Partial<PatientDocumentDTO> = null;
  patientDocumentId: string = '';
  parentPatientDocumentID: string = '';
  activeNode: any;
  PreviewExtractionForm: FormGroup;
  gender: string = '';
  drpGenderLoop: GenderDTO[];
  ruleColor: {
    ctrlName1?: string;
    errValue1?: string;
    erStatus1?: boolean;
    ctrlName2?: string;
    errValue2?: string;
    erStatus2?: boolean;
    ctrlName3?: string;
    errValue3?: string;
    erStatus3?: boolean;
    ctrlName4?: string;
    errValue4?: string;
    erStatus4?: boolean;
    ctrlName5?: string;
    errValue5?: string;
    erStatus5?: boolean;
    ctrlName6?: string;
    errValue6?: string;
    erStatus6?: boolean;
    ctrlName7?: string;
    errValue7?: string;
    erStatus7?: boolean;
    ctrlName8?: string;
    errValue8?: string;
    erStatus8?: boolean;
    ctrlName9?: string;
    errValue9?: string;
    erStatus9?: boolean;
    ctrlName10?: string;
    errValue10?: string;
    erStatus10?: boolean;
    ctrlName11?: string;
    errValue11?: string;
    erStatus11?: boolean;
    ctrlName12?: string;
    errValue12?: string;
    erStatus12?: boolean;
    ctrlName13?: string;
    errValue13?: string;
    erStatus13?: boolean;
    ctrlName14?: string;
    errValue14?: string;
    erStatus14?: boolean;
    ctrlName15?: string;
    errValue15?: string;
    erStatus15?: boolean;
    ctrlName16?: string;
    errValue16?: string;
    erStatus16?: boolean;
    ctrlName17?: string;
    errValue17?: string;
    erStatus17?: boolean;
    ctrlName18?: string;
    errValue18?: string;
    erStatus18?: boolean;
    ctrlName19?: string;
    errValue19?: string;
    erStatus19?: boolean;
    ctrlName20?: string;
    errValue20?: string;
    erStatus20?: boolean;
    ctrlName21?: string;
    errValue21?: string;
    erStatus21?: boolean;
    ctrlName22?: string;
    errValue22?: string;
    erStatus22?: boolean;
    ctrlName23?: string;
    errValue23?: string;
    erStatus23?: boolean;
    ctrlName24?: string;
    errValue24?: string;
    erStatus24?: boolean;
    ctrlName25?: string;
    errValue25?: string;
    erStatus25?: boolean;
    ctrlName26?: string;
    errValue26?: string;
    erStatus26?: boolean;
    ctrlName27?: string;
    errValue27?: string;
    erStatus27?: boolean;
    ctrlName28?: string;
    errValue28?: string;
    erStatus28?: boolean;
    ctrlName29?: string;
    errValue29?: string;
    erStatus29?: boolean;
    ctrlName30?: string;
    errValue30?: string;
    erStatus30?: boolean;
    ctrlName31?: string;
    errValue31?: string;
    erStatus31?: boolean;
    ctrlName32?: string;
    errValue32?: string;
    erStatus32?: boolean;
    ctrlName33?: string;
    errValue33?: string;
    erStatus33?: boolean;
    ctrlName34?: string;
    errValue34?: string;
    erStatus34?: boolean;
    ctrlName35?: string;
    errValue35?: string;
    erStatus35?: boolean;
    ctrlName36?: string;
    errValue36?: string;
    erStatus36?: boolean;
    ctrlName37?: string;
    errValue37?: string;
    erStatus37?: boolean;
    ctrlName38?: string;
    errValue38?: string;
    erStatus38?: boolean;
    ctrlName39?: string;
    errValue39?: string;
    erStatus39?: boolean;
    ctrlName40?: string;
    errValue40?: string;
    erStatus40?: boolean;
    ctrlName41?: string;
    errValue41?: string;
    erStatus41?: boolean;
    ctrlName42?: string;
    errValue42?: string;
    erStatus42?: boolean;
    ctrlName43?: string;
    errValue43?: string;
    erStatus43?: boolean;
    ctrlName44?: string;
    errValue44?: string;
    erStatus44?: boolean;
    ctrlName45?: string;
    erStatus45?: boolean;
    errValue45?: string;
    ctrlName46?: string;
    erStatus46?: boolean;
    errValue46?: string;
    ctrlName47?: string;
    erStatus47?: boolean;
    errValue47?: string;
    ctrlName48?: string;
    erStatus48?: boolean;
    errValue48?: string;
    ctrlName49?: string;
    erStatus49?: boolean;
    errValue49?: string;
  } = null;
  drpDoctor: { name: string; id: string }[] = [];
  ClinicalForm: FormGroup;
  public filteredOrderingDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredMarRepDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredRenDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredMarDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredRefDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredPrimaryDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredPractionerTypes: Observable<doctorInterface[]> | undefined;
  OrderingDoctor: string = '';
  blobName: string = '';
  drpSymptoms: any[] = [];
  mainTree = [];
  childTree = [];
  currentKey: string = '';
  full_name: string = '';
  sfull_name = [];
  childDocType = [];
  drpDiagnosisICD10: {
    diagnosisCode10ShortCodeId: string;
    diagnosisCode: string;
    diagnosisCodeTypeData: string;
    diagnosisCodeTypeDataId: string;
    id: string;
    creationTime: string | Date;
    creatorId: string;
    isActive: number;
    isExist: number;
  }[];
  drpMachines: MachineTypeDTO[] = [];
  drpDXType: any[] = [];
  drpPrdSetupType: any[] = [];
  medicareUpdateForm: FormGroup;
  submitted: boolean;
  keysToExclude = [
    'all',
    'facetoface',
    'sleepstudyreport',
    'rx',
    'compliancereport',
  ];
  drpPayerId: { id: string; name: string; payerCode: string; payerName }[] = [];
  drpPlan: planInterface[] = [];
  drpAtuhPlan: TypeOfPlanDTO[] = [];
  drpCategory: AuthCategoryDTO[] = [];
  drpAuthVisit: AuthVisitsDTO[] = [];
  lstIndex: SortedData[] = [];
  fileId: string = '';
  ruleCheck: number = 1;
  compliancePendingValidationCount: number = 0;
  demographicsPendingValidationCount: number = 0;
  initialFaceToFacePendingValidationCount: number = 0;
  prescriptionPendingValidationCount: number = 0;
  sleepStudyPendingValidationCount: number = 0;
  verificationPendingValidationCount: number = 0;
  isCompSA: number = 0;
  percentageOfCompletion: number = 0;
  insuranceAfterSave: PatientsDTO;
  pageNumber: number = 0;
  strDocumentType: string = 'RX';
  expiryStatus: string = '';
  @Input() rulesValues: MasterRulesDTO[] = [];
  patientData: PatientDTO | null = null;
  selectedRules: string[] = [];
  hideFullNotificataion: boolean = true;
  faceToface: FormGroup;
  sleepStudyReport: FormGroup;
  rx: FormGroup;
  complianceReport: FormGroup;
  demographicsArr: DemographicsDTO[] = [];
  faceTofaceArr: InitialFaceToFaceDTO[] = [];
  sleepStudyArr: SleepStudyDTO[] = [];
  ComplienceArr: ComplainceDTO[] = [];
  priscriptionArr: PrescriptionDTO[] = [];
  hideNotificatiaonButton: boolean;
  public filtered1Icd10Types: Observable<any[]> | undefined;
  //#endregion Declarations

  //Constructor
  constructor(
    private patientDropdownService: PatientMasterDropdownService,
    private fb: FormBuilder, //To Build a Form
    private table: TableService,
    private dateValidator: DateValidator,
    private patientDocumentService: PatientDocumentService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private activateRoute: ActivatedRoute,
    public title: Title,
    private dialog: MatDialog,
    private doctorService: DoctorService,
    public list: ListService,
    private numberValidators: NumberValidators,
    private textValidators: TextValidators,
    private reminderService: ReminderService,
    // private patientService: PatientService,
    private diagnosis10: DiagnosisCode10Service,
    private PatientPersonalService: PatientPersonalService,
    private ruleMasterService: MasterRulesService,
  ) {}

  //page Load Function
  ngOnInit(): void {
    this.title.setTitle('Qsecure | Document');
    const activatedRoute = this.activateRoute.paramMap.subscribe((response) => {
      this.patientId = response.get('patientId');
      this.fileId = response?.get('fileId') ?? '';
    });
    this.ruleMasterService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      const rules: MasterRulesDTO[] = response?.items ?? [];
      this.rulesValues = rules ?? [];
      this.rulesValues && this.checkRulesForFeildHighlight(this.rulesValues, SetupTypes.New, DXTypes.OSA);
    })
    this.patientId = this.patientId ?? defaultGuid;
    this.documentGrouping();
    this.createDocumentForm();
    this.getDocumentGroup();
    this.pdfHeight = '93%';
    this.isNewPatient = this.cookieService.get('patientStatus');
    if (this.isNewPatient == 'false') {
      this.isEditBtn = true;
      this.documentForm.disable();
      // this.patientIdEmitter.emit();
    }
    this.getPatientIdFormPatientDetails(this.patientId);
    this.ruleMasterService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      const rules: MasterRulesDTO[] = response?.items ?? [];
      this.rulesValues = rules ?? [];
      this.rulesValues && this.checkRulesForFeildHighlight(this.rulesValues, SetupTypes.New, DXTypes.OSA);
    });

    this.medicareUpdateForm?.get('drpPrdSetupType').valueChanges.subscribe(response => {
      if (this.rulesValues && response) {
        const drpDXType: string = this.medicareUpdateForm?.get('drpDXType').value ?? "";
        const drpPrdSetupType: string = this.medicareUpdateForm?.get('drpPrdSetupType').value ?? "";
        this.rulesValues && this.checkRulesForFeildHighlight(this.rulesValues, drpPrdSetupType, drpDXType);
      }
    })
    this.medicareUpdateForm?.get('drpDXType').valueChanges.subscribe(response => {
      if (this.rulesValues && response) {
        const drpDXType: string = this.medicareUpdateForm?.get('drpDXType').value ?? "";
        const drpPrdSetupType: string = this.medicareUpdateForm?.get('drpPrdSetupType').value ?? "";
        this.reminderService?.clear();
        if (this.medicareUpdateForm?.get('drpDXType').value == "CSA" || this.medicareUpdateForm?.get('drpDXType').value == "OSA") {
          this.hideFullNotificataion = false;

        }
        else {
          this.hideFullNotificataion = true;
        }
        this.rulesValues && this.checkRulesForFeildHighlight(this.rulesValues, drpPrdSetupType, drpDXType);

      }
    })

  }
  // On Page Destroy
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.getDropdown();
    this.dataSource.paginator = this.paginator;
  }

  editForm() {
    this.isEditBtn = false;
    this.documentForm.enable();
  }
  public onPagesLoaded(value: string = ''): void {
    this.pdfHeight = '93%';
  }

  //#region Load Dropdowns
  getDropdown(): void {
    this.drpDoctor = [];
    const doctorItemList = (query: any) => this.doctorService.getList(query);
    this.list.hookToQuery(doctorItemList).subscribe(
      (response) => {
        response?.items?.forEach((element) => {
          this.drpDoctor?.push({
            name:
              element['npiNumber'] +
              ' / ' +
              element?.firstName +
              ' ' +
              element?.middleName +
              ' ' +
              element?.lastName,
            id: element?.id ?? '',
          });
        });
        this.filteredOrderingDoctorTypes = this.PreviewExtractionForm.get(
          'txtDoctorSearchFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredOrderingDoctorTypes = this.PreviewExtractionForm.get(
          'txtordSearchFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.drpDiagnosisICD10 = [];
    const diagonsis10ItemList = (query: any) => this.diagnosis10.getList(query);
    this.list.hookToQuery(diagonsis10ItemList).subscribe(
      (response) => {
        response?.items?.forEach((element: DiagnosisCode10DTO) => {
          let resultString =
            element?.diagnosisCode + ' ' + element?.diagnosisCodeTypeData;
          this.drpDiagnosisICD10?.push({
            diagnosisCode: element?.diagnosisCode ?? '',
            diagnosisCode10ShortCodeId:
              element?.diagnosisCode10ShortCodeId ?? '',
            diagnosisCodeTypeData: resultString,
            diagnosisCodeTypeDataId: element?.diagnosisCodeTypeDataId ?? '',
            id: element?.id ?? '',
            creationTime: element?.creationTime ?? '',
            creatorId: element?.creatorId ?? '',
            isActive: element?.isActive,
            isExist: element?.isExist,
          });
        });

        this.filtered1Icd10Types = this.PreviewExtractionForm.get(
          'txtIcd10Filter1'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDiagnosisICD10.filter((option) =>
              option?.diagnosisCodeTypeData
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filtered1Icd10Types = this.PreviewExtractionForm.get(
          'txtIcd10Filter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDiagnosisICD10.filter((option) =>
              option?.diagnosisCodeTypeData
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.DocumentTypes,
      PatientDropdowns.ReviewStatus,
      PatientDropdowns.ReviewReasons,
      PatientDropdowns.BranchOffices,
      PatientDropdowns.SortingTypes,
      PatientDropdowns.IsActiveStatus,
      PatientDropdowns.Genders,
      PatientDropdowns.Symptoms,
      PatientDropdowns.DXTypes,
      PatientDropdowns.SetupTypes,
      PatientDropdowns.MachineTypes,
    ];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (response) => {
          if (
            response &&
            response.reviewStatuses &&
            response.reviewReasons &&
            response.branchOffices &&
            response.documentTypes &&
            response.genders &&
            response.lstSymptoms &&
            response.lstDXTypes &&
            response.lstSetupTypes &&
            response?.lstMachineTypes
          ) {
            this.drpDocumentTypeLoop = response.documentTypes;
            this.drpReviewStatusLoop = response.reviewStatuses;
            this.drpReviewReasonLoop = response.reviewReasons;
            this.drpBranchLoop = response.branchOffices;
            this.drpIsActiveStatus = response.lstIsActiveStatus;
            this.drpGenderLoop = response.genders;
            this.drpSymptoms = response?.lstSymptoms ?? [];
            this.drpDXType = response?.lstDXTypes ?? [];
            this.drpPrdSetupType = response?.lstSetupTypes ?? [];
            this.drpMachines = response?.lstMachineTypes;
            //To Load the Patients Documents Data in the Table on Page Load
            if (
              this.patientId !== '' &&
              this.patientId !== null &&
              this.patientId !== undefined
            ) {
              this.getDocumentsByPatientId();
            }
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        },
        () => {}
      );
    this.subscription$.push(patientDropdown);
  }

  //#endregion Dropdowns

  //Intialize Form
  createDocumentForm() {
    this.documentForm = this.fb.group({
      drpReviewStatus: new FormControl(null),
      drpReviewReason: new FormControl(null),
      documentFile: new FormControl(null, [Validators.required]),
      drpDocumentType: new FormControl(null, [Validators.required]),

      //  drpDocumentUploadType: new FormControl(null),
      txtDocumentStartDate: new FormControl('', [Validators.required]),
      txtDocumentEndDate: new FormControl('', [Validators.required]),
      txtNote: new FormControl(''),
    });

    this.documentSearchForm = this.fb.group({
      fileName: new FormControl(null),
      documentStatus: new FormControl(null),
      documentStartDate: new FormControl(null, [
        this.dateValidator.dateVaidator,
      ]),
      documentEndDate: new FormControl(null, [this.dateValidator.dateVaidator]),
      documentType: new FormControl(null),
    });

    this.medicareUpdateForm = this.fb.group({
      chkIsRulesNeeded: new FormControl(''),
      drpDXType: new FormControl(''),
      drpPrdSetupType: new FormControl(''),
    });

    this.PreviewExtractionForm = this.fb.group({
      faceToface: new FormArray([]),
      sleepStudy: new FormArray([]),
      priscription: new FormArray([]),
      Complience: new FormArray([]),

      // Demographics
      // txtFirstName: new FormControl(''),
      // txtMiddleName: new FormControl(''),
      // txtLastName: new FormControl(''),
      // drpGeneralGender: new FormControl(''),
      // txtDOB: new FormControl(''),
      // txtReason: new FormControl(''),

      // Initial Face to Face
      txtFobPatientName: new FormControl(''),
      txtIobDob: new FormControl(''),
      txtIobFadeToFace: new FormControl(''),
      txtDoctor: new FormControl(''),
      txtNpiNumber: new FormControl(''),
      txtSignedDate: new FormControl(''),
      chkPrevioushistory: new FormControl(''),
      chkSleepTestRecommended: new FormControl(''),
      chkSleepDoctorSigned: new FormControl(''),
      chkSleepPatientBenifited: new FormControl(''),
      drpSymptoms: new FormControl(''),

      // Sleep Study Report
      txtPatientName1: new FormControl(''),
      txtDob: new FormControl(''),
      txtStudyDate: new FormControl(''),
      txtPressureSetting: new FormControl(''),
      txtREI: new FormControl(''),
      txtRDI: new FormControl(''),
      txtSleepMinutes: new FormControl(''),
      txtAHI: new FormControl(''),
      chkSleepStudyDoctorSigned: new FormControl(''),
      txtSSCentralApneas: new FormControl(''),
      txtSSCentralHyponeas: new FormControl(''),
      txtSSApneaAndHypopnea: new FormControl(''),
      txtDiagnosisStudyDate: new FormControl(''),
      txtTitrationSleepStudyDate: new FormControl(''),
      txtTAHI: new FormControl(''),
      txtTCentralApneas: new FormControl(''),
      txtTCentralHyponeas: new FormControl(''),
      txtTApneaAndHypopnea: new FormControl(''),
      chkCPAPTriedAndFail: new FormControl(''),
      chkRespiratoryBreakdownAvailable: new FormControl(''),
      chkTitrationSleepStudyDoctorSigned: new FormControl(''),
      chkisCPAPReturned: new FormControl(''),
      chkTSSBaselineFile: new FormControl(''),
      txtObstructiveApnea: new FormControl(''),
      txtdiagnosisCode10Id: new FormControl(''),

      // RX
      txtPatientName2: new FormControl(''),
      txtDOB2: new FormControl(''),
      txtPrescriptionDate: new FormControl(''),
      // txtDoctor:new FormControl(''),
      drpMachineDetails: new FormControl(''),
      txtPressureLevel: new FormControl(''),
      txtLengthOfNeed: new FormControl(''),
      // txtNpiNumber:new FormControl(''),
      chkDoctorSigned: new FormControl(''),
      // txtSignedDate:new FormControl(''),
      txtdiagnosisCode10Id1: new FormControl(''),

      // Compliance report
      txtPatientName3: new FormControl(''),
      txtDOB3: new FormControl(''),
      txtStudyStartDate: new FormControl(''),
      txtCompliancePercentage: new FormControl(''),
      txtStudyEndDate: new FormControl(''),
      rbnComplianceMet: new FormControl(''),
      txtUsagedays: new FormControl(''),
      txtUsagedaysOutOf: new FormControl(''),
      txtGreater4Hours: new FormControl(''),
      txtLess4hours: new FormControl(''),
    });

    this.faceToface = new FormGroup({
      txtFobPatientName: new FormControl(''),
      txtIobDob: new FormControl(''),
      txtIobFadeToFace: new FormControl(''),
      txtDoctor: new FormControl(''),
      txtNpiNumber: new FormControl(''),
      txtSignedDate: new FormControl(''),
      chkPrevioushistory: new FormControl(''),
      chkSleepTestRecommended: new FormControl(''),
      chkSleepDoctorSigned: new FormControl(''),
      chkSleepPatientBenifited: new FormControl(''),
      drpSymptoms: new FormControl(''),
    });

    this.sleepStudyReport = new FormGroup({
      // Sleep Study Report
      txtPatientName1: new FormControl(''),
      txtDob: new FormControl(''),
      txtStudyDate: new FormControl(''),
      txtPressureSetting: new FormControl(''),
      txtREI: new FormControl(''),
      txtRDI: new FormControl(''),
      txtSleepMinutes: new FormControl(''),
      txtAHI: new FormControl(''),
      chkSleepStudyDoctorSigned: new FormControl(''),
      txtSSCentralApneas: new FormControl(''),
      txtSSCentralHyponeas: new FormControl(''),
      txtSSApneaAndHypopnea: new FormControl(''),
      txtDiagnosisStudyDate: new FormControl(''),
      txtTitrationSleepStudyDate: new FormControl(''),
      txtTAHI: new FormControl(''),
      txtTCentralApneas: new FormControl(''),
      txtTCentralHyponeas: new FormControl(''),
      txtTApneaAndHypopnea: new FormControl(''),
      chkCPAPTriedAndFail: new FormControl(''),
      chkRespiratoryBreakdownAvailable: new FormControl(''),
      chkTitrationSleepStudyDoctorSigned: new FormControl(''),
      chkisCPAPReturned: new FormControl(''),
      chkTSSBaselineFile: new FormControl(''),
      txtObstructiveApnea: new FormControl(''),
      txtdiagnosisCode10Id: new FormControl(''),
    });

    this.rx = new FormGroup({
      txtPatientName2: new FormControl(''),
      txtDOB2: new FormControl(''),
      txtPrescriptionDate: new FormControl(''),
      txtDoctor: new FormControl(''),
      drpMachineDetails: new FormControl(''),
      txtPressureLevel: new FormControl(''),
      txtLengthOfNeed: new FormControl(''),
      txtNpiNumber: new FormControl(''),
      chkDoctorSigned: new FormControl(''),
      txtSignedDate: new FormControl(''),
      txtdiagnosisCode10Id1: new FormControl(''),
    });

    this.complianceReport = new FormGroup({
      // Compliance report
      txtPatientName3: new FormControl(''),
      txtDOB3: new FormControl(''),
      txtStudyStartDate: new FormControl(''),
      txtCompliancePercentage: new FormControl(''),
      txtStudyEndDate: new FormControl(''),
      rbnComplianceMet: new FormControl(''),
      txtUsagedays: new FormControl(''),
      txtUsagedaysOutOf: new FormControl(''),
      txtGreater4Hours: new FormControl(''),
      txtLess4hours: new FormControl(''),
    });

    this.PreviewExtractionForm.valueChanges.subscribe((response) => {
      const txtEndDate = response?.txtEndDate ?? '';
      const strEndDate =
        (txtEndDate ?? '') === '' ? 'Not Mentioned' : txtEndDate;
      if (strEndDate) {
        this.expiryStatus =
          strEndDate === 'Not Mentioned'
            ? strEndDate
            : new Date() < new Date(strEndDate)
            ? 'Active'
            : 'Expired';
      }
    });

    const valueChanges = this.documentSearchForm.valueChanges.subscribe(
      (value) => {
        if (this.patientId && this.documentSearchForm.valid) {
          const documentType: string = value?.documentType ?? '';
          const fileName: string = value?.fileName ?? '';
          const docFromDate: string = value?.documentStartDate ?? '';
          const docToDate: string = value?.documentEndDate ?? '';
          this.isShowProcessBar = true;
          this.arrUnsortedDocumentList = [];
          this.dataSource = new MatTableDataSource(
            this.arrUnsortedDocumentList
          );
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          const searchDocument$ = this.patientDocumentService
            .searchPatientDocumentDetailsByPatientIdAndSFileNameAndSDocumentTypeAndDtDocumentStartDateAndDtDocumentEndDate(
              this.patientId,
              fileName?.trim(),
              documentType?.trim(),
              docFromDate,
              docToDate
            )
            .subscribe(
              (response) => {
                this.arrUnsortedDocumentList = [];
                if (response) {
                  response?.forEach((element: PatientDocumentBlobDTO) => {
                    if (
                      element?.documentUploadType ===
                        DocumentUploadTypes.SplitUpload &&
                      element?.isVerified !== 1
                    ) {
                      element = this.extractShortNameAndDate(element);
                    } else if (
                      (element?.isVerified === 1 &&
                        element?.documentUploadType ===
                          DocumentUploadTypes.ConsolidatedUpload) ||
                      element?.documentUploadType ===
                        DocumentUploadTypes.ManualUploadType
                    ) {
                      element = this.extractShortNameAndDate(element);
                    }
                  });
                }
                this.isShowProcessBar = false;
                this.dataSource = new MatTableDataSource(
                  this.arrUnsortedDocumentList
                );
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
              },
              (err) => {
                this.dataSource = new MatTableDataSource(
                  this.arrUnsortedDocumentList
                );
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.isShowProcessBar = false;
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }
            );
          this.subscription$.push(searchDocument$);
        }
      }
    );
    this.subscription$.push(valueChanges);

    // this.documentForm.disable();
    // this.documentForm.controls['documentFile'].enable();  // commend for form disable
  }

  //To Toggle Extended Info based on Document Type
  togglePanel() {
    if (
      this.documentForm.value.drpDocumentType === 'RX' ||
      this.documentForm.value.drpDocumentType === 'FACE TO FACE'
    ) {
      this.panelOpenState = true; // Flag for the Accordion Expansion

      //Validation Conditions based on Document Type
      this.documentForm?.controls?.txtDocumentStartDate?.setValidators([
        Validators.required,
      ]);
      this.documentForm?.controls?.txtDocumentEndDate?.setValidators([
        Validators.required,
      ]);
      this.documentForm?.controls?.txtDocumentStartDate?.updateValueAndValidity();
      this.documentForm?.controls?.txtDocumentEndDate?.updateValueAndValidity();
    } else {
      this.panelOpenState = false; // Flag for the Accordion Expansion
      //Validation Conditions based on Document Type
      this.documentForm?.controls?.txtDocumentStartDate?.setValidators([
        Validators.nullValidator,
      ]);
      this.documentForm?.controls?.txtDocumentEndDate?.setValidators([
        Validators.nullValidator,
      ]);

      this.documentForm?.controls?.txtDocumentStartDate?.updateValueAndValidity();
      this.documentForm?.controls?.txtDocumentEndDate?.updateValueAndValidity();
    }
  }

  //Get Uploaded File Name
  getfilename(files: FileList): void {
    this.filesname = [];
    for (let index = 0; index < files.length; index++) {
      if (files[index].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
        this.showErrorForFileType = true;
        this.showErrorForFileLength = false;
        this.filesname = [];
        return;
      }
      if (files.length >= DocumentMaxandTypeLimits.documentMaxNoFileLimit) {
        this.showErrorForFileLength = true;
        this.showErrorForFileType = false;
        this.filesname = [];

        return;
      }
      if (files[index].name.length > 15) {
        this.filesname.push(files[index].name.slice(0, 20) + '...');
      } else {
        this.filesname.push(files[index].name);
      }
    }
    this.intCheckedDocuments = files.length;
    this.isDocumentEditSaveButtonHidden = false;
    // this.documentForm.enable();
  }

  //#region ButtonClick Functions

  //Reset Button
  resetDocumentForm(): void {
    this.show = true;
    this.viewDocViewer = true;
    this.documentForm.reset();
    this.documentForm.patchValue({
      drpReviewStatus: null,
      drpReviewReason: null,
      drpDocumentType: null,
      documentFile: null,
      txtDocumentStartDate: '',
      txtDocumentEndDate: '',
      txtNote: '',
    });
    this.getDocumentGroup();
    this.patientDocumentId = '';
    this.selectedDocumentforUpdate = null;
    this.showErrorForFileType = false;
    this.showErrorForFileLength = false;
    this.filesname = [];
    this.isDocumentForButtonDisable = false;
    this.intCheckedDocuments = 0;
    this.documentForm.disable();
    this.documentForm.controls['documentFile'].enable();
    this.documentForm.markAsUntouched();
    this.documentForm.updateValueAndValidity();
    this.panelOpenState = false;
    this.isDocumentEditSaveButtonHidden = true;
    this.strPdfSource = '';
    this.isShowSpinner = false;
    this.strSelectedPdfPath = '';
    this.getDocumentsByPatientId();
  }

  //Save Button Click
  upload(files: any) {
    const description = this.documentForm?.get('txtNote')?.value;
    const startDateValue = this.documentForm?.get(
      'txtDocumentStartDate'
    )?.value;
    const endDateValue = this.documentForm?.get('txtDocumentEndDate')?.value;
    const startDate: string | null =
      startDateValue === ''
        ? null
        : String(new Date(startDateValue).toISOString());
    const endDate: string | null =
      endDateValue === '' ? null : String(new Date(endDateValue).toISOString());

    if (
      this.patientDocumentId === '' &&
      this.selectedDocumentforUpdate === null
    ) {
      if (files?.length === 0) {
        return;
      }
      let fileResult: { name: File; value: string; savedOrNot: boolean }[] = [];
      for (let file of files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          fileResult.push({
            name: file,
            value: reader?.result?.slice(28)?.toString() ?? '',
            savedOrNot: false,
          });
          if (fileResult.length === files.length) {
            this.table.setDocumentFormData({
              value: this.documentForm.value,
              filenames: this.filesname,
            });
            this.saveManualDocuments(fileResult, startDate, endDate);
          }
        };
      }
    } else {
      this.isShowSpinner = true;
      const UpdateDetails: UpdateDocumentBlobDTO = {
        note: description,
        documentStartDate: startDateValue,
        documentEndDate: endDateValue,
        documentType: this.documentForm?.get('drpDocumentType')?.value,
        tenantId: this.tenantId,
        reviewReason: this.documentForm?.get('drpReviewReason')?.value,
        reviewStatus: this.documentForm?.get('drpReviewStatus')?.value,
      };
      this.patientDocumentService
        .putByInputAndDocumentId(UpdateDetails, this.patientDocumentId)
        .subscribe(
          () => {
            this.isShowSpinner = false;

            // Swal.fire({
            //   title: 'Success',
            //   html: 'Updated Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Updated Successfully', 'Success');
            this.getDocumentGroup();
            this.resetDocumentForm();
          },
          (err) => {
            this.isShowSpinner = false;

            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    }
  }

  //Save Documents Manual Upload
  saveManualDocuments(
    fileResult: { name: File; value: string; savedOrNot: boolean }[],
    startDate?: string,
    endDate?: string
  ) {
    let documentArrayToBeSaved: {
      patientDocumentBlobDTO: SavePatientDocumentBlobDTO;
      patientObjectID: string;
    }[] = [];

    fileResult.forEach((file, index) => {
      if (!file.savedOrNot) {
        let savePatientDocumentBlobDTO: SavePatientDocumentBlobDTO = {
          isVerified: 1,
          patientID: this.patientId, //this.patientId,
          fileName: file.name.name,
          fileContentAsBase64String: file.value,
          documentTypeId: this.patientId, //this.patientId
          documentUploadType: DocumentUploadTypes.ManualUploadType,
          parentPatientDocumentID: null ?? null,
          documentOrderNumber: index,
          documentStartDate: startDate,
          documentEndDate: endDate,
          TenantId: this.tenantId,
          docStatus: '',
          //documentType: this.drpDocumentTypeLoop.filter(v => {return v?.documentType ===this.documentForm?.get("drpDocumentType")?.value; })[0]?.documentType,
          documentType:
            this.documentForm?.get('drpDocumentType')?.value == null
              ? ''
              : this.documentForm?.get('drpDocumentType')?.value,
          reviewStatus:
            this.documentForm?.get('drpReviewStatus')?.value == null
              ? ''
              : this.documentForm?.get('drpReviewStatus')?.value,
          reviewReason:
            this.documentForm?.get('drpReviewReason')?.value == null
              ? ''
              : this.documentForm?.get('drpReviewReason')?.value,
          note: this.documentForm?.get('txtNote')?.value,
          isSorted: 0,
        };
        if (this.parentPatientDocumentID !== '') {
          savePatientDocumentBlobDTO = {
            ...savePatientDocumentBlobDTO,
            parentPatientDocumentID: [this.parentPatientDocumentID],
          };
        }
        let documentToBeSaved = {
          patientDocumentBlobDTO: savePatientDocumentBlobDTO,
          patientObjectID: this.patientId,
        };
        documentArrayToBeSaved.push(documentToBeSaved);
      }
    });
    from(documentArrayToBeSaved)
      .pipe(
        tap(() => {
          this.isShowSpinner = true;
        }),
        groupBy((v) => v.patientObjectID),
        mergeMap((group) => group.pipe(toArray())),
        mergeMap((group) =>
          from(group).pipe(
            concatMap((v) =>
              defer(() =>
                this.patientDocumentService.postByInputAndPatientID(
                  v.patientDocumentBlobDTO,
                  v.patientObjectID
                )
              )
            )
          )
        ),
        takeLast(1),
        map(() => {
          this.parentPatientDocumentID = '';
          this.getDocumentsByPatientId();
          this.getDocumentGroup();
        })
      )
      .subscribe(
        () => {
          this.table.setDocumentFormData(null);
          this.isShowSpinner = false;
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Saved Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Saved Successfully', 'Success');
          this.resetDocumentForm();
          this.saveDocumentFormState.emit(this.documentForm.value);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.isShowSpinner = false;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  patientDetails: PatientDocumentBlobDTO[] = [];
  //To Get the Uploaded Documents by Patient Id
  getDocumentsByPatientId(): void {
    this.isShowProcessBar = true;
    if (
      this.patientId !== '' &&
      this.patientId !== null &&
      this.patientId !== undefined
    ) {
      const patientGet = this.patientDocumentService
        .getByPatientId(this.patientId)
        .subscribe(
          (response) => {
            this.patientDetails = response;
            this.getDocumentsListByPatientId(this.patientDetails);
          },
          (err) => {
            this.isShowProcessBar = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(patientGet);
    }
  }

  //To Get the Document List
  getDocumentsListByPatientId(patientDetails: PatientDocumentBlobDTO[]): void {
    this.arrUnsortedDocumentList = [];
    this.arrMergeDocumentList = [];
    patientDetails?.forEach((element: PatientDocumentBlobDTO) => {
      element = this.extractShortNameAndDate(element);
    });
    this.documentGrouping();
    this.documentGroupingFunction();
    listOfDocuments = patientDetails;
    this.isShowProcessBar = false;
  }

  //To get Extract the Short Name and Date(Format Table Data)
  private extractShortNameAndDate(
    element: PatientDocumentBlobDTO
  ): PatientDocumentBlobDTO {
    let uploadType: DocumentUploadTypes;
    let resultDocStartDate: string = '';
    let resultDocEndDate: string = '';
    this.tenantId = element?.tenantId ?? defaultGuid;
    const filename: string =
      element?.fileName?.toLowerCase()?.replace('/(XP[a-z]th)|(.pdf)/gi', '') ??
      '';
    if (typeof element?.documentStartDate === 'string') {
      const date = `${new Date(element?.documentStartDate)?.toLocaleDateString(
        'en-US'
      )}`;
      const hours = `${new Date(element?.documentStartDate)?.getHours()}`;
      const time = `${new Date(element?.documentStartDate)?.getMinutes()}`;
      resultDocStartDate = date + '  ' + hours + ':' + time;
    }
    if (typeof element?.documentEndDate === 'string') {
      const date = `${new Date(element?.documentEndDate)?.toLocaleDateString(
        'en-US'
      )}`;
      const hours = `${new Date(element?.documentEndDate)?.getHours()}`;
      const time = `${new Date(element?.documentEndDate)?.getMinutes()}`;
      resultDocEndDate = date + '  ' + hours + ':' + time;
    }
    uploadType = DocumentUploadTypes.ManualUploadType;
    element = {
      fileName: filename,
      documentUploadType: uploadType,
      documentStartDate: resultDocStartDate,
      documentEndDate: resultDocEndDate,
      docStatus:
        new Date(resultDocEndDate) >= this.myDate ? 'Active' : 'Expired',
      ...element,
    };
    this.arrUnsortedDocumentList.push(element);
    this.dataSource = new MatTableDataSource(this.arrUnsortedDocumentList);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    return element;
  }

  //Grouping the Documents by Document Type Wise
  documentGroupingFunction(): void {
    this.documentGroupedByType = [];
    const groupedDocument$ = from(this.arrUnsortedDocumentList).pipe(
      groupBy((v) => v.documentTypeId),
      mergeMap((group) =>
        group.pipe(
          reduce(
            (
              acc: Partial<DocumentGroupIngListType>,
              cur: PatientDocumentBlobDTO
            ) => {
              acc?.groupItem?.push(cur);
              return acc;
            },
            {
              documentType: group.key,
              documentGroupedByType: this.drpDocumentTypeLoop.filter(
                (v) => v?.documentType === group.key
              )[0]?.documentType,
              groupItem: [],
            }
          )
        )
      ),
      toArray()
    );
    const combinedData$ = combineLatest([
      groupedDocument$,
      from(this.drpDocumentTypeLoop),
    ]).subscribe(
      (v) => {
        this.documentGroupedByType = v[0];
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(combinedData$);
  }

  //

  // documentGrouping
  documentGrouping(): void {
    const docGrouping = this.patientDocumentService
      .getDocumentGroupByPatientId(this.patientId)
      .subscribe((e) => {
        this.documentGroup = e;
      });
    this.subscription$.push(docGrouping);
  }
  //#endregion

  //Edit Document
  viewPdfFile(selectedBlobName: string, patientDocumentId: string): void {
    this.pdfHeight = '100%';
    this.show = false;

    this.patientDocumentId = patientDocumentId;
    this.viewDocViewer = false;
    this.getpatientDocumentByBlobID(selectedBlobName, patientDocumentId);
  }

  //To get the pdf File on click File Name under Document Group List
  getPdf(value: string) {
    this.pdfHeight = '100%';
    const getDocument = this.patientDocumentService
      .getPatientDocumentByBlobIDByBlobName(value)
      .subscribe(
        (response) => {
          this.viewDocViewer = false;

          if (response && response !== null && response?.length !== 0) {
            this.strSelectedPdfPath = String(
              'data:application/pdf;base64,' + response
            );
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        },
        () => {}
      );
    this.subscription$.push(getDocument);
  }
  //Get Patient Document by Blob Name and Patient Document Id
  getpatientDocumentByBlobID(
    selectedBlobName: string,
    patientDocumentId: string
  ): void {
    this.isShowSpinner = true;
    const getDocument = this.patientDocumentService
      .getPatientDocumentByBlobIDByBlobName(selectedBlobName)
      .subscribe(
        (response) => {
          this.isShowSpinner = false;
          if (response && response !== null && response?.length !== 0) {
            this.strSelectedPdfPath = String(
              'data:application/pdf;base64,' + response
            );
          }

          let selectedDocument: PatientDocumentBlobDTO = listOfDocuments.filter(
            (document) => {
              return document && document?.id === patientDocumentId;
            }
          )[0];

          this.selectedDocumentforUpdate = selectedDocument;
          (selectedDocument !== null || selectedDocument !== undefined) &&
            this.documentForm.patchValue({
              drpDocumentUploadType: 'Manual Sorting',
              txtDocumentStartDate:
                selectedDocument.documentStartDate == null
                  ? ''
                  : selectedDocument.documentStartDate,
              txtDocumentEndDate:
                selectedDocument.documentEndDate == null
                  ? ''
                  : selectedDocument.documentEndDate,
              drpDocumentType:
                selectedDocument.documentTypeId == ''
                  ? null
                  : selectedDocument.documentType,
              drpReviewStatus:
                selectedDocument.reviewStatus == ''
                  ? null
                  : selectedDocument.reviewStatus,
              drpReviewReason:
                selectedDocument.reviewReason == ''
                  ? null
                  : selectedDocument.reviewReason,
              txtNote: selectedDocument.note,
            });
          if (selectedDocument !== null || selectedDocument !== undefined) {
            this.documentForm.enable();
            this.documentForm.controls['documentFile'].disable();
            this.isDocumentForButtonDisable = true;
            this.isDocumentEditSaveButtonHidden = false;
          }
          this.getDocumentsByPatientId();
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );

    this.subscription$.push(getDocument);
  }

  //Download Document
  downloadFile(blobName: string, fileName: string): void {
    this.pdfHeight = '100%';
    const downloadDocument = this.patientDocumentService
      .getPatientDocumentByBlobIDByBlobName(blobName)
      .subscribe(
        (response) => {
          if (response && response !== null && response?.length !== 0) {
            let filelocation: string = String(
              'data:application/pdf;base64,' + response
            );
            let link = document.createElement('a');
            link.download = fileName + '.pdf';
            link.href = filelocation.toString();
            link.click();
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        },
        () => {}
      );
    this.subscription$.push(downloadDocument);
  }

  //Delete Document
  deleteFileById(patientDocumentId: string): void {
    this.pdfHeight = '100%';
    this.isShowProcessBar = true;
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const deleteDocumentFile = this.patientDocumentService
          .deleteByPatientIdAndPatientDocumentId(
            this.patientId,
            patientDocumentId
          )
          .subscribe(
            () => {},
            (err) => {
              this.isShowProcessBar = false;
              this.dataSource = new MatTableDataSource([]);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
        this.subscription$.push(deleteDocumentFile);
      }
      this.getDocumentsByPatientId();
      this.getDocumentGroup();
      this.resetDocumentForm();
      this.isShowProcessBar = false;
    });
  }

  //#region tree
  private _transformer = (node: DocumentGroupDTO1, level: number) => {
    return {
      expandable: !!node?.documentDetail && node?.documentDetail?.length > 0,
      name: node?.documentType,
      level: level,
      documentCount: node?.documentCount,
      blobName: node?.blobName,
      uploadedDate: node?.uploadedDate,
    };
  };

  treeControl = new FlatTreeControl<DocumentTreeNode>(
    (node) => node?.level,
    (node) => node?.expandable
    // node=>node.
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node?.level,
    (node) => node?.expandable,
    (node) => node?.documentDetail
  );

  dataSource1 = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: DocumentTreeNode) => node?.expandable;

  getDocumentGroup() {
    const newValue = this.patientDocumentService
      .getDocumentGroupByPatientId(this.patientId)
      .subscribe((response) => {
        const ex: DocumentGroupDTO1[] = response?.map((x, i) => {
          let documentDetail: any[] = response[i].documentDetail?.map((e) => {
            let echDeatils = {
              documentType: e.fileName,
              documentCount: x.documentCount,
              blobName: e.blobName,
              fileName: e.blobName,
              documentId: e.documentId,
              uploadedDate: e.uploadedDate,
            };

            return echDeatils;
          });
          let e = {
            documentType: x.documentType,
            documentCount: x.documentCount,
            documentDetail: documentDetail,
          };
          return e;
        });
        this.dataSource1.data = ex;
        this.mainTree = this.dataSource1._flattenedData.value;

        // for (const item of this.dataSource1._flattenedData.value) {
        this.dataSource1._flattenedData.value.forEach((item, index) => {
          if (item.expandable) {
            this.currentKey = item.name.toLowerCase().replace(/\s+/g, '');
            this.full_name = item.name;
          } else {
            if (this.currentKey) {
              if (!this.childTree[this.currentKey]) {
                this.childTree[this.currentKey] = [];
                if (!this.keysToExclude.includes(this.currentKey)) {
                  this.childDocType.push(this.currentKey);
                  this.sfull_name.push(this.full_name);
                }
              }
              this.childTree[this.currentKey].push(item);
            }
          }
        });
      });

    this.subscription$.push(newValue);
  }
  clearGender() {
    this.gender = '';
    this.PreviewExtractionForm.patchValue({ drpGeneralGender: '' });
  }
  //! Doctor Modal Open Functions
  openDoctorModal(doctorId?: string) {
    const dialogRef = this.dialog.open(PatientDoctorComponent, {
      disableClose: true,
      data: { doctorId: doctorId },
    });
    dialogRef.afterClosed().subscribe(
      () => {
        this.doctorDropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  doctorDropdown(): void {
    this.drpDoctor = [];
    const itemList = (query: any) => this.doctorService.getList(query);
    this.list.hookToQuery(itemList).subscribe(
      (response) => {
        response?.items?.forEach((element) => {
          this.drpDoctor?.push({
            name:
              element['npiNumber'] +
              ' / ' +
              element?.firstName +
              ' ' +
              element?.middleName +
              ' ' +
              element?.lastName,
            id: element?.id ?? '',
          });
        });
        this.filteredOrderingDoctorTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtOrdSearchFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredPractionerTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtPractitionerSearchFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredMarRepDoctorTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtMarRepSearchFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredRenDoctorTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtRenDoctorFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredRefDoctorTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtRefDoctorFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredMarDoctorTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtMarDoctorFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredPrimaryDoctorTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtPrimaryDoctorSearchFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      },
      () => {}
    );
    // this.doctorService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
    //   response?.items?.forEach(element => {
    //     this.drpDoctor?.push({
    //       name: element['npiNumber'] + ' / ' + element?.firstName + " " + element?.middleName + " " + element?.lastName,
    //       id: element?.id
    //     });
    //   });
    // }, () => {
    // });
  }

  doctorValueChange(formType?: string, value?: MatSelect) {
    if (formType === 'p2p') {
      const doctorNpi: string = String(value?.value)?.split(' /')[0];
      const d = this.Prescription()?.value[0];
      const p2pvalue = {
        txtNpiNumber: doctorNpi,
        txtPatientName2: d?.txtPatientName2,
        txtDOB2: d?.txtDOB2,
        txtPrescriptionDate: d?.txtPrescriptionDate,
        txtprescriptionsDoctorSign: d?.txtprescriptionsDoctorSign,
        txtDoctor: value?.value,
        drpMachineDetails: d?.drpMachineDetails,
        txtPressureLevel: d?.txtPressureLevel,
        txtLengthOfNeed: d?.txtLengthOfNeed,
        chkDoctorSigned: d?.chkDoctorSigned,
        txtSignedDate: d?.txtSignedDate,
        txtItemcode: d?.txtItemcode,
        txtDuration: d?.txtDuration,
        txtEstimatedLength: d?.txtEstimatedLength,
        txtSupplierName: d?.txtSupplierName,
        txtSupplierDetials: d?.txtSupplierDetials,
        txtdiagnosisCode10Id1: d?.txtdiagnosisCode10Id1,
      };
      this.Prescription().clear();
      const priscription: FormGroup = this.newPrescription();
      this.Prescription()?.push(priscription);
      this.PreviewExtractionForm?.patchValue({ priscription: [p2pvalue] });
    }
    if (formType === 'f2f') {
      const doctorNpi: string = String(value?.value)?.split(' /')[0];
      // const d = this.FaceToFace()?.value[0];
      // const f2fvalue = {
      //   txtNpiNumber: doctorNpi,
      //   txtDateofVisit: d?.txtDateofVisit,
      //   txtPatientinfo: d?.txtPatientinfo,
      //   txtHeightFeet: d?.txtHeightFeet,
      //   txtWeight: d?.txtWeight,
      //   txtDoctor: value?.value,
      //   txtSignature: d?.txtSignature,
      //   txtSignedDate: d?.txtSignedDate,
      //   chkPrevioushistory: d?.chkPrevioushistory,
      //   txtFobPatientName: d?.txtFobPatientName,
      //   txtIobDob: d?.txtIobDob,
      //   txtIobFadeToFace: d?.txtIobFadeToFace,
      //   txtTreatment: d?.txtTreatment,
      //   chkSleepTestRecommended: d?.chkSleepTestRecommended,
      //   chkSleepDoctorSigned: d?.chkSleepDoctorSigned,
      //   chkSleepPatientBenifited: d?.chkSleepPatientBenifited,
      //   txtFobPatientBenifitNotes: d?.txtFobPatientBenifitNotes,
      //   drpSymptoms: d?.drpSymptoms,
      // };
      // this.FaceToFace().clear();
      // const FaceToFace: FormGroup = this.newFaceToFace();
      // this.FaceToFace()?.push(FaceToFace);
      // this.PreviewExtractionForm?.patchValue({ faceToface: [f2fvalue] });
      this.PreviewExtractionForm?.patchValue({
        txtNpiNumber: doctorNpi
      });
      // this.PreviewExtractionForm?.patchValue({
      //   f2fvalue
      // });
    }
  }

  // faectoface form control return
  FaceToFace() {
    return this.PreviewExtractionForm?.get('faceToface') as FormArray;
  }
  newFaceToFace(): FormGroup {
    return this.fb.group({
      txtDateofVisit: [''],
      txtPatientinfo: [''],
      txtHeightFeet: ['', this.numberValidators.isNumberCheck],
      txtHeightInches: new FormControl('', [
        Validators.min(0),
        Validators.max(11),
        this.numberValidators.isNumberCheck,
      ]),
      txtWeight: ['', this.numberValidators.isNumberCheck],
      txtDoctor: [''],
      txtNpiNumber: ['', this.numberValidators.isNumberCheck],
      txtSignature: [''],
      txtSignedDate: [''],
      chkPrevioushistory: [''],
      email: ['', [Validators.email]],
      //! new
      txtFobPatientName: [''],
      txtIobDob: [''],
      txtIobFadeToFace: [''],
      chkSleepTestRecommended: [''],
      chkSleepDoctorSigned: [''],
      chkSleepPatientBenifited: [''],
      txtFobPatientBenifitNotes: [''],
      drpSymptoms: [],
    });
  }

  // Prescription form control return
  Prescription() {
    return this.PreviewExtractionForm?.get('priscription') as FormArray;
  }
  newPrescription(): FormGroup {
    return this.fb.group({
      txtPatientName2: ['', this.textValidators.isTextCheck],
      txtDOB2: [''],
      txtPrescriptionDate: [''],
      txtDoctor: [''],
      txtDoctorSearchFilter: [''],
      drpMachineDetails: [''],
      txtPressureLevel: [''],
      txtLengthOfNeed: [''],
      txtNpiNumber: ['', this.numberValidators.isNumberCheck],
      chkDoctorSigned: [''],
      txtSignedDate: [''],
      txtItemcode: [''],
      txtSearchFilter: [''],
      txtDuration: [''],
      txtEstimatedLength: [''],
      txtSupplierName: ['', this.textValidators.isTextCheck],
      txtSupplierDetials: [''],
      txtdiagnosisCode10Id1: [''],
      txtIcd10Filter1: [''],
      txtItemcodeSearch: [''],
      //! new
      txtprescriptionsDoctorSign: [''],
    });
  }

  //! Clear dropdwon
  clearOrderingDoctor() {
    this.OrderingDoctor = '';
    this.PreviewExtractionForm?.patchValue({ txtDoctor: '' });
  }

  //! Download override function
  downloadClicked() {
    this.blobName &&
      window.open(
        baseUrl + '#/ViewDocumentPerviewByBlobId/' + this.blobName + '/' + 1,
        '_blank'
      );
  }

  /// for enter number only
  numberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event?.which ? event?.which : event?.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  // Only Numbers with Decimals
  keyPressNumbersDecimal(event: {
    which: any;
    keyCode: any;
    preventDefault: () => void;
  }) {
    var charCode = event?.which ? event?.which : event?.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event?.preventDefault();
      return false;
    }
    return true;
  }

  //! Open ICD 10 Add Modal
  openIcd10Modal() {
    const dialogRef = this.dialog.open(PatientIcd10Component, {
      disableClose: true,
      data: {},
    });
    dialogRef.afterClosed().subscribe(
      () => {
        this.getDropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  onSubmitDisapproveMedicare() {
    
  }

  onSubmit() {
    this.submitted = true;
    let PatienetFromDocument: CreateUpdateMedicareDocDTO;
    // const demoValues = this.getDemographicsValues();
    const faceToFaceValues = this.getFaceToFaceValues();
    this.faceToface = this.getFaceToFaceValues();
    const sleepStudyValues = this.getSleepStudyValues();
    const priscriptionValues = this.getPriscriptionValues();
    const ComplienceValues = this.getComplienceValues();

    this.faceTofaceArr = [];
    this.faceTofaceArr?.push({
      initialFaceToFaceId: defaultGuid,
          patientName:this.PreviewExtractionForm.controls['txtFobPatientName'].value,
          dateOfBirth: this.PreviewExtractionForm.controls['txtIobDob'].value,
          faceToFaceDate: this.PreviewExtractionForm.controls['txtIobFadeToFace'].value,
          doctorName: this.PreviewExtractionForm.controls['txtDoctor'].value,
          npi: this.PreviewExtractionForm.controls['txtNpiNumber'].value,
          hieght: 0,
          weight: 0,
          signedDate : this.PreviewExtractionForm.get('txtSignedDate').value,
          previousHistoryDeclaration: 0 ,
          //! new
          isSleepTestRecommended:  this.PreviewExtractionForm.get('chkSleepTestRecommended').value ? 1 : 0,
          isDoctorSigned: this.PreviewExtractionForm.get('chkSleepDoctorSigned').value ? 1 : 0,
          isPatientBenifited:this.PreviewExtractionForm.get('chkSleepPatientBenifited').value ? 1 : 0,
          patientBenefitNotes:"",
          symptoms: this.PreviewExtractionForm.controls['drpSymptoms'].value ?? '',
    });
    this.sleepStudyArr = [];

    this.sleepStudyArr?.push({
      sleepStudyId: defaultGuid,
      patientName: this.PreviewExtractionForm.controls['txtPatientName1'].value,
      dateOfBirth: this.PreviewExtractionForm.controls['txtDob'].value,
      sleepStudyDate: this.PreviewExtractionForm.controls['txtStudyDate'].value,
      rdi: this.PreviewExtractionForm.controls['txtRDI'].value  == '' ? 0 : this.PreviewExtractionForm.controls['txtRDI'].value  ?? 0,
      rei:this.PreviewExtractionForm.controls['txtREI'].value  == '' ? 0 : this.PreviewExtractionForm.controls['txtREI'].value  ?? 0,
      diagnosisCode:this.PreviewExtractionForm.controls['txtdiagnosisCode10Id'].value == '' ? [] : this.PreviewExtractionForm.controls['txtdiagnosisCode10Id'].value ?? 0,
      pressureSetting: this.PreviewExtractionForm.controls['txtPressureSetting'].value ?? '',
      //! new
      isCPAPReturned: +this.PreviewExtractionForm.controls['chkisCPAPReturned'].value ?? 0,
      diagnosticSleepStudy
      // : null,
      :{
          totalSleepMins: this.PreviewExtractionForm.controls['txtSleepMinutes'].value ?? '',
          ahi: this.PreviewExtractionForm.controls['txtAHI'].value  == '' ? 0 : this.PreviewExtractionForm.controls['txtAHI'].value  ?? 0,
          diagnosticSleepStudyDate:this.PreviewExtractionForm.controls['txtDiagnosisStudyDate'].value  ?? null,
          isDoctorSigned: this.PreviewExtractionForm.controls['chkSleepStudyDoctorSigned'].value ? 1 : 0  ?? 0,
          centralApneas: this.PreviewExtractionForm.controls['txtSSCentralApneas'].value =='' ? 0 : this.PreviewExtractionForm.controls['txtSSCentralApneas'].value  ?? 0,

          centralHyponeas:  this.PreviewExtractionForm.controls['txtSSCentralHyponeas'].value == '' ? 0 : this.PreviewExtractionForm.controls['txtSSCentralHyponeas'].value ?? 0,
          centralAHI: 0,
          totalApneaAndHypopnea: this.PreviewExtractionForm.controls['txtSSApneaAndHypopnea'].value =='' ? 0 : this.PreviewExtractionForm.controls['txtSSApneaAndHypopnea'].value ?? 0,
        },
      titrationSleepStudy : {
              isBaselineFileAvailable: 0 ,
              obstructiveApnea: this.PreviewExtractionForm.controls['txtObstructiveApnea'].value == '' ? 0 : this.PreviewExtractionForm.controls['txtObstructiveApnea'].value ?? 0,
              titrationSleepStudyDate: this.PreviewExtractionForm.controls['txtTitrationSleepStudyDate'].value  ,
              isCPAPTriedAndFail: this.PreviewExtractionForm.controls['chkCPAPTriedAndFail'].value ? 1 : 0  ?? 0 ,
              isRespiratoryBreakdownAvailable:this.PreviewExtractionForm.controls['chkRespiratoryBreakdownAvailable'].value ? 1 : 0  ?? 0,
              isDoctorSigned:this.PreviewExtractionForm.controls['chkTitrationSleepStudyDoctorSigned'].value ? 1 : 0  ?? 0,
              ahi: this.PreviewExtractionForm.controls['txtTAHI'].value == '' ? 0 : this.PreviewExtractionForm.controls['txtTAHI'].value  ?? 0 ,
              centralApneas: this.PreviewExtractionForm.controls['txtTCentralApneas'].value == '' ? 0 : this.PreviewExtractionForm.controls['txtTCentralApneas'].value ?? 0 ,
              centralHyponeas:  0,
              totalApneaAndHypopnea:this.PreviewExtractionForm.controls['txtTApneaAndHypopnea'].value == '' ? 0 : this.PreviewExtractionForm.controls['txtTApneaAndHypopnea'].value ?? 0,
            },
      homeSleepTest : null,
      ahi: 0,
    });
    this.priscriptionArr = [];
    this.priscriptionArr?.push({
      prescriptionId: defaultGuid,
        patientName: this.PreviewExtractionForm.controls['txtPatientName2'].value ?? '' ,
        dateOfBirth: this.PreviewExtractionForm.controls['txtDOB2'].value ?? '' ,
        prescriptionDate: this.PreviewExtractionForm.controls['txtPrescriptionDate'].value ?? '' ,
        doctorName: this.PreviewExtractionForm.controls['txtDoctor'].value ?? '' ,
        machineDetails:this.PreviewExtractionForm.controls['drpMachineDetails'].value ?? '',
        pressureLevel: this.PreviewExtractionForm.controls['txtPressureLevel'].value ?? '',
        lengthOfNeed: this.PreviewExtractionForm.controls['txtLengthOfNeed'].value ?? '',
        diagnosticCode: this.PreviewExtractionForm.controls['txtdiagnosisCode10Id1'].value  == '' ? [] : this.PreviewExtractionForm.controls['txtdiagnosisCode10Id1'].value  ?? '',
        isDoctorSigned: this.PreviewExtractionForm.controls['chkDoctorSigned'].value ? 1 : 0,
        npi: this.PreviewExtractionForm.controls['txtNpiNumber'].value ?? '' ,
    });

    this.ComplienceArr = []; // Need to work
    ComplienceValues.forEach((element) => {
      this.ComplienceArr?.push({
        complainceId: defaultGuid,
        patientName: element?.txtPatientName3 ?? '',
        dateOfBirth: element?.txtDOB3 ?? '',
        studyStartDate: element?.txtStudyStartDate ?? '',
        studyEndDate: element?.txtStudyEndDate ?? '',
        isComplainceMet: +element?.rbnComplianceMet | 0,
        complaincePercentage: element?.txtCompliancePercentage ?? '',
        usageDays: element?.txtUsagedays ?? '',
        greaterThanFourHrs: element?.txtGreater4Hours ?? '',
        lessThanFourHrs: element?.txtLess4hours ?? '',
        outOfDays: element?.txtUsagedaysOutOf ?? '',
      });
    });

    // let zermidIns = this.drpPayerId?.filter(value => {
    //   return value && value?.name === this.PreviewExtractionForm.value.drpPayorName
    // })[0];
    // let pverifyIns = this.drpPlan?.filter(value => {
    //   return value && value?.name === this.PreviewExtractionForm.value.drpInsurance
    // })[0];

    const isNeedtoCheckDemo =
      this.lstIndex?.filter((e) => e?.documentType === 'DEMOGRAPHICS')[0] ===
        undefined ||
      this.lstIndex?.filter((e) => e?.documentType === 'DEMOGRAPHICS')[0] ===
        null
        ? 0
        : 1;
    const isNeedtoCheckF2F =
      this.lstIndex?.filter((e) => e?.documentType === 'FACE TO FACE')[0] ===
        undefined ||
      this.lstIndex?.filter((e) => e?.documentType === 'FACE TO FACE')[0] ===
        null
        ? 0
        : 1;
    const isNeedtoCheckPres =
      this.lstIndex?.filter((e) => e?.documentType === 'RX')[0] === undefined ||
      this.lstIndex?.filter((e) => e?.documentType === 'RX')[0] === null
        ? 0
        : 1;
    const isNeedtoCheckSleep =
      this.lstIndex?.filter(
        (e) => e?.documentType === 'SLEEP STUDY REPORT'
      )[0] === undefined ||
      this.lstIndex?.filter(
        (e) => e?.documentType === 'SLEEP STUDY REPORT'
      )[0] === null
        ? 0
        : 1;
    const isNeedtoCheckComplaince =
      this.lstIndex?.filter(
        (e) => e?.documentType === 'COMPLIANCE REPORT'
      )[0] === undefined ||
      this.lstIndex?.filter(
        (e) => e?.documentType === 'COMPLIANCE REPORT'
      )[0] === null
        ? 0
        : 1;
    const isNeedtoCheckVerify =
      this.lstIndex?.filter(
        (e) => e?.documentType === 'INSURANCE VERIFICATION'
      )[0] === undefined ||
      this.lstIndex?.filter(
        (e) => e?.documentType === 'INSURANCE VERIFICATION'
      )[0] === null
        ? 0
        : 1;
    const isRulesNeeded = this.medicareUpdateForm.value.chkIsRulesNeeded;
    PatienetFromDocument = {
      // demographics: this.demographicsArr,
      initialFaceToFaces: this.faceTofaceArr,
      sleepStudies: this.sleepStudyArr,
      prescriptions: this.priscriptionArr,
      complainces: this.ComplienceArr,
      // fileId: this.fileId,
      isDemographicsDataAvailable: isNeedtoCheckDemo,
      isInitialFaceToFaceDataAvailable: isNeedtoCheckF2F,
      isSleepStudyDataAvailable: isNeedtoCheckSleep,
      isPrescriptionDataAvailable: isNeedtoCheckPres,
      isComplainceDataAvailable: isNeedtoCheckComplaince,
      isVerificationDataAvailable: isNeedtoCheckVerify,
      isRuleCheck: isRulesNeeded ? 1 : 0,
      compliancePendingValidationCount:
        this.compliancePendingValidationCount ?? 0,
      demographicsPendingValidationCount:
        this.demographicsPendingValidationCount ?? 0,
      initialFaceToFacePendingValidationCount:
        this.initialFaceToFacePendingValidationCount ?? 0,
      prescriptionPendingValidationCount:
        this.prescriptionPendingValidationCount ?? 0,
      sleepStudyPendingValidationCount:
        this.sleepStudyPendingValidationCount ?? 0,
      verificationPendingValidationCount:
        this.verificationPendingValidationCount ?? 0,
      isCompSA: this.isCompSA,
      percentageOfCompletion: this.percentageOfCompletion,
      isRulesNeeded: +this.medicareUpdateForm.value.chkIsRulesNeeded ?? 0,
      dxType: this.medicareUpdateForm.value.drpDXType,
      setupType:
        this.medicareUpdateForm.value.drpDXType === DXTypes.CPAPtoBIPAP ||
        this.medicareUpdateForm.value.drpDXType === DXTypes.CompSA
          ? SetupTypes.None
          : this.medicareUpdateForm.value.drpPrdSetupType,
      approvedPatientId: this.insuranceAfterSave?.approvedPatientId,
      saleOrderId: this.insuranceAfterSave?.saleOrderId,
    };

    const newValue = this.patientDocumentService
      .checkRoles(this.patientId, PatienetFromDocument)
      .subscribe(
        (response) => {
          this.toastr.success('Saved Successfully', 'Success');
          if (response?.percentageOfCompletion === 100) {
            this.percentageOfCompletion = 100;
            this.prescriptionPendingValidationCount = 0;
            this.compliancePendingValidationCount = 0;
            this.initialFaceToFacePendingValidationCount = 0;
            this.sleepStudyPendingValidationCount = 0;
            this.demographicsPendingValidationCount = 0;
            this.verificationPendingValidationCount = 0;
          } else {
            this.percentageOfCompletion = response?.percentageOfCompletion ?? 0;
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          const errorCnt: {
            msg: string;
            type: number;
            btntxt: string;
            iconLogo: SweetAlertIcon;
          } = this.getRuleMasterErrorMsg(
            data?.error?.error?.message,
            data.error.error
          );
          if (errorCnt.type === 1) {
            Swal.fire({
              icon: errorCnt.iconLogo,
              html: errorCnt.msg,
              //text: data?.error?.error?.message,
              showCancelButton: true,
              customClass: { container: 'swal2-popup2' },
              cancelButtonColor: '#f46a6a',
              confirmButtonColor: '#34c38f',
              confirmButtonText: errorCnt.btntxt,
            }).then((result) => {
              if (result.value == true) {
                this.ruleCheck = 0;
                this.isCompSA = 1;
                this.onSubmit();
              }
            });
          } else {
            Swal.fire({
              icon: errorCnt.iconLogo,
              html: errorCnt.msg,
            });
          }
        }
      );
  }

  getRuleMasterErrorMsg(
    message: string,
    _error: any
  ): { msg: string; type: number; btntxt: string; iconLogo: SweetAlertIcon } {
    let endMsg: string = '';
    const ruleErr = '{"ruleError":{"';
    let iconLogo: SweetAlertIcon = 'error';
    const inCrtDxSetupMsg = 'Please select correct DX type and Setup type!';
    const inCrtValMsg = 'Your request is not valid!';
    const complErrMsg = 'Input string was not in a correct format.';
    let btntxt = 'Close';
    let type: number = 0;
    this.percentageOfCompletion = 0;
    if (
      message &&
      message?.includes(ruleErr) &&
      message !== inCrtDxSetupMsg &&
      message !== inCrtValMsg &&
      message !== complErrMsg
    ) {
      const pmessgae: customErrorMessage = JSON?.parse(message) ?? null;

      const faceToFaceErr: any[] = pmessgae?.ruleError?.faceToFace ?? [];
      const prescriptionErr: any[] = pmessgae?.ruleError?.prescription ?? [];
      const sleepStudyErr: any[] = pmessgae?.ruleError?.sleepStudy ?? [];
      if (faceToFaceErr && faceToFaceErr?.length !== 0) {
        let messgtoDisplayStart: string =
          "<span><b>Face To Face</b></span<br> <table style=' margin-left: auto; margin-right: auto;text-align: -webkit-auto;'><tbody>";
        let messgtoDisplayInnerCon: string = messgtoDisplayStart + '';
        faceToFaceErr?.forEach((element) => {
          messgtoDisplayInnerCon +=
            "<tr><td style='display:contents;'>" +
            `${element?.ruleNumber}` +
            `${'- ' + element?.rule}` +
            '</td></tr>';
        });
        const messgtoDisplayEnd = messgtoDisplayInnerCon + '</tbody></table>';
        endMsg += messgtoDisplayEnd;
      }
      if (prescriptionErr && prescriptionErr?.length !== 0) {
        let messgtoDisplayStart: string =
          "<span><b>Prescription</b></span<br> <table style=' margin-left: auto; margin-right: auto;text-align: -webkit-auto;'><tbody>";
        let messgtoDisplayInnerCon: string = messgtoDisplayStart + '';
        prescriptionErr?.forEach((element) => {
          messgtoDisplayInnerCon +=
            "<tr><td style='display:contents;'>" +
            `${element?.ruleNumber}` +
            `${'- ' + element?.rule}` +
            '</td></tr>';
        });
        const messgtoDisplayEnd = messgtoDisplayInnerCon + '</tbody></table>';
        endMsg += messgtoDisplayEnd;
      }
      if (sleepStudyErr && sleepStudyErr?.length !== 0) {
        let messgtoDisplayStart: string =
          "<span><b>Sleep Study</b></span<br> <table style=' margin-left: auto; margin-right: auto;text-align: -webkit-auto;'><tbody>";
        let messgtoDisplayInnerCon: string = messgtoDisplayStart + '';
        sleepStudyErr?.forEach((element) => {
          messgtoDisplayInnerCon +=
            "<tr><td style='display:contents;'>" +
            `${element?.ruleNumber}` +
            `${'- ' + element?.rule}` +
            '</td></tr>';
        });
        const messgtoDisplayEnd = messgtoDisplayInnerCon + '</tbody></table>';
        endMsg += messgtoDisplayEnd;
      }

      this.compliancePendingValidationCount =
        pmessgae?.compliancePendingValidationCount ?? 0;
      this.demographicsPendingValidationCount =
        pmessgae?.demographicsPendingValidationCount ?? 0;
      this.initialFaceToFacePendingValidationCount =
        pmessgae?.initialFaceToFacePendingValidationCount ?? 0;
      this.prescriptionPendingValidationCount =
        pmessgae?.prescriptionPendingValidationCount ?? 0;
      this.sleepStudyPendingValidationCount =
        pmessgae?.sleepStudyPendingValidationCount ?? 0;
      this.verificationPendingValidationCount =
        pmessgae?.verificationPendingValidationCount ?? 0;
      iconLogo = 'info';
      this.percentageOfCompletion =
        isNaN(+pmessgae?.percentageOfCompletion ?? 0) === true
          ? 0
          : pmessgae?.percentageOfCompletion ?? 0;
      type = 1; //! for Tabular swal
      btntxt = 'Ignore and continue!';
    } else {
      type = 0;
      iconLogo = 'error';
      endMsg = String(message) ?? '';
      btntxt = btntxt;
    }
    return { msg: endMsg, type: type, iconLogo: iconLogo, btntxt: btntxt };
  }

  // AuthorizationArr: Auth

  // get values form Demographics
  getDemographicsValues() {
    return this.PreviewExtractionForm.value.Demographics;
  }

  // get values form faceToface
  getFaceToFaceValues() {
    return this.PreviewExtractionForm.value ?? [];
  }

  // get values form sleepStudy
  getSleepStudyValues() {
    return this.PreviewExtractionForm.value.sleepStudy ?? [];
  }

  // get values form Complience
  getComplienceValues() {
    return this.PreviewExtractionForm.value.Complience ?? [];
  }

  // get values form priscription
  getPriscriptionValues() {
    return this.PreviewExtractionForm.value.priscription ?? [];
  }

  //! To Displays currently open accordion with corresponding document page
  currentlyOpenAcc(accName: string) {
    switch (accName) {
      case 'DEMOGRAPHICS':
        {
          this.pdfHeight = '80vh';
        }
        break;
      case 'FACE TO FACE':
        {
          this.pdfHeight = '130vh';
        }
        break;
      case 'SLEEP STUDY REPORT':
        {
          this.pdfHeight = '170vh';
        }
        break;
      case 'RX':
        {
          this.pdfHeight = '130vh';
        }
        break;
      case 'COMPLIANCE REPORT':
        {
          this.pdfHeight = '90vh';
        }
        break;
      case 'INSURANCE VERIFICATION':
        {
          this.pdfHeight = '80vh';
        }
        break;
      case 'AUTHORIZATION':
        {
          this.pdfHeight = '80vh';
        }
        break;
      case 'SALEORDER':
        {
          this.pdfHeight = '80vh';
        }
        break;
      default:
        this.pdfHeight = '80vh';
    }

    const currentPageIndex: number =
      this.lstIndex?.find((value: SortedDataDTO) => {
        return value?.documentType === accName;
      })?.pageNumber || 1;
    this.pageNumber = currentPageIndex ?? 0;
    this.getRenderedPageNumber(this.pageNumber);
  }

  //! get render page number
  getRenderedPageNumber(value: number) {
    this.strDocumentType = '';
    this.PreviewExtractionForm?.patchValue({
      drpDocumentType: '',
    });
    if (value && value && this.lstIndex && this.drpDocumentTypeLoop) {
      this.strDocumentType =
        this.lstIndex?.filter((i) => i?.pageNumber === value)[0]
          ?.documentType ?? '';
      const strDocumentType = this.drpDocumentTypeLoop.filter(
        (t) =>
          t?.documentType?.toLocaleLowerCase() ===
          this.strDocumentType?.toLocaleLowerCase()
      )[0]?.documentType;
      this.PreviewExtractionForm?.patchValue({
        drpDocumentType: strDocumentType,
      });
      this.pageNumber = value;
    }
  }

  // demographics input values as array
  get f() {
    return this.PreviewExtractionForm.controls;
  }
  get t() {
    return this.f.Demographics as FormArray;
  }

  // faceToface input values as array
  get a() {
    return this.PreviewExtractionForm.controls;
  }
  get b() {
    return this.a.faceToface as FormArray;
  }

  // sleepStudy input values as array
  get c() {
    return this.PreviewExtractionForm.controls;
  }
  get d() {
    return this.c.sleepStudy as FormArray;
  }

  // priscription input values as array
  get g() {
    return this.PreviewExtractionForm.controls;
  }
  get h() {
    return this.g.priscription as FormArray;
  }

  // Complience input values as array
  get i() {
    return this.PreviewExtractionForm.controls;
  }
  get j() {
    return this.i?.Complience as FormArray;
  }

  // Insurance input values as array
  get l() {
    return this.PreviewExtractionForm.controls;
  }
  get m() {
    return this.l.Insurance as FormArray;
  }

  //! Display extracted data of file in form
  displayExtractedDataofFile(pateintData: PatientDTO) {
    let patientArr: any[] = [];
    let patientPushArr: any[] = [];

    let clinicalArr: any[] = [];
    let clinicalPushArr: any[] = [];

    let FaceToFaceArr: any[] = [];
    let FaceToFacePushArr: any[] = [];

    let SleepArr: any[] = [];
    let SleepPushArr: any[] = [];

    let ComplienceArr: any[] = [];
    let CompliencePushArr: any[] = [];

    const responseHeightFeet =
      pateintData?.clinicals?.height === 0
        ? ''
        : String(pateintData?.clinicals?.height).split('.', 2)[0] ?? '';

    //! demo details initial patch
    this.demo().clear();
    if (pateintData?.personals) {
      patientArr?.push(pateintData?.personals);
      patientArr?.map((d) => {
        let newDemoData = {
          txtFirstName: d?.firstName,
          txtDOB: d?.dateOfBirth,
          txtMiddleName: d?.middleNamen,
          txtLastName: d?.lastName,
          drpGeneralGender: d?.gender,
        };
        patientPushArr?.push(newDemoData);
        var demo: FormGroup = this.newDemo();
        this.demo()?.push(demo);
      });
      this.PreviewExtractionForm?.patchValue({ Demographics: patientPushArr });
      //! prescription details initial patch
      this.Prescription().clear();
      clinicalArr?.push(pateintData?.clinicals);
      clinicalArr?.map((d) => {
        let newDemoData = {
          txtPatientName2: pateintData?.personals.fullName,
          txtDOB2: pateintData?.personals.dateOfBirth,
          txtPrescriptionDate: d?.prescriptionDate,
          txtprescriptionsDoctorSign: d?.doctorSignature,
          txtDoctor: d?.orderingDoctor,
          txtNpiNumber: d?.npiNumber,
          txtSignedDate: d?.doctorSignedDate,
          txtDuration: d?.duration,
          txtEstimatedLength: d?.estimatedLength,
          txtSupplierName: d?.supplierName,
          txtdiagnosisCode10Id1: d?.diagnosisCode10,
        };
        clinicalPushArr?.push(newDemoData);
        var Prescription: FormGroup = this.newPrescription();
        this.Prescription()?.push(Prescription);
      });
      this.PreviewExtractionForm?.patchValue({ priscription: clinicalPushArr });
      this.FaceToFace().clear();
      //! Face to face details initial patch
      FaceToFaceArr?.push(pateintData?.clinicals);
      FaceToFaceArr?.map((d) => {
        let newDemoData = {
          txtDateofVisit: d?.dateOfVisit,
          txtPatientinfo: pateintData?.personals.fullName,
          txtHeightFeet: responseHeightFeet,
          txtWeight: d?.weight,
          txtDoctor: d?.orderingDoctor,
          txtNpiNumber: d?.npiNumber,
          txtSignature: d?.doctorSignature,
          txtSignedDate: d?.doctorSignedDate,
        };
        FaceToFacePushArr?.push(newDemoData);
        var FaceToFace: FormGroup = this.newFaceToFace();
        this.FaceToFace()?.push(FaceToFace);
      });
      this.PreviewExtractionForm?.patchValue({ faceToface: FaceToFacePushArr });

      //! sleep study details initial patch
      SleepArr?.push(pateintData?.clinicals);
      this.sleepStudies().clear();

      SleepArr?.map((d) => {
        let newDemoData = {
          txtPatientName1: pateintData?.personals.fullName,
          txtDob: pateintData?.personals.dateOfBirth,
          txtdiagnosisCode10Id: d?.diagnosisCode10,
        };
        SleepPushArr?.push(newDemoData);
        var sleepStudies: FormGroup = this.newsleepStudies();
        this.sleepStudies()?.push(sleepStudies);
      });
      this.PreviewExtractionForm?.patchValue({ sleepStudy: SleepPushArr });

      //! Complience details initial patch
      ComplienceArr?.push(pateintData?.clinicals);
      this.Complience().clear();

      ComplienceArr?.map(() => {
        let newDemoData = {
          txtPatientName3: pateintData?.personals.fullName,
          txtDOB3: pateintData?.personals.dateOfBirth,
        };
        CompliencePushArr?.push(newDemoData);
        var Complience: FormGroup = this.newComplience();
        this.Complience()?.push(Complience);
      });
      this.PreviewExtractionForm?.patchValue({ Complience: CompliencePushArr });

      this.patientData = pateintData ?? null;
    }
  }

  //! get PatientId Form Patient Details
  getPatientIdFormPatientDetails(_patientId: string) {
    // this.patientService.get(this.patientId).subscribe(response => {
    //   let pateintData: { fname: string, mname: string, lname: string, dob: string, gender: string } | null = {
    //     fname: response?.personals?.firstName,
    //     lname: response?.personals?.lastName,
    //     mname: response?.personals?.middleName,
    //     gender: response?.personals?.gender,
    //     dob: response?.personals?.dateOfBirth
    //   };
    //   this.displayEmptyDataofFile(pateintData);
    // }, (err) => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'info',
    //     text: data?.error?.error?.message,
    //   });
    // });

    // New API call
    this.PatientPersonalService.get(this.patientId).subscribe(
      (response) => {
        let pateintData: {
          fname: string;
          mname: string;
          lname: string;
          dob: string;
          gender: string;
        } | null = {
          fname: response?.firstName,
          lname: response?.lastName,
          mname: response?.middleName,
          gender: response?.gender,
          dob: response?.dateOfBirth,
        };
        this.displayEmptyDataofFile(pateintData);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  //! Display empty data in form
  displayEmptyDataofFile(patientData: {
    fname: string;
    mname: string;
    lname: string;
    dob: string;
    gender: string;
  }) {
    let patientPushArr: any[] = [];
    let presArr: any[] = [];
    let presPushArr: any[] = [];
    let FaceToFacePushArr: any[] = [];
    let SleepPushArr: any[] = [];
    let CompliencePushArr: any[] = [];
    // demo details initial patch
    // let newDemoData = {
    //   txtFirstName: patientData?.fname ?? "",
    //   txtDOB: patientData?.dob ?? "",
    //   txtMiddleName: patientData?.mname,
    //   txtLastName: patientData?.lname ?? "",
    //   drpGeneralGender: patientData?.gender
    // };
    this.checkRulesForFeildHighlight(this.rulesValues, SetupTypes.New, DXTypes.OSA);
    // this.demo().clear();

    // patientPushArr?.push(newDemoData);
    // var demo: FormGroup = this.newDemo();
    // this.demo()?.push(demo);
    // this.PreviewExtractionForm?.patchValue({ Demographics: patientPushArr });

    // prescription details initial patch

    let newPresData = {
      txtPatientName2:
        (patientData?.fname ?? '') + '' + (patientData?.lname ?? ''),
      txtDOB2: patientData?.dob ?? '',
      txtPrescriptionDate: null,
      txtprescriptionsDoctorSign: '',
      txtDoctor: '',
      txtNpiNumber: '',
      txtSignedDate: null,
      txtDuration: '',
      txtEstimatedLength: '',
      txtSupplierName: '',
      txtdiagnosisCode10Id1: '',
    };

    presArr?.push(newPresData);
    this.Prescription().clear();

    var Prescription: FormGroup = this.newPrescription();
    this.Prescription()?.push(Prescription);
    this.PreviewExtractionForm?.patchValue({ priscription: presPushArr });

    // Face to face details initial patch

    let newf2fdata = {
      txtFobPatientName:
        (patientData?.fname ?? '') + '' + (patientData?.lname ?? ''),
      txtIobDob: patientData?.dob ?? '',
      txtDateofVisit: '',
      txtPatientinfo: '',
      txtHeightFeet: '',
      txtWeight: '',
      txtDoctor: '',
      txtNpiNumber: '',
      txtSignature: '',
      txtSignedDate: null,
      chkPrevioushistory: '',
      txtIobFadeToFace: '',
      txtTreatment: '',
      chkSleepTestRecommended: '',
      chkSleepDoctorSigned: '',
      chkSleepPatientBenifited: '',
      txtFobPatientBenifitNotes: '',
      drpSymptoms: [],
    };
    FaceToFacePushArr?.push(newf2fdata);

   //  var PreviewExtractionForm: FormGroup = this.newFaceToFace();



    var FaceToFace: FormGroup = this.newFaceToFace();
    this.FaceToFace().clear();

    this.FaceToFace()?.push(FaceToFace);
    this.PreviewExtractionForm?.patchValue({ faceToface: FaceToFacePushArr });
    const originalDate = FaceToFacePushArr[0]?.txtIobDob;
    const date = new Date(originalDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    let formattedDate = `${year}-${month}-${day}`;


    // this.PreviewExtractionForm?.patchValue({
    //   txtFobPatientName: FaceToFacePushArr[0]?.txtFobPatientName,
    //   txtIobDob: '2023-05-20',
    // });

    // this.PreviewExtractionForm?.patchValue({  FaceToFacePushArr });

    // this.faceToface?.patchValue({
    //   txtFobPatientName: FaceToFacePushArr[0]?.txtFobPatientName,
    //   txtIobDob: formattedDate,
    // });

    // this.sleepStudyReport?.patchValue({
    //   txtPatientName1: FaceToFacePushArr[0]?.txtFobPatientName,
    //   txtDob: formattedDate,
    // });

    this.PreviewExtractionForm?.patchValue({
      txtFobPatientName: FaceToFacePushArr[0]?.txtFobPatientName,
      txtIobDob: formattedDate,
      drpSymptoms : [''],
      txtPatientName1: FaceToFacePushArr[0]?.txtFobPatientName,
      txtDob: formattedDate,
         txtPatientName2: FaceToFacePushArr[0]?.txtFobPatientName,
      txtDOB2: formattedDate,
    });


    // this.rx?.patchValue({
    //   txtPatientName2: FaceToFacePushArr[0]?.txtFobPatientName,
    //   txtDOB2: formattedDate,
    // });

    this.complianceReport?.patchValue({
      txtPatientName3: FaceToFacePushArr[0]?.txtFobPatientName,
      txtDOB3: formattedDate,
    });

    //! sleep study details initial patch

    let newSleepData = {
      txtPatientName1:
        (patientData?.fname ?? '') + '' + (patientData?.lname ?? ''),
      txtDob: patientData?.dob ?? '',
      txtdiagnosisCode10Id: '',
      txtPressureSetting: '',
      txtSleepMinutes: '',
      txtREI: '',
      txtRDI: '',
      txtAHI: '',
      txtDiagnosisStudyDate: '',
      chkSleepStudyDoctorSigned: '',
      txtSSCentralApneas: '',
      txtSSCentralHyponeas: '',
      // txtSSCentralAHI: "",
      txtSSApneaAndHypopnea: '',
      txtTitrationSleepStudyDate: '',
      chkCPAPTriedAndFail: '',
      chkRespiratoryBreakdownAvailable: '',
      chkTitrationSleepStudyDoctorSigned: '',
      chkisCPAPReturned: '',
      chkTSSBaselineFile: '',
      txtObstructiveApnea: '',
      txtTAHI: '',
      txtTCentralApneas: '',
      txtTCentralHyponeas: '',
      txtTApneaAndHypopnea: '',
      txtHomeSleepStudyDate: '',
      txtHSMinutes: '',
      txtHSAHI: '',
      chkHSDoctorSigned: '',
      txtHSCentralApneas: '',
      txtHSCentralHyponeas: '',
      // txtHSCentralAHI: "",
      txtHSApneaAndHypopnea: '',
      chkHSpateintSigned: '',
    };
    this.sleepStudies().clear();
    SleepPushArr?.push(newSleepData);
    var sleepStudies: FormGroup = this.newsleepStudies();
    this.sleepStudies()?.push(sleepStudies);

    this.PreviewExtractionForm?.patchValue({ sleepStudy: SleepPushArr });

    //! Complience details initial patch
    let newCompData = {
      txtPatientName3:
        (patientData?.fname ?? '') + '' + (patientData?.lname ?? ''),
      txtDOB3: patientData?.dob ?? '',
      txtStudyStartDate: '',
      txtStudyEndDate: '',
      rbnComplianceMet: '',
      txtCompliancePercentage: '',
      txtUsagedays: '',
      txtUsagedaysOutOf: '',
      txtGreater4Hours: '',
      txtLess4hours: '',
    };

    CompliencePushArr?.push(newCompData);
    var Complience: FormGroup = this.newComplience();
    this.Complience().clear();

    this.Complience()?.push(Complience);
    this.PreviewExtractionForm?.patchValue({ Complience: CompliencePushArr });

    // this.patientData = pateintData ?? null;
  }

  // demographics form control return
  demo() {
    return this.PreviewExtractionForm?.get('Demographics') as FormArray;
  }
  newDemo(): FormGroup {
    return this.fb.group({
      txtFirstName: ['', this.textValidators.isTextCheck],
      txtDOB: [''],
      txtMiddleName: [''],
      txtLastName: ['', this.textValidators.isTextCheck],
      drpGeneralGender: [''],
      txtReason: [''],
    });
  }

  // sleepstudy form control return
  sleepStudies() {
    return this.PreviewExtractionForm?.get('sleepStudy') as FormArray;
  }
  newsleepStudies(): FormGroup {
    return this.fb.group({
      txtPatientName1: ['', this.textValidators.isTextCheck],
      txtDob: [''],
      txtSleepMinutes: [''],
      txtREI: ['', this.numberValidators.isNumberCheck],
      txtRDI: ['', this.numberValidators.isNumberCheck],
      txtAHI: ['', [this.numberValidators.isNumberCheck]],
      txtdiagnosisCode10Id: [''],
      txtdiagnosisCode10I1: [''],
      txtPressureSetting: [''],
      txtStudyDate: [''],
      //! new
      txtDiagnosisStudyDate: [''],
      chkSleepStudyDoctorSigned: [''],
      txtSSCentralApneas: [''],
      txtSSCentralHyponeas: [''],
      // txtSSCentralAHI: [''],
      txtSSApneaAndHypopnea: [''],
      txtTitrationSleepStudyDate: [''],
      txtTAHI: [''],
      txtTCentralApneas: [''],
      txtTCentralHyponeas: [''],
      txtTApneaAndHypopnea: [''],

      chkCPAPTriedAndFail: [''],
      chkRespiratoryBreakdownAvailable: [''],
      chkTitrationSleepStudyDoctorSigned: [''],
      chkisCPAPReturned: [''],

      chkTSSBaselineFile: [''],
      txtObstructiveApnea: [''],
      txtHSMinutes: [''],
      txtHSAHI: [''],
      chkHSDoctorSigned: [''],
      txtHSCentralApneas: [''],
      txtHSCentralHyponeas: [''],
      //  txtHSCentralAHI: [''],
      txtHSApneaAndHypopnea: [''],
      chkHSpateintSigned: [''],
      txtHomeSleepStudyDate: [''],
    });
  }

  // Complience form control return
  Complience() {
    return this.PreviewExtractionForm?.get('Complience') as FormArray;
  }
  newComplience(): FormGroup {
    return this.fb.group({
      txtPatientName3: ['', this.textValidators.isTextCheck],
      txtDOB3: [''],
      txtStudyStartDate: [''],
      txtStudyEndDate: [''],
      rbnComplianceMet: [''],
      txtCompliancePercentage: [''],
      txtUsagedays: [''],
      txtUsagedaysOutOf: [''],
      txtGreater4Hours: [''],
      txtLess4hours: [''],
    });
  }

  //! highlist feilds based on rules
  checkRulesForFeildHighlight(
    _rulesValues: MasterRulesDTO[],
    drpPrdSetupType: string,
    drpDXType: string
  ) {
    this.reminderService?.clear();
    this.ruleColor = null;
    this.selectedRules = [];
    this.rulesValues.forEach((e) => {
      if (
        e?.setupType === SetupTypes.New &&
        drpPrdSetupType === SetupTypes.New &&
        e?.dxType === DXTypes.OSA &&
        drpDXType === DXTypes.OSA
      ) {
        if (e?.ruleName === 'Symptoms of sleep apnea is must in face to face') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName1: 'drpSymptoms',
            erStatus1: e?.isCheckValidation ?? false,
            errValue1: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName === 'Sleep test recommendation is must in face to face'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName2: 'chkSleepTestRecommended',
            erStatus2: e?.isCheckValidation ?? false,
            errValue2: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Doctor Signature in face to face is must') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName3: 'chkSleepDoctorSigned',
            erStatus3: e?.isCheckValidation ?? false,
            errValue3: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'Diagnostic Sleep study date is must after the Face to Face'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName4: 'txtDiagnosisStudyDate',
            erStatus4: e?.isCheckValidation ?? false,
            errValue4: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Total sleep time more than 120') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName5: 'txtSleepMinutes',
            erStatus5: e?.isCheckValidation ?? false,
            errValue5: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'AHI value should be greater than or equal to 5') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName6: 'txtAHI',
            erStatus6: e?.isCheckValidation ?? false,
            errValue6: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName6: 'txtHSAHI',
            erStatus6: e?.isCheckValidation ?? false,
            errValue6: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Doctor Signature in diagnostic sleep study') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName7: 'chkSleepStudyDoctorSigned',
            erStatus7: e?.isCheckValidation ?? false,
            errValue7: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'CPAP tried and failed must in titration sleep study for BIPAP product'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName8: 'chkCPAPTriedAndFail',
            erStatus8: e?.isCheckValidation ?? false,
            errValue8: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Respiratory breakdowns is must') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName9: 'chkRespiratoryBreakdownAvailable',
            erStatus9: e?.isCheckValidation ?? false,
            errValue9: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Doctor signature in titration study is must') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName10: 'chkTitrationSleepStudyDoctorSigned',
            erStatus10: e?.isCheckValidation ?? false,
            errValue10: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'Patient Signature in Home Sleep test instruction sheet'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName11: 'chkHSpateintSigned',
            erStatus11: e?.isCheckValidation ?? false,
            errValue11: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'RX date after sleep study date') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName12: 'txtPrescriptionDate',
            erStatus12: e?.isCheckValidation ?? false,
            errValue12: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Face to face date is valid for 365 days') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName13: 'txtIobFadeToFace',
            erStatus13: e?.isCheckValidation ?? false,
            errValue13: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Sleep study date should be valid for 365 days') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName14: 'txtDiagnosisStudyDate',
            erStatus14: e?.isCheckValidation ?? false,
            errValue14: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
      } else if (
        e?.setupType === SetupTypes.Replacement &&
        drpPrdSetupType === SetupTypes.Replacement &&
        e?.dxType === DXTypes.OSA &&
        drpDXType === DXTypes.OSA
      ) {
        if (
          e?.ruleName === 'Current Face to Face date is valid within one year'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName15: 'txtIobFadeToFace',
            erStatus15: e?.isCheckValidation ?? false,
            errValue15: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'Usage and benefits of PAP needs to be mentioned is must in face to face'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName16: 'chkSleepPatientBenifited',
            erStatus16: e?.isCheckValidation ?? false,
            errValue16: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Doctor signature is must in face to face') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName17: 'chkSleepDoctorSigned',
            erStatus17: e?.isCheckValidation ?? false,
            errValue17: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName === 'Doctor signature in diagnostic sleep study is must'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName18: 'chkSleepStudyDoctorSigned',
            erStatus18: e?.isCheckValidation ?? false,
            errValue18: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'RX after the current face to face notes') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName19: 'txtPrescriptionDate',
            erStatus19: e?.isCheckValidation ?? false,
            errValue19: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
      } else if (
        e?.setupType === SetupTypes.New &&
        e?.setupType === drpPrdSetupType &&
        e?.dxType === DXTypes.CSA &&
        drpDXType === DXTypes.CSA
      ) {
        if (e?.ruleName === 'Symptoms of sleep apnea is must in face to face') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName20: 'drpSymptoms',
            erStatus20: e?.isCheckValidation ?? false,
            errValue20: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName === 'Sleep test recommendation is must in face to face'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName21: 'chkSleepTestRecommended',
            erStatus21: e?.isCheckValidation ?? false,
            errValue21: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Doctor Signature in face to face is must') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName22: 'chkSleepDoctorSigned',
            erStatus22: e?.isCheckValidation ?? false,
            errValue22: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Diagnostic sleep study is valid for 365 days') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName23: 'txtDiagnosisStudyDate',
            erStatus23: e?.isCheckValidation ?? false,
            errValue23: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'AHI value is greater than or equal to 5') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName24: 'txtAHI',
            erStatus24: e?.isCheckValidation ?? false,
            errValue24: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName25: 'txtHSAHI',
            erStatus25: e?.isCheckValidation ?? false,
            errValue25: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'Sum of central apneas and central hypopneas needs to be greater than 50% of total apneas and hypopneas'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName27: 'txtSSCentralApneas',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName27: 'txtSSCentralHyponeas',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName27: 'txtSSApneaAndHypopnea',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName26: 'txtHSCentralApneas',
            erStatus26: e?.isCheckValidation ?? false,
            errValue26: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName26: 'txtHSCentralHyponeas',
            erStatus26: e?.isCheckValidation ?? false,
            errValue26: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName26: 'txtHSApneaAndHypopnea',
            erStatus26: e?.isCheckValidation ?? false,
            errValue26: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'Sum of central apnea and central hypopnea (central AHI) value needs to be greater than or equal to 5'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName27: 'txtSSCentralApneas',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName27: 'txtSSCentralHyponeas',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName27: 'txtSSApneaAndHypopnea',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName26: 'txtHSCentralApneas',
            erStatus26: e?.isCheckValidation ?? false,
            errValue26: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName26: 'txtHSCentralHyponeas',
            erStatus26: e?.isCheckValidation ?? false,
            errValue26: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName26: 'txtHSApneaAndHypopnea',
            erStatus26: e?.isCheckValidation ?? false,
            errValue26: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'RX date after sleep study date') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName28: 'txtPrescriptionDate',
            erStatus28: e?.isCheckValidation ?? false,
            errValue28: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Face to face date should be valid for 365 days') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName40: 'txtIobFadeToFace',
            erStatus40: e?.isCheckValidation ?? false,
            errValue40: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName === 'Doctor Signature in diagnostic sleep study is must'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName44: 'txtPrescriptionDate',
            erStatus44: e?.isCheckValidation ?? false,
            errValue44: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
      } else if (
        e?.setupType === SetupTypes.Replacement &&
        e?.setupType === drpPrdSetupType &&
        drpDXType === e?.dxType &&
        e?.dxType === DXTypes.CSA
      ) {
        if (e?.ruleName === 'Face to face notes valid only within 365 days') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName29: 'txtIobFadeToFace',
            erStatus29: e?.isCheckValidation ?? false,
            errValue29: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'Benefits and usage of PAP should be mentioned in face to face'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName30: 'chkSleepPatientBenifited',
            erStatus30: e?.isCheckValidation ?? false,
            errValue30: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Doctor signature is must in face to face') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName31: 'chkSleepDoctorSigned',
            erStatus31: e?.isCheckValidation ?? false,
            errValue31: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'AHI value should be greater than or equal to 5') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName32: 'txtAHI',
            erStatus32: e?.isCheckValidation ?? false,
            errValue32: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName32: 'txtHSAHI',
            erStatus32: e?.isCheckValidation ?? false,
            errValue32: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'Sum of central apneas and central hypopneas needs to be greater than 50% of total apneas and hypopneas'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName27: 'txtSSCentralApneas',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName27: 'txtSSCentralHyponeas',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName27: 'txtSSApneaAndHypopnea',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName26: 'txtHSCentralApneas',
            erStatus26: e?.isCheckValidation ?? false,
            errValue26: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName26: 'txtHSCentralHyponeas',
            erStatus26: e?.isCheckValidation ?? false,
            errValue26: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName26: 'txtHSApneaAndHypopnea',
            erStatus26: e?.isCheckValidation ?? false,
            errValue26: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'Sum of central apnea and central hypopnea (central AHI) value needs to be greater than or equal to 5'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName27: 'txtSSCentralApneas',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName27: 'txtSSCentralHyponeas',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName27: 'txtSSApneaAndHypopnea',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName26: 'txtHSCentralApneas',
            erStatus26: e?.isCheckValidation ?? false,
            errValue26: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName26: 'txtHSCentralHyponeas',
            erStatus26: e?.isCheckValidation ?? false,
            errValue26: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName26: 'txtHSApneaAndHypopnea',
            erStatus26: e?.isCheckValidation ?? false,
            errValue26: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName === 'Doctor signature is must in diagnostic sleep study'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName35: 'chkSleepStudyDoctorSigned',
            erStatus35: e?.isCheckValidation ?? false,
            errValue35: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'RX date after face to face date') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName36: 'txtPrescriptionDate',
            erStatus36: e?.isCheckValidation ?? false,
            errValue36: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
      } else if (e?.dxType === DXTypes.CPAPtoBIPAP && e?.dxType === drpDXType) {
        if (
          e?.ruleName === 'Titration sleep study date is valid for 365 days'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName37: 'txtTitrationSleepStudyDate',
            erStatus37: e?.isCheckValidation ?? false,
            errValue37: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'CPAP tried and ineffective for patient mentioned in titration sleep study'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName38: 'chkCPAPTriedAndFail',
            erStatus38: e?.isCheckValidation ?? false,
            errValue38: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'Respiratory breakdowns is must in titration sleep study'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName39: 'chkRespiratoryBreakdownAvailable',
            erStatus39: e?.isCheckValidation ?? false,
            errValue39: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Face to face date is Valid for 365 days') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName40: 'txtIobFadeToFace',
            erStatus40: e?.isCheckValidation ?? false,
            errValue40: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'Baseline file is must') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName41: 'chkTSSBaselineFile',
            erStatus41: e?.isCheckValidation ?? false,
            errValue41: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (e?.ruleName === 'RX date should be Valid for 365 days') {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName42: 'txtPrescriptionDate',
            erStatus42: e?.isCheckValidation ?? false,
            errValue42: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName === 'Face to face notes must prior to sleep study and RX'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName43: 'txtIobFadeToFace',
            erStatus43: e?.isCheckValidation ?? false,
            errValue43: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
      } else if (e?.dxType === DXTypes.CompSA && e?.dxType === drpDXType) {
        if (
          e?.ruleName === 'Titration sleep study date is valid for 365 days'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName45: 'txtTitrationSleepStudyDate',
            erStatus45: e?.isCheckValidation ?? false,
            errValue45: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'AHI value is greater than or equal to 5 in titration study'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName32: 'txtAHI',
            erStatus32: e?.isCheckValidation ?? false,
            errValue32: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName32: 'txtHSAHI',
            erStatus32: e?.isCheckValidation ?? false,
            errValue32: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName32: 'txtTAHI',
            erStatus32: e?.isCheckValidation ?? false,
            errValue32: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'Sum of central apneas and central hypopneas needs to be greater than 50 % of total apneas and hypopneas in titration study'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName27: 'txtTCentralApneas',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName27: 'txtTApneaAndHypopnea',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName27: 'txtTCentralHyponeas',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'Sum of central apnea and central hypopnea (central AHI) value needs to be greater than or equal to 5 in titration study'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName27: 'txtTCentralApneas',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName27: 'txtTApneaAndHypopnea',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
          this.ruleColor = {
            ctrlName27: 'txtTCentralHyponeas',
            erStatus27: e?.isCheckValidation ?? false,
            errValue27: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
        if (
          e?.ruleName ===
          'Obstructive apnea should be less than 5 in titration study'
        ) {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = {
            ctrlName49: 'txtObstructiveApnea',
            erStatus49: e?.isCheckValidation ?? false,
            errValue49: e?.ruleName ?? '',
            ...this.ruleColor,
          };
        }
      }
    });

    if (this.selectedRules.length === 0) {
      this.hideFullNotificataion = false;
    }
  }

  //// clear all notification
  clearNotificationData() {
    if (this.selectedRules.length !== 0) {
      of(this.selectedRules).subscribe(() => {
        this.selectedRules.shift();
        this.clearNotificationData();
      });
      this.hideNotificatiaonButton = false;
    }
  }

  //#endregion
}

export interface DocumentGroupIngListType {
  documentType: string;
  documentGroupedByType: string;
  groupItem: PatientDocumentBlobDTO[];
}

export interface DocumentListType {
  strDocumentName: string;
  dtDocumentDate: string;
  strDocumentPath: string;
  isSelected: boolean;
}
/** Flat node with expandable and level information */
interface DocumentTreeNode {
  expandable: boolean;
  name: string;
  level: number;
  documentCount: number;
  blobName: string;
  uploadedDate: string;
}

export interface DocumentGroupDTO1 {
  documentType: string;
  documentCount?: number;
  blobName?: string;
  uploadedDate?: string;
  documentDetail?: DocumentDetail[];
}

export interface DocumentDetail {
  documentType: string;
  documentId?: string;
  blobName?: string;
  fileName?: any;
  uploadedDate?: string;
  documentCount?: number;
}
