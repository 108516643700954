<br />
<div class="card card-body">
    <!-- <mat-accordion #firstAccordion="matAccordion">
        <mat-expansion-panel [expanded]="step">

            <mat-expansion-panel-header>
                <mat-panel-title class="customThemeClass">
                    <ng-container *ngIf="mailConfigId===''; else elseTitleTemplate">
                        <b>Add Mail Configuration</b>
                    </ng-container>
                    <ng-template #elseTitleTemplate>
                        <b>Edit Mail Configuration</b>
                    </ng-template>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <form [formGroup]="mailConfig">
                <div class="row">
                    <mat-form-field class="col-4">
                        <mat-label>Fax Number <span class="asterisk">*</span> </mat-label>
                        <input matInput maxlength="40" mask="(000)-000-00000" placeholder="Enter Fax Number"
                            formControlName="txtFaxNumber" type="text">
                        <mat-error *ngIf="
                            mailConfig.get('txtFaxNumber')?.touched &&
                            mailConfig.get('txtFaxNumber')?.errors
                              ">
                            Enter Minimum 10 Digits!
                        </mat-error>
                        <mat-error *ngIf="mailConfig?.get('txtFaxNumber')?.touched &&
                        mailConfig?.get('txtFaxNumber')?.errors?.required ">
                            Fax Number is a required field!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                        <mat-label> Email Id <span class="asterisk">*</span></mat-label>
                        <input matInput maxlength="40" formControlName="txtEmail" type="text"
                            pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            [textMask]="{ mask: emailMask }" />

                        <mat-error *ngIf="mailConfig?.get('txtEmail')?.touched &&
                          mailConfig?.get('txtEmail')?.errors?.required ">
                            Email Address is a required field!
                        </mat-error>
                        <mat-error *ngIf="
                          mailConfig?.get('txtEmail')?.touched &&
                          mailConfig?.get('txtEmail')?.errors?.pattern
                          ">
                            Please Enter a Valid Email Address
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                        <mat-label> Password <span class="asterisk">*</span></mat-label>
                        <input matInput maxlength="40" [type]="hide ? 'password' : 'text'" formControlName="txtPassword"
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}" type="text" />
                        <a class="font-size-20" mat-icon-button matSuffix (click)="hide = !hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </a>
                        <mat-error *ngIf="
                            mailConfig?.get('txtPassword')?.touched &&
                            mailConfig?.get('txtPassword')?.errors?.required
                             ">
                            Password is a required field!
                        </mat-error>
                        <mat-error *ngIf="
                            mailConfig?.get('txtPassword')?.touched &&
                            mailConfig?.get('txtPassword')?.errors?.pattern
                          ">
                            Passwords must contain at least one non alphanumeric character,one uppercase,one digit
                            and at
                            least 8 or more characters
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                        <mat-label>Port Number <span class="asterisk">*</span> </mat-label>
                        <input matInput maxlength="15" pattern="[0-9]*" placeholder="Enter Port Number"
                            formControlName="txtPortNumber" type="text">
                        <mat-error *ngIf="
                            mailConfig.get('txtPortNumber')?.touched &&
                            mailConfig.get('txtPortNumber')?.errors?.pattern
                              ">
                            Enter Only Numbers!
                        </mat-error>
                        <mat-error *ngIf="mailConfig?.get('txtPortNumber')?.touched &&
                        mailConfig?.get('txtPortNumber')?.errors?.required ">
                            Port Number is a required field!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                        <mat-label>Host Name <span class="asterisk">*</span> </mat-label>
                        <input matInput maxlength="40" pattern="[A-Za-z0-9\.]*" formControlName="txtHostName"
                            type="text">
                        <mat-error *ngIf="
                            mailConfig.get('txtHostName')?.touched &&
                            mailConfig.get('txtHostName')?.errors?.pattern
                              ">
                            Enter Only Charcters and dots !
                        </mat-error>
                        <mat-error *ngIf="mailConfig?.get('txtHostName')?.touched &&
                        mailConfig?.get('txtHostName')?.errors?.required">
                            Host Name is a required field!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                        <mat-label>Server Protocol <span class="asterisk">*</span></mat-label>
                        <mat-select maxlength="40" formControlName="drpServerProtocol">
                            <mat-option *ngFor="let protocol of drpProtocol" [value]="protocol.name">
                                {{ protocol.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="mailConfig?.get('drpServerProtocol')?.touched &&
                        mailConfig?.get('drpServerProtocol')?.errors?.required ">
                            Server Protocol Type is a required field!
                        </mat-error>
                    </mat-form-field>

                    <div class="col-6">
                        <br />
                        <mat-checkbox formControlName="chkSsleEnable">SSL Enable <span class="asterisk">*</span>
                        </mat-checkbox>
                    </div>

                </div>
            </form>
            <br />
            <div class="row">
                <div class="col-sm-12">
                    <div class="text-lg-center">
                        <button mat-button class="buttonColor mr-2"
                            [disabled]="!( saveButtonHide ||!mailConfig.invalid && mailConfig.dirty )"
                            (click)="saveMaster()">
                            Save
                        </button>
                        <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="reset()">Reset</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion> -->
    <div class="row">
        <div class="col-12 table-responsive">
            <table mat-table class="table ml-4" *ngIf="isTableData == true" matSort style="border: 1px solid  #cdc7c7" [dataSource]="dataSource">
                <form style="display: flex" [formGroup]="mailConfigSearchForm">
                    <ng-container matColumnDef="branchName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-branchName" matInput formControlName="branchName" />
                                <mat-placeholder>Branch Name</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.branchName}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                        <td mat-cell *matCellDef="let data; let i = index">
                            {{ i+1 }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="faxNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-faxNumber" matInput formControlName="faxNumber" />
                                <mat-placeholder>Fax Number</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.faxNumber}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-name" matInput formControlName="name" />
                                <mat-placeholder>Name</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.name}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="emailId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-emailId" matInput formControlName="emailId" />
                                <mat-placeholder>Email Id</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.emailId}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="portNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-portNumber" matInput formControlName="portNumber" />
                                <mat-placeholder>Port Number</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.portNumber}}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="hostName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input autocomplete="new-hostName" matInput formControlName="hostName" />
                                <mat-placeholder>Host Name</mat-placeholder>
                            </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{data?.hostName}}
                            </ng-container>
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="Options">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
                        <td mat-cell *matCellDef="let data">
                            <a class="eyeCursorclass" (click)="ViewMaster(data)" target="_blank"><i
                                    class="fa fa-pencil editBtnColor mr-1"></i>
                            </a>
                            &nbsp;
                            <a class="deleteBtnColor" (click)="deleteMaster(data)">
                                <i class="fa fa-trash" ></i>
                            </a>
                        </td>
                    </ng-container> -->

                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="6">
                            <div *ngIf="!isLoading">
                                {{"No Records Found"}}
                            </div>
                            <div [hidden]="!isLoading">
                                <mat-spinner [diameter]="220"></mat-spinner>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="6">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>
                </form>
                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                    [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            </table>
        </div>
    </div>
</div>
<div class="row"></div>
