import { DOCUMENT, LocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { EventEmitter } from '@angular/core';
import { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { resourceUsage } from 'process';
import { PostingNotificationService } from 'projects/billing/src/app/billing-proxy/billing-management/posting/posting-notification.service';
import { ItemAuthorizationDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto';
import { ItemAuthorizationService } from 'projects/order/src/app/order-proxy/order-management/order/item-authorization.service';
import { PatientSearchService, PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import { misReportsUrl } from 'projects/patient/src/environments/environment';
import { TableService } from 'projects/shared/src/app/table.service';
import { map } from 'rxjs/operators';

import Swal from 'sweetalert2';

import { DashboardService } from '../admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { OrganizationUnitDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto/models';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { GetRoleListUsingTenantIdDto } from '../admin-proxy/platform-app-management/rcm/platform-management/role/models';
import { RoleService } from '../admin-proxy/platform-app-management/rcm/platform-management/role/role.service';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { ChatSignalrComponent } from '../chat-signalr/chat-signalr.component';
import { ClaimNotificationComponent } from '../claim-notification/claim-notification.component';
import { NotificationTrayComponent } from '../notification-tray/notification-tray.component';
import { ProductNotificationService } from '../warehouse-proxy/rcm/warehouse-management/warehouse/product-notification.service';
import { ProductStatus } from '../warehouse-proxy/rcm/warehouse-management/warehouse/product-status.enum';

import { WarehouseDashboardService } from '../warehouse-proxy/rcm/warehouse-management/warehouse/warehouse-dashboard.service';

import { ReminderService } from './reminder.service';
// import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { fromEvent, Subject, Subscription } from 'rxjs';

const currentDate = new Date();
const month = currentDate.getMonth();
const year = currentDate.getFullYear();

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],

})
export class DashboardComponent implements OnInit,OnDestroy {
  iswHouseHidden: boolean = false;
  claimCount: number;
  intNotCount: number = 0;
  userClaimCount: number = 0;
  organizationCount: number;
  patientCount: number;
  roleCount: number;
  saleOrderCount: Number;
  tenantCount: Number;
  userCount: number;
  tenantId: string;
  organizationId: string;
  authorizationCount: number;
  autoUploadDocumentCount: number;
  insuranceCount: number;
  manualUploadDocumentCount: number;
  totalRoleCount: number;
  totalUserCount: number;
  verificationCount: number;
  roleName: string;
  tenantHide: boolean = false;
  adminLoginHide: boolean = false;
  roleId: string = "";
  userId: string = "";
  tenantUserHide: boolean = false;
  organizationUnitId: string = "";
  LoginUserName: string = "";
  LoginUserTenenatName: string = "";
  LoginUserorganizationUnitName: string = "";
  arrOrganizationUnit: OrganizationUnitDTO[] = [];
  tenanatId: string = '' as string;
  lblTotalUserCount: number;
  lblOverallUserCount: number;
  lblPatientDetailCount: number;
  lblTodayOverallSaleOrder: number;
  lblNewClient: number;
  lblAuthorization: number;
  lblAutoUploadDoucmentCount: number;
  lblManualUploadDocumentCount: number;
  lblClaimCount: number;
  lbtCtPatientcount: number;
  lblCtSaleorderCount: number;
  lblCtTenantCount: number;
  lblInsuranceCount: number;
  lblOrganizationCount: number;
  lblPatientCount: number;
  lblRoleCount: number;
  lblSaleOrderCount: number;
  lblTenantCount: number;
  lblTotalRoleCount: number;
  lblTotalUsercount: number;
  // lblTotalUserCount: number;
  lblUserCount: number;
  lblVerificationCount: number;
  lblCurrentDayPatientCount: number;
  lblCurrentDaySaleOrderCount: number;
  iswHouseloading: boolean;
  showWareHouse: boolean = false;
  showUser: boolean = false;
  selectedRange: { startDate: Moment, endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';
  @Input() clientId: string = '';
  // dashboard warehouse variable declartions
  todayApprovedOrders: number;
  todayOrders: number;
  todayReadyToApproveOrders: number;
  todayRejectedOrders: number;
  totalApprovedOrders: number;
  totalBrands: number;
  totalCategories: number;
  totalInStockProducts: number;
  totalLowStockProducts: number;
  totalNoStockProducts: number = 0;
  totalOrders: number = 0;
  totalOutOfStockProducts: number;
  totalProductStocks: number;
  totalProducts: number;
  totalReadyToApproveOrders: number;
  totalRejectedOrders: number;
  totalVendors: number;
  showWarehouseDashboard: boolean = false;
  adminshowhide: boolean;
  warehouseUser: boolean;
  warehouseExecutive: boolean;
  dashBoardForm: FormGroup;
  // dashBoardThemeForm: FormGroup;
  subscription$ :Subscription[]=[];
  arrNotify: any[] = [];
  expiryMinimumDate: Date;
  dateFilterForm: FormGroup;

  constructor(@Inject(DOCUMENT) private document: any,

    //   private dashboardService: DashboardService,
    public userProfileService: UserService,
    private tableService: TableService,
    private router: Router,
    public organizationService: OrganizationUnitService,
    private dashboardService: DashboardService,
    private location: LocationStrategy,
    private dialog: MatDialog,
    private prodNotifService: ProductNotificationService,
    private reminderService: ReminderService,
    private roleService: RoleService,
    private warehouse: WarehouseDashboardService,
    private NotifService: PostingNotificationService,
    public patientSearchService: PatientSearchService,
    public patientService: PatientService,
    public itemAuthorizationService: ItemAuthorizationService,
    public formBuilder: FormBuilder,

  ) {

  }
  ngOnInit() {

    this.dateFilterForm = this.formBuilder.group({
      txtFromDate: new FormControl(new Date(year, month, 1)),
      txtToDate: new FormControl(new Date())
    })

    this.getDashboardCount();
    //this.getDashboardCount(Intl.DateTimeFormat('en-US').format(new Date(year, month, 1)), Intl.DateTimeFormat('en-US').format(new Date()));

    this.tableService.getAdminLogin().subscribe(val => {
      this.adminLoginHide = val;
      this.adminLoginHide == true && this.dashboardCount();
    });
    this.tableService.getClientLogin().subscribe(val => {
      this.tableService.getTenantId().subscribe(value => {
        this.tenanatId = value;

      });
      this.tenantHide = val;
      this.tenantHide == true && this.dashboardUserCount();
    });


    this.loadUserProfile();
    this.loadRoleDropdown();
    this.warehouseDashboard();
    this.txtThemeValue = localStorage.getItem('dataSource')
    this.dropdownColorChange();
    // BackbuttonDisable
    // BackButtonDisableModule.forRoot({
    //   preserveScrollPosition: true
    // })
  }

  // dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement){
  //   this.getDashboardCount(dateRangeStart.value, dateRangeEnd.value);
  // }

  // get txtFromDate(){ return this.dateFilterForm?.get('txtFromDate'); }
  // get txtToDate(){ return this.dateFilterForm?.get('txtToDate'); }

  navBarData: string = '';
  //color change patch value
  dropdownColorChange() {
    // this.tableService.getNavBarData().subscribe(value => {
    //   if (value == "" || value == null || value == undefined) {
    //     this.dashBoardThemeForm.patchValue({ txtTheme: this.colors[0].value })
    //   } else {
    //     this.dashBoardThemeForm.patchValue({ txtTheme: this.txtThemeValue })
    //   }
    // })
  }

  disableBrowserBackButton() {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }
  misReports() {
    window.open(misReportsUrl, '_blank');
  }
  // onTimeReports() {
  //   window.open(OnTimeUrl, '_blank');
  // }
  newPatient() {
    this.router.navigate(['patientCreateEdit'])
  }
  newClient() {
    this.router.navigate(['clientList'])
  }
  loadUserProfile() {
    this.dashboardService.userProfile().subscribe(response => {
      this.userId = response?.userId ?? "";
      this.userId && this.getNotificationUserCounts(this.userId);
      if (response?.roleName === 'Warehouse Manager') {
        this.showWareHouse = true;
      }
      // if((response?.roleName === 'admin' || response?.roleName !==('Warehouse Manager' || "Warehouse Executive" ||
      // 'Respiratory Therapist' || 'Scheduler')) && response?.tenantId !== null){
      //   this.showUser = true
      // }

      if ((response?.roleName === 'admin' ||
        response?.roleName !== 'Warehouse Manager' &&
        response?.roleName !== 'Warehouse Executive') &&
        response?.tenantId !== null) {

        this.showUser = true
      }
      this.showUser && this.getExpiryCounts();

      this.LoginUserName = response?.userName ?? "";
      this.showWareHouse = (response?.roleName === 'Warehouse Manager');
      this.adminshowhide = (response?.roleName === 'admin');
      this.warehouseUser = (response?.roleName !== 'admin' && response?.roleName !== 'Warehouse Manager');
      this.warehouseExecutive = (response?.roleName == 'Warehouse Executive');
      this.showWareHouse && this.getNotificationCounts();
      this.showWareHouse && this.getNotificationForPopup();
    }, err => {
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });
  }
   //! get Product Notifications Counts For non Read and Non dissmised notifys
   getNotificationCounts() {
    this.iswHouseloading = true;
    const getProductNotificationSub =  this.prodNotifService.getProductNotification().pipe(
      map(e => e?.items),
      map(e => e?.filter(e => e?.isDismissed === false)),
      map(e => e?.filter(e => e?.readStatus === false))
    ).subscribe(response => {
      this.reminderService?.clear();
      this.iswHouseHidden = true;
      this.iswHouseloading = false;
      this.intNotCount = response?.length ?? 0;
    }, err => {
      this.iswHouseHidden = true;
      this.iswHouseloading = false;

      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });
    this.subscription$.push(getProductNotificationSub);

  }
  /// for notification count
  getNotificationUserCounts(userId: string) {
    this.iswHouseloading = true;
    // this.userId = localStorage.getItem("userId") ?? "";
    const getNotificationByUserIdSub =   this.NotifService.getNotificationByUserId(userId).subscribe(response => {
      this.reminderService?.clear();
      let notifyList: any[] = response?.items ?? [];
      notifyList?.forEach(element => {
        let newDate = new Date(element?.completedOn);
        if (newDate > new Date()) {
          this.arrNotify.push({
            sub: element?.subject,
          })
          this.userClaimCount = this.arrNotify?.length ?? 0;
        }
      })
    }, err => {
      this.iswHouseHidden = true;
      this.iswHouseloading = false;

      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getNotificationByUserIdSub);

  }
  dashboardCount() {
    // const getCountSub =   this.dashboardService.getCount().subscribe(response => {
    //   this.lblCtTenantCount = response?.tenantCount;
    //   this.lblOrganizationCount = response?.organizationUnitCount;
    //   this.lblRoleCount = response?.roleCount;
    //   this.lblTotalRoleCount = response?.totalRoleCount;
    //   this.lblTotalUserCount = response?.totalUserCount;
    //   this.lblOverallUserCount = response?.userCount;
    //   this.lblPatientDetailCount = response?.patientCount;
    //   this.lblSaleOrderCount = response?.saleOrderCount;
    //   this.lblVerificationCount = response?.verificationCount
    //   this.lblInsuranceCount = response?.insuranceCount;
    //   this.lblManualUploadDocumentCount = response?.manualUploadDocumentCount;
    //   this.lblAutoUploadDoucmentCount = response?.autoUpladDocumentCount;
    //   this.lblAuthorization = response?.authorizationCount;
    //   this.lblClaimCount = response?.claimCount;
    //   this.lblCurrentDayPatientCount = response?.ctDayPatientCount;
    //   this.lblCurrentDaySaleOrderCount = response?.ctDaySaleOrderCount;

    //   // this.lblTodayOverallSaleOrder = response?.ctDaySaleOrderCount;
    //   // this.lblNewClient = response?.tenantCount;
    //   // this.lblAuthorization=response?.authorizationCount;
    //   // this.lbtCtPatientcount=response?.patientCount;
    //   // this. lblCtSaleorderCount=response?.saleOrderCount
    //   // this. lblTenantCount=response?.tenantCount
    //   // this.lblOverallUserCount = response?.userCount;
    //   // this.lblUserCount=response?.userCount;

    // });

    // this.subscription$.push(getCountSub);

    // this.dashboardService.getTenantBasedCountByTenantIdAndOrganizationUnitId(this.tenantId, this.organizationId).subscribe(val => {
    // }
    // )
  }
  dashboardUserCount() {
    // if (this.tenanatId !== null && this.tenanatId !== undefined && this.tenantId !== "") {
    //   const getCountByTenantByTenantIdSub =   this.dashboardService.getCountByTenantByTenantId(this.tenanatId).subscribe(response => {
    //     // this.lblCtTenantCount=response?.tenantCount;
    //     this.lblOrganizationCount = response?.organizationUnitCount;
    //     this.lblRoleCount = response?.roleCount;
    //     this.lblTotalRoleCount = response?.totalRoleCount;
    //     // this.lblTotalUserCount = response?.totalUserCount;
    //     this.lblOverallUserCount = response?.userCount;
    //     this.lblPatientDetailCount = response?.patientCount;
    //     this.lblSaleOrderCount = response?.orderCount;
    //     this.lblVerificationCount = response?.verificationCount;
    //     this.lblInsuranceCount = response?.insuranceCount;
    //     this.lblManualUploadDocumentCount = response?.manualUploadDocumentCount;
    //     this.lblAutoUploadDoucmentCount = response?.autoUpladDocumentCount;
    //     this.lblAuthorization = response?.authorizationCount;
    //     this.lblClaimCount = response?.claimCount;
    //     this.lblCurrentDayPatientCount = response?.ctDayPatientCount;
    //     this.lblCurrentDaySaleOrderCount = response?.ctDaySaleOrderCount;
    //   });
    // this.subscription$.push(getCountByTenantByTenantIdSub);

    // }
  }
  // dashboard for warehouse
  warehouseDashboard() {
    const getDashboardCountSub =  this.warehouse.getDashboardCount().subscribe(response => {
      this.todayApprovedOrders = response?.todayApprovedOrders ?? 0;
      this.todayOrders = response?.todayOrders ?? 0;
      this.todayReadyToApproveOrders = response?.todayReadyToApproveOrders ?? 0;
      this.todayRejectedOrders = response?.todayRejectedOrders ?? 0;
      this.totalApprovedOrders = response?.totalApprovedOrders ?? 0;
      this.totalBrands = response?.totalBrands ?? 0;
      this.totalCategories = response?.totalCategories ?? 0;
      this.totalInStockProducts = response?.totalInStockProducts ?? 0;
      this.totalLowStockProducts = response?.totalLowStockProducts ?? 0;
      this.totalNoStockProducts = response?.totalNoStockProducts ?? 0;
      this.totalOrders = response?.totalOrders;
      this.totalOutOfStockProducts = response?.totalOutOfStockProducts ?? 0;
      this.totalProductStocks = response?.totalProductStocks ?? 0;
      this.totalProducts = response?.totalProducts ?? 0;
      this.totalReadyToApproveOrders = response?.totalReadyToApproveOrders ?? 0;
      this.totalRejectedOrders = response?.totalRejectedOrders ?? 0;
      this.totalVendors = response?.totalVendors ?? 0;
      this.todayReadyToPackOrders = response?.todayReadyToPackOrders ?? 0;
      this.todayReadyToDeliverOrders = response?.todayReadyToDeliverOrders ?? 0;
      this.todayDeliveredOrders = response?.todayDeliveredOrders ?? 0;
      this.totalReadyToPackOrders = response?.totalReadyToPackOrders ?? 0;
      this.totalReadyToDeliverOrders = response?.totalReadyToDeliverOrders ?? 0;
      this.totalDeliveredOrders = response?.totalDeliveredOrders ?? 0;
    })
    this.subscription$.push(getDashboardCountSub);


  }

  // get dashboard for all box
  getDashboardCount() {

    const getDashboardDetailsSub =  this.dashboardService.getDashboardDetails().subscribe(response => {
      this.totalPatientCount = response?.totalPatientCount;
      this.totalVerificationCount = response?.totalVerificationCount;
      this.totalFileUploadCount = response?.totalFileUploadCount;
      this.totalSaleOrderCount = response?.totalSaleOrderCount;
      this.totalShippingCount = response?.totalShippingCount;
      this.totalBillingCount = response?.totalBillingCount;
      this.totalClaimCount = response?.totalClaimCount;
      this.totalDenialCount = response?.totalDenialCount;
      this.branchCount = response?.branchCount
      this.currentMonthAboutToExpireItemBasedAuthCount = response?.currentMonthAboutToExpireItemBasedAuthCount
      this.currentMonthActiveItemBasedAuthCount = response?.currentMonthActiveItemBasedAuthCount
      this.currentMonthClaimCount = response?.currentMonthClaimCount
      this.currentMonthDenialCount = response?.currentMonthDenialCount
      this.currentMonthExpireItemBasedAuthCount = response?.currentMonthExpireItemBasedAuthCount
      this.currentMonthFileUploadCount = response?.currentMonthFileUploadCount
      this.currentMonthInsuranceBasedAuthCount = response?.currentMonthInsuranceBasedAuthCount
      this.currentMonthItemBasedAuthCount = response?.currentMonthItemBasedAuthCount
      this.currentMonthPatientCount = response?.currentMonthPatientCount
      this.currentMonthSaleOrderCount = response?.currentMonthSaleOrderCount
      this.currentMonthSaleOrderToBillingPercentage = response?.currentMonthSaleOrderToBillingPercentage
      this.currentMonthVerificationCount = response?.currentMonthVerificationCount
      this.totalAboutToExpireItemBasedAuthCount = response?.totalAboutToExpireItemBasedAuthCount
      this.totalActiveItemBasedAuthCount = response?.totalActiveItemBasedAuthCount
      this.totalBillingCount = response?.totalBillingCount
      this.totalClaimCount = response?.totalClaimCount
      this.totalDenialCount = response?.totalDenialCount
      this.totalExpireItemBasedAuthCount = response?.totalExpireItemBasedAuthCount
      this.totalFileUploadCount = response?.totalFileUploadCount
      this.totalInsuranceBasedAuthCount = response?.totalInsuranceBasedAuthCount
      this.totalItemBasedAuthCount = response?.totalItemBasedAuthCount
      this.totalPatientCount = response?.totalPatientCount
      this.totalSaleOrderCount = response?.totalSaleOrderCount
      this.totalSaleOrderToBillingPercentage = response?.totalSaleOrderToBillingPercentage
      this.totalShippingCount = response?.totalShippingCount
      this.totalVerificationCount = response?.totalVerificationCount
      this.userCount = response?.userCount
    }, err => {
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
    this.subscription$.push(getDashboardDetailsSub);

  }

    //get role droprdown
    loadRoleDropdown() {
      let roleList: GetRoleListUsingTenantIdDto = {
        tenantId: this.clientId
      }
      const getRoleListSub =      this.roleService.getRoleList(roleList).subscribe(response => {
      }, err => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      })
    this.subscription$.push(getRoleListSub);

    }
    //! get Product Notifications In Dismissable toastr
    getNotificationForPopup() {
      const getProductNotificationSub =    this.prodNotifService.getProductNotification().pipe(
        map(e => e?.items),
        // map(e => e?.filter(e => e?.isDismissed === false)),
        // map(e => e?.filter(e => e?.readStatus === false))
      ).subscribe(response => {
        this.reminderService?.clear();
        response?.forEach(element => {
          const userName: string = this.LoginUserName ?? "";
          const titleMsg: string = `Hi  ${userName}  👋`;
          element?.productStatus === ProductStatus?.InStock && this.reminderService?.success(titleMsg, element);
          element?.productStatus === ProductStatus?.LowStock && this.reminderService?.warning(titleMsg, element);
          element?.productStatus === ProductStatus?.OutOfStock && this.reminderService?.error(titleMsg, element);
        });
      }, err => {
        this.reminderService?.clear();
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    this.subscription$.push(getProductNotificationSub);

    }

  todayReadyToPackOrders: number;
  todayReadyToDeliverOrders: number;
  todayDeliveredOrders: number
  totalReadyToPackOrders: number;
  totalReadyToDeliverOrders: number;
  totalDeliveredOrders: number;
  //! Open Notification modal using ref
  openNotificationsModal() {

    const dialogRef = this.dialog.open(NotificationTrayComponent, {
      data: {},
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(() => {
      this.showWareHouse && this.getNotificationCounts();
    });
  }
  openNotificationsUserModal() {
    const dialogRef = this.dialog.open(ClaimNotificationComponent, {
      data: { userId: this.userId },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(() => {
      this.showWareHouse && this.getNotificationCounts();
    });
  }
  openChatModal() {
    const dialogRef = this.dialog.open(ChatSignalrComponent, {
      width: "50vw",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(() => {
      this.showWareHouse && this.getNotificationCounts();
    });
  }


  getExpiryCounts() {
    // this.reminderService?.clear();
    // const listSub$: Observable<PatientDTO[]> = this.patientService.getList(new PagedAndSortedResultRequestDto).pipe(map(e => e?.items));
    // listSub$.subscribe(response => {
    //   const userName: string = this.LoginUserName ?? "";
    //   const titleMsg: string = `Hi  ${userName}  👋`;
    //   response?.forEach(x => {
    //     if (x.authorizations.primaryAuthorizations && x?.authorizations?.primaryAuthorizations?.authExpiryRemainder) {
    //       let data: { name: string, defaultId: string, msg: string } = { name: x?.personals?.fullName, defaultId: x?.defaultPatientId, msg: x?.authorizations?.primaryAuthorizations?.authExpiryRemainder };
    //       this.reminderService?.successExpiry(titleMsg, data);
    //     }
    //     if (x.authorizations.secondaryAuthorizations && x?.authorizations?.secondaryAuthorizations?.authExpiryRemainder) {
    //       let data: { name: string, defaultId: string, msg: string } = { name: x?.personals?.fullName, defaultId: x?.defaultPatientId, msg: x?.authorizations?.secondaryAuthorizations?.authExpiryRemainder };
    //       this.reminderService?.successExpiry(titleMsg, data);
    //     }
    //     if (x.authorizations.tertiaryAuthorizations && x?.authorizations?.tertiaryAuthorizations?.authExpiryRemainder) {
    //       let data: { name: string, defaultId: string, msg: string } = { name: x?.personals?.fullName, defaultId: x?.defaultPatientId, msg: x?.authorizations?.tertiaryAuthorizations?.authExpiryRemainder };
    //       this.reminderService?.successExpiry(titleMsg, data);
    //     }
    //   });
    // }, err => {
    //   this.reminderService?.clear();
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // })

    // this.reminderService?.clear();
    // const listSub$: Observable<ItemAuthorizationDTO[]> = this.itemAuthorizationService.getList(new PagedAndSortedResultRequestDto).pipe(map(e => e?.items));
    // listSub$.pipe().subscribe(response => {
    //   const userName: string = this.LoginUserName ?? "";
    //   const titleMsg: string = `Hi  ${userName}  👋`;
    //   response?.forEach(x => {
    //     x.patientId = x?.patientId ?? "";
    //     x?.patientId && this.patientService.get(x?.patientId).subscribe(patResponse => {
    //       let patDetails: ItemAuthDTO = {
    //         pName: patResponse?.personals?.fullName ?? "",
    //         pId: patResponse?.id ?? "",
    //         pDefaultId: patResponse?.defaultPatientId ?? "",
    //         authExpireStatus: x?.authExpireStatus ?? ""
    //       }
    //       if (x?.authExpireStatus !== "") {
    //         let data: { name: string, defaultId: string, msg: string } =
    //         {
    //           name: patDetails?.pName ?? "",
    //           defaultId: patDetails?.pDefaultId ?? "",
    //           msg: patDetails?.authExpireStatus ?? ""
    //         };
    //         this.reminderService?.successExpiry(titleMsg, data);
    //       }
    //     }, err => {
    //       this.reminderService?.clear();
    //       const data: HttpErrorResponse = err;
    //       Swal.fire({
    //         icon: 'error',
    //         text: data?.error?.error?.message,
    //       });
    //     })
    //   });
    // }, err => {
    //   this.reminderService?.clear();
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // })
  }

  // new dashboard functionality
  totalPatientCount: number;
  totalVerificationCount: number;
  totalFileUploadCount: number;
  totalSaleOrderCount: number;
  totalShippingCount: number;
  totalBillingCount: number;
  totalClaimCount: number;
  totalDenialCount: number;
  branchCount: number;
  currentMonthAboutToExpireItemBasedAuthCount: number;
  currentMonthActiveItemBasedAuthCount: number;
  currentMonthBillingCount: number;
  currentMonthClaimCount: number;
  currentMonthDenialCount: number;
  currentMonthExpireItemBasedAuthCount: number;
  currentMonthFileUploadCount: number;
  currentMonthInsuranceBasedAuthCount: number;
  currentMonthItemBasedAuthCount: number;
  currentMonthPatientCount: number;
  currentMonthSaleOrderCount: number;
  currentMonthSaleOrderToBillingPercentage: number;
  currentMonthVerificationCount: number;
  totalAboutToExpireItemBasedAuthCount: number;
  totalActiveItemBasedAuthCount: number;
  totalExpireItemBasedAuthCount: number;
  totalInsuranceBasedAuthCount: number;
  totalItemBasedAuthCount: number;
  totalSaleOrderToBillingPercentage: number;

  // get Item based Auth click events
  getItemBasedAuthCountForActive(value: string) {
    if (value == "Active") {
      this.router.navigate(['auth-followup-report/active']);
    } else if (value == "All") {
      this.router.navigate(['auth-followup-report/all']);
    } else if (value = "Expire") {
      this.router.navigate(['auth-followup-report/expire']);
    }
  }
  // get danail report
  getDanailReport(value) {
    this.router.navigate(['denial-reports/This Month']);
  }
  // clear Theme
  theme: string = "";
  color: string = "";
  clearTheme() {
    this.theme = '';
    this.dashBoardForm.patchValue({ txtProvider: '' });
  }
  selectedValue: any;
  // colors dropdown
  colors = [
    {
      id: '1',
      value: 'Light Sea Green'
    },
    {
      id: '2',
      value: 'Sky Blue'
    },
    {
      id: '3',
      value: 'Pure Blue'
    },
    {
      id: '4',
      value: 'Violet'
    }
  ];
  txtThemeValue: string = "";
  // colors onchange
  onChange(data) {
    this.txtThemeValue = data;
    this.tableService.setNavBarData(this.txtThemeValue);
    localStorage.setItem('dataSource', this.txtThemeValue);
  }
  ngOnDestroy(): void {
    this.subscription$.forEach(sub=>{
      sub && sub?.unsubscribe();
    })
  }
}

export interface ItemAuthDTO extends ItemAuthorizationDTO {
  pName?: string
  pId?: string
  pDefaultId?: string
  authExpireStatus?: string
}
