import type {
  CountsOutputForWonace,
  CreateUpdateMMOrderDTO,
  CreateUpdateResupplyStatusInactiveSuppliesDTO,
  ExchangeItemsListDTO,
  ExchangeListDTO,
  ExchangeOrdersListDTO,
  FilterResupply,
  FrequentlyOrderedItemDTO,
  GooglePlacesMatechedDTO,
  InboundDocumentDataDTO,
  InputOrderListDto,
  InputOrderStatusListDto,
  ListofVerificationsDTO,
  MMOrderItemLookupDTO,
  MmOrderDTO,
  MmOrderDataDetailsDTO,
  OrderListDto,
  OrderPatientDetailsDTO,
  OrderPatientNotesDTO,
  OrderStatusListDto,
  OrderTabResultDTO,
  OverrideListDTO,
  PatientAddressValidationDTO,
  PatientChartNoDTO,
  PatientInfoDTO,
  PatientOrderInsuranceDTO,
  PatientShippingAddressDTO,
  RentalListDTO,
  SupplyArchiveItemsDTO,
  TechnicianDropDown,
  clsSaleOrderDTOforBilling,
  completedandnoncashorderlistDTO,
  mmRentalOrderDTO,
  requestDTO,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';
import {
  CreateUpdateMMOrderItemDTO,
  MmOrderItemExchanges,
  OrderNumberDetails,
  ProdcutDetailsforStock,
  ProductDetailsDTO,
} from 'projects/inventory/src/app/item-proxy/item-management/optimization/dto';
import {
  CreateUpdatePatientAddressDTO,
  PatientAddressDTO,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto/models';
import { mmRentalTypes } from './mm-rental-types.enum';
import { AgingDaysFilter } from './aging-days-filter.enum';
import { ResupplyStausList } from './resupply-staus-list.enum';
import { ResupplyFilterList } from './resupply-filter-list.enum';
import { WonaceOrderTab } from './wonace-order-tab.enum';

@Injectable({
  providedIn: 'root',
})
export class MmOrderService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateMMOrderDTO) =>
    this.restService.request<any, MmOrderDTO>(
      {
        method: 'POST',
        url: '/api/orderApp/mm-order',
        body: input,
      },
      { apiName: this.apiName }
    );
  createV1 = (input: CreateUpdateMMOrderDTO) =>
    this.restService.request<any, MmOrderDTO>(
      {
        method: 'POST',
        url: '/api/orderApp/mm-order/async-v1',
        body: input,
      },
      { apiName: this.apiName }
    );
  createMultiplePatientAddressByInput = (
    input: CreateUpdatePatientAddressDTO[]
  ) =>
    this.restService.request<any, PatientAddressDTO[]>(
      {
        method: 'POST',
        url: '/api/orderApp/mm-order/multiple-patient-address',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/orderApp/mm-order/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, MmOrderDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MmOrderDTO>>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  getNotesByPatientIdByPatientID = (patientID: string) =>
    this.restService.request<any, OrderPatientNotesDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/notes-by-patient-id',
        params: { patientID },
      },
      { apiName: this.apiName }
    );
  getPatientOrderList = (gPatientId: string) =>
    this.restService.request<any, PagedResultDto<MmOrderDTO>>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/patient-order-list/${gPatientId}`,
      },
      { apiName: this.apiName }
    );

  getPatientOrderItemList = (gOrderId: string) =>
    this.restService.request<any, PagedResultDto<CreateUpdateMMOrderItemDTO>>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/patient-order-item-list/${gOrderId}`,
      },
      { apiName: this.apiName }
    );

  remReqByInputValue = (inputValue: CreateUpdateMMOrderDTO) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        responseType: 'text',
        url: '/api/orderApp/mm-order/rem-req',
        body: inputValue,
      },
      { apiName: this.apiName }
    );

  getPatientChartNoV1 = () =>
    this.restService.request<any, PatientChartNoDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/get-patient-chart-no-v1',
      },
      { apiName: this.apiName }
    );
  exchangeItemByGOrderIdAndGOrderItemIdAndGExchangedOrderItemIdAndINoQtyExgeAndInput =
    (
      gOrderId: string,
      gOrderItemId: string,
      gExchangedOrderItemId: string,
      iNoQtyExge: number,
      input: MmOrderItemExchanges
    ) =>
      this.restService.request<any, CreateUpdateMMOrderItemDTO>(
        {
          method: 'POST',
          url: '/api/orderApp/mm-order/exchange-item',
          params: { gOrderId, gOrderItemId, gExchangedOrderItemId, iNoQtyExge },
          body: input,
        },
        { apiName: this.apiName }
      );

  getOrderList = (
    iFilterType: number,
    isQuaterEnable: boolean,
    dFromDate: string,
    dToDate: string
  ) =>
    this.restService.request<any, PagedResultDto<MmOrderDTO>>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/order-list',
        params: { iFilterType, isQuaterEnable, dFromDate, dToDate },
      },
      { apiName: this.apiName }
    );

  getFrequentlyBoughtItemByGPatientId = (gPatientId: string) =>
    this.restService.request<any, PagedResultDto<CreateUpdateMMOrderItemDTO>>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/get-frequently-bought-item/${gPatientId}`,
      },
      { apiName: this.apiName }
    );

  computeOrderAmountByInput = (input: CreateUpdateMMOrderDTO) =>
    this.restService.request<any, CreateUpdateMMOrderDTO>(
      {
        method: 'POST',
        url: '/api/orderApp/mm-order/compute-order-amount',
        body: input,
      },
      { apiName: this.apiName }
    );

  getOrderNumberDetails = () =>
    this.restService.request<any, OrderNumberDetails>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/get-order-number-details',
      },
      { apiName: this.apiName }
    );
  // getProductDetails = () =>
  //   this.restService.request<any, ProductDetailsDTO[]>(
  //     {
  //       method: 'GET',
  //       url: '/api/orderApp/mm-order/product-details',
  //     },
  //     { apiName: this.apiName }
  //   );

  getProductDetails = () =>
    this.restService.request<any, ProdcutDetailsforStock[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/product-details',
      },
      { apiName: this.apiName }
    );

  getProductDetailsByCategoryByLtCategoryIds = (ltCategoryIds: string[]) =>
    this.restService.request<any, ProdcutDetailsforStock[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/product-details-by-category',
        params: { ltCategoryIds },
      },
      { apiName: this.apiName }
    );

  // loadProductDetailsByGProductIdAndGInsuranceGroupIdAndBCashOrder = (
  //   gProductId: string,
  //   gLoctionId:string,
  //   gInsuranceGroupId: string,
  //   bCashOrder: boolean,
  //   // isInsuranceCategory: boolean
  // ) =>
  //   this.restService.request<any, CreateUpdateMMOrderItemDTO>(
  //     {
  //       method: 'POST',
  //       url: '/api/orderApp/mm-order/load-product-details',
  //       params: {
  //         gProductId,
  //         gLoctionId,
  //         gInsuranceGroupId,
  //         bCashOrder,
  //         // isInsuranceCategory,
  //       },
  //     },
  //     { apiName: this.apiName }
  //   );
  loadProductDetailsByGProductIdAndGLoctionIdAndGInsuranceGroupIdAndBCashOrder =
    (
      gOrderId: string,
      PatientId: string,
      gProductId: string,
      gLoctionId: string,
      gInsuranceGroupId: string,
      bCashOrder: boolean
    ) =>
      this.restService.request<any, CreateUpdateMMOrderItemDTO>(
        {
          method: 'POST',
          url: '/api/orderApp/mm-order/load-product-details',
          params: {
            gOrderId,
            gProductId,
            gLoctionId,
            gInsuranceGroupId,
            bCashOrder,
            PatientId,
          },
        },
        { apiName: this.apiName }
      );
  getRentalItemsListByDtFromDateAndDtToDate = (
    dtFromDate: string,
    dtToDate: string
  ) =>
    this.restService.request<any, RentalListDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/rental-items-list',
        params: { dtFromDate, dtToDate },
      },
      { apiName: this.apiName }
    );

  getRentalOrderAmountByGOrderAndMonthAndAmountAndDtOrderDate = (
    gOrder: string,
    month: number,
    Amount: number,
    dtOrderDate: string
  ) =>
    this.restService.request<any, mmRentalOrderDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/rental-order-amount',
        params: { gOrder, month, amount: Amount, dtOrderDate },
      },
      { apiName: this.apiName }
    );

  getRentalItemsListByIdOrderIdByGOrder = (gOrder: string) =>
    this.restService.request<any, mmRentalOrderDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/rental-items-list-by-id-order-id',
        params: { gOrder },
      },
      { apiName: this.apiName }
    );

  updateAccessoryExchangeDetailsByInput = (input: CreateUpdateMMOrderItemDTO) =>
    this.restService.request<any, CreateUpdateMMOrderItemDTO>(
      {
        method: 'PUT',
        url: '/api/orderApp/mm-order/update-accessory-exchange-details',
        body: input,
      },
      { apiName: this.apiName }
    );
  update = (id: string, input: CreateUpdateMMOrderDTO) =>
    this.restService.request<any, MmOrderDTO>(
      {
        method: 'PUT',
        url: `/api/orderApp/mm-order/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getPatientAddressDetailsforOrderByPatientId = (patientId: string) =>
    this.restService.request<any, PatientShippingAddressDTO[]>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/get-patient-address-detailsfor-order/${patientId}`,
      },
      { apiName: this.apiName }
    );

  getPatientChartNo = () =>
    this.restService.request<any, PatientChartNoDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/get-patient-chart-no',
      },
      { apiName: this.apiName }
    );

  getPatientDetailsforChartIdByPatientId = (patientId: string) =>
    this.restService.request<any, PatientInfoDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/get-patient-detailsfor-chart-id/${patientId}`,
      },
      { apiName: this.apiName }
    );

  getPatientBillingAddressDetailsByPatientId = (patientId: string) =>
    this.restService.request<any, PatientAddressDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/get-patient-billing-address-details/${patientId}`,
      },
      { apiName: this.apiName }
    );
  getPatientDetailsforOrderViewByPatientId = (patientId: string) =>
    this.restService.request<any, OrderPatientDetailsDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/get-patient-detailsfor-order-view/${patientId}`,
      },
      { apiName: this.apiName }
    );
  getPatientDetailsforOrderViewByPatientIdV1 = (patientId: string) =>
    this.restService.request<any, OrderPatientDetailsDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/get-patient-detailsfor-order-view-v1/${patientId}`,
      },
      { apiName: this.apiName }
    );
  getActivePolicyByPatientIdByPatientId = (patientId: string) =>
    this.restService.request<any, PatientOrderInsuranceDTO[]>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/get-active-policy-by-patient-id/${patientId}`,
      },
      { apiName: this.apiName }
    );

  exchangeItemByGOrderIdAndGOrderItemId = (
    gOrderId: string,
    gOrderItemId: string[]
  ) =>
    this.restService.request<any, MmOrderDTO>(
      {
        method: 'POST',
        url: '/api/orderApp/mm-order/exchange-item',
        params: { gOrderId },
        body: gOrderItemId,
      },
      { apiName: this.apiName }
    );

  getRxImagedocumentIdByPatientId = (patientId: string) =>
    this.restService.request<any, InboundDocumentDataDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/rx-imagedocument-id/${patientId}`,
      },
      { apiName: this.apiName }
    );
  getExchangeItemsListByDtFromDateAndDtToDate = (
    dtFromDate: string,
    dtToDate: string
  ) =>
    this.restService.request<any, ExchangeListDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/exchange-items-list',
        params: { dtFromDate, dtToDate },
      },
      { apiName: this.apiName }
    );

  getExchangeItemsListV1ByDtFromDateAndDtToDate = (
    dtFromDate: string,
    dtToDate: string
  ) =>
    this.restService.request<any, ExchangeOrdersListDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/exchange-items-list-v1',
        params: { dtFromDate, dtToDate },
      },
      { apiName: this.apiName }
    );

  getExchangedItemsDetailsByIdByGExchangeId = (gExchangeId: string) =>
    this.restService.request<any, ExchangeItemsListDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/exchanged-items-details-by-id/${gExchangeId}`,
      },
      { apiName: this.apiName }
    );

  getExchangedItemsDetailsByIdV1ByOriginalOrderIdAndNewOrderId = (
    originalOrderId: string,
    newOrderId: string
  ) =>
    this.restService.request<any, ExchangeItemsListDTO>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/exchanged-items-details-by-id-v1',
        params: { originalOrderId, newOrderId },
      },
      { apiName: this.apiName }
    );

  checkAddressValidation_GoogleByInput = (input: PatientAddressValidationDTO) =>
    this.restService.request<any, GooglePlacesMatechedDTO>(
      {
        method: 'POST',
        url: '/api/orderApp/mm-order/check-address-validation_Google',
        body: input,
      },
      { apiName: this.apiName }
    );

  checkAddressValidation_GoogleV1ByAddressIdAndInput = (
    addressId: string,
    input: PatientAddressValidationDTO
  ) =>
    this.restService.request<any, GooglePlacesMatechedDTO>(
      {
        method: 'POST',
        url: `/api/orderApp/mm-order/check-address-validation_Google-v1/${addressId}`,
        body: input,
      },
      { apiName: this.apiName }
    );
  createOverrideOrderByInput = (input: CreateUpdateMMOrderDTO) =>
    this.restService.request<any, MmOrderDTO>(
      {
        method: 'POST',
        url: '/api/orderApp/mm-order/override-order',
        body: input,
      },
      { apiName: this.apiName }
    );
  updateOrderOverrideByIdAndInputData = (
    id: string,
    inputData: CreateUpdateMMOrderDTO
  ) =>
    this.restService.request<any, MmOrderDTO>(
      {
        method: 'PUT',
        url: `/api/orderApp/mm-order/${id}/order-override`,
        body: inputData,
      },
      { apiName: this.apiName }
    );
  createOrderToBillingByInput = (input: CreateUpdateMMOrderDTO) =>
    this.restService.request<any, MmOrderDTO>(
      {
        method: 'POST',
        url: '/api/orderApp/mm-order/order-to-billing',
        body: input,
      },
      { apiName: this.apiName }
    );
  updateOrderToBillingByIdAndInputData = (
    id: string,
    inputData: CreateUpdateMMOrderDTO
  ) =>
    this.restService.request<any, MmOrderDTO>(
      {
        method: 'PUT',
        url: `/api/orderApp/mm-order/${id}/order-to-billing`,
        body: inputData,
      },
      { apiName: this.apiName }
    );

  getOrderOverrideListByDtFromDateAndDtToDate = (
    dtFromDate: string,
    dtToDate: string
  ) =>
    this.restService.request<any, OverrideListDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/order-override-list',
        params: { dtFromDate, dtToDate },
      },
      { apiName: this.apiName }
    );

  updateOverrideStatusByGOrderIdAndStatusAndSComments = (
    gOrderId: string,
    status: boolean,
    sComments: string
  ) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `/api/orderApp/mm-order/override-status/${gOrderId}`,
        params: { status, sComments },
      },
      { apiName: this.apiName }
    );
  getOrderListV1 = (dFromDate: string, dToDate: string, gCSR: string) =>
    this.restService.request<any, PagedResultDto<MmOrderDTO>>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/order-list-async-v1',
        params: { dFromDate, dToDate, gCSR },
      },
      { apiName: this.apiName }
    );
  getOrderListV2 = (
    dFromDate: string,
    dToDate: string,
    gCSR: string,
    gPatient: string,
    gOrderStatus: string
  ) =>
    this.restService.request<any, PagedResultDto<MmOrderDTO>>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/order-list-async-v2',
        params: { dFromDate, dToDate, gCSR, gPatient, gOrderStatus },
      },
      { apiName: this.apiName }
    );

  // getOrderListV3 = (
  //   dFromDate: string,
  //   dToDate: string,
  //   gCSR: string,
  //   gPatient: string,
  //   gOrderStatus: string,
  //   iAdmin: boolean,
  //   gTechnician: string
  // ) =>
  //   this.restService.request<any, MmOrderDTO[]>(
  //     {
  //       method: 'GET',
  //       url: '/api/orderApp/mm-order/order-list-async-v3',
  //       params: {
  //         dFromDate,
  //         dToDate,
  //         gCSR,
  //         gPatient,
  //         gOrderStatus,
  //         iAdmin,
  //         gTechnician,
  //       },
  //     },
  //     { apiName: this.apiName }
  //   );

  getOrderListV3 = (
    dFromDate: string,
    dToDate: string,
    gCSR: string,
    gPatient: string,
    gOrderStatus: string,
    iAdmin: boolean,
    Technician: string,
    pageNo,
    pageOffset
  ) =>
    this.restService.request<any, MmOrderDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/order-list-async-v3',
        params: {
          dFromDate,
          dToDate,
          gCSR,
          gPatient,
          gOrderStatus,
          iAdmin,
          technician: Technician,
          pageNo,
          pageOffset,
        },
      },
      { apiName: this.apiName }
    );

  copyOrderDetails = (OrderId: string) =>
    this.restService.request<any, MmOrderDTO>(
      {
        method: 'POST',
        url: `/api/orderApp/mm-order/copy-order-details/${OrderId}`,
      },
      { apiName: this.apiName }
    );
  copyOrderDetailsV1 = (OrderId: string) =>
    this.restService.request<any, MmOrderDataDetailsDTO>(
      {
        method: 'POST',
        url: `/api/orderApp/mm-order/copy-order-details-async-v1/${OrderId}`,
      },
      { apiName: this.apiName }
    );
  resupplyCopyOrderDetails = (OrderId: string) =>
    this.restService.request<any, MmOrderDataDetailsDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/get-resupply-create-order/${OrderId}`,
      },
      { apiName: this.apiName }
    );
  loadProductDetailsForLookupByGProductIdAndGPatientIdAndGInsuranceGroupId = (
    gProductId: string,
    gPatientId: string,
    gInsuranceGroupId: string
  ) =>
    this.restService.request<any, MMOrderItemLookupDTO>(
      {
        method: 'POST',
        url: '/api/orderApp/mm-order/load-product-details-for-lookup',
        params: { gProductId, gPatientId, gInsuranceGroupId },
      },
      { apiName: this.apiName }
    );
  updateAccessoryExchangeDetailsLookupByItemData = (
    ItemData: MMOrderItemLookupDTO
  ) =>
    this.restService.request<any, MMOrderItemLookupDTO>(
      {
        method: 'PUT',
        url: '/api/orderApp/mm-order/update-accessory-exchange-details-lookup',
        body: ItemData,
      },
      { apiName: this.apiName }
    );
  getPatientChartNoV2BySSearchParams = (sSearchParams: string) =>
    this.restService.request<any, PatientChartNoDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/get-patient-chart-no-v2',
        params: { sSearchParams },
      },
      { apiName: this.apiName }
    );
  getOrderData = (id: string) =>
    this.restService.request<any, MmOrderDataDetailsDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/${id}/order-data`,
      },
      { apiName: this.apiName }
    );

  getPatientChartNoV3BySSearchParamsAndPatient = (
    sSearchParams: string,
    patient: string
  ) =>
    this.restService.request<any, PatientChartNoDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/get-patient-chart-no-v3',
        params: { sSearchParams, patient },
      },
      { apiName: this.apiName }
    );
  updateOrderStatusByGOrderIdAndGStatusId = (
    gOrderId: string,
    gStatusId: number
  ) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: '/api/orderApp/mm-order/update-order-status',
        params: { gOrderId, gStatusId },
      },
      { apiName: this.apiName }
    );
  getSingleOrderByGOrderId = (gOrderId: string) =>
    this.restService.request<any, MmOrderDTO>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/single-order/${gOrderId}`,
      },
      { apiName: this.apiName }
    );
  orderCancelByOrderIdAndStatus = (orderId: string, status: number) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/orderApp/mm-order/order-cancel/${orderId}`,
        params: { status },
      },
      { apiName: this.apiName }
    );
  updateOverrideStatusV1ByGOrderIdAndStatusAndSComments = (
    gOrderId: string,
    status: boolean,
    sComments: string
  ) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `/api/orderApp/mm-order/override-status-v1/${gOrderId}`,
        params: { status, sComments },
      },
      { apiName: this.apiName }
    );
  patientOrderDetails = (orderId: string, patientId: String) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/patient-order-details`,
        params: { orderId, patientId },
      },
      { apiName: this.apiName }
    );

  getFrequentlyOrderedItemsByGPatientId = (gPatientId: string) =>
    this.restService.request<any, FrequentlyOrderedItemDTO[]>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/frequently-ordered-items/${gPatientId}`,
      },
      { apiName: this.apiName }
    );
  getTechnicianDropDown = () =>
    this.restService.request<any, TechnicianDropDown[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/technician-drop-down',
      },
      { apiName: this.apiName }
    );
  getPatientVerificationsForOrderByPatientIdAndGVerificationId = (
    patientId: string,
    gVerificationId: string
  ) =>
    this.restService.request<any, ListofVerificationsDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/get-patient-verifications-for-order',
        params: { patientId, gVerificationId },
      },
      { apiName: this.apiName }
    );
  getRentalItemsListv1ByOrderIdAndChartIdAndPatientnameAndDosDateAndStatus = (
    orderId: number,
    chartId: string,
    patientname: string,
    dosDate: string,
    status: mmRentalTypes
  ) =>
    this.restService.request<any, RentalListDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/rental-items-listv1',
        params: { orderId, chartId, patientname, dosDate, status },
      },
      { apiName: this.apiName }
    );

  // getReSupplyCurrentOrderList = (
  //   resupply: ResupplyStausList,
  //   agingDays: AgingDaysFilter,
  //   filter: ResupplyFilterList,
  //   chartNo: string,
  //   patientName: string,
  //   fromDate: string,
  //   toDate: string
  // ) =>
  //   this.restService.request<any, completedandnoncashorderlistDTO[]>(
  //     {
  //       method: 'GET',
  //       url: '/api/orderApp/mm-order/get-re-supply-order-listfor-current-day',
  //       params: {
  //         resupply,
  //         agingDays,
  //         filter,
  //         chartNo,
  //         patientName,
  //         fromDate,
  //         toDate,
  //       },
  //     },
  //     { apiName: this.apiName }
  //   );

  getReSupplyCurrentOrderList = (
    resupply: ResupplyStausList,
    agingDays: AgingDaysFilter,
    filter: ResupplyFilterList,
    filterApply: FilterResupply
  ) =>
    this.restService.request<any, completedandnoncashorderlistDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/get-re-supply-order-listfor-current-day',
        params: {
          resupply,
          agingDays,
          filter,
          chartNo: filterApply.chartNo,
          patientName: filterApply.patientName,
          fromDate: filterApply.fromDate,
          toDate: filterApply.toDate,
          csrId: filterApply.csrId,
        },
      },
      { apiName: this.apiName }
    );
  getResupplyAboveNinetyDays = (
    chartNo: string,
    patientName: string,
    fromDate: string,
    csrId: string[]
  ) =>
    this.restService.request<any, completedandnoncashorderlistDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/get-re-supply-over-due-orders',
        params: {
          chartNo: chartNo,
          patientName: patientName,
          fromDate: fromDate,
          csrId: csrId,
        },
      },
      { apiName: this.apiName }
    );

  getResupplyStatusListForInactiveSuppliesByInput = (
    input: CreateUpdateResupplyStatusInactiveSuppliesDTO
  ) =>
    this.restService.request<any, completedandnoncashorderlistDTO[]>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/resupply-status-list-for-inactive-supplies',
        params: {
          fromDate: input.fromDate,
          toDate: input.toDate,
          chartID: input.chartID,
          patientName: input.patientName,
          csr: input.csr,
        },
      },
      { apiName: this.apiName }
    );

  getReSupplyInactiveList = (patiuentId: string) =>
    this.restService.request<any, completedandnoncashorderlistDTO[]>(
      {
        method: 'POST',
        url:
          '/api/orderApp/mm-order/updating-patient-inactive-status/' +
          patiuentId,
      },
      { apiName: this.apiName }
    );
  getResupplyPatientDetails = (patiuentId: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/resupply-patient-details/' + patiuentId,
      },
      { apiName: this.apiName }
    );

  getBillingOrderDetailsforOrderIdByGOrderId = (gOrderId: string) =>
    this.restService.request<any, clsSaleOrderDTOforBilling>(
      {
        method: 'GET',
        url: `/api/orderApp/mm-order/get-billing-order-detailsfor-order-id/${gOrderId}`,
      },
      { apiName: this.apiName }
    );

  updateInactivateSuppliesByInput = (input: SupplyArchiveItemsDTO) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: '/api/orderApp/mm-order/update-inactivate-supplies',
        body: input,
      },
      { apiName: this.apiName }
    );

  supplyArchiveItemsv1ByInput = (input: SupplyArchiveItemsDTO) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: '/api/orderApp/mm-order/supply-archive-itemsv1',
        body: input,
      },
      { apiName: this.apiName }
    );

  patientActiveStatusNotes = (input: any) =>
    this.restService.request<any, any>(
      {
        method: 'PUT',
        url: '/api/orderApp/mm-order/patient-acttive-status-with-notes',
        body: input,
      },
      { apiName: this.apiName }
    );
  getOrderTabReportByShortCode = (shortCode: WonaceOrderTab) =>
    this.restService.request<any, OrderTabResultDTO>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/get-order-tab-report',
        params: { shortCode },
      },
      { apiName: this.apiName }
    );

    getOrderTabReportByDto = (dto: requestDTO) =>
      this.restService.request<any, OrderTabResultDTO>({
        method: 'POST',
        url: '/api/orderApp/mm-order/get-order-tab-report',
        body: dto,
      },
      { apiName: this.apiName });

  getCountOfTabsInWonace = () =>
    this.restService.request<any, CountsOutputForWonace>(
      {
        method: 'GET',
        url: '/api/orderApp/mm-order/get-count-of-tabs-in-wonace',
      },
      { apiName: this.apiName }
    );

  getOrderNumbersForMyQueueByInput = (input: InputOrderListDto) =>
    this.restService.request<any, OrderListDto[]>(
      {
        method: 'POST',
        url: '/api/orderApp/mm-order/get-order-numbers-for-my-queue',
        body: input,
      },
      { apiName: this.apiName }
    );

  getOrderStatusForMyQueueByInput = (input: InputOrderStatusListDto) =>
    this.restService.request<any, OrderStatusListDto[]>(
      {
        method: 'POST',
        url: '/api/orderApp/mm-order/get-order-status-for-my-queue',
        body: input,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
