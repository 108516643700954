import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { RequisitionService } from '../item-proxy/item-management/optimization/requisition.service';
import { Observable, Subscription } from 'rxjs';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { RequisitionStatusDTO } from '../item-proxy/item-management/optimization/dto';
import { map, startWith } from 'rxjs/operators';
import { RequisitionStatusShortCode } from 'projects/shared/src/app/data/common-data';
import { DataTableDirective } from 'angular-datatables';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-requisition-list',
  templateUrl: './requisition-list.component.html',
  styleUrls: ['./requisition-list.component.scss'],
})
export class RequisitionListComponent implements OnInit {
  requisitionListData: any;
  requisitionItemsList: any;
  requisitionStatusList: any[] = [];
  requisitionFilterForm: FormGroup;
  showCompleted: boolean = false;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  // filteredRequisitionStatus: Observable<string[]> | undefined;
  filteredRequisitionStatus: RequisitionStatusDTO[];
  requisitionListOptions: any = {
    dom: 'Bfrtip',
    responsive: true,
    paging: false,
    scrollX: true,
    scrollCollapse: true,
    scrollY: '200px',
    fixedHeader: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },

    columnDefs: [
      { targets: [9,10], visible: false },
      { targets: [0], width: '5%' }, //options
      { targets: [1], width: '15%' }, // requisition no
      { targets: [2], width: '15%' }, // CSR
      { targets: [3], width: '20%' }, // shipping method
      { targets: [4], width: '15%' }, // created date
      { targets: [5], width: '10%' }, // status
      { targets: [6], width: '20%' }, // Special Instruction
      // { orderable: true, targets: '_all' }
    ],
    buttons: [
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },

    ],
  };
  drpFilter = new FormControl();
  tblItemSelectedRowId: string;
  tblSelectedStatusId: string;
  loadRequisitionTable: boolean = true;
  loadRequisitionItemsTable: boolean = true;
  reqStatusShortCodeIds = RequisitionStatusShortCode;
  subscription$: Subscription[] = [];
  
  currentPage : any = 1;
  pageOffset: any = 50;
  totalPages : any = 1;
  visibleColumns: number[] ;

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private requisitionService: RequisitionService,
    private communicationService: CommunicationService,
    private commonService: CommonService
  ) {
    const comService =
      this.communicationService.loadRequisitionListFunctionCall$.subscribe(
        (data) => {
          this.requisitionItemsList = data.data;
          data.flag && this.reloadItemTable();
          !data.flag &&
            this.loadRequisitionList(
              this.requisitionFilterForm.value.drpStatus
            );
        }
      );
    this.subscription$.push(comService);
  }
  //On Page Load
  ngOnInit() {
    this.initializeForms();
    this.loadRequisitionStatus();
    this.drpFilter.valueChanges.subscribe((branchFilterText) => {
      branchFilterText = branchFilterText.toLowerCase();
      this.filteredRequisitionStatus = this.requisitionStatusList.filter((dx) =>
        dx.requisitionStatus.toLowerCase().includes(branchFilterText)
      );
    });
  }
  initializeForms() {
    this.requisitionFilterForm = this.fb.group({
      drpStatus: new FormControl(''),
      txtFilterSearch: new FormControl(''),
    });
  }
  //Method to load the Requisition Status
  loadRequisitionStatus() {
    const requisitionStatus = this.requisitionService
      .getRequisitionStatusList()
      .subscribe(
        (response) => {
          this.requisitionStatusList = response ?? [];

          const allStatusOption: RequisitionStatusDTO = {
            id: '',
            mmReferenceRequisitionId: '',
            fgColor: '',
            bgColor: '',
            status: 0,
            shortCodeId: '',
            requisitionStatus: 'All',
          };

          this.requisitionStatusList = [
            allStatusOption,
            ...this.requisitionStatusList,
          ];
          this.filteredRequisitionStatus = this.requisitionStatusList;

          this.requisitionFilterForm.patchValue({
            drpStatus: RequisitionStatusShortCode.orderedDirect,
          });
          this.onStatusChange(RequisitionStatusShortCode.orderedDirect);
        },
        (err) => {}
      );
    this.subscription$.push(requisitionStatus);
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  tblItemSelectedRow(reqId: string, reqStatusId: string) {
    this.tblItemSelectedRowId = reqId;
    this.tblSelectedStatusId = reqStatusId;
    this.getRequisitionItems(reqId);
  }
  oldRequisitionId:string;
  //Get Requisition Items
  getRequisitionItems(requisitionId: string) {
    if(this.oldRequisitionId != requisitionId){
      this.oldRequisitionId= requisitionId;
      this.loadRequisitionItemsTable = false;
      if(this.oldRequisitionId!==''){
      const requisitionItemsList = this.requisitionService
        .getRequisitionItemsList(requisitionId)
        .subscribe(
          (response) => {

            this.requisitionItemsList = response ?? [];
            this.loadRequisitionItemsTable = true;
          },
          (err) => {
            this.loadRequisitionItemsTable = true;
            this.requisitionItemsList = [];
          }
        );
      this.subscription$.push(requisitionItemsList);
      }else{
        this.requisitionItemsList = [];
        setTimeout(() => {
        this.loadRequisitionItemsTable = true
        },100);
      }
    }

  }
  //To Load the Requisition List
  loadRequisitionList(status: string) {
    this.loadRequisitionTable = false;
    const requisitionList = this.requisitionService
      .getRequisitionListByStatusShortCodeId(
        status,
        this.currentPage,
        this.pageOffset
      )
      .subscribe(
        (response) => {

          this.loadRequisitionTable = true;
          //this.requisitionListData = response ?? [];
          let pagedResponse = response as any;
          this.requisitionListData = pagedResponse.items;
          this.totalPages = Math.ceil(pagedResponse.totalCount / this.pageOffset);
          this.requisitionListData = this.requisitionListData.map((requisition) => {
            return {
              ...requisition,
              createdDate: this.commonService.getFormattedDateTimeZone(
                requisition.createdDate
              ),
              lastModifiedDate: this.commonService.getFormattedDateTimeZone(
                requisition.lastModifiedDate
              ),
            };
          });
          this.requisitionItemsList = [];
          this.requisitionListData &&
            this.requisitionListData.length > 0 &&
            this.tblItemSelectedRow(
              this.requisitionListData?.[0]?.reqId,
              this.requisitionListData?.[0]?.shortCodeId
            );
            if (this.datatableElement && this.datatableElement.dtInstance) {
              this.refreshDataTable();
            } else {
              setTimeout(() => this.refreshDataTable(), 500);
            }
        },
        (err) => {
          this.loadRequisitionTable = true;
        }
      );

    this.subscription$.push(requisitionList);
  }
  //To Cancel the Requisition
  cancelRequisition(requisitionId: string) {
    Swal.fire({
      title: 'Are you sure you want to Cancel',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Cancel Requisition',
    }).then((result) => {
      if (result.value) {
        this.requisitionService
          .cancelRequisitionByGReqId(requisitionId)
          .subscribe(
            (response) => {
              this.loadRequisitionList(
                this.requisitionFilterForm.value.drpStatus
              );
              this.tblItemSelectedRow('', '');
            },
            (err) => {}
          );

        this.toaster.success('Requisition Cancelled!');
      }
    });
  }
  onStatusChange(event: any) {
    this.currentPage = 1;
    this.loadRequisitionList(this.requisitionFilterForm.value.drpStatus);
  }

  toFixed(data) {
    if(data != null || data != undefined){
      let value = data.toFixed(2)
      return value
    } else{
      return data
    }

  }
  handleTableCellClick(e: Event, specialInstruction) {
    if(specialInstruction!==''&& specialInstruction!==null && specialInstruction!==undefined){
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, specialInstruction);
    }
  }
  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, hcpcData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;

    if (tr) {
      const row = this.myTable.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        row.child(this.format(row.data(), hcpcData)).show();
      }
    }
  }
  //Add Dynamic Notes Row in UnProcessed Table
  format(d: any, hcpcData: any) {
    let returnString: string = '';
    // hcpcData?.forEach(function (value) {
    returnString += '<tr class="border-bottom"><td>' + hcpcData + '</td></tr>';
    // });
    return returnString;
  }
  //To Reload the Items List Table by delay
  reloadItemTable() {
    this.loadRequisitionItemsTable = false;
    setTimeout(() => {
      this.loadRequisitionItemsTable = true;
    }, 0.01);
  }

  addPageNavigationControls(){
    let buttonsElement =  $(".dt-buttons")[0];
    $("<span> : <span>").appendTo(buttonsElement);
    console.log('$addPageNavigationControls : current page = {currentPage}' + this.currentPage)
    if(this.currentPage == 1)
    {
      $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
    }
    else 
    {
      $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
     }

    $("<span> Page <span>").appendTo(buttonsElement);
    $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);
    $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
    $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);
    
    if(this.currentPage >= this.totalPages)
    {
      $("<button  disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
      $("<button  disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }
    else
    {
       $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
       $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.MovePage(true));
    $('#btnPrevPage').on('click', (event) => this.MovePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.changePage(1));
    $('#btnLastPage').on('click', (event) => this.changePage(this.totalPages));
   }

   fnGotoPage(){
    let pageValue = $("#txtGotoPage").val();
    let pageno = parseInt(pageValue.toString());
    if(pageno > 0 && this.currentPage !=  pageno && pageno <= this.totalPages)
    {
      this.changePage(pageno);
    }
  }

  MovePage(isNext:boolean)
  {
    console.log('MovePage called....' + this.currentPage);
    let pageNo = this.currentPage;
    if(isNext)
      pageNo++;
    else 
    pageNo--;
    //this.nevigatePage.emit(this.currentPage);
    this.changePage(pageNo);
  }

  changePage(pageNo : number){
    console.log('changePage PageNo : ' + pageNo + 'this.currentPage :' + this.currentPage + 'this.totalPages :' + this.totalPages);
    if( pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages)
    {
      this.currentPage = pageNo; 
      this.loadRequisitionList(this.requisitionFilterForm.value.drpStatus);
    }
  }

  refreshDataTable(): void { 
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
     
      // Clear search input values
      //$('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      //dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();

      // Attach the event listener for filtering
      var visColumns =  this.visibleColumns;
      var index = 0;
      dtInstance.columns().every(function () {
        const that = this; 
        if(visColumns && visColumns.length > 0)
        {  
              if(visColumns.indexOf(index) < 0)
                this.visible(false);
        } 
        index++;

      });
    });

  }
}
