import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { SplitDocumentModalComponent } from 'projects/admin/src/app/split-document-modal/split-document-modal.component';
import { noWhitespaceValidator } from '../add-note-tab/add-note-tab.component';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { MasterNotesService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {
  @Output() reloadTableEvent = new EventEmitter(); //Event Emitter to reload the table
  @Output() reloadTreeEvent = new EventEmitter(); //Event Emitter to reload the tree
  @Output() selectNode = new EventEmitter();
  CallbackForm: FormGroup;
  NoteTypeControl=new FormControl();
  seletedBranchValue: string = defaultGuid;
  defaultGuid: string = defaultGuid;
  isdrpLoading: boolean;
  isShowSpinner: boolean = false;
  drpNotetype: any;
  listNoteType: any;
  noteType: any;
  noteTypeId: any;
  savebtn:boolean=false;
  notesText = '';
  constructor( private fb: FormBuilder,
    private InboundDocService: InboundDocumentService,
    private toastr: ToastrService,
    private notesService: MasterNotesService,
    private dialog: MatDialogRef<CallbackComponent>,
    private communicationService: CommunicationService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      documentId: string;
      inboundStatusId: string;
      shortCodeId:string;
      selectedNode:any;
      currentShortCodeId:string;
    } = {
      documentId: defaultGuid,
      inboundStatusId: defaultGuid,
      shortCodeId:defaultGuid,
      selectedNode:defaultGuid,
      currentShortCodeId:''
    }) { }
    subscription$: Subscription[] = [];
  ngOnInit(): void {
    this.CallbackForm = this.fb.group({
      documentFile: new FormControl(null),
      txtComments: new FormControl('',[Validators.required, noWhitespaceValidator]),
      chkStat: new FormControl(''),
      drpBranchType: new FormControl('',[Validators.required]),
      drpNotetype:new FormControl("",[ Validators.required]),
    });
    this.noteTypeDetails();
    this.NoteTypeControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpNotetype = this.listNoteType.filter((dx) =>
        dx.noteType.toLowerCase().includes(FilterText)
      );
    });
  }
  resetCallBack() {
    this.CallbackForm.reset({
      txtComments: '',
      drpBranchType: '',
      drpNotetype:'',
    });
  }
  noteTypeDetails(){
    this.isdrpLoading = true;
    const NoteType = this.notesService
    // .getListV1(new PagedAndSortedResultRequestDto())
    this.notesService.getDrpNoteTypes().subscribe(
        (response) => {
          this.isdrpLoading = false;
          this.listNoteType=response;
          this.drpNotetype=this.listNoteType;
        },
        (err) => {
          this.isdrpLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          // this.subscription$.push(NoteType);
        }
      );
  }
  saveCallback(){
    this.savebtn=true;
    this.isShowSpinner = true;
    const formData = this.CallbackForm.value;
     this.noteType=this.CallbackForm.value.drpNotetype.split('/')[1];
    this.noteTypeId=this.CallbackForm.value.drpNotetype.split('/')[0];
    this.InboundDocService
      .saveCallBack(this.data.documentId,formData.drpBranchType,formData.txtComments,this.noteTypeId,this.noteType,
        this.data.currentShortCodeId
      )
      .subscribe(
        (response) => {
          this.isShowSpinner = false;
          if(response.statusCode==200){
          this.toastr.success('Saved Successfully', 'Success');
          this.drpNotetype=this.listNoteType;
          this.selectNode.emit({ node: this.data.selectedNode });
          this.communicationService.triggerFunctionCall(this.data.selectedNode);
          this.reloadTableEvent.emit();
          this.dialog.close();
          this.resetCallBack();
          this.savebtn=false;
          }
          else{
            this.isShowSpinner = false;
            const data: HttpErrorResponse = response.message;
            Swal.fire({
              icon: 'info',
              text: response.message,
            });
            this.savebtn=false;
          }
      },
      (err) => {
        this.isShowSpinner = false;
        Swal.fire({
          icon: 'info',
          text: err.error.error.message,
        });
        this.savebtn=false;
      }
    );

  }

   //Update Character Count for TextArea
   updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > maxlength) {
      this.notesText = this.notesText?.substr(0, maxlength);
    }
  }


}
