<div class="row pb-2">
  <div class="col-lg-7 d-flex align-items-center">
    <h2 class="mb-2 header-text" mat-dialog-title>
      <B>Claim Info - {{ defaultSaleOrderId }}</B>
    </h2>
  </div>
  <div class="col-lg-5 text-right">
    <a
      [routerLink]="'/patientCreateEditTab/' + data?.patientId"
      target="_blank"
    >
      <button
        mat-button
        matTooltip="Click to View Patient Details"
        class="buttonColor mr-2"
      >
        Patient Details

        <!-- Patient Details -->
      </button>
    </a>
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <!-- <div class="col-lg-1 text-right"> -->
  <!-- <button type="button" mat-icon-button mat-dialog-close class="close-button">
      <mat-icon>close</mat-icon>
    </button> -->
  <!-- <button type="button" mat-icon-button  class="close-button" (click)="onCloseClick()">
      <mat-icon>close</mat-icon>
    </button> -->

  <!-- </div> -->
</div>

<mat-dialog-content class="mat-typography">
  <div class="card card-body">
    <mat-card-content>
      <div class="row">
        <h2 class="col-6">
          <b class="heading"
            >Chart Id - <span class="header-text">{{ data.chartNo }}</span></b
          >
          &nbsp;
          <b class="heading"
            >Patient Name -<span class="header-text">
              {{ data.fullName }}</span
            ></b
          >
        </h2>
        <h2 class="col-6 text-right">
          <b class="auth-status-header font-weight-bolder blinking">{{
            ClaimStatus
          }}</b>
        </h2>
        <h2 class="col-12 text-right text-danger" *ngIf="(claimForm.invalid || isSavebtn) && isLoadData">
          <span class="errorCls"><i class="fa fa-info-circle"></i> Missing Required Field : </span> {{findInvalidControls()}} <span *ngIf=" claimForm.invalid && isSavebtn"> ,24d.Modifiers 1</span>
         <span *ngIf=" !claimForm.invalid && isSavebtn"> 24d.Modifiers 1</span>
        </h2>
        <!-- Errors Div -->
        <div class="col-12" *ngIf="data?.isResubmit">
          <div class="card p-0 card-body bg-error">
            <div class="d-flex flex-row">
              <!-- <div class="col-1 d-flex align-items-center small-font p-0">
                <mat-icon class="text-danger pt-1 small-font ico-width">info</mat-icon>Error in Claim Form
              </div>
              <div class="col-11 small-font p-0">Test Test Test Test Test</div> -->

              <mat-icon
                *ngIf="ltErrors.length > 0"
                class="text-danger pt-1 ico-width"
                >info</mat-icon
              >

              <div *ngIf="ltErrors.length > 0" class="small-font p-0 ml-1">
                <ng-container class="fw-600"
                  ><b class="p-0 medium-font"
                    >Error in Claim Form:
                  </b></ng-container
                >
                <ng-container *ngFor="let data of ltErrors">
                  {{ data.serialNumber }}. {{ data.error }}.
                </ng-container>
              </div>
              <mat-icon
                *ngIf="ltErrors.length == 0"
                class="text-danger ico-width"
                >info</mat-icon
              >
              <div class="small-font p-0 ml-1" *ngIf="ltErrors.length == 0">
                No Error Data Found
              </div>
            </div>
          </div>
        </div>

        <form [formGroup]="claimForm">
          <!-- Row 1 -->
          <!-- Medicare -->
          <mat-checkbox class="col-3 pt-2" formControlName="chkMedicare"
            >1.Medicare(Medicare#)</mat-checkbox
          >
          <!-- Medicaid -->
          <mat-checkbox class="col-3 pt-2" formControlName="chkMedicaid"
            >1.Medicaid(Medicaid#)</mat-checkbox
          >
          <!-- Tricare -->
          <mat-checkbox class="col-3 pt-2" formControlName="chkTrciare"
            >1.Tricare(ID#/DoD#)</mat-checkbox
          >
          <!-- Champva -->
          <mat-checkbox class="col-3 pt-2" formControlName="chkChampva"
            >1.Champva(Member ID#)</mat-checkbox
          >

          <!-- Row 2 -->
          <!-- Group Health -->
          <mat-checkbox class="col-3 pt-2" formControlName="chkGroupPlan"
            >1.Group Health Plan(ID#)</mat-checkbox
          >
          <!-- Feca -->
          <mat-checkbox class="col-3 pt-2" formControlName="chkFeca"
            >1.Feca blk lung(ID#)</mat-checkbox
          >
          <!-- Other -->
          <mat-checkbox class="col-3 pt-2" formControlName="chkOther"
            >1.Other(ID#)</mat-checkbox
          >
          <!-- Insured Id Number  (Policy No)-->
          <mat-form-field class="col-3">
            <mat-label id="txtPolicyNo"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtPolicyNo')?.invalid
              }"
              >1a.Insured's I.D.Number<span class="asterisk">*</span></mat-label
            >
            <input
              autocomplete="new-txtPolicyNo"
              matInput
              readonly
              formControlName="txtPolicyNo"
              type="text"
            />
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('txtPolicyNo')?.touched &&
                  claimForm?.get('txtPolicyNo')?.errors?.required)
              "
            >
              Insured's I.D.Number is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Row 2 -->
          <!-- Patient Full Name -->
          <mat-form-field class="col-3">
            <mat-label id="txtFullName"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtFullName')?.invalid
              }"
            >
              2.Patient's Name<span class="asterisk">*</span
              >(LastName,FirstName,MiddleInitial)
            </mat-label>
            <input
              autocomplete="new-txtFullName"
              matInput
              readonly
              formControlName="txtFullName"
              type="text"
            />
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('txtFullName')?.touched &&
                  claimForm?.get('txtFullName')?.errors?.required)
              "
            >
              Patient's Name is a required field!
            </mat-error>
            <mat-error
              *ngIf="
                claimForm?.get('txtFullName')?.touched &&
                claimForm?.get('txtFullName')?.errors?.sAlphabets
              "
            >
              Enter only Alphabets!
            </mat-error>
          </mat-form-field>

          <!-- Patient Birth Date-->
          <mat-form-field class="col-3">
            <mat-label id="txtBirthdate"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtBirthdate')?.invalid
              }"
            >
              3.Patient's Birth Date <span class="asterisk">*</span></mat-label
            >
            <input
              autocomplete="new-txtBirthdate"
              [max]="BirthdateMaxDate"
              formControlName="txtBirthdate"
              matInput
              [disabled]="isSelect"
              maxlength="40"
              type="text"
              placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtBirthdate"
              [min]="BirthdateMinDate"
            />
            <mat-datepicker-toggle matSuffix [for]="txtBirthdate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtBirthdate> </mat-datepicker>
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('txtBirthdate')?.touched &&
                  claimForm?.get('txtBirthdate')?.errors &&
                  !(BirthdateMinDate <= claimForm?.get('txtBirthdate')?.value))
              "
            >
              Enter Valid date
            </mat-error>
            <mat-error
              *ngIf="
                claimForm?.get('txtBirthdate')?.touched &&
                claimForm?.get('txtBirthdate')?.errors?.required
              "
            >
              Birth Date is a required field!
            </mat-error>
            <mat-error
              *ngIf="
                BirthdateMinDate <= claimForm?.get('txtBirthdate')?.value &&
                !(
                  claimForm?.get('txtBirthdate')?.touched &&
                  claimForm?.get('txtBirthdate')?.errors?.required
                )
              "
            >
              Enter Past Dates Only!
            </mat-error>
          </mat-form-field>

          <!-- Gender -->
          <mat-form-field class="col-3">
            <mat-label id="drpGender"
              [ngClass]="{ 'is-invalid': claimForm?.get('drpGender')?.invalid }"
              >3.Gender <span class="asterisk">*</span></mat-label
            >
            <mat-select
              formControlName="drpGender"
              [(value)]="strGender"
              [disabled]="isSelect"
              maxlength="100"
            >
              <mat-option
                *ngFor="let test of drpGenderLoop"
                [value]="test.genderShortCode"
              >
                {{ test.genderName }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              (click)="strGender = ''; $event.stopPropagation()"
              *ngIf="strGender"
              matSuffix
              (click)="clearGender()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('drpGender')?.touched &&
                  claimForm?.get('drpGender')?.errors?.required)
              "
            >
              Gender is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Insured Full Name -->
          <mat-form-field class="col-3">
            <mat-label id="txtInsuredFullName"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtInsuredFullName')?.invalid
              }"
            >
              4.Insured's Name<span class="asterisk">*</span
              >(LastName,FirstName,MiddleInitial)
            </mat-label>
            <input
              autocomplete="new-txtInsuredFullName"
              matInput
              readonly
              formControlName="txtInsuredFullName"
              type="text"
            />
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('txtInsuredFullName')?.touched &&
                  claimForm?.get('txtInsuredFullName')?.errors?.required)
              "
            >
              Insured's Name is a required field!
            </mat-error>
            <mat-error
              *ngIf="
                claimForm?.get('txtInsuredFullName')?.touched &&
                claimForm?.get('txtInsuredFullName')?.errors?.sAlphabets
              "
            >
              Enter only Alphabets!
            </mat-error>
          </mat-form-field>

          <!-- Row 3-->

          <!-- Patient Address -->
          <mat-form-field class="col-3">
            <mat-label id="txtAddress"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtAddress')?.invalid
              }"
            >
              5.Patient's Address(No.,Street) <span class="asterisk">*</span>
            </mat-label>
            <textarea
              autocomplete="new-txtAddress"
              formControlName="txtAddress"
              rows="3"
              matInput
              readonly
              maxlength="100"
            ></textarea>
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('txtAddress')?.touched &&
                  claimForm?.get('txtAddress')?.errors?.required)
              "
            >
              Patient Address is a required field!
            </mat-error>
          </mat-form-field>

          <!-- State -->
          <mat-form-field class="col-3">
            <mat-label id="drpBillingState"
              [ngClass]="{
                'is-invalid': claimForm?.get('drpBillingState')?.invalid
              }"
              >5.State <span class="asterisk">*</span>
            </mat-label>
            <mat-select
              formControlName="drpBillingState"
              [(value)]="strBillingState"
              [disabled]="isSelect"
              (selectionChange)="onChangeBillCity($event?.value)"
              #singleSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="drpBillingStateFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let state of filteredBillingStates | async"
                [value]="state?.stateShortName"
              >
                {{ state?.stateName }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              (click)="strBillingState = undefined; $event.stopPropagation()"
              *ngIf="strBillingState"
              matSuffix
              (click)="clearBillingState()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('drpBillingState')?.touched &&
                  claimForm?.get('drpBillingState')?.errors?.required)
              "
            >
              State is a required field!
            </mat-error>
          </mat-form-field>

          <!-- City -->
          <mat-form-field class="col-3">
            <mat-label id="drpBillingCity"
              [ngClass]="{
                'is-invalid': claimForm?.get('drpBillingCity')?.invalid
              }"
              >5.City <span class="asterisk">*</span></mat-label
            >
            <mat-select
              formControlName="drpBillingCity"
              [(value)]="strBillingCity"
              [disabled]="isSelect"
              #singleSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="drpBillingCityFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let city of filteredBillingCities | async"
                [value]="city.cityName"
              >
                {{ city.cityName }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              (click)="strBillingCity = undefined; $event.stopPropagation()"
              *ngIf="strBillingCity"
              matSuffix
              (click)="clearBillingCity()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>

            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('drpBillingCity')?.touched &&
                  claimForm?.get('drpBillingCity')?.errors?.required)
              "
            >
              City is a required field!
            </mat-error>
          </mat-form-field>
          <!-- Zip Code -->
          <mat-form-field class="col-3">
            <mat-label id="txtBillingPostalCode"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtBillingPostalCode')?.invalid
              }"
            >
              5.Zip Code <span class="asterisk">*</span>
            </mat-label>
            <input
              autocomplete="new-txtBillingPostalCode"
              matInput
              [disabled]="isSelect"
              maxlength="10"
              formControlName="txtBillingPostalCode"
              type="text"
            />
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('txtBillingPostalCode')?.touched &&
                  claimForm?.get('txtBillingPostalCode')?.errors?.pattern)
              "
            >
              Enter only Numbers!
            </mat-error>

            <mat-error
              *ngIf="
                claimForm?.get('txtBillingPostalCode')?.touched &&
                claimForm?.get('txtBillingPostalCode')?.errors?.required
              "
            >
              Zip Code is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Row 4 -->
          <!-- Telephone -->
          <mat-form-field class="col-3">
            <span class="mr-1" matPrefix>{{ countryPhoneCode }}</span>
            <mat-label id="txtTelephone"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtTelephone')?.invalid
              }"
            >
              5.TelePhone(Include Area Code)
            </mat-label>

            <input
              autocomplete="new-txtTelephone"
              prefix="+1-"
              matInput
              formControlName="txtTelephone"
              mask="(000)-000-0000"
              type="text"
            />
            <mat-error
              *ngIf="
                claimForm?.get('txtTelephone')?.touched &&
                claimForm?.get('txtTelephone')?.errors
              "
            >
              Enter Minimum 10 Digits!
            </mat-error>
          </mat-form-field>

          <!-- Patient Relationship Insured-->
          <mat-form-field class="col-3">
            <mat-label id="drpRelationshipType"
              [ngClass]="{
                'is-invalid': claimForm?.get('drpRelationshipType')?.invalid
              }"
            >
              6.Patient Relationship to insured
              <span class="asterisk">*</span></mat-label
            >
            <mat-select
              [(value)]="strRelationship"
              formControlName="drpRelationshipType"
              maxlength="100"
            >
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="txtRelationshipTypeControl"
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtRelationshipType"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let relationship of filteredRelationShip"
                [value]="relationship.relationShipCode"
              >
                {{ relationship.relationType }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              (click)="strRelationship = undefined; $event.stopPropagation()"
              *ngIf="strRelationship"
              matSuffix
              (click)="clearRelationship()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('drpRelationshipType').touched &&
                  claimForm?.get('drpRelationshipType')?.errors?.required)
              "
            >
              Relationship is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Insured's Address -->
          <mat-form-field class="col-3">
            <mat-label id="txtInsAddress"> 7.Insured's Address(No.,Street) </mat-label>
            <textarea
              autocomplete="new-txtInsAddress"
              formControlName="txtInsAddress"
              rows="3"
              matInput
              maxlength="100"
            ></textarea>
            <mat-error
              *ngIf="
                claimForm?.get('txtInsAddress')?.touched &&
                claimForm?.get('txtInsAddress')?.errors?.required
              "
            >
              Insured Address is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Insured's State -->
          <mat-form-field class="col-3">
            <mat-label id="drpResponsibleState"> 7.State</mat-label>
            <mat-select
              formControlName="drpResponsibleState"
              (selectionChange)="onChangeResCity($event?.value)"
              [(value)]="strResponsibleState"
              #singleSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="drpResponsibleStateFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let state of filteredResponsibleStates | async"
                [value]="state?.stateShortName"
              >
                {{ state?.stateName }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              *ngIf="claimForm?.value?.drpResponsibleState"
              matSuffix
              (click)="clearResponsibleState(); $event.stopPropagation()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf="
                claimForm?.get('drpResponsibleState')?.touched &&
                claimForm?.get('drpResponsibleState')?.errors?.required
              "
            >
              State is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Row 5 -->
          <!-- Insured's City-->
          <mat-form-field class="col-3">
            <mat-label id="drpResponsibleCity">7.City </mat-label>
            <mat-select
              formControlName="drpResponsibleCity"
              [(value)]="strResponsibleCity"
              #singleSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="drpResponsibleCityFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let city of filteredResponsibleCities | async"
                [value]="city.cityName"
              >
                {{ city.cityName }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              *ngIf="claimForm?.value?.drpResponsibleCity"
              matSuffix
              (click)="clearResponsibleCity(); $event.stopPropagation()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>

            <mat-error
              *ngIf="
                claimForm?.get('drpResponsibleCity')?.touched &&
                claimForm?.get('drpResponsibleCity')?.errors?.required
              "
            >
              City is a required field!
            </mat-error>
          </mat-form-field>

          <!-- ZIP Code -->
          <mat-form-field class="col-3">
            <mat-label id="txtRespPostalCode"> 7.Zip Code </mat-label>
            <input
              autocomplete="new-txtRespPostalCode"
              matInput
              maxlength="10"
              formControlName="txtRespPostalCode"
              type="text"
            />
            <mat-error
              *ngIf="
                claimForm?.get('txtRespPostalCode')?.touched &&
                claimForm?.get('txtRespPostalCode')?.errors?.pattern
              "
            >
              Enter only Numbers!
            </mat-error>
            <mat-error
              *ngIf="
                claimForm?.get('txtRespPostalCode')?.touched &&
                claimForm?.get('txtRespPostalCode')?.errors?.required
              "
            >
              Zip Code is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Telephone -->
          <mat-form-field class="col-3">
            <span class="mr-1" matPrefix>{{ countryPhoneCode }}</span>
            <mat-label id="txtInsTelephone"> 7.TelePhone(Include Area Code) </mat-label>
            <input
              autocomplete="new-txtInsTelephone"
              matInput
              prefix="+1-"
              formControlName="txtInsTelephone"
              mask="(000)-000-0000"
              type="text"
            />
            <mat-error
              *ngIf="
                claimForm?.get('txtInsTelephone')?.touched &&
                claimForm?.get('txtInsTelephone')?.errors
              "
            >
              Enter Minimum 10 Digits!
            </mat-error>
          </mat-form-field>

          <!-- Reserved for NUCC -->
          <mat-form-field class="col-3">
            <mat-label id="txtReservedForNucc"> 8.Reserved for NUCC </mat-label>
            <input
              autocomplete="new-txtReservedForNucc"
              matInput
              maxlength="100"
              formControlName="txtReservedForNucc"
              type="text"
            />
          </mat-form-field>

          <!-- Row 6-->
          <!-- Other Insured's Name -->
          <mat-form-field class="col-3">
            <mat-label id="txtReservedForNucc">
              9.Other Insured's Name(LastName,FirstName,MiddleInitial)
            </mat-label>
            <input
              autocomplete="new-txtReservedForNucc"
              matInput
              maxlength="100"
              formControlName="txtInsReservedForNucc"
              type="text"
            />
          </mat-form-field>

          <!-- Other Insured's Policy or Group Number-->
          <mat-form-field class="col-3">
            <mat-label id="txtPolicyGroup"> 9a.Other Insured's Policy Or Group Number </mat-label>
            <input
              autocomplete="new-txtPolicyGroup"
              matInput
              maxlength="100"
              formControlName="txtPolicyGroup"
              type="text"
            />
          </mat-form-field>

          <!-- Reserved for NUCC Use -->
          <mat-form-field class="col-3">
            <mat-label id="txtOtherInsuredPolicy"> 9b.Reserved For NUCC Use</mat-label>
            <input
              autocomplete="new-txtOtherInsuredPolicy"
              matInput
              maxlength="100"
              formControlName="txtOtherInsuredPolicy"
              type="text"
            />
          </mat-form-field>

          <!-- Reserved for NUCC Use -->
          <mat-form-field class="col-3">
            <mat-label id="txtReservedNucc"> 9c.Reserved For NUCC Use</mat-label>
            <input
              autocomplete="new-txtReservedNucc"
              matInput
              maxlength="100"
              formControlName="txtReservedNucc"
              type="text"
            />
          </mat-form-field>

          <!-- Row 7-->
          <!-- Insurance Plan Name or Program Name -->
          <mat-form-field class="col-3">
            <mat-label id="txtInsPlanName"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtInsPlanName')?.invalid
              }"
            >
              9d.Insurance PlanName Or ProgramName
              <span class="asterisk">*</span></mat-label
            >
            <mat-select
              formControlName="txtInsPlanName"
              [(value)]="strPayers"
              [disabled]="isSelect"
              #singleSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="payerCode / payerName / Code"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtPayerFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let payer of filteredPayerTypes | async"
                [value]="payer?.payerId"
              >
                {{ payer?.payerShortCodeList }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('txtInsPlanName')?.touched &&
                  claimForm?.get('txtInsPlanName')?.errors?.required)
              "
            >
              Insurance PlanName Or ProgramName is a required field!
            </mat-error>
            <button
              mat-button
              (click)="strPayers = undefined; $event?.stopPropagation()"
              *ngIf="strPayers"
              matSuffix
              (click)="clearPayers()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <!-- Patient Condition Related To-->
          <mat-label class="col-3 pt-2">
            10.Is Patient's Condition Related To</mat-label
          >
          <!-- Employment-->
          <mat-checkbox class="col-3 pt-2 pl-5" formControlName="chkEmployment"
            >10a.Employment(Current or Previous)
          </mat-checkbox>
          <!-- AutoAccident-->
          <mat-checkbox
            class="col-3 pt-2 pl-5 ml-2"
            formControlName="chkAutoAccident"
            >10b.AutoAccident?</mat-checkbox
          >

          <!-- Row 8 -->
          <!-- Other Accident -->
          <mat-checkbox class="col-3 pt-2" formControlName="chkOtherAccident"
            >10c.OtherAccident?</mat-checkbox
          >
          <!-- Claim Codes -->
          <mat-checkbox class="col-3 pt-2" formControlName="chkClaimCodes"
            >10d.Claim Codes (Designated By NUCC)
          </mat-checkbox>

          <!-- Insured Policy Group Or Feca Number -->
          <mat-form-field class="col-3">
            <mat-label id="txtInsuredPolicyGroup"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtInsuredPolicyGroup')?.invalid
              }"
              >11.Insured's Policy Group Or Feca Number</mat-label
            >

            <input
              autocomplete="new-txtReservedForNucc"
              matInput
              readonly
              maxlength="100"
              formControlName="txtInsuredPolicyGroup"
              type="text"
            />
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('txtInsuredPolicyGroup')?.touched &&
                  claimForm?.get('txtInsuredPolicyGroup')?.errors?.required)
              "
            >
              Gender is a required field!
            </mat-error>
            <!-- <mat-select
              formControlName="txtInsPlanName"
              [(value)]="strPayers"
              #singleSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtPayerFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let payer of filteredPayerTypes | async"
                [value]="payer?.id"
              >
                {{ payer?.payerCodeName }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                claimForm?.get('txtInsPlanName')?.touched &&
                claimForm?.get('txtInsPlanName')?.errors?.required
              "
            >
              Insured's Policy Group is a required field!
            </mat-error>
            <button
              mat-button
              (click)="strPayers = undefined; $event?.stopPropagation()"
              *ngIf="strPayers"
              matSuffix
              (click)="clearPayers()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button> -->
          </mat-form-field>

          <!-- Insured's Date of Birth -->
          <mat-form-field class="col-3">
            <mat-label id="txtInsBirthdate">11a.Insured's Date Of Birth </mat-label>
            <input
              autocomplete="new-txtInsBirthdate"
              [max]="BirthdateMaxDate"
              formControlName="txtInsBirthdate"
              matInput
              maxlength="40"
              type="text"
              placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtInsuredDob"
              [min]="BirthdateMinDate"
            />
            <mat-datepicker-toggle matSuffix [for]="txtInsuredDob">
            </mat-datepicker-toggle>
            <mat-datepicker #txtInsuredDob> </mat-datepicker>
            <mat-error
              *ngIf="
                claimForm?.get('txtInsBirthdate')?.touched &&
                claimForm?.get('txtInsBirthdate')?.errors &&
                !(BirthdateMinDate <= claimForm?.get('txtInsBirthdate')?.value)
              "
            >
              Enter Valid date
            </mat-error>
            <mat-error
              *ngIf="
                claimForm?.get('txtInsBirthdate')?.touched &&
                claimForm?.get('txtInsBirthdate')?.errors?.required
              "
            >
              Insured's Date Of Birth is a required field!
            </mat-error>
            <mat-error
              *ngIf="
                BirthdateMinDate <= claimForm?.get('txtInsBirthdate')?.value &&
                !(
                  claimForm?.get('txtInsBirthdate')?.touched &&
                  claimForm?.get('txtInsBirthdate')?.errors?.required
                )
              "
            >
              Enter Past Dates Only!
            </mat-error>
          </mat-form-field>

          <!-- Row 9 -->
          <!-- Insured's Gender -->
          <mat-form-field class="col-3">
            <mat-label id="txtInsGender">11a.Gender</mat-label>
            <mat-select formControlName="drpInsGender" maxlength="100">
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="txtInsGenderControl"
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtInsGender"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let test of filterGender"
                [value]="test.genderShortCode"
              >
                {{ test.genderName }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              *ngIf="claimForm?.value?.drpInsGender"
              matSuffix
              (click)="clearInsGender(); $event.stopPropagation()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf="
                claimForm?.get('drpInsGender')?.touched &&
                claimForm?.get('drpInsGender')?.errors?.required
              "
            >
              Gender is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Other Claim Id's-->
          <mat-form-field class="col-3">
            <mat-label id="txtInsPlanName"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtInsPlanName')?.invalid
              }"
            >
              11b.Other ClaimId(Designated by NUCC)
              <span class="asterisk">*</span></mat-label
            >
            <mat-select
              formControlName="txtInsPlanName"
              [(value)]="strPayers"
              #singleSelect
              [disabled]="isSelect"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="payerCode / payerName / Code"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtPayerFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let payer of filteredPayerTypes | async"
                [value]="payer?.payerId"
              >
                {{ payer?.payerShortCodeList }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('txtInsPlanName')?.touched &&
                  claimForm?.get('txtInsPlanName')?.errors?.required)
              "
            >
              Other ClaimId(Designated by NUCC) is a required field!
            </mat-error>
            <button
              mat-button
              (click)="strPayers = undefined; $event?.stopPropagation()"
              *ngIf="strPayers"
              matSuffix
              (click)="clearPayers()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <!-- Insurance Plan Name or Program Name-->
          <mat-form-field class="col-3">
            <mat-label id="txtInsPlanName"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtInsPlanName')?.invalid
              }"
              >11c.Insurance PlanName Or ProgramName
              <span class="asterisk">*</span></mat-label
            >
            <mat-select
              formControlName="txtInsPlanName"
              [(value)]="strPayers"
              #singleSelect
              [disabled]="isSelect"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="payerCode / payerName / Code"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtPayerFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let payer of filteredPayerTypes | async"
                [value]="payer?.payerId"
              >
                {{ payer?.payerShortCodeList }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('txtInsPlanName')?.touched &&
                  claimForm?.get('txtInsPlanName')?.errors?.required)
              "
            >
              Insurance PlanName Or ProgramName is a required field!
            </mat-error>
            <button
              mat-button
              (click)="strPayers = undefined; $event?.stopPropagation()"
              *ngIf="strPayers"
              matSuffix
              (click)="clearPayers()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <!-- Another Health Benefit Plan -->
          <mat-form-field class="col-3">
            <mat-label id="txtInsPlanName"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtInsPlanName')?.invalid
              }"
              >11d.Is There Another Health Benefit Plan?
              <span class="asterisk">*</span></mat-label
            >
            <mat-select
              formControlName="txtInsPlanName"
              [(value)]="strPayers"
              #singleSelect
              [disabled]="isSelect"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="payerCode / payerName / Code"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtPayerFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let payer of filteredPayerTypes | async"
                [value]="payer?.payerId"
              >
                {{ payer?.payerShortCodeList }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('txtInsPlanName')?.touched &&
                  claimForm?.get('txtInsPlanName')?.errors?.required)
              "
            >
              11d.Is There Another Health Benefit Plan? is a required field!
            </mat-error>
            <button
              mat-button
              (click)="strPayers = undefined; $event?.stopPropagation()"
              *ngIf="strPayers"
              matSuffix
              (click)="clearPayers()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <!-- Row 10-->
          <!-- Signature -->
          <div class="col-12">
            <mat-label >
              12.Patient's Or Authorized Person's Signature I authorize the
              release of any medical or other infromation necessary to process
              this claim. I also request payment of government benefits either
              to myself or to the party who accepts assignment below</mat-label
            >
          </div>
          <!-- Row 11-->
          <!--Signed -->
          <mat-form-field class="col-6">
            <mat-label id="txtSigned"> Signed</mat-label>
            <input
              autocomplete="new-txtSigned"
              matInput
              maxlength="100"
              formControlName="txtSigned"
              type="text"
            />
          </mat-form-field>
          <!-- Signed Date -->
          <mat-form-field class="col-6">
            <mat-label id="txtDate"> Date</mat-label>
            <input
              autocomplete="new-txtDate"
              formControlName="txtDate"
              matInput
              maxlength="40"
              type="text"
              placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtDate"
            />
            <mat-datepicker-toggle matSuffix [for]="txtDate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtDate> </mat-datepicker>
            <mat-error
              *ngIf="
                claimForm?.get('txtDate')?.touched &&
                claimForm?.get('txtDate')?.errors &&
                !(BirthdateMinDate <= claimForm?.get('txtDate')?.value)
              "
            >
              Enter Valid date
            </mat-error>
          </mat-form-field>

          <!-- Row 12 -->
          <!-- Insured's Person Signature-->
          <div class="col-12">
            <mat-label>
              13.Insured's Or Authorized Person's Signature I authorize the
              release of any medical or other infromation necessary to process
              this claim. I also request payment of government benefits either
              to myself or to the party who accepts assignment below</mat-label
            >
          </div>
          <!-- Row 13 -->
          <!-- Signed -->
          <mat-form-field class="col-12">
            <mat-label id="txtInsSigned"> 13.Signed</mat-label>
            <input
              autocomplete="new-txtSigned"
              matInput
              maxlength="100"
              formControlName="txtInsSigned"
              type="text"
            />
          </mat-form-field>

          <!-- Row 14 -->
          <!-- Date of Current Illness-->
          <mat-form-field class="col-6">
            <mat-label id="txtCurrentIllnessDate">
              14.Date Of Current Illness,Injury Or Pregnancy(LMP)</mat-label
            >
            <input
              autocomplete="new-txtCurrentIllnessDate"
              formControlName="txtCurrentIllnessDate"
              matInput
              maxlength="40"
              type="text"
              placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtCurrentIllnessDate"
            />
            <mat-datepicker-toggle matSuffix [for]="txtCurrentIllnessDate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtCurrentIllnessDate> </mat-datepicker>
            <mat-error
              *ngIf="
                claimForm?.get('txtCurrentIllnessDate')?.touched &&
                claimForm?.get('txtCurrentIllnessDate')?.errors &&
                !(
                  BirthdateMinDate <=
                  claimForm?.get('txtCurrentIllnessDate')?.value
                )
              "
            >
              Enter Valid date
            </mat-error>
          </mat-form-field>

          <!-- Other Date -->
          <mat-form-field class="col-6">
            <mat-label id="txtOtherDate"> 15.Other Date</mat-label>
            <input
              autocomplete="new-txtOtherDate"
              formControlName="txtOtherDate"
              matInput
              maxlength="40"
              type="text"
              placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtOtherDate"
            />
            <mat-datepicker-toggle matSuffix [for]="txtOtherDate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtOtherDate> </mat-datepicker>
            <mat-error
              *ngIf="
                claimForm?.get('txtOtherDate')?.touched &&
                claimForm?.get('txtOtherDate')?.errors &&
                !(BirthdateMinDate <= claimForm?.get('txtOtherDate')?.value)
              "
            >
              Enter Valid date
            </mat-error>
          </mat-form-field>

          <!-- Row 15 -->
          <div class="col-12">
            <mat-label>
              16.Dates Patient Unable to work in Current Occupation</mat-label
            >
          </div>

          <!-- Row 16 -->
          <!-- From Date -->
          <mat-form-field class="col-6">
            <mat-label id="txtFromDate"> 16.From Date</mat-label>
            <input
              autocomplete="new-txtFromDate"
              formControlName="txtFromDate"
              matInput
              maxlength="40"
              type="text"
              placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtFromDate"
            />
            <mat-datepicker-toggle matSuffix [for]="txtFromDate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtFromDate> </mat-datepicker>
            <mat-error
              *ngIf="
                claimForm?.get('txtFromDate')?.touched &&
                claimForm?.get('txtFromDate')?.errors &&
                !(BirthdateMinDate <= claimForm?.get('txtFromDate')?.value)
              "
            >
              Enter Valid date
            </mat-error>
          </mat-form-field>

          <!-- To Date -->
          <mat-form-field class="col-6">
            <mat-label id="txtToDate"> 16.To Date</mat-label>
            <input
              autocomplete="new-txtToDate"
              formControlName="txtToDate"
              matInput
              maxlength="40"
              type="text"
              placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtToDate"
            />
            <mat-datepicker-toggle matSuffix [for]="txtToDate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtToDate> </mat-datepicker>
            <mat-error
              *ngIf="
                claimForm?.get('txtToDate')?.touched &&
                claimForm?.get('txtToDate')?.errors &&
                !(BirthdateMinDate <= claimForm?.get('txtToDate')?.value)
              "
            >
              Enter Valid date
            </mat-error>
          </mat-form-field>

          <!-- Name of Referring Provider -->
          <mat-form-field class="col-6">
            <mat-label id="drpReferringDoctor"
              [ngClass]="{
                'is-invalid': claimForm?.get('drpReferringDoctor')?.invalid
              }"
            >
              17.Name Of Referring Provider Or Other Source
              <span class="asterisk">*</span>
            </mat-label>
            <mat-select
              formControlName="drpReferringDoctor"
              [(value)]="strReferringDoctor"
              (selectionChange)="onChangeProvider($event)"
              #singleSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  [noEntriesFoundLabel]="drpDownMessage()"
                  hideClearSearchButton="true"
                  formControlName="txtDoctorFilter"
                  (keyup)="searchDoctor($event.target.value)"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let doctor of filteredDoctorTypes | async"
                [value]="doctor.id"
              >
                {{ doctor.doctorName }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              (click)="strReferringDoctor = ''; $event.stopPropagation()"
              *ngIf="strReferringDoctor"
              (click)="clearReferringDoctor()"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('drpReferringDoctor')?.touched &&
                  claimForm?.get('drpReferringDoctor')?.errors?.required)
              "
            >
              Name of Referring Provider Or Other Source !
            </mat-error>
          </mat-form-field>

          <!-- Referring Provider Details -->
          <mat-form-field class="col-3">
            <mat-label id="txt17a"> 17a.Referring Provider</mat-label>
            <input
              autocomplete="new-txt17a"
              matInput
              readonly
              maxlength="100"
              formControlName="txt17a"
              type="text"
            />
          </mat-form-field>

          <!-- Referring Provider NPI -->
          <mat-form-field class="col-3">
            <mat-label id="txtReferringProviderNPI"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtReferringProviderNPI')?.invalid
              }"
            >
              17b.NPI <span class="asterisk">*</span>
            </mat-label>
            <input
              autocomplete="new-txtReferringProviderNPI"
              formControlName="txtReferringProviderNPI"
              matInput
              readonly
              maxlength="100"
              type="text"
            />

            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('txtReferringProviderNPI')?.touched &&
                  claimForm?.get('txtReferringProviderNPI')?.errors?.required)
              "
            >
              NPI Or ProgramName is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Row 17 -->
          <!-- Hospitalization Dates -->
          <div class="col-12">
            <mat-label >
              18.Hospitalization Dates Related To Current Services</mat-label
            >
          </div>
          <!-- From Date -->
          <mat-form-field class="col-6">
            <mat-label id="txtHosFromDate"> 18.From Date</mat-label>
            <input
              autocomplete="new-txtHosFromDate"
              formControlName="txtHosFromDate"
              matInput
              maxlength="40"
              type="text"
              placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtHosFromDate"
            />
            <mat-datepicker-toggle matSuffix [for]="txtHosFromDate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtHosFromDate> </mat-datepicker>
            <mat-error
              *ngIf="
                claimForm?.get('txtHosFromDate')?.touched &&
                claimForm?.get('txtHosFromDate')?.errors &&
                !(BirthdateMinDate <= claimForm?.get('txtHosFromDate')?.value)
              "
            >
              Enter Valid date
            </mat-error>
          </mat-form-field>
          <!-- To Date -->
          <mat-form-field class="col-6">
            <mat-label id="txtHosToDate"> 18.To Date</mat-label>
            <input
              autocomplete="new-txtHosToDate"
              formControlName="txtHosToDate"
              matInput
              maxlength="40"
              type="text"
              placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtHosToDate"
            />
            <mat-datepicker-toggle matSuffix [for]="txtHosToDate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtHosToDate> </mat-datepicker>
            <mat-error
              *ngIf="
                claimForm?.get('txtHosToDate')?.touched &&
                claimForm?.get('txtHosToDate')?.errors &&
                !(BirthdateMinDate <= claimForm?.get('txtHosToDate')?.value)
              "
            >
              Enter Valid date
            </mat-error>
          </mat-form-field>

          <!-- Row 18-->
          <!-- Addition Claim Information -->
          <mat-form-field class="col-6">
            <mat-label id="txtAdditionalClaimInfo">
              19.Additional Claim Information (Designated by NUCC)</mat-label
            >
            <input
              autocomplete="new-txtAdditionalClaimInfo"
              matInput
              maxlength="100"
              formControlName="txtAdditionalClaimInfo"
              type="text"
            />
          </mat-form-field>

          <!-- Outside Lab -->
          <mat-form-field class="col-3">
            <mat-label id="chkOutsideLab"> 20.OutSideLab</mat-label>
            <input
              autocomplete="new-chkOutsideLab"
              matInput
              maxlength="100"
              formControlName="chkOutsideLab"
              type="text"
            />
          </mat-form-field>

          <!-- Charges -->
          <mat-form-field class="col-3">
            <mat-label id="txtCharges"> 20.$Charges</mat-label>
            <input
              autocomplete="new-txtCharges"
              matInput
              maxlength="100"
              (keypress)="onKeyPress($event)"
              formControlName="txtCharges"
              type="number"
            />
          </mat-form-field>

          <!-- Row 19 -->
          <!-- Diagnosis Codes -->
          <div class="col-12">
            <mat-label >
              21.Diagnosis Or Nature Of Illness Or Injury Relate A-L to service
              line below
              <span class="asterisk">*</span>
            </mat-label>
          </div>

          <!-- Diagnosis Code ICD-10-->
          <mat-form-field class="col-6">
            <mat-label id="drpDiag10ID"
              [ngClass]="{
                'is-invalid': claimForm?.get('drpDiag10ID')?.invalid
              }"
              >21.Diagnosis Code-ICD-10 <span class="asterisk">*</span></mat-label
            >
            <mat-select class="MatSelect-Width"
              multiple
              formControlName="drpDiag10ID"
              [(value)]="lstIcd10"
              hideClearSearchButton="true"
              #singleSelect
              (selectionChange)="onSelectionChange($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="claimForm?.get('drpDiag10ID')?.errors?.maxSelection?
                  ' Maximum 12 diagnosis codes only select!':'Search'"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtIcd10Filter"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let diagnosis of filteredIcd10Types | async"
                [value]="diagnosis.diagnosisCode"
              >
                {{
                  diagnosis.diagnosisCode +
                    " / " +
                    diagnosis.diagnosisCodeTypeData
                }}
              </mat-option>
            </mat-select>
            <button
              (click)="lstIcd10 = []; $event.stopPropagation()"
              *ngIf="lstIcd10?.length !== 0"
              (click)="clearIcd10()"
              mat-icon-button matSuffix
            >
              <mat-icon>close</mat-icon>
            </button> &nbsp;
          <a [matTooltip]="'Add Diagnosis Code'"  matSuffix style="margin-left: 10px;"
          (click)="$event.stopPropagation(); openIcd10Modal() "
          >
          <img  class="img-responsive"
                      src="assets/Billing Icons/addIcon.png" height="28" /></a>
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('drpDiag10ID')?.touched &&
                  claimForm?.get('drpDiag10ID')?.errors?.required)
              "
            >
              Diagnosis Code-ICD-10 is a required field!
            </mat-error>
            <mat-error
            *ngIf="
              (claimForm?.get('drpDiag10ID')?.touched &&
                claimForm?.get('drpDiag10ID')?.errors?.maxSelection)
            "
          >
            Maximum 12 diagnosis codes only select!
          </mat-error>
          </mat-form-field>
          <!-- Diagnosis Codes -->
          <mat-form-field class="col-2">
            <mat-label id="txtIcdTen1"> 21a.Diagnosis Code-ICD-10</mat-label>
            <input
              autocomplete="new-txtIcdTen1"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtIcdTen1"
              type="text"
            />
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label id="txtIcdTen2"> 21b.Diagnosis Code-ICD-10</mat-label>
            <input
              autocomplete="new-txtIcdTen2"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtIcdTen2"
              type="text"
            />
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label id="txtIcdTen3"> 21c.Diagnosis Code-ICD-10</mat-label>
            <input
              autocomplete="new-txtIcdTen3"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtIcdTen3"
              type="text"
            />
          </mat-form-field>
          <!-- Row 20-->
          <mat-form-field class="col-2">
            <mat-label id="txtIcdTen4"> 21d.Diagnosis Code-ICD-10</mat-label>
            <input
              autocomplete="new-txtIcdTen4"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtIcdTen4"
              type="text"
            />
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label id="txtIcdTen5"> 21e.Diagnosis Code-ICD-10</mat-label>
            <input
              autocomplete="new-txtIcdTen5"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtIcdTen5"
              type="text"
            />
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label id="txtIcdTen6"> 21f.Diagnosis Code-ICD-10</mat-label>
            <input
              autocomplete="new-txtIcdTen6"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtIcdTen6"
              type="text"
            />
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label id="txtIcdTen7"> 21g.Diagnosis Code-ICD-10</mat-label>
            <input
              autocomplete="new-txtIcdTen7"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtIcdTen7"
              type="text"
            />
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label id="txtIcdTen8"> 21h.Diagnosis Code-ICD-10</mat-label>
            <input
              autocomplete="new-txtIcdTen8"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtIcdTen8"
              type="text"
            />
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label id="txtIcdTen9"> 21i.Diagnosis Code-ICD-10</mat-label>
            <input
              autocomplete="new-txtIcdTen9"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtIcdTen9"
              type="text"
            />
          </mat-form-field>

          <!-- Row 21 -->
          <mat-form-field class="col-2">
            <mat-label id="txtIcdTen10"> 21j.Diagnosis Code-ICD-10</mat-label>
            <input
              autocomplete="new-txtIcdTen10"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtIcdTen10"
              type="text"
            />
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label id="txtIcdTen11"> 21k.Diagnosis Code-ICD-10</mat-label>
            <input
              autocomplete="new-txtIcdTen11"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtIcdTen11"
              type="text"
            />
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label id="txtIcdTen12"> 21l.Diagnosis Code-ICD-10</mat-label>
            <input
              autocomplete="new-txtIcdTen12"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtIcdTen12"
              type="text"
            />
          </mat-form-field>
          <!-- Codes End -->

          <!-- Resubmission Code -->
          <mat-form-field class="col-3">
            <mat-label id="txtResubmissionCode"> 22.Resubmission Code</mat-label>
            <input
              autocomplete="new-txtResubmissionCode"
              matInput
              maxlength="100"
              formControlName="txtResubmissionCode"
              type="text"
            />
          </mat-form-field>
          <!-- Original Ref No-->
          <mat-form-field class="col-3">
            <mat-label id="txtOriginalRefNo"> 22.Original Ref No</mat-label>
            <input
              autocomplete="new-txtOriginalRefNo"
              matInput
              maxlength="100"
              formControlName="txtOriginalRefNo"
              type="text"
            />
          </mat-form-field>

          <!-- Row 22 -->
          <!-- Prior Authorization Number -->
          <mat-form-field class="col-3">
            <mat-label id="txtPriorNumber"> 23.Prior Authorization Number</mat-label>
            <input
              autocomplete="new-txtPriorNumber"
              matInput
              readonly
              maxlength="100"
              formControlName="txtPriorNumber"
              type="text"
            />
          </mat-form-field>

          <!-- Federal Tax ID Number -->
          <mat-form-field class="col-3">
            <mat-label id="txtFederalTaxIdNumber"> 25.Federal Tax ID Number</mat-label>
            <input
              autocomplete="new-txtFederalTaxIdNumber"
              matInput
              readonly
              maxlength="100"
              formControlName="txtFederalTaxIdNumber"
              type="text"
            />
          </mat-form-field>

          <!-- SSN -->
          <mat-checkbox class="col-3" formControlName="chkSsn"
            >25.SSN</mat-checkbox
          >
          <!-- EIN -->
          <mat-checkbox class="col-3" formControlName="chkEin"
            >25.EIN</mat-checkbox
          >
          <!-- Row 23 -->
          <!-- Patient Account Number -->
          <mat-form-field class="col-3">
            <mat-label id="txtPtAccountNumber"> 26.Patient's Account Number</mat-label>
            <input
              autocomplete="new-txtPtAccountNumber"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtPtAccountNumber"
              type="text"
            />
          </mat-form-field>

          <!-- Accept Assignment -->
          <mat-checkbox class="col-3" formControlName="chkAcceptAssignment"
            >27.Accept Assignment</mat-checkbox
          >

          <!-- Total Charge -->
          <mat-form-field class="col-3">
            <mat-label id="txtTotalCharge"> 28.$Total Charge </mat-label>
            <input
              autocomplete="new-txtTotalCharge"
              matInput
              readonly
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtTotalCharge"
              type="number"
            />
            <mat-error
              *ngIf="
                claimForm?.get('txtTotalCharge')?.touched &&
                claimForm?.get('txtTotalCharge')?.errors?.required
              "
            >
              Total Charge is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Amount Paid -->
          <mat-form-field class="col-3">
            <mat-label id="txtAmountPaid"> 29.$Amount Paid</mat-label>
            <input
              autocomplete="new-txtAmountPaid"
              matInput
              maxlength="100"
              formControlName="txtAmountPaid"
              (keypress)="onKeyPress($event)"
              type="number"
            />
          </mat-form-field>

          <!-- Row 24-->
          <!-- RSVD for NUCC Use-->
          <mat-form-field class="col-12">
            <mat-label id="txtRsvd"> 30.Rsvd for NUCC Use</mat-label>
            <input
              autocomplete="new-txtRsvd"
              matInput
              maxlength="100"
              formControlName="txtRsvd"
              type="text"
            />
          </mat-form-field>
          <!-- Row 25-->
          <!-- Signature of Physician-->
          <div class="col-12">
            <mat-label >
              31. Signature Of Physician or supplier including degrees or
              credentials (I certify that the statements on the reverse apply to
              this bill and are made a part thereof.)</mat-label
            >
          </div>
          <!-- Row 26-->
          <!-- Signed -->
          <mat-form-field class="col-6">
            <mat-label id="drpProvider"
              [ngClass]="{
                'is-invalid': claimForm?.get('drpProvider')?.invalid
              }"
            >
              31.Signed<span class="asterisk">*</span></mat-label
            >
            <!-- <input matInput    maxlength="40" formControlName="txtPhysicianSigned" type="text" /> -->
            <mat-select
              maxlength="100"
              [(value)]="strProvider"
              formControlName="drpProvider"
              (selectionChange)="onChangeProvidersUpdate($event?.value)"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  [formControl]="txtdrpProviderContron"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let providers of drpProviders"
                [value]="providers.shortCodeId"
              >
                {{ providers.fullName }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              (click)="strProvider = ''; $event.stopPropagation()"
              *ngIf="strProvider"
              matSuffix
              (click)="clearProvider()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf="
                submitted ||
                (claimForm?.get('drpProvider')?.touched &&
                  claimForm?.get('drpProvider')?.errors?.required)
              "
            >
              Provider is a required field!
            </mat-error>
          </mat-form-field>
          <!-- Date -->
          <mat-form-field class="col-6">
            <mat-label id="txtPhysicianSignedDate"> 31.Date</mat-label>
            <input
              autocomplete="new-txtPhysicianSignedDate"
              formControlName="txtPhysicianSignedDate"
              matInput
              maxlength="40"
              type="text"
              placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtPhysicianSignedDate"
            />
            <mat-datepicker-toggle matSuffix [for]="txtPhysicianSignedDate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtPhysicianSignedDate> </mat-datepicker>
            <mat-error
              *ngIf="
                claimForm?.get('txtPhysicianSignedDate')?.touched &&
                claimForm?.get('txtPhysicianSignedDate')?.errors &&
                !(
                  BirthdateMinDate <=
                  claimForm?.get('txtPhysicianSignedDate')?.value
                )
              "
            >
              Enter Valid date
            </mat-error>
          </mat-form-field>

          <!-- Row 27-->
          <!-- Service Facility Location Information -->
          <mat-form-field class="col-3">
            <mat-label id="txtServiceInfo"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtServiceInfo')?.invalid
              }"
              >32.Service Facility Location Information
              <span class="asterisk">*</span></mat-label
            >
            <mat-select
              maxlength="100"
              formControlName="txtServiceInfo"
              [(value)]="strPlaceOfService"
              (selectionChange)="onChangeServiceFacility($event)"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  [formControl]="txtServiceInfoControl"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="drpServiceInfo"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let pos of filterServiceFacility"
                [value]="pos.id"
              >
                {{ pos.placeOfServiceType }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              (click)="clearPlaceOfService()"
              (click)="strPlaceOfService = ''; $event.stopPropagation()"
              *ngIf="strPlaceOfService"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              *ngIf="
                claimForm?.get('txtServiceInfo')?.touched &&
                claimForm?.get('txtServiceInfo')?.errors?.required
              "
            >
              Place Of Service is a required field!
            </mat-error>
          </mat-form-field>
          <!-- 32a-->
          <mat-form-field class="col-3">
            <mat-label id="txtServiceInfoA"> 32a.Service Facility Location Information</mat-label>
            <input
              autocomplete="new-txtServiceInfoA"
              matInput
              maxlength="100"
              formControlName="txtServiceInfoA"
              type="text"
            />
          </mat-form-field>
          <!-- 32b-->
          <mat-form-field class="col-3">
            <mat-label id="txtServiceInfoB"> 32b.Service Facility Location Information-No</mat-label>
            <input
              autocomplete="new-txtServiceInfoB"
              matInput
              maxlength="100"
              formControlName="txtServiceInfoB"
              type="text"
            />
          </mat-form-field>

          <!-- Billing Provider Info & PH#-->
          <mat-form-field class="col-3">
            <mat-label id="txtBillingProvider"> 33.Billing Provider Info & PH#</mat-label>
            <textarea
              matInput
              [disabled]="isSelect"
              maxlength="100"
              rows="1"
              formControlName="txtBillingProvider"
              type="text"
            ></textarea>
          </mat-form-field>

          <!-- Row 28 -->
          <!-- 33a -->
          <mat-form-field class="col-3">
            <mat-label id="txtBillingProviderA"
              [ngClass]="{
                'is-invalid': claimForm?.get('txtBillingProviderA')?.invalid
              }"
            >
              33a.NPI<span class="asterisk">*</span></mat-label
            >
            <input
              autocomplete="new-txtBillingProviderA"
              matInput
              [disabled]="isSelect"
              maxlength="100"
              formControlName="txtBillingProviderA"
              type="text"
              [ngClass]="{
                'is-invalid':
                  submitted &&
                  claimForm?.get('txtBillingProviderA')?.errors?.required
              }"
            />
            <mat-error
              *ngIf="
                claimForm?.get('txtBillingProviderA')?.touched &&
                claimForm?.get('txtBillingProviderA')?.errors?.required
              "
            >
              Provider is a required field!
            </mat-error>
          </mat-form-field>
          <!-- 33b -->
          <mat-form-field class="col-3">
            <mat-label id="txtBillingProviderB"> 33b.Provider Last Name</mat-label>
            <input
              autocomplete="new-txtBillingProviderB"
              matInput
              maxlength="100"
              formControlName="txtBillingProviderB"
              type="text"
            />
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label id="txtClaimAddress"> Claim Address : </mat-label>
            <input
              autocomplete="new-txtClaimAddress"
              matInput
              maxlength="100"
              formControlName="txtClaimAddress"
              type="text"
            />
          </mat-form-field>
        </form>
      </div>
    </mat-card-content>
    <!-- Item Codes List -->
    <div class="row">
      <div class="col-12">
        <h4 class="customThemeClass"><b>Item Codes List</b></h4>
        <div class="row pt-1">
          <div class="col-12">
            <div class="d-flex justify-content-center" *ngIf="!isLoadData">
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>

            <div class="table table-responsive" *ngIf="isLoadData">
              <table
                datatable
                [dtOptions]="dtClaimItemOptions"
                class="row-border hover w-100"
              >
                <thead>
                  <tr>
                    <td class="col-1"><b>24a.DOS From</b></td>
                    <td><b>24a.DOS To</b></td>
                    <td>
                      <b>24b.POS<span class="asterisk">*</span></b>
                    </td>
                    <td><b>24c.Emg</b></td>
                    <td><b>24d.CPT/HCPCS</b></td>
                    <td>
                      <b>24d.Modifiers 1<span class="asterisk">*</span></b>
                    </td>
                    <td><b>24d.Modifiers 2</b></td>
                    <td><b>24d.Modifiers 3</b></td>
                    <td><b>24d.Modifiers 4</b></td>
                    <td><b>24e.Diagonsis Pointer</b></td>
                    <td><b>24f.Charges</b></td>
                    <td><b>24g.DaysOrUnits</b></td>
                    <td><b>24H.EPSDT Family Plan</b></td>
                    <td><b>24i.Id.Qual.</b></td>
                    <td><b>24j.RenderingProviderId.#</b></td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let billingInfo of billingInfoArray">
                    <!-- Accessing properties of each billingInfo object for table cells -->
                    <td>
                      {{
                        billingInfo.dosFromDate
                          | date : "MM/dd/yyyy h:mm a" : "en_US"
                      }}
                    </td>
                    <td>
                      {{
                        billingInfo.dosToDate
                          | date : "MM/dd/yyyy h:mm a" : "en_US"
                      }}
                    </td>
                    <td>
                      <mat-select
                        (selectionChange)="loadPosDropDown()"
                        [(value)]="billingInfo.pos"
                        placeholder="POS"
                        class="custom-dropdown"
                        #drpPos
                      >
                        <mat-option>
                          <ngx-mat-select-search
                            [formControl]="searchControl"
                            placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            (keyup)="filterPOS()"
                          >
                          </ngx-mat-select-search
                        ></mat-option>

                        <mat-option
                          *ngFor="let POS of filteredPOS"
                          [value]="POS.id"
                        >
                          {{ POS.placeOfServiceType }}
                        </mat-option>
                      </mat-select>
                    </td>
                    <td>{{ billingInfo.emg }}</td>
                    <td>{{ billingInfo.hcpcCode }}</td>
                    <td>
                      <mat-select
                        (selectionChange)="loadModifire(1)"
                        [(value)]="billingInfo.modifiers1"
                        placeholder="Modifiers 1"
                        class="custom-dropdown"
                        #drpModifires1
                      >
                        <mat-option>
                          <ngx-mat-select-search
                            [formControl]="searchControl"
                            placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            (keyup)="filterModifier()"
                          ></ngx-mat-select-search
                        ></mat-option>

                        <mat-option
                          *ngFor="let modifiers of filteredOptions"
                          [value]="modifiers.id"
                        >
                          {{ modifiers.mmRefId }}
                        </mat-option>
                      </mat-select>
                    </td>
                    <td>
                      <mat-select
                        (selectionChange)="loadModifire(2)"
                        [(value)]="billingInfo.modifiers2"
                        placeholder="Modifiers 2"
                        #drpModifires2
                        class="custom-dropdown"
                      >
                        <mat-option>
                          <ngx-mat-select-search
                            [formControl]="searchControl"
                            placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            (keyup)="filterModifier()"
                          ></ngx-mat-select-search
                        ></mat-option>

                        <mat-option
                          *ngFor="let modifiers of filteredOptions"
                          [value]="modifiers.id"
                        >
                          {{ modifiers.mmRefId }}
                        </mat-option>
                      </mat-select>
                    </td>
                    <td>
                      <mat-select
                        (selectionChange)="loadModifire(3)"
                        [(value)]="billingInfo.modifiers3"
                        placeholder="Modifiers 3"
                        #drpModifires3
                        class="custom-dropdown"
                      >
                        <mat-option>
                          <ngx-mat-select-search
                            [formControl]="searchControl"
                            placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            (keyup)="filterModifier()"
                          ></ngx-mat-select-search
                        ></mat-option>

                        <mat-option
                          *ngFor="let modifiers of filteredOptions"
                          [value]="modifiers.id"
                        >
                          {{ modifiers.mmRefId }}
                        </mat-option>
                      </mat-select>
                    </td>
                    <td>
                      <mat-select
                        (selectionChange)="loadModifire(4)"
                        [(value)]="billingInfo.modifiers4"
                        placeholder="Modifiers 4"
                        #drpModifires4
                        class="custom-dropdown"
                      >
                        <mat-option>
                          <ngx-mat-select-search
                            [formControl]="searchControl"
                            placeholderLabel="Search"
                            noEntriesFoundLabel="No Matches found"
                            (keyup)="filterModifier()"
                          ></ngx-mat-select-search
                        ></mat-option>

                        <mat-option
                          *ngFor="let modifiers of filteredOptions"
                          [value]="modifiers.id"
                        >
                          {{ modifiers.mmRefId }}
                        </mat-option>
                      </mat-select>
                    </td>
                    <td>{{ billingInfo.diagonsisPointer }}</td>
                    <td>{{ billingInfo.charges }}</td>
                    <td>{{ billingInfo.daysOrUnits }}</td>
                    <td>{{ billingInfo.epsdtFamilyPlan }}</td>
                    <td>{{ billingInfo.idQual }}</td>
                    <td>{{ billingInfo.renderingProviderId }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    class="buttonColor mr-2"
    (click)="saveClaimDetails()"
    [disabled]="claimForm?.invalid || saveButtonHide || isSavebtn"
    [hidden]="isClaimBatch"
  >
    <ng-container *ngIf="!data.isResubmit">Save</ng-container>
    <ng-container *ngIf="data.isResubmit">Save & Resubmit</ng-container>
    <mat-icon *ngIf="isSaveLoading" class="mr-2 ml-2">
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>
  <!-- <button mat-button (click)="check()" >Check</button> -->
  <!-- <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button> -->
</mat-dialog-actions>
<app-loader-component *ngIf="isLoading"></app-loader-component>
