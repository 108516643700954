import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';

import { OrdersDTO, SaleorderDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto/models';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { getOrder, updateOrder } from 'projects/order/src/app/order-store/order.action';
import { ConfirmationDialogService } from 'projects/patient/src/app/confirmation-dialog.service';
import { AuthVisitsDTO, OrderMasterDropdownDTO, TypeOfPlanDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { PatientMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { AuthorizationDTO, ClinicalDTO, PatientDTO, PersonalDTO, PolicyDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
import Swal from 'sweetalert2';



@Component({
  selector: 'app-shipping-create-edit',
  templateUrl: './shipping-create-edit.component.html',
  styleUrls: ['./shipping-create-edit.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})

export class ShippingCreateEditComponent implements OnInit , OnDestroy {

  shippingVerificationData: any;
  savedClinicalData: any;
  savedDocumentData: any;
  savedInsuranceData: any;
  savedAuthorizationData: any;
  savedItemData: any;
  savedVerificationData: any;
  shippingAuthorizationData: any;
  shipingPatientLedgerData: any;
  shipingHistoryData: any;
  shippingVerificationForm: FormGroup;
  authorizationForm: FormGroup;
  ledgerForm: FormGroup;
  claimHistoryForm: FormGroup;
  @Input() patientId: string = '';
  drpPolicies: PolicyDTO[] = [];
  private confirmationDialogService: ConfirmationDialogService;
  @Input() isStatusShow: boolean = false;
  tblNotesReponse: any[] = [];
  strPageType: string = 'shippingApproval';
  strPageTitleType: string = 'shippingApprovalTitle';
  @Output() saveButton: EventEmitter<any> = new EventEmitter<any>();
  orderId: string = "";
  patientID: string = "";
  @ViewChild('nav') nav: NgbNav;
  show: boolean;
  organizationUnitId: string = "";
  insuranceVerificationId: string = "00000000-0000-0000-0000-000000000000";
  verifyTableData: any[] = []
  payorLevel: any[]
  showswal = true;
  drpPlan: TypeOfPlanDTO[] = [];
  authorizationId: string = "00000000-0000-0000-0000-000000000000";
  sDefaultPatientId: string;
  drpPlanType: any[];
  drpAuthVisit: AuthVisitsDTO[] = [];

  //
  strLocation: string = "";
  currentOrderStatus: string = ""
  strBillingAddress: string = "";
  strBillingState: string = "";
  strBillingCity: string = "";
  strBillingCountry: string = "";
  strPostalCode: string = "";
  strMobile: string = "";
  strEmailAddress: string = "";
  lblDefaultPatientId: string = "";
  lblLastUpdatedDateTime: string | Date;
  lblPatientName: string = "";
  lblPatientGender: string = "";
  lblPatientDOB: string = "";
  lblPatientHeight: string = "";
  lblBillingAmount: string = "";
  lblBillingStatus: string = "";
  lblPatientWeight: string = "";
  lblBranchOffice: string = "";
  lblAccountNumber: string = "";
  lblSsnNumber: string = "";
  forBlink: boolean = true;
  preOrderId: string = "";
  @Output() orderIdEmitter: EventEmitter<OrdersDTO> = new EventEmitter<OrdersDTO>(null);
  subscription$: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public title: Title, private Table: TableService,
    private formBuilder: FormBuilder,
    private store: Store,
    private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    private text: TextValidators,
    private fb: FormBuilder,
    private patientDropdownService: PatientMasterDropdownService,
    private orderDropdownService: OrderMasterDropdownService,
    private region: RegionDropdownService,
    private saleOrderService: SaleorderService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  approveDisapproveStatus: approvalStatus = {
    document: false,
    verification: false,
    authorization: false,
    ledger: false
  };


  ngOnInit(): void {
    // this.Table.getSelectedPatientId().subscribe(response => {
    //   this.patientID = response;
    // });
    this.title.setTitle("Qsecure | Shipping Approval");
    this.activatedRoute.paramMap.subscribe(response => {
      this.orderId = response?.get('id');

      if (this.patientId === null || this.patientId === "" || this.patientId === undefined) {

        const getOrder = this.saleOrderService.get(this.orderId).subscribe(Res => {
          let orderResp: SaleorderDTO = Res;
          this.patientId = orderResp?.patientId ?? "";
          this.patientID = orderResp?.patientId ?? "";

          if (this.patientId === null || this.patientId === "" || this.patientId === undefined) {
            this.patientId = orderResp?.patients?.id;
          }

          this.patientId !== null && this.patientId !== undefined && this.patientId !== "" && this.getDetailsByPatientID(this.patientId);
        })
        this.subscription$.push(getOrder);
      }
      this.Table.setSelectedOrderId(this.orderId);
    });
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.PayorLevels
    ]
    const patientDropdown = this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      let response: PatientMasterDropdownDTO = stateResponse;
      this.payorLevel = response?.payorLevels;

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(patientDropdown);
    this.createPatientForm();
    // this.patientDropdownService.get().subscribe((stateResponse) => {
    //   let response: PatientMasterDropdownDTO = stateResponse;
    //   this.payorLevel = response?.payorLevels;

    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
    this.getDropdown();
  }

  createPatientForm() {

    this.shippingVerificationForm = this.formBuilder.group({
      txtName: new FormControl("", [this.textValidators.isTextCheck]),
      //   txtAddress: new FormControl("", [this.textValidators.isTextCheck]),
      txtDateOfBirth: new FormControl("", [Validators.required]),
      txtCommentsNotes: new FormControl(""),
      txtPlan: new FormControl(null, [Validators.required]),
      txtPlanName: new FormControl("", [Validators.required]),
      txtPlanPhone: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtPolicy: new FormControl("", [Validators.required]),
      txtGroup: new FormControl(""),
      txtEffectiveDate: new FormControl("", [Validators.required]),
      txtType: new FormControl(null, [Validators.required]),
      txtFascalYearStart: new FormControl(""),
      txtEnd: new FormControl(""),
      chkMedicare: new FormControl(""),
      chkPatient: new FormControl(""),
      chkFamily: new FormControl(""),
      txtCmnCode: new FormControl("", [this.textValidators.isTextCheck]),
      drpTypeOfPlan: new FormControl(null, [Validators.required]),
      chkIndividual: new FormControl(""),
      chkOutOfNetwork: new FormControl(""),
      chkFamilyDeductible: new FormControl(""),
      txtDeductibleIndividual: new FormControl(""),
      txtDeductibleOutOfNetwork: new FormControl(""),
      txtDeductibleFamily: new FormControl(""),
      txtDeductibleMetIndividual: new FormControl(""),
      txtDeductibleMetOutOfNetwork: new FormControl(""),
      txtDeductibleMetFamily: new FormControl(""),
      drpPayableIndividual: new FormControl(null),
      drpPayableOutOfNetwork: new FormControl(null),
      drpPayableFamily: new FormControl(null),
      txtExpenseIndividual: new FormControl(""),
      txtExpenseOutOfNetwork: new FormControl(""),
      txtExpenseFamily: new FormControl(""),
      txtExpenseMetIndividual: new FormControl(""),
      txtExpenseMetOutOfNetwork: new FormControl(""),
      txtExpenseMetFamily: new FormControl(""),
      chkExpenseIndividual: new FormControl(""),
      chkExpenseOutOfNetwork: new FormControl(""),
      chkExpenseFamily: new FormControl(""),
      chkPrecertRequired: new FormControl(""),
      txtPhoneCall: new FormControl("", [this.numberValidators.isNumberCheck]),
      chkReferralRequired: new FormControl(""),
      txtPcp: new FormControl("", [this.textValidators.isTextCheck]),
      chkPredetermination: new FormControl(""),
      txtPreNotes: new FormControl("", [this.textValidators.isTextCheck]),
      txtLifetimeMax: new FormControl(""),
      txtLifetimeMaxMet: new FormControl(""),
      chkDmePartsCovered: new FormControl(""),
      chkDmeReplacementUnit: new FormControl(""),
      txtDmeAnnualLimit: new FormControl(""),
      txtDmeLimitMet: new FormControl(""),
      txtRequestedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtPrimaryPayor: new FormControl("", [this.textValidators.isTextCheck]),
      txtContact: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtCallTrackingNumber: new FormControl(""),
      txtPolicyHolderName: new FormControl("", [this.textValidators.isTextCheck]),
      txtPolicyHolderDob: new FormControl(""),
      chkNetwork: new FormControl(""),
      txtClaimAddress: new FormControl(""),
      txtPlanCob: new FormControl("", [Validators.required]),
      txtDedTotal: new FormControl(""),
      txtDedMet: new FormControl(""),
      txtOopTotal: new FormControl(""),
      txtOopMet: new FormControl(""),
      txtOopDeductible: new FormControl(""),
      txtDmeCoverage: new FormControl(""),
      txtDmeMax: new FormControl(""),
      txtHcpcs: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtState: new FormControl("", [this.textValidators.isTextCheck]),
      txtCoverageCriteria: new FormControl("", [this.textValidators.isTextCheck]),
      txtQtyLimits: new FormControl("", [this.textValidators.isTextCheck]),
      txtFrequency: new FormControl("", [this.textValidators.isTextCheck]),
      txtRent: new FormControl("", [this.textValidators.isTextCheck]),
      txtPaRequired: new FormControl("", [this.textValidators.isTextCheck]),
      txtCodesRequirePa: new FormControl("", [this.textValidators.isTextCheck]),
      txtSameOrSimilarInfo: new FormControl("", [this.textValidators.isTextCheck]),
      txtSpokeWith: new FormControl("", [this.textValidators.isTextCheck]),
      drpVerifiedBy: new FormControl("",),
      txtDateVerified: new FormControl("", [this.textValidators.isTextCheck]),
      drpPreparedBy: new FormControl("", [this.textValidators.isTextCheck]),
      txtPreparedOn: new FormControl(""),
      rbtnVerificationStatus: new FormControl("", [Validators.required]),
      txtDeo: new FormControl("", [this.numberValidators.isNumberCheck]),
      txtCoIns: new FormControl(""),
      rbtnPolicyType: new FormControl(""),
      rbtnInsuranceVerify: new FormControl("", [Validators.required]),
      txtcommentsToTech: new FormControl("", [this.textValidators.isTextCheck]),
      rbtnPending: new FormControl(""),
      rbtnUnableToVerify: new FormControl(""),
      txtReaon: new FormControl("")

    });
    this.authorizationForm = this.fb.group({
      txtAuthId: new FormControl("", [Validators.required]),
      drpAuthVisit: new FormControl(null),
      txtAuthAmount: new FormControl(""),
      txtStartDate: new FormControl(""),
      txtEndDate: new FormControl(""),
      drpCategory: new FormControl(null),
      drpItem: new FormControl(null),
      txtNote: new FormControl(""),
      chkCompleted: new FormControl(""),
      txtCompletedBy: new FormControl("", [this.text.isTextCheck]),
      drpPlan: new FormControl(null),
      txtPlan: new FormControl(null, [Validators.required]),
      // txtPlanName: new FormControl(null, [Validators.required]),
      rbtnAuth: new FormControl("", Validators.required),
      rbtnPolicyType: new FormControl(null),
    });


    this.ledgerForm = this.fb.group({
      rbtnLedger: new FormControl("", Validators.required),
    });
    this.claimHistoryForm = this.fb.group({
      rbtnClaimHistory: new FormControl("", Validators.required),
      chkShippingApproved: new FormControl(""),

    });

    //this.findInvalidControls();
    this.getInsuranceVerifyByOrderId();


  }
  getPatientId(value: string) {
    this.patientID = value;
  }
  // saveInsuranceVerify() {
  //   this.saveButton.emit();
  // }
  UpdateApprovalStatus(status: MatRadioChange, pagetype: string) {
    switch (pagetype) {
      case "Document":
        {
          this.approveDisapproveStatus = { ...this.approveDisapproveStatus, document: status.value };
        }
        break;
      case "Insurance Verification":
        {
          this.approveDisapproveStatus = {
            ...this.approveDisapproveStatus,
            verification: status.value
          };
        }
        break;
      case "Authorization":
        {
          this.approveDisapproveStatus = {
            ...this.approveDisapproveStatus,
            authorization: status.value
          };
        }
        break;
      case "Patient Ledger":
        {
          this.approveDisapproveStatus = {
            ...this.approveDisapproveStatus,
            ledger: status.value
          };
        }
        break;
      default:
        break;
    }

    // this.Table.setShippingApprovalStatus({ ...this.approveDisapproveStatus });
   const getShippingApprovalStatus= this.Table.getShippingApprovalStatus().subscribe((value: approvalStatus) => {
      //

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getShippingApprovalStatus);
  }
  returnStatus() {
    return this.approveDisapproveStatus?.document &&
      this.approveDisapproveStatus?.verification &&
      this.approveDisapproveStatus?.authorization && this.approveDisapproveStatus?.ledger;
  }
  onSaveInsuranceVerificationData(value: any) {
    this.shippingVerificationData = value;
  }

  onSaveAuthorizationData(value: any) {
    this.shippingAuthorizationData = value;
  }
  onSavePatientLedgerData(value: any) {
    this.shipingPatientLedgerData = value;
  }

  onSaveHistoryData(value: any) {
    this.shipingHistoryData = value;
  }


  beforeUnloadHander(event) {
    // this.PostCall();
    this.getConfirmationDialog(event);
    // return event.returnValue = "something..."
  }

  getConfirmationDialog(event: any) {
    event.returnValue = "something...."
    if ((this.shippingVerificationData && this.shippingVerificationData.dirty) || (this.shippingAuthorizationData && this.shippingAuthorizationData.dirty) || (this.shipingPatientLedgerData && this.shipingPatientLedgerData.dirty)(this.shipingHistoryData && this.shipingHistoryData.dirty)) {
      this.confirmationDialogService.confirm('Please confirm..', 'Do you want to save the changes ... ?')
        .then((confirmed) => {

          if ((confirmed == true) && this.shippingVerificationData && this.shippingVerificationData.dirty) {
            this.saveShipingVerificationForm();
          } else {
            this.shippingVerificationForm.reset();
          }
          if ((confirmed == true) && this.shippingAuthorizationData && this.shippingAuthorizationData.dirty) {
            this.saveShipingAuthorizationForm();
          } else {
            this.authorizationForm.reset();
          }
          if ((confirmed == true) && this.shipingPatientLedgerData && this.shipingPatientLedgerData.dirty) {
            this.saveShipingPatientLedgerForm();
          } else {
            this.ledgerForm.reset();
          }
          if ((confirmed == true) && this.shipingHistoryData && this.shipingHistoryData.dirty) {
            this.saveShipingHistoryForm();
          } else {
            this.claimHistoryForm.reset();
          }


        })
        .catch((e) => e);
    }
  }

  onNavChange($event: NgbNavChangeEvent) {

    if (this.shippingVerificationData && this.showswal && this.shippingVerificationData.dirty) {

      if (($event.nextId === "Document") || ($event.nextId === "Authorization") || ($event.nextId === 'Ledger') || ($event.nextId === 'History')) {
        Swal.fire({
          title: 'Changes Has Been Done?',
          text: 'Would You Like To Save The Changes!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(result => {
          if (result.value) {
            this.showswal = false;
            this.saveShipingVerificationForm();
          } else {

            this.showswal = false;
            this.shippingVerificationData = [];
          }

        })
      }
      this.showswal = true;
    }

    if (this.shippingAuthorizationData && this.showswal && this.shippingAuthorizationData.dirty) {

      if (($event.nextId === "Document") || ($event.nextId === "Verification") || ($event.nextId === 'Ledger') || ($event.nextId === 'History')) {

        Swal.fire({
          title: 'Changes Has Been Done?',
          text: 'Would You Like To Save The Changes!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(result => {
          if (result.value) {
            this.showswal = false;
            this.saveShipingAuthorizationForm();
          } else {
            this.showswal = false;
            this.shippingAuthorizationData = [];
          }

        })
      }
      this.showswal = true;
    }

    if (this.shipingPatientLedgerData && this.showswal && this.shipingPatientLedgerData.dirty) {



      if (($event.nextId === "Document") || ($event.nextId === "Verification") || ($event.nextId === 'Authorization') || ($event.nextId === 'History')) {


        Swal.fire({
          title: 'Changes Has Been Done?',
          text: 'Would You Like To Save The Changes!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(result => {
          if (result.value) {
            this.showswal = false;
            this.saveShipingPatientLedgerForm();
          } else {

            this.showswal = false;
            this.shipingPatientLedgerData = [];
          }

        })
      }
      this.showswal = true;
    }

    if (this.shipingHistoryData && this.showswal && this.shipingHistoryData.dirty) {



      if (($event.nextId === "Verification") || ($event.nextId === "Authorization") || ($event.nextId === 'Ledger') || ($event.nextId === 'Document')) {
        Swal.fire({
          title: 'Changes Has Been Done?',
          text: 'Would You Like To Save The Changes!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes!',
          cancelButtonText: 'No',
          allowOutsideClick: false,
        }).then(result => {
          if (result.value) {
            this.showswal = false;
            this.saveShipingHistoryForm();
          } else {

            this.showswal = false;
            this.shipingHistoryData = [];
          }

        })

      }
      this.showswal = true;
    }
    this.showswal = true;

  }

  // shipping Verification tab change function
  // Save Shipping Verification Form
  saveShipingVerificationForm() {
    if (this.shippingVerificationData && this.shippingVerificationData !== null) {

      this.shippingVerificationForm.patchValue({
        // drpChart: this.shippingVerificationData['controls'].drpChart.value,
        txtName: this.shippingVerificationData['controls'].txtName.value,
        // txtAddress: this.shippingVerificationData['controls'].txtAddress.value,
        txtDateOfBirth: this.shippingVerificationData['controls'].txtDateOfBirth.value,
        txtCommentsNotes: this.shippingVerificationData['controls'].txtCommentsNotes.value,
        txtPlan: this.shippingVerificationData['controls'].txtPlan.value,
        txtPlanName: this.shippingVerificationData['controls'].txtPlanName.value,
        txtPlanPhone: this.shippingVerificationData['controls'].txtPlanPhone.value,
        txtPolicy: this.shippingVerificationData['controls'].txtPolicy.value,
        txtGroup: this.shippingVerificationData['controls'].txtGroup.value,
        txtEffectiveDate: this.shippingVerificationData['controls'].txtEffectiveDate.value,
        txtType: this.shippingVerificationData['controls'].txtType.value,
        txtFascalYearStart: this.shippingVerificationData['controls'].txtFascalYearStart.value,
        txtEnd: this.shippingVerificationData['controls'].txtEnd.value,
        chkMedicare: this.shippingVerificationData['controls'].chkMedicare.value,
        chkPatient: this.shippingVerificationData['controls'].chkPatient.value,
        chkFamily: this.shippingVerificationData['controls'].chkFamily.value,
        txtCmnCode: this.shippingVerificationData['controls'].txtCmnCode.value,
        drpTypeOfPlan: this.shippingVerificationData['controls'].drpTypeOfPlan.value,
        chkIndividual: this.shippingVerificationData['controls'].chkIndividual.value,
        chkOutOfNetwork: this.shippingVerificationData['controls'].chkOutOfNetwork.value,
        chkFamilyDeductible: this.shippingVerificationData['controls'].chkFamilyDeductible.value,
        txtDeductibleIndividual: this.shippingVerificationData['controls'].txtDeductibleIndividual.value,
        txtDeductibleOutOfNetwork: this.shippingVerificationData['controls'].txtDeductibleOutOfNetwork.value,
        txtDeductibleFamily: this.shippingVerificationData['controls'].txtDeductibleFamily.value,
        txtDeductibleMetIndividual: this.shippingVerificationData['controls'].txtDeductibleMetIndividual.value,
        txtDeductibleMetOutOfNetwork: this.shippingVerificationData['controls'].txtDeductibleMetOutOfNetwork.value,
        txtDeductibleMetFamily: this.shippingVerificationData['controls'].txtDeductibleMetFamily.value,
        drpPayableIndividual: this.shippingVerificationData['controls'].drpPayableIndividual.value,
        drpPayableOutOfNetwork: this.shippingVerificationData['controls'].drpPayableOutOfNetwork.value,
        drpPayableFamily: this.shippingVerificationData['controls'].drpPayableFamily.value,
        txtExpenseIndividual: this.shippingVerificationData['controls'].txtExpenseIndividual.value,
        txtExpenseOutOfNetwork: this.shippingVerificationData['controls'].txtExpenseOutOfNetwork.value,
        txtExpenseFamily: this.shippingVerificationData['controls'].txtExpenseFamily.value,
        txtExpenseMetIndividual: this.shippingVerificationData['controls'].txtExpenseMetIndividual.value,
        txtExpenseMetOutOfNetwork: this.shippingVerificationData['controls'].txtExpenseMetOutOfNetwork.value,
        txtExpenseMetFamily: this.shippingVerificationData['controls'].txtExpenseMetFamily.value,
        chkExpenseIndividual: this.shippingVerificationData['controls'].chkExpenseIndividual.value,
        chkExpenseOutOfNetwork: this.shippingVerificationData['controls'].chkExpenseOutOfNetwork.value,
        chkExpenseFamily: this.shippingVerificationData['controls'].chkExpenseFamily.value,
        chkPrecertRequired: this.shippingVerificationData['controls'].chkPrecertRequired.value,
        txtPhoneCall: this.shippingVerificationData['controls'].txtPhoneCall.value,
        chkReferralRequired: this.shippingVerificationData['controls'].chkReferralRequired.value,
        txtPcp: this.shippingVerificationData['controls'].txtPcp.value,
        chkPredetermination: this.shippingVerificationData['controls'].chkPredetermination.value,
        txtPreNotes: this.shippingVerificationData['controls'].txtPreNotes.value,
        txtLifetimeMax: this.shippingVerificationData['controls'].txtLifetimeMax.value,
        txtLifetimeMaxMet: this.shippingVerificationData['controls'].txtLifetimeMaxMet.value,
        chkDmePartsCovered: this.shippingVerificationData['controls'].chkDmePartsCovered.value,
        chkDmeReplacementUnit: this.shippingVerificationData['controls'].chkDmeReplacementUnit.value,
        txtDmeAnnualLimit: this.shippingVerificationData['controls'].txtDmeAnnualLimit.value,
        txtDmeLimitMet: this.shippingVerificationData['controls'].txtDmeLimitMet.value,
        txtRequestedBy: this.shippingVerificationData['controls'].txtRequestedBy.value,
        txtPrimaryPayor: this.shippingVerificationData['controls'].txtPrimaryPayor.value,
        txtContact: this.shippingVerificationData['controls'].txtContact.value,
        txtCallTrackingNumber: this.shippingVerificationData['controls'].txtCallTrackingNumber.value,
        txtPolicyHolderName: this.shippingVerificationData['controls'].txtPolicyHolderName.value,
        txtPolicyHolderDob: this.shippingVerificationData['controls'].txtPolicyHolderDob.value,
        chkNetwork: this.shippingVerificationData['controls'].chkNetwork.value,
        txtClaimAddress: this.shippingVerificationData['controls'].txtClaimAddress.value,
        txtPlanCob: this.shippingVerificationData['controls'].txtPlanCob.value,
        txtDedTotal: this.shippingVerificationData['controls'].txtDedTotal.value,
        txtDedMet: this.shippingVerificationData['controls'].txtDedMet.value,
        txtOopTotal: this.shippingVerificationData['controls'].txtOopTotal.value,
        txtOopMet: this.shippingVerificationData['controls'].txtOopMet.value,
        txtOopDeductible: this.shippingVerificationData['controls'].txtOopDeductible.value,
        txtDmeCoverage: this.shippingVerificationData['controls'].txtDmeCoverage.value,
        txtDmeMax: this.shippingVerificationData['controls'].txtDmeMax.value,
        txtHcpcs: this.shippingVerificationData['controls'].txtHcpcs.value,
        txtState: this.shippingVerificationData['controls'].txtState.value,
        txtCoverageCriteria: this.shippingVerificationData['controls'].txtCoverageCriteria.value,
        txtQtyLimits: this.shippingVerificationData['controls'].txtQtyLimits.value,
        txtFrequency: this.shippingVerificationData['controls'].txtFrequency.value,
        txtRent: this.shippingVerificationData['controls'].txtRent.value,
        txtPaRequired: this.shippingVerificationData['controls'].txtPaRequired.value,
        txtCodesRequirePa: this.shippingVerificationData['controls'].txtCodesRequirePa.value,
        txtSameOrSimilarInfo: this.shippingVerificationData['controls'].txtSameOrSimilarInfo.value,
        txtSpokeWith: this.shippingVerificationData['controls'].txtSpokeWith.value,
        drpVerifiedBy: this.shippingVerificationData['controls'].drpVerifiedBy.value,
        txtDateVerified: this.shippingVerificationData['controls'].txtDateVerified.value,
        drpPreparedBy: this.shippingVerificationData['controls'].drpPreparedBy.value,
        txtPreparedOn: this.shippingVerificationData['controls'].txtPreparedOn.value,
        rbtnVerificationStatus: this.shippingVerificationData['controls'].rbtnVerificationStatus.value,
        rbtnPolicyType: this.shippingVerificationData['controls'].rbtnPolicyType.value,
        rbtnPending: this.shippingVerificationData['controls'].rbtnPolicyType.value,
        rbtnInsuranceVerify: this.shippingVerificationData['controls'].rbtnInsuranceVerify.value,
        rbtnUnableToVerify: this.shippingVerificationData['controls'].rbtnPolicyType.value,
        txtDeo: this.shippingVerificationData['controls'].txtDeo.value,
        txtCoIns: this.shippingVerificationData['controls'].txtCoIns.value,
        txtcommentsToTech: this.shippingVerificationData['controls'].txtcommentsToTech.value,
        txtReason: this.shippingVerificationData['controls'].txtReason.value
      });
    }
    this.showswal = false;
    this.getPatientDetailsForShippedVerficationSave();
    this.shippingVerificationData = [];
  }
  //Get details for Shipping Verification save
  getPatientDetailsForShippedVerficationSave() {
    let personalDetails: SaleorderDTO;
   const saleOrderGet = this.saleOrderService.get(this.orderId).subscribe(response => {
    // this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
      let notesDetails = response;
      personalDetails = response;
      let PatientData = response.patients;
      this.verifyTableData = [];
      if (personalDetails.patients.insuranceVerifications) {
        let savedData = {
          primaryInsuranceVerifies: personalDetails.patients.insuranceVerifications.primaryInsuranceVerifies,
          secondaryInsuranceVerifies: personalDetails.patients.insuranceVerifications.secondaryInsuranceVerifies,
          tertiaryInsuranceVerifies: personalDetails.patients.insuranceVerifications.tertiaryInsuranceVerifies
        }
        return this.saveInsuranceVerify(personalDetails, savedData, PatientData);

      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(saleOrderGet);
  }
  // save Shipping Verification
  saveInsuranceVerify(response, savedData, PatientData: PatientDTO) {
    let payorLevelName: string[] = this.payorLevel.map(v => v.payorLevelName);
    if (this.shippingVerificationForm.value.txtPlan === payorLevelName[0]) {

      savedData.primaryInsuranceVerifies = {
        // insuranceVerificationId: this.insuranceVerificationId,
        chart: this.shippingVerificationForm.value?.txtName,
        name: this.shippingVerificationForm.value?.txtName,
        address: this.shippingVerificationForm.value?.txtName,
        dateofBirth: this.shippingVerificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.shippingVerificationForm.value?.txtCommentsNotes,
        plan: this.shippingVerificationForm.value?.txtPlan,
        planName: this.shippingVerificationForm.value?.txtPlanName,
        planPhone: this.shippingVerificationForm.value?.txtPlanPhone,
        policy: this.shippingVerificationForm.value?.txtPolicy,
        groupNo: this.shippingVerificationForm.value?.txtGroup,
        effectiveDate: this.shippingVerificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.shippingVerificationForm.value?.txtType,
        fascalYearStart: this.shippingVerificationForm.value?.txtFascalYearStart,
        end: this.shippingVerificationForm.value?.txtEnd,
        isMedicareCmn: +this.shippingVerificationForm.value?.chkMedicare,
        isPatientHome: +this.shippingVerificationForm.value?.chkPatient,
        isFamilyDeductible: +this.shippingVerificationForm.value?.chkFamilyDeductible,
        cmnCode: this.shippingVerificationForm.value?.txtCmnCode,
        typeOfPlan: this.shippingVerificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.shippingVerificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.shippingVerificationForm.value?.chkOutOfNetwork,
        isFamily: +this.shippingVerificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.shippingVerificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtDeductibleFamily,
        },

        deductibleMet: {
          individual: this.shippingVerificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.shippingVerificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.drpPayableOutOfNetwork,
          family: this.shippingVerificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.shippingVerificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtExpenseOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.shippingVerificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +this.shippingVerificationForm.value?.chkExpenseIndividual,
          outOfNetwork: +this.shippingVerificationForm.value?.chkExpenseOutOfNetwork,
          family: +this.shippingVerificationForm.value?.chkExpenseFamily,
        },
        isPercertRequired: +this.shippingVerificationForm.value?.chkPrecertRequired,
        phone: this.shippingVerificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.shippingVerificationForm.value?.chkReferralRequired,
        pcp: this.shippingVerificationForm.value?.txtPcp,
        isPredetermination: +(this.shippingVerificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkPredetermination)),
        preNotes: this.shippingVerificationForm.value?.txtPreNotes,
        lifeTimeMax: this.shippingVerificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.shippingVerificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.shippingVerificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.shippingVerificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.shippingVerificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.shippingVerificationForm.value?.txtDmeLimitMet,
        requestedBy: this.shippingVerificationForm.value?.txtRequestedBy,
        primaryPayor: this.shippingVerificationForm.value?.txtPrimaryPayor,
        contact: this.shippingVerificationForm.value?.txtContact,
        callTrackingNumber: this.shippingVerificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.shippingVerificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.shippingVerificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.shippingVerificationForm.value?.chkNetwork,
        claimAddress: this.shippingVerificationForm.value?.txtClaimAddress,
        planCob: this.shippingVerificationForm.value?.txtPlanCob,
        dedTotal: this.shippingVerificationForm.value?.txtDedTotal,
        dedMet: this.shippingVerificationForm.value?.txtDedMet,
        oopTotal: this.shippingVerificationForm.value?.txtOopTotal,
        oopMet: this.shippingVerificationForm.value?.txtOopMet,
        oop: this.shippingVerificationForm.value?.txtOopDeductible,
        dmeCoverage: this.shippingVerificationForm.value?.txtDmeCoverage,
        dmeMax: this.shippingVerificationForm.value?.txtDmeMax,
        hcpcs: this.shippingVerificationForm.value?.txtHcpcs,
        coverageCriteria: this.shippingVerificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.shippingVerificationForm.value?.txtQtyLimits,
        frequency: this.shippingVerificationForm.value?.txtFrequency,
        rent: this.shippingVerificationForm.value?.txtRent,
        paRequired: this.shippingVerificationForm.value?.txtPaRequired,
        codesRequirePa: this.shippingVerificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.shippingVerificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.shippingVerificationForm.value?.txtSpokeWith,
        verifiedBy: this.shippingVerificationForm.value?.drpVerifiedBy,
        dateVerified: this.shippingVerificationForm.value?.txtDateVerified,
        preparedBy: this.shippingVerificationForm.value?.drpPreparedBy,
        preparedOn: this.shippingVerificationForm.value?.txtPreparedOn,
        verificationStatus: this.shippingVerificationForm.value?.rbtnVerificationStatus,
        deo: this.shippingVerificationForm.value?.txtDeo,
        coInsurance: this.shippingVerificationForm.value?.txtCoIns,
        approvalStatus: this.shippingVerificationForm.value.rbtnInsuranceVerify,
        commentsToTech: this.shippingVerificationForm.value.txtcommentsToTech,
        reason: this.shippingVerificationForm.value.txtReason

      }

    } else if (this.shippingVerificationForm.value.txtPlan === payorLevelName[1]) {

      savedData.secondaryInsuranceVerifies = {
        // insuranceVerificationId: this.insuranceVerificationId,
        chart: this.shippingVerificationForm.value?.txtName,
        name: this.shippingVerificationForm.value?.txtName,
        address: this.shippingVerificationForm.value?.txtName,
        dateofBirth: this.shippingVerificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.shippingVerificationForm.value?.txtCommentsNotes,
        plan: this.shippingVerificationForm.value?.txtPlan,
        planName: this.shippingVerificationForm.value?.txtPlanName,
        planPhone: this.shippingVerificationForm.value?.txtPlanPhone,
        policy: this.shippingVerificationForm.value?.txtPolicy,
        groupNo: this.shippingVerificationForm.value?.txtGroup,
        effectiveDate: this.shippingVerificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.shippingVerificationForm.value?.txtType,
        fascalYearStart: this.shippingVerificationForm.value?.txtFascalYearStart,
        end: this.shippingVerificationForm.value?.txtEnd,
        isMedicareCmn: +this.shippingVerificationForm.value?.chkMedicare,
        isPatientHome: +this.shippingVerificationForm.value?.chkPatient,
        isFamilyDeductible: +this.shippingVerificationForm.value?.chkFamilyDeductible,
        cmnCode: this.shippingVerificationForm.value?.txtCmnCode,
        typeOfPlan: this.shippingVerificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.shippingVerificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.shippingVerificationForm.value?.chkOutOfNetwork,
        isFamily: +this.shippingVerificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.shippingVerificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtDeductibleFamily,
        },

        deductibleMet: {
          individual: this.shippingVerificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.shippingVerificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.drpPayableOutOfNetwork,
          family: this.shippingVerificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.shippingVerificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtExpenseOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.shippingVerificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +this.shippingVerificationForm.value?.chkExpenseIndividual,
          outOfNetwork: +this.shippingVerificationForm.value?.chkExpenseOutOfNetwork,
          family: +this.shippingVerificationForm.value?.chkExpenseFamily,
        },
        isPercertRequired: +this.shippingVerificationForm.value?.chkPrecertRequired,
        phone: this.shippingVerificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.shippingVerificationForm.value?.chkReferralRequired,
        pcp: this.shippingVerificationForm.value?.txtPcp,
        isPredetermination: +(this.shippingVerificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkPredetermination)),
        preNotes: this.shippingVerificationForm.value?.txtPreNotes,
        lifeTimeMax: this.shippingVerificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.shippingVerificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.shippingVerificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.shippingVerificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.shippingVerificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.shippingVerificationForm.value?.txtDmeLimitMet,
        requestedBy: this.shippingVerificationForm.value?.txtRequestedBy,
        primaryPayor: this.shippingVerificationForm.value?.txtPrimaryPayor,
        contact: this.shippingVerificationForm.value?.txtContact,
        callTrackingNumber: this.shippingVerificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.shippingVerificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.shippingVerificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.shippingVerificationForm.value?.chkNetwork,
        claimAddress: this.shippingVerificationForm.value?.txtClaimAddress,
        planCob: this.shippingVerificationForm.value?.txtPlanCob,
        dedTotal: this.shippingVerificationForm.value?.txtDedTotal,
        dedMet: this.shippingVerificationForm.value?.txtDedMet,
        oopTotal: this.shippingVerificationForm.value?.txtOopTotal,
        oopMet: this.shippingVerificationForm.value?.txtOopMet,
        oop: this.shippingVerificationForm.value?.txtOopDeductible,
        dmeCoverage: this.shippingVerificationForm.value?.txtDmeCoverage,
        dmeMax: this.shippingVerificationForm.value?.txtDmeMax,
        hcpcs: this.shippingVerificationForm.value?.txtHcpcs,
        coverageCriteria: this.shippingVerificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.shippingVerificationForm.value?.txtQtyLimits,
        frequency: this.shippingVerificationForm.value?.txtFrequency,
        rent: this.shippingVerificationForm.value?.txtRent,
        paRequired: this.shippingVerificationForm.value?.txtPaRequired,
        codesRequirePa: this.shippingVerificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.shippingVerificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.shippingVerificationForm.value?.txtSpokeWith,
        verifiedBy: this.shippingVerificationForm.value?.drpVerifiedBy,
        dateVerified: this.shippingVerificationForm.value?.txtDateVerified,
        preparedBy: this.shippingVerificationForm.value?.drpPreparedBy,
        preparedOn: this.shippingVerificationForm.value?.txtPreparedOn,
        verificationStatus: this.shippingVerificationForm.value?.rbtnVerificationStatus,
        approvalStatus: this.shippingVerificationForm.value.rbtnInsuranceVerify,
        deo: this.shippingVerificationForm.value?.txtDeo,
        coInsurance: this.shippingVerificationForm.value?.txtCoIns,
        commentsToTech: this.shippingVerificationForm.value?.txtcommentsToTech,
        reason: this.shippingVerificationForm.value.txtReason

      }
    } else if (this.shippingVerificationForm.value.txtPlan === payorLevelName[2]) {

      savedData.tertiaryInsuranceVerifies = {
        // insuranceVerificationId: this.insuranceVerificationId,
        chart: this.shippingVerificationForm.value?.txtName,
        name: this.shippingVerificationForm.value?.txtName,
        address: this.shippingVerificationForm.value?.txtName,
        dateofBirth: this.shippingVerificationForm.value?.txtDateOfBirth,
        commentsOrNotes: this.shippingVerificationForm.value?.txtCommentsNotes,
        plan: this.shippingVerificationForm.value?.txtPlan,
        planName: this.shippingVerificationForm.value?.txtPlanName,
        planPhone: this.shippingVerificationForm.value?.txtPlanPhone,
        policy: this.shippingVerificationForm.value?.txtPolicy,
        groupNo: this.shippingVerificationForm.value?.txtGroup,
        effectiveDate: this.shippingVerificationForm.value?.txtEffectiveDate,
        typeOfPolicy: this.shippingVerificationForm.value?.txtType,
        fascalYearStart: this.shippingVerificationForm.value?.txtFascalYearStart,
        end: this.shippingVerificationForm.value?.txtEnd,
        isMedicareCmn: +this.shippingVerificationForm.value?.chkMedicare,
        isPatientHome: +this.shippingVerificationForm.value?.chkPatient,
        isFamilyDeductible: +this.shippingVerificationForm.value?.chkFamilyDeductible,
        cmnCode: this.shippingVerificationForm.value?.txtCmnCode,
        typeOfPlan: this.shippingVerificationForm.value?.drpTypeOfPlan,
        isIndividual: +this.shippingVerificationForm.value?.chkIndividual,
        isOutOfNetwork: +this.shippingVerificationForm.value?.chkOutOfNetwork,
        isFamily: +this.shippingVerificationForm.value?.chkFamily,
        planDeductible: {
          individual: this.shippingVerificationForm.value?.txtDeductibleIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtDeductibleOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtDeductibleFamily,
        },

        deductibleMet: {
          individual: this.shippingVerificationForm.value?.txtDeductibleMetIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtDeductibleMetOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtDeductibleMetFamily,
        },
        payablePercent: {
          individual: this.shippingVerificationForm.value?.drpPayableIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.drpPayableOutOfNetwork,
          family: this.shippingVerificationForm.value?.drpPayableFamily,
        },
        outOfPocketExpense: {
          individual: this.shippingVerificationForm.value?.txtExpenseIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtExpenseOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtExpenseFamily,
        },
        outOfPocketExpenseMet: {
          individual: this.shippingVerificationForm.value?.txtExpenseMetIndividual,
          outOfNetwork: this.shippingVerificationForm.value?.txtExpenseMetOutOfNetwork,
          family: this.shippingVerificationForm.value?.txtExpenseMetFamily,
        },
        outOfPocketIncludeDeductible: {
          individual: +this.shippingVerificationForm.value?.chkExpenseIndividual,
          outOfNetwork: +this.shippingVerificationForm.value?.chkExpenseOutOfNetwork,
          family: +this.shippingVerificationForm.value?.chkExpenseFamily,
        },
        isPercertRequired: +this.shippingVerificationForm.value?.chkPrecertRequired,
        phone: this.shippingVerificationForm.value?.txtPhoneCall,
        isReferralRequired: +this.shippingVerificationForm.value?.chkReferralRequired,
        pcp: this.shippingVerificationForm.value?.txtPcp,
        isPredetermination: +(this.shippingVerificationForm.value?.chkPredetermination == (null || undefined) ? 0 : (this.shippingVerificationForm.value?.chkPredetermination)),
        preNotes: this.shippingVerificationForm.value?.txtPreNotes,
        lifeTimeMax: this.shippingVerificationForm.value?.txtLifetimeMax,
        lifeTimeMaxMet: this.shippingVerificationForm.value?.txtLifetimeMaxMet,
        isDmePartsCovered: +this.shippingVerificationForm.value?.chkDmePartsCovered,
        isDmeReplaceMentUnit: +this.shippingVerificationForm.value?.chkDmeReplacementUnit,
        dmeAnnualLimit: this.shippingVerificationForm.value?.txtDmeAnnualLimit,
        dmeLimitMet: this.shippingVerificationForm.value?.txtDmeLimitMet,
        requestedBy: this.shippingVerificationForm.value?.txtRequestedBy,
        primaryPayor: this.shippingVerificationForm.value?.txtPrimaryPayor,
        contact: this.shippingVerificationForm.value?.txtContact,
        callTrackingNumber: this.shippingVerificationForm.value?.txtCallTrackingNumber,
        policyHolderName: this.shippingVerificationForm.value?.txtPolicyHolderName,
        policyHolderDob: this.shippingVerificationForm.value?.txtPolicyHolderDob,
        isNetwork: +this.shippingVerificationForm.value?.chkNetwork,
        claimAddress: this.shippingVerificationForm.value?.txtClaimAddress,
        planCob: this.shippingVerificationForm.value?.txtPlanCob,
        dedTotal: this.shippingVerificationForm.value?.txtDedTotal,
        dedMet: this.shippingVerificationForm.value?.txtDedMet,
        oopTotal: this.shippingVerificationForm.value?.txtOopTotal,
        oopMet: this.shippingVerificationForm.value?.txtOopMet,
        oop: this.shippingVerificationForm.value?.txtOopDeductible,
        dmeCoverage: this.shippingVerificationForm.value?.txtDmeCoverage,
        dmeMax: this.shippingVerificationForm.value?.txtDmeMax,
        hcpcs: this.shippingVerificationForm.value?.txtHcpcs,
        coverageCriteria: this.shippingVerificationForm.value?.txtCoverageCriteria,
        qtyLimits: this.shippingVerificationForm.value?.txtQtyLimits,
        frequency: this.shippingVerificationForm.value?.txtFrequency,
        rent: this.shippingVerificationForm.value?.txtRent,
        paRequired: this.shippingVerificationForm.value?.txtPaRequired,
        codesRequirePa: this.shippingVerificationForm.value?.txtCodesRequirePa,
        sameOrSimilarInfo: this.shippingVerificationForm.value?.txtSameOrSimilarInfo,
        spokeWith: this.shippingVerificationForm.value?.txtSpokeWith,
        verifiedBy: this.shippingVerificationForm.value?.drpVerifiedBy,
        dateVerified: this.shippingVerificationForm.value?.txtDateVerified,
        preparedBy: this.shippingVerificationForm.value?.drpPreparedBy,
        preparedOn: this.shippingVerificationForm.value?.txtPreparedOn,
        verificationStatus: this.shippingVerificationForm.value?.rbtnVerificationStatus,
        approvalStatus: this.shippingVerificationForm.value.rbtnInsuranceVerify,
        deo: this.shippingVerificationForm.value?.txtDeo,
        coInsurance: this.shippingVerificationForm.value?.txtCoIns,
        commentsToTech: this.shippingVerificationForm.value?.txtcommentsToTech,
        reason: this.shippingVerificationForm.value.txtReason
      }
    }


    let insVerification;
    let verifyData = savedData

    insVerification = {
      defaultClaimId: response?.defaultClaimId,
      orders: response?.orders,
      clinicals: response?.clinicals,
      insurances: response?.insurances,
      items: response?.items,
      patients: {
        personals: PatientData.personals,
        addresses: PatientData.addresses,
        contacts: PatientData.contacts,
        clinicals: PatientData.clinicals,
        insurances: PatientData.insurances,
        notes: PatientData.notes,
        isAutoIntake: PatientData.isAutoIntake,
        policies: PatientData.policies,
        defaultPatientId: PatientData.defaultPatientId,
        insuranceVerifications: {
          primaryInsuranceVerifies: verifyData.primaryInsuranceVerifies,
          secondaryInsuranceVerifies: verifyData.secondaryInsuranceVerifies,
          tertiaryInsuranceVerifies: verifyData.tertiaryInsuranceVerifies,
        },
        authorizations: PatientData.authorizations,
        patientDocuments: PatientData.patientDocuments,
        organizationUnitId: PatientData.organizationUnitId,
        autoIntakeDocumentDetails: response?.patients.autoIntakeDocumentDetails,
        chartId: response.chartId,
        billingDetails: response.patients.billingDetails,
        patientLockDetails: response.patients.patientLockDetails,
        isSorted: response?.patients?.isSorted

      },
      paymentTransactionDetails: response?.paymentTransactionDetails,
      payPalCheckOutOrderMailResendCount: response?.payPalCheckOutOrderMailResendCount,
      saleorderId: response?.saleorderId,
      orderStatus: response?.orderStatus,
      organizationUnitId: response?.organizationUnitId,
      currentStage: response?.currentStage,
      currentStageStatus: response?.currentStageStatus,
      claimId: response?.claimId,
      patientId: response?.patientId,
      isShippingApprove: response?.isShippingApprove,
      isCashPayment: response?.isCashPayment,
      orderProcessStatus: response?.orderProcessStatus,
      patientLedgerReason: response.patientLedgerReason,
      documentReason: response.documentReason,
      payPalCheckOutOrderPaymentStatus: response.payPalCheckOutOrderPaymentStatus,
      payPalInvoiceMailResendCount: response.payPalInvoiceMailResendCount,
      payPalInvoicePaymentStatus: response.payPalInvoicePaymentStatus
      // personals: response?.personals,
      // addresses: response?.addresses,
      // contacts: response?.contacts,
      // clinicals: response?.clinicals,
      // insurances: response?.insurances,
      // notes: response?.notes,
      // policies: response?.policies,
      // defaultPatientId: response?.defaultPatientId,
      // insuranceVerifications: {
      //   primaryInsuranceVerifies: verifyData.primaryInsuranceVerifies,
      //   secondaryInsuranceVerifies: verifyData.secondaryInsuranceVerifies,
      //   tertiaryInsuranceVerifies: verifyData.tertiaryInsuranceVerifies,
      // },
      // authorizations: response?.authorizations,
      // patientDocuments: response?.patientDocuments,
      // organizationUnitId: response?.organizationUnitId
    }
    this.patientId !== "" && this.store.dispatch(new updateOrder(this.orderId, insVerification)).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  // shipping verification get verify by Id
  getInsuranceVerifyByOrderId() {
    this.store.dispatch(new getOrder(this.orderId)).pipe(map(responses => {
      let selectedPatient = responses?.orderState.selectedOrder.patients;
      let verifyData: any[] = [];
      this.patientId = selectedPatient.patientId;
      this.sDefaultPatientId = selectedPatient.defaultPatientId;

      //! To get verification details based on primary, secondary & tertiary policies
      let verifyDatas = selectedPatient.insuranceVerifications.primaryInsuranceVerifies;

      verifyDatas.plan === "Primary" && verifyData.push(verifyDatas)

      return verifyData;
    })).subscribe(response => {


      this.shippingVerificationForm.patchValue({
        drpChart: response[0]?.chart,
        txtName: response[0]?.name,
        txtAddress: response[0]?.address,
        txtDateOfBirth: ((response[0]?.dateofBirth) == null ? "" : response[0]?.dateofBirth),
        txtCommentsNotes: response[0]?.commentsOrNotes,
        txtPlan: response[0]?.plan,
        txtPlanName: response[0]?.planName,
        txtPlanPhone: response[0]?.planPhone,
        txtPolicy: response[0]?.policy,
        txtGroup: response[0]?.groupNo,
        txtEffectiveDate: ((response[0]?.effectiveDate) == null ? "" : response[0]?.effectiveDate),
        txtType: response[0]?.typeOfPolicy,
        txtFascalYearStart: ((response[0]?.fascalYearStart) == null ? "" : response[0]?.fascalYearStart),
        txtEnd: ((response[0]?.end) == null ? "" : response[0]?.end),
        chkMedicare: response[0]?.isMedicareCmn,
        chkPatient: response[0]?.isPatientHome,
        chkFamilyDeductible: response[0]?.isFamilyDeductible,
        txtCmnCode: response[0]?.cmnCode,
        drpTypeOfPlan: response[0]?.typeOfPlan,
        chkIndividual: response[0]?.isIndividual,
        chkOutOfNetwork: response[0]?.isOutOfNetwork,
        chkFamily: response[0]?.isFamily,
        txtDeductibleIndividual: response[0]?.planDeductible.individual,
        txtDeductibleOutOfNetwork: response[0]?.planDeductible.outOfNetwork,
        txtDeductibleFamily: response[0]?.planDeductible.family,
        txtDeductibleMetIndividual: response[0]?.deductibleMet.individual,
        txtDeductibleMetOutOfNetwork: response[0]?.deductibleMet.outOfNetwork,
        txtDeductibleMetFamily: response[0]?.deductibleMet.family,
        drpPayableIndividual: response[0]?.payablePercent.individual,
        drpPayableOutOfNetwork: response[0]?.payablePercent.outOfNetwork,
        drpPayableFamily: response[0]?.payablePercent.family,
        txtExpenseIndividual: response[0]?.outOfPocketExpense.individual,
        txtExpenseOutOfNetwork: response[0]?.outOfPocketExpense.outOfNetwork,
        txtExpenseFamily: response[0]?.outOfPocketExpense.family,
        txtExpenseMetIndividual: response[0]?.outOfPocketExpenseMet.individual,
        txtExpenseMetOutOfNetwork: response[0]?.outOfPocketExpenseMet.outOfNetwork,
        txtExpenseMetFamily: response[0]?.outOfPocketExpenseMet.family,
        chkExpenseIndividual: response[0]?.outOfPocketIncludeDeductible.individual,
        chkExpenseOutOfNetwork: response[0]?.outOfPocketIncludeDeductible.outOfNetwork,
        chkExpenseFamily: response[0]?.outOfPocketIncludeDeductible.family,
        chkPrecertRequired: response[0]?.isPercertRequired,
        txtPhoneCall: response[0]?.phone,
        chkReferralRequired: response[0]?.isReferralRequired,
        txtPcp: response[0]?.pcp,
        chkPredetermination: response[0]?.isPredetermination,
        txtPreNotes: response[0]?.preNotes,
        txtLifetimeMax: response[0]?.lifeTimeMax,
        txtLifetimeMaxMet: response[0]?.lifeTimeMaxMet,
        chkDmePartsCovered: response[0]?.isDmePartsCovered,
        chkDmeReplacementUnit: response[0]?.isDmeReplaceMentUnit,
        txtDmeAnnualLimit: response[0]?.dmeAnnualLimit,
        txtDmeLimitMet: response[0]?.dmeLimitMet,
        txtRequestedBy: response[0]?.requestedBy,
        txtPrimaryPayor: response[0]?.primaryPayor,
        txtContact: response[0]?.contact,
        txtCallTrackingNumber: response[0]?.callTrackingNumber,
        txtPolicyHolderName: response[0]?.policyHolderName,
        txtPolicyHolderDob: ((response[0]?.policyHolderDob) == null ? "" : response[0]?.policyHolderDob),
        chkNetwork: response[0]?.isNetwork,
        txtClaimAddress: response[0]?.claimAddress,
        txtPlanCob: response[0]?.planCob,
        txtDedTotal: response[0]?.dedTotal,
        txtDedMet: response[0]?.dedMet,
        txtOopTotal: response[0]?.oopTotal,
        txtOopMet: response[0]?.oopMet,
        txtOopDeductible: response[0]?.oop,
        txtDmeCoverage: response[0]?.dmeCoverage,
        txtDmeMax: response[0]?.dmeMax,
        txtHcpcs: response[0]?.hcpcs,
        txtCoverageCriteria: response[0]?.coverageCriteria,
        txtQtyLimits: response[0]?.qtyLimits,
        txtFrequency: response[0]?.frequency,
        txtRent: response[0]?.rent,
        txtPaRequired: response[0]?.paRequired,
        txtCodesRequirePa: response[0]?.codesRequirePa,
        txtSameOrSimilarInfo: response[0]?.sameOrSimilarInfo,
        txtSpokeWith: response[0]?.spokeWith,
        drpVerifiedBy: response[0]?.verifiedBy,
        txtDateVerified: ((response[0]?.dateVerified) == null ? "" : response[0]?.dateVerified),
        drpPreparedBy: response[0]?.preparedBy,
        txtPreparedOn: ((response[0]?.preparedOn) == null ? "" : response[0]?.preparedOn),
        rbtnVerificationStatus: response[0]?.verificationStatus,
        rbtnInsuranceVerify: response[0]?.approvalStatus,
        txtDeo: response[0]?.deo,
        txtCoIns: response[0]?.coinsurance,

        txtcommentsToTech: response[0]?.commentsToTech,
        txtReason: response[0]?.txtReason
      })
    }, err => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
  }

  // shipping authorization tab change function
  // save authorization form
  saveShipingAuthorizationForm() {
    if (this.shippingAuthorizationData && this.shippingAuthorizationData !== null) {
      this.authorizationForm.patchValue({
        txtAuthId: this.shippingAuthorizationData['controls'].txtAuthId.value,
        drpAuthVisit: this.shippingAuthorizationData['controls'].drpAuthVisit.value,
        txtAuthAmount: this.shippingAuthorizationData['controls'].txtAuthAmount.value,
        txtStartDate: this.shippingAuthorizationData['controls'].txtStartDate.value,
        txtEndDate: this.shippingAuthorizationData['controls'].txtEndDate.value,
        drpCategory: this.shippingAuthorizationData['controls'].drpCategory.value,
        drpItem: this.shippingAuthorizationData['controls'].drpItem.value,
        txtNote: this.shippingAuthorizationData['controls'].txtNote.value,
        chkCompleted: this.shippingAuthorizationData['controls'].chkCompleted.value,
        txtCompletedBy: this.shippingAuthorizationData['controls'].txtCompletedBy.value,
        drpPlan: this.shippingAuthorizationData['controls'].drpPlan.value,
        // txtPlan: this.savedAuthorizationData['controls'].txtPlan.value,
        // txtPlanName: this.savedAuthorizationData['controls'].txtPlanName.value,
        rbtnAuth: this.shippingAuthorizationData['controls'].rbtnAuth.value,
        rbtnPolicyType: this.shippingAuthorizationData['controls'].rbtnPolicyType
      });
    }
    this.showswal = false;
    this.getPatientDetailsForShippedAuthorizationSave()
    this.shippingAuthorizationData = [];

  }
  // authorization get details
  getPatientDetailsForShippedAuthorizationSave() {
    let personalDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
      personalDetails = response?.orderState.selectedOrder;
      let savedData = {
        primaryAuthorizations: personalDetails.patients.authorizations.primaryAuthorizations,
        secondaryAuthorizations: personalDetails.patients.authorizations.secondaryAuthorizations,
        tertiaryAuthorizations: personalDetails.patients.authorizations.tertiaryAuthorizations
      }
      return this.saveAuthorization(personalDetails, savedData);

    }, err => {
      const data: HttpErrorResponse = err;


      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  // save authorization
  saveAuthorization(response, savedData) {
    let typeOfPlanName: string[] = this.drpPlan.map(v => v.typeOfPlanName);

    if (this.authorizationForm.value.txtPlan === typeOfPlanName[0]) {
      savedData.primaryAuthorizations = {
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.txtPlan,
        planName: this.authorizationForm.value.txtPlanName,
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: ""

      }
    } else if (this.authorizationForm.value.txtPlan === typeOfPlanName[1]) {

      savedData.secondaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.txtPlan,
        planName: this.authorizationForm.value.txtPlanName,
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",

      }
    } else if (this.authorizationForm.value.txtPlan === typeOfPlanName[2]) {

      savedData.tertiaryAuthorizations = {
        authorizationId: this.authorizationId,
        authId: this.authorizationForm.value.txtAuthId,
        authVisits: this.authorizationForm.value.drpAuthVisit,
        authNo: this.authorizationForm.value.txtAuthNo,
        authAmount: this.authorizationForm.value.txtAuthAmount,
        startDate: this.authorizationForm.value.txtStartDate,
        endDate: this.authorizationForm.value.txtEndDate,
        category: this.authorizationForm.value.drpCategory,
        item: this.authorizationForm.value.drpItem === "" ? [] : this.authorizationForm.value.drpItem,
        note: this.authorizationForm.value.txtNote,
        isCompleted: this.authorizationForm.value.chkCompleted === true || this.authorizationForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.authorizationForm.value.txtCompletedBy,
        typeOfPolicy: this.authorizationForm.value.drpPlan,
        plan: this.authorizationForm.value.txtPlan,
        planName: this.authorizationForm.value.txtPlanName,
        approvalStatus: this.authorizationForm.value.rbtnAuth,
        approvedByWhom: "",
        approvedOn: "",

      }
    }
    let insAuthorization: SaleorderDTO;

    insAuthorization = {
      defaultClaimId: response?.defaultClaimId,
      orders: response?.orders,
      clinicals: response?.clinicals,
      insurances: response?.insurances,
      items: response.items,
      patients: {
        personals: response.patients.personals,
        addresses: response.patients.addresses,
        contacts: response.patients.contacts,
        clinicals: response.patients.clinicals,
        insurances: response.patients.insurances,
        notes: response.patients.notes,
        policies: response.patients.policies,
        defaultPatientId: response.patients.defaultPatientId,
        insuranceVerifications: response.patients.insuranceVerifications,
        authorizations: {
          primaryAuthorizations: savedData.primaryAuthorizations,
          secondaryAuthorizations: savedData.secondaryAuthorizations,
          tertiaryAuthorizations: savedData.tertiaryAuthorizations
        },
        patientDocuments: response.patients.patientDocuments,
        organizationUnitId: response.patients.organizationUnitId,
        isAutoIntake: response.patients.isAutoIntake,
        autoIntakeDocumentDetails: response.patients.autoIntakeDocumentDetails,
        chartId: response.chartId,
        billingDetails: response.patients.billingDetails,
        patientLock: response.patients.patientLock,
        // isSorted:response?.patients?.isSorted,
        fileId: response?.patients?.fileId,
      },
      saleorderId: response?.saleorderId,
      orderStatus: response.orderStatus,
      organizationUnitId: response?.organizationUnitId,
      currentStage: response?.currentStage,
      currentStageStatus: response?.currentStageStatus,
      claimId: response.claimId,
      patientId: response.patientId,
      isShippingApprove: response?.isShippingApprove,
      isCashPayment: response.isCashPayment,
      orderProcessStatus: response.orderProcessStatus,
      patientLedgerReason: response.patientLedgerReason,
      documentReason: response.documentReason,
      paymentTransactionDetails: response.paymentTransactionDetails,
      payPalMailResendCount: response?.payPalMailResendCount,
      payPalPaymentMethodType: response?.payPalPaymentMethodType,
      payPalPaymentStatus: response?.payPalPaymentStatus,
      shippingCheckList: response?.shippingCheckList,
      orderBilling: response?.orderBilling,
      deliveryTicket: response?.deliveryTicket,
      authType: response?.authType

    }
    this.orderId !== "" && this.store.dispatch(new updateOrder(this.orderId, insAuthorization)).subscribe(response => {

      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
      this.toastr.success('Saved Successfully','Success')
      this.clearAuthorizationData();
      this.getTableData(response?.orderState?.selectedOrder?.id);
    }, err => {
      const data: HttpErrorResponse = err;


      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  // clear authorization
  clearAuthorizationData() {
    // this.authorizationForm.patchValue({
    //   txtAuthId: "",
    //   drpAuthVisit: "",
    //   txtAuthNo: "",
    //   txtAuthAmount: "",
    //   txtStartDate: "",
    //   txtEndDate: "",
    //   drpCategory: "",
    //   drpItem: "",
    //   txtNote: "",
    //   chkCompleted: "",
    //   txtCompletedBy: "",
    //   drpPlan: "",
    //   txtPlan: "",
    //   rbtnAuth: "",
    // })
    this.authorizationForm.reset();
    this.authorizationForm.markAsUntouched();
    this.authorizationForm.updateValueAndValidity();
  }
  //get table data for authorization
  getTableData(orderId) {
    let saleOrderDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(this.orderId)).pipe(map(responses => {
      let saleOrderDetails = responses?.orderState.selectedOrder;
      let authData: AuthorizationDTO[] = [];
      //! To get verification details based on primary, secondary & tertiary policies
      // const primaryAuth = itemDetails.patients.authorizations.primaryAuthorizations
      let authDatas = saleOrderDetails.patients.authorizations.primaryAuthorizations;
      authDatas?.plan === "Primary" && authData.push(authDatas);
      return authData;
    })).subscribe(primaryAuth => {
      this.authorizationForm.patchValue({
        txtAuthId: primaryAuth[0]?.authId,
        drpAuthVisit: primaryAuth[0]?.authVisits,
        txtAuthNo: primaryAuth[0]?.authNo,
        txtAuthAmount: primaryAuth[0]?.authAmount,
        txtStartDate: primaryAuth[0]?.startDate,
        txtEndDate: primaryAuth[0]?.endDate,
        drpCategory: primaryAuth[0]?.category,
        drpItem: primaryAuth[0]?.item,
        txtNote: primaryAuth[0]?.note,
        chkCompleted: primaryAuth[0]?.isCompleted == "Done" ? 1 : 0,
        txtCompletedBy: primaryAuth[0]?.completedBy,
        txtPlan: primaryAuth[0]?.plan,
        rbtnAuth: primaryAuth[0]?.approvalStatus,
      });


    })
    let itemDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(orderId)).subscribe(response => {
      itemDetails = response?.orderState.selectedOrder;
      this.tblNotesReponse = [];
      const primaryAuth = itemDetails.patients.authorizations.primaryAuthorizations
      const secondaryAuth = itemDetails.patients.authorizations.secondaryAuthorizations
      const tertiaryAuth = itemDetails.patients.authorizations.tertiaryAuthorizations
      if (primaryAuth != null) {
        this.tblNotesReponse.push({
          authId: primaryAuth.authId,
          authVisits: primaryAuth?.authVisits,
          authNo: primaryAuth.authNo,
          authAmount: primaryAuth.authAmount,
          item: primaryAuth.item,
          note: primaryAuth.note,
          typeOfPolicy: primaryAuth.typeOfPolicy,
          startDate: primaryAuth.startDate == null ? "" : new Date(primaryAuth.startDate)?.toLocaleDateString("en-US"),
          endDate: primaryAuth.endDate == null ? "" : new Date(primaryAuth.endDate)?.toLocaleDateString("en-US"),
          category: primaryAuth.category,
          completed: primaryAuth.isCompleted,
          completedBy: primaryAuth.completedBy,
          plan: primaryAuth.plan,
          orderId: itemDetails.id,
          patientId: itemDetails?.patientId,
          authorizationId: primaryAuth?.authorizationId
        });
      }
      if (secondaryAuth != null) {
        this.tblNotesReponse.push({
          authId: secondaryAuth.authId,
          authVisits: secondaryAuth?.authVisits,
          authNo: secondaryAuth.authNo,
          authAmount: secondaryAuth.authAmount,
          item: secondaryAuth.item,
          note: secondaryAuth.note,
          typeOfPolicy: secondaryAuth.typeOfPolicy,
          startDate: secondaryAuth.startDate == null ? "" : new Date(secondaryAuth.endDate)?.toLocaleDateString("en-US"),
          endDate: secondaryAuth.endDate == null ? "" : new Date(secondaryAuth.endDate)?.toLocaleDateString("en-US"),
          category: secondaryAuth.category,
          completed: secondaryAuth.isCompleted,
          completedBy: secondaryAuth.completedBy,
          plan: secondaryAuth.plan,
          orderId: itemDetails.id,
          patientId: itemDetails?.patientId,
          authorizationId: secondaryAuth?.authorizationId
        });
      }
      if (tertiaryAuth != null) {
        this.tblNotesReponse.push({
          authId: tertiaryAuth.authId,
          authVisits: tertiaryAuth?.startDate,
          authNo: tertiaryAuth.authNo,
          authAmount: tertiaryAuth.authAmount,
          item: tertiaryAuth.item,
          note: tertiaryAuth.note,
          typeOfPolicy: tertiaryAuth.typeOfPolicy,
          startDate: tertiaryAuth.startDate == null ? "" : new Date(tertiaryAuth.endDate)?.toLocaleDateString("en-US"),
          endDate: tertiaryAuth.endDate == null ? "" : new Date(tertiaryAuth.endDate)?.toLocaleDateString("en-US"),
          category: tertiaryAuth.category,
          completed: tertiaryAuth.isCompleted,
          completedBy: tertiaryAuth.completedBy,
          plan: tertiaryAuth.plan,
          orderId: itemDetails.id,
          patientId: itemDetails?.patientId,
          authorizationId: tertiaryAuth?.authorizationId
        });
      }

      this.Table.setAuthTable(this.tblNotesReponse);
    }, err => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });
  }

  getDropdown() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.AuthVisits,
      OrderDropdowns.TypeOfPlans,
      OrderDropdowns.PlanTypes
    ]
    this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {

      let response: OrderMasterDropdownDTO = stateResponse;
      this.drpAuthVisit = response?.authVisits;

      this.drpPlan = response?.typeOfPlans;
      this.drpPlanType = response?.planTypes;


      // this.drpAuthItem = response.authItems;
    }, err => {
      // const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
    // this.orderId != "" && this.orderId != null && this.orderId != undefined && this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {


    // }, err => {
    //   // const data: HttpErrorResponse = err;
    //   // Swal.fire({
    //   //   icon: 'error',
    //   //   text: data?.error?.error?.message,
    //   // });
    // });
    this.Table.getSelectedOrderId().subscribe(value => {
      setTimeout(() => {
        this.orderId != null && this.currentOrderStatusShow(this.orderId);
      }, 400);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });


    // this.currentOrderStatusShow(this.orderId)
  }

  // current order status show
  currentOrderStatusShow(orderId) {
    this.orderId != null && this.orderId != "" && this.store.dispatch(new getOrder(orderId)).subscribe(response => {

      this.currentOrderStatus = response?.orderState?.selectedOrder?.orderStatus
    })

  }

  // patient ledger forms
  saveShipingPatientLedgerForm() {
    if (this.shipingPatientLedgerData && this.shipingPatientLedgerData !== null) {
      this.ledgerForm.patchValue({
        rbtnLedger: this.shipingPatientLedgerData['controls'].rbtnLedger.value,
      });
    }
    this.showswal = false;
    this.getPatientDetailsForPatientLedgerSave()
    this.shipingPatientLedgerData = [];
  }

  getPatientDetailsForPatientLedgerSave() {
    let authOrderDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
      //
      authOrderDetails = response?.orderState.selectedOrder;
      this.saveLedger(authOrderDetails);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  saveLedger(response) {
    let personal: PatientDTO;
    let authDetails = {
      organizationUnitId: response?.organizationUnitId,
      orders: response?.orders,
      insurances: response?.insurances,
      clinicals: response?.clinicals,
      items: response?.items,
      insuranceVerifies: response?.insuranceVerifies,
      authorizations: response?.authorizations,
      orderStatus: response?.orderStatus,
      saleorderId: response?.saleorderId,
      currentStage: response?.currentStage,
      currentStageStatus: response?.currentStageStatus,
      orderWipStatus: response?.orderWipStatus,
      patientLedgerStatus: this.ledgerForm.value.rbtnLedger,
      shippingApprovalStatus: response?.shippingApprovalStatus,
      claimHistoryStatus: response?.claimHistoryStatus,
      patients: response?.patients,
      patientId: response?.patientId
      // shippingApprovalStatus:response?.shippingApprovalStatus
    }
    this.orderId != "" && this.store.dispatch(new updateOrder(this.orderId, authDetails)).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')


    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  getByData(response) {

    this.authorizationForm.patchValue({
      txtAuthId: response.authId,
      drpAuthVisit: response.authVisits,
      txtAuthNo: response.authNo,
      txtAuthAmount: response.authAmount,
      txtStartDate: response.startDate,
      txtEndDate: response.endDate,
      drpCategory: response.category,
      drpItem: response.item,
      txtNote: response.note,
      chkCompleted: response.completed,
      txtCompletedBy: response.completedBy,
      txtPlan: response.plan
    })
  }

  // Shipping history save
  saveShipingHistoryForm() {
    if (this.shipingHistoryData && this.shipingHistoryData !== null) {
      this.claimHistoryForm.patchValue({
        rbtnClaimHistory: this.shipingHistoryData['controls'].rbtnClaimHistory.value,
        chkShippingApproved: this.shipingHistoryData['controls'].chkShippingApproved.value,
      });
    }
    this.showswal = false;
    this.getPatientDetailsForHistorySave()
    this.shipingHistoryData = [];

  }
  getPatientDetailsForHistorySave() {
    let authOrderDetails: SaleorderDTO;
    this.store.dispatch(new getOrder(this.orderId)).subscribe(response => {
      //
      authOrderDetails = response?.orderState.selectedOrder;
      this.saveClaimHistory(authOrderDetails);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  saveClaimHistory(response: SaleorderDTO) {
    // let personal: PatientDTO;
    let authDetails = {
      orders: response?.orders,
      insurances: response?.insurances,
      clinicals: response?.clinicals,
      items: response?.items,
      orderStatus: response?.orderStatus,
      saleorderId: response?.saleorderId,
      currentStage: this.claimHistoryForm.value.chkShippingApproved === true ? "shippingToBilling" : "orderProcessing",
      currentStageStatus: response?.currentStageStatus,
      orderWipStatus: response?.orderWipStatus,
      patientLedgerStatus: response?.patientLedgerStatus,
      claimHistoryStatus: this.claimHistoryForm.value.rbtnClaimHistory,
      shippingApprovalStatus: this.claimHistoryForm.value.chkShippingApproved === true ? "shippingToBilling" : "orderProcessing",
      patients: response?.patients,
      organizationUnitId: response?.organizationUnitId,
      patientId: response?.patientId,
      isShippingApprove: response?.isShippingApprove
    }
    this.orderId != "" && this.store.dispatch(new updateOrder(this.orderId, authDetails)).subscribe(responses => {
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Saved Successfully','Success')
      //  this.getAuthTableData(this.orderId);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.orderId != "" && this.store.dispatch(new updateOrder(this.orderId, authDetails)).subscribe(responses => {
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  // change next tab functions
  changeNextTab(value: string) {
    value === "Document" && this.nav?.select("Verification");
    value === "Verification" && this.nav?.select("Authorization");
    value === "Authorization" && this.nav?.select("Ledger");
    value === "Ledger" && this.nav?.select("History");
  }

  // get patient details for side bar

  countryShortName: string = '';

  getDetailsByPatientID(patientId: string) {
    this.patientId !== null && this.patientId !== undefined && this.patientId !== "" && this.store.dispatch(new getPatient(patientId)).subscribe(responseData => {
      let personalResponse: PersonalDTO = responseData?.patientState?.selectedPatient?.personals;
      let patientResponse: PatientDTO = responseData?.patientState?.selectedPatient;
      let clinicalResponse: ClinicalDTO = responseData?.patientState?.selectedPatient?.clinicals;
      this.lblLastUpdatedDateTime = patientResponse?.lastModificationTime;
      if (personalResponse !== null || personalResponse !== undefined) {
        this.lblDefaultPatientId = responseData?.patientState?.selectedPatient?.defaultPatientId?.toString();
        this.lblPatientName = personalResponse?.fullName;
        // this.lblPatientGender = clinicalResponse?.genderType == null ? "" : clinicalResponse?.genderType,
        this.lblPatientGender = responseData?.patientState.selectedPatient?.clinicals?.genderType === 'M' ? "Male" : responseData?.patientState.selectedPatient?.clinicals?.genderType === 'F' ? "Female" : responseData?.patientState.selectedPatient?.clinicals?.genderType === "U" ? "Unknown" : '',
          this.lblPatientDOB = personalResponse?.dateOfBirth == null ? "" : new Date(personalResponse?.dateOfBirth)?.toLocaleDateString("en-US");
        this.lblPatientHeight = clinicalResponse?.height === 0 ? "" : clinicalResponse?.height.toString();
        this.lblPatientWeight = clinicalResponse?.weight === 0 ? "" : clinicalResponse?.weight.toString();
        //  this.lblBranchOffice = personalResponse?.branchOffice == null ? "" : personalResponse?.branchOffice
        this.lblAccountNumber = personalResponse?.accountNumber;
        this.lblSsnNumber = personalResponse?.ssn;
        this.patientId = responseData?.patientState?.selectedPatient?.id;
        this.lblBranchOffice = personalResponse?.branchOffice;
        if (patientResponse?.billingDetails != null) {
          this.lblBillingAmount = patientResponse?.billingDetails.amount;
          this.lblBillingStatus = patientResponse?.billingDetails.status;
        }
        if (patientResponse?.billingDetails?.status === "Processed") {
          this.forBlink = false;
        }


        //this.lblBranchOffice = this.drpBranchOffices?.filter(value => { return value && value?.branchOfficeShortCodeId === personalResponse?.branchOffice })[0]?.branchOffice;
        // this.strLocation = this.drpLocationTypes?.filter(value => { return value && value?.locationTypeShortCodeId === patientResponse?.personals?.locationType })[0].locationType;
        // this.strLocation = this.drpLocationTypes?.filter(value => { return value && value?.locationTypeShortCodeId === patientResponse?.personals?.locationType })[0].locationType;
        this.strLocation = personalResponse?.locationType;
        this.strBillingAddress = patientResponse?.addresses[0].address;

        this.region.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
          this.strBillingCountry = response?.items?.filter(value => { return value && value?.countryShortName === patientResponse?.addresses[0]?.country })[0]?.countryName;
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });


        patientResponse?.addresses[0]?.country != null && patientResponse?.addresses[0]?.country !== "" && patientResponse?.addresses[0]?.country != undefined &&
          this.region.getAllStatesByCountryNameByCountryShortName(patientResponse?.addresses[0]?.country).subscribe(response => {
            this.strBillingState = response?.items?.filter(value => { return value && value?.stateShortName === patientResponse?.addresses[0]?.state })[0]?.stateName
          }, err => {
            const data: HttpErrorResponse = err;
            // Swal.fire({
            //   icon: 'error',
            //   text: data?.error?.error?.message,
            // });
          });

        //this.strBillingState = patientResponse?.addresses[0].state; //this.drpState?.filter(value => { return value && value?.stateId === patientResponse?.addresses[0].state })[0]?.stateType;
        this.strBillingCity = patientResponse?.addresses[0].city;//this.drpCity?.filter(value => { return value && value?.cityId === patientResponse?.addresses[0].city })[0]?.cityType;
        //this.drpCountries?.filter(value => { return value && value?.countryTypeId === patientResponse?.addresses[0].country })[0]?.countryType;

        this.strPostalCode = patientResponse?.addresses[0]?.postalCode;
        this.strMobile = patientResponse?.addresses[0]?.mobile;
        //this.strEmailAddress = patientResponse?.addresses[0]?.emailId;
        this.strEmailAddress = patientResponse?.personals?.emailId;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  backToApprovalList() {
    this.router.navigate(['orders/shippingApprovalList']);
  }

}

export interface approvalStatus {
  document?: boolean,
  verification?: boolean,
  authorization?: boolean,
  ledger?: boolean
}
