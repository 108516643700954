<div class="row">
    <div class="col-12">
        <div class="row" id="dropdown-container">
            <div class="col-12 d-flex justify-content-between">
                <form [formGroup]="patientEntrySearchForm">

                    <!--Branch Drop Down-->
                    <mat-form-field class="col-1_5 custom-form-field" appearance="none">
                        <mat-label class="drpLabel">
                            <div class="select-placeholder-container" *ngIf="isBranchLoading">
                                <span>Loading...</span>
                                <mat-spinner class="spinner" diameter="20"></mat-spinner>
                            </div>
                            <span *ngIf="!isBranchLoading" class="drpLabel">Branch</span>
                        </mat-label>
                        <mat-select formControlName="drpBranchtype" multiple
                            (selectionChange)="onSelectionBranchChange($event)"
                            (openedChange)="onBranchDropdownOpenChange($event,selectedBranchValue)" (closed)="handleCloseDrpdown(patientEntrySearchForm.get('drpBranchtype').value);" class="drpOptionFontSize">
                            <mat-option class="drpOptionFontSize">
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtBranchTypeV1"
                                    (keyup)="getBrachDropDown($event.target.value,selectedBranchValue)"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngIf="!BranchSelectAllOption" [value]="'selectAll'"
                                class="drpOptionFontSize">Select All</mat-option>
                            <mat-option *ngIf="BranchSelectAllOption" [value]="'DeselectAll'"
                                class="drpOptionFontSize">Deselect All</mat-option>
                            <mat-option *ngFor="let branch of lstFilterBranch | async" [value]="branch?.orgId"
                                class="drpOptionFontSize">
                                {{ branch?.organizationUnitName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>


                     <!--Insurance Drop Down-->
                     <mat-form-field class="col-1_5 custom-form-field" appearance="none" *ngIf="activeTabBin === MyQueueBinStatus.Verification || activeTabBin === MyQueueBinStatus.Verification">
                        <mat-label class="drpLabel">Insurance</mat-label>
                        <mat-select formControlName="drpInsurance" multiple (selectionChange)="onSelectionInsuranceChange($event)"
                            (closed)="handleCloseDrpdown(patientEntrySearchForm.get('drpInsurance').value);" class="drpOptionFontSize" (openedChange)="onInsDropdownOpenChange($event,selectedInsValue)">
                            <mat-option class="drpOptionFontSize">
                                <ngx-mat-select-search placeholderLabel="Payer Id / Name / Code" noEntriesFoundLabel="No Matches found" formControlName="txtInsuranceTypeV1" (keyup)="payerSearch($event.target.value,$event.key)"></ngx-mat-select-search>
                            </mat-option>

                            <mat-option *ngIf="!InsuranceSelectAllOption" [value]="'selectAll'" class="drpOptionFontSize">Select All</mat-option>
                            <mat-option *ngIf="InsuranceSelectAllOption" [value]="'DeselectAll'" class="drpOptionFontSize">Deselect All</mat-option>

                        <mat-option *ngFor="let payor of filteredPrimaryPayors | async "[value]="payor?.payerId" [title]="payor?.payerShortCodeList" class="drpOptionFontSize">
                            {{ payor?.payerShortCodeList }} 
                        </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--TAT-->
                    <mat-form-field class="col-1_5 custom-form-field" appearance="none">
                        <mat-label class="drpLabel">TAT</mat-label>
                        <mat-select class="custom-mat-select" formControlName="drptatControl" multiple
                            (selectionChange)="onSelectionTATChange($event)" class="drpOptionFontSize" (closed)="handleCloseDrpdown(patientEntrySearchForm.get('drptatControl').value);">
                            <mat-option class="drpOptionFontSize">
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    [formControl]="TATTypeControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngIf="!TATSelectAllOption" [value]="'selectAll'"
                                class="drpOptionFontSize">Select All</mat-option>
                            <mat-option *ngIf="TATSelectAllOption" [value]="'DeselectAll'"
                                class="drpOptionFontSize">Deselect All</mat-option>
                            <mat-option *ngFor="let key of tatFilterKeys" [value]="key.tatId" class="drpOptionFontSize">
                                {{ key.tatValue }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                      <!--Primary Insurance Drop Down-->
                      <mat-form-field class="col-1_5 custom-form-field" appearance="none" *ngIf="activeTabBin !== MyQueueBinStatus.Unprocessed && activeTabBin !== MyQueueBinStatus.ReadyforOrder" style="min-width: 140px !important;">
                        <mat-label class="drpLabel">Primary Insurance</mat-label>
                        <mat-select formControlName="drpPrimaryInsurance" multiple (selectionChange)="onSelectionPrimaryInsuranceChange($event)" (openedChange)="onPrimInsDropdownOpenChange($event,selectedPriInsValue)"
                            (closed)="handleCloseDrpdown(patientEntrySearchForm.get('drpPrimaryInsurance').value);" class="drpOptionFontSize">
                            <mat-option class="drpOptionFontSize">
                                <ngx-mat-select-search placeholderLabel="Payer Id / Name / Code" noEntriesFoundLabel="No Matches found" formControlName="txtPrimaryInsuranceType" (keyup)="payerPrimSearch($event.target.value,$event.key)"></ngx-mat-select-search>
                            </mat-option>

                            <mat-option *ngIf="!PrimaryInsuSelectAllOption" [value]="'selectAll'" class="drpOptionFontSize">Select All</mat-option>
                            <mat-option *ngIf="PrimaryInsuSelectAllOption" [value]="'DeselectAll'" class="drpOptionFontSize">Deselect All</mat-option>

                        <mat-option *ngFor="let payor of filteredInsurancePayors | async "[value]="payor?.payerId" [title]="payor?.payerShortCodeList" class="drpOptionFontSize">
                            {{ payor?.payerShortCodeList }} 
                        </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--Verfication Status Drop Don-->
                    <mat-form-field class="col-1_5 custom-form-field" appearance="none" *ngIf="activeTabBin === MyQueueBinStatus.Verification" style="min-width: 140px !important;">
                        <mat-label class="drpLabel">Verification Status</mat-label>
                        <mat-select class="custom-mat-select" formControlName="drpVerificationStatus" multiple
                            (selectionChange)="onSelectionVerifiacationChange($event)" class="drpOptionFontSize" (closed)="handleCloseDrpdown(patientEntrySearchForm.get('drpVerificationStatus').value);">
                            <mat-option class="drpOptionFontSize">
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found" [formControl]="verificaionTypeControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngIf="!VerificationSelectAllOption" [value]="'selectAll'" class="drpOptionFontSize">Select All</mat-option>
                            <mat-option *ngIf="VerificationSelectAllOption" [value]="'DeselectAll'" class="drpOptionFontSize">Deselect All</mat-option>
                            <mat-option *ngFor="let option of verificationOptions" [value]="option.value" class="drpOptionFontSize">
                                {{ option.key }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--Schedule Status-->
                    <mat-form-field class="col-1_5 custom-form-field" appearance="none" *ngIf="activeTabBin === MyQueueBinStatus.Schedule" style="min-width: 130px !important;">
                        <mat-label class="drpLabel">Schedule Status</mat-label>
                        <mat-select class="custom-mat-select" formControlName="drpSecheduleStatus" multiple
                            (selectionChange)="onSelectionScheduleChange($event)" class="drpOptionFontSize" (closed)="handleCloseDrpdown(patientEntrySearchForm.get('drpSecheduleStatus').value);">
                            <mat-option class="drpOptionFontSize">
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    [formControl]="calendarTypeControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngIf="!scheduleSelectAllOption" [value]="'selectAll'" class="drpOptionFontSize">Select All</mat-option>
                            <mat-option *ngIf="scheduleSelectAllOption" [value]="'DeselectAll'" class="drpOptionFontSize">Deselect All</mat-option>
                            <mat-option *ngFor="let key of scheduleDrpValues" [value]="key.scheValues" class="drpOptionFontSize">
                                {{ key.scheValues }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>


                    <!--Checklist Status Drop Don-->
                    <mat-form-field class="col-1_5 custom-form-field" appearance="none" *ngIf="activeTabBin === MyQueueBinStatus.Checklist" style="min-width: 140px !important;">
                        <mat-label class="drpLabel">Checklist Status</mat-label>
                        <mat-select class="custom-mat-select" formControlName="drpChecklistStatus" multiple
                            (selectionChange)="onSelectionChecklistChange($event)" class="drpOptionFontSize" (closed)="handleCloseDrpdown(patientEntrySearchForm.get('drpChecklistStatus').value);">
                            <mat-option class="drpOptionFontSize">
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found" [formControl]="checklistTypeControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngIf="!checklistSelectAllOption" [value]="'selectAll'" class="drpOptionFontSize">Select All</mat-option>
                            <mat-option *ngIf="checklistSelectAllOption" [value]="'DeselectAll'" class="drpOptionFontSize">Deselect All</mat-option>
                            <mat-option *ngFor="let option of checklistOptions" [value]="option.key" class="drpOptionFontSize">
                                {{ option.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--Auth Status Drop Down-->
                    <mat-form-field class="col-1_5 custom-form-field" appearance="none" *ngIf="activeTabBin === MyQueueBinStatus.Precert">
                        <mat-label class="drpLabel">Auth Status</mat-label>
                        <mat-select class="custom-mat-select" formControlName="drpAuthStatus" multiple
                            (selectionChange)="onSelectionAuthChange($event)" class="drpOptionFontSize" (closed)="handleCloseDrpdown(patientEntrySearchForm.get('drpAuthStatus').value);">
                            <mat-option class="drpOptionFontSize">
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found" formControlName="txtInsuranceSearch"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngIf="!AuthSelectAllOption" [value]="'selectAll'" class="drpOptionFontSize">Select All</mat-option>
                            <mat-option *ngIf="AuthSelectAllOption" [value]="'DeselectAll'" class="drpOptionFontSize">Deselect All</mat-option>
                            <mat-option *ngFor="let key of ltAuthStatus | async" [value]="key.authId" class="drpOptionFontSize">
                                {{ key.authStatus }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--CSR Name Drop Down-->
                    <mat-form-field class="col-1_5 custom-form-field" appearance="none">
                        <mat-label class="drpLabel">
                            <div class="select-placeholder-container" *ngIf="isCsrLoading">
                                <span>Loading...</span>
                                <mat-spinner class="spinner" diameter="20"></mat-spinner>
                            </div>
                        
                            <span *ngIf="!isCsrLoading">
                                <span *ngIf="activeTabBin === MyQueueBinStatus.Schedule" class="drpLabel">Technician</span>
                                <span *ngIf="activeTabBin !== MyQueueBinStatus.Schedule" class="drpLabel">CSR Name</span>
                            </span>
                        </mat-label>
                        <mat-select formControlName="drpCSRName" multiple
                            (selectionChange)="onSelectionCSRChange($event)"
                            (openedChange)="onCSRDropdownOpenChange($event,selectedCSRValue)" (closed)="handleCloseDrpdown(patientEntrySearchForm.get('drpCSRName').value);" class="drpOptionFontSize">
                            <mat-option class="drpOptionFontSize">
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtCSRTypeV1"
                                    (keyup)="getCSRNameDropDown($event.target.value,selectedCSRValue)"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngIf="!csrSelectAllOption" [value]="'selectAll'"
                                class="drpOptionFontSize">Select All</mat-option>
                            <mat-option *ngIf="csrSelectAllOption" [value]="'DeselectAll'"
                                class="drpOptionFontSize">Deselect All</mat-option>
                            <mat-option *ngFor="let data of lstFilterCSR | async" [value]="data?.csrId"
                                class="drpOptionFontSize">
                                {{ data?.userName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--Practice Drop Down-->
                    <mat-form-field class="col-1_5 custom-form-field" appearance="none">
                        <mat-label class="drpLabel">
                            <div class="select-placeholder-container" *ngIf="isPracticeLoading">
                                <span>Loading...</span>
                                <mat-spinner class="spinner" diameter="20"></mat-spinner>
                            </div>
                            <span *ngIf="!isPracticeLoading" class="drpLabel">Practice</span>
                        </mat-label>
                        <mat-select formControlName="drpPractice" multiple
                            (selectionChange)="onSelectionPracticeChange($event)"
                            (openedChange)="onPracticeDropdownOpenChange($event,selectedPracticeValue)" (closed)="handleCloseDrpdown(patientEntrySearchForm.get('drpPractice').value);" class="drpOptionFontSize">
                            <mat-option class="drpOptionFontSize">
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtPracticeType"
                                    (keyup)="getPracticeDropDown($event.target.value,selectedPracticeValue)"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngIf="!practiceSelectAllOption" [value]="'selectAll'"
                                class="drpOptionFontSize">Select All</mat-option>
                            <mat-option *ngIf="practiceSelectAllOption" [value]="'DeselectAll'"
                                class="drpOptionFontSize">Deselect All</mat-option>
                            <mat-option *ngFor="let data of lstFilterPractice | async" [value]="data?.practiceID"
                                class="drpOptionFontSize">
                                {{ data?.practiceName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--Sorting Drop Down-->
                    <mat-form-field class="col-1_5 custom-form-field" appearance="none">
                        <mat-label class="drpLabel">Sorting</mat-label>
                        <mat-select class="drpOptionFontSize" formControlName="drpSorting" (closed)="handleCloseDrpdown(patientEntrySearchForm.get('drpSorting').value);">
                            <mat-option *ngFor="let option of sortingOptions" [value]="option.value"
                                class="drpOptionFontSize">{{ option.key }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--Split Drop Down-->
                    <mat-form-field class="col-1_5 custom-form-field" appearance="none">
                        <mat-label class="drpLabel">
                            <div class="select-placeholder-container" *ngIf="isSplitLoading">
                                <span>Loading...</span>
                                <mat-spinner class="spinner" diameter="20"></mat-spinner>
                            </div>
                            <span *ngIf="!isSplitLoading" class="drpLabel">Split</span>
                        </mat-label>
                        <mat-select formControlName="drpSplit" multiple
                            (selectionChange)="onSelectionSplitChange($event)"
                            (openedChange)="onSplitDropdownOpenChange($event,selectedSplitValue)" (closed)="handleCloseDrpdown(patientEntrySearchForm.get('drpSplit').value);" class="drpOptionFontSize">
                            <mat-option class="drpOptionFontSize">
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtSplitSearch"
                                    (keyup)="getSplitDropDown($event.target.value,selectedSplitValue)"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngIf="!splitSelectAllOption" [value]="'selectAll'"
                                class="drpOptionFontSize">Select All</mat-option>
                            <mat-option *ngIf="splitSelectAllOption" [value]="'DeselectAll'"
                                class="drpOptionFontSize">Deselect All</mat-option>
                            <mat-option *ngFor="let data of lstFilterSplit | async" [value]="data?.sfax"
                                class="drpOptionFontSize">
                                {{ data?.faxId }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--Join Drop Down-->
                    <mat-form-field class="col-1_5 custom-form-field" appearance="none">
                        <mat-label class="drpLabel">
                            <div class="select-placeholder-container" *ngIf="isJoinLoading">
                                <span>Loading...</span>
                                <mat-spinner class="spinner" diameter="20"></mat-spinner>
                            </div>
                            <span *ngIf="!isJoinLoading" class="drpLabel">Join</span>
                        </mat-label>
                        <mat-select formControlName="drpJoin" multiple
                            (selectionChange)="onSelectionJoinChange($event)"
                            (openedChange)="onJoinDropdownOpenChange($event,selectedJointValue)" (closed)="handleCloseDrpdown(patientEntrySearchForm.get('drpJoin').value);" class="drpOptionFontSize">
                            <mat-option class="drpOptionFontSize">
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                    formControlName="txtJoinSearch"
                                    (keyup)="getJoinDropDown($event.target.value,selectedJointValue)"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngIf="!splitSelectAllOption" [value]="'selectAll'"
                                class="drpOptionFontSize">Select All</mat-option>
                            <mat-option *ngIf="splitSelectAllOption" [value]="'DeselectAll'"
                                class="drpOptionFontSize">Deselect All</mat-option>
                            <mat-option *ngFor="let data of lstFilterJoin | async" [value]="data?.sfax"
                                class="drpOptionFontSize">
                                {{ data?.faxId }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </form>
            </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf="loadInboundData">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
          </div>
        <div class="table-responsive" *ngIf="!loadInboundData">
            <table #tableContainer datatable [dtOptions]="dtDocumentBinTableOptions"
                class="row-border hover w-100 display dataTables_scroll">
                <thead>
                    <tr>
                        <th><mat-icon>apps</mat-icon></th>
                        <th [matTooltip]="'Received Date'">Received Date</th>
                        <th [matTooltip]="'Chart Id'">Chart Id</th>
                        <th [matTooltip]="'Patient Name'">Patient Name</th>
                        <th [matTooltip]="'Primary Insurance'">Primary Insurance</th>
                        <th [matTooltip]="'Location'">Location</th>
                        <th [matTooltip]="'Primary Verification Status'">Primary Verification Status</th>
                        <th [matTooltip]="'Auth Status'">Auth Status</th>
                        <th [matTooltip]="'Medicare Status'">Medicare Status</th>
                        <th [matTooltip]="'Technician'">Technician</th>
                        <th [matTooltip]="'Schedule Status'">Schedule Status</th>
                        <th>TAT</th>
                        <th>Fax Id</th>
                        <th>Document</th>
                        <th>Modified</th>
                        <th>Secondary Insurance</th>
                        <th>Tertiary Insurance</th>
                        <th>CSR Name</th>
                        <th>CheckList Id</th>
                        <th>Sorting</th>
                        <th>Upload Type</th>
                        <th>Service Type</th>
                        <th>Document Check List</th>
                        <th>Practice</th>
                        <th>Insurance Group</th>
                        <th>Aging Days</th>
                        <th>Split</th>
                        <th>Join</th>
                        <th>Prepared On </th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let document of inBoundTableData">

                        <td class="no-select">
                            <mat-icon class="pointer toggle-icon"
                                (click)="getTotalInboundData($event, document)">add</mat-icon>

                            <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                            <mat-menu class="custom-mat-menu" #menu="matMenu">
                                <button
                                    (click)="openViewNotesPopup(document.patientId,document.defaultFaxId,document.docId)"
                                    [disabled]="isEmpty(document.patientId)" mat-menu-item>
                                    View Patient Notes
                                </button>
                                <button mat-menu-item (click)="viewPatientLedger(document?.patientId)"
                                    [disabled]="isEmpty(document.patientId)">
                                    View Patient Ledger
                                </button>
                            </mat-menu>
                        </td>

                        <td (dblclick)="onRowDoubleClick(document?.uploadedDate)"
                            [matTooltip]="document?.uploadedDate || '-'">
                            {{ document?.uploadedDate || '-'}}
                        </td>

                        <td (dblclick)="onRowDoubleClick(document.chartId || '-')" class="custom-width">
                            <a [class.disabled]="isEmpty(document.chartId) || isEmpty(document.patientId)"
                                class="preview-link" [routerLink]="'/patientCreateEditTab/' + document.patientId"
                                target="_blank" [matTooltip]="document.chartId || '-'">{{ document.chartId || "-" }}</a>
                        </td>

                        <td (dblclick)="onRowDoubleClick(document?.patientName)"
                            [matTooltip]="document?.patientName || '-'">
                            {{ document?.patientName ||'-' }}
                        </td>

                        <td (dblclick)="onRowDoubleClick(document?.priPolicyName)"
                            [matTooltip]="document?.priPolicyName || '-'">
                            {{ document?.priPolicyName || '-'}}
                        </td>

                        <td (dblclick)="onRowDoubleClick(document?.branchName)"
                            [matTooltip]="document?.branchName || '-'">
                            {{ document?.branchName || '-'}}
                        </td>
                        <td (dblclick)="onRowDoubleClick(document?.priVerificationStatus)"
                            [matTooltip]="document?.priVerificationStatus || '-'">
                            {{ document?.priVerificationStatus || '-'}}
                        </td>

                        <td (dblclick)="onRowDoubleClick(document?.authStatusName)"
                            [matTooltip]="document?.authStatusName || '-'">
                            {{ document?.authStatusName || '-'}}
                        </td>
                        <td (dblclick)="onRowDoubleClick(document?.medicareStatus)"
                            [matTooltip]="document?.medicareStatus || '-'">
                            {{ document?.medicareStatus || '-'}}
                        </td>
                        <td (dblclick)="onRowDoubleClick(document?.scheduledToName)"
                            [matTooltip]="document?.scheduledToName || '-'">
                            {{ document?.scheduledToName || '-'}}
                        </td>
                        <td (dblclick)="onRowDoubleClick(document?.scheduleStatus)"
                            [matTooltip]="document?.scheduleStatus || '-'">
                            {{ document?.scheduleStatus || '-'}}
                        </td>

                        <td>
                            {{ document?.tat || '-'}}
                        </td>

                        <td>
                            {{ document?.defaultFaxId || '-'}}
                        </td>
                        <td>
                            {{ document?.documentName || '-'}}
                        </td>
                        <td>
                            {{ document?.modifiedByAndDateText || '-'}}
                        </td>
                        <td>
                            {{ document?.secPolicyName || '-'}}
                        </td>
                        <td>
                            {{ document?.terPolicyName || '-'}}
                        </td>
                        <td>
                            {{ document?.checklistId || '-'}}
                        </td>
                        <td>
                            {{ document?.csrName || '-'}}
                        </td>
                        <td>
                            {{ document?.isSorted !== null && document?.isSorted !== undefined && document?.isSorted !==
                            ''
                            ?
                            document?.isSorted ? "Yes" : "No" : "-" }}
                        </td>
                        <td>
                            {{ document?.uploadType || '-'}}
                        </td>

                        <td>
                            {{ document?.serviceType || '-'}}
                        </td>
                        <td>
                            {{ document?.isDocumentCheckList || '-'}}
                        </td>
                        <td>
                            {{ document?.practice || '-'}}
                        </td>

                        <td>
                            {{ document?.insuranceGroup || '-'}}
                        </td>

                        <td>
                            {{ document?.agingsDays || '-'}}
                        </td>
                        <td>
                            {{ document?.splitFromFaxId || '-'}}
                        </td>
                        <td>
                            {{ document?.joinedFaxIds || '-'}}
                        </td>
                        <td>
                            {{ document?.preparedOn || '-'}}
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>