import { Component, OnInit, ViewChild } from '@angular/core';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { WonaceOrderTab } from 'projects/order/src/app/order-proxy/order-management/order-optimization/wonace-order-tab.enum';
import { MyQueueBinStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import {
  InputOrderListDto,
  InputOrderStatusListDto,
  OrderListDto,
  OrderStatusListDto,
  requestDTO,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { Form, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import {
  InputPatientListDto,
  PatientChartIdDTO,
  PatientListDto,
  PayorDetailDTO,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-order-bin-queue',
  templateUrl: './order-bin-queue.component.html',
  styleUrls: ['./order-bin-queue.component.scss'],
})
export class OrderBinQueueComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  MyQueueBinStatus = MyQueueBinStatus;
  orderBinQueueFilterForm: FormGroup;
  activeTabBin: MyQueueBinStatus;
  currentPage: any = 1;
  totalPages: any = 1;
  pageOffset: any = 50;
  wonaceOrderTab = WonaceOrderTab;
  loadInboundOrderData: boolean = false;
  getInboundOrderTableData: Subscription;
  inboundOrderTableData: any;
  lstInsurance: PayorDetailDTO[] = [];
  lstSelectedInsurance: string[] = [];
  lstOrderStatus: OrderStatusListDto[] = [];
  lstSelectedOrderStatus: string[] = [];
  lstChartNos: PatientChartIdDTO[] = [];
  lstSelectedChartNos: string[] = [];
  lstOrderNos: OrderListDto[] = [];
  lstSelectedOrderNos: string[] = [];
  lstPatients: PatientListDto[] = [];
  lstSelectedPatients: string[] = [];
  orderStatusDD$: Subscription;
  orderNoDD$: Subscription;
  insuranceDD$: Subscription;
  patientDD$: Subscription;
  chartIdDD$: Subscription;
  subscription$: Subscription[] = [];
  constructor(
    private mmOrderService: MmOrderService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private patientPersonalService: PatientPersonalService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.activeTabBin = MyQueueBinStatus.Resupply;
    this.initializeForms();
    this.getOrderFileterList();
    this.loadDropdowns();
  }

  initializeForms() {
    this.orderBinQueueFilterForm = this.fb.group({
      drpOrderNo: new FormControl(''),
      txtOrderNo: new FormControl(''),
      drpChartId: new FormControl(''),
      txtChartId: new FormControl(''),
      drpPatientName: new FormControl(''),
      txtPatientName: new FormControl(''),
      drpStatus: new FormControl(''),
      txtStatus: new FormControl(''),
      txtOrderFromDate: new FormControl(''),
      txtOrderToDate: new FormControl(''),
      txtSuppliesFromDate: new FormControl(''),
      txtSuppliesToDate: new FormControl(''),
      drpInsurance: new FormControl(''),
      txtInsurance: new FormControl(''),
    });
  }
  //To Load the filter dropdowns
  loadDropdowns() {
    this.loadPatientDropdown();
    this.loadChartNoDropdown();
    this.loadOrderNoDropdown();
    this.loadOrderStatusDropdown();
    this.loadInsuranceDropdown();
  }
  //To Load the Insurance Dropdown
  loadInsuranceDropdown() {
    if (this.insuranceDD$) {
      this.insuranceDD$.unsubscribe();
    }
    let insuranceSearchString =
      this.orderBinQueueFilterForm?.value?.txtInsurance ?? '';
    this.insuranceDD$ = this.patientPersonalService
      .getPayorListByPayerDetaislAndSPayer(
        insuranceSearchString,
        this.lstSelectedInsurance ?? []
      )
      .subscribe(
        (response) => {
          this.lstInsurance = response ?? [];
        },
        (err) => {
          this.lstInsurance = [];
        }
      );
  }
  //To Load the Order Status Dropdown
  loadOrderStatusDropdown() {
    if (this.orderStatusDD$) {
      this.orderStatusDD$.unsubscribe();
    }
    let params: InputOrderStatusListDto = {
      sSearch: this.orderBinQueueFilterForm?.value?.txtStatus ?? '',
      gOrderIds: this.lstSelectedOrderStatus ?? [],
    };
    console.log(this.orderBinQueueFilterForm?.value?.txtStatus);
    console.log(this.lstSelectedOrderStatus);
    this.orderStatusDD$ = this.mmOrderService
      .getOrderStatusForMyQueueByInput(params)
      .subscribe(
        (response) => {
          this.lstOrderStatus = response ?? [];
        },
        (err) => {
          this.lstOrderStatus = [];
        }
      );
  }

  //Load Order No Dropdown
  loadOrderNoDropdown() {
    if (this.orderNoDD$) {
      this.orderNoDD$.unsubscribe();
    }
    let params: InputOrderListDto = {
      iSearch:
        this.orderBinQueueFilterForm?.value?.txtOrderNo == '' ||
        this.orderBinQueueFilterForm?.value?.txtOrderNo == undefined
          ? null
          : Number(this.orderBinQueueFilterForm?.value?.txtOrderNo),
      orderIds: this.lstSelectedOrderNos ?? [],
    };
    console.log(this.orderBinQueueFilterForm?.value?.txtOrderNo);
    console.log(this.lstSelectedOrderNos);
    this.orderNoDD$ = this.mmOrderService
      .getOrderNumbersForMyQueueByInput(params)
      .subscribe(
        (response) => {
          this.lstOrderNos = response ?? [];
        },
        (err) => {
          this.lstOrderNos = [];
        }
      );
  }

  //Load Chart No Dropdown
  loadChartNoDropdown() {
    if (this.chartIdDD$) {
      this.chartIdDD$.unsubscribe();
    }
    let chartSearchString =
      this.orderBinQueueFilterForm?.value?.txtChartId ?? '';

    this.chartIdDD$ = this.patientPersonalService
      .createChartIdByChartIdPATAndSPatient(
        chartSearchString,
        this.lstSelectedChartNos ?? []
      )
      .subscribe(
        (response) => {
          this.lstChartNos = response ?? [];
        },
        (err) => {
          this.lstChartNos = [];
        }
      );
  }

  //Patient Dropdown
  loadPatientDropdown() {
    if (this.patientDD$) {
      this.patientDD$.unsubscribe();
    }

    let params: InputPatientListDto = {
      gPat: this.lstSelectedPatients ?? [],
      sSearch: this.orderBinQueueFilterForm?.value?.txtPatientName ?? '',
    };
    this.patientDD$ = this.patientPersonalService
      .getPatientListByInput(params)
      .subscribe(
        (response) => {
          this.lstPatients = response ?? [];
          console.log(this.lstPatients);
        },
        (err) => {
          this.lstPatients = [];
        }
      );
  }

  getArrowClass(data: any): any {
    return { [data.arrowclass]: this.activeTabBin === data.status };
  }
  changePage(pageNo: number) {
    if (pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages) {
      this.currentPage = pageNo;
      this.getOrderFileterList();
    }
  }
  binActiveTab(data: any) {
    this.activeTabBin = data?.status;
    this.orderBinQueueFilterForm.reset();
    this.getOrderFileterList();
  }
  onOrderDateChange(): void {
    if (
      this.orderBinQueueFilterForm.value.txtOrderFromDate &&
      this.orderBinQueueFilterForm.value.txtOrderToDate
    ) {
      this.getOrderFileterList();
    }
  }
  onSuppliesDateChange(): void {
    if (
      this.orderBinQueueFilterForm.value.txtSuppliesFromDate &&
      this.orderBinQueueFilterForm.value.txtSuppliesToDate
    ) {
      this.getOrderFileterList();
    }
  }
  //GET Order Table Data
  getOrderFileterList() {
    this.loadInboundOrderData = true;
    if (this.getInboundOrderTableData) {
      this.getInboundOrderTableData.unsubscribe();
    }
    let patientids = this.lstSelectedPatients?.concat(this.lstSelectedChartNos ?? []);
    let selectedTabShortCode = this.menus.find(
      (a) => a.status == this.activeTabBin
    )?.wonaceOrderTab;
    console.log(this.orderBinQueueFilterForm?.get('txtOrderFromDate').value);
    const orderStartDate =
      this.orderBinQueueFilterForm?.get('txtOrderFromDate').value ?? null;
    const orderEndDate =
      this.orderBinQueueFilterForm.get('txtOrderToDate').value;
    let orderFromDate = this.datepipe.transform(orderStartDate, 'yyyy-MM-dd');
    let orderToDate = this.datepipe.transform(orderEndDate, 'yyyy-MM-dd');

    const startDate = this.orderBinQueueFilterForm.get(
      'txtSuppliesFromDate'
    ).value;
    const endDate = this.orderBinQueueFilterForm.get('txtSuppliesToDate').value;
    let suppliesFromDate = this.datepipe.transform(startDate, 'yyyy-MM-dd');
    let suppliesToDate = this.datepipe.transform(endDate, 'yyyy-MM-dd');
    const orderInputParams: requestDTO = {
      shortCode: selectedTabShortCode,
      faxIds: [],
      patientIds: patientids,
      locationIds: [],
      orderIds: this.lstSelectedOrderNos ?? [],
      priPolicyIds: this.lstSelectedInsurance ?? [],
      secPolicyIds: [],
      terPolicyIds: [],
      dFromSuppliesDate: suppliesFromDate ?? null,
      dToSuppliesDate: suppliesToDate ?? null,
      dFromOrderDate: orderFromDate ?? null,
      dToOrderDate: orderToDate ?? null,
      csrIds: [],
      orderStatus: this.lstSelectedOrderStatus ?? [],
      resupply: '',
      agingdays: '',
      filter: '',
      pageNo: this.currentPage,
      pageOffset: this.pageOffset,
    };

    this.getInboundOrderTableData = this.mmOrderService
      .getOrderTabReportByDto(orderInputParams)
      .subscribe(
        (response) => {
          this.menus = [
            {
              status: MyQueueBinStatus.Resupply,
              color: '#faa307',
              count: response?.resupplyCount,
              arrowclass: 'resupply-down',
              wonaceOrderTab: this.wonaceOrderTab.Resupply,
            },
            {
              status: MyQueueBinStatus.SaleOrder,
              color: '#b56576',
              count: response?.orderCount,
              arrowclass: 'saleorder-down',
              wonaceOrderTab: this.wonaceOrderTab.Order,
            },
            {
              status: MyQueueBinStatus.Shipping,
              color: '#c77dff',
              count: response?.shippingCount,
              arrowclass: 'shipping-down',
              wonaceOrderTab: this.wonaceOrderTab.Shipping,
            },
            {
              status: MyQueueBinStatus.Billing,
              color: '#57cc99',
              count: response?.billingCount,
              arrowclass: 'billing-down',
              wonaceOrderTab: this.wonaceOrderTab.Billing,
            },
          ];

          switch (this.activeTabBin) {
            case MyQueueBinStatus.Resupply:
              this.inboundOrderTableData = response?.resupply ?? [];
              this.inboundOrderTableData = this.inboundOrderTableData.map(
                (inbound) => {
                  return {
                    ...inbound,
                    suppliesInactiveDate:
                      this.commonService.getFormattedDateTimeZone(
                        inbound.suppliesInactiveDate
                      ),
                    modifiedDate: this.commonService.getFormattedDateTimeZone(
                      inbound.modifiedDate
                    ),
                    createdDate: this.commonService.getFormattedDateTimeZone(
                      inbound.createdDate
                    ),

                    orderDate: this.commonService.getFormattedDate(
                      inbound.orderDate
                    ),
                    suppliesDate: this.commonService.getFormattedDate(
                      inbound.suppliesDate
                    ),
                    lastMaskDate: this.commonService.getFormattedDateTimeZone(
                      inbound.lastMaskDate
                    ),
                  };
                }
              );
              this.totalPages = Math.ceil(
                response.resupplyCount / this.pageOffset
              );
              break;
            case MyQueueBinStatus.SaleOrder:
              this.inboundOrderTableData = response?.order ?? [];
              this.inboundOrderTableData = this.inboundOrderTableData.map(
                (inbound) => {
                  return {
                    ...inbound,
                    suppliesDate: this.commonService.getFormattedDateZone(
                      inbound.suppliesDate
                    ),
                    orderDate: this.commonService.getFormattedDateZone(
                      inbound.orderDate
                    ),
                    lastMaskProcessDate:
                      this.commonService.getFormattedDateTimeZone(
                        inbound.lastMaskProcessDate
                      ),
                    creationTime: this.commonService.getFormattedDateTimeZone(
                      inbound.creationTime
                    ),
                    modifiedOn: this.commonService.getFormattedDateTimeZone(
                      inbound.modifiedOn
                    ),
                  };
                }
              );
              this.totalPages = Math.ceil(
                response.orderCount / this.pageOffset
              );
              break;
            case MyQueueBinStatus.Shipping:
              this.inboundOrderTableData = response?.shipping ?? [];
              this.inboundOrderTableData = this.inboundOrderTableData.map(
                (inbound) => {
                  return {
                    ...inbound,
                    suppliesdate: this.commonService.getFormattedDateZone(
                      inbound.suppliesdate
                    ),
                    createdDate: this.commonService.getFormattedDateTimeZone(
                      inbound.createdDate
                    ),
                  };
                }
              );
              this.totalPages = Math.ceil(
                response.shippingCount / this.pageOffset
              );
              break;
            case MyQueueBinStatus.Billing:
              this.inboundOrderTableData = response?.billing ?? [];
              this.inboundOrderTableData = this.inboundOrderTableData.map(
                (inbound) => {
                  return {
                    ...inbound,
                    suppliesDate: this.commonService.getFormattedDateZone(
                      inbound.suppliesDate
                    ),
                    createdDate: this.commonService.getFormattedDateTimeZone(
                      inbound.createdDate
                    ),
                    backOrderSince: this.commonService.getFormattedDateZone(
                      inbound.backOrderSince
                    ),
                  };
                }
              );
              this.totalPages = Math.ceil(
                response.billingCount / this.pageOffset
              );
              break;
          }


          this.loadInboundOrderData = false;
        },
        (err) => {
          this.loadInboundOrderData = false;
        }
      );
  }

  menus = [
    {
      status: MyQueueBinStatus.Resupply,
      color: '#faa307',
      count: 0,
      arrowclass: 'resupply-down',
      wonaceOrderTab: this.wonaceOrderTab.Resupply,
    },
    {
      status: MyQueueBinStatus.SaleOrder,
      color: '#b56576',
      count: 0,
      arrowclass: 'saleorder-down',
      wonaceOrderTab: this.wonaceOrderTab.Order,
    },
    {
      status: MyQueueBinStatus.Shipping,
      color: '#c77dff',
      count: 0,
      arrowclass: 'shipping-down',
      wonaceOrderTab: this.wonaceOrderTab.Shipping,
    },
    {
      status: MyQueueBinStatus.Billing,
      color: '#57cc99',
      count: 0,
      arrowclass: 'billing-down',
      wonaceOrderTab: this.wonaceOrderTab.Billing,
    },
  ];
}
