import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { TableService } from 'projects/shared/src/app/table.service';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

import { UpdatePermissionsDto } from '../../admin-proxy/platform-app-management/volo/abp/permission-management/models';
import { PermissionsService } from '../../admin-proxy/volo/abp/permission-management';


@Component({
  selector: 'app-executives-roles-permission',
  templateUrl: './executives-roles-permission.component.html',
  styleUrls: ['./executives-roles-permission.component.scss']
})
export class ExecutivesRolesPermissionComponent implements OnInit {
  executiveRolespermissionForm: FormGroup;
  providerName: string = "";
  providerKey: string = "";
  tenantId: string = "";
  roleName: string = "";
  tenantUserHide: boolean = true;
  adminHide: boolean = true;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  constructor(
    private formBuilder: FormBuilder, private permission: PermissionsService,
    public dialog: MatDialog,
    private tableService: TableService,     
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { roleName?: string }
  ) { }

  ngOnInit(): void {

    this.executiveRolespermissionForm = this.formBuilder.group({

      chkRoleCreate: new FormControl(""),
      chkRoleEdit: new FormControl(""),
      chkRoleView: new FormControl(""),
      chkRoleDelete: new FormControl(""),
      chkRoleManagePermissions: new FormControl(""),

      chkUsersCreate: new FormControl(""),
      chkUsersEdit: new FormControl(""),
      chkUsersView: new FormControl(""),
      chkUsersDelete: new FormControl(""),
      chkUsersManagePermissions: new FormControl(""),

      chkTenantCreate: new FormControl(""),
      chkTenantEdit: new FormControl(""),
      chkTenantView: new FormControl(""),
      chkTenantDelete: new FormControl(""),
      chkTenantManageFeatures: new FormControl(""),

      chkOrganizationCreate: new FormControl(""),
      chkOrganizationEdit: new FormControl(""),
      chkOrganizationView: new FormControl(""),
      chkOrganizationDelete: new FormControl(""),

    });
    this.loadPermissions();

  }

  selectAll(event: MatCheckboxChange) {
    this.executiveRolespermissionForm.patchValue({
      chkRoleCreate: event.checked == true ? true : false,
      chkRoleEdit: event.checked == true ? true : false,
      chkRoleView: event.checked == true ? true : false,
      chkRoleDelete: event.checked == true ? true : false,
      chkRoleManagePermissions: event.checked == true ? true : false,

      chkUsersCreate: event.checked == true ? true : false,
      chkUsersEdit: event.checked == true ? true : false,
      chkUsersView: event.checked == true ? true : false,
      chkUsersDelete: event.checked == true ? true : false,
      chkUsersManagePermissions: event.checked == true ? true : false,

      chkTenantCreate: event.checked == true ? true : false,
      chkTenantEdit: event.checked == true ? true : false,
      chkTenantView: event.checked == true ? true : false,
      chkTenantDelete: event.checked == true ? true : false,
      chkTenantManageFeatures: event.checked == true ? true : false,

      chkOrganizationCreate: event.checked == true ? true : false,
      chkOrganizationEdit: event.checked == true ? true : false,
      chkOrganizationView: event.checked == true ? true : false,
      chkOrganizationDelete: event.checked == true ? true : false,
    })
  }
  selectAllRoles(event: MatCheckboxChange) {
    this.executiveRolespermissionForm.patchValue({
      chkRoleCreate: event.checked == true ? true : false,
      chkRoleEdit: event.checked == true ? true : false,
      chkRoleView: event.checked == true ? true : false,
      chkRoleDelete: event.checked == true ? true : false,
      chkRoleManagePermissions: event.checked == true ? true : false,
    })
  }
  selectAllUser(event: MatCheckboxChange) {
    this.executiveRolespermissionForm.patchValue({
      chkUsersCreate: event.checked == true ? true : false,
      chkUsersEdit: event.checked == true ? true : false,
      chkUsersView: event.checked == true ? true : false,
      chkUsersDelete: event.checked == true ? true : false,
      chkUsersManagePermissions: event.checked == true ? true : false,
    })
  }
  selectAllBranch(event: MatCheckboxChange) {
    this.executiveRolespermissionForm.patchValue({
      chkOrganizationCreate: event.checked == true ? true : false,
      chkOrganizationEdit: event.checked == true ? true : false,
      chkOrganizationView: event.checked == true ? true : false,
      chkOrganizationDelete: event.checked == true ? true : false,
    })
  }
  selectAllClient(event: MatCheckboxChange) {
    this.executiveRolespermissionForm.patchValue({
      chkTenantCreate: event.checked == true ? true : false,
      chkTenantEdit: event.checked == true ? true : false,
      chkTenantView: event.checked == true ? true : false,
      chkTenantDelete: event.checked == true ? true : false,
      chkTenantManageFeatures: event.checked == true ? true : false,
    })
  }



  permissionDetails: UpdatePermissionsDto;

  savePermission() {
    this.providerName = "R" ?? "";
    this.providerKey = this.data.roleName ?? "";
    // tenantId:this.data.clientId
    //!role
    if (this.data.roleName != null) {
      if (this.executiveRolespermissionForm.value.chkRoleCreate == true || this.executiveRolespermissionForm.value.chkRoleEdit == true
        || this.executiveRolespermissionForm.value.chkRoleView == true || this.executiveRolespermissionForm.value.chkRoleDelete == true
        || this.executiveRolespermissionForm.value.chkRoleManagePermissions == true) {
        this.executiveRolespermissionForm.value.chkRoleView = true;
      }
      else {
        this.executiveRolespermissionForm.value.chkRoleView = false;
      }
      //!users
      if (this.executiveRolespermissionForm.value.chkUsersCreate == true || this.executiveRolespermissionForm.value.chkUsersEdit == true
        || this.executiveRolespermissionForm.value.chkUsersView == true || this.executiveRolespermissionForm.value.chkUsersDelete == true
        || this.executiveRolespermissionForm.value.chkUsersManagePermissions == true) {
        this.executiveRolespermissionForm.value.chkUsersView = true;
        this.executiveRolespermissionForm.value.chkRoleView = true;
        this.executiveRolespermissionForm.value.chkOrganizationView = true;
      }
      else {
        this.executiveRolespermissionForm.value.chkUsersView = false;
      }
      //client
      if (this.executiveRolespermissionForm.value.chkTenantCreate == true || this.executiveRolespermissionForm.value.chkTenantEdit == true
        || this.executiveRolespermissionForm.value.chkTenantView == true || this.executiveRolespermissionForm.value.chkTenantCreate == true
        || this.executiveRolespermissionForm.value.chkTenantManageFeatures == true) {
        this.executiveRolespermissionForm.value.chkTenantView = true;
      }
      else {
        this.executiveRolespermissionForm.value.chkTenantView = false;
      }
      //!branch
      if (this.executiveRolespermissionForm.value.chkOrganizationCreate == true || this.executiveRolespermissionForm.value.chkOrganizationEdit == true
        || this.executiveRolespermissionForm.value.chkOrganizationView == true || this.executiveRolespermissionForm.value.chkOrganizationDelete == true) {
        this.executiveRolespermissionForm.value.chkOrganizationView = true;
      }
      else {
        this.executiveRolespermissionForm.value.chkOrganizationView = false;
      }

      this.permissionDetails = {
        permissions: [
          //Roles

          {
            name: "AbpIdentity.Roles.Create",
            isGranted: this.executiveRolespermissionForm.value.chkRoleCreate == "" ? false : this.executiveRolespermissionForm.value.chkRoleCreate
          },
          {
            name: "AbpIdentity.Roles.Update",
            isGranted: this.executiveRolespermissionForm.value.chkRoleEdit == "" ? false : this.executiveRolespermissionForm.value.chkRoleEdit
          },
          {
            name: "AbpIdentity.Roles",
            isGranted: this.executiveRolespermissionForm.value.chkRoleView == "" ? false : this.executiveRolespermissionForm.value.chkRoleView
          },
          {
            name: "AbpIdentity.Roles.Delete",
            isGranted: this.executiveRolespermissionForm.value.chkRoleDelete == "" ? false : this.executiveRolespermissionForm.value.chkRoleDelete
          },
          {
            name: "AbpIdentity.Roles.ManagePermissions",
            isGranted: (this.executiveRolespermissionForm.value.chkRoleManagePermissions == "" || false) ? true : true,
          },

          //Users
          {
            name: "AbpIdentity.Users.Create",
            isGranted: this.executiveRolespermissionForm.value.chkUsersCreate == "" ? false : this.executiveRolespermissionForm.value.chkUsersCreate
          },
          {
            name: "AbpIdentity.Users.Update",
            isGranted: this.executiveRolespermissionForm.value.chkUsersEdit == "" ? false : this.executiveRolespermissionForm.value.chkUsersEdit
          },
          {
            name: "AbpIdentity.Users",
            isGranted: this.executiveRolespermissionForm.value.chkUsersView == "" ? false : this.executiveRolespermissionForm.value.chkUsersView
          },
          {
            name: "AbpIdentity.Users.Delete",
            isGranted: this.executiveRolespermissionForm.value.chkUsersDelete == "" ? false : this.executiveRolespermissionForm.value.chkUsersDelete
          },
          {
            name: "AbpIdentity.Users.ManagePermissions",
            isGranted: this.executiveRolespermissionForm.value.chkUsersManagePermissions == "" ? false : this.executiveRolespermissionForm.value.chkUsersManagePermissions
          },

          //Organizations
          {
            name: "PlatformManagement.Organizations.Create",
            isGranted: this.executiveRolespermissionForm.value.chkOrganizationCreate == "" ? false : this.executiveRolespermissionForm.value.chkOrganizationCreate
          },
          {
            name: "PlatformManagement.Organizations.Edit",
            isGranted: this.executiveRolespermissionForm.value.chkOrganizationEdit == "" ? false : this.executiveRolespermissionForm.value.chkOrganizationEdit
          },
          {
            name: "PlatformManagement.Organizations.Delete",
            isGranted: this.executiveRolespermissionForm.value.chkOrganizationDelete == "" ? false : this.executiveRolespermissionForm.value.chkOrganizationDelete
          },

          {
            name: "PlatformManagement.Organizations",
            isGranted: this.executiveRolespermissionForm.value.chkOrganizationView == "" ? false : this.executiveRolespermissionForm.value.chkOrganizationView
          },


          //Official
          {
            name: "AbpTenantManagement.Tenants.Create",
            isGranted: this.executiveRolespermissionForm.value.chkTenantCreate == "" ? false : this.executiveRolespermissionForm.value.chkTenantCreate
          },
          {
            name: "AbpTenantManagement.Tenants.Update",
            isGranted: this.executiveRolespermissionForm.value.chkTenantEdit == "" ? false : this.executiveRolespermissionForm.value.chkTenantEdit
          },
          {
            name: "AbpTenantManagement.Tenants.Delete",
            isGranted: this.executiveRolespermissionForm.value.chkTenantDelete == "" ? false : this.executiveRolespermissionForm.value.chkTenantDelete
          },
          {
            name: "AbpTenantManagement.Tenants",
            isGranted: this.executiveRolespermissionForm.value.chkTenantView == "" ? false : this.executiveRolespermissionForm.value.chkTenantView
          },
          {
            name: "AbpTenantManagement.Tenants.ManageFeatures",
            isGranted: this.executiveRolespermissionForm.value.chkTenantManageFeatures == "" ? false : this.executiveRolespermissionForm.value.chkTenantManageFeatures
          },
        ]
      }
    }
    this.permission.update(this.providerName, this.providerKey, this.permissionDetails).subscribe(response => {
      this.toastr.success('Saved Successfully','Success')
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      // this.dialogRef.close();
      this.dialog.closeAll()
    }, err => {
    });

  }

  loadPermissions() {
    this.providerName = "R";
    this.providerKey = this.data?.roleName ?? ""
    this.permission.get(this.providerName ?? "", this.providerKey ?? "").subscribe(response => {



      response && response?.groups?.forEach(element => {
        element?.permissions?.forEach(elements => {
          {

            //! roles Management
            if (elements.name === "AbpIdentity.Roles.Create") {
              this.executiveRolespermissionForm.patchValue({
                chkRoleCreate: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Roles.Update") {
              this.executiveRolespermissionForm.patchValue({
                chkRoleEdit: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Roles.Delete") {
              this.executiveRolespermissionForm.patchValue({
                chkRoleDelete: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Roles.ManagePermissions") {
              this.executiveRolespermissionForm.patchValue({
                chkRoleManagePermissions: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Roles") {
              this.executiveRolespermissionForm.patchValue({
                chkRoleView: elements?.isGranted
              });
            }

            //!user
            if (elements.name === "AbpIdentity.Users.Create") {
              this.executiveRolespermissionForm.patchValue({
                chkUsersCreate: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Users.Update") {
              this.executiveRolespermissionForm.patchValue({
                chkUsersEdit: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Users.Delete") {
              this.executiveRolespermissionForm.patchValue({
                chkUsersDelete: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Users.ManagePermissions") {
              this.executiveRolespermissionForm.patchValue({
                chkUsersManagePermissions: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Users") {
              this.executiveRolespermissionForm.patchValue({
                chkUsersView: elements?.isGranted
              });
            }

            //!client
            if (elements.name === "AbpTenantManagement.Tenants.Create") {
              this.executiveRolespermissionForm.patchValue({
                chkTenantCreate: elements?.isGranted
              });
            }
            if (elements.name === "AbpTenantManagement.Tenants.Update") {
              this.executiveRolespermissionForm.patchValue({
                chkTenantEdit: elements?.isGranted
              });
            }
            if (elements.name === "AbpTenantManagement.Tenants.Delete") {
              this.executiveRolespermissionForm.patchValue({
                chkTenantDelete: elements?.isGranted
              });
            }
            if (elements.name === "AbpTenantManagement.Tenants.ManageFeatures") {
              this.executiveRolespermissionForm.patchValue({
                chkTenantManageFeatures: elements?.isGranted
              });

            }
            if (elements.name === "AbpTenantManagement.Tenants") {
              this.executiveRolespermissionForm.patchValue({
                chkTenantView: elements?.isGranted
              });
            }

            //!Branch
            if (elements.name === "PlatformManagement.Organizations.Create") {
              this.executiveRolespermissionForm.patchValue({
                chkOrganizationCreate: elements?.isGranted
              });
            }
            if (elements.name === "PlatformManagement.Organizations.Edit") {
              this.executiveRolespermissionForm.patchValue({
                chkOrganizationEdit: elements?.isGranted
              });
            }
            if (elements.name === "PlatformManagement.Organizations.Delete") {
              this.executiveRolespermissionForm.patchValue({
                chkOrganizationDelete: elements?.isGranted
              });
            }
            if (elements.name === "PlatformManagement.Organizations") {
              this.executiveRolespermissionForm.patchValue({
                chkOrganizationView: elements?.isGranted
              });
            }


          }
        });
      })
    }, err => {
    });
  }

}
