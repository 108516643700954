import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Observable, of, Subscription } from 'rxjs';
import { filter, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AddNoteTabComponent } from '../add-note-tab/add-note-tab.component';
import { NoteType, noteTypeOptions, PatientNotesSearchDTO } from '../patient-proxy/patient-optimization/dto/models';
import { PatientNotesService } from '../patient-proxy/patient-optimization/patient-notes.service';
import moment, { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { DatePipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';

@Component({
  selector: 'app-common-notes-search-tab',
  templateUrl: './common-notes-search.component.html',
  styleUrls: ['./common-notes-search.component.scss'],
  providers: [DatePipe]
})
export class CommonNotesSearchComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  strPageType: string = 'commonNotesList';
  notesTableData: any[] = [];
  noteSearchForm: FormGroup;
  selectedRange: { startDate: Moment, endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  @Input() patientId: string = '';
  calendarLocale: LocaleConfig;
  dateRangStatus: boolean = false;
  fromDate: string;
  toDate: any;
  calendarPlaceholder: string = '';
  arrDisplayedColumns: string[] = [
    "defaultNotesId",
    "defaultPatientId",
    "patientName",
    "noteType",
    "branch",
    "description",
    "subject",
    "reasonData",
    "statusData",
    "userName",
    "dateCreated",
    //  "Options"
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  subscription$: Subscription[] = [];
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchValue : string[] =[];
  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  tenantId: string = "";
  branchForm: FormGroup;
  organizationUnitName: string;

  constructor(
    private titleService: Title,
    public dialog: MatDialog,
    private patientNotesService: PatientNotesService,
    public formBuilder: FormBuilder,
    private dateValidator: DateValidator,
    private datepipe: DatePipe,
    private branchService : OrganizationUnitService
  ) {
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1
    };

    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
    };
   }
   public data: { patientId: string } = { patientId: defaultGuid }
  ngOnInit(): void {
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.patientId = this.patientId ?? defaultGuid;
    if (this.data?.patientId !== defaultGuid) {
      this.patientId = this.data?.patientId;
    }
    this.noteSearchForm = this.formBuilder.group({
      txtNoteId: new FormControl(''),
      txtNoteType: new FormControl(''),
      txtDescription: new FormControl(''),
      txtSubject: new FormControl(''),
      txtReason: new FormControl(''),
      txtCreatedBy: new FormControl(''),
      txtStatus: new FormControl(''),
      txtCreatedDate: new FormControl('', this.dateValidator?.dateVaidator),
      txtPatientId: new FormControl(''),
      txtPatientName: new FormControl(''),
    });
    this.titleService?.setTitle('Qsecure | Notes List');
    this.setTableData([]);
    this.getSearchObs();

    this.branchForm = this.formBuilder.group({
      drpBranch : new FormControl(''),
      txtBranch : new FormControl(''),
    });

    this.getBranchList();

  }



  datesUpdateds(e){
    if(e.startDate !=null || e.endDate !=null){
      this.dateRangStatus = true
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate,"MM/dd/yyyy")
      this.toDate = this.datepipe.transform(endDate,"MM/dd/yyyy")
      this.getSearchObs()

    }else{
      this.dateRangStatus = false
      this.getSearchObs()

    }
  }

  gettoolTip(data){
  return data.description
  }
  getSearchObs() {
    const claimSearchSub = this.noteSearchForm?.valueChanges?.pipe(
      startWith({
        gPatientId: '',
        sNoteId: '',
        sPatientId: '',
        sNoteType: '',
        sDescription: '',
        sSeverity: '',
        sSubject: '',
        sReason: '',
        sCreatedBy: '',
        dtCreatedDate: '',
        dtActualDate: '',
        dtDateNeeded: '',
        sStatus: '',
        dtDateCompleted: '',
      }),
      filter((e) => e && this.noteSearchForm?.valid),
      map((response: any) => {
        //this.setPatientTable([]);
        //this.PATIENTDATA = [];
        this.isLoading = true;
        // this.isShowProgress = true;
        const sValue: PatientNotesSearchDTO = {
          defaultNotesId: response?.txtNoteId?.trim()?.toLowerCase(),
          noteType: response?.txtNoteType?.trim()?.toLowerCase(),
          noteReason: response?.txtReason?.trim()?.toLowerCase(),
          description: response?.txtDescription?.trim()?.toLowerCase(),
          subject: response?.txtSubject?.trim()?.toLowerCase(),
          createdBy: response?.txtCreatedBy?.trim()?.toLowerCase(),
          status: response?.txtStatus?.trim()?.toLowerCase(),
          dateCreated: response?.txtCreatedDate?.trim()?.toLowerCase(),
          patientId: response?.txtPatientId?.trim()?.toLowerCase(),
          patientName: response?.txtPatientName?.trim()?.toLowerCase(),

          // start_Date: this.fromDate.trim()?.toLowerCase(),
          // end_Date: this.toDate.trim()?.toLowerCase(),
        };
        return sValue;
      }),
      switchMap((sValue) =>
      // gPatientId, gOrderId, gClaimId, sNoteId, sPatientName, sDefaultPatientId,
      // sNoteType, sReason, sSubject, sDescription, sCreatedBy, dtCreatedDate, sStatus, start_Date, end_Date
        this.patientNotesService.notesSearchByGPatientIdAndGOrderIdAndGClaimIdAndSNoteIdAndSPatientNameAndSDefaultPatientIdAndSNoteTypeAndSReasonAndSSubjectAndSDescriptionAndSCreatedByAndDtCreatedDateAndSStatusAndStart_DateAndEnd_Date(
          this.patientId,
          defaultGuid,
          defaultGuid,
          sValue?.defaultNotesId,
          sValue?.patientName,
          sValue?.patientId,
          sValue?.noteType,
          sValue?.noteReason,
          sValue?.subject,
          sValue?.description,
          sValue?.createdBy,
          sValue?.dateCreated,
          sValue?.status,
          this.fromDate,
          this.toDate,

        )
      )
    )
    .subscribe(response => {
      response = response?.map((x, i = 1) => {
        const y = {
          customNoteType: noteTypeOptions?.filter(
            (a) => a?.key === x?.noteType?.toString()
          )?.[0]?.value,
          ...x,
        };
        return y;
      });
      this.isLoading = false;
      this.setTableData(response ?? []);
    }, (err) => {
      this.setTableData([]);
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    });
    this.subscription$?.push(claimSearchSub);
  }



  setTableData(response: PatientNotesSearchDTO[] = []) {
    this.notesTableData = response;
    //!  Listed the Claim processed records in claim proces table
    this.dataSource = new MatTableDataSource(this.notesTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.sort.disableClear = true;
    this.dataSource.paginator = this.paginator;
  }

  viewNotesById(value: PatientNotesSearchDTO) {
    const dialogRef = this.dialog.open(AddNoteTabComponent, {
      disableClose: true,
      data: {
        patientId: value?.patientId ?? defaultGuid,
        notesId: value?.notesId ?? defaultGuid,
        clamId: value?.claimId ?? defaultGuid,
        orderId: value?.orderId ?? defaultGuid,
      },
    });
    const closeDialog$ = dialogRef.afterClosed().subscribe(
      (result) => {
        this.getSearchObs();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog$);
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  allBranchSelect(){
    if(this.selectAllBranch.selected){
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    }
    else{
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getBranchList(){
    const getBranchList = this.branchService.getBranchList(this.tenantId).subscribe(response => {
      response?.forEach(element => {
        this.drpBranch.push({
          id: element.id,
          organizationUnitName: element.organizationUnitName,
        })
      });

      this.brnachList = this.branchForm
        ?.get('txtBranch')
        ?.valueChanges?.pipe(
          startWith(''),
          map((value) => 
            this.drpBranch?.filter((option) =>
              option?.organizationUnitName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );


    }, err =>{
      const data : HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getBranchList);
  }
}

export interface CustomPatientSearchDTO {
  gPatientId: string,
  sNoteId: string,
  sPatientName: string,
  sDefaultPatientId: string,
  sNoteType: string,
  sDescription: string,
  //sSeverity: string,
  sSubject: string,
  sReason: string,
  sCreatedBy: string,
  dtCreatedDate: string,
  dtActualDate: string,
  dtDateNeeded: string,
  sStatus: string,
  dtDateCompleted: string,
  start_Date: string,
  end_Date: string
}

export interface Branch{
  id?: string;
  organizationUnitName?: string;
}
