import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Observable, Subject, Subscription } from 'rxjs';
import { AddNoteTabComponent } from '../../add-note-tab/add-note-tab.component';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { NewVerificationComponent } from '../../newVerification/newVerification.component';
import { DocumentCheckListComponent } from 'projects/admin/src/app/document-check-list/document-check-list.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientAddPolicyTabComponent } from '../../patient-add-policy-tab/patient-add-policy-tab.component';
import { OndemandOnlineVerificationComponent } from '../../ondemand-online-verification/ondemand-online-verification.component';
import { PatientCommonDocumentMedicareUploadTabComponent } from 'projects/shared/src/app/components/patient-common-document-medicare-upload-tab/patient-common-document-medicare-upload-tab.component';
import { TextConversationComponent } from '../../text-conversation/text-conversation.component';
import { DatePipe } from '@angular/common';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';

@Component({
  selector: 'app-patient-list-table-v2',
  templateUrl: './patient-list-table-v2.component.html',
  styleUrls: ['./patient-list-table-v2.component.scss'],
})
export class PatientListTableV2Component implements OnInit {
  @HostBinding('style.font') font =
    '400 13px/20px Roboto, "Helvetica Neue", sans-serif';
  @HostBinding('style.letter-spacing') letterSpacing = 'normal';
  @Input() tableData: any;
  @Output() isLoadTbl? = new EventEmitter<void>();
  @Output() nevigatePage? = new EventEmitter<number>();
  @Output() getVisibleColumns? =   new EventEmitter<number[]>();
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Input() visibleColumns: number[] ;
  patientListDataArray: any;
  isLoading: boolean;
  strPdfString:string = '';
  dtPatientListOptions: any = {
    responsive: true,
    // stateSave: true,
    // searching: false,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    fixedHeader: true, // Fix the header
    fixedColumns: {
      leftColumns: 3, // Number of columns to fix on the left
      rightColumns: 1, // Number of columns to fix on the right
    },
    columnDefs: [
      { targets: [0], orderable: false },
      { targets: [12,13,14], visible: false },
      { targets: [0, 1, 2], className: 'dt-fixed-column' },
      {
        targets: [5, 10, 11],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
      { targets: [1,3,4,10,11,14,16], width: '1%' },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Patient List',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      }
      // {
      //   text: 'Show Default Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns(':not(:lt(3),:last-child)').visible(false);
          
      //   },
      // },
      // {
      //   text: 'Show All Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns().visible(true);
      //   },
      // },
      // {
      //   text: '<',
      //   action: this.changePage(false),
      // },
      // {
      //   text: '>',
      //   action: this.changePage(true),
      // },
    ],
  };
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  @ViewChild('tblpatientList', { static: true }) tblpatientList: ElementRef;
  tblItemSelectedRowId: string;
  chartId: string;
  patientId: string;
  documentId: string;
  defaultGuid = defaultGuid;
  @ViewChild('picker') picker: MatDatepicker<Date>;
  subscription$: Subscription[] = [];
  policyId: any;
  verifyId: any;
  verifyStatus: any;
  tenantId: string;
  constructor(
    private dialog: MatDialog,private snackBar: MatSnackBar,private datepipe:DatePipe,
    private elRef:ElementRef,
    private inboundDocumentService: InboundDocumentService,
  ) {}

  ngOnInit() {
    this.patientListDataArray = this.tableData;
    // this.patientListDataArray.push(this.tableData);
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
    this.tenantId = localStorage.getItem('tenantId') ?? '';

    this.dtPatientListOptions.buttons[0].filename =
    'Patient List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }
  changePage(isNext:boolean){
    if(isNext)
      this.currentPage++;
    else 
      this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }

  fnGotoPage(){
    let pageValue = $("#txtGotoPage").val();
    let pageno = parseInt(pageValue.toString());
    if(pageno > 0 && this.currentPage !=  pageno && pageno <= this.totalPages)
    {
      this.nevigatePage.emit(pageno);
    }
  }


  fnColumnSelectionClick(){
    $(".buttons-columnVisibility").on('click', (event) => this.fnGetColumnVisiblity("ONE"));
  }

  fnGetColumnVisiblity(event){
    
    let visibleColumns = [];
    if(event == "ALL")
    {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => { 
        dtInstance.columns().visible(true);
      });
    }
    else 
    { 
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => { 
        if(event == "DEFAULT")
          dtInstance.columns(':not(:lt(3),:last-child)').visible(false);

        var index = 0;
        dtInstance.columns().every(function () {
          const that = this;
          if(this.visible())
            visibleColumns.push(index)
          index++; 
        });
      });
    } 

    this.getVisibleColumns.emit(visibleColumns);
  }

  addPageNavigationControls(){
    let buttonsElement =  $(".dt-buttons")[0];

    $("<button class='dt-button' id='btnDefaultClms' type='button'><span>Show Default Column</span></button>").appendTo(buttonsElement);
    $("<button class='dt-button' id='btnAllClms' type='button'><span>Show All Column</span></button>").appendTo(buttonsElement);
    
    $("<span> : <span>").appendTo(buttonsElement);
    if(this.currentPage == 1)
    {
      $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
    }
    else 
    {
      $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
     }

    $("<span> Page <span>").appendTo(buttonsElement);
    $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);
    $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
    $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);
    
    if(this.currentPage >= this.totalPages)
    {
      $("<button disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
      $("<button disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }
    else
    {
       $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
       $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $(".buttons-colvis").on('click', (event) => this.fnColumnSelectionClick());
    $('#btnDefaultClms').on('click', (event) => this.fnGetColumnVisiblity("DEFAULT"));
    $('#btnAllClms').on('click', (event) => this.fnGetColumnVisiblity("ALL"));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) => this.nevigatePage.emit(this.totalPages));
   }

 
  refreshDataTable(): void { 
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
     
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();
      // console.log($(".dt-buttons")[0])

      // $("<span>Page " + this.currentPage + " of " + this.totalPages + " <span>").appendTo($(".dt-buttons")[0]);
      // //console.log(document.getElementsByClassName("dt-buttons"));
      // Attach the event listener for filtering
      var visColumns =  this.visibleColumns;
      var index = 0;
      dtInstance.columns().every(function () {
        const that = this; 
        if(visColumns && visColumns.length > 0)
        {  
              if(visColumns.indexOf(index) < 0)
                this.visible(false);
        } 
        index++;
    
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });

    window.removeEventListener('resize', this.calculateScrollY);
  }


  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.dtPatientListOptions.scrollY = screenHeight * availableSpacePercentage;
  };



  tblItemSelectedRow(patientId: string, documentId: string, chartId: string) {
    this.tblItemSelectedRowId = patientId;
    this.patientId = patientId;
    this.documentId = documentId;
    this.chartId = chartId;
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: this.patientId,
        defaultFaxId: this.chartId,
        isCallNote: false,
        isPending: false,
        docId: this.documentId,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: defaultGuid,
        addnotes: true,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: this.patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  openTextConversation(){
    const config: MatDialogConfig = {
      disableClose: true,
      width: '60%',
      height:'70%',
      data: {
        isPatientMdl: true,
      }
    };
    const dialogRef = this.dialog.open(TextConversationComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  openNewVerification(patientId: string, isOnline: boolean) {
    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        patientId: patientId,
        isOnline: isOnline,
      },
    };
    const dialogRef = this.dialog.open(NewVerificationComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  checkListModel(data) {
    const dialogRef = this.dialog.open(DocumentCheckListComponent, {
      // height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId

        documentId: data.documentId,
        faxId: data.faxId || 'N/A',
      },
    });

    // Subscribe to the closed event
    dialogRef.afterClosed().subscribe(() => {
      // Call the addNotes function directly
      dialogRef.componentInstance.addNotes();
    });
  }

  onDateSelected(event: MatDatepickerInputEvent<Date>) {
    // Additional logic when a date is selected
    // Close the datepicker programmatically after a date is selected
    this.picker.close();
  }

  //To View The Policy
  EditPolicy(policy: any) {
    if (policy.priPolicyId === defaultGuid) {
      if (policy.secPolicyId === defaultGuid) {
        this.policyId = policy.secPolicyId;
      } else {
        this.policyId = policy.terPolicyId;
      }
    } else {
      this.policyId = policy.priPolicyId;
    }
    if (policy.priVerificationId === defaultGuid) {
      if (policy.secVerificationId === defaultGuid) {
        this.verifyId = policy.secVerificationId;
      } else {
        this.verifyId = policy.terVerificationId;
      }
    } else {
      this.verifyId = policy.priVerificationId;
    }
    if (policy.priVerificationStatus === null ||policy.priVerificationStatus === '' ) {
      if (policy.secVerificationStatus === null ||policy.secVerificationStatus === '') {
        this.verifyStatus= policy.secVerificationStatus;
      } else {
        this.verifyStatus = policy.terVerificationStatus;
      }
    } else {
      this.verifyStatus = policy.priVerificationStatus;
    }
    const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
      data: {
        patientId: policy.patientId,
        policyId: this.policyId??defaultGuid,
        documentId: policy.docId,
        wipId: policy.wipId ?? defaultGuid,
        // selectedNode: this.selectedNode,
        verificationId: this.verifyId,
        verificationStatus:this.verifyStatus,

      },
    });
    const aftClosed = dialogRef.afterClosed().subscribe(
      (result) => {
        // this.patientIdEmitters.next(policy.patientId);

        // policy.patientId !== defaultGuid && this.getTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(aftClosed);
  }


  ondemandOnlineVerification(policy:any){
    if (policy.priPolicyId === defaultGuid) {
      if (policy.secPolicyId === defaultGuid) {
        this.policyId = policy.secPolicyId;
      } else {
        this.policyId = policy.terPolicyId;
      }
    } else {
      this.policyId = policy.priPolicyId;
    }
    if (policy.priVerificationId === defaultGuid) {
      if (policy.secVerificationId === defaultGuid) {
        this.verifyId = policy.secVerificationId;
      } else {
        this.verifyId = policy.terVerificationId;
      }
    } else {
      this.verifyId = policy.priVerificationId;
    }
    if (policy.priVerificationStatus === null ||policy.priVerificationStatus === '' ) {
      if (policy.secVerificationStatus === null ||policy.secVerificationStatus === '') {
        this.verifyStatus= policy.secVerificationStatus;
      } else {
        this.verifyStatus = policy.terVerificationStatus;
      }
    } else {
      this.verifyStatus = policy.priVerificationStatus;
    }
    const dialogRef = this.dialog.open(OndemandOnlineVerificationComponent, {
      data: {
        patientId: policy.patientId,
        policyId: this.policyId??defaultGuid,
        documentId: policy.documentId,
        wipId: policy.wipId ?? defaultGuid,
        // selectedNode: this.selectedNode,
        verificationId: this.verifyId,
        verificationStatus:this.verifyStatus,

      },
    });

    const aftClosed = dialogRef.afterClosed().subscribe(
      (result) => {
        // this.patientIdEmitters.next(policy.patientId);
        //this.isLoadTbl.emit();
        // policy.patientId !== defaultGuid && this.getTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(aftClosed);
  }

 //To add inbound documents manually
 addDocumentType(patientId:string,wipId:string) {
  const dialogData = {
    constructorpatientId: patientId??defaultGuid, // Replace with your actual patientId value
    constructortenantId: this.tenantId??defaultGuid,       // Replace with your actual tendid value
    selectedWipId:wipId??defaultGuid,
    isPatientList:true,
    isPatientDisable:true,
  };
  const dialogRef = this.dialog.open(PatientCommonDocumentMedicareUploadTabComponent, {
    // minHeight: '33vh',
    minWidth: '75vw',
    data: dialogData,
  });

  dialogRef.afterClosed().subscribe(
    () => {
      // this.getInboundDocsList();
    },
    (err) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }
  );
}
 //#region Patient Document PDF Download
 patientDocPdfDownload(data:any) {
  this.inboundDocumentService.downloadInboundDocumentByBlobName(data?.blobname)
  .subscribe(
    (response) => {
        if (
          typeof response === 'string' &&
          response &&
          response !== null &&
          response.length !== 0
        ) {
          this.strPdfString = String(response);
          // Decode base64 string to binary data
          const byteCharacters = atob(this.strPdfString);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          const blob = new Blob([byteArray], { type: "application/pdf" });
          // const fileName = this.defaultFaxId;
          const fileName = data?.chartId + '-' + data?.patientName;
          // Create a link element
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;

          // Append the link to the body
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          // Remove the link from the DOM
          document.body.removeChild(link);
        }
      },
      (err: HttpErrorResponse) => {
        console.error('Error downloading the document', err);
      }
    );
  }
// #endregion Patient Document PDF Download

}

export interface Branch {
  id?: string;
  organizationUnitName?: string;
}
