import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { ViewPatientLedgerComponent } from '../../view-patient-ledger/view-patient-ledger.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { InvoiceReportService } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/invoice-report.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { map, startWith } from 'rxjs/operators';
import { clsInvoiceReportSearch, clsSearch } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/dto';

@Component({
  selector: 'app-invoicetab-bin-queue',
  templateUrl: './invoicetab-bin-queue.component.html',
  styleUrls: ['./invoicetab-bin-queue.component.scss']
})
export class InvoicetabBinQueueComponent implements OnInit {
  isLoading:boolean=false;
  defaultGuid = defaultGuid;
  @Input() inBoundTableData: any[];
  @Input() activeTabBin: any;
  @Output() patientEntryCount: EventEmitter<number> =
  new EventEmitter<number>();
  // @Output() nevigatePage? = new EventEmitter<number>();
  // @Output() getVisibleColumns? =   new EventEmitter<number[]>();
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  // @Input() visibleColumns: number[] ;
  @ViewChild('tableContainer', { read: ViewContainerRef })
  tableContainer: ViewContainerRef;
  myTable2: DataTables.Api;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement2: DataTableDirective;
  invoiceFilterForm: FormGroup;
  billingInvoiceTable: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    language: {
      search: '',
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    columnDefs: [{ targets: 1, orderable: false },
      { targets: [7,8,11,12,13,14,15], visible: false }
    ],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Invoice List',
        exportOptions: {
          columns: ':not(:first-child)', // Skip the first column
          // columns: function (idx, data, node) {
          //   return idx !== 0 && idx !== 1;
          // }
        },
      },
    ], initComplete: function (this: any) {
      const searchInput = document.querySelector(
        '.dataTables_wrapper .dataTables_filter'
      ) as HTMLElement;
      if (searchInput) {
        searchInput.style.float = 'left';
        searchInput.style.display = 'flex';
        searchInput.style.alignItems = 'center';
        searchInput.style.width = '50%';
        searchInput.style.textAlign = 'left';
        // searchInput.appendChild(document.getElementById('dropdown-container'));
      }
      // $('#example_wrapper .dt-buttons').append($('#dropdown-container'));
    },
  };
  drpPatientId : any[] = [];
  drpInvoiceId : any[] = [];
  drpStatusId : any[] = [];
  public filteredPatient: Observable<string[]> | undefined;
  public filteredInvoice: Observable<string[]> | undefined;
  public filteredStatus: Observable<string[]> | undefined;
  isdrpLoading: boolean;
  isdrpInvoiceLoading: boolean;
  loadPatientDropDownAPICall: Subscription;
  loadInvoiceDropDownAPICall: Subscription;
  loadStatusDropDownAPICall: Subscription;
  loadInvoiceTableAPICall: Subscription;
  $subscription: Subscription[] = [];
  selectPatientList: any;
  selectInvoiceList: any;
  selectStatusList: any;
  isInvoiceDropdownOpen = false;
  txtDOSFromDate: any;
  isPatinetDropdownOpen = false;
  DataList: any[];
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private renderer: Renderer2,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
    private invoiceReportService: InvoiceReportService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.initializeForms();
    this.loadPatientDropDown('');
    this.loadInvoiceDropDown('');
    this.loadStatusDropDown();
  }
  ngAfterViewInit(): void {
    if (this.datatableElement2 && this.datatableElement2.dtInstance) {
      this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
        this.addPageNavigationControls();
      });
    } else {
      setTimeout(() =>  this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
        this.addPageNavigationControls();
      }), 500);
    }
  }

  ngOnDestroy(): void {
    if (this.loadPatientDropDownAPICall) {
      this.loadPatientDropDownAPICall.unsubscribe();
    }
    if (this.loadInvoiceDropDownAPICall) {
      this.loadInvoiceDropDownAPICall.unsubscribe();
    }
    if (this.loadStatusDropDownAPICall) {
      this.loadStatusDropDownAPICall.unsubscribe();
    }
    if (this.loadInvoiceTableAPICall) {
      this.loadInvoiceTableAPICall.unsubscribe();
    }
    this.$subscription.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
    const dropdownContainer = document.getElementById('dropdown-container');
    if (dropdownContainer) {
      dropdownContainer.remove();
    }
  }
  // refreshDataTable(): void {
  //   this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
  //     this.addPageNavigationControls();
  //   });
  // }
  initializeForms() {
    this.invoiceFilterForm = this.fb.group({
      drpPatient: new FormControl(''),
      txtPatientFilter: new FormControl(''),
      drpInvoice: new FormControl(''),
      txtInvoiceFilter: new FormControl(''),
      drpStatus: new FormControl(''),
      txtStatusFilter: new FormControl(''),
      txtDOSFromDate: new FormControl(''),
      txtDOSEndDate: new FormControl(''),
    });
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  openViewNotesPopup(patientID) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: patientID,
        pageType: 'Patient Note',
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        this.toaster.error(data?.error?.error?.message);
      }
    );
  }
  viewPatientLedger(patientId: string) {
    const dialogRef = this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    // this.dialog.afterAllClosed.subscribe((resposne) => {});

    const closeDialog = dialogRef.afterClosed().subscribe(
      (isSaved: any) => {
        if (isSaved) {
          // this.reloadClaimTable.emit();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }
   //#region DoubleClick Copy Text
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  //#endRegio DoubleClick Copy Text

  getTotalInboundData(event: Event, inboundData: any) {
    const target = event.target as HTMLElement;
    const tr = target.closest('tr');
    const icon = target as HTMLElement;
    this.getDataTableData2(tr, inboundData, icon);
  }

  //#region History Tables

  //DataTable Data Get Method and Toggle Notes Row in Table
  async getDataTableData2(tr: any, inboundData: any, icon: HTMLElement): Promise<void> {
    this.myTable2 = await this.datatableElement2.dtInstance;
    if (tr) {
      const row = this.myTable2.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        icon.innerText = 'add';
      } else {
        // Open this row
        row.child(this.format2(row.data(), inboundData)).show();
        row.child().addClass('no-padding');
        icon.innerText = 'remove';
      }
    }
  }
    //Add Dynamic Row
    format2(data: any, inboundData: any): HTMLElement {
      const table = this.renderer.createElement('table');
      const thead = this.renderer.createElement('thead');
      const tbody = this.renderer.createElement('tbody');

      const thRow = this.renderer.createElement('tr');
       const headers = [
      { text: 'Charge($)', key: 'charges' },
      { text: 'Processing Method', key: 'processingMethod' },
      { text: 'Modified', key: 'modifiedTime' },
      { text: 'Bill To', key: 'billTo' },
      { text: 'Payer Name', key: 'priPayerName' },
      { text: 'Plan Name', key: 'payorLevel' },
      { text: 'Status', key: 'invoiceStatusName' },
    ];


      headers.forEach(header => {
        // if (shouldSkipKey(header.key)) return;
        const th = this.renderer.createElement('th');
        this.renderer.appendChild(th, document.createTextNode(header.text));
        this.renderer.appendChild(thRow, th);
      });

      this.renderer.appendChild(thead, thRow);
      this.renderer.appendChild(table, thead);
      this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

      if (!Array.isArray(inboundData)) {
        inboundData = [inboundData];
      }

      inboundData.forEach((value: any) => {
        const trData = this.renderer.createElement('tr');
        const cells = [
                { text: value.charges, key: 'charges' },
                { text: value.processingMethod, key: 'processingMethod' },
                { text: value.modifiedTime, key: 'modifiedTime' },
                { text: value.billTo, key: 'billTo' },
                { text: value.priPayerName, key: 'priPayerName' },
                { text: value.payorLevel, key: 'payorLevel' },
                { text: value.invoiceStatusName, key: 'invoiceStatusName' },
              ];

        cells.forEach(cell => {
          // if (shouldSkipKey(cell.key)) return;

          const td = this.renderer.createElement('td');
            this.renderer.appendChild(td, document.createTextNode(cell.text));

          this.renderer.appendChild(trData, td);
        });

        this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
        this.renderer.appendChild(tbody, trData);
      });

      this.renderer.appendChild(table, tbody);
      return table;
    }
    onButtonClick(value:any){

    }
    onPatinetDropdownOpenChange(isOpen: boolean) {
      this.isPatinetDropdownOpen = isOpen;
      if (this.isPatinetDropdownOpen) {
        // this.invoiceSearchForm.value.txtPatientFilter || null
        this.loadPatientDropDown(
          ''
        );
      }
    }

    onInvoiceDropdownOpenChange(isOpen: boolean) {
      this.isInvoiceDropdownOpen = isOpen;
      if (this.isInvoiceDropdownOpen) {
        // this.invoiceSearchForm.value.txtInvoiceFilter || null
        this.loadInvoiceDropDown(
          ''
        );
      }
    }

    onSelectPatient(event: any) {
      this.getTableData();
    }
    onSelectInvoice(event: any) {
      this.getTableData();
    }
    onSelectStatus(event: any) {
      this.getTableData();
    }
    drpDownMessage() {
      if (this.isdrpLoading === false) {
        return 'No Matches found';
      } else {
        return 'Loading...';
      }
    }
    drpInvoiceDownMessage() {
      if (this.isdrpInvoiceLoading === false) {
        return 'No Matches found';
      } else {
        return 'Loading...';
      }
    }
    oldPatient:string;
    loadPatientDropDown(value: string, eventKey?: string) {
      if (
        eventKey !== 'ArrowDown' &&
        eventKey !== 'ArrowUp' &&
        eventKey !== 'ArrowRight' &&
        eventKey !== 'ArrowLeft' &&
        (value == '' || value?.length > 2 )
        && value != this.oldPatient
      ) {
        this.oldPatient = value;
        //Get Payer list
        let sSearch:clsSearch={
          sSearch : value,
          ltExisting: this.drpPatientId
        };
        this.isdrpLoading = true;
        if (this.loadPatientDropDownAPICall) {
          this.loadPatientDropDownAPICall.unsubscribe();
        }
        this.loadPatientDropDownAPICall = this.invoiceReportService
          .drpSearchPatientChartBySearch(sSearch)
          .subscribe((response) => {
            this.selectPatientList = response?.items || [];
            this.isdrpLoading = false;

            this.filteredPatient = this.invoiceFilterForm
              .get('txtPatientFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.selectPatientList?.filter((option) =>
                    option?.patientChart
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          });
      }
    }
    oldInvoice : string;
    loadInvoiceDropDown(value: string, eventKey?: string) {
      if (
        eventKey !== 'ArrowDown' &&
        eventKey !== 'ArrowUp' &&
        eventKey !== 'ArrowRight' &&
        eventKey !== 'ArrowLeft'&&
        (value == '' || value?.length > 3 )
        && value != this.oldInvoice
      ) {
        this.oldInvoice=value;
        //Get Payer list
        this.isdrpInvoiceLoading = true;
        if (this.loadInvoiceDropDownAPICall) {
          this.loadInvoiceDropDownAPICall.unsubscribe();
        }
        let sSearch:clsSearch={
          sSearch : value,
          ltExisting: this.drpInvoiceId
        };
        this.loadInvoiceDropDownAPICall = this.invoiceReportService
          .drpSearchInvoiceNumberBySearch(sSearch)
          .subscribe((response) => {
            this.selectInvoiceList = response?.items || [];
            this.isdrpInvoiceLoading = false;

            this.filteredInvoice = this.invoiceFilterForm
              .get('txtPatientFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.selectInvoiceList?.filter((option) =>
                    option?.defaultClaimId
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          });
      }
    }
    loadStatusDropDown(eventKey?: string) {
      if (
        eventKey !== 'ArrowDown' &&
        eventKey !== 'ArrowUp' &&
        eventKey !== 'ArrowRight' &&
        eventKey !== 'ArrowLeft'
      ) {
        //Get Payer list
        // this.isdrpInvoiceLoading = true;
        if (this.loadStatusDropDownAPICall) {
          this.loadStatusDropDownAPICall.unsubscribe();
        }
        this.loadStatusDropDownAPICall = this.invoiceReportService
          .drpInvoiceStatus()
          .subscribe((response) => {
            this.selectStatusList = response?.items || [];
            // this.isdrpInvoiceLoading = false;

            this.filteredStatus = this.invoiceFilterForm
              .get('txtStatusFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.selectStatusList?.filter((option) =>
                    option?.statusName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          });
      }
    }
    focusOutDOS() {

      let txtDOSFromDate = this.datepipe.transform(
        this.invoiceFilterForm.value.txtDOSFromDate || null,
        'yyyy-MM-dd'
      );
      let txtDOSToDate = this.datepipe.transform(
        this.invoiceFilterForm.value.txtDOSEndDate || null,
        'yyyy-MM-dd'
      );
      console.log(txtDOSFromDate);
      console.log(txtDOSToDate);
      if (txtDOSFromDate !== null && txtDOSToDate !== null) {
        // if (txtDOSFromDate !== this.txtDOSFromDate) {
        this.getTableData();
      }
    }
    clearPatient() {
      this.invoiceFilterForm.patchValue({
        drpPatient: null,
      });
      this.drpPatientId=[];
      this.getTableData();
    }
    clearInvoice() {
      this.invoiceFilterForm.patchValue({
        drpInvoice: null,
      });
      this.drpInvoiceId=[];
      this.getTableData();
    }
    clearStatus() {
      this.invoiceFilterForm.patchValue({
        drpStatus: null,
      });
      this.drpStatusId=[];
      this.getTableData();
    }
    clearDOS() {
      this.invoiceFilterForm.patchValue({
        txtDOSFromDate: null,
        txtDOSEndDate: null,
      });
      this.getTableData();
    }
    clearAll() {
      // const { firstDate, lastDate } = this.getFirstAndLastDatesOfCurrentMonth();
      this.invoiceFilterForm.reset();
      this.invoiceFilterForm.patchValue({
        // txtDOSFromDate: firstDate,
        // txtDOSEndDate: lastDate
        txtDOSFromDate: null,
        txtDOSEndDate: null
      });
      this.drpPatientId=[];
      this.drpInvoiceId=[];
      this.drpStatusId=[];
      this.getTableData();
    }
    addSlashToDate(event: Event): void {
      const input = event.target as HTMLInputElement;
      const inputValue = input.value;
      input.value = inputValue
        .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
        .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
        .replace(/[^\d\/]/g, '');
    }
    handleSelectionChange(value: any): void {
      if(value){
      this.getTableData();
    }
    }
    nevigatePage(pageNumber){
      this.currentPage = pageNumber;
      this.getTableData();
    }

    getTableData() {
      this.txtDOSFromDate = this.datepipe.transform(
        this.invoiceFilterForm.value.txtDOSFromDate || null,
        'yyyy-MM-dd'
      );
      let txtDOSEndDate = this.datepipe.transform(
        this.invoiceFilterForm.value.txtDOSEndDate || null,
        'yyyy-MM-dd'
      );

      if (
        (this.txtDOSFromDate &&
          txtDOSEndDate &&
          this.txtDOSFromDate <= txtDOSEndDate) ||
        (!this.txtDOSFromDate && !txtDOSEndDate)
      ) {
        this.isLoading = true;

        // setTimeout(() => {
        //   this.isLoading = false;
        // }, 500);

        try {
          if (this.loadInvoiceTableAPICall) {
            this.loadInvoiceTableAPICall.unsubscribe();
          }

          let input: clsInvoiceReportSearch = {
            ltPatients: this.drpPatientId.length == 0 ? null : this.drpPatientId,
            ltClaims: this.drpInvoiceId.length == 0 ? null : this.drpInvoiceId,
            ltStatus: this.drpStatusId.length == 0 ? null : this.drpStatusId,
            dtDOSFrom: this.txtDOSFromDate,
            dtDOSTo: txtDOSEndDate
          };
          this.loadInvoiceTableAPICall = this.invoiceReportService
            .getInvoiceReportDetailsBySearch(input)
            .subscribe(
              (response) => {
                this.inBoundTableData = response?.items || [];
                this.inBoundTableData = this.inBoundTableData.map((invoice) => {
                  return {
                    ...invoice,
                    dtDOB: this.commonService.getFormattedDateZone(invoice.dtDOB),
                    dtDOS: this.commonService.getFormattedDateZone(invoice.dtDOS),
                    creationTime: this.commonService.getFormattedDateTimeZone(
                      invoice.creationTime
                    ),
                    modifiedTime: this.commonService.getFormattedDateTimeZone(
                      invoice.modifiedTime
                    ),
                  };
                });
                setTimeout(() => {
                  this.isLoading = false;
                }, 500);
              },
              (err) => {
                this.DataList = [];
                const data: HttpErrorResponse = err;
                console.error('Unexpected error:', data?.error?.error?.message);
                setTimeout(() => {
                  this.isLoading = false;
                }, 500);
              }
            );
          // this.tableData$ = getTableData;
        } catch (error) {
          console.error('Unexpected error:', error);
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        } finally {
        }
      } else {
        this.snackBar.open('Select Start / End Date!', 'Close', {
          duration: 2000,
        });
        this.isLoading = false;
      }
    }
    addPageNavigationControls(){
      let buttonsElement =  $(".dt-buttons")[0];

      // $("<button class='dt-button' id='btnDefaultClms' type='button'><span>Show Default Column</span></button>").appendTo(buttonsElement);
      // $("<button class='dt-button' id='btnAllClms' type='button'><span>Show All Column</span></button>").appendTo(buttonsElement);

      $("<span> : <span>").appendTo(buttonsElement);
      if(this.currentPage == 1)
      {
        $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
        $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
      }
      else
      {
        $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
        $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
       }

      $("<span> Page <span>").appendTo(buttonsElement);
      $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);
      $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
      $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);

      if(this.currentPage >= this.totalPages)
      {
        $("<button disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
        $("<button disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
      }
      else
      {
         $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
         $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
      }

      $('#btnNextPage').on('click', (event) => this.changePage(true));
      $('#btnPrevPage').on('click', (event) => this.changePage(false));
      // $(".buttons-colvis").on('click', (event) => this.fnColumnSelectionClick());
      // $('#btnDefaultClms').on('click', (event) => this.fnGetColumnVisiblity("DEFAULT"));
      // $('#btnAllClms').on('click', (event) => this.fnGetColumnVisiblity("ALL"));
      $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
      // $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
      // $('#btnLastPage').on('click', (event) => this.nevigatePage.emit(this.totalPages));
      $('#btnFirstPage').on('click', (event) => this.nevigatePage(1));
      $('#btnLastPage').on('click', (event) => this.nevigatePage(this.totalPages));
     }
     changePage(isNext:boolean){
      if(isNext)
        this.currentPage++;
      else
        this.currentPage--;
      this.nevigatePage(this.currentPage);
    }

    fnGotoPage(){
      let pageValue = $("#txtGotoPage").val();
      let pageno = parseInt(pageValue.toString());
      if(pageno > 0 && this.currentPage !=  pageno && pageno <= this.totalPages)
      {
        this.nevigatePage(pageno);
      }
    }


    // fnColumnSelectionClick(){
    //   $(".buttons-columnVisibility").on('click', (event) => this.fnGetColumnVisiblity("ONE"));
    // }
    // fnGetColumnVisiblity(event){

    //   let visibleColumns = [];
    //   if(event == "ALL")
    //   {
    //     this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
    //       dtInstance.columns().visible(true);
    //     });
    //   }
    //   else
    //   {
    //     this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
    //       if(event == "DEFAULT")
    //         dtInstance.columns(':not(:lt(3),:last-child)').visible(false);

    //       var index = 0;
    //       dtInstance.columns().every(function () {
    //         const that = this;
    //         if(this.visible())
    //           visibleColumns.push(index)
    //         index++;
    //       });
    //     });
    //   }

    //   this.getVisibleColumns.emit(visibleColumns);
    // }

}
