<table
  datatable
  [dtOptions]="orderTableOptions"
  class="row-border hover w-100 display"
  *ngIf="!isOrderTableLoaded"
>
  <thead>
    <tr>
      <th [matTooltip]="'Options'"><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Order No'" class="text-center">Order No</th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'Supplies Date'">Supplies Date</th>
      <th [matTooltip]="'Insurance'">Insurance</th>
      <th [matTooltip]="'Primary Insurance'">Primary Insurance</th>
      <th [matTooltip]="'Order Status'">Order Status</th>
      <th [matTooltip]="'Order Date'">Order Date</th>
      <th [matTooltip]="'Last Mask Date'">Last Mask Date</th>
      <th [matTooltip]="'Points'" class="text-center">Points</th>
      <th [matTooltip]="'Exchange/Return'">Exchange/Return</th>
      <th [matTooltip]="'Billing Order Type'">Billing Order Type</th>
      <th [matTooltip]="'Payment Status'">Payment Status</th>
      <th [matTooltip]="'Hello Sign Status'">Hello Sign Status</th>
      <th [matTooltip]="'CSR'">CSR</th>
      <th [matTooltip]="'Created By'">Created By</th>
      <th class="text-right" [matTooltip]="'Created Date'">Created Date</th>
      <th [matTooltip]="'Modify By'">Modify By</th>
      <th class="text-right" [matTooltip]="'Modified Date'">Modified Date</th>
    </tr>
  </thead>
  <tbody>
    <!-- (dblclick)="navigateToOrderPage(data.patientId, data.id)" -->
    <tr
      [ngClass]="tblSelectedRow === data.id ? 'row-highlighted' : ''"
      (click)="tblItemSelectedRow(data.id)"
      *ngFor="let data of orderTableData"
    >
      <td [matTooltip]="'Open Options'">
        <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu"
          >more_vert</mat-icon
        >
        <mat-menu class="custom-mat-menu" #menu="matMenu">
          <button
            mat-menu-item
            (click)="
              openAddNotesPopUp(
                data.defaultFaxId,
                data.patientId,
                data.docId,
                false,
                data.chartNo
              )
            "
          >
            Add Patient Notes
          </button>
          <button mat-menu-item (click)="openViewNotesPopup(data.patientId)">
            View Patient Notes
          </button>
          <!-- <button mat-menu-item>View Document Images</button> -->
          <a
            [routerLink]="
              '/view_document_tiff/' + data.faxDocumentId + '/' + true
            "
            target="_blank"
            class="preview-link"
            [class.disabled]="
              isEmpty(data.faxDocumentId) ||
              data?.isSorted === false ||
              data?.isSorted === null
            "
          >
            <button
              [disabled]="
                isEmpty(data.faxDocumentId) ||
                data?.isSorted === false ||
                data?.isSorted === null
              "
              mat-menu-item
            >
              View Document Image
            </button>
          </a>
          <button
            mat-menu-item
            (click)="viewPatientLedgerPopup(data.patientId, data.chartNo)"
          >
            View Patient Ledger
          </button>
          <mat-divider></mat-divider>
          <button
            (click)="navigateToOrderPage(data.patientId, defaultGuid)"
            mat-menu-item
          >
            Start New Order
          </button>
          <!-- </a> -->
          <mat-divider></mat-divider>
          <button
            (click)="navigateToOrderPage(data.patientId, data.id)"
            mat-menu-item
          >
            View Sale Order
          </button>
          <button (click)="copyOrder(data.patientId, data.id)" mat-menu-item>
            Copy Sale Order
          </button>
          <button
            [class.disabled]="
              data.id === defaultGuid || data.id === null || data.id === ''
            "
            (click)="
              getOrderHistory(
                data.id,
                data.mmRefereceOrderId,
                data.chartNo,
                data.patientName
              )
            "
            mat-menu-item
          >
            Order History
          </button>
          <!-- </a> -->
          <button mat-menu-item>OnDemand Verification Request</button>
          <button mat-menu-item>Online Verification</button>

          <a
            [class.disabled]="data.paymentMethod == 'Cash' || data.isExchangeOrder"
            [routerLink]="'/orders/sale-order-invoices/' + data.id"
            target="_blank"
            class="preview-link"
          >
            <button
              mat-menu-item
              [class.disabled]="
                data.paymentMethod == 'Cash' || data.isExchangeOrder
              "
              [disabled]="data.paymentMethod == 'Cash' || data.isExchangeOrder"
            >
              Invoices
            </button>
          </a>

          <mat-divider></mat-divider>
          <!-- <a
            [routerLink]="
              '/Hello-sign/'+ data.id + '/' + data.patientId+'/' + data.signatureId+'/'+false"

            target="_blank"
            class="preview-link"

          >
            <button

              mat-menu-item
            >
            Hello Sign
            </button>
          </a> -->

          <button mat-menu-item [matMenuTriggerFor]="helloSign">
            Hello Sign
          </button>
          <mat-menu #helloSign="matMenu">
            <a
              [class.disabled]="
                isEmpty(data.chartNo) || isEmpty(data.patientId)
              "
              [routerLink]="
                '/patientCreateEditTab/' +
                data.patientId +
                '/' +
                data.documentId +
                '/' +
                'Sorting'
              "
              target="_blank"
              class="preview-link"
            >
              <button mat-menu-item>View Document</button>
            </a>

            <a
              [routerLink]="
                '/Hello-sign/' +
                data.id +
                '/' +
                data.patientId +
                '/' +
                data.signatureId +
                '/' +
                false
              "
              target="_blank"
              class="preview-link"
            >
              <button
                (click)="orderCancel(data.id, data.orderStatusNo)"
                mat-menu-item
              >
                View Status
              </button>
            </a>
          </mat-menu>

          <!-- <button mat-menu-item>Resend Hello Sign</button> -->
          <mat-divider></mat-divider>
          <button (click)="getPrintTicketDetails(data.id)" mat-menu-item>
            Print Ticket
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item>Move Back To Pending</button>
          <mat-divider></mat-divider>
          <button mat-menu-item [matMenuTriggerFor]="cancelOrder">
            Cancel
          </button>
          <mat-menu #cancelOrder="matMenu">
            <button mat-menu-item>Resupply Cancel</button>
            <button
              (click)="orderCancel(data.id, data.orderStatusNo)"
              mat-menu-item
            >
              Current Order Cancel
            </button>
          </mat-menu>
          <mat-divider></mat-divider>
          <button
            mat-menu-item
            [matMenuTriggerFor]="paypalInvoice"
            [disabled]="data?.paymentMethod === 'Insurance'"
          >
            Paypal Invoice
          </button>
        </mat-menu>
        <mat-menu #paypalInvoice="matMenu">
          <!-- <button mat-menu-item>Send Invoice Reminder</button> -->
          <button
            [disabled]="data.invoiceId === null"
            (click)="resendPaypalInvoice(data.invoiceId, data.id)"
            mat-menu-item
          >
            Resend Invoice
          </button>
          <!-- <button mat-menu-item>Copy Invoice Link</button> -->
          <button
            [disabled]="data.invoiceId === null"
            (click)="cancelPaypalInvoice(data.invoiceId)"
            mat-menu-item
          >
            Cancel Invoice
          </button>
          <button mat-menu-item>Invoice Paid Manually/Mark as Paid</button>
        </mat-menu>
      </td>
      <td class="text-center" [matTooltip]="data?.mmRefereceOrderId || '-'">
        <!-- {{ data.mmRefereceOrderId }} -->

        <a
          [class.disabled]="
            isEmpty(data.mmRefereceOrderId) || isEmpty(data.patientId)
          "
          [routerLink]="
            '/orders/saleOrder/' +
            data.patientId +
            '/' +
            data.id +
            '/' +
            pageType
          "
          class="preview-link"
          >{{ data?.mmRefereceOrderId }}
        </a>
      </td>
      <td [matTooltip]="data?.chartNo || '-'">
        <a
          [class.disabled]="isEmpty(data.chartNo) || isEmpty(data.patientId)"
          [routerLink]="
            '/patientCreateEditTab/' + data.patientId + '/' + data.documentId
          "
          target="_blank"
          class="preview-link"
          >{{ data?.chartNo ? data?.chartNo : "-" }}
        </a>
      </td>
      <td [matTooltip]="data?.patientName || '-'">{{ data.patientName }}</td>
      <td [matTooltip]="data.suppliesDate">
        <!-- isDefaultDate(data.suppliesDate)
            ? "-"
            : (data.suppliesDate | date : "MM/dd/yyyy" : "en_US") -->
        {{ data.suppliesDate }}
      </td>
      <td [matTooltip]="data?.insuranceCategoryName || '-'">
        {{ data.insuranceCategoryName }}
      </td>
      <td [matTooltip]="data?.policyName || '-'">{{ data.policyName }}</td>

      <td [matTooltip]="data?.orderStatusName || '-'">
        {{ data.orderStatusName }}
      </td>
      <td [matTooltip]="data.orderDate">
        <!-- {{ data.orderDate | date : "MM/dd/yyyy" : "en_US" }}
           -->
        {{ data.orderDate }}
        <!-- {{ commonService.getFormattedDateTimeZone(data.orderDate) }} -->
      </td>
      <td [matTooltip]="data.lastMaskProcessDate">
        <!-- {{
          data.lastMaskProcessDate
            ? (data.lastMaskProcessDate | date : "MM/dd/yyyy" : "en_US")
            : "-"
        }} -->
        {{ data.lastMaskProcessDate }}
      </td>
      <td class="text-center" [matTooltip]="data?.orderPointsCAL || '-'">
        {{ data.orderPointsCAL }}
      </td>
      <td
        [matTooltip]="
          data?.exchangeOrReturn
            ? data?.exchangeOrReturn + '-' + data.mmNewOrderId
            : '-'
        "
      >
        <ng-container *ngIf="data.exchangeOrReturn">
          <a
            target="_blank"
            class="preview-link"
            (click)="navigateToOrderPage(data.patientId, data.newOrderId)"
          >
            {{
              data.exchangeOrReturn === null || data.exchangeOrReturn === ""
                ? "-"
                : data.exchangeOrReturn
            }}

            {{
              data.mmNewOrderId === null || data.mmNewOrderId === ""
                ? " "
                : "- " + data.mmNewOrderId
            }}
          </a>
        </ng-container>
        <ng-container
          *ngIf="
            !data.exchangeOrReturn ||
            data.exchangeOrReturn === null ||
            data.exchangeOrReturn === ''
          "
        >
          {{
            data.exchangeOrReturn === null || data.exchangeOrReturn === ""
              ? "-"
              : data.exchangeOrReturn
          }}

          {{
            data.mmNewOrderId === null || data.mmNewOrderId === ""
              ? " "
              : "- " + data.mmNewOrderId
          }}
        </ng-container>
      </td>
      <td [matTooltip]="data?.paymentMethod || '-'">
        {{ data.paymentMethod ? data.paymentMethod : "-" }}
      </td>
      <td [matTooltip]="data?.paymentStatus || '-'">
        {{ data.paymentStatus ? data.paymentStatus : "-" }}
      </td>
      <td [matTooltip]="data?.hellosignStatus || '-'">
        {{ data.hellosignStatus ? data.hellosignStatus : "-" }}
      </td>
      <td [matTooltip]="data?.csrName || '-'">
        {{ data?.csrName ? data.csrName : "-" }}
      </td>
      <td
        [matTooltip]="data?.createdBy || '-'"
        (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')"
      >
        {{ data?.createdBy || "-" }}
      </td>

      <td
        class="text-right"
        [matTooltip]="data?.creationTime"
        (dblclick)="onRowDoubleClickV1(data?.creationTime)"
      >
        {{ data?.creationTime }}
      </td>
      <td
        [matTooltip]="data?.modifiedUser || '-'"
        (dblclick)="onRowDoubleClickV1(data?.modifiedUser || '-')"
      >
        {{ data?.modifiedUser || "-" }}
      </td>

      <td
        class="text-right"
        [matTooltip]="data?.lastModificationTime"
        (dblclick)="onRowDoubleClickV1(data?.lastModificationTime)"
      >
        {{ data?.lastModificationTime }}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th>
        <input
          matInput
          class="text-center textbox-border-class"
          type="text"
          placeholder="Order No"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Chart No"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Patient Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Supplies Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Insurance"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Primary Insurance"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Order Status"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Order Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Last Mask Date"
        />
      </th>
      <th>
        <input
          matInput
          class="text-center textbox-border-class"
          type="text"
          placeholder="Points"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Exchange/Return"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Payment Method"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Payment Status"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Hello Sign Status"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="CSR"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
          name="search-Created By"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created Date"
          name="search-Created Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modify By"
          name="search-Modify By"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Modified Date"
          name="search-Modified Date"
        />
      </th>
    </tr>
  </tfoot>
</table>

<div class="d-flex justify-content-center" *ngIf="isOrderTableLoaded">
  <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
</div>
