<mat-dialog-content class="mat-typography">
  <div class="modal-header p-0">
    <h1 class="modal-title">Upload Fax Documents

      <span class="customThemeClass" *ngIf="selectedFileCount > 0 ">
        ( Selected Files: {{ selectedFileCount }} - Total Size : {{ totalSizeforTable }} )
      </span>
      <span class="asterisk" *ngIf="showErrorForFileType">
        {{ "Select Only PDF`s" }}
      </span>
      <span class="asterisk" *ngIf="showErrorBasedOnMaxSize">
        {{ 'Max Total file`s size of 190MB or Less' }}
      </span>
    </h1>
    <button class="close" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="inboundUploadForm">
    <div class="modal-body">
      <div class="row">
         <div class="col-6">
          <div class="card card-body">
            <div class="row">
            <mat-form-field class="col-9">
              <mat-label>Location<span class="asterisk"> * </span></mat-label>
              <mat-select #selectBranch formControlName="drpBranchType">
                <mat-option *ngFor="let branch of filteredBranchTypeList" [value]="branch.id">
                  {{ branch.organizationUnitName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
             <div class="col-md-3 mt-3">
               <mat-checkbox formControlName="chkStat">STAT</mat-checkbox>
             </div>
            </div>
            <!-- </div> -->
             <div>
               <div class="drop-area ">
            <!-- <span class="asterisk text-left" *ngIf="showErrorForFileType">
                {{ "Select Only PDF`s" }}
              </span> -->
              <!-- <span class="asterisk" *ngIf="showErrorBasedOnMaxSize">
                {{ 'Max Total file`s size of 20MB or Less' }}
              </span> -->

            <div class="file-upload-container " (drop)="addMoreFile($event,true);$event.preventDefault();"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)" >

                <img class="img-responsive pl-1 mb-1 cursor" src="assets/Claim Page Icons/browse.png" alt=""
                    height="17">
                <div class="drop-area"  >
                    Drag and drop files here
                </div>
                <div class="name-label-container">
                    <hr class="hr-divider">
                    <h2 class="name-label">Or</h2>
                    <hr class="hr-divider">
                </div>
                <div style="padding-bottom: 17px;">
                    Have file on your computer
                </div>
                &nbsp;
                <button class="file-upload-button cursor" [disabled]="chkdisableButton">
                  <div class="row" style="padding-left: 56px;">
                    <div class="brow-align mr-3">Browse</div>
                    <mat-icon class="cursor">cloud_upload</mat-icon>
                  </div>
                  <input autocomplete="new-documentFile" id="file-upload" type="file" formControlName="documentFile"
                    class="cursor" (change)="onFileChange($event)" [multiple]="true" #uploadFileReference
                    accept="application/pdf" [disabled]="chkdisableButton" (drop)="false"/>
                </button>
            </div>
               </div>
            </div>
        </div>
         </div>
           <div class="col-6">
            <div class="card card-body">
              <!-- <div class="row">
              <mat-form-field class="col-9">
                <mat-label>Location<span class="asterisk"> * </span></mat-label>
                <mat-select #selectBranch formControlName="drpBranchType">
                  <mat-option *ngFor="let branch of filteredBranchTypeList" [value]="branch.id">
                    {{ branch.organizationUnitName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
               <div class="col-md-3 mt-3">
                 <mat-checkbox formControlName="chkStat">STAT</mat-checkbox>
               </div>
              </div> -->
              <!-- <mat-divider style="padding-bottom: 3px;"></mat-divider> -->
              <div class="row">

                <div class=" col-12" *ngIf="!isRefresh">
                  <div *ngIf="arrSelectedDocuments && arrSelectedDocuments?.length !== 0">
                    <div class="custom-card">
                      <div class="row">
                        <div class="col-lg-10">
                          <div class="fileName nowrap_card">Add File <span class="size_pagecount">(Select only PDF's)</span></div>
                          <!-- <div class="mb-0">
                            <p class="size_pagecount nowrap_card mb-0">Select only PDF's</p>
                          </div> -->
                        </div>
                        <div class="col-lg-2 center"  >
                          <input type="file"  (change)="addMoreFile($event,false)" [multiple]="true" formControlName="addMoreFile"
                          accept="application/pdf" style="display: none;" #AddfileInput >
                          <mat-icon (click)="AddfileInput.click()" matTooltip="Click to Add file" class="addMoreFileButton">add_circle</mat-icon>
                        </div>
                      </div>
                    </div>
                    <div style="max-height: 250px;overflow-x: hidden;overflow-y: auto;" >
                      <ng-container *ngFor="let document of arrSelectedDocuments">
                        <div class="custom-card">
                          <div class="row">
                            <div class="col-lg-10">
                              <div [matTooltip]="document.fileName" class="fileName nowrap_card">{{ document.fileName }}.pdf
                                <span class="size_pagecount">({{ document.fileSize }} - {{ document.pageCount }} Pages)
                                </span>
                              </div>
                              <!-- <div class="mb-0">
                                <p class="size_pagecount nowrap_card mb-0">{{ document.fileSize }} - {{ document.pageCount }} Pages
                                </p>
                              </div> -->
                            </div>
                            <div class="col-lg-2 center">
                              <mat-icon matTooltip="Click to remove file" class="deleteFileButton" (click)="deleteDocument(document)">delete</mat-icon>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="row">
                      <div class=" col-12" *ngIf="!isRefresh" >
                        <div class="meter" [hidden]="!isProcessBarShow">
                          <span class="meterV1" [style.width]="width+'%'"></span>
                        </div>
                      </div>
                     </div>
              </div>
                </div>

                <div class="col-12 " style="padding: 4.5rem !important;
                       margin-top: 4rem !important;display: flex; justify-content: center; align-items: center;"
                *ngIf="!arrSelectedDocuments || arrSelectedDocuments?.length === 0" >
                  <img  class="img-responsive pr-1 mb-0 cursor"
                  src="assets/images/DashboardIcons/No file.png" alt="" height="27" />
                  No Files Selected
                </div>
               </div>

           </div>
         </div>
      </div>
    </div>
    <div class="modal-footer">
      <!-- <div class="col-5 text-center" style="margin: 0px !important;">
      <span *ngIf="notificationText" class="blinking"> Please wait for the selected files to load</span>
    </div> -->
      <div class="col-7 " style="margin: 0px !important;">
        <button mat-button class="buttonColor mb-2 mr-2" mat-raised-button
          [disabled]="inboundUploadForm.invalid || chkdisableButton || !arrSelectedDocuments || arrSelectedDocuments?.length === 0"
          (click)="savefuncation()">
          <span>Save</span>
          <mat-icon *ngIf="chkdisableButton">
            <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
          </mat-icon>
        </button>
        <button mat-button [disabled]="chkdisableButton" class="resetclr mb-2 mr-2"
          (click)="resetInboundDocumentForm()">Reset</button>
      </div>
    </div>
  </form>
</mat-dialog-content>
