<meta http-equiv="Cache-control" content="no-cache" />
<div class="container-fluid">
  <div class="row align-items-center" *ngIf="isModel == true">
    <div class="col-lg-9">
      <h3 class="customThemeClass mt-2">
        <b>View Document Image <span *ngIf="data.defaultDocumentId"><b>- {{ data.defaultDocumentId }}</b> </span></b>
      </h3>
    </div>
    <div class="col-lg-3" style="text-align-last: right">
      <div>
        <button mat-icon-button mat-dialog-close class="close-button">
          <mat-icon class="mr-2">close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="row bg" style="margin-top: -10px">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0 font-size-18" *ngIf="sortStatus != 'true'">
          <b>Sorting / Processed Documents</b>
        </h3>
        <h3 class="mb-0 font-size-18" *ngIf="sortStatus == 'true'">
          <b>Sorting / Sorted Documents</b>
        </h3>
        <h3 class="mb-0 font-size-18">
          <b *ngIf="faxId">FaxId:</b>&nbsp;<b class="text-danger" *ngIf="faxId">{{ faxId }}</b>
        </h3>
        <h3 class="mb-0 font-size-18" >
          <b>Patient Id:</b>&nbsp;<b class="text-danger">{{
            defaultPatientId ? defaultPatientId : '-'
          }}</b>
        </h3>

        <h3 class="mb-0 font-size-18">
          <b>Patient Name:</b>&nbsp;<b class="text-danger">{{ patientName ? patientName : '-' }}</b>
        </h3>


        <div class="page-title-box page-title-right pb-0" *ngIf="isModel != true">
          <ol class="breadcrumb m-0" style="background-color: transparent">
            <li>
              <button *ngIf="sortStatus != 'true' && sortingBin != 'true'"
                mat-button
                class="buttonColor mr-2 font-size-14 pl-2"
                (click)="redirectToProcessedDocuments()"
              >
                <i class="fa fa-backward mr-1"></i> Back to Processed Documents
              </button>
              <button *ngIf="sortingBin == 'true'"
              mat-button
              class="buttonColor mr-2 font-size-14 pl-2"
              (click)="redirectTomissingDocuments()"
            >
              <i class="fa fa-backward mr-1"></i> Move to missingDocuments
            </button>
            <button *ngIf="sortStatus == 'true'"
            mat-button
            class="buttonColor mr-2 font-size-14 pl-2"
            (click)="redirectTomissingDocuments()"
          >
            <i class="fa fa-backward mr-1"></i> Back 
          </button>
              <button
                mat-button *ngIf="sortingBin == 'true'"
                class="defaultButtonColor"
                (click)="saveSortedDocument()"
                [disabled]="completeButtonDisabled"
              >
                Complete
                <mat-icon *ngIf="isLoading">
                  <mat-spinner
                    class="spinner-border spinner-border-sm"
                    diameter="20"
                  >
                  </mat-spinner>
                </mat-icon>
              </button>
            </li>
            <!-- <li class="ml-2 mt-2 breadcrumb-item">Sorting</li>
            <li class="mt-2 breadcrumb-item muted">Processed Documents</li> -->
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <mat-card style="margin-bottom: 30px; height: 840px">
      <mat-card-content >
        <div class="row">
          <div class="col-lg-3">


                <div class="card" class="custom-select-card custom-width-auto">
                  <div class="card-header custom-card-header" *ngIf="sortStatus != 'true' && isModel == false">
                    <b>No.Of Pages [ {{ totalPages }} ] </b>
                    <div class="row">
                      <input autocomplete="new-Number" class="col-8" (keypress)="numberOnly($event)"
                        placeholder=" 1 (or) 1-5 (or) 1,3,4" [(ngModel)]="pageCount" matInput maxlength="8" type="text" />
                      <button class="ml-1 defaultButton" (click)="moveDocument()" [disabled]="moveButtonDisabled" mat-button>
                        <mat-icon>arrow_back</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              <div class="card-body"  [style]="isModel ? 'overflow-y: auto; max-height: 810px;' : 'overflow-y: auto; max-height: 715px;'">
                <mat-selection-list
                  #documentTypes
                  id="sltDocumentType"
                  [multiple]="false"
                  [(ngModel)]="dTypeId"
                  (selectionChange)="onListSelected(dTypeId)"
                  class="custom-selection-list"
                >
                  <mat-list-option
                    *ngFor="let document of documentClassification"
                    [value]="document.docTypeId"
                    [matTooltip]="getToolTip(document)"
                  >
                    <div>
                      {{ document.documentType }}
                      <span class="h5" *ngIf="sortStatus != 'true'"
                        >({{ document.documentPages.length }})</span
                      >
                      <span class="h5" *ngIf="sortStatus == 'true'"
                      >({{ document.documentPages }})</span
                    >
                    </div>

                    <!-- <span
                      class="pull-right"
                      matBadge="{{ document.documentPages.length }}"
                      matBadgeOverlap="false"
                    ></span> -->
                  </mat-list-option>
                </mat-selection-list>
              </div>
          </div>
          <!-- <div class=" col-lg-2 form-group algin-change" >
            <label class="lables">Enter Page Range:</label>
            <input   maxlength="8" type="text" class="form-control lables" placeholder="1-5 , 1,3,4" [(ngModel)]="pageCount" >

           </div> -->
          <!-- <div class="col-lg-1 d-flex align-items-center">
            <button
              class="custom-button defaultButtonColor"
              [disabled]="moveButtonDisabled"
              (click)="moveDocument()"
              mat-button
            >
              <mat-icon class="arrow-icon">keyboard_arrow_left</mat-icon>
            </button>
          </div> -->
          <div class="col-lg-9">
            <!-- style="height: 800px;" -->
            <div class="pdf-container ml-0">
              <ng-container>
                <ngx-extended-pdf-viewer
                  [src]="strSelectedPdfPath"
                  [(page)]="pageNumber"
                  [(pageViewMode)]="pageViewMode"
                  (pagesLoaded)="onPagesLoaded($event)"
                  [height]="pdfHeight"
                  [showSidebarButton]="false"
                  [showFindButton]="true"
                  [textLayer]="true"
                  [showPagingButtons]="true"
                  [showOpenFileButton]="false"
                  [showHandToolButton]="false"
                  [showScrollingButton]="false"
                  [showSpreadButton]="false"
                  [showPropertiesButton]="false"
                  [showBookmarkButton]="false"
                  [zoom]="'page-width'"
                  [enableDragAndDrop]="false"
                  [(page)]="currentPage"
                  (pageChange)="onPageChange($event)"
                >
                </ngx-extended-pdf-viewer>
              </ng-container>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- <button mat-button (click)="getPdfPage()">Test Button</button>
  <div class="col-xl-9">
    <ng-container>
      <ngx-extended-pdf-viewer
        [base64Src]="strSelectedPdfPath"
        (pageChange)="testMethod()"
        [useBrowserLocale]="true"
        [textLayer]="true"
        [(page)]="pageNumber"
        [(pageViewMode)]="pageViewMode"
        [showHandToolButton]="true"
        [height]="pdfHeight"
      >
      </ngx-extended-pdf-viewer>
    </ng-container>
  </div> -->
</div>
