import type { CreateUpdateMMOrderDTO, CreateUpdateMmOrderItemReturnDTO, MmOrderDTO, MmOrderItemReturnDTO, ReturnListDTO, ReturnProductDetailsListDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MmOrderItemReturnService {
  apiName = 'orderManagement';

  create = (input: CreateUpdateMmOrderItemReturnDTO) =>
    this.restService.request<any, MmOrderItemReturnDTO>({
      method: 'POST',
      url: '/api/orderApp/mm-order-item-return',
      body: input,
    },
    { apiName: this.apiName });

  createMultipleMmOrderItemReturn = (OrderId: string, input: CreateUpdateMmOrderItemReturnDTO[]) =>
    this.restService.request<any, MmOrderDTO>({
      method: 'POST',
      url: `/api/orderApp/mm-order-item-return/multiple-mm-order-item-return/${OrderId}`,
      body: input,
    },
    { apiName: this.apiName });

  createNewSaleOrderByInput = (input: CreateUpdateMMOrderDTO) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/orderApp/mm-order-item-return/new-sale-order',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/orderApp/mm-order-item-return/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, MmOrderItemReturnDTO>({
      method: 'GET',
      url: `/api/orderApp/mm-order-item-return/${id}`,
    },
    { apiName: this.apiName });

  getAllOrderItemReturnList = () =>
    this.restService.request<any, MmOrderItemReturnDTO[]>({
      method: 'GET',
      url: '/api/orderApp/mm-order-item-return/order-item-return-list',
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MmOrderItemReturnDTO>>({
      method: 'GET',
      url: '/api/orderApp/mm-order-item-return',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getReturnItemsListByDtFromDateAndDtToDate = (dtFromDate: string, dtToDate: string) =>
    this.restService.request<any, ReturnListDTO[]>({
      method: 'GET',
      url: '/api/orderApp/mm-order-item-return/return-items-list',
      params: { dtFromDate, dtToDate },
    },
    { apiName: this.apiName });

  getReturnedItemsDetailsByIdByGOrderId = (gOrderId: string) =>
    this.restService.request<any, ReturnProductDetailsListDTO[]>({
      method: 'GET',
      url: `/api/orderApp/mm-order-item-return/returned-items-details-by-id/${gOrderId}`,
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateMmOrderItemReturnDTO) =>
    this.restService.request<any, MmOrderItemReturnDTO>({
      method: 'PUT',
      url: `/api/orderApp/mm-order-item-return/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  updateReasonIncreaseStockByInput = (input: ReturnProductDetailsListDTO) =>
    this.restService.request<any, string>({
      method: 'PUT',
      responseType: 'text',
      url: '/api/orderApp/mm-order-item-return/update-reason-increase-stock',
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
