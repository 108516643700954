import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { WonaceClaimTab } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/billing/wonace-claim-tab.enum';
import { requestOfClaimwoance } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/dto/models';
import { requestOfClaimwoance1 } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/dto/models';
import { ItemPaymentService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import {
  MyQueueClaimStatus,
  MyQueueClaimStatusText,
} from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { Subscription } from 'rxjs';
import { MasterProviderService } from '../warehouse-proxy/inventory/master-provider.service';
import { ProviderGetDTO } from '../warehouse-proxy/inventory/dto/models';
import { InputPatientListDto, PatientChartIdDTO, PatientListDto } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-personal.service';
import { claimStatus } from 'projects/billing/src/app/billing-proxy/billing-management/billing/claim-status.enum';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-claim-bin-queue',
  templateUrl: './claim-bin-queue.component.html',
  styleUrls: ['./claim-bin-queue.component.scss'],
})
export class ClaimBinQueueComponent implements OnInit {
  loadInboundData: boolean = false;
  inboundStatusIds = MyQueueClaimStatus;
  lstSelectedChartNos: string[] = [];
  lstSelectedPatients: string[] = [];
  lstSelecteddrpProvider: string[] = [];
  lstSelectedClaimStatus: string[] = [];
  lstChartNos: PatientChartIdDTO[] = [];
  lstPatients: PatientListDto[] = [];
  lstprovider: ProviderGetDTO[];
  lstClaimStatus = Object.keys(claimStatus).map(key => ({ key, value: claimStatus[key] }));
  menus = [
    {
      status: MyQueueClaimStatusText.ClaimSubmission,
      color: '#faa307',
      count: 0,
      arrowclass: 'claimsubmission-down',
      inboundStatusIds: MyQueueClaimStatus.ClaimSubmission,
    },
    {
      status: MyQueueClaimStatusText.AutoPosting,
      color: '#b56576',
      count: 0,
      arrowclass: 'autoposting-down',
      inboundStatusIds: MyQueueClaimStatus.AutoPosting,
    },
    {
      status: MyQueueClaimStatusText.ClaimPosting,
      color: '#c77dff',
      count: 0,
      arrowclass: 'claimposting-down',
      inboundStatusIds: MyQueueClaimStatus.ClaimPosting,
    },
    {
      status: MyQueueClaimStatusText.ClaimChecklist,
      color: '#57cc99',
      count: 0,
      arrowclass: 'claimchecklist-down',
      inboundStatusIds: MyQueueClaimStatus.ClaimChecklist,
    },
  ];
  inboundTableData: any;
  txtDOSFromDate:any;
  activeTabBin: MyQueueClaimStatusText;
  inboundstatusCode: any;
  chartIdDD$: Subscription;
  getClaimTableData: Subscription;
  patientDD$: Subscription;
  providerDD$: Subscription;
  cliamBinQueueFilterForm: FormGroup;
  inititalLoading: boolean = true;
  currentPage : any = 1;
  totalPages : any = 1;
  pageOffset: any = 50;
  visibleColumns : number[];

  constructor(
    private itemPaymentService: ItemPaymentService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private masterProviderService: MasterProviderService,
    private patientPersonalService: PatientPersonalService,
  ) { }

  ngOnInit(): void {
    this.initializeForms();
    this.getClaimFileterList(this.inboundStatusIds.ClaimSubmission);
    this.activeTabBin = MyQueueClaimStatusText.ClaimSubmission;
    this.inboundstatusCode = MyQueueClaimStatus.ClaimSubmission;
    this.getClaimFileterList(this.inboundstatusCode);
    this.loadProviderDropdown();
    this.loadChartNoDropdown();
    this.loadPatientDropdown();
  }

  initializeForms() {
    this.cliamBinQueueFilterForm = this.fb.group({
      drpProvider: new FormControl(''),
      txtProvider: new FormControl(''),
      drpChartId: new FormControl(''),
      drpPatientName: new FormControl(''),
      txtPatientName: new FormControl(''),
      txtChartId: new FormControl(''),
      txtDOSFromDate:new FormControl(''),
      txtDOSEndDate:new FormControl(''),
      // drpChartId: new FormControl(''),
      // txtChartId: new FormControl(''),
      // drpPatientName: new FormControl(''),

      // drpStatus: new FormControl(''),
      // txtStatus: new FormControl(''),
      // txtOrderFromDate: new FormControl(''),
      // txtOrderToDate: new FormControl(''),
      // txtSuppliesFromDate: new FormControl(''),
      // txtSuppliesToDate: new FormControl(''),
      // drpInsurance: new FormControl(''),
      // txtInsurance: new FormControl(''),
    });
  }

  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }
  focusOutDOS() {

    let txtDOSFromDate = this.datepipe.transform(this.cliamBinQueueFilterForm.value.txtDOSFromDate || null,'yyyy-MM-dd');
    let txtDOSToDate = this.datepipe.transform(this.cliamBinQueueFilterForm.value.txtDOSEndDate || null,'yyyy-MM-dd');
    console.log(txtDOSFromDate);
    console.log(txtDOSToDate);
    if (txtDOSFromDate !== null && txtDOSToDate !== null) {
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }
  clearDOS() {
    this.cliamBinQueueFilterForm.patchValue({
      txtDOSFromDate: null,
      txtDOSEndDate: null,
    });
    this.getClaimFileterList(this.inboundstatusCode);
  }
  //Load Provider Dropdown
  loadProviderDropdown() {
    if (this.providerDD$) {
      this.providerDD$.unsubscribe();
    }
    let pName: string = this.cliamBinQueueFilterForm?.value?.txtProvider, sProvider: string[] = this.lstSelecteddrpProvider;
    this.providerDD$ = this.masterProviderService
      .getProviderNameByPNameAndSProvider(pName, sProvider)
      .subscribe(
        (response) => {
          this.lstprovider = response ?? [];
        },
        (err) => {
          this.lstprovider = [];
        }
      );
  }

  //Load Chart No Dropdown
  loadChartNoDropdown() {
    if (this.chartIdDD$) {
      this.chartIdDD$.unsubscribe();
    }
    let chartSearchString = this.cliamBinQueueFilterForm?.value?.txtChartId ?? '';

    this.chartIdDD$ = this.patientPersonalService.createChartIdByChartIdPATAndSPatient(chartSearchString, this.lstSelectedChartNos ?? [])
      .subscribe(
        (response) => {
          this.lstChartNos = response ?? [];
        },
        (err) => {
          this.lstChartNos = [];
        }
      );
  }
  //Patient Dropdown
  loadPatientDropdown() {
    if (this.patientDD$) {
      this.patientDD$.unsubscribe();
    }

    let params: InputPatientListDto = {
      gPat: this.lstSelectedPatients ?? [],
      sSearch: this.cliamBinQueueFilterForm?.value?.txtPatientName ?? '',
    };
    this.patientDD$ = this.patientPersonalService.getPatientListByInput(params)
      .subscribe(
        (response) => {
          this.lstPatients = response ?? [];

        },
        (err) => {
          this.lstPatients = [];
        }
      );
  }


  getArrowClass(data: any): any {
    return { [data.arrowclass]: this.activeTabBin === data.status };
  }

  binActiveTab(data: any) {
    this.activeTabBin = data?.status;
    this.inboundstatusCode = data?.inboundStatusIds;
    this.getClaimFileterList(this.inboundstatusCode);
  }

  changePage(pageNo : number){
    if( pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages)
    {
      this.currentPage = pageNo; 
      this.getClaimFileterList(this.inboundstatusCode);
    }
  }

  getClaimFileterList(inboundStatusIds: any) {
    let shortCode = inboundStatusIds;
    let patientids = this.lstSelectedPatients?.concat(this.lstSelectedChartNos ?? []);
    this.txtDOSFromDate = this.datepipe.transform(this.cliamBinQueueFilterForm.value.txtDOSFromDate || null, 'yyyy-MM-dd');
    let txtDOSEndDate = this.datepipe.transform(this.cliamBinQueueFilterForm.value.txtDOSEndDate || null, 'yyyy-MM-dd');
    let dto: requestOfClaimwoance1 = {
      postingMethod: [],
      shortCode: shortCode,
      checkNo: [],
      claimProcessingStatus: [],
      waystarStatus: [],
      claimStatus: this.lstSelectedClaimStatus || [],
      patientId: patientids,
      orderId: [],
      claimId: [],
      fromdos: this.txtDOSFromDate,
      todos: txtDOSEndDate,
      providerId: this.lstSelecteddrpProvider || [],
      pageNo: this.currentPage,
      pageOffset: this.pageOffset
    };
    this.loadInboundData = true;
    if (this.getClaimTableData) {
      this.getClaimTableData.unsubscribe();
    }
    this.getClaimTableData = this.itemPaymentService.getClaimTabReportByDto(dto)
      .subscribe(
        (response) => {
          if (shortCode == MyQueueClaimStatus.ClaimSubmission) {
            this.totalPages = Math.ceil(response?.claimSubmissionCount / this.pageOffset);
            this.inboundTableData = response?.claimSubmission;
            this.inboundTableData = this.inboundTableData.map((inbound) => {
              return {
                ...inbound,
                dos: this.commonService.getFormattedDateZone(inbound.dos),
              };
            });
          } else if (shortCode == MyQueueClaimStatus.AutoPosting) {
            this.totalPages = Math.ceil(response?.autoPostingCount / this.pageOffset);
            this.inboundTableData = response?.autoPosting;
            this.inboundTableData = this.inboundTableData.map((inbound) => {
              return {
                ...inbound,
                checkDate: this.commonService.getFormattedDateZone(
                  inbound.checkDate
                ),
                itempaymentDetails: inbound.itempaymentDetails.map((item) => {
                  return {
                    ...item,

                    dtDos: this.commonService.getFormattedDateZone(item.dtDos),
                  };
                }),
              };
            });
          } else if (shortCode == MyQueueClaimStatus.ClaimPosting) {
            this.totalPages = Math.ceil(response?.claimPostingCount / this.pageOffset);
            this.inboundTableData = response?.claimPosting;
            this.inboundTableData = this.inboundTableData.map((inbound) => {
              return {
                ...inbound,
                checkDate: this.commonService.getFormattedDateZone(
                  inbound.checkDate
                ),
                processedDate: this.commonService.getFormattedDateZone(
                  inbound.processedDate
                ),

                isCCSettled: inbound.isCCSettled ? 'Settled' : 'Unsettled'
              };
            });
          } else if (shortCode == MyQueueClaimStatus.ClaimChecklist) {
            this.totalPages = Math.ceil(response?.claimChecklistCount / this.pageOffset);
            this.inboundTableData = response?.claimChecklist;
            this.inboundTableData = this.inboundTableData.map((inbound) => {
              return {
                ...inbound,
                chequeDate: this.commonService.getFormattedDateZone(
                  inbound.chequeDate
                ),
                postingDate: this.commonService.getFormattedDateZone(
                  inbound.postingDate
                ),
                //   processedDate: this.commonService.getFormattedDateZone(
                //     inbound.processedDate
                //   ),

                //   isCCSettled : inbound.isCCSettled ? 'Settled' : 'Unsettled'
              };
            });
          }

          if(this.inititalLoading){
          this.menus.forEach((menu) => {
            switch (menu.status) {
              case MyQueueClaimStatusText.ClaimSubmission:
                menu.count = response?.claimSubmissionCount || 0;
                break;
              case MyQueueClaimStatusText.AutoPosting:
                menu.count = response?.autoPostingCount || 0;
                break;
              case MyQueueClaimStatusText.ClaimPosting:
                menu.count = response?.claimPostingCount || 0;
                break;
              case MyQueueClaimStatusText.ClaimChecklist:
                menu.count = response?.claimChecklistCount || 0;
                break;
            }
          });
          this.inititalLoading = false;
        }

          this.loadInboundData = false;
        },
        (err) => {
          this.loadInboundData = false;
        }
      );
  }
}
