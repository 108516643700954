import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import Swal from 'sweetalert2';
import { InsuranceAllowablesService } from '../../item-proxy/item-management/optimization/insurance-allowables.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-insurance-allowables-tableV1',
  templateUrl: './insurance-allowables-tableV1.component.html',
  styleUrls: ['./insurance-allowables-tableV1.component.scss']
})
export class InsuranceAllowablesTableV1Component implements OnInit {
  @Input() insuranceTableData: any;
  @Output() reloadTable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() nevigatePage? = new EventEmitter<number>();
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  dtProductTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    info: true,
    search: {
      smart: false,
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Insurance Allowables List',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
          // columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
      },
    ],
  };
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  constructor(
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
    private insuranceAllowablesService: InsuranceAllowablesService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.dtProductTableOptions.buttons[0].filename =
    'Insurance Allowables List ' +  '-' + this.datepipe.transform(new Date(),'MM-dd-yyyy') ;
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
  }
  gOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.dtProductTableOptions.scrollY =
      screenHeight * availableSpacePercentage;
  };
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  triggerToggle() {
    this.communicationService.triggerInsuranceAllowableToggle();
  }
  getInsAllowablesById(id: string) {
    this.communicationService.triggerInsuranceAllowableGetMethodCallV1(id);
  }
  deleteAllowables(allowableId : string){
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.insuranceAllowablesService.deleteAllowable(allowableId).subscribe(response => {
          this.reloadTable.emit(true);
          this.toastr.success('Deleted Successfully','Success');

          });
        }
        }, err => {
          const data: HttpErrorResponse = err;

          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  addPageNavigationControls(){
    let buttonsElement =  $(".dt-buttons")[0];

    // $("<button class='dt-button' id='btnDefaultClms' type='button'><span>Show Default Column</span></button>").appendTo(buttonsElement);
    // $("<button class='dt-button' id='btnAllClms' type='button'><span>Show All Column</span></button>").appendTo(buttonsElement);

    $("<span> : <span>").appendTo(buttonsElement);
    if(this.currentPage == 1)
    {
      $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
    }
    else
    {
      $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
     }

    $("<span> Page <span>").appendTo(buttonsElement);
    $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);
    $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
    $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);

    if(this.currentPage >= this.totalPages)
    {
      $("<button disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
      $("<button disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }
    else
    {
       $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
       $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    // $(".buttons-colvis").on('click', (event) => this.fnColumnSelectionClick());
    // $('#btnDefaultClms').on('click', (event) => this.fnGetColumnVisiblity("DEFAULT"));
    // $('#btnAllClms').on('click', (event) => this.fnGetColumnVisiblity("ALL"));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    // $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    // $('#btnLastPage').on('click', (event) => this.nevigatePage.emit(this.totalPages));
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) => this.nevigatePage.emit(this.totalPages));
   }
   changePage(isNext:boolean){
    if(isNext)
      this.currentPage++;
    else
      this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }

  fnGotoPage(){
    let pageValue = $("#txtGotoPage").val();
    let pageno = parseInt(pageValue.toString());
    if(pageno > 0 && this.currentPage !=  pageno && pageno <= this.totalPages)
    {
      this.nevigatePage.emit(pageno);
    }
  }

}
