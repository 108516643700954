<table
  id="tblPolicyList"
  datatable
  [dtOptions]="dtInsuranceTabOptions"
  class="tbl hover row-border hover w-100 display"
>
  <thead>
    <tr>
      <th><mat-icon> apps</mat-icon></th>
      <th [matTooltip]="'Payor Level'">Payor Level</th>
      <th [matTooltip]="'Payer Code/Name'">Payer Code/Name</th>
      <th [matTooltip]="'PolicyNo'">PolicyNo</th>
      <th [matTooltip]="'Plantype'">Plantype</th>
      <th [matTooltip]="'Payor Mode'">Payor Mode</th>
      <th [matTooltip]="'Eligibility Type'">Eligibility Type</th>
      <th [matTooltip]="'Verification Status'">Verification Status</th>
      <th [matTooltip]="'Precert'">Precert</th>
      <th [matTooltip]="'Allowed %'">Allowed %</th>
      <th [matTooltip]="'Verified date'" class="text-center">Verified date</th>
      <th [matTooltip]="'VerifiedBy'">VerifiedBy</th>
      <th [matTooltip]="'Verification Expiry'">Verification Expiry</th>
      <th [matTooltip]="'Status'">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let data of policyDetails"
      [ngClass]="{ stat_highlight: data.isStat == true }"
    >
      <td>
        <a class="pr-2">
          <i
            matTooltip="Click to Edit"
            (click)="EditPolicy(data)"
            class="fa fa-pencil editBtnColor"
          ></i>
        </a>
        <a
          [class.disabled]="
            data?.verificationStatus === lstVerificationSttaus.new ||
            data?.verificationStatus === lstVerificationSttausV1.new
          "
          class="pl-2"
        >
          <i
            matTooltip="Click to View Verification"
            (click)="viewVerificationById(data)"
            class="fa fa-eye editBtnColor"
          ></i>
        </a>
      </td>
      <td
        [matTooltip]="data.payorLevel || '-'"
        (dblclick)="onRowDoubleClickV1(data?.payorLevel || '-')"
      >
        <span
          *ngIf="
            data?.payorLevel === InsuranceType.Primary ||
            data?.payorLevel === InsuranceTypeV1.primary
          "
          >{{ InsuranceType.Primary }}</span
        >
        <span
          *ngIf="
            data?.payorLevel === InsuranceType.Secondary ||
            data?.payorLevel === InsuranceTypeV1.secondary
          "
          >{{ InsuranceType.Secondary }}</span
        >
        <span
          *ngIf="
            data?.payorLevel === InsuranceType.Tertiary ||
            data?.payorLevel === InsuranceTypeV1.tertiary
          "
          >{{ InsuranceType.Tertiary }}</span
        >
      </td>
      <td
        [matTooltip]="data.payorName || '-'"
        (dblclick)="onRowDoubleClickV1(data?.payorName || '-')"
      >
        {{ data?.payorName ? data?.payorName : "-" }}
      </td>
      <td
        [matTooltip]="data.policyNo || '-'"
        (dblclick)="onRowDoubleClickV1(data?.policyNo || '-')"
      >
        {{ data?.policyNo ? data?.policyNo : "-" }}
      </td>
      <td
        [matTooltip]="data.typeofPlan || '-'"
        (dblclick)="onRowDoubleClickV1(data?.typeofPlan || '-')"
      >
        {{ data?.typeofPlan ? data?.typeofPlan : "-" }}
      </td>
      <td
        [matTooltip]="data.eligibilityMode || '-'"
        (dblclick)="onRowDoubleClickV1(data?.eligibilityMode || '-')"
      >
        {{
          data?.eligibilityMode === 1
            ? "pverify"
            : data?.eligibilityMode === 2
            ? "Zermid"
            : data.eligibilityMode === 3
            ? "Change Health Care"
            : "Manual"
        }}
      </td>
      <td
        [matTooltip]="
          data?.isEligibilityDone === lstVerificationSttaus.new ||
          data?.isEligibilityDone === lstVerificationSttausV1.new
            ? '-'
            : data?.verificationMethod
        "
        (dblclick)="
          onRowDoubleClickV1(
            data?.isEligibilityDone === lstVerificationSttaus.new ||
              data?.isEligibilityDone === lstVerificationSttausV1.new
              ? '-'
              : data?.verificationMethod
          )
        "
      >
        {{
          data?.isEligibilityDone === lstVerificationSttaus.new ||
          data?.isEligibilityDone === lstVerificationSttausV1.new
            ? "-"
            : data?.verificationMethod
        }}
      </td>
      <td
        [matTooltip]="data.verificationStatus || '-'"
        (dblclick)="onRowDoubleClickV1(data?.verificationStatus || '-')"
      >
        <!-- {{data?.isEligibilityDone?data?.isEligibilityDone:'-'}} -->
        <span
          *ngIf="
            data?.verificationStatus === lstVerificationSttaus.done ||
            data?.verificationStatus === lstVerificationSttausV1.done
          "
          >Done</span
        >
        <span
          *ngIf="
            data?.verificationStatus === lstVerificationSttaus.pending ||
            data?.verificationStatus === lstVerificationSttausV1.pending
          "
          >Pending</span
        >
        <span
          *ngIf="
            data?.verificationStatus === lstVerificationSttaus.unabletoverify ||
            data?.verificationStatus === lstVerificationSttausV1.unabletoverify
          "
          >Unable To Verify</span
        >
        <span
          *ngIf="
            data?.verificationStatus === lstVerificationSttaus.new ||
            data?.verificationStatus === lstVerificationSttausV1.new
          "
          >New</span
        >
        <span class="text-red" *ngIf="(data?.verificationStatus!==lstVerificationSttaus.new)&&
        (data?.verificationStatus!==lstVerificationSttausV1.new) &&
        (data?.verificationStatus!==lstVerificationSttaus.unabletoverify)&&
        (data?.verificationStatus!==lstVerificationSttausV1.unabletoverify)&&
        (data?.verificationStatus!==lstVerificationSttaus.pending)&&
        (data?.verificationStatus!==lstVerificationSttausV1.pending)&&
        (data?.verificationStatus!==lstVerificationSttaus.done)&&
        (data?.verificationStatus!==lstVerificationSttausV1.done)"
        >{{data?.verificationStatus}}</span>
      </td>
      <td
        [matTooltip]="data?.isPrecertRequired ? 'Yes' : 'No' || '-'"
        (dblclick)="
          onRowDoubleClickV1(data?.isPrecertRequired ? 'Yes' : 'No' || '-')
        "
      >
        {{ data?.isPrecertRequired == 1 ? "Yes" : "No" }}
      </td>
      <td
        [matTooltip]="data.allowedPercentage || '-'"
        (dblclick)="onRowDoubleClickV1(data?.allowedPercentage || '-')"
      >
        {{ data?.allowedPercentage ? data.allowedPercentage : "-" }}
      </td>
      <td
        [matTooltip]="getFormattedDate(data?.lastVerifiedDate)"
        (dblclick)="
          onRowDoubleClickV1(getFormattedDate(data?.lastVerifiedDate))
        "
        class="text-center"
      >
        {{ getFormattedDate(data?.lastVerifiedDate) }}
      </td>
      <td
        [matTooltip]="data.lastVerificationName || '-'"
        (dblclick)="onRowDoubleClickV1(data?.lastVerificationName || '-')"
      >
        {{ data?.lastVerificationName ? data?.lastVerificationName : "-" }}
      </td>
      <td
        [matTooltip]="data.verificationExpired || '-'"
        (dblclick)="onRowDoubleClickV1(data?.verificationExpired || '-')"
      >
        {{ data?.verificationExpired ? data?.verificationExpired : "-" }}
      </td>
      <td [matTooltip]="data?.isActive ? 'Active' : 'Inactive' || '-'">
        <ng-container *ngIf="data?.isActive === true">
          <mat-slide-toggle
            (change)="StatusChange($event, data)"
            [checked]="true"
            class="ml-2"
          >
            {{ data?.isActive ? "Active" : "Inactive" }}
          </mat-slide-toggle>
        </ng-container>
        <ng-container *ngIf="data?.isActive === false">
          <mat-slide-toggle
            (change)="StatusChange($event, data)"
            [checked]="false"
            class="ml-2"
          >
            {{ data?.isActive ? "Active" : "Inactive" }}
          </mat-slide-toggle>
        </ng-container>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon> apps</mat-icon></th>

      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Payor Level"
          name="search-PayorLevel"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Payer Code/Name"
          name="search-PayerCode/Name"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="PolicyNo"
          name="search-PolicyNo"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Plantype"
          name="search-Plantype"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Eligibility Mode"
          name="search-EligibilityMode"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Eligibility Type"
          name="search-EligibilityType"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Verification Status"
          name="search-Verification"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Precert"
          name="search-Precert"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Allowed %"
          name="search-Allowed %"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Verified date"
          name="search-Verified date"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Verified By"
          name="search-Verified By"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Verification Expiry"
          name="search-Verification Expiry"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Status"
          name="search-Status"
        />
      </th>
    </tr>
  </tfoot>
</table>
