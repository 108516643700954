import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegionCitiesDTO, RegionCountriesDTO, RegionStatesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/models';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service/region-dropdown.service';
import { defaultCountry, LogoMaxandTypeLimits } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';

import { TenantsService } from '../admin-proxy/platform-app-management/rcm/platform-management/tenant/tenants.service';
import { BlobDTO, CreateTenantOfficialInformationDTO, CreateUpdateFileBatchSettingsDTO, CreateUpdateSettingsDTO, TenantNameEmailValidationDto, UpdateTenantOfficialInformationDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/tenants/models';
import { UsernameEmailValidationDto } from '../admin-proxy/platform-app-management/rcm/platform-management/users/dto';
import { ToggleSwitchComponent } from '../toggle-switch/toggle-switch.component';
import { ToastrService } from "ngx-toastr";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

const defaultUrl = "../../../assets/images/user.png"
@Component({
  selector: 'app-official',
  templateUrl: './official.component.html',
  styleUrls: ['./official.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OfficialComponent),
      multi: true
    }],
})

export class OfficialComponent implements OnInit {
  UserForm: FormGroup;
  panelOpenState = false;
  public strPageType: string = "officialList";
  @Input() clientId: string = '';
  sessionUserId: string = '';
  show: boolean = false;
  saveShow: boolean = true;
  deleteShow: boolean = false;
  showDelete: boolean = true
  hide = true;
  userId: string = '';
  @Input() chkStatus: boolean = false;
  emailMask: any;
  archive: number[] = [];
  itemQuantity: number[] = [];
  @Output() clientIdEmitter: EventEmitter<string> = new EventEmitter<string>();
  isShowSpinner: boolean = false;
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(ToggleSwitchComponent) toggle: Component;
  isImageValid: boolean = true;
  isShownSaveButton: boolean = true;
  showErrorForFileType: boolean = false;
  showErrorForFileLength: boolean = false;
  showErrorBasedOnSize: boolean = false;
  isEditDetails: boolean = false;
  fileAs64Value: string = defaultUrl;
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  phoneCode: string = '';
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;

  drpCountry: RegionCountriesDTO[] = [];
  Country: string = "";
  State: string = "";
  City: string = "";
  checked: boolean;
  
  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue = formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }
  
  constructor(private formBuilder: FormBuilder,
    private router: Router, private route: ActivatedRoute,
    private officialService: TenantsService,
    private Table: TableService,
    private regionDropdownService: RegionDropdownService,
    private toastr: ToastrService,

  ) { }


  ngOnInit(): void {

    this.sessionUserId = this.route.snapshot.params.id;
    this.sessionUserId ? this.isEditDetails = true : this.isEditDetails = false
    this.Table.setClientTable(null);

    //!create
    this.Table.getTenantCreate().subscribe(value => {
      if (value == true) {
        this.isShownSaveButton = true
      } else {
        this.isShownSaveButton = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    //!update
    this.Table.getTenantUpdate().subscribe(value => {
      if (value == true) {
        this.isShownSaveButton = true
      } else {
        this.isShownSaveButton = false;
      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })

    //! delete
    this.Table.getTenantDelete().subscribe(value => {
      if (value == true) {

        this.showDelete = true;
        if (this.sessionUserId != null) {
          this.showDelete = true;
        } else {
          this.showDelete = false;
        }
      } else {

        this.deleteShow = false;

      }
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })

    this.UserForm = this.formBuilder.group({
      txtCompanyName: new FormControl("", Validators.compose([Validators.required,]), this.checkTenantNameUniqness()),
      txtEmail: new FormControl("", Validators.compose([Validators.required, Validators.email]), this.checkEmailUniqness()),
      txtCEO: new FormControl(""),
      txtTelephone: new FormControl(""),
      txtLocation: new FormControl(""),
      txtAddress: new FormControl(""),
      drpCountry: new FormControl(""),
      txtCountryFilter: new FormControl(""),
      txtState: new FormControl(""),
      txtStateFilter: new FormControl(""),
      txtCity: new FormControl(""),
      txtCityFilter: new FormControl(""),
      txtPostalCode: new FormControl(""),
      txtTaxId: new FormControl("", [Validators.required],this.taxIdUniqness()),
      // txtWebSite: new FormControl(""),
      txtWebSite: ['', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      // txtPassword: new FormControl("", [Validators.required]),
      txtPassword: new FormControl("", this.getPasswordValidators()),
      txtDltReason: new FormControl(""),
      imgLogo: new FormControl(null),
      //  drpArchieve: new FormControl(30),
      chkDocumentRule: new FormControl(""),
      txtClearingHouse:new FormControl("")



    })

    this.UserForm.patchValue({
      txtTelephone: "123456"
    });
    this.UserForm.patchValue({
      txtTelephone: ""
    });
    if (this.clientId == "" || this.clientId == null) {
      this.Table.getClientData().subscribe(value => {

        this.clientId = value
      })
    }
    this.clientId !== "" && this.clientId !== undefined && this.clientId !== null && this.Table.getOrderId().subscribe(val => {
      this.userId = val;
      if (this.userId != "") {
        localStorage.setItem('initialUserId', this.userId);
      }

    })
    this.clientId !== "" && this.clientId !== undefined && this.clientId !== null && this.getClientById()
    this.emailMask = emailMask;
    // this.getQuantity();
    this.itemQuantity = Array(100)?.fill(0)?.map((x, i) => i + 1) ?? [];

    this.countryDropdown();
  }
  getPasswordValidators() {
    if (this.sessionUserId) {
      return [];
    } else {
      return [Validators.required];
    }
  }
  checkTenantNameUniqness(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      const input: TenantNameEmailValidationDto = {
        tenantName: String(control?.value ?? "")?.toLowerCase()?.trim() ?? "",
        emailAddress: "",
        tenantId:this.clientId,
        isTenantNameExists: false,
        isEmailAddressExists: false
      };
      return this.officialService.checkTenantNameExistsByInput(input)
        .pipe(
          tap(x => control?.setErrors({ 'isTenantNameExists': true })),
          debounceTime(300),
          map(response => response?.isTenantNameExists === true ? { 'isTenantNameExists': true } : (control?.setErrors(null) == null) ? null : null));
    }
  }
  checkEmailUniqness(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      const input: TenantNameEmailValidationDto = {
        tenantName: "",
        emailAddress: String(control?.value ?? "")?.toLowerCase()?.trim() ?? "",
        tenantId:this.clientId,
        isTenantNameExists: false,
        isEmailAddressExists: false
      };
      return this.officialService.checkEmailIfExistsByInput(input)
        .pipe(
          tap(x => control?.setErrors({ 'isEmailAddressExists': true })),
          debounceTime(300),
          map(response => response?.isEmailAddressExists === true ? { 'isEmailAddressExists': true } : (control?.setErrors(null) == null) ? null : null));
    }
  }
  taxIdUniqness() :AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return this.officialService.checkTaxIdIfExistsByInput(String(control?.value ?? "")?.toLowerCase()?.trim() ?? "",this.clientId)
        .pipe(
          tap(x => control?.setErrors({ 'isTaxIdExists': true })),
          debounceTime(300),
          map(response => response === true ? { 'isTaxIdExists': true } : (control?.setErrors(null) == null) ? null : null));
    }
  }
  // load archive dropdwon
  getQuantity() {
    for (let i = 1; i <= 100; i++) {
      this.archive.push(i)

    }
  }
  // Get Country Dropdown
  countryDropdown() {
    this.UserForm.patchValue({ "drpCountry": defaultCountry.US })
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;
    this.drpCountry = [];
    this.regionDropdownService.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      //
      this.drpCountry = response?.items ?? [];
      this.filteredCountries = this.UserForm?.get("txtCountryFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCountry.filter(option => option?.countryName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.UserForm.patchValue({
      txtState: null,
      txtCity: null
    })
    this.drpStates = [];
    this.drpCities = [];
    value !== null && value !== undefined && this.regionDropdownService.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {

      this.drpStates = response?.items ?? [];

      if (response?.items?.length === 0) {
        this.phoneCode = "";
        this.UserForm.controls['txtTelephone'].disable();
      } else {
        this.UserForm.controls['txtTelephone'].enable();
        this.phoneCode = this.drpStates[0]?.countryPhoneCode ?? "";
      }
      this.filteredStates = this.UserForm?.get("txtStateFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpStates.filter(option =>
            option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
          ))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    return this.drpStates.length !== 0;
  }
  // On Change City Dropdown

  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.UserForm.patchValue({
      txtCity: null
    });
    country = (country == "") ? (this.UserForm.value.drpCountry == (null || undefined) ? "" : (this.UserForm.value.drpCountry)) : country;
    this.drpCities = [];
    state !== null && state !== undefined && this.regionDropdownService.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {

      this.drpCities = response?.items ?? [];
      this.filteredCities = this.UserForm?.get("txtCityFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpCities.filter(option => option?.cityName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    return this.drpCities.length !== 0;
  }
  onFileSelected(value: File) {
    if (value.size >= LogoMaxandTypeLimits.documentFileSizeLimit) {
      this.showErrorForFileType = false;
      this.showErrorBasedOnSize = true;
      this.isImageValid = false;
      return;
    };
    if ("image/jpeg" !== value.type) {
      this.showErrorForFileType = true;
      this.showErrorBasedOnSize = false;
      this.isImageValid = false;
      return;
    }
    this.showErrorForFileType = false;
    this.showErrorBasedOnSize = false;
    this.isImageValid = true; 
    let reader = new FileReader();
    this.fileAs64Value = "";
    reader.readAsDataURL(value);
    reader.onload = () => {


      this.fileAs64Value = reader?.result?.toString() ?? "";
    }
  }
  
  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHander(event) {
  //   return false;
  // }



  saveOfficial() {
    this.isShowSpinner = true;
    let arch: CreateUpdateFileBatchSettingsDTO = {
      archivedPeriod: 30// this.UserForm.value.drpArchieve == 0 ? 30 : this.UserForm.value.drpArchieve
    }

    let setting: CreateUpdateSettingsDTO = {
      fileBatchSettings: arch,
      paymentSettings: null,
      subscriptionSettings: null
    };



    if ((this.clientId === "" || this.clientId === undefined || this.clientId === null)) {

      let official: CreateTenantOfficialInformationDTO = {
        ceoName: this.UserForm.value.txtCEO,
        phoneNumber: this.UserForm.value.txtTelephone,
        location: this.UserForm.value.txtLocation,
        website: this.UserForm.value.txtWebSite,
        adminEmailAddress: this.UserForm.value.txtEmail.toLowerCase(),
        adminPassword: this.UserForm.value.txtPassword,
        name: this.UserForm.value.txtCompanyName,
        extraProperties: {},
        settings: setting,
        logoAsBase64String: this.fileAs64Value === defaultUrl ? "" : this.fileAs64Value?.slice(23)?.toString() ?? "",
        country: this.UserForm.value.drpCountry,
        state: this.UserForm.value.txtState,
        city: this.UserForm.value.txtCity,
        address: this.UserForm.value.txtAddress,
        postalCode: this.UserForm.value.txtPostalCode,
        taxId: this.UserForm.value.txtTaxId,
        isDocumentRuleCheck: +(this.UserForm.value.chkDocumentRule ?? 0) || 1,
        clearingHouse: Number(this.UserForm.value.txtClearingHouse?this.UserForm.value.txtClearingHouse:1)
      };

      this.officialService.saveTenantWithOfficialInformation(official).subscribe(response => {
        const responseObject = JSON.parse(response);
          this.clientId=responseObject.tenantId;
          localStorage.setItem('initialUserId', responseObject.userId);
          this.userId=localStorage.getItem("initialUserId");
        this.isShowSpinner = false;
        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
          this.router.navigate(['clientList']);
          if (result.value) {
            //!change next tab
            this.nextTabMoveOnSaveEmitter.emit("Official");
          }
        }, err => { });
        this.Table.setClientTable(responseObject.tenantId);
        this.clientIdEmitter.emit(responseObject.tenantId);
        // this.reset();
      }, err => {
        const data: HttpErrorResponse = err;
        this.isShowSpinner = false;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isShowSpinner = false;
      });
    }
    else {
      this.userId = localStorage.getItem("initialUserId")??this.userId;

      let officialUpdate: UpdateTenantOfficialInformationDTO = {
        ceoName: this.UserForm.value.txtCEO,
        phoneNumber: this.UserForm.value.txtTelephone,
        location: this.UserForm.value.txtLocation,
        website: this.UserForm.value.txtWebSite,
        adminEmailAddress: this.UserForm.value.txtEmail.toLowerCase(),
        name: this.UserForm.value.txtCompanyName,
        settings: setting,
        country: this.UserForm.value.drpCountry,
        state: this.UserForm.value.txtState,
        city: this.UserForm.value.txtCity,
        address: this.UserForm.value.txtAddress,
        postalCode: this.UserForm.value.txtPostalCode,
        taxId: this.UserForm.value.txtTaxId,
        //  this.UserForm.value.drpCountry,
        // this.UserForm.value.txtState,
        // this.UserForm.value.txtCity,
        //this.UserForm.value.txtAddress,
        // this.UserForm.value.txtPostalCode,
        logoAsBase64String: this.fileAs64Value === defaultUrl ? "" : this.fileAs64Value?.slice(23)?.toString() ?? "",
        isDocumentRuleCheck: +(this.UserForm.value.chkDocumentRule ?? 0) || 1,
        clearingHouse: Number(this.UserForm.value.txtClearingHouse?this.UserForm.value.txtClearingHouse:1)
      };

      this.officialService.updateTenantWithOfficialInformation(this.clientId, this.userId, officialUpdate).subscribe(response => {
        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
          this.router.navigate(['clientList']);
          if (result.value) {
            //!change next tab
            this.nextTabMoveOnSaveEmitter.emit("Official");
          }
        }, err => { });
        this.isShowSpinner = false;

        this.Table.setClientTable(response);
        this.clientIdEmitter.emit(response);


        // this.reset();
      }, err => {
        this.isShowSpinner = false;

        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isShowSpinner = false;
      });

    }

  }
  //! edit officical details
  getClientById() {

    this.officialService.getTenantInformationById(this.clientId).subscribe(response => {
      // this.UserForm.controls['txtPassword'].disable()
      const logoBlobName: string = response.logoBlobName ?? "";
      logoBlobName && this.getLogoByLogoBlobName(logoBlobName);
      this.State = response?.state ?? '';
      this.Country = response?.country ?? '';
      this.Country != null && this.Country !== "" && this.Country != undefined && this.onChangeState(this.Country) && this.UserForm.patchValue({
        drpBillingCountry: this.Country,
      });
      this.State != null && this.State !== "" && this.State != undefined && this.onChangeCity(this.State, this.Country) && this.UserForm.patchValue({
        txtBillingState: this.State,
        txtBillingCity: response?.city
      });
      this.UserForm.patchValue({
        txtCompanyName: response?.tenantName,
        txtEmail: response?.adminEmailAddress,
        txtCEO: response?.ceoName,
        txtTelephone: response?.phoneNumber,
        txtLocation: response?.location,
        txtWebSite: response?.website,
        txtDltReason: response?.reasonForInactive,
        drpArchieve: 30,//response?.settings?.fileBatchSettings?.archivedPeriod == 0 ? 30 : response?.settings?.fileBatchSettings?.archivedPeriod,
        txtAddress: response?.address,
        drpCountry: response?.country,
        txtState: response?.state,
        txtCity: response?.city,
        txtPostalCode: response?.postalCode,
        txtTaxId: response?.taxId,
        txtClearingHouse: response?.clearingHouse
      })
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  clearCountry() {
    this.UserForm.controls['txtTelephone'].disable();
    this.Country = "";
    this.UserForm.patchValue({ drpCountry: "" })
  }

  clearState() {
    this.State = "";
    this.UserForm.patchValue({ txtState: "" })
  }

  clearCity() {
    this.City = "";
    this.UserForm.patchValue({ txtCity: "" })
  }


  getLogoByLogoBlobName(logoBlobName: string) {
    if (logoBlobName && typeof logoBlobName === "string" && logoBlobName !== "") {
      const blobData: BlobDTO = {
        tenantId: this.clientId,
        blobName: logoBlobName
      }
      this.officialService.getLogoByBlobNameByBlobDetails(blobData).subscribe(response => {


        if (response) {
          this.fileAs64Value = String("data:image/jpeg;base64," + response);
        } else {
          this.fileAs64Value = defaultUrl;
        }
      }, error => {
        const data: HttpErrorResponse = error;
        this.fileAs64Value = defaultUrl;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }
  }
  //! inactive officical details
  inactiveField() {
    this.show = true
    this.saveShow = false
    this.deleteShow = true
    this.UserForm.controls['txtCompanyName'].disable()
    this.UserForm.controls['txtEmail'].disable()
    this.UserForm.controls['txtCEO'].disable()
    this.UserForm.controls['txtTelephone'].disable()
    this.UserForm.controls['txtLocation'].disable()
    this.UserForm.controls['txtWebSite'].disable()
    this.UserForm.controls['txtPassword'].disable()
    this.UserForm.controls['txtDltReason'].disable()
    this.UserForm.controls['drpArchieve'].disable()
    this.UserForm.controls['txtAddress'].disable()
    this.UserForm.controls['drpCountry'].disable()
    this.UserForm.controls['txtCountryFilter'].disable()
    this.UserForm.controls['txtState'].disable()
    this.UserForm.controls['txtCity'].disable()
    this.UserForm.controls['txtCityFilter'].disable()
    this.UserForm.controls['txtPostalCode'].disable()
    this.UserForm.controls['txtTaxId'].disable()
    this.UserForm.controls['chkDocumentRulde'].disable()

  }
  change(event: boolean) {
    if (event == true) {
      this.show = true
      this.saveShow = false
      this.deleteShow = true
      this.UserForm.controls['txtCompanyName'].disable()
      this.UserForm.controls['txtEmail'].disable()
      this.UserForm.controls['txtCEO'].disable()
      this.UserForm.controls['txtTelephone'].disable()
      this.UserForm.controls['txtLocation'].disable()
      this.UserForm.controls['txtWebSite'].disable()
      this.UserForm.controls['txtPassword'].disable()
      this.UserForm.controls['drpArchieve'].disable()
      this.UserForm.controls['txtAddress'].disable()
      this.UserForm.controls['drpCountry'].disable()
      this.UserForm.controls['txtCountryFilter'].disable()
      this.UserForm.controls['txtState'].disable()
      this.UserForm.controls['txtCity'].disable()
      this.UserForm.controls['txtCityFilter'].disable()
      this.UserForm.controls['txtPostalCode'].disable()
      this.UserForm.controls['txtTaxId'].disable()
      this.UserForm.controls['chkDocumentRulde'].disable()
    } else {
      this.show = false
      this.saveShow = true
      this.deleteShow = false
      this.UserForm.controls['txtCompanyName'].enable()
      this.UserForm.controls['txtEmail'].enable()
      this.UserForm.controls['txtCEO'].enable()
      this.UserForm.controls['txtTelephone'].enable()
      this.UserForm.controls['txtLocation'].enable()
      this.UserForm.controls['txtWebSite'].enable()
      this.UserForm.controls['txtPassword'].enable()
      this.UserForm.controls['txtPostalCode'].enable()
      //this.UserForm.controls['drpArchieve'].disable()
    }
    if (event == false) {
      this.activateClient();
    }
  }
  //! active officical details
  activateClient() {

    Swal.fire({
      title: 'Are You Sure want to Activate Client?',
      text: 'Please Select Yes or No !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No',
      allowOutsideClick: false,

    }).then(result => {

      if (result.isConfirmed == true) {
        this.chkStatus = false;
        this.officialService.updateTenantToActive(this.clientId).subscribe(response => {


          // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Activated Successfully','Success')
        }
          , err => {
            const data: HttpErrorResponse = err;

            Swal.fire({
              icon: 'info',
              text: "Sorry!! There is no deactive tenant in this Id!",
            });
          })
        this.router.navigate(['clientList']);
        return
      } if (result.isDismissed || result.isDenied) {
        this.chkStatus = true;
        // this.router.navigate(['clientList']);

        this.toggle['chkValue'] = this.chkStatus;
        this.show = true
        this.saveShow = false
        this.deleteShow = true
        this.UserForm.controls['txtCompanyName'].disable()
        this.UserForm.controls['txtEmail'].disable()
        this.UserForm.controls['txtCEO'].disable()
        this.UserForm.controls['txtTelephone'].disable()
        this.UserForm.controls['txtLocation'].disable()
        this.UserForm.controls['txtWebSite'].disable()
        this.UserForm.controls['txtPassword'].disable()
        this.UserForm.controls['txtDltReason'].disable()
        this.UserForm.controls['drpArchieve'].disable()
        this.UserForm.controls['chkDocumentRule'].disable()
        return
      }
      this.toggle.inputs;

      //if (result.isDismissed || result.isDenied) {
      this.chkStatus = true;
      this.toggle['chkValue'] = this.chkStatus;
      return
      //}


      // }
    });

  }


  // }

  //! delete officical details

  deleteOfficial() {
    const sReasonForDeactive = this.UserForm.value.txtDltReason
    this.officialService.deleteTenantInformation(this.clientId, sReasonForDeactive).subscribe(response => {
      // Swal.fire({ title: 'Success', html: 'Deleted Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      this.toastr.success('Deleted Successfully','Success')
      // this.reset();
      this.router.navigate(['clientList']);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error.error.validationErrors[0].message,
      });
    });

  }
  //! reset form officical details
  reset() {
    this.UserForm.patchValue({
      txtCompanyName: "",
      txtEmail: "",
      txtCEO: "",
      txtTelephone: "",
      txtLocation: "",
      txtWebSite: "",
      txtPassword: "",
      txtDltReason: "",
      drpArchieve: "",
      txtAddress: "",
      drpCountry: "",
      txtCountryFilter: "",
      txtState: "",
      txtStateFilter: "",
      txtCity: "",
      txtCityFilter: "",
      txtPostalCode: "",
      txtTaxId:"",
      imgLogo: "",
      cheDocumentRule: ""

    })
    this.UserForm.markAsUntouched();
    this.UserForm.updateValueAndValidity();
    this.showErrorForFileType = false;
    this.showErrorBasedOnSize = false;
    this.fileAs64Value = defaultUrl;
  }

}


export interface error {
  error: {
    code: string,
    message: string,
    details: string,
    data: {},
    validationErrors: {}
  }
  code: string,
  message: string,
  details: string,
  data: {},
  validationErrors: {}
}
