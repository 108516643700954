<!-- Inbound Doc Assigning  -->
<mat-dialog-content class="mat-typography">
  <div class="row align-items-center">
    <div class="col-lg-9">
      <h3 class="customThemeClass mt-2">
        <b>Document Allocation - {{ data.defaultDocumentId }}</b>
      </h3>
    </div>
    <div class="col-lg-3" style="text-align-last: right">
      <div>
        <button
          [disabled]="dataSource && dataSource?.length === 0"
          mat-button
          class="buttonColor mr-2 font-size-14 pl-2"
          (click)="approveDocument()"
        >
          <i class="mdi mdi-plus mr-1"></i>Complete Document Process
        </button>
        <button mat-icon-button mat-dialog-close class="close-button">
          <mat-icon class="mr-2">close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <form [formGroup]="patientSearchForm">
        <!--Patient Search -->
        <mat-form-field class="col-lg-12">
          <mat-label> Search Patient <span class="asterisk">*</span></mat-label>
          <mat-select
            formControlName="drpSearchPatient"
            (selectionChange)="loadPatientForm()"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No Matches found"
                formControlName="txtPatientFilter"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option [value]="defaultGuid"> New Patient </mat-option>
            <mat-option [value]="0"> File Missing </mat-option>
            <mat-option
              *ngFor="let patients of filteredPatients | async"
              [value]="patients.guid"
            >
              {{ patients.idAndNameAndDOB }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <div class="row" *ngIf="isTabVisible">
        <div class="col-xl-12 pb-2">
          <ul
            class="card card-body"
            ngbNav
            #nav="ngbNav"
            justify="justified"
            class="alert-success nav nav-pills nav-fill"
            style="border-bottom: 2px solid rgb(201, 201, 201)"
          >
            <li
              ngbNavItem="DemoGraphics"
              [class.selected-tab]="nav.activeId === 'DemoGraphics'"
            >
              <a ngbNavLink (click)="changeTab('DemoGraphics')"><b>DemoGraphics</b></a>
            </li>
            <li
              ngbNavItem="Plans"
              [class.selected-tab]="nav.activeId === 'Plans'"
            >
              <a ngbNavLink id="tab-3" (click)="changeTab('Plans')"
                ><b>Plans</b></a
              >
            </li>
          </ul>
        </div>
      </div>

      <form [formGroup]="documentAssignForm" *ngIf="isFormVisible">
        <!-- First Name -->
        <div *ngIf="activeTab === 'DemoGraphics'">
          <mat-form-field class="col-12">
            <mat-label> Chart <span class="asterisk">*</span></mat-label>
            <input
              matInput
              maxlength="40"
              formControlName="txtChart"
              type="text"
              autocomplete="new-txtChart"
            />
            <mat-error
              class="spanAlignment"
              *ngIf="
                documentAssignForm?.get('txtChart')?.touched &&
                documentAssignForm?.get('txtChart')?.errors?.required
              "
            >
              First Name is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-4">
            <mat-label> First Name <span class="asterisk">*</span></mat-label>
            <input
              matInput
              maxlength="40"
              formControlName="txtFirstName"
              type="text"
              autocomplete="new-txtFirstName"
            />
            <mat-error
              class="spanAlignment"
              *ngIf="
                documentAssignForm?.get('txtFirstName')?.touched &&
                documentAssignForm?.get('txtFirstName')?.errors?.required
              "
            >
              First Name is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Middle Name -->
          <mat-form-field class="col-4">
            <mat-label>Middle Name</mat-label>
            <input
              matInput
              maxlength="40"
              formControlName="txtMiddleName"
              type="text"
              autocomplete="new-txtMiddleName"
            />
          </mat-form-field>

          <!-- Last Name-->
          <mat-form-field class="col-4">
            <mat-label> Last Name <span class="asterisk">*</span></mat-label>
            <input
              matInput
              maxlength="40"
              formControlName="txtLastName"
              type="text"
              autocomplete="new-txtLastName"
            />
            <mat-error
              class="spanAlignment"
              *ngIf="
                documentAssignForm?.get('txtLastName')?.touched &&
                documentAssignForm?.get('txtLastName')?.errors?.required
              "
            >
              Last Name is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Gender -->
          <mat-form-field class="col-4">
            <mat-label>Gender<span class="asterisk">*</span></mat-label>
            <mat-select
              formControlName="drpGender"
              autocomplete="new-drpGender"
              [(value)]="gender"
              maxlength="40"
            >
              <mat-option
                *ngFor="let gender of drpGenderLoop"
                [value]="gender.genderShortCode"
              >
                {{ gender.genderName }}
              </mat-option>
            </mat-select>
            <mat-error
              class="spanAlignment"
              *ngIf="
                documentAssignForm?.get('drpGender')?.touched &&
                documentAssignForm?.get('drpGender')?.errors?.required
              "
            >
              Gender is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Birth Date -->
          <mat-form-field class="col-4">
            <mat-label> Birth Date <span class="asterisk">*</span></mat-label>
            <input
              autocomplete="new-txtBirthdate"
              [max]="BirthdateMaxDate"
              formControlName="txtBirthdate"
              matInput
              maxlength="40"
              type="text"
              placeholder="(MM/DD/YYYY)"
              [matDatepicker]="txtBirthdate"
              [min]="BirthdateMinDate"
            />
            <mat-datepicker-toggle matSuffix [for]="txtBirthdate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtBirthdate> </mat-datepicker>
            <mat-error
              *ngIf="
                documentAssignForm?.get('txtBirthdate')?.touched &&
                documentAssignForm?.get('txtBirthdate')?.errors &&
                !(
                  BirthdateMinDate <=
                  documentAssignForm?.get('txtBirthdate')?.value
                )
              "
            >
              Enter Valid date
            </mat-error>
            <mat-error
              *ngIf="
                documentAssignForm?.get('txtBirthdate')?.touched &&
                documentAssignForm?.get('txtBirthdate')?.errors?.required
              "
            >
              Birth Date is a required field!
            </mat-error>
            <mat-error
              *ngIf="
                BirthdateMinDate <=
                  documentAssignForm?.get('txtBirthdate')?.value &&
                !(
                  documentAssignForm?.get('txtBirthdate')?.touched &&
                  documentAssignForm?.get('txtBirthdate')?.errors?.required
                )
              "
            >
              Enter Past Dates Only!
            </mat-error>
          </mat-form-field>

          <!-- Email-->
          <mat-form-field class="col-4">
            <mat-label> Email <span class="asterisk">*</span></mat-label>
            <input
              matInput
              maxlength="40"
              type="text"
            />
            <!-- <mat-error
              class="spanAlignment"
              *ngIf="
                documentAssignForm?.get('txtLastName')?.touched &&
                documentAssignForm?.get('txtLastName')?.errors?.required
              "
            >
              Last Name is a required field!
            </mat-error> -->
          </mat-form-field>

           <!-- Street-->
           <mat-form-field class="col-12">
            <mat-label> Street <span class="asterisk">*</span></mat-label>
            <input
              matInput
              maxlength="40"
              type="text"
            />
            <!-- <mat-error
              class="spanAlignment"
              *ngIf="
                documentAssignForm?.get('txtLastName')?.touched &&
                documentAssignForm?.get('txtLastName')?.errors?.required
              "
            >
              Last Name is a required field!
            </mat-error> -->
          </mat-form-field>

          <!-- State-->
          <mat-form-field class="col-4">
            <mat-label>State</mat-label>
            <mat-select formControlName="txtState" (selectionChange)='onChangeCity($event.value,"")' [(value)]="State"
              #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="txtStateFilter"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let state of filteredStates | async" [value]="state.stateShortName">
                {{ state.stateName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="State='';$event.stopPropagation()" *ngIf="State" (click)="clearState()"
              matSuffix mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <!-- City-->
          <mat-form-field class="col-4">
            <mat-label>City</mat-label>
            <mat-select formControlName="txtCity" [(value)]="City" #singleSelect>
              <mat-option>
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  formControlName="txtCityFilter"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityName">
                {{ city.cityName }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix (click)="clearCity()"
              mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col-4">
            <mat-label> Zip Code </mat-label>
            <input
              matInput
              maxlength="10"
              formControlName="txtBillingPostalCode"
              pattern="[0-9]*"
              type="text"
            />
            <mat-error
              *ngIf="
                documentAssignForm?.get('txtBillingPostalCode')?.touched &&
                documentAssignForm?.get('txtBillingPostalCode')?.errors
                  ?.pattern
              "
            >
              Enter only Numbers!
            </mat-error>
          </mat-form-field>

          <!-- Home No-->
          <mat-form-field class="col-4">
            <mat-label> Home No <span class="asterisk">*</span></mat-label>
            <input
              matInput
              maxlength="40"
              (input)="phoneNo()"
              formControlName="txtHomeNo"
              type="text"
              mask="(000)-000-0000"
              prefix="{{ phoneCode }}-"
            />
            <mat-error
              class="spanAlignment"
              *ngIf="
                documentAssignForm?.get('txtHomeNo')?.touched &&
                documentAssignForm?.get('txtHomeNo')?.errors?.required
              "
            >
              Home No is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Cell No-->
          <mat-form-field class="col-4">
            <mat-label> Cell No <span class="asterisk">*</span></mat-label>
            <input
              matInput
              maxlength="40"
              (input)="phoneNo()"
              formControlName="txtCellNo"
              type="text"
              mask="(000)-000-0000"
              prefix="{{ phoneCode }}-"
            />
            <mat-error
              class="spanAlignment"
              *ngIf="
                documentAssignForm?.get('txtCellNo')?.touched &&
                documentAssignForm?.get('txtCellNo')?.errors?.required
              "
            >
              Cell No is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Work No-->
          <mat-form-field class="col-4">
            <mat-label> Work No <span class="asterisk">*</span></mat-label>
            <input
              matInput
              maxlength="40"
              (input)="phoneNo()"
              formControlName="txtWorkNo"
              type="text"
              mask="(000)-000-0000"
              prefix="{{ phoneCode }}-"
            />
            <mat-error
              class="spanAlignment"
              *ngIf="
                documentAssignForm?.get('txtWorkNo')?.touched &&
                documentAssignForm?.get('txtWorkNo')?.errors?.required
              "
            >
            Work No is a required field!
            </mat-error>
          </mat-form-field>

          <!-- CSR -->
          <!-- <mat-form-field class="col-4">
            <mat-label>CSR<span class="asterisk"> *</span></mat-label>
            <mat-select formControlName="userToBeAssigned" [(value)]="selUserId">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtUserToBeAssignedFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let users of filteredIdentityUsers | async"
                [value]="users.userId"
              >
                {{ users.name }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

          <div class="col-1">
            <mat-checkbox formControlName="chkStat">Stat</mat-checkbox>
          </div>

          <!--Comments-->
          <mat-form-field class="col-12">
            <mat-label>Notes</mat-label>
            <textarea
              matInput
              rows="4"
              autocomplete="new-txtComments"
              maxlength="5000"
              formControlName="txtComments"
              [(ngModel)]="notesText"
              (input)="updateCharacterCount()"
              type="text"
            ></textarea>
            <div class="char-count">{{ notesText?.length }} / 5000</div>

          </mat-form-field>

          <div >
           <div class="col-12"><mat-label ><b>Referral Tracking</b></mat-label></div>
            <mat-form-field class="col-6">
              <mat-label>Referral</mat-label>
              <mat-select formControlName="txtCity" [(value)]="City" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtCityFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option  [value]="0">
                sample Data
                </mat-option>
              </mat-select>
              <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix (click)="clearCity()"
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-6">
              <mat-label>Physician</mat-label>
              <mat-select formControlName="txtCity" [(value)]="City" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtCityFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="0">
                  Test Data
                </mat-option>
              </mat-select>
              <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix (click)="clearCity()"
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <div class="col-12"> <button
              mat-button
              class="buttonColor mb-1 mr-2"


            >
              search Referral
            </button></div>


              <mat-form-field class="col-6">
                <mat-label>Service Type</mat-label>
                <mat-select formControlName="txtCity" [(value)]="City" #singleSelect>
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                      formControlName="txtCityFilter"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option  [value]="0">
                    Sample
                  </mat-option>
                </mat-select>
                <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix (click)="clearCity()"
                  mat-icon-button>
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

          </div>

          <!-- Buttons -->
          <div class="row pb-2">
            <div class="col-lg-12 d-flex justify-content-center">
              <button
                mat-button
                class="buttonColor mb-1 mr-2"
                [disabled]="documentAssignForm?.invalid "
                (click)="assignDocument()"
              >
                Allocate
              </button>
              <!-- !(!documentAssignForm?.invalid && documentAssignForm?.dirty) -->
              <button
                mat-button
                class="resetclr buttonColor mb-1 mr-2"
                (click)="resetForm()"
              >
                Reset
              </button>
              <!-- <button mat-button class="resetclr buttonColor mb-1 mr-2" (click)="testMethod()" >Test</button> -->
            </div>
          </div>
        </div>

        <div *ngIf="activeTab === 'Plans'">
          <mat-accordion #PrimaryAccordion="matAccordion">
            <mat-expansion-panel [(expanded)]="PrimarypanelOpenState">
              <mat-expansion-panel-header>
                <mat-panel-title class="customThemeClass">
                  <ng-container>
                    <b>Primary Insurance</b>
                  </ng-container>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-card-content>
                <div class="row">
                  <mat-form-field class="col-lg-12">
                    <mat-label>Select Payor<span class="asterisk">*</span></mat-label>
                    <mat-select formControlName="drpselectPayor" (selectionChange)="onSelectPayor($event)"  >
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Payer Id / Name / Code" noEntriesFoundLabel="No Matches found"
                          formControlName="selectPayor"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let payor of filteredSelectPayers | async" [title]="payor?.payerShortCodeList" [value]="payor?.payerShortCodeList">
                        {{ payor?.payerShortCodeList }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                        documentAssignForm?.get('drpselectPayor')?.touched &&
                        documentAssignForm?.get('drpselectPayor')?.errors?.required
                      ">
                      Payer Id is a required field!
                    </mat-error>
                    <!-- <button mat-button (click)="zirmedPayor = '';
                     $event.stopPropagation()" *ngIf="zirmedPayor" matSuffix mat-icon-button>
                      <mat-icon>close</mat-icon>
                    </button> -->
                  </mat-form-field>
                  <mat-form-field class="col-sm-6">
                    <mat-label>Policy No<span class="asterisk">*</span></mat-label>
                    <input maxlength="20" matInput type="text" pattern="[a-zA-Z0-9]*" />
                    <!-- <mat-error *ngIf="
                        policyForm?.get('txtPolicyNo')?.touched &&
                        policyForm?.get('txtPolicyNo')?.errors?.pattern
                      ">
                      Enter Only Alphanumeric Only!
                    </mat-error>
                    <mat-error *ngIf="
                        policyForm?.get('txtPolicyNo')?.touched &&
                        policyForm?.get('txtPolicyNo')?.errors?.required
                      ">
                      Policy No is a required field!
                    </mat-error> -->
                  </mat-form-field>
                  <mat-form-field class="col-sm-6">
                    <mat-label>Group No<span class="asterisk">*</span></mat-label>
                    <input maxlength="20" matInput type="text" pattern="[a-zA-Z0-9]*" />
                    <!-- <mat-error *ngIf="
                        policyForm?.get('txtPolicyNo')?.touched &&
                        policyForm?.get('txtPolicyNo')?.errors?.pattern
                      ">
                      Enter Only Alphanumeric Only!
                    </mat-error>
                    <mat-error *ngIf="
                        policyForm?.get('txtPolicyNo')?.touched &&
                        policyForm?.get('txtPolicyNo')?.errors?.required
                      ">
                      Policy No is a required field!
                    </mat-error> -->
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-expansion-panel> </mat-accordion>

          <br/>
          <mat-accordion #SecondaryAccordion="matAccordion">
            <mat-expansion-panel [(expanded)]="SecpanelOpenState">
              <mat-expansion-panel-header>
                <mat-panel-title class="customThemeClass">
                  <ng-container>
                    <b>Secondary Insurance</b>
                  </ng-container>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-card-content>
                <div class="row">
                  <mat-form-field class="col-lg-12">
                    <mat-label>Select Payor<span class="asterisk">*</span></mat-label>
                    <mat-select>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Payer Id / Name / Code" noEntriesFoundLabel="No Matches found"
                          formControlName="selectSecPayor"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let payor of filteredSelectSecPayers | async" [title]="payor?.payerShortCodeList" [value]="payor?.payerShortCodeList">
                        {{ payor?.payerShortCodeList }}
                      </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="
                        policyForm?.get('drpselectPayor')?.touched &&
                        policyForm?.get('drpselectPayor')?.errors?.required
                      ">
                      Payer Id is a required field!
                    </mat-error> -->

                  </mat-form-field>
                  <mat-form-field class="col-sm-6">
                    <mat-label>Policy No<span class="asterisk">*</span></mat-label>
                    <input maxlength="20" matInput type="text" pattern="[a-zA-Z0-9]*" />
                    <!-- <mat-error *ngIf="
                        policyForm?.get('txtPolicyNo')?.touched &&
                        policyForm?.get('txtPolicyNo')?.errors?.pattern
                      ">
                      Enter Only Alphanumeric Only!
                    </mat-error>
                    <mat-error *ngIf="
                        policyForm?.get('txtPolicyNo')?.touched &&
                        policyForm?.get('txtPolicyNo')?.errors?.required
                      ">
                      Policy No is a required field!
                    </mat-error> -->
                  </mat-form-field>
                  <mat-form-field class="col-sm-6">
                    <mat-label>Group No<span class="asterisk">*</span></mat-label>
                    <input maxlength="20"matInput type="text" pattern="[a-zA-Z0-9]*" />
                    <!-- <mat-error *ngIf="
                        policyForm?.get('txtPolicyNo')?.touched &&
                        policyForm?.get('txtPolicyNo')?.errors?.pattern
                      ">
                      Enter Only Alphanumeric Only!
                    </mat-error>
                    <mat-error *ngIf="
                        policyForm?.get('txtPolicyNo')?.touched &&
                        policyForm?.get('txtPolicyNo')?.errors?.required
                      ">
                      Policy No is a required field!
                    </mat-error> -->
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-expansion-panel> </mat-accordion>

            <br/>
          <mat-accordion #TertiaryAccordion="matAccordion">
            <mat-expansion-panel [(expanded)]="TerpanelOpenState">
              <mat-expansion-panel-header>
                <mat-panel-title class="customThemeClass">
                  <ng-container>
                    <b>Tertiary Insurance</b>
                  </ng-container>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-card-content>
                <div class="row">
                  <mat-form-field class="col-lg-12">
                    <mat-label>Select Payor<span class="asterisk">*</span></mat-label>
                    <mat-select>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Payer Id / Name / Code" noEntriesFoundLabel="No Matches found"
                          formControlName="selectTerPayor"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let payor of filteredSelectTerPayers | async" [title]="payor?.payerShortCodeList" [value]="payor?.payerShortCodeList">
                        {{ payor?.payerShortCodeList }}
                      </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="
                        policyForm?.get('drpselectPayor')?.touched &&
                        policyForm?.get('drpselectPayor')?.errors?.required
                      ">
                      Payer Id is a required field!
                    </mat-error> -->

                  </mat-form-field>
                  <mat-form-field class="col-sm-6">
                    <mat-label>Policy No<span class="asterisk">*</span></mat-label>
                    <input maxlength="20" matInput type="text" pattern="[a-zA-Z0-9]*" />
                    <!-- <mat-error *ngIf="
                        policyForm?.get('txtPolicyNo')?.touched &&
                        policyForm?.get('txtPolicyNo')?.errors?.pattern
                      ">
                      Enter Only Alphanumeric Only!
                    </mat-error>
                    <mat-error *ngIf="
                        policyForm?.get('txtPolicyNo')?.touched &&
                        policyForm?.get('txtPolicyNo')?.errors?.required
                      ">
                      Policy No is a required field!
                    </mat-error> -->
                  </mat-form-field>
                  <mat-form-field class="col-sm-6">
                    <mat-label>Group No<span class="asterisk">*</span></mat-label>
                    <input maxlength="20"matInput type="text" pattern="[a-zA-Z0-9]*" />
                    <!-- <mat-error *ngIf="
                        policyForm?.get('txtPolicyNo')?.touched &&
                        policyForm?.get('txtPolicyNo')?.errors?.pattern
                      ">
                      Enter Only Alphanumeric Only!
                    </mat-error>
                    <mat-error *ngIf="
                        policyForm?.get('txtPolicyNo')?.touched &&
                        policyForm?.get('txtPolicyNo')?.errors?.required
                      ">
                      Policy No is a required field!
                    </mat-error> -->
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-expansion-panel> </mat-accordion>
            <hr/>

            <div class="row">
              <div class="col-12">
                <h4 class="customThemeClass ml-3">
                  <b>Cash Info</b>
                </h4>
              </div>
               <div class="col-lg-5">
              <mat-checkbox>Cash Case</mat-checkbox>
            </div>
            </div>
            <hr/>
            <div class="row">
              <div class="col-12">
                <h4 class="customThemeClass">
                  <b>Other Info</b>
                </h4>
              </div>
              <mat-form-field class="col-lg-12">
                <mat-label
                    >Provider <span class="asterisk">*</span></mat-label
                  >
                  <mat-select
                    maxlength="40"
                    [(value)]="provider"
                    autocomplete="off"
                    formControlName="txtProvider"
                  >
                    <mat-option
                      *ngFor="let providers of drpProviders"
                      [value]="providers.shortCodeId"
                    >
                      {{ providers.name }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    matSuffix
                    mat-icon-button
                    (click)="clearProvider()"
                    (click)="provider = undefined; $event.stopPropagation()"
                    *ngIf="provider"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                    documentAssignForm?.get('txtProvider')?.touched &&
                    documentAssignForm?.get('txtProvider')?.errors?.required
                    "
                  >
                    Provider is a required field!
                  </mat-error>
              </mat-form-field>
              <mat-form-field class="col-lg-12">
                <mat-label>Select Facility<span class="asterisk"> *</span></mat-label>
                <mat-select formControlName="drpselectPayor" (selectionChange)="onSelectPayor($event)"  >
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="payerCode / payerName / Code" noEntriesFoundLabel="No Matches found"
                      formControlName="selectPayor"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let payor of filteredSelectPayers | async" [title]="payor?.payerShortCodeList" [value]="payor?.payerShortCodeList">
                    {{ payor?.payerShortCodeList }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="
                    documentAssignForm?.get('drpselectPayor')?.touched &&
                    documentAssignForm?.get('drpselectPayor')?.errors?.required
                  ">
                  Payer Id is a required field!
                </mat-error>
                <!-- <button mat-button (click)="zirmedPayor = '';
                 $event.stopPropagation()" *ngIf="zirmedPayor" matSuffix mat-icon-button>
                  <mat-icon>close</mat-icon>
                </button> -->
              </mat-form-field>
              <mat-form-field class="col-lg-12">
                <mat-label>Select Location<span class="asterisk"> *</span></mat-label>
                <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName">
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtBranch"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option #selectAllBranch  (click)="allBranchSelect()" value="0">All</mat-option>

                  <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                    {{ branch.organizationUnitName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <hr/>
            <div class="row">
              <div class="col-lg-6">
                <div class="col-12">
                  <h4 class="customThemeClass ml-3">
                    <b>Document Info</b>
                  </h4>
                </div>
                 <div class="col-lg-12">
                  <button
                  mat-button
                  class="buttonColor mb-1 mr-2"
                >
                  Document Checklist
                </button>
              </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-12">
                    <h4 class="customThemeClass ml-3">
                      <b>Actions</b>
                    </h4>
                  </div>
                   <div class="col-lg-12">
                    <button
                    mat-button
                    class="buttonColor mb-1 mr-2"
                  >
                    Send to Verification / Ready
                  </button>
                </div>
                </div>
              </div>
            </div>
            <hr/>
        </div>
      </form>


      <form *ngIf="isWipFormVisible" [formGroup]="wipForm">
        <!-- WIP -->
        <mat-form-field class="col-lg-6">
          <mat-label>WIP<span class="asterisk">*</span></mat-label>
          <mat-select formControlName="drpWIPId">
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No Matches found"
                formControlName="txtWIP"
              ></ngx-mat-select-search>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- CSR -->
        <mat-form-field class="col-6">
          <mat-label>CSR<span class="asterisk">*</span></mat-label>
          <mat-select
            formControlName="wipUserToBeAssigned"
            [(value)]="selUserId"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No Matches found"
                formControlName="txtWipUserToBeAssignedFilter"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let users of filteredWipCSRs | async"
              [value]="users.userId"
            >
              {{ users.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--Comments-->
        <mat-form-field class="col-lg-12">
          <mat-label>Notes</mat-label>
          <textarea
            matInput
            rows="4"
            autocomplete="new-txtComments"
            maxlength="5000"
            formControlName="txtWIPNotes"
            [(ngModel)]="wipNotesText"
            (input)="updateCharacterCount()"
            type="text"
          ></textarea>
          <div class="char-count">{{ notesText?.length }} / 5000</div>
        </mat-form-field>
        <!-- Buttons -->
        <div class="row pb-2">
          <div class="col-lg-12 d-flex justify-content-center">
            <button
              mat-button
              class="buttonColor mb-1 mr-2"
              (click)="assignDocument()"
            >
              Allocate
            </button>
            <!-- !(!documentAssignForm?.invalid && documentAssignForm?.dirty) -->
            <button
              mat-button
              class="resetclr buttonColor mb-1 mr-2"
              (click)="resetForm()"
            >
              Reset
            </button>
            <!-- <button mat-button class="resetclr buttonColor mb-1 mr-2" (click)="testMethod()" >Test</button> -->
          </div>
        </div>
      </form>

      <div class="table-container">
        <table class="table table-bordered pt-2">
          <thead>
            <th>Patient Name</th>
            <!-- <th>Gender</th> -->
            <th>DOB</th>
            <th>CSR</th>
            <!-- <th>Comments</th> -->
          </thead>
          <tbody>
            <tr *ngFor="let record of dataSource; let i = index">
              <td>
                {{ record?.patientName }}
              </td>
              <!-- <td>
                {{ record?.gender }}
              </td> -->
              <td>
                {{ record?.dob }}
              </td>
              <td>
                {{ record?.assignedTo }}
              </td>
              <!-- <td>{{ record.comments }}</td> -->
            </tr>
          </tbody>
          <tfoot>
            <ng-container
              *ngIf="dataSource && dataSource?.length === 0 && !isShowSpinner"
            >
              <td class="text-align-center" colspan="12">No Records Found</td>
            </ng-container>
            <ng-container *ngIf="isShowSpinner">
              <td class="text-align-center" colspan="12">
                <mat-spinner [diameter]="220"></mat-spinner>
              </td>
            </ng-container>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="pdf-container">
        <ng-container>
          <ngx-extended-pdf-viewer
            [src]="strSelectedPdfPath"
            [height]="pdfheight"
            [showSidebarButton]="false"
            [showFindButton]="true"
            [textLayer]="true"
            [showPagingButtons]="true"
            [showOpenFileButton]="false"
            [showHandToolButton]="false"
            [showScrollingButton]="false"
            [showSpreadButton]="false"
            [showPropertiesButton]="false"
            [showBookmarkButton]="false"
            [zoom]="'page-width'"
            [enableDragAndDrop]="false"
          >
          </ngx-extended-pdf-viewer>
        </ng-container>
      </div>
    </div>
  </div>
</mat-dialog-content>

<!-- <mat-dialog-actions align="end" style="padding-top: 80px">
   <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions> -->

<!-- #region Old Inbound Document Commented Code -->

<!-- <mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6"></div>
  </div>
  <div class="modal-body">
    <form [formGroup]="typeOfPatientForm">
      <div class="row">
        <div class="col-lg-6">
          <mat-button-toggle-group formControlName="tyepOfPatient">
            <mat-button-toggle [value]="'Existing'"
              ><b>Existing Patient</b></mat-button-toggle
            >
            <mat-button-toggle [value]="'New'"
              ><b>New Patient</b></mat-button-toggle
            >
          </mat-button-toggle-group>
        </div>
        <div class="col-lg-6"*ngIf="!typeOfPatient" >
          <h2
            class="text-right text-danger"
            style="font-weight: 600px !important"
          >
            Note: If Patient Exists, Switch to Existing Patients Tab
          </h2>
        </div>
        <div class="col-lg-6"*ngIf="typeOfPatient" >
          <h2
            class="text-right text-danger"
            style="font-weight: 600px !important"
          >
            Note: If Patient Not Exists, Switch Tab to Create New Patient
          </h2>
        </div>
      </div>
    </form>-->
<!--New Patient-->
<!--<div *ngIf="!typeOfPatient" class="pt-4">
      <form [formGroup]="newPatientForm">
        <div class="row">
          <mat-form-field class="col-3">
            <mat-label> First Name <span class="asterisk">*</span></mat-label>
            <input
              matInput
              maxlength="40"
              formControlName="txtFirstName"
              type="text"
              autocomplete="off"
            />
            <mat-error
              class="spanAlignment"
              *ngIf="
                newPatientForm?.get('txtFirstName')?.touched &&
                newPatientForm?.get('txtFirstName')?.errors?.required
              "
            >
              First Name is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Middle Name</mat-label>
            <input
              matInput
              maxlength="40"
              formControlName="txtMiddleName"
              type="text"
              autocomplete="off"
            />
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label> Last Name <span class="asterisk">*</span></mat-label>
            <input
              matInput
              maxlength="40"
              formControlName="txtLastName"
              type="text"
              autocomplete="off"
            />
            <mat-error
              class="spanAlignment"
              *ngIf="
                newPatientForm?.get('txtLastName')?.touched &&
                newPatientForm?.get('txtLastName')?.errors?.required
              "
            >
              Last Name is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Gender<span class="asterisk">*</span></mat-label>
            <mat-select
              formControlName="drpGender"
              autocomplete="off"
              [(value)]="gender"
              maxlength="40"
            >
              <mat-option
                *ngFor="let test of drpGenderLoop"
                [value]="test.genderShortCode"
              >
                {{ test.genderName }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              (click)="gender = ''; $event.stopPropagation()"
              *ngIf="gender"
              matSuffix
              (click)="clearGender()"
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error
              class="spanAlignment"
              *ngIf="
                newPatientForm?.get('drpGender')?.touched &&
                newPatientForm?.get('drpGender')?.errors?.required
              "
            >
              Gender is a required field!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label> Birth Date <span class="asterisk">*</span></mat-label>
            <input
              [max]="BirthdateMaxDate"
              matInput
              maxlength="40"
              formControlName="txtBirthdate"
              type="text"
              placeholder="(MM/DD/YYYY)"
              autocomplete="off"
              matInput
              [matDatepicker]="txtBirthdate"
              [min]="BirthdateMinDate"
            />
            <mat-datepicker-toggle matSuffix [for]="txtBirthdate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtBirthdate> </mat-datepicker>
            <mat-error
              *ngIf="
                newPatientForm?.get('txtBirthdate')?.touched &&
                newPatientForm?.get('txtBirthdate')?.errors &&
                !(
                  BirthdateMinDate <= newPatientForm?.get('txtBirthdate')?.value
                )
              "
            >
              Enter Valid date
            </mat-error>
            <mat-error
              *ngIf="
                newPatientForm?.get('txtBirthdate')?.touched &&
                newPatientForm?.get('txtBirthdate')?.errors?.required
              "
            >
              Birth Date is a required field!
            </mat-error>
            <mat-error
              *ngIf="
                BirthdateMinDate <=
                  newPatientForm?.get('txtBirthdate')?.value &&
                !(
                  newPatientForm?.get('txtBirthdate')?.touched &&
                  newPatientForm?.get('txtBirthdate')?.errors?.required
                )
              "
            >
              Enter Past Dates Only!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-3">
            <mat-label>Assign To<span class="asterisk">*</span></mat-label>
            <mat-select
              formControlName="userToBeAssigned"
              [(ngModel)]="selUserId"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtUserToBeAssignedFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let users of filteredIdentityUsers | async"
                [value]="users.id"
              >
                {{ users.userName }}
              </mat-option>
            </mat-select>
          </mat-form-field>-->

<!--Comments-->
<!--<mat-form-field class="col-lg-6">
            <mat-label> Comments <span class="asterisk">*</span></mat-label>
            <textarea
              matInput
              rows="4"
              autocomplete="off"
              maxlength="500"
              formControlName="txtComments"
              type="text"
            ></textarea>
            <mat-error
              class="spanAlignment"
              *ngIf="
                newPatientForm?.get('txtComments')?.touched &&
                newPatientForm?.get('txtComments')?.errors?.required
              "
            >
              Comments is a required field!
            </mat-error>
            <mat-error
              class="spanAlignment"
              *ngIf="newPatientForm?.get('txtComments')?.hasError('whitespace')"
            >
              Comments is a required field!
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>-->
<!--Existing Patient-->
<!--<div *ngIf="typeOfPatient" class="pt-4">
      <form [formGroup]="existingPatientForm">
        <div class="row">
          <!--Patient Name Search-->
<!--<mat-form-field class="col-6">
            <mat-label
              >Search Patient Name<span class="asterisk">*</span></mat-label
            >
            <mat-select formControlName="drpPatientId">
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtPatinetIdFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let patientIds of filteredPatientId | async"
                [value]="patientIds.guid"
              >
                {{ patientIds.idAndNameAndDOB }}
              </mat-option>
              <mat-error
                class="spanAlignment"
                *ngIf="
                  newPatientForm?.get('drpPatientId')?.touched &&
                  newPatientForm?.get('drpPatientId')?.errors?.required
                "
              >
                Patient Id is a required field!
              </mat-error>
            </mat-select>
          </mat-form-field>-->
<!--Assign To-->
<!-- <mat-form-field class="col-6">
            <mat-label>Assign To<span class="asterisk">*</span></mat-label>
            <mat-select
              formControlName="userToBeAssigned"
              [(ngModel)]="selUserId"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtUserToBeAssignedFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let users of filteredIdentityUsers | async"
                [value]="users.id"
              >
                {{ users.userName }}
              </mat-option>
            </mat-select>
          </mat-form-field>-->

<!--Comments-->
<!--<mat-form-field class="col-lg-6">
            <mat-label> Comments <span class="asterisk">*</span></mat-label>
            <textarea
              matInput
              rows="4"
              autocomplete="off"
              maxlength="500"
              formControlName="txtComments"
              type="text"
            ></textarea>
            <mat-error
              class="spanAlignment"
              *ngIf="
                existingPatientForm?.get('txtComments')?.touched &&
                existingPatientForm?.get('txtComments')?.errors?.required
              "
            >
              Comments is a required field!
            </mat-error>
            <mat-error
              class="spanAlignment"
              *ngIf="
                existingPatientForm?.get('txtComments')?.hasError('whitespace')
              "
            >
              Comments is a required field!
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    *ngIf="!typeOfPatient"
    [disabled]="
      isShowSpinner || !(!newPatientForm?.invalid && newPatientForm?.dirty)
    "
    class="buttonColor"
    mat-button
    (click)="saveNewPatientDocumentDetails()"
  >
    Create & Allocate
    <mat-icon *ngIf="isShowSpinner">
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>
  <button
    *ngIf="typeOfPatient"
    [disabled]="
      isShowSpinner ||
      !(!existingPatientForm?.invalid && existingPatientForm?.dirty)
    "
    class="buttonColor"
    mat-button
    (click)="saveExistingPatientDocumentDetails()"
  >
    Allocate
    <mat-icon *ngIf="isShowSpinner">
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>
  <button mat-button class="resetclr" (click)="resetDocumentForm()">
    Reset
  </button>

  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions> -->

<!-- #endregion-->
