<div class="notifications">
  <div *ngFor="let reminder of reminders" class="notification" [ngClass]="className(reminder)">
    <ng-container *ngTemplateOutlet="remindersTpl;context:{reminder:reminder}"></ng-container>
  </div>
</div>

<ng-template #remindersTpl let-reminder="reminder">
  <div class="row title">
    <div class="col-10 mt-2">{{reminder?.titleMsg}}</div>
    <button mat-icon-button class="col-2" (click)="closeReminder(reminder)" matTooltip="Close the Reminder">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
  <div class="message">
    <div id="{{reminder?.ProductNotification?.id}}" mat-line class="m-0 ">
      <span class="float-left">
        <span>
          {{ reminder?.ProductNotification?.hcpcCode }}
        </span>
        <span *ngIf="reminder?.ProductNotification?.message">-</span>
        <span>
          {{ reminder?.ProductNotification?.message }}
        </span>
      </span>
      <span class="float-right">
        <span>
          {{ reminder?.ProductNotification?.currentQty }}
        </span>
        <span> / </span>
        <span>
          {{ reminder?.ProductNotification?.minimumQty }}
        </span>
      </span>
    </div>
    <br>
    <div mat-line>
      <span class="float-left">
        <span class="text-muted"> {{
          reminder?.ProductNotification?.categoryName
          }}</span>
        <span *ngIf="reminder?.ProductNotification?.brandName">-</span>
        <span class="text-muted"> {{
          reminder?.ProductNotification?.brandName }}
        </span>
      </span>
      <span class="text-muted float-right">
        {{reminder?.ProductNotification?.creationTime | date :'MM/dd/yyyy h:mm a' :'en_US'}}
      </span>
    </div>
  </div>
</ng-template>
