<mat-dialog-content class="mat-typography" style="height:90% !important">
  <div class="row">
    <div class="col-lg-6">
      <h2>{{ addDocumentbinModalTitle }}</h2>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="documentBinForm">
      <div class="row">
        <div class="col-lg-6">
          <div class="row">
            <ng-container *ngIf="showErrorForFileType">
              <div class="row">
                <div class="col-12">
                  <span class="asterisk">{{ 'Select Only PDF`s' }}</span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="showErrorBasedOnSize">
              <div class="row">
                <div class="col-12">
                  <span class="asterisk">{{ 'Select each file`s size of 10mb or Less' }}</span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="showErrorForFileLength">
              <div class="row">
                <div class="col-12">
                  <span class="asterisk">{{ 'Select Only 10 files' }}</span>
                </div>
              </div>
            </ng-container>
            <div class="col-6 center">
              <label [hidden]="selectedFileUploadsData!==null" for="file-upload"
                class="btnclr waves-effect font-size-12 pt-2 pb-2 pr-4 pl-4">
                <i class="fa fa-cloud-upload mr-2"></i>Browse<span class="asterisk"> * </span>
              </label>
              autocomplete="off" id="file-upload" formControlName="documentFile"
                (change)="getSelectedFilesDetails(uploadFileReference.files)" [multiple]="true" #uploadFileReference
                accept="application/pdf" type="file" />
            </div>
            <mat-form-field class="col-6">
              <mat-label>User To Be Assigned</mat-label>
              <mat-select formControlName="txtUser" maxlength="40" #singleSelect>
                <mat-option *ngFor="let user of arrUsersList" [value]="user.id">
                  {{ user.userName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="row">
            <div class="col-6 center">
              <br>
              <mat-checkbox formControlName="chkStat">Stat
              </mat-checkbox>
            </div>

            <div class="col-6">
              <mat-form-field *ngIf="selectedFileUploadsData===null">
                <mat-label>Notes</mat-label>
                <input autocomplete="new-txtNote" formControlName="txtNote" maxlength="100" matInput>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-align-center mt-2 mb-2">
          <div *ngIf="selectedFileUploadsData===null">
            <button mat-button class="buttonColor mb-2 mr-2"
              [disabled]="chkdisableButton || documentBinForm?.get('documentFile')?.value===null && this.fileId ===''"
              (click)="saveUploadedDocuments(documentBinForm?.get('documentFile')?.value)">
              Save
            </button>
            <button mat-button class="resetclr mb-2 mr-2" (click)="resetDocumentBinForm()">
              Reset
            </button>
          </div>
          <div *ngIf="selectedFileUploadsData!==null">
            <button mat-button class="buttonColor mb-2 mr-2"
              [disabled]="chkdisableButton || documentBinForm?.get('documentFile')?.value===null && this.fileId ===''"
              (click)="saveUploadedDocuments(documentBinForm?.get('documentFile')?.value)">
              Save
            </button>
            <button mat-button class="resetclr mb-2 mr-2" (click)="resetDocumentBinForm()">
              Reset
            </button>
          </div>
        </div>
      </div>
    </form>

    <div class="table-responsive" *ngIf="selectedFileUploadsData!==null">
      <table class="users table table-sm table-bordered ">
        <thead style="display: table-header-group;">
          <tr>
            <!-- <th class="table-light" scope="col">S.No</th> -->
            <th class="table-light" scope="col">Document Name</th>
            <th class="table-light" scope="col">Status</th>
            <th class="table-light" scope="col">Assigned To</th>
            <th class="table-light" scope="col">Assigned Date</th>
          </tr>
        </thead>
        <tbody style="display: table-row-group;">
          <tr scope="row">
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ 1 }} </span>
              </p>
            </td>
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ selectedFileUploadsData?.fileName }} </span>
              </p>
            </td>
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ selectedFileUploadsData?.fileStatus }} </span>
              </p>
            </td>
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ selectedFileUploadsData?.assignedToUserName }} </span>
              </p>
            </td>
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ selectedFileUploadsData?.uploadedOn }} </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive" *ngIf="arrSelectedDocuments && arrSelectedDocuments?.length !== 0">
      <table class="users table table-sm table-bordered ">
        <thead style="display: table-header-group;">
          <tr>
            <!-- <th class="table-light" scope="col">S.No</th> -->
            <th class="table-light" scope="col">Document Name</th>
            <th class="table-light" scope="col">Document Type</th>
            <th class="table-light" scope="col">Document Size</th>
          </tr>
        </thead>
        <tbody style="display: table-row-group;">
          <tr scope="row" *ngFor="let document of arrSelectedDocuments;let i=index">
            <!-- <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ i+1}} </span>
              </p>
            </td> -->
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ document?.fileName }} </span>
              </p>
            </td>
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ document?.fileType }} </span>
              </p>
            </td>
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ document?.fileSize }} </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>

<!-- <mat-dialog-actions align="end">
  <button type="button" mat-button class="buttonColor"
    [disabled]=" saveButtonHide ||!(!verificationForm.invalid && (verificationForm.dirty ))"
    (click)=" getOrderDetailsForSave()">Save
    <mat-icon *ngIf='isShowSpinner'>
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
    </mat-icon>
  </button>
  <button mat-button class="resetclr" (click)="clearData()">Reset</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions> -->