import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { MasterNotesDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto/models';
import { MasterNotesService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-notes.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, tap, filter } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AddNotesComponent } from '../add-notes/add-notes.component';

import {
  NotesSeverityDTO,
  NotesStatusDTO,
  NotesTypeDTO,
  PatientMasterDropdownDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import {
  CreateUpdatePatientNotesDTO,
  NoteType,
  noteTypeOptions,
  PatientNotesDTO,
} from '../patient-proxy/patient-optimization/dto/models';
import {
  IdentityUserUsingTenantIdDto,
  IdentityUserWithRoleDto,
  UserService,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { PatientNotesService } from '../patient-proxy/patient-optimization/patient-notes.service';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { InboundStatusService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound-status/inbound-status.service';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { InboundStatusDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound-status/dto';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { PatientInfoDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
// import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-add-note-tab',
  templateUrl: './add-note-tab.component.html',
  styleUrls: ['./add-note-tab.component.scss'],
  providers: [ListService],
})
export class AddNoteTabComponent implements OnInit, OnDestroy {
  @Input() patientId: string = defaultGuid;
  @Output() reloadTableEvent = new EventEmitter(); //Event Emitter to reload the table
  @Output() reloadTreeEvent = new EventEmitter(); //Event Emitter to reload the tree
  @Output() selectNode = new EventEmitter();
  @Input() selectedNode: any;
  @Input() defaultFaxId: any;
  @Input() chartNo: any;
  @Input() addnotes: any;
  // @Input() patientMedicareChecklistId: string;
  NoteTypeControl = new FormControl();
  @Input() isCallNote: boolean;
  issaving: boolean = false;
  isdrpLoading: boolean;
  // isCallNote: boolean;
  defaultNotesId: string = '';
  issave: string = 'Save';
  notesId: string = defaultGuid;
  public noteForm: FormGroup;
  loginUserId: string = '';
  binList: InboundStatusDTO[] = []; //Bin Menu List
  LoginUserName: string = '';
  drpNotetype: any;
  // drpNoteReason: MasterNotesDTO[] = [];
  notesReason: string = '';
  notesDes: string;
  drpSeverity: NotesSeverityDTO[] = [];
  severity: string;
  drpState: any[] = []; //drpState: MasterNotesDTO[] = [];
  state: string;
  drpStatus: NotesStatusDTO[] = []; //statuses: NotesStatusDTO[] = [];
  status: string;
  subject: string;
  userList: IdentityUserWithRoleDto[] = [];
  txtDescription: string;
  isShowSpinner: boolean = false;
  notesModalTitle: string = '';
  Idview: boolean = false;
  tblNotesReponse: any[] = [];
  pageType: NoteType;
  saveButtonHide: boolean = false;
  drpDisable: boolean = false;
  notesText = '';
  // public filteredNoteTypes: Observable<NotesTypeDTO[]>;
  @ViewChild('singleSelect') singleSelect: MatSelect;
  defaultFaxIdV1: string;
  chartNoV1:string;
  subscription$: Subscription[] = [];
  inboundStatusService: any;
  listNoteType: any;
  defaultNotetypeId: string;
  defaultNotetype: string;
  patientDetails: PatientInfoDTO;
  constructor(
    private notesService: MasterNotesService,
    public list: ListService,
    private fb: FormBuilder,
    private textValidators: TextValidators,
    private Table: TableService,
    private userService: UserService,
    public dashboardService: DashboardService,
    private InboundDocService: InboundDocumentService,
    private patientDropdownService: PatientMasterDropdownService,
    private toastr: ToastrService,
    public notesDialogRef: MatDialogRef<AddNotesComponent>,
    private dialog: MatDialogRef<AddNotesComponent>,
    private communicationService: CommunicationService,
    private mmOrderService: MmOrderService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      defaultFaxId: string;
      notesId?: string;
      orderId: string;
      claimId: string;
      noteType: NoteType;
      notesDes: string;
      isCallNote: boolean;
      isPending: boolean;
      isBillingPending?: boolean; //To trigger the Billing Status Update based on this value.
      isOverride?: boolean;
      docId: string;
      patientAuthorizationId: string;
      patientVerificationId: string;
      patientMedicareChecklistId: string;
      inboundStatusId: string;
      inboundStatusShortCodeId: string;
      selectedNode: any;
      chartNo: string;
      addnotes: boolean;
      patientNotes: any[];
    } = {
      patientId: defaultGuid,
      defaultFaxId: defaultGuid,
      notesId: defaultGuid,
      orderId: defaultGuid,
      claimId: defaultGuid,
      noteType: NoteType.general,
      notesDes: undefined,
      isCallNote: false,
      isPending: false,
      isBillingPending: false,
      isOverride: false,
      docId: defaultGuid,
      inboundStatusId: defaultGuid,
      inboundStatusShortCodeId: defaultGuid,
      selectedNode: defaultGuid,
      chartNo: '',
      addnotes: false,
      patientAuthorizationId: defaultGuid,
      patientVerificationId: defaultGuid,
      patientMedicareChecklistId: defaultGuid,
      patientNotes: [],
    },
    public patientNotesService: PatientNotesService
  ) {
    this.isCallNote = this.data.isCallNote;
  }

  //On Load Function
  ngOnInit(): void {
    const tableSubscription = this.Table.getLoginUserId().subscribe((val) => {
      this.loginUserId = val;
    });
    this.subscription$.push(tableSubscription);
    //Form Builder
    this.noteForm = this.fb.group({
      drpNotetype: new FormControl('', [Validators.required]),
      txtNoteType: new FormControl(''),
      // drpNoteReason: new FormControl('', [Validators.required]),
      txtCreatedBy: new FormControl('', [this.textValidators.isTextCheck]),
      txtDateCreated: new FormControl(new Date()),
      txtActualDate: new FormControl(''),
      txtDateNeeded: new FormControl(''),
      drpSeverity: new FormControl(''),
      drpState: new FormControl(''),
      drpStatus: new FormControl(''),
      txtDateCompleted: new FormControl(''),
      // txtSubject: new FormControl(null, [Validators.required,noWhitespaceValidator]),
      txtDescription: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      chkReminder: new FormControl(''),
      // chkIsCallNote: new FormControl(true, this.isCallNote ? [Validators.required, mustBeCheckedValidator()] : null)
      chkIsCallNote: new FormControl(
        this.getisCallNote(),
        this.isCallNote ? [Validators.required, mustBeCheckedValidator()] : null
      ),
    });
    if (this.data?.notesDes) {
      this.noteForm.patchValue({
        txtDescription: this.data.notesDes,
      });
      this.noteForm.disable();
    } else {
    }
    this.noteTypeDetails();
    // if (this.data?.patientId == null || this.data?.patientId == '' || this.data?.patientId == undefined) {
    //   //To Load the Filter Dropdown
    //   // this.drpNotetype = noteTypeOptions
    //   //   ?.map((e) => {
    //   //     let x = {
    //   //       value: e.value,
    //   //       key: e.key,
    //   //     };
    //   //     return x;
    //   //   })
    //   //   .filter((a) => NoteType.faxNote);

    //   this.notesType = <NoteType>(
    //     this.drpNotetype.filter((x) => x.value === NoteType.faxNote)?.[0]?.key
    //   );

    //   this.noteForm.patchValue({
    //     drpNotetype: this.drpNotetype.filter(
    //       (x) => x.value === NoteType.faxNote
    //     )?.[0]?.key,
    //     drpStatus: this.drpStatus.find((x) => x.statusShortCodeId === "001")?.statusShortCodeId || null,
    //   });
    //   this.drpDisable = true;
    //   // this.getNotesReason();
    // }
    // else if (this.data?.noteType === NoteType.order) {
    //   //To Load the Filter Dropdown
    //   this.drpNotetype = noteTypeOptions
    //     ?.map((e) => {
    //       let x = {
    //         value: e.value,
    //         key: e.key,
    //       };
    //       return x;
    //     })
    //     .filter((a) => a.key == 'order');

    //   this.notesType = <NoteType>(
    //     this.drpNotetype.filter((x) => x.value === NoteType.order)?.[0]?.key
    //   );

    //   this.noteForm.patchValue({
    //     drpNotetype: this.drpNotetype.filter(
    //       (x) => x.value === NoteType.order
    //     )?.[0]?.key,
    //   });
    //   this.drpDisable = true;
    //   // this.getNotesReason();
    // } else if (this.data?.noteType === NoteType.invoice) {
    //   //To Load the Filter Dropdown
    //   this.drpNotetype = noteTypeOptions
    //     ?.map((e) => {
    //       let x = {
    //         value: e.value,
    //         key: e.key,
    //       };
    //       return x;
    //     })
    //     .filter((a) => a.key == 'invoice');

    //   this.notesType = <NoteType>(
    //     this.drpNotetype.filter((x) => x.value === NoteType.invoice)?.[0]?.key
    //   );
    //   this.noteForm.patchValue({
    //     drpNotetype: this.drpNotetype.filter(
    //       (x) => x.value === NoteType.invoice
    //     )?.[0]?.key,
    //   });
    //   this.drpDisable = true;

    //   // this.getNotesReason();
    // } else if (this.data?.noteType === NoteType.faxNote) {
    //   //To Load the Filter Dropdown
    //   this.drpNotetype = noteTypeOptions
    //     ?.map((e) => {
    //       let x = {
    //         value: e.value,
    //         key: e.key,
    //       };
    //       return x;
    //     })
    //     .filter((a) => a.key);

    //   this.notesType = <NoteType>(
    //     this.drpNotetype.filter((x) => x.value === NoteType.faxNote)?.[0]?.key
    //   );
    //   this.noteForm.patchValue({
    //     drpNotetype: this.drpNotetype.filter(
    //       (x) => x.value === NoteType.faxNote
    //     )?.[0]?.key,
    //   });
    //   //  this.drpDisable = true;

    //   // this.getNotesReason();
    // } else if (this.data?.noteType === NoteType.patient) {
    //   //To Load the Filter Dropdown
    //   this.drpNotetype = noteTypeOptions
    //     ?.map((e) => {
    //       let x = {
    //         value: e.value,
    //         key: e.key,
    //       };
    //       return x;
    //     })
    //     .filter((a) => a.key != 'order' && a.key != 'invoice');

    //   this.notesType = <NoteType>(
    //     this.drpNotetype.filter((x) => x.value === NoteType.patient)?.[0]?.key
    //   );
    //   this.noteForm.patchValue({
    //     drpNotetype: this.drpNotetype.filter(
    //       (x) => x.value === NoteType.patient
    //     )?.[0]?.key,
    //   });

    //   // this.getNotesReason();
    //   // this.drpDisable = true;
    // }
    this.NoteTypeControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpNotetype = this.listNoteType.filter((dx) =>
        dx.noteType.toLowerCase().includes(FilterText)
      );
    });
    // this.getDropdown();

    if (this.data) {
      this.patientId = this.data?.patientId ?? defaultGuid;
      this.pageType = this.data?.noteType ?? NoteType.general;
      this.defaultFaxId = this.data?.defaultFaxId;
      this.chartNo = this.data?.chartNo;
      this.addnotes = this.data?.addnotes;
      // this.notesType = this.data?.noteType ?? NoteType.general;
    }

    if(this.patientId !== defaultGuid && this.patientId !==null && this.patientId!==undefined) {
      this.loadPatientDetails(this.patientId);
    }else{
      this.defaultFaxIdV1 = this.data?.defaultFaxId;
    }
    this.loadUserProfile();
    this.getNoteById(this.data.notesId);
    if (this.noteForm.valid || this.noteForm.dirty) {
      this.noteForm.value;
    }
    // this. loadBins() ;
  }
  // loadBins() {
  //   this.inboundStatusService
  //     .getList(new PagedAndSortedResultRequestDto())
  //     .subscribe((response) => {
  //       this.binList = response?.items;
  //     });
  // }

  //Load Notes Reason
  // getNotesReason() {
  //   const type: NoteType = <NoteType>this.notesType;

  //   const noteReasonDetails = this.notesService
  //     .getMasterListBasedOnNoteType(type)
  //     .subscribe((response) => {
  //       this.drpNoteReason = response;
  //     });
  //   this.subscription$.push(noteReasonDetails);
  //   // return this.drpNoteReason;
  // }

  //Load Note Type
  // noteTypeDetails(){
  //   const NoteType = this.notesService
  //   .getListV1(new PagedAndSortedResultRequestDto())
  //     .subscribe(
  //       (response) => {
  //         this.listNoteType=response;
  //         this.drpNotetype=this.listNoteType;
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //         this.subscription$.push(NoteType);
  //       }
  //     );
  // }

  // Note Type Drop Down V1
  noteTypeDetails() {
    this.isdrpLoading = true;
    this.issaving = true;
    const NoteTypes = this.notesService.getDrpNoteTypes().subscribe(
      (response) => {
        this.isdrpLoading = false;
        this.issaving = false;
        this.listNoteType = response;
        this.drpNotetype = this.listNoteType;
        this.defaultNotetype=this.listNoteType.filter(x=>((x.noteType).toLowerCase()==((NoteType.generalNoted).toLowerCase()))
        ||((x.noteType).toLowerCase()==(NoteType.general).toLowerCase()))[0]?.noteType;
        this.defaultNotetypeId=this.listNoteType.filter(x=>((x.noteType).toLowerCase()==((NoteType.generalNoted).toLowerCase()))
        ||((x.noteType).toLowerCase()==((NoteType.general).toLowerCase())))[0]?.id;
        this.noteForm.patchValue({
          drpNotetype: this.defaultNotetypeId + '/' +  this.defaultNotetype
        })
      },
      (err) => {
        this.isdrpLoading = true;
        this.issaving = true;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.subscription$.push(NoteTypes);
      }
    );
  }
  //Load Status Dropdown
  // getDropdown() {
  //   let patientDropdownInput: PatientDropdowns[];
  //   patientDropdownInput = [
  //     PatientDropdowns.NoteStatus,
  //     PatientDropdowns.NoteSeverities,
  //   ];

  //   const patientDropdownSubscription = this.patientDropdownService
  //     .getPatientDropdownsByInput(patientDropdownInput)
  //     .subscribe(
  //       (stateResponse) => {
  //         const response: PatientMasterDropdownDTO = stateResponse;
  //         this.drpStatus = response?.notesStatuses;
  //         this.drpSeverity = response?.notesSeverities;
  //         this.noteForm.patchValue({
  //           drpStatus:
  //             this.drpStatus.find((x) => x.statusShortCodeId === '001')
  //               ?.statusShortCodeId || null,
  //         });
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //         this.subscription$.push(patientDropdownSubscription);
  //       }
  //     );
  // }

  //Update Character Count for TextArea
  updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > maxlength) {
      this.notesText = this.notesText?.substr(0, maxlength);
    }
  }

  //Load Patient Dropdowns
  // getDropdown() {
  //   let patientDropdownInput: PatientDropdowns[];
  //   patientDropdownInput = [
  //     PatientDropdowns.NoteStatus,
  //     PatientDropdowns.NoteSeverities,
  //     PatientDropdowns.NoteTypes,
  //   ];
  //   const patientDropdownSubscription = this.patientDropdownService
  //     .getPatientDropdownsByInput(patientDropdownInput)
  //     .subscribe(
  //       (stateResponse) => {
  //         const response: PatientMasterDropdownDTO = stateResponse;
  //         this.statuses = response?.notesStatuses;
  //         this.drpseverity = response?.notesSeverities;
  //         this.txtNotetype = response?.lstNotesType;

  //         //Search dropdown in Notes Type Dropdown
  //         this.filteredNoteTypes = this.noteForm
  //           .get('txtNotesFilter')
  //           .valueChanges.pipe(
  //             startWith(''),
  //             map((value) =>
  //               this.txtNotetype.filter((option) =>
  //                 option?.notesType
  //                   ?.toLowerCase()
  //                   ?.includes(value?.toLowerCase() ?? '')
  //               )
  //             )
  //           );
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'error',
  //           text: data?.error?.error?.message,
  //         });
  //         this.subscription$.push(patientDropdownSubscription);
  //       }
  //     );

  //   const notesList = (query: any) => this.notesService.getList(query);
  //   const notesListSubscription = this.list.hookToQuery(notesList).subscribe(
  //     (response) => {
  //       response &&
  //         response?.totalCount &&
  //         response?.items &&
  //         response?.items?.forEach((element) => {});
  //       this.drpState = response.items;
  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'error',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.subscription$.push(patientDropdownSubscription, notesListSubscription);
  // }

  resetNotes() {
    this.noteForm.reset({
      drpNotetype: null,
      txtDescription: '',
    });
  }
  //Save and Update the Patient Notes
  saveNotes() {
    this.issaving = true;
    this.isShowSpinner = true;
    this.notesId = this.data?.notesId ?? defaultGuid;
    //Notes Input Constant
    const notesInput: CreateUpdatePatientNotesDTO = {
      noteType: this.noteForm.value.drpNotetype.split('/')[1],
      noteTypeId: this.noteForm.value.drpNotetype.split('/')[0],
      description: this.noteForm.value.txtDescription ?? '',
      severity: this.severity ?? '',
      // subject: this.noteForm.value.txtSubject ?? '',
      documentId: this.data.docId ?? null,
      // noteReason: this.noteForm.value.drpNoteReason ?? '',
      createdBy: this.loginUserId,
      patientVerificationId: this.data.patientVerificationId,
      patientAuthorizationId: this.data.patientAuthorizationId,
      dateCreated: this.noteForm.value.txtDateCreated ?? '',
      actualDate: this.noteForm.value.txtActualDate ?? '',
      dateComplete: this.noteForm.value.txtDateCompleted ?? '',
      dateNeeded: this.noteForm.value.txtDateNeeded ?? '',
      status: this.status ?? '',
      patientId: this.patientId,
      isCallNote: this.noteForm.value.chkIsCallNote || false,
      patientMedicareChecklistId: this.data.patientMedicareChecklistId,
      claimId:this.data.claimId,
    };
    //Save
    this.notesId === defaultGuid &&
      this.patientNotesService
        .creatingPatientNotesByInput(notesInput)
        .subscribe(
          (response) => {

            // this.data.patientNotes.push("Dhana - 02/27/2024 12:52:07 AM -" + this.noteForm.value.txtDescription);
            // this.data.patientNotes.push(response);

            this.noteForm.reset();
            !this.data.isBillingPending &&
              !this.data.isOverride &&
              this.toastr.success('Saved Successfully', 'Success');
            this.issaving = false;

            // this.communicationService.triggerFunctionCall(this.data.selectedNode); By Manikandan
            // this.communicationService.triggerpatientList(patientSaved?.patientId);
            this.communicationService.triggerViewNotesList();
            // this.reloadTableEvent.emit();  By Manikandan
            // this.selectNode.emit({ node: this.selectedNode }); By Manikandan
            //  let NotesDetails = [{Notes:response.notesDetails, FaxID:this.data.defaultFaxId}];
            //  this.communicationService.addNotes(NotesDetails);
            // this.notesDialogRef.close(patientSaved);
            // this.notesDialogRef.close(this.data.patientNotes);
            this.notesDialogRef.close(response);
            this.isShowSpinner = false;
            this.notesId = defaultGuid;
            if (this.data.isPending == true) {
              this.InboundDocService.updateInboundStatusByInboundDocumentIdAndSShortCodeAndSNotes(
                this.data.docId,
                this.data.inboundStatusShortCodeId,
                this.data.inboundStatusId,
                ''
              ).subscribe(
                (response) => {
                  this.toastr.success('Bin Updated', 'Success');
                  this.dialog.close();
                },
                (err) => {
                  this.toastr.success('Bin Updated', 'Success');
                  this.dialog.close();
                }
              );

              this.communicationService.triggerFunctionCall(
                this.data.selectedNode
              );
              this.reloadTableEvent.emit();
              this.selectNode.emit({ node: this.data.selectedNode });
            }
            this.data.isBillingPending
              ? this.communicationService.triggerUpdateBillingStatus(
                  this.data.orderId
                )
              : false;

            this.data.isOverride &&
              this.communicationService.triggerUpdateOverrideStatus({
                orderId: this.data.orderId,
                status: false,
                comments: notesInput.description,
              });
          },
          (err) => {
            this.issaving = false;
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    //Update
    this.notesId !== defaultGuid &&
      this.patientNotesService
        .updatingPatientNotesByIdAndInput(this.notesId, notesInput)
        .subscribe(
          (response) => {
            this.noteForm.reset();
            this.toastr.success('Updated Successfully', 'Success');
            this.issaving = false;
            // this.communicationService.triggerFunctionCall(this.data.selectedNode);
            this.communicationService.triggerViewNotesList();
            this.notesDialogRef.close(response);
            // this.reloadTableEvent.emit();
            // this.selectNode.emit({ node: this.selectedNode });
            // this.notesDialogRef.close(patientSaved);
            this.isShowSpinner = false;
            this.notesId = defaultGuid;
          },
          (err) => {
            this.issaving = false;
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
  }
  //Get Notes Details by Notes Id
  getNoteById(noteId: string = '') {
    this.saveButtonHide = true;
    //Patching Note Fields in the Modal Popup
    if (noteId != defaultGuid && noteId != '' && noteId != null) {
      this.issaving = true;
      const getbyIdsubscription$ = this.patientNotesService
        .get(noteId)
        .pipe(
          tap((x) => {
            //  this.notesType = x?.noteType;
            // this.getNotesReason();
          })
        )
        .subscribe(
          (response) => {
            this.defaultNotesId = response?.defaultNotesId;
            this.status = response?.status;
            let NotetypeId=this.listNoteType.filter(x=>x.id==response?.noteTypeId)[0]?.id;
            let Notetype=this.listNoteType.filter(x=>x.id==response?.noteTypeId)[0]?.noteType;
            this.noteForm.patchValue({
              drpNotetype: (NotetypeId || this.defaultNotesId) + '/' + (Notetype||this.defaultNotetype),
              drpStatus:
                this.drpStatus.find((x) => x.statusShortCodeId === '001')
                  ?.statusShortCodeId || null,

              //   // drpNoteReason: response?.noteReason,
              txtCreatedBy:
                (response?.createdBy ?? '') == ''
                  ? this.LoginUserName
                  : this.userList.filter((a) => a.id == response.createdBy)?.[0]
                      ?.userName,
              txtDateCreated: response?.dateCreated ?? '',
              txtActualDate: response?.actualDate ?? '',
              txtDateNeeded: response?.dateNeeded ?? '',
              drpSeverity: response?.severity,
              txtDateCompleted: response?.dateComplete,
              // txtSubject: response?.subject,
              txtDescription: response?.description,
              // drpNoteReason: response?.noteReason,
            });

            //this.status = response?.status;
            //this.drpDisable =true;
            // response?.noteType == NoteType.order ||
            // response?.noteType == NoteType.invoice
            //   ? true
            //   : false;
            response.status == '002' ? this.noteForm.disable() : '';
            this.loginUserId = response?.createdBy;
            this.saveButtonHide = response.status == '002' ? true : false;
            this.notesModalTitle = 'Update Notes';
            this.issave = 'Update';
            this.Idview = true;
          },
          (err) => {
            this.saveButtonHide = true;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.noteForm.markAllAsTouched();
      this.noteForm.markAsDirty();
      this.noteForm.updateValueAndValidity();
      this.subscription$.push(getbyIdsubscription$);
    } else {
      this.issaving = false;
    }
  }
  // logger() {
  //   Object.keys(this.noteForm.controls).forEach((key) => {
  //     const controlErrors: string = this.noteForm?.get(key)?.status ?? ' ';
  //     if (controlErrors != null && controlErrors != 'VALID') {
  //       Object.keys(controlErrors).forEach((keyError) => {
  //       });
  //     }
  //   });
  // }
  //To Load User Details and get the Notes Details if it Exists
  loadUserProfile() {
    this.saveButtonHide = true;

    //To get the User Name and the Notes Details
    const dashBoardSubscription = this.dashboardService.userProfile().subscribe(
      (response) => {
        this.LoginUserName = response?.userName;
        //Get Notes Details
        if (this.data?.notesId !== '' && this.data?.notesId !== defaultGuid) {
          this.notesModalTitle = 'Update Notes';
          this.issave = 'Update';
          const userDetail: IdentityUserUsingTenantIdDto = {
            tenantId: localStorage.getItem('tenantId'),
          };
          const userList = this.userService
            .getUserList(userDetail)
            .subscribe((response) => {
              this.userList = response;
            });
          this.subscription$.push(userList);
        }
        //New Notes Creation
        else {
          this.saveButtonHide = false;
          this.data.notesId = defaultGuid;
          this.noteForm.patchValue({ txtCreatedBy: this.LoginUserName });
          this.notesModalTitle = 'Add Notes';
          this.issave = 'Save';
          this.Idview = false;
        }
      },
      (err) => {
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(dashBoardSubscription);
  }

  //#region Clear Functions
  clearSeverity() {
    this.severity = '';
    this.noteForm.patchValue({ txtSeverity: '' });
  }
  //To clear Notes Type
  clearNotesType() {
    this.noteForm.patchValue({ drpNotetype: '' });
  }
  //To clear Notes Reason
  // clearNotesReason() {
  //   this.notesReason = '';
  //   this.noteForm.patchValue({ drpNoteReason: '' });
  // }
  clearStatus() {
    this.status = '';
    this.noteForm.patchValue({ drpStatus: '' });
  }
  clearState() {
    this.state = '';
    this.noteForm.patchValue({ drpState: '' });
  }
  clearSubject() {
    //this.subject = '';
    //this.noteForm.patchValue({ txtSubject: '' });
  }
  clearDescription() {
    // this.description = '';
    //this.noteForm.patchValue({ txtDescription: '' });
  }
  clearCreatedBy() {
    this.noteForm.patchValue({ txtCreatedBy: this.LoginUserName });
  }

  getisCallNote() {
    if (this.isCallNote === true) {
      return true;
    } else {
      return '';
    }
  }
  //#endregion

  // On Page Destroy
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  loadPatientDetails(patientId: any) {
    this.patientId = patientId;
    const patientDetails = this.mmOrderService
      .getPatientDetailsforChartIdByPatientId(patientId)
      .subscribe(
        (response) => {
        this.defaultFaxIdV1 = response?.defaultFaxId;
        this.chartNoV1 = response?.chartNo;
        },
        (err) => {

        }
      );
    this.subscription$.push(patientDetails);
  }


}
export function noWhitespaceValidator(control: FormControl) : ValidationErrors | null{
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
}

export function mustBeCheckedValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === true) {
      return null; // The checkbox is checked, so it's valid
    } else {
      return { mustBeChecked: true }; // The checkbox is not checked, so it's invalid
    }
  };
}

export function positiveNumberValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;

  // Check if the value is a number and greater than 0
  if (value !== null && value !== undefined && +value > 0) {
    return null; // Valid, no error
  } else {
    return { positiveNumber: true }; // Error, value not greater than 0
  }
}
