import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import {
  InvoiceDTO,
  InvoiceViewDTO,
  ShippingApprovalDTO,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { ShippingApprovalService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-approval.service';
import { SaleorderTemplateService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder-template.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { InvoiceService } from 'projects/order/src/app/order-proxy/order-management/pay-pal';
import { InvoiceDetailsService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/invoice-details.service';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';

class Product {
  name: string;
  price: number;
  qty: number;
}
class Invoice {
  customerName: string;
  address: string;
  contactNo: number;
  email: string;

  products: Product[] = [];
  additionalDetails: string;
  

  constructor() {
    // Initially one empty product row we will show
    this.products?.push(new Product());
  }
}

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
  providers: [DatePipe],
})
export class InvoiceListComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<InvoiceDTO> =
    new MatTableDataSource<InvoiceDTO>([]);
  products: Product[] = [];

  invoice = new Invoice();
  selectedRange: { startDate: Moment; endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  calendarLocale: LocaleConfig;
  dateRangStatus: boolean = false;
  invoiceForm!: FormGroup;
  fromDate: string;
  toDate: string;
  calendarPlaceholder: string = '';
  arrDisplayedColumns: string[] = [
    'options',
    'sSaleorderId',
    'sPatientId',
    'sPatientName',
    'invoiceCode',
    'invoiceType',
    'branch',
    'creationTime',

  ];
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  isLoading: boolean = false;
  subscription$: Subscription[] = [];

  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchValue : string[] =[];
  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  tenantId: string = "";
  branchForm: FormGroup;
  organizationUnitName: string;

  constructor(
    private title: Title,
    public orderTemplateService: SaleorderTemplateService,
    public invoiceDetailsService: InvoiceDetailsService,
    private datepipe: DatePipe,
    public fb: FormBuilder,
    private formBuilder: FormBuilder,
    private dateValidator: DateValidator,
    private shippingApprovalService: ShippingApprovalService,
    private branchService : OrganizationUnitService
  ) {
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      firstDay: 1,
    };

    this.ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
    };
  }
  ngOnInit(): void {
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.products.push(new Product());
    this.title.setTitle('Qsecure | Invoice List');
    this.createShippingListForm();
    //this.getShippingListTableBySearch();
    //this.getInvoiceListTableBySearch();

    this.invoiceForm = this.fb.group({
      saleorderId: new FormControl(''),
      patientId: new FormControl(''),
      patientName: new FormControl(''),
      invoiceCode: new FormControl(''),
      invoiceType: new FormControl(''),
      createdDate: new FormControl(''),
      dtStartDate: new FormControl(''),
      dtEndDate: new FormControl(''),
    });
    this.getShippingListBySearch();

    this.branchForm = this.formBuilder.group({
      drpBranch : new FormControl(''),
      txtBranch : new FormControl(''),
    });

    this.getBranchList();

  }

  getNextSaturday() {
    const dayINeed = 6; // for Saturday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  datesUpdateds(e) {
    if (e.startDate != null || e.endDate != null) {
      this.dateRangStatus = true;
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
      this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
      this.getShippingListBySearch();
    } else {
      this.dateRangStatus = false;
      (this.fromDate = null), (this.toDate = null);
      this.getShippingListBySearch();
    }
  }

  getNextSunday() {
    const dayINeed = 7; // for Sunday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  // On Page Destroy
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //! Download Order template , Generate Template
  getOrderTemplate(orderValue: {
    orderId: string;
    defaultSaleOrderId?: string;
  }) {
    if (orderValue?.orderId) {
      const orderTemplate = this.orderTemplateService
        .getSaleOrderDocumentByOrderId(orderValue?.orderId)
        .subscribe(
          (response) => {
            if (
              typeof response === 'string' &&
              response &&
              response?.length !== 0
            ) {
              const filelocation: String = String(
                'data:application/pdf;base64,' + response
              );
              const link = document?.createElement('a');
              link.download = orderValue?.defaultSaleOrderId + '.pdf';
              link.href = filelocation?.toString();
              link?.click();
            }
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(orderTemplate);
    }
  }

  getShippingListBySearch() {
    const valueChanges = this.invoiceForm.valueChanges
      .pipe(
        startWith({
          saleorderId: '',
          patientId: '',
          patientName: '',
          invoiceCode: '',
          invoiceType: '',
          createdDate: '',
        }),
        tap((x) => {
          this.isLoading = true;
          this.setTableData([]);
        }),
        filter((e) => e && this.invoiceForm?.valid),
        debounceTime(300),
        map((response) => {
          this.isLoading = true;
          const sValue = {
            sSaleOrdeId: response?.saleorderId?.trim()?.toLowerCase(),
            sPatientId: response?.patientId?.trim()?.toLowerCase(),
            sPatientName: response?.patientName?.trim()?.toLowerCase(),
            sInvoiceCode: response?.invoiceCode?.trim()?.toLowerCase(),
            sInvoiceType: response?.invoiceType?.trim()?.toLowerCase(),
            dtCreatedDate: response?.createdDate?.trim()?.toLowerCase(),
            dtStartDate: this.fromDate?.trim()?.toLowerCase(),
            dtEndDate: this.toDate?.trim()?.toLowerCase(),
          };
          return sValue;
        }),

        switchMap((sValue) =>
          this.invoiceDetailsService.searchInvoiceBySSaleOrdeIdAndSPatientIdAndSPatientNameAndSInvoiceCodeAndSInvoiceTypeAndDtCreatedDateAndDtStartDateAndDtEndDate(
            sValue?.sSaleOrdeId,
            sValue?.sPatientId,
            sValue?.sPatientName,
            sValue?.sInvoiceCode,
            sValue?.sInvoiceType,
            sValue?.dtCreatedDate,
            sValue?.dtStartDate,
            sValue?.dtEndDate
          )
        )
      )
      .subscribe(
        (response) => {
          this.isLoading = false;
          const responseData: InvoiceDTO[] = response.items ?? [];
          this.setTableData(responseData);
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
        }
      );

    this.subscription$.push(valueChanges);
  }

  // getShippingListTableBySearch() {
  //   // const shipppingList = this.shippingForm.valueChanges.pipe(
  //   //   startWith({
  //   //     txtSaleorderId: "",
  //   //     txtPatientId: "",
  //   //     txtPatientName: "",
  //   //     txtCsrName: "",
  //   //     txtStatus: "",
  //   //     txtDos: ""
  //   //   }),
  //   //   filter(x => x && this.shippingForm.valid),
  //   //   map(response => {
  //   //     this.setTableData([]);
  //   //     this.isLoading = true;
  //   //     const searchData = {
  //   //       sSaleOrderId: String(response?.txtSaleorderId)?.trim() ?? "",
  //   //       sDefaultPatientId: String(response?.txtPatientId)?.trim() ?? "",
  //   //       sPatientName: String(response?.txtPatientName)?.trim() ?? "",
  //   //       sUserName: String(response?.txtCsrName)?.trim() ?? "",
  //   //       Status: String(response?.txtStatus)?.trim() ?? "",
  //   //       DOS: String(response?.txtDos)?.trim() ?? "",
  //   //     }
  //   //     return searchData;
  //   //   }),
  //   //   switchMap(x => this.shippingApprovalService.searchShippingApporvalListBySSaleOrderIdAndSDefaultPatientIdAndSPatientNameAndSUserNameAndStatusAndDtDOS(
  //   //     x.sSaleOrderId, x.sDefaultPatientId, x.sPatientName, x.sUserName, x.Status, x.DOS)
  //   //   )).subscribe(response => {
  //   //     this.isLoading = false;
  //   //     const responseData: ShippingApprovalDTO[] = response ?? [];
  //   //     // this.setTableData(responseData);
  //   //   },
  //   //     (err) => {
  //   //       this.setTableData([]);
  //   //       this.isLoading = false;
  //   //       const data: HttpErrorResponse = err;
  //   //       Swal.fire({
  //   //         icon: 'error',
  //   //         text: data?.error?.error?.message,
  //   //       });
  //   //     });
  //   // this.subscription$.push(shipppingList);
  // }
  statusUpdate(value: string, type: string) {
    this.invoiceDetailsService
      .get(value)
      .pipe(
        map((x) => {
          this.setTableData([]);
          x.isApproved = !x.isApproved;
          return x;
        }),
        switchMap((x) => this.invoiceDetailsService.update(x.id, x))
      )
      .subscribe(
        (x) => {
          this.getShippingListBySearch();
          //this.getInvoiceListTableBySearch();
        },
        (err) => {
          this.setTableData([]);
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  // getInvoiceListTableBySearch() {
  //   this.isLoading = true;

  //   const invoiceListSub = this.invoiceDetailsService
  //     .getList(new PagedAndSortedResultRequestDto())
  //     .subscribe(
  //       (response) => {

  //         const responseData: InvoiceDTO[] = response?.items ?? [];

  //         this.setTableData(responseData);
  //         this.isLoading = false;
  //       },
  //       (err) => {
  //         this.setTableData([]);
  //         this.isLoading = false;
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   this.subscription$.push(invoiceListSub);
  // }
  createShippingListForm() {
    // this.shippingForm = this.formBuilder.group({
    //   txtSaleorderId: new FormControl(''),
    //   txtPatientId: new FormControl(''),
    //   txtPatientName: new FormControl(''),
    //   txtCsrName: new FormControl(''),
    //   txtStatus: new FormControl(''),
    //   txtDos: new FormControl('', this.dateValidator.dateVaidator),
    // });
  }
  setTableData(tableData: InvoiceDTO[] = []) {
    this.dataSource = new MatTableDataSource(tableData);
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  generatePDF(value: string, action = 'open') {
    this.invoiceDetailsService
      .getInvoiceDetailsByIdByGInvoiceId(value)
      .subscribe(
        (response) => {
          this.generatePDFByResponse(response);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  generatePDFByResponse(response: InvoiceViewDTO, action = 'open') {
    this.invoice = {
      additionalDetails: '',
      address: '',
      contactNo: 0,
      customerName: 'asdasd',
      email: '',
      products: [
        {
          name: '33',
          price: 33,
          qty: 33,
        },
      ],
    };

    let itemDetails = response?.ltItems?.map((x) => {
      let y = {
        dos:
          (x?.dosFrom ?? '') === ''
            ? ''
            : new Date(x?.dosFrom).toLocaleDateString(),
        code: x?.itemProcedureCode,
        desc: x?.description,
        billAmt: x?.billed,
        priInsPaidAmt: x?.insurancePaid,
        priInsAdjAmt: x?.adjustments,
        sectreInsPaidAmt: 0,
        sectreInsAdjAmt: 0,
        patBillAmt: 0,
        patPaidAmt: 0,
        patWoAmt: 0,
        balAmt: x?.currentBalance,
      };
      return y;
    });

    let docDefinition: TDocumentDefinitions = {
      content: [
        {
          text: 'Patient Statement',
          fontSize: 16,
          alignment: 'center',
          color: '#047886',
        },
        {
          columns: [
            [
              {
                text: `Provider Details`,
                alignment: 'left',
                bold: true,
                fontSize: 14,
              },
              {
                text: `Name :${response?.provider?.fullName ?? ''}
                 Address:${response?.provider?.homeAddress ?? ''}
                 Tel: ${response?.provider?.phone ?? ''}
                 Fax:${response?.provider?.fax ?? ''}`,
                fontSize: 12,
                alignment: 'left',
              },
              { text: ` `, alignment: 'left' },
              {
                text: `RETURN SERVICE REQUESTED : `,
                fontSize: 12,
                alignment: 'left',
              },
              { text: ` `, alignment: 'left' },
              {
                text: `Patient Name :${response?.personalDetails?.fullName}`,
                bold: true,
                fontSize: 14,
              },
              {
                text: ` Address:${
                  response?.addressDetails?.address ??
                  '' + response?.addressDetails?.state ??
                  '' + response?.addressDetails?.country ??
                  ''
                }`,
              },
              {
                text: ` Account: #${response?.personalDetails?.accountNumber}`,
              },
              { text: ` Signature:` },
            ],
            [
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },

              {
                table: {
                  widths: [50, 50],
                  body: [
                    ['Payment Type', ' '],
                    [
                      'Cash',
                      { table: { heights: 5, widths: [10], body: [['    ']] } },
                    ],
                    [
                      'Check',
                      { table: { heights: 5, widths: [10], body: [['    ']] } },
                    ],
                    [
                      'VISA',
                      { table: { heights: 5, widths: [10], body: [['    ']] } },
                    ],
                    [
                      'Master Card',
                      { table: { heights: 5, widths: [10], body: [['    ']] } },
                    ],
                  ],
                },
                layout: 'noBorders',
              },
            ],
            [
              {
                text: `Statement Date : ${new Date()?.toLocaleDateString()}`,
                fontSize: 12,
                alignment: 'left',
              },
              {
                table: {
                  body: [
                    [
                      { text: 'Amount Due', bold: true },
                      { text: 'Amount Paid', bold: true },
                    ],
                    [
                      itemDetails?.reduce(
                        (partialSum, a) => partialSum + +a?.balAmt,
                        0
                      ),
                      itemDetails?.reduce(
                        (partialSum, a) => partialSum + +a?.priInsPaidAmt,
                        0
                      ),
                    ],
                  ],
                },
              },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },

              { text: ` `, alignment: 'left' },
              { text: ` Date:__/__/_____`, alignment: 'left' },
              { text: ` CVV Code:__________`, alignment: 'left' },
              { text: ` Expiration Date:__/__/_____`, alignment: 'left' },
            ],
          ],
        },
        {
          text: ' ',
          fontSize: 12,
          alignment: 'center',
        },
        {
          text: ' ',
          fontSize: 12,
          alignment: 'center',
        },
        {
          text: 'PLEASE DETACH AND RETURN TOP PORTION WITH YOUR PAYMENT',
          fontSize: 12,
          alignment: 'center',
        },
        {
          text: '- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -',
          fontSize: 12,
          alignment: 'center',
        },
        {
          text: ' ',
          fontSize: 12,
          alignment: 'center',
        },
        {
          text: ' ',
          fontSize: 12,
          alignment: 'center',
        },
        {
          table: {
            widths: [
              'auto',
              'auto',
              'auto',

              'auto',
              'auto',
              'auto',

              'auto',
              30,
              30,

              30,
              30,
            ],
            body: [
              [
                {
                  text: `DOS
                         Code`,
                  bold: true,
                },
                { text: 'Desc', bold: true },
                { text: 'Bill Amt', bold: true },

                { text: 'Pri Ins PaidAmt', bold: true },
                { text: 'Pri Ins AdjAmt', bold: true },
                { text: 'Sec/Ter Ins PaidAmt', bold: true },

                { text: 'Sec/Ter Ins AdjAmt', bold: true },
                { text: 'Pat BillAmt', bold: true },
                { text: 'Pat PaidAmt', bold: true },

                { text: 'Pat WoAmt', bold: true },
                { text: 'Pat Bal$', bold: true },
              ],

              ...itemDetails?.map((p) => [
                `${p.dos + ' ' + p.code}`,
                p.desc,
                p.billAmt,
                p.priInsPaidAmt,
                p.priInsAdjAmt,
                p.sectreInsPaidAmt,
                p.sectreInsAdjAmt,
                p.patBillAmt,
                p.patPaidAmt,
                p.patWoAmt,
                p.balAmt,
              ]),
            ],
          },
        },

        {
          text: ' ',
          fontSize: 12,
          alignment: 'center',
        },
        {
          columns: [
            [{ text: `Total Outstanding`, alignment: 'left' }],
            [
              {
                text: itemDetails?.map((p) => [
                  `${p?.dos + ' ' + p?.code}`,
                  p?.desc,
                  p?.billAmt?.toString() ?? '',
                  p?.priInsPaidAmt?.toString() ?? '',
                  p?.priInsAdjAmt?.toString() ?? '',
                  p?.sectreInsPaidAmt?.toString() ?? '',
                  p?.sectreInsAdjAmt?.toString() ?? '',
                  p?.patBillAmt?.toString() ?? '',
                  p?.patPaidAmt?.toString() ?? '',
                  p?.patWoAmt?.toString() ?? '',
                  p?.balAmt?.toString() ?? '',
                ]),
                alignment: 'right',
              },
            ],

            [
              {
                text: `${itemDetails?.reduce(
                  (partialSum, a) => partialSum + +a?.balAmt,
                  0
                )}`,
                alignment: 'right',
              },
            ],
          ],
        },
        {
          text: ` `,
          fontSize: 12,
          alignment: 'left',
        },
        // {
        //   table: {
        //     widths: [
        //       60,
        //       50,
        //       50,
        //       50,
        //       50,
        //       50,
        //       50,
        //       60
        //     ],
        //     body: [
        //       [
        //         { text: "UnApplied", bold: true },
        //         { text: "0-30", bold: true },
        //         { text: "31-60", bold: true },
        //         { text: "61-90", bold: true },
        //         { text: "91-120", bold: true },
        //         { text: "120+", bold: true },
        //         { text: "Total", bold: true },
        //         { text: "Net Due $", bold: true },
        //       ],
        //       ...itemDetails?.map(p => [
        //         `${p.dos
        //           +p.code}`, p.desc,
        //         p.billAmt, p.priInsPaidAmt,
        //         p.priInsAdjAmt, p.sectreInsPaidAmt,
        //         p.sectreInsAdjAmt,
        //         p.patBillAmt, p.patPaidAmt,
        //         p.patWoAmt, p.balAmt
        //       ]),
        //     ]
        //   },
        // },
        {
          text: ` `,
          fontSize: 12,
          alignment: 'left',
        },
        {
          text: ` `,
          fontSize: 12,
          alignment: 'left',
        },
        // {
        //   text: `Last Paid Amount: $ ${0.00}`,
        //   fontSize: 12, alignment: 'left',
        // },
        {
          text: ` `,
          fontSize: 12,
          alignment: 'left',
        },
        {
          text: ` `,
          fontSize: 12,
          alignment: 'left',
        },
        {
          text: `"You can also Pay your bill using credit card from the Patient Portal. Late fee will be accessed after 30days."`,
          fontSize: 12,
          alignment: 'left',
        },
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15],
        },
      },
    };

    if (action === 'download') {
      pdfMake?.createPdf(docDefinition)?.download();
    } else if (action === 'print') {
      pdfMake?.createPdf(docDefinition)?.print();
    } else {
      pdfMake?.createPdf(docDefinition)?.open();
    }
  }

  allBranchSelect(){
    if(this.selectAllBranch.selected){
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    }
    else{
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getBranchList(){
    const getBranchList = this.branchService.getBranchList(this.tenantId).subscribe(response => {
      response?.forEach(element => {
        this.drpBranch.push({
          id: element.id,
          organizationUnitName: element.organizationUnitName,
        })
      });

      this.brnachList = this.branchForm
        ?.get('txtBranch')
        ?.valueChanges?.pipe(
          startWith(''),
          map((value) => 
            this.drpBranch?.filter((option) =>
              option?.organizationUnitName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );


    }, err =>{
      const data : HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getBranchList);
  }
 
}

export interface Branch{
  id?: string;
  organizationUnitName?: string;
}