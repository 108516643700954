import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { MasterProviderService } from 'projects/order/src/app/order-proxy/order-management/inventory';
import { MasterProviderDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto/models';
import {
  BillingMasterDropdownService,
  PatientMasterDropdownService,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { BillingDropdowns } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/billing-dropdowns.enum';
import { BillingMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/billing/models';
import { PayorLevelDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/patient/models';

import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AddInsurancePaymentComponent } from '../add-insurance-payment/add-insurance-payment.component';
import { AddItemPaymentComponent } from '../add-item-payment/add-item-payment.component';
import { ItemPaymentsDTO } from '../claim-proxy/claim-processing-management/rcm/billing-management/posting/dto/models';
import { ItemPaymentService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { MatTableDataSource } from '@angular/material/table';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { ClaimInfoDetailsService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization';
import {
  AddressService,
  PatientPersonalService,
} from 'projects/patient/src/app/patient-proxy/patient-optimization';
import {
  PatientSaleOrderService,
  SaleOrderItemService,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization';
import { NoteType } from 'projects/admin/src/app/warehouse-proxy/inventory/dto';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { PatientNotesTabComponent } from 'projects/patient/src/app/patient-notes-tab/patient-notes-tab.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-add-payments',
  templateUrl: './add-payments.component.html',
  styleUrls: ['./add-payments.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class AddPaymentsComponent implements OnInit, OnDestroy {
  strTitle: 'Add Insurance Payment' | 'Edit Insurance Payment' =
    'Add Insurance Payment';
  @Input() arrTableData: ItemPaymentsDTO[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  isLoading: boolean = true;
  arrDisplayedColumns: string[] = [
    'Options',
    'itemCode',
    'billedAmount',
    'cost',
    'paidAmount',
    'currentBalance',
    'deductAmount',
    'coinsurance',
    'planType',
    'checkNo',
    'checkDate',
    'checkAmount',
    'postingDate',

  ];
  totalBilled: number = 0;
  totalAdjust: number = 0;
  totalCost: number = 0;
  totalInsPaid: number = 0;
  totalBalance: number = 0;
  drpCode: any[] = [];
  drpPayment: any[] = [];
  drpReason: any[] = [];
  strClaimId: string = '';
  strInsPaymentId: string = '';
  subscription$: Subscription[] = [];
  strInsuranceType: string = '';
  strPatientId: string = defaultGuid;
  strOrderId: string = defaultGuid;
  discountPercent: number = 0;
  strSource: string = '';
  strCode: string = '';
  strReason: string = '';
  strType: string = '';
  strInsurance: string = '';
  drpInsurance: PayorLevelDTO[] = [];
  strClaimNumber: string = '';
  lblPatientName: string = '';
  lblclaimId: string = '';
  lblSaleOrderId: string = '';
  saveButtonHide: boolean = false;
  drpItems: any[] = [];
  lblPatientId: string = '';
  lblDOB: string = '';
  lblDOS: string = '';
  lblAddress: string = '';
  lblProvider: string = '';
  drpProviders: MasterProviderDTO[] = [];
  strProviderId: string = '';
  selFile835ResultValue: string = '';
  selFileEOBResultValue: string = '';
  selFileNameList: { name: string; type: string }[] = [];
  strClaimStatus: any;
  expandedElement: any | null;

  constructor(
    public dialog: MatDialog,
    private billingDropdownService: BillingMasterDropdownService,
    private claimInformationService: ClaimInfoDetailsService,
    public activatedRoute: ActivatedRoute,
    private masterProviderService: MasterProviderService,
    private patientMasterDropdownService: PatientMasterDropdownService,
    private itemPaymentService: ItemPaymentService,
    private saleOrderItemService: SaleOrderItemService,
    private patientPersonalService: PatientPersonalService,
    private addressService: AddressService,
    private patientSaleOrderService: PatientSaleOrderService,
    private toastr: ToastrService
  ) { }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.saveButtonHide = true;
    const activatedRoute: Subscription =
      this.activatedRoute?.paramMap?.subscribe((response) => {
        this.strClaimId = response?.get('id') ?? '';
        this.strInsPaymentId = response?.get('paymentId') ?? '';
        this.strTitle =
          this.strInsPaymentId === ''
            ? 'Add Insurance Payment'
            : 'Edit Insurance Payment';
      });
    this.subscription$?.push(activatedRoute);

    this.getDropdown();
    this.activatedRoute?.queryParams?.subscribe((v) => {
      this.strClaimStatus = v?.id ?? 'All';
    });
  }
  getClaimDetailsById() {
    const claimsObs = this.claimInformationService?.get(this.strClaimId);
    const combinedObs: Subscription = claimsObs
      .pipe(
        map((x) => {
          const claimResponse = x;
          this.strClaimNumber = claimResponse?.defaultClaimId ?? '';
          this.strOrderId = claimResponse?.orderId ?? '';
          this.strPatientId = claimResponse?.patientId ?? '';

          this.lblPatientId = claimResponse?.defaultPatientId;
          this.lblSaleOrderId = claimResponse?.defaultSaleOrderId;
          this.lblclaimId = claimResponse?.defaultClaimId ?? '';

          return x;
        }),
        switchMap((x) => this.patientSaleOrderService?.get(this.strOrderId)),
        map((x) => {
          const saleOrderResponse = x;
          this.lblSaleOrderId = saleOrderResponse?.defaultSaleOrderId ?? '';
          return x;
        }),
        switchMap((x) => this.patientPersonalService?.get(this.strPatientId)),
        map((x) => {
          const patientResponse = x;
          this.lblPatientName = patientResponse?.fullName ?? '';
          this.lblDOB = patientResponse?.dateOfBirth ?? '';
          this.lblProvider =
            this.drpProviders?.filter(
              (response) =>
                response?.shortCodeId === patientResponse?.providerId
            )[0]?.fullName ?? '';
          this.strProviderId = patientResponse?.providerId ?? '';
          return x;
        }),
        switchMap((x) =>
          this.saleOrderItemService?.getItemsListByOrderIdByOrderId(
            this.strOrderId
          )
        ),
        map((x) => {
          const itemsResponse = x;
          this.lblDOS = itemsResponse?.[0]?.dosFrom ?? '';
          const items = itemsResponse ?? [];
          this.drpItems = items ?? [];
          return x;
        }),
        switchMap((x) =>
          this.addressService?.getAddressByPatientIdByPatientId(this.strOrderId)
        ),
        map((x) => {
          const addressResponse = x?.[0];
          this.lblAddress =
            ` ${addressResponse?.address + ',' ?? ''} ${addressResponse?.city + ',' ?? ''
            } ${addressResponse?.state + ',' ?? ''} ${addressResponse?.country ?? ''
            }` ?? '';
          return x;
        })
      )
      .subscribe(
        (response) => {
          this.saveButtonHide = false;
          this.getTableData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(combinedObs);
  }

  //! get dropdowns data API fun
  getDropdown() {
    this.patientMasterDropdownService;
    let billingDropdownInput: BillingDropdowns[];
    billingDropdownInput = [
      BillingDropdowns.Codes,
      BillingDropdowns.PaymentTypes,
      BillingDropdowns.Reasons,
    ];
    const getDropdown: Subscription = this.billingDropdownService
      .getBillingDropdownsByInput(billingDropdownInput)
      .subscribe(
        (stateResponse) => {
          let response: BillingMasterDropdownDTO = stateResponse;
          this.drpCode = response?.codes;
          this.drpPayment = response?.paymentTypes;
          this.drpReason = response?.reasons;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(getDropdown);

    const getMasterProviderDropdown: Subscription = this.masterProviderService
      .getList(new PagedAndSortedResultRequestDto())
      .pipe(map((e) => e?.items))
      .subscribe(
        (response) => {
          this.drpProviders = response ?? [];
          this.strClaimId && this.getClaimDetailsById();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(getMasterProviderDropdown);

    const input: PatientDropdowns[] = [PatientDropdowns.PayorLevels];
    const getPatientDropdown: Subscription = this.patientMasterDropdownService
      .getPatientDropdownsByInput(input)
      .subscribe(
        (response) => {
          this.drpInsurance = response?.payorLevels ?? [];
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(getPatientDropdown);
  }

  //! add new payment items
  paymentItemsModalEvent(value?: string, paymentId?: string) {
    if (value !== '') {
      const dialogRef = this.dialog.open(AddItemPaymentComponent, {
        data: {
          insPaymentId: defaultGuid,
          itemPaymentId: value ?? '',
          claimId: this.strClaimId,
          reasonPaymentId: paymentId,
        },
      });
      dialogRef.afterClosed().subscribe(
        () => {
          this.getTableData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      const dialogRef = this.dialog.open(AddItemPaymentComponent, {
        data: {
          insPaymentId: defaultGuid,
          itemPaymentId: value ?? '',
          claimId: this.strClaimId,
          reasonPaymentId: paymentId,
        },
      });
      dialogRef.afterClosed().subscribe(
        () => {
          this.getTableData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }
  getTableData() {
    this.isLoading = true;
    this.arrTableData = [];
    const getinsurancePaymentById: Subscription = this.itemPaymentService
      .getItemPaymentsBasedOnClaimIdByClaimId(this.strClaimId)
      .subscribe(
        (response) => {
          response?.forEach((element) => {
            const mlstAdjustPayments =
              element?.lstAdjustPayments?.map((x) => {
                let e = x;
                e.adjustment = x?.adjustment ?? '';
                e.reasonCode = x?.reasonCode ?? '';
                e.paymentId = x?.paymentId ?? '';
                return e;
              }) ?? [];

            const cBal =
              (
                +Number(element?.deductAmount ?? '0') +
                +Number(element?.coinsurance ?? '0')
              )?.toFixed(2) ?? String(0);

            const itemPaymentData: ItemPaymentsDTO = {
              allowed: element?.allowed ?? '0',
              billed: element?.billed ?? '0',
              checkDate: element?.checkDate ?? '',
              checkNo: element?.checkNo ?? '',
              claimId: element?.claimId ?? '',
              claimNumber: element?.claimNumber ?? '',
              coinsurance: element?.coinsurance ?? '0',
              creationTime: element?.creationTime ?? '',
              creatorId: element?.creatorId ?? '',
              currentBalance: +Number(element?.checkAmount) === 0
                ? element?.billed ?? '0'
                : String(cBal),
              lastModificationTime: element?.lastModificationTime ?? '',
              lastModifierId: element?.lastModifierId ?? '',
              ediBlobName: element?.ediBlobName ?? '',
              eobBlobName: element?.eobBlobName ?? '',
              lstAdjustPayments: mlstAdjustPayments ?? [],
              planType: element?.planType ?? '',
              postingStatus: element?.postingStatus ?? '',
              itemProcedureCode: element?.itemProcedureCode ?? '',
              insurancePaid: element?.insurancePaid ?? '',
              insurancePaymentId: element?.insurancePaymentId ?? '',
              id: element?.id ?? '',
              isDeleted: element?.isDeleted ?? false,
              deleterId: element?.deleterId ?? '',
              deletionTime: element?.deletionTime ?? '',
              downloadedZipName: element?.downloadedZipName ?? '',
              reasonCode: '',
              deductAmount: element?.deductAmount ?? '0',
              ediFileContentAsBase64String: this.selFile835ResultValue,
              eobFileContentAsBase64String: this.selFileEOBResultValue,
              postingDate: element?.postingDate ?? '',
              checkAmount: element?.checkAmount ?? '0',
              patientName: '',
              defaultPatientId: '',
              patientId: '',
              eobPdfBlobName: ''
            };
            this.arrTableData?.push(itemPaymentData);
          });
          this.isLoading = false;
          this.dataSource = new MatTableDataSource(
            this.arrTableData?.reverse() ?? []
          );
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        (err: HttpErrorResponse) => {
          this.isLoading = false;
          this.dataSource = new MatTableDataSource(this.arrTableData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(getinsurancePaymentById);
    // if (response) {
    //   const url =
    //     '/#/ViewDocumentPerviewByBlobId/' + response?.eobBlobName + '/' + 4;
    //   window.open(url, '_blank');
    // }
  }

  deleteItemPayment(value?: string) {
    this.saveButtonHide = true;
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        value &&
          this.itemPaymentService.delete(value).subscribe(
            (response) => {
              this.saveButtonHide = false;
              // Swal.fire({
              //   title: 'Success',
              //   html: 'Deleted Successfully',
              //   icon: 'success',
              //   timer: 3000,
              //   timerProgressBar: true,
              // });
              this.toastr.success('Deleted Successfully','Success')
              this.getTableData();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
      }
    });
  }

  addInsurancePayments(value?: string) {
    if (value !== '') {
      const dialogRef = this.dialog.open(AddInsurancePaymentComponent, {
        data: {
          insPaymentId: this.strInsPaymentId,
          itemPaymentId: value ?? '',
          claimId: this.strClaimId,
        },
      });
      dialogRef.afterClosed().subscribe(
        () => {
          this.getTableData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      const dialogRef = this.dialog.open(AddInsurancePaymentComponent, {
        data: {
          insPaymentId: this.strInsPaymentId,
          itemPaymentId: value ?? '',
          claimId: this.strClaimId,
        },
      });
      dialogRef.afterClosed().subscribe(
        () => {
          this.getTableData();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        patientID: this.strPatientId,
        notesId: defaultGuid,
        orderId: defaultGuid,
        claimId: defaultGuid,
        noteType: NoteType.invoice
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientID: this.strPatientId,
        pageType: NoteType.invoice,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
}
