<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 d-flex flex-row">
          <ng-container *ngFor="let data of menus">
            <label [ngClass]="getArrowClass(data)" [ngStyle]="{ 'background-color': data.color }"
              (click)="binActiveTab(data)"
              class="pointer customPointingLabel claim-status-label rounded-border down mb-2 py-2 px-2 text-center mr-1"
              matlabel>
              {{ data.status }} ({{ data.count }})
            </label>
          </ng-container>
        </div>
      </div>

      <div class="card card-body">
        <div class="d-flex justify-content-center" *ngIf="loadInboundData">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>

        <div class="row">
        <div class="col-12" *ngIf="!loadInboundData">
          <app-document-bin-queue-table [inBoundTableData]="inboundTableData" [activeTabBin]="activeTabBin" [inboundStatusId]="inboundStatusId"></app-document-bin-queue-table>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
