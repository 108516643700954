import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { ViewPatientLedgerComponent } from '../../view-patient-ledger/view-patient-ledger.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { clsEOBERAReportDTO, clsEOBERAReportSearch, clsEOBStatusDTO, clsPayerDetailDTO, clsPayerSearch } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/dto';
import { Observable, Subscription } from 'rxjs';
import { ItemPaymentService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { DatePipe } from '@angular/common';
import { EOBERAReportService } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/eoberareport.service';
import { map, startWith } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-eob_era_bin_queue',
  templateUrl: './eob_era_bin_queue.component.html',
  styleUrls: ['./eob_era_bin_queue.component.scss']
})
export class Eob_era_bin_queueComponent implements OnInit {
  isLoading:boolean=false;
  eobSearchForm: FormGroup;
  defaultGuid = defaultGuid;
  @Input() inBoundTableData: any[];
  @Input() activeTabBin: any;
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Output() patientEntryCount: EventEmitter<number> =
  new EventEmitter<number>();
  @ViewChild('tableContainer', { read: ViewContainerRef })
  tableContainer: ViewContainerRef;
  myTable2: DataTables.Api;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement2: DataTableDirective;
  ltPayors: clsPayerDetailDTO[] = [];
  filteredPayors: Observable<clsPayerDetailDTO[]>;
  subscription$: Subscription[] = [];
  eobData: clsEOBERAReportDTO[] = [];
  ltStatus: clsEOBStatusDTO[] = [];
  filteredStatuses: Observable<clsEOBStatusDTO[]>;
  eobTable: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    language: {
      search: '',
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    columnDefs: [{ targets: 1, orderable: false },
      { targets: [7,9,10,11], visible: false }
    ],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'EOB_ERA List',
        exportOptions: {
          columns: ':not(:first-child)', // Skip the first column
          // columns: function (idx, data, node) {
          //   return idx !== 0 && idx !== 1;
          // }
        },
      },
    ], initComplete: function (this: any) {
      const searchInput = document.querySelector(
        '.dataTables_wrapper .dataTables_filter'
      ) as HTMLElement;
      if (searchInput) {
        searchInput.style.float = 'left';
      }
    },
  };
  constructor(
    private toaster: ToastrService,
    private renderer: Renderer2,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private eobService: EOBERAReportService,
    private commonService: CommonService,
    private itemPaymentService: ItemPaymentService,
    private loaderService: LoaderService,
    private toasterService: ToastrService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initializeForms();
    this.loadDropdowns();
    // this.buildFilterParams();
  }
  ngAfterViewInit(): void {
    if (this.datatableElement2 && this.datatableElement2.dtInstance) {
      this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
        this.addPageNavigationControls();
      });
    } else {
      setTimeout(() =>  this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
        this.addPageNavigationControls();
      }), 500);
    }
  }
  initializeForms() {
    this.eobSearchForm = this.fb.group({
      txtRemittanceStartDate: new FormControl(''),
      txtRemittanceEndDate: new FormControl(''),
      txtCreatedStartDate: new FormControl(''),
      txtCreatedEndDate: new FormControl(''),
      drpPayer: new FormControl(''),
      txtPayerFilter: new FormControl(''),
      txtAmount: new FormControl(''),
      txtPaymentMethod: new FormControl(''),
      txtCheckNumber: new FormControl(''),
      drpStatus: new FormControl(''),
      txtStatusFilter: new FormControl(''),
    });
  }

  loadDropdowns() {
    this.loadPayors('');
    this.loadStatus();
  }

  searchPayor(value: any) {
    this.loadPayors(value);
  }

  loadPayors(value: string) {
    let payorsList =
      this.eobSearchForm.value.drpPayer === '' ||
      this.eobSearchForm.value.drpPayer === null ||
      this.eobSearchForm.value.drpPayer === undefined
        ? []
        : this.eobSearchForm.value.drpPayer;

    let params: clsPayerSearch = {
      iMode: 4,
      sSearch: value,
      ltExisting: payorsList,
    };

    const payorSearch = this.eobService
      .drpSearchPayerBySearch(params)
      .subscribe(
        (response) => {
          this.ltPayors = response.items ?? [];
          this.filteredPayors = this.eobSearchForm
            .get('txtPayerFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltPayors?.filter((option) =>
                  option?.payerName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );

    this.subscription$.push(payorSearch);
  }
  loadStatus() {
    const status = this.eobService.drpEOBStatus().subscribe(
      (response) => {
        this.ltStatus = response.items ?? [];
        this.filteredStatuses = this.eobSearchForm
          .get('txtStatusFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.ltStatus?.filter((option) =>
                option?.statusName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (error) => {
        console.error('Error:', error);
      }
    );
    this.subscription$.push(status);
  }
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  onCreatedDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (
      this.eobSearchForm.value.txtCreatedStartDate &&
      this.eobSearchForm.value.txtCreatedEndDate
    ) {
      this.buildFilterParams();
    }
  }
  onRemittanceDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (
      this.eobSearchForm.value.txtRemittanceStartDate &&
      this.eobSearchForm.value.txtRemittanceEndDate
    ) {
      this.buildFilterParams();
    }
  }
  buildFilterParams() {
    let payer =
      this.eobSearchForm.value.drpPayer === null ||
      this.eobSearchForm.value.drpPayer.length === 0
        ? []
        : this.eobSearchForm.value.drpPayer;
    let status =
      this.eobSearchForm.value.drpStatus === null ||
      this.eobSearchForm.value.drpStatus?.length === 0
        ? []
        : this.eobSearchForm.value.drpStatus;

    let checkNumber = this.eobSearchForm?.value?.txtCheckNumber?.trim() ?? '';
    let amount =
      this.eobSearchForm?.value?.txtAmount === '' ||
      this.eobSearchForm?.value?.txtAmount === null
        ? null
        : this.eobSearchForm?.value?.txtAmount;
    let createdFromDate =
      this.eobSearchForm?.value?.txtCreatedStartDate == null ||
      this.eobSearchForm?.value?.txtCreatedStartDate == ''
        ? null
        : this.datepipe.transform(
            this.eobSearchForm?.value?.txtCreatedStartDate,
            'MM/dd/yyyy'
          );
    let createdToDate =
      this.eobSearchForm?.value?.txtCreatedEndDate == null ||
      this.eobSearchForm?.value?.txtCreatedEndDate == ''
        ? null
        : this.datepipe.transform(
            this.eobSearchForm?.value?.txtCreatedEndDate,
            'MM/dd/yyyy'
          );

    let remittanceFromDate =
      this.eobSearchForm?.value?.txtRemittanceStartDate == null ||
      this.eobSearchForm?.value?.txtRemittanceStartDate == ''
        ? null
        : this.datepipe.transform(
            this.eobSearchForm?.value?.txtRemittanceStartDate,
            'MM/dd/yyyy'
          );

    let remittanceToDate =
      this.eobSearchForm?.value?.txtRemittanceEndDate == null ||
      this.eobSearchForm?.value?.txtRemittanceEndDate == ''
        ? null
        : this.datepipe.transform(
            this.eobSearchForm?.value?.txtRemittanceEndDate,
            'MM/dd/yyyy'
          );
    let params: clsEOBERAReportSearch = {
      ltPayers: payer,
      ltStatus: status,
      sCheckNumber: checkNumber,
      dAmount: amount,
    };
    // dtRemitanceFrom: remittanceFromDate,
    // dtRemitanceTo: remittanceToDate,
    // dtCreatedFrom: createdFromDate,
    // dtCreatedTo: createdToDate,
    this.getEOBERAReport(
      params,
      remittanceFromDate,
      remittanceToDate,
      createdFromDate,
      createdToDate
    );
  }
  getEOBERAReport(
    params: clsEOBERAReportSearch,
    remittanceFrom,
    remittanceTo,
    createdFrom,
    createdTo
  ) {
    this.isLoading = true;
    const report = this.eobService
      .getEOBERAReportsBySearchAndDtRemitanceFromAndDtRemitanceToAndDtCreatedFromAndDtCreatedTo(
        params,
        remittanceFrom,
        remittanceTo,
        createdFrom,
        createdTo
      )
      .subscribe(
        (response) => {
          this.inBoundTableData = response?.items ?? [];
          this.inBoundTableData = this.inBoundTableData.map((data) => {
            return {
              ...data,
              dtCreated: this.commonService.getFormattedDateTimeZone(
                data.dtCreated
              ),
              dtRemitance: this.commonService.getFormattedDateZone(
                data.dtRemitance
              ),
              dtModified: this.commonService.getFormattedDateTimeZone(
                data.dtModified
              ),
            };
          });
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }
  clearRemittanceDate() {
    this.eobSearchForm.patchValue({
      txtRemittanceStartDate: null,
      txtRemittanceEndDate: null,
    });
  }
  clearCreatedDate() {
    this.eobSearchForm.patchValue({
      txtCreatedStartDate: null,
      txtCreatedEndDate: null,
    });
  }
  clearPayer() {
    this.eobSearchForm.patchValue({
      drpPayer: null,
    });
  }
  clearPaymentMethod() {
    this.eobSearchForm.patchValue({
      drpPaymentMethod: null,
    });
  }
  clearStatus() {
    this.eobSearchForm.patchValue({
      drpStatus: null,
    });
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  openViewNotesPopup(patientID) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: patientID,
        pageType: 'Patient Note',
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        this.toaster.error(data?.error?.error?.message);
      }
    );
  }
  viewPatientLedger(patientId: string) {
    const dialogRef = this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    // this.dialog.afterAllClosed.subscribe((resposne) => {});

    const closeDialog = dialogRef.afterClosed().subscribe(
      (isSaved: any) => {
        if (isSaved) {
          // this.reloadClaimTable.emit();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }
   //#region DoubleClick Copy Text
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  //#endRegio DoubleClick Copy Text

  getTotalInboundData(event: Event, inboundData: any) {
    const target = event.target as HTMLElement;
    const tr = target.closest('tr');
    const icon = target as HTMLElement;
    this.getDataTableData2(tr, inboundData, icon);
  }

  //#region History Tables

  //DataTable Data Get Method and Toggle Notes Row in Table
  async getDataTableData2(tr: any, inboundData: any, icon: HTMLElement): Promise<void> {
    this.myTable2 = await this.datatableElement2.dtInstance;
    if (tr) {
      const row = this.myTable2.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        icon.innerText = 'add';
      } else {
        // Open this row
        row.child(this.format2(row.data(), inboundData)).show();
        row.child().addClass('no-padding');
        icon.innerText = 'remove';
      }
    }
  }
    //Add Dynamic Row
    format2(data: any, inboundData: any): HTMLElement {
      const table = this.renderer.createElement('table');
      const thead = this.renderer.createElement('thead');
      const tbody = this.renderer.createElement('tbody');

      const thRow = this.renderer.createElement('tr');
       const headers = [
      { text: 'Created By', key: 'CreatedBy' },
      { text: 'Last Modified By', key: 'LastModifiedBy' },
      { text: 'Last Modified Date', key: 'LastModifiedDate' },
      { text: 'Status', key: 'Status' },
    ];


      headers.forEach(header => {
        // if (shouldSkipKey(header.key)) return;
        const th = this.renderer.createElement('th');
        this.renderer.appendChild(th, document.createTextNode(header.text));
        this.renderer.appendChild(thRow, th);
      });

      this.renderer.appendChild(thead, thRow);
      this.renderer.appendChild(table, thead);
      this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

      if (!Array.isArray(inboundData)) {
        inboundData = [inboundData];
      }

      inboundData.forEach((value: any) => {
        const trData = this.renderer.createElement('tr');
        const cells = [
                { text: value.createdByName, key: 'CreatedBy' },
                { text: value.modifiedByName, key: 'LastModifiedBy' },
                { text: value.dtModified, key: 'LastModifiedDate' },
                { text: value.eobStatusName, key: 'Status' },
              ];

        cells.forEach(cell => {
          // if (shouldSkipKey(cell.key)) return;

          const td = this.renderer.createElement('td');
            this.renderer.appendChild(td, document.createTextNode(cell.text));

          this.renderer.appendChild(trData, td);
        });

        this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
        this.renderer.appendChild(tbody, trData);
      });

      this.renderer.appendChild(table, tbody);
      return table;
    }
    onButtonClick(value:any){

    }
    addPageNavigationControls(){
      let buttonsElement =  $(".dt-buttons")[0];

      // $("<button class='dt-button' id='btnDefaultClms' type='button'><span>Show Default Column</span></button>").appendTo(buttonsElement);
      // $("<button class='dt-button' id='btnAllClms' type='button'><span>Show All Column</span></button>").appendTo(buttonsElement);

      $("<span> : <span>").appendTo(buttonsElement);
      if(this.currentPage == 1)
      {
        $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
        $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
      }
      else
      {
        $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
        $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
       }

      $("<span> Page <span>").appendTo(buttonsElement);
      $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);
      $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
      $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);

      if(this.currentPage >= this.totalPages)
      {
        $("<button disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
        $("<button disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
      }
      else
      {
         $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
         $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
      }

      $('#btnNextPage').on('click', (event) => this.changePage(true));
      $('#btnPrevPage').on('click', (event) => this.changePage(false));
      // $(".buttons-colvis").on('click', (event) => this.fnColumnSelectionClick());
      // $('#btnDefaultClms').on('click', (event) => this.fnGetColumnVisiblity("DEFAULT"));
      // $('#btnAllClms').on('click', (event) => this.fnGetColumnVisiblity("ALL"));
      $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
      // $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
      // $('#btnLastPage').on('click', (event) => this.nevigatePage.emit(this.totalPages));
      $('#btnFirstPage').on('click', (event) => this.nevigatePage(1));
      $('#btnLastPage').on('click', (event) => this.nevigatePage(this.totalPages));
     }
     changePage(isNext:boolean){
      if(isNext)
        this.currentPage++;
      else
        this.currentPage--;
      this.nevigatePage(this.currentPage);
    }

    fnGotoPage(){
      let pageValue = $("#txtGotoPage").val();
      let pageno = parseInt(pageValue.toString());
      if(pageno > 0 && this.currentPage !=  pageno && pageno <= this.totalPages)
      {
        this.nevigatePage(pageno);
      }
    }
    nevigatePage(pageNumber){
      this.currentPage = pageNumber;
      this.buildFilterParams();
    }
}
