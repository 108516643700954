<br />

<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h4 class="customThemeClass"><b>Extracted Patient Details</b></h4>
    </div>
    <div class="col-lg-6 text-right">
      <button class="text-right close" aria-label="Close" mat-dialog-close> <b> X </b>
      </button>
    </div>
  </div>
  <div class="card card-body">
    <form [formGroup]="ExPatientForm">
      <div class="row">
        <div class="col-lg-12">
          <mat-card-content>
            <div class="row">
              <mat-form-field class="col-3">
                <mat-label> Chart Id <span class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtChartId" matInput maxlength="40" formControlName="txtChartId" type="text" />
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label> Branch <span class="asterisk">*</span> </mat-label>
                <mat-select maxlength="40" [(value)]="location" formControlName="drpLocation">
                  <mat-option *ngFor="let location of drpLocationTypes" [value]="location.organizationUnitName">
                    {{ location.organizationUnitName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="
                ExPatientForm?.get('drpLocation')?.touched &&
                ExPatientForm?.get('drpLocation')?.errors?.required
              ">
                  Branch is a required field!
                </mat-error>
                <button mat-button (click)="location='';$event.stopPropagation()" *ngIf="location" matSuffix
                  (click)="clearLocation()" mat-icon-button >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label> First Name <span class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtFirstName" matInput maxlength="40" formControlName="txtFirstName" type="text" />
                <mat-error *ngIf="
                ExPatientForm?.get('txtFirstName')?.touched &&
                ExPatientForm?.get('txtFirstName')?.errors?.required
              ">
                  First Name is a required field!
                </mat-error>
                <mat-error *ngIf="
                ExPatientForm?.get('txtFirstName')?.touched &&
                ExPatientForm?.get('txtFirstName')?.errors?.sAlphabets
              ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Middle Name</mat-label>
                <input autocomplete="new-txtMiddleName" matInput maxlength="40" formControlName="txtMiddleName" type="text" />
                <mat-error *ngIf="
                ExPatientForm?.get('txtMiddleName')?.touched &&
                ExPatientForm?.get('txtMiddleName')?.errors?.sAlphabets
              ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label> Last Name <span class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtLastName" matInput maxlength="40" formControlName="txtLastName" type="text" />
                <mat-error *ngIf="
            ExPatientForm?.get('txtLastName')?.touched &&
            ExPatientForm?.get('txtLastName')?.errors?.required
          ">
                  Last Name is a required field!
                </mat-error>
                <mat-error *ngIf="
                ExPatientForm?.get('txtLastName')?.touched &&
                ExPatientForm?.get('txtLastName')?.errors?.sAlphabets
              ">
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label> Birth Date <span class="asterisk">*</span></mat-label>

                <input autocomplete="new-txtBirthdate" [max]="BirthdateMaxDate" formControlName="txtBirthdate" matInput maxlength="40" type="text"
                  placeholder="(MM/DD/YYYY)" [matDatepicker]="txtBirthdate" [min]="BirthdateMinDate" />
                <mat-datepicker-toggle matSuffix [for]="txtBirthdate"> </mat-datepicker-toggle>
                <mat-datepicker #txtBirthdate> </mat-datepicker>
                <mat-error *ngIf="
              ExPatientForm?.get('txtBirthdate')?.touched &&
              ExPatientForm?.get('txtBirthdate')?.errors">
                  Enter Valid date
                </mat-error>
                <mat-error
                  *ngIf=" ExPatientForm?.get('txtBirthdate')?.touched && ExPatientForm?.get('txtBirthdate')?.errors?.required">
                  Birth Date is a required field!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label> Email Address</mat-label>
                <input autocomplete="new-txtBillingEmailAddress" formControlName="txtBillingEmailAddress" matInput
                pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                  [textMask]="{ mask: emailMask }" maxlength="40" type="text" />
                <mat-error *ngIf="
                ExPatientForm?.get('txtBillingEmailAddress')?.touched &&
                ExPatientForm?.get('txtBillingEmailAddress')?.errors?.pattern
              ">
                  This is not a valid Email!!!
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="text-lg-center">
                  <button [disabled]="!(!ExPatientForm.invalid && ExPatientForm.dirty ) "
                    (click)="savePersonal()" mat-button class="buttonColor"> Save </button>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
