
<!-- <div class="modal-header">
  <h2 class="modal-title"><b>Join with Fax - {{ joinFaxID ? faxId +' '+ '/' + ' '+ joinFaxID : faxId}}</b></h2> -->
  <!-- <i aria-label="Close" mat-dialog-close class="fa fa-times"></i> -->
  <!-- <div class="page-title-box page-title-right">
  <button mat-button class="buttonColor text-right" [disabled]="lisDocIds.length===0"
  (click)="multipleJoin()"
  >
  Multiple Fax Join -->
  <!-- <mat-icon *ngIf="isSpinnerDisabled[iTableRow]">
    <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
    </mat-spinner>
  </mat-icon> -->
<!-- </button>
</div>
  <mat-icon mat-dialog-close>close</mat-icon>
</div> -->
<div class="container-fluid" >
  <!-- Headers -->
  <div class="row">
    <div class="col-lg-12">
      <div class="d-flex align-items-center justify-content-between" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
      style="cursor:move">
        <h2 class="modal-title"><b>Join with Fax - {{ joinFaxID ? faxId +' '+ '/' + ' '+ joinFaxID : faxId}}</b></h2>
        <div class="page-title-box page-title-right p-2" >
          <mat-radio-group [(ngModel)]="selectedOption" class="mr-2">
            <mat-radio-button class="active" (click)="singleJoin()" [value]="0">Single Fax Join</mat-radio-button>
            <mat-radio-button class="active pl-2" (click)="multiJoin()" [value]="1">Multiple Fax Join</mat-radio-button>
          </mat-radio-group>
          <button mat-button class="buttonColor text-right" [disabled]="lisDocIds.length===0 ||isMultipleSpinnerDisabled"
          (click)="multipleJoin()"
          >
          Join Faxes
            <!-- <mat-spinner *ngIf="isMultipleSpinnerDisabled" class="spinner-border spinner-border-sm" diameter="20">
            </mat-spinner> -->
        </button> &nbsp; &nbsp; &nbsp;
          <button mat-dialog-close  mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
<div class="card card-body">
  <!-- <div class="row align-items-center">
    <div class="col-lg-9">
      <h4 class="customThemeClass">
        <b>Join with Fax - {{ joinFaxID ? faxId +' '+ '/' + ' '+ joinFaxID : faxId}}</b><br>
      </h4>
    </div>
    <div class="col-lg-3" style="text-align-last: right">
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div> -->
  <div class="row">
    <div class="col-lg-12">
      <!-- <div class="card elevated-div"> -->
      <!-- <div class="card-header">Shipping List</div> -->
      <!-- <div class="card-body pb-0"> -->
        <!-- <h2 class="cutompoint font-weight-bolder" *ngIf="isLoaded">
      Location - <span class="txtColor font-weight-bolder">{{branchName}}</span>
        </h2> -->
        <div class="col-lg-12 content table-responsive p-0">
          <div class="d-flex justify-content-center" *ngIf="!isLoaded">
            <!-- <mat-spinner></mat-spinner> -->
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
          </div>
          <table *ngIf="isLoaded" datatable [dtOptions]="dtJoinTblOptions" class="tbl hover row-border hover w-100 display" id="joinData">
            <thead>
              <tr>
                <!-- <th [matTooltip]="'Location'">Location</th> -->
                <th>
                  <mat-checkbox (click)="onUserSelectionChange($event)"
                   (change)="$event ? masterToggle() : null"
                   [checked]="selection.hasValue()" [(ngModel)]="checkAll"
                   [disabled]="allCheckboxdisabled || isSigleJoin"
                   style="margin-right: 3px">
                  </mat-checkbox>
                </th>
                <th [matTooltip]="'Fax Id'">Fax Id</th>
                <th [matTooltip]="'Chart Id'">Chart Id</th>
                <th [matTooltip]="'Patient Name'">Patient Name</th>
                <th [matTooltip]="'DOB'">DOB</th>
                <th [matTooltip]="'Fax Folder'">Fax Folder</th>
                <th [matTooltip]="'Location'">Location</th>
                <th [matTooltip]="'Practice Name'">Practice Name</th>
                <th [matTooltip]="'Page No'">Pages</th>
                <th [matTooltip]="'Document Name'">Document Name</th>
                <th [matTooltip]="'Received Date'">Received Date</th>
                <th><mat-icon>apps</mat-icon></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of joinTableData">
                <!-- <span style="display: none;">{{iTableRow + 1}}</span> -->
                <!-- <td (dblclick)="onRowDoubleClick(data?.branchName || '-')" [matTooltip]="data?.branchName || '-'">{{ data.branchName ? data.branchName : '-'}}</td> -->
                <td class="checkbox-and-link">
                  <mat-checkbox  [checked]="selection.isSelected(data)" [disabled]="isSigleJoin"
                      (change)="onCheckboxChange($event,data.documentId)" (click)="$event.stopPropagation()" ></mat-checkbox>
                </td>
                <td
                class="custom-width"
                (dblclick)="onRowDoubleClick(data?.faxId || '-')"
              >
                <!-- <a [class.disabled]="isEmpty(data.blobName)" [routerLink]="'/patient_preview_document/' + data.blobName + '/' + data.defaultFaxId" target="_blank" class="preview-link" >{{ data?.defaultFaxId || '-'}}</a> -->
                <a
                  [matTooltip]="data?.faxId || '-'"
                  [class.disabled]="
                    isEmpty(data.documentId) || isEmpty(data.faxId)
                  "
                  [routerLink]="
                    '/patient_preview_tiff_document/' +
                    data.faxId +
                    '/' +
                    data.documentId
                  "
                  target="_blank"
                  class="preview-link"
                  >{{ data?.faxId || "-" }}</a
                >
              </td>
                <!-- <td (dblclick)="onRowDoubleClick(data?.faxId || '-')" [matTooltip]="data?.faxId || '-'">{{ data.faxId ? data.faxId : '-'}}</td> -->
                <td (dblclick)="onRowDoubleClick(data?.chartId || '-')">
                  <a [class.disabled]="isEmpty(data?.chartId) || isEmpty(data?.patientId)" class="preview-link"
                      [routerLink]="'/patientCreateEditTab/' + data?.patientId" target="_blank"
                      [matTooltip]="data?.chartId || '-'">{{ data?.chartId || '-'}}</a>
               </td>
                <td (dblclick)="onRowDoubleClick(data?.patientName || '-')" [matTooltip]="data?.patientName || '-'">{{ data.patientName ? data.patientName : '-'}}</td>
                <td (dblclick)="onRowDoubleClick(getFormattedDateV1(data?.dob))" [matTooltip]="getFormattedDateV1(data?.dob)">{{
                  getFormattedDateV1(data?.dob)}}</td>
                <td (dblclick)="onRowDoubleClick(data?.inboundStatus || '-')" [matTooltip]="data?.inboundStatus || '-'">{{ data.inboundStatus ? data.inboundStatus : '-'}}</td>
                <td (dblclick)="onRowDoubleClick(data?.branchName || '-')" [matTooltip]="data?.branchName || '-'">{{ data.branchName ? data.branchName : '-'}}</td>
                <td (dblclick)="onRowDoubleClick(data?.practiceName || '-')" [matTooltip]="data?.practiceName || '-'">{{ data.practiceName ? data.practiceName : '-'}}</td>
                <td (dblclick)="onRowDoubleClick(data?.pageNo || '-')" [matTooltip]="data?.pageNo || '-'">{{ data.pageNo ? data.pageNo : '-'}}</td>
                <td (dblclick)="onRowDoubleClick(data?.documentName || '-')" [matTooltip]="data?.documentName || '-'">{{ data.documentName ? data.documentName : '-'}}</td>
                <td (dblclick)="onRowDoubleClick(getFormattedDateTimeZone(data?.uploadedDate))" [matTooltip]="getFormattedDateTimeZone(data?.uploadedDate)">{{
                  getFormattedDateTimeZone(data?.uploadedDate) }}</td>

                <td>
                  <button mat-button class="buttonColor" [disabled]="isButtonDisabled||!isSigleJoin"
                    (click)="joinFax(data.documentId,data.faxId, iTableRow)">
                    Join
                    <!-- <mat-icon *ngIf="isSpinnerDisabled[iTableRow]">
                      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
                      </mat-spinner>
                    </mat-icon> -->
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>

</div>
