<div class="container-fluid">
    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <div class="page-title-box page-title-left pb-0">
                    <h3 class="font-weight-bolder mb-3">
                        <b class="customThemeClass">Master / Resupply</b>
                    </h3>
                </div>
                <div class="page-title-box page-title-right pb-0">
                    <div class="d-flex align-items-center">
                        <mat-radio-group [(ngModel)]="selectedOption" class="radio-buttons"
                            (change)="getResuppltTableData()">
                            <mat-radio-button [value]="0" class="active"><b>All</b></mat-radio-button>
                            <mat-radio-button [value]="1" class="active p-2"
                                [checked]="2"><b>Active</b></mat-radio-button>
                            <mat-radio-button [value]="2" class="active"><b>Inactive</b></mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-body">
        <mat-accordion class="spaces">
            <mat-expansion-panel [expanded]="step" (afterExpand)="toggle()" (closed)="toggleV2()">
                <mat-expansion-panel-header>
                    <mat-panel-title class="customThemeClass">
                        <b>
                            <ng-container *ngIf="
                    resupplyId === '';
                    then thenTitleTemplate;
                    else elseTitleTemplate
                  "></ng-container>
                            <ng-template #thenTitleTemplate> Add Resupply Details </ng-template>
                            <ng-template #elseTitleTemplate> Edit Resupply Details </ng-template>
                        </b>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-card-content>
                    <form [formGroup]="resupplyForm">
                        <div class="row">

                            <!--InSurance Category Drop Down-->
                            <mat-form-field class="col-3">
                                <mat-label>Insurance Category<span class="asterisk">*</span></mat-label>
                                <mat-select formControlName="InsCategory">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search"
                                            noEntriesFoundLabel="No Matches found"
                                            [formControl]="InsCategFilterControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let Ins of InsurenceCategoryList" [value]="Ins.id">
                                        {{ Ins.insuranceCategory }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="
                        resupplyForm?.get('InsCategory')?.touched &&
                        resupplyForm?.get('InsCategory')?.errors?.required
                      ">
                                    Insurance Category is a required field!
                                </mat-error>
                            </mat-form-field>

                            <!-- Product Category Drop Down-->
                            <mat-form-field class="col-3">
                                <mat-label>Product Category<span class="asterisk">*</span></mat-label>
                                <mat-select formControlName="drpCategory">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search"
                                            noEntriesFoundLabel="No Matches found"
                                            formControlName="txtCategoryFilter"></ngx-mat-select-search>
                                    </mat-option>

                                    <mat-option *ngFor="let categories of filteredCategoryDetails | async"
                                        [value]="categories.id">
                                        {{ categories.productCategoryName }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="
                                resupplyForm?.get('InsCategory')?.touched &&
                                resupplyForm?.get('InsCategory')?.errors?.required
                              ">
                                    Product Category is a required field!
                                </mat-error>
                            </mat-form-field>

                            <!--Quantity-->
                            <mat-form-field class="col-3">
                                <mat-label>Quantity<span class="asterisk">*</span></mat-label>
                                <input autocomplete="new-txtQuantity" matInput maxlength="10"
                                    formControlName="txtQuantity" type="text"
                                    oninput="this.value = this.value.replace(/[^0-9]+/g, '');" />
                                <mat-error *ngIf="
                                    resupplyForm?.get('InsCategory')?.touched &&
                                    resupplyForm?.get('InsCategory')?.errors?.required
                                  ">
                                    Quantity is a required field!
                                </mat-error>
                            </mat-form-field>

                            <!--Due Days-->
                            <mat-form-field class="col-3">
                                <mat-label>Due Days<span class="asterisk">*</span></mat-label>
                                <input autocomplete="new-txtDueDays" matInput maxlength="5" formControlName="txtDueDays"
                                    type="text" oninput="this.value = this.value.replace(/[^0-9]+/g, '');" />
                                <mat-error *ngIf="
                                    resupplyForm?.get('InsCategory')?.touched &&
                                    resupplyForm?.get('InsCategory')?.errors?.required
                                  ">
                                    Due Days is a required field!
                                </mat-error>
                            </mat-form-field>


                            <div class="col-12 pt-3 text-center">
                                <button mat-button class="buttonColor mb-2 mr-2"
                                    [disabled]="!!resupplyForm.invalid || saveButtonHide" (click)="saveResupply()">
                                    {{ resupplyId ? 'Update' : 'Save' }}
                                </button>
                                <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="resetResupplyForm()">
                                    Reset
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-card-content>
            </mat-expansion-panel>
        </mat-accordion>

        <div class="card card-body mt-3">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-center" *ngIf="isTableLoading">
                        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                    </div>
                    <div class="container-fluid">
                        <app-master-resupply-table *ngIf="!isTableLoading" [resupplyTableData]="resupplyTableData"
                            (edit)="editResupply($event)"
                            (isLoadTbl)="getResuppltTableData()"></app-master-resupply-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>