<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-0">
        <h3 class="font-weight-bolder mb-2 mt-2">
          <b class="customThemeClass">Inventory Management / Insurance Allowables</b>
        </h3>

        <div class="page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">
            <!-- (click)="exportTableData()" -->

            <!-- <li class="breadcrumb-item ">Inventory</li>
            <li class="breadcrumb-item ">Product Allowables List</li> -->
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <!-- <div class="card card-body"> -->
      <mat-accordion>
        <mat-expansion-panel [expanded]="step">
          <mat-expansion-panel-header class="pl-4">
            <mat-panel-title class="header-text">
              <!-- <ng-container> Add Insurance Allowables</ng-container><br /><br /> -->
              <!-- <ng-container
                *ngIf="insuranceAllowablesId === defaultGuid; else elseTitleTemplate"
              >
                <b class="text-small"
                  >Add Insurance Allowables
                </b> </ng-container -->
              <!-- ><br /><br />
              <ng-template #elseTitleTemplate> -->
              <b>Add / Edit Insurance Allowables </b>
              <!-- </ng-template> -->
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- <mat-card-content> -->

          <mat-card-content>
            <div class="row">
              <div class="col-8">
                <form [formGroup]="allowablesForm">
                  <div class="row">
                    <mat-form-field class="col-4">
                      <!-- (selectionChange)="getHCPCCodeAndAllowables()" -->
                      <!-- <mat-label>Insurance Category<span class="asterisk">*</span> -->
                        <mat-label>
                          <!-- <div class="select-placeholder-container" *ngIf="isCategorydrpLoading">
                            <span>Insurance Category</span>
                            <mat-spinner class="spinner" diameter="20"></mat-spinner>
                          </div> -->
                          <div class="select-placeholder-container" *ngIf="isCategorydrpLoading">
                            <span>Loading...</span>
                            <mat-spinner class="spinner" diameter="20"></mat-spinner>
                          </div>
                          <span *ngIf="!isCategorydrpLoading">Insurance Category <span class="asterisk">*</span></span>
                          <!-- <span *ngIf="!isCategorydrpLoading">Insurance Category</span> -->
                      </mat-label>
                      <!-- </mat-label> -->
                      <mat-select class="dd-text" formControlName="txtInsCategory" autocomplete="off"
                        multiple (selectionChange)="onSelectionChange($event)"  (openedChange)="onDropOpenCategory($event)">
                        <mat-option>
                          <ngx-mat-select-search [placeholderLabel]="drpDownSearchMessage()" [noEntriesFoundLabel]="drpDownMessage()"
                            formControlName="filterCategory" hideClearSearchButton="true"
                            (keyup)="getCategoryDropdowns($event.target.value,(allowablesForm.value.txtInsCategory||[]))">
                          </ngx-mat-select-search>
                        </mat-option>
                        <!-- <mat-option *ngIf="!CategorySelectAllOption" [value]="'selectAll'">Select All</mat-option>
                        <mat-option *ngIf="CategorySelectAllOption" [value]="'selectAll'">Deselect All</mat-option> -->
                        <mat-option *ngFor="let provider of filterInsCategory | async" [value]="provider.id">
                          {{ provider.insuranceCategory }}
                        </mat-option>
                      </mat-select>
                      <!-- <button
                            mat-button
                            (click)="
                              product = undefined; $event.stopPropagation()
                            "
                            *ngIf="product"
                            matSuffix
                            (click)="clearMainProduct()"
                            mat-icon-button
                          >
                            <mat-icon>close</mat-icon>
                          </button> -->
                      <mat-error *ngIf="
                              allowablesForm?.get('txtInsCategory')?.touched &&
                              allowablesForm?.get('txtInsCategory')?.errors
                                ?.required
                            ">
                        Insurance Category is a required field!
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-4">
                      <mat-label>
                      <div class="select-placeholder-container" *ngIf="isHcpcdrpLoading">
                        <span>Loading...</span>
                        <mat-spinner class="spinner" diameter="20"></mat-spinner>
                      </div>
                        <span *ngIf="!isHcpcdrpLoading">Hcpc Code <span class="asterisk">*</span></span>
                      </mat-label>
                      <mat-select class="dd-text" formControlName="txtHcpcCode" autocomplete="off" multiple
                        (selectionChange)="onSelectionChangeV1($event)"
                        (openedChange)="onDropOpenHcpc($event)">
                        <mat-option>
                          <ngx-mat-select-search [placeholderLabel]="drpDownSearchMessageV1()" [noEntriesFoundLabel]="drpDownMessageV1()"
                            formControlName="filterHcpcCode" hideClearSearchButton="true"
                            (keyup)="hcpcDropdown( $event.target.value)"></ngx-mat-select-search>
                        </mat-option>
                        <!-- <mat-option *ngIf="!hcpcSelectAllOption" [value]="'selectAll'">Select All</mat-option>
                        <mat-option *ngIf="hcpcSelectAllOption" [value]="'selectAll'">Deselect All</mat-option> -->
                        <mat-option *ngFor="let provider of filterProducts | async" [value]="provider">
                          {{ provider.hcpcCode }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="
                              allowablesForm?.get('txtHcpcCode')?.touched &&
                              allowablesForm?.get('txtHcpcCode')?.errors
                                ?.required
                            ">
                        HCPC code is a required field!
                      </mat-error>
                    </mat-form-field>


                    <mat-form-field class="col-4">
                      <mat-label>
                        Allowable($)
                        <span class="asterisk">*</span></mat-label>

                      <!-- (paste)="numbersAndfloatOnly($event)" -->
                      <input class="dd-text" formControlName="txtAllowables" autocomplete="off"
                        pattern="^\d*(\.\d{0,2})?$" (keypress)="numbersAndfloatOnly($event)" (paste)="onPaste($event)"
                        matInput maxlength="10" type="text" />
                      <mat-error
                        *ngIf="allowablesForm?.get('txtAllowables')?.touched && allowablesForm?.get('txtAllowables')?.errors?.required">
                        Allowable Amount is a required field!
                      </mat-error>
                      <mat-error *ngIf="
                           allowablesForm?.get('txtAllowables')?.errors?.pattern">
                        Allowable Amount after decimal two digit only allowed!
                      </mat-error>
                    </mat-form-field>
                  </div>
                </form>
              </div>
              <div class="col-4">
                <button mat-button class="buttonColor mr-2" [disabled]="allowablesForm.invalid || saveDisable"
                  (click)="saveAllowables()">
                  Save/Update
                  <mat-icon *ngIf='saveDisable'>
                    <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
                  </mat-icon>
                </button>
                <button mat-button (click)="resetForm()" class="resetclr buttonColor">
                  Reset
                </button>
              </div>
            </div>

          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- </div> -->

      <div class="mt-2 card">
        <!-- <div class="card card-body"> -->
        <!-- <div class="row "> -->
        <!-- <div class="card"> -->
        <div class="card-header header-text">Insurance Allowable List</div>
        <div class="card-body">
          <div class="d-flex justify-content-center" *ngIf="loadTable">
            <!-- <mat-spinner></mat-spinner> -->
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
          </div>
          <div *ngIf="!loadTable" class="table table-responsive">
            <app-insurance-allowables-tableV1 [insuranceTableData]="insuranceTableData"
            (nevigatePage)="changePage($event)" [currentPage]="currentPage" [totalPages]="totalPages" (reloadTable)="getListOfUpdatedAllowable()"></app-insurance-allowables-tableV1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
