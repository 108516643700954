import { groupBy, map, startWith, tap } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  RegionCountriesDTO,
  RegionStatesDTO,
  RegionCitiesDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto';
import { Observable, Subscription } from 'rxjs';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { AddressService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { PatientAddressDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { AddressType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data';
import { defaultCountry } from '../../enums/allenums.enum';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { noWhitespaceValidator } from 'projects/patient/src/app/status/status.component';
import { PatientAddressValidationDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';

@Component({
  selector: 'app-create-update-patient-address',
  templateUrl: './create-update-patient-address.component.html',
  styleUrls: ['./create-update-patient-address.component.scss'],
})
export class CreateUpdatePatientAddressComponent
  implements OnInit, AfterViewInit
{
  addressForm: FormGroup;
  isSaveBtnDisabled: boolean = false;
  addressText: string = '';
  phoneCode: string = '';
  country: string = '';
  drpCountries: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: any[] = [];
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue =
          formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }
  State: string = '';
  City: string = '';
  addressType: number = 1;
  emailMask: any;
  subscription$: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private regionDropdownService: RegionDropdownService,
    private addressService: AddressService,
    private dialog: MatDialogRef<CreateUpdatePatientAddressComponent>,
    private communicationService: CommunicationService,
    private toaster: ToastrService,
    private mmOrderService: MmOrderService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      chartId: string;
      addressId: string;
    } = {
      patientId: defaultGuid,
      chartId: '',
      addressId: defaultGuid,
    }
  ) {}

  ngOnInit(): void {
    this.initializeForms();
    this.getPatientBasicDetails();
    this.getCountryDropdown();
    this.emailMask = emailMask;
    // this.patchDefaultValues();
  }
  patchDefaultValues() {
    if (
      this.addressForm.value.txtNameLine == '' ||
      this.addressForm.value.txtNameLine == null ||
      this.addressForm.value.txtNameLine == undefined
    ) {
      this.addressForm.patchValue({
        txtNameLine: this.patientBasicDetails?.fullName,
      });
    }
    if (
      this.addressForm.value.txtDescription == '' ||
      this.addressForm.value.txtDescription == null ||
      this.addressForm.value.txtDescription == undefined
    ) {
      this.addressForm.patchValue({
        txtDescription: 'Delivery Address',
      });
    }
  }
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  initializeForms() {
    this.addressForm = this.fb.group({
      txtId: new FormControl(''),
      txtDescription: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtNameLine: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtAddress: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtCountry: new FormControl('', [Validators.required]),
      txtCountryFilter: new FormControl(''),
      txtState: new FormControl('', [Validators.required]),
      txtStateFilter: new FormControl(''),
      txtCity: new FormControl('', [Validators.required]),
      txtCityFilter: new FormControl(''),
      txtPostalCode: new FormControl('', [Validators.required]),
      txtHomeNo: new FormControl(''),
      txtCellNo: new FormControl(''),
      txtEmail: new FormControl(''),
      chkActive: new FormControl(''),
    });
  }
  patientBasicDetails: any;
  getPatientBasicDetails() {
    this.addressService
      .getPatientBasicDetailsByPatientId(this.data.patientId)
      .subscribe(
        (response) => {
          this.patientBasicDetails = response;
          if (
            this.addressForm.value.txtNameLine == '' ||
            this.addressForm.value.txtNameLine == null ||
            this.addressForm.value.txtNameLine == undefined
          ) {
            this.addressForm.patchValue({
              txtNameLine: this.patientBasicDetails?.fullName,
            });
          }
          if (
            this.addressForm.value.txtDescription == '' ||
            this.addressForm.value.txtDescription == null ||
            this.addressForm.value.txtDescription == undefined
          ) {
            this.addressForm.patchValue({
              txtDescription: 'Delivery Address',
            });
          }
        },
        (err) => {}
      );
  }

  //Update Character Count for TextArea
  updateCharacterCount(maxlength: number) {
    // Ensure the text length does not exceed the maximum length
    if (this.addressText?.length > maxlength) {
      this.addressText = this.addressText?.substr(0, maxlength);
    }
  }

  //To load the Address Details if the Id is present
  loadEdit() {
    this.addressService.get(this.data.addressId).subscribe(
      (response) => {
        this.addressForm.patchValue({
          txtNameLine:
            response?.nameLine == null || response?.nameLine == ''
              ? this.patientBasicDetails?.fullName
              : response?.nameLine,
          txtDescription:
            response?.description == null || response?.description == ''
              ? 'Shipping Address'
              : response?.description,
          txtAddress: response?.address,
          txtPostalCode: response?.postalCode,
          txtHomeNo: response?.phone,
          txtCellNo: response?.mobile,
          txtEmail: response?.emailId,
        });
        this.addressType = response?.addressType;
        this.country = response?.country;
        this.State = response?.state;

        (this.State != null || this.State != undefined) &&
        this.onChangeState(this.country);

        (this.State != null || this.State != undefined) &&
          this.onChangeCity(this.State, this.country);


        this.addressForm.patchValue({
          txtCountry: response?.country,
          txtState: response?.state,
          txtCity: response?.city,
        });
      },
      (err) => {}
    );
  }
  getCountryDropdown() {
    this.drpCountries = [];
    this.country = defaultCountry.US;
    const getCountrySubscription = this.regionDropdownService
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .pipe(
        map((response) => {
          this.drpCountries = response?.items ?? [];

          this.phoneCode =
            response?.items?.filter(
              (x) => x.countryShortName === defaultCountry.US
            )?.[0]?.countryPhoneCode ?? '';
          return response;
        }),
        tap((x) => {
          this.country = defaultCountry.US;

          this.addressForm?.patchValue(
            { txtCountry: defaultCountry.US },
            { emitEvent: true }
          );
        }),
        map((x) => {
          this.onChangeState(defaultCountry.US);
        })
      )
      .subscribe(
        (response) => {
          this.filteredCountries = this.addressForm
            .get('txtCountryFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCountries.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
          if (
            this.data.addressId != defaultGuid &&
            this.data.addressId != null &&
            this.data.addressId != ''
          ) {
            this.loadEdit();
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getCountrySubscription);
  }
  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.addressForm.patchValue({
      txtState: null,
      txtCity: null,
    });
    this.drpStates = [];
    this.drpCities = [];
    value !== null &&
      value !== undefined &&
      this.regionDropdownService
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response?.items ?? [];
            this.phoneCode = response?.items[0]?.countryPhoneCode;
            this.filteredStates = this.addressForm
              ?.get('txtStateFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    // return this.drpStates.length !== 0;
    return this.drpStates && this.drpStates?.length !== 0;
  }
  //On Change City Dropdown
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.addressForm.patchValue({
      txtCity: null,
    });
    this.drpCities = [];
    state !== null &&
      state !== undefined &&
      this.regionDropdownService
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response?.items;

            this.filteredCities = this.addressForm
              .get('txtCityFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return this.drpCities.length !== 0;
  }
  //Clear State dropdown
  clearState() {
    this.State = '';
    this.addressForm.patchValue({ txtState: '' });
  }
  //Clear City dropdown
  clearCity() {
    this.City = '';
    this.addressForm.patchValue({ txtCity: '' });
  }
  saveAddress() {
    this.isSaveBtnDisabled = true;
    let phone=null;
    let mobile= null;
    if(this.addressForm.value.txtHomeNo){
      phone=this.addressForm.value.txtHomeNo.length===10?
      this.addressForm.value.txtHomeNo.slice(0,3)+"-"+this.addressForm.value.txtHomeNo.slice(3,6)+"-"+this.addressForm.value.txtHomeNo.slice(6,15):
      this.addressForm.value.txtHomeNo;
    }
    if(this.addressForm.value.txtCellNo){
      mobile=this.addressForm.value.txtCellNo.length===10?
      this.addressForm.value.txtCellNo.slice(0,3)+"-"+this.addressForm.value.txtCellNo.slice(3,6)+"-"+this.addressForm.value.txtCellNo.slice(6,15):
      this.addressForm.value.txtCellNo;
    }
    let address: PatientAddressDTO = {
      patientId: this.data.patientId,
      addressType: this.addressType,
      address: this.addressForm.value.txtAddress.trim(),
      city: this.addressForm.value.txtCity,
      state: this.addressForm.value.txtState,
      country: this.addressForm.value.txtCountry,
      postalCode: this.addressForm.value.txtPostalCode.trim(),
      emailId: this.addressForm.value.txtEmail.toLowerCase().trim(),
      phone: phone,
      mobile: mobile,
      nameLine: this.addressForm.value.txtNameLine.trim(),
      description: this.addressForm.value.txtDescription.trim(),
    };

    // if (this.data.addressId)
    if (
      this.data.addressId != defaultGuid &&
      this.data.addressId != null &&
      this.data.addressId != ''
    ) {
      this.addressService.update(this.data.addressId, address).subscribe(
        (response) => {
          // this.communicationService.triggerAddressDetails(response);
          this.communicationService.triggerAddressList(this.data.patientId);
          this.isSaveBtnDisabled = false;
          var lstSavedCity =this.drpCities?.filter((x) => x?.cityName === this.addressForm?.get('txtCity')?.value?.trim());
          if(lstSavedCity.length>0){
            lstSavedCity.forEach(element => {
              element.cityId=null;
             });
            }
          this.toaster.success('Shipping Address Updated Successfully!');
          this.resetForm();
          this.dialog.close();
        },
        (err) => {
          this.isSaveBtnDisabled = false;
          this.toaster.error('Something Went Wrong!');
        }
      );
    } else {
      this.addressService.create(address).subscribe(
        (response) => {
          // this.communicationService.triggerAddressDetails(response);
          this.communicationService.triggerAddressList(this.data.patientId);
          this.isSaveBtnDisabled = false;
          var lstSavedCity =this.drpCities?.filter((x) => x?.cityName === this.addressForm?.get('txtCity')?.value?.trim());
          if(lstSavedCity.length>0){
            lstSavedCity.forEach(element => {
              element.cityId=null;
             });
            }
          this.toaster.success('Shipping Address Added Successfully!');
          this.resetForm();
          this.dialog.close();
        },
        (err) => {
          this.isSaveBtnDisabled = false;
          this.toaster.error('Something Went Wrong!');
        }
      );
    }
    // this.addressService.create(address).subscribe(
    //   (response) => {
    //     this.communicationService.triggerAddressDetails(response);
    //     this.communicationService.triggerAddressList(this.data.patientId);
    //     this.isSaveBtnDisabled = false;
    //     this.toaster.success('Shipping Address Added Successfully!');
    //     this.resetForm();
    //     this.dialog.close();
    //   },
    //   (err) => {
    //     this.isSaveBtnDisabled = false;
    //     this.toaster.error('Something Went Wrong!');
    //   }
    // );
  }

  //Method to Reset the form
  resetForm() {
    this.addressForm.reset();
  }
  //Method to verify address using google api
  verifyAddress() {

    let deliveryAddress: PatientAddressValidationDTO = {
      address_line1: this.addressForm.value.txtAddress.trim() ?? '',
      city: this.addressForm.value.txtCity.trim() ?? '',
      state_province: this.addressForm.value.txtState.trim() ?? '',
      phone: this.addressForm.value.txtCellNo.trim() ?? '',
      postal_code: this.addressForm.value.txtPostalCode.trim() ?? '',
    };

    const addressVerify = this.mmOrderService
      .checkAddressValidation_GoogleByInput(deliveryAddress)
      .subscribe(
        (response) => {
          response?.isValid
            ? this.toaster.success('Address Verified Successfully!')
            : this.toaster.warning('Invalid Address!');
        },
        (err) => {}
      );
    this.subscription$.push(addressVerify);
  }

  //Verify & Save Address
  verifyandSaveAddress() {
    this.isSaveBtnDisabled = true;
    let phone=null;
    let mobile= null;
    if(this.addressForm.value.txtHomeNo){
    phone=this.addressForm.value.txtHomeNo.length===10?
    this.addressForm.value.txtHomeNo.slice(0,3)+"-"+this.addressForm.value.txtHomeNo.slice(3,6)+"-"+this.addressForm.value.txtHomeNo.slice(6,15):
    this.addressForm.value.txtHomeNo;
    }
    if(this.addressForm.value.txtCellNo){
     mobile=this.addressForm.value.txtCellNo.length===10?
    this.addressForm.value.txtCellNo.slice(0,3)+"-"+this.addressForm.value.txtCellNo.slice(3,6)+"-"+this.addressForm.value.txtCellNo.slice(6,15):
    this.addressForm.value.txtCellNo;
    }
    const chkIsNewCity  =
      this.drpCities?.filter((x) => x?.cityName === this.addressForm.value?.txtCity)?.[0]
        ?.cityId ?? null;
    // let phone=this.addressForm.value.txtHomeNo;
    // let mobile= this.addressForm.value.txtCellNo;
    let address: PatientAddressDTO = {
      patientId: this.data.patientId,
      addressType: this.addressType,
      address: this.addressForm.value.txtAddress.trim(),
      city: this.addressForm.value.txtCity,
      state: this.addressForm.value.txtState,
      country: this.addressForm.value.txtCountry,
      postalCode: this.addressForm.value.txtPostalCode.trim(),
      emailId: this.addressForm.value.txtEmail.toLowerCase().trim(),
      // phone: this.addressForm.value.txtHomeNo,
      // mobile: this.addressForm.value.txtCellNo,
      phone: phone,
      mobile: mobile,
      nameLine: this.addressForm.value.txtNameLine.trim(),
      description: this.addressForm.value.txtDescription.trim(),
      isNewCity: chkIsNewCity==1?true: false,
    };
    let Delphone=null;
    if(this.addressForm.value.txtHomeNo){
      Delphone=this.addressForm.value.txtHomeNo.length===10?
    this.addressForm.value.txtHomeNo.slice(0,3)+"-"+this.addressForm.value.txtHomeNo.slice(3,6)+"-"+this.addressForm.value.txtHomeNo.slice(6,15):
    this.addressForm.value.txtHomeNo;
    }
    let deliveryAddress: PatientAddressValidationDTO = {
      address_line1: this.addressForm.value.txtAddress.trim() ?? '',
      city: this.addressForm.value.txtCity.trim() ?? '',
      state_province: this.addressForm.value.txtState.trim() ?? '',
      // phone: this.addressForm.value.txtCellNo.trim() ?? '',
      phone: Delphone,
      postal_code: this.addressForm.value.txtPostalCode.trim() ?? '',
    };
    // console.log(deliveryAddress,this.data.addressId)
    if (
      this.data.addressId != defaultGuid &&
      this.data.addressId != null &&
      this.data.addressId != ''
    ) {
      this.mmOrderService
        .checkAddressValidation_GoogleV1ByAddressIdAndInput(
          this.data.addressId,
          deliveryAddress
        )
        .subscribe(
          (response) => {
            if (!response?.isValid) {
              Swal.fire({
                // title: 'Are you sure you want to Delete?',
                text: 'This address is not Verified in Google Map.Are you sure you want to continue and Update this address?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#f46a6a',
                confirmButtonText: 'Yes',
              }).then((result) => {
                if (result.value) {
                  this.addressService
                    .update(this.data.addressId, address)
                    .subscribe(
                      (response) => {
                        this.communicationService.triggerAddressList(
                          this.data.patientId
                        );
                        this.isSaveBtnDisabled = false;
                        this.toaster.success(
                          'Shipping Address Updated Successfully!'
                        );
                        this.resetForm();
                        this.dialog.close();
                      },
                      (err) => {
                        this.isSaveBtnDisabled = false;
                        this.toaster.error('Something Went Wrong!');
                      }
                    );
                }else{
                  this.isSaveBtnDisabled = false;
                }
              });
            } else {
              address.isGoogleValidated = true;
              this.addressService
                .update(this.data.addressId, address)
                .subscribe(
                  (response) => {
                    this.communicationService.triggerAddressList(
                      this.data.patientId
                    );
                    this.isSaveBtnDisabled = false;
                    this.toaster.success(
                      'Shipping Address Updated Successfully!'
                    );
                    this.resetForm();
                    this.dialog.close();
                  },
                  (err) => {
                    this.isSaveBtnDisabled = false;
                    this.toaster.error('Something Went Wrong!');
                  }
                );
            }
          },
          (err) => {
            this.isSaveBtnDisabled = false;
          }
        );
    } else {
      this.mmOrderService
        .checkAddressValidation_GoogleV1ByAddressIdAndInput(
          this.data.addressId,
          deliveryAddress
        )
        .subscribe(
          (response) => {
            if (!response?.isValid) {
              Swal.fire({
                // title: 'Are you sure you want to Delete?',
                text: 'This address is not Verified in Google Map.Are you sure you want to continue and save this address?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#f46a6a',
                confirmButtonText: 'Yes',
              }).then((result) => {
                if (result.value) {
                  this.addressService.create(address).subscribe(
                    (response) => {
                      // this.communicationService.triggerAddressDetails(response);
                      this.communicationService.triggerAddressList(
                        this.data.patientId
                      );
                      this.isSaveBtnDisabled = false;
                      this.toaster.success(
                        'Shipping Address Added Successfully!'
                      );
                      this.resetForm();
                      this.dialog.close();
                    },
                    (err) => {
                      this.isSaveBtnDisabled = false;
                      this.toaster.error('Something Went Wrong!');
                    }
                  );
                }else{
                  this.isSaveBtnDisabled = false;
                }
              });
            } else {
              address.isGoogleValidated = true;
              this.addressService.create(address).subscribe(
                (response) => {
                  // this.communicationService.triggerAddressDetails(response);
                  this.communicationService.triggerAddressList(
                    this.data.patientId
                  );
                  this.isSaveBtnDisabled = false;
                  this.toaster.success('Shipping Address Added Successfully!');
                  this.resetForm();
                  this.dialog.close();
                },
                (err) => {
                  this.isSaveBtnDisabled = false;
                  this.toaster.error('Something Went Wrong!');
                }
              );
            }

            // this.isSaveBtnDisabled = false;
          },
          (err) => {
            this.isSaveBtnDisabled = false;
          }
        );
    }
  }
  addNewCity(){
    if((this.addressForm.value.txtCityFilter)!== undefined && (this.addressForm.value.txtCityFilter) !==null &&
     (this.addressForm.value.txtCityFilter).trim() !=='' && (this.addressForm.value.txtCityFilter).trim().length<=40){
      // const objWithIdIndex = this.drpCities.findIndex((obj) => obj.cityId === 1);
      // if(objWithIdIndex !== -1){
      // this.drpCities.splice(objWithIdIndex, 1);
      // }
      const newCityName=this.addressForm.value.txtCityFilter.trim().toLowerCase();
      var alreadyAdd=this.drpCities?.filter((obj) => (obj.cityName).trim().toLowerCase() === newCityName);
      if(alreadyAdd.length===0){
   let newCity = {
      cityName:this.addressForm.value.txtCityFilter,
      cityId:1
    };

    this.drpCities?.push(newCity);
    this.addressForm.patchValue({ txtCity: this.addressForm.value.txtCityFilter });
  }
  }
  }
  cityDrpDownMessage(){
    if (this.addressForm.value.txtState === ''||this.addressForm.value.txtState === null || this.addressForm.value.txtState === undefined) {
      return 'No Matches found';
    } else if((this.addressForm.value.txtCityFilter === ''||this.addressForm.value.txtCityFilter === null || this.addressForm.value.txtCityFilter === undefined) ?
    (this.addressForm.value.txtCityFilter !== ''&& this.addressForm.value.txtCityFilter !== null && this.addressForm.value.txtCityFilter !== undefined)
    : (this.addressForm.value.txtCityFilter).trim().length>40){
      return 'maximum 40 characters only allowed';
    }else {
      return 'Enter a City Name';
    }
  }
}
