import { Component, Input, OnInit } from '@angular/core';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import {
  ColorCodingDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ViewChild } from '@angular/core';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/inbound-document.service';
import { StatusService } from '../patient-proxy/patient/status.service';
import { CreateUpdateInboundStatusDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dropdowns/dto';
// import { noWhitespaceValidator } from '../add-note-tab/add-note-tab.comp//onent';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatRadioChange } from '@angular/material/radio';
import { ActiveStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/active-status.enum';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  userStatusId = 1;
  statusId = '';
  emptyguid: string = defaultGuid;
  dtOptions: any;
  tableData$: Subscription;
  @Input() clientId: string = '';
  step: boolean = false;
  AppontmentForm: FormGroup;
  isShowSpinner: boolean = false;
  saveButtonHide: boolean;
  bgcolor: string;
  mainStatusId: string = defaultGuid;
  isActive: any;
  defaultGuid = defaultGuid;
  tenantId: string;
  isLoading: boolean;
  totalCount: number;
  faxDetails: any;
  selectedOption: number;
  txtStatusName: string = '';
  txtSortedId: number;
  isSortedNoExists: boolean = false;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public disabled = false;
  public color: ThemePalette = 'primary';
  public touchUi = false;
  dataSource: MatTableDataSource<any>;
  $subscription: Subscription[] = [];
  tableData: CustomColorCodingDTO[] = [];
  wipTableData: any;
  issaving = false;
  inboundStatusDetails: CreateUpdateInboundStatusDTO;
  // displayedColumns: string[] = [
  //   'options',
  //   'InboundStatus',
  //   'creationTime',
  //   'Status',
  // ];
  durationShow: boolean = false;
  WIPStatusId = ActiveStatus.Active;
  tableDatas: any;
  isFieldDisabled: boolean = false;
  sortedId: any;
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
  constructor(
    private formBuilder: FormBuilder,
    private statusService: StatusService,
    private InboundDocumentService: InboundDocumentService,
    private toastr: ToastrService,
    public title: Title,
    private communicationService: CommunicationService,
    private commonService:CommonService
  ) {
    this.communicationService.functionLoadWipStatusListv1$.subscribe(() => {
      this.GetStatusFilterList();
    });
  }
  ngOnInit(): void {

    this.selectedOption = 1;
    this.dtOptions = {
      responsive: true,
      pageLength: 10,
      lengthMenu: [10, 25, 50, 100, 200],
      paging: false,
      scrollCollapse: true,
      scrollY: '300px',
      scrollX: true,
      info: false,
      columnDefs: [
        { targets: [0, 1, 3], orderable: false },
      ],
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'colvis',
          // columns: [2, 3]
        }
      ]
    };
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    // this.step = true;
    // this.AppontmentForm = this.formBuilder.group({
    //   txtStatusName: new FormControl('', [
    //     Validators.required, noWhitespaceValidator
    //   ]),
    //   // txtSortedId: new FormControl('')
    // });
    this.GetStatusFilterList();

    // this.AppontmentForm.get('txtStatusName').valueChanges.subscribe((a) => {
    //   this.bgcolor = a;
    // });
    this.title.setTitle('Qsecure | Bin');

    // this.AppontmentForm.controls['txtSortedId'].disable();
  }
  // checkSortedIdExists() {
  //   const sortedId = this.AppontmentForm.get('txtSortedId').value;

  //   this.InboundDocumentService
  //     .checksortedNoExists(sortedId)
  //     .subscribe(
  //       (response) => { // Assuming exists is a boolean property

  //         this.isSortedNoExists = response;
  //         if(this.isSortedNoExists===true){
  //           this.saveButtonHide=true;
  //         }else{
  //           this.saveButtonHide=false;
  //         }

  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  // }

  // StatusFilter(status:number){
  // const statusList = this.InboundDocumentService
  // .StatusFilter(status)
  // .subscribe(
  //   (response) => {
  //     // this.tableData = response?.items?.map((x, i = 1) => {
  //     //   const y = {
  //     //     sNo: ++i,
  //     //     ...x,
  //     //   };
  //     //   return y;
  //     // });
  //     this.tableDatas=response;
  //     this.isShowSpinner = false;
  //     this.dataSource = new MatTableDataSource(this.tableDatas);
  //     this.dataSource.sort = this.sort;
  //     this.dataSource.paginator = this.paginator;
  //   },
  //   (err) => {
  //     this.saveButtonHide = false;
  //     this.isShowSpinner = false;
  //     const data: HttpErrorResponse = err;
  //   }
  // );
  // this.subscription$.push(statusList);
  // }


  // wipStatusFilter(status: number) {
  //   this.isLoading = true;
  //    const faxData=this.InboundDocumentService.getInboundStatusFilter===(status).subscribe(
  //      (response) => {
  //        this.faxDetails = response;

  //        this.isLoading = false;
  //      },
  //      (error) => {
  //        console.error('Error fetching branch details:', error);
  //        this.isLoading = false;
  //      }
  //    );
  //    this.$subscription.push(faxData);
  //  }

  // To get the Inbound status List
  // GetStatusList() {
  //   // this.isShowSpinner = true;
  //   this.isLoading = true;
  //   this.tableData$?.unsubscribe();
  //   const getWIPTableData = this.InboundDocumentService
  //     .getStatusList(new PagedAndSortedResultRequestDto())
  //     .subscribe(

  //       (response) => {

  //         this.wipTableData = response
  //           ?.items?.map((x, i = 1) => {
  //             const y = {
  //               sNo: ++i,
  //               ...x,
  //             };
  //             return y;
  //           });


  //         this.isLoading = false;
  //       },
  //       (err) => {
  //         this.isLoading = false;
  //         this.wipTableData = [];
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }


  //       // (response) => {
  //       //   this.tableData = response
  //       //   ?.items?.map((x, i = 1) => {
  //       //     const y = {
  //       //       sNo: ++i,
  //       //       ...x,
  //       //     };
  //       //     return y;
  //       //   });


  //       //   this.isShowSpinner = false;
  //       //   this.dataSource = new MatTableDataSource(this.tableData);
  //       //   this.dataSource.sort = this.sort;
  //       //   this.dataSource.paginator = this.paginator;
  //       //   // this.StatusFilter(1);

  //       // },
  //       // (err) => {
  //       //   this.saveButtonHide = false;
  //       //   this.isShowSpinner = false;
  //       //   const data: HttpErrorResponse = err;
  //       // }
  //     );
  //   this.tableData$ = getWIPTableData;
  //   // this.$subscription.push(statusList);
  // }
  GetStatusFilterList() {

    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const getWIPTableData = this.InboundDocumentService
      .getStatusFilterList(this.selectedOption)
      .subscribe(
        (response) => {
          this.wipTableData = response;
          this.wipTableData = this.wipTableData.map((data) => {
            return {
              ...data,
              creationTime: this.commonService.getFormattedDateTimeZone(
                data.creationTime
              ),
              lastModificationTime: this.commonService.getFormattedDateTimeZone(
                data.lastModificationTime
              ),
            };
          });
          this.totalCount = this.wipTableData.length;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.wipTableData = [];
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.tableData$ = getWIPTableData;

  }
  //To Save the Inbound status
  // saveStatus() {
  //   this.issaving = true;
  //   this.isShowSpinner = true;
  //   if (this.mainStatusId !== defaultGuid) {
  //     this.inboundStatusDetails = {
  //       inboundStatus: (this.AppontmentForm.get('txtStatusName').value || '').toString().trim(),
  //       mainStatusId: this.mainStatusId,
  //       tenantId: this.tenantId,
  //       isActive: this.isActive
  //     };
  //     this.InboundDocumentService
  //       .updateStatus(this.mainStatusId, this.inboundStatusDetails)
  //       .subscribe(
  //         (response) => {
  //           this.reset();
  //           this.isFieldDisabled = false;
  //           this.isLoading = true;
  //           this.GetStatusFilterList();
  //           this.isShowSpinner = false;
  //           this.step = false;
  //           this.toastr.success('WIP Status Updated Successfully', 'Success');
  //           this.issaving = false;
  //         },
  //         (err) => {
  //           this.isLoading = false;
  //           this.isShowSpinner = false;
  //           this.GetStatusFilterList();
  //           this.step = false;
  //           this.wipTableData = [];
  //           const data: HttpErrorResponse = err;
  //           Swal.fire({
  //             icon: 'info',
  //             text: data?.error?.error?.message,
  //           });
  //           this.issaving = false;
  //         }
  //       );

  //   } else if (this.mainStatusId === defaultGuid) {
  //     // this.sortedId = this.AppontmentForm.get('txtSortedId').value
  //     this.inboundStatusDetails = {
  //       inboundStatus: (this.AppontmentForm.get('txtStatusName').value || '')
  //         .toString()
  //         .trim(),
  //       tenantId: this.tenantId,
  //       isActive: 1
  //     };

  //     this.InboundDocumentService.createStatus(this.inboundStatusDetails).subscribe(
  //       (response) => {
  //         this.reset();
  //         this.isFieldDisabled = false;
  //         this.isLoading = true;
  //         this.GetStatusFilterList();
  //         this.isShowSpinner = false;
  //         this.toastr.success('WIP Status Added Successfully', 'Success');
  //         this.issaving = false;
  //       },
  //       (err) => {
  //         this.saveButtonHide = false;
  //         this.isShowSpinner = false;

  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //         this.issaving = false;
  //       }
  //     );
  //   }
  // }

  // //View Status
  // viewStatus(id: string, isdefault: number) {
  //   this.isFieldDisabled = false;
  //   // this.AppontmentForm.controls['txtSortedId'].enable();
  //   if (isdefault == 1) {
  //     this.isFieldDisabled = true;
  //   }
  //   if (this.step == false) {
  //     this.step = true;
  //   }
  //   const colorCodes = this.InboundDocumentService.getById(id).subscribe(
  //     (response) => {
  //       this.AppontmentForm.patchValue({
  //         txtStatusName: response.inboundStatus,
  //         // txtSortedId: response.iSortOrder,
  //       });
  //       this.mainStatusId = response.id ?? '';
  //       this.isActive = response.isActive ?? '';

  //     },
  //     (err) => {
  //       this.saveButtonHide = false;
  //       this.isShowSpinner = false;
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.$subscription.push(colorCodes);
  // }

  //Delete functionality for Inbound status
  // deleteInboundStatus(id: string) {

  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: "You won't be able to retrieve this data!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!',
  //   }).then((result) => {
  //     if (result.value) {
  //       const statusDel = this.InboundDocumentService.deleteInboundStatus(id).subscribe(
  //         (response) => {
  //           this.toastr.success('WIP Status Deleted', 'Success');
  //           this.GetStatusList();
  //         },
  //         (err) => {
  //           this.saveButtonHide = false;
  //           this.isShowSpinner = false;
  //           const data: HttpErrorResponse = err;
  //           Swal.fire({
  //             icon: 'info',
  //             text: data?.error?.error?.message,
  //           });
  //         }
  //       );
  //     }
  //   });
  // }


  // statusChange(event: MatSlideToggleChange, data: any) {
  //   if (event.checked == true) {
  //     Swal.fire({
  //       title: 'Do you want to activate?',
  //       text: 'By Changing Status, this status will be activated',
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#34c38f',
  //       cancelButtonColor: '#f46a6a',
  //       confirmButtonText: 'Yes, Activate it!'
  //     }).then(result => {
  //       if (result.value) {
  //         if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
  //           this.InboundDocumentService.updateEnableDisable(data?.id, 1).subscribe(response => {
  //             this.toastr.success('Activated Successfully', 'Success')
  //             this.GetStatusFilterList();
  //           }, err => {
  //             this.toastr.success('Activated Successfully', 'Success');
  //             this.GetStatusFilterList();
  //           });
  //         }
  //       }
  //       else {
  //         this.GetStatusFilterList();
  //       }
  //     });
  //   }
  //   else
  //     if (event.checked == false) {
  //       Swal.fire({
  //         title: 'Do you want to deactivate?',
  //         text: 'By Changing Status, this status will be deactivated',
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: '#34c38f',
  //         cancelButtonColor: '#f46a6a',
  //         confirmButtonText: 'Yes, deactivate it!'
  //       }).then(result => {
  //         if (result.value) {
  //           if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
  //             this.InboundDocumentService.updateEnableDisable(data?.id, 0).subscribe(response => {
  //               this.toastr.success('Deactivated Successfully', 'Success')
  //               this.GetStatusFilterList();
  //             }, err => {
  //               this.toastr.success('Deactivated Successfully', 'Success');
  //               this.GetStatusFilterList();
  //             });
  //           }

  //         }
  //         else {
  //           this.GetStatusFilterList();
  //         }
  //       });
  //     }
  // }

  //Reset Inbound status
  // reset() {
  //   // this.txtStatusName = '';
  //   this.AppontmentForm.reset();
  //   // this.AppontmentForm.patchValue({
  //   //   txtStatusName: '',
  //   //   // txtSortedId: '',
  //   // });
  //   this.mainStatusId = defaultGuid;
  //   this.isFieldDisabled = false;
  // }

  //On Destroy Function
  ngOnDestroy(): void {
    this.$subscription?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

}
export interface CustomColorCodingDTO extends ColorCodingDTO {
  sNo: number;
}


export function noWhitespaceValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value && control.value.trim() === '') {
    return { 'whitespace': true };
  }
  return null;
}
