<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div
        class="d-flex align-items-center justify-content-between"
        style="margin-top: -20px"
      >
        <h3 class="mb-0 font-size-18">
          <b>Patient / Inbound</b>
        </h3>

        <br />
        <div class="page-title-box page-title-right pt-2 pb-0">
          <ol class="breadcrumb m-0" style="background-color: transparent">
            <div class="input-group m-2" id="daterange">
              <input
                type="text"
                class="form-control"
                placeholder="Username"
                [hidden]="dateRangStatus != true"
                aria-label="Username"
                aria-describedby="basic-addon1"
                name="daterange"
                ngxDaterangepickerMd
                [ranges]="ranges"
                [(ngModel)]="selectedRange"
                id="calendar-input"
                [showCustomRangeLabel]="true"
                [alwaysShowCalendars]="true"
                [linkedCalendars]="true"
                [showClearButton]="true"
                [locale]="calendarLocale"
                [placeholder]="calendarPlaceholder"
                [keepCalendarOpeningWithRange]="true"
                [minDate]="minDate"
                [maxDate]="maxDate"
                (datesUpdated)="datesUpdateds($event)"
                autocomplete="new-daterange"
              />
        
            </div>
            
           
            <li>
                <mat-form-field  class="pr-3">
                  <mat-label>Branch</mat-label>
                  <mat-select
                    #selectBranch
                    formControlName="drpBranch"
                    multiple
                    [(value)]="organizationUnitName"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtBranch"
                      ></ngx-mat-select-search>
                    </mat-option>

                    <mat-option
                      #selectAllBranch
                      (click)="allBranchSelect()"
                      value="0"
                      >All</mat-option
                    >

                    <mat-option
                      *ngFor="let branch of brnachList | async"
                      [value]="branch.id"
                    >
                      {{ branch.organizationUnitName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              <mat-radio-button class="All-radio-button m-2" id="disableRadio" [checked]="true" (click)="disableFilter()">
                All
            </mat-radio-button>
            <mat-radio-button class="Date-radio-button m-2" id="enableRadio" (click)="enableFilter()">
              Date
          </mat-radio-button>
            <li>
                <span class="input-group-text bg-color mr-3 mt-1 disabled" id="basic-addon1">
                    <label style="margin: auto" for="calendar-input">
                        <i class="fa fa-filter cursor" aria-hidden="true"></i>
                    </label>
                </span>
            </li>
            <li>
              <button
              mat-button
              class="buttonColor mr-2 font-size-14 pl-2"
              (click)="redirectToProcessedDocuments()"
            >
              <i class="mdi mdi-plus mr-1"></i>Processed Documents
            </button>
              <button
              class="mr-2 buttonColor"
              mat-button
              (click)="addInboundDocuments()"
            >
              Upload Documents
            </button>
            <button
            [disabled]="
              dataSource &&
              dataSource?.data &&
              dataSource?.data?.length === 0
            "
            mat-raised-button
            (click)="
              exporter.exportTable('xlsx', {
                fileName: 'Inbound List',
                sheet: 'InboundList',
                Props: { Author: 'QSecure' }
              })
            "
            class="buttonColor font-size-14 pl-2"
          >
            <i class="mdi mdi-plus mr-1"></i>Export
          </button>
      </li>
            <!-- <li class="ml-2 mt-2 breadcrumb-item">Patient</li>
            <li class="mt-2 breadcrumb-item muted">Inbound Documents</li> -->
          </ol>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-lg-1 ml-4">
      <input matInput />
      <mat-placeholder
        ><b>Total Files - </b
        ><b class="customThemeClass">15</b></mat-placeholder
      >
    </div>
    <div class="col-lg-1">
      <input matInput />
      <mat-placeholder
        ><b>Process Files - </b
        ><b class="customThemeClass">10</b></mat-placeholder
      >
    </div>
    <div class="col-lg-1">
      <input matInput />
      <mat-placeholder
        ><b>Pending Files - </b
        ><b class="customThemeClass">5</b></mat-placeholder
      >
    </div>
  </div>  -->

  <div class="row mt-2">
    <div class="col-lg-2">
      <mat-card class="overflow-hidden pr-0" style="height: 70vh">
        <mat-card-header>
          <div class="total-count-label">Locations</div>
        </mat-card-header>
        <mat-divider class="pt-1"></mat-divider>
        <mat-card-content>
          <mat-tree
            [dataSource]="treeDatasource"
            [treeControl]="treeControl"
            style="height: 65vh"
            class="overflow-auto"
          >
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
              <!-- use a disabled button to provide padding for tree leaf -->
              <button mat-icon-button disabled></button>
              {{ node.name }}
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-tree-node
              *matTreeNodeDef="let node; when: hasChild"
              matTreeNodePadding
            >
              <button
                mat-icon-button
                matTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.name"
              >
                <mat-icon class="mat-icon-rtl-mirror">
                  {{
                    treeControl.isExpanded(node)
                      ? "expand_more"
                      : "chevron_right"
                  }}
                </mat-icon>
              </button>
              {{ node.name }}
            </mat-tree-node>
          </mat-tree>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg-10">
      <div class="row float-right mr-1">
        <div class="mr-3"><b>Total Files - </b><b class="customThemeClass">10</b></div>
        <div class="mr-3"><b>Process Files - </b><b class="customThemeClass">8</b></div>
        <div class="mr-3"><b>Pending Files - </b><b class="customThemeClass">16</b></div>
      </div>
      <form [formGroup]="inboundSearchForm">
        <div class="table table-responsive example-container mat-elevation-z4">
          <table
            mat-table
            matSort
            class="pt-0 mt-0"
            [dataSource]="dataSource"
            matTableExporter
            #exporter="matTableExporter"
            [hiddenColumns]="[0]"
          >
            <!--Fax ID-->

            <ng-container matColumnDef="faxId">
              <th
                class="header"
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-faxId"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="faxId"
                  />
                  <mat-placeholder>Fax Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{
                    data.defaultFaxId == "" || data.defaultFaxId == null
                      ? "-"
                      : data.defaultFaxId
                  }}
                </ng-container>
              </td>
            </ng-container>

            <!--Document ID-->
            <ng-container matColumnDef="documentId">
              <th
                class="header"
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-documentId"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="documentId"
                  />
                  <mat-placeholder>Document Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  <a
                    matTooltip="Click to View Options"
                    class="eyeCursorclass"
                    (click)="
                      openPopup(
                        data.docId,
                        data.blobName,
                        data.defaultDocumentId
                      )
                    "
                  >
                    {{ data.defaultDocumentId }}
                  </a>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="docPageCount">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="header"
              >
                Page
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data.docPageCount }}
                </ng-container>
              </td>
            </ng-container>
            <!--Is Stat-->
            <ng-container matColumnDef="isStat">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>STAT</th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  <mat-checkbox
                    [checked]="element.isStat === 1"
                    (change)="onCheckboxChange(this.element)"
                  >
                  </mat-checkbox>
                </ng-container>
              </td>
            </ng-container>
            <!--Document Name-->
            <ng-container matColumnDef="documentName">
              <th
                class="header"
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-documentName"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="documentName"
                  />
                  <mat-placeholder>Document Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{ data.documentName }}
                  <!-- TEST Document.pdf -->
                </ng-container>
              </td>
            </ng-container>
            <!--Fax Number-->
            <ng-container matColumnDef="faxNumber">
              <th
                class="header"
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-faxNumber"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="faxNumber"
                  />
                  <mat-placeholder>Fax Number</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  <!-- {{ data.faxNumber }}  -->
                  {{
                    data.faxNumber == "" || data.faxNumber == null
                      ? "-"
                      : data.faxNumber
                  }}
                </ng-container>
              </td>
            </ng-container>
            <!-- Branch -->
            <ng-container matColumnDef="branch">
              <th
                class="header"
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-faxName"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="branch"
                  />
                  <mat-placeholder>Branch</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">-</td>
            </ng-container>
            <!-- Fax Name -->
            <ng-container matColumnDef="faxName">
              <th
                class="header"
                mat-sort-header
                mat-header-cell
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <input
                    autocomplete="new-faxName"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="faxName"
                  />
                  <mat-placeholder>Fax Name</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let data">
                <ng-container>
                  {{
                    data.faxName == "" || data.faxName == null
                      ? "-"
                      : data.faxName
                  }}
                </ng-container>
              </td>
            </ng-container>
            <!-- Patient Count -->
            <!-- <ng-container matColumnDef="patientCount">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <input
                  matInput
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                  formControlName="patientCount"
                />
                <mat-placeholder>Patient Count</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container *ngIf="editPatientCount != data.docId">
                {{ data.patientCount }} &nbsp;
                <a class="eyeCursorclass">
                  <i
                    (click)="fnEditPatientCount(data.docId)"
                    target="_blank"
                    class="fa fa-pencil mr-5 pencilColor"
                  ></i>
                </a>
              </ng-container>

              <ng-container *ngIf="editPatientCount == data.docId">
                <input
                  mat-input
                  class="w-25"
                  id="txtEdit{{ data.docId }}"
                  #inputElement
                  [value]="data.patientCount"
                />

                <button mat-mini-fab class="wip-status-color">
                  <mat-icon
                    matTooltip="Click To Update"
                    class="wip-danger-status-color"
                    (click)="fnUpdatePatientCount(data.docId)"
                    disabled
                    >check_circle_outline</mat-icon
                  >
                </button>
              </ng-container>
            </td>
          </ng-container> -->
            <!--Uploaded Type-->
            <!-- <ng-container matColumnDef="uploadedType">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <input
                  matInput
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                  formControlName="uploadedType"
                />
                <mat-placeholder> Uploaded Type</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.uploadedType }}
              </ng-container>
            </td>
          </ng-container> -->
            <!--Uploaded By-->
            <!-- <ng-container matColumnDef="uploadedBy">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <input
                  matInput
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                  formControlName="uploadedBy"
                />
                <mat-placeholder>Uploaded By</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.uploadedByName }}

              </ng-container>
            </td>
          </ng-container> -->
            <!--Uploaded Date-->
            <ng-container matColumnDef="uploadedDate">
              <th
                class="header"
                mat-sort-header
                class="w-125"
                mat-header-cell
                *matHeaderCellDef
              >
                <mat-form-field class="filter">
                  <mat-label> Uploaded Date </mat-label>
                  <input
                    autocomplete="new-uploadedDate"
                    matInput
                    (keydown.space)="$event.stopPropagation()"
                    (click)="$event.stopPropagation()"
                    formControlName="uploadedDate"
                    placeholder="(MM/DD/YYYY)"
                  />
                  <mat-error
                    *ngIf="
                      inboundSearchForm?.get('uploadedDate')?.touched &&
                      inboundSearchForm?.get('uploadedDate')?.errors
                        ?.dateVaidator
                    "
                  >
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                {{
                  element?.uploadedDate | date : "MM/dd/yyyy h:mm a" : "en_US"
                }}
              </td>
            </ng-container>
            <!--Options-->
            <!-- <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
            <td mat-cell *matCellDef="let data">
              <a class="editBtnColor mr-2" (click)="openPopup(data.docId)">
                <i
                  class="fa fa-eye pointer"
                  matTooltip="Click To Assign Document"
                ></i>
              </a>
              <button
                *ngIf="
                  data.processedCount == data.patientCount &&
                  data.patientCount != 0
                "
                mat-mini-fab
                class="wip-status-color"
              >
                <mat-icon
                  matTooltip="Click To Complete"
                  class="wip-danger-status-color"
                  (click)="approveDocument(data.docId)"
                  disabled
                  >check_circle_outline</mat-icon
                >
              </button>
            </td>
          </ng-container> -->

            <!--No Records-->
            <ng-container matColumnDef="noRecords">
              <td
                class="text-align-center"
                mat-footer-cell
                *matFooterCellDef
                colspan="6"
              >
                <div *ngIf="!isShowSpinner">
                  {{ "No Records Found" }}
                </div>
                <div [hidden]="!isShowSpinner">
                  <mat-spinner [diameter]="220"></mat-spinner>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
              mat-row
              [ngStyle]="{ 'background-color': row.colorCode }"
              *matRowDef="let row; columns: displayedColumns"
            ></tr>
          </table>
          <div
            *ngIf="
              !(
                dataSource &&
                dataSource?.data &&
                dataSource?.data?.length !== 0
              )
            "
            class="text-align-center"
          >
            <div class="message py-3" *ngIf="!isShowSpinner">
              {{ "No Records Found" }}
            </div>
            <div class="py-3" [hidden]="!isShowSpinner">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </div>
          <mat-paginator
            [pageSizeOptions]="[10, 20, 50, 100]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </form>
    </div>
  </div>
</div>
