<canvas #canvas
        (mousedown)="onMouseDown($event)"
        (mousemove)="onMouseMove($event)"
        (mouseup)="onMouseUp($event)"
        
        (contextmenu)="deleteText($event)"
        
        (click)="addText($event)"
        width="400"
        height="300">
</canvas>

<!-- (mouseleave)="onMouseLeave($event)" -->
