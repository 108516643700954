import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Observable, of, Subscription } from 'rxjs';
import { defaultGuid } from '../document-perviewer/document-perviewer.component';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
const defaultUrl = '../../../assets/images/user.png';
import { OrganizationUnitDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
// import { DatePipe } from '@angular/common';
import { MatRadioChange } from '@angular/material/radio';
import { UserResetPasswordComponent } from '../user-reset-password/user-reset-password.component';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MatDialog } from '@angular/material/dialog';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { RoleService, RoleService_RoleDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/role';
import { TenantsService } from '../admin-proxy/platform-app-management/rcm/platform-management/tenant';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { RegionCitiesDTO, RegionCountriesDTO, RegionStatesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatPaginator } from '@angular/material/paginator';
import { UsernameEmailValidationDto } from '../admin-proxy/platform-app-management/rcm/platform-management/users/dto';
import { catchError, debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { PasswordValidation } from '../confirmedvalidator';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { defaultCountry, LogoMaxandTypeLimits } from 'projects/shared/src/app/enums/allenums.enum';
import { IdentityUserUpdateDto } from '../admin-proxy/platform-app-management/identity';
import { IdentityUserCreateUsingTenantIdDto } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import emailMask from 'text-mask-addons/dist/emailMask';
@Component({
  selector: 'app-usertabcomponent',
  templateUrl: './usertabcomponent.component.html',
  styleUrls: ['./usertabcomponent.component.scss'],
})
export class UsertabcomponentComponent implements OnInit {
  @Input() clientId: string = '';
  userStatusId = 1;
  isLoading: boolean = true;
  tableData$: Subscription;
  tenantId: string;
  public strPageType: string = 'SettingUsersList';
  datatableElement: DataTableDirective;
  userTableDataList: any;
  isPanelOpenState: boolean = false;
  isImageValid: boolean = true;
  sltMainBranchNameValue = '';
  userId: string = '';
  roleForm: FormGroup;
  userFilterForm: FormGroup;
  isShownSaveButton: boolean = true;
  // isSaveDisable : false;
  isShowSpinner: boolean = false;
  isShownUsersTable: boolean = true;
  // userIdlist:
  showErrorForFileType: boolean = false;
  showErrorBasedOnSize: boolean = false;
  fileAs64Value: string = defaultUrl;
  role: string;
  drpRoles: RoleService_RoleDTO[] = [];
  drpRolesList: RoleService_RoleDTO[] = [];
  sltMainBranchNameFilter=new FormControl();
  sltRemoteBranchNameFilter= new FormControl();
  emptyguid: string = defaultGuid;
  emailMask: any;
  drpOrganization: OrganizationUnitDTO[] = [];
  drpOrganizationList: OrganizationUnitDTO[] = [];
  RemoteSelectAllOption = false;
  RoleSelectAllOption=false;
  hide = true;
  isMandatory = false;
  userIdlist: string[] = [];
  phoneCode: string = '';
  drpCountry: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  CountryName = '';
  stateName = '';
  Country: string = '';
  State: string = '';
  City: string = '';
  RemoteBranch = '';
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;
  public lat: number;
  public long: number;
  branch: string;
  roleDropdownValue: string;
  public latitude: number;
  public longitude: number;
  userTableData: any[] = [];
  subscription$: Subscription[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  IdentityUserService: any;
  txtRoleFilter=new FormControl();
  // step: boolean = false;
  // panelOpenState = false;
  addWorkingId: string;
  concurrencyStamp: string = '';
  isUpdateMode: boolean = false;
  // dtOptions: DataTables.Settings = {};
  dtOptions: any;
  toggle() {
    this.isPanelOpenState = true;
  }
  toggleV2() {
    this.isPanelOpenState = false;
  }
  constructor(
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private userService: UserService,
    private branchService: OrganizationUnitService,
    private regionDropdownService: RegionDropdownService,
    private officialService: TenantsService,
    private toastr: ToastrService,
    // private datepipe: DatePipe,
    private dateValidator: DateValidator,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,

  )  {
    this.communicationService.functionLoadUserList$.subscribe((selectedStatus) => {
      this.getTableData(selectedStatus);
      this.userStatusId=selectedStatus;
    });
  }
  ngOnInit() {
    this.emailMask = emailMask;
    this.tenantId = localStorage.getItem("tenantId") ?? "";
   this.getTableData(this.userStatusId);
   this.roleForm = this.formBuilder.group(
     {
       txtRole: new FormControl('', Validators.required),
       txtUserName: new FormControl(
         '',
         Validators.required,
         this.checkUserUniqness(),

       ),
       txtEmail: new FormControl(
         '',
         [Validators.required, Validators.email],
         [this.checkEmailUniqness()]
       ),
       sltMainBranchName: new FormControl('', Validators.required),
       sltRemoteBranchName: new FormControl(''),
       txtPassword: new FormControl(
         '',
         this.isUpdateMode ? null : Validators.required
       ),
       txtConfirmPassword: new FormControl(
         '',
         this.isUpdateMode ? null : Validators.required
       ),
       txtPhnNo: new FormControl(''),
       txtAddress1: new FormControl(''),
       txtAddress2: new FormControl(''),
       drpCountry: new FormControl(''),
       drpState: new FormControl(''),
       drpStateFilter: new FormControl(''),
       drpCity: new FormControl(''),
       drpCityFilter: new FormControl(''),
       txtPostalCode: new FormControl(''),
     },
     {
       validator: PasswordValidation.MatchPassword,
     }
   );
   this.filterFormInitiate();
   this.loadRoleDropdown();
   this.countryDropdown();
   this.loadOrganizationDropdown();
   // this.getTableData(this.userStatusId);

   this.txtRoleFilter.valueChanges.subscribe((dxTypeFilterText) => {
     dxTypeFilterText = dxTypeFilterText.toLowerCase();
     this.drpRoles = this.drpRolesList.filter((dx) =>
       dx.roleDisplayName.toLowerCase().includes(dxTypeFilterText)
     );
   });
   this.sltRemoteBranchNameFilter.valueChanges.subscribe((dxTypeFilterText) => {
     dxTypeFilterText = dxTypeFilterText.toLowerCase();
     this.drpOrganization = this.drpOrganizationList.filter((dx) =>
       dx.organizationUnitName.toLowerCase().includes(dxTypeFilterText)
     );
   });
  }
  fnDisableRemoteBranches() {
    this.sltMainBranchNameValue = this.roleForm.value.sltMainBranchName;
    this.clearRemoteBranch()
  }
  fnDisableRemoteBranchesV1() {
    this.sltMainBranchNameValue = this.roleForm.value.sltMainBranchName;
  }

  onFileSelected(value: File) {
    if (value.size >= LogoMaxandTypeLimits.documentFileSizeLimit) {
      this.showErrorForFileType = false;
      this.showErrorBasedOnSize = true;
      this.isImageValid = false;
      return;
    }
    if ('image/jpeg' !== value.type) {
      this.showErrorForFileType = true;
      this.showErrorBasedOnSize = false;
      this.isImageValid = false;
      return;
    }
    this.showErrorForFileType = false;
    this.showErrorBasedOnSize = false;
    this.isImageValid = true;
    let reader = new FileReader();
    this.fileAs64Value = '';
    reader.readAsDataURL(value);
    reader.onload = () => {
      this.fileAs64Value = reader?.result?.toString() ?? '';
      this.showErrorBasedOnSize = false;
      this.showErrorForFileType = false;
    };
  }

  //// get table data
  getTableData(userStatusId: number) {
    // this.userTableData = [];
    // let sltRemoteBranchName = this.userFilterForm?.value.sltRemoteBranchNameFilter;
    // let joinedValues = '';
    // this.isLoading=true;
    // if (Array.isArray(sltRemoteBranchName) && sltRemoteBranchName.length > 0) {
    //   joinedValues = sltRemoteBranchName.join(',');
    // }
    // let roleName = this.userFilterForm?.get('txtRoleFilter').value;
    // let joinedValuesV1 = '';
    // this.isLoading=true;
    // if (Array.isArray(roleName) && roleName.length > 0) {
    //   joinedValuesV1 = roleName.join(',');
    // }
    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const getInboundTableData =
      // this.userService.getUsersByStatusByUserStatusAndGTenant(userStatusId, this.tenantId).subscribe(
        this.userService.getUsersByStatusByUserStatusAndGTenantV1(
          userStatusId, this.tenantId,this.userFilterForm?.value.txtRoleFilter,
          this.userFilterForm?.value.sltMainBranchNameFilter,
          this.userFilterForm?.value.txtEmailFilter.toLowerCase(),
          this.userFilterForm?.value.txtUserNameFilter?.trim(),
        ).subscribe(

         (response) => {
          this.userTableData=response;
          const arrTableData = this.userTableData;
          this.isLoading = false;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          const arrTableData = [];
          this.isLoading = false;
        }
      );
    this.tableData$ = getInboundTableData;
  }
  resetFilterForm() {
    const resetfields ={
      txtEmailFilter :''
    }
    this.userFilterForm.reset(resetfields);
    this.getTableData(this.userStatusId); // userStatus
  }
  multiUserResetPassword() {
    this.dialog.open(UserResetPasswordComponent, {
      minHeight: '20vh',
      minWidth: '50vw',
      disableClose: true,
      data: {
        userId: defaultGuid,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => { });
  }
  onRadioChange(event: MatRadioChange): void {
    this.isLoading=true;
    this.userStatusId = event.value;
    // this.communicationService.triggerLoadUSerList(this.userStatusId);
    this.isLoading=false;
   this.getTableData(this.userStatusId); // userStatus
  }
  filterFormInitiate(){
    this.userFilterForm = this.formBuilder.group({
      txtRoleFilter:new FormControl(''),
      txtUserNameFilter:new FormControl(''),
      sltMainBranchNameFilter:new FormControl(''),
      txtEmailFilter:new FormControl(''),
    });
  }
  updatePasswordValidators() {
    this.roleForm.get('txtPassword').setValidators([Validators.required]);
    this.roleForm.get('txtPassword').updateValueAndValidity();
    this.roleForm
      .get('txtConfirmPassword')
      .setValidators([Validators.required]);
    this.roleForm.get('txtConfirmPassword').updateValueAndValidity();
  }
  get otherSearchValidation(): boolean {
    const formValue = this.userFilterForm.getRawValue();
    if (!formValue) {
      return false;
    }
    return (
      (formValue.txtRoleFilter?.length === 0 ||
       formValue.txtRoleFilter === null ||
       formValue.txtRoleFilter === undefined) &&
      (formValue.txtUserNameFilter?.trim() === '' ||
       formValue.txtUserNameFilter === null ||
       formValue.txtUserNameFilter === undefined) &&
      (formValue.sltMainBranchNameFilter?.length === 0 ||
       formValue.sltMainBranchNameFilter === null ||
       formValue.sltMainBranchNameFilter === undefined) &&
      (formValue.txtEmailFilter?.trim() === '' ||
       formValue.txtEmailFilter === null ||
       formValue.txtEmailFilter === undefined)
    );
}
  clearPasswordValidators() {
    this.roleForm.controls['txtPassword'].clearValidators();
    this.roleForm.controls['txtPassword'].updateValueAndValidity();
    this.roleForm.controls['txtConfirmPassword'].clearValidators();
    this.roleForm.controls['txtConfirmPassword'].updateValueAndValidity();
  }
   //! load role dropdown
   loadRoleDropdown() {
    this.roleService.getActiveRolesByGTenantId(this.clientId).subscribe(
      (response) => {
        this.drpRoles = [];
        this.drpRolesList = response;
        this.drpRoles=this.drpRolesList;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  // Get Country Dropdown
  countryDropdown() {
    this.roleForm.patchValue({ drpCountry: defaultCountry.US });
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;
    this.drpCountry = [];
    this.regionDropdownService
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpCountry =
            response?.items.filter((x) => x.countryShortName == 'US') ?? [];
          this.filteredCountries = this.roleForm
            ?.get('txtCountryFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
 // On Change State Dropdown
 onChangeState(value: string | null | undefined): boolean {
  this.roleForm.patchValue({
    drpState: null,
    drpCity: null,
  });
  this.drpStates = [];
  this.drpCities = [];
  value !== null &&
    value !== undefined &&
    this.regionDropdownService
      .getAllStatesByCountryNameByCountryShortName(value)
      .subscribe(
        (response) => {
          this.drpStates = response?.items ?? [];
          if (response?.items?.length === 0) {
            this.phoneCode = '';
            this.roleForm.controls['txtPhnNo'].disable();
          } else {
            this.roleForm.controls['txtPhnNo'].enable();
            this.phoneCode = this.drpStates[0]?.countryPhoneCode ?? '';
          }
          this.filteredStates = this.roleForm
            ?.get('drpStateFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpStates.filter((option) =>
                  option?.stateName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  return this.drpStates.length !== 0;
}
// On Change City Dropdown
onChangeCity(state: string | null | undefined, country: string): boolean {
  this.City = '';
  this.roleForm.patchValue({
    drpCity: null,
  });
  country =
    country == ''
      ? this.roleForm.value.drpCountry == (null || undefined)
        ? ''
        : this.roleForm.value.drpCountry
      : country;
  this.drpCities = [];
  state !== null &&
    state !== undefined &&
    this.regionDropdownService
      .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
        country,
        state
      )
      .subscribe(
        (response) => {
          this.drpCities = response?.items ?? [];
          this.City = response?.totalCount == 0 ? '' : 'City';
          this.filteredCities = this.roleForm
            ?.get('drpCityFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCities.filter((option) =>
                  option?.cityName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  return this.drpCities.length !== 0;
}
onRoleChange(role) {
  this.roleDropdownValue = role.value;
  this.isMandatory = false;
}
 // clear dropdown from ontime
 clearCountry() {
  // this.userForm2.controls['txtPhnNo'].disable();
  this.Country = '';
  this.roleForm.patchValue({ drpCountry: '' });
}

clearState() {
  this.State = '';
  this.roleForm.patchValue({ drpState: '' });
}

clearCity() {
  this.City = '';
  this.roleForm.patchValue({ drpCity: '' });
}

clearRole() {
  this.role = '';
  this.roleForm.patchValue({ txtRole: '' });
  // this.userForm.patchValue({ txtRole: '' });
}

clearBranch() {
  this.branch = '';
  this.roleForm.patchValue({ txtBranchName: '' });
}
clearConfirmPassword() {
  this.roleForm.patchValue({ txtConfirmPassword: '' });
}

clearRemoteBranch() {
  this.RemoteSelectAllOption = false;
  this.RemoteBranch = '';
  this.userFilterForm.patchValue({ sltRemoteBranchNameFilter: '' });
}
  /// save user functionality
  saveOnTimeUser() {
    let sltRemoteBranchName = this.roleForm.value.sltRemoteBranchName;
    let joinedValues = '';
    this.isLoading=true;
    // Check if sltRemoteBranchName is an array and not null
    if (Array.isArray(sltRemoteBranchName) && sltRemoteBranchName.length > 0) {
      // Use join(',') only if sltRemoteBranchName is an array with at least one element
      joinedValues = sltRemoteBranchName.join(',');
    }
    this.isShowSpinner = true;
    this.isShownSaveButton = false;
    if (this.userId == null || this.userId == '' || this.userId == undefined) {
      let user: IdentityUserCreateUsingTenantIdDto = {
        tenantId: this.clientId,
        userName: this.roleForm.value.txtUserName.replace(/ /g, ''),
        name: this.roleForm.value.txtUserName,
        surname: '',
        email: this.roleForm.value.txtEmail,
        phoneNumber: this.roleForm.value.txtPhnNo,
        lockoutEnabled: true,
        roleNames: [this.roleForm.value.txtRole],
        password: this.roleForm.value.txtPassword,
        extraProperties: {
          organizationUnitId: this.roleForm.value.sltMainBranchName,
          profileImageAsBase64String:
            this.fileAs64Value === defaultUrl
              ? ''
              : this.fileAs64Value?.slice(23)?.toString() ?? '',
          status: 'Active',
          RemoteLocationsId: joinedValues.toString(),
          address1: this.roleForm.value.txtAddress1,
          address2: this.roleForm.value.txtAddress2,
          country: this.roleForm.value.drpCountry,
          state: this.roleForm.value.drpState,
          city: this.roleForm.value.drpCity,
          zipCode: this.roleForm.value.txtPostalCode,
          userWorkingHoursId: this.emptyguid,
          latitude: this.latitude,
          longitude: this.longitude,
        },
      };
      this.userService.createUser(user).subscribe(
        (response) => {

          this.isShownSaveButton = true;
          this.isShowSpinner = false;
          this.reset();
          // this.communicationService.triggerLoadUSerList(this.userStatusId);
          if(this.userTableDataList.length===0){
            this.communicationService.triggerLoadBranchList(this.userStatusId);
           } else{
             if(this.userStatusId !== 3){
          //  this.userTableDataList.push(response);
          this.userTableDataList.unshift(response);
             }
           }
           this.toastr.success('Saved Successfully', 'Success');
           this.isLoading=false;
          // this.getTableData(this.userStatusId);
        },
        (err) => {
          this.isShowSpinner = false;
          this.isShownSaveButton = true;
          this.isPanelOpenState = true;
          this.isLoading=false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      let user: IdentityUserUpdateDto = {
        userName: this.roleForm.value.txtUserName.replace(/ /g, ''),
        name: this.roleForm.value.txtUserName,
        surname: '',
        email: this.roleForm.value.txtEmail,
        phoneNumber: this.roleForm.value.txtPhnNo,
        lockoutEnabled: true,
        roleNames: [this.roleForm.value.txtRole],
        extraProperties: {
          organizationUnitId: this.roleForm.value.sltMainBranchName,
          profileImageAsBase64String:
            this.fileAs64Value === defaultUrl
              ? ''
              : this.fileAs64Value?.slice(23)?.toString() ?? '',
          status: 'Active',
          RemoteLocationsId: joinedValues.toString(),
          address1: this.roleForm.value.txtAddress1,
          address2: this.roleForm.value.txtAddress2,
          country: this.roleForm.value.drpCountry,
          state: this.roleForm.value.drpState,
          city: this.roleForm.value.drpCity,
          zipCode: this.roleForm.value.txtPostalCode,
          userWorkingHoursId: this.emptyguid,
          latitude: this.latitude,
          longitude: this.longitude,
        },
      };

      this.userService.updateUser(this.clientId, this.userId, user).subscribe(
        (response) => {
          this.isShownSaveButton = true;
          this.isShowSpinner = false;
          this.reset();
          // this.communicationService.triggerLoadUSerList(this.userStatusId);
          // let index = this.userTableDataList.findIndex(d => d.id === response.id);
          // this.userTableDataList.splice(index,1);
          // this.userTableDataList.push(response);
          const index = this.userTableDataList.findIndex(
            (obj) => obj.id === response?.id
          );
          if (index !== -1) {
            this.userTableDataList[index] = response; //Replace the Object
            const objectToMove = this.userTableDataList.splice(index, 1)[0]; // Remove and get the object
            this.userTableDataList.unshift(objectToMove);
          }
          this.isLoading = false;
          // this.getTableData(this.userStatusId);
          this.isPanelOpenState = false;
          this.toastr.success('Updated Successfully', 'Success');
          this.isLoading=false;
        },
        (err) => {
          this.isShowSpinner = false;
          this.isShownSaveButton = true;
          this.isLoading=false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
    this.updatePasswordValidators();
  }
  reset() {
    this.roleForm.reset();
    this.userId = '';
    this.isUpdateMode = false;
    this.fileAs64Value = '../../../assets/images/user.png';
    this.showErrorForFileType = false;
    this.showErrorBasedOnSize = false;
    this.updatePasswordValidators();
  }

  checkEmailUniqness(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      const gUser = this.userId == '' ? this.emptyguid : this.userId;
      // const userId = await getUserIdAsync();
      const input: UsernameEmailValidationDto = {
        username: '',
        emailAddress:
          String(control?.value ?? '')
            ?.toLowerCase()
            ?.trim() ?? '',
        isUserNameExists: false,
        isEmailAddressExists: false,
        // userId:"4472e4c3-968b-b08b-c11a-3a0d4e4e48f2",
        userId: gUser,
      };
      return this.userService.checkEmailIfExistsByInput(input).pipe(
        tap((x) => control?.setErrors({ isEmailAddressExists: true })),
        debounceTime(300),
        map((response) =>
          response?.isEmailAddressExists === true
            ? { isEmailAddressExists: true }
            : control?.setErrors(null) == null
              ? null
              : null
        )
      );
    };
  }
  checkUserUniqness(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      // const gUser = userId === '' ? this.emptyguid : userId;
      const gUser = this.userId == '' ? this.emptyguid : this.userId;

      const input: UsernameEmailValidationDto = {
        username: String(control?.value ?? '')?.toLowerCase()?.trim() ?? '',
        emailAddress: '',
        isUserNameExists: false,
        isEmailAddressExists: false,
        userId: gUser,
      };

      return this.userService.checkEmailIfExistsByInput(input).pipe(
        debounceTime(300),
        switchMap((response) => {

          if (response?.isUserNameExists === true) {
            return of({ isUserNameExists: true });
          } else {
            control?.setErrors(null);
            return of(null);
          }
        }),
        catchError((error) => {
          console.error('Error in asynchronous validator:', error);
          // Show an alert or any other error handling logic here
          alert('An error occurred while checking user uniqueness. Please try again.');
          return of(null); // Return null to avoid breaking the observable chain
        })
      );
    };
  }
  loadOrganizationDropdown() {
    this.branchService
      .getOrganizationList(this.clientId)
      .subscribe((response) => {
        this.drpOrganization = [];
        response &&
          response?.forEach((element) => {
            if (element?.status == 'Active') {
              this.drpOrganizationList.push(element);

              //this.drpOrganization = response ?? [];
            }
          });
          this.drpOrganization= this.drpOrganizationList;
      });
  }
  onSelectionChange(event: any) {
    const selectedOptions = this.userFilterForm?.get('sltMainBranchNameFilter').value;
    if (selectedOptions.includes('selectAll')) {
      this.RemoteSelectAllOption = !this.RemoteSelectAllOption;

      if (this.RemoteSelectAllOption) {
        // If "Select All" is selected, set all other options
        this.userFilterForm?.get('sltMainBranchNameFilter')
          .patchValue([...this.drpOrganization.map((org) => org.id).filter((option) => option !== this.sltMainBranchNameValue)]);
      } else {
        // If "Select All" is deselected, clear all other options
        this.userFilterForm?.get('sltMainBranchNameFilter').patchValue([]);
      }
    } else {
      // If an individual option is selected, check if all options are selected and update "Select All"
      this.RemoteSelectAllOption =
        selectedOptions.length === this.drpOrganization.length;

      // If an individual option is deselected, deselect "Select All"
      if (
        selectedOptions.length < this.drpOrganization.length &&
        this.RemoteSelectAllOption
      ) {
        this.RemoteSelectAllOption = false;
        this.userFilterForm?.get('sltMainBranchNameFilter')
          .patchValue(
            selectedOptions.filter((option) => option !== 'selectAll')
          );
      }
    }
    this.RemoteBranch = 'RemoteBranch'
  }
  onSelectionChangeRole(event: any) {
    const selectedOptions = this.userFilterForm?.get('txtRoleFilter').value;
    if (selectedOptions.includes('selectAll')) {
      this.RoleSelectAllOption = !this.RoleSelectAllOption;

      if (this.RoleSelectAllOption) {
        // If "Select All" is selected, set all other options
        this.userFilterForm?.get('txtRoleFilter')
          .patchValue([...this.drpRoles.map((org) => org.id)]);
      } else {
        // If "Select All" is deselected, clear all other options
        this.userFilterForm?.get('txtRoleFilter').patchValue([]);
      }
    } else {
      // If an individual option is selected, check if all options are selected and update "Select All"
      this.RoleSelectAllOption =
        selectedOptions.length === this.drpRoles.length;

      // If an individual option is deselected, deselect "Select All"
      if (
        selectedOptions.length < this.drpRoles.length &&
        this.RoleSelectAllOption
      ) {
        this.RoleSelectAllOption = false;
        this.userFilterForm?.get('txtRoleFilter')
          .patchValue(
            selectedOptions.filter((option) => option !== 'selectAll')
          );
      }
    }
    this.RemoteBranch = 'RemoteBranch'
  }

}
