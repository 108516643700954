<div class="container-fluid main-content">
    <div class="row bg">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between ">
          <h3 class="mb-0 font-size-18"><b>Patient / Archive Documents</b></h3>
          <div class="page-title-box page-title-right  pt-2 pb-0">
            <ol class="breadcrumb m-0">
              <li>
               <button mat-button (click)="backToUploadDoc()"
                  class="buttonColor font-size-14 pl-2 mr-2">
                  <i class="mdi mdi-plus mr-1"></i>Back To Upload Documents
                </button>
              </li>
              <!-- <li class="ml-2 mt-2 breadcrumb-item">Patient</li>
              <li class="mt-2 breadcrumb-item muted">Archive Documents</li> -->
            </ol>
          </div>
        </div>
      </div>
      <div class="col-12">
        <mat-card-content>
          <form [formGroup]="batchUploadBinForm">
            <div class="table table-responsive">
              <table class="mat-elevation-z8" mat-table class="table" [dataSource]="dataSource"
                matSort>
                <ng-container matColumnDef="defaultBatchId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-form-field class="filter">
                      <input autocomplete="new-defaultBatchId" matInput formControlName="defaultBatchId" type="text" />
                      <mat-placeholder class="center">Batch ID</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="alert alert-success p-2" *ngIf="element?.percentageOfCompletion===100">
                      {{ element?.defaultBatchId }}
                    </span>

                    <span class="alert alert-danger p-2" *ngIf="element?.percentageOfCompletion!==100">
                      {{ element?.defaultBatchId }}
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="uploadedDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-form-field class="filter">
                      <mat-label> Uploaded Date </mat-label>
                      <input autocomplete="new-uploadedDate" matInput formControlName="uploadedDate" type="text" placeholder="(MM/DD/YYYY)" />
                      <mat-error
                        *ngIf="batchUploadBinForm?.get('uploadedDate')?.touched && batchUploadBinForm?.get('uploadedDate')?.errors?.dateVaidator">
                        Enter Valid date
                      </mat-error>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container>
                      {{ element?.uploadedOn | date: 'MM/dd/yyyy h:mm a':'en_US' }}
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="uploadedBy">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-form-field class="filter">
                      <input autocomplete="new-uploadedBy" matInput formControlName="uploadedBy" type="text" />
                      <mat-placeholder class="center"> Uploaded By</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container>
                      {{ element?.uploadedBy }}
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="assignedByUserName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-form-field class="filter">
                      <input autocomplete="new-assignedByUserName" matInput formControlName="assignedByUserName" type="text" />
                      <mat-placeholder class="center"> Assigned By</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container>
                      {{ element?.assignedByUserName }}
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="fileName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-form-field class="filter">
                      <input autocomplete="new-fileName" matInput formControlName="fileName" type="text" />
                      <mat-placeholder class="center"> File Name</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container>
                      {{ element?.fileName }}
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="fileSize">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-form-field class="filter">
                      <input autocomplete="new-fileSize" matInput formControlName="fileSize" type="text" />
                      <mat-placeholder class="center">File Size</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container>
                      {{ element?.fileSize }}
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="numberOfPages">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-form-field class="filter">
                      <input autocomplete="new-numberOfPages" matInput formControlName="numberOfPages" type="text" />
                      <mat-placeholder class="center">No Of Pages</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container>
                      {{ element?.numberOfPages }}
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="isStat">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Is Stat
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container>
                      <ng-container *ngIf="element?.isStat === 1">
                        <mat-checkbox [disabled]="true" [checked]="true"> </mat-checkbox>
                      </ng-container>
                      <ng-container *ngIf="element?.isStat === 0">
                        <mat-checkbox [disabled]="true" [checked]="false"> </mat-checkbox>
                      </ng-container>
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="%completed">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Rules % Completed
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container>
                      {{ element?.percentageOfCompletion + " %" }}
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="fileStatus">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-form-field class="filter">
                      <input autocomplete="new-fileStatus" matInput formControlName="fileStatus" type="text" />
                      <mat-placeholder class="center">File Status</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container>
                      <span [ngClass]="{'make-red': element?.overallStatus === 'Processing',
                      'make-blue': element?.overallStatus === 'ApprovalPending',
                      'make-green': element?.overallStatus === 'Approved'}">
                        {{ element?.overallStatus}}
                      </span>
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="assignedToUserName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-form-field class="filter">
                      <input autocomplete="new-assignedToUserName" matInput formControlName="assignedToUserName" type="text" />
                      <mat-placeholder class="center"> Assigned To</mat-placeholder>
                    </mat-form-field>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container>
                      {{ element?.assignedToUserName }}
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Options">
                  <th class="verticalAlignColumn  text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                    Options
                  </th>
                  <td class="verticalAlignColumn text-center" mat-cell *matCellDef="let element">

                    <a *ngIf="element?.blobName" (click)="unarchiveFileById(element?.id)"
                    matTooltip="Click To Unarchive Document">
                    <i class="fa fa-archive pointer editBtnColor pl-3" ></i>
                  </a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="noRecords">
                  <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="12">
                    <div *ngIf="!isLoading">
                      {{"No Records Found"}}
                    </div>
                    <div [hidden]="!isLoading">
                      <mat-spinner [diameter]="220"></mat-spinner>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="paginator">
                  <td mat-footer-cell *matFooterCellDef colspan="12">
                    <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                      [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                      [pageSizeOptions]="[5, 10, 25, 100]">
                    </mat-paginator>
                  </td>
                </ng-container>

                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                  [ngClass]="{ 'active': dataSource && dataSource?.data && dataSource?.data?.length!==0}"></tr>
                <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
                <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
                <!-- <tr mat-row *matRowDef="let element; columns: ['expandedErrorDetail']"
                  [class.example-expanded-row]="expandedElement === element"></tr> -->
              </table>
            </div>
          </form>
        </mat-card-content>
      </div>
    </div>
  </div>
