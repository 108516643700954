import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PickUpExchangeService } from '../order-proxy/order-management/order-optimization/pick-up-exchange.service';
import { PickupSaleOrderItemDTO } from '../order-proxy/order-management/order-optimization/dto';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-pickup-exchange-tab',
  templateUrl: './pickup-exchange-tab.component.html',
  styleUrls: ['./pickup-exchange-tab.component.scss'],
})
export class PickupExchangeTabComponent implements OnInit {
  tblItemReponse: PickupSaleOrderItemDTO[] = [];
  isLoading: boolean = false;
  @Input() orderId: string = defaultGuid;
  @Input() patientId: string = defaultGuid;
  emptyId: string = defaultGuid;
  subscription$: Subscription[] = [];
  constructor(
    private router: Router,
    private pickupExchangeService: PickUpExchangeService,
    public title: Title,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Pickup/Exchange");
    this.getPickupExchangeList();
  }
  redirect() {
    this.router.navigate([
      '/orders/add-exchange-return-item/',
      this.patientId,
      this.orderId,
    ]);
  }
  getPickupExchangeList() {
    this.isLoading = true;
    this.tblItemReponse = [];
    const saleOrderGet = this.pickupExchangeService
      .getPickUpExchangeListByOrderId(this.orderId)
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.tblItemReponse = response;
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(saleOrderGet);
  }

  deletePickupExchangeItem(id: string = defaultGuid) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const saleOrderGet = this.pickupExchangeService
          .DeletePickupItem(id)
          .subscribe(
            (response) => {
              Swal.fire({
                icon: 'success',
                text: 'Deleted Successfully!',
              });
              this.getPickupExchangeList();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
      }
    });

    //DeletePickupItem
  }
}
