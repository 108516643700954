import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { DocumentSortingComponent } from 'projects/admin/src/app/document-sorting/document-sorting.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import Swal from 'sweetalert2';
import { AddNoteTabComponent } from '../add-note-tab/add-note-tab.component';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {
  [x: string]: any;
  displayedColumns: string[] =
   ['ChartNo',
   'Name',
   'state',
   'Insurance',
   'EnteredBy',
   'Location',
   'LockedBy',
   'LockedAt',
   'Days',
   'EnteredOn',
   'Status',
  ];
  patientDetails: any[] = [];
  isShowSpinner: boolean;
  dataSource: MatTableDataSource<any>;
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.isShowSpinner = false;
    this.verificationList=[
      {ChartNo:"CBER01",Name:"vetter",state:"NJ",Insurance:"UHC",EnteredBy:"Kunal patal",Location:"Houseton",Days:123,EnteredOn:"1.12.2023",Status:3},
      {ChartNo:"AYBD02",Name:"day",state:"NY",Insurance:"Atena",EnteredBy:"Kunal patal",Location:"Houseton",Days:123,EnteredOn:"2.25.2023",Status:3},
      {ChartNo:"YRRTE2",Name:"Ng",state:"TX",Insurance:"Medicare",EnteredBy:"Kunal patal",Location:"Houseton",Days:123,EnteredOn:"1.1.2023",Status:3},
      {ChartNo:"SFET23",Name:"Murphy",state:"NJ",Insurance:"BC",EnteredBy:"Kunal patal",Location:"Houseton",Days:123,EnteredOn:"6.08.2023",Status:3},
      {ChartNo:"BFEW78",Name:"Lewis",state:"Y",Insurance:"Web TPA",EnteredBy:"Kunal patal",Location:"Houseton",Days:123,EnteredOn:"11.11.2023",Status:3}

    ];
    document.addEventListener('click', this.onDocumentClick.bind(this));
  }
  ngOnDestroy(): void {
    document.removeEventListener('click', this.onDocumentClick.bind(this));
  }
  onDocumentClick(event: Event): void {
      this.showContextMenu = false;
  }
  onRowContextMenu(event: MouseEvent): void {
    event.preventDefault();
    this.contextMenuPosition = { top: event.clientY, left: event.clientX };
    this.showContextMenu = true;
  }
  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        isCallNote:false,
        noteType:"Patient Note",
        notesId:"00000000-0000-0000-0000-000000000000",
        patientId:"ee77ec4d-1f43-d505-e56c-3a0ec7458292"
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.$subscription.push(closeDialog);
  }
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: defaultGuid,
        pageType: this.pageType, //Notes Table Popup Page
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  viewDocument(
    patientId: string,
    documentId: string,
    defaultDocumentId: string,
    isSorted: boolean
  ) {

    const dialogRef = this.dialog.open(DocumentSortingComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId
        patientId: patientId,
        documentId: documentId,
        defaultDocumentId: defaultDocumentId,
        isSorted: isSorted,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle the dialog close event here if needed

    });
  }
  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }
  CancelVerification(){
    Swal.fire({
      title: 'Are you sure you want to Cancel?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
    }).then(result => {
      if (result.value) {

      }
    });
  }

  goToMIVerification() {
    // Navigate to the 'order/pickupExchangeList' path
    this.router.navigate(['order', 'pickupExchangeList']);
  }
}
