<div class="container-fluid main-content">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between ">
        <h3 class="mb-0 font-size-18"><b>Patient / Upload Documents</b></h3>
        <div class="page-title-box page-title-right  pt-2 pb-0">
          <ol class="breadcrumb m-0">
            <li>
              <button mat-button *ngIf="isShownCreateButton" (click)="openAddFaxBinModal()"
                class="buttonColor font-size-14 pl-2 mr-2">
                <i class="mdi mdi-plus mr-1"></i>Upload Documents
              </button>
              <button mat-button *ngIf="isShownCreateButton" (click)="openArchivedDoc()"
              class="buttonColor font-size-14 pl-2 ">
              <i class="mdi mdi-plus mr-1"></i>Archived Documents
            </button>
            </li>
            <!-- <li class="ml-2 mt-2 breadcrumb-item">Patient</li>
            <li class="mt-2 breadcrumb-item muted">Upload Documents</li> -->
          </ol>
        </div>
      </div>
    </div>
    <div class="col-12 ">
      <mat-card-content>
        <!-- <div [hidden]="!isLoading"
          style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
          <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
          </mat-progress-bar>
        </div> -->
        <form [formGroup]="batchUploadBinForm">
          <div class="table table-responsive">
            <table class="mat-elevation-z8" multiTemplateDataRows mat-table class="table"style="border: 1px solid #cdc7c7;" [dataSource]="dataSource"
              matSort>
              <ng-container matColumnDef="defaultBatchId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <mat-form-field class="filter">
                    <input autocomplete="new-defaultBatchId" matInput formControlName="defaultBatchId" type="text" />
                    <mat-placeholder class="center">Batch ID</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <span class="alert alert-success p-2" *ngIf="element?.percentageOfCompletion===100">
                    {{ element?.defaultBatchId }}
                  </span>

                  <span class="alert alert-danger p-2" *ngIf="element?.percentageOfCompletion!==100">
                    {{ element?.defaultBatchId }}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="uploadedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <mat-form-field class="filter">
                    <mat-label> Uploaded Date </mat-label>
                    <input autocomplete="new-uploadedDate" matInput formControlName="uploadedDate" type="text" placeholder="(MM/DD/YYYY)" />
                    <mat-error
                      *ngIf="batchUploadBinForm?.get('uploadedDate')?.touched && batchUploadBinForm?.get('uploadedDate')?.errors?.dateVaidator">
                      Enter Valid date
                    </mat-error>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    {{ element?.uploadedOn | date: 'MM/dd/yyyy h:mm a':'en_US' }}
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="uploadedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <mat-form-field class="filter">
                    <input autocomplete="new-uploadedBy" matInput formControlName="uploadedBy" type="text" />
                    <mat-placeholder class="center"> Uploaded By</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    {{ element?.uploadedBy }}
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="assignedByUserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <mat-form-field class="filter">
                    <input autocomplete="new-assignedByUserName" matInput formControlName="assignedByUserName" type="text" />
                    <mat-placeholder class="center"> Assigned By</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    {{ element?.assignedByUserName }}
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="fileName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <mat-form-field class="filter">
                    <input autocomplete="new-fileName" matInput formControlName="fileName" type="text" />
                    <mat-placeholder class="center"> File Name</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    {{ element?.fileName }}
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="fileSize">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <mat-form-field class="filter">
                    <input autocomplete="new-fileSize" matInput formControlName="fileSize" type="text" />
                    <mat-placeholder class="center">File Size</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    {{ element?.fileSize }}
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="numberOfPages">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <mat-form-field class="filter">
                    <input autocomplete="new-numberOfPages" matInput formControlName="numberOfPages" type="text" />
                    <mat-placeholder class="center">No Of Pages</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    {{ element?.numberOfPages }}
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="isStat">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Is Stat
                </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    <ng-container *ngIf="element?.isStat === 1">
                      <mat-checkbox [disabled]="true" [checked]="true"> </mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="element?.isStat === 0">
                      <mat-checkbox [disabled]="true" [checked]="false"> </mat-checkbox>
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="%completed">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Rules %
                </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    {{ element?.percentageOfCompletion + " %" }}
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="fileStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <mat-form-field class="filter">
                    <input autocomplete="new-fileStatus" matInput formControlName="fileStatus" type="text" />
                    <mat-placeholder class="center">File Status</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    <span [ngClass]="{'make-red': element?.overallStatus === 'Processing',
                    'make-blue': element?.overallStatus === 'ApprovalPending',
                    'make-green': element?.overallStatus === 'Approved'}">
                      {{ element?.overallStatus}}
                    </span>
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="assignedToUserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <mat-form-field class="filter">
                    <input autocomplete="new-assignedToUserName" matInput formControlName="assignedToUserName" type="text" />
                    <mat-placeholder class="center"> Assigned To</mat-placeholder>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container>
                    {{ element?.assignedToUserName }}
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="Options">
                <th class="verticalAlignColumn  text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                  Options
                </th>
                <td class="verticalAlignColumn text-center" mat-cell *matCellDef="let element">
                  <a *ngIf="element?.blobName" (click)="documnetViewerSideBySide(element?.id)">
                    <i class="fa fa-pencil-square-o editBtnColor mr-3" matTooltip="Click To Update Details"></i> </a>

                  <!-- <a *ngIf="element?.percentageOfCompletion === 100 && element?.overallStatus!=='Approved'"
                    (click)="approvePtsToPt(element?.id,element?.patientsId)">
                    <i class="fa fa-check pointer editBtnColor mr-3" matTooltip="Click To Approve"></i> </a> -->
                  <a *ngIf="isShownViewButton" (click)="editById(element?.id)">

                    <i class="fa fa-pencil pointer editBtnColor mr-3" matTooltip="Click To Edit"></i> </a>
                  <a *ngIf="element?.blobName" (click)="previewDocumentById(element?.blobName)">
                    <i class="fa fa-eye pointer editBtnColor mr-3" matTooltip="Click To View Document"></i> </a>

                  <button *ngIf="element?.blobName" mat-icon-button class="editBtnColor m-0 p-0">
                    <mat-icon matTooltip="Click To Download Document"
                      (click)="downloadAutoUploadLastDocument(element?.blobName) ">
                      cloud_download</mat-icon>
                  </button>

                  <a *ngIf="element?.blobName" (click)="deleteFileById(element?.id)"
                  matTooltip="Click To Delete Document">
                  <i class="fa fa-trash pointer deleteBtnColor pl-3" ></i>
                </a>
                <a *ngIf="element?.blobName" (click)="archiveFileById(element?.id)"
                matTooltip="Click To Archive Document">
                <i class="fa fa-archive pointer editBtnColor pl-3" ></i>
              </a>
                  <button *ngIf="(!!element?.patientsId )&& element?.overallStatus!=='Approved'"
                    (click)="expandedElement = expandedElement === element ? null : element" mat-icon-button
                    class="editBtnColor m-0 p-0">
                    <mat-icon class="pointer"> {{ expandedElement === element ? 'expand_less' : 'expand_more' }}
                    </mat-icon>
                  </button>
                </td>
              </ng-container>
              <ng-container matColumnDef="noRecords">
                <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="12">
                  <div *ngIf="!isLoading">
                    {{"No Records Found"}}
                  </div>
                  <div [hidden]="!isLoading">
                    <mat-spinner [diameter]="220"></mat-spinner>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="paginator">
                <td mat-footer-cell *matFooterCellDef colspan="12">
                  <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                    [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                    [pageSizeOptions]="[5, 10, 25, 100]">
                  </mat-paginator>
                </td>
              </ng-container>
              <ng-container matColumnDef="expandedErrorDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="arrDisplayedColumns?.length">
                  <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="example-element-description">
                      <ng-container *ngIf="element?.etrPtDetails">
                        <p>Extracted Patient</p>
                        <table class="table table-bordered table-sm">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">DOB</th>
                              <th scope="col">Options</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr [ngClass]="{patient_highlight:patientId ===''}">
                              <td>{{ element?.etrPtDetails?.patientName }}</td>
                              <td>{{ element?.etrPtDetails?.dateOfBirth | date: 'MM/dd/yyyy':'en_US'}}</td>
                              <td>
                                <a (click)="patientId=''" *ngIf="element?.overallStatus!=='Approved'">
                                  <i class="fa fa-check pointer editBtnColor mr-3" matTooltip="Click To Select"></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container
                        *ngIf="(element?.identifiedPatientList |async) !== [] &&(element?.identifiedPatientList |async)['length'] !== 0">
                        <p> Identical Patient </p>
                        <table class="table table-bordered table-sm">
                          <thead>
                            <tr>
                              <th scope="col">Patient Id</th>
                              <th scope="col">Name</th>
                              <th scope="col">DOB</th>
                              <th scope="col">Mail Id</th>
                              <th scope="col">Status</th>
                              <th scope="col">Options</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let ipatient of element?.identifiedPatientList |async ">
                              <tr [ngClass]="{patient_highlight:patientId ===ipatient?.identifiedPatientId}">
                                <th scope="row">{{ ipatient?.defaultPatientId }}</th>
                                <td>{{ ipatient?.patientName }}</td>

                                <td>{{ ipatient?.dateOfBirth| date :'MM/dd/yyyy' :'en_US'}}
                                <td>{{ ipatient?.mailId }}</td>
                                <td>{{ ipatient?.patientStatus }}</td>
                                <td>
                                  <a (click)="patientId=ipatient?.identifiedPatientId"
                                    *ngIf="element?.overallStatus!=='Approved'">
                                    <i class="fa fa-check pointer editBtnColor mr-3" matTooltip="Click To Select"></i>
                                  </a>
                                </td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </ng-container>
                      <button mat-button *ngIf="element?.overallStatus!=='Approved' " matTooltip="Click To Dispprove"
                        class="float-right resetclr  mb-2"
                        (click)="approvePtsToPt(element?.etrPtDetails,element?.id,element?.patientsId,element?.reason4AppOrRej ,fileApprovalStatus?.Rejected, patientId)">
                        Disapprove </button>
                      <button mat-button *ngIf="element?.overallStatus!=='Approved'" matTooltip="Click To Approve"
                        class="float-right buttonColor mr-4 mb-2"
                        (click)="approvePtsToPt(element?.etrPtDetails,element?.id,element?.patientsId,element?.reason4AppOrRej,fileApprovalStatus?.Approved,patientId)">
                        Approve </button>

                    </div>
                  </div>
                </td>
              </ng-container>
              <tr mat-footer-row *matFooterRowDef="['noRecords']"
                [ngClass]="{ 'active': dataSource && dataSource?.data && dataSource?.data?.length!==0}"></tr>
              <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
              <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
              <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let element; columns: ['expandedErrorDetail']"   [class.example-expanded-row]="expandedElement === element"></tr>
            </table>
          </div>
        </form>
      </mat-card-content>
    </div>
  </div>
  <app-patient-search [patientId]='patientId' (selPatientId)="getPatientIdOnselect($event)"></app-patient-search>
</div>
