
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-lg-12">
            <h2>Product Shipping Label</h2>
        </div>
        <div class="col-12">
          <div class="container-fluid mt-1">
            <ngx-extended-pdf-viewer  [textLayer]="true" [showHandToolButton]="true"
              [src]="strSelectedPdfPath" [height]="'70vh'" [useBrowserLocale]="false"  [delayFirstView]='1000'>
            </ngx-extended-pdf-viewer>
          </div>
        </div>
    
    </div>



<!-- <mat-dialog-content class="mat-typography"> -->
    
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>