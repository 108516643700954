<div class="row">
  <div class="col-lg-6">
    <h2>Insurance Eligibility</h2>
  </div>
  <div class="col-lg-6" style="text-align:end !important">
    <img class="img-responsive" src="assets/Dashboard New/Real-Transparent-pVerify-logo.png"  height="40"/>
  </div>
</div>

<form [formGroup]="eligibilityMedicareForm">
<mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col-lg-12">
        <mat-card-content >
          <div class="row">
            <mat-radio-group class="rg pt-3 ml-3" formControlName="rbtnPolicyType">
              <!-- <mat-radio-button value="PVerify">PVerify</mat-radio-button>&nbsp;&nbsp;&nbsp;
              <mat-radio-button value="Zirmed">Zirmed Verify</mat-radio-button>&nbsp;&nbsp;&nbsp; -->
            </mat-radio-group>
            <mat-accordion class="example-headers-align" multi>
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title class="customThemeClass font-size-18">
                    <b> Requests Criteria</b>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- <hr class="hr-divider"> -->
                <div class="row" >

                <mat-divider class="col-12"></mat-divider>
                  <div class="col-lg-12 pt-1" style="padding-left:0px !important">
                    <div class="col-4">
                      <h4 class="customThemeClass"><b>Provider</b></h4>
                    </div>
                    <mat-form-field class="col-3">
                      <mat-label>Plan <span class="asterisk">*</span></mat-label>
                      <!-- <mat-select maxlength="40" formControlName="drpPayor"
                        (valueChange)="valueChangeLoadDropdown($event)">
                        <mat-option *ngFor="let payor of drpPolicies" [value]="payor.id">
                          {{
                          payor.payorId  + "/" + payor.payorName
                          }}
                        </mat-option>
                      </mat-select> -->
                      <input autocomplete="new-drpPayor" maxlength="100" formControlName="drpPayor"  matInput
                      type="text" readonly/>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('drpPayor')?.touched &&
                          eligibilityMedicareForm?.get('drpPayor')?.errors
                            ?.required
                        ">
                        Payor Level is a required field!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>NPI <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtNpi" maxlength="100" formControlName="txtNpi" maxlength="10" matInput
                        type="text" readonly/>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtNpi')?.touched &&
                          eligibilityMedicareForm?.get('txtNpi')?.errors
                            ?.required
                        ">
                        NPI is a required field!
                      </mat-error>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtNpi')?.touched &&
                          eligibilityMedicareForm?.get('txtNpi')?.errors
                            ?.minlength
                        ">
                        Enter Minimum 10 Digits!
                      </mat-error>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtNpi')?.touched &&
                          eligibilityMedicareForm?.get('txtNpi')?.errors?.number
                        ">
                        Enter only Numbers!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>Provider Name
                        <span class="asterisk">*</span>
                      </mat-label>
                      <input autocomplete="new-txtProviderName" maxlength="100" formControlName="txtProviderName" matInput type="text" readonly/>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtProviderName')
                            ?.touched &&
                          eligibilityMedicareForm?.get('txtProviderName')
                            ?.errors?.required
                        ">
                        Provider Name is a required field!
                      </mat-error>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtProviderName')
                            ?.touched &&
                          eligibilityMedicareForm?.get('txtProviderName')
                            ?.errors?.sAlphabets
                        ">
                        Enter only Alphabets!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label>DOS <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtEligibilityDate" formControlName="txtEligibilityDate" matInput maxlength="40" placeholder="MM/DD/YYYY"
                        [matDatepicker]="txtEligibilityDate" readonly/>
                      <mat-datepicker-toggle matSuffix [for]="txtEligibilityDate" disabled>
                      </mat-datepicker-toggle>
                      <mat-datepicker #txtEligibilityDate> </mat-datepicker>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtEligibilityDate')
                            ?.touched &&
                          eligibilityMedicareForm?.get('txtEligibilityDate')
                            ?.errors
                        ">
                        Please select Valid Date!
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-12" style="padding-left:0px !important">
                    <div class="col-8">
                      <h4 class="customThemeClass">
                        <b>Subscriber Information</b>
                      </h4>
                    </div>
                    <mat-form-field class="col-2">
                      <mat-label>First Name <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtFirstName" maxlength="100" formControlName="txtFirstName" matInput type="text" readonly/>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtFirstName')
                            ?.touched &&
                          eligibilityMedicareForm?.get('txtFirstName')?.errors
                            ?.required
                        ">
                        First Name is a required field!
                      </mat-error>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtFirstName')
                            ?.touched &&
                          eligibilityMedicareForm?.get('txtFirstName')?.errors
                            ?.sAlphabets
                        ">
                        Enter only Alphabets!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                      <mat-label>Last Name <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtLastName" maxlength="100" formControlName="txtLastName" matInput type="text" readonly/>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtLastName')
                            ?.touched &&
                          eligibilityMedicareForm?.get('txtLastName')?.errors
                            ?.required
                        ">
                        Last Name is a required field!
                      </mat-error>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtLastName')
                            ?.touched &&
                          eligibilityMedicareForm?.get('txtLastName')?.errors
                            ?.sAlphabets
                        ">
                        Enter only Alphabets!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2">
                      <mat-label>DOB <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtDob" formControlName="txtDob" matInput maxlength="100" placeholder="MM/DD/YYYY"
                        [matDatepicker]="txtDob" readonly/>
                      <mat-datepicker-toggle matSuffix [for]="txtDob" disabled>
                      </mat-datepicker-toggle>
                      <mat-datepicker #txtDob> </mat-datepicker>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtDob')?.touched &&
                          eligibilityMedicareForm?.get('txtDob')?.errors
                        ">
                        Please select Valid Date!
                      </mat-error>
                    </mat-form-field>
                    <!-- <mat-form-field class="col-4">
                          <mat-label>Suffix</mat-label>
                          <input maxlength="40" formControlName="txtSuffix" matInput type="text" />
                        </mat-form-field> -->
                    <mat-form-field class="col-2">
                      <mat-label>Member ID <span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtMemeberId" maxlength="100" formControlName="txtMemeberId" matInput type="text"
                        pattern="[a-zA-Z0-9]*" readonly/>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtMemeberId')
                            ?.touched &&
                          eligibilityMedicareForm?.get('txtMemeberId')?.errors
                            ?.required
                        ">
                        Last Name is a required field!
                      </mat-error>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtMemeberId')
                            ?.touched &&
                          eligibilityMedicareForm?.get('txtMemeberId')?.errors
                            ?.pattern
                        ">
                        Special Characters not allowed!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="
                        eligibilityMedicareForm?.value?.rbtnPolicyType ===
                        'PVerify'
                      " class="col-2">
                      <mat-label>Payer Id<span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtPayerId" maxlength="100" formControlName="txtPayerId" matInput type="text" readonly/>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtPayerId')?.touched &&
                          eligibilityMedicareForm?.get('txtPayerId')?.errors
                            ?.required
                        ">
                        Payer Id is a required field!
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-2" *ngIf="
                        eligibilityMedicareForm?.value?.rbtnPolicyType ===
                        'Zirmed'
                      ">
                      <mat-label>Payer Id<span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtZirmedPayerId" formControlName="txtZirmedPayerId" matInput type="text" readonly/>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtZirmedPayerId')
                            ?.touched &&
                          eligibilityMedicareForm?.get('txtZirmedPayerId')
                            ?.errors?.required
                        ">
                        Payer Name is a required field!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-2" *ngIf="
                        eligibilityMedicareForm?.value?.rbtnPolicyType ===
                        'PVerify'
                      ">
                      <mat-label>Payer Name<span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtPayerName" formControlName="txtPayerName" matInput type="text" readonly/>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtPayerName')
                            ?.touched &&
                          eligibilityMedicareForm?.get('txtPayerName')?.errors
                            ?.required
                        ">
                        Payer Name is a required field!
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-6" *ngIf="
                        eligibilityMedicareForm?.value?.rbtnPolicyType ===
                        'Zirmed'
                      ">
                      <mat-label>Payer Name<span class="asterisk">*</span></mat-label>
                      <input autocomplete="new-txtZirmedPayerName" formControlName="txtZirmedPayerName" matInput type="text" readonly/>
                      <mat-error *ngIf="
                          eligibilityMedicareForm?.get('txtZirmedPayerName')
                            ?.touched &&
                          eligibilityMedicareForm?.get('txtZirmedPayerName')
                            ?.errors?.required
                        ">
                        Payer Name is a required field!
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [hidden]="isLoading" *ngIf="isShown" [expanded]="true" class="pt-2">
                <mat-expansion-panel-header>
                  <mat-panel-title class="customThemeClass font-size-18">
                    <b>Results</b>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-divider class="col-12"></mat-divider>
                <div class="row pt-2">
                  <div class="col-lg-3">
                    <h4 class="customThemeClass"><b>Eligibility Report - </b></h4>
                  </div>
                  <!-- <br> -->
                  <div class="col-lg-3">
                    <h4 class="mb-0">
                      <b>Eligibility Begin Date</b> : {{ lblEligibilityDate }}
                    </h4>
                  </div>
                  <div class="col-lg-3 ">
                    <h4 class="mb-0">
                      <b> Plan Begin Date</b> : {{ lblPlanBeginDate }}
                    </h4>
                  </div>
                  <div class="col-lg-3 ">
                    <h4 class="mb-0">
                      <b>Plan End Date</b> : {{ lblPlanEndDate }}
                    </h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 ">
                    <h4 class="customThemeClass">
                      <b>Plan Coverage Summary</b>
                    </h4>
                    <!-- <app-table [strPageType]="strPageType"> </app-table> -->
                    <div class="col-12  mt-3" style="padding-left: 2px;">
                      <div *ngIf="!isSummaryLoading" class="table table-responsive">
                          <table
                            id="tblpatientList"
                            datatable [dtOptions]="dtSubscriberInfo"
                            class="tbl hover row-border hover w-100 display">
                            <thead>
                              <tr>
                                <th>Plan</th>
                                <th>Status</th>
                                <th>Effective Date</th>
                                <th>Expiry Date</th>
                                <th>Group Name</th>
                                <th>Group No</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let datas of coverageSummaryTableData">
                                <td [matTooltip]="datas.insurance"> {{ datas.insurance }}</td>
                                <td [matTooltip]="datas.coverage">{{ datas.coverage }}</td>
                                <td [matTooltip]="datas.date">  {{ datas.date }}</td>
                                <td [matTooltip]="datas.expDate"> {{ datas.expDate }}</td>
                                <td [matTooltip]="datas.groupName"> {{ datas.groupName }}</td>
                                <td [matTooltip]="datas.groupNo">  {{ datas.groupNo }}</td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <mat-divider class="col-12"></mat-divider>
                    <h4 class="customThemeClass pt-1"><b>Subscriber Info</b></h4>
                  </div>
                  <!-- <table style="margin-left: 13px!important;">
                    <tr>
                      <td class="tdAlignment"><b>Patient Name</b></td>
                      <td class="tdAlignment">: {{ lblSubscriberName }}</td>
                      <td class="tdAlignment"><b>Address</b></td>
                      <td class="tdAlignment">: {{ lblPatientAddress }}</td>
                    </tr>

                    <tr>
                      <td class="tdAlignment"><b>Gender</b></td>
                      <td class="tdAlignment">: {{ lblPatientGender }}</td>
                      <td class="tdAlignment"><b>State</b></td>
                      <td class="tdAlignment">: {{ lblState }}</td>
                    </tr>
                    <tr>
                      <td class="tdAlignment"><b>Member ID</b></td>
                      <td class="tdAlignment">: {{ lblMemberId }}</td>
                      <td class="tdAlignment"><b>City</b></td>
                      <td class="tdAlignment">: {{ lblCity }}</td>
                    </tr>

                    <tr>
                      <td class="tdAlignment"><b>Date Of Birth</b></td>
                      <td class="tdAlignment">: {{ lblPatientDob }}</td>

                      <td class="tdAlignment"><b>Zip Code</b></td>
                      <td class="tdAlignment">: {{ lblZip }}</td>
                    </tr>
                  </table> -->
                  <div class="col-2 ">
                    <b>Patient Name</b>
                    <h1>{{lblSubscriberName}}</h1>
                  </div>
                  <div class="col-1 vl">
                    <b>Gender</b>
                    <h1>{{lblPatientGender}}</h1>
                  </div>
                  <div class="col-1 vl">
                    <b>Member ID</b>
                    <h1>{{lblMemberId}}</h1>
                  </div>
                  <div class="col-2 vl">
                    <b>Date Of Birth</b>
                    <h1>{{lblPatientDob}}</h1>
                  </div>
                  <div class="col-2 vl">
                    <b>Address</b>
                    <h1>{{lblSubscriberName}}</h1>
                  </div>
                  <div class="col-1 vl">
                    <b>State</b>
                    <h1>{{lblState}}</h1>
                  </div>
                  <div class="col-1 vl">
                    <b>City</b>
                    <h1>{{lblCity}}</h1>
                  </div>
                  <div class="col-2 vl">
                    <b>Zip Code</b>
                    <h1>{{lblZip}}</h1>
                  </div>
                  <mat-divider class="col-12"></mat-divider>
                </div>


                <h4 class="mb-0 customThemeClass pt-1"><b>Deductible Summary</b></h4>
                <br />
                <div class="row">
                   <div class="col-6" >
                    <table *ngIf="changeHealthCareData">

                    <tr>
                      <td><b>Individual Deductible InNet</b></td>
                      <!-- <td>:</td> -->
                      <td>
                        <b> ${{ lblIDInNet ? lblIDInNet : 0}}</b>
                      </td>
                    </tr>
                    <tr>
                      <td class="mt-2"><b>Individual Deductible OutNet</b></td>
                      <td>
                        <b> ${{ lblIDOutNet ? lblIDOutNet : 0 }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Individual OOP InNet</b></td>
                      <td>
                        <b> ${{ lblIDOOPInNet ? lblIDOOPInNet : 0 }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Individual OOP OutNet</b></td>
                      <td>
                        <b> ${{ lblIDOOPOutNet ? lblIDOOPOutNet : 0}}</b>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Individual Deductible Remaining InNet</b></td>
                      <td>
                        <b> ${{ lblIRemainingInNet ? lblIRemainingInNet :0}}</b>
                      </td>
                    </tr>

                    <tr>
                      <td><b>Individual Deductible Remaining OutNet</b></td>
                      <td>
                        <b> ${{ lblIRemainingOutNet ? lblIRemainingOutNet : 0}}</b>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Individual OOP Remaining InNet</b></td>
                      <td>
                        <b> ${{ lblIOOPRemainingInNet ? lblIOOPRemainingInNet : 0 }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Individual OOP Remaining OutNet</b></td>
                      <td>
                        <b> ${{ lblIOOPRemainingOutNet ? lblIOOPRemainingOutNet : 0}}</b>
                      </td>
                    </tr>
                    <tr>
                      <td><b>CoIns Net</b></td>
                      <td>
                        <b> ${{ lblCPInNet ? lblCPInNet : 0}}</b>
                      </td>
                    </tr>
                  </table>
                  </div>

                   <div class="col-6">
                    <table *ngIf="changeHealthCareData">
                      <tr>
                        <td><b>Family Deductible InNet</b></td>
                        <!--<td><b>:</b></td> -->
                        <td>
                          <b> ${{ lblFDInNet ? lblFDInNet : 0 }}</b>
                        </td>
                      </tr>
                      <tr>

                        <td><b>Family Deductible OutNet</b></td>
                        <td>
                          <b> ${{ lblFDOutNet ? lblFDOutNet : 0 }}</b>
                        </td>
                      </tr>
                      <tr>
                       <td><b>Family OOP InNet</b></td>
                        <td>
                          <b> ${{ lblFOOPInNet ? lblFOOPInNet : 0 }}</b>
                        </td>
                      </tr>
                      <tr>
                       <td><b>Family OOP OutNet</b></td>
                        <td>
                          <b> ${{ lblFOOPOutNet ? lblFOOPOutNet : 0}}</b>
                        </td>
                      </tr>
                      <tr>
                        <td><b> Family Deductible Remaining InNet</b></td>
                        <td>
                          <b> ${{ lblFRemainingInNet ? lblFRemainingInNet :0}}</b>
                        </td>
                      </tr>

                      <tr>
                        <td><b>Family Deductible Remaining OutNet</b></td>
                        <td>
                          <b> ${{ lblFRemainingOutNet ? lblFRemainingOutNet : 0}}</b>
                        </td>
                      </tr>
                      <tr>
                       <td><b>Family OOP Remaining InNet</b></td>
                        <td>
                          <b> ${{ lblFOOPRemainingInNet ? lblFOOPRemainingInNet : 0}}</b>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Family OOP Remaining OutNet</b></td>
                        <td>
                          <b> ${{ lblFOOPRemainingOutNet ? lblFOOPRemainingOutNet : 0}}</b>
                        </td>
                      </tr>
                      <tr>
                        <td><b>CoPay Net</b></td>
                        <td>
                          <b> ${{ lblCPOutNet ? lblCPOutNet : 0}}</b>
                        </td>
                      </tr>
                    </table >
                   </div>

                   <div class="col-6">
                    <table *ngIf="pVerifyData">
                      <tr>
                        <td><b>Individual Deductible InNet</b></td>
                        <!-- <td>:</td> -->
                        <td>
                          <b> {{ lblIDInNet ? lblIDInNet : 0}}</b>
                        </td>
                      </tr>
                      <tr>
                        <td class="mt-2"><b>Individual Deductible OutNet</b></td>
                        <td>
                          <b> {{ lblIDOutNet ? lblIDOutNet : 0 }}</b>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Individual OOP InNet</b></td>
                        <td>
                          <b> {{ lblIDOOPInNet ? lblIDOOPInNet : 0 }}</b>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Individual OOP OutNet</b></td>
                        <td>
                          <b> {{ lblIDOOPOutNet ? lblIDOOPOutNet : 0}}</b>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Individual Deductible Remaining InNet</b></td>
                        <td>
                          <b> {{ lblIRemainingInNet ? lblIRemainingInNet :0}}</b>
                        </td>
                      </tr>

                      <tr>
                        <td><b>Individual Deductible Remaining OutNet</b></td>
                        <td>
                          <b> {{ lblIRemainingOutNet ? lblIRemainingOutNet : 0}}</b>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Individual OOP Remaining InNet</b></td>
                        <td>
                          <b> {{ lblIOOPRemainingInNet ? lblIOOPRemainingInNet : 0 }}</b>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Individual OOP Remaining OutNet</b></td>
                        <td>
                          <b> {{ lblIOOPRemainingOutNet ? lblIOOPRemainingOutNet : 0}}</b>
                        </td>
                      </tr>
                      <tr>
                        <td><b>CoIns Net</b></td>
                        <td>
                          <b> {{ lblCPInNet ? lblCPInNet : 0}}</b>
                        </td>
                      </tr>
                    </table>
                   </div>

                   <div class="col-6">
                    <table *ngIf="pVerifyData">
                    <tbody>
                      <tr>
                        <td><b>Family Deductible InNet</b></td>
                        <!--<td><b>:</b></td> -->
                        <td>
                          <b> {{ lblFDInNet ? lblFDInNet : 0 }}</b>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Family Deductible OutNet</b></td>
                        <td>
                          <b> {{ lblFDOutNet ? lblFDOutNet : 0 }}</b>
                        </td>
                      </tr>
                      <tr>
                       <td><b>Family OOP InNet</b></td>
                        <td>
                          <b> {{ lblFOOPInNet ? lblFOOPInNet : 0 }}</b>
                        </td>
                      </tr>
                      <tr>
                       <td><b>Family OOP OutNet</b></td>
                        <td>
                          <b> {{ lblFOOPOutNet ? lblFOOPOutNet : 0}}</b>
                        </td>
                      </tr>
                      <tr>
                        <td><b> Family Deductible Remaining InNet</b></td>
                        <td>
                          <b> {{ lblFRemainingInNet ? lblFRemainingInNet :0}}</b>
                        </td>
                      </tr>

                      <tr>
                       <td><b>Family Deductible Remaining OutNet</b></td>
                        <td>
                          <b> {{ lblFRemainingOutNet ? lblFRemainingOutNet : 0}}</b>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Family OOP Remaining InNet</b></td>
                        <td>
                          <b> {{ lblFOOPRemainingInNet ? lblFOOPRemainingInNet : 0}}</b>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Family OOP Remaining OutNet</b></td>
                        <td>
                          <b> {{ lblFOOPRemainingOutNet ? lblFOOPRemainingOutNet : 0}}</b>
                        </td>
                      </tr>
                      <tr>
                       <td><b>CoPay Net</b></td>
                        <td>
                          <b> {{ lblCPOutNet ? lblCPOutNet : 0}}</b>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                   </div>

                 </div>
                 <br />
                 <mat-expansion-panel [hidden]="isLoading" *ngIf="isShown" [expanded]="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="customThemeClass font-size-18">
                      <b>Other Covered Services</b>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-lg-12">
                    <!-- <h4 class="mb-1 customThemeClass">
                      <b>Other Covered Services</b>
                    </h4> -->
                    <div class="table-responsive table-bordered">
                      <table *ngIf="pVerifyData" class="table mb-0">
                        <tbody>
                          <tr *ngFor="let table of otherServiceTableData">
                            <td>
                              {{ table.serviceName }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table *ngIf="changeHealthCareData" class="table mb-0">
                        <tbody>
                          <tr *ngFor="let table of otherServiceTableData">
                            <td>
                              {{ table.description }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>

              <br />
                <mat-expansion-panel [hidden]="isLoading" *ngIf="isShown" [expanded]="false">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="customThemeClass font-size-18">
                      <b>Free Services</b>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-lg-12">
                   <!-- <h4 class="mb-1"><b>Free Services</b></h4> -->
                    <div class="table-responsive table-bordered">
                      <table class="table mb-0">
                        <tbody>
                          <tr>
                            <td>Alcoholism</td>
                          </tr>

                          <tr>
                            <td>Home Health</td>
                          </tr>
                          <tr>
                            <td>Smoking Cessation</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                  </div>
                </div>
              </mat-expansion-panel>

                <br />
              </mat-expansion-panel>
            </mat-accordion>
            <!-- </div> -->
          </div>
        </mat-card-content>
      </div>
    </div>
  <!-- </div> -->
</mat-dialog-content>
</form>



<mat-dialog-actions align="end">
  <button *ngIf="eligibilityMedicareForm?.value?.rbtnPolicyType === 'PVerify'" mat-button class="buttonColor"
  [disabled]="isLoading || !(!eligibilityMedicareForm?.invalid)" (click)="verify()">
    Verify
    <mat-icon *ngIf="isLoading">
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>

  <button *ngIf="eligibilityMedicareForm?.value?.rbtnPolicyType === 'Zirmed'" mat-button class="buttonColor"
  [disabled]="isLoading || !(!eligibilityMedicareForm?.invalid)" (click)="zirmedVerify()">
    Verify
    <mat-icon *ngIf="isLoading">
      <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
      </mat-spinner>
    </mat-icon>
  </button>
  <button class="buttonCancelColor" mat-button mat-dialog-close (click)="close()">Close</button>
</mat-dialog-actions>
