import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { Subscription, SubscribableOrPromise, Observable } from 'rxjs';
import {
  ProductCategoryDetailsService,
  ProductDetailsService,
  ProductStockDetailsService,
  VendorDetailsService,
} from '../item-proxy/item-management/optimization';
import {
  ProductStockDetailsByBranchDTO,
  SearchProductStockDetailsDTO,
  VendorDetailsDTO,
} from '../item-proxy/item-management/optimization/dto';
import moment from 'moment';
import { InventoryTransferComponent } from '../inventory-transfer/inventory-transfer.component';
import { MatDialog } from '@angular/material/dialog';
import { AdjustmentComponent } from '../adjustment/adjustment.component';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { OrganizationUnitDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.scss'],
})
export class StockListComponent implements OnInit {
  dtBranchWiseOptions: any = {
    dom: 'Bfrtip',
    responsive: true,
    paging: false,
    scrollX: true,
    scrollCollapse: true,
    scrollY: '400px',
    fixedHeader: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    columnDefs: [
      // { targets: [0, 1], orderable: false },
      // { targets: [11, 12, 13], visible: false },
      { targets: [0, 1, -1], className: 'dt-fixed-column' },
    ],
    buttons: [
      {
        extend: 'excel',
        filename: 'Branch Wise Stock List',
        text: 'Export', // Change the text to 'Export'
        exportOptions: {
          columns: ':visible:not(:nth-child(1))',
        },
      },
      // {
      //   extend: 'colvis',
      //   columns: ':not(:eq(0), :eq(1), :eq(2) )',
      // },
    ],
  };

  dtTotalStockOptions: any = {
    dom: 'Bfrtip',
    responsive: true,
    paging: false,
    scrollX: true,
    scrollCollapse: true,
    scrollY: '400px',
    fixedHeader: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    columnDefs: [
      { targets: [15], visible: false }, //,16,17,18
      { targets: 0, orderable: false }, // Targets the first column (index 0)
      {
        targets: [4, 8, 9, 16, 18],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
      { targets: [0, 1, 2, 3, 7, -1], className: 'dt-fixed-column' }, // Ad
      // Add other columnDefs as needed
    ],
    buttons: [
      {
        extend: 'excel',
        filename: 'Total Stock List',
        text: 'Export',
      },
      // {
      //   extend: 'colvis',
      //   columns: ':not(:eq(0), :eq(1), :eq(2) )',
      // },
    ],
  };
  stockBranchWise: Subscription;
  totalstockWise: Subscription;
  activeTab: string = 'BranchWise';
  allStockList: any;
  stockList: any;
  branchStockList: ProductStockDetailsByBranchDTO[] = [];
  branchProductList: SearchProductStockDetailsDTO[] = [];
  selectAllOption = false;
  productId: string = defaultGuid;
  productIdTotal: string = defaultGuid;
  selectedProduct = [];
  loadStockTable: boolean = true;
  tableData$: Subscription;
  loadBranchStockTable: boolean = true;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  lstBranch: any[] = [];
  selectedBranches: string[] = [];
  tenantId: string = defaultGuid;
  defaultGuid = defaultGuid;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement2: DataTableDirective;
  myTable2: DataTables.Api;
  stockForm: FormGroup;
  totalStockForm: FormGroup;
  @ViewChild('allBranchSelected') private allBranchSelected: MatOption;
  @ViewChild('branchSelect') branchSelect: MatSelect;
  filteredBranch: Observable<string[]> | undefined;
  branchFilterValue: string[] = [];
  ltVendors: any;
  filteredVendorDetails: Observable<VendorDetailsDTO[]> | undefined;
  ltCategories: any;
  filteredCategoryDetails: Observable<string[]> | undefined;
  ltproducts: any;
  filteredProductDetails: Observable<string[]> | undefined;
  searchTypeForm: FormGroup;
  subscription$: Subscription[] = [];

  currentPage : any = 1;
  pageOffset: any = 50;
  totalPages : any = 1;
  visibleColumns: number[] ;
  
  constructor(
    private vendorService: VendorDetailsService,
    private categoryService: ProductCategoryDetailsService,
    private productDetailsService: ProductDetailsService,
    private productStockService: ProductStockDetailsService,
    private communicationService: CommunicationService,
    public dialog: MatDialog,
    public title: Title,
    private renderer: Renderer2,
    private branchService: OrganizationUnitService,
    private fb: FormBuilder,
    private elRef: ElementRef,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
    private commonService: CommonService
  ) {
    const updateStatus =
      this.communicationService.functionReloadStockTable$.subscribe(() => {
        this.reloadStockList();
      });
    this.subscription$.push(updateStatus);
  }

  refreshDataTable(): void { 
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
     
      // Clear search input values
      //$('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();

      // Attach the event listener for filtering
      var visColumns =  this.visibleColumns;
      var index = 0;
      dtInstance.columns().every(function () {
        const that = this; 
        if(visColumns && visColumns.length > 0)
        {  
              if(visColumns.indexOf(index) < 0)
                this.visible(false);
        } 
        index++;
    
      });
    });

  }

  ngAfterViewInit(): void {

  }

  ngOnInit() {
    this.currentPage = 1;
    this.title.setTitle('Qsecure | Stocks');
    this.dtBranchWiseOptions.buttons[0].filename =
      'Branch Wise Stock List' +
      '-' +
      this.datePipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtTotalStockOptions.buttons[0].filename =
      'Total Stock List' +
      '-' +
      this.datePipe.transform(new Date(), 'MM-dd-yyyy');
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.initializeForms();
    this.getBranchList();
    this.getBranchWiseStockReport(defaultGuid);
  }
  ngOnDestroy(): void {
    if (this.stockBranchWise) {
      this.stockBranchWise.unsubscribe();
    }
    if (this.totalstockWise) {
      this.totalstockWise.unsubscribe();
    }
  }
  //To Initialize Forms
  initializeForms() {
    this.searchTypeForm = this.fb.group({
      searchType: new FormControl(''),
    });

    this.stockForm = this.fb.group({
      drpBranch: new FormControl(''),
      txtBranchFilter: new FormControl(''),
      txtStartDate: new FormControl(''),
      txtEndDate: new FormControl(''),
      drpManufacturer: new FormControl(''),
      txtManufacturerFilter: new FormControl(''),
      drpCategory: new FormControl(''),
      txtCategoryFilter: new FormControl(''),
      drpProduct: new FormControl(''),
      txtProductFilter: new FormControl(''),
    });
    this.loadDropdowns();
    const dates = this.commonService.getFirstAndLastDatesOfCurrentMonth();
    this.stockForm.patchValue({
      txtStartDate: dates.fromDate,
      txtEndDate: dates.toDate,
    });
  }
  loadDropdowns() {
    this.searchTypeForm.patchValue({
      searchType: '1',
    });
    const vendorDetails = this.vendorService
      .getActiveVendorList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltVendors = response?.items;
          this.filteredVendorDetails = this.stockForm
            .get('txtManufacturerFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltVendors?.filter((option) =>
                  option?.vendorName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(vendorDetails);

    const categoryDetails = this.categoryService
      .getActiveCategoryList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltCategories = response?.items;
          this.filteredCategoryDetails = this.stockForm
            .get('txtCategoryFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltCategories?.filter((option) =>
                  option?.productCategoryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );

    const productsList = this.productDetailsService
      .getProductListforStock()
      .subscribe(
        (response) => {
          this.ltproducts = response;
          this.filteredProductDetails = this.stockForm
            .get('txtProductFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltproducts?.filter((option) =>
                  option?.productName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(productsList);
  }
  //To get the Branch list
  getBranchList() {
    const getBranchList = this.branchService
      .getBranchListV1(this.tenantId, false)
      .subscribe(
        (response) => {
          this.lstBranch = response;

          // const allBranchOption: OrganizationUnitDTO = {
          //   id: '00000000-0000-0000-0000-000000000000',
          //   organizationUnitName: 'All',
          // };
          // this.lstBranch = [allBranchOption, ...this.lstBranch];
          this.filteredBranch = this.stockForm
            .get('txtBranchFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstBranch?.filter((option) =>
                  option?.organizationUnitName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

          this.stockForm
            .get('drpBranch')
            .patchValue([
              ...this.lstBranch
                .filter((a) => a.mmRefId === -2139397197)
                .map((org) => org.id),
            ]);

          this.selectedBranches = this.lstBranch
            .filter((a) => a.mmRefId === -2139397197)
            .map((org) => org.id);
        },
        (err) => {}
      );
  }

  openTransferModal() {
    const dialogRef = this.dialog.open(InventoryTransferComponent, {
      maxWidth: '75%',
      minHeight: '60%',
      disableClose: true,
      data: {
        transferId: this.selectedProduct,
      },
    });
    dialogRef.afterClosed().subscribe((a) => {
    });
  }

  openAdjustModal() {
    const dialogRef = this.dialog.open(AdjustmentComponent, {
      // maxWidth: '80%',
      // minHeight: '60%',
      disableClose: true,
      data: {
        adjustId: this.selectedProduct,
      },
    });
    dialogRef.afterClosed().subscribe((a) => {
      // this.getBranchList();
      // this.selectedProduct = [];
    });
  }

  handleCheckboxChange(item) {
    // Implement your logic when the checkbox is clicked
  }

  changeTab(tab: string) {
    this.currentPage = 1;
    this.activeTab = tab;
    if (this.activeTab == 'BranchWise') {
      this.stockForm
        .get('drpBranch')
        .patchValue([
          ...this.lstBranch
            .filter((a) => a.mmRefId === -2139397197)
            .map((org) => org.id),
        ]);

      this.selectedBranches = this.lstBranch
        .filter((a) => a.mmRefId === -2139397197)
        .map((org) => org.id);

      this.getBranchWiseStockReport(defaultGuid);
    } else if (this.activeTab == 'TotalStock') {
      this.selectedBranches = [];
      this.searchTypeForm.patchValue({
        searchType: '1',
      });
      const dates = this.commonService.getFirstAndLastDatesOfCurrentMonth();
      this.stockForm.patchValue({
        txtStartDate: dates.fromDate,
        txtEndDate: dates.toDate,
      });
      this.getTotalStockReport();
    }
  }

  onButtonClick(data: any) {}
  selectedLocation = '';
  onCheckboxChange(value, event) {
    if (event.target.checked === true) {
      // Add the ID to the array if it doesn't exist
      if (
        this.selectedLocation === '' ||
        this.selectedLocation === value.branchId
      ) {
        this.selectedLocation = value.branchId;
        this.selectedProduct.push(value.id);
      } else {
        this.toastr.error('Select Same Source Location');
        event.target.checked = false;
        value.isChecked = false;
      }
    } else {
      // Remove the ID from the array
      this.selectedProduct = this.selectedProduct.filter((x) => x !== value.id);
      if (this.selectedProduct.length === 0) {
        this.selectedLocation = '';
      }
    }
  }

  //Select All Users
  toggleAllBranchSelection() {
    if (this.allBranchSelected.selected) {
      this.branchSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.branchSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  onSelectionChange(event: any) {
    const selectedOptions = this.stockForm.get('drpBranch').value;

    if (selectedOptions.includes('selectAll')) {
      this.selectAllOption = !this.selectAllOption;

      if (this.selectAllOption) {
        this.stockForm
          .get('drpBranch')
          .patchValue([...this.lstBranch.map((org) => org.id)]);
        this.selectedBranches = this.lstBranch.map((org) => org.id);
      } else {
        // If "Select All" is deselected, clear all other options
        this.stockForm.get('drpBranch').patchValue([]);
        this.selectedBranches = [];
      }
    } else {
      // If an individual option is selected, check if all options are selected and update "Select All"
      this.selectAllOption = selectedOptions.length === this.lstBranch.length;

      // If an individual option is deselected, deselect "Select All"
      if (
        selectedOptions.length < this.lstBranch.length &&
        this.selectAllOption
      ) {
        this.selectAllOption = false;
        this.stockForm
          .get('drpBranch')
          .patchValue(
            selectedOptions.filter((option) => option !== 'selectAll')
          );
      }
    }
  }

  //To Reload the Stock List
  reloadStockList() {
    console.log('reloadStockList');
    this.getBranchWiseStockReport(defaultGuid);
    // this.getBranchList();
    this.selectedProduct = [];
  }

  //Copy Text While Duble on table Row
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  //To reset the stock form
  resetStockForm() {
    console.log('resetStockForm');
    if (this.activeTab == 'BranchWise') {
      this.stockForm.reset();
      this.stockForm
        .get('drpBranch')
        .patchValue([
          ...this.lstBranch
            .filter((a) => a.mmRefId === -2139397197)
            .map((org) => org.id),
        ]);
      this.onSelectionChange(null);
      this.selectedBranches = this.lstBranch
        .filter((a) => a.mmRefId === -2139397197)
        .map((org) => org.id);
      this.getBranchWiseStockReport(defaultGuid);
    } else if (this.activeTab == 'TotalStock') {
      this.stockForm.reset();
      if (this.searchTypeForm.value.searchType == '1') {
        const dates = this.commonService.getFirstAndLastDatesOfCurrentMonth();
        this.stockForm.patchValue({
          txtStartDate: dates.fromDate,
          txtEndDate: dates.toDate,
        });
        this.getTotalStockReport();
      } else if (this.searchTypeForm.value.searchType == '2') {
        this.allStockList = [];
        this.loadStockTable = false;
        setTimeout(() => {
          this.loadStockTable = true;
        }, 100);
      }
    }
  }

  get validateSearch(): boolean {
    if (this.searchTypeForm.value.searchType == '1') {
      // if (startDate != null && endDate != null) {
      const startDate = this.stockForm.get('txtStartDate').value;
      const endDate = this.stockForm.get('txtEndDate').value;
      return startDate == null || endDate == null;
    } else if (this.searchTypeForm.value.searchType == '2') {
      return (
        (this.stockForm.value.drpManufacturer == null ||
          this.stockForm.value.drpManufacturer == '') &&
        (this.stockForm.value.drpCategory == null ||
          this.stockForm.value.drpCategory == '') &&
        (this.stockForm.value.drpProduct == null ||
          this.stockForm.value.drpProduct == '') &&
        (this.stockForm.value.drpBranch == null ||
          this.selectedBranches.length == 0)
      );
    }
  }

  //#region BranchWise Stock

  //To get the BranchWise Stock Report
  getBranchWiseStockReport(productId: string) {
    console.log('getBranchWiseStockReport...' + "curr pageno : " + this.currentPage + " pgOffset : " + this.pageOffset);
    this.loadStockTable = false;
    this.tableData$?.unsubscribe();
    this.stockBranchWise = this.productStockService
      .productStockDetailsV1ByGBranchIdsAndProductId(
        this.selectedBranches,
        productId,
        this.currentPage,
        this.pageOffset
      )
      .subscribe(
        (response) => {

          //this.stockList = response;
          let pagedResponse = response as any;
          if (pagedResponse == null)
          {
            this.loadStockTable = true;
            return null;
          }
          this.stockList = pagedResponse.items;
          this.totalPages = Math.ceil(pagedResponse.totalCount / this.pageOffset);
          this.stockList = this.stockList.map((stock) => {
            return {
              ...stock,
              createdDate: this.commonService.getFormattedDateTimeZone(
                stock.createdDate
              ),
              modifiedDate: this.commonService.getFormattedDateTimeZone(
                stock.modifiedDate
              ),
              lastReceivedDate: this.commonService.getFormattedDateTimeZone(
                stock.lastReceivedDate
              ),
              lastDispensedDate: this.commonService.getFormattedDateTimeZone(
                stock.lastDispensedDate
              ),
            };
          });
          this.selectedLocation = '';
          this.selectedProduct = [];
          this.loadStockTable = true;
          if (this.datatableElement && this.datatableElement.dtInstance) {
            this.refreshDataTable();
          } else {
            setTimeout(() => this.refreshDataTable(), 500);
          }
        },
        (err) => {
          this.loadStockTable = true;
        }
      );
    this.tableData$ = this.stockBranchWise;
    // setTimeout(() => {
    //   this.addPageNavigationControls();
    // }, 5000);
  }

  //To get the BranchWise Stock History
  getStockHistory(event: Event, productId: string) {
    this.productId = productId ? productId : defaultGuid;
    let stockList: SearchProductStockDetailsDTO[] = [];
    const stockDetails = this.productStockService
      .productStockDetailsV1ByGBranchIdsAndProductId(
        this.selectedBranches,
        productId,
        this.currentPage,
        this.pageOffset
      )
      .subscribe(
        (response) => {
          stockList = response;
          const target = event.target as HTMLElement;
          const tr = target.closest('tr');
          this.getDataTableData2(tr, stockList);
        },
        (err) => {
          const target = event.target as HTMLElement;
          const tr = target.closest('tr');

          this.getDataTableData2(tr, stockList);
        }
      );
    this.subscription$.push(stockDetails);
  }

  //#endregion

  //#region Total Stock

  //#region Search Form

  searchTypeChange() {
    console.log('searchTypeChange...');
    this.currentPage = 1;
    if (this.searchTypeForm.value.searchType == '2') {
      this.stockForm.patchValue({
        drpBranch: '',
      });
      this.selectedBranches = [];

      this.allStockList = [];
      this.loadStockTable = false;
      setTimeout(() => {
        this.loadStockTable = true;
      }, 100);
    } else if (this.searchTypeForm.value.searchType == '1') {
      const dates = this.commonService.getFirstAndLastDatesOfCurrentMonth();
      this.stockForm.patchValue({
        txtStartDate: dates.fromDate,
        txtEndDate: dates.toDate,
      });
      this.getTotalStockReport();
    }
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 100);
    }
  }
  clearManufacturer() {
    this.stockForm.patchValue({
      drpManufacturer: null,
    });
  }
  clearCategory() {
    this.stockForm.patchValue({
      drpCategory: null,
    });
  }
  clearProduct() {
    this.stockForm.patchValue({
      drpProduct: null,
    });
  }
  clearBranch() {
    this.stockForm.patchValue({
      drpBranch: null,
    });
    this.selectedBranches = [];
    this.onSelectionChange(null);
  }

  //#endregion

  //#region Table Binding Api
  //To get the Stock Report
  getTotalStockReport() {
    console.log('getTotalStockReport ');
    // selectedBranches: string[], productId: string
    let manufacturerId =
      this.stockForm.value.drpManufacturer == null ||
      this.stockForm.value.drpManufacturer == ''
        ? defaultGuid
        : this.stockForm.value.drpManufacturer;
    let categoryId =
      this.stockForm.value.drpCategory == null ||
      this.stockForm.value.drpCategory == ''
        ? defaultGuid
        : this.stockForm.value.drpCategory;
    let prodId =
      this.stockForm.value.drpProduct == null ||
      this.stockForm.value.drpProduct == ''
        ? defaultGuid
        : this.stockForm.value.drpProduct;
    this.loadStockTable = false;
    this.tableData$?.unsubscribe();
    if (this.searchTypeForm.value.searchType == '1') {
      const startDate = this.stockForm.get('txtStartDate').value;
      const endDate = this.stockForm.get('txtEndDate').value;
      let fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
      let toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
      //Date Validation
      if (startDate != null && endDate != null) {
        let allStockList: SearchProductStockDetailsDTO[] = [];
        this.loadStockTable = false;
        this.tableData$?.unsubscribe();
        const stockDetails = this.productStockService
          .productStockDetailsForAllBranchV1ByLastReceivedFromDateAndLastReceivedToDateAndGManufacturerAndGCategoryAndGBranchIdsAndProductIdAndIsProductFilter(
            fromDate,
            toDate,
            defaultGuid,
            defaultGuid,
            [],
            defaultGuid,
            false,
            this.currentPage,
            this.pageOffset
          )
          .subscribe(
            (response) => {
              //this.allStockList = response;
              let pagedResponse = response as any;
              this.allStockList = pagedResponse.items;
              this.totalPages = Math.ceil(pagedResponse.totalCount / this.pageOffset);
              this.allStockList = this.allStockList.map((stock) => {
                return {
                  ...stock,
                  createdDate: this.commonService.getFormattedDateTimeZone(
                    stock.createdDate
                  ),
                  modifiedDate: this.commonService.getFormattedDateTimeZone(
                    stock.modifiedDate
                  ),
                  lastReceivedDate: this.commonService.getFormattedDateTimeZone(
                    stock.lastReceivedDate
                  ),
                  lastDispensedDate: this.commonService.getFormattedDateTimeZone(
                    stock.lastDispensedDate
                  ),
                };
              });
              this.loadStockTable = true;
              if (this.datatableElement && this.datatableElement.dtInstance) {
                this.refreshDataTable();
              } else {
                setTimeout(() => this.refreshDataTable(), 100);
              }
            },
            (err) => {
              this.loadStockTable = true;
            }
          );
        this.subscription$.push(stockDetails);
      }
    } else if (this.searchTypeForm.value.searchType == '2') {
      this.totalstockWise = this.productStockService
        .productStockDetailsForAllBranchV1ByLastReceivedFromDateAndLastReceivedToDateAndGManufacturerAndGCategoryAndGBranchIdsAndProductIdAndIsProductFilter(
          null,
          null,
          manufacturerId,
          categoryId,
          this.selectedBranches,
          prodId,
          true,
          this.currentPage,
          this.pageOffset

        )
        .subscribe(
          (response) => {
            //this.allStockList = response;
            let pagedResponse = response as any;
            this.allStockList = pagedResponse.items;
            this.totalPages = Math.ceil(pagedResponse.totalCount / this.pageOffset);
            this.allStockList = this.allStockList.map((stock) => {
              return {
                ...stock,
                createdDate: this.commonService.getFormattedDateTimeZone(
                  stock.createdDate
                ),
                modifiedDate: this.commonService.getFormattedDateTimeZone(
                  stock.modifiedDate
                ),
                lastReceivedDate: this.commonService.getFormattedDateTimeZone(
                  stock.lastReceivedDate
                ),
                lastDispensedDate: this.commonService.getFormattedDateTimeZone(
                  stock.lastDispensedDate
                ),
              };
            });
            this.loadStockTable = true;
            if (this.datatableElement && this.datatableElement.dtInstance) {
              this.refreshDataTable();
            } else {
              setTimeout(() => this.refreshDataTable(), 100);
            }
          },
          (err) => {
            this.loadStockTable = true;
          }
        );
      this.tableData$ = this.totalstockWise;
    }
  }

  //To get the Total Stock History
  getTotalStockHistory(event: Event, productId: string) {
    this.productIdTotal = productId ? productId : defaultGuid;
    let allStockList: SearchProductStockDetailsDTO[] = [];
    var fromDate = null;
    var toDate = null;
    if (this.searchTypeForm.value.searchType == '1') {
      const startDate = this.stockForm.get('txtStartDate').value;
      const endDate = this.stockForm.get('txtEndDate').value;
      fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
      toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
    }

    const stockDetails = this.productStockService
      .productStockDetailsForAllBranchV1ByLastReceivedFromDateAndLastReceivedToDateAndGManufacturerAndGCategoryAndGBranchIdsAndProductIdAndIsProductFilter(
        fromDate,
        toDate,
        defaultGuid,
        defaultGuid,
        [],
        productId,
        false,
        this.currentPage,
        this.pageOffset
      )
      .subscribe(
        (response) => {
          allStockList = response;
          const target = event.target as HTMLElement;
          const tr = target.closest('tr');
          this.getDataTableData2(tr, allStockList);
        },
        (err) => {
          const target = event.target as HTMLElement;
          const tr = target.closest('tr');

          this.getDataTableData2(tr, allStockList);
        }
      );
    this.subscription$.push(stockDetails);
  }
  //#endregion

  //#endregion

  //#region History Tables

  //DataTable Data Get Method and Toggle Notes Row in Table
  async getDataTableData2(tr: any, productList: any): Promise<void> {
    this.myTable2 = await this.datatableElement2.dtInstance;

    if (tr) {
      const row = this.myTable2.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        row.child(this.format2(row.data(), productList)).show();
        row.child().addClass('no-padding');
      }
    }
  }

  //Add Dynamic Notes Row in UnProcessed Table
  format2(data: any, productList: any): HTMLElement {
    const table = this.renderer.createElement('table');
    const thead = this.renderer.createElement('thead');
    const tbody = this.renderer.createElement('tbody');

    const thRow = this.renderer.createElement('tr');

    const th1 = this.renderer.createElement('th');
    const th2 = this.renderer.createElement('th');
    const th3 = this.renderer.createElement('th');
    const th4 = this.renderer.createElement('th');
    const th5 = this.renderer.createElement('th');
    const th6 = this.renderer.createElement('th');
    const th7 = this.renderer.createElement('th');
    const th12 = this.renderer.createElement('th');
    const th13 = this.renderer.createElement('th');
    const th8 = this.renderer.createElement('th');
    const th9 = this.renderer.createElement('th');
    const th10 = this.renderer.createElement('th');
    const th11 = this.renderer.createElement('th');
    const th14 = this.renderer.createElement('th');
    const th15 = this.renderer.createElement('th');
    const th16 = this.renderer.createElement('th');
    const th17 = this.renderer.createElement('th');
    const th18 = this.renderer.createElement('th');
    const th19 = this.renderer.createElement('th');

    // this.renderer.setStyle(th7, 'text-align', 'right');
    // this.renderer.setStyle(th8, 'text-align', 'right');
    this.renderer.appendChild(thRow, th4);
    this.renderer.appendChild(th1, document.createTextNode('#'));
    this.renderer.appendChild(th2, document.createTextNode('Product Code'));
    this.renderer.appendChild(th3, document.createTextNode('Description'));
    this.renderer.appendChild(th4, document.createTextNode('Size'));
    this.renderer.appendChild(th5, document.createTextNode('Manufacturer'));
    this.renderer.appendChild(th6, document.createTextNode('Category'));
    this.renderer.appendChild(th7, document.createTextNode('Location Bin'));
    this.renderer.appendChild(th8, document.createTextNode('Serial/Lot No'));
    this.renderer.appendChild(th9, document.createTextNode('Branch'));
    this.renderer.appendChild(th10, document.createTextNode('Total Quantity'));
    this.renderer.appendChild(th11, document.createTextNode('Sold Quantity'));
    this.renderer.appendChild(
      th12,
      document.createTextNode('Balance Quantity')
    );
    this.renderer.appendChild(th13, document.createTextNode('TotalCost ($)'));
    this.renderer.appendChild(th14, document.createTextNode('Created By'));
    this.renderer.appendChild(th15, document.createTextNode('Created Date'));
    this.renderer.appendChild(th16, document.createTextNode('Modified By'));
    this.renderer.appendChild(th17, document.createTextNode('Modified Date'));
    this.renderer.appendChild(
      th18,
      document.createTextNode('Last Received Date')
    );
    this.renderer.appendChild(
      th19,
      document.createTextNode('Last Dispensed Date')
    );

    this.renderer.appendChild(thRow, th1);
    this.renderer.appendChild(thRow, th2);
    this.renderer.appendChild(thRow, th3);
    this.renderer.appendChild(thRow, th4); // Corrected index for 'Bin Location'
    this.renderer.appendChild(thRow, th5);
    this.renderer.appendChild(thRow, th6);
    this.renderer.appendChild(thRow, th7);
    this.renderer.appendChild(thRow, th8);
    this.renderer.appendChild(thRow, th9);
    this.renderer.appendChild(thRow, th10);
    this.renderer.appendChild(thRow, th11);
    this.renderer.appendChild(thRow, th12);
    this.renderer.appendChild(thRow, th13);
    this.renderer.appendChild(thRow, th14);
    this.renderer.appendChild(thRow, th15);
    this.renderer.appendChild(thRow, th16);
    this.renderer.appendChild(thRow, th17);
    this.renderer.appendChild(thRow, th18);
    this.renderer.appendChild(thRow, th19);
    this.renderer.appendChild(thead, thRow);
    this.renderer.appendChild(table, thead);

    this.renderer.setStyle(table, 'backgroundColor', '#e0e0e0');

    // Loop through productList and create rows
    productList.forEach((value: any, index: number) => {
      const trData = this.renderer.createElement('tr');

      // const tdHash = this.renderer.createElement('td');
      // this.renderer.appendChild(tdHash, document.createTextNode(''));
      // this.renderer.addClass(tdHash, 'dt-control');
      // this.renderer.addClass(tdHash, 'text-center');
      const tdCheckbox = this.renderer.createElement('td');
      const checkbox = this.renderer.createElement('input');
      checkbox.type = 'checkbox';
      this.renderer.appendChild(tdCheckbox, checkbox);
      this.renderer.appendChild(trData, tdCheckbox);

      const tdProductCode = this.renderer.createElement('td');
      this.renderer.appendChild(
        tdProductCode,
        document.createTextNode(value.productCode)
      );

      const tdDescription = this.renderer.createElement('td');
      this.renderer.appendChild(
        tdDescription,
        document.createTextNode(value.description)
      );

      const tdSize = this.renderer.createElement('td');
      this.renderer.appendChild(
        tdSize,
        document.createTextNode(value.sizeName ? value.sizeName : '-')
      );

      const tdManufacturer = this.renderer.createElement('td');
      this.renderer.appendChild(
        tdManufacturer,
        document.createTextNode(value.vendorName ? value.vendorName : '-')
      );

      const tdCategory = this.renderer.createElement('td');
      this.renderer.appendChild(
        tdCategory,
        document.createTextNode(value.categoryName ? value.categoryName : '-')
      );

      const tdBinLocation = this.renderer.createElement('td');
      this.renderer.appendChild(
        tdBinLocation,
        document.createTextNode(value.binLocation ? value.binLocation : '-')
      );

      const tdLotNo = this.renderer.createElement('td');
      this.renderer.appendChild(tdLotNo, document.createTextNode(value.lotNo));

      const tdBranch = this.renderer.createElement('td');
      this.renderer.appendChild(
        tdBranch,
        document.createTextNode(value.branchName)
      );

      const tdQuantity = this.renderer.createElement('td');
      // this.renderer.setStyle(tdQuantity, 'text-align', 'right');

      this.renderer.appendChild(
        tdQuantity,
        document.createTextNode(value.quantity)
      );
      this.renderer.appendChild(trData, tdQuantity);

      const soldQuantity = this.renderer.createElement('td');
      // this.renderer.setStyle(soldQuantity, 'text-align', 'right');
      this.renderer.appendChild(
        soldQuantity,
        document.createTextNode(value.soldQuantity)
      );
      this.renderer.appendChild(trData, soldQuantity);

      const balQuantity = this.renderer.createElement('td');
      // this.renderer.setStyle(balQuantity, 'text-align', 'right');
      this.renderer.appendChild(
        balQuantity,
        document.createTextNode(value.balanceQuantity)
      );
      this.renderer.appendChild(trData, balQuantity);

      const tdTotalUnitCost = this.renderer.createElement('td');
      // this.renderer.setStyle(tdTotalUnitCost, 'text-align', 'right');
      this.renderer.appendChild(
        tdTotalUnitCost,
        document.createTextNode(value.totalUnitCost)
      );

      // const tdBranch = this.renderer.createElement('td');
      // this.renderer.appendChild(
      //   tdBranch,
      //   document.createTextNode(value.branchName)
      // );
      const tdCreatedBy = this.renderer.createElement('td');
      this.renderer.appendChild(
        tdCreatedBy,
        document.createTextNode(value.createdBy ?? '')
      );

      const tdCreatedDate = this.renderer.createElement('td');
      // const formattedCreatedDate = this.datePipe.transform(
      //   value.createdDate,
      //   'MM/dd/yyyy'
      // );
      this.renderer.appendChild(
        tdCreatedDate,
        document.createTextNode(this.commonService.getFormattedDateTimeZone(value.createdDate))
      );

      const tdModifiedBy = this.renderer.createElement('td');
      this.renderer.appendChild(
        tdModifiedBy,
        document.createTextNode(value.modifiedBy)
      );

      const tdModifiedDate = this.renderer.createElement('td');
      // const formattedModifiedDate = this.datePipe.transform(
      //   value.modifiedDate,
      //   'MM/dd/yyyy'
      // );
      this.renderer.appendChild(
        tdModifiedDate,
        document.createTextNode(this.commonService.getFormattedDateTimeZone(value.modifiedDate))
      );

      const tdLastReceivedDate = this.renderer.createElement('td');
      // const formattedLastReceivedDate = this.datePipe.transform(
      //   value.lastReceivedDate,
      //   'MM/dd/yyyy'
      // );
      this.renderer.appendChild(
        tdLastReceivedDate,
        document.createTextNode(this.commonService.getFormattedDateTimeZone(value.lastReceivedDate))
      );

      const tdLastDispensedDate = this.renderer.createElement('td');
      // const formattedLastDispensedDate = value.lastDispensedDate
      //   ? this.datePipe.transform(value.lastDispensedDate, 'MM/dd/yyyy')
      //   : '-';
      this.renderer.appendChild(
        tdLastDispensedDate,
        document.createTextNode(this.commonService.getFormattedDateTimeZone(value.lastDispensedDate))
      );
      this.renderer.setProperty(
        tdCheckbox,
        'checked',
        value.isChecked || false
      );

      // Listen to checkbox change event
      this.renderer.listen(tdCheckbox, 'change', (event) => {
        // Update the product's isChecked property
        value.isChecked = event.target.checked;
        // Call the function to handle checkbox change
        this.onCheckboxChange(value, event);
      });
      // this.renderer.appendChild(trData, tdCheckbox);
      this.renderer.appendChild(trData, tdProductCode);
      this.renderer.appendChild(trData, tdDescription);
      this.renderer.appendChild(trData, tdSize);
      this.renderer.appendChild(trData, tdManufacturer);
      this.renderer.appendChild(trData, tdCategory);
      this.renderer.appendChild(trData, tdBinLocation);
      this.renderer.appendChild(trData, tdLotNo);
      this.renderer.appendChild(trData, tdBranch);
      this.renderer.appendChild(trData, tdQuantity);
      this.renderer.appendChild(trData, soldQuantity);
      this.renderer.appendChild(trData, balQuantity);
      this.renderer.appendChild(trData, tdTotalUnitCost);
      this.renderer.appendChild(trData, tdCreatedBy);
      this.renderer.appendChild(trData, tdCreatedDate);
      this.renderer.appendChild(trData, tdModifiedBy);
      this.renderer.appendChild(trData, tdModifiedDate);
      this.renderer.appendChild(trData, tdLastReceivedDate);
      this.renderer.appendChild(trData, tdLastDispensedDate);
      this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
      this.renderer.appendChild(tbody, trData);
    });

    this.renderer.appendChild(table, tbody);

    return table;
  }
  //#endregion
  addPageNavigationControls(){
    let buttonsElement =  $(".dt-buttons")[0];
    $("<span> : <span>").appendTo(buttonsElement);
    console.log('$addPageNavigationControls : current page = {currentPage}' + this.currentPage)
    if(this.currentPage == 1)
    {
      $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
    }
    else 
    {
      $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
     }

    $("<span> Page <span>").appendTo(buttonsElement);
    $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);
    $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
    $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);
    
    if(this.currentPage >= this.totalPages)
    {
      $("<button  disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
      $("<button  disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }
    else
    {
       $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
       $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.MovePage(true));
    $('#btnPrevPage').on('click', (event) => this.MovePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.changePage(1));
    $('#btnLastPage').on('click', (event) => this.changePage(this.totalPages));
   }

   fnGotoPage(){
    let pageValue = $("#txtGotoPage").val();
    let pageno = parseInt(pageValue.toString());
    if(pageno > 0 && this.currentPage !=  pageno && pageno <= this.totalPages)
    {
      this.changePage(pageno);
    }
  }

  MovePage(isNext:boolean)
  {
    console.log('MovePage called....' + this.currentPage);
    let pageNo = this.currentPage;
    if(isNext)
      pageNo++;
    else 
    pageNo--;
    //this.nevigatePage.emit(this.currentPage);
    this.changePage(pageNo);
  }

  changePage(pageNo : number){
    console.log('changePage PageNo : ' + pageNo + 'this.currentPage :' + this.currentPage + 'this.totalPages :' + this.totalPages);
    if( pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages)
    {
      this.currentPage = pageNo; 
      if (this.activeTab == 'BranchWise') 
      {
        this.getBranchWiseStockReport(defaultGuid);
      }
      else if (this.activeTab == 'TotalStock') {
        this.getTotalStockReport();
      }
    }
  }

}
