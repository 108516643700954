import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IdentityUserDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { SharedTableService } from 'projects/shared/src/app/shared-table/shared-table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { CreateUpdateReminderDetailDTO } from '../order-proxy/order-management/order-optimization/dto';
import { ReminderDetailService } from '../order-proxy/order-management/order-optimization/reminder-detail.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-add-reminder-tab',
  templateUrl: './add-reminder-tab.component.html',
  styleUrls: ['./add-reminder-tab.component.scss']
})
export class AddReminderTabComponent implements OnInit {
  tenantId: string = defaultGuid;
  arrUsersList: IdentityUserDto[] = [];
  reminderForm: FormGroup;
  reminderId: string = defaultGuid;
  minDate: Date;
  saveButtonHide: boolean = false;
  subscription$: Subscription[] = [];
  patientId: string = defaultGuid;
  orderId: string = defaultGuid;
  isShowSpinner: boolean = false;

  isCompleted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public userService: UserService,
    public dialogRef: MatDialogRef<AddReminderTabComponent>,
    public reminderDetailService: ReminderDetailService,
    private sharedTableService: SharedTableService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { patientId: string; orderId: string; reminderId: string } =
      { patientId: defaultGuid, orderId: defaultGuid, reminderId: defaultGuid }
  ) { }
  //! On Init Functions
  ngOnInit(): void {
    this.patientId = this.data?.patientId ?? defaultGuid;
    this.orderId = this.data?.orderId ?? defaultGuid;
    this.reminderId = this.data?.reminderId ?? defaultGuid;
    this.createReminderForm();
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 0);
    this.getDropdown();
  }
  //! Get Dropdowns
  getDropdown() {
    const userList = this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;

      this.reminderId !== defaultGuid && this.getReminderById();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(userList);
  }
  //! Get reminder By Id
  getReminderById() {
    this.saveButtonHide = true;
    const reminder = this.reminderDetailService.get(this.reminderId).subscribe(response => {
      this.reminderForm.patchValue({
        txtSubject: response?.subject,
        txtDate: response?.reminderDate,
        chkDontShowAgain: response?.isDontShowAgain,
        chkCompleted: response?.isCompleted,
        drpAssignedTo: response?.assignedTo,
      })
      this.isCompleted = (response?.isCompleted === 1);

      this.saveButtonHide = false;
      this.tenantId = response?.tenantId ?? defaultGuid;
    }, err => {
      this.saveButtonHide = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(reminder);
  }
  // ! Form Creation and Few Validations
  createReminderForm() {
    this.reminderForm = this.formBuilder.group({
      txtSubject: new FormControl("", [Validators.required]),
      txtDate: new FormControl("", [Validators.required]),
      chkDontShowAgain: new FormControl(""),
      chkCompleted: new FormControl(""),
      drpAssignedTo: new FormControl([]),
    })
  }
  //! Save or Update Reminder
  saveReminders() {
    this.isShowSpinner = true;
    const reminder: CreateUpdateReminderDetailDTO = {
      patientId: this.patientId,
      saleOrderId: this.orderId,
      assignedTo: this.reminderForm?.value?.drpAssignedTo,
      reminderDate: this.reminderForm?.value?.txtDate,
      subject:this.reminderForm?.value?.txtSubject.toString().trim(),
      isCompleted: this.reminderForm?.value?.chkCompleted ? 1 : 0,
      isDontShowAgain: +this.reminderForm?.value?.chkDontShowAgain ? 1 : 0
    }
    if (this.reminderId !== defaultGuid) {
      reminder.tenantId = this.tenantId;
      this.reminderDetailService.update(this.reminderId, reminder).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.dialogRef.close();
        this.getReminderTableData();

        this.isShowSpinner = false;
      }, err => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({ icon: 'info', text: data?.error?.error?.message, });
      })
    } else {
      this.reminderDetailService.create(reminder).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        this.getReminderTableData();
        this.dialogRef.close();
        this.isShowSpinner = false;
      }, err => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({ icon: 'info', text: data?.error?.error?.message, });
      })
    }
  }
  //! Reload Table On save an d update
  getReminderTableData() {
    const getReminderTable = this.reminderDetailService.getRemindersByOrderIdByID(this.orderId).subscribe(response => {
      this.sharedTableService.setTableData(response);
    }, err => {
      this.sharedTableService.setTableData([]);
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(getReminderTable);
  }
}
