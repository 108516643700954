import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IdentityUserDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { wipStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/wip-status.enum';
import { WorkInProgressService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress';
import { IdentityUserService } from 'projects/admin/src/app/admin-proxy/volo/abp/identity/identity-user.service';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService ,ToastrIconClasses } from "ngx-toastr";

@Component({
  selector: 'app-schedule-task',
  templateUrl: './schedule-task.component.html',
  styleUrls: ['./schedule-task.component.scss']
})
export class ScheduleTaskComponent implements OnInit {
  defaultWIPId: string = "";
  WIPId: string = defaultGuid;
  selUserId = defaultGuid;
  documentId: string = defaultGuid;
  defaultGuid = defaultGuid;
  isShowSpinner: boolean = false;
  strWIPStatus: string = "";
  scheduleTaskForm: FormGroup;
  scheduleMaxDate: Date = new Date();
  filteredIdentityUsers: Observable<IdentityUserDto[]> = of([]);
  lstUsers: IdentityUserDto[] = []
  subscription$: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private identityUserService: IdentityUserService,
    private workInProgressService: WorkInProgressService,
    private toastr : ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data: { WIPId: string, defaultWIPId: string, documentId: string, } = { WIPId: defaultGuid, defaultWIPId: "", documentId: defaultGuid }
  ) { }

  ngOnInit(): void {
    this.scheduleTaskForm = this.fb.group({
      txtComment: new FormControl(""),
      txtScheduleDate: new FormControl("", [Validators.required]),
    });
    this.scheduleMaxDate.setDate(new Date().getDate() + 1);
    this.WIPId = this.data?.WIPId ?? defaultGuid;
    this.defaultWIPId = this.data?.defaultWIPId ?? defaultGuid;
    this.documentId = this.data?.documentId ?? defaultGuid;
    this.WIPId !== defaultGuid && this.documentId !== defaultGuid && this.getWIPDetailsById();
  }
  getWIPDetailsById() {
    const workInProgressSub = this.workInProgressService.get(this.WIPId).subscribe(response => {
      response.wipStatus === wipStatus.completed && this.scheduleTaskForm.disable();
      response.wipStatus === wipStatus.completed && this.scheduleTaskForm.disable();
    });
    this.subscription$.push(workInProgressSub);
  }
  updateScheduleDetails() {
    const wipForm = this.scheduleTaskForm?.value;
    if (this.scheduleTaskForm?.disabled) { return null };
    this.isShowSpinner = true;
    this.workInProgressService.get(this.WIPId).pipe(
      switchMap(x => {
        x.comments = wipForm?.txtComment;
        
        return this.workInProgressService.update(this.WIPId, x);
      }),
    ).subscribe(response => {

      this.toastr.success('Saved Successfully','Success')
      // Swal.fire({
      //   title: 'Success',
      //   html: 'Saved Successfully',
      //   icon: 'success',
      //   timer: 3000,
      //   timerProgressBar: true,
      // });
      this.isShowSpinner = false;
      this.matDialog.closeAll();
    },
      (err) => {
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
}
