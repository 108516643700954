import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProductDetailsService } from '../item-proxy/item-management/optimization/product-details.service';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProductHCPCCodeComponent } from './product-hcpc-code/product-hcpc-code.component';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { CreateUpdateProductHcpcCodeDTO } from '../item-proxy/item-management/optimization/dto';
import { Status } from '../item-proxy/item-management/optimization';

@Component({
  selector: 'app-product-update-unit-cost',
  templateUrl: './product-update-unit-cost.component.html',
  styleUrls: ['./product-update-unit-cost.component.scss'],
})
export class ProductUpdateUnitCostComponent implements OnInit {
  step: boolean = true;
  currentPage : any = 1;
  pageOffset: any = 50;
  totalPages : any = 1;
  productUnitCostForm: FormGroup;
  filterProduct: any;
  isLoadingProduct: boolean = false;
  tableDataList: any;
  isLoadingTable: boolean = false;
  subscription$: Subscription[] = [];
  txtProductdrpId: string;
  ProductApiSubscription: Subscription;
  ProductTypeControl = new FormControl();
  ltHCPC: CreateUpdateProductHcpcCodeDTO[] = [];
  productHCPCCodeList: any;
  constructor(
    private formBuilder: FormBuilder,
    private productDetailsService: ProductDetailsService,
    private title: Title,
    private commonService: CommonService,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) {
    this.subscribeToCommunicationService(
      this.communicationService.ProductUnitCostToggleMethodCall$,
      () => this.toggleV2()
    );

    this.subscribeToCommunicationService(
      this.communicationService.triggerProductGetMethodCall$,
      (data) => this.getInsAllowablesById(data)
    );

    this.subscribeToCommunicationService(
      this.communicationService.removeHCPCMethodCall$,
      (code) => this.removeHCPC(code)
    );

    this.subscribeToCommunicationService(
      this.communicationService.addHCPCMethodCall$,
      (code) => this.addHCPC(code)
    );

  }

  ngOnInit(): void {
    this.title.setTitle('Product Unit Cost Update | Qsecure');
    this.initializationForm();
    this.productUnitCostForm.controls['txtHCPC'].disable();
    this.loadProductDetailsV2('');
    this.getTableData();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  private subscribeToCommunicationService(observable, callback) {
    const subscription = observable.subscribe(callback);
    this.subscription$.push(subscription);
  }

  toggleV2() {
    this.step = false;
  }

  getInsAllowablesById(data: any) {
    this.ltHCPC = [];
    this.searchProduct('',data?.id);
    this.txtProductdrpId = data?.id;
    this.step = true;
    this.productUnitCostForm.patchValue({
      drpProduct: data?.id || null,
      txtHCPC: data?.hcpcCode || null,
      txtUnitCost: data?.unitCost || null,
    });
    this.productHCPCCodeList = data?.hcpcCode || [];
    if (this.productHCPCCodeList) {
      this.productHCPCCodeList.forEach(element => {
        this.ltHCPC.push({
          hcpcCode: element,
          productId: this.txtProductdrpId,
          status: Status.Active,
          line: 0,
        });
      });
    }
  }

  initializationForm() {
    const numericPattern = Validators.pattern(/^\d{1,9}(\.\d{1,2})?$/);
    this.productUnitCostForm = this.formBuilder.group({
      drpProduct: new FormControl('', [Validators.required]),
      txtProduct: new FormControl(''),
      txtHCPC: new FormControl(''),
      txtUnitCost: ['', [Validators.required, numericPattern]],
    });
  }

  resetForm() {
    // this.insuranceAllowablesId = '';
    this.productUnitCostForm.reset();
    this.txtProductdrpId = '';
    // this.productUnitCostForm.patchValue({
    //   drpProduct: null,
    //   txtHCPC: null,
    //   txtUnitCost: null,
    // });
    this.clearValidationErrors();
  }

  clearValidationErrors() {
    // Object.keys(this.productUnitCostForm.controls).forEach((key) => {
    //   this.productUnitCostForm.get(key).setErrors(null);
    // });
  }

  productList: any;

  numbersAndfloatOnly(event: any) {
    const allowedChars = '0123456789.';
    const inputChar = event.key;

    if (event.key === '.' && event.currentTarget.value.includes('.')) {
      // Allow only one decimal point
      event.preventDefault();
    } else if (allowedChars.indexOf(inputChar) === -1) {
      // Prevent input of characters other than numbers and dot
      event.preventDefault();
    }
  }
  maxNumberForDispaly = '9999999.99';
  sanitizeInput(event: any, controlName: string) {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^\d.]/g, '');
    this.productUnitCostForm.get(controlName)?.setValue(sanitizedValue);
  }
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData('text');
    const isNumber = /^\d+$/.test(pastedData); //Should contain only whole numbers

    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }

  //To load the product details in the table
  // loadProductDetailsV2() {
  //   this.isLoadingProduct = true;
  //   const productList = this.productDetailsService.getListUnitCost().subscribe(
  //     (response) => {
  //       this.productList = response ?? [];
  //       this.filterProduct = this.productUnitCostForm
  //         .get('txtProduct')
  //         .valueChanges.pipe(
  //           startWith(''),
  //           map((value) =>
  //             this.productList?.filter(
  //               (option) =>
  //                 option?.productCode
  //                   ?.toLowerCase()
  //                   .includes(value?.toLowerCase() ?? '') ||
  //                 option?.mainProductName
  //                   ?.toLowerCase()
  //                   .includes(value?.toLowerCase() ?? '')
  //             )
  //           )
  //         );
  //       this.isLoadingProduct = false;
  //     },
  //     (err) => {
  //       this.isLoadingProduct = false;
  //     }
  //   );
  //   this.subscription$.push(productList);
  // }

  loadProductDetailsV2(inputValue: string) {
    this.isLoadingProduct = true;
    this.searchProduct('',inputValue);
  }

  searchProduct(searchParameter: string,productId:string) {
    if (this.ProductApiSubscription) {
      this.ProductApiSubscription.unsubscribe();
    }
    this.ProductApiSubscription = this.productDetailsService.getListUnitCostBySearchAndInput(productId,searchParameter)
      .subscribe(
        (response) => {
          this.productList = response ?? [];
          this.filterProduct = this.productUnitCostForm
            .get('txtProduct')
            .valueChanges.pipe(
              startWith(''),
              debounceTime(100), // Add debounce time
              distinctUntilChanged(), // Ensure the search term has changed
              map((value) =>
                this.productList?.filter(
                  (option) =>
                    option?.productCode?.toLowerCase().includes(value?.toLowerCase() ?? '') ||
                    option?.mainProductName?.toLowerCase().includes(value?.toLowerCase() ?? '')
                )
              )
            );
          this.isLoadingProduct = false;
        },
        (err) => {
          this.isLoadingProduct = false;
        }
      );
    this.subscription$.push(this.ProductApiSubscription);
  }

  isDropdownOpen = false;
  onDropdownOpenChange(isOpen: boolean,productId: string) {
    this.isDropdownOpen = isOpen;
    if (this.isDropdownOpen) {
      this.productUnitCostForm.patchValue({
        txtProduct: ''
      });
      this.searchProduct('',productId);
    }
  }

  bindHCPCandUnitCost(event: MatSelectChange) {
    const index = this.productList.findIndex((obj) => obj.id === event.value);
    if (index !== -1) {
      this.ltHCPC = [];
      this.productUnitCostForm.patchValue({
        txtHCPC: this.productList[index].hcpcCode || '',
        txtUnitCost: this.productList[index].unitCost || 0,
      });
      this.txtProductdrpId = event.value || defaultGuid;
      this.productHCPCCodeList = this.productList[index].hcpcCode || [];
      if (this.productHCPCCodeList) {
        this.productHCPCCodeList.forEach(element => {
          this.ltHCPC.push({
            hcpcCode: element,
            productId: this.txtProductdrpId,
            status: Status.Active,
            line: 0,
          });
        });
      }
    }
  }
  isSaveLoading: boolean = false;
  unitCostUpdate() {
    this.isLoadingTable=true;
    let gProductId: string, unitCost: number;
    gProductId = this.productUnitCostForm.get('drpProduct').value || defaultGuid;
    unitCost = this.productUnitCostForm.get('txtUnitCost').value || 0;
    this.isSaveLoading = true;
    const save = this.productDetailsService
      .updateUnitCostByGProductIdAndUnitCostAndHcpcinput(gProductId, unitCost, this.ltHCPC)
      .subscribe(
        (response) => {
          this.toastr.success('The unit cost has been updated successfully.', 'Success');
          const index = this.productList.findIndex(
            (obj) => obj.id === response.id
          );
          if (index !== -1) {
            const hcpcCodes = response.hcpcCode;
            this.productList[index].hcpcCode = [...hcpcCodes];
            this.productList[index].unitCost = response.unitCost;
          }

          const indexTbl = this.tableDataList.findIndex(
            (obj) => obj.id === response.id
          );
          if (indexTbl !== -1) {
            const hcpcCodes = response.hcpcCode;
            this.tableDataList[indexTbl].productCode = response.productCode;
            this.tableDataList[indexTbl].mainProductName = response.mainProductName;
            this.tableDataList[indexTbl].hcpcCode = [...hcpcCodes];
            this.tableDataList[indexTbl].unitCost = response.unitCost;
            this.tableDataList[indexTbl].lastModificationTime =
              this.commonService.getFormattedDateTimeZone(
                response.lastModificationTime
              );
            this.tableDataList[indexTbl].dtlastModificationTime =
              this.commonService.getFormattedDateTimewithSecondZone(
                response.lastModificationTime
              );
            this.tableDataList[indexTbl].modifiedBy = response.modifiedBy;
            this.tableDataList[indexTbl].createdBy = response.createdBy;

          } else {
            const hcpcCodes = response.hcpcCode;
            this.tableDataList.push({
              id: response.id,
              productCode: response.productCode,
              mainProductName: response.mainProductName,
              hcpcCode: [...hcpcCodes],
              unitCost: response.unitCost,
              creationTime: this.commonService.getFormattedDateTimeZone(
                response.creationTime
              ),
              lastModificationTime: this.commonService.getFormattedDateTimeZone(
                response.lastModificationTime
              ),
              dtlastModificationTime: this.commonService.getFormattedDateTimewithSecondZone(
                response.lastModificationTime
              ),
              modifiedBy: response.modifiedBy,
              createdBy: response?.createdBy,
            });
          }
          this.tableDataList.sort((a, b) => {
            return (
              new Date(b.dtlastModificationTime).getTime() -
              new Date(a.dtlastModificationTime).getTime()
            );
          });

          this.isLoadingTable=false;
          this.isSaveLoading = false;
          this.resetForm();
        },
        (err) => {
          this.isLoadingTable=false;
          this.isSaveLoading = false;
          this.resetForm();
        }
      );
    this.subscription$.push(save);
  }

  getTableData() {
    this.isLoadingTable = true;
    const productListtbl = this.productDetailsService
      .getListProductDetailsWonance(this.currentPage, this.pageOffset)
      .subscribe(
        (response) => {
          this.tableDataList = response?.items ?? [];
          this.totalPages = Math.ceil((response?.totalCount||0)/ this.pageOffset);
          this.tableDataList = this.tableDataList.map((tbl) => {
            return {
              ...tbl,
              creationTime: this.commonService.getFormattedDateTimeZone(
                tbl.creationTime
              ),
              lastModificationTime: this.commonService.getFormattedDateTimeZone(
                tbl.lastModificationTime
              ),
              dtlastModificationTime: this.commonService.getFormattedDateTimewithSecondZone(
                tbl.lastModificationTime
              ),
            };
          });

          this.isLoadingTable = false;
        },
        (err) => {
          this.isLoadingTable = false;
        }
      );
    this.subscription$.push(productListtbl);
  }
  changePage(pageNo : number){

    if( pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages)
    {
      this.currentPage = pageNo;
      this.getTableData();
    }
  }

  //Open HCPC Modal to save HCPC
  openMdlHCPCcode() {
    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        productdDpId: this.txtProductdrpId,
        productHCPCList: this.ltHCPC,
      },
    };

    const dialogRef = this.dialog.open(ProductHCPCCodeComponent, config);
    const closeDialog = dialogRef.afterClosed().subscribe(
      (result: any) => {
        if (result && result.isAddHCPC && result.ltHCPC) {
          this.ltHCPC = [...result.ltHCPC];
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  //Function To Remove HCPC Code List When Clicked on Remove Button
  removeHCPC(code: string) {
    let currentHcpcCodes = this.productUnitCostForm.get('txtHCPC')?.value || '';
    if (typeof currentHcpcCodes !== 'string') {
      currentHcpcCodes = String(currentHcpcCodes);
    }
    const updatedHcpcCodes = currentHcpcCodes
      .split(',')
      .filter(hcpcCode => hcpcCode !== code)
      .join(',');
    this.productUnitCostForm.get('txtHCPC')?.setValue(updatedHcpcCodes);
  }

  //Function To Add HCPC Code List When Clicked on Add Button
  addHCPC(code: string) {
    let currentHcpcCodes = this.productUnitCostForm.get('txtHCPC')?.value || '';
    if (typeof currentHcpcCodes !== 'string') {
        currentHcpcCodes = String(currentHcpcCodes);
    }
    const hcpcCodeArray = currentHcpcCodes.split(',').filter(Boolean);
    if (!hcpcCodeArray.includes(code)) {
        hcpcCodeArray.push(code);
    }
    const updatedHcpcCodes = hcpcCodeArray.join(',');
    this.productUnitCostForm.get('txtHCPC')?.setValue(updatedHcpcCodes);
}

}
