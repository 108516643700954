<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-lg-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bolder mb-3 mt-2"><b class="header-class">List / Claim Dept / Claim Status List</b></h3>

        <div class="page-title-box page-title-right">
          <form [formGroup]="claimStatusForm">
            <div class="row d-flex justify-content-center align-items-center">
              <ng-container *ngIf="filterValue">
                <mat-form-field class="pr-3 dd-text customWidthV1">
                  <mat-label >Patient Search</mat-label>
                  <mat-select (selectionChange)="onChartIdChange($event)" formControlName="drpPatient" #singleSelect>
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Search" (keyup)="searchPatient($event.target.value)"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtPatientFilter"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let chart of lstPatientChartNos" [value]="chart.patientId">
                      {{ chart.ddValue }}
                    </mat-option>
                  </mat-select>
                  <button mat-button matSuffix (click)="clearPatient($event)" [disabled]="isEmpty(this.claimStatusForm.value.drpPatient)" mat-icon-button>
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!filterValue">

                <!-- <mat-checkbox  class="pr-3 dd-text" (change)="selectCheckBox($event)" formControlName="chkDateRequired" > Filter By Date
                </mat-checkbox> -->

                <mat-form-field [hidden]="OnlyTicketIDBased" *ngIf="chkDateRequired" class="pr-3 pt-2 customWidth"
                  [ngClass]="{'mat-form-field-invalid': claimStatusForm.get('txtStartDateValue').invalid || claimStatusForm.get('txtEndDateValue').invalid}">
                  <mat-label>Order Date Range<span class="asterisk">*</span></mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate matInput [(ngModel)]="dOrderFromDate" placeholder="Start date"
                      formControlName="txtStartDateValue" (keydown)="addSlashToDate($event)" maxlength="10" />
                    <input matEndDate matInput (dateChange)="onDateChange($event)" [(ngModel)]="dOrderToDate"
                      placeholder="End date" formControlName="txtEndDateValue" (keydown)="addSlashToDate($event)"
                      maxlength="10" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                  <mat-error *ngIf="claimStatusForm.get('txtStartDateValue').hasError('required')">Start date is
                    required</mat-error>
                  <mat-error
                    *ngIf="claimStatusForm.get('txtStartDateValue').invalid && !claimStatusForm.get('txtStartDateValue').hasError('required')">Invalid
                    start date</mat-error>

                  <mat-error *ngIf="claimStatusForm.get('txtEndDateValue').hasError('required')">End date is
                    required</mat-error>
                  <mat-error
                    *ngIf="claimStatusForm.get('txtEndDateValue').invalid && !claimStatusForm.get('txtEndDateValue').hasError('required')">Invalid
                    end date</mat-error>
                  <mat-error *ngIf="claimStatusForm.hasError('dateRangeError')">From date must be less than or equal to
                    To date</mat-error>
                </mat-form-field>




                <mat-form-field class="pr-3 pt-2 dd-text" [hidden]="OnlyTicketIDBased">
                  <mat-label>Claim Status</mat-label>
                  <mat-select formControlName="drpOrderStatus" [value]="defaultStatusValue"
                    (selectionChange)="onStatusChange()">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                        formControlName="txtStatusFilter"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="0">
                      All
                    </mat-option>
                    <mat-option *ngFor="let status of filteredOrderStatusList | async" [value]="status.id">
                      {{ status.claimStatus }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="pr-3 pt-2 dd-text">
                  <mat-label>Ticket No</mat-label>
                  <input autocomplete="new-txtTicketNo" matInput formControlName="txtTicketNo" maxlength="10"
                    (input)="sanitizeInput($event, 'txtTicketNo');handleInputChange($event)" />
                </mat-form-field>
              </ng-container>

              <div class="pr-3 dd-text ">
                <app-common-switch class="h-100" [onText]="'Patient'" [offText]="'Others'"
                  (changed)="filterChange($event)">
                </app-common-switch>
              </div>

              <button matTooltip="Click To Upload EOB" mat-button class="buttonColor font-size-10 mr-2"
                (click)="openEOBModel()">
                Upload EOB
              </button>
              <img matTooltip="Check Color Code" (click)="OpenColorDiv()" class="pointer img-responsive mb-1 mr-1"
                height="33" src="assets/Claim Page Icons/color_drop.png" />

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-1 p-2">
    <div class="" [ngClass]="iscolorCode === true ? 'col-11_5 mb-5' : 'col-12 mb-5'">
      <div class="card card-body p-2">
        <div class="d-flex justify-content-center" *ngIf="isLoadClaimStatusTableData">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
        <div *ngIf="!isLoadClaimStatusTableData" class="table table-responsive">
          <app-claim-status-list-table (reloadClaimTable)="reloadClaimTable()"
            [claimsTableData]="claimDataArray"></app-claim-status-list-table>
        </div>
      </div>
    </div>

    <div *ngIf="iscolorCode" class="" [ngClass]="iscolorCode === true ? 'col-0_5' : ''">
      <div class="border d-flex align-items-center justify-content-center">
        <div class="p-1">
          <ng-container *ngFor="let color of lstStaticColorList">
            <div [ngbTooltip]="color.status" class="circle white mb-2"
              [ngStyle]="{ 'background-color': color.colorCode }"></div>
          </ng-container>
        </div>
      </div>
    </div>

  </div>
</div>
