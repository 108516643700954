<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <h3 class="customThemeClass">
        <b>
          <ng-container *ngIf="documentType == 1">
            Fax Id: {{ defaultDocId }}
          </ng-container>
          <ng-container *ngIf="documentType == 2">
            Check No: {{ defaultDocId }}
          </ng-container>
        </b>
      </h3>
    </div>
  </div>

  <div class="row d-flex align-items-center justify-content-center">
    <div class="col-10">
      <div class="pdf-container">
        <ng-container>
          <ngx-extended-pdf-viewer
            [base64Src]="strPdfString"
            [height]="pdfheight"
            [showSidebarButton]="false"
            [showFindButton]="true"
            [textLayer]="true"
            [showPagingButtons]="true"
            [showOpenFileButton]="false"
            [showHandToolButton]="false"
            [showScrollingButton]="false"
            [showSpreadButton]="false"
            [showPropertiesButton]="false"
            [showBookmarkButton]="false"
            [zoom]="'page-width'"
            [enableDragAndDrop]="false"
          >
          </ngx-extended-pdf-viewer>
        </ng-container>
      </div>
    </div>
  </div>
</div>
