import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PDFDocument } from 'pdf-lib';
import { ItemPaymentService } from '../../../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import {
  AdjustmentPaymentDTO,
  CreateUpdateAdjustmentPaymentDTO,
  CreateUpdateItemPaymentsDTO,
  CreateUpdateItemPaymentsForCCDTO,
  ItemPaymentsAddEOBDTO,
} from '../../../claim-proxy/claim-processing-management/rcm/billing-management/posting/dto/models';
import {
  ccPlan,
  defaultGuid,
  postingType,
} from 'projects/shared/src/app/enums/allenums.enum';
import { ClaimProcessingService } from '../../../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-processing.service';
import { Observable, Subscription } from 'rxjs';
import {
  ClaimHCPCList,
  ClaimNoDTO,
} from '../../../claim-proxy/claim-processing-management/rcm/billing-management/dto/models';
import { map, startWith } from 'rxjs/operators';
import de from 'date-fns/esm/locale/de/index.js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { GlossaryDetailsService } from '../../../claim-proxy/claim-processing-management/rcm/billing-management/optimization/claim/glossary-details.service';
import {
  GlossaryDTO,
  PaymentMethodBillingStatusDTO,
} from '../../../claim-proxy/claim-processing-management/rcm/billing-management/optimization/dto/models';
import { DatePipe } from '@angular/common';
import { CCSettlementService } from '../../../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/ccsettlement.service';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { EOBHistoryService } from '../../../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/eobhistory.service';
import { MatSelectChange } from '@angular/material/select';
import { BillingReportService } from '../../../claim-proxy/claim-processing-management/rcm/billing-management/optimization/billing-reports/billing-report.service';
// import { CCSettlementService } from '../../../claim-proxy/claim-processing-management/rcm/billing-management/billing/ccsettlement.service';

@Component({
  selector: 'app-upload-eob',
  templateUrl: './upload-eob.component.html',
  styleUrls: ['./upload-eob.component.scss'],
})
export class UploadEOBComponent implements OnInit {
  isAllCCSettled: boolean = false;
  DateMax: Date;
  isPatientResponsibility: boolean = false;
  isAddItemPaymentTableLoaded: boolean = true;
  AddItemPaymentTableData: any;
  AddItemForm: FormGroup;
  FileForm: FormGroup;
  AddAdjustmentPaymentForm: FormGroup;
  pdfheight: string = '100%';
  notesText = '';
  userManulUpload: boolean = false;
  isPaymentTabelLengthchk: any = 0;
  dtAddItemPaymentTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    fixedHeader: true, // Fix the header

    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    order: [],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Add Item Payment',
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
    ],
    columnDefs: [
      { targets: [0, 1], orderable: false },
      { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' }, // Ad
    ],
  };
  strPdfString: string | ArrayBuffer | null = null; // Initialize to null
  isPdfLoad: boolean = true;
  adjustmentPaymentDTO: AdjustmentPaymentDTO[] = [];
  dtAdjustmentPaymentOptions: any = {
    responsive: true,
    bFilter: false,
    paging: false,
    info: false,
    scrollCollapse: true,
    scrollY: '90px',
    fixedHeader: true, // Fix the header
    columnDefs: [{ targets: [-1], orderable: false }],
    order: [[3, 'asc']],
  };
  // index: number = 0;
  Currentindex: string = null;
  isAdjLoading: boolean = true;
  private loadClaimListAPICall: Subscription;
  isLoadingloadClaimList: boolean = false;
  // filteredClaimList:ClaimNoDTO[];
  filteredClaimList: Observable<ClaimNoDTO[]>;
  lstClaimList: ClaimNoDTO[];
  filteredtxtItemId_HCPCCode: Observable<ClaimHCPCList[]>;
  lstHCPCList: ClaimHCPCList[];
  // filteredtxtItemId_HCPCCode: ClaimItemHcpcCode[];
  isLoadingloadonClaimChange: boolean = false;
  tooltipPatientValue: string = '-';
  tooltipInsuranceValue: string = '-';
  tooltipTicketValue: number = 0;
  // isLoadingHCPC: boolean = false;
  private loadHCPCCodeListAPICall: Subscription;
  private loadAddItemPaymentTableAPICall: Subscription;
  isSaveAddItemPayment: boolean = false;
  isUpdateAddItemPayment: boolean = false;
  private SaveAddItemPaymentAPICall: Subscription;
  private UpdateAddItemPaymentAPICall: Subscription;
  private paymentTypeApiCall: Subscription;
  patientId: string;
  eobBlobName: any;
  isIndividual: boolean = false;
  isloadViewEOBPDF: boolean = false;
  private loadViewEOBPDFAPICall: Subscription;
  OrderId: string = defaultGuid;
  // isDeleteItem: boolean = false;
  private deleteItemAPICall: Subscription;
  chartId: string = '';
  isEditEnabled: boolean = false;
  EditId: string = '';
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  checkEFTDrpData: any;
  openCheckEFTDrp: any;
  private checkEFTdrpApiSubscription: Subscription;
  ischeckEFTLoading: boolean = false;
  ischeckEftDisable: boolean = false;
  // @ViewChild(DataTableDirective, { static: false })
  // datatableElementForReason: DataTableDirective;
  isInsuranceAvailable: boolean = false;
  myTable: DataTables.Api;
  // myTableForReason: DataTables.Api;
  isloadingGlossaryDropDownData: boolean = false;
  filteredreasonCode: Observable<GlossaryDTO[]>;
  filteredPaymentTypes: Observable<PaymentMethodBillingStatusDTO[]>;
  lstReasonCode: any;
  ltPaymentTypes: any;
  glossaryCode: string;
  loadGlossaryDropDownAPICall: Subscription;
  EditData: any;
  // lstPlanTypeList:claimPlanInsurance[];
  // filterPlanTypeList: Observable<claimPlanInsurance[]>;
  lstPlanTypeList: any;
  sPlan = ccPlan;
  filteredPlanList: any = [
    {
      // id: 0,
      name: this.sPlan[0],
    },
    {
      // id: 1,
      name: this.sPlan[1],
    },
    {
      // id: 2,
      name: this.sPlan[2],
    },
    {
      // id: 3,
      name: this.sPlan[3],
    },
  ];
  newFilesForUpload: File;
  isClaimLoading: any = false;
  maxNumberForDispaly = '999999999.9999';
  isSavedOrUpdated: boolean = false;
  DefaultLastUploadBlobName: string = '';
  DefaultLastUploadBlobFileName:string = ''
  isUpdateCCSetItemPayment: boolean = false;
  settledClaimIds: string[] = [];
  isUpdateCCSetItemPaymentAPICall: Subscription;
  fileUploadAPICall: Subscription;
  isZeroAllowed: boolean = false;
  constructor(
    private snackBar: MatSnackBar,
    private itemPaymentService: ItemPaymentService,
    private cCSettlementService: CCSettlementService,
    private claimProcessingService: ClaimProcessingService,
    private glossaryDetailsService: GlossaryDetailsService,
    public EoBDialogRef: MatDialogRef<UploadEOBComponent>,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private datepipe: DatePipe,
    private eobHistoryService: EOBHistoryService,
    private billingReportService: BillingReportService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cliamData: any;
      isSaveEnable: boolean;
    } = {
      cliamData: [],
      isSaveEnable: false,
    }
  ) {
    this.DateMax = new Date();
  }

  ngOnInit(): void {
    this.isIndividual = this.data.cliamData ? true : false;
    this.intializeForms();
    if (this.isIndividual) {
      this.loadClaimList(this.data?.cliamData?.defaultClaimId || defaultGuid);
      // this.loadViewEOBPDF(this.data?.cliamData?.eobBlobName || '');
    } else {
      this.loadClaimList('');
    }
    this.loadGlossaryDropDown();
    this.loadPaymentTypes();
    this.getDrpCheckEFT('');
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
  }
  ngAfterViewInit(): void {
    window.removeEventListener('resize', this.calculateScrollY);
  }
  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.2; // Adjust the percentage as needed
    this.dtAdjustmentPaymentOptions.scrollY =
      (screenHeight - 100) * availableSpacePercentage;
  };

  ngOnDestroy(): void {
    if (this.loadClaimListAPICall) {
      this.loadClaimListAPICall.unsubscribe();
    }
    if (this.loadGlossaryDropDownAPICall) {
      this.loadGlossaryDropDownAPICall.unsubscribe();
    }
    if (this.loadHCPCCodeListAPICall) {
      this.loadHCPCCodeListAPICall.unsubscribe();
    }
    if (this.SaveAddItemPaymentAPICall) {
      this.SaveAddItemPaymentAPICall.unsubscribe();
    }
    if (this.UpdateAddItemPaymentAPICall) {
      this.UpdateAddItemPaymentAPICall.unsubscribe();
    }

    if (this.loadAddItemPaymentTableAPICall) {
      this.loadAddItemPaymentTableAPICall.unsubscribe();
    }
    if (this.loadViewEOBPDFAPICall) {
      this.loadViewEOBPDFAPICall.unsubscribe();
    }
    if (this.deleteItemAPICall) {
      this.deleteItemAPICall.unsubscribe();
    }
    if (this.isUpdateCCSetItemPaymentAPICall) {
      this.isUpdateCCSetItemPaymentAPICall.unsubscribe();
    }
    if (this.fileUploadAPICall) {
      this.fileUploadAPICall.unsubscribe();
    }
    if (this.paymentTypeApiCall) {
      this.paymentTypeApiCall.unsubscribe();
    }
    window.removeEventListener('resize', this.calculateScrollY);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }
  intializeForms() {
    this.FileForm = this.formBuilder.group({
      fileUpload: ['', Validators.required],
      slctCheckEFTFilter: new FormControl(''),
      slctCheckEFT: new FormControl(''),
    });

    const numericPattern = Validators.pattern(/^\d{1,9}(\.\d{1,4})?$/);

    this.AddItemForm = this.formBuilder.group({
      sltClaimId: new FormControl('', [Validators.required]),
      sltClaimIdFilter: new FormControl(''),
      txtInsurance: new FormControl(''),
      txtPatient: new FormControl(''),
      txtTicketId: new FormControl(''),
      // txtCheckno: new FormControl('', [Validators.required],),
      // txtCheckno: new FormControl('', [Validators.required, Validators.pattern(/^\S*$/)]),
      txtCheckno: new FormControl('', [Validators.required, Validators.pattern(/^(?!\s*$).+/)]),
      dCheckDate: new FormControl(this.DateMax, [Validators.required]),
      txtPlan: new FormControl('', [Validators.required]),
      sltItemId_HCPCCode: new FormControl('', [Validators.required]),
      sltItemId_HCPCCodeFilter: new FormControl(''),
      // txtbilledAmount: new FormControl('', [Validators.required]),
      txtbilledAmount: ['', [Validators.required, numericPattern]],
      txtAllowed: ['', [Validators.required, numericPattern]],
      txtPaid: ['', [numericPattern]],
      txtDeduct: ['', [numericPattern]],
      txtCoins: ['', [numericPattern]],
      txtCurrentBalance: ['', [numericPattern]],
      txtCheckAmount: ['', [Validators.required, numericPattern]],
      dPostingDate: new FormControl(this.DateMax, [Validators.required]),
      drpPaymentType: new FormControl('', [Validators.required]),
      txtPaymentTypeFilter: new FormControl(''),
    });

    this.AddAdjustmentPaymentForm = this.formBuilder.group({
      txtReasonCode: new FormControl('', [Validators.required]),
      sltReasonCodeFilter: new FormControl(''),
      txtAdjustment: ['', [Validators.required, numericPattern]],
      txtComments: new FormControl(''),
    });
  }
  // // Custom validator function
  // customValidator(control: AbstractControl): ValidationErrors | null {
  //   const value: string = control.value || '';
  //   const valid = /^[a-zA-Z0-9!@#$%^&*()\-+=:;"',.?\/{}[\]`~|\\]+$/.test(
  //     value.trim()
  //   );
  //   return valid ? null : { invalidInput: true };
  // }

  //Row Click Event UnProcessed Table
  handleTableCellClick(e: MouseEvent, lstAdjustPayments: any): void {
    try {
      const target = e.target as HTMLElement;
      const tr = target.closest('tr');
      this.getDataTableData(tr, lstAdjustPayments);
    } catch (error) {
      console.error('Unexpected error:', error);
      // setTimeout(() => {
      //   this.isloadingGlossaryDropDownData = false;
      // }, 500);
    } finally {
    }
  }

  // By Manikandan For collapse All Rows
  collapseAllRows(): void {
    try {
      const rows = this.myTable?.rows({ page: 'current' }); // Get all rows on the current page
      rows.every((idx, data, node) => {
        const rowData = this.myTable?.row(node).data();
        if (rowData) {
          this.myTable?.row(node).child.hide(); // Hide the child row if it's expanded
        }
        return true; // Continue to the next row
      });
    } catch (error) {
      console.error('Unexpected error:', error);
      // setTimeout(() => {
      //   this.isloadingGlossaryDropDownData = false;
      // }, 500);
    } finally {
    }
  }

  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, lstAdjustPayments: any): Promise<void> {
    try {
      this.myTable = await this.datatableElement.dtInstance;
      if (tr) {
        const row = this.myTable.row(tr);
        if (row.child.isShown()) {
          // This row is already open - close it
          row.child.hide();
        } else {
          // Open this row
          this.collapseAllRows(); // By Manikandan

          row.child(this.format(row.data(), lstAdjustPayments)).show();
        }
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      // setTimeout(() => {
      //   this.isloadingGlossaryDropDownData = false;
      // }, 500);
    } finally {
    }
  }

  // format(d: any, lstAdjustPayments: any) {
  //   let returnString: string = '';
  //   if (lstAdjustPayments && lstAdjustPayments.length > 0) {
  //     lstAdjustPayments.forEach(function (value, index) {
  //       const concatenatedValue =
  //         (value?.reasonCode || '-') + ' - ' + (value?.adjustment || '-') + ' - ' + (value?.glossaryDescription || '-');
  //       returnString +=
  //         '<tr class="border-bottom " style="white-space: pre-wrap !important;font-size:12px !important;"><td style="border: 1px solid rgba(0, 0, 0, 0.2); width: 100%">' +
  //         concatenatedValue +
  //         '</td></tr>';
  //     });
  //   } else {
  //     returnString = '<tr><td>No data available</td></tr>'; // Placeholder message when notesData is null or empty
  //   }

  //   return returnString;
  // }

  format(d: any, lstAdjustPayments: any): string {
    let returnString = '';
    if (lstAdjustPayments && lstAdjustPayments.length > 0) {
      lstAdjustPayments.forEach(function (value) {
        const concatenatedValue = `${value?.reasonCode || '-'} - ${
          value?.adjustment || '-'
        } - ${value?.glossaryDescription || '-'}`;
        // returnString += `<tr class="border-bottom" style="white-space: pre-wrap !important;font-size:12px !important;">
        //                    <td style="border: 1px solid rgba(0, 0, 0, 0.2); width: 100%">${concatenatedValue}</td>
        //                  </tr>`;
        returnString +=
          '' +
          concatenatedValue +
          '<h1 class="line" style="border-bottom: 1px solid rgb(196, 201, 196);Margin: 4px 0 9px 0px"></h1>';
      });
    } else {
      returnString = '<tr><td></td></tr>';
    }
    return returnString;
  }

  loadGlossaryDropDown() {
    this.isloadingGlossaryDropDownData = true;
    try {
      if (this.loadGlossaryDropDownAPICall) {
        this.loadGlossaryDropDownAPICall.unsubscribe();
      }
      this.loadGlossaryDropDownAPICall = this.glossaryDetailsService
        .drpGlossary()
        .subscribe(
          (response) => {
            if (response.items.length > 0) {
              this.lstReasonCode = response?.items;
              this.filteredreasonCode = this.AddAdjustmentPaymentForm.get(
                'sltReasonCodeFilter'
              ).valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.lstReasonCode?.filter((option) =>
                    option?.glossaryCode
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
            }

            setTimeout(() => {
              this.isloadingGlossaryDropDownData = false;
            }, 500);
          },
          (error) => {
            console.error('Error:', error);
            setTimeout(() => {
              this.isloadingGlossaryDropDownData = false;
            }, 500);
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isloadingGlossaryDropDownData = false;
      }, 500);
    } finally {
    }
  }
  loadPaymentTypes() {
    if (this.paymentTypeApiCall) {
      this.paymentTypeApiCall.unsubscribe();
    }
    this.paymentTypeApiCall = this.billingReportService
      .getPaymentMethodsByBFilter(true)
      .subscribe(
        (response) => {
          this.ltPaymentTypes = response ?? [];
          this.filteredPaymentTypes = this.AddItemForm.get(
            'txtPaymentTypeFilter'
          ).valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.ltPaymentTypes?.filter((option) =>
                option?.statusName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        },
        (error) => {
          console.error('Error:', error);
        }
      );
  }
  patchNotes(event: string) {
    const index = this.lstReasonCode.findIndex((obj) => obj.id === event);
    if (index !== -1) {
      this.AddAdjustmentPaymentForm?.patchValue({
        txtComments: this.lstReasonCode[index]?.description || '',
      });
      this.glossaryCode =
        this.lstReasonCode[index]?.glossaryCode || defaultGuid;
    }
  }

  loadViewEOBPDF(blobName: string) {
    if (!this.isEmpty(blobName)) {
      this.isloadViewEOBPDF = false;
      this.isPdfLoad = false;
      try {
        if (this.loadViewEOBPDFAPICall) {
          this.loadViewEOBPDFAPICall.unsubscribe();
        }
        this.loadViewEOBPDFAPICall = this.itemPaymentService
          .getEOBDocumentByBlobIDByBlobName(blobName)
          .subscribe(
            (response) => {
              if (response !== null && response !== undefined) {
                this.strPdfString = response.toString();
              } else {
                // this.toastr.warning(
                //   'PDF Response is null or undefined.',
                //   'Warning'
                // );
                // this.showNotification('PDF Response is null or undefined.');
                this.strPdfString = null;
              }
              setTimeout(() => {
                this.isloadViewEOBPDF = true;
                this.isPdfLoad = true;
              }, 500);
            },
            (error) => {
              console.error('Error:', error);
              this.strPdfString = null;
              setTimeout(() => {
                this.isloadViewEOBPDF = true;
                this.isPdfLoad = true;
              }, 500);
            }
          );
      } catch (error) {
        console.error('Unexpected error:', error);
        this.strPdfString = null;
        setTimeout(() => {
          this.isloadViewEOBPDF = true;
          this.isPdfLoad = true;
        }, 500);
      } finally {
      }
    } else {
      // this.toastr.warning('EOB file has not found', 'Warning');
      this.isPaymentTabelLengthchk === 0
        ? console.log('EOB file has not found')
        : this.showNotification('EOB file has been found');
      // this.showNotification('EOB file has not found');
      this.strPdfString = null;
      setTimeout(() => {
        this.isloadViewEOBPDF = true;
        this.isPdfLoad = true;
      }, 500);
    }
  }
  searchClaim(value: any) {
    this.loadClaimList(value);
  }

  loadClaimList(value: any) {
    this.isLoadingloadClaimList = true;
    try {
      if (this.loadClaimListAPICall) {
        this.loadClaimListAPICall.unsubscribe();
      }
      this.loadClaimListAPICall = this.claimProcessingService
        .getClaimIdList(value)
        .subscribe(
          (response) => {
            this.lstClaimList = response;
            this.filteredClaimList = this.AddItemForm.get(
              'sltClaimIdFilter'
            ).valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstClaimList?.filter((option) =>
                  option?.claimNo
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
            if (this.isIndividual) {
              this.AddItemForm?.patchValue({
                // sltClaimId: response[0]
                sltClaimId: response[0].claimId,
              });
              this.onClaimChange();
            }

            setTimeout(() => {
              this.isLoadingloadClaimList = false; // Enable the checkbox
            }, 100);
          },
          (error) => {
            console.error('Error:', error);
            setTimeout(() => {
              this.isLoadingloadClaimList = false; // Enable the checkbox
            }, 100);
          }
        );
    } catch (error) {
      // this.reloadTable.emit('Mani');
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isLoadingloadClaimList = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }

  patchBilledAmount() {
    const index = this.lstHCPCList.findIndex(
      (obj) => obj.orderItemId === this.AddItemForm.value.sltItemId_HCPCCode
    );
    if (index !== -1) {
      this.AddItemForm?.patchValue({
        txtbilledAmount: this.lstHCPCList[index]?.billedAmount || 0,
        txtAllowed: this.lstHCPCList[index]?.allowedAmount || 0,
      });
      this.onKeyPressToFindisZero(2);
    }
  }
  isDropdownOpen = false;
  onDropdownOpenChange(isOpen: boolean) {
    this.isDropdownOpen = isOpen;
    if (this.isDropdownOpen) {
      this.searchClaim('');
    }
    // You can add additional logic here if needed
  }

  onClaimChange() {
    this.loadAddItemPaymentTable();
    // if(!this.isLoadingloadonClaimChange){
    this.isLoadingloadonClaimChange = true;
    // this.isLoadingHCPC = true;
    try {
      // if (this.loadClaimListAPICall) {
      //   this.loadClaimListAPICall.unsubscribe();
      // }
      this.loadClaimListAPICall = this.claimProcessingService
        .loadPatientOrderDetailsByGClaimId(
          // this.AddItemForm.value.sltClaimId.claimId
          this.AddItemForm.value.sltClaimId
        )
        .subscribe(
          (response) => {
            this.DefaultLastUploadBlobName = response?.eobBlobName || '';
            // this.DefaultLastUploadBlobFileName = response?.eobBlobName || '';
            this.lstPlanTypeList = response?.ltPlanTypes;
            if (!this.userManulUpload) {
              this.loadViewEOBPDF(this.DefaultLastUploadBlobName);
            }
            this.patientId = response?.patientId || defaultGuid;
            this.AddItemForm?.patchValue({
              txtPatient: response?.patientName || '-',
              txtTicketId: response?.ticketId || '-',
              txtPlan: response?.planType || null,
            });
            this.tooltipPatientValue = response?.patientName || '-';
            this.tooltipTicketValue = response?.ticketId || 0;
            this.OrderId = response.orderId;
            this.chartId = response.chartId;
            this.adjustmentPaymentDTO = [];

            this.lstHCPCList = response.claimItemList;
            this.filteredtxtItemId_HCPCCode = this.AddItemForm.get(
              'sltItemId_HCPCCodeFilter'
            ).valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstHCPCList?.filter((option) =>
                  option?.hcpcCode
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

            // if (response?.planType !== this.sPlan[0]) {
            //   this.isInsurance = true;
            //   const filteredPlnTypeInsurance = response?.ltPlanTypes.filter(
            //     (plan) => plan.planType === response?.planType
            //   );
            //   this.AddItemForm?.patchValue({
            //     txtInsurance: filteredPlnTypeInsurance[0]?.insuranceName,
            //   });
            //   this.tooltipInsuranceValue =
            //     filteredPlnTypeInsurance[0]?.insuranceName || '-';
            // }
            setTimeout(() => {
              this.onPlanChange();
              this.isLoadingloadonClaimChange = false; // Enable the checkbox
            }, 100);
            // setTimeout(() => {
            //   // this.isLoadingHCPC = false; // Enable the checkbox
            // }, 100);
          },
          (error) => {
            console.error('Error:', error);
            this.AddItemForm?.patchValue({
              txtPatient: '-',
              txtTicketId: '-',
            });
            setTimeout(() => {
              this.isLoadingloadonClaimChange = false; // Enable the checkbox
            }, 100);
          }
        );
    } catch (error) {
      // this.reloadTable.emit('Mani');
      console.error('Unexpected error:', error);
      this.AddItemForm?.patchValue({
        txtPatient: '-',
        txtTicketId: '-',
      });
      setTimeout(() => {
        this.isLoadingloadonClaimChange = false; // Enable the checkbox
      }, 100);
    } finally {
      // this.loadHCPCCode();
      // this.loadAddItemPaymentTable();
    }
    // }
  }

  isInsurance: boolean = false;
  onPlanChange() {
    if (this.AddItemForm.value.txtPlan === this.sPlan[0]) {
      this.isInsuranceAvailable = false;
      this.isPatientResponsibility = true;
      this.isInsurance = false;
      this.clearValidatorsPatientResponsibility();
      this.onKeyPressToFindisZero(3);
    } else {
      this.isPatientResponsibility = false;
      this.isInsurance = true;
      this.updateValidatorsForNonPatientResponsibility();
      this.onKeyPressToFindisZero(1);

      const filteredPlnTypeInsurance = this.lstPlanTypeList.filter(
        (plan) => plan.planType === this.AddItemForm.value.txtPlan
      );
      this.AddItemForm?.patchValue({
        txtInsurance: filteredPlnTypeInsurance[0]?.insuranceName,
      });
      this.tooltipInsuranceValue =
        filteredPlnTypeInsurance[0]?.insuranceName || '-';

      if (filteredPlnTypeInsurance[0]?.isValid === false) {
        this.isInsuranceAvailable = true;
        Swal.fire({
          title:
            'Patient ' +
            filteredPlnTypeInsurance[0]?.planType +
            ' insurance is not available',
          icon: 'warning',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        });
      } else {
        this.isInsuranceAvailable = false;
      }
    }
  }

  clearValidatorsPatientResponsibility() {
    this.AddItemForm.controls['sltItemId_HCPCCode'].clearValidators();
    this.AddItemForm.controls['sltItemId_HCPCCode'].updateValueAndValidity();
    this.AddItemForm.controls['txtbilledAmount'].clearValidators();
    this.AddItemForm.controls['txtbilledAmount'].updateValueAndValidity();
    this.AddItemForm.controls['txtAllowed'].clearValidators();
    this.AddItemForm.controls['txtAllowed'].updateValueAndValidity();
    this.AddItemForm.controls['txtCheckAmount'].clearValidators();
    this.AddItemForm.controls['txtCheckAmount'].updateValueAndValidity();
    this.AddItemForm.controls['drpPaymentType'].clearValidators();
    this.AddItemForm.controls['drpPaymentType'].updateValueAndValidity();
    //File Browse Upload Remove Validation when select Patient Responsibility
    this.FileForm.controls['fileUpload'].clearValidators();
    this.FileForm.controls['fileUpload'].updateValueAndValidity();

    this.AddItemForm.get('txtPaid').setValidators([Validators.required]);
    this.AddItemForm.get('txtPaid').updateValueAndValidity();

    this.AddItemForm.get('txtCurrentBalance').setValidators([
      Validators.required,
    ]);
    this.AddItemForm.get('txtCurrentBalance').updateValueAndValidity();
  }

  updateValidatorsForNonPatientResponsibility() {
    const numericPattern = Validators.pattern(/^\d{1,9}(\.\d{1,4})?$/);
    this.AddItemForm.get('sltItemId_HCPCCode').setValidators([
      Validators.required,
    ]);
    this.AddItemForm.get('sltItemId_HCPCCode').updateValueAndValidity();
    this.AddItemForm.get('txtbilledAmount').setValidators([
      Validators.required,
      numericPattern,
    ]);
    this.AddItemForm.get('txtbilledAmount').updateValueAndValidity();
    this.AddItemForm.get('txtAllowed').setValidators([
      Validators.required,
      numericPattern,
    ]);
    this.AddItemForm.get('txtAllowed').updateValueAndValidity();
    this.AddItemForm.get('txtCheckAmount').setValidators([
      Validators.required,
      numericPattern,
    ]);
    this.AddItemForm.get('txtCheckAmount').updateValueAndValidity();

    this.AddItemForm.get('drpPaymentType').setValidators([Validators.required]);
    this.AddItemForm.get('drpPaymentType').updateValueAndValidity();

    this.FileForm.get('fileUpload').setValidators([Validators.required]);
    this.FileForm.get('fileUpload').updateValueAndValidity();

    this.AddItemForm.controls['txtPaid'].clearValidators();
    this.AddItemForm.controls['txtPaid'].updateValueAndValidity();

    this.AddItemForm.controls['txtCurrentBalance'].clearValidators();
    this.AddItemForm.controls['txtCurrentBalance'].updateValueAndValidity();
  }

  loadAddItemPaymentTable() {
    this.isAddItemPaymentTableLoaded = false;
    try {
      if (this.loadAddItemPaymentTableAPICall) {
        this.loadAddItemPaymentTableAPICall.unsubscribe();
      }
      this.loadAddItemPaymentTableAPICall = this.itemPaymentService
        .getItemPaymentsBasedOnClaimIdByClaimId(
          // this.AddItemForm.value.sltClaimId.claimId
          this.AddItemForm.value.sltClaimId
        )
        .subscribe(
          (response) => {
            this.isPaymentTabelLengthchk = response.length;
            this.AddItemPaymentTableData = response || [];
            response.length === 0
              ? (this.ischeckEftDisable = true)
              : (this.ischeckEftDisable = false);
            this.getDrpCheckEFTForSearch(this.AddItemPaymentTableData[0]?.checkNo);
            // this.loadViewEOBPDF(response[0]?.eobPdfBlobName || '');
            setTimeout(() => {
              this.statusChange({
                checked: this.ischeckEftDisable,
              } as MatSlideToggleChange);
              this.isAddItemPaymentTableLoaded = true; // Enable the checkbox
              const filteredCheckEFT = this.checkEFTDrpData.filter(
                (check) =>
                  check.blobName ===
                  this.AddItemPaymentTableData[0]?.eobPdfBlobName
              );
              this.FileForm?.patchValue({
                slctCheckEFT: filteredCheckEFT[0]?.blobName,
              });
            this.AddItemForm.patchValue({
            txtCheckno : filteredCheckEFT[0]?.checkNumber,
            txtCheckAmount : filteredCheckEFT[0]?.checkAmount
          });
            }, 100);
          },
          (error) => {
            console.error('Error:', error);
            this.AddItemPaymentTableData = [];
            setTimeout(() => {
              this.isAddItemPaymentTableLoaded = true; // Enable the checkbox
            }, 100);
          }
        );
    } catch (error) {
      // this.reloadTable.emit('Mani');
      console.error('Unexpected error:', error);
      this.AddItemPaymentTableData = [];
      setTimeout(() => {
        this.isAddItemPaymentTableLoaded = true; // Enable the checkbox
      }, 100);
    } finally {
    }
  }

  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  // sanitizeInput(event: any, controlName: string) {
  //   const inputValue = event.target.value;
  //   const sanitizedValue = inputValue.replace(/[^\d.]/g, '');
  //   this.AddItemForm.get(controlName)?.setValue(sanitizedValue);
  // }

  sanitizeInput(event: any, controlName: string) {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^\d.]/g, '');
    this.AddItemForm.get(controlName)?.setValue(sanitizedValue);
  }

  sanitizeInputForAdjustment(event: any, controlName: string) {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^\d.]/g, '');
    this.AddAdjustmentPaymentForm.get(controlName)?.setValue(sanitizedValue);
  }

  sanitizeInputForAdjustmentNoSpace(event: any, controlName: string) {
    const inputValue = event.target.value;

    // Check if the input value is empty or contains only spaces
    if (inputValue.trim() === '') {
      // If the input is empty or contains only spaces, set the value to an empty string
      this.AddAdjustmentPaymentForm.get(controlName)?.setValue('');
      return;
    }

    // Remove unwanted characters except for spaces within the text
    const sanitizedValue = inputValue.replace(
      /(^\s+|\s+$|[^ \w\d!@#$%^&*()\-+=:;"',.?\/{}[\]`~|\\])/g,
      ''
    );
    this.AddAdjustmentPaymentForm.get(controlName)?.setValue(sanitizedValue);
  }

  clearItemId_HCPCCodeList(event: Event) {
    event.stopPropagation();
    this.AddItemForm?.patchValue({
      sltItemId_HCPCCode: '',
    });
  }

  clearClaimList(event: Event) {
    event.stopPropagation();
    this.AddItemForm?.patchValue({
      sltClaimId: '',
    });
  }

  resetAdjustmentPaymentForm() {
    this.AddAdjustmentPaymentForm?.reset();
    this.Currentindex = null;
    this.EditData = null;
  }

  saveAddAdjustment() {
    this.isAdjLoading = false;

    let x = this.adjustmentPaymentDTO.find(
      (p) =>
        p.adjustment === this.AddAdjustmentPaymentForm.value.txtAdjustment &&
        p.glossaryId === this.AddAdjustmentPaymentForm.value.txtReasonCode
      // p.reasonCode === this.glossaryCode //this.AddAdjustmentPaymentForm.value.txtReasonCode
    );
    if (x) {
      this.toastr.warning(
        'Adjustment Payment has been already exists same value',
        'Warning'
      );
      setTimeout(() => {
        this.isAdjLoading = true;
      }, 100);
    } else {
      let adjustmentPaymentDTO: AdjustmentPaymentDTO;
      adjustmentPaymentDTO = {
        paymentId: defaultGuid,
        reasonCode: this.glossaryCode,
        glossaryId: this.AddAdjustmentPaymentForm.value.txtReasonCode,
        adjustment: this.AddAdjustmentPaymentForm.value.txtAdjustment,
        glossaryDescription: this.AddAdjustmentPaymentForm.value.txtComments,
      };
      this.adjustmentPaymentDTO.push(adjustmentPaymentDTO);
      this.resetAdjustmentPaymentForm();
      setTimeout(() => {
        this.isAdjLoading = true;
      }, 100);
    }
  }
  updateAdjustment() {
    this.isAdjLoading = false;
    const index = this.adjustmentPaymentDTO.findIndex(
      (obj) =>
        obj.glossaryId === this.Currentindex &&
        obj.adjustment === this.EditData.adjustment
    );
    if (index !== -1) {
      this.adjustmentPaymentDTO[index] = {
        paymentId: defaultGuid,
        reasonCode: this.glossaryCode,
        glossaryId: this.AddAdjustmentPaymentForm.value.txtReasonCode,
        adjustment: this.AddAdjustmentPaymentForm.value.txtAdjustment,
        glossaryDescription: this.AddAdjustmentPaymentForm.value.txtComments,

        // glossaryId: this.Currentindex,
        // paymentId: defaultGuid,
        // reasonCode: this.AddAdjustmentPaymentForm.value.txtReasonCode,
        // adjustment: this.AddAdjustmentPaymentForm.value.txtAdjustment,
        // glossaryDescription : this.AddAdjustmentPaymentForm.value.txtComments,
      };
      // this.Currentindex = null;
      // this.EditData = null;
      this.resetAdjustmentPaymentForm();
      setTimeout(() => {
        this.isAdjLoading = true;
      }, 100);
    } else {
      this.toastr.warning('Something went wrong', 'Warning');
      setTimeout(() => {
        this.isAdjLoading = true;
      }, 100);
    }
  }
  edit(data: any) {
    this.EditData = data;
    this.Currentindex = data.glossaryId;
    this.AddAdjustmentPaymentForm.patchValue({
      txtReasonCode: data.glossaryId, //data.reasonCode,
      txtAdjustment: data.adjustment,
      txtComments: data.glossaryDescription,
    });
  }
  CancelAdjustment() {
    this.resetAdjustmentPaymentForm();
  }
  delete(data: any) {
    Swal.fire({
      title: 'Are you sure to delete - Adjustment Payment ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        // if (confirm('Are you sure to delete - Adjustment Payment ?')) {
        const index = this.adjustmentPaymentDTO.findIndex(
          (obj) => obj.glossaryId === data?.glossaryId
        );
        if (index !== -1) {
          this.adjustmentPaymentDTO.splice(index, 1);
        }
      } else {
      }
    });
  }

  convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

  setDateValueV2 = (dateValue: any) => {
    if (!dateValue) return '';
    return this.datepipe.transform(dateValue, 'yyyy-MM-dd');
  };

  saveAddItemPayment() {
    if (this.userManulUpload) {
      this.fileUpload();
    } else {
      this.saveAddItemPaymentAfterFileUpload();
    }
    this.userManulUpload = false;
  }

  saveAddItemPaymentAfterFileUpload() {
    if (this.isEditEnabled) {
      // For Update
      this.isUpdateAddItemPayment = true;
      try {
        if (this.UpdateAddItemPaymentAPICall) {
          this.UpdateAddItemPaymentAPICall.unsubscribe();
        }

        let lstAdjustPayments: CreateUpdateAdjustmentPaymentDTO[] = [];
        lstAdjustPayments = this.adjustmentPaymentDTO;

        const index = this.lstHCPCList.findIndex(
          (obj) =>
            obj.orderItemId === this.AddItemForm.value?.sltItemId_HCPCCode
        );
        let itemProcedureCode = '';
        if (index !== -1) {
          itemProcedureCode = this.lstHCPCList[index].hcpcCode;
        }

        const index1 = this.lstClaimList.findIndex(
          (obj) => obj.claimId === this.AddItemForm.value?.sltClaimId
        );
        let claimNumber = '';
        if (index1 !== -1) {
          claimNumber = this.lstClaimList[index1].claimNo;
        }

        let createUpdateItemPaymentsForCCDTO: CreateUpdateItemPaymentsForCCDTO;
        if (this.isPatientResponsibility) {
          createUpdateItemPaymentsForCCDTO = {
            claimId: this.AddItemForm.value?.sltClaimId || defaultGuid,
            patientId: this.patientId,
            patientName: this.AddItemForm.value?.txtPatient || '',
            chartId: this.chartId,
            planType: this.AddItemForm.value?.txtPlan || '',
            checkNo: this.AddItemForm.value?.txtCheckno.trim() || '',
            billed: 0,
            allowed: 0,
            coinsurance: 0,
            deductAmount: 0,
            insurancePaid: this.AddItemForm.value?.txtPaid || 0,
            checkAmount: 0,
            currentBalance: this.AddItemForm.value?.txtCurrentBalance || 0,
            lstAdjustPayments: [],
            eobPdfBlobName: this.DefaultLastUploadBlobName,
            eobPdfFileName:this.DefaultLastUploadBlobFileName,
            claimNumber: claimNumber,
            checkDate: this.setDateValueV2(this.AddItemForm.value?.dCheckDate),
            orderId: this.OrderId,
            itemId: null,
            itemProcedureCode: '',
            postingDate: this.setDateValueV2(
              this.AddItemForm.value?.dPostingDate
            ),
            postingType: postingType.ManualPosting,
            paymentMethodBillingStatus: null,
          };
        } else {
          createUpdateItemPaymentsForCCDTO = {
            claimId: this.AddItemForm.value?.sltClaimId || defaultGuid,
            patientId: this.patientId,
            patientName: this.AddItemForm.value?.txtPatient || '',
            chartId: this.chartId,
            planType: this.AddItemForm.value?.txtPlan || '',
            checkNo: this.AddItemForm.value?.txtCheckno.trim() || '',
            billed: this.AddItemForm.value?.txtbilledAmount || 0,
            allowed: this.AddItemForm.value?.txtAllowed || 0,
            coinsurance: this.AddItemForm.value?.txtCoins || 0,
            deductAmount: this.AddItemForm.value?.txtDeduct || 0,
            insurancePaid: this.AddItemForm.value?.txtPaid || 0,
            checkAmount: this.AddItemForm.value?.txtCheckAmount || 0,
            currentBalance: this.AddItemForm.value?.txtCurrentBalance || 0,
            lstAdjustPayments: lstAdjustPayments,
            eobPdfBlobName: this.DefaultLastUploadBlobName,
            eobPdfFileName:this.DefaultLastUploadBlobFileName,
            claimNumber: claimNumber,
            checkDate: this.setDateValueV2(this.AddItemForm.value?.dCheckDate),
            orderId: this.OrderId,
            itemId: this.AddItemForm.value?.sltItemId_HCPCCode || defaultGuid,
            itemProcedureCode: itemProcedureCode,
            postingDate: this.setDateValueV2(
              this.AddItemForm.value?.dPostingDate
            ),
            postingType: postingType.ManualPosting,
            paymentMethodBillingStatus:
              this.AddItemForm.value.drpPaymentType ?? null,
            };
        }

        let id = this.EditId;
        this.loadClaimListAPICall = this.itemPaymentService
          // .updateForCCV1(id, createUpdateItemPaymentsForCCDTO)
          .updateV2(id, createUpdateItemPaymentsForCCDTO)
          .subscribe(
            (response) => {
              this.isSavedOrUpdated = true;
              this.onPlanChange();
              // this.loadViewEOBPDF(this.DefaultLastUploadBlobName); API issue null so need to check
              const uIndex = this.AddItemPaymentTableData.findIndex(
                (obj) => obj.id === this.EditId
              );
              if (uIndex !== -1) {
                // this.AddItemPaymentTableData[uIndex] = response;
              }
              this.loadAddItemPaymentTable();
              // this.isAddItemPaymentTableLoaded = false;
              setTimeout(() => {
                // this.isAddItemPaymentTableLoaded = true;
                this.isUpdateAddItemPayment = false;
              }, 100);
              this.resetAddItemBasicPayment();
              this.resetAdjustmentPaymentForm();
              this.adjustmentPaymentDTO = [];
              this.isEditEnabled = false;
              this.AddItemForm.markAsPristine();
              this.AddItemForm.markAsUntouched();
              this.toastr.success('Update successfully', 'Success');
            },
            (err) => {
              // // Check if the error has the expected structure
              if (err.error && err.error.error && err.error.error.message) {
                //   // Extract the specific error message from the error object
                const specificErrorMessage = err.error.error.message;
                //   // Show a warning toaster with the specific error message
                // this.toastr.warning(
                //   'Manual Update Failed: Reason: ' + specificErrorMessage,
                //   'Warning'
                // );
                Swal.fire({
                  text: specificErrorMessage,
                  icon: 'warning',
                  confirmButtonText: 'OK',
                });
              } else {
                //   // If the error structure is unexpected, log the entire error message
                console.error('Unexpected error structure:', err);
                Swal.fire({
                  text: 'Manual Update Failed',
                  icon: 'warning',
                  confirmButtonText: 'OK',
                });
                // this.toastr.error('Manual Update Failed', 'Error');
              }
              setTimeout(() => {
                // this.isSaveAddItemPayment = false;
                this.isUpdateAddItemPayment = false;
                // this.isEditEnabled = false;
              }, 100);
            }
          );
      } catch (error) {
        // this.reloadTable.emit('Mani');
        console.error('Unexpected error:', error);
        // this.toastr.warning(
        //   'Error - Update manual posting. Please try again.',
        //   'Warning'
        // );

        Swal.fire({
          text: 'Error - Update manual posting. Please try again.',
          icon: 'warning',
          confirmButtonText: 'OK',
        });
        setTimeout(() => {
          this.isUpdateAddItemPayment = false;
          // this.isEditEnabled = false;
        }, 100);
      } finally {
      }
    } else {
      // For Save
      this.isSaveAddItemPayment = true;
      try {
        if (this.SaveAddItemPaymentAPICall) {
          this.SaveAddItemPaymentAPICall.unsubscribe();
        }
        let lstAdjustPayments: CreateUpdateAdjustmentPaymentDTO[] = [];
        lstAdjustPayments = this.adjustmentPaymentDTO;

        const index = this.lstHCPCList.findIndex(
          (obj) =>
            obj.orderItemId === this.AddItemForm.value?.sltItemId_HCPCCode
        );
        let itemProcedureCode = '';
        if (index !== -1) {
          itemProcedureCode = this.lstHCPCList[index].hcpcCode;
        }

        const index1 = this.lstClaimList.findIndex(
          (obj) => obj.claimId === this.AddItemForm.value?.sltClaimId
        );
        let claimNumber = '';
        if (index1 !== -1) {
          claimNumber = this.lstClaimList[index1].claimNo;
        }
        let createUpdateItemPaymentsForCCDTO: CreateUpdateItemPaymentsForCCDTO;
        if (this.isPatientResponsibility) {
          createUpdateItemPaymentsForCCDTO = {
            claimId: this.AddItemForm.value?.sltClaimId || defaultGuid,
            patientId: this.patientId,
            patientName: this.AddItemForm.value?.txtPatient || '',
            chartId: this.chartId,
            planType: this.AddItemForm.value?.txtPlan || '',
            checkNo: this.AddItemForm.value?.txtCheckno.trim() || '',
            billed: 0,
            allowed: 0,
            coinsurance: 0,
            deductAmount: 0,
            insurancePaid: this.AddItemForm.value?.txtPaid || 0,
            checkAmount: 0,
            currentBalance: this.AddItemForm.value?.txtCurrentBalance || 0,
            lstAdjustPayments: [],
            eobPdfBlobName: this.DefaultLastUploadBlobName,
            eobPdfFileName:this.DefaultLastUploadBlobFileName,
            claimNumber: claimNumber,
            checkDate: this.setDateValueV2(this.AddItemForm.value?.dCheckDate),
            orderId: this.OrderId,
            itemId: null,
            itemProcedureCode: '',
            postingDate: this.setDateValueV2(
              this.AddItemForm.value?.dPostingDate
            ),
            postingType: postingType.ManualPosting,
            paymentMethodBillingStatus: null,
          };
        } else {
          createUpdateItemPaymentsForCCDTO = {
            claimId: this.AddItemForm.value?.sltClaimId || defaultGuid,
            patientId: this.patientId,
            patientName: this.AddItemForm.value?.txtPatient || '',
            chartId: this.chartId,
            planType: this.AddItemForm.value?.txtPlan || '',
            checkNo: this.AddItemForm.value?.txtCheckno.trim() || '',

            billed: this.AddItemForm.value?.txtbilledAmount || 0,
            allowed: this.AddItemForm.value?.txtAllowed || 0,
            coinsurance: this.AddItemForm.value?.txtCoins || 0,
            deductAmount: this.AddItemForm.value?.txtDeduct || 0,
            insurancePaid: this.AddItemForm.value?.txtPaid || 0,
            checkAmount: this.AddItemForm.value?.txtCheckAmount || 0,
            currentBalance: this.AddItemForm.value?.txtCurrentBalance || 0,
            lstAdjustPayments: lstAdjustPayments,
            eobPdfBlobName: this.DefaultLastUploadBlobName,
            eobPdfFileName:this.DefaultLastUploadBlobFileName,
            claimNumber: claimNumber,
            checkDate: this.setDateValueV2(this.AddItemForm.value?.dCheckDate),
            orderId: this.OrderId,
            itemId: this.AddItemForm.value?.sltItemId_HCPCCode || defaultGuid,
            itemProcedureCode: itemProcedureCode,
            postingDate: this.setDateValueV2(
              this.AddItemForm.value?.dPostingDate
            ),
            postingType: postingType.ManualPosting,
            paymentMethodBillingStatus:
              this.AddItemForm.value.drpPaymentType ?? defaultGuid,
          };
        }
        this.loadClaimListAPICall = this.itemPaymentService
          // .createForCCV1(createUpdateItemPaymentsForCCDTO)
          .createV2(createUpdateItemPaymentsForCCDTO)
          .subscribe(
            (response) => {
              this.isSavedOrUpdated = true;
              // this.settledClaimIds=[];
              this.isAllCCSettled = false;
              this.onPlanChange();
              this.resetAddItemBasicPayment();
              this.resetAdjustmentPaymentForm();
              this.adjustmentPaymentDTO = [];
              // this.isAddItemPaymentTableLoaded = false;
              // this.AddItemPaymentTableData.push(response);
              this.loadAddItemPaymentTable();
              setTimeout(() => {
                // this.isAddItemPaymentTableLoaded = true;
                this.isSaveAddItemPayment = false;
              }, 100);
              this.AddItemForm.markAsPristine();
              this.AddItemForm.markAsUntouched();
              this.toastr.success('Saved successfully', 'Success');
            },
            (err) => {
              // // Check if the error has the expected structure
              if (err.error && err.error.error && err.error.error.message) {
                //   // Extract the specific error message from the error object
                const specificErrorMessage = err.error.error.message;
                //   // Show a warning toaster with the specific error message
                // this.toastr.warning(
                //   'Manual Save Failed: Reason: ' + specificErrorMessage,
                //   'Warning'
                // );
                Swal.fire({
                  text: 'Manual Save Failed: Reason: ' + specificErrorMessage,
                  icon: 'warning',
                  confirmButtonText: 'OK',
                });
              } else {
                // If the error structure is unexpected, log the entire error message
                console.error('Unexpected error structure:', err);
                // this.toastr.error('Manual Save Failed', 'Error');
                Swal.fire({
                  text: 'Manual Save Failed',
                  icon: 'warning',
                  confirmButtonText: 'OK',
                });
              }
              setTimeout(() => {
                this.isSaveAddItemPayment = false; // Enable the checkbox
              }, 100);
            }
          );
      } catch (error) {
        // this.reloadTable.emit('Mani');
        console.error('Unexpected error:', error);
        // this.toastr.warning(
        //   'Error - manual posting. Please try again.',
        //   'Warning'
        // );
        Swal.fire({
          // title: 'warning',
          text: 'Error - manual posting. Please try again.',
          icon: 'warning',
          confirmButtonText: 'OK',
        });
        setTimeout(() => {
          this.isSaveAddItemPayment = false; // Enable the checkbox
        }, 100);
      } finally {
      }
    }
  }
  resetAddItemBasicPayment() {
    this.AddItemForm.patchValue({
      // sltClaimId: null,
      // txtPatient: null,
      // txtTicketId: null,
      txtPlan: null,
      sltItemId_HCPCCode: null,
      txtbilledAmount: null,
      txtAllowed: null,
      txtPaid: null,
      txtDeduct: null,
      txtCoins: null,
      txtCurrentBalance: null,
      isInsurance: null,
      // dPostingDate: null,
    });
    this.isInsurance = false; //
    this.isPatientResponsibility = false;
    this.userManulUpload = false;
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  resetAddItemPayment() {
    if (this.isEditEnabled) {
      this.isEditEnabled = false;
      this.isZeroAllowed = false;
      // this.loadViewEOBPDF(this.DefaultLastUploadBlobName); // API issue null so need to check
      this.AddItemForm.patchValue({
        txtPlan: null,
        sltItemId_HCPCCode: null,
        txtCheckno: null,
        dCheckDate: new Date(),
        txtbilledAmount: null,
        txtAllowed: null,
        txtPaid: null,
        txtDeduct: null,
        txtCoins: null,
        txtCurrentBalance: null,
        txtCheckAmount: null,
        dPostingDate: new Date(),
        drpPaymentType: null,
      });
      this.onPlanChange();
    } else {
      Swal.fire({
        title: 'Are you sure to reset all the details?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          const resetFields = {
            txtPlan: this.AddItemForm.get('txtPlan').value,
            sltClaimId: this.AddItemForm.get('sltClaimId').value,
            txtTicketId: this.AddItemForm.get('txtTicketId').value,
            txtPatient: this.AddItemForm.get('txtPatient').value,
            txtInsurance: this.AddItemForm.get('txtInsurance').value,
          };
          this.isZeroAllowed = false;
          // if (confirm('Are you sure to reset all the details?')) {
          this.AddItemForm?.reset(resetFields);
          // this.FileForm?.reset();
          // this.isPdfLoad = false;
          // this.strPdfString = null;
          this.isEditEnabled = false;
          this.isIndividual = false;
          // this.isAddItemPaymentTableLoaded = false;
          // this.AddItemPaymentTableData = [];
          this.adjustmentPaymentDTO = [];
          this.resetAdjustmentPaymentForm();
          // this.loadClaimList('');
          this.settledClaimIds = [];
          this.onPlanChange();
          this.isAllCCSettled = false;
          setTimeout(() => {
            this.clearPaymentFields();
            // this.isAddItemPaymentTableLoaded = true;
            // this.isPdfLoad = true;
            // this.isInsurance = false;
          }, 100);
        }
      });
    }
  }

  // formatDate(isoDate: string): string {
  //   const dateObject = new Date(isoDate);
  //   return `${(dateObject.getMonth() + 1).toString().padStart(2, '0')}/
  //       ${dateObject.getDate().toString().padStart(2, '0')}/
  //       ${dateObject.getFullYear().toString()}`;
  // }
  editItem(event: MouseEvent, claimData: any) {
    event.stopPropagation();
    try {
      // Open this row
      this.collapseAllRows();
    } catch (error) {
      console.error('collapseAllRows Unexpected error:', error);
    } finally {
    }

    // this.index = 0;
    this.isEditEnabled = true;
    this.EditId = claimData.id;
    this.DefaultLastUploadBlobFileName = claimData?.eobPdfFileName;
    // if(!claimData.eobPdfBlobName){
    this.loadViewEOBPDF(claimData.eobPdfBlobName); // API issue null so need to check
    // }
    this.getDrpCheckEFTForSearch(claimData?.checkNo);
    // this.AddItemForm.sltClaimId.
    this.AddItemForm.patchValue({
      // sltClaimId: claimData?.claimId || defaultGuid,
      // txtPatient: claimData?.patientName || '',
      // txtTicketId: claimData?.ticketId || 0,
      txtPlan: claimData?.planType || '',
      sltItemId_HCPCCode: claimData?.itemId || defaultGuid,
      txtCheckno: claimData?.checkNo || 0,
      dCheckDate: new Date(claimData?.checkDate) || null,
      // dCheckDate: this.formatDate(claimData?.checkDate) || null,
      // { hcpcCode: claimData.itemProcedureCode , orderItemId:claimData.itemId , productId: "1c5f0f2a-dd6e-8e4d-1655-3a122ffaa1d9"}
      txtbilledAmount: claimData.billed,
      txtAllowed: claimData.allowed,
      txtPaid: claimData.insurancePaid,
      txtDeduct: claimData.deductAmount,
      txtCoins: claimData?.coinsurance || null,
      txtCurrentBalance: claimData?.currentBalance || null,
      txtCheckAmount: claimData?.checkAmount || null,
      drpPaymentType: claimData?.paymentMethodBillingStatusId || null,
      // dPostingDate: this.formatDate(claimData?.postingDate) || null,
      dPostingDate: new Date(claimData?.checkDate) || null,
    });
    setTimeout(() => {
      const filteredCheckEFT = this.checkEFTDrpData.filter(
        (check) => check.blobName === claimData?.eobPdfBlobName
      );
      this.FileForm?.patchValue({
        slctCheckEFT: filteredCheckEFT[0]?.blobName,
      });
    }, 100);
    this.onPlanChange();
    if (claimData.lstAdjustPayments) {
      this.isAdjLoading = false;
      this.adjustmentPaymentDTO = [];
      this.adjustmentPaymentDTO = claimData.lstAdjustPayments;
      setTimeout(() => {
        this.isAdjLoading = true; // Enable the checkbox
      }, 100);
    }
  }

  deleteItem(claimData: any) {
    Swal.fire({
      title: 'Are you sure to delete Item Payment?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        // if (confirm('Are you sure to delete Item Payment?')) {
        this.isAddItemPaymentTableLoaded = false;
        try {
          if (this.deleteItemAPICall) {
            this.deleteItemAPICall.unsubscribe();
          }
          this.deleteItemAPICall = this.itemPaymentService
            .delete(claimData.id)
            .subscribe(
              (response) => {
                this.isSavedOrUpdated = true;

                const index = this.AddItemPaymentTableData.findIndex(
                  (obj) => obj.id === claimData.id
                );
                if (index !== -1) {
                  const objectToMove = this.AddItemPaymentTableData.splice(
                    index,
                    1
                  );
                }
                this.onCheckboxChange();
                this.toastr.success('Item Deleted successfully', 'Success');
                setTimeout(() => {
                  this.isAddItemPaymentTableLoaded = true; // Enable the checkbox
                }, 100);
              },
              (error) => {
                console.error('Error:', error);
                setTimeout(() => {
                  this.isAddItemPaymentTableLoaded = true; // Enable the checkbox
                }, 100);
              }
            );
        } catch (error) {
          // this.reloadTable.emit('Mani');
          console.error('Unexpected error:', error);
          setTimeout(() => {
            this.isAddItemPaymentTableLoaded = true; // Enable the checkbox
          }, 100);
        } finally {
        }
      } else {
      }
    });
  }

  fileUpload() {
    this.isPdfLoad = false;
    try {
      if (this.fileUploadAPICall) {
        this.fileUploadAPICall.unsubscribe();
      }
      this.fileUploadAPICall = this.itemPaymentService
        .uploadClaimEOBByEobFileAndGClaimId(
          this.newFilesForUpload,
          this.AddItemForm.value.sltClaimId
        )
        .subscribe(
          (response) => {
            this.isSavedOrUpdated = true;
            if (response['data']) {
              this.DefaultLastUploadBlobName = response['data'];
              this.DefaultLastUploadBlobFileName = response['fileName'];
            }
            this.saveAddItemPaymentAfterFileUpload();
            // this.toastr.success(response['message'], 'Success');
            setTimeout(() => {
              this.isPdfLoad = true;
            }, 100);
          },
          (error) => {
            console.error('Error:', error);
            this.DefaultLastUploadBlobName = null;
            this.DefaultLastUploadBlobFileName = null;
            this.saveAddItemPaymentAfterFileUpload();
            setTimeout(() => {
              this.isPdfLoad = true;
            }, 100);
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isPdfLoad = true;
      }, 100);
    }
  }

  onFileChange(event: any): void {
    // const files: File = event?.target?.files;

    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file) {
      this.eobBlobName = file.name;
      this.newFilesForUpload = file;
    } else {
      this.eobBlobName = null;
      this.newFilesForUpload = null;
    }
    // Check if a file was selected and if it is a valid PDF
    if (
      file &&
      (file.type === 'application/pdf' ||
        file.name.toLowerCase().endsWith('.pdf'))
    ) {
      this.isPdfLoad = false; // Hide the PDF viewer initially

      this.getPDFPageCount(file)
        .then((pageCount) => {
          if (pageCount > 0) {
            const reader = new FileReader();
            reader.onload = () => {
              const base64String = btoa(reader.result as string); // Encode using btoa()
              this.strPdfString = base64String;
              setTimeout(() => (this.isPdfLoad = true), 500);
              target.value = '';
              this.userManulUpload = true;
            };
            reader.readAsBinaryString(file); // Read as binary string for proper encoding
            // this.fileUpload();
          } else {
            this.toastr.warning(
              'Error loading PDF file. Please try again.',
              'Warning'
            );
            this.strPdfString = null;
            this.eobBlobName = null;
            this.newFilesForUpload = null;
            target.value = ''; // Clear the file input value
            setTimeout(() => (this.isPdfLoad = true), 500);
          }
        })
        .catch((error) => {
          console.warn(error);
          this.toastr.warning(
            'Error loading PDF file. Please try again.',
            'Warning'
          );
          target.value = ''; // Clear the file input value
          setTimeout(() => (this.isPdfLoad = true), 500);
        });
    } else {
      this.toastr.warning(
        'Invalid file format. Please select a valid PDF file.',
        'Warning'
      );
      // Optionally clear the file input value to reset it
      target.value = '';
    }
  }

  async getPDFPageCount(file: File): Promise<number> {
    try {
      const pdfBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes, {
        ignoreEncryption: true,
      });
      const pages = pdfDoc.getPages();
      return pages.length;
    } catch (error) {
      console.warn(error);
      return 0; // Return a default value or handle the error as per your requirement
    }
  }

  onCloseClick() {
    this.EoBDialogRef.close(this.isSavedOrUpdated);
  }

  onCheckboxChangeForAllSettled() {
    // this.settledClaimIds = this.AddItemPaymentTableData.map(item => item.id);
    if (this.isAllCCSettled) {
      this.settledClaimIds = this.AddItemPaymentTableData.filter(
        (item) => item.isCCSettled !== true
      ) // Filter items where isCCSettled is not true
        .map((item) => {
          item.isCheckedForCC = true; // Set isCheckedForCC to true for the filtered items
          return item.id; // Return the id of the filtered items
        });
    } else {
      this.AddItemPaymentTableData.map((item) => {
        item.isCheckedForCC = false;
      });
      this.settledClaimIds = [];
    }
  }

  onCheckboxChange() {
    let allCCNotDone = this.AddItemPaymentTableData.filter(
      (item) => item.isCCSettled !== true
    ).map((item) => item.id);

    this.settledClaimIds = [];
    this.settledClaimIds = this.AddItemPaymentTableData.filter(
      (item) => item.isCheckedForCC === true && item.isCCSettled !== true
    ).map((item) => item.id);
    this.isAllCCSettled =
      this.settledClaimIds.length === allCCNotDone.length &&
      this.settledClaimIds.length > 0
        ? true
        : false;
  }

  btnClick() {
    this.isUpdateCCSetItemPayment = true;
    try {
      if (this.isUpdateCCSetItemPaymentAPICall) {
        this.isUpdateCCSetItemPaymentAPICall.unsubscribe();
      }
      this.isUpdateCCSetItemPaymentAPICall = this.cCSettlementService
        .updateCCSettlementFlagByLstClaimIdV1(this.settledClaimIds)
        .subscribe(
          (response) => {
            this.isSavedOrUpdated = true;
            this.AddItemPaymentTableData.forEach((item) => {
              if (this.settledClaimIds.includes(item.id)) {
                item.isCCSettled = true;
              }
            });
            this.settledClaimIds = [];
            this.isAllCCSettled = false;
            this.toastr.success('CC Settled successfully', 'Success');
            setTimeout(() => {
              this.isUpdateCCSetItemPayment = false;
            }, 100);
          },
          (error) => {
            const data: HttpErrorResponse = error;
            const modifiedError = JSON.parse(data?.error);
            Swal.fire({
              text: modifiedError?.error?.message,
              icon: 'warning',
              confirmButtonText: 'OK',
            });
            // this.toastr.error(modifiedError?.error?.message);
            setTimeout(() => {
              this.isUpdateCCSetItemPayment = false;
            }, 100);
          }
        );
    } catch (error) {
      // this.reloadTable.emit('Mani');
      // this.toastr.error('Unexpected error:', error.message);
      Swal.fire({
        title: 'Unexpected Error',
        text: error.message,
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      setTimeout(() => {
        this.isUpdateCCSetItemPayment = false;
      }, 100);
    } finally {
    }
  }

  //Function To Find Allowed Input Field is Zero or Greater than Zero
  onKeyPressToFindisZero(isKey: number) {
    const allowedInputValue = this.AddItemForm.value.txtAllowed;

    if (isKey === 3) {
      this.isZeroAllowed = false;
      // this.clearPaymentFields();
    } else {
      if (allowedInputValue < 1 && allowedInputValue !== '') {
        this.isZeroAllowed = true;
        this.setPaymentFieldsToZero();
      } else {
        this.isZeroAllowed = false;
        if (isKey === 4) {
          // this.clearPaymentFields();
        }
      }
    }
  }
  private clearPaymentFields() {
    this.AddItemForm.get('txtPaid').setValue('');
    this.AddItemForm.get('txtCoins').setValue('');
  }

  private setPaymentFieldsToZero() {
    this.AddItemForm.get('txtPaid').setValue('0');
    this.AddItemForm.get('txtCoins').setValue('0');
  }

  isExistingChk: boolean = false;
  statusChange(event: MatSlideToggleChange) {
    // this.statusChange({ checked: true } as MatSlideToggleChange);
    if (event.checked == true) {
      this.AddItemForm.patchValue({
        txtCheckAmount : '',
        txtCheckno : '',
      });
      this.strPdfString = null;
      this.FileForm?.reset();
      this.isExistingChk = true;
    } else {
      this.isExistingChk = false;
    }
  }

  //#region GET Check EFT Drop Down
  getDrpCheckEFT(checkNumber: any): void {
    this.ischeckEFTLoading = true;
    if (this.checkEFTdrpApiSubscription) {
      this.checkEFTdrpApiSubscription.unsubscribe();
    }
    this.checkEFTdrpApiSubscription = this.eobHistoryService
      .getClaimChecksBySCheckNumber(checkNumber)
      .subscribe(
        (response) => {
          this.ischeckEFTLoading = false;
          this.checkEFTDrpData = response?.items;
          this.openCheckEFTDrp = [...this.checkEFTDrpData];
          // this.drpPatientName = this.checkEFTDrpData;
          this.checkEFTDrpData = this.checkEFTDrpData.sort((a, b) =>
            a.checkNumber.localeCompare(b.checkNumber)
          );
        },
        (err) => {
          this.ischeckEFTLoading = false;
        }
      );
  }
  getDrpCheckEFTForSearch(checkNumber: any): void {
    this.ischeckEFTLoading = true;
    if (this.checkEFTdrpApiSubscription) {
      this.checkEFTdrpApiSubscription.unsubscribe();
    }
    this.checkEFTdrpApiSubscription = this.eobHistoryService
      .getClaimChecksBySCheckNumber(checkNumber)
      .subscribe(
        (response) => {
          this.ischeckEFTLoading = false;
          this.checkEFTDrpData = response?.items;
          this.openCheckEFTDrp = [...this.checkEFTDrpData];
          this.checkEFTDrpData = this.checkEFTDrpData.sort((a, b) =>
            a.checkNumber.localeCompare(b.checkNumber)
          );
          // this.AddItemForm.patchValue({
          //   txtCheckno : response?.items[0]?.checkNumber,
          //   txtCheckAmount : response?.items[0]?.checkAmount
          // });

        },
        (err) => {
          this.ischeckEFTLoading = false;
        }
      );
  }
  //Search Check EFT Drp down
  searchCheckEFT(searchCheckNumber: any) {
    this.getDrpCheckEFT(searchCheckNumber);

    // if (this.checkEFTdrpApiSubscription) {
    //   this.checkEFTdrpApiSubscription.unsubscribe();
    //   this.ischeckEFTLoading = false;
    // }
    // this.ischeckEFTLoading = true;
    // this.checkEFTdrpApiSubscription = this.eobHistoryService
    //   .getClaimChecksBySCheckNumber(
    //     searchCheckNumber
    //   )
    //   .subscribe(
    //     (response) => {
    //       this.ischeckEFTLoading = false;
    //       this.checkEFTDrpData = response?.items;
    //       // this.drpPatientName = this.patientDrpData;
    //     },
    //     (err) => { }
    //   );
  }

  isCheckEFTDropdownOpen = false;
  onCheckEFTDropdownOpenChange(isOpen: boolean) {
    this.isCheckEFTDropdownOpen = isOpen;
    if (this.isCheckEFTDropdownOpen) {
      this.getDrpCheckEFT('');
    }

    // this.isCheckEFTDropdownOpen = isOpen;
    // if (this.isCheckEFTDropdownOpen) {
    //   this.AddItemForm.patchValue({
    //     slctCheckEFTFilter: ''
    //   });
    //   this.checkEFTDrpData = [...this.openCheckEFTDrp];
    // }
  }
  // On Change CheckEFTDrpData to Load Pdf Check
  onCheckEFtChange(event: MatSelectChange): void {

    const filteredCheckNumber = this.checkEFTDrpData.filter(
      (check) =>
        check.blobName === event?.value
    );
    this.AddItemForm.patchValue({
      txtCheckno : filteredCheckNumber?.[0]?.checkNumber || '',
      txtCheckAmount : filteredCheckNumber?.[0]?.checkAmount || ''
    });
    this.DefaultLastUploadBlobFileName = filteredCheckNumber?.[0]?.fileName;
    this.loadViewEOBPDF(event?.value);
    this.DefaultLastUploadBlobName=event?.value;
  }
  //#endregion GET Check EFT Drop Down

  // check() {
  //   console.log('this.isSaveAddItemPayment =' + this.isSaveAddItemPayment);
  //   console.log('this.isUpdateAddItemPayment = ' + this.isUpdateAddItemPayment);
  //   console.log('this.AddItemForm.invalid =' + this.AddItemForm.invalid);
  //   console.log(
  //     'this.isPatientResponsibility =' + this.isPatientResponsibility
  //   );
  //   console.log(
  //     'this.adjustmentPaymentDTO.length =' + this.adjustmentPaymentDTO.length
  //   );
  //   console.log('this.FileForm.invalid =' + this.FileForm.invalid);
  //   console.log(
  //     'this.isEmpty(this.strPdfString) =' + this.isEmpty(this.strPdfString)
  //   );
  //   console.log('!this.isPdfLoad =' + !this.isPdfLoad);
  // }
}
