<!-- Fax Process TAB-->
<div *ngIf="activeTab === 'FaxProcess'">
  <div class="table table-responsive" *ngIf="!isLoading">
    <table
      datatable
      class="row-border hover w-100 display"
      [dtOptions]="myVerification"
    >
      <thead>
        <tr>
          <th><mat-icon> wrap_text</mat-icon></th>
          <th><mat-icon>apps</mat-icon></th>
          <th [matTooltip]="'Chart No'">Chart No</th>
          <th [matTooltip]="'Name'">Name</th>
          <th [matTooltip]="'State'">State</th>
          <th [matTooltip]="'Entered By'">Entered By</th>
          <th [matTooltip]="'Location'">Location</th>
          <th [matTooltip]="'Rank'">Rank</th>
          <th [matTooltip]="'Insurance'">Insurance</th>
          <th [matTooltip]="'Locked By'">Locked By</th>
          <th [matTooltip]="'Locked At'" class="text-center cus-column-width">Locked At</th>
          <th [matTooltip]="'Days'" class="text-right">Days</th>
          <th [matTooltip]="'Entered On'" class="text-center">Entered On</th>
          <th class="text-center cus-column-width" [matTooltip]="'Created Date'">Created Date</th>
          <th [matTooltip]="'Created By'">Created By</th>
          <th [matTooltip]="'Modify By'">Modify By</th>
          <th class="text-right right" [matTooltip]="'Modified Date'">
            Modified Date
          </th>
          <th [matTooltip]="'Status'">Status</th>
          <th [matTooltip]="'STAT'">STAT</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of DataList; let i = index"
          [ngClass]="{
            stat_highlight: data.isStat === 1 || data.successfulStatUpdate
          }"
        >
          <td
            class="dt-control"
            (click)="handleTableCellClick($event, data.notes)"
          ></td>
          <td>
            <!-- [ngClass]="{'disabled-link': data?.lockedStatus || data?.islockstatusCheck}" -->
            <mat-icon
              style="cursor: pointer !important"
              [matMenuTriggerFor]="menu"
              >more_vert</mat-icon
            >
            <mat-checkbox
              *ngIf="data.lockedStatus !== 1 && data.lockedStatus !== 2"
              [disabled]="disableLockCheckbox"
              (change)="addRemoveChk($event, data)"
            ></mat-checkbox>
            <mat-checkbox
              *ngIf="data.lockedStatus === 1"
              checked="true"
              [disabled]="disableLockCheckbox"
              (change)="addRemoveChk($event, data)"
            ></mat-checkbox>
            <mat-icon *ngIf="data.lockedStatus === 2" style="color: red"
              >lock</mat-icon
            >
            <mat-menu class="custom-mat-menu" #menu="matMenu">
              <button
                mat-menu-item
                [disabled]="
                  data.verificationStatus !== 'New' &&
                  data.verificationStatus !== 'new'
                "
                (click)="EditPolicy(data)"
                [ngClass]="{
                  'disabled-link':
                    data?.lockedStatus === 0 ||
                    data?.lockedStatus === 2 ||
                    data?.islockstatusCheck === false
                }"
              >
                Do Verification
              </button>
              <button
                mat-menu-item
                [disabled]="
                  data.verificationStatus === 'New' ||
                  data.verificationStatus === 'new'
                "
                (click)="viewVerificationVerifyBinById(data)"
                [ngClass]="{
                  'disabled-link':
                    data?.lockedStatus === 0 ||
                    data?.lockedStatus === 2 ||
                    data?.islockstatusCheck === false
                }"
              >
                View Verification
              </button>

              <!-- <button mat-menu-item [disabled]="true" (click)="EditPolicy(data)">Do Verification</button>
          <button mat-menu-item [disabled]="true" (click)="viewVerificationVerifyBinById(data)">View Verification</button> -->
              <button mat-menu-item (click)="openAddNotesPopUp($event, data)">
                Add Note
              </button>
              <button mat-menu-item (click)="openViewNotesPopup(data)">
                View Notes
              </button>
              <a
                [class.disabled]="
                  isEmpty(data.documentId) || data?.isSorted === false
                "
                [routerLink]="
                  '/view_document_tiff/' + data.documentId + '/' + true
                "
                target="_blank"
                class="preview-link"
              >
                <button
                  [disabled]="
                    isEmpty(data.documentId) || data?.isSorted === false
                  "
                  mat-menu-item
                >
                  View Document Images
                </button>
              </a>
              <button mat-menu-item (click)="viewPatientLedger(data)">
                View Patient Ledger
              </button>
              <!-- <button mat-menu-item >Add Fax Process Note</button> -->
              <button
                mat-menu-item
                *ngIf="
                  data.verificationStatus !== 'pending' &&
                  data.verificationStatus !== 'Pending'
                "
                (click)="isStatusUpdate(data.verificationId)"
                [disabled]="true"
              >
                Set Pending
              </button>
              <button
                mat-menu-item
                *ngIf="
                  data.verificationStatus === 'pending' ||
                  data.verificationStatus === 'Pending'
                "
                (click)="isStatusUpdate(data.verificationId)"
                [disabled]="true"
              >
                Clear Pending
              </button>
              <button
                mat-menu-item
                [disabled]="
                  data?.isExpired === 1 ||
                  (data?.verificationStatus !== lstVerificationSttaus.new &&
                    data?.verificationStatus !== lstVerificationSttausV1.new &&
                    data?.verificationStatus !==
                      lstVerificationSttaus.pending &&
                    data?.verificationStatus !==
                      lstVerificationSttausV1.pending)
                "
                (click)="cancelVerificationStatus(data?.verificationId)"
              >
                Cancel Verification
              </button>
            </mat-menu>
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.chartNo || '-')"
            [title]="data?.chartNo"
          >
            <a
              [routerLink]="
                '/patientCreateEditTab/' + data?.patientId + '/' + data?.docId
              "
              target="_blank"
              [matTooltip]="data?.chartNo"
              class="preview-link"
            >
              {{
                data?.chartNo === null || data?.chartNo === ""
                  ? "-"
                  : data?.chartNo
              }}
            </a>
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.patientName || '-')"
            [matTooltip]="data?.patientName"
          >
            {{
              data?.patientName === null || data?.patientName === ""
                ? "-"
                : data?.patientName
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.state || '-')"
            [matTooltip]="data?.state"
          >
            {{ data?.state === null || data?.state === "" ? "-" : data.state }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.enteredBy || '-')"
            [matTooltip]="data?.enteredBy"
          >
            {{ data?.enteredBy }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.location || '-')"
            [matTooltip]="data?.location"
          >
            {{
              data?.location === null || data?.location === ""
                ? "-"
                : data.location
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.plan || '-')"
            [matTooltip]="data?.plan"
          >
            <span *ngIf="data?.plan === insuranceType.primary">Primary</span>
            <span *ngIf="data?.plan === insuranceType.secondary"
              >Secondary</span
            >
            <span *ngIf="data?.plan === insuranceType.tertiary">Tertiary</span>
            <span *ngIf="data?.plan === insuranceType.Patient_Response"
              >Patient_Response</span
            >
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.payerName || '-')"
            [matTooltip]="data?.payerName"
          >
            {{
              data?.payerName === null || data?.payerName === ""
                ? "-"
                : data?.payerName
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.lockedBy || '-')"
            [matTooltip]="data?.lockedBy"
          >
            {{
              data?.lockedBy === null || data?.lockedBy === ""
                ? "-"
                : data?.lockedBy
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.lockedAt || '-')"
            [matTooltip]="data?.lockedAt || '-'"
            class="text-center cus-column-width"
          >
            {{ data?.lockedAt || "-" }}
          </td>
          <td class="text-right" (dblclick)="onRowDoubleClickV1(data.days)">
            {{ data.days }}
          </td>
          <!-- <td (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.enteredOn))"  [matTooltip]="getFormattedDate(data?.enteredOn)" class="text-center"> {{
        (data?.enteredOn !==null || data?.enteredOn !=='')
          ? (data?.enteredOn | date : "MM/dd/yyyy" : "en_US")
          : "-"}}</td> -->
          <td
            (dblclick)="onRowDoubleClickV1(data?.enteredOn || '-')"
            [matTooltip]="data?.enteredOn || '-'"
            class="text-center"
          >
            {{ data?.enteredOn || "-" }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.createdDate || '-')"
            [matTooltip]="data?.createdDate || '-'"
            class="text-center cus-column-width"
          >
            {{ data?.createdDate || "-" }}
          </td>
          <td
            [matTooltip]="data?.createdBy || '-'"
            (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')"
          >
            {{ data?.createdBy || "-" }}
          </td>

          <td class=""
            [matTooltip]="data?.modifiedBy || '-'"
            (dblclick)="onRowDoubleClickV1(data?.modifiedBy || '-')"
          >
            {{ data?.modifiedBy || "-" }}
          </td>

          <td
            class="text-center cus-column-width"
            [matTooltip]="data?.modifiedDate || '-'"
            (dblclick)="onRowDoubleClickV1(data?.modifiedDate || '-')"
          >
            {{ data?.modifiedDate || "-" }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.verificationStatus)"
            [matTooltip]="data?.verificationStatus"
          >
            <span
              *ngIf="
                data?.verificationStatus === lstVerificationSttaus.done ||
                data?.verificationStatus === lstVerificationSttausV1.done
              "
              >Done</span
            >
            <span
              *ngIf="
                data?.verificationStatus === lstVerificationSttaus.pending ||
                data?.verificationStatus === lstVerificationSttausV1.pending
              "
              >Pending</span
            >
            <span
              *ngIf="
                data?.verificationStatus ===
                  lstVerificationSttaus.unabletoverify ||
                data?.verificationStatus ===
                  lstVerificationSttausV1.unabletoverify
              "
              >Unable To Verify</span
            >
            <span
              *ngIf="
                data?.verificationStatus === lstVerificationSttaus.new ||
                data?.verificationStatus === lstVerificationSttausV1.new
              "
              >New</span
            >
            <span
              class="text-red"
              *ngIf="
                data?.verificationStatus !== lstVerificationSttaus.new &&
                data?.verificationStatus !== lstVerificationSttausV1.new &&
                data?.verificationStatus !==
                  lstVerificationSttaus.unabletoverify &&
                data?.verificationStatus !==
                  lstVerificationSttausV1.unabletoverify &&
                data?.verificationStatus !== lstVerificationSttaus.pending &&
                data?.verificationStatus !== lstVerificationSttausV1.pending &&
                data?.verificationStatus !== lstVerificationSttaus.done &&
                data?.verificationStatus !== lstVerificationSttausV1.done
              "
              >{{ data?.verificationStatus }}</span
            >
          </td>
          <td>
            <mat-checkbox
              [(ngModel)]="data.isStat"
              (change)="isStatUpdate($event, data)"
              [disabled]="disableSTATCheckbox"
            >
              <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span>
            </mat-checkbox>
          </td>
        </tr>
      </tbody>
      <!-- Footer -->
      <tfoot>
        <tr>
          <th><mat-icon> wrap_text</mat-icon></th>
          <th><mat-icon>apps</mat-icon></th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Chart No"
              name="search-ChartNo"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Name"
              name="search-Name"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="State"
              name="search-state"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Entered By"
              name="search-EnteredBy"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Location"
              name="search-Location"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Rank"
              name="search-Rank"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Insurance"
              name="search-Insurance"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Locked By"
              name="search-LockedBy"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Locked At"
              name="search-LockedAt"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class text-right w-100"
              type="text"
              placeholder="Days"
              name="search-Days"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Entered On"
              name="search-EnteredOn"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Created Date"
              name="search-CreatedDate"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Created By"
              name="search-Created By"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Modify By"
              name="search-Modify By"
            />
          </th>
          <th class="text-right">
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Modified Date"
              name="search-Modified Date"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Status"
              name="search-Status"
            />
          </th>
          <th><mat-icon>no_encryption</mat-icon></th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
<!-- On-Demand TAB-->
<div *ngIf="activeTab === 'On-Demand'">
  <div class="table table-responsive" *ngIf="!isLoading">
    <table
      datatable
      class="row-border hover w-100 display"
      [dtOptions]="myVerification"
    >
      <thead>
        <tr>
          <th><mat-icon> wrap_text</mat-icon></th>
          <th><mat-icon>apps</mat-icon></th>
          <th [matTooltip]="'Chart No'">Chart No</th>
          <th [matTooltip]="'Name'">Name</th>
          <th [matTooltip]="'State'">State</th>
          <th [matTooltip]="'Entered By'">Entered By</th>
          <th [matTooltip]="'Location'">Location</th>
          <th [matTooltip]="'Rank'">Rank</th>
          <th [matTooltip]="'Insurance'">Insurance</th>
          <th [matTooltip]="'Locked By'">Locked By</th>
          <th [matTooltip]="'Locked At'" class="text-center cus-column-width">Locked At</th>
          <th [matTooltip]="'Days'" class="text-right">Days</th>
          <th [matTooltip]="'Entered On'" class="text-center">Entered On</th>
          <th class="text-center cus-column-width" [matTooltip]="'Created Date'">Created Date</th>
          <th [matTooltip]="'Created By'">Created By</th>
          <th [matTooltip]="'Modify By'">Modify By</th>
          <th class="text-center cus-column-width" [matTooltip]="'Modified Date'">
            Modified Date
          </th>
          <th [matTooltip]="'Status'">Status</th>
          <th [matTooltip]="'STAT'">STAT</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of DataList; let i = index"
          [ngClass]="{
            stat_highlight: data.isStat === 1 || data.successfulStatUpdate
          }"
        >
          <td
            class="dt-control"
            (click)="handleTableCellClick($event, data.notes)"
          ></td>
          <td>
            <mat-icon
              style="cursor: pointer !important"
              [matMenuTriggerFor]="menu"
              >more_vert</mat-icon
            >
            <mat-checkbox
              *ngIf="data.lockedStatus !== 1 && data.lockedStatus !== 2"
              [disabled]="disableLockCheckbox"
              (change)="addRemoveChk($event, data)"
            ></mat-checkbox>
            <mat-checkbox
              *ngIf="data.lockedStatus === 1"
              checked="true"
              [disabled]="disableLockCheckbox"
              (change)="addRemoveChk($event, data)"
            ></mat-checkbox>
            <mat-icon *ngIf="data.lockedStatus === 2" style="color: red"
              >lock</mat-icon
            >
            <mat-menu class="custom-mat-menu" #menu="matMenu">
              <button
                mat-menu-item
                [disabled]="
                  data.verificationStatus !== 'New' &&
                  data.verificationStatus !== 'new'
                "
                (click)="EditPolicy(data)"
                [ngClass]="{
                  'disabled-link':
                    data?.lockedStatus === 0 ||
                    data?.lockedStatus === 2 ||
                    data?.islockstatusCheck === false
                }"
              >
                Do Verification
              </button>
              <button
                mat-menu-item
                [disabled]="
                  data.verificationStatus === 'New' ||
                  data.verificationStatus === 'new'
                "
                (click)="viewVerificationVerifyBinById(data)"
                [ngClass]="{
                  'disabled-link':
                    data?.lockedStatus === 0 ||
                    data?.lockedStatus === 2 ||
                    data?.islockstatusCheck === false
                }"
              >
                View Verification
              </button>

              <!-- <button mat-menu-item [disabled]="true"(click)="EditPolicy(data)">Do Verification</button>
          <button mat-menu-item [disabled]="true"(click)="viewVerificationVerifyBinById(data)">View Verification</button> -->
              <button mat-menu-item (click)="openAddNotesPopUp($event, data)">
                Add Note
              </button>
              <button mat-menu-item (click)="openViewNotesPopup(data)">
                View Notes
              </button>
              <a
                [class.disabled]="
                  isEmpty(data.documentId) || data?.isSorted === false
                "
                [routerLink]="
                  '/view_document_tiff/' + data.documentId + '/' + true
                "
                target="_blank"
                class="preview-link"
              >
                <button
                  [disabled]="
                    isEmpty(data.documentId) || data?.isSorted === false
                  "
                  mat-menu-item
                >
                  View Document Images
                </button>
              </a>
              <button mat-menu-item (click)="viewPatientLedger(data)">
                View Patient Ledger
              </button>
              <button
                mat-menu-item
                *ngIf="
                  data.verificationStatus !== 'pending' &&
                  data.verificationStatus !== 'Pending'
                "
                (click)="isStatusUpdate(data.verificationId)"
                [disabled]="true"
              >
                Set Pending
              </button>
              <button
                mat-menu-item
                *ngIf="
                  data.verificationStatus === 'pending' ||
                  data.verificationStatus === 'Pending'
                "
                (click)="isStatusUpdate(data.verificationId)"
                [disabled]="true"
              >
                Clear Pending
              </button>
              <button
                mat-menu-item
                [disabled]="
                  data?.isExpired === 1 ||
                  (data?.verificationStatus !== lstVerificationSttaus.new &&
                    data?.verificationStatus !== lstVerificationSttausV1.new &&
                    data?.verificationStatus !==
                      lstVerificationSttaus.pending &&
                    data?.verificationStatus !==
                      lstVerificationSttausV1.pending)
                "
                (click)="cancelVerificationStatus(data?.verificationId)"
              >
                Cancel Verification
              </button>
            </mat-menu>
          </td>
          <td class="text-center" [title]="data?.chartNo">
            <a
              [routerLink]="
                '/patientCreateEditTab/' + data?.patientId + '/' + data?.docId
              "
              target="_blank"
              (dblclick)="onRowDoubleClickV1(data?.chartNo)"
              [matTooltip]="data?.chartNo"
              class="preview-link"
            >
              {{
                data?.chartNo === null || data?.chartNo === ""
                  ? "-"
                  : data?.chartNo
              }}
            </a>
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.patientName || '-')"
            [matTooltip]="data?.patientName"
          >
            {{
              data?.patientName === null || data?.patientName === ""
                ? "-"
                : data?.patientName
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.state || '-')"
            [matTooltip]="data?.state"
          >
            {{ data?.state === null || data?.state === "" ? "-" : data?.state }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.enteredBy || '-')"
            [matTooltip]="data?.enteredBy"
          >
            {{ data?.enteredBy }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.location || '-')"
            [matTooltip]="data?.location"
          >
            {{
              data?.location === null || data?.location === ""
                ? "-"
                : data?.location
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.plan || '-')"
            [matTooltip]="data?.plan"
          >
            <span *ngIf="data?.plan === insuranceType.primary">Primary</span>
            <span *ngIf="data?.plan === insuranceType.secondary"
              >Secondary</span
            >
            <span *ngIf="data?.plan === insuranceType.tertiary">Tertiary</span>
            <span *ngIf="data?.plan === insuranceType.Patient_Response"
              >Patient_Response</span
            >
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.payerName || '-')"
            [matTooltip]="data?.payerName"
          >
            {{
              data?.payerName === null || data?.payerName === ""
                ? "-"
                : data?.payerName
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.lockedBy || '-')"
            [matTooltip]="data?.lockedBy"
          >
            {{
              data?.lockedBy === null || data?.lockedBy === ""
                ? "-"
                : data?.lockedBy
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.lockedAt || '-')"
            [matTooltip]="data?.lockedAt || '-'"
            class="text-center cus-column-width"
          >
            {{ data?.lockedAt || "-" }}
          </td>
          <td
            class="text-right"
            (dblclick)="onRowDoubleClickV1(data?.days || '-')"
            [matTooltip]="data?.days"
          >
            {{ data.days }}
          </td>

          <td
            (dblclick)="onRowDoubleClickV1(data?.enteredOn || '-')"
            [matTooltip]="data?.enteredOn || '-'"
            class="text-center"
          >
            {{ data?.enteredOn || "-" }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.createdDate || '-')"
            [matTooltip]="data?.createdDate || '-'"
            class="text-center cus-column-width"
          >
            {{ data?.createdDate || "-" }}
          </td>
          <td
            [matTooltip]="data?.createdBy || '-'"
            (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')"
          >
            {{ data?.createdBy || "-" }}
          </td>

          <td
            [matTooltip]="data?.modifiedBy || '-'"
            (dblclick)="onRowDoubleClickV1(data?.modifiedBy || '-')"
          >
            {{ data?.modifiedBy || "-" }}
          </td>

          <td
            class="text-center cus-column-width"
            [matTooltip]="data?.modifiedDate || '-'"
            (dblclick)="onRowDoubleClickV1(data?.modifiedDate || '-')"
          >
            {{ data?.modifiedDate || "-" }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.verificationStatus)"
            [matTooltip]="data?.verificationStatus"
          >
            <span
              *ngIf="data?.verificationStatus === lstVerificationSttaus.done"
              >Done</span
            >
            <span
              *ngIf="data?.verificationStatus === lstVerificationSttaus.pending"
              >Pending</span
            >
            <span
              *ngIf="
                data?.verificationStatus ===
                lstVerificationSttaus.unabletoverify
              "
              >Unable To Verify</span
            >
            <span *ngIf="data?.verificationStatus === lstVerificationSttaus.new"
              >New</span
            >
            <span
              class="text-red"
              *ngIf="
                data?.verificationStatus !== lstVerificationSttaus.new &&
                data?.verificationStatus !== lstVerificationSttausV1.new &&
                data?.verificationStatus !==
                  lstVerificationSttaus.unabletoverify &&
                data?.verificationStatus !==
                  lstVerificationSttausV1.unabletoverify &&
                data?.verificationStatus !== lstVerificationSttaus.pending &&
                data?.verificationStatus !== lstVerificationSttausV1.pending &&
                data?.verificationStatus !== lstVerificationSttaus.done &&
                data?.verificationStatus !== lstVerificationSttausV1.done
              "
              >{{ data?.verificationStatus }}</span
            >
          </td>
          <td>
            <mat-checkbox
              [(ngModel)]="data.isStat"
              (change)="isStatUpdate($event, data)"
              [disabled]="disableSTATCheckbox"
            >
              <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span>
            </mat-checkbox>
          </td>
        </tr>
      </tbody>
      <!-- Footer -->
      <tfoot>
        <tr>
          <th><mat-icon> wrap_text</mat-icon></th>
          <th><mat-icon>apps</mat-icon></th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Chart No"
              name="search-ChartNo"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Name"
              name="search-Name"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="State"
              name="search-state"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Entered By"
              name="search-EnteredBy"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Location"
              name="search-Location"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Rank"
              name="search-Rank"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Insurance"
              name="search-Insurance"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Locked By"
              name="search-LockedBy"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Locked At"
              name="search-LockedAt"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class text-right w-100"
              type="text"
              placeholder="Days"
              name="search-Days"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Entered On"
              name="search-EnteredOn"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Created Date"
              name="search-CreatedDate"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Created By"
              name="search-Created By"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Modify By"
              name="search-Modify By"
            />
          </th>
          <th class="text-right">
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Modified Date"
              name="search-Modified Date"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Status"
              name="search-Status"
            />
          </th>
          <th><mat-icon>no_encryption</mat-icon></th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
<!-- Authorization TAB-->
<div *ngIf="activeTab === 'Authorization'">
  <div class="table table-responsive" *ngIf="!isLoading">
    <table
      datatable
      class="row-border hover w-100 display"
      [dtOptions]="myVerification"
    >
      <thead>
        <tr>
          <th><mat-icon> wrap_text</mat-icon></th>
          <th><mat-icon>apps</mat-icon></th>
          <th [matTooltip]="'Chart No'">Chart No</th>
          <th [matTooltip]="'Name'">Name</th>
          <th [matTooltip]="'State'">State</th>
          <th [matTooltip]="'Entered By'">Entered By</th>
          <th [matTooltip]="'Location'">Location</th>
          <th [matTooltip]="'Rank'">Rank</th>
          <th [matTooltip]="'Insurance'">Insurance</th>
          <th [matTooltip]="'Locked By'">Locked By</th>
          <th [matTooltip]="'Locked At'" class="text-center cus-column-width">Locked At</th>
          <th [matTooltip]="'Days'" class="text-right">Days</th>
          <th [matTooltip]="'Entered On'" class="text-center">Entered On</th>
          <th class="text-center cus-column-width " [matTooltip]="'Created Date'">Created Date</th>
          <th [matTooltip]="'Created By'">Created By</th>
          <th [matTooltip]="'Modify By'">Modify By</th>
          <th class="text-center cus-column-width" [matTooltip]="'Modified Date'">
            Modified Date
          </th>
          <th [matTooltip]="'Status'">Status</th>
          <th [matTooltip]="'STAT'">STAT</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of DataList; let i = index"
          [ngClass]="{
            stat_highlight: data.isTAT === true || data.successfulStatUpdate
          }"
        >
          <td
            class="dt-control"
            (click)="handleTableCellClick($event, data.authorizationNotes)"
          ></td>
          <td>
            <mat-icon
              style="cursor: pointer !important"
              [matMenuTriggerFor]="menu"
              >more_vert</mat-icon
            >
            <mat-checkbox
              *ngIf="data.lockedStatus !== 1 && data.lockedStatus !== 2"
              [disabled]="disableLockCheckbox"
              (change)="addRemoveChk($event, data)"
            ></mat-checkbox>
            <mat-checkbox
              *ngIf="data.lockedStatus === 1"
              checked="true"
              [disabled]="disableLockCheckbox"
              (change)="addRemoveChk($event, data)"
            ></mat-checkbox>
            <mat-icon *ngIf="data.lockedStatus === 2" style="color: red"
              >lock</mat-icon
            >
            <mat-menu class="custom-mat-menu" #menu="matMenu">
              <button
                mat-menu-item
                [disabled]="
                  data.patientId === defaultGuid ||
                  data.patientId === null ||
                  data.patientId === '' ||
                  (data.status !== 'Yet to Start' &&
                    data.status !== null &&
                    data.status !== '')
                "
                (click)="doprecert(data)"
                [ngClass]="{
                  'disabled-link':
                    data?.lockedStatus === 0 ||
                    data?.lockedStatus === 2 ||
                    data?.islockstatusCheck === false
                }"
              >
                Do Authorization
              </button>
              <button
                mat-menu-item
                [disabled]="
                  data.patientId === defaultGuid ||
                  data.patientId === null ||
                  data.patientId === '' ||
                  data.status === 'Yet to Start' ||
                  data.status === null ||
                  data.status === ''
                "
                (click)="viewprecert(data)"
                [ngClass]="{
                  'disabled-link':
                    data?.lockedStatus === 0 ||
                    data?.lockedStatus === 2 ||
                    data?.islockstatusCheck === false
                }"
              >
                view Authorization
              </button>
              <button
                mat-menu-item
                [disabled]="
                  data.verificationStatus === 'New' ||
                  data.verificationStatus === 'new'
                "
                (click)="viewVerificationById(data)"
                [ngClass]="{
                  'disabled-link':
                    data?.lockedStatus === 0 ||
                    data?.lockedStatus === 2 ||
                    data?.islockstatusCheck === false
                }"
              >
                View Verification
              </button>
              <button mat-menu-item (click)="openAddAuthNotesPopUp(data)">
                Add Note
              </button>
              <button mat-menu-item (click)="openViewNotesPopupAuth(data)">
                View Notes
              </button>
              <a
                [class.disabled]="
                  isEmpty(data.docId) || data?.isSorted === false
                "
                [routerLink]="'/view_document_tiff/' + data.docId + '/' + true"
                target="_blank"
                class="preview-link"
              >
                <button
                  [disabled]="isEmpty(data.docId) || data?.isSorted === false"
                  mat-menu-item
                >
                  View Document Images
                </button>
              </a>
              <button mat-menu-item (click)="viewPatientLedger(data)">
                View Patient Ledger
              </button>
              <button
                mat-menu-item
                (click)="isStatusUpdateAuth(data.id)"
                [disabled]="true"
              >
                Clear Pending Status
              </button>
            </mat-menu>
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.chartId || '-')"
            [title]="data?.chartId"
          >
            <a
              [routerLink]="
                '/patientCreateEditTab/' + data?.patientId + '/' + data?.docId
              "
              target="_blank"
              [matTooltip]="data?.chartNo"
              class="preview-link"
            >
              {{
                data?.chartId === null || data?.chartId === ""
                  ? "-"
                  : data?.chartId
              }}
            </a>
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.fullName || '-')"
            [matTooltip]="data?.fullName"
          >
            {{
              data?.fullName === null || data?.fullName === ""
                ? "-"
                : data?.fullName
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.state || '-')"
            [matTooltip]="data?.state"
          >
            {{ data?.state === null || data?.state === "" ? "-" : data?.state }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.enterBy || '-')"
            [matTooltip]="data?.enterBy"
          >
            {{ data?.enterBy }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.locationName || '-')"
            [matTooltip]="data?.locationName"
          >
            {{
              data?.locationName === null || data?.locationName === ""
                ? "-"
                : data?.locationName
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.plan || '-')"
            [matTooltip]="data?.plan"
          >
            <span *ngIf="data?.plan === insuranceType.primary">Primary</span>
            <span *ngIf="data?.plan === insuranceType.secondary"
              >Secondary</span
            >
            <span *ngIf="data?.plan === insuranceType.tertiary">Tertiary</span>
            <span *ngIf="data?.plan === insuranceType.Patient_Response"
              >Patient_Response</span
            >
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.payorName || '-')"
            [matTooltip]="data?.payorName"
          >
            {{
              data?.payorName === null || data?.payorName === ""
                ? "-"
                : data?.payorName
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.lockedByName || '-')"
            [matTooltip]="data?.lockedByName"
          >
            {{
              data?.lockedByName === null || data?.lockedByName === ""
                ? "-"
                : data?.lockedByName
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.lockedAT || '-')"
            [matTooltip]="data?.lockedAT || '-'"
            class="text-center cus-column-width"
          >
            {{ data?.lockedAT || "-" }}
          </td>
          <td
            class="text-right"
            (dblclick)="onRowDoubleClickV1(data?.days || '-')"
            [matTooltip]="data?.days"
          >
            {{ data.days }}
          </td>

          <!-- <td (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.enterOn))"  [matTooltip]="getFormattedDate(data?.enterOn)" class="text-center"> {{
        (data?.enterOn !==null || data?.enterOn !=='')
          ? (data?.enterOn | date : "MM/dd/yyyy" : "en_US")
          : "-"}}</td>

          <td [matTooltip]="data?.modifyBy|| '-'"
          (dblclick)="onRowDoubleClickV1(data?.modifyBy || '-')">{{
          data?.modifyBy || '-' }}</td> -->

          <td
            (dblclick)="onRowDoubleClickV1(data?.enterOn || '-')"
            [matTooltip]="data?.enterOn || '-'"
            class="text-center"
          >
            {{ data?.enterOn || "-" }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.createdDate || '-')"
            [matTooltip]="data?.createdDate || '-'"
            class="text-center cus-column-width "
          >
            {{ data?.createdDate || "-" }}
          </td>
          <td
            [matTooltip]="data?.createdBy || '-'"
            (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')"
          >
            {{ data?.createdBy || "-" }}
          </td>

          <td
            [matTooltip]="data?.modifiedBy || '-'"
            (dblclick)="onRowDoubleClickV1(data?.modifiedBy || '-')"
          >
            {{ data?.modifiedBy || "-" }}
          </td>

          <td
            class="text-center cus-column-width"
            [matTooltip]="data?.modifiedDate || '-'"
            (dblclick)="onRowDoubleClickV1(data?.modifiedDate || '-')"
          >
            {{ data?.modifiedDate || "-" }}
          </td>

          <td
            (dblclick)="onRowDoubleClickV1(data?.status)"
            [matTooltip]="data?.status"
          >
            {{ data?.status }}
          </td>
          <td>
            <mat-checkbox
              [(ngModel)]="data.isTAT"
              (change)="isStatUpdateAuth($event, data)"
              [disabled]="disableSTATCheckbox"
            >
              <span [hidden]="true">{{ data.isTAT ? "True" : "False" }}</span>
            </mat-checkbox>
          </td>
        </tr>
      </tbody>
      <!-- Footer -->
      <tfoot>
        <tr>
          <th><mat-icon> wrap_text</mat-icon></th>
          <th><mat-icon>apps</mat-icon></th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Chart No"
              name="search-ChartNo"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Name"
              name="search-Name"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="State"
              name="search-state"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Entered By"
              name="search-EnteredBy"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Location"
              name="search-Location"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Rank"
              name="search-Rank"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Insurance"
              name="search-Insurance"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Locked By"
              name="search-LockedBy"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class text-center w-100"
              type="text"
              placeholder="Locked At"
              name="search-LockedAt"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class text-right w-100"
              type="text"
              placeholder="Days"
              name="search-Days"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class text-center w-100"
              type="text"
              placeholder="Entered On"
              name="search-EnteredOn"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class text-center w-100"
              type="text"
              placeholder="Created Date"
              name="search-CreatedDate"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Created By"
              name="search-Created By"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Modify By"
              name="search-Modify By"
            />
          </th>
          <th class="text-right">
            <input
              matInput
              class="textbox-border-class text-center w-100"
              type="text"
              placeholder="Modified Date"
              name="search-Modified Date"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Status"
              name="search-Status"
            />
          </th>
          <th><mat-icon>no_encryption</mat-icon></th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
<!-- VerificationIssues TAB-->
<div *ngIf="activeTab === 'VerificationIssues'">
  <div class="table table-responsive" *ngIf="!isLoading">
    <table
      datatable
      class="row-border hover w-100 display"
      [dtOptions]="myVerification"
    >
      <thead>
        <tr>
          <th><mat-icon> wrap_text</mat-icon></th>
          <th><mat-icon>apps</mat-icon></th>
          <th [matTooltip]="'Fax Id'">Fax Id</th>
          <th [matTooltip]="'Pages'" class="text-right">Pages</th>
          <th [matTooltip]="'Received'" class="text-center">Received</th>
          <th [matTooltip]="'Enter On'" class="text-center">Enter On</th>
          <th [matTooltip]="'Chart Id'">Chart Id</th>
          <th [matTooltip]="'Name'">Name</th>
          <th [matTooltip]="'Rank'">Rank</th>
          <th [matTooltip]="'Insurance'">Insurance</th>
          <th [matTooltip]="'Locked By'">Locked By</th>
          <th [matTooltip]="'Locked At'" class="text-center cus-column-width">Locked At</th>
          <th [matTooltip]="'Practice'">Practice</th>
          <th [matTooltip]="'provider Name'">provider Name</th>
          <th [matTooltip]="'Ins Group'">Ins Group</th>
          <th class="text-center cus-column-width" [matTooltip]="'Created Date'">Created Date</th>
          <th [matTooltip]="'Created By'">Created By</th>
          <th [matTooltip]="'Modified By'">Modified By</th>
          <th class="text-center cus-column-width" [matTooltip]="'Modified Date'">
            Modified Date
          </th>
          <th [matTooltip]="'Documents'">Documents</th>
          <th [matTooltip]="'CSR'">CSR</th>
          <th [matTooltip]="'Status'">Status</th>
          <th [matTooltip]="'STAT'">STAT</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of DataList; let i = index"
          [ngClass]="{
            stat_highlight: data.isStat === 1 || data.successfulStatUpdate
          }"
        >
          <td
            class="dt-control"
            (click)="handleTableCellClick($event, data.notes)"
          ></td>
          <td>
            <mat-icon
              style="cursor: pointer !important"
              [matMenuTriggerFor]="menu"
              >more_vert</mat-icon
            >
            <mat-checkbox
              *ngIf="data.lockedStatus !== 1 && data.lockedStatus !== 2"
              [disabled]="disableLockCheckbox"
              (change)="addRemoveChk($event, data)"
            ></mat-checkbox>
            <mat-checkbox
              *ngIf="data.lockedStatus === 1"
              checked="true"
              [disabled]="disableLockCheckbox"
              (change)="addRemoveChk($event, data)"
            ></mat-checkbox>
            <mat-icon *ngIf="data.lockedStatus === 2" style="color: red"
              >lock</mat-icon
            >
            <mat-menu class="custom-mat-menu" #menu="matMenu">
              <button
                mat-menu-item
                [disabled]="
                  data.verificationStatus === 'New' ||
                  data.verificationStatus === 'new'
                "
                (click)="viewVerificationVerifyBinById(data)"
                [ngClass]="{
                  'disabled-link':
                    data?.lockedStatus === 0 ||
                    data?.lockedStatus === 2 ||
                    data?.islockstatusCheck === false
                }"
              >
                View Verification
              </button>
              <!-- <button mat-menu-item [disabled]="true" (click)="viewVerificationVerifyBinById(data)">View Verification</button> -->
              <button mat-menu-item (click)="openAddNotesPopUp($event, data)">
                Add Notes
              </button>
              <button mat-menu-item (click)="openViewNotesPopup(data)">
                View Notes
              </button>
              <button
                mat-menu-item
                [matMenuTriggerFor]="moveTo"
                disabled="true"
              >
                Move To
              </button>
            </mat-menu>
            <mat-menu #moveTo="matMenu">
              <ng-container *ngFor="let bin of binList">
                <button
                  mat-menu-item
                  [disabled]="
                    bin.shortCodeId === data.inboundShortCode ||
                    bin.shortCodeId === inboundStatusIds.unprocessedFax
                  "
                  [value]="bin.id"
                  (click)="
                    bin.shortCodeId === inboundStatusIds.callBack
                      ? callback(data.docId, bin.shortCodeId)
                      : newSetupMoveTo(
                          data.docId,
                          data.inboundStatusId,
                          bin.shortCodeId,
                          false
                        )
                  "
                >
                  {{ bin.inboundStatus }}
                </button>
              </ng-container>
            </mat-menu>
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.defaultFaxId || '-')"
            [matTooltip]="data?.defaultFaxId || '-'"
          >
            <a
              [class.disabled]="
                isEmpty(data.documentId) || isEmpty(data.defaultFaxId)
              "
              [routerLink]="
                '/patient_preview_tiff_document/' +
                data.defaultFaxId +
                '/' +
                data.documentId
              "
              target="_blank"
              class="preview-link"
              >{{ data?.defaultFaxId || "-" }}</a
            >
          </td>
          <td
            class="text-right"
            (dblclick)="onRowDoubleClickV1(data?.pages || '-')"
            [matTooltip]="data?.pages"
          >
            {{ data?.pages === null || data?.pages === "" ? "-" : data?.pages }}
          </td>
          <td class="text-center"
            (dblclick)="onRowDoubleClickV1(data?.receivedDate || '-')"
            [matTooltip]="data?.receivedDate || '-'"
          >
            {{ data?.receivedDate || "-" }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.enterOn || '-')"
            [matTooltip]="data?.enterOn || '-'"
            class="text-center"
          >
            {{ data?.enteredOn ||'-' }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.chartNo || '-')"
            [title]="data?.chartNo"
          >
            <a
              [routerLink]="
                '/patientCreateEditTab/' + data?.patientId + '/' + data?.docId
              "
              target="_blank"
              [matTooltip]="data?.chartNo"
              class="preview-link"
            >
              {{
                data?.chartNo === null || data?.chartNo === ""
                  ? "-"
                  : data?.chartNo
              }}
            </a>
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.patientName || '-')"
            [matTooltip]="data?.patientName"
          >
            {{
              data?.patientName === null || data?.patientName === ""
                ? "-"
                : data?.patientName
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.plan || '-')"
            [matTooltip]="data?.plan"
          >
            <span *ngIf="data?.plan === insuranceType.primary">Primary</span>
            <span *ngIf="data?.plan === insuranceType.secondary"
              >Secondary</span
            >
            <span *ngIf="data?.plan === insuranceType.tertiary">Tertiary</span>
            <span *ngIf="data?.plan === insuranceType.Patient_Response"
              >Patient_Response</span
            >
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.payerName || '-')"
            [matTooltip]="data?.payerName"
          >
            {{
              data?.payerName === null || data?.payerName === ""
                ? "-"
                : data?.payerName
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.lockedBy || '-')"
            [matTooltip]="data?.lockedBy"
          >
            {{
              data?.lockedBy === null || data?.lockedBy === ""
                ? "-"
                : data?.lockedBy
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.lockedAt || '-')"
            [matTooltip]="data?.lockedAt || '-'"
            class="text-center cus-column-width"
          >
            {{ data?.lockedAt || "-" }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.pratice || '-')"
            [matTooltip]="data?.pratice"
          >
            {{
              data?.pratice === null || data?.pratice === ""
                ? "-"
                : data?.pratice
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.provider || '-')"
            [matTooltip]="data?.provider"
          >
            {{
              data?.provider === null || data?.provider === ""
                ? "-"
                : data?.provider
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.groupNo || '-')"
            [matTooltip]="data?.groupNo"
          >
            {{
              data?.groupNo === null || data?.groupNo === ""
                ? "-"
                : data?.groupNo
            }}
          </td>

          <!-- <td   (dblclick)="onRowDoubleClickV1(data?.enteredBy||'-')"[matTooltip]="data?.enteredBy">{{(data?.enteredBy===null||data?.enteredBy==='')?'-':data?.enteredBy}}</td> -->

          <td
            (dblclick)="onRowDoubleClickV1(data?.createdDate || '-')"
            [matTooltip]="data?.createdDate || '-'"
            class="text-center cus-column-width"
          >
            {{ data?.createdDate || "-" }}
          </td>
          <td
            [matTooltip]="data?.createdBy || '-'"
            (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')"
          >
            {{ data?.createdBy || "-" }}
          </td>

          <td
            [matTooltip]="data?.modifiedBy || '-'"
            (dblclick)="onRowDoubleClickV1(data?.modifiedBy || '-')"
          >
            {{ data?.modifiedBy || "-" }}
          </td>

          <td
            (dblclick)="onRowDoubleClickV1(data?.modifiedDate || '-')"
            [matTooltip]="data?.modifiedDate || '-'"
            class="text-center cus-column-width"
          >
            {{ data?.modifiedDate || "-" }}
          </td>

          <td
            (dblclick)="onRowDoubleClickV1(data?.documents || '-')"
            [matTooltip]="data?.documents"
          >
            {{
              data?.documents === null || data?.documents === ""
                ? "-"
                : data?.documents
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.csrName || '-')"
            [matTooltip]="data?.csrName"
          >
            {{
              data?.csrName === null || data?.csrName === ""
                ? "-"
                : data?.csrName
            }}
          </td>
          <td
            (dblclick)="onRowDoubleClickV1(data?.verificationStatus || '-')"
            [matTooltip]="
              data?.verificationStatus === null
                ? '-'
                : data?.verificationStatus === 'unabletoverify'
                ? 'Unable To Verify'
                : data?.verificationStatus
            "
          >
            <span
              *ngIf="
                data?.verificationStatus === lstVerificationSttaus.done ||
                data?.verificationStatus === lstVerificationSttausV1.done
              "
              >Done</span
            >
            <span
              *ngIf="
                data?.verificationStatus === lstVerificationSttaus.pending ||
                data?.verificationStatus === lstVerificationSttausV1.pending
              "
              >Pending</span
            >
            <span
              *ngIf="
                data?.verificationStatus ===
                  lstVerificationSttaus.unabletoverify ||
                data?.verificationStatus ===
                  lstVerificationSttausV1.unabletoverify
              "
              >Unable To Verify</span
            >
            <span
              *ngIf="
                data?.verificationStatus === lstVerificationSttaus.new ||
                data?.verificationStatus === lstVerificationSttausV1.new
              "
              >New</span
            >
            <span
              class="text-red"
              *ngIf="
                data?.verificationStatus !== lstVerificationSttaus.new &&
                data?.verificationStatus !== lstVerificationSttausV1.new &&
                data?.verificationStatus !==
                  lstVerificationSttaus.unabletoverify &&
                data?.verificationStatus !==
                  lstVerificationSttausV1.unabletoverify &&
                data?.verificationStatus !== lstVerificationSttaus.pending &&
                data?.verificationStatus !== lstVerificationSttausV1.pending &&
                data?.verificationStatus !== lstVerificationSttaus.done &&
                data?.verificationStatus !== lstVerificationSttausV1.done
              "
              >{{ data?.verificationStatus }}</span
            >
          </td>
          <td>
            <mat-checkbox
              [(ngModel)]="data.isStat"
              (change)="isStatUpdate($event, data)"
              [disabled]="disableSTATCheckbox"
            >
              <span [hidden]="true">{{ data.isStat ? "True" : "False" }}</span>
            </mat-checkbox>
          </td>
        </tr>
      </tbody>
      <!-- Footer -->
      <tfoot>
        <tr>
          <th><mat-icon> wrap_text</mat-icon></th>
          <th><mat-icon>apps</mat-icon></th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Fax Id"
              name="search-FaxId"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class text-right w-100"
              type="text"
              placeholder="Pages"
              name="search-Pages"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class text-center w-100"
              type="text"
              placeholder="Received"
              name="search-Received"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class text-center w-100"
              type="text"
              placeholder="Enter On"
              name="search-enteron"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Chart Id"
              name="search-Chart Id"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Name"
              name="search-Name"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Rank"
              name="search-Rank"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Insurance"
              name="search-Insurance"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Locked By"
              name="search-LockedBy"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class text-center w-100"
              type="text"
              placeholder="Locked At"
              name="search-LockedAt"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Practice"
              name="search-Practice"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="provider Name"
              name="search-providerName"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Ins Group"
              name="search-InsGroup"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class text-center w-100"
              type="text"
              placeholder="Created Date"
              name="search-CreatedDate"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Created By"
              name="search-Created By"
            />
          </th>

          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Modified By"
              name="search-ModifiedBy"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class text-center w-100"
              type="text"
              placeholder="Modified Date"
              name="search-ModifiedDate"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Documents"
              name="search-Documents"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="CSR"
              name="search-CSR"
            />
          </th>
          <th>
            <input
              matInput
              class="textbox-border-class"
              type="text"
              placeholder="Status"
              name="search-Status"
            />
          </th>
          <th><mat-icon>no_encryption</mat-icon></th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<div class="d-flex justify-content-center" *ngIf="isLoading">
    <!-- <mat-spinner></mat-spinner> -->
    <img
      class="img-responsive pb-2 mr-1"
      src="assets/gifs/Infinity-loader.gif"
    />
</div>
