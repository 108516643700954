import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { defaultGuid } from '../../document-perviewer/document-perviewer.component';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-view-ledager',
  templateUrl: './view-ledager.component.html',
  styleUrls: ['./view-ledager.component.scss']
})
export class ViewLedagerComponent implements OnInit {

  @Input() tableData: any;
  itemTableData:any;

  dtItemOptions: any = {
    responsive: true,
    paging: false,
    filter: false,
    scrollCollapse: true,
    scrollX: true,
    order: [],
    scrollY: '300px',
    info: true,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    columnDefs: [
      { targets: [0], width: '10%' },
      { targets: [1], width: '20%' },
      { targets: [2], width: '10%' },
      { targets: [3], width: '10%' },
      { targets: [4], width: '10%' },
      { targets: [5], width: '10%' },
      { targets: [6], width: '10%' },
      { targets: [7], width: '10%' },
      { targets: [8], width: '10%' },
    ],
  };
  
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  tblItemSelectedRowId: string;
  defaultGuid = defaultGuid;
  yourDate: Date;
  constructor(private datepipe: DatePipe,) { }

  ngOnInit(): void {
    this.yourDate = new Date('0001-01-01T00:00:00+05:53');
    this.itemTableData = this.tableData
  }


  tblItemSelectedRow(id: string, data) {
    this.tblItemSelectedRowId = this.tblItemSelectedRowId === id ? defaultGuid : id;
  }

  isDefaultDate(data): boolean {
    const suppliesDate = this.datepipe.transform(data, 'MM/dd/yyyy');
    const defaultDate = this.datepipe.transform(this.yourDate, 'MM/dd/yyyy');
    return suppliesDate === defaultDate;
  }

   //Row Click Event UnProcessed Table
   handleTableCellClick(e: Event, hcpcData: any): void {
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, hcpcData.hcpcList);
  }

  //DataTable Data Get Method and Toggle Notes Row in Table
  async getDataTableData(tr: any, notesData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;

    if (tr) {
      const row = this.myTable.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        row.child(this.format(row.data(), notesData)).show();
      }
    }
  }

  //Add Dynamic Notes Row in Tabl
  format(d: any, notesData: any) {
    let returnString: string = '';
    notesData?.forEach(function (value, index) {
      returnString +=
      '' +
       value.hcpcCode +'<h1 class="line" style="border-bottom: 1px solid rgb(196, 201, 196);Margin: 4px 0 9px 0px"></h1>'
    });
    return returnString;
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  getHcpcCodes(hcpcList: any[]): string {
    return hcpcList.map(hcpcData => hcpcData.hcpcCode).join(', ');
  }
}
