import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WIPList } from '../admin-proxy/platform-app-management/rcm/platform-management/work-in-progress/dto';

@Component({
  selector: 'app-admin-wip-report',
  templateUrl: './admin-wip-report.component.html',
  styleUrls: ['./admin-wip-report.component.scss']
})
export class AdminWipReportComponent implements OnInit, AfterViewInit {
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  selDefaultDocId: string = "";
  isLoading: boolean = false;
  arrWipDisplayedColumns: string[] = [
    'wipId',
    'assignedByName',
    "assignedToName",
    'assignedDate',
    'wipStatus',
  ];
  constructor(@Inject(MAT_DIALOG_DATA) public data: { wipList: WIPList[], selDefaultDocId: string }) {
    //this.setTableData([]);
  }
  ngAfterViewInit(): void {
    const wipList = this.data?.wipList ?? [];
    this.setTableData(wipList ?? []);
  }

  ngOnInit(): void {
    this.selDefaultDocId = this.data?.selDefaultDocId ?? "";
  }
  setTableData(wipList: WIPList[] = []) {
    this.dataSource = new MatTableDataSource(wipList);
    this.dataSource.sort = this.sort;
    this.dataSource.sort.disableClear = true;
    this.dataSource.paginator = this.paginator;
  }
}
