import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Base64 } from './base64';

@Component({
  selector: 'app-annotation',
  templateUrl: './annotation.component.html',
  styleUrls: ['./annotation.component.scss'],
})
export class AnnotationComponent implements OnInit {
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  ctx!: CanvasRenderingContext2D;
  isDrawing: boolean = false;
  startX: number = 0;
  startY: number = 0;
  lineColor: string = '#F6BE00';
  textColor: string = '#0000FF';
  savedState: ImageData | null = null;
  textAnnotations: TextAnnotation[] = [];

  rectWidth: number;
  rectHeight: number;

  constructor() {}

  ngOnInit() {
    const base64Instance = new Base64();
    const tiffBase64 = base64Instance.base64String;
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');

    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      this.ctx.drawImage(img, 0, 0);
    };
    img.src = 'data:image/tiff;base64,' + tiffBase64;
  }

  onMouseDown(event: MouseEvent) {
    // event.preventDefault(); // Prevent default right-click menu
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    this.startX = event.clientX - rect.left;
    this.startY = event.clientY - rect.top;
    this.isDrawing = true;
    this.savedState = this.ctx.getImageData(0, 0, canvas.width, canvas.height);
  }

  onMouseMove(event: MouseEvent) {
    // event.preventDefault(); // Prevent default right-click menu
    if (!this.isDrawing) return;

    // Get the canvas and its context
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');

    // Get the bounding rectangle of the canvas
    const rect = canvas.getBoundingClientRect();

    // Calculate current mouse position relative to the canvas
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Calculate width and height of the rectangle
    const rectWidth = x - this.startX;
    const rectHeight = y - this.startY;

    if (this.savedState !== null) {
      this.ctx.putImageData(this.savedState, 0, 0);
    }

    // Draw the rectangle for the current move
    this.ctx.strokeStyle = this.lineColor;
    this.ctx.lineWidth = 3;
    this.ctx.strokeRect(this.startX, this.startY, rectWidth, rectHeight);
  }

  onMouseUp(event: MouseEvent) {
    if (!this.isDrawing) return;

    // Get the canvas and its context
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');

    // Get the bounding rectangle of the canvas
    const rect = canvas.getBoundingClientRect();

    // Calculate current mouse position relative to the canvas
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Calculate width and height of the rectangle
    const rectWidth = x - this.startX;
    const rectHeight = y - this.startY;

    // Save the current canvas state
    const currentState = this.ctx.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    );

    if (this.savedState !== null) {
      // Redraw the previously saved canvas state
      this.ctx.putImageData(this.savedState, 0, 0);
    }

    // Draw the rectangle for the current move
    this.ctx.strokeStyle = this.lineColor;
    this.ctx.lineWidth = 3;
    this.ctx.strokeRect(this.startX, this.startY, rectWidth, rectHeight);
    this.rectWidth = rectWidth;
    this.rectHeight = rectHeight;

    // Save the changes for the current move
    this.savedState = this.ctx.getImageData(0, 0, canvas.width, canvas.height);
    this.isDrawing = false;
  }

  onMouseLeave(event: MouseEvent) {
    this.isDrawing = false;

    this.addText(event);
  }

  addText(event: MouseEvent) {
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    const textX = event.clientX - rect.left;
    const textY = event.clientY - rect.top;

    let text = prompt('Enter text:');
    if (text === null || text === '' || text) {
      text = text.trim();
      // Save current state before making changes
      const currentState = this.ctx.getImageData(
        0,
        0,
        this.canvasRef.nativeElement.width,
        this.canvasRef.nativeElement.height
      );

      // Draw text directly onto the canvas
      this.ctx.font = '45px Arial'; // Specify font style and size
      this.ctx.fillStyle = this.textColor;
      this.ctx.fillText(text, textX, textY);

      // Save the changes made by drawing the text
      const newTextState = this.ctx.getImageData(
        0,
        0,
        this.canvasRef.nativeElement.width,
        this.canvasRef.nativeElement.height
      );
      // Redraw the previously saved canvas state
      this.ctx.putImageData(currentState, 0, 0);

      // Draw the text again on the restored canvas state
      this.ctx.fillText(text, textX, textY);

      // Save the changes for the text
      this.savedState = newTextState;

      // Create a new TextAnnotation object and add it to the textAnnotations array
      const newAnnotation: TextAnnotation = {
        text: text,
        x: textX,
        y: textY,
        rectWidth: this.rectWidth,
        rectHeight: this.rectHeight,
      };
      this.textAnnotations.push(newAnnotation);
    }
    // Redraw all annotations
    this.redrawAnnotations();
  }

  deleteText(event: MouseEvent) {
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;

    // Find the text annotation closest to the clicked position
    const indexToDelete = this.textAnnotations.findIndex((annotation) => {
      const textWidth = this.ctx.measureText(annotation.text).width;
      return (
        clickX >= annotation.x &&
        clickX <= annotation.x + textWidth &&
        clickY >= annotation.y - 14 &&
        clickY <= annotation.y
      ); // 14 is font size
    });

    if (indexToDelete !== -1) {
      // Ask for confirmation
      const confirmation = confirm(
        'Are you sure you want to delete this text?'
      );
      if (confirmation) {
        this.textAnnotations.splice(indexToDelete, 1);
        this.redrawAnnotations();
      }
    }
  }

  redrawAnnotations() {
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    const img = new Image();
    img.onload = () => {
      this.ctx.drawImage(img, 0, 0);
      this.textAnnotations.forEach((annotation) => {
        this.ctx.font = '14px Arial';
        this.ctx.fillStyle = this.textColor;
        this.ctx.fillText(annotation.text, annotation.x, annotation.y);
        // Draw the rectangle for the current move
        this.ctx.strokeStyle = this.lineColor;
        this.ctx.lineWidth = 3;
        this.ctx.strokeRect(
          annotation.x - annotation.rectWidth,
          annotation.y - annotation.rectHeight,
          annotation.rectWidth,
          annotation.rectHeight
        );

        // Save the changes for the current move
        this.savedState = this.ctx.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );
      });
    };
    img.src = 'data:image/tiff;base64,' + new Base64().base64String;
  }
}

interface TextAnnotation {
  text: string;
  x: number;
  y: number;
  rectWidth: number;
  rectHeight: number;
}
