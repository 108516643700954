import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { PageViewModeType } from 'ngx-extended-pdf-viewer';
import { ToastrService } from 'ngx-toastr';
import { DocumentClassificationDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import { SaveDocumentDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/sorting/dto';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { SortingShortCode } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import { MmOrderService } from '../order-proxy/order-management/order-optimization/mm-order.service';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { HellosignSignatureService } from '../order-proxy/order-management/order-optimization/hellosign-signature.service';
import { ItemPaymentService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rx-preview-document',
  templateUrl: './rx-preview-document.component.html',
  styleUrls: ['./rx-preview-document.component.scss']
})
export class RxPreviewDocumentComponent implements OnInit {
  strSelectedPdfPath: string = '';
  strSelectedPdfPathAll: string = '';
  SortingShortCode = SortingShortCode;
  inputForm: FormGroup;
  pageViewMode: PageViewModeType = 'single';
  pdfHeight: string;
  patientId: string;
  defaultPatientId: string;
  patientName: string;
  documentId: string;
  defaultFaxId: string;
  docView: any;
  totalPages: number = 0;
  // pdfPages: number = 0;
  pageNumber: number = 1;
  width: any;
  pageCount;
  tempArray = [];
  imageDataURL: any;
  widthDisplay: number;
  sortStatus: any;
  isModel: boolean = false;
  heightDisplay: number;
  isLoading: boolean = false;
  moveButtonDisabled: boolean = true;
  completeButtonDisabled: boolean = true;
  currentPage: number = 1;
  faxId: any;
  documentData: any;
  sortingBin: any;
  moveToMissingBin: any;
  dTypeId: string; //Document Type NgModel Field
  documentClassification: DocumentClassificationDto[] = [];
  documentTypes = [];
  saveDocumentDetails: SaveDocumentDto[] = [];
  isLoadSortingView: boolean = true;
  isExpanded = false;
  blobName: string;
  signatureRequestedId: any;
  tenantId: string;
  documentTypesArray: any[] = [];
  subscription$: Subscription[] = [];
  constructor(private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private patientPersonalService: PatientPersonalService,
    private cookieService: CookieService,
    private tableService: TableService,
    private fb: FormBuilder,
    private mmOrderService: MmOrderService,
    private inboundDocumentService: InboundDocumentService,
    private helloSign: HellosignSignatureService,
    private itemPaymentService: ItemPaymentService, @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      documentData: string;
      fileContent: string;
      viewType: boolean;
      isClaim: boolean;
      isRxView: boolean
    }) { }

  ngOnInit(): void {

    this.tenantId = localStorage.getItem('tenantId') ?? '';
      this.route.params.subscribe(params => {
        this.signatureRequestedId = params['signatureRequestedId'];
      });


      if(this.data.viewType == true){
        this.documentData = this.data.fileContent

        this.viewSortedDoc(this.documentData)
      }  else if(this.data.isRxView == true){
        this.loadRxDocumentPreview()
      }
     

      if(this.data.isClaim == true){
        this.loadClaimPreviewDoc()
      }
  }
  viewSortedDoc(doc){
    if (
      typeof doc === 'string' &&
      doc &&
      doc !== null 
    ) {
      this.strSelectedPdfPath = String(
        'data:application/pdf;base64,' + doc
      );
    }
  }

  sortingDocView() {

    this.isLoadSortingView = true;
    // this.isDisabled = true;
    const sortingDocViewList = this.inboundDocumentService
      .getSortedDocumentPagesByGDocumentId(this.documentId)
      .subscribe(
        (response) => {
          this.documentTypes = response.ltDocuments.map((value) => ({
            docTypeId: value.documentTypeId,
            shortCodeId: value.shortCode,
            isMedicareBin: value?.isMedicareBin,
            documentType: value?.documentType.toUpperCase(),
            documentPages: value?.docPageContent?.ltPageNumbers ?? [],
            pageCount: value?.pageCount ?? 0,
            fileContent: value?.docPageContent?.fileContent ?? '',
          }));

          this.documentTypesArray = Object.values(this.documentTypes);

          this.isLoadSortingView = false;
          // this.isDisabled = false;
        },
        (err) => {
          // this.strSelectedPdfPath = '';
          this.isLoadSortingView = false;
          // this.isDisabled = false;
          console.warn('Error', err);
          // Check if the error has the expected structure
          if (err.error && err.error.message) {
            // Parse the JSON error message and extract the specific message
            try {
              const errorMessageObject = JSON.parse(err.error.message);
              const specificErrorMessage = errorMessageObject.error.message;

              // Print the specific error message
              console.warn('Specific Error Message:', specificErrorMessage);

              // Show a warning toaster with the specific error message
              this.toastr.warning('Reason: ' + specificErrorMessage, 'Warning');
            } catch (parseError) {
              // Log a warning if there is an issue parsing the error message
              console.warn('Error parsing error message:', parseError);
              this.toastr.warning('Reason: ' + parseError, 'Warning');
            }
          } else {
            // If the error structure is unexpected, log the entire error message
            console.warn('Unexpected error structure:', err);
            this.toastr.error('Unexpected error structure: ' + err, 'Error');
          }
        }
      );
    this.subscription$.push(sortingDocViewList);
  }

  loadClaimPreviewDoc(){
    this.itemPaymentService.getEOBDocumentByBlobIDByBlobName(this.data.documentData).subscribe(response => {
     
    })
  }

  loadRxDocumentPreview(){

  }

  // openRxDocument(){
  //   this.mmOrderService.getRxImagedocumentIdByPatientId(this.data.patientId).subscribe(response => {
  //     this.viewDocmentPDF(response.documentId)
  //     this.documentId = response.documentId
  //     this.blobName = response.blobName
  //     this.sortingDocView();
  //     this.loadPatientData();
  //     this.loadInboundDocument();
  //   })
  
  // }


  viewDocmentPDFHelloSign(data){
    this.helloSign.getSignatureDownloadDocumentBySignatureIdAndTenantId(data.documentData,this.tenantId).subscribe(  (response) => {
      if (
        typeof response === 'string' &&
        response &&
        response !== null &&
        response?.length !== 0
      ) {
        this.strSelectedPdfPath = String(
          'data:application/pdf;base64,' + response
        );
      }
    },
    (err) => {
      this.strSelectedPdfPath = '';
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }

    )
  }

  viewDocmentPDF(docID){
    this.inboundDocumentService.downloadInboundDocumentByDocumentId(docID).subscribe(  (response) => {
      if (
        typeof response === 'string' &&
        response &&
        response !== null &&
        response?.length !== 0
      ) {
        this.strSelectedPdfPath = String(
          'data:application/pdf;base64,' + response
        );
      }
    },
    (err) => {
      this.strSelectedPdfPath = '';
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    }

    )
  }
  //Get Patient Details
  loadPatientData() {
    //Load Patient List Dropdown
    const searchPatientSub = this.patientPersonalService
      .patientSearchBySDefaultPatientIdAndSPatientNameAndDtDOBAndSSSNAndSEmailIdAndSMobileAndSStatus(
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        1
      )
      .subscribe((response) => {
        this.defaultPatientId = response?.find(
          (a) => a.patientId == this.patientId
        )?.defaultPatientId;
        this.patientName = response?.find(
          (a) => a.patientId == this.patientId
        )?.patientName;
      });
    this.subscription$.push(searchPatientSub);
  }

  onListItemClicked(selectedDocument: any) {
    const shortCodeId = selectedDocument.shortCodeId; // Additional value from the selected document
    this.onListSelected(selectedDocument.docTypeId, shortCodeId);
  }

  //List Selection Event-To enable the document move button
  onListSelected(e, shortCodeId) {
    try {
    this.moveButtonDisabled = false;
    this.isLoading = true;
    if (shortCodeId == SortingShortCode.All) {
      this.strSelectedPdfPath = (this.strSelectedPdfPathAll) ? this.strSelectedPdfPathAll :'';
    } else {
      var filteredDocuments = this.documentTypesArray.filter(function (doc) {
        return doc.docTypeId === e;
      });
      if (filteredDocuments.length > 0 && filteredDocuments[0].fileContent) {
        const fileContent: string = filteredDocuments[0].fileContent;
        this.strSelectedPdfPath = String(
          'data:application/pdf;base64,' + fileContent
        );
      } else {
        this.strSelectedPdfPath = '';
      }
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setTimeout(() => {
      this.isLoading = false;
    }, 100);
  }
  }

  onListSelected_(e) {
    this.moveButtonDisabled = false;
    var filteredDocuments = this.documentTypesArray.filter(function (doc) {
      return doc.documentTypeId === e[0];
    });
    if (filteredDocuments) {
      const fileContent: string =
        filteredDocuments[0]['docPageContent']['fileContent'];
      this.strSelectedPdfPath = String(
        'data:application/pdf;base64,' + fileContent
      );
    } else {
      this.strSelectedPdfPath = '';
    }

    // let matchingDocument = this.docView.find(
    //   (doc: DocView) => doc.documentTypeId === e[0]
    // );
    // if (matchingDocument) {
    //   const fileContent: string = matchingDocument.fileContent;
    //   this.strSelectedPdfPath = String(
    //     'data:application/pdf;base64,' + fileContent
    //   );
    // } else {
    //   this.strSelectedPdfPath = '';
    // }
  }



  getToolTip(data) {
    return data.documentType;
  }

  onPageChange(e) {
    this.currentPage = e;
  }
  openModel() {}
  loadInboundDocument() {
    this.isLoading = true;
    const loadInboundDocument = this.inboundDocumentService
      .downloadInboundDocumentByBlobName(this.blobName)
      .subscribe(
        (response) => {
          if (
            typeof response === 'string' &&
            response &&
            response !== null &&
            response?.length !== 0
          ) {
            this.strSelectedPdfPathAll = String(
              'data:application/pdf;base64,' + response
            );
          }
          this.isLoading = false;
        },
        (err) => {
          this.strSelectedPdfPathAll = '';
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(loadInboundDocument);
  }
}
