<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18"><b>Approval List</b></h4>
        <ol class="breadcrumb m-0">
          <li>
            <button matTooltip="Click To Goback to Batch List" [routerLink]="'/uploadBin'" mat-button
              class="pl-2 pr-2 buttonColor font-size-10">
              <i class="fa fa-backward mr-1" ></i> Back To Batch List
            </button>
          </li>
          <li class="ml-2 mt-2 breadcrumb-item">Approval List</li>
          <li class="mt-2 breadcrumb-item muted">Approval List</li>
        </ol>
      </div>
      <mat-card-content>
        <!-- <div [hidden]="!isLoading"
          style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
          <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
          </mat-progress-bar>
        </div> -->
        <div class="table table-responsive">
          <table class="mat-elevation-z8" multiTemplateDataRows mat-table class="table" [dataSource]="dataSource"
            matSort>
            <ng-container matColumnDef="batchNo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Batch No</th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  {{ element?.defaultBatchId }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="stat">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Stat</th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element?.isStat === true">
                  <mat-checkbox [disabled]="true" [checked]="true"> </mat-checkbox>
                </ng-container>
                <ng-container *ngIf="element?.isStat === false">
                  <mat-checkbox [disabled]="true" [checked]="false"> </mat-checkbox>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="documentName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Document Name</th>
              <td mat-cell *matCellDef="let element">
                {{ element?.uploadedFileName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  {{ element?.status }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="Options">
              <th class="verticalAlignColumn text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                Options
              </th>
              <td class="verticalAlignColumn text-center" mat-cell *matCellDef="let element">
                <a (click)="expandedElement = expandedElement === element ? null : element">
                  <mat-icon class="pointer">
                    {{ expandedElement === element ? 'expand_less' : 'expand_more' }}</mat-icon>
                </a>
                <!-- <a *ngIf="
                  element?.listIdentifiedPatients !== [] &&
                  element?.listIdentifiedPatients.length !== 0
                " (click)="expandedElement = expandedElement === element ? null : element">
                <mat-icon class="pointer">
                  {{ expandedElement === element ? 'expand_less' : 'expand_more' }}</mat-icon>
              </a> -->

                <!-- <a *ngIf=" element?.status ==='New Patient'">
                <mat-icon class="editBtnColor m-0" matTooltip="Approve" (click)=" addNewPatientWhenApproved(element) ">
                  check_circle_outline
                </mat-icon>
              </a> -->
              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="10">
                <div *ngIf="!isLoading">
                  {{"No Records Found"}}
                </div>
                <div *ngIf="isLoading">
                  {{"Processing..."}}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="10">
                <mat-paginator class="table table-responsive mat-table-class" #extractpaginator
                  [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                  [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container>
            <ng-container matColumnDef="expandedErrorDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="arrDisplayedColumns?.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="example-element-description">
                    <p>Extracted Patient</p>
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <!-- <th scope="col">Patient Id</th> -->
                          <th scope="col">Name</th>
                          <th scope="col">DOB</th>
                          <th scope="col">Mail Id</th>
                          <th scope="col">Status</th>
                          <th scope="col">Options</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ element?.extractedData?.fullName }}</td>
                          <td>{{ element?.extractedData?.dateOfBirth }}</td>
                          <td>{{ element?.extractedData?.emailId }}</td>
                          <td>{{ element?.extractedData?.status }}</td>
                          <td>
                            <button *ngIf="element?.extractedData?.lastDocumentBlobDetails" mat-icon-button
                              class="editBtnColor m-0 p-0">
                              <mat-icon matTooltip="Click To Preview Document"
                                (click)="previewDocumentById(element?.extractedData?.lastDocumentBlobDetails)">
                                visibility
                              </mat-icon>
                            </button>
                            <button *ngIf="element?.extractedfields" mat-icon-button class="editBtnColor m-0 p-0">
                              <mat-icon matTooltip="Click To Edit Details"
                                (click)="updateExtractionPatientData(element)">
                                edit</mat-icon>
                            </button>
                            <button *ngIf="element?.extractedData?.lastDocumentBlobDetails" mat-icon-button
                              class="editBtnColor m-0 p-0">
                              <mat-icon matTooltip="Click To Download Document" (click)="
                              downloadAutoUploadLastDocument(element?.extractedData?.lastDocumentBlobDetails)">
                                cloud_download
                              </mat-icon>
                            </button>
                            <button *ngIf="element?.fileId" mat-icon-button class="editBtnColor m-0 p-0">
                              <mat-icon matTooltip="Click To Update Details"
                                (click)="documnetViewerSideBySide(element?.fileId)">
                                update
                              </mat-icon>
                            </button>

                            <button mat-icon-button [disabled]="addButtonDisable" class="editBtnColor m-0">
                              <mat-icon matTooltip="Approve" (click)="addNewPatientWhenApproved(element)">
                                check_circle_outline</mat-icon>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <p *ngIf="element?.listIdentifiedPatients !== [] &&element?.listIdentifiedPatients?.length !== 0">
                      Identical Patient </p>
                    <table *ngIf="
                      element?.listIdentifiedPatients !== [] &&
                      element?.listIdentifiedPatients.length !== 0
                    " class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th scope="col">Patient Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">DOB</th>
                          <th scope="col">Mail Id</th>
                          <th scope="col">Status</th>
                          <th scope="col">Options</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let ipatient of element?.listIdentifiedPatients">
                          <th scope="row">{{ ipatient.identifiedDefaultPatientId }}</th>
                          <td>{{ ipatient.name }}</td>
                          <td>{{ ipatient.dateOfBirth }}</td>
                          <td>{{ ipatient.mailId }}</td>
                          <td>{{ ipatient.status }}</td>

                          <td>
                            <!-- <button *ngIf="element?.extractedData?.lastDocumentBlobDetails" mat-icon-button
                            class="editBtnColor m-0 p-0">
                            <mat-icon matTooltip="Click To Download Document" (click)="
                                downloadAutoUploadLastDocument(
                                  element?.extractedData?.lastDocumentBlobDetails
                                )
                              ">
                              cloud_download</mat-icon>
                          </button> -->
                            <button mat-icon-button [disabled]="addButtonDisable" class="editBtnColor m-0">
                              <mat-icon matTooltip="Approve" (click)="
                                approvalUpdatePatientId(
                                  element?.fileId,
                                  ipatient.identifiedPatientId
                                )
                              ">
                                check_circle_outline</mat-icon>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>


                  </div>
                </div>
              </td>
            </ng-container>
            <tr mat-footer-row *matFooterRowDef="['noRecords']"
              [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: ['expandedErrorDetail']"
              [class.example-expanded-row]="expandedElement === element"></tr>
          </table>
        </div>

      </mat-card-content>
    </div>
    <div class="col-12">
      <mat-accordion #firstAccordion="matAccordion">
        <mat-expansion-panel [expanded]="step">
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <ng-container>
                <b>Existing Patient List Search</b>
              </ng-container>

            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row mt-1">
            <div class="col-lg-12">
              <form [formGroup]="personalForm">
                <div [hidden]="!isLoading"
                  style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
                  <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
                  </mat-progress-bar>
                </div>
                <div class="table table-responsive">
                  <table mat-table [dataSource]="patientData" class="mat-elevation-z8">
                    <!-- <ng-container matColumnDef="S.No">
                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input matInput formControlName="Sno" type="text" />
                          <mat-placeholder class="center">S.No</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element; let i=index">
                        {{ i+1 }}
                      </td>
                    </ng-container> -->

                    <!-- Name Column -->
                    <ng-container matColumnDef="Name">
                      <!-- <th mat-header-cell *matHeaderCellDef> Name </th> -->
                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-Name" matInput formControlName="Name" />
                          <mat-placeholder>Name</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <span matTooltip="{{element?.Name}}" matTooltipClass="custom-tooltip-font"> {{ element?.Name
                          }}</span>
                      </td>
                    </ng-container>
                    <!-- Patient Id Column -->
                    <ng-container matColumnDef="Patient Id">
                      <!-- <th mat-header-cell *matHeaderCellDef> Patient Id </th> -->
                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-PatientId" matInput formControlName="PatientId" />
                          <mat-placeholder>Patient Id</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element"> {{ element?.PatientId }}</td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="Address">
                      <!-- <th mat-header-cell *matHeaderCellDef> Address </th> -->
                      <th class="header" fxFlex="200" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-Address" matInput formControlName="Address" />
                          <mat-placeholder>Address</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td fxFlex="200" mat-cell *matCellDef="let element"> <span class="ellipsis .cut-text">
                          {{ element?.Address }} </span>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="SSN">
                      <!-- <th mat-header-cell *matHeaderCellDef> Account Number </th> -->
                      <th class="header  w-175" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-SSN" matInput formControlName="SSN" />
                          <mat-placeholder>SSN</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">{{ element?.SSN }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Account Number">

                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-AccountNumber" matInput formControlName="AccountNumber" />
                          <mat-placeholder>Account No</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">{{ element?.AccountNumber }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Email Address">

                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-EmailAddress" matInput formControlName="EmailAddress" />
                          <mat-placeholder>Email Address</mat-placeholder>

                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">{{ element?.EmailAddress }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Date Of Birth">

                      <th class="header" class="w-125" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <mat-label> Date Of Birth </mat-label>
                          <input autocomplete="new-DateOfBirth" matInput formControlName="DateOfBirth" placeholder="(MM/DD/YYYY)" />
                          <mat-error
                            *ngIf=" personalForm?.get('DateOfBirth')?.touched && personalForm?.get('DateOfBirth')?.errors?.dateVaidator">
                            Enter Valid date
                          </mat-error>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">{{ element?.DateOfBirth }}</td>
                    </ng-container>

                    <ng-container matColumnDef="Moblie">
                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-Moblie" matInput formControlName="Moblie" />
                          <mat-placeholder>Moblie</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">{{ element?.Moblie }}</td>
                    </ng-container>

                    <ng-container matColumnDef="currStatus">
                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-currStatus" matInput formControlName="currStatus"  />
                          <mat-placeholder>Current Status</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">{{ element?.Moblie }}</td>
                    </ng-container>
                    <ng-container matColumnDef="LockStatus">
                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-LockStatus" matInput formControlName="LockStatus" />
                          <mat-placeholder>Status </mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">{{ element?.LockStatusWithName }}</td>
                    </ng-container>

                    <ng-container matColumnDef="noRecords">
                      <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                        <div *ngIf="!isLoading">
                          {{"No Records Found"}}
                        </div>
                        <div *ngIf="isLoading">
                          {{"Processing..."}}
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                      <td mat-footer-cell *matFooterCellDef colspan="9">
                        <mat-paginator class="table table-responsive" #patientPaginator
                          [length]="patientData?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                          [pageSizeOptions]="[5, 10, 25, 100]">
                        </mat-paginator>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    <tr mat-footer-row *matFooterRowDef="['noRecords']"
                      [ngClass]="{ active: patientData && patientData.data && patientData.data.length !== 0 }"></tr>
                    <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
                  </table>
                </div>
              </form>
            </div>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
