<div class="row">
  <div class="col-lg-6">
    <h5 class="customThemeClass"><b>Permissions</b></h5>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-xl-12">
      <!--Tenant-->
      <div class="row">
        <div class="col-lg-12">
          <mat-card-content>
            <form [formGroup]="permissionForm">
              <div class="row">
                <div class="col-12">
                  <div class="example-action-buttons">
                    <button mat-button (click)="accordion.closeAll()">Collapse All</button>
                    <button mat-button (click)="accordion.openAll()">Expand All</button>&nbsp;
                    <mat-checkbox  formControlName="chkAllManagement" (change)="selectAll($event)">Select All</mat-checkbox>
                  </div>
                </div>
                <div class="col-12">
                  <mat-accordion class="example-headers-align" multi>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Patient Management
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="col-6">
                        <mat-checkbox  formControlName="chkPatientManagement"  (change)="selectAllPatient($event)">Select All Permission
                          for Patient </mat-checkbox>
                      </div>
                      <div [hidden]='true' class="px-5 col-6">
                        <mat-checkbox   formControlName="chkPatientCreate">Create Patients
                        </mat-checkbox>
                      </div>
                      <div [hidden]='true' class="px-5 col-6">
                        <mat-checkbox formControlName="chkPatientEdit">Edit Patients
                        </mat-checkbox>
                      </div>
                      <div [hidden]='true' class="px-5 col-6">
                        <mat-checkbox formControlName="chkPatientView">View Patients
                        </mat-checkbox>
                      </div>
                    <!-- newly added for permission -->
                      <div  class="px-5 col-6">
                        <mat-checkbox (change)="changePatient($event)" formControlName="chkPatientIntake">Patient Intake
                        </mat-checkbox>
                      </div>
                      <div  class="px-5 col-6">
                        <mat-checkbox (change)="changePatient($event)" formControlName="chkInsVerification">Insurance Verification
                        </mat-checkbox>
                      </div>
                      <div  class="px-5 col-6">
                        <mat-checkbox (change)="changePatient($event)" formControlName="chkAuthorization">Authorization
                        </mat-checkbox>
                      </div>
                     <!-- newly added for permission -->
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changePatient($event)" formControlName="chkPatientDelete">Delete Patients
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changePatient($event)" formControlName="chkDocumentDelete">Document Delete
                        </mat-checkbox>
                      </div>

                    </mat-expansion-panel>


                    <!-- <mat-expansion-panel >
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    Plan
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div class="col-6">
                                                <mat-checkbox (change)="selectAllPlan($event)" >Select All Permission for Order </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkPlanCreate">Create Plan
                                                </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkPlanEdit">Edit Plan
                                                </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkPlanView">View Plan
                                                </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkPlanDelete">Delete Plan
                                                </mat-checkbox>
                                            </div>

                                        </mat-expansion-panel> -->
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Order Management
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="col-6">
                        <mat-checkbox  formControlName="chkOrderManagement"  (change)="selectAllOrder($event)">Select All Permission
                          for Order </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeOrder($event)" formControlName="chkOrderCreate">Create Orders
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeOrder($event)" formControlName="chkOrderEdit">Edit Orders
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeOrder($event)" formControlName="chkOrderView">View Orders
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeOrder($event)" formControlName="chkOrderDelete">Delete Orders
                        </mat-checkbox>
                      </div>
                      <!-- Newly added -->
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeOrder($event)" formControlName="chkShipping">Shipping Approval
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeOrder($event)" formControlName="chkWarehouse">Warehouse
                        </mat-checkbox>
                      </div>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Item
                        </mat-panel-title>

                      </mat-expansion-panel-header>
                      <div class="col-6">
                        <mat-checkbox formControlName="chkItemManagement"  (change)="selectAllItem($event)">Select All Permission for
                          Item </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeItem($event)" formControlName="chkItemCreate">Create Items
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeItem($event)" formControlName="chkItemEdit">Edit Items
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeItem($event)" formControlName="chkItemView">View Items
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeItem($event)" formControlName="chkItemDelete">Delete Items
                        </mat-checkbox>
                      </div>

                    </mat-expansion-panel>

                    <!-- <mat-expansion-panel >
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    Insurance
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div class="col-6">
                                                <mat-checkbox (change)="selectAllInsurance($event)">Select All Permission for Insurance </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkInsuranceCreate">Create Insurances
                                                </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkInsuranceEdit">Edit Insurances
                                                </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkInsuranceView">View Insurances
                                                </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkInsuranceDelete">Delete Insurances
                                                </mat-checkbox>
                                            </div>

                                        </mat-expansion-panel> -->

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Notes
                        </mat-panel-title>

                      </mat-expansion-panel-header>
                      <div class="col-6">
                        <mat-checkbox formControlName="chkNotesManagement"  (change)="selectAllNotes($event)">Select All Permission
                          for Notes </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeNotes($event)" formControlName="chkNotesCreate">Create Notes
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeNotes($event)" formControlName="chkNotesEdit">Edit Notes
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeNotes($event)" formControlName="chkNotesView">View Notes
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeNotes($event)" formControlName="chkNotesDelete">Delete Notes
                        </mat-checkbox>
                      </div>

                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          File Upload
                        </mat-panel-title>

                      </mat-expansion-panel-header>
                      <div class="col-6">
                        <mat-checkbox formControlName="chkFileUploadManagement" (change)="selectAllFileUpload($event)">Select All Permission for File Upload
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeFileUpload($event)" formControlName="chkFileUploadCreate">Create File Upload
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeFileUpload($event)" formControlName="chkFileUploadEdit">Edit File Upload
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeFileUpload($event)" formControlName="chkFileUploadView">View File Upload
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeFileUpload($event)" formControlName="chkFileUploadDelete">Delete File Upload
                        </mat-checkbox>
                      </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          User
                        </mat-panel-title>

                      </mat-expansion-panel-header>
                      <div class="col-6">
                        <mat-checkbox  formControlName="chkUsersManagement" (change)="selectAllUser($event)">Select All Permission for
                          Users </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeUsers($event)" formControlName="chkUsersCreate">Create User
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeUsers($event)" formControlName="chkUsersEdit">Edit User
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeUsers($event)" formControlName="chkUsersView">View User
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeUsers($event)" formControlName="chkUsersDelete">Delete User
                        </mat-checkbox>
                      </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Roles
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <div class="col-6">
                        <mat-checkbox formControlName="chkRolesManagement" (change)="selectAllRole($event)">Select All Permission for
                          Roles </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeRoles($event)" formControlName="chkRolesCreate">Create Role
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeRoles($event)" formControlName="chkRolesEdit">Edit Role
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeRoles($event)" formControlName="chkRolesView">View Role
                        </mat-checkbox>
                      </div>
                      <div class="px-5 col-6">
                        <mat-checkbox (change)="changeRoles($event)" formControlName="chkRolesDelete">Delete Role
                        </mat-checkbox>
                      </div>
                      <!-- <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkRolesManagePermissions">Manage
                                                    Permissions </mat-checkbox>
                                            </div> -->
                    </mat-expansion-panel>

                    <!-- <mat-expansion-panel *ngIf = 'tenantUserHide'>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    Identity Management
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div class="col-6">
                                                <p>Role Management</p>
                                            </div>
                                            <div class="px-3 col-6">
                                                <mat-checkbox >Select All Permission for Roles </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkRoleCreate">Create Roles </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkRoleEdit">Edit Roles</mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkRoleView">View Roles</mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkRoleDelete">Delete Roles</mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkRoleManagePermissions">Manage Permissions </mat-checkbox>
                                            </div>
                                            <div class="col-6">
                                                <p>Users Management</p>
                                            </div>
                                            <div class="px-3 col-6">
                                                <mat-checkbox >Select All Permission for Users </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkUsersCreate">Create Users
                                                </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkUsersEdit">Edit Users</mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkUsersView">View Users</mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkUsersDelete">Delete Users</mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">
                                                <mat-checkbox formControlName="chkUsersManagePermissions">Manage Permissions</mat-checkbox>
                                            </div>
                                        </mat-expansion-panel> -->
                    <!-- <mat-expansion-panel >
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    Tenant Management
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div class="px-3 col-6">
                                                <mat-checkbox >Select All Permission for Tenants </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">

                                                <mat-checkbox formControlName="chkTenantCreate">Create Tenants</mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">

                                                <mat-checkbox formControlName="chkTenantEdit">Edit Tenants</mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">

                                                <mat-checkbox formControlName="chkTenantView">View Tenants</mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">

                                                <mat-checkbox formControlName="chkTenantDelete">Delete Tenants</mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">

                                                <mat-checkbox formControlName="chkTenantManageFeatures">Manage Feautures</mat-checkbox>
                                            </div>
                                        </mat-expansion-panel> -->
                    <!-- <mat-expansion-panel *ngIf = 'adminHide && tenantUserHide'>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    Organization Management
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div class="px-3 col-6">
                                                <mat-checkbox >Select All Permission for Organization </mat-checkbox>
                                            </div>

                                            <div class="px-5 col-6">

                                                <mat-checkbox formControlName="chkOrganizationCreate">Create Organization</mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">

                                                <mat-checkbox formControlName="chkOrganizationEdit">Edit Organization</mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">

                                                <mat-checkbox formControlName="chkOrganizationDelete">Delete Organization
                                                </mat-checkbox>
                                            </div>
                                            <div class="px-5 col-6">

                                                <mat-checkbox formControlName="chkOrganizationView">View Organization</mat-checkbox>
                                            </div>
                                        </mat-expansion-panel> -->
                  </mat-accordion>
                </div>
              </div>
            </form>
          </mat-card-content>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="buttonColor mr-2" (click)="savePermission()">Save</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
