<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <mat-accordion #firstAccordion="matAccordion">
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <ng-container>
                <b>Existing Patient List Search</b>
              </ng-container>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row mt-1">
            <div class="col-lg-12">
              <form [formGroup]="patientSearchForm">
                <!-- <div [hidden]="!isLoading"
                  style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
                  <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
                  </mat-progress-bar>
                </div> -->
                
                <div class="table table-responsive">
                  <table mat-table [dataSource]="patientData" class="mat-elevation-z8">
                    <ng-container matColumnDef="S.No">
                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-Sno" matInput formControlName="Sno" type="text" />
                          <mat-placeholder class="center">S.No</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element; let i=index">
                        {{ i+1 }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="Name">
                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-Name" matInput formControlName="Name" />
                          <mat-placeholder>Name</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <span matTooltip="{{element?.Name}}" matTooltipClass="custom-tooltip-font"> {{ element?.Name
                          }}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Patient Id">
                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-PatientId" matInput formControlName="PatientId" />
                          <mat-placeholder>Patient Id</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">

                        {{ element?.PatientId }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="Address">
                      <th class="header" fxFlex="200" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-Address" matInput formControlName="Address" />
                          <mat-placeholder>Address</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td fxFlex="200" mat-cell *matCellDef="let element"> <span class="ellipsis cut-text">
                          {{ element?.Address }} </span>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="SSN">
                      <th class="header  w-175" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-SSN" matInput formControlName="SSN" />
                          <mat-placeholder>SSN</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">{{ element?.SSN }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Account Number">

                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-AccountNumber" matInput formControlName="AccountNumber" />
                          <mat-placeholder>Account No</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">{{ element?.AccountNumber }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Email Address">

                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-EmailAddress" matInput formControlName="EmailAddress" />
                          <mat-placeholder>Email Address</mat-placeholder>

                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">{{ element?.EmailAddress }}</td>
                    </ng-container>
                    <ng-container matColumnDef="Date Of Birth">

                      <th class="header" class="w-125" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <mat-label> Date Of Birth </mat-label>
                          <input autocomplete="new-DateOfBirth" matInput formControlName="DateOfBirth" placeholder="(MM/DD/YYYY)" />
                          <mat-error
                            *ngIf=" patientSearchForm?.get('DateOfBirth')?.touched && patientSearchForm?.get('DateOfBirth')?.errors?.dateVaidator">
                            Enter Valid date
                          </mat-error>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">{{
                        element?.DateOfBirth }}</td>
                    </ng-container>

                    <ng-container matColumnDef="Moblie">
                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-Moblie" matInput formControlName="Moblie" />
                          <mat-placeholder>Moblie</mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">{{ element?.Moblie }}</td>
                    </ng-container>
                    <ng-container matColumnDef="LockStatus">
                      <th class="header" mat-header-cell *matHeaderCellDef>
                        <mat-form-field class="filter">
                          <input autocomplete="new-LockStatus" matInput formControlName="LockStatus" />
                          <mat-placeholder>Status </mat-placeholder>
                        </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">{{
                        element?.LockStatusWithName }}</td>
                    </ng-container>

                    <ng-container matColumnDef="noRecords">
                      <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                        <div *ngIf="!isLoading">
                          {{"No Records Found"}}
                        </div>
                        <div [hidden]="!isLoading" >
                          <mat-spinner [diameter]="220"></mat-spinner>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                      <td mat-footer-cell *matFooterCellDef colspan="9">
                        <mat-paginator class="table table-responsive" #patientPaginator
                          [length]="patientData?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                          [pageSizeOptions]="[5, 10, 25, 100]">
                        </mat-paginator>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"
                      (contextmenu)="onContextMenu($event, row)" [ngClass]="{ 'highlighted': row.highlighted}"></tr>
                    <!-- [ngClass]="{'alert alert-success': patientId , 'alert alert-danger': !patientId }"></tr> -->
                    <tr mat-footer-row *matFooterRowDef="['noRecords']"
                      [ngClass]="{ active: patientData && patientData.data && patientData.data.length !== 0 }"></tr>
                    <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </div>

  </div>
</div>
<div style="visibility: auto; position: fixed" [style.left]="contextMenuPosition?.x"
[style.top]="contextMenuPosition?.y" [matMenuTriggerFor]="contextMenu"></div>
<mat-menu #contextMenu="matMenu">
<ng-template matMenuContent let-item="item">
  <button *ngIf="!patientId" mat-menu-item (click)="onContextMenuSelectAction(item)">Select Patient</button>
  <button *ngIf="patientId" mat-menu-item (click)="onContextMenuDeselecteAction(item)">De-Select
    Patient</button>
</ng-template>
</mat-menu>
