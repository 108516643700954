<div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">

  <div class="card card-body align-items-center justify-content-center p-0">
    <!-- <h1 class="fw-bolder" [ngClass]="{ 'blink-text': isLoading || TiffError }" *ngIf="isLoading || TiffError">
      {{ loadingMessage }}
    </h1> -->

    <div *ngIf="isLoading" class="d-flex justify-content-center">
      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
    </div>


    <div *ngIf="!TiffError">
      <div class="zoom-buttons" *ngIf="!isLoading">
        <button [matTooltip]="'Clear All Annotation'" mat-raised-button color="warn"
          (click)="clearAllAnnotation()"
          [disabled]="this.textAnnotations.length === 0 || disableAnnotation"><mat-icon>restore</mat-icon></button>
        <button [matTooltip]="'Zoom In Page'" mat-button color="primary" (click)="zoomIn()"
          [disabled]="isLoading || disableAnnotation">
          <mat-icon>add</mat-icon>
        </button>
        <button [matTooltip]="'Go to First Page'" mat-button color="primary" (click)="firstImage()"
          [disabled]="isLoading || disableAnnotation">
          <mat-icon>first_page</mat-icon>
        </button>
        <button [matTooltip]="'Go to Previous Page'" mat-button color="primary" (click)="previousImage()"
          [disabled]="isLoading || disableAnnotation">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <input [matTooltip]="'Go to Custom Page'" matInput class="form-control custom-input" type="number"
          [(ngModel)]="pageNumber" min="1" max="{{totalPages}}" (focusout)="goToPage()"
          [readonly]="isLoading || disableAnnotation" />

        <span> of {{ totalPages }}</span>
        <button [matTooltip]="'Go to Next Page'" mat-button color="primary" (click)="nextImage()"
          [disabled]="isLoading || disableAnnotation">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <button [matTooltip]="'Go to Last Page'" mat-button color="primary" (click)="lastImage()"
          [disabled]="isLoading || disableAnnotation">
          <mat-icon>last_page</mat-icon>
        </button>

        <button [matTooltip]="'Zoom Out Page'" mat-button color="primary" (click)="zoomOut()"
          [disabled]="isLoading || disableAnnotation">
          <mat-icon>remove</mat-icon>
        </button>
        <button [matTooltip]="'Save Annotation'" class="defaultButton saveButton" mat-raised-button
          (click)="saveAnnotation()" [disabled]="this.textAnnotations.length === 0 || disableAnnotation">
          <mat-icon *ngIf="!disableAnnotation">save</mat-icon>

          <mat-icon *ngIf="disableAnnotation">
            <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
          </mat-icon>

        </button>

        <!-- <div class="button-container">
          <button [matTooltip]="'Save Annotation'" class="defaultButton saveButton" mat-raised-button
            (click)="saveAnnotation()" [disabled]="this.textAnnotations.length === 0 || disableAnnotation">
            <mat-icon *ngIf="disableAnnotation">save</mat-icon>
            <mat-spinner *ngIf="!disableAnnotation" diameter="30"></mat-spinner>
          </button>
        </div> -->

      </div>
    </div>
  </div>

  <div [hidden]="isHideTiffViewer"
    class="card card-body align-items-center justify-content-center scrollable-card p-0"
    style="min-height: 73vh; max-height: 73vh">
    <div class="scrollable-card-image" [hidden]="disableAnnotation">
      <!-- (dblclick)="marker($event)" -->
      <canvas #canvas (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)" (mouseover)="OnMouseOver($event)"
        (mouseup)="onMouseUp($event)" (mouseleave)="onMouseLeave($event)" (contextmenu)="deleteText($event)"
        (click)="addText($event)" ></canvas>
      <input [(ngModel)]="tiffPageNo" type="hidden" name="tiffPageNo" />
    </div>
  </div>


</div>
