<div class="container-fluid">
    <!-- <h2 class="font-weight-bolder mt-3">
        <b class="header-class">Email/Print Letter</b>
    </h2> -->
    <div class="col-12 card card-body mt-1">
        <div class="d-flex align-items-center justify-content-between">
            <div class="row px-0 ml-1">
              <!-- <button mat-icon-button class="buttonColor"> -->
              <mat-icon matTooltip="Back" type="button" (click)="backToRxReport()"
                class="bg-lightseagreen mr-2 text-center">keyboard_arrow_left</mat-icon>
              <!-- </button> -->
              <h3 class="font-weight-bolder header-font mb-0" style="margin-top: 2px">
                Email/Print Letter
              </h3>
            </div>
            <!-- </div> -->

            
         
            <!-- <div class="col-6">
                <div class="col-1"><mat-icon matTooltip="Back" type="button" (click)="backToRxReport()"
                    class="bg-lightseagreen mr-2 text-center">keyboard_arrow_left</mat-icon></div>
                <h2 class="col-5"><b>Email/Print Letter</b></h2>
            </div> -->
            <div class="text-right mb-1">
            <button (click)="previewRXDocument()" [disabled]="!isRxView"
            mat-raised-button class="buttonColor mr-2"
            ><a   [matTooltip]="'Click to view Rx Document '"   >
                <img
                class="img-responsive pr-1 mb-1 cursor"
                src="assets/Claim Page Icons/doc view.png"
                alt=""
                height="17"
              ></a>
            View RX 
            </button>
            </div>
        </div>

    <div class="card">
        <div class="card-body custom-editor">
            <form [formGroup]="EmailForm">
              <mat-form-field class="col-2">
                <mat-label>Template Search<span class="asterisk">*</span></mat-label>
                <mat-select
                (selectionChange)="selectTemaplate($event)"
                  formControlName="drpTemplate"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtTemplate"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngFor="let template of filteredTemplate | async"
                    [value]="template"
                  >
                    {{ template.templateName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="col-2">
                <mat-label>Practitioner Name <span class="asterisk">*</span> </mat-label>
                <mat-select formControlName="drpPracticeManagement" 
                  >
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                      formControlName="txtPracticeFilter"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option [value]="option.id" *ngFor="let option of filteredPractices | async">
                    {{ option.practiceName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>


                <mat-form-field class="col-2">
                  <mat-label> To Email Address<span class="asterisk">*</span></mat-label>
                  <input autocomplete="new-txtEmail" matInput maxlength="40" formControlName="txtEmail" type="text"
                  pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" [textMask]="{ mask: emailMask }" />
              
                  <mat-error *ngIf="EmailForm?.get('txtEmail')?.touched && EmailForm?.get('txtEmail')?.errors?.required ">
                    Email Address is a required field!
                  </mat-error>
                  <mat-error *ngIf="EmailForm?.get('txtEmail')?.touched && EmailForm?.get('txtEmail')?.errors?.pattern">
                    Please Enter a Valid Email Address
                  </mat-error>
                </mat-form-field>
              
                <mat-form-field class="col-2">
                  <mat-label>Subject <span class="asterisk">*</span></mat-label>
                  <input autocomplete="new-txtSubject" matInput maxlength="100" formControlName="txtSubject" type="text">
                </mat-form-field>
              
                <button mat-button class="buttonColor mb-2 mr-2" (click)="fnSendEmail()"
                  [disabled]="!(EmailForm.get('txtEmail').valid && EmailForm.get('editorContent').valid) || saveButtonHide">Send</button>
                  <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="reset()">
                    Reset
                  </button>
              
                <angular-editor formControlName="editorContent" [config]="config"></angular-editor>
              
              </form>
              
        </div>
    </div>
</div>