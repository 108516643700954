import { defaultGuid } from './../document-perviewer/document-perviewer.component';
import { PatientDTO } from 'projects/order/src/app/order-proxy/order-management/rcm/patient-management/patient/dto';
import { DemographicsDTO } from './../../../../patient/src/app/patient-proxy/patient/dto/models';
import { delay, toArray, groupBy, mergeMap, concatMap, takeLast, map } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomUrlSerializer } from 'projects/patient/src/app/custom-url-serializer';
import { PatientSearchService, PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import { baseUrl } from 'projects/patient/src/environments/environment';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { concat, defer, from, Observable, of, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AddDocumentBotcComponent } from '../add-document-botc/add-document-botc.component';
import { AutoIntakeDocumentDetailsDTO } from '../admin-proxy/platform-app-management/rcm/patient-management/patient/dto/models';
import { FileUploadService } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/file-upload.service';
import { UploadFilesService } from '../admin-proxy/platform-app-management/rcm/platform-management/upload-file';
import { IdentifiedPatientListDTO, SearchUploadFilesDTO, UploadFilesDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/upload-file/dto/models';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { IdentityUserDto } from '../admin-proxy/volo/abp/identity/models';
import { Patient } from '../approval-list/approval-list.component';

import { dateFormatter } from '../sub-batch-list/sub-batch-list.component';
import { PatientsService } from 'projects/patient/src/app/patient-proxy/patient/patients.service';
import { UploadedFileStatus } from '../admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/uploaded-file-status.enum';
@Component({
  selector: 'app-upload-bin-botc',
  templateUrl: './upload-bin-botc.component.html',
  styleUrls: ['./upload-bin-botc.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ])],
})
export class UploadBinBotcComponent implements OnInit {
  expandedElement: any | null;
  isLoading: boolean = true;
  strPageType: string = 'AdminfaxBin';
  organizationUnitId: string = '';
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  arrUsersList: IdentityUserDto[] = [];
  tenantId: string = "";
  arrDisplayedColumns: string[] = [];
  displayedColumns: string[] = ['Name', 'Patient Id', 'Email Address', 'Date Of Birth', 'LockStatus'];
  arrTableData: any[] = [];
  isShownSaveButton: boolean = false;
  isShownCreateButton: boolean = false;
  isShownDeleteButton: boolean = false;
  contextMenuPosition = { x: '0px', y: '0px' };
  patientId: string = "";
  fileApprovalStatus = UploadedFileStatus;

  isShownViewButton: boolean = false;
  batchUploadBinForm: FormGroup;
  constructor(
    private title: Title,
    private table: TableService,
    public userService: UserService,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dateValidators: DateValidator,
    private fileUploadService: FileUploadService,
    private uploadFilesService: UploadFilesService,
    private router: Router,
    private patientsService: PatientsService
  ) {


  }

  ngOnInit(): void {
    this.isLoading = true;
    this.batchUploadBinForm = this.fb.group({
      documentName: new FormControl(""),
      documentType: new FormControl(""),
      //  uploadedFrom: new FormControl(""),
      uploadedDate: new FormControl("", [this.dateValidators.dateVaidator]),
      uploadedBy: new FormControl(""),
      defaultBatchId: new FormControl(""),
      assignedByUserName: new FormControl(""),
      fileName: new FormControl(""),
      fileSize: new FormControl(""),
      numberOfPages: new FormControl(""),
      //  isStat: new FormControl(""),
      fileStatus: new FormControl(""),
      assignedToUserName: new FormControl(""),
      // noofFilesUploaded: new FormControl(""),
      // batchStatus: new FormControl(""),
    })
    this.batchUploadBinForm.valueChanges.subscribe(value => {
      const sfrmvalue = this.batchUploadBinForm.value;
      if (this.batchUploadBinForm?.valid) {
        const searchValue: SearchUploadFilesDTO = {
          defaultFileId: sfrmvalue?.defaultBatchId ?? null,
          fileName: sfrmvalue?.fileName ?? null,
          fileStatus: sfrmvalue?.fileStatus ?? null,
          fileSize: sfrmvalue?.fileSize ?? null,
          numberOfPages: sfrmvalue?.numberOfPages ?? null,
          uploadedOn: sfrmvalue?.uploadedDate ?? null,
          uploadedBy: sfrmvalue?.uploadedBy ?? null,
          assignedBy: sfrmvalue?.assignedByUserName ?? null,
          assignedTo: sfrmvalue?.assignedToUserName ?? null,
          uploadedFrom: ""
        }
        this.searchByInputs(searchValue);
      }
    })

    this.tenantId = localStorage.getItem("tenantId") ?? "";
    // this.arrDisplayedColumns = ['documentName', 'uploadedFrom', 'uploadedDate', 'uploadedBy', 'noofFilesUploaded', 'batchStatus', 'Options'];
    this.arrDisplayedColumns = ["Options","defaultBatchId", "fileName", "%completed", "isStat", "fileStatus", "fileSize",  "uploadedDate", "uploadedBy", "numberOfPages",];
    this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId') ?? "";
    this.getUsersList();
    this.table.setlstfaxBinDataTable([]);
    this.title.setTitle('Qsecure | Fax Bin');
    this.table.getfileUploadCreate().subscribe(value => {
      if (value == true) {
        this.isShownCreateButton = true;
      }
      else {
        this.isShownCreateButton = false;
      }
    });

    this.table.getfileUploadDelete().subscribe(value => {
      if (value == true) {
        this.isShownDeleteButton = true;
      }
      else {
        this.isShownDeleteButton = false;
      }
    });

    this.table.getfileUploadView().subscribe(value => {
      if (value == true) {
        this.isShownViewButton = true;
      }
      else {
        this.isShownViewButton = false;
      }
    });
  }

  //! download AutoUpload Document
  downloadAutoUploadLastDocument(blobName: string) {
    if (typeof blobName === 'string' && blobName) {
      this.uploadFilesService.getPatientDocumentByBlobNameByBlobName(blobName).subscribe((response) => {
        if (typeof response === 'string' && response && response?.length !== 0) {
          let filelocation: String = String('data:application/pdf;base64,' + response);
          let link = document.createElement('a');
          link.download = blobName + '.pdf';
          link.href = filelocation.toString();
          link.click();
        }
      },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
    }
  }
  //! Preview Document in sep window by blob name
  previewDocumentById(blobName: string) {
    // const ut: string = blobName?.replace(/\s/gi, '!~!') ?? "";
    const ut: string = blobName ?? "";

    if (typeof ut === 'string' && ut && ut !== "") {
      blobName && window.open(baseUrl + '#/ViewDocumentPerviewByBlobId/' + ut + '/' + 3, "_blank");
    }
  }

  private searchByInputs(searchValue: SearchUploadFilesDTO) {
    this.arrTableData = [];
    this.dataSource = new MatTableDataSource(this.arrTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
this.uploadFilesService.searchFilesBasedOnStatusByArchiveStatusAndOSearchUploadFiles(false,searchValue).subscribe(response => {
    //this.uploadFilesService.searchPatientsByInput(searchValue).subscribe(response => {
      let arrTableData: batchDetails[] = [];
      const faxBinDataTable: batchDetails[] = this.extractBatchTableDetails(response);
      this.arrTableData = [...faxBinDataTable];
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  // alert for reload
  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHander(event: BeforeUnloadEvent) {
  //   return false;
  // }

  getPatientIdOnselect(value: string) {
    this.patientId = value ?? "";
  }

  // get uploaded file list details
  getFaxBinTableData() {
    this.isLoading = true;
    this.arrTableData = [];
    this.dataSource = new MatTableDataSource(this.arrTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    let arrTableData: batchDetails[] = [];
    this.uploadFilesService.getUploadFilesByArchiveStatusByArchiveStatus(false).subscribe(response => {
    //this.uploadFilesService.getUploadedFileList().subscribe(response => {
      const faxBinDataTable: UploadFilesDTO[] = response;
      arrTableData = this.extractBatchTableDetails(faxBinDataTable);
      this.arrTableData = [...arrTableData];
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  // get extreaction deatils
  private extractBatchTableDetails(faxBinDataTable: UploadFilesDTO[]): batchDetails[] {
    let arrTableData: batchDetails[] = [];
    try {
      faxBinDataTable.forEach(v => {
        if (v && v !== null && !v?.archived && !v?.isDeleted) {
          let data: batchDetails = {
            id: v?.id ?? "",
            defaultBatchId: v?.defaultFileId ?? "",
            uploadedBy: this.getUserName(v?.uploadedBy ?? "") ?? "",
            // uploadedFrom: v?.uploadedFrom ?? "",
            uploadedOn: dateFormatter(v?.uploadedOn ?? "") ?? "",
            fileSize: v?.fileSize ?? "",
            fileStatus: v?.fileStatus ?? "",
            fileName: v?.fileName ?? "",
            isStat: v?.isStat ?? 0,
            numberOfPages: v?.numberOfPages ?? 0,
            assignedBy: v?.assignedBy ?? "",
            assignedTo: v?.assignedTo ?? "",
            blobName: v?.blobName ?? "",
            fileId: v?.id ?? "",
            assignedToUserName: v?.assignedToUserName ?? "",
            assignedByUserName: v?.assignedByUserName ?? "",
            percentageOfCompletion: v?.percentageOfCompletion ?? 0,
            sortingStatus: v?.sortingStatus ?? "",
            patientsId: v?.patientsId ?? "",
            overallStatus: v?.overallStatus ?? "",
            identifiedPatientList: of(v?.identifiedPatientList) ?? of([]),
            etrPtDetails: v?.extractedPatientDetails,
            reason4AppOrRej: v?.reasonForRejected ?? ""
          };

          arrTableData.push(data);
        }
      });
    } catch (error) {
      arrTableData = [];
    }
    return arrTableData;
  }
  // get Username by ID
  private getUserName(value: string) {
    if (value && typeof value === "string" && value?.length >= 36) {
      return this.arrUsersList?.filter(u => u?.id === value)[0]?.userName ?? "";
    }
    return ""
  }
  // get Users name list
  private getUsersList() {

    this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;
      this.getFaxBinTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  openAddFaxBinModal() {
    const dialogRef = this.dialog.open(AddDocumentBotcComponent, {
      minWidth: '60vw',
      minHeight: '0vh',
      maxWidth: '0vw',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getFaxBinTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  openArchivedDoc(){
    this.router.navigate(['archiveDocuments']);
  }

  archiveBatchById(value: string) {
    Swal.fire({
      title: 'Are you sure you want to Archive?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Archive it!',
    }).then(result => {
      if (result.value) {
        if (value !== "" && typeof value === "string" && value !== undefined && value !== null) {
          this.fileUploadService.updateArchiveFileBatchStatusByBatchIdAndArchived(value, true).subscribe(() => {
            this.getFaxBinTableData();
          }, err => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
        }
      }
    });
  }

  //Delete files by id
  deleteFileById(id: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.value) {
        id && this.uploadFilesService.deleteFilesByFileId(id).subscribe(response => {
          if(response && response?.includes("Can't")){
            Swal.fire({
              icon: 'info',
              text: response
            });
          }
          else{
            this.getFaxBinTableData();
          }  
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }

  archiveFileById(id: string){
    Swal.fire({
      title: 'Are you sure you want to archive?',
      //text: "You won't be able to retrieve this data!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
    }).then(result => {
      if (result.value) {
        id && this.uploadFilesService.updateArchiveStatusByFileIdAndArchiveStatus(id,true).subscribe(response => {
            this.getFaxBinTableData(); 
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }


  //! Document Viewer with Feilds side by side
  documnetViewerSideBySide(fileId?: string) {
    this.patientId = "";
    //let data: any[] = this.patientData?.data ?? [];
    //  data?.forEach(e => e.highlighted = false);
    // this.patientData.data = data;
    // this.patientData = new MatTableDataSource(data);
    // this.patientData.sort = this.Patientsort;
    // this.patientData.paginator = this.patientPaginator;
    if (fileId && this.patientId) {
      const url = '/#/ViewDocumentDetailsAndApprove/' + [fileId?.toString()] + '/' + [this.patientId?.toString()];
      window.open(url, '_blank');
    } else {
      const url = '/#/ViewDocumentDetailsAndApprove/' + [fileId?.toString()];
      window.open(url, '_blank');
    }
  }
  //! Update Document Id
  editById(fileId: string) {
    const dialogRef = this.dialog.open(AddDocumentBotcComponent, {
      minHeight: '40vh',
      minWidth: '60vw',
      data: fileId
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getFaxBinTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  //! approve patients to patient
  approvePtsToPt(element: any, fileId: string, patientsId: string, reason4AppOrRej: string, status: UploadedFileStatus.Approved | UploadedFileStatus.Rejected, patientId: string | null) {

    const reasonForApproveOrRejected = reason4AppOrRej ?? " ";
    const patientID = patientId === '' ? null : patientId;
    const strStatus: UploadedFileStatus = status;
    Swal.fire({
      title: 'Are you sure you want to ' + status + ' ?',
      text: strStatus === UploadedFileStatus.Rejected ? "You can change this anytime!" : "You won't be able to revert this change!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, ' + status + ' it!',
    }).then((result) => {
      if (result.value) {
        if (this.patientId === '') {
          if (this.pateintDemofromFormCheck(element, status)) {
            return
          }
          this.patientsService.approvePatientByFileIdAndPatientsIdAndPatientIdAndStatusAndReasonForApproveOrRejected(fileId, patientsId, patientID, strStatus, reasonForApproveOrRejected).subscribe(response => {
            Swal.fire({
              title: 'Success',
              html: status + ' Successfully',
              icon: 'success',
              timer: 3000,
              timerProgressBar: true,
            });
            this.getFaxBinTableData();
            this.patientId = '';
          }, (err: HttpErrorResponse) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
        }
        if (this.patientId !== '') {
          if (this.pateintDemofromFormCheck(element, status)) {
            return
          }
          this.patientsService.approvePatientByFileIdAndPatientsIdAndPatientIdAndStatusAndReasonForApproveOrRejected(fileId, patientsId, patientID, strStatus, reasonForApproveOrRejected).subscribe(response => {
            Swal.fire({
              title: 'Success',
              html: status + ' Successfully',
              icon: 'success',
              timer: 3000,
              timerProgressBar: true,
            });
            this.getFaxBinTableData();
            this.patientId = '';
          }, (err: HttpErrorResponse) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
        }
      }
    });
  }


  //// swal validation from form values
  pateintDemofromFormCheck(ele, status) {
    if (status == UploadedFileStatus.Approved) {
      if (ele && ele?.firstName == null || ele?.firstName == "") {
        Swal.fire("Please enter First Name in Demographics");
        return true;

      }
      if (ele && ele?.lastName == null || ele?.lastName == "") {
        Swal.fire("Please enter Last Name in Demographics");
        return true;
      }
      if (ele && ele?.dateOfBirth == null || ele?.dateOfBirth == undefined) {
        Swal.fire("Please enter Date Of Birth in Demographics");
        return true;

      }
    }

  }
}

export interface batchDetails {
  id?: string,
  defaultBatchId?: string,
  isError?: boolean,
  uploadedBy?: string,
  uploadedOn?: string,
  type?: string,
  fileSize?: string;
  fileStatus?: string;
  fileName?: string;
  isStat?: number;
  numberOfPages?: number;
  assignedBy?: string;
  assignedTo?: string;
  blobName?: string;
  fileId?: string;
  assignedToUserName?: string
  assignedByUserName?: string
  percentageOfCompletion?: number
  sortingStatus?: string
  patientsId?: string
  overallStatus?: string
  identifiedPatientList?: Observable<IdentifiedPatientListDTO[]>;
  etrPtDetails?: DemographicsDTO;
  reason4AppOrRej: string
}


