import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { OrganizationUnitDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserByRoleDTO } from '../admin-proxy/platform-app-management/identity';
import { defaultGuid } from '../document-perviewer/document-perviewer.component';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import { InboundStatusService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound-status/inbound-status.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { MasterNotesService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-notes.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
@Component({
  selector: 'app-send-to-another-user',
  templateUrl: './send-to-another-user.component.html',
  styleUrls: ['./send-to-another-user.component.scss'],
})
export class SendToAnotherUserComponent implements OnInit {
  userId: string;
  listNoteType:any;
  drpNotetypeList: any;
  isdrpLoading: boolean;
  iscsrLoading: boolean =false;
  notesText = '';
  subscription$: Subscription[] = [];
  NoteType:any;
  NoteTypeId:any;
  isShowSpinner: boolean=false;
  csrUsersList: Subscription;
  initializeWithDocId(docId: string) {
    this.docId = docId;
  }
  @Input() docId: string;
  isSaveDisable: boolean = false;
  lstUsers: UserByRoleDTO[] = [];
  CSRFilterControl = new FormControl();
  NoteTypeControl=new FormControl();
  filteredCSRList: UserByRoleDTO[];
  seletedCSRValue: string = defaultGuid;
  sendAnotherUserForm: FormGroup;
  csrdetails: string;
  defaultGuid=defaultGuid;
  constructor(
    private userService: UserService,
    private notesService: MasterNotesService,
    private fb: FormBuilder,
    private inboundStatusService: InboundStatusService,
    private toaster: ToastrService,
    public dialogRef: MatDialogRef<SendToAnotherUserComponent>,
    private communicationService: CommunicationService,
    // @Inject(MAT_DIALOG_DATA) public data: any
    @Inject(MAT_DIALOG_DATA)
    public data: {
      docId: string;
      selectedNode:any;
    }={
      docId:defaultGuid,
      selectedNode:null
    }
  ) {}

  ngOnInit(): void {
    this.loadDropdown();
    this.userId = localStorage.getItem('userId') ?? '';
    // this.CSRFilterControl.valueChanges.subscribe((dxTypeFilterText) => {
    //   dxTypeFilterText = dxTypeFilterText.toLowerCase();
    //   this.filteredCSRList = this.lstUsers.filter((dx) =>
    //     dx.userDetails.toLowerCase().includes(dxTypeFilterText)
    //   );
    // });
    this.sendAnotherUserForm = this.fb.group({
      txtComments: new FormControl('', [Validators.required,noWhitespaceValidator]),
      drpCSR: new FormControl('', [Validators.required]),
      drpNotetype: new FormControl('',[Validators.required]),
      txtNoteType:new FormControl(''),
      txtCSR:new FormControl(''),
    });
    this.NoteTypeControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpNotetypeList = this.listNoteType.filter((dx) =>
        dx.noteType.toLowerCase().includes(FilterText)
      );
    });

    if (this.data) {
      this.docId = this.data?.docId ?? defaultGuid;
    }
  }

  loadDropdown() {
    this.isdrpLoading = true;
    this.iscsrLoading = true;
    const csrUsersList = this.userService
      .getCSRUsers('')
      .subscribe((response) => {
        // this.isdrpLoading = false;
        this.iscsrLoading = false;
        this.lstUsers = response;
        this.filteredCSRList = this.lstUsers;
      },
      (err) => {
        // this.isdrpLoading = false;
        this.iscsrLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        // this.subscription$.push(NoteType);
      }
    );
        const NoteType = this.notesService
        // .getListV1(new PagedAndSortedResultRequestDto())
        this.notesService.getDrpNoteTypes().subscribe(
            (response) => {
              this.isdrpLoading = false;
              // this.iscsrLoading = false;
              this.listNoteType=response;
              this.drpNotetypeList=this.listNoteType;
            },
            (err) => {
              this.isdrpLoading = false;
              // this.iscsrLoading = false;
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
              // this.subscription$.push(NoteType);
            }
          );
      }
  Reset() {
    this.sendAnotherUserForm.patchValue({
      txtComments: '',
      drpCSR: '',
      drpNotetype:''
    });
    this.sendAnotherUserForm.reset();
    this.UserSearch('');
  }
  UserSearch(value){
    this.iscsrLoading = true;
    this.lstUsers =[];
    this.filteredCSRList=[];
    if(this.csrUsersList){
      this.csrUsersList.unsubscribe();
    }
    this.csrUsersList = this.userService
      .getCSRUsers(value)
      .subscribe((response) => {
        // this.isdrpLoading = false;
        this.iscsrLoading = false;
        this.lstUsers = response;
        this.filteredCSRList = this.lstUsers;
      },
      (err) => {
        // this.isdrpLoading = false;
        this.iscsrLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        // this.subscription$.push(NoteType);
      }
    );
    }
    clearUser(event: Event) {
      event.stopPropagation();
      this.sendAnotherUserForm.patchValue({
        drpCSR: '',
      });
      this.UserSearch('');
    }
  csrDetailsSave(seletedCSRValue: string, comments: string) {
    this.isSaveDisable=true;
    this.isShowSpinner = true;
   this.NoteType=this.sendAnotherUserForm.value.drpNotetype.split('/')[1];
   this.NoteTypeId=this.sendAnotherUserForm.value.drpNotetype.split('/')[0];
      this.inboundStatusService
        .csrDetailsSave(comments,this.NoteTypeId,this.NoteType, this.docId, seletedCSRValue)
        .subscribe((response) => {
          this.csrdetails = response;
          this.toaster.success('Updated Successfully', 'Success');
          this.communicationService.triggerFunctionCall(this.data.selectedNode);
          // this.dialogRef.close();
          this.dialogRef.close(response);
          this.isSaveDisable=false;
          this.isShowSpinner = false;
        },
         (err)=> {
          const data: HttpErrorResponse = err;
          const errorMessageObject = JSON.parse(err.error);
          const specificErrorMessage = errorMessageObject.error.message;
          if(data?.error){
            Swal.fire({
              icon: 'info',
              text: specificErrorMessage,
            });
          this.isSaveDisable=false;
          this.isShowSpinner = false;
        }else{
          this.toaster.success('Updated Successfully', 'Success');
          this.communicationService.triggerFunctionCall(this.data.selectedNode);
          this.dialogRef.close();
          this.isSaveDisable=false;
          this.isShowSpinner = false;
        }
     });
  }

    //Update Character Count for TextArea
    updateCharacterCount(maxlength: number) {
      // Ensure the text length does not exceed the maximum length
      if (this.notesText?.length > maxlength) {
        this.notesText = this.notesText?.substr(0, maxlength);
      }
    }

}
