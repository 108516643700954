import { FlatTreeControl } from '@angular/cdk/tree';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import {
  customErrorMessage,
  defaultGuid,
} from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import {
  DiagnosisCode10Service,
  PatientDropdowns,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import {
  BranchOfficeDTO,
  DiagnosisCode10DTO,
  DocumentTypeDTO,
  GenderDTO,
  MachineTypeDTO,
  ReviewReasonDTO,
  ReviewStatusDTO,
  SortingTypeDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientDocumentService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import {
  DocumentGroupDTO,
  PatientDocumentBlobDTO,
  UpdateDocumentBlobDTO,
  CreateUpdateMedicareDocDTO,
  CreatePatientDocumentBlobDTO,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import {
  ComplainceDTO,
  DemographicsDTO,
  DiagnosticSleepStudyDTO,
  HomeSleepTestDTO,
  InitialFaceToFaceDTO,
  PatientDTO,
  PatientDocumentDTO,
  PatientsDTO,
  PrescriptionDTO,
  SavePatientDocumentBlobDTO,
  SleepStudyDTO,
  TitrationSleepStudyDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto';
import { ToastrService } from 'ngx-toastr';
import {
  DocumentMaxandTypeLimits,
  DocumentUploadTypes,
} from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { combineLatest, defer, from, Observable, of, Subscription } from 'rxjs';
import {
  concatMap,
  groupBy,
  map,
  mergeMap,
  reduce,
  startWith,
  takeLast,
  tap,
  toArray,
} from 'rxjs/operators';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PatientDoctorComponent } from 'projects/patient/src/app/patient-doctor/patient-doctor.component';
import { MatDialog } from '@angular/material/dialog';
import { DoctorService } from 'projects/patient/src/app/patient-proxy/patient';
import { doctorInterface } from 'projects/billing/src/app/billing-claim-info/billing-claim-info.component';
import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { MatSelect } from '@angular/material/select';
import { NumberValidators } from '../../validations/number-validator';
import { TextValidators } from '../../validations/text-validator';
import { baseUrl } from 'projects/patient/src/environments/environment';
import { PatientIcd10Component } from 'projects/patient/src/app/patient-icd10/patient-icd10.component';
import { planInterface } from 'projects/patient/src/app/patient-add-policy/patient-add-policy.component';
import {
  AuthCategoryDTO,
  AuthVisitsDTO,
  TypeOfPlanDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order';
import { SortedData } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/upload-file/dto';
import { DXTypes } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/dxtypes.enum';
import { SetupTypes } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/setup-types.enum';
import { SortedDataDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/file-upload/dto';
import { MasterRulesDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/tenants/dto';
import { ReminderService } from 'projects/admin/src/app/dashboard/reminder.service';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { MasterRulesService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/tenant';
import {
  DocumentGroupDTO1,
  DocumentGroupIngListType,
  DocumentListType,
} from '../patient-common-document-tab/patient-common-document-tab.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentBinService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/document-bin/document-bin.service';
import { DocumentClassificationDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { PDFDocument } from 'pdf-lib';
import { CommunicationService } from '../../services/communication.service';
// import type {
//   CreatePatientDocumentBlobDTO,
//   CreateUpdateMedicareDocDTO,
//   DocumentGroupDTO,
//   PatientDocumentBlobDTO,
//   UpdateDocumentBlobDTO,
// } from './dto/models';

let listOfDocuments: PatientDocumentBlobDTO[] = [];
@Component({
  selector: 'app-patient-common-document-medicare-upload-tab',
  templateUrl: './patient-common-document-medicare-upload-tab.component.html',
  styleUrls: ['./patient-common-document-medicare-upload-tab.component.scss'],
})
export class PatientCommonDocumentMedicareUploadTabComponent implements OnInit {
  //#region Declarations
  @Output() saveDocumentFormState: EventEmitter<any> = new EventEmitter<any>();
  @Output() patientIdEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> =
    new EventEmitter<string>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatAccordion, { static: false }) accordion: MatAccordion;

  @ViewChild(MatSort, { static: false }) mergeSort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) mergePaginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  panelOpenState: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  mergeDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  arrUnsortedDocumentList: PatientDocumentBlobDTO[] = [];
  arrMergeDocumentList: PatientDocumentBlobDTO[] = [];
  arrSortedDocumentList: DocumentListType[] = [];
  drpReviewStatusLoop: ReviewStatusDTO[] = [];
  documentGroupedByType: Partial<DocumentGroupIngListType>[] = [];
  documentGroup: DocumentGroupDTO[] = [];
  drpReviewReasonLoop: ReviewReasonDTO[] = [];
  drpBranchLoop: BranchOfficeDTO[] = [];
  drpDocumentUploadType: SortingTypeDTO[] = [];
  drpIsActiveStatus: any[] = [];
  subscription$: Subscription[] = [];
  patientChartApiSubscription: Subscription;
  @Input() patientId: string = defaultGuid;
  constructorpatientId: string = defaultGuid;
  isShowProcessBar: boolean = false;
  myDate = new Date();
  isNewPatient: any;
  tenantId: string = defaultGuid;
  constructortenantId: string = defaultGuid;
  selectedWipId: string;
  document: string;
  documentForm: FormGroup;
  documentSearchForm: FormGroup;
  show: boolean = false;
  isEditBtn: boolean = false;
  showErrorForFileLength: boolean = false;
  showErrorForFileType: boolean;
  showExtensionInfo: string = '';
  showErrorForExtension: boolean = false;
  isDocumentForButtonDisable: boolean = false;
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';
  filesname: string[] = [];
  intCheckedDocuments: number = 0;
  isDocumentEditSaveButtonHidden: boolean = true;
  @Input() strPdfSource: string = '';
  isShowSpinner: boolean = false;
  strSelectedPdfPath: string = '';
  selectedDocumentforUpdate: Partial<PatientDocumentDTO> = null;
  patientDocumentId: string = '';
  parentPatientDocumentID: string = '';
  activeNode: any;
  @Input() viewDocViewer: boolean = true;
  documentTypeFilterControl = new FormControl();
  documentClassification: DocumentClassificationDto[] = [];
  filteredDocumentTypeList: DocumentClassificationDto[] = [];
  filteredPatients: Observable<{ guid: string; idAndNameAndDOB: string }[]> =
  of([]);
  lstAllPatientIds: { guid: string; idAndNameAndDOB: string; }[];
  isPatientList: any;
  lstChartNos: any[] = [];
  isDisabled:boolean=false;
  saveButton: boolean=false;
  //#endregion Declarations
  isShowSaveSpinner:boolean = false;
  isPdfLoad: boolean = true;
  eobBlobName: any;
  newFilesForUpload: File;
  isSelectBrowse:boolean=false;
  selectedFileName: string ='No file chosen';
  blobFileName: string='';
  strPdfString: string;
  lstPatientChartNos:any;
  selectFileName:string;
  selectBlobName:string;
  selectpages:number = 0;
  selectedFilePathPDF:string;
  private faxUploadAPICall: Subscription;
  constructor(
    private fb: FormBuilder,
    private patientDropdownService: PatientMasterDropdownService,
    private table: TableService,
    private patientDocumentService: PatientDocumentService,
    private toastr: ToastrService,
    private patientPersonalService: PatientPersonalService,
    private documentBindService: DocumentBinService,
    public dialog: MatDialog,
    private mmOrderService: MmOrderService,
    private communicationService: CommunicationService,
    @Inject(MAT_DIALOG_DATA) public data:
     {
      constructorpatientId:string,
      constructortenantId:string,
      selectedWipId:string,
      isPatientList:boolean,
      isPatientDisable?:boolean,
    }={
   constructorpatientId :defaultGuid,
    constructortenantId :defaultGuid,
    selectedWipId : defaultGuid,
    isPatientList:false,
    isPatientDisable:false,
  }){}

  ngOnInit(): void {
    this.createDocumentForm();
    this.constructortenantId = localStorage.getItem('tenantId');
    this.documentTypeFilterControl.setValue('');
    this.documentTypeFilterControl.valueChanges.subscribe(
      (dxTypeFilterText) => {
        dxTypeFilterText = dxTypeFilterText.toLowerCase(); // Convert filter text to lowercase
        this.filteredDocumentTypeList = this.documentClassification.filter(
          (dx) => dx.documentType.toLowerCase().includes(dxTypeFilterText)
        );
      }
    );
    this.loadPatientDropdown();
    this.searchPatient('',this.data.constructorpatientId)
    // if(this.data.isPatientList  ){
    //   if(this.data.constructorpatientId !==defaultGuid){
    //   this.documentForm.patchValue({
    //     drpSearchPatient:this.data.constructorpatientId
    //   });
    //   this.documentForm?.controls?.drpSearchPatient?.setValidators([Validators.required]);
    //    this.isDisabled=true;
    // }else{
    //   this.documentForm?.controls?.drpSearchPatient?.setValidators([Validators.required]);
    // }
    // }
  }

  ngAfterViewInit() {
    this.getDropdown();
    // this.dataSource.paginator = this.paginator;
  }

  createDocumentForm() {
    this.documentForm = this.fb.group({
      drpReviewStatus: new FormControl(null),
      drpReviewReason: new FormControl(null),
      documentFile: new FormControl(null),
      drpDocumentType: new FormControl(null, [Validators.required]),
      drpSearchPatient:new FormControl(null),
      drpPatient:new FormControl(this.data.constructorpatientId, [Validators.required]),
      //  drpDocumentUploadType: new FormControl(null),
      txtDocumentStartDate: new FormControl(''), // [Validators.required]
      txtDocumentEndDate: new FormControl(''), // [Validators.required]
      txtNote: new FormControl(''),
      txtPatientFilter:new FormControl(null),
      fileUpload: ['', Validators.required],
    });
  }
  getDropdown(): void {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      // PatientDropdowns.DocumentTypes,
      PatientDropdowns.ReviewStatus,
      PatientDropdowns.ReviewReasons,
    ];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (response) => {
          if (response && response.reviewStatuses && response.reviewReasons) {
            this.drpReviewStatusLoop = response.reviewStatuses;
            this.drpReviewReasonLoop = response.reviewReasons;
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        },
        () => {}
      );

    const documentBinTypes = this.documentBindService
      // .documentTypeList()
      .getDocumentBinBasedOnFilterByStatusFilter(1)
      .subscribe(
        (response) => {
          if (response) {
            this.documentClassification = response.map((value) => ({
              docTypeId: value.id,
              documentType: value.documentType.toUpperCase(),
              documentPages: [],
              fileCount: 0,
            }));
            this.filteredDocumentTypeList = [...this.documentClassification];
            this.filteredDocumentTypeList.sort((a, b) => a.documentType.localeCompare(b.documentType));
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });
        }
      );
  }
  searchPatient(value: any,patientId:string) {
    this.loadPatientChartNos(value,patientId);
  }

      //To Load the Chart No of the Patients
      loadPatientChartNos(searchParameter,patientId) {
        if (this.patientChartApiSubscription) {
          this.patientChartApiSubscription.unsubscribe();
        }
        this.patientChartApiSubscription = this.mmOrderService
          // .getPatientChartNoV2BySSearchParams(searchParameter)
          .getPatientChartNoV3BySSearchParamsAndPatient(searchParameter,patientId)
          .subscribe(
            (response) => {
              this.lstChartNos = response;
              this.lstPatientChartNos=this.lstChartNos;

              this.documentForm.patchValue({
                drpPatient:this.data.constructorpatientId
              })
              return this.lstChartNos;
            },
            (err) => {}
          );
      }
  //Get Uploaded File Name
  getfilename(files: FileList): void {
    this.filesname = [];
    if (files.length >= DocumentMaxandTypeLimits.documentMaxNoFileLimit) {
      // Handle the case when there are more than 11 files.
      // You can return or perform any other action here.
      this.showErrorForFileLength = true;
      this.showErrorForFileType = false;
      this.filesname = [];
      let count = DocumentMaxandTypeLimits.documentMaxNoFileLimit - 1;

      this.toastr.warning(
        'Too many files. Maximum allowed is ' + count + '.',
        'warning'
      );
      return;
    }
    for (let index = 0; index < files.length; index++) {
      if (files[index].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
        this.showErrorForFileType = true;
        this.showErrorForFileLength = false;
        this.filesname = [];
        this.toastr.warning('PDF Only allowed.', 'warning');
        return;
      }
      this.filesname.push(files[index].name);

      // if (files[index].name.length > 15) {
      //   this.filesname.push(files[index].name.slice(0, 20) + '...');
      // } else {
      //   this.filesname.push(files[index].name);
      // }
    }
    this.intCheckedDocuments = files.length;
    this.isDocumentEditSaveButtonHidden = false;
    this.saveButton=false;
   // this.documentForm.enable();
  }
  //Save Documents Manual Upload
  // saveManualDocuments(
  //   fileResult: { name: File; value: string; savedOrNot: boolean }[],
  //   startDate?: string,
  //   endDate?: string
  // ) {
  //   let documentArrayToBeSaved: {
  //     patientDocumentBlobDTO: SavePatientDocumentBlobDTO;
  //     patientObjectID: string;
  //   }[] = [];

  //   fileResult.forEach((file, index) => {
  //     if (!file.savedOrNot) {
  //       let savePatientDocumentBlobDTO: SavePatientDocumentBlobDTO = {
  //         isVerified: 1,
  //         patientID: this.constructorpatientId, //this.patientId,
  //         fileName: file.name.name,
  //         fileContentAsBase64String: file.value,
  //         documentTypeId: this.constructorpatientId, // this.patientId, //this.patientId
  //         documentUploadType: DocumentUploadTypes.ManualUploadType,
  //         parentPatientDocumentID: null ?? null,
  //         documentOrderNumber: index,
  //         documentStartDate: startDate,
  //         documentEndDate: endDate,
  //         TenantId: this.constructortenantId, // this.tenantId,
  //         docStatus: '',
  //         //documentType: this.drpDocumentTypeLoop.filter(v => {return v?.documentType ===this.documentForm?.get("drpDocumentType")?.value; })[0]?.documentType,
  //         documentType:
  //           this.documentForm?.get('drpDocumentType')?.value == null
  //             ? ''
  //             : this.documentForm?.get('drpDocumentType')?.value,
  //         reviewStatus:
  //           this.documentForm?.get('drpReviewStatus')?.value == null
  //             ? ''
  //             : this.documentForm?.get('drpReviewStatus')?.value,
  //         reviewReason:
  //           this.documentForm?.get('drpReviewReason')?.value == null
  //             ? ''
  //             : this.documentForm?.get('drpReviewReason')?.value,
  //         note: this.documentForm?.get('txtNote')?.value,
  //         isSorted: 0,
  //       };

  //       if (this.parentPatientDocumentID !== '') {
  //         savePatientDocumentBlobDTO = {
  //           ...savePatientDocumentBlobDTO,
  //           parentPatientDocumentID: [this.parentPatientDocumentID],
  //         };
  //       }
  //       let documentToBeSaved = {
  //         patientDocumentBlobDTO: savePatientDocumentBlobDTO,
  //         patientObjectID: this.constructorpatientId,
  //       };
  //       documentArrayToBeSaved.push(documentToBeSaved);
  //     }
  //   });

  //   // return false;


  //   from(documentArrayToBeSaved)
  //     .pipe(
  //       tap(() => {
  //         this.isShowSpinner = true;
  //       }),
  //       groupBy((v) => v.patientObjectID),
  //       mergeMap((group) => group.pipe(toArray())),
  //       mergeMap((group) =>
  //         from(group).pipe(
  //           concatMap((v) =>
  //             defer(() =>
  //               // this.patientDocumentService.postByInputAndPatientID(
  //               this.patientDocumentService.insertPatientDocument(
  //                 v.patientDocumentBlobDTO,
  //                 v.patientObjectID
  //               )
  //             )
  //           )
  //         )
  //       ),
  //       takeLast(1),
  //       map(() => {
  //         this.parentPatientDocumentID = '';
  //         this.getDocumentsByPatientId();
  //         this.getDocumentGroup();
  //       })
  //     )
  //     .subscribe(
  //       () => {
  //         this.table.setDocumentFormData(null);
  //         this.isShowSpinner = false;
  //         // Swal.fire({
  //         //   title: 'Success',
  //         //   html: 'Saved Successfully',
  //         //   icon: 'success',
  //         //   timer: 3000,
  //         //   timerProgressBar: true,
  //         // });
  //         this.toastr.success('Saved Successfully', 'Success');
  //         this.resetDocumentForm();
  //         this.saveDocumentFormState.emit(this.documentForm.value);
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         this.isShowSpinner = false;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  // }
  saveManualDocumentsV1(
    fileResult: { name: File; value: string; savedOrNot: boolean }[],
    startDate?: string,
    endDate?: string
  ) {   this.saveButton=true;
    let documentArrayToBeSaved: {
      patientDocumentBlobDTO: CreatePatientDocumentBlobDTO;
      patientObjectID: string;
    }[] = [];

    let selectedDocumentTypeId =this.documentForm?.get('drpDocumentType')?.value == null
              ? ''
              : this.documentForm?.get('drpDocumentType')?.value;

    let selectedDocumentType = this.filteredDocumentTypeList.find(
      (document) => document.docTypeId === selectedDocumentTypeId
    )?.documentType;

    fileResult.forEach((file, index) => {
      const docInfo=this.documentForm.value;
     if(this.data.isPatientList){
      this.data.constructorpatientId=docInfo.drpSearchPatient;
     }
      if (!file.savedOrNot) {
        let savePatientDocumentBlobDTO: CreatePatientDocumentBlobDTO = {
          faxId: defaultGuid,
          patientId:  this.data.constructorpatientId,
          documentId: '',
          fileContentAsBase64String: file.value,
          blobName: file.name.name,
          fileName: file.name.name,
          documentTypeId:selectedDocumentTypeId,
          reviewStatus:
            this.documentForm?.get('drpReviewStatus')?.value == null
              ? ''
              : this.documentForm?.get('drpReviewStatus')?.value,
          reviewReason:
            this.documentForm?.get('drpReviewReason')?.value == null
              ? ''
              : this.documentForm?.get('drpReviewReason')?.value,
          documentStartDate: startDate,
          documentEndDate: endDate,
          tenantId: this.constructortenantId,
          wipId: this.data.selectedWipId ? this.data.selectedWipId : null,
          note: this.documentForm?.get('txtNote')?.value,
          docStatus: '',
          documentType:selectedDocumentType,
          isVerified: 1,
          documentUploadType: DocumentUploadTypes.ManualUploadType,
          // uploadType: "Manual"


          // fileContentAsBase64String: file.value,
          // this.patientId, //this.patientId

          // parentPatientDocumentID: null ?? null,
          // documentOrderNumber: index,
          // this.tenantId,

          //documentType: this.drpDocumentTypeLoop.filter(v => {return v?.documentType ===this.documentForm?.get("drpDocumentType")?.value; })[0]?.documentType,

          // isSorted: 0,
        };

        // if (this.parentPatientDocumentID !== '') {
        //   savePatientDocumentBlobDTO = {
        //     ...savePatientDocumentBlobDTO,
        //     parentPatientDocumentID: [this.parentPatientDocumentID],
        //   };
        // }
        let documentToBeSaved = {
          patientDocumentBlobDTO: savePatientDocumentBlobDTO,
          patientObjectID:this.data.constructorpatientId,
        };
        documentArrayToBeSaved.push(documentToBeSaved);
      }
    });

    // return false;


    from(documentArrayToBeSaved)
      .pipe(
        tap(() => {
          this.isShowSpinner = true;
        }),
        groupBy((v) => v.patientObjectID),
        mergeMap((group) => group.pipe(toArray())),
        mergeMap((group) =>
          from(group).pipe(
            concatMap((v) =>
              defer(() =>
                // this.patientDocumentService.postByInputAndPatientID(
                this.patientDocumentService.insertPatientDocument(
                  v.patientDocumentBlobDTO,
                  v.patientObjectID
                )
              )
            )
          )
        ),
        takeLast(1),
        map(() => {
          this.parentPatientDocumentID = '';
          this.getDocumentsByPatientId();
          this.getDocumentGroup();
        })
      )
      .subscribe(
        () => {
          this.table.setDocumentFormData(null);
          this.isShowSpinner = false;
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Saved Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Saved Successfully', 'Success');
          this.resetDocumentForm();
          this.isDisabled=false;
          this.saveDocumentFormState.emit(this.documentForm.value);
          this.dialog.closeAll();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.isShowSpinner = false;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.saveButton=false;
        }
      );
  }

  //Reset Button
  resetDocumentForm(): void {
    this.show = true;
    this.isPdfLoad = true;
    this.isSelectBrowse = true;
    this.viewDocViewer = true;
    this.strPdfString = null;
    this.documentForm.reset();
    this.selectedFileName='No file chosen';
    this.documentForm.patchValue({
      drpPatient:this.data.constructorpatientId
    })
  }

  patientDetails: PatientDocumentBlobDTO[] = [];
  //To Get the Uploaded Documents by Patient Id
  getDocumentsByPatientId(): void {
    this.isShowProcessBar = true;
    if (
      this.data.constructorpatientId !== '' &&
      this.data.constructorpatientId !== null &&
      this.data.constructorpatientId !== undefined
    ) {
      const patientGet = this.patientDocumentService
        .getByPatientId(this.data.constructorpatientId)
        .subscribe(
          (response) => {
            this.patientDetails = response;
            this.getDocumentsListByPatientId(this.patientDetails);
          },
          (err) => {
            this.isShowProcessBar = false;
            const data: HttpErrorResponse = err;
            // Swal.fire({
            //   icon: 'info',
            //   text: data?.error?.error?.message,
            // });
          }
        );
      this.subscription$.push(patientGet);
    }
  }

  //To Get the Document List
  getDocumentsListByPatientId(patientDetails: PatientDocumentBlobDTO[]): void {
    this.arrUnsortedDocumentList = [];
    this.arrMergeDocumentList = [];
    patientDetails?.forEach((element: PatientDocumentBlobDTO) => {
      element = this.extractShortNameAndDate(element);
    });
    this.documentGrouping();
    this.documentGroupingFunction();
    listOfDocuments = patientDetails;
    this.isShowProcessBar = false;
  }
  // documentGrouping
  documentGrouping(): void {
    const docGrouping = this.patientDocumentService
      .getDocumentGroupByPatientId(this.data.constructorpatientId)
      .subscribe((e) => {
        this.documentGroup = e;
      });
    this.subscription$.push(docGrouping);
  }

  //To get Extract the Short Name and Date(Format Table Data)
  private extractShortNameAndDate(
    element: PatientDocumentBlobDTO
  ): PatientDocumentBlobDTO {
    let uploadType: DocumentUploadTypes;
    let resultDocStartDate: string = '';
    let resultDocEndDate: string = '';
    this.tenantId = element?.tenantId ?? defaultGuid;
    const filename: string =
      element?.fileName?.toLowerCase()?.replace('/(XP[a-z]th)|(.pdf)/gi', '') ??
      '';
    if (typeof element?.documentStartDate === 'string') {
      const date = `${new Date(element?.documentStartDate)?.toLocaleDateString(
        'en-US'
      )}`;
      const hours = `${new Date(element?.documentStartDate)?.getHours()}`;
      const time = `${new Date(element?.documentStartDate)?.getMinutes()}`;
      resultDocStartDate = date + '  ' + hours + ':' + time;
    }
    if (typeof element?.documentEndDate === 'string') {
      const date = `${new Date(element?.documentEndDate)?.toLocaleDateString(
        'en-US'
      )}`;
      const hours = `${new Date(element?.documentEndDate)?.getHours()}`;
      const time = `${new Date(element?.documentEndDate)?.getMinutes()}`;
      resultDocEndDate = date + '  ' + hours + ':' + time;
    }
    uploadType = DocumentUploadTypes.ManualUploadType;
    element = {
      fileName: filename,
      documentUploadType: uploadType,
      documentStartDate: resultDocStartDate,
      documentEndDate: resultDocEndDate,
      docStatus:
        new Date(resultDocEndDate) >= this.myDate ? 'Active' : 'Expired',
      ...element,
    };
    this.arrUnsortedDocumentList.push(element);
    this.dataSource = new MatTableDataSource(this.arrUnsortedDocumentList);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    return element;
  }
  //Grouping the Documents by Document Type Wise
  documentGroupingFunction(): void {
    this.documentGroupedByType = [];
    const groupedDocument$ = from(this.arrUnsortedDocumentList).pipe(
      groupBy((v) => v.documentTypeId),
      mergeMap((group) =>
        group.pipe(
          reduce(
            (
              acc: Partial<DocumentGroupIngListType>,
              cur: PatientDocumentBlobDTO
            ) => {
              acc?.groupItem?.push(cur);
              return acc;
            },
            {
              documentType: group.key,
              documentGroupedByType: this.documentClassification.filter(
                (v) => v?.documentType === group.key
              )[0]?.documentType,
              groupItem: [],
            }
          )
        )
      ),
      toArray()
    );
    const combinedData$ = combineLatest([
      groupedDocument$,
      from(this.documentClassification),
    ]).subscribe(
      (v) => {
        this.documentGroupedByType = v[0];
      },
      (err) => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'info',
        //   text: data?.error?.error?.message,
        // });
      }
    );
    this.subscription$.push(combinedData$);
  }

  getDocumentGroup() {
    const newValue = this.patientDocumentService
      .getDocumentGroupByPatientId(this.data.constructorpatientId)
      .subscribe((response) => {
        const ex: DocumentGroupDTO1[] = response?.map((x, i) => {
          let documentDetail: any[] = response[i].documentDetail?.map((e) => {
            let echDeatils = {
              documentType: e.fileName,
              documentCount: x.documentCount,
              blobName: e.blobName,
              fileName: e.blobName,
              documentId: e.documentId,
              uploadedDate: e.uploadedDate,
            };

            return echDeatils;
          });
          let e = {
            documentType: x.documentType,
            documentCount: x.documentCount,
            documentDetail: documentDetail,
          };
          return e;
        });
        // this.dataSource1.data = ex;
        // this.mainTree = this.dataSource1._flattenedData.value;

        // // for (const item of this.dataSource1._flattenedData.value) {
        // this.dataSource1._flattenedData.value.forEach((item, index) => {
        //   if (item.expandable) {
        //     this.currentKey = item.name.toLowerCase().replace(/\s+/g, '');
        //     this.full_name = item.name;
        //   } else {
        //     if (this.currentKey) {
        //       if (!this.childTree[this.currentKey]) {
        //         this.childTree[this.currentKey] = [];
        //         if (!this.keysToExclude.includes(this.currentKey)) {
        //           this.childDocType.push(this.currentKey);
        //           this.sfull_name.push(this.full_name);
        //         }
        //       }
        //       this.childTree[this.currentKey].push(item);
        //     }
        //   }
        // });
      });

    this.subscription$.push(newValue);
  }

  //Save Button Click
  upload(files: any) {
    const description = this.documentForm?.get('txtNote')?.value;
    const startDateValue = this.documentForm?.get(
      'txtDocumentStartDate'
    )?.value;
    const endDateValue = this.documentForm?.get('txtDocumentEndDate')?.value;
    const startDate: string | null =
      startDateValue === ''
        ? null
        : String(new Date(startDateValue).toISOString());
    const endDate: string | null =
      endDateValue === '' ? null : String(new Date(endDateValue).toISOString());

    if (
      this.patientDocumentId === '' &&
      this.selectedDocumentforUpdate === null
    ) {
      if (files?.length === 0) {
        return;
      }
      let fileResult: { name: File; value: string; savedOrNot: boolean }[] = [];
      for (let file of files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          fileResult.push({
            name: file,
            value: reader?.result?.slice(28)?.toString() ?? '',
            savedOrNot: false,
          });
          if (fileResult.length === files.length) {
            this.table.setDocumentFormData({
              value: this.documentForm.value,
              filenames: this.filesname,
            });
            // this.saveManualDocuments(fileResult, startDate, endDate);
            this.saveManualDocumentsV1(fileResult, startDate, endDate);
          }
        };
      }
    } else {
      this.isShowSpinner = true;
      const UpdateDetails: UpdateDocumentBlobDTO = {
        note: description,
        documentStartDate: startDateValue,
        documentEndDate: endDateValue,
        documentType: this.documentForm?.get('drpDocumentType')?.value,
        tenantId: this.tenantId,
        reviewReason: this.documentForm?.get('drpReviewReason')?.value,
        reviewStatus: this.documentForm?.get('drpReviewStatus')?.value,
      };
      this.patientDocumentService
        .putByInputAndDocumentId(UpdateDetails, this.patientDocumentId)
        .subscribe(
          () => {
            this.isShowSpinner = false;

            // Swal.fire({
            //   title: 'Success',
            //   html: 'Updated Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Updated Successfully', 'Success');
            this.getDocumentGroup();
            this.resetDocumentForm();
          },
          (err) => {
            this.isShowSpinner = false;

            const data: HttpErrorResponse = err;
            // Swal.fire({
            //   icon: 'info',
            //   text: data?.error?.error?.message,
            // });
          }
        );
    }
  }
  loadPatientDropdown(){
     //Load Patient List Dropdown
     const searchPatientSub = this.patientPersonalService
     .patientSearchBySDefaultPatientIdAndSPatientNameAndDtDOBAndSSSNAndSEmailIdAndSMobileAndSStatus(
       '',
       '',
       '',
       '',
       '',
       '',
       '',
       1
     )
     .subscribe((response) => {
       this.lstAllPatientIds = response.map((x) => {
         const dateOptions: Intl.DateTimeFormatOptions = {
           year: 'numeric',
           month: '2-digit',
           day: '2-digit',
         };
         //date in MM/DD/YYYY FORMAT
         const dob =
           (x?.dob ?? '') === ''
             ? ''
             : new Date(x?.dob)?.toLocaleDateString('en-US', dateOptions) ??
               '';
         let y: { guid: string; idAndNameAndDOB: string } = {
           guid: x?.patientId,
           idAndNameAndDOB: x?.chartId + ' - ' + x?.patientName + ' - ' + dob,
           ...x,
         };
         return y;
       });

       this.filteredPatients = this.documentForm
         ?.get('txtPatientFilter')
         ?.valueChanges?.pipe(
           startWith(''),
           map((value) =>
             this.lstAllPatientIds.filter((option) =>
               option?.idAndNameAndDOB
                 ?.toLowerCase()
                 ?.includes(value?.toLowerCase() ?? '')
             )
           )
         );
     });
   this.subscription$.push(searchPatientSub);
  }

  deleteDocument(e) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const index = this.filesname.indexOf(e);
        if (index !== -1) {
          this.filesname.splice(index, 1);
          this.saveButton=true;
        }
      }
    });
  }


  //BroWse On Change Upload
  onFileChange(event: any): void {

    this.isSelectBrowse=false;
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    this.newFilesForUpload = file;
    if (file) {
      const fileName = file.name;
      this.blobFileName = fileName;
      // this.selectedFileName = fileName;
    } else {
      this.blobFileName = 'default.pdf';
      // this.selectedFileName = 'NA';
    }

    // Check if a file was selected and if it is a valid PDF
    if (
      file &&
      (file.type === 'application/pdf' ||
        file.name.toLowerCase().endsWith('.pdf'))
    ) {
      this.isPdfLoad = false; // Hide the PDF viewer initially

      this.getPDFPageCount(file)
        .then((pageCount) => {
          if (pageCount > 0) {
            const reader = new FileReader();
            reader.onload = () => {
              const base64String = btoa(reader.result as string); // Encode using btoa()
              this.strPdfString = base64String;
              // setTimeout(() => (this.isPdfLoad = true), 500);
              target.value = '';
            };
            reader.readAsBinaryString(file); // Read as binary string for proper encoding
            this.selectedFileName = file.name;

            this.onChangeFileBrowse();

          } else {
            this.toastr.warning(
              'Error loading PDF file. Please try again.',
              'Warning'
            );
            this.selectedFileName='No file chosen'
            this.strPdfString = null;
            target.value = ''; // Clear the file input value
            setTimeout(() => (this.isPdfLoad = true), 500);
          }
        })
        .catch((error) => {
          console.warn(error);
          this.toastr.warning(
            'Error loading PDF file. Please try again.',
            'Warning'
          );
          this.selectedFileName='No file chosen';
          this.strPdfString = null;
          target.value = ''; // Clear the file input value
          setTimeout(() => (this.isPdfLoad = true), 500);
        });
    } else {
      this.toastr.warning(
        'Invalid file format. Please select a valid PDF file.',
        'Warning'
      );
      this.selectedFileName='No file chosen'
      // Optionally clear the file input value to reset it
      target.value = '';
      this.strPdfString = null;
    }
  }

  async getPDFPageCount(file: File): Promise<number> {
    try {
      const pdfBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes, {
        ignoreEncryption: true,
      });
      const pages = pdfDoc.getPages();
      return pages.length;
    } catch (error) {
      console.warn(error);
      return 0; // Return a default value or handle the error as per your requirement
    }
  }

  onChangeFileBrowse() {
    if (this.faxUploadAPICall) {
      this.faxUploadAPICall.unsubscribe();
    }
    this.faxUploadAPICall = this.patientDocumentService
      .uploadPatientFileByPatientFile(
        this.newFilesForUpload
      )
      .subscribe(
        (response) => {
          this.selectpages=response?.pages;
          this.selectBlobName=response?.blobName;
          this.selectFileName=response?.fileName;
          this.selectedFilePathPDF = response?.filePathPDF;
          this.isSelectBrowse=true;
          this.isPdfLoad=true;
        },
        (error) => {
          this.isSelectBrowse=false;
          this.isPdfLoad=true;
          const data: HttpErrorResponse = error;
          const errorMsg = data?.error?.error?.message || 'Server Error';
          this.toastr.error(errorMsg, 'Error');
        }
      );
  }

  saveNewDocument(){
this.isShowSaveSpinner = true;
    const newDocumentFrom = this.documentForm.value;
    let selectedDocumentTypeId =this.documentForm?.get('drpDocumentType')?.value == null
              ? ''
              : this.documentForm?.get('drpDocumentType')?.value;

    let selectedDocumentType = this.filteredDocumentTypeList.find(
      (document) => document.docTypeId === selectedDocumentTypeId
    )?.documentType;

    let savePatientDocumentBlobDTO: CreatePatientDocumentBlobDTO = {
      faxId: defaultGuid,
      patientId:  this.data.constructorpatientId,
      pageNumber:0,
      pageCount:this.selectpages,
      documentId: defaultGuid,
      blobName: this.selectBlobName,
      fileName: this.selectFileName,
      documentTypeId: newDocumentFrom.drpDocumentType,
      reviewStatus:
        this.documentForm?.get('drpReviewStatus')?.value == null
          ? ''
          : this.documentForm?.get('drpReviewStatus')?.value,
      reviewReason:
        this.documentForm?.get('drpReviewReason')?.value == null
          ? ''
          : this.documentForm?.get('drpReviewReason')?.value,
      documentStartDate: newDocumentFrom.txtDocumentStartDate,
      documentEndDate: newDocumentFrom.txtDocumentEndDate,
      tenantId: this.constructortenantId,
      wipId: this.data.selectedWipId ? this.data.selectedWipId : null,
      note: this.documentForm?.get('txtNote')?.value,
      documentType:selectedDocumentType,
      isVerified: 1,
      documentUploadType: DocumentUploadTypes.ManualUploadType,
      filePathPDF:this.selectedFilePathPDF
    };
    this.patientDocumentService.insertPatientDocumentByInputAndPatientId(savePatientDocumentBlobDTO , this.data.constructorpatientId).subscribe(
      (response) => {
        this.isShowSaveSpinner = false;
        this.isPdfLoad=false;
        this.dialog.closeAll();
        this.toastr.success('Document uploaded successfully','Success');
        this.isSelectBrowse=false;
        this.communicationService.triggerLoadDeaultFaxIdList();
      },
      (err) => {
        this.isSelectBrowse=false;
        this.isPdfLoad=false;
        this.isShowSaveSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  clearPatient(event: Event) {
    event.stopPropagation();
    this.documentForm?.patchValue({
      drpPatient: '',
    });
    this.searchPatient('',this.data.constructorpatientId)
  }
}
