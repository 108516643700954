import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { dateFormatter } from 'projects/admin/src/app/sub-batch-list/sub-batch-list.component';
import { OrderBillingStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { AddAuthorizationComponent } from '../add-authorization/add-authorization.component';
import { AddItemAuthorizationComponent } from '../add-item-authorization/add-item-authorization.component';
import { ItemAuthorizationDTO, SaleorderDTO } from '../order-proxy/order-management/order/dto/models';
import { ItemAuthorizationService } from '../order-proxy/order-management/order/item-authorization.service';
import { SaleorderService } from '../order-proxy/order-management/order/saleorder.service';
import { AuthorizationDTO } from '../order-proxy/order-management/rcm/patient-management/patient/dto/models';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-order-authorization',
  templateUrl: './order-authorization.component.html',
  styleUrls: ['./order-authorization.component.scss'],
  providers: [ListService]

})
export class OrderAuthorizationComponent implements OnInit, OnDestroy {
  authType: "InsuranceBased" | "ItemBased" = 'ItemBased';
  strPageType: string = "auth";

  @Input() orderId: string = '';
  tblAuthReponse: any[] = [];
  Show: boolean = false;
  @Input() patientId: string = '';
  viewOrEdit: string = "";
  isLoading: boolean = false;
  orderStatus: boolean = true;
  drpItemCdoes: string[] = [];
  subscription$: Subscription[] = [];
  constructor(private store: Store,
    public list: ListService,
    private Table: TableService,
    public dialog: MatDialog,
    private itemAuthorizationService: ItemAuthorizationService,
    private toastr: ToastrService,
    private orderService: SaleorderService
  ) { }

  ngOnInit(): void {
    this.orderId = this.orderId ?? "";
    this.authType === 'ItemBased' && this.getItemBasedAuthTableData(this.patientId);
  }



  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //! Load Auth Table Datas
  getAuthTableData(orderId: string) {
    this.isLoading = true;
    let orderDetails: SaleorderDTO;
    const OrderService = this.orderService.get(orderId).subscribe(response => {
      // this.store.dispatch(new getOrder(orderId)).subscribe(response => {
      orderDetails = response;
      this.tblAuthReponse = [];
      //this.orderStatus = (orderDetails?.orderBilling?.status === billingStatus.OrderPending || orderDetails?.orderBilling?.status === billingStatus.ReadyToBilling) ? true : false;
      const orderBillstatus: [string, OrderBillingStatus][] = Object.entries(OrderBillingStatus);
      this.orderStatus = orderBillstatus?.some(s => ((s[1] ?? "") === (orderDetails?.orderBilling?.status)))
      const primaryAuth = orderDetails?.patients?.authorizations?.primaryAuthorizations
      const secondaryAuth = orderDetails?.patients?.authorizations?.secondaryAuthorizations
      const tertiaryAuth = orderDetails?.patients?.authorizations?.tertiaryAuthorizations
      if (primaryAuth != null) {
        primaryAuth.startDate = primaryAuth?.startDate ?? "";
        primaryAuth.endDate = primaryAuth?.endDate ?? "";
        const toDate: Date | null = primaryAuth?.endDate == "" ? null : new Date(primaryAuth?.endDate);
        const fromDate: Date | null = primaryAuth?.startDate == "" ? null : new Date(primaryAuth?.startDate);
        const timeDifference: number = toDate?.getTime() - fromDate?.getTime() ?? 0;
        const daysDifference: number = +(timeDifference / (1000 * 60 * 60 * 24) ?? 0).toFixed(0);
        this.tblAuthReponse.push({
          authId: primaryAuth?.authId,
          authVisits: primaryAuth?.authVisits,
          authNo: primaryAuth?.authNo,
          authAmount: primaryAuth?.authAmount,
          item: primaryAuth?.item,
          note: primaryAuth?.note,
          typeOfPolicy: primaryAuth?.typeOfPolicy,
          startDate: dateFormatter(primaryAuth?.startDate),
          endDate: dateFormatter(primaryAuth?.endDate),
          category: primaryAuth?.category,
          completed: primaryAuth?.isCompleted,
          completedBy: primaryAuth?.completedBy,
          plan: primaryAuth?.plan,
          orderId: orderDetails.id,
          patientId: orderDetails?.patientId,
          authorizationId: primaryAuth?.authorizationId,
          rbtnStatus: primaryAuth?.approvalStatus,
          txtReason: primaryAuth?.reason,
          units: primaryAuth?.units,
          used: primaryAuth?.used,
          saleOrRent: primaryAuth?.saleOrRent,
          isExpired: primaryAuth?.isExpired,
          itemCodes: primaryAuth?.itemCodes,
          // daysDifference: daysDifference,
          expiryStatus: primaryAuth?.expiryStatus,
          isFollowUp: primaryAuth?.isFollowUp
        });
        if (primaryAuth?.approvalStatus === "Disapproved") {
          this.Show = true
        }
      }
      if (secondaryAuth != null) {
        secondaryAuth.startDate = secondaryAuth?.startDate ?? "";
        secondaryAuth.endDate = secondaryAuth?.endDate ?? "";
        const toDate: Date | null = secondaryAuth?.endDate == "" ? null : new Date(secondaryAuth?.endDate);
        const fromDate: Date | null = secondaryAuth?.startDate == "" ? null : new Date(secondaryAuth?.startDate);
        const timeDifference: number = toDate?.getTime() - fromDate?.getTime() ?? 0;
        const daysDifference: number = +(timeDifference / (1000 * 60 * 60 * 24) ?? 0).toFixed(0);
        this.tblAuthReponse.push({
          authId: secondaryAuth?.authId,
          authVisits: secondaryAuth?.authVisits,
          authNo: secondaryAuth?.authNo,
          authAmount: secondaryAuth?.authAmount,
          item: secondaryAuth?.item,
          note: secondaryAuth?.note,
          typeOfPolicy: secondaryAuth?.typeOfPolicy,
          startDate: dateFormatter(secondaryAuth?.startDate),
          endDate: dateFormatter(secondaryAuth?.endDate),
          category: secondaryAuth?.category,
          completed: secondaryAuth?.isCompleted,
          completedBy: secondaryAuth?.completedBy,
          plan: secondaryAuth?.plan,
          orderId: orderDetails?.id,
          patientId: orderDetails?.patientId,
          authorizationId: secondaryAuth?.authorizationId,
          rbtnStatus: secondaryAuth?.approvalStatus,
          txtReason: secondaryAuth?.reason,
          units: secondaryAuth?.units,
          used: secondaryAuth?.used,
          saleOrRent: secondaryAuth?.saleOrRent,
          isExpired: secondaryAuth?.isExpired,
          itemCodes: secondaryAuth?.itemCodes,
          expiryStatus: secondaryAuth?.expiryStatus,
          isFollowUp: secondaryAuth?.isFollowUp
          //    daysDifference: daysDifference
        });
        if (secondaryAuth?.approvalStatus === "Disapproved") {
          this.Show = true
        }
      }
      if (tertiaryAuth != null) {
        tertiaryAuth.startDate = tertiaryAuth?.startDate ?? "";
        tertiaryAuth.endDate = tertiaryAuth?.endDate ?? "";
        const toDate: Date | null = tertiaryAuth?.endDate == "" ? null : new Date(tertiaryAuth?.endDate);
        const fromDate: Date | null = tertiaryAuth?.startDate == "" ? null : new Date(tertiaryAuth?.startDate);
        const timeDifference: number = toDate?.getTime() - fromDate?.getTime() ?? 0;
        const daysDifference: number = +(timeDifference / (1000 * 60 * 60 * 24) ?? 0).toFixed(0);
        this.tblAuthReponse.push({

          authId: tertiaryAuth?.authId,
          authVisits: tertiaryAuth?.startDate,
          authNo: tertiaryAuth?.authNo,
          authAmount: tertiaryAuth?.authAmount,
          item: tertiaryAuth?.item,
          note: tertiaryAuth?.note,
          typeOfPolicy: tertiaryAuth?.typeOfPolicy,
          startDate: dateFormatter(tertiaryAuth?.startDate),
          endDate: dateFormatter(tertiaryAuth?.endDate),
          category: tertiaryAuth?.category,
          completed: tertiaryAuth?.isCompleted,
          completedBy: tertiaryAuth?.completedBy,
          plan: tertiaryAuth?.plan,
          orderId: orderDetails.id,
          patientId: orderDetails?.patientId,
          authorizationId: tertiaryAuth?.authorizationId,
          rbtnStatus: tertiaryAuth?.approvalStatus,
          txtReason: tertiaryAuth?.reason,
          units: tertiaryAuth?.units,
          used: tertiaryAuth?.used,
          saleOrRent: tertiaryAuth?.saleOrRent,
          isExpired: tertiaryAuth?.isExpired,
          itemCodes: tertiaryAuth?.itemCodes,
          expiryStatus: tertiaryAuth?.expiryStatus,
          isFollowUp: tertiaryAuth?.isFollowUp
          // daysDifference: daysDifference
        });
        if (tertiaryAuth?.approvalStatus === "Disapproved") {
          this.Show = true
        }
      }
      this.isLoading = false;

      this.Table.setAuthTable(this.tblAuthReponse);
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(OrderService);
  }

  //! Open Auth Modal Popup
  openAuthModal() {

    const dialogRef = this.dialog.open(AddAuthorizationComponent, {
      disableClose: true,
      data: { orderId: this.orderId, allowUptSve: this.orderStatus, patientId: this.patientId }
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {
      this.orderId !== "" && this.orderId !== null && this.orderId !== undefined && this.getAuthTableData(this.orderId);
    });
    this.subscription$.push(closeModal);
  }
  //! Open Auth Modal Popup
  openItemBasedAuthModal() {
    const dialogRef = this.dialog.open(AddItemAuthorizationComponent, {
      disableClose: true,
      data: { orderId: this.orderId, allowUptSve: this.orderStatus, patientId: this.patientId }
    });
    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.orderId !== "" && this.authType === 'ItemBased' && this.getItemBasedAuthTableData(this.patientId);
    });
    this.subscription$.push(closeDialog)
  }

  //! View Auth Modal Popup
  viewItemBasedAuth(auth: any) {
    const dialogRef = this.dialog.open(AddItemAuthorizationComponent, {
      disableClose: true,
      data: { orderId: this.orderId, authId: auth?.id, authDatas: auth, allowUptSve: this.orderStatus, patientId: this.patientId }
    });
    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      this.authType = "ItemBased";
      this.orderId !== "" && this.orderId !== null && this.orderId !== undefined && this.getItemBasedAuthTableData(this.patientId);
    });
    this.subscription$.push(closeDialog);
  }
  //! View Auth Modal Popup
  viewAuth(auth: AuthorizationDTO) {
    const dialogRef = this.dialog.open(AddAuthorizationComponent, {
      disableClose: true,
      data: { orderId: this.orderId, authDatas: auth, allowUptSve: this.orderStatus, patientId: this.patientId }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.orderId !== "" && this.orderId !== null && this.orderId !== undefined && this.getAuthTableData(this.orderId);
    });
  }
  //! Delete Auth By Id
  deleteAuth(datas?: any) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        const deleteAuth = this.orderService.deleteAuthorization(datas?.orderId, datas?.authorizationId).subscribe(() => {
          this.tblAuthReponse.forEach(element => {
            if (datas?.authorizationId == element.authorizationId) {
              let index = this.tblAuthReponse.indexOf(element, 0)
              this.tblAuthReponse.splice(index, 1)
            }
          });
          this.Table.setAuthTable(this.tblAuthReponse);
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
        this.subscription$.push(deleteAuth);
      }
    });
  }
  getUpdates(value: string) {
    this.authType = ((value === 'InsuranceBased') ? "InsuranceBased" : "ItemBased");
    value === this.authType && this.authType === 'InsuranceBased' && this.getAuthTableData(this.orderId);
    value === this.authType && this.authType === 'ItemBased' && this.getItemBasedAuthTableData(this.patientId);
  }

  //!Item Based Auth
  getItemBasedAuthTableData(patientId?: string) {
    this.isLoading = true;
    this.tblAuthReponse = [];
    const authorizationGetist = this.itemAuthorizationService.getList(new PagedAndSortedResultRequestDto).pipe(map(e => e.items), map(e => e = e?.filter(x => x?.patientId === patientId))).subscribe(response => {
      response?.forEach(element => {
        let strDaysDifference = "";
        if (element?.startDate && element?.endDate) {
          const startDate = new Date(element?.startDate)?.toLocaleDateString("en-US");
          const startDatet = new Date(startDate)?.getTime() ?? 0;
          const endDate = new Date(element?.endDate)?.toLocaleDateString("en-US");
          const endDatet = new Date(endDate)?.getTime() ?? 0;
          const diff = new Date(endDatet).setHours(12) - new Date(startDatet).setHours(12);
          const intDaysDifference: number = (Math.round(diff / 8.64e7) ?? 0); //+((timeDiff / 31536000000 ?? 0).toFixed(0))??0;
          strDaysDifference = String(intDaysDifference);
        }

        this.tblAuthReponse?.push({
          id: element?.id,
          authId: element?.authId,
          authNo: element?.authNo,
          authAmount: element?.authAmount,
          item: element?.item,
          note: element?.note,
          startDate: element?.startDate == null ? "" : new Date(element?.startDate ?? "")?.toLocaleDateString("en-US"),
          endDate: element?.endDate == null ? "" : new Date(element?.endDate)?.toLocaleDateString("en-US"),
          orderId: element?.orderId,
          patientId: element?.patientId,
          authorizationId: element?.authorizationId,
          rbtnStatus: element?.approvalStatus,
          txtReason: element?.reason,
          units: element?.units,
          used: element?.used,
          saleOrRent: element?.saleOrRent,
          days: element?.days,
          authExpireStatus: element?.authExpireStatus,
          approvalStatus: element?.approvalStatus,
          approvedByWhom: element?.approvedByWhom,
          approvedOn: element?.approvedOn,
          insurance: element?.insurance,
          reason: element?.reason,
          expireStatus: element?.expireStatus,
          isFollowUp: +(element?.isFollowUp ?? 0),
          daysDifference: strDaysDifference
        });
      });
      this.isLoading = false;
      this.Table.setAuthTable(this.tblAuthReponse);
    }, (err: HttpErrorResponse) => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(authorizationGetist);
  }
  updateFollowUp(value: { id: string }) {
    if (value?.id) {
      const authorizationGet = this.itemAuthorizationService.get(value?.id).subscribe(response => {
        const mResponse: ItemAuthorizationDTO = {
          authorizationId: response?.authorizationId,
          authId: response?.authId,
          authNo: response?.authNo,
          authAmount: response?.authAmount,
          startDate: response?.startDate,
          endDate: response?.endDate,
          days: response?.days,
          authExpireStatus: response?.authExpireStatus,
          item: response?.item,
          note: response?.note,
          approvalStatus: response?.approvalStatus,
          approvedByWhom: response?.approvedByWhom,
          approvedOn: response?.approvedOn,
          insurance: response?.insurance,
          isFollowUp: response?.isFollowUp === 0 ? 1 : 0,
          reason: response?.reason,
          units: response?.units,
          used: response?.used,
          saleOrRent: response?.saleOrRent,
          expireStatus: response?.expireStatus,
          patientId: response?.patientId,
          orderId: response?.orderId,
        }
        this.itemAuthorizationService.update(value?.id, mResponse).subscribe(res => {
          this.getItemBasedAuthTableData(this.patientId);
          // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
          this.toastr.success('Saved Successfully','Success')

        }, (err: HttpErrorResponse) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        })
      }, (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(authorizationGet);
    }

  }
}
