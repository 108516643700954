import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { baseUrl } from 'projects/patient/src/environments/environment';
import Swal, { SweetAlertResult } from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';

import { AccountService } from '../admin-proxy/platform-app-management/rcm/platform-management/reset-password/account.service';
import { defaultGuid } from '../document-perviewer/document-perviewer.component';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  cookieValue: string = "";
  emailMask: any;
  forgotForm: FormGroup;
  isValidEmail:boolean=false;
  constructor(
    private cookieService: CookieService,
     public router: Router, private forgotService: AccountService,
    private formBuilder: FormBuilder, private oauthService: OAuthService,
    private toastr: ToastrService,
    ) { }
  ngOnInit(): void {
    this.forgotForm = this.formBuilder.group({
      txtMail: new FormControl("", [Validators.required, Validators.email]),
    });
    this.emailMask = emailMask;
    this.cookieValue = this.cookieService.get('__tenant') ?? "";
  }
  //! forgot function
  submit() {   
    this.isValidEmail = true;
      let datas = {
        emailAddress: this.forgotForm.value.txtMail.toLowerCase(),
        returnUrl: baseUrl + "#/updatePassword",
        tenantId: defaultGuid,//this.cookieValue=="",
      }
      this.forgotService.resetPasswordRequestByInput(datas).subscribe(response => {
        this.toastr.success('We have e-mailed your password reset link!','Success');
        setTimeout(() => {
          this.oauthService.loadDiscoveryDocumentAndLogin();
          }, 2000);
      }, err => {
        this.isValidEmail = false;
        const data: HttpErrorResponse = err;
        const modifiedError = JSON.parse(data?.error);
        Swal.fire({
          icon: 'warning',
          text: modifiedError?.error?.message,
        });
      });
  }
  //back to login
  backToLogin() {
    this.oauthService.loadDiscoveryDocumentAndLogin();
  }

}
