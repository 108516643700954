<div class="container-fluid">
    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between" style="margin-top:-15px;">
                <h3 class="font-weight-bolder mb-0">Patient / Invoice List</h3>
                <div class="page-title-box page-title-right">
                    <ol class="breadcrumbs m-0 align-items-center">

                       <!-- <div class="input-group">
                        <input autocomplete="new-dateRangStatus" type="text" class="form-control" [hidden]="dateRangStatus != true" placeholder="Username" [ngStyle]="{'border-color':dateRangStatus == true ? '#bdb4b4' : 'white' }"
                         aria-label="Username" aria-describedby="basic-addon1" name="daterange" ngxDaterangepickerMd [(ngModel)]="selectedRange"  id="calendar-input"
                         [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [linkedCalendars]="true"
                         [ranges]="ranges" [showClearButton]="true" [locale]="calendarLocale" [placeholder]="calendarPlaceholder"
                         [keepCalendarOpeningWithRange]="true" [minDate]="minDate" [maxDate]="maxDate" (datesUpdated)="datesUpdateds($event)" >


                      </div> -->
                      <form [formGroup]="branchForm">
                        <mat-form-field class="pr-3">
                          <mat-label>Branch</mat-label>
                          <mat-select #selectBranch formControlName="drpBranch" multiple [(value)]="organizationUnitName">
                            <mat-option>
                              <ngx-mat-select-search
                                placeholderLabel="Search"
                                noEntriesFoundLabel="No Matches found"
                                formControlName="txtBranch"
                              ></ngx-mat-select-search>
                            </mat-option>
            
                            <mat-option #selectAllBranch (click)="allBranchSelect()" value="0">All</mat-option>
            
                            <mat-option *ngFor="let branch of brnachList | async" [value]="branch.id">
                              {{ branch.organizationUnitName }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <button mat-button class="mr-2 buttonColor"
                            [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)" (click)="
                            exporter.exportTable('xlsx', { fileName: 'Invoice List', sheet: 'InvoiceList', Props: { Author: 'QSecure' } })">
                            Export
                        </button>
                      </form>
                      <!-- <span class="input-group-text bg-color mr-2" id="basic-addon1"><label style="margin: auto;" for="calendar-input" >
                        <i  class="fa fa-filter cursor"  aria-hidden="true"></i></label></span> -->

                        
                        <!-- <li class="breadcrumb-item">Orders</li>
                        <li class="breadcrumb-item">Invoice Approval</li> -->
                    </ol>
                </div>
            </div>
        </div>

        <div class="col-12 pb-5">
            <form [formGroup]="invoiceForm">
            <div class="table table-responsive example-container mat-elevation-z4 mb-0 pb-0">

                <table mat-table class="pt-0 mt-0" matTableExporter #exporter="matTableExporter"
                    [hiddenColumns]="[6, 7, 8]" [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="sSaleorderId">
                        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>

                            <mat-form-field class="filter">
                                <input  matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="saleorderId" autocomplete="new-saleorderId" />
                                <mat-placeholder class="center">Saleorder Id</mat-placeholder>
                              </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element">


                            <a matTooltip="Click to View Sale Order" class="eyeCursorclass" [routerLink]="
                  '/orders/saleorderCreateEditTab/' +element?.patientId + '/' + element?.saleorderId
                " target="_blank">
                                {{ element?.sSaleorderId }}
                            </a>


                        </td>
                    </ng-container>
                    <ng-container matColumnDef="sPatientId">
                        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>

                            <mat-form-field class="filter">
                                <input  matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="patientId" autocomplete="new-patientId" />
                                <mat-placeholder class="center">Patient Id</mat-placeholder>
                              </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a matTooltip="Click to View Patient" class="eyeCursorclass"
                                [routerLink]="'/patientCreateEditTab/' + element?.patientId" target="_blank">
                                {{ element?.sPatientId }}
                            </a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="sPatientName">
                        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>

                            <mat-form-field class="filter">
                                <input  matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="patientName" autocomplete="new-patientName" />
                                <mat-placeholder class="center">Patient Name</mat-placeholder>
                              </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element?.sPatientName }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="invoiceCode">
                        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>

                            <mat-form-field class="filter">
                                <input  matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="invoiceCode" autocomplete="new-invoiceCode" />
                                <mat-placeholder class="center">Invoice Code</mat-placeholder>
                              </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element">

                            <a matTooltip="Click To Download" class="fa fa-file-pdf-o pointer editBtnColor pl-3"
                                (click)="generatePDF(element.id,'download')">
                                {{ element.invoiceCode }}
                            </a>


                        </td>
                    </ng-container>
                    <ng-container matColumnDef="invoiceType">
                        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>

                            <mat-form-field class="filter">
                                <input  matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="invoiceType" autocomplete="new-invoiceType" />
                                <mat-placeholder class="center">Invoice Type</mat-placeholder>
                              </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container [ngSwitch]="element?.invoiceType">
                                <ng-container *ngSwitchCase="'Patient_Response'">Patient Responsibility</ng-container>
                                <ng-container *ngSwitchCase="'primary'">Primary</ng-container>
                                <ng-container *ngSwitchCase="'secondary'">Secondary </ng-container>
                                <ng-container *ngSwitchCase="'tertiary'">Tertiary </ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="branch">
                      <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>

                          <mat-form-field class="filter">
                              <input  matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="branch" autocomplete="new-branch" />
                              <mat-placeholder class="center">Branch</mat-placeholder>
                            </mat-form-field>
                      </th>
                      <td mat-cell *matCellDef="let element">
                         -
                      </td>
                  </ng-container>
                    <ng-container matColumnDef="creationTime">
                        <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>

                            <mat-form-field class="filter">
                                <input  matInput (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" formControlName="createdDate" autocomplete="new-createdDate" />
                                <mat-placeholder class="center">Created Date</mat-placeholder>
                              </mat-form-field>

                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element?.dos != '0001-01-01T00:00:00'">
                                {{ element?.creationTime | date : "MM/dd/yyyy" : "en_US" }}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="options">
                        <th class="header" mat-header-cell *matHeaderCellDef>Options</th>
                        <td class="align" mat-cell *matCellDef="let element">
                            <button *ngIf="!element?.isApproved" mat-mini-fab class="wip-status-color">
                                <mat-icon matTooltip="Click To Approve"
                                    (click)="statusUpdate(element?.id, 'completed')" class="wip-danger-status-color"
                                    disabled>check_circle_outline</mat-icon>
                            </button>
                            <button *ngIf="element?.isApproved" mat-button disabled>
                                    Approved
                            </button>


                        </td>
                    </ng-container>
                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="6">
                            <div *ngIf="!isLoading">
                                {{ "No Records Found" }}
                            </div>
                            <div [hidden]="!isLoading">
                                <mat-spinner [diameter]="220"></mat-spinner>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
                </table>

                <div *ngIf="
                  !(
                    dataSource &&
                    dataSource?.data &&
                    dataSource?.data?.length !== 0
                  )
                " class="text-align-center">
                    <div class="message py-3" *ngIf="!isLoading">
                        {{ "No Records Found" }}
                    </div>
                    <div class="py-3" [hidden]="!isLoading">
                        <mat-spinner [diameter]="220"></mat-spinner>
                    </div>
                </div>
                <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </form>
        </div>
    </div>
</div>
