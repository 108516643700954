import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { BatchDataDetailsDTO, ClaimBatchDTO, ClaimBatchDataDTO, ClaimStatusListDTO, CreateUpdateClaimBatchDTO, OverallClaimBatchDTO } from '../dto/models';

@Injectable({
  providedIn: 'root',
})
export class ClaimBatchService {
  apiName = 'billingManagement';

  create = (input: CreateUpdateClaimBatchDTO) =>
    this.restService.request<any, ClaimBatchDTO>({
      method: 'POST',
      url: '/api/billingApp/claim-batch',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/billingApp/claim-batch/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ClaimBatchDTO>({
      method: 'GET',
      url: `/api/billingApp/claim-batch/${id}`,
    },
    { apiName: this.apiName });

  getActiveClaimBatchData = () =>
    this.restService.request<any, BatchDataDetailsDTO[]>({
      method: 'GET',
      url: '/api/billingApp/claim-batch/active-claim-batch-data',
    },
    { apiName: this.apiName });

  getBatchDetailsByIdByGClaimBatchId = (gClaimBatchId: string) =>
    this.restService.request<any, ClaimBatchDataDTO[]>({
      method: 'GET',
      url: `/api/billingApp/claim-batch/batch-details-by-id/${gClaimBatchId}`,
    },
    { apiName: this.apiName });

  getClaimBatchDetailsByFromDateAndToDateAndGProviderIdAndGClaimBatchStatusId = (fromDate: string, toDate: string, gProviderId: string, gClaimBatchStatusId: string) =>
    this.restService.request<any, OverallClaimBatchDTO[]>({
      method: 'GET',
      url: '/api/billingApp/claim-batch/claim-batch-details',
      params: { fromDate, toDate, gProviderId, gClaimBatchStatusId },
    },
    { apiName: this.apiName });

    getClaimStatusListDetailsByFromDateAndToDateAndOrderIdAndGClaimBatchStatusIdAndPatientId = (fromDate: string, toDate: string, orderId: number, gClaimBatchStatusId: string, patientId: string) =>
      this.restService.request<any, ClaimStatusListDTO[]>({
        method: 'GET',
        url: '/api/billingApp/claim-batch/claim-status-list-details',
        params: { fromDate, toDate, orderId, gClaimBatchStatusId, patientId },
      },
      { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ClaimBatchDTO>>({
      method: 'GET',
      url: '/api/billingApp/claim-batch',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateClaimBatchDTO) =>
    this.restService.request<any, ClaimBatchDTO>({
      method: 'PUT',
      url: `/api/billingApp/claim-batch/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  updateClaimBatchStatus = () =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/billingApp/claim-batch/claim-batch-status',
    },
    { apiName: this.apiName });
    claimBatchstatus = () =>
      this.restService.request<any, any>({
        method: 'GET',
        url: `/api/billingApp/claim-batch-status/drp-batchstaus`,
      },
      { apiName: this.apiName });
      claimBatchDetailsId = (gClaimBatchId:string) =>
        this.restService.request<any, any>({
          method: 'GET',
          url: `/api/billingApp/claim-batch/batch-details-by-id/${gClaimBatchId}`,
        },
        { apiName: this.apiName });
  constructor(private restService: RestService) {}
}
