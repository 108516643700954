<div class="row bg">
  <div class="col-12">
    <div class="d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18"><b>Sub Batch</b></h4>
      <ol class="breadcrumb m-0">
        <li>
          <button matTooltip="Click To Goback to Batch List" [routerLink]="'/uploadBin'" mat-button
            class="pl-2 pr-2 buttonColor font-size-10">
            <i class="fa fa-backward mr-1" ></i> Back To Batch List
          </button>
        </li>
        <li class="ml-2 mt-2 breadcrumb-item">Sub Batch</li>
        <li class="mt-2 breadcrumb-item muted">Sub Batch</li>
      </ol>
    </div>
    <mat-card-content>
      <form [formGroup]="subBatchUploadBinForm">
        <!-- <div [hidden]="!isLoading"
          style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
          <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
          </mat-progress-bar>
        </div> -->
        <div class="table table-responsive">
          <table class="mat-elevation-z8" multiTemplateDataRows mat-table class="table" [dataSource]="dataSource"
            matSort>

            <ng-container matColumnDef="batchNo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-batchId" matInput formControlName="batchId" type="text" />
                  <mat-placeholder class="center">Batch Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  {{ element.defaultBatchId }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="stat">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Stat
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.isStat===true">
                  <mat-checkbox [disabled]="true" [checked]='true'> </mat-checkbox>
                </ng-container>
                <ng-container *ngIf="element.isStat===false">
                  <mat-checkbox [disabled]="true" [checked]='false'> </mat-checkbox>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="documentName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>

                <mat-form-field class="filter">
                  <input autocomplete="new-documentName" matInput formControlName="documentName" type="text" />
                  <mat-placeholder class="center"> Document Name</mat-placeholder>
                </mat-form-field>

              </th>
              <td mat-cell *matCellDef="let element">

                <span class="alert alert-success p-2" *ngIf="element.reasonForNotExtracted===''">
                  {{ element.uploadedFileName }}
                </span>
                <span class="alert alert-danger p-2" *ngIf="element.reasonForNotExtracted!==''">
                  {{ element.uploadedFileName }}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="assignedBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>

                <mat-form-field class="filter">
                  <input autocomplete="new-assignedBy" matInput formControlName="assignedBy" type="text" />
                  <mat-placeholder class="center">Assigned By</mat-placeholder>
                </mat-form-field>


              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  {{ element.assignedBy }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="assignedTo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-assignedTo" matInput formControlName="assignedTo" type="text" />
                  <mat-placeholder class="center">Assigned To</mat-placeholder>
                </mat-form-field>

              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  {{ element.assignedTo }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="assignedTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <mat-label> Assigned Date </mat-label>
                  <input autocomplete="new-assignedTime" matInput formControlName="assignedTime" type="text" placeholder="(MM/DD/YYYY)" />
                  <mat-error
                    *ngIf=" subBatchUploadBinForm?.get('assignedTime')?.touched && subBatchUploadBinForm?.get('assignedTime')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  {{ element.assignedTime }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="uploadedFileStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-uploadedFileStatus" matInput formControlName="uploadedFileStatus" type="text" />
                  <mat-placeholder class="center">Status</mat-placeholder>
                </mat-form-field>

              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  {{ element.uploadedFileStatus }}
                </ng-container>
              </td>
            </ng-container>



            <ng-container matColumnDef="Options">
              <th class="verticalAlignColumn text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                Options
              </th>
              <td class="verticalAlignColumn text-center" mat-cell *matCellDef="let element">
                <a *ngIf="isShownViewButton " (click)="update(element.fileId)">
                  <i class="fa fa-pencil pointer editBtnColor mr-3" matTooltip="Click To Edit"></i>
                </a>

                <a *ngIf="isShownDeleteButton" class="deleteBtnColor" (click)="deleteFileByID(element.fileId)">
                  <i class="fa fa-trash pointer mr-3" matTooltip="Click To Delete" ></i>
                </a>
                <a *ngIf="element.reasonForNotExtracted!==''"
                  (click)="expandedElement = expandedElement === element ? null : element">
                  <mat-icon class="pointer"> {{expandedElement === element ?'expand_less' : 'expand_more'}}</mat-icon>
                </a>

                <a  (click)="clicktoUpdate(element?.fileId)">
                  <i class="fa fa-pencil pointer editBtnColor mr-3" matTooltip="Click To View File"></i>
                </a>

              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="10">
                <div *ngIf="!isLoading">
                  {{"No Records Found"}}
                </div>
                <div *ngIf="isLoading">
                  {{"Processing..."}}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="10">
                <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                  [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                  [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container>


            <ng-container matColumnDef="expandedErrorDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="arrDisplayedColumns?.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="example-element-description">
                    {{element.reasonForNotExtracted}}
                  </div>
                </div>
              </td>
            </ng-container>


            <tr mat-footer-row *matFooterRowDef="['noRecords']"
              [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>

            <tr mat-row *matRowDef="let element; columns: ['expandedErrorDetail'];"
              [class.example-expanded-row]="expandedElement === element"></tr>
          </table>
        </div>
      </form>
    </mat-card-content>
  </div>

  <div class="col-12">
    <mat-accordion #firstAccordion="matAccordion">
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <ng-container>
              <b>Existing Patient List Search </b>
            </ng-container>

          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row mt-1">
          <div class="col-lg-12">
            <form [formGroup]="patientSearchForm">
              <div [hidden]="!isLoading"
                style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
                <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
                </mat-progress-bar>
              </div>
              <div class="table table-responsive">
                <table mat-table [dataSource]="patientData" class="mat-elevation-z8">
                  <ng-container matColumnDef="S.No">
                    <th class="header" mat-header-cell *matHeaderCellDef>
                      <mat-form-field class="filter">
                        <input autocomplete="new-Sno" matInput formControlName="Sno" type="text" />
                        <mat-placeholder class="center">S.No</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element; let i=index">
                      {{ i+1 }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Name">
                    <th class="header" mat-header-cell *matHeaderCellDef>
                      <mat-form-field class="filter">
                        <input autocomplete="new-Name" matInput formControlName="Name" />
                        <mat-placeholder>Name</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span matTooltip="{{element?.Name}}" matTooltipClass="custom-tooltip-font"> {{ element?.Name
                        }}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Patient Id">
                    <th class="header" mat-header-cell *matHeaderCellDef>
                      <mat-form-field class="filter">
                        <input autocomplete="new-PatientId" matInput formControlName="PatientId" />
                        <mat-placeholder>Patient Id</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element">

                      {{ element?.PatientId }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Address">
                    <th class="header" fxFlex="200" mat-header-cell *matHeaderCellDef>
                      <mat-form-field class="filter">
                        <input autocomplete="new-Address" matInput formControlName="Address" />
                        <mat-placeholder>Address</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td fxFlex="200" mat-cell *matCellDef="let element"> <span class="ellipsis cut-text">
                        {{ element?.Address }} </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="SSN">
                    <th class="header  w-175" mat-header-cell *matHeaderCellDef>
                      <mat-form-field class="filter">
                        <input autocomplete="new-SSN" matInput formControlName="SSN" />
                        <mat-placeholder>SSN</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element?.SSN }}</td>
                  </ng-container>
                  <ng-container matColumnDef="Account Number">

                    <th class="header" mat-header-cell *matHeaderCellDef>
                      <mat-form-field class="filter">
                        <input autocomplete="new-AccountNumber" matInput formControlName="AccountNumber" />
                        <mat-placeholder>Account No</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element?.AccountNumber }}</td>
                  </ng-container>
                  <ng-container matColumnDef="Email Address">

                    <th class="header" mat-header-cell *matHeaderCellDef>
                      <mat-form-field class="filter">
                        <input autocomplete="new-EmailAddress" matInput formControlName="EmailAddress" />
                        <mat-placeholder>Email Address</mat-placeholder>

                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element?.EmailAddress }}</td>
                  </ng-container>
                  <ng-container matColumnDef="Date Of Birth">

                    <th class="header" class="w-125" mat-header-cell *matHeaderCellDef>
                      <mat-form-field class="filter">
                        <mat-label> Date Of Birth </mat-label>
                        <input autocomplete="new-DateOfBirth" matInput formControlName="DateOfBirth" placeholder="(MM/DD/YYYY)" />
                        <mat-error
                          *ngIf=" patientSearchForm?.get('DateOfBirth')?.touched && patientSearchForm?.get('DateOfBirth')?.errors?.dateVaidator">
                          Enter Valid date
                        </mat-error>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element">{{
                      element?.DateOfBirth }}</td>
                  </ng-container>

                  <ng-container matColumnDef="Moblie">
                    <th class="header" mat-header-cell *matHeaderCellDef>
                      <mat-form-field class="filter">
                        <input autocomplete="new-Moblie" matInput formControlName="Moblie" />
                        <mat-placeholder>Moblie</mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element?.Moblie }}</td>
                  </ng-container>
                  <ng-container matColumnDef="LockStatus">
                    <th class="header" mat-header-cell *matHeaderCellDef>
                      <mat-form-field class="filter">
                        <input autocomplete="new-LockStatus" matInput formControlName="LockStatus" />
                        <mat-placeholder>Status </mat-placeholder>
                      </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element" >{{
                      element?.LockStatusWithName }}</td>
                  </ng-container>

                  <ng-container matColumnDef="noRecords">
                    <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                      <div *ngIf="!isLoading">
                        {{"No Records Found"}}
                      </div>
                      <div *ngIf="isLoading">
                        {{"Processing..."}}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="paginator">
                    <td mat-footer-cell *matFooterCellDef colspan="9">
                      <mat-paginator class="table table-responsive" #patientPaginator
                        [length]="patientData?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                        [pageSizeOptions]="[5, 10, 25, 100]">
                      </mat-paginator>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns" (contextmenu)="onContextMenu($event, row)"
                    [ngClass]="{ 'highlighted': row.highlighted}"></tr>
                  <!-- [ngClass]="{'alert alert-success': patientId , 'alert alert-danger': !patientId }"></tr> -->
                  <tr mat-footer-row *matFooterRowDef="['noRecords']"
                    [ngClass]="{ active: patientData && patientData.data && patientData.data.length !== 0 }"></tr>
                  <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
                </table>
              </div>
            </form>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<div style="visibility: auto; position: fixed" [style.left]="contextMenuPosition?.x"
  [style.top]="contextMenuPosition?.y" [matMenuTriggerFor]="contextMenu"></div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button *ngIf="!patientId" mat-menu-item (click)="onContextMenuSelectAction(item)">Select Patient</button>
    <button *ngIf="patientId" mat-menu-item (click)="onContextMenuDeselecteAction(item)">De-Select Patient</button>
  </ng-template>
</mat-menu>
