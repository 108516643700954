import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HorizontalComponent } from 'projects/layout/src/app/horizontal/horizontal.component';
import { ShippingApprovalListComponent } from './shipping-approval-list/shipping-approval-list.component';
import { ShippingCreateEditComponent } from './shipping-create-edit/shipping-create-edit.component';
import { WarehouseListComponent } from './warehouse-list/warehouse-list.component';

const routes: Routes = [
  { path: '',component: HorizontalComponent,
  children: [
    //Shipping
    { path: 'orders/shippingApprovalList', component: ShippingApprovalListComponent},
    { path: 'orders/shippingApproval/:id', component: ShippingCreateEditComponent},
    //Warehouse
    { path: 'orders/billingWarehouse', component: WarehouseListComponent},
  ]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ShippingRoutingModule { }
