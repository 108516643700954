import type { ProductType } from '../../items/product-type.enum';
import {
  AuditedEntityDto,
  FullAuditedEntityDto,
  mapEnumToOptions,
} from '@abp/ng.core';
import type { Status } from '../status.enum';
import { FullAuditedAggregateRoot } from 'projects/admin/src/app/admin-proxy/platform-app-management/domain/entities/auditing';
import { CreateUpdateMmOrderItemSettingsDTO, OrderProductHcpcCode } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';

export interface CreateUpdateProductAllowableDetailsDTO {
  productId?: string;
  payerId?: string;
  quantity: number;
  eligibleMonths: number;
  priceType: PriceType;
  allowableAmount: number;
}

export interface CreateUpdateProductBrandDetailsDTO {
  productBrandDetailName: string;
}
export interface InsuranceAllowablesDTO extends FullAuditedEntityDto<string> {
  insuranceCategoryId?: string;
  hcpcCodeId?: string;
  hcpCcode?: string;
  allowable: number;
  minimumQuantity?: number;
  maximumQuantity?: number;
  quantityDays?: number;
  tenantId?: string;
}

export interface MultipleInsuranceAllowablesDTO {
  insuranceCategoryId?: string;
  hcpcCodeId?: string;
  allowable: number;
  hcpCcode: string[];
  minimumQuantity?: number;
  quantityDays?: number;
  maximumQuantity?: number;
  tenantId?: string;
}

export interface CreateUpdateInsuranceAllowablesDTO {
  insuranceCategoryId?: string;
  hcpcCodeId?: string;
  HCPCcode?: string;
  allowable: number;
  minimumQuantity?: number;
  maximumQuantity?: number;
  quantityDays?: number;
  tenantId?: string;
}
export interface CreateUpdateProductCategoryDetailsDTO {
  productCategoryName: string;
  status: Status;
  mmRefferenceId?: number;
  accessories?: number;
  showSettings?: number;
  ddOrder?: number;
  showMinPressure?: number;
  showMaxPressure?: number;
  showSettlingTime?: number;
  showIPAP?: number;
  showEPAP?: number;
  showRampPressure?: number;
  showRampTime?: number;
  showBiFlex?: number;
  showSetPressure?: number;
  showCFlex?: number;
  showRRBPM?: number;
  showIPAPmin?: number;
  showIPAPmax?: number;
  showEEP?: number;
  showSetVT?: number;
  showRate?: number;
  machinePoints?: string;
  pointMultiplier?: string;
  defaultPoints?: string;
  cashRxRequired?: string;
  serialLotNoRequired?: string;
  physicalInvWeekOfMonth?: string;
  physicalInvDayOfWeek?: string;
  low?: string;
  high?: string;
  mid?: string;
  updatedDate: string;
}
export interface InsuranceAllowablesHCPCDTO {
  insuranceCategoryId?: string;
  hcpcCodeId?: string;
  insuranceName?: string;
  hcpcCode?: string;
  allowable: number;
  maximumQuantity?: number;
  quantityDays?: number;
  maximumQuantityBill?: number;
  tenantId?: string;
  insAllowablesId?: string;
  productId?: string;
  createdBy?: string;
  modifyBy?: string;
  productName?: string;
  createdByName?: string;
  modifyByName?: string;
  modifyDate?: string;
  createdDate?: string;
}
export interface CreateUpdateProductStockDetailsDTO {
  brandId?: string;
  categoryId?: string;
  productId?: string;
  vendorId?: string;
  lotNo?: string;
  pricePerUnit: number;
  quantity: number;
  soldQuantity: number;
  isAllSerialNumbersUpdated?: string;
  productSerialNumberDetails: ProductLotDetailsDTO[];
}

export interface CreateUpdateResupplyProductDetailsDTO {
  mainproductId: string;
  hcpcCode: string;
  resupplyProductName: string;
  productCode: number;
  productDescription: string;
  minimumQuantity: number;
  // status: Status;
}

export interface CreateUpdateVendorDetailsDTO {
  vendorName: string;
  vendorCode: string;
  addressLine1: string;
  addressLine2?: string;
  primaryPhoneNumber: string;
  secondaryPhoneNumber?: string;
  emailId?: string;
  fax?: string;
  fobDelivery?: string;
  contactPersonName?: string;
  contactPersonMailId?: string;
  contactNo?: string;
  comments?: string;
  productQuantity: number;
  country?: string;
  state?: string;
  city?: string;
  zip?: string;
  terms?: string;
  status: Status;
  shipVia?: string;
  webSite?: string;
  remarks?: string;
  orderEmail?: string;
  lotNumberLength?: number;
  accountNumber?: string;
  serialNumberLength?: string;
}
export interface CreateUpdateInventoryTransferStatusDTO {
  description?: string;
  fgColor?: string;
  bgColor?: string;
}
export interface InventoryTransferStatusDTO
  extends FullAuditedEntityDto<string> {
  tenantId?: string;
  description?: string;
  fgColor?: string;
  bgColor?: string;
}
export interface AdjustmentReasonDTO extends FullAuditedEntityDto<string> {
  invAdjReasonId: number;
  adjustmentReason?: string;
  tenantId?: string;
}
export interface CreateUpdateInventoryAdjustmentDTO {
  mmRefInventoryAdjustmentId: number;
  inventoryLocationId?: string;
  invAdjReasonId?: string;
  reasonText?: string;
  adjustmentNotes?: string;
  submittedBy?: string;
  adjustmentItemList: CreateUpdateAdjustmentItemDTO[];
  productCategoryId?: string;
  tenantId?: string;
}
export interface CreateUpdateAdjustmentItemDTO {
  mmRefInventoryAdjustmentItemId: number;
  mmRefInventoryAdjustmentId: number;
  inventoryAdjustmentId?: string;
  productId?: string;
  qoh?: number;
  newQOH?: number;
  serialLotNumber: string;
  mmInventoryId: string;
  productName: string;
  ssId?: string;
  lotSerialNo?: string;
  userId?: string;
  reasonText?: string;
  unitCost?: number;
  physical?: number;
  processed: number;
}
export interface InventoryAdjustmentDTO extends FullAuditedEntityDto<string> {
  mmRefInventoryAdjustmentId: number;
  inventoryLocationId?: string;
  invAdjReasonId: number;
  reasonText?: string;
  submittedBy?: string;
  adjustmentItemList: AdjustmentItemDTO[];
  productCategoryId?: string;
}
export interface AdjustmentItemDTO extends FullAuditedEntityDto<string> {
  mmRefInventoryAdjustmentItemId: number;
  mmRefInventoryAdjustmentId: number;
  inventoryAdjustmentId?: string;
  productId?: string;
  qoh?: number;
  newQOH?: number;
  ssId?: number;
  lotSerialNo?: string;
  userId?: string;
  reasonText?: string;
  unitCost?: number;
  processed: number;
}

export interface InventoryTransferItemDTO extends FullAuditedEntityDto<string> {
  inventoryTransferId?: string;
  productId?: string;
  ssId?: number;
  quantity?: number;
  unitCost?: number;
  extendedCost?: number;
  shipmentComment?: string;
  productName?: string;
  inventoryTransferName?: string;
  tenantId?: string;
}
export interface CreateUpdateInventoryTransferDTO {
  csrId?: string;
  shippingMethodId?: string;
  sourceLocationId?: string;
  destinationLocationId?: string;
  specialInstructions?: string;
  transferId?: string;
  tenantId?: string;
  lockedBy?: string;
  lockedBySPID?: number;
  packed?: string;
  createdDate?: string;
  lockedOn?: string;
  status?: string;
  trackingNo: string;
  transfer: boolean;
  inventoryTransferItemDTO: CreateUpdateInventoryTransferItemDTO[];
}

export interface CreateUpdateInventoryTransferItemDTO {
  inventoryTransferId?: string;
  productId?: string;
  serialandLotNumebr?: string;
  quantity?: number;
  unitCost?: number;
  extendedCost?: number;
  shipmentComment?: string;
  mmInventroyId: string;
  tenantId?: string;
  itemId?: string;
}

export interface CreateUpdateInventoryTransferItemDTO {
  inventoryTransferId?: string;
  productId?: string;
  ssId?: number;
  quantity?: number;
  unitCost?: number;
  extendedCost?: number;
  productName: string;
  shipmentComment?: string;
  tenantId?: string;
}
export interface InventoryTransferItemDTO extends FullAuditedEntityDto<string> {
  inventoryTransferId?: string;
  productId?: string;
  ssId?: number;
  quantity?: number;
  unitCost?: number;
  extendedCost?: number;
  shipmentComment?: string;
  productName?: string;
  inventoryTransferName?: string;
  tenantId?: string;
}
export interface InventoryTransferDTO extends FullAuditedEntityDto<string> {
  csrId?: string;
  shippingMethodId?: string;
  shippingMethodName?: string;
  sourceLocationId?: string;
  sourceLocationName?: string;
  destinationLocationId?: string;
  destinationLocationName?: string;
  specialInstructions?: string;
  csrName?: string;
  tenantId?: string;
  lockedBy?: string;
  lockedByName?: string;
  lockedBySPID?: number;
  lockedOn?: string;
  status?: number;
  inventoryTransferItemDTO: InventoryTransferItemDTO[];
}

export interface ProductAllowableDetailsDTO extends AuditedEntityDto<string> {
  productId?: string;
  productName?: string;
  payerId?: string;
  payerName?: string;
  quantity: number;
  eligibleMonths: number;
  priceType: PriceType;
  allowableAmount: number;
  tenantId?: string;
  ProductTypes: string;
}
export interface SearchProductAllowablesDetailsDTO {
  productIds: string[];
  insuraceIds: string[];
  createdFromDate?: string;
  createdToDate?: string;
}

export interface ProductBrandDetailSearchDTO {
  productBrandDetailName?: string;
  status?: string;
  createdDate?: string;
}

export interface ProductBrandDetailsDTO extends FullAuditedEntityDto<string> {
  productBrandDetailName?: string;
  tenantId?: string;
}

export interface ProductCategoryDetailsDTO
  extends FullAuditedEntityDto<string> {
  productCategoryName?: string;
  status?: Status;
  tenantId?: string;
  mmRefferenceId?: number;
  name?: string;
  accessories?: number;
  showSettings?: number;
  ddOrder?: number;
  modifiedMmRefferenceId?: number;
  showMinPressure?: number;
  showMaxPressure?: number;
  showSettlingTime?: number;
  showIPAP?: number;
  showEPAP?: number;
  showRampPressure?: number;
  showRampTime?: number;
  showBiFlex?: number;
  showSetPressure?: number;
  showCFlex?: number;
  showRRBPM?: number;
  showIPAPmin?: number;
  showIPAPmax?: number;
  showEEP?: number;
  showSetVT?: number;
  showRate?: number;
  machinePoints?: string;
  pointMultiplier?: string;
  defaultPoints?: string;
  cashRxRequired?: string;
  serialLotNoRequired?: string;
  physicalInvWeekOfMonth?: string;
  physicalInvDayOfWeek?: string;
  low?: string;
  high?: string;
  mid?: string;
  updatedDate: string;
  brands: ProductBrandDetailsDTO[];
  creationTime?: string;
  lastModificationTime?: string;
  lastModifierId?: string;
}

export interface ProductCategoryDataDTO {
  productCategoryId?: string;
  productCategoryName?: string;
  status?: Status;
  tenantId?: string;
  mmRefferenceId?: number;
  name?: string;
  accessories?: number;
  showSettings?: number;
  ddOrder?: number;
  modifiedMmRefferenceId?: number;
  showMinPressure?: number;
  showMaxPressure?: number;
  showSettlingTime?: number;
  showIPAP?: number;
  showEPAP?: number;
  showRampPressure?: number;
  showRampTime?: number;
  showBiFlex?: number;
  showSetPressure?: number;
  showCFlex?: number;
  showRRBPM?: number;
  showIPAPmin?: number;
  showIPAPmax?: number;
  showEEP?: number;
  showSetVT?: number;
  showRate?: number;
  machinePoints?: string;
  pointMultiplier?: string;
  defaultPoints?: string;
  cashRxRequired?: string;
  serialLotNoRequired?: string;
  physicalInvWeekOfMonth?: string;
  physicalInvDayOfWeek?: string;
  low?: string;
  high?: string;
  mid?: string;
  id: string;
  createdBy?: string;
  lastModifyBy?: string;
  updatedDate?: string;
  isNewSetup?: boolean;
  brands: ProductBrandDetailsDTO[];
  creationTime?: string;
  lastModificationTime?: string;
  lastModifierId?: string;
}

export interface ProductDetailsDTO extends AuditedEntityDto<string> {
  productCode?: string;
  productDescription?: string;
  hcpcCode?: string;
  mainProductName?: string;
  minimumQuantity: number;
  availableQuantity: number;
  tenantId?: string;
  mmReferrenceId?: number;
  manufacturerId?: string;
  categoryId?: string;
  notes?: string;
  unitCost?: number;
  settings?: number;
  inactive?: number;
  hide?: number;
  fulfillment?: number;
  cdsPriceVerif?: number;
  cdsPartVerif?: number;
  hasPPMPart?: number;
  hasCDSPart?: number;
  cash?: string;
  upc?: string;
  ticketExtraNote?: string;
  weight_oz?: string;
  manufatureName?: string;
  categoryName?: string;
  status: Status;
  productTypes?: ProductType;
  modifiedBy?: string;
  createdBy?: string;
  binLocation?: string;
  sizeId?: string;
  hcpcCodes: ProductHcpcCodeDTO[];
  creationTime?:string;
  lastModificationTime?:string;
}
export interface ProductDataDTO {
  productCode?: string;
  productDescription?: string;
  hcpcCode?: string;
  mainProductName?: string;
  minimumQuantity: number;
  availableQuantity: number;
  tenantId?: string;
  mmReferrenceId?: number;
  manufacturerId?: string;
  categoryId?: string;
  notes?: string;
  unitCost?: number;
  settings?: number;
  inactive?: number;
  hide?: number;
  fulfillment?: number;
  cdsPriceVerif?: number;
  cdsPartVerif?: number;
  hasPPMPart?: number;
  hasCDSPart?: number;
  cash?: string;
  upc?: string;
  ticketExtraNote?: string;
  weight_oz?: string;
  manufatureName?: string;
  categoryName?: string;
  status: Status;
  productTypes?: ProductType;
  modifiedBy?: string;
  createdBy?: string;
  binLocation?: string;
  hcpcCodes: ProductHcpcCodeDTO[];
  id?: string;
  creationTime?: string;
  lastModificationTime?: string;
  lastModifierId?: string;
}
export interface MmOrderItemExchanges extends FullAuditedAggregateRoot<string> {
  newOrderItemId?: string;
  originalOrderItemId?: string;
  userId?: string;
  createdDate?: string;
  originalProductId?: string;
  originalOrderNo?: number;
  originalSuppliesDate?: string;
  originalOrderCreatedBy?: string;
  qtyExchanged: number;
  needsApproved: boolean;
  returnExchangeReasonId?: string;
  orderReturnExchangeItemConditionId?: string;
  rma_number?: string;
  mg_number?: string;
  returnFlag: boolean;
  restocked: boolean;
  received?: string;
  receivedUserId?: string;
  receivedSerialLotNo?: string;
  otherReasonText?: string;
  tenantId?: string;
  status: Status;
}
export interface OrderNumberDetails {
  orderNumber: number;
  status?: string;
  orderStatusId?: string;
  mmOrderStatusId: number;
}
export interface CreateUpdateMMOrderItemDTO {
  tenantId?: string;
  primaryInsuranceId?: string;
  mmReferenceOrderItemId: number;
  mmOrderId: string;
  mmProductId: string;
  qty: number;
  sizeId?: string;
  allowable: number;
  accessory: number;
  backOrderedSince?: string;
  lineItemNotes?: string;
  productCode?: string;
  serialLotName?: string;
  sizeName?: string;
  trackingNumber?: string;
  productDescription?: string;
  patientQtyOnHand: number;
  points_Cal: number;
  serialLotNo: string;
  itemCost: number;
  fixedPoints: number;
  unitCost: number;
  unitAllowable: number;
  unitAccessory: number;
  exchange: boolean;
  return: boolean;
  discountPercent: number;
  specialItem: number;
  addOnFromSuggestions: number;
  remoteTransferSuccessful: number;
  status: number;
  mmOrderItemId?: string;
  awaitDlvry: number;
  pendpalOrder: number;
  hcpcList: OrderProductHcpcCode[];
  mmCategoryReferenceId: number;
  bCashOrder: boolean;
  bIsNewSetup: boolean;
  isInsuranceCategory: boolean;
  isStockadded?: number;
  packedby?: string;
  actualAccessory: number;
  discountAccessory: number;
  mmCategoryId?: string;
  showSettings?: number;
  minimumQuantity: number;
  sMinStock: boolean;
  stockCount: number;
  shippingDate?: string;
  locationId?: string;
  totalAmount: number;
  serialLotNumber?: string; //Serial lot Number Manual Entry Field
  itemSettings?:CreateUpdateMmOrderItemSettingsDTO;
  categoryName?:string;
  maximumQuantity?: number;
  minQuantity?: number;
  orderedQnty?:number;
}


export interface RentalListDTO {
  rentalId?: string;
  mmOrderId?: string;
  mmRefereceOrderId: number;
  patientId?: string;
  chartNo?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  fullName?: string;
  totalmonths: number;
  totalAmount: number;
  monthAmount: number;
  dosDate?: string;
  rentalStatus?: string;
}
export interface CreateUpdateProductDetailsDTO {
  hcpcCodes: ProductHcpcCodeDTO[];
  mainProductName: string;
  productCode?: string;
  productDescription: string;
  minimumQuantity: number;
  status: Status;
  notes?: string;
  unitCost?: number;
  settings?: number;
  inactive?: number;
  hide?: number;
  fulfillment?: number;
  cdsPriceVerif?: number;
  cdsPartVerif?: number;
  hasPPMPart?: number;
  hasCDSPart?: number;
  cash?: string;
  upc?: string;
  ticketExtraNote?: string;
  weight_oz?: string;
  manufacturerId?: string;
  categoryId?: string;
  binLocation?: string;
  productType?: ProductType;
  sizeId: string;
  mmReferrenceId?: number;
}
// export interface ProductHcpcCodeDTO extends AuditedEntityDto<string> {
//   hcpcCode: string;
//   productId: string;
//   line?: number;
//   TenantId?: string;
//   status: Status;
//   productName?: string;
// }

export interface ProductHcpcCodeDTO extends FullAuditedEntityDto<string> {
  hcpcCode: string;
  productId: string;
  line?: number;
  tenantId?: string;
  status: Status;
  productName?: string;
}

export interface CreateUpdateProductHcpcCodeDTO {
  hcpcCode: string;
  productId: string;
  line?: number;
  tenantId?: string;
  status: Status;
}

export interface ProductDetailsSearchDTO {
  productCode: number;
  productDescription?: string;
  hcpcCode?: string;
  mainProductName?: string;
  minimumQuantity: number;
  availableQuantity: number;
  status: Status;
  tenantId?: string;
  dtFromDate?: string;
  dtToDate?: string;
}

export interface ProductStockDetailsDTO extends AuditedEntityDto<string> {
  brandId?: string;
  categoryId?: string;
  productId?: string;
  vendorId?: string;
  lotNo?: string;
  pricePerUnit: number;
  quantity: number;
  soldQuantity: number;
  productType: ProductType;
  tenantId?: string;
  isAllSerialNumbersUpdated?: string;
  ProductTypes?: string;
  productSerialNumberDetails: ProductLotDetailsDTO[];
}
export interface ProductLotDetailsDTO {
  productDetailsId?: string;
  serailNo?: string;
  orderId?: string;
  defaultOrderId?: string;
  soldDate?: string;
  status?: string;
}
export interface ResuplyProductDetailSearchDTO {
  mainproductId?: string;
  productCode: number;
  productDescription?: string;
  hcpcCode?: string;
  resupplyProductName?: string;
  minimumQuantity: number;
  availableQuantity: number;
  stockStatus?: string;
  status: boolean;
  tenantId?: string;
  dtFromDate?: string;
  dtToDate?: string;
  mainProductName?: string;
}

export interface ResupplyProductDetailsDTO extends AuditedEntityDto<string> {
  mainproductId?: string;
  productCode: number;
  productDescription?: string;
  hcpcCode?: string;
  resupplyProductName?: string;
  minimumQuantity: number;
  availableQuantity: number;
  stockStatus?: string;
  status: Status;
  tenantId?: string;
  mainProductName?: string;
}

export interface VendorDetailsDTO extends AuditedEntityDto<string> {
  mmReferrenceId?: number;
  ddOrder?: number;
  vendorCode?: string;
  vendorName?: string;
  addressLine1?: string;
  addressLine2?: string;
  primaryPhoneNumber?: string;
  secondaryPhoneNumber?: string;
  emailId?: string;
  fax?: string;
  fobDelivery?: string;
  contactPersonName?: string;
  contactNo?: string;
  contactPersonMailId?: string;
  comments?: string;
  country?: string;
  state?: string;
  city?: string;
  zip?: string;
  terms?: string;
  shipVia?: string;
  webSite?: string;
  remarks?: string;
  orderEmail?: string;
  lotNumberLength?: number;
  accountNumber?: string;
  serialNumberLength?: string;
  status?: Status;
  tenantId?: string;
  creationTime?: string;
  lastModificationTime?: string;
  lastModifierId?: string;
  productCategories: ProductCategoryDetailsDTO[];
}

export interface VendorDataDTO {
  mmReferrenceId?: number;
  ddOrder?: number;
  vendorCode?: string;
  vendorName?: string;
  addressLine1?: string;
  addressLine2?: string;
  lastModifyBy?: string;
  createdBy?: string;
  primaryPhoneNumber?: string;
  secondaryPhoneNumber?: string;
  emailId?: string;
  fax?: string;
  fobDelivery?: string;
  contactPersonName?: string;
  contactNo?: string;
  contactPersonMailId?: string;
  comments?: string;
  country?: string;
  state?: string;
  city?: string;
  zip?: string;
  terms?: string;
  shipVia?: string;
  webSite?: string;
  remarks?: string;
  orderEmail?: string;
  lotNumberLength?: number;
  accountNumber?: string;
  serialNumberLength?: string;
  status?: Status;
  tenantId?: string;
  isBarcodeSplitup: boolean;
  updatedDate?: string;
  productCategories: ProductCategoryDetailsDTO[];
  id?: string;
  creationTime?: string;
  lastModificationTime?: string;
  lastModifierId?: string;
}

export interface SearchProductStockDetailsDTO {
  brandId?: string;
  brandName?: string;
  branchName?: string;
  categoryId?: string;
  categoryName?: string;
  productId?: string;
  productName?: string;
  vendorId?: string;
  vendorName?: string;
  lotNo?: string;
  pricePerUnit: number;
  quantity: number;
  soldQuantity: number;
  balanceQuantity: number;
  status: ProductStockDetailStatus;
  id?: string;
  description?: string;
  priceType?: PriceType;
  productallowedamount?: number;
  productType?: ProductType;
  branchId?: string;
  binLocation?: string;
  productCode?: string;
  totalUnitCost?: number;
  sizeId?: string;
  sizeName?: string;
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  lastDispensedDate?: string;
  lastReceivedDate?: string;
}

export interface UploadProductStockSerialNumbersDTO {
  fileContentAsBase64String: string;
  fileName: string;
  id: string;
  lotNumber?: string;
}
export interface ProductStockDetailsByBranchDTO {
  branchId?: string;
  branchName?: string;
  totalQuantity: number;
  totalUnitCost?: number;
  lastReceivedDate?: string;
  lastDispensedDate?: string;
}

export enum PriceType {
  Purchase = 'Purchase',
  Rental = 'Rental',
}

export const priceTypeOptions = mapEnumToOptions(PriceType);
export enum ProductStockDetailStatus {
  StockInHand = 'Stock In Hand',
  StockCompleted = 'Stock Completed',
  All = 'All',
}

export const stockDetailsStatusOptions = mapEnumToOptions(
  ProductStockDetailStatus
);
export interface ZeroStockProductDetailsDTO {
  productName: string;
  brandName: string;
  categoryName: string;
  pricePerUnit: number;
  lotNo: number;
  createdDate?: string;
  soldQuantity: number;
  quantity: number;
}
export interface ProdcutDetailsforStock {
  productId: string;
  productName: string;
  productCode?: string;
  sMinStock: boolean;
  stockCount: number;
  productDescription : string;
}
export interface InventoryLimtDTO {
  inventoryLimit: number;
  onHandTotal: number;
  onOrder: number;
}

export interface CreateUpdateRequisitionDTO {
  requisitionId?: string;
  requisitionDate?: string;
  requestedUserId?: string;
  shippingMethodId?: string;
  tenantId?: string;
  destinationLocationId?: string;
  mmRefLocId?: number;
  requisitionTotal?: number;
  itemList: RequisitionProductDTO[];
  noOfDays?: number;
  specialInstruction: string;
  defaultRequisitionId: number;
  inventoryLimit: string;
  onHandTotal: string;
  onOrder: string;
  requisitionStatus?: string;
  requisitionStatusDetails: RequisitionStatusDTO;
  poRaised?: boolean;
}
export interface InventorySerialLotNoDTO extends FullAuditedEntityDto<string> {
  mmReferenceSSID: number;
  lotSerialNo?: string;
  status: Status;
}
export interface CreateUpdateInventorySerialLotNoDTO {
  mmReferenceSSID: number;
  lotSerialNo?: string;
  status: Status;
}

export interface RequisitionDTO extends FullAuditedEntityDto<string> {
  tenantId?: string;
  requisitionDate?: string;
  requestedUserId?: string;
  shippingMethodId?: string;
  destinationLocationId?: string;
  requisitionTotal?: number;
  itemList: RequisitionItemListDTO[];
  noOfDays?: number;
  specialInstruction?: string;
  defaultRequisitionId: number;
  inventoryLimit?: number;
  onHandTotal?: number;
  onOrder?: number;
  poRaised?: boolean;
  requisitionStatus?: string;
  referenceNumber?: string;
  mmRefLocId?:number;
  requisitionStatusDetails: RequisitionStatusDTO;
}
export interface RequisitionItemListDTO {
  productId?: string;
  reqId?: string;
  mmReferenceReqItemId?: string;
  thisOrder?: number;
  productCode: string;
  productType: string;
  manufacturer: string;
  category: string;
  manufacturerId: string;
  categoryId: string;
  availQty: string;
  description: string;
  pendPatOrder: string;
  requestedQuantity: number;
  receivedQuantity: number;
  balanceQuantity: number;
  unitCost: number;
  extendedCost: number;
  serialLotNumber: string;
  backOrderSince: string;
  itemStatus?: string;
  PORaised?: boolean;
  status: number;
}
export interface CreateUpdateRequisitionStatusDTO {
  mmReferenceRequisitionId?: number;
  requisitionStatus?: string;
  fgColor?: string;
  bgColor?: string;
  status: Status;
}

export interface RequisitionStatusDTO extends AuditedEntityDto<string> {
  mmReferenceRequisitionId?: string;
  requisitionStatus: string;
  fgColor?: string;
  bgColor?: string;
  status: number;
  shortCodeId?: string;
}
export interface RequisitionProductDTO {
  sortNumber?: number;
  availQty: number;
  category: string;
  dailyAvgDesp: number;
  description: string;
  extendedCost?: number;
  manufactureId?: string;
  manufacturer: string;
  pendPatOrder: number;
  productCategoryId?: string;
  productCode: string;
  productId: string;
  qtyOnHand: number;
  quantity: number;
  recOrderQty: number;
  status: string;
  thisOrder: number;
  unitCost?: number;
  totalCost?: number;
}

export interface Runtransfer {
  reqId?: string;
  packedBy?: string;
  lockedBy?: string;
  trackingNo?: string;
  itemIds: string[];
}

export interface RequisitionTrackingDTO extends FullAuditedEntityDto<string> {
  requisitionTrackingId: number;
  trackingNo?: string;
  date?: string;
  requisitionId?: string;
  packedBy?: string;
  verifiedBy?: string;
  status: Status;
}

export interface CreateUpdateTrackingDTO {
  requisitionTrackingId: number;
  trackingNo: string;
  date?: string;
  requisitionId?: string;
  packedBy: string;
  verifiedBy: string;
  status: Status;
  specialInstructions: string;
  trackingItemList: CreateUpdateRequisitionTrackingItemsDTO[];
}

export interface CreateUpdateRequisitionTrackingItemsDTO {
  requisitionTrackingId?: string;
  requisitionItemId: string;
  qtyShipped: number;
  status: Status;
}

export interface CreateUpdateRequisitionTrackingDTO {
  requisitionTrackingId: number;
  trackingNo: string;
  date?: string;
  requisitionId?: string;
  packedBy: string;
  verifiedBy: string;
  status: Status;
}
export interface RequisitionDataDTO {
  reqId?: string;
  requisitioStatusId?: string;
  requestedUserId?: string;
  requitionLocationId?: string;
  requisitionId: number;
  tenantId?: string;
  requisitionDate?: string;
  lastModifiedDate?: string;
  requestedUser?: string;
  requestLocationName?: string;
  shippingMethod?: string;
  shortCodeId: string;
  status?: string;
  specialInstruction: string;
  requitionStatus?: number;
  extendedCost?: number;
  CreatedDate: string;
}

export interface RequisitionProductListDTO {
  productId?: string;
  packedBy?: string;
  verifiedBy?: string;
  reqId?: string;
  productCode?: string;
  productType?: string;
  productDescription?: string;
  binLocation?: string;
  shipmentComment?: string;
  receivedQuantity: number;
  balanceQuantity: number;
  requestedQuantity: number;
  thisOrder: number;
  unitCost: number;
  extendedCost: number;
  serialLotNumber?: string;
  packedByName?: string;
  verifiedByName?: string;
  backOrderSince?: string;
  processedDate?: string;
  shippingDate?: string;
  processedBy?: string;
  tracking?: string;
  whInventory: number;
  whOrder: number;
  whAvailability?: string;
  requestItemId?: string;
  destinationLocationId?: string;
  poRaised?: number;
}
export interface ReceiveRequitioDTO {
  productId?: string;
  reqId?: string;
  tenantId?: string;
  serialLotNumber?: string;
  receivedQuantity: number;
  thisOrder: number;
  trackingNumber: string;
  requisitionItemId?: string;
}
export interface ReqDefaultListDTO {
  shippingMethodId?: string;
  locationId?: string;
  inventoryLimit?: number;
  onHandTotal?: number;
  onOrder?: number;
}
export interface requsitionsforPurchaseDto {
  requisitionId: string[];
  manufacturerId?: string;
  mmReferrenceId: number;
  manufacturerCode?: string;
  manufacturerName?: string;
  productId?: string;
  productCode?: string;
  productDescription?: string;
  quantity: number;
  unitCost: number;
  totalCost: number;
}
export interface CreateUpdateMMPurchaseOrderDTO {
  manufacturerId?: string;
  defaultPurchaseOrderId: number;
  purchaseOrderStatusId?: string;
  orderedBy?: string;
  tenantId?: string;
}

export interface CreateUpdateMMPurchaseOrderItemsDto {
  purchaseOrderId?: string;
  requisitionId: string[];
  productId?: string;
  orderedQuantity: number;
  receivedQuantity: number;
  unitCost: number;
  totalCost: number;
  tenantId?: string;
}
export interface MMPurchaseOrderDTO extends FullAuditedEntityDto<string> {
  manufacturerId?: string;
  defaultPurchaseOrderId: number;
  purchaseOrderStatusId?: string;
  orderedBy?: string;
  tenantId?: string;
}

export interface MMPurchaseOrderItemsDTO extends FullAuditedEntityDto<string> {
  purchaseOrderId?: string;
  requisitionId: string[];
  productId?: string;
  orderedQuantity: number;
  receivedQuantity: number;
  unitCost: number;
  totalCost: number;
  tenantId?: string;
}
export interface createPurchaseOrderDTO {
  manufacturerId?: string;
  orderedBy?: string;
  purchaseOrderItems: MMPurchaseOrderItemsDTO[];
  tenantId?: string;
}
export interface PackingListDTO {
  referenceNumber?: string;
  Location?: string;
  packedPerson?: string;
  productCode: string;
  productDescription: string;
  Qty?: string;
  binLocation?: string;
  DestinationLocationId?: string;
  packedBy: string;
}
export interface POSummary {
  manufacturerId?: string;
  mmManufacturerId?: string;
  manufacturerName?: string;
  requisitionCount: number;
  totalCost: number;
}
export interface UpdateRequisitionReceiveQtyDTO {
  lstReqItemDetails: RequisitionProductListDTO[];
  isCompleted: boolean;
}

export interface aptClasslistDTO {
  aptClassId?: string;
  mmReferenceId: number;
  description?: string;
}
export interface InventorySerialLotNumberQuantityDto extends FullAuditedAggregateRoot<string> {
  lotSerialNo?: string;
  quantiy: number;
}

export interface CreateUpdateNewProductDetailsDTO extends AuditedEntityDto<string> {
  productCode?: string;
  mainProductName?: string;
  hcpcCode: string[];
  unitCost?: number;
  createdBy?: string;
  modifiedBy?: string;
  creationTime?: string;
  lastModificationTime?: string;
}
