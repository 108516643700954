import { checkList } from './../../../../billing/src/app/check-eft-selection/check-eft-selection.component';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { InboundStatusShortCode } from 'projects/shared/src/app/data/common-data';
import { MyQueueBinStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { patientEntryRequestDto } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';

@Component({
  selector: 'app-document-bin-queue',
  templateUrl: './document-bin-queue.component.html',
  styleUrls: ['./document-bin-queue.component.scss'],
})
export class DocumentBinQueueComponent implements OnInit {

  getInboundTableData:Subscription;
  loadInboundData:boolean = false;
  inboundStatusIds = InboundStatusShortCode;
  MyQueueBinStatus = MyQueueBinStatus;
  activeTabBin: MyQueueBinStatus;
  documentBinFilterForm: FormGroup;
  @ViewChild('tableContainer', { read: ViewContainerRef })
  tableContainer: ViewContainerRef;
  inboundTableData: any;
  inboundStatusId: string;
  unprocessedTabCount:number = 0;
  verificationTabCount:number = 0;
  precertTabCount:number = 0;
  medicareTabCount:number = 0;
  scheduleTabCount:number = 0;
  readyTabCount: number = 0;

  @Output() patientEntryCount: EventEmitter<number> =
  new EventEmitter<number>();
  constructor(private fb: FormBuilder, private title: Title,private inboundDocumentService:InboundDocumentService, private commonService: CommonService,) {}

  ngOnInit() {
    this.initializeForms();
    this.activeTabBin = MyQueueBinStatus.Unprocessed;
    this.inboundStatusId = this.inboundStatusIds.unprocessedFax;
    this.getFaxFileterList(this.inboundStatusIds.unprocessedFax);
    this.title.setTitle('Qsecure | My Queue');
  }

  ngAfterViewInit() {
    // Manually trigger DataTable initialization
    // $(this.tableContainer.element.nativeElement).DataTable(
    //   this.dtDocumentBinTableOptions
    // );
  }
  initializeForms() {
    this.documentBinFilterForm = this.fb.group({
      drpPatient: new FormControl(''),
      txtPatientFilter: new FormControl(''),
      drpUploadType: new FormControl(''),
      txtUploadTypeFilter: new FormControl(''),
      dtReceivedFromDate: new FormControl(''),
      dtReceivedToDate: new FormControl(''),
      drpPrimaryInsurance: new FormControl(''),
      txtPrimaryInsuranceFilter: new FormControl(''),
      drpPractice: new FormControl(''),
      txtPracticeFilter: new FormControl(''),
    });
  }
  sortColumn(columnIndex: number) {
    // const table = $(this.tableContainer.element.nativeElement).DataTable();
    // console.log(table);
    // const currentOrder = table.order();
    // console.log(currentOrder);
    // const newOrder = currentOrder[0][0] === columnIndex && currentOrder[0][1] === 'asc' ? 'desc' : 'asc';
    // table.order([columnIndex, newOrder]).draw();

    const table = $(this.tableContainer.element.nativeElement).DataTable();
    const currentOrder = table.order();
    const newOrder =
      currentOrder.length > 0 &&
      currentOrder[0][0] === columnIndex &&
      currentOrder[0][1] === 'asc'
        ? 'desc'
        : 'asc';
    table.order([columnIndex, newOrder]).draw();
  }

  binActiveTab(data: any) {
    this.activeTabBin = data?.status;
    this.inboundStatusId = data?.inboundStatusIds;
    this.getFaxFileterList(data?.inboundStatusIds);
  }

getFaxFileterList(inboundStatusIds: any) {
    this.loadInboundData = true;
    if (this.getInboundTableData) {
        this.getInboundTableData.unsubscribe();
    }

    let wonaceRequestDto : patientEntryRequestDto = {
      statusCode: this.inboundStatusId,
      // faxId: [],
      locationId: [],
      priPolicyId: [],
      secPolicyId: [],
      terPolicyId: [],
      csrId: [],
      authStatus: [],
      practiceId: [],
      sorting: 0,
      calendarStatus: [],
      verificationStatus:  [],
      medicareChecklistStatus: [],
      tatFilter: [],
      splitfaxId: [],
      joinfaxId: [],
    };
    
    this.getInboundTableData = this.inboundDocumentService.getInboundFaxDocsForWonaceByRequestDto(wonaceRequestDto).subscribe(
        (response) => {
            this.menus = [
                {
                    status: MyQueueBinStatus.Unprocessed,
                    color: '#faa307',
                    count: response?.unprocessedCount ?? 0,
                    arrowclass: 'unprocessed-down',
                    inboundStatusIds: this.inboundStatusIds?.unprocessedFax ?? ''
                },
                {
                    status: MyQueueBinStatus.Verification,
                    color: '#b56576',
                    count: response?.verificationCount ?? 0,
                    arrowclass: 'verification-down',
                    inboundStatusIds: this.inboundStatusIds?.verification ?? ''
                },
                {
                    status: MyQueueBinStatus.Precert,
                    color: '#c77dff',
                    count: response?.precetCount ?? 0,
                    arrowclass: 'precert-down',
                    inboundStatusIds: this.inboundStatusIds?.preCert ?? ''
                },
                {
                    status: MyQueueBinStatus.Checklist,
                    color: '#57cc99',
                    count: response?.medicareCount ?? 0,
                    arrowclass: 'checklist-down',
                    inboundStatusIds: this.inboundStatusIds?.medicare ?? ''
                },
                {
                    status: MyQueueBinStatus.Schedule,
                    color: '#f357ab',
                    count: response?.sheduleCount ?? 0,
                    arrowclass: 'schedule-down',
                    inboundStatusIds: this.inboundStatusIds?.scheduled ?? ''
                },
                {
                    status: MyQueueBinStatus.ReadyforOrder,
                    color: '#5ea5d0',
                    count: response?.readyCount ?? 0,
                    arrowclass: 'order-down',
                    inboundStatusIds: this.inboundStatusIds?.ready ?? ''
                }
            ];

            this.inboundTableData = response?.ouput ?? [];
            this.inboundTableData = this.inboundTableData.map((inbound) => {
                const lastModifierByText = inbound?.lastModifierBy && inbound.lastModifierBy !== '-' ? ' by ' + inbound.lastModifierBy : '';
                const modifiedByAndDateText = this.commonService.getFormattedDateTimeZone(inbound?.lastModificationTime) + lastModifierByText;
                return {
                    ...inbound,
                    uploadedDate: this.commonService.getFormattedDateTimeZone(inbound?.uploadedDate),
                    preparedOn: this.commonService.getFormattedDateTimeZone(inbound?.preparedOn),
                    modifiedByAndDateText: modifiedByAndDateText
                };
            });
            this.loadInboundData = false;
        },
        (err) => {
            this.loadInboundData = false;
        }
    );
}
  getArrowClass(data: any): any {
    return { [data.arrowclass]: this.activeTabBin === data.status};
}


  menus= [
    {
      status: MyQueueBinStatus.Unprocessed,
      color: '#faa307',
      count: this.unprocessedTabCount,
      arrowclass:'unprocessed-down',
      inboundStatusIds: this.inboundStatusIds.unprocessedFax
    },
    {
      status: MyQueueBinStatus.Verification,
      color: '#b56576',
      count: this.verificationTabCount,
      arrowclass:'verification-down',
      inboundStatusIds: this.inboundStatusIds.verification
    },
    {
      status: MyQueueBinStatus.Precert,
      color: '#c77dff',
      count: this.precertTabCount,
      arrowclass:'precert-down',
      inboundStatusIds: this.inboundStatusIds.preCert
    },
    {
      status: MyQueueBinStatus.Checklist,
      color: '#57cc99',
      count: this.medicareTabCount,
      arrowclass:'checklist-down',
      inboundStatusIds: this.inboundStatusIds.medicare
    },
    {
      status: MyQueueBinStatus.Schedule,
      color: '#f357ab',
      count: this.scheduleTabCount,
      arrowclass:'schedule-down',
      inboundStatusIds: this.inboundStatusIds.scheduled
    },
    {
      status: MyQueueBinStatus.ReadyforOrder,
      color: '#5ea5d0',
      count: this.readyTabCount,
      arrowclass:'order-down',
      inboundStatusIds: this.inboundStatusIds.ready
    }
  ];
}
