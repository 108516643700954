// Created By Manikandan - 05 April 2024
// Last Modify By Manikandan - 18 April 2024
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { ClaimViewTabComponent } from '../claim-view-tab/claim-view-tab.component';
import { MasterProviderDTO } from 'projects/inventory/src/app/inventory-provider/inventory-provider.component';
import { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { MasterProviderService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import {
  PatientMasterDropdownDTO,
  PayorLevelDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { Title } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import { ClaimProcessingService } from '../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-processing.service';
import { insuranceTypeOptions } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data';
import { ClaimFormDetailsService } from '../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-form-details.service';
import { DatePipe } from '@angular/common';
import { ClaimProcessQuickOptions } from 'projects/shared/src/app/enums/allenums.enum';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  ClaimListDTO,
  ProviderFileCountDTO,
} from '../claim-proxy/claim-processing-management/rcm/billing-management/dto/models';
import { ToastrService } from 'ngx-toastr';
import { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { PaymentProvidersService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/payment-providers.service';
import { PaymentProvidersDTO } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/dto';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';

@Component({
  selector: 'app-claim-process-tab',
  templateUrl: './claim-process-tab.component.html',
  styleUrls: ['./claim-process-tab.component.scss'],
  providers: [DatePipe],
})
export class ClaimProcessTabComponent implements OnInit, OnDestroy {
  claimProcessFilterForm: FormGroup;
  paymentProviderFilterForm: FormGroup;
  defaultGuid = defaultGuid;
  totalSelectedOrders: number = 0;
  selectAll: boolean = false;
  lstClaimProcessList: any;
  isLoadProvider: boolean = false;
  lstProviders: ProviderFileCountDTO[] = []; // MasterProviderDTO[] = [];
  lstPayorLevel: PayorLevelDTO[] = [];
  ltPaymentProviders: PaymentProvidersDTO[] = [];
  public filteredPaymentProviders:
    | Observable<PaymentProvidersDTO[]>
    | undefined;
  selectedPaymentProvider: string;
  selectedRangeDate: any = { startDate: null, endDate: null };
  ranges: any;
  calendarLocale: LocaleConfig;
  dateRangStatus: boolean = false;
  calendarPlaceholder: string = '';
  lstQuickOptions = [
    {
      id: ClaimProcessQuickOptions.Today,
      text: 'Today',
    },
    {
      id: ClaimProcessQuickOptions.Tomorrow,
      text: 'Tomorrow',
    },
    {
      id: ClaimProcessQuickOptions.Yesterday,
      text: 'Yesterday',
    },
  ];
  payorLevels: { value: string; key: string }[] = [];
  subscription$: Subscription[] = [];
  claimIdArray = [];
  dtClaimTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },

    scrollCollapse: true,
    scrollY: '400px',
    scrollX: true,
    order: [],
    search: {
      smart: false,
    },
    columnDefs: [
      { targets: [0], orderable: false }, // Hide the first column (index 0)
      { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
    ],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Claim Process List',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
          // columns: [2, 3, 4, 5, 6, 7, 8, 9],
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2),:eq(3), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
    ],
  };
  fromDate: string = '';
  toDate: string = '';
  isClaimTableLoaded: boolean = true;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  private loadClaimProcessListAPICall: Subscription;
  private ManualProcessEDIAPICall: Subscription;
  private processZirmedApiCall: Subscription;
  txtProviderControl = new FormControl();
  ctFromdate: Date;
  ctTodate: Date;
  status: boolean;
  isShowSpinner: boolean = false;
  isZermidProcessing: boolean = false;
  filterProvider: any;
  constructor(
    private toaster: ToastrService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private masterProviderService: MasterProviderService,
    private patientDropdownService: PatientMasterDropdownService,
    private title: Title,
    private claimProcessingService: ClaimProcessingService,
    private claimFormDetailsService: ClaimFormDetailsService,
    private datePipe: DatePipe,
    private commonService: CommonService,
    private paymentProvidersService: PaymentProvidersService
  ) {
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'MM/DD/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      firstDay: 1,
    };

    this.ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
    };
  }
  payorLevel: any;
  ngOnInit(): void {
    this.dtClaimTableOptions.buttons[0].filename =
      'Claim Process List' +
      '-' +
      this.datePipe.transform(new Date(), 'MM-dd-yyyy');
    this.title.setTitle('Qsecure | Claim Process');
    this.payorLevels = insuranceTypeOptions?.map((e) => {
      let x = { value: e.value, key: e.key };
      return x;
    });

    this.initializeForms();
    // this.setDefaultDateValues();
    // this.claimDefaultInputs();
    this.loadDropdowns();
    this.loadPaymentProviders();
    //Provider search
    this.txtProviderControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.lstProviders = this.filterProvider.filter((dx) =>
        dx.provider.toLowerCase().includes(searchTerm)
      );
    });
    (this.selectedRangeDate.startDate = this.getCTStartOfMonth()),
      (this.selectedRangeDate.endDate = this.ctDateNow());
  }
  test() {
    alert(this.selectedPaymentProvider);
  }
  //Method to initialize the search form
  initializeForms() {
    this.claimProcessFilterForm = this.fb.group({
      drpProvider: new FormControl('', [Validators.required]),
      drpClaimRank: new FormControl(''),
      // dtDOSFrom: new FormControl('', [Validators.required]),
      // dtDOSTo: new FormControl('', [Validators.required]),
      // dtDOSFrom: new FormControl(''),
      txtProviderFilter: new FormControl(''),
      dtDOSTo: new FormControl(''),
      rbtnDate: new FormControl(''),
      drpQuickOptions: new FormControl(''),
      chkDateRequired: new FormControl(false),
      drpPaymentProvider: new FormControl(''),
      txtPaymentProvider: new FormControl(''),
    });
    this.paymentProviderFilterForm = this.fb.group({
      drpPaymentProvider: new FormControl(''),
      txtPaymentProvider: new FormControl(''),
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.next();
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
    if (this.loadClaimProcessListAPICall) {
      this.loadClaimProcessListAPICall.unsubscribe();
    }

    if (this.ManualProcessEDIAPICall) {
      this.ManualProcessEDIAPICall.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    // Check if there are unsaved changes (you'll need to implement this logic)
    if (this.isShowSpinner) {
      $event.returnValue = true;
    }
  }

  //To reload the Datatable instance
  reloadDatatable() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns.adjust().draw();
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  setDefaultDateValues(): void {
    this.claimProcessFilterForm.patchValue({
      // dtDOSFrom: this.getCTStartOfMonth(),
      dtDOSTo: this.getCTStartOfMonth() + '-' + this.ctDateNow(),
    });
  }

  getCTStartOfMonth(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setDate(1); // Set to the first day of the month
    dateObj.setHours(0, 0, 0, 0);
    //    const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    // console.log(formattedDate);

    return dateObj;
  }
  // //Open the Claim Form modal popup
  // openClaimForm(
  //   orderId: string,
  //   patientId: string,
  //   defaultSaleOrderId: string,
  //   claimId: string
  // ) {
  //   const dialogRef = this.dialog.open(ClaimViewTabComponent, {
  //     disableClose: true,
  //     data: {
  //       orderId: orderId,
  //       patientId: patientId,
  //       defaultSaleOrderId: defaultSaleOrderId,
  //       claimId: claimId,
  //     },
  //   });
  //   dialogRef.afterClosed().subscribe(() => {
  //     this.loadClaimProcessList();

  //     //  this.lstClaimProcessList.forEach(x => {
  //     //  if(x.claimId != defaultGuid){
  //     //   this.claimIdArray.push(x.claimId)
  //     //   x.selected = true

  //     //  }
  //     // })
  //   });
  // }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  ctDate(date: Date): Date {
    let usaTime = date.toLocaleString('en-US', { timeZone: 'America/Chicago' });
    const dateObj = new Date(usaTime);
    dateObj.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
    // const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    // console.log(formattedDate);
    return dateObj;
  }

  ctDateNow(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
    // const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    // console.log(formattedDate);
    return dateObj;
  }
  ctDateYesterday() {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    let dateObj = new Date(usaTime);
    dateObj.setDate(dateObj.getDate() - 1);
    dateObj.setHours(0, 0, 0, 0);
    return dateObj;
  }

  ctDateTomorrow() {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    let dateObj = new Date(usaTime);
    dateObj.setDate(dateObj.getDate() + 1);
    dateObj.setHours(0, 0, 0, 0);
    return dateObj;
  }

  ctDateTimeNow() {
    const usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    return usaTime;
  }

  ctDateTime(date: Date) {
    const usaTime = date.toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    return usaTime;
  }

  // scQuickOption(Event: Event) {
  //   if (
  //     this.claimProcessFilterForm.value.drpQuickOptions ===
  //     ClaimProcessQuickOptions.Yesterday
  //   ) {
  //     this.claimProcessFilterForm.patchValue({
  //       dtDOSFrom: new Date(
  //         new Date().setDate(this.ctDateNow().getDate() - 1)
  //       ).toISOString(),
  //       dtDOSTo: new Date(
  //         new Date().setDate(this.ctDateNow().getDate() - 1)
  //       ).toISOString(),
  //     });
  //   } else if (
  //     this.claimProcessFilterForm.value.drpQuickOptions ===
  //     ClaimProcessQuickOptions.Tomorrow
  //   ) {
  //     this.claimProcessFilterForm.patchValue({
  //       dtDOSFrom: new Date(
  //         new Date().setDate(this.ctDateNow().getDate() + 1)
  //       ).toISOString(),
  //       dtDOSTo: new Date(
  //         new Date().setDate(this.ctDateNow().getDate() + 1)
  //       ).toISOString(),
  //     });
  //   } else {
  //     this.claimProcessFilterForm.patchValue({
  //       dtDOSFrom: this.ctDateNow().toISOString(),
  //       dtDOSTo: this.ctDateNow().toISOString(),
  //     });
  //   }
  // }

  onDateChangeFrom(event: MatDatepickerInputEvent<Date>): void {
    // console.log('onDateChangeFrom event.value', event.value);
    // console.log(
    //   'onDateChangeFrom this.ctDate(event.value)' + this.ctDate(event.value)
    // );
    // console.log('onDateChangeFrom this.ctDateNow() ' + this.ctDateNow());
    // if(new Date()  === event.value) {
    // }else{
    // }
    // this.claimProcessFilterForm.patchValue({
    //   drpQuickOptions:null
    // });
  }
  // onDateChangeTo(event: MatDatepickerInputEvent<Date>): void {
  //   console.log('new Date(event.value)');
  //   console.log(new Date(event.value));
  //   // this.requiredEndDate();
  //   // console.log('onDateChangeTo event.value', event.value);
  //   // console.log(
  //   //   'onDateChangeTo new Date(this.ctTodate)' + new Date(this.ctTodate)
  //   // );
  //   console.log('onDateChangeTo this.ctDateTimeNow() ' + this.ctDateNow());

  //   if (new Date(event.value) === this.ctDateNow()) {
  //     // console.log(new Date(event.value) === this.ctDateNow());
  //     console.log('Equal Today');
  //     this.claimProcessFilterForm.patchValue({
  //       drpQuickOptions: ClaimProcessQuickOptions.Today,
  //     });
  //   } else if (new Date(event.value) === this.ctDateYesterday()) {
  //     // console.log(new Date(event.value) < this.ctDateNow());
  //     console.log('Equal Yesterday');
  //     this.claimProcessFilterForm.patchValue({
  //       drpQuickOptions: ClaimProcessQuickOptions.Yesterday,
  //     });
  //   } else if (new Date(event.value) === this.ctDateTomorrow()) {
  //     // console.log(new Date(event.value) > this.ctDateNow());
  //     console.log('Equal Tomorrow');
  //     this.claimProcessFilterForm.patchValue({
  //       drpQuickOptions: ClaimProcessQuickOptions.Tomorrow,
  //     });
  //   } else {
  //     this.claimProcessFilterForm.patchValue({
  //       drpQuickOptions: null,
  //     });
  //   }

  //   // this.dateRangeValidator;

  //   // console.log(new Date(event.value) === this.ctDateNow());

  //   // console.log(new Date(event.value) < this.ctDateNow());

  //   // console.log(new Date(event.value) > this.ctDateNow());

  //   // if (new Date(this.ctTodate) > this.ctDateNow()) {
  //   //   // Your logic here
  //   //   console.log('if');
  //   // } else {
  //   //   console.log('else');
  //   // }

  //   // console.log('onDateChangeTo event.value', event.value);
  //   // console.log('onDateChangeFrom event.value.toISOString() ' + event.value.toISOString())
  //   // console.log('onDateChangeTo new Date().toISOString() ' + new Date().toISOString())

  //   // if(new Date()  === event.value) {
  //   //   this.claimProcessFilterForm.patchValue({
  //   //     drpQuickOptions:ClaimProcessQuickOptions.Today
  //   //   });

  //   // }else{

  //   // }

  //   // this.claimProcessFilterForm.patchValue({
  //   //   drpQuickOptions:null
  //   // });
  // }
  //To load the dropdowns in the page
  loadDropdowns() {
    // const providers = this.masterProviderService
    //   .getListV1(new PagedAndSortedResultRequestDto())
    this.isLoadProvider = true;
    const providers = this.claimProcessingService
      .getMasterProviderWithFileCount()
      .subscribe(
        (response) => {
          this.lstProviders = response || [];
          this.filterProvider = this.lstProviders;
          this.isLoadProvider = false;
          if (this.lstProviders.length !== 0) {
            this.claimProcessFilterForm.patchValue({
              // drpProvider: response?.items?.[0].shortCodeId || null
              drpProvider: this.lstProviders?.[0].providerId || null,
            });
          }
          this.loadClaimProcessList();
        },
        (err) => {
          this.isLoadProvider = false;
        }
      );
    this.subscription$?.push(providers);

    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.PayorLevels];
    const patientDropdownSubscription = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: PatientMasterDropdownDTO = stateResponse;
          this.lstPayorLevel = response?.payorLevels;
          this.claimProcessFilterForm.patchValue({
            drpClaimRank: this.lstPayorLevel[0]?.payorLevelName || null,
          });
        },
        (err) => {}
      );
    this.subscription$.push(patientDropdownSubscription);
  }
  loadProviderAlone() {
    this.isLoadProvider = true;
    const providers = this.claimProcessingService
      .getMasterProviderWithFileCount()
      .subscribe(
        (response) => {
          this.lstProviders = response || [];
          this.filterProvider = this.lstProviders;
          this.isLoadProvider = false;
          if (this.lstProviders.length !== 0) {
            this.claimProcessFilterForm.patchValue({
              // drpProvider: response?.items?.[0].shortCodeId || null
              drpProvider: this.lstProviders?.[0].providerId || null,
            });
          }
          this.loadClaimProcessList();
        },
        (err) => {
          this.isLoadProvider = false;
        }
      );
    this.subscription$?.push(providers);
  }
  loadPaymentProviders() {
    const paymentProviders = this.paymentProvidersService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          console.log(response?.items);
          this.ltPaymentProviders = response?.items ?? [];

          this.filteredPaymentProviders = this.paymentProviderFilterForm
            .get('txtPaymentProvider')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltPaymentProviders.filter((option) =>
                  option?.providerName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

          if (this.ltPaymentProviders.length > 0) {
            this.paymentProviderFilterForm.patchValue({
              drpPaymentProvider:
                this.ltPaymentProviders.find((a) => a.shortCodeId == '001')
                  .id ?? defaultGuid,
            });
          }
        },
        (err) => {}
      );
    this.subscription$.push(paymentProviders);
  }

  //Default Input parameters for the Claim Process List
  claimDefaultInputs() {
    // this.fromDate = new Date().toLocaleDateString();
    // this.toDate = new Date().toISOString();
    this.fromDate = null;
    this.toDate = null;
    this.claimProcessFilterForm.patchValue({
      drpProvider: this.lstProviders?.[0].providerId || null,
      drpClaimRank: this.lstPayorLevel[0]?.payorLevelName || null,
      chkDateRequired: false,
      // dtDOSFrom: new Date().toISOString(),
      // dtDOSTo: new Date().toISOString(),
      // dtDOSFrom: this.getCTStartOfMonth(),
      // dtDOSTo:'',
      rbtnDate: '2',
    });
  }

  //To reset the form and load default filter details
  resetForm() {
    // this.claimProcessFilterForm.reset();
    this.claimDefaultInputs();
    this.loadClaimProcessList();
  }

  countsUpdate(data: any) {
    // this.claimIdArray.push(data.claimId);
    //  this.lstClaimProcessList.forEach(x => {
    if (data.total === null) {
      if (data.status === true) {
        if (data.claimId != defaultGuid) {
          this.claimIdArray.push(data.claimId);
          data.selected = true;
        }
      } else {
        const objWithIdIndex = this.claimIdArray.findIndex(
          (obj) => obj.claimId === data.claimId
        );
        this.claimIdArray.splice(objWithIdIndex, 1);
      }
      this.totalSelectedOrders = this.claimIdArray.length;
    } else {
      // if(data.total>0){
      //   this.validateClaimProcessButtons=true;
      // }
      this.claimIdArray = data.total;
      this.totalSelectedOrders = this.claimIdArray.length;
    }
    // })
  }
  selectCheckBox(event) {
    if (event.checked) {
      this.claimProcessFilterForm?.controls?.dtDOSTo?.setValidators([
        Validators.required,
      ]);
      this.claimProcessFilterForm?.controls?.dtDOSTo?.updateValueAndValidity();

      this.fromDate = this.convertISODateStringToCustomFormat(
        this.getCTStartOfMonth()
      );
      this.toDate = this.convertISODateStringToCustomFormat(this.ctDateNow());

      this.claimProcessFilterForm.patchValue({
        dtDOSTo:
          this.convertISODateStringToCustomFormat(this.getCTStartOfMonth()) +
          '-' +
          this.convertISODateStringToCustomFormat(this.ctDateNow()),
      });
      (this.selectedRangeDate.startDate = this.getCTStartOfMonth()),
        (this.selectedRangeDate.endDate = this.ctDateNow());
      // let startDate=(this.fromDate).convertTo().Date
      this.claimProcessFilterForm?.controls?.dtDOSTo?.setValidators([
        Validators.nullValidator,
      ]);
      this.claimProcessFilterForm?.controls?.dtDOSTo?.updateValueAndValidity();
      this.claimProcessFilterForm.patchValue({
        dtDOSTo: '',
      });
    } else {
      this.fromDate = null;
      this.toDate = null;
      (this.selectedRangeDate.startDate = null),
        (this.selectedRangeDate.endDate = null);
    }
  }

  datesUpdateds(e) {
    if (e.startDate != null || e.endDate != null) {
      this.dateRangStatus = true;
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      // if((endDate).toDateString()==='Invalid Date'){
      //   this.fromDate = this.datePipe.transform(startDate,"MM/dd/yyyy")
      //   this.toDate = null;
      //   e.endDate=null;
      //   this.claimProcessFilterForm
      //   .get('dtDOSTo')
      //   .setErrors({ invalid: true });
      //   console.log(this.claimProcessFilterForm.value.dtDOSTo);
      //   this.selectedRangeDate.startDate=e.startDate,
      //   this.selectedRangeDate.endDate= null
      //   console.log(this.claimProcessFilterForm.value.dtDOSTo);
      // }else{
      this.fromDate = this.datePipe.transform(startDate, 'MM/dd/yyyy');
      this.toDate = this.datePipe.transform(endDate, 'MM/dd/yyyy');
      // }
    } else {
      this.fromDate = null;
      this.toDate = null;
    }
  }
  ManualProcessEDI() {
    this.isShowSpinner = true;
    try {
      if (this.ManualProcessEDIAPICall) {
        this.ManualProcessEDIAPICall.unsubscribe();
      }
      // let gClaim: string[]=[];
      let gProvider: string = this.claimProcessFilterForm.value.drpProvider;
      // gClaim.push('3ee0e05f-b88b-692f-22b1-3a12220fa3bd');

      this.ManualProcessEDIAPICall = this.claimFormDetailsService
        .manualClaimProcessingByGClaimAndGProviderAndPaymentProviderId(
          this.claimIdArray,
          gProvider,
          this.selectedPaymentProvider ?? defaultGuid
        )
        .subscribe(
          (response) => {
            this.toaster.success(
              'The manual Process has been completed.',
              'Success'
            );
            this.loadProviderAlone();

            // Generate the 837 EDI format file data
            const fileData = response.sComment;

            // Create a Blob object from the file data
            const blob = new Blob([fileData], { type: 'text/plain' });

            // Create a download link for the Blob object
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = '837_EDI_Format.txt';

            // Trigger the download by programmatically clicking the link
            downloadLink.click();

            const index = this.lstProviders.findIndex(
              (obj) => obj.providerId === response?.providerId
            );
            if (index !== -1) {
              this.lstProviders[index].provider = response?.provider;
            }

            // this.loadClaimProcessList();

            // this.lstClaimProcessList.filter((option) =>
            //   option?.claimId.includes(this.claimIdArray)
            // );

            // const cindex = this.lstClaimProcessList.findIndex(
            //   (obj) => obj.claimId === this.claimIdArray
            // );
            // if (index !== -1) {
            //   this.branchDetails[index] = response; //Replace the Object
            //   const objectToMove = this.branchDetails.splice(index, 1)[0]; // Remove and get the object
            //   this.branchDetails.unshift(objectToMove);
            // }
          },
          (error) => {
            console.error(error);
            setTimeout(() => {
              this.isShowSpinner = false; // Enable the checkbox
            }, 1000);
            this.loadClaimProcessList();
          },
          () => {
            // This block will be executed on completion
            setTimeout(() => {
              this.isShowSpinner = false; // Enable the checkbox
            }, 1000);
            this.loadClaimProcessList();
          }
        );
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        this.isShowSpinner = false; // Enable the checkbox
      }, 1000);
      this.loadClaimProcessList();
    } finally {
    }
  }
  //To load the dates based on the selected date filter
  loadDates(event$: any) {
    const currentDate = new Date();
    const selectedValue = event$.value;
    if (selectedValue == '1') {
      const yesterdayDate = new Date(currentDate);
      yesterdayDate.setDate(currentDate.getDate() - 1);

      this.fromDate = yesterdayDate.toLocaleDateString();
      this.toDate = yesterdayDate.toISOString();

      // dtDOSTo: new Date().toISOString(),
    } else if (selectedValue == '2') {
      this.fromDate = new Date().toLocaleDateString();
      this.toDate = new Date().toISOString();
    } else if (selectedValue == '3') {
      const tommorowDate = new Date(currentDate);
      tommorowDate.setDate(currentDate.getDate() + 1);

      this.fromDate = tommorowDate.toLocaleDateString();
      this.toDate = tommorowDate.toISOString();
    }
  }

  // //Validate the Claim Process methods
  // get validateClaimProcessButtons(): boolean {
  //   return (
  //     this.lstClaimProcessList?.filter((item) => item.selected == true).length >
  //     0
  //   );
  //   // return true;
  // }

  check() {}
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }
  //To Load the Claim Process List
  loadClaimProcessList() {
    if (this.claimProcessFilterForm.valid) {
      if (this.loadClaimProcessListAPICall) {
        this.loadClaimProcessListAPICall.unsubscribe();
      }
      this.totalSelectedOrders = 0;
      this.claimIdArray = [];

      this.isClaimTableLoaded = false;
      let fDate = this.fromDate;
      let tDate = this.toDate;

      // if (this.claimProcessFilterForm.value.dtDOSTo.startDate!==null) {
      //   fDate = this.convertISODateStringToCustomFormat(
      //     this.claimProcessFilterForm.value.dtDOSTo.toString().split('-')[0]
      //   );
      // }

      // if (this.claimProcessFilterForm.value.dtDOSTo.endDate !==null) {
      //   tDate = this.convertISODateStringToCustomFormat(
      //     this.claimProcessFilterForm.value.dtDOSTo.toString().split('-')[1]
      //   );
      // }

      this.loadClaimProcessListAPICall = this.claimProcessingService
        .getClaimProcessingListByDtFromDateAndDtToDateAndSProviderAndSRank(
          fDate,
          tDate,
          this.claimProcessFilterForm.value.drpProvider,
          this.claimProcessFilterForm.value.drpClaimRank
        )
        .subscribe(
          (response) => {
            this.lstClaimProcessList = response.items;
            this.lstClaimProcessList = this.lstClaimProcessList.map((claim) => {
              return {
                ...claim,
                dos: this.commonService.getFormattedDateZone(claim.dos),
              };
            });
            this.lstClaimProcessList = this.lstClaimProcessList.map((data) => ({
              ...data,
              selected: false,
            }));

            // this.lstClaimProcessList.forEach((x) => {
            //   if (x.claimId != defaultGuid) {
            //     x.selected = true;
            //     let index = this.claimIdArray.indexOf(x.claimId);
            //     if (index !== -1) {
            //       // this.claimIdArray.splice(index, 1);
            //     } else {
            //       this.claimIdArray.push(x.claimId);
            //       this.totalSelectedOrders = this.claimIdArray.length;
            //     }
            //   } else {
            //     x.selected = false;
            //   }
            // });
            //  })

            this.isClaimTableLoaded = true;
          },
          (err) => {
            this.isClaimTableLoaded = true;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    }
  }
  // Custom validator function to check if from date is greater than to date
  // dateRangeValidator: ValidatorFn = (
  //   control: AbstractControl
  // ): { [key: string]: any } | null => {
  //   const from = control.get('dtDOSFrom')?.value;
  //   const to = control.get('dtDOSTo')?.value;
  //   if (from && to && from > to) {
  //     return { dateRangeError: true };
  //   }
  //   return null;
  // };
  convertISODateStringToCustomFormat(isoDateString: any): string {
    const inputDate = new Date(isoDateString);

    // Extracting date components
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDate.getDate().toString().padStart(2, '0');
    const year = inputDate.getFullYear().toString();

    // Formatted date string in MM/DD/YYYY format
    const formattedDateString = `${month}/${day}/${year}`;

    return formattedDateString;
  }
  // requiredEndDate() {
  //   const from = this.claimProcessFilterForm.get('dtDOSFrom')?.value;
  //   const to = this.claimProcessFilterForm.get('dtDOSTo')?.value;
  //   if (from !== null) {
  //     this.claimProcessFilterForm?.controls?.dtDOSTo?.setValidators([
  //       Validators.required,
  //     ]);
  //     this.claimProcessFilterForm?.controls?.dtDOSTo?.updateValueAndValidity();
  //   } else {
  //     this.claimProcessFilterForm?.controls?.dtDOSTo?.setValidators([
  //       Validators.nullValidator,
  //     ]);
  //     this.claimProcessFilterForm?.controls?.dtDOSTo?.updateValueAndValidity();
  //   }
  // }

  /* #region Zirmed Process */
  zirmedProcess() {
    this.isZermidProcessing = true;
    let gProvider: string = this.claimProcessFilterForm.value.drpProvider;
    try {
      if (this.processZirmedApiCall) {
        this.processZirmedApiCall.unsubscribe();
      }

      this.processZirmedApiCall = this.claimFormDetailsService
        .zermidClaimProcessingByGClaimAndGProvider(this.claimIdArray, gProvider)
        .subscribe(
          (response) => {
            this.toaster.success(
              'Claim Moved to Waystar Successfully',
              'Success'
            );
            this.loadProviderAlone();
            this.isZermidProcessing = false;
          },
          (error) => {
            console.error(error);
            setTimeout(() => {
              this.isZermidProcessing = false; // Enable the checkbox
            }, 1000);
            this.loadClaimProcessList();
          },
          () => {
            // This block will be executed on completion
            setTimeout(() => {
              this.isZermidProcessing = false; // Enable the checkbox
            }, 1000);
            this.loadClaimProcessList();
          }
        );
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        this.isZermidProcessing = false; // Enable the checkbox
      }, 1000);
      this.loadClaimProcessList();
    } finally {
    }
  }
  /* #endregion */
}
