import { ListService } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';

import { Observable, Subscription } from 'rxjs';
import { first, isEmpty, map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  DiagnosisCode10Service,
  DiagnosisCode9Service,
  PatientDropdowns,
} from '../dropdown-proxy/dropdown-management/dropdowns';
import {
  DiagnosisCode10DTO,
  DiagnosisCode9DTO,
  FacilityDTO,
  MarketingReferralTypeDTO,
  ReferringProviderDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientDoctorComponent } from '../patient-doctor/patient-doctor.component';
import { PatientIcd10Component } from '../patient-icd10/patient-icd10.component';
import { PatientIcd9Component } from '../patient-icd9/patient-icd9.component';
import { DoctorService } from '../patient-proxy/patient';
import { PatientClinicalService } from '../patient-proxy/patient-optimization';
import {
  CreateUpdatePatientClinicalDTO,
  PatientClinicalDTO,
} from '../patient-proxy/patient-optimization/dto';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PatientCreateEditTabComponent } from '../patient-create-edit-tab/patient-create-edit-tab.component';
import { PracticeManagementService } from '../patient-proxy/practice-management/practice-management.service';
import { DrpPracticeManagementDTO } from '../patient-proxy/practice-management/dto/models';

@Component({
  selector: 'app-patient-clinical-tab',
  templateUrl: './patient-clinical-tab.component.html',
  styleUrls: ['./patient-clinical-tab.component.scss'],
  providers: [ListService],
})
export class PatientClinicalTabComponent implements OnInit, OnDestroy {
  ClinicalForm: FormGroup;
  referringProviderType: string; //Referring Provider Type String
  marketingReferralType: string;
  RefTypeControl=new FormControl();
  RefinfoControl=new FormControl();
  str: string[]; //String List
  height: string = ''; //To Save the Height
  marketingReferralfacility: string; //Marketing Referral Facility string
  referringDoctor: string = ''; //Referring Doctor String
  referringProviderFacility: string; //Referring Provider Facility String
  primaryDoctor: string = ''; //Primary Doctor String
  practitioner: string = ''; //Practitioner String
  marketingReferralDoctor: string = ''; //Marketing Referral Doctor String
  //marketingRepDoctor: string = ''; //Marketing Rep Doctor string
  txtIcd9: string[] = []; //ICD9 String List
  txtIcd10: string[] = []; //ICD10 string List
  icd9Event: string = '';
  icd10Event: string = '';
  strLoginUserId: string = '';
  referrralProviderDoctorget:string='';
  markettingreferrralDoctorget:string='';
  saveButtonHide: boolean; //Flag to hide the save Button
  isShowSpinner: boolean = false; //Spinner variable
  tenantId: string;
  isNewPatient: any;
  editFormValues: boolean = false;
  physicianDropdown: Subscription;
  //drpRenderingTypeLoop: RenderingProviderDTO[]; //rendering provider class for Dropdown
  drpReferringtypeLoop: ReferringProviderDTO[];
  drpMarketingtypeLoop: MarketingReferralTypeDTO[]; //Marketing Type Loop Class
  drpDiagnosisICD9: CustomDiagnosisCode9DTO[] = [];
  drpDiagnosisICD10: CustomDiagnosisCode10DTO[] = [];
  drpReferringProviderFacility: FacilityDTO[]; //Reffering Provider Facility class for Facility
  drpReferralPractice:DrpPracticeManagementDTO[]=[];//Referral Practice
  drpReferralPracticeExtened:DrpPracticeManagementDTO[]=[];//Referral Practice
  drpMarketingFacility: FacilityDTO[]; //Reffering Provider Facility class for Facility
  drpDoctor: doctorInterface[] = [];
  @Output() patientIdEmitter: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(PatientCreateEditTabComponent)
  PatientCreateEditTabComponent: PatientCreateEditTabComponent;
  @Input() patientId: string = defaultGuid; //Get Patient Id
  clinicalId: string = defaultGuid; //Get Patient Id
  @Output() saveClinicalFormState: EventEmitter<any> = new EventEmitter<any>(); //Save Clinical Form Emitter
  subscription$: Subscription[] = [];
  isShownSaveButton: boolean = true;
  physiciansselectId:any;
  public filteredReferringProviderDoctorTypes:
    any; //Filtered Referring Provider Doctor Types
  public filteredPrimaryDoctorTypes: Observable<doctorInterface[]> | undefined; //Filtered Primary Doctor Types
  public filteredPractionerTypes: Observable<doctorInterface[]> | undefined; //Filtered Practitioners Types
  public filteredMarketingRepDoctorTypes:
    | Observable<doctorInterface[]>
    | undefined; //Filtered Marketing Rep Doctors
  public filteredIcd9Types: Observable<CustomDiagnosisCode9DTO[]> | undefined; //Filtered ICD9 Types
  public filteredIcd10Types: Observable<CustomDiagnosisCode10DTO[]> | undefined; //Filtered ICD10 Types
  public filteredMarketingReferralDoctorTypes:
    | Observable<doctorInterface[]>
    | undefined; //Filtered Marketing Referral Doctors
  iPhysicianLoading: boolean=false;
  txtReferringProviderDoctorFilter=new FormControl();
  primaryDocList: any;
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private numberValidators: NumberValidators,
    private patientDropdownService: PatientMasterDropdownService,
    private patientClincalService: PatientClinicalService,
    private diagnosis9: DiagnosisCode9Service,
    private diagnosis10: DiagnosisCode10Service,
    private doctorService: DoctorService,
    private Table: TableService,
    private toastr: ToastrService,
    public list: ListService,
    private cookieService: CookieService,
    private activateRoute: ActivatedRoute,
    public title: Title,
    public practiceManagementService: PracticeManagementService
  ) {}

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  //! Page Initialization funtions
  ngOnInit(): void {
    this.title.setTitle('Qsecure | Clinical');
    const activatedRoute = this.activateRoute.paramMap.subscribe((response) => {
      this.patientId = response.get('patientId');
    });
    this.tenantId = localStorage.getItem('tenantId');
    this.createClinicalForm();
    this.getDropdown();
    this.getPatientDetailsByID();
    this.icd9Dropdown();
    this.icd10Dropdown();
    // this.getRefPractice();
    this.isNewPatient = this.cookieService.get('patientStatus');

    if (this.isNewPatient == 'true') {
      this.ClinicalForm.enable();
      this.editFormValues = true;
    } else {
      this.ClinicalForm.disable();
      this.editFormValues = false;
      // this.patientIdEmitter.emit();
    }
    //

    if (this.ClinicalForm.invalid || this.ClinicalForm.dirty) {
      this.saveClinicalFormState.emit(this.ClinicalForm);
    }

     //Referral Type for Referring Provider
     this.RefTypeControl.valueChanges.subscribe((filtertxt) => {
      filtertxt = filtertxt.toLowerCase();
      this.drpReferralPracticeExtened = this.drpReferralPracticeExtened.filter((dx) =>
        dx.practiceName.toLowerCase().includes(filtertxt)
      );
    });

      //Extended Info for Referring Provider
      this.RefinfoControl.valueChanges.subscribe((filtertxt) => {
        filtertxt = filtertxt.toLowerCase();
        this.drpReferralPractice = this.drpReferralPractice.filter((dx) =>
          dx.practiceName.toLowerCase().includes(filtertxt)
        );
      });

      this.txtReferringProviderDoctorFilter.valueChanges.subscribe((filtertxt) => {
        filtertxt = filtertxt.toLowerCase();
        this.filteredReferringProviderDoctorTypes = this.drpDoctor.filter((dx) =>
          dx.name.toLowerCase().includes(filtertxt)
        );
      });

  }
  editForm() {
    this.editFormValues = true;
    this.ClinicalForm.enable();
  }
  //Numbers Only Validation Function
  numberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  removeICD9Code(value: string) {
    this.txtIcd9?.splice(this.txtIcd9?.indexOf(value), 1);

    this.ClinicalForm.patchValue({
      txtDiagnosisCodes9: this.txtIcd9,
    });
  }
  removeICD10Code(value: string) {
    this.txtIcd10?.splice(this.txtIcd10?.indexOf(value), 1);

    this.ClinicalForm.patchValue({
      txtdiagnosisCode10Id: this.txtIcd10,
    });
  }
  //If Clinical Exists get details by patient Id
  getPatientDetailsByID() {
    if (this.patientId !== defaultGuid) {
      const storeSubscription = this.patientClincalService
        .getClinicalDetailsByGPatientId(this.patientId)
        .subscribe(
          (response) => {
            if (this.isNewPatient == 'false') {
              this.ClinicalForm.disable();
            }
            const patientClinical: PatientClinicalDTO = response;
            this.referrralProviderDoctorget=patientClinical?.referringProviderDoctorId;
            // console.log(patientClinical?.referringProviderDoctorId+'/'+patientClinical?.referringProviderDoctor);

            this.handleReferraPracticeExtended(patientClinical?.referringProviderDoctorId)
            if(!this.isEmpty(patientClinical?.referringProviderDoctorId)){
              this.ClinicalForm.patchValue({
                //  txtReferringProviderDoctor: this.referrralProviderDoctorget,
                 txtReferringProviderDoctor: patientClinical?.referringProviderDoctorId+'/'+patientClinical?.referringProviderDoctor,
                 txtPrimaryDoctor:patientClinical?.referringProviderDoctorId+'/'+patientClinical?.referringProviderDoctor,
                 txtReferringProviderFacility: patientClinical?.referringProviderFacilityId
              });
              this.ClinicalForm.get('txtReferringProviderFacility').disable();

            }
          this.reloadDoctor('',false);
            //Marketing Referral
            this.markettingreferrralDoctorget=patientClinical?.marketingReferralDoctor;

            this.markettingreferrralDoctorget != null && this.markettingreferrralDoctorget !== "" && this.markettingreferrralDoctorget != undefined &&
            this.extendedReferraPracticedrp(this.markettingreferrralDoctorget) && this.ClinicalForm.patchValue({
              txtMarketingReferralDoctor: this.markettingreferrralDoctorget,
              txtMarketingReferralFacility: patientClinical?.marketingReferralFacility
            });


            this.clinicalId = response?.id ?? defaultGuid;
            if (
              patientClinical !== null &&
              patientClinical !== undefined &&
              patientClinical.height != 0
            ) {
              this.str = String(patientClinical.height).split('.', 2);
            }

            const setDateValue = (dateValue: any) => {
              return dateValue ? new Date(dateValue).toISOString().split('T')[0] : '';
            };

            if (patientClinical?.['dod']) {
              this.ClinicalForm.get('txtGeneralDod').setValue(setDateValue( patientClinical['dod']));
            }
            patientClinical !== null &&
              patientClinical !== undefined &&
              this.ClinicalForm.patchValue({
                txtReferringProviderType:
                  patientClinical?.referringProviderType == ''
                    ? null
                    : patientClinical?.referringProviderType,
                // txtReferringProviderDoctor:
                //   patientClinical?.referringProviderDoctor === ''
                //     ? null
                //     : patientClinical.referringProviderDoctor,
                  //  txtReferringProviderDoctor:
                  // patientClinical?.referringProviderDoctorId === ''
                  //   ? null
                  //   : patientClinical.referringProviderDoctorId,

                txtReferringProviderDoctor: patientClinical?.referringProviderDoctorId+'/'+patientClinical?.referringProviderDoctor,

                txtReferringProviderFacility:
                  patientClinical?.referringProviderFacilityId == ''
                    ? null
                    : patientClinical.referringProviderFacilityId,
                // txtPrimaryDoctor:
                //   patientClinical?.primaryDoctor === ''
                //     ? null
                //     : patientClinical.primaryDoctor,
                // txtPrimaryDoctor:
                // patientClinical?.primaryDoctorId === ''
                //   ? null
                //   : patientClinical.primaryDoctorId,
                txtPrimaryDoctor: patientClinical?.primaryDoctorId+'/'+patientClinical?.primaryDoctor,
                txtGeneralHeightFeet:
                  patientClinical?.height === 0 ? '' : this.str[0],
                txtGeneralHeightInches:
                  patientClinical?.height === 0 ? '' : this.str[1],
                txtGeneralWeight:
                  patientClinical?.weight === 0 ? '' : patientClinical?.weight,

                txtGeneralCondition: patientClinical?.infectiousConditon,
                txtAirborne: patientClinical?.airborne,
                txtContact: patientClinical?.contact,
                txtDroplet: patientClinical?.droplet,
                // txtGeneralDod:
                //   patientClinical['dod'] == null ? '' : patientClinical['dod'],
                txtGeneralFunctionalAbility: patientClinical?.functionalAbility,
                txtGeneralPractioner:
                  patientClinical?.generalPractitioner == ''
                    ? null
                    : patientClinical?.generalPractitioner,
                txtMarketingReferralType:
                  patientClinical?.marketingReferralType == ''
                    ? null
                    : patientClinical?.marketingReferralType,
                txtMarketingReferralFacility:
                  patientClinical?.marketingReferralFacility == ''
                    ? null
                    : patientClinical?.marketingReferralFacility,
                txtMarketingReferralContact:
                  patientClinical?.marketingReferralContact === ''
                    ? null
                    : patientClinical?.marketingReferralContact,
                txtMarketingReferralDoctor:
                  patientClinical?.marketingReferralDoctor == ''
                    ? null
                    : patientClinical?.marketingReferralDoctor,
                txtDiagnosisCodes9:
                  patientClinical?.diagnosisCode9?.length === 0
                    ? null
                    : patientClinical.diagnosisCode9,
                txtdiagnosisCode10Id:
                  patientClinical?.diagnosisCode10?.length === 0
                    ? null
                    : patientClinical.diagnosisCode10,
              });
            this.referringDoctor = patientClinical?.referringProviderDoctorId+'/'+patientClinical?.referringProviderDoctor??'';
            this.primaryDoctor = patientClinical?.primaryDoctorId+'/'+patientClinical?.primaryDoctor?? patientClinical?.referringProviderDoctorId+'/'+patientClinical?.referringProviderDoctor;
            this.txtIcd9 = patientClinical?.diagnosisCode9 ?? [];
            this.txtIcd10 = patientClinical?.diagnosisCode10 ?? [];
            this.marketingReferralType =
              patientClinical?.marketingReferralType ?? '';
            this.marketingReferralDoctor =
              patientClinical?.marketingReferralDoctor ?? '';
            this.marketingReferralfacility =
              patientClinical?.marketingReferralFacility ?? '';
            this.practitioner = patientClinical?.generalPractitioner ?? '';
          },
          (err) => {
            const data: HttpErrorResponse = err;
            // Swal.fire({
            //   icon: 'error',
            //   text: data?.error?.error?.message,
            // });
          }
        );
      this.subscription$.push(storeSubscription);
    }
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  //! Form Creation and Few Validations
  createClinicalForm() {
    this.ClinicalForm = this.formBuilder.group({
      txtReferringProviderType: new FormControl(null),

      txtReferringProviderDoctor: new FormControl(null, [Validators.required]),
      txtReferringProviderDoctorFilter: new FormControl(''),
      txtReferringProviderFacility: new FormControl(''),

      txtPrimaryDoctor: new FormControl(null),
      txtPrimaryDoctorSearchFilter: new FormControl(null),
      txtDiagnosisCodes9: new FormControl(null),
      txtIcd9Filter: new FormControl(null),
      txtdiagnosisCode10Id: new FormControl([], [Validators.required]),
      txtIcd10Filter: new FormControl(null),
      //Extented Info
      txtGeneralCondition: new FormControl(''),
      txtRefType: new FormControl(''),
      txtRefinfoType: new FormControl(''),
      txtAirborne: new FormControl(''),
      txtContact: new FormControl(''),
      txtDroplet: new FormControl(''),
      txtGeneralHeightInches: new FormControl('', [
        Validators.min(0),
        Validators.max(11),
        this.numberValidators.isNumberCheck,
      ]),
      txtGeneralHeightFeet: new FormControl('', [
        this.numberValidators.isNumberCheck,
      ]),
      txtGeneralWeight: new FormControl('', [
        this.numberValidators.isNumberCheck,
      ]),
      txtGeneralDod: new FormControl(null),
      txtGeneralFunctionalAbility: new FormControl(null),
      txtGeneralPractioner: new FormControl(null),
      txtPractitionerSearchFilter: new FormControl(''),

      txtMarketingReferralType: new FormControl(null),
      txtMarketingReferralFacility: new FormControl(''),

      txtMarketingReferralContact: new FormControl('', [
        this.numberValidators.isNumberCheck,
      ]),
      txtMarketingReferralDoctor: new FormControl(null),
      txtMarketingReferralDoctorFilter: new FormControl(null),
      txtReferringDoctor: new FormControl(null),
      txtMarketingRep: new FormControl(null),
      txtMarketingRepSearchFilter: new FormControl(''),
      txtRefferalDoctorFilter: new FormControl(''),
    });
  }

  //! Doctor Modal Open Functions
  openDoctorModal(doctorId?: string) {
    const dialogRef = this.dialog.open(PatientDoctorComponent, {
      disableClose: true,
      data: { doctorId: doctorId },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.doctorDropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  //Clear Referring Provider Doctor
  clearReferringDoctor() {
    this.referringDoctor = '';
    this.referringProviderFacility='';
    this.ClinicalForm.patchValue({
      txtReferringProviderDoctor: '',
    });
  }

  //CLear Referring Provider Type
  clearReferringProviderType() {
    this.referringProviderType = '';
    this.ClinicalForm.patchValue({ txtReferringType: '' });
  }

  // Clear Referring Provider Facility
  clearReferringProviderFacility() {
    this.referringProviderFacility = '';
    this.ClinicalForm.patchValue({
      txtReferringFacility: '',
    });
  }

  //Same as Referring Provider Doctor Dropdown
  sameAsReferringProviderDoctor() {
    // console.log(this.ClinicalForm.value.txtReferringProviderDoctor.split('/')[0]+"/" +this.ClinicalForm.value.txtReferringProviderDoctor.split('/')[1]+"/"+this.ClinicalForm.value.txtReferringProviderDoctor.split('/')[2])
    this.ClinicalForm.patchValue({
      // txtPrimaryDoctor: this.ClinicalForm.value.txtReferringProviderDoctor,
      txtPrimaryDoctor: this.ClinicalForm.value.txtReferringProviderDoctor.split('/')[0]+"/" +this.ClinicalForm.value.txtReferringProviderDoctor.split('/')[1]+"/"+this.ClinicalForm.value.txtReferringProviderDoctor.split('/')[2],
    });
  }

  //Clear Primary Doctor Function
  clearPrimaryDoctor() {
    this.primaryDoctor = '';
    this.ClinicalForm.patchValue({ txtPrimaryDoctor: '' });
  }

  //Clear Practitioner
  clearPractitionerDoctor() {
    this.practitioner = '';
    this.ClinicalForm.patchValue({
      txtGeneralPractioner: '',
    });
  }

  //Clear Marketing Referral Type
  clearmarketingReferralType() {
    this.marketingReferralType = '';
    this.ClinicalForm.patchValue({ ClinicalGeneral: { txtReferralType: '' } });
  }

  //CLear Marketting Referral Facility
  clearMarketingReferralFacility() {
    this.marketingReferralfacility = '';
    this.ClinicalForm.patchValue({
      txtReferralFacility: '',
    });
  }

  //Clear Marketing Referral Doctor
  clearMarketingReferralDoctor() {
    this.marketingReferralDoctor = '';
    this.ClinicalForm.patchValue({
      ClinicalGeneral: { txtReferralDoctor: '' },
    });
  }

  //CLear ICD10 Dropdown
  cleartxtIcd10() {
    this.txtIcd10 = [];
    this.ClinicalForm.patchValue({
      txtdiagnosisCode10Id: [],
    });
  }

  //Clear ICD9 Dropdown
  clearIcd9() {
    this.txtIcd9 = [];
    this.ClinicalForm.patchValue({
      txtDiagnosisCodes9: [],
    });
  }

  //Open ICD9 Modal to save ICD9
  openIcd9Modal() {
    const dialogRef = this.dialog.open(PatientIcd9Component, {
      disableClose: true,
      data: {},
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.getDropdown();
        this.icd9Dropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  //Open ICD10 Modal to save ICD10
  openIcd10Modal() {
    const dialogRef = this.dialog.open(PatientIcd10Component, {
      disableClose: true,
      data: {},
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.getDropdown();
        this.icd10Dropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  //! Master Dropdown Funtion
  getDropdown() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.RenderingProviders,
      PatientDropdowns.ReferringProviders,
      PatientDropdowns.MarketingReferralTypes,
      PatientDropdowns.Genders,
      PatientDropdowns.FuntionalAbilities,
      PatientDropdowns.MarketingRepTypes,
      PatientDropdowns.Practitioners,
      PatientDropdowns.Facilities,
      PatientDropdowns.MarketingReferralContacts,
    ];
    const patientDropdownSubscription = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (response) => {
          this.drpReferringProviderFacility = response.facilities;
          this.drpReferringtypeLoop = response.renderingProviders;
          this.drpMarketingtypeLoop = response.marketingReferralTypes;
          this.drpMarketingFacility = response.facilities;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientDropdownSubscription);
    this.doctorDropdown();
  }
  icd9Dropdown() {
    const itemList = (query: any) => this.diagnosis9.getList(query);
    const itemListSubscription = this.list
      .hookToQuery(itemList)
      .subscribe((response) => {
        this.drpDiagnosisICD9 =
          response?.items?.map((x) => {
            const d: CustomDiagnosisCode9DTO = {
              customDiagnosisCode: `${
                (x?.diagnosisCode ?? '') +
                ' - ' +
                (x?.diagnosisCodeTypeData ?? '')
              }`,
              ...x,
            };
            return d;
          }) ?? [];
        this.filteredIcd9Types = this.ClinicalForm?.get(
          'txtIcd9Filter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDiagnosisICD9?.filter((option) =>
              option?.customDiagnosisCode
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
      });
    this.subscription$.push(itemListSubscription);
  }
  icd10Dropdown() {
    this.drpDiagnosisICD10 = [];
    const itemList = (query: any) => this.diagnosis10.getList(query);
    const itemListSubscription = this.list.hookToQuery(itemList).subscribe(
      (response) => {
        this.drpDiagnosisICD10 =
          response?.items?.map((x) => {
            const d: CustomDiagnosisCode10DTO = {
              customDiagnosisCode: `${
                (x?.diagnosisCode ?? '') +
                ' - ' +
                (x?.diagnosisCodeTypeData ?? '')
              }`,
              ...x,
            };
            return d;
          }) ?? [];
        this.filteredIcd10Types = this.ClinicalForm?.get(
          'txtIcd10Filter'
        )?.valueChanges?.pipe(
          startWith(''),
          map((value) =>
            this.drpDiagnosisICD10.filter((option) =>
              option?.customDiagnosisCode
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(itemListSubscription);
  }
  //! Doctors Dropdown Funtion
  doctorDropdown(): void {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    this.drpDoctor = [];
    // const itemList = (query: any) => this.doctorService.getList(query);
    // const doctorDropdownSubscription = this.list.hookToQuery(itemList)
    //   .subscribe(
    //     (response) => {
    //       response?.items?.forEach((element) => {
    //         this.drpDoctor?.push({
    //           name:
    //             element?.npiNumber +
    //             ' / ' +
    //             element?.firstName +
    //             ' ' +
    //             element?.middleName +
    //             ' ' +
    //             element?.lastName,
    //           id: element?.id ?? '',
    //         });
    //       });

    const doctorDropdownSubscription = this.doctorService.getPhysicianDropdown(null,null)
      .subscribe(
        (response) => {
          response?.forEach((element) => {
            this.drpDoctor?.push({
              name:element?.doctorName,
              id: element?.id ?? '',
            });
          });

          this.filteredReferringProviderDoctorTypes=this.drpDoctor;
          this.primaryDocList=this.drpDoctor;
          // //Referring Provider Doctor Dropdown
          // this.filteredReferringProviderDoctorTypes = this.ClinicalForm?.get(
          //   'txtReferringProviderDoctorFilter'
          // )?.valueChanges.pipe(
          //   startWith(''),
          //   map((value) =>
          //     this.drpDoctor?.filter((option) =>
          //       option?.name
          //         ?.toLowerCase()
          //         ?.includes(value?.toLowerCase() ?? '')
          //     )
          //   )
          // );

          //Primary Doctor Dropdown
          this.filteredPrimaryDoctorTypes = this.ClinicalForm?.get(
            'txtPrimaryDoctorSearchFilter'
          )?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.primaryDocList?.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
          //Marketing Referral Doctor Dropdown
          this.filteredMarketingReferralDoctorTypes = this.ClinicalForm?.get(
            'txtMarketingReferralDoctorFilter'
          )?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDoctor?.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
          //Practitioner Doctor Dropdown
          this.filteredPractionerTypes = this.ClinicalForm?.get(
            'txtPractitionerSearchFilter'
          )?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDoctor?.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );

          //Marketing Rep Doctor Dropdown
          this.filteredMarketingRepDoctorTypes = this.ClinicalForm?.get(
            'txtMarketingRepSearchFilter'
          )?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpDoctor?.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        },
        () => {}
      );
    this.subscription$.push(doctorDropdownSubscription);
  }

  getFormattedDate(date: string): string {
    if (!date) return null;

    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
  //! Patient Clinical Save and Update Function
  saveClinical() {

    this.saveButtonHide = true;
    this.isShowSpinner = true;
    if (
      (this.ClinicalForm.value.txtGeneralHeightFeet == '' ||
        this.ClinicalForm.value.txtGeneralHeightFeet == null ||
        this.ClinicalForm.value.txtGeneralHeightFeet == undefined) &&
      (this.ClinicalForm.value.txtGeneralHeightInches == '' ||
        this.ClinicalForm.value.txtGeneralHeightInches == undefined ||
        this.ClinicalForm.value.txtGeneralHeightInches == null)
    ) {
      this.height = '0';
    } else {
      this.height =
        this.ClinicalForm.value.txtGeneralHeightFeet +
        '.' +
        this.ClinicalForm.value.txtGeneralHeightInches;

      parseFloat(this.height);
    }
    const dodSelectedDate = this.getFormattedDate(this.ClinicalForm.value.txtGeneralDod);
    //Clinical Details Initialization
    var clinicalDetails: CreateUpdatePatientClinicalDTO;

    clinicalDetails = {
      patientId: this.patientId,
      height: this.height == '0' ? 0 : parseFloat(this.height),
      weight: +this.ClinicalForm.value.txtGeneralWeight | 0,
      // dod: this.ClinicalForm.value.txtGeneralDod,
      dod: dodSelectedDate,
      functionalAbility: this.ClinicalForm.value.txtGeneralFunctionalAbility,
      generalPractitioner: this.ClinicalForm.value.txtGeneralPractioner,
      infectiousConditon: +this.ClinicalForm?.get('txtGeneralCondition')?.value,
      airborne: +this.ClinicalForm?.get('txtAirborne')?.value,
      contact: +this.ClinicalForm?.get('txtContact')?.value,
      droplet: +this.ClinicalForm?.get('txtDroplet')?.value,
      referringProviderType: this.ClinicalForm?.get('txtReferringProviderType')
        ?.value,

      referringProviderDoctor:this.ClinicalForm.value.txtReferringProviderDoctor.split('/')[1]+"/"+this.ClinicalForm.value.txtReferringProviderDoctor.split('/')[2],
      referringProviderDoctorId:this.ClinicalForm.value.txtReferringProviderDoctor.split('/')[0] || defaultGuid,

      // referringProviderDoctor: this.ClinicalForm?.get('txtReferringProviderDoctor')
      //   ?.value,
      // referringProviderDoctorId:this.ClinicalForm.value.txtReferringProviderDoctor,
       referringProviderFacilityId:
        this.ClinicalForm.value.txtReferringProviderFacility,
      // primaryDoctor: this.ClinicalForm?.get('txtPrimaryDoctor')
      // ?.value,
      // primaryDoctorId: this.ClinicalForm.value.txtPrimaryDoctor,
      // primaryDoctor: this.ClinicalForm?.get('txtPrimaryDoctor')?.value || null,
      // primaryDoctorId: this.ClinicalForm.value.txtPrimaryDoctor || null,
      primaryDoctor: this.ClinicalForm.value.txtPrimaryDoctor.split('/')[1]+"/"+this.ClinicalForm.value.txtPrimaryDoctor.split('/')[2],
      primaryDoctorId: this.ClinicalForm.value.txtPrimaryDoctor.split('/')[0]==='null'? defaultGuid:this.ClinicalForm.value.txtPrimaryDoctor.split('/')[0],
      marketingReferralType: this.ClinicalForm?.get('txtMarketingReferralType')
        ?.value,
      marketingReferralDoctor: this.ClinicalForm?.get('txtMarketingReferralDoctor')
        ?.value,
      marketingReferralDoctorId:
        this.ClinicalForm.value.txtMarketingReferralDoctor,
      marketingReferralFacility:
        this.ClinicalForm.value.txtMarketingReferralFacility,
      marketingReferralContact: this.ClinicalForm?.get(
        'txtMarketingReferralContact'
      )?.value,
      diagnosisCode9:
        this.ClinicalForm.value.txtDiagnosisCodes9 == ''
          ? []
          : this.ClinicalForm.value.txtDiagnosisCodes9,
      diagnosisCode10: this.ClinicalForm.value.txtdiagnosisCode10Id,
      tenantId: this.tenantId ?? defaultGuid,
    };
    this.clinicalId = this.clinicalId ?? defaultGuid;
    this.patientId = this.patientId ?? defaultGuid;
    //Clinical Save
    if (this.clinicalId === defaultGuid) {
      this.patientClincalService.create(clinicalDetails).subscribe(
        (response) => {
          let patientSaved: PatientClinicalDTO = response;
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Saved Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Saved Successfully', 'Success');
          this.patientIdEmitter.emit(this.patientId);

          this.saveButtonHide = false;
          this.isShowSpinner = false;
        },
        (err) => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        },
        () => {}
      );
    }
    //Clinical Update
    else if (this.clinicalId !== defaultGuid) {
      this.patientClincalService
        .update(this.clinicalId, clinicalDetails)
        .subscribe(
          (response) => {
            this.patientIdEmitter.emit(this.patientId);

            // Swal.fire({
            //   title: 'Success',
            //   html: 'Updated Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Updated Successfully', 'Success');
            this.saveButtonHide = false;
            this.isShowSpinner = false;
          },
          (err) => {
            this.saveButtonHide = false;
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          },
          () => {}
        );
    }
  }
  reloadDoctor(value, searchReferral) {
    setTimeout(() => {
      this.iPhysicianLoading = true;
    }, 100);

    this.ClinicalForm.patchValue({
      //  txtReferringProviderDoctor: this.referrralProviderDoctorget,
       txtReferringProviderDoctor: '',
    });
    this.referringDoctor='';
    const demo = this.ClinicalForm.value;
    if (this.physicianDropdown) {
      this.physicianDropdown.unsubscribe();
      this.iPhysicianLoading = false;
    }
    this.drpDoctor=[];
    this.physicianDropdown = this.doctorService
      .getPhysicianDropdown(
        demo.txtReferringProviderFacility === null ? '' : demo.txtReferringProviderFacility,
        value
      )
      .subscribe(
        (response) => {
          setTimeout(() => {
            this.iPhysicianLoading = false;
          }, 100);
          response?.forEach((element) => {
          this.drpDoctor?.push({
            name:element?.doctorName,
            id: element?.id ?? '',
          });
        });
          this.filteredReferringProviderDoctorTypes=this.drpDoctor;

        },
        (err) => {
          setTimeout(() => {
            this.iPhysicianLoading = false;
          }, 100);
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
        }
      );
  }

//Referral Provider
  // handleReferraPracticeChange(event: any) {
  //   // console.log(event.value.split('/')[0]);
  //   this.physiciansselectId = event.value.split('/')[0];
  //    this.handleReferraPracticeExtended(this.physiciansselectId);
  // }

  // handleReferraPracticeExtended(physiciansId: string): any {
  //   this.ClinicalForm.patchValue({
  //     // txtReferringProviderDoctor: null,
  //     txtReferringProviderFacility: null
  //   });

  //   const referrallist = this.practiceManagementService.drpPracticeByDoctorIdByPhysiciansId(physiciansId).subscribe(
  //     (response) => {
  //       this.drpReferralPracticeExtened = response;

  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //       this.subscription$.push(referrallist);
  //     });
  // }

  handleReferraPracticeExtended(physiciansId: string): any {
    const referralDropdowns = this.doctorService
      .getPracticeDropdown('', '')
      .subscribe(
        (response) => {
          this.drpReferralPracticeExtened = response;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
          this.subscription$.push(referralDropdowns);
        }
      );
  }

//Extended Info
extendedReferraPractice(event: any): void {

  this.physiciansselectId = event.value;

   this.extendedReferraPracticedrp(this.physiciansselectId);
}

extendedReferraPracticedrp(physiciansId: string): any {
  this.ClinicalForm.patchValue({
    txtMarketingReferralFacility: null
  });

  const referrallist = this.practiceManagementService.drpPracticeByDoctorIdByPhysiciansId(physiciansId).subscribe(
    (response) => {
      this.drpReferralPractice = response;

    },
    (err) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.subscription$.push(referrallist);
    });
}
    //GET Refereal Practice Drop Down
    // getRefPractice() {
    //   const userList = this.practiceManagementService.drpPractice().subscribe(
    //     (response) => {
    //       this.drpReferralPractice = response;
    //     },
    //     (err) => {
    //       const data: HttpErrorResponse = err;
    //       Swal.fire({
    //         icon: 'info',
    //         text: data?.error?.error?.message,
    //       });
    //       this.subscription$.push(userList);
    //     });
    // }
}



export interface doctorInterface {
  id: string;
  name: string;
}
export interface CustomDiagnosisCode9DTO extends DiagnosisCode9DTO {
  customDiagnosisCode: string;
}

export interface CustomDiagnosisCode10DTO extends DiagnosisCode10DTO {
  customDiagnosisCode: string;
}
