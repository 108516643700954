<div>
  <form [formGroup]="personalTabForm" class="mt-4">
    <mat-card class="mr-3">
      <mat-card-title class="customThemeClass"
        ><b>General Info</b></mat-card-title
      >
      <mat-divider class="bg mb-5"> </mat-divider>
      <mat-card-content>
        <div class="row mt-3">
          <div class="col-lg-12">
            <mat-card-content>
              <div class="row">
                <mat-form-field class="col-2">
                  <mat-label>
                    Chart Id <span class="asterisk">*</span></mat-label
                  >
                  <input
                    matInput
                    maxlength="40"
                    formControlName="txtChartId"
                    type="text"
                    autocomplete="new-txtChartId" readonly
                  />

                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                      personalTabForm?.get('txtChartId')?.touched &&
                      personalTabForm?.get('txtChartId')?.errors?.required
                    "
                  >
                    Chart Id is a required field!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2">
                  <mat-label>
                    Branch <span class="asterisk">*</span>
                  </mat-label>
                  <mat-select
                    maxlength="40"
                    [(value)]="location"
                    formControlName="drpLocation"
                    autocomplete="off"
                    [errorStateMatcher]="matcher"
                  >
                    <mat-option
                      *ngFor="let location of drpLocationTypes"
                      [value]="location.id"
                    >
                      {{ location.organizationUnitName }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    class="txtBillingCity"
                    *ngIf="
                      personalTabForm?.get('drpLocation')?.touched &&
                      personalTabForm?.get('drpLocation')?.errors?.required
                    "
                  >
                    Branch is a required field!
                  </mat-error>
                  <button
                    mat-button
                    (click)="location = ''; $event.stopPropagation()"
                    *ngIf="location"
                    matSuffix
                    (click)="clearLocation()"
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                  <!-- Title-->
                  <mat-form-field class="col-2">
                    <mat-label>Title </mat-label>
                    <mat-select formControlName="drpTitle" autocomplete="new-drpTitle">
                      <mat-option *ngFor="let title of drpTitleLoop" [value]="title.titleName">
                        {{ title.titleName }}
                      </mat-option>
                    </mat-select>
                    <!-- <mat-error class="spanAlignment" *ngIf="
                    personalTabForm?.get('drpTitle')?.touched &&
                    personalTabForm?.get('drpTitle')?.errors?.required
                    ">
                      Title is a required field!
                    </mat-error> -->
                  </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label>
                    First Name <span class="asterisk">*</span></mat-label
                  >
                  <input
                    matInput
                    maxlength="40"
                    formControlName="txtFirstName"
                    type="text"
                    autocomplete="new-txtFirstName"
                    pattern="^[^0-9]*$"
                  />

                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                      personalTabForm?.get('txtFirstName')?.touched &&
                      personalTabForm?.get('txtFirstName')?.errors?.required
                    "
                  >
                    First Name is a required field!
                  </mat-error>

                  <mat-error class="spanAlignment" *ngIf="
                          personalTabForm?.get('txtFirstName')?.touched &&
                          personalTabForm?.get('txtFirstName')?.errors?.pattern
                          ">
                          Numbers not allowed!
                          </mat-error>
                </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label>Middle Name</mat-label>
                  <input
                    matInput
                    maxlength="40"
                    formControlName="txtMiddleName"
                    type="text"
                    autocomplete="new-txtMiddleName"
                    pattern="^[^0-9]*$"
                  />
                  <mat-error class="spanAlignment" *ngIf="
                  personalTabForm?.get('txtMiddleName')?.touched &&
                  personalTabForm?.get('txtMiddleName')?.errors?.pattern
                  ">
                    Numbers not allowed!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-2">
                  <mat-label>
                    Last Name <span class="asterisk">*</span></mat-label
                  >
                  <input
                    matInput
                    maxlength="40"
                    formControlName="txtLastName"
                    type="text"
                    autocomplete="new-txtLastName"
                    pattern="^[^0-9]*$"
                  />
                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                      personalTabForm?.get('txtLastName')?.touched &&
                      personalTabForm?.get('txtLastName')?.errors?.required
                    "
                  >
                    Last Name is a required field!
                  </mat-error>
                  <mat-error class="spanAlignment" *ngIf="
                  personalTabForm?.get('txtLastName')?.touched &&
                  personalTabForm?.get('txtLastName')?.errors?.pattern
                  ">
                    Numbers not allowed!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-2">
                  <mat-label>
                    Birth Date <span class="asterisk">*</span></mat-label
                  >
                  <input
                    [max]="BirthdateMaxDate"
                    matInput
                    maxlength="10"
                    formControlName="txtBirthdate"
                    type="text"
                    placeholder="(MM/DD/YYYY)"
                    autocomplete="new-txtBirthdate"
                    matInput
                    [matDatepicker]="txtBirthdate"
                    [min]="BirthdateMinDate"
                    onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                  />
                  <mat-datepicker-toggle matSuffix [for]="txtBirthdate">
                  </mat-datepicker-toggle>
                  <mat-datepicker #txtBirthdate> </mat-datepicker>
                  <mat-error
                    *ngIf="
                      personalTabForm?.get('txtBirthdate')?.touched &&
                      personalTabForm?.get('txtBirthdate')?.errors &&
                      !(
                        BirthdateMinDate <=
                        personalTabForm?.get('txtBirthdate')?.value
                      )
                    "
                  >
                    Enter Valid date
                  </mat-error>
                  <mat-error
                    *ngIf="
                      personalTabForm?.get('txtBirthdate')?.touched &&
                      personalTabForm?.get('txtBirthdate')?.errors?.required
                    "
                  >
                    Birth Date is a required field!
                  </mat-error>
                  <mat-error
                    *ngIf="
                      BirthdateMinDate <=
                        personalTabForm?.get('txtBirthdate')?.value &&
                      !(
                        personalTabForm?.get('txtBirthdate')?.touched &&
                        personalTabForm?.get('txtBirthdate')?.errors?.required
                      )
                    "
                  >
                    Enter Past Dates Only!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2">
                  <mat-label>Gender<span class="asterisk">*</span></mat-label>
                  <mat-select
                    formControlName="drpGender"
                    autocomplete="off"
                    [(value)]="gender"
                    maxlength="40"
                  >
                    <mat-option
                      *ngFor="let test of drpGenderLoop"
                      [value]="test.genderShortCode"
                    >
                      {{ test.genderName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    (click)="gender = ''; $event.stopPropagation()"
                    *ngIf="gender"
                    matSuffix
                    (click)="clearGender()"
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                      personalTabForm?.get('drpGender')?.touched &&
                      personalTabForm?.get('drpGender')?.errors?.required
                    "
                  >
                    Gender is a required field!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label
                    >Provider <span class="asterisk">*</span></mat-label
                  >
                  <mat-select
                    maxlength="40"
                    [(value)]="provider"
                    autocomplete="off"
                    formControlName="txtProvider"
                  >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      [formControl]="txtProviderFilter"
                    ></ngx-mat-select-search>
                  </mat-option>
                    <mat-option
                      *ngFor="let providers of drpProviders"
                      [value]="providers.shortCodeId"
                    >
                      {{ providers.name }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    matSuffix
                    mat-icon-button
                    (click)="clearProvider()"
                    (click)="provider = undefined; $event.stopPropagation()"
                    *ngIf="provider"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                      personalTabForm?.get('txtProvider')?.touched &&
                      personalTabForm?.get('txtProvider')?.errors?.required
                    "
                  >
                    Provider is a required field!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label>
                    Account Group <span class="asterisk">*</span>
                  </mat-label>
                  <mat-select
                    maxlength="40"
                    [(value)]="accountGroup"
                    formControlName="drpAccountGroup"
                  >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      [formControl]="txtAccountGroupFilter"
                    ></ngx-mat-select-search>
                  </mat-option>
                    <mat-option
                      *ngFor="let account of drpAccountGroups"
                      [value]="account.accountGroupShortCodeId"
                    >
                      {{ account.accountGroup }}
                    </mat-option>
                  </mat-select>

                  <button
                    mat-button
                    matSuffix
                    (click)="clearAccountGroup()"
                    mat-icon-button
                    (click)="accountGroup = undefined; $event.stopPropagation()"
                    *ngIf="accountGroup"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                      personalTabForm?.get('drpAccountGroup')?.touched &&
                      personalTabForm?.get('drpAccountGroup')?.errors?.required
                    "
                  >
                    Account Group is a required field!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-4">
                  <mat-label> Email Address</mat-label>
                  <input
                    formControlName="txtBillingEmailAddress"
                    matInput
                    autocomplete="new-txtBillingEmailAddress"
                    pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                    [textMask]="{ mask: emailMask }"
                    maxlength="40"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      personalTabForm?.get('txtBillingEmailAddress')?.touched &&
                      personalTabForm?.get('txtBillingEmailAddress')?.errors
                        ?.pattern
                    "
                  >
                    This is not a valid Email!!!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-2">
                  <mat-label> Home Phone No</mat-label>
                  <input
                    formControlName="txtHomePhoneNo"
                    matInput
                    autocomplete="new-txtHomePhoneNo"
                    maxlength="40"
                    prefix="+{{ phoneCode }}-"
                    mask="(000)-000-0000"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      personalTabForm?.get('txtHomePhoneNo')?.touched &&
                      personalTabForm?.get('txtHomePhoneNo')?.errors
                    "
                  >
                    Enter Minimum 10 Digits!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-2">
                  <mat-label>Work Phone No</mat-label>
                  <input
                    formControlName="txtWorkPhoneNo"
                    autocomplete="new-txtWorkPhoneNo"
                    matInput
                    maxlength="40"
                    prefix="+{{ phoneCode }}-"
                    mask="(000)-000-0000"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      personalTabForm?.get('txtWorkPhoneNo')?.touched &&
                      personalTabForm?.get('txtWorkPhoneNo')?.errors
                    "
                  >
                    Enter Minimum 10 Digits!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-2">
                  <mat-label>Cell No</mat-label>
                  <input
                    formControlName="txtCellNo"
                    autocomplete="new-txtCellNo"
                    matInput
                    maxlength="40"
                    prefix="+{{ phoneCode }}-"
                    mask="(000)-000-0000"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      personalTabForm?.get('txtCellNo')?.touched &&
                      personalTabForm?.get('txtCellNo')?.errors
                    "
                  >
                    Enter Minimum 10 Digits!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-2">
                  <mat-label>Emergency Contact No</mat-label>
                  <input
                    formControlName="txtEmergencyNo"
                    autocomplete="new-txtEmergencyNo"
                    matInput
                    maxlength="40"
                    prefix="+{{ phoneCode }}-"
                    mask="(000)-000-0000"
                    type="text"
                  />
                  <mat-error
                    *ngIf="
                      personalTabForm?.get('txtEmergencyNo')?.touched &&
                      personalTabForm?.get('txtEmergencyNo')?.errors
                    "
                  >
                    Enter Minimum 10 Digits!
                  </mat-error>
                </mat-form-field>

                <!-- <mat-form-field class="col-3">
                  <mat-label>
                    Color Code Status <span class="asterisk">*</span>
                  </mat-label>
                  <mat-select
                    maxlength="40"
                    [(value)]="colorCodingName"
                    formControlName="drpColorCodes"
                    #singleSelect
                    [ngStyle]="{
                      'background-color': colorCodingName,
                      padding: '10px 10px 10px 10px',
                      'border-radius': '0px'
                    }"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtColorCodeFilter"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let colors of filteredColorCodes | async"
                      [value]="colors?.colorCode"
                      [ngStyle]="{ 'background-color': colors?.colorCode }"
                    >
                      {{ colors?.colorCodingName }}
                    </mat-option>
                  </mat-select>

                  <button
                    mat-button
                    matSuffix
                    (click)="clearColorCode()"
                    mat-icon-button
                    [ngStyle]="{
                      'background-color': colorCodingName,
                      padding: '10px 20px 26px 10px',
                      'border-radius': '0px'
                    }"
                    (click)="
                      colorCodingName = undefined; $event.stopPropagation()
                    "
                    *ngIf="colorCodingName"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error
                    class="spanAlignment"
                    *ngIf="
                      personalTabForm?.get('drpColorCodes')?.touched &&
                      personalTabForm?.get('drpColorCodes')?.errors?.required
                    "
                  >
                    Color Code is a required field!
                  </mat-error>
                </mat-form-field> -->
              </div>
            </mat-card-content>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="mr-3 mt-3">
      <!-- <mat-card-title class="customThemeClass"
        ><b> Billing Address</b></mat-card-title
      > -->

      <mat-card-title class="customThemeClass">
        <div class="row">
          <div class="col-md-6">
            <h4 class="customThemeClass"><b>Billing Address</b></h4>
          </div>
          <div class="col-md-6 text-sm-right">
            <div class="text-sm-right col-lg-12 p-0">
              <button mat-button class="buttonColor mr-1 font-size-13 btn-sm"
              (click)="viewAddressList()" [disabled]="isEditButton || patientId===null ||patientId===undefined||patientId===''||
              patientId===defaultGuid ">
                <i class="fa fa-address-book mr-1"></i> View All Address </button>
            </div>
          </div>
        </div>
      </mat-card-title>

      <mat-divider class="bg mb-5"> </mat-divider>
      <div class="row mt-4">
        <div class="col-lg-12">
          <mat-card-content>
            <div class="row">
              <mat-form-field class="col-3">
                <mat-label> Address</mat-label>
                <textarea
                  formControlName="txtBillingAddress"
                  rows="3"
                  matInput
                  maxlength="200"
                ></textarea>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Country</mat-label>
                <mat-select
                  formControlName="drpBillingCountry"
                  (selectionChange)="onChangeBillState($event.value)"
                  [(value)]="BillingCountry"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtBillingCountryFilter"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let country of filteredBillingCountries | async"
                    [value]="country.countryShortName"
                  >
                    {{ country.countryName }}
                  </mat-option>
                </mat-select>
                <button
                  mat-button
                  (click)="BillingCountry = undefined; $event.stopPropagation()"
                  *ngIf="BillingCountry"
                  (click)="clearBillingCountry()"
                  matSuffix
                  mat-icon-button
                  [disabled]="personalTabForm.disabled"
                >
                  <mat-icon >close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label>State </mat-label>
                <mat-select
                  formControlName="txtBillingState"
                  (selectionChange)="onChangeBillCity($event.value, '')"
                  [(value)]="BillingState"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtBillingStateFilter"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let state of filteredBillingStates | async"
                    [value]="state.stateShortName"
                  >
                    {{ state.stateName }}
                  </mat-option>
                </mat-select>
                <button
                  mat-button
                  (click)="BillingState = undefined; $event.stopPropagation()"
                  *ngIf="BillingState"
                  (click)="clearBillingState()"
                  matSuffix
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label> County</mat-label>
                <input
                  formControlName="drpBillingCounty"
                  matInput
                  maxlength="40"
                  type="text"
                  autocomplete="new-drpBillingCounty"
                />
                <mat-error
                  *ngIf="
                    personalTabForm?.get('drpBillingCounty')?.touched &&
                    personalTabForm?.get('drpBillingCounty')?.errors?.sAlphabets
                  "
                >
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label>City</mat-label>
                <mat-select
                  formControlName="txtBillingCity"
                  [(value)]="BillingCity"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtBillingCityFilter"
                      hideClearSearchButton="true"
                      [noEntriesFoundLabel]="billingCityDrpDownMessage()"
                    (focusout)="addNewBillingCity()"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let city of filteredBillingCities | async"
                    [value]="city.cityName"
                  >
                    {{ city.cityName }}
                  </mat-option>
                </mat-select>
                <button
                  mat-button
                  (click)="BillingCity = ''; $event.stopPropagation()"
                  *ngIf="BillingCity"
                  matSuffix
                  (click)="clearBillingCity()"
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label> Zip Code </mat-label>
                <input
                  matInput
                  maxlength="10"
                  formControlName="txtBillingPostalCode"
                  pattern="[0-9-]*"
                  type="text"
                  autocomplete="new-txtBillingPostalCode"
                  class="zip-code-input"
                />
                <mat-error *ngIf=" personalTabForm?.get('txtBillingPostalCode')?.touched &&
                personalTabForm?.get('txtBillingPostalCode')?.errors?.pattern">
                    Enter only Numbers!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label> Landline</mat-label>
                <input
                  formControlName="txtBillingPhone"
                  matInput
                  maxlength="40"
                  prefix="{{ phoneCode }}-"
                  mask="(000)-000-0000"
                  type="text"
                  autocomplete="new-txtBillingPhone"
                />
                <mat-error
                  *ngIf="
                    personalTabForm?.get('txtBillingPhone')?.touched &&
                    personalTabForm?.get('txtBillingPhone')?.errors
                  "
                >
                  Enter Minimum 10 Digits!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label> Fax</mat-label>
                <input
                  formControlName="txtBillingFax"
                  matInput
                  maxlength="40"
                  prefix="{{ phoneCode }}-"
                  mask="(000)-000-0000"
                  type="text"
                  autocomplete="new-txtBillingFax"
                />
                <mat-error
                  *ngIf="
                    personalTabForm?.get('txtBillingFax')?.touched &&
                    personalTabForm?.get('txtBillingFax')?.errors
                  "
                >
                  Enter Minimum 10 Digits!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label> Zone</mat-label>
                <mat-select
                  maxlength="40"
                  [(value)]="zone"
                  formControlName="drpBillingZone"
                >
                  <mat-option
                    *ngFor="let zone of drpZones"
                    [value]="zone.zoneTypeShortCodeId"
                  >
                    {{ zone.zoneType }}
                  </mat-option>
                </mat-select>
                <button
                  mat-button
                  (click)="zone = ''; $event.stopPropagation()"
                  *ngIf="zone"
                  matSuffix
                  (click)="clearZone()"
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label><mat-icon> call</mat-icon> Mobile</mat-label>
                <input
                  formControlName="txtBillingMobile"
                  prefix="{{ phoneCode }}-"
                  mask="(000)-000-0000"
                  matInput
                  maxlength="40"
                  type="text"
                  autocomplete="new-txtBillingMobile"
                />
                <mat-error
                  *ngIf="
                    personalTabForm?.get('txtBillingMobile')?.touched &&
                    personalTabForm?.get('txtBillingMobile')?.errors
                  "
                >
                  Enter Minimum 10 Digits!
                </mat-error>
              </mat-form-field>
            </div>
          </mat-card-content>
        </div>
      </div>

      <mat-accordion #SecondAccordion="matAccordion">
        <mat-expansion-panel [(expanded)]="panelOpenState">
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <ng-container>
                <b>Contact Info</b>
              </ng-container>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content>
            <div class="row">
              <div class="col-lg-12 text-lg-right">
                <button
                  mat-button
                  class="buttonColor"
                  (click)="copyBillingAddress()" [disabled]="isEditButton"
                >
                  <i class="fa fa-clone mr-1"></i>
                  Same as Billing Address
                </button>
              </div>
              <mat-form-field class="col-3">
                <mat-label> Relationship </mat-label>
                <mat-select
                  formControlName="drpEmergencyRelationshipType"
                  autocomplete="off"
                  (selectionChange)="copyPatientAddressDetails()"
                  maxlength="40"
                >
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    [formControl]="dtxtEmergencyRelationshipTypeFilter"
                  ></ngx-mat-select-search>
                </mat-option>
                  <mat-option
                    *ngFor="let emergency of relationShipWithoutSelves"
                    [value]="emergency.relationShipCode"
                  >
                    {{ emergency.relationType }}
                  </mat-option>
                </mat-select>
                <button [disabled]="personalTabForm.disabled" mat-button matSuffix mat-icon-button>
                  <mat-icon>close</mat-icon>
                </button>
                <mat-error
                  *ngIf="
                    personalTabForm?.get('drpEmergencyRelationshipType')
                      ?.touched &&
                    personalTabForm?.get('drpEmergencyRelationshipType')?.errors
                      ?.required
                  "
                >
                  Relationship is a required field!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label> First Name </mat-label>
                <input
                  matInput
                  formControlName="txtEmergencyFirstName"
                  autocomplete="new-txtEmergencyFirstName"
                  type="text"
                  maxlength="40"
                  pattern="^[^0-9]*$"
                />
                <mat-error class="spanAlignment" *ngIf="
                personalTabForm?.get('txtEmergencyFirstName')?.touched &&
                personalTabForm?.get('txtEmergencyFirstName')?.errors?.pattern
                ">
                   Numbers not allowed!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Middle Name</mat-label>
                <input
                  formControlName="txtEmergencyMiddleName"
                  autocomplete="new-txtEmergencyMiddleName"
                  maxlength="40"
                  matInput
                  type="text"
                  pattern="^[^0-9]*$"
                />
                <mat-error class="spanAlignment" *ngIf="
                personalTabForm?.get('txtEmergencyMiddleName')?.touched &&
                personalTabForm?.get('txtEmergencyMiddleName')?.errors?.pattern
                ">
                   Numbers not allowed!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label> Last Name</mat-label>
                <input
                  formControlName="txtEmergencyLastName"
                  autocomplete="new-txtEmergencyLastName"
                  maxlength="40"
                  matInput
                  type="text"
                  pattern="^[^0-9]*$"
                />
                <mat-error class="spanAlignment" *ngIf="
                personalTabForm?.get('txtEmergencyLastName')?.touched &&
                personalTabForm?.get('txtEmergencyLastName')?.errors?.pattern
                ">
                  Numbers not allowed!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Address</mat-label>
                <textarea
                  formControlName="txtEmergencyAddress"
                  autocomplete="new-txtEmergencyAddress"
                  maxlength="200"
                  rows="3"
                  matInput
                ></textarea>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Country</mat-label>
                <mat-select
                  formControlName="txtEmergencyCountry"
                  autocomplete="new-txtEmergencyCountry"
                  (selectionChange)="onChangeEmerState($event.value)"
                  [(value)]="EmergencyCountry"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtEmergencyCountryFilter"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <ng-container
                    *ngIf="(filteredEmergencyCountries | async)?.length"
                  >
                    <mat-option
                      *ngFor="let country of filteredEmergencyCountries | async"
                      [value]="country.countryShortName"
                    >
                      {{ country.countryName }}
                    </mat-option>
                  </ng-container>
                </mat-select>
                <button
                  mat-button
                  (click)="
                    EmergencyCountry = undefined; $event.stopPropagation()
                  "
                  *ngIf="EmergencyCountry"
                  matSuffix
                  (click)="clearEmergencyCountry()"
                  mat-icon-button [disabled]="personalTabForm.disabled"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>State</mat-label>
                <mat-select
                  formControlName="txtEmergencyState"
                  autocomplete="new-txtEmergencyState"
                  (selectionChange)="onChangeEmerCity($event.value, '')"
                  #singleSelect
                  [(value)]="EmergencyState"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtEmergencyStateFilter"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <ng-container
                    *ngIf="(filteredEmergencyStates | async)?.length"
                  >
                    <mat-option
                      *ngFor="let state of filteredEmergencyStates | async"
                      [value]="state.stateShortName"
                    >
                      {{ state.stateName }}
                    </mat-option>
                  </ng-container>
                </mat-select>
                <button
                  mat-button
                  (click)="EmergencyState = undefined; $event.stopPropagation()"
                  *ngIf="EmergencyState"
                  matSuffix
                  (click)="clearEmergencyState()"
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button>
                <mat-error> Enter only Alphabets! </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>County</mat-label>
                <input
                  formControlName="txtEmergencyCounty"
                  autocomplete="new-txtEmergencyCounty"
                  matInput
                  maxlength="40"
                  type="text"
                />
                <mat-error
                  *ngIf="
                    personalTabForm?.get('txtEmergencyCounty')?.touched &&
                    personalTabForm?.get('txtEmergencyCounty')?.errors
                      ?.sAlphabets
                  "
                >
                  Enter only Alphabets!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>City</mat-label>
                <mat-select
                  formControlName="txtEmergencyCity"
                  autocomplete="new-txtEmergencyCity"
                  [(value)]="EmergencyCity"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtEmergencyCityFilter"
                      hideClearSearchButton="true"
                      [noEntriesFoundLabel]="ContactInfocityDrpDownMessage()"
                    (focusout)="ContactInfoaddNewCity()"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <ng-container
                    *ngIf="(filteredEmergencyCities | async)?.length"
                  >
                    <mat-option
                      *ngFor="let city of filteredEmergencyCities | async"
                      [value]="city.cityName"
                    >
                      {{ city.cityName }}
                    </mat-option>
                  </ng-container>
                </mat-select>
                <button
                  mat-button
                  (click)="EmergencyCity = undefined; $event.stopPropagation()"
                  *ngIf="EmergencyCity"
                  matSuffix
                  (click)="clearEmergencyCity()"
                  mat-icon-button
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label> Zip Code </mat-label>
                <input
                  formControlName="txtEmergencyPostalCode"
                  autocomplete="new-txtEmergencyPostalCode"
                  maxlength="10"
                  pattern="[0-9-]*"
                  matInput
                  type="text"
                  class="zip-code-input"
                />
                <mat-error *ngIf=" personalTabForm?.get('txtEmergencyPostalCode')?.touched &&
                personalTabForm?.get('txtEmergencyPostalCode')?.errors?.pattern">
                    Enter only Numbers!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Fax</mat-label>
                <input
                  formControlName="txtEmergencyFax"
                  autocomplete="new-txtEmergencyFax"
                  matInput
                  maxlength="40"
                  prefix="+{{ phoneEmerCode }}-"
                  mask="(000)-000-0000"
                  type="text"
                />
                <mat-error
                  *ngIf="
                    personalTabForm?.get('txtEmergencyFax')?.touched &&
                    personalTabForm?.get('txtEmergencyFax')?.errors
                  "
                >
                  Enter Minimum 10 Digits!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label> Email Address </mat-label>
                <input
                  formControlName="txtEmergencyEmail"
                  matInput
                  autocomplete="new-txtEmergencyEmail"
                  pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                  [textMask]="{ mask: emailMask }"
                  maxlength="40"
                  type="text"
                />
                <mat-error
                  *ngIf="
                    personalTabForm?.get('txtEmergencyEmail')?.touched &&
                    personalTabForm?.get('txtEmergencyEmail')?.errors?.pattern
                  "
                >
                  This is not a valid Email!!!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label>Landline</mat-label>
                <input
                  formControlName="txtEmergencyPhone"
                  autocomplete="new-txtEmergencyPhone"
                  matInput
                  maxlength="40"
                  prefix="+{{ phoneEmerCode }}-"
                  mask="(000)-000-0000"
                  type="text"
                />
                <mat-error
                  *ngIf="
                    personalTabForm?.get('txtEmergencyPhone')?.touched &&
                    personalTabForm?.get('txtEmergencyPhone')?.errors
                  "
                >
                  Enter Minimum 10 Digits!
                </mat-error>
                <mat-error
                  *ngIf="
                    personalTabForm?.get('txtEmergencyPhone')?.touched &&
                    personalTabForm?.get('txtEmergencyPhone')?.errors?.required
                  "
                >
                  Payor Level is a required field!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Mobile</mat-label>
                <input
                  formControlName="txtEmergencyMobile"
                  autocomplete="new-txtEmergencyMobile"
                  matInput
                  prefix="+{{ phoneEmerCode }}-"
                  mask="(000)-000-0000"
                  type="text"
                />
                <mat-error
                  *ngIf="
                    personalTabForm?.get('txtEmergencyMobile')?.touched &&
                    personalTabForm?.get('txtEmergencyMobile')?.errors
                  "
                >
                  Enter Minimum 10 Digits!
                </mat-error>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-expansion-panel> </mat-accordion
      ><br />
    </mat-card>

    <div class="mr-3">
      <mat-accordion #firstAccordion="matAccordion">
        <mat-expansion-panel class="mt-3">
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <ng-container>
                <b>Extended Info</b>
              </ng-container>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row mt-3">
            <div class="row">
              <div class="col-lg-12">
                <mat-form-field class="col-3">
                  <mat-label>SSN</mat-label>
                  <input
                    matInput
                    formControlName="txtSSN"
                    type="text"
                    mask="000-00-0000"
                    [validation]="false"
                    autocomplete="new-txtSSN"
                  />
                  <mat-error
                    *ngIf="
                      personalTabForm?.get('txtSSN')?.touched &&
                      personalTabForm?.get('txtSSN')?.errors
                    "
                  >
                    Enter Minimum 4 Digits!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label> Suffix</mat-label>
                  <mat-select
                    maxlength="40"
                    [(value)]="suffixName"
                    formControlName="drpSuffix"
                  >
                    <mat-option
                      *ngFor="let type of drpSuffixes"
                      [value]="type.suffixShortCodeId"
                    >
                      {{ type.suffixName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    (click)="suffixName = ''; $event.stopPropagation()"
                    *ngIf="suffixName"
                    (click)="clearSuffix()"
                    matSuffix
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label>Marital Status</mat-label>
                  <mat-select
                    formControlName="drpMarital"
                    [(value)]="maritalStatus"
                    maxlength="40"
                  >
                    <mat-option
                      *ngFor="let martail of drpmartailStatusLoop"
                      [value]="martail.maritalStatusTypeId"
                    >
                      {{ martail.maritalStatusType }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    (click)="maritalStatus = ''; $event.stopPropagation()"
                    *ngIf="maritalStatus"
                    (click)="clearMaritalStatus()"
                    matSuffix
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label>Account Number</mat-label>
                  <input
                    matInput
                    maxlength="200"
                    [validation]="false"
                    formControlName="txtAccountNumber"
                    mask="000-000-0000-00000"
                    placeHolderCharacter=""
                    type="text"
                    autocomplete="new-txtAccountNumber"
                  />
                  <mat-error
                    *ngIf="
                      personalTabForm?.get('txtAccountNumber')?.touched &&
                      personalTabForm?.get('txtAccountNumber')?.errors
                    "
                  >
                    Enter Minimum 9 Digits!
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label> Customer Type</mat-label>
                  <mat-select
                    maxlength="40"
                    [(value)]="customerType"
                    formControlName="drpCustomerType"
                  >
                    <mat-option
                      *ngFor="let type of drpCustomerTypes"
                      [value]="type.customerTypeShortCodeId"
                    >
                      {{ type.customerType }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    (click)="customerType = ''; $event.stopPropagation()"
                    *ngIf="customerType"
                    (click)="clearCustomer()"
                    matSuffix
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <mat-form-field class="col-3">
                  <mat-label>Hold Reason</mat-label>
                  <mat-select
                    maxlength="40"
                    [(value)]="holdReason"
                    formControlName="drpHoldReason"
                  >
                    <mat-option
                      *ngFor="let holdReasons of drpHoldReasons"
                      [value]="holdReasons.holdReasonShortCodeId"
                    >
                      {{ holdReasons.holdReasonName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    (click)="holdReason = ''; $event.stopPropagation()"
                    *ngIf="holdReason"
                    matSuffix
                    (click)="clearHoldReason()"
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label>Dunning Message</mat-label>
                  <mat-select
                    maxlength="40"
                    [(value)]="dunningMessage"
                    formControlName="drpDunningMessage"
                  >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      [formControl]="dunningMessageFilter"
                    ></ngx-mat-select-search>
                  </mat-option>
                    <mat-option
                      *ngFor="let dunning of drpDunnigMessages"
                      [value]="dunning.dunningMessageShortCodeId"
                    >
                      {{ dunning.dunningMessage }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    (click)="dunningMessage = ''; $event.stopPropagation()"
                    *ngIf="dunningMessage"
                    (click)="clearDunningMessage()"
                    matSuffix
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label>Alerts</mat-label>
                  <textarea
                    formControlName="txtAlerts"
                    maxlength="400"
                    rows="3"
                    matInput
                  ></textarea>
                </mat-form-field>
                <mat-checkbox class="col-3 pt-3" formControlName="chkCollection"
                  >Collection</mat-checkbox
                >
                <mat-checkbox
                  class="col-3 pt-3"
                  formControlName="chkHipaaSignature"
                  >HIPAA Signature on file
                </mat-checkbox>
                <mat-checkbox
                  class="col-3 pt-3"
                  formControlName="chkAutoDunning"
                  >Auto Dunning</mat-checkbox
                >
                <mat-checkbox class="col-3 pt-3" formControlName="chkStat"
                  >Stat</mat-checkbox
                >
                <mat-checkbox
                  class="col-3 pt-3"
                  formControlName="chkCashPayment"
                  >Cash Payment</mat-checkbox
                >
              </div>
            </div>
            <mat-divider class="mt-3 bg"> </mat-divider>
            <div class="row">
              <div class="col-lg-12">
                <br />
                <div class="row">
                  <div class="col-6">
                    <h4 class="customThemeClass ml-3">
                      <b>Delivery Address</b>
                    </h4>
                  </div>
                  <div class="col-6 text-lg-right">
                    <button
                      (click)="copyPatient()"
                      mat-button
                      class="buttonColor" [disabled]="isEditButton"
                    >
                      <i class="fa fa-clone mr-1"></i>
                      Same as Billing Address
                    </button>
                  </div>
                </div>
                <mat-form-field class="col-3">
                  <mat-label> Address</mat-label>
                  <textarea
                    formControlName="txtDeliveryAddress"
                    rows="3"
                    matInput
                    maxlength="200"
                  ></textarea>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label>Country</mat-label>
                  <mat-select
                    formControlName="drpDeliveryCountry"
                    (selectionChange)="onChangedeliState($event.value)"
                    [(value)]="DeliveryCountry"
                    #singleSelect
                  >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      [formControl]="txtdeliveryCountryFilter"
                    ></ngx-mat-select-search>
                  </mat-option>
                    <mat-option
                      *ngFor="let country of filteredDeliveryCountries"
                      [value]="country.countryShortName"
                    >
                      {{ country.countryName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    (click)="
                      DeliveryCountry = undefined; $event.stopPropagation()
                    "
                    (click)="clearDeliveryCountry()"
                    *ngIf="DeliveryCountry"
                    matSuffix
                    mat-icon-button
                    [disabled]="personalTabForm.disabled"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label>State</mat-label>
                  <mat-select
                    formControlName="txtDeliveryState"
                    (selectionChange)="onChangeDeliCity($event.value, '')"
                    [(value)]="DeliveryState"
                    #singleSelect
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtDeliveryStateFilter"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let state of filteredDeliveryStates | async"
                      [value]="state.stateShortName"
                    >
                      {{ state.stateName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    (click)="
                      DeliveryState = undefined; $event.stopPropagation()
                    "
                    *ngIf="DeliveryState"
                    (click)="clearDeliveryState()"
                    matSuffix
                    mat-icon-button
                    [disabled]="personalTabForm.disabled"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label> County</mat-label>
                  <input
                    formControlName="drpDeliveryCounty"
                    matInput
                    maxlength="40"
                    type="text"
                    autocomplete="new-drpDeliveryCounty"
                  />
                  <mat-error
                    *ngIf="
                      personalTabForm?.get('drpDeliveryCounty')?.touched &&
                      personalTabForm?.get('drpDeliveryCounty')?.errors
                        ?.sAlphabets
                    "
                  >
                    Enter only Alphabets!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label>City</mat-label>
                  <mat-select
                    formControlName="txtDeliveryCity"
                    [(value)]="DeliveryCity"
                    #singleSelect
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtDeliveryCityFilter"
                        hideClearSearchButton="true"
                        [formControl]="txtDeliveryCitiesFilter"
                        [noEntriesFoundLabel]="DeliverycityDrpDownMessage()"
                    (focusout)="DeliveryaddNewCity()"
                      >
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let city of filteredDeliveryCities"
                      [value]="city.cityName"
                    >
                      {{ city.cityName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    matSuffix
                    mat-icon-button
                    (click)="DeliveryCity = ''"
                    [disabled]="personalTabForm.disabled"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label> Zip Code</mat-label>
                  <input
                    matInput
                    maxlength="10"
                    formControlName="txtDeliveryPostalCode"
                    pattern="[0-9-]*"
                    type="text"
                    autocomplete="new-txtDeliveryPostalCode"
                    class="zip-code-input"
                  />
                  <mat-error *ngIf=" personalTabForm?.get('txtDeliveryPostalCode')?.touched &&
                  personalTabForm?.get('txtDeliveryPostalCode')?.errors?.pattern">
                      Enter only Numbers!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label> Landline</mat-label>
                  <input
                    matInput
                    maxlength="40"
                    formControlName="txtDeliveryPhone"
                    prefix="+{{ phoneDelCode }}-"
                    mask="(000)-000-0000"
                    type="text"
                    autocomplete="new-txtDeliveryPhone"
                  />
                  <mat-error
                    *ngIf="
                      personalTabForm?.get('txtDeliveryPhone')?.touched &&
                      personalTabForm?.get('txtDeliveryPhone')?.errors
                    "
                  >
                    Enter Minimum 10 Digits!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label> Fax</mat-label>
                  <input
                    matInput
                    maxlength="40"
                    formControlName="txtDeliveryFax"
                    prefix="+{{ phoneDelCode }}-"
                    mask="(000)-000-0000"
                    type="text"
                    autocomplete="new-txtDeliveryFax"
                  />
                  <mat-error
                    *ngIf="
                      personalTabForm?.get('txtDeliveryFax')?.touched &&
                      personalTabForm?.get('txtDeliveryFax')?.errors
                    "
                  >
                    Enter Minimum 10 Digits!
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label> Zone</mat-label>
                  <mat-select
                    maxlength="40"
                    [(value)]="zones"
                    formControlName="drpZone"
                  >
                    <mat-option
                      *ngFor="let zone of drpZones"
                      [value]="zone.zoneTypeShortCodeId"
                    >
                      {{ zone.zoneType }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    (click)="zones = ''; $event.stopPropagation()"
                    *ngIf="zones"
                    matSuffix
                    (click)="clearZones()"
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label> Mobile</mat-label>
                  <input
                    matInput
                    maxlength="40"
                    formControlName="txtDeliveryMobile"
                    prefix="+{{ phoneDelCode }}-"
                    mask="(000)-000-0000"
                    type="text"
                    autocomplete="new-txtDeliveryMobile"
                  />
                  <mat-error
                    *ngIf="
                      personalTabForm?.get('txtDeliveryMobile')?.touched &&
                      personalTabForm?.get('txtDeliveryMobile')?.errors
                    "
                  >
                    Enter Minimum 10 Digits!
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <mat-divider class="mt-3 bg"> </mat-divider>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <br />
    <div class="row">
      <div class="col-sm-12">
        <div class="text-lg-center">
          <button
            mat-button
            class="buttonColor"
            (click)="savePersonal()"
            [disabled]="isEditButton ||
              saveButtonHide ||
              !(!personalTabForm.invalid && personalTabForm.dirty)
            "
          >
            Save
            <mat-icon *ngIf="isShowSpinner">
              <mat-spinner
                class="spinner-border spinner-border-sm"
                diameter="20"
              >
              </mat-spinner>
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<br />
<br />
<br />


