import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { doctorInterface } from 'projects/patient/src/app/patient-clinical/patient-clinical.component';
import {
  DoctorService,
  PayerService,
} from 'projects/patient/src/app/patient-proxy/patient';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { PatientExportSearchDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { patientExportOrder } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-export-order.enum';
import { MatDialog } from '@angular/material/dialog';
import { PatientStatementHistoryComponent } from '../patient-statement-history/patient-statement-history.component';

@Component({
  selector: 'app-patient-statement',
  templateUrl: './patient-statement.component.html',
  styleUrls: ['./patient-statement.component.scss'],
})
export class PatientStatementComponent implements OnInit {
  isLoaded: boolean = true;
  lstChartNos: any[] = [];
  public paymentColor: ThemePalette = 'primary';
  public noteColor: ThemePalette = 'primary';
  public touchUi = false;
  patientStatementForm: FormGroup;
  patientListDropdownSubscription: Subscription;
  referralDropdown: Subscription;
  referralPractice: any;
  filteredPractices: Observable<doctorInterface[]> | undefined;
  lstBranch: any;
  filteredLocationDetails: Observable<string[]> | undefined;
  lstInsuranceCategory: any;
  filteredInsuranceCategory: Observable<string[]> | undefined;
  tenantId: string = defaultGuid;
  ltPatientStatementReport: any;
  subscription$: Subscription[] = [];
  constructor(
    private title: Title,
    private fb: FormBuilder,
    private commonService: CommonService,
    private mmOrderService: MmOrderService,
    private doctorService: DoctorService,
    private branchService: OrganizationUnitService,
    private payerService: PayerService,
    private patientPersonalService: PatientPersonalService
  ) {
    this.title.setTitle('Qsecure | Patient Statement');
    this.tenantId = localStorage.getItem('tenantId') ?? '';
  }
  ngOnInit() {
    this.initializeForms();
    this.loadDropdowns();
    this.getPatientStatementReport(true);
  }
  initializeForms() {
    this.patientStatementForm = this.fb.group({
      chkDontSendStatement: new FormControl(''),
      txtDontSendStatement: new FormControl(''),
      drpPatientName: new FormControl(''),
      txtPatientNameFilter: new FormControl(''),
      drpPractices: new FormControl(''),
      txtPracticeFilter: new FormControl(''),
      drpPatientLocation: new FormControl(''),
      txtLocationFilter: new FormControl(''),
      drpInsuranceCategory: new FormControl(''),
      txtInsuranceCategoryFilter: new FormControl(''),
      rdbtnTotalBalance: new FormControl(''),
      txtBalanceLessThan: new FormControl(''),
      drpOrderBy: new FormControl(''),
      txtOrderByFilter: new FormControl(''),
      drpPreCollectionStatus: new FormControl(''),
      txtPreCollectionStatusFilter: new FormControl(''),
      txtPaymentColor: new FormControl(''),
      txtNoteColor: new FormControl(''),
      txtPaymentDueinDays: new FormControl(''),
      txtPaymentForLastDays: new FormControl(''),
      chkShowCreditCardPaymentOptions: new FormControl(''),
      chkShowPatientsProviderOnly: new FormControl(''),
      chkShowInsurancePendingTotalAmount: new FormControl(''),
      chkShowPatientPaymentsSinceStatement: new FormControl(''),
      chkShowPatientPaymentsForLastDays: new FormControl(''),
      chkIncludeAllOpenLineItems: new FormControl(''),
      chkDontShowCharges: new FormControl(''),
      txtCreditCardNote: new FormControl(''),
    });
    this.patientStatementForm.get('txtBalanceLessThan').disable();
    this.patientStatementForm.get('txtPaymentForLastDays').disable();
  }

  //#region Dropdowns

  loadDropdowns() {
    this.loadPatientListDropdown('', defaultGuid);
    this.practiceSearch('');
    this.loadBranchesDropdown();
    this.loadInsuranceCategoryDropdown();
  }
  loadPatientListDropdown(searchParameter: string, patientId: string): any {
    if (this.patientListDropdownSubscription) {
      this.patientListDropdownSubscription.unsubscribe();
    }
    this.patientListDropdownSubscription = this.mmOrderService
      .getPatientChartNoV3BySSearchParamsAndPatient(searchParameter, patientId)
      .subscribe(
        (response) => {
          this.lstChartNos = response;
        },
        (err) => {
          return [];
        }
      );
  }
  searchPatient(event: KeyboardEvent, value: any, patientId: string) {
    if (this.isAlphanumeric(event.key) || event.key === 'Backspace') {
      this.loadPatientListDropdown(value, patientId);
    }
  }
  isAlphanumeric(key: string): boolean {
    const regex = /^[a-z0-9]$/i;
    return regex.test(key);
  }
  //Practice Management Dropdown
  practiceSearch(value) {
    if (this.referralDropdown) {
      this.referralDropdown.unsubscribe();
    }
    this.referralDropdown = this.doctorService
      .getPracticeDropdown(value, defaultGuid)
      .subscribe(
        (response) => {
          this.referralPractice = response;
          //Referring Provider Doctor Dropdown
          this.filteredPractices = this.patientStatementForm
            ?.get('txtPracticeFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.referralPractice?.filter((option) =>
                  option?.practiceName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
  }

  //To Load the Branch Dropdown
  loadBranchesDropdown() {
    const getBranchList = this.branchService
      .getBranchListV1(this.tenantId, false)
      .subscribe((response) => {
        this.lstBranch = response;
        this.lstBranch = response.sort((a, b) =>
          a.organizationUnitName.localeCompare(b.organizationUnitName)
        );
        this.filteredLocationDetails = this.patientStatementForm
          .get('txtLocationFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstBranch?.filter((option) =>
                option?.organizationUnitName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    this.subscription$.push(getBranchList);
  }

  //To Load the Insurance Category Dropdown
  loadInsuranceCategoryDropdown() {
    const payerList = this.payerService.InsuranceCategoryGetList().subscribe(
      (response) => {
        this.lstInsuranceCategory = response.items ?? [];
        // filteredInsuranceCategory
        this.lstInsuranceCategory = this.lstInsuranceCategory.sort((a, b) =>
          a.insuranceCategory.localeCompare(b.insuranceCategory)
        );
        this.filteredInsuranceCategory = this.patientStatementForm
          .get('txtInsuranceCategoryFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstInsuranceCategory?.filter((option) =>
                option?.insuranceCategory
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (err) => {
        console.log(err);
      }
    );
    this.subscription$.push(payerList);
  }

  //#endregion

  //On Patient Dropdown Change Method
  onPatientChange(event: any) {
    let patientId = event?.value ?? defaultGuid;
    const onChangePatient = this.patientPersonalService
      .getPatientIdDetailsByPatient(patientId)
      .subscribe(
        (response) => {
          let data = response;

          this.patientStatementForm.patchValue({
            drpPractices: data?.practiceId,
            drpPatientLocation: data?.locationId,
            drpInsuranceCategory: data?.insId,
          });
          // this.patientStatementForm.disable();
          this.patientStatementForm.get('drpPractices').disable();
          this.patientStatementForm.get('drpPatientLocation').disable();
          this.patientStatementForm.get('drpInsuranceCategory').disable();
        },
        (err) => {}
      );
    this.subscription$.push(onChangePatient);
  }

  //To get the Patient Statement Report
  getPatientStatementReport(isDefault: boolean) {
    this.isLoaded = false;
    let input: PatientExportSearchDTO = {
      patientId: this.patientStatementForm.value.drpPatientName,
      practiceId: this.patientStatementForm.value.drpPractices,
      branchId: this.patientStatementForm.value.drpPatientLocation,
      insuranceId: this.patientStatementForm.value.drpInsuranceCategory,
      orderBy: this.patientStatementForm.value.drpOrderBy,
      isDefault: isDefault,
      isGreaterthan:
        this.patientStatementForm.value.rdbtnTotalBalance == 1 ? true : false,
      balance: this.patientStatementForm.value.txtBalanceLessThan ?? null,
      days:
        this.patientStatementForm.value.chkShowPatientPaymentsForLastDays ==
        true
          ? this.patientStatementForm.value.txtPaymentForLastDays
          : null,
    };

    this.patientPersonalService.getPatientExportDataByDTO(input).subscribe(
      (response) => {
        this.ltPatientStatementReport = response;

        this.ltPatientStatementReport = this.ltPatientStatementReport.map(
          (data) => {
            return {
              ...data,
              paidDate: this.commonService.getFormattedDateZone(data.paidDate),
            };
          }
        );

        this.isLoaded = true;
      },
      (err) => {
        this.ltPatientStatementReport = [];
        this.isLoaded = true;
      }
    );
  }
  //To change the field when the balance criteria was changed
  balanceCriteriaChange() {
    let value = this.patientStatementForm.value.rdbtnTotalBalance;
    if (this.patientStatementForm.value.rdbtnTotalBalance !== 2) {
      this.patientStatementForm.get('txtBalanceLessThan').disable();
    } else {
      this.patientStatementForm.get('txtBalanceLessThan').enable();
    }
  }
  paymentChecked() {
  
    let value =
      this.patientStatementForm.value.chkShowPatientPaymentsForLastDays;
    if (!this.patientStatementForm.value.chkShowPatientPaymentsForLastDays) {
      this.patientStatementForm.get('txtPaymentForLastDays').disable();
    } else {
      this.patientStatementForm.get('txtPaymentForLastDays').enable();
    }
  }
  clearForm() {
    this.patientStatementForm.patchValue({
      drpPatientName: '',
      drpPatientLocation: '',
      drpInsuranceCategory: '',
    });
    this.patientStatementForm.get('drpPractices').enable();
    this.patientStatementForm.get('drpPatientLocation').enable();
    this.patientStatementForm.get('drpInsuranceCategory').enable();
  }
  resetForm() {
    this.patientStatementForm.reset();
    this.clearForm();
    this.getPatientStatementReport(true);
  }
  // //On Key Press Validation
  // onKeyPress(event: KeyboardEvent) {
  //   const isNumber = /[0-9]/.test(event.key);
  //   if (!isNumber) {
  //     event.preventDefault(); // Prevent the input if the key is not a number
  //   }
  // }

  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value + event.key;

    // Prevent the input if the key is not a number or if it results in an invalid number (e.g., starts with 0)
    if (!isNumber || (currentValue.length === 1 && currentValue === '0')) {
      event.preventDefault();
    }
  }

  numbersAndfloatOnly(event: any) {
    const allowedChars = '0123456789.';
    const inputChar = event.key;

    if (event.key === '.' && event.currentTarget.value.includes('.')) {
      // Allow only one decimal point
      event.preventDefault();
    } else if (allowedChars.indexOf(inputChar) === -1) {
      // Prevent input of characters other than numbers and dot
      event.preventDefault();
    }
  }
}
