<br />
<div class="card card-body">
  <!-- <div class="list-box"> -->
  <div class="mt-3">
    <div class="row">
      <h4 class="col-9 customThemeClass" style="margin-top: -20px;"><b>Add Document Info</b></h4>
    </div>
    <form [formGroup]="documentForm">
      <mat-card-content>
        <div class="row">
          <mat-card-content class="col-12">
            <div class="row">
              <div class="col-12" *ngIf="showErrorForFileType">
                <span class="asterisk">{{ 'Select Only PDF`s' }}</span>
              </div>
              <div class="col-12" *ngIf="showErrorBasedOnSize">
                <span class="asterisk">{{ 'Select each file`s size of 5mb' }}</span>
              </div>
              <div class="col-12" *ngIf="showErrorForFileLength">
                <span class="asterisk">{{ 'Select Only 10 files' }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label [hidden]="isDocumentForButtonDisable" for="file-upload" (click)="resetDocumentForm()"
                  class="col-lg-6 btnclr waves-effect font-size-12 pt-2 pb-2">
                  <i class="fa fa-cloud-upload"></i> Upload<span class="asterisk"> * </span>
                </label>
                <input [hidden]="isDocumentForButtonDisable" id="file-upload" formControlName="documentFile"
                  [multiple]="true" #UploadFeildReference (change)="getfilename(UploadFeildReference.files)"
                  accept="application/pdf" maxFileSize="10000" [ngClass]="{ hint: showErrorForExtension }"
                  type="file" />
              </div>
            </div>
            <div class="row pt-1">
              <mat-form-field class="col-3">
                <mat-label>Document Upload Type <span class="asterisk">*</span> </mat-label>
                <mat-select (selectionChange)='documentTypeDropdownChange()' formControlName="drpDocumentUploadType"
                  maxlength="40">
                  <mat-option *ngFor="let uploadType of drpDocumentUploadType" [value]="uploadType.sortingType">
                    {{ uploadType.sortingType }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Document Type <span
                    *ngIf="documentForm?.get('drpDocumentUploadType').value==='Manual Sorting'" class="asterisk">*</span>
                </mat-label>
                <mat-select formControlName="drpDocumentType" maxlength="40">
                  <mat-option *ngFor="let document of drpDocumentTypeLoop" [value]="document.documentType">
                    {{ document.documentType }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Review Status</mat-label>
                <mat-select formControlName="drpReviewStatus" maxlength="40">
                  <mat-option *ngFor="let reviewstatus of drpReviewStatusLoop"
                    [value]="reviewstatus.reviewStatusShortCodeId">
                    {{ reviewstatus.reviewStatus }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Review Reason</mat-label>
                <mat-select formControlName="drpReviewReason" maxlength="40">
                  <mat-option *ngFor="let reviewstatus of drpReviewReasonLoop"
                    [value]="reviewstatus.reviewReasonShortCodeId">
                    {{ reviewstatus.reviewReason }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col-3">
                <mat-label>Document Start Date
                  <ng-container
                    *ngIf="documentForm?.get('drpDocumentType')?.value === 'RX' || documentForm?.get('drpDocumentType')?.value === 'FACE TO FACE'; else elseEndDateReqTemplate">
                    <span class="asterisk">*</span>

                  </ng-container>
                  <ng-template #elseEndDateReqTemplate>
                  </ng-template>
                </mat-label>
                <input autocomplete="new-txtDocumentStartDate" formControlName="txtDocumentStartDate" placeholder="(MM/DD/YYYY)" matInput maxlength="40"
                  [ngxMatDatetimePicker]="txtDocumentStartDate" />
                <mat-datepicker-toggle matSuffix [for]="$any(txtDocumentStartDate)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #txtDocumentStartDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                  [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
                  <ng-template>
                    <span>OK</span>
                  </ng-template>
                </ngx-mat-datetime-picker>
                <ng-container
                  *ngIf="documentForm?.get('drpDocumentType')?.value === 'RX' || documentForm?.get('drpDocumentType')?.value === 'FACE TO FACE'; else elseStartDateValTemplate">
                  <mat-error *ngIf="
                  documentForm?.get('txtDocumentStartDate')?.touched &&
                  documentForm?.get('txtDocumentStartDate')?.errors?.required
                ">
                      Document Start Date is a required field!
                    </mat-error>
                </ng-container>
                <ng-template #elseStartDateValTemplate>

                </ng-template>
                <mat-error *ngIf="
                documentForm?.get('txtDocumentStartDate')?.touched &&
                documentForm?.get('txtDocumentStartDate')?.errors?.matDatetimePickerParse">
                  Enter Valid Date!
                </mat-error>
              </mat-form-field>


              <mat-form-field class="col-3">
                <mat-label>Document End Date
                  <ng-container
                    *ngIf="documentForm?.get('drpDocumentType')?.value === 'RX' || documentForm?.get('drpDocumentType')?.value === 'FACE TO FACE'; else elseEndDateReqTemplate">
                    <span class="asterisk">*</span>
                  </ng-container>
                  <ng-template #elseEndDateReqTemplate>

                  </ng-template>
                </mat-label>
                <input autocomplete="new-txtDocumentEndDate" formControlName="txtDocumentEndDate" placeholder="(MM/DD/YYYY)" matInput maxlength="40"
                  [ngxMatDatetimePicker]="txtDocumentEndDate" />
                <mat-datepicker-toggle matSuffix [for]="$any(txtDocumentEndDate)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #txtDocumentEndDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                  [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
                  <ng-template>
                    <span>OK</span>
                  </ng-template>
                </ngx-mat-datetime-picker>

                <ng-container
                  *ngIf="documentForm?.get('drpDocumentType')?.value === 'RX' || documentForm?.get('drpDocumentType')?.value === 'FACE TO FACE'; else elseEndDateValTemplate">
                  <mat-error *ngIf="
                  documentForm?.get('txtDocumentEndDate')?.touched &&
                  documentForm?.get('txtDocumentEndDate')?.errors?.required
                ">
                        Document End Date is a required field!
                      </mat-error>
                </ng-container>
                <ng-template #elseEndDateValTemplate>

                </ng-template>



                <mat-error *ngIf="
                documentForm?.get('txtDocumentEndDate')?.touched &&
                documentForm?.get('txtDocumentEndDate')?.errors?.matDatetimePickerParse">
                  Enter Valid Date!
                </mat-error>

              </mat-form-field>
              <mat-form-field class="col-6">
                <mat-label>Description</mat-label>
                <textarea formControlName="txtNote" rows="1" matInput maxlength="400"></textarea>
              </mat-form-field>

              <button *ngIf="orderStatus" mat-button [hidden]="isDocumentEditSaveButtonHidden"
                [disabled]="documentForm.invalid || documentForm?.get('drpDocumentUploadType').value==='Manual Sorting' && documentForm?.get('drpDocumentType').value===null || isShowSpinner"
                (click)="upload(UploadFeildReference.files)" class="btnsave buttonColor mb-2 mr-2">
                Save
                <mat-icon *ngIf="isShowSpinner">
                  <mat-spinner class="spinner-border spinner-border-sm" diameter="120"> </mat-spinner>
                </mat-icon>
              </button>
              <button mat-button (click)="resetDocumentForm()"
                class="resetclr waves-effect font-size-12 btn-sm mr-2 ml-2">
                Reset
              </button>
            </div>
          </mat-card-content>
          <div *ngIf="show" class="col-12">
            <div [hidden]="isDocumentEditSaveButtonHidden" class="alert alert-secondary mt-2 pb-0 pr-0 pl-0 pt-2 p1">
              <label for="">
                <i class="bx bxs-info-circle font-size-16 align-middle mr-2"></i>
                Selected Document(s) Count:{{ filesname.length }}</label>
            </div>
            <div class="table-bordered" *ngIf="filesname !== [] && filesname.length !== 0">
              <div class="lbl customThemeClass" *ngIf="filesname !== [] && filesname.length !== 0">
                <b>Documents To be Uploaded</b>
              </div>
              <div *ngIf="filesname !== [] && filesname.length !== 0" class="list-box">
                <div class="bgr mb-1" *ngFor="let item of filesname">
                  <i class="fa fa-file-pdf-o" ></i>
                  {{ item }}
                </div>
              </div>
            </div>
            <label class="customThemeClass mt-1"
              *ngIf="arrUnsortedDocumentList !== [] && arrUnsortedDocumentList.length !== 0">
              Uploaded Document(s) Count:{{ arrUnsortedDocumentList.length }}
            </label>
            <!-- <div [hidden]="!isLoading"
              style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
              <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
              </mat-progress-bar>
            </div> -->
            <div *ngIf="arrUnsortedDocumentList !== [] && arrUnsortedDocumentList.length !== 0"
              class="col-12 table-responsive">

              <table mat-table class="table" [dataSource]="dataSource" matSort>
                <form style="display: flex">
                  <ng-container matColumnDef="OrderId">
                    <th class="header" mat-header-cell *matHeaderCellDef>Document Type </th>mt
                    <td mat-cell *matCellDef="let element"> {{element.documentType}} </td>
                  </ng-container>
                  <ng-container matColumnDef="ItemId">
                    <th class="header" mat-header-cell *matHeaderCellDef> File Name</th>
                    <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
                  </ng-container>
                  <ng-container matColumnDef="documentStartDate">
                    <th class="header" mat-header-cell *matHeaderCellDef>Document Start Date</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element?.documentStartDate | date: 'MM/dd/yyyy h:mm a':'en_US' }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="documentEndDate">
                    <th class="header" mat-header-cell *matHeaderCellDef>Document End Date</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element?.documentEndDate | date: 'MM/dd/yyyy h:mm a':'en_US' }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="documentStatus">
                    <th class="header" mat-header-cell *matHeaderCellDef>
                      Document Status
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element?.docStatus }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="type">
                    <th class="header" mat-header-cell *matHeaderCellDef>Document Upload Type</th>
                    <td mat-cell *matCellDef="let element"> {{element.itemName}} </td>
                  </ng-container>
                  <ng-container matColumnDef="options">
                    <th class="header" mat-header-cell *matHeaderCellDef>
                      Options
                    </th>
                    <td mat-cell *matCellDef="let documentList">
                      <a class="editBtnColor">
                        <i class="fa fa-pencil mr-3 pointer customThemeClass"
                          (click)="viewPdfFile(documentList.blobName, documentList.patientDocumentID)"></i>
                        <i class="fa fa-cloud-download mr-3 pointer customThemeClass"
                          (click)="downloadFile(documentList.blobName, documentList.fileName)"></i>
                        <i [hidden]="!orderStatus" *ngIf='isShownDocumentDeleteIcon'
                          class="fa fa-trash pointer asterisk"
                          (click)="deleteFileById(documentList.patientDocumentID)"></i>
                      </a>
                    </td>
                  </ng-container>


                  <ng-container matColumnDef="noRecords">
                    <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="6">
                      <div *ngIf="!isLoading">
                        {{"No Records Found"}}
                      </div>
                      <div [hidden]="!isLoading">
                        <mat-spinner [diameter]="220"></mat-spinner>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="paginator">
                    <td mat-footer-cell *matFooterCellDef colspan="6">
                      <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                        [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                        [pageSizeOptions]="[5, 10, 25, 100]">
                      </mat-paginator>
                    </td>
                  </ng-container>
                </form>
                <tr mat-footer-row *matFooterRowDef="['noRecords']"
                  [ngClass]="{ active: dataSource && dataSource.data && dataSource.data.length !== 0 }"></tr>
                <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
              </table>
            </div>
          </div>
          <div *ngIf="strSelectedPdfPath" class="col-12">
            <div class="container-fluid mt-2">
              <ngx-extended-pdf-viewer [textLayer]="true" [showHandToolButton]="true" [src]="strSelectedPdfPath"
                [height]="'70vh'" [useBrowserLocale]="false" [delayFirstView]='1000'>
              </ngx-extended-pdf-viewer>
            </div>
          </div>
        </div>
      </mat-card-content>
      <br>
      <ng-container *ngIf="strPageType==='shippingApproval';">
        <hr>
        <mat-form-field *ngIf="showReason" class="col-2">
          <mat-label>Reason</mat-label>
          <input autocomplete="new-txtReason" matInput maxlength="100" formControlName="txtReason" type="text">
        </mat-form-field>
        <div class="row">
          <div class="col-1">
            <h3 class="customThemeClass"><b>Status<span class="asterisk">*</span></b></h3>

          </div>
          <div class="col-8 ">
            <mat-radio-group (change)="onChangeGetReason($event)" formControlName="rbtnDocument"
              class="col-sm-7 radio mt-2 mr-2 txt-left">
              <mat-radio-button class="mr-2" value="Approved">Approve</mat-radio-button>
              <mat-radio-button class="mr-2" value="Disapproved">Disapprove</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-sm-3">
            <div class="text-lg-right">
              <ng-container *ngIf='!isShown; else elseTemplate'>
                <button mat-button [disabled]="documentForm?.get('rbtnDocument').value===''"
                  (click)="savedocumentloop(this.documentForm.value.rbtnDocument)"
                  class="btn buttonColor waves-effect font-size-12 btn-sm mr-1 ml-1">
                  Save
                  <mat-icon *ngIf='isShowSpinner'>
                    <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                  </mat-icon>
                </button>
              </ng-container>
              <ng-template #elseTemplate>
                <button mat-button
                  [disabled]="documentForm.invalid || documentForm?.get('rbtnDocument').value==='' || documentForm?.get('drpDocumentUploadType').value==='Manual Sorting' && documentForm?.get('drpDocumentType').value===null"
                  (click)="upload(UploadFeildReference?.files)"
                  class="btn buttonColor waves-effect font-size-12 btn-sm mr-1 ml-1">
                  Save
                  <mat-icon *ngIf='isShowSpinner'>
                    <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                  </mat-icon>
                </button>
              </ng-template>

            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</div>
