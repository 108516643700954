<div class="row" *ngIf="isLoading">
  <div class="col-12">
    <div class="d-flex justify-content-center" *ngIf="isLoading">
      <!-- <mat-spinner></mat-spinner> -->
      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
    </div>
  </div>
</div>


<table  *ngIf="!isLoading" id="tblProvider" datatable [dtOptions]="dtProviderListTableOptions" class="tbl hover row-border hover w-100 display">
    <thead>
        <tr>
            <th><mat-icon>apps</mat-icon></th>
            <th [matTooltip]="'NPI'" class="text-right">NPI</th>
            <th [matTooltip]="'Prefix Code'">Prefix Code</th>
            <th [matTooltip]="'Name'">Name</th>
            <th [matTooltip]="'MM Tax ID'">MM Tax ID</th>
            <th [matTooltip]="'Phone'" class="text-right">Phone</th>
            <th [matTooltip]="'createdBy'">Created By</th>
            <th class="text-right" [matTooltip]="'Created Date'" class="text-center">Created Date</th>
            <th [matTooltip]="'Modify By'">Modify By</th>
            <th class="text-right" [matTooltip]="'Created/Modified Date'" class="text-center">Modified Date</th>
            <th [matTooltip]="'Status'">Status</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of providerTableDataList">

            <td>
                <a class="eyeCursorclass" (click)="ViewProvider(data?.id)" [matTooltip]="'Click To Edit Provider'">
                    <i class="fa fa-pencil pointer editBtnColor pl-3"></i>
                </a>
                <!-- <a class="deleteBtnColor" (click)="deleteProvider(data?.id)" [matTooltip]="'Click To Delete Provider'">
                    <i class="fa fa-trash pointer deleteBtnColor pl-3"></i>
                </a> -->
            </td>

            <td class="text-right" [matTooltip]="data?.npi || '-'" (dblclick)="onRowDoubleClickV1(data?.npi || '-')">{{
                data?.npi || '-'}}</td>
            <td [matTooltip]="data?.prefixCode || '-'">{{data?.prefixCode || '-'}}</td>
            <td [matTooltip]="data?.fullName || '-'" (dblclick)="onRowDoubleClickV1(data?.fullName || '-')">
                {{data?.fullName===null?'-':data?.fullName}}
            </td>
            <td [matTooltip]="data?.mmTaxId || '-'" (dblclick)="onRowDoubleClickV1(data?.mmTaxId || '-')">
              {{data?.mmTaxId || '-'}}
          </td>
            <td class="text-right" [matTooltip]="data?.phone || '-'" (dblclick)="onRowDoubleClickV1(data?.phone || '-')">
                {{data?.phone || '-'}}
            </td>
            <td [matTooltip]="data.createdBy || '-'" (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')">{{data?.createdBy ?
              data.createdBy : '-'}}</td>
            <td class="text-right"  [matTooltip]="data?.creationTime"
              (dblclick)="onRowDoubleClickV1(data?.creationTime)" class="text-center">
              {{ data?.creationTime }}
            </td>
            <td [matTooltip]="data.modifiedBy || '-'" (dblclick)="onRowDoubleClickV1(data?.modifiedBy || '-')">{{data?.modifiedBy ?
              data.modifiedBy : '-'}}</td>
            <td class="text-right"  [matTooltip]="data?.lastModificationTime"
              (dblclick)="onRowDoubleClickV1(data?.lastModificationTime)" class="text-center">
              {{ data?.lastModificationTime }}
            </td>
            <td>
              <ng-container >
                <mat-slide-toggle [matTooltip]="data?.isActive===1 ? 'Active' : 'Inactive' || '-'" (change)="statusChange($event, data)" [checked]="data?.isActive==1" >
                    {{ data?.isActive===1 ? 'Active' : 'Inactive' }}
                </mat-slide-toggle>
            </ng-container>
            </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th><mat-icon>apps</mat-icon></th>
            <th class="text-right">
                <input matInput class="textbox-border-class" type="text" placeholder="NPI" name="search-NPI" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Prefix Code" name="search-Prefix Code" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Name" name="search-Pre-Name" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="MM Tax ID" name="search-MMTaxID" />
            </th>
            <th class="text-right">
              <input matInput class="textbox-border-class" type="text" placeholder="Phone" name="search-Phone" />
          </th>
            <th>
              <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                name="search-Created By" />
            </th>
            <th class="text-right">
              <input matInput type="text" class="textbox-border-class text-center" placeholder="Created Date"
                name="search-Created Date" />
            </th>
            <th>
              <input matInput class="textbox-border-class" type="text" placeholder="Modified By"
                name="search-Modified By" />
            </th>
            <th class="text-right">
              <input matInput type="text" class="textbox-border-class text-center" placeholder="Modified Date"
                name="search-Modified Date" />
            </th>
            <th>
              <input matInput class="textbox-border-class" type="text" placeholder="Status"
                name="search-Status" />
            </th>
        </tr>
    </tfoot>
</table>
