<div class="container-fluid">
  <!-- Page Headers -->
  <div class="row bg" style="margin-top: -10px">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0 font-size-18"><b>Auto Sorting</b></h3>
        <div class="page-title-box page-title-right pb-0">
          <ol class="breadcrumb m-0" style="background-color: transparent">
            <li class="ml-2 mt-2 breadcrumb-item">Sorting</li>
            <li class="mt-2 breadcrumb-item muted">Processed Documents</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <mat-card style="margin-bottom: 30px">
      <mat-card-content>
        <div class="row">
          <div class="col-lg-2">
            <div class="card" class="custom-select-card">
              <div class="card-header custom-card-header">
                <b>Inbox [ {{ totalPages }} ] </b>
              </div>
              <div class="card-body">
                <mat-selection-list
                  #documentTypes
                  id="sltDocumentType"
                  [multiple]="false"
                  [(ngModel)]="dTypeId"
                  (selectionChange)="onListSelected()"
                  class="custom-selection-list"
                >
                  <mat-list-option
                    *ngFor="let document of documentClassification"
                    [value]="document.docTypeId"
                  >
                    {{ document.documentType }}

                    <span
                      class="pull-right"
                      matBadge="{{ document.documentPages.length }}"
                      matBadgeOverlap="false"
                    ></span>
                  </mat-list-option>
                </mat-selection-list>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <!-- style="height: 800px;" -->
            <div class="pdf-container">
              <ng-container>
                <ngx-extended-pdf-viewer
                  [base64Src]="strSelectedPdfPath"
                  [(page)]="pageNumber"
                  [(pageViewMode)]="pageViewMode"
                  (pagesLoaded)="onPagesLoaded($event)"
                  [height]="pdfHeight"
                  [showSidebarButton]="false"
                  [showFindButton]="true"
                  [textLayer]="true"
                  [showPagingButtons]="true"
                  [showOpenFileButton]="false"
                  [showHandToolButton]="false"
                  [showScrollingButton]="false"
                  [showSpreadButton]="false"
                  [showPropertiesButton]="false"
                  [showBookmarkButton]="false"
                  [zoom]="'page-width'"
                  [enableDragAndDrop]="false"
                >
                </ngx-extended-pdf-viewer>
              </ng-container>
            </div>
          </div>
          <div class="col-lg-4">
            <form [formGroup]="autoExtractionForm">
              <ng-container>
                <!--Progress Bar-->
                <div class="row mt-2">
                  <div class="col-12">
                    <mat-progress-bar
                      mode="determinate"
                      [ngClass]="{
                        progress_success: percentageOfCompletion === 100,
                        progress_danger: percentageOfCompletion !== 100
                      }"
                      matTooltip="{{
                        percentageOfCompletion + ' % Of Completion'
                      }}"
                      [value]="percentageOfCompletion"
                    >
                    </mat-progress-bar>
                  </div>
                  <div class="col-12" [style.text-align]="'-webkit-center'">
                    <button
                      type="button"
                      *ngIf="percentageOfCompletion === 100"
                      class="mt-3 btn btn-success"
                    >
                      {{ percentageOfCompletion + " % Of Completion" }}
                    </button>
                    <button
                      type="button"
                      *ngIf="percentageOfCompletion !== 100"
                      class="mt-3 btn btn-danger"
                    >
                      {{ percentageOfCompletion + " % Of Completion" }}
                    </button>
                  </div>
                </div>
                <!--Dropdowns-->
                <div class="row mt-2">
                  <div class="col-12">
                    <br />
                    <mat-checkbox formControlName="chkIsRulesNeeded"
                      >Is Rules Needed
                    </mat-checkbox>
                  </div>
                  <mat-form-field class="col-12">
                    <mat-label>DX Type</mat-label>
                    <mat-select formControlName="drpDXType">
                      <mat-option
                        *ngFor="let dx of drpDXType"
                        [value]="dx.dxType"
                      >
                        {{ dx.dxType }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field
                    class="col-12"
                    *ngIf="
                      autoExtractionForm?.get('drpDXType')?.value !==
                        'CPAP to BIPAP' &&
                      autoExtractionForm?.get('drpDXType')?.value !== 'CompSA'
                    "
                  >
                    <mat-label>Product Setup Type</mat-label>
                    <mat-select formControlName="drpPrdSetupType">
                      <mat-option
                        *ngFor="let setup of drpPrdSetupType"
                        [value]="setup?.setupType"
                      >
                        {{ setup?.setupType }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!--Accordions-->
                <div class="row mt-2">
                  <div class="col-12">
                    <mat-accordion #firstAccordion="matAccordion">

                        <!-- Demographics -->
                      <mat-expansion-panel
                        id="DEMOGRAPHICS"
                        #mep="matExpansionPanel"
                        (opened)="currentlyOpenAcc('DEMOGRAPHICS')"
                        [expanded]="false"
                      >
                        <mat-expansion-panel-header>
                          <mat-panel-title class="customThemeClass mt-3">
                            <ng-container>
                              <b>Demographics </b>
                              <i
                                *ngIf="demographicsPendingValidationCount === 0"
                                class="fa fa-check-circle-o ml-5"
                              ></i>
                              <span
                                *ngIf="demographicsPendingValidationCount !== 0"
                                [ngClass]="{
                                  ' deleteBtnColor ml-5':
                                    demographicsPendingValidationCount !== 0
                                }"
                                matBadge="{{
                                  demographicsPendingValidationCount
                                }}"
                                matBadgeSize="small"
                              ></span>
                            </ng-container>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row">
                          <div class="list-group list-group-flush">
                            <div class="list-group-item">
                              <h4 class="card-title">
                                <b>Demographics</b>
                              </h4>
                              <ng-container>
                                <p>Extracted Patient</p>
                                <div class="table-responsive">
                                  <table class="table table-bordered table-sm">
                                    <thead>
                                      <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">DOB</th>
                                        <th scope="col">Options</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>test Patient</td>
                                        <td>12/05/2000</td>
                                        <td>
                                          <input
                                            class="customThemeClass"
                                            style="width: 60px; height: 20px"
                                            type="radio"
                                            matTooltip="Click To Select Patient"
                                            target="_blank"
                                          />
                                          sddsfsdf
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </ng-container>
                              <ng-container>
                                <p>Identical Patient</p>
                                <div class="table-responsive">
                                  <table class="table table-bordered table-sm">
                                    <thead>
                                      <tr>
                                        <th scope="col">Patient Id</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">DOB</th>
                                        <th scope="col">Mail Id</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Options</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <ng-container>
                                        <tr>
                                          <th scope="row">
                                            dfgdfg
                                          </th>
                                          <td>rtregf dfgfdgfd</td>
                                          <td>
                                          dfgdfg
                                          </td>
                                          <td>dfgfdgfdg</td>
                                          <td>dfgdfgdfg</td>
                                          <td>
                                            <input
                                              class="customThemeClass"
                                              style="width: 60px; height: 20px"
                                              type="radio"
                                              matTooltip="Click To Select Patient"
                                              target="_blank"
                                            />
                                            <!--
                                            <a *ngIf="fileStatus!=='Approved'" class="eyeCursorclass customThemeClass"
                                              matTooltip="Click To Select Patient" (click)="
                                                  patientId =
                                                    ipatient?.identifiedPatientId
                                                " target="_blank"><i class="fa fa-pencil"></i></a> -->
                                          </td>
                                        </tr>
                                      </ng-container>
                                    </tbody>
                                  </table>
                                </div>
                              </ng-container>
                              <ng-container>
                                <mat-form-field class="col-12" style="display: initial;">
                                    <mat-label> Search Patient <span class="asterisk">*</span></mat-label>
                                    <mat-select
                                      formControlName="drpSearchPatient"
                                      
                                    >
                                      <mat-option>
                                        <ngx-mat-select-search
                                          placeholderLabel="Search"
                                          noEntriesFoundLabel="No Matches found"
                                          formControlName="txtPatientFilter"
                                        ></ngx-mat-select-search>
                                      </mat-option>
                                      <mat-option [value]="">
                                        New Patient
                                      </mat-option>
                                      <mat-option
                                        [value]=""
                                      >
                                        
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                              </ng-container>
                              <div class="form-row">
                                <mat-form-field class="col-12">
                                  <mat-label> First Name </mat-label>
                                  <input
                                    autocomplete="off"
                                    matInput
                                    maxlength="40"
                                    formControlName="txtFirstName"
                                    type="text"
                                  />
                                  <mat-error
                                    *ngIf="
                                    autoExtractionForm?.get('txtFirstName')
                                        ?.touched &&
                                        autoExtractionForm?.get('txtFirstName')
                                        ?.errors?.sAlphabets
                                    "
                                  >
                                    Enter only Alphabets!
                                  </mat-error>
                                  <mat-error
                                    *ngIf="
                                    autoExtractionForm?.get('txtFirstName')
                                        ?.touched &&
                                        autoExtractionForm?.get('txtFirstName')
                                        ?.errors?.required
                                    "
                                  >
                                    First Name is a required field!
                                  </mat-error>
                                </mat-form-field>
                                <mat-form-field class="col-12">
                                  <mat-label> Middle Name </mat-label>
                                  <input
                                    autocomplete="off"
                                    matInput
                                    maxlength="40"
                                    formControlName="txtMiddleName"
                                    type="text"
                                  />
                                  <mat-error
                                    *ngIf="
                                    autoExtractionForm?.get(
                                        'txtMiddleName'
                                      )?.touched &&
                                      autoExtractionForm?.get(
                                        'txtMiddleName'
                                      )?.errors?.sAlphabets
                                    "
                                  >
                                    Enter only Alphabets!
                                  </mat-error>
                                </mat-form-field>
                                <mat-form-field class="col-12">
                                  <mat-label> Last Name </mat-label>
                                  <input
                                    autocomplete="off"
                                    matInput
                                    maxlength="40"
                                    formControlName="txtLastName"
                                    type="text"
                                  />
                                  <mat-error
                                    *ngIf="
                                    autoExtractionForm?.get('txtLastName')
                                        ?.touched &&
                                        autoExtractionForm?.get('txtLastName')
                                        ?.errors?.sAlphabets
                                    "
                                  >
                                    Enter only Alphabets!
                                  </mat-error>
                                  <mat-error
                                    *ngIf="
                                    autoExtractionForm?.get('txtLastName')
                                        ?.touched &&
                                        autoExtractionForm?.get('txtLastName')
                                        ?.errors?.required
                                    "
                                  >
                                    Last Name is a required field!
                                  </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-12">
                                  <mat-label>Gender</mat-label>
                                  <mat-select
                                    formControlName="drpGeneralGender"
                                    [(value)]="gender"
                                    maxlength="40"
                                  >
                                    <mat-option
                                      *ngFor="let test of drpGenderLoop"
                                      [value]="test.genderShortCode"
                                    >
                                      {{ test.genderName }}
                                    </mat-option>
                                  </mat-select>
                                  <button
                                    mat-button
                                    (click)="
                                      gender = ''; $event.stopPropagation()
                                    "
                                    *ngIf="gender"
                                    matSuffix
                                    (click)="clearGender()"
                                    mat-icon-button
                                  >
                                    <mat-icon>close</mat-icon>
                                  </button>
                                  <mat-error
                                    *ngIf="
                                    autoExtractionForm?.get(
                                        'drpGeneralGender'
                                      )?.touched &&
                                      autoExtractionForm?.get(
                                        'drpGeneralGender'
                                      )?.errors?.required
                                    "
                                  >
                                    Gender is a required field!
                                  </mat-error>
                                </mat-form-field>

                                <mat-form-field class="col-12">
                                  <mat-label> Birth Date </mat-label>
                                  <input
                                    autocomplete="off"
                                    formControlName="txtDOB"
                                    matInput
                                    maxlength="40"
                                    type="text"
                                    placeholder="(MM/DD/YYYY)"
                                    [matDatepicker]="txtDOB"
                                  />
                                  <mat-datepicker-toggle
                                    matSuffix
                                    [for]="txtDOB"
                                  >
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #txtDOB> </mat-datepicker>
                                  <mat-error
                                    *ngIf="
                                    autoExtractionForm?.get('txtDOB')
                                        ?.touched &&
                                        autoExtractionForm?.get('txtDOB')
                                        ?.errors
                                    "
                                  >
                                    Enter Valid date
                                  </mat-error>
                                  <mat-error
                                    *ngIf="
                                    autoExtractionForm?.get('txtDOB')
                                        ?.touched &&
                                        autoExtractionForm?.get('txtDOB')
                                        ?.errors?.required
                                    "
                                  >
                                    Birth Date is a required field!
                                  </mat-error>
                                </mat-form-field>
                                <mat-form-field class="mt-4 col-12">
                                  <mat-label> Reason </mat-label>
                                  <input
                                    autocomplete="off"
                                    matInput
                                    maxlength="40"
                                    formControlName="txtReason"
                                    type="text"
                                  />
                                </mat-form-field>
                              </div>
                              <div
                                class="row"
                              >
                                <div class="col-12">
                                  <button
                                    [disabled]="!patientsId"
                                    mat-button
                                    class="float-right resetclr"
                                  >
                                    Disapprove
                                  </button>
                                  <button
                                    [disabled]="!patientsId"
                                    mat-button
                                    class="float-right buttonColor mr-2"
                                  >
                                    Approve
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <i
                            *ngIf="demo2nd"
                            [ngClass]="{ hide: demoHide == true }"
                            class="fa fa-plus customThemeClass"
                            matTooltip="Click To Add Demographics"
                            style="
                              cursor: pointer;
                              margin-left: 362px;
                              font-size: x-large;
                            "
                            (click)="addAccordion()"
                          ></i> -->
                        </div>
                        <!-- <app-patient-search
                          *ngIf="!!patientsId && fileStatus !== 'Approved'"
                          [patientId]="patientId"
                        >
                        </app-patient-search> -->
                      </mat-expansion-panel>

                   <!-- Face to Face-->
                   <mat-expansion-panel id="FACE TO FACE"  [expanded]="false">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="customThemeClass mt-3">
                        <ng-container>
                          <b>Initial face to face </b>

                          <i *ngIf="
                              initialFaceToFacePendingValidationCount === 0
                            " class="fa fa-check-circle-o ml-5"></i>
                          <span *ngIf="
                              initialFaceToFacePendingValidationCount !== 0
                            " [ngClass]="{
                              ' deleteBtnColor ml-5':
                                initialFaceToFacePendingValidationCount !== 0
                            }" matBadge="{{
                              initialFaceToFacePendingValidationCount
                            }}" matBadgeSize="small"></span>
                        </ng-container>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                      <div class="list-group list-group-flush">
                        <div class="list-group-item">
                          <h4 class="card-title">
                            <b>Initial Face To Face</b>
                          </h4>
                          <div class="form-row">
                            <mat-form-field class="col-12">
                              <mat-label> Patient Name </mat-label>
                              <input autocomplete="off" matInput maxlength="40" formControlName="txtFobPatientName" type="text" />
                              <mat-error *ngIf="
                                  autoExtractionForm?.get(
                                    'txtFobPatientName'
                                  )?.touched &&
                                  autoExtractionForm?.get(
                                    'txtFobPatientName'
                                  )?.errors?.sAlphabets
                                ">
                                Enter only Alphabets!
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label> Date of Birth </mat-label>
                              <input autocomplete="off" formControlName="txtIobDob" matInput maxlength="40" type="text"
                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtIobDob" />
                              <mat-datepicker-toggle matSuffix [for]="txtIobDob">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtIobDob> </mat-datepicker>
                              <mat-error *ngIf="
                              autoExtractionForm?.get('txtIobDob')
                                    ?.touched &&
                                    autoExtractionForm?.get('txtIobDob')
                                    ?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label [ngClass]="{
                                  warning:
                                    ruleColor?.erStatus13 ||
                                    ruleColor?.erStatus15 ||
                                    ruleColor?.erStatus29 ||
                                    ruleColor?.erStatus40 ||
                                    ruleColor?.erStatus43
                                }">
                                Face To Face Date
                              </mat-label>
                              <input autocomplete="off" formControlName="txtIobFadeToFace" matInput matTooltip="{{
                                  ruleColor?.errValue13 ||
                                    ruleColor?.errValue15 ||
                                    ruleColor?.errValue29 ||
                                    ruleColor?.errValue40 ||
                                    ruleColor?.errValue43
                                }}" maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                                [matDatepicker]="txtIobFadeToFace" />
                              <mat-datepicker-toggle matSuffix [for]="txtIobFadeToFace">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtIobFadeToFace>
                              </mat-datepicker>
                              <mat-error *ngIf="
                              autoExtractionForm?.get('txtIobFadeToFace')
                                    ?.touched &&
                                    autoExtractionForm?.get('txtIobFadeToFace')
                                    ?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label> Date of Visit </mat-label>
                              <input autocomplete="off" [max]="txtDateofVisit" formControlName="txtDateofVisit" matInput maxlength="40"
                                type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDateofVisit"
                                [min]="txtDateofVisit" />
                              <mat-datepicker-toggle matSuffix [for]="txtDateofVisit">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtDateofVisit> </mat-datepicker>
                              <mat-error *ngIf="
                              autoExtractionForm?.get('txtDateofVisit')
                                    ?.touched &&
                                    autoExtractionForm?.get('txtDateofVisit')
                                    ?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>

                            <div class="col-md-12 text-right">
                              <button type="button" class="buttonColor btn-sm btn-circle">
                                <i class="fa fa-plus" matTooltip="Click to Add Doctor"></i>
                              </button>
                            </div>
                            <mat-form-field class="col-12">
                              <mat-label>Doctor Name</mat-label>
                              <mat-select formControlName="txtDoctor" [(value)]="OrderingDoctor" #singleSelect>
                                <mat-option *ngFor="let doctor of drpDoctor" [value]="doctor.name">
                                  {{ doctor.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label>NPI Number</mat-label>
                              <input autocomplete="off" maxlength="13" mask="000-000-0000" formControlName="txtNpiNumber" matInput
                                type="text" />
                              <mat-error *ngIf="
                                  autoExtractionForm?.get('txtNpiNumber')
                                    ?.touched &&
                                    autoExtractionForm?.get('txtNpiNumber')
                                    ?.errors?.number
                                ">
                                Enter only Numbers!
                              </mat-error>
                            </mat-form-field>
                            <!-- <mat-form-field class="col-6">
                              <mat-label> Signature </mat-label>
                              <input matInput maxlength="40" formControlName="txtSignature" type="text" />
                            </mat-form-field> -->
                            <mat-form-field class="col-12">
                              <mat-label> Signed date </mat-label>
                              <input autocomplete="off" formControlName="txtSignedDate" matInput maxlength="40" type="text"
                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtSignedDate1" />
                              <mat-datepicker-toggle matSuffix [for]="txtSignedDate1">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtSignedDate1> </mat-datepicker>
                              <mat-error *ngIf="
                              autoExtractionForm?.get('txtSignedDate')
                                    ?.touched &&
                                    autoExtractionForm?.get('txtSignedDate')
                                    ?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                            <div class="col-12">
                              <br />
                              <mat-checkbox formControlName="chkPrevioushistory">Previous history – declaration
                              </mat-checkbox>
                            </div>
                            <div class="col-12">
                              <br />
                              <mat-checkbox aria-labelledby="Sleep Test Recommended" [ngClass]="{
                                  warning:
                                    ruleColor?.erStatus2 ||
                                    ruleColor?.erStatus21
                                }" matTooltip="{{
                                  ruleColor?.errValue2 || ruleColor?.errValue21
                                }}" formControlName="chkSleepTestRecommended">Sleep Test Recommended
                              </mat-checkbox>
                            </div>
                            <div class="col-12">
                              <br />
                              <mat-checkbox [ngClass]="{
                                  warning:
                                    ruleColor?.erStatus3 ||
                                    ruleColor?.erStatus17 ||
                                    ruleColor?.erStatus22 ||
                                    ruleColor?.erStatus32
                                }" matTooltip="{{
                                  ruleColor?.errValue3 ||
                                    ruleColor?.errValue17 ||
                                    ruleColor?.errValue22 ||
                                    ruleColor?.errValue32
                                }}" formControlName="chkSleepDoctorSigned">Doctor Signed
                              </mat-checkbox>
                            </div>
                            <div class="col-12">
                              <br />

                              <mat-checkbox matTooltip="{{
                                  ruleColor?.errValue16 || ruleColor?.errValue30
                                }}" [ngClass]="{
                                  warning:
                                    ruleColor?.erStatus16 ||
                                    ruleColor?.erStatus30
                                }" formControlName="chkSleepPatientBenifited">
                                Patient Benifits and Usage
                              </mat-checkbox>
                            </div>

                            <mat-form-field class="col-12">
                              <mat-label [ngClass]="{
                                  warning:
                                    ruleColor?.erStatus1 ||
                                    ruleColor?.erStatus20
                                }">Symptoms
                              </mat-label>
                              <mat-select multiple matTooltip="{{
                                  ruleColor?.errValue1 || ruleColor?.errValue20
                                }}" formControlName="drpSymptoms">
                                <mat-option *ngFor="let sysm of drpSymptoms" [value]="sysm.symptom">
                                  {{ sysm.symptom }}
                                </mat-option>
                              </mat-select>
                              <mat-error *ngIf="
                              autoExtractionForm?.get('drpSymptoms')
                                    ?.touched &&
                                    autoExtractionForm?.get('drpSymptoms')
                                    ?.errors?.required
                                ">
                                Symptoms is a required field!
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <i *ngIf="face2nd" [ngClass]="{ hide: faceHide == true }" class="fa fa-plus ml-8 customThemeClass"
                        matTooltip="Click To Add Face To Face" style="
                          cursor: pointer;
                          margin-left: 362px;
                          font-size: x-large;"></i>
                    </div>
                  </mat-expansion-panel>



                    </mat-accordion>
                  </div>
                </div>
              </ng-container>
            </form>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <div class="col-lg-2"></div>
  </div>
</div>
