import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RxReportSendMailComponent } from '../../rx-report-send-mail/rx-report-send-mail.component';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { BillingListService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/billing-list.service';
import { Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { RXInputDTO } from '../../admin-proxy/platform-app-management/rcm/platform-management/inbound/dto/models';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { RxPreviewDocumentComponent } from 'projects/order/src/app/rx-preview-document/rx-preview-document.component';

@Component({
  selector: 'app-rx-report-table-list',
  templateUrl: './rx-report-table-list.component.html',
  styleUrls: ['./rx-report-table-list.component.scss'],
})
export class RxReportTableListComponent implements OnInit {
  @Input() reportTableData: any;
  rxTableList: any;
  @Input() fileterValue: boolean;
  reportTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Rx Report',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      {
        text: 'Show Default Column',
        action: function (e, dt, node, config) {
          dt.columns(':not(:lt(3),:last-child)').visible(false);
        },
      },
      {
        text: 'Show All Column',
        action: function (e, dt, node, config) {
          dt.columns().visible(true);
        },
      },
    ],
    columnDefs: [
      { targets: 0, orderable: false }, // Targets the first column (index 0)
      {
        targets: [5,7,8],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
      { targets: [0, 1, 2, 3, 7, -1], className: 'dt-fixed-column' }, // Ad
      // Add other columnDefs as needed
    ],
  };
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  yourDate: Date;
  subscription$: Subscription[] = [];
  RxCount: any;
  @Output() updateCount = new EventEmitter();
  @Output() reloadRxReport = new EventEmitter();
  private checkIsRXExistsGetAPICall: Subscription;
  constructor(
    private datepipe: DatePipe,
    private dialog: MatDialog,
    private billingListService: BillingListService,
    private snackBar: MatSnackBar,
    private toaster: ToastrService,
  ) {}

  ngOnInit(): void {
    this.reportTableOptions.buttons[0].filename =
    'Rx Report' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
  }

  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

  
  previewRXDocument(){
    const dialogRef = this.dialog.open(RxPreviewDocumentComponent, {
      height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId

        // documentData: ,
        viewType: false,
        isRxView: true

      },
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });

    if (this.checkIsRXExistsGetAPICall) {
      this.checkIsRXExistsGetAPICall.unsubscribe();
    }
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }
  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.55; // Adjust the percentage as needed
    this.reportTableOptions.scrollY = screenHeight * availableSpacePercentage;
  };

  //To refresh the Datatable
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  // .patientId,data.orderId
  checkIsRXExists(data : any,isOpenindividualSendEMailModel : boolean = false){

    let patientId: string = data.patientId;
    let orderId: string = data.orderId;

    this.checkIsRXExistsGetAPICall = this.billingListService
      .rxFileExistsByPatientIdAndOrderId(patientId,orderId)
      .subscribe(
        (response) => {
          if(response){  
            if(isOpenindividualSendEMailModel){
              this.openUploadModel(data);
            }else{
              this.RxCount = this.reportTableData.filter((dx) => dx.rxcheckBox === true); 
              this.updateCount.emit({ data: this.RxCount });
            }          
            
          }else{
            const index = this.reportTableData.findIndex(
              (obj) => obj.patientId === patientId && obj.orderId === orderId
            );
            if (index !== -1) {
              this.reportTableData[index].rxcheckBox = false; 
              this.reportTableData[index].rxcheckBoxDisabled= true; 
              this.RxCount = this.reportTableData.filter((dx) => dx.rxcheckBox === true);        
            this.updateCount.emit({ data: this.RxCount });
            // this.showNotification('RX Document has Not Found for the Patient of '+ this.reportTableData[index].patientName);
            this.toaster.warning('RX Document has Not Found for the Patient of '+ this.reportTableData[index].patientName, 'Warning');
            }
          }

          
        },
        (err) => {
        }
      );
  }

  matCheckBox(event: any, data: any) {
    this.checkIsRXExists(data)   
  }
  //To Add Notes
  openUploadPopUp(data: any) {
    this.checkIsRXExists(data,true)
  }

  openUploadModel(data: any){
    let inputs: RXInputDTO[]=[];
    // Create input object
    let input: RXInputDTO = {
      gPatientId: data?.patientId || defaultGuid,
      gOrderId: data?.orderId || defaultGuid,
    };
    // Push input into inputs array
    inputs.push(input);
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '20vh',
      minWidth: '55vw',
      data: { input: inputs },
    };
    const dialogRef = this.dialog.open(RxReportSendMailComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      (data: any)  => {
        if (data.length > 0) {
            data.forEach(element => {
              const index = this.reportTableData.findIndex(
                (obj) => obj.orderId === element.orderId && obj.patientId === element.patientId
              );
              if (index !== -1) {
                this.reportTableData[index] = element; 
              }
            });
          }else{
            this.reloadRxReport.emit();
          }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.error(data?.error?.error?.message)
        this.reloadRxReport.emit();
      }
    );
    // this.subscription$.push(closeDialog);
  }
}


