import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {
  callLogTableData,
} from '../admin-dashboard/dashboard-data';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { defaultGuid } from '../document-perviewer/document-perviewer.component';
import { ComlplainceFilter } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/comlplaince-filter.enum';
import { Subscription } from 'rxjs';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-personal.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-compliance-report',
  templateUrl: './compliance-report.component.html',
  styleUrls: ['./compliance-report.component.scss'],
})
export class ComplianceReportComponent implements OnInit {
  complianceForm: FormGroup;
  otherInfoForm: FormGroup;
  tableData$: Subscription;
  tableType: string;
  isLoading: boolean = false;
  // activeTab: string = 'New';

  ComplaincesTypes = ComlplainceFilter;
  activeTab: ComlplainceFilter = this.ComplaincesTypes.New;

  defaultGuid: string = defaultGuid;
  tableTypesArray = [
    'Download',
    'FTF Note',
    'CheckList',
    'Billing',
    'Compliance Met',
    'Non Compliant',
  ];
  tableOptions = {
    responsive: true,
    pageLength: 10,
    lengthMenu: [10, 25, 50, 100, 200],
    scrollCollapse: true,
    scrollY: '30vh',
  };
  dtCallLogOptions = {
    responsive: true,
    pageLength: 5,
    lengthMenu: [5, 10, 25, 50, 100],
    bFilter: false,
    paging: false,
    info: false,
    scrollCollapse: true,
    scrollY: '21vh',
  };
  DataList: any[] = [];
  callLogTableData: any[] = [];
  tblSelectedRow: string = '';
  otherInfo: any;
  constructor(
    private fb: FormBuilder,
    private title: Title,
    private dialog: MatDialog,
    private patientPersonalService: PatientPersonalService,
    private communicationService: CommunicationService,
    private commonService : CommonService

  ) {
    this.communicationService.functionComplainceLsit$.subscribe((activeTab) => {
      this.getComplainceListTableData(activeTab);
    });
  }

  ngOnInit(): void {
   
    this.initializeForms();
    this.tableType = 'Download';
    // this.DataList = complianceTableData;
    // this.callLogTableData = callLogTableData;
    this.complianceForm.patchValue({
      drpTableType: this.tableType,
    });
    this.title.setTitle('Qsecure | My Compliance');
    this.otherInfo = {
      phone: '+17159866545',
      cellPhone: '+19865653232',
      referralPractice: 'Test Doctor',
      orderingPhysician: 'Test Doctor',
    };
    this.otherInfoForm.patchValue({
      txtPhone: this.otherInfo.phone,
      txtCellPhone: this.otherInfo.cellPhone,
      txtReferralPractice: this.otherInfo.referralPractice,
      txtOrderingPhysician: this.otherInfo.orderingPhysician,
    });

    this.getComplainceListTableData(this.ComplaincesTypes.New);
  }
  initializeForms() {
    this.complianceForm = this.fb.group({
      drpTableType: new FormControl(''),
    });
    this.otherInfoForm = this.fb.group({
      txtPhone: new FormControl(''),
      txtCellPhone: new FormControl(''),
      txtReferralPractice: new FormControl(''),
      txtOrderingPhysician: new FormControl(''),
    });
  }
  selectedRow(name: string) {
    this.tblSelectedRow = this.tblSelectedRow === name ? '' : name;
  }
  reloadTable(type: string) {
    this.tableType = type;
  }


  changeTab(tab: number) {
    // Check if the clicked tab is already active
    if (this.activeTab === tab) {
      return;
    }

    this.activeTab = tab;
    if (this.activeTab === this.ComplaincesTypes.New ||
      this.activeTab === this.ComplaincesTypes.Billing ||
      this.activeTab === this.ComplaincesTypes.CheckList ||
      this.activeTab === this.ComplaincesTypes.ComplianceMet ||
      this.activeTab === this.ComplaincesTypes.FTFNote ||
      this.activeTab === this.ComplaincesTypes.NonComplianceMet) {
      this.getComplainceListTableData(
        this.activeTab
      );
    }
  }


  //GET Complaince List Table Data 
  getComplainceListTableData(complainceID: number): void {
    this.tableData$?.unsubscribe();
    this.isLoading = true;
    const getPracticeListTableData = this.patientPersonalService.complainceReportByFilter(complainceID).subscribe(
      (response) => {
        this.isLoading = false;
        this.DataList = response;

        this.DataList = this.DataList.map((complaincedata) => {
          return {
            ...complaincedata,
            orderDate: this.commonService.getFormattedDateTimeZone(
              complaincedata.orderDate
            ),
            createdDate: this.commonService.getFormattedDateTimeZone(
              complaincedata.createdDate
            ),
            modifiedDate: this.commonService.getFormattedDateTimeZone(
              complaincedata.modifiedDate
            ),
          };
        });
      },
      (error) => {
        const data: HttpErrorResponse = error;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isLoading = false;
        this.DataList = [];
      }
    );
    this.tableData$ = getPracticeListTableData;
  }
}
