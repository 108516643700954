import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-exchange-list-table',
  templateUrl: './exchange-list-table.component.html',
  styleUrls: ['./exchange-list-table.component.scss'],
})
export class ExchangeListTableComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  @Input() ltExchangeOrders: any;

  subscription$: Subscription[] = [];
  dtExchangeItemOptions: any = {
    responsive: true,
    dom: 'Bfrtip',
    search: {
      smart: false, // Set to "none" for full exact match
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    paging: false,
    scrollCollapse: true,
    scrollY: '60vh',
    scrollX: true,
    info: true,
    order: [],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Exchange Orders List',
        exportOptions: {
          columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          // columns: ':visible:not(:first-child) ',
          // format: {
          //   header: (data, columnIdx) => {
          //     switch (columnIdx) {
          //       case 1:
          //         return 'Chart Id';
          //       case 2:
          //         return 'Patient Name';
          //       case 3:
          //         return 'Exchange Date';
          //       case 4:
          //         return 'Original Order';
          //       case 5:
          //         return 'Original Order';
          //       default:
          //         return data;
          //     }
          //   },
         // },
        },
      },
    ],
  };
  ngOnInit(): void {
    this.dtExchangeItemOptions.buttons[0].filename =
      'Exchange Orders List' +
      '-' +
      this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  constructor(
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
    private loaderService: LoaderService
  ) {}
  //After View Method
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  getExchangeItemsList(
    originalOrderId: string,
    newOrderId: string,
    originalOrderNo: string,
    newOrderNo: string
  ) {
    let orderIds = {
      originalOrderId: originalOrderId,
      originalOrderNo: originalOrderNo,
      newOrderId: newOrderId,
      newOrderNo: newOrderNo,
    };
    this.communicationService.triggerExchangeItemsList(orderIds);
  }
  //US Format Date and Time
  getFormattedDateTimeZone(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: 'America/Chicago',
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }
}
