import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { TableService } from 'projects/shared/src/app/table.service';
import Swal from 'sweetalert2';

import { PermissionService } from '../admin-proxy/platform-app-management/rcm/platform-management/permission/permission.service';
import { UpdatePermissionsDto } from '../admin-proxy/volo/abp/permission-management/models';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})

export class PermissionComponent implements OnInit {
  permissionForm: FormGroup;
  providerName: string = "";
  providerKey: string = "";
  adminHide: boolean = true;
  check: any = { checked: true };
  constructor(public dialogRef: MatDialogRef<PermissionComponent>,
    public tableService: TableService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public permission: PermissionService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { roleName?: string; clientId?: string },) { }
  show: boolean = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  ngOnInit(): void {


    this.permissionForm = this.formBuilder.group({

      chkAllManagement: new FormControl(""),

      chkPatientManagement: new FormControl(""),
      chkPatientCreate: new FormControl(""),
      chkPatientEdit: new FormControl(""),
      chkPatientView: new FormControl(""),
      chkPatientDelete: new FormControl(""),
      chkDocumentDelete: new FormControl(""),

      chkPlanManagement: new FormControl(""),
      chkPlanCreate: new FormControl(""),
      chkPlanEdit: new FormControl(""),
      chkPlanView: new FormControl(""),
      chkPlanDelete: new FormControl(""),

      chkItemManagement: new FormControl(""),
      chkItemCreate: new FormControl(""),
      chkItemEdit: new FormControl(""),
      chkItemView: new FormControl(""),
      chkItemDelete: new FormControl(""),

      chkInsuranceManagement: new FormControl(""),
      chkInsuranceCreate: new FormControl(""),
      chkInsuranceEdit: new FormControl(""),
      chkInsuranceView: new FormControl(""),
      chkInsuranceDelete: new FormControl(""),

      chkNotesManagement: new FormControl(""),
      chkNotesCreate: new FormControl(""),
      chkNotesEdit: new FormControl(""),
      chkNotesView: new FormControl(""),
      chkNotesDelete: new FormControl(""),

      chkOrderManagement: new FormControl(""),
      chkOrderCreate: new FormControl(""),
      chkOrderEdit: new FormControl(""),
      chkOrderView: new FormControl(""),
      chkOrderDelete: new FormControl(""),

      chkFileUploadManagement: new FormControl(""),
      chkFileUploadCreate: new FormControl(""),
      chkFileUploadEdit: new FormControl(""),
      chkFileUploadView: new FormControl(""),
      chkFileUploadDelete: new FormControl(""),

      // chkRoleCreate: new FormControl(""),
      // chkRoleEdit: new FormControl(""),
      // chkRoleView: new FormControl(""),
      // chkRoleDelete: new FormControl(""),
      // chkRoleManagePermissions: new FormControl(""),

      chkUsersManagement: new FormControl(""),
      chkUsersCreate: new FormControl(""),
      chkUsersEdit: new FormControl(""),
      chkUsersView: new FormControl(""),
      chkUsersDelete: new FormControl(""),
      chkUsersManagePermissions: new FormControl(""),

      // chkTenantCreate: new FormControl(""),
      // chkTenantEdit: new FormControl(""),
      // chkTenantView: new FormControl(""),
      // chkTenantDelete: new FormControl(""),
      // chkTenantManageFeatures: new FormControl(""),

      // chkOrganizationCreate: new FormControl(""),
      // chkOrganizationEdit: new FormControl(""),
      // chkOrganizationView: new FormControl(""),
      // chkOrganizationDelete: new FormControl(""),

      chkRolesManagement: new FormControl(""),
      chkRolesCreate: new FormControl(""),
      chkRolesEdit: new FormControl(""),
      chkRolesView: new FormControl(""),
      chkRolesDelete: new FormControl(""),
      chkRolesManagePermissions: new FormControl(""),

      // newly added for permission
      chkPatientIntake: new FormControl(""),
      chkInsVerification: new FormControl(""),
      chkAuthorization: new FormControl(""),
      chkShipping: new FormControl(""),
      chkWarehouse: new FormControl("")
    });
    this.loadPermissions();
  }

  changePatient(event: MatCheckboxChange) {
    // patient
    if (this.permissionForm.value.chkPatientIntake === true && this.permissionForm.value.chkAuthorization === true && this.permissionForm.value.chkDocumentDelete === true
      && this.permissionForm.value.chkInsVerification === true && this.permissionForm.value.chkPatientDelete === true) {

      this.permissionForm.patchValue({

        chkPatientManagement: event.checked === true ? true : false,
        // chkAllManagement: event.checked === true ? true : false,

      });
    } else {

      this.permissionForm.patchValue({
        chkPatientManagement: event.checked === false ? false : false,
        // chkAllManagement: event.checked === false ? false : false,
      });
    }

    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }
  }

  changeOrder(event: MatCheckboxChange) {

    // order

    if (this.permissionForm.value.chkOrderCreate === true && this.permissionForm.value.chkOrderDelete === true && this.permissionForm.value.chkOrderEdit === true
      && this.permissionForm.value.chkOrderView === true && this.permissionForm.value.chkShipping === true && this.permissionForm.value.chkWarehouse === true) {

      this.permissionForm.patchValue({
        chkOrderManagement: event.checked === true ? true : false,
        // chkAllManagement: event.checked === true ? true : false,
      });
    } else {

      this.permissionForm.patchValue({
        chkOrderManagement: event.checked === false ? false : false,
        // chkAllManagement: event.checked === false ? false : false,
      });
    }
    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }

  }


  changeItem(event: MatCheckboxChange) {

    //item


    if (this.permissionForm.value.chkItemCreate === true && this.permissionForm.value.chkItemDelete === true && this.permissionForm.value.chkItemEdit === true
      && this.permissionForm.value.chkItemView === true) {

      this.permissionForm.patchValue({
        chkItemManagement: event.checked === true ? true : false,
        // chkAllManagement: event.checked === true ? true : false,
      });
    } else {

      this.permissionForm.patchValue({
        chkItemManagement: event.checked === false ? false : false,
        // chkAllManagement: event.checked === false ? false : false,
      });
    }
    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }

  }
  changeNotes(event: MatCheckboxChange) {

    //notes

    if (this.permissionForm.value.chkNotesCreate === true && this.permissionForm.value.chkNotesDelete === true && this.permissionForm.value.chkNotesEdit === true
      && this.permissionForm.value.chkNotesView === true) {

      this.permissionForm.patchValue({
        chkNotesManagement: event.checked === true ? true : false,
        //   chkAllManagement: event.checked === true ? true : false,
      });
    } else {

      this.permissionForm.patchValue({
        chkNotesManagement: event.checked === false ? false : false,
        //  chkAllManagement: event.checked === false ? false : false,
      });
    }

    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }

  }


  changeFileUpload(event: MatCheckboxChange) {


    // fileupload
    if (this.permissionForm.value.chkFileUploadCreate === true && this.permissionForm.value.chkFileUploadDelete === true && this.permissionForm.value.chkFileUploadEdit === true
      && this.permissionForm.value.chkFileUploadView === true) {

      this.permissionForm.patchValue({
        chkFileUploadManagement: event.checked === true ? true : false,
        //  chkAllManagement: event.checked === true ? true : false,
      });
    } else {

      this.permissionForm.patchValue({
        chkFileUploadManagement: event.checked === false ? false : false,
        // chkAllManagement: event.checked === false ? false : false,
      });
    }
    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }
  }



  changeUsers(event: MatCheckboxChange) {

    //user
    if (this.permissionForm.value.chkUsersCreate === true && this.permissionForm.value.chkUsersDelete === true && this.permissionForm.value.chkUsersEdit === true
      && this.permissionForm.value.chkUsersView === true) {

      this.permissionForm.patchValue({
        chkUsersManagement: event.checked === true ? true : false,
        //  chkAllManagement: event.checked === true ? true : false,
      });
    } else {

      this.permissionForm.patchValue({
        chkUsersManagement: event.checked === false ? false : false,
        //  chkAllManagement: event.checked === false ? false : false,
      });
    }
    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }
  }


  changeRoles(event: MatCheckboxChange) {


    // roles


    if (this.permissionForm.value.chkRolesView === true && this.permissionForm.value.chkRolesCreate === true && this.permissionForm.value.chkRolesEdit === true
      && this.permissionForm.value.chkRolesDelete === true) {


      this.permissionForm.patchValue({
        chkRolesManagement: event.checked === true ? true : false,
        //chkAllManagement: event.checked === true ? true : false,
      });
    } else {

      this.permissionForm.patchValue({
        chkRolesManagement: event.checked === false ? false : false,
        //chkAllManagement: event.checked === false ? false : false,
      });
    }
    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }

  }

  click() {
    this.show = true
  }
  isVisible = true;

  toggle(): void {
    this.isVisible = !this.isVisible;
  }

  //! select all click
  allSelected = false;
  selectAll(event: MatCheckboxChange) {

    this.permissionForm.patchValue({

      chkPatientManagement: event.checked == true ? true : false,
      chkPlanManagement: event.checked == true ? true : false,
      chkItemManagement: event.checked == true ? true : false,
      chkInsuranceManagement: event.checked == true ? true : false,
      chkNotesManagement: event.checked == true ? true : false,
      chkOrderManagement: event.checked == true ? true : false,
      chkFileUploadManagement: event.checked == true ? true : false,
      chkUsersManagement: event.checked == true ? true : false,
      chkRolesManagement: event.checked == true ? true : false,

      chkPatientCreate: event.checked == true ? true : false,
      chkPatientEdit: event.checked == true ? true : false,
      chkPatientView: event.checked == true ? true : false,
      chkPatientDelete: event.checked == true ? true : false,
      chkDocumentDelete: event.checked == true ? true : false,
      chkPlanCreate: event.checked == true ? true : false,
      chkPlanEdit: event.checked == true ? true : false,
      chkPlanView: event.checked == true ? true : false,
      chkPlanDelete: event.checked == true ? true : false,
      /// new record
      chkPatientIntake: event.checked == true ? true : false,
      chkInsVerification: event.checked == true ? true : false,
      chkAuthorization: event.checked == true ? true : false,


      chkRoleCreate: event.checked == true ? true : false,
      chkRoleEdit: event.checked == true ? true : false,
      chkRoleView: event.checked == true ? true : false,
      chkRoleDelete: event.checked == true ? true : false,
      chkRoleManagePermissions: event.checked == true ? true : false,

      chkUsersCreate: event.checked == true ? true : false,
      chkUsersEdit: event.checked == true ? true : false,
      chkUsersView: event.checked == true ? true : false,
      chkUsersDelete: event.checked == true ? true : false,
      chkUsersManagePermissions: event.checked == true ? true : false,

      chkTenantCreate: event.checked == true ? true : false,
      chkTenantEdit: event.checked == true ? true : false,
      chkTenantView: event.checked == true ? true : false,
      chkTenantDelete: event.checked == true ? true : false,
      chkTenantManageFeatures: event.checked == true ? true : false,

      chkOrganizationCreate: event.checked == true ? true : false,
      chkOrganizationEdit: event.checked == true ? true : false,
      chkOrganizationDelete: event.checked == true ? true : false,
      chkOrganizationView: event.checked == true ? true : false,

      chkItemCreate: event.checked == true ? true : false,
      chkItemEdit: event.checked == true ? true : false,
      chkItemView: event.checked == true ? true : false,
      chkItemDelete: event.checked == true ? true : false,

      chkInsuranceCreate: event.checked == true ? true : false,
      chkInsuranceEdit: event.checked == true ? true : false,
      chkInsuranceView: event.checked == true ? true : false,
      chkInsuranceDelete: event.checked == true ? true : false,

      chkNotesCreate: event.checked == true ? true : false,
      chkNotesEdit: event.checked == true ? true : false,
      chkNotesView: event.checked == true ? true : false,
      chkNotesDelete: event.checked == true ? true : false,

      chkOrderCreate: event.checked == true ? true : false,
      chkOrderEdit: event.checked == true ? true : false,
      chkOrderView: event.checked == true ? true : false,
      chkOrderDelete: event.checked == true ? true : false,

      chkRolesCreate: event.checked == true ? true : false,
      chkRolesEdit: event.checked == true ? true : false,
      chkRolesView: event.checked == true ? true : false,
      chkRolesDelete: event.checked == true ? true : false,
      // chkRolesManagePermissions: event.checked == true ? true : false,

      chkFileUploadCreate: event.checked == true ? true : false,
      chkFileUploadEdit: event.checked == true ? true : false,
      chkFileUploadView: event.checked == true ? true : false,
      chkFileUploadDelete: event.checked == true ? true : false,

      chkShipping: event.checked == true ? true : false,
      chkWarehouse: event.checked == true ? true : false,
    });
  }
  //! select patient click
  selectAllPatient(event: MatCheckboxChange) {

    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }

    this.permissionForm.patchValue({

      chkPatientCreate: event.checked === true ? true : false,
      chkPatientEdit: event.checked === true ? true : false,
      chkPatientView: event.checked === true ? true : false,
      chkPatientDelete: event.checked === true ? true : false,
      chkDocumentDelete: event.checked === true ? true : false,
      chkPlanCreate: event.checked === true ? true : false,
      chkPlanEdit: event.checked === true ? true : false,
      chkPlanView: event.checked === true ? true : false,
      chkPlanDelete: event.checked === true ? true : false,
      /// new record
      chkPatientIntake: event.checked == true ? true : false,
      chkInsVerification: event.checked == true ? true : false,
      chkAuthorization: event.checked == true ? true : false,
    });
  }
  //! select order click
  selectAllOrder(event: MatCheckboxChange) {

    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true
      && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }


    this.permissionForm.patchValue({

      chkOrderCreate: event.checked === true ? true : false,
      chkOrderEdit: event.checked === true ? true : false,
      chkOrderView: event.checked === true ? true : false,
      chkOrderDelete: event.checked === true ? true : false,
      chkShipping: event.checked == true ? true : false,
      chkWarehouse: event.checked == true ? true : false,
    });
  }
  //! select plan click
  selectAllPlan(event: MatCheckboxChange) {

    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true
      && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }


    this.permissionForm.patchValue({

      chkPlanCreate: event.checked === true ? true : false,
      chkPlanEdit: event.checked === true ? true : false,
      chkPlanView: event.checked === true ? true : false,
      chkPlanDelete: event.checked === true ? true : false,
    });
  }
  //! select item click
  selectAllItem(event: MatCheckboxChange) {

    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true
      && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }



    this.permissionForm.patchValue({

      chkItemCreate: event.checked === true ? true : false,
      chkItemEdit: event.checked === true ? true : false,
      chkItemView: event.checked === true ? true : false,
      chkItemDelete: event.checked === true ? true : false,
    });
  }

  selectAllInsurance(event: MatCheckboxChange) {

    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true
      && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }



    this.permissionForm.patchValue({

      chkInsuranceCreate: event.checked === true ? true : false,
      chkInsuranceEdit: event.checked === true ? true : false,
      chkInsuranceView: event.checked === true ? true : false,
      chkInsuranceDelete: event.checked === true ? true : false,
    });
  }
  //! select notes click
  selectAllNotes(event: MatCheckboxChange) {


    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true
      && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }


    this.permissionForm.patchValue({

      chkNotesCreate: event.checked === true ? true : false,
      chkNotesEdit: event.checked === true ? true : false,
      chkNotesView: event.checked === true ? true : false,
      chkNotesDelete: event.checked === true ? true : false,
    });
  }
  selectAllFileUpload(event: MatCheckboxChange) {


    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true
      && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }

    this.permissionForm.patchValue({

      chkFileUploadCreate: event.checked === true ? true : false,
      chkFileUploadEdit: event.checked === true ? true : false,
      chkFileUploadView: event.checked === true ? true : false,
      chkFileUploadDelete: event.checked === true ? true : false,
    });
  }

  selectAllUser(event: MatCheckboxChange) {


    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true
      && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }

    this.permissionForm.patchValue({

      chkUsersCreate: event.checked == true ? true : false,
      chkUsersEdit: event.checked == true ? true : false,
      chkUsersView: event.checked == true ? true : false,
      chkUsersDelete: event.checked == true ? true : false,
    });
  }

  selectAllRole(event: MatCheckboxChange) {


    if (this.permissionForm.value.chkPatientManagement === true && this.permissionForm.value.chkItemManagement === true
      && this.permissionForm.value.chkNotesManagement === true && this.permissionForm.value.chkOrderManagement === true &&
      this.permissionForm.value.chkFileUploadManagement === true && this.permissionForm.value.chkUsersManagement === true && this.permissionForm.value.chkRolesManagement === true) {

      this.permissionForm.patchValue({
        chkAllManagement: event.checked === true ? true : false,

      });
    }
    else {

      this.permissionForm.patchValue({

        chkAllManagement: event.checked === false ? false : false,
      });
    }



    this.permissionForm.patchValue({
      chkRolesCreate: event.checked == true ? true : false,
      chkRolesEdit: event.checked == true ? true : false,
      chkRolesView: event.checked == true ? true : false,
      chkRolesDelete: event.checked == true ? true : false,
      // chkRolesManagePermissions: event.checked == true ? true : false,
    });
  }


  permissionDetails: UpdatePermissionsDto;



  savePermission() {
    this.providerName = "R",
      this.providerKey = this.data?.roleName ?? ""
    // tenantId:this.data.clientId
    //!patient
    if (this.data?.clientId != null && this.data?.roleName != null) {
      if (this.permissionForm.value.chkPatientCreate === true || this.permissionForm.value.chkPatientEdit === true
        || this.permissionForm.value.chkPatientDelete === true || this.permissionForm.value.chkPatientView === true
        || this.permissionForm.value.chkDocumentDelete === true || this.permissionForm.value.chkPlanCreate === true
        || this.permissionForm.value.chkPlanEdit === true || this.permissionForm.value.chkPlanView === true
        || this.permissionForm.value.chkPlanDelete === true) {
        this.permissionForm.value.chkPatientView = true;
        this.permissionForm.value.chkPlanView = true;
      }
      else {
        this.permissionForm.value.chkPatientView = true;
        this.permissionForm.value.chkPlanView = false;
      }

      if (this.permissionForm.value.chkPatientCreate == true || this.permissionForm.value.chkPatientEdit == true
        || this.permissionForm.value.chkPatientDelete == true || this.permissionForm.value.chkPatientView == true
        || this.permissionForm.value.chkDocumentDelete == true) {
        // this.permissionForm.value.chkUsersCreate=true;
        // this.permissionForm.value.chkUsersEdit = true;
        this.permissionForm.value.chkOrganizationView = true;
        // this.permissionForm.value.chkUsersDelete = true;
      }

      //!order
      if (this.permissionForm.value.chkOrderCreate === true || this.permissionForm.value.chkOrderEdit === true
        || this.permissionForm.value.chkOrderDelete === true || this.permissionForm.value.chkOrderView === true) {
        this.permissionForm.value.chkOrderView = true;
        this.permissionForm.value.chkItemView = true;
      }
      else {
        this.permissionForm.value.chkOrderView = false;
      }
      //item
      if (this.permissionForm.value.chkItemCreate === true || this.permissionForm.value.chkItemEdit === true
        || this.permissionForm.value.chkItemDelete === true || this.permissionForm.value.chkItemView === true) {
        this.permissionForm.value.chkItemView = true;
      }
      else {
        this.permissionForm.value.chkItemView = false;
      }
      //!insurance
      if (this.permissionForm.value.chkInsuranceCreate === true || this.permissionForm.value.chkInsuranceEdit === true
        || this.permissionForm.value.chkInsuranceDelete === true || this.permissionForm.value.chkInsuranceView === true) {
        this.permissionForm.value.chkInsuranceView = true;
      }
      else {
        this.permissionForm.value.chkInsuranceView = false;
      }
      //!notes
      if (this.permissionForm.value.chkNotesCreate === true || this.permissionForm.value.chkNotesEdit === true
        || this.permissionForm.value.chkNotesDelete === true || this.permissionForm.value.chkNotesView === true) {
        this.permissionForm.value.chkNotesView = true;
      }
      else {
        this.permissionForm.value.chkNotesView = false;
      }

      if (this.permissionForm.value.chkFileUploadCreate === true || this.permissionForm.value.chkFileUploadEdit === true
        || this.permissionForm.value.chkFileUploadView === true || this.permissionForm.value.chkFileUploadDelete === true) {
        this.permissionForm.value.chkFileUploadView = true;
      }
      else {
        this.permissionForm.value.chkFileUploadView = false;
      }

      //! users
      if (this.permissionForm.value.chkUsersCreate == true || this.permissionForm.value.chkUsersEdit == true
        || this.permissionForm.value.chkUsersDelete == true || this.permissionForm.value.chkUsersView == true) {
        this.permissionForm.value.chkUsersView = true;
      }
      else if (this.permissionForm.value.chkUsersCreate == false && this.permissionForm.value.chkUsersEdit == false
        && this.permissionForm.value.chkUsersDelete == false) {
        this.permissionForm.value.chkUsersView = true;
      }
      else {
        this.permissionForm.value.chkUsersView = false;
      }
      // Role
      if (this.permissionForm.value.chkRolesCreate == true || this.permissionForm.value.chkRolesEdit == true
        || this.permissionForm.value.chkRolesDelete == true || this.permissionForm.value.chkRolesView == true) {
        this.permissionForm.value.chkRolesView = true;
      }
      else if (this.permissionForm.value.chkUsersView == true) {
        this.permissionForm.value.chkRolesView = true;
      }
      else {
        this.permissionForm.value.chkRolesView = false;
      }


      this.permissionDetails = {
        permissions: [

          // Permission access for Patients

          {
            name: "AbpIdentity.Roles.ManagePermissions",
            isGranted: true,
          },



          //! new reocord

          {
            name: "PatientManagement.PatientIntake.Create",
            isGranted: this.permissionForm.value.chkPatientIntake === "" ? false : this.permissionForm.value.chkPatientIntake
          },
          {
            name: "PatientManagement.InsuranceVerification.Create",
            isGranted: this.permissionForm.value.chkInsVerification === "" ? false : this.permissionForm.value.chkInsVerification
          },
          {
            name: "PatientManagement.Authorization.Create",
            isGranted: this.permissionForm.value.chkAuthorization === "" ? false : this.permissionForm.value.chkAuthorization
          },




          {
            name: "PatientManagement.Patients.Create",
            isGranted: this.permissionForm.value.chkPatientCreate === "" ? false : this.permissionForm.value.chkPatientCreate
          },
          {
            name: "PatientManagement.Patients.Edit",
            isGranted: this.permissionForm.value.chkPatientEdit === "" ? false : this.permissionForm.value.chkPatientEdit
          },
          {
            name: "PatientManagement.Patients",
            isGranted: this.permissionForm.value.chkPatientView
          },
          {
            name: "PatientManagement.Patients.Delete",
            isGranted: this.permissionForm.value.chkPatientDelete === "" ? false : this.permissionForm.value.chkPatientDelete
          },
          {
            name: "PatientManagement.Document.Delete",
            isGranted: this.permissionForm.value.chkDocumentDelete === "" ? false : this.permissionForm.value.chkDocumentDelete
          },
          {
            name: "PatientManagement.Plans.Create",
            isGranted: this.permissionForm.value.chkPlanCreate === "" ? false : this.permissionForm.value.chkPlanCreate
          },
          {
            name: "PatientManagement.Plans.Edit",
            isGranted: this.permissionForm.value.chkPlanEdit === "" ? false : this.permissionForm.value.chkPlanEdit
          },
          {
            name: "PatientManagement.Plans",
            isGranted: this.permissionForm.value.chkPlanView
          },
          {
            name: "PatientManagement.Plans.Delete",
            isGranted: this.permissionForm.value.chkPlanDelete === "" ? false : this.permissionForm.value.chkPlanDelete
          },

          // Permission access for Orders

          {
            name: "OrderManagement.Orders.Create",
            isGranted: this.permissionForm.value.chkOrderCreate === "" ? false : this.permissionForm.value.chkOrderCreate
          },
          {
            name: "OrderManagement.Orders.Edit",
            isGranted: this.permissionForm.value.chkOrderEdit === "" ? false : this.permissionForm.value.chkOrderEdit
          },
          {
            name: "OrderManagement.Orders",
            isGranted: this.permissionForm.value.chkOrderView
          },
          {
            name: "OrderManagement.Orders.Delete",
            isGranted: this.permissionForm.value.chkOrderDelete === "" ? false : this.permissionForm.value.chkOrderDelete
          },
          //! Newly added fields
          {
            name: "OrderManagement.Shipping",
            isGranted: this.permissionForm.value.chkShipping === "" ? false : this.permissionForm.value.chkShipping
          },
          {
            name: "OrderManagement.Warehouse",
            isGranted: this.permissionForm.value.chkWarehouse === "" ? false : this.permissionForm.value.chkWarehouse
          },
          //!for order tenent id get
          {
            name: "AbpIdentity.Users",
            isGranted: true,
          },


          {
            name: "PlatformManagement.Organizations.Create",
            isGranted: this.permissionForm.value.chkOrganizationCreate == "" ? false : this.permissionForm.value.chkOrganizationCreate,
          },
          {
            name: "PlatformManagement.Organizations.Edit",
            isGranted: this.permissionForm.value.chkOrganizationEdit == "" ? false : this.permissionForm.value.chkOrganizationEdit,
          },

          {
            name: "PlatformManagement.Organizations.Delete",
            isGranted: this.permissionForm.value.chkOrganizationDelete == "" ? false : this.permissionForm.value.chkOrganizationDelete,
          },
          {
            name: "PlatformManagement.Organizations",
            isGranted: this.permissionForm.value.chkOrganizationView == "" ? false : this.permissionForm.value.chkOrganizationView,
          },




          // Permission access for Items

          {
            name: "OrderManagement.Items.Create",
            isGranted: this.permissionForm.value.chkItemCreate === "" ? false : this.permissionForm.value.chkItemCreate
          },
          {
            name: "OrderManagement.Items.Edit",
            isGranted: this.permissionForm.value.chkItemEdit === "" ? false : this.permissionForm.value.chkItemEdit
          },
          {
            name: "OrderManagement.Items",
            isGranted: this.permissionForm.value.chkItemView
          },
          {
            name: "OrderManagement.Items.Delete",
            isGranted: this.permissionForm.value.chkItemDelete === "" ? false : this.permissionForm.value.chkItemDelete
          },

          // Permission access for Insurances

          {
            name: "OrderManagement.Insurances.Create",
            isGranted: this.permissionForm.value.chkInsuranceCreate === "" ? false : this.permissionForm.value.chkInsuranceCreate
          },
          {
            name: "OrderManagement.Insurances.Edit",
            isGranted: this.permissionForm.value.chkInsuranceEdit === "" ? false : this.permissionForm.value.chkInsuranceEdit
          },
          {
            name: "OrderManagement.Insurances",
            isGranted: this.permissionForm.value.chkInsuranceView
          },
          {
            name: "OrderManagement.Insurances.Delete",
            isGranted: this.permissionForm.value.chkInsuranceDelete === "" ? false : this.permissionForm.value.chkInsuranceDelete
          },

          // Permission access for Notes

          {
            name: "OrderManagement.Notes.Create",
            isGranted: this.permissionForm.value.chkNotesCreate === "" ? false : this.permissionForm.value.chkNotesCreate
          },
          {
            name: "OrderManagement.Notes.Edit",
            isGranted: this.permissionForm.value.chkNotesEdit === "" ? false : this.permissionForm.value.chkNotesEdit
          },
          {
            name: "OrderManagement.Notes",
            isGranted: this.permissionForm.value.chkNotesView
          },
          {
            name: "OrderManagement.Notes.Delete",
            isGranted: this.permissionForm.value.chkNotesDelete === "" ? false : this.permissionForm.value.chkNotesDelete
          },


          {
            name: "PlatformManagement.FileUploads.Create",
            isGranted: this.permissionForm.value.chkFileUploadCreate === "" ? false : this.permissionForm.value.chkFileUploadCreate
          },
          {
            name: "PlatformManagement.FileUploads.Edit",
            isGranted: this.permissionForm.value.chkFileUploadEdit === "" ? false : this.permissionForm.value.chkFileUploadEdit
          },
          {
            name: "PlatformManagement.FileUploads",
            isGranted: this.permissionForm.value.chkFileUploadView
          },
          {
            name: "PlatformManagement.FileUploads.Delete",
            isGranted: this.permissionForm.value.chkFileUploadDelete === "" ? false : this.permissionForm.value.chkFileUploadDelete
          },

          // Permission for User
          {
            name: "AbpIdentity.Users.Create",
            isGranted: this.permissionForm.value.chkUsersCreate == "" ? false : this.permissionForm.value.chkUsersCreate
          },
          {
            name: "AbpIdentity.Users.Update",
            isGranted: this.permissionForm.value.chkUsersEdit == "" ? false : this.permissionForm.value.chkUsersEdit
          },
          {
            name: "AbpIdentity.Users",
            isGranted: this.permissionForm.value.chkUsersView == "" ? false : this.permissionForm.value.chkUsersView
          },
          {
            name: "AbpIdentity.Users.Delete",
            isGranted: this.permissionForm.value.chkUsersDelete == "" ? false : this.permissionForm.value.chkUsersDelete
          },
          // Permission for Role
          {
            name: "AbpIdentity.Roles.Create",
            isGranted: this.permissionForm.value.chkRolesCreate == "" ? false : this.permissionForm.value.chkRolesCreate
          },
          {
            name: "AbpIdentity.Roles.Update",
            isGranted: this.permissionForm.value.chkRolesEdit == "" ? false : this.permissionForm.value.chkRolesEdit
          },
          {
            name: "AbpIdentity.Roles",
            isGranted: this.permissionForm.value.chkRolesView == "" ? false : this.permissionForm.value.chkRolesView
          },
          {
            name: "AbpIdentity.Roles.Delete",
            isGranted: this.permissionForm.value.chkRolesDelete == "" ? false : this.permissionForm.value.chkRolesDelete
          },
          {
            name: "AbpIdentity.Roles.ManagePermissions",
            isGranted: this.permissionForm.value.chkRoleManagePermissions == "" ? false : this.permissionForm.value.chkRoleManagePermissions
          },
          {
            name: "OrderManagement.Warehouse",
            isGranted: true
          },
          // {
          //   name: "OrderManagement.Warehouse.Delete",
          //   isGranted: true
          // },
          // {
          //   name: "OrderManagement.Warehouse.Create",
          //   isGranted: true
          // },
          // {
          //   name: "OrderManagement.Warehouse.Update",
          //   isGranted: true
          // },
          // {
          //   name: "OrderManagement.Warehouse.Edit",
          //   isGranted: true
          // },

        ]
      }
    }


  const permissonValue =   this.permission.updatePermissionServiceByTenantIdAndProviderNameAndProviderKeyAndInput(this.data?.clientId ?? "", this.providerName ?? "", this.providerKey ?? "", this.permissionDetails)
   .subscribe(response => {

   })
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      if(permissonValue){
        this.toastr.success('Saved Successfully','Success')
        // this.dialogRef.close();
        this.dialog.closeAll()
      }

   err => {
    this.toastr.info(err)
    };
  }
  //! view role permission
  loadPermissions() {
    this.providerName = "R";
    this.providerKey = this.data?.roleName ?? "";
    this.permission.getPermissionServiceByProviderNameAndProviderKeyAndTenantId(this.providerName ?? "", this.providerKey ?? "", this.data?.clientId ?? "").subscribe(response => {

      response && response?.groups?.forEach(element => {
        element?.permissions?.forEach(elements => {

          {
            //! newly added records
            if (elements.name === "PatientManagement.PatientIntake.Create") {
              this.permissionForm.patchValue({
                chkPatientIntake: elements?.isGranted
              });
            }
            if (elements.name === "PatientManagement.InsuranceVerification.Create") {
              this.permissionForm.patchValue({
                chkInsVerification: elements?.isGranted
              });
            }
            if (elements.name === "PatientManagement.Authorization.Create") {
              this.permissionForm.patchValue({
                chkAuthorization: elements?.isGranted
              });
            }

            //Patient Management
            if (elements.name === "PatientManagement.Patients") {
              this.permissionForm.patchValue({
                chkPatientView: elements?.isGranted
              });
            }
            if (elements.name === "PatientManagement.Patients.Create") {
              this.permissionForm.patchValue({
                chkPatientCreate: elements?.isGranted
              });
            }
            if (elements.name === "PatientManagement.Patients.Edit") {
              this.permissionForm.patchValue({
                chkPatientEdit: elements?.isGranted
              });
            }
            if (elements.name === "PatientManagement.Patients.Delete") {
              this.permissionForm.patchValue({
                chkPatientDelete: elements?.isGranted
              });
            }
            if (elements.name === "PatientManagement.Document.Delete") {
              this.permissionForm.patchValue({
                chkDocumentDelete: elements?.isGranted
              });
            }
            if (elements.name === "PatientManagement.Plans.Create") {
              this.permissionForm.patchValue({
                chkPlanCreate: elements?.isGranted
              });
            }
            if (elements.name === "PatientManagement.Plans.Edit") {
              this.permissionForm.patchValue({
                chkPlanEdit: elements?.isGranted
              });
            }
            if (elements.name === "PatientManagement.Plans") {
              this.permissionForm.patchValue({
                chkPlanView: elements?.isGranted

              });
            }
            if (elements.name === "PatientManagement.Plans.Delete") {
              this.permissionForm.patchValue({
                chkPlanDelete: elements?.isGranted
              });
            }


            //Order Management
            if (elements.name === "OrderManagement.Orders") {
              this.permissionForm.patchValue({
                chkOrderView: elements?.isGranted
              });
            }
            if (elements.name === "OrderManagement.Orders.Create") {
              this.permissionForm.patchValue({
                chkOrderCreate: elements?.isGranted
              });
            }
            if (elements.name === "OrderManagement.Orders.Edit") {
              this.permissionForm.patchValue({
                chkOrderEdit: elements?.isGranted
              });
            }
            if (elements.name === "OrderManagement.Orders.Delete") {
              this.permissionForm.patchValue({
                chkOrderDelete: elements?.isGranted
              });
            }

            if (elements.name === "OrderManagement.Shipping") {
              this.permissionForm.patchValue({
                chkShipping: elements?.isGranted
              });
            }
            if (elements.name === "OrderManagement.Warehouse") {
              this.permissionForm.patchValue({
                chkWarehouse: elements?.isGranted
              });
            }

            //Item
            if (elements.name === "OrderManagement.Items") {
              this.permissionForm.patchValue({
                chkItemView: elements?.isGranted
              });
            }
            if (elements.name === "OrderManagement.Items.Create") {
              this.permissionForm.patchValue({
                chkItemCreate: elements?.isGranted
              });
            }
            if (elements.name === "OrderManagement.Items.Edit") {
              this.permissionForm.patchValue({
                chkItemEdit: elements?.isGranted
              });
            }
            if (elements.name === "OrderManagement.Items.Delete") {
              this.permissionForm.patchValue({
                chkItemDelete: elements?.isGranted
              });
            }

            //Insurance
            if (elements.name === "OrderManagement.Insurances") {
              this.permissionForm.patchValue({
                chkInsuranceView: elements?.isGranted
              });
            }
            if (elements.name === "OrderManagement.Insurances.Create") {
              this.permissionForm.patchValue({
                chkInsuranceCreate: elements?.isGranted
              });
            }
            if (elements.name === "OrderManagement.Insurances.Edit") {
              this.permissionForm.patchValue({
                chkInsuranceEdit: elements?.isGranted
              });
            }
            if (elements.name === "OrderManagement.Insurances.Delete") {
              this.permissionForm.patchValue({
                chkInsuranceDelete: elements?.isGranted
              });
            }

            //Notes
            if (elements.name === "OrderManagement.Notes") {
              this.permissionForm.patchValue({
                chkNotesView: elements?.isGranted
              });
            }
            if (elements.name === "OrderManagement.Notes.Create") {
              this.permissionForm.patchValue({
                chkNotesCreate: elements?.isGranted
              });
            }
            if (elements.name === "OrderManagement.Notes.Edit") {
              this.permissionForm.patchValue({
                chkNotesEdit: elements?.isGranted
              });
            }
            if (elements.name === "OrderManagement.Notes.Delete") {
              this.permissionForm.patchValue({
                chkNotesDelete: elements?.isGranted
              });
            }

            //File Upload For Extraction
            if (elements.name === "PlatformManagement.FileUploads") {
              this.permissionForm.patchValue({
                chkFileUploadView: elements?.isGranted
              });
            }
            if (elements.name === "PlatformManagement.FileUploads.Create") {
              this.permissionForm.patchValue({
                chkFileUploadCreate: elements?.isGranted
              });
            }
            if (elements.name === "PlatformManagement.FileUploads.Edit") {
              this.permissionForm.patchValue({
                chkFileUploadEdit: elements?.isGranted
              });
            }
            if (elements.name === "PlatformManagement.FileUploads.Delete") {
              this.permissionForm.patchValue({
                chkFileUploadDelete: elements?.isGranted
              });
            }

            // users
            if (elements.name === "AbpIdentity.Users") {
              this.permissionForm.patchValue({
                chkUsersView: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Users.Create") {
              this.permissionForm.patchValue({
                chkUsersCreate: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Users.Update") {
              this.permissionForm.patchValue({
                chkUsersEdit: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Users.Delete") {
              this.permissionForm.patchValue({
                chkUsersDelete: elements?.isGranted
              });
            }
            // roles
            if (elements.name === "AbpIdentity.Roles") {
              this.permissionForm.patchValue({
                chkRolesView: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Roles.Create") {
              this.permissionForm.patchValue({
                chkRolesCreate: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Roles.Update") {
              this.permissionForm.patchValue({
                chkRolesEdit: elements?.isGranted
              });
            }
            if (elements.name === "AbpIdentity.Roles.Delete") {
              this.permissionForm.patchValue({
                chkRolesDelete: elements?.isGranted
              });
            }
            this.changePatient(this.check);
            this.changeOrder(this.check);
            this.changeItem(this.check);
            this.changeNotes(this.check);
            this.changeFileUpload(this.check);
            this.changeUsers(this.check);
            this.changeRoles(this.check);
          }
        });
      })
    }, err => {
    });
  }
}
