import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { AddAuthorizationTabComponent } from 'projects/order/src/app/add-authorization-tab/add-authorization-tab.component';
import { PatientAuthorizationService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { authSearchDTO, patientAuthSearchDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-patient-common-authorization-tab',
  templateUrl: './patient-common-authorization-tab.component.html',
  styleUrls: ['./patient-common-authorization-tab.component.scss'],
})
export class PatientCommonAuthorizationTabComponent
  implements OnInit, OnDestroy {
  @Input() orderId: string = defaultGuid;
  @Input() patientId: string = defaultGuid;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  defaultGuid = defaultGuid;
  isLoading: boolean;
  isShowProgress: boolean;
  orderStatus: string;
  tableData: authSearchDTO[];
  subscription$: Subscription[] = [];
  public arrDisplayedColumns: string[] = [
    'options',
    'authId',
    'item',
    'authBeginDate',
    'authEndDate',
    'days',
    'status',
    'isFollowUp',
    'amount',
    'saleOrRent',
    'units',
    'used',
    'planName',
    'planType',
  
  ];

  constructor(
    public authService: PatientAuthorizationService,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getAuthorizationList();
  }
  // On Page Destroy
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  getAuthorizationList() {
    this.isLoading = true;
    const input: patientAuthSearchDTO = {
      sPatientGuid: this.patientId,
      sDefaultPatientNo: "",
      sPatientName: "",
      dtCreatedDate: "",
      dtAuthBeginDate: "",
      dtAuthEndDate: "",
      sOrderId: "",
      sItem: "",
      sUnits: "",
      sUsed: "",
      sAuthType: "",
      sDays: "",
      sAmount: "",
      sSaleOrRent: "",
      sAuthNo: "",
      sPlanName: "",
      sStatus: ""
    }
    const searchDocument$ = this.authService
      .searchPatientAuthorizationByInput(input)
      .subscribe((response) => {
        this.tableData = response ?? [];
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(this.tableData ?? []);
        this.sort.disableClear = true;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    this.subscription$.push(searchDocument$);
  }

  //To Open Auth Modal
  openAuthModal(authId: string) {
    const dialogRef = this.dialog.open(AddAuthorizationTabComponent, {
      disableClose: true,
      data: {
        orderId: this.orderId,
        authorizationId: authId,
        patientId: this.patientId,
      },
    });
    const closeModal = dialogRef.afterClosed().subscribe(() => {
      this.orderId !== '' &&
        this.orderId !== null &&
        this.orderId !== undefined &&
        this.getAuthorizationList();
    });
    this.subscription$.push(closeModal);
  }
  //To Delete the Authorization
  deleteAuth(authId: string) {
    //const authData=this.authService.delete(authId).subscribe(response=>{});
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.authService.delete(authId).subscribe(
          (response) => {
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Deleted Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Deleted Successfullyy','Success')
            this.getAuthorizationList();
          },
          (err) => {
            const data: HttpErrorResponse = err;

            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    });
  }
}
