import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableService } from 'projects/shared/src/app/table.service';
import { defer, from } from 'rxjs';
import { concatMap, groupBy, mergeMap, takeLast, toArray } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { DashboardService } from '../admin-proxy/platform-app-management/rcm/platform-management/dashboard';
import { ProductNotificationDTO } from '../warehouse-proxy/rcm/warehouse-management/warehouse/dto/models';
import { ProductNotificationService } from '../warehouse-proxy/rcm/warehouse-management/warehouse/product-notification.service';


@Component({
  selector: 'app-notification-tray',
  templateUrl: './notification-tray.component.html',
  styleUrls: ['./notification-tray.component.scss'],
})
export class NotificationTrayComponent implements OnInit {
  userId: string = "";
  loginUserName: string = "";
  notificationList: ProductNotificationDTO[] = [];
  intOutOfStock: number = 0;
  intReStocked: number = 0;
  intLowStock: number = 0;
  intNoStock: number = 0;
  intRead: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userId: string },
    private dashboardService: DashboardService,
    private table: TableService,
    private prodNotifService: ProductNotificationService
  ) { }
  //! page initialization function
  ngOnInit(): void {
    this.loadUserProfile();
  }
  //! Get Product Notifications List
  getNotifyList() {
    this.prodNotifService.getProductNotification().subscribe(response => {
      this.notificationList = [];
      this.intOutOfStock = 0;
      this.intReStocked = 0;
      this.intLowStock = 0;
      this.intNoStock = 0;
      this.intRead = 0;
      let notifyList: ProductNotificationDTO[] = response?.items ?? [];
      notifyList?.forEach(element => {
        element.minimumQty = element?.minimumQty ?? 0
        element.currentQty = element?.currentQty ?? 0
        !element?.isDismissed && this.notificationList?.push(element);
      });
      this.notificationList?.forEach(element => {
        if (element?.productStatus === "Out Of Stock" && !element?.readStatus) {
          this.intOutOfStock = 1 + this.intOutOfStock;
        }
        if (element?.productStatus === "In Stock" && !element?.readStatus) {
          this.intReStocked = 1 + this.intReStocked;
        }
        if (element?.productStatus === "Low Stock" && !element?.readStatus) {
          this.intLowStock = 1 + this.intLowStock;
        }
        if (element?.productStatus === "No stock" && !element?.readStatus) {
          this.intNoStock = 1 + this.intNoStock;
        }
        if (element?.readStatus) {
          this.intRead = 1 + this.intRead;
        }
      })
    }, err => {
      this.notificationList = [];

      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  //! Get User Profile Details
  loadUserProfile() {
    this.dashboardService.userProfile().subscribe(response => {
      this.userId = response?.userId ?? "";
      this.loginUserName = response?.userName ?? "";
      response?.roleName==='Warehouse Manager' && this.getNotifyList();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  //!Mark  Notification by ID as Read
  dismissNotifyById(value: ProductNotificationDTO) {
    value.isDismissed = true;
    const input: ProductNotificationDTO = value;
    if (input?.id && input) {
      this.prodNotifService.update(input?.id, input).subscribe(response => {
        this.getNotifyList();
      })
    }
  }
  //!Mark  Notification by ID as Read
  readNotifyById(value: ProductNotificationDTO) {
    value.readStatus = true;
    const input: ProductNotificationDTO = value;
    if (input?.id && input) {
      this.prodNotifService.update(input?.id, input).subscribe(response => {
        this.getNotifyList();
      })
    }
  }
  //!Mark All Notifications as Read
  markAllread() {
    const lstNonReadNotify: ProductNotificationDTO[] = this.notificationList.filter(e => { return e && e?.readStatus === false });
    let arryNonReadNotify: ProductNotificationDTO[] = [];
    lstNonReadNotify?.forEach(e => {
      let data = e;
      data.readStatus = true;
      arryNonReadNotify?.push(data)
    });
    setTimeout(() => {
      from(arryNonReadNotify).pipe(
        groupBy(v => v.id),
        mergeMap(group => group.pipe(toArray())),
        mergeMap(group => from(group)
          .pipe(
            concatMap((v) => defer(() => this.prodNotifService.update(v?.id, v))))),
        takeLast(1)).subscribe(response => {


          this.getNotifyList();
        })
    }, 50);

  }
  //!Mark All Notifications as Dismissed
  markAllDismissed() {
    const lstNonReadNotify: ProductNotificationDTO[] = this.notificationList.filter(e => { return e && e?.isDismissed === false });
    let arryNonReadNotify: ProductNotificationDTO[] = [];
    lstNonReadNotify?.forEach(e => {
      let data = e;
      data.isDismissed = true;
      arryNonReadNotify?.push(data)
    });
    setTimeout(() => {
      from(arryNonReadNotify).pipe(
        groupBy(v => v.id),
        mergeMap(group => group.pipe(toArray())),
        mergeMap(group => from(group)
          .pipe(
            concatMap((v) => defer(() => this.prodNotifService.update(v?.id, v))))),
        takeLast(1)).subscribe(response => {


          this.getNotifyList();
        })
    }, 50);

  }
}

