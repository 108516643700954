import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { ApprovalStatus } from 'projects/order/src/app/order-proxy/order-management/order-optimization/approval-status.enum';
import {
  CreateUpdateShippingApprovalDTO,
  ShippingApprovalDTO,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import { ShippingApprovalService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/shipping-approval.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-shipping-approval-tab',
  templateUrl: './shipping-approval-tab.component.html',
  styleUrls: ['./shipping-approval-tab.component.scss'],
})
export class ShippingApprovalTabComponent implements OnInit,OnDestroy {
  orderId: string = defaultGuid;
  patientId: string = defaultGuid;
  shippingId: string = defaultGuid;
  defaultGuid: string = defaultGuid;
  navId: string;
  shippingDetails: ShippingApprovalDTO;
  subscription$: Subscription[] = [];
  approvalForm: FormGroup;
  // approvalStatus: ApprovalStatus;
  approve: string = '';
  constructor(
    public shippingService: ShippingApprovalService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((response) => {
      this.patientId = response?.get('patientId') ?? defaultGuid;
      this.orderId = response?.get('orderId') ?? defaultGuid;
      this.shippingId = response?.get('id') ?? defaultGuid;
      this.shippingId !== defaultGuid && this.getShippingDetails();
    });

    this.approvalForm = new FormGroup({
      approve: new FormControl(''),
      disapprove: new FormControl(''),
    });
  }

 // On Page Destroy
 ngOnDestroy(): void {
  this.subscription$?.forEach((sub) => {
    sub && sub?.unsubscribe();
  });
}

  backToShippingApprovalList() {
     this.router.navigate(['/orders/shipping-approvallist-tab']);
  }


  //To get the Shipping Details
  getShippingDetails() {
    const shippingDetails = this.shippingService
      .get(this.shippingId)
      .subscribe(
        (response) => {
          this.shippingDetails = response;
          this.approve =
            this.shippingDetails.documentApproval == ApprovalStatus.approved
              ? 'true'
              : this.shippingDetails.documentApproval ==
                ApprovalStatus.disapproved
                ? 'false'
                : '';

          this.shippingDetails?.defaultPatientId ?? "";
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(shippingDetails); //To cancel the previous request if the api call again.
  }

  //On Change NavBar Event
  onNavChange(event: NgbNavChangeEvent) {
    if (event.nextId == 'Document') {
      this.approve =
        this.shippingDetails.documentApproval == ApprovalStatus.approved
          ? 'true'
          : this.shippingDetails.documentApproval == ApprovalStatus.disapproved
            ? 'false'
            : '';
    }
    if (event.nextId == 'Verification') {
      this.approve =
        this.shippingDetails.verificationApproval == ApprovalStatus.approved
          ? 'true'
          : this.shippingDetails.verificationApproval ==
            ApprovalStatus.disapproved
            ? 'false'
            : '';
    }
    if (event.nextId == 'Authorization') {
      this.approve =
        this.shippingDetails.authorizationApproval == ApprovalStatus.approved
          ? 'true'
          : this.shippingDetails.authorizationApproval ==
            ApprovalStatus.disapproved
            ? 'false'
            : '';
    }
  }

  //Save Approval Status
  saveApprovalStatus(tabValue: string, approvalStatus: string) {
    if (tabValue === 'Document') {
      this.shippingDetails.documentApproval =
        approvalStatus == 'true'
          ? ApprovalStatus.approved
          : ApprovalStatus.disapproved;
      this.shippingService
        .update(this.shippingId, this.shippingDetails)
        .subscribe((response) => {

          let message: string =
            approvalStatus === 'true'
              ? 'Document Approved'
              : 'Document Dis-Approved';
          // Swal.fire({
          //   title: 'Success',
          //   html: message,
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('message','Success')
        });
    } else if (tabValue === 'Verification') {
      this.shippingDetails.verificationApproval =
        approvalStatus == 'true'
          ? ApprovalStatus.approved
          : ApprovalStatus.disapproved;
      this.shippingService
        .update(this.shippingId, this.shippingDetails)
        .subscribe((response) => {
          // Swal.fire({
          //   title: 'Success',
          //   html:
          //     approvalStatus === 'true'
          //       ? 'Verification Approved'
          //       : 'Verification Dis-Approved',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success(approvalStatus === 'true'
                ? 'Verification Approved'
                : 'Verification Dis-Approved','Success')
        });
    } else {
      this.shippingDetails.authorizationApproval =
        approvalStatus == 'true'
          ? ApprovalStatus.approved
          : ApprovalStatus.disapproved;
      this.shippingService
        .update(this.shippingId, this.shippingDetails)
        .subscribe((response) => {
          // Swal.fire({
          //   title: 'Success',
          //   html:
          //     approvalStatus === 'true'
          //       ? 'Authorization Approved'
          //       : 'Authorization Dis-Approved',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success(approvalStatus === 'true'
                ? 'Authorization Approved'
                : 'Authorization Dis-Approved','Success')
        });
    }
  }
}
