<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <!-- <h3 class="font-weight-bolder my-2 d-flex align-items-center">
        Patient / Shipping Approval
      </h3> -->
      <h3 class="font-weight-bolder mb-2 mt-2">
        <b class="customThemeClass">List / Billing Dept / Shipping Approval</b>
      </h3>
    </div>
  </div>

  <div class="col-lg-12">
    <!-- Row 1-->
    <div class="row">
      <div class="col-lg-10">
        <div class="card elevated-div">
          <!-- <div class="card-header">Shipping List</div> -->
          <div class="card-body pb-0 mb-1 mt-1">
            <div
              class="d-flex justify-content-center"
              *ngIf="!isShippingTableLoaded"
            >
              <!-- <mat-spinner></mat-spinner> -->
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>

            <div class="table table-responsive">
              <table
                *ngIf="isShippingTableLoaded"
                datatable
                [dtOptions]="dtShippingOptions"
                class="row-border hover w-100 display"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th><mat-icon>apps</mat-icon></th>
                    <th [matTooltip]="'Location'">Location</th>
                    <th [matTooltip]="'Ticket Id'">Ticket Id</th>
                    <th [matTooltip]="'Chart No'">Chart No</th>
                    <th [matTooltip]="'Name'">Name</th>
                    <th [matTooltip]="'Insurance/Cash'">Insurance/Cash</th>
                    <th [matTooltip]="'CSR'">CSR</th>
                    <th [matTooltip]="'Exchange'">Exchange</th>
                    <th class="text-right" [matTooltip]="'Supplies Date'">
                      Supplies Date
                    </th>
                    <th [matTooltip]="'Modify By'">Modify By</th>
                    <th class="text-right" [matTooltip]="'Modified Date'">
                      Modified Date
                    </th>
                    <th [matTooltip]="'Created By'">Created By</th>
                    <th class="text-right" [matTooltip]="'Created Date'">
                      Created Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    (click)="tblShpSelectedRow(data.orderId, data)"
                    [ngClass]="
                      this.tblShpSelectedRowId === data.orderId
                        ? 'row-highlighted'
                        : ''
                    "
                    *ngFor="let data of shippingTableData"
                  >
                    <td></td>
                    <td [matTooltip]="'Open Options'">
                      <mat-icon
                        style="cursor: pointer !important"
                        [matMenuTriggerFor]="menu"
                        >more_vert</mat-icon
                      >
                      <mat-menu #menu="matMenu">
                        <button
                          mat-menu-item
                          (click)="
                            openPendingNotesPopUp(
                              data.defaultFaxId,
                              data.patientId,
                              data.faxDocumentId,
                              false,
                              data.chartno
                            )
                          "
                        >
                          <!-- [disabled]="
                          data.patientId === defaultGuid ||
                          data.patientId === null ||
                          data.patientId === ''
                        " -->
                          Pending Notes
                        </button>
                        <button
                          mat-menu-item
                          (click)="
                            openAddNotesPopUp(
                              data.defaultFaxId,
                              data.patientId,
                              data.faxDocumentId,
                              false,
                              data.chartno
                            )
                          "
                        >
                          <!-- [disabled]="
                          data.patientId === defaultGuid ||
                          data.patientId === null ||
                          data.patientId === ''
                        " -->
                          Add Patient Note
                        </button>
                        <button
                          mat-menu-item
                          (click)="
                            openViewNotesPopup(
                              data.patientId,
                              data.defaultFaxId,
                              data.faxDocumentId
                            )
                          "
                        >
                          View Patient Notes
                        </button>
                        <button
                          [class.disabled]="
                            data.orderId === defaultGuid ||
                            data.orderId === null ||
                            data.orderId === ''
                          "
                          (click)="
                            getOrderHistory(
                              data.orderId,
                              data.mmRefereceOrderId,
                              data.chartno,
                              data.fullName
                            )
                          "
                          mat-menu-item
                        >
                          Order History
                        </button>
                        <button mat-menu-item (click)="viewPatientLedger()">
                          View Patient Ledger
                        </button>
                        <a
                          [routerLink]="
                            '/view_document_tiff/' +
                            data.faxDocumentId +
                            '/' +
                            true
                          "
                          target="_blank"
                          class="preview-link"
                          [class.disabled]="
                            isEmpty(data.faxDocumentId) ||
                            data?.isSorted === false ||
                            data?.isSorted === null
                          "
                        >
                          <button
                            [disabled]="
                              isEmpty(data.faxDocumentId) ||
                              data?.isSorted === false ||
                              data?.isSorted === null
                            "
                            mat-menu-item
                          >
                            View Document Image
                          </button>
                        </a>

                        <!-- <button mat-menu-item (click)="checkListModel(item)">
                        Document CheckList
                      </button> -->
                      </mat-menu>
                    </td>
                    <td [matTooltip]="data?.state || '-'">
                      {{ data.state ? data.state : "-" }}
                    </td>
                    <td [matTooltip]="data?.mmRefereceOrderId || '-'">
                      <a
                        class="preview-link"
                        [class.disabled]="
                          isEmpty(data.chartno) || isEmpty(data.patientId)
                        "
                        [routerLink]="
                          '/orders/saleOrder/' +
                          data.patientId +
                          '/' +
                          data.orderId +
                          '/' +
                          '0'
                        "
                        target="_blank"
                        >{{
                          data?.mmRefereceOrderId
                            ? data?.mmRefereceOrderId
                            : "-"
                        }}
                      </a>
                    </td>
                    <td [matTooltip]="data?.chartno || '-'">
                      <a
                        class="preview-link"
                        [class.disabled]="
                          isEmpty(data.chartno) || isEmpty(data.patientId)
                        "
                        [routerLink]="
                          '/patientCreateEditTab/' +
                          data.patientId +
                          '/' +
                          data.documentId
                        "
                        target="_blank"
                        >{{ data?.chartno ? data?.chartno : "-" }}
                      </a>
                    </td>
                    <td [matTooltip]="data?.fullName || '-'">
                      {{ data.fullName }}
                    </td>
                    <td
                      [matTooltip]="
                        data.isCashOrder
                          ? 'Cash'
                          : data.priPlanName === null || data.priPlanName === ''
                          ? '-'
                          : data.priPlanName
                      "
                    >
                      <!-- {{ data.priPlanName }} -->

                      {{
                        data.isCashOrder
                          ? "Cash"
                          : data.priPlanName === null || data.priPlanName === ""
                          ? "-"
                          : data.priPlanName
                      }}
                    </td>
                    <td [matTooltip]="data?.csrName || '-'">
                      {{ data.csrName }}
                    </td>
                    <td [matTooltip]="data?.isExchangeOrder ? 'Yes' : 'No'">
                      {{ data?.isExchangeOrder ? "Yes" : "No" }}
                    </td>
                    <!-- data?.suppliesdate
                    ? (data?.suppliesdate | date : 'MM/dd/yyyy' : 'en_US')
                    : '-' -->
                    <td class="text-right" [matTooltip]="data?.suppliesdate">
                      {{ data?.suppliesdate }}
                    </td>
                    <td [matTooltip]="data?.modifyByName || '-'">
                      {{ data?.modifyByName || "-" }}
                    </td>

                    <td class="text-right" [matTooltip]="data?.modifiedDate">
                      {{ data?.modifiedDate }}
                    </td>
                    <td [matTooltip]="data?.createdByName || '-'">
                      {{ data.createdByName }}
                    </td>
                    <!-- data?.enteredon
                          ? (data?.enteredon | date : "MM/dd/yyyy" : "en_US")
                          : "-" -->
                    <td class="text-right" [matTooltip]="data?.enteredon">
                      {{ data?.enteredon }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="card elevated-div card-body pt-4 pb-4">
          <form [formGroup]="shippingForm">
            <mat-form-field class="w-100">
              <mat-label>Patient Balance</mat-label>
              <input
                readonly
                matInput
                maxlength="40"
                formControlName="txtPatientBalance"
                type="text"
                [value]="patientBalanceandNotesDetails?.patientBalance"
                placeholder="Patient Balance"
                autocomplete="off"
              />
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>INS Balance</mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                [value]="patientBalanceandNotesDetails?.insBalance"
                formControlName="txtINSBalance"
                type="text"
                placeholder="INS Balance"
                autocomplete="off"
              />
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>Total Balance</mat-label>
              <input
                matInput
                readonly
                maxlength="40"
                [value]="patientBalanceandNotesDetails?.totalBalance"
                formControlName="txtTotalBalance"
                type="text"
                placeholder="Total Balance"
                autocomplete="off"
              />
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>Billing</mat-label>
              <textarea
                readonly
                [value]="patientBalanceandNotesDetails?.billingNotes"
                formControlName="txtBillingNotes"
                rows="5"
                placeholder="Billing"
                maxlength="5000"
                matInput
              ></textarea>
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>Shipping</mat-label>
              <textarea
                readonly
                formControlName="txtShippingNotes"
                rows="5"
                [value]="patientBalanceandNotesDetails?.shippingNotes"
                placeholder="Shipping"
                maxlength="5000"
                matInput
              ></textarea>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <br />

    <!-- Row 2 -->

    <div class="col-lg-12 px-0">
      <div class="card card-body elevated-div mt-1">
        <div class="row">
          <div class="col-12 d-flex justify-content-between">
            <div>
              <button
                mat-raised-button
                class="mx-2 buttonColor"
                [disabled]="
                  tblShpSelectedRowId == defaultGuid ||
                  priVerificationId === defaultGuid ||
                  isCashOrder
                "
                (click)="openVerificationModal('Primary')"
              >
                <mat-icon>verified_user</mat-icon>
                Primary Ins
              </button>
              <button
                mat-raised-button
                [disabled]="
                  tblShpSelectedRowId == defaultGuid ||
                  secVerificationId === defaultGuid ||
                  isCashOrder
                "
                class="mx-2 buttonColor"
                (click)="openVerificationModal('secondary')"
              >
                <mat-icon>verified_user</mat-icon>Secondary Ins
              </button>
              <button
                mat-raised-button
                [disabled]="
                  tblShpSelectedRowId == defaultGuid ||
                  authorizationId === defaultGuid ||
                  isCashOrder
                "
                class="mx-2 buttonColor"
                (click)="viewAuthorization()"
              >
                <mat-icon>verified_user</mat-icon>Authorization
              </button>
              <a
                mat-raised-button
                class="mx-2 buttonColor preview-checklist-link"
                [disabled]="
                  tblShpSelectedRowId == defaultGuid ||
                  checkListId === defaultGuid ||
                  isCashOrder
                "
                target="_blank"
                [routerLink]="
                  '/view_checklist/' +
                  patientId +
                  '/' +
                  true +
                  '/' +
                  true +
                  '/' +
                  checkListId
                "
              >
                <mat-icon>assignment_turned_in</mat-icon>Check List
              </a>
              <button
                mat-raised-button
                class="mx-2 buttonColor"
                [disabled]="tblShpSelectedRowId == defaultGuid"
                (click)="viewPatientLedger()"
              >
                <mat-icon>view_list</mat-icon>View Ledger
              </button>
              <button
                mat-raised-button
                class="mx-2 buttonColor"
                [disabled]="tblShpSelectedRowId == defaultGuid"
                (click)="getPrintTicketDetails()"
              >
                <mat-icon>print</mat-icon>Print Ticket
              </button>
              <button
                mat-raised-button
                class="mx-2 buttonColor"
                [disabled]="disableBtn != true"
              >
                <mat-icon>picture_as_pdf</mat-icon>View PDF
              </button>
            </div>
            <div>
              <button
                mat-raised-button
                class="mx-2 buttonColor"
                [disabled]="
                  tblShpSelectedRowId == defaultGuid ||
                  isShowSpinner ||
                  !isShippingTableLoaded
                "
                (click)="approveDisapproveShipping(tblShpSelectedRowId, true)"
              >
                <mat-icon style="margin-right: 5px" *ngIf="!isShowSpinner"
                  >check_circle_outline</mat-icon
                >
                <mat-icon *ngIf="isShowSpinner">
                  <mat-spinner
                    class="spinner-border-sm"
                    diameter="20"
                  ></mat-spinner> </mat-icon
                >Approved
              </button>
              <button
                mat-raised-button
                [disabled]="
                  tblShpSelectedRowId == defaultGuid || isCashOrder === true || isExchangeOrder
                "
                class="mx-2 resetclr"
                (click)="notesReason()"
              >
                <mat-icon>cancel</mat-icon>Disapprove
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />

    <!-- row 3 -->
    <div class="row">
      <div class="col-lg-2">
        <div class="card elevated-div card-body p-0 mb-3">
          <div class="table-container">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th [matTooltip]="'Approval No'" class="custom-th">
                    Approval No
                  </th>
                  <th [matTooltip]="'Amount'" class="custom-th">Amount</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="authTableData.length > 0">
                  <tr *ngFor="let data of authTableData">
                    <td [matTooltip]="data.authId">
                      {{ data.approvalNo ? data.approvalNo : "-" }}
                    </td>
                    <td [matTooltip]="data.amount">{{ data.amount }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-lg-9">
        <div class="card card-body elevated-div mb-3 pt-0 px-2 pb-1 mb-3">
          <div class="d-flex justify-content-center" *ngIf="!loadItmTbl">
            <!-- <mat-spinner></mat-spinner> -->
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
            />
          </div>
          <!-- <div *ngIf="loadItmTbl" class="table-container-itm"> -->
          <table
            datatable
            *ngIf="loadItmTbl"
            [dtOptions]="dtItemOptions"
            class="table table-sm row-border hover display"
          >
            <thead>
              <tr>
                <th>#</th>
                <th [matTooltip]="'Prod Code'">Prod Code</th>
                <th [matTooltip]="'Description'">Description</th>
                <th [matTooltip]="'Qty'" class="text-right">Qty</th>
                <!-- <th>Exchange</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                [ngClass]="
                  this.tblItemSelectedRowId === data.productId
                    ? 'row-highlighted'
                    : ''
                "
                (click)="tblItemSelectedRow(data.productId, data.hcpcList)"
                *ngFor="let data of itemTableData"
              >
                <td></td>
                <td [matTooltip]="data?.productCode || '-'">
                  {{ data.productCode ? data.productCode : "-" }}
                </td>
                <td [matTooltip]="data?.productDescription || '-'">
                  {{ data.productDescription ? data.productDescription : "-" }}
                </td>
                <td class="text-right" [matTooltip]="data?.quantity || '0'">
                  {{ data.quantity }}
                </td>
                <!-- <td><mat-checkbox></mat-checkbox></td> -->
              </tr>
            </tbody>
          </table>
          <!-- </div> -->
        </div>
      </div>
      <!-- HCPC Table -->
      <div class="col-lg-1">
        <div class="card elevated-div card-body p-0 mb-3">
          <div class="table-container">
            <table class="table table-bordered mb-0">
              <thead>
                <tr>
                  <th [matTooltip]="'HCPC'" class="custom-th">HCPC</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of hcpcTableData">
                  <td [matTooltip]="data.hcpcCode">{{ data.hcpcCode }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
