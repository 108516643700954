<mat-card class="ml-3">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-12">
        <!-- <div *ngIf="lblDefaultOrderId" class="text-center">
          <span style="font-size: larger;"><h2 class="text-blue text-bold mb-0">SaleOrder Id - {{ lblDefaultOrderId }}</h2></span>
        </div>
        <div class="text-center">
          <span><h2 class="colored-heading text-bold mb-0" >Chart No - {{ lblDefaultPatientId }}</h2></span>
        </div>
        <div class="text-center">
          <span style="font-size: larger;"><h4 class="colored-heading text-bold mb-0">Patient Name - {{ lblPatientName }}</h4></span>
        </div>
        <div class="text-center">
          <span><h2 class="colored-heading text-bold mb-0" >Branch - Arlington Office</h2></span>
        </div>
        <div class="table-responsive">
          <table class="table table-centered  table-borderless mb-0">
            <tbody class="text-center">
              <tr>
                <td class="pb-0 pt-0">
                  <p class="mb-0 text-right">DOB</p>
                </td>
                <td class="pb-0 pt-0">
                  <p class="mb-0 text-center">-</p>
                </td>
                <td class="pb-0 pt-0">
                  <p class="mb-0 text-left">{{ lblPatientDOB | date: 'MM/dd/yyyy':'en_US' }}  - Age  {{ lblPatientAge}}</p>
                </td>
              </tr>
              <tr>
                <td class="pb-0 pt-0">
                  <p class="mb-0 text-right">Gender</p>
                </td>
                <td class="pb-0 pt-0">
                  <p class="mb-0 text-center">-</p>
                </td>
                <td class="pb-0 pt-0">
                  <p class="mb-0 text-left">
                    <ng-container *ngIf="lblPatientGender==='F'">
                    Female
                    </ng-container>
                    <ng-container *ngIf="lblPatientGender==='M'">
                      Male
                    </ng-container>
                    <ng-container *ngIf="lblPatientGender==='U'">
                      Unknown
                    </ng-container>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->


        <div *ngIf="lblDefaultOrderId" class="text-center">
          <span class="colored-heading text-heading  mb-0">SaleOrder Id - {{ lblDefaultOrderId }}</span>
        </div>

        <div class="text-center">
          <span class="colored-heading text-heading mb-0">Chart No - {{ lblDefaultPatientId }}</span>
        </div>
        <div class="text-center">
          <span class="colored-heading mb-0 text-content" style="font-size: larger;">Patient Name - {{ lblPatientName }}</span>
        </div>
        <div class="text-center">
          <span class="colored-heading mb-0 text-content">Branch - Arlington Office</span>
        </div>
        <div class="text-center">
          <span class="mb-0 text-right text-content">DOB </span>
          <span class="mb-0 text-center text-content">- </span>
          <!-- <span class="mb-0 text-left text-content">{{ lblPatientDOB | date: 'MM/dd/yyyy':'en_US' }} - Age {{ lblPatientAge }}</span> -->
          <!-- <span class="mb-0 text-left text-content">{{ lblPatientDOB | date: 'MM/dd/yyyy'}} - Age {{ lblPatientAge }}</span> -->
          <span class="mb-0 text-left text-content">{{ lblPatientDOB }} - Age {{ lblPatientAge }}</span>
        </div>
        <div class="text-center">
          <span class="mb-0 text-right text-content"> Gender </span>
          <span class="mb-0 text-center text-content">-</span>
          <span class="mb-0 text-left">
            <ng-container [ngSwitch]="lblPatientGender">
              <span *ngSwitchCase="'F'" class="text-content"> Female</span>
              <span *ngSwitchCase="'M'" class="text-content"> Male</span>
              <span *ngSwitchCase="'U'" class="text-content"> Unknown</span>
            </ng-container>
          </span>
        </div>

        <div *ngIf="lblDefaultOrderId===''" class="text-center">
          <span style="font-size: larger mb-0"><h4 class="colored-heading blinking mb-0">Current Status -
            <ng-container [ngSwitch]="lblPtCurrentStatus">
              <ng-container *ngSwitchCase="'clinicalPending'">Clinical Pending </ng-container>
              <ng-container *ngSwitchCase="'policyPending'">Policy Pending </ng-container>
              <ng-container *ngSwitchCase="'verifyPending'">Verification Pending </ng-container>
              <ng-container *ngSwitchCase="'readyForOrder'">Ready For Order </ng-container>
              <ng-container *ngSwitchDefault>Ready For Intake </ng-container>
            </ng-container>
          </h4>
          </span>
        </div>
        <div *ngIf=" lblDefaultOrderId!==''" class="text-center">
          <span style="font-size: larger mb-0"><h4 class="colored-heading blinking mb-0">Current Status -
            <ng-container [ngSwitch]="lblPtCurrentStatus">
              <ng-container *ngSwitchCase="'completed'">Order Completed</ng-container>
              <ng-container *ngSwitchCase="'itemPending'">Item Pending</ng-container>
              <ng-container *ngSwitchCase="'orderVoided'">Order Voided</ng-container>
              <ng-container *ngSwitchCase="'authorizationPending'">Authorization Pending</ng-container>
              <ng-container *ngSwitchCase="'orderPending'">Order Pending</ng-container>
              <ng-container *ngSwitchDefault>Ready For Order</ng-container>
            </ng-container>
          </h4>
          </span>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="mt-3 ml-3">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-12">
        <h2 class="colored-heading"><b>Billing Details</b></h2>
        <div class="text-center">
          <!-- <p class="mb-0 text-center">Billed Amount {{lblBilledAmount | currency : "USD" : "symbol"}}</p> -->
          <p class="mb-0 text-center"> Patient Balance</p>
          <p class="mb-0 text-center colored-heading mt-1"> {{lblPatientBalance | currency : "USD" : "symbol"}}</p>
        </div>
        <div class="table-responsive mt-2">
          <table class="table table-centered">
            <tbody>
              <tr>
                <td>
                  <!-- <p class="mb-0 text-center">Allowed Amount {{lblAllowedAmount | currency : "USD" : "symbol"}}</p> -->
                  <p class="mb-0 text-center">Inurance Balance</p>
                  <p class="mb-0 text-center colored-heading mt-1"> {{lblInsuranceBalance | currency : "USD" : "symbol"}}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <p class="mb-0 text-center">Received Amount {{lblReceivedAmount | currency : "USD" : "symbol"}}</p> -->
                  <p class="mb-0 text-center">Recoop Amount</p>
                  <p class="mb-0 text-center colored-heading mt-1"> {{lblInsuranceRecoopAmount | currency : "USD" : "symbol"}}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <p class="mb-0 text-center">Received Amount {{lblReceivedAmount | currency : "USD" : "symbol"}}</p> -->
                  <p class="mb-0 text-center">Total Amount</p>
                  <p class="mb-0 text-center colored-heading mt-1"> {{lblTotalAmount | currency : "USD" : "symbol"}}</p>
                </td>
              </tr>
              <!-- <tr>
                <td>
                  <p class="mb-0 text-center">Pending Amount {{lblPendingAmount | currency : "USD" : "symbol"}}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">Adjustment Amount {{iAdjustmentAmount | currency : "USD" : "symbol"}}</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p class="mb-0 text-center">Billing Status </p>
                    <h5 class="btm text-center">
                      <div [ngSwitch]="lblBillingStatus">
                        <div *ngSwitchCase="'all'">ALL</div>
                        <div *ngSwitchCase="'claimProcessed'">Claim Processed</div>
                        <div *ngSwitchCase="'claimDenied'">Claim Denied</div>
                        <div *ngSwitchCase="'readyToProcess'">Ready To Process</div>
                        <div *ngSwitchCase="'claimCompleted'">Claim Completed</div>
                        <div *ngSwitchCase="'postingCompleted'">Posting Completed</div>
                        <div *ngSwitchCase="'primaryPostingPending'">Primary Posting Pending</div>
                        <div *ngSwitchCase="'secondaryPostingPending'">Secondary Posting Pending</div>
                        <div *ngSwitchCase="'tertiaryPostingPending'">Tertiary Posting Pending</div>
                        <div *ngSwitchCase="'eobPending'">EOB Pending</div>
                        <div *ngSwitchDefault></div>
                      </div>
                    </h5>

                </td>
              </tr> -->
              <tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="mt-3 ml-3">
  <mat-card-content>
    <div class="row">
      <div class="col-lg-12">
        <h2 class="colored-heading"><b>Age Wise Payment Details</b></h2>
        <div class="text-center mt-2">
          <div class="row">
            <div class="col-lg-6">
              <p class="mb-0 ml-3"><b>Days</b></p>
            </div>
            <div class="col-lg-6">
              <p class="mb-0"><b>Amount</b></p>
            </div>
          </div>
        </div>
        <div class="table-responsive mt-2">
          <table class="table table-centered" style="margin-top:0px;">
            <tbody class="text-center">
              <tr>
                <td>
                  <p class="mb-0 text-center">30</p>
                </td>
                <td>
                  <!-- <p class="mb-0 text-center">{{lblthirtyDayPaidAmnt===""?0:lblthirtyDayPaidAmnt | currency : "USD" :
                    "symbol"}}</p> -->
                    <p class="mb-0 text-center">{{lblThirtyDays}}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">60</p>
                </td>
                <td>
                  <!-- <p class="mb-0 text-center">{{lblsixtyDayPaidAmnt===""?0 :lblsixtyDayPaidAmnt | currency : "USD" :
                    "symbol" }}</p> -->
                    <p class="mb-0 text-center">{{lblSixtyDays}}</p>

                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">90</p>
                </td>
                <td>
                  <!-- <p class="mb-0 text-center">{{lblninetyDayPaidAmnt===""?0:lblninetyDayPaidAmnt | currency : "USD" :
                    "symbol"}}</p> -->
                    <p class="mb-0 text-center">{{lblNinetyDays}}</p>

                </td>
              </tr>
              <tr>
                <td>
                  <p class="mb-0 text-center">Above 90</p>
                </td>
                <td>
                  <!-- <p class="mb-0 text-center">{{lblAboveNinetyDayPaidAmnt===""?0:lblAboveNinetyDayPaidAmnt | currency :
                    "USD" : "symbol" }}</p> -->
                    <p class="mb-0 text-center">{{lblAboveNinetyDays}}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
