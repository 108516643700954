import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { PlatformUrl } from 'projects/patient/src/environments/environment';
import { from } from 'rxjs';
import { groupBy, mergeMap, reduce, map, toArray } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ChatService } from '../admin-proxy/platform-app-management/rcm/platform-management/chats';
import {
  ChatDTO,
  CreateUpdateChatDTO,
} from '../admin-proxy/platform-app-management/rcm/platform-management/chats/dto/models';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import {
  IdentityUserUsingTenantIdDto,
  IdentityUserWithRoleDto,
} from '../admin-proxy/platform-app-management/rcm/platform-management/user/models';
@Component({
  selector: 'app-chat-signalr',
  templateUrl: './chat-signalr.component.html',
  styleUrls: ['./chat-signalr.component.scss'],
})
export class ChatSignalrComponent implements OnInit {
  @Input() message: any | undefined;
  userId: string | undefined;
  private hubConnection: signalR.HubConnection;
  public data: any[];
  title = 'angular-chat';
  channel: any;
  username = '';
  messages: any[] = [];
  selUserToMessage: string = "";
  newMessage = '';
  messageList: any[] = [];
  messageBoxList: { fromUserId?: string, fromUserName?: string, isMessageViewed?: string, message?: any[], messageSentTime?: string, toUserId?: string, toUserName?: string } = {};
  chatClient: any;
  currentUser: any;
  usersList: IdentityUserWithRoleDto[] = [];

  clientId: string = localStorage.getItem('tenantId') ?? '';

  constructor(
    private chatClientService: ChatService,
    private userService: UserService
  ) { }
  ngOnInit(): void {
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(PlatformUrl + '/notify')
      .build();

    connection.start().then(function () {
    }).catch(function (err) {
      return console.warn('SignalR not Connected', err.toString())
    });
    this.userId = localStorage.getItem('userId') ?? '';

    connection.on("SendMessage", () => {
      this.messageList = [];
      this.chatClientService.getList(new PagedAndSortedResultRequestDto).subscribe(e => {
        this.chatClientService
          .getList(new PagedAndSortedResultRequestDto())
          .subscribe((response) => {
            this.addToMsgListBox(response?.items);
          });


        this.chatClientService.getchatdata(this.userId).subscribe(response => {
          this.addToInbox(response);
        });
      });
    });

    this.messageList = [];

    let filters: IdentityUserUsingTenantIdDto = {
      tenantId: this.clientId,
    };

    this.userService.getUserList(filters).subscribe(
      (response) => {
        this.usersList = response;
        this.chatClientService
          .getList(new PagedAndSortedResultRequestDto())
          .subscribe((response) => {
            this.addToMsgListBox(response?.items);
          });
      });


  }

  ViewChatByUserId(value: ChatDTO) {
    this.selUserToMessage = value?.fromUserId ?? "";
    this.chatClientService.getchatdata(value?.fromUserId).subscribe(response => {
      this.addToInbox(response);
    });

  }
  sendMessage(messgae: string[]) {
    const input: CreateUpdateChatDTO = {
      fromUserId: this.userId,
      toUserId: this.selUserToMessage,
      message: this.newMessage,
      isUserViewed: false,
    };
    this.chatClientService.create(input).subscribe((response) => {
    });
  }

  get isSentByCurrentUser() {
    return this.message?.user?.id === this.userId;
  }

  // async sendMessage() {
  //   if (this.newMessage.trim() === '') {
  //     return;
  //   }

  //   try {
  //     await this.channel.sendMessage({
  //       text: this.newMessage,
  //     });
  //     this.newMessage = '';
  //   } catch (err) {
  //   }
  // }
  async joinChat() {
    const { username } = this;
    try {
      this.currentUser = await this.chatClient.setUser({
        id: username,
        name: username,
      });

      this.channel.on('message.new', (event) => {

      });

      const filter = {
        type: 'team',
        members: { $in: [`${this.currentUser.me.id}`] },
      };
      const sort = { last_message_at: -1 };

      this.messageList = await this.chatClient.queryChannels(filter, sort, {
        watch: true,
        state: true,
      });
    } catch (err) {
      return;
    }
  }

  addToInbox(msgInboxArray: {
    fromUserId: string,
    fromUserName: string,
    toAllUsers: {},
    toSingleUser: any[]
  }) {
    this.messageBoxList ={};
    msgInboxArray?.toSingleUser?.forEach((element) => {
      let message: any = {
        fromUserId: msgInboxArray?.fromUserId,
        fromUserName: msgInboxArray.fromUserName,
        toUserId: element?.toUserId,
        toUserName: element.toUserName,
        message: element?.message,
        messageSentTime: element?.creationTime,
        isMessageViewed: element?.isMessageViewed,
      };
      this.messageBoxList=message;
    });

  }

  addToMsgListBox(msgInboxArray: any[]) {

    msgInboxArray?.forEach((element) => {
      let message: any = {
        fromUserId: element?.fromUserId,
        fromUserName: this.usersList.filter((e) => e?.id === element?.fromUserId)[0]?.userName ?? '',
        toUserId: element?.toUserId,
        toUserName: this.usersList.filter((e) => e?.id === element?.toUserId)[0]?.userName ?? '',
        message: element?.message,
        messageSentTime: element?.creationTime,
        isMessageViewed: element?.isMessageViewed,
      };
      this.messageList?.push(message);

    });
  }
}
