import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import Swal from 'sweetalert2';
import { BatchFileUploadsDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/dto';
import { FileUploadService } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/file-upload.service';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';

import { IdentityUserDto } from '../admin-proxy/volo/abp/identity/models';
import { dateFormatter } from '../sub-batch-list/sub-batch-list.component';
import { AddDocumentBinComponent } from './../add-document-bin/add-document-bin.component';

@Component({
  selector: 'app-upload-bin',
  templateUrl: './upload-bin.component.html',
  styleUrls: ['./upload-bin.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
})
export class UploadBinComponent implements OnInit {
  isLoading: boolean = true;
  strPageType: string = 'AdminfaxBin';
  organizationUnitId: string = '';
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  expandedElement: any | null;
  arrUsersList: IdentityUserDto[] = [];
  tenantId: string = "";
  arrDisplayedColumns: string[] = [];
  arrTableData: any[] = [];
  isShownSaveButton: boolean = false;
  isShownCreateButton: boolean = false;
  isShownDeleteButton: boolean = false;
  isShownViewButton: boolean = false;
  batchUploadBinForm: FormGroup;
  constructor(
    private title: Title,
    private table: TableService,
    public userService: UserService,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dateValidators: DateValidator,
    private fileUploadService: FileUploadService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.batchUploadBinForm = this.fb.group({
      documentName: new FormControl(""),
      documentType: new FormControl(""),
      uploadedFrom: new FormControl(""),
      uploadedDate: new FormControl("", [this.dateValidators.dateVaidator]),
      uploadedBy: new FormControl(""),
      // noofFilesUploaded: new FormControl(""),
      // batchStatus: new FormControl(""),
    })
    this.batchUploadBinForm.valueChanges.subscribe(value => {
      if (this.batchUploadBinForm.valid) {
        const sBatchCode: string = this.batchUploadBinForm.value.documentName;
        const sType: string = this.batchUploadBinForm.value.documentType;
        const sUploadFrom: string = this.batchUploadBinForm.value.uploadedFrom;
        const dtCreatedDate: string = this.batchUploadBinForm.value.uploadedDate;
        const sUploadBy: string = this.batchUploadBinForm.value.uploadedBy;
        // const iFileCount: number = this.batchUploadBinForm.value.noofFilesUploaded;
        //    const sBatchStatus: string = this.batchUploadBinForm.value.batchStatus;
        this.searchByInputs(sBatchCode, sType, sUploadFrom, dtCreatedDate, sUploadBy, 0, "");
      }
    })
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    // this.arrDisplayedColumns = ['documentName', 'uploadedFrom', 'uploadedDate', 'uploadedBy', 'noofFilesUploaded', 'batchStatus', 'Options'];
    this.arrDisplayedColumns = [ 'documentName', 'uploadedFrom', 'uploadedDate', 'uploadedBy','Options',];
    this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId') ?? "";
    this.getUsersList();
    this.table.setlstfaxBinDataTable([]);
    this.title.setTitle('Qsecure | Fax Bin');
    this.table.getfileUploadCreate().subscribe(value => {
      if (value == true) {
        this.isShownCreateButton = true;
      }
      else {
        this.isShownCreateButton = false;
      }
    });

    this.table.getfileUploadDelete().subscribe(value => {
      if (value == true) {
        this.isShownDeleteButton = true;
      }
      else {
        this.isShownDeleteButton = false;
      }
    });

    this.table.getfileUploadView().subscribe(value => {
      if (value == true) {
        this.isShownViewButton = true;
      }
      else {
        this.isShownViewButton = false;
      }
    });
  }
  private searchByInputs(sBatchCode: string, sType: string, sUploadFrom: string, dtCreatedDate: string, sUploadBy: string, iFileCount: number, sBatchStatus: string) {
    this.fileUploadService.searchFileBySBatchCodeAndSTypeAndSUploadFromAndDtCreatedDateAndSUploadByAndIFileCountAndSBatchStatus(sBatchCode, sType,
      sUploadFrom,
      dateYYYYMMDDtoMMDDYYYY(dtCreatedDate),
      sUploadBy,
      iFileCount,
      sBatchStatus).subscribe(response => {

        this.arrTableData = [];
        this.dataSource = new MatTableDataSource(this.arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        const startDate: null = null;
        const endDate = new Date()?.toLocaleDateString("en-US");
        const status: string = "ALL";
        let arrTableData: batchDetails[] = [];
        const faxBinDataTable: BatchFileUploadsDTO[] = response?.items ?? [];


        arrTableData = this.extractBatchTableDetails(faxBinDataTable);
        this.arrTableData = [...arrTableData];
        this.dataSource = new MatTableDataSource(this.arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
  }

  // alert for reload
  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHander(event: BeforeUnloadEvent) {
  //   return false;
  // }
  getFaxBinTableData() {
    this.isLoading = true;
    this.arrTableData = [];
    this.dataSource = new MatTableDataSource(this.arrTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    const startDate: string = "";
    const endDate = Intl.DateTimeFormat('en-US').format(new Date());
    const status: string = "ALL";
    let arrTableData: batchDetails[] = [];
    this.fileUploadService.getFileBatchListByDtStartDateAndDtEndDateAndSStatus(startDate, endDate, status).subscribe(response => {
      const faxBinDataTable: BatchFileUploadsDTO[] = response;
      arrTableData = this.extractBatchTableDetails(faxBinDataTable);
      this.arrTableData = [...arrTableData];
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  private extractBatchTableDetails(faxBinDataTable: BatchFileUploadsDTO[]): batchDetails[] {
    let arrTableData: batchDetails[] = [];
    try {
      faxBinDataTable.forEach(v => {
        if (v && v?.listFileUploads && v?.listFileUploads !== [] && v?.listFileUploads?.length !== 0 && v?.listFileUploads !== null && !v?.archived && !v?.isDeleted) {
          const listFileUploads = v?.listFileUploads;

          let data: batchDetails = {
            isError: !listFileUploads.every(v => v?.reasonForNotExtracted === "") ?? false,
            id: v.id ?? "",
            defaultBatchId: v.defaultBatchId ?? "",
            //batchStatus: v?.batchStatus ?? "",
            uploadedBy: this.getUserName(v?.uploadedBy ?? "") ?? "",
            uploadedFrom: v?.uploadedFrom ?? "",
            uploadedOn: dateFormatter(v?.uploadedOn ?? "") ?? "",
            type: v?.type ?? "",
            //   noofFilesUploaded: listFileUploads?.length ?? 0,
            fileId: listFileUploads[0]?.fileId
          };
          arrTableData.push(data);
        }
      });
    } catch (error) {
      arrTableData = [];
    }
    return arrTableData;
  }
  private getUserName(value: string) {
    if (value && typeof value === "string" && value?.length >= 36) {
      return this.arrUsersList?.filter(u => u?.id + '/' + u?.userName === value)[0]?.userName ?? "";
    }
    return ""
  }
  private getUsersList() {

    this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;
      this.getFaxBinTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  openAddFaxBinModal() {
    const dialogRef = this.dialog.open(AddDocumentBinComponent, {
      minHeight: '40vh',
      minWidth: '60vw',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getFaxBinTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }


  archiveBatchById(value: string) {

    Swal.fire({
      title: 'Are you sure you want to Archive?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Archive it!',
    }).then(result => {
      if (result.value) {
        if (value !== "" && typeof value === "string" && value !== undefined && value !== null) {
          this.fileUploadService.updateArchiveFileBatchStatusByBatchIdAndArchived(value, true).subscribe(() => {
            this.getFaxBinTableData();
          }, err => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
        }
      }
    });
  }

  deleteFileById(value: { id: string }) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.value) {
        value.id && this.fileUploadService.deleteBatchByBatchId(value.id).subscribe(response => {


          this.getFaxBinTableData();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }
  
  //! Document Viewer with Feilds side by side
  documnetViewerSideBySide(fileId: string, batchId: string) {
    if (fileId && batchId) {
      const url = '/#/ViewDocumentDetailsAndApprove/' + [fileId.toString()] + '/' + [batchId.toString()];
      window.open(url, '_blank');
    } else {
      const url = '/#/subBatchListTemp/' + [batchId.toString()];
      window.open(url, '_blank');
    }
  }

}
export interface batchDetails {
  // id: string, defaultBatchId: string, isError: boolean, batchStatus: string, uploadedBy: string, uploadedFrom: string, uploadedOn: string, type: string, noofFilesUploaded: number
  id: string, defaultBatchId: string, isError: boolean, uploadedBy: string, uploadedFrom: string, uploadedOn: string, type: string, fileId: string
}
function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    let latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}

