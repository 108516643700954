<h2 mat-dialog-title>
    <mat-toolbar class="buttonColor">
        <div class="align-right-end">Chat List</div>
    </mat-toolbar>
</h2>
<mat-dialog-content>
    <div class="content" role="main">
        <div class="container">
            <div class="messaging">
                <div class="inbox_msg">
                    <div class="inbox_people">
                        <div class="headind_srch">
                            <div class="channel_heading">
                                <h4>Recent Chats</h4>
                            </div>
                        </div>
                        <div class="inbox_chat">
                            <div class="channels" *ngFor="let msg of messageList">
                                <div *ngIf="msg" class="chat_list">
                                    <div (click)="ViewChatByUserId(msg)" *ngIf="msg" class="chat_people">
                                        <!-- <ng-template *ngIf="msg;else elseMsgTemplate" class="chat_ib">
                                            <h5>
                                                {{ msg?.toUserId }}
                                            </h5>
                                        </ng-template>
                                        <ng-template #elseMsgTemplate class="chat_ib">

                                        </ng-template> -->

                                        <div class="chat_ib">
                                            <h5 class="title">
                                                {{ msg?.fromUserName }}
                                            </h5>
                                            <p class="preview">
                                                {{msg?.message}}
                                            </p>
                                            <span class="time">
                                                {{msg?.messageSentTime | date :'MM/dd/yyyy h:mm a' :'en_US'}}
                                                <span *ngIf="msg?.isMessageViewed" class="pl-1 double-tick">
                                                    <i class="fa fa-check-circle-o" ></i>
                                                </span>
                                            </span>

                                        </div>

                                        <!-- <li class="person focus">
                                            <span class="title">Voldemort </span>
                                            <span class="preview">What are you getting... Oh, oops...</span>
                                        </li> -->


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mesgs">
                        <div class="seprated-list2">
                            <mat-form-field class="col-12">
                                <mat-label>User </mat-label>
                                <mat-select [(ngModel)]="selUserToMessage">
                                    <mat-option [value]="'All Users'">
                                        {{ "All Users" }}
                                    </mat-option>
                                    <mat-option *ngFor="let user of usersList" [value]="user.id">
                                        {{ user?.userName }}
                                    </mat-option>

                                </mat-select>
                                <button mat-button (click)="selUserToMessage='';$event.stopPropagation()"
                                    *ngIf="selUserToMessage" matSuffix mat-icon-button >
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <div class="msg_history">
                            <div class="seprated-list">
                                <li class="message" *ngFor="let message of messageBoxList?.message">
                                    <div
                                        *ngIf="messageBoxList?.toUserId !== userId;then incoming_msg;else outgoing_msg">
                                    </div>
                                    <ng-template #incoming_msg>
                                        <div class="incoming_msg">
                                            <div class="incoming_msg_img">
                                                <img src="https://i.imgur.com/k2PZLZa.png" alt="User avatar" />
                                            </div>
                                            <div class="received_msg">
                                                <div class="received_withd_msg">
                                                    <p>{{ message?.message }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template #outgoing_msg>
                                        <div class="outgoing_msg">
                                            <div class="sent_msg">
                                                <p>{{ message?.message }}</p>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                            </div>
                        </div>
                        <div class="type_msg">
                            <form class="input_msg_write">
                                <input autocomplete="new-newMessage" type="text" class="write_msg" placeholder="Type a message" name="newMessage"
                                    [(ngModel)]="newMessage" />
                                <button class="msg_send_btn" type="button" (click)="sendMessage(messages)">
                                    <i class="fa fa-paper-plane-o" ></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
