import { PatientMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { wipStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/wip-status.enum';
import { WorkInProgressService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress';
import {
  CommentsList,
  WIPList,
  clsWipSearchDTO,
  clsWipStatus,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress/dto';
import {
  wipUserTaskFilter,
  wipUserTaskFilterOptions,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress/wip-user-task-filter.enum';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { TableService } from 'projects/shared/src/app/table.service';
import { Observable, Subscription, forkJoin } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PatientPersonalService } from '../patient-proxy/patient-optimization';
import { PatientPersonalDTO } from '../patient-proxy/patient-optimization/dto';
import { ScheduleTaskComponent } from '../schedule-task/schedule-task.component';
import { ScheduledTaskListComponent } from '../scheduled-task-list/scheduled-task-list.component';
import { TaskViewerComponent } from '../task-viewer/task-viewer.component';
import { Moment } from 'moment';
import {
  DaterangepickerDirective,
  LocaleConfig,
} from 'ngx-daterangepicker-material';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { WipAllocationModalComponent } from '../wip-allocation-modal/wip-allocation-modal.component';
import { WipListModalComponent } from '../wip-list-modal/wip-list-modal.component';
import { ToastrService } from 'ngx-toastr';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { UserByRoleDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { WipStatusDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/order';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';

@Component({
  selector: 'app-task-list-page-tab',
  templateUrl: './task-list-page-tab.component.html',
  styleUrls: ['./task-list-page-tab.component.scss'],
  providers: [DatePipe],
})
export class TaskListPageTabComponent implements OnInit, AfterContentInit {
  wipForm: FormGroup;
  wipSearchForm: FormGroup;
  wipFilterForm: FormGroup;
  wipSuppliesSearchForm: FormGroup;
  wipSuppliesFilterForm: FormGroup;
  selectedRange: { startDate: Moment; endDate: Moment };
  activeTab: string = 'NewSetup';
  // calendarLocale: LocaleConfig;
  // calendarPlaceholder: string = '';
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';
  alwaysShowCalendars: boolean;
  dateRangStatus: boolean = false;
  drpWipReportType: { value: string; key: string }[] = [];
  wipReportType: string;
  scheduledData: any;
  fromDate: any;
  toDate: any;
  isLoading: boolean = true;
  isLoadingSupplies: boolean = true;
  filterValue: any;
  userFilterValue: string[] = [];
  lstFilterUsers: UserByRoleDTO[] = [];
  public filteredUsers: Observable<UserByRoleDTO[]> | undefined;
  statusFilterValue: string[] = [];
  lstFilterStatus: clsWipStatus[] = [];
  drpWipStatus: WipStatusDTO[] = [];
  public filteredStatus: Observable<clsWipStatus[]> | undefined;
  public filteredWipStatus: Observable<any[]> | undefined;

  suppliesUserFilterValue: string[] = [];
  lstSuppliesFilterUsers: UserByRoleDTO[] = [];
  public suppliesFilteredUsers: Observable<UserByRoleDTO[]> | undefined;

  suppliesStatusFilterValue: string[] = [];
  lstSuppliesFilterStatus: clsWipStatus[] = [];
  public suppliesFilteredStatus: Observable<clsWipStatus[]> | undefined;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  newSetupList: any[] = [];
  newSetupCount: number;
  dataSourceSupplies: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  suppliesList: any[] = [];
  suppliesCount: number;
  dataSourceComments: MatTableDataSource<CommentsList> =
    new MatTableDataSource<CommentsList>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) suppliesSort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) suppliesPaginator: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) commentsPaginator: MatPaginator;
  @ViewChild(DaterangepickerDirective, { static: false })
  pickerDirective: DaterangepickerDirective;
  @ViewChild('allUsersSelected') private allUsersSelected: MatOption;
  @ViewChild('userSelect') userSelect: MatSelect;
  @ViewChild('allStatusSelected') private allStatusSelected: MatOption;
  @ViewChild('statusSelect') statusSelect: MatSelect;

  @ViewChild('suppliesAllUsersSelected')
  private suppliesAllUsersSelected: MatOption;
  @ViewChild('suppliesUserSelect') suppliesUserSelect: MatSelect;
  @ViewChild('suppliesAllStatusSelected')
  private suppliesAllStatusSelected: MatOption;
  @ViewChild('suppliesStatusSelect') suppliesStatusSelect: MatSelect;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchValue: string[] = [];
  step: boolean = false;
  // @ViewChild("isAssinged",{ static: false })isAssinged: any
  arrDisplayedColumns: string[] = [
    'action',
    'option',
    'defaultDocumentId',
    'wipId',
    'defaultPatientId',
    'isStat',
    'branch',
    'patientName',
    'wipStatus',
    'agentName',
    'csr',
    'assignedBy',
    'assignedDate',
    //'comments',
  ];
  public arrDisplayedColumnsSupplies: string[] = [
    'action',
    'option',
    'sDefaultSaleOrderId',
    'sWipId',
    'sDefaultPatientId',
    'isStat',
    'sbranch',
    'sPatientName',
    'sWipStatus',
    'sAgentName',
    'sCsr',
    'sAssignedBy',
    'sAssignedDate',
  ];
  subscription$: Subscription[] = [];
  userId: string = defaultGuid;
  arrDisplayedCommentsColumns: string[] = [
    'comments',
    'userName',
    'commentsDate',
  ];

  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  tenantId: string = '';
  branchForm: FormGroup;
  organizationUnitName: string;
  maxCount: number = 5;

  constructor(
    public workInProgressService: WorkInProgressService,
    public patientPersonalService: PatientPersonalService,
    private patientDropdownService: PatientMasterDropdownService,
    private fb: FormBuilder,
    public tableService: TableService,
    private matDialog: MatDialog,
    private router: Router,
    private title: Title,
    private datepipe: DatePipe,
    private dateValidator: DateValidator,
    private toastr: ToastrService,
    private userService: UserService,
    private branchService: OrganizationUnitService
  ) {
    this.drpWipReportType = wipUserTaskFilterOptions?.map((e) => {
      let x = { value: e.value, key: e.key };
      return x;
    });
    this.alwaysShowCalendars = true;
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      firstDay: 1,
    };
    this.ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      // 'Current week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      // 'Next weekend': [this.getNextSaturday(), this.getNextSunday()],
      // 'Next 3 days': [moment().add(1, 'days'), moment().add(3, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      // 'Current day': [moment(), moment()],
      // 'Next 2 days': [moment().add(1, 'days'), moment().add(2, 'days')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
  }

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.initializeForms();
    this.wipForm = this.fb.group({
      drpWipReportType: new FormControl('', Validators.required),
    });
    this.getWipStatusDropdown();
    this.wipForm.patchValue({
      drpWipReportType: this.drpWipReportType.filter(
        (a) => a.value == wipUserTaskFilter.currentTasks
      )?.[0]?.key,
    });

    this.getFilterDropdowns();
    if (this.activeTab == 'NewSetup') {
      this.searchNewSetupWIPList();
      this.checkNewSetupCount();
    }

    this.branchForm = this.fb.group({
      drpBranch: new FormControl(''),
      txtBranch: new FormControl(''),
    });

    this.getBranchList();
  }
  ngAfterContentInit() {
    this.title.setTitle('Qsecure | WIP List');
    this.userId = localStorage?.getItem('userId') ?? defaultGuid;
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  initializeForms() {
    this.wipSearchForm = this.fb.group({
      documentId: new FormControl(''),
      wipId: new FormControl(''),
      defaultPatientId: new FormControl(''),
      assignedBy: new FormControl(''),
      csr: new FormControl(''),
      branch: new FormControl(''),
      agentName: new FormControl(''),
      assignedDate: new FormControl('', this.dateValidator.dateVaidator),
      wipStatus: new FormControl(''),
      patientName: new FormControl(''),
      //defaultSaleOrderId: new FormControl(''),
    });
    this.wipFilterForm = this.fb.group({
      txtUser: new FormControl(''),
      txtUserFilter: new FormControl(''),
      txtStatus: new FormControl(''),
      txtStatusFilter: new FormControl(''),
      txtWipStatus: new FormControl(''),
      txtSuppliesUserFilter: new FormControl(''),
    });

    this.wipSuppliesSearchForm = this.fb.group({
      sDocumentId: new FormControl(''),
      sWipId: new FormControl(''),
      sDefaultPatientId: new FormControl(''),
      sAssignedBy: new FormControl(''),
      sCsr: new FormControl(''),
      sbranch: new FormControl(''),
      sAgentName: new FormControl(''),
      sAssignedDate: new FormControl('', this.dateValidator.dateVaidator),
      sWipStatus: new FormControl(''),
      sPatientName: new FormControl(''),
      sDefaultSaleOrderId: new FormControl(''),
    });

    this.wipSuppliesFilterForm = this.fb.group({
      txtSuppliesUser: new FormControl(''),
      txtSuppliesUserFilter: new FormControl(''),
      txtSuppliesStatus: new FormControl(''),
      txtSuppliesStatusFilter: new FormControl(''),
    });
  }
  rangeClicked(e) {}
  datesUpdateds(e) {
    if (e.startDate != null || e.endDate != null) {
      this.dateRangStatus = true;
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
      this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
      //this.searchNewSetupWIPList()
    } else {
      this.fromDate = null;
      this.toDate = null;
      //this.searchNewSetupWIPList()
      this.dateRangStatus = false;
    }
  }
  getNextSaturday() {
    const dayINeed = 6; // for Saturday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }
  getNextSunday() {
    const dayINeed = 7; // for Sunday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }

  //Search Functionality For New Setup Table List
  searchNewSetupWIPList() {
    const valueChanges = this.wipSearchForm.valueChanges
      .pipe(
        startWith({
          documentId: '',
          wipId: '',
          defaultPatientId: '',
          patientName: '',
          wipStatus: '',
          assignedBy: '',
          csr: '',
          agentName: '',
          assignedDate: '',
        }),
        tap((x) => {
          this.isLoading = true;
          this.setNewSetupTableData([]);
        }),
        filter((e) => e && this.wipSearchForm?.valid),
        debounceTime(300),
        map((response) => {
          this.isLoading = true;
          let status: any = null;

          const sValue: clsWipSearchDTO = {
            defaultDocumentId: response?.documentId?.trim()?.toLowerCase(),
            wipId: response?.wipId?.trim()?.toLowerCase(),
            defaultPatientId: response?.defaultPatientId?.trim()?.toLowerCase(),
            wipStatus: response?.wipStatusName?.trim()?.toLowerCase(),
            assignedBy: response?.assignedBy?.trim()?.toLowerCase(),
            csrName: response?.csr?.trim()?.toLowerCase(),
            agentName: response?.agentName?.trim()?.toLowerCase(),
            assignedDate: response?.assignedDate?.trim()?.toLowerCase(),
            patientName: response?.patientName?.trim()?.toLowerCase(),
            isSupplies: 0,
            user:
              this.wipFilterForm.value.txtStatus == null ||
              this.wipFilterForm.value.txtStatus == ''
                ? []
                : this.wipFilterForm.value.txtStatus.filter(
                    (a) => a != 0 && a != undefined
                  ),
            status:
              this.wipFilterForm.value.txtUser == null ||
              this.wipFilterForm.value.txtUser == ''
                ? []
                : this.wipFilterForm.value.txtUser.filter(
                    (a) => a != 0 && a != undefined
                  ),
          };

          return sValue;
        }),
        switchMap((sValue) =>
          this.workInProgressService.wipTaskList(this.userId, sValue)
        )
      )
      .subscribe(
        (response) => {
          this.isLoading = false;
          response =
            response.length > 0
              ? response?.map((user) => ({ ...user, isSelected: false }))
              : [];
          this.setNewSetupTableData(response);
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
        }
      );
    this.subscription$.push(valueChanges);
  }
  //Filter New Setup WIP List
  filterNewSetupWIPList() {
    let userId = localStorage.getItem('userId') ?? defaultGuid;
    let searchDTO: clsWipSearchDTO = {
      defaultPatientId: '',
      patientName: '',
      defaultDocumentId: '',
      wipStatus: null,
      wipId: '',
      csrName: '',
      agentName: '',
      assignedBy: '',
      fromDate: '',
      toDate: '',
      assignedDate: '',
      isSupplies: 0,
      status:
        this.wipFilterForm.value.txtStatus == null ||
        this.wipFilterForm.value.txtStatus == ''
          ? []
          : this.wipFilterForm.value.txtStatus.filter(
              (a) => a != 0 && a != undefined
            ),
      user:
        this.wipFilterForm.value.txtUser == null ||
        this.wipFilterForm.value.txtUser == ''
          ? []
          : this.wipFilterForm.value.txtUser.filter(
              (a) => a != 0 && a != undefined
            ),
    };

    const wipList = this.workInProgressService
      .wipTaskList(userId, searchDTO)
      .subscribe(
        (response) => {
          this.isLoading = false;
          response =
            response.length > 0
              ? response?.map((user) => ({ ...user, isSelected: false }))
              : [];
          this.setNewSetupTableData(response);
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'error',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(wipList);
  }
  //Set New Setup Table Data
  setNewSetupTableData(data: any) {
    this.newSetupList = data;

    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.sort.disableClear = true;
    this.dataSource.paginator = this.paginator;
  }
  //All rows select in New Setup
  selectNewSetupAll(event: any) {
    this.dataSource.data =
      this.dataSource.data.length > 0
        ? this.dataSource?.data.map((user) => ({
            ...user,
            isSelected: event.checked,
          }))
        : [];
    this.newSetupList = this.dataSource.data;
    this.checkNewSetupCount();
  }
  //Checkbox Select Event in New Setup Table
  selectNewSetupWip(event: any, wipId: string) {
    if (
      this.newSetupList?.filter((a) => a.isSelected == true).length <
        this.maxCount ||
      event.checked == false
    ) {
      let tableValue = this.newSetupList?.find((a) => a.id == wipId);
      tableValue.isSelected = event.checked;
      this.checkNewSetupCount();
    } else if (
      this.newSetupList?.filter((a) => a.isSelected == true).length ===
        this.maxCount &&
      event.checked === true
    ) {
      this.toastr.error('Maximum Limit Reached');
      let tableValue = this.newSetupList?.find((a) => a.id == wipId);
      tableValue.isSelected = false;
      this.dataSource.data = [];
      this.setNewSetupTableData(this.newSetupList);
    }
  }
  //Checkbox Select Validation for New Setup
  checkNewSetupCount() {
    this.newSetupCount = this.newSetupList?.filter(
      (item) => item?.isSelected === true
    )?.length;
  }
  //Reset New Setup Form
  resetNewSetupForm() {
    this.wipFilterForm.reset();
    this.searchNewSetupWIPList();
  }
  //New Setup Multiple WIP Allocation Popup
  openMultipleWIPAllocationModal() {
    const dialogRef = this.matDialog.open(WipAllocationModalComponent, {
      disableClose: true,
      //width: '60vw',
      data: {
        patientId:
          this.newSetupList
            ?.filter((item) => item?.isSelected === true)
            ?.map((item) => item?.patientId) ?? [],
        wipId:
          this.newSetupList
            ?.filter((item) => item?.isSelected === true)
            ?.map((item) => item?.id) ?? [],
        defaultWipId:
          this.newSetupList
            ?.filter((item) => item?.isSelected === true)
            ?.map((item) => item?.wipId) ?? [],
      },
    });
    dialogRef.afterClosed().subscribe((a) => {
      this.searchNewSetupWIPList();
      this.suppliesSearchWipList();
    });
  }

  //Supplies Search List
  suppliesSearchWipList() {
    this.getSuppliesWipStatusDropdown();
    const valueChanges = this.wipSuppliesSearchForm.valueChanges
      .pipe(
        startWith({
          sDefaultSaleOrderId: '',
          sWipId: '',
          sDefaultPatientId: '',
          sPatientName: '',
          sAssignedBy: '',
          sCsr: '',
          sAgentName: '',
          sAssignedDate: '',
          sWipStatus: '',
        }),
        tap((x) => {
          this.isLoadingSupplies = true;
          this.setSuppliesTableData([]);
        }),
        filter((e) => e && this.wipSearchForm?.valid),
        debounceTime(300),
        map((response) => {
          this.isLoadingSupplies = true;
          let status: any = null;
          const sValue: clsWipSearchDTO = {
            defaultSaleOrderId: response?.sDefaultSaleOrderId
              .trim()
              .toLowerCase(),
            wipId: response?.sWipId?.trim()?.toLowerCase(),
            defaultPatientId: response?.sDefaultPatientId
              ?.trim()
              ?.toLowerCase(),
            wipStatus: response?.wipStatusName?.trim()?.toLowerCase(),
            assignedBy: response?.sAssignedBy?.trim()?.toLowerCase(),
            csrName: response?.sCsr?.trim()?.toLowerCase(),
            agentName: response?.sAgentName?.trim()?.toLowerCase(),
            assignedDate: response?.sAssignedDate?.trim()?.toLowerCase(),
            patientName: response?.sPatientName?.trim()?.toLowerCase(),
            isSupplies: 1,
            user:
              this.wipSuppliesFilterForm.value.txtSuppliesUser == null ||
              this.wipSuppliesFilterForm.value.txtSuppliesUser == ''
                ? []
                : this.wipSuppliesFilterForm.value.txtSuppliesUser.filter(
                    (a) => a != 0 && a != undefined
                  ),

            status:
              this.wipSuppliesFilterForm.value.txtSuppliesStatus == null ||
              this.wipSuppliesFilterForm.value.txtSuppliesStatus == ''
                ? []
                : this.wipSuppliesFilterForm.value.txtSuppliesStatus.filter(
                    (a) => a != 0 && a != undefined
                  ),
          };

          return sValue;
        }),
        switchMap((sValue) =>
          this.workInProgressService.wipTaskList(this.userId, sValue)
        )
      )
      .subscribe(
        (response) => {
          this.isLoadingSupplies = false;
          response =
            response.length > 0
              ? response?.map((user) => ({ ...user, isSelected: false }))
              : [];
          this.setSuppliesTableData(response);
        },
        (err) => {
          this.isLoadingSupplies = false;
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'error',
          //   text: data?.error?.error?.message,
          // });
        }
      );

    this.subscription$.push(valueChanges);
  }
  //Filter Supplies List
  filterSuppliesList() {
    this.isLoadingSupplies = true;
    let userId = localStorage.getItem('userId') ?? defaultGuid;
    let searchDTO: clsWipSearchDTO = {
      defaultPatientId: '',
      patientName: '',
      defaultDocumentId: '',
      wipStatus: null,
      wipId: '',
      csrName: '',
      agentName: '',
      assignedBy: '',
      fromDate: '',
      toDate: '',
      assignedDate: '',
      isSupplies: 1,
      user:
        this.wipSuppliesFilterForm.value.txtSuppliesUser == null ||
        this.wipSuppliesFilterForm.value.txtSuppliesUser == ''
          ? []
          : this.wipSuppliesFilterForm.value.txtSuppliesUser.filter(
              (a) => a != 0 && a != undefined
            ),

      status:
        this.wipSuppliesFilterForm.value.txtSuppliesStatus == null ||
        this.wipSuppliesFilterForm.value.txtSuppliesStatus == ''
          ? []
          : this.wipSuppliesFilterForm.value.txtSuppliesStatus.filter(
              (a) => a != 0 && a != undefined
            ),
    };

    const wipList = this.workInProgressService
      .wipTaskList(userId, searchDTO)
      .subscribe(
        (response) => {
          this.isLoadingSupplies = false;
          response =
            response.length > 0
              ? response?.map((user) => ({ ...user, isSelected: false }))
              : [];
          this.setSuppliesTableData(response);
        },
        (err) => {
          this.isLoadingSupplies = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'error',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(wipList);
  }
  //Set Supplies Table Data
  setSuppliesTableData(data: any) {
    this.suppliesList = data;
    this.dataSourceSupplies = new MatTableDataSource(data);
    this.dataSourceSupplies.sort = this.sort;
    this.dataSourceSupplies.sort.disableClear = true;
    this.dataSourceSupplies.paginator = this.paginator;
  }
  //Select All Rows in Supplies Table
  selectSuppliesAll(event: any) {
    this.dataSourceSupplies.data =
      this.dataSourceSupplies.data.length > 0
        ? this.dataSourceSupplies?.data.map((user) => ({
            ...user,
            isSelected: event.checked,
          }))
        : [];
    this.suppliesList = this.dataSourceSupplies.data;
    this.checkSuppliesCount();
  }
  //Checkbox Select Event in Supplies Table
  selectSuppliesWip(event: any, wipId: string) {
    if (
      this.suppliesList?.filter((a) => a.isSelected == true).length <
        this.maxCount ||
      event.checked == false
    ) {
      let tableValue = this.suppliesList?.find((a) => a.id == wipId);
      tableValue.isSelected = event.checked;
      this.checkSuppliesCount();
    } else if (
      this.suppliesList?.filter((a) => a.isSelected == true).length ===
        this.maxCount &&
      event.checked === true
    ) {
      this.toastr.error('Maximum Limit Reached');
      let tableValue = this.suppliesList?.find((a) => a.id == wipId);
      tableValue.isSelected = false;
      this.dataSourceSupplies.data = [];
      this.setSuppliesTableData(this.suppliesList);
    }
  }
  //Validation for Checkbox
  checkSuppliesCount() {
    this.suppliesCount = this.suppliesList?.filter(
      (item) => item?.isSelected === true
    )?.length;

  }
  //Reset Supplies List
  resetSuppliesForm() {
    this.wipSuppliesFilterForm.reset();
    this.suppliesSearchWipList();
  }

  openWipDetailsModal(id: string, defaultWipId: string) {
    const dialogRef = this.matDialog.open(WipListModalComponent, {
      disableClose: true,
      data: {
        id: id ?? defaultGuid,
        defaultWipId: defaultWipId,
      },
    });
    dialogRef.afterClosed().subscribe((a) => {});
  }

  changeTab(tab: string) {
    this.activeTab = tab;
    if (this.activeTab == 'NewSetup') {
      this.searchNewSetupWIPList();
      this.checkNewSetupCount();
    } else if (this.activeTab == 'Supplies') {
      this.suppliesSearchWipList();
      this.checkSuppliesCount();
    }
  }
  //To get WIP Allocation popup
  openWipAllocationModal(
    wipId: string,
    docId: string,
    patientId: string,
    defaultWipId: string
  ) {
    const dialogRef = this.matDialog.open(WipAllocationModalComponent, {
      disableClose: true,
      //width: '60vw',
      data: {
        //documentId: docId ?? defaultGuid,
        patientId: [patientId] ?? [],
        wipId: [wipId] ?? [],
        defaultWipId: [defaultWipId] ?? [],
      },
    });
    dialogRef.afterClosed().subscribe((a) => {
      this.searchNewSetupWIPList();
      this.suppliesSearchWipList();
    });
  }

  //Update WIP Completed Status
  updateWipComplete(wipId: string) {
    Swal.fire({
      title: 'Are you sure you want to Complete?',
      text: 'Approve!',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.value) {
        const assignedTasks = this.workInProgressService
          .updateWipStatus(wipId)
          .subscribe(
            (response) => {
              this.searchNewSetupWIPList();
              //this.getSuppliesWipList();
            },
            (err) => {
              this.toastr.success('Updated Successfully');
              this.searchNewSetupWIPList();
              //this.getSuppliesWipList();
            }
          );
      }
    });
  }

  // To view WIP Details
  viewWIPDetailsById(wipData: WIPList) {
    const dialogRef = this.matDialog.open(TaskViewerComponent, {
      disableClose: true,
      data: {
        WIPId: wipData?.id ?? defaultGuid,
        documentId: wipData?.documentId ?? defaultGuid,
        defaultWIPId: wipData.wipId,
      },
    });
    const closeDialog$ = dialogRef.afterClosed().subscribe(
      (result) => {},
      (err) => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      }
    );
    this.subscription$.push(closeDialog$);
  }

  statusUpdate(id: string = defaultGuid, value: string = '') {
    this.isLoading = true;
    this.workInProgressService
      .get(id)
      .pipe(
        map((x) => {
          x.wipStatus = <wipStatus>value;
          return x;
        }),
        switchMap((x) => this.workInProgressService.update(id, x))
      )
      .subscribe(
        (response) => {
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'error',
          //   text: data?.error?.error?.message,
          // });
        }
      );
  }

  getFilterDropdowns() {
    const userList = this.userService.getUsersByTenant().subscribe(
      (response) => {
        this.lstFilterUsers = response;

        this.filteredUsers = this.wipFilterForm
          ?.get('txtUserFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstFilterUsers?.filter((option) =>
                option?.userDesignation
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );

        this.lstSuppliesFilterUsers = response;
        this.suppliesFilteredUsers = this.wipSuppliesFilterForm
          ?.get('txtSuppliesUserFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstSuppliesFilterUsers?.filter((option) =>
                option?.userDesignation
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (err) => {}
    );
    this.subscription$.push(userList);

    const statusList = this.workInProgressService
      .getWipStatus()
      .subscribe((response) => {
        this.lstFilterStatus = response;
        this.filteredStatus = this.wipFilterForm
          ?.get('txtStatusFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstFilterStatus?.filter((option) =>
                option?.displayName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );

        this.lstSuppliesFilterStatus = response;
        this.suppliesFilteredStatus = this.wipFilterForm
          ?.get('txtSuppliesStatusFilter')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstSuppliesFilterStatus?.filter((option) =>
                option?.displayName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });

    this.subscription$.push(statusList);
  }
  //Select All Users
  toggleAllUsersSelection() {
    if (this.allUsersSelected.selected) {
      this.userSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.userSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }
  //Select All Status
  toggleAllStatusSelection() {
    if (this.allStatusSelected.selected) {
      this.statusSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.statusSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  //Select All Users
  toggleAllSuppliesUsersSelection() {
    if (this.suppliesAllUsersSelected.selected) {
      this.suppliesUserSelect.options.forEach((item: MatOption) =>
        item.select()
      );
    } else {
      this.suppliesUserSelect.options.forEach((item: MatOption) =>
        item.deselect()
      );
    }
  }
  //Select All Status
  toggleAllSuppliesStatusSelection() {
    if (this.suppliesAllStatusSelected.selected) {
      this.suppliesStatusSelect.options.forEach((item: MatOption) =>
        item.select()
      );
    } else {
      this.suppliesStatusSelect.options.forEach((item: MatOption) =>
        item.deselect()
      );
    }
  }

  getWipStatusDropdown() {
    //Plan Types Dropdown
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.WipStatus,
    ];
    const patientDropdown =
      this.patientDropdownService.getPatientDropdownsByInput(
        patientDropdownInput
      );
    const combinedObs = forkJoin([patientDropdown]);
    combinedObs.subscribe((response) => {
      const responseData: PatientMasterDropdownDTO = response?.[0];
      this.drpWipStatus = responseData?.lstWipStatus;

      this.filteredWipStatus = this.wipFilterForm
        .get('txtWipStatus')
        ?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpWipStatus?.filter((option) =>
              option?.wipStatus
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
    });
  }

  getSuppliesWipStatusDropdown() {
    //Plan Types Dropdown
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.WipStatus,
    ];
    const patientDropdown =
      this.patientDropdownService.getPatientDropdownsByInput(
        patientDropdownInput
      );
    const combinedObs = forkJoin([patientDropdown]);
    combinedObs.subscribe((response) => {
      const responseData: PatientMasterDropdownDTO = response?.[0];
      this.drpWipStatus = responseData?.lstWipStatus;

      this.filteredWipStatus = this.wipSuppliesFilterForm
        .get('txtSuppliesStatusFilter')
        ?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpWipStatus?.filter((option) =>
              option?.wipStatus
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
    });
  }

  allBranchSelect() {
    if (this.selectAllBranch.selected) {
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getBranchList() {
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe(
        (response) => {
          response?.forEach((element) => {
            this.drpBranch.push({
              id: element.id,
              organizationUnitName: element.organizationUnitName,
            });
          });

          this.brnachList = this.branchForm
            ?.get('txtBranch')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpBranch?.filter((option) =>
                  option?.organizationUnitName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getBranchList);
  }
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
}

export interface Branch {
  id?: string;
  organizationUnitName?: string;
}
