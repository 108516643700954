import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { dateFormatter } from 'projects/patient/src/app/user-task-list/user-task-list.component';
import { DocumentMaxandTypeLimits } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import Swal from 'sweetalert2';
import { BatchFileUploadsDTO, CreateUpdateBatchFileUploadsDTO, FileUploadsDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/dto';
import { FileUploadService } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/file-upload.service';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import { IdentityUserDto } from '../admin-proxy/platform-app-management/volo/abp/identity/models';
import { defaultGuid } from '../document-perviewer/document-perviewer.component';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-add-document-bin',
  templateUrl: './add-document-bin.component.html',
  styleUrls: ['./add-document-bin.component.scss']
})
export class AddDocumentBinComponent implements OnInit {
  fileId: string = "";
  addDocumentbinModalTitle: string = "Upload Fax Documents";
  strPageType: string = "selectedDocumentList";
  organizationUnitId: string = "";
  arrSelectedDocuments: any[] = [];
  arrUsersList: IdentityUserDto[] = [];
  documentBinForm: FormGroup = new FormGroup({});
  isShowSpinner: boolean = false;
  showErrorBasedOnSize: boolean = false;
  showErrorForFileType: boolean = false;
  showErrorForFileLength: boolean = false;
  userId: string = "";
  tenantId: string = "";
  chkdisableButton: boolean = false;
  batchId: string = "";
  notesText ='';
  selectedFileUploadsData: Partial<FileUploadsDTO> | null = null;
  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    @Inject(MAT_DIALOG_DATA) public data: { batchId: string, fileId: string },
    private table: TableService,
    public userService: UserService,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    this.fileId && this.batchId && this.getDeatilsByBlobDocumentName(this.fileId);
  }

  ngOnInit(): void {

    this.resetDocumentBinForm();
    this.batchId = this.data?.batchId ?? "";
    this.fileId = this.data?.fileId ?? "";
    this.fileId && this.batchId && this.getDeatilsByBlobDocumentName(this.fileId);
    this.table.setSelectedDocumentList(this.arrSelectedDocuments);
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.organizationUnitId = localStorage.getItem("initialOrganizationUnitId") ?? "";

    this.documentBinForm = this.fb.group({
      documentFile: new FormControl(null),
      txtUser: new FormControl(null),
      chkStat: new FormControl(""),
      txtNote: new FormControl("")
    })

    this.userId = localStorage.getItem("userId") ?? "";
    this.getUsersList();

  }

  private getUsersList() {
    this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;
      this.fileId && this.batchId && this.getDeatilsByBlobDocumentName(this.fileId);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  getDeatilsByBlobDocumentName(strDocumentId: string) {
    if (strDocumentId !== "" && strDocumentId !== null && strDocumentId !== undefined) {
      this.fileUploadService.getFileBatchByIdByBatchId(this.batchId).subscribe(response => {
        const responseBatchData: BatchFileUploadsDTO = response;
        if (responseBatchData && responseBatchData?.listFileUploads && strDocumentId) {
          const selectedFileUploadsData: Partial<FileUploadsDTO> | null = responseBatchData?.listFileUploads?.filter(v => v?.fileId === strDocumentId)[0] ?? null;
          if (selectedFileUploadsData) {
            this.documentBinForm.patchValue({
              txtUser: selectedFileUploadsData?.assignedTo ?? "",
              chkStat: selectedFileUploadsData?.isStat === 1 ? true : false,
              txtNote: selectedFileUploadsData?.tags ?? "",
            })
            this.selectedFileUploadsData = selectedFileUploadsData || null;
            this.addDocumentbinModalTitle = "Edit Sub Batch Documents";
            this.selectedFileUploadsData.assignedTo = this.getUserName(selectedFileUploadsData?.assignedTo ?? "");
            this.selectedFileUploadsData.assignedTime = dateFormatter(selectedFileUploadsData?.assignedTime ?? "");
          }
        }
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }

  }
  private getUserName(value: string) {
    if (value && typeof value === "string" && value?.length >= 36) {
      return this.arrUsersList?.filter(u => u?.id + '/' + u?.userName === value)[0]?.userName ?? "";
    }
    return ""
  }
  saveUploadedDocuments(files: any) {
    this.chkdisableButton = true;
    if (this.fileId === "") {
      if (files.length === 0) {
        return;
      }
      this.saveFunction();
    } else {
      this.editFunction();
    }
  }

  resetDocumentBinForm() {
    this.documentBinForm.reset({
      txtUser: null,
      chkStat: false,
      txtNote: ""
    });
    this.selectedFileUploadsData = null;
    this.showErrorForFileType = false;
    this.showErrorForFileLength = false;
    this.showErrorBasedOnSize = false;
    this.arrSelectedDocuments = [];
    this.fileId = "";
    this.batchId = "";
    this.addDocumentbinModalTitle = "Upload Fax Documents";
    this.table.setSelectedDocumentList(this.arrSelectedDocuments);
  }
  getSelectedFilesDetails(files: any) {
    this.arrSelectedDocuments = [];
    this.table.setSelectedDocumentList([]);
    const fileResult: { base64Value: string; fileName: string; fileType: string; fileSize: string; }[] = [];
    for (let index = 0; index < files.length; index++) {
      if (files[index].size > DocumentMaxandTypeLimits.documentFileSizeLimit) {
        this.showErrorBasedOnSize = true;
        this.showErrorForFileType = false;
        this.showErrorForFileLength = false;
        this.arrSelectedDocuments = [];
        return;
      };
      if (files[index].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
        this.showErrorForFileType = true;
        this.showErrorBasedOnSize = false;
        this.showErrorForFileLength = false;
        this.arrSelectedDocuments = [];
        return;
      };
      if (files.length >= 2) {
        this.showErrorForFileLength = true;
        this.showErrorForFileType = false;
        this.showErrorBasedOnSize = false;
        this.arrSelectedDocuments = [];
        return;
      };
      this.showErrorForFileType = false;
      this.showErrorBasedOnSize = false;
      this.showErrorForFileLength = false;
      let fileResultValue: string = "";
      const fileName: string = files[index].name;
      const m1fileName: string = fileName.replace(".pdf", "");
      const m2fileName: string = m1fileName.replace(".pdf", "");
      const reader = new FileReader();
      reader.readAsDataURL(files[index]);
      let modifiedElement: { base64Value: string, fileName: string, fileType: string, fileSize: string };
      reader.onload = () => {
        fileResultValue = reader?.result?.slice(28)?.toString() ?? "";
        modifiedElement = {
          base64Value: fileResultValue,
          fileName: m2fileName,
          fileType: files[index].type === "application/pdf" ? "PDF" : "",
          fileSize: this.formatFileSize(files[index].size, 3)
        };
        fileResult.push(modifiedElement);
      }
      this.arrSelectedDocuments = fileResult;
    }
  }
  formatFileSize(bytes: number, decimalPoint: number) {
    if (bytes == 0) return '0 Bytes';
    if (isNaN(bytes)) return '0 Bytes';
    const k = 1024;
    const dm = decimalPoint || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  saveFunction() {
    this.isShowSpinner = true;
    const listFileUploads: any[] = [];
    let batchFileUploadsInput: CreateUpdateBatchFileUploadsDTO;
    this.arrSelectedDocuments.forEach(element => {
      listFileUploads.push({
        fileId: defaultGuid,
        uploadedFileName: element?.fileName,
        blobName: "",
        tags: [this.documentBinForm.value.txtNote],
        uploadedFileStatus: "New",
        assignedTo: this.documentBinForm.value.txtUser === "" || this.documentBinForm.value.txtUser === null ? "" : this.documentBinForm.value.txtUser,
        assignedBy: this.userId ?? defaultGuid,
        assignedTime: this.documentBinForm.value.txtUser === "" || this.documentBinForm.value.txtUser === null ? "" : new Date().toISOString(),
        extractedData: { extractedfields: null },
        sortedData: [],
        listIdentifiedPatients: null ?? [],
        approvedPatientId: defaultGuid,
        approvedBy: "",
        updatedBy: "",
        updatedOn: null ?? "",
        fileContentAsBase64String: element?.base64Value,
        isStat: this.documentBinForm.value.chkStat === true ? 1 : 0,
      });
    });
    batchFileUploadsInput = {
      type: "",
      uploadedFrom: "Application",
      uploadedBy: this.userId ?? defaultGuid,
      uploadedOn: new Date().toISOString(),
      listFileUploads: listFileUploads ?? null,
      batchStatus: "New",
      archived: false,
      archivedBy: "",
      archivedDate: "",
    };
    this.fileUploadService.fileBatchUploadByBatchFileUploadsInput(batchFileUploadsInput).subscribe(value => {
      this.isShowSpinner = false;
      this.toastr.success('Saved Successfully','Success')
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).finally(() => {
        this.resetDocumentBinForm();
        this.dialog.closeAll();
      this.chkdisableButton = false;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.isShowSpinner = false;
      this.chkdisableButton = false;
    })
  }


  editFunction() {
    const listFileUploadsData: Partial<FileUploadsDTO> = this.selectedFileUploadsData;
    const fileUploadsInput: FileUploadsDTO = {
      fileId: listFileUploadsData?.fileId,
      uploadedFileName: listFileUploadsData?.uploadedFileName,
      isStat: this.documentBinForm.value.chkStat === true ? 1 : 0,
      blobName: listFileUploadsData?.blobName,
      tags: listFileUploadsData?.tags ?? [],
      uploadedFileStatus: listFileUploadsData?.uploadedFileStatus,
      assignedTo: this.documentBinForm.value.txtUser === "" || this.documentBinForm.value.txtUser === null ? "" : this.documentBinForm.value.txtUser,
      assignedTime: this.documentBinForm.value.txtUser === "" || this.documentBinForm.value.txtUser === null ? "" : new Date().toISOString(),
      assignedBy: this.userId ?? defaultGuid,
      extractedData: listFileUploadsData?.extractedData,
      sortedData: listFileUploadsData?.sortedData ?? [],
      listIdentifiedPatients: listFileUploadsData?.listIdentifiedPatients ?? [],
      approvedPatientId: listFileUploadsData?.approvedPatientId,
      approvedBy: listFileUploadsData?.approvedBy,
      updatedBy: listFileUploadsData?.updatedBy,
      updatedOn: listFileUploadsData?.updatedOn,
      reasonForNotExtracted: listFileUploadsData?.reasonForNotExtracted,
      lstIndex: listFileUploadsData?.lstIndex,
    };

    this.fileUploadService.updateFileOnBatchByBatchIdAndFileIdAndFileUploadsInput(this.batchId, listFileUploadsData?.fileId ?? "", fileUploadsInput).subscribe(value => {
      this.toastr.success('Saved Successfully','Success')
        this.resetDocumentBinForm();
        this.dialog.closeAll();
      this.chkdisableButton = false;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.chkdisableButton = false;
    })
  }
}

