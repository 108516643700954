<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h3 class="font-weight-bolder mb-0">Shipping Approval Steps</h3>
        <div class=" page-title-box page-title-right">
          <ol class="breadcrumbs m-0 align-items-center">

            <li class="mb-1 mr-2">
              <button matTooltip="Click To Goback to Approval List" mat-button class="buttonColor font-size-10"
                (click)="backToApprovalList()">
                <i class="fa fa-backward mr-1" ></i> Back To Approval List
              </button>
            </li>

            <li class="breadcrumb-item">Orders</li>
            <li class="breadcrumb-item ">Shipping Approval Steps</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <app-document-tab></app-document-tab> -->
<!-- <div class="row pt-1">
  <div class="col-12" style="margin-top: -19px;">
    <ul ngbNav #nav="ngbNav" (navChange)="onNavChange($event)" justify="justified"
      class="alert alert-success nav nav-pills nav-fill">
      <li ngbNavItem="Document" class="nav-item">
        <a ngbNavLink>Document</a>
        <ng-template ngbNavContent>
          <app-order-document (patientIdEmitter)="getPatientId($event)"
            (nextTabMoveOnSaveEmitter)="changeNextTab($event)" [patientID]='patientID' [strPageType]="strPageType"
            [orderId]='orderId'></app-order-document>
        </ng-template>
      </li>
      <li ngbNavItem="Verification" class="nav-item">
        <a ngbNavLink>Insurance Verification</a>
        <ng-template ngbNavContent>
          <app-shipping-verification [orderId]='orderId' (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
            [patientId]="patientID" (saveInsuranceVerificationFormState)="onSaveInsuranceVerificationData($event)">
          </app-shipping-verification>
        </ng-template>
      </li>

      <li ngbNavItem="Authorization">
        <a ngbNavLink>Authorization</a>
        <ng-template ngbNavContent>
          <app-shipping-auth (nextTabMoveOnSaveEmitter)="changeNextTab($event)" [patientId]="patientID"
            [orderId]='orderId' (saveAuthorizationFormState)="onSaveAuthorizationData($event)"></app-shipping-auth>
        </ng-template>
      </li>
      <li ngbNavItem="Ledger">
        <a ngbNavLink>Patient Ledger</a>
        <ng-template ngbNavContent>
          <app-shipping-ledger [patientId]="patientID" (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
            [orderId]='orderId' (savePatientLedgerFormState)="onSavePatientLedgerData($event)"></app-shipping-ledger>
        </ng-template>
      </li>
      <li ngbNavItem="History">
        <a ngbNavLink>History</a>
        <ng-template ngbNavContent>
          <app-shipping-history [orderId]='orderId' (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
            (saveHistoryFormState)="onSaveHistoryData($event)"></app-shipping-history>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>  -->





<div class="row" style="margin-top: -27px;">
  <div class="col-xl-2 pr-0">
    <div class="card card-body">
      <div class="text-center">
        <span style="font-size: larger;"><b class="colored-heading">Patient ID </b></span>
        <h3 style="margin: 0 0 4px;"><b>{{ lblDefaultPatientId }}</b></h3>

      </div>
      <div class="text-center">
        <span style="font-size: larger;"><b class="colored-heading">Patient Name </b></span>
        <h3 style="margin: 0 0 4px;"><b>{{ lblPatientName }}</b></h3>
      </div>
      <div class="table-responsive mt-2">
        <table class="table table-centered">
          <tbody>
            <!-- Newly added Param -->
            <tr>
              <td class="blinking">
                <p class="mb-0 text-center">CurrentStatus</p> <br>
                <h5 class="btm text-center">{{ currentOrderStatus }}</h5>
              </td>
            </tr>
            <!-- Newly added Param -->
            <tr>
              <td>
                <p class="mb-0 text-center">Location</p> <br>
                <h5 class="btm text-center">{{ strLocation }}</h5>
              </td>

            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Gender</p> <br>
                <h5 class="btm text-center">{{ lblPatientGender }}</h5>
              </td>
            <tr>
              <td>

                <p class="mb-0 text-center">DOB</p> <br>
                <h5 class="btm text-center">{{ lblPatientDOB }}</h5>
              </td>
            </tr>
            <!-- <tr>
              <td>
                <p class="mb-0 text-center">Height</p> <br>
                <h5 class="btm text-center">{{ lblPatientHeight | mask: "0'00''" }}</h5>
              </td>
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Weight(Lbs)</p> <br>
                <h5 class="btm text-center">{{ lblPatientWeight }}</h5>
              </td>
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Branch Office </p> <br>
                <h5 class="btm text-center">{{ lblBranchOffice }}</h5>
              </td>
            </tr>
            <tr>
              <td>
                <p class="mb-0  text-center">Account </p><br>
                <h5 class="btm text-center">{{ lblAccountNumber }}</h5>
              </td>
            </tr>-->
            <tr>
              <td>
                <p class="mb-0  text-center">SSN</p><br>
                <h5 class="btm  text-center">{{ lblSsnNumber }}</h5>
              </td>
              <!-- <td style="width: 60%">
                <h5 class="mb-0">{{ lblSsnNumber }}</h5>
              </td> -->
            </tr>

            <tr>
              <td>
                <p class="mb-0  text-center">Mobile</p> <br>
                <h5 class="btm  text-center">{{ strMobile }}</h5>
              </td>
              <!-- <td style="width: 60%">
                <h5 class="mb-0">{{ strMobile }}</h5>
              </td> -->
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Billing Country</p><br>
                <h5 class="btm  text-center">{{ strBillingCountry }}</h5>
              </td>
              <!-- <td style="width: 60%">
                <h5 class="mb-0">{{ strEmailAddress }}</h5>
              </td> -->
            </tr>
            <!-- <tr>
              <td>
                <p class="mb-0">Billing Address : {{ strBillingAddress }}</p>
              </td>

            </tr> -->

            <tr>
              <td>
                <p class="mb-0 text-center">Billing City</p><br>
                <h5 class="btm  text-center">{{ strBillingCity }}</h5>
              </td>
              <!-- <td style="width: 50%">
                <h5 class="mb-0">{{ strBillingCity }}</h5>
              </td> -->
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Billing State</p><br>
                <h5 class="btm  text-center">{{ strBillingState }}</h5>
              </td>
              <!-- <td >
                <h5 class="mb-0">{{ strBillingState }}</h5>
              </td> -->
            </tr>

            <tr>
              <td *ngIf="forBlink">
                <p class="mb-0 text-center">Billing Amount</p> <br>
                <h5 class="btm text-center">{{ lblBillingAmount }}</h5>
              </td>
            </tr>
            <tr>
              <td *ngIf="forBlink">
                <p class="mb-0 text-center">Billing Status</p> <br>
                <h5 class="btm text-center">{{ lblBillingStatus }}</h5>
              </td>
            </tr>

            <tr>
              <td *ngIf="!forBlink" class="blinking">
                <p class="mb-0 text-center">Billing Amount</p> <br>
                <h5 class="btm text-center">{{ lblBillingAmount }}</h5>
              </td>
            </tr>
            <tr>
              <td *ngIf="!forBlink" class="blinking">
                <p class="mb-0 text-center">Billing Status</p> <br>
                <h5 class="btm text-center">{{ lblBillingStatus }}</h5>
              </td>
            </tr>
            <tr>
              <td>
                <p class="mb-0 text-center">Last Updated On </p> <br>
                <h5 class="btm text-center">{{ lblLastUpdatedDateTime | date :'MM/dd/yyyy h:mm a' :'en_US' }}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-xl-10">

    <ul ngbNav #nav="ngbNav" (navChange)="onNavChange($event)" justify="justified"
      class="alert alert-success nav nav-pills nav-fill">
      <li ngbNavItem="Document" class="nav-item">
        <a ngbNavLink>Document</a>
        <ng-template ngbNavContent>
          <app-order-document (patientIdEmitter)="getPatientId($event)"
            (nextTabMoveOnSaveEmitter)="changeNextTab($event)" [patientID]='patientID' [strPageType]="strPageType"
            [orderId]='orderId'></app-order-document>
        </ng-template>
      </li>
      <li ngbNavItem="Verification" class="nav-item">
        <a ngbNavLink>Insurance Verification</a>
        <ng-template ngbNavContent>
          <app-shipping-verification [orderId]='orderId' (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
            [patientId]="patientID" (saveInsuranceVerificationFormState)="onSaveInsuranceVerificationData($event)">
          </app-shipping-verification>
        </ng-template>
      </li>

      <li ngbNavItem="Authorization">
        <a ngbNavLink>Authorization</a>
        <ng-template ngbNavContent>
          <app-shipping-auth (nextTabMoveOnSaveEmitter)="changeNextTab($event)" [patientId]="patientID"
            [orderId]='orderId' (saveAuthorizationFormState)="onSaveAuthorizationData($event)"></app-shipping-auth>
        </ng-template>
      </li>
      <!-- <li ngbNavItem="Ledger">
        <a ngbNavLink>Patient Ledger</a>
        <ng-template ngbNavContent>
          <app-shipping-ledger [patientId]="patientID" (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
            [orderId]='orderId' (savePatientLedgerFormState)="onSavePatientLedgerData($event)"></app-shipping-ledger>
        </ng-template>
      </li>
      <li ngbNavItem="History">
        <a ngbNavLink>History</a>
        <ng-template ngbNavContent>
          <app-shipping-history [orderId]='orderId' (nextTabMoveOnSaveEmitter)="changeNextTab($event)"
            (saveHistoryFormState)="onSaveHistoryData($event)"></app-shipping-history>
        </ng-template>
      </li> -->
    </ul>

    <div [ngbNavOutlet]="nav"></div>


  </div>
</div>
