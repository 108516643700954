export class InboundStatusShortCode {
  public static unprocessedFax: string = '001';
  public static faxIssues: string = '002';
  public static confirmation: string = '003';
  public static verification: string = '004';
  public static verificationIssues: string = '005';
  public static preCert: string = '006';
  public static medicare: string = '007';
  public static disapproved: string = '008';
  public static missingDocuments: string = '009';
  public static ready: string = '010';
  public static scheduled: string = '011';
  public static completed: string = '012';
  public static callBack: string = '013';
  public static pending: string = '014';
  public static archive: string = '015';
  public static recycleBin: string = '016';
  public static myFaxPending: string = '017';
  public static myFaxCompleted: string = '018';
}

export class RequisitionStatusShortCode {
  public static requisitionStarted: string = 'Req001';
  public static warrantyReplacement: string = 'Req002';
  public static submittedToShippingDepartment: string = 'Req003';
  public static orderedDirect: string = 'Req004';
  public static transferred: string = 'Req005';
  public static completed: string = 'Req006';
  public static pending: string = 'Req007';
  public static Cancel: string = 'Req008';
}

export const warehouseMMReferrenceId: string = '-2139397197';

export class saleOrderStatus {
  public static orderStarted: number = 0;
  public static billingProcess:number=1;
  public static pendingOrder: number = 3;
  public static orderCompleted: number = 4;
  public static shippingProcess: number = 2;
  public static shippingApprovalProcess: number = 10;
  public static disapprovedByBillingShipping: number = 7;
  public static disapprovedStartOrderOverride:number=91;
  public static disapprovedBillingOverride:number=92;
  public static disapprovedShippingOverride:number=93;
  public static cancelledTicket:number=98;
}
