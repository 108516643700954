<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <h3 class="font-weight-bolder mb-0 pt-2">
        <b class="customThemeClass">Tools / Inventory / On Hand Inventory</b>
      </h3>
      <!-- <div class="col-2"></div> -->
      <div class="col-8 d-flex flex-row-reverse">
        <div>
          <button
            type="button"
            [disabled]="selectedProduct.length === 0"
            mat-raised-button
            (click)="openAdjustModal()"
            class="buttonColor mr-2 mt-2 mat-raised-small-button"
          >
            <img
              class="img-responsive pr-1"
              src="assets/Requisition Icons/adjustment.png"
              alt=""
              height="14"
            />
            Adjust Inventory
          </button>
          <button
            type="button"
            [disabled]="selectedProduct.length === 0"
            mat-raised-button
            (click)="openTransferModal()"
            class="buttonColor mr-2 mt-2 mat-raised-small-button"
          >
            <img
              class="img-responsive pr-1"
              src="assets/Requisition Icons/location.png"
              alt=""
              height="14"
            />
            Location Transfer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mt-3">
      <div class="card curved-card mb-3">
        <div class="row">
          <div class="col-xl-8">
            <ul
              ngbNav
              #nav="ngbNav"
              justify="justified"
              class="alert-success nav nav-pills nav-fill curved-card-header"
            >
              <li
                ngbNavItem="BranchWise"
                class="header-text first-tab"
                [class.selected-tab]="nav.activeId === 'BranchWise'"
              >
                <a ngbNavLink (click)="changeTab('BranchWise')"
                  >Branch-Wise Stock</a>
              </li>
              <li
                ngbNavItem="TotalStock"
                class="header-text"
                [class.selected-tab]="nav.activeId === 'TotalStock'"
              >
                <a ngbNavLink (click)="changeTab('TotalStock')">Total Stock</a>
              </li>
            </ul>
          </div>
        </div>

        <!-- <div class="card-header p-0"></div> position-absolute top-50 z-index-1-->
        <div
          class="card-body px-0"
          style="border-top: 3px solid rgb(201, 201, 201)"
        >
          <!-- Filters -Branchwise-->
          <div *ngIf="activeTab === 'BranchWise'" class="row">
            <div class="col-12">
              <form class="pl-2" [formGroup]="stockForm">
                <mat-form-field>
                  <mat-label>Branch</mat-label>
                  <mat-select
                    formControlName="drpBranch"
                    multiple
                    [(value)]="selectedBranches"
                    (selectionChange)="onSelectionChange($event)"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtBranchFilter"
                      ></ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngIf="!selectAllOption" [value]="'selectAll'"
                      >Select All</mat-option
                    >
                    <mat-option *ngIf="selectAllOption" [value]="'selectAll'"
                      >Deselect All</mat-option
                    >
                    <mat-option
                      *ngFor="let organization of filteredBranch | async"
                      [value]="organization.id"
                    >
                      {{ organization.organizationUnitName }}
                    </mat-option>
                  </mat-select>

                  <mat-error
                    *ngIf="
                      stockForm.get('drpBranch')?.touched &&
                      stockForm.get('drpBranch')?.errors?.required
                    "
                    >Field is required!
                  </mat-error>
                </mat-form-field>
                <button
                  class="buttonColor ml-3 mat-raised-small-button"
                  [disabled]="
                    stockForm.get('drpBranch').value == null ||
                    stockForm.get('drpBranch').value == '' ||
                    stockForm.get('drpBranch').value == undefined
                  "
                  (click)="getBranchWiseStockReport(defaultGuid)"
                  mat-raised-button
                  [matTooltip]="'Search Report'"
                >
                  <i class="fa fa-search"></i>
                </button>

                <button
                  class="resetclr ml-1 mat-raised-small-button"
                  mat-raised-button
                  (click)="resetStockForm()"
                  [matTooltip]="'Reset'"
                >
                  <i class="fa fa-refresh"></i>
                </button>
              </form>
              <mat-divider class="my-2"></mat-divider>
            </div>
          </div>
          <!-- Filters -Total Stock-->
          <div *ngIf="activeTab === 'TotalStock'" class="row">
            <div class="col-12">
              <!-- Search Type -->
              <div class="row">
                <div class="col-12 d-flex flex-row align-items-center">
                  <form class="pl-2" [formGroup]="searchTypeForm">
                    <mat-label class="small-text text-center align-middle"
                      >Search Byss</mat-label
                    >
                    <mat-radio-group
                      formControlName="searchType"
                      class="mt-2 ml-2"
                      aria-label="Select an option"
                      (change)="searchTypeChange()"
                    >
                      <mat-radio-button class="pt-2" value="1"
                        >Date Range</mat-radio-button
                      >
                      <mat-radio-button class="ml-2 pt-2" value="2"
                        >Others</mat-radio-button
                      >
                    </mat-radio-group>
                  </form>
                  <p
                    *ngIf="searchTypeForm.get('searchType').value == '2'"
                    class="text-danger pl-2 mb-0 font-weight-bolder"
                  >
                    Please select any of the filter
                  </p>
                </div>
              </div>

              <!-- Others -->
              <form class="pl-2" [formGroup]="stockForm">
                <!-- Date Range -->
                <mat-form-field
                  *ngIf="searchTypeForm.get('searchType').value == '1'"
                  class="col-2 pl-1 dd-text customWidth"
                >
                  <mat-label>Last Received Date</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      placeholder="Start date"
                      formControlName="txtStartDate"
                    />

                    <input  
                      matEndDate
                      matInput
                      placeholder="End date"
                      formControlName="txtEndDate"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>

                <!-- Manufacturer -->
                <mat-form-field
                  *ngIf="searchTypeForm.get('searchType').value == '2'"
                  class="col-2 pl-1 dd-text"
                >
                  <mat-label> Manufacturer</mat-label>
                  <mat-select formControlName="drpManufacturer">
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtManufacturerFilter"
                      ></ngx-mat-select-search>
                    </mat-option>

                    <mat-option
                      *ngFor="let vendors of filteredVendorDetails | async"
                      [value]="vendors.id"
                    >
                      {{ vendors.vendorName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    *ngIf="
                      stockForm.get('drpManufacturer').value != '' ||
                      stockForm.get('drpManufacturer').value != null
                    "
                    (click)="clearManufacturer(); $event.stopPropagation()"
                    matSuffix
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <!-- Category -->
                <mat-form-field
                  *ngIf="searchTypeForm.get('searchType').value == '2'"
                  class="col-2 dd-text"
                >
                  <mat-label> Category</mat-label>
                  <mat-select formControlName="drpCategory">
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtCategoryFilter"
                      ></ngx-mat-select-search>
                    </mat-option>

                    <mat-option
                      *ngFor="let categories of filteredCategoryDetails | async"
                      [value]="categories.id"
                    >
                      {{ categories.productCategoryName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    *ngIf="
                      stockForm.get('drpCategory').value != '' ||
                      stockForm.get('drpCategory').value != null
                    "
                    (click)="clearCategory(); $event.stopPropagation()"
                    matSuffix
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>

                <!-- Products -->
                <mat-form-field
                  *ngIf="searchTypeForm.get('searchType').value == '2'"
                  class="col-2 dd-text"
                >
                  <mat-select
                    class="dd-text"
                    formControlName="drpProduct"
                    placeholder="Product Code"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        formControlName="txtProductFilter"
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                      ></ngx-mat-select-search
                    ></mat-option>
                    <mat-option
                      *ngFor="let products of filteredProductDetails | async"
                      [value]="products.productId"
                    >
                      {{ products.productName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    *ngIf="
                      stockForm.get('drpProduct').value != '' ||
                      stockForm.get('drpProduct').value != null
                    "
                    (click)="clearProduct(); $event.stopPropagation()"
                    matSuffix
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <!-- Branch -->
                <mat-form-field
                  *ngIf="searchTypeForm.get('searchType').value == '2'"
                >
                  <mat-label>Branch</mat-label>
                  <mat-select
                    formControlName="drpBranch"
                    multiple
                    [(value)]="selectedBranches"
                    (selectionChange)="onSelectionChange($event)"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        placeholderLabel="Search"
                        noEntriesFoundLabel="No Matches found"
                        formControlName="txtBranchFilter"
                      ></ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngIf="!selectAllOption" [value]="'selectAll'"
                      >Select All</mat-option
                    >
                    <mat-option *ngIf="selectAllOption" [value]="'selectAll'"
                      >Deselect All</mat-option
                    >
                    <mat-option
                      *ngFor="let organization of filteredBranch | async"
                      [value]="organization.id"
                    >
                      {{ organization.organizationUnitName }}
                    </mat-option>
                  </mat-select>
                  <button
                    mat-button
                    *ngIf="
                      stockForm.get('drpBranch').value != '' ||
                      stockForm.get('drpBranch').value != null
                    "
                    (click)="clearBranch(); $event.stopPropagation()"
                    matSuffix
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <button
                  class="buttonColor ml-3 mat-raised-small-button"
                  (click)="getTotalStockReport()"
                  [disabled]="validateSearch"
                  mat-raised-button
                  [matTooltip]="'Search Report'"
                >
                  <i class="fa fa-search"></i>
                </button>
                <button
                  class="resetclr ml-1 mat-raised-small-button"
                  mat-raised-button
                  (click)="resetStockForm()"
                  [matTooltip]="'Reset'"
                >
                  <i class="fa fa-refresh"></i>
                </button>
              </form>
            </div>
          </div>

          <div class="row" *ngIf="!loadStockTable">
            <div class="col-12">
              <div
                class="d-flex justify-content-center"
                *ngIf="!loadStockTable"
              >
                <img
                  class="img-responsive pb-2 mr-1"
                  src="assets/gifs/Infinity-loader.gif"
                />
              </div>
            </div>
          </div>
          <div class="row" *ngIf="loadStockTable">
            <div class="col-12">
              <!-- BranchWise Stock Table-->
              <div *ngIf="activeTab === 'BranchWise'" class="container-fluid">
                <div *ngIf="loadStockTable" class="table table-responsive">
                  <table
                    class="row-border hover w-100 display"
                    datatable
                    [dtOptions]="dtBranchWiseOptions"
                  >
                    <thead>
                      <tr>
                        <th><mat-icon> wrap_text</mat-icon></th>
                        <th [matTooltip]="'Product Code'">Product Code</th>
                        <th [matTooltip]="'Description'">Description</th>
                        <th [matTooltip]="'Size'">Size</th>
                        <th [matTooltip]="'Manufacturer'">Manufacturer</th>
                        <th [matTooltip]="'Category'">Category</th>
                        <th [matTooltip]="'Location Bin'">Location Bin</th>
                        <th [matTooltip]="'Serial/Lot No'">Serial/Lot No</th>
                        <th [matTooltip]="'Branch'">Branch</th>
                        <th [matTooltip]="'Total Quantity'" class="text-right">
                          Total Quantity
                        </th>
                        <th [matTooltip]="'Sold Quantity'" class="text-right">
                          Sold Quantity
                        </th>
                        <th
                          [matTooltip]="'Balance Quantity'"
                          class="text-right"
                        >
                          Balance Quantity
                        </th>
                        <th [matTooltip]="'Total Cost ($)'" class="text-right">
                          Total Cost ($)
                        </th>
                        <th [matTooltip]="'Created By'">Created By</th>
                        <th class="text-right" [matTooltip]="'Created Date'">
                          Created Date
                        </th>
                        <th [matTooltip]="'Modified By'">Modified By</th>
                        <th [matTooltip]="'Modified Date'">Modified Date</th>
                        <th [matTooltip]="'Last Received Date'">
                          Last Received Date
                        </th>
                        <th [matTooltip]="'Last Dispensed Date'">
                          Last Dispensed Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of stockList">
                        <td
                          [matTooltip]="'Click to View Stock History'"
                          (click)="getStockHistory($event, data.productId)"
                          class="dt-control text-center"
                        ></td>

                        <td
                          [matTooltip]="data?.productCode || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.productCode || '-')
                          "
                        >
                          {{ data?.productCode || "-" }}
                        </td>
                        <td
                          [matTooltip]="data?.description || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.description || '-')
                          "
                        >
                          {{ data?.description || "-" }}
                        </td>
                        <td
                          [matTooltip]="data?.sizeName || '-'"
                          (dblclick)="onRowDoubleClickV1(data?.sizeName || '-')"
                        >
                          {{ data?.sizeName || "-" }}
                        </td>
                        <td
                          [matTooltip]="data?.vendorName || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.vendorName || '-')
                          "
                        >
                          {{ data?.vendorName || "-" }}
                        </td>
                        <td
                          [matTooltip]="data?.categoryName || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.categoryName || '-')
                          "
                        >
                          {{ data?.categoryName || "-" }}
                        </td>
                        <td
                          [matTooltip]="data?.binLocation || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.binLocation || '-')
                          "
                        >
                          {{ data.binLocation || "-" }}
                        </td>
                        <td>
                          -
                        <!-- [matTooltip]="data?.lotNo || '-'" -->
                        <!-- (dblclick)="onRowDoubleClickV1(data?.lotNo || '-')" -->
                          <!-- {{ data.lotNo || "-" }} -->
                        </td>
                        <td
                          [matTooltip]="data?.branchName || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.branchName || '-')
                          "
                        >
                          {{ data.branchName || "-" }}
                        </td>
                        <td
                          class="text-right"
                          [matTooltip]="data?.quantity || '-'"
                          (dblclick)="onRowDoubleClickV1(data?.quantity || '-')"
                        >
                          {{ data.quantity || "-" }}
                        </td>
                        <td
                          class="text-right"
                          [matTooltip]="data?.soldQuantity || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.soldQuantity || '-')
                          "
                        >
                          {{ data?.soldQuantity || "-" }}
                        </td>
                        <td
                          class="text-right"
                          [matTooltip]="data?.balanceQuantity || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.balanceQuantity || '-')
                          "
                        >
                          {{ data?.balanceQuantity || "-" }}
                        </td>
                        <td
                          class="text-right"
                          [matTooltip]="data?.totalUnitCost || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.totalUnitCost || '-')
                          "
                        >
                          {{ data.totalUnitCost || "-" }}
                        </td>
                        <!-- <td>
                          <ng-container *ngIf="data?.modifiedDate != '0001-01-01T00:00:00'">
                            {{ data?.modifiedDate | date : "MM/dd/yyyy" : "en_US" }}
                          </ng-container>
                        </td> -->
                        <td
                          [matTooltip]="data?.createdBy || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.createdBy || '-')
                          "
                        >
                          {{ data?.createdBy || "-" }}
                        </td>

                        <td
                          class="text-right"
                          [matTooltip]="data?.createdDate"
                          (dblclick)="
                            onRowDoubleClickV1(
                              data?.createdDate
                            )
                          "
                        >
                          {{ data?.createdDate }}
                        </td>
                        <td
                          [matTooltip]="data?.modifiedBy || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.modifiedBy || '-')
                          "
                        >
                          {{ data?.modifiedBy || "-" }}
                        </td>
                        <td
                          [matTooltip]="
                            data?.modifiedDate || '-'
                          "
                          (dblclick)="
                            onRowDoubleClickV1(
                              data?.modifiedDate
                            )
                          "
                        >
                          <ng-container
                            *ngIf="data?.modifiedDate != '0001-01-01T00:00:00'"
                          >
                            {{ data?.modifiedDate }}
                          </ng-container>
                        </td>

                        <td
                          [matTooltip]="
                            data?.lastReceivedDate
                          "
                          (dblclick)="
                            onRowDoubleClickV1(
                              data?.lastReceivedDate
                            )
                          "
                          class="text-center"
                        >
                          <ng-container
                            *ngIf="
                              data?.lastReceivedDate != '0001-01-01T00:00:00'
                            "
                          >
                            {{ data?.lastReceivedDate }}
                          </ng-container>
                        </td>

                        <td
                          [matTooltip]="
                            data?.lastDispensedDate
                          "
                          (dblclick)="
                            onRowDoubleClickV1(
                              data?.lastDispensedDate
                            )
                          "
                          class="text-center"
                        >
                          <ng-container
                            *ngIf="
                              data?.lastDispensedDate != '0001-01-01T00:00:00'
                            "
                          >
                            {{ data?.lastDispensedDate }}
                          </ng-container>
                        </td>

                        <!-- <td>
                          <ng-container *ngIf="data?.lastDispensedDate != '0001-01-01T00:00:00'">
                            {{
                            data.lastDispensedDate == null
                            ? "-"
                            : (data.lastDispensedDate
                            | date : "MM/dd/yyyy" : "en_US")
                            }}
                          </ng-container>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- Total Stock Table-->
              <div *ngIf="activeTab === 'TotalStock'" class="container-fluid">
                <div *ngIf="loadStockTable" class="table table-responsive">
                  <table
                    class="row-border hover w-100 display"
                    datatable
                    [dtOptions]="dtTotalStockOptions"
                  >
                    <thead>
                      <tr>
                        <th><mat-icon>wrap_text</mat-icon></th>
                        <th [matTooltip]="'Product Code'">Product Code</th>
                        <th [matTooltip]="'Description'">Description</th>
                        <th [matTooltip]="'Size'">Size</th>
                        <th [matTooltip]="'Manufacturer'">Manufacturer</th>
                        <th [matTooltip]="'Category'">Category</th>
                        <th [matTooltip]="'Location Bin'">Location Bin</th>
                        <th [matTooltip]="'Serial/Lot No'">Serial/Lot No</th>
                        <th [matTooltip]="'Branch'">Branch</th>
                        <th [matTooltip]="'Total Quantity'" class="text-right">
                          Total Quantity
                        </th>
                        <th [matTooltip]="'Sold Quantity'" class="text-right">
                          Sold Quantity
                        </th>
                        <th
                          [matTooltip]="'Balance Quantity'"
                          class="text-right"
                        >
                          Balance Quantity
                        </th>
                        <th [matTooltip]="'Total Cost ($)'" class="text-right">
                          Total Cost ($)
                        </th>
                        <th [matTooltip]="'Created By'">Created By</th>
                        <th class="text-right" [matTooltip]="'Created Date'">
                          Created Date
                        </th>
                        <th [matTooltip]="'Modified By'">Modified By</th>
                        <th [matTooltip]="'Modified Date'">Modified Date</th>
                        <th [matTooltip]="'Last Received Date'">
                          Last Received Date
                        </th>
                        <th [matTooltip]="'Last Dispensed Date'">
                          Last Dispensed Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of allStockList">
                        <td
                          [matTooltip]="'Click to View Stock History'"
                          (click)="getTotalStockHistory($event, data.productId)"
                          class="dt-control text-center"
                        ></td>
                        <td
                          [matTooltip]="data?.productCode || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.productCode || '-')
                          "
                        >
                          {{ data?.productCode || "-" }}
                        </td>
                        <td
                          [matTooltip]="data?.description || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.description || '-')
                          "
                        >
                          {{ data?.description || "-" }}
                        </td>
                        <td
                          [matTooltip]="data?.sizeName || '-'"
                          (dblclick)="onRowDoubleClickV1(data?.sizeName || '-')"
                        >
                          {{ data?.sizeName || "-" }}
                        </td>
                        <td
                          [matTooltip]="data?.vendorName || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.vendorName || '-')
                          "
                        >
                          {{ data?.vendorName || "-" }}
                        </td>
                        <td
                          [matTooltip]="data?.categoryName || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.categoryName || '-')
                          "
                        >
                          {{ data?.categoryName || "-" }}
                        </td>
                        <td
                          [matTooltip]="data?.binLocation || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.binLocation || '-')
                          "
                        >
                          {{ data.binLocation || "-" }}
                        </td>
                        <td

                        >
                        -
                        <!-- [matTooltip]="data?.lotNo || '-'"
                        (dblclick)="onRowDoubleClickV1(data?.lotNo || '-')"
                          {{ data.lotNo || "-" }} -->
                        </td>
                        <td
                          [matTooltip]="data?.branchName || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.branchName || '-')
                          "
                        >
                          {{ data.branchName || "-" }}
                        </td>
                        <td
                          class="text-right"
                          [matTooltip]="data?.quantity || '-'"
                          (dblclick)="onRowDoubleClickV1(data?.quantity || '-')"
                        >
                          {{ data.quantity || "-" }}
                        </td>
                        <td
                          class="text-right"
                          [matTooltip]="data?.soldQuantity || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.soldQuantity || '-')
                          "
                        >
                          {{ data?.soldQuantity || "-" }}
                        </td>
                        <td
                          class="text-right"
                          [matTooltip]="data?.balanceQuantity || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.balanceQuantity || '-')
                          "
                        >
                          {{ data?.balanceQuantity || "-" }}
                        </td>
                        <td
                          class="text-right"
                          [matTooltip]="data?.totalUnitCost || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.totalUnitCost || '-')
                          "
                        >
                          {{ data.totalUnitCost || "-" }}
                        </td>
                        <td
                          [matTooltip]="data?.createdBy || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.createdBy || '-')
                          "
                        >
                          {{ data?.createdBy || "-" }}
                        </td>

                        <td
                          class="text-right"
                          [matTooltip]="data?.createdDate"
                          (dblclick)="
                            onRowDoubleClickV1(
                              data?.createdDate
                            )
                          "
                        >
                          {{ data?.createdDate }}
                        </td>
                        <td
                          [matTooltip]="data?.modifiedBy || '-'"
                          (dblclick)="
                            onRowDoubleClickV1(data?.modifiedBy || '-')
                          "
                        >
                          {{ data?.modifiedBy || "-" }}
                        </td>
                        <td
                          [matTooltip]="
                            data?.modifiedDate || '-'
                          "
                          (dblclick)="
                            onRowDoubleClickV1(
                              data?.modifiedDate || '-'
                            )
                          "
                        >
                          <ng-container
                            *ngIf="data?.modifiedDate != '0001-01-01T00:00:00'"
                          >
                            {{ data?.lastReceivedDate }}
                          </ng-container>
                        </td>
                        <td
                          [matTooltip]="
                            data?.lastReceivedDate
                          "
                          (dblclick)="
                            onRowDoubleClickV1(
                              data?.lastReceivedDate
                            )
                          "
                          class="text-center"
                        >
                          <ng-container
                            *ngIf="
                              data?.lastReceivedDate != '0001-01-01T00:00:00'
                            "
                          >
                            {{ data?.lastReceivedDate }}
                          </ng-container>
                        </td>

                        <td
                          [matTooltip]="
                            data?.lastDispensedDate
                          "
                          (dblclick)="
                            onRowDoubleClickV1(
                              data?.lastDispensedDate
                            )
                          "
                          class="text-center"
                        >
                          <ng-container
                            *ngIf="
                              data?.lastDispensedDate != '0001-01-01T00:00:00'
                            "
                          >
                            {{ data?.lastDispensedDate }}
                          </ng-container>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
