import { error } from './../official/official.component';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { pdfString, pdfString2 } from '../admin-dashboard/dashboard-data';
import { DomSanitizer } from '@angular/platform-browser';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { imagesList, testImg } from './imageList';
import { defaultGuid } from '../document-perviewer/document-perviewer.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { retry } from 'rxjs/operators';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';

@Component({
  selector: 'app-split-document-modal',
  templateUrl: './split-document-modal.component.html',
  styleUrls: ['./split-document-modal.component.scss'],
})
export class SplitDocumentModalComponent implements OnInit {
  docId: string;
  headerText: string;
  subscription$: Subscription[] = [];
  isLoading: boolean;
  readonly DEFAULT_LAZY_LOADING = 10;
  from: number = 1;
  to: number = this.DEFAULT_LAZY_LOADING;
  loadInboundDocumentAPICall$: Subscription;
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  ctx!: CanvasRenderingContext2D;
  pageNumber: number = 1;
  totalPages: number = 0;
  overAllPages: number = 0;
  overAllPagesBG: number = 0;
  // readonly DEFAULT_ZOOM_LEVEL = 1;
  // readonly MAX_ZOOM_LEVEL = 2.5;
  // readonly MIN_ZOOM_LEVEL = 0.5;
  readonly DEFAULT_ZOOM_LEVEL = 0.7;
  readonly MAX_ZOOM_LEVEL = this.DEFAULT_ZOOM_LEVEL + 4;
  readonly MIN_ZOOM_LEVEL = 0.2;
  zoomLevel: number = this.DEFAULT_ZOOM_LEVEL; // Default Size
  existingDocumentImageList: any = [];
  selectedExistingDocument: any = null;
  loadingMessage: string = 'Loading ...';
  prevZoomLevel: number;
  strPdf: any;
  pdfheight = '100%';
  imgTif: string = testImg;
  disableSaveButton: boolean = false;
  splitDocumentImageList: any = [];
  selectedSplitDocument: any = null;
  TotalDocumentImageList: any = [];
  TiffError: boolean = true;
  tiffPageNo: number = 0;
  subscription: Subscription[] = [];
  isOrderPageLoading: boolean;
  refreshButtonClicked: boolean = false;
  readonly DEFAULT_LAZY_LOADING_API = 10;
  TiffNotLoadingError: boolean = true;
  zoomLevelReSize:number = this.DEFAULT_ZOOM_LEVEL;

  constructor(
    private loaderService: LoaderService,
    private sanitizer: DomSanitizer,
    private inboundDocumentService: InboundDocumentService,
    private toastr: ToastrService,
    private dialog: MatDialogRef<SplitDocumentModalComponent>,
    private communicationService: CommunicationService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      documentId: string;
      defaultDocumentId: string;
      selectedNode: string;
      faxId: string;
      blobName : string;
    } = {
      documentId: defaultGuid,
      defaultDocumentId: '',
      selectedNode: '',
      faxId: '',
      blobName:''
    }
  ) {
    this.loaderService.getLoaderState().subscribe((isOrderPageLoading) => {
      this.isOrderPageLoading = isOrderPageLoading;
    });
    this.reloadcanvas();
  }

  ngOnInit(): void {
    if (!this.isEmpty(this.data.documentId)) {
      // this.loadInboundDocument(this.data.documentId);

      this.docId = this.data.documentId;
      this.loadInboundDocumentV1(this.from, this.to);
    }
  }

  fnRefreshButtonClicked(){
    this.refreshButtonClicked = true;
    this.loadInboundDocumentV1(1,this.DEFAULT_LAZY_LOADING_API)
  }

  loadInboundDocumentV1(from: number, to: number) {
    this.isLoading = true;
    this.loaderService.showLoader();
    // this.firstTotalAPICall=false;
    let pageNo = 1;
    if (this.loadInboundDocumentAPICall$) {
      this.loadInboundDocumentAPICall$?.unsubscribe();
    }
    const loadInboundDocument = this.inboundDocumentService
      .getTIFFImageBase64v1ByGIdAndFromAndTo(this.docId, this.from, this.to)
      .subscribe(
        (response) => {
          this.totalPages = response?.files?.length || 0;
          this.overAllPagesBG = response?.totalPagecount || 0;
          this.overAllPages = response?.totalPagecount || 0;

          if (response && this.totalPages > 0) {
            this.TotalDocumentImageList = response.files.map((a) => {
              return {
                ...a,
                src: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `data:image/tiff;base64, ${a.file}`
                ),
                pageNo: a.pageNo,
              };
            });

            // this.selectedExistingDocument = this.TotalDocumentImageList?.[0];
            this.existingDocumentImageList = this.TotalDocumentImageList;
            // this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
            this.selectFirstDocuementIntheTotalFile();
            this.loadInboundDocumentLoadMore(this.from, this.to);
          }
          this.isLoading = false;
          this.TiffError = false;
          this.TiffNotLoadingError=false;
          // this.loaderService.hideLoader();
          // this.SortSaveError = false;
        },
        (err) => {
          this.TiffNotLoadingError=true;
            if(this.refreshButtonClicked){
              this.refreshButtonClicked = false;
            }
          this.loadInboundDocumentPDF()
          this.isLoading = false;
          this.TiffError = true;
          this.loadingMessage = 'Image not found';
          this.loaderService.hideLoader();
          // this.handleErrorResponse(err);
          // this.isHideTiffViewer = true;
          // if (this.isHideTiffViewer && !this.isEmpty(this.blobName)) {
          //   this.loadInboundDocumentPDF();
          // } else {
          //   console.error('File name  = ' + this.blobName);
          // }
        }
      );
    this.loadInboundDocumentAPICall$ = loadInboundDocument;
  }
  strPdfString: string = '';
  showPdfViwer: boolean = false;
  loadInboundDocumentPDF() {
    // if(this.isEmpty(this.strPdfString)) {
    if(this.strPdfString === '') {

        this.showPdfViwer = false;
        this.inboundDocumentService
          // .downloadInboundDocumentByBlobName(this.data.blobName)
          .downloadInboundDocumentByBlobName(this.data.blobName)
          .subscribe(
            (response) => {
              if (
                typeof response === 'string' &&
                response &&
                response !== null &&
                response?.length !== 0
              ) {
                this.strPdfString = String(response);
              }
              this.showPdfViwer = true;
            },
            (err) => {
              // this.strSelectedPdfPath = '';
              this.showPdfViwer = false;

              const data: HttpErrorResponse = err;
              this.toastr.error(data?.error?.error?.message);
            }
          );
        }
      }

  selectFirstDocuementIntheTotalFile() {
    // this.selectedExistingDocument = this.TotalDocumentImageList?.[0];
    this.loadTiffImageV1(this.existingDocumentImageList?.[0]);
  }

  loadInboundDocumentLoadMore(from: number, to: number) {
    // this.isTotalAPIcallGoingOn = true;
    if (this.overAllPagesBG >= this.to) {
      this.from = to + 1;
      this.to = to + this.DEFAULT_LAZY_LOADING;

      if (this.loadInboundDocumentAPICall$) {
        this.loadInboundDocumentAPICall$?.unsubscribe();
      }
      const loadInboundDocument = this.inboundDocumentService
        .getTIFFImageBase64v1ByGIdAndFromAndTo(this.docId, this.from, this.to)
        .subscribe(
          (response) => {
            let totalPagesLoadMore = response.files.length;
            if (response && totalPagesLoadMore > 0) {
              // Assuming response is an array of items to be pushed into the list
              response.files.forEach((item) => {
                const newItem = {
                  ...item,
                  src: this.sanitizer.bypassSecurityTrustResourceUrl(
                    `data:image/tiff;base64, ${item.file}`
                  ),
                  pageNo: item.pageNo,
                };
                this.TotalDocumentImageList.push(newItem);
                item = null;
              });
              this.overAllPages = this.TotalDocumentImageList.length;
              // if (this.selectedOption === this.sotingSelectedCSS.Total) {
              this.totalPages = this.TotalDocumentImageList.length;
              this.existingDocumentImageList = this.TotalDocumentImageList;
              // }
            }
            this.loadInboundDocumentLoadMore(this.from, this.to);
            // this.firstTotalAPICall=true;
          },
          (err) => {
            // this.handleErrorResponse(err);
            this.loaderService.hideLoader();
          }
        );
      this.loadInboundDocumentAPICall$ = loadInboundDocument;
    } else {
      // this.firstTotalAPICall=true;
      // this.checkTotalAPICallCompletion();
      this.loaderService.hideLoader();
    }
  }

  private handleError(error: any) {
    const data: HttpErrorResponse = error;
    this.toastr.error(data?.error?.error?.message, 'Error');
    // this.disableAnnotation = true;
  }

  loadTiffImageV1(docDetails: any) {
    if (docDetails && docDetails.file) {
      // this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        // Calculate the position to draw the image so that it remains centered
        // const imageWidth = img.width * this.DEFAULT_ZOOM_LEVEL;
        // const imageHeight = img.height * this.DEFAULT_ZOOM_LEVEL;
        const imageWidth = img.width * this.zoomLevel;
        const imageHeight = img.height * this.zoomLevel;
        // Clear canvas before drawing
        this.ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Adjust canvas size based on zoom level
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        this.ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        // Scroll to the selected image
        const selectedImageElement = document.querySelector(
          '.custom-thumbnail-view-selected'
        );
        if (selectedImageElement) {
          selectedImageElement.scrollIntoView({
            behavior: 'auto',
            block: 'center',
          });
        }
      };
      img.src = 'data:image/tiff;base64,' + docDetails.file;

      this.tiffPageNo = docDetails.pageNo || 0;
      this.pageNumber = docDetails.pageNo || 0;
      this.selectedExistingDocument = docDetails;
      // this.TiffError = true; Need to check Many case
      this.loadingMessage = '';
    } else {
      this.TiffError = true;
      this.loadingMessage = 'Images has not found/Empty';
    }
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  loadExistingImage(docDetails: any) {
    this.selectedExistingDocument = docDetails;
    this.loadTiffImage(docDetails.file);
    this.pageNumber = docDetails.pageNo;
  }

  reloadcanvas() {
    setTimeout(() => {
      if (this.selectedExistingDocument === null) {
        this.loadingMessage = 'Image not found';
      }
    }, 10000);
  }

  // Function to zoom in
  // zoomIn() {
  //   if (this.zoomLevel < this.MAX_ZOOM_LEVEL) {
  //     this.zoomLevel += 0.1;
  //     this.drawZoomedImage(); // Redraw image with new zoom level
  //   }
  //   console.log("zoomLevel " + this.zoomLevel + " is " + this. MAX_ZOOM_LEVEL);
  // }

  zoomIn() {
    if (this.zoomLevel < this.MAX_ZOOM_LEVEL) {
      this.prevZoomLevel = this.zoomLevel;
      this.zoomLevel = Math.round((this.zoomLevel + 0.1) * 100) / 100; // Round to two decimal places
      this.zoomLevelReSize = this.zoomLevel;
      // this.drawZoomedImage(); // Redraw image with new zoom level
      this.redrawAnnotationsZoom();
    }
  }

  // Function to zoom out
  // zoomOut() {
  //   if (this.zoomLevel > this.MIN_ZOOM_LEVEL) {
  //     this.zoomLevel -= 0.1;
  //     this.drawZoomedImage(); // Redraw image with new zoom level
  //   }
  //   console.log("zoomLevel " + this.zoomLevel + " is " + this. MIN_ZOOM_LEVEL);
  // }

  zoomOut() {
    if (this.zoomLevel > this.MIN_ZOOM_LEVEL) {
      this.prevZoomLevel = this.zoomLevel;
      this.zoomLevel = Math.round((this.zoomLevel - 0.1) * 100) / 100; // Round to two decimal places
      this.zoomLevelReSize = this.zoomLevel;
      // this.drawZoomedImage(); // Redraw image with new zoom level
      this.redrawAnnotationsZoom();
    }
  }

  // previousImage() {
  //   if (
  //     this.selectedExistingDocument &&
  //     this.selectedExistingDocument.pageNo > 1
  //   ) {
  //     let previousImageNo = this.selectedExistingDocument.pageNo - 2;
  //     this.loadExistingImage(this.existingDocumentImageList?.[previousImageNo]);
  //   }
  // }
  previousImage() {
    if (
      this.selectedExistingDocument &&
      this.selectedExistingDocument.pageNo > 1
    ) {
      // let previousImageNo = this.selectedExistingDocument.pageNo - 2;
      // this.loadExistingImage(this.existingDocumentImageList?.[previousImageNo]);
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.checkPreviousIndex()]
      );
    }
  }

  // nextImage() {
  //   if (
  //     this.selectedExistingDocument &&
  //     this.selectedExistingDocument.pageNo <
  //       this.existingDocumentImageList.length
  //   ) {
  //     console.log(' nextImage this.selectedExistingDocument')
  //     console.log(this.selectedExistingDocument)
  //     let nextImageNo = this.selectedExistingDocument.pageNo;
  //     console.log('this.existingDocumentImageList?.[nextImageNo]')
  //     console.log(this.existingDocumentImageList?.[nextImageNo])
  //     this.loadExistingImage(this.existingDocumentImageList?.[nextImageNo]);
  //   }
  // }

  nextImage() {
    if (
      this.selectedExistingDocument &&
      this.selectedExistingDocument.pageNo <
        this.existingDocumentImageList.length
    ) {
      // console.log(' nextImage this.selectedExistingDocument')
      // console.log(this.selectedExistingDocument)
      // let nextImageNo = this.selectedExistingDocument.pageNo;
      // console.log('this.existingDocumentImageList?.[nextImageNo]')

      // this.loadExistingImage(this.existingDocumentImageList?.[nextImageNo]);
      this.loadExistingImage(
        this.existingDocumentImageList?.[this.checkNextIndex()]
      );
   
    }
  }

  firstImage() {
    this.loadExistingImage(this.existingDocumentImageList?.[0]);
  }

  lastImage() {
    const pageIndex = this.totalPages - 1;
    this.loadExistingImage(this.existingDocumentImageList?.[pageIndex]);
  }

  // GetMidNextImage(){
  //   const index = this.existingDocumentImageList.findIndex(
  //     (item) => item.pageNo === this.pageNumber
  //   );
  //   if (index !== -1) {
  //   }
  //   else{

  //   }
  // }

  // Function to navigate to a specific page
  goToPage() {
    if (this.pageNumber <= 0) {
      this.pageNumber = 1;
      this.firstImage();
    } else if (this.pageNumber >= 1 && this.pageNumber <= this.totalPages) {
      // this.existingDocumentImageList = this.TotalDocumentImageList;
      const index = this.existingDocumentImageList.findIndex(
        (item) => item.pageNo === this.pageNumber
      );

      if (index !== -1) {
        this.loadExistingImage(this.existingDocumentImageList[index]);
      } else {
        // if(this.pageNumber >= 1 && this.pageNumber <= this.TotalDocumentImageList.length){
        //   // this.GetMidNextImage();
        //   this.firstImage();
        // }else{
        //   this.firstImage();
        // }
        this.firstImage();
      }
    } else {
      this.pageNumber = this.totalPages;
      this.lastImage();
    }
  }

  // Function to draw the image on the canvas with the current zoom level
  drawZoomedImage() {
    const docDetails = this.selectedExistingDocument;
    const tiffBase64 = docDetails.file;
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      // Calculate the position to draw the image so that it remains centered
      const imageWidth = img.width * this.zoomLevel;
      const imageHeight = img.height * this.zoomLevel;
      // Clear canvas before drawing
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Adjust canvas size based on zoom level
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      // Draw image with adjusted dimensions and centered position

      this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
    };
    img.src = 'data:image/tiff;base64,' + tiffBase64;
  }

  // redrawAnnotationsZoom() {
  //   // withOutRotate
  //   if (!this.isLoading) {
  //     const canvas = this.canvasRef.nativeElement;
  //     this.ctx = canvas.getContext('2d');
  //     this.ctx.clearRect(0, 0, canvas.width, canvas.height);
  //     const img = new Image();
  //     img.onload = () => {
  //       // Calculate the new image dimensions based on the zoom level
  //       const imageWidth = Math.round(img.width * this.zoomLevel);
  //       const imageHeight = Math.round(img.height * this.zoomLevel);

  //       canvas.width = imageWidth;
  //       canvas.height = imageHeight;
  //       this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);

  //       // this.textAnnotations.forEach((annotation) => {
  //       //   const fontSize = Math.round(25 * this.zoomLevel);
  //       //   this.ctx.font = `${fontSize}px Arial`;

  //       //   const adjustedX = Math.round(annotation.x * this.zoomLevel);
  //       //   const adjustedY = Math.round(annotation.y * this.zoomLevel);

  //       //   this.ctx.fillStyle = this.textColor;
  //       //   this.ctx.fillText(
  //       //     annotation.text,
  //       //     adjustedX - Math.round(annotation.rectWidth * this.zoomLevel),
  //       //     adjustedY -
  //       //       Math.round((annotation.rectHeight + 10) * this.zoomLevel)
  //       //   );

  //       //   this.ctx.strokeStyle = this.lineColor;
  //       //   this.ctx.lineWidth = 3;
  //       //   this.ctx.strokeRect(
  //       //     adjustedX - Math.round(annotation.rectWidth * this.zoomLevel),
  //       //     adjustedY - Math.round(annotation.rectHeight * this.zoomLevel),
  //       //     Math.round(annotation.rectWidth * this.zoomLevel),
  //       //     Math.round(annotation.rectHeight * this.zoomLevel)
  //       //   );

  //       //   this.savedState = this.ctx.getImageData(
  //       //     0,
  //       //     0,
  //       //     canvas.width,
  //       //     canvas.height
  //       //   );
  //       // });

  //       // Save the current zoom level for the next redraw
  //       this.prevZoomLevel = this.zoomLevel;
  //     };
  //     img.src = 'data:image/tiff;base64,' + this.selectedExistingDocument.file;
  //   }
  // }

  redrawAnnotationsZoom() { //withRotate
    if (!this.isLoading) {
      const canvas = this.canvasRef.nativeElement;
      this.ctx = canvas.getContext('2d');
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      const img = new Image();
      img.onload = () => {
        // Calculate the new image dimensions based on the zoom level
        const imageWidth = Math.round(img.width * this.zoomLevel);
        const imageHeight = Math.round(img.height * this.zoomLevel);
  
        // Calculate the canvas size to fit the rotated image
        const angle = this.rotationAngle * Math.PI / 180;
        const sin = Math.sin(angle);
        const cos = Math.cos(angle);
        const rotatedWidth = Math.abs(imageWidth * cos) + Math.abs(imageHeight * sin);
        const rotatedHeight = Math.abs(imageHeight * cos) + Math.abs(imageWidth * sin);
  
        canvas.width = rotatedWidth;
        canvas.height = rotatedHeight;
        this.ctx.save();
  
        // Rotate the canvas context
        this.ctx.translate(canvas.width / 2, canvas.height / 2);
        this.ctx.rotate(angle);
        this.ctx.translate(-imageWidth / 2, -imageHeight / 2);
        this.ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
        this.ctx.restore();
  
  
        // Save the current zoom level and rotation angle for the next redraw
        // this.prevZoomLevel = this.zoomLevel;
        this.prevRotationAngle = this.rotationAngle;
      };
      img.src = 'data:image/tiff;base64,' + this.selectedExistingDocument.file;
    }
  }

  loadTiffImage(tiffBase64: string) {
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      // canvas.width = img.width;
      // canvas.height = img.height;
      // Calculate the position to draw the image so that it remains centered
      // const imageWidth = img.width * this.DEFAULT_ZOOM_LEVEL;
      // const imageHeight = img.height * this.DEFAULT_ZOOM_LEVEL;
      const imageWidth = img.width * this.zoomLevel;
      const imageHeight = img.height * this.zoomLevel;
      // Clear canvas before drawing
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Adjust canvas size based on zoom level
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      this.ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      // Scroll to the selected image
      const selectedImageElement = document.querySelector(
        '.custom-thumbnail-view-selected'
      );
      if (selectedImageElement) {
        selectedImageElement.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      }
    };
    img.src = 'data:image/tiff;base64,' + tiffBase64;
    // this.zoomLevel = this.DEFAULT_ZOOM_LEVEL;
  }

  // //To Load the Split Image in Pdf Viewer
  loadSplitImage(docDetails: any) {
    this.selectedSplitDocument = docDetails;

    // const existingDocument = this.inboundDocumentService
    //   .getInboundPdfPage(
    //     this.data.documentId,
    //     this.selectedSplitDocument.pageNo
    //   )
    //   .subscribe((response) => {
    //     this.strPdf = response;
    //   });
    // this.subscription.push(existingDocument);
  }
  check() {
    console.log(this.TiffError + 'TiffError');
    console.log(this.isLoading + 'isLoading');
  }
  //To Reset the Splitted Document
  resetDocumentSplit() {
    this.loaderService.showLoader();
    this.selectedExistingDocument = null;
    this.selectedSplitDocument = null;
    this.splitDocumentImageList = [];
    this.existingDocumentImageList = this.TotalDocumentImageList;
    this.selectFirstDocuementIntheTotalFile();
    this.loaderService.hideLoader();
  }
  dbClickMoveToExistingDocument() {
    this.moveToExistingDocument();
  }
  //To Move(Remove to Existing) the Page to the Existing Document
  moveToExistingDocument() {
    this.splitDocumentImageList = this.splitDocumentImageList.filter(
      (a) => a.pageNo !== this.selectedSplitDocument.pageNo
    );
    this.existingDocumentImageList.push(this.selectedSplitDocument);
    this.selectedSplitDocument = null;
    this.sortBothDocuments();
  }

  checkNextIndex() {
    const pageNumToFind = this.selectedExistingDocument.pageNo;

    let nextIndex = 0;
    const index = this.existingDocumentImageList.findIndex(
      (item) => item.pageNo === pageNumToFind
    );

    if (index !== -1) {
      nextIndex = (index + 1) % this.existingDocumentImageList.length;
    }
    return nextIndex;
  }
  checkPreviousIndex() {
    const pageNumToFind = this.selectedExistingDocument.pageNo;
    let previewsIndex = 0;
    const index = this.existingDocumentImageList.findIndex(
      (item) => item.pageNo === pageNumToFind
    );
    if (index !== -1) {
      previewsIndex = (index - 1) % this.existingDocumentImageList.length;
    }
    if (previewsIndex < 0) {
      previewsIndex = 0;
    }
    return previewsIndex;
  }
  dbClickMoveToSplitDocument() {
    this.moveToSplitDocument();
  }

  // dbClickMoveToSplitDocument(event: MouseEvent, image: any): void {
  //   event.preventDefault(); // Prevent default behavior if any
  //   event.stopPropagation(); // Stop event from bubbling up
  //   // Your logic for double-click
  //   console.log('Image double-clicked', image);
  // }
  //To Move the Page to the new Split Document
  moveToSplitDocument() {
    let docDetails = this.existingDocumentImageList?.[this.checkNextIndex()];
    this.existingDocumentImageList = this.existingDocumentImageList.filter(
      (a) => a.pageNo !== this.selectedExistingDocument.pageNo
    );

    let index = this.existingDocumentImageList.findIndex(
      (item) => item.pageNo === this.selectedExistingDocument.pageNo
    );
    // let removedExistingItem = [];
    if (index !== -1) {
      //   // Remove item from PendingDocumentImageList and add it to SortedDocumentImageList
      // removedExistingItem =
      this.existingDocumentImageList.splice(index, 1)[0];
    }
    this.splitDocumentImageList.push(this.selectedExistingDocument);
    // this.splitDocumentImageList.push(removedExistingItem);
    this.loadExistingImage(docDetails);
    this.sortBothDocuments();

    // this.selectedExistingDocument =
    //   this.existingDocumentImageList?.[this.checkNextIndex()];
    // this.pageNumber = this.selectedExistingDocument.pageNo;
  }
  //To Save the new Split Documents
  saveSplitDocument() {
    this.loaderService.showLoader();
    this.disableSaveButton = true;
    let splittedPageNumbers = this.splitDocumentImageList.map((a) => a.pageNo);
    this.inboundDocumentService
      .splitInboundDocument(this.data.documentId, splittedPageNumbers)
      .subscribe(
        (response) => {
          if (response['statusCode'] === 200) {
            this.disableSaveButton = false;
            this.toastr.success('Document Splitted Successfully', 'Success');
            this.dialog.close();
            this.communicationService.triggerFunctionCall(
              this.data.selectedNode
            );
          } else if (response['statusCode'] === 207) {
            Swal.fire({
              title: response['message'],
              // text: 'By Changing Status, this branch will be deactivated',
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#34c38f',
              cancelButtonColor: '#f46a6a',
              confirmButtonText: 'Yes',
            }).then((result) => {
              if (result.value) {
                this.inboundDocumentService
                  .splitInboundDocument(
                    this.data.documentId,
                    splittedPageNumbers,
                    true
                  )
                  .subscribe(
                    (response) => {
                      if (response['statusCode'] === 200) {
                        this.disableSaveButton = false;
                        this.toastr.success(
                          'Document Splitted Successfully',
                          'Success'
                        );
                        this.dialog.close();
                        this.communicationService.triggerFunctionCall(
                          this.data.selectedNode
                        );
                      } else {
                        this.toastr.warning(response['message'], 'Warning');
                        this.disableSaveButton = false;
                      }
                    },
                    (err) => {
                      const data: HttpErrorResponse = err.error;
                      this.toastr.error(data.error.message, 'Error');
                      this.disableSaveButton = false;
                    }
                  );
              } else {
                this.disableSaveButton = false;
              }
            });
          } else {
            this.toastr.warning(response['message'], 'Warning');
            this.disableSaveButton = false;
          }
          this.loaderService.hideLoader();
        },
        (err) => {
          const data: HttpErrorResponse = err.error;
          this.toastr.error(data.error.message, 'Error');
          this.disableSaveButton = false;
          this.loaderService.hideLoader();
        }
      );
  }
  //To sort the arrays by page no
  sortBothDocuments() {
    this.existingDocumentImageList.sort((a, b) => {
      return a.pageNo - b.pageNo; // Sort by name alphabetically
      // For sorting by age: return a.age - b.age;
    });
    this.splitDocumentImageList.sort((a, b) => {
      return a.pageNo - b.pageNo; // Sort by name alphabetically
      // For sorting by age: return a.age - b.age;
    });
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  rotationAngle = 0;
  prevRotationAngle = 0;
  rotateClockwise() {
    this.rotationAngle = (this.rotationAngle + 90) % 360;
    this.redrawAnnotationsZoom();
  }
  
  rotateCounterclockwise() {
    this.rotationAngle = (this.rotationAngle - 90 + 360) % 360;
    this.redrawAnnotationsZoom();
  }
}
