
// import statements for packages
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

// custom validator to check number validation

@Injectable({
    providedIn: 'root'
})

// export class to validate names
export class TextValidators {

    // custom validator to check text validation
    isTextCheck(AC: AbstractControl) {
        const txtValue = String(AC?.value)?.trim();
        const sAlphabets = /^[a-zA-Z ]*$/.test(txtValue) ? txtValue : "";
        // const sAlphabets = /[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(txtValue) ? txtValue : "";
        if (AC && txtValue && !sAlphabets) {
            return { sAlphabets: true, msg: "Please Enter Valid Mail Id" };
        }
        return null;
    };
}
