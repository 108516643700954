import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { forkJoin, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import {
  OrderDropdowns,
  PatientDropdowns,
} from '../dropdown-proxy/dropdown-management/dropdowns';
import {
  ChartDTO,
  OrderMasterDropdownDTO,
  PayablePercentDTO,
  PlanTypeDTO,
  TypeOfPlanDTO,
  YearTypeDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/order';
import {
  PatientMasterDropdownDTO,
  PayorLevelDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import {
  OrderMasterDropdownService,
  PatientMasterDropdownService,
} from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import {
  PatientPersonalDTO,
  PatientPolicyDTO,
  CreateUpdatePatientVerificationDTO,
  GetNPI_TaxId,
} from '../patient-proxy/patient-optimization/dto';
import { PatientPersonalService } from '../patient-proxy/patient-optimization/patient-personal.service';
import { PatientPolicyService } from '../patient-proxy/patient-optimization/patient-policy.service';
import { PatientVerificationService } from '../patient-proxy/patient-optimization/patient-verification.service';
import {
  InsuranceType,
  VerifyType,
  verifyTypeOptions,
} from '../patient-proxy/patient/enumeration-data';
import { VerificationStatus, YearType } from '../patient-proxy/patient/enumeration-data/verification-status.enum';
import { ToastrService } from 'ngx-toastr';

import { drpPolicesDTO } from '../patient-verification/patient-verification.component';
import { PatientAddPolicyTabComponent } from '../patient-add-policy-tab/patient-add-policy-tab.component';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { UserByRoleDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { map, startWith } from 'rxjs/operators';
import { InboundStatusShortCode } from 'projects/shared/src/app/data/common-data';
import { PatientPverifyTabComponent } from '../patient-pverify-tab/patient-pverify-tab.component';
import { noWhitespaceValidator } from '../add-note-tab/add-note-tab.component';
import { DatePipe } from '@angular/common';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
// import { DashboardFaxTableComponent } from 'projects/admin/src/app/dashboard-fax-table/dashboard-fax-table.component';

@Component({
  selector: 'app-patient-manual-verification-tab',
  templateUrl: './patient-manual-verification-tab.component.html',
  styleUrls: ['./patient-manual-verification-tab.component.scss'],
})
export class PatientManualVerificationTabComponent
  implements OnInit, OnDestroy
{
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  patientId: string = defaultGuid;
  policyId: string = defaultGuid;
  verificationId: string = defaultGuid;
  isViewVerification:boolean=false;
  verificationForm: FormGroup;
  defaultPatientId: string = '';
  chartId: string = '';
  dateofBirth  :Date;
  dateofBirthV1:string;
  effectiveDate:Date;
  effectiveDateV1:string;
  fascalYearStart:Date;
  fascalYearStartV1
  planCob:Date;
  planCobV1:string;
  fascalYearEnd:Date;
  fascalYearEndV1:string;
  policyHolderDob:Date;
  policyHolderDobV1:string;
  verifiedOn:Date;
  verifiedOnV1:string;
  verifiedOnCurrent:Date;
  verifiedOnCurrentV1:string;
  approvedOn:Date;
  approvedOnV1:string;
  approvedOnCurrent:Date;
  approvedOnCurrentV1:string;
  plan: string = '';
  payorPlan: string = '';
  verfnAMethod: string = '';
  notesText = '';
  commonTechNotes = '';
  type: string = '';
  typeOfPlan: string = '';
  payablePercentType: string = '';
  payablePercentType1: string = '';
  payablePercentType2: string = '';
  drpPayorLevel: string;
  drpPayorName: string;
  drpPolicyNo: string;
  drpPhoneNo: string;
  isSaveBtn: boolean = false;
  verifiedMethod: string;
  isShowSpinner: boolean = false;
  isPatientLockedByCurrentUser: boolean = true;
  payorLevel: PayorLevelDTO[];
  drpChart: ChartDTO[] = [];
  drpPlan: TypeOfPlanDTO[] = [];
  drpPayable: PayablePercentDTO[] = [];
  drpPolices: drpPolicesDTO[] = [];
  drpPlanType: PlanTypeDTO[] = [];
  drpYearType: YearTypeDTO[] = [];
  drpPolicies: PatientPolicyDTO[] = [];
  subscription$: Subscription[] = [];
  userName: string = '';
  documentId: string = null;
  selectedNode:string = null;
  lstFilterUsers: UserByRoleDTO[] = [];
  groupNo: any;
  userId: string;
  npi: any = '';
  mmTaxId: any = '';
  newInsurance:boolean=false;
  inboundStatusIds = InboundStatusShortCode;
  @Output() reloadTableEvent = new EventEmitter();
  @Output() selectNode = new EventEmitter();
  payerId: string;

  TypeControl=new FormControl();
  drpTypeSearch:any;

  PlanTypeControl=new FormControl();
  drpPlanTypeSearch:any;

  PayableControl=new FormControl();
  drpPayableSearch:any;

  PreparedByControl=new FormControl();
  drpUserSearch:any;

  payablePercentV2Control=new FormControl();
  drpPayableSearchV2:any;

  payablePercentV3Control=new FormControl();
  drpPayableSearchV3:any;

  verfiedByControl=new FormControl();
  drpVerifiedSearch:any;
  isVerificationLoading: boolean=false;
  showInvalid:boolean=false;
  constructor(
    // private dashboardFaxTableservice: DashboardFaxTableComponent,
    private formBuilder: FormBuilder,
    private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    private userService: UserService,
    private datepipe: DatePipe,
    private patientDropdownService: PatientMasterDropdownService,
    private orderDropdownService: OrderMasterDropdownService,
    // public manualVerificationDialog: MatDialogRef<PatientManualVerificationTabComponent>,
    private patientPersonalService: PatientPersonalService,
    private patientPolicyService: PatientPolicyService,
    private patientVerificationService: PatientVerificationService,
    // public doVerificationModel: MatDialogRef<PatientAddPolicyTabComponent>,
    private toastr: ToastrService,
    public dialog: MatDialog,

    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      verificationId: string;
      policyId: string;
      savehidden?: boolean;
      documentId?:string;
      selectedNode?:string;
      isViewVerification?:boolean;
      isSaveBtn: boolean;
      inboundShortCode?:string;
      verificationStatus?:string;
      isNewInsurance?:boolean;
      payerId?:string;
      defaultFaxId?:string;
    } = {
      patientId: defaultGuid,
      verificationId: defaultGuid,
      policyId: defaultGuid,
      savehidden: false,
      documentId:null,
      selectedNode:'',
      isViewVerification:false,
      isSaveBtn: false,
      inboundShortCode:'0',
      verificationStatus:null,
      isNewInsurance:false,
      payerId:defaultGuid,
      defaultFaxId:'-'
    },
    public dialogRef: MatDialogRef<PatientManualVerificationTabComponent>,
    // @Inject(MAT_DIALOG_DATA)
    // public data1: any,

    private communicationService: CommunicationService


  ) {}
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {

    // Retrieve the data from localStorage
    const localStorageData = localStorage.getItem('smrtlkDls');
    this.userId = localStorage.getItem("userId") ?? "";
    // Parse the JSON data
    const parsedData = JSON.parse(localStorageData);
    // Access the 'name' property
    this.userName = parsedData?.name || '';
    this.createVerificationForm();
    this.documentId=this.data?.documentId ?? null;
    this.patientId = this.data?.patientId ?? defaultGuid;
    this.policyId = this.data?.policyId ?? defaultGuid;
    this.verificationId = this.data?.verificationId ?? defaultGuid;
    this.isViewVerification = this.data?.isViewVerification ?? false;
    this.isSaveBtn = this.data.isSaveBtn;
    this.newInsurance = this.data.isNewInsurance;

      this.isVerificationLoading=true;
    this.patientId && this.patientId !== defaultGuid && this.getDropdown();
   this.getUserByTenant();
   this.getNPI();
     // if (this.data.savehidden === true) {
      // this.getInsVerificationByID();
    // }
    this.verificationForm.patchValue({
      drpVerifiedBy:this.userId,
      drpPreparedBy:this.userId
    })

    //Type DropDowm Search
    this.TypeControl.valueChanges.subscribe((FilterText) => {
    FilterText = FilterText.toLowerCase();
    this.drpYearType = this.drpTypeSearch.filter((dx) =>
      dx.yearType.toLowerCase().includes(FilterText)
    );
  });

    //Type of Plan DropDowm Search
    this.PlanTypeControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpPlanType = this.drpPlanTypeSearch.filter((dx) =>
        dx.planType.toLowerCase().includes(FilterText)
      );
    });

     //Payable at what percent of the allowed amount DropDowm Search 1
     this.PayableControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpPayable = this.drpPayableSearch.filter((dx) =>
        dx.payablePercentType.toLowerCase().includes(FilterText)
      );
    });

    //Payable at what percent of the allowed amount DropDowm Search 2
    this.payablePercentV2Control.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpPayable = this.drpPayableSearchV2.filter((dx) =>
        dx.payablePercentType.toLowerCase().includes(FilterText)
      );
    });

    //Payable at what percent of the allowed amount DropDowm Search 3
    this.payablePercentV3Control.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpPayable = this.drpPayableSearchV3.filter((dx) =>
        dx.payablePercentType.toLowerCase().includes(FilterText)
      );
    });

    //Prepared By Drop Down Search
    this.PreparedByControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.lstFilterUsers = this.drpUserSearch.filter((dx) =>
        dx.userDesignation.toLowerCase().includes(FilterText)
      );
    });

    //Verfied By Drop Down Search
    this.verfiedByControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.lstFilterUsers = this.drpVerifiedSearch.filter((dx) =>
        dx.userDesignation.toLowerCase().includes(FilterText)
      );
    });

    // const printBtn: HTMLElement = document.getElementById('btnPrint');
    //   printBtn.onclick = function () {
    //     printDiv('printDiv');
    //   };
    this.isVerificationLoading=false;

    this.verificationForm.get('chkActive').valueChanges.subscribe((checked) => {
      this.showInvalid = !checked;
    });
  }
  getNPI() {
    const NPI_TaxID = this.patientPersonalService
      .getNPIList(this.patientId)
      .subscribe(
        (response) => {
          // Perform a null check on the response
          const GetNPI_TaxId: GetNPI_TaxId = response ?? null;

          // If response is not null, extract the properties
          if (GetNPI_TaxId) {
            this.mmTaxId = GetNPI_TaxId.mmTaxId;
            this.npi = GetNPI_TaxId.npi;
          } else {
            this.mmTaxId = ' - ';
            this.npi = ' - ';
          }
        },
        (err) => {
          // Handle error and display message
          //const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });

          this.mmTaxId = ' - ';
          this.npi = ' - ';
        }
      );
  }
  getUserByTenant(){
  const userList = this.userService.getUsersByTenant().subscribe(
    (response) => {
      this.lstFilterUsers = response;
      this.drpUserSearch=this.lstFilterUsers.sort((a, b) =>
            a.userDesignation.localeCompare(b.userDesignation)
          );
      this.drpVerifiedSearch=this.lstFilterUsers.sort((a, b) =>
            a.userDesignation.localeCompare(b.userDesignation)
          );
    });
    }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }

     //Update Character Count for Common Tech Textarea
     updateCharacterCountCommonTech(maxlength: number) {
      // Ensure the text length does not exceed the maximum length
      if (this.commonTechNotes?.length > maxlength) {
        this.commonTechNotes = this.commonTechNotes?.substr(0, maxlength);
      }
    }
  getInsVerificationByID() {
    this.isShowSpinner = false;
    this.isVerificationLoading=true;
    this.patientVerificationService
      // .getVerificationDetailsByPatientIdByPatientId(this.data.patientId)
      .get(this.data.verificationId)
      .subscribe(
        (response) => {

          const patientInfo = response;

          this.verifiedMethod = patientInfo?.vMethod;

          // this.fiscalYear = patientInfo?.fascalYearStart ? patientInfo?.fascalYearStart : this.verificationForm.value.txtFascalYearStart

          const savedPayorLevel: string = this.payorLevel?.filter(
            (x) =>
              (x?.payorLevelName?.toLowerCase() ?? '') ===
              (patientInfo?.plan?.toLowerCase() ?? '')
          )?.[0]?.payorLevelName;


          savedPayorLevel &&
            this.payorValueChangeDropdown(patientInfo?.planName);
          //   if(patientInfo?.dateofBirth!==null &&patientInfo?.dateofBirth!==undefined){
          //     this.dateofBirth=new Date(patientInfo?.dateofBirth)
          //     this.dateofBirth.setDate(this.dateofBirth.getDate() )
          //     this.dateofBirthV1 =this.dateofBirth.toISOString().split('T')[0]
          //   this.verificationForm
          //   .get('txtDateOfBirth')
          //   .setValue(this.dateofBirthV1)
          // }
          // if(patientInfo?.effectiveDate!==null &&patientInfo?.effectiveDate!==undefined ){
          //   this.effectiveDate=new Date(patientInfo?.effectiveDate)
          //   this.effectiveDate.setDate(this.effectiveDate.getDate() )
          //   this.effectiveDateV1 =this.effectiveDate.toISOString().split('T')[0]
          //   this.verificationForm
          //   .get('txtEffectiveDate')
          //   .setValue(this.effectiveDateV1)
          // }
          // if(patientInfo?.fascalYearStart!==null &&patientInfo?.fascalYearStart!==undefined ){
          //   this.fascalYearStart=new Date(patientInfo?.fascalYearStart)
          //   this.fascalYearStart.setDate(this.fascalYearStart.getDate() )
          //   this.fascalYearStartV1 =this.fascalYearStart.toISOString().split('T')[0]
          //   this.verificationForm
          //   .get('txtFascalYearStart')
          //   .setValue( this.fascalYearStartV1)
          // }

          // if(patientInfo?.planCob!==null && patientInfo?.planCob!==undefined){
          //   this.planCob=new Date(patientInfo?.planCob)
          //   this.planCob.setDate(this.planCob.getDate() )
          //   this.planCobV1 =this.planCob.toISOString().split('T')[0]
          //   this.verificationForm
          //   .get('txtPlanCob')
          //   .setValue(this.planCobV1)
          // }
          // if(patientInfo?.fascalYearEnd!==null && patientInfo?.fascalYearEnd!==undefined){
          //   this.fascalYearEnd=new Date(patientInfo?.fascalYearEnd)
          //   this.fascalYearEnd.setDate(this.fascalYearEnd.getDate() )
          //   this.fascalYearEndV1 =this.fascalYearEnd.toISOString().split('T')[0]
          //   this.verificationForm
          //   .get('txtEnd')
          //   .setValue(this.fascalYearEndV1)
          // }
          // if(patientInfo?.policyHolderDob!==null &&patientInfo?.policyHolderDob!==undefined ){
          //   this.verificationForm
          //   .get('txtPolicyHolderDob')
          //   .setValue( this.policyHolderDobV1)
          //   this.policyHolderDob=new Date(patientInfo?.policyHolderDob)
          //   this.policyHolderDob.setDate(this.policyHolderDob.getDate() )
          //   this.policyHolderDobV1 =this.policyHolderDob.toISOString().split('T')[0]
          // }
          // this.verifiedOnCurrent=new Date()
          // this.verifiedOnCurrent.setDate(this.verifiedOnCurrent.getDate() )
          // this.verifiedOnCurrentV1 =this.verifiedOnCurrent.toISOString().split('T')[0]
          //   if(patientInfo?.verifiedOn===null){
          //     this.verificationForm.get('txtDateVerified')
          //     .setValue(this.verifiedOnCurrentV1)
          //   } else{
          //     this.verifiedOn=new Date(patientInfo?.verifiedOn)
          //     this.verifiedOn.setDate(this.verifiedOn.getDate())
          //     this.verifiedOnV1 =this.verifiedOn.toISOString().split('T')[0]
          //   this.verificationForm.get('txtDateVerified')
          //   .setValue(this.verifiedOnV1)
          //   }
          //   this.approvedOnCurrent=new Date()
          //   this.approvedOnCurrent.setDate(this.approvedOnCurrent.getDate())
          //   this.approvedOnCurrentV1 =this.approvedOnCurrent.toISOString().split('T')[0]
          //   if( patientInfo?.approvedOn===null){
          //     this.verificationForm.get('txtPreparedOn')
          //     .setValue(this.approvedOnCurrentV1)
          //   } else{
          //     this.approvedOn=new Date(patientInfo?.approvedOn)
          //     this.approvedOn.setDate(this.approvedOn.getDate() )
          //     this.approvedOnV1 =this.approvedOn.toISOString().split('T')[0]
          //   this.verificationForm.get('txtPreparedOn')
          //   .setValue( this.approvedOnV1)
          //   }
           this.payerId=patientInfo?.payerId;
          this.verificationForm.patchValue({
            txtName: patientInfo?.name,
            txtDateOfBirth: patientInfo.dateofBirth1,
            txtAddress:patientInfo?.address,
            txtCommentsNotes: patientInfo?.commentsOrNotes,
            txtPlan: savedPayorLevel,
            txtPlanName: patientInfo?.planName,
            txtPlanPhone: patientInfo?.planPhone,
            txtPolicy: patientInfo?.policy,
            txtGroup: patientInfo?.groupNo??null,
             txtEffectiveDate: patientInfo?.effectiveDate1,
            txtType: patientInfo?.typeOfPolicy,
             txtFascalYearStart: patientInfo?.fascalYearStart1,
             txtEnd: patientInfo?.fascalYearEnd1,
            chkMedicare: patientInfo?.medicareCMNonFile,
            chkPatient: patientInfo?.isPatientHomeHealthEpisode,
            chkFamilyDeductible: patientInfo?.familyDeductCoversIndividual,
            txtCmnCode: patientInfo?.cmnCode,
            drpTypeOfPlan: patientInfo?.typeOfPlan,
            chkIndividual: patientInfo?.isIndividual,
            chkOutOfNetwork: patientInfo?.isOutOfNetwork,
            chkFamily: patientInfo?.isFamily,
            txtDeductibleIndividual: patientInfo?.deductible,
            txtDeductibleOutOfNetwork:
              patientInfo?.oonDeductible,
            txtDeductibleFamily: patientInfo?.familyDeductible,
            txtDeductibleMetIndividual: patientInfo?.deductibleMet,
            txtDeductibleMetOutOfNetwork:
              patientInfo?.oonDeductibleMet,
            txtDeductibleMetFamily: patientInfo?.familyDeductibleMet,
            drpPayableIndividual: patientInfo?.payableAt,
            drpPayableOutOfNetwork: patientInfo?.oonPayableAt,
            drpPayableFamily: patientInfo?.familyPayableAt,
            txtExpenseIndividual: patientInfo?.outOfPocket,
            txtExpenseOutOfNetwork:
              patientInfo?.oonOutOfPocket,
            txtExpenseFamily: patientInfo?.familyOutOfPocket,
            txtExpenseMetIndividual:
              patientInfo?.outOfPocketMet,
            txtExpenseMetOutOfNetwork:
              patientInfo?.oonOutOfPocketMet,
            txtExpenseMetFamily: patientInfo?.familyOutOfPocketMet,
            chkExpenseIndividual:
              patientInfo?.doesOutOfPocketIncludeDed,
            chkExpenseOutOfNetwork:
              patientInfo?.doesOutOfPocketIncludeDed,
            chkExpenseFamily: patientInfo?.doesFamilyOutOfPocketIncludeDed,
            chkPrecertRequired: patientInfo?.isPrecertRequired===null?patientInfo?.isMedicarePolicy===true?false:true
            :patientInfo?.isPrecertRequired,
            txtPhoneCall: patientInfo?.phoneNumber,
            chkReferralRequired: patientInfo?.referralRequired,
            txtPcp: patientInfo?.pcp,
            chkPredetermination: patientInfo?.predetermination,
            txtPreNotes: patientInfo?.predeterminationNotes,
            txtLifetimeMax: patientInfo?.lifetimeMaxBenefit??'0.00',
            txtLifetimeMaxMet: patientInfo?.lifetimeBenefitUsed??'0.00',
            chkDmePartsCovered: patientInfo?.dmeCoverage,
            chkDmeReplacementUnit: patientInfo?.dmeReplacement===1?true:false,
            txtDmeAnnualLimit: patientInfo?.dmeLimitPerYear??'0.00',
            txtDmeLimitMet: patientInfo?.dmeLimitMet??'0.00',
            txtRequestedBy: patientInfo?.requestedBy,
            txtPrimaryPayor: patientInfo?.primaryPayor,
            txtContact: patientInfo?.contact,
            txtCallTrackingNumber: patientInfo?.callTrackingNumber,
            txtPolicyHolderName: patientInfo?.policyHolderName,
            txtPolicyHolderDob: patientInfo?.policyHolderDob1,
            chkNetwork: patientInfo?.isNetwork,
            txtClaimAddress: patientInfo?.claimAddress,
            txtPlanCob: patientInfo?.planCob1,
            txtCoverageCriteria: patientInfo?.coverageCriteria,
            txtQtyLimits: patientInfo?.qtyLimits,
            txtFrequency: patientInfo?.frequency,
            txtRent: patientInfo?.rent,
            txtPaRequired: patientInfo?.paRequired,
            txtCodesRequirePa: patientInfo?.codesRequirePa,
            txtSameOrSimilarInfo: patientInfo?.sameOrSimilarInfo,
            txtSpokeWith: patientInfo?.spokeWith,
            drpVerifiedBy: patientInfo?.verifiedBy!==null?patientInfo?.verifiedBy:this.userId,
             txtDateVerified: patientInfo?.verifiedOn1!==null?patientInfo?.verifiedOn1:new Date(),
            drpPreparedBy: patientInfo?.approvedBy!==null?patientInfo?.approvedBy:this.userId,
             txtPreparedOn: patientInfo?.approvedOn1!==null?patientInfo?.approvedOn1:new Date(),
            rbtnVerificationStatus: patientInfo?.verificationStatus==='New'?null:patientInfo?.verificationStatus,
            txtDeo: patientInfo?.deo,
            txtcommentsToTech: patientInfo?.commentToTech,
            txtVerfnAMethod: patientInfo?.vMethod=='0'?'Manual':patientInfo?.vMethod=='1'?'Electronic':patientInfo?.vMethod,
            // txtDeductibleRemainingFamily:
            //   patientInfo?.de,
            // txtDeductibleRemainingOutOfNetwork:
            //   patientInfo?.deductibleRemaining?.outOfNetwork,
            // txtDeductibleRemainingIndividual:
            //   patientInfo?.deductibleRemaining?.individual,
            // txtRemainingIndividual:
            //   patientInfo?.outOfPocketRemaining?.individual,
            // txtRemainingFamily: patientInfo?.outOfPocketRemaining?.family,
            // txtRemainingOutOfNetwork:
            //   patientInfo?.outOfPocketRemaining?.outOfNetwork,
            txtCoPayment: patientInfo.coPayment,
            txtCoInsurance: patientInfo.coInsurance,
            chkActive: patientInfo?.isActive===1?patientInfo?.isActive:false,
          });
          if (
            this.verificationForm.value.txtFascalYearStart != null ||
            this.verificationForm.value.txtFascalYearStart != '' ||
            this.verificationForm.value.txtFascalYearStart != undefined
          ) {
            this.isGroupData = true;
          } else {
            this.isGroupData = false;
          }

          this.isShowSpinner = false;
          this.isVerificationLoading=false;
        },
        (err) => {
          this.isShowSpinner = false;
          this.isVerificationLoading=false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
      this.isShowSpinner = false;
  }
  isGroupData: boolean = false;
  onChange() {
    // this.verificationForm.controls['txtGroup'].setValidators(
    //   Validators.required
    // );
    this.verificationForm.controls['txtEnd'].setValidators(Validators.required);
    this.isGroupData = true;
  }
  openPverifyModal() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(PatientPverifyTabComponent, {
      disableClose: true,
      data: { patientId: this.patientId, documentId :this.documentId,policyId: this.policyId },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(() => {
      // this.policyId !== defaultGuid && this.getVerificationStatus();
    });
    this.subscription$.push(closeDialog);
  }
  // getVerificationStatus() {
  //   this.isEligibility = true;
  //   const lastVerificationDataSub = this.patientPolicyService
  //     .getLastVerificationDataByPolicyId(this.policyId)
  //     .subscribe(
  //       (response) => {
  //         this.lastVerificationData = response ?? null;
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   this.subscription$.push(lastVerificationDataSub);
  // }
  valueChangeLoadDropdown(type: string) {
    // this.drpPolicies = [];
    this.patientPolicyService
      .getActivePolicyByPatientIdByPatientId(this.patientId)
      .subscribe(
        (response) => {
          const policyDetails: PatientPolicyDTO[] = response;
          this.drpPolicies =
            policyDetails?.filter(
              (x) =>
                (x?.payorLevel?.toLowerCase() ?? '') ===
                (type?.toLowerCase() ?? '')
            ) ?? [];
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  payorValueChangeDropdown(payorName: string) {
    const savedPolicy =
      this.drpPolicies?.filter(
        (x) =>
          (x?.payorName?.toLowerCase() ?? '') ===
          (payorName?.toLowerCase() ?? '')
      )?.[0] ?? null;
    this.verificationForm.patchValue({
      txtPolicy: savedPolicy?.policyNo ?? '',
      txtPlanPhone: savedPolicy?.phone ?? '',
    });
  }
  //Manual Verification Form Load
  createVerificationForm() {
    const currentDate = new Date().toISOString().split('T')[0];
    this.verificationForm = this.formBuilder.group({
      txtName: new FormControl(''),
      txtDateOfBirth: new FormControl('', [Validators.required]),
      txtCommentsNotes: new FormControl(''),
      txtPlan: new FormControl('', Validators.required),
      txtPlanName: new FormControl('', [Validators.required]),
      txtPlanPhone: new FormControl('', [this.numberValidators.isNumberCheck]),
      txtPolicy: new FormControl('', [Validators.required]),
      // txtGroup: new FormControl('', [Validators.required,noWhitespaceValidator]),
      txtGroup: new FormControl(''),
      txtEffectiveDate: new FormControl('', [Validators.required]),
      txtType: new FormControl(null, [Validators.required]),
      txtFascalYearStart: new FormControl(''),
      txtEnd: new FormControl(''),
      chkActive: new FormControl('',[Validators.required]),
      chkMedicare: new FormControl(false),
      chkPatient: new FormControl(false),
      chkFamily: new FormControl(''),
      txtCmnCode: new FormControl('', [this.textValidators.isTextCheck]),
      drpTypeOfPlan: new FormControl(null, [Validators.required]),
      chkIndividual: new FormControl(''),
      chkOutOfNetwork: new FormControl(''),
      chkFamilyDeductible: new FormControl(false),
      txtDeductibleIndividual: new FormControl(''),
      txtDeductibleOutOfNetwork: new FormControl(''),
      txtDeductibleFamily: new FormControl(''),
      txtDeductibleMetIndividual: new FormControl(''),
      txtDeductibleMetOutOfNetwork: new FormControl(''),
      txtDeductibleMetFamily: new FormControl(''),
      drpPayableIndividual: new FormControl(null),
      drpPayableOutOfNetwork: new FormControl(null),
      drpPayableFamily: new FormControl(null),
      txtExpenseIndividual: new FormControl(''),
      txtExpenseOutOfNetwork: new FormControl(''),
      txtExpenseFamily: new FormControl(''),
      txtExpenseMetIndividual: new FormControl(''),
      txtExpenseMetOutOfNetwork: new FormControl(''),
      txtExpenseMetFamily: new FormControl(''),
      chkExpenseIndividual: new FormControl(''),
      chkExpenseOutOfNetwork: new FormControl(''),
      chkExpenseFamily: new FormControl(''),
      chkPrecertRequired: [true],
      txtPhoneCall: new FormControl('', [this.numberValidators.isNumberCheck]),
      chkReferralRequired: new FormControl(false),
      txtPcp: new FormControl('', [this.textValidators.isTextCheck]),
      chkPredetermination: new FormControl(false),
      txtPreNotes: new FormControl(''),
      txtLifetimeMax: new FormControl(''),
      txtLifetimeMaxMet: new FormControl(''),
      chkDmePartsCovered: new FormControl(''),
      chkDmeReplacementUnit: new FormControl(''),
      txtDmeAnnualLimit: new FormControl(''),
      txtDeductibleRemainingFamily: new FormControl(''),
      txtDeductibleRemainingIndividual: new FormControl(''),
      txtDeductibleRemainingOutOfNetwork: new FormControl(''),
      txtRemainingIndividual: new FormControl(''),
      txtRemainingFamily: new FormControl(''),
      txtRemainingOutOfNetwork: new FormControl(''),
      txtCoPayment: new FormControl(''),
      txtCoInsurance: new FormControl(''),
      txtDmeLimitMet: new FormControl(''),
      txtRequestedBy: new FormControl('', [this.textValidators.isTextCheck]),
      txtPrimaryPayor: new FormControl('', [this.textValidators.isTextCheck]),
      txtContact: new FormControl('', [this.numberValidators.isNumberCheck]),
      txtCallTrackingNumber: new FormControl(''),
      txtTypedrp:new FormControl(''),
      txtPlanType :new FormControl(''),
      txtPayable:new FormControl(''),
      txtPayableper:new FormControl(''),
      txtPayablepercent:new FormControl(''),
      txtVerified:new FormControl(''),
      txtPreparedBy:new FormControl(''),
      txtPolicyHolderName: new FormControl('', [
        this.textValidators.isTextCheck,
      ]),
      txtPolicyHolderDob: new FormControl(''),
      chkNetwork: new FormControl(''),
      txtClaimAddress: new FormControl(''),
      txtPlanCob: new FormControl(''),
      txtCoverageCriteria: new FormControl('', [
        this.textValidators.isTextCheck,
      ]),
      txtQtyLimits: new FormControl('', [this.textValidators.isTextCheck]),
      txtFrequency: new FormControl('', [this.textValidators.isTextCheck]),
      txtRent: new FormControl('', [this.textValidators.isTextCheck]),
      txtPaRequired: new FormControl('', [this.textValidators.isTextCheck]),
      txtCodesRequirePa: new FormControl('', [this.textValidators.isTextCheck]),
      txtSameOrSimilarInfo: new FormControl('', [
        this.textValidators.isTextCheck,
      ]),
      txtSpokeWith: new FormControl('', [this.textValidators.isTextCheck]),
      drpVerifiedBy: new FormControl(this.userId , [ Validators.required ]),
      txtDateVerified: new FormControl(currentDate, [Validators.required]),
      drpPreparedBy: new FormControl(this.userId),
      txtPreparedOn: new FormControl(currentDate),
      rbtnVerificationStatus: new FormControl('', [Validators.required]),
      txtDeo: new FormControl('', this.numberValidators.isNumberCheck),
      txtcommentsToTech: new FormControl(''),
      txtVerfnAMethod: new FormControl('Manual', [Validators.required]),
      txtAddress:new FormControl(''),
    });
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() - 1);
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
  }

  //Load Dropdowns
  getDropdown() {

    const orderDropdownInput: OrderDropdowns[] = [
      OrderDropdowns.Charts,
      OrderDropdowns.TypeOfPlans,
      OrderDropdowns.PlanTypes,
      OrderDropdowns.PayablePercents,
      OrderDropdowns.YearTypes,
    ];
    const orderDropdown =
      this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput);
    //To get the Patient and Policy Details
    //Patient Details
    const patientDetails = this.patientPersonalService.get(this.patientId);
    //To get and update patient Dropdowns
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.PayorLevels,
    ];
    const patietDropdown =
      this.patientDropdownService.getPatientDropdownsByInput(
        patientDropdownInput
      );

// const activePolicyDropdown =
// this.patientPolicyService.getPatientPolicyByPatientPolicyIdByPatientIdAndPolicyId(
//   this.patientId,
//   this.policyId
// );


    const combinedObs = forkJoin([
      orderDropdown,
      patientDetails,
      patietDropdown,
      // activePolicyDropdown,
    ]);

    combinedObs.subscribe(
      (response) => {
        const responseData: OrderMasterDropdownDTO = response?.[0];
        this.drpChart = responseData?.charts;
        this.drpPlan = responseData?.typeOfPlans;
        this.drpPayable = responseData?.payablePercents.sort((a, b) =>
          Number(a.payablePercentType)-Number(b.payablePercentType)

          );;
        this.drpPlanType = responseData?.planTypes;
        this.drpYearType = responseData?.yearTypes;

        this.drpTypeSearch=this.drpYearType;
        this.drpPlanTypeSearch=this.drpPlanType;
        this.drpPayableSearch=this.drpPayable;
        this.drpPayableSearchV2=this.drpPayable;
        this.drpPayableSearchV3=this.drpPayable;

        const patientDetails: PatientPersonalDTO = response?.[1];
        this.defaultPatientId = patientDetails?.defaultPatientId;
        this.chartId = patientDetails?.chartId;
        this.verificationForm
        .get('txtDateOfBirth')
        .setValue(new Date(patientDetails?.dateOfBirth).toISOString().split('T')[0]),
        this.verificationForm.patchValue({
          txtName: patientDetails?.fullName,
          // txtDateOfBirth: patientDetails?.dateOfBirth,
        });

        const drpdownResponse: PatientMasterDropdownDTO = response?.[2];
        this.payorLevel = drpdownResponse?.payorLevels;

        // const policyDetails: PatientPolicyDTO[] = response?.[3];
        // this.drpPolicies = response[3];
        // response[3].forEach((element) => {
        //   this.drpPolicyNo = element.policyNo;
        //   this.drpPayorLevel = element.payorLevel;
        //   this.drpPayorName =
        //     element.payorId + ' ' + '/' + ' ' + element.payorName;
        //     this.groupNo=element.groupNo;
        // });
        // this.drpPolicies = policyDetails?.filter(x => (x?.payorLevel?.toLowerCase() ?? "") === (InsuranceType.Primary?.toLowerCase() ?? "")) ?? [];
        // this.verificationForm.patchValue({
        //   txtPlan: this.drpPayorLevel,
        //   txtPlanName: this.drpPayorName,
        //   txtGroup:this.groupNo,
        // });
        // this.verificationForm.patchValue({
        //   txtPolicy: this.drpPolicyNo,
        //   txtPlanPhone: this.drpPhoneNo,
        // });
         this.verificationId !== defaultGuid && this.getInsVerificationByID();
        //this.getInsVerificationByID();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //To Save the Verification Details
  saveInsuranceVerify() {
    this.isShowSpinner = true;
    const formValue = this.verificationForm.value;
    const dateofBirth = this.datepipe.transform(formValue.txtDateOfBirth, 'yyyy-MM-dd');
    const txtEffectiveDate = this.datepipe.transform(formValue.txtEffectiveDate, 'yyyy-MM-dd');
    const txtFascalYearStart = this.datepipe.transform(formValue.txtFascalYearStart, 'yyyy-MM-dd');
    const txtEnd = this.datepipe.transform(formValue.txtEnd, 'yyyy-MM-dd');
    const txtDateVerified = this.datepipe.transform(formValue.txtDateVerified, 'yyyy-MM-dd');
    const txtPreparedOn = this.datepipe.transform(formValue.txtPreparedOn, 'yyyy-MM-dd');
    const txtPolicyHolderDob = this.datepipe.transform(formValue.txtPolicyHolderDob, 'yyyy-MM-dd');
    const txtPlanCob = this.datepipe.transform(formValue.txtPlanCob, 'yyyy-MM-dd');
    const savedData: CreateUpdatePatientVerificationDTO = {
      verificationId: this.verificationId ?? defaultGuid,
      patientId: this.patientId ?? defaultGuid,
      policyId: this.policyId ?? defaultGuid,
      // payerId: this.drpPolicies?.filter(
      //   (x) => x?.payorName === this.verificationForm.value?.txtPlanName
      // )?.[0]?.id ?? defaultGuid,
      payerId:this.payerId,
      chartId: this.chartId,
      name: this.verificationForm.value?.txtName ?? '',
     address: this.verificationForm.value?.txtAddress ?? '',
      dateofBirth: dateofBirth ?? '',
      commentsOrNotes: this.verificationForm.value?.txtCommentsNotes ?? '',
      plan: this.verificationForm.value?.txtPlan ?? '',
      planName: this.verificationForm.value?.txtPlanName ?? '',
      planPhone: this.verificationForm.value?.txtPlanPhone ?? '',
      policy: this.verificationForm.value?.txtPolicy ?? '',
      groupNo: this.verificationForm.value?.txtGroup ?? '',
      effectiveDate:txtEffectiveDate ?? '',
      typeOfPolicy: this.verificationForm.value?.txtType ?? '',
      fascalYearStart:txtFascalYearStart ?? '',
      fascalYearEnd: txtEnd ?? '',
      medicareCMNonFile: this.verificationForm.value?.chkMedicare,
      isPatientHomeHealthEpisode: this.verificationForm.value?.chkPatient ,
      familyDeductCoversIndividual: this.verificationForm.value?.chkFamilyDeductible,
      cmnCode: this.verificationForm.value?.txtCmnCode ?? '',
      typeOfPlan: this.verificationForm.value?.drpTypeOfPlan ?? '',
      isIndividual: +this.verificationForm.value?.chkIndividual ?? 0,
      isOutOfNetwork: +this.verificationForm.value?.chkOutOfNetwork ?? 0,
      isFamily: +this.verificationForm.value?.chkFamily ?? 0,
      deductible: this.verificationForm.value?.txtDeductibleIndividual ?? '',
      familyDeductible: this.verificationForm.value?.txtDeductibleFamily ?? '',
      oonDeductible: this.verificationForm.value?.txtDeductibleOutOfNetwork ?? '',
      deductibleMet: this.verificationForm.value?.txtDeductibleMetIndividual ?? '',
      familyDeductibleMet: this.verificationForm.value?.txtDeductibleMetFamily ?? '',
      oonDeductibleMet: this.verificationForm.value?.txtDeductibleMetOutOfNetwork ?? '',
      payableAt: this.verificationForm.value?.drpPayableIndividual ?? '',
      familyPayableAt: this.verificationForm.value?.drpPayableFamily ?? '',
      oonPayableAt: this.verificationForm.value?.drpPayableOutOfNetwork ?? '',
      outOfPocket: this.verificationForm.value?.txtExpenseIndividual ?? '',
      familyOutOfPocket: this.verificationForm.value?.txtExpenseFamily ?? '',
      oonOutOfPocket: this.verificationForm.value?.txtExpenseOutOfNetwork ?? '',
      outOfPocketMet: this.verificationForm.value?.txtExpenseMetIndividual ?? '',
      familyOutOfPocketMet: this.verificationForm.value?.txtExpenseMetFamily ?? '',
      oonOutOfPocketMet: this.verificationForm.value?.txtExpenseMetOutOfNetwork ?? '',
      doesOutOfPocketIncludeDed: +(this.verificationForm.value?.chkExpenseIndividual ),
      doesFamilyOutOfPocketIncludeDed: +(this.verificationForm.value?.chkExpenseFamily ),
      doesOonOutOfPocketIncludeDed: +(this.verificationForm.value?.chkExpenseOutOfNetwork ),
      // outOfPocketRemaining: {
      //   individual: this.verificationForm.value?.txtRemainingIndividual ?? '',
      //   family: this.verificationForm.value?.txtRemainingFamily ?? '',
      //   outOfNetwork:
      //     this.verificationForm.value?.txtRemainingOutOfNetwork ?? '',
      // },
      // deductibleRemaining: {
      //   family: this.verificationForm.value?.txtDeductibleRemainingFamily ?? '',
      //   individual:
      //     this.verificationForm.value?.txtDeductibleRemainingIndividual ?? '',
      //   outOfNetwork:
      //     this.verificationForm.value?.txtDeductibleRemainingOutOfNetwork,
      // },
      isPrecertRequired: +this.verificationForm.value?.chkPrecertRequired ?? 0,
      phoneNumber: this.verificationForm.value?.txtPhoneCall ?? '',
      referralRequired: this.verificationForm.value?.chkReferralRequired ,
      pcp: this.verificationForm.value?.txtPcp ?? '',
      predetermination: this.verificationForm.value?.chkPredetermination ,
      predeterminationNotes: this.verificationForm.value?.txtPreNotes ?? '',
      lifetimeMaxBenefit: this.verificationForm.value?.txtLifetimeMax ?? '',
      lifetimeBenefitUsed: this.verificationForm.value?.txtLifetimeMaxMet ?? '',
      dmeCoverage: this.verificationForm.value?.chkDmePartsCovered ===true? 1:0,
      dmeReplacement: (this.verificationForm.value?.chkDmeReplacementUnit!==''&&this.verificationForm.value?.chkDmeReplacementUnit!==false)?1:0,
      dmeLimitPerYear: this.verificationForm.value?.txtDmeAnnualLimit !==''? this.verificationForm.value?.txtDmeAnnualLimit: 0,
      dmeLimitMet: this.verificationForm.value?.txtDmeLimitMet!==''? this.verificationForm.value?.txtDmeLimitMet: 0,
      requestedBy: this.verificationForm.value?.txtRequestedBy ?? '',
      primaryPayor: this.verificationForm.value?.txtPrimaryPayor ?? '',
      contact: this.verificationForm.value?.txtContact ?? '',
      callTrackingNumber: this.verificationForm.value?.txtCallTrackingNumber ?? '',
      policyHolderName: this.verificationForm.value?.txtPolicyHolderName ?? '',
      policyHolderDob:txtPolicyHolderDob ?? '',
      isNetwork: +this.verificationForm.value?.chkNetwork ?? 0,
      claimAddress: this.verificationForm.value?.txtClaimAddress ?? '',
      planCob: txtPlanCob ?? '',
      coverageCriteria: this.verificationForm.value?.txtCoverageCriteria ?? '',
      qtyLimits: this.verificationForm.value?.txtQtyLimits ?? '',
      frequency: this.verificationForm.value?.txtFrequency ?? '',
      rent: this.verificationForm.value?.txtRent ?? '',
      paRequired: this.verificationForm.value?.txtPaRequired ?? '',
      codesRequirePa: this.verificationForm.value?.txtCodesRequirePa ?? '',
      sameOrSimilarInfo: this.verificationForm.value?.txtSameOrSimilarInfo ?? '',
      spokeWith: this.verificationForm.value?.txtSpokeWith ?? '',
      verifiedBy: this.verificationForm.value?.drpVerifiedBy??this.userId,
      verifiedOn: txtDateVerified ?? '',
      approvedBy: this.verificationForm.value?.drpPreparedBy===''?this.userId:this.verificationForm.value?.drpPreparedBy,
      approvedOn: txtPreparedOn ?? '',
      verificationStatus: this.verificationForm.value?.rbtnVerificationStatus ?? '',
      deo: this.verificationForm.value?.txtDeo ?? '',
      moveToAuthStatus: '',
      commentToTech: this.verificationForm.value?.txtcommentsToTech ?? '',
      vMethod: this.verificationForm.value?.txtVerfnAMethod ?? '',
      coPayment: this.verificationForm.value?.txtCoPayment ?? '',
      coInsurance: this.verificationForm.value?.txtCoInsurance ?? '',
      isActive: +this.verificationForm.value?.chkActive ?? 0,

      // txtDeductibleRemainingFamily: new FormControl(''),
      // txtDeductibleRemainingIndividual: new FormControl(''),
      // txtDeductibleRemainingOutOfNetwork: new FormControl(''),
      // txtRemainingIndividual: new FormControl(''),
      // txtRemainingFamily: new FormControl(''),
      // txtRemainingOutOfNetwork: new FormControl(''),
      // txtCoPayment: new FormControl(''),
      // txtCoInsurance: new FormControl(''),
      // verifiedTypeMethod: 'Manual',
      eligibilityMode: 1,
      docId: this.documentId || null,
      type:  this.verificationForm.value?.txtType,
      referralPracticeId: 0

    };
    // if (this.data.verificationStatus === "New" || this.data.verificationStatus === "new"|| this.data.verificationStatus === "Pending"|| this.data.verificationStatus === "pending")
    // {
      this.patientVerificationService
        .update(this.verificationId, savedData)
        .subscribe(
          (response) => {
            // Swal.fire({
            //   icon: 'success',
            //   text: "Saved Successfully",
            // });
            // this.communicationService.triggerFunctionCall(this.data.selectedNode);
            this.toastr.success('Updated Successfully', 'Success');
            this.isShowSpinner = false;
            this.dialogRef.close('Saved');
          this.communicationService.triggerFunctionCall(this.data.selectedNode);

          },
          (err) => {
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    // } else {
    //   this.patientVerificationService.create(savedData).subscribe(
    //     (response) => {
    //       this.toastr.success('Saved Successfully', 'Success');
    //       this.isShowSpinner = false;
    //       this.dialogRef.close('Saved');
    //       this.communicationService.triggerFunctionCall(this.data.selectedNode);
    //     },
    //     (err) => {
    //       this.isShowSpinner = false;
    //       const data: HttpErrorResponse = err;
    //       Swal.fire({
    //         icon: 'info',
    //         text: data?.error?.error?.message,
    //       });
    //     }
    //   );
    // }
  }


  //#region Clear Dropdowns
  clearVerifyMethod() {
    this.verfnAMethod = '';
    this.verificationForm.patchValue({ txtVerfnAMethod: '' });
  }
  // clearPlan() {
  //   this.plan = '';
  //   this.verificationForm.patchValue({ txtPlan: '' });
  // }
  clearPayorPlan() {
    this.payorPlan = '';
    this.verificationForm.patchValue({ txtPlanName: '' });
  }
  clearType() {
    this.type = '';
    this.verificationForm.patchValue({ txtType: '' });
  }
  clearTypeOfPlan() {
    this.typeOfPlan = '';
    this.verificationForm.patchValue({ drpTypeOfPlan: '' });
  }
  clearPayablePercentType() {
    this.payablePercentType = '';
    this.verificationForm.patchValue({ drpPayableIndividual: '' });
  }
  clearPayablePercentType1() {
    this.payablePercentType1 = '';
    this.verificationForm.patchValue({ drpPayableFamily: '' });
  }
  clearPayablePercentType2() {
    this.payablePercentType2 = '';
    this.verificationForm.patchValue({ drpPayableOutOfNetwork: '' });
  }

  //#endregion

  //#region Manual Verification Print
    printManulVerfication(){
      window.print();
}
 //#endregion
 changeInsuranceBenift(selectOption:number,event: MatSlideToggleChange){
  if(selectOption ===0){
    if(event.checked){
      this.verificationForm.patchValue({
        chkIndividual:true,
        chkFamily:false,
        chkOutOfNetwork:false
      });
    }else{
      this.verificationForm.patchValue({
        chkIndividual:false
      });
    }
  }else if(selectOption ===1){
    if(event.checked){
    this.verificationForm.patchValue({
      chkIndividual:false,
      chkFamily:true,
      chkOutOfNetwork:false
    });
  }else{
    this.verificationForm.patchValue({
      chkFamily:false
    });
  }
  }else{
    if(event.checked){
   this.verificationForm.patchValue({
    chkIndividual:false,
      chkFamily:false,
      chkOutOfNetwork:true
   });
  } else{
    this.verificationForm.patchValue({
      chkOutOfNetwork:false
    });
  }
  }
 }

 isEmpty(value: any): boolean {
  return (
    value === null ||
    value === undefined ||
    value === '' ||
    value === defaultGuid
  );
}
viewFaxDocument(){
  const url = '/#/patient_preview_tiff_document/' + this.data.defaultFaxId + '/' + this.documentId;
      window.open(url, '_blank');
    }
//  printManulVerficationV1() {
//   let printContents, popupWin;
//   printContents = document.getElementById('verification-form').innerHTML;
//   popupWin = window.open('', '', 'top=0,left=0,height=100%,width=auto');
//   if(popupWin){
//     popupWin.document.open();
//     popupWin.document.write(`
//       <html>
//         <head>
//           <link rel="stylesheet" href="./patient-manual-verification-tab.component.scss" type="text/css" media="print">
//           <body onload="window.print();window.close()">${printContents}</body>
//         </head>
//       </html>
//     `)
//     popupWin.document.close();
//   }
//   }
}
