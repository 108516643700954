import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { ViewPatientLedgerComponent } from '../../view-patient-ledger/view-patient-ledger.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PaymentMethodBillingStatusDTO, PaymentsReportDTO } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/optimization/dto';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ItemPaymentService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { BillingReportService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/optimization/billing-reports';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { map, startWith } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-payments-bin-queue',
  templateUrl: './payments-bin-queue.component.html',
  styleUrls: ['./payments-bin-queue.component.scss']
})
export class PaymentsBinQueueComponent implements OnInit {
  isLoading:boolean=false;
  defaultGuid = defaultGuid;
  paymentSearchForm: FormGroup;
  @Input() inBoundTableData: any[];
  @Input() activeTabBin: any;
  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Output() patientEntryCount: EventEmitter<number> =
  new EventEmitter<number>();
  @ViewChild('tableContainer', { read: ViewContainerRef })
  tableContainer: ViewContainerRef;
  myTable2: DataTables.Api;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement2: DataTableDirective;
  isDataLoaded: boolean = false;
  ltPaymentsReport: PaymentsReportDTO[] = [];
  ltPaymentTypes: PaymentMethodBillingStatusDTO[] = [];
  filteredPaymentTypes: Observable<PaymentMethodBillingStatusDTO[]>;
  subscription$: Subscription[] = [];
  billingPaymentTable: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    language: {
      search: '',
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    columnDefs: [{ targets: 1, orderable: false },
      { targets: [5,6,8,10,11], visible: false }
    ],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Payment List',
        exportOptions: {
          columns: ':not(:first-child)', // Skip the first column
          // columns: function (idx, data, node) {
          //   return idx !== 0 && idx !== 1;
          // }
        },
      },
    ], initComplete: function (this: any) {
      const searchInput = document.querySelector(
        '.dataTables_wrapper .dataTables_filter'
      ) as HTMLElement;
      if (searchInput) {
        searchInput.style.float = 'left';
      }
    },
  };
  constructor(
    private dialog: MatDialog,
    private toaster: ToastrService,
    private renderer: Renderer2,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private billingReportService: BillingReportService,
    private commonService: CommonService,
    private itemPaymentService: ItemPaymentService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.initializeForms();
    this.loadDropdowns();
  }
  ngAfterViewInit(): void {
    if (this.datatableElement2 && this.datatableElement2.dtInstance) {
      this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
        this.addPageNavigationControls();
      });
    } else {
      setTimeout(() =>  this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
        this.addPageNavigationControls();
      }), 500);
    }
  }

  initializeForms() {
    this.paymentSearchForm = this.fb.group({
      // drpStatus: new FormControl(''),
      // txtStatusFilter: new FormControl(''),
      drpMethod: new FormControl(''),
      txtMethodFilter: new FormControl(''),
      txtCreatedStartDate: new FormControl(''),
      txtCreatedEndDate: new FormControl(''),
      txtReferenceNo: new FormControl(''),
      txtRemittanceStartDate: new FormControl(''),
      txtRemittanceEndDate: new FormControl(''),
    });
  }
  loadDropdowns() {
    const paymentMethod = this.billingReportService
      .getPaymentMethodsByBFilter(false)
      .subscribe(
        (response) => {
          this.ltPaymentTypes = response ?? [];
          this.filteredPaymentTypes = this.paymentSearchForm
            .get('txtMethodFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltPaymentTypes?.filter((option) =>
                  option?.statusName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (error) => {
          console.error('Error:', error);
        }
      );
    this.subscription$.push(paymentMethod);
  }

  clearStatus() {
    this.paymentSearchForm.patchValue({
      drpStatus: null,
    });
  }
  clearMethod() {
    this.paymentSearchForm.patchValue({
      drpMethod: null,
    });
    this.buildFilterParams();
  }
  clearCreatedDate() {
    this.paymentSearchForm.patchValue({
      txtCreatedStartDate: null,
      txtCreatedEndDate: null,
    });
  }
  clearRemittanceDate() {
    this.paymentSearchForm.patchValue({
      txtRemittanceStartDate: null,
      txtRemittanceEndDate: null,
    });
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }
  onCreatedDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (
      this.paymentSearchForm.value.txtCreatedStartDate &&
      this.paymentSearchForm.value.txtCreatedEndDate
    ) {
      this.buildFilterParams();
    }
  }

  onRemittanceDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (
      this.paymentSearchForm.value.txtRemittanceStartDate &&
      this.paymentSearchForm.value.txtRemittanceEndDate
    ) {
      this.buildFilterParams();
    }
  }

  buildFilterParams() {
    let paymentMethod =
      this.paymentSearchForm.value.drpMethod === null ||
      this.paymentSearchForm.value.drpMethod === ''
        ? defaultGuid
        : this.paymentSearchForm.value.drpMethod;
    let referenceNumber =
      this.paymentSearchForm?.value?.txtReferenceNo?.trim() ?? '';

    let createdFromDate =
      this.paymentSearchForm?.value?.txtCreatedStartDate == null ||
      this.paymentSearchForm?.value?.txtCreatedStartDate == ''
        ? null
        : this.datepipe.transform(
            this.paymentSearchForm?.value?.txtCreatedStartDate,
            'MM/dd/yyyy'
          );
    let createdToDate =
      this.paymentSearchForm?.value?.txtCreatedEndDate == null ||
      this.paymentSearchForm?.value?.txtCreatedEndDate == ''
        ? null
        : this.datepipe.transform(
            this.paymentSearchForm?.value?.txtCreatedEndDate,
            'MM/dd/yyyy'
          );

    let remittanceFromDate =
      this.paymentSearchForm?.value?.txtRemittanceStartDate == null ||
      this.paymentSearchForm?.value?.txtRemittanceStartDate == ''
        ? null
        : this.datepipe.transform(
            this.paymentSearchForm?.value?.txtRemittanceStartDate,
            'MM/dd/yyyy'
          );

    let remittanceToDate =
      this.paymentSearchForm?.value?.txtRemittanceEndDate == null ||
      this.paymentSearchForm?.value?.txtRemittanceEndDate == ''
        ? null
        : this.datepipe.transform(
            this.paymentSearchForm?.value?.txtRemittanceEndDate,
            'MM/dd/yyyy'
          );
    this.isLoading = true;
    this.getPaymentsReport(
      paymentMethod,
      createdFromDate,
      createdToDate,
      referenceNumber,
      remittanceFromDate,
      remittanceToDate
    );
  }
  getPaymentsReport(
    paymentMethod: any,
    createdFromDate: any,
    createdToDate: any,
    referenceNumber: any,
    remittanceFromDate: any,
    remittanceToDate: any
  ) {
    const paymentReport = this.billingReportService
      .getPaymentsBillingReportByPaymentMethodStatusAndDtCreatedFromDateAndDtCreatedToDateAndSReferenceNumberAndDtRemittanceFromDateAndDtRemittanceToDate(
        paymentMethod,
        createdFromDate,
        createdToDate,
        referenceNumber,
        remittanceFromDate,
        remittanceToDate
      )
      .subscribe(
        (response) => {
          this.inBoundTableData = response ?? [];
          this.inBoundTableData = this.inBoundTableData.map((data) => {
            return {
              ...data,
              createdDate: this.commonService.getFormattedDateTimeZone(
                data.createdDate
              ),
              remittanceDate: this.commonService.getFormattedDateZone(
                data.remittanceDate
              ),
              modifiedDate: this.commonService.getFormattedDateTimeZone(
                data.modifiedDate
              ),
            };
          });
          this.isLoading = false;
        },
        (err) => {
          console.log(err);
          this.isLoading = false;
          this.inBoundTableData = [];
        }
      );

    this.subscription$.push(paymentReport);
  }

  openViewNotesPopup(patientID) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: patientID,
        pageType: 'Patient Note',
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
        //this.getReminderTableData();
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        this.toaster.error(data?.error?.error?.message);
      }
    );
  }
  viewPatientLedger(patientId: string) {
    const dialogRef = this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    // this.dialog.afterAllClosed.subscribe((resposne) => {});

    const closeDialog = dialogRef.afterClosed().subscribe(
      (isSaved: any) => {
        if (isSaved) {
          // this.reloadClaimTable.emit();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }
   //#region DoubleClick Copy Text
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  //#endRegio DoubleClick Copy Text

  getTotalInboundData(event: Event, inboundData: any) {
    const target = event.target as HTMLElement;
    const tr = target.closest('tr');
    const icon = target as HTMLElement;
    this.getDataTableData2(tr, inboundData, icon);
  }

  //#region History Tables

  //DataTable Data Get Method and Toggle Notes Row in Table
  async getDataTableData2(tr: any, inboundData: any, icon: HTMLElement): Promise<void> {
    this.myTable2 = await this.datatableElement2.dtInstance;
    if (tr) {
      const row = this.myTable2.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        icon.innerText = 'add';
      } else {
        // Open this row
        row.child(this.format2(row.data(), inboundData)).show();
        row.child().addClass('no-padding');
        icon.innerText = 'remove';
      }
    }
  }
    //Add Dynamic Row
    format2(data: any, inboundData: any): HTMLElement {
      const table = this.renderer.createElement('table');
      const thead = this.renderer.createElement('thead');
      const tbody = this.renderer.createElement('tbody');

      const thRow = this.renderer.createElement('tr');
       const headers = [
      { text: 'Adjustments($)', key: 'Adjustments' },
      { text: 'Total', key: 'Total' },
      { text: 'Created By', key: 'CreatedBy' },
      { text: 'Last Modified By', key: 'LastModifiedBy' },
      { text: 'Last Modified Date', key: 'LastModifiedDate' },
    ];


      headers.forEach(header => {
        // if (shouldSkipKey(header.key)) return;
        const th = this.renderer.createElement('th');
        this.renderer.appendChild(th, document.createTextNode(header.text));
        this.renderer.appendChild(thRow, th);
      });

      this.renderer.appendChild(thead, thRow);
      this.renderer.appendChild(table, thead);
      this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

      if (!Array.isArray(inboundData)) {
        inboundData = [inboundData];
      }

      inboundData.forEach((value: any) => {
        const trData = this.renderer.createElement('tr');
        const cells = [
                { text: value.adjustments, key: 'Adjustments' },
                { text: value.total, key: 'Total' },
                { text: value.createdBy, key: 'CreatedBy' },
                { text: value.modifiedBy, key: 'LastModifiedBy' },
                { text: value.modifiedDate, key: 'LastModifiedDate' },
              ];

        cells.forEach(cell => {
          // if (shouldSkipKey(cell.key)) return;

          const td = this.renderer.createElement('td');
            this.renderer.appendChild(td, document.createTextNode(cell.text));

          this.renderer.appendChild(trData, td);
        });

        this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
        this.renderer.appendChild(tbody, trData);
      });

      this.renderer.appendChild(table, tbody);
      return table;
    }
    onButtonClick(value:any){

    }
    addPageNavigationControls(){
      let buttonsElement =  $(".dt-buttons")[0];

      // $("<button class='dt-button' id='btnDefaultClms' type='button'><span>Show Default Column</span></button>").appendTo(buttonsElement);
      // $("<button class='dt-button' id='btnAllClms' type='button'><span>Show All Column</span></button>").appendTo(buttonsElement);

      $("<span> : <span>").appendTo(buttonsElement);
      if(this.currentPage == 1)
      {
        $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
        $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
      }
      else
      {
        $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
        $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
       }

      $("<span> Page <span>").appendTo(buttonsElement);
      $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);
      $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
      $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);

      if(this.currentPage >= this.totalPages)
      {
        $("<button disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
        $("<button disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
      }
      else
      {
         $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
         $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
      }

      $('#btnNextPage').on('click', (event) => this.changePage(true));
      $('#btnPrevPage').on('click', (event) => this.changePage(false));
      // $(".buttons-colvis").on('click', (event) => this.fnColumnSelectionClick());
      // $('#btnDefaultClms').on('click', (event) => this.fnGetColumnVisiblity("DEFAULT"));
      // $('#btnAllClms').on('click', (event) => this.fnGetColumnVisiblity("ALL"));
      $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
      // $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
      // $('#btnLastPage').on('click', (event) => this.nevigatePage.emit(this.totalPages));
      $('#btnFirstPage').on('click', (event) => this.nevigatePage(1));
      $('#btnLastPage').on('click', (event) => this.nevigatePage(this.totalPages));
     }
     changePage(isNext:boolean){
      if(isNext)
        this.currentPage++;
      else
        this.currentPage--;
      this.nevigatePage(this.currentPage);
    }

    fnGotoPage(){
      let pageValue = $("#txtGotoPage").val();
      let pageno = parseInt(pageValue.toString());
      if(pageno > 0 && this.currentPage !=  pageno && pageno <= this.totalPages)
      {
        this.nevigatePage(pageno);
      }
    }
    nevigatePage(pageNumber){
      this.currentPage = pageNumber;
      this.buildFilterParams();
    }


}
