import { CreateUpdateInitialFaceToFaceDTO } from './../../../../patient/src/app/patient-proxy/patient/dto/models';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { ShippingTypesService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/shipping-types';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { Subject, Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  VendorDetailsService,
  ProductDetailsService,
} from '../item-proxy/item-management/optimization';
import {
  RequisitionProductDTO,
  CreateUpdateRequisitionDTO,
} from '../item-proxy/item-management/optimization/dto';
import { RequisitionService } from '../item-proxy/item-management/optimization/requisition.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-requisition-form',
  templateUrl: './requisition-form.component.html',
  styleUrls: ['./requisition-form.component.scss'],
})
export class RequisitionFormComponent implements OnInit {
  requisitionForm: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtElements: QueryList<DataTableDirective>;
  dtTrigger: Subject<any> = new Subject<any>();
  notesText = '';
  isDisabledQty: boolean = false;
  itemCount: number = 0;
  requisitionTotal: string = '0';
  requisitionDate: string = '';
  ltShippingMethods: any;
  lstBranch: any;
  ltVendors: any;
  searchCtrl: string = '';
  filteredVendorDetails: Observable<string[]> | undefined;
  filteredShippingMethodDetails: Observable<string[]> | undefined;
  filteredLocationDetails: Observable<string[]> | undefined;
  loadTable = true;
  ltproducts: any = [];
  filteredProductDetails: Observable<string[]> | undefined;
  userId: string = defaultGuid;
  userName: string = '';
  tenantId: string = defaultGuid;
  isSaveBtnDisabled: boolean = false;
  requisitionItemsValidator: boolean = false;
  requisitionItems: RequisitionProductDTO[] = [];
  isReqTableLoaded: boolean = true;
  @ViewChild('tableBody') private tableBody: ElementRef;
  @ViewChild('scrollContainer') private scrollContainer: ElementRef;
  orderTableOptions: any = {
    responsive: true,
    paging: false,
    scrollX: true,
    searching: false,
    scrollCollapse: true,
    scrollY: '300px',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [[0, 'asc']],
    columnDefs: [
      { targets: [0], visible: false },
      { targets: [1], width: '5%' }, // Options
      { targets: [2], width: '10%' }, // Product Code
      { targets: [3], width: '15%' }, // Description
      { targets: [4], width: '10%' }, // Manufacturer
      { targets: [5], width: '10%' }, // Category
      { targets: [6], width: '10%' }, // This Order
      { targets: [7], width: '5%' }, // RecOrderQty
      { targets: [8], width: '5%' }, // Status
      { targets: [9], width: '5%' }, // DailyAvgDisp
      { targets: [10], width: '5%' }, // QOH
      { targets: [11], width: '5%' }, // Await Delivery
      { targets: [12], width: '5%' }, // Pending Patient Order
      { targets: [13], width: '5%' }, // Unit Cost
      { targets: [14], width: '5%' }, // Total Cost
    ],
  };
  subscription$: Subscription[] = [];
  editableRowIndex: number | null = null;
  searchControl = new FormControl();
  filteredOptions: any;
  location: string = '';
  manufacturer: string = '';
  shippingMethod: string = '';
  constructor(
    private formBuilder: FormBuilder,
    private TableService: TableService,
    private title: Title,
    private shippingMethodService: ShippingTypesService,
    private branchService: OrganizationUnitService,
    private vendorService: VendorDetailsService,
    private productDetailsService: ProductDetailsService,
    private toastr: ToastrService,
    private requisitionService: RequisitionService
  ) {
    //To search the products inisde the products dropdown
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.filterProducts();
      });
  }

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.userId = localStorage.getItem('userId') ?? '';
    this.title.setTitle('Qsecure | Create Requisition');
    this.initializeForms();
    this.loadUserDetails();
    this.loadDropdowns();
    this.getDefaultFormDetails();
    this.validateItemsList();
    console.log('ngOnInit..form..');
  }

  //To Load the user details patch it to the requisition form
  loadUserDetails() {
    //To patch the default fields
    this.TableService.getLoginUserName().subscribe((val) => {
      this.userName = val;
      this.requisitionDate = moment(new Date()).format('MM/DD/YYYY');
      this.requisitionForm.patchValue({
        txtRequisitionDate: this.requisitionDate,
        txtRequestBy: this.userName,
      });
    });
  }
  initializeForms() {
    this.requisitionForm = this.formBuilder.group({
      txtId: new FormControl(''),
      txtRequisitionDate: new FormControl(''),
      txtRequestBy: new FormControl(''),
      drpShippingMethod: new FormControl('', [Validators.required]),
      txtShippingMethodFilter: new FormControl(''),
      drpLocation: new FormControl('', [Validators.required]),
      txtLocationFilter: new FormControl(''),
      txtInventoryLimit: new FormControl(''),
      txtOnHandTotal: new FormControl(''),
      txtOnOrder: new FormControl(''),
      txtRequisitionTotal: new FormControl(''),
      txtSpecialInstructions: new FormControl(''),
      txtNoofDays: new FormControl(''),
      txtManufacturer: new FormControl(''),
      txtManufacturerFilter: new FormControl(''),
      txtProductFilter: new FormControl(''),
    });
  }
  //To Validate the Items List
  validateItemsList() {
    this.requisitionItemsValidator =
      this.requisitionItems.filter((a) => a.productId != '').length > 1
        ? true
        : false;
  }

  get isSubmitButtonEnabled(): boolean {
    // Check both form validity and items list condition
    return (
      this.requisitionForm.valid &&
      this.requisitionItems.filter((a) => a.productId != '').length > 0 &&
      this.requisitionItems
        .filter((a) => a.productId != '')
        .every(
          (item) =>
            item.thisOrder.toString() !== '0' &&
            item.thisOrder !== null &&
            item.thisOrder.toString() !== ''
        )
    );
  }
  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData('text');
    const isNumber = /^\d+$/.test(pastedData); //Should contain only whole numbers

    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }

  //After View In it function
  ngAfterViewInit(): void {
    this.requisitionItems.push(this.addEmptyObject());
    this.dtTrigger.next();
    //this.reloadDatatable();
  }
  //Re Initialize the Datatable
  reloadDatatable() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns.adjust().draw();
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
      // setInterval(this.scrollToBottom, 1000);
    });
    // this.scrollToBottom();
  }
  test(){
    this.scrollToBottom();
  }
  private scrollToBottom(): void {
    const tableWrapper = document.querySelector(
      '.dataTables_scrollBody'
    ) as HTMLElement;
    if (tableWrapper) {
      tableWrapper.scrollTop = tableWrapper.scrollHeight;
      //this.reloadDatatable();
    }
  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }

  //To Load the Dropdowns
  loadDropdowns() {
    //Load Shipping Methods
    const shippingMethodDetails = this.shippingMethodService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltShippingMethods = response?.items;
          this.filteredShippingMethodDetails = this.requisitionForm
            .get('txtShippingMethodFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltShippingMethods?.filter((option) =>
                  option?.description
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(shippingMethodDetails);

    //Branch List
    const getBranchList = this.branchService
      .getBranchListV1(this.tenantId, false)
      .subscribe((response) => {
        this.lstBranch = response;
        this.lstBranch = response.sort((a, b) =>
          a.organizationUnitName.localeCompare(b.organizationUnitName)
        );
        this.filteredLocationDetails = this.requisitionForm
          .get('txtLocationFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstBranch?.filter((option) =>
                option?.organizationUnitName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    this.subscription$.push(getBranchList);
    //Manufacturer List
    const vendorDetails = this.vendorService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.ltVendors = response?.items;
          this.ltVendors = response.items.sort((a, b) =>
            a.vendorName.localeCompare(b.vendorName)
          );
          this.filteredVendorDetails = this.requisitionForm
            .get('txtManufacturerFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.ltVendors?.filter((option) =>
                  option?.vendorName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(vendorDetails);

    //products Search List
    const productsList = this.productDetailsService
      .getProductListforStock()
      .subscribe(
        (response) => {
          this.ltproducts = response;
          this.filteredOptions = [...this.ltproducts];
        },
        (err) => {}
      );
    this.subscription$.push(productsList);
  }

  //To Load the Product Details based on the dropdown selection
  loadProductDetails(data: any, productId: string, thisordervalue: string) {
    this.isSaveBtnDisabled = true;

    const location = this.requisitionForm.value.drpLocation ?? defaultGuid;
    const noOfDays = this.requisitionForm.value.txtNoofDays ?? 0;
    this.searchControl.setValue('');
    if (
      !(
        this.requisitionItems.filter((a) => a.productId === productId).length >
        1
      )
    ) {
      //Get Product Details Based on the Product Id
      this.requisitionService
        .getProductDetailsV1ByIProductIdAndOrgIdAndNoOfDays(
          productId,
          location,
          noOfDays
        )
        .subscribe(
          (response) => {
            let value = response?.[0];

            if (value != null && value != undefined) {
              data.availQty = value?.availQty;
              data.category = value?.category;
              data.dailyAvgDesp = value?.dailyAvgDesp;
              data.description = value?.description;
              data.extendedCost = value?.extendedCost;
              data.manufactureId = value?.manufactureId;
              data.manufacturer = value?.manufacturer;
              data.pendPatOrder = value?.pendPatOrder;
              data.productCategoryId = value?.productCategoryId;
              data.productCode = value?.productCode;
              data.productId = value?.productId;
              data.qtyOnHand = value?.qtyOnHand;
              data.quantity = value?.quantity;

              data.thisOrder =
              data.thisOrder > 1 ? data.thisOrder : value?.thisOrder;
              data.recOrderQty = value?.recOrderQty;
              data.status = value?.status;
              // data.thisOrder = thisordervalue;
              data.unitCost = value?.unitCost;
              this.requisitionItems.filter((a) => a.productId === '').length ===
                0 && this.requisitionItems.push(this.addEmptyObject());

              this.reloadDatatable();
              this.updateQuantity(data, data.thisOrder);
              setTimeout(() => {
                this.scrollToBottom();
              }, 1);
            }
            this.isSaveBtnDisabled = false;
          },
          (err) => {
            this.isSaveBtnDisabled = false;
          }
        );
    } else {
      this.isSaveBtnDisabled = false;
      data.productId = '';
      this.toastr.warning('Product Already Added!');
    }
  }
  //Update Product Quantity Method
  updateQuantity(data: any, thisOrderValue: any) {
    data.thisOrder = thisOrderValue;
    data.totalCost = (data.thisOrder * data.unitCost).toFixed(2);
    this.calculateRequisitionTotal();
  }
  //To get the Inventory Limit Details
  getInventoryLimitDetails() {
    this.location = this.requisitionForm.value.drpLocation;
    this.requisitionService
      .getInventoryLimitV1(this.requisitionForm.value.drpLocation)
      .subscribe(
        (response) => {
          this.requisitionForm.patchValue({
            txtInventoryLimit: response?.inventoryLimit,
            txtOnHandTotal: response?.onHandTotal,
            txtOnOrder: response?.onOrder,
          });
        },
        (err) => {
          this.requisitionForm.patchValue({
            txtInventoryLimit: 0,
            txtOnHandTotal: 0,
            txtOnOrder: 0,
          });
        }
      );
  }
  //Get Inventory Details
  getDefaultFormDetails() {
    console.log('getDefaultFormDetails');
    this.requisitionService.getDefaultRequisitionDetails().subscribe(
      (response) => {
        this.requisitionForm.patchValue({
          drpShippingMethod: response?.shippingMethodId,
          //40, //"42af283f-70f4-42df-416b-3a0f4c44ee1f" 20
          drpLocation:
            response?.locationId != defaultGuid ? response?.locationId : '',
          txtInventoryLimit: response?.inventoryLimit,
          txtOnHandTotal: response?.onHandTotal,
          txtOnOrder: response?.onOrder,
          txtNoofDays: 4,
        });
        this.location = response?.locationId ?? '';
        this.shippingMethod = response?.shippingMethodId ?? '';
      },
      (err) => {
        this.requisitionForm.patchValue({
          drpShippingMethod: defaultGuid,
          drpLocation: defaultGuid,
          txtInventoryLimit: 0,
          txtOnHandTotal: 0,
          txtOnOrder: 0,
          txtNoofDays: 4,
        });
        this.location = '';
        this.shippingMethod = '';
      }
    );
  }

  //To Calculate the requistion total and patch in the field
  calculateRequisitionTotal() {
    const totalCost: number = this.requisitionItems
      .filter((a) => a.productId != '')
      .reduce((acc, item) => acc + item.thisOrder * item.unitCost, 0);

    this.requisitionTotal = totalCost.toFixed(2).toString();

    this.requisitionForm.patchValue({
      txtRequisitionTotal: this.requisitionTotal,
    });
  }

  //Method to add the Empty Row to the Table
  addEmptyObject(): RequisitionProductDTO {
    this.itemCount++;
    let value: RequisitionProductDTO = {
      sortNumber: this.itemCount,
      availQty: 0,
      category: '',
      dailyAvgDesp: 0,
      description: '',
      extendedCost: 0,
      manufactureId: '',
      manufacturer: '',
      pendPatOrder: 0,
      productCategoryId: '',
      productCode: '',
      productId: '',
      qtyOnHand: 0,
      quantity: 0,
      recOrderQty: 0,
      status: '',
      thisOrder: 1,
      unitCost: 0,
    };
    return value;
  }
  //Method to submit the Requisition Form
  submitRequisition() {
    //this.requisitionItems.pop();
    // this.requisitionItems.filter(
    //   (a) => a.productId !== ''
    // );

    this.isSaveBtnDisabled = true;
    let RequisitionData: CreateUpdateRequisitionDTO = {
      requisitionId: defaultGuid,
      defaultRequisitionId: 0,
      requisitionDate: this.requisitionDate,
      requestedUserId: this.userId,
      shippingMethodId: this.requisitionForm?.value?.drpShippingMethod,
      mmRefLocId: this.requisitionForm?.value?.drpLocation,
      requisitionTotal: Number(this.requisitionTotal),
      itemList: this.requisitionItems.filter((a) => a.productId !== ''),
      noOfDays: null,
      specialInstruction: this.requisitionForm?.value?.txtSpecialInstructions,
      inventoryLimit: this.requisitionForm?.value?.txtInventoryLimit,
      onHandTotal: this.requisitionForm?.value?.txtOnHandTotal,
      onOrder: this.requisitionForm?.value?.txtOnOrder,
      requisitionStatusDetails: null,
    };

    this.requisitionService.create(RequisitionData).subscribe(
      (response) => {
        this.toastr.success('Requisition submitted successfully!');
        this.isSaveBtnDisabled = false;
        this.resetForm();
      },
      (err) => {
        this.isSaveBtnDisabled = false;
        const data: HttpErrorResponse = err;
        // const message = JSON.parse(data?.error);
        const message = data?.error;
        Swal.fire({
          icon: 'info',
          text: message?.error?.message,
        });
      }
    );
  }

  //Reset Form
  resetForm() {
    this.requisitionForm.reset();
    this.getDefaultFormDetails();
    this.loadUserDetails();
    this.requisitionItems = [];
    this.requisitionTotal = '0';

    this.reloadDatatable();
    this.requisitionItems.push(this.addEmptyObject());
  }

  //Allow only numbers
  numberOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (
        value === null ||
        value === undefined ||
        Validators.required(control) !== null
      ) {
        // If the field is required or empty, don't perform number-only validation
        return null;
      }

      // Regular expression for numbers only
      const numberRegExp = /^[0-9]+$/;

      if (!numberRegExp.test(value.toString())) {
        return { numberOnly: true };
      }

      return null;
    };
  }

  //Method To Search the Products in Dropdown
  filterProducts(): void {
    const searchValue = this.searchControl.value.toLowerCase();
    this.filteredOptions = this.ltproducts.filter((option) =>
      option.productName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  //To get the Recommended Quantity of the Requested Items and reload the quantities
  getRecommendedQuantityforItems() {
    const days = this.requisitionForm.value.txtNoofDays;
    const requisitionValues = this.requisitionItems.filter(
      (a) => a.productId !== ''
    );
    //To check if the requisition items are present or not
    if (requisitionValues.length > 0) {
      const thisOrderUpdate = this.requisitionService
        .calculateRequisitionQtyByNoOfDaysByLtItemsListAndNoOfDays(
          requisitionValues,
          days
        )
        .subscribe(
          (response) => {
            response = response.map((data, index) => ({
              sortNumber: index + 1,
              ...data,
            }));

            response.forEach((item) => {
              this.updateQuantity(item, item.thisOrder);
            });

            this.requisitionItems = response;
            this.requisitionItems.push(this.addEmptyObject());
            this.reloadDatatable();
          },
          (err) => {}
        );
      this.subscription$.push(thisOrderUpdate);
    }
  }

  //Smart Requisition
  smartRequisition() {
    this.isReqTableLoaded = false;
    const smartReqDetails = this.requisitionService
      .getSmartRequitionListByGManIdAndLocationIdAndNoofDays(
        this.requisitionForm.value.txtManufacturer,
        this.requisitionForm.value.drpLocation,
        this.requisitionForm.value.txtNoofDays
      )
      .subscribe(
        (response) => {
          this.isReqTableLoaded = true;
          response = response.map((data, index) => ({
            sortNumber: index + 1,
            ...data,
          }));
          this.itemCount = response.length;
          this.requisitionItems = response;

          response.forEach((item) => {
            this.updateQuantity(item, item.thisOrder);
          });

          this.requisitionItems.push(this.addEmptyObject());
          this.reloadDatatable();
        },
        (err) => {
          this.isReqTableLoaded = true;
          this.requisitionItems = [];
          this.requisitionItems.push(this.addEmptyObject());
          this.requisitionItems.forEach((item) => {
            this.updateQuantity(item, item.thisOrder);
          });
          this.reloadDatatable();
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
        }
      );
    this.subscription$.push(smartReqDetails);
  }

  //Button Validation for Smart Requisition
  get isSmartRequisitionButtonDisabled(): boolean {
    return this.requisitionForm.value.txtManufacturer === null ||
      this.requisitionForm.value.txtManufacturer === undefined ||
      this.requisitionForm.value.txtManufacturer === '' ||
      this.requisitionForm.value.txtManufacturer === defaultGuid ||
      this.requisitionForm.value.drpLocation === null ||
      this.requisitionForm.value.drpLocation === undefined ||
      this.requisitionForm.value.drpLocation === '' ||
      this.requisitionForm.value.drpLocation === defaultGuid
      ? true
      : false;
  }

  //To load the Product List on button click
  loadProductList() {
    this.isReqTableLoaded = false;
    const productList = this.requisitionService
      .btnProdListClickByManufacturerIdAndLocationIdAndNoofDays(
        this.requisitionForm.value.txtManufacturer,
        this.requisitionForm.value.drpLocation,
        this.requisitionForm.value.txtNoofDays
      )
      .subscribe(
        (response) => {
          response = response.map((data, index) => ({
            sortNumber: index + 1,
            ...data,
          }));
          this.itemCount = response.length;
          this.requisitionItems = response;
          response.forEach((item) => {
            this.updateQuantity(item, item.thisOrder);
          });
          this.requisitionItems.push(this.addEmptyObject());
          this.isReqTableLoaded = true;
          this.reloadDatatable();
        },
        (err) => {
          this.isReqTableLoaded = true;
          this.requisitionItems = [];
          this.requisitionItems.push(this.addEmptyObject());
          this.requisitionItems.forEach((item) => {
            this.updateQuantity(item, item.thisOrder);
          });
          this.reloadDatatable();
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
        }
      );
    this.subscription$.push(productList);
  }

  //To remove the product from the requisition items list
  removeProduct(productId: string) {
    if (productId != '') {
      this.requisitionItems = this.requisitionItems.filter(
        (a) => a.productId != productId
      );
      this.calculateRequisitionTotal();
      this.reloadDatatable();
      this.toastr.success('Product Removed');
    } else {
      this.toastr.error('Cannot Remove Empty Row');
    }
  }

  // clear dropdown
  clearLocation() {
    this.location = '';
    this.requisitionForm?.patchValue({ drpLocation: '' });
  }
  clearManufacturer() {
    this.manufacturer = '';
    this.requisitionForm?.patchValue({ txtManufacturer: '' });
  }
  clearShippingMethod() {
    this.shippingMethod = '';
    this.requisitionForm?.patchValue({ drpShippingMethod: '' });
  }
  getProdCode(productId): string {
    const val = this.ltproducts?.find((a) => a.productId == productId);
    return val?.productCode ?? '';
  }
}
