
<div id="verification-form" class="verification-form">
  <div class="row">
  <div class="col-4">
    <h2 class="font-weight-bolder">Insurance Verification Form</h2>
  </div>
  <div class="col-8 d-inline-flex justify-content-end align-items-end">
    <div class="col-4 text-right">
      <h2 class="heading-style font-weight-bolder">Tax ID : <span class="textColor font-weight-bolder">{{ mmTaxId ? mmTaxId : ' - ' }}</span></h2>
    </div>
    <div class="col-4 text-right">
      <h2 class="heading-style font-weight-bolder">NPI ID : <span class="textColor font-weight-bolder">{{ npi ? npi : ' - ' }}</span></h2>
    </div>
  <button mat-button  (click)="openPverifyModal()" [hidden]="!isViewVerification || verificationForm.get('txtVerfnAMethod')?.value !=='Electronic'"
  class="lastSpace buttonColor waves-effect font-size-12 mb-2 mr-2 btn-sm">
  <i class="fa fa-check mr-1"></i>
  Electronic Eligibility Check
  </button>
</div>
<div class="row" style="padding-left :29px;">
  <h4 class="text-primary">Monitor Medical, Inc - 12705 South Kirkwood RD, Ste. 203, Stafford, TX 77477  <span class="text-danger headText" > PTAN: 0434450001</span></h4>
</div>
<div class="col-12">
  <mat-divider></mat-divider>
</div>
<!-- <button mat-button (click)="testFunc()">Test</button> -->
  </div>
  <mat-dialog-content class="mat-typography">
  <form [formGroup]="verificationForm">
    <div class="row">
      <div class="col-6">
        <h4 class="customThemeClass"><b>Patient Chart: {{chartId}}</b></h4>
      </div>
      <div class="col-6">
        <h4 class="customThemeClass"><b>Lookup Patient Ins</b></h4>
      </div>
    </div>
    <div class="row">
      <mat-form-field class="col-3">
        <mat-label>Patient Name</mat-label>
        <input autocomplete="new-txtName" formControlName="txtName" maxlength="80" matInput type="text" readonly pattern="^[^0-9]*$">
        <mat-error *ngIf="
          verificationForm?.get('txtName')?.errors?.sAlphabets
                      ">
          Numbers not allowed!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-1">
        <mat-label [ngClass]="{ 'is-invalid': verificationForm?.get('txtDateOfBirth')?.invalid }">Date Of Birth<span class="asterisk">*</span></mat-label>
        <input autocomplete="new-txtDateOfBirth" readonly formControlName="txtDateOfBirth" disabled=true
        matInput placeholder="MM/DD/YYYY" [matDatepicker]="txtDateOfBirth"
          [min]="BirthdateMinDate" maxlength="10"
          onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')">
        <mat-datepicker-toggle matSuffix [for]="txtDateOfBirth">
        </mat-datepicker-toggle>
        <mat-datepicker #txtDateOfBirth></mat-datepicker>
        <mat-error *ngIf="
          verificationForm.get('txtDateOfBirth')?.errors">
          Enter Valid date
        </mat-error>
        <mat-error *ngIf="
          verificationForm.get('txtDateOfBirth')?.errors?.required
          ">
          Date Of Birth is a required field!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-1_5 address">
        <mat-label> Address</mat-label>
        <textarea
          formControlName="txtAddress"
          rows="2"
          matInput
          maxlength="200"
          readonly
        ></textarea>
      </mat-form-field>
      <mat-form-field class="col-1">
        <mat-label [ngClass]="{ 'is-invalid': verificationForm?.get('txtPlan')?.invalid }"> Plan <span class="asterisk">*</span></mat-label>
        <input autocomplete="new-txtPlan" formControlName="txtPlan"  matInput type="text"
          maxlength="75" readonly>
        <!-- <mat-select  formControlName="txtPlan" (valueChange)="valueChangeLoadDropdown($event)" [(ngModel)]="plan">
          <mat-option *ngFor="let payor of payorLevel" [(value)]="payor.payorLevelName">
            {{ payor.payorLevelName }}
          </mat-option> -->
        <!-- </mat-select> -->
        <!-- <button mat-button (click)="plan=undefined;$event.stopPropagation()" *ngIf="plan" matSuffix
          (click)="clearPlan()" mat-icon-button >
          <mat-icon>close</mat-icon>
        </button> -->
        <mat-error *ngIf="
          verificationForm.get('txtPlan')?.errors?.required
          ">
          Plan is a required field!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-3_5">
        <mat-label [ngClass]="{ 'is-invalid': verificationForm?.get('txtPlanName')?.invalid }"> Payor Plan <span class="asterisk">*</span></mat-label>
        <input autocomplete="new-txtPlanName" formControlName="txtPlanName" matInput type="text" readonly
          [matTooltip]="verificationForm.get('txtPlanName')?.value">
        <!-- <mat-select formControlName="txtPlanName" (valueChange)="payorValueChangeDropdown($event)"  [(ngModel)]="payorPlan" >
          <mat-option *ngFor="let payor of drpPolicies" [value]="payor.payorName">
            {{ payor.payorName }}
          </mat-option>
        </mat-select> -->
        <button mat-button (click)="payorPlan=undefined;$event.stopPropagation()" *ngIf="payorPlan" matSuffix
          (click)="clearPayorPlan()" mat-icon-button >
          <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="
          verificationForm.get('txtPlanName')?.errors?.required
          ">
          Payor Plan is a required field!
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-1_25">
        <mat-label [ngClass]="{ 'is-invalid': verificationForm?.get('txtPolicy')?.invalid }">Policy # <span class="asterisk">*</span></mat-label>
        <input autocomplete="new-txtPolicy" formControlName="txtPolicy" maxlength="75" matInput type="text" readonly>
        <mat-error *ngIf="
          verificationForm.get('txtPolicy')?.errors?.required
          ">
          Policy Name is a required field!
        </mat-error>

        <mat-error *ngIf="
        verificationForm.get('txtPolicy')?.errors?.sAlphabets
        ">
          Enter only Alphabets!
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col-6">

        <mat-form-field class="col-12 comments">
          <mat-label>Comments/Notes</mat-label>
          <textarea matInput maxlength="5000" [(ngModel)]="notesText"
          (input)="updateCharacterCount()" formControlName="txtCommentsNotes" rows="5"></textarea>
        </mat-form-field>
        <div class="char-countV1">
            {{ notesText?.length }} / 5000
          </div>
      </div>
      <div class="col-6">
        <div class="row">
          <mat-form-field class="col-4">
            <mat-label> Plan Phone</mat-label>
            <input autocomplete="new-txtPlanPhone" formControlName="txtPlanPhone" prefix="+1-" mask="(000)-000-0000" matInput type="text"
              maxlength="40">
            <mat-error *ngIf="
                  verificationForm.get('txtPlanPhone')?.errors
                  ">
              Enter 10 digit Numbers!
            </mat-error>
          </mat-form-field>
           <mat-form-field class="col-4">
          <mat-label *ngIf="isGroupData != true">
            Group # </mat-label>
          <mat-label *ngIf="isGroupData == true" >Group #  </mat-label>
          <input autocomplete="new-txtGroup" formControlName="txtGroup"  maxlength="20" matInput type="text">
          <mat-error *ngIf="
            verificationForm.get('txtGroup')?.errors?.pattern
            ">
            Special Characters Not Allowed!
          </mat-error>
           </mat-form-field>
           <mat-form-field class="col-4">
          <!-- [min]="BirthdateMinDate" -->
          <mat-label [ngClass]="{ 'is-invalid': verificationForm?.get('txtEffectiveDate')?.invalid }">Effective Date <span class="asterisk">*</span></mat-label>
          <input autocomplete="new-txtEffectiveDate"  formControlName="txtEffectiveDate" matInput placeholder="MM/DD/YYYY"
            [matDatepicker]="txtEffectiveDate" maxlength="10"
            onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')" >
          <mat-datepicker-toggle matSuffix [for]="txtEffectiveDate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtEffectiveDate></mat-datepicker>
          <mat-error *ngIf="
               verificationForm.get('txtEffectiveDate')?.errors?.required
               ">
            Effective Date is a required field!
          </mat-error>
           </mat-form-field>

        </div>
        <div class="row">
          <mat-form-field class="col-4">
            <mat-label [ngClass]="{ 'is-invalid': verificationForm?.get('txtType')?.invalid }">Type<span class="asterisk">*</span></mat-label>
            <mat-select maxlength="40" [(value)]="type" formControlName="txtType">
              <mat-option>
                <ngx-mat-select-search [formControl]="TypeControl" placeholderLabel="Search"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtTypedrp"></ngx-mat-select-search>
            </mat-option>
              <mat-option *ngFor="let payor of drpYearType" [value]="payor.shortCodeId">
                {{ payor.yearType }}
              </mat-option>
            </mat-select>
            <button mat-button (click)="type=undefined;$event.stopPropagation()" *ngIf="type" matSuffix
              (click)="clearType()" mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="
                verificationForm.get('txtType')?.errors?.required
                ">
              Type is a required field!
            </mat-error>
             </mat-form-field>
          <mat-form-field class="col-4">
            <mat-label>Fiscal Year Start</mat-label>
            <!-- [min]="BirthdateMinDate"  -->
            <input autocomplete="new-txtFascalYearStart" (dateChange)="onChange()" formControlName="txtFascalYearStart" matInput placeholder="MM/DD/YYYY"
              [matDatepicker]="txtFascalYearStart" maxlength="10"
              onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
              >
            <mat-datepicker-toggle matSuffix [for]="txtFascalYearStart">
            </mat-datepicker-toggle>
            <mat-datepicker #txtFascalYearStart></mat-datepicker>
            <mat-error *ngIf="
                verificationForm.get('txtFascalYearStart')?.errors
                ">
                Enter valid Date!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-4">
            <!-- <mat-label *ngIf="isGroupData != true">End</mat-label> -->
            <!-- <mat-label *ngIf="isGroupData == true" >End<span class="asterisk">*</span></mat-label> -->
            <mat-label >End</mat-label>
            <input autocomplete="new-txtEnd" formControlName="txtEnd" placeholder="MM/DD/YYYY" matInput [matDatepicker]="txtEnd"
            maxlength="10"
            onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')">
            <mat-datepicker-toggle matSuffix [for]="txtEnd">
            </mat-datepicker-toggle>
            <mat-datepicker #txtEnd></mat-datepicker>
            <mat-error *ngIf="
            verificationForm.get('txtEnd')?.errors
            ">
            Enter valid Date!
        </mat-error>
          </mat-form-field>
        </div>

      </div>

    </div>

    <div class="row">
      <div class="col-12">
        <mat-card-content>
          <div class="row">

            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkMedicare">Medicare CMN on file?</mat-checkbox>
            </div>
            <div class="col-3">
              <br>
              <!-- <mat-checkbox formControlName="chkPatient">Is Patient Home Health Episode?</mat-checkbox> -->
              <mat-checkbox formControlName="chkPatient"><p class="mb-0" style="text-wrap: wrap;">Is Patient Home Health Episode?</p></mat-checkbox>
            </div>

            <div class="col-4">
              <br>
              <mat-checkbox formControlName="chkFamilyDeductible"><p class="mb-0" style="text-wrap: wrap;">Does Family Deductible cover Individual Deductible?</p>
              </mat-checkbox>
            </div>
            <div class="col-2">
              <br>
              <mat-checkbox formControlName="chkActive">  <span [ngClass]="{ 'is-invalid': showInvalid }" class="active">Active <span class="asterisk">*</span>
              </span></mat-checkbox>
            </div>

            <mat-form-field class="col-3">
              <mat-label>CMN Code</mat-label>
              <input autocomplete="new-txtCmnCode" matInput maxlength="50" formControlName="txtCmnCode" type="text">
              <mat-error *ngIf="

            verificationForm.get('txtCmnCode')?.errors?.sAlphabets
            ">
            Enter only Alphabets!
          </mat-error>
            </mat-form-field>


            <mat-form-field class="col-3">
              <mat-label [ngClass]="{ 'is-invalid': verificationForm?.get('drpTypeOfPlan')?.invalid }"> Type Of Plan <span class="asterisk">*</span></mat-label>
              <mat-select [(value)]="typeOfPlan" formControlName="drpTypeOfPlan">
              <mat-option>
                  <ngx-mat-select-search [formControl]="PlanTypeControl" placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtPlanType"></ngx-mat-select-search>
              </mat-option>
                <mat-option *ngFor="let payor of drpPlanType" [value]="payor.planType">
                  {{ payor.planType }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="typeOfPlan=undefined;$event.stopPropagation()" *ngIf="typeOfPlan" matSuffix
                (click)="clearTypeOfPlan()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="
                    verificationForm.get('drpTypeOfPlan')?.errors?.required
                    ">
                Type Of Plan is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DEO</mat-label>
              <input autocomplete="new-txtDeo" matInput maxlength="40" formControlName="txtDeo" type="text">
              <mat-error *ngIf="
                verificationForm.get('txtDeo')?.errors?.number
                ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>

          </div>
        </mat-card-content>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-12">
        <!-- <div class="row">
          <div class="col-md-6"> -->
            <h4 class="customThemeClass"><b>Insurance Benefit Info</b></h4>
          <!-- </div>
          <div class="col-md-6">
          </div>
        </div> -->
        <mat-card-content>
          <div class="row" style="margin-top: -3rem;">
            <div class="col-3">
            </div>
            <div class="col-3">
              <!-- <br> -->
              <mat-checkbox formControlName="chkIndividual" (change)="changeInsuranceBenift(0,$event)">Individual</mat-checkbox>
            </div>
            <div class="col-3">
              <!-- <br> -->
              <mat-checkbox formControlName="chkFamily" (change)="changeInsuranceBenift(1,$event)">Family</mat-checkbox>
            </div>
            <div class="col-3">
              <!-- <br> -->
              <mat-checkbox formControlName="chkOutOfNetwork" (change)="changeInsuranceBenift(2,$event)">Out of Network</mat-checkbox>
            </div>
            <div class="col-3">
              <br>
              <p>What is Plan Deductible? ($)</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleIndividual" matInput formControlName="txtDeductibleIndividual"   type="text"
            pattern="^\d+(\.\d{1,2})?$"  maxlength="40">
            <mat-error *ngIf="
                    verificationForm.get('txtDeductibleIndividual')?.errors?.pattern
                    ">
               Enter numbers only and two digit after decimal!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleFamily" matInput formControlName="txtDeductibleFamily"
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtDeductibleFamily')?.errors?.pattern
                      ">
                 Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleOutOfNetwork" matInput formControlName="txtDeductibleOutOfNetwork"
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtDeductibleOutOfNetwork')?.errors?.pattern
                      ">
              Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>

            <div *ngIf="verifiedMethod == 'ChangeHealthCare'" class="col-3">
              <br>
              <p >What is Deductible Remaining?</p>
            </div>
            <mat-form-field *ngIf="verifiedMethod == 'ChangeHealthCare'"class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleRemainingIndividual" matInput formControlName="txtDeductibleRemainingIndividual"
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtDeductibleRemainingIndividual')?.errors?.pattern
                      ">
                  Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="verifiedMethod == 'ChangeHealthCare'" class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleRemainingFamily" matInput formControlName="txtDeductibleRemainingFamily"
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtDeductibleRemainingFamily')?.errors?.pattern
                      ">
               Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="verifiedMethod == 'ChangeHealthCare'"class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleRemainingOutOfNetwork" matInput formControlName="txtDeductibleRemainingOutOfNetwork"
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtDeductibleRemainingOutOfNetwork')?.errors?.pattern
                      ">
                  Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>


            <div class="col-3">
              <br>
              <p>How much of Deductible has been met? ($)</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleMetIndividual" matInput formControlName="txtDeductibleMetIndividual"
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtDeductibleMetIndividual')?.errors?.pattern
                      ">
                  Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleMetFamily" matInput formControlName="txtDeductibleMetFamily"
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtDeductibleMetFamily')?.errors?.pattern
                      ">
              Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtDeductibleMetOutOfNetwork" matInput formControlName="txtDeductibleMetOutOfNetwork"
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtDeductibleMetOutOfNetwork')?.errors?.pattern
                      ">
               Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>Payable at what percent of the allowed amount? (%)</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <mat-select maxlength="40" [(value)]="payablePercentType" formControlName="drpPayableIndividual">
                <mat-option>
                  <ngx-mat-select-search [formControl]="PayableControl" placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtPayable"></ngx-mat-select-search>
              </mat-option>
                <mat-option *ngFor="let payable of drpPayable" [value]="payable.payablePercentType">
                  {{payable.payablePercentType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="payablePercentType=undefined;$event.stopPropagation()"
                (click)="clearPayablePercentType()" *ngIf="payablePercentType" matSuffix mat-icon-button
                >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <mat-select maxlength="40" [(value)]="payablePercentType1" formControlName="drpPayableFamily">
                <mat-option>
                  <ngx-mat-select-search [formControl]="payablePercentV2Control" placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtPayableper"></ngx-mat-select-search>
              </mat-option>
                <mat-option *ngFor="let payable of drpPayable" [value]="payable.payablePercentType">
                  {{payable.payablePercentType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="payablePercentType1=undefined;$event.stopPropagation()"
                (click)="clearPayablePercentType1()" *ngIf="payablePercentType1" matSuffix mat-icon-button
                >
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <mat-select maxlength="40" [(value)]="payablePercentType2" formControlName="drpPayableOutOfNetwork">
                <mat-option>
                  <ngx-mat-select-search [formControl]="payablePercentV3Control" placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtPayablepercent"></ngx-mat-select-search>
              </mat-option>
                <mat-option *ngFor="let payable of drpPayable" [value]="payable.payablePercentType">
                  {{payable.payablePercentType}}
                </mat-option>
              </mat-select>
              <button mat-button (click)="payablePercentType2=undefined;$event.stopPropagation()"
                (click)="clearPayablePercentType2()" *ngIf="payablePercentType2" matSuffix mat-icon-button
                >
                <mat-icon>close</mat-icon>
              </button>

            </mat-form-field>
            <div class="col-3">
              <br>
              <p>Out-of-pocket expense? ($)</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseIndividual" formControlName="txtExpenseIndividual"  matInput
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtExpenseIndividual')?.errors?.pattern
                      ">
                 Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseFamily" formControlName="txtExpenseFamily" matInput
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtExpenseFamily')?.errors?.pattern
                      ">
               Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseOutOfNetwork"  formControlName="txtExpenseOutOfNetwork" matInput
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtExpenseOutOfNetwork')?.errors?.pattern
                      ">
              Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>

            <div *ngIf="verifiedMethod == 'ChangeHealthCare'" class="col-3">
              <br>
              <p >What is Out-of-pocket Remaining?</p>
            </div>
            <mat-form-field *ngIf="verifiedMethod == 'ChangeHealthCare'" class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtRemainingIndividual" formControlName="txtRemainingIndividual" matInput
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtRemainingIndividual')?.errors?.pattern
                      ">
                Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="verifiedMethod == 'ChangeHealthCare'"class="col-3">
              <mat-label></mat-label>
              <input  autocomplete="new-txtRemainingFamily" formControlName="txtRemainingFamily"  matInput
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtRemainingFamily')?.errors?.pattern
                      ">
                Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="verifiedMethod == 'ChangeHealthCare'" class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtRemainingOutOfNetwork"  formControlName="txtRemainingOutOfNetwork" matInput
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtRemainingOutOfNetwork')?.errors?.pattern
                      ">
              Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>How much Out-of-pocket expense has been met? ($)</p>
            </div>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseMetIndividual" formControlName="txtExpenseMetIndividual" matInput
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtExpenseMetIndividual')?.errors?.pattern
                      ">
           Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseMetFamily" formControlName="txtExpenseMetFamily" matInput
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtExpenseMetFamily')?.errors?.pattern
                      ">
           Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label></mat-label>
              <input autocomplete="new-txtExpenseMetOutOfNetwork" formControlName="txtExpenseMetOutOfNetwork" matInput
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtExpenseMetOutOfNetwork')?.errors?.pattern
                      ">
            Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <div class="col-3">
              <br>
              <p>Does Out-of-pocket expense include the deductible?</p>
            </div>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkExpenseIndividual">Individual</mat-checkbox>
            </div>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkExpenseFamily">Family</mat-checkbox>
            </div>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkExpenseOutOfNetwork">Out Of Network</mat-checkbox>
            </div>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkPrecertRequired">Is Precert Required?</mat-checkbox>
            </div>
            <!-- prefix="+1-" -->
            <mat-form-field class="col-3">
              <mat-label>If yes, Phone # to call or Portal Name</mat-label>
              <input autocomplete="new-txtPhoneCall" formControlName="txtPhoneCall" maxlength="30" matInput type="text"
                >
              <mat-error *ngIf="
                    verificationForm.get('txtPhoneCall')?.errors
                    ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Lifetime Max Met ($)</mat-label>
              <input autocomplete="new-txtLifetimeMaxMet" formControlName="txtLifetimeMaxMet" matInput
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtLifetimeMaxMet')?.errors?.pattern
                      ">
                 Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Lifetime Max ($)</mat-label>
              <input autocomplete="new-txtLifetimeMax" formControlName="txtLifetimeMax"  matInput
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtLifetimeMax')?.errors?.pattern
                      ">
                 Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>

            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkReferralRequired">Is a referral required?</mat-checkbox>
            </div>

            <mat-form-field class="col-3">
              <mat-label>PCP</mat-label>
              <input autocomplete="new-txtPcp" formControlName="txtPcp" maxlength="50" matInput type="text">
              <mat-error *ngIf="
                verificationForm.get('txtPcp')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DME Limit Met ($)</mat-label>
              <input autocomplete="new-txtDmeLimitMet" formControlName="txtDmeLimitMet" matInput
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtDmeLimitMet')?.errors?.pattern
                      ">
                  Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>DME Annual Limit ($)</mat-label>
              <input autocomplete="new-txtDmeAnnualLimit" formControlName="txtDmeAnnualLimit"
              matInput
              pattern="^\d+(\.\d{1,2})?$"  maxlength="40" type="text">
              <mat-error *ngIf="
                      verificationForm.get('txtDmeAnnualLimit')?.errors?.pattern
                      ">
                  Enter numbers only and two digit after decimal!
                </mat-error>
            </mat-form-field>

            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkPredetermination">Predetermination</mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>Pre.Notes</mat-label>
              <input autocomplete="new-txtPreNotes" formControlName="txtPreNotes" maxlength="1000" matInput type="text">

            </mat-form-field>



            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkDmePartsCovered"> DME Parts Covered</mat-checkbox>
            </div>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkDmeReplacementUnit"> DME Replacement Unit</mat-checkbox>
            </div>

            <mat-form-field class="col-3">
              <mat-label>Requested By</mat-label>
              <input autocomplete="new-txtRequestedBy" formControlName="txtRequestedBy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                verificationForm.get('txtRequestedBy')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Primary Payor</mat-label>
              <input autocomplete="new-txtPrimaryPayor" formControlName="txtPrimaryPayor" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                verificationForm.get('txtPrimaryPayor')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Call tracking number / Reference #</mat-label>
              <input autocomplete="new-txtCallTrackingNumber" formControlName="txtCallTrackingNumber" maxlength="40" matInput type="text">
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Contact (phone/Web)</mat-label>
              <input autocomplete="new-" formControlName="txtContact" maxlength="15" matInput type="text">
              <mat-error *ngIf="
                    verificationForm.get('txtContact')?.errors?.number
                    ">
                Enter only Numbers!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Policy Holder Name</mat-label>
              <input autocomplete="new-txtPolicyHolderName" formControlName="txtPolicyHolderName" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                verificationForm.get('txtPolicyHolderName')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Policy Holder DOB</mat-label>
              <input autocomplete="new-txtPolicyHolderDob" formControlName="txtPolicyHolderDob" matInput placeholder="MM/DD/YYYY"
                [matDatepicker]="txtPolicyHolderDob"  maxlength="10"
                onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                >
              <mat-datepicker-toggle matSuffix [for]="txtPolicyHolderDob">
              </mat-datepicker-toggle>
              <mat-datepicker #txtPolicyHolderDob></mat-datepicker>
              <mat-error *ngIf="
                verificationForm.get('txtPolicyHolderDob')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Claim Address</mat-label>
              <textarea autocomplete="new-txtClaimAddress" rows="3" maxlength="60" formControlName="txtClaimAddress" matInput></textarea>
            </mat-form-field>
            <div class="col-3">
              <br>
              <mat-checkbox formControlName="chkNetwork">Network (YES / NO)</mat-checkbox>
            </div>
            <mat-form-field class="col-3">
              <mat-label>Plan COB</mat-label>
              <input autocomplete="new-txtPlanCob" formControlName="txtPlanCob" matInput  placeholder="MM/DD/YYYY"
                [matDatepicker]="txtPlanCob"  maxlength="10"
                onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                >
              <mat-datepicker-toggle matSuffix [for]="txtPlanCob">
              </mat-datepicker-toggle>
              <mat-datepicker #txtPlanCob></mat-datepicker>
              <mat-error *ngIf="
                    verificationForm.get('txtPlanCob')?.errors">
                Enter Valid Date!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Coverage criteria</mat-label>
              <input autocomplete="new-txtCoverageCriteria" formControlName="txtCoverageCriteria" matInput maxlength="40" type="text">
              <mat-error *ngIf="
                verificationForm.get('txtCoverageCriteria')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Unit limits</mat-label>
              <input autocomplete="new-txtQtyLimits" formControlName="txtQtyLimits" matInput maxlength="40" type="text">
              <mat-error *ngIf="
                verificationForm.get('txtQtyLimits')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Frequency</mat-label>
              <input autocomplete="new-txtFrequency" formControlName="txtFrequency" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                verificationForm.get('txtFrequency')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Rent (How many months)or Purchase?</mat-label>
              <input autocomplete="new-txtRent" formControlName="txtRent" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                verificationForm.get('txtRent')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-6">
              <mat-label>PA required: Purchase or rental upfront or after what months?</mat-label>
              <input autocomplete="new-txtPaRequired" formControlName="txtPaRequired" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                verificationForm.get('txtPaRequired')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Codes that require PA</mat-label>
              <input autocomplete="new-txtCodesRequirePa" formControlName="txtCodesRequirePa" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                verificationForm.get('txtCodesRequirePa')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Same or Similar Info</mat-label>
              <input autocomplete="new-txtSameOrSimilarInfo" formControlName="txtSameOrSimilarInfo" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                verificationForm.get('txtSameOrSimilarInfo')?.errors?.sAlphabets
                ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Spoke with</mat-label>
              <input autocomplete="new-txtSpokeWith" formControlName="txtSpokeWith" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                    verificationForm.get('txtSpokeWith')?.errors?.sAlphabets
                    ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="col-3">
              <mat-label>Prepared By</mat-label>
              <input autocomplete="new-drpPreparedBy" formControlName="drpPreparedBy" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                    verificationForm.get('drpPreparedBy')?.touched &&
                    verificationForm.get('drpPreparedBy')?.errors?.sAlphabets
                    ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field> -->

            <mat-form-field class="col-3">
              <mat-label [ngClass]="{ 'is-invalid': verificationForm?.get('drpVerifiedBy')?.invalid }">Verified By<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="drpVerifiedBy">
              <mat-option>
                  <ngx-mat-select-search [formControl]="verfiedByControl" placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtVerified"></ngx-mat-select-search>
              </mat-option>
                <mat-option
                  *ngFor="let users of lstFilterUsers"
                  [value]="users?.userId"
                >
                  {{ users?.userDesignation }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
              verificationForm.get('drpVerifiedBy')?.errors?.required
              ">
              Verified By is a required field!
            </mat-error>
            </mat-form-field>


            <mat-form-field class="col-3">
              <mat-label [ngClass]="{ 'is-invalid': verificationForm?.get('txtDateVerified')?.invalid }">Date Verified <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtDateVerified" formControlName="txtDateVerified" matInput maxlength="10" placeholder="MM/DD/YYYY"
                [matDatepicker]="txtDateVerified"
                onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')">
              <mat-datepicker-toggle matSuffix [for]="txtDateVerified">
              </mat-datepicker-toggle>
              <mat-datepicker #txtDateVerified></mat-datepicker>
              <mat-error *ngIf="
                           verificationForm.get('txtDateVerified')?.errors">
                Enter Valid Date!
              </mat-error>
              <mat-error *ngIf="
                    verificationForm.get('txtDateVerified')?.errors?.required
                    ">
                    <!-- Date Verified is a required field! -->
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Prepared By</mat-label>
              <mat-select formControlName="drpPreparedBy">
              <mat-option>
                  <ngx-mat-select-search [formControl]="PreparedByControl" placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtPreparedBy"></ngx-mat-select-search>
              </mat-option>
                <mat-option
                  *ngFor="let users of lstFilterUsers"
                  [value]="users?.userId"
                >
                  {{ users?.userDesignation }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <mat-form-field class="col-3">
              <mat-label>Verified By<span class="asterisk">*</span> </mat-label>
              <input autocomplete="new-drpVerifiedBy" formControlName="drpVerifiedBy"  maxlength="50" matInput type="text">
             <mat-error *ngIf="
                    verificationForm.get('drpVerifiedBy')?.touched &&
                    verificationForm.get('drpVerifiedBy')?.errors?.sAlphabets
                    ">
                Enter only Alphabets!
              </mat-error>
              <mat-error *ngIf="
                    verificationForm.get('drpVerifiedBy')?.touched &&
                    verificationForm.get('drpVerifiedBy')?.errors?.required
                    ">
                    Verified By is a required field!
            </mat-error>
            </mat-form-field> -->
            <mat-form-field class="col-3">
              <mat-label>Prepared On</mat-label>
              <input autocomplete="new-txtPreparedOn" formControlName="txtPreparedOn" matInput placeholder="MM/DD/YYYY" [matDatepicker]="txtPreparedOn" maxlength="10"
              onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')">
              <mat-datepicker-toggle matSuffix [for]="txtPreparedOn" >
              </mat-datepicker-toggle>
              <mat-datepicker #txtPreparedOn></mat-datepicker>
            </mat-form-field>
            <!-- <mat-form-field class="col-6">
              <mat-label>Comments To Tech</mat-label>
              <input autocomplete="new-txtcommentsToTech" formControlName="txtcommentsToTech" maxlength="1000" matInput type="text">

            </mat-form-field> -->
            <mat-form-field class="col-3">
              <mat-label [ngClass]="{ 'is-invalid': verificationForm?.get('txtVerfnAMethod')?.invalid }">Verification Method <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-" formControlName="txtVerfnAMethod" maxlength="40" matInput type="text" readonly>
              <mat-error *ngIf="
                    verificationForm.get('txtVerfnAMethod')?.errors?.sAlphabets
                    ">
                Enter only Alphabets!
              </mat-error>
              <mat-error *ngIf="
              verificationForm.get('txtVerfnAMethod')?.errors?.required
              ">
              Plan is a required field!
            </mat-error>
            </mat-form-field>

            <mat-form-field class="col-12">
              <mat-label>Comments To Tech</mat-label>
              <textarea matInput rows="3" autocomplete="off" maxlength="5000" formControlName="txtcommentsToTech" [(ngModel)]="commonTechNotes" (input)="updateCharacterCountCommonTech(5000)"
                        type="text" ></textarea>
              <div class="char-count">
                {{ commonTechNotes?.length }} / 5000
              </div>

              <!-- <input autocomplete="new-txtcommentsToTech" formControlName="txtcommentsToTech" maxlength="1000" matInput type="text"> -->

            </mat-form-field>

            <mat-form-field *ngIf="verifiedMethod == 'ChangeHealthCare'" class="col-3" >
              <mat-label >Co Payment </mat-label>
              <input autocomplete="new-txtCoPayment"  formControlName="txtCoPayment" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                    verificationForm.get('txtCoPayment')?.errors?.sAlphabets
                    ">
                Enter only Alphabets!
              </mat-error>

            </mat-form-field>

            <mat-form-field *ngIf="verifiedMethod == 'ChangeHealthCare'" class="col-3">
              <mat-label>Co Insurance </mat-label>
              <input autocomplete="new-txtCoInsurance" formControlName="txtCoInsurance" maxlength="40" matInput type="text">
              <mat-error *ngIf="
                    verificationForm.get('txtCoInsurance')?.errors?.sAlphabets
                    ">
                Enter only Alphabets!
              </mat-error>
            </mat-form-field>
            <div class="col-9">
              <mat-radio-group formControlName="rbtnVerificationStatus">
                <mat-label class="mb-2 mr-2 custom-radio" [ngClass]="{ 'is-invalid': verificationForm?.get('rbtnVerificationStatus')?.invalid }">Verification Status<span class="asterisk">(*) </span></mat-label>
                <mat-radio-button class="mb-2 mr-2 custom-radio" value="Done">Done</mat-radio-button>
                <mat-radio-button class="mb-2 mr-2 custom-radio" value="Pending">Pending</mat-radio-button>
                <mat-radio-button class="mb-2 mr-2 custom-radio" value="Unabletoverify">Unable to Verify</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>
  </form>
  </mat-dialog-content>
  <!-- <mat-dialog-actions> -->
    <div class="row mt-2">
      <div class="col-4 pt-1">
        <button mat-button class="buttonColor dd-text p-2" id="viewDocBtn" (click)="viewFaxDocument()" [disabled]="isEmpty(data?.documentId)">
          <img src="assets/images/NewSetup/GroupV1.png" alt=""class="sea-green-icon-ins mr-1">  View Document
        </button>
      </div>
      <div class="col-8 d-flex justify-content-end pt-1">
        <button mat-button class="buttonColor p-2 dd-text mr-2" id="printBtn" (click)="printManulVerfication()"><i class="fa fa-print clsprintIcon"></i> Print</button>

        <button id="saveBtn" class="buttonColor dd-text p-2 mr-2" *ngIf='isPatientLockedByCurrentUser && !data.savehidden && isSaveBtn != true && data.isNewInsurance!==true' [disabled]="verificationForm.invalid  || isShowSpinner||
        verificationForm?.get('chkActive').value===false"
          mat-button (click)="saveInsuranceVerify()" [hidden]="data.inboundShortCode===inboundStatusIds.missingDocuments">Save
          <mat-icon *ngIf='isShowSpinner'>
            <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
          </mat-icon>
        </button>
        <button id="closeBtn" class="buttonCancelColor dd-text p-2 mr-2" mat-button mat-dialog-close>Close</button>
      </div>
    </div>
<!-- </mat-dialog-actions> -->
</div>

<app-loader-component *ngIf="isVerificationLoading"></app-loader-component>
