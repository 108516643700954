<br />
<div class="card card-body">
  <mat-accordion>
    <mat-expansion-panel [expanded]="isPanelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title class="customThemeClass">
          <b>Add User</b>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="executiveUsersForm">
        <div class="row">
          <mat-form-field class="col-3">
            <mat-label> User Name <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtUserName" matInput maxlength="40" formControlName="txtUserName" type="text" pattern="[a-zA-Z]*" />
            <mat-error *ngIf="
                executiveUsersForm?.get('txtUserName')?.touched &&
                executiveUsersForm?.get('txtUserName')?.errors?.required">
              UserName is a required field!
            </mat-error>
            <mat-error *ngIf="
                executiveUsersForm?.get('txtUserName')?.touched &&
                executiveUsersForm?.get('txtUserName')?.errors?.pattern">
              UserName must be alphabets and space not allowed!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <!-- pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,4}$" -->
            <mat-label> Email Id <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtEmail" matInput maxlength="40" formControlName="txtEmail" type="text"
            pattern="[a-zA-Z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              [textMask]="{ mask: emailMask }" />
            <mat-error *ngIf="
                executiveUsersForm?.get('txtEmail')?.touched &&
                executiveUsersForm?.get('txtEmail')?.errors?.pattern">
              Please Enter a Valid Email Address
            </mat-error>
            <mat-error *ngIf="
                executiveUsersForm?.get('txtEmail')?.touched &&
                executiveUsersForm?.get('txtEmail')?.errors?.required">
              Email Address is a required field!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Role Names <span class="asterisk">*</span></mat-label>
            <mat-select formControlName="txtRole">
              <mat-option *ngFor="let roles of drpRoles" [value]="roles.name">
                {{ roles.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                executiveUsersForm?.get('txtRole')?.touched &&
                executiveUsersForm?.get('txtRole')?.errors?.required">
              Role Name is a required field!
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Password <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-password" [type]="passwordHide ? 'password' : 'text'" matInput formControlName="txtPassword" type="text"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}" />
            <a class="font-size-20" mat-icon-button matSuffix (click)="passwordHide = !passwordHide">
              <mat-icon>{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </a>
            <mat-error *ngIf="
                executiveUsersForm?.get('txtPassword')?.touched &&
                executiveUsersForm?.get('txtPassword')?.errors?.required">
              Password is a required field!
            </mat-error>
            <mat-error *ngIf="
                executiveUsersForm?.get('txtPassword')?.touched &&
                executiveUsersForm?.get('txtPassword')?.errors?.pattern">
              Passwords must be at least one non alphanumeric character,one uppercase,one digit and
              at least 8 or more characters
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Confirm Password <span class="asterisk font-size-18">*</span></mat-label>
            <input autocomplete="new-password" [type]="confirmPswdhide ? 'password' : 'text'" matInput formControlName="txtConfirmPassword"
              type="text" pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}" />
            <mat-error *ngIf="
                executiveUsersForm?.get('txtConfirmPassword')?.touched &&
                executiveUsersForm?.get('txtConfirmPassword')?.errors?.required">
              Password is a required field!
            </mat-error>
            <a class="font-size-20" mat-icon-button matSuffix (click)="confirmPswdhide = !confirmPswdhide">
              <mat-icon>{{confirmPswdhide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </a>
            <mat-error *ngIf="
              executiveUsersForm?.get('txtPassword')?.touched &&
              executiveUsersForm?.get('txtPassword')?.errors?.required">
              Password is a required field!
            </mat-error>
            <mat-error *ngIf="
              executiveUsersForm?.get('txtPassword')?.touched &&
              executiveUsersForm?.get('txtPassword')?.errors?.pattern">
              Passwords must be at least one non alphanumeric character,one uppercase,one digit and
              at least 8 or more characters
            </mat-error>
          </mat-form-field>
          <div class="col-6">
            <div class="text-lg-left">
              <button mat-button [disabled]="executiveUsersForm.invalid" class="buttonColor mt-1 mb-1 mr-2"
                (click)="saveExecutiveUsers()">
                Save
              </button>
              <button mat-button class="resetclr mb-1 mr-1" (click)="resetExecustiveUsers()">Reset</button>
            </div>
          </div>
        </div>
      </form>
      <!-- <div class="row">
        <div class="col-sm-12">
          <div class="text-lg-center">
            <button mat-button class="buttonColor" (click)="saveExecutiveUsers()">Save</button>
          </div>
        </div>
      </div> -->
    </mat-expansion-panel>
  </mat-accordion>
  <div class="row">
    <div class="col-12 table-responsive">
      <app-table [strPageType]="strPageType" (viewButton)="ViewExecutiveUser($event)"
        (deleteButton)="deleteExecutiveUser($event)">
      </app-table>
    </div>
  </div>
</div>
