import { SaleOrderViewComponent } from './../../../order/src/app/sale-order-view/sale-order-view.component';
import { PreviewDocumentComponent } from './../../../order/src/app/preview-document/preview-document.component';
import { ReferralPracticeComponent } from './referral-practice/referral-practice.component';
import { ReferralPracticeListComponent } from './referral-practice-list/referral-practice-list.component';
import { PatientImportComponent } from './patient-import/patient-import.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminCreateEditComponent } from 'projects/admin/src/app/admin-create-edit/admin-create-edit.component';
import { ApprovalBotListComponent } from 'projects/admin/src/app/approval-bot-list/approval-bot-list.component';
import { ApprovalListComponent } from 'projects/admin/src/app/approval-list/approval-list.component';
import { ClientComponent } from 'projects/admin/src/app/client/client.component';
import { DashboardComponent } from 'projects/admin/src/app/dashboard/dashboard.component';
import { DocumentPerviewerComponent } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { ForgotPasswordComponent } from 'projects/admin/src/app/forgot-password/forgot-password.component';
import { IdentityserverLoginComponent } from 'projects/admin/src/app/identityserver-login/identityserver-login.component';
import { ItemSuppliesComponent } from 'projects/admin/src/app/item-supplies/item-supplies.component';
import { PageNotFoundComponent } from 'projects/admin/src/app/page-not-found/page-not-found.component';
import { PatientListComponent } from 'projects/admin/src/app/patient-list/patient-list.component';
import { SubBatchBotListComponent } from 'projects/admin/src/app/sub-batch-bot-list/sub-batch-bot-list.component';
import { SubBatchListTempComponent } from 'projects/admin/src/app/sub-batch-list-temp/sub-batch-list-temp.component';
import { SubBatchListComponent } from 'projects/admin/src/app/sub-batch-list/sub-batch-list.component';
import { UpdatePasswordComponent } from 'projects/admin/src/app/update-password/update-password.component';
import { UploadBinBotComponent } from 'projects/admin/src/app/upload-bin-bot/upload-bin-bot.component';
import { UploadBinBotcComponent } from 'projects/admin/src/app/upload-bin-botc/upload-bin-botc.component';
import { UploadBinComponent } from 'projects/admin/src/app/upload-bin/upload-bin.component';
import { UserRegisterComponent } from 'projects/admin/src/app/user-register/user-register.component';
import { CheckEftSelectionComponent } from 'projects/billing/src/app/check-eft-selection/check-eft-selection.component';
import { ClaimProcessComponent } from 'projects/billing/src/app/claim-process/claim-process.component';
import { ClaimStatusComponent } from 'projects/billing/src/app/claim-status/claim-status.component';
import { InventoryItemComponent } from 'projects/inventory/src/app/inventory-item/inventory-item.component';
import { InventoryNotesComponent } from 'projects/inventory/src/app/inventory-notes/inventory-notes.component';
import { InventoryPayorComponent } from 'projects/inventory/src/app/inventory-payor/inventory-payor.component';
import { InventoryProductComponent } from 'projects/inventory/src/app/inventory-product/inventory-product.component';
import { InventoryProviderDetailsComponent } from 'projects/inventory/src/app/inventory-provider-details/inventory-provider-details.component';
import { InventoryProviderComponent } from 'projects/inventory/src/app/inventory-provider/inventory-provider.component';
import { ProductBrandComponent } from 'projects/inventory/src/app/product-brand/product-brand.component';
import { ProductCategoryComponent } from 'projects/inventory/src/app/product-category/product-category.component';
import { ProductStockComponent } from 'projects/inventory/src/app/product-stock/product-stock.component';
import { PurchaseOrderComponent } from 'projects/inventory/src/app/purchase-order/purchase-order.component';
import { StockViewComponent } from 'projects/inventory/src/app/stock-view/stock-view.component';
import { VendorComponent } from 'projects/inventory/src/app/vendor/vendor.component';
import { HorizontalComponent } from 'projects/layout/src/app/horizontal/horizontal.component';
import { AddCoverageAreaComponent } from 'projects/on-time-schedules/src/app/add-coverage-area/add-coverage-area.component';
import { OnTimeCreateEditComponent } from 'projects/on-time-schedules/src/app/on-time-create-edit/on-time-create-edit.component';
import { OntimeLeaveApplyComponent } from 'projects/on-time-schedules/src/app/ontime-leave-apply/ontime-leave-apply.component';
import { CalendarListComponent } from 'projects/schedule/src/app/calendar-list/calendar-list.component';
import { CalenderScheduleComponent } from 'projects/schedule/src/app/calender-schedule/calender-schedule.component';
import { OnTimeScheduleCreateEditComponent } from 'projects/schedule/src/app/on-time-schedule-create-edit/on-time-schedule-create-edit.component';
import { ApprovedListsComponent } from 'projects/shipping/src/app/approved-lists/approved-lists.component';
import { CashPaymentViewComponent } from 'projects/shipping/src/app/cash-payment-view/cash-payment-view.component';
import { ProductTrackViewComponent } from 'projects/shipping/src/app/product-track-view/product-track-view.component';
import { ReadyToDeliveryComponent } from 'projects/shipping/src/app/ready-to-delivery/ready-to-delivery.component';
import { ReadyToPackListComponent } from 'projects/shipping/src/app/ready-to-pack-list/ready-to-pack-list.component';
import { ReturnApprovedListComponent } from 'projects/shipping/src/app/return-approved-list/return-approved-list.component';
import { ShippingApprovalListComponent } from 'projects/shipping/src/app/shipping-approval-list/shipping-approval-list.component';
import { ShippingCreateEditComponent } from 'projects/shipping/src/app/shipping-create-edit/shipping-create-edit.component';
import { ShippingReturnListComponent } from 'projects/shipping/src/app/shipping-return-list/shipping-return-list.component';
import { ShippingTicketCreateEditComponent } from 'projects/shipping/src/app/shipping-ticket-create-edit/shipping-ticket-create-edit.component';
import { ShippingTicketListComponent } from 'projects/shipping/src/app/shipping-ticket-list/shipping-ticket-list.component';
import { ShippingTicketComponent } from 'projects/shipping/src/app/shipping-ticket/shipping-ticket.component';
import { ShippingViewComponent } from 'projects/shipping/src/app/shipping-view/shipping-view.component';
import { TicketPdfViewComponent } from 'projects/shipping/src/app/ticket-pdf-view/ticket-pdf-view.component';
import { WarehouseListComponent } from 'projects/shipping/src/app/warehouse-list/warehouse-list.component';
import { BillingCreateEditComponent } from './../../../billing/src/app/billing-create-edit/billing-create-edit.component';
import { BillingListComponent } from './../../../billing/src/app/billing-list/billing-list.component';
import { ClaimHistoryComponent } from './../../../billing/src/app/claim-history/claim-history.component';
import { PostingListComponent } from './../../../billing/src/app/posting-list/posting-list.component';
import { OrderCreateEditComponent } from './../../../order/src/app/order-create-edit/order-create-edit.component';
import { RenewalOrdersMenuComponent } from './../../../order/src/app/renewal-orders-menu/renewal-orders-menu.component';
import { PatientAuthMenuComponent } from './patient-auth-menu/patient-auth-menu.component';
import { PatientCreateEditComponent } from './patient-create-edit/patient-create-edit.component';
import { PatientPersonalComponent } from './patient-personal/patient-personal.component';
import { PatientSearchComponent } from './patient-search/patient-search.component';
import { PatientVerifyMenuComponent } from './patient-verify-menu/patient-verify-menu.component';
import {
  DocumentPreviewComponent,
  UserTaskListComponent,
} from './user-task-list/user-task-list.component';
import { PostingHistoryComponent } from 'projects/billing/src/app/posting-history/posting-history.component';
import { AllowablesListComponent } from 'projects/inventory/src/app/allowables-list/allowables-list.component';
import { ShippingExecReportComponent } from 'projects/shipping/src/app/shipping-exec-report/shipping-exec-report.component';
import { ShippingWMangerReportsComponent } from 'projects/shipping/src/app/shipping-wmanger-reports/shipping-wmanger-reports.component';
import { BillingCashPaymentViewComponent } from 'projects/billing/src/app/billing-cash-payment-view/billing-cash-payment-view.component';
import { MisReportsComponent } from 'projects/admin/src/app/mis-reports/mis-reports.component';
import { AddPurchaseOrderComponent } from 'projects/inventory/src/app/add-purchase-order/add-purchase-order.component';
import { ArAgingReportComponent } from 'projects/admin/src/app/ar-aging-report/ar-aging-report.component';
import { ImportMisReportComponent } from './import-mis-report/import-mis-report.component';
import { ClaimDenialReportsComponent } from 'projects/billing/src/app/claim-denial-reports/claim-denial-reports.component';
import { AdminCalenderComponent } from 'projects/schedule/src/app/admin-calender/admin-calender.component';
import { ArchiveBotDocComponent } from 'projects/admin/src/app/archive-bot-doc/archive-bot-doc.component';
import { AuthFollowUpComponent } from 'projects/order/src/app/auth-follow-up/auth-follow-up.component';
import { MasterCityComponent } from 'projects/inventory/src/app/master-city/master-city.component';
import { MasterStateComponent } from 'projects/inventory/src/app/master-state/master-state.component';
import { MasterModifiersComponent } from 'projects/inventory/src/app/master-modifiers/master-modifiers.component';
import { InsuranceReportComponent } from 'projects/order/src/app/insurance-report/insurance-report.component';
import { AddPaymentsComponent } from 'projects/billing/src/app/add-payments/add-payments.component';
import { ManualPostingHistoryComponent } from 'projects/billing/src/app/manual-posting-history/manual-posting-history.component';
import { PatientsTabComponent } from './patients-tab/patients-tab.component';
import { PatientClinicalTabComponent } from './patient-clinical-tab/patient-clinical-tab.component';
import { PatientCreateEditTabComponent } from './patient-create-edit-tab/patient-create-edit-tab.component';
import { PatientSearchTabComponent } from './patient-search-tab/patient-search-tab.component';
import { ChequeListComponent } from 'projects/billing/src/app/cheque-list/cheque-list.component';
import { DocumentFollowupComponent } from './document-followup/document-followup.component';
import { OrderFollowupComponent } from './order-followup/order-followup.component';
import { DocumentTabComponent } from './document-tab/document-tab.component';
import { OrderCreateEditTabComponent } from 'projects/order/src/app/order-create-edit-tab/order-create-edit-tab.component';
import { ShippingListTabComponent } from 'projects/shipping/src/app/shipping-list-tab/shipping-list-tab.component';
import { OrderSearchTabComponent } from 'projects/order/src/app/order-search-tab/order-search-tab.component';
import { ShippingApprovalTabComponent } from 'projects/shipping/src/app/shipping-approval-tab/shipping-approval-tab.component';
import { ClaimProcessTabComponent } from 'projects/billing/src/app/claim-process-tab/claim-process-tab.component';
import { ClaimStatusTabComponent } from 'projects/billing/src/app/claim-status-tab/claim-status-tab.component';
import { ClaimCheckListComponent } from 'projects/billing/src/app/claim-check-list/claim-check-list.component';
import { ColorCodesMasterComponent } from './color-codes-master/color-codes-master.component';
import { CommonNotesSearchComponent } from './common-notes-search/common-notes-search.component';
import { InboundTabComponent } from 'projects/admin/src/app/inbound-tab/inbound-tab.component';
import { TaskListPageTabComponent } from './task-list-page-tab/task-list-page-tab.component';
import { BillingReportsTabComponent } from 'projects/admin/src/app/billing-reports-tab/billing-reports-tab.component';
import { ArAgingReportsTabComponent } from 'projects/admin/src/app/ar-aging-reports-tab/ar-aging-reports-tab.component';
import { InvoiceListComponent } from 'projects/shipping/src/app/invoice-list/invoice-list.component';
import { ArchivedInboundListComponent } from 'projects/admin/src/app/archived-inbound-list/archived-inbound-list.component';
import { UserTaskReportTabComponent } from './user-task-report-tab/user-task-report-tab.component';
import { PatientAuthorizationTabComponent } from './patient-authorization-tab/patient-authorization-tab.component';
import { PatientClaimComponent } from './patient-claim/patient-claim.component';
import { PatientOrdersComponent } from './patient-orders/patient-orders.component';
import { PickupExchangeListComponent } from 'projects/order/src/app/pickup-exchange-list/pickup-exchange-list.component';
import { AddPickupExchangeItemTabComponent } from 'projects/order/src/app/add-pickup-exchange-item-tab/add-pickup-exchange-item-tab.component';
import { CreateEditProductTabComponent } from 'projects/inventory/src/app/create-edit-product-tab.component/create-edit-product-tab.component';
import { ProductTabComponent } from 'projects/inventory/src/app/product-tab.component/product-tab.component';
import { ResupplyProductTabComponent } from 'projects/inventory/src/app/resupply-product-tab/resupply-product-tab.component';
import { ProductBrandTabComponent } from 'projects/inventory/src/app/product-brand-tab.component/product-brand-tab.component';
import { ProductCategoryTabComponent } from 'projects/inventory/src/app/product-category-tab.component/product-category-tab.component';
import { ProductVendorTabComponent } from 'projects/inventory/src/app/product-vendor-tab.component/product-vendor-tab.component';
import { AllowablesTabComponent } from 'projects/inventory/src/app/allowables-tab/allowables-tab.component';
import { StockListTabComponent } from 'projects/inventory/src/app/stock-list-tab/stock-list-tab.component';
import { ProcessedDocumentsTabComponent } from 'projects/admin/src/app/processed-documents-tab/processed-documents-tab.component';
import { DocumentSortingComponent } from 'projects/admin/src/app/document-sorting/document-sorting.component';
import { AssignDocumentTabComponent } from 'projects/admin/src/app/assign-document-tab/assign-document-tab.component';
import { SortingDocumentsTabComponent } from 'projects/admin/src/app/sorting-documents-tab/sorting-documents-tab.component';
import { AddDocNpiComponent } from './add-doc-npi/add-doc-npi.component';
import { NpiDetailsComponent } from './npi-details/npi-details.component';
import { MasterDocumentBinComponent } from 'projects/inventory/src/app/master-document-bin/master-document-bin.component';
import { PatientAuthorizationDetailsComponent } from './patient-authorization-details/patient-authorization-details.component';
import { PatientAuthorizationListComponent } from './patient-authorization-list/patient-authorization-list.component';
import { AdminDashboardComponent } from 'projects/admin/src/app/admin-dashboard/admin-dashboard.component';
import { ZeroStockListComponent } from 'projects/inventory/src/app/zero-stock-list/zero-stock-list.component';
import { TimerComponent } from './timer/timer.component';
import { StatusComponent } from './status/status.component';
import { CommonNotesSearchV1Component } from './common-notes-search-v1/common-notes-search-v1.component';
import { MasterWipStatusComponent } from 'projects/inventory/src/app/master-wip-status/master-wip-status.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { PrimaryInsComponent } from 'projects/admin/src/app/primary-ins/primary-ins.component';
import { ViewNotesComponent } from 'projects/admin/src/app/view-notes/view-notes.component';
import { CustomerServicePoolComponent } from './customer-service-pool/customer-service-pool.component';
import { SubmenusampleComponent } from 'projects/admin/src/app/submenusample/submenusample.component';
import { PatientListV1Component } from './patient-list-v1/patient-list-v1.component';
import { VerificationComponent } from './verification/verification.component';
import { ComplianceReportComponent } from 'projects/admin/src/app/compliance-report/compliance-report.component';
import { MIVerificationComponent } from './miverification/miverification.component';
import { MedicareCheckListComponent } from './medicare-check-list/medicare-check-list.component';
import { RequisitionApprovalComponent } from 'projects/inventory/src/app/requisition-approval/requisition-approval.component';
import { CommonDocViewerModalComponent } from 'projects/shared/src/app/components/common-doc-viewer-modal/common-doc-viewer-modal.component';
import { PracticeManagementV2Component } from './practice-management-v2/practice-management-v2.component';
import { RequisitionListComponent } from 'projects/inventory/src/app/requisition-list/requisition-list.component';
import { AdjustmentComponent } from 'projects/inventory/src/app/adjustment/adjustment.component';
import { StockListComponent } from 'projects/inventory/src/app/stock-list/stock-list.component';
import { NewsetupModalComponent } from './newsetup-modal/newsetup-modal.component';
import { RequistionViewerComponent } from 'projects/inventory/src/app/requistion-viewer/requistion-viewer.component';
import { SaleOrderComponent } from 'projects/order/src/app/sale-order/sale-order.component';
import { RequisitionFormComponent } from 'projects/inventory/src/app/requisition-form/requisition-form.component';
import { ShippingListComponent } from 'projects/shipping/src/app/shipping-list/shipping-list.component';
import { BillingListTabComponent } from 'projects/billing/src/app/billing-list-tab/billing-list-tab.component';
import { PatientClaimsComponent } from './patient-claims/patient-claims.component';
import { PatientClaimsTabComponent } from 'projects/billing/src/app/patient-claims-tab/patient-claims-tab.component';
import { DoVerificationModalComponent } from 'projects/shared/src/app/components/do-verification-modal/do-verification-modal.component';
import { MyverficationV1Component } from './myverfication-v1/myverfication-v1.component';
import { AppointmentScheduleComponent } from 'projects/schedule/src/app/appointment-schedule/appointment-schedule.component';
import { ViewDocumentCheckListComponent } from 'projects/admin/src/app/view-document-check-list/view-document-check-list.component';
import { EmailPrintLetterComponent } from 'projects/admin/src/app/email-print-letter/email-print-letter.component';
import { PurchaseOrderTabComponent } from 'projects/inventory/src/app/purchase-order-tab/purchase-order-tab.component';
import { AllowablesCalculationComponent } from 'projects/inventory/src/app/allowables-calculation/allowables-calculation.component';
import { InsuranceAllowablesComponent } from 'projects/inventory/src/app/insurance-allowables/insurance-allowables.component';
import { MasterMminsuranceComponent } from 'projects/inventory/src/app/master-mminsurance/master-mminsurance.component';
import { ExchangeListComponent } from 'projects/order/src/app/exchange-list/exchange-list.component';
import { PracticeManagementListComponent } from './practice-management-list/practice-management-list.component';
import { PatientListV2Component } from './patient-list-v2/patient-list-v2.component';
import { AddPracticeManagementComponent } from './add-practice-management/add-practice-management.component';
import { MasterMaskComponent } from 'projects/inventory/src/app/master-mask/master-mask.component';
import { ReportPracticeManagementComponent } from './report-practice-management/report-practice-management.component';
import { MmOrderReturnExchangeReason1Component } from 'projects/inventory/src/app/mm-Order-Return-Exchange-Reason1/mm-Order-Return-Exchange-Reason1.component';
import { OverrideListComponent } from 'projects/order/src/app/override-list/override-list.component';
import { ReturnOrderListComponent } from 'projects/order/src/app/return-order-list/return-order-list.component';
import { RentalListComponent } from 'projects/order/src/app/rental-list/rental-list.component';
import { MasterMedicareChecklistComponent } from './master-medicare-checklist/master-medicare-checklist.component';
import { ShippingDisapprovedListComponent } from 'projects/shipping/src/app/shipping-disapproved-list/shipping-disapproved-list.component';
import { MasterVerification_doVerificationComponent } from './masterVerification_doVerification/masterVerification_doVerification.component';
import { CcSettlementComponent } from 'projects/order/src/app/cc-settlement/cc-settlement.component';
import { PatientNewInsuranceReviewComponent } from './patient-new-insurance-review/patient-new-insurance-review.component';
import { AnnotationComponent } from 'projects/shared/src/app/components/annotation/annotation.component';
import { ClaimStatusListComponent } from 'projects/billing/src/app/claim-status-list/claim-status-list.component';
import { ClaimChequeListComponent } from 'projects/billing/src/app/claim-cheque-list/claim-cheque-list.component';
import { CommonDocTiffViewerComponent } from 'projects/shared/src/app/components/common-doc-tiff-viewer/common-doc-tiff-viewer.component';
import { ViewDocumentCheckListTiffViewerComponent } from 'projects/admin/src/app/view-document-check-list-tiff-viewer/view-document-check-list-tiff-viewer.component';
import { CalendarListV1Component } from 'projects/schedule/src/app/calendar-list-v1/calendar-list-v1.component';
import { MasterVerification_doAuthorizationComponent } from './masterVerification_doAuthorization/masterVerification_doAuthorization.component';
import { LazyloadingComponent } from 'projects/admin/src/app/admin-dashboard/lazyloading/lazyloading.component';
import { SizeComponent } from 'projects/inventory/src/app/size/size.component';
import { InboundSortingPendingComponent } from './patient-list-v2/inbound-sorting-pending/inbound-sorting-pending.component';
import { InboundBatchSortingComponent } from './inbound-batch-sorting/inbound-batch-sorting.component';
import { MasterModifierV1Component } from 'projects/inventory/src/app/master-modifiers/master-modifier-v1/master-modifier-v1.component';
import { InboundBatchSortingTiffComponent } from './inbound-batch-sorting-tiff/inbound-batch-sorting-tiff.component';
import { RxReportComponent } from 'projects/admin/src/app/rx-report/rx-report.component';
import { HelloSignComponent } from 'projects/order/src/app/hello-sign/hello-sign.component';
import { MaterDiscountComponent } from 'projects/inventory/src/app/mater-discount/mater-discount.component';
import { HelloSignReportComponent } from 'projects/order/src/app/hello-sign-report/hello-sign-report.component';
import { ClaimBatchComponent } from 'projects/billing/src/app/claim-batch/claim-batch.component';
import { AutoPostingERAComponent } from 'projects/billing/src/app/auto-posting-era/auto-posting-era.component';
import { TextConversationComponent } from './text-conversation/text-conversation.component';
import { TextConversationMasterComponent } from './text-conversation-master/text-conversation-master.component';
import { TemplateCreationComponent } from './template-creation/template-creation.component';
import { CalenderScheduleV2Component } from 'projects/schedule/src/app/calender-schedule-v2/calender-schedule-v2.component';
import { RxEmailPrintLetterComponent } from 'projects/admin/src/app/rx-email-print-letter/rx-email-print-letter.component';
import { RxPreviewDocumentComponent } from 'projects/order/src/app/rx-preview-document/rx-preview-document.component';
import { CcSettlementTabComponent } from 'projects/order/src/app/cc-settlement-tab/cc-settlement-tab.component';
import { MyOrdersComponent } from 'projects/order/src/app/my-orders/my-orders.component';
import { ImageAnnotationComponent } from 'projects/schedule/src/app/image-annotation/image-annotation.component';
import { PatientStatementComponent } from 'projects/admin/src/app/patient-statement/patient-statement.component';
import { CalendarScheduleV3Component } from 'projects/schedule/src/app/calendar-schedule-v3/calendar-schedule-v3.component';
import { OrdershipppingComponent } from 'projects/admin/src/app/ordershippping/ordershippping.component';
import { ResupplyComponent } from 'projects/order/src/app/resupply/resupply.component';
import { NikoBillingComponent } from 'projects/billing/src/app/niko-billing/niko-billing.component';
import { BillingViewInvoiceComponent } from 'projects/billing/src/app/niko-billing/billing-view-invoice/billing-view-invoice.component';
import { OrderInvoicesComponent } from 'projects/billing/src/app/order-invoices/order-invoices.component';
import { MasterResupplyComponent } from './master-resupply/master-resupply.component';
import { TwoFactorAuthenticationComponent } from 'projects/admin/src/app/two-factor-authentication/two-factor-authentication.component';
import { InsuranceAllowablesUpdateComponent } from 'projects/inventory/src/app/insurance-allowables-update/insurance-allowables-update.component';
import { ProductUpdateUnitCostComponent } from 'projects/inventory/src/app/product-update-unit-cost/product-update-unit-cost.component';
import { MasterDoctorComponent } from './master-doctor/master-doctor.component';
import { PatientCommonPdfViewerComponent } from 'projects/shared/src/app/components/patient-common-pdf-viewer/patient-common-pdf-viewer.component';
import { MyQueueComponent } from 'projects/admin/src/app/my-queue/my-queue.component';

const routes: Routes = [
  //Login
  { path: '', component: IdentityserverLoginComponent },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
    loadChildren: () =>
      import('projects/admin/src/app/app.module').then((m) => m.AdminModule),
  },
  {
    path: 'updatePassword',
    component: UpdatePasswordComponent,
    loadChildren: () =>
      import('projects/admin/src/app/app.module').then((m) => m.AdminModule),
  },
  //Layout-TopBar
  {
    path: '',
    component: HorizontalComponent,
    loadChildren: () =>
      import('projects/layout/src/app/app.module').then(
        (m) => m.HorizontolModule
      ),
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('projects/order/src/app/app.module').then((m) => m.OrderModule),
  },
  {
    path: 'ViewDocumentPerviewByBlobId/:blobName/:serviceName',
    component: DocumentPreviewComponent,
  },

  {
    path: 'ViewDocumentPerviewByBlobId/:blobName/:serviceName/:sClaimNumber',
    component: DocumentPreviewComponent,
  },
  {
    path: 'master/twoFactorAuthentication/:userId/:tenantId',
    component: TwoFactorAuthenticationComponent,
    loadChildren: () =>
      import('projects/inventory/src/app/app.module').then(
        (m) => m.InventoryModule
      ),
  },
  {
    path: '',
    component: HorizontalComponent,
    children: [
      // Dashboard
      {
        path: 'super_dashboard',
        component: DashboardComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      { path: 'dashboard', component: AdminDashboardComponent },
      { path: 'annotation', component: AnnotationComponent },

      { path: 'dashboard/:activeTab', component: AdminDashboardComponent },
      { path: 'myqueue', component: MyQueueComponent },
      {
        path: 'emailprintletter/:patientId',
        component: EmailPrintLetterComponent,
      },
      {
        path: 'emailprintletter/:patientId/:templateName',
        component: EmailPrintLetterComponent,
      },
      { path: 'RxEmailPrintLetter', component: RxEmailPrintLetterComponent },

      //Medicare Check List URL V1 Start
      {
        path: 'view_checklist/:patientId/:isview/:medicareChecklistId',
        component: DoVerificationModalComponent,
      },
      {
        path: 'view_checklist/:patientId/:isview/:isShow/:medicareChecklistId',
        component: DoVerificationModalComponent,
      },
      {
        path: 'do_checklist/:patientId/:medicareChecklistId',
        component: DoVerificationModalComponent,
      },
      //Medicare Check List URL V1 End
      {
        path: 'patient_preview_document/:blobName/:defaultId/:documentType',
        component: CommonDocViewerModalComponent,
      },
      {
        path: 'patient_preview_tiff_document/:headerText/:docId',
        component: CommonDocTiffViewerComponent,
      },
      {
        path: 'patient_preview_pdf_document/:docId/:blobName',
        component: PatientCommonPdfViewerComponent,
      },
      {
        path: 'patient_preview_pdf_document/:docId/:blobName/:isInbound',
        component: PatientCommonPdfViewerComponent,
      },
      {
        path: 'image',
        component: ImageAnnotationComponent,
      },
      {
        path: 'new_setup/:docId/:selectedNode',
        component: NewsetupModalComponent,
      },
      {
        path: 'new_setup/:docId/:selectedNode/:inboundShortCode',
        component: NewsetupModalComponent,
      },
      {
        path: 'new_setup/:docId/:selectedNode/:isSorting?/:inboundShortCode',
        component: NewsetupModalComponent,
      },
      {
        path: 'do_verification/:activeTabId',
        component: MasterVerification_doVerificationComponent,
      },
      {
        path: 'doAuthorization',
        component: MasterVerification_doAuthorizationComponent,
      },
      {
        path: 'view_document/:patientId/:documentId/:defaultDocumentId/:isSorted/:defaultFaxId/:blobName',
        component: ViewDocumentCheckListComponent,
      },
      {
        path: 'view_document_tiff/:docId',
        component: ViewDocumentCheckListTiffViewerComponent,
      },
      {
        path: 'view_document_tiff/:docId/:sortingType',
        component: ViewDocumentCheckListTiffViewerComponent,
      },
      {
        path: 'view_document_tiff_sorting/:docId/:isSorting/:sortingType/:isInbound',
        component: ViewDocumentCheckListTiffViewerComponent,
      },
      {
        path: 'dashboard/appointmentSchedule',
        component: AppointmentScheduleComponent,
      },
      { path: 'compliance-report', component: ComplianceReportComponent },
      { path: 'practicemanagement', component: PracticeManagementV2Component },
      {
        path: 'practicemanagementlist',
        component: PracticeManagementListComponent,
      },
      {
        path: 'addpracticemanagement/:id',
        component: AddPracticeManagementComponent,
      },
      {
        path: 'addpracticemanagement',
        component: AddPracticeManagementComponent,
      },
      {
        path: 'report-practicemanagement',
        component: ReportPracticeManagementComponent,
      },
      {
        path: 'preview-Document/:signatureRequestedId',
        component: PreviewDocumentComponent,
      },
      {
        path: 'RX-Preview-Document',
        component: RxPreviewDocumentComponent,
      },
      // { path: 'dashboardv1', component: AdminDashboardV1Component },
      // { path: 'dashboardv2', component: AdminDashboardV2Component },
      // { path: 'dashboardv3', component: AdminDashboardV3Component },
      // { path: 'dashboardv4', component: AdminDashboardV4Component },
      // { path: 'dashboardv5', component: AdminDashboardV5Component },
      // { path: 'dashboardv6', component: AdminDashboardV6Component },
      // { path: 'dashboardv7', component: AdminDashboardV7Component },
      { path: 'submenu', component: SubmenusampleComponent },

      {
        path: 'billing-reports',
        component: MisReportsComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'ar-reports',
        component: ArAgingReportComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      { path: 'patientImport', component: PatientImportComponent },
      { path: 'auth-followup-report', component: AuthFollowUpComponent },
      { path: 'auth-followup-report/active', component: AuthFollowUpComponent },
      { path: 'auth-followup-report/all', component: AuthFollowUpComponent },
      { path: 'auth-followup-report/expire', component: AuthFollowUpComponent },
      { path: 'InsuranceReport', component: InsuranceReportComponent },
      { path: 'master-state', component: MasterStateComponent },
      { path: 'master-city', component: MasterCityComponent },
      { path: 'import-mis-report', component: ImportMisReportComponent },
      { path: 'denial-reports', component: ClaimDenialReportsComponent },
      { path: 'denial-reports/All', component: ClaimDenialReportsComponent },
      { path: 'order-follow-up-reports', component: OrderFollowupComponent },
      {
        path: 'document-follow-up-reports',
        component: DocumentFollowupComponent,
      },

      {
        path: 'text-conversation',
        component: TextConversationMasterComponent,
      },

      //Master Pages
      { path: 'master-state', component: MasterStateComponent },
      { path: 'master-city', component: MasterCityComponent },
      // {
      //   path: 'inventory/master-modifiers',
      //   component: MasterModifiersComponent,
      // },
      {
        path: 'inventory/master-modifiers',
        component: MasterModifierV1Component,
      },
      {
        path: 'inventory/order-return',
        component: MmOrderReturnExchangeReason1Component,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/master-mminsurance',
        component: MasterMminsuranceComponent,
      },
      {
        path: 'inventory/master-size',
        component: SizeComponent,
      },
      {
        path: 'inventory/requisition-viewer',
        component: RequistionViewerComponent,
      },
      {
        path: 'master/color-code',
        component: ColorCodesMasterComponent,
      },

      //Patient
      { path: 'patientSearch', component: PatientSearchComponent },

      { path: 'patientDocV2', component: DocumentTabComponent },
      { path: 'addPatitentDocNpi', component: AddDocNpiComponent },
      { path: 'npiDetails', component: NpiDetailsComponent },
      { path: 'patientClinicalV2', component: PatientClinicalTabComponent },
      { path: 'patientv2', component: PatientsTabComponent },
      { path: 'patientSearchV2', component: PatientSearchTabComponent },
      { path: 'patient-list', component: PatientListV2Component },
      {
        path: 'inbound_sorting_pending',
        component: InboundSortingPendingComponent,
      },
      { path: 'batch_sorting', component: InboundBatchSortingComponent },
      // { path: 'batch_sorting_tiff', component: InboundBatchSortingTiffComponent },

      { path: 'notesSearch', component: CommonNotesSearchComponent },
      { path: 'notesSearchV1', component: CommonNotesSearchV1Component },

      { path: 'patientCreateEdit', component: PatientCreateEditComponent },
      {
        path: 'patientCreateEditTab',
        component: PatientCreateEditTabComponent,
      },
      { path: 'text-conversation', component: TextConversationComponent },

      {
        path: 'inventory/product',
        component: ProductTabComponent,
      },
      {
        path: 'inventory/productupdate',
        component: ProductUpdateUnitCostComponent,
      },
      {
        path: 'inventory/Adjustment',
        component: AdjustmentComponent,
      },
      {
        path: 'inventory/requisition',
        component: RequisitionFormComponent,
      },
      {
        path: 'inventory/requisition-list',
        component: RequisitionListComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/product-brand-tab',
        component: ProductBrandTabComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/zero-stock-list',
        component: ZeroStockListComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/timer',
        component: TimerComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/status',
        component: StatusComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/product-category-tab',
        component: ProductCategoryTabComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/vendor-tab',
        component: ProductVendorTabComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'documentSorting/:patientId/:documentId',
        component: DocumentSortingComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'assignDocumentTab/:documentId/:blobName',
        component: AssignDocumentTabComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },

      {
        path: 'orders/add-exchange-return-item/:patientId/:orderId',
        component: AddPickupExchangeItemTabComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },

      {
        path: 'patientCreateEditTab/:patientId',
        component: PatientCreateEditTabComponent,
      },
      {
        path: 'patientCreateEditTab/:patientId/:documentId',
        component: PatientCreateEditTabComponent,
      },
      {
        path: 'patientCreateEditTab/:patientId/:wipId',
        component: PatientCreateEditTabComponent,
      },
      {
        path: 'patientCreateEditTab/:patientId/:documentId/:activeTab',
        component: PatientCreateEditTabComponent,
      },
      {
        path: 'shipping-list',
        component: ShippingListComponent,
      },

      { path: 'patientPersonal', component: PatientPersonalComponent },
      { path: 'admin-calender', component: AdminCalenderComponent },
      // Referral-practice
      {
        path: 'referral-practice-list',
        component: ReferralPracticeListComponent,
      },
      { path: 'referral-practice', component: ReferralPracticeComponent },
      {
        path: 'referral-practice/:practiceId',
        component: ReferralPracticeComponent,
      },

      //Order
      {
        path: 'orders/saleorderCreateEdit/:id',
        component: OrderCreateEditComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'patient/saleorderCreateEdit/:orderId/:editOrView',
        component: OrderCreateEditComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'orders/saleorderCreateEditTab/:patientId/:orderId',
        component: OrderCreateEditTabComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'orders/OrderTemplateCreateEditTab/:patientId/:orderId/:templateId',
        component: OrderCreateEditTabComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },

      {
        path: 'orders/saleOrder/:patientId/:orderId/:pageType',
        component: SaleOrderComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'orders/saleOrder',
        component: SaleOrderComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'orders/saleOrder/:patientId/:orderId/:isCopyOrder/:pageType',
        component: SaleOrderComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'orders/saleOrder/:patientId/:orderId/:isCopyOrder/:pageType/:isResupply',
        component: SaleOrderComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'orders/saleOrderview/:patientId/:orderId',
        component: SaleOrderViewComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'orders/invoiceview/:orderId/:claimId/:rentalOrderId',
        component: BillingViewInvoiceComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'orders/sale-order-invoices/:orderId',
        component: OrderInvoicesComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },

      {
        path: 'patient/saleorderCreateEdit/:orderId',
        component: OrderCreateEditComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'patient/saleorderCreateEdit',
        component: OrderCreateEditComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },

      { path: 'rx-report', component: RxReportComponent },
      {
        path: 'Hello-sign/:orderId/:patientId/:signatureId/:isView',
        component: HelloSignComponent,
      },
      //Topabar - Menus
      {
        path: 'patient/insuranceVerification',
        component: PatientVerifyMenuComponent,
      },
      // {
      //   path: 'patient/cc-settlement',
      //   component: CcSettlementComponent,
      // },
      {
        path: 'patient/cc-settlement',
        component: CcSettlementTabComponent,
      },
      {
        path: 'patient/insuranceAuthorization',
        component: PatientAuthMenuComponent,
      },
      {
        path: 'patient/rental-list',
        component: RentalListComponent,
      },
      {
        path: 'authorization-list',
        component: PatientAuthorizationListComponent,
      },
      {
        path: 'authDetails/:Id',
        component: PatientAuthorizationDetailsComponent,
      },

      {
        path: 'patient/orders',
        component: RenewalOrdersMenuComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },

      {
        path: 'patient/my-orders',
        component: MyOrdersComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'patient/order-list',
        component: OrderSearchTabComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'patient/order-list/hellosignreport',
        component: HelloSignReportComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'orders/exchange-list',
        component: ExchangeListComponent,
        loadChildren: () =>
          import('projects/order/src/app/app.module').then(
            (m) => m.OrderModule
          ),
      },
      {
        path: 'orders/shippingApprovalList',
        component: ShippingApprovalListComponent,
        loadChildren: () =>
          import('projects/shipping/src/app/app.module').then(
            (m) => m.ShippingModule
          ),
      },
      {
        path: 'orders/shipping-approvallist-tab',
        component: ShippingListTabComponent,
        loadChildren: () =>
          import('projects/shipping/src/app/app.module').then(
            (m) => m.ShippingModule
          ),
      },
      {
        path: 'orders/shipping-disapproved-list',
        component: ShippingDisapprovedListComponent,
        loadChildren: () =>
          import('projects/shipping/src/app/app.module').then(
            (m) => m.ShippingModule
          ),
      },

      {
        path: 'orders/override-list',
        component: OverrideListComponent,
        loadChildren: () =>
          import('projects/shipping/src/app/app.module').then(
            (m) => m.ShippingModule
          ),
      },
      {
        path: 'orders/return-orders-list',
        component: ReturnOrderListComponent,
        loadChildren: () =>
          import('projects/shipping/src/app/app.module').then(
            (m) => m.ShippingModule
          ),
      },
      {
        path: 'orders/InvoiceListTab',
        component: InvoiceListComponent,
      },
      //Shipping
      {
        path: 'shippingView/:id/:orderId/:patientId',
        component: ShippingApprovalTabComponent,
        loadChildren: () =>
          import('projects/shipping/src/app/app.module').then(
            (m) => m.ShippingModule
          ),
      },
      {
        path: 'orders/shippingApproval/:id',
        component: ShippingCreateEditComponent,
        loadChildren: () =>
          import('projects/shipping/src/app/app.module').then(
            (m) => m.ShippingModule
          ),
      },
      {
        path: 'orders/shippingApproval',
        component: ShippingCreateEditComponent,
      },
      {
        path: 'orders/shippingTicketList',
        component: ShippingTicketListComponent,
      },
      {
        path: 'orders/shippingApprovedList',
        component: ApprovedListsComponent,
      },
      {
        path: 'orders/shippingReturnTicketList',
        component: ShippingReturnListComponent,
      },
      {
        path: 'orders/shippingTicketView/:orderId',
        component: ShippingViewComponent,
      },
      {
        path: 'orders/shippingReturnApprovedList',
        component: ReturnApprovedListComponent,
      },
      { path: 'orders/readyToPackList', component: ReadyToPackListComponent },
      {
        path: 'orders/warehoseManagerReports',
        component: ShippingWMangerReportsComponent,
      },
      {
        path: 'orders/warehoseExecutiveReports',
        component: ShippingExecReportComponent,
      },

      //Warehouse
      {
        path: 'orders/billingWarehouse',
        component: WarehouseListComponent,
        loadChildren: () =>
          import('projects/shipping/src/app/app.module').then(
            (m) => m.ShippingModule
          ),
      },
      {
        path: 'orders/shippingViewTicket/:orderId',
        component: ShippingTicketComponent,
      },
      {
        path: 'orders/readyToDeliverList',
        component: ReadyToDeliveryComponent,
      },
      {
        path: 'orders/trackView/:orderId',
        component: ProductTrackViewComponent,
      },
      {
        path: 'viewPatientLedger',
        component: ViewPatientLedgerComponent,
      },
      {
        path: 'PrimaryIns',
        component: PrimaryInsComponent,
      },
      {
        path: 'viewNotes',
        component: ViewNotesComponent,
      },

      //Billing
      {
        path: 'billing-list',
        component: BillingListTabComponent,
        loadChildren: () =>
          import('projects/billing/src/app/app.module').then(
            (m) => m.BillingModule
          ),
      },
      {
        path: 'billing/billingCreateEdit/:id',
        component: BillingCreateEditComponent,
        loadChildren: () =>
          import('projects/billing/src/app/app.module').then(
            (m) => m.BillingModule
          ),
      },
      {
        path: 'billing/billingCreateEdit',
        component: BillingCreateEditComponent,
      },
      { path: 'billing/check', component: CheckEftSelectionComponent },
      { path: 'billing/posting', component: PostingListComponent },
      { path: 'AddNewPosting/:id', component: AddPaymentsComponent },
      { path: 'AddNewPosting/:id/:paymentId', component: AddPaymentsComponent },
      { path: 'billing/history/:id', component: ClaimHistoryComponent },

      { path: 'claim/history/:id', component: PostingHistoryComponent },
      { path: 'claim/posting-history/:id', component: PostingHistoryComponent },
      {
        path: 'claim/manualhistory/:id',
        component: ManualPostingHistoryComponent,
      },
      { path: 'billing/claimProcessingList', component: ClaimProcessComponent },
      {
        path: 'billing/claimprocessing-list',
        component: ClaimProcessTabComponent,
      },
      {
        path: 'billing/claim-batch',
        component: ClaimBatchComponent,
      },
      {
        path: 'billing/auto-posting-era',
        component: AutoPostingERAComponent,
      },
      {
        path: 'billing/patientclaims-tab',
        component: PatientClaimsTabComponent,
      },
      { path: 'billing/claimStatusList', component: ClaimStatusComponent },
      {
        path: 'billing/claimStatusListTab',
        component: ClaimStatusTabComponent,
      },
      {
        path: 'billing/claims-list',
        component: ClaimStatusListComponent,
        loadChildren: () =>
          import('projects/billing/src/app/app.module').then(
            (m) => m.BillingModule
          ),
      },
      // {
      //   path: 'billing/niko-billing',
      //   component: NikoBillingComponent,
      //   loadChildren: () =>
      //     import('projects/billing/src/app/app.module').then(
      //       (m) => m.BillingModule
      //     ),
      // },
      {
        path: 'billing/billing',
        component: NikoBillingComponent,
        loadChildren: () =>
          import('projects/billing/src/app/app.module').then(
            (m) => m.BillingModule
          ),
      },
      // ClaimStatusListComponent
      { path: 'billing/claimCheckList', component: ChequeListComponent },
      { path: 'billing/claimCheckListTab', component: ClaimCheckListComponent },
      {
        path: 'billing/claim-check-list',
        component: ClaimChequeListComponent,
        loadChildren: () =>
          import('projects/billing/src/app/app.module').then(
            (m) => m.BillingModule
          ),
      },
      { path: 'billing/ticket', component: ShippingTicketComponent },

      { path: 'inventory/item/:import', component: ItemSuppliesComponent },
      { path: 'shipping/virePdf/:id', component: TicketPdfViewComponent },
      //Admin
      {
        path: 'clientList',
        component: ClientComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'processedDocuments',
        component: ProcessedDocumentsTabComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'sortingDocuments',
        component: SortingDocumentsTabComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'documentSorting',
        component: DocumentSortingComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'processedDocuments',
        component: ProcessedDocumentsTabComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'clientView/:id',
        component: AdminCreateEditComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'clientCreate',
        component: AdminCreateEditComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'inventory/item',
        component: InventoryItemComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/productTab',
        component: ProductTabComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/stock-list-tab',
        component: StockListComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/resupplyTab',
        component: ResupplyProductTabComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },

      {
        path: 'inventory/payor',
        component: InventoryPayorComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'master/document-bin',
        component: MasterDocumentBinComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },

      {
        path: 'inventory/master-wip-status',
        component: MasterWipStatusComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/master-mask',
        component: MasterMaskComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/notes',
        component: InventoryNotesComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/discount',
        component: MaterDiscountComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'master/template',
        component: TemplateCreationComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'master/resupply',
        component: MasterResupplyComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'master/physician',
        component: MasterDoctorComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/provider',
        component: InventoryProviderComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/provider/details',
        component: InventoryProviderDetailsComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/addPurchaseOrder/:id',
        component: AddPurchaseOrderComponent,
      },
      {
        path: 'inventory/addPurchaseOrder',
        component: AddPurchaseOrderComponent,
      },
      {
        path: 'inventory/purchase-order',
        component: PurchaseOrderTabComponent,
      },
      //! Item Management
      {
        path: 'inventory/product-brand',
        component: ProductBrandComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/product-category',
        component: ProductCategoryComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/product-list',
        component: InventoryProductComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/allowables-list',
        component: AllowablesListComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/insurance-allowables',
        component: AllowablesCalculationComponent,
      },
      {
        path: 'inventory/insurance-allowables-list',
        component: InsuranceAllowablesComponent,
      },
      {
        path: 'inventory/insurance-allowables-update',
        component: InsuranceAllowablesUpdateComponent,
      },
      {
        path: 'inventory/allowables-tab',
        component: AllowablesTabComponent,
      },
      {
        path: 'inventory/vendor',
        component: VendorComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/stock-list',
        component: ProductStockComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'inventory/stock-view/:id',
        component: StockViewComponent,
        loadChildren: () =>
          import('projects/inventory/src/app/app.module').then(
            (m) => m.InventoryModule
          ),
      },
      // {
      //   path: 'inventory/master-mminsurance',
      //   component: MastermminsurancegroupComponent,
      //   loadChildren: () =>
      //     import('projects/inventory/src/app/app.module').then(
      //       (m) => m.InventoryModule
      //     ),
      // },
      { path: 'ordershippping', component: OrdershipppingComponent },
      { path: 'resupply', component: ResupplyComponent },
      { path: 'ordershippping', component: OrdershipppingComponent },
      //! Fax Bin Routes
      {
        path: 'uploadBin',
        component: UploadBinComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'uploadBin2',
        component: UploadBinBotComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'uploadBinC',
        component: UploadBinBotcComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'inboundDocs',
        component: InboundTabComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },

      {
        path: 'ArchivedinboundDocs',
        component: ArchivedInboundListComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },

      {
        path: 'archiveDocuments',
        component: ArchiveBotDocComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'approvalList/:id',
        component: ApprovalListComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'subBatchList/:id',
        component: SubBatchListComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'subBatchListTemp/:id',
        component: SubBatchListTempComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'approvalList2/:id',
        component: ApprovalBotListComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'subBatchList2/:id',
        component: SubBatchBotListComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'maps',
        component: AddCoverageAreaComponent,
        loadChildren: () =>
          import('projects/on-time-schedules/src/app/app.module').then(
            (m) => m.OnTimeModule
          ),
      },

      // leave approval routing
      {
        path: 'userLeaveApply',
        component: OntimeLeaveApplyComponent,
        loadChildren: () =>
          import('projects/on-time-schedules/src/app/app.module').then(
            (m) => m.OnTimeModule
          ),
      },

      {
        path: 'Faxbin',
        component: UserTaskListComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'billing-report',
        component: BillingReportsTabComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'patient-statement-report',
        component: PatientStatementComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'ar-aging-report',
        component: ArAgingReportsTabComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'patient/userTaskReportTab',
        component: UserTaskReportTabComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },

      {
        path: 'patient/taskListTab',
        component: TaskListPageTabComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'tools/patient-management',
        component: PatientListV1Component,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'patient/new-insurance-review',
        component: PatientNewInsuranceReviewComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'patient/verification',
        component: VerificationComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'navigation/masterverification',
        component: MIVerificationComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'currentsupplies/masterverification/:patientId',
        component: MIVerificationComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'tools/my-verification',
        component: MyverficationV1Component,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'tools/my-medicarechecklist',
        component: MedicareCheckListComponent,
      },
      {
        path: 'Lazyloading',
        component: LazyloadingComponent,
      },
      {
        path: 'navigation/mastermedicarechecklist',
        component: MasterMedicareChecklistComponent,
      },
      {
        path: 'currentsupplies/mastermedicarechecklist/:patientId',
        component: MasterMedicareChecklistComponent,
      },
      {
        path: 'patient/patientClaim/:id',
        component: PatientClaimComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'order/pickupExchangeList',
        component: PickupExchangeListComponent,
      },
      {
        path: 'patient/customerServicePool',
        component: CustomerServicePoolComponent,
      },
      {
        path: 'patient/patientOrders/:id',
        component: PatientOrdersComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },

      {
        path: 'ViewDocumentDetailsAndApprove/:fileId/:patientId',
        component: DocumentPerviewerComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'ViewDocumentDetailsAndApprove/:fileId',
        component: DocumentPerviewerComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: 'ViewAllPatientsListData',
        component: PatientListComponent,
        loadChildren: () =>
          import('projects/admin/src/app/app.module').then(
            (m) => m.AdminModule
          ),
      },
      //! on time Routes
      {
        path: 'onTime',
        component: OnTimeCreateEditComponent,
        loadChildren: () =>
          import('projects/on-time-schedules/src/app/app.module').then(
            (m) => m.OnTimeModule
          ),
      },
      {
        path: 'onTimeSchedule',
        component: OnTimeScheduleCreateEditComponent,
        loadChildren: () =>
          import('projects/schedule/src/app/app.module').then(
            (m) => m.onTimeScheduleModule
          ),
      },
      // shipping ticket Routings
      {
        path: 'shippingTickets',
        component: ShippingTicketCreateEditComponent,
        loadChildren: () =>
          import('projects/shipping/src/app/app.module').then(
            (m) => m.ShippingModule
          ),
      },
      //Old Schdule Calender Version 1
      // {
      //   path: 'settings/calender',
      //   component: CalenderScheduleComponent,
      //   loadChildren: () =>
      //     import('projects/schedule/src/app/app.module').then(
      //       (m) => m.onTimeScheduleModule
      //     ),
      // },
      // {
      //   path: 'settings/calender/:patientId',
      //   component: CalenderScheduleComponent,
      //   loadChildren: () =>
      //     import('projects/schedule/src/app/app.module').then(
      //       (m) => m.onTimeScheduleModule
      //     ),
      // },
      // {
      //   path: 'settings/calender/:patientId/:documentId',
      //   component: CalenderScheduleComponent,
      //   loadChildren: () =>
      //     import('projects/schedule/src/app/app.module').then(
      //       (m) => m.onTimeScheduleModule
      //     ),
      // },

      //New Schdule Calender Version 1
      {
        path: 'settings/calender',
        component: CalenderScheduleV2Component,
        loadChildren: () =>
          import('projects/schedule/src/app/app.module').then(
            (m) => m.onTimeScheduleModule
          ),
      },
      {
        path: 'tools/scheduleV1',
        component: CalendarScheduleV3Component,
        loadChildren: () =>
          import('projects/schedule/src/app/app.module').then(
            (m) => m.onTimeScheduleModule
          ),
      },
      {
        path: 'settings/calender/:patientId',
        component: CalenderScheduleV2Component,
        loadChildren: () =>
          import('projects/schedule/src/app/app.module').then(
            (m) => m.onTimeScheduleModule
          ),
      },
      {
        path: 'settings/calender/:patientId/:documentId',
        component: CalenderScheduleV2Component,
        loadChildren: () =>
          import('projects/schedule/src/app/app.module').then(
            (m) => m.onTimeScheduleModule
          ),
      },

      //Version 1 For Schedule Calender
      {
        path: 'settings/calenderV1',
        component: CalenderScheduleV2Component,
        loadChildren: () =>
          import('projects/schedule/src/app/app.module').then(
            (m) => m.onTimeScheduleModule
          ),
      },
      {
        path: 'settings/calender-list',
        component: CalendarListComponent,
        loadChildren: () =>
          import('projects/schedule/src/app/app.module').then(
            (m) => m.onTimeScheduleModule
          ),
      },
      {
        path: 'settings/schedule-list',
        component: CalendarListV1Component,
        loadChildren: () =>
          import('projects/schedule/src/app/app.module').then(
            (m) => m.onTimeScheduleModule
          ),
      },
    ],
  },
  { path: 'cashPayment/:id', component: CashPaymentViewComponent },
  { path: 'cashBillPayment/:id', component: BillingCashPaymentViewComponent },
  { path: 'userRegistration', component: UserRegisterComponent },
  // Wild card routing
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
