import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AssignmentDTO, ClaimFillingCodeDTO, GenderDTO, InsuranceTypeDTO, PatientMasterDropdownDTO, PayorLevelDTO, RelationShipWithSelfDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { planInterface } from 'projects/patient/src/app/patient-add-policy/patient-add-policy.component';
import { PayerService, PverifyPayersService } from 'projects/patient/src/app/patient-proxy/patient';
import { PolicyDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { PatientDTO } from '../admin-proxy/platform-app-management/rcm/patient-management/patient/dto/models';
import { FileUploadService } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/file-upload.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-extractionaddpolciy',
  templateUrl: './extractionaddpolciy.component.html',
  styleUrls: ['./extractionaddpolciy.component.scss'],
  providers: [ListService]
})
export class ExtractionaddpolciyComponent implements OnInit {
  policyModalTitle: string = '';
  payorLevel: PayorLevelDTO[] = [];
  drpInsurance: InsuranceTypeDTO[] = [];
  policyForm: FormGroup;
  drpPlan: planInterface[] = [];
  payorlevel: string;
  payorInsurance: string;
  Insurance: string = '';
  Payers: string = '';
  public filteredInsuranceTypes: Observable<planInterface[]> | undefined;
  public filteredPayerTypes: Observable<{ id: string; name: string; payerCode: string; }[]> | undefined;
  fileId: string = '';
  batchId: string = '';
  tblPolicesReponse: any[];
  selectedPolicy: PolicyDTO;
  relationshipLoop: RelationShipWithSelfDTO[];
  drpclaimLoop: ClaimFillingCodeDTO[];
  drpgenderLoop: GenderDTO[];
  drpAssignment: AssignmentDTO[];
  policiesId: string;
  sLoginUserName: string;
  drpPayerList: { id: string; payerId: string; payerName: string; payerShortCodeId: string; }[];
  drpPayerId: { id: string; name: string; payerCode: string; }[];
  patientData?: PatientDTO;

  constructor(
    public dialog: MatDialog,
    private listService: ListService,
    private formBuilder: FormBuilder,
    private fileUploadService: FileUploadService,
    private planService: PverifyPayersService,
    public policyDialogRef: MatDialog,
    private payerService: PayerService,
    private patientDropdownService: PatientMasterDropdownService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { fileId?: string, batchId?: string, patientData?: PatientDTO }
  ) { }

  ngOnInit(): void {
    this.fileId = this.data?.fileId ?? "";
    this.batchId = this.data?.batchId ?? "";
    this.patientData = this.data?.patientData ?? null;
    this.fileId && this.batchId && this.getPatientDetailsForPolicy();
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.PayorLevels, PatientDropdowns.RelationShipWithSelf, PatientDropdowns.ClaimFillingCodes, PatientDropdowns.Genders, PatientDropdowns.InsuranceTypes, PatientDropdowns.Assignments
    ]
    this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      let response: PatientMasterDropdownDTO = stateResponse;
      this.payorLevel = response.payorLevels;
      this.relationshipLoop = response.relationShipWithSelves;
      this.drpclaimLoop = response.claimFillingCodes;
      this.drpgenderLoop = response.genders;
      this.drpInsurance = response.insuranceTypes;
      this.drpAssignment = response.lstAssignment;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.planService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpPlan = [];
      response?.items?.forEach(element => {
        this.drpPlan.push({
          id: element.id ?? "",
          name: element?.payerName ?? "",
          code: element?.payerCode ?? "",
          payorId: element.shortCodeId ?? ""
        });
      });
      this.filteredInsuranceTypes = this.policyForm?.get("txtInsuranceFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpPlan.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.payerService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpPayerId = [];
      response?.items?.forEach(element => {
        this.drpPayerId.push({
          id: element.id ?? "",
          name: element?.payerId + ' / ' + element?.payerName,
          payerCode: element?.payerId ?? ""
        });

      });
      this.filteredPayerTypes = this.policyForm?.get("txtPayerFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpPayerId?.filter((option: { name: string; }) => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });

    });

    const patientList = query => this.payerService.getList(query);
    this.listService?.hookToQuery(patientList)?.subscribe(response => {
      this.drpPayerList = [];
      if (response && response?.items && response?.items !== []) {
        response?.items?.forEach(element => {
          this.drpPayerList.push({
            id: element?.id,
            payerId: element.payerId,
            payerName: element.payerName,
            payerShortCodeId: element.payerShortCodeId,
          });
        })
      }
    }, (err: HttpErrorResponse) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.policyForm = this.formBuilder.group({
      drpPayor: new FormControl(null, [Validators.required]),
      drpInsurance: new FormControl(null, [Validators.required]),
      txtPolicyInfoPolicyNo: new FormControl("", Validators.required),
      txtPolicyInfoPayorId: new FormControl(null, Validators.required),
      txtNote: new FormControl(null),
      txtPayerFilter: new FormControl(""),
      txtInsuranceFilter: new FormControl(""),


    });
  }
  clearInsurance() {
    this.Insurance = '';
    this.policyForm.patchValue({ drpInsurance: "" });

  }
  clearPayers() {
    this.Payers = '';
    this.policyForm.patchValue({ txtPolicyInfoPayorId: "" });
  }
  clearPayorlevel() {
    this.payorlevel = '';
    this.policyForm.patchValue({ drpPayor: "" });
  }
  getPatientDetailsForPolicy() {
    this.fileUploadService.getFileBatchByIdByBatchId(this.batchId).subscribe(response => {
      response?.listFileUploads?.forEach(element => {
        if (element.fileId === this.fileId) {
          if (element?.extractedData?.extractedfields?.policies?.primaryPolicy) {
            const selectedPolicy: PolicyDTO = element?.extractedData?.extractedfields?.policies?.primaryPolicy;
            this.selectedPolicy = element?.extractedData?.extractedfields?.policies?.primaryPolicy;
            this.policyForm.patchValue({
              drpPayor: selectedPolicy?.payorLevel,
              drpInsurance: selectedPolicy?.insuranceType?.trim() ?? "",
              txtPolicyInfoPayorId: selectedPolicy?.payorId,
              txtPolicyInfoPolicyNo: selectedPolicy?.policyNo,
              txtNote: selectedPolicy?.note,
            });
          }
        }
      });
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }


  getPatientDetailsForPolicySave() {

    this.selectedPolicy = {
      policiesId: this.policiesId === "00000000-0000-0000-0000-000000000000" || undefined ? "00000000-0000-0000-0000-000000000000" : this.selectedPolicy?.policiesId,
      payorLevel: this.policyForm.value.drpPayor,
      insuranceType: this.policyForm.value.drpInsurance,
      policyNo: this.policyForm.value.txtPolicyInfoPolicyNo,
      payorId: this.policyForm.value.txtPolicyInfoPayorId,
      note: this.policyForm.value.txtNote,
      coverageType:  this.selectedPolicy?.coverageType,
      insuranceId: this.selectedPolicy?.insuranceId,
      payorContact: this.selectedPolicy?.payorContact,
      startDate: this.selectedPolicy?.startDate,
      endDate: this.selectedPolicy?.endDate,
      payPct: this.selectedPolicy?.payPct,
      deductible: this.selectedPolicy?.deductible,
      deductibleMet: this.selectedPolicy?.deductibleMet,
      outOfPocketExpense: this.selectedPolicy?.outOfPocketExpense,
      outOfPocketExpenseMet: this.selectedPolicy?.outOfPocketExpenseMet,
      groupNo: this.selectedPolicy?.groupNo,
      groupName: this.selectedPolicy?.groupName,
      user1: this.selectedPolicy?.user1,
      user2: this.selectedPolicy?.user2,
      user3: this.selectedPolicy?.user3,
      user4: this.selectedPolicy?.user4,
      relationShipType: this.selectedPolicy?.relationShipType,
      additionalPtId: this.selectedPolicy?.additionalPtId,
      claimFillingCode: this.selectedPolicy?.claimFillingCode,
      tplName: this.selectedPolicy?.tplName,
      tplCode: this.selectedPolicy?.tplCode,
      propertyAndCasualtyAgency: this.selectedPolicy?.propertyAndCasualtyAgency,
      carrierId: this.selectedPolicy?.carrierId,
      alwaysCrossover: this.selectedPolicy?.alwaysCrossover,
      copyPatientInfo: this.selectedPolicy?.copyPatientInfo,
      lastName: this.selectedPolicy?.lastName,
      fisrtName: this.selectedPolicy?.fisrtName,
      middleName: this.selectedPolicy?.middleName,
      suffix: this.selectedPolicy?.suffix,
      birthdate: this.selectedPolicy?.birthdate,
      gender: this.selectedPolicy?.gender,
      ssn: this.selectedPolicy?.ssn,
      address1: this.selectedPolicy?.address1,
      address2: this.selectedPolicy?.address2,
      city: this.selectedPolicy?.city,
      state: this.selectedPolicy?.state,
      country: this.selectedPolicy?.country,
      postalCode: this.selectedPolicy?.postalCode,
      phone: this.selectedPolicy?.phone,
      mobile: this.selectedPolicy?.mobile,
      employer: this.selectedPolicy?.employer,
      employerContact: this.selectedPolicy?.employerContact,
      employerAddress1: this.selectedPolicy?.employerAddress1,
      employerAddress2: this.selectedPolicy?.employerAddress2,
      employerCity: this.selectedPolicy?.employerCity,
      employerSate: this.selectedPolicy?.employerSate,
      employerCountry: this.selectedPolicy?.employerCountry,
      employerPostalCode: this.selectedPolicy?.employerPostalCode,
      employerPhone: this.selectedPolicy?.employerPhone,
      box10d: this.selectedPolicy?.box10d,
      box19: this.selectedPolicy?.box19,
      insuredId: this.selectedPolicy?.insuredId,
      assignmentId: this.selectedPolicy?.assignmentId,
      copay: this.selectedPolicy?.copay,
      added: this.selectedPolicy?.added,
      employerId:this.selectedPolicy?.employerId,
      id:this.selectedPolicy?.id
    }
    this.patientData = {
      policies: {
        primaryPolicy: this.selectedPolicy,
        secondaryPolicy: null,
        tertiaryPolicy: null
      },
      personals: this.patientData?.personals,
      addresses: this.patientData?.addresses,
      contacts: this.patientData?.contacts,
      clinicals: this.patientData?.clinicals,
      insurances: this.patientData?.insurances,
      notes: this.patientData?.notes,
      insuranceVerifications: this.patientData?.insuranceVerifications,
      authorizations: this.patientData?.authorizations,
      defaultPatientId: this.patientData?.defaultPatientId,
      chartId: this.patientData?.chartId,
      isAutoIntake: this.patientData?.isAutoIntake,
      autoIntakeDocumentDetails: this.patientData?.autoIntakeDocumentDetails,
      sleepStudyReport: this.patientData?.sleepStudyReport,
      complainceReport: this.patientData?.complainceReport,
      organizationUnitId: this.patientData?.organizationUnitId,
      patientDocuments:this.patientData?.patientDocuments,
      billingDetails: this.patientData?.billingDetails,
      patientLock: this.patientData?.patientLock,
      isRescheduled:this.patientData?.isRescheduled,
      tenantId: this.patientData?.tenantId,
      saleOrderId :this.patientData?.saleOrderId,
      id :this.patientData?.id,
      batchId: this.patientData?.batchId,
      // fileId: this.patientData?.fileId,
    };
    setTimeout(() => {
      this.fileUploadService.updateExtractedDetailsOnFileByBatchIdAndFileIdAndExtractedDataInput(this.batchId, this.fileId, this.patientData).subscribe(() => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success') 
        this.policyDialogRef.closeAll();
      }, error => {
        const data: HttpErrorResponse = error;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }, 100);

  }

}
