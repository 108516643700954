<div class="row">
  <div class="col-lg-6">
    <h5 class="customThemeClass"><b>Permissions</b></h5>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-xl-12">

      <div class="row">
        <div class="col-lg-12">
          <mat-card-content>
            <form [formGroup]="permissionForm">
              <div class="row">
                <div class="col-12 pb-2">
                  <div class="example-action-buttons">
                    <!-- <button mat-button (click)="accordion.closeAll()">Collapse All</button>
                    <button mat-button (click)="accordion.openAll()">Expand All</button>&nbsp; -->
                    <div class="row">
                      <div class="col-12 ">
                        <mat-checkbox formControlName="chkAllManagement" (change)="selectOverAll()" > Select
                          All</mat-checkbox>
                          <button class="float-right" mat-button (click)="toggleAccordion()" id="collapse-btn">Expand All</button>

                      </div>
                      
                    </div>
                    
                  </div>

                  <mat-accordion class="example-headers-align" multi>
                    <ng-container *ngFor="let menu of categoriesV1">
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title class="color">
                            
                            {{ menu | titlecase }}  
                          </mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-checkbox class="col-12" #selectAllCheckboxRef (change)="selectAllV1(menu)"
                                      [checked]="isCategorySelectedV1(menu)">
                          Select All Permission for {{ menu | titlecase }}
                        </mat-checkbox>
                        <mat-divider class="my-2"></mat-divider>

                        <div *ngFor="let submenu of Object.keys(groupedPermissions[menu]);let i = index">
                          <hr *ngIf="i !== 0">
                          <mat-panel-title class="col-12 px-0 color py-1" >
                            {{ submenu | titlecase }}
                          </mat-panel-title>
                          <div class="row">
                            <div class="col-12 px-5 checkbox-row">
                              <ng-container *ngFor="let permission of groupedPermissions[menu][submenu]">
                                <mat-checkbox (change)="togglePermissionV1(menu, submenu + '_' + permission.access)"
                                            [checked]="isSelectedV1(menu, submenu + '_' + permission.access)">
                                {{ permission.access | titlecase }}
                              </mat-checkbox>
                              </ng-container>
                            </div>
                            
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </ng-container>
                  </mat-accordion>

                </div>
              </div>
            </form>
          </mat-card-content>
        </div>
      </div>


    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="buttonColor mr-2" (click)="savePermission()">Save</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
