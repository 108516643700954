import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { EOBERAReportService } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/eoberareport.service';
import { InvoiceReportService } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/invoice-report.service';
import { clsEOBERAReportSearch, clsInvoiceReportSearch } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/dto';
import { BillingReportService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/optimization/billing-reports';
import { defaultGuid, nikoBillingMenu, nikoBillingMenuStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invoice-bin-queue',
  templateUrl: './invoice-bin-queue.component.html',
  styleUrls: ['./invoice-bin-queue.component.scss']
})
export class InvoiceBinQueueComponent implements OnInit {
  nikoBillingMenu = nikoBillingMenuStatus;
  activeTab = nikoBillingMenuStatus.INVOICES;
  activeTabBin: nikoBillingMenuStatus;
  dataCount=0;
  inboundTableData:any;
  ltPaymentsReport:any;
  eobData:any;
  loadInboundData:boolean =false;
  loadInvoiceTableAPICall:Subscription;
  invoiceCount=0;
  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private invoiceReportService: InvoiceReportService,
    private billingReportService: BillingReportService,
    private _snackBar: MatSnackBar,
    private commonService: CommonService,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private eobService: EOBERAReportService,
  ) { }

  ngOnInit() {
    this.activeTabBin = nikoBillingMenuStatus.INVOICES;
    this.getTableData();
  }
  getArrowClass(data: any): any {
    return { [data.arrowclass]: this.activeTabBin === data.status};
   }
   binActiveTab(data: any) {
    this.activeTabBin = data?.status;
    if(this.activeTabBin === nikoBillingMenuStatus.INVOICES) {
      this.getTableData();
    }else if(this.activeTabBin === nikoBillingMenuStatus.PAYMENTS) {
        this.getPaymentsReport(defaultGuid,null,null,null,null,null);
    }else if(this.activeTabBin === nikoBillingMenuStatus.EOB_ERA) {
      this.getEOBERAReport(null,null,null,null);
    }

  }
  getTableData() {
    // this.txtDOSFromDate = this.datepipe.transform(
    //   this.invoiceSearchForm.value.txtDOSFromDate || null,
    //   'yyyy-MM-dd'
    // );
    // let txtDOSEndDate = this.datepipe.transform(
    //   this.invoiceSearchForm.value.txtDOSEndDate || null,
    //   'yyyy-MM-dd'
    // );

    // if (
    //   (this.txtDOSFromDate &&
    //     txtDOSEndDate &&
    //     this.txtDOSFromDate <= txtDOSEndDate) ||
    //   (!this.txtDOSFromDate && !txtDOSEndDate)
    // ) {
    //   console.log('If');
    //   console.log(this.txtDOSFromDate, txtDOSEndDate);
    //   this.isLoading = true;

    //   // setTimeout(() => {
    //   //   this.isLoading = false;
    //   // }, 500);

    //   try {
    //     if (this.loadInvoiceTableAPICall) {
    //       this.loadInvoiceTableAPICall.unsubscribe();
    //     }

        // let input: clsInvoiceReportSearch = {
        //   ltPatients: this.drpPatientId.length == 0 ? null : this.drpPatientId,
        //   ltClaims: this.drpInvoiceId.length == 0 ? null : this.drpInvoiceId,
        //   ltStatus: this.drpStatusId.length == 0 ? null : this.drpStatusId,
        //   dtDOSFrom: this.txtDOSFromDate,
        //   dtDOSTo: txtDOSEndDate
        // };
        this.loadInboundData=true;
        let input: clsInvoiceReportSearch = {
            ltPatients: null,
            ltClaims:  null,
            ltStatus: null,
            dtDOSFrom: '',
            dtDOSTo: ''
          };
        this.loadInvoiceTableAPICall = this.invoiceReportService
          .getInvoiceReportDetailsBySearch(input)
          .subscribe(
            (response) => {
              this.inboundTableData = (response?.items.slice(0, 50) )  || [];
              this.invoiceCount=this.inboundTableData.length;
              this.menus.find(x=>x.status===this.activeTabBin).count=this.invoiceCount;
              this.inboundTableData = this.inboundTableData.map((invoice) => {
                return {
                  ...invoice,
                  dtDOB: this.commonService.getFormattedDateZone(invoice.dtDOB),
                  dtDOS: this.commonService.getFormattedDateZone(invoice.dtDOS),
                  creationTime: this.commonService.getFormattedDateTimeZone(
                    invoice.creationTime
                  ),
                  modifiedTime: this.commonService.getFormattedDateTimeZone(
                    invoice.modifiedTime
                  ),
                };
              });
             this.loadInboundData=false;
            },
            (err) => {
              this.inboundTableData = [];
              this.invoiceCount=this.inboundTableData.length;
              this.loadInboundData=false;
              const data: HttpErrorResponse = err;
              // console.error('Unexpected error:', data?.error?.error?.message);
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
  }
  getPaymentsReport(
    paymentMethod: any,
    createdFromDate: any,
    createdToDate: any,
    referenceNumber: any,
    remittanceFromDate: any,
    remittanceToDate: any
  ) {

    this.loadInboundData=true;
    const paymentReport = this.billingReportService
      .getPaymentsBillingReportByPaymentMethodStatusAndDtCreatedFromDateAndDtCreatedToDateAndSReferenceNumberAndDtRemittanceFromDateAndDtRemittanceToDate(
        paymentMethod,
        createdFromDate,
        createdToDate,
        referenceNumber,
        remittanceFromDate,
        remittanceToDate
      )
      .subscribe(
        (response) => {
          this.ltPaymentsReport = (response?.slice(0,50)) ?? [];
          this.menus.find(x=>x.status===this.activeTabBin).count=this.ltPaymentsReport.length;
          this.ltPaymentsReport = this.ltPaymentsReport.map((data) => {
            return {
              ...data,
              createdDate: this.commonService.getFormattedDateTimeZone(
                data.createdDate
              ),
              remittanceDate: this.commonService.getFormattedDateZone(
                data.remittanceDate
              ),
              modifiedDate: this.commonService.getFormattedDateTimeZone(
                data.modifiedDate
              ),
            };
          });
          this.loadInboundData=false;
        },
        (err) => {
          this.loadInboundData=false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });

        }
      );
  }
  getEOBERAReport(
    remittanceFrom,
    remittanceTo,
    createdFrom,
    createdTo
  ) {
    let params: clsEOBERAReportSearch = {
      ltPayers: [],
      ltStatus: [],
      sCheckNumber: '',
      dAmount: null,
    };
    this.loadInboundData = true;
    const report = this.eobService
      .getEOBERAReportsBySearchAndDtRemitanceFromAndDtRemitanceToAndDtCreatedFromAndDtCreatedTo(
        params,
        remittanceFrom,
        remittanceTo,
        createdFrom,
        createdTo
      )
      .subscribe(
        (response) => {
          this.eobData = (response?.items.slice(0,50)) ?? [];
          this.menus.find(x=>x.status===this.activeTabBin).count=this.eobData.length;
          this.eobData = this.eobData.map((data) => {
            return {
              ...data,
              dtCreated: this.commonService.getFormattedDateTimeZone(
                data.dtCreated
              ),
              dtRemitance: this.commonService.getFormattedDateZone(
                data.dtRemitance
              ),
              dtModified: this.commonService.getFormattedDateTimeZone(
                data.dtModified
              ),
            };
          });
          this.loadInboundData = false;
        },
        (err) => {
          this.loadInboundData = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });

        }
      );
  }

  menus= [
    {
      status: nikoBillingMenuStatus.INVOICES,
      color: '#faa307',
      count: 0,
      arrowclass:'unprocessed-down',
      // inboundStatusIds: this..unprocessedFax
    },
    {
      status: nikoBillingMenuStatus.PAYMENTS,
      color: '#b56576',
      count: 0,
      arrowclass:'verification-down',
      // inboundStatusIds: this.inboundStatusIds.verification
    },
    {
      status: nikoBillingMenuStatus.EOB_ERA,
      color: '#c77dff',
      count: 0,
      arrowclass:'precert-down',
      // inboundStatusIds: this.inboundStatusIds.preCert
    },
  ];
}
