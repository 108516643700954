import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MmOrderItemSettingsService } from '../order-proxy/order-management/order-optimization/mm-order-item-settings.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {
  CreateUpdateMmOrderItemSettingsDTO,
  machineSettingsDTO,
} from '../order-proxy/order-management/order-optimization/dto';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';

@Component({
  selector: 'app-machine-settings-modal',
  templateUrl: './machine-settings-modal.component.html',
  styleUrls: ['./machine-settings-modal.component.scss'],
})
export class MachineSettingsModalComponent implements OnInit {
  machineSettingsForm: FormGroup;
  machineSettingFields: machineSettingsDTO;
  machineSetting: CreateUpdateMmOrderItemSettingsDTO = null;
  isFormLoaded: boolean = true;
  subscription$: Subscription[] = [];
  isSettingDiabled: boolean = false;
  constructor(
    private fb: FormBuilder,
    private orderItemSettings: MmOrderItemSettingsService,
    private toaster: ToastrService,
    public dialog: MatDialogRef<MachineSettingsModalComponent>,
    public settingDialogRef: MatDialogRef<MachineSettingsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      productId: string;
      machineSetting: CreateUpdateMmOrderItemSettingsDTO;
      categoryName: string;
      isSettingsView: boolean;
    } = {
      productId: defaultGuid,
      machineSetting: null,
      categoryName: '',
      isSettingsView:false
    }
  ) {}

  ngOnInit() {
    this.getMachineSettings(this.data.productId);
    this.initializeForms();
    this.machineSetting = this.data?.machineSetting ?? null;
    this.setMachineSettings();
    this.isSettingDiabled=this.data?.isSettingsView
  }
  initializeForms() {
    this.machineSettingsForm = this.fb.group({
      txtMinPressure: new FormControl(''),
      txtMaxPressure: new FormControl(''),
      txtSettingTime: new FormControl(''),
      txtIPAP: new FormControl(''),
      txtEPAP: new FormControl(''),
      txtRampPressure: new FormControl(''),
      txtRampTime: new FormControl(''),
      txtBiFlex: new FormControl(''),
      txtSetPressure: new FormControl(''),
      txtCFlex: new FormControl(''),
      txtRRBPM: new FormControl(''),
      txtIPAPMin: new FormControl(''),
      txtIPAPMax: new FormControl(''),
      txtEEP: new FormControl(''),
      txtSetVT: new FormControl(''),
      txtRate: new FormControl(''),
    });
  }
  //To return the entered values to the selected item in the order items list
  closeModel() {
    this.dialog.close(this.machineSetting);
  }

  //To get the Machine Settings
  getMachineSettings(productId: string) {
    this.isFormLoaded = false;
    const machineSettings = this.orderItemSettings
      .getMachineSettingsByProductIdByProductId(productId)
      .subscribe(
        (response) => {
          this.machineSettingFields = response ?? null;
          const allValuesFalse = Object.values(this.machineSettingFields).every(value => value === false);
            if (allValuesFalse) {
                this.toaster.warning("Machine Options are not available");
                this.settingDialogRef.close();
            } else {
                console.log('Some values are true');
            }
          this.isFormLoaded = true;
        },
        (err) => {
          this.toaster.error(err);
          this.isFormLoaded = true;
        }
      );
    this.subscription$.push(machineSettings);
  }

  //To set the Machine Settings for the existing values
  setMachineSettings() {
    this.machineSettingsForm.patchValue({
      txtMinPressure: this.machineSetting?.minPressure ?? null,
      txtMaxPressure: this.machineSetting?.maxPressure ?? null,
      txtSettingTime: this.machineSetting?.settlingTime ?? null,
      txtIPAP: this.machineSetting?.ipap ?? null,
      txtEPAP: this.machineSetting?.epap ?? null,
      txtRampPressure: this.machineSetting?.rampPressure ?? null,
      txtRampTime: this.machineSetting?.rampTime ?? null,
      txtBiFlex: this.machineSetting?.biFlex ?? null,
      txtSetPressure: this.machineSetting?.setPressure ?? null,
      txtCFlex: this.machineSetting?.cFlex ?? null,
      txtRRBPM: this.machineSetting?.rrbpm ?? null,
      txtIPAPMin: this.machineSetting?.ipaPmin ?? null,
      txtIPAPMax: this.machineSetting?.ipaPmax ?? null,
      txtEEP: this.machineSetting?.eep ?? null,
      txtSetVT: this.machineSetting?.setVT ?? null,
      txtRate: this.machineSetting?.rate ?? null,
    });
  }

  //KeyPress allow numbers only
  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  //To Save the Machine Settings
  saveMachineSettings() {
    // this.machineSetting = {
    //   tenantId: localStorage.getItem('tenantId') ?? defaultGuid,
    //   orderItemId: defaultGuid,
    //   mmOrderItemId: 0,
    //   minPressure:
    //     this.machineSettingsForm.value?.txtMinPressure != null
    //       ? Number(this.machineSettingsForm.value?.txtMinPressure)
    //       : this.machineSettingsForm.value?.txtMinPressure,
    //   maxPressure:
    //     this.machineSettingsForm.value?.txtMaxPressure != null
    //       ? Number(this.machineSettingsForm.value?.txtMaxPressure)
    //       : this.machineSettingsForm.value?.txtMaxPressure,
    //   settlingTime:
    //     this.machineSettingsForm.value?.txtSettingTime != null
    //       ? Number(this.machineSettingsForm.value?.txtSettingTime)
    //       : this.machineSettingsForm.value?.txtSettingTime,
    //   ipap:
    //     this.machineSettingsForm.value?.txtIPAP != null
    //       ? Number(this.machineSettingsForm.value?.txtIPAP)
    //       : this.machineSettingsForm.value?.txtIPAP,
    //   epap:
    //     this.machineSettingsForm.value?.txtEPAP != null
    //       ? Number(this.machineSettingsForm.value?.txtEPAP)
    //       : this.machineSettingsForm.value?.txtEPAP,
    //   rampPressure:
    //     this.machineSettingsForm.value?.txtRampPressure != null
    //       ? Number(this.machineSettingsForm.value?.txtRampPressure)
    //       : this.machineSettingsForm.value?.txtRampPressure,
    //   rampTime:
    //     this.machineSettingsForm.value?.txtRampTime != null
    //       ? Number(this.machineSettingsForm.value?.txtRampTime)
    //       : this.machineSettingsForm.value?.txtRampTime,
    //   biFlex:
    //     this.machineSettingsForm.value?.txtBiFlex != null
    //       ? Number(this.machineSettingsForm.value?.txtBiFlex)
    //       : this.machineSettingsForm.value?.txtBiFlex,
    //   setPressure:
    //     this.machineSettingsForm.value?.txtSetPressure != null
    //       ? Number(this.machineSettingsForm.value?.txtSetPressure)
    //       : this.machineSettingsForm.value?.txtSetPressure,
    //   cFlex:
    //     this.machineSettingsForm.value?.txtCFlex != null
    //       ? Number(this.machineSettingsForm.value?.txtCFlex)
    //       : this.machineSettingsForm.value?.txtCFlex,
    //   rrbpm:
    //     this.machineSettingsForm.value?.txtRRBPM != null
    //       ? Number(this.machineSettingsForm.value?.txtRRBPM)
    //       : this.machineSettingsForm.value?.txtRRBPM,
    //   ipaPmin:
    //     this.machineSettingsForm.value?.txtIPAPMin != null
    //       ? Number(this.machineSettingsForm.value?.txtIPAPMin)
    //       : this.machineSettingsForm.value?.txtIPAPMin,
    //   ipaPmax:
    //     this.machineSettingsForm.value?.txtIPAPMax != null
    //       ? Number(this.machineSettingsForm.value?.txtIPAPMax)
    //       : this.machineSettingsForm.value?.txtIPAPMax,
    //   eep:
    //     this.machineSettingsForm.value?.txtEEP != null
    //       ? Number(this.machineSettingsForm.value?.txtEEP)
    //       : this.machineSettingsForm.value?.txtEEP,
    //   setVT:
    //     this.machineSettingsForm.value?.txtSetVT != null
    //       ? Number(this.machineSettingsForm.value?.txtSetVT)
    //       : this.machineSettingsForm.value?.txtSetVT,
    //   rate:
    //     this.machineSettingsForm.value?.txtRate != null
    //       ? Number(this.machineSettingsForm.value?.txtRate)
    //       : this.machineSettingsForm.value?.txtRate,
    //   status: true,
    // };
    this.machineSetting = {
      tenantId: localStorage.getItem('tenantId') ?? defaultGuid,
      orderItemId: defaultGuid,
      mmOrderItemId: 0,
      minPressure:
        this.machineSettingsForm.value?.txtMinPressure == null ||
        this.machineSettingsForm.value?.txtMinPressure == ''
          ? null
          : this.machineSettingsForm.value?.txtMinPressure,
      maxPressure:
        this.machineSettingsForm.value?.txtMaxPressure == null ||
        this.machineSettingsForm.value?.txtMaxPressure == ''
          ? null
          : this.machineSettingsForm.value?.txtMaxPressure,
      settlingTime:
        this.machineSettingsForm.value?.txtSettingTime == null ||
        this.machineSettingsForm.value?.txtSettingTime == ''
          ? null
          : this.machineSettingsForm.value?.txtSettingTime,
      ipap:
        this.machineSettingsForm.value?.txtIPAP == null ||
        this.machineSettingsForm.value?.txtIPAP == ''
          ? null
          : this.machineSettingsForm.value?.txtIPAP,
      epap:
        this.machineSettingsForm.value?.txtEPAP == null ||
        this.machineSettingsForm.value?.txtEPAP == ''
          ? null
          : this.machineSettingsForm.value?.txtEPAP,
      rampPressure:
        this.machineSettingsForm.value?.txtRampPressure == null ||
        this.machineSettingsForm.value?.txtRampPressure == ''
          ? null
          : this.machineSettingsForm.value?.txtRampPressure,
      rampTime:
        this.machineSettingsForm.value?.txtRampTime == null ||
        this.machineSettingsForm.value?.txtRampTime == ''
          ? null
          : this.machineSettingsForm.value?.txtRampTime,
      biFlex:
        this.machineSettingsForm.value?.txtBiFlex == null ||
        this.machineSettingsForm.value?.txtBiFlex == ''
          ? null
          : this.machineSettingsForm.value?.txtBiFlex,
      setPressure:
        this.machineSettingsForm.value?.txtSetPressure == null ||
        this.machineSettingsForm.value?.txtSetPressure == ''
          ? null
          : this.machineSettingsForm.value?.txtSetPressure,
      cFlex:
        this.machineSettingsForm.value?.txtCFlex == null ||
        this.machineSettingsForm.value?.txtCFlex == ''
          ? null
          : this.machineSettingsForm.value?.txtCFlex,
      rrbpm:
        this.machineSettingsForm.value?.txtRRBPM == null ||
        this.machineSettingsForm.value?.txtRRBPM == ''
          ? null
          : this.machineSettingsForm.value?.txtRRBPM,
      ipaPmin:
        this.machineSettingsForm.value?.txtIPAPMin == null ||
        this.machineSettingsForm.value?.txtIPAPMin == ''
          ? null
          : this.machineSettingsForm.value?.txtIPAPMin,
      ipaPmax:
        this.machineSettingsForm.value?.txtIPAPMax == null ||
        this.machineSettingsForm.value?.txtIPAPMax == ''
          ? null
          : this.machineSettingsForm.value?.txtIPAPMax,
      eep:
        this.machineSettingsForm.value?.txtEEP == null ||
        this.machineSettingsForm.value?.txtEEP == ''
          ? null
          : this.machineSettingsForm.value?.txtEEP,
      setVT:
        this.machineSettingsForm.value?.txtSetVT == null ||
        this.machineSettingsForm.value?.txtSetVT == ''
          ? null
          : this.machineSettingsForm.value?.txtSetVT,
      rate:
        this.machineSettingsForm.value?.txtRate == null ||
        this.machineSettingsForm.value?.txtRate == ''
          ? null
          : this.machineSettingsForm.value?.txtRate,
      status: true,
    };
    this.toaster.success('Machine Settings Added');
    this.closeModel();
  }

  //To Reset the Form
  resetForm() {
    this.machineSettingsForm.reset();
  }
}
