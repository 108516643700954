import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ProductNotification } from '../dashboard/product-notification';
import { ReminderService } from '../dashboard/reminder.service';
import { ProductStatus } from '../warehouse-proxy/rcm/warehouse-management/warehouse/product-status.enum';

@Component({
  selector: 'app-notification-expiry',
  templateUrl: './notification-expiry.component.html',
  styleUrls: ['./notification-expiry.component.scss']
})
export class NotificationExpiryComponent implements OnInit {
  reminders: {id?: number, titleMsg?: string, globalExpiryNotification?: { name: string, defaultId: string, msg: string }, timeout?: number}[] = [];
  private _subscription: Subscription;
  organizationUnitId = null;
  constructor(private tableService: TableService, private reminderService: ReminderService) { }
  patientName: string = "";
  reminderSubject: string = "";
  loginUserName: string = "";
  
  //! page initialization function
  ngOnInit(): void {
    this._subscription = this.reminderService.getExpiryObservable().subscribe(reminder => {
      this._addReminder(reminder)
    });
    this.tableService.getLoginUserName().subscribe(val => {
      this.loginUserName = val;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  close() {

  }
  //! Close toastr using class instance
  closeReminder(reminder: {id?: number, titleMsg?: string, globalExpiryNotification?: { name: string, defaultId: string, msg: string }, timeout?: number}) {
    this.reminders = this.reminders?.filter(rem => rem?.id !== reminder?.id);
  }
  //! Add toastr using class instance
  _addReminder(reminder: {idx?: number, titleMsg?: string, globalExpiryNotification?: { name: string, defaultId: string, msg: string }, timeout?: number}) {
    reminder && this.reminders?.push(reminder);
    if (reminder?.timeout !== 0) {
      setTimeout(() => this.closeReminder(reminder), 1000000);
    }
  }
  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
  //! Style Setting toastr
  className(reminder: {idx?: number, titleMsg?: string, globalExpiryNotification?: { name: string, defaultId: string, msg: string }, timeout?: number}): string {
    let style: string;
    let msg="will be Expire Within";
    switch (reminder?.globalExpiryNotification?.msg) {
      case ProductStatus.InStock: style = 'success'; break;
      default: style = 'info'; break;
    }
    return style;
  }

}
