import { TableService } from './../../../../shared/src/app/table.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ArAgingReportRowsDTO, PatientMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { BilliingFilterResultDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { BillingReportColumnName } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/billing-report-column-name.enum';
import { BillingReportRowName } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/billing-report-row-name.enum';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { MisReportsPopupComponent } from '../mis-reports-popup/mis-reports-popup.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { delayWhen } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-ar-aging-report',
  templateUrl: './ar-aging-report.component.html',
  styleUrls: ['./ar-aging-report.component.scss']
})
export class ArAgingReportComponent implements OnInit {
  scheduledDate: Date;
  el: ElementRef;
  source?: any[] = [];
  configs?: { rows?: string; columns?: string };
  txtRow: BillingReportRowName;
  txtColumn: BillingReportColumnName;
  showAGorBillRep: boolean = true;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  public arrDisplayedColumns: string[] = [];
  isLoading: boolean = true;
  drpARAgingReport: ArAgingReportRowsDTO[] = []
  BillingReportRowName: BillingReportRowName;
  BillingReportColumnName: BillingReportColumnName;
  filteredData: BilliingFilterResultDTO;
  SearchForm: FormGroup;
  arrList: any[] = [];
  constructor(
    @Inject(ElementRef) el: ElementRef,
    private fb: FormBuilder,
    private title: Title,
    private tab: TableService,
    private patientDropdownService: PatientMasterDropdownService,
    private reportService: ReportService,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | AR Aging Reports");
    this.arrDisplayedColumns = [ 'SaleOrderNumber', 'invoiceNumber', 'patientDefaultId', 'patientName','BilledAmount', 'AllowedAmount', 'balanceAmount', 'createdDaysOut', 'payorName', 'branchName'];
    this.SearchForm = this.fb.group({
      searchData: new FormControl("")
    });
    const valueChanges: Subscription = this.SearchForm.valueChanges.subscribe(
      value => {
        this.dataSource.filter = JSON.stringify(value.searchData);
        setTimeout(() => {
          this.filterTableData(value.searchData);
        }, 100);
      },
      err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    this.getTableData();
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.PlanTypes,
    ]
    this.patientDropdownService.getPatientDropdownsByInput(patientDropdownInput).subscribe((stateResponse) => {
      const reponse: PatientMasterDropdownDTO = stateResponse;
      this.drpARAgingReport = reponse?.lstArAgingReportRows ?? [];
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  //! get default table data
  getTableData() {
    this.isLoading = true;
    let data: any[] = [];
    this.showAGorBillRep;
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.reportService.getARAginReport().subscribe(response => {
      this.isLoading = true;
      this.arrList = response;
      data = response ?? [];
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      let error: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: error?.error?.error?.message,
      });
    });
  }

  /// common search field
  filterTableData(searchValue: any) {
    let data: any[] = [];
    this.reportService.searchArAgingReportBySInputAndLstArAgingReports(searchValue, this.arrList).subscribe(
      response => {

        data = response ?? [];
        const arrTableData = data;
        this.dataSource = new MatTableDataSource(arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

  }

  /// export from table list for Ar-Aging-Report
  exportTableData() {
    this.reportService.exportArAgingReportsByLstArAgingReports(this.dataSource.data).subscribe(response => {
      if (response && response?.length !== 0) {
        let filelocation: String = String("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + response);
        let link = document.createElement("a");
        link.download = ("Ar-Aging-Report") + ".xlsx";
        link.href = filelocation.toString();
        link.click();
        this.toastr.success('Exported Successfully','Success')
        // Swal.fire({ title: 'Success', html: 'Exported Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
      }
    }, err => {
      const data: HttpErrorResponse = err;
      let errorMesg: string = data?.error;
      if (errorMesg?.includes('Sorry!! There is no data in table to export')) {
        errorMesg = 'Sorry!! There is no data in table to export';
      }
      else {
        errorMesg = data?.error?.error?.message;
      }
      Swal.fire({
        icon: 'info',
        text: errorMesg
      });
    });
  }



  //! get  table data based on row column names
  getTableBasedOnFilter() {
    this.source = [];
    this.configs = { rows: "", columns: "" };
    this.filteredData = null;
    this.reportService.getFilteredArAgingReportsBySArAgingReportRowNameAndSArAgingReportColumnName(this.txtRow, this.txtColumn).subscribe(response => {
      this.isLoading = false;
      const data: BilliingFilterResultDTO = response;
      this.filteredData = data;
      let datas: { columnName?: string, filters?: any[], rowName?: string } | null = { filters: [] };
      datas.columnName = data?.columnName;
      datas.rowName = data?.rowName;
      this.source = data?.filters;
      this.configs = { rows: this.txtRow, columns: this.txtColumn }
    }, err => {
      this.isLoading = false;
      let error: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: error?.error?.error?.message,
      });
    });




    // this.tab.loadDummyPatientData1().subscribe(response => {
    //   this.isLoading = false;

    //   const data: BilliingFilterResultDTO = response;

    //   this.filteredData = data;
    //   this.source = data?.filters?.data;
    //   this.configs = { rows: this.txtRow, columns: this.txtColumn }
    // }, err => {
    //   this.isLoading = false;
    //   let error: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: error?.error?.error?.message,
    //   });
    // });
  }

  openReportsmodal() {
    const dialogRef = this.dialog.open(MisReportsPopupComponent, {
      width: '50%',
      minHeight: '60%',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(() => {
      // this.getReminderTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
}


