import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BillingDenialService } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/billing-reports/billing-denial.service';
import { BillingDenialSearchDTO } from 'projects/billing/src/app/billing-proxy/billing-management/rcm/billing-management/optimization/dto';
import { MyQueueBinStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-denial-bin-queue',
  templateUrl: './denial-bin-queue.component.html',
  styleUrls: ['./denial-bin-queue.component.scss']
})
export class DenialBinQueueComponent implements OnInit {
  loadInboundData: boolean = false;
  inboundTableData:any;
  billingInvoicesService: Subscription;
  deinialCount=0;
  @Output() deineialCount: EventEmitter<number> =
  new EventEmitter<number>();
  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private billingDenialService: BillingDenialService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getTableData();
  }


  getTableData() {
    // this.txtCreatedFromDate = this.datepipe.transform(
    //   this.denialSearchForm.value.txtCreatedFromDate || null,
    //   'yyyy-MM-dd'
    // );
    // let txtCreatedEndDate = this.datepipe.transform(
    //   this.denialSearchForm.value.txtCreatedEndDate || null,
    //   'yyyy-MM-dd'
    // );
    // this.txtDOSFromDate = this.datepipe.transform(
    //   this.denialSearchForm.value.txtDOSFromDate || null,
    //   'yyyy-MM-dd'
    // );
    // let txtDOSEndDate = this.datepipe.transform(
    //   this.denialSearchForm.value.txtDOSEndDate || null,
    //   'yyyy-MM-dd'
    // );

    // if (
    //   ((this.txtCreatedFromDate &&
    //     txtCreatedEndDate &&
    //     this.txtCreatedFromDate <= txtCreatedEndDate) ||
    //     (!this.txtCreatedFromDate && !txtCreatedEndDate)) &&
    //   ((this.txtDOSFromDate && txtDOSEndDate && this.txtDOSFromDate <= txtDOSEndDate) ||
    //     (!this.txtDOSFromDate && !txtDOSEndDate))
    // ) {
    //   this.loadInboundData = true;
    //   try {
    //     if (this.loadDenialsTableAPICall) {
    //       this.loadDenialsTableAPICall.unsubscribe();
    //     }


        // reasonCode?: string;
        // glossaryId: string[];
        // dtFromDate?: string;
        // dtToDate?: string;
        // dtDosFromDate?: string;
        // dtDosToDate?: string;
        // payerName?: string;
        // status?: string;
        // payerIds: string[];


        // let input: BillingDenialSearchDTO = {
        //   glossaryId: this.denialSearchForm.value.drpDenial ? this.denialSearchForm.value.drpDenial : [] ,
        //   dtFromDate: this.txtCreatedFromDate,
        //   dtToDate: txtCreatedEndDate,
        //   dtDosFromDate: this.txtDOSFromDate,
        //   dtDosToDate: txtDOSEndDate,
        //   // status: this.denialSearchForm.value.drpStatus || null,
        //   payerIds: this.denialSearchForm.value.drpPayer ? this.denialSearchForm.value.drpPayer : []
        // };
        this.loadInboundData=true;
         let input: BillingDenialSearchDTO = {
          glossaryId: [] ,
          dtFromDate: '',
          dtToDate: '',
          dtDosFromDate: '',
          dtDosToDate: '',
          // status: this.denialSearchForm.value.drpStatus || null,
          payerIds:  []
        };
        this.billingInvoicesService = this.billingDenialService
          .getDenialReportsByInput(input)
          .subscribe(
            (response) => {
              this.inboundTableData = response;
              this.deinialCount=response.length;
              this.loadInboundData=false;
              this.inboundTableData = this.inboundTableData.map((billing) => {
                return {
                  ...billing,
                  dosDate: this.commonService.getFormattedDateZone(
                    billing.dtDos
                  ),
                  dob: this.commonService.getFormattedDateZone(billing.dob),
                  creationTime: this.commonService.getFormattedDateTimeZone(
                    billing.creationTime
                  ),
                  lastModificationTime: this.commonService.getFormattedDateTimeZone(
                    billing.lastModificationTime
                  ),
                  hcpcCode : this.processString(billing.hcpcCode),
                  status: 'Paid',
                  statusFgColor: '#fff',
                  statusBgColor: '#16aeff',
                };
              });
              // this.tblShpSelectedRow(
              //   this.DataList?.[0]?.rentalId,
              //   this.DataList?.[0]
              // );
              setTimeout(() => {
                this.loadInboundData = false;
              }, 500);
              this.deineialCount.emit(this.deinialCount);
            },
            (err) => {
              this.inboundTableData = [];
              this.deinialCount=0;
              this.deineialCount.emit(this.deinialCount);
              this.loadInboundData=false;
              const data: HttpErrorResponse = err;
              console.error('Unexpected error:', data?.error?.error?.message);
              setTimeout(() => {
                this.loadInboundData = false;
              }, 500);
            }
          );
        }
        txtCreatedFromDate : any;
txtDOSFromDate : any;

processString(input: string): string {
  const parts = input.split('/');

  // If the string was split, remove the first part and return the remaining
  if (parts.length > 1) {
    return parts.slice(1).join(', ').trim();
  }

  // If no split happened, return the input as is
  return input.trim();
}


  menus= [
    {
      status:'Denials List',
      color: '#faa307',
      count: 5,
      arrowclass:'unprocessed-down',
      inboundStatusIds: '001'
    }
  ]
}



