import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe } from '@angular/common';
import {
  NoteType
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-patient-notes-modal',
  templateUrl: './patient-notes-modal.component.html',
  styleUrls: ['./patient-notes-modal.component.scss']
})
export class PatientNotesModalComponent implements OnInit {
  // dtNotesOptionsv1: any = {
  //   responsive: true,
  //   paging: false,
  //   scrollCollapse: true,
  //   scrollY: '300px',
  //   scrollX: true,
  //   info: false
  // };

  dtNotesOptionsv1 = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    // dom: 'lifrtp',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '300px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [0, 1], orderable: false },
      {
        targets: [2],
        type: 'date',
        render: function (data, type, row) {
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Patient Notes',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      }
      // {
      //   extend: 'colvis',
      //   columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))',
      // }
    ],
  };

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  @Input() patientId: string = defaultGuid;
  @Input() noteType: NoteType = NoteType.general;
  @Input() docId: string = defaultGuid;
  @Input() selectedNode: any;
  patientListDataArray: any;
  PatientNotesDetailsV1: any
  @Input() vchartNo: string = '';
  @Input() vDescription: string = '';
  @Input() vPatientVerificationId:string=defaultGuid;
  @Input() vPatientAuthorizationId:string=defaultGuid;
  @Input() vMedicareChecklistIdId:string=defaultGuid;
  @Input() tableData: any;
  @ViewChild('dataTable', { static: false }) dataTable: any;
  $subscription: any;
  selectedRowIndex: number;

  constructor(
    private dialog: MatDialog,
    private datepipe: DatePipe,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      pageType: NoteType;
      isAddVisible: boolean;
      orderId: string;
      docId: string;
      defaultFaxId: string;
      chartNo: string;
      addnotes: boolean;
    } = {
        patientId: defaultGuid,
        orderId: defaultGuid,
        pageType: NoteType.general,
        isAddVisible: true,
        docId: defaultGuid,
        defaultFaxId: '',
        chartNo: 'Kandan',
        addnotes: false
      }

  ) { }

  ngOnInit(): void {
    // var table = $('#tblNotesMdl').DataTable();
    // table.destroy();
    this.PatientNotesDetailsV1 = this.tableData;

    // this.calculateScrollY();
    // window.addEventListener('resize', this.calculateScrollY);

    if ((this.patientId ?? defaultGuid) !== defaultGuid) {
      this.patientId = this.patientId ?? defaultGuid;
    }
    if ((this.data?.patientId ?? defaultGuid) !== defaultGuid) {
      this.patientId = this.data?.patientId ?? defaultGuid;
    }
    if (this.data) {
      this.noteType;
    }

    this.dtNotesOptionsv1.buttons[0].filename =
    'Patient Notes' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }


  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
    setTimeout(() => {
      $('#tblNotesMdl').DataTable().columns.adjust().draw();
    }, 200);
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  // ngAfterViewInit(): void {
  //   // if (this.datatableElement && this.datatableElement.dtInstance) {
  //   //   this.refreshDataTable();
  //   // } else {
  //   //   setTimeout(() => this.refreshDataTable(), 500);
  //   // }
  //   setTimeout(() => {
  //     $('#tblNotesMdl').DataTable().columns.adjust().draw();
  //   }, 200);
  // }

  // refreshDataTable(): void {
  //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     $('input', dtInstance.table(0).footer()).val('');
  //     dtInstance.search('').columns().search('').draw();
  //     dtInstance.columns().every(function () {
  //       const that = this;
  //       $('input', this.footer()).on('keyup change', function () {
  //         if (that.search() !== this['value']) {
  //           that.search(this['value']).draw();
  //         }
  //       });
  //     });
  //   });
  // }


  // ngOnDestroy(): void {
  //   window.removeEventListener('resize', this.calculateScrollY);
  // }

  // calculateScrollY = () => {
  //   const screenHeight =
  //     window.innerHeight ||
  //     document.documentElement.clientHeight ||
  //     document.body.clientHeight;
  //   const availableSpacePercentage = 0.5;
  //   this.dtNotesOptionsv1.scrollY = screenHeight * availableSpacePercentage;
  // };
  UpdateNotes(notesId: string, isCallNote: boolean = false, patientIdNotes: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient',
        notesId: notesId,
        patientId: patientIdNotes,
        defaultFaxId: this.data.defaultFaxId,
        isCallNote: isCallNote,
        isPending: false,
        docId: this.docId,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: this.selectedNode,
        chartNo: this.vchartNo,
        addnotes: false,
        patientVerificationId:this.vPatientVerificationId,
        patientAuthorizationId:this.vPatientAuthorizationId,
        patientMedicareChecklistId:this.vMedicareChecklistIdId,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.communicationService.triggerViewNotesList();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    // this.$subscription.push(closeDialog);
  }

  //Open Description(Notes) Model
  openDescription(description: string) {
    this.vDescription = description;
    document.getElementById('scrolltxt').scrollIntoView({ behavior: "smooth"});
  }
  setSelectedRow(index: number): void {
    this.selectedRowIndex = index;
  }

//US Format Date and Time 
getFormattedDateTimeZone(date: string): string {
  if (date) {
	const formattedDate = new Date(date);
	const options: Intl.DateTimeFormatOptions = {
	  year: 'numeric', month: '2-digit', day: '2-digit',
	  hour: 'numeric', minute: '2-digit',
	  hour12: true,
	  timeZone: 'America/Chicago'
	};
	return formattedDate.toLocaleString('en-US', options);
  } else {
	return '-';
  }
}

  // refreshDataTable(): void {
  //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     // Clear search input values
  //     $('input', dtInstance.table(0).footer()).val('');

  //     // Redraw the DataTable to load all records
  //     dtInstance.search('').columns().search('').draw();

  //     // Attach the event listener for filtering
  //     dtInstance.columns().every(function () {
  //       const that = this;
  //       $('input', this.footer()).on('keyup change', function () {
  //         if (that.search() !== this['value']) {
  //           that.search(this['value']).draw();
  //         }
  //       });
  //     });
  //   });
  // }
}
