<!-- Breadcrumbs -->
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between mb-0">
        <h3 class="font-weight-bolder mb-0">Archive Inbound Documents</h3>
        <div class="page-title-box page-title-right">
          <ul class="breadcrumbs m-0 align-items-center">
            <button
              mat-button
              (click)="backToInboundDoc()"
              class="buttonColor font-size-14 pl-2 mr-2"
            >
              <i class="mdi mdi-plus mr-1"></i>Back To Inbound Documents
            </button>
            <li class="breadcrumb-item">Patient</li>
            <li class="breadcrumb-item">Archive Inbound Documents</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Title -->
<div class="row">
  <div class="col-12">
    <div class="card card-body">
      <form [formGroup]="inboundDocumentStatusForm">
        <div class="row">
          <mat-form-field class="col-3">
            <label>Status</label>
            <mat-select
              formControlName="txtInboundStatus"
              (selectionChange)="onDropdownChange($event)"
            >
              <mat-option
                *ngFor="let value of inboundDocumentStatus | keyvalue"
                [value]="value.value.key"
              >
                {{ value.value.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <div class="col-3">
            <br>
            <mat-checkbox formControlName="chkArchived">Include Archived
            </mat-checkbox>
          </div> -->
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Table -->
<div class="row">
  <div class="col-12 table-responsive" style="margin-top: -25px">
    <div class="card card-body mt-5">
      <table
        table
        mat-table
        matSort
        [dataSource]="dataSource"
        matTableExporter
        #exporter="matTableExporter"
      >
        <form style="display: flex">
          <ng-container matColumnDef="defaultDocumentId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Doc ID</th>
            <td mat-cell *matCellDef="let data">
              {{ data?.defaultDocumentId }}
            </td>
          </ng-container>
          <!--  -->
          <ng-container matColumnDef="documentName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>File Name</th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data?.documentName }}
              </ng-container>
            </td>
          </ng-container>
          <!--  -->
          <ng-container matColumnDef="documentStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              File Status
            </th>
            <td mat-cell *matCellDef="let data">
              {{ data?.documentStatus }}
            </td>
          </ng-container>
          <!--  -->
          <ng-container matColumnDef="uploadedDate">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              Uploaded Date
            </th>
            <td mat-cell *matCellDef="let data">
              {{ data?.uploadedDate | date : "MM/dd/yyyy" : "en_US" }}
            </td>
          </ng-container>
          <!--  -->
          <ng-container matColumnDef="uploadedByName">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              Uploaded By
            </th>
            <td mat-cell *matCellDef="let data">
              {{ data?.uploadedByName }}
            </td>
          </ng-container>
          <!--  -->

          <!--  -->
          <!-- <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Options
            </th>
            <td mat-cell *matCellDef="let data">
              <a class="editBtnColor"  *ngIf="data?.documentName" (click)="unarchiveInboundFileById(data?.id)">
                <i class="fa fa-archive" matTooltip="Click To Unarchive Document" aria-hidden="true"></i>
              </a>
            </td>
          </ng-container> -->
          <!--  -->
          <ng-container matColumnDef="noRecords">
            <td
              class="text-align-center"
              mat-footer-cell
              *matFooterCellDef
              colspan="9"
            >
              <div *ngIf="!isLoading">
                {{ "No Records Found" }}
              </div>
              <div [hidden]="!isLoading">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </td>
          </ng-container>
          <!-- Paginator -->
          <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef colspan="11">
              <mat-paginator
                class="table table-responsive mat-table-class"
                #MatPaginator
                [length]="dataSource?.data?.length"
                [pageSize]="10"
                [pageIndex]="0"
                showFirstLastButtons
                [pageSizeOptions]="[5, 10, 25, 100]"
              >
              </mat-paginator>
            </td>
          </ng-container>
        </form>
        <tr
          mat-footer-row
          *matFooterRowDef="['noRecords']"
          [ngClass]="{
            active:
              dataSource && dataSource?.data && dataSource?.data?.length !== 0
          }"
        ></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
      </table>
    </div>
  </div>
</div>
