import type {
  CreatePatientDocumentBlobDTO,
  CreateUpdateChecklistSortingDTO,
  CreateUpdateMedicareDocDTO,
  DocumentGroupDTO,
  PatientDocumentBlobDTO,
  PatientDocumentBlobNewDTO,
  PatientDocumentDataDTO,
  UpdateDocumentBlobDTO,
  uploadedFileDTO,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { WorkInProgressDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress/dto';

@Injectable({
  providedIn: 'root',
})
export class PatientDocumentService {
  apiName = 'Default';

  deleteByPatientIdAndPatientDocumentId = (
    patientId: string,
    patientDocumentId: string
  ) =>
    this.restService.request<any, PatientDocumentBlobDTO>(
      {
        method: 'DELETE',
        url: '/api/app/patient-document',
        params: { patientId, patientDocumentId },
      },
      { apiName: this.apiName }
    );

  getByIdByGDocId = (gDocId: string) =>
    this.restService.request<any, PatientDocumentBlobDTO>(
      {
        method: 'GET',
        url: `/api/app/patient-document/by-id/${gDocId}`,
      },
      { apiName: this.apiName }
    );

  getByPatientId = (patientId: string) =>
    this.restService.request<any, PatientDocumentBlobDTO[]>(
      {
        method: 'GET',
        url: '/api/app/patient-document',
        params: { patientId },
      },
      { apiName: this.apiName }
    );

  getPatientDocumentByBlobIDByBlobName = (blobName: string) =>
    this.restService.request<any, number[]>(
      {
        method: 'GET',
        url: '/api/app/patient-document/patient-document-by-blob-iD',
        params: { blobName },
      },
      { apiName: this.apiName }
    );

    insertPatientDocument= (
      input: CreatePatientDocumentBlobDTO,
      patientID: string
    ) =>
      this.restService.request<any, PatientDocumentBlobDTO>(
        {
          method: 'POST',
          url: `/api/app/patient-document/insert-patient-document/${patientID}`,
          params: { patientID },
          body: input,
        },
        { apiName: this.apiName }
      );

  postByInputAndPatientID = (
    input: CreatePatientDocumentBlobDTO,
    patientID: string
  ) =>
    this.restService.request<any, PatientDocumentBlobDTO>(
      {
        method: 'POST',
        url: '/api/app/patient-document',
        params: { patientID },
        body: input,
      },
      { apiName: this.apiName }
    );

  putByInputAndDocumentId = (
    input: UpdateDocumentBlobDTO,
    documentId: string
  ) =>
    this.restService.request<any, PatientDocumentBlobDTO>(
      {
        method: 'PUT',
        url: '/api/app/patient-document',
        params: { documentId },
        body: input,
      },
      { apiName: this.apiName }
    );

  getDocumentGroupByPatientId = (patientId: string) =>
    this.restService.request<any, DocumentGroupDTO[]>(
      {
        method: 'GET',
        url: `/api/app/patient-document/get-document-group/${patientId}`,
      },
      { apiName: this.apiName }
    );

  searchPatientDocumentDetailsByPatientIdAndSFileNameAndSDocumentTypeAndDtDocumentStartDateAndDtDocumentEndDate =
    (
      patientId: string,
      sFileName: string,
      sDocumentType: string,
      dtDocumentStartDate: string,
      dtDocumentEndDate: string
    ) =>
      this.restService.request<any, PatientDocumentBlobDTO[]>(
        {
          method: 'POST',
          url: `/api/app/patient-document/search-patient-document-details/${patientId}`,
          params: {
            sFileName,
            sDocumentType,
            dtDocumentStartDate,
            dtDocumentEndDate,
          },
        },
        { apiName: this.apiName }
      );

  checkRoles = (patientId: string, body: CreateUpdateMedicareDocDTO) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: `/api/app/patient-document/validate-medicare-checklist/${patientId}`,
        params: { patientId },
        body: body,
      },
      { apiName: this.apiName }
    );

    getPatientWIPId = (patientId: string) =>
    this.restService.request<any, WorkInProgressDTO[]>(
      {
        method: 'GET',
        url: `/api/app/patient-document/get-patient-wIPId/${patientId}`,
      },
      { apiName: this.apiName }
    );

    checklistwithSortingUpdateByInput = (input: CreateUpdateChecklistSortingDTO) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/patient-document/checklistwith-sorting-update',
      body: input,
    },
    { apiName: this.apiName });

    getpatientdocumentDetailsByGpatientId = (gpatientId: string) =>
      this.restService.request<any, PatientDocumentDataDTO[]>({
        method: 'GET',
        url: `/api/app/patient-document/patientdocument-details/${gpatientId}`,
      },
      { apiName: this.apiName });


    uploadPatientFileByPatientFile = (patientFile: File) => {
      const formData = new FormData();
      formData.append('patientFile', patientFile);   
      return this.restService.request<any, uploadedFileDTO>(
        {
          method: 'POST',
          url: '/api/app/patient-document/upload-patient-file',
          body: formData,
        },
        { apiName: this.apiName }
      );
    };

    insertPatientDocumentByInputAndPatientId = (input: CreatePatientDocumentBlobDTO, patientId: string) =>
      this.restService.request<any, PatientDocumentBlobNewDTO>({
        method: 'POST',
        url: `/api/app/patient-document/insert-patient-document/${patientId}`,
        body: input,
      },
      { apiName: this.apiName });

    deleteManualUploadDocumentByGDocid = (gDocid: string) =>
      this.restService.request<any, void>({
        method: 'DELETE',
        url: '/api/app/patient-document/delete-manual-upload-document',
        params: { gDocid },
      },
      { apiName: this.apiName });
  
  constructor(private restService: RestService) {}
}
