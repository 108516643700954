<div class="mat-typography">
  <!-- Headers -->
  <div class="row" [hidden]="isMultiAuth">
    <div style="font-size: 16px" class="col-12 d-flex justify-content-between">
      <div></div>
      <div class="custom-qsecure-color font-weight-bolder">
        Monitor Medical Inc<br />
        Authorization Forms
      </div>

      <div class="text-right">
        <button mat-icon-button mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <mat-divider class="col-12"></mat-divider>
    <div class="col-12 d-flex justify-content-between custom-qsecure-color">
      <p class="mb-0 py-3 dd-bold-text font-weight-bolder">
        TaxID # {{ patientInfo?.mmTaxId || "NA" }}
      </p>
      <p class="mb-0 py-3 dd-bold-text font-weight-bolder">
        NPI: {{ patientInfo?.npi || "NA" }}
      </p>
      <p class="mb-0 py-3 dd-bold-text font-weight-bolder">TPI: {{ tpi }}</p>
      <p class="mb-0 py-3 dd-bold-text font-weight-bolder">
        PTAN: {{ ptanNumber }}
      </p>
    </div>
    <mat-divider class="col-12"></mat-divider>
    <!-- <div class="col-5 custom-qsecure-color font-weight-bolder">
      {{ chartId }} / {{ patientInfo?.patientName }}
    </div> -->
    <!-- Auth Status -->
    <!-- <div class="col-4 auth-status-header text-danger font-weight-bolder blink">
      {{ authStatus }}
    </div> -->
  </div>

  <!-- Content -->
  <mat-dialog-content class="mat-typography">
    <!-- Patient Details -->
    <div class="col-12">
      <div class="row">
        <div class="col-6 px-0">
          <div class="card mt-1">
            <div class="card-body px-0 py-2">
              <!-- Patient Name-->
              <div class="col-lg-12 d-flex justify-content-between px-2 pb-1">
                <mat-label class="font-weight-bolder dd-bold-text"
                  >Patient Name
                </mat-label>
                <mat-label class="dd-text">{{
                  patientInfo?.patientName == "" ||
                  patientInfo?.patientName == null
                    ? "NA"
                    : patientInfo?.patientName
                }}</mat-label>
              </div>
              <!-- DOB -->
              <div class="col-lg-12 d-flex justify-content-between px-2 pb-1">
                <mat-label class="font-weight-bolder dd-bold-text"
                  >Date of Birth
                </mat-label>

                <mat-label class="dd-text">{{
                  patientInfo?.dateofbirth == "" ||
                  patientInfo?.dateofbirth == null
                    ? "NA"
                    : (patientInfo?.dateofbirth | date : "MM/dd/yyyy" : "en_US")
                }}</mat-label>
              </div>

              <!-- Insurance -->
              <div class="col-lg-12 d-flex justify-content-between px-2 pb-1">
                <mat-label class="font-weight-bolder dd-bold-text"
                  >Insurance
                </mat-label>
                <mat-label class="dd-text">{{
                  patientInfo?.policyName
                }}</mat-label>
              </div>

              <!-- Policy No -->
              <div class="col-lg-12 d-flex justify-content-between px-2 pb-1">
                <mat-label class="font-weight-bolder dd-bold-text"
                  >Policy #
                </mat-label>
                <mat-label class="dd-text">{{
                  patientInfo?.memberId == "" || patientInfo?.memberId == null
                    ? "NA"
                    : patientInfo?.memberId
                }}</mat-label>
              </div>
              <!-- Diagnosis Code 9 -->
              <div class="col-lg-12 d-flex justify-content-between px-2 pb-1">
                <mat-label class="font-weight-bolder dd-bold-text"
                  >Diagnosis Code 9</mat-label
                >
                <mat-label class="dd-text">
                  {{
                    patientInfo?.diagnosis9 == "" ||
                    patientInfo?.diagnosis9 == null
                      ? "NA"
                      : patientInfo?.diagnosis9
                  }}</mat-label
                >
              </div>

              <!-- Diagnosis Code 10 -->
              <div class="col-lg-12 d-flex justify-content-between px-2 pb-1">
                <mat-label class="font-weight-bolder dd-bold-text"
                  >Diagnosis Code 10</mat-label
                >
                <mat-label class="dd-text">
                  {{
                    patientInfo?.diagnosis10 == "" ||
                    patientInfo?.diagnosis10 == null
                      ? "NA"
                      : patientInfo?.diagnosis10
                  }}</mat-label
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 px-0">
          <div class="card mt-1">
            <div class="card-body px-0 py-2">
              <!-- Chart No -->
              <div class="col-lg-12 d-flex justify-content-between px-2 pb-1">
                <mat-label class="dd-bold-text">Chart # </mat-label>
                <mat-label class="dd-text">{{ chartId }}</mat-label>
              </div>
              <!-- Phone -->
              <div class="col-lg-12 d-flex justify-content-between px-2 pb-1">
                <mat-label class="dd-bold-text">Phone </mat-label>
                <mat-label class="dd-text">{{
                  patientInfo?.payorPhone == "" ||
                  patientInfo?.payorPhone == null
                    ? "NA"
                    : patientInfo?.payorPhone
                }}</mat-label>
              </div>
              <!-- Group No-->
              <div class="col-lg-12 d-flex justify-content-between px-2 pb-1">
                <mat-label class="dd-bold-text">Group # </mat-label>
                <mat-label class="dd-text">{{
                  patientInfo?.groupNo == "" || patientInfo?.groupNo == null
                    ? "NA"
                    : patientInfo?.groupNo
                }}</mat-label>
              </div>
              <!-- Doctor NPI -->
              <div class="col-lg-12 d-flex justify-content-between px-2 pb-1">
                <mat-label class="dd-bold-text">Doctor NPI </mat-label>
                <mat-label class="dd-text">{{
                  patientInfo?.doctorNPI == "" || patientInfo?.doctorNPI == null
                    ? "NA"
                    : patientInfo?.doctorNPI
                }}</mat-label>
              </div>
              <!-- Doctor Fax Number -->
              <div class="col-lg-12 d-flex justify-content-between px-2 pb-1">
                <mat-label class="dd-bold-text">Doctor Fax Number</mat-label>
                <mat-label class="dd-text">
                  {{
                    patientInfo?.doctorFax == "" ||
                    patientInfo?.doctorFax == null
                      ? "NA"
                      : patientInfo?.doctorFax
                  }}</mat-label
                >
              </div>
              <!-- Doctor Address -->
              <div class="col-lg-12 d-flex justify-content-between px-2 pb-1">
                <mat-label class="dd-bold-text">Doctor Address</mat-label>
                <mat-label class="dd-text">
                  {{
                    patientInfo?.location == "" ||
                    patientInfo?.location == null ||
                    patientInfo?.location == "-"
                      ? "NA"
                      : patientInfo?.location
                  }}</mat-label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Compliance -->
    <div class="row">
      <div class="col-12">
        <div class="card card-body p-2 mt-1">
          <form [formGroup]="complianceForm">
            <!-- Row 1-->
            <div class="row">
              <div class="col-12">
                <div class="d-flex flex-row align-items-center">
                  <p class="custom-qsecure-color pr-2 mb-0 dd-heading-text">
                    Compliance
                  </p>
                  <mat-radio-group
                    class="pr-2 mt-2 dd-text"
                    formControlName="complianceYes"
                  >
                    <mat-radio-button
                      [value]="true"
                      (click)="isComplianceYes()"
                    >
                      Yes
                    </mat-radio-button>
                    &nbsp;&nbsp;&nbsp;
                    <mat-radio-button [value]="false" (click)="isCompliaceNo()">
                      No
                    </mat-radio-button>
                  </mat-radio-group>

                  <mat-label class="pr-2 dd-bold-text"
                    >If yes,time frame:</mat-label
                  >

                  <mat-form-field class="col-3">
                    <mat-label>Select Days</mat-label>
                    <mat-select formControlName="complianceDays">
                      <mat-option>
                        <ngx-mat-select-search
                          [formControl]="SelectDaysControl"
                          placeholderLabel="Search"
                          noEntriesFoundLabel="No Matches found"
                          formControlName="txtSelectDays"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let datas of dataSelectDays"
                        [value]="datas.daysID"
                      >
                        {{ datas.daysName }}
                      </mat-option>
                    </mat-select>
                    <button
                      mat-button
                      (click)="clearComplianceDays(); $event.stopPropagation()"
                      matSuffix
                      mat-icon-button
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- Row 2 -->
            <div class="row mt-2">
              <div class="col-12">
                <div class="d-flex flex-row align-items-center">
                  <!-- *ngIf="complianceForm.get('complianceYes').value === true" -->
                  <mat-radio-group
                    formControlName="complianceFirstMonth"
                    [disabled]="
                      complianceForm.get('complianceYes').value != true
                    "
                  >
                    <mat-radio-button
                      class="dd-text"
                      [value]="1"
                      (click)="selectCompliance()"
                    >
                      Send compliance report after first month rental
                    </mat-radio-button>
                    &nbsp;&nbsp;&nbsp;
                    <mat-radio-button
                      class="dd-text"
                      [value]="2"
                      (click)="selectCompliance()"
                    >
                      Send compliance report on or before / during third month
                      of rental
                    </mat-radio-button>
                    &nbsp;&nbsp;&nbsp;
                    <mat-radio-button
                      class="dd-text"
                      [value]="3"
                      (click)="selectCompliance()"
                    >
                      Other (Please Indicate)</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- CPT Codes for Machines -->
    <div class="row">
      <div class="col-12">
        <div class="card card-body p-2 mt-1">
          <form [formGroup]="cptCodeForm">
            <div class="row">
              <div class="col-12">
                <div class="d-flex flex-row align-items-center">
                  <p class="custom-qsecure-color dd-heading-text pr-2 mb-0">
                    CPT Codes for Machine
                  </p>
                  <mat-radio-group
                    class="radioGroup pr-2"
                    formControlName="cptcodes"
                  >
                    <div class="m-2" *ngFor="let cpt of lstCptCodes">
                      <mat-radio-button
                        class="mt-2 dd-text"
                        [value]="cpt.id"
                        (click)="cptCodeCheck()"
                      >
                        {{ cpt.code }}
                      </mat-radio-button>
                    </div>
                  </mat-radio-group>

                  <div class="pr-3 mt-2">
                    <mat-checkbox
                      class="dd-text"
                      [disabled]="!isCptRental"
                      formControlName="cptRental"
                      (change)="isRentalTrueCPT($event)"
                      >Rental
                    </mat-checkbox>
                  </div>

                  <mat-form-field class="mt-2">
                    <mat-label>Rental Month</mat-label>
                    <mat-select
                      [disabled]="cptCodeForm.get('cptRental').value != true"
                      formControlName="cptRentalMonth"
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          [formControl]="SelectMonthControl"
                          placeholderLabel="Search"
                          noEntriesFoundLabel="No Matches found"
                          formControlName="txtSelectMonth"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let datas of dataSelectMonth"
                        [value]="datas.monID"
                      >
                        {{ datas.monName }}
                      </mat-option>
                    </mat-select>
                    <button
                      mat-button
                      type="button"
                      (click)="clearCptRental(); $event.stopPropagation()"
                      matSuffix
                      mat-icon-button
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Supplies -->
    <div class="row">
      <div class="col-12">
        <div class="card card-body p-2 mt-1">
          <form [formGroup]="suppliesForm">
            <!-- Row 1-->
            <div class="row">
              <div class="col-12">
                <p class="dd-heading-text custom-qsecure-color">Supplies</p>
              </div>
            </div>
            <!-- Row 2 -->
            <div class="row">
              <div class="col-5 pl-0">
                <mat-checkbox
                  class="col-2 mr-1 dd-text"
                  *ngFor="let supply of lstSupplies"
                  formControlName="suppliesChkbox"
                  [checked]="supply.isTrue"
                  (change)="suppliesChange($event, supply.id)"
                >
                  {{ supply.code }}
                </mat-checkbox>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Oxygen -->
    <div class="row">
      <div class="col-12">
        <div class="card card-body p-2 mt-1">
          <form [formGroup]="oxygenForm">
            <div class="row">
              <div class="col-12">
                <div class="d-flex flex-row align-items-center">
                  <p class="custom-qsecure-color dd-heading-text pr-2 mb-0">
                    Oxygen
                  </p>

                  <mat-radio-group
                    class="radioGroup pr-2"
                    formControlName="oxygencode"
                  >
                    <div class="m-2" *ngFor="let oxy of lstOxygen">
                      <mat-radio-button
                        class="mt-2 dd-text"
                        [value]="oxy.id"
                        (click)="oxygenCodeCheck()"
                      >
                        {{ oxy.code }}
                      </mat-radio-button>
                    </div>
                  </mat-radio-group>

                  <div class="pr-3 mt-2">
                    <mat-checkbox
                      class="dd-text"
                      [disabled]="!isOxygenRental"
                      formControlName="oxygenRental"
                      (change)="isRentalTrueOxygen($event)"
                      >Rental
                    </mat-checkbox>
                  </div>

                  <mat-form-field class="mt-2">
                    <mat-label>Rental Month</mat-label>
                    <mat-select
                      [disabled]="
                        oxygenForm.get('oxygenRental').value != true
                      "
                      formControlName="oxygenrentalMonth"
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          [formControl]="SelectMonthReferralControl"
                          placeholderLabel="Search"
                          noEntriesFoundLabel="No Matches found"
                          formControlName="txtSelectMonthReferral"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let datas of dataSelectMonthReferral"
                        [value]="datas.monID"
                      >
                        {{ datas.monName }}
                      </mat-option>
                    </mat-select>
                    <button
                      mat-button
                      type="button"
                      (click)="clearOxygenrental(); $event.stopPropagation()"
                      matSuffix
                      mat-icon-button
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Diagnosis-->
    <div class="row">
      <div class="col-12">
        <div class="card card-body p-2 mt-1">
          <form [formGroup]="diaginosisForm">
            <!-- Row 1-->
            <div class="row">
              <div class="col-12">
                <p class="dd-heading-text custom-qsecure-color">Diagnosis</p>
              </div>
            </div>

            <!-- Row 2-->
            <div class="row">
              <div class="col-6 pl-0">
                <mat-checkbox
                  class="col-2 dd-text"
                  *ngFor="let diag of lstDiagnosisCode"
                  formControlName="diagnosisChkbox"
                  [checked]="diag.isTrue"
                  (change)="disgnosisChange($event, diag.id)"
                >
                  {{ diag.code }}
                </mat-checkbox>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Auth Details -->
    <div class="card card-body p-2 mt-1">
      <!-- Auth Details Form -->
      <p class="custom-qsecure-color font-weight-bolder pt-2 dd-heading-text">
        Auth Details
      </p>
      <form [formGroup]="authorizationForm">
        <div class="row">
          <!-- Reference -->
          <mat-form-field class="col-lg-2">
            <mat-label> Reference <span class="asterisk">*</span></mat-label>
            <mat-select
              formControlName="drpReference"
              [(value)]="referenceValue"
              (selectionChange)="onReferenceChange()"
            >
              <mat-option [value]="'Call'"> Call </mat-option>
              <mat-option [value]="'Portal'"> Portal </mat-option>
              <mat-option [value]="'Fax'"> Fax </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                authorizationForm?.get('drpReference')?.touched &&
                authorizationForm?.get('drpReference')?.errors?.required
              "
            >
              Reference is a required field!
            </mat-error>
            <button
              mat-button
              type="button"
              (click)="clearRefrence(); $event.stopPropagation()"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <!-- Status -->
          <mat-form-field class="col-lg-2">
            <mat-label> Status <span class="asterisk">*</span> </mat-label>
            <mat-select
              [(value)]="statusValue"
              (selectionChange)="onStatusChange($event)"
              formControlName="drpStatus"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  [formControl]="txtSearchStatus"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let status of ltAuthStatus"
                [value]="status.id"
              >
                {{ status.authStatus }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                authorizationForm?.get('drpStatus')?.touched &&
                authorizationForm?.get('drpStatus')?.errors?.required
              "
            >
              Status is a required field!
            </mat-error>
            <button
              mat-button
              type="button"
              (click)="clearStatus(); $event.stopPropagation()"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <!-- Spoke With /Reference No -->
          <mat-form-field class="col-lg-3" *ngIf="referenceValue === 'Call'">
            <mat-label
              >Spoke With / Reference No<span
                *ngIf="selectedStatus !== 'No Precert'"
                class="asterisk"
                >*</span
              >
            </mat-label>
            <input
              matInput
              formControlName="txtSpokeWith"
              maxlength="40"
              type="text"
              autocomplete="new-txtSpokeWith"
            />
            <mat-error
              *ngIf="
                authorizationForm?.get('txtSpokeWith')?.touched &&
                authorizationForm?.get('txtSpokeWith')?.errors?.required
              "
            >
              Spoke With is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Fax Number -->
          <mat-form-field *ngIf="referenceValue === 'Fax'" class="col-lg-2">
            <mat-label
              >Fax Number
              <span *ngIf="selectedStatus !== 'No Precert'" class="asterisk"
                >*</span
              ></mat-label
            >
            <input
              matInput
              formControlName="txtFaxNumber"
              maxlength="15"
              mask="(000)-000-0000"
              type="text"
              autocomplete="new-txtFaxNumber"
            />
            <mat-error
              *ngIf="
                authorizationForm?.get('txtFaxNumber')?.touched &&
                authorizationForm?.get('txtFaxNumber')?.errors?.required
              "
            >
              Fax Number is a required field!
            </mat-error>
          </mat-form-field>

          <!-- Documents -->
          <mat-form-field *ngIf="referenceValue === 'Fax'" class="col-lg-2">
            <mat-label
              >Documents
              <span *ngIf="selectedStatus !== 'No Precert'" class="asterisk"
                >*</span
              ></mat-label
            >
            <mat-select
              formControlName="drpDocument"
              [(value)]="documentsValue"
              #documentSelect
              multiple
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  [formControl]="txtSearchDocument"
                ></ngx-mat-select-search>
              </mat-option>
              <!--
                 <mat-option
                   #allDocumentSelected
                   (click)="toggleAllDocumentsSelection()"
                   value="0"
                   >All</mat-option
                 > -->
              <mat-option
                *ngFor="let document of lstDocs"
                [value]="document.id"
              >
                <!-- [value]="document?.documentTypeId" -->
                {{ document?.fileNameType }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                authorizationForm?.get('drpDocument')?.touched &&
                authorizationForm?.get('drpDocument')?.errors?.required
              "
            >
              Documents is a required field!
            </mat-error>
            <button
              mat-button
              type="button"
              (click)="clearDocument(); $event.stopPropagation()"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <!-- View Documents -->
          <button
            *ngIf="referenceValue === 'Fax'"
            [disabled]="documentsValue.length === 0 || viewBtnSpinner"
            (click)="openDocumentViewer()"
            class="viewDocButton mt-1 dd-text"
            mat-button
          >
            <!-- class="viewDocButton mt-1"  -->
            <mat-icon *ngIf="!viewBtnSpinner" class="send-icon pt-0"
              >remove_red_eye</mat-icon
            >
            <mat-icon *ngIf="viewBtnSpinner">
              <mat-spinner
                class="spinner-border spinner-border-sm"
                diameter="20"
              >
              </mat-spinner>
            </mat-icon>
            Preview Documents
          </button>
          <div class="col-lg-2 pb-2"></div>
        </div>
        <div class="row">
          <!-- Auth Id -->
          <mat-form-field class="col-lg-2">
            <mat-label>
              Auth ID
              <span
                *ngIf="
                  selectedStatus === 'Approved' ||
                  selectedStatus === 'Pre-Determination Approved'
                "
                class="asterisk"
                >*</span
              >
              <ng-container
                *ngIf="
                  selectedStatus === 'Approved' ||
                  selectedStatus === 'Pre-Determination Approved'
                "
              >
              </ng-container>
            </mat-label>
            <input
              matInput
              formControlName="txtAuthId"
              maxlength="20"
              type="text"
              autocomplete="new-txtAuthId"
            />

            <ng-container
              *ngIf="
                selectedStatus === 'Approved' ||
                selectedStatus === 'Pre-Determination Approved'
              "
            >
              <mat-error
                class="spanAlignment"
                *ngIf="
                  authorizationForm?.get('txtAuthId')?.touched &&
                  authorizationForm?.get('txtAuthId')?.errors?.required
                "
              >
                <!-- Auth ID is a required field! -->
              </mat-error>
            </ng-container>
          </mat-form-field>

          <!--Plan Type-->
          <mat-form-field class="col-lg-3">
            <mat-label> Plan Type </mat-label>
            <mat-select [(value)]="planType" formControlName="txtPlanType">
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="PlanTypeControl"
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  formControlName="txtPlanTypeRef"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let planType of drpPlanTypes"
                [value]="planType.id"
              >
                {{ planType.planType }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                authorizationForm?.get('txtPlanType')?.touched &&
                authorizationForm?.get('txtPlanType')?.errors?.required
              "
            >
              Plan Type is a required field!
            </mat-error>
            <button
              mat-button
              type="button"
              (click)="clearPlantype(); $event.stopPropagation()"
              matSuffix
              mat-icon-button
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <!-- Auth Start Date -->
          <mat-form-field class="col-lg-2">
            <mat-label
              >Start Date
              <span
                *ngIf="
                  selectedStatus === 'Approved' ||
                  selectedStatus === 'Pre-Determination Approved'
                "
                class="asterisk"
                >*</span
              >
              <ng-container
                *ngIf="
                  selectedStatus === 'Approved' ||
                  selectedStatus === 'Pre-Determination Approved'
                "
              >
              </ng-container
            ></mat-label>
            <input
              matInput
              formControlName="txtStartDate"
              placeholder="(MM/DD/YYYY)"
              autocomplete="new-txtStartDate"
              [formControl]="authorizationForm.controls.txtStartDate"
              [matDatepicker]="txtStartDate"
              (keydown)="addSlashToDate($event)"
              (dateInput)="checkEndDate()"
              maxlength="10"
              />
            <mat-datepicker-toggle matSuffix [for]="txtStartDate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtStartDate></mat-datepicker>
            <mat-error
              *ngIf="
                authorizationForm?.get('txtStartDate')?.touched &&
                authorizationForm?.get('txtStartDate')?.errors?.invalidDate
              "
            >
              Enter Valid Date!
            </mat-error>
          </mat-form-field>

          <!-- Auth End Date -->
          <mat-form-field class="col-lg-2">
            <mat-label
              >End Date
              <span
                *ngIf="
                  selectedStatus === 'Approved' ||
                  selectedStatus === 'Pre-Determination Approved'
                "
                class="asterisk"
                >*</span
              >
              <ng-container
                *ngIf="
                  selectedStatus === 'Approved' ||
                  selectedStatus === 'Pre-Determination Approved'
                "
              >
              </ng-container>
            </mat-label>
            <input
              matInput
              formControlName="txtEndDate"
              placeholder="(MM/DD/YYYY)"
              autocomplete="new-txtEndDate"
              (keydown)="addSlashToDate($event)"
              [formControl]="authorizationForm.controls.txtEndDate"
              [min]="getMinimumEndDate()"
              [matDatepicker]="txtEndDate"
              maxlength="10"
              (keyup)="checkMinLength($event.target.value)"
             />
            <mat-datepicker-toggle matSuffix [for]="txtEndDate">
            </mat-datepicker-toggle>
            <mat-datepicker #txtEndDate></mat-datepicker>
            <mat-error
              *ngIf="
                (authorizationForm?.get('txtEndDate')?.touched &&
                  authorizationForm?.get('txtEndDate')?.errors?.invalidDate)
              "
            >
              Enter Valid and greater than  start date!
            </mat-error>
          </mat-form-field>

          <!-- Notes -->
          <mat-form-field class="col-12 pb-2">
            <mat-label>Notes <span class="asterisk">*</span></mat-label>
            <textarea
              rows="4"
              maxlength="5000"
              matInput
              [(ngModel)]="notesText"
              formControlName="txtNotes"
              autocomplete="off"
            ></textarea>

            <mat-error
              *ngIf="
                authorizationForm?.get('txtNotes')?.touched &&
                authorizationForm?.get('txtNotes')?.errors
              "
            >
              Notes is a required field!
            </mat-error>
          </mat-form-field>
          <div class="char-count">{{ notesText?.length }} / 5000</div>
        </div>
      </form>
    </div>
  </mat-dialog-content>

  <br>
  <div class="row">
    <div class="col-5">
      <button mat-button class="buttonColor p-2 mr-2 dd-text" (click)="viewFaxDocument()" [disabled]="isEmpty(docId)">
        <img src="assets/images/NewSetup/GroupV1.png" alt=""class="sea-green-icon-ins mr-1">  View Document
      </button>
    </div>
    <div class="col-7">
      <div class="d-flex flex-row-reverse mb-2">
        <button
          type="button"
          [hidden]="!isView||resethidden"
          mat-button
          (click)="resetForm()"
          class="resetclr p-2 mr-2 dd-text"
        >
          Reset
        </button>
        <button
          type="button"
          [hidden]="!isView||savehide===true"
          mat-button
          (click)="
            saveAuthorization(patientInfo.patientId, patientInfo.wipId)
          "
          [disabled]="authorizationForm.invalid || isShowSpinner"
          class="buttonColor p-2 mr-2 dd-text"
        >
          Save
          <mat-icon *ngIf="isShowSpinner">
            <mat-spinner
              class="spinner-border spinner-border-sm"
              diameter="20"
            >
            </mat-spinner>
          </mat-icon>
        </button>
      </div>
    </div>
  </div>

</div>

<ng-template #documentViewerDialog class="mat-typography">
  <div class="row">
    <h3
      class="col-lg-6 mt-2 custom-qsecure-color font-weight-bolder"
      mat-dialog-title
    >
      Preview Auth Document
    </h3>
    <div class="col-lg-6 text-right mb-2">
      <button
        class="col-lg-6"
        mat-icon-button
        class="close-button"
        (click)="closePreview()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="pdf-container">
      <ng-container>
        <ngx-extended-pdf-viewer
          [base64Src]="finalPdfBase64"
          [height]="pdfHeight"
          [showSidebarButton]="false"
          [showFindButton]="true"
          [textLayer]="true"
          [showPagingButtons]="true"
          [showOpenFileButton]="false"
          [showHandToolButton]="false"
          [showScrollingButton]="false"
          [showSpreadButton]="false"
          [showPropertiesButton]="false"
          [showBookmarkButton]="false"
          [zoom]="'page-width'"
          [enableDragAndDrop]="false"
        >
        </ngx-extended-pdf-viewer>
      </ng-container>
    </div>
  </div>
  <div align="end" mat-dialog-actions>
    <button
      class="faxButtonColor mt-1 mr-1 float-right"
      (click)="sendFax()"
      mat-button
    >
      <mat-icon class="send-icon">send</mat-icon>Send Fax
    </button>
  </div>
</ng-template>

<!-- <button mat-raised-button (click)="samplepdf()">Merge</button> -->
<app-loader-component *ngIf="isDoPreCertLoading"></app-loader-component>
