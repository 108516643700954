import { CoreModule } from '@abp/ng.core';
import { registerLocale } from '@abp/ng.core/locale';
import { AgmCoreModule } from '@agm/core';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatNativeDateModule,
  MatOptionModule,
  MatPseudoCheckboxModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_COLOR_FORMATS,
  NgxMatColorPickerModule,
  NGX_MAT_COLOR_FORMATS,
} from '@angular-material-components/color-picker';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxsModule } from '@ngxs/store';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { OnTimeModule } from 'projects/on-time-schedules/src/app/app.module';
import { APP_ROUTE_PROVIDER } from 'projects/patient/src/app/route.provider';
import {
  environment,
  GoogleAPIKey,
} from 'projects/patient/src/environments/environment';
import { SharedModule } from 'projects/shared/src/app/shared.module';
import { AddDocumentBinComponent } from './add-document-bin/add-document-bin.component';
import { AddDocumentBotComponent } from './add-document-bot/add-document-bot.component';
import { AdminCreateEditComponent } from './admin-create-edit/admin-create-edit.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApprovalBotListComponent } from './approval-bot-list/approval-bot-list.component';
import { ApprovalListComponent } from './approval-list/approval-list.component';
import { BranchComponent } from './branch/branch.component';
import { ClientComponent } from './client/client.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentPerviewerComponent } from './document-perviewer/document-perviewer.component';
import { ExecutivesCreateEditComponent } from './executives/executives-create-edit/executives-create-edit.component';
import { ExecutivesRolesPermissionComponent } from './executives/executives-roles-permission/executives-roles-permission.component';
import { ExecutivesRolesComponent } from './executives/executives-roles/executives-roles.component';
import { ExecutivesUsersComponent } from './executives/executives-users/executives-users.component';
import { ExtractionBotModalComponent } from './extraction-bot-modal/extraction-bot-modal.component';
import { ExtractionModalComponent } from './extraction-modal/extraction-modal.component';
import { ExtractionaddpolciyComponent } from './extractionaddpolciy/extractionaddpolciy.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { IdentityserverLoginComponent } from './identityserver-login/identityserver-login.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { InvoicePaymentViewComponent } from './invoice-payment-view/invoice-payment-view.component';
import { ItemInsuranceViewComponent } from './item-insurance-view/item-insurance-view.component';
import { ItemSuppliesComponent } from './item-supplies/item-supplies.component';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { NotificationTrayComponent } from './notification-tray/notification-tray.component';
import { OfficialComponent } from './official/official.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { PaymentSetupComponent } from './payment-setup/payment-setup.component';
import { PaymentViewComponent } from './payment-view/payment-view.component';
import { PermissionComponent } from './permission/permission.component';
import { RoleComponent } from './role/role.component';
import { RuleMasterComponent } from './rule-master/rule-master.component';
import { SubBatchBotListComponent } from './sub-batch-bot-list/sub-batch-bot-list.component';
import { SubBatchListComponent } from './sub-batch-list/sub-batch-list.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { UploadBinBotComponent } from './upload-bin-bot/upload-bin-bot.component';
import { UploadBinComponent } from './upload-bin/upload-bin.component';
import { UserRegisterComponent } from './user-register/user-register.component';
import { UserComponent } from './user/user.component';
import { SubBatchListTempComponent } from './sub-batch-list-temp/sub-batch-list-temp.component';
import { UploadBinBotcComponent } from './upload-bin-botc/upload-bin-botc.component';
import { AddDocumentBotcComponent } from './add-document-botc/add-document-botc.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DocumentEligibilityHistoryComponent } from './document-eligibility-history/document-eligibility-history.component';
import { VerifyPatientAmountsComponent } from './verify-patient-amounts/verify-patient-amounts.component';
import { AdminAddItemModalComponent } from './admin-add-item-modal/admin-add-item-modal.component';
import { PatientSearchComponent } from './patient-search/patient-search.component';
import { MisReportsComponent } from './mis-reports/mis-reports.component';
import { AngularPivotTableComponent } from './angular-pivot-table/angular-pivot-table.component';
import { ArAgingReportComponent } from './ar-aging-report/ar-aging-report.component';
import { AdminMailConfigurationComponent } from './admin-mail-configuration/admin-mail-configuration.component';
import { MisReportsPopupComponent } from './mis-reports-popup/mis-reports-popup.component';
import { NgxChartComponent } from './ngx-chart/ngx-chart.component';
import { ClaimNotificationComponent } from './claim-notification/claim-notification.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ReportsMailSenderComponent } from './reports-mail-sender/reports-mail-sender.component';
import { ChatSignalrComponent } from './chat-signalr/chat-signalr.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NotificationExpiryComponent } from './notification-expiry/notification-expiry.component';
import { ArchiveBotDocComponent } from './archive-bot-doc/archive-bot-doc.component';
// import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { InboundTabComponent } from './inbound-tab/inbound-tab.component';
import { AddInboundDocumentsTabComponent } from './add-inbound-documents-tab/add-inbound-documents-tab.component';
import { AssignDocumentModalComponent } from './assign-document-modal/assign-document-modal.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PatientSearchTabComponent } from './patient-search-tab/patient-search-tab.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatSortModule } from '@angular/material/sort';
import { BillingReportsTabComponent } from './billing-reports-tab/billing-reports-tab.component';
import {
  DxLoadPanelModule,
  DxBulletModule,
  DxButtonModule,
  DxChartModule,
  DxDataGridModule,
  DxTemplateModule,
} from 'devextreme-angular';
import { ArAgingReportsTabComponent } from './ar-aging-reports-tab/ar-aging-reports-tab.component';
import { ArchivedInboundListComponent } from './archived-inbound-list/archived-inbound-list.component';
import { AdminWipReportComponent } from './admin-wip-report/admin-wip-report.component';
import { MatChipsModule } from '@angular/material/chips';
import { ProcessedDocumentsTabComponent } from './processed-documents-tab/processed-documents-tab.component';
import { AutoSortingComponent } from './auto-sorting/auto-sorting.component';
import { AssignDocumentTabComponent } from './assign-document-tab/assign-document-tab.component';
import { SortingDocumentsTabComponent } from './sorting-documents-tab/sorting-documents-tab.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { MatTreeModule } from '@angular/material/tree';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { PermissionV1Component } from './permission-v1/permission-v1.component';
import { DataTablesModule } from 'angular-datatables';
import { SliderDatePickerComponent } from './slider-date-picker/slider-date-picker.component';
import { DashboardFaxTableComponent } from './dashboard-fax-table/dashboard-fax-table.component';
import { ViewPatientLedgerComponent } from './view-patient-ledger/view-patient-ledger.component';
import { PrimaryInsComponent } from './primary-ins/primary-ins.component';
import { ViewNotesComponent } from './view-notes/view-notes.component';
import { AddPatientNoteComponent } from './add-patient-note/add-patient-note.component';
import { ViewDocumentComponent } from './view-document/view-document.component';
import { DocumentCheckListComponent } from './document-check-list/document-check-list.component';
import { InsuranceCheckListComponent } from './insurance-check-list/insurance-check-list.component';
import { SubmenusampleComponent } from './submenusample/submenusample.component';
import { AddFaxDocumentsComponent } from './add-fax-documents/add-fax-documents.component';
import { SendToAnotherUserComponent } from './send-to-another-user/send-to-another-user.component';
import { JoinFaxComponent } from './join-fax/join-fax.component';
import { SplitDocumentModalComponent } from './split-document-modal/split-document-modal.component';
import { ComplianceReportComponent } from './compliance-report/compliance-report.component';
import { ViewDocumentCheckListComponent } from './view-document-check-list/view-document-check-list.component';
import { StatusComponent } from 'projects/patient/src/app/status/status.component';
import { UserNewComponentComponent } from './UserNewComponent/UserNewComponent.component';
import { UsertabcomponentComponent } from './usertabcomponent/usertabcomponent.component';
import { ExchangeOrderComponent } from './exchange-order/exchange-order.component';
import { DatePipe } from '@angular/common';
import { EmailPrintLetterComponent } from './email-print-letter/email-print-letter.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AddReminderComponent } from './add-reminder/add-reminder.component';
import { ClientTableComponent } from './client-table/client-table.component';
import { CallDetailsTableComponent } from './call-details-table/call-details-table.component';
import { ComplianceReportListComponent } from './compliance-report-list/compliance-report-list.component';
import { UserResetPasswordComponent } from './user-reset-password/user-reset-password.component';
import { FaxFinderComponent } from './admin-dashboard/faxFinder/faxFinder.component';
import { FaxFinderTableComponent } from './admin-dashboard/faxFinder/faxFinderTable/faxFinderTable.component';
import { ComplainceCallDetailsComponent } from './complaince-call-details/complaince-call-details.component';
import { ViewDocumentCheckListTiffViewerComponent } from './view-document-check-list-tiff-viewer/view-document-check-list-tiff-viewer.component';
import { LazyloadingComponent } from './admin-dashboard/lazyloading/lazyloading.component';
import { FaxFinderLazyLoadingComponent } from './admin-dashboard/fax-finder-lazy-loading/fax-finder-lazy-loading.component';
import { FaxFinderLazyLoadingV1Component } from './admin-dashboard/fax-finder-lazy-loading-v1/fax-finder-lazy-loading-v1.component';
import { FaxFinderLazyLoadingTableV1Component } from './admin-dashboard/fax-finder-lazy-loading-v1/fax-finder-lazy-loading-table-v1/fax-finder-lazy-loading-table-v1.component';
import { FaxEmailTableComponent } from './branch/fax-email-table/fax-email-table.component';
import { FtpTableComponent } from './branch/ftp-table/ftp-table.component';
import { RoleTableComponent } from './role/role-table/role-table.component';
import { InviteUserTableComponent } from './invite-user/invite-user-table/invite-user-table.component';
import { WipStatusTableComponent } from 'projects/patient/src/app/status/wip-status-table/wip-status-table.component';
import { UserTableListComponent } from './usertabcomponent/user-tableList/user-tableList.component';
import { PermissionsModalListComponent } from './permissions-modal-list/permissions-modal-list.component';
import { RxReportComponent } from './rx-report/rx-report.component';
import { RxReportTableListComponent } from './rx-report/rx-report-table-list/rx-report-table-list.component';
import { RxReportFilterSwitchComponent } from './rx-report/rx-report-filter-switch/rx-report-filter-switch.component';
import { RxReportSendMailComponent } from './rx-report-send-mail/rx-report-send-mail.component';
import { RuleMasterModalComponent } from './rule-master/rule-master-modal/rule-master-modal.component';
import { RxEmailPrintLetterComponent } from './rx-email-print-letter/rx-email-print-letter.component';
import { ViewLedagerComponent } from './view-patient-ledger/view-ledager/view-ledager.component';
import { ScheduleEmailPrintLetterV1Component } from './schedule-email-print-letter-v1/schedule-email-print-letter-v1.component';
import { PatientStatementComponent } from './patient-statement/patient-statement.component';
import { PatientStatementTableComponent } from './patient-statement/patient-statement-table/patient-statement-table.component';
import { PatientStatementHistoryComponent } from './patient-statement-history/patient-statement-history.component';
import { PatientStatementHistoryTableComponent } from './patient-statement-history/patient-statement-history-table/patient-statement-history-table.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OrdershipppingtableComponent } from './ordershippping/ordershipppingtable/ordershipppingtable.component';
import { OrdershipppingComponent } from './ordershippping/ordershippping.component';
import { TwoFactorAuthenticationComponent } from './two-factor-authentication/two-factor-authentication.component';
import { AppOrderWorksheetMdlComponent } from './app-order-worksheet-mdl/app-order-worksheet-mdl.component';
import { AppWorkSheetTableComponent } from './app-order-worksheet-mdl/app-work-sheet-table/app-work-sheet-table.component';
import { MyQueueComponent } from './my-queue/my-queue.component';
import { MatStepperModule } from '@angular/material/stepper';
import { DocumentBinQueueComponent } from './document-bin-queue/document-bin-queue.component';
import { DocumentBinQueueTableComponent } from './document-bin-queue/document-bin-queue-table/document-bin-queue-table.component';
import { ClaimBinQueueComponent } from './claim-bin-queue/claim-bin-queue.component';
import { DenialBinQueueComponent } from './denial-bin-queue/denial-bin-queue.component';
import { DenialBinQueueTableComponent } from './denial-bin-queue/denial-bin-queue-table/denial-bin-queue-table.component';
import { ClaimBinQueueTableComponent } from './claim-bin-queue/claim-bin-queue-table/claim-bin-queue-table.component';
import { OrderBinQueueComponent } from './order-bin-queue/order-bin-queue.component';
import { InvoiceBinQueueComponent } from './invoice-bin-queue/invoice-bin-queue.component';
import { OrderBinQueTableComponent } from './order-bin-queue/order-bin-que-table/order-bin-que-table.component';
import { InvoicetabBinQueueComponent } from './invoice-bin-queue/invoicetab-bin-queue/invoicetab-bin-queue.component';
import { PaymentsBinQueueComponent } from './invoice-bin-queue/payments-bin-queue/payments-bin-queue.component';
import { Eob_era_bin_queueComponent } from './invoice-bin-queue/eob_era_bin_queue/eob_era_bin_queue.component';

const providers: Provider[] = [
  { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
  { provide: LocationStrategy, useClass: HashLocationStrategy },
  { provide: MatDialogRef, useValue: {} },
  { provide: MAT_DIALOG_DATA, useValue: [] },
  ,
  APP_ROUTE_PROVIDER,
  DatePipe,
];

@NgModule({
  declarations: [
    AppComponent,
    IdentityserverLoginComponent,
    ArAgingReportsTabComponent,
    PatientSearchTabComponent,
    DashboardComponent,
    AddInboundDocumentsTabComponent,
    NgxChartComponent,
    AdminCreateEditComponent,
    ClientComponent,
    OfficialComponent,
    RoleComponent,
    ToggleSwitchComponent,
    InboundTabComponent,
    BranchComponent,
    UserComponent,
    InviteUserComponent,
    PaymentSetupComponent,
    PermissionComponent,
    PaymentViewComponent,
    InvoicePaymentViewComponent,
    ForgotPasswordComponent,
    UpdatePasswordComponent,
    UserRegisterComponent,
    ExecutivesCreateEditComponent,
    ExecutivesRolesComponent,
    ExecutivesRolesPermissionComponent,
    ExecutivesUsersComponent,
    AddDocumentBinComponent,
    ApprovalListComponent,
    ExtractionModalComponent,
    SubBatchListComponent,
    UploadBinComponent,
    PageNotFoundComponent,
    ItemSuppliesComponent,
    DocumentPerviewerComponent,
    ItemInsuranceViewComponent,
    ExtractionaddpolciyComponent,
    RuleMasterComponent,
    StatusComponent,
    PatientListComponent,
    UploadBinBotComponent,
    AddDocumentBotComponent,
    SubBatchBotListComponent,
    ApprovalBotListComponent,
    ExtractionBotModalComponent,
    NotificationTrayComponent,
    NotificationMessageComponent,
    SubBatchListTempComponent,
    UploadBinBotcComponent,
    AddDocumentBotcComponent,
    DocumentEligibilityHistoryComponent,
    VerifyPatientAmountsComponent,
    AdminAddItemModalComponent,
    PatientSearchComponent,
    MisReportsComponent,
    AngularPivotTableComponent,
    ArAgingReportComponent,
    AdminMailConfigurationComponent,
    MisReportsPopupComponent,
    ClaimNotificationComponent,
    ReportsMailSenderComponent,
    ChatSignalrComponent,
    NotificationExpiryComponent,
    ArchiveBotDocComponent,
    AssignDocumentModalComponent,
    BillingReportsTabComponent,
    ArchivedInboundListComponent,
    AdminWipReportComponent,
    ProcessedDocumentsTabComponent,
    AutoSortingComponent,
    AssignDocumentTabComponent,
    SortingDocumentsTabComponent,
    AdminDashboardComponent,
    PermissionV1Component,
    SliderDatePickerComponent,
    DashboardFaxTableComponent,
    ViewPatientLedgerComponent,
    PrimaryInsComponent,
    ViewNotesComponent,
    AddPatientNoteComponent,
    ViewDocumentComponent,
    DocumentCheckListComponent,
    InsuranceCheckListComponent,
    SubmenusampleComponent,
    AddFaxDocumentsComponent,
    SendToAnotherUserComponent,
    JoinFaxComponent,
    SplitDocumentModalComponent,
    ComplianceReportComponent,
    ViewDocumentCheckListComponent,
    UserNewComponentComponent,
    UsertabcomponentComponent,
    ExchangeOrderComponent,
    EmailPrintLetterComponent,
    AddReminderComponent,
    ClientTableComponent,
    CallDetailsTableComponent,
    ComplianceReportListComponent,
    UserResetPasswordComponent,
    FaxFinderComponent,
    FaxFinderTableComponent,
    ComplainceCallDetailsComponent,
    ViewDocumentCheckListTiffViewerComponent,
    LazyloadingComponent,
    FaxFinderLazyLoadingComponent,
    FaxFinderLazyLoadingV1Component,
    FaxFinderLazyLoadingTableV1Component,
    FaxEmailTableComponent,
    FtpTableComponent,
    RoleTableComponent,
    WipStatusTableComponent,
    InviteUserTableComponent,
    UserTableListComponent,
    PermissionsModalListComponent,
    RxReportComponent,
    RxReportTableListComponent,
    RxReportFilterSwitchComponent,
    RxReportSendMailComponent,
    RuleMasterModalComponent,
    RxEmailPrintLetterComponent,
    ViewLedagerComponent,
    ScheduleEmailPrintLetterV1Component,
    PatientStatementComponent,
    PatientStatementTableComponent,
    PatientStatementHistoryComponent,
    PatientStatementHistoryTableComponent,
    OrdershipppingComponent,
    OrdershipppingtableComponent,
      TwoFactorAuthenticationComponent,
      AppOrderWorksheetMdlComponent,
      AppWorkSheetTableComponent,
      MyQueueComponent,
      DocumentBinQueueComponent,
      DocumentBinQueueTableComponent,
      DenialBinQueueTableComponent,
      ClaimBinQueueComponent,
      DenialBinQueueComponent,
      ClaimBinQueueTableComponent,
      OrderBinQueueComponent,
      OrderBinQueTableComponent,
      InvoiceBinQueueComponent,
      InvoicetabBinQueueComponent,
      PaymentsBinQueueComponent,
      Eob_era_bin_queueComponent
   ],
  imports: [
    AngularEditorModule,
    BrowserModule,
    DxChartModule,
    DragDropModule,
    AppRoutingModule,
    DxButtonModule,
    MatChipsModule,
    MatMenuModule,
    MatButtonToggleModule,
    HttpClientModule,
    DxLoadPanelModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    MatListModule,
    MatTableExporterModule,
    MatSlideToggleModule,
    MatSortModule,
    MatBadgeModule,
    MatDividerModule,
    NgApexchartsModule,
    MatToolbarModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    NgxsModule.forRoot(),
    CommonModule,
    FormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatPseudoCheckboxModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    ScrollingModule,
    MatCheckboxModule,
    MatOptionModule,
    MatStepperModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    NgxMatColorPickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    DataTablesModule,
    MatTableModule,
    SharedModule,
    TextMaskModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    NgxMaskModule.forRoot({ showMaskTyped: true }),
    MatTooltipModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgSelectModule,
    NgxExtendedPdfViewerModule,
    NgbModule,
    // MatIconModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    OnTimeModule,
    AgmCoreModule.forRoot({
      apiKey: GoogleAPIKey,
      language: 'en',
      libraries: ['places', 'drawing', 'geometry'],
    }),
    NgxDaterangepickerMd.forRoot(),
    // BackButtonDisableModule.forRoot(),
    MatTreeModule,
    NgbNavModule,
  ],
  providers: providers,
  exports: [
    ClientComponent,
    AngularPivotTableComponent,
    DashboardComponent,
    ToggleSwitchComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
@NgModule({})
export class AdminModule {
  static forRoot(): ModuleWithProviders<AdminModule> {
    return {
      ngModule: AppModule,
      providers: providers,
    };
  }
}
