import { Component, OnInit } from '@angular/core';
import { InvoiceDetailsService } from '../order-proxy/order-management/order-optimization/invoice-details.service';
import { ActivatedRoute } from '@angular/router';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import {
  InvoiceDTO,
  InvoiceViewDTO,
} from '../order-proxy/order-management/order-optimization/dto';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import { Title } from '@angular/platform-browser';
class Invoice {
  customerName: string;
  address: string;
  contactNo: number;
  email: string;

  products: Product[] = [];
  additionalDetails: string;

  constructor() {
    // Initially one empty product row we will show
    this.products?.push(new Product());
  }
}
class Product {
  name: string;
  price: number;
  qty: number;
}
@Component({
  selector: 'app-finance-tab',
  templateUrl: './finance-tab.component.html',
  styleUrls: ['./finance-tab.component.scss'],
})
export class FinanceTabComponent implements OnInit {
  tblInvoices: InvoiceDTO[] = [];
  orderId: string = defaultGuid;
  invoice = new Invoice();
  isLoading: boolean = false;
  constructor(
    private invoiceDetailService: InvoiceDetailsService,
    private activatedRoute: ActivatedRoute,
    public title: Title,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Finance");

    this.activatedRoute.paramMap.subscribe((response) => {
      this.orderId = response.get('orderId') ?? '';
    });
    this.getSaleOrderId();
  }

  getSaleOrderId() {
    this.invoiceDetailService
      .getInvoiceBySaleOrderId(this.orderId)
      .subscribe((response) => {
        
        this.tblInvoices = response;
      });
  }
  generatePDF(value: string, action = 'open') {
    this.invoiceDetailService
      .getInvoiceDetailsByIdByGInvoiceId(value)
      .subscribe(
        (response) => {
          this.generatePDFByResponse(response);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  generatePDFByResponse(response: InvoiceViewDTO, action = 'open') {
    this.invoice = {
      additionalDetails: '',
      address: '',
      contactNo: 0,
      customerName: 'asdasd',
      email: '',
      products: [
        {
          name: '33',
          price: 33,
          qty: 33,
        },
      ],
    };

    let itemDetails = response?.ltItems?.map((x) => {
      let y = {
        dos:
          (x?.dosFrom ?? '') === ''
            ? ''
            : new Date(x?.dosFrom).toLocaleDateString(),
        code: x?.itemProcedureCode,
        desc: x?.description,
        billAmt: x?.billed,
        priInsPaidAmt: x?.insurancePaid,
        priInsAdjAmt: x?.adjustments,
        sectreInsPaidAmt: 0,
        sectreInsAdjAmt: 0,
        patBillAmt: 0,
        patPaidAmt: 0,
        patWoAmt: 0,
        balAmt: x?.currentBalance,
      };
      return y;
    });

    let docDefinition: TDocumentDefinitions = {
      content: [
        {
          text: 'Patient Statement',
          fontSize: 16,
          alignment: 'center',
          color: '#047886',
        },
        {
          columns: [
            [
              {
                text: `Provider Details`,
                alignment: 'left',
                bold: true,
                fontSize: 14,
              },
              {
                text: `Name :${response?.provider?.fullName ?? ''}
                 Address:${response?.provider?.homeAddress ?? ''}
                 Tel: ${response?.provider?.phone ?? ''}
                 Fax:${response?.provider?.fax ?? ''}`,
                fontSize: 12,
                alignment: 'left',
              },
              { text: ` `, alignment: 'left' },
              {
                text: `RETURN SERVICE REQUESTED : `,
                fontSize: 12,
                alignment: 'left',
              },
              { text: ` `, alignment: 'left' },
              {
                text: `Patient Name :${response?.personalDetails?.fullName}`,
                bold: true,
                fontSize: 14,
              },
              {
                text: ` Address:${
                  response?.addressDetails?.address ??
                  '' + response?.addressDetails?.state ??
                  '' + response?.addressDetails?.country ??
                  ''
                }`,
              },
              {
                text: ` Account: #${response?.personalDetails?.accountNumber}`,
              },
              { text: ` Signature:` },
            ],
            [
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },

              {
                table: {
                  widths: [50, 50],
                  body: [
                    ['Payment Type', ' '],
                    [
                      'Cash',
                      { table: { heights: 5, widths: [10], body: [['    ']] } },
                    ],
                    [
                      'Check',
                      { table: { heights: 5, widths: [10], body: [['    ']] } },
                    ],
                    [
                      'VISA',
                      { table: { heights: 5, widths: [10], body: [['    ']] } },
                    ],
                    [
                      'Master Card',
                      { table: { heights: 5, widths: [10], body: [['    ']] } },
                    ],
                  ],
                },
                layout: 'noBorders',
              },
            ],
            [
              {
                text: `Statement Date : ${new Date()?.toLocaleDateString()}`,
                fontSize: 12,
                alignment: 'left',
              },
              {
                table: {
                  body: [
                    [
                      { text: 'Amount Due', bold: true },
                      { text: 'Amount Paid', bold: true },
                    ],
                    [
                      itemDetails?.reduce(
                        (partialSum, a) => partialSum + +a?.balAmt,
                        0
                      ),
                      itemDetails?.reduce(
                        (partialSum, a) => partialSum + +a?.priInsPaidAmt,
                        0
                      ),
                    ],
                  ],
                },
              },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },
              { text: ` `, alignment: 'center' },

              { text: ` `, alignment: 'left' },
              { text: ` Date:__/__/_____`, alignment: 'left' },
              { text: ` CVV Code:__________`, alignment: 'left' },
              { text: ` Expiration Date:__/__/_____`, alignment: 'left' },
            ],
          ],
        },
        {
          text: ' ',
          fontSize: 12,
          alignment: 'center',
        },
        {
          text: ' ',
          fontSize: 12,
          alignment: 'center',
        },
        {
          text: 'PLEASE DETACH AND RETURN TOP PORTION WITH YOUR PAYMENT',
          fontSize: 12,
          alignment: 'center',
        },
        {
          text: '- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -',
          fontSize: 12,
          alignment: 'center',
        },
        {
          text: ' ',
          fontSize: 12,
          alignment: 'center',
        },
        {
          text: ' ',
          fontSize: 12,
          alignment: 'center',
        },
        {
          table: {
            widths: [
              'auto',
              'auto',
              'auto',

              'auto',
              'auto',
              'auto',

              'auto',
              30,
              30,

              30,
              30,
            ],
            body: [
              [
                {
                  text: `DOS
                         Code`,
                  bold: true,
                },
                { text: 'Desc', bold: true },
                { text: 'Bill Amt', bold: true },

                { text: 'Pri Ins PaidAmt', bold: true },
                { text: 'Pri Ins AdjAmt', bold: true },
                { text: 'Sec/Ter Ins PaidAmt', bold: true },

                { text: 'Sec/Ter Ins AdjAmt', bold: true },
                { text: 'Pat BillAmt', bold: true },
                { text: 'Pat PaidAmt', bold: true },

                { text: 'Pat WoAmt', bold: true },
                { text: 'Pat Bal$', bold: true },
              ],

              ...itemDetails?.map((p) => [
                `${p.dos + ' ' + p.code}`,
                p.desc,
                p.billAmt,
                p.priInsPaidAmt,
                p.priInsAdjAmt,
                p.sectreInsPaidAmt,
                p.sectreInsAdjAmt,
                p.patBillAmt,
                p.patPaidAmt,
                p.patWoAmt,
                p.balAmt,
              ]),
            ],
          },
        },

        {
          text: ' ',
          fontSize: 12,
          alignment: 'center',
        },
        {
          columns: [
            [{ text: `Total Outstanding`, alignment: 'left' }],
            [
              {
                text: itemDetails?.map((p) => [
                  `${p?.dos + ' ' + p?.code}`,
                  p?.desc,
                  p?.billAmt?.toString() ?? '',
                  p?.priInsPaidAmt?.toString() ?? '',
                  p?.priInsAdjAmt?.toString() ?? '',
                  p?.sectreInsPaidAmt?.toString() ?? '',
                  p?.sectreInsAdjAmt?.toString() ?? '',
                  p?.patBillAmt?.toString() ?? '',
                  p?.patPaidAmt?.toString() ?? '',
                  p?.patWoAmt?.toString() ?? '',
                  p?.balAmt?.toString() ?? '',
                ]),
                alignment: 'right',
              },
            ],

            [
              {
                text: `${itemDetails?.reduce(
                  (partialSum, a) => partialSum + +a?.balAmt,
                  0
                )}`,
                alignment: 'right',
              },
            ],
          ],
        },
        {
          text: ` `,
          fontSize: 12,
          alignment: 'left',
        },
        // {
        //   table: {
        //     widths: [
        //       60,
        //       50,
        //       50,
        //       50,
        //       50,
        //       50,
        //       50,
        //       60
        //     ],
        //     body: [
        //       [
        //         { text: "UnApplied", bold: true },
        //         { text: "0-30", bold: true },
        //         { text: "31-60", bold: true },
        //         { text: "61-90", bold: true },
        //         { text: "91-120", bold: true },
        //         { text: "120+", bold: true },
        //         { text: "Total", bold: true },
        //         { text: "Net Due $", bold: true },
        //       ],
        //       ...itemDetails?.map(p => [
        //         `${p.dos
        //           +p.code}`, p.desc,
        //         p.billAmt, p.priInsPaidAmt,
        //         p.priInsAdjAmt, p.sectreInsPaidAmt,
        //         p.sectreInsAdjAmt,
        //         p.patBillAmt, p.patPaidAmt,
        //         p.patWoAmt, p.balAmt
        //       ]),
        //     ]
        //   },
        // },
        {
          text: ` `,
          fontSize: 12,
          alignment: 'left',
        },
        {
          text: ` `,
          fontSize: 12,
          alignment: 'left',
        },
        // {
        //   text: `Last Paid Amount: $ ${0.00}`,
        //   fontSize: 12, alignment: 'left',
        // },
        {
          text: ` `,
          fontSize: 12,
          alignment: 'left',
        },
        {
          text: ` `,
          fontSize: 12,
          alignment: 'left',
        },
        {
          text: `"You can also Pay your bill using credit card from the Patient Portal. Late fee will be accessed after 30days."`,
          fontSize: 12,
          alignment: 'left',
        },
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15],
        },
      },
    };

    if (action === 'download') {
      pdfMake?.createPdf(docDefinition)?.download();
    } else if (action === 'print') {
      pdfMake?.createPdf(docDefinition)?.print();
    } else {
      pdfMake?.createPdf(docDefinition)?.open();
    }
  }
}
