<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class=" d-flex align-items-center justify-content-between ">
        <h3 class="mb-0 font-size-18"><b>Upload Documents</b></h3>
        <div class="page-title-box page-title-right  pt-2 pb-0">

          <ol class=" breadcrumb m-0">
            <li>
              <button mat-button *ngIf="isShownCreateButton" (click)="openAddFaxBinModal()"
                class=" buttonColor font-size-14 pl-2 ">
                <i class="mdi mdi-plus mr-1"></i>Upload Documents
              </button>
            </li>
            <li class="ml-2 mt-2 breadcrumb-item">Patient</li>
            <li class="mt-2 breadcrumb-item muted">Upload Documents</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <mat-card-content>
    <!-- <div [hidden]="!isLoading"
      style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
      <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
      </mat-progress-bar>
    </div> -->
    <form [formGroup]="batchUploadBinForm">
      <div class="table table-responsive">
        <table class="mat-elevation-z8" mat-table class="table" [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="documentName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-documentName" matInput formControlName="documentName" type="text" />
                <mat-placeholder class="center">Batch ID</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="alert alert-success p-2" *ngIf="element.isError">
                {{ element.defaultBatchId }}
              </span>
              <span class="alert alert-danger p-2" *ngIf="!element.isError">
                {{ element.defaultBatchId }}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="documentType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-documentType" matInput formControlName="documentType" type="text" />
                <mat-placeholder class="center">Batch Type</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                {{ element.type }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="uploadedFrom">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-uploadedFrom" matInput formControlName="uploadedFrom" type="text" />
                <mat-placeholder class="center"> Uploaded From</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                {{ element.uploadedFrom }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="uploadedDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <mat-label> Uploaded Date </mat-label>
                <input autocomplete="new-uploadedDate" matInput formControlName="uploadedDate" type="text" placeholder="(MM/DD/YYYY)" />
                <mat-error
                  *ngIf=" batchUploadBinForm?.get('uploadedDate')?.touched && batchUploadBinForm?.get('uploadedDate')?.errors?.dateVaidator">
                  Enter Valid date
                </mat-error>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                {{ element.uploadedOn }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="uploadedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input autocomplete="new-uploadedBy" matInput formControlName="uploadedBy" type="text" />
                <mat-placeholder class="center"> Uploaded By</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                {{ element.uploadedBy }}
              </ng-container>
            </td>
          </ng-container>
          <!-- <ng-container matColumnDef="noofFilesUploaded">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input matInput formControlName="noofFilesUploaded" type="text" />
                <mat-placeholder class="center"> No Of Files</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                {{ element.noofFilesUploaded }}
              </ng-container>
            </td>
          </ng-container> -->
          <!-- <ng-container matColumnDef="batchStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input matInput formControlName="batchStatus" type="text" />
                <mat-placeholder class="center"> Batch Status</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container>
                {{element.batchStatus}}
              </ng-container>
            </td>
          </ng-container> -->
          <ng-container matColumnDef="Options">
            <th class="verticalAlignColumn  text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
              Options
            </th>
            <td class="verticalAlignColumn text-center" mat-cell *matCellDef="let element">
              <!-- <button class="btn btn-sm lightSeaGreen-dot mr-3" (click)="archiveBatchById(element.id)" mat-button>
                Archive
              </button> -->

              <!-- <a *ngIf="isShownViewButton" [routerLink]="'/approvalList/'+ element.id">
                <i class="fa fa-eye pointer editBtnColor mr-3" matTooltip="Click To Approve Batch"></i> </a> -->
              <!-- <a *ngIf="isShownViewButton" [routerLink]="'/subBatchList/'+ element.id">
                <i class="fa fa-pencil pointer editBtnColor mr-3" matTooltip="Click To View Batch"></i> </a> -->
              <!-- <a *ngIf="isShownDeleteButton" class="deleteBtnColor" (click)="deleteFileById(element)">
                <i class="fa fa-trash pointer mr-3" matTooltip="Click To Delete Batch" ></i>
              </a> -->
              <a *ngIf="isShownViewButton"    (click)="documnetViewerSideBySide(element?.fileId,element?.id)">
                <i class="fa fa-pencil pointer editBtnColor mr-3" matTooltip="Click To View"></i> </a>

                <!-- <a *ngIf="isShownViewButton"    (click)="documnetViewerSideBySide(null,element?.id)">
                  <i class="fa fa-eye pointer editBtnColor mr-3" matTooltip="Click To View"></i> </a> -->
            </td>
          </ng-container>
          <ng-container matColumnDef="noRecords">
            <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
              <div *ngIf="!isLoading">
                {{"No Records Found"}}
              </div>
              <div *ngIf="isLoading">
                {{"Processing..."}}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="paginator">
            <td mat-footer-cell *matFooterCellDef colspan="10">
              <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                [pageSizeOptions]="[5, 10, 25, 100]">
              </mat-paginator>
            </td>
          </ng-container>
          <tr mat-footer-row *matFooterRowDef="['noRecords']"
            [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length!==0}"></tr>
          <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
        </table>
      </div>
    </form>
  </mat-card-content>
</div>
