import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import Swal from 'sweetalert2';
import { ClaimInformationsService } from '../billing-proxy/billing-management/posting';
import {
  CreateUpdateItemPaymentsDTO,
  ItemPaymentsDTO,
  LstAdjustPayment,
} from '../claim-proxy/claim-processing-management/rcm/billing-management/posting/dto/models';
import { ItemPaymentService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { of, Subscription } from 'rxjs';
import { PostingChargesDTO } from '../billing-proxy/billing-management/posting/dto';
import { DocumentMaxandTypeLimits } from 'projects/shared/src/app/enums/allenums.enum';
import { filter, switchMap } from 'rxjs/operators';
import { ClaimInfoDetailsService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization';
import { SaleOrderItemService } from 'projects/order/src/app/order-proxy/order-management/order-optimization';
import { CustomSaleOrderItemDTO } from '../claim-view-tab/claim-view-tab.component';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-add-item-payment',
  templateUrl: './add-item-payment.component.html',
  styleUrls: ['./add-item-payment.component.scss'],
})
export class AddItemPaymentComponent implements OnInit, OnDestroy {
  itemPaymentTitle: 'Add Item Payment' | 'Edit Item Payment' =
    'Add Item Payment';
  itemPaymentForm: FormGroup;
  strItemPaymentId: string = defaultGuid;
  saveButtonHide: boolean = false;
  drpItems: CustomSaleOrderItemDTO[] = [];
  strClaimId: string = defaultGuid;
  insPaymentId: string = defaultGuid;
  subscription$: Subscription[] = [];
  strDefaultClaimId: string = '';
  strPatientId: string = defaultGuid;
  strDefaultPatientId: string = defaultGuid;
  strPatientName: string = '';
  selFileNameList: { name: string; type: string }[] = [];
  uploadFileReference: any;
  selFileEOBResultValue: string;
  selFile835ResultValue: string;
  selItemValues: ItemPaymentsDTO;
  reasonPaymentId: string = '';

  constructor(
    private fb: FormBuilder,
    private claimInformationService: ClaimInfoDetailsService,
    private saleOrderItemService: SaleOrderItemService,
    private itemPaymentService: ItemPaymentService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      insPaymentId: string;
      itemPaymentId: string;
      claimId: string;
      reasonPaymentId?: string;
    },
    private matDialogRef: MatDialog
  ) { }
  ngOnInit(): void {
    this.saveButtonHide = true;
    this.itemPaymentForm = this.fb.group({
      txtCheckNo: new FormControl('', Validators.required),
      txtCheckDate: new FormControl('', Validators.required),
      txtPlanType: new FormControl('', Validators.required),
      drpItem: new FormControl('', Validators.required),
      txtBilled: new FormControl('', Validators.required),
      txtReasonCode: new FormControl(''),
      txtAllowed: new FormControl(''),
      txtPaid: new FormControl(''),
      txtAdjustment: new FormControl(''),
      txtDeductAmount: new FormControl(''),
      txtCoInsurance: new FormControl(''),
      txtCurrentBalalnce: new FormControl(''),
      txtCheckAmount: new FormControl('', Validators.required),
      txtPostingDate: new FormControl('', Validators.required),
    });
    this.strItemPaymentId = ((this.data?.itemPaymentId === '') ? defaultGuid : (this.data?.itemPaymentId ?? defaultGuid));
    this.strClaimId = this.data?.claimId ?? defaultGuid;
    this.insPaymentId = this.data?.insPaymentId ?? defaultGuid;
    this.reasonPaymentId = this.data?.reasonPaymentId ?? defaultGuid;
    this.getDropdowns(this.strClaimId);

    this.itemPaymentForm.valueChanges.pipe(filter(x => {
      return x?.txtDeductAmount || x?.txtCoInsurance || x.txtCheckAmount
    })).subscribe(response => {
      const billedAmount = ((+Number(response?.txtBilled)) ?? 0);
      const coInsurance = ((+Number(response?.txtCoInsurance)) ?? 0);
      const deductAmount = ((+Number(response?.txtDeductAmount)) ?? 0);
      const checkAmount = ((+Number(response?.txtCheckAmount)) ?? 0);
      const currentBalance = ((coInsurance + deductAmount)?.toFixed(2) ?? 0);
      if (checkAmount === 0) {
        this.itemPaymentForm.patchValue({ txtCurrentBalalnce: billedAmount }, { emitEvent: false })
      } else {
        this.itemPaymentForm.patchValue({ txtCurrentBalalnce: currentBalance }, { emitEvent: false })
      }
    })
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  getDropdowns(claimId: string) {
    const getDropdowns: Subscription = this.claimInformationService
      .get(claimId).pipe(
        switchMap(x => {
          this.strPatientId = x?.patientId ?? defaultGuid;
          this.strDefaultPatientId = x?.defaultPatientId ?? "";
          this.strDefaultClaimId = x?.defaultClaimId ?? '';
          return this.saleOrderItemService.getItemsListByOrderIdByOrderId(x.orderId)
        })
      )
      .subscribe(
        (response) => {
          this.drpItems = response ?? [];
          this.strItemPaymentId !== defaultGuid && this.getItemPaymentDetails();
          this.saveButtonHide = false;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );

    this.subscription$?.push(getDropdowns);
  }
  getItemPaymentDetails() {
    const getItemPaymentDetails: Subscription = this.itemPaymentService
      .get(this.strItemPaymentId)
      .subscribe(
        (response) => {
          this.strItemPaymentId = response?.id ?? defaultGuid;
          this.selFileNameList = [
            { name: response?.eobBlobName, type: 'PDF' },
            { name: response?.ediBlobName, type: 'Text' },
          ];
          this.selItemValues = response;
          const selReasonCodePaymentDeatils: LstAdjustPayment =
            response?.lstAdjustPayments?.filter(
              (x) => x?.paymentId === this.reasonPaymentId
            )[0] ?? null;
          this.strPatientId = response?.patientId ?? defaultGuid;
          this.strDefaultPatientId = response?.defaultPatientId ?? "";
          this.strPatientName = response?.patientName ?? "";
          this.itemPaymentForm.patchValue({
            txtCheckNo: response?.checkNo ?? '',
            txtCheckDate: response?.checkDate ?? '',
            txtPlanType: response?.planType ?? '',
            drpItem: response?.itemProcedureCode ?? '',
            txtBilled: response?.billed ?? '',
            txtReasonCode: selReasonCodePaymentDeatils?.reasonCode ?? '',
            txtAllowed: response?.allowed ?? '',
            txtPaid: response?.insurancePaid ?? '',
            txtAdjustment: selReasonCodePaymentDeatils?.adjustment ?? '',
            txtCurrentBalance: response?.currentBalance ?? '',
            txtDeductAmount: response?.deductAmount ?? '',
            txtCoInsurance: response?.coinsurance ?? '',
            txtCurrentBalalnce: response?.currentBalance ?? "",
            txtPostingDate: response?.postingDate,
            txtCheckAmount: response?.checkAmount,
          });
          this.itemPaymentTitle = 'Edit Item Payment';
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$?.push(getItemPaymentDetails);
  }

  saveItemPayment() {
    this.saveButtonHide = true;
    const fValue: any = this.itemPaymentForm?.value ?? null;
    const selReasonCodePaymentDeatils: LstAdjustPayment = this.selItemValues?.lstAdjustPayments?.filter((x) => x?.paymentId === this.reasonPaymentId)[0];
    const updatedReasonCodeDeatils: LstAdjustPayment = {
      paymentId: this.reasonPaymentId === "" ? (defaultGuid) : this.reasonPaymentId,
      reasonCode: fValue?.txtReasonCode,
      adjustment: fValue?.txtAdjustment,
    };
    const indexOfReasonCode = this.selItemValues?.lstAdjustPayments?.indexOf(selReasonCodePaymentDeatils, 0) ?? -1;
    if (indexOfReasonCode !== -1) {
      this.selItemValues.lstAdjustPayments[indexOfReasonCode] = updatedReasonCodeDeatils;
    } else if ((this.selItemValues?.lstAdjustPayments ?? null) !== null) {
      this.selItemValues.lstAdjustPayments = [updatedReasonCodeDeatils, ...(this.selItemValues?.lstAdjustPayments ?? [])];
    } else {
      this.selItemValues = {
        lstAdjustPayments: [updatedReasonCodeDeatils],
        ...this.selItemValues
      };
    }
    const input: CreateUpdateItemPaymentsDTO = {
      patientId: this.strPatientId,
      // defaultPatientId: this.strDefaultPatientId,
      patientName: this.strPatientName,
      // insurancePaymentId: this.insPaymentId,
      itemProcedureCode: fValue?.drpItem,
      billed: fValue?.txtBilled,
      allowed: fValue?.txtAllowed,
      coinsurance: fValue?.txtCoInsurance,
      // writeOff: fValue?.txtAdjustment,
      insurancePaid: fValue?.txtPaid,
      currentBalance: fValue?.txtCurrentBalalnce,
      checkDate: fValue?.txtCheckDate,
      checkNo: fValue?.txtCheckNo,
      claimId: this.strClaimId,
      claimNumber: this.strDefaultClaimId,
      downloadedZipName: this.selItemValues?.downloadedZipName,
      ediBlobName: this.selItemValues?.ediBlobName,
      eobBlobName: this.selItemValues?.eobBlobName,
      planType: fValue?.txtPlanType,
      postingStatus: this.selItemValues?.postingStatus,
      lstAdjustPayments: this.selItemValues?.lstAdjustPayments ?? [],
      // reasonCode: '',
      deductAmount: fValue?.txtDeductAmount,
      ediFileContentAsBase64String: this.selFile835ResultValue,
      eobFileContentAsBase64String: this.selFileEOBResultValue,
      checkAmount: fValue?.txtCheckAmount,
      postingDate: fValue?.txtPostingDate,
      patientResponsibility: 0,
      insRecoopAmount: 0
    };
    if (this.strItemPaymentId !== defaultGuid) {
      this.itemPaymentService.update(this.strItemPaymentId, input).subscribe(
        (response) => {
          this.saveButtonHide = false;
          this.matDialogRef.closeAll();
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Updated Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Updated Successfully','Success')
        },
        (err) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      let updatedReasonCodeDeatils: LstAdjustPayment = null;
      if ((fValue?.txtReasonCode || fValue?.txtAdjustment) || (fValue?.txtReasonCode && fValue?.txtAdjustment)) {
        updatedReasonCodeDeatils = {
          paymentId: defaultGuid,
          reasonCode: fValue?.txtReasonCode,
          adjustment: fValue?.txtAdjustment,
        };
        input.lstAdjustPayments = [updatedReasonCodeDeatils]
      }
      this.itemPaymentService.create(input).subscribe(
        (response) => {
          this.saveButtonHide = false;
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Saved Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Saved Successfully','Success')
          this.matDialogRef.closeAll();
        },
        (err) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }
  clearItem() {
    this.itemPaymentForm.patchValue({ drpItem: '' });
  }

  //! Read From File. and upload to Backend For EDI parsing
  eftImportFormFile(value?: any) {
    try {
      const fileList: FileList = value;
      let file835: File = null;
      let fileEOB: File = null;
      this.selFileNameList = [];
      if (!(fileList?.length === 1 || fileList?.length === 2)) {
        return;
      }
      for (let index = 0; index < fileList?.length; index++) {
        if (
          fileList[index]?.type === '' ||
          fileList[index]?.type === 'text/plain'
        ) {
          file835 = fileList?.item(index);
          this.selFileNameList.push({
            name: fileList[index]?.name,
            type: 'Text',
          });
        }
        if (
          fileList[index]?.type === DocumentMaxandTypeLimits.documentTypeLimit
        ) {
          fileEOB = fileList?.item(index);
          this.selFileNameList.push({
            name: fileList[index]?.name,
            type: 'PDF',
          });
        }
      }
      if (!fileEOB) {
        Swal.fire({ icon: 'info', text: 'Please Select Remits PDF File' });
        this.uploadFileReference.nativeElement.value = null;
        return;
      }
      let file835ResultValue: string = '';
      let fileEOBResultValue: string = '';
      let reader = new FileReader();

      if (!!fileEOB) {
        reader?.readAsDataURL(fileEOB);
        reader.onload = () => {
          fileEOBResultValue = reader?.result?.slice(28)?.toString() ?? '';
          if (fileEOBResultValue && fileEOBResultValue !== '') {
            this.selFileEOBResultValue = fileEOBResultValue ?? '';
          }
          if (!!file835) {
            reader?.readAsDataURL(file835);
            reader.onload = () => {
              file835ResultValue =
                file835.type === ''
                  ? reader?.result?.slice(37)?.toString()
                  : file835.type === 'text/plain'
                    ? reader?.result?.slice(23)?.toString()
                    : '';
              if (file835ResultValue && file835ResultValue !== '') {
                this.selFile835ResultValue = file835ResultValue ?? '';
              }
            };
          }
        };
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
