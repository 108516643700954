import { Component, OnInit, Input, Inject, } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AddReminderComponent } from 'projects/admin/src/app/add-reminder/add-reminder.component';
import { reminderTableData } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { ReminderService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/remainder/reminder.service';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-personal.service';
import { PatientSummaryDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { CommunicationService } from '../../services/communication.service';
import { ToastrService } from "ngx-toastr";
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-patient-reminder',
  templateUrl: './patient-reminder.component.html',
  styleUrls: ['./patient-reminder.component.scss']
})
export class PatientReminderComponent implements OnInit {
  @Input() lblDefaultPatientId: string = '';
  @Input() patientId: string = defaultGuid;
  // @Input() patientId: string = defaultGuid;
  @Input() selectedNode: any;
  // @Input() isdisable: any;
  // dtReminderTableOptions:any = {
  //   responsive: true,
  //   paging: false,
  //   scrollCollapse: true,
  //   scrollY: '300px',
  //   scrollX: true,
  //   info: false
  // };
  selectedOption: any;
  isLoading: boolean;
  $subscription: any;
  remindertbldata: any;
  totalCount: number;
  tableData$: Subscription;
  // lblDefaultPatientId:string;
  // dtReminderTableOptions = dtReminderTableOptionsv1;
  vDescription: any;
  selectedRowIndex: number;
  reminderTableData = reminderTableData;
  constructor(
    private dialog: MatDialog,
    public matDialog: MatDialog,
    private reminderService: ReminderService,
    private patientPersonalService: PatientPersonalService,
    private toastr: ToastrService,
    private communicationService: CommunicationService,
    public title: Title,
    // @Inject(MAT_DIALOG_DATA)
    // public data: {
    //   selectedNode: any;
    // } = {
    //     selectedNode: defaultGuid,
    //   },
  ) {
    this.communicationService.reminderGetFunctionCallPatient$.subscribe((selectedNode) => {
      this.GetReminderFilterList();
    });
  }

  ngOnInit(): void {
    // this.dtReminderTableOptions = {
    //   responsive: true,
    //   pageLength: 10,
    //   lengthMenu: [5, 10, 25, 50, 100],
    //   paging: true,
    //   info:false,
    // };
    this.title.setTitle('Qsecure | Reminder');
    this.GetReminderFilterList();
    this.selectedOption = 0;
    this.patientId = this.patientId === '' ? defaultGuid : this.patientId;
    (this.patientId ?? defaultGuid) !== defaultGuid && this.getPatientSummary();
  }

  isPastDue(date: string): boolean {
    const currentDate = new Date();
    const dueDate = new Date(date);
    return dueDate < currentDate;
  }

  //Add Remainder Model
  OpenAddReminderMdl(status: boolean) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '35vw',
      data: {
        noteType: 'patient',
        remindertitle: 'Add Reminder',
        status: status,
        reminderid: defaultGuid,
        selectedNode: this.selectedNode,
        lblDefaultPatientId: this.lblDefaultPatientId,
        isdisable: false,
      },
    };
    const dialogRef = this.dialog.open(AddReminderComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.GetReminderFilterList();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    // this.$subscription.push(closeDialog);
  }

  //Update Remainder Model
  // UpdateReminder(reminderid: string, status: boolean) {

  //   const config: MatDialogConfig = {
  //     disableClose: true,
  //     minHeight: '30vh',
  //     minWidth: '35vw',
  //     data: {
  //       noteType: 'patient',
  //       reminderid: reminderid,
  //       remindertitle: 'Update Reminder',
  //       status: status,
  //       selectedNode: this.selectedNode,
  //       lblDefaultPatientId: this.lblDefaultPatientId,
  //       isdisable: false,
  //     },
  //   };

  //   const dialogRef = this.dialog.open(AddReminderComponent, config);

  //   const closeDialog = dialogRef.afterClosed().subscribe(
  //     () => {
  //       this.GetReminderFilterList();
  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.$subscription.push(closeDialog);
  // }

  //GET Reminder List In Table
  GetReminderFilterList() {

    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const getRemindertbleData = this.reminderService
      .getPatientRemainderList(this.selectedOption,this.patientId)
      .subscribe(
        (response) => {
          this.remindertbldata = response;
          this.totalCount = this.remindertbldata.length;
          // this.vDescription = response['description'] ?? '';
          if (response && response.length > 0) {
            this.vDescription = response[0].description ?? 'N/A';
          } else {
            this.vDescription = '';
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.remindertbldata = [];
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.tableData$ = getRemindertbleData;
  }

  //Open Description(Notes) Model
  // openDescription(description: string) {
  //   this.vDescription = description;
  // }
  // setSelectedRow(index: number): void {
  //   this.selectedRowIndex = index;
  // }

  //To get Patient Summary
  getPatientSummary() {
    const patientSummaryDto: PatientSummaryDTO = {
      patientId: this.patientId,
      patientName: '',
      orderId: defaultGuid,
      defaultOrderId: '',
      defaultPatientId: '',
      chartId: '',
      currentStatus: '',
      dob: '',
      billedAmount: '0',
      receivedAmount: '0',
      pendingAmount: '0',
      billingStatus: '0',
      thirtyDayBilledAmnt: '0',
      sixtyDayBilledAmnt: '0',
      ninetyDayBilledAmnt: '0',
      aboveNinetyDayBilledAmnt: '0',
      gender: "",
      adjustmentAmount: ''
    };
    const result = this.patientPersonalService
      .patientSummaryByPatientSummaryDto(patientSummaryDto)
      .subscribe(
        (response) => {
          this.lblDefaultPatientId = response?.patientId ?? "";
        },
        (err) => {
          this.isLoading = false;
          this.remindertbldata = [];
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  ///Delete Reminder
  // DeleteReminder(id: string) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: 'You won\'t be able to retrieve this data!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then(result => {
  //     if (result.value) {
  //       this.reminderService.enableDisableReminderById(id).subscribe(response => {

  //         this.toastr.success('Deleted Successfully', 'Success');

  //         this.GetReminderFilterList();
  //       }, err => {
  //         const data: HttpErrorResponse = err;

  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       });
  //     }
  //   });
  // }

  // ///Complete Reminder
  // completeReminder(id: string) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Complete Reminder?',
  //     text: 'You won\'t be able to retrieve this data!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, Completed it!'
  //   }).then(result => {
  //     if (result.value) {
  //       this.reminderService.updateReminderStatus(id).subscribe(response => {
  //         this.toastr.success('Reminder Completed Successfully', 'Success');
  //         this.GetReminderFilterList();
  //       }, err => {
  //         const data: HttpErrorResponse = err;

  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       });
  //     }
  //   });
  // }


}
