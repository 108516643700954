import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import Swal from 'sweetalert2';

import { AddDocumentBotComponent } from '../add-document-bot/add-document-bot.component';
import { DocumentUploadService } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload';
import { BatchDocumentUploadsDTO, DocumentUploadsDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/dto';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import { IdentityUserDto } from '../admin-proxy/volo/abp/identity';
import { dateFormatter, subBatchDetails } from '../sub-batch-list/sub-batch-list.component';

@Component({
  selector: 'app-sub-batch-bot-list',
  templateUrl: './sub-batch-bot-list.component.html',
  styleUrls: ['./sub-batch-bot-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
})
export class SubBatchBotListComponent implements OnInit {
  //! Declaration and variable Initialization
  isLoading: boolean = true;
  strPageType: string = 'subBatchList';
  organizationUnitId: string = '';
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  expandedElement: any | null;
  arrUsersList: IdentityUserDto[] = [];
  tenantId: string = "";
  arrDisplayedColumns: string[] = [];
  arrTableData: any[] = [];
  batchId: string = "";
  chkdisableButton: boolean;
  isShownSaveButton: boolean = false;
  isShownCreateButton: boolean = false;
  isShownDeleteButton: boolean = false;
  isShownViewButton: boolean = false;
  subBatchUploadBinForm: FormGroup;
  constructor(
    private title: Title,
    private table: TableService,
    public userService: UserService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public dateValidators: DateValidator,
    private activatedRoute: ActivatedRoute,
    private documentUploadService: DocumentUploadService
  ) { }
  //! Page Initialization Function
  ngOnInit(): void {
    this.arrDisplayedColumns = [ 'Options','batchNo', 'stat', 'documentName', 'assignedBy', 'assignedTo', 'assignedTime', 'uploadedFileStatus',];
    this.title.setTitle('Qsecure | Sub Batch List');
    this.activatedRoute.paramMap.subscribe(response => {
      this.batchId = response.get('id') ?? "";
      this.getUsersList();
    })

    this.subBatchUploadBinForm = this.fb.group({
      batchId: new FormControl(""),
      documentName: new FormControl(""),
      assignedBy: new FormControl(""),
      assignedTo: new FormControl(""),
      assignedTime: new FormControl("", this.dateValidators.dateVaidator),
      uploadedFileStatus: new FormControl(""),
    })

    this.table.getfileUploadUpdate().subscribe(value => {
      if (value == true) {
        this.isShownSaveButton = true;
      }
      else {
        this.isShownSaveButton = false;
      }
    });

    this.table.getfileUploadDelete().subscribe(value => {
      if (value == true) {
        this.isShownDeleteButton = true;
      }
      else {
        this.isShownDeleteButton = false;
      }
    });

    this.table.getfileUploadView().subscribe(value => {
      if (value == true) {
        this.isShownViewButton = true;
      }
      else {
        this.isShownViewButton = false;
      }
    });
  }

  //! Get  Users Name
  private getUserName(value: string) {
    if (value && typeof value === "string" && value?.length >= 36) {
      return this.arrUsersList?.filter(u => u?.id + '/' + u?.userName === value)[0]?.userName ?? "";
    }
    return ""
  }
  //! Get  Users List
  private getUsersList() {
    this.isLoading = true
    this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;
      this.batchId !== "" && this.batchId !== undefined && this.batchId !== null && this.getbatchTableData();
      this.isLoading = false;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  //! Get Sub Batch Inital Table Details
  getbatchTableData() {
    this.arrTableData = [];
    this.dataSource = new MatTableDataSource(this.arrTableData);
    this.documentUploadService.getBatchDocumentByIdByBatchId(this.batchId).subscribe(response => {
      let arrTableData: subBatchDetails[] = [];
      const responseBatchData: BatchDocumentUploadsDTO = response ?? null;
      arrTableData = this.extractSubBatchTableDetails(responseBatchData);
      this.arrTableData = [...arrTableData];
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }



  //! Extract Sub Batch Table Details
  private extractSubBatchTableDetails(batchDataTable: BatchDocumentUploadsDTO): subBatchDetails[] {
    let arrTableData: subBatchDetails[] = [];
    try {
      const batchListData: DocumentUploadsDTO[] = batchDataTable?.listDocumentUploads ?? [];
      if (batchListData && batchListData !== [] && batchListData?.length !== 0 && batchListData !== null) {
        batchListData?.forEach(element => {
          let data: subBatchDetails = {
            defaultBatchId: batchDataTable?.defaultBatchId ?? "",
            fileId: element?.fileId ?? "",
            uploadedFileName: element?.uploadedFileName ?? "",
            isStat: element?.isStat === 1 ? true : false,
            blobName: element?.blobName ?? "",
            uploadedFileStatus: element?.extractionStatus ?? "",
            assignedTo: this.getUserName(element?.assignedTo ?? "") ?? "",
            assignedTime: dateFormatter(element?.assignedTime ?? "") ?? "",
            assignedBy: this.getUserName(element?.assignedBy ?? "") ?? "",
            updatedBy: this.getUserName(element?.updatedBy ?? "") ?? "",
            updatedOn: dateFormatter(element?.updatedOn ?? "") ?? "",
          };
          arrTableData?.push(data);
        });
      }
    } catch (error) {
      arrTableData = [];
    }
    return arrTableData;
  }




  //! Update Single File Details
  update(value: string) {
    const dialogRef = this.dialog.open(AddDocumentBotComponent, {
      data: { batchId: this.batchId, fileId: value },
      minHeight: '40vh',
      minWidth: '60vw',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getbatchTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
}
