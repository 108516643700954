import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Observable, Subscription } from 'rxjs';
import { ProductsDTO } from '../item-proxy/item-management/items/dto';
import { map, startWith } from 'rxjs/operators';
import { ProductHcpcCodeService } from '../item-proxy/item-management/optimization/product-hcpc-code.service';
import { InsuranceCategoryService } from 'projects/patient/src/app/patient-proxy/patient-optimization/insurance-category.service';
import { InsuranceAllowablesService } from '../item-proxy/item-management/optimization/insurance-allowables.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-insurance-allowables-update',
  templateUrl: './insurance-allowables-update.component.html',
  styleUrls: ['./insurance-allowables-update.component.scss']
})
export class InsuranceAllowablesUpdateComponent implements OnInit {

  loadTable:boolean=false;
  insuranceTableData:any=[];
  currentPage : any = 1;
  pageOffset: any = 50;
  totalPages : any = 1;
  insuranceAllowablesId=defaultGuid;
  step: boolean = false;
  defaultGuid=defaultGuid;
  allowablesForm: FormGroup;
  filterInsCategory: any;
  subscription$: Subscription[] = [];
  public filterProducts: Observable<ProductsDTO[]> | undefined;
  drpHcpcCode: any[] = [];
  drpInsCategory: any[] = [];
  tenantId:string;
  saveDisable:boolean=false;
  CategorySelectAllOption = false;
  hcpcSelectAllOption=false;
  categoryList: Subscription;
  hcpcCodeList: Subscription;
  isHcpcdrpLoading:boolean=false;
  isCategorydrpLoading:boolean=false;
  constructor(
    private formBuilder: FormBuilder,
    private hpcpCodeService: ProductHcpcCodeService,
    private insuranceCategoryService: InsuranceCategoryService,
    private insuranceAllowablesService: InsuranceAllowablesService,
    private toastr: ToastrService,
    private communicationService: CommunicationService,
    private commonService: CommonService,
    public title: Title,
  ) {
    const comService1 =
      this.communicationService.insuranceAllowableToggleMethodCall$.subscribe(
        () => {
          this.toggleV2();
        }
      );
    this.subscription$.push(comService1);
    const comService2 =
      this.communicationService.insuranceAllowableGetMethodCallV1$.subscribe(
        (allowableId) => {
          this.getInsAllowablesById(allowableId);
        }
      );
    this.subscription$.push(comService2);
  }

  ngOnInit() {
    this.title.setTitle('Qsecure | Insurance Allowables');
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.initializationForm();
    this.getHCPSDropdowns('',[],false,'');
    this.getCategoryDropdowns('',[])
    this.getListOfUpdatedAllowable();
  }
  initializationForm(){
    this.allowablesForm = this.formBuilder.group({
      txtHcpcCode: new FormControl('', [Validators.required]),
      txtInsCategory: new FormControl('', [Validators.required]),
      txtInsuranceFilter: new FormControl(''),
      drpInsuranceName: new FormControl(''),
      txtAllowables: new FormControl('', [Validators.required]),
      filterHcpcCode: new FormControl(''),
      filterCategory: new FormControl(''),
      txtProductType: new FormControl(''),
    });
  }
  ishcpcDropdown:boolean=false;
  onDropOpenHcpc(isOpen:boolean){
    this.ishcpcDropdown = isOpen;
    if (this.ishcpcDropdown) {
      this.allowablesForm.patchValue({
        filterHcpcCode: ''
      });
      if(this.allowablesForm.value.txtHcpcCode){
      const hcpcCodes = this.allowablesForm.value.txtHcpcCode
        .filter((x: any) => x.hcpcCode)
        .map((x: any) => x.hcpcCode);
      //   const selectedHcpcId = this.getTypeDrpObjectsByIdsList(hcpcCodes);
      //   console.log('selectedHcpcId');
      //   console.log(selectedHcpcId);
        // this.allowablesForm.patchValue({
        //   txtHcpcCode: selectedHcpcId ? [selectedHcpcId] : [],
        // });
        this.getHCPSDropdowns('',hcpcCodes,false,'',true);
      }else{
        this.getHCPSDropdowns('',[],true,'');
      }
    }
  }
  //To get the Dropdowns
  getHCPSDropdowns(sSearch:string,sHcpc:string[],isEdit:boolean,editValue:string,multiSelect?:boolean) {
    if(this.hcpcCodeList){
      this.hcpcCodeList.unsubscribe();
    }
    this.filterProducts=null;
    this.isHcpcdrpLoading=true;
    this.hcpcCodeList = this.hpcpCodeService
      .getAllProductHCPCCodeListV1(sSearch,sHcpc)
      .subscribe(
        (response) => {
          this.drpHcpcCode = [];
          // this.drpHcpcCode = response.filter(
          //   (hcpcCode) => hcpcCode.status === 'Active'
          // );
          this.drpHcpcCode = response;
          this.filterProducts = this.allowablesForm
            ?.get('filterHcpcCode')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpHcpcCode.filter((option) =>
                  option?.hcpcCode
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
            if(isEdit){
              const selectedHcpcId = this.getTypeDrpObjectById(editValue);
              this.allowablesForm.patchValue({
                txtHcpcCode: selectedHcpcId ? [selectedHcpcId] : [],
              });
            }
            if(multiSelect){
          if(this.allowablesForm.value.txtHcpcCode){
            const hcpcCodes = this.allowablesForm.value.txtHcpcCode
            .filter((x: any) => x.hcpcCode)
            .map((x: any) => x.hcpcCode);
            const selectedHcpcId = this.getTypeDrpObjectsByIdsList(hcpcCodes);

                this.allowablesForm.patchValue({
                  txtHcpcCode: selectedHcpcId ? selectedHcpcId : [],
                });
              }
          }

          this.isHcpcdrpLoading=false;


        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.isHcpcdrpLoading=false;
        }
      );
    this.subscription$.push(this.hcpcCodeList);
    }
    isCategDropdown:boolean=false;
    onDropOpenCategory(isOpen:boolean){
      this.isCategDropdown = isOpen;
      if (this.isCategDropdown) {
        this.allowablesForm.patchValue({
          filterCategory: ''
        });
       this.getCategoryDropdowns('',(this.allowablesForm.value.txtInsCategory||[]));
      }
    }
    hcpcDropdown(search:string){
      const hcpcCodes = this.allowablesForm.value.txtHcpcCode
      .filter((x: any) => x.hcpcCode)
      .map((x: any) => x.hcpcCode);
      this.getHCPSDropdowns(search,hcpcCodes,false,'',true);
    }
    getCategoryDropdowns(sSearch:string,gIns:string[]) {
      if(this.categoryList){
        this.categoryList.unsubscribe();
      }
      this.isCategorydrpLoading=true;
      this.filterInsCategory=null;  // reset the filter when dropdown is changed or opened.
      this.categoryList = this.insuranceCategoryService
      .getAllInsuranceCategoryListV2(sSearch,gIns)
      .subscribe(
        (response) => {
          this.drpInsCategory = [];
          this.drpInsCategory = response;
          this.filterInsCategory = this.allowablesForm
            ?.get('filterCategory')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpInsCategory.filter((option) =>
                  option?.insuranceCategory
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
            this.isCategorydrpLoading=false;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.isCategorydrpLoading=false;
        }
      );
    this.subscription$.push(this.categoryList);
  }
  numbersAndfloatOnly(event: any) {
    const allowedChars = '0123456789.';
    const inputChar = event.key;

    if (event.key === '.' && event.currentTarget.value.includes('.')) {
      // Allow only one decimal point
      event.preventDefault();
    } else if (allowedChars.indexOf(inputChar) === -1) {
      // Prevent input of characters other than numbers and dot
      event.preventDefault();
    }
  }
  toggleV2() {
    this.step = false;
  }

  // resetForm() {
  //   this.allowablesForm.patchValue({
  //     txtInsCategory: '',
  //     txtHcpcCode: '',
  //     txtAllowables: '',
  //   });
  //   this.insuranceAllowablesId = '';
  //   this.allowablesForm.markAsUntouched();
  //   this.allowablesForm.updateValueAndValidity();
  //   // this.allowablesForm.reset();
  // }
  resetForm() {
    // this.allowablesForm.patchValue({
    //   txtInsCategory: '',
    //   txtHcpcCode: '',
    //   txtAllowables: '',
    // });
    this.allowablesForm.reset();
    this.insuranceAllowablesId = '';
    this.CategorySelectAllOption = false;
    this.hcpcSelectAllOption=false;
    // Object.keys(this.allowablesForm.controls).forEach(key => {
    //   this.allowablesForm.get(key)?.setErrors(null);
    this.allowablesForm.updateValueAndValidity();

  // });
    // this.allowablesForm.reset();
  }
  getHCPCCodeAndAllowables() {
    const hcpcCode = (this.allowablesForm.value.txtHcpcCode===null || this.allowablesForm.value.txtHcpcCode === undefined || this.allowablesForm.value.txtHcpcCode === '')?
     '' : this.allowablesForm.value.txtHcpcCode
    .filter((x: any) => x.hcpcCode)
    .map((x: any) => x.hcpcCode);
    let insCategory = this.allowablesForm.get('txtInsCategory')?.value ||'';
    if(hcpcCode!==''&&hcpcCode!==undefined && hcpcCode !== null && hcpcCode.length != 0 && insCategory!==''
      &&insCategory!==undefined && insCategory.length!= 0){
    this.insuranceAllowablesService
      .getAllowableForInsCategoryAndHCPCCode(insCategory,hcpcCode,0)
      .subscribe(
        (response) => {
        // if(response.allowableAmount===null || response.allowableAmount === ''){
        //   this.allowablesForm.patchValue({
        //     txtAllowables: '',
        //   });
        // }else{
            this.allowablesForm.patchValue({
            txtAllowables: response.allowableAmount,
          });
        // }


        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
   }
  }
  getListOfUpdatedAllowable() {
  this.loadTable=true;
    this.insuranceAllowablesService
      .getListOfUpdatedAllowable(this.currentPage, this.pageOffset)
      .subscribe(
        (response) => {
           this.insuranceTableData=response?.items;
          //  response.totalCount
           this.totalPages = Math.ceil(response?.totalCount/ this.pageOffset);
           this.insuranceTableData = this.insuranceTableData.map((product) => {
            return {
              ...product,
              createdDate: this.commonService.getFormattedDateTimeZone(
                product.createdDate
              ),
              modifyDate: this.commonService.getFormattedDateTimeZone(
                product.modifyDate
              ),
            };
          });
           this.loadTable=false;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.loadTable=false;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  changePage(pageNo : number){

    if( pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages)
    {
      this.currentPage = pageNo;
      this.getListOfUpdatedAllowable();
    }
  }

  saveAllowables() {
    this.saveDisable=true;
      const hcpcCodeIds = this.allowablesForm.value.txtHcpcCode
      .filter((x: any) => x.id)
      .map((x: any) => x.id);

      const hcpcCodes = this.allowablesForm.value.txtHcpcCode
        .filter((x: any) => x.hcpcCode)
        .map((x: any) => x.hcpcCode);
      let saveDetails = {
        insuranceCategoryId: this.allowablesForm.value.txtInsCategory,
        hcpcCodeId: hcpcCodeIds,
        hcpCcode: hcpcCodes,
        allowable: this.allowablesForm.value.txtAllowables,
        tenantId: this.tenantId,
      };

      this.insuranceAllowablesService
        .productAlowablesForMutipleHcpc(saveDetails)
        .subscribe(
          (response) => {
            if (response == 'Saved Successfully' || response == 'Updated Successfully!') {
              this.toastr.success(response, 'Success');
              this.saveDisable=false;
              this.resetForm();
              this.getListOfUpdatedAllowable();
            } else {
              this.saveDisable=false;
              Swal.fire({
                icon: 'info',
                text: response,
              });
            }
          },
          (err) => {
            const data = err.error;
            if (data.text == 'Saved Successfully' || data.text == 'Updated Successfully!') {
              this.toastr.success(data.text, 'Success');
              this.saveDisable=false;
              this.resetForm();
              this.getListOfUpdatedAllowable();
            } else {
              this.saveDisable=false;
            Swal.fire({
              icon: 'info',
              text: data.error.message,
            });
            }
          }
        );
    }

  //To get the Insurance Allowables Id
  getInsAllowablesById(allowableId) {
    this.step = true;
    this.insuranceAllowablesId = allowableId;
    this.insuranceAllowablesService.get(allowableId).subscribe((response) => {
      this.getHCPSDropdowns('', [response.hcpCcode],true,response.hcpCcode);
      this.getCategoryDropdowns('', [response?.insuranceCategoryId]);

      // const selectedHcpcId = this.getTypeDrpObjectById(response.hcpCcode);

      this.allowablesForm.patchValue({
        txtInsCategory: response?.insuranceCategoryId ? [response.insuranceCategoryId] : [],
        // txtHcpcCode: selectedHcpcId ? [selectedHcpcId] : [],
        txtAllowables: response?.allowable || '',
      });

    });
  }
  getTypeDrpObjectById(hcpCcode: string): any {
    if (this.drpHcpcCode) {
      return this.drpHcpcCode.find((type) => type.hcpcCode === hcpCcode);
    } else {
      return null;
    }
  }
  getTypeDrpObjectsByIdsList(hcpCcodes: string[]): any[] {
    if (this.drpHcpcCode) {
      return this.drpHcpcCode.filter((type) => hcpCcodes.includes(type.hcpcCode));
    } else {
      return [];
    }
  }
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData('text');
    const isNumber = /^\d+$/.test(pastedData); //Should contain only whole numbers

    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  onSelectionChange(event: any) {
    // const selectedOptions = this.allowablesForm.get('txtInsCategory').value;

    // if (selectedOptions.includes('selectAll')) {
    //   this.CategorySelectAllOption = !this.CategorySelectAllOption;

    //   if (this.CategorySelectAllOption) {
    //     this.allowablesForm
    //       .get('txtInsCategory')
    //     .patchValue([...this.drpInsCategory.map((org) => org.id)]);
    //   } else {
    //     this.allowablesForm.get('txtInsCategory').patchValue([]);
    //   }
    // } else {
    //   this.CategorySelectAllOption =
    //     selectedOptions.length === this.drpInsCategory.length;
    //   if (
    //     selectedOptions.length < this.drpInsCategory.length &&
    //     this.CategorySelectAllOption
    //   ) {
    //     this.CategorySelectAllOption = false;
    //     this.allowablesForm
    //       .get('txtInsCategory')
    //       .patchValue(
    //         selectedOptions.filter((option) => option !== 'selectAll')
    //       );
    //   }
    // }
    this.getHCPCCodeAndAllowables();
  }
  onSelectionChangeV1(event: any) {
    // const selectedOptions = this.allowablesForm.get('txtHcpcCode').value;

    // if (selectedOptions.includes('selectAll')) {
    //   this.hcpcSelectAllOption = !this.hcpcSelectAllOption;

    //   if (this.hcpcSelectAllOption) {
    //     this.allowablesForm
    //       .get('txtHcpcCode')
    //     .patchValue([...this.drpHcpcCode.map((org) => org)]);
    //   } else {
    //     this.allowablesForm.get('txtHcpcCode').patchValue([]);
    //   }
    // } else {
    //   this.hcpcSelectAllOption =
    //     selectedOptions.length === this.drpHcpcCode.length;
    //   if (
    //     selectedOptions.length < this.drpHcpcCode.length &&
    //     this.hcpcSelectAllOption
    //   ) {
    //     this.hcpcSelectAllOption = false;
    //     this.allowablesForm
    //       .get('txtHcpcCode')
    //       .patchValue(
    //         selectedOptions.filter((option) => option !== 'selectAll')
    //       );
    //   }
    // }
    this.getHCPCCodeAndAllowables();
  }
  drpDownMessage() {
    if (this.isCategorydrpLoading === false) {
      return 'No Matches found';
    } else {
      return 'Loading...';
    }
  }
  drpDownMessageV1() {
    if (this.isHcpcdrpLoading === false) {
      return 'No Matches found';
    } else {
      return 'Loading...';
    }
  }
  drpDownSearchMessage() {
    if (this.isCategorydrpLoading === false) {
      return 'Search...';
    } else {
      return 'Loading...';
    }
  }
  drpDownSearchMessageV1() {
    if (this.isHcpcdrpLoading === false) {
      return 'Search...';
    } else {
      return 'Loading...';
    }
  }
}
