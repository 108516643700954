<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 d-flex flex-row">
          <ng-container *ngFor="let data of menus">
            <label [ngClass]="getArrowClass(data)" [ngStyle]="{ 'background-color': data.color }"
              (click)="binActiveTab(data)"
              class="pointer customPointingLabel claim-status-label rounded-border down mb-2 py-2 px-2 text-center mr-1"
              matlabel>
              {{ data.status }} ({{ data.count }})
              <!-- {{ data.status }} <span *ngIf="activeTabBin === data?.status">({{this.dataCount}})</span>
              <span *ngIf="activeTabBin !== data?.status">(0)</span> -->
            </label>
          </ng-container>
        </div>
      </div>

      <div class="card card-body">
        <div class="d-flex justify-content-center" *ngIf="loadInboundData">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>

        <div class="table-responsive " style="overflow-x: hidden !important" *ngIf="!loadInboundData">
          <app-invoicetab-bin-queue *ngIf="activeTabBin===nikoBillingMenu.INVOICES" [inBoundTableData]="inboundTableData" [activeTabBin]="activeTabBin"></app-invoicetab-bin-queue>
          <app-payments-bin-queue *ngIf="activeTabBin===nikoBillingMenu.PAYMENTS" [inBoundTableData]="ltPaymentsReport" [activeTabBin]="activeTabBin"></app-payments-bin-queue>
          <app-eob_era_bin_queue *ngIf="activeTabBin===nikoBillingMenu.EOB_ERA" [inBoundTableData]="eobData" [activeTabBin]="activeTabBin"></app-eob_era_bin_queue>

        </div>

        <!-- <h1 *ngIf="activeTabBin === MyQueueBinStatus.Unprocessed">Unprocess</h1>
          <h1 *ngIf="activeTabBin === MyQueueBinStatus.Verification">Verification</h1>
          <h1 *ngIf="activeTabBin === MyQueueBinStatus.Precert">Precert</h1>
          <h1 *ngIf="activeTabBin === MyQueueBinStatus.Checklist">Checklist</h1>
          <h1 *ngIf="activeTabBin === MyQueueBinStatus.Schedule">Schedule</h1>
          <h1 *ngIf="activeTabBin === MyQueueBinStatus.ReadyforOrder">Ready for Order</h1> -->

      </div>
    </div>
  </div>
</div>

