import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderComponent } from 'projects/order/src/app/order/order.component';
import { PatientSearchComponent } from 'projects/patient/src/app/patient-search/patient-search.component';

import { AppComponent } from './app.component';
import { HorizontalComponent } from './horizontal/horizontal.component';


const routes: Routes = [
  // {
  //   path: 'horizontalTopbar',
  //   children: [
  //     {
  //       path: '',
  //       redirectTo: 'horizontalTopbar/:id',
  //       pathMatch: 'full'
  //     },
  //     {
  //       path: 'horizontalTopbar/:id',
  //       component: HorizontoltopbarComponent
  //     }
  //   ]
  // },

  // {
  //   path: 'horizontal',
  //   children: [
  //     {
  //       path: '',
  //       redirectTo: 'horizontal/:id',
  //       pathMatch: 'full'
  //     },
  //     {
  //       path: 'horizontal/:id',
  //       component: HorizontalComponent
  //     }
  //   ]
  // },
  // {
  //     path: '', component: AppComponent,
  //     redirectTo: "patientSearch",
  //     pathMatch: "full",
  // }



  // {
  //   path: '', component: AppComponent,
  //   redirectTo: "",
  //   pathMatch: "full",

  //   children: [
  //     {
  //       path: '',
  //       // pathMatch : 'full',
  //       loadChildren: () => import('../../../patient/src/app/app.module').then(m => m.AppModule),
  //     },

  //     {
  //       path: 'order',
  //       loadChildren: () => import('../../../order/src/app/app.module').then(m => m.AppModule)
  //     },

  //     {
  //       path: 'shared',
  //       loadChildren: () => import('../../../shared/src/app/app.module').then(m => m.AppModule)
  //     },
  //   ]
  // },
];


@NgModule({
  imports: [RouterModule.forChild(
    routes,
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }


