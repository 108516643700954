<div class="col-lg-12">
  <div class="container-fluid">
    <div class="row">
      <div class="col-9">
        <div class=" d-flex align-items-center justify-content-between">
          <h1 class="font-weight-bolder mb-0"><b>Inbox</b></h1>
        </div>
      </div>
      <div class="col-3">
          <div class="row bg">
            <div class="col-12">
              <!-- <form [formGroup]="dateFilterForm">
                <mat-form-field
                  class="mat-calendar col-12 ng-tns-c281-12 ng-trigger ng-trigger-fadeInCalendar">
                  <mat-label>Enter a date range</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                      <input autocomplete="new-txtFromDate" matStartDate formControlName="txtFromDate" placeholder="Start date" #dateRangeStart>
                      <input autocomplete="new-txtToDate" matEndDate formControlName="txtToDate" placeholder="End date" #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>

                </mat-form-field>
              </form> -->

            </div>
          </div>
      </div>

      <!-- theme -->
      <!-- <div class="col-4">
        <form [formGroup]="dashBoardThemeForm">
          <mat-form-field class="col-11 mr-2">
            <mat-label>Theme</mat-label>
            <mat-select maxlength="40" formControlName="txtTheme" (selectionChange)="onChange($event.value)">
              <mat-option *ngFor="let color of colors" [value]="color.value">
                {{color.value}}
              </mat-option>
            </mat-select>
            <button mat-button (click)="theme='';$event.stopPropagation()" *ngIf="theme" matSuffix (click)="clearTheme()"
              mat-icon-button >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </form>
      </div> -->
    </div>
  </div>

</div>


<div class="col-lg-12 mt-lg-3 font-size-12" style="font-family: cursive;">
  <div class="row mb-lg-3 mt-lg-0">
    <div class="col-sm-6 col-lg-3 mb-4 mb-lg-0">
      <a [routerLink]="'/patientSearch'">
        <div class="candidates-feature-info shadow-sm border-10 mt-4">
          <div class="row">
            <div class="col-9">
              <h2 class="customThemeClass"><b>Patient Count</b></h2>
              <hr class="mt-2">
            </div>
            <div class="col-3">
              <img class="img-responsive" src="assets/Dashboard New/Patient Count.png" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="candidates-info-content">
                <mat-card class="text-center"><b>Total - </b><b class="customThemeClass">{{totalPatientCount}}</b>
                </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
            <div class="col-lg-6 mt-3">
              <div class="candidates-info-count">
                <mat-card class="text-center"><b>This Month - </b><b
                    class="customThemeClass">{{currentMonthPatientCount}}</b> </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
    <!--  -->
    <div class="col-sm-6 col-lg-3 mb-4 mb-lg-0">
      <a [routerLink]="'/patient/insuranceVerification'">
        <div class="candidates-feature-info shadow-sm border-10 mt-4">
          <div class="row">
            <div class="col-9">
              <h2 class="customThemeClass"><b>Insurance Verification Count</b></h2>
              <hr class="mt-2">
            </div>
            <div class="col-3">
              <img class="img-responsive" src="assets/Dashboard New/Insurance verification.png" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="candidates-info-content">
                <mat-card class="text-center"><b>Total - </b><b class="customThemeClass">{{totalVerificationCount}}</b>
                </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
            <div class="col-lg-6 mt-3">
              <div class="candidates-info-count">
                <mat-card class="text-center"><b>This Month - </b><b
                    class="customThemeClass">{{currentMonthVerificationCount}}</b> </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
    <!--  -->
    <div class="col-sm-6 col-lg-3 mb-4 mb-lg-0">
      <a [routerLink]="'/uploadBinC'">
        <div class="candidates-feature-info shadow-sm border-10 mt-4">
          <div class="row">
            <div class="col-9">
              <h2 class="customThemeClass"><b>File Upload Count</b></h2>
              <hr class="mt-2">
            </div>
            <div class="col-3">
              <img class="img-responsive" src="assets/Dashboard New/File Upload count.png" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="candidates-info-content">
                <mat-card class="text-center"><b>Total - </b><b class="customThemeClass">{{totalFileUploadCount}}</b>
                </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
            <div class="col-lg-6 mt-3">
              <div class="candidates-info-count">
                <mat-card class="text-center"><b>This Month - </b><b
                    class="customThemeClass">{{currentMonthFileUploadCount}}</b> </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
    <!--  -->

    <!--  -->
    <div class="col-sm-6 col-lg-3 mb-4 mb-lg-0">
      <a [routerLink]="'/patient/orders'">
        <div class="candidates-feature-info shadow-sm border-10 mt-4">
          <div class="row">
            <div class="col-9">
              <h2 class="customThemeClass"><b>Sale Order Count</b></h2>
              <hr class="mt-2">
            </div>
            <div class="col-3">
              <img class="img-responsive" src="assets/Dashboard New/Sale order count.png" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="candidates-info-content">
                <mat-card class="text-center"><b>Total - </b><b class="customThemeClass">{{totalSaleOrderCount}}</b>
                </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
            <div class="col-lg-6 mt-3">
              <div class="candidates-info-count">
                <mat-card class="text-center"><b>This Month - </b><b
                    class="customThemeClass">{{currentMonthSaleOrderCount}}</b> </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
    <!--  -->
    <!--  -->
    <div class="col-sm-6 col-lg-3 mb-4 mb-lg-0">
      <a [routerLink]="'/orders/shippingApprovalList'">
        <div class="candidates-feature-info shadow-sm border-10 mt-4">
          <div class="row">
            <div class="col-9">
              <h2 class="customThemeClass"><b>Shipping Count</b></h2>
              <hr class="mt-2">
            </div>
            <div class="col-3">
              <img class="img-responsive" src="assets/Dashboard New/Shipping Count.png" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="candidates-info-content">
                <mat-card class="text-center"><b>Total - </b><b class="customThemeClass">{{totalShippingCount}}</b>
                </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
            <div class="col-lg-6 mt-3">
              <div class="candidates-info-count">
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
    <!--  -->
    <!--  -->
    <div class="col-sm-6 col-lg-3 mb-4 mb-lg-0">
      <a [routerLink]="'/billing-list'">
        <div class="candidates-feature-info shadow-sm border-10 mt-4">
          <div class="row">
            <div class="col-9">
              <h2 class="customThemeClass"><b>Billing Count</b></h2>
              <hr class="mt-2">
            </div>
            <div class="col-3">
              <img class="img-responsive" src="assets/Dashboard New/Billing Count.png" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="candidates-info-content">
                <mat-card class="text-center"><b>Total - </b><b class="customThemeClass">{{totalBillingCount}}</b>
                </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
            <div class="col-lg-6 mt-3">
              <div class="candidates-info-count">
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col-sm-6 col-lg-3 mb-4 mb-lg-0">
      <a [routerLink]="'/billing/claimProcessingList'">
        <div class="candidates-feature-info shadow-sm border-10 mt-4">
          <div class="row">
            <div class="col-9">
              <h2 class="customThemeClass"><b>Claim Count</b></h2>
              <hr class="mt-2">
            </div>
            <div class="col-3">
              <img class="img-responsive" src="assets/Dashboard New/Claim Count.png" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="candidates-info-content">
                <mat-card class="text-center"><b>Total - </b><b class="customThemeClass">{{totalClaimCount}}</b>
                </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
            <div class="col-lg-6 mt-3">
              <div class="candidates-info-count">
                <mat-card class="text-center"><b>This Month - </b><b
                    class="customThemeClass">{{currentMonthClaimCount}}</b> </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
    <!--  -->

<!--
    <div class="col-sm-6 col-lg-3 mb-4 mb-lg-0">
      <div class="candidates-feature-info shadow-sm border-10 mt-4">
        <div class="row">
          <div class="col-9">
            <h2 class="customThemeClass"><b>Denial Count</b></h2>
            <hr class="mt-2">
          </div>
          <div class="col-3">
            <img class="img-responsive" src="assets/Dashboard New/Denial Count.png" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="candidates-info-content">
              <a [routerLink]="'/denial-reports/All'">
                <mat-card class="text-center"><b>Total - </b><b class="customThemeClass">{{totalDenialCount}}</b>
                </mat-card>
              </a>
            </div>
            <div class="candidates-info-count">
            </div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="candidates-info-count">
              <a [routerLink]="'/denial-reports'">
                <mat-card class="text-center"><b>This Month - </b><b
                    class="customThemeClass">{{currentMonthClaimCount}}</b> </mat-card>
              </a>
            </div>
            <div class="candidates-info-count">
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-sm-6 col-lg-3 mb-4 mb-lg-0">
      <div class="candidates-feature-info shadow-sm border-10 mt-4">
        <div class="row">
          <div class="col-9">
            <h2 class="customThemeClass"><b>Product Count</b></h2>
            <hr class="mt-2">
          </div>
          <div class="col-3">
            <img class="img-responsive" src="assets/Dashboard New/Denial Count.png" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="candidates-info-content">
              <a [routerLink]="'/denial-reports/All'">
                <mat-card class="text-center"><b>Total - </b><b class="customThemeClass">0</b>
                </mat-card>
              </a>
            </div>
            <div class="candidates-info-count">
            </div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="candidates-info-count">
              <a [routerLink]="'/denial-reports'">
                <mat-card class="text-center"><b>This Month - </b><b
                    class="customThemeClass">0</b> </mat-card>
              </a>
            </div>
            <div class="candidates-info-count">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="col-sm-6 col-lg-3 mb-4 mb-lg-0">
      <a [routerLink]="'/patient/insuranceAuthorization'">
        <div class="candidates-feature-info shadow-sm border-10 mt-4">
          <div class="row">
            <div class="col-9">
              <h2 class="customThemeClass"><b>Insurance Based Auth Count</b></h2>
              <hr class="mt-2">
            </div>
            <div class="col-3">
              <img class="img-responsive" src="assets/Dashboard New/Insurance Based Auth Count.png" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="candidates-info-content">
                <mat-card class="text-center"><b>Total - </b><b
                    class="customThemeClass">{{totalInsuranceBasedAuthCount}}</b>
                </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
            <div class="col-lg-6 mt-3">
              <div class="candidates-info-count">
                <mat-card class="text-center"><b>This Month - </b><b
                    class="customThemeClass">{{currentMonthInsuranceBasedAuthCount}}</b> </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="candidates-info-content">
                <mat-card class="text-center"><b>Active - </b><b class="customThemeClass">0</b>
                </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
            <div class="col-lg-6 mt-3">
              <div class="candidates-info-count">
                <mat-card class="text-center"><b>Expired - </b><b class="customThemeClass">0</b> </mat-card>
              </div>
              <div class="candidates-info-count">
              </div>
            </div>
          </div>

        </div>
      </a>
    </div>
    <!--  -->
    <!--  -->


    <div class="col-sm-6 col-lg-3 mb-4 mb-lg-0">
      <div class="candidates-feature-info shadow-sm border-10 mt-4">
        <div class="row">
          <div class="col-9">
            <a [routerLink]="'/auth-followup-report'">
              <h2 class="customThemeClass"><b>Item Based Auth Count</b></h2>
              <hr class="mt-2">
            </a>
          </div>

          <div class="col-3">
            <img class="img-responsive" src="assets/Dashboard New/Item based Auth Count.png" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="candidates-info-content">
              <a [routerLink]="'/auth-followup-report/all'">
                <mat-card class="text-center pointer" (click)="getItemBasedAuthCountForActive('All')"><b>Total - </b><b
                    class="customThemeClass">{{totalItemBasedAuthCount}}</b>
                </mat-card>
              </a>
            </div>
            <div class="candidates-info-count">
            </div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="candidates-info-count">
              <mat-card class="text-center"><b>This Month - </b><b
                  class="customThemeClass">{{currentMonthItemBasedAuthCount}}</b> </mat-card>
            </div>
            <div class="candidates-info-count">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="candidates-info-content">
              <mat-card class="text-center pointer" (click)="getItemBasedAuthCountForActive('Active')"><b>Active -
                </b><b class="customThemeClass">{{totalActiveItemBasedAuthCount}}</b>
              </mat-card>
            </div>
            <div class="candidates-info-count">
            </div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="candidates-info-count">
              <mat-card class="text-center pointer" (click)="getItemBasedAuthCountForActive('Expire')"><b>Expired -
                </b><b class="customThemeClass">{{currentMonthItemBasedAuthCount}}</b> </mat-card>
            </div>
            <div class="candidates-info-count">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-lg-3 mb-4 mb-lg-0">
      <div class="candidates-feature-info shadow-sm border-10 mt-4">
        <div class="row">
          <div class="col-8">
            <h2 class="customThemeClass"><b>Total SaleOrder To Billing Percentage</b></h2>
            <mat-progress-bar mode="determinate" [value]="totalSaleOrderToBillingPercentage"></mat-progress-bar>
          </div>
          <div class="col-4 pt-3">
            <b class="font-increase customThemeClass">{{totalSaleOrderToBillingPercentage}}{{"%"}}</b>
          </div>
        </div>
        <hr class="mt-2">
        <div class="row">
          <div class="col-8">
            <h2 class="customThemeClass"><b>Current Month SaleOrder To Billing Percentage</b></h2>
            <mat-progress-bar mode="determinate" [value]="currentMonthSaleOrderToBillingPercentage"></mat-progress-bar>
          </div>
          <div class="col-4 pt-3">
            <b class=" font-increase customThemeClass">{{currentMonthSaleOrderToBillingPercentage}}{{"%"}}</b>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<!-- <button mat-button *ngIf="showWareHouse" matBadge="{{intNotCount}}" [matBadgeHidden]="intNotCount===0"
  matBadgeColor="warn" matTooltip="Notification Tray" class="clr mat-fab-bottom-right rightfloatfabbutton buttonColor"
  (click)='openNotificationsModal()' mat-mini-fab>
  <mat-icon class="icon">notifications_active</mat-icon>
</button>
<app-notification-message *ngIf="showWareHouse"></app-notification-message>
<button mat-button *ngIf="showUser" matBadge="{{userClaimCount}}" [matBadgeHidden]="userClaimCount===0"
  matBadgeColor="warn" matTooltip="Claim Denial" class="clr mat-fab-bottom-right rightfloatfabbutton buttonColor"
  (click)='openNotificationsUserModal()' mat-mini-fab>
  <mat-icon class="icon">notifications_active</mat-icon>
</button>
<app-notification-message *ngIf="showUser"></app-notification-message>
<button mat-button matTooltip="Chat Box" class="clr mat-fab-bottom-right floatfabbuttontop buttonColor"
  (click)='openChatModal()' mat-mini-fab>
  <mat-icon class="icon">chat_bubble</mat-icon>
</button>
<app-notification-expiry></app-notification-expiry> -->
