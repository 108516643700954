<form [formGroup]="paymentSearchForm" >
  <div class="row" id="dropdown-container">
    <div class="col-12 d-flex">
        <mat-form-field class="col-2 custom-form-field" appearance="none" >
            <mat-label class="drpLabel">Method</mat-label>
            <mat-select class="custom-mat-select" formControlName="drpMethod"
            (selectionChange)="buildFilterParams()">
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Method"
                noEntriesFoundLabel="No Matches found"
                formControlName="txtMethodFilter"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let paymentTypes of filteredPaymentTypes | async"
              [value]="paymentTypes.id"
            >
              {{ paymentTypes.statusName }}
            </mat-option>
          </mat-select>
          <button
            mat-button
            *ngIf="
              paymentSearchForm.get('drpMethod').value != '' &&
              paymentSearchForm.get('drpMethod').value != null &&
              paymentSearchForm.get('drpMethod').value != undefined
            "
            (click)="
              clearMethod(); $event.stopPropagation();
            "
            matSuffix
            mat-icon-button
          >
          <mat-icon style="font-size: 16px !important;">close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field class="col-2" appearance="none">
          <mat-label class="drpLabel">Created Date</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              matInput
              placeholder="Start Date"
              (keydown)="addSlashToDate($event)"
              formControlName="txtCreatedStartDate"
              maxlength="10"
              style="font-size: 12px;"
            />

            <input
              matEndDate
              (dateChange)="onCreatedDateChange($event)"
              (keydown)="addSlashToDate($event)"
              matInput
              placeholder="End Date"
              formControlName="txtCreatedEndDate"
              maxlength="10"
              style="font-size: 12px;"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker" style="font-size: 12px !important;"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <button
            mat-button
            *ngIf="
              (paymentSearchForm.get('txtCreatedStartDate').value != '' &&
                paymentSearchForm.get('txtCreatedStartDate').value != null &&
                paymentSearchForm.get('txtCreatedStartDate').value !=
                  undefined) ||
              (paymentSearchForm.get('txtCreatedEndDate').value != '' &&
                paymentSearchForm.get('txtCreatedEndDate').value != null &&
                paymentSearchForm.get('txtCreatedEndDate').value != undefined)
            "
            (click)="
              clearCreatedDate();
              $event.stopPropagation();
              buildFilterParams()
            "
            matSuffix
            mat-icon-button
          >
          <mat-icon style="font-size: 16px !important;">close</mat-icon>
          </button>
          <mat-error *ngIf="paymentSearchForm?.get('txtCreatedStartDate')?.errors||paymentSearchForm?.get('txtCreatedEndDate')?.errors ">
            Enter a start date and End Date!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-2" class="drpLabel">
          <input
          class="drpLabel"
            matInput
            (change)="buildFilterParams()"
            formControlName="txtReferenceNo"
            placeholder="Check/Invoice No."
            autocomplete="off"
          />
        </mat-form-field>

        <mat-form-field class="col-2" appearance="none">
          <mat-label class="drpLabel">Remittance Date</mat-label>
          <mat-date-range-input [rangePicker]="pickerRemittance">
            <input
              matStartDate
              (keydown)="addSlashToDate($event)"
              matInput
              placeholder="Start date"
              formControlName="txtRemittanceStartDate"
              maxlength="10"
              style="font-size: 12px;"
            />

            <input
              matEndDate
              matInput
              (dateChange)="onRemittanceDateChange($event)"
              (keydown)="addSlashToDate($event)"
              placeholder="End Date"
              formControlName="txtRemittanceEndDate"
              maxlength="10"
              style="font-size: 12px;"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerRemittance" style="font-size: 12px !important;"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #pickerRemittance></mat-date-range-picker>
          <button
            mat-button
            *ngIf="
              (paymentSearchForm.get('txtRemittanceStartDate').value != '' &&
                paymentSearchForm.get('txtRemittanceStartDate').value !=
                  null &&
                paymentSearchForm.get('txtRemittanceStartDate').value !=
                  undefined) ||
              (paymentSearchForm.get('txtRemittanceEndDate').value != '' &&
                paymentSearchForm.get('txtRemittanceEndDate').value != null &&
                paymentSearchForm.get('txtRemittanceEndDate').value !=
                  undefined)
            "
            (click)="
              clearRemittanceDate();
              $event.stopPropagation();
              buildFilterParams()
            "
            matSuffix
            mat-icon-button
          >
          <mat-icon style="font-size: 16px !important;">close</mat-icon>
          </button>
          <mat-error *ngIf="paymentSearchForm?.get('txtRemittanceStartDate')?.errors||paymentSearchForm?.get('txtRemittanceEndDate')?.errors ">
            Enter a start date and End Date!
          </mat-error>
        </mat-form-field>
    </div>
  </div>
</form>
    <div class="d-flex justify-content-center" *ngIf="isLoading">
      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
    </div>


      <table *ngIf="!isLoading"
          datatable
          [dtOptions]="billingPaymentTable"
          class="row-border hover w-100 display"
        >
          <thead>
            <tr>
              <th [matTooltip]="'Options'"><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'Check/Invoice No.'">Check/Invoice No.</th>
              <th [matTooltip]="'Payer'">Payer</th>
              <th [matTooltip]="'Method'">Method</th>
              <th class="text-right" [matTooltip]="'Payment ($)'">
                Payment ($)
              </th>
              <th class="text-right" [matTooltip]="'Adjustments ($)'">
                Adjustments ($)
              </th>
              <th class="text-right" [matTooltip]="'Total ($)'">Total ($)</th>
              <!-- <th class="text-right" [matTooltip]="'Unapplied ($)'">
                Unapplied ($)
              </th> -->
              <th [matTooltip]="'Remittance/Paid Date'">
                Remittance/Paid Date
              </th>
              <th [matTooltip]="'Created By'">Created By</th>
              <th [matTooltip]="'Created Date'">Created Date</th>
              <th [matTooltip]="'Last Modified By'">Last Modified By</th>
              <th [matTooltip]="'Last Modified Date'">Last Modified Date</th>

              <!-- <th [matTooltip]="'Claim Count'">Claim Count</th> -->
              <!-- <th>Status</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let invoice of inBoundTableData">
              <td>
                <mat-icon class="pointer toggle-icon" (click)="getTotalInboundData($event, invoice)">add</mat-icon>

                <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                    <button (click)="openViewNotesPopup(invoice.patientId)"
                        [disabled]="isEmpty(invoice.patientId)" mat-menu-item>
                        View Patient Notes
                    </button>
                    <button mat-menu-item (click)="viewPatientLedger(invoice?.patientId)"
                        [disabled]="isEmpty(invoice.patientId)">
                        View Patient Ledger
                    </button>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(invoice.referenceNumber || '-')">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    [matTooltip]="invoice?.referenceNumber || '-'"
                    class="pr-2 checkNo-batch"
                    >{{ invoice.referenceNumber || "-" }}</span
                  >
                  <span
                    *ngIf="invoice.paymentMethod !== 'Cash'"
                    [matTooltip]="'Claim Count : ' + invoice?.claimCount || '-'"
                    class="claim-count-badge text-center align-middle"
                    mat-icon-button
                    >{{ invoice.claimCount }}</span
                  >
                </div>
              </td>

              <td (dblclick)="onRowDoubleClick(invoice.payerName || '-')" [matTooltip]="invoice?.payerName || '-'">
                {{ invoice.payerName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(invoice.paymentMethod || '-')"[matTooltip]="invoice?.paymentMethod || '-'">
                {{ invoice.paymentMethod || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(invoice.payment || '-')" class="text-right" [matTooltip]="invoice?.payment || '-'">
                {{ invoice.payment || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(invoice.adjustments || '-')" class="text-right" [matTooltip]="invoice?.adjustments || '-'">
                {{ invoice.adjustments || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(invoice.total || '-')" class="text-right" [matTooltip]="invoice?.total || '-'">
                {{ invoice.total || "-" }}
              </td>

              <!-- <td class="text-right" [matTooltip]="'-'">-</td> -->
              <td (dblclick)="onRowDoubleClick(invoice.remittanceDate || '-')" [matTooltip]="invoice?.remittanceDate || '-'">
                {{ invoice.remittanceDate || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(invoice.createdBy || '-')" [matTooltip]="invoice?.createdBy || '-'">
                {{ invoice.createdBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(invoice.createdDate || '-')" [matTooltip]="invoice?.createdDate || '-'">
                {{ invoice.createdDate || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(invoice.modifiedBy || '-')" [matTooltip]="invoice?.modifiedBy || '-'">
                {{ invoice.modifiedBy || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(invoice.modifiedDate || '-')" [matTooltip]="invoice?.modifiedDate || '-'">
                {{ invoice.modifiedDate || "-" }}
              </td>
            </tr>
          </tbody>
        </table>
