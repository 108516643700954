import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { PatientClinicalService } from '../patient-proxy/patient-optimization/patient-clinical.service';

@Component({
  selector: 'app-master-doctor',
  templateUrl: './master-doctor.component.html',
  styleUrls: ['./master-doctor.component.scss']
})
export class MasterDoctorComponent implements OnInit {

  isTableLoading:boolean = false;
  doctorTableData:any;
  currentPage : any = 1;
  totalPages : any = 1;
  pageOffset: any = 50;
  visibleColumns : number[];
  constructor(
    public title: Title,
    public patientclinicalservice: PatientClinicalService,
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | Physician');
    this.getPhysicianList();
  }

  changePage(pageNo : number){
    if( pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages)
    {
      this.currentPage = pageNo; 
      this.getPhysicianList();
    }
  }

//GET DOCTOR LIST
  getPhysicianList(){
    this.isTableLoading = true;
    this.patientclinicalservice.getDoctorListByPageNoAndPageOffset(this.currentPage, this.pageOffset)
      .subscribe(
        (response) => {
          this.doctorTableData = response.items || [];
          this.totalPages = Math.ceil(response?.totalCount / this.pageOffset);
          this.isTableLoading = false;
        },
        (err) => {
          this.isTableLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
}
