<form [formGroup]="denialSearchForm" >
  <div class="row" id="dropdown-container">
     <div class="col-12 d-flex">
      <mat-form-field class="col-2" appearance="none">
      <mat-label class="drpLabel">Denial </mat-label>
        <mat-select formControlName="drpDenial" [(value)]="drpDenialId" multiple
                  #denialSelect (closed)="handleSelectionChange(denialSearchForm.get('drpDenial').value);">
        <mat-option style="font-size: 12px;">
          <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                 formControlName="txtDenialFilter">
          </ngx-mat-select-search>
        </mat-option >
        <mat-option style="font-size: 12px;" *ngFor="let reasonCode of filteredreasonCode | async" [value]="reasonCode.id">
          {{ reasonCode.glossaryCode }}
        </mat-option>
        </mat-select>
      <button mat-button *ngIf="
          denialSearchForm.get('drpDenial').value != '' &&
          denialSearchForm.get('drpDenial').value != null &&
          denialSearchForm.get('drpDenial').value != undefined
        " (click)="clearDenial(); $event.stopPropagation()" matSuffix mat-icon-button>
        <mat-icon style="font-size: 16px !important;">close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="col-2" appearance="none">
      <mat-label class="drpLabel">Chart Id </mat-label>
        <mat-select formControlName="drpchartId" [(value)]="drpDenialId" multiple
                  #denialSelect (closed)="handleSelectionChange(denialSearchForm.get('drpchartId').value);">
        <mat-option style="font-size: 12px;">
          <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                 formControlName="txtChartIdFilter">
          </ngx-mat-select-search>
        </mat-option >
        <mat-option style="font-size: 12px;" >
         Test
        </mat-option>
        </mat-select>
      <button mat-button *ngIf="
          denialSearchForm.get('drpchartId').value != '' &&
          denialSearchForm.get('drpchartId').value != null &&
          denialSearchForm.get('drpchartId').value != undefined
        " (click)="clearDenial(); $event.stopPropagation()" matSuffix mat-icon-button>
        <mat-icon style="font-size: 16px !important;">close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="col-2" appearance="none">
      <mat-label class="drpLabel">Patient </mat-label>
        <mat-select formControlName="drpPatient" [(value)]="drpDenialId" multiple
                  #denialSelect (closed)="handleSelectionChange(denialSearchForm.get('drpPatient').value);">
        <mat-option style="font-size: 12px;">
          <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                 formControlName="txtPatientFilter">
          </ngx-mat-select-search>
        </mat-option >
        <mat-option style="font-size: 12px;" >
         Test
        </mat-option>
        </mat-select>
      <button mat-button *ngIf="
          denialSearchForm.get('drpPatient').value != '' &&
          denialSearchForm.get('drpPatient').value != null &&
          denialSearchForm.get('drpPatient').value != undefined
        " (click)="clearDenial(); $event.stopPropagation()" matSuffix mat-icon-button>
        <mat-icon style="font-size: 16px !important;">close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="col-2" appearance="none">
      <mat-label class="drpLabel">HCPC </mat-label>
        <mat-select formControlName="drpHcpc" [(value)]="drpDenialId" multiple
                  #denialSelect (closed)="handleSelectionChange(denialSearchForm.get('drpHcpc').value);">
        <mat-option style="font-size: 12px;">
          <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                                 formControlName="txtHcpcFilter">
          </ngx-mat-select-search>
        </mat-option >
        <mat-option style="font-size: 12px;">
         Test
        </mat-option>
        </mat-select>
      <button mat-button *ngIf="
          denialSearchForm.get('drpHcpc').value != '' &&
          denialSearchForm.get('drpHcpc').value != null &&
          denialSearchForm.get('drpHcpc').value != undefined
        " (click)="clearDenial(); $event.stopPropagation()" matSuffix mat-icon-button>
        <mat-icon style="font-size: 16px !important;">close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="col-2" appearance="none">
      <mat-label class="drpLabel">Payer</mat-label>
      <mat-select formControlName="drpPayer" [(value)]="drpPayorId" multiple
        (openedChange)="onPayerDropdownOpenChange($event)" (closed)="handleSelectionChange(denialSearchForm.get('drpPayer').value);">
        <mat-option style="font-size: 12px;">
          <ngx-mat-select-search ngDefaultControl placeholderLabel="Payer Id / Name / Code"
            hideClearSearchButton="true" [noEntriesFoundLabel]="drpDownMessage()" formControlName="txtPayerFilter"
            (keyup)="loadPayerDropDown($event.target.value,$event.key)">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option style="font-size: 12px;" *ngFor="let payor of filteredPrimaryPayors | async " [value]="payor?.payerId"
          [title]="payor?.payerShortCodeList">
          {{ payor?.payerShortCodeList }} </mat-option>
      </mat-select>

      <button mat-button *ngIf="
          denialSearchForm.get('drpPayer').value != '' &&
          denialSearchForm.get('drpPayer').value != null &&
          denialSearchForm.get('drpPayer').value != undefined
        " (click)="clearPayer(); $event.stopPropagation()" matSuffix mat-icon-button>
         <mat-icon style="font-size: 16px !important;">close</mat-icon>
      </button>
    </mat-form-field>
     </div>
  </div>
</form>
<div class="d-flex justify-content-center" *ngIf="isLoading">
  <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
</div>
      <table datatable  *ngIf="!isLoading" [dtOptions]="dtDenialBinTableOptions" class="row-border hover w-100 display">
          <thead>
            <tr>
              <th><mat-icon>apps</mat-icon></th>
              <!-- <th><mat-checkbox></mat-checkbox></th> -->
              <th [matTooltip]="'Denial'">Denial</th>
              <th [matTooltip]="'Created'">Created</th>
              <th [matTooltip]="'Chart Id'">Chart Id</th>
              <th [matTooltip]="'Patient'">Patient Name</th>
              <th [matTooltip]="'Claim Id'">Claim Id</th>
              <th [matTooltip]="'DOS'">DOS</th>
              <th [matTooltip]="'HCPCS'">HCPCS</th>
              <th [matTooltip]="'Modifiers'">Modifiers</th>
              <th [matTooltip]="'Amount($)'" class="text-right">Amount($)</th>
              <th [matTooltip]="'Payer'">Payor</th>
              <th [matTooltip]="'Comments'">Comments</th>
              <!-- <th>Status</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inBoundTableData">
              <td class="no-select">
                <mat-icon class=" toggle-icon"(click)="getTotalInboundData($event, data)"
                [ngClass]="
                       data.glossaryDescription!==null
                          ? 'pointer'
                          : ''
                      "  [matTooltip]="
                       data.glossaryDescription!==null
                          ? 'Click To View Comments'
                          : 'Comments Not Available'
                      ">add</mat-icon>

                <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                    <button (click)="openViewNotesPopup(data.patientId)" mat-menu-item>View Patient Notes</button>
                    <button (click)="viewPatientLedger(data?.patientId)" mat-menu-item>View Patient Ledger</button>
                </mat-menu>
            </td>
              <td (dblclick)="onRowDoubleClick(data.reasonCode || '-')"class="denial-code-bolder" [matTooltip]=" data.reasonCode || '-'">
                {{ data.reasonCode || '-' }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.creationTime || '-')"[matTooltip]=" data.creationTime || '-'">{{ data.creationTime || '-' }}</td>
              <td (dblclick)="onRowDoubleClick(data.chartNo || '-')">
                <a [class.disabled]="
                    isEmpty(data.chartNo) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartNo || '-'">{{ data.chartNo || "-" }}</a>
              </td>
              <td (dblclick)="onRowDoubleClick((data.patientname || '-') + ' (' + (data.dob || '-') + ')')"[matTooltip]="(data.patientname || '-') + ' (' + (data.dob || '-') + ')'">

               <p> {{ data.patientname || '-' }}</p>
                <!-- <br /> -->
                <p>({{ data.dob || '-' }})</p>
              </td>

              <td (dblclick)="onRowDoubleClick(data.claimNumber || '-' )">
                <!-- [routerLink]="
                '/orders/invoiceview/' + defaultGuid + '/' + defaultGuid
              " target="_blank" [matTooltip]="data.claimNumber || '-'" -->

                <a class="preview-link pointer"
                  [class.disabled]="isEmpty(data.claimNumber) || isEmpty(data.orderId) || isEmpty(data.patientId)  || isEmpty(data.mmReferenceOrderId) || isEmpty(data.claimId)"
                  (click)="
            openClaimForm(
              data.orderId ,
              data.patientId,
              data.mmReferenceOrderId ,
              data.claimId,
              data.chartId,
              data.patientname
            )
          ">{{ data.claimNumber || '-' }}</a>
              </td>

              <td (dblclick)="onRowDoubleClick(data.dosDate   || '-')" [matTooltip]="data.dosDate   || '-'">{{ data.dosDate || '-'}}</td>
              <td (dblclick)="onRowDoubleClick(data.hcpcCode  || '-')" [matTooltip]="data.hcpcCode  || '-'">{{ data.hcpcCode || '-'}}</td>
              <td (dblclick)="onRowDoubleClick(data.modifier1 || '-')" [matTooltip]="data.modifier1 || data.modifier2 || data.modifier3 || data.modifier4 || '-' ">{{
                data.modifier1 || data.modifier2 || data.modifier3 || data.modifier4 || '-'}}</td>
              <td (dblclick)="onRowDoubleClick(data.dBalanceAmount || '-')"[matTooltip]="('$')+data.dBalanceAmount || '-'" class="text-right">{{ data.dBalanceAmount || '-' }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.payer || '-')" [matTooltip]="data.payer || '-'">{{ data.payer || '-'}}</td>
              <td (dblclick)="onRowDoubleClick(data.glossaryDescription || '-')" [matTooltip]="data.glossaryDescription || '-'">{{ data.glossaryDescription || '-'}}</td>
              <!-- <td class="text-center">
                <div class="d-flex align-items-center">
                  <span [ngStyle]="{
                      'background-color': data.statusBgColor,
                      color: data.statusFgColor
                    }" class="rounded-border ml-1 w-75 p-1 claim-status-label">
                    {{ data?.status || "-" }}
                  </span>
                </div>
              </td> -->
            </tr>
          </tbody>
      </table>

