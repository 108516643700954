import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { PatientSearchDTO } from 'projects/patient/src/app/patient-search-tab/patient-search-tab.component';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AutoIntakeDocumentDetailsDTO } from '../admin-proxy/platform-app-management/rcm/patient-management/patient/dto/models';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';

@Component({
  selector: 'app-patient-search-tab',
  templateUrl: './patient-search-tab.component.html',
  styleUrls: ['./patient-search-tab.component.scss']
})
export class PatientSearchTabComponent implements OnInit {
  isLoadingResults: boolean = false;
  isLoading: boolean;
  isShowProgress: boolean;
  subscription$: Subscription[] = [];
  patientSearchTable: string[] = [
    'patientName',
    'defaultPatientId',
    'dob',
  ];
  PATIENTDATA: PatientSearchDTO[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  personalForm: FormGroup;
  constructor(
    public userService: UserService,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dateValidator: DateValidator,
    private patientPersonalService: PatientPersonalService
  ) { }


  ngOnInit(): void {
    this.personalForm = this.fb.group({
      patientName: '',
      defaultPatientId: '',
      ssn: '',
      dob: new FormControl('', this.dateValidator.dateVaidator),
      email: '',
      mobile: '',
      status: ''
    });
    this.setTableData([]);
    const valueChanges: Subscription = this.personalForm.valueChanges.pipe(
      startWith({
        patientName: '',
        defaultPatientId: '',
        ssn: '',
        dob: '',
        email: '',
        mobile: '',
        status: ''
      }),
      tap((x) => {
        this.isLoading = true;
        this.setTableData([]);
      }),
      filter((e) => e && this.personalForm?.valid),
      debounceTime(300),
      map((response) => {
        this.isLoading = true;
        const sValue = {
          patientName: "",//response?.patientName?.trim()?.toLowerCase(),
          defaultPatientId: response?.defaultPatientId?.trim()?.toLowerCase(),
          ssn: response?.ssn?.trim()?.toLowerCase(),
          dob: response?.dob?.trim()?.toLowerCase(),
          email: response?.email?.trim()?.toLowerCase(),
          mobile: response?.mobile?.trim()?.toLowerCase(),
          status: response?.status?.trim()?.toLowerCase()
        };
        return sValue;
      }),
      switchMap(sValue =>
        this.patientPersonalService
          .patientSearchBySDefaultPatientIdAndSPatientNameAndDtDOBAndSSSNAndSEmailIdAndSMobileAndSStatus(
            sValue?.defaultPatientId,
            sValue?.patientName,
            sValue?.dob,
            sValue?.ssn,
            sValue?.email,
            sValue?.mobile,
            sValue?.status,
            0
          )
      ), map(x => x)
    ).subscribe(
      (data) => {
        this.setTableData(data.map(x=> {
          x.dob =(new Date(x.dob).toLocaleDateString());
          return x;
        }));


      }, err => {
        this.setTableData([]);
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });

      });
    this.subscription$.push(valueChanges);

  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //Set Table Data
  setTableData(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filter = String(this.personalForm.value.patientName)?.trim()?.toLowerCase() ?? "";
    this.isLoading = false;
  }

}
export interface patientData {
  id: string,
  PatientId: string,
  Address: string,
  Name: string,
  DateOfBirth: string,
  SSN: string,
  AccountNumber: string,
  EmailAddress: string,
  Moblie: string,
  isAutoIntake: number,
  autoIntakeDocumentDetails: AutoIntakeDocumentDetailsDTO,
  LockStatus: string,
  LockStatusWithName: string,
  highlighted: boolean,
}
