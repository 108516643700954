<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center">
        <p class="header-text mb-0">
          Machine Settings ({{ data.categoryName }})
        </p>
        <button mat-icon-button mat-dialog-close (click)="closeModel()" class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card card-body">
        <div class="d-flex justify-content-center" *ngIf="!isFormLoaded">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>

        <form *ngIf="isFormLoaded" [formGroup]="machineSettingsForm">
          <div class="row">
            <!-- Min Pressure -->
            <div class="col-4" *ngIf="machineSettingFields.showMinPressure">
              <mat-form-field >
                <mat-label> Min Pressure </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtMinPressure" />
              </mat-form-field>
            </div>
            <!-- Max Pressure -->
            <div class="col-4" *ngIf="machineSettingFields.showMaxPressure">
              <mat-form-field >
                <mat-label> Max Pressure </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtMaxPressure" />
              </mat-form-field>
            </div>
            <!-- Setting Time -->
            <div class="col-3" *ngIf="machineSettingFields.showSettlingTime">
              <mat-form-field  >
                <mat-label> Setting Time </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtSettingTime" />
              </mat-form-field>
            </div>
            <div class="col-3" *ngIf="machineSettingFields.showIPAP">
              <!-- IPAP -->
              <mat-form-field  >
                <mat-label> IPAP </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtIPAP" />
              </mat-form-field>
            </div>
            <div class="col-3" *ngIf="machineSettingFields.showEPAP">
              <!-- EPAP -->
              <mat-form-field  >
                <mat-label> EPAP </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtEPAP" />
              </mat-form-field>
            </div>
            <div class="col-3" *ngIf="machineSettingFields.showRampPressure">
              <!-- Ramp Pressure -->
              <mat-form-field  >
                <mat-label> Ramp Pressure </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtRampPressure" />
              </mat-form-field>
            </div>
            <div class="col-3" *ngIf="machineSettingFields.showRampTime">
              <!-- Ramp Time -->
              <mat-form-field  >
                <mat-label> Ramp Time </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtRampTime" />
              </mat-form-field>
            </div>
            <div class="col-3" *ngIf="machineSettingFields.showBiFlex">
              <!-- BiFlex -->
              <mat-form-field  >
                <mat-label> BiFlex </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtBiFlex" />
              </mat-form-field>
            </div>

            <div class="col-3" *ngIf="machineSettingFields.showSetPressure">
              <!-- Set Pressure -->
              <mat-form-field  >
                <mat-label> Set Pressure </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtSetPressure" />
              </mat-form-field>
            </div>
            <div class="col-3" *ngIf="machineSettingFields.showCFlex">
              <!-- CFlex -->
              <mat-form-field  >
                <mat-label> CFlex </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtCFlex" />
              </mat-form-field>
            </div>
            <div class="col-3" *ngIf="machineSettingFields.showRRBPM">
              <!-- RRBPM -->
              <mat-form-field  >
                <mat-label> RRBPM </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtRRBPM" />
              </mat-form-field>
            </div>
            <div class="col-3" *ngIf="machineSettingFields.showIPAPmin">
              <!-- IPAP Min -->
              <mat-form-field  >
                <mat-label> IPAP Min </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtIPAPMin" />
              </mat-form-field>
            </div>
            <div class="col-3" *ngIf="machineSettingFields.showIPAPmax">
              <!-- IPAP Max -->
              <mat-form-field  >
                <mat-label> IPAP Max </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtIPAPMax" />
              </mat-form-field>
            </div>
            <div class="col-3" *ngIf="machineSettingFields.showEEP">
              <!-- EEP -->
              <mat-form-field  >
                <mat-label> EEP </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtEEP" />
              </mat-form-field>
            </div>

            <div class="col-3" *ngIf="machineSettingFields.showSetVT">

              <!-- Set VT -->
              <mat-form-field  >
                <mat-label> Set VT </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtSetVT" />
              </mat-form-field>
            </div>
            <div class="col-3" *ngIf="machineSettingFields.showRate">
              <!-- Rate -->
              <mat-form-field  >
                <mat-label> Rate </mat-label>
                <input (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" matInput maxlength="5"
                  formControlName="txtRate" />
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12 mt-2 px-0">
        <div class="d-flex flex-row-reverse">
          <button mat-raised-button class="resetclr" [disabled]="isSettingDiabled" (click)="resetForm()">
            Reset
          </button>
          <button mat-raised-button class="buttonColor mr-2" [disabled]="isSettingDiabled"
            (click)="saveMachineSettings()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>