import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import Swal from 'sweetalert2';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { Observable, Subscription } from 'rxjs';
import { DocumentBinService } from '../admin-proxy/platform-app-management/rcm/platform-management/document-bin/document-bin.service';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import moment, { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

const TREE_DATA: FoodNode[] = [
  {
    name: 'DWF (15)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'NC (32)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'Houseton (42)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'Ohio (16)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'Austin (32)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'Florida (16)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'Remote Services (41)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },

];
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}
interface FoodNode {
  name: string;
  children?: FoodNode[];
}
@Component({
  selector: 'app-processed-documents-tab',
  templateUrl: './processed-documents-tab.component.html',
  styleUrls: ['./processed-documents-tab.component.scss'],
  providers: [DatePipe],
})
export class ProcessedDocumentsTabComponent implements OnInit {
  processedDocumentsSearchForm: FormGroup;
  isShowSpinner: boolean = false;
  subscription$: Subscription[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'documentId',
    'wipId',
    'patientId',
    'patientName',
    'pageCount',
    'assignedTo',
    // 'assignedDate',
    'processedDate',
    //'documentStatus',
    //'options'
  ];
  selectedRange: { startDate: Moment; endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  fromDate: any;
  toDate: any;
  selectedOption = 'option1';
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';
  alwaysShowCalendars: boolean;
  dateRangStatus: boolean = false;
  isNewPatient: boolean = false;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  private transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  };
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  treeDatasource = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener
  );

  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchValue : string[] =[];
  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  tenantId: string = "";
  branchForm: FormGroup;
  organizationUnitName: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private inboundDocumentService: InboundDocumentService,
    private dateValidator: DateValidator,
    private documentBinService: DocumentBinService,
    private branchService : OrganizationUnitService,
    private datepipe: DatePipe,
    private cookieService: CookieService
  ) {
    this.treeDatasource.data = TREE_DATA;
    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Current week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      //'Next weekend': [this.getNextSaturday(), this.getNextSunday()],
      //'Next 3 days': [moment().add(1, 'days'), moment().add(3, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      // 'Current day': [moment(), moment()],
      // 'Next 2 days': [moment().add(1, 'days'), moment().add(2, 'days')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
  }

  ngOnInit(): void {
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.initializeForms();
    this.loadProcessedDocuments();

    this.branchForm = this.fb.group({
      drpBranch : new FormControl(''),
      txtBranch : new FormControl(''),
    });

    this.getBranchList();

  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  initializeForms() {
    this.processedDocumentsSearchForm = this.fb.group({
      documentId: new FormControl(''),
      wipId: new FormControl(''),
      patientId: new FormControl(''),
      patientName: new FormControl(''),
      pageCount: new FormControl(''),
      assignedTo: new FormControl(''),
      //documentStatus: new FormControl(''),
      assignedDate: new FormControl('', this.dateValidator.dateVaidator),
      processedDate: new FormControl('', this.dateValidator.dateVaidator),
    });
  }

  loadProcessedDocuments() {
    this.processedDocumentsSearchForm.valueChanges
      .pipe(
        startWith({
          documentId: '',
          wipId: '',
          patientId: '',
          patientName: '',
          pageCount: '',
          assignedTo: '',
          assignedDate: '',
          processedDate: '',
          fromDate:this.fromDate,
          toDate:this.toDate,
          //documentStatus: '',
        }),
        tap((x) => {
          this.isShowSpinner = true;
          this.setTableData([]);
        }),
        filter((e) => e && this.processedDocumentsSearchForm?.valid),
        debounceTime(300),
        map((response) => {
          this.isShowSpinner = true;
          const sValue = {
            documentId: response?.documentId?.trim()?.toLowerCase(),
            wipId: response?.wipId?.trim()?.toLowerCase(),
            patientId: response?.patientId?.trim()?.toLowerCase(),
            patientName: response?.patientName?.trim()?.toLowerCase(),
            pageCount: response?.pageCount?.trim()?.toLowerCase(),
            assignedTo: response?.assignedTo?.trim()?.toLowerCase(),
            assignedDate: response?.assignedDate?.trim()?.toLowerCase(),
            processedDate: response?.processedDate?.trim()?.toLowerCase(),
            fromDate:response?.fromDate?.trim().toLowerCase(),
            toDate:response?.toDate?.trim().toLowerCase(),
            //documentStatus: response?.documentStatus?.trim()?.toLowerCase(),
          };
          return sValue;
        }),
        switchMap((sValue) =>
          this.inboundDocumentService.getProcessDocumentDetails(
            sValue.documentId,
            '',
            sValue.wipId,
            sValue.patientId,
            sValue.patientName,
            sValue.assignedDate,
            sValue.fromDate,
            sValue.toDate,
            sValue.assignedTo,
            '',
            sValue.processedDate
          )
        )
      )
      .subscribe(
        (response) => {
          this.isShowSpinner = false;
          this.setTableData(response);
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'error',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  setTableData(data: any) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.sort.disableClear = true;
    this.dataSource.paginator = this.paginator;
  }
  redirectToInbound() {
    this.router.navigate(['inboundDocs']);
  }
  //Redirect to Manual Sorting Page
  redirectToManualSorting(patientId: string, documentId: string) {
    const documentBin = this.documentBinService
      .checkDocumentBinExists()
      .subscribe(
        (response) => {
          this.cookieService.set('sortingView', 'false');

          if (response === 'Success') {
            this.router.navigate([
              'documentSorting' + '/' + patientId + '/' + documentId,
            ]);
          }
        },
        (err) => {
          Swal.fire({
            icon: 'error',
            text: 'Please add the Document Type in Document Bin!',
          });
        }
      );
    this.subscription$.push(documentBin);
  }

  //Redirect Page to Processed Documents
  redirectToSortingDocuments() {
    this.router.navigate(['sortingDocuments']);
  }

  allBranchSelect(){
    if(this.selectAllBranch.selected){
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    }
    else{
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getBranchList(){
    const getBranchList = this.branchService.getBranchList(this.tenantId).subscribe(response => {
      response?.forEach(element => {
        this.drpBranch.push({
          id: element.id,
          organizationUnitName: element.organizationUnitName,
        })
      });

      this.brnachList = this.branchForm
        ?.get('txtBranch')
        ?.valueChanges?.pipe(
          startWith(''),
          map((value) =>
            this.drpBranch?.filter((option) =>
              option?.organizationUnitName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );


    }, err =>{
      const data : HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getBranchList);
  }

  datesUpdateds(e) {
    if (e.startDate != null || e.endDate != null) {
      this.dateRangStatus = true;
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
      this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
      this.loadProcessedDocuments();
    } else {
      this.dateRangStatus = false;
      (this.fromDate = null), (this.toDate = null);
    }
  }
  enableFilter(){
    let Filter = <HTMLButtonElement> document.getElementById('basic-addon1');
    Filter.classList.remove("disabled");
    Filter.disabled = true;
    let dateRange = <HTMLButtonElement> document.getElementById('calendar-input');
    dateRange.style.display="block";
    this.selectedRange=null;
  }
  disableFilter(){
    let Filter = <HTMLButtonElement> document.getElementById('basic-addon1');
    Filter.classList.add("disabled");
    Filter.disabled = false;
    let dateRange = <HTMLButtonElement> document.getElementById('calendar-input');
    dateRange.style.display="none";
    this.fromDate='';
    this.toDate='';
    this.loadProcessedDocuments();
  }

}



export interface Branch{
  id?: string;
  organizationUnitName?: string;
}
