<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between ">
        <h3 class="mb-0 font-size-18"><b> AR Aging Report</b></h3>
        <div class="page-title-box page-title-right  pt-2 pb-0">
          <ol class="breadcrumb m-0">
            <li class="ml-2 mt-2 breadcrumb-item"> AR Aging Report</li>
            <li class="mt-2 breadcrumb-item muted">MIS Reports</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-body">
    <!-- <div class="row">
      <div class="col-12">
        <b>
          AR Aging Report
        </b>
      </div>
    </div> -->
    <div class="row">
      <div class="col-8">
      </div>
      <div class="col-2">
        <button mat-button class="buttonColor"
        [disabled]="(dataSource && dataSource?.data && dataSource?.data?.length === 0)"
        (click)="exportTableData()">
          Export List
        </button>
      </div>
      <div class="col-md-2">
          <form [formGroup]="SearchForm">
              <div class="md-form mt-0 text-left" style="margin-left: -87px;">
                  <input autocomplete="new-searchData" formControlName="searchData" class="form-control mr-sm-2" type="text"
                      placeholder="Search">
              </div>
          </form>
      </div>
  </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="sno">
        <th class="header" mat-header-cell *matHeaderCellDef>
          S.No
        </th>
        <td mat-cell *matCellDef="let element let i = index">
          {{ i+1 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="invoiceServiceDate">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Invoice Service Date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.invoiceServiceDate | date: "MM/dd/yyyy":"en_US" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="patientName">
        <th class="header" mat-header-cell *matHeaderCellDef>Patient Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.patientName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="SaleOrderNumber">
        <th class="header" mat-header-cell *matHeaderCellDef>Sale Order Number</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.saleOrderNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="invoiceNumber">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Invoice Number
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.invoiceNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="BilledAmount">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Billed Amount
        </th>
        <td mat-cell *matCellDef="let element">
          {{'$ ' + (element?.billedAmount ||0 ) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="AllowedAmount">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Allowed Amount
        </th>
        <td mat-cell *matCellDef="let element">
          {{'$ ' + (element?.allowedAmount ||0 ) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="balanceAmount">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Balance Amount
        </th>
        <td mat-cell *matCellDef="let element">
          {{'$ ' + (element?.balanceAmount ||0 ) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdDaysOut">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Created Days Out
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.createdDaysOut }}
        </td>
      </ng-container>
      <ng-container matColumnDef="payorName">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Payor Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.payorName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="patientDefaultId">
        <th class="header" mat-header-cell *matHeaderCellDef>
          Patient Default Id
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.patientDefaultId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="branchName">
        <th class="header" mat-header-cell *matHeaderCellDef>Branch Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.branchName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="insurance">
        <th class="header" mat-header-cell *matHeaderCellDef>Insurance</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.insurance }}
        </td>
      </ng-container>
      <ng-container matColumnDef="itemName">
        <th class="header" mat-header-cell *matHeaderCellDef>Item Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.itemName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <th class="header" mat-header-cell *matHeaderCellDef>Units</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.quantity }}
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th class="header" mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.amount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecords">
        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
          <div *ngIf="!isLoading">
            {{ "No Records Found" }}
          </div>
          <div [hidden]="!isLoading">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paginator">
        <td mat-footer-cell *matFooterCellDef colspan="12">
          <mat-paginator class="table table-responsive" #MatPaginator [length]="dataSource?.data?.length"
            [pageSize]="10" [pageIndex]="0" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
          </mat-paginator>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="['noRecords']" [ngClass]="{
        active: dataSource && dataSource?.data && dataSource?.data?.length !== 0
      }"></tr>
      <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
    </table>
    <div *ngIf="dataSource && dataSource?.data && dataSource?.data?.length !== 0" class="row mt-3" style="display:none;">
      <div class="col-12">
        <b>
          Aging Summary
        </b>
      </div>
    </div>
    <div *ngIf="dataSource && dataSource?.data && dataSource?.data?.length !== 0" class="row mt-2" style="display:none;">
      <mat-form-field class="col-3">
        <mat-label>Row </mat-label>
        <mat-select [(ngModel)]="txtRow">
          <mat-option *ngFor="let report of drpARAgingReport" [value]="report.rowName">
            {{ report.rowName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>Column </mat-label>
        <mat-select [(ngModel)]="txtColumn">
          <mat-option *ngFor="let report of drpARAgingReport" [value]="report.rowName">
            {{ report.rowName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="col-6">
        <button mat-button class="mt-2 mr-2 buttonColor" [disabled]="!((txtRow) && (txtColumn))"
          (click)="getTableBasedOnFilter()">
          Submit
        </button>
      </div>
    </div>
    <ng-container *ngIf="filteredData && dataSource && dataSource?.data && dataSource?.data?.length !== 0">
      <app-angular-pivot-table *ngIf="txtRow && txtColumn" [source]="source" [configs]="configs">
      </app-angular-pivot-table>
    </ng-container>
  </div>
</div>

<div class="row">
  <div class="col-12 text-right">
    <button mat-button matTooltip="Add Reports" class="clr mat-fab-bottom-right rightfloatfabbutton"
      (click)='openReportsmodal()' mat-mini-fab>
      <mat-icon class="icon">add</mat-icon>
    </button>
  </div>
</div>
