import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TableService } from 'projects/shared/src/app/table.service';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';

import { GetRoleListUsingTenantIdDto } from '../../admin-proxy/platform-app-management/rcm/platform-management/role/models';
import { RoleService } from '../../admin-proxy/platform-app-management/rcm/platform-management/role/role.service';
import { IdentityUserCreateUsingTenantIdDto, IdentityUserUsingTenantIdDto } from '../../admin-proxy/platform-app-management/rcm/platform-management/user/models';
import { UserService } from '../../admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { IdentityRoleDto, IdentityUserUpdateDto } from '../../admin-proxy/platform-app-management/volo/abp/identity/models';
import { ToastrService } from "ngx-toastr";
import { RoleDto } from '../../admin-proxy/platform-app-management/identity';

@Component({
  selector: 'app-executives-users',
  templateUrl: './executives-users.component.html',
  styleUrls: ['./executives-users.component.scss']
})
export class ExecutivesUsersComponent implements OnInit {

  public strPageType: string = "executiveUserList";
  executiveUsersForm: FormGroup;
  isPanelOpenState = false;
  // drpRoles: IdentityRoleDto[] = [];
  drpRoles: RoleDto[] = [];
  executiveUsersTableData: any[] = [];
  userId: string = "";
  concurrencyStamp: string = "";
  emailMask: any;
  passwordHide = true;
  confirmPswdhide = true;
  tenantId: string = "";
  constructor(
    public setTitle: Title,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private tableService: TableService,
    private roleService: RoleService,
    private textValidators: TextValidators,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.tenantId = localStorage.getItem("tenantId") ?? "";

    this.executiveUsersForm = this.formBuilder.group({
      txtUserName: new FormControl("", [Validators.required, this.textValidators.isTextCheck]),
      txtEmail: new FormControl("", [Validators.required, Validators.email]),
      txtRole: new FormControl("", Validators.required),
      txtPassword: new FormControl("", Validators.required),
      txtConfirmPassword: new FormControl("", Validators.required),
    })
    this.setTitle.setTitle("Qsecure | Executive Roles");
    this.loadExecutiveUsersTable();
    this.loadRoleDropdown();
    this.emailMask = emailMask;
  }

  saveExecutiveUsers() {
    if (this.userId == null || this.userId == "" || this.userId == undefined) {
      let user: IdentityUserCreateUsingTenantIdDto = {
        tenantId: null,
        userName: this.executiveUsersForm.value.txtUserName,
        name: "",
        surname: "",
        email: this.executiveUsersForm.value.txtEmail.toLowerCase(),
        phoneNumber: "",
        lockoutEnabled: true,
        roleNames: [
          this.executiveUsersForm.value.txtRole
        ],
        password: this.executiveUsersForm.value.txtPassword,
        extraProperties: {
          organizationUnitId: this.executiveUsersForm.value.txtBranchName
        }
      };
      this.userService.createUser(user).subscribe(response => {
        this.resetExecustiveUsers();
        this.loadExecutiveUsersTable();
        this.toastr.success('Saved Successfully','Success')
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      }, err => {
      });
    }
    else {
      let user: IdentityUserUpdateDto = {
        userName: this.executiveUsersForm.value.txtUserName,
        name: "",
        surname: "",
        email: this.executiveUsersForm.value.txtEmail.toLowerCase(),
        phoneNumber: "",
        lockoutEnabled: true,
        roleNames: [
          this.executiveUsersForm.value.txtRole
        ],
        password: this.executiveUsersForm.value.txtPassword,
        extraProperties: {
          organizationUnitId: this.executiveUsersForm.value.txtBranchName
        },
        concurrencyStamp: this.concurrencyStamp,
      };

      this.userService.updateUser(this.tenantId, this.userId, user).subscribe(response => {
        this.resetExecustiveUsers();
        this.loadExecutiveUsersTable();
        this.toastr.success('Saved Successfully','Success')
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
      }, err => {
      });

    }
  }

  loadExecutiveUsersTable() {
    let userList: IdentityUserUsingTenantIdDto = {
      tenantId: null
    }
    this.userService.getUserList({ tenantId: this.tenantId }).subscribe(response => {
      this.executiveUsersTableData = [];
      response && response?.forEach(element => {

        this.executiveUsersTableData.push({
          name: element?.userName,
          email: element?.email,
          role: element?.roleName,
          id: element?.id,
        });
      })
      this.tableService.setExecutiveUserListTable(this.executiveUsersTableData);
    })
  }

  ViewExecutiveUser(event) {
    this.getExecutiveUserById(event.id)

  }
  getExecutiveUserById(userId) {
    this.isPanelOpenState = !this.isPanelOpenState;
    this.executiveUsersForm.controls['txtPassword'].disable();
    this.executiveUsersForm.controls['txtConfirmPassword'].disable();
    this.userService.getUserById(this.tenantId, userId).subscribe(response => {
      this.userId = response?.id ?? "";
      this.concurrencyStamp = response?.concurrencyStamp ?? "";
      this.executiveUsersForm.patchValue({
        txtUserName: response?.userName,
        txtEmail: response?.email,
        txtRole: response?.roleName,
      })
    })
  }

  deleteExecutiveUser(event) {
    // Swal.fire({
    //   title: 'Are you sure you want to Delete?',
    //   text: 'You won\'t be able to retrieve this data!',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#34c38f',
    //   cancelButtonColor: '#f46a6a',
    //   confirmButtonText: 'Yes, delete it!'
    // }).then(result => {
    //   if (result.value) {
    //     this.userService.deleteUser(null, event.id).subscribe(response => {
    //       this.executiveUsersTableData.forEach(element => {
    //         if (event.id == element?.id) {
    //           let index = this.executiveUsersTableData.indexOf(element, 0)
    //           this.executiveUsersTableData.splice(index, 1)
    //         }
    //       });
    //       this.tableService.setExecutiveUserListTable(this.executiveUsersTableData);
    //     }, err => {
    //       const data: HttpErrorResponse = err;
    //       Swal.fire({
    //         icon: 'error',
    //         text: data?.error?.error?.message,
    //       });
    //     });
    //   }
    // });
    // this.resetExecustiveUsers();
  }

  loadRoleDropdown() {
    let roleList: GetRoleListUsingTenantIdDto = {
      tenantId: null ?? ""
    }
    this.roleService.getRoleList(roleList).subscribe(response => {
      this.drpRoles = [];
      this.drpRoles = response;
    })
  }

  resetExecustiveUsers() {
    this.executiveUsersForm.patchValue({
      txtUserName: "",
      txtEmail: "",
      txtRole: "",
      txtPassword: "",
      txtConfirmPassword: "",
    })
    this.executiveUsersForm?.markAsUntouched();
    this.executiveUsersForm?.get('txtUserName')?.setErrors(null);
    this.executiveUsersForm?.get('txtEmail')?.setErrors(null);
    this.executiveUsersForm?.get('txtRole')?.setErrors(null);
    this.executiveUsersForm?.get('txtPassword')?.setErrors(null);
    this.executiveUsersForm?.get('txtConfirmPassword')?.setErrors(null);
    this.executiveUsersForm?.updateValueAndValidity();
  }
}
