import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { PatientAuthSaveUpdateFormComponent } from '../patient-auth-save-update-form/patient-auth-save-update-form.component';
import { defaultGuid } from '../medicare-check-list/medicare-check-list.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-patient-authorization-table',
  templateUrl: './patient-authorization-table.component.html',
  styleUrls: ['./patient-authorization-table.component.scss']
})
export class PatientAuthorizationTableComponent implements OnInit {
  @Input() tableData: any;
  @Output() isLoadTable? = new EventEmitter();
  @Output() viewClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteClick: EventEmitter<any> = new EventEmitter<any>();
  // dtAuthorizationOptions: any = {
  //   responsive: true,
  //   paging: false,
  //   scrollCollapse: true,
  //   scrollY: '300px',
  //   scrollX: true,
  //   info: false
  // };

  dtAuthorizationOptions:any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '350px',
    scrollX: true,
    fixedHeader: true,
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Authorization List',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))',
      }
    ],
  };
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  tableAuthorizationDetails :any;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.tableAuthorizationDetails = this.tableData;

    this.dtAuthorizationOptions.buttons[0].filename =
    'Authorization List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
    setTimeout(() => {
      $('#tblAuthorization').DataTable().columns.adjust().draw();
    }, 200);
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

  doprecert(data: any) {
    const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent);
    const componentInstance = dialogRef.componentInstance;
    componentInstance.patientId = data.patientId;
    componentInstance.verificationId = data.verificationId;
    componentInstance.docId = data.docId;
    componentInstance.faxId = data.faxId;
    componentInstance.isView = false;
    componentInstance.isSupplies = true;
    componentInstance.authorizationId=data.authorizationId;
    // componentInstance.selectedNode = this.selectedNode;
    componentInstance.chartId = data.chartId;

  }
  viewprecert(data) {
    const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent);
    const componentInstance = dialogRef.componentInstance;
    componentInstance.patientId = data.patientId;
    componentInstance.verificationId = data.verificationId;
    componentInstance.docId = data.docId;
    componentInstance.faxId = data.faxId;
    componentInstance.isView = true;
    componentInstance.isSupplies = true;
    componentInstance.authorizationId=data.authorizationId;
    // componentInstance.selectedNode = this.selectedNode;
    componentInstance.chartId = data.chartId;
    dialogRef.afterClosed().subscribe(() => {
      this.isLoadTable.emit();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
}
