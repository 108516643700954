<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 d-flex flex-row">
          <ng-container *ngFor="let data of menus">
            <label [ngClass]="getArrowClass(data)" [ngStyle]="{ 'background-color': data.color }"
              (click)="binActiveTab(data)"
              class="pointer customPointingLabel claim-status-label rounded-border down mb-2 py-2 px-2 text-center mr-1"
              matlabel>
              {{ data.status }} ({{ data.count }})
            </label>
          </ng-container>
        </div>
      </div>

      <div class="card card-body">

        <div class="row">
          <div class="col-12 px-0">
            <p class="header-text mb-0 pl-3">Filters</p>
            <form class="pl-1" [formGroup]="cliamBinQueueFilterForm">

              <!-- Provider Name -->
              <mat-form-field appearance="none" class="col-1_5 custom-form-field" style="min-width: 130px !important;">
                <mat-label class="drpLabel">Provider Name</mat-label>
                <mat-select class="custom-mat-select" formControlName="drpProvider" [(ngModel)]="lstSelecteddrpProvider"
                  (selectionChange)="getClaimFileterList(inboundstatusCode)" (openedChange)="loadProviderDropdown()"
                  multiple>
                  <mat-option class="drpOptionFontSize">
                    <ngx-mat-select-search (keyup)="loadProviderDropdown()" placeholderLabel="Provider Name" formControlName="txtProvider"
                      noEntriesFoundLabel="No Matches found"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let data of lstprovider" [value]="data.pId" class="drpOptionFontSize">{{ data.providerName }}</mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Chart Id -->
              <mat-form-field class="col-1_5 custom-form-field" appearance="none">
                <mat-label class="drpLabel">Chart Id</mat-label>
                <mat-select class="custom-mat-select" formControlName="drpChartId" [(ngModel)]="lstSelectedChartNos"
                  multiple (selectionChange)="getClaimFileterList(inboundstatusCode)">
                  <mat-option class="drpOptionFontSize">
                    <ngx-mat-select-search (keyup)="loadChartNoDropdown()" placeholderLabel="Chart Id"
                      formControlName="txtChartId" noEntriesFoundLabel="No Matches found"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let data of lstChartNos" [value]="data.spatientID" class="drpOptionFontSize">{{ data.chartId }}</mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Patient Name -->
              <mat-form-field class="col-1_5 custom-form-field" appearance="none">
                <mat-label class="drpLabel">Patient</mat-label>
                <mat-select class="custom-mat-select" formControlName="drpPatientName"  [(ngModel)]="lstSelectedPatients"
                  (selectionChange)="getClaimFileterList(inboundstatusCode)" multiple>
                  <mat-option class="drpOptionFontSize">
                    <ngx-mat-select-search placeholderLabel="Patient Name" (keyup)="loadPatientDropdown()"
                      formControlName="txtPatientName" noEntriesFoundLabel="No Matches found"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let data of lstPatients" [value]="data.patId" class="drpOptionFontSize">{{ data.fullName }}</mat-option>
                </mat-select>
              </mat-form-field>

            <!-- Status -->
            <mat-form-field class="col-1_5 custom-form-field" appearance="none">
              <mat-label class="drpLabel">Status</mat-label>
              <mat-select class="custom-mat-select" formControlName="drpClaimStatus"  [(ngModel)]="lstSelectedClaimStatus"
                (selectionChange)="getClaimFileterList(inboundstatusCode)" multiple>
                <mat-option class="drpOptionFontSize">
                  <ngx-mat-select-search placeholderLabel="Patient Name"
                    formControlName="txtClaimStatus" noEntriesFoundLabel="No Matches found"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let data of lstClaimStatus" [value]="data.value" class="drpOptionFontSize">{{ data.value }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Date of Service -->
            <mat-form-field class="col-1_5 custom-form-field" appearance="none" *ngIf="false">
              <mat-label class="drpLabel">Date of Service</mat-label>
              <mat-date-range-input [rangePicker]="picker" >
                <input style="font-size: 12px;" class="dateinput" matStartDate matInput placeholder="Start Date" formControlName="txtDOSFromDate" maxlength="10"
                   (keydown)="addSlashToDate($event)" (focusout)="focusOutDOS()" (dateChange)="focusOutDOS();"/>
                <input style="font-size: 12px;" class="dateinput" matEndDate matInput placeholder="End Date" formControlName="txtDOSEndDate" maxlength="10"
                  (keydown)="addSlashToDate($event)" (focusout)="focusOutDOS()"(dateChange)="focusOutDOS();"/>
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 12px !important;"></mat-datepicker-toggle>
              <mat-date-range-picker #picker ></mat-date-range-picker>
              <button mat-button *ngIf="
              (cliamBinQueueFilterForm.get('txtDOSFromDate').value != '' &&
              cliamBinQueueFilterForm.get('txtDOSFromDate').value != null &&
              cliamBinQueueFilterForm.get('txtDOSFromDate').value != undefined) ||
              (cliamBinQueueFilterForm.get('txtDOSEndDate').value != '' &&
              cliamBinQueueFilterForm.get('txtDOSEndDate').value != null &&
              cliamBinQueueFilterForm.get('txtDOSEndDate').value != undefined)
            " (click)="clearDOS(); $event.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon style="font-size: 12px !important;">close</mat-icon>
          </button>
          <mat-error *ngIf="cliamBinQueueFilterForm?.get('txtDOSFromDate')?.error||cliamBinQueueFilterForm?.get('txtDOSEndDate')?.error ">
            Enter a start date and End Date!
          </mat-error>
          </mat-form-field>
            </form>
          </div>
        </div>

        <mat-divider class="col-12 p-1"></mat-divider>

        <div class="d-flex justify-content-center" *ngIf="loadInboundData">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>

        <div class="table-responsive" *ngIf="!loadInboundData">
          <app-claim-bin-queue-table [inBoundTableData]="inboundTableData" (nevigatePage)="changePage($event)" [activeTabBin]="activeTabBin" [visibleColumns]="visibleColumns" [currentPage]="currentPage" [totalPages]="totalPages"></app-claim-bin-queue-table>
        </div>
      </div>
    </div>
  </div>
</div>