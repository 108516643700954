import type { CreateUpdatePayerDetailDTO, PayerDetailDTO, PayerResponseDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { PayorDetailDTO } from './practice-management/dto';

@Injectable({
  providedIn: 'root',
})
export class PayerDetailService {
  apiName = 'Default';

  create = (input: CreateUpdatePayerDetailDTO) =>
    this.restService.request<any, PayerDetailDTO>({
      method: 'POST',
      url: '/api/app/payer-detail',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/payer-detail/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, any>({
      method: 'GET',
      url: `/api/app/payer-detail/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, any>({
      method: 'GET',
      url: '/api/app/payer-detail',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdatePayerDetailDTO) =>
    this.restService.request<any, PayerDetailDTO>({
      method: 'PUT',
      url: `/api/app/payer-detail/${id}`,
      body: input,
    },
    { apiName: this.apiName });
    getPayersByClearingHouse = () =>
    this.restService.request<any, PayerDetailDTO[]>({
      method: 'GET',
      url: '/api/app/payer-detail/get-payers-by-clearing-house',
    },
    { apiName: this.apiName });
  getAllPayers = () =>
    this.restService.request<any, boolean>({
      method: 'GET',
      url: '/api/app/payer-detail/get-all-payers',
    },
    { apiName: this.apiName });

    getdrpPayerByIMode = (iMode: number) =>
    this.restService.request<any, PayerResponseDTO[]>({
      method: 'GET',
      url: '/api/app/payer-detail/drp-payer',
      params: { iMode },
    },
    { apiName: this.apiName });
    getdrpPayerByIModeV1 = (iMode: number,sSearch:string,gPayor:string) =>
    this.restService.request<any, PayerResponseDTO[]>({
      method: 'GET',
      url: '/api/app/payer-detail/drp-payer',
      params: { iMode,sSearch,gPayor },
    },
    { apiName: this.apiName });

  getPayerListByModeByIMode = (iMode: number) =>
    this.restService.request<any, PayerDetailDTO[]>({
      method: 'GET',
      url: '/api/app/payer-detail/get-payer-list-by-mode',
      params: { iMode },
    },
    { apiName: this.apiName });
    payerEnableDisable = (id: string) =>
    this.restService.request<any, any>({
      method: 'POST',
      url: `/api/app/payer-detail/enable-disable-payer/${id}`,
    },
    { apiName: this.apiName });
    InsuranceCategoryCreate= (InsuranceCategoryId:string,lstPayorId:string[]) =>
    this.restService.request<any, string>({
      method: 'PUT',
      url: '/api/app/payer-detail/update-insurance-cat-id-to-payer-ids',
      params:{InsuranceCategoryId:InsuranceCategoryId},
      body: lstPayorId,
    },
    { apiName: this.apiName });
    statusFilter= (iMode:number,statusFilter:number,sSearch:string) =>
    this.restService.request<any, any>({
      method: 'GET',
      url: `/api/app/payer-detail/get-payer-list-by-mode`,
      params:{iMode,statusFilter,sSearch}
    },
    { apiName: this.apiName });

  getPayorListByPayerDetaislAndSPayer = (payerDetaisl: string, sPayer: string[]) =>
    this.restService.request<any, PayorDetailDTO[]>({
      method: 'POST',
      url: '/api/app/payer-detail/get-payor-list',
      params: { payerDetaisl },
      body: sPayer,
    },
    { apiName: this.apiName });
  constructor(private restService: RestService) {}
}
