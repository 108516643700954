import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { MasterNotesService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { noteTypeOptions } from 'projects/admin/src/app/warehouse-proxy/inventory/dto';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { PatientNotesService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import {
  NoteType,
  PatientNotesDTO,
  PatientNotesSearchDTO,
  PatientNotesV1ApiDTO,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  startWith,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { crudPremission } from '../../shared-table/shared-table.component';
import { DateValidator } from '../../validations/date-validator';
import { CookieService } from 'ngx-cookie-service';
import { Title } from '@angular/platform-browser';
import { dtInboundOptions } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { CommunicationService } from '../../services/communication.service';

@Component({
  selector: 'app-patient-common-notes',
  templateUrl: './patient-common-notes.component.html',
  styleUrls: ['./patient-common-notes.component.scss'],
})
export class PatientCommonNotesComponent
  implements OnInit, OnDestroy{
  patientNotesForm: FormGroup;
  @Output() patientIdEmitter: EventEmitter<string> = new EventEmitter<string>();
  subscription$: Subscription[] = [];
  @Input() patientId: string = defaultGuid;
  @Input() docId: string = defaultGuid;
  @Input() orderId: string = defaultGuid;
  @Input() claimId: string = defaultGuid;
  @Input() noteType: NoteType = NoteType.general;
  @Input() isAddVisible: boolean = true;
  @Input() selectedNode: any;

  PatientNotesDetails: any;
  vpatientName: any;
  vchartNo: any;
  vDescription: any;
  PatientNotesDetailsV1: PatientNotesV1ApiDTO[] = [];

  // dtNotesOptions = dtInboundOptions;
  selectedRow: string;
  isLoading: boolean;
  isNewPatient: any;
  isOrderValue: any;
  editOrderStatus: boolean = false;
  isShowProgress: boolean;
  arrTableData: PatientNotesDTO[] = [];
  crudPermisson: crudPremission = {
    create: false,
    read: false,
    update: false,
    delete: false,
  };
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource = new MatTableDataSource(this.arrTableData);
  isShownUpdateIconForNotes: boolean = true;
  editFormValues: boolean = false;

  public arrDisplayedColumns: string[] = [
    'options',
    'defaultNotesId',
    'customNoteType',
    'noteReason',
    'subject',
    'description',
    'createdBy',
    'status',
    'createdDate',
  ];
  $subscription: any;
  selectedRowIndex: number;
  constructor(
    private patientNotesService: PatientNotesService,
    private formbuilder: FormBuilder,
    private matDialog: MatDialog,
    private dateValidator: DateValidator,
    private notesService: MasterNotesService,
    private cookieService: CookieService,
    public title: Title,
    private dialog: MatDialog,
    private communicationService: CommunicationService,

    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      pageType: NoteType;
      isAddVisible: boolean;
      orderId: string;
      docId: string;
      defaultFaxId: string;
      chartNo: string;
      addnotes:boolean;
      patientVerificationId:string;
      patientAuthorizationId:string;
      patientMedicareChecklistId:string;
    } = {
        patientId: defaultGuid,
        orderId: defaultGuid,
        pageType: NoteType.general,
        isAddVisible: true,
        docId: defaultGuid,
        defaultFaxId: '',
        chartNo: 'Kandan',
        addnotes:false,
        patientVerificationId:defaultGuid,
        patientAuthorizationId:defaultGuid,
        patientMedicareChecklistId:defaultGuid,
      }
  ) {

    this.communicationService.viewNotesFunctionCall$.subscribe((selectedNode) => {
      this.getTableData();
    });
  }

  //On Page Load
  ngOnInit(): void {
    // this.dtOptions = {
    //   responsive: true,
    //   pageLength: 5,
    //   paging: false,
    //   scrollCollapse: true,
    //   scrollY: '300px',
    //   scrollX: true,
    //   info: false,
    // };

    // this.title.setTitle('Qsecure | Notes');
    this.isOrderValue = this.cookieService.get('orderStatus');
    if (this.isOrderValue == 'true') {
      this.editOrderStatus = true;
      this.editFormValues = true;
    } else {
      this.editOrderStatus = false;
      this.editFormValues = false;
    }

    if ((this.patientId ?? defaultGuid) !== defaultGuid) {
      this.patientId = this.patientId ?? defaultGuid;
    }
    if ((this.data?.patientId ?? defaultGuid) !== defaultGuid) {
      this.patientId = this.data?.patientId ?? defaultGuid;
    }
    if (this.data && this.data?.isAddVisible !== null) {
      this.isAddVisible = this.data?.isAddVisible ?? false;
    }
    if (this.data) {
      this.noteType;
    }

    this.patientNotesForm = this.formbuilder.group({
      defaultNotesId: new FormControl(''),
      noteType: new FormControl(''),
      description: new FormControl(''),
      severity: new FormControl(''),
      subject: new FormControl(''),
      noteReason: new FormControl(''),
      createdBy: new FormControl(''),
      createdDate: new FormControl('', this.dateValidator.dateVaidator),
      actualDate: new FormControl('', this.dateValidator.dateVaidator),
      dateNeeded: new FormControl('', this.dateValidator.dateVaidator),
      status: new FormControl(''),
      dateComplete: new FormControl('', this.dateValidator.dateVaidator),
    });
    this.isNewPatient = this.cookieService.get('patientStatus');
    if (this.isNewPatient == 'true') {
      this.editFormValues = true;
    } else {
      this.editFormValues = false;
      this.editOrderStatus = false;
      this.patientIdEmitter.emit();
    }
    this.getTableData();
  }
  editForm() {
    this.editFormValues = true;
    this.editOrderStatus = true;
  }
  //Get Notes List into Table
  // getTableData() {
  //   this.isLoading = true;
  //   const valueChanges = this.patientNotesForm.valueChanges
  //     .pipe(
  //       distinctUntilChanged(),
  //       startWith({
  //         defaultNotesId: '',
  //         noteType: '',
  //         noteReason: '',
  //         description: '',
  //         subject: '',
  //         createdBy: '',
  //         status: '',
  //         createdDate: null,
  //       }),
  //       filter((e) => e && this.patientNotesForm?.valid),
  //       map((response: any) => {
  //         //this.setPatientTable([]);
  //         //this.PATIENTDATA = [];
  //         this.isLoading = true;
  //         this.isShowProgress = true;
  //         const sValue: PatientNotesSearchDTO = {
  //           defaultNotesId: response?.defaultNotesId?.trim()?.toLowerCase(),
  //           noteType: response?.noteType?.trim()?.toLowerCase(),
  //           noteReason: response?.noteReason?.trim()?.toLowerCase(),
  //           description: response?.description?.trim()?.toLowerCase(),
  //           subject: response?.subject?.trim()?.toLowerCase(),
  //           createdBy: response?.createdBy?.trim()?.toLowerCase(),
  //           status: response?.status?.trim()?.toLowerCase(),
  //           dateCreated: response?.createdDate?.trim()?.toLowerCase(),
  //         };
  //         return sValue;
  //       }),
  //       switchMap((sValue) =>
  //         this.patientNotesService.notesSearchByGPatientIdAndGOrderIdAndGClaimIdAndSNoteIdAndSPatientNameAndSDefaultPatientIdAndSNoteTypeAndSReasonAndSSubjectAndSDescriptionAndSCreatedByAndDtCreatedDateAndSStatusAndStart_DateAndEnd_Date(
  //           this.patientId,
  //           defaultGuid,
  //           defaultGuid,
  //           sValue?.defaultNotesId,
  //           '',
  //           '',
  //           sValue?.noteType,
  //           sValue?.noteReason,
  //           sValue?.subject,
  //           sValue?.description,
  //           sValue?.createdBy,
  //           sValue?.dateCreated,
  //           sValue?.status,
  //           '',
  //           ''
  //         )
  //       )
  //     )
  //     .subscribe(
  //       (response) => {
  //         response = response?.map((x, i = 1) => {
  //           const y = {
  //             sNo: ++i,
  //             customNoteType: noteTypeOptions?.filter(
  //               (a) => a?.key === x?.noteType?.toString()
  //             )?.[0]?.value,
  //             ...x,
  //           };
  //           return y;
  //         });
  //         if (this.data) {
  //           this.noteType = this.data?.pageType;
  //         }
  //         if (
  //           this.data?.pageType === NoteType.order ||
  //           this.noteType === NoteType.order
  //         ) {
  //           //To Load the Filter Dropdown
  //           response = response?.filter(
  //             (a) => a?.noteType?.toString() == 'order'
  //           );
  //         } else if (
  //           this.data?.pageType === NoteType.invoice ||
  //           this.noteType === NoteType.invoice
  //         ) {
  //           //To Load the Filter Dropdown
  //           response = response?.filter(
  //             (a) => a?.noteType?.toString() == 'invoice'
  //           );
  //         } else {
  //           //To Load the Filter Dropdown
  //           response = response?.filter(
  //             (a) =>
  //               a?.noteType?.toString() != 'invoice' &&
  //               a?.noteType?.toString() != 'order'
  //           );
  //         }

  //         response && response && this.setTableData(response);
  //         this.isShowProgress = false;
  //         this.isLoading = false;
  //       },
  //       (err) => {
  //         this.setTableData([]);
  //         this.isLoading = false;
  //         this.isShowProgress = false;
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );

  //   this.subscription$.push(valueChanges);
  // }


  getTableData() {
    this.isLoading = true;
    this.patientNotesService.getPatientNotesByPatientIdV1(this.patientId).subscribe(
      (response) => {
        this.isLoading = false;
        this.PatientNotesDetailsV1 = response['allNotes'] ?? [];
        // this.vpatientName = response['allNotes'][0].patientName;
        this.vpatientName = response['patientName'] ?? 'NA';
        this.vchartNo = response['chartId'] || 'N/A';
        this.vDescription = response['lastNoteDescription'] ?? '';
      },
      (error) => {
        const data: HttpErrorResponse = error;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isLoading = false;
      }
    );
  }


  // //Open Description(Notes) Model
  // openDescription(description: string) {
  //   this.vDescription = description;
  // }
  // setSelectedRow(index: number): void {
  //   this.selectedRowIndex = index;
  // }


  //Get Notes Table Data
  // getTableDataV1() {
  //   this.isLoading = true;
  //   this.patientNotesService.getPatientNotesByPatientIdV1(this.patientId).subscribe(
  //     (response) => {
  //       this.isLoading = false;
  //       this.PatientNotesDetailsV1 = response['allNotes'];

  //       this.vpatientName = response['allNotes'][0].patientName;
  //       this.vchartNo = response['allNotes'][0].chartNo;
  //       this.vDescription = response['allNotes'][0].description;


  //     },
  //     (error) => {
  //       console.error('Error fetching patient details:', error);
  //       this.isLoading = false;
  //     }
  //   );

  // }

  //To Add Open Note Modal
  openAddNotesPopUp(isCallNote: boolean = false) {

    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        // claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        // orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: this.patientId,
        defaultFaxId: this.data.defaultFaxId,
        isCallNote: isCallNote,
        isPending: false,
        docId: this.docId,
        inboundStatusShortCodeId: defaultGuid,
        selectedNode: this.selectedNode,
        chartNo: this.vchartNo,
        addnotes:false,
        patientAuthorizationId:this.data.patientAuthorizationId,
        patientVerificationId:this.data.patientVerificationId,
        patientMedicareChecklistId:this.data.patientMedicareChecklistId,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
        // this.getTableDataV1();
        // this.getTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    // this.$subscription.push(closeDialog);
  }

  getToolTip(data) {
    return data.description;
  }
  //On Destroy Function
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  NoteModal() {
    // const dialogRef = this.matDialog.open(AddNoteTabComponent, {
    const dialogRef = this.matDialog.open(PatientCommonNotesComponent, {
      disableClose: true,
      data: {
        noteType: this.noteType,
        notesId: '00000000-0000-0000-0000-000000000000',
        patientId: this.patientId,
        isCallNote: false,
        isPending: true,
        docId: this.docId,
        chartNo: this.vchartNo,
        // defaultFaxId:this.defaultFaxId,
        // inboundStatusShortCodeId:this.inboundStatusShortCodeId,
        // selectedNode: this.selectedNode,
      },
    });

    const closeDialog$ = dialogRef.afterClosed().subscribe(
      (result) => {
        this.getTableData();
        // this.getTableDataV1();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog$);
  }
  //To Open Note Modal
  openNoteModal(value: { notesId: string }) {
    const dialogRef = this.matDialog.open(AddNoteTabComponent, {
      disableClose: true,
      data: {
        patientId: this.patientId,
        notesId: value?.notesId ?? defaultGuid,
        orderId: defaultGuid,
        claimId: defaultGuid,
        noteType: this.noteType,
        pageType: this.noteType,
      },
    });

    const closeDialog$ = dialogRef.afterClosed().subscribe(
      (result) => {
        this.getTableData();
        // this.getTableDataV1();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog$);
  }

  //Set Table Data
  setTableData(order: any[]) {
    this.arrTableData = order;
    this.dataSource = new MatTableDataSource(this.arrTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  // UpdateNotes(notesId: string, isCallNote: boolean = false) {
  //   const config: MatDialogConfig = {
  //     disableClose: true,
  //     minHeight: '30vh',
  //     minWidth: '35vw',
  //     data: {
  //       noteType: 'patient',
  //       notesId: notesId,
  //       patientId: this.patientId,
  //       defaultFaxId: this.data.defaultFaxId,
  //       isCallNote: isCallNote,
  //       isPending: false,
  //       docId: this.docId,
  //       inboundStatusShortCodeId: defaultGuid,
  //       selectedNode: this.selectedNode,
  //       chartNo: this.vchartNo,
  //       addnotes:false,
  //     },
  //   };
  //   const dialogRef = this.dialog.open(AddNoteTabComponent, config);

  //   const closeDialog = dialogRef.afterClosed().subscribe(
  //     () => {
  //       this.getTableData();
  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.$subscription.push(closeDialog);
  // }
}
