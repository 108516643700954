import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, TemplateRef, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatListItem, MatSelectionListChange } from '@angular/material/list';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CookieService } from 'ngx-cookie-service';
import { doctorInterface } from 'projects/billing/src/app/billing-claim-info/billing-claim-info.component';
import { ProductService, ProductType } from 'projects/inventory/src/app/item-proxy/item-management/items';
import { itemInterface, productInterface } from 'projects/order/src/app/add-item/add-item.component';
import { MasterProviderService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-provider.service';
import { ItemDTO } from 'projects/order/src/app/order-proxy/order-management/order/dto';
import { SaleorderService } from 'projects/order/src/app/order-proxy/order-management/order/saleorder.service';
import { DiagnosisCode10Service, PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { AuthVisitsDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { DiagnosisCode10DTO, DocumentTypeDTO, GenderDTO, MachineTypeDTO, PayorLevelDTO, PlaceOfServiceDTO, SaleOrRentDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { AuthCategoryDTO, OrderMasterDropdownDTO, TypeOfPlanDTO } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/dto/order/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/master-dropdown-service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/rcm/dropdown-management/dropdowns/order-dropdowns.enum';
import { planInterface } from 'projects/patient/src/app/patient-add-policy/patient-add-policy.component';
import { PatientDoctorComponent } from 'projects/patient/src/app/patient-doctor/patient-doctor.component';
import { PatientIcd10Component } from 'projects/patient/src/app/patient-icd10/patient-icd10.component';
import { DoctorService, PayerService, PverifyPayersService } from 'projects/patient/src/app/patient-proxy/patient';
import { ComplainceDTO, CreateUpdateInsuranceDetailDTO, CreateUpdatePatientsDTO, DemographicsDTO, DiagnosticSleepStudyDTO, HomeSleepTestDTO, InitialFaceToFaceDTO, PatientDTO, PatientsDTO, PrescriptionDTO, SleepStudyDTO, TitrationSleepStudyDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { DXTypes } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/dxtypes.enum';
import { SetupTypes } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/setup-types.enum';
import { PatientsService } from 'projects/patient/src/app/patient-proxy/patient/patients.service';
import { baseUrl } from 'projects/patient/src/environments/environment';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';
import { PatientService } from '../../../../patient/src/app/patient-proxy/patient/patient.service';
import { AdminAddItemModalComponent } from '../admin-add-item-modal/admin-add-item-modal.component';
import { DocumentUploadsDTO, SortedDataDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/dto/models';
import { SortType, UploadedFileStatus } from '../admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { MasterRulesService } from '../admin-proxy/platform-app-management/rcm/platform-management/tenant';
import { MasterRulesDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/tenants/dto';
import { UploadFilesService } from '../admin-proxy/platform-app-management/rcm/platform-management/upload-file';
import { IdentifiedPatientListDTO, SortedData } from '../admin-proxy/platform-app-management/rcm/platform-management/upload-file/dto/models';
import { ReminderService } from '../dashboard/reminder.service';
import { DocumentEligibilityHistoryComponent } from '../document-eligibility-history/document-eligibility-history.component';
import { ExtractionaddpolciyComponent } from '../extractionaddpolciy/extractionaddpolciy.component';
export const defaultGuid: string = "00000000-0000-0000-0000-000000000000";
import { ToastrService } from "ngx-toastr";

export class FileNode {
  children: FileNode[];
  filename: string;
  type: any;
}

/** Flat node with expandable and level information */
export class FileFlatNode { constructor(public expandable: boolean, public filename: string, public level: number, public type: any) { } }

@Component({
  selector: 'app-document-perviewer',
  templateUrl: './document-perviewer.component.html',
  styleUrls: ['./document-perviewer.component.scss'],
  providers: [ListService],
})
export class DocumentPerviewerComponent implements OnInit, OnChanges {
  // saveButtonHide: boolean;
  approvedPatientId: string = "";
  isTreeViewEnabled: boolean = true;
  demoAccordion: boolean = false;
  lstAccordions: any[] = [];
  authorizationPendingValidationCount: number = 0;
  panelOpenState: boolean = false;
  isInsuranceVerification: boolean = false;
  ClinicalForm: FormGroup;
  fileId: string = '';
  expiryStatus: string = '';
  formArrayFeilds: FormArray;
  pageNumber: number = 0;
  pdfHeight: string = "80vh";
  ruleCheck: number = 1;
  isPdfLoadSpinner: boolean = false;
  step: boolean = true;
  drpGenderLoop: GenderDTO[];
  drpPlace: PlaceOfServiceDTO[] = [];
  public filteredItemTypes: Observable<itemInterface[]>;
  selFileUploadsInput: DocumentUploadsDTO | null;
  strSelectedPdfPath: string = '';
  strDocumentType: string = 'RX';
  PreviewExtractionForm: FormGroup;
  emailMask = emailMask ?? null;
  compliancePendingValidationCount: number = 0;
  demographicsPendingValidationCount: number = 0;
  initialFaceToFacePendingValidationCount: number = 0;
  prescriptionPendingValidationCount: number = 0;
  sleepStudyPendingValidationCount: number = 0;
  verificationPendingValidationCount: number = 0;
  OrderingDoctor: string = '';
  hideNotificatiaonButton: boolean = true;
  public filteredOrderingDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredMarRepDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredRenDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredMarDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredRefDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredPrimaryDoctorTypes: Observable<doctorInterface[]> | undefined;
  public filteredPractionerTypes: Observable<doctorInterface[]> | undefined;
  drpDoctor: { name: string; id: string }[] = [];
  drpPayerId: { id: string, name: string, payerCode: string, payerName }[] = [];
  drpDocumentTypeLoop: DocumentTypeDTO[] = [];
  @ViewChildren(MatExpansionPanel)
  matExpansionPanelQueryList: QueryList<MatExpansionPanel>;
  @ViewChildren(MatExpansionPanel) matlist: MatListItem;
  drpitem: productInterface[] = [];
  drpitems: any[] = [];
  public filtered1Icd10Types: Observable<any[]> | undefined;
  drpDiagnosisICD10: {
    diagnosisCode10ShortCodeId: string;
    diagnosisCode: string;
    diagnosisCodeTypeData: string;
    diagnosisCodeTypeDataId: string;
    id: string;
    creationTime: string | Date;
    creatorId: string;
    isActive: number;
    isExist: number;
  }[];
  lstIndex: SortedData[] = [];
  tenantId: string = '';
  savedButtonIconShow: boolean = false;
  blobName: string = '';
  drpLocationTypes: {
    id: string;
    organizationUnitName: string;
    tenantId: string;
  }[] = [];
  patientData: PatientDTO | null = null;
  height: string = '';
  organizationUnitId: string = '';
  Icd10: string = '';
  machineDetails: string = '';
  insuranceVerification: string = '';
  patientsId: string = '';
  patientId: string = '';
  insuranceType: string;
  payorLevel: PayorLevelDTO[] = [];
  drpMachines: MachineTypeDTO[] = [];
  drpDXType: any[] = [];
  drpPrdSetupType: any[] = [];
  drpSymptoms: any[] = [];
  txtItem: string = '';
  @Input() rulesValues: MasterRulesDTO[] = [];
  selectedRules: string[] = [];
  strFileId: string = '';
  isShowSpinner: boolean = false;
  saveButtonHide: boolean = false;
  fileApprovalStatus = UploadedFileStatus;
  drpItemCdoes: string[] = [];
  drpSaleOrRents: SaleOrRentDTO[] = [];
  itemCodes: string;
  sale: string;
  // drpAuthVisit: AuthVisitsDTO[] = [];
  // drpCategory: AuthCategoryDTO[] = [];
  //drpMarketingRef: MarketingReferralDTO[] = [];
  // drpAuthItem: ItemDTO[] = [];
  // drpPlan: TypeOfPlanDTO[] = [];

  ruleColor: {
    ctrlName1?: string,
    errValue1?: string,
    erStatus1?: boolean,
    ctrlName2?: string,
    errValue2?: string,
    erStatus2?: boolean,
    ctrlName3?: string,
    errValue3?: string,
    erStatus3?: boolean,
    ctrlName4?: string,
    errValue4?: string,
    erStatus4?: boolean,
    ctrlName5?: string,
    errValue5?: string,
    erStatus5?: boolean,
    ctrlName6?: string,
    errValue6?: string,
    erStatus6?: boolean,
    ctrlName7?: string,
    errValue7?: string,
    erStatus7?: boolean,
    ctrlName8?: string,
    errValue8?: string,
    erStatus8?: boolean,
    ctrlName9?: string,
    errValue9?: string,
    erStatus9?: boolean,
    ctrlName10?: string,
    errValue10?: string,
    erStatus10?: boolean,
    ctrlName11?: string,
    errValue11?: string,
    erStatus11?: boolean,
    ctrlName12?: string,
    errValue12?: string,
    erStatus12?: boolean,
    ctrlName13?: string,
    errValue13?: string,
    erStatus13?: boolean,
    ctrlName14?: string,
    errValue14?: string,
    erStatus14?: boolean,
    ctrlName15?: string,
    errValue15?: string,
    erStatus15?: boolean,
    ctrlName16?: string,
    errValue16?: string,
    erStatus16?: boolean,
    ctrlName17?: string,
    errValue17?: string,
    erStatus17?: boolean,
    ctrlName18?: string,
    errValue18?: string,
    erStatus18?: boolean,
    ctrlName19?: string,
    errValue19?: string,
    erStatus19?: boolean,
    ctrlName20?: string,
    errValue20?: string,
    erStatus20?: boolean,
    ctrlName21?: string,
    errValue21?: string,
    erStatus21?: boolean,
    ctrlName22?: string,
    errValue22?: string,
    erStatus22?: boolean,
    ctrlName23?: string,
    errValue23?: string,
    erStatus23?: boolean,
    ctrlName24?: string,
    errValue24?: string,
    erStatus24?: boolean,
    ctrlName25?: string,
    errValue25?: string,
    erStatus25?: boolean,
    ctrlName26?: string,
    errValue26?: string,
    erStatus26?: boolean,
    ctrlName27?: string,
    errValue27?: string,
    erStatus27?: boolean,
    ctrlName28?: string,
    errValue28?: string,
    erStatus28?: boolean,
    ctrlName29?: string,
    errValue29?: string,
    erStatus29?: boolean,
    ctrlName30?: string,
    errValue30?: string,
    erStatus30?: boolean,
    ctrlName31?: string,
    errValue31?: string,
    erStatus31?: boolean,
    ctrlName32?: string,
    errValue32?: string,
    erStatus32?: boolean,
    ctrlName33?: string,
    errValue33?: string,
    erStatus33?: boolean,
    ctrlName34?: string,
    errValue34?: string,
    erStatus34?: boolean,
    ctrlName35?: string,
    errValue35?: string,
    erStatus35?: boolean,
    ctrlName36?: string,
    errValue36?: string,
    erStatus36?: boolean,
    ctrlName37?: string,
    errValue37?: string,
    erStatus37?: boolean,
    ctrlName38?: string,
    errValue38?: string,
    erStatus38?: boolean,
    ctrlName39?: string,
    errValue39?: string,
    erStatus39?: boolean,
    ctrlName40?: string,
    errValue40?: string,
    erStatus40?: boolean,
    ctrlName41?: string,
    errValue41?: string,
    erStatus41?: boolean,
    ctrlName42?: string,
    errValue42?: string,
    erStatus42?: boolean,
    ctrlName43?: string,
    errValue43?: string,
    erStatus43?: boolean,
    ctrlName44?: string,
    errValue44?: string,
    erStatus44?: boolean,
    ctrlName45?: string,
    erStatus45?: boolean,
    errValue45?: string,
    ctrlName46?: string,
    erStatus46?: boolean,
    errValue46?: string,
    ctrlName47?: string,
    erStatus47?: boolean,
    errValue47?: string,
    ctrlName48?: string,
    erStatus48?: boolean,
    errValue48?: string,
    ctrlName49?: string,
    erStatus49?: boolean,
    errValue49?: string,
  } = null;
  isCompSA: number = 0;
  percentageOfCompletion: number = 0;
  identifiedPatientList: IdentifiedPatientListDTO[] = [];
  fileStatus: string = '';
  extraPtData: DemographicsDTO = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private patientDropdownService: PatientMasterDropdownService,
    private uploadFilesService: UploadFilesService,
    private numberValidators: NumberValidators,
    private planService: PverifyPayersService,
    private patientService: PatientService,
    private formBuilder: FormBuilder,
    private doctorService: DoctorService,
    public list: ListService,
    private diagnosis10: DiagnosisCode10Service,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private textValidators: TextValidators,
    private itemService: ProductService,
    private branchService: OrganizationUnitService,
    private patientsService: PatientsService,
    private tableService: TableService,
    private cookieService: CookieService,
    private ruleMasterService: MasterRulesService,
    private reminderService: ReminderService,
    private payerService: PayerService,
    private orderDropdownService: OrderMasterDropdownService,
    private providerList: MasterProviderService,
    private saleOrderService: SaleorderService,
    private toastr: ToastrService,
  ) {

  }

  ngOnChanges(_changes: SimpleChanges): void {

  }
  getPatientIdOnselect(value: string) {

    this.patientId = value ?? "";
  }
  sortedData: SortedDataDTO[] = [];
  selectionChange(value: MatSelectionListChange) {
    this.isPdfLoadSpinner = true;

    value?.options[0]?.value && this.previewDocumentById(value?.options[0]?.value)
  }
  ngOnInit(): void {
    this.reminderService?.clear();
    // this.tableService.loadDummyPatientData1().subscribe(response => {
    //   const responses: SortedDataDTO[] = response.sortedDocumentDetails;
    //   this.sortedData = responses;
    // })
    this.ruleMasterService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      const rules: MasterRulesDTO[] = response?.items ?? [];
      this.rulesValues = rules ?? [];
      this.rulesValues && this.checkRulesForFeildHighlight(this.rulesValues, SetupTypes.New, DXTypes.OSA);
    })
    this.tenantId = this.cookieService.get('__tenant') ?? '';
    this.activatedRoute?.paramMap?.subscribe((response) => {
      this.fileId = response?.get('fileId') ?? '';
      this.patientId = response?.get('patientId') ?? '';
    });
    this.PreviewExtractionForm = this.formBuilder.group({
      txtItemcode: new FormControl(''),
      txtSearchFilter: new FormControl(''),
      chkIsRulesNeeded: new FormControl(''),
      drpDocumentType: new FormControl(''),
      drpPrdSetupType: new FormControl(''),
      drpDXType: new FormControl(''),
      txtReason: new FormControl(''),
      numberOfDemographics: ['', Validators.required],
      Demographics: new FormArray([]),
      faceToface: new FormArray([]),
      sleepStudy: new FormArray([]),
      priscription: new FormArray([]),
      Complience: new FormArray([]),
      authorization: new FormArray([]),
      drpInsurance: new FormControl(''),

      ///newly added field for insurance
      drpPayorLevel: new FormControl(''),
      drpPayorName: new FormControl(''),
      txtMemberId: new FormControl(''),
      drpProvider: new FormControl(''),
      txtPayorFilter: new FormControl(''),
      txtProviderFilter: new FormControl(''),
      txtInsFilter: new FormControl(''),
      drpGeneralPlaceOfService: new FormControl(''),
      chkWorkProgressCompleted: new FormControl(''),
      // newly added in face to face
      txtFfbPatientName: new FormControl(''),
      txtFfbDob: new FormControl(''),
      txtFfbFaceToFace: new FormControl(''),
      chkSleepTestRecommended: new FormControl(''),
      chkSleepDoctorSigned: new FormControl(''),
      chkSleepPatientBenifited: new FormControl(''),
      txtFobPatientBenifitNotes: new FormControl(''),

      // newly add in Sleep study
      chkSleepStudyDoctorSigned: new FormControl(''),
      chkSleepStudyCentralApneas: new FormControl(''),
      chkSleepStudyCentralHyponeas: new FormControl(''),
      chkSleepStudyCentralAHI: new FormControl(''),
      chkSleepStudyApneaAndHypopnea: new FormControl(''),
      txtTitrationSleepStudyDate: new FormControl(''),
      chkCPAPTriedAndFail: new FormControl(''),
      chkRespiratoryBreakdownAvailable: new FormControl(''),
      chkTitrationSleepStudyDoctorSigned: new FormControl(''),
      chkisCPAPReturned: new FormControl(''),
      drpSymptoms: new FormControl(''),
      drpVerifyType: new FormControl(''),
      // newly added priscr
      txtprescriptionsDoctorSign: new FormControl(''),

      // new fiedls for authorization
      drpAuthInsurance: new FormControl(''),
      txtAuthId: new FormControl(''),
      drpAuthVisit: new FormControl(''),
      drpAuthAmount: new FormControl(''),
      drpCategory: new FormControl(''),
      txtStartDate: new FormControl(''),
      txtEndDate: new FormControl(''),
      chkCompleted: new FormControl(''),
      txtCompletedBy: new FormControl(''),
      txtUnits: new FormControl(''),
      txtUsed: new FormControl(''),
      txtSaleOrRent: new FormControl(''),
      txtExpired: new FormControl(''),
      chkFollowUp: new FormControl(''),
      txtItemCodes: new FormControl(''),
      txtNote: new FormControl(''),
    });

    this.PreviewExtractionForm.valueChanges.subscribe(response => {
      const txtEndDate = response?.txtEndDate ?? "";
      const strEndDate = ((txtEndDate ?? "") === '') ? "Not Mentioned" : txtEndDate;
      if (strEndDate) {
        this.expiryStatus = ((strEndDate === "Not Mentioned") ? strEndDate : (new Date() < new Date(strEndDate) ? 'Active' : 'Expired'));
      }
    })


    this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId') ?? '';
    this.getDropdown();

    // setTimeout(() => {
    if (
      this.drpLocationTypes && this.drpDiagnosisICD10 && this.drpDoctor && this.drpDocumentTypeLoop && this.drpitem) {
      this.fileId && this.getDocumentDeatilsByFileId(this.fileId);
    }
    // }, 1000);

    this.PreviewExtractionForm?.get('drpPrdSetupType').valueChanges.subscribe(response => {
      if (this.rulesValues && response) {
        const drpDXType: string = this.PreviewExtractionForm?.get('drpDXType').value ?? "";
        const drpPrdSetupType: string = this.PreviewExtractionForm?.get('drpPrdSetupType').value ?? "";
        this.rulesValues && this.checkRulesForFeildHighlight(this.rulesValues, drpPrdSetupType, drpDXType);
      }
    })
    this.PreviewExtractionForm?.get('drpDXType').valueChanges.subscribe(response => {
      if (this.rulesValues && response) {
        const drpDXType: string = this.PreviewExtractionForm?.get('drpDXType').value ?? "";
        const drpPrdSetupType: string = this.PreviewExtractionForm?.get('drpPrdSetupType').value ?? "";
        this.reminderService?.clear();
        if (this.PreviewExtractionForm?.get('drpDXType').value == "CSA" || this.PreviewExtractionForm?.get('drpDXType').value == "OSA") {
          this.hideFullNotificataion = false;

        }
        else {
          this.hideFullNotificataion = true;
        }
        this.rulesValues && this.checkRulesForFeildHighlight(this.rulesValues, drpPrdSetupType, drpDXType);

      }
    })
    this.hideNotificatiaonButton = true;
  }

  //! highlist feilds based on rules
  checkRulesForFeildHighlight(_rulesValues: MasterRulesDTO[], drpPrdSetupType: string, drpDXType: string) {
    this.reminderService?.clear();
    this.ruleColor = null;
    this.selectedRules = [];

    this.rulesValues.forEach(e => {
      if (e?.setupType === SetupTypes.New && drpPrdSetupType === SetupTypes.New && e?.dxType === DXTypes.OSA && drpDXType === DXTypes.OSA) {
        if (e?.ruleName === "Symptoms of sleep apnea is must in face to face") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName1: "drpSymptoms", erStatus1: e?.isCheckValidation ?? false, errValue1: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Sleep test recommendation is must in face to face") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName2: "chkSleepTestRecommended", erStatus2: e?.isCheckValidation ?? false, errValue2: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Doctor Signature in face to face is must") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName3: "chkSleepDoctorSigned", erStatus3: e?.isCheckValidation ?? false, errValue3: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Diagnostic Sleep study date is must after the Face to Face") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName4: "txtDiagnosisStudyDate", erStatus4: e?.isCheckValidation ?? false, errValue4: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Total sleep time more than 120") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName5: "txtSleepMinutes", erStatus5: e?.isCheckValidation ?? false, errValue5: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "AHI value should be greater than or equal to 5") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName6: "txtAHI", erStatus6: e?.isCheckValidation ?? false, errValue6: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName6: "txtHSAHI", erStatus6: e?.isCheckValidation ?? false, errValue6: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Doctor Signature in diagnostic sleep study") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName7: "chkSleepStudyDoctorSigned", erStatus7: e?.isCheckValidation ?? false, errValue7: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "CPAP tried and failed must in titration sleep study for BIPAP product") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName8: "chkCPAPTriedAndFail", erStatus8: e?.isCheckValidation ?? false, errValue8: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Respiratory breakdowns is must") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName9: "chkRespiratoryBreakdownAvailable", erStatus9: e?.isCheckValidation ?? false, errValue9: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Doctor signature in titration study is must") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName10: "chkTitrationSleepStudyDoctorSigned", erStatus10: e?.isCheckValidation ?? false, errValue10: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Patient Signature in Home Sleep test instruction sheet") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName11: "chkHSpateintSigned", erStatus11: e?.isCheckValidation ?? false, errValue11: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "RX date after sleep study date") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName12: "txtPrescriptionDate", erStatus12: e?.isCheckValidation ?? false, errValue12: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Face to face date is valid for 365 days") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName13: "txtIobFadeToFace", erStatus13: e?.isCheckValidation ?? false, errValue13: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Sleep study date should be valid for 365 days") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName14: "txtDiagnosisStudyDate", erStatus14: e?.isCheckValidation ?? false, errValue14: e?.ruleName ?? "", ...this.ruleColor };
        }
      } else if (e?.setupType === SetupTypes.Replacement && drpPrdSetupType === SetupTypes.Replacement && e?.dxType === DXTypes.OSA && drpDXType === DXTypes.OSA) {
        if (e?.ruleName === "Current Face to Face date is valid within one year") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName15: "txtIobFadeToFace", erStatus15: e?.isCheckValidation ?? false, errValue15: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Usage and benefits of PAP needs to be mentioned is must in face to face") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName16: "chkSleepPatientBenifited", erStatus16: e?.isCheckValidation ?? false, errValue16: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Doctor signature is must in face to face") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName17: "chkSleepDoctorSigned", erStatus17: e?.isCheckValidation ?? false, errValue17: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Doctor signature in diagnostic sleep study is must") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName18: "chkSleepStudyDoctorSigned", erStatus18: e?.isCheckValidation ?? false, errValue18: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "RX after the current face to face notes") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName19: "txtPrescriptionDate", erStatus19: e?.isCheckValidation ?? false, errValue19: e?.ruleName ?? "", ...this.ruleColor };
        }
      } else if (e?.setupType === SetupTypes.New && e?.setupType === drpPrdSetupType && e?.dxType === DXTypes.CSA && drpDXType === DXTypes.CSA) {
        if (e?.ruleName === "Symptoms of sleep apnea is must in face to face") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName20: "drpSymptoms", erStatus20: e?.isCheckValidation ?? false, errValue20: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Sleep test recommendation is must in face to face") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName21: "chkSleepTestRecommended", erStatus21: e?.isCheckValidation ?? false, errValue21: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Doctor Signature in face to face is must") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName22: "chkSleepDoctorSigned", erStatus22: e?.isCheckValidation ?? false, errValue22: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Diagnostic sleep study is valid for 365 days") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName23: "txtDiagnosisStudyDate", erStatus23: e?.isCheckValidation ?? false, errValue23: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "AHI value is greater than or equal to 5") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName24: "txtAHI", erStatus24: e?.isCheckValidation ?? false, errValue24: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName25: "txtHSAHI", erStatus25: e?.isCheckValidation ?? false, errValue25: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Sum of central apneas and central hypopneas needs to be greater than 50% of total apneas and hypopneas") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName27: "txtSSCentralApneas", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName27: "txtSSCentralHyponeas", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName27: "txtSSApneaAndHypopnea", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName26: "txtHSCentralApneas", erStatus26: e?.isCheckValidation ?? false, errValue26: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName26: "txtHSCentralHyponeas", erStatus26: e?.isCheckValidation ?? false, errValue26: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName26: "txtHSApneaAndHypopnea", erStatus26: e?.isCheckValidation ?? false, errValue26: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Sum of central apnea and central hypopnea (central AHI) value needs to be greater than or equal to 5") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName27: "txtSSCentralApneas", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName27: "txtSSCentralHyponeas", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName27: "txtSSApneaAndHypopnea", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName26: "txtHSCentralApneas", erStatus26: e?.isCheckValidation ?? false, errValue26: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName26: "txtHSCentralHyponeas", erStatus26: e?.isCheckValidation ?? false, errValue26: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName26: "txtHSApneaAndHypopnea", erStatus26: e?.isCheckValidation ?? false, errValue26: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "RX date after sleep study date") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName28: "txtPrescriptionDate", erStatus28: e?.isCheckValidation ?? false, errValue28: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Face to face date should be valid for 365 days") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName40: "txtIobFadeToFace", erStatus40: e?.isCheckValidation ?? false, errValue40: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Doctor Signature in diagnostic sleep study is must") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName44: "txtPrescriptionDate", erStatus44: e?.isCheckValidation ?? false, errValue44: e?.ruleName ?? "", ...this.ruleColor };
        }
      } else if (e?.setupType === SetupTypes.Replacement && e?.setupType === drpPrdSetupType && drpDXType === e?.dxType && e?.dxType === DXTypes.CSA) {
        if (e?.ruleName === "Face to face notes valid only within 365 days") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName29: "txtIobFadeToFace", erStatus29: e?.isCheckValidation ?? false, errValue29: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Benefits and usage of PAP should be mentioned in face to face") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName30: "chkSleepPatientBenifited", erStatus30: e?.isCheckValidation ?? false, errValue30: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Doctor signature is must in face to face") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName31: "chkSleepDoctorSigned", erStatus31: e?.isCheckValidation ?? false, errValue31: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "AHI value should be greater than or equal to 5") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName32: "txtAHI", erStatus32: e?.isCheckValidation ?? false, errValue32: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName32: "txtHSAHI", erStatus32: e?.isCheckValidation ?? false, errValue32: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Sum of central apneas and central hypopneas needs to be greater than 50% of total apneas and hypopneas") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName27: "txtSSCentralApneas", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName27: "txtSSCentralHyponeas", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName27: "txtSSApneaAndHypopnea", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName26: "txtHSCentralApneas", erStatus26: e?.isCheckValidation ?? false, errValue26: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName26: "txtHSCentralHyponeas", erStatus26: e?.isCheckValidation ?? false, errValue26: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName26: "txtHSApneaAndHypopnea", erStatus26: e?.isCheckValidation ?? false, errValue26: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Sum of central apnea and central hypopnea (central AHI) value needs to be greater than or equal to 5") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName27: "txtSSCentralApneas", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName27: "txtSSCentralHyponeas", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName27: "txtSSApneaAndHypopnea", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName26: "txtHSCentralApneas", erStatus26: e?.isCheckValidation ?? false, errValue26: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName26: "txtHSCentralHyponeas", erStatus26: e?.isCheckValidation ?? false, errValue26: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName26: "txtHSApneaAndHypopnea", erStatus26: e?.isCheckValidation ?? false, errValue26: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Doctor signature is must in diagnostic sleep study") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName35: "chkSleepStudyDoctorSigned", erStatus35: e?.isCheckValidation ?? false, errValue35: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "RX date after face to face date") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName36: "txtPrescriptionDate", erStatus36: e?.isCheckValidation ?? false, errValue36: e?.ruleName ?? "", ...this.ruleColor };
        }
      } else if (e?.dxType === DXTypes.CPAPtoBIPAP && e?.dxType === drpDXType) {
        if (e?.ruleName === "Titration sleep study date is valid for 365 days") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName37: "txtTitrationSleepStudyDate", erStatus37: e?.isCheckValidation ?? false, errValue37: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "CPAP tried and ineffective for patient mentioned in titration sleep study") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName38: "chkCPAPTriedAndFail", erStatus38: e?.isCheckValidation ?? false, errValue38: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Respiratory breakdowns is must in titration sleep study") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName39: "chkRespiratoryBreakdownAvailable", erStatus39: e?.isCheckValidation ?? false, errValue39: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Face to face date is Valid for 365 days") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName40: "txtIobFadeToFace", erStatus40: e?.isCheckValidation ?? false, errValue40: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Baseline file is must") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName41: "chkTSSBaselineFile", erStatus41: e?.isCheckValidation ?? false, errValue41: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "RX date should be Valid for 365 days") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName42: "txtPrescriptionDate", erStatus42: e?.isCheckValidation ?? false, errValue42: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Face to face notes must prior to sleep study and RX") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName43: "txtIobFadeToFace", erStatus43: e?.isCheckValidation ?? false, errValue43: e?.ruleName ?? "", ...this.ruleColor };
        }
      } else if (e?.dxType === DXTypes.CompSA && e?.dxType === drpDXType) {

        if (e?.ruleName === "Titration sleep study date is valid for 365 days") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName45: "txtTitrationSleepStudyDate", erStatus45: e?.isCheckValidation ?? false, errValue45: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "AHI value is greater than or equal to 5 in titration study") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName32: "txtAHI", erStatus32: e?.isCheckValidation ?? false, errValue32: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName32: "txtHSAHI", erStatus32: e?.isCheckValidation ?? false, errValue32: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName32: "txtTAHI", erStatus32: e?.isCheckValidation ?? false, errValue32: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Sum of central apneas and central hypopneas needs to be greater than 50 % of total apneas and hypopneas in titration study") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName27: "txtTCentralApneas", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName27: "txtTApneaAndHypopnea", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName27: "txtTCentralHyponeas", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Sum of central apnea and central hypopnea (central AHI) value needs to be greater than or equal to 5 in titration study") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName27: "txtTCentralApneas", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName27: "txtTApneaAndHypopnea", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
          this.ruleColor = { ctrlName27: "txtTCentralHyponeas", erStatus27: e?.isCheckValidation ?? false, errValue27: e?.ruleName ?? "", ...this.ruleColor };
        }
        if (e?.ruleName === "Obstructive apnea should be less than 5 in titration study") {
          e?.isCheckValidation && this.selectedRules.push(e?.id);
          this.ruleColor = { ctrlName49: "txtObstructiveApnea", erStatus49: e?.isCheckValidation ?? false, errValue49: e?.ruleName ?? "", ...this.ruleColor };
        }
      }
    })

    if (this.selectedRules.length === 0) {
      this.hideFullNotificataion = false;
    }
  }

  //! open search modal
  openSearchModal() {
    const dialogRef = this.dialog.open(ExtractionaddpolciyComponent, {
      disableClose: true,
      data: {
        fileId: this.fileId,
        batchId: this.patientsId,
        patientData: this.patientData,
      },
    });
    dialogRef.afterClosed().subscribe(
      () => {
        this.fileId &&
          this.patientsId &&
          this.getDocumentDeatilsByFileId(this.fileId);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  //! get PatientId Form Patient Details
  getPatientIdFormPatientDetails(_patientId: string) {
    this.patientService.get(this.patientId).subscribe(response => {
      let pateintData: { fname: string, mname: string, lname: string, dob: string, gender: string } | null = {
        fname: response?.personals?.firstName,
        lname: response?.personals?.lastName,
        mname: response?.personals?.middleName,
        gender: response?.personals?.gender,
        dob: response?.personals?.dateOfBirth
      };
      this.displayEmptyDataofFile(pateintData);
    }, (err) => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  // onchange function for authorization
  onAuthPayorsChanges(event) {
    if (this.patientsId !== null || this.patientsId !== "" || this.patientsId !== undefined) {
      this.patientsService.get(this.patientsId).subscribe((res) => {
        const primaryAuth = res?.authorizations?.primaryAuthorizations;
        const secondaryAuth = res?.authorizations?.secondaryAuthorizations;
        const tertiaryAuth = res?.authorizations?.tertiaryAuthorizations;
        if (event?.value === 'Primary') {
          if (primaryAuth?.plan === event?.value) {
            this.PreviewExtractionForm?.patchValue({
              drpAuthInsurance: primaryAuth?.plan,
              txtAuthId: primaryAuth?.authId,
              drpAuthVisit: primaryAuth?.authVisits,
              drpAuthAmount: primaryAuth?.authAmount,
              drpCategory: primaryAuth?.category,
              txtStartDate: primaryAuth?.startDate,
              txtEndDate: primaryAuth?.endDate,
              chkCompleted: primaryAuth?.isCompleted,
              txtCompletedBy: primaryAuth?.completedBy,
              txtNote: primaryAuth?.note,
              txtUnits: primaryAuth?.units,
              txtUsed: primaryAuth?.used,
              txtSaleOrRent: primaryAuth?.saleOrRent,
              txtExpired: primaryAuth?.isExpired,
              chkFollowUp: primaryAuth?.isFollowUp,
              txtItemCodes: primaryAuth?.itemCodes
            });
            let primaryAuthEndDate = ((primaryAuth?.endDate ?? "") === '') ? "Not Mentioned" : primaryAuth?.endDate;
            this.expiryStatus = ((primaryAuthEndDate === "Not Mentioned") ? primaryAuthEndDate : (new Date() < new Date(primaryAuthEndDate) ? 'Active' : 'Expired'));
          } else {
            this.PreviewExtractionForm?.patchValue({
              txtAuthId: "",
              drpAuthVisit: "",
              drpAuthAmount: "",
              drpCategory: "",
              txtStartDate: "",
              txtEndDate: "",
              chkCompleted: "",
              txtCompletedBy: "",
              txtNote: "",
              txtUnits: "",
              txtUsed: "",
              txtSaleOrRent: "",
              txtExpired: 0,
              chkFollowUp: 0,
              txtItemCodes: ""
            })
          }
        }

        //// dropdown value secondary and binding saved datas
        if (event?.value === 'Secondary') {
          if (secondaryAuth?.plan === event?.value) {
            this.PreviewExtractionForm?.patchValue({
              drpAuthInsurance: secondaryAuth?.plan,
              txtAuthId: secondaryAuth?.authId,
              drpAuthVisit: secondaryAuth?.authVisits,
              drpAuthAmount: secondaryAuth?.authAmount,
              drpCategory: secondaryAuth?.category,
              txtStartDate: secondaryAuth?.startDate,
              txtEndDate: secondaryAuth?.endDate,
              chkCompleted: secondaryAuth?.isCompleted,
              txtCompletedBy: secondaryAuth?.completedBy,
              txtNote: secondaryAuth?.note,
              txtUnits: secondaryAuth?.units,
              txtUsed: secondaryAuth?.used,
              txtSaleOrRent: secondaryAuth?.saleOrRent,
              txtExpired: secondaryAuth?.isExpired,
              chkFollowUp: secondaryAuth?.isFollowUp,
              txtItemCodes: secondaryAuth?.itemCodes
            });
            let secondaryAuthEndDate = ((secondaryAuth?.endDate ?? "") === '') ? "Not Mentioned" : secondaryAuth?.endDate;
            this.expiryStatus = ((secondaryAuthEndDate === "Not Mentioned") ? secondaryAuthEndDate : (new Date() < new Date(secondaryAuthEndDate) ? 'Active' : 'Expired'));
          } else {
            this.PreviewExtractionForm?.patchValue({
              txtAuthId: "",
              drpAuthVisit: "",
              drpAuthAmount: "",
              drpCategory: "",
              txtStartDate: "",
              txtEndDate: "",
              chkCompleted: "",
              txtCompletedBy: "",
              txtNote: "",
              txtUnits: "",
              txtUsed: "",
              txtSaleOrRent: "",
              txtExpired: 0,
              chkFollowUp: 0,
              txtItemCodes: ""
            })
          }
        }

        //// dropdown value tertiary and binding saved datas
        if (event?.value === 'Tertiary') {
          if (
            tertiaryAuth?.plan === event?.value) {
            this.PreviewExtractionForm?.patchValue({
              drpAuthInsurance: tertiaryAuth?.plan,
              txtAuthId: tertiaryAuth?.authId,
              drpAuthVisit: tertiaryAuth?.authVisits,
              drpAuthAmount: tertiaryAuth?.authAmount,
              drpCategory: tertiaryAuth?.category,
              txtStartDate: tertiaryAuth?.startDate,
              txtEndDate: tertiaryAuth?.endDate,
              chkCompleted: tertiaryAuth?.isCompleted,
              txtCompletedBy: tertiaryAuth?.completedBy,
              txtNote: tertiaryAuth?.note,
              txtUnits: tertiaryAuth?.units,
              txtUsed: tertiaryAuth?.used,
              txtSaleOrRent: tertiaryAuth?.saleOrRent,
              txtExpired: tertiaryAuth?.isExpired,
              chkFollowUp: tertiaryAuth?.isFollowUp,
              txtItemCodes: tertiaryAuth?.itemCodes
            });
            let tertiaryAuthEndDate = ((tertiaryAuth?.endDate ?? "") === '') ? "Not Mentioned" : tertiaryAuth?.endDate;
            this.expiryStatus = ((tertiaryAuthEndDate === "Not Mentioned") ? tertiaryAuthEndDate : (new Date() < new Date(tertiaryAuthEndDate) ? 'Active' : 'Expired'));
          } else {
            this.PreviewExtractionForm?.patchValue({
              txtAuthId: "",
              drpAuthVisit: "",
              drpAuthAmount: "",
              drpCategory: "",
              txtStartDate: "",
              txtEndDate: "",
              chkCompleted: "",
              txtCompletedBy: "",
              txtNote: "",
              txtUnits: "",
              txtUsed: "",
              txtSaleOrRent: "",
              txtExpired: 0,
              chkFollowUp: 0,
              txtItemCodes: ""
            })
          }
        }

      })


    }
  }
  // close all Rule function
  hideFullNotificataion: boolean = true;
  closeAllRule() {
    this.hideFullNotificataion = false;
  }

  //! Get Document details by file id
  mergedDocumentDetails: any[] = [];
  getDocumentDeatilsByFileId(fileId: string) {
    this.isPdfLoadSpinner = true;
    this.prescriptionLength = [];
    this.sleepStudyLength = [];
    this.complienceLength = [];
    this.demoLength = [];
    this.uploadFilesService
      .getUploadedFileByIdByFileId(fileId)
      .subscribe((response) => {
        this.approvedPatientId = response?.approvedPatientId ?? "";
        this.approvedPatientId != null && this.approvedPatientId != undefined && this.approvedPatientId != "" &&
          this.saleOrderService.getAllItemCodesByPatientId(this.approvedPatientId).subscribe(response => {
            this.drpItemCdoes = response;
          });

        this.strFileId = response?.defaultFileId ?? "";
        this.patientsId = response?.patientsId ?? "";
        this.identifiedPatientList = response?.identifiedPatientList ?? [];
        this.fileStatus = response?.overallStatus ?? "";
        this.fileId = fileId ?? "";
        this.patientId = response?.approvedPatientId ?? "";
        this.extraPtData = response?.extractedPatientDetails ?? null;
        //// To bind merrge doc details from uploaded file
        this.mergedDocumentDetails = response?.mergedDocumentDetails;
        if (this.patientsId !== "" && this.fileId !== "" && (this.patientId !== "" || this.patientId === "")) {
          //! get patients details form patients collection
          setTimeout(() => {
            this.patientsId !== "" && this.fileId !== "" && (this.patientId !== "" || this.patientId === "") && this.getPatientSIdFormPatientSDetails(this.patientsId);
          }, 1000);
        } else if (this.patientId === "" && this.fileId !== "" && this.patientsId !== "") {
          //! get patient details form patient collection
          this.patientId !== "" && this.fileId !== "" && this.patientsId === "" && this.getPatientIdFormPatientDetails(this.patientId);
        } else if (this.patientsId !== "" && this.fileId !== "" && this.patientId === "" && response?.extractedPatientDetails !== undefined && response?.extractedPatientDetails !== null && response?.extractedPatientDetails) {
          const ExData: DemographicsDTO = response?.extractedPatientDetails;
          this.displayEmptyDataofFile({ fname: ExData?.firstName ?? "", lname: ExData?.lastName ?? "", dob: ExData?.dateOfBirth ?? "", mname: ExData?.middleName ?? "", gender: ExData?.gender ?? "" });
        } else {
          //! for new patients data to display
          this.displayEmptyDataofFile({ fname: "", mname: "", lname: "", dob: "", gender: "" });
        }

        response && response?.blobName && this.previewDocumentById(response?.blobName);
        this.lstIndex = response?.sortedDocumentDetails ?? [];
        // response?.extractedPatientDetails?.forEach((element) => {
        //   if (element?.fileId === fileId) {
        //     element?.lstIndex?.forEach((count) => {
        //       if (count.documentType === 'DEMOGRAPHICS') {
        //         this.demoLength.push({
        //           documentType: count.documentType,
        //           header: count.header,
        //         });
        //       }
        //       if (count.documentType === 'COMPLIANCE REPORT') {
        //         this.complienceLength.push({
        //           documentType: count.documentType,
        //           header: count.header,
        //         });
        //       }
        //       if (count.documentType === 'SLEEP STUDY REPORT') {
        //         this.sleepStudyLength.push({
        //           documentType: count.documentType,
        //           header: count.header,
        //         });
        //       }
        //       if (count.documentType === 'RX') {
        //         this.prescriptionLength.push({
        //           documentType: count.documentType,
        //           header: count.header,
        //         });

        //       }
        //     });

        //     if (
        //       this.demoLength.length ===
        //       this.PreviewExtractionForm.value.Demographics.length + 1 ||
        //       this.demoLength.length <
        //       this.PreviewExtractionForm.value.Demographics.length
        //     ) {
        //       this.demoHide = true;
        //     }
        //     if (
        //       this.complienceLength.length ===
        //       this.PreviewExtractionForm.value.faceToface.length + 1 ||
        //       this.complienceLength.length <
        //       this.PreviewExtractionForm.value.faceToface.length
        //     ) {
        //       this.faceHide = true;
        //     }        //     if (
        //       this.sleepStudyLength.length ===
        //       this.PreviewExtractionForm.value.sleepStudy.length + 1 ||
        //       this.sleepStudyLength.length <
        //       this.PreviewExtractionForm.value.sleepStudy.length
        //     ) {
        //       this.sleepHide = true;
        //     }
        //     if (
        //       this.prescriptionLength.length ===
        //       this.PreviewExtractionForm.value.priscription.length + 1 ||
        //       this.prescriptionLength.length <
        //       this.PreviewExtractionForm.value.priscription.length
        //     ) {
        //       this.presHide = true;
        //     }        //     if (
        //       this.complienceLength.length ===
        //       this.PreviewExtractionForm.value.Complience.length + 1 ||
        //       this.complienceLength.length <
        //       this.PreviewExtractionForm.value.Complience.length
        //     ) {
        //       this.complHide = true;
        //     }        //     this.patientsId =
        //       element?.patientsId ?? defaultGuid;

        //     this.lstIndex = element?.lstIndex ?? [];

        //     this.selFileUploadsInput = element ?? null;
        //     const patientData: PatientDTO =
        //       element?.extractedData?.extractedfields ?? null;

        //     if (this.patientsId === defaultGuid) {
        //       this.displayEmptyDataofFile()
        //       // patientData &&
        //       //   patientData !== null &&
        //       //   this.displayEmptyDataofFile(patientData);
        //     } else {
        //       this.patientsGetById();
        //     }
        //   }        // });
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
  }

  /// approve demographics
  aprveOrDisAprvePat(status: UploadedFileStatus) {
    const demoValues = this.getDemographicsValues();
    const patientsId = this.patientsId ?? "";
    const firstName = demoValues[0]?.txtFirstName ?? "";
    const lastName = demoValues[0]?.txtLastName ?? "";
    const middleName = demoValues[0]?.txtMiddleName ?? "";
    const dob = (demoValues[0]?.txtDOB === '') ? null : (new Date(demoValues[0]?.txtDOB).toISOString() ?? null);
    if (this.pateintDemofromFormCheck(status)) {
      return
    }
    this.patientsService.updatePatientBasicDetailsByPatientsIdAndFirstNameAndLastNameAndMiddleNameAndDob(patientsId, firstName, lastName, middleName, dob).subscribe(() => {
      this.doApprove(status);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  doApprove(status: UploadedFileStatus) {
    const strReason: string = this.PreviewExtractionForm.value.txtReason ?? " ";
    const strStatus = ((status === UploadedFileStatus.Rejected) ? 'Disapprove' : 'Approve');
    Swal.fire({
      title: 'Are you sure you want to ' + strStatus + ' ?',
      text: status === "Rejected" ? "You can change this anytime!" : "You won't be able to revert this change!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, ' + strStatus + ' it!',
    }).then((result) => {
      if (result.value) {
        if (this.patientId === '') {
          this.patientsService.approvePatientByFileIdAndPatientsIdAndPatientIdAndStatusAndReasonForApproveOrRejected(this.fileId, this.patientsId, null, status, strReason).subscribe(_response => {
            Swal.fire({
              title: 'Success',
              html: strStatus + ' Successfully',
              icon: 'success',
              timer: 3000,
              timerProgressBar: true,
            });
            this.getDocumentDeatilsByFileId(this.fileId);
          }, (err: HttpErrorResponse) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
        }
        if (this.patientId !== '') {
          this.patientsService.approvePatientByFileIdAndPatientsIdAndPatientIdAndStatusAndReasonForApproveOrRejected(this.fileId, this.patientsId, this.patientId, status, strReason).subscribe(_response => {
            Swal.fire({
              title: 'Success',
              html: strStatus + ' Successfully',
              icon: 'success',
              timer: 3000,
              timerProgressBar: true,
            });
            this.getDocumentDeatilsByFileId(this.fileId);

          }, (err: HttpErrorResponse) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          });
        }
      }
    });
  }

  doctorDropdown(): void {
    this.drpDoctor = [];
    const itemList = (query: any) => this.doctorService.getList(query);
    this.list.hookToQuery(itemList).subscribe(
      (response) => {
        response?.items?.forEach((element) => {
          this.drpDoctor?.push({
            name:
              element['npiNumber'] +
              ' / ' +
              element?.firstName +
              ' ' +
              element?.middleName +
              ' ' +
              element?.lastName,
            id: element?.id ?? '',
          });
        });
        this.filteredOrderingDoctorTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtOrdSearchFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredPractionerTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtPractitionerSearchFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredMarRepDoctorTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtMarRepSearchFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredRenDoctorTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtRenDoctorFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredRefDoctorTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtRefDoctorFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredMarDoctorTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtMarDoctorFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredPrimaryDoctorTypes = this.ClinicalForm.get(
          'ClinicalGeneral.txtPrimaryDoctorSearchFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      },
      () => { }
    );
    // this.doctorService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
    //   response?.items?.forEach(element => {
    //     this.drpDoctor?.push({
    //       name: element['npiNumber'] + ' / ' + element?.firstName + " " + element?.middleName + " " + element?.lastName,
    //       id: element?.id
    //     });
    //   });
    // }, () => {
    // });
  }

  //!Open AddPolicy Modal
  openPolicyModal() {
    const dialogRef = this.dialog.open(ExtractionaddpolciyComponent, {
      disableClose: true,
      data: {
        fileId: this.fileId,
        batchId: this.patientsId,
        patientData: this.patientData,
      },
    });
    dialogRef.afterClosed().subscribe(
      () => {
        this.fileId &&
          this.patientsId &&
          this.getDocumentDeatilsByFileId(this.fileId);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }


  demoLength: any[] = [];
  faceToFceLength: any[] = [];
  sleepStudyLength: any[] = [];
  prescriptionLength: any[] = [];
  complienceLength: any[] = [];


  //! Display extracted data of file in form
  displayExtractedDataofFile(pateintData: PatientDTO) {
    let patientArr: any[] = [];
    let patientPushArr: any[] = [];

    let clinicalArr: any[] = [];
    let clinicalPushArr: any[] = [];

    let FaceToFaceArr: any[] = [];
    let FaceToFacePushArr: any[] = [];

    let SleepArr: any[] = [];
    let SleepPushArr: any[] = [];

    let ComplienceArr: any[] = [];
    let CompliencePushArr: any[] = [];

    const responseHeightFeet =
      pateintData?.clinicals?.height === 0
        ? ''
        : String(pateintData?.clinicals?.height).split('.', 2)[0] ?? '';

    //! demo details initial patch
    this.demo().clear();
    if (pateintData?.personals) {
      patientArr?.push(pateintData?.personals);
      patientArr?.map((d) => {
        let newDemoData = {
          txtFirstName: d?.firstName,
          txtDOB: d?.dateOfBirth,
          txtMiddleName: d?.middleNamen,
          txtLastName: d?.lastName,
          drpGeneralGender: d?.gender
        };
        patientPushArr?.push(newDemoData);
        var demo: FormGroup = this.newDemo();
        this.demo()?.push(demo);
      });
      this.PreviewExtractionForm?.patchValue({ Demographics: patientPushArr });
      //! prescription details initial patch
      this.Prescription().clear();
      clinicalArr?.push(pateintData?.clinicals);
      clinicalArr?.map((d) => {
        let newDemoData = {
          txtPatientName2: pateintData?.personals.fullName,
          txtDOB2: pateintData?.personals.dateOfBirth,
          txtPrescriptionDate: d?.prescriptionDate,
          txtprescriptionsDoctorSign: d?.doctorSignature,
          txtDoctor: d?.orderingDoctor,
          txtNpiNumber: d?.npiNumber,
          txtSignedDate: d?.doctorSignedDate,
          txtDuration: d?.duration,
          txtEstimatedLength: d?.estimatedLength,
          txtSupplierName: d?.supplierName,
          txtdiagnosisCode10Id1: d?.diagnosisCode10,
        };
        clinicalPushArr?.push(newDemoData);
        var Prescription: FormGroup = this.newPrescription();
        this.Prescription()?.push(Prescription);
      });
      this.PreviewExtractionForm?.patchValue({ priscription: clinicalPushArr });
      this.FaceToFace().clear();
      //! Face to face details initial patch
      FaceToFaceArr?.push(pateintData?.clinicals);
      FaceToFaceArr?.map((d) => {
        let newDemoData = {
          txtDateofVisit: d?.dateOfVisit,
          txtPatientinfo: pateintData?.personals.fullName,
          txtHeightFeet: responseHeightFeet,
          txtWeight: d?.weight,
          txtDoctor: d?.orderingDoctor,
          txtNpiNumber: d?.npiNumber,
          txtSignature: d?.doctorSignature,
          txtSignedDate: d?.doctorSignedDate,
        };
        FaceToFacePushArr?.push(newDemoData);
        var FaceToFace: FormGroup = this.newFaceToFace();
        this.FaceToFace()?.push(FaceToFace);
      });
      this.PreviewExtractionForm?.patchValue({ faceToface: FaceToFacePushArr });

      //! sleep study details initial patch
      SleepArr?.push(pateintData?.clinicals);
      this.sleepStudies().clear();

      SleepArr?.map((d) => {
        let newDemoData = {
          txtPatientName1: pateintData?.personals.fullName,
          txtDob: pateintData?.personals.dateOfBirth,
          txtdiagnosisCode10Id: d?.diagnosisCode10,
        };
        SleepPushArr?.push(newDemoData);
        var sleepStudies: FormGroup = this.newsleepStudies();
        this.sleepStudies()?.push(sleepStudies);
      });
      this.PreviewExtractionForm?.patchValue({ sleepStudy: SleepPushArr });

      //! Complience details initial patch
      ComplienceArr?.push(pateintData?.clinicals);
      this.Complience().clear();

      ComplienceArr?.map(() => {
        let newDemoData = {
          txtPatientName3: pateintData?.personals.fullName,
          txtDOB3: pateintData?.personals.dateOfBirth,

        };
        CompliencePushArr?.push(newDemoData);
        var Complience: FormGroup = this.newComplience();
        this.Complience()?.push(Complience);
      });
      this.PreviewExtractionForm?.patchValue({ Complience: CompliencePushArr });


      this.patientData = pateintData ?? null;
    }
  }
  //! Display empty data in form
  displayEmptyDataofFile(patientData: { fname: string, mname: string, lname: string, dob: string, gender: string }) {
    let patientPushArr: any[] = [];
    let presArr: any[] = [];
    let presPushArr: any[] = [];
    let FaceToFacePushArr: any[] = [];
    let SleepPushArr: any[] = [];
    let CompliencePushArr: any[] = [];
    // demo details initial patch
    let newDemoData = {
      txtFirstName: patientData?.fname ?? "",
      txtDOB: patientData?.dob ?? "",
      txtMiddleName: patientData?.mname,
      txtLastName: patientData?.lname ?? "",
      drpGeneralGender: patientData?.gender
    };
    this.checkRulesForFeildHighlight(this.rulesValues, SetupTypes.New, DXTypes.OSA);
    this.demo().clear();

    patientPushArr?.push(newDemoData);
    var demo: FormGroup = this.newDemo();
    this.demo()?.push(demo);
    this.PreviewExtractionForm?.patchValue({ Demographics: patientPushArr });

    // prescription details initial patch

    let newPresData = {
      txtPatientName2: (patientData?.fname ?? "") + "" + (patientData?.lname ?? ""),
      txtDOB2: patientData?.dob ?? "",
      txtPrescriptionDate: null,
      txtprescriptionsDoctorSign: "",
      txtDoctor: "",
      txtNpiNumber: "",
      txtSignedDate: null,
      txtDuration: "",
      txtEstimatedLength: "",
      txtSupplierName: "",
      txtdiagnosisCode10Id1: "",
    };

    presArr?.push(newPresData);
    this.Prescription().clear();

    var Prescription: FormGroup = this.newPrescription();
    this.Prescription()?.push(Prescription);
    this.PreviewExtractionForm?.patchValue({ priscription: presPushArr });

    // Face to face details initial patch

    let newf2fdata = {
      txtFobPatientName: (patientData?.fname ?? "") + "" + (patientData?.lname ?? ""),
      txtIobDob: patientData?.dob ?? "",
      txtDateofVisit: "",
      txtPatientinfo: "",
      txtHeightFeet: "",
      txtWeight: "",
      txtDoctor: "",
      txtNpiNumber: "",
      txtSignature: "",
      txtSignedDate: null,
      chkPrevioushistory: "",
      txtIobFadeToFace: "",
      txtTreatment: "",
      chkSleepTestRecommended: "",
      chkSleepDoctorSigned: "",
      chkSleepPatientBenifited: "",
      txtFobPatientBenifitNotes: "",
      drpSymptoms: [],
    };
    FaceToFacePushArr?.push(newf2fdata);
    var FaceToFace: FormGroup = this.newFaceToFace();
    this.FaceToFace().clear();

    this.FaceToFace()?.push(FaceToFace);
    this.PreviewExtractionForm?.patchValue({ faceToface: FaceToFacePushArr });

    //! sleep study details initial patch

    let newSleepData = {
      txtPatientName1: (patientData?.fname ?? "") + "" + (patientData?.lname ?? ""),
      txtDob: patientData?.dob ?? "",
      txtdiagnosisCode10Id: "",
      txtPressureSetting: "",
      txtSleepMinutes: "",
      txtREI: "",
      txtRDI: "",
      txtAHI: "",
      txtDiagnosisStudyDate: "",
      chkSleepStudyDoctorSigned: "",
      txtSSCentralApneas: "",
      txtSSCentralHyponeas: "",
      // txtSSCentralAHI: "",
      txtSSApneaAndHypopnea: "",
      txtTitrationSleepStudyDate: "",
      chkCPAPTriedAndFail: "",
      chkRespiratoryBreakdownAvailable: "",
      chkTitrationSleepStudyDoctorSigned: "",
      chkisCPAPReturned: "",
      chkTSSBaselineFile: "",
      txtObstructiveApnea: "",
      txtTAHI: '',
      txtTCentralApneas: '',
      txtTCentralHyponeas: '',
      txtTApneaAndHypopnea: '',
      txtHomeSleepStudyDate: '',
      txtHSMinutes: "",
      txtHSAHI: "",
      chkHSDoctorSigned: "",
      txtHSCentralApneas: "",
      txtHSCentralHyponeas: "",
      // txtHSCentralAHI: "",
      txtHSApneaAndHypopnea: "",
      chkHSpateintSigned: "",
    };
    this.sleepStudies().clear();
    SleepPushArr?.push(newSleepData);
    var sleepStudies: FormGroup = this.newsleepStudies();
    this.sleepStudies()?.push(sleepStudies);


    this.PreviewExtractionForm?.patchValue({ sleepStudy: SleepPushArr });

    //! Complience details initial patch
    let newCompData = {
      txtPatientName3: (patientData?.fname ?? "") + "" + (patientData?.lname ?? ""),
      txtDOB3: patientData?.dob ?? "",
      txtStudyStartDate: "",
      txtStudyEndDate: "",
      rbnComplianceMet: "",
      txtCompliancePercentage: "",
      txtUsagedays: "",
      txtUsagedaysOutOf: "",
      txtGreater4Hours: "",
      txtLess4hours: "",
    };

    CompliencePushArr?.push(newCompData);
    var Complience: FormGroup = this.newComplience();
    this.Complience().clear();

    this.Complience()?.push(Complience);
    this.PreviewExtractionForm?.patchValue({ Complience: CompliencePushArr });

    // this.patientData = pateintData ?? null;
  }
  submitted = false;
  demo1st: boolean = true;
  demo2nd: boolean = false;
  face1st: boolean = true;
  face2nd: boolean = false;
  sleep1st: boolean = true;
  sleep2nd: boolean = false;
  Insurance1st: boolean = true;
  Insurance2nd: boolean = false;
  prescription1st: boolean = true;
  prescription2nd: boolean = false;
  complience1st: boolean = true;
  complience2nd: boolean = false;



  // demographics input values as array
  get f() {
    return this.PreviewExtractionForm.controls;
  }
  get t() {
    return this.f.Demographics as FormArray;
  }

  // faceToface input values as array
  get a() {
    return this.PreviewExtractionForm.controls;
  }
  get b() {
    return this.a.faceToface as FormArray;
  }

  // sleepStudy input values as array
  get c() {
    return this.PreviewExtractionForm.controls;
  }
  get d() {
    return this.c.sleepStudy as FormArray;
  }

  // priscription input values as array
  get g() {
    return this.PreviewExtractionForm.controls;
  }
  get h() {
    return this.g.priscription as FormArray;
  }

  // Complience input values as array
  get i() {
    return this.PreviewExtractionForm.controls;
  }
  get j() {
    return this.i?.Complience as FormArray;
  }

  // Insurance input values as array
  get l() {
    return this.PreviewExtractionForm.controls;
  }
  get m() {
    return this.l.Insurance as FormArray;
  }

  //// after save getbyid
  insuranceAfterSave: PatientsDTO;
  fieldHide: boolean = false;
  getPatientSIdFormPatientSDetails(_patientsId: string) {
    this.hideNotificatiaonButton = true;
    this.patientsService.get(this.patientsId).subscribe((response) => {
      this.insuranceAfterSave = response;
      if (response?.verifications && response?.verifications?.primaryInsuranceVerifies !== null &&
        response?.verifications?.primaryInsuranceVerifies?.verificationStatus == "Done" &&
        response?.orders && response?.orders?.items?.length != 0) {
        this.fieldHide = true
      }
      if (response?.orders?.isCompleted === 'Done') {
        this.fieldHide = false
      }
      // this.checkRulesForFeildHighlight(this.rulesValues, response?.setupType, response?.dxType);
      if (response?.demographics?.length === 0) {
        this.demo1st = true;
        this.demo2nd = false;
      }
      if (response?.sleepStudies?.length === 0) {
        this.sleep1st = true;
        this.sleep2nd = false;
      }
      if (response?.prescriptions?.length === 0) {
        this.prescription1st = true;
        this.prescription2nd = false;
      }
      if (response?.initialFaceToFaces?.length === 0) {
        this.face1st = true;
        this.face2nd = false;
      }
      if (response?.complainces?.length === 0) {
        this.complience1st = true;
        this.complience2nd = false;
      }
      let demoSavedArr: any[] = [];
      let FaceToFaceSavedArr: any[] = [];
      let sleepStudiesSavedArr: any[] = [];
      let PrescriptionSavedArr: any[] = [];
      let ComplienceSavedArr: any[] = [];

      // demographics
      this.demo()?.clear();
      response?.demographics?.map((d) => {
        let newDemoData = {
          txtFirstName: d?.firstName,
          txtDOB: d?.dateOfBirth,
          txtMiddleName: d?.middleName,
          txtLastName: d?.lastName,
          drpGeneralGender: d?.gender
        };
        demoSavedArr?.push(newDemoData);

        var demo: FormGroup = this.newDemo();
        this.demo()?.push(demo);
      });

      // face to face
      this.FaceToFace()?.clear();
      response?.initialFaceToFaces?.map((d) => {
        let newFaceData = {
          txtDateofVisit: d?.dateOfVisit,
          txtPatientinfo: d?.patientName,
          txtHeightFeet: d?.hieght,
          txtWeight: d?.weight,
          txtDoctor: d?.doctorName,
          txtNpiNumber: d?.npi,
          txtSignature: d?.doctorSignature,
          txtSignedDate: d?.signedDate,
          chkPrevioushistory: d?.previousHistoryDeclaration,
          //! new
          txtFobPatientName: d?.patientName,
          txtIobDob: d?.dateOfBirth,
          txtIobFadeToFace: d?.faceToFaceDate,
          txtTreatment: d?.apneaTreatment,
          chkSleepTestRecommended: d?.isSleepTestRecommended,
          chkSleepDoctorSigned: d?.isDoctorSigned,
          chkSleepPatientBenifited: d?.isPatientBenifited,
          txtFobPatientBenifitNotes: d?.patientBenefitNotes,
          drpSymptoms: d?.symptoms,
        };
        FaceToFaceSavedArr?.push(newFaceData);
        var FaceToFace: FormGroup = this.newFaceToFace();
        this.FaceToFace()?.push(FaceToFace);
      });
      // sleep study report
      this.sleepStudies()?.clear();
      response?.sleepStudies?.map((d) => {
        let newSleepData = {
          txtPatientName1: d?.patientName,
          txtDob: d?.dateOfBirth,
          txtdiagnosisCode10Id: d?.diagnosisCode,
          txtPressureSetting: d?.pressureSetting,
          //! new
          txtSleepMinutes: (String(+(parseInt(d?.diagnosticSleepStudy?.totalSleepMins) || 0) ?? 0) ?? ""),
          txtREI: d?.rei,
          txtRDI: d?.rdi,
          txtAHI: d?.diagnosticSleepStudy?.ahi,
          txtDiagnosisStudyDate: d?.diagnosticSleepStudy?.diagnosticSleepStudyDate,
          chkSleepStudyDoctorSigned: d?.diagnosticSleepStudy?.isDoctorSigned,
          txtSSCentralApneas: d?.diagnosticSleepStudy?.centralApneas,
          txtSSCentralHyponeas: d?.diagnosticSleepStudy?.centralHyponeas,
          //  txtSSCentralAHI: d?.diagnosticSleepStudy?.centralAHI,
          txtSSApneaAndHypopnea: d?.diagnosticSleepStudy?.totalApneaAndHypopnea,
          txtTitrationSleepStudyDate: d?.titrationSleepStudy?.titrationSleepStudyDate,
          chkCPAPTriedAndFail: d?.titrationSleepStudy?.isCPAPTriedAndFail,
          chkRespiratoryBreakdownAvailable: d?.titrationSleepStudy?.isRespiratoryBreakdownAvailable,
          chkTitrationSleepStudyDoctorSigned: d?.titrationSleepStudy?.isDoctorSigned,
          chkisCPAPReturned: d?.isCPAPReturned,
          chkTSSBaselineFile: d?.titrationSleepStudy?.isBaselineFileAvailable,
          txtObstructiveApnea: d?.titrationSleepStudy?.obstructiveApnea,
          txtTAHI: d?.titrationSleepStudy?.ahi,
          txtTCentralApneas: d?.titrationSleepStudy?.centralApneas,
          txtTCentralHyponeas: d?.titrationSleepStudy?.centralHyponeas,
          txtTApneaAndHypopnea: d?.titrationSleepStudy?.totalApneaAndHypopnea,
          txtHSMinutes: (String(+(parseInt(d?.homeSleepTest?.totalSleepMins)) || 0) ?? ""),
          txtHSAHI: d?.homeSleepTest?.ahi,
          chkHSDoctorSigned: d?.homeSleepTest?.isDoctorSigned,
          txtHSCentralApneas: d?.homeSleepTest?.centralApneas,
          txtHSCentralHyponeas: d?.homeSleepTest?.centralHyponeas,
          //  txtHSCentralAHI: d?.homeSleepTest?.centralAHI,
          txtHSApneaAndHypopnea: d?.homeSleepTest?.totalApneaAndHypopnea,
          chkHSpateintSigned: d?.homeSleepTest?.isPatientSigned,
          txtHomeSleepStudyDate: d?.homeSleepTest?.homeSleepTestDate
        };
        sleepStudiesSavedArr?.push(newSleepData);
        var sleepStudies: FormGroup = this.newsleepStudies();
        this.sleepStudies()?.push(sleepStudies);
      });

      // Prescription report
      this.Prescription()?.clear();
      response?.prescriptions?.map((d) => {
        let newPrescriptionData = {
          txtPatientName2: d?.patientName,
          txtDOB2: d?.dateOfBirth,
          txtPrescriptionDate: d?.prescriptionDate,
          txtprescriptionsDoctorSign: d?.doctorSignature,
          txtDoctor: d?.doctorName,
          drpMachineDetails: d?.machineDetails,
          txtPressureLevel: d?.pressureLevel,
          txtLengthOfNeed: d?.lengthOfNeed,
          txtNpiNumber: d?.npi,
          chkDoctorSigned: d?.isDoctorSigned,
          txtSignedDate: d?.doctorSignedDate,
          txtItemcode: d?.itemId,
          txtDuration: d?.duration,
          txtEstimatedLength: d?.estimatedLength,
          txtSupplierName: d?.supplierName,
          txtSupplierDetials: d?.suppliesDetails,
          txtdiagnosisCode10Id1: d?.diagnosticCode,
        };
        PrescriptionSavedArr?.push(newPrescriptionData);
        var Prescription: FormGroup = this.newPrescription();
        this.Prescription()?.push(Prescription);
      });

      // Complience report
      this.Complience()?.clear();
      response?.complainces?.map((d) => {
        let newComplienceData = {
          txtPatientName3: d?.patientName,
          txtDOB3: d?.dateOfBirth,
          txtStudyStartDate: d?.studyStartDate,
          txtStudyEndDate: d?.studyEndDate,
          rbnComplianceMet: d?.isComplainceMet,
          txtCompliancePercentage: d?.complaincePercentage,
          txtUsagedays: d?.usageDays,
          txtUsagedaysOutOf: d?.outOfDays,
          txtGreater4Hours: d?.greaterThanFourHrs,
          txtLess4hours: d?.lessThanFourHrs,
        };
        ComplienceSavedArr?.push(newComplienceData);
        var Complience: FormGroup = this.newComplience();
        this.Complience()?.push(Complience);
      });

      ////insurance binding afetr save


      setTimeout(() => {
        this.PreviewExtractionForm?.patchValue({
          drpPayorLevel: response?.insurances?.primaryInsurance?.insuranceType,
          drpPayorName: response?.insurances?.primaryInsurance?.zermidInsName,
          txtMemberId: response?.insurances?.primaryInsurance?.policyNo,
          drpProvider: response?.insurances?.primaryInsurance?.provider,
          drpVerifyType: response?.insurances?.primaryInsurance?.verifyType,
          drpInsurance: response?.insurances?.primaryInsurance?.pVerifyInsName,
          chkWorkProgressCompleted: response?.orders?.isCompleted === 'Done' ? true : false,
          drpGeneralPlaceOfService: response?.orders?.pos
        })
      }, 1000);

      // authorization patch
      this.PreviewExtractionForm?.patchValue({
        drpAuthInsurance: response?.authorizations?.primaryAuthorizations?.plan,
        txtAuthId: response?.authorizations?.primaryAuthorizations?.authId,
        drpAuthVisit: response?.authorizations?.primaryAuthorizations?.authVisits,
        drpAuthAmount: response?.authorizations?.primaryAuthorizations?.authAmount,
        drpCategory: response?.authorizations?.primaryAuthorizations?.category,
        txtStartDate: response?.authorizations?.primaryAuthorizations?.startDate,
        txtEndDate: response?.authorizations?.primaryAuthorizations?.endDate,
        chkCompleted: response?.authorizations?.primaryAuthorizations?.isCompleted,
        txtCompletedBy: response?.authorizations?.primaryAuthorizations?.completedBy,
        txtNote: response?.authorizations?.primaryAuthorizations?.note,
        txtUnits: response?.authorizations?.primaryAuthorizations?.units,
        txtUsed: response?.authorizations?.primaryAuthorizations?.used,
        txtSaleOrRent: response?.authorizations?.primaryAuthorizations?.saleOrRent,
        txtExpired: response?.authorizations?.primaryAuthorizations?.isExpired,
        chkFollowUp: response?.authorizations?.primaryAuthorizations?.isFollowUp,
        txtItemCodes: response?.authorizations?.primaryAuthorizations?.itemCodes
      });
      let primaryAuth = response?.authorizations?.primaryAuthorizations;
      let primaryAuthEndDate = ((primaryAuth?.endDate ?? "") === '') ? "Not Mentioned" : primaryAuth?.endDate;
      this.expiryStatus = ((primaryAuthEndDate === "Not Mentioned") ? primaryAuthEndDate : (new Date() < new Date(primaryAuthEndDate) ? 'Active' : 'Expired'));
      // demo,sleepstudy,facetoface,complience,prescription patch
      this.PreviewExtractionForm?.patchValue({ Demographics: demoSavedArr });
      this.PreviewExtractionForm?.patchValue({ faceToface: FaceToFaceSavedArr });
      this.PreviewExtractionForm?.patchValue({
        sleepStudy: sleepStudiesSavedArr,
      });
      this.PreviewExtractionForm?.patchValue({
        priscription: PrescriptionSavedArr,
      });
      this.PreviewExtractionForm?.patchValue({ Complience: ComplienceSavedArr });
      this.PreviewExtractionForm?.patchValue({
        drpInsurance: response?.verifications,
        //! new
        chkIsRulesNeeded: response?.['isRulesNeeded'],
        drpPrdSetupType: response?.['setupType'],
        drpDXType: response?.['dxType']
      });
      this.percentageOfCompletion = response?.percentageOfCompletion;
      this.compliancePendingValidationCount = response?.compliancePendingValidationCount ?? 0;
      this.demographicsPendingValidationCount = response?.demographicsPendingValidationCount ?? 0;
      this.initialFaceToFacePendingValidationCount = response?.initialFaceToFacePendingValidationCount ?? 0;
      this.prescriptionPendingValidationCount = response?.prescriptionPendingValidationCount ?? 0;
      this.sleepStudyPendingValidationCount = response?.sleepStudyPendingValidationCount ?? 0;
      this.verificationPendingValidationCount = response?.verificationPendingValidationCount ?? 0;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }



  doctorValueChange(formType?: string, value?: MatSelect) {
    if (formType === "p2p") {
      const doctorNpi: string = String(value?.value)?.split(' /')[0];
      const d = this.Prescription()?.value[0];
      const p2pvalue = {
        txtNpiNumber: doctorNpi,
        txtPatientName2: d?.txtPatientName2,
        txtDOB2: d?.txtDOB2,
        txtPrescriptionDate: d?.txtPrescriptionDate,
        txtprescriptionsDoctorSign: d?.txtprescriptionsDoctorSign,
        txtDoctor: value?.value,
        drpMachineDetails: d?.drpMachineDetails,
        txtPressureLevel: d?.txtPressureLevel,
        txtLengthOfNeed: d?.txtLengthOfNeed,
        chkDoctorSigned: d?.chkDoctorSigned,
        txtSignedDate: d?.txtSignedDate,
        txtItemcode: d?.txtItemcode,
        txtDuration: d?.txtDuration,
        txtEstimatedLength: d?.txtEstimatedLength,
        txtSupplierName: d?.txtSupplierName,
        txtSupplierDetials: d?.txtSupplierDetials,
        txtdiagnosisCode10Id1: d?.txtdiagnosisCode10Id1,
      };
      this.Prescription().clear();
      const priscription: FormGroup = this.newPrescription();
      this.Prescription()?.push(priscription);
      this.PreviewExtractionForm?.patchValue({ priscription: [p2pvalue] });
    }
    if (formType === "f2f") {
      const doctorNpi: string = String(value?.value)?.split(' /')[0];
      const d = this.FaceToFace()?.value[0];
      const f2fvalue = {
        txtNpiNumber: doctorNpi,
        txtDateofVisit: d?.txtDateofVisit,
        txtPatientinfo: d?.txtPatientinfo,
        txtHeightFeet: d?.txtHeightFeet,
        txtWeight: d?.txtWeight,
        txtDoctor: value?.value,
        txtSignature: d?.txtSignature,
        txtSignedDate: d?.txtSignedDate,
        chkPrevioushistory: d?.chkPrevioushistory,
        txtFobPatientName: d?.txtFobPatientName,
        txtIobDob: d?.txtIobDob,
        txtIobFadeToFace: d?.txtIobFadeToFace,
        txtTreatment: d?.txtTreatment,
        chkSleepTestRecommended: d?.chkSleepTestRecommended,
        chkSleepDoctorSigned: d?.chkSleepDoctorSigned,
        chkSleepPatientBenifited: d?.chkSleepPatientBenifited,
        txtFobPatientBenifitNotes: d?.txtFobPatientBenifitNotes,
        drpSymptoms: d?.drpSymptoms,
      };
      this.FaceToFace().clear();
      const FaceToFace: FormGroup = this.newFaceToFace();
      this.FaceToFace()?.push(FaceToFace);
      this.PreviewExtractionForm?.patchValue({ faceToface: [f2fvalue] });
    }
  }
  txtPrice: number = 0;
  ietmChange(formType?: string, value?: NgSelectComponent) {
    if (formType === "p2p") {
      const item: string = value?.selectedValues[0]?.toString() ?? "";
      this.txtPrice = this.drpitem?.filter(e => e?.productId === item)[0]?.price;
      const d = this.Prescription()?.value[0];
      const p2pvalue = {
        txtNpiNumber: d.txtNpiNumber,
        txtPatientName2: d?.txtPatientName2,
        txtDOB2: d?.txtDOB2,
        txtPrescriptionDate: d?.txtPrescriptionDate,
        txtprescriptionsDoctorSign: d?.txtprescriptionsDoctorSign,
        txtDoctor: d?.txtDoctor,
        drpMachineDetails: d?.drpMachineDetails,
        txtPressureLevel: d?.txtPressureLevel,
        txtLengthOfNeed: d?.txtLengthOfNeed,
        chkDoctorSigned: d?.chkDoctorSigned,
        txtSignedDate: d?.txtSignedDate,
        txtItemcode: d?.txtItemcode,
        txtDuration: d?.txtDuration,
        txtEstimatedLength: d?.txtEstimatedLength,
        txtSupplierName: d?.txtSupplierName,
        txtSupplierDetials: d?.txtSupplierDetials,
        txtdiagnosisCode10Id1: d?.txtdiagnosisCode10Id1,
      };
      this.Prescription().clear();
      const priscription: FormGroup = this.newPrescription();
      this.Prescription()?.push(priscription);
      this.PreviewExtractionForm?.patchValue({ priscription: [p2pvalue] });
    }
  }
  // demographics form control return
  demo() {
    return this.PreviewExtractionForm?.get('Demographics') as FormArray;
  }
  newDemo(): FormGroup {
    return this.formBuilder.group({
      txtFirstName: ['', this.textValidators.isTextCheck],
      txtDOB: [''],
      txtMiddleName: [''],
      txtLastName: ['', this.textValidators.isTextCheck],
      drpGeneralGender: [''],
      txtReason: [''],
    });
  }

  // faectoface form control return
  FaceToFace() {
    return this.PreviewExtractionForm?.get('faceToface') as FormArray;
  }
  newFaceToFace(): FormGroup {
    return this.formBuilder.group({
      txtDateofVisit: [''],
      txtPatientinfo: [''],
      txtHeightFeet: ['', this.numberValidators.isNumberCheck],
      txtHeightInches: new FormControl('', [
        Validators.min(0),
        Validators.max(11),
        this.numberValidators.isNumberCheck,
      ]),
      txtWeight: ['', this.numberValidators.isNumberCheck],
      txtDoctor: [''],
      txtNpiNumber: ['', this.numberValidators.isNumberCheck],
      txtSignature: [''],
      txtSignedDate: [''],
      chkPrevioushistory: [''],
      email: ['', [Validators.email]],
      //! new
      txtFobPatientName: [''],
      txtIobDob: [''],
      txtIobFadeToFace: [''],
      chkSleepTestRecommended: [''],
      chkSleepDoctorSigned: [''],
      chkSleepPatientBenifited: [''],
      txtFobPatientBenifitNotes: [''],
      drpSymptoms: [],
    });
  }

  // Prescription form control return
  Prescription() {
    return this.PreviewExtractionForm?.get('priscription') as FormArray;
  }
  newPrescription(): FormGroup {
    return this.formBuilder.group({
      txtPatientName2: ['', this.textValidators.isTextCheck],
      txtDOB2: [''],
      txtPrescriptionDate: [''],
      txtDoctor: [''],
      txtDoctorSearchFilter: [''],
      drpMachineDetails: [''],
      txtPressureLevel: [''],
      txtLengthOfNeed: [''],
      txtNpiNumber: ['', this.numberValidators.isNumberCheck],
      chkDoctorSigned: [''],
      txtSignedDate: [''],
      txtItemcode: [''],
      txtSearchFilter: [''],
      txtDuration: [''],
      txtEstimatedLength: [''],
      txtSupplierName: ['', this.textValidators.isTextCheck],
      txtSupplierDetials: [''],
      txtdiagnosisCode10Id1: [''],
      txtIcd10Filter1: [''],
      txtItemcodeSearch: [''],
      //! new
      txtprescriptionsDoctorSign: [''],
    });
  }
  // alert for reload
  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHander(event: BeforeUnloadEvent) {
  //   return false;
  // }
  // sleepstudy form control return
  sleepStudies() {
    return this.PreviewExtractionForm?.get('sleepStudy') as FormArray;
  }
  newsleepStudies(): FormGroup {
    return this.formBuilder.group({
      txtPatientName1: ['', this.textValidators.isTextCheck],
      txtDob: [''],
      txtSleepMinutes: [''],
      txtREI: ['', this.numberValidators.isNumberCheck],
      txtRDI: ['', this.numberValidators.isNumberCheck],
      txtAHI: ['', [this.numberValidators.isNumberCheck]],
      txtdiagnosisCode10Id: [''],
      txtdiagnosisCode10I1: [''],
      txtPressureSetting: [''],
      txtStudyDate: [''],
      //! new
      txtDiagnosisStudyDate: [''],
      chkSleepStudyDoctorSigned: [''],
      txtSSCentralApneas: [''],
      txtSSCentralHyponeas: [''],
      // txtSSCentralAHI: [''],
      txtSSApneaAndHypopnea: [''],
      txtTitrationSleepStudyDate: [''],
      txtTAHI: [''],
      txtTCentralApneas: [''],
      txtTCentralHyponeas: [''],
      txtTApneaAndHypopnea: [''],


      chkCPAPTriedAndFail: [''],
      chkRespiratoryBreakdownAvailable: [''],
      chkTitrationSleepStudyDoctorSigned: [''],
      chkisCPAPReturned: [''],

      chkTSSBaselineFile: [''],
      txtObstructiveApnea: [''],
      txtHSMinutes: [''],
      txtHSAHI: [''],
      chkHSDoctorSigned: [''],
      txtHSCentralApneas: [''],
      txtHSCentralHyponeas: [''],
      //  txtHSCentralAHI: [''],
      txtHSApneaAndHypopnea: [''],
      chkHSpateintSigned: [''],
      txtHomeSleepStudyDate: [''],
    });
  }

  // Complience form control return
  Complience() {
    return this.PreviewExtractionForm?.get('Complience') as FormArray;
  }
  newComplience(): FormGroup {
    return this.formBuilder.group({
      txtPatientName3: ['', this.textValidators.isTextCheck],
      txtDOB3: [''],
      txtStudyStartDate: [''],
      txtStudyEndDate: [''],
      rbnComplianceMet: [''],
      txtCompliancePercentage: [''],
      txtUsagedays: [''],
      txtUsagedaysOutOf: [''],
      txtGreater4Hours: [''],
      txtLess4hours: [''],
    });
  }


  //! Open ICD 10 Add Modal
  openIcd10Modal() {
    const dialogRef = this.dialog.open(PatientIcd10Component, {
      disableClose: true,
      data: {},
    });
    dialogRef.afterClosed().subscribe(
      () => {
        this.getDropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  //! Doctor Modal Open Functions
  openDoctorModal(doctorId?: string) {
    const dialogRef = this.dialog.open(PatientDoctorComponent, {
      disableClose: true,
      data: { doctorId: doctorId },
    });
    dialogRef.afterClosed().subscribe(
      () => {
        this.doctorDropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  clearIcd10() {
    this.Icd10 = '';
    this.PreviewExtractionForm?.patchValue({
      clinicalDiagnosis: {
        txtDiagnosis9: '',
      },
    });
  }

  clearMachineDetails() {
    this.machineDetails = '';
    this.PreviewExtractionForm?.patchValue({
      drpMachineDetails: '',
    });
  }

  clearinsuranceType() {
    this.insuranceType = '';
    this.PreviewExtractionForm?.patchValue({
      drpInsurance: '',
    });

  }

  //! Open New Item Add Modal using Template ref
  openNewItemAddModal(templateRef: TemplateRef<any>) {

    const dialogRef = this.dialog.open(templateRef, {
      data: { clientId: this.tenantId },
    });
    dialogRef.afterClosed().subscribe(
      () => {
        this.getDropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //! get render page number
  getRenderedPageNumber(value: number) {
    this.strDocumentType = '';
    this.PreviewExtractionForm?.patchValue({
      drpDocumentType: '',
    });
    if (value && value && this.lstIndex && this.drpDocumentTypeLoop) {
      this.strDocumentType =
        this.lstIndex?.filter((i) => i?.pageNumber === value)[0]?.documentType ??
        '';
      const strDocumentType = this.drpDocumentTypeLoop.filter(
        (t) =>
          t?.documentType?.toLocaleLowerCase() ===
          this.strDocumentType?.toLocaleLowerCase()
      )[0]?.documentType;
      this.PreviewExtractionForm?.patchValue({
        drpDocumentType: strDocumentType,
      });
      this.pageNumber = value;
    }
  }
  pdfLoadCompleted(value?: { pagesCount?: number }) {
    this.isPdfLoadSpinner = true;
    if (value?.pagesCount) {
      this.isPdfLoadSpinner = false;
    }
  }
  //! get PDF by DocumentId to preview
  previewDocumentById(blobName: string) {

    (blobName ?? "") && this.uploadFilesService
      .getPatientDocumentByBlobNameByBlobName(blobName)
      .subscribe(
        (response) => {
          this.strSelectedPdfPath = '';
          if (typeof response === 'string' && response && response?.length !== 0) {
            this.strSelectedPdfPath = String('data:application/pdf;base64,' + response);
          }
          this.isPdfLoadSpinner = false;
          this.getRenderedPageNumber(1);

        },
        (err) => {
          this.isPdfLoadSpinner = false;
          this.strSelectedPdfPath = '';
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
    this.blobName = this.blobName ?? "";
    (this.blobName ?? "") && this.uploadFilesService
      .getPatientDocumentByBlobNameByBlobName(this.blobName)
      .subscribe(
        (response) => {
          this.strSelectedPdfPath = '';
          if (typeof response === 'string' && response && response?.length !== 0) {
            this.strSelectedPdfPath = String('data:application/pdf;base64,' + response);
          }
          this.isPdfLoadSpinner = false;
          this.getRenderedPageNumber(1);

        },
        (err) => {
          this.isPdfLoadSpinner = false;
          this.strSelectedPdfPath = '';
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });




  }

  toggleTreeView() {
    this.isTreeViewEnabled = !this.isTreeViewEnabled;
  }



  //! Form Array Feilds and validations
  addFAfeildControls() {
    const formArrayFeilds = this.formBuilder.group({
      controlName: [''],
    });
    this.formArrayFeilds = this.PreviewExtractionForm.get(
      'formArrayFeilds'
    ) as FormArray;
    this.formArrayFeilds?.push(formArrayFeilds);
  }
  //! Add Accordions on click
  extendMatAccordion() {
    this.matExpansionPanelQueryList.changes.subscribe((change) => {
      change.last.open();
      this.cd?.detectChanges();
    });
    this.lstAccordions.push('');
  }
  //! Slice Form control name
  removeFormControlName(index: number) {
    this.formArrayFeilds.removeAt(index);
  }

  //! Update Document Type In DB
  updateDocumentType() {
    // this.tenantId = this.cookieService.get('__tenant') ?? "";
    localStorage.setItem('tenantId', this.tenantId);
    if (this.PreviewExtractionForm.value.drpDocumentType !== '') {
      let selectedIndex: SortedData;
      this.lstIndex.forEach((i) => {
        if (i?.pageNumber === this.pageNumber) {
          i.documentType = this.PreviewExtractionForm.value.drpDocumentType;
          selectedIndex = {
            documentType: this.PreviewExtractionForm.value.drpDocumentType,
            pageNumber: this.pageNumber,
            sortType: SortType.Manual,
            sortedFileId: i?.sortedFileId,
          };
        }
      });
      if (selectedIndex?.sortedFileId && this.fileId) {
        setTimeout(() => {
          this.uploadFilesService
            .updateSortedIndexByFileIdAndSortedFileIdAndDocumentType(
              this.fileId,
              selectedIndex?.sortedFileId,
              selectedIndex?.documentType
            )
            .subscribe(
              () => {
                // Swal.fire({
                //   title: 'Success',
                //   html: 'Saved Successfully',
                //   icon: 'success',
                //   timer: 3000,
                //   timerProgressBar: true,
                // });
                this.toastr.success('Saved Successfully','Success')
                this.fileId && this.getDocumentDeatilsByFileId(this.fileId);
              },
              (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }
            );
        }, 200);
      }
    }
  }
  drpPlan: planInterface[] = [];
  drpAtuhPlan: TypeOfPlanDTO[] = [];
  drpCategory: AuthCategoryDTO[] = [];
  drpAuthVisit: AuthVisitsDTO[] = [];

  //drpMarketingRef: MarketingReferralDTO[] = [];
  drpAuthItem: ItemDTO[] = [];

  // drpPayerId: any[] = [];
  drpProviders: any[] = [];
  verifyType: any[] = [];
  filterPayor: Observable<{ id: string; name: string; payerCode: string; payerName: string; }[]> | undefined;
  filteredProvider: any[] = [];
  filterIns: Observable<{ id: string; name: string; code: string; payorId: string; }[]> | undefined;
  //! Dropdown Funtion
  getDropdown(): void {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.AuthVisits,
      OrderDropdowns.AuthCategories,
      OrderDropdowns.TypeOfPlans
    ]
    this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      let response: OrderMasterDropdownDTO = stateResponse
      this.drpAuthVisit = response.authVisits;
      this.drpCategory = response.authCategories;
      this.drpAtuhPlan = response.typeOfPlans;
    })



    this.planService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      // this.drpPlan = [];
      response?.items?.forEach(element => {
        this.drpPlan.push({
          id: element.id ?? "",
          name: element?.payerName ?? "",
          code: element?.payerCode ?? "",
          payorId: element.shortCodeId ?? ""
        });
      });

      this.filterIns = this.PreviewExtractionForm?.get("txtInsFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpPlan.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    });


    /// payor dropdown
    this.payerService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      // this.drpPayerId = [];
      response?.items?.forEach(element => {
        this.drpPayerId.push({
          id: element.id ?? "",
          name: element?.payerId + ' / ' + element?.payerName,
          payerCode: element?.payerId ?? "",
          payerName: element?.payerName,
        });

      });
      this.filterPayor = this.PreviewExtractionForm?.get("txtPayorFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpPayerId.filter(option => option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
    })

    /// provider dropdown
    const providerList = (query: any) => this.providerList.getList(query);
    this.list.hookToQuery(providerList).subscribe(response => {
      response?.items?.forEach(element => {
        this.drpProviders.push({
          id: element.id,
          name: element?.fullName,
          shortCodeId: element?.shortCodeId,
          fullName: element?.fullName,
          npi: element?.npi,
        });
      });
    })

    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.DocumentTypes,
      PatientDropdowns.ReviewStatus,
      PatientDropdowns.ReviewReasons,
      PatientDropdowns.BranchOffices,
      PatientDropdowns.SortingTypes,
      PatientDropdowns.PayorLevels,
      PatientDropdowns.MachineTypes,
      PatientDropdowns.Symptoms,
      PatientDropdowns.DXTypes,
      PatientDropdowns.SetupTypes,
      PatientDropdowns.Genders,
      PatientDropdowns.PlaceOfServices,
      PatientDropdowns.SaleOrRent
    ];
    this.drpDocumentTypeLoop = [];
    this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (response) => {
          this.drpGenderLoop = response.genders;
          this.drpPlace = response?.placeOfServices;
          this.drpSaleOrRents = response?.saleOrRents;
          if (response && response?.documentTypes) {
            this.drpDocumentTypeLoop = response?.documentTypes;
            this.payorLevel = response?.payorLevels;
            this.drpMachines = response?.lstMachineTypes;
          }
          this.drpDXType = response?.lstDXTypes ?? [];
          this.drpSymptoms = response?.lstSymptoms ?? [];
          this.drpPrdSetupType = response?.lstSetupTypes ?? [];

        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.drpDoctor = [];
    const doctorItemList = (query: any) => this.doctorService.getList(query);
    this.list.hookToQuery(doctorItemList).subscribe(
      (response) => {
        response?.items?.forEach((element) => {
          this.drpDoctor?.push({
            name:
              element['npiNumber'] +
              ' / ' +
              element?.firstName +
              ' ' +
              element?.middleName +
              ' ' +
              element?.lastName,
            id: element?.id ?? '',
          });
        });
        this.filteredOrderingDoctorTypes = this.PreviewExtractionForm.get(
          'txtDoctorSearchFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filteredOrderingDoctorTypes = this.PreviewExtractionForm.get(
          'txtordSearchFilter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDoctor?.filter((option) =>
              option?.name?.toLowerCase()?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.verifyType = [
      { id: 1, type: "Pverify" }
      // { id: 2, type: "Zermid" }
    ];

    this.drpitem = [];
    this.drpitem = [];
    this.itemService.getProductsByInput(ProductType.All).subscribe(response => {


      response?.forEach(element => {
        this.drpitem.push({
          hcpcCode: element.hcpcCode,
          productDescription: element?.productDescription,
          productCode: element?.productCode,
          status: element?.status,
          price: element?.price,
          productId: element?.productId,
          productName: element?.productName,
          productType: element?.productType,
          productNames: element?.productName,
          brandName: element?.brandName,
          categoryName: element?.categoryName,
          name: element?.name
        });
        // this.drpitem.push({
        //   equipmentBillingCode: element?.itemName,
        //   itemId: element?.itemCode + ' / ' + element?.itemName,
        //   id: element?.id,
        //   name: element?.itemName,
        //   location: element?.locationId,
        //   charge: element?.price,
        // });
      });

    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });

    this.itemService.getList(new PagedAndSortedResultRequestDto()).subscribe(
      (_response) => {


        // this.filteredItemTypes = this.PreviewExtractionForm?.get("txtSearchFilter")?.valueChanges?.pipe(
        //   startWith(''),
        //   map(value => this.drpitem?.filter(option => option?.itemId?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        // );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      },
      () => { }
    );

    this.drpDiagnosisICD10 = [];
    const diagonsis10ItemList = (query: any) => this.diagnosis10.getList(query);
    this.list.hookToQuery(diagonsis10ItemList).subscribe(
      (response) => {
        response?.items?.forEach((element: DiagnosisCode10DTO) => {
          let resultString =
            element?.diagnosisCode + ' ' + element?.diagnosisCodeTypeData;
          this.drpDiagnosisICD10?.push({
            diagnosisCode: element?.diagnosisCode ?? '',
            diagnosisCode10ShortCodeId:
              element?.diagnosisCode10ShortCodeId ?? '',
            diagnosisCodeTypeData: resultString,
            diagnosisCodeTypeDataId: element?.diagnosisCodeTypeDataId ?? '',
            id: element?.id ?? '',
            creationTime: element?.creationTime ?? '',
            creatorId: element?.creatorId ?? '',
            isActive: element?.isActive,
            isExist: element?.isExist,
          });
        });

        this.filtered1Icd10Types = this.PreviewExtractionForm.get(
          'txtIcd10Filter1'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDiagnosisICD10.filter((option) =>
              option?.diagnosisCodeTypeData
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
        this.filtered1Icd10Types = this.PreviewExtractionForm.get(
          'txtIcd10Filter'
        )?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpDiagnosisICD10.filter((option) =>
              option?.diagnosisCodeTypeData
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.drpLocationTypes = [];
    if (
      this.tenantId !== '' &&
      this.tenantId !== undefined &&
      this.tenantId !== null
    ) {
      this.branchService.getOrganizationList(this.tenantId).subscribe(
        (response) => {
          response?.forEach((element) => {
            this.drpLocationTypes?.push({
              id: element?.id ?? '',
              organizationUnitName: element?.organizationUnitName ?? '',
              tenantId: element?.tenantId ?? '',
            });
          });
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }


  //! Clear dropdwon
  clearOrderingDoctor() {
    this.OrderingDoctor = '';
    this.PreviewExtractionForm?.patchValue({ txtDoctor: '' });
  }

  //! Download override function
  downloadClicked() {
    this.blobName &&
      window.open(
        baseUrl + '#/ViewDocumentPerviewByBlobId/' + this.blobName + '/' + 1,
        '_blank'
      );
  }

  /// pverify save for getting patient insurance amount
  vPatient() {
    this.saveButtonHide = true;
    this.isShowSpinner = true;
    let zermidIns = this.drpPayerId?.filter(value => {
      return value && value?.name === this.PreviewExtractionForm.value.drpPayorName

    })[0];
    let pverifyIns = this.drpPlan?.filter(value => {
      return value && value?.name === this.PreviewExtractionForm.value.drpInsurance
    })[0];

    let pverifyData: CreateUpdateInsuranceDetailDTO;
    pverifyData = {
      provider: this.PreviewExtractionForm.value.drpProvider,
      verifyType: this.PreviewExtractionForm.value.drpVerifyType,
      insuranceType: this.PreviewExtractionForm.value.drpPayorLevel,
      pVerifyInsName: pverifyIns?.name,
      pVerifyInsCode: pverifyIns?.code,
      zermidInsName: zermidIns?.name,
      zermidInsCode: zermidIns?.payerCode,
      policyNo: this.PreviewExtractionForm.value.txtMemberId,
      notes: "",
      lastEdit: "",
      added: "",
    }

    setTimeout(() => {
      this.patientsService.verifyPatientByPatientsIdAndPatientIdAndInput(this.patientsId, this.approvedPatientId, pverifyData).subscribe(_response => {
        Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {
          if (result.value == true) {
            this.getPatientSIdFormPatientSDetails(this.patientsId);
            // const dialogRef = this.dialog.open(VerifyPatientAmountsComponent, {
            //    disableClose: true, data: { patientsId: this.patientsId }
            //   });
            // dialogRef.afterClosed().subscribe(() => {

            // }, err => {
            //   const data: HttpErrorResponse = err;
            //   Swal.fire({
            //     icon: 'error',
            //     text: data?.error?.error?.message,
            //   });
            // });
          }
          this.saveButtonHide = false;
          this.isShowSpinner = false;
        });
        this.saveButtonHide = false;
        this.isShowSpinner = false;
      },
        err => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        })
    }, 5000);

  }

  /// completed confirmation
  isCompeltedConfirmation() {
    Swal.fire({
      title: 'Are you sure you want to complete?',
      text: 'Order details cannot be modifed in further',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Confirm!'
    }).then(result => {
      if (result.value) {
      }
    })
  }


  viewAllPatientsListData() {
    window.open(baseUrl + '#/ViewAllPatientsListData');
  }

  inventoryItemId: string = '';
  onChange(value) {
    this.inventoryItemId = value;
    const drpitem: productInterface = this.drpitem.filter(e => e?.hcpcCode === value)[0] ?? null;
    this.ClinicalForm?.patchValue({
      itemData: {
        txtItemcode: drpitem.hcpcCode,
        drpLocation: "",
      },
      itemPurchase: {
        txtCharge: drpitem.price,
      },
    });
  }

  //! check for  Atleast One Branch
  checkForBranchexisitAtleastOne(): boolean {
    if (this.drpLocationTypes?.length === 0) {
      const messageToDisplay =
        'Please Add Atleast One Branch in Admin Settings';
      Swal.fire({
        icon: 'info',
        text: messageToDisplay,
      });
      return true;
    }
    return false;
  }

  demoHide: boolean;
  presHide: boolean;
  faceHide: boolean;
  complHide: boolean;
  sleepHide: boolean;
  // demographics values push into array
  addAccordion() {
    this.demo1st = false;
    this.demo2nd = true;
    if (
      this.demoLength.length ===
      this.PreviewExtractionForm.value.Demographics.length ||
      this.demoLength.length <
      this.PreviewExtractionForm.value.Demographics.length
    ) {
      this.demoHide = true;
    }
    this.demoAccordion = true;
    this.t.push(
      this.formBuilder.group({
        txtFirstName: ['', this.textValidators.isTextCheck],
        txtDOB: [''],
        txtMiddleName: [''],
        txtLastName: [''],
        drpGeneralGender: [''],
        txtReason: [''],
      })
    );
  }

  // faceToface values push into array
  addFaceAccordion() {
    this.face1st = false;
    this.face2nd = true;
    if (
      this.complienceLength.length ===
      this.PreviewExtractionForm.value.faceToface.length ||
      this.complienceLength.length <
      this.PreviewExtractionForm.value.faceToface.length
    ) {
      this.faceHide = true;
    }
    this.b.push(
      this.formBuilder.group({
        txtDateofVisit: [''],
        txtPatientinfo: [''],
        txtHeightFeet: ['', this.numberValidators.isNumberCheck],
        txtHeightInches: new FormControl('', [
          Validators.min(0),
          Validators.max(11),
          this.numberValidators.isNumberCheck,
        ]),

        txtWeight: ['', this.numberValidators.isNumberCheck],
        txtDoctor: [''],
        txtNpiNumber: ['', this.numberValidators.isNumberCheck],
        txtSignature: [''],
        txtSignedDate: [''],
        chkPrevioushistory: [''],
        txtordSearchFilter: [''],
        email: ['', [Validators.email]],
        txtFobPatientName: [''],
        txtIobDob: [''],
        txtIobFadeToFace: [''],
        chkSleepTestRecommended: [''],
        chkSleepDoctorSigned: [''],
        chkSleepPatientBenifited: [''],
        txtFobPatientBenifitNotes: [''],
        drpSymptoms: [],
      })
    );
  }

  // sleepStudy values push into array
  addSleepAccordion() {
    this.sleep1st = false;
    this.sleep2nd = true;
    if (
      this.sleepStudyLength.length ===
      this.PreviewExtractionForm.value.sleepStudy.length ||
      this.sleepStudyLength.length <
      this.PreviewExtractionForm.value.sleepStudy.length
    ) {
      this.sleepHide = true;
    }
    this.d?.push(
      this.formBuilder.group({
        txtPatientName1: ['', this.textValidators.isTextCheck],
        txtDob: [''],
        txtSleepMinutes: [''],
        // txtSleepMinutes: [''],
        txtREI: ['', this.numberValidators.isNumberCheck],
        txtRDI: ['', this.numberValidators.isNumberCheck],
        txtAHI: ['', [this.numberValidators.isNumberCheck]],
        txtdiagnosisCode10Id: [''],
        txtdiagnosisCode10I1: [''],
        txtIcd10Filter: [''],
        txtStudyDate: [''],
        txtPressureSetting: [''],
      })
    );
  }

  // priscription values push into array
  addPrescriptionAccordion() {
    this.prescription1st = false;
    this.prescription2nd = true;
    if (
      this.prescriptionLength.length ===
      this.PreviewExtractionForm.value.priscription.length ||
      this.prescriptionLength.length <
      this.PreviewExtractionForm.value.priscription.length
    ) {
      this.presHide = true;
    }
    this.h.push(
      this.formBuilder.group({
        txtPatientName2: ['', this.textValidators.isTextCheck],
        txtDOB2: [''],
        txtPrescriptionDate: [''],
        txtprescriptionsDoctorSign: [''],
        txtDoctor: [''],
        txtDoctorSearchFilter: [''],
        drpMachineDetails: [''],
        txtPressureLevel: [''],
        txtLengthOfNeed: [''],
        txtNpiNumber: ['', this.numberValidators.isNumberCheck],
        chkDoctorSigned: [''],
        txtSignedDate: [''],
        txtItemcode: [''],
        txtSearchFilter: [''],
        txtDuration: [''],
        txtEstimatedLength: [''],
        txtSupplierName: ['', this.textValidators.isTextCheck],
        txtSupplierDetials: [''],
        txtdiagnosisCode10Id1: [''],
        txtIcd10Filter1: [''],
        txtItemcodeSearch: [''],
      })
    );
  }

  // Complience values push into array
  addComplienceAccordion() {
    this.complience1st = false;
    this.complience2nd = true;
    if (
      this.complienceLength.length ===
      this.PreviewExtractionForm.value.Complience.length ||
      this.complienceLength.length <
      this.PreviewExtractionForm.value.Complience.length
    ) {
      this.complHide = true;
    }
    this.j.push(
      this.formBuilder.group({
        txtPatientName3: ['', this.textValidators.isTextCheck],
        txtDOB3: [''],
        txtStudyStartDate: [''],
        txtStudyEndDate: [''],
        rbnComplianceMet: [''],
        txtCompliancePercentage: [''],
        txtUsagedays: [''],
        txtUsagedaysOutOf: [''],
        txtGreater4Hours: [''],
        txtLess4hours: [''],
      })
    );
  }

  // Insurance values push into array
  // addInsurance() {
  //   this.Insurance1st = false
  //   this.Insurance2nd = true
  //   this.m.push(this.formBuilder.group({
  //     drpInsurance: [''],
  //   }));
  // }

  demographicsArr: DemographicsDTO[] = [];
  faceTofaceArr: InitialFaceToFaceDTO[] = [];
  sleepStudyArr: SleepStudyDTO[] = [];
  ComplienceArr: ComplainceDTO[] = [];
  priscriptionArr: PrescriptionDTO[] = [];
  // AuthorizationArr: Auth

  // get values form Demographics
  getDemographicsValues() {
    return this.PreviewExtractionForm.value.Demographics;

  }

  // get values form faceToface
  getFaceToFaceValues() {
    return this.PreviewExtractionForm.value.faceToface;
  }

  // get values form sleepStudy
  getSleepStudyValues() {
    return this.PreviewExtractionForm.value.sleepStudy;
  }

  // get values form Complience
  getComplienceValues() {
    return this.PreviewExtractionForm.value.Complience;
  }

  // get values form priscription
  getPriscriptionValues() {
    return this.PreviewExtractionForm.value.priscription;
  }

  //// Save function
  primaryIns = null;
  secondaryIns = null;
  tertiaryIns = null;
  primaryAuth = null;
  secondaryAuth = null;
  tertiaryAuth = null;
  onSubmit() {
    this.submitted = true;
    let PatienetFromDocument: CreateUpdatePatientsDTO;
    // if(this.patientsId === "" || this.patientsId === null || this.patientsId === undefined){
    const demoValues = this.getDemographicsValues();
    const faceToFaceValues = this.getFaceToFaceValues();
    const sleepStudyValues = this.getSleepStudyValues();
    const ComplienceValues = this.getComplienceValues();
    const priscriptionValues = this.getPriscriptionValues();
    this.demographicsArr = [];
    demoValues.forEach((element) => {
      this.demographicsArr?.push({
        demographicsId: defaultGuid,
        firstName: element?.txtFirstName ?? "",
        lastName: element?.txtLastName ?? "",
        middleName: element?.txtMiddleName ?? "",
        gender: element?.drpGeneralGender ?? "",
        dateOfBirth: element?.txtDOB ?? null,
        patientName: '',
      });
    });
    if (this.pateintDemoDetailsCheck()) {
      return
    }

    this.faceTofaceArr = [];
    faceToFaceValues.forEach((element) => {
      this.faceTofaceArr?.push({
        initialFaceToFaceId: defaultGuid,
        patientName: element?.txtFobPatientName ?? "",
        dateOfBirth: element?.txtIobDob ?? "",
        faceToFaceDate: element?.txtIobFadeToFace ?? "",
        doctorName: element?.txtDoctor ?? "",
        npi: element?.txtNpiNumber ?? "",
        doctorSignature: element?.txtSignature ?? "",
        apneaTreatment: element?.txtTreatment ?? "",
        dateOfVisit: element?.txtDateofVisit ?? "",
        patientInfo: element?.txtPatientinfo ?? "",
        hieght: +element?.txtHeightFeet | 0,
        weight: +element?.txtWeight | 0,
        signedDate: element?.txtSignedDate ?? null,
        previousHistoryDeclaration: +element?.chkPrevioushistory | 0,
        //! new
        isSleepTestRecommended: +element?.chkSleepTestRecommended ?? 0,
        isDoctorSigned: +element?.chkSleepDoctorSigned ?? 0,
        isPatientBenifited: + element?.chkSleepPatientBenifited ?? 0,
        patientBenefitNotes: element?.txtFobPatientBenifitNotes ?? "",
        symptoms: element?.drpSymptoms ?? [],
      });
    });

    this.sleepStudyArr = [];
    sleepStudyValues.forEach((element) => {
      let diagnosticSleepStudy: DiagnosticSleepStudyDTO = null;
      let totalSleepMins: string = isNaN(+element?.txtSleepMinutes ?? 0) === true ? "" : (String(+(parseInt(element?.txtSleepMinutes) || 0) ?? ""));
      let ahi: number = isNaN(+element?.txtAHI ?? 0) === true ? 0 : (+element?.txtAHI ?? 0);
      let diagnosticSleepStudyDate: string | null = element?.txtDiagnosisStudyDate ?? null;
      let isDoctorSigned: number = isNaN(+element?.chkSleepStudyDoctorSigned ?? 0) === true ? 0 : (+element?.chkSleepStudyDoctorSigned ?? 0);
      let centralApneas: number = isNaN(+element?.txtSSCentralApneas ?? 0) === true ? 0 : (+element?.txtSSCentralApneas ?? 0);
      let centralHyponeas: number = isNaN(+ element?.txtSSCentralHyponeas ?? 0) === true ? 0 : (+element?.txtSSCentralHyponeas ?? 0);
      //  let centralAHI: number = isNaN(+ element?.txtSSCentralAHI ?? 0) === true ? 0 : (+element?.txtSSCentralAHI ?? 0);
      let totalApneaAndHypopnea: number = isNaN(+ element?.txtSSApneaAndHypopnea ?? 0) === true ? 0 : + element?.txtSSApneaAndHypopnea ?? 0;
      if (totalSleepMins || ahi || diagnosticSleepStudyDate || isDoctorSigned || centralApneas || centralHyponeas || totalApneaAndHypopnea) {
        diagnosticSleepStudy = {
          totalSleepMins: totalSleepMins ?? "",
          ahi: ahi ?? 0,
          diagnosticSleepStudyDate: diagnosticSleepStudyDate ?? null,
          isDoctorSigned: isDoctorSigned ?? 0,
          centralApneas: centralApneas ?? 0,
          centralHyponeas: centralHyponeas ?? 0,
          centralAHI: 0,
          totalApneaAndHypopnea: totalApneaAndHypopnea ?? 0,
        }
      }
      //! Titration Sleep Study
      let titrationSleepStudy: TitrationSleepStudyDTO = null;
      let isBaselineFileAvailable: number = isNaN(+element?.chkTSSBaselineFile ?? 0) === true ? 0 : (+element?.chkTSSBaselineFile ?? 0);
      let obstructiveApnea: number = isNaN(+element?.txtObstructiveApnea ?? 0) === true ? 0 : (+element?.txtObstructiveApnea ?? 0);
      let titrationSleepStudyDate: string | null = (element?.txtTitrationSleepStudyDate ?? null);
      let isCPAPTriedAndFail: number = isNaN(+ element?.chkCPAPTriedAndFail ?? 0) === true ? 0 : (+element?.chkCPAPTriedAndFail ?? 0);
      let isRespiratoryBreakdownAvailable: number = isNaN(+ element?.chkRespiratoryBreakdownAvailable ?? 0) === true ? 0 : (+element?.chkRespiratoryBreakdownAvailable ?? 0);
      let isTitrationtDoctorSigned: number = isNaN(+element?.chkTitrationSleepStudyDoctorSigned ?? 0) === true ? 0 : (+element?.chkTitrationSleepStudyDoctorSigned ?? 0);
      let Tahi: number = isNaN(+element?.txtTAHI ?? 0) === true ? 0 : (+element?.txtTAHI ?? 0);
      let TcentralApneas: number = isNaN(+element?.txtTCentralApneas ?? 0) === true ? 0 : (+element?.txtTCentralApneas ?? 0);
      let TcentralHyponeas: number = isNaN(+ element?.txtTCentralHyponeas ?? 0) === true ? 0 : (+element?.txtTCentralHyponeas ?? 0);
      let TtotalApneaAndHypopnea: number = isNaN(+ element?.txtTApneaAndHypopnea ?? 0) === true ? 0 : + element?.txtTApneaAndHypopnea ?? 0;

      if (isBaselineFileAvailable || obstructiveApnea || titrationSleepStudyDate || isCPAPTriedAndFail || Tahi || TcentralApneas || TcentralHyponeas || TtotalApneaAndHypopnea || isRespiratoryBreakdownAvailable || isTitrationtDoctorSigned) {
        titrationSleepStudy = {
          isBaselineFileAvailable: isBaselineFileAvailable ?? 0,
          obstructiveApnea: obstructiveApnea ?? 0,
          titrationSleepStudyDate: titrationSleepStudyDate ?? null,
          isCPAPTriedAndFail: isCPAPTriedAndFail ?? 0,
          isRespiratoryBreakdownAvailable: isRespiratoryBreakdownAvailable ?? 0,
          isDoctorSigned: isTitrationtDoctorSigned ?? 0,
          ahi: Tahi ?? 0,
          centralApneas: TcentralApneas ?? 0,
          centralHyponeas: TcentralHyponeas ?? 0,
          totalApneaAndHypopnea: TtotalApneaAndHypopnea ?? 0,
        }
      }
      //! home Sleep Test
      let homeSleepTest: HomeSleepTestDTO = null;
      let HStotalSleepMins: string = (String(+(parseInt(element?.txtHSMinutes) || 0)) ?? "");
      let HSahi: number = isNaN(+element?.txtHSAHI ?? 0) === true ? 0 : (+element?.txtHSAHI ?? 0);
      let HSisDoctorSigned: number = isNaN(+element?.chkHSDoctorSigned ?? 0) === true ? 0 : (+element?.chkHSDoctorSigned ?? 0);
      let HScentralApneas: number = isNaN(+element?.txtHSCentralApneas ?? 0) === true ? 0 : (+element?.txtHSCentralApneas ?? 0);
      let HScentralHyponeas: number = isNaN(+ element?.txtHSCentralHyponeas ?? 0) === true ? 0 : (+element?.txtHSCentralHyponeas ?? 0);
      // let HScentralAHI: number = isNaN(+ element?.txtHSCentralAHI ?? 0) === true ? 0 : (+element?.txtHSCentralAHI ?? 0);
      let HStotalApneaAndHypopnea: number = isNaN(+ element?.txtHSApneaAndHypopnea ?? 0) === true ? 0 : (+element?.txtHSApneaAndHypopnea ?? 0);
      let HSisPatientSigned: number = isNaN(+element?.chkHSpateintSigned ?? 0) === true ? 0 : (+element?.chkHSpateintSigned ?? 0);
      let HomeSleepTestDate: string = element.txtHomeSleepStudyDate ?? null
      if (HStotalSleepMins || HSahi || HSisDoctorSigned || HScentralApneas || HScentralHyponeas || HStotalApneaAndHypopnea || HSisPatientSigned || HomeSleepTestDate) {
        homeSleepTest = {
          totalSleepMins: HStotalSleepMins ?? "",
          ahi: HSahi ?? 0,
          isDoctorSigned: HSisDoctorSigned ?? 0,
          centralApneas: HScentralApneas ?? 0,
          centralHyponeas: HScentralHyponeas ?? 0,
          centralAHI: 0,
          totalApneaAndHypopnea: HStotalApneaAndHypopnea ?? 0,
          isPatientSigned: HSisPatientSigned ?? 0,
          homeSleepTestDate: HomeSleepTestDate
        }
      }
      this.sleepStudyArr?.push({
        sleepStudyId: defaultGuid,
        patientName: element?.txtPatientName1 ?? "",
        dateOfBirth: element?.txtDob ?? "",
        sleepStudyDate: element?.txtStudyDate ?? "",
        rdi: element?.txtRDI == '' ? 0 : element?.txtRDI,
        rei: element?.txtREI == '' ? 0 : element?.txtREI,
        diagnosisCode:
          element?.txtdiagnosisCode10Id == ''
            ? []
            : element?.txtdiagnosisCode10Id ?? "",
        pressureSetting: element?.txtPressureSetting ?? "",
        //! new
        isCPAPReturned: +element?.chkisCPAPReturned ?? 0,
        diagnosticSleepStudy: diagnosticSleepStudy ?? null,
        titrationSleepStudy: titrationSleepStudy ?? null,
        homeSleepTest: homeSleepTest ?? null,
        ahi: 0
      });
    });

    this.ComplienceArr = [];
    ComplienceValues.forEach((element) => {
      this.ComplienceArr?.push({
        complainceId: defaultGuid,
        patientName: element?.txtPatientName3 ?? "",
        dateOfBirth: element?.txtDOB3 ?? "",
        studyStartDate: element?.txtStudyStartDate ?? "",
        studyEndDate: element?.txtStudyEndDate ?? "",
        isComplainceMet: +element?.rbnComplianceMet | 0,
        complaincePercentage: element?.txtCompliancePercentage ?? "",
        usageDays: element?.txtUsagedays ?? "",
        greaterThanFourHrs: element?.txtGreater4Hours ?? "",
        lessThanFourHrs: element?.txtLess4hours ?? "",
        outOfDays: element?.txtUsagedaysOutOf ?? "",
      });
    });

    this.priscriptionArr = [];
    priscriptionValues.forEach((element) => {
      this.priscriptionArr?.push({
        prescriptionId: defaultGuid,
        patientName: element?.txtPatientName2 ?? "",
        dateOfBirth: element?.txtDOB2 ?? "",
        prescriptionDate: element?.txtPrescriptionDate ?? "",
        doctorName: element?.txtDoctor ?? "",
        machineDetails: element?.drpMachineDetails ?? "",
        pressureLevel: element?.txtPressureLevel ?? "",
        lengthOfNeed: element?.txtLengthOfNeed ?? "",
        diagnosticCode:
          element?.txtdiagnosisCode10Id1 == ''
            ? []
            : element?.txtdiagnosisCode10Id1 ?? "",
        suppliesDetails: element?.txtSupplierDetials ?? "",
        isDoctorSigned: +element?.chkDoctorSigned | 0,
        npi: element?.txtNpiNumber ?? "",
        doctorSignedDate: element?.txtSignedDate ?? "",
        itemId: element?.txtItemcode ?? "",
        duration: element?.txtDuration ?? "",
        estimatedLength: element?.txtEstimatedLength ?? "",
        supplierName: element?.txtSupplierName ?? "",
        //! new
        doctorSignature: element?.txtprescriptionsDoctorSign ?? "",
      });
    });

    let zermidIns = this.drpPayerId?.filter(value => {
      return value && value?.name === this.PreviewExtractionForm.value.drpPayorName
    })[0];
    let pverifyIns = this.drpPlan?.filter(value => {
      return value && value?.name === this.PreviewExtractionForm.value.drpInsurance
    })[0];
    const isNeedtoCheckDemo = this.lstIndex?.filter(e => e?.documentType === 'DEMOGRAPHICS')[0] === undefined || this.lstIndex?.filter(e => e?.documentType === 'DEMOGRAPHICS')[0] === null ? 0 : 1;
    const isNeedtoCheckF2F = this.lstIndex?.filter(e => e?.documentType === 'FACE TO FACE')[0] === undefined || this.lstIndex?.filter(e => e?.documentType === 'FACE TO FACE')[0] === null ? 0 : 1;
    const isNeedtoCheckPres = this.lstIndex?.filter(e => e?.documentType === 'RX')[0] === undefined || this.lstIndex?.filter(e => e?.documentType === 'RX')[0] === null ? 0 : 1;
    const isNeedtoCheckSleep = this.lstIndex?.filter(e => e?.documentType === 'SLEEP STUDY REPORT')[0] === undefined || this.lstIndex?.filter(e => e?.documentType === 'SLEEP STUDY REPORT')[0] === null ? 0 : 1;
    const isNeedtoCheckComplaince = this.lstIndex?.filter(e => e?.documentType === 'COMPLIANCE REPORT')[0] === undefined || this.lstIndex?.filter(e => e?.documentType === 'COMPLIANCE REPORT')[0] === null ? 0 : 1;
    const isNeedtoCheckVerify = this.lstIndex?.filter(e => e?.documentType === 'INSURANCE VERIFICATION')[0] === undefined || this.lstIndex?.filter(e => e?.documentType === 'INSURANCE VERIFICATION')[0] === null ? 0 : 1;

    ///// insurance payload for primary
    if (this.PreviewExtractionForm.value.drpPayorLevel === 'Primary') {
      this.primaryIns = {
        verifyType: this.PreviewExtractionForm.value.drpVerifyType,
        insuranceType: this.PreviewExtractionForm.value.drpPayorLevel,
        pVerifyInsName: pverifyIns?.name,
        pVerifyInsCode: pverifyIns?.code,
        zermidInsName: zermidIns?.name,
        zermidInsCode: zermidIns?.payerCode,
        policyNo: this.PreviewExtractionForm.value.txtMemberId,
        notes: "",
        lastEdit: "",
        added: "",
        provider: this.PreviewExtractionForm.value.drpProvider
      }
      this.secondaryIns = this.insuranceAfterSave?.insurances?.secondaryInsurance
      this.tertiaryIns = this.insuranceAfterSave?.insurances?.tertiaryInsurance
    }

    ///// insurance payload for secondary
    if (this.PreviewExtractionForm.value.drpPayorLevel === 'Secondary') {
      this.secondaryIns = {
        verifyType: this.PreviewExtractionForm.value.drpVerifyType,
        insuranceType: this.PreviewExtractionForm.value.drpPayorLevel,
        pVerifyInsName: pverifyIns?.name,
        pVerifyInsCode: pverifyIns?.code,
        zermidInsName: zermidIns?.name,
        zermidInsCode: zermidIns?.payerCode,
        policyNo: this.PreviewExtractionForm.value.txtMemberId,
        notes: "",
        lastEdit: "",
        added: "",
        provider: this.PreviewExtractionForm.value.drpProvider
      }
      this.primaryIns = this.insuranceAfterSave?.insurances?.primaryInsurance
      this.tertiaryIns = this.insuranceAfterSave?.insurances?.tertiaryInsurance
    }

    ///// insurance payload for Tertiary
    if (this.PreviewExtractionForm.value.drpPayorLevel === 'Tertiary') {
      this.tertiaryIns = {
        verifyType: this.PreviewExtractionForm.value.drpVerifyType,
        insuranceType: this.PreviewExtractionForm.value.drpPayorLevel,
        pVerifyInsName: pverifyIns?.name,
        pVerifyInsCode: pverifyIns?.code,
        zermidInsName: zermidIns?.name,
        zermidInsCode: zermidIns?.payerCode,
        policyNo: this.PreviewExtractionForm.value.txtMemberId,
        notes: "",
        lastEdit: "",
        added: "",
        provider: this.PreviewExtractionForm.value.drpProvider
      }
      this.primaryIns = this.insuranceAfterSave?.insurances?.primaryInsurance
      this.secondaryIns = this.insuranceAfterSave?.insurances?.secondaryInsurance
    }


    if (this.PreviewExtractionForm.value.drpAuthInsurance === 'Primary') {
      this.primaryAuth = {
        plan: this.PreviewExtractionForm.value.drpAuthInsurance,
        authId: this.PreviewExtractionForm.value.txtAuthId,
        authVisits: this.PreviewExtractionForm.value.drpAuthVisit,
        authAmount: this.PreviewExtractionForm.value.drpAuthAmount,
        category: this.PreviewExtractionForm.value.drpCategory,
        startDate: this.PreviewExtractionForm.value.txtStartDate,
        endDate: this.PreviewExtractionForm.value.txtEndDate,
        isCompleted: this.PreviewExtractionForm.value.chkCompleted === true || this.PreviewExtractionForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.PreviewExtractionForm.value.txtCompletedBy,
        note: this.PreviewExtractionForm.value.txtNote,
        item: [],
        units: this.PreviewExtractionForm.value.txtUnits,
        used: this.PreviewExtractionForm.value.txtUsed,
        saleOrRent: this.PreviewExtractionForm.value.txtSaleOrRent,
        isExpired: +(this.PreviewExtractionForm.value.txtExpired),
        isFollowUp: +(this.PreviewExtractionForm.value.chkFollowUp),
        itemCodes: this.PreviewExtractionForm.value.txtItemCodes,
      }
      this.secondaryAuth = this.insuranceAfterSave?.authorizations?.secondaryAuthorizations
      this.tertiaryAuth = this.insuranceAfterSave?.authorizations?.tertiaryAuthorizations
    }

    if (this.PreviewExtractionForm.value.drpAuthInsurance === 'Secondary') {
      this.secondaryAuth = {
        plan: this.PreviewExtractionForm.value.drpAuthInsurance,
        authId: this.PreviewExtractionForm.value.txtAuthId,
        authVisits: this.PreviewExtractionForm.value.drpAuthVisit,
        authAmount: this.PreviewExtractionForm.value.drpAuthAmount,
        category: this.PreviewExtractionForm.value.drpCategory,
        startDate: this.PreviewExtractionForm.value.txtStartDate,
        endDate: this.PreviewExtractionForm.value.txtEndDate,
        isCompleted: this.PreviewExtractionForm.value.chkCompleted === true || this.PreviewExtractionForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.PreviewExtractionForm.value.txtCompletedBy,
        note: this.PreviewExtractionForm.value.txtNote,
        item: [],
        units: this.PreviewExtractionForm.value.txtUnits,
        used: this.PreviewExtractionForm.value.txtUsed,
        saleOrRent: this.PreviewExtractionForm.value.txtSaleOrRent,
        isExpired: +(this.PreviewExtractionForm.value.txtExpired),
        isFollowUp: +(this.PreviewExtractionForm.value.chkFollowUp),
        itemCodes: this.PreviewExtractionForm.value.txtItemCodes
      }
      this.primaryAuth = this.insuranceAfterSave?.authorizations?.primaryAuthorizations
      this.tertiaryAuth = this.insuranceAfterSave?.authorizations?.tertiaryAuthorizations
    }

    if (this.PreviewExtractionForm.value.drpAuthInsurance === 'Tertiary') {
      this.tertiaryAuth = {
        plan: this.PreviewExtractionForm.value.drpAuthInsurance,
        authId: this.PreviewExtractionForm.value.txtAuthId,
        authVisits: this.PreviewExtractionForm.value.drpAuthVisit,
        authAmount: this.PreviewExtractionForm.value.drpAuthAmount,
        category: this.PreviewExtractionForm.value.drpCategory,
        startDate: this.PreviewExtractionForm.value.txtStartDate,
        endDate: this.PreviewExtractionForm.value.txtEndDate,
        isCompleted: this.PreviewExtractionForm.value.chkCompleted === true || this.PreviewExtractionForm.value.chkCompleted === 1 ? "Done" : "Pending",
        completedBy: this.PreviewExtractionForm.value.txtCompletedBy,
        note: this.PreviewExtractionForm.value.txtNote,
        item: [],
        units: this.PreviewExtractionForm.value.txtUnits,
        used: this.PreviewExtractionForm.value.txtUsed,
        saleOrRent: this.PreviewExtractionForm.value.txtSaleOrRent,
        isExpired: +(this.PreviewExtractionForm.value.txtExpired),
        isFollowUp: +(this.PreviewExtractionForm.value.chkFollowUp),
        itemCodes: this.PreviewExtractionForm.value.txtItemCodes
      }
      this.primaryAuth = this.insuranceAfterSave?.authorizations?.primaryAuthorizations
      this.secondaryAuth = this.insuranceAfterSave?.authorizations?.secondaryAuthorizations
    }


    PatienetFromDocument = {
      demographics: this.demographicsArr,
      initialFaceToFaces: this.faceTofaceArr,
      sleepStudies: this.sleepStudyArr,
      prescriptions: this.priscriptionArr,
      complainces: this.ComplienceArr,
      verifications: {
        primaryInsuranceVerifies: this.insuranceAfterSave?.verifications?.primaryInsuranceVerifies,
        secondaryInsuranceVerifies: this.insuranceAfterSave?.verifications?.secondaryInsuranceVerifies,
        tertiaryInsuranceVerifies: this.insuranceAfterSave?.verifications?.tertiaryInsuranceVerifies
      },
      orders: {
        pos: this.PreviewExtractionForm.value.drpGeneralPlaceOfService,
        isCashPayment: 0,
        isCompleted: this.PreviewExtractionForm.value.chkWorkProgressCompleted === true ? "Done" : "Pending",
        totalItemQty: 0,
        totalItemPrice: 0,
        items: this.insuranceAfterSave?.orders?.items
      },
      batchId: this.fileId,
      authorizations: {
        primaryAuthorizations: this.primaryAuth,
        secondaryAuthorizations: this.secondaryAuth,
        tertiaryAuthorizations: this.tertiaryAuth
      },
      insurances: {
        primaryInsurance: this.primaryIns,
        secondaryInsurance: this.secondaryIns,
        tertiaryInsurance: this.tertiaryIns,
      },
      fileId: this.fileId,
      isDemographicsDataAvailable: isNeedtoCheckDemo,
      isInitialFaceToFaceDataAvailable: isNeedtoCheckF2F,
      isSleepStudyDataAvailable: isNeedtoCheckSleep,
      isPrescriptionDataAvailable: isNeedtoCheckPres,
      isComplainceDataAvailable: isNeedtoCheckComplaince,
      isVerificationDataAvailable: isNeedtoCheckVerify,
      isRuleCheck: this.ruleCheck,
      compliancePendingValidationCount: this.compliancePendingValidationCount ?? 0,
      demographicsPendingValidationCount: this.demographicsPendingValidationCount ?? 0,
      initialFaceToFacePendingValidationCount: this.initialFaceToFacePendingValidationCount ?? 0,
      prescriptionPendingValidationCount: this.prescriptionPendingValidationCount ?? 0,
      sleepStudyPendingValidationCount: this.sleepStudyPendingValidationCount ?? 0,
      verificationPendingValidationCount: this.verificationPendingValidationCount ?? 0,
      isCompSA: this.isCompSA,
      percentageOfCompletion: this.percentageOfCompletion,
      isRulesNeeded: +this.PreviewExtractionForm.value.chkIsRulesNeeded ?? 0,
      dxType: this.PreviewExtractionForm.value.drpDXType,
      setupType: this.PreviewExtractionForm.value.drpDXType === DXTypes.CPAPtoBIPAP || this.PreviewExtractionForm.value.drpDXType === DXTypes.CompSA ? SetupTypes.None : this.PreviewExtractionForm.value.drpPrdSetupType,
      approvedPatientId: this.insuranceAfterSave?.approvedPatientId,
      saleOrderId: this.insuranceAfterSave?.saleOrderId
    };


    if (
      this.patientsId === '' ||
      this.patientsId === defaultGuid ||
      this.patientsId === undefined
    ) {
      this.patientsService.create(PatienetFromDocument).subscribe(
        (response) => {
          this.patientsId = response?.id;
          this.getPatientSIdFormPatientSDetails(this.patientsId);
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Saved Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Saved Successfully','Success')
          if (response?.percentageOfCompletion === 100) {
            this.percentageOfCompletion = 100;
            this.prescriptionPendingValidationCount = 0;
            this.compliancePendingValidationCount = 0;
            this.initialFaceToFacePendingValidationCount = 0;
            this.sleepStudyPendingValidationCount = 0;
            this.demographicsPendingValidationCount = 0;
            this.verificationPendingValidationCount = 0;
          } else {
            this.percentageOfCompletion = response?.percentageOfCompletion;
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          const errorCnt: { msg: string; type: number; btntxt: string; iconLogo: SweetAlertIcon; } = this.getRuleMasterErrorMsg(
            data?.error?.error?.message, data.error.error
          );
          if (errorCnt.type === 1) {
            Swal.fire({
              icon: errorCnt.iconLogo,
              html: errorCnt.msg,
              //text: data?.error?.error?.message,
              showCancelButton: true,
              customClass: { container: 'swal2-popup2' },
              cancelButtonColor: '#f46a6a',
              confirmButtonColor: '#34c38f',
              confirmButtonText: errorCnt.btntxt,
            }).then((result) => {
              if (result.value == true) {
                this.ruleCheck = 0;
                this.isCompSA = 1;
                this.onSubmit();
              }
            });
          } else {
            Swal.fire({
              icon: errorCnt.iconLogo,
              html: errorCnt.msg,
            });
          }
        });
    } else {
      this.patientsService
        .update(this.patientsId, PatienetFromDocument)
        .subscribe(
          (response) => {
            this.patientsId = response?.id;
            if (response?.orders?.isCompleted === 'Done') {
              this.fieldHide = false
            }

            /// swal alert display if sale order generated
            if (response?.orders?.isCompleted === 'Done') {
              // Swal.fire({
              //   title: 'Success',
              //   html: 'Sale Order created Successfully',
              //   icon: 'success',
              //   timer: 3000,
              //   timerProgressBar: true,
              // });
              this.toastr.success('Sale Order created Successfully','Success')
            }

            /// swal alert display if sale order not generated
            else {
              // Swal.fire({
              //   title: 'Success',
              //   html: 'Saved Successfully',
              //   icon: 'success',
              //   timer: 3000,
              //   timerProgressBar: true,
              // });
              this.toastr.success('Saved Successfully','Success')
            }

            if (response?.percentageOfCompletion === 100) {
              this.percentageOfCompletion = 100;
              this.prescriptionPendingValidationCount = 0;
              this.compliancePendingValidationCount = 0;
              this.initialFaceToFacePendingValidationCount = 0;
              this.sleepStudyPendingValidationCount = 0;
              this.demographicsPendingValidationCount = 0;
              this.verificationPendingValidationCount = 0;
            } else {
              this.percentageOfCompletion = response?.percentageOfCompletion;
            }
          },
          (err) => {
            const data: HttpErrorResponse = err;

            const errorCnt: { msg: string; type: number; btntxt: string; iconLogo: SweetAlertIcon; } = this.getRuleMasterErrorMsg(data?.error?.error?.message, data.error.error);
            if (errorCnt.type === 1) {
              Swal.fire({
                icon: errorCnt.iconLogo,
                html: errorCnt.msg,
                //text: data?.error?.error?.message,
                showCancelButton: true,
                customClass: { container: 'swal2-popup2' },
                cancelButtonColor: '#f46a6a',
                confirmButtonColor: '#34c38f',
                confirmButtonText: errorCnt.btntxt,
              }).then((result) => {
                if (result.value == true) {
                  this.ruleCheck = 0;
                  this.isCompSA = 1;
                  this.onSubmit();
                }
              });
            } else {
              Swal.fire({
                icon: errorCnt.iconLogo,
                html: errorCnt.msg,
              });
            }
          });
    }
    this.ruleCheck = 1;
    // stop here if form is invalid
    if (this.PreviewExtractionForm.invalid) {
      return;
    }
  }
  pateintDemoDetailsCheck() {

    if (this.demographicsArr[0] && String(this.demographicsArr[0]?.firstName)?.length === 0) {
      Swal.fire("Please enter First Name in Demographics");
      return true;

    }
    if (this.demographicsArr[0] && String(this.demographicsArr[0]?.lastName)?.length === 0) {
      Swal.fire("Please enter Last Name in Demographics");
      return true;
    }
    if (this.demographicsArr[0] && this.demographicsArr[0]?.dateOfBirth === null || this.demographicsArr[0]?.dateOfBirth === undefined) {
      Swal.fire("Please enter Date Of Birth in Demographics");
      return true;

    }
  }

  //// swal validation from form values
  pateintDemofromFormCheck(status) {
    if (status == UploadedFileStatus.Approved) {
      if (this.PreviewExtractionForm.value.Demographics[0] && String(this.PreviewExtractionForm.value.Demographics[0]?.txtFirstName)?.length === 0) {
        Swal.fire("Please enter First Name in Demographics");
        return true;

      }
      if (this.PreviewExtractionForm.value.Demographics[0] && String(this.PreviewExtractionForm.value.Demographics[0]?.txtLastName)?.length === 0) {
        Swal.fire("Please enter Last Name in Demographics");
        return true;
      }
      if (this.PreviewExtractionForm.value.Demographics[0] && this.PreviewExtractionForm.value.Demographics[0]?.txtDOB === null || this.PreviewExtractionForm.value.Demographics[0]?.txtDOB === undefined) {
        Swal.fire("Please enter Date Of Birth in Demographics");
        return true;

      }
    }

  }

  //// drop down on change
  onPayorsChanges(event) {
    if (this.patientsId !== null || this.patientsId !== "" || this.patientsId !== undefined) {
      this.patientsService.get(this.patientsId).subscribe((res) => {
        //// dropdown value primary and binding saved datas
        if (event?.value === 'Primary') {
          if (res?.insurances?.primaryInsurance?.insuranceType === event?.value) {
            this.PreviewExtractionForm?.patchValue({
              drpPayorLevel: res?.insurances?.primaryInsurance?.insuranceType,
              drpPayorName: res?.insurances?.primaryInsurance?.zermidInsName,
              txtMemberId: res?.insurances?.primaryInsurance?.policyNo,
              drpProvider: res?.insurances?.primaryInsurance?.provider,
              drpVerifyType: res?.insurances?.primaryInsurance?.verifyType,
              drpInsurance: res?.insurances?.primaryInsurance?.pVerifyInsName
            })
          } else {
            this.PreviewExtractionForm?.patchValue({
              // drpPayorLevel: "",
              drpPayorName: "",
              txtMemberId: "",
              drpProvider: "",
              drpVerifyType: "",
              drpInsurance: ""
            })
          }
        }

        //// dropdown value secondary and binding saved datas
        if (event?.value === 'Secondary') {
          if (res?.insurances?.secondaryInsurance?.insuranceType === event?.value) {
            this.PreviewExtractionForm?.patchValue({
              drpPayorLevel: res?.insurances?.secondaryInsurance?.insuranceType,
              drpPayorName: res?.insurances?.secondaryInsurance?.zermidInsName,
              txtMemberId: res?.insurances?.secondaryInsurance?.policyNo,
              drpProvider: res?.insurances?.secondaryInsurance?.provider,
              drpVerifyType: res?.insurances?.secondaryInsurance?.verifyType,
              drpInsurance: res?.insurances?.secondaryInsurance?.pVerifyInsName
            })
          } else {
            this.PreviewExtractionForm?.patchValue({
              // drpPayorLevel: "",
              drpPayorName: "",
              txtMemberId: "",
              drpProvider: "",
              drpVerifyType: "",
              drpInsurance: ""
            })
          }
        }

        //// dropdown value tertiary and binding saved datas
        if (event?.value === 'Tertiary') {
          if (res?.insurances?.tertiaryInsurance !== null &&
            res?.insurances?.tertiaryInsurance?.insuranceType === event?.value) {
            this.PreviewExtractionForm?.patchValue({
              drpPayorLevel: res?.insurances?.tertiaryInsurance?.insuranceType,
              drpPayorName: res?.insurances?.tertiaryInsurance?.zermidInsName,
              txtMemberId: res?.insurances?.tertiaryInsurance?.policyNo,
              drpProvider: res?.insurances?.tertiaryInsurance?.provider,
              drpVerifyType: res?.insurances?.tertiaryInsurance?.verifyType,
              drpInsurance: res?.insurances?.tertiaryInsurance?.pVerifyInsName
            })
          } else {
            this.PreviewExtractionForm?.patchValue({
              // drpPayorLevel: "",
              drpPayorName: "",
              txtMemberId: "",
              drpProvider: "",
              drpVerifyType: "",
              drpInsurance: ""
            })
          }
        }
      })
    }
  }


  /// for enter number only
  numberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event?.which ? event?.which : event?.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  // Only Numbers with Decimals
  keyPressNumbersDecimal(event: { which: any; keyCode: any; preventDefault: () => void; }) {
    var charCode = event?.which ? event?.which : event?.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event?.preventDefault();
      return false;
    }
    return true;
  }

  //! To Displays currently open accordion with corresponding document page
  currentlyOpenAcc(accName: string) {

    switch (accName) {
      case "DEMOGRAPHICS": { this.pdfHeight = "80vh"; } break;
      case "FACE TO FACE": { this.pdfHeight = "130vh"; } break;
      case "SLEEP STUDY REPORT": { this.pdfHeight = "170vh"; } break;
      case "RX": { this.pdfHeight = "130vh"; } break;
      case "COMPLIANCE REPORT": { this.pdfHeight = "90vh"; } break;
      case "INSURANCE VERIFICATION": { this.pdfHeight = "80vh"; } break;
      case "AUTHORIZATION": { this.pdfHeight = "80vh"; } break;
      case "SALEORDER": { this.pdfHeight = "80vh"; } break;
      default: this.pdfHeight = "80vh";
    }

    const currentPageIndex: number =
      this.lstIndex?.find((value: SortedDataDTO) => { return value?.documentType === accName; })?.pageNumber || 1;
    this.pageNumber = currentPageIndex ?? 0;
    this.getRenderedPageNumber(this.pageNumber);
  }
  closeRule(ruleData: MasterRulesDTO) {
    this.selectedRules = this.selectedRules?.filter(rule =>
      rule !== ruleData?.id,
    );
  }
  openInsuranceVerification() {
    this.isInsuranceVerification = true;
  }
  //// clear all notification
  clearNotificationData() {
    if (this.selectedRules.length !== 0) {
      of(this.selectedRules).subscribe(() => {
        this.selectedRules.shift();
        this.clearNotificationData();
      })
      this.hideNotificatiaonButton = false
    }
  }

  getRuleMasterErrorMsg(message: string, _error: any): { msg: string; type: number; btntxt: string; iconLogo: SweetAlertIcon; } {
    let endMsg: string = '';
    const ruleErr = '{"ruleError":{"';
    let iconLogo: SweetAlertIcon = 'error';
    const inCrtDxSetupMsg = "Please select correct DX type and Setup type!";
    const inCrtValMsg = "Your request is not valid!";
    const complErrMsg = "Input string was not in a correct format.";
    let btntxt = "Close";
    let type: number = 0;
    this.percentageOfCompletion = 0;
    if (message && message?.includes(ruleErr) && message !== inCrtDxSetupMsg && message !== inCrtValMsg && message !== complErrMsg) {
      const pmessgae: customErrorMessage = JSON?.parse(message) ?? null;

      const faceToFaceErr: any[] = pmessgae?.ruleError?.faceToFace ?? [];
      const prescriptionErr: any[] = pmessgae?.ruleError?.prescription ?? [];
      const sleepStudyErr: any[] = pmessgae?.ruleError?.sleepStudy ?? [];
      if (faceToFaceErr && faceToFaceErr?.length !== 0) {
        let messgtoDisplayStart: string = "<span><b>Face To Face</b></span<br> <table style=' margin-left: auto; margin-right: auto;text-align: -webkit-auto;'><tbody>";
        let messgtoDisplayInnerCon: string = messgtoDisplayStart + '';
        faceToFaceErr?.forEach(element => {
          messgtoDisplayInnerCon += "<tr><td style='display:contents;'>" + `${element?.ruleNumber}` + `${'- ' + element?.rule}` + '</td></tr>';
        })
        const messgtoDisplayEnd = messgtoDisplayInnerCon + '</tbody></table>';
        endMsg += messgtoDisplayEnd;
      }
      if (prescriptionErr && prescriptionErr?.length !== 0) {
        let messgtoDisplayStart: string = "<span><b>Prescription</b></span<br> <table style=' margin-left: auto; margin-right: auto;text-align: -webkit-auto;'><tbody>";
        let messgtoDisplayInnerCon: string = messgtoDisplayStart + '';
        prescriptionErr?.forEach(element => {
          messgtoDisplayInnerCon += "<tr><td style='display:contents;'>" + `${element?.ruleNumber}` + `${'- ' + element?.rule}` + '</td></tr>';
        })
        const messgtoDisplayEnd = messgtoDisplayInnerCon + '</tbody></table>';
        endMsg += messgtoDisplayEnd;

      }
      if (sleepStudyErr && sleepStudyErr?.length !== 0) {
        let messgtoDisplayStart: string = "<span><b>Sleep Study</b></span<br> <table style=' margin-left: auto; margin-right: auto;text-align: -webkit-auto;'><tbody>";
        let messgtoDisplayInnerCon: string = messgtoDisplayStart + '';
        sleepStudyErr?.forEach(element => {
          messgtoDisplayInnerCon += "<tr><td style='display:contents;'>" + `${element?.ruleNumber}` + `${'- ' + element?.rule}` + '</td></tr>';
        })
        const messgtoDisplayEnd = messgtoDisplayInnerCon + '</tbody></table>';
        endMsg += messgtoDisplayEnd;

      }

      this.compliancePendingValidationCount = pmessgae?.compliancePendingValidationCount ?? 0;
      this.demographicsPendingValidationCount = pmessgae?.demographicsPendingValidationCount ?? 0;
      this.initialFaceToFacePendingValidationCount = pmessgae?.initialFaceToFacePendingValidationCount ?? 0;
      this.prescriptionPendingValidationCount = pmessgae?.prescriptionPendingValidationCount ?? 0;
      this.sleepStudyPendingValidationCount = pmessgae?.sleepStudyPendingValidationCount ?? 0;
      this.verificationPendingValidationCount = pmessgae?.verificationPendingValidationCount ?? 0;
      iconLogo = 'info';
      this.percentageOfCompletion = isNaN(+pmessgae?.percentageOfCompletion ?? 0) === true ? 0 : (pmessgae?.percentageOfCompletion ?? 0);
      type = 1;//! for Tabular swal
      btntxt = 'Ignore and continue!';
    } else {
      type = 0;
      iconLogo = 'error';
      endMsg = String(message) ?? "";
      btntxt = btntxt;
    }
    return { msg: endMsg, type: type, iconLogo: iconLogo, btntxt: btntxt };
  }

  // open eligibility history modal
  openEligibilityHistoryModal() {
    const dialogRef = this.dialog.open(DocumentEligibilityHistoryComponent, {
      disableClose: true,
      minWidth: '60vw',
      data: { patientsId: this.patientsId }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  provider: string = '';
  payor: string = '';
  insurance: string = '';
  gender: string = '';
  generalPlaceOfService: string = '';
  clearProvider() {
    this.provider = '';
    this.PreviewExtractionForm.patchValue({ drpProvider: "" })
  }
  clearPayor() {
    this.payor = '';
    this.PreviewExtractionForm.patchValue({ drpPayorName: "" })
  }
  clearInsurance() {
    this.insurance = '';
    this.PreviewExtractionForm.patchValue({ drpInsurance: "" })
  }
  clearGender() {
    this.gender = "";
    this.PreviewExtractionForm.patchValue({ drpGeneralGender: '' });

  }
  clearSale() {
    this.sale = '';
    this.PreviewExtractionForm.patchValue({ txtSaleOrRent: "" });
  }
  clearItemCodes() {
    this.itemCodes = '';
    this.PreviewExtractionForm.patchValue({ txtItemCodes: "" });
  }
  clearGeneralPlaceOfService() {
    this.generalPlaceOfService = '';
    this.PreviewExtractionForm.patchValue({
      drpGeneralPlaceOfService: null
    });
  }

  ///// Add item Modal
  openAddItemModal() {
    const dialogRef = this.dialog.open(AdminAddItemModalComponent, {
      disableClose: true,

      data: { patientsId: this.insuranceAfterSave?.id }
    })
    dialogRef.afterClosed().subscribe(() => {
      this.getPatientSIdFormPatientSDetails(this.patientsId);
    })
  }
}

//// error message interface
export interface customErrorMessage {
  compliancePendingValidationCount: number
  demographicsPendingValidationCount: number
  initialFaceToFacePendingValidationCount: number
  prescriptionPendingValidationCount: number
  sleepStudyPendingValidationCount: number
  verificationPendingValidationCount: number
  percentageOfCompletion: number,
  ruleError: RulesError;
}
export interface RulesError {
  item: any[];
  faceToFace: { ruleNumber: string, rule: string }[];
  sleepStudy: { ruleNumber: string, rule: string }[];
  prescription: { ruleNumber: string, rule: string }[];
}

