import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { TableService } from 'projects/shared/src/app/table.service';
import Swal from 'sweetalert2';

import { GetRoleListUsingTenantIdDto } from '../../admin-proxy/platform-app-management/rcm/platform-management/role/models';
import { RoleService } from '../../admin-proxy/platform-app-management/rcm/platform-management/role/role.service';
import { IdentityRoleCreateOrUpdateDtoBase, IdentityRoleUpdateDto } from '../../admin-proxy/platform-app-management/volo/abp/identity';
import { IdentityRoleService } from '../../admin-proxy/volo/abp/identity';
import { ExecutivesRolesPermissionComponent } from './../executives-roles-permission/executives-roles-permission.component';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-executives-roles',
  templateUrl: './executives-roles.component.html',
  styleUrls: ['./executives-roles.component.scss'],
})
export class ExecutivesRolesComponent implements OnInit {
  public strPageType: string = 'ExecutiveRoles';
  executiveRolesForm: FormGroup;
  isPanelOpenState = false;
  roleTableData: any[] = [];
  roleId: string = '';
  concurrencyStamp: string = '';
  constructor(
    public setTitle: Title,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private tableService: TableService,
    public dialog: MatDialog,
    public rolesService: IdentityRoleService,
    private toastr: ToastrService,

  ) {}

  ngOnInit(): void {
    this.executiveRolesForm = this.formBuilder.group({
      txtRoleName: new FormControl('', Validators.required),
    });
    this.setTitle.setTitle('Qsecure | Executive Roles');
    this.loadAllRolesTable();
  }

  loadAllRolesTable() {
    let roleList: GetRoleListUsingTenantIdDto = {
      tenantId: null,
    };
    this.roleService.getRoleList(roleList).subscribe(response => {
      this.roleTableData = [];
      response &&
        response?.forEach(element => {
          this.roleTableData.push({
            name: element?.Name,
            id: element?.Id,
            concurrencyStamp: element?.ConcurrencyStamp,
          });
        });
      this.tableService.setExecutiveRoleListTable(this.roleTableData);
    });
  }
  //! Save Executive Roles Function
  saveExecutiveRoles() {
    let rolesDetails: IdentityRoleCreateOrUpdateDtoBase = {
      name: this.executiveRolesForm.value.txtRoleName,
      isDefault: false,
      isPublic: true,
      extraProperties: {},
    };
    let rolesUpdateDetails: IdentityRoleUpdateDto = {
      name: this.executiveRolesForm.value.txtRoleName,
      isDefault: false,
      isPublic: true,
      concurrencyStamp: this.concurrencyStamp,
      extraProperties: {},
    };

    if (this.roleId === '' || this.roleId === undefined || this.roleId === null) {
      this.rolesService.create(rolesDetails).subscribe(
        response => {
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Saved Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Saved Successfully','Success')
          this.resetExecutiveRoles();
          this.loadAllRolesTable();
        },
        err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      this.rolesService.update(this.roleId, rolesUpdateDetails).subscribe(
        response => {
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Updated Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Updated Successfully','Success')
          this.resetExecutiveRoles();
          this.loadAllRolesTable();
        },
        err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
    this.roleId = '';
  }


  ViewExecutiveRole(event: { id: any; }) {
    this.getExecutiveRoleById(event?.id)
  }

  getExecutiveRoleById(roleId: any){
    this.isPanelOpenState = !this.isPanelOpenState;
    this.roleService.getRoleById(null,roleId??"").subscribe(response => {
      this.roleId = response?.id??"";
      this.concurrencyStamp = response?.concurrencyStamp??"";
      this.executiveRolesForm.patchValue({
        txtRoleName: response?.name,
      });
    });
  }

  deleteExecutiveRole(event: { id: string; }){
   Swal.fire({
     title: 'Are you sure you want to Delete?',
     text: 'You won\'t be able to retrieve this data!',
     icon: 'warning',
     showCancelButton: true,
     confirmButtonColor: '#34c38f',
     cancelButtonColor: '#f46a6a',
     confirmButtonText: 'Yes, delete it!'
   }).then(result => {
     if (result.value) {
       this.roleService.deleteRole(null,event.id).subscribe(response => {
         this.roleTableData.forEach(element => {
           if (event.id == element?.id) {
             let index = this.roleTableData.indexOf(element, 0)
             this.roleTableData.splice(index, 1)
           }
         });
         this.tableService.setExecutiveRoleListTable(this.roleTableData);
       }, err => {
         const data: HttpErrorResponse = err;
         Swal.fire({
           icon: 'info',
           text: data?.error?.error?.message,
         });
       });
     }
   });
   this.resetExecutiveRoles();
  }

  //! Open Permissions ModalPopup Function
  ViewPermissions(roleName?: any) {
    const dialogRef = this.dialog.open(ExecutivesRolesPermissionComponent, {
      disableClose: true,
      data: { roleName: roleName.name }
    });
    dialogRef.afterClosed().subscribe(() => {});
  }
  //! Reset Function for Executive Roles form
  resetExecutiveRoles() {
    this.executiveRolesForm.patchValue({
      txtRoleName: '',
    });
    this.roleId = '';
    this.executiveRolesForm?.markAsUntouched();
    this.executiveRolesForm?.get('txtRoleName')?.setErrors(null);
    this.executiveRolesForm?.updateValueAndValidity();
  }
}
