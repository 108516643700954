import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { PatientDocumentService } from 'projects/patient/src/app/patient-proxy/patient-optimization/patient-document.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-patient-common-pdf-viewer',
  templateUrl: './patient-common-pdf-viewer.component.html',
  styleUrls: ['./patient-common-pdf-viewer.component.scss']
})
export class PatientCommonPdfViewerComponent implements OnInit {

  strPdfViewString:string='';
  documentId:string;
  blobName:string;
  subscription$:Subscription[]=[];
  isPdfViewerLoading: boolean = false;
  pdfheight: string = '100%';
  defaultFaxId:string;
  chartID:string;
  patientName:string;
  isInbound:any;
  constructor(
    private toastr: ToastrService,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private inboundDocumentService: InboundDocumentService,
    private patientDocumentService: PatientDocumentService,
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | PDF Viewer');
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.documentId = response.get('docId') ?? '';
        this.blobName = response.get('blobName') ?? '';
        this.isInbound = response.get('isInbound') ?? '';
        this.handleDocumentLoad();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toastr.error(data?.error?.error?.message, 'Error');
      }
    );
    this.subscription$.push(activatedRoute);
  }

  private handleDocumentLoad(): void {
    if (this.isInbound === 'false' || this.isInbound === undefined) {
        console.log('IF inbound', this.isInbound);
        this.loadInboundSortingDocumentPDF(this.blobName);
    } else {
        console.log('ELSe inbound', this.isInbound);
        this.loadInboundDocumentPDF(this.blobName);
    }
    this.loadPatientDetails(this.documentId);
}

  private loadDocumentPDF(blobName: string, serviceMethod: (blobName: string) => Observable<any>) {
    if (this.strPdfViewString === '') {
      this.isPdfViewerLoading = true;
      serviceMethod(blobName).subscribe(
        (response) => {
          if (typeof response === 'string' && response && response !== null && response.length !== 0) {
            this.strPdfViewString = String(response);
          }
          this.isPdfViewerLoading = false;
        },
        (err) => {
          this.isPdfViewerLoading = false;
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
        }
      );
    }
  }

  loadInboundDocumentPDF(blobName: string) {
    this.loadDocumentPDF(blobName, this.inboundDocumentService.downloadInboundDocumentByBlobName.bind(this.inboundDocumentService));
  }

  loadInboundSortingDocumentPDF(blobName: string) {
    this.loadDocumentPDF(blobName, this.patientDocumentService.getPatientDocumentByBlobIDByBlobName.bind(this.patientDocumentService));
  }
  loadPatientDetails(docId: string) {
    const getPatientDetails = this.inboundDocumentService
      .getPatientDemographicsDetails(docId)
      .subscribe(
        (response) => {
          this.defaultFaxId = response?.defaultFaxId || '-'
          this.chartID = response?.chartId || '';
          this.patientName = ((response?.firstName || '') +' '+ (response?.middleName || '') +' '+ (response?.lastName || '')).trim();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          console.warn(data?.error?.error?.message);
        }
      );
  }

}
