import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';
import { FaxMailService } from '../admin-proxy/platform-app-management/rcm/platform-management/mail-watcher';
import { CreateUpdateFaxMailDetailsDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/mail-watcher/dto/models';
import { MatAccordion } from '@angular/material/expansion';
import { MailConfigServerProtocolService } from '../admin-proxy/platform-app-management/rcm/platform-management/dropdowns/mail-config-server-protocol.service';
import { delay } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-admin-mail-configuration',
  templateUrl: './admin-mail-configuration.component.html',
  styleUrls: ['./admin-mail-configuration.component.scss']
})

export class AdminMailConfigurationComponent implements OnInit {
  // Declarations
  drpProtocol: any[] = [];
  @Input() clientId: string = '';
  mailConfig: FormGroup;
  emailMask: any;
  step: boolean = false;
  hide = true;
  isLoading: boolean;
  isTableData: boolean = false
  mailConfigSearchForm: FormGroup;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  displayedColumns: string[] = [ 'branchName', 'faxNumber', 'name','emailId', 'portNumber', 'hostName', ];
  saveButtonHide: boolean;
  mailConfigId: string = "";
  isShowSpinner: boolean;
  mailConfigTableData: any[] = [];

  @ViewChild('firstAccordion') firstAccordion: MatAccordion;
  constructor(
    private formBuilder: FormBuilder,
    private FaxMailService: FaxMailService,
    private MailConfigService: MailConfigServerProtocolService,
    private toastr: ToastrService,
    public title: Title,
  ) { }

  ngOnInit(): void {
    this.title.setTitle("Qsecure | Mail Configuration");
    this.mailConfig = this.formBuilder.group({
      txtEmail: new FormControl("", Validators.required),
      txtPassword: new FormControl("", Validators.required),
      txtPortNumber: new FormControl("", Validators.required),// integer
      txtHostName: new FormControl("", Validators.required),// ,
      txtFaxNumber: new FormControl("", Validators.required),
      drpServerProtocol: new FormControl("", Validators.required),
      chkSsleEnable: new FormControl("", Validators.required),
    });
    this.mailConfigSearchForm = this.formBuilder.group({
      emailId: new FormControl(""),
      portNumber: new FormControl(""),
      hostName: new FormControl(""),
      faxNumber: new FormControl("")
    });
    this.emailMask = emailMask;
    this.getMasterTable();
    this.getDropdown();
  }
  // get Protocol dropdown
  getDropdown() {
    this.MailConfigService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {

      response?.items?.forEach(element => {

        this.drpProtocol?.push({
          name: element?.serverProtocol,
          id: element?.id
        });
      });
    })
  }


  // save mailConfig
  saveMaster() {
    if ((this.mailConfigId == null || this.mailConfigId == "" || this.mailConfigId == undefined)) {
      let mailConfig: CreateUpdateFaxMailDetailsDTO = {
        faxNumber: this.mailConfig.value.txtFaxNumber,
        mailId: this.mailConfig.value.txtEmail,
        mailPassword: this.mailConfig.value.txtPassword,
        portNumber: this.mailConfig.value.txtPortNumber,
        hostName: this.mailConfig.value.txtHostName,
        isSSLEnabled: this.mailConfig.value.chkSsleEnable,
        serverProtocol: this.mailConfig.value.drpServerProtocol
      };
      this.FaxMailService.create(mailConfig).subscribe(response => {
        this.toastr.success('Saved Successfully','Success')
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {

          this.mailConfigId = response.id;
          this.saveButtonHide = false;
          this.isShowSpinner = false;
        // });
        this.reset();
        this.getMasterTable();
      }, err => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    } else {
      let mailConfig: CreateUpdateFaxMailDetailsDTO = {
        faxNumber: this.mailConfig.value.txtFaxNumber,
        mailId: this.mailConfig.value.txtEmail,
        mailPassword: this.mailConfig.value.txtPassword,
        portNumber: this.mailConfig.value.txtPortNumber,
        hostName: this.mailConfig.value.txtHostName,
        isSSLEnabled: this.mailConfig.value.chkSsleEnable,
        serverProtocol: this.mailConfig.value.drpServerProtocol
      };
      this.FaxMailService.update(this.mailConfigId, mailConfig).subscribe(response => {
        this.toastr.success('Updated Successfully','Success')
        // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).then(result => {

          this.saveButtonHide = false;
          this.isShowSpinner = false;

        // });
        this.reset();
        this.getMasterTable();
      }, err => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }
  // reser mail config data
  reset() {
    this.mailConfig.patchValue({
      txtEmail: "",
      txtPassword: "",
      txtPortNumber: "",
      txtHostName: "",
      txtFaxNumber: "",
      drpServerProtocol: "",
      chkSsleEnable: ""
    });
    this.mailConfig.markAsUntouched();
    this.mailConfig.updateValueAndValidity();
    this.mailConfig.markAsPristine();
    this.mailConfigId = '';
    this.saveButtonHide = false;
  }
  // get Mail config tabe data
  getMasterTable() {
    this.isLoading = true
    this.FaxMailService.getList(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.mailConfigTableData = [];

      response && response?.items?.forEach(element => {
        this.mailConfigTableData.push({
          data: element?.id,
          faxNumber: element?.faxNumber,
          emailId: element?.mailId,
          portNumber: element?.portNumber,
          hostName: element?.hostName,
          name: 'Monitor Medical',
          branchName: element?.branchName
        });
      })
      const arrTableData = this.mailConfigTableData;

      if(arrTableData.length !=0){
        this.isTableData = true
        this.step = false;
      }else{
        this.isTableData = false
        this.step = true;
      }
      this.dataSource = new MatTableDataSource(arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      // Swal.fire({
      //   icon: 'error',
      //   text: data?.error?.error?.message,
      // });
    })
  }

  //edit mailConfig
  ViewMaster(data) {

    this.step = !this.step;
    this.mailConfigId = data.data
    this.FaxMailService.get(this.mailConfigId).subscribe(response => {
      this.mailConfig.patchValue({
        txtEmail: response?.mailId,
        txtPassword: response?.mailPassword,
        txtPortNumber: response?.portNumber,
        txtHostName: response?.hostName,
        txtFaxNumber: response?.faxNumber,
        drpServerProtocol: response?.serverProtocol,
        chkSsleEnable: response?.isSSLEnabled
      })
    });
  }

  //! get branch by id
  getMasterById(id: string) {
    this.FaxMailService.get(id).subscribe(response => {

      this.mailConfig.patchValue({
        txtEmail: response?.mailId,
        txtPassword: response?.mailPassword,
        txtPortNumber: response?.portNumber,
        txtHostName: response?.hostName,
        txtFaxNumber: response?.faxNumber,
        drpServerProtocol: response?.serverProtocol,
        chkSsleEnable: response?.isSSLEnabled
      });
    })
  }

  ///delete Master
  deleteMaster(data) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: 'You won\'t be able to retrieve this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.FaxMailService.delete(data.data).subscribe(response => {
          this.mailConfigTableData.forEach(element => {
            if (data.id == element?.id) {
              let index = this.mailConfigTableData.indexOf(element, 0)
              this.mailConfigTableData.splice(index, 1)
              this.toastr.success('Deleted Successfully','Success')
              // Swal.fire({ title: 'Success', html: 'Deleted Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            }
          });
          this.getMasterTable();
        }, err => {
          const data: HttpErrorResponse = err;

          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
    this.reset();
  }
}




