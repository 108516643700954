import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IdentityUserDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { wipStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/wip-status.enum';
import { WorkInProgressService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress';
import { WorkInProgressDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress/dto';
import { IdentityUserService } from 'projects/admin/src/app/admin-proxy/volo/abp/identity/identity-user.service';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { Observable, of, Subscription } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-task-viewer',
  templateUrl: './task-viewer.component.html',
  styleUrls: ['./task-viewer.component.scss'],
})
export class TaskViewerComponent implements OnInit {
  defaultWIPId: string = '';
  WIPId: string = defaultGuid;
  selUserId = defaultGuid;
  documentId: string = defaultGuid;
  defaultGuid = defaultGuid;
  isShowSpinner: boolean = false;
  strWIPStatus: string = '';
  wipTaskForm: FormGroup;
  scheduleMaxDate: Date = new Date();
  filteredIdentityUsers: Observable<IdentityUserDto[]> = of([]);
  lstUsers: IdentityUserDto[] = [];
  subscription$: Subscription[] = [];
  constructor(
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private identityUserService: IdentityUserService,
    private workInProgressService: WorkInProgressService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA)
    public data: { WIPId: string; defaultWIPId: string; documentId: string } = {
      WIPId: defaultGuid,
      defaultWIPId: '',
      documentId: defaultGuid,
    }
  ) {}

  ngOnInit(): void {
    this.scheduleMaxDate.setDate(new Date().getDate() + 1);
    this.WIPId = this.data?.WIPId ?? defaultGuid;
    this.defaultWIPId = this.data?.defaultWIPId ?? defaultGuid;
    this.documentId = this.data?.documentId ?? defaultGuid;
    this.WIPId !== defaultGuid &&
      this.documentId !== defaultGuid &&
      this.getWIPDetailsById();
    this.createFormGroup();
  }
  createFormGroup() {
    this.wipTaskForm = this.fb.group({
      txtComment: new FormControl(''),
      userToBeAssigned: new FormControl('', Validators.required),
      txtUserToBeAssignedFilter: new FormControl(''),
    });
    const identityUserSub = this.identityUserService
      .getList(new PagedAndSortedResultRequestDto())
      .pipe()
      .subscribe((response) => {
        const sortedList = response.items.sort((a, b) =>
          a.userName.localeCompare(b.userName)
        );
        this.lstUsers =
          response?.items?.filter(
            (x) => x?.id !== (localStorage?.getItem('userId') ?? defaultGuid)
          ) ?? [];
        this.filteredIdentityUsers = this.wipTaskForm
          ?.get('txtUserToBeAssignedFilter')
          ?.valueChanges?.pipe(
            startWith(''),
            map((value) =>
              this.lstUsers?.filter((option) =>
                option?.userName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    this.subscription$.push(identityUserSub);
  }

  getWIPDetailsById() {
    const workInProgressSub = this.workInProgressService
      .get(this.WIPId)
      .subscribe((response) => {
        response.wipStatus === wipStatus.completed &&
          this.wipTaskForm.disable();
      });
    this.subscription$.push(workInProgressSub);
  }
  updateWIPDetails() {
    const wipForm = this.wipTaskForm?.value;
    if (this.wipTaskForm?.disabled) {
      return null;
    }
    this.isShowSpinner = true;
    this.workInProgressService
      .get(this.WIPId)
      .pipe(
        switchMap((x) => {
          const y: WorkInProgressDTO = {
            WIPId: x?.WIPId,
            documentId: x?.documentId,
            patientId: x?.patientId,

            assignedBy: x?.assignedBy,
            assignedDate: x?.assignedDate,
            wipStatus: wipStatus.reAssigned,
            comments: x?.comments,
            tenantId: x?.tenantId,
          };
          return this.workInProgressService.update(this.WIPId, y);
        }),
        switchMap((x) => {
          const y: WorkInProgressDTO = {
            WIPId: '',
            documentId: x?.documentId,
            patientId: x?.patientId,
            assignedBy: localStorage.getItem('userId') ?? defaultGuid,
            assignedDate: new Date().toISOString(),
            wipStatus: wipStatus.yetToStart,
            comments: wipForm?.txtComment,
            tenantId: x?.tenantId,
          };
          return this.workInProgressService.create(y);
        })
      )
      .subscribe(
        (response) => {
          this.toastr.success('Saved Successfully', 'Success');
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Saved Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.isShowSpinner = false;
          this.matDialog.closeAll();
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
}
