<div *ngIf="!isLoading">
  <table id="tblMMCFaxProcessList" datatable [dtOptions]="dtEligibilityHistory"
    class="tbl hover row-border hover w-100 display">
    <thead>
      <tr>
        <th><mat-icon>apps</mat-icon></th>
        <th [matTooltip]="'Payor Level'">Payor Level</th>
        <th [matTooltip]="'Payor Name'">Payor Name</th>
        <th [matTooltip]="'Plan Type'">Plan Type</th>
        <th [matTooltip]="'Policy No'">Policy No</th>
        <th [matTooltip]="'Status'">Status</th>
        <th [matTooltip]="'Payor Mode'">Payor Mode</th>
        <th [matTooltip]="'Verified By'">Verified By</th>
        <th [matTooltip]="'Verified Method'">Verified Method</th>
        <th [matTooltip]="'Date Verified'">Date Verified</th>
        <th [matTooltip]="'Expiry Status'">Expiry Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of DataList">
        <td >
          <a class="eyeCursorclass" >
            <i
              (click)="viewVerificationById(data)"
              target="_blank"
              class="fa fa-pencil mr-5 pencilColor"
              [class.hide]="(data?.expiryStatus==='yes'||data?.expiryStatus==='Yes')"
            ></i>
          </a>
        </td>
        <td (dblclick)="onRowDoubleClick(data?.plan || '-')" [matTooltip]="data?.plan || '-'" >
          <span *ngIf="(data?.plan===InsuranceType.Primary)||(data?.plan===InsuranceTypeV1.primary)">{{InsuranceType.Primary}}</span>
          <span *ngIf="(data?.plan===InsuranceType.Secondary)||(data?.plan===InsuranceTypeV1.secondary)">{{InsuranceType.Secondary}}</span>
          <span *ngIf="(data?.plan===InsuranceType.Tertiary)||(data?.plan===InsuranceTypeV1.tertiary)">{{InsuranceType.Tertiary}}</span>
        </td>
        <td (dblclick)="onRowDoubleClick(data?.planName || '-')" [matTooltip]="data?.planName || '-'">{{
          data?.planName || '-'}}</td>
        <td (dblclick)="onRowDoubleClick(data?.typeOfPlan || '-')" [matTooltip]="data?.typeOfPlan || '-'">
            {{ data?.typeOfPlan || '-' }}
        </td>
        <td (dblclick)="onRowDoubleClick(data?.policy || '-')" [matTooltip]="data?.policy || '-'">
          {{ data?.policy || '-'}}
        </td>
        <td (dblclick)="onRowDoubleClick(data?.verificationStatus || '-')" [matTooltip]="data?.verificationStatus || '-'">
          <span *ngIf="(data?.verificationStatus===lstVerificationSttaus.done)||(data?.verificationStatus===lstVerificationSttausV1.done)">Done</span>
          <span *ngIf="(data?.verificationStatus===lstVerificationSttaus.pending)||(data?.verificationStatus===lstVerificationSttausV1.pending)">Pending</span>
          <span *ngIf="(data?.verificationStatus===lstVerificationSttaus.unabletoverify)||(data?.verificationStatus===lstVerificationSttausV1.unabletoverify)">Unable To Verify</span>
          <span *ngIf="(data?.verificationStatus===lstVerificationSttaus.new)||(data?.verificationStatus===lstVerificationSttausV1.new)">New</span>
          <span class="text-red" *ngIf="(data?.verificationStatus!==lstVerificationSttaus.new)&&
    (data?.verificationStatus!==lstVerificationSttausV1.new) &&
    (data?.verificationStatus!==lstVerificationSttaus.unabletoverify)&&
    (data?.verificationStatus!==lstVerificationSttausV1.unabletoverify)&&
    (data?.verificationStatus!==lstVerificationSttaus.pending)&&
    (data?.verificationStatus!==lstVerificationSttausV1.pending)&&
    (data?.verificationStatus!==lstVerificationSttaus.done)&&
    (data?.verificationStatus!==lstVerificationSttausV1.done)"
    >{{data?.verificationStatus}}</span>
        </td>
        <td (dblclick)="onRowDoubleClick(data?.eligibilityMode || '-')" [matTooltip]="data?.eligibilityMode || '-'">
            <ng-container [ngSwitch]="data?.eligibilityMode">
              <ng-container *ngSwitchCase="'1'">pVerify</ng-container>
              <ng-container *ngSwitchCase="'2'">Zermid</ng-container>
              <ng-container *ngSwitchCase="'3'">ChangeHealthCare</ng-container>
              <ng-container *ngSwitchDefault> - </ng-container>
            </ng-container>
        </td>
        <td (dblclick)="onRowDoubleClick(data?.verifiedByUser)"
          [matTooltip]="data?.verifiedByUser">
          {{ data?.verifiedByUser }}
        </td>
        <td (dblclick)="onRowDoubleClick(data.vMethod === 0 ? 'Manual' : 'Electronic'  || '-')" [matTooltip]="data.vMethod === 0 ? 'Manual' : 'Electronic'  || '-'">
          {{((data?.verificationStatus===lstVerificationSttaus.new)||(data?.verificationStatus===lstVerificationSttausV1.new))?'-': data.vMethod === 0 ? 'Manual' : 'Electronic' }}
        </td>
        <td class="text-center" (dblclick)="onRowDoubleClick(getFormattedDate(data?.verifiedOn))"
          [matTooltip]="getFormattedDate(data?.verifiedOn)">
          {{ getFormattedDate(data?.verifiedOn) }}
        </td>
        <td  (dblclick)="onRowDoubleClick(data?.expiryStatus || '-')" [matTooltip]="data?.expiryStatus || '-'"
        >{{data?.expiryStatus||'-'}}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th><mat-icon>apps</mat-icon></th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Payor Level"
            name="search-plan" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Payor Name" name="search-Payor Name" />
        </th>
        <th>
          <input matInput type="text" class="textbox-border-class" placeholder="Plan Type" name="search-Plan Type" />
        </th>
        <th>
          <input matInput type="text" class="textbox-border-class" placeholder="Policy No"
            name="search-Policy No" />
        </th>
        <th>
          <input matInput type="text" class="textbox-border-class" placeholder="Status" name="search-Status" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Eligibility Mode" name="search-Eligibility Mode" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Verified By" name="search-Verified By" />
        </th>
        <th>
          <input matInput class="textbox-border-class" type="text" placeholder="Verified Method" name="search-Verified Method" />
        </th>
        <th>
          <input matInput class="textbox-border-class text-center" type="text" placeholder="Date Verified" name="search-Date Verifiedn" />
        </th>
        <th>
          <input matInput type="text" class="textbox-border-class" placeholder="Expiry Status" name="search-Expiry Status" />
      </th>
      </tr>
    </tfoot>
  </table>
</div>
<div class="row" *ngIf="isLoading">
  <div class="col-12">
    <div class="d-flex justify-content-center">
      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
    </div>
  </div>
</div>
