<div class="row mt-2">
  <div class="col-12 mgn">
      <div class="d-flex align-items-center justify-content-between">
          <h4 class="font-size-18"><b>Executives Steps</b></h4>
          <div class="page-title-box page-title-right">
              <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item">Admin</li>

                  <li class="breadcrumb-item ">Client</li>
              </ol>
          </div>
      </div>
  </div>
</div>
<div class="row pt-2">
<div class="col-12" style="margin-top: -8px;">
  <ul ngbNav #nav="ngbNav" justify="justified" class="alert alert-success nav nav-pills nav-fill">
      <li ngbNavItem="Roles" class="nav-item">
          <a ngbNavLink>Roles</a>
          <ng-template ngbNavContent>
              <app-executives-roles></app-executives-roles>
          </ng-template>
      </li>
      <li ngbNavItem="Users" class="nav-item">
          <a ngbNavLink>Users</a>
          <ng-template ngbNavContent>
              <app-executives-users></app-executives-users>
          </ng-template>
      </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
</div>

