import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-permission-v1',
  templateUrl: './permission-v1.component.html',
  styleUrls: ['./permission-v1.component.scss'],
})
export class PermissionV1Component implements OnInit {
  permissionForm: FormGroup;
  isAccordionCollapsed: boolean = true;
  // @ViewChild('selectAllCheckbox') selectAllCheckbox: MatCheckbox;
  @ViewChild('selectAllCheckbox', { static: true })
  selectAllCheckboxRef: ElementRef<HTMLInputElement>;

  permissionsV1: Permission[] = [
    {
      "menu": "Admin Dashboard",
      "subMenu": "",
      "access": "View",
      "isActive": true
  }, 

  //branch
    {
        "menu": "Settings",
        "subMenu": "Branch",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Branch",
        "access": "Edit",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Branch",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Branch",
        "access": "Delete",
        "isActive": true
    },
    
    //paypal setup
    {
        "menu": "Settings",
        "subMenu": "Paypal Setup",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Paypal Setup",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Paypal Setup",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Paypal Setup",
        "access": "Delete",
        "isActive": true
    },
    // role
    {
        "menu": "Settings",
        "subMenu": "Role",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Role",
        "access": "Edit",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Role",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Role",
        "access": "Delete",
        "isActive": true
    },
    // user
    {
        "menu": "Settings",
        "subMenu": "User",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "User",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "User",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "User",
        "access": "Delete",
        "isActive": true
    },
    //Invite User
    {
        "menu": "Settings",
        "subMenu": "Invite User",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Invite User",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Invite User",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Invite User",
        "access": "Delete",
        "isActive": true
    },
    //Wip Status
    {
      "menu": "Settings",
      "subMenu": "Wip Status",
      "access": "Create",
      "isActive": true
  },
  {
      "menu": "Settings",
      "subMenu": "Wip Status",
      "access": "Edit ",
      "isActive": true
  },
  {
      "menu": "Settings",
      "subMenu": "Wip Status",
      "access": "View",
      "isActive": true
  },
  {
      "menu": "Settings",
      "subMenu": "Wip Status",
      "access": "Delete",
      "isActive": true
  },
  // Rule Master
    {
        "menu": "Settings",
        "subMenu": "Rule Master",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Rule Master",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Rule Master",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Settings",
        "subMenu": "Rule Master",
        "access": "Delete",
        "isActive": true
    },
    //Appointment Type
    // {
    //     "menu": "Settings",
    //     "subMenu": "Appointment Type",
    //     "access": "Create",
    //     "isActive": true
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "Appointment Type",
    //     "access": "Edit ",
    //     "isActive": true
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "Appointment Type",
    //     "access": "View",
    //     "isActive": true
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "Appointment Type",
    //     "access": "Delete",
    //     "isActive": true
    // },
  //Fax/mail Configration
  {
    "menu": "Settings",
    "subMenu": "Fax/mail Configration",
    "access": "Create",
    "isActive": true
},
{
    "menu": "Settings",
    "subMenu": "Fax/mail Configration",
    "access": "Edit ",
    "isActive": true
},
{
    "menu": "Settings",
    "subMenu": "Fax/mail Configration",
    "access": "View",
    "isActive": true
},
{
    "menu": "Settings",
    "subMenu": "Fax/mail Configration",
    "access": "Delete",
    "isActive": true
},

  //FPT Type
  {
    "menu": "Settings",
    "subMenu": "FTP configration",
    "access": "Create",
    "isActive": true
},
{
    "menu": "Settings",
    "subMenu": "FTP configration",
    "access": "Edit ",
    "isActive": true
},
{
    "menu": "Settings",
    "subMenu": "FTP configration",
    "access": "View",
    "isActive": true
},
{
    "menu": "Settings",
    "subMenu": "FTP configration",
    "access": "Delete",
    "isActive": true
},

    // Notes
//     {
//       "menu": "Master",
//       "subMenu": "Notes",
//       "access": "Create",
//       "isActive": true
//   },
//   {
//       "menu": "Master",
//       "subMenu": "Notes",
//       "access": "Edit ",
//       "isActive": true
//   },
//   {
//       "menu": "Master",
//       "subMenu": "Notes",
//       "access": "View",
//       "isActive": true
//   },
//   {
//       "menu": "Master",
//       "subMenu": "Notes",
//       "access": "Delete",
//       "isActive": true
//   },

  //Add payor
  {
      "menu": "Master",
      "subMenu": "Add Payor",
      "access": "Create",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Add Payor",
      "access": "Edit ",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Add Payor",
      "access": "View",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Add Payor",
      "access": "Delete",
      "isActive": true
  },

  //Size

  {
    "menu": "Master",
    "subMenu": "Size",
    "access": "Create",
    "isActive": true
},
{
    "menu": "Master",
    "subMenu": "Size",
    "access": "Edit ",
    "isActive": true
},
{
    "menu": "Master",
    "subMenu": "Size",
    "access": "View",
    "isActive": true
},
{
    "menu": "Master",
    "subMenu": "Size",
    "access": "Delete",
    "isActive": true
},
  //Color Codes
  {
      "menu": "Master",
      "subMenu": "Color Codes",
      "access": "Create",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Color Codes",
      "access": "Edit ",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Color Codes",
      "access": "View",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Color Codes",
      "access": "Delete",
      "isActive": true
  },
  //Document Bin
  {
      "menu": "Master",
      "subMenu": "Document Bin",
      "access": "Create",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Document Bin",
      "access": "Edit ",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Document Bin",
      "access": "View",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Document Bin",
      "access": "Delete",
      "isActive": true
  },

  //Provider List
  {
      "menu": "Master",
      "subMenu": "Provider List",
      "access": "Create",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Provider List",
      "access": "Edit ",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Provider List",
      "access": "View",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Provider List",
      "access": "Delete",
      "isActive": true
  },
  //Modifier
  {
      "menu": "Master",
      "subMenu": "Modifier",
      "access": "Create",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Modifier",
      "access": "Edit ",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Modifier",
      "access": "View",
      "isActive": true
  },
  {
      "menu": "Master",
      "subMenu": "Modifier",
      "access": "Delete",
      "isActive": true
  },
  //Intake - Patient Personal, Clinical,Document,Insurance
    {
        "menu": "Patient Management",
        "subMenu": "Intake - Patient Personal, Clinical,Document",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Intake - Patient Personal, Clinical,Document",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Intake - Patient Personal, Clinical,Document",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Intake - Patient Personal, Clinical,Document",
        "access": "Delete",
        "isActive": true
    },
    //Insurance Verification
    {
        "menu": "Patient Management",
        "subMenu": "Insurance, Verification",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Insurance, Verification",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Insurance, Verification",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Insurance, Verification",
        "access": "Delete",
        "isActive": true
    },
    //Authorization
    {
        "menu": "Patient Management",
        "subMenu": "Authorization",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Authorization",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Authorization",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Authorization",
        "access": "Delete",
        "isActive": true
    },

    //Medicare Checklist
    {
        "menu": "Patient Management",
        "subMenu": "Medicare checkList",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Medicare checkList",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Medicare checkList",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Medicare checkList",
        "access": "Delete",
        "isActive": true
    },

    //Remainter

    {
        "menu": "Patient Management",
        "subMenu": "Remainter",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Remainter",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Remainter",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Patient Management",
        "subMenu": "Remainter",
        "access": "Delete",
        "isActive": true
    },

        // Notes
        {
            "menu": "Patient Management",
            "subMenu": "Notes",
            "access": "Create",
            "isActive": true
        },
        {
            "menu": "Patient Management",
            "subMenu": "Notes",
            "access": "Edit ",
            "isActive": true
        },
        {
            "menu": "Patient Management",
            "subMenu": "Notes",
            "access": "View",
            "isActive": true
        },
        {
            "menu": "Patient Management",
            "subMenu": "Notes",
            "access": "Delete",
            "isActive": true
        },
    //Orders
    {
        "menu": "Order Management",
        "subMenu": "Orders",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Orders",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Orders",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Orders",
        "access": "Delete",
        "isActive": true
    },
    //Items
    {
        "menu": "Order Management",
        "subMenu": "Items",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Items",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Items",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Items",
        "access": "Delete",
        "isActive": true
    },
    //Claim
    {
        "menu": "Order Management",
        "subMenu": "Claim",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Claim",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Claim",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Claim",
        "access": "Delete",
        "isActive": true
    },
    //Shipping
    {
        "menu": "Order Management",
        "subMenu": "Shipping",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Shipping",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Shipping",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Shipping",
        "access": "Delete",
        "isActive": true
    },
    //Warehouse
    {
        "menu": "Order Management",
        "subMenu": "Warehouse",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Warehouse",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Warehouse",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Order Management",
        "subMenu": "Warehouse",
        "access": "Delete",
        "isActive": true
    },
    //Category
    {
        "menu": "Inventory Management",
        "subMenu": "Category",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Category",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Category",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Category",
        "access": "Delete",
        "isActive": true
    },
    //Product Brand
    {
        "menu": "Inventory Management",
        "subMenu": "Product Brand",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Product Brand",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Product Brand",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Product Brand",
        "access": "Delete",
        "isActive": true
    },
    //Manufacturer
    {
        "menu": "Inventory Management",
        "subMenu": "Manufacturer",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Manufacturer",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Manufacturer",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Manufacturer",
        "access": "Delete",
        "isActive": true
    },
    //Product List
    {
        "menu": "Inventory Management",
        "subMenu": "Product List",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Product List",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Product List",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Product List",
        "access": "Delete",
        "isActive": true
    },
    //Insurance Allowables List
    {
        "menu": "Inventory Management",
        "subMenu": "Insurance Allowables",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Insurance Allowables",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Insurance Allowables",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Insurance Allowables",
        "access": "Delete",
        "isActive": true
    },
    //Requisition
    {
        "menu": "Inventory Management",
        "subMenu": "Requisition",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Requisition",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Requisition",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Requisition",
        "access": "Delete",
        "isActive": true
    },
    //Purchase
    {
        "menu": "Inventory Management",
        "subMenu": "Purchase",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Purchase",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Purchase",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Inventory Management",
        "subMenu": "Purchase",
        "access": "Delete",
        "isActive": true
    },
    //Stock
    // {
    //     "menu": "Inventory Management",
    //     "subMenu": "Stock",
    //     "access": "Create",
    //     "isActive": true
    // },
    // {
    //     "menu": "Inventory Management",
    //     "subMenu": "Stock",
    //     "access": "Edit ",
    //     "isActive": true
    // },
    // {
    //     "menu": "Inventory Management",
    //     "subMenu": "Stock",
    //     "access": "View",
    //     "isActive": true
    // },
    // {
    //     "menu": "Inventory Management",
    //     "subMenu": "Stock",
    //     "access": "Delete",
    //     "isActive": true
    // },
    // {
    //     "menu": "MSI Report",
    //     "subMenu": "Billing Report",
    //     "access": "View",
    //     "isActive": true
    // },
    //Admin Schedule
    {
        "menu": "Appointment",
        "subMenu": "Admin Schedule",
        "access": "View",
        "isActive": true
    },
    //Leave Approval
    {
        "menu": "Appointment",
        "subMenu": "Leave Approval",
        "access": "Create",
        "isActive": true
    },
    {
        "menu": "Appointment",
        "subMenu": "Leave Approval",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Appointment",
        "subMenu": "Leave Approval",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Appointment",
        "subMenu": "Leave Approval",
        "access": "Delete",
        "isActive": true
    },
    //My Calender
    {
        "menu": "Appointment",
        "subMenu": "My Calender",
        "access": "View",
        "isActive": true
    },
    //Calender Lists
    {
        "menu": "Appointment",
        "subMenu": "Calender Lists",
        "access": "Edit ",
        "isActive": true
    },
    {
        "menu": "Appointment",
        "subMenu": "Calender Lists",
        "access": "View",
        "isActive": true
    },
    {
        "menu": "Appointment",
        "subMenu": "Calender Lists",
        "access": "Delete",
        "isActive": true
    },
    //OnTime Setting - Holiday
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Holiday",
    //     "access": "Create",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Holiday",
    //     "access": "Edit ",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Holiday",
    //     "access": "View",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Holiday",
    //     "access": "Delete",
    //     "isActive": false
    // },
    //OnTime Setting - Ontime User
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Ontime User",
    //     "access": "Create",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Ontime User",
    //     "access": "Edit ",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Ontime User",
    //     "access": "View",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Ontime User",
    //     "access": "Delete",
    //     "isActive": false
    // },
    //OnTime Setting - Coverage Area
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Coverage Area",
    //     "access": "Create",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Coverage Area",
    //     "access": "Edit ",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Coverage Area",
    //     "access": "View",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Coverage Area",
    //     "access": "Delete",
    //     "isActive": false
    // },
    //OnTime Setting - Ontime Branch
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Ontime Branch",
    //     "access": "Create",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Ontime Branch",
    //     "access": "Edit ",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Ontime Branch",
    //     "access": "View",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Ontime Branch",
    //     "access": "Delete",
    //     "isActive": false
    // },
    //OnTime Setting - Pickup
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Pickup",
    //     "access": "Create",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Pickup",
    //     "access": "Edit ",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Pickup",
    //     "access": "View",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Setting - Pickup",
    //     "access": "Delete",
    //     "isActive": false
    // },
    //OnTime Schedule
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Schedule",
    //     "access": "Create",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Schedule",
    //     "access": "Edit ",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Schedule",
    //     "access": "View",
    //     "isActive": false
    // },
    // {
    //     "menu": "Settings",
    //     "subMenu": "OnTime Schedule",
    //     "access": "Delete",
    //     "isActive": false
    // }

    //Appoinment schedule

        {
        "menu": "Appoinment",
        "subMenu": "Schedule",
        "access": "Create",
        "isActive": false
    },
    {
        "menu": "Appoinment",
        "subMenu": "Schedule",
        "access": "Edit ",
        "isActive": false
    },
    {
        "menu": "Appoinment",
        "subMenu": "Schedule",
        "access": "View",
        "isActive": false
    },
    {
        "menu": "Appoinment",
        "subMenu": "Schedule",
        "access": "Delete",
        "isActive": false
    },
    //Schedule List
    {
        "menu": "Appoinment",
        "subMenu": "Schedule List",
        "access": "Create",
        "isActive": false
    },
    {
        "menu": "Appoinment",
        "subMenu": "Schedule List",
        "access": "Edit ",
        "isActive": false
    },
    {
        "menu": "Appoinment",
        "subMenu": "Schedule List",
        "access": "View",
        "isActive": false
    },
    {
        "menu": "Appoinment",
        "subMenu": "Schedule List",
        "access": "Delete",
        "isActive": false
    },

    //pratice

    {
        "menu": "Pratice",
        "subMenu": "Pratice",
        "access": "Create",
        "isActive": false
    },
    {
        "menu": "Pratice",
        "subMenu": "Pratice",
        "access": "Edit ",
        "isActive": false
    },
    {
        "menu": "Pratice",
        "subMenu": "Pratice",
        "access": "View",
        "isActive": false
    },
    {
        "menu": "Pratice",
        "subMenu": "Pratice",
        "access": "Delete",
        "isActive": false
    },

    //Reports

    
    {
        "menu": "Pratice",
        "subMenu": "Reports",
        "access": "View",
        "isActive": false
    },
   
];

  groupedPermissions: GroupedPermissions = {};

  // permissions = {
  //   Settings: [
  //     {
  //       subMenu: 'Client Setting - Branch',
  //       access: 'Create',
  //       isActive: true,
  //     },
  //     {
  //       subMenu: 'Client Setting - Branch',
  //       access: 'View',
  //       isActive: true,
  //     },
  //     {
  //       subMenu: 'Client Setting - Branch',
  //       access: 'Edit',
  //       isActive: true,
  //     },
  //     {
  //       subMenu: 'Client Setting - Branch',
  //       access: 'Delete',
  //       isActive: true,
  //     },
  //     // {
  //     //     "subMenu": "Client Setting - Paypal Setup",
  //     //     "access": "Create",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Paypal Setup",
  //     //     "access": "View",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Paypal Setup",
  //     //     "access": "Edit",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Paypal Setup",
  //     //     "access": "Delete",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Role",
  //     //     "access": "Create",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Role",
  //     //     "access": "View",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Role",
  //     //     "access": "Edit",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Role",
  //     //     "access": "Delete",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - User",
  //     //     "access": "Create",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - User",
  //     //     "access": "View",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - User",
  //     //     "access": "Edit",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - User",
  //     //     "access": "Delete",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Invite User",
  //     //     "access": "Create",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Invite User",
  //     //     "access": "View",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Invite User",
  //     //     "access": "Edit",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Invite User",
  //     //     "access": "Delete",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Fax Configuration",
  //     //     "access": "View",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Rule Master",
  //     //     "access": "Create",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Rule Master",
  //     //     "access": "View",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Rule Master",
  //     //     "access": "Edit",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "Client Setting - Rule Master",
  //     //     "access": "Delete",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Appointment Type",
  //     //     "access": "Create",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Appointment Type",
  //     //     "access": "View",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Appointment Type",
  //     //     "access": "Edit",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Appointment Type",
  //     //     "access": "Delete",
  //     //     "isActive": true
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Holiday",
  //     //     "access": "Create",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Holiday",
  //     //     "access": "View",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Holiday",
  //     //     "access": "Edit",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Holiday",
  //     //     "access": "Delete",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Ontime User",
  //     //     "access": "Create",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Ontime User",
  //     //     "access": "View",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Ontime User",
  //     //     "access": "Edit",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Ontime User",
  //     //     "access": "Delete",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Coverage Area",
  //     //     "access": "Create",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Coverage Area",
  //     //     "access": "View",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Coverage Area",
  //     //     "access": "Edit",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Coverage Area",
  //     //     "access": "Delete",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Ontime Branch",
  //     //     "access": "Create",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Ontime Branch",
  //     //     "access": "View",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Ontime Branch",
  //     //     "access": "Edit",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Ontime Branch",
  //     //     "access": "Delete",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Pickup",
  //     //     "access": "Create",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Pickup",
  //     //     "access": "View",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Pickup",
  //     //     "access": "Edit",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Setting - Pickup",
  //     //     "access": "Delete",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Schedule",
  //     //     "access": "Create",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Schedule",
  //     //     "access": "View",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Schedule",
  //     //     "access": "Edit",
  //     //     "isActive": false
  //     // },
  //     // {
  //     //     "subMenu": "OnTime Schedule",
  //     //     "access": "Delete",
  //     //     "isActive": false
  //     // }
  //   ],
  //   // "Patient Management": [
  //   //     {
  //   //         "subMenu": "Intake - Patient Personal, Clinical,Document,Insurance",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Intake - Patient Personal, Clinical,Document,Insurance",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Intake - Patient Personal, Clinical,Document,Insurance",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Intake - Patient Personal, Clinical,Document,Insurance",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Insurance Verification",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Insurance Verification",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Insurance Verification",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Insurance Verification",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Authorization",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Authorization",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Authorization",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Authorization",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     }
  //   // ],
  //   // "Order Management": [
  //   //     {
  //   //         "subMenu": "Orders",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Orders",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Orders",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Orders",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Items",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Items",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Items",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Items",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Claim",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Claim",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Claim",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Claim",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Shipping",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Shipping",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Shipping",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Shipping",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Warehouse",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Warehouse",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Warehouse",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Warehouse",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     }
  //   // ],
  //   // "Inventory Management": [
  //   //     {
  //   //         "subMenu": "Category",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Category",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Category",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Category",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Product Brand",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Product Brand",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Product Brand",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Product Brand",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Vendor",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Vendor",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Vendor",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Vendor",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Product List",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Product List",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Product List",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Product List",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Allowables List",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Allowables List",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Allowables List",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Allowables List",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Stock",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Stock",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Stock",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Stock",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     }
  //   // ],
  //   // "Master": [
  //   //     {
  //   //         "subMenu": "Notes",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Notes",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Notes",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Notes",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Add Payor",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Add Payor",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Add Payor",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Add Payor",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Color Codes",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Color Codes",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Color Codes",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Color Codes",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Document Bin",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Document Bin",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Document Bin",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Document Bin",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Provider List",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Provider List",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Provider List",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Provider List",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Modifier",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Modifier",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Modifier",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Modifier",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     }
  //   // ],
  //   // "MSI Report": [
  //   //     {
  //   //         "subMenu": "Billing Report",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     }
  //   // ],
  //   // "Appointment": [
  //   //     {
  //   //         "subMenu": "Admin Schedule",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Leave Approval",
  //   //         "access": "Create",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Leave Approval",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Leave Approval",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Leave Approval",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "My Calender",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Calender Lists",
  //   //         "access": "View",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Calender Lists",
  //   //         "access": "Edit",
  //   //         "isActive": true
  //   //     },
  //   //     {
  //   //         "subMenu": "Calender Lists",
  //   //         "access": "Delete",
  //   //         "isActive": true
  //   //     }
  //   // ]
  // };
  categoriesV1:any;

  // categories = Object.keys(this.permissions);

  // selectedPermissions: { [category: string]: string[] } = {};
  selectedPermissionsV1: { [category: string]: string[] } = {};
  // checkboxState: { [category: string]: { [subMenu: string]: boolean } } = {};

  constructor(private formBuilder: FormBuilder) {
  }

  @ViewChild(MatAccordion) accordion: MatAccordion;

  Object = Object;

  ngOnInit(): void {
    this.permissionForm = this.formBuilder.group({
      chkAllManagement: new FormControl(''),
    });

    this.permissionsV1.forEach(permission => {
      const { menu, subMenu, access, isActive } = permission;

      // Check if the menu already exists in the groupedPermissions object
      if (!this.groupedPermissions[menu]) {
        this.groupedPermissions[menu] = {};
      }

      // Check if the submenu already exists in the groupedPermissions[menu] object
      if (!this.groupedPermissions[menu][subMenu]) {
        this.groupedPermissions[menu][subMenu] = [];
      }

      // Add the access and isActive properties to the submenu
      const permissionObject: Permission = { menu, subMenu, access, isActive };
      this.groupedPermissions[menu][subMenu].push(permissionObject);
    });
    this.categoriesV1 = Object.keys(this.groupedPermissions);
  }

  savePermission() {
    // Loop through categories and submenus
    // for (const category of this.categories) {
    //   for (const subMenu of this.permissions[category]) {
    //     // Access the checkbox state using this.checkboxState
    //     const isChecked = this.checkboxState[category]?.[subMenu.subMenu] || false;

    //     // You can use isChecked as needed (e.g., send it to an API)

    //   }
    // }


  }

  // isCategorySelected(category: string): boolean {
  //   return (
  //     this.selectedPermissions[category]?.length ===
  //     this.permissions[category].length
  //   );
  // }

  // selectAll(category: string): void {
  //   if (!this.isCategorySelected(category)) {
  //     if (!this.selectedPermissions[category]) {
  //       this.selectedPermissions[category] = [];
  //     }

  //     const selectedAccesses = this.permissions[category].map(
  //       (subMenu) => subMenu.access
  //     );

  //     // Loop through submenus and select them based on access level
  //     for (const subMenu of this.permissions[category]) {
  //       if (!this.isSelected(category, subMenu.subMenu)) {
  //         this.selectedPermissions[category].push(subMenu.subMenu);
  //       }
  //     }
  //   } else {
  //     // Clear the selection by setting it to an empty array
  //     this.selectedPermissions[category] = [];
  //   }


  // }

  // togglePermission(category: string, subMenu: string): void {
  //   if (!this.isSelected(category, subMenu)) {

  //     this.selectedPermissions[category].push(subMenu);

  //   } else {

  //     const index = this.selectedPermissions[category].indexOf(subMenu);

  //     if (index !== -1) {
  //       this.selectedPermissions[category].splice(index, 1);
  //     }
  //   }

  // }

  // isSelected(category: string, subMenu: string): boolean {
  //   return this.selectedPermissions[category]?.includes(subMenu);
  // }

  isCategorySelectedV1(category: string): boolean {
    const subMenuCount = this.permissionsV1.filter((permission) => {
      return (
        permission.menu === category &&
        this.isSelectedV1(
          category,
          permission.subMenu + '_' + permission.access
        )
      );
    }).length;
    this.mainSelect()
    return (
      subMenuCount ===
      this.permissionsV1.filter((permission) => {
        return permission.menu === category;
      }).length
    );
  }

  isSelectedV1(category: string, subMenu_access: string): boolean {
    return this.selectedPermissionsV1[category]?.includes(subMenu_access);
  }

  togglePermissionV1(category: string, subMenu_access: string) {
    if (!this.selectedPermissionsV1[category]) {
      this.selectedPermissionsV1[category] = [];
    }
    if (!this.isSelectedV1(category, subMenu_access)) {
      this.selectedPermissionsV1[category].push(subMenu_access);
    } else {
      const index =
        this.selectedPermissionsV1[category].indexOf(subMenu_access);
      if (index !== -1) {
        this.selectedPermissionsV1[category].splice(index, 1);
      }
    }
    this.mainSelect()
  }
  mainSelect(){
    let sumOfLengths = 0;
    for (const category in this.selectedPermissionsV1) {
      if (this.selectedPermissionsV1.hasOwnProperty(category)) {
        const selectedPermissionsArray = this.selectedPermissionsV1[category];
        sumOfLengths += selectedPermissionsArray.length;
      }
    }
    let l1=this.permissionsV1.length;
    (l1 == sumOfLengths) ?  this.setSelectOverAllCheckboxChecked() : this.setSelectOverAllCheckboxUnChecked() ;
  }

  toggleAccordion(): void {
    this.isAccordionCollapsed = !this.isAccordionCollapsed;
    if (this.isAccordionCollapsed) {
      this.accordion.closeAll();
      // Update the button label to "Collapse All"
      document.getElementById('collapse-btn').innerHTML = 'Expand All';
    } else {
      this.accordion.openAll();
      // Update the button label to "Expand All"
      document.getElementById('collapse-btn').innerHTML = ' Collapse All';
    }
  }
  
  setSelectOverAllCheckboxChecked() {
    this.permissionForm.get('chkAllManagement')?.setValue(true);
  }
  setSelectOverAllCheckboxUnChecked() {
    this.permissionForm.get('chkAllManagement')?.setValue(false);
  }
  selectOverAll(){
    let check=this.permissionForm.get('chkAllManagement')?.value;
    if(check){
      this.categoriesV1.forEach(category => {
        this.selectedPermissionsV1[category] = [];
        this.selectAllV1(category);
      });
    }else{
      this.categoriesV1.forEach(category => {
        this.selectedPermissionsV1[category] = [];
      });
    }
  }

  selectAllV1(category: string): void {
    const allSubMenuAccesses = this.permissionsV1
      .filter((permission) => permission.menu === category)
      .map((subMenu) => `${subMenu.subMenu}_${subMenu.access}`);

    const allSelected = allSubMenuAccesses.every((subMenuAccess) =>
      this.isSelectedV1(category, subMenuAccess)
    );

    if (allSelected) {
      this.selectedPermissionsV1[category] = [];
    } else {
      this.selectedPermissionsV1[category] = [...allSubMenuAccesses];
    }
  }
}
interface Permission {
  menu: string;
  subMenu: string;
  access: string;
  isActive: boolean;
}

interface GroupedPermissions {
  [menu: string]: {
    [subMenu: string]: Permission[];
  };
}
