<!-- Component Start -->
<!-- <div class="container-fluid"> -->
<!-- <div class="row bg">
    <div class="col-12">
        <div class="d-flex align-item-center justify-content-between m-0">
            <h4 class="mt-2 mb-0 font-size-18"><b>Master / Modifier</b></h4>
            <div class=" page-title-box  page-title-right mt-1 mb-0">
                <ol class="breadcrumbs m-0">
                    <div class="input-group mb-3">
                        <input autocomplete="new-daterange" type="text" class="form-control" placeholder="Username"
                            [ngStyle]="{'border-color':dateRangStatus == true ? '#bdb4b4' : 'white' }"
                            aria-label="Username" aria-describedby="basic-addon1" name="daterange" ngxDaterangepickerMd
                            [(ngModel)]="selectedRange" id="calendar-input" [showCustomRangeLabel]="true"
                            [alwaysShowCalendars]="true" [linkedCalendars]="true" [ranges]="ranges"
                            [showClearButton]="true" [locale]="calendarLocale" [placeholder]="calendarPlaceholder"
                            [keepCalendarOpeningWithRange]="true" [minDate]="minDate" [maxDate]="maxDate"
                            (datesUpdated)="datesUpdateds($event)">
                        <span class="input-group-text bg-color" id="basic-addon1"><label style="margin: auto;"
                                for="calendar-input"><i class="fa fa-filter cursor"
                                    aria-hidden="true"></i></label></span>

                    </div>

                    <li class="breadcrumb-item">Master Pages</li>
                    <li class="breadcrumb-item">Master Modifier</li>
                </ol>
            </div>
        </div>
    </div>
</div> -->
<div class="col-12" style="margin-bottom: -10px">
    <div class="card card-body ">
        <!-- Accordion Form Start -->
        <mat-accordion>
            <mat-expansion-panel [expanded]="step" (afterExpand)="toggle()" (closed)="toggleV2()">
                <mat-expansion-panel-header>
                    <mat-panel-title class="customThemeClass">
                        <ng-container *ngIf="mstModifierId === ''; else elseTitleTemplate">
                            <b>Add Modifier</b>
                        </ng-container>
                        <ng-template #elseTitleTemplate>
                            <b>Edit Modifier</b>
                        </ng-template>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <form [formGroup]="mstModifyrForm">
                    <div class="">
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field class="col-6">
                                    <mat-label>Select Modifier</mat-label>
                                    <mat-select formControlName="drpMstModifyr" [(value)]="txtModifier" #singleSelect>
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel="Search"
                                                noEntriesFoundLabel="No Matches found"
                                                formControlName="drpMstModifyrFilter"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let Modifier of filteredModifiers | async"
                                            [value]="Modifier.modifierName">
                                            {{ Modifier.modifierName }}
                                        </mat-option>
                                    </mat-select>
                                    <button mat-button (click)="txtModifier=undefined;$event.stopPropagation()"
                                        *ngIf="txtModifier" (click)="clearMstModifier()" matSuffix mat-icon-button>
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-form-field class="col-6">
                                    <mat-label>Modifier<span class="asterisk">*</span></mat-label>
                                    <input autocomplete="new-txtNewMstModifyr" matInput
                                        formControlName="txtNewMstModifyr" placeholder="Enter Modifier" maxlength="80"
                                        type="text" />
                                    <mat-error
                                        *ngIf=" mstModifyrForm?.get('txtNewMstModifyr')?.touched && mstModifyrForm?.get('txtNewMstModifyr')?.errors?.required ">
                                        Modifier is a required field!
                                    </mat-error>
                                    <mat-error
                                        *ngIf="mstModifyrForm?.get('txtNewMstModifyr')?.touched && mstModifyrForm?.get('txtNewMstModifyr')?.errors">
                                        Enter only Alphanumeric!
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </div>
                </form>
                <br>
                <div class="col-sm-12">
                    <div class="text-lg-center">
                        <button mat-button class="buttonColor mb-2 mr-2"
                            [disabled]="saveButtonHide||!(!mstModifyrForm.invalid && mstModifyrForm.dirty)"
                            (click)="saveMstModifier()">
                            Save
                            <mat-icon *ngIf='saveButtonHide'>
                                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                            </mat-icon>
                        </button>
                        <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="resetMstModifier()">
                            Reset
                        </button>
                    </div>
                </div>
                <br />
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <!-- Accordion Form Start -->
</div>

<div class="col-12">
    <div class="card card-body">
        <!-- Table Start -->
        <h4 class="customThemeClass ml-3 mt-2"><b>Master Modifier</b></h4>
        <!-- <form [formGroup]="modifierSearchForm"> -->
        <div class="tabspace table-responsive">
            <!-- <table mat-table class="table border" matSort [dataSource]="dataSource">
                    <ng-container matColumnDef="sno">
                        <th mat-header-cell *matHeaderCellDef >S.No</th>
                        <td mat-cell *matCellDef="let data; let i = index">
                            {{ i+1 }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="modifierName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input (click)="$event.stopPropagation()" matInput formControlName="modifierName" autocomplete="new-modifierName" />
                                <mat-placeholder class="center"> Modifier Name</mat-placeholder>
                              </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{ data?.modifierName }}
                            </ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="creationTime">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field class="filter">
                                <input (click)="$event.stopPropagation()" matInput formControlName="createdDate" autocomplete="new-createdDate" />
                                <mat-placeholder class="center">Created Date</mat-placeholder>
                              </mat-form-field>
                        </th>
                        <td mat-cell *matCellDef="let data">
                            <ng-container>
                                {{ data?.creationTime | date: 'MM/dd/yyyy':'en_US' }}
                            </ng-container>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="Options">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
                        <td mat-cell *matCellDef="let data">
                            <a class="editBtnColor" (click)="viewMstModifier(data?.id)">
                                <i class="fa fa-pencil  editBtnColor mr-1" (click)="toggleV2()" matTooltip="Click To Edit"></i>
                            </a>
                            &nbsp;
                            <a class="deleteBtnColor" (click)="deleteMstModifier(data?.id)">
                                <i class="fa fa-trash-o" matTooltip="Click To Delete"></i>
                            </a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="noRecords">
                        <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="9">
                            <div *ngIf="!isShowSpinner">
                                {{"No Records Found"}}
                            </div>
                            <div [hidden]="!isShowSpinner">
                                <mat-spinner [diameter]="220"></mat-spinner>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="paginator">
                        <td mat-footer-cell *matFooterCellDef colspan="9">
                            <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                                [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </td>
                    </ng-container>
                    <tr mat-footer-row *matFooterRowDef="['noRecords']"
                        [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>
                </table> -->



            <table id="tblModifierList" datatable [dtOptions]="dtModifierTableOptions"
                class="tbl hover row-border hover w-100 display">
                <thead>
                    <tr>
                        <th><mat-icon>apps</mat-icon></th>
                        <th [matTooltip]="'Modifier Name'">Modifier Name</th>
                        <th [matTooltip]="'Created By'">Created By</th>
                        <th class="text-right" [matTooltip]="'Created Date'" >Created Date</th>
                        <th [matTooltip]="'Modify By'">Modify By</th>
                        <th class="text-right" [matTooltip]="'Modified Date'" >Modified Date</th>
                        <th [matTooltip]="'Status'">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of modifierTbldata">
                        <td>
                            <ng-container>
                                <a class="editBtnColor" (click)="viewMstModifier(data?.id)" matTooltip="Click To Edit">
                                    <i class="fa fa-pencil pointer editBtn mr-1"></i>
                                </a>
                                <!-- &nbsp;
                                <a class="deleteBtnColor" (click)="deleteMstModifier(data?.id)">
                                    <i class="fa fa-trash-o" matTooltip="Click To Delete"></i>
                                </a> -->
                            </ng-container>
                        </td>
                        <td (dblclick)="onRowDoubleClickV1(data?.modifierName || '-')"
                            [matTooltip]="data?.modifierName || '-'"> {{ data?.modifierName ||
                            '-' }} </td>
                            <td [matTooltip]="data?.createdBy || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.createdBy || '-')">{{
                            data?.createdBy || '-' }}</td>

                        <td class="text-right" [matTooltip]="getFormattedDate(data?.creationTime)"
                            (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.creationTime))">
                            {{ getFormattedDate(data?.creationTime) }}
                        </td>
                        <td [matTooltip]="data?.modifyBy|| '-'"
                            (dblclick)="onRowDoubleClickV1(data?.modifyBy || '-')">{{
                            data?.modifyBy || '-' }}</td>

                        <td class="text-right" [matTooltip]="getFormattedDate(data?.lastModificationTime)"
                            (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.lastModificationTime))" >
                            {{ getFormattedDate(data?.lastModificationTime) }}
                        </td>
                        <td [matTooltip]="data?.isActive ? 'Active' : 'Inactive' || '-'">
                            <ng-container *ngIf="data?.isActive==1">
                                <mat-slide-toggle (change)="statusChange($event, data)" [checked]='true'
                                    class="ml-2">
                                    {{ data?.isActive ? 'Active' : 'Inactive' }}</mat-slide-toggle>
                            </ng-container>
                            <ng-container *ngIf="data?.isActive==0">
                                <mat-slide-toggle (change)="statusChange($event, data)" [checked]='false'
                                    class="ml-2">
                                    {{ data?.isActive ? 'Active' : 'Inactive' }}</mat-slide-toggle>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th><mat-icon>apps</mat-icon></th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Modifier Name"
                                name="search-ModifierName" />
                        </th>
                        <th>
                          <input matInput class="textbox-border-class" type="text" placeholder="Created By"
                              name="search-Created By" />
                      </th>
                      <th>
                          <input matInput class="textbox-border-class " type="text"
                                placeholder="Created Date" name="search-Created Date" />
                       </th>
                       <th>
                          <input matInput class="textbox-border-class" type="text" placeholder="Modify By"
                              name="search-Modify By" />
                      </th>
                        <th>
                            <input matInput class="textbox-border-class " type="text"
                                placeholder="Modified Date" name="search-Modified Date" />
                        </th>
                        <th>
                            <input matInput class="textbox-border-class" type="text" placeholder="Status"
                                name="search-Status" />
                        </th>
                    </tr>
                </tfoot>
            </table>



        </div>
        <!-- </form> -->
    </div>
</div>

<!-- Table End -->

<!-- </div> -->
<!-- Component End -->
