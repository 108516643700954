<div class="row">
  <div class="col-12">
    <div class="card card-body px-2 pb-1 pt-2">
      <form [formGroup]="denialSearchForm">
        <!-- <button mat-icon-button>
            <mat-icon class="btn-filter-color">filter_list</mat-icon>
          </button>
          <button mat-icon-button>
            <mat-icon>refresh</mat-icon>
          </button> -->
        <!-- <mat-form-field class="col-2">
          <input matInput formControlName="txtDenial" placeholder="Denial" maxlength="75" autocomplete="off" />
        </mat-form-field> -->
        <mat-form-field class="col-2">
          <mat-label>Denial </mat-label>
          <!-- <mat-select formControlName="drpDenial" [(value)]="drpDenialId" multiple (selectionChange)="onSelectDenial($event)" (keydown)="handleKeydown($event)">
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                formControlName="txtDenialFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let reasonCode of filteredreasonCode | async" [value]="reasonCode.id">
              {{ reasonCode.glossaryCode }}
            </mat-option>
          </mat-select> -->

          <mat-select formControlName="drpDenial" [(value)]="drpDenialId" multiple
              #denialSelect>
    <mat-option>
      <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                             formControlName="txtDenialFilter">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let reasonCode of filteredreasonCode | async" [value]="reasonCode.id">
      {{ reasonCode.glossaryCode }}
    </mat-option>
  </mat-select>

          <button mat-button *ngIf="
              denialSearchForm.get('drpDenial').value != '' &&
              denialSearchForm.get('drpDenial').value != null &&
              denialSearchForm.get('drpDenial').value != undefined
            " (click)="clearDenial(); $event.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="col-2 range-picker">
          <mat-label>Created</mat-label>
          <mat-date-range-input [rangePicker]="createdDatePicker">
            <input matStartDate matInput placeholder="Start date" formControlName="txtCreatedFromDate" maxlength="10"
              placeholder="(MM/DD/YYYY)" (keydown)="addSlashToDate($event)" (focusout)="focusOutCreated()" />

            <input matEndDate matInput placeholder="End date" formControlName="txtCreatedEndDate" maxlength="10"
              placeholder="(MM/DD/YYYY)" (keydown)="addSlashToDate($event)" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="createdDatePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #createdDatePicker></mat-date-range-picker>
          <button mat-button *ngIf="
              (denialSearchForm.get('txtCreatedFromDate').value != '' &&
                denialSearchForm.get('txtCreatedFromDate').value != null &&
                denialSearchForm.get('txtCreatedFromDate').value !=
                  undefined) ||
              (denialSearchForm.get('txtCreatedEndDate').value != '' &&
                denialSearchForm.get('txtCreatedEndDate').value != null &&
                denialSearchForm.get('txtCreatedEndDate').value != undefined)
            " (click)="clearCreatedDate(); $event.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>Payer</mat-label>
          <mat-select formControlName="drpPayer" [(value)]="drpPayorId" multiple
            (openedChange)="onPayerDropdownOpenChange($event)">
            <mat-option>
              <ngx-mat-select-search ngDefaultControl placeholderLabel="Payer Id / Name / Code"
                hideClearSearchButton="true" [noEntriesFoundLabel]="drpDownMessage()" formControlName="txtPayerFilter"
                (keyup)="loadPayerDropDown($event.target.value,$event.key)">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let payor of filteredPrimaryPayors | async " [value]="payor?.payerId"
              [title]="payor?.payerShortCodeList">
              {{ payor?.payerShortCodeList }} </mat-option>
          </mat-select>

          <button mat-button *ngIf="
              denialSearchForm.get('drpPayer').value != '' &&
              denialSearchForm.get('drpPayer').value != null &&
              denialSearchForm.get('drpPayer').value != undefined
            " (click)="clearPayer(); $event.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="col-2" [hidden]="true">
          <mat-label>Status</mat-label>
          <mat-select formControlName="drpStatus">
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                hideClearSearchButton="true" formControlName="txtStatusFilter"></ngx-mat-select-search>
            </mat-option>
            <mat-option value="Ignore">Ignore</mat-option>
            <mat-option value="New">New</mat-option>
            <mat-option value="Resubmitted">Resubmitted</mat-option>
            <mat-option value="Write Off">Write Off</mat-option>
            <mat-option value="Paid">Paid</mat-option>
          </mat-select>
          <button mat-button *ngIf="
              denialSearchForm.get('drpStatus').value != '' &&
              denialSearchForm.get('drpStatus').value != null &&
              denialSearchForm.get('drpStatus').value != undefined
            " (click)="clearStatus(); $event.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="col-2 range-picker">
          <mat-label>Date of Service</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate matInput placeholder="Start date" formControlName="txtDOSFromDate" maxlength="10"
              placeholder="(MM/DD/YYYY)" (keydown)="addSlashToDate($event)" (focusout)="focusOutDOS()" />

            <input matEndDate matInput placeholder="End date" formControlName="txtDOSEndDate" maxlength="10"
              placeholder="(MM/DD/YYYY)" (keydown)="addSlashToDate($event)"  />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <button mat-button *ngIf="
              (denialSearchForm.get('txtDOSFromDate').value != '' &&
                denialSearchForm.get('txtDOSFromDate').value != null &&
                denialSearchForm.get('txtDOSFromDate').value != undefined) ||
              (denialSearchForm.get('txtDOSEndDate').value != '' &&
                denialSearchForm.get('txtDOSEndDate').value != null &&
                denialSearchForm.get('txtDOSEndDate').value != undefined)
            " (click)="clearDOS(); $event.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <button type="button" matTooltip="Search" mat-raised-button (click)="getTableData(); $event.stopPropagation;"
          class="search-icon-button mr-2 px-2 mat-raised-small-button">
          <!-- <mat-icon class="fg-white" >Search</mat-icon> -->
            <img  class="img-responsive"
                    src="assets/Billing Icons/searchV1.png" height="20" />
        </button>
        &nbsp;
        <button type="button" matTooltip="Reset" mat-raised-button (click)="clearAll(); $event.stopPropagation;"
          class="reset-icon-button mr-2 px-2 mat-raised-small-button">
          <mat-icon class="fg-white">refresh</mat-icon>
        </button>
      </form>
    </div>
  </div>
</div>

<div class="row mt-2" *ngIf="!isLoading">
  <div class="col-12">
    <div class="card card-body p-1">
      <div class="table table-responsive">
        <table datatable [dtOptions]="billingDenialsTable" class="row-border hover w-100 display">
          <thead>
            <tr>
              <th [hidden]="true">*</th>
              <!-- <th><mat-checkbox></mat-checkbox></th> -->
              <th [matTooltip]="'Denial'">Denial</th>
              <th [matTooltip]="'Created'">Created</th>
              <th [matTooltip]="'Chart Id'">Chart Id</th>
              <th [matTooltip]="'Patient'">Patient</th>
              <th [matTooltip]="'Claim Id'">Claim Id</th>
              <th [matTooltip]="'DOS'">DOS</th>
              <th [matTooltip]="'HCPCS'">HCPCS</th>
              <th [matTooltip]="'Modifiers'">Modifiers</th>
              <th [matTooltip]="'Amount($)'" class="text-right">Amount($)</th>
              <th [matTooltip]="'Payer'">Payor</th>
              <!-- <th>Status</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of DataList">
              <td [hidden]="true">{{ data.paymentId }}</td>
              <!-- <td><mat-checkbox></mat-checkbox></td> -->
              <td class="denial-code-bolder" [matTooltip]=" data.reasonCode || '-'">
                {{ data.reasonCode || '-' }}
              </td>
              <td [matTooltip]=" data.creationTime || '-'">{{ data.creationTime || '-' }}</td>
              <td>
                <a [class.disabled]="
                    isEmpty(data.chartNo) || isEmpty(data.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + data.patientId" target="_blank"
                  [matTooltip]="data.chartNo || '-'">{{ data.chartNo || "-" }}</a>
              </td>
              <td [matTooltip]="(data.patientname || '-') + ' (' + (data.dob || '-') + ')'">

                {{ data.patientname || '-' }}
                <br />
                ({{ data.dob || '-' }})
              </td>

              <td>
                <!-- [routerLink]="
                '/orders/invoiceview/' + defaultGuid + '/' + defaultGuid
              " target="_blank" [matTooltip]="data.claimNumber || '-'" -->

                <a class="preview-link pointer"
                  [class.disabled]="isEmpty(data.claimNumber) || isEmpty(data.orderId) || isEmpty(data.patientId)  || isEmpty(data.mmReferenceOrderId) || isEmpty(data.claimId)"
                  (click)="
            openClaimForm(
              data.orderId ,
              data.patientId,
              data.mmReferenceOrderId ,
              data.claimId,
              data.chartId,
              data.patientname
            )
          ">{{ data.claimNumber || '-' }}</a>
              </td>

              <td [matTooltip]="data.dosDate || '-'">{{ data.dosDate || '-'}}</td>
              <td [matTooltip]="data.hcpcCode || '-'">{{ data.hcpcCode || '-'}}</td>
              <td [matTooltip]="data.modifier1 || data.modifier2 || data.modifier3 || data.modifier4 || '-' ">{{
                data.modifier1 || data.modifier2 || data.modifier3 || data.modifier4 || '-'}}</td>
              <td [matTooltip]="('$')+data.dBalanceAmount || '-'" class="text-right">{{ data.dBalanceAmount || '-' }}
              </td>
              <td [matTooltip]="data.payer || '-'">{{ data.payer || '-'}}</td>
              <!-- <td class="text-center">
                <div class="d-flex align-items-center">
                  <span [ngStyle]="{
                      'background-color': data.statusBgColor,
                      color: data.statusFgColor
                    }" class="rounded-border ml-1 w-75 p-1 claim-status-label">
                    {{ data?.status || "-" }}
                  </span>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="isLoading">
  <div class="col-12">
    <div class="d-flex justify-content-center">
      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
    </div>
  </div>
</div>
<!-- <app-loader-component *ngIf="!isLoading"></app-loader-component> -->
