import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { dtInboundOptions } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { UserByRoleDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { OrganizationUnitDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { ShippingTypesService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/shipping-types';
import {
  IdentityUserUsingTenantIdDto,
  UserService,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { InventoryTransferStatusService } from '../item-proxy/item-management/optimization/inventory-transfer-status.service';
import { InventoryTransferService } from '../item-proxy/item-management/optimization/inventory-transfer.service';
import {
  CreateUpdateInventoryTransferDTO,
  CreateUpdateInventoryTransferItemDTO,
} from '../item-proxy/item-management/optimization/dto';
import { ProductDetailsService } from '../item-proxy/item-management/optimization';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { element } from 'protractor';

@Component({
  selector: 'app-inventory-transfer',
  templateUrl: './inventory-transfer.component.html',
  styleUrls: ['./inventory-transfer.component.scss'],
})
export class InventoryTransferComponent implements OnInit {
  transferForm: FormGroup;
  inventoryTableForm: FormGroup;
  CSRFilterControl = new FormControl();
  isRunTransfer: boolean = false;
  branchFilterControl = new FormControl();
  branchFilterLocation = new FormControl();
  filteredBranchTypeList: OrganizationUnitDTO[];
  filteredCSRList: UserByRoleDTO[];
  defaultPackedBy: any;
  defaultVerifiedBy: any;
  seletedShippingValue: any;
  LocationFilterControl = new FormControl();
  lstUsers: UserByRoleDTO[] = [];
  lstBranch: OrganizationUnitDTO[] = [];
  lstLocation: OrganizationUnitDTO[] = [];
  filteredLocationList: any;
  lstShippingTypes: any;
  txtSerialNo: any;
  filteredOptions: any;
  subscription$: Subscription[] = [];
  txtQuantity: any;
  txtShipping: any;
  searchControl = new FormControl();
  ltproducts: any;
  loadTable: boolean = true;
  loadTableData = [];
  lstStatus: any;
  selectserialNo: any;
  selectQuantity: any;
  selectShippingCom: any;
  filteredStatusList: any;
  filteredUserList: any;
  lstUserList: any;
  seletedCSRValue: string = defaultGuid;
  seletedBranchValue: string = defaultGuid;
  seletedLocationValue: string = defaultGuid;
  filteredShippingTypes: any;
  isSourceLocation: boolean = false;
  transferTable: any;
  tenantId: string = '';
  editMode = false;
  dtInboundOptions: any = {
    responsive: true,
    filter: false,
    scrollX: '200px',
    scrollCollapse: true,
    paging: false,
    // pageLength: 10,
    // lengthMenu: [10, 25, 50, 100, 200],
    // columnDefs: [
    //   { targets: [0, 1, 3], orderable: false },
    // ]
  };
  lstUserList1: any;
  filteredUserList1: any;
  productDetailsList: any;
  productDes: any;
  lstProductCode: any;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private branchService: OrganizationUnitService,
    private shippingTypeService: ShippingTypesService,
    private tranferStatusService: InventoryTransferStatusService,
    private tranferService: InventoryTransferService,
    private toastr: ToastrService,
    private mmOrderService: MmOrderService,
    private communicationService: CommunicationService,
    private productDetailsService: ProductDetailsService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      transferId: string[];
    } = {
      transferId: [],
    }
  ) {
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.filterProducts();
      });
  }

  ngOnInit(): void {
    this.transferForm = this.formBuilder.group({
      drpCSR: new FormControl('', [Validators.required]),
      drpBranchType: new FormControl('', [Validators.required]),
      txtBranch: new FormControl(''),
      txtDate: new FormControl(new Date()),
      txtNote: new FormControl(''),
      txtTrackingNo: new FormControl(''),
      drpDestinationType: new FormControl(''),
      drpShippingTypes: new FormControl('', [Validators.required]),
      txtShippingType: new FormControl(''),
      drpStatusType: new FormControl('', [Validators.required]),
      txtStatusType: new FormControl(''),
      drpUserList: new FormControl(''),
      txtUserList: new FormControl(''),
      drpVerifyedBy: new FormControl(''),
      txtUserList1: new FormControl(''),
      drpLocation: new FormControl('', [Validators.required]),
      txtLocation: new FormControl(''),
    });
    this.inventoryTableForm = this.formBuilder.group({
      txtProdCode: new FormControl(''),
      drpProdCode: new FormControl('', [Validators.required]),
      txtSerialNo: new FormControl(''),
      txtQuantity: new FormControl(''),
      txtShipping: new FormControl(''),
    });
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.loadProducts();
    this.loadDropdown();

    this.CSRFilterControl.valueChanges.subscribe((dxTypeFilterText) => {
      dxTypeFilterText = dxTypeFilterText.toLowerCase();
      this.filteredCSRList = this.lstUsers.filter((dx) =>
        dx.name.toLowerCase().includes(dxTypeFilterText)
      );
    });
    this.branchFilterControl.valueChanges.subscribe((branchFilterText) => {
      branchFilterText = branchFilterText.toLowerCase();
      this.filteredBranchTypeList = this.lstBranch.filter((dx) =>
        dx.organizationUnitName.toLowerCase().includes(branchFilterText)
      );
    });
    this.LocationFilterControl.valueChanges.subscribe((txtLocation) => {
      txtLocation = txtLocation.toLowerCase();
      this.filteredLocationList = this.lstLocation.filter((dx) =>
        dx.organizationUnitName.toLowerCase().includes(txtLocation)
      );
    });
    if (this.data.transferId) {
      this.loadTransferData();
    }
  }

  //Method To Search the Products in Dropdown
  filterProducts(): void {
    const searchValue = this.searchControl.value.toLowerCase();
    this.filteredOptions = this.ltproducts.filter((option) =>
      option.productName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  //Load Products
  loadProducts() {
    const productsList = this.mmOrderService.getProductDetails().subscribe(
      (response) => {
        this.ltproducts = response;
        this.filteredOptions = [...this.ltproducts];
      },
      (err) => {}
    );
    this.subscription$.push(productsList);
  }

  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  loadDropdown() {
    // const getProduct = this.productDetailsService.getList(new PagedAndSortedResultRequestDto).subscribe(response =>{
    //   this.productDetailsList = response.items
    //   this.lstProductCode = response.items

    //   this.productDetailsList = this.inventoryTableForm
    //   ?.get('txtProdCode')
    //   ?.valueChanges.pipe(
    //     startWith(''),
    //     map((value) =>
    //       this.lstProductCode.filter((option) =>
    //         option?.productCode
    //           ?.toLowerCase()
    //           ?.includes(value?.toLowerCase() ?? '')
    //       )
    //     )
    //   );
    // })
    const csrUsersList = this.userService
      .getCSRUsersV1()
      .subscribe((response) => {
        this.lstUsers = response;
        this.seletedCSRValue = response[0].userId;
        // const allUserOption: UserByRoleDTO = {
        //   name: 'All',
        //   userId: '00000000-0000-0000-0000-000000000000',
        //   roleId: '',
        //   role: '',
        //   userDesignation: '',
        // };
        this.lstUsers = [...this.lstUsers];
        this.filteredCSRList = this.lstUsers;
      });

    const getBranchList = this.branchService
      .getBranchListV1(this.tenantId,false)
      .subscribe((response) => {
        this.lstBranch = response;
        this.lstBranch = response.sort((a, b) =>
          a.organizationUnitName.localeCompare(b.organizationUnitName)
        );
        // const allBranchOption: OrganizationUnitDTO = {
        //   id: '00000000-0000-0000-0000-000000000000',
        //   organizationUnitName: 'All',
        // };
        this.lstBranch = [...this.lstBranch];
        this.filteredBranchTypeList = this.lstBranch;
      });

    const getLocationList = this.branchService
      .getBranchListV1(this.tenantId,false)
      .subscribe((response) => {
        this.lstLocation = response;

        this.lstLocation = response.sort((a, b) =>
          a.organizationUnitName.localeCompare(b.organizationUnitName)
        );
        // const allBranchOption: OrganizationUnitDTO = {
        //   id: '00000000-0000-0000-0000-000000000000',
        //   organizationUnitName: 'All',
        // };
        this.seletedLocationValue = response[0].id;
        this.lstLocation = [...this.lstLocation];
        this.filteredLocationList = this.lstLocation;
      });
    const tranferStatus = this.tranferStatusService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe((response) => {
        this.lstStatus = response?.items;
        this.lstStatus = response.items.sort((a, b) =>
          a.description.localeCompare(b.description)
        );
        this.filteredStatusList = this.transferForm
          ?.get('txtStatusType')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstStatus.filter((option) =>
                option?.description
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    const getShippingTypes = this.shippingTypeService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe((response) => {
        this.lstShippingTypes = response.items;
        this.lstShippingTypes = response.items.sort((a, b) =>
          a.description.localeCompare(b.description)
        );
        this.seletedShippingValue = response.items[0].id;

        this.filteredShippingTypes = this.transferForm
          ?.get('txtShippingType')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstShippingTypes.filter((option) =>
                option?.description
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });

    const filterUserByTenant: IdentityUserUsingTenantIdDto = {
      tenantId: localStorage?.getItem('tenantId') ?? '',
    };
    const userList = this.userService
      .getUserList(filterUserByTenant)
      .subscribe((response) => {
        this.lstUserList = response;
        this.lstUserList = response.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        this.defaultPackedBy = response[0].id;

        this.filteredUserList = this.transferForm
          ?.get('txtUserList')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstUserList.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });

    const userList1 = this.userService
      .getUserList(filterUserByTenant)
      .subscribe((response) => {
        this.lstUserList1 = response;
        this.defaultVerifiedBy = response[0].id;
        this.lstUserList1 = response.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        this.filteredUserList1 = this.transferForm
          ?.get('txtUserList1')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstUserList1.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
  }

  loadTransferData() {
    this.loadTable = false;
    this.tranferService
      .getInventoryTranferItemDetailsByGInventory(this.data.transferId)
      .subscribe(
        (response) => {
          this.loadTable = true;
          this.transferTable = response['inventoryTransferItemDTO'];
          this.transferTable.forEach((element) => {
            element['oldQty'] = element.quantity;
          });
          this.transferForm.patchValue({
            drpBranchType: response.sourceLocationId,
          });
          response.sourceLocationId ? (this.isSourceLocation = true) : false;
          // this.transferTable.forEach(element => {
          //   this.inventoryTableForm.patchValue({
          //     drpProdCode: element.productId,
          //     txtSerialNo: element.serialandLotNumebr,
          //     txtQuantity: element.quantity,
          //     txtShipping: element.shipmentComment
          //   })
          // });
        },
        (err) => {
          this.loadTable = true;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  selectRowserialNo() {
    this.selectserialNo = 'data';
    this.editMode = true;
  }

  selectRowQuantity() {
    this.selectQuantity = 'data';
    this.editMode = true;
  }

  selectRowShipping() {
    this.selectShippingCom = 'data';
    this.editMode = true;
  }

  onDropdownSelect(event) {
    this.productDes = event.value;
  }
  product: string = '';
  clearProduct() {
    this.product = '';

    this.inventoryTableForm.patchValue({ txtProdCode: '' });
  }

  runTransfer() {
    const saveDetails: CreateUpdateInventoryTransferDTO = {
      csrId: this.transferForm.value.drpCSR ?? defaultGuid,
      shippingMethodId: this.transferForm.value.drpShippingTypes ?? defaultGuid,
      sourceLocationId: this.transferForm.value.drpBranchType ?? defaultGuid,
      destinationLocationId: this.transferForm.value.drpLocation ?? defaultGuid,
      specialInstructions: this.transferForm.value.txtNote,
      createdDate: this.transferForm.value.txtDate,
      tenantId: this.tenantId,
      packed: this.transferForm.value.drpUserList
        ? this.transferForm.value.drpUserList
        : '00000000-0000-0000-0000-000000000000',
      lockedBy: this.transferForm.value.drpVerifyedBy
        ? this.transferForm.value.drpVerifyedBy
        : '00000000-0000-0000-0000-000000000000',
      trackingNo: this.transferForm.value.txtTrackingNo,
      lockedOn: '',
      status: this.transferForm.value.drpStatusType
        ? this.transferForm.value.drpStatusType
        : '00000000-0000-0000-0000-000000000000',
      transfer: true,
      inventoryTransferItemDTO: this.transferTable,
      //  [{
      //   inventoryTransferId: defaultGuid,
      //   productId: this.inventoryTableForm.value.drpProdCode ?  this.inventoryTableForm.value.drpProdCode :'00000000-0000-0000-0000-000000000000',
      //   quantity: this.txtQuantity,
      //   serialandLotNumebr: this.txtSerialNo? this.txtSerialNo : '',
      //   unitCost: 0,
      //   extendedCost: 0,
      //   mmInventroyId: this.transferTable.mmInventroyId,
      //   shipmentComment: this.txtShipping,
      //   tenantId: this.tenantId,
      //   ssId: 0,
      //   productName: ''

      // }]
    };

    this.tranferService.create(saveDetails).subscribe(
      (response) => {
        this.toastr.success('Saved Successfully', 'Success');
        this.dialog.closeAll();
      },

      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  saveTranferDetails() {
    const saveDetails: CreateUpdateInventoryTransferDTO = {
      csrId: this.transferForm.value.drpCSR ?? defaultGuid,
      shippingMethodId: this.transferForm.value.drpShippingTypes ?? defaultGuid,
      sourceLocationId: this.transferForm.value.drpBranchType ?? defaultGuid,
      destinationLocationId: this.transferForm.value.drpLocation ?? defaultGuid,
      specialInstructions: this.transferForm.value.txtNote,
      createdDate: this.transferForm.value.txtDate,
      tenantId: this.tenantId,
      packed: this.transferForm.value.drpUserList
        ? this.transferForm.value.drpUserList
        : '00000000-0000-0000-0000-000000000000',
      lockedBy: this.transferForm.value.drpVerifyedBy
        ? this.transferForm.value.drpVerifyedBy
        : '00000000-0000-0000-0000-000000000000',
      trackingNo: this.transferForm.value.txtTrackingNo,
      lockedOn: '',
      status: this.transferForm.value.drpStatusType
        ? this.transferForm.value.drpStatusType
        : '00000000-0000-0000-0000-000000000000',
      transfer: false,
      inventoryTransferItemDTO: this.transferTable,
      //  [{
      //   inventoryTransferId: defaultGuid,
      //   productId: this.inventoryTableForm.value.drpProdCode ?  this.inventoryTableForm.value.drpProdCode :'00000000-0000-0000-0000-000000000000',
      //   quantity: this.txtQuantity,
      //   serialandLotNumebr: this.txtSerialNo? this.txtSerialNo : '',
      //   unitCost: 0,
      //   extendedCost: 0,
      //   mmInventroyId: this.transferTable.mmInventroyId,
      //   shipmentComment: this.txtShipping,
      //   tenantId: this.tenantId,
      //   ssId: 0,
      //   productName: ''

      // }]
    };

    this.tranferService.create(saveDetails).subscribe(
      (response) => {
        this.toastr.success('Saved Successfully', 'Success');
        this.communicationService.triggerReloadStockTable();
        this.dialog.closeAll();
      },

      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
}
