import type { CreateUpdateProductCategoryDetailsDTO, ProductCategoryDataDTO, ProductCategoryDetailsDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductCategoryDetailsService {
  apiName = 'ItemManagement';

  create = (input: CreateUpdateProductCategoryDetailsDTO) =>
    this.restService.request<any, ProductCategoryDetailsDTO>({
      method: 'POST',
      url: '/api/ItemApp/product-category-details',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/ItemApp/product-category-details/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ProductCategoryDetailsDTO>({
      method: 'GET',
      url: `/api/ItemApp/product-category-details/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductCategoryDetailsDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-category-details',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });

    getListV1 = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductCategoryDataDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-category-details/async-v1',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });


  update = (id: string, input: CreateUpdateProductCategoryDetailsDTO) =>
    this.restService.request<any, ProductCategoryDetailsDTO>({
      method: 'PUT',
      url: `/api/ItemApp/product-category-details/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  searchProductCategoryBySproductCategoryNameAndStatusAndDtStartDateAndDtEndDate = (sproductCategoryName: string, Status: string, dtStartDate: string, dtEndDate: string) =>
    this.restService.request<any, ProductCategoryDetailsDTO[]>({
      method: 'GET',
      url: '/api/ItemApp/product-category-details/search-product-category',
      params: { sproductCategoryName, status: Status, dtStartDate, dtEndDate },
    },
    { apiName: this.apiName });
    getActiveCategoryList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductCategoryDetailsDTO>>({
      method: 'GET',
      url: '/api/ItemApp/product-category-details/active-category',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
    { apiName: this.apiName });


    getListV2 = () =>
    this.restService.request<any, ProductCategoryDetailsDTO[]>({
      method: 'GET',
      url: '/api/ItemApp/product-category-details/async-v2',
    },
    { apiName: this.apiName });


  constructor(private restService: RestService) {}
}
