// Created By Manikandan - 13 April 2024
// Last Modify By Manikandan - 18 April 2024

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { Subscription } from 'rxjs';
import { MoveToSecondaryModelComponent } from '../move-to-secondary-model/move-to-secondary-model.component';
import { PaymentMethodsModalComponent } from 'projects/order/src/app/payment-methods-modal/payment-methods-modal.component';
import { DatePipe } from '@angular/common';
import { ItemPaymentService } from '../../claim-proxy/claim-processing-management/rcm/claim-processing-management/x12/item-payment.service';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { UploadEOBComponent } from '../../claim-status-list/claim-status-list-table/upload-eob/upload-eob.component';
import { InsuranceRecoopComponent } from '../../claim-status-list/claim-status-list-table/insurance-recoop/insurance-recoop.component';
import { UpdateClaimStatusComponent } from '../../claim-status-list/claim-status-list-table/update-claim-status/update-claim-status.component';
import { ClaimViewTabComponent } from '../../claim-view-tab/claim-view-tab.component';

@Component({
  selector: 'app-auto-posting-eratable',
  templateUrl: './auto-posting-eratable.component.html',
  styleUrls: ['./auto-posting-eratable.component.scss'],
})
export class AutoPostingERATableComponent implements OnInit {
  @Input() DataList: any[];
  @Output() isLoadTbl = new EventEmitter<boolean>();
  dtAutoPostingOptions: any = {
    responsive: true,
    // stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '250px',
    scrollX: true,
    fixedHeader: true,
    order:[],
    columnDefs: [
      { targets: [0,-2,-1], orderable: false },
    //  { targets: [0], className: 'dt-fixed-column' },
    //   {
    //     targets: [8],
    //     type: 'date',
    //     render: function (data, type, row) {
    //       // Assuming data is in the format MM/DD/YYYY
    //       if (type === 'sort' || type === 'type') {
    //         var dateParts = data.split('/');
    //         return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
    //       } else {
    //         return data;
    //       }
    //     },
    //   },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Auto Posting (ERA)',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      {
        text: 'Show Default Column',
        action: function (e, dt, node, config) {
          dt.columns(':not(:lt(3),:last-child)').visible(false);
        },
      },
      {
        text: 'Show All Column',
        action: function (e, dt, node, config) {
          dt.columns().visible(true);
        },
      },
    ],
  };
  dtAutoPostingChildOptions: any = {
    responsive: true,
    stateSave: true,
    paging: false,
    dom: 'lrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    fixedHeader: true,
  };
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  isLoading: any;
  defaultGuid = defaultGuid;
  selectedRow: string = '';
  subscription$: Subscription[] = [];
  DataListChild: any = [];
  myTable: DataTables.Api;
  yourDate: Date;
  constructor(private snackBar: MatSnackBar, public matDialog: MatDialog,
    private datepipe: DatePipe,
    private dialog: MatDialog,
  private itemPaymentService: ItemPaymentService) {}

  ngOnInit() {

    this.dtAutoPostingOptions.buttons[0].filename =
    'Auto Posting (ERA)' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.calculateScrollY();
    this.yourDate = new Date('0001-01-01T00:00:00+05:53');

    window.addEventListener('resize', this.calculateScrollY);
    if(this.DataList){
      this.tblItemSelectedRow(this.DataList[0])
    } else {
      this.DataListChild = []
    }
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
    window.removeEventListener('resize', this.calculateScrollY);
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
    window.removeEventListener('resize', this.calculateScrollY);
  }
  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.2; // Adjust the percentage as needed
    this.dtAutoPostingOptions.scrollY = screenHeight * availableSpacePercentage;
  };
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

   // By Manikandan For collapse All Rows
   collapseAllRows(): void {
    const rows = this.myTable.rows({ page: 'current' }); // Get all rows on the current page
    rows.every((idx, data, node) => {
      const rowData = this.myTable.row(node).data();
      if (rowData) {
        this.myTable.row(node).child.hide(); // Hide the child row if it's expanded
      }
      return true; // Continue to the next row
    });
  }

  //Row Click Event UnProcessed Table
  handleTableCellClick(e: MouseEvent, notesData: any): void {
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }

    //Add Dynamic Notes Row in UnProcessed Table
    format(d: any, notesData: any) {
      let returnString: string = '';
      notesData?.forEach(function (value, index) {
        // returnString +=
        //   '<tr class="border-bottom" style="white-space: pre-wrap !important;"><td>' +
        //   value +
        //   '></td></tr>';
        returnString +=
          '<tr class="border-bottom " style="white-space: pre-wrap !important;"><td style="border: 1px solid rgba(0, 0, 0, 0.2); width: 100%">' +
          value +
          '</td></tr>';
      });
      return returnString;
    }


  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, notesData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;
    if (tr) {
      const row = this.myTable.row(tr);
      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        this.collapseAllRows(); // By Manikandan
        row.child(this.format(row.data(), notesData)).show();
      }
    }
  }

  tblItemSelectedRow(data: any) {
    if(this.selectedRow !== data?.claimId){
      this.selectedRow = data?.claimId;
      this.isLoading = true;
      this.LoadChildTable(data);
    }
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  LoadChildTable(data: any) {
    this.isLoading = true
    this.itemPaymentService.getItemPaymentsBasedOnClaimIdByClaimId(data?.claimId).subscribe(response => {
      this.DataListChild = response
      this.isLoading = false;
    },(err)=>{
      this.isLoading = false
    })

  }

  onCheckboxChangeSkip(data : any){

  }
  onCheckboxChangeOk(data : any){

  }

  isDefaultDate(data): boolean {

    const suppliesDate = this.datepipe.transform(data, 'MM/dd/yyyy');
    const defaultDate = this.datepipe.transform(this.yourDate, 'MM/dd/yyyy');
    return suppliesDate === defaultDate;
  }

//Add Notes 
  openAddNotesPopUpV1(
    event: MouseEvent,
    data: any,
    isCallNote: boolean
  ) {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        noteType: 'patient', // 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        patientId: data.patientId,
        defaultFaxId: data.defaultFaxId,
        isCallNote: isCallNote,
        isPending: false,
        docId: data.docId,
        inboundStatusShortCodeId: defaultGuid,
        addnotes: true,
        // patientNotes: data.patientNotes,
      },
    };
    const dialogRef = this.dialog.open(AddNoteTabComponent, config);
    const closeDialog = dialogRef.afterClosed().subscribe(() => {},
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
    this.subscription$.push(closeDialog);
  }

  //View Notes 
  openViewNotesPopup(patientId:string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      // height: '40vw',
      // maxHeight: '40vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note', // this.pageType, //Notes Table Popup Page
        isAddVisible: false,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {
      },
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //View Patent Ledger
  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  //Upload EOB
  uploadEOB(cliamData: any, isSaveEnable: boolean) {
    const dialogRef = this.dialog.open(UploadEOBComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        cliamData: cliamData,
        isSaveEnable: isSaveEnable,
      },
    });
    
    const closeDialog = dialogRef.afterClosed().subscribe(
      (isSaved: any) => {
        if (isSaved) {
          this.isLoadTbl.emit(true);
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }

  //Insurance ReCoop
  insuranceRecoop(cliamData: any) {
    this.dialog.open(InsuranceRecoopComponent, {
      disableClose: true,
      data: {
        cliamData: cliamData,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {
      this.isLoadTbl.emit(true);
    });
  }

  //Update Status
  UpdateStatus(cliamData: any) {
    this.dialog.open(UpdateClaimStatusComponent, {
      disableClose: true,
      data: {
        cliamData: cliamData,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {
      this.isLoadTbl.emit(true);
    });
  }

  //Move To Secondary 
  OpenMoveToSecondaryModel(claimData: any) {
    const dialogRef = this.dialog.open(MoveToSecondaryModelComponent, {
      minHeight: '33vh',
      minWidth: '52vw',
      data: {
        claimData: claimData,
      },
      disableClose: true,
    });

    const closeDialog = dialogRef.afterClosed().subscribe((isSaved: any) => {
      if (isSaved) {
        this.isLoadTbl.emit(true);
      }
    });
  }

  //Patient Responsibility
  OpenPatientResponsibilityModel(claimId: string, claimData: any) {
    const dialogRef = this.dialog.open(PaymentMethodsModalComponent, {
      data: { orderId: defaultGuid, claimId: claimId, claimData: claimData }, // this.orderId
      disableClose: true,
      // You can pass data to your dialog if needed
    });
    // Handle the dialog result if needed
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isLoadTbl.emit(true);
      }
    });
  }

  //Open Claim Form
  openClaimForm(
    orderId: string,
    patientId: string,
    chartNo: string,
    patientName: string,
    defaultSaleOrderId: string,
    claimId: string,
    isClaimBatch: boolean,
    isResubmit?: boolean
  ) {
    const dialogRef = this.dialog.open(ClaimViewTabComponent, {
      disableClose: true,
      data: {
        orderId: orderId,
        patientId: patientId,
        defaultSaleOrderId: defaultSaleOrderId,
        claimId: claimId,
        isClaimBatch: isClaimBatch,
        fullName: patientName,
        chartNo: chartNo,
        isResubmit: isResubmit ?? false,
      },
    });

    const closeDialog = dialogRef.afterClosed().subscribe(
      (claimDetails: any) => {
        if (isResubmit) {
          this.isLoadTbl.emit(true);
        }
      },
      (err) => {
      }
    );
  }

}
