<div class="container-fluid">
    <div class="row bg">
      <div class="col-12">
        <div class="d-flex align-item-center justify-content-between m-0">
          <h4 class="mt-3 mb-0 font-size-18"><b>Inventory / Zero Stock List</b></h4>
          <div class="page-title-box page-title-right mt-1 mb-0">
            <ol class="breadcrumbs m-0">
              <li style="margin-right: 11px;
              margin-top: -7px;">
                 <button matTooltip="Click To Add New stock" mat-button class="buttonColor font-size-10"
                (click)="openProductModal()">
                <i class="fa fa-plus mr-1" ></i>
                Add stock
              </button></li>
  
             
            </ol>
          </div>
        </div>
      </div>
    </div>
  
  
    <!-- Stock List Table -->
    <form [formGroup]="stockSearchForm">
      <div class="table table-responsive example-container mat-elevation-z4 pt-0 mt-0">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          class="pt-0 mt-0" style="margin-left: 15px !important;"
        >
          <!--Product Name-->
          <ng-container matColumnDef="productName">
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-productName" matInput formControlName="productName" />
                <mat-placeholder>Product Name</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.productName }}
              </ng-container>
            </td>
          </ng-container>
          <!--Brand Name-->
          <ng-container matColumnDef="brandName">
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input autocomplete="new-brandName" matInput formControlName="brandName" />
                <mat-placeholder>Brand Name</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.brandName }}
              </ng-container>
            </td>
          </ng-container>
          <!--Category Name-->
          <ng-container matColumnDef="categoryName">
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input
                autocomplete="new-categoryName"
                  matInput
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                  formControlName="categoryName"
                />
                <mat-placeholder>Category Name</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.categoryName }}
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="pricePerUnit">
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input
                autocomplete="new-pricePerUnit"
                  matInput
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                  formControlName="pricePerUnit"
                />
                <mat-placeholder>Price Per Unit</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.pricePerUnit }}
              </ng-container>
            </td>
          </ng-container>
          <!--Lot No-->
          <ng-container matColumnDef="lotNo">
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
              <mat-form-field class="filter">
                <input
                autocomplete="new-lotNo"
                  matInput
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                  formControlName="lotNo"
                />
                <mat-placeholder>Lot No</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.lotNo }}
              </ng-container>
            </td>
          </ng-container>
          <!--Stock Count-->
          <ng-container matColumnDef="stockCount">
            <th class="header" mat-sort-header mat-header-cell *matHeaderCellDef>
              Stock
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.soldQuantity }} / {{ data.quantity }}
              </ng-container>
            </td>
          </ng-container>
        
          <!-- Created Date-->
          <ng-container matColumnDef="createdDate">
            <th
              class="header"
              mat-sort-header
              class="w-125"
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <mat-label> Created Date </mat-label>
                <input
                autocomplete="new-createdDate"
                  matInput
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                  formControlName="createdDate"
                  placeholder="(MM/DD/YYYY)"
                />
                <mat-error
                  *ngIf="
                    stockSearchForm?.get('createdDate')?.touched &&
                    stockSearchForm?.get('createdDate')?.errors?.dateVaidator
                  "
                >
                  Enter Valid date
                </mat-error>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.createdDate | date : "MM/dd/yyyy h:mm a" : "en_US" }}
            </td>
          </ng-container>
         
        
          <!--No Records-->
          <ng-container matColumnDef="noRecords">
            <td
              class="text-align-center"
              mat-footer-cell
              *matFooterCellDef
              colspan="6"
            >
              <div *ngIf="!isShowSpinner">
                {{ "No Records Found" }}
              </div>
              <div [hidden]="!isShowSpinner">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            [ngStyle]="{ 'background-color': row.colorCode }"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
        <div
          *ngIf="
            !(dataSource && dataSource?.data && dataSource?.data?.length !== 0)
          "
          class="text-align-center"
        >
          <div class="message py-3" *ngIf="!isShowSpinner">
            {{ "No Records Found" }}
          </div>
          <div class="py-3" [hidden]="!isShowSpinner">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 20, 50, 100]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </form>
  </div>
  