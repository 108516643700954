import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import { PatientICDCodesService } from '../patient-proxy/controllers/patient-icdcodes.service';
import { TableService } from 'projects/shared/src/app/table.service';
import Swal from 'sweetalert2';
import { DiagnosisCode9Service } from '../dropdown-proxy/dropdown-management/dropdowns/diagnosis-code9.service';
import { DiagnosisCode10DTO, DiagnosisCode9DTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient/models';
import { Subscription } from 'rxjs';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-patient-icd9',
  templateUrl: './patient-icd9.component.html',
  styleUrls: ['./patient-icd9.component.scss']
})
export class PatientIcd9Component implements OnInit , OnDestroy{
  ICDCode9Form: FormGroup;
  strPageType: string = "icd9Page";
  isLoading: boolean;
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  txtICDCode: string = "";
  subscription$: Subscription[] = [];
  public icd9TableData: any[] = [];
  constructor(private formBuilder: FormBuilder,
    private icd9Service: PatientICDCodesService,
    private toastr: ToastrService,
    private icdSaveService: DiagnosisCode9Service,
    // private doctorService: DoctorService,
    // private npiDoctorService: GetDoctorNpiService,
    private Table: TableService,

  ) { }


  ngOnInit(): void {
    this.icd9TableData=[];
    this.createICDCode9();
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  organizationUnitId = null;
  SearchICDCode9() {
    this.isLoading = true;
    let icd9Search: DiagnosisCode9DTO = {
      diagnosisCodeTypeDataId: "",
      diagnosisCode: "",
      diagnosisCodeTypeData: this.ICDCode9Form.value.txtICDCode,
      isActive: 0,
      isExist: 0,
    }
    const ICD9Serach = this.icd9Service.getPatientICDCode9ByInput(icd9Search).subscribe((response: any) => {
      if (response.length != 0) {
        let responseArray: DiagnosisCode10DTO[] = response;
        this.icd9TableData = [];
        responseArray.forEach(responseData => {
          this.icd9TableData.push({
            sno: '',
            DiagnosisCode: responseData.diagnosisCode == null || undefined ? " " : responseData.diagnosisCode,
            DiagnsoisCodeData: responseData.diagnosisCodeTypeData == null || undefined ? " " : responseData.diagnosisCodeTypeData,
            isExists: responseData.isExist,
          })
        })

        this.Table.setICD9Table(this.icd9TableData);
        this.dataSource = new MatTableDataSource(this.icd9TableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      else {
        Swal.fire("Sorry!! Given Data does not exists ");
      }
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(ICD9Serach)
  }
  AddICD9(data) {
    Swal.fire({
      title: 'Are you sure you want to save?',
      text: 'Would You Like To Save ICDCode9 Details',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, Save!'
    }).then(result => {
      if (result.value) {
        // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
        let ICDode9Datas = data;
        let diagnosisCode9DTO: DiagnosisCode9DTO = {
          diagnosisCodeTypeDataId: "00000000-0000-0000-0000-000000000000",
          diagnosisCode: ICDode9Datas.DiagnosisCode,
          diagnosisCodeTypeData: ICDode9Datas.DiagnsoisCodeData,
          isExist: 1,
          isActive: 1,
          //organizationUnitId: ""
        };
        this.icdSaveService.create(diagnosisCode9DTO).subscribe(response => {
          if (response) {
            // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
            this.toastr.success('Saved Successfully','Success')
            this.SearchICDCode9();

          }
          else {
          }
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });

      }
    });
  }
  Reset() {
    this.ICDCode9Form.reset({
      txtICDCode: "",
      txtCodeDescription: "",
    });
  }
  createICDCode9() {
    this.ICDCode9Form = this.formBuilder.group({
      txtICDCode: new FormControl(""),
      txtCodeDescription: new FormControl(""),
    });
  }
}
