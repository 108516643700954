import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PatientCommonAuthorizationTabComponent } from './components/patient-common-authorization-tab/patient-common-authorization-tab.component';
import { PatientCommonDocumentTabComponent } from './components/patient-common-document-tab/patient-common-document-tab.component';
import { PatientCommonSummaryTabComponent } from './components/patient-common-summary-tab/patient-common-summary-tab.component';
import { PatientCommonVerificationTabComponent } from './components/patient-common-verification-tab/patient-common-verification-tab.component';
import {
  BoldConfigDirective,
  H1tagsConfigDirective,
  H2tagsConfigDirective,
  H3tagsConfigDirective,
  H4tagsConfigDirective,
  H5tagsConfigDirective,
  H6tagsConfigDirective,
  NormalConfigDirective,
} from './directive/config-directive.directive';
import { RouterModule } from '@angular/router';
import { FilterPipe } from './pipes/filter.pipe';
import { SharedTableComponent } from './shared-table/shared-table.component';
import { TableComponent } from './table/table.component';
import { DiagonsisPipe } from './pipes/diagonsis.pipe';
import { PatientCommonNotesComponent } from './components/patient-common-notes/patient-common-notes.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { PatientCommonDocumentMedicareTabComponent } from './components/patient-common-document-medicare-tab/patient-common-document-medicare-tab.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PatientCommonDocumentMedicareUploadTabComponent } from './components/patient-common-document-medicare-upload-tab/patient-common-document-medicare-upload-tab.component';
import { CommonDocViewerModalComponent } from './components/common-doc-viewer-modal/common-doc-viewer-modal.component';
import { ScollToTopComponent } from './components/scoll-to-top/scoll-to-top.component';
import { SharedViewPatientLedgerComponent } from './components/shared-view-patient-ledger/shared-view-patient-ledger.component';
import { DataTablesModule } from 'angular-datatables';
import { MatMenuModule } from '@angular/material/menu';
import { CreateUpdatePatientAddressComponent } from './components/create-update-patient-address/create-update-patient-address.component';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';
import { PatientAddressListComponent } from './components/patient-address-list/patient-address-list.component';
import { PatientCommonSortingTabComponent } from './components/PatientCommonSortingTab/PatientCommonSortingTab.component';
import { DoVerificationModalComponent } from './components/do-verification-modal/do-verification-modal.component';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TiffViewerComponent } from './components/tiff-viewer/tiff-viewer.component';
import { PatientCommonNotesV1Component } from './components/patient-common-notes-v1/patient-common-notes-v1.component';
import { PatientReminderComponent } from './components/patient-reminder/patient-reminder.component';
import { PatientReminderDashboardComponent } from './components/patient-reminder-dashboard/patient-reminder-dashboard.component';
import { PatientNotesModalComponent } from './components/patient-notes-modal/patient-notes-modal.component';
import { PatientReminderV1Component } from './components/patient-reminder-v1/patient-reminder-v1.component';
import { LoaderComponentComponent } from './components/loader-component/loader-component.component';
import { PatientAddressListTableComponent } from './components/patient-address-list-table/patient-address-list-table.component';
import { CommonDocTiffViewerComponent } from './components/common-doc-tiff-viewer/common-doc-tiff-viewer.component';
import { CommonSortingComponent } from './components/common-sorting/common-sorting.component';
import { PatientReminderDashboardTableComponent } from './components/patient-reminder-dashboard/patient-reminder-dashboard-table/patient-reminder-dashboard-table.component';
import { CommonSwitchComponent } from './components/common-switch/common-switch.component';
import { PatientCommonClaimComponent } from './components/patient-common-claim/patient-common-claim.component';
import { PatientCommonClaimTableComponent } from './components/patient-common-claim/patient-common-claim-table/patient-common-claim-table.component';
import { CommonModelPDFViewerBase64Component } from './components/common-model-pdfviewer-base64/common-model-pdfviewer-base64.component';
import { CommonDoumentViewerForMedicareComponent } from './components/common-doument-viewer-for-medicare/common-doument-viewer-for-medicare.component';
import { CommonSortingViewComponent } from './components/common-sorting-view/common-sorting-view.component';
import { CustomTooltipDirective } from './directive/cutom-tooltip.directive';
import { CustomToolTipComponent } from './components/custom-tool-tip/custom-tool-tip.component';
import { ToolTipRendererDirective } from './directive/tool-tip-renderer.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PatientCommonPdfViewerComponent } from './components/patient-common-pdf-viewer/patient-common-pdf-viewer.component';

@NgModule({
  declarations: [
    TableComponent,
    SharedTableComponent,
    NormalConfigDirective,
    BoldConfigDirective,
    H1tagsConfigDirective,
    H2tagsConfigDirective,
    H3tagsConfigDirective,
    H4tagsConfigDirective,
    H5tagsConfigDirective,
    H6tagsConfigDirective,
    CustomTooltipDirective,
    ToolTipRendererDirective,
    CustomToolTipComponent,
    FilterPipe,
    PatientCommonAuthorizationTabComponent,
    PatientCommonDocumentTabComponent,
    PatientCommonSummaryTabComponent,
    PatientCommonNotesComponent,
    PatientCommonVerificationTabComponent,
    DiagonsisPipe,
    PatientCommonDocumentMedicareTabComponent,
    PatientCommonDocumentMedicareUploadTabComponent,
    CommonDocViewerModalComponent,
    ScollToTopComponent,
    SharedViewPatientLedgerComponent,
    CreateUpdatePatientAddressComponent,
    PatientAddressListComponent,
    PatientCommonSortingTabComponent,
    DoVerificationModalComponent,
    TiffViewerComponent,
    PatientCommonNotesV1Component,
    PatientReminderComponent,
    PatientReminderDashboardComponent,
    PatientNotesModalComponent,
    PatientReminderV1Component,
    LoaderComponentComponent,
    PatientAddressListTableComponent,
    CommonDocTiffViewerComponent,
    CommonSortingComponent,
    PatientReminderDashboardTableComponent,
    CommonSwitchComponent,
    PatientCommonClaimComponent,
    PatientCommonClaimTableComponent,
    CommonModelPDFViewerBase64Component,
    CommonDoumentViewerForMedicareComponent,
    CommonSortingViewComponent,
    CustomToolTipComponent,
    PatientCommonPdfViewerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSortModule,
    MatToolbarModule,
    MatTooltipModule,
    MatCardModule,
    MatSelectModule,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    TextMaskModule,
    NgxMaskModule,
    MatDialogModule,
    MatDividerModule,
    NgxExtendedPdfViewerModule,
    MatTreeModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatIconModule,
    MatExpansionModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatTableExporterModule,
    MatDatepickerModule,
    MatRadioModule,
    NgxMatSelectSearchModule,
    DataTablesModule,
    MatMenuModule,
    NgbNavModule,
    RouterModule.forRoot([]),
  ],
  exports: [
    TableComponent,
    SharedTableComponent,
    NormalConfigDirective,
    BoldConfigDirective,
    H1tagsConfigDirective,
    H2tagsConfigDirective,
    H3tagsConfigDirective,
    H4tagsConfigDirective,
    H5tagsConfigDirective,
    H6tagsConfigDirective,
    CustomTooltipDirective,
    ToolTipRendererDirective,
    CustomToolTipComponent,
    FilterPipe,
    PatientCommonAuthorizationTabComponent,
    PatientCommonDocumentTabComponent,
    PatientCommonSummaryTabComponent,
    PatientCommonVerificationTabComponent,
    PatientCommonNotesComponent,
    PatientCommonNotesV1Component,
    PatientReminderComponent,
    PatientReminderDashboardComponent,
    DiagonsisPipe,
    PatientCommonDocumentMedicareTabComponent,
    CommonDocViewerModalComponent,
    ScollToTopComponent,
    SharedViewPatientLedgerComponent,
    PatientCommonSortingTabComponent,
    PatientAddressListComponent,
    LoaderComponentComponent,
    CommonSortingComponent,
    CommonSwitchComponent,
    PatientCommonClaimComponent,
    CommonModelPDFViewerBase64Component,
    CommonDoumentViewerForMedicareComponent,
    CommonSortingViewComponent
  ],
})
export class SharedModule {}
