import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PatientAddPolicyTabComponent } from '../../patient-add-policy-tab/patient-add-policy-tab.component';
import { PatientPolicyDTO, PatientVerificationDTO } from '../../patient-proxy/patient-optimization/dto';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { PatientManualVerificationTabComponent } from '../../patient-manual-verification-tab/patient-manual-verification-tab.component';
import { Subscription } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ToastrService } from 'ngx-toastr';
import { PatientPolicyService } from '../../patient-proxy/patient-optimization/patient-policy.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { VerificationStatus, VerificationStatusV1 } from '../../patient-proxy/patient/enumeration-data/verification-status.enum';
import { InsuranceType } from '../../patient-proxy/patient/enumeration-data/insurance-type.enum';
import { InsuranceTypeV1 } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/billing-management/billing/insurance-type.enum';
import { DatePipe } from '@angular/common';
import { PatientPersonalService } from '../../patient-proxy/patient-optimization/patient-personal.service';
// import { PatientPolicyDTO, PatientVerificationDTO } from '../patient-proxy/patient-optimization/dto';
@Component({
  selector: 'app-patient-insurance-tab-table',
  templateUrl: './patient-insurance-tab-table.component.html',
  styleUrls: ['./patient-insurance-tab-table.component.scss']
})
export class PatientInsuranceTabTableComponent implements OnInit {
  public bOpenNewPolicy: boolean;
  subscription$: Subscription[] = [];
  PatientPersonalAPICall:Subscription;
  wipId: string;
  isLoading: boolean = true;
  policyDetails:any;
  lstVerificationSttaus=VerificationStatus;
  lstVerificationSttausV1=VerificationStatusV1;
  @Input() tableData:any;
  @Input() patientId: string = defaultGuid;
  @Input() selectedOption:string;
  @Input() defaultFaxId:string;
  @Input() documentId:string;
  @Output() patientIdEmitter: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  InsuranceType=InsuranceType;
  InsuranceTypeV1=InsuranceTypeV1;
  constructor(
    public dialog: MatDialog,
    private patientPolicyService: PatientPolicyService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private datepipe:DatePipe,
    private patientPersonalService: PatientPersonalService,
  ) {}


  dtInsuranceTabOptions = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '400px',
    scrollX: true,
    fixedHeader: true,
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Policy List',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))',
      }
    ],
  };

  ngOnInit(): void {
    this.policyDetails=this.tableData;

    this.dtInsuranceTabOptions.buttons[0].filename =
    'Policy List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  //After View Method
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
    setTimeout(() => {
      $('#tblPolicyList').DataTable().columns.adjust().draw();
    }, 200);
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  //To View The Policy
  EditPolicy(policy: PatientPolicyDTO) {
    this.bOpenNewPolicy = false;
    const dialogRef = this.dialog.open(PatientAddPolicyTabComponent, {
      data: {
        patientId: this.patientId,
        policyId: policy.policyId,
        verificationId: policy?.lastVerificationId,
        wipId: this.wipId ?? defaultGuid,
        verificationStatus: policy.verificationStatus,
        defaultFaxId:this.defaultFaxId,
        documentId:this.documentId
      },
    });
    const aftClosed = dialogRef.afterClosed().subscribe(
      (result) => {
        this.patientIdEmitter.next(this.patientId);

        // this.patientId !== defaultGuid && this.getPolicyData(1);--
        this.patientId !== defaultGuid &&  this.communicationService.triggerLoadPolicyList(this.selectedOption);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(aftClosed);
  }

  viewVerificationById(verification: PatientVerificationDTO): void {
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      data: {
        patientId: this.patientId, verificationId: verification?.lastVerificationId, policyId: verification?.policyId, savehidden: true,
        verificationStatus: verification.verificationStatus,defaultFaxId:this.defaultFaxId,
        documentId:this.documentId
      }
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      dialogRef?.close(result);
    });
    this.subscription$.push(closeDialog);
  }

  StatusChange(event: MatSlideToggleChange, data: any) {

    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Policy will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          if (data?.policyId && data?.policyId !== "" && data?.policyId !== null && data?.policyId !== undefined) {
            this.patientPolicyService.getPolicyActiveInactiveStatusV1(data?.policyId).subscribe(response => {
              this.isLoading = true;
              this.toastr.success('Activated Successfully', 'Success')
              this.patientId !== defaultGuid &&  this.communicationService.triggerLoadPolicyList(this.selectedOption);
              // this.getPolicyData(2);--
            },
              (err) => {
                this.isLoading = true;
                this.toastr.success('Activated Successfully', 'Success')
                this.patientId !== defaultGuid &&  this.communicationService.triggerLoadPolicyList(this.selectedOption);
                // this.getPolicyData(2);--
              });
          }
        }
        else {
          event.source.checked = false;
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this  Policy will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            if (data?.policyId && data?.policyId !== "" && data?.policyId !== null && data?.policyId !== undefined) {
              this.patientPolicyService.getPolicyActiveInactiveStatusV1(data?.policyId).subscribe(response => {
                this.isLoading = true;
                this.toastr.success('Deactivated Successfully', 'Success')
                this.patientId !== defaultGuid &&  this.communicationService.triggerLoadPolicyList(this.selectedOption);
                // this.getPolicyData(1);--
              },
                (err) => {
                  this.isLoading = true;
                  this.toastr.success('Deactivated Successfully', 'Success')
                  this.patientId !== defaultGuid &&  this.communicationService.triggerLoadPolicyList(this.selectedOption);
                  // this.getPolicyData(1);--
                });

            }
          }
          else {
            event.source.checked = true;
          }
        });
      }
  }
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }
}
