<div class="fluid-container">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between m-0">
        <h4 class="font-size-18 m-0 pl-3"><b>Shipping Approval</b></h4>
        <div class="page-title-box page-title-right pt-2 pb-0">
          <ol class="breadcrumbs m-0">
            <li class="blinking ml-2 mt-2 mr-2 breadcrumb-item"></li>
            <li class="mb-1">
              <button matTooltip="Back To Shipping Approval" mat-button (click)="backToShippingApprovalList()"
                class="buttonColor font-size-10">
                <i class="fa fa-backward mr-1"></i> Back To Shipping Approval
              </button>

            </li>
            <li class="ml-2 mt-2 breadcrumb-item">Patient</li>
            <li class="mt-2 breadcrumb-item muted">SaleOrder</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-2 pr-0 mb-3">
      <app-patient-common-summary-tab *ngIf="!!patientId" [orderId]="orderId" [patientId]="patientId">
      </app-patient-common-summary-tab>
      <app-patient-common-summary-tab *ngIf="!patientId" [orderId]="orderId" [patientId]="patientId">
      </app-patient-common-summary-tab>
    </div>

    <div class="col-xl-10 pr-0">
      <ul [(activeId)]="navId" ngbNav #nav="ngbNav" (navChange)="onNavChange($event)" justify="justified"
        class="alert alert-success nav nav-pills nav-fill pt-3">
        <li ngbNavItem="Document" [disabled]="orderId === defaultGuid">
          <a ngbNavLink>Document</a>
          <ng-template ngbNavContent>

            <app-patient-common-document-tab [patientId]="patientId"></app-patient-common-document-tab>
          </ng-template>
        </li>
        <li ngbNavItem="Verification" [disabled]="orderId === defaultGuid">
          <a ngbNavLink>Verification</a>

          <ng-template ngbNavContent>
            <app-patient-common-verification-tab [patientId]="patientId"></app-patient-common-verification-tab>
          </ng-template>
        </li>
        <li ngbNavItem="Authorization" [disabled]="orderId === defaultGuid">
          <a ngbNavLink>Authorization</a>
          <ng-template ngbNavContent>
            <app-patient-common-authorization-tab [orderId]="orderId" [patientId]="patientId">
            </app-patient-common-authorization-tab>
          </ng-template>
        </li>
        <li ngbNavItem="PatientLedger" [disabled]="orderId === defaultGuid">
          <a ngbNavLink>Patient Ledger</a>
          <ng-template ngbNavContent>
            <app-shared-view-patient-ledger [orderId]="orderId" [patientId]="patientId">
            </app-shared-view-patient-ledger>
          </ng-template>
        </li>
      </ul>
      <div class="row approvalDiv" *ngIf="navId !== 'PatientLedger'" >
        <mat-label [ngSwitch]="navId" class="pt-2 approvalLabel">
          <ng-container *ngSwitchCase="'Document'">
            Document Approval
          </ng-container>
          <ng-container *ngSwitchCase="'Verification'">
            Verification Approval
          </ng-container>
          <ng-container *ngSwitchCase="'Authorization'">
            Authorization Approval
          </ng-container>
          <span class="asterisk">*</span>
        </mat-label>


        <mat-radio-group [(ngModel)]="approve" class="col-sm-2 radio mt-2 mr-2 txt-left">
          <mat-radio-button class="mr-2" value="true">Approve</mat-radio-button>
          <mat-radio-button class="mr-3" value="false">Disapprove</mat-radio-button>
        </mat-radio-group>
        <button class="waves-effect ml-2 btn-rounded btnApproval " [disabled]="approve===''" (click)="saveApprovalStatus(navId,approve)"
          mat-raised-button color="primary">
          <!-- <mat-icon ="isShowSpinner">
          <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
          </mat-spinner>
        </mat-icon> -->
          <mat-icon>check_circle</mat-icon>
          Update
        </button>
      </div>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
