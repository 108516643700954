<table id="tbl" datatable [dtOptions]="dtOptions" class="row-border hover nowrap_th_td w-100 display">
  <thead>
    <tr>
      <th [matTooltip]="'NPI'">NPI</th>
      <th [matTooltip]="'Physicians'">Physicians</th>
      <th [matTooltip]="'FAX'">FAX</th>
      <th [matTooltip]="'Address1'">Address1</th>
      <th [matTooltip]="'City'">City</th>
      <th [matTooltip]="'State'">State</th>
      <th [matTooltip]="'Country'">Country</th>
      <th [matTooltip]="'Postal Code'">Postal Code</th>
      <th [matTooltip]="'Action'">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of doctorTableData">
      <td [matTooltip]="data.NpiNumber || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.NpiNumber || '-')">{{ data.NpiNumber || '-' }}</td>
      <td [matTooltip]="data.FullName || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.FullName || '-')">{{ data.FullName || '-' }}</td>
      <td [matTooltip]="data.faxNo || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.faxNo || '-')">{{ data.faxNo || '-' }}</td>
      <td [matTooltip]="data.Address1 || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.Address1 || '-')">{{ data.Address1 || '-' }}</td>
      <td [matTooltip]="data.City || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.City || '-')">{{ data.City || '-' }}</td>
      <td [matTooltip]="data.State || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.State || '-')">{{ data.State || '-' }}</td>
      <td [matTooltip]="data.Country || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.Country || '-')">{{ data.Country || '-' }}</td>
      <td [matTooltip]="data.PostalCode || '-'"
                            (dblclick)="onRowDoubleClickV1(data?.PostalCode || '-')">{{ data.PostalCode || '-' }}</td>
      <td class="text-center">
        <div *ngIf="data.isExists; then content; else other_content">Already Exists</div>
        <ng-template #content>Already Exists</ng-template>
        <ng-template #other_content>
          <div>
            <a target="_blank" (click)="AddNpiDoctor(data)">
              <i class="fa fa-plus pointer mr-5"></i>
            </a>
          </div>
        </ng-template>
      </td>
    </tr>
  </tbody>
</table>