import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import {
  dtNotesTableOptions,
  notesList,
  userList,
} from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { UserByRoleDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { InboundStatusService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound-status/inbound-status.service';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-wip-allocation-modal-v2',
  templateUrl: './wip-allocation-modal-v2.component.html',
  styleUrls: ['./wip-allocation-modal-v2.component.scss'],
})
export class WipAllocationModalV2Component implements OnInit {
  allocationForm: FormGroup;
  CSRFilterControl= new FormControl();
  selectedUserId: string | null = null;
  seletedCSRValue: string = defaultGuid;
  lstUsers: UserByRoleDTO[] = [];
  filteredCSRList: UserByRoleDTO[];
  csrdetails: string;
  filteredIdentityUsers: Observable<UserByRoleDTO[]> = of([]);
  constructor(private fb: FormBuilder,
    private communicationService: CommunicationService,
    private userService:UserService,
    private inboundStatusService: InboundStatusService,
    private toaster: ToastrService,
    public dialogRef: MatDialogRef<WipAllocationModalV2Component>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      docId: string;
      faxId:string;
      selectedNode:string;
    } = {
      docId: defaultGuid,
      faxId:defaultGuid,
      selectedNode:''
    }) {}
  userList = userList;
  notesText: string = '';
  notesList = notesList;
  dtNotesTableOptions = dtNotesTableOptions;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  ngOnInit(): void {

    this.initializeForms();
    this.loadDropdowns('');
    this.CSRFilterControl.valueChanges.subscribe((dxTypeFilterText) => {
      dxTypeFilterText = dxTypeFilterText.toLowerCase();
      this.filteredCSRList = this.lstUsers
        .filter((dx) => dx.name.toLowerCase().includes(dxTypeFilterText))
        .sort((a, b) => a.name.localeCompare(b.name));
    });
    this.allocationForm = this.fb.group({
      txtComment: new FormControl(''),
      drpCSR:new FormControl('')
    });
  }
  initializeForms() {
    this.allocationForm = this.fb.group({
      txtAgentFilter: new FormControl(''),
      txtComment: new FormControl(''),
    });
  }
  loadDropdowns(search) {
    const csrUsersList = this.userService
    .getCSRUsers(search)
    .subscribe((response) => {
      this.lstUsers = response;
      this.filteredCSRList = this.lstUsers;
      this.filteredCSRList.sort((a, b) => a.name.localeCompare(b.name));
    });

  }
  UserSearch(value){
    this.loadDropdowns(value);
    }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  //Row Click Event UnProcessed Table
  handleTableCellClick(e: Event, notesData: any): void {
    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }
  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, notesData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;

    if (tr) {
      const row = this.myTable.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        row.child(this.format(row.data(), notesData)).show();
      }
    }
    // Now you can use the dataTable object to get data, for example:
    // const data = this.myTable.rows().data().toArray();

  }
  //Add Dynamic Notes Row in UnProcessed Table
  format(d: any, notesData: any) {
    let returnString: string = '';

    returnString += '<tr class="border-bottom"><td>' + notesData + '</td></tr>';

    return returnString;
    // (
    //   '<tr class="border-bottom"><td>' +
    //   'There is a language course organised at the training centre. Your unit can send one soldier to attend this course. You have been tasked with giving a briefing presenting two possible candidates' +
    //   '</td></tr>' +
    //   '<tr class="border-bottom"><td>' +
    //   'Let me start with Candidate A and his strengths. Captain Nowak is a very experienced officer who has participated in a few missions abroad and cooperated with our foreign allies. Therefore, he has plenty of practical experience in real life communication in English and working in this language' +
    //   '</td></tr>'
    // );
  }
  saveCSRDetails(seletedCSRValue:string,comments:string)
  {
    this.inboundStatusService
  .csrDetailsSave(comments, defaultGuid,'',this.data.docId, seletedCSRValue)
  .subscribe(
    (response) => {
      // Handle successful response
      // this.csrdetails = response;

      this.toaster.success('Updated Successfully', 'Success');
      this.communicationService.triggerFunctionCall(this.data.selectedNode);
      this.dialogRef.close();
    },
    (error) => {
      // Handle error response
      if (error.error && error.error.message) {
        // Access the error message
        const errorMessage = error.error.message;

        // Handle the error message as needed
        console.error('Error:', errorMessage);

        // For example, show an error toast or alert
        this.toaster.error(errorMessage, 'Error');
      } else {
        // Handle other types of errors
        console.error('Unexpected error:', error);
      }
    }
  );


  }
  resetCallBack() {
    this.allocationForm.reset({
      txtComment: '',
      CSRFilterControl: '',
    });
  }
}
