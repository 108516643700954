//#region Imports
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  MatTree,
  MatTreeNestedDataSource,
  MatTreeNode,
} from '@angular/material/tree';
import { ApexOptions, ChartComponent } from 'ng-apexcharts';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { UserByRoleDTO } from '../admin-proxy/platform-app-management/identity/models';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { MatSelect } from '@angular/material/select';
import { DateAdapter, MatOption } from '@angular/material/core';
import { OrganizationUnitDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddNoteTabComponent } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import Swal from 'sweetalert2';
import {
  csrPointsTableData,
  dtCSRTableOptions,
  dtMonthlyProductSalesOptions,
  dtProductTableOptions,
  dtOverrideTableOptions,
  dtReminderTableOptions,
  dtSuppliesTableOptions,
  monthlyProductSalesTableData,
  productTableData,
  reminderTableData,
  suppliesTableData,
} from './dashboard-data';
import { faxTableInputDataDto } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import { defaultGuid } from '../document-perviewer/document-perviewer.component';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { AddFaxDocumentsComponent } from '../add-fax-documents/add-fax-documents.component';
import { CommunicationService } from '../../../../shared/src/app/services/communication.service';
import { Title } from '@angular/platform-browser';
import { NotificationsService } from 'projects/layout/src/app/notiifcation/notifications.service';
import { FaxFinderComponent } from './faxFinder/faxFinder.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FaxFinderLazyLoadingComponent } from './fax-finder-lazy-loading/fax-finder-lazy-loading.component';
import { FaxFinderLazyLoadingV1Component } from './fax-finder-lazy-loading-v1/fax-finder-lazy-loading-v1.component';
import { DashboardService } from '../admin-proxy/platform-app-management/rcm/platform-management/dashboard';
import { map } from 'jquery';
import { MyFaxfilter } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/my-faxfilter.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { OrderDashboardService } from 'projects/order/src/app/order-proxy/order-management/order/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { DashboardService_Csr3MonthStatistics, DashboardService_CsrCommission, DashboardService_CsrHurdleStat, DashboardService_DashboardExchanges } from 'projects/order/src/app/order-proxy/order-management/order/models';
// import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { OverrideListDTO } from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto/models';
import { NotesReasonComponent } from 'projects/inventory/src/app/notes-reason/notes-reason.component';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { NotesType } from 'projects/order/src/app/order-proxy/order-management/inventory/dto/models';
import { AppOrderWorksheetMdlComponent } from '../app-order-worksheet-mdl/app-order-worksheet-mdl.component';

//#endregion
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
  //#region Declarations
  @ViewChild('treeView', { static: false }) treeView: MatTree<any>;
  fmAdminDashboard: FormGroup;
  strPageType: any = 'Default';
  tableInput: faxTableInputDataDto = {
    user: [],
    branch: [],
    statusCode: '',
    month: 0,
    year: 0,
  }; //Input Dto for the MyFax Table
  activeTabId: string;
  inboundTableData: any;
  totalFaxCount: number = 0;
  seletedCSRValue: string = defaultGuid;
  seletedBranchValue: string = defaultGuid;
  selectedInboundStatusId: string = defaultGuid;
  selectedShortCodeId: string = '001';
  selectedMonth: number = 0;
  selectedYear: number = 0;
  branchNames: any;
  treeControl: NestedTreeControl<TreeDTO>;
  selectedNode: any = null; // Store the selected tree node element
  treeData: TreeDTO[] = [];
  treeDataValue: any;
  dataSource: MatTreeNestedDataSource<TreeDTO>;
  loadChildComponent = true;
  dateValue: any;
  missingDocDefault: any;
  selectedDate: any;
  dates: Date[] = [];
  currentIndex = 0;
  pageSize = 5;
  displayedDates: any;
  selectedDateIndex: number | null = null;
  @ViewChild('chartObj') chart: ChartComponent;
  public chartOptions: Partial<ApexOptions>;
  lstUsers: UserByRoleDTO[] = [];
  filteredIdentityUsers: Observable<UserByRoleDTO[]> = of([]);
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchForm: FormGroup;
  csrForm: FormGroup;
  branchValue: string[] = [];
  lstBranch: OrganizationUnitDTO[] = [];
  organizationUnitName: string;
  CSRFilterControl = new FormControl();
  branchFilterControl = new FormControl();
  recursive: boolean = false;
  filteredDocumentTypeList: UserByRoleDTO[];
  drCSRList: any;
  // filteredCSRTypeList: UserByRoleDTO[];
  filteredCSRList: UserByRoleDTO[];
  filteredBranchTypeList: OrganizationUnitDTO[];
  // selectedDate = new FormControl(new Date());
  dateCtrl = new FormControl();
  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  tenantId: string = '';
  tableData$: Subscription;
  myFaxFilter: any;
  subscription$: Subscription[] = [];
  maxDate: Date;
  selectedOption: MyFaxfilter;
  MyFaxfilter = MyFaxfilter;
  isMyFaxBtn: boolean = false;
  myTree$: Subscription;
  branchListAPI$: Subscription;
  userListAPI$: Subscription;
  isTreeLoad = true;
  //Table Datas
  isreminder: string;
  isTabclick: string = 'Unchecked';
  isFax: string;
  //Product Sales Table
  monthlyProductSalesTableData = monthlyProductSalesTableData;
  dtMonthlyProductOptions = dtMonthlyProductSalesOptions;

  //CSR Points Table
  csrPointsTableData = csrPointsTableData;
  dtCSRTableOptions = dtCSRTableOptions;

  //Product Exchange Table
  productTableData = productTableData;
  dtProductTableOptions = dtProductTableOptions;
  dtOverrideTableOptions = dtOverrideTableOptions;

  //Supplies Table
  suppliesTableData = suppliesTableData;
  dtSuppliesTableOptions = dtSuppliesTableOptions;

  //Reminder Table
  reminderTableData = reminderTableData;
  dtReminderTableOptions = dtReminderTableOptions;

  // //Call Notes Table
  // calllogTableData = calllogTableData;
  // dtCalllogTableOptions = dtCalllogTableOptions;
  showReminderTab = false;
  patientForm: FormGroup;
  $subscription: any;
  isLoading: boolean;
  selectedOption1: any;
  selectedRowIndex: number;
  vDescription: any;
  remindertbldata: any;
  totalCount: number;
  getInboundTableData: Subscription;
  @ViewChildren('counterLabel') counterLabels: QueryList<ElementRef>;
  @ViewChild('txtDateValue') txtDateValue: MatDatepicker<Date>;
  changeLocation: OrganizationUnitDTO[];
  selectedPageType: any;
  //#endregion
  currentPage : any = 1;
  pageOffset: any = 50;
  totalPages : any = 1;
  visibleColumns : number[];
  constructor(
    private communicationService: CommunicationService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private branchService: OrganizationUnitService,
    public matDialog: MatDialog,
    private InboundDocService: InboundDocumentService,
    private title: Title,
    private dateAdapter: DateAdapter<Date>,
    private dashboardService: DashboardService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private orderDashboardService: OrderDashboardService,
    private toastr: ToastrService,
    // private loaderService: LoaderService,
    private mmOrderService: MmOrderService,
  ) {
    // this.loaderService.getLoaderState().subscribe((isOrderPageLoading) => {
    //   this.isgetdashboardDetails = isOrderPageLoading;
    // });
    this.communicationService.functionCall$.subscribe((selectedNode) => {
      this.setFaxTableInputs();
      this.getTreeData(selectedNode);
    });

    this.communicationService.fnCallTreeOnly$.subscribe((selectedNode) => {
      this.getTreeData(selectedNode);
    });

    this.communicationService.functionReminder$.subscribe((selectedNode) => {
      this.activateReminderTab();
    });

    this.maxDate = this.dateAdapter.addCalendarYears(new Date(), 0);
    this.chartOptions = {
      series: [
        {
          name: 'Sales',
          data: [30, 40, 45, 50, 49, 60, 70, 91, 125, 150],
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          'Marie J',
          'Patricia A',
          'Toni S',
          'Angeline N',
          'Aimee B',
          'Chaies C',
          'Sarah B',
          'Tracy J',
          'Ashiey J',
          'Jincy R',
        ],
      },
      // yaxis: {
      //   title: {
      //     text: 'Sales'
      //   }
      // },
      fill: {
        opacity: 1,
      },
    };
    var date1 = new Date(new Date().getFullYear(), 0, 1);
    var date2 = new Date();
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    let loop = new Date();
    this.dates = [new Date(), new Date(new Date().setDate(loop.getDate() - 1))];
    for (let i = 0; i < Difference_In_Days; i++) {
      let newDate = loop.setDate(loop.getDate() - 1);

      loop = new Date(newDate);
      this.dates.push(loop);
    }
    this.displayDates();
  }

  ngOnInit(): void {
    this.isMyFaxBtn = false;
    this.getTreeData(null);
    this.retrieveAndSetTenantId()
      .then(() => {
        // Now that tenantId is set, you can proceed with other logic
        this.loadDropdownBranch();
      })
      .catch((error) => {
        // console.error('Error retrieving and setting tenantId:', error);
      });

    this.selectedOption = 0;

    this.title.setTitle('Qsecure | Dashboard');
    //Default Tree View Data
    this.treeControl = new NestedTreeControl<TreeDTO>(this.getChildren);
    //Default DatePicker Inputs
    this.dateValue =
      new Date().toLocaleString('default', { month: 'short' }) +
      '/' +
      new Date().getFullYear();
    this.selectedDate =
      new Date().getMonth() + 1 + '/' + new Date().getFullYear();
    this.selectedMonth = new Date().getMonth() + 1;
    this.selectedYear = new Date().getFullYear();

    this.selectedOption1 = MyFaxfilter.Pending;
    this.selectedPageType = 'Default';
    // this.loadDropdown();
    this.loadDropdownBranch();
    this.loadDropdownUser('');

    // this.csrFilterControl.setValue('');

    // this.branchFilterControl.setValue('');

    this.fmAdminDashboard = this.formBuilder.group({
      drLocationList: new FormControl(),
      txtDate: new FormControl(),
      txtDateValue: new FormControl(''),
      drpCSR: new FormControl('', Validators.required),
      drpBranch: new FormControl('', Validators.required),
      txtBranch: new FormControl(),
      drpBranchType: new FormControl(),
      dMonthPicker: new FormControl('', Validators.required),
    });
    this.fmAdminDashboard.patchValue({
      txtDateValue: this.dateValue,
    });
    if (localStorage.getItem('roleName') !== 'admin') {
      this.seletedCSRValue = localStorage.getItem('userId') ?? defaultGuid;
      this.seletedBranchValue =
        localStorage.getItem('organizationId') ?? defaultGuid;
    }

    // this.loadDropdown();

    this.dashboardService.userProfile().subscribe((response) => {
      if (localStorage.getItem('roleName') !== 'admin') {
        this.seletedCSRValue = response.userId ?? defaultGuid;
        this.seletedBranchValue = response.organizationUnitId ?? defaultGuid;
      }
    });
    // this.bindTreeData();

    // this.patientForm = this.formBuilder.group({
    //   patientName: ['Tristan, Jesus M Tristan, Jesus MTristan, Jesus MTristan, Jesus MTristan, Jesus MTristan, Jesus MTristan, Jesus M Tristan, Jesus M'],
    //   address: ['2013 VARSITY DR'],
    //   chartNo: ['00003505TX'],
    //   dob: ['1967-05-27'],
    //   city: ['GRAND PRAIRIE'],
    //   state: ['TX'],
    //   zip: ['75051-4272'],
    //   homeNo: ['214-676-1676'],
    //   cell: ['214-676-1676'],
    //   workNo: [''],
    //   email: ['jesusmtristan@gmail.com'],
    // });

    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.activeTabId = response.get('activeTab') ?? 'Faxes';
        this.isreminder = this.activeTabId;
        if (this.activeTabId == 'Reminder') {
          this.isTabclick = 'checked';
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message || 'Server Unreachable',
        });
      }
    );
    this.subscription$.push(activatedRoute);
  }
  ngOnDestroy(): void {
    this.myTree$?.unsubscribe();
    // this.branchListAPI$?.unsubscribe();
    this.userListAPI$?.unsubscribe();
    this.tableData$?.unsubscribe();

    if (this.getCSROrderStatDetailsAPICall) {
      this.getCSROrderStatDetailsAPICall.unsubscribe();
    }

    if (this.getCsr3MonthsStatisticsDetailsAPICall) {
      this.getCsr3MonthsStatisticsDetailsAPICall.unsubscribe();
    }
     if (this.getCsrWiseCommissionDetailsAPICall) {
      this.getCsrWiseCommissionDetailsAPICall.unsubscribe();
    }

    if (this.getOrderExchangeListDetailsAPICall) {
      this.getOrderExchangeListDetailsAPICall.unsubscribe();
    }

    if (this.getOverrideListDetailsAPICall) {
      this.getOverrideListDetailsAPICall.unsubscribe();
    }



  }

  ngAfterViewInit() {
    this.counterLabels.forEach((label, index) => {
      // Parse the label text to get the max count
      const maxCount = parseInt(label.nativeElement.innerText, 10);
      this.incrementCounter(0, index, maxCount);
    });
    this.setFaxTableInputs();
  }
  getDashboardData() {
    this.getCSROrderStatDetails();
  }
  // isgetdashboardDetails: boolean = false;

  private getCSROrderStatDetailsAPICall: Subscription;
  isgetCSROrderStatDetails: boolean = true;
  // isgetCSROrderStatDetails: boolean = false;
  csrOrderDetails: DashboardService_CsrHurdleStat;

  getCSROrderStatDetails() {
    if (this.getOverrideListDetailsAPICall) {
      this.getOverrideListDetailsAPICall.unsubscribe();
    }
    this.isgetOverrideListDetails = false;
    if (this.getOrderExchangeListDetailsAPICall) {
      this.getOrderExchangeListDetailsAPICall.unsubscribe();
    }
    this.isgetOrderExchangeListDetails = false;
    if (this.getCsrWiseCommissionDetailsAPICall) {
      this.getCsrWiseCommissionDetailsAPICall.unsubscribe();
    }
    this.isgetCsrWiseCommissionDetails = false;
    if (this.getCsr3MonthsStatisticsDetailsAPICall) {
      this.getCsr3MonthsStatisticsDetailsAPICall.unsubscribe();
    }
    this.isgetCsr3MonthsStatisticsDetails = false;

    this.isgetCSROrderStatDetails = true;
    // this.loaderService.showLoader();
    const formValue = this.fmAdminDashboard.value;

    let gCSR: string = formValue?.drpCSR,
      gLocation: string = formValue?.drpBranchType,
      iMonth: number=this.selectedMonth,
      iYear: number=this.selectedYear;
    try {
      if (this.getCSROrderStatDetailsAPICall) {
        this.getCSROrderStatDetailsAPICall.unsubscribe();
      }
      this.getCSROrderStatDetailsAPICall = this.orderDashboardService
        .getCsrOrderStatByGCSRAndGLocationAndIMonthAndIYear(
          gCSR,
          gLocation,
          iMonth,
          iYear
        )
        .subscribe(
          (response) => {
            this.csrOrderDetails = response || null;
            // this.getCsr3MonthsStatisticsDetails();
            this.isgetCSROrderStatDetails = false;
          },
          (error) => {
            const data: HttpErrorResponse = error;
            const errorMessage = data?.error;
            // this.toastr.error(errorMessage?.error?.message, 'Error');
            // this.getCsr3MonthsStatisticsDetails();
            this.isgetCSROrderStatDetails = false;
            this.csrOrderDetails=null;
          }
        );
    } catch (error) {
      // this.toastr.error('Get API Failed', 'Error');
      this.csrOrderDetails=null;
      // this.getCsr3MonthsStatisticsDetails();
      this.isgetCSROrderStatDetails = false;
      console.error('Unexpected error:', error);

    } finally {
    }
  }

  private getCsr3MonthsStatisticsDetailsAPICall: Subscription;
  isgetCsr3MonthsStatisticsDetails: boolean = false;
  Csr3MonthStatisticsDetails: DashboardService_Csr3MonthStatistics[] = [];

  getCsr3MonthsStatisticsDetails() {
    if (this.getOverrideListDetailsAPICall) {
      this.getOverrideListDetailsAPICall.unsubscribe();
    }
    this.isgetOverrideListDetails = false;
    if (this.getOrderExchangeListDetailsAPICall) {
      this.getOrderExchangeListDetailsAPICall.unsubscribe();
    }
    this.isgetOrderExchangeListDetails = false;
    if (this.getCsrWiseCommissionDetailsAPICall) {
      this.getCsrWiseCommissionDetailsAPICall.unsubscribe();
    }
    this.isgetCsrWiseCommissionDetails = false;


    this.isgetCsr3MonthsStatisticsDetails = true;
    // this.loaderService.showLoader();
    const formValue = this.fmAdminDashboard.value;

    let gCSR: string = formValue?.drpCSR,
      gLocation: string = formValue?.drpBranchType,
      iMonth: number=this.selectedMonth,
      iYear: number=this.selectedYear;
    try {
      if (this.getCsr3MonthsStatisticsDetailsAPICall) {
        this.getCsr3MonthsStatisticsDetailsAPICall.unsubscribe();
      }
      this.getCsr3MonthsStatisticsDetailsAPICall = this.orderDashboardService
        .getCsr3MonthsStatisticsListByGCSRAndGLocationAndIMonthAndIYear(
          gCSR,
          gLocation,
          iMonth,
          iYear
        )
        .subscribe(
          (response) => {
            this.Csr3MonthStatisticsDetails = response || [];
            // this.loaderService.hideLoader();
            this.getCsrWiseCommissionDetails();
            this.isgetCsr3MonthsStatisticsDetails = false;
          },
          (error) => {
            const data: HttpErrorResponse = error;
            const errorMessage = data?.error;
            // this.toastr.error(errorMessage?.error?.message, 'Error');
            // this.loaderService.hideLoader();
            this.getCsrWiseCommissionDetails();
            this.Csr3MonthStatisticsDetails = [];
            this.isgetCsr3MonthsStatisticsDetails = false;
          },
        );
    } catch (error) {
      // this.toastr.error('Get API Failed', 'Error');
      // this.reloadTable.emit('Mani');
      this.Csr3MonthStatisticsDetails = [];
      console.error('Unexpected error:', error);
      // this.loaderService.hideLoader();
      this.getCsrWiseCommissionDetails();
    } finally {
    }
  }


  private getCsrWiseCommissionDetailsAPICall: Subscription;
  isgetCsrWiseCommissionDetails: boolean = false;
  CsrWiseCommissionDetails: DashboardService_CsrCommission[] = [];

  getCsrWiseCommissionDetails() {

    if (this.getOverrideListDetailsAPICall) {
      this.getOverrideListDetailsAPICall.unsubscribe();
    }
    this.isgetOverrideListDetails = false;
    if (this.getOrderExchangeListDetailsAPICall) {
      this.getOrderExchangeListDetailsAPICall.unsubscribe();
    }
    this.isgetOrderExchangeListDetails = false;

    this.isgetCsrWiseCommissionDetails = true;
    // this.loaderService.showLoader();
    const formValue = this.fmAdminDashboard.value;

    let gCSR: string = formValue?.drpCSR,
      gLocation: string = formValue?.drpBranchType,
      iMonth: number=this.selectedMonth,
      iYear: number=this.selectedYear;
    try {
      if (this.getCsrWiseCommissionDetailsAPICall) {
        this.getCsrWiseCommissionDetailsAPICall.unsubscribe();
      }
      this.getCsrWiseCommissionDetailsAPICall = this.orderDashboardService
        .getCsrWiseCommissionListByGCSRAndGLocationAndIMonthAndIYear(
          gCSR,
          gLocation,
          iMonth,
          iYear
        )
        .subscribe(
          (response) => {

            this.CsrWiseCommissionDetails = response || [];
            // this.loaderService.hideLoader();
            this.getOrderExchangeListDetails();
            this.isgetCsrWiseCommissionDetails = false;
          },
          (error) => {
            const data: HttpErrorResponse = error;
            const errorMessage = data?.error;
            // this.toastr.error(errorMessage?.error?.message, 'Error');
            // this.loaderService.hideLoader();
            this.getOrderExchangeListDetails();
            this.CsrWiseCommissionDetails = [];
            this.isgetCsrWiseCommissionDetails = false;
          }
        );
    } catch (error) {
      // this.toastr.error('Get API Failed', 'Error');
      // this.reloadTable.emit('Mani');
      this.CsrWiseCommissionDetails = [];
      console.error('Unexpected error:', error);
      // this.loaderService.hideLoader();
      this.getOrderExchangeListDetails();
    } finally {
    }
  }


  private getOrderExchangeListDetailsAPICall: Subscription;
  isgetOrderExchangeListDetails: boolean = false;
  DashboardExchangesDetails: DashboardService_DashboardExchanges[] = [];

  getOrderExchangeListDetails() {

    if (this.getOverrideListDetailsAPICall) {
      this.getOverrideListDetailsAPICall.unsubscribe();
    }
    this.isgetOverrideListDetails = false;

    this.isgetOrderExchangeListDetails = true;
    // this.loaderService.showLoader();
    const formValue = this.fmAdminDashboard.value;

    let gCSR: string = formValue?.drpCSR,
      gLocation: string = formValue?.drpBranchType,
      iMonth: number=this.selectedMonth,
      iYear: number=this.selectedYear;
    try {
      if (this.getOrderExchangeListDetailsAPICall) {
        this.getOrderExchangeListDetailsAPICall.unsubscribe();
      }
      this.getOrderExchangeListDetailsAPICall = this.orderDashboardService
        .getOrderExchangeListByGCSRAndGLocationAndIMonthAndIYear(
          gCSR,
          gLocation,
          iMonth,
          iYear
        )
        .subscribe(
          (response) => {

            this.DashboardExchangesDetails = response || [];
            // this.loaderService.hideLoader();
            this.getOverrideListDetails();
            this.isgetOrderExchangeListDetails = false;
          },
          (error) => {
            const data: HttpErrorResponse = error;
            const errorMessage = data?.error;
            // this.toastr.error(errorMessage?.error?.message, 'Error');
            // this.loaderService.hideLoader();
            this.getOverrideListDetails();
            this.DashboardExchangesDetails = [];
            this.isgetOrderExchangeListDetails = false;
          }
        );
    } catch (error) {
      // this.toastr.error('Get API Failed', 'Error');
      // this.reloadTable.emit('Mani');
      this.DashboardExchangesDetails = [];
      console.error('Unexpected error:', error);
      // this.loaderService.hideLoader();
      this.getOverrideListDetails();
    } finally {
    }
  }

  private getOverrideListDetailsAPICall: Subscription;
  isgetOverrideListDetails: boolean = false;
  OverrideDetails: OverrideListDTO[] = [];

  getOverrideListDetails() {
    this.isgetOverrideListDetails = true;
    // this.loaderService.showLoader();
    const formValue = this.fmAdminDashboard.value;

    let gCSR: string = formValue?.drpCSR,
      gLocation: string = formValue?.drpBranchType,
      iMonth: number=this.selectedMonth,
      iYear: number=this.selectedYear;
    try {
      if (this.getOverrideListDetailsAPICall) {
        this.getOverrideListDetailsAPICall.unsubscribe();
      }
      this.getOverrideListDetailsAPICall = this.orderDashboardService
        .getOrderOverrideListByGCSRAndGLocationAndIMonthAndIYear(
          gCSR,
          gLocation,
          iMonth,
          iYear
        )
        .subscribe(
          (response) => {

            this.OverrideDetails = response || [];

            this.OverrideDetails = this.OverrideDetails.map(
              (data) => {
                return {
                  ...data,
                  orderedDate: this.commonService.getFormattedDateZone(
                    data.orderedDate
                  ),
                  overrideDate: this.commonService.getFormattedDateZone(
                    data.overrideDate
                  ),
                };
              }
            );


            // this.loaderService.hideLoader();
            this.isgetOverrideListDetails = false;
          },
          (error) => {
            const data: HttpErrorResponse = error;
            const errorMessage = data?.error;
            // this.toastr.error(errorMessage?.error?.message, 'Error');
            // this.loaderService.hideLoader();
            this.OverrideDetails = [];
            this.isgetOverrideListDetails = false;
          },
        );
    } catch (error) {
      // this.toastr.error('Get API Failed', 'Error');
      // this.reloadTable.emit('Mani');
      this.OverrideDetails = [];
      console.error('Unexpected error:', error);
      // this.loaderService.hideLoader();
    } finally {
    }
  }

    //Approve Disapprove Method
    approveDisapproveOverride(orderId: string, ticketId: string, status: boolean) {
      status && this.approveDisapproveApiCall(orderId, status, '');
      // !status && this.openNotes(orderId, ticketId);
      !status && this.notesReason(orderId, ticketId);
    }

    isApprovedDisabled=false;
      //To Approve & Disapprove API Call
  approveDisapproveApiCall(orderId: string, status: boolean, comments: string) {
    this.isApprovedDisabled=true;
    this.mmOrderService
      .updateOverrideStatusV1ByGOrderIdAndStatusAndSComments(
        orderId,
        status,
        comments
      )
      .subscribe(
        (response) => {
          status
            ? this.toastr.success('Override Approved!')
            : this.toastr.success('Disapproved!');

          this.communicationService.triggerOverriddenOrdersList();
          this.isApprovedDisabled=false;
          this.getOverrideListDetails();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          this.toastr.error(data?.error?.error?.message);
          this.isApprovedDisabled=false;
          this.getOverrideListDetails();
        }
      );
  }

  isdisApprovedDisabled=false;
  notesReason(orderId: string, ticketId: string) {
    this.isdisApprovedDisabled=true;
    const dialogRef = this.matDialog.open(NotesReasonComponent, {
      minHeight: '33vh',
      minWidth: '52vw',
      disableClose: true,
      data: {
        patientId: defaultGuid , // this.shippingListDetails.patientId,
        ticketId: ticketId, //  this.shippingListDetails.mmRefereceOrderId,
        notesType: NotesType.orderRelated,
        orderId: orderId,
        type: NotesType.orderRelated,
      },
    });
    dialogRef.afterClosed().subscribe((a) => {
      this.isdisApprovedDisabled=false;
      this.getOverrideListDetails();
    });
  }






  openFaxFinderModal() {
    const config: MatDialogConfig = {
      disableClose: true,
      // minHeight: '30vh',
      // minWidth: '50vw',
      // maxWidth: '80vw',
    };
    const dialogRef = this.matDialog.open(FaxFinderComponent, config);

    const closeDialogFF = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message || 'Server Unreachable',
        });
      }
    );
    this.subscription$.push(closeDialogFF);
  }

  openFaxFinderModalLazyLoading() {
    const config: MatDialogConfig = {
      disableClose: true,
      // minHeight: '30vh',
      // minWidth: '50vw',
      // maxWidth: '80vw',
    };
    const dialogRef = this.matDialog.open(
      FaxFinderLazyLoadingComponent,
      config
    );

    const closeDialogFF = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message || 'Server Unreachable',
        });
      }
    );
    this.subscription$.push(closeDialogFF);
  }

  openFaxFinderModalLazyLoadingV1() {
    const config: MatDialogConfig = {
      disableClose: true,
      // minHeight: '30vh',
      // minWidth: '50vw',
      // maxWidth: '80vw',
    };
    const dialogRef = this.matDialog.open(
      FaxFinderLazyLoadingV1Component,
      config
    );

    const closeDialogFF = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message || 'Server Unreachable',
        });
      }
    );
    this.subscription$.push(closeDialogFF);
  }

  getTenantId(): Promise<string> {
    return new Promise((resolve, reject) => {
      const tenantId = localStorage.getItem('tenantId') ?? '';

      if (tenantId !== '') {
        resolve(tenantId);
      } else {
        reject('TenantId not found in localStorage');
      }
    });
  }

  retrieveAndSetTenantId(): Promise<void> {
    return new Promise((resolve, reject) => {
      const tenantId = localStorage.getItem('tenantId') ?? '';

      if (tenantId !== '') {
        this.tenantId = tenantId;
        resolve();
      } else {
        // If tenantId is empty, retry after a short delay
        setTimeout(() => {
          this.retrieveAndSetTenantId().then(resolve).catch(reject);
        }, 1000); // Adjust the delay as needed
      }
    });
  }

  addFaxDocuments() {
    const dialogRef = this.matDialog.open(AddFaxDocumentsComponent, {
      minHeight: '33vh',
      minWidth: '52vw',
      data: {
        selectedNode: this.selectedNode ?? null,
      },
      disableClose: true,
    });
  }
  setFaxTableInputs() {
    // this.loadChildComponent = false;
    this.tableInput = {
      user: this.seletedCSRValue === defaultGuid ? [] : [this.seletedCSRValue],
      branch:
        this.seletedBranchValue === defaultGuid
          ? []
          : [this.seletedBranchValue],
      statusCode: this.selectedShortCodeId, //selectedInboundStatusId,
      month: null, //this.selectedMonth,
      year: null, //this.selectedYear,
    };
    if (this.selectedPageType != 'Default') {
      this.loadTable(this.tableInput);
    }
    if (
      this.tableInput.statusCode == '001' &&
      this.selectedPageType == 'Default'
    ) {
      this.isMyFaxBtn = true; 
      this.getFaxFileterList();
    } else {
      this.isMyFaxBtn = false;
    }
    // console.log(this.selectedNode)
  }

  changePage(pageNo : number){
   
    if( pageNo != this.currentPage && pageNo > 0 && pageNo <= this.totalPages)
    {
      this.currentPage = pageNo; 
      this.setFaxTableInputs();
    }
  }

  fnGetVisibleColumns (columns:number[]){
    this.visibleColumns = columns;
  }

  fnSearchClick (){
    this.currentPage = 1;
    this.setFaxTableInputs();
  }
  //To Load the Inbound Table Data
  loadTable(inputData: any) {
    this.loadChildComponent = false;
    this.tableData$?.unsubscribe();
    if (this.getInboundTableData) {
      this.getInboundTableData.unsubscribe();
    }
    this.getInboundTableData =
      this.InboundDocService.getInboundFaxDocsByUserAndBranchAndStatusCodeAndMonthAndYear(
        inputData.user,
        inputData.branch,
        inputData.statusCode, // inboundStatus,
        inputData.month,
        inputData.year,
        this.currentPage,
        this.pageOffset
      ).subscribe(
        (response) => {
          this.inboundTableData = response.items;
          this.totalPages = Math.ceil(response.totalCount / this.pageOffset);
          this.inboundTableData = this.inboundTableData.map((inbound) => {
            return {
              ...inbound,
              uploadedDate: this.commonService.getFormattedDateTimeZone(
                inbound.uploadedDate
              ),
              lastModificationTime: this.commonService.getFormattedDateTimeZone(
                inbound.lastModificationTime
              ),
              scheduledOn: this.commonService.getFormattedDateTimeZone(
                inbound.scheduledOn
              ),
            };
          });
          // Manually trigger change detection
          this.cdr.detectChanges();
          this.loadChildComponent = true;
        },
        (err) => {
          // Manually trigger change detection
          this.cdr.detectChanges();
          this.loadChildComponent = true;
          this.inboundTableData = [];
        }
      );
    this.tableData$ = this.getInboundTableData;
  }
  openDatePicker() {
    this.txtDateValue.open();
  }

  incrementCounter(currentCount: number, index: number, maxCount: number) {
    if (currentCount < maxCount) {
      setTimeout(() => {
        this.counterLabels.toArray()[index].nativeElement.innerText = (
          currentCount + 1
        ).toString();
        this.incrementCounter(currentCount + 1, index, maxCount);
      }, 10); // Adjust the delay as needed
    }
  }
  openAddNotesPopUp() {
    const config: MatDialogConfig = {
      disableClose: true,
      minHeight: '30vh',
      minWidth: '50vw',
      data: {
        claimId: '00000000-0000-0000-0000-000000000000',
        noteType: 'Order Note',
        notesId: '00000000-0000-0000-0000-000000000000',
        orderId: '0b50be04-6c75-92d3-97c6-3a0e17ee6a18',
        patientId: '15ebf292-a587-e51d-447c-3a0e17705a36',
      },
    };
    const dialogRef = this.matDialog.open(AddNoteTabComponent, config);

    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        // this.getReminderTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message || 'Server Unreachable',
        });
      }
    );
    this.subscription$.push(closeDialog);
  }

  viewdtaes(date: string, index: number) {
    this.selectedDateIndex = index;
  }

  displayDates() {
    // Calculate the end index for the current page.
    const endIndex = this.currentIndex + this.pageSize;
    this.displayedDates = this.dates.slice(this.currentIndex, endIndex);
    this.displayedDates.reverse();
  }

  nextPage() {
    // Move to the next page of dates if available.
    if (this.currentIndex + this.pageSize < this.dates.length) {
      this.currentIndex += this.pageSize;
      this.displayDates();
    }
  }

  prevPage() {
    // Move to the previous page of dates if available.
    if (this.currentIndex > 0) {
      this.currentIndex -= this.pageSize;
      this.displayDates();
    }
  }

  changeTab(tab: string) {
    this.isTabclick = 'checked';

    this.isreminder = tab;
    this.isFax = tab;
  }

  allBranchSelect() {
    if (this.selectAllBranch.selected) {
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }

  loadDropdownBranch() {
    const getBranchList = this.branchService
      .getBranchList(this.tenantId)
      .subscribe((response) => {
        this.lstBranch = response;
        this.changeLocation = response;
        const allBranchOption: OrganizationUnitDTO = {
          id: '00000000-0000-0000-0000-000000000000',
          organizationUnitName: 'All',
        };

        this.lstBranch = [allBranchOption, ...this.lstBranch];
        this.filteredBranchTypeList = this.lstBranch;
        this.branchFilterControl.valueChanges.subscribe((branchFilterText) => {
          branchFilterText = branchFilterText.toLowerCase();
          this.filteredBranchTypeList = this.lstBranch.filter((dx) =>
            dx.organizationUnitName.toLowerCase().includes(branchFilterText)
          );
        });
      });
    // this.userListAPI$=getBranchList;
  }

  loadDropdownUser(search: string) {
    this.userListAPI$?.unsubscribe();

    const csrUsersList = this.userService.getCSRUsers(search).subscribe(
      (response) => {
        if (response) {
          this.lstUsers = response;

          const allUserOption: UserByRoleDTO = {
            name: 'All',
            userId: '00000000-0000-0000-0000-000000000000',
            roleId: '',
            role: '',
            userDesignation: '',
          };

          this.lstUsers = [allUserOption, ...this.lstUsers];
          this.filteredCSRList = this.lstUsers;
          this.getDashboardData();

          // this.CSRFilterControl.valueChanges.subscribe((dxTypeFilterText) => {
          //   dxTypeFilterText = dxTypeFilterText.toLowerCase();
          //   this.filteredCSRList = this.lstUsers.filter((dx) =>
          //     dx.name.toLowerCase().includes(dxTypeFilterText)
          //   );
          // });
        } else {
          const allUserOption: UserByRoleDTO = {
            name: 'All',
            userId: '00000000-0000-0000-0000-000000000000',
            roleId: '',
            role: '',
            userDesignation: '',
          };

          this.lstUsers = [allUserOption];
          this.filteredCSRList = this.lstUsers;
          this.getDashboardData();
        }
      },
      (error) => {
        if (error.status === 204) {
          // Handle the case when the response is 204 (No Content)
          const allUserOption: UserByRoleDTO = {
            name: 'All',
            userId: '00000000-0000-0000-0000-000000000000',
            roleId: '',
            role: '',
            userDesignation: '',
          };

          this.lstUsers = [allUserOption];
          this.filteredCSRList = this.lstUsers;
          this.getDashboardData();
        } else {
          // Handle other error scenarios
          // console.error('Error loading CSR users:', error);
        }
      }
    );

    this.userListAPI$ = csrUsersList;
  }
  UserSearch(value) {
    this.loadDropdownUser(value);
  }
  clearUser(event: Event) {
    this.fmAdminDashboard.patchValue({
      drpCSR: '',
    });
    this.UserSearch('');
  }
  // chosenMonthHandler(normalizedMonth: any, datepicker: any) {
  //   this.dateValue =
  //     normalizedMonth.toLocaleString('default', { month: 'short' }) +
  //     '/' +
  //     normalizedMonth.getFullYear();
  //   this.selectedDate =
  //     normalizedMonth.getMonth() + 1 + '/' + normalizedMonth.getFullYear();
  //   this.selectedMonth = normalizedMonth.getMonth() + 1;
  //   this.selectedYear = normalizedMonth.getFullYear();
  //   this.getDashboardData();
  //   datepicker.close();
  // }

  chosenMonthHandler(normalizedMonth: any, datepicker: any) {
    const year = normalizedMonth.getFullYear();
    const month = normalizedMonth.getMonth() + 1;

    // Format the dateValue as 'MMM/YYYY'
    this.dateValue = normalizedMonth.toLocaleString('default', { month: 'short' }) + '/' + year;

    // Set the selectedDate to the first day of the selected month
    this.selectedDate = new Date(year, month - 1, 1);

    this.selectedMonth = month;
    this.selectedYear = year;

    this.getDashboardData();
    datepicker.close();
  }
  displayTreeValue(node) {
    this.treeDataValue.forEach((treeData) => {
      let treeValue: TreeDTO;
      let subTreeValue: TreeDTO[] = [];
      let branchName = treeData.organizationUnitName;
      let branchId = treeData.branchId;
      let branchTotalCount = treeData.average ?? 0;

      if (treeData.inboundStatus.length > 0) {
        treeData.inboundStatus.forEach((subTreeData) => {
          let secondSubTreeValue: TreeDTO[] = [];

          if (subTreeData.lstSubStatus != null) {
            subTreeData.lstSubStatus.forEach((secondSubTreeData) => {
              secondSubTreeValue.push(
                new TreeDTO(
                  treeData.branchId,
                  secondSubTreeData.inboundStatus,
                  secondSubTreeData.id,
                  secondSubTreeData.fileCount,
                  false,
                  subTreeData.id,
                  secondSubTreeData.shortCodeId
                )
              );
            });
          }
          subTreeValue.push(
            new TreeDTO(
              treeData.branchId,
              subTreeData.inboundStatus,
              subTreeData.id,
              subTreeData.fileCount,
              false,
              null,
              subTreeData.shortCodeId,
              secondSubTreeValue
            )
          );
        });
      }
      treeValue = new TreeDTO(
        branchId,
        branchName,
        branchId,
        branchTotalCount,
        true,
        null,
        '',
        subTreeValue
      );
      this.treeData.push(treeValue);
    });

    this.treeControl = new NestedTreeControl<TreeDTO>(this.getChildren);
    this.dataSource = new MatTreeNestedDataSource();
    this.dataSource.data = this.treeData;
    // this.dataSource.data=[];
    if (node != null) {
      let expandNode = this.dataSource.data.find(
        (a) => a.branchId === node.branchId
      );
      this.treeControl.expand(expandNode);
      if (node.subMenuId != null) {
        let expandNode2 = expandNode.children.value.find(
          (a) => a.id === node.subMenuId
        );
        this.treeControl.expand(expandNode2);
      }
      this.selectedNode = node;
    }
    //  else {
    //   this.getTreeData(1)
    // }
  }
  //To bind the Location Tree Data
  getTreeData(node?: any) {
    this.myTree$?.unsubscribe();
    this.isTreeLoad = false;
    // const treeData = this.branchService.getBranchWithStatus().subscribe(
    const treeData = this.branchService.getBranchWithStatusV2().subscribe(
      (response) => {
        this.treeData = [];
        this.totalFaxCount = response.allFilesCount;
        this.treeDataValue = response.lstStatus;

        this.isTreeLoad = true;
        this.displayTreeValue(node);
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message || 'Server Unreachable',
        });
        this.isTreeLoad = true;
        this.totalFaxCount = 0;
      }
    );
    this.isTreeLoad = true;
    // this.tableData$ = treeData;
    this.myTree$ = treeData;
    // this.subscription$.push(treeData);
  }

  getChildren = (node: TreeDTO) => {
    return node.children;
  };

  hasChildren = (index: number, node: TreeDTO) => {
    return node.children.value.length > 0;
  };

  myExpand(node: any) {
    // if (!this.treeControl.isExpanded(node)) {
    //   this.treeControl.collapseAll();
    // }
    this.treeControl.toggle(node);
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  reloadDashboard(node: any, pageType: any, isBranch: boolean, event: Event) {
    this.selectedPageType = pageType;
    this.changeTab('Faxes');
    this.activeTabId = 'Faxes';
    this.isTabclick = 'Unchecked';
    this.selectedShortCodeId =
      pageType == 'Default' ? '001' : node.statusShortCodeId;
    this.selectedInboundStatusId =
      pageType == 'Default' ? defaultGuid : node.id;
    this.seletedBranchValue =
      pageType == 'Default' ? defaultGuid : node?.branchId;
    if (!isBranch || pageType == 'Default') {
      // this.loadChildComponent = false;
      this.strPageType = pageType;
      this.currentPage = 1;
      this.setFaxTableInputs();
      this.communicationService.triggerFunctionCall(node);
    }
    if(this.strPageType === 'Default'  && this.isTabclick !== 'checked'){
      this.getDashboardData();
    }
    // Store the selected node for future reference
    this.selectedNode = node;
  }

  onSelectedOptionChage(){
    this.currentPage = 1;
    this.getFaxFileterList();
  }

  getFaxFileterList() {
    this.loadChildComponent = false;
    if (this.getInboundTableData) {
      this.getInboundTableData.unsubscribe();
    }
    this.getInboundTableData = this.InboundDocService.getMyFaxDocsByFilter(
      this.selectedOption1, this.currentPage, this.pageOffset
    ).subscribe(
      (response) => {
        this.inboundTableData = response.items;
        this.totalPages = Math.ceil(response.totalCount / this.pageOffset);
        this.inboundTableData = this.inboundTableData.map((inbound) => {
          return {
            ...inbound,
            uploadedDate: this.commonService.getFormattedDateTimeZone(
              inbound.uploadedDate
            ),
            lastModificationTime: this.commonService.getFormattedDateTimeZone(
              inbound.lastModificationTime
            ),
            completedDate: this.commonService.getFormattedDateTimeZone(
              inbound.completedDate
            ),
            scheduledOn: this.commonService.getFormattedDateTimeZone(
              inbound.scheduledOn
            ),
          };
        });
        this.loadChildComponent = true;
      },
      (err) => {
        this.loadChildComponent = false;
      }
    );
  }
  selectNode(node?: any) {
    this.selectedNode = null;
    this.getTreeData(node?.node);
  }

  activateReminderTab(): void {
    this.changeTab('Reminder');
    this.activeTabId = 'Reminder';
    this.router.navigate(['/dashboard/' + 'Reminder']);
  }
  //Order Work Sheet Modal
  orderworksheet() {
    this.matDialog.open(AppOrderWorksheetMdlComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {},
    });
    this.matDialog.afterAllClosed.subscribe((resposne) => {});
  }
}

export interface Branch {
  id?: string;
  organizationUnitName?: string;
}
/**
 * Node for Inbound Status
 */
export class TreeDTO {
  children: BehaviorSubject<TreeDTO[]>;
  constructor(
    public branchId: string,
    public item: string,
    public id: string,
    public count: number,
    public isBranch: boolean,
    public subMenuId: string,
    public statusShortCodeId?: string,
    children?: TreeDTO[],
    public parent?: TreeDTO
  ) {
    this.children = new BehaviorSubject(children === undefined ? [] : children);
  }
}
