import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PostingNotificationService } from 'projects/billing/src/app/billing-proxy/billing-management/posting/posting-notification.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { defer, from } from 'rxjs';
import { concatMap, groupBy, mergeMap, takeLast, toArray } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { DashboardService } from '../admin-proxy/platform-app-management/rcm/platform-management/dashboard';
import { ProductNotificationDTO } from '../warehouse-proxy/rcm/warehouse-management/warehouse/dto/models';

@Component({
  selector: 'app-claim-notification',
  templateUrl: './claim-notification.component.html',
  styleUrls: ['./claim-notification.component.scss']
})
export class ClaimNotificationComponent implements OnInit {
  userId: string = "";
  loginUserName: string = "";
  notificationList: any[] = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userId: string },
    private dashboardService: DashboardService,
    private table: TableService,
    private claimNotifService: PostingNotificationService
  ) { }
  //! page initialization function
  ngOnInit(): void {
    // this.loadUserProfile();
   this.getNotifyList()
  }
  //! Get Product Notifications List
  getNotifyList() {

    this.claimNotifService.getNotificationByUserId(this.data.userId).subscribe(response => {
      this.notificationList = [];

      let notifyList: any[] = response?.items ?? [];
      notifyList?.forEach(element => {
        let newDate = new Date(element?.completedOn);
        if(newDate > new Date()){
          this.notificationList?.push(element);
        }
      });

    }, err => {
      this.notificationList = [];

      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  //!Mark  Notification by ID as Read
  dismissNotifyById(value: ProductNotificationDTO) {
    value.isDismissed = true;
    const input: ProductNotificationDTO = value;
    // if (input?.id && input) {
    //   this.prodNotifService.update(input?.id, input).subscribe(response => {
    //     this.getNotifyList();
    //   })
    // }
  }
  //!Mark  Notification by ID as Read
  readNotifyById(value: ProductNotificationDTO) {
    value.readStatus = true;
    const input: ProductNotificationDTO = value;
    // if (input?.id && input) {
    //   this.prodNotifService.update(input?.id, input).subscribe(response => {
    //     this.getNotifyList();
    //   })
    // }
  }
  //!Mark All Notifications as Read
  markAllread() {
    const lstNonReadNotify: ProductNotificationDTO[] = this.notificationList.filter(e => { return e && e?.readStatus === false });
    let arryNonReadNotify: ProductNotificationDTO[] = [];
    lstNonReadNotify?.forEach(e => {
      let data = e;
      data.readStatus = true;
      arryNonReadNotify?.push(data)
    });
    // setTimeout(() => {
    //   from(arryNonReadNotify).pipe(
    //     groupBy(v => v.id),
    //     mergeMap(group => group.pipe(toArray())),
    //     mergeMap(group => from(group)
    //       .pipe(
    //         concatMap((v) => defer(() => this.prodNotifService.update(v?.id, v))))),
    //     takeLast(1)).subscribe(response => {


    //       this.getNotifyList();
    //     })
    // }, 50);

  }
  //!Mark All Notifications as Dismissed
  markAllDismissed() {
    const lstNonReadNotify: ProductNotificationDTO[] = this.notificationList.filter(e => { return e && e?.isDismissed === false });
    let arryNonReadNotify: ProductNotificationDTO[] = [];
    lstNonReadNotify?.forEach(e => {
      let data = e;
      data.isDismissed = true;
      arryNonReadNotify?.push(data)
    });
    // setTimeout(() => {
    //   from(arryNonReadNotify).pipe(
    //     groupBy(v => v.id),
    //     mergeMap(group => group.pipe(toArray())),
    //     mergeMap(group => from(group)
    //       .pipe(
    //         concatMap((v) => defer(() => this.prodNotifService.update(v?.id, v))))),
    //     takeLast(1)).subscribe(response => {


    //       this.getNotifyList();
    //     })
    // }, 50);

  }
}

