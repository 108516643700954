import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  RegionCountriesDTO,
  RegionStatesDTO,
  RegionCitiesDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto';
import { map, startWith, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { RegionDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import emailMask from 'text-mask-addons/dist/emailMask';
import { DataTableDirective } from 'angular-datatables';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientMasterDropdownDTO } from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss'],
})
export class PatientDetailsComponent implements OnInit {
  patientDetailsForm: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  phoneCode: string = '';
  country: string = '';
  drpCountries: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  State: string = '';
  City: string = '';
  drpGenderLoop: any;
  drpSuffixes: any;
  emailMask: any;
  isInsuranceTableLoaded: boolean = true;
  subscription$: Subscription[] = [];
  insuranceList: any[] = [];
  dtInsuranceTableOptions: any = {
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
  };
  constructor(
    private fb: FormBuilder,
    private regionDropdownService: RegionDropdownService,
    private mmOrderService: MmOrderService,
    private patientDropdownService: PatientMasterDropdownService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      chartId: string;
    } = {
      patientId: defaultGuid,
      chartId: '',
    }
  ) {
    this.emailMask = emailMask;
  }

  ngOnInit() {
    this.emailMask = emailMask;
    this.initializeForms();
    this.loadDropdowns();
    this.getCountryDropdown();
    this.loadPatientDetails();
    this.loadInsuranceDetails();
  }
  //After View In it function
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {}
  initializeForms() {
    this.patientDetailsForm = this.fb.group({
      txtChartNo: new FormControl(''),
      txtFirstName: new FormControl(''),
      txtLastName: new FormControl(''),
      txtMiddleName: new FormControl(''),
      drpSuffix: new FormControl(''),
      txtBirthdate: new FormControl(''),
      drpGender: new FormControl(''),
      txtFax: new FormControl(''),
      txtAddress: new FormControl(''),
      txtCountry: new FormControl(''),
      txtCountryFilter: new FormControl(''),
      txtState: new FormControl(''),
      txtStateFilter: new FormControl(''),
      txtCity: new FormControl(''),
      txtCityFilter: new FormControl(''),
      txtPostalCode: new FormControl(''),
      txtHomeNo: new FormControl(''),
      txtWorkNo: new FormControl(''),
      txtMobileNo: new FormControl(''),
      txtEmail: new FormControl(''),
      txtSSN: new FormControl(''),
      drpModifiedBy: new FormControl(''),
      txtModifiedDate: new FormControl(''),
      chkActive: new FormControl(''),
      chkNeverCall: new FormControl(''),
      txtNotes: new FormControl(''),
    });
  }
  loadDropdowns() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.Suffixes,
      PatientDropdowns.Genders,
    ];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          let response: PatientMasterDropdownDTO = stateResponse;

          this.drpSuffixes = response?.suffixes;
          this.drpGenderLoop = response?.genders;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientDropdown);
  }

  //Method to load the Patient Details
  loadPatientDetails() {
    const patientPersonalDetails = this.mmOrderService
      .getPatientDetailsforOrderViewByPatientIdV1(this.data.patientId)
      .subscribe(
        (response) => {
          this.country = response?.country;
          this.State = response?.state;
          (this.State != null || this.State != undefined) &&
            this.onChangeCity(this.State, this.country);

          this.patientDetailsForm.patchValue({
            txtChartNo: response?.chartNo,
            txtFirstName: response?.firstName,
            txtLastName: response?.lastName,
            txtMiddleName: response?.middleName,
            drpSuffix: response?.suffix,
            txtBirthdate: response?.dateOfBirth,
            drpGender: response?.gender,
            txtFax: response?.faxNumber,
            txtAddress: response?.address,
            txtCountry: response?.country,
            txtState: response?.state,
            txtCity: response?.city,
            txtPostalCode: response?.postalCode,
            txtHomeNo: response?.homephone,
            txtWorkNo: response?.workphone,
            txtMobileNo: response?.mobile,
            txtEmail: response?.emailId,
            txtSSN: response?.ssn,
            drpModifiedBy: response?.modifiedBy,
            txtModifiedDate: response?.modifiedOn,
            chkActive: response?.isActive,
            chkNeverCall: response?.isNeverCall,
            txtNotes: response?.notes,
          });
        },
        (err) => {}
      );
    this.subscription$.push(patientPersonalDetails);
  }
  //Method to load the insurance details
  loadInsuranceDetails() {
    this.isInsuranceTableLoaded = false;
    const insuranceDetails = this.mmOrderService
      .getActivePolicyByPatientIdByPatientId(this.data.patientId)
      .subscribe(
        (response) => {
          this.isInsuranceTableLoaded = true;
          this.insuranceList = response;
        },
        (err) => {
          this.isInsuranceTableLoaded = true;
        }
      );
    this.subscription$.push(insuranceDetails);
  }
  //Update Character Count for TextArea

  //Country Dropdown
  getCountryDropdown() {
    this.drpCountries = [];
    this.country = defaultCountry.US;
    const getCountrySubscription = this.regionDropdownService
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .pipe(
        map((response) => {
          this.drpCountries = response?.items ?? [];

          this.phoneCode =
            response?.items?.filter(
              (x) => x.countryShortName === defaultCountry.US
            )?.[0]?.countryPhoneCode ?? '';
          return response;
        }),
        tap((x) => {
          this.country = defaultCountry.US;

          this.patientDetailsForm?.patchValue(
            { txtCountry: defaultCountry.US },
            { emitEvent: true }
          );
        }),
        map((x) => {
          this.onChangeState(defaultCountry.US);
        })
      )
      .subscribe(
        (response) => {
          this.filteredCountries = this.patientDetailsForm
            .get('txtCountryFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCountries.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getCountrySubscription);
  }
  //On change state method
  onChangeState(value: string | null | undefined): boolean {
    this.patientDetailsForm.patchValue({
      txtState: null,
      txtCity: null,
    });
    this.drpStates = [];
    this.drpCities = [];
    value !== null &&
      value !== undefined &&
      this.regionDropdownService
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response?.items ?? [];
            this.phoneCode = response?.items[0]?.countryPhoneCode;
            this.filteredStates = this.patientDetailsForm
              ?.get('txtStateFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return this.drpStates.length !== 0;
  }
  //On Change City Dropdown
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.patientDetailsForm.patchValue({
      txtCity: null,
    });

    this.drpCities = [];
    state !== null &&
      state !== undefined &&
      this.regionDropdownService
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response?.items;

            this.filteredCities = this.patientDetailsForm
              .get('txtCityFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return this.drpCities.length !== 0;
  }
  //Clear State dropdown
  clearState() {
    this.State = '';
    this.patientDetailsForm.patchValue({ txtState: '' });
  }
  //Clear City dropdown
  clearCity() {
    this.City = '';
    this.patientDetailsForm.patchValue({ txtCity: '' });
  }
}
