<div class="container-fluid">
<div class="row bg mt-1">
    <div class="col-12 d-flex justify-content-between">
        <h3 class="font-weight-bolder">Fax Id : <span class="app-custom-color font-weight-bolder">{{defaultFaxId ||
                '--'}}</span></h3>
        <h3 class="font-weight-bolder">Patient Name : <span class="app-custom-color font-weight-bolder">{{vpatientName ||'--'}}</span> | Age ({{vpatientAge}})</h3>
        <h3 class="font-weight-bolder">Chart No : <span class="app-custom-color font-weight-bolder">{{chartId ||
                '--'}}</span></h3>

        <h3 class="font-weight-bolder mr-3">Checklist Id : <span
                class="app-custom-color font-weight-bolder">{{uniqueChecklistId
                || '--'}}</span></h3>

        <div class="row">
            <div class="col-12">
                <button class="buttonColor mb-2 p-2 dd-text mr-1" [disabled]="isEmpty(blobName) || isDownloading" mat-raised-button matTooltip="Download Patient Document" [matTooltipDisabled]="isEmpty(blobName)" (click)="patientDocPdfDownload()">
                    <i class="fa fa-download mr-1" aria-hidden="true"></i> <span *ngIf="!isDownloading" class="dd-text ml-1">Document</span> <span *ngIf="isDownloading" class="dd-text ml-1">Downloading</span>
                    <mat-icon *ngIf="isDownloading">
                        <mat-spinner class="spinner-border-sm ml-1" diameter="18"></mat-spinner>
                    </mat-icon>
                </button>
                <button class="buttonColor mb-2 p-2 dd-text mr-1" [disabled]="isEmpty(blobName) || isDownloading" mat-raised-button matTooltip="View Document in PDF" [matTooltipDisabled]="isEmpty(blobName)" (click)="viewPatientBlobPDF()">
                    <i class="fa fa-eye" aria-hidden="true"></i> <span class="dd-text ml-1">View Document in PDF</span>
                </button>
            </div>
        </div>
    </div>
</div>
</div>

<div class="row" style="position: fixed;height:84%;width:100%">
    <div class="col-5" style="overflow-y: scroll;height:100%;">
        <div class="row">
            <div class="formquestion col-12">
                <!-- <div class="row">
                    <div class="col-12 d-flex justify-content-between">
                        <h3 class="font-weight-bolder">Fax Id : <span class="app-custom-color font-weight-bolder">{{defaultFaxId ||
                                '--'}}</span></h3>
                        <h3 class="font-weight-bolder">Patient Name : <span class="app-custom-color font-weight-bolder">{{vpatientName ||
                                '--'}}</span></h3>
                        <h3 class="font-weight-bolder">Chart No : <span class="app-custom-color font-weight-bolder">{{chartId ||
                                '--'}}</span></h3>

                        <h3 class="font-weight-bolder mr-3">Checklist Id : <span
                                class="app-custom-color font-weight-bolder">{{uniqueChecklistId
                                || '--'}}</span></h3>
                    </div>
                </div> -->
                <div class="container-fluid card card-body" style="height:410px;"
                    *ngIf="isPreQuestionaire && !isenableFinalResult">
                    <h2 class="app-custom-color">Add Insurance Checklist</h2>
                    <form [formGroup]="preQuestionaireform">
                        <mat-label> Pre-Questionaire - Please choose a check list type and click
                            Next.</mat-label><br /><br />
                        <div class="d-flex justify-content-center" *ngIf="isTreeLoad">
                            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                        </div>
                        <mat-radio-group class="example-radio-group" formControlName="rbtnReportType">
                            <mat-radio-button class="example-radio-button" *ngFor="let item of checkList"
                                [value]="item">
                                {{item.checklist}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </form>

                    <div class="col-sm-12" style="position:absolute;bottom:10px;">
                        <div class="text-lg-right">
                            <!-- <button mat-button [disabled]="isPreQuestionaire" class="backclr mr-2">
                                Back 1
                            </button> -->
                            <button mat-button class="Nextclr mr-4" (click)="OpenNextSet('notnow',[])"
                                [disabled]="!(!preQuestionaireform.invalid && (preQuestionaireform.dirty ))">Next</button>
                        </div>
                    </div>
                </div>
                <div class="container-fluid card card-body" *ngIf="!isPreQuestionaire && !isenableFinalResult">
                    <h2 class="app-custom-color">{{selectedPrequestionaire}} Questions</h2>
                    <div *ngFor='let item of Questions;let i = index'>
                        <ng-container>
                            <br /><mat-label class="questionlabel">{{item.question}}</mat-label><br /><br />
                            <mat-radio-group>
                                <mat-radio-button class="pr-2" [disabled]="item.isedited"
                                    *ngFor="let choice of item.options"
                                    (click)="!item.isedited && OpenNextSet(choice,item)"
                                    (change)="OpenNextSet(choice,item)" [value]="choice"
                                    [checked]="item.selectedItem === choice">
                                    <span class="wrap-label-text">
                                        {{choice}}
                                    </span>&nbsp;&nbsp;
                                </mat-radio-button>
                            </mat-radio-group>
                        </ng-container>
                    </div>
                    <div class="d-flex justify-content-center" *ngIf="isLoadingQuestion">
                        <!-- *ngIf="dataSource?.data?.length === 0" -->
                        <!-- <mat-spinner></mat-spinner> -->
                        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                    </div>
                    <div class="col-sm-12">
                        <div class="text-lg-right">
                            <button mat-button class="backclr mr-2" (click)="Goback(2)" [disabled]="isLoadingQuestion">
                                {{ back_2_text }}
                            </button>
                            <!-- <button mat-button class="Nextclr mr-2" *ngIf="!isenableFinish">Finish</button> -->
                            <!-- <button mat-button class="Nextclr mr-2" *ngIf="isenableFinish"
                                (click)="OpenResult()" [disabled]="isLoadingQuestion">Next</button> -->
                        </div>
                    </div>
                </div>
                <div *ngIf="isenableFinalResult">
                    <form [formGroup]="FinalForm">
                        <div>
                            <!-- <h2 class="app-custom-color">{{checkListName}}</h2> -->
                            <!-- Patient Details -->
                            <div class="card mb-0 card-body">
                                <div class="row">
                                    <div class="col-8">
                                        <h2 class="app-custom-color">{{checkListName}}</h2>
                                    </div>
                                    <div class="col-4 text-right">
                                        <button mat-button class="Nextclr" (click)="openViewNotesPopup()">View
                                            Notes</button>
                                    </div>

                                    <!-- Verification -->
                                    <mat-form-field class="col-12">
                                        <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpVerification')?.invalid }">Verification<span class="asterisk">*</span></mat-label>
                                        <mat-select formControlName="drpVerification"
                                            (selectionChange)="onVerificationselect()"
                                            [(ngModel)]="selectedVerification" autocomplete="new-drpVerification"
                                            maxlength="40">
                                            <mat-option *ngFor="let verify of verification"
                                                [value]="verify.verificationId">
                                                {{ verify.policyName }} - {{verify.payorLevel}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf=" FinalForm?.get('drpVerification')?.touched && FinalForm?.get('drpVerification')?.errors?.required">
                                            Please select the verification
                                        </mat-error>
                                    </mat-form-field>

                                    <!-- <mat-form-field class="col-5 outlinebox" appearance="none">
                                        <mat-label>Comments</mat-label>
                                        <textarea style="resize:none;margin-bottom: -20px;" [(ngModel)]="AddNotes"
                                            readonly matInput rows="11" autocomplete="new-txtComments" maxlength="5000"
                                            formControlName="txtComments" type="text"></textarea>
                                    </mat-form-field> -->
                                    <!-- Insurance Comments -->
                                    <!-- <mat-form-field class="Inscomments col-12">
                                        <mat-label>Insurance Verification Notes</mat-label>
                                        <textarea style="resize:none;" readonly matInput rows="2"
                                            autocomplete="new-txtInsComments" maxlength="5000"
                                            formControlName="txtInsComments" type="text"></textarea>
                                    </mat-form-field> -->

                                    <mat-form-field class="Inscomments col-12">
                                            <mat-label>Insurance Verification Notes</mat-label>
                                            <textarea class="textareaResize" matInput rows="3"
                                                autocomplete="new-txtInsComments" maxlength="5000"
                                                formControlName="txtInsComments" type="text"></textarea>
                                        </mat-form-field>

                                    <div class="col-12">
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>

                                <!--Dynamic Questions based on prequestionarie-->

                                <br />
                                <!--New setup or Transfer PAP or CPAP to Bilevel-->
                                <div class="row"
                                    *ngIf="checkListName == 'CPAP to BiLevel' || checkListName == 'Transfer PAP' || checkListName == 'New Setup'">
                                    <div class="col-6">
                                        <h2 class="app-custom-color">{{checkListName}}</h2>
                                    </div>
                                    <div class="col-6 text-right mb-1">
                                        <button matTooltip="Click Go To Do Sorting" mat-button class="buttonColor font-size-10 ml-2 p-2 dd-text" (click)="openDoSorting()" *ngIf="!isSorting">
                                          <img class="img-responsive mb-1 mr-1" height="10" src="../assets/patient_list_icons/Do_Sorting.png" /> Do Sorting
                                        </button>
                                    </div>
                                    <div class="col-1">
                                        <!-- class="checkboxpad"  MedicarecheckBoxList.CPAPF2F -->
                                        <mat-checkbox #ChkcpaptoBilevelF2F
                                            (change)="checkBoxEvent($event,imageType.CPAPFaceToFace,cpaptoBilevelF2F)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ cpaptoBilevelF2F.length }}</span></mat-checkbox>
                                        <!-- cpaptoBilevelF2FCount -->

                                    </div>
                                    <div class="col-6">
                                        <div class="col-12">
                                        <mat-label class="custom-font" [ngClass]="{ 'is-invalid': FinalForm?.get('txtdatefacetofaceOSA')?.invalid }"> Face - to - Face clinical evaluation conducted
                                            prior to sleep test to assess the patient for OSA. <span
                                                class="asterisk">*</span></mat-label>
                                        </div>
                                        <mat-form-field class="col-12">
                                            <mat-label>(MM/DD/YYYY) <span class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtdatefacetofaceOSA"
                                                formControlName="txtdatefacetofaceOSA" matInput maxlength="10"
                                                type="text" placeholder="(MM/DD/YYYY)"
                                                [matDatepicker]="txtdatefacetofaceOSA"
                                                (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtdatefacetofaceOSA">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtdatefacetofaceOSA>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-1">
                                        <mat-checkbox #ChkcpaptoBilevelDWO
                                            (change)="checkBoxEvent($event,imageType.CPAPDetailWrittenOrder,cpaptoBilevelDWO)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ cpaptoBilevelDWO.length }}</span></mat-checkbox>
                                    </div>
                                    <div class="col-4">
                                        <!-- <br><br> -->
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtRXDateNew')?.invalid }">
                                                RX Date <span class="asterisk">*</span> </mat-label>
                                            <input autocomplete="new-txtRXDateNew" [max]="BirthdateMaxDate"
                                                formControlName="txtRXDateNew" matInput maxlength="10" type="text"
                                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtRXDateNew"
                                                [min]="RxdateMin" (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtRXDateNew">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtRXDateNew> </mat-datepicker>
                                            <mat-error *ngIf="
                                            FinalForm?.get('txtRXDateNew')?.touched &&
                                            FinalForm?.get('txtRXDateNew')?.errors &&
                                              !(
                                                RxdateMin <=
                                                FinalForm?.get('txtRXDateNew')?.value
                                              )|| BirthdateMinDate <=
                                              FinalForm?.get('txtRXDateNew')?.value &&
                                            !(
                                              FinalForm?.get('txtRXDateNew')?.touched &&
                                              FinalForm?.get('txtRXDateNew')?.errors
                                                ?.required
                                            )
                                            ">
                                                Enter past one year date
                                            </mat-error>
                                        </mat-form-field>
                                    </div>


                                    <div class="col-1">
                                        <!-- class="checkboxpad"  -->
                                        <mat-checkbox #ChkcpaptoBilevelMCST
                                            (change)="checkBoxEvent($event,imageType.CPAPMedicareSleepTest,cpaptoBilevelMCST)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ cpaptoBilevelMCST.length }}</span></mat-checkbox>
                                        <!-- cpaptoBilevelMCSTCount -->
                                    </div>

                                    <div class="col-6">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtMedicarecovered')?.invalid }"> Medicare covered sleep test <span
                                                    class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtMedicarecovered"
                                                formControlName="txtMedicarecovered" matInput maxlength="10" type="text"
                                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtMedicarecovered"
                                                (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtMedicarecovered">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtMedicarecovered>
                                            </mat-datepicker>

                                        </mat-form-field>
                                    </div>


                                    <!-- <div class="col-1">
                                        <mat-checkbox #ChkcpaptoBilevelDWO
                                            (change)="checkBoxEvent($event,imageType.CPAPDetailWrittenOrder,cpaptoBilevelDWO)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ cpaptoBilevelDWO.length }}</span></mat-checkbox>
                                    </div> -->
                                    <!-- cpaptoBilevelDWOCount -->

                                    <!-- <div class="col-1"></div> -->
                                    <div class="col-5">

                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpdatedetailedWO')?.invalid }">Detailed written order <span
                                                    class="asterisk">*</span></mat-label>
                                            <mat-select formControlName="drpdatedetailedWO" placeholder="select"
                                                autocomplete="new-drpdatedetailedWO" maxlength="40">
                                                <mat-option *ngFor="let product of detailedWrittenorder"
                                                    [value]="product.id">
                                                    {{ product?.shortdesc || '-' }} / {{ product?.description || '-' }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>


                                    <div class="col-1" [hidden]="checkListName == 'New Setup'">
                                        <mat-checkbox #ChkcpaptoBilevelPT
                                            (change)="checkBoxEvent($event,imageType.CPAPPickupTicket,cpaptoBilevelPT)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ cpaptoBilevelPT.length}}</span></mat-checkbox>
                                        <!-- cpaptoBilevelPTCount -->
                                    </div>

                                    <div class="col-5" [hidden]="checkListName == 'New Setup'">
                                        <div class="col-12">
                                        <mat-label class="custom-font" [ngClass]="{ 'is-invalid': FinalForm?.get('txtMedicarePickup')?.invalid }">Pickup Ticket - Is required if the CPAP has not been <span
                                                class="asterisk">*</span></mat-label>
                                        </div>
                                        <mat-form-field class="col-12">
                                            <mat-label>(MM/DD/YYYY) <span class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtMedicarePickup"
                                                formControlName="txtMedicarePickup" matInput maxlength="10" type="text"
                                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtMedicarePickup"
                                                (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtMedicarePickup">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtMedicarePickup>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <!-- <div class="col-6"> -->
                                        <!-- Physician -->
                                        <mat-form-field class="col-6" [class.mt-4]="checkListName !== 'New Setup'" [class.mt-0]="checkListName === 'New Setup'">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpPhysicianNew')?.invalid }">
                                                <div class="select-placeholder-container" *ngIf="isPhysicianLoading">
                                                    <span>Loading...</span>
                                                    <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                                </div>
                                                <span *ngIf="!isPhysicianLoading">Physician<span class="asterisk">*</span></span>
                                            </mat-label>
                                            <mat-select formControlName="drpPhysicianNew" #singleSelect
                                                (openedChange)="getPhysicianDrpdwnV1('')" [(ngModel)]="PhysicianNew">
                                                <mat-option>
                                                    <ngx-mat-select-search placeholderLabel="Search"
                                                        noEntriesFoundLabel="No Matches found"
                                                        hideClearSearchButton="true"
                                                        formControlName="txtPhysicianFilterNew"
                                                        (keyup)="getPhysicianDrpdwnV1($event.target.value)"></ngx-mat-select-search>
                                                </mat-option>
                                                <!-- | async -->
                                                <!-- <mat-option *ngFor="let doctor of filteredPhysiciansNew"
                                                [value]="doctor.id+'/'+ doctor.doctorName">
                                                {{ doctor.doctorName }}
                                            </mat-option> -->

                                                <mat-option *ngFor="let doctor of filteredPhysiciansNew"
                                                    [value]="doctor.id">
                                                    {{ doctor.doctorName }}
                                                </mat-option>

                                            </mat-select>
                                            <button mat-button matSuffix (click)="clearPhysicianDoctorsListNew($event)"
                                                mat-icon-button [disabled]="isShowChecklist == 'true'">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>

                                    <!-- </div> -->

                                </div>
                                <!-- PAP Requal. (failed 90 day compliance)' -->
                                <div class="row" *ngIf="checkListName == 'PAP Requal. (failed 90 day compliance)'">
                                    <div class="col-6">
                                        <h2 class="app-custom-color">{{checkListName}}</h2>
                                    </div>
                                        <div class="col-6 text-right mb-1">
                                            <button matTooltip="Click Go To Do Sorting" mat-button class="buttonColor font-size-10 ml-2 p-2 dd-text" (click)="openDoSorting()" *ngIf="!isSorting">
                                              <img class="img-responsive mb-1 mr-1" height="10" src="../assets/patient_list_icons/Do_Sorting.png" /> Do Sorting
                                            </button>
                                        </div>

                                    <div class="col-1">
                                        <!-- MedicarecheckBoxList.PAPRequalF2f -->
                                        <mat-checkbox #ChkPAPRequalF2F
                                            (change)="checkBoxEvent($event,imageType.PAPreFaceToFace,papRequalF2f)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ papRequalF2f.length }}</span></mat-checkbox>
                                    </div>


                                    <div class="col-6">
                                        <div class="col-12">
                                        <mat-label class="custom-font" [ngClass]="{ 'is-invalid': FinalForm?.get('txtdatefacetofaceOSAPAP')?.invalid }"> Face - to - Face clinical evaluation conducted
                                            prior to sleep test to assess the patient for OSA. <span
                                                class="asterisk">*</span></mat-label>
                                        </div>
                                        <mat-form-field class="col-12">
                                            <mat-label>(MM/DD/YYYY) <span class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtdatefacetofaceOSAPAP"
                                                formControlName="txtdatefacetofaceOSAPAP" matInput maxlength="10"
                                                type="text" placeholder="select"
                                                [matDatepicker]="txtdatefacetofaceOSAPAP"
                                                (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtdatefacetofaceOSAPAP">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtdatefacetofaceOSAPAP>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-1">
                                        <mat-checkbox #ChkPAPRequalDWO
                                            (change)="checkBoxEvent($event,imageType.PAPreDetailWrittenOrder,papRequalDWO)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ papRequalDWO.length }}</span></mat-checkbox>
                                    </div>

                                    <div class="col-4">
                                        <!-- <br><br> -->
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtRXDatePAP')?.invalid }">
                                                RX Date <span class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtRXDatePAP" [max]="BirthdateMaxDate"
                                                formControlName="txtRXDatePAP" matInput maxlength="10" type="text"
                                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtRXDatePAP"
                                                [min]="RxdateMin" (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtRXDatePAP">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtRXDatePAP> </mat-datepicker>
                                            <mat-error *ngIf="
                                                FinalForm?.get('txtRXDatePAP')?.touched &&
                                                FinalForm?.get('txtRXDatePAP')?.errors &&
                                                !(
                                                    RxdateMin <=
                                                    FinalForm?.get('txtRXDatePAP')?.value
                                                )|| BirthdateMinDate <=
                                                FinalForm?.get('txtRXDatePAP')?.value &&
                                                !(
                                                FinalForm?.get('txtRXDatePAP')?.touched &&
                                                FinalForm?.get('txtRXDatePAP')?.errors
                                                    ?.required
                                                )
                                                ">
                                                Enter past one year date
                                            </mat-error>
                                        </mat-form-field>
                                    </div>


                                    <div class="col-1">
                                        <mat-checkbox #ChkPAPRequalMCST
                                            (change)="checkBoxEvent($event,imageType.PAPreMedicareSleepTest,papRequalMCST)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ papRequalMCST.length }}</span></mat-checkbox>
                                    </div>
                                    <div class="col-6">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtMedicarecoveredPAP')?.invalid }"> Medicare covered sleep test <span
                                                    class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtMedicarecoveredPAP"
                                                formControlName="txtMedicarecoveredPAP" matInput maxlength="10"
                                                type="text" placeholder="(MM/DD/YYYY)"
                                                [matDatepicker]="txtMedicarecoveredPAP"
                                                (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtMedicarecoveredPAP">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtMedicarecoveredPAP>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <!-- <div class="col-1">
                                        <mat-checkbox #ChkPAPRequalDWO
                                            (change)="checkBoxEvent($event,imageType.PAPreDetailWrittenOrder,papRequalDWO)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ papRequalDWO.length }}</span></mat-checkbox>
                                    </div> -->
                                    <!-- <div class="col-1"></div> -->
                                    <div class="col-5">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpdatedetailedWOPAP')?.invalid }">Detailed written order <span
                                                    class="asterisk">*</span></mat-label>
                                            <mat-select formControlName="drpdatedetailedWOPAP" placeholder="select"
                                                autocomplete="new-drpdatedetailedWOPAP" maxlength="40">
                                                <mat-option *ngFor="let product of detailedWrittenorder"
                                                    [value]="product.id">
                                                    {{ product?.shortdesc || '-' }} / {{ product?.description || '-' }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-1">
                                        <mat-checkbox #ChkPAPRequalRT
                                            (change)="checkBoxEvent($event,imageType.PAPreReturnTicket,papRequalRT)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ papRequalRT.length }}</span></mat-checkbox>
                                    </div>

                                    <div class="col-6">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtreturnTicketPAP')?.invalid }"> Return ticket <span class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtreturnTicketPAP"
                                                formControlName="txtreturnTicketPAP" matInput maxlength="10" type="text"
                                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtreturnTicketPAP"
                                                (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtreturnTicketPAP">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtreturnTicketPAP>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-5">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpPhysicianPAP')?.invalid }">
                                                <div class="select-placeholder-container" *ngIf="isPhysicianLoading">
                                                    <span>Loading...</span>
                                                    <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                                </div>
                                                <span *ngIf="!isPhysicianLoading">Physician<span class="asterisk">*</span></span>
                                            </mat-label>
                                            <mat-select formControlName="drpPhysicianPAP" #singleSelect
                                                (openedChange)="getPhysicianDrpdwnV1('')" [(ngModel)]="PhysicianNew">
                                                <mat-option>
                                                    <ngx-mat-select-search placeholderLabel="Search"
                                                        noEntriesFoundLabel="No Matches found"
                                                        hideClearSearchButton="true"
                                                        formControlName="txtPhysicianFilterPAP"
                                                        (keyup)="getPhysicianDrpdwnV1($event.target.value)"></ngx-mat-select-search>
                                                </mat-option>

                                                <!-- <mat-option *ngFor="let doctor of filteredPhysiciansPAP"
                                                    [value]="doctor.id+'/'+ doctor.doctorName">
                                                    {{ doctor.doctorName }}
                                                </mat-option> -->

                                                <mat-option *ngFor="let doctor of filteredPhysiciansNew"
                                                    [value]="doctor.id">
                                                    {{ doctor.doctorName }}
                                                </mat-option>
                                            </mat-select>
                                            <button mat-button matSuffix (click)="clearPhysicianDoctorsListPAP($event)"
                                                mat-icon-button [disabled]="isShowChecklist == 'true'">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </div>


                                    <!-- </div> -->
                                </div>
                                <!--Replacement PAP-->
                                <div class="row" *ngIf="checkListName == 'Replacement PAP'">
                                    <div class="col-6">
                                        <h2 class="app-custom-color">{{checkListName}}</h2>
                                    </div>

                                    <div class="col-6 text-right mb-1">
                                        <button matTooltip="Click Go To Do Sorting" mat-button class="buttonColor font-size-10 ml-2 p-2 dd-text" (click)="openDoSorting()" *ngIf="!isSorting">
                                          <img class="img-responsive mb-1 mr-1" height="10" src="../assets/patient_list_icons/Do_Sorting.png" /> Do Sorting
                                        </button>
                                    </div>


                                    <div class="col-1">
                                        <mat-checkbox #ChkRPAPMCST
                                            (change)="checkBoxEvent($event,imageType.REPLACEMENTMedicareSeepTest,rPAPMCST)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ rPAPMCST.length }}</span></mat-checkbox>
                                    </div>

                                    <div class="col-6">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtmedicareReplacement')?.invalid }"> Medicare covered sleep test <span
                                                    class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtmedicareReplacement"
                                                formControlName="txtmedicareReplacement" matInput maxlength="10"
                                                type="text" placeholder="(MM/DD/YYYY)"
                                                [matDatepicker]="txtmedicareReplacement"
                                                (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtmedicareReplacement">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtmedicareReplacement>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-1">
                                        <mat-checkbox #ChkRPAPDWO
                                            (change)="checkBoxEvent($event,imageType.REPLACEMENTDetailWrittenOrder,rPAPDWO)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ rPAPDWO.length }}</span></mat-checkbox>
                                    </div>

                                    <div class="col-4">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtRXDateReplacement')?.invalid }">
                                                RX Date <span class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtRXDateReplacement" [max]="BirthdateMaxDate"
                                                formControlName="txtRXDateReplacement" matInput maxlength="10"
                                                type="text" placeholder="(MM/DD/YYYY)"
                                                [matDatepicker]="txtRXDateReplacement" [min]="RxdateMin"
                                                (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtRXDateReplacement">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtRXDateReplacement> </mat-datepicker>
                                            <mat-error *ngIf="
                                            FinalForm?.get('txtRXDateReplacement')?.touched &&
                                            FinalForm?.get('txtRXDateReplacement')?.errors &&
                                              !(
                                                RxdateMin <=
                                                FinalForm?.get('txtRXDateReplacement')?.value
                                              )|| BirthdateMinDate <=
                                              FinalForm?.get('txtRXDateReplacement')?.value &&
                                            !(
                                              FinalForm?.get('txtRXDateReplacement')?.touched &&
                                              FinalForm?.get('txtRXDateReplacement')?.errors
                                                ?.required
                                            )
                                            ">
                                                Enter past one year date
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-1">
                                        <mat-checkbox #ChkRPAPFTF
                                            (change)="checkBoxEvent($event,imageType.REPLACEMENTftfNote,rPAPFTF)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ rPAPFTF.length }}</span></mat-checkbox>
                                    </div>

                                    <div class="col-6">
                                        <div class="col-12">
                                        <mat-label class="custom-font" [ngClass]="{ 'is-invalid': FinalForm?.get('txtFTFnoteReplacement')?.invalid }"> FTF note - (After pt has enrolled in MDCR.
                                            Discusses benefit of PAP, plan for) <span
                                                class="asterisk">*</span></mat-label>
                                        </div>
                                        <mat-form-field class="col-12">
                                            <mat-label>(MM/DD/YYYY) <span class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtFTFnoteReplacement"
                                                formControlName="txtFTFnoteReplacement" matInput maxlength="40"
                                                type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtFTFnoteReplacement"
                                                (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtFTFnoteReplacement">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtFTFnoteReplacement>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-5">
                                        <br><br>
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpdatedetailedWOReplacement')?.invalid }">Detailed written order <span
                                                    class="asterisk">*</span></mat-label>
                                            <mat-select formControlName="drpdatedetailedWOReplacement"
                                                placeholder="select" autocomplete="new-drpdatedetailedWOReplacement"
                                                maxlength="40">
                                                <mat-option *ngFor="let product of detailedWrittenorder"
                                                    [value]="product.id">
                                                    {{ product?.shortdesc || '-' }} / {{ product?.description || '-' }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-6">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpPhysicianReplacement')?.invalid }">
                                                <div class="select-placeholder-container" *ngIf="isPhysicianLoading">
                                                    <span>Loading...</span>
                                                    <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                                </div>
                                                <span *ngIf="!isPhysicianLoading">Physician<span class="asterisk">*</span></span>
                                            </mat-label>
                                            <mat-select formControlName="drpPhysicianReplacement" #singleSelect
                                                (openedChange)="getPhysicianDrpdwnV1('')" [(ngModel)]="PhysicianNew">
                                                <mat-option>
                                                    <ngx-mat-select-search placeholderLabel="Search"
                                                        noEntriesFoundLabel="No Matches found"
                                                        hideClearSearchButton="true"
                                                        formControlName="txtPhysicianFilterReplacement" (keyup)="getPhysicianDrpdwnV1($event.target.value)"></ngx-mat-select-search>
                                                </mat-option>

                                                <!-- <mat-option *ngFor="let doctor of filteredPhysiciansReplacement"
                                                    [value]="doctor.id+'/'+ doctor.doctorName">
                                                    {{ doctor.doctorName }}
                                                </mat-option> -->

                                                <mat-option *ngFor="let doctor of filteredPhysiciansNew"
                                                    [value]="doctor.id">
                                                    {{ doctor.doctorName }}
                                                </mat-option>
                                            </mat-select>
                                            <button mat-button matSuffix
                                                (click)="clearPhysicianDoctorsListReplacement($event)" mat-icon-button [disabled]="isShowChecklist == 'true'">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </div>

                                </div>
                                <!--Compliance Release or Supplies-->
                                <div class="row" *ngIf="checkListName == 'Compliance Release' || checkListName == 'Supplies' ">
                                    <div class="col-6">
                                        <h2 class="app-custom-color">{{checkListName}}</h2>
                                    </div>

                                    <div class="col-6 text-right mb-1">
                                        <button matTooltip="Click Go To Do Sorting" mat-button class="buttonColor font-size-10 ml-2 p-2 dd-text" (click)="openDoSorting()" *ngIf="!isSorting">
                                          <img class="img-responsive mb-1 mr-1" height="10" src="../assets/patient_list_icons/Do_Sorting.png" /> Do Sorting
                                        </button>
                                    </div>


                                    <div class="col-1">
                                        <mat-checkbox #ChkCRF2F
                                            (change)="checkBoxEvent($event,imageType.COMPLIANCEftf,crF2F)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ crF2F.length }}</span></mat-checkbox>
                                    </div>
                                    <div class="col-6">
                                        <div class="col-12">
                                        <mat-label class="custom-font" [ngClass]="{ 'is-invalid': FinalForm?.get('txtFTFnoteCompliance')?.invalid }"> Current face to face (FTF) note discusses patients diagnosis, use
                                            benefit of PAP, a recommendation continue to use the PAP unit. <span
                                                class="asterisk">*</span></mat-label>
                                            </div>
                                        <mat-form-field class="col-12">
                                            <mat-label>(MM/DD/YYYY) <span class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtFTFnoteCompliance"
                                                formControlName="txtFTFnoteCompliance" matInput maxlength="10"
                                                type="text" [matDatepicker]="txtFTFnoteCompliance"
                                                (keydown)="addSlashToDate($event)" placeholder="(MM/DD/YYYY)"/>
                                            <mat-datepicker-toggle matSuffix [for]="txtFTFnoteCompliance">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtFTFnoteCompliance>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-1">
                                        <mat-checkbox #ChkCRDWO
                                            (change)="checkBoxEvent($event,imageType.COMPLIANCEDetailWrittenOrder,crDWO)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ crDWO.length }}</span></mat-checkbox>
                                    </div>
                                    <div class="col-4">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtRXDateCompliance')?.invalid }">
                                                RX Date <span class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtRXDateCompliance" [max]="BirthdateMaxDate"
                                                formControlName="txtRXDateCompliance" matInput maxlength="10"
                                                type="text" placeholder="(MM/DD/YYYY)"
                                                [matDatepicker]="txtRXDateCompliance" [min]="RxdateMin"
                                                (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtRXDateCompliance">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtRXDateCompliance> </mat-datepicker>
                                            <mat-error *ngIf="
                                            FinalForm?.get('txtRXDateCompliance')?.touched &&
                                            FinalForm?.get('txtRXDateCompliance')?.errors &&
                                              !(
                                                RxdateMin <=
                                                FinalForm?.get('txtRXDateCompliance')?.value
                                              )|| BirthdateMinDate <=
                                              FinalForm?.get('txtRXDateCompliance')?.value &&
                                            !(
                                              FinalForm?.get('txtRXDateCompliance')?.touched &&
                                              FinalForm?.get('txtRXDateCompliance')?.errors
                                                ?.required
                                            )
                                            ">
                                                Enter past one year date
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-1">
                                        <mat-checkbox #ChkCRMCST
                                            (change)="checkBoxEvent($event,imageType.COMPLIANCEMedicareSleepTest,crMCST)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ crMCST.length }}</span></mat-checkbox>
                                    </div>
                                    <div class="col-6">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtmedicareCompliance')?.invalid }"> Medicare covered sleep test <span
                                                    class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtmedicareCompliance"
                                                formControlName="txtmedicareCompliance" matInput maxlength="10"
                                                type="text" placeholder="(MM/DD/YYYY)"
                                                [matDatepicker]="txtmedicareCompliance"
                                                (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtmedicareCompliance">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtmedicareCompliance>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>


                                    <!-- <div class="col-1">
                                        <mat-checkbox #ChkCRDWO
                                            (change)="checkBoxEvent($event,imageType.COMPLIANCEDetailWrittenOrder,crDWO)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ crDWO.length }}</span></mat-checkbox>
                                    </div> -->

                                    <div class="col-5">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpdatedetailedWOCompliance')?.invalid }">Detailed written order <span
                                                    class="asterisk">*</span></mat-label>
                                            <mat-select formControlName="drpdatedetailedWOCompliance"
                                                placeholder="select" autocomplete="new-drpdatedetailedWOCompliance"
                                                maxlength="40">
                                                <mat-option *ngFor="let product of detailedWrittenorder"
                                                    [value]="product.id">
                                                    {{ product?.shortdesc || '-' }} / {{ product?.description || '-' }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-6">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpPhysicianCompliance')?.invalid }">
                                                <div class="select-placeholder-container" *ngIf="isPhysicianLoading">
                                                    <span>Loading...</span>
                                                    <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                                </div>
                                                <span *ngIf="!isPhysicianLoading">Physician<span class="asterisk">*</span></span>
                                            </mat-label>
                                            <mat-select formControlName="drpPhysicianCompliance" #singleSelect
                                                (openedChange)="getPhysicianDrpdwnV1('')" [(ngModel)]="PhysicianNew">
                                                <mat-option>
                                                    <ngx-mat-select-search placeholderLabel="Search"
                                                        noEntriesFoundLabel="No Matches found"
                                                        hideClearSearchButton="true"
                                                        formControlName="txtPhysicianFilterCompliance"
                                                        (keyup)="getPhysicianDrpdwnV1($event.target.value)"></ngx-mat-select-search>
                                                </mat-option>

                                                <!-- <mat-option *ngFor="let doctor of filteredPhysiciansCompliance"
                                                    [value]="doctor.id+'/'+ doctor.doctorName">
                                                    {{ doctor.doctorName }}
                                                </mat-option> -->

                                                <mat-option *ngFor="let doctor of filteredPhysiciansNew"
                                                    [value]="doctor.id">
                                                    {{ doctor.doctorName }}
                                                </mat-option>
                                            </mat-select>
                                            <button mat-button matSuffix [disabled]="isShowChecklist == 'true'"
                                                (click)="clearPhysicianDoctorsListCompliance($event)" mat-icon-button [disabled]="isShowChecklist == 'true'">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </div>


                                    <!-- </div> -->
                                </div>
                                <!--Oxygen Setup-->
                                <div class="row" *ngIf="checkListName == 'Oxygen Setup'">
                                    <div class="col-6">
                                        <h2 class="app-custom-color">{{checkListName}}</h2>
                                    </div>
                                    <div class="col-6 text-right mb-1">
                                        <button matTooltip="Click Go To Do Sorting" mat-button class="buttonColor font-size-10 ml-2 p-2 dd-text" (click)="openDoSorting()" *ngIf="!isSorting">
                                          <img class="img-responsive mb-1 mr-1" height="10" src="../assets/patient_list_icons/Do_Sorting.png" /> Do Sorting
                                        </button>
                                    </div>


                                    <div class="col-1">
                                        <mat-checkbox #ChkOSOCEN
                                            (change)="checkBoxEvent($event,imageType.OfflineClinicalNote,osOCEN)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ osOCEN.length }}</span></mat-checkbox>
                                    </div>

                                    <div class="col-5">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtclinicalevaluationOxygen')?.invalid }"> Office clinical evaluation notes <span
                                                    class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtclinicalevaluationOxygen"
                                                formControlName="txtclinicalevaluationOxygen" matInput maxlength="10"
                                                type="text" placeholder="(MM/DD/YYYY)"
                                                [matDatepicker]="txtclinicalevaluationOxygen"
                                                (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtclinicalevaluationOxygen">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtclinicalevaluationOxygen>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>


                                    <div class="col-1">
                                        <!-- <mat-checkbox #ChkOSDWO
                                            (change)="checkBoxEvent($event,imageType.OXYGENDetailWrittenOrder,osDWO)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ osDWO.length }}</span></mat-checkbox> -->
                                    </div>

                                    <div class="col-5">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpdatedetailedWOOxygen')?.invalid }">Detailed written order <span
                                                    class="asterisk">*</span></mat-label>
                                            <mat-select formControlName="drpdatedetailedWOOxygen" placeholder="select"
                                                autocomplete="new-drpdatedetailedWOOxygen" maxlength="40">
                                                <mat-option *ngFor="let product of detailedWrittenorder"
                                                    [value]="product.id">
                                                    {{ product?.shortdesc || '-' }} / {{ product?.description || '-' }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-1">
                                        <mat-checkbox #ChkOSMCST
                                            (change)="checkBoxEvent($event,imageType.OXYGENMedicareSleepTest,osMCST)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ osMCST.length }}</span></mat-checkbox>
                                    </div>

                                    <mat-form-field class="col-5">
                                        <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtmedicareOxygen')?.invalid }"> Medicare covered sleep test <span
                                                class="asterisk">*</span></mat-label>
                                        <input autocomplete="new-txtmedicareOxygen" formControlName="txtmedicareOxygen"
                                            matInput maxlength="10" type="text" placeholder="(MM/DD/YYYY)"
                                            [matDatepicker]="txtmedicareOxygen" (keydown)="addSlashToDate($event)" />
                                        <mat-datepicker-toggle matSuffix [for]="txtmedicareOxygen">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #txtmedicareOxygen>
                                        </mat-datepicker>
                                    </mat-form-field>

                                    <div class="col-1">
                                        <mat-checkbox #ChkOSDWO
                                            (change)="checkBoxEvent($event,imageType.OXYGENDetailWrittenOrder,osDWO)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ osDWO.length }}</span></mat-checkbox>
                                    </div>

                                    <mat-form-field class="col-5">
                                        <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtRXDateOxygen')?.invalid }">
                                            RX Date <span class="asterisk">*</span></mat-label>
                                        <input autocomplete="new-txtRXDateOxygen" [max]="BirthdateMaxDate"
                                            formControlName="txtRXDateOxygen" matInput maxlength="10" type="text"
                                            placeholder="(MM/DD/YYYY)" [matDatepicker]="txtRXDateOxygen"
                                            [min]="RxdateMin" (keydown)="addSlashToDate($event)" />
                                        <mat-datepicker-toggle matSuffix [for]="txtRXDateOxygen">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #txtRXDateOxygen> </mat-datepicker>
                                        <mat-error *ngIf="
                                        FinalForm?.get('txtRXDateOxygen')?.touched &&
                                        FinalForm?.get('txtRXDateOxygen')?.errors &&
                                          !(
                                            RxdateMin <=
                                            FinalForm?.get('txtRXDateOxygen')?.value
                                          )|| BirthdateMinDate <=
                                          FinalForm?.get('txtRXDateOxygen')?.value &&
                                        !(
                                          FinalForm?.get('txtRXDateOxygen')?.touched &&
                                          FinalForm?.get('txtRXDateOxygen')?.errors
                                            ?.required
                                        )
                                        ">
                                            Enter past one year date
                                        </mat-error>
                                    </mat-form-field>

                                    <div class="col-6">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpPhysicianOxygen')?.invalid }">
                                                <div class="select-placeholder-container" *ngIf="isPhysicianLoading">
                                                    <span>Loading...</span>
                                                    <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                                </div>
                                                <span *ngIf="!isPhysicianLoading">Physician<span class="asterisk">*</span></span>
                                            </mat-label>
                                            <mat-select formControlName="drpPhysicianOxygen" #singleSelect
                                                (openedChange)="getPhysicianDrpdwnV1('')" [(ngModel)]="PhysicianNew">
                                                <mat-option>
                                                    <ngx-mat-select-search placeholderLabel="Search"
                                                        noEntriesFoundLabel="No Matches found"
                                                        hideClearSearchButton="true"
                                                        formControlName="txtPhysicianFilterOxygen"
                                                        (keyup)="getPhysicianDrpdwnV1($event.target.value)"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let doctor of filteredPhysiciansNew"
                                                    [value]="doctor.id">
                                                    {{ doctor.doctorName }}
                                                </mat-option>
                                            </mat-select>
                                            <button mat-button matSuffix
                                                (click)="clearPhysicianDoctorsListOxygen($event)" mat-icon-button [disabled]="isShowChecklist == 'true'">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </div>

                                </div>

                                <!--Non Invasive Ventilation-->
                                <div class="row" *ngIf="checkListName == 'Non-invasive ventilation (NIV)'">
                                    <div class="col-6">
                                        <h2 class="app-custom-color">{{checkListName}}</h2>
                                    </div>
                                    <div class="col-6 text-right mb-1">
                                        <button matTooltip="Click Go To Do Sorting" mat-button class="buttonColor font-size-10 ml-2 p-2 dd-text" (click)="openDoSorting()" *ngIf="!isSorting">
                                          <img class="img-responsive mb-1 mr-1" height="10" src="../assets/patient_list_icons/Do_Sorting.png" /> Do Sorting
                                        </button>
                                    </div>

                                    <div class="col-1">
                                        <mat-checkbox #ChkNIVF2F
                                            (change)="checkBoxEvent($event,imageType.NONINVASIVEfaceToface,nivF2F)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ nivF2F.length }}</span></mat-checkbox>
                                    </div>
                                    <div class="col-5">
                                        <div class="col-12">
                                        <mat-label class="custom-font" [ngClass]="{ 'is-invalid': FinalForm?.get('txtclinicalevaluationNIV')?.invalid }"> Face to Face clinical evaluation conducted <span
                                                class="asterisk">*</span></mat-label>
                                        </div>
                                        <mat-form-field class="col-12">
                                            <mat-label>(MM/DD/YYYY) <span class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtclinicalevaluationNIV"
                                                formControlName="txtclinicalevaluationNIV" matInput maxlength="10"
                                                type="text" placeholder="(MM/DD/YYYY)"
                                                [matDatepicker]="txtclinicalevaluationNIV"
                                                (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtclinicalevaluationNIV">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtclinicalevaluationNIV>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-1">
                                        <!-- <mat-checkbox #ChkNIVDWO
                                            (change)="checkBoxEvent($event,imageType.NONINVASIVEDetailWrittenOrder,nivDWO)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ nivDWO.length }}</span></mat-checkbox> -->
                                    </div>

                                    <div class="col-5">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpdatedetailedWONIV')?.invalid }">Detailed written order <span
                                                    class="asterisk">*</span></mat-label>
                                            <mat-select formControlName="drpdatedetailedWONIV" placeholder="select"
                                                autocomplete="new-drpdatedetailedWONIV" maxlength="40">
                                                <mat-option *ngFor="let product of detailedWrittenorder"
                                                    [value]="product.id">
                                                    {{ product?.shortdesc || '-' }} / {{ product?.description || '-' }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-1">
                                        <mat-checkbox #ChkNIVMCST
                                            (change)="checkBoxEvent($event,imageType.NONINVASIVEMedicareSleepTest,nivMCST)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ nivMCST.length }}</span></mat-checkbox>
                                    </div>

                                    <div class="col-5">
                                        <mat-form-field class="col-12">
                                            <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtmedicareNIV')?.invalid }"> Medicare covered sleep test <span
                                                    class="asterisk">*</span></mat-label>
                                            <input autocomplete="new-txtmedicareNIV" formControlName="txtmedicareNIV"
                                                matInput maxlength="10" type="text" placeholder="(MM/DD/YYYY)"
                                                [matDatepicker]="txtmedicareNIV" (keydown)="addSlashToDate($event)" />
                                            <mat-datepicker-toggle matSuffix [for]="txtmedicareNIV">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #txtmedicareNIV>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-1">
                                        <mat-checkbox #ChkNIVDWO
                                            (change)="checkBoxEvent($event,imageType.NONINVASIVEDetailWrittenOrder,nivDWO)" [disabled]="!isSorting" matTooltip="Sorting is Pending" [matTooltipDisabled]="isSorting"><span
                                                class="checklabel">{{ nivDWO.length }}</span></mat-checkbox>
                                    </div>

                                    <mat-form-field class="col-4">
                                        <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtRXDateNIV')?.invalid }">
                                            RX Date <span class="asterisk">*</span></mat-label>
                                        <input autocomplete="new-txtRXDateNIV" [max]="BirthdateMaxDate"
                                            formControlName="txtRXDateNIV" matInput maxlength="10" type="text"
                                            placeholder="(MM/DD/YYYY)" [matDatepicker]="txtRXDateNIV" [min]="RxdateMin"
                                            (keydown)="addSlashToDate($event)" />
                                        <mat-datepicker-toggle matSuffix [for]="txtRXDateNIV">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #txtRXDateNIV> </mat-datepicker>
                                        <mat-error *ngIf="
                                        FinalForm?.get('txtRXDateNIV')?.touched &&
                                        FinalForm?.get('txtRXDateNIV')?.errors &&
                                          !(
                                            RxdateMin <=
                                            FinalForm?.get('txtRXDateNIV')?.value
                                          )|| BirthdateMinDate <=
                                          FinalForm?.get('txtRXDateNIV')?.value &&
                                        !(
                                          FinalForm?.get('txtRXDateNIV')?.touched &&
                                          FinalForm?.get('txtRXDateNIV')?.errors
                                            ?.required
                                        )
                                        ">
                                            Enter past one year date
                                        </mat-error>
                                    </mat-form-field>


                                    <mat-form-field class="col-6 ml-2">
                                        <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpPhysicianNIV')?.invalid }">
                                            <div class="select-placeholder-container" *ngIf="isPhysicianLoading">
                                                <span>Loading...</span>
                                                <mat-spinner class="spinner" diameter="20"></mat-spinner>
                                            </div>
                                            <span *ngIf="!isPhysicianLoading">Physician<span class="asterisk">*</span></span>
                                        </mat-label>
                                        <mat-select formControlName="drpPhysicianNIV" #singleSelect
                                            (openedChange)="getPhysicianDrpdwnV1('')" [(ngModel)]="PhysicianNew">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No Matches found" hideClearSearchButton="true"
                                                    formControlName="txtPhysicianFilterNIV"
                                                    (keyup)="getPhysicianDrpdwnV1($event.target.value)"></ngx-mat-select-search>
                                            </mat-option>

                                            <!-- <mat-option *ngFor="let doctor of filteredPhysiciansNIV"
                                                [value]="doctor.id+'/'+ doctor.doctorName">
                                                {{ doctor.doctorName }}
                                            </mat-option> -->

                                            <mat-option *ngFor="let doctor of filteredPhysiciansNew"
                                                [value]="doctor.id">
                                                {{ doctor.doctorName }}
                                            </mat-option>

                                        </mat-select>
                                        <button mat-button matSuffix (click)="clearPhysicianDoctorsListNIV($event)"
                                            mat-icon-button [disabled]="isShowChecklist == 'true'">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <!-- </div> -->
                                </div>

                                <mat-divider></mat-divider>
                                <div class="row mt-3">

                                    <div class="col-12">
                                        <mat-label class="app-custom-color">New Checklist</mat-label>
                                    </div>

                                    <mat-form-field class="col-4">
                                        <mat-label> Prepared Date</mat-label>
                                        <input autocomplete="new-txtPreparedDate" formControlName="txtPreparedDate"
                                            matInput maxlength="10" type="text" placeholder="(MM/DD/YYYY)"
                                            [matDatepicker]="txtPreparedDate" [max]="endDate"
                                            (keydown)="addSlashToDate($event)" />
                                        <mat-datepicker-toggle matSuffix
                                            [for]="txtPreparedDate"></mat-datepicker-toggle>
                                        <mat-datepicker #txtPreparedDate></mat-datepicker>

                                        <mat-error *ngIf="
                                            FinalForm?.get('txtPreparedDate')?.touched &&
                                            FinalForm?.get('txtPreparedDate')?.hasError('matDatepickerParse')
                                        ">
                                            Enter a valid date!
                                        </mat-error>

                                        <mat-error *ngIf="
                                            FinalForm?.get('txtPreparedDate')?.touched &&
                                            !FinalForm?.get('txtPreparedDate')?.errors?.required &&
                                            minDate < FinalForm?.get('txtPreparedDate')?.value
                                        ">
                                            Enter Past Date Only!
                                        </mat-error>
                                    </mat-form-field>





                                    <mat-form-field class="col-4">
                                        <mat-label>Prepared By</mat-label>
                                        <input matInput readonly maxlength="100" formControlName="txtPreparedBy"
                                            type="text" autocomplete="new-txtPreparedBy" />
                                    </mat-form-field>
                                    <div class="col-4 mt-3">
                                        <mat-checkbox readonly [matTooltip]="'Grand Fathered'"
                                            formControlName="txtgrandFathered">Grand Fathered
                                        </mat-checkbox>
                                    </div>
                                    <!-- Compliance Met -->

                                    <div class="col-4">
                                        <mat-radio-group id="rbnapprove" class="txt-left">
                                            <mat-checkbox #approveCheckbox (change)="onApprove('1')" [disabled]="isShowChecklist=='true'"
                                                [checked]="isSelected('1')" class="mr-2">Approve</mat-checkbox>
                                            <mat-checkbox #disapproveCheckbox (change)="onApprove('2')" [disabled]="isShowChecklist=='true'"
                                                [checked]="isSelected('2')" class="mr-2">Disapprove</mat-checkbox>
                                        </mat-radio-group>
                                    </div>
                                    <mat-form-field class="col-5">
                                        <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('drpDisapprove')?.invalid }">Disapprove Reason <span class="asterisk" *ngIf="isDispproveReason">*</span></mat-label>
                                        <mat-select formControlName="drpDisapprove"
                                            (selectionChange)="drpDisapproveselected()" [(ngModel)]="disapproveReason"
                                            autocomplete="new-drpDisapprove" placeholder="select" maxlength="40">
                                            <mat-option *ngFor="let dis of disapprovedReason" [value]="dis.key">
                                                {{ dis.displayName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <!--Disaprove Notes-->
                                    <mat-form-field class="col-9" *ngIf="isDisapproveNotes">
                                        <mat-label [ngClass]="{ 'is-invalid': FinalForm?.get('txtDisapproveNotes')?.invalid }">DisApprove Notes <span class="asterisk">*</span></mat-label>
                                        <textarea matInput maxlength="100" formControlName="txtDisapproveNotes"
                                            type="text" autocomplete="new-txtDisapproveNotes" rows="4"
                                            class="textareaResize"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="row">
                                    <div class="col-12 mt-1">
                                        <mat-divider></mat-divider>
                                    </div>

                                    <mat-form-field class="col-12">
                                        <mat-label>Notes</mat-label>
                                            <textarea class="textareaResize" matInput rows="4"
                                                autocomplete="new-txtAddNotes" maxlength="5000"
                                                formControlName="txtAddNotes" type="text" [(ngModel)]="notesText" (input)="updateCharacterCount(5000)"></textarea>
                                                <div class="char-count">
                                                    {{ notesText?.length }} / 5000
                                                  </div>
                                    </mat-form-field>

                                    <div class="col-12 align-items-right mt-3">
                                        <div class="text-lg-right">
                                            <!-- <button mat-button class="buttonColor mr-2" *ngIf="branchId===''" (click)="openAddWorkingModel()">
                                                Add Branch hours</button> -->

                                            <!-- <button mat-button class="backclr mr-2" [disabled]="isviewcheckLlist" (click)="GotoQuestionform()">
                                                Back
                                            </button> -->
                                            <button mat-button class="backclr mr-2"
                                                [disabled]="isviewcheckLlist || isShowChecklist || isSave" (click)="Goback(3)">
                                                Back
                                            </button>
                                            <button mat-button class="Nextclr mr-2"
                                                [disabled]="FinalForm.invalid || isSave || isShowChecklist"
                                                (click)="Savefinalform()">
                                                <span>Save</span>
                                                <mat-icon *ngIf="isShowSpinner">
                                                    <mat-spinner class="spinner-border spinner-border-sm"
                                                        diameter="20"></mat-spinner>
                                                </mat-icon>
                                            </button>

                                            <!-- <button mat-button class="Nextclr mr-2"
                                                [disabled]="FinalForm.invalid || isSave" (click)="Savefinalform()">
                                                <span>Save & Close</span>
                                                <mat-icon *ngIf="isShowSpinner">
                                                    <mat-spinner class="spinner-border spinner-border-sm"
                                                        diameter="20"></mat-spinner>
                                                </mat-icon>
                                            </button> -->
                                            <!-- <button mat-button class="Nextclr mr-2"
                                            (click)="check()">
                                            Check
                                        </button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div><br />
            <!-- <div class="sortingcategory col-12">
                <div class="row">
                    <div class="col-xl-12 pb-2">
                        <div class="card elevated-div">


                            <mat-accordion class="example-headers-align">
                                <mat-expansion-panel [expanded]="SortingpanelOpenState" (afterExpand)="toggle()"
                                    (closed)="toggleV2()">
                                    <mat-expansion-panel-header style="padding-right:25px !important;">
                                        <div class="my-panel" style="width:100%" (click)="$event.stopPropagation();">
                                            <ul class="card-title" ngbNav #nav="ngbNav" justify="justified"
                                                class="alert-success nav nav-pills nav-fill"
                                                style="border-bottom: 2px solid rgb(201, 201, 201)">
                                                <li ngbNavItem="sleepstudy" id="sleepstudy">
                                                    <a [ngClass]="activeTab == 'sleepstudy' ? 'active' : ''" ngbNavLink
                                                        (click)="changeTab('sleepstudy')"><b>Sleep Study</b></a>
                                                </li>
                                                <li ngbNavItem="rx" id="rx">
                                                    <a [ngClass]="activeTab == 'rx' ? 'active' : ''" ngbNavLink
                                                        (click)="changeTab('rx')"><b>RX</b></a>
                                                </li>
                                                <li ngbNavItem="compliance" id="compliance">
                                                    <a [ngClass]="activeTab == 'compliance' ? 'active' : ''" ngbNavLink
                                                        (click)="changeTab('compliance')"><b>Compliance</b></a>
                                                </li>
                                                <li ngbNavItem="facetoface" id="facetoface">
                                                    <a [ngClass]="activeTab == 'facetoface' ? 'active' : ''" ngbNavLink
                                                        (click)="changeTab('facetoface')"><b>Face-to-Face</b></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </mat-expansion-panel-header>
                                    <div class="card-body mb-0">
                                        <form [formGroup]="Tabviewform">
                                            <div *ngIf="activeTab === 'sleepstudy'">
                                                <div class="card mb-0 card-body">
                                                    <div class="row">
                                                        <div class="col-12 d-flex justify-content-between">
                                                            <mat-label class="app-custom-color">Sleep Study</mat-label>
                                                        </div>
                                                        <mat-form-field class="col-3">

                                                            <mat-label> Study Date</mat-label>
                                                            <input autocomplete="new-txtStudyDate"
                                                                formControlName="txtStudyDate" matInput readonly
                                                                maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                                                                [matDatepicker]="txtStudyDate" />
                                                            <mat-datepicker-toggle matSuffix [for]="txtStudyDate"
                                                                disabled>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #txtStudyDate>
                                                            </mat-datepicker>
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> Pressure Settings</mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtPressureSettings" type="text"
                                                                autocomplete="new-txtPressureSettings" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> REI </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtREI" type="text"
                                                                autocomplete="new-txtREI" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> RDI </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtRDI" type="text"
                                                                autocomplete="new-txtRDI" />
                                                        </mat-form-field>
                                                        <div class="col-12 d-flex justify-content-between">
                                                            <mat-label class="app-custom-color">Diagnostic Sleep
                                                                Study</mat-label>
                                                        </div>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> Total Sleep Minutes </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtTotalSleep" type="text"
                                                                autocomplete="new-txtTotalSleep" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> AHI</mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtAHI" type="text"
                                                                autocomplete="new-txtAHI" />
                                                        </mat-form-field>
                                                        <div class="col-3">
                                                            <mat-checkbox disabled [matTooltip]="'Doctor Signed'"
                                                                formControlName="chkDoctorsigned">Doctor
                                                                Signed
                                                            </mat-checkbox>
                                                        </div>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> Central Apneas </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtCentralApneas" type="text"
                                                                autocomplete="new-txtCentralApneas" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> Central Hyponeas </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtCentralHyponeas" type="text"
                                                                autocomplete="new-txtCentralHyponeas" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-4">
                                                            <mat-label> Total Apnea & Hypopnea </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtTotalApnea" type="text"
                                                                autocomplete="new-txtTotalApnea" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-4">

                                                            <mat-label>Diag Sleep Study Date</mat-label>
                                                            <input autocomplete="new-txtdiagSleepdate"
                                                                formControlName="txtdiagSleepdate" matInput readonly
                                                                maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                                                                [matDatepicker]="txtdiagSleepdate" />
                                                            <mat-datepicker-toggle matSuffix [for]="txtdiagSleepdate"
                                                                disabled>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #txtdiagSleepdate>
                                                            </mat-datepicker>
                                                        </mat-form-field>
                                                        <div class="col-12 d-flex justify-content-between">
                                                            <mat-label class="app-custom-color">Titration Sleep
                                                                Study</mat-label>
                                                        </div>
                                                        <mat-form-field class="col-3">
                                                            <mat-label>Sleep Study Date</mat-label>
                                                            <input autocomplete="new-txtSleepStudyDate"
                                                                formControlName="txtSleepStudyDate" matInput readonly
                                                                maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                                                                [matDatepicker]="txtSleepStudyDate" />
                                                            <mat-datepicker-toggle matSuffix [for]="txtSleepStudyDate"
                                                                disabled>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #txtSleepStudyDate>
                                                            </mat-datepicker>
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> AHI</mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtTitrationAHI" type="text"
                                                                autocomplete="new-txtTitrationAHI" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> Central Apneas </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtTitrationCentralApneas" type="text"
                                                                autocomplete="new-txtTitrationCentralApneas" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> Central Hyponeas </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtTitrationCentralHyponeas"
                                                                type="text"
                                                                autocomplete="new-txtTitrationCentralHyponeas" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-4">
                                                            <mat-label> Total Apnea & Hypopnea </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtTitrationtotalHypopnea" type="text"
                                                                autocomplete="new-txtTitrationtotalHypopnea" />
                                                        </mat-form-field>
                                                        <div class="col-4">
                                                            <mat-checkbox disabled [matTooltip]="'CPAP Tried and Fai'"
                                                                formControlName="chkCPAPTried">CPAP
                                                                Tried and Fai
                                                            </mat-checkbox>
                                                        </div>
                                                        <div class="col-4">
                                                            <mat-checkbox disabled
                                                                [matTooltip]="'Resp. breakdown Avail'"
                                                                formControlName="chkRespbreakdown">Resp. breakdown Avail
                                                            </mat-checkbox>
                                                        </div>
                                                        <div class="col-4">
                                                            <mat-checkbox disabled [matTooltip]="'Doctor Signed'"
                                                                formControlName="chkTitrationDoctorsigned">Doctor Signed
                                                            </mat-checkbox>
                                                        </div>
                                                        <div class="col-4">
                                                            <mat-checkbox disabled [matTooltip]="'CPAP returned'"
                                                                formControlName="chkCPAPreturned">CPAP
                                                                returned
                                                            </mat-checkbox>
                                                        </div>
                                                        <div class="col-4">
                                                            <mat-checkbox disabled
                                                                [matTooltip]="'Baseline file available'"
                                                                formControlName="chkBaselinefile">Baseline file
                                                                available
                                                            </mat-checkbox>
                                                        </div>
                                                        <mat-form-field class="col-4">
                                                            <mat-label> Obstructive Apnea </mat-label>
                                                            <input autocomplete="new-txtObstructive"
                                                                formControlName="txtObstructive" matInput maxlength="40"
                                                                type="text" placeholder="(MM/DD/YYYY)" />
                                                        </mat-form-field>


                                                        <mat-form-field class="col-4">
                                                            <mat-label>Diag. Code-ICD-10</mat-label>
                                                            <mat-select placeholder="Select"
                                                                formControlName="diagSleepStudy" multiple>
                                                                <mat-option *ngFor="let dis of diagnosiscodesleepstudy"
                                                                    [value]="dis" [disabled]="true">
                                                                    {{ dis }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="activeTab === 'rx'">
                                                <div class="card mb-0 card-body">
                                                    <div class="row">
                                                        <div class="col-12 d-flex justify-content-between">
                                                            <mat-label class="app-custom-color">Prescription</mat-label>
                                                        </div>
                                                        <mat-form-field class="col-4">
                                                            <mat-label> Prescription Date</mat-label>
                                                            <input autocomplete="new-txtPrescriptiondate"
                                                                formControlName="txtPrescriptiondate" matInput readonly
                                                                maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                                                                [matDatepicker]="txtPrescriptiondate" />
                                                            <mat-datepicker-toggle matSuffix [for]="txtPrescriptiondate"
                                                                disabled>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #txtPrescriptiondate>
                                                            </mat-datepicker>
                                                        </mat-form-field>
                                                        <mat-form-field class="col-4">
                                                            <mat-label> Machine Details </mat-label>
                                                            <input matInput readonly
                                                                formControlName="txtRXMachineDetails" type="text"
                                                                autocomplete="new-txtRXMachineDetails" />
                                                        </mat-form-field>

                                                        <mat-form-field class="col-4">
                                                            <mat-label> Pressure level </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtPressurelevel" type="text"
                                                                autocomplete="new-txtPressurelevel" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-6">
                                                            <mat-label> Doctor Name </mat-label>
                                                            <input matInput readonly formControlName="txtRXDoctorName"
                                                                type="text" autocomplete="new-txtRXDoctorName" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> Length of need </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtLengthofneed" type="text"
                                                                autocomplete="new-txtLengthofneed" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> NPI Number </mat-label>
                                                            <input matInput readonly maxlength="13" mask="000-000-0000"
                                                                formControlName="txtNPINumber" type="text"
                                                                autocomplete="new-txtNPINumber" />
                                                        </mat-form-field>
                                                        <div class="col-3">
                                                            <mat-checkbox disabled [matTooltip]="'Is Doctor Signed'"
                                                                formControlName="chkRXDoctorsigned">Is
                                                                Doctor Signed
                                                            </mat-checkbox>
                                                        </div>
                                                        <mat-form-field class="col-3">

                                                            <mat-label> Doctor Signed Date</mat-label>
                                                            <input autocomplete="new-txtDoctorsigneddate"
                                                                formControlName="txtDoctorsigneddate" matInput readonly
                                                                maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                                                                [matDatepicker]="txtDoctorsigneddate" />
                                                            <mat-datepicker-toggle matSuffix [for]="txtDoctorsigneddate"
                                                                disabled>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #txtDoctorsigneddate>
                                                            </mat-datepicker>

                                                        </mat-form-field>
                                                        <mat-form-field class="col-6">
                                                            <mat-label>Diagnosis Code-ICD-10</mat-label>
                                                            <mat-select placeholder="Select"
                                                                formControlName="diagSleepStudy" multiple>
                                                                <mat-option *ngFor="let dis of diagnosiscodeRX"
                                                                    [value]="dis" [disabled]="true">
                                                                    {{ dis }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeTab === 'compliance'">
                                                <div class="card mb-0 card-body">
                                                    <div class="row">
                                                        <div class="col-12 d-flex justify-content-between">
                                                            <mat-label class="app-custom-color">Compliance</mat-label>
                                                        </div>
                                                        <mat-form-field class="col-4">
                                                            <mat-label> Study Start Date</mat-label>
                                                            <input autocomplete="new-txtStudyStartdate"
                                                                formControlName="txtStudyStartdate" matInput readonly
                                                                maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                                                                [matDatepicker]="txtStudyStartdate" />
                                                            <mat-datepicker-toggle matSuffix [for]="txtStudyStartdate"
                                                                disabled>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #txtStudyStartdate>
                                                            </mat-datepicker>

                                                        </mat-form-field>
                                                        <mat-form-field class="col-4">
                                                            <mat-label> Study End Date</mat-label>
                                                            <input autocomplete="new-txtStudyEnddate"
                                                                formControlName="txtStudyEnddate" matInput readonly
                                                                maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                                                                [matDatepicker]="txtStudyEnddate" />
                                                            <mat-datepicker-toggle matSuffix [for]="txtStudyEnddate"
                                                                disabled>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #txtStudyEnddate>
                                                            </mat-datepicker>
                                                        </mat-form-field>
                                                        <mat-form-field class="col-4">
                                                            <mat-label> Compliance percentage </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtcompPercentage" type="text"
                                                                autocomplete="new-txtcompPercentage" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> Usage days </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtusageDays" type="text"
                                                                autocomplete="new-txtusageDays" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> Out Of </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtOutOf" type="text"
                                                                autocomplete="new-txtOutOf" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> Greather than 4 hours </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtGreaterfourhours" type="text"
                                                                autocomplete="new-txtGreaterfourhours" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> Less than 4 hours </mat-label>
                                                            <input matInput readonly maxlength="40"
                                                                formControlName="txtLessfourhours" type="text"
                                                                autocomplete="new-txtLessfourhours" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeTab === 'facetoface'">
                                                <div class="card mb-0 card-body">
                                                    <div class="row">
                                                        <div class="col-12 d-flex justify-content-between">
                                                            <mat-label class="app-custom-color">Face to Face</mat-label>
                                                        </div>
                                                        <mat-form-field class="col-3">

                                                            <mat-label> Face to Face Date</mat-label>
                                                            <input autocomplete="new-txtfacetofacedate"
                                                                formControlName="txtfacetofacedate" matInput readonly
                                                                maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                                                                [matDatepicker]="txtfacetofacedate" />
                                                            <mat-datepicker-toggle matSuffix [for]="txtfacetofacedate"
                                                                disabled>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #txtfacetofacedate>
                                                            </mat-datepicker>

                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> Date of visit</mat-label>
                                                            <input autocomplete="new-txtDateofvisit"
                                                                formControlName="txtDateofvisit" matInput readonly
                                                                maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                                                                [matDatepicker]="txtDateofvisit" />
                                                            <mat-datepicker-toggle matSuffix [for]="txtDateofvisit"
                                                                disabled>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #txtDateofvisit>
                                                            </mat-datepicker>

                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> Signed Date</mat-label>
                                                            <input autocomplete="new-txtSignedDate"
                                                                formControlName="txtSignedDate" matInput readonly
                                                                maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                                                                [matDatepicker]="txtSignedDate" />
                                                            <mat-datepicker-toggle matSuffix [for]="txtSignedDate"
                                                                disabled>
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #txtSignedDate>
                                                            </mat-datepicker>

                                                        </mat-form-field>
                                                        <mat-form-field class="col-3">
                                                            <mat-label> NPI Number </mat-label>
                                                            <input matInput readonly maxlength="13" mask="000-000-0000"
                                                                formControlName="txtfaceNPINumber" type="text"
                                                                autocomplete="new-txtfaceNPINumber" />
                                                        </mat-form-field>
                                                        <div class="col-4">
                                                            <mat-checkbox disabled
                                                                [matTooltip]="'Previous history - declaration'"
                                                                formControlName="chkPrevioushistory">Is Previous history
                                                            </mat-checkbox>
                                                        </div>
                                                        <div class="col-4">
                                                            <mat-checkbox disabled
                                                                [matTooltip]="'Sleep test recommended'"
                                                                formControlName="chkfaceSleeptest">Sleep test
                                                                recommended
                                                            </mat-checkbox>
                                                        </div>
                                                        <div class="col-4">
                                                            <mat-checkbox disabled [matTooltip]="'Is Doctor Signed'"
                                                                formControlName="chkfaceDoctorsigned">Is Doctor Signed
                                                            </mat-checkbox>
                                                        </div>

                                                        <mat-checkbox class="col-4" disabled
                                                            [matTooltip]="'Patient Benefits and usage'"
                                                            formControlName="chkfacePatientBenefits">Patient benefits
                                                        </mat-checkbox>

                                                        <mat-form-field class="col-4">
                                                            <mat-label> Doctor Name </mat-label>
                                                            <input matInput readonly formControlName="txtfaceDoctorName"
                                                                type="text" autocomplete="new-txtfaceDoctorName" />
                                                        </mat-form-field>
                                                        <mat-form-field class="col-4">
                                                            <mat-label> Symptoms </mat-label>
                                                            <input matInput readonly formControlName="txtfaceSymptoms"
                                                                type="text" autocomplete="new-txtfaceSymptoms" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="scrollbottom"></div>
                                </mat-expansion-panel>
                            </mat-accordion>





                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>

    <div class="col-6 stickypdfviewer">

        <div class="card card-body align-items-center justify-content-center p-0">
            <!-- <h1 class="fw-bolder" *ngIf="isLoading || TiffError">
                {{ loadingMessage }}
            </h1> -->

            <div *ngIf="isLoading" class="d-flex justify-content-center">
                <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>


            <div *ngIf="!TiffError">
                <div class="zoom-buttons" *ngIf="!isLoading">

                <a [matTooltip]="'Clear All Annotation'" class="ml-2 mt-2" (click)="clearAllAnnotation()"
                    [class.disabled]="this.textAnnotations.length === 0 || disableAnnotation" href="javascript:void(0)">
                    <mat-icon>clear</mat-icon>
                    <!-- restore -->
                </a>

                <a [matTooltip]="'Zoom In Page'" class="ml-2 mt-2" (click)="zoomIn()"
                    [class.disabled]="isLoading || zoomLevel === MAX_ZOOM_LEVEL"
                    href="javascript:void(0)">
                    <mat-icon>add</mat-icon>
                  </a>
      
                <a [matTooltip]="'Zoom Out Page'" class="ml-2 mt-2" (click)="zoomOut()"
                    [class.disabled]="isLoading || zoomLevel === MIN_ZOOM_LEVEL"
                    href="javascript:void(0)">
                    <mat-icon>remove</mat-icon>
                </a>
      
                <a [matTooltip]="'Go to First Page'" class="ml-2 mt-2" (click)="firstImage()"
                  [class.disabled]="isLoading || pageNumber === 1" href="javascript:void(0)">
                  <mat-icon>first_page</mat-icon>
                </a>

                <a [matTooltip]="'Go to Previous Page'" class="ml-2 mt-2" (click)="previousImage()"
                  [class.disabled]="isLoading ||  pageNumber === 1" href="javascript:void(0)">
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </a>

                    <!-- <button [matTooltip]="'Clear All Annotation'" mat-raised-button color="warn"
                        (click)="clearAllAnnotation()"
                        [disabled]="this.textAnnotations.length === 0 || disableAnnotation"><mat-icon>restore</mat-icon></button>
                     <button [matTooltip]="'Zoom In Page'" mat-button color="primary" (click)="zoomIn()"
                        [disabled]="isLoading || disableAnnotation">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button [matTooltip]="'Go to First Page'" mat-button color="primary" (click)="firstImage()"
                        [disabled]="isLoading || disableAnnotation">
                        <mat-icon>first_page</mat-icon>
                    </button>
                    <button [matTooltip]="'Go to Previous Page'" mat-button color="primary" (click)="previousImage()"
                        [disabled]="isLoading || disableAnnotation">
                        <mat-icon>keyboard_arrow_left</mat-icon>
                    </button> -->
                    <input [matTooltip]="'Go to Custom Page'" matInput class="form-control custom-input" type="number"
                        [(ngModel)]="pageNumber" min="1" max="{{totalPages}}" (focusout)="goToPage()"
                        [readonly]="isLoading || disableAnnotation" />

                     <a [matTooltip]="'Go to Next Page'" class="ml-2 mt-2" (click)="nextImage()"
                        [class.disabled]="isLoading || pageNumber === existingDocumentImageList.length"
                        href="javascript:void(0)">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                     </a>
                    <a [matTooltip]="'Go to Last Page'" class="ml-2 mt-2" (click)="lastImage()"
                        [class.disabled]="isLoading || pageNumber === existingDocumentImageList.length"
                        href="javascript:void(0)">
                        <mat-icon>last_page</mat-icon>
                    </a>

                    <span> of {{ totalPages }}</span>

                    <a [matTooltip]="'Save Annotation'" class="ml-2 mt-2" (click)="saveAnnotation()"
                    [class.disabled]="this.textAnnotations.length === 0 || disableAnnotation" href="javascript:void(0)">
                    <mat-icon *ngIf="!disableAnnotation">save</mat-icon>
    
                    <mat-icon *ngIf="disableAnnotation">
                      <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
                    </mat-icon>
                  </a>
                  <!-- <a [matTooltip]="'View Document in PDF'" class="ml-2 pt-1" (click)="viewPatientBlobPDF()"
                  [class.disabled]="isLoading || isEmpty(docID)" href="javascript:void(0)">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </a> -->

                    <!-- <button [matTooltip]="'Go to Next Page'" mat-button color="primary" (click)="nextImage()"
                        [disabled]="isLoading || disableAnnotation">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </button>
                    <button [matTooltip]="'Go to Last Page'" mat-button color="primary" (click)="lastImage()"
                        [disabled]="isLoading || disableAnnotation">
                        <mat-icon>last_page</mat-icon>
                    </button> 

                    <button [matTooltip]="'Zoom Out Page'" mat-button color="primary" (click)="zoomOut()"
                        [disabled]="isLoading || disableAnnotation">
                        <mat-icon>remove</mat-icon>
                    </button> 
                    <button [matTooltip]="'Save Annotation'" class="defaultButton saveButton" mat-raised-button
                        (click)="saveAnnotation()" [disabled]="this.textAnnotations.length === 0 || disableAnnotation">
                        <mat-icon *ngIf="!disableAnnotation">save</mat-icon>

                        <mat-icon *ngIf="disableAnnotation">
                            <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
                        </mat-icon>

                    </button> -->

                    <!-- <div class="button-container">
                    <button [matTooltip]="'Save Annotation'" class="defaultButton saveButton" mat-raised-button
                      (click)="saveAnnotation()" [disabled]="this.textAnnotations.length === 0 || disableAnnotation">
                      <mat-icon *ngIf="disableAnnotation">save</mat-icon>
                      <mat-spinner *ngIf="!disableAnnotation" diameter="30"></mat-spinner>
                    </button>
                  </div> -->

                </div>
            </div>
        </div>

        <div [hidden]="isHideTiffViewer" [ngClass]="{'isHideTiffViewer': isLoading,'d-flex': !isHideTiffViewer && !isLoading}"
            class="d-flex justify-content-center scrollable-card p-0 backgroundclr border-line">
            <div class="scrollable-card-image" [hidden]="disableAnnotation">
                <canvas #canvas (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"
                    (mouseup)="onMouseUp($event)" (mouseleave)="onMouseLeave($event)" (dblclick)="deleteText($event)"
                    (click)="addText($event)"></canvas>
                <input [(ngModel)]="tiffPageNo" type="hidden" name="tiffPageNo" />
            </div>
        </div>

        <div *ngIf="isHideTiffViewer" class="pdf-container pb-4">
            <ng-container *ngIf="showPdfViwer">
                <ngx-extended-pdf-viewer [base64Src]="strPdfString" [height]="pdfheight" [showSidebarButton]="false"
                    [showFindButton]="true" [textLayer]="true" [showPagingButtons]="true" [showOpenFileButton]="false"
                    [showHandToolButton]="false" [showScrollingButton]="false" [showSpreadButton]="false"
                    [showPropertiesButton]="false" [showBookmarkButton]="false" [zoom]="'page-width'"
                    [enableDragAndDrop]="false">
                </ngx-extended-pdf-viewer>
            </ng-container>
        </div>

        <!-- <div class="card card-body align-items-center justify-content-center scrollable-card p-0" style="height: 83vh">
            <div class="scrollable-card-image">
                <canvas #canvas (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"
                    (mouseup)="onMouseUp($event)" (mouseleave)="onMouseLeave($event)" (contextmenu)="deleteText($event)"
                    (click)="addText($event)"></canvas>
                <input [(ngModel)]="tiffPageNo" type="hidden" name="tiffPageNo" />
            </div>
        </div> -->
    </div>

 <!-- #region Tiff Preview Viewer-->
 <div class="col-1 p-0" [hidden]="isHideTiffViewer">
    <div class="card card-body align-items-center scrollable-card p-0" style="height: 85vh">
        <ng-container *ngFor="let image of existingDocumentImageList">
            <div class="card my-2 elevated-div custom-thumbnail-view">
                <img class="card-img-top img-fluid custom-thumbnail-image" (click)="loadExistingImage(image)"
                    [ngClass]="{
            'custom-thumbnail-view-selected':
              selectedExistingDocument?.pageNo === image?.pageNo
          }" [src]="image?.src" [alt]="image?.pageNo" />
                <!-- <p class="align-end">Page No : {{ image.pageNo }}</p> -->
            </div>
        </ng-container>
    </div>
</div>

</div>
<!-- </mat-dialog-content> -->
<app-loader-component *ngIf="isOrderPageLoading"></app-loader-component>
