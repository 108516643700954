<div class="row">
  <div class="col-lg-6">
    <h5 class="customThemeClass"><b>Permissions</b></h5>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-12">
      <mat-card-content>
        <form [formGroup]="executiveRolespermissionForm">
          <div class="row">
            <div class="col-12">
              <div class="example-action-buttons">
                <button mat-button (click)="accordion.openAll()">Expand All</button>
                <button mat-button (click)="accordion.closeAll()">Collapse All</button> &nbsp;
                <mat-checkbox (change)="selectAll($event)">Select All</mat-checkbox>
              </div>
            </div>
            <div class="col-12">
              <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel *ngIf = 'tenantUserHide'>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Identity Management </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="col-6">
                    <p>Role Management</p>
                  </div>
                  <div class="px-3 col-6">
                    <mat-checkbox (change)="selectAllRoles($event)">Select All Permission for Roles </mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkRoleCreate">Create Roles </mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkRoleEdit">Edit Roles</mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkRoleView">View Roles</mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkRoleDelete">Delete Roles</mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkRoleManagePermissions"
                      >Manage Permissions
                    </mat-checkbox>
                  </div>
                  <div class="col-6">
                    <p>Users Management</p>
                  </div>
                  <div class="px-3 col-6">
                    <mat-checkbox (change)="selectAllUser($event)">Select All Permission for Users </mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkUsersCreate">Create Users </mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkUsersEdit">Edit Users</mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkUsersView">View Users</mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkUsersDelete">Delete Users</mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkUsersManagePermissions"
                      >Manage Permissions</mat-checkbox
                    >
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf = 'tenantUserHide'>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Client Management </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="px-3 col-6">
                    <mat-checkbox (change)="selectAllClient($event)">Select All Permission for Clients </mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkTenantCreate">Create Clients</mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkTenantEdit">Edit Clients</mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkTenantView">View Clients</mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkTenantDelete">Delete Clients</mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkTenantManageFeatures"
                      >Manage Feautures</mat-checkbox
                    >
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel  *ngIf = 'adminHide && tenantUserHide'>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Branch Management </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="px-3 col-6">
                    <mat-checkbox (change)="selectAllBranch($event)">Select All Permission for Organization </mat-checkbox>
                  </div>

                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkOrganizationCreate"
                      >Create Organization</mat-checkbox
                    >
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkOrganizationEdit"
                      >Edit Organization</mat-checkbox
                    >
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkOrganizationDelete"
                      >Delete Organization
                    </mat-checkbox>
                  </div>
                  <div class="px-5 col-6">
                    <mat-checkbox formControlName="chkOrganizationView"
                      >View Organization</mat-checkbox
                    >
                  </div>
                </mat-expansion-panel>
                <br />
              </mat-accordion>
            </div>
          </div>
        </form>
      </mat-card-content>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="buttonColor" (click)="savePermission()">Save</button>
  <button mat-button class="resetclr">Reset</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
