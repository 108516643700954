import type { CreateUpdateMailTemplateDetailsDTO, MailTemplateDetailsDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MailTemplateDetailsService {
  apiName = 'platformManagement';

  create = (input: CreateUpdateMailTemplateDetailsDTO) =>
    this.restService.request<any, MailTemplateDetailsDTO>({
      method: 'POST',
      url: '/api/PlatformApp/mail-template-details',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/PlatformApp/mail-template-details/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, MailTemplateDetailsDTO>({
      method: 'GET',
      url: `/api/PlatformApp/mail-template-details/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MailTemplateDetailsDTO>>({
      method: 'GET',
      url: '/api/PlatformApp/mail-template-details',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getMailTemplateByBlobIDByBlobName = (blobName: string) =>
    this.restService.request<any, number[]>({
      method: 'GET',
      url: '/api/PlatformApp/mail-template-details/mail-template-by-blob-iD',
      params: { blobName },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateMailTemplateDetailsDTO) =>
    this.restService.request<any, MailTemplateDetailsDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/mail-template-details/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
