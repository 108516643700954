<div class="container-fluid">
    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
                <div class="page-title-box page-title-left pb-0">
                    <h3 class="font-weight-bolder mb-3">
                        <b class="customThemeClass">Master / Physician List</b>
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-body">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-center" *ngIf="isTableLoading">
                    <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                </div>
                <div class="container-fluid">
                    <app-master-doctor-table *ngIf="!isTableLoading" (nevigatePage)="changePage($event)" [doctorTableData]="doctorTableData" [visibleColumns]="visibleColumns" [currentPage]="currentPage" [totalPages]="totalPages"></app-master-doctor-table>
                </div>
            </div>
        </div>
    </div>
</div>