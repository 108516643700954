import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AddInboundDocumentsTabComponent } from '../add-inbound-documents-tab/add-inbound-documents-tab.component';
import { MatDialog } from '@angular/material/dialog';
import { AssignDocumentModalComponent } from '../assign-document-modal/assign-document-modal.component';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import {
  CreateUpdateInboundDocumentDTO,
  InboundDocumentDTO,
} from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import { inboundDocumentStatus } from '../admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/inbound-document-status.enum';
import { ToastrService } from 'ngx-toastr';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { FlatTreeControl } from '@angular/cdk/tree';
import moment, { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Observable, Subscription } from 'rxjs';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';

interface FoodNode {
  name: string;
  children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'DWF (15)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'NC (32)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'Houseton (42)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'Ohio (16)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'Austin (32)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'Florida (16)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
  {
    name: 'Remote Services (41)',
    children: [
      { name: 'UnProcessed (10)' },
      { name: 'Fax Issues (5)' },
      { name: 'Confirmation (6)' },
      { name: 'Verification (9)' },
      { name: 'Precert (15)' },
      {
        name: 'Medicare',
        children: [{ name: 'Verbal Approvals' }],
      },
    ],
  },
];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}
@Component({
  selector: 'app-inbound-tab',
  templateUrl: './inbound-tab.component.html',
  styleUrls: ['./inbound-tab.component.scss'],
  providers: [DatePipe],
})
export class InboundTabComponent implements OnInit {
  inboundSearchForm: FormGroup;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild('inputElement') inputElementRef: ElementRef;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  isShowSpinner: boolean = false;
  editPatientCount: string;
  inboundDetails: CreateUpdateInboundDocumentDTO;
  displayedColumns = [
    //'options',
    'documentId',
    'isStat',
    'documentName',
    'docPageCount',
    'branch',
    'faxNumber',
    'faxName',
    //'patientCount',
    //'uploadedType',
    //'uploadedBy',
    'uploadedDate',
    'faxId',
  ];
  selectedRange: { startDate: Moment; endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  fromDate: any;
  toDate: any;
  selectedOption = 'option1';
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';
  alwaysShowCalendars: boolean;
  dateRangStatus: boolean = false;
  isNewPatient: boolean = false;

  private transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  treeDatasource = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener
  );

  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchValue: string[] = [];
  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  tenantId: string = '';
  branchForm: FormGroup;
  organizationUnitName: string;
  subscription$: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private inboundDocumentService: InboundDocumentService,
    private dateValidator: DateValidator,
    private branchService : OrganizationUnitService
  ) {
    this.treeDatasource.data = TREE_DATA;
    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Current week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      //'Next weekend': [this.getNextSaturday(), this.getNextSunday()],
      //'Next 3 days': [moment().add(1, 'days'), moment().add(3, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      // 'Current day': [moment(), moment()],
      // 'Next 2 days': [moment().add(1, 'days'), moment().add(2, 'days')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
  }
  ngOnInit(): void {
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.initializeForms();
    this.getInboundDocsList();

    this.branchForm = this.formBuilder.group({
      drpBranch : new FormControl(''),
      txtBranch : new FormControl(''),
    });

    this.getBranchList();
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  //To Initialize Forms
  initializeForms() {
    this.inboundSearchForm = this.formBuilder.group({
      faxId: new FormControl(''),
      documentId: new FormControl(''),
      branch: new FormControl(''),
      documentName: new FormControl(''),
      faxNumber: new FormControl(''),
      faxName: new FormControl(''),
      //patientCount: new FormControl(''),
      //uploadedType: new FormControl(''),
      //uploadedBy: new FormControl(''),
      uploadedDate: new FormControl('', this.dateValidator.dateVaidator),
    });
  }
  getInboundDocsList() {
    const valueChanges = this.inboundSearchForm.valueChanges
      .pipe(
        startWith({
          faxId: '',
          documentId: '',
          documentName: '',
          faxNumber: '',
          faxName: '',
          // patientCount: '',
          // uploadedType: '',
          // uploadedBy: '',
          uploadedDate: '',
          fromDate:this.fromDate,
          toDate:this.toDate,
        }),
        tap((x) => {
          this.isShowSpinner = true;
          this.setTableData([]);
        }),
        filter((e) => e && this.inboundSearchForm?.valid),
        debounceTime(300),
        map((response) => {
          this.isShowSpinner = true;
          const sValue = {
            faxId: response?.faxId?.trim()?.toLowerCase(),
            documentId: response?.documentId?.trim()?.toLowerCase(),
            documentName: response?.documentName?.trim()?.toLowerCase(),
            faxNumber: response?.faxNumber?.trim()?.toLowerCase(),
            faxName: response?.faxName?.trim()?.toLowerCase(),
            uploadedDate: response?.uploadedDate?.trim()?.toLowerCase(),
            fromDate:response?.fromDate?.trim().toLowerCase(),
            toDate:response?.toDate?.trim().toLowerCase(),
          };

          return sValue;
        }),
        switchMap((sValue) =>
          this.inboundDocumentService.getInboundDocsList(
            sValue?.documentId,
            sValue?.faxId,
            sValue?.documentName,
            '',
            '',
            sValue?.uploadedDate,
            '',
            '',
            '',
            '',
            '',
            '',
            sValue.fromDate,
            sValue.toDate,
            '',
            sValue?.faxNumber,
            sValue?.faxName
          )
        )
      )
      .subscribe(
        (response) => {
          this.isShowSpinner = false;
          this.setTableData(response);
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'error',
          //   text: data?.error?.error?.message,
          // });
        }
      );
  }


  fnEditPatientCount(docId: string) {
    this.editPatientCount = docId;
  }
  // fnUpdatePatientCount(docId: string) {
  //   this.editPatientCount = null;
  //   const patientCount = this.inputElementRef.nativeElement.value;
  //   let inboundDetails: CreateUpdateInboundDocumentDTO;
  //   this.inboundDocumentService.get(docId).subscribe((response) => {
  //     inboundDetails = response;
  //     var ptCount: number = +patientCount;
  //     inboundDetails.patientCount = +ptCount;
  //   });
  //   setTimeout(() => {
  //     this.inboundDocumentService.update(docId, inboundDetails).subscribe(
  //       (response) => {

  //         this.getInboundDocsList();
  //         this.toastr.success('Patient Count Updated');
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   }, 200);
  // }

  //Open Document Allocation Popup

  async fnUpdatePatientCount(docId: string) {
    this.editPatientCount = null;
    const patientCount = this.inputElementRef.nativeElement.value;
    //let inboundDetails: CreateUpdateInboundDocumentDTO;

    // this.inboundDocumentService.get(docId).subscribe((response) => {
    //   inboundDetails = response;
    //   var ptCount: number = +patientCount;
    //   inboundDetails.patientCount = +ptCount;
    // });
    await this.fnGetPatientDetails(docId, patientCount);

    setTimeout(() => {
      this.inboundDocumentService.update(docId, this.inboundDetails).subscribe(
        (response) => {
          this.isShowSpinner = false;
          this.getInboundDocsList();
          this.toastr.success('Patient Count Updated');
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }, 500);
  }

  fnGetPatientDetails(docId: string, patientCount: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.editPatientCount = null;
      this.inboundDocumentService.get(docId).subscribe((response) => {
        this.inboundDetails = response;
        var ptCount: number = +patientCount;
        this.inboundDetails.patientCount = +ptCount;
      });
      resolve();
    });
  }

  openPopup(docId: string, blobName: string, defaultDocumentId: string) {
    const dialogRef = this.dialog.open(AssignDocumentModalComponent, {
      // minHeight: '33vh',
      // minWidth: '67vw',
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        documentId: docId,
        blobName: blobName,
        defaultDocumentId: defaultDocumentId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {
      this.getInboundDocsList();
    });
  }
  setTableData(data: any) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.sort.disableClear = true;
    this.dataSource.paginator = this.paginator;
  }

  //To approve the document processed
  approveDocument(documentId: string) {
    Swal.fire({
      title: 'Are you sure you want Process the Document?',
      text: 'Approve!',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.value) {
        const approveDocument = this.inboundDocumentService
          .approveInboundDocumentByGInboundDocumentId(documentId)
          .subscribe((response) => {
            this.getInboundDocsList();
            Swal.fire({
              icon: 'success',
              text: 'Inbound Process Completed',
            });
          });
      }
    });
  }

  //To add inbound documents manually
  addInboundDocuments() {
    const dialogRef = this.dialog.open(AddInboundDocumentsTabComponent, {
      minHeight: '33vh',
      minWidth: '52vw',
    });

    dialogRef.afterClosed().subscribe(
      () => {
        this.getInboundDocsList();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //Redirect Page to Processed Documents
  redirectToProcessedDocuments() {
    this.router.navigate(['processedDocuments']);
  }

  onCheckboxChange(element: any) {
    element.isStat = element.isStat === 1 ? 0 : 1;
    // Make API call here
    this.inboundDocumentService.STATupdate(element?.docId).subscribe(
      (value) => {
        this.toastr.success('STAT Updated Successfully', 'Success');
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.getInboundDocsList();
        this.toastr.success(data?.error?.text);
      }
    );
  }

  redirectToDocumentAssign(blobName: string, documentId: string) {
    this.router.navigate([
      'assignDocumentTab' + '/' + documentId + '/' + blobName,
    ]);
  }



  getBranchList(){
    const getBranchList = this.branchService.getBranchList(this.tenantId).subscribe(response => {
      response?.forEach(element => {
        this.drpBranch.push({
          id: element.id,
          organizationUnitName: element.organizationUnitName,
        })
      });


      this.brnachList = this.branchForm
        ?.get('txtBranch')
        ?.valueChanges?.pipe(
          startWith(''),
          map((value) =>
            this.drpBranch?.filter((option) =>
              option?.organizationUnitName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );


    }, err =>{
      const data : HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getBranchList);
  }

  allBranchSelect(){
    if(this.selectAllBranch.selected){
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    }
    else{
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }
  datesUpdateds(e) {
    if (e.startDate != null || e.endDate != null) {
      this.dateRangStatus = true;
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
      this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
      this.getInboundDocsList();
    } else {
      this.dateRangStatus = false;
      (this.fromDate = null), (this.toDate = null);
    }
  }
  enableFilter(){
    let Filter = <HTMLButtonElement> document.getElementById('basic-addon1');
    Filter.classList.remove("disabled");
    Filter.disabled = true;
    let dateRange = <HTMLButtonElement> document.getElementById('calendar-input');
    dateRange.style.display="block";
    this.selectedRange=null;
  }
  disableFilter(){
    let Filter = <HTMLButtonElement> document.getElementById('basic-addon1');
    Filter.classList.add("disabled");
    Filter.disabled = false;
    let dateRange = <HTMLButtonElement> document.getElementById('calendar-input');
    dateRange.style.display="none";
    this.fromDate='';
    this.toDate='';
    this.getInboundDocsList();
  }

}

export interface Branch{
  id?: string;
  organizationUnitName?: string;
}
