<!-- Component Start -->
<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <!-- Nav Bar Start -->
      <div class="d-flex align-item-center justify-content-between m-0">
        <h4 class="mt-2 mb-0 font-size-18"><b>Master /Timer</b></h4>
        <div class="page-title-box page-title-right mt-1 mb-0">
          <ol class="breadcrumbs m-0">
            <!-- <li> -->

            <button
              class="mr-2 mt-0 buttonColor"
              mat-raised-button
              [disabled]="
                dataSource && dataSource?.data && dataSource?.data?.length === 0
              "
              (click)="
                exporter.exportTable('xlsx', {
                  fileName: 'Color Codes',
                  sheet: 'Color Codes',
                  Props: { Author: 'QSecure' }
                })
              "
            >
              Export
            </button>
          </ol>
        </div>
      </div>
      <!-- Nav End Start -->
    </div>
  </div>
  <div class="row">
    <div class="col-6" style="margin-bottom: -32px">
      <div class="card card-body">
        <!-- Accordion Form Start -->
        <mat-accordion>
          <mat-expansion-panel
            (toggle)="(step)"
            [expanded]="step"
            (afterExpand)="toggle()"
            (closed)="toggleV2()"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="customThemeClass">
                <ng-container
                  *ngIf="colorCodeId === defaultGuid; else elseTitleTemplate"
                >
                  <!--*ngIf="mstModifierId === ''; else elseTitleTemplate" -->
                  <b>Add Timer</b>
                </ng-container>
                <ng-template #elseTitleTemplate>
                  <b>Edit Timer</b>
                </ng-template>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="AppontmentForm">
              <div class="">
                <mat-card-content>
                  <div class="row">
                    <div class="col-5 time">
                      <mat-label>
                        Timer <span class="asterisk">*</span></mat-label
                      >
                      <div class="ngx-timepicker-field-example">
                        <ngx-timepicker-field
                          [disabled]="durationShow"
                          formControlName="txtDuration"
                        >
                        </ngx-timepicker-field>
                      </div>
                    </div>
                    <mat-form-field class="col-6 m-1">
                      <mat-label
                        >Worker<span class="asterisk">*</span></mat-label
                      >
                      <input
                        matInput
                        class="worker"
                        style="font-weight: 700"
                        formControlName="txtWorker"
                        [disabled]="false"
                      />
                    </mat-form-field>
                  </div>
                </mat-card-content>
              </div>
            </form>
            <br />
            <div class="col-sm-12">
              <div class="text-lg-center">
                <button
                  mat-button
                  class="buttonColor mb-2 mr-2"
                  [disabled]="
                    saveButtonHide ||
                    !(!AppontmentForm.invalid && AppontmentForm.dirty)
                  "
                  (click)="saveStatusColorCode()"
                >
                  Save
                  <mat-icon *ngIf="saveButtonHide">
                    <mat-spinner
                      class="spinner-border spinner-border-sm"
                      diameter="20"
                    >
                    </mat-spinner>
                  </mat-icon>
                </button>
                <button
                  mat-button
                  class="resetclr btn btn-danger mb-2 mr-2"
                  (click)="resetColorCodes()"
                >
                  Reset
                </button>
              </div>
            </div>
            <br />
          </mat-expansion-panel>
        </mat-accordion>
        <!-- Accordion Form End -->
      </div>
    </div>
    <br />
    <div class="col-6">
      <div class="card card-body">
        <div class="col-12 pt-2">
          <h4 class="customThemeClass ml-3 mt-2"><b>Master Timer</b></h4>
          <div class="tabspace example-container mat-elevation-z4">
            <!-- Table Start -->

            <table
              mat-table
              class="table"
              matTableExporter
              #exporter="matTableExporter"
              [hiddenColumns]="[3]"
              matSort
              [dataSource]="dataSource"
              class="mat-elevation-z8"
            >
              <ng-container matColumnDef="sNo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No</th>
                <td mat-cell *matCellDef="let data">
                  {{ data.sNo }}
                </td>
              </ng-container>
              <ng-container matColumnDef="Timer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Timer</th>
                <td mat-cell *matCellDef="let data">
                  <ng-container>
                    <span>
                      <span class="mat-ripple mat-mdc-button-ripple"></span>
                      1
                    </span>
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="Worker">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Worker
                </th>
                <td mat-cell *matCellDef="let data">
                  <ng-container>
                    <span>
                      <span class="mat-ripple mat-mdc-button-ripple"></span>
                      -
                    </span>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="creationTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Created Date
                </th>
                <td mat-cell *matCellDef="let data">
                  <ng-container>
                    {{ data?.creationTime | date : "MM/dd/yyyy" : "en_US" }}
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef>Options</th>
                <td mat-cell *matCellDef="let data">
                  <a class="editBtnColor" (click)="viewColorCode(data?.id)">
                    <i
                      class="fa fa-pencil editBtnColor mr-1"
                      matTooltip="Click To Edit"
                    ></i>
                  </a>
                  &nbsp;
                  <a class="deleteBtnColor" (click)="deleteColorCode(data?.id)">
                    <i class="fa fa-trash-o" matTooltip="Click To Delete"></i>
                  </a>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div
              *ngIf="
                !(
                  dataSource &&
                  dataSource?.data &&
                  dataSource?.data?.length !== 0
                )
              "
              class="text-align-center"
            >
              <div class="message py-3" *ngIf="!isShowSpinner">
                {{ "No Records Found" }}
              </div>
              <div class="py-3" [hidden]="!isShowSpinner">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </div>
            <mat-paginator
              [pageSizeOptions]="[10, 20, 50, 100]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
          <!-- Table End -->
        </div>
      </div>
    </div>
  </div>
  <!-- Component End -->
</div>
