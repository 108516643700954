import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import Swal from 'sweetalert2';

import { AddDocumentBinComponent } from '../add-document-bin/add-document-bin.component';
import { FileUploadService } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload';
import { FileUploadsWithBatchIdDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/dto';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import { IdentityUserDto } from '../admin-proxy/platform-app-management/volo/abp/identity/models';

@Component({
  selector: 'app-sub-batch-list',
  templateUrl: './sub-batch-list.component.html',
  styleUrls: ['./sub-batch-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
})
export class SubBatchListComponent implements OnInit {
  isLoading: boolean = true;
  strPageType: string = 'subBatchList';
  organizationUnitId: string = '';
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  expandedElement: any | null;
  arrUsersList: IdentityUserDto[] = [];
  tenantId: string = "";
  arrDisplayedColumns: string[] = [];
  arrTableData: any[] = [];
  batchId: string = "";
  chkdisableButton: boolean;
  isShownSaveButton: boolean = false;
  isShownCreateButton: boolean = false;
  isShownDeleteButton: boolean = false;
  isShownViewButton: boolean = false;
  subBatchUploadBinForm: FormGroup;

  constructor(
    private title: Title,
    private table: TableService,
    public userService: UserService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public dateValidators: DateValidator,
    private fileUploadService: FileUploadService,
    private activatedRoute: ActivatedRoute,
  ) { }
  // ! Page Initialization Function

  ngOnInit(): void {
    this.arrDisplayedColumns = [ 'Options','batchNo', 'stat', 'documentName', 'assignedBy', 'assignedTo', 'assignedTime', 'uploadedFileStatus',];

    this.title.setTitle('Qsecure | Sub Batch');
    this.activatedRoute.paramMap.subscribe(response => {
      this.batchId = response.get('id') ?? "";
      this.getUsersList();
    })
    this.table.getfileUploadUpdate().subscribe(value => {
      if (value == true) {
        this.isShownSaveButton = true;
      }
      else {
        this.isShownSaveButton = false;
      }
    });

    this.table.getfileUploadDelete().subscribe(value => {
      if (value == true) {
        this.isShownDeleteButton = true;
      }
      else {
        this.isShownDeleteButton = false;
      }
    });

    this.table.getfileUploadView().subscribe(value => {
      if (value == true) {
        this.isShownViewButton = true;
      }
      else {
        this.isShownViewButton = false;
      }
    });


    this.subBatchUploadBinForm = this.fb.group({
      batchId: new FormControl(""),
      documentName: new FormControl(""),
      assignedBy: new FormControl(""),
      assignedTo: new FormControl(""),
      assignedTime: new FormControl("", this.dateValidators.dateVaidator),
      uploadedFileStatus: new FormControl(""),
    })
    this.subBatchUploadBinForm.valueChanges.subscribe(value => {

      if (this.subBatchUploadBinForm.valid && this.batchId !== "" && this.batchId !== undefined && this.batchId !== null) {
        const sDefaultBatchId: string = this.subBatchUploadBinForm.value.batchId ?? "";
        const documentName: string = this.subBatchUploadBinForm.value.documentName ?? "";
        const assignedBy: string = this.subBatchUploadBinForm.value.assignedBy ?? "";
        const assignedTo: string = this.subBatchUploadBinForm.value.assignedTo ?? "";
        const assignedTime: string = this.subBatchUploadBinForm.value.assignedTime ?? "";
        const uploadedFileStatus: string = this.subBatchUploadBinForm.value.uploadedFileStatus ?? "";
        this.searchByInputs(this.batchId, sDefaultBatchId,
          documentName,
          assignedTo,
          assignedBy,
          assignedTime,
          uploadedFileStatus);
      }
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })

  }
  // ! Get Sub Batch Details By Search Values
  searchByInputs(batchId: string, sDefaultBatchId: string, sFileName: string, sAssignedTo: string, sAssignedBy: string, dtAssignedTime: string, sStatus: string) {
    this.fileUploadService.searchFilesInBatchByBatchIdAndSDefaultBatchIdAndSFileNameAndSAssignedToAndSAssignedByAndDtAssignedTimeAndSStatus(batchId,
      sDefaultBatchId,
      sFileName,
      sAssignedTo,
      sAssignedBy,
      dateYYYYMMDDtoMMDDYYYY(dtAssignedTime),
      sStatus).subscribe(response => {

        this.arrTableData = [];
        this.dataSource = new MatTableDataSource(this.arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        let arrTableData: subBatchDetails[] = [];
        const responseBatchData: FileUploadsWithBatchIdDTO[] = response;
        arrTableData = this.extractSubBatchTableDetails(responseBatchData);
        this.arrTableData = [...arrTableData];
        this.dataSource = new MatTableDataSource(this.arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.dataSource = new MatTableDataSource(this.arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });

  }

  // ! Extract Sub Batch Table Details
  private extractSubBatchTableDetails(batchDataTable: FileUploadsWithBatchIdDTO[]): subBatchDetails[] {
    let arrTableData: subBatchDetails[] = [];
    try {
      if (batchDataTable && batchDataTable !== [] && batchDataTable?.length !== 0 && batchDataTable !== null) {
        const listFileUploads = batchDataTable;
        listFileUploads.forEach(element => {
          const data: subBatchDetails = {
            defaultBatchId: element?.defaultBatchId ?? "",
            fileId: element?.fileId ?? "",
            uploadedFileName: element?.uploadedFileName ?? "",
            isStat: element?.isStat === 1 ? true : false,
            blobName: element?.blobName ?? "",
            uploadedFileStatus: element?.uploadedFileStatus ?? "",
            assignedTo: this.getUserName(element?.assignedTo ?? "") ?? "",
            assignedTime: dateFormatter(element?.assignedTime ?? "") ?? "",
            assignedBy: this.getUserName(element?.assignedBy ?? "") ?? "",
            updatedBy: this.getUserName(element?.updatedBy ?? "") ?? "",
            updatedOn: dateFormatter(element?.updatedOn ?? "") ?? "",
            reasonForNotExtracted:element?.reasonForNotExtracted??"",
          };
          arrTableData.push(data);
        });
      }
    } catch (error) {
      arrTableData = [];
    }
    return arrTableData;
  }

  // ! Get  Users Name
  private getUserName(value: string) {
    if (value && typeof value === "string" && value?.length >= 36) {
      return this.arrUsersList?.filter(u => u?.id + '/' + u?.userName === value)[0]?.userName ?? "";
    }
    return ""
  }
  // ! Get  Users List
  private getUsersList() {
    this.isLoading = true
    this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;
      this.batchId !== "" && this.batchId !== undefined && this.batchId !== null && this.getbatchTableData();
      this.isLoading = false;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }



  // ! Get Sub Batch Inital Table Details
  getbatchTableData() {
    this.searchByInputs(this.batchId, "", "", "", "", "", "");
  }




  // ! Update Single File Details
  update(value: string) {
    const dialogRef = this.dialog.open(AddDocumentBinComponent, {
      data: { batchId: this.batchId, fileId: value },
      minHeight: '40vh',
      minWidth: '60vw',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getbatchTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  // ! Delete Single File Details
  deleteFileByID(value: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.value) {
        value && this.fileUploadService.deleteFileByBatchIdAndFileId(this.batchId, value).subscribe(response => {
          this.getbatchTableData();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }
}
export interface subBatchDetails {
  defaultBatchId: string;
  fileId?: string;
  uploadedFileName: string;
  isStat: boolean;
  blobName: string;
  uploadedFileStatus: string;
  assignedTo: string;
  assignedTime: string;
  assignedBy: string;
  updatedOn: string;
  updatedBy: string;
  reasonForNotExtracted?: string;
}
export function dateFormatter(strDate: string): string {
  if (typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit', hour: "2-digit", minute: "2-digit" };
    const latest_date = new Intl.DateTimeFormat('en-US', options).format(new Date(strDate));
    return latest_date;
  }
  return "";
}
export function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    const latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}
