import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DocumentsService } from 'projects/patient/src/app/patient-proxy/controllers';
import { DocumentService, PatientSearchService } from 'projects/patient/src/app/patient-proxy/patient';
import { baseUrl } from 'projects/patient/src/environments/environment';
import { UploadFileStatus } from 'projects/shared/src/app/enums/allenums.enum';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { AutoIntakeDocumentDetailsDTO, PatientDTO } from '../admin-proxy/platform-app-management/rcm/patient-management/patient/dto/models';
import { DocumentUploadService, FileUploadService } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload';
import { BatchDocumentUploadsDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/dto/models';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { OrganizationUnitDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { IdentityUserDto } from '../admin-proxy/volo/abp/identity';
import { appprovalDetails, Patient } from '../approval-list/approval-list.component';
import { ExtractionBotModalComponent } from '../extraction-bot-modal/extraction-bot-modal.component';

@Component({
  selector: 'app-approval-bot-list',
  templateUrl: './approval-bot-list.component.html',
  styleUrls: ['./approval-bot-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ])
  ],
})
export class ApprovalBotListComponent implements OnInit {
  step: boolean = false;
  isLoading: boolean = true;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  patientData: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) tableRef: MatTable<any>;
  CreateNewPatientWindow: any = 0;
  @ViewChild(MatSort, { static: true }) Patientsort: MatSort;
  @ViewChild(MatPaginator, { static: true }) patientPaginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) PatienttableRef: MatTable<any>;
  displayedColumns: string[] = [
    'Name',
    'Patient Id',
    'Email Address',
    'Date Of Birth',
    'LockStatus',
  ];

  expandedElement: any | null;
  arrUsersList: IdentityUserDto[] = [];
  tenantId: string = '';
  arrDisplayedColumns: string[] = [];
  PATIENTDATA: Patient[] = [];

  arrTableData: any[] = [];
  batchId: string = '';
  chkdisableButton: boolean;
  addButtonDisable: boolean = false;
  branchListData: OrganizationUnitDTO[] = [];
  strSelectedPdfPath: string = '';
  approvalForm: FormGroup;
  subscription$: Subscription[] = [];

  constructor(
    private fileUploadService: FileUploadService,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private store: Store,
    private documentService: DocumentService,
    private documentsService: DocumentsService,
    private dialog: MatDialog,
    private branchService: OrganizationUnitService,
    private dateValidator: DateValidator,
    private formBuilder: FormBuilder,
    private patientSearchService: PatientSearchService,
    private router: Router,
    private documentUploadService: DocumentUploadService

  ) { }

  ngOnInit(): void {
    this.approvalForm = this.formBuilder.group({
      Name: '',
      PatientId: '',
      Address: '',
      SSN: '',
      AccountNumber: '',
      EmailAddress: '',
      DateOfBirth: new FormControl('', this.dateValidator.dateVaidator),
      Moblie: '',
      LockStatus: '',
      currStatus:''
    });
    const valueChanges: Subscription = this.approvalForm.valueChanges.subscribe(
      (value: Patient) => {
        let filter: Patient = {
          PatientId: value?.PatientId?.trim()?.toLowerCase(),
          Name: value?.Name?.trim()?.toLowerCase(),
          Address: value?.Address?.trim()?.toLowerCase(),
          SSN: value?.SSN?.trim()?.toLowerCase(),
          AccountNumber: value?.AccountNumber?.trim()?.toLowerCase(),
          EmailAddress: value?.EmailAddress?.trim()?.toLowerCase(),
          DateOfBirth: value?.DateOfBirth?.trim()?.toLowerCase(),
          Moblie: value?.Moblie?.trim()?.toLowerCase(),
          LockStatus: value?.LockStatus?.trim()?.toLowerCase(),
          currStatus: value ?.currStatus?.trim()?.toLowerCase(),
        };
        if (this.approvalForm.valid) {
          this.patientData.filter = JSON.stringify(filter);
          this.filtertable(filter);
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.arrDisplayedColumns = [
    
      'batchNo',
      'stat',
      'documentName',
      'status',  'Options',
    
    ];
    this.tenantId = localStorage.getItem('tenantId') ?? '';

    this.activatedRoute.paramMap.subscribe((response) => {
      this.batchId = response.get('id') ?? '';
    });
    this.title.setTitle('Qsecure | Approval List');
    this.batchId !== '' &&
      this.batchId !== undefined &&
      this.batchId !== null &&
      this.getbatchTableData();

    this.tenantId !== '' &&
      this.tenantId !== undefined &&
      this.tenantId !== null &&
      this.getBranchTable();
  }
  //! filter patient by inputs
  filtertable(filter: Patient) {
    let PatientName = filter?.Name;
    let DefaultPatientId = filter?.PatientId;
    let SSN = filter?.SSN;
    let AccountNumber = filter?.AccountNumber;
    let EmailId = filter?.EmailAddress;
    let Mobile = filter?.Moblie;
    let Fax = '';
    let Address = filter?.Address;
    let dobDay = filter?.DateOfBirth;
    let lockStatus = filter?.LockStatus;
    let currStatus = filter?.currStatus
    this.PATIENTDATA = [];

    this.patientSearchService
      .searchPatientBySPatientNameAndSDefaultPatientIdAndSSSNAndSAccountNumberAndSEmailIdAndSMobileAndSFaxAndSAddressAndDtDOBAndSLockStatusAndSSearchNameAndSCurrentStatus(
        PatientName,
        DefaultPatientId,
        SSN,
        AccountNumber,
        EmailId,
        Mobile,
        Fax,
        Address,
        dobDay,
        lockStatus,
        'uploadSearch',
        currStatus
      )
      .subscribe(
        (data) => {
          this.PATIENTDATA = [];
          data &&
            data?.items?.forEach((element) => {
              if (
                element &&
                element?.addresses !== [] &&
                element?.addresses?.length !== 0
              ) {
                const lastDocumentBlobDetails: AutoIntakeDocumentDetailsDTO =
                  element?.autoIntakeDocumentDetails?.slice(
                    element?.autoIntakeDocumentDetails?.length - 1,
                    element?.autoIntakeDocumentDetails?.length
                  )[0] || null;
                const dateOfBirth = element?.personals?.dateOfBirth === null ? '' : Intl.DateTimeFormat('en-US').format(new Date(element?.personals?.dateOfBirth));
                this.PATIENTDATA.push({
                  id: element?.id,
                  PatientId: element?.defaultPatientId?.toString(),
                  Address:
                    element?.addresses[0]?.address === null || undefined
                      ? ''
                      : element?.addresses[0]?.address,
                  Name:
                    element?.personals?.firstName +
                    ' ' +
                    element?.personals?.middleName +
                    ' ' +
                    element?.personals?.lastName,
                  DateOfBirth: dateOfBirth,
                  SSN:
                    element?.personals?.ssn === null || undefined
                      ? ''
                      : element?.personals?.ssn,
                  AccountNumber:
                    element?.personals?.accountNumber === null || undefined
                      ? ''
                      : element?.personals?.accountNumber,
                  EmailAddress:
                    element?.personals?.emailId === null || undefined
                      ? ''
                      : element?.personals?.emailId,
                  Moblie:
                    element?.addresses[0]?.mobile === null || undefined
                      ? ''
                      : element?.addresses[0]?.mobile,
                  isAutoIntake: element?.isAutoIntake,
                  autoIntakeDocumentDetails: lastDocumentBlobDetails ?? null,
                  LockStatus: element?.patientLock?.status,
                  LockStatusWithName:
                    element?.patientLock?.status,
                    currStatus: element?.currentStatus
                });

              }
              this.patientData = new MatTableDataSource(this.PATIENTDATA);
              this.patientData.sort = this.Patientsort;
              this.patientData.paginator = this.patientPaginator;
            });
          // this.patientData = ne*w MatTableDataSource(this.PATIENTDATA);
          // this.patientData.sort = this.Patientsort;
          // this.patientData.paginator = this.patientPaginator;
        },
        (err) => {
          this.patientData = new MatTableDataSource(this.PATIENTDATA);
          this.patientData.sort = this.Patientsort;
          this.patientData.paginator = this.patientPaginator;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );

    this.patientData = new MatTableDataSource(this.PATIENTDATA);
    this.patientData.sort = this.Patientsort;
    this.patientData.paginator = this.patientPaginator;
  }

  //! preview docuemt By  Blob Name
  previewDocumentById(lastAutoDocumentBlob: AutoIntakeDocumentDetailsDTO) {
    lastAutoDocumentBlob?.blobName && lastAutoDocumentBlob && window.open(baseUrl + '#/ViewDocumentPerviewByBlobId/' + lastAutoDocumentBlob?.blobName + '/' + 1, "_blank");
  }
  //! Get Branch List Details

  getBranchTable() {
    this.branchListData = [];
    this.branchService.getOrganizationList(this.tenantId).subscribe(
      (response) => {
        this.branchListData = response ?? [];
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //! Update Extracted Pateint Data through Modal
  updateExtractionPatientData(value: appprovalDetails) {
    if (value && value?.extractedfields && value?.fileId && this.batchId) {
      const dialogRef = this.dialog.open(ExtractionBotModalComponent, {
        data: {
          patientData: value?.extractedfields,
          batchId: this.batchId,
          fileId: value?.fileId,
        },
        minHeight: '80%',
        minWidth: '80%',
      });

      dialogRef.afterClosed().subscribe(() => {
        this.getbatchTableData();
      });
    }
  }

  //! download AutoUpload Last Document
  downloadAutoUploadLastDocument(
    lastAutoDocumentBlob: AutoIntakeDocumentDetailsDTO
  ) {
    if (
      typeof lastAutoDocumentBlob?.blobName === 'string' &&
      lastAutoDocumentBlob?.blobName
    ) {
      this.documentService
        .getPatientDocumentByBlobIDByBlobName(lastAutoDocumentBlob.blobName)
        .subscribe(
          (response) => {

            if (typeof response === 'string' && response && response?.length !== 0) {
              let filelocation: String = String(
                'data:application/pdf;base64,' + response
              );
              let link = document.createElement('a');
              link.download = lastAutoDocumentBlob?.fileName + '.pdf';
              link.href = filelocation.toString();
              link.click();
            }
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          },
          () => { }
        );
    }
  }

  //! Get Sub Batch Inital Table Details
  getbatchTableData() {
    this.isLoading = true;
    this.arrTableData = [];
    this.dataSource = new MatTableDataSource(this.arrTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    let arrTableData: appprovalDetails[] = [];
    this.documentUploadService.getBatchDocumentByIdByBatchId(this.batchId).subscribe(
      (response: BatchDocumentUploadsDTO) => {
        const responseBatchData: BatchDocumentUploadsDTO = response;
        arrTableData = this.extractSubBatchTableDetails(responseBatchData);
        this.arrTableData = [...arrTableData];
        this.dataSource = new MatTableDataSource(this.arrTableData.reverse());
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //! Extract Sub Batch Table Details
  private extractSubBatchTableDetails(
    batchDataTable: BatchDocumentUploadsDTO
  ): appprovalDetails[] {
    let arrTableData: Partial<appprovalDetails[]> = [];
    try {
      if (
        batchDataTable &&
        batchDataTable?.listDocumentUploads !== [] &&
        batchDataTable?.listDocumentUploads?.length !== 0 &&
        batchDataTable !== null
      ) {
        const listFileUploads = batchDataTable?.listDocumentUploads ?? [];
        listFileUploads?.forEach((element) => {
          if (element?.extractionStatus === UploadFileStatus.Completed) {
            const extractedData: PatientDTO | null =
              element?.extractedData?.extractedfields ?? null;
            const lastDocumentBlobDetails: AutoIntakeDocumentDetailsDTO | null =
              extractedData?.autoIntakeDocumentDetails?.slice(
                extractedData?.autoIntakeDocumentDetails?.length - 1,
                extractedData?.autoIntakeDocumentDetails?.length
              )[0] || null;
            const modifedExtractedData = {
              fullName: (extractedData?.personals?.firstName ?? "") + " " + (extractedData?.personals?.lastName ?? ''),
              dateOfBirth:
                extractedData?.personals?.dateOfBirth === '' ||
                  extractedData?.personals?.dateOfBirth === undefined ||
                  extractedData?.personals?.dateOfBirth === null
                  ? ''
                  : new Date(
                    extractedData?.personals?.dateOfBirth
                  )?.toLocaleDateString("en-US") ?? '',
              emailId: extractedData?.personals?.emailId ?? '',
              lastDocumentBlobDetails: lastDocumentBlobDetails ?? null,
            };
            let data: Partial<appprovalDetails> = {
              defaultBatchId: batchDataTable?.defaultBatchId ?? '',
              fileId: element?.fileId ?? '',
              uploadedFileName: element?.uploadedFileName ?? '',
              isStat: element?.isStat === 1 ? true : false,
              blobName: element?.blobName ?? '',
              listIdentifiedPatients:
                element?.listIdentifiedPatients?.map((e) => {
                  e.dateOfBirth =
                    e?.dateOfBirth === '' ||
                      e?.dateOfBirth === undefined ||
                      e?.dateOfBirth === null
                      ? ''
                      : new Date(e?.dateOfBirth)?.toLocaleDateString("en-US") ?? '';
                  return e;
                }) ?? [],
              // extractedfields:
              //   element?.extractedData?.extractedfields ?? undefined,
              // extractedData: modifedExtractedData ?? undefined,
              // status:
              //   element?.listIdentifiedPatients !== [] &&
              //     element?.listIdentifiedPatients !== null &&
              //     element?.listIdentifiedPatients !== undefined &&
              //     element?.listIdentifiedPatients?.length !== 0
              //     ? 'Existing Patient'
              //     : 'New Patient',
            };
            arrTableData.push(data);
          }
        });
      }
      return arrTableData;
    } catch (error) {
      return [];
    }
  }
}
