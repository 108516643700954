import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ProductBrandService, Status } from '../item-proxy/item-management/items';
import { CreateUpdateProductBrandDTO } from '../item-proxy/item-management/items/dto';
import {
  CreateUpdateProductBrandDetailsDTO,
  ProductBrandDetailSearchDTO,
} from '../item-proxy/item-management/optimization/dto';
import { ProductBrandDetailsService } from '../item-proxy/item-management/optimization';
import { Title } from '@angular/platform-browser';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';

@Component({
  selector: 'app-product-brand-tab',
  templateUrl: './product-brand-tab.component.html',
  styleUrls: ['./product-brand-tab.component.scss'],
})
export class ProductBrandTabComponent implements OnInit {
  productBrandForm: FormGroup;
  productBrandSearchForm: FormGroup;
  dataSource: MatTableDataSource<any>;
  brandId: string = '';
  tableData: any[];
  step: boolean = false;
  isShowSpinner: boolean = false;
  saveButtonHide: boolean = false;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'options',
    'brandName',
    'status',
    'createdDate',


  ];
  subscription$: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private productBrandService: ProductBrandDetailsService,
    public title: Title,
    public dateValidators: DateValidator,
  ) {}

  ngOnInit(): void {
     this.step = true;
    this.title.setTitle("Qsecure | Product Brand");
    this.initializeForms();
    this.getProductBrandList();
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  statusChange(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Brand will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          let productCategoryDetails = {
            ...data ?? null,
            status: Status.Active,
          }
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.productBrandService.update(data?.id, productCategoryDetails).subscribe(response => {
              // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
              this.toastr.success('Activated Successfully','Success')
              this.getProductBrandList();
            }, (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            })
          }
        }
        else {
          this.getProductBrandList();
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Brand will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            let productCategoryDetails = {
              ...data ?? null,
              status: Status.Inactive,
            }
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.productBrandService.update(data?.id, productCategoryDetails).subscribe(response => {
                // Swal.fire({ title: 'Success', html: 'Deactivated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                this.toastr.success('Deactivated Successfully','Success')
                this.getProductBrandList();
              }, (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              })
            }
          }
          else {
            this.getProductBrandList();
          }
        });
      }
  }

  initializeForms() {
    this.productBrandForm = this.formBuilder.group({
      txtBrandName: new FormControl(null, Validators.required),
    });
    this.productBrandSearchForm = this.formBuilder.group({
      brandName: new FormControl(''),
      createdDate: new FormControl('', this.dateValidators.dateVaidator),
      status: new FormControl(''),
    });
  }
  resetForm() {
    this.productBrandForm?.reset();
    this.clearValidationErrors();
  }
  clearValidationErrors() {
    Object.keys(this.productBrandForm.controls).forEach(key => {
      this.productBrandForm.get(key).setErrors(null);
    });
  }

  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
  saveProductBrand() {
    this.saveButtonHide = true;

    let input: CreateUpdateProductBrandDetailsDTO = {
      //Create Update DTO
      productBrandDetailName: this.productBrandForm?.value?.txtBrandName ?? '',
    };
    this.brandId = this.brandId ?? '';
    if (this.brandId === '') {
      this.productBrandService.create(input).subscribe(
        (response) => {
          this.brandId = '';
          this.toastr.success('Saved Successfully', 'Success');
          this.saveButtonHide = false;
          this.resetForm();
          this.getProductBrandList();
        },
        (err) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          this.toastr.error(err?.error?.error?.message, 'Error');
        }
      );
    } else {
      this.productBrandService.update(this.brandId, input).subscribe(
        (response) => {
          this.brandId = '';
          // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
          this.toastr.success('Updated Successfully', 'Success');
          this.resetForm();
          this.getProductBrandList();
          this.saveButtonHide = false;
        },
        (err) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          this.toastr.error(err?.error?.error?.message, 'Error');
        }
      );
    }
  }

  //To Get the Product Brand List
  getProductBrandList() {
    this.isShowSpinner = true;
    this.tableData = [];
    const valueChanges = this.productBrandSearchForm.valueChanges
      .pipe(
        startWith({
          brandName: '',
          status: '',
          createdDate: '',
        }),
        tap((x) => {
          this.isShowSpinner = true;
          this.setTableData([]);
        }),
        filter((e) => e && this.productBrandSearchForm?.valid),
        debounceTime(300),
        map((response) => {
          this.isShowSpinner = true;

          let ltStatus : any = null;
          switch(response?.status?.trim()?.toLowerCase()){
            case 'active':
              ltStatus = 'Active';
              break;
            case 'inactive':
              ltStatus = 'InActive';
              break;
            default:
              ltStatus = null;
          }

          const sValue: ProductBrandDetailSearchDTO = {
            productBrandDetailName: response?.brandName?.trim()?.toLowerCase(),
            status: ltStatus,
            createdDate: response?.createdDate?.trim()?.toLowerCase(),
          };
          return sValue;
        }),
        switchMap((sValue) =>
          this.productBrandService.searchProductBrandByInput(sValue)
        )
      )
      .subscribe(
        (response: any) => {

          this.isShowSpinner = false;
          this.setTableData(response.items);
        },
        (err) => {
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
        }
      );

    this.subscription$?.push(valueChanges);
  }
  //Set Table Data
  setTableData(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  getProductDetailsById(brandId: string) {
    if (brandId) {
      const ProductBrandGet = this.productBrandService
        .get(brandId)
        .subscribe((response) => {
          this.productBrandForm.patchValue({
            txtBrandName: response?.productBrandDetailName ?? '',
          });
          this.brandId = response?.id ?? '';
          this.step = true;
        });
      this.subscription$.push(ProductBrandGet);
    }
  }
  //Delete product brand details by id
  deletebrand(value: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        if (value) {
          const productBrandDelete = this.productBrandService
            .delete(value)
            .subscribe(
              (resposne) => {
                this.resetForm();
                this.getProductBrandList();
              },
              (err) => {
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }
            );
          this.subscription$.push(productBrandDelete);
        }
      }
    });
  }
}
