
<form [formGroup]="invoiceFilterForm" >
    <div class="row" id="dropdown-container">
       <div class="col-12 d-flex">
            <mat-form-field class="col-2 custom-form-field" appearance="none" >
              <mat-label class="drpLabel">Patient / Chart No</mat-label>
              <mat-select class="custom-mat-select" formControlName="drpPatient"
              (openedChange)="onPatinetDropdownOpenChange($event)" multiple [(value)]="drpPatientId"
              (closed)="handleSelectionChange(invoiceFilterForm.get('drpPatient').value);">
                  <mat-option style="font-size: 12px;">
                    <ngx-mat-select-search ngDefaultControl placeholderLabel="Patient / Chart No - Type Minimum 3 Characters"
                      hideClearSearchButton="true" [noEntriesFoundLabel]="drpDownMessage()" formControlName="txtPatientFilter"
                      (keyup)="loadPatientDropDown($event.target.value,$event.key)">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option style="font-size: 12px;" *ngFor="let patient of filteredPatient | async " [value]="patient?.patientId"
                 [title]="patient?.patientChart">
                 {{ patient?.patientChart }} </mat-option>
                   </mat-select>
                   <button mat-button *ngIf="
                   invoiceFilterForm.get('drpPatient').value != '' &&
                   invoiceFilterForm.get('drpPatient').value != null &&
                   invoiceFilterForm.get('drpPatient').value != undefined
                 " (click)="clearPatient(); $event.stopPropagation()" matSuffix mat-icon-button>
                     <mat-icon style="font-size: 16px !important;">close</mat-icon>
                     <!-- <i class="fa fa-times" aria-hidden="true" style="font-size: 16px;"></i> -->
                   </button>
            </mat-form-field>

            <mat-form-field class="col-2 custom-form-field" appearance="none" >
              <mat-label class="drpLabel">Invoice ID</mat-label>
              <mat-select class="custom-mat-select" formControlName="drpInvoice"
              (openedChange)="onInvoiceDropdownOpenChange($event)" multiple [(value)]="drpInvoiceId"
              (closed)="handleSelectionChange(invoiceFilterForm.get('drpInvoice').value);">
              <mat-option style="font-size: 12px;">
                <ngx-mat-select-search ngDefaultControl placeholderLabel="Invoice - Type Minimum 3 Characters"
                  hideClearSearchButton="true" [noEntriesFoundLabel]="drpInvoiceDownMessage()"
                  formControlName="txtInvoiceFilter" (keyup)="loadInvoiceDropDown($event.target.value,$event.key)">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option style="font-size: 12px;" *ngFor="let invoice of filteredInvoice | async " [value]="invoice?.claimId"
                [title]="invoice?.defaultClaimId">
                {{ invoice?.defaultClaimId }} </mat-option>
              </mat-select>
              <button mat-button *ngIf="
              invoiceFilterForm.get('drpInvoice').value != '' &&
              invoiceFilterForm.get('drpInvoice').value != null &&
              invoiceFilterForm.get('drpInvoice').value != undefined
            " (click)="clearInvoice(); $event.stopPropagation()" matSuffix mat-icon-button>
                <mat-icon style="font-size: 16px !important;">close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-2 custom-form-field" appearance="none">
              <mat-label class="drpLabel">Status</mat-label>
              <mat-select class="custom-mat-select" formControlName="drpStatus"  multiple
              [(value)]="drpStatusId" (closed)="handleSelectionChange(invoiceFilterForm.get('drpStatus').value);">
              <mat-option style="font-size: 12px;">
                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                  hideClearSearchButton="true" formControlName="txtStatusFilter"></ngx-mat-select-search>
              </mat-option >
              <mat-option style="font-size: 12px;" *ngFor="let status of filteredStatus | async" [value]="status.statusId">
                {{ status.statusName }}
              </mat-option>
              </mat-select>
              <button mat-button *ngIf="
              invoiceFilterForm.get('drpStatus').value != '' &&
              invoiceFilterForm.get('drpStatus').value != null &&
              invoiceFilterForm.get('drpStatus').value != undefined
            " (click)="clearStatus(); $event.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon style="font-size: 16px !important;">close</mat-icon>
            </button>
            </mat-form-field>

          <mat-form-field class="col-2 range-picker" appearance="none">
              <mat-label class="drpLabel">Date of Service</mat-label>
              <mat-date-range-input [rangePicker]="picker" >
                <input style="font-size: 12px;" class="dateinput" matStartDate matInput placeholder="Start Date" formControlName="txtDOSFromDate" maxlength="10"
                   (keydown)="addSlashToDate($event)" (focusout)="focusOutDOS()" (dateChange)="focusOutDOS();"/>
                <input style="font-size: 12px;" class="dateinput" matEndDate matInput placeholder="End Date" formControlName="txtDOSEndDate" maxlength="10"
                  (keydown)="addSlashToDate($event)" (focusout)="focusOutDOS()"(dateChange)="focusOutDOS();"/>
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 12px !important;"></mat-datepicker-toggle>
              <mat-date-range-picker #picker ></mat-date-range-picker>
              <button mat-button *ngIf="
              (invoiceFilterForm.get('txtDOSFromDate').value != '' &&
              invoiceFilterForm.get('txtDOSFromDate').value != null &&
              invoiceFilterForm.get('txtDOSFromDate').value != undefined) ||
              (invoiceFilterForm.get('txtDOSEndDate').value != '' &&
              invoiceFilterForm.get('txtDOSEndDate').value != null &&
              invoiceFilterForm.get('txtDOSEndDate').value != undefined)
            " (click)="clearDOS(); $event.stopPropagation()" matSuffix mat-icon-button>
            <mat-icon style="font-size: 12px !important;">close</mat-icon>
          </button>
          <mat-error *ngIf="invoiceFilterForm?.get('txtDOSFromDate')?.error||invoiceFilterForm?.get('txtDOSEndDate')?.error ">
            Enter a start date and End Date!
          </mat-error>
          </mat-form-field>
        </div>
       </div>
          </form>
      <div class="d-flex justify-content-center" *ngIf="isLoading">
        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
      </div>

      <table datatable [dtOptions]="billingInvoiceTable"  class="row-border hover w-100 display" *ngIf="!isLoading">
          <thead>
            <tr>
              <!-- <th [hidden]="true">*</th> -->
              <th><mat-icon>apps</mat-icon></th>
              <th>Chart ID</th>
              <th>Patient Name</th>
              <th>Invoice ID</th>
              <th>Ticket ID</th>
              <th>DOS</th>
              <th>Created</th>
              <th>Modified</th>
              <th class="text-right">Charge($)</th>
              <th class="text-right">Balance($)</th>
              <th>Service Location</th>
              <th>Processing Method </th>
              <th>Bill To</th>
              <th>Payer Name</th>
              <th>Plan Name</th>
              <th class="cwidth" >Status</th>
            </tr>
          </thead>
          <tbody >
            <tr *ngFor="let invoice of inBoundTableData" >
              <td class="no-select">
                <mat-icon class="pointer toggle-icon" (click)="getTotalInboundData($event, invoice)">add</mat-icon>

                <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                    <button (click)="openViewNotesPopup(invoice.patientId)"
                        [disabled]="isEmpty(invoice.patientId)" mat-menu-item>
                        View Patient Notes
                    </button>
                    <button mat-menu-item (click)="viewPatientLedger(invoice?.patientId)"
                        [disabled]="isEmpty(invoice.patientId)">
                        View Patient Ledger
                    </button>
                </mat-menu>
            </td>
              <td (dblclick)="onRowDoubleClick(invoice.chartId || '-')">
                <a [class.disabled]="
                    isEmpty(invoice.chartId) || isEmpty(invoice.patientId)
                  " class="preview-link" [routerLink]="'/patientCreateEditTab/' + invoice.patientId" target="_blank"
                  [matTooltip]="invoice.chartId || '-'">{{ invoice.chartId || "-" }}</a>
              </td>
                <td (dblclick)="onRowDoubleClick((invoice.patientName || '-') + ' (' + (invoice.dtDOB || '-') + ')')"[matTooltip]="(invoice.patientName || '-') + ' (' + (invoice.dtDOB || '-') + ')'">

                  <p> {{ invoice.patientName || '-' }}</p>
                   <!-- <br /> -->
                   <p>({{ invoice.dtDOB || '-' }})</p>
                 </td>
              <td (dblclick)="onRowDoubleClick(invoice.invoiceNumber || '-')">

                <a class="preview-link"  [class.disabled]="isEmpty(invoice.orderId) || isEmpty(invoice.invoiceNumber) || isEmpty(invoice.invoiceStatusId) " [routerLink]="
                    '/orders/invoiceview/' + (invoice.orderId || defaultGuid) + '/' + (invoice.claimId || defaultGuid) + '/' + (invoice.rentalOrderId || defaultGuid)
                  " target="_blank" class="preview-link" [matTooltip]="invoice.invoiceNumber || '-'" >
                  {{ invoice.invoiceNumber || "-" }}
                </a>

              </td>
              <td (dblclick)="onRowDoubleClick(invoice.orderTicketId || '-')">
                <a [class.disabled]="
                isEmpty(invoice.orderId) || isEmpty(invoice.patientId) || isEmpty(invoice.orderTicketId)
              " class="preview-link" [routerLink]="
                    '/orders/saleOrderview/' + invoice.patientId + '/' + invoice.orderId
                  " target="_blank" class="preview-link" [matTooltip]="invoice.orderTicketId || '-'">{{ invoice.orderTicketId || "-" }}
                </a>
              </td>
              <td (dblclick)="onRowDoubleClick(invoice.dtDOS || '-')" [matTooltip]="invoice.dtDOS || '-'" >{{ invoice.dtDOS || '-'}}</td>
              <td (dblclick)="onRowDoubleClick(invoice.creationTime || '-')" [matTooltip]="invoice.creationTime || '-'" >{{ invoice.creationTime || '-'}}</td>
              <td (dblclick)="onRowDoubleClick(invoice.modifiedTime || '-')" [matTooltip]="invoice.modifiedTime || '-'" >{{ invoice.modifiedTime || '-'}}</td>
              <td (dblclick)="onRowDoubleClick(invoice.charges || '-')" [matTooltip]="invoice.charges || '-'"  class="text-right">{{ invoice.charges  || '-'}}</td>
              <td (dblclick)="onRowDoubleClick(invoice.balance || '-')" [matTooltip]="invoice.balance || '-'" class="text-right">{{ invoice.balance || '-'}}</td>
              <td (dblclick)="onRowDoubleClick(invoice.serviceLocationName || '-')" [matTooltip]="invoice.serviceLocationName || '-'" >{{ invoice.serviceLocationName ||'-' }}</td>
              <td (dblclick)="onRowDoubleClick(invoice.processingMethod || '-')" [matTooltip]="invoice.processingMethod || '-'" >{{ invoice.processingMethod   ||'-' }}</td>
              <td (dblclick)="onRowDoubleClick(invoice.billTo || '-')" [matTooltip]="invoice.billTo || '-'" >{{ invoice.billTo || '-'}}</td>
              <td >
                <!-- Check if the payor level is Primary -->
                <span [matTooltip]="invoice.priPayerName || '-'"   class="splSpan" *ngIf="invoice.payorLevel === 'Primary'; else check_Secondary">
                  {{ invoice.priPayerName || '-' }}
                </span>

                <!-- Check if the payor level is Secondary (this acts like 'else if') -->
                <ng-template #check_Secondary>
                  <span   class="splSpan" *ngIf="invoice.payorLevel === 'Secondary'; else show_Default">
                    {{ invoice.secPayerName || '-' }}
                  </span>
                </ng-template>

                <!-- Else block (this acts like 'else') -->
                <ng-template #show_Default  >
                  <span class="splSpan" [matTooltip]="'-'">
                    -
                  </span>
                </ng-template>
                <br />
                <div  class="d-flex align-items-center justify-content-between">
                  <span [matTooltip]="invoice.payorLevel || 'Other'" class="rounded-border w-100 text-center p-1 insurance-type-label">
                    {{ invoice.payorLevel || 'Other' }}
                  </span>
                  <span *ngIf="invoice.isPriAcceptElectronicClaim || invoice.isSecAcceptElectronicClaim"
                    matTooltip="Accepts Electronic Claims" class="text-center">
                    <!-- <mat-icon mat-icon-button class="ico-small"
                      >mail_outline</mat-icon
                    > -->
                    <img class="img-responsive pl-1 mb-1" src="assets/Billing Icons/Mail.png" alt="" height="11" />
                  </span>
                </div>
              </td>
              <td>
                <!-- Check if the payor level is Primary -->
                <span  [matTooltip]="invoice.priPlanTypeName || '-'" class="splSpan" *ngIf="invoice.payorLevel === 'Primary'; else checkSecondary">
                  {{ invoice.priPlanTypeName || '-' }}
                </span>

                <!-- Check if the payor level is Secondary (this acts like 'else if') -->
                <ng-template #checkSecondary>
                  <span [matTooltip]="invoice.secPlanTypeName || '-'" class="splSpan" *ngIf="invoice.payorLevel === 'Secondary'; else showDefault">
                    {{ invoice.secPlanTypeName || '-' }}
                  </span>
                </ng-template>

                <!-- Else block (this acts like 'else') -->
                <ng-template #showDefault>
                  <span class="splSpan" [matTooltip]="'-'">
                    -
                  </span>
                </ng-template>
              </td>
              <!-- [ngStyle]="{
                'background-color': invoice.colorCode,
                color: updateTextColor(invoice.colorCode) -->
              <!-- }" -->
              <td class="text-center cwidth"  >
                {{ invoice?.invoiceStatusName || "-" }}
                <!-- <div class="d-flex align-items-center">
                  <span  class="rounded-border ml-1 w-100 p-1 claim-status-label" [matTooltip]="invoice?.invoiceStatusName || '-'"  >
                    {{ invoice?.invoiceStatusName || "-" }}
                  </span>
                </div> -->
              </td>
            </tr>
          </tbody>
      </table>


