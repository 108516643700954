import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import {
  CreateUpdatePickUpExchangeDTO,
  CreateUpdateSaleOrderItemDTO,
  ItemDetailsListDTO,
  PickupExchangeStatus,
  PickupExchangeType,
  ReturnAllItemsInputDTO,
} from '../order-proxy/order-management/order-optimization/dto';
import { PickUpExchangeService } from '../order-proxy/order-management/order-optimization/pick-up-exchange.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AddPickupExchangeItemTabComponent } from '../add-pickup-exchange-item-tab/add-pickup-exchange-item-tab.component';

@Component({
  selector: 'app-pickup-exchange-options-tab',
  templateUrl: './pickup-exchange-options-tab.component.html',
  styleUrls: ['./pickup-exchange-options-tab.component.scss'],
})
export class PickupExchangeOptionsTabComponent implements OnInit {
  ExchangeForm: FormGroup;
  maxDate: Date;
  minDate: Date;
  isShowSpinner: boolean = false;
  constructor(
    private fb: FormBuilder,
    private cookieService: CookieService,
    private pickupExchangeService: PickUpExchangeService,
    private toastr: ToastrService,
    private router: Router,
    public dialogRef: MatDialogRef<PickupExchangeOptionsTabComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      itemId: string;
      patientId: string;
      orderId: string;
      itemDetails: CreateUpdateSaleOrderItemDTO;
      isExchange: boolean;
      isReturnAllItems: boolean;
    } = {
      itemId: defaultGuid,
      patientId: defaultGuid,
      orderId: defaultGuid,
      itemDetails: null,
      isExchange: false,
      isReturnAllItems: false,
    }
  ) {}

  ngOnInit(): void {
    
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
    this.minDate = new Date();
    this.minDate.setFullYear(this.minDate.getFullYear() - 120);
    this.intializeForm();
  }
  //Intialize Form
  intializeForm() {
    this.ExchangeForm = this.fb.group({
      txtReturnDate: new FormControl(null, [Validators.required]),
      txtComments: new FormControl('', [Validators.required]),
    });
  }
  initializeExchangeSave() {
    //Return all items in a order
    if (this.data.isReturnAllItems) {
      let returnInputDetails: ReturnAllItemsInputDTO = {
        orderId: this.data.orderId,
        pickupReason: this.ExchangeForm?.get('txtComments')?.value,
        initiatedDate: this.ExchangeForm?.get('txtReturnDate')?.value,
      };
      //returnAllItemOrders
      const returnAllItems = this.pickupExchangeService
        .returnAllItemOrders(returnInputDetails)
        .subscribe(
          (response) => {
            this.dialogRef.close();
            Swal.fire({
              icon: 'success',
              text: 'Saved Successfully',
            });
            this.cookieService.set('orderStatus', 'true');
            this.router.navigate([
              'orders/saleorderCreateEditTab',
              this.data.patientId,
              this.data.orderId,
            ]);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    }
    //For individual item exchange and return
    else {
      let ExchangeDetails: CreateUpdatePickUpExchangeDTO;

      let items: ItemDetailsListDTO = {
        id: defaultGuid,
        exchangedItemId: this.data.itemId,
        itemId: defaultGuid,
        pickupExchangeType: this.data.isExchange
          ? PickupExchangeType.ItemExchange
          : PickupExchangeType.ItemReturn,
        pickupReason: this.ExchangeForm?.get('txtComments')?.value,
        initiatedDate: this.ExchangeForm?.get('txtReturnDate')?.value,
      };
      ExchangeDetails = {
        patientId: this.data.patientId ?? defaultGuid,
        saleorderId: this.data.orderId ?? defaultGuid,
        claimId: defaultGuid,
        defaultPickupExchangeId: '',
        pickupStatus: PickupExchangeStatus.ReturnInitiated,
        saleOrderItem: this.data.itemDetails,
        ItemDetailsList: [items],
        returnCompletedDate: '',
        fileContentAsBase64String:'',
        blobName:''
      };
      
      this.savePickupExchangeDetails(ExchangeDetails);
    }
  }
  //To Save the Pickup & Exchange Details
  savePickupExchangeDetails(ExchangeDetails: any) {
    //this.toastr.success('Saved Successfully', 'Success');
    this.pickupExchangeService.create(ExchangeDetails).subscribe(
      (response) => {
        this.dialogRef.close();
        Swal.fire({
          icon: 'success',
          text: 'Saved Successfully',
        });
        location.reload();
        this.isShowSpinner = false;
      },
      (err) => {
        this.isShowSpinner = false;
        this.dialogRef.close();
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
}
