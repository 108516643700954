import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { PayerService } from 'projects/patient/src/app/patient-proxy/patient';
import {
  CreateUpdatePayerDetailDTO,
  PayerDTO,
} from 'projects/patient/src/app/patient-proxy/patient/dto';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { PayerDetailService } from 'projects/patient/src/app/patient-proxy/patient/payer-detail.service';
import emailMask from 'text-mask-addons/dist/emailMask';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import {
  defaultCountry,
  defaultGuid,
} from 'projects/shared/src/app/enums/allenums.enum';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { InsuranceCategoryService } from 'projects/patient/src/app/patient-proxy/patient-optimization/insurance-category.service';
import { CreateUpdateInsuranceCategoryDTO } from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-inventory-payor',
  templateUrl: './inventory-payor.component.html',
  styleUrls: ['./inventory-payor.component.scss'],
  providers: [ListService],
})
export class InventoryPayorComponent implements OnInit, OnDestroy {
  lstInsurenceCategory: any;
  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue =
          formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }
  searchForm: FormGroup;
  isShowSpinner: boolean = false;
  strPageType: string = 'payorPage';
  payorId: string = '';
  isLoading: boolean = true;
  step: boolean = false;
  step1: boolean = false;
  State: string = '';
  City: string = '';
  selectedOption: number = 1;
  // sSearch:string='';
  drpModeType: number = 4;
  payorForm: FormGroup;
  payorInsuranceForm: FormGroup;
  payorSearchForm: FormGroup;
  eligibilityMode: any;
  EligibilityPayor: any;
  pVerifyPayor: string = '';
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'Options',
    'payerId',
    'payerName',
    'eligibilityMode',
  ];
  organizationUnitId: string = null;
  tenantId: string = '';
  payerTableData: any;
  saveButtonHide: boolean;
  emailMask: any;
  subscription$: Subscription[] = [];
  dtOptions: any;
  searchLoading: boolean = false;
  drpStates: any[];
  drpCities: any[];
  lstPayerId: string[] = [];
  filteredCities: any;
  filteredStates: any;
  eligibilityModeDetails: any;
  InsurenceCategoryList: any;
  saveButtonHideIns: boolean;
  InsCategFilterControl = new FormControl();
  Data: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private table: TableService,
    private payerService: PayerService,
    private regionDropdownService: RegionDropdownService,
    private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    private list: ListService,
    public title: Title,
    private reportService: ReportService,
    private payerDetails: PayerDetailService,
    private toastr: ToastrService,
    private insuranceCategoryService: InsuranceCategoryService,
    private commonService: CommonService
  ) {}
  ngOnInit(): void {
    this.eligibilityModeDetails = [
      { id: 1, value: 'pVerify' },
      { id: 2, value: 'Zermid' },
      { id: 3, value: 'ChangeHealthCare' },
      { id: 4, value: 'Manual' },
      { id: 5, value: 'Office Ally' },
    ];
    this.selectedOption = 1;
    this.initializeForms();
    this.payorInsuranceForm = this.formBuilder.group({
      InsCategoryV1: new FormControl('', [Validators.required]),
      chkIns: new FormControl(''),
    });
    this.emailMask = emailMask;
    this.statusFilter();
    this.title.setTitle('Qsecure | Payor');
    this.onChangeState(defaultCountry.US);
    this.getInsuranceCategory();

    this.InsCategFilterControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.InsurenceCategoryList = this.lstInsurenceCategory.filter((dx) =>
        dx.insuranceCategory.toLowerCase().includes(FilterText)
      );
    });
  }
  initializeForms() {
    this.payorForm = this.formBuilder.group({
      txtPayorID: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtPayorName: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
        this.textValidators.isTextCheck,
      ]),
      selectEligibility: new FormControl('', [Validators.required]),
      InsCategory: new FormControl('', [Validators.required]),
      txtPayorCode: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtFaxNumber: new FormControl('', [Validators.required]),
      txtEmail: new FormControl('', [Validators.required]),
      txtAddress: new FormControl('', [
        Validators.required,
        noWhitespaceValidator,
      ]),
      txtAddress2: new FormControl(''),
      drpState: new FormControl('', [Validators.required]),
      drpCity: new FormControl('', [Validators.required]),
      drpCityFilter: new FormControl(''),
      drpStateFilter: new FormControl(''),
      txtPostalCode: new FormControl('', [Validators.required]),
      txtContactName: new FormControl('', [this.textValidators.isTextCheck]),
      txtPhoneNumber: new FormControl(''),
      txtWrtOff: new FormControl(''),
      txtpmtCode: new FormControl(''),
      txtInsCode: new FormControl(''),
      txtClaimIndicator: new FormControl(''),
      isChecklistRequired: false,
      isMedicare: false,
    });
    this.searchForm = this.formBuilder.group({
      sSearch: new FormControl(''),
    });
  }
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
  SecToggle() {
    this.step1 = true;
  }
  secToggleV2() {
    this.step1 = false;
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  eligibiltyStatus(modeId: number) {
    const selectedMode = this.eligibilityModeDetails.find(
      (mode) => mode.id === modeId
    );
    return selectedMode ? selectedMode.value : null;
  }

  //! filter table
  filtertable(filter) {
    this.isLoading = true;
    let PayorId = filter.payorIdSearch;
    let PayorName = filter.payorNameSearch;

    const searchPayer = this.payerService
      .searchPayerBySPayerAndSPayerName(PayorId, PayorName)
      .subscribe((data) => {
        this.payerTableData = [];
        data?.items?.forEach((element) => {
          this.payerTableData = element;
        });
        this.isLoading = false;
      });
    this.subscription$.push(searchPayer);
  }
  resetPayorInsuranceForm() {
    this.payorInsuranceForm.patchValue({
      InsCategoryV1: '',
      chkIns: '',
    });
    this.payorInsuranceForm.markAsTouched();
    this.payorInsuranceForm.updateValueAndValidity();
  }
  resetPayorForm() {
    this.payorForm.patchValue({
      txtPayorID: '',
      txtPayorName: '',
      selectEligibility: '',
      InsCategory: '',
      txtPayorCode: '',
      txtFaxNumber: '',
      txtEmail: '',
      txtAddress: '',
      txtAddress2: '',
      drpState: '',
      drpCity: '',
      txtPostalCode: '',
      txtContactName: '',
      txtPhoneNumber: '',
      txtWrtOff: '',
      txtpmtCode: '',
      txtInsCode: '',
      txtClaimIndicator: '',
      isChecklistRequired: false,
      isMedicare: false,
    });
    this.payorId = '';
    this.payorForm.reset();
    this.payorForm.markAsUntouched();
    this.payorForm.updateValueAndValidity();
    this.initializeForms();
  }
  savePayor() {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    this.tenantId = localStorage.getItem('tenantId');
    let payerDetails: CreateUpdatePayerDetailDTO = {
      payerName: this.payorForm.value.txtPayorName,
      insuranceCategory: this.payorForm.value.InsCategory,
      // insuranceCategory:defaultGuid,
      payerCode: this.payorForm.value.txtPayorID,
      code: this.payorForm.value.txtPayorCode,
      payerShortCodeId: '',
      eligibilityMode: this.payorForm.value.selectEligibility,
      isActive: true,
      isSupportingEligibility: true,
      isSupportingClaims: true,
      isEDIPayer: true,
      address: this.payorForm.value.txtAddress,
      city: this.payorForm.value.drpCity,
      state: this.payorForm.value.drpState,
      zip: this.payorForm.value.txtPostalCode,
      contactName: this.payorForm.value.txtContactName,
      phoneNo: this.payorForm.value.txtPhoneNumber,
      preCertNo: null,
      email: this.payorForm.value.txtEmail.toLowerCase(),
      faxNo: this.payorForm.value.txtFaxNumber,
      pmtCode: this.payorForm.value.txtpmtCode,
      wrtOffCode: this.payorForm.value.txtWrtOff,
      claimFilingIndicator: this.payorForm.value.txtClaimIndicator,
      insCategoryID: null,
      insuranceTypeCode: this.payorForm.value.txtInsCode,
      requireCheckList: this.payorForm.value.isChecklistRequired,
      isMedicarePolicy: this.payorForm.value.isMedicare,
    };
    if (
      this.payorId === '' ||
      this.payorId === undefined ||
      this.payorId === null
    ) {
      this.payorId = '';
      this.payerDetails.create(payerDetails).subscribe(
        (response) => {
          this.toastr.success('Saved Successfully', 'Success');
          this.saveButtonHide = false;
          this.toggleV2();
          this.resetPayorForm();
          this.statusFilter();
          this.isShowSpinner = false;
        },
        (err) => {
          this.isShowSpinner = false;
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
      return;
    }

    this.payerDetails.update(this.payorId, payerDetails).subscribe(
      (response) => {
        this.toastr.success('Updated Successfully', 'Success');
        this.resetPayorForm();
        this.toggleV2();
        this.statusFilter();
      },
      (err) => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  addRemovePayorId(event: MatSlideToggleChange, id: string) {
    if (event.checked === true) {
      this.lstPayerId.push(id);
    } else {
      const index = this.lstPayerId.indexOf(id);
      if (index !== -1) {
        this.lstPayerId.splice(index, 1);
      }
    }
  }
  saveInsuranceCategory() {
    this.saveButtonHideIns = true;
    this.payerDetails
      .InsuranceCategoryCreate(
        this.payorInsuranceForm.value.InsCategoryV1,
        this.lstPayerId
      )
      .subscribe(
        (response) => {
          this.toastr.success('Updated Successfully', 'Success');
          this.saveButtonHideIns = false;
          this.secToggleV2();
          this.resetPayorInsuranceForm();
          this.statusFilter();
          this.lstPayerId = [];
        },
        (err) => {
          this.saveButtonHideIns = false;
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    return;
  }
  editPayor(value: PayerDTO) {
    this.saveButtonHide = false;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (this.step == false) {
      this.step = true;
    }
    const payerGet = this.payerDetails.get(value.id).subscribe(
      (response) => {
        this.onChangeCity(response?.state, defaultCountry.US);
        this.payorForm.patchValue({
          txtPayorID: (response?.payerCode).toLowerCase()==='null' ? null : response?.payerCode,
          txtPayorName: response?.payerName,
          selectEligibility: response?.eligibilityMode,
          InsCategory: response?.insuranceCategory,
          txtPayorCode: (response?.code).toLowerCase()==='null' ? null : response?.code,
          txtFaxNumber: response?.faxNo,
          txtEmail: response?.email,
          txtAddress: response?.address,
          txtAddress2: response?.address,
          drpState: response?.state,
          drpCity: response?.city,
          txtPostalCode: response?.zip,
          txtContactName: response?.contactName,
          txtPhoneNumber: response?.phoneNo,
          txtWrtOff: response?.wrtOffCode,
          txtpmtCode: response?.pmtCode,
          txtInsCode: response?.insuranceTypeCode,
          txtClaimIndicator: response?.claimFilingIndicator,
          isChecklistRequired: response?.requireCheckList,
          isMedicare: response?.isMedicarePolicy,
        });
        this.payorId = response?.id;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(payerGet);
  }

  onSelect(event: any) {
    this.eligibilityMode = event.value
      ? event.value
      : this.payorForm.value.selectEligibility;
  }

  getInsuranceCategory() {
    const payerList = this.payerService.InsuranceCategoryGetList().subscribe(
      (response) => {
        this.lstInsurenceCategory = response.items;
        this.InsurenceCategoryList = this.lstInsurenceCategory;
      },
      (err) => {
        this.table.setPayorCodeList(this.payerTableData);
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.isLoading = false;
      }
    );
    this.subscription$.push(payerList);
  }
  //! Delete Payer Function By Id
  deletePayor(payer: PayerDTO) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const deletePayor = this.payerDetails.delete(payer.id).subscribe(
          (response) => {
            this.resetPayorForm();
            this.statusFilter();
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
        this.subscription$.push(deletePayor);
      }
    });
  }

  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.payorForm.patchValue({
      drpState: null,
      drpCity: null,
    });
    this.drpStates = [];
    this.drpCities = [];
    value !== null &&
      value !== undefined &&
      this.regionDropdownService
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response?.items ?? [];
            this.filteredStates = this.payorForm
              ?.get('drpStateFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return this.drpStates.length !== 0;
  }
  // On Change City Dropdown
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.payorForm.patchValue({
      drpCity: null,
    });
    country = defaultCountry.US;
    country =
      country == ''
        ? this.payorForm.value.drpCountry == (null || undefined)
          ? ''
          : this.payorForm.value.drpCountry
        : country;
    this.drpCities = [];
    state !== null &&
      state !== undefined &&
      this.regionDropdownService
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response?.items ?? [];

            this.filteredCities = this.payorForm
              ?.get('drpCityFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return this.drpCities.length !== 0;
  }
  statusChange(event: MatSlideToggleChange, id: string) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Payor will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!',
      }).then((result) => {
        if (result.value) {
          if (id && id !== '' && id !== null && id !== undefined) {
            this.payerDetails.payerEnableDisable(id).subscribe(
              (response) => {
                this.toastr.success('Activated Successfully', 'Success');
                this.statusFilter();
              },
              (err) => {
                this.toastr.success('Activated Successfully', 'Success');
                this.statusFilter();
                //   const data: HttpErrorResponse = err;
                //   Swal.fire({
                //     icon: 'info',
                //     text: data?.error?.error?.message,
                //   });
              }
            );
          }
        } else {
          event.source.checked = false;
        }
      });
    } else if (event.checked == false) {
      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this Payor will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!',
      }).then((result) => {
        if (result.value) {
          if (id && id !== '' && id !== null && id !== undefined) {
            this.payerDetails.payerEnableDisable(id).subscribe(
              (response) => {
                this.toastr.success('Deactivated Successfully', 'Success');
                this.statusFilter();
              },
              (err) => {
                // const data: HttpErrorResponse = err;
                // Swal.fire({
                //   icon: 'info',
                //   text: data?.error?.error?.message,
                // });
                this.toastr.success('Deactivated Successfully', 'Success');
                this.statusFilter();
              }
            );
          }
        } else {
          event.source.checked = true;
        }
      });
    }
  }
  statusFilter() {
    this.isLoading = true;
    this.lstPayerId = [];
    if (this.Data) {
      this.Data.unsubscribe();
    }
    this.Data = this.payerDetails
      .statusFilter(
        this.drpModeType,
        this.selectedOption,
        this.searchForm.value.sSearch
      )
      .subscribe(
        (response) => {
          this.payerTableData = response;
          this.payerTableData = this.payerTableData.map((data) => {
            return {
              ...data,
              creationTime: this.commonService.getFormattedDateTimeZone(
                data.creationTime
              ),
              lastModificationTime: this.commonService.getFormattedDateTimeZone(
                data.lastModificationTime
              ),
            };
          });
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching details:', error);
          this.isLoading = false;
        }
      );
  }
  lstPayer(lstPayerId: string[]) {
    this.lstPayerId = lstPayerId;
  }
}
export interface payorList {
  payorIdSearch: string;
  payorNameSearch: string;
}
