<meta http-equiv="Cache-control" content="no-cache" />
<div class="container-fluid">
  <div class="row align-items-center">
    <div class="col-lg-9">
      <h3 class="customThemeClass mt-2">
        <b>Document Preview </b>
      </h3>
    </div>
    <div class="col-lg-3" style="text-align-last: right">
      <mat-icon  mat-dialog-close>close</mat-icon>

    </div>
    <!-- <div class="col-lg-3" style="text-align-last: right" [hidden]="true" > -->
      <!-- Need to add Flag . We used mostly New page Sale order page only using model - Inventry team Please note-->
      <!-- <div> -->
        <!-- <button mat-icon-button mat-dialog-close class="close-button">
          <mat-icon class="mr-2">close</mat-icon>
        </button> -->
        <!-- <div class="modal-header"> -->
    
          <!-- <i aria-label="Close" mat-dialog-close class="fa fa-times"></i> -->
         
        <!-- </div> -->
      <!-- </div> -->
    <!-- </div> -->
  </div>

  <!-- <div class="row bg" style="margin-top: -10px">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0 font-size-18" *ngIf="sortStatus != 'true'">
          <b>Sorting / Processed Documents</b>
        </h3>
        <h3 class="mb-0 font-size-18" *ngIf="sortStatus == 'true'">
          <b>Sorting / Sorted Documents</b>
        </h3>
        <h3 class="mb-0 font-size-18">
          <b *ngIf="faxId">FaxId:</b>&nbsp;<b class="text-danger" *ngIf="faxId">{{ faxId }}</b>
        </h3>
        <h3 class="mb-0 font-size-18">
          <b>Patient Id:</b>&nbsp;<b class="text-danger">{{
            defaultPatientId ? defaultPatientId : '-'
            }}</b>
        </h3>

        <h3 class="mb-0 font-size-18">
          <b>Patient Name:</b>&nbsp;<b class="text-danger">{{ patientName ? patientName : '-' }}</b>
        </h3>

   

      </div>
    </div>
  </div> -->

  <div class="col-lg-12">
    <mat-card style="margin-bottom: 30px;">
      <mat-card-content>
        <div class="row">
          <!-- <div class="col-lg-3">
            <div class="card-body" [style]="'overflow-y: auto; max-height: 810px;'">
              <mat-selection-list #documentTypes id="sltDocumentType" [multiple]="false" [(ngModel)]="dTypeId" class="custom-selection-list"> -->

                <!-- (selectionChange)="onListSelected(dTypeId)"  -->
                <!-- <mat-list-option *ngFor="let document of documentTypesArray" [value]="document.docTypeId"
                  [matTooltip]="getToolTip(document)" (click)="onListItemClicked(document)">
                  <div>
                    {{ document.documentType }} <span class="h5">({{ document.pageCount }})</span>
                  </div>
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div> -->


          <div class="col-lg-12">
            <!-- style="height: 800px;" -->
            <div class="pdf-container ml-0" *ngIf="!isLoading">
              <ng-container >
                <!-- (pagesLoaded)="onPagesLoaded($event)" -->
                <ngx-extended-pdf-viewer [src]="strSelectedPdfPath" [(page)]="pageNumber"
                  [(pageViewMode)]="pageViewMode" [height]="pdfHeight" [showSidebarButton]="false"
                  [showFindButton]="true" [textLayer]="true" [showPagingButtons]="true" [showOpenFileButton]="false"
                  [showHandToolButton]="false" [showScrollingButton]="false" [showSpreadButton]="false"
                  [showPropertiesButton]="false" [showBookmarkButton]="false" [zoom]="'page-width'"
                  [enableDragAndDrop]="false" [(page)]="currentPage" (pageChange)="onPageChange($event)">
                </ngx-extended-pdf-viewer>
              </ng-container>
            </div>
            <div class="d-flex justify-content-center" *ngIf="isLoading">

              <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>
