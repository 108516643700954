import { Component, OnInit } from '@angular/core';
import { DocumentClassificationDto } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import { PageViewModeType, PagesLoadedEvent } from 'ngx-extended-pdf-viewer';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { Subscription } from 'rxjs';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GenderDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';

@Component({
  selector: 'app-auto-sorting',
  templateUrl: './auto-sorting.component.html',
  styleUrls: ['./auto-sorting.component.scss'],
})
export class AutoSortingComponent implements OnInit {
  moveButtonDisabled: boolean = true;
  dTypeId: string; //Document Type NgModel Field
  totalPages: number = 0;
  pageNumber: number = 1;
  face1st: boolean = true;
  face2nd: boolean = false;
  strSelectedPdfPath: string = '';
  percentageOfCompletion: number = 0;
  pdfHeight: string;
  gender:string;
  patientsId: string = '';
  patientId: string = '';
  fileStatus: string = '';
  faceHide: boolean;
  drpGenderLoop: GenderDTO[];
  drpDXType: any[] = [];
  drpPrdSetupType: any[] = [];
  drpSymptoms: any[] = [];
  ruleColor: {
    ctrlName1?: string,
    errValue1?: string,
    erStatus1?: boolean,
    ctrlName2?: string,
    errValue2?: string,
    erStatus2?: boolean,
    ctrlName3?: string,
    errValue3?: string,
    erStatus3?: boolean,
    ctrlName4?: string,
    errValue4?: string,
    erStatus4?: boolean,
    ctrlName5?: string,
    errValue5?: string,
    erStatus5?: boolean,
    ctrlName6?: string,
    errValue6?: string,
    erStatus6?: boolean,
    ctrlName7?: string,
    errValue7?: string,
    erStatus7?: boolean,
    ctrlName8?: string,
    errValue8?: string,
    erStatus8?: boolean,
    ctrlName9?: string,
    errValue9?: string,
    erStatus9?: boolean,
    ctrlName10?: string,
    errValue10?: string,
    erStatus10?: boolean,
    ctrlName11?: string,
    errValue11?: string,
    erStatus11?: boolean,
    ctrlName12?: string,
    errValue12?: string,
    erStatus12?: boolean,
    ctrlName13?: string,
    errValue13?: string,
    erStatus13?: boolean,
    ctrlName14?: string,
    errValue14?: string,
    erStatus14?: boolean,
    ctrlName15?: string,
    errValue15?: string,
    erStatus15?: boolean,
    ctrlName16?: string,
    errValue16?: string,
    erStatus16?: boolean,
    ctrlName17?: string,
    errValue17?: string,
    erStatus17?: boolean,
    ctrlName18?: string,
    errValue18?: string,
    erStatus18?: boolean,
    ctrlName19?: string,
    errValue19?: string,
    erStatus19?: boolean,
    ctrlName20?: string,
    errValue20?: string,
    erStatus20?: boolean,
    ctrlName21?: string,
    errValue21?: string,
    erStatus21?: boolean,
    ctrlName22?: string,
    errValue22?: string,
    erStatus22?: boolean,
    ctrlName23?: string,
    errValue23?: string,
    erStatus23?: boolean,
    ctrlName24?: string,
    errValue24?: string,
    erStatus24?: boolean,
    ctrlName25?: string,
    errValue25?: string,
    erStatus25?: boolean,
    ctrlName26?: string,
    errValue26?: string,
    erStatus26?: boolean,
    ctrlName27?: string,
    errValue27?: string,
    erStatus27?: boolean,
    ctrlName28?: string,
    errValue28?: string,
    erStatus28?: boolean,
    ctrlName29?: string,
    errValue29?: string,
    erStatus29?: boolean,
    ctrlName30?: string,
    errValue30?: string,
    erStatus30?: boolean,
    ctrlName31?: string,
    errValue31?: string,
    erStatus31?: boolean,
    ctrlName32?: string,
    errValue32?: string,
    erStatus32?: boolean,
    ctrlName33?: string,
    errValue33?: string,
    erStatus33?: boolean,
    ctrlName34?: string,
    errValue34?: string,
    erStatus34?: boolean,
    ctrlName35?: string,
    errValue35?: string,
    erStatus35?: boolean,
    ctrlName36?: string,
    errValue36?: string,
    erStatus36?: boolean,
    ctrlName37?: string,
    errValue37?: string,
    erStatus37?: boolean,
    ctrlName38?: string,
    errValue38?: string,
    erStatus38?: boolean,
    ctrlName39?: string,
    errValue39?: string,
    erStatus39?: boolean,
    ctrlName40?: string,
    errValue40?: string,
    erStatus40?: boolean,
    ctrlName41?: string,
    errValue41?: string,
    erStatus41?: boolean,
    ctrlName42?: string,
    errValue42?: string,
    erStatus42?: boolean,
    ctrlName43?: string,
    errValue43?: string,
    erStatus43?: boolean,
    ctrlName44?: string,
    errValue44?: string,
    erStatus44?: boolean,
    ctrlName45?: string,
    erStatus45?: boolean,
    errValue45?: string,
    ctrlName46?: string,
    erStatus46?: boolean,
    errValue46?: string,
    ctrlName47?: string,
    erStatus47?: boolean,
    errValue47?: string,
    ctrlName48?: string,
    erStatus48?: boolean,
    errValue48?: string,
    ctrlName49?: string,
    erStatus49?: boolean,
    errValue49?: string,
  } = null;
  OrderingDoctor: string = '';
  autoExtractionForm: FormGroup;
  pageViewMode: PageViewModeType = 'single';
  drpDoctor: { name: string; id: string }[] = [];
  documentClassification: DocumentClassificationDto[] = [];
  demographicsPendingValidationCount: number = 0;
  initialFaceToFacePendingValidationCount: number = 0;
  subscription$: Subscription[] = [];
  constructor(
    private patientDropdownService: PatientMasterDropdownService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.intializeForms();
    this.loadDocumentTypes();
  }
  currentlyOpenAcc(accName: string){
    
  }
  //Initialize Forms
  intializeForms() {
    this.autoExtractionForm = this.formBuilder.group({
      chkIsRulesNeeded: new FormControl(''),
      drpDXType: new FormControl(''),
      drpPrdSetupType: new FormControl(''),
    });
  }

  //Load Document Types
  loadDocumentTypes() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [PatientDropdowns.DocumentTypes];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (response) => {
          if (response && response.documentTypes) {
            this.documentClassification = response.documentTypes.map(
              (value) => ({
                docTypeId: value.id,
                documentType: value.documentType,
                documentPages: [],
                fileCount: 0,
              })
            );
            
            //this.drpDocumentTypeLoop = this.documentClassification;
          }
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientDropdown);
  }

  //List Selection Event-To enable the document move button
  onListSelected() {
    this.moveButtonDisabled = false;
  }
  //On Pages Load to Get the total Pages in the pdf
  public onPagesLoaded(pagecount: PagesLoadedEvent): void {
    this.totalPages = pagecount.pagesCount;
  }
  clearGender(){

  }
}
