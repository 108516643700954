import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientSearchService } from 'projects/patient/src/app/patient-proxy/patient';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { AddDocumentBinComponent } from '../add-document-bin/add-document-bin.component';
import { AutoIntakeDocumentDetailsDTO } from '../admin-proxy/platform-app-management/rcm/patient-management/patient/dto/models';
import { FileUploadService } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload';
import { FileUploadsWithBatchIdDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/dto';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import { IdentityUserDto } from '../admin-proxy/platform-app-management/volo/abp/identity/models';
import { Patient } from '../approval-list/approval-list.component';

@Component({
  selector: 'app-sub-batch-list-temp',
  templateUrl: './sub-batch-list-temp.component.html',
  styleUrls: ['./sub-batch-list-temp.component.scss'],
  providers: [DatePipe],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
})
export class SubBatchListTempComponent implements OnInit {
  patientSearchForm: FormGroup;

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  patientData: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: true }) Patientsort: MatSort;
  @ViewChild(MatPaginator, { static: true }) patientPaginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) PatienttableRef: MatTable<any>;
  displayedColumns: string[] = [
    'Name',
    'Patient Id',
    'Email Address',
    'Date Of Birth',
    'LockStatus',
  ];
  contextMenuPosition = { x: '0px', y: '0px' };
  isLoading: boolean = true;
  strPageType: string = 'subBatchList';
  organizationUnitId: string = '';
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  expandedElement: any | null;
  arrUsersList: IdentityUserDto[] = [];
  tenantId: string = "";
  arrDisplayedColumns: string[] = [];
  arrTableData: any[] = [];
  batchId: string = "";
  chkdisableButton: boolean;
  isShownSaveButton: boolean = false;
  isShownCreateButton: boolean = false;
  isShownDeleteButton: boolean = false;
  isShownViewButton: boolean = false;
  subBatchUploadBinForm: FormGroup;
  patientId: string = "";

  constructor(
    private title: Title,
    private table: TableService,
    public userService: UserService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public dateValidators: DateValidator,
    private fileUploadService: FileUploadService,
    private activatedRoute: ActivatedRoute,
    private patientSearchService: PatientSearchService,
    private datepipe: DatePipe,
    private router: Router
  ) { }
  //! Page Initialization Function

  ngOnInit(): void {
    this.patientSearchForm = this.fb.group({
      Name: '',
      PatientId: '',
      Address: '',
      SSN: '',
      AccountNumber: '',
      EmailAddress: '',
      DateOfBirth: new FormControl('', this.dateValidators.dateVaidator),
      Moblie: '',
      LockStatus: '',
      currStatus:''
    });
    const valueChanges: Subscription = this.patientSearchForm.valueChanges.subscribe(
      (value: Patient) => {
        let filter: Patient = {
          PatientId: value.PatientId?.trim()?.toLowerCase(),
          Name: value.Name?.trim()?.toLowerCase(),
          Address: value.Address?.trim()?.toLowerCase(),
          SSN: value.SSN?.trim()?.toLowerCase(),
          AccountNumber: value.AccountNumber?.trim()?.toLowerCase(),
          EmailAddress: value.EmailAddress?.trim()?.toLowerCase(),
          DateOfBirth: value.DateOfBirth?.trim()?.toLowerCase(),
          Moblie: value.Moblie?.trim()?.toLowerCase(),
          LockStatus: value.LockStatus?.trim()?.toLowerCase(),
          currStatus: value.currStatus?.trim()?.toLowerCase(),
        };
        if (this.patientSearchForm.valid) {
          this.patientData.filter = JSON.stringify(filter);
          this.filtertable(filter);
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.arrDisplayedColumns = [ 'Options','batchNo', 'stat', 'documentName', 'assignedBy', 'assignedTo', 'assignedTime', 'uploadedFileStatus',];

    this.title.setTitle('Qsecure | Sub Batch');
    this.activatedRoute.paramMap.subscribe(response => {
      this.batchId = response.get('id') ?? "";
      this.getUsersList();
    })
    this.table.getfileUploadUpdate().subscribe(value => {
      if (value == true) {
        this.isShownSaveButton = true;
      }
      else {
        this.isShownSaveButton = false;
      }
    });

    this.table.getfileUploadDelete().subscribe(value => {
      if (value == true) {
        this.isShownDeleteButton = true;
      }
      else {
        this.isShownDeleteButton = false;
      }
    });

    this.table.getfileUploadView().subscribe(value => {
      if (value == true) {
        this.isShownViewButton = true;
      }
      else {
        this.isShownViewButton = false;
      }
    });


    this.subBatchUploadBinForm = this.fb.group({
      batchId: new FormControl(""),
      documentName: new FormControl(""),
      assignedBy: new FormControl(""),
      assignedTo: new FormControl(""),
      assignedTime: new FormControl("", this.dateValidators.dateVaidator),
      uploadedFileStatus: new FormControl(""),
    })
    this.subBatchUploadBinForm.valueChanges.subscribe(value => {

      if (this.subBatchUploadBinForm.valid && this.batchId !== "" && this.batchId !== undefined && this.batchId !== null) {
        const sDefaultBatchId: string = this.subBatchUploadBinForm.value.batchId ?? "";
        const documentName: string = this.subBatchUploadBinForm.value.documentName ?? "";
        const assignedBy: string = this.subBatchUploadBinForm.value.assignedBy ?? "";
        const assignedTo: string = this.subBatchUploadBinForm.value.assignedTo ?? "";
        const assignedTime: string = this.subBatchUploadBinForm.value.assignedTime ?? "";
        const uploadedFileStatus: string = this.subBatchUploadBinForm.value.uploadedFileStatus ?? "";
        this.searchByInputs(this.batchId, sDefaultBatchId,
          documentName,
          assignedTo,
          assignedBy,
          assignedTime,
          uploadedFileStatus);
      }
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })

  }


  // filter
  filtertable(filter: Patient) {
    let PatientName = filter.Name;
    let DefaultPatientId = filter.PatientId;
    let SSN = filter.SSN;
    let AccountNumber = filter.AccountNumber;
    let EmailId = filter.EmailAddress;
    let Mobile = filter.Moblie;
    let Fax = '';
    let Address = filter.Address;
    let dobDay = filter.DateOfBirth;
    let currStatus = filter .currStatus;
    let lockStatus = filter.LockStatus;
    let PATIENTDATA = [];
    var today = new Date(dobDay);
    var date =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();
    this.patientSearchService
      .searchPatientBySPatientNameAndSDefaultPatientIdAndSSSNAndSAccountNumberAndSEmailIdAndSMobileAndSFaxAndSAddressAndDtDOBAndSLockStatusAndSSearchNameAndSCurrentStatus(
        PatientName,
        DefaultPatientId,
        SSN,
        AccountNumber,
        EmailId,
        Mobile,
        Fax,
        Address,
        dobDay,
        lockStatus,
        'uploadSearch',
        currStatus
      )
      .subscribe(
        (data) => {
          data &&
            data?.items?.forEach((element) => {
              if (
                element?.addresses?.length !== 0
              ) {
                const lastDocumentBlobDetails: AutoIntakeDocumentDetailsDTO =
                  element.autoIntakeDocumentDetails.slice(
                    element.autoIntakeDocumentDetails.length - 1,
                    element.autoIntakeDocumentDetails.length
                  )[0] || null;
                const dateOfBirth =
                  element?.personals?.dateOfBirth === null
                    ? ''
                    : this.datepipe.transform(
                      element?.personals?.dateOfBirth,
                      'MM/dd/yyyy'
                    ) ?? '';
                PATIENTDATA.push({
                  id: element?.id,
                  PatientId: element?.defaultPatientId?.toString(),
                  Address:
                    element?.addresses[0]?.address === null || undefined
                      ? ''
                      : element?.addresses[0]?.address,
                  Name:
                    element?.personals?.firstName +
                    ' ' +
                    element?.personals?.middleName +
                    ' ' +
                    element?.personals?.lastName,
                  DateOfBirth: dateOfBirth,
                  SSN:
                    element?.personals?.ssn === null || undefined
                      ? ''
                      : element?.personals?.ssn,
                  AccountNumber:
                    element?.personals?.accountNumber === null || undefined
                      ? ''
                      : element?.personals?.accountNumber,
                  EmailAddress:
                    element?.personals?.emailId === null || undefined
                      ? ''
                      : element?.personals?.emailId,
                  Moblie:
                    element?.addresses[0]?.mobile === null || undefined
                      ? ''
                      : element?.addresses[0]?.mobile,
                  isAutoIntake: element.isAutoIntake,
                  autoIntakeDocumentDetails: lastDocumentBlobDetails ?? null,
                  LockStatus: element?.patientLock?.status,
                  LockStatusWithName:
                    element?.patientLock?.status,
                  highlighted: false

                });

              }
              this.patientData = new MatTableDataSource(PATIENTDATA);
              this.patientData.sort = this.Patientsort;
              this.patientData.paginator = this.patientPaginator;
            });

        },
        (error) => {
        }
      );

    this.patientData = new MatTableDataSource([]);
    this.patientData.sort = this.Patientsort;
    this.patientData.paginator = this.patientPaginator;
  }
  //! Get Sub Batch Details By Search Values
  searchByInputs(batchId: string, sDefaultBatchId: string, sFileName: string, sAssignedTo: string, sAssignedBy: string, dtAssignedTime: string, sStatus: string) {
    this.fileUploadService.searchFilesInBatchByBatchIdAndSDefaultBatchIdAndSFileNameAndSAssignedToAndSAssignedByAndDtAssignedTimeAndSStatus(batchId,
      sDefaultBatchId,
      sFileName,
      sAssignedTo,
      sAssignedBy,
      dateYYYYMMDDtoMMDDYYYY(dtAssignedTime),
      sStatus).subscribe(response => {

        this.arrTableData = [];
        this.dataSource = new MatTableDataSource(this.arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        let arrTableData: subBatchDetails[] = [];
        const responseBatchData: FileUploadsWithBatchIdDTO[] = response;
        arrTableData = this.extractSubBatchTableDetails(responseBatchData);
        this.arrTableData = [...arrTableData];
        this.dataSource = new MatTableDataSource(this.arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.dataSource = new MatTableDataSource(this.arrTableData);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });

  }

  //! Extract Sub Batch Table Details
  private extractSubBatchTableDetails(batchDataTable: FileUploadsWithBatchIdDTO[]): subBatchDetails[] {
    let arrTableData: subBatchDetails[] = [];
    try {
      if (batchDataTable &&  batchDataTable?.length !== 0 && batchDataTable !== null) {
        const listFileUploads = batchDataTable;
        listFileUploads.forEach(element => {
          let data: subBatchDetails = {
            defaultBatchId: element?.defaultBatchId ?? "",
            fileId: element?.fileId ?? "",
            uploadedFileName: element?.uploadedFileName ?? "",
            isStat: element?.isStat === 1 ? true : false,
            blobName: element?.blobName ?? "",
            uploadedFileStatus: element?.uploadedFileStatus ?? "",
            assignedTo: this.getUserName(element?.assignedTo ?? "") ?? "",
            assignedTime: dateFormatter(element?.assignedTime ?? "") ?? "",
            assignedBy: this.getUserName(element?.assignedBy ?? "") ?? "",
            updatedBy: this.getUserName(element?.updatedBy ?? "") ?? "",
            updatedOn: dateFormatter(element?.updatedOn ?? "") ?? "",
            //reasonForNotExtracted: element?.reasonForNotExtracted ?? "",
            reasonForNotExtracted: "",
            highlighted: false
          };
          arrTableData.push(data);
        });
      }
    } catch (error) {
      arrTableData = [];
    }
    return arrTableData;
  }

  clicktoUpdate(fileId: string) {
    if (!this.patientId && fileId) {
      const url = '/#/ViewDocumentDetailsAndApprove/' + [fileId.toString()] + '/' + [this.batchId.toString()];
      window.open(url, '_blank');
      return
    }
    const url = '/#/ViewDocumentDetailsAndApprove/' + [fileId.toString()] + '/' + this.batchId + '/' + [this.patientId.toString()];
    window.open(url, '_blank');
  }

  //! Get  Users Name
  private getUserName(value: string) {
    if (value && typeof value === "string" && value?.length >= 36) {
      return this.arrUsersList?.filter(u => u?.id + '/' + u?.userName === value)[0]?.userName ?? "";
    }
    return ""
  }
  //! Get  Users List
  private getUsersList() {
    this.isLoading = true
    this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;
      this.batchId !== "" && this.batchId !== undefined && this.batchId !== null && this.getbatchTableData();
      this.isLoading = false;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }



  //! Get Sub Batch Inital Table Details
  getbatchTableData() {
    this.searchByInputs(this.batchId, "", "", "", "", "", "");
  }




  //! Update Single File Details
  update(value: string) {
    const dialogRef = this.dialog.open(AddDocumentBinComponent, {
      data: { batchId: this.batchId, fileId: value },
      minHeight: '40vh',
      minWidth: '60vw',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getbatchTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  onContextMenuDeselecteAction(row: subBatchDetails) {
    let data: subBatchDetails[] = this.patientData?.data ?? [];
    data?.forEach(e => e.highlighted = false);
    this.patientData.data = data;
    this.patientData = new MatTableDataSource(data);
    this.patientData.sort = this.Patientsort;
    this.patientData.paginator = this.patientPaginator;
    row.highlighted = false;
    this.patientId = "";
  }
  onContextMenuSelectAction(row: Patient) {
    let data: subBatchDetails[] = this.patientData?.data ?? [];
    data?.forEach(e => e.highlighted = false);
    this.patientData.data = data;
    this.patientData = new MatTableDataSource(data);
    this.patientData.sort = this.Patientsort;
    this.patientData.paginator = this.patientPaginator;
    row.highlighted = !row?.highlighted;
    this.patientId = row?.id ?? "";
  }
  onContextMenu(event: MouseEvent, item: any) {

    event?.preventDefault();
    this.contextMenuPosition.x = event?.clientX + 'px';
    this.contextMenuPosition.y = event?.clientY + 'px';
    this.contextMenu.menuData = { 'item': item };
    this.contextMenu?.menu?.focusFirstItem('mouse');
    this.contextMenu?.openMenu();
  }


  //! Delete Single File Details
  deleteFileByID(value: string) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.value) {
        value && this.fileUploadService.deleteFileByBatchIdAndFileId(this.batchId, value).subscribe(response => {
          this.getbatchTableData();
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }
}
export interface subBatchDetails {
  defaultBatchId: string;
  fileId?: string;
  uploadedFileName: string;
  isStat: boolean;
  blobName: string;
  uploadedFileStatus: string;
  assignedTo: string;
  assignedTime: string;
  assignedBy: string;
  updatedOn: string;
  updatedBy: string;
  reasonForNotExtracted?: string;
  highlighted?: boolean;
}
export function dateFormatter(strDate: string): string {
  if (typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    var options: Intl.DateTimeFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit', hour: "2-digit", minute: "2-digit" };
    let latest_date = new Intl.DateTimeFormat('en-US', options).format(new Date(strDate));
    return latest_date;
  }
  return "";
}
export function dateYYYYMMDDtoMMDDYYYY(strDate: string): string {
  if (strDate && strDate !== "" && typeof strDate === 'string' && strDate !== null && strDate !== undefined && strDate.length >= 1 && !isNaN(new Date(strDate).getTime())) {
    let latest_date = Intl.DateTimeFormat('en-US')?.format(new Date(strDate))
    return latest_date;
  }
  return "";
}
