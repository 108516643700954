import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CreateUpdatePatientAddressComponent } from '../create-update-patient-address/create-update-patient-address.component';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { Subscription } from 'rxjs';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DataTableDirective } from 'angular-datatables';
@Component({
  selector: 'app-patient-address-list',
  templateUrl: './patient-address-list.component.html',
  styleUrls: ['./patient-address-list.component.scss'],
})
export class PatientAddressListComponent implements OnInit {
  addressList: any[] = [];
  isAddressTableLoaded = true;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtAddressTableOptions: any = {
    responsive: true,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Patient Address List'
      },
    ],
    paging: false,
    scrollCollapse: true,
    scrollY: '60vh',
    // scrollX: true,
    info: true,
  };
  subscription$: Subscription[] = [];
  constructor(
    private dialog: MatDialog,
    private mmOrderService: MmOrderService,
    private communicationService: CommunicationService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      chartId: string;
    } = {
      patientId: defaultGuid,
      chartId: '',
    }
  ) {
    this.communicationService.addressListFunctionCall$.subscribe(
      (patientId) => {
        this.loadPatientAddress(patientId);
      }
    );
  }

  ngOnInit() {
    this.loadPatientAddress(this.data.patientId);
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //To Add the new Address
  addNewAddress() {
    const dialogRef = this.dialog.open(CreateUpdatePatientAddressComponent, {
      data: {
        patientId: this.data.patientId,
        chartId: this.data.chartId,
        addressId: defaultGuid,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  //Method to load the Address Details of the Patient
  loadPatientAddress(patientId: string) {
    this.isAddressTableLoaded = false;
    const addressDetails = this.mmOrderService
      .getPatientAddressDetailsforOrderByPatientId(this.data.patientId)
      .subscribe(
        (response) => {
          this.addressList = response;
          this.isAddressTableLoaded = true;
        },
        (err) => {
          this.isAddressTableLoaded = true;
        }
      );
    this.subscription$.push(addressDetails);
  }
}
