<div class="container-fluid">
  <div class="row">
    <div class="col-12 mb-3">
      <div class="row">
        <div class="col-12 d-flex flex-row">
          <ng-container *ngFor="let data of menus">
            <label
              [ngClass]="getArrowClass(data)"
              [ngStyle]="{ 'background-color': data.color }"
              (click)="binActiveTab(data)"
              class="pointer customPointingLabel claim-status-label rounded-border down mb-2 py-2 px-2 text-center mr-1"
              matlabel
            >
              {{ data.status }} ({{ data.count }})
            </label>
          </ng-container>
        </div>
      </div>

      <div class="card card-body">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12 px-0">
                <p class="header-text mb-0 pl-3">Filters</p>
                <form class="pl-1" [formGroup]="orderBinQueueFilterForm">
                  <!-- Order No -->
                  <mat-form-field
                    class="col-1_5 custom-form-field"
                    appearance="none"
                  >
                    <mat-label class="drpLabel">Order No</mat-label>
                    <mat-select
                      class="custom-mat-select"
                      formControlName="drpOrderNo"
                      [(ngModel)]="lstSelectedOrderNos"
                      (selectionChange)="getOrderFileterList()"
                      multiple
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          (keyup)="loadOrderNoDropdown()"
                          placeholderLabel="Order No"
                          formControlName="txtOrderNo"
                          noEntriesFoundLabel="No Matches found"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let data of lstOrderNos"
                        [value]="data.orderId"
                        >{{ data.mmRefereceOrderId }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <!-- Chart Id -->
                  <mat-form-field
                    class="col-1_5 custom-form-field"
                    appearance="none"
                  >
                    <mat-label class="drpLabel">Chart Id</mat-label>
                    <mat-select
                      class="custom-mat-select"
                      formControlName="drpChartId"
                      [(ngModel)]="lstSelectedChartNos"
                      (selectionChange)="getOrderFileterList()"
                      multiple
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          (keyup)="loadChartNoDropdown()"
                          placeholderLabel="Chart Id"
                          formControlName="txtChartId"
                          noEntriesFoundLabel="No Matches found"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let data of lstChartNos"
                        [value]="data.spatientID"
                        >{{ data.chartId }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>

                  <!-- Patient Name -->
                  <mat-form-field
                    class="col-1_5 custom-form-field"
                    appearance="none"
                  >
                    <mat-label class="drpLabel">Patient</mat-label>
                    <mat-select
                      class="custom-mat-select"
                      formControlName="drpPatientName"
                      (selectionChange)="getOrderFileterList()"
                      [(ngModel)]="lstSelectedPatients"
                      multiple
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          placeholderLabel="Patient Name"
                          (keyup)="loadPatientDropdown()"
                          formControlName="txtPatientName"
                          noEntriesFoundLabel="No Matches found"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let data of lstPatients"
                        [value]="data.patId"
                        >{{ data.fullName }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>

                  <!-- Status -->
                  <mat-form-field
                    class="col-1_5 custom-form-field"
                    appearance="none"
                  >
                    <mat-label class="drpLabel">Status</mat-label>
                    <mat-select
                      (selectionChange)="getOrderFileterList()"
                      class="custom-mat-select"
                      formControlName="drpStatus"
                      [(ngModel)]="lstSelectedOrderStatus"
                      multiple
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          (keyup)="loadOrderStatusDropdown()"
                          placeholderLabel="Status"
                          formControlName="txtStatus"
                          noEntriesFoundLabel="No Matches found"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let data of lstOrderStatus"
                        [value]="data.orderStatusId"
                        >{{ data.description }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>

                  <!-- Insurance  -->
                  <mat-form-field
                    *ngIf="
                      activeTabBin == MyQueueBinStatus.Billing ||
                      activeTabBin == MyQueueBinStatus.Shipping
                    "
                    class="col-1_5 custom-form-field"
                    appearance="none"
                  >
                    <mat-label class="drpLabel">Insurance</mat-label>
                    <mat-select
                      (selectionChange)="getOrderFileterList()"
                      [(value)]="lstSelectedInsurance"
                      class="custom-mat-select dd-text"
                      formControlName="drpInsurance"
                      multiple
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          (keyup)="loadInsuranceDropdown()"
                          placeholderLabel="Insurance"
                          formControlName="txtInsurance"
                          noEntriesFoundLabel="No Matches found"
                        ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let data of lstInsurance"
                        [value]="data.payerId"
                        >{{ data.payerName }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>

                  <!--Order Date -->
                  <mat-form-field
                    *ngIf="activeTabBin == MyQueueBinStatus.SaleOrder"
                    class="dd-text col-2"
                  >
                    <mat-label class="drpLabel">Order Date</mat-label>
                    <mat-date-range-input [rangePicker]="orderDatePicker">
                      <input
                        matStartDate
                        matInput
                        placeholder="Start date"
                        formControlName="txtOrderFromDate"
                      />

                      <input
                        matEndDate
                        (dateChange)="onOrderDateChange()"
                        matInput
                        placeholder="End date"
                        formControlName="txtOrderToDate"
                      />
                    </mat-date-range-input>
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="orderDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker
                      #orderDatePicker
                    ></mat-date-range-picker>
                  </mat-form-field>

                  <!-- Supplies Date -->
                  <mat-form-field
                    class="dd-text col-2"
                    *ngIf="activeTabBin == MyQueueBinStatus.Resupply"
                  >
                    <mat-label class="drpLabel">Supplies Date</mat-label>
                    <mat-date-range-input [rangePicker]="suppliesDatePicker">
                      <input
                        matStartDate
                        matInput
                        placeholder="Start date"
                        formControlName="txtSuppliesFromDate"
                      />

                      <input
                        matEndDate
                        matInput
                        (dateChange)="onSuppliesDateChange()"
                        placeholder="End date"
                        formControlName="txtSuppliesToDate"
                      />
                    </mat-date-range-input>
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="suppliesDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker
                      #suppliesDatePicker
                    ></mat-date-range-picker>
                  </mat-form-field>
                </form>
              </div>
            </div>
            <mat-divider class="col-12 px-0 my-2"></mat-divider>
            <div
              class="d-flex justify-content-center"
              *ngIf="loadInboundOrderData"
            >
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>
            <app-order-bin-que-table
              *ngIf="!loadInboundOrderData"
              (nevigatePage)="changePage($event)"
              [orderTableData]="inboundOrderTableData"
              [activeTabBin]="activeTabBin"
              [currentPage]="currentPage" [totalPages]="totalPages"
            ></app-order-bin-que-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
