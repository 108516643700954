<table id="tblInsuranceReviewTableList" datatable [dtOptions]="dtNewInsuranceTableOptions"
    class="tbl hover row-border hover w-100 display">
    <thead>
        <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th colspan="4">Primary</th>
            <th colspan="4">Secondary</th>
            <th colspan="4">Tertiary</th>
            <th></th>
        </tr>
        <tr>
            <th><mat-icon>apps</mat-icon></th>
            <th [matTooltip]="'Chart No'">Chart No</th>
            <th [matTooltip]="'Patient Name'">Patient Name</th>
            <th [matTooltip]="'DOB'" class="text-center">DOB</th>
            <!--Primary-->
            <th [matTooltip]="'Insurance'" class="custstatus">Insurance</th>
            <th [matTooltip]="'Verification Validity'" class="custstatus text-center">Verification Validity</th>
            <th [matTooltip]="'Auth Validity'" class="custstatus text-center">Auth Validity</th>
            <!-- <th [matTooltip]="'Inserted/Updated On'" class="text-center">Inserted/Updated On</th> -->
            <th [matTooltip]="'Primary Inserted By'">Primary Inserted By</th>
            <th class="text-right" [matTooltip]="'Primary Inserted Date'" >Primary Inserted Date</th>
            <th [matTooltip]="'Primary Updated By'">Primary Updated By</th>
            <th class="text-right" [matTooltip]="'Primary Updated Date'" >Primary Updated Date</th>
            <!--Secondary-->
            <th [matTooltip]="'Insurance'" class="custstatus">Insurance</th>
            <th [matTooltip]="'Verification Validity'" class="custstatus text-center">Verification Validity</th>
            <th [matTooltip]="'Auth Validity'" class="custstatus text-center">Auth Validity</th>
            <!-- <th [matTooltip]="'Inserted/Updated On'" class="text-center">Inserted/Updated On</th> -->
            <th [matTooltip]="'Secondary Inserted By'">Secondary Inserted By</th>
            <th class="text-right" [matTooltip]="'Secondary Inserted Date'" >Secondary Inserted Date</th>
            <th [matTooltip]="'Secondary Updated By'">Secondary Updated By</th>
            <th class="text-right" [matTooltip]="'Secondary Updated Date'" >Secondary Updated Date</th>
            <!--Tertiary-->
            <th [matTooltip]="'Insurance'" class="custstatus">Insurance</th>
            <th [matTooltip]="'Verification Validity'" class="custstatus text-center">Verification Validity</th>
            <th [matTooltip]="'Auth Validity'" class="custstatus text-center">Auth Validity</th>
            <!-- <th [matTooltip]="'Inserted/Updated On'" class="text-center">Inserted/Updated On</th> -->
            <th [matTooltip]="'Tertiary Inserted By'">Tertiary Inserted By</th>
            <th class="text-right" [matTooltip]="'Tertiary Inserted Date'" >Tertiary Inserted Date</th>
            <th [matTooltip]="'Tertiary Updated By'">Tertiary Updated By</th>
            <th class="text-right" [matTooltip]="'Tertiary Updated Date'" >Tertiary Updated Date</th>
            <th [matTooltip]="'CSR'">CSR</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of newInsuranceListdata"
            (click)="tblItemSelectedRow(data.patientId,data.documentId,data.chartId)">

            <td>
                <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu"
                    [matTooltip]="'Open Options'">more_vert</mat-icon>
                <img src="assets/images/NewInsurance/insurance.png" alt="View"
                    class="sea-green-icon-ins pointer ml-2 mb-2" (click)="viewInsuranceHistory(data.patientId)"
                    [matTooltip]="'View Insurance History'">
                <!-- <mat-icon class="custom-icon ml-2" (click)="viewInsuranceHistory(data.patientId)">remove_red_eye</mat-icon> -->
                <mat-menu class="custom-mat-menu" #menu="matMenu" [matTooltip]="'View Insurance History'">
                    <button mat-menu-item (click)="openAddNotesPopUp()">Add Notes</button>
                    <button mat-menu-item (click)="openViewNotesPopup()">View Patient
                        Notes</button>
                    <a [class.disabled]="isEmpty(data.blobName) || data?.isSorted === false"
                    [routerLink]="'/view_document_tiff/' + data.docId +'/'+ true"
                        target="_blank" class="preview-link">
                        <button [disabled]="isEmpty(data.blobName) || data?.isSorted === false" mat-menu-item>View Document Images</button>
                    </a>
                    <button mat-menu-item (click)="viewPatientLedger(data.patientId)">View Patient Ledger</button>
                    <mat-divider></mat-divider>

                    <!-- <button mat-menu-item (click)="addPolicy(data)" [disabled]="
                    data.patientId === defaultGuid ||
                     data.patientId === null ||
                    data.patientId === '' ||data.patientId ==='undefined'||
                    !((data.priVerificationStatus === 'New'||data.priVerificationStatus === null) &&
                    (data.secVerificationStatus === 'New' || data.secVerificationStatus === null)&&
                    (data.terVerificationStatus === 'New'||data.terVerificationStatus === null))
                  " >
                      Do Verification - Primary
                    </button> -->

                    <button mat-menu-item (click)="addPolicy(data,1)" [disabled]="data?.primary[0]?.verificationStatus!==4 ||  data.patientId === defaultGuid ||
                    data.patientId === null || data.patientId === '' || data.patientId ==='undefined'">
                        Do Verification - Primary
                    </button>
                    <button mat-menu-item (click)="addPolicy(data,2)" [disabled]="data?.secondary[0]?.verificationStatus!==4 ||  data.patientId === defaultGuid ||
                    data.patientId === null || data.patientId === '' || data.patientId ==='undefined'">
                        Do Verification - Secondary
                    </button>
                    <button mat-menu-item (click)="addPolicy(data,3)" [disabled]="data?.tertiary[0]?.verificationStatus!==4 ||  data.patientId === defaultGuid ||
                    data.patientId === null || data.patientId === '' || data.patientId ==='undefined'">
                        Do Verification - Teritary
                    </button>
                    <a [routerLink]="['/emailprintletter/'+data.patientId]" target="_blank">
                    <button [disabled]="true" mat-menu-item>Email supplies Reminder</button>
                    </a>
                    <mat-divider></mat-divider>
                    <a [routerLink]="'/orders/saleOrder/' + data.patientId + '/' + defaultGuid+'/'+'0' " target="_blank"
                        class="preview-link">
                        <button mat-menu-item>Start New Order</button>
                    </a>
                    <mat-divider></mat-divider>
                    <a [routerLink]="'/settings/calender/' + data.patientId " target="_blank" class="preview-link">
                        <button mat-menu-item>Schedule Appointment</button>
                    </a>
                    <mat-divider></mat-divider>

                    <!-- <button mat-menu-item (click)="openNewVerification(data.patientId,false)">Ondemand/Online
                        Verification
                        Request</button> -->

                        <button mat-menu-item (click)="ondemandOnlineVerification(data)">Ondemand/Online Verification
                            Request</button>
                    <mat-divider></mat-divider>

                    <!-- <button mat-menu-item (click)="openNewVerification(data.patientId,true)">Online
                        Verification</button> -->
                    <mat-divider></mat-divider>
                    <a [class.disabled]="data.isMedicarePolicy=== 0 ||  isEmpty(data.blobName) || isEmpty(data.documentId || data.documentId === defaultGuid)"
                    [routerLink]="'/do_checklist/' + data.patientId + '/' + '/' + null"
                        target="_blank" class="preview-link">
                        <button
                            [disabled]="isEmpty(data.blobName) || isEmpty(data.documentId) || data.isMedicarePolicy=== 0 || data.documentId === defaultGuid"
                            mat-menu-item>New CheckList</button>
                    </a>
                    <mat-divider></mat-divider>
                    <button [disabled]="true" mat-menu-item>Text Conversation</button>
                    <button [disabled]="true" mat-menu-item>Mark Processed</button>
                </mat-menu>
            </td>

            <td [matTooltip]="data?.chartId || '-'">
                <a [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)"
                    [routerLink]="'/patientCreateEditTab/' + data.patientId + '/' + data.documentId" target="_blank"
                    class="preview-link">{{data?.chartId || '-'}}
                </a>
            </td>

            <td [matTooltip]="data?.patientName || '-'" (dblclick)="onRowDoubleClickV1(data?.patientName || '-')">{{
                data?.patientName || '-' }}</td>

            <td [matTooltip]="data?.dateOfBirth"
                (dblclick)="onRowDoubleClickV1(data?.dateOfBirth)" class="text-center">
                {{ data?.dateOfBirth }}
            </td>

            <!--Primary-->
            <td [matTooltip]="data?.primary[0]?.insurance || '-'"
                (dblclick)="onRowDoubleClickV1(data?.primary[0]?.insurance || '-')" class="custstatus">
                {{ data?.primary[0]?.insurance || '-' }}
            </td>
            <!--Verfication Status-->
            <td (dblclick)="onRowDoubleClickV1(data?.primary[0]?.verValidity || '-')" class="text-center custstatus">
                <i class="fa fa-check checkicon" *ngIf="data?.primary[0]?.verificationStatus === 2"
                    [matTooltip]="data?.primary[0]?.vStatus"></i>
                <i class="fa fa-eye eyeicon ml-2" *ngIf="data?.primary[0]?.verificationStatus === 2"
                    [matTooltip]="'View Verification'"
                    (click)="viewVerificationById(data?.patientId,data?.primary[0]?.verificationId,data?.primary[0]?.vStatus,data?.primary[0]?.policyId)"></i>
                <i class="fa fa-times cansicon ml-2" *ngIf="data?.primary[0]?.verificationStatus === 3"
                    [matTooltip]="data?.primary[0]?.vStatus"></i>
                <i class="fa fa-exclamation expicon ml-2" *ngIf="data?.primary[0]?.verificationStatus === 4"
                    [matTooltip]="data?.primary[0]?.vStatus"></i>
                <i class="fa fa-clock-o pendingicon ml-2" *ngIf="data?.primary[0]?.verificationStatus === 1"
                    [matTooltip]="data?.primary[0]?.vStatus"></i>
                <span class="ml-2" [matTooltip]="data?.primary[0]?.verValidity || '-'"
                    *ngIf="data?.primary[0]?.verificationStatus != 1">{{ data?.primary[0]?.verValidity || '-' }}</span>
                <span class="ml-2" [matTooltip]="data?.primary[0]?.verValidity"
                    *ngIf="data?.primary[0]?.verificationStatus === 1">{{ data?.primary[0]?.verValidity }}</span>
            </td>
            <!--Auth Status-->
            <td (dblclick)="onRowDoubleClickV1(data?.primary[0]?.authValidity || '-')" class="text-center custstatus">
                <i class="fa fa-check checkicon" *ngIf="data?.primary[0]?.authStatus === 2"
                    [matTooltip]="data?.primary[0]?.aStatus"></i>
                <i class="fa fa-eye eyeicon ml-2" *ngIf="data?.primary[0]?.authStatus === 2"
                    [matTooltip]="'View Verification'"
                    (click)="viewVerificationById(data?.patientId,data?.primary[0]?.verificationId,data?.primary[0]?.aStatus,data?.primary[0]?.policyId)"></i>
                <i class="fa fa-file-o fileicon ml-2" *ngIf="data?.primary[0]?.authStatus === 4"
                    [matTooltip]="data?.primary[0]?.aStatus"></i>
                <i class="fa fa-times cansicon ml-2" *ngIf="data?.primary[0]?.authStatus === 5"
                    [matTooltip]="data?.primary[0]?.aStatus"></i>
                <i class="fa fa-exclamation expicon ml-2" *ngIf="data?.primary[0]?.authStatus === 6"
                    [matTooltip]="data?.primary[0]?.aStatus"></i>
                <i class="fa fa-clock-o pendingicon ml-2"
                    *ngIf="data?.primary[0]?.authStatus === 1 || data?.primary[0]?.authStatus === 3"
                    [matTooltip]="data?.primary[0]?.aStatus"></i>
                <span class="ml-2" [matTooltip]="data?.primary[0]?.authValidity || '-'"
                    *ngIf="data?.primary[0]?.authStatus !== 1 && data?.primary[0]?.authStatus !== 3"> {{
                    data?.primary[0]?.authValidity || '-' }}</span>
                <span class="ml-2" [matTooltip]="data?.primary[0]?.authValidity"
                    *ngIf="data?.primary[0]?.authStatus === 1 && data?.primary[0]?.authStatus === 3"> {{
                    data?.primary[0]?.authValidity }}</span>
            </td>

            <td [matTooltip]="data?.primary[0]?.createdBy || '-'"
            (dblclick)="onRowDoubleClickV1(data?.primary[0]?.createdBy || '-')">{{
              data?.primary[0]?.createdBy || '-' }}</td>

            <td [matTooltip]="getFormattedDate(data?.primary[0]?.creationTime)"
            (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.primary[0]?.creationTime))"
            class="text-center">
            {{ getFormattedDate(data?.primary[0]?.creationTime) }}
        </td>
        <td [matTooltip]="data?.primary[0]?.modifyBy|| '-'"
            (dblclick)="onRowDoubleClickV1(data?.primary[0]?.modifyBy || '-')">{{
              data?.primary[0]?.modifyBy || '-' }}</td>

        <td class="text-right" [matTooltip]="getFormattedDate(data?.primary[0]?.lastModificationTime)"
            (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.primary[0]?.lastModificationTime))" >
            {{ getFormattedDate(data?.primary[0]?.lastModificationTime) }}
        </td>

            <!--Secondary-->
            <td [matTooltip]="data?.secondary[0]?.insurance || '-'"
                (dblclick)="onRowDoubleClickV1(data?.secondary[0]?.insurance || '-')" class="custstatus">
                {{ data?.secondary[0]?.insurance || '-' }}
            </td>
            <!--Verfication Status-->
            <td (dblclick)="onRowDoubleClickV1(data?.secondary[0]?.verValidity || '-')" class="text-center custstatus">
                <i class="fa fa-clock-o pendingicon ml-2" *ngIf="data?.secondary[0]?.verificationStatus === 1"
                    [matTooltip]="data?.secondary[0]?.vStatus"></i>
                <i class="fa fa-check checkicon" *ngIf="data?.secondary[0]?.verificationStatus === 2"
                    [matTooltip]="data?.secondary[0]?.vStatus"></i>
                <i class="fa fa-eye eyeicon ml-2" *ngIf="data?.secondary[0]?.verificationStatus === 2"
                    [matTooltip]="'View Verification'"
                    (click)="viewVerificationById(data?.patientId,data?.secondary[0]?.verificationId,data?.secondary[0]?.vStatus,data?.secondary[0]?.policyId)"></i>
                <i class="fa fa-times cansicon ml-2" *ngIf="data?.secondary[0]?.verificationStatus === 3"
                    [matTooltip]="data?.secondary[0]?.vStatus"></i>
                <i class="fa fa-exclamation expicon ml-2" *ngIf="data?.secondary[0]?.verificationStatus === 4"
                    [matTooltip]="data?.secondary[0]?.vStatus"></i>
                <span class="ml-2" [matTooltip]="data?.secondary[0]?.verValidity || '-'"
                    *ngIf="data?.secondary[0]?.verificationStatus != 1">{{ data?.secondary[0]?.verValidity || '-'
                    }}</span>
                <span class="ml-2" [matTooltip]="data?.secondary[0]?.verValidity"
                    *ngIf="data?.secondary[0]?.verificationStatus === 1">{{ data?.secondary[0]?.verValidity }}</span>
            </td>
            <!--Auth Status-->
            <td (dblclick)="onRowDoubleClickV1(data?.secondary[0]?.authValidity || '-')" class="text-center custstatus">
                <i class="fa fa-clock-o pendingicon ml-2"
                    *ngIf="data?.secondary[0]?.authStatus === 1 || data?.secondary[0]?.authStatus === 3"
                    [matTooltip]="data?.secondary[0]?.aStatus"></i>
                <i class="fa fa-check checkicon" *ngIf="data?.secondary[0]?.authStatus === 2"
                    [matTooltip]="data?.secondary[0]?.aStatus"></i>
                <i class="fa fa-eye eyeicon ml-2" *ngIf="data?.secondary[0]?.authStatus === 2"
                    [matTooltip]="'View Insurance'"
                    (click)="viewVerificationById(data?.patientId,data?.secondary[0]?.verificationId,data?.secondary[0]?.aStatus,data?.secondary[0]?.policyId)"></i>
                <i class="fa fa-file-o fileicon ml-2" *ngIf="data?.secondary[0]?.authStatus === 4"
                    [matTooltip]="data?.secondary[0]?.aStatus"></i>
                <i class="fa fa-times cansicon ml-2" *ngIf="data?.secondary[0]?.authStatus === 5"
                    [matTooltip]="data?.secondary[0]?.aStatus"></i>
                <i class="fa fa-exclamation expicon ml-2" *ngIf="data?.secondary[0]?.authStatus === 6"
                    [matTooltip]="data?.secondary[0]?.aStatus"></i>
                <span class="ml-2" [matTooltip]="data?.secondary[0]?.authValidity || '-'"
                *ngIf="data?.secondary[0]?.authStatus !== 1 && data?.secondary[0]?.authStatus !== 3"> {{
                data?.secondary[0]?.authValidity || '-' }}</span>
                <span class="ml-2" [matTooltip]="data?.secondary[0]?.authValidity"
                *ngIf="data?.secondary[0]?.authStatus === 1 && data?.secondary[0]?.authStatus === 3"> {{
                data?.secondary[0]?.authValidity }}</span>
            </td>

            <td [matTooltip]="data?.secondary[0]?.createdBy || '-'"
            (dblclick)="onRowDoubleClickV1(data?.secondary[0]?.createdBy || '-')">{{
              data?.secondary[0]?.createdBy || '-' }}</td>

            <td [matTooltip]="getFormattedDate(data?.secondary[0]?.creationTime)"
            (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.secondary[0]?.creationTime))"
            class="text-center">
            {{ getFormattedDate(data?.secondary[0]?.creationTime) }}
        </td>
        <td [matTooltip]="data?.secondary[0]?.modifyBy|| '-'"
            (dblclick)="onRowDoubleClickV1(data?.secondary[0]?.modifyBy || '-')">{{
              data?.secondary[0]?.modifyBy || '-' }}</td>

        <td class="text-right" [matTooltip]="getFormattedDate(data?.secondary[0]?.lastModificationTime)"
            (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.secondary[0]?.lastModificationTime))" >
            {{ getFormattedDate(data?.secondary[0]?.lastModificationTime) }}
        </td>

            <!--Tertiary-->
            <td [matTooltip]="data?.tertiary[0]?.insurance || '-'"
                (dblclick)="onRowDoubleClickV1(data?.tertiary[0]?.insurance || '-')" class="custstatus">
                {{ data?.tertiary[0]?.insurance || '-' }}
            </td>
            <!--Verfication Status -->
            <td
                (dblclick)="onRowDoubleClickV1(data?.tertiary[0]?.verValidity || '-')" class="text-center custstatus">
                <i class="fa fa-clock-o pendingicon ml-2" *ngIf="data?.tertiary[0]?.verificationStatus === 1"
                    [matTooltip]="data?.tertiary[0]?.vStatus"></i>
                <i class="fa fa-check checkicon" *ngIf="data?.tertiary[0]?.verificationStatus === 2"
                    [matTooltip]="data?.tertiary[0]?.vStatus"></i>
                <i class="fa fa-eye eyeicon ml-2" *ngIf="data?.tertiary[0]?.verificationStatus === 2"
                    [matTooltip]="'View Insurance'"
                    (click)="viewVerificationById(data?.patientId,data?.tertiary[0]?.verificationId,data?.tertiary[0]?.vStatus,data?.tertiary[0]?.policyId)"></i>
                <i class="fa fa-times cansicon ml-2" *ngIf="data?.tertiary[0]?.verificationStatus === 3"
                    [matTooltip]="data?.tertiary[0]?.vStatus"></i>
                <i class="fa fa-exclamation expicon ml-2" *ngIf="data?.tertiary[0]?.verificationStatus === 4"
                    [matTooltip]="data?.tertiary[0]?.vStatus"></i>
                <span class="ml-2" [matTooltip]="data?.tertiary[0]?.verValidity || '-'"
                    *ngIf="data?.tertiary[0]?.verificationStatus != 1">{{ data?.tertiary[0]?.verValidity || '-'
                    }}</span>
                <span class="ml-2" [matTooltip]="data?.tertiary[0]?.verValidity"
                    *ngIf="data?.tertiary[0]?.verificationStatus === 1">{{ data?.tertiary[0]?.verValidity }}</span>
            </td>
            <!--Auth Status-->
            <td
                (dblclick)="onRowDoubleClickV1(data?.tertiary[0]?.authValidity || '-')" class="text-center custstatus">
                <i class="fa fa-clock-o pendingicon ml-2"
                    *ngIf="data?.tertiary[0]?.authStatus === 1 || data?.tertiary[0]?.authStatus === 3"
                    [matTooltip]="data?.tertiary[0]?.aStatus"></i>
                <i class="fa fa-check checkicon" *ngIf="data?.tertiary[0]?.authStatus === 2"
                    [matTooltip]="data?.tertiary[0]?.aStatus"></i>
                <i class="fa fa-eye eyeicon ml-2" *ngIf="data?.tertiary[0]?.authStatus === 2"
                    [matTooltip]="'View Insurance'"
                    (click)="viewVerificationById(data?.patientId,data?.tertiary[0]?.verificationId,data?.tertiary[0]?.aStatus,data?.tertiary[0]?.policyId)"></i>
                <i class="fa fa-file-o fileicon ml-2" *ngIf="data?.tertiary[0]?.authStatus === 4"
                    [matTooltip]="data?.tertiary[0]?.aStatus"></i>
                <i class="fa fa-times cansicon ml-2" *ngIf="data?.tertiary[0]?.authStatus === 5"
                    [matTooltip]="data?.tertiary[0]?.aStatus"></i>
                <i class="fa fa-exclamation expicon ml-2" *ngIf="data?.tertiary[0]?.authStatus === 6"
                    [matTooltip]="data?.tertiary[0]?.aStatus"></i>
                    <span class="ml-2" [matTooltip]="data?.tertiary[0]?.authValidity || '-'"
                    *ngIf="data?.tertiary[0]?.authStatus !== 1 && data?.tertiary[0]?.authStatus !== 3"> {{
                    data?.tertiary[0]?.authValidity || '-' }}</span>
                    <span class="ml-2" [matTooltip]="data?.tertiary[0]?.authValidity"
                    *ngIf="data?.tertiary[0]?.authStatus === 1 && data?.tertiary[0]?.authStatus === 3"> {{
                    data?.tertiary[0]?.authValidity }}</span>

            </td>

            <td [matTooltip]="data?.tertiary[0]?.createdBy || '-'"
            (dblclick)="onRowDoubleClickV1(data?.tertiary[0]?.createdBy || '-')">{{
              data?.tertiary[0]?.createdBy || '-' }}</td>

            <td [matTooltip]="getFormattedDate(data?.tertiary[0]?.creationTime)"
            (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.tertiary[0]?.creationTime))"
            class="text-center">
            {{ getFormattedDate(data?.tertiary[0]?.creationTime) }}
        </td>
        <td [matTooltip]="data?.tertiary[0]?.modifyBy|| '-'"
            (dblclick)="onRowDoubleClickV1(data?.tertiary[0]?.modifyBy || '-')">{{
              data?.tertiary[0]?.modifyBy || '-' }}</td>

        <td class="text-right" [matTooltip]="getFormattedDate(data?.tertiary[0]?.lastModificationTime)"
            (dblclick)="onRowDoubleClickV1(getFormattedDate(data?.tertiary[0]?.lastModificationTime))" >
            {{ getFormattedDate(data?.tertiary[0]?.lastModificationTime) }}
        </td>

            <td [matTooltip]="data?.csrName || '-'" (dblclick)="onRowDoubleClickV1(data?.csrName || '-')">
                {{data?.csrName || '-' }}
            </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th><mat-icon>apps</mat-icon></th>

            <th>
                <input matInput type="text" class="textbox-border-class" placeholder="Chart No"
                    name="search-Chart No" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Patient Name"
                    name="search-PatientName" />
            </th>
            <th>
                <input matInput class="textbox-border-class text-center" type="text" placeholder="DOB"
                    name="search-DOB" />
            </th>

            <th class="custstatus">
                <input matInput type="text" class="textbox-border-class" placeholder="Insurance"
                    name="search-Insurance" />
            </th>
            <th class="custstatus">
                <input matInput type="text" class="textbox-border-class" placeholder="Verification Validity"
                    name="search-VerificationValidity" />
            </th>
            <th class="custstatus">
                <input matInput type="text" class="textbox-border-class" placeholder="Auth Validity"
                    name="search-AuthValidity" />
            </th>
            <!-- <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Inserted/Updated On"
                    name="search-Inserted/Updated On" />
            </th> -->
            <th>
              <input matInput class="textbox-border-class" type="text" placeholder=" Primary Inserted By"
                  name="search-Inserted By" />
          </th>
          <th class="text-right">
              <input matInput class="textbox-border-class text-center" type="text"
                    placeholder="Primary Inserted Date" name="search-Inserted Date" />
           </th>
           <th>
              <input matInput class="textbox-border-class" type="text" placeholder="Primary Updated By"
                  name="search-Updated By" />
          </th>
            <th class="text-right">
                <input matInput class="textbox-border-class text-center" type="text"
                    placeholder="Primary Updated Date" name="search-Updated Date" />
            </th>

            <th class="custstatus">
                <input matInput type="text" class="textbox-border-class" placeholder="Insurance"
                    name="search-Insurance" />
            </th>
            <th class="custstatus">
                <input matInput type="text" class="textbox-border-class" placeholder="Verification Validity"
                    name="search-VerificationValidity" />
            </th>
            <th class="custstatus">
                <input matInput type="text" class="textbox-border-class" placeholder="Auth Validity"
                    name="search-AuthValidity" />
            </th>
            <!-- <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Inserted/Updated On"
                    name="search-Inserted/Updated On" />
            </th> -->
            <th>
              <input matInput class="textbox-border-class" type="text" placeholder="Secondary Inserted By"
                  name="search-Inserted By" />
          </th>
          <th class="text-right">
              <input matInput class="textbox-border-class text-center" type="text"
                    placeholder="Secondary Inserted Date" name="search-Inserted Date" />
           </th>
           <th>
              <input matInput class="textbox-border-class" type="text" placeholder="Secondary Updated By"
                  name="search-Updated By" />
          </th>
            <th class="text-right">
                <input matInput class="textbox-border-class text-center" type="text"
                    placeholder="Secondary Updated Date" name="search-Updated Date" />
            </th>

            <th class="custstatus">
                <input matInput type="text" class="textbox-border-class" placeholder="Insurance"
                    name="search-Insurance" />
            </th>
            <th class="custstatus">
                <input matInput type="text" class="textbox-border-class" placeholder="Verification Validity"
                    name="search-VerificationValidity" />
            </th>
            <th class="custstatus">
                <input matInput type="text" class="textbox-border-class" placeholder="Auth Validity"
                    name="search-AuthValidity" />
            </th>
            <!-- <th>
                <input matInput class="textbox-border-class" type="text" placeholder="Inserted/Updated On"
                    name="search-Inserted/Updated On" />
            </th> -->
            <th>
              <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Inserted By"
                  name="search-Inserted By" />
          </th>
          <th class="text-right">
              <input matInput class="textbox-border-class text-center" type="text"
                    placeholder="Tertiary Inserted Date" name="search-Inserted Date" />
           </th>
           <th>
              <input matInput class="textbox-border-class" type="text" placeholder="Tertiary Updated By"
                  name="search-Updated By" />
          </th>
            <th class="text-right">
                <input matInput class="textbox-border-class text-center" type="text"
                    placeholder="Tertiary Updated Date" name="search-Updated Date" />
            </th>
            <th>
                <input matInput class="textbox-border-class" type="text" placeholder="CSR" name="search-CSR" />
            </th>
        </tr>
    </tfoot>
</table>
