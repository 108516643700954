import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import {
  IdentityUserDto,
  UserByRoleDTO,
} from '../admin-proxy/platform-app-management/identity';
import { Observable, Subscription, of } from 'rxjs';
import {
  GenderDTO,
  PatientMasterDropdownDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { map, startWith } from 'rxjs/operators';
import { defaultGuid } from '../document-perviewer/document-perviewer.component';
import { IdentityUserService } from '../admin-proxy/volo/abp/identity/identity-user.service';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientPersonalService } from 'projects/patient/src/app/patient-proxy/patient-optimization';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  InboundDocumentDetailsDto,
  NewPatientDTO,
} from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import { RegionCitiesDTO, RegionStatesDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { PayerDetailService } from 'projects/patient/src/app/patient-proxy/patient/payer-detail.service';
import { MasterProviderService } from '../warehouse-proxy/inventory';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
export interface Branch{
  id?: string;
  organizationUnitName?: string;
}
@Component({
  selector: 'app-assign-document-modal',
  templateUrl: './assign-document-modal.component.html',
  styleUrls: ['./assign-document-modal.component.scss'],
})

export class AssignDocumentModalComponent implements OnInit {
  documentAssignForm: FormGroup;
  patientSearchForm: FormGroup;
  wipForm: FormGroup;
  selUserId = '';
  pdfheight: string = '100%';
  strSelectedPdfPath: string = '';
  defaultGuid = defaultGuid;
  notesText = '';
  wipNotesText = '';
  gender: string = '';
  dataSource: InboundDocumentDetailsDto[] = [];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  lstAllPatientIds: { guid: string; idAndNameAndDOB: string }[] = [];
  drpGenderLoop: GenderDTO[] = [];
  lstUsers: UserByRoleDTO[] = [];
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  saveButtonDisabled: boolean = false;
  filteredIdentityUsers: Observable<UserByRoleDTO[]> = of([]);
  filteredWipCSRs: Observable<UserByRoleDTO[]> = of([]);
  filteredPatients: Observable<{ guid: string; idAndNameAndDOB: string }[]> =
    of([]);
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  drpProviders: any[] = [];
  public drpBranch: Branch[] = [];
  phoneCode: string = '';
  provider: string = '';
  tenantId: string = "";
  organizationUnitName: string;
  public filteredSelectPayers: Observable<string[]> | undefined;
  public filteredSelectSecPayers: Observable<string[]> | undefined;
  public filteredSelectTerPayers: Observable<string[]> | undefined;
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  public brnachList: Observable<Branch[]> | undefined;
  selectPayorList: any;
  selectSecPayorList: any;
  selectTerPayorList: any;
  activeTab: string = 'DemoGraphics';
  subscription$: Subscription[] = [];
  isFormVisible = false;
  PrimarypanelOpenState: boolean = true;
  SecpanelOpenState: boolean = false;
  TerpanelOpenState: boolean = false;
  isTabVisible = false;
  isWipFormVisible = false;
  isShowSpinner = false;
  State: string = "";
  City: string = "";
  payorId: any;
  payorName: any;
  eligibilityMode: any;
  arrDisplayedColumns = [
    'patientName',
    //'gender',
    'dob',
    'assignedTo',
    //'comments',
  ];
  ngOnInit(): void {
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.initializeForms();
    this.loadValidDates();
    this.loadInboundDocument();
    this.loadDropdowns();
    this.loadAssignedDocsList();
    this.onChangeState(defaultCountry.US);
    this.getPayorList()
    this.getBranchList()
  }

  constructor(
    private formBuilder: FormBuilder,
    private textValidators: TextValidators,
    private identityUserService: IdentityUserService,
    private patientDropdownService: PatientMasterDropdownService,
    private patientPersonalService: PatientPersonalService,
    private inboundDocumentService: InboundDocumentService,
    private toastr: ToastrService,
    private regionDropdownService: RegionDropdownService,
    private userService: UserService,
    private dateValidator: DateValidator,
    private dialog: MatDialogRef<AssignDocumentModalComponent>,
    private payerDetailService: PayerDetailService,
    private providerList: MasterProviderService,
    private branchService : OrganizationUnitService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      documentId: string;
      blobName: string;
      defaultDocumentId: string;
    } = {
      documentId: defaultGuid,
      blobName: '',
      defaultDocumentId: '',
    }
  ) {}
  //To set the Valid Dates for Birth Date field
  loadValidDates() {
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() - 1);
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
  }
  //Whitespace Validator
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  updateCharacterCount() {
    // Ensure the text length does not exceed the maximum length
    if (this.notesText?.length > 5000) {
      this.notesText = this.notesText?.substr(0, 5000);
    }
  }
  //Initialize Form
  initializeForms() {
    this.eligibilityMode = 1;
    this.patientSearchForm = this.formBuilder.group({
      drpSearchPatient: new FormControl('', [Validators.required]),
      txtPatientFilter: new FormControl(''),
    });

    this.documentAssignForm = this.formBuilder.group({
      txtFirstName: new FormControl('', [
        Validators.required,
        this.textValidators.isTextCheck,
        noWhitespaceValidator,
      ]),
      txtLastName: new FormControl('', [
        Validators.required,
        this.textValidators.isTextCheck,
        noWhitespaceValidator,
      ]),
      txtMiddleName: new FormControl('', [this.textValidators.isTextCheck]),
      txtBirthdate: new FormControl(null, [Validators.required]),
      drpGender: new FormControl(null, [Validators.required]),
      userToBeAssigned: new FormControl(''),
      txtUserToBeAssignedFilter: new FormControl(''),
      txtComments: new FormControl(''),
      txtState: new FormControl(""),
      txtStateFilter: new FormControl(""),
      txtCity: new FormControl(""),
      txtCityFilter: new FormControl(""),
      txtBillingPostalCode: new FormControl(""),
      txtChart: new FormControl(""),
      txtWorkNo: new FormControl(""),
      txtHomeNo: new FormControl(""),
      txtCellNo: new FormControl(""),
      drpBranch: new FormControl(""),
      txtBranch: new FormControl(""),
      chkStat: new FormControl(""),
      txtProvider: new FormControl(""),
      drpselectPayor: new FormControl('', ),
      selectPayor: new FormControl(''),
      drpSecselectPayor: new FormControl('',),
      selectSecPayor: new FormControl(''),
      drpTerselectPayor: new FormControl('', ),
      selectTerPayor: new FormControl(''),
    });

    this.wipForm = this.formBuilder.group({
      drpWIPId: new FormControl(''),
      txtWIP: new FormControl(''),
      wipUserToBeAssigned: new FormControl(''),
      txtWipUserToBeAssignedFilter: new FormControl(''),
      txtWIPNotes: new FormControl(''),
    });
  }
  //To approve the document processed
  approveDocument() {
    Swal.fire({
      title: 'Are you sure you want Process the Document?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.value) {
        const approveDocument = this.inboundDocumentService
          .approveInboundDocumentByGInboundDocumentId(this.data.documentId)
          .subscribe(
            (response) => {
              this.toastr.success('Inbound Process Completed!');
              this.dialog.close();
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
      }
    });
  }

  phoneNo(){
    this.documentAssignForm.controls['txtWorkNo'].setValidators(
      Validators.required
    );
    this.documentAssignForm.controls['txtHomeNo'].setValidators(
      Validators.required
    );
    this.documentAssignForm.controls['txtCellNo'].setValidators(
      Validators.required
    );
  }

  loadDropdowns() {
    //Load Gender Dropdown
    const patientDropdownInput: PatientDropdowns[] = [PatientDropdowns.Genders];
    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          let response: PatientMasterDropdownDTO = stateResponse;
          this.drpGenderLoop = response?.genders ?? [];
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientDropdown);

    //Load Patient List Dropdown
    const searchPatientSub = this.patientPersonalService
      .patientSearchBySDefaultPatientIdAndSPatientNameAndDtDOBAndSSSNAndSEmailIdAndSMobileAndSStatus(
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        1
      )
      .subscribe((response) => {
        this.lstAllPatientIds = response.map((x) => {
          const dateOptions: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          };
          //date in MM/DD/YYYY FORMAT
          const dob =
            (x?.dob ?? '') === ''
              ? ''
              : new Date(x?.dob)?.toLocaleDateString('en-US', dateOptions) ??
                '';
          let y: { guid: string; idAndNameAndDOB: string } = {
            guid: x?.patientId,
            idAndNameAndDOB:
              x?.defaultPatientId + ' - ' + x?.patientName + ' - ' + dob,
            ...x,
          };
          return y;
        });

        this.filteredPatients = this.patientSearchForm
          ?.get('txtPatientFilter')
          ?.valueChanges?.pipe(
            startWith(''),
            map((value) =>
              this.lstAllPatientIds.filter((option) =>
                option?.idAndNameAndDOB
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    this.subscription$.push(searchPatientSub);

    const providerList = this.providerList
    .getList(new PagedAndSortedResultRequestDto())
    .subscribe((response) => {
      response?.items?.forEach((element) => {
        this.drpProviders.push({
          id: element?.id,
          name: element?.fullName,
          shortCodeId: element?.shortCodeId,
          fullName: element?.fullName,
          npi: element?.npi,
        });
      });
    });
  this.subscription$.push(providerList);
    //Load User List Dropdown

    const csrUsersList = this.userService
      .getCSRUsers('')
      .subscribe((response) => {
        this.lstUsers = response;
        this.filteredIdentityUsers = this.documentAssignForm
          ?.get('txtUserToBeAssignedFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstUsers.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
        this.filteredWipCSRs = this.wipForm
          ?.get('txtWipUserToBeAssignedFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstUsers.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    this.subscription$.push(csrUsersList);
  }
  loadInboundDocument() {
    this.inboundDocumentService
      .downloadInboundDocumentByBlobName(this.data.blobName)
      .subscribe(
        (response) => {
          if (
            typeof response === 'string' &&
            response &&
            response !== null &&
            response?.length !== 0
          ) {
            this.strSelectedPdfPath = String(
              'data:application/pdf;base64,' + response
            );
          }
        },
        (err) => {
          this.strSelectedPdfPath = '';

          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  allBranchSelect(){
    if(this.selectAllBranch.selected){
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    }
    else{
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getBranchList(){
    const getBranchList = this.branchService.getBranchList(this.tenantId).subscribe(response => {
      response?.forEach(element => {
        this.drpBranch.push({
          id: element.id,
          organizationUnitName: element.organizationUnitName,
        })
      });

      this.brnachList = this.documentAssignForm
        ?.get('txtBranch')
        ?.valueChanges?.pipe(
          startWith(''),
          map((value) =>
            this.drpBranch?.filter((option) =>
              option?.organizationUnitName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );


    }, err =>{
      const data : HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getBranchList);
  }
  loadPatientForm() {
    const patientId = this.patientSearchForm.value.drpSearchPatient;
    this.documentAssignForm.reset();
    if (patientId != defaultGuid && patientId != null && patientId != '0') {
      this.isWipFormVisible = false;
      const getPatientDetails = this.inboundDocumentService
        .getPatientDetail(patientId)
        .subscribe((response) => {
          if (response?.csr != null && response?.csr != defaultGuid) {
            this.selUserId = response?.csr;
            this.documentAssignForm.patchValue({
              userToBeAssigned: this.selUserId,
            });
          }

          this.documentAssignForm.patchValue({
            txtFirstName: response?.firstName,
            txtMiddleName: response?.middleName,
            txtLastName: response?.lastName,
            drpGender: response?.gender,
            txtBirthdate: response?.dob,
          });
          // this.isFormVisible = !this.isFormVisible;
          this.isTabVisible = true;
          this.isFormVisible = true;
          this.documentAssignForm.disable();
          this.documentAssignForm.get('userToBeAssigned')?.enable();
          this.documentAssignForm.get('txtUserToBeAssignedFilter')?.enable();
          this.documentAssignForm.get('txtComments')?.enable();
        });
      this.subscription$.push(getPatientDetails);
    } else if (patientId == '0') {
      this.isTabVisible = false;
      this.isWipFormVisible = true;
      this.isFormVisible = false;
    } else {
      if (patientId == defaultGuid) {
        this.isTabVisible = true;
        this.isWipFormVisible = false;
        this.documentAssignForm.reset();
        this.documentAssignForm.enable();
        this.isFormVisible = true;
      } else {
        this.isTabVisible = false;
        this.isWipFormVisible = true;
        this.isFormVisible = false;
      }
    }
  }
  loadAssignedDocsList() {
    const loadAssignedDocsList = this.inboundDocumentService
      .getInitialAssignedDocuments(this.data.documentId)
      .subscribe((response) => {
        this.dataSource = response;
      });
  }
  resetForm() {
    this.patientSearchForm.reset();
    this.documentAssignForm.reset();
    this.wipForm.reset();
    this.isFormVisible = false;
    this.isWipFormVisible = false;
  }
  //To assign a Document
  assignDocument() {
    this.saveButtonDisabled = true;
    const patientValue: NewPatientDTO = {
      documentId: this.data.documentId,
      defaultPatientId:'',
      patientId: this.patientSearchForm.getRawValue().drpSearchPatient,
      firstName: this.documentAssignForm.getRawValue().txtFirstName,
      middleName: this.documentAssignForm.getRawValue().txtMiddleName,
      lastName: this.documentAssignForm.getRawValue().txtLastName,
      birthDate: this.documentAssignForm.getRawValue().txtBirthdate,
      gender: this.documentAssignForm.getRawValue().drpGender,
      assignedTo: this.documentAssignForm.getRawValue().userToBeAssigned,
      comment: this.documentAssignForm.getRawValue().txtComments,
    };
    this.inboundDocumentService.inserNewPatient(patientValue).subscribe(
      (response) => {
        this.saveButtonDisabled = false;
        this.toastr.success('Saved Successfully');
        this.loadAssignedDocsList();
        this.resetForm();
        //this.dialog.close();
      },
      (err) => {
        this.saveButtonDisabled = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  clearGender() {
    this.gender = '';
    this.documentAssignForm?.patchValue({ drpGender: '' });
  }

  changeTab(tab: string) {
    this.activeTab = tab;
    if (this.activeTab == 'DemoGraphics') {
    } else if (this.activeTab == 'Plans') {
    }
  }

  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.documentAssignForm.patchValue({
      txtState: null,
      txtCity: null
    })
    this.drpStates = [];
    this.drpCities = [];
    value !== null && value !== undefined && this.regionDropdownService.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {

      this.drpStates = response?.items ?? [];

      // if (response?.items?.length === 0) {
      //   this.phoneCode = "";
      //   this.documentAssignForm.controls['txtWorkNo'].disable();
      //   this.documentAssignForm.controls['txtWorkNo'].disable();
      //   this.documentAssignForm.controls['txtWorkNo'].disable();
      // } else {
      //   this.documentAssignForm.controls['txtTelephone'].enable();
      //   this.phoneCode = this.drpStates[0]?.countryPhoneCode ?? "";
      // }
      this.phoneCode = response?.items[0]?.countryPhoneCode;
      this.filteredStates = this.documentAssignForm?.get("txtStateFilter")?.valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpStates.filter(option =>
            option?.stateName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")
          ))
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    return this.drpStates.length !== 0;
  }

  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.documentAssignForm.patchValue({
      txtCity: null
    });
    country = (country == "") ? (this.documentAssignForm.value.drpCountry == (null || undefined) ? "" : (this.documentAssignForm.value.drpCountry)) : country;
    this.drpCities = [];
    state !== null && state !== undefined && this.regionDropdownService.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, state).subscribe(response => {
      this.drpCities = response?.items;
        this.filteredCities = this.documentAssignForm
        .get('txtCityFilter')
        .valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpCities.filter((option) =>
              option?.cityName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    return this.drpCities.length !== 0;
  }


  clearState() {
    this.State = "";
    this.documentAssignForm.patchValue({ txtState: "" })
  }

  clearCity() {
    this.City = "";
    this.documentAssignForm.patchValue({ txtCity: "" })
  }
  getPayorList() {
    let eligibilityMode = 1
    this.payerDetailService
      .getPayerListByModeByIMode(eligibilityMode)
      .subscribe((response) => {
        this.selectPayorList = response;
        this.selectPayorList = response.map((x) => {
          x['payerShortCodeList'] =
          ((x?.payerCode===null||x?.payerCode==="")?'-' :x?.payerCode)+ ' ' + '/' + ' ' + x?.payerName+' ' + '/' + ' '
          + ((x?.code===null||x?.code==="")?'-':x?.code);
          return x;
        });
        this.filteredSelectPayers = this.documentAssignForm
          .get('selectPayor')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.selectPayorList?.filter((option) =>
                option?.payerShortCodeList
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
          this.filteredSelectSecPayers = this.documentAssignForm
          .get('selectSecPayor')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.selectPayorList?.filter((option) =>
                option?.payerShortCodeList
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
          this.filteredSelectTerPayers = this.documentAssignForm
          .get('selectTerPayor')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.selectPayorList?.filter((option) =>
                option?.payerShortCodeList
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
  }

  clearProvider() {
    this.provider = '';
    this.documentAssignForm?.patchValue({ txtProvider: '' });
  }
  onSelectPayor(event: any) {
    const payorList = event.value;
    let splitValue = payorList.split('/');
    this.payorId = splitValue[0];
    this.payorName = splitValue[1];
  }

}



/* #region Old Inbound Code Commented */

// typeOfPatient: boolean = true;
// defaultGuid = defaultGuid;
// selUserId = "";
// inBoundDocId = defaultGuid;
// lstAllPatientIds: { guid: string, idAndNameAndDOB: string }[] = [];
// lstUsers: IdentityUserDto[] = []
// filteredPatientId: Observable<{ guid: string, idAndNameAndDOB: string }[]> = of([]);
// filteredIdentityUsers: Observable<IdentityUserDto[]> = of([]);
// typeOfPatientForm: FormGroup;
// existingPatientForm: FormGroup;
// newPatientForm: FormGroup;
// BirthdateMaxDate: Date;
// BirthdateMinDate: Date;
// gender: string = '';
// drpGenderLoop: GenderDTO[] = [];
// subscription$: Subscription[] = [];
// tenantId = defaultGuid;
// drpProviders: { id: string, name: string, shortCodeId: string, fullName: string, npi: string, }[] = [];
// drpColorCodings: ColorCodingDTO[] = [];
// isShowSpinner = false;
// drpAccountGroups: AccountGroupDTO[] = [];
// constructor(
//   private fb: FormBuilder,
//   private dialog: MatDialog,
//   private personalServoive: PatientPersonalService,
//   private identityUserService: IdentityUserService,
//   private textValidators: TextValidators,
//   private patientSearchService: PatientSearchService,
//   private organizationUnitService: OrganizationUnitService,
//   private patientDropdownService: PatientMasterDropdownService,
//   private masterProviderService: MasterProviderService,
//   private colorCodingService: ColorCodingService,
//   public workInProgressService: WorkInProgressService,
//   private inboundDocumentService: InboundDocumentService,
//   private toastr: ToastrService,
//   @Inject(MAT_DIALOG_DATA) public data: { inBoundDocId?: string } = { inBoundDocId: defaultGuid },
// ) { }

// ngOnInit(): void {
//   this.inBoundDocId = this.data?.inBoundDocId ?? defaultGuid;
//   this.tenantId = localStorage?.getItem("tenantId") ?? defaultGuid;
//   this.BirthdateMaxDate = new Date();
//   this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate() - 1);
//   this.BirthdateMinDate = new Date();
//   this.BirthdateMinDate.setFullYear(
//     this.BirthdateMinDate.getFullYear() - 120
//   );
//   this.typeOfPatientForm = this.fb.group({
//     tyepOfPatient: new FormControl("Existing")
//   });

//   this.createFormGroup();
//   this.getExsisitingPatientsList();
//   this.getDropdowns();
// }

// // get dropdown API function
// getDropdowns() {
//   const patientDropdownInput: PatientDropdowns[] = [PatientDropdowns.Genders,
//   PatientDropdowns.AccountGroups,
//   ];
//   const patientDropdown = this.patientDropdownService
//     .getPatientDropdownsByInput(patientDropdownInput)
//     .subscribe(
//       (stateResponse) => {
//         let response: PatientMasterDropdownDTO = stateResponse;
//         this.drpGenderLoop = response?.genders ?? [];
//         this.drpAccountGroups = response?.accountGroups ?? [];
//         this.drpColorCodings = response?.lstColorCodings ?? [];
//       },
//       (err) => {
//         const data: HttpErrorResponse = err;
//         Swal.fire({
//           icon: 'info',
//           text: data?.error?.error?.message,
//         });
//       }
//     );
//   const colorCodingDropdown = this.colorCodingService.getList(new PagedAndSortedResultRequestDto).subscribe(
//     (response) => {
//       this.drpColorCodings = response?.items ?? [];
//     },
//     (err) => {
//       const data: HttpErrorResponse = err;
//       Swal.fire({
//         icon: 'info',
//         text: data?.error?.error?.message,
//       });
//     }
//   );

//   this.subscription$.push(colorCodingDropdown, patientDropdown);
// }
// //! create form groups
// createFormGroup() {
//   this.existingPatientForm = this.fb.group({
//     drpPatientId: new FormControl("", [Validators.required]),
//     txtPatinetIdFilter: new FormControl(""),
//     userToBeAssigned: new FormControl("", [Validators.required]),
//     txtUserToBeAssignedFilter: new FormControl(""),
//     txtComments: new FormControl("", [Validators.required])
//     // txtScheduleDate: new FormControl(""),
//   });
//   this.newPatientForm = this.fb.group({
//     txtFirstName: new FormControl('', [Validators.required, this.textValidators.isTextCheck,]),
//     txtLastName: new FormControl('', [Validators.required, this.textValidators.isTextCheck,]),
//     txtMiddleName: new FormControl('', [this.textValidators.isTextCheck]),
//     txtBirthdate: new FormControl(null, [Validators.required]),
//     drpGender: new FormControl(null, [Validators.required]),
//     userToBeAssigned: new FormControl("", [Validators.required]),
//     txtUserToBeAssignedFilter: new FormControl(""),
//     txtComments: new FormControl("", [Validators.required])
//   });

//   const valueChanges = this.newPatientForm.valueChanges.subscribe(
//     () => {
//       const firstname: AbstractControl =
//         this.newPatientForm.get('txtFirstName');
//       if (firstname.dirty) {
//         firstname?.value?.trim() === '' && firstname?.setErrors({ required: true });
//         firstname?.markAsDirty();
//         firstname?.markAsTouched();
//         // firstname.markAsPristine();
//       }

//       const lastname: AbstractControl = this.newPatientForm.get('txtLastName');
//       if (lastname.dirty) {
//         lastname?.value?.trim() === '' && lastname?.setErrors({ required: true });
//         lastname?.markAsDirty();
//         lastname?.markAsTouched();
//         //   firstname.markAsPristine();
//       }
//     },
//     (err) => {
//       const data: HttpErrorResponse = err;
//       Swal.fire({
//         icon: 'info',
//         text: data?.error?.error?.message,
//       });
//     }
//   );
// }
// // get patient list for dropdown
// getExsisitingPatientsList() {

//   const searchPatientSub = this.personalServoive.patientSearchBySDefaultPatientIdAndSPatientNameAndDtDOBAndSSSNAndSEmailIdAndSMobileAndSStatus(
//     "", "", "", "", "", "", "",1
//   ).pipe().subscribe(response => {

//     this.lstAllPatientIds = response.map(x => {
//       const dateOtions: Intl.DateTimeFormatOptions = {
//         year: "numeric",
//         month: "2-digit",
//         day: "2-digit",
//       };
//       //date in MM/DD/YYYY FORMAT
//       const dob = ((x?.dob ?? "") === "") ? "" : ((new Date(x?.dob)?.toLocaleDateString('en-US', dateOtions)) ?? "");
//       let y: { guid: string, idAndNameAndDOB: string } = {
//         guid: x?.patientId,
//         idAndNameAndDOB: x?.defaultPatientId + " - " + x?.patientName + " - " + dob,
//         ...x
//       };
//       return y;
//     });
//     this.filteredPatientId = this.existingPatientForm
//       ?.get('txtPatinetIdFilter')
//       ?.valueChanges?.pipe(
//         startWith(''),
//         map((value) =>
//           this.lstAllPatientIds.filter((option) => option?.idAndNameAndDOB?.toLowerCase()?.includes(value?.toLowerCase() ?? ''))
//         )
//       );
//   });
//   const identityUserSub = this.identityUserService.getList(new PagedAndSortedResultRequestDto).pipe().subscribe(response => {
//     this.lstUsers = response?.items;
//     const sortedList = this.lstUsers.sort((a, b) => a.userName.localeCompare(b.userName));
//     this.filteredIdentityUsers = this.existingPatientForm
//       ?.get('txtUserToBeAssignedFilter')
//       ?.valueChanges?.pipe(
//         startWith(''),
//         map((value) =>
//           this.lstUsers?.filter((option) => option?.userName?.toLowerCase()?.includes(value?.toLowerCase() ?? ''))
//         )
//       );
//     this.filteredIdentityUsers = this.newPatientForm
//       ?.get('txtUserToBeAssignedFilter')
//       ?.valueChanges?.pipe(
//         startWith(''),
//         map((value) =>
//           this.lstUsers?.filter((option) => option?.userName?.toLowerCase()?.includes(value?.toLowerCase() ?? ''))
//         )
//       );
//   });

//   // Value changes of pateint Type button
//   const tyepOfPatientSub = this.typeOfPatientForm?.get('tyepOfPatient')?.valueChanges?.subscribe(x => {
//     this.typeOfPatient = !this.typeOfPatient;
//     this.resetDocumentForm();
//   });
//   this.subscription$.push(searchPatientSub, identityUserSub, tyepOfPatientSub);
// }

// // clear dropdown
// clearGender() {
//   this.gender = '';
//   this.newPatientForm?.patchValue({ drpGender: '' });
// }
// // reset Document Form
// resetDocumentForm() {
//   this.existingPatientForm.patchValue({
//     drpPatientId: "",
//     userToBeAssigned: ""
//   });
//   this.newPatientForm.patchValue({
//     txtFirstName: "",
//     txtLastName: "",
//     txtMiddleName: "",
//     txtBirthdate: "",
//     drpGender: "",
//   });
//   this.selUserId = "";
// }

// // Save New Patient Document Functions
// saveNewPatientDocumentDetails() {
//   this.isShowSpinner = true;
//   const patientValue = this.newPatientForm.value;
//   const chartIdObs = this.patientSearchService.generateChartId()
//   const organizationListObs = this.organizationUnitService.getOrganizationList(this.tenantId)
//   const masterProviderObs = this.masterProviderService.getList(new PagedAndSortedResultRequestDto())
//   let inboundDetails: CreateUpdateInboundDocumentDTO = {
//     patientId: defaultGuid,
//     documentStatus: inboundDocumentStatus.assigned,
//     isExistingPatient: this.typeOfPatient
//   };
//   forkJoin([chartIdObs, organizationListObs, masterProviderObs]).pipe(
//     map(response => {
//       const chartId = response?.[0];
//       const organizationList = response?.[1];
//       const providerList = response?.[2];
//       return { chartId, organizationList, providerList }
//     }), switchMap(x => {
//       const mPatientPersonal: CreateUpdatePatientPersonalDTO = {
//         chartId: x?.chartId,
//         locationType: x?.organizationList?.[0]?.organizationUnitName,
//         accountGroupType: this.drpAccountGroups?.[0]?.accountGroupShortCodeId,
//         colorCodingId: this.drpColorCodings?.[0]?.id,
//         providerId: x?.providerList?.items?.[0]?.shortCodeId,
//         firstName: patientValue?.txtFirstName,
//         middleName: patientValue?.txtMiddleName,
//         lastName: patientValue?.txtLastName,
//         dateOfBirth: patientValue?.txtBirthdate,
//         gender: patientValue?.drpGender,
//         patientBalance: 0,
//         insuranceBalance: 0,
//         totalBalance: 0,
//         isAutoDunning: 0,
//         isCashPayment: 0,
//         isStat: 0
//       };
//       return this.personalServoive.create(mPatientPersonal)
//     }),

//   ).pipe(
//     switchMap(x => {
//       inboundDetails.patientId = x?.id ?? defaultGuid;

//       return this.inboundDocumentService.get(this.inBoundDocId)
//     }),
//     switchMap(x => {
//       const id: string = this.inBoundDocId ?? defaultGuid;
//       inboundDetails = {
//         documentName: x?.documentName,
//         blobName: x?.blobName,
//         uploadedBy: x?.uploadedBy ?? defaultGuid,
//         uploadedDate: x?.uploadedDate,
//         defaultDocumentId: x?.defaultDocumentId,
//         fileContentAsBase64String: x?.fileContentAsBase64String,
//         comments: x?.comments ?? " ",
//         tenantId: ((x?.tenantId) ?? localStorage?.getItem("tenantId")) ?? defaultGuid,
//         documentStatus: inboundDocumentStatus.assigned,
//         isExistingPatient: this.typeOfPatient,
//         patientId: inboundDetails?.patientId
//       };

//       return this.inboundDocumentService.assignDocumentByIdAndInboundDetails(id, inboundDetails);
//     }),
//     switchMap(x => {
//       const workInProgressDetails: CreateUpdateWorkInProgressDTO = {
//         wipId: " ",
//         documentId: x.id,
//         patientId: x.patientId,
//         assignedTo: patientValue?.userToBeAssigned ?? defaultGuid,
//         assignedBy: (localStorage?.getItem("userId")) ?? defaultGuid,
//         assignedDate: new Date().toISOString(),
//         comments: patientValue?.txtComments ?? '',
//         tenantId: x.tenantId,
//         wipStatus: wipStatus.yetToStart,
//         isScheduled: false,
//         scheduledOnDate: null,
//         scheduledDate: null
//       }
//       return this.workInProgressService.create(workInProgressDetails);
//     }),
//   ).subscribe(response => {
//     this.isShowSpinner = false;
//     this.resetDocumentForm();
//     this.toastr.success('Created New Patient and Alloted Successfully','Success')
//     // Swal.fire({ title: 'Success', html: 'Created New Patient and Alloted Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
//     this.dialog.closeAll();
//   }, err => {
//     this.isShowSpinner = false;
//     const data: HttpErrorResponse = err;
//     Swal.fire({
//       icon: 'info',
//       text: data?.error?.error?.message,
//     });
//   })
// }
// // Save Ex Patient Document Functions

// saveExistingPatientDocumentDetails() {
//   this.isShowSpinner = true;
//   const exPatientValue = this.existingPatientForm.value;
//   const id: string = this.inBoundDocId;
//   this.inboundDocumentService.get(this.inBoundDocId).pipe(
//     switchMap(x => {
//       let inboundDetails: CreateUpdateInboundDocumentDTO = {
//         documentName: x?.documentName,
//         blobName: x?.blobName,
//         uploadedBy: x?.uploadedBy ?? defaultGuid,
//         uploadedDate: x?.uploadedDate,
//         defaultDocumentId: x?.defaultDocumentId,
//         fileContentAsBase64String: x?.fileContentAsBase64String,
//         comments: x?.comments ?? " ",
//         tenantId: ((x?.tenantId) ?? localStorage?.getItem("tenantId")) ?? defaultGuid,
//         documentStatus: inboundDocumentStatus.assigned,
//         isExistingPatient: this.typeOfPatient,
//         patientId: exPatientValue?.drpPatientId ?? defaultGuid
//       };
//       return this.inboundDocumentService.assignDocumentByIdAndInboundDetails(id, inboundDetails);
//     }),
//     switchMap(x => {
//       const workInProgressDetails: CreateUpdateWorkInProgressDTO = {
//         wipId: " ",
//         documentId: x.id,
//         patientId: x.patientId,
//         assignedTo: exPatientValue?.userToBeAssigned ?? defaultGuid,
//         assignedBy: (localStorage?.getItem("userId")) ?? defaultGuid,
//         assignedDate: new Date().toISOString(),
//         comments: exPatientValue?.txtComments ?? '',
//         tenantId: x.tenantId,
//         wipStatus: wipStatus.yetToStart,
//         //  scheduledOnDate: new Date().toISOString(),
//         //  scheduledDate: ((exPatientValue?.txtScheduleDate ?? " ") === " " ? null : new Date(exPatientValue?.txtScheduleDate).toISOString()),
//         //  isScheduled: ((exPatientValue?.txtScheduleDate ?? " ") === " ")
//         isScheduled: false,
//         scheduledOnDate: null,
//         scheduledDate: null
//       }
//       return this.workInProgressService.create(workInProgressDetails);
//     }

//     )).subscribe(response => {
//       this.isShowSpinner = false;
//       this.resetDocumentForm();
//       this.toastr.success('Alloted Successfully','Success')
//       // Swal.fire({ title: 'Success', html: 'Alloted Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
//       this.dialog.closeAll();
//     }, err => {
//       this.isShowSpinner = false;
//       const data: HttpErrorResponse = err;
//       Swal.fire({
//         icon: 'info',
//         text: data?.error?.error?.message,
//       });
//     });
// }

/* #endregion */
