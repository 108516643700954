import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomUrlSerializer } from 'projects/patient/src/app/custom-url-serializer';
import { PatientSearchService, PatientService } from 'projects/patient/src/app/patient-proxy/patient';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AutoIntakeDocumentDetailsDTO } from '../admin-proxy/platform-app-management/rcm/patient-management/patient/dto/models';
import { FileUploadService } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload';
import { UploadFilesService } from '../admin-proxy/platform-app-management/rcm/platform-management/upload-file';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import { Patient } from '../approval-list/approval-list.component';
import { dateYYYYMMDDtoMMDDYYYY } from '../sub-batch-list-temp/sub-batch-list-temp.component';

@Component({
  selector: 'app-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss']
})
export class PatientSearchComponent implements OnInit, OnChanges {
  contextMenuPosition = { x: '0px', y: '0px' };
  @Output() selPatientId: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  @Input() patientId: string = "";
  isLoading: boolean = false;
  patientSearchForm: FormGroup;
  displayedColumns: string[] = ['Name', 'Patient Id', 'Email Address', 'Date Of Birth', 'LockStatus',];
  patientData: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: true }) Patientsort: MatSort;
  @ViewChild(MatPaginator, { static: true }) patientPaginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) PatienttableRef: MatTable<any>;
  constructor(
    private title: Title,
    private table: TableService,
    public userService: UserService,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dateValidators: DateValidator,
    private fileUploadService: FileUploadService,
    private uploadFilesService: UploadFilesService,
    private patientSearchService: PatientSearchService,
    private customUrlSerializer: CustomUrlSerializer,
    private route: ActivatedRoute,
    private router: Router,
    private patientService: PatientService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      let data: patientData[] = this.patientData?.data ?? [];
      data?.forEach(e => e.highlighted = false);
      let selInsValueIndex: patientData = data?.filter(e => e.id === this.patientId)[0] ?? null;
      if (selInsValueIndex) {
        selInsValueIndex.highlighted = true;
      }
      const mdata = data.lastIndexOf(selInsValueIndex, 0);
      if (mdata !== -1) {
        data[mdata] = selInsValueIndex;
      }
      this.patientData.data = data;
      this.patientData = new MatTableDataSource(data);
      this.patientData.sort = this.Patientsort;
      this.patientData.paginator = this.patientPaginator;
    }
  }

  ngOnInit(): void {
    this.patientSearchForm = this.fb.group({
      Name: '',
      PatientId: '',
      Address: '',
      SSN: '',
      AccountNumber: '',
      EmailAddress: '',
      DateOfBirth: new FormControl('', this.dateValidators.dateVaidator),
      Moblie: '',
      LockStatus: '',
      currStatus: ''
    });
    const valueChanges: Subscription = this.patientSearchForm.valueChanges.pipe(
      filter(x => x && this.patientSearchForm.valid),
      map((value: Patient) => {
        const filter: Patient = {
          PatientId: value.PatientId?.trim()?.toLowerCase(),
          Name: value.Name?.trim()?.toLowerCase(),
          Address: value.Address?.trim()?.toLowerCase(),
          SSN: value.SSN?.trim()?.toLowerCase(),
          AccountNumber: value.AccountNumber?.trim()?.toLowerCase(),
          EmailAddress: value.EmailAddress?.trim()?.toLowerCase(),
          DateOfBirth: value.DateOfBirth?.trim()?.toLowerCase(),
          Moblie: value.Moblie?.trim()?.toLowerCase(),
          LockStatus: value.LockStatus?.trim()?.toLowerCase(),
          currStatus: value.currStatus?.trim()?.toLowerCase(),
        };
        this.isLoading = true;

        let data: any[] = this.patientData?.data ?? [];
        data?.forEach(e => e.highlighted = false);
        this.patientData.data = data;
        this.patientData = new MatTableDataSource(data);
        this.patientData.sort = this.Patientsort;
        this.patientData.paginator = this.patientPaginator;
        this.patientId = "";
        return filter;
      }), switchMap(filter => {
        const PatientName = filter.Name ?? "";
        const DefaultPatientId = filter.PatientId ?? "";
        const SSN = filter.SSN ?? "";
        const AccountNumber = filter.AccountNumber ?? "";
        const EmailId = filter.EmailAddress ?? "";
        const Mobile = filter.Moblie ?? "";
        const Fax = '' ?? "";
        const Address = filter.Address ?? "";
        const dobDay = filter.DateOfBirth ?? "";
        const currStatus = filter.currStatus ?? ""
        const lockStatus = filter.LockStatus ?? "";
        this.patientData.filter = JSON.stringify(filter);
        return this.patientSearchService
          .searchPatientBySPatientNameAndSDefaultPatientIdAndSSSNAndSAccountNumberAndSEmailIdAndSMobileAndSFaxAndSAddressAndDtDOBAndSLockStatusAndSSearchNameAndSCurrentStatus(
            PatientName,
            DefaultPatientId,
            SSN,
            AccountNumber,
            EmailId,
            Mobile,
            Fax,
            Address,
            dobDay,
            lockStatus,
            'uploadSearch',
            currStatus
          )
      }), map(x => x.items)
    ).subscribe(
      (data) => {
        let PATIENTDATA: patientData[] = [];
        data?.forEach((element) => {
          if (
            element &&
            element?.addresses &&
            element?.addresses?.length !== 0
          ) {
            const lastDocumentBlobDetails: AutoIntakeDocumentDetailsDTO =
              element?.autoIntakeDocumentDetails.slice(
                element?.autoIntakeDocumentDetails.length - 1,
                element?.autoIntakeDocumentDetails.length
              )[0] || null;
            const dateOfBirth = element?.personals?.dateOfBirth === null ? '' : dateYYYYMMDDtoMMDDYYYY(element?.personals?.dateOfBirth) ?? '';
            PATIENTDATA?.push({
              id: element?.id,
              PatientId: element?.defaultPatientId?.toString(),
              Address:
                element?.addresses[0]?.address === null || undefined
                  ? ''
                  : element?.addresses[0]?.address,
              Name:
                element?.personals?.fullName ?? "",
              DateOfBirth: dateOfBirth,
              SSN:
                element?.personals?.ssn === null || undefined
                  ? ''
                  : element?.personals?.ssn,
              AccountNumber:
                element?.personals?.accountNumber === null || undefined
                  ? ''
                  : element?.personals?.accountNumber,
              EmailAddress:
                element?.personals?.emailId === null || undefined
                  ? ''
                  : element?.personals?.emailId,
              Moblie:
                element?.addresses[0]?.mobile === null || undefined
                  ? ''
                  : element?.addresses[0]?.mobile,
              isAutoIntake: element?.isAutoIntake,
              autoIntakeDocumentDetails: lastDocumentBlobDetails ?? null,
              LockStatus: element?.patientLock?.status,
              LockStatusWithName:
                element?.patientLock?.status,
              highlighted: false

            });
            let selInsValueIndex: patientData = PATIENTDATA?.filter(e => e?.id === this.patientId)[0] ?? null;
            if (selInsValueIndex) {
              selInsValueIndex.highlighted = true;
            }
            const mdata = PATIENTDATA?.lastIndexOf(selInsValueIndex, 0);
            if (mdata !== -1) {
              PATIENTDATA[mdata] = selInsValueIndex;
            }
          }
        });
        this.patientData = new MatTableDataSource(PATIENTDATA);
        this.patientData.sort = this.Patientsort;
        this.patientData.paginator = this.patientPaginator;
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
        this.patientData = new MatTableDataSource([]);
        this.patientData.sort = this.Patientsort;
        this.patientData.paginator = this.patientPaginator;
      });

  }


  // Context menu on patient deselection
  onContextMenuDeselecteAction(row: patientData) {
    let data: patientData[] = this.patientData?.data ?? [];
    data?.forEach(e => e.highlighted = false);
    this.patientData.data = data;
    this.patientData = new MatTableDataSource(data);
    this.patientData.sort = this.Patientsort;
    this.patientData.paginator = this.patientPaginator;
    this.patientId = "";
    this.selPatientId.emit(this.patientId);

  }
  // Context menu on patient selection
  onContextMenuSelectAction(row: patientData) {
    let data: patientData[] = this.patientData?.data ?? [];
    data?.forEach(e => e.highlighted = false);
    this.patientData.data = data;

    this.patientData = new MatTableDataSource(data);
    this.patientData.sort = this.Patientsort;
    this.patientData.paginator = this.patientPaginator;
    row.highlighted = true;
    this.patientId = row?.id ?? "";
    this.selPatientId.emit(this.patientId);
  }

  // Context menu click handlers
  onContextMenu(event: PointerEvent, item: any) {
    event?.stopPropagation();
    event?.stopImmediatePropagation();
    event?.preventDefault();
    this.contextMenuPosition.x = event?.clientX + 'px';
    this.contextMenuPosition.y = event?.clientY + 'px';
    this.contextMenu.menuData = { 'item': item };
    this.contextMenu?.menu?.focusFirstItem('mouse');
    this.contextMenu?.openMenu();
  }
  // filter
  filtertable(filter: Patient) {
    this.isLoading = true;

    let PatientName = filter.Name;
    let DefaultPatientId = filter.PatientId;
    let SSN = filter.SSN;
    let AccountNumber = filter.AccountNumber;
    let EmailId = filter.EmailAddress;
    let Mobile = filter.Moblie;
    let Fax = '';
    let Address = filter.Address;
    let dobDay = filter.DateOfBirth;
    let currStatus = filter.currStatus
    let lockStatus = filter.LockStatus;
    let PATIENTDATA: patientData[] = [];
    var today = new Date(dobDay);
    var date =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();
    this.patientSearchService
      .searchPatientBySPatientNameAndSDefaultPatientIdAndSSSNAndSAccountNumberAndSEmailIdAndSMobileAndSFaxAndSAddressAndDtDOBAndSLockStatusAndSSearchNameAndSCurrentStatus(
        PatientName,
        DefaultPatientId,
        SSN,
        AccountNumber,
        EmailId,
        Mobile,
        Fax,
        Address,
        dobDay,
        lockStatus,
        'uploadSearch',
        currStatus
      )
      .subscribe(
        (data) => {
          data &&
            data?.items?.forEach((element) => {
              if (
                element &&
                element?.addresses &&
                element?.addresses?.length !== 0
              ) {
                const lastDocumentBlobDetails: AutoIntakeDocumentDetailsDTO =
                  element?.autoIntakeDocumentDetails.slice(
                    element?.autoIntakeDocumentDetails.length - 1,
                    element?.autoIntakeDocumentDetails.length
                  )[0] || null;
                const dateOfBirth = element?.personals?.dateOfBirth === null ? '' : dateYYYYMMDDtoMMDDYYYY(element?.personals?.dateOfBirth) ?? '';
                PATIENTDATA?.push({
                  id: element?.id,
                  PatientId: element?.defaultPatientId?.toString(),
                  Address:
                    element?.addresses[0]?.address === null || undefined
                      ? ''
                      : element?.addresses[0]?.address,
                  Name:
                    element?.personals?.fullName ?? "",
                  DateOfBirth: dateOfBirth,
                  SSN:
                    element?.personals?.ssn === null || undefined
                      ? ''
                      : element?.personals?.ssn,
                  AccountNumber:
                    element?.personals?.accountNumber === null || undefined
                      ? ''
                      : element?.personals?.accountNumber,
                  EmailAddress:
                    element?.personals?.emailId === null || undefined
                      ? ''
                      : element?.personals?.emailId,
                  Moblie:
                    element?.addresses[0]?.mobile === null || undefined
                      ? ''
                      : element?.addresses[0]?.mobile,
                  isAutoIntake: element?.isAutoIntake,
                  autoIntakeDocumentDetails: lastDocumentBlobDetails ?? null,
                  LockStatus: element?.patientLock?.status,
                  LockStatusWithName:
                    element?.patientLock?.status,
                  highlighted: false

                });
                let selInsValueIndex: patientData = PATIENTDATA?.filter(e => e?.id === this.patientId)[0] ?? null;
                if (selInsValueIndex) {
                  selInsValueIndex.highlighted = true;
                }
                const mdata = PATIENTDATA?.lastIndexOf(selInsValueIndex, 0);
                if (mdata !== -1) {
                  PATIENTDATA[mdata] = selInsValueIndex;
                }
              }
            });
          this.patientData = new MatTableDataSource(PATIENTDATA);
          this.patientData.sort = this.Patientsort;
          this.patientData.paginator = this.patientPaginator;
          this.isLoading = false;
        }, err => {
          this.isLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.patientData = new MatTableDataSource([]);
          this.patientData.sort = this.Patientsort;
          this.patientData.paginator = this.patientPaginator;
        });

  }
}
export interface patientData {
  id: string,
  PatientId: string,
  Address: string,
  Name: string,
  DateOfBirth: string,
  SSN: string,
  AccountNumber: string,
  EmailAddress: string,
  Moblie: string,
  isAutoIntake: number,
  autoIntakeDocumentDetails: AutoIntakeDocumentDetailsDTO,
  LockStatus: string,
  LockStatusWithName: string,
  highlighted: boolean,
}
