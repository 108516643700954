import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterContentInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IdentityUserDto } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { wipStatus } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/wip-status.enum';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { WorkInProgressService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress';
import {
  CommentsList,
  WIPList,
  clsWipSearchDTO,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress/dto';
import {
  wipUserTaskFilter,
  wipUserTaskFilterOptions,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress/wip-user-task-filter.enum';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { TableService } from 'projects/shared/src/app/table.service';
import { Observable, of, pipe, Subscription } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PatientPersonalService } from '../patient-proxy/patient-optimization';
import { ScheduleTaskComponent } from '../schedule-task/schedule-task.component';
import { ScheduledTaskListComponent } from '../scheduled-task-list/scheduled-task-list.component';
import { TaskViewerComponent } from '../task-viewer/task-viewer.component';
import moment, { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { DatePipe } from '@angular/common';
import { IdentityUserService } from 'projects/admin/src/app/admin-proxy/volo/abp/identity/identity-user.service';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';

@Component({
  selector: 'app-user-task-report-tab',
  templateUrl: './user-task-report-tab.component.html',
  styleUrls: ['./user-task-report-tab.component.scss'],
  providers: [DatePipe],
})
export class UserTaskReportTabComponent implements OnInit, AfterContentInit {
  wipForm: FormGroup;
  UserTaskReportsearchableForm: FormGroup;
  drpWipReportType: { value: string; key: string }[] = [];
  drpUsersList: IdentityUserDto[] = [];
  filteredIdentityUsers: Observable<IdentityUserDto[]> = of([]);
  wipReportType: string;
  isLoading: boolean = true;
  selectedRange: { startDate: Moment; endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  fromDate: any;
  toDate: any;
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';
  dateRangStatus: boolean = false;
  alwaysShowCalendars: boolean;
  dataSource: MatTableDataSource<WIPList> = new MatTableDataSource<WIPList>([]);
  dataSourceComments: MatTableDataSource<CommentsList> =
    new MatTableDataSource<CommentsList>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) commentsPaginator: MatPaginator;
  // @ViewChild("isAssinged",{ static: false })isAssinged: any
  arrDisplayedColumns: string[] = [
    'defaultDocumentId',
    'wipId',
    'defaultPatientId',
    'patientName',
    'assignedByName',
    'assignedDate',
    'comments',
    'wipStatus',
  ];
  subscription$: Subscription[] = [];
  userId: string = defaultGuid;
  arrDisplayedCommentsColumns: string[] = [
    'comments',
    'userName',
    'commentsDate',
  ];
  filterValue: {
    defaultDocumentId: any;
    wipId: any;
    defaultPatientId: any;
    assignedByName: any;
    toDate: any;
    patientName: any;
    wipStatus: any;
    assignedDate: any;
    fromDate: any;
  };
  constructor(
    public workInProgressService: WorkInProgressService,
    public patientPersonalService: PatientPersonalService,
    private fb: FormBuilder,
    public tableService: TableService,
    private matDialog: MatDialog,
    private identityUserService: IdentityUserService,
    private datepipe: DatePipe,
    private router: Router,
    public userService: UserService,

    private title: Title
  ) {
    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      firstDay: 1,
    };

    this.ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      // 'Current week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      // 'Next weekend': [this.getNextSaturday(), this.getNextSunday()],
      // 'Next 3 days': [moment().add(1, 'days'), moment().add(3, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      // 'Current day': [moment(), moment()],
      // 'Next 2 days': [moment().add(1, 'days'), moment().add(2, 'days')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
    this.drpUsersList.unshift({
      userName: 'All Users',
      id: defaultGuid,
      emailConfirmed: false,
      phoneNumberConfirmed: false,
      lockoutEnabled: false,
      isDeleted: false,
      deletionTime: '',
      creationTime: '',
      extraProperties: undefined,
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Qsecure | User WIP Report');
    this.wipForm = this.fb.group({
      drpWipReportType: new FormControl('', Validators.required),
      txtUserSearchFilter: new FormControl(''),
      drpUserName: new FormControl(''),
    });
    this.UserTaskReportsearchableForm = this.fb.group({
      documentId: new FormControl(''),
      wipId: new FormControl(''),
      defaultPatientId: new FormControl(''),
      assignedByName: new FormControl(''),
      assignedDate: new FormControl(''),
      wipStatus: new FormControl(''),
      patientName: new FormControl(''),
    });
    this.fnDropdownLoader();
    this.getWipList();
  }

  datesUpdateds(e) {
    if (e.startDate != null || e.endDate != null) {
      this.dateRangStatus = true;
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
      this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
      //this.getAssignedTask();
      //this.getWipList();
    } else {
      this.dateRangStatus = false;
      (this.fromDate = null), (this.toDate = null);
      //this.getAssignedTask();
      //this.getWipList();
    }
  }

  //Function to Load the Dropdowns
  fnDropdownLoader() {
    //Report Type Dropdown
    this.drpWipReportType = wipUserTaskFilterOptions
      ?.map((e) => {
        let x = { value: e.value, key: e.key };
        return x;
      })
      .sort();
    //User Dropdown Load
    this.identityUserService
      .getList(new PagedAndSortedResultRequestDto())
      .pipe()
      .subscribe((response) => {
        this.drpUsersList = response?.items;

        this.drpUsersList.sort((a, b) => a.userName.localeCompare(b.userName));
        this.drpUsersList.unshift({
          userName: 'All Users',
          id: defaultGuid,
          emailConfirmed: false,
          phoneNumberConfirmed: false,
          lockoutEnabled: false,
          isDeleted: false,
          deletionTime: '',
          creationTime: '',
          extraProperties: undefined,
        });
        this.filteredIdentityUsers = this.wipForm
          ?.get('txtUserSearchFilter')
          ?.valueChanges?.pipe(
            startWith(''),
            map((value) =>
              this.drpUsersList?.filter((option) =>
                option?.userName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });

    this.wipForm.patchValue({
      drpWipReportType: this.drpWipReportType.filter(
        (a) => a.value == wipUserTaskFilter.all
      )?.[0]?.key,
      drpUserName: this.drpUsersList?.[0]?.id ?? '',
    });
  }
  getNextSaturday() {
    const dayINeed = 6; // for Saturday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }
  getNextSunday() {
    const dayINeed = 7; // for Sunday
    const today = moment().isoWeekday();
    if (today <= dayINeed) {
      return moment().isoWeekday(dayINeed);
    } else {
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
  }
  ngAfterContentInit() {
    this.userId = localStorage?.getItem('userId') ?? defaultGuid;
    this.wipForm.valid && this.getAssignedTask();
  }

  getWIPReport(value: string) {
    this.getAssignedTask();
  }

  //Get Assigned task list
  getAssignedTask() {
    // this.userId =
    //   (this.wipForm.value.drpUserName ?? '') === ''
    //     ? defaultGuid
    //     : this.wipForm.value.drpUserName;
    // const sValue: clsWipSearchDTO = {
    //   defaultDocumentId: '',
    //   wipId: '',
    //   defaultPatientId: '',
    //   wipStatus: wipStatus.all,
    //   assignedBy: defaultGuid,
    //   assignedDate: '',
    //   patientName: '',
    // };
    // const assignedTasks = this.workInProgressService
    //   .wipTaskListByUserIdAndFilterAndWipSearch(
    //     this.userId,
    //     this.wipForm.value.drpWipReportType,
    //     sValue
    //   )
    //   .subscribe(
    //     (response) => {
    //       this.isLoading = false;
    //       this.setTableData(response);
    //     },
    //     (err) => {
    //       this.isLoading = false;
    //       const data: HttpErrorResponse = err;
    //       Swal.fire({
    //         icon: 'error',
    //         text: data?.error?.error?.message,
    //       });
    //     }
    //   );
    // this.subscription$.push(assignedTasks);
    // return !!assignedTasks;
  }

  getWipList() {
    // this.UserTaskReportsearchableForm.valueChanges
    //   .pipe(
    //     startWith({
    //       documentId: '',
    //       wipId: '',
    //       patientName: '',
    //       defaultPatientId: '',
    //       wipStatus: '',
    //       assignedBy: '',
    //       assignedDate: '',
    //     }),
    //     tap((x) => {
    //       this.isLoading = true;
    //       this.setTableData([]);
    //     }),
    //     filter((e) => e && this.UserTaskReportsearchableForm?.valid),
    //     debounceTime(300),
    //     map((response) => {
    //       this.isLoading = true;
    //       const sValue = {
    //         documentId: response?.documentId?.trim()?.toLowerCase(),
    //         wipId: response?.wipId?.trim()?.toLowerCase(),
    //         defaultPatientId: response?.defaultPatientId?.trim()?.toLowerCase(),
    //         wipstatus:
    //           response?.wipStatus == 'Yet To Start'
    //             ? 'yetToStart'
    //             : response?.wipStatus == 'Hold'
    //             ? 'hold'
    //             : response?.wipStatus == 'In Process'
    //             ? 'inProcess'
    //             : response.wipStatus == 'Completed'
    //             ? 'completed'
    //             : response.wipStatus,
    //         assignedBy: response?.assignedByName?.trim()?.toLowerCase(),
    //         assignedDate: response?.assignedDate?.trim()?.toLowerCase(),
    //         patientName: response?.patientName?.trim()?.toLowerCase(),
    //       };

    //       this.filterValue = {
    //         defaultDocumentId: sValue?.documentId,
    //         wipId: sValue?.wipId,
    //         defaultPatientId: sValue.defaultPatientId,
    //         assignedByName: sValue?.assignedBy,
    //         assignedDate: sValue?.assignedDate,
    //         patientName: sValue?.patientName,
    //         wipStatus: sValue?.wipstatus ? sValue?.wipstatus.trim() : null,
    //         toDate: this.toDate,
    //         fromDate: this.fromDate,
    //       };
    //       return sValue;
    //     }),
    //     switchMap((sValue) =>
    //       this.workInProgressService.wipTaskListByUserIdAndFilterAndWipSearch(
    //         (this.wipForm.value.drpUserName ?? '') === ''
    //           ? defaultGuid
    //           : this.wipForm.value.drpUserName,
    //         this.wipForm.value.drpWipReportType,
    //         this.filterValue
    //       )
    //     )
    //   )
    //   .subscribe(
    //     (response) => {
    //       this.isLoading = false;
    //       this.setTableData(response);
    //     },
    //     (err) => {
    //       this.isLoading = false;
    //       const data: HttpErrorResponse = err;
    //       // Swal.fire({
    //       //   icon: 'error',
    //       //   text: data?.error?.error?.message,
    //       // });
    //     }
    //   );
  }

  // To view WIP Details
  // viewWIPDetailsById(wipData: WIPList) {
  //   const dialogRef = this.matDialog.open(TaskViewerComponent, {
  //     disableClose: true,
  //     data: {
  //       WIPId: wipData?.id ?? defaultGuid,
  //       documentId: wipData?.documentId ?? defaultGuid,
  //       defaultWIPId: wipData.wipId,
  //     },
  //   });
  //   const closeDialog$ = dialogRef.afterClosed().subscribe(
  //     (result) => {
  //       this.getAssignedTask();
  //     },
  //     (err) => {
  //       this.getAssignedTask();

  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.subscription$.push(closeDialog$);
  // }
  // To Schedule WIP Details
  // scheduleWIPDetailsById(wipData: WIPList) {
  //   const dialogRef = this.matDialog.open(ScheduleTaskComponent, {
  //     disableClose: true,
  //     data: {
  //       WIPId: wipData?.id ?? defaultGuid,
  //       documentId: wipData?.documentId ?? defaultGuid,
  //       defaultWIPId: wipData.wipId,
  //     },
  //   });
  //   const closeDialog$ = dialogRef.afterClosed().subscribe(
  //     (result) => {
  //       this.getAssignedTask();
  //     },
  //     (err) => {
  //       this.getAssignedTask();
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  //   this.subscription$.push(closeDialog$);
  // }
  openCommentsDialog(templateRef: TemplateRef<any>, e) {
    this.matDialog.open(templateRef, { data: e.comments });
    this.dataSourceComments = new MatTableDataSource(e.comments);
    this.dataSourceComments.paginator = this.commentsPaginator;
  }
  ngOnDestroy(): void {
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  // statusUpdate(id: string = defaultGuid, value: string = '') {
  //   this.isLoading = true;
  //   this.workInProgressService
  //     .get(id)
  //     .pipe(
  //       map((x) => {
  //         x.wipStatus = <wipStatus>value;
  //         return x;
  //       }),
  //       switchMap((x) => this.workInProgressService.update(id, x))
  //     )
  //     .subscribe(
  //       (response) => {
  //         this.isLoading = false;
  //         this.getAssignedTask();
  //       },
  //       (err) => {
  //         this.getAssignedTask();
  //         this.isLoading = false;
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  // }
  getScheduledTasksList() {
    const dialogRef = this.matDialog.open(ScheduledTaskListComponent, {
      disableClose: false,
      data: {
        userId: defaultGuid,
        tableColumns: ['assignedTo'],
      },
    });
    dialogRef.afterClosed().subscribe((a) => {
      this.getAssignedTask();
    });
  }
  setTableData(response: WIPList[] = []) {
    this.dataSource = new MatTableDataSource(response ?? []);
    this.dataSource.sort = this.sort;
    this.sort.disableClear = true;
    this.dataSource.paginator = this.paginator;
    this.isLoading = false;
  }
}
