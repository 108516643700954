import { ListService } from '@abp/ng.core';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { PayerService } from 'projects/patient/src/app/patient-proxy/patient';
import { InsuranceCategoryService } from 'projects/patient/src/app/patient-proxy/patient-optimization/insurance-category.service';
import { PayerDTO, getPayerDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { PayerDetailService } from 'projects/patient/src/app/patient-proxy/patient/payer-detail.service';
import { ReportService } from 'projects/patient/src/app/patient-proxy/patient/report.service';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-master-payor',
  templateUrl: './master-payor.component.html',
  styleUrls: ['./master-payor.component.scss']
})
export class MasterPayorComponent implements OnInit {
  @Input() payerTableData: any[];
  selection = new SelectionModel<getPayerDTO>(true, []);
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @Output() isLoadTbl = new EventEmitter<boolean>();
  @Output() edit = new EventEmitter<PayerDTO>();
  @Output() lstPayerIds = new EventEmitter<string[]>();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtMMCFaxProcessApi: DataTables.Api;
  eligibilityModeDetails: any;
  dtOptions:any;
  isLoading: boolean = false;
  lstPayerId:string[]=[];
  selectedOption:number;
  selectedUserList: any;
  ids: any;
  constructor(
    private formBuilder: FormBuilder,
    private table: TableService,
    private payerService: PayerService,
    private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    private list: ListService,
    public title: Title,
    private reportService: ReportService,
    private payerDetails: PayerDetailService,
    private toastr: ToastrService,
    private insuranceCategoryService: InsuranceCategoryService,
    private snackBar: MatSnackBar,
    private datepipe:DatePipe
  ) { }

  ngOnInit(): void {
    this.dataSource=new MatTableDataSource(this.payerTableData);
    this.dtOptions = {

        responsive: true,
        // stateSave: true,
        paging: false,
        dom: 'Bfrtip',
        language: {
          emptyTable: 'No records',
          info: 'Total : _MAX_ records',
          infoEmpty: 'Total : _MAX_ records',
          infoFiltered: '(filtered : _TOTAL_ records)',
          loadingRecords: 'Loading...',
          zeroRecords: 'No matching records',
        },
        scrollCollapse: true,
        scrollY: '300px',
        scrollX: true,
        fixedHeader: true,
        columnDefs: [
          { targets: [0, 1, -1], orderable: false },
          { targets: [0, 1, 2, 3, -1], className: 'dt-fixed-column' },
          // { type: 'date', targets: [19] },
          { targets: [8,9,10,11,12,13,14,15,16,17,18], visible: false },
        ],
        search: {
          smart: false, // Set to "none" for full exact match
        },
        buttons: [
            {
              extend: 'excel',
              text: 'Export',
              filename: 'Master Payor',
              exportOptions: {
                columns: ':visible:not(:first-child) ',
              },
            },
            {
              extend: 'colvis',
              columns: ':not(:eq(0), :eq(1), :eq(2) )',
            },
            {
              text: 'Show Default Column',
              action: function (e, dt, node, config) {
                dt.columns(':not(:lt(3))').visible(false);
                dt.buttons('.buttons-colvis').popover('hide');
              },
            },
            {
              text: 'Show All Column',
              action: function (e, dt, node, config) {
                dt.columns().visible(true);
                dt.buttons('.buttons-colvis').popover('hide');
              },
            },
          ],
      };
    this.eligibilityModeDetails=[
      {id:1,value:"pVerify"},
      {id:2,value:"Zermid" },
      {id:3,value:"ChangeHealthCare"  },
      { id: 4, value: 'Manual' },
      { id: 5, value: 'Office Ally' },
       ];
    this.selectedOption=1;

    this.dtOptions.buttons[0].filename =
    'Master Payor' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
    window.removeEventListener('resize', this.calculateScrollY);
  }
  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.dtOptions.scrollY =
      screenHeight * availableSpacePercentage;
  };
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  addRemovePayorId(event: MatSlideToggleChange, id: string) {
    if (event.checked === true) {
      this.lstPayerId.push(id);
      this.lstPayerIds.emit(this.lstPayerId);
    } else {
      const index = this.lstPayerId.indexOf(id);
      if (index !== -1) {
        this.lstPayerId.splice(index, 1);
      }
      this.lstPayerIds.emit(this.lstPayerId);
    }
  }
  eligibiltyStatus(modeId:number){
    const selectedMode = this.eligibilityModeDetails.find(mode => mode.id == modeId);
    return selectedMode ? selectedMode.value : null;
  }
  statusChange(event: MatSlideToggleChange,id:string) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Payor will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          if (id && id !== "" && id !== null && id !== undefined) {
            this.payerDetails.payerEnableDisable(id).subscribe(response => {
              this.toastr.success('Activated Successfully', 'Success')
                this.isLoadTbl.emit(true);
            }, err => {
              // this.toastr.success('Activated Successfully', 'Success')
              // this.isLoadTbl.emit(true);
              // this.statusFilter();
              const data: HttpErrorResponse = err;
              if(data?.error?.text ==='Updated Successfully'){
                this.toastr.success('Activated Successfully', 'Success')
                this.isLoadTbl.emit(true);
            }else{
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
              event.source.checked = false;
            }
            });
          }
        }
        else {
          event.source.checked = false;
        }
      });
    }
    else
      if (event.checked == false) {
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Payor will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {

          if (result.value) {
            if (id && id !== "" && id !== null && id !== undefined) {

              this.payerDetails.payerEnableDisable(id).subscribe(response => {
                this.toastr.success('Deactivated Successfully', 'Success')
                this.isLoadTbl.emit(true);
                // this.statusFilter();
              }, err => {
                const data: HttpErrorResponse = err;
                if(data?.error?.text ==='Updated Successfully'){
                  this.toastr.success('Deactivated Successfully', 'Success')
                  this.isLoadTbl.emit(true);
                }else{
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
                event.source.checked = true;
              }
                // this.toastr.success('Deactivated Successfully', 'Success')
                // this.isLoadTbl.emit(true);
                // this.statusFilter();
              });
            }

          }
          else {
            event.source.checked = true;
          }
        });
      }
  }
  editPayor(value: PayerDTO){
    this.edit.emit(value);
  }
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  onUserSelectionChange(event: MatSelectChange): void {
    this.selectedUserList = event.value;
        this.selectedUserList = this.payerTableData.map(user => user.userId);
    }
      /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
   const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // if there is a selection then clear that selection
    if (this.isSomeSelected()) {
      this.clearAllId();
    } else {
      this.isAllSelected()
        ? this.clearAllId()
        : this.getAllId();
    }
  }

  isSomeSelected() {
    return this.selection.selected.length > 0;
  }
  getAllId(){
    this.dataSource.data.forEach(row => this.selection.select(row));
    this.lstPayerId=[];
    this.ids=this.dataSource.data.forEach(row =>  this.lstPayerId.push(row.id));
      // this.lstPayerId.push();
      this.lstPayerIds.emit(this.lstPayerId);
    }
    clearAllId(){
      this.selection.clear() ;
      this.lstPayerId=[];
      this.lstPayerIds.emit(this.lstPayerId);
    }
  }


