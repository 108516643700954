<table
  datatable
  class="row-bordered hover w-100 display"
  [dtOptions]="dtClaimTableOptions"
>
  <thead>

    <tr>
      <th><mat-icon>apps</mat-icon></th>
      <th [matTooltip]="'Ticket Id'">Ticket No</th>
      <th [matTooltip]="'Chart No'">Chart No</th>
      <th [matTooltip]="'Claim Id'">Claim Id</th>
      <th [matTooltip]="'Patient Name'">Patient Name</th>
      <th [matTooltip]="'Billed Quantity'">Billed Quantity</th>
      <!-- <th [matTooltip]="'Billed Amount ($)'">Billed Amount ($)</th> -->
      <th [matTooltip]="'Net Total ($)'">Net Total ($)</th>
      <!-- <th [matTooltip]="'Shipping Fee ($)'">Shipping Fee ($)</th> -->
      <th [matTooltip]="'Item Total ($)'">Item Total ($)</th>
      <!-- <th [matTooltip]="'Allowed ($)'">Total Allowed ($)</th> -->
      <!-- <th [matTooltip]="'Accessory ($)'">Total Accessory ($)</th> -->
      <!-- <th
        style="
          word-wrap: break-word !important;
          white-space: break-spaces !important;
        "
        [matTooltip]="'Patient Responsibility ($)'"
      >
        Patient Responsibility ($)
      </th> -->
      <!-- <th [matTooltip]="'Insurance Balance ($)'">Insurance Balance ($)</th>
      <th [matTooltip]="'Insurance Amount ($)'">Insurance Amount ($)</th> -->
      <th class="customWidth text-center" >
        Total($)
        <!-- Patient Responsibility ($) -->
        <!-- <div class="row">
          <div class="p-2 mt-1 col-5 vl" >
              Paid
          </div>
          <div class="p-2 mt-1 col-5 vl">
             Pending
          </div>
          <div class="col-2 p-2 mt-1">
            Total
          </div>
        </div> -->
      </th>
      <th class="customWidth text-center" >
        Paid($)
      </th>
      <th class="customWidth text-center" >
        Balance($)
      </th>
      <th [matTooltip]="'Check No'">Check No</th>
      <!-- <th [matTooltip]="'Check Date'">Check Date</th> -->
      <th [matTooltip]="'Payer Name'">Payer Name</th>
      <th [matTooltip]="'Posting Count'">Posting Count</th>

      <th [matTooltip]="'Clearing House'">Clearing House</th>
      <th [matTooltip]="'Processed Date'">Processed Date</th>
      <th [matTooltip]="'Provider Name'">Provider Name</th>
      <th [matTooltip]="'Settled Status'">Settled Status</th>
      <th [matTooltip]="'Batch No'">Batch No</th>
      <th class="text-right" [matTooltip]="'Aging Days'">Aging Days</th>
      <th class="text-right" [matTooltip]="'Undo'">Undo</th>
      <th

      [matTooltip]="'Write Off Amount ($)'"
    >
      Write Off Amount ($)
    </th>
      <th

        [matTooltip]="'Claim Current Balance ($)'"
      >
        Current Balance ($)
      </th>
      <th [matTooltip]="'Waystar Status'">Waystar Status</th>
      <th [matTooltip]="'Status'" class="text-center">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let claimData of claimsTableData">
      <td [matTooltip]="'Open Options'">
        <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu"
          >more_vert</mat-icon
        >
        <mat-menu class="custom-mat-menu" #menu="matMenu">
          <button
            mat-menu-item
            [disabled]="isEmpty(claimData.patientId)"
            (click)="openAddNotesPopUpV1($event, claimData, false)"
          >
            Add Patient Notes
          </button>
          <button
            mat-menu-item
            [disabled]="isEmpty(claimData.patientId)"
            (click)="
              openViewNotesPopup(
                claimData.patientId,
                claimData.defaultFaxId,
                claimData.docId
              )
            "
          >
            View Patient Notes
          </button>
          <button
            mat-menu-item
            [disabled]="isEmpty(claimData.patientId)"
            (click)="viewPatientLedger(claimData.patientId)"
          >
            Patient Ledger
          </button>
          <button
            mat-menu-item
            (click)="uploadEOB(claimData, true)"
            [disabled]="isEmpty(claimData.claimId)"
          >
            Upload EOB
          </button>
          <button
            mat-menu-item
            (click)="uploadEOB(claimData, false)"
            [disabled]="
              isEmpty(claimData.claimId) || isEmpty(claimData.eobBlobName)
            "
          >
            View EOB
          </button>
          <button
            mat-menu-item
            [disabled]="isEmpty(claimData.claimId)"
            (click)="insuranceRecoop(claimData)"
          >
            Insurance Recoup
          </button>
          <button
            mat-menu-item
            [disabled]="
              isEmpty(claimData.claimId) || isEmpty(claimData.claimStatusId)
            "
            (click)="UpdateStatus(claimData)"
          >
            Update Status
          </button>
          <button
            [disabled]="
              !claimData.isMoveSecClaim || claimData.isSecClaimProcessed
            "
            mat-menu-item
            (click)="OpenMoveToSecondaryModel(claimData)"
          >
            Move to Secondary
          </button>
          <button
            [disabled]="
              !claimData.isPatientResp ||
              claimData.patientRespAmount <= 0 ||
              claimData.isMailSent === 1
            "
            mat-menu-item
            (click)="
              OpenPatientResponsibilityModel(claimData.claimId, claimData)
            "
          >
            Patient Responsibility
          </button>
          <button
          [disabled]="!claimData?.toShowWriteOff || isEmpty(claimData.claimId)"
            mat-menu-item
            (click)="
              OpenWriteOff(claimData.claimId)
            "
          >
           Write Off
          </button>
          <!--  -->
          <button
            *ngIf="claimData.isResubmitEnabled"
            mat-menu-item
            (click)="
              openClaimForm(
                claimData.orderId,
                claimData.patientId,
                claimData.chartId,
                claimData.patientName,
                claimData.mmRefOrderId,
                claimData.claimId,
                false,
                claimData.isResubmitEnabled
              )
            "
          >
            <!-- claimData.isResubmitEnabled -->
            Resubmit Claim Form
          </button>

        </mat-menu>
      </td>
      <td class="text-right" [matTooltip]="claimData?.mmRefOrderId || '-'">
        <a
          class="preview-link"
          [class.disabled]="
            isEmpty(claimData.patientId) || isEmpty(claimData.orderId)
          "
          [routerLink]="
            '/orders/saleOrder/' +
            claimData.patientId +
            '/' +
            claimData.orderId +
            '/' +
            '0'
          "
          target="_blank"
        >
          {{ claimData?.mmRefOrderId || "-" }}
        </a>
      </td>
      <td>
        <a
          [class.disabled]="
            isEmpty(claimData.patientId) || isEmpty(claimData.chartId)
          "
          matTooltip="{{ claimData?.chartId || '-' }}"
          class="preview-link"
          [routerLink]="'/patientCreateEditTab/' + claimData?.patientId"
          target="_blank"
        >
          {{ claimData?.chartId || "-" }}
        </a>
      </td>
      <td class="text-right">
        <a
          class="pointer"
          matTooltip="{{ claimData?.defaultClaimId || '-' }}"
          [class.disabled]="
            isEmpty(claimData.defaultClaimId) ||
            isEmpty(claimData.orderId) ||
            isEmpty(claimData.patientId) ||
            isEmpty(claimData.mmRefOrderId) ||
            isEmpty(claimData.claimId)
          "
          (click)="
            openClaimForm(
              claimData.orderId,
              claimData.patientId,
              claimData.chartId,
              claimData.patientName,
              claimData.mmRefOrderId,
              claimData.claimId,
              true,
              claimData.isResubmitEnabled
            )
          "
        >
          {{ claimData?.defaultClaimId || "-" }}
        </a>
      </td>
      <td [matTooltip]="claimData?.patientName || '-'">
        {{ claimData?.patientName || "-" }}
      </td>
      <td [matTooltip]="claimData?.billedQuantity || '-'" class="text-right">
        {{ claimData?.billedQuantity || "-" }}
      </td>
      <!-- <td [matTooltip]="claimData?.billedAmount || '-'" class="text-right">
        {{ claimData?.billedAmount || '-' }}
      </td> -->
      <td [matTooltip]="claimData?.netTotal || '-'" class="text-right">
        {{ claimData?.netTotal || "-" }}
      </td>
      <!-- <td [matTooltip]="claimData?.shippingFee || '-'" class="text-right">
        {{ claimData?.shippingFee || "-" }}
      </td> -->
      <td [matTooltip]="claimData?.billedAmount || '-'" class="text-right">
        {{ claimData?.billedAmount || "-" }}
      </td>
      <!-- <td [matTooltip]="claimData?.allowedAmount || '-'" class="text-right">
        {{ claimData?.allowedAmount || "-" }}
      </td> -->
      <!-- <td [matTooltip]="claimData?.totalAccessory || '-'" class="text-right">
        {{ claimData?.totalAccessory || "-" }}
      </td> -->
      <!-- <td [matTooltip]="claimData?.insuranceBalance || '-'" class="text-right">
        {{ claimData?.insuranceBalance || "-" }}
      </td> -->

      <!-- <td class="custd nowarp customWidth">
        <div class="row">
          <div
            class="text-contentV1 "
            [ngClass]="isEmpty(claimData.recoopAmount) ? '' : 'col-8'"
          >
            <mat-label
              *ngIf="
                !isEmpty(claimData.recoopAmount) && claimData.recoopAmount > 0
              "
              [matTooltip]="'Recoup : $' + claimData?.recoopAmount || 0"
              class="cus-mat-label-recoop border rounded"
            >
              &#160;Recoup:$<span class="sf">{{
                claimData?.recoopAmount || 0
              }}</span
              >&#160;
            </mat-label>
          </div>
          <div
            [matTooltip]="
              isEmpty(claimData.insuranceAmount)
                ? '$0'
                : claimData.insuranceAmount
            "
            [ngClass]="isEmpty(claimData.recoopAmount) ? 'col-12' : 'col-4'"
          >
            <div class="text-right">
              <span class="sf">{{
                isEmpty(claimData.insuranceAmount)
                  ? "$0"
                  : claimData.insuranceAmount
              }}</span>
            </div>
          </div>
        </div>
      </td> -->

      <!-- <td class="text-right">
        <div class="row">
          <div class="text-contentV1 pr-0 col-4 vl">
            <mat-label
              *ngIf="claimData?.patientRespPaidAmount > 0"
              [matTooltip]="'Paid:$' + claimData?.patientRespPaidAmount || 0"
              class="cus-mat-label-paid border rounded"
            >
              &#160;Paid:$<span class="sf">{{
                claimData?.patientRespPaidAmount || 0
              }}</span
              >&#160;
            </mat-label>
          </div>
          <div class="text-contentV1 pl-0 pr-0 col-4 vl">
            <mat-label
              *ngIf="claimData?.patientRespPendingAmount > 0"
              [matTooltip]="
                'Pending:$' + claimData?.patientRespPendingAmount || 0
              "
              class="cus-mat-label-pending border rounded"
            >
              &#160;Pending:$<span class="sf">{{
                claimData?.patientRespPendingAmount || 0
              }}</span
              >&#160;
            </mat-label>
          </div>
          <div class="col-2 p-2 mt-1 vl">
            {{
              isEmpty(claimData.patientRespAmount)
                ? "-"
                : claimData.patientRespAmount
            }}
          </div>
        </div>
      </td> -->

      <!-- Total -->
      <td >
        <div class="row">

              <!-- *ngIf="claimData?.allowedAmount > 0" -->
          <div  class="text-contentV1 p-0 col-12"  >
            <mat-label
              [matTooltip]="'Allowable:' + claimData?.allowedAmount || 0"
              class="cus-mat-label-shippingFee  rounded "
            >
              &#160;Allowable:<span class="sf">{{
                claimData?.allowedAmount || 0
              }}</span
              >&#160;
            </mat-label>
          </div>
          <!-- *ngIf="claimData?.totalAccessory > 0" -->
          <div   class="text-contentV1  p-0 col-12 ">
            <mat-label
              [matTooltip]="
                'Accessory:' + claimData?.totalAccessory || 0
              "
              class="cus-mat-label-shippingFee  rounded"
            >
              &#160;Accessory:<span class="sf">{{
                claimData?.totalAccessory || 0
              }}</span
              >&#160;
            </mat-label>
          </div>
          <!-- *ngIf="claimData?.shippingFee > 0" -->
          <div  class=" text-contentV1 col-12 p-0">
            <mat-label
              [matTooltip]="
                'Shipping Fee:' + claimData?.shippingFee || 0
              "
              class="cus-mat-label-shippingFee  rounded"
            >
              &#160;Shipping Fee:<span class="sf">{{
                claimData?.shippingFee || 0
              }}</span
              >&#160;
            </mat-label>
          </div>
        </div>
      </td>
      <!-- paid -->
      <td >
        <div class="row">

              <!-- *ngIf="claimData?.allowedAmount > 0" -->
          <div  class="text-contentV1 p-0 col-12"  >
            <mat-label
              [matTooltip]="'Total:' + claimData?.totalPaidAmount || 0"
              class="cus-mat-label-shippingFee  rounded "
            >
              &#160;Total:<span class="sf">{{
                claimData?.totalPaidAmount || 0
              }}</span
              >&#160;
            </mat-label>
          </div>
          <!-- *ngIf="claimData?.totalAccessory > 0" -->
          <div   class="text-contentV1  p-0 col-12 " style="text-align: left !important;">
            <mat-label
              class="cus-mat-label-shippingFee  rounded"
              style="text-align: left;"
            >
            <img
              [matTooltip]="'Recoup:$' + (claimData?.recoopAmount || 0)"
                class="img-responsive"
                src="assets/Patient Menu Icons/OverPay.png"
                alt=""
                height="18"
              />
              &#160;Insurance:<span class="sf">{{
                claimData?.insuranceAmount || 0
              }}</span
              >&#160;
              <!-- <button
              class="custom-mainmenu-btn pt-1 color-black"
              mat-menu-item
             [matTooltip]="claimData?.recoopAmount || 0"
            > -->

            <!-- </button> -->
            </mat-label>

          </div>
          <!-- *ngIf="claimData?.shippingFee > 0" -->
          <div  class=" text-contentV1 col-12 p-0">
            <mat-label
              [matTooltip]="
                'Patient:' + (claimData?.patientRespPaidAmount || 0)
              "
              class="cus-mat-label-shippingFee  rounded"
            >
              &#160;Patient:<span class="sf">{{
                claimData?.patientRespPaidAmount || 0
              }}</span
              >&#160;
            </mat-label>
          </div>
        </div>
      </td>
        <!-- Balance -->
      <td >
        <div class="row">

              <!-- *ngIf="claimData?.allowedAmount > 0" -->
          <div  class="text-contentV1 p-0 col-12"  >
            <mat-label
              [matTooltip]="'Total:' + (claimData?.totalBalanceAmount || 0)"
              class="cus-mat-label-shippingFee  rounded "
            >
              &#160;Total:<span class="sf">{{
                claimData?.totalBalanceAmount || 0
              }}</span
              >&#160;
            </mat-label>
          </div>
          <!-- *ngIf="claimData?.totalAccessory > 0" -->
          <div   class="text-contentV1  p-0 col-12 ">
            <mat-label
              [matTooltip]="
                'Insurance:' + (claimData?.insuranceBalance || 0)
              "
              class="cus-mat-label-shippingFee  rounded"
            >
              &#160;Insurance:<span class="sf">{{
                claimData?.insuranceBalance || 0
              }}</span
              >&#160;
            </mat-label>
          </div>
          <!-- *ngIf="claimData?.shippingFee > 0" -->
          <div  class=" text-contentV1 col-12 p-0">
            <mat-label
              [matTooltip]="
                'Patient:' + (claimData?.patientRespPendingAmount || 0)
              "
              class="cus-mat-label-shippingFee  rounded"
            >
              &#160;Patient:<span class="sf">{{
                claimData?.patientRespPendingAmount || 0
              }}</span
              >&#160;
            </mat-label>
          </div>
        </div>
      </td>

      <td >
        <div class="d-flex align-items-center justify-content-between">
        <a
          class="pointer "
          [class.disabled]="
            isEmpty(claimData.chequeNumber) || isEmpty(claimData.eobBlobName)
          "
          (click)="openEOBPDF(claimData)"
        >
        {{ claimData?.chequeNumber|| "-"}}
        </a>
        </div>
        <div style="padding-top: 15px !important;" class="d-flex align-items-center justify-content-between"
        *ngIf="claimData?.checkDate!==null && claimData?.checkDate!=='-'">
          ({{claimData?.checkDate }})
        </div>
      </td>
      <!-- <td [matTooltip]="claimData?.checkDate">
        {{ claimData?.checkDate }}
      </td> -->
      <td [matTooltip]="claimData?.payerName || '-'">
        {{ claimData?.payerName || "-" }}
      </td>

      <td [matTooltip]="claimData?.postingCount || '-'" class="text-right">
        {{ claimData?.postingCount || "-" }}
      </td>

      <td [matTooltip]="claimData?.clearingHouse || '-'">
        {{ claimData?.clearingHouse || "-" }}
      </td>
      <td [matTooltip]="claimData?.processedDate">
        {{ claimData?.processedDate }}
      </td>
      <td [matTooltip]="claimData?.providerName || '-'">
        {{ claimData?.providerName || "-" }}
      </td>
      <td [matTooltip]="claimData?.isCCSettled ? 'Settled' : 'Unsettled'">
        {{ claimData?.isCCSettled ? "Settled" : "Unsettled" }}
      </td>
      <td [matTooltip]="claimData?.defaultBatchId || '-'">
        {{ claimData?.defaultBatchId || "-" }}
      </td>
      <td class="text-right" [matTooltip]="claimData?.agingDays || '0'">
        {{ claimData?.agingDays || "0" }}
      </td>
      <td>
        <button mat-button (click)="enableClaimResubmission(claimData.claimId)" [disabled]="!claimData?.isUndoClaim">
          <mat-icon matTooltip="Undo Claim Submission">undo</mat-icon>
        </button>
      </td>
      <!-- [matTooltip]="claimData?.writeOffAmount || '0'" -->
      <td class="text-right" >
        {{ claimData?.writeOffAmount || "0" }}
        <img
        [matTooltip]="claimData?.writeOffAmount && claimData?.writeOffAmount != 0 ? 'Click to View Write Off Notes' : 'No Preview Available'"
          class="img-responsive "
          [ngClass]="claimData?.writeOffAmount && claimData?.writeOffAmount != 0 ? 'pointer' : 'cursor-not-allowed'"
          src="assets/Patient Menu Icons/writeOffNotesV1.png"
          alt=""
          height="16"
          (click)="openWriteOffNotes(claimData)"
        />
        &#160;
      </td>
      <td
        [matTooltip]="claimData?.claimCurrentBalance || '-'"
        class="text-center"
      >
        {{ claimData?.claimCurrentBalance || "-" }}
      </td>
      <td [matTooltip]="claimData?.waystarStatus || '-'">
        {{ claimData?.waystarStatus || "-" }}
      </td>
      <td class="text-center">
        <div class="d-flex align-items-center justify-content-between">
          <mat-icon
            class="text-danger pt-1 f-15 pointer"
            mat-icon-button
            [hidden]="
              !claimData.isResubmitEnabled ||
              claimData.isResubmitEnabled == null
            "
            customToolTip
            (mouseover)="showError(claimData?.claimId)"
            [contentTemplate]="template"
            >info</mat-icon
          >
          <div
            class="w-25"
            [hidden]="
              !(
                !claimData.isResubmitEnabled ||
                claimData.isResubmitEnabled == null
              )
            "
          ></div>
          <span
            [matTooltip]="claimData?.claimStatusName || '-'"
            [style.color]="updateTextColor(claimData?.colorCode)"
            [style.backgroundColor]="claimData?.colorCode"
            class="rounded-border ml-1 w-75 p-2 claim-status-label"
          >
            {{ claimData?.claimStatusName || "-" }}
          </span>
        </div>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th><mat-icon>apps</mat-icon></th>

      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Ticket Id"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Chart No"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Claim Id"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Patient Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Billed Quantity"
        />
      </th>
      <!-- <th>
        <input matInput class="textbox-border-class" type="text" placeholder="Billed Amount ($)" />
      </th> -->
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Net Total ($)"
        />
      </th>

      <!-- <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Shipping Fee ($)"
        />
      </th> -->
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Item Total ($)"
        />
      </th>
      <!-- <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Total Allowed ($)"
        />
      </th> -->
<!--
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Total Acessory($)"
        />
      </th> -->

      <!-- <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Insurance Balance ($)"
        />
      </th>

      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Insurance Amount ($)"
        />
      </th> -->

      <th class="customWidth">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Total($)"
        />
      </th>
      <th class="customWidth">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Paid ($)"
        />
      </th>
      <th class="customWidth">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Balance ($)"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Check No"
        />
      </th>
      <!-- <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Check Date"
        />
      </th> -->
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Payer Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Posting Count"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Clearing House"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Processed Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Provider Name"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Settled Status"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Batch No"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Aging Days"
        />
      </th>
      <th class="text-right">
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Undo"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Write Off Amount ($)"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Current Balance ($)"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Waystar Status"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Status"
        />
      </th>
    </tr>
  </tfoot>
</table>
<ng-template #template>
  <mat-spinner
    *ngIf="spinnerEnabled"
    class="spinner-border-sm"
    diameter="20"
  ></mat-spinner>
  <div
    style="display: flex; flex-direction: column"
    *ngIf="ltErrors.length > 0 && !spinnerEnabled"
  >
    <p class="text-danger p-1 mb-0 error-popup bolder">Error in Claim Form</p>
    <mat-divider></mat-divider>
    <ng-container *ngFor="let data of ltErrors">
      <p class="mb-0 p-1 error-popup">
        {{ data.serialNumber }}.{{ data.error }}
      </p>
      <mat-divider></mat-divider>
    </ng-container>
  </div>
  <div
    style="display: flex; flex-direction: column"
    *ngIf="ltErrors.length == 0 && !spinnerEnabled"
  >
    <p class="mb-0 p-1 error-popup">No Error Data Found</p>
  </div>
</ng-template>
