import type { CreateUpdateDoctorDTO, CreateUpdatePracticeDoctorDTO, DoctorDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Doctor } from './practice-management/models';
import { DrpPhysiciansManagementDTO } from './models';

@Injectable({
  providedIn: 'root',
})
export class DoctorService {
  apiName = 'Default';

  create = (input: CreateUpdateDoctorDTO) =>
    this.restService.request<any, DoctorDTO>({
      method: 'POST',
      url: '/api/app/doctor',
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/doctor/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, DoctorDTO>({
      method: 'GET',
      url: `/api/app/doctor/${id}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getDoctorsBasedOnOrganizationByOrganizationUnitId = (organizationUnitId: string) =>
    this.restService.request<any, DoctorDTO[]>({
      method: 'GET',
      url: `/api/app/doctor/doctors-based-on-organization/${organizationUnitId}`,
    },
     { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<DoctorDTO>>({
      method: 'GET',
      url: '/api/app/doctor',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
     { apiName: this.apiName, skipHandleError: true });

  update = (id: string, input: CreateUpdateDoctorDTO) =>
    this.restService.request<any, DoctorDTO>({
      method: 'PUT',
      url: `/api/app/doctor/${id}`,
      body: input,
    },
     { apiName: this.apiName, skipHandleError: true });

     updatePracticeDoctor = (id: string, input: CreateUpdatePracticeDoctorDTO) =>
     this.restService.request<any, Doctor>({
       method: 'PUT',
       url: `/api/app/doctor/${id}/practice-doctor`,
       body: input,
     },
     { apiName: this.apiName });

    getPracticeDropdown = (sPracticeSearch:string,gPractice:string) =>
    this.restService.request<any, any>({
      method: 'POST',
      url: `/api/app/practice-management/drp-practice`,
      params:{sPracticeSearch,gPractice}
    },
     { apiName: this.apiName, skipHandleError: true });


     getPhysicianDropdown = (PracticeID:string,fullName:string) =>
      this.restService.request<any, any>({
        method: 'GET',
        url: `/api/app/doctor/get-all-physicians`,
        params:{PracticeID,fullName}
      },
       { apiName: this.apiName, skipHandleError: true });

     getPhysicianDropdownV1 = (PracticeID:string,fullName:string,gDoctor : string) =>
    this.restService.request<any, any>({
      method: 'GET',
      url: `/api/app/doctor/get-all-physicians`,
      params:{PracticeID,fullName,gDoctor}
    },
     { apiName: this.apiName, skipHandleError: true });

     getAllPhysiciansV1ByGDoctorAndSSearch = (gDoctor: string, sSearch: string) =>
      this.restService.request<any, DrpPhysiciansManagementDTO[]>({
        method: 'GET',
        url: '/api/app/doctor/get-all-physicians-v1',
        params: { gDoctor, sSearch },
      },
      { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
