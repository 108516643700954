<div class="col-lg-12 px-0">
  <!-- Row 1-->
  <div class="row">
    <div class="col-lg-10">
      <div class="card elevated-div">
        <!-- <div class="card-header">Shipping List</div> -->
        <div class="card-body pb-0 mb-1 mt-1 p-0">
          <div class="col-12 content table-responsive">
            <div
              class="d-flex justify-content-center"
              *ngIf="!isBillingTableLoaded"
            >
              <!-- <mat-spinner></mat-spinner> -->
              <img
                class="img-responsive pb-2 mr-1"
                src="assets/gifs/Infinity-loader.gif"
              />
            </div>

            <div class="table table-responsive">
              <table
                *ngIf="isBillingTableLoaded"
                datatable
                [dtOptions]="dtBillingOptions"
                class="row-border hover w-100 display"
              >
                <thead>
                  <tr>
                    <!-- <th>#</th> -->
                    <th><mat-icon>apps</mat-icon></th>
                    <th [matTooltip]="'Ticket ID'" class="text-right">
                      Ticket ID
                    </th>
                    <th [matTooltip]="'Location'">Location</th>
                    <th [matTooltip]="'Chart No'">Chart No</th>
                    <th [matTooltip]="'Patient Name'">Patient Name</th>
                    <th [matTooltip]="'Shipping Method'">Shipping Method</th>
                    <th [matTooltip]="'Insurance/Cash'">Insurance/Cash</th>
                    <th [matTooltip]="'Supplies Date'">Supplies Date</th>
                    <th [matTooltip]="'Comments'">Comments</th>
                    <th [matTooltip]="'Email'">Email</th>
                    <th [matTooltip]="'Shipping Status'">Shipping Status</th>
                    <th [matTooltip]="'Patient Balance'" class="text-right">
                      Patient Balance
                    </th>
                    <th [matTooltip]="'Ins Balance'" class="text-right">
                      Ins Balance
                    </th>
                    <th [matTooltip]="'Total Balance'" class="text-right">
                      Total Balance
                    </th>
                    <th [matTooltip]="'Created By'">Created By</th>
                    <th class="text-right" [matTooltip]="'Created Date'">
                      Created Date
                    </th>
                    <th [matTooltip]="'Modify By'">Modify By</th>
                    <th class="text-right" [matTooltip]="'Modified Date'">
                      Modified Date
                    </th>
                    <th [matTooltip]="'Reference Ticket ID'" class="text-right">
                      Reference Ticket ID
                    </th>
                    <!-- <th>Pending</th> -->
                    <th [matTooltip]="'Since'">Since</th>
                    <!-- Back Ordered  -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    (click)="tblShpSelectedRow(ticket)"
                    [ngClass]="
                      this.selectedShippingOrderId === ticket.mmOrderId
                        ? 'row-highlighted'
                        : ''
                    "
                    *ngFor="let ticket of ticketDataArray"
                  >
                    <!-- <td></td> -->
                    <!-- Accessing properties of each ticket object for table cells -->
                    <td [matTooltip]="'Open Options'">
                      <mat-icon
                        style="cursor: pointer !important"
                        [matMenuTriggerFor]="menu"
                        >more_vert</mat-icon
                      >
                      <mat-menu #menu="matMenu">
                        <button
                          mat-menu-item
                          [hidden]="!checkActiveTab"
                          *ngIf="
                            activeTab == 'NewSetup' || activeTab == 'Supplies'
                          "
                          (click)="
                            openAddNotesPopUp(
                              ticket.defaultFaxId,
                              ticket.patientId,
                              ticket.docId,
                              false,
                              ticket.chartNo
                            )
                          "
                        >
                          Pending Notes
                        </button>

                        <button
                          mat-menu-item
                          (click)="
                            openAddNotesPopUp(
                              ticket.defaultFaxId,
                              ticket.patientId,
                              ticket.docId,
                              false,
                              ticket.chartNo
                            )
                          "
                        >
                          Add Patient Note
                        </button>
                        <button
                          mat-menu-item
                          (click)="
                            openViewNotesPopup(
                              ticket.patientId,
                              ticket.defaultFaxId,
                              ticket.docId
                            )
                          "
                        >
                          View Patient Notes
                        </button>
                        <button
                          mat-menu-item
                          (click)="viewPatientLedger(ticket?.patientId)"
                        >
                          View Patient Ledger
                        </button>
                        <button
                          [class.disabled]="
                            ticket.mmOrderId === defaultGuid ||
                            ticket.mmOrderId === null ||
                            ticket.mmOrderId === ''
                          "
                          (click)="
                            getOrderHistory(
                              ticket.mmOrderId,
                              ticket.mmRefereceOrderId,
                              ticket.chartNo,
                              ticket.fullName
                            )
                          "
                          mat-menu-item
                        >
                          Order History
                        </button>
                        <a
                          [routerLink]="
                            '/view_document_tiff/' +
                            ticket.faxDocumentId +
                            '/' +
                            true
                          "
                          target="_blank"
                          class="preview-link"
                          [class.disabled]="
                            isEmpty(ticket.faxDocumentId) ||
                            ticket?.isSorted === false ||
                            ticket?.isSorted === null
                          "
                        >
                          <button
                            [disabled]="
                              isEmpty(ticket.faxDocumentId) ||
                              ticket?.isSorted === false ||
                              ticket?.isSorted === null
                            "
                            mat-menu-item
                          >
                            View Document Image
                          </button>
                        </a>
                      </mat-menu>
                    </td>
                    <td
                      class="text-right"
                      [matTooltip]="ticket?.mmRefereceOrderId || '-'"
                    >
                      <a
                        class="preview-link"
                        [class.disabled]="
                          isEmpty(ticket.chartNo) || isEmpty(ticket.patientId)
                        "
                        [routerLink]="
                          '/orders/saleOrder/' +
                          ticket.patientId +
                          '/' +
                          ticket.mmOrderId +
                          '/' +
                          '0'
                        "
                        target="_blank"
                        >{{
                          ticket?.mmRefereceOrderId
                            ? ticket?.mmRefereceOrderId
                            : "-"
                        }}
                      </a>
                    </td>
                    <td [matTooltip]="ticket?.state || '-'">
                      {{
                        ticket.state === null || ticket.state === ""
                          ? "-"
                          : ticket.state
                      }}
                    </td>
                    <td [matTooltip]="ticket?.chartNo || '-'">
                      <a
                        class="preview-link"
                        [class.disabled]="
                          isEmpty(ticket.chartNo) || isEmpty(ticket.patientId)
                        "
                        [routerLink]="
                          '/patientCreateEditTab/' +
                          ticket.patientId +
                          '/' +
                          ticket.documentId
                        "
                        target="_blank"
                        >{{ ticket.chartNo }}
                      </a>
                    </td>
                    <td [matTooltip]="ticket?.fullName || '-'">
                      {{ ticket.fullName || "-" }}
                    </td>
                    <td [matTooltip]="ticket?.shippingMethod || '-'">
                      {{ ticket?.shippingMethod || "-" }}
                    </td>
                    <td
                      [matTooltip]="
                        ticket.isCashOrder
                          ? 'Cash'
                          : ticket.insuranceName === null ||
                            ticket.insuranceName === ''
                          ? '-'
                          : ticket.insuranceName
                      "
                    >
                      <!-- {{ ticket?.insuranceName || "-" }} -->

                      {{
                        ticket.isCashOrder
                          ? "Cash"
                          : ticket.insuranceName === null ||
                            ticket.insuranceName === ""
                          ? "-"
                          : ticket.insuranceName
                      }}
                    </td>
                    <!-- class="text-right" -->
                    <td [matTooltip]="ticket?.suppliesDate">
                      {{ ticket?.suppliesDate }}
                    </td>
                    <td [matTooltip]="ticket?.comments || '-'">
                      {{
                        ticket.comments === null || ticket.comments === ""
                          ? "-"
                          : ticket.comments
                      }}
                    </td>
                    <td [matTooltip]="ticket?.emailId || '-'">
                      {{
                        ticket.emailId === null || ticket.emailId === ""
                          ? "-"
                          : ticket.emailId
                      }}
                    </td>
                    <td [matTooltip]="ticket.shippingStatus || '-'">
                      {{ ticket.shippingStatus || "-" }}
                    </td>
                    <td
                      [matTooltip]="ticket.patientBalance || '0'"
                      class="text-right"
                    >
                      {{ ticket.patientBalance || "0" }}
                    </td>
                    <td
                      [matTooltip]="ticket.insurancePending || '0'"
                      class="text-right"
                    >
                      {{ ticket.insurancePending || "0" }}
                    </td>
                    <td
                      [matTooltip]="ticket.totalBalance || '0'"
                      class="text-right"
                    >
                      {{ ticket.totalBalance || "0" }}
                    </td>
                    <!-- <td [matTooltip]="ticket.username || '-'">
                      {{ ticket.username }}
                    </td> -->
                    <!-- <td><mat-checkbox></mat-checkbox></td> -->
                    <td [matTooltip]="ticket?.username || '-'">
                      {{ ticket?.username || "-" }}
                    </td>
                    <!-- class="text-right" -->
                    <td [matTooltip]="ticket?.createdDate">
                      {{ ticket?.createdDate }}
                    </td>
                    <td [matTooltip]="ticket?.lastModifyByName || '-'">
                      {{ ticket?.lastModifyByName || "-" }}
                    </td>
                    <!-- class="text-right" -->
                    <td [matTooltip]="ticket?.modifiedDate">
                      {{ ticket?.modifiedDate }}
                    </td>
                    <td
                      class="text-right"
                      [matTooltip]="ticket?.referenceOrderId || '-'"
                    >
                      {{ ticket?.referenceOrderId || "-" }}
                    </td>
                    <td [matTooltip]="ticket?.backOrderSince">
                      {{ ticket.backOrderSince }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 pl-1">
      <div class="card elevated-div card-body pt-4 pb-4">
        <form [formGroup]="billingForm">
          <!-- Billing Comments -->
          <mat-form-field class="w-100">
            <mat-label>Comments to Billing</mat-label>
            <textarea
              [value]="shippingListNotesDetails?.billingNotes"
              formControlName="txtBillingComments"
              rows="5"
              maxlength="5000"
              matInput
              readonly
            ></textarea>
          </mat-form-field>
          <!-- Shipping Comments -->
          <mat-form-field class="w-100">
            <mat-label>Comments to Shipping</mat-label>
            <textarea
              [value]="shippingListNotesDetails?.shippingNotes"
              formControlName="txtShippingComments"
              rows="5"
              maxlength="5000"
              matInput
              readonly
            ></textarea>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
  <br />

  <!-- Row 2 -->
  <div class="col-lg-12 px-0">
    <div class="card card-body py-3 elevated-div mt-1">
      <div class="row">
        <div class="col-12">
          <a
            mat-raised-button
            [disabled]="
              selectedShippingOrderId === defaultGuid ||
              checkListId == defaultGuid ||
              isCashOrder
            "
            target="_blank"
            [routerLink]="
              '/view_checklist/' +
              patientId +
              '/' +
              true +
              '/' +
              true +
              '/' +
              checkListId
            "
            class="mx-2 buttonColor preview-checklist-link"
          >
            <mat-icon style="margin-right: 5px">check</mat-icon>Check List
          </a>

          <!-- <a
          mat-raised-button
          class="mx-2 buttonColor preview-checklist-link"
          [disabled]="
            tblShpSelectedRowId == defaultGuid ||
            checkListId === defaultGuid
          "
          target="_blank"
          [routerLink]="
            '/view_checklist/' +
            patientId +
            '/' +
            true +
            '/' +
            true +
            '/' +
            checkListId
          "
        >
          <mat-icon>assignment_turned_in</mat-icon>Check List
        </a> -->

          <button
            [disabled]="
              selectedShippingOrderId === defaultGuid ||
              priVerificationId == defaultGuid ||
              isCashOrder
            "
            mat-raised-button
            (click)="openVerificationModal('Primary')"
            class="mx-2 buttonColor"
          >
            <mat-icon> bubble_chart</mat-icon>
            Primary Ins
          </button>
          <button
            mat-raised-button
            [disabled]="
              selectedShippingOrderId === defaultGuid ||
              secVerificationId == defaultGuid ||
              isCashOrder
            "
            (click)="openVerificationModal('secondary')"
            class="mx-2 buttonColor"
          >
            <mat-icon> linear_scale</mat-icon>Secondary Ins
          </button>
          <button
            mat-raised-button
            [disabled]="
              selectedShippingOrderId == defaultGuid ||
              authorizationId === defaultGuid ||
              isCashOrder
            "
            class="mx-2 buttonColor"
            (click)="viewAuthorization()"
          >
            <mat-icon>verified_user</mat-icon>Authorization
          </button>
          <button
            type="button"
            (click)="splitBillingItems()"
            mat-raised-button
            [disabled]="
              disableSplitButton ||
              selectedShippingOrderId === defaultGuid ||
              isCashOrder
            "
            class="buttonColor"
          >
            <img
              class="img-responsive pr-1 mb-1"
              src="assets/Shipping List Icons/Split.png"
              alt=""
              height="17"
            />Split
          </button>
          <button
            mat-raised-button
            (click)="billingComplet()"
            [disabled]="selectedShippingOrderId === defaultGuid"
            class="mx-2 float-right buttonColor"
          >
            <mat-icon style="margin-right: 5px" *ngIf="!isShowSpinner"
              >check_circle_outline</mat-icon
            >
            <mat-icon *ngIf="isShowSpinner">
              <mat-spinner
                class="spinner-border spinner-border-sm"
                diameter="20"
              ></mat-spinner>
            </mat-icon>
            Complete
          </button>
          <button
            mat-raised-button
            [disabled]="selectedShippingOrderId === defaultGuid"
            (click)="getPrintTicketDetails()"
            class="mx-2 buttonColor float-right"
          >
            <mat-icon style="margin-right: 5px">print</mat-icon>Print Ticket
          </button>
          <button
            (click)="viewPatientLedgerPopup()"
            mat-raised-button
            [disabled]="selectedShippingOrderId === defaultGuid"
            class="mx-2 buttonColor float-right"
          >
            <mat-icon style="margin-right: 5px">remove_red_eye</mat-icon>View
            Ledger
          </button>
        </div>
      </div>
    </div>
  </div>
  <br />

  <!-- row 3 -->
  <div class="row">
    <div class="col-lg-2">
      <div class="card elevated-div card-body p-0 mb-3">
        <div class="table-container">
          <table class="table mb-0">
            <thead>
              <tr>
                <th [matTooltip]="'Approval No'" class="custom-th">
                  Approval No
                </th>
                <th [matTooltip]="'Amount'" class="custom-th">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of authTableData">
                <td [matTooltip]="data.authId">
                  {{ data.approvalNo ? data.approvalNo : "-" }}
                </td>
                <td [matTooltip]="data.amount">{{ data.amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Items Table -->
    <div class="col-lg-9">
      <div class="card card-body elevated-div mb-3 pt-0 px-2 pb-1 mb-3">
        <div class="d-flex justify-content-center" *ngIf="!loadTable">
          <!-- <mat-spinner></mat-spinner> -->
          <img
            class="img-responsive pb-2 mr-1"
            src="assets/gifs/Infinity-loader.gif"
          />
        </div>

        <table
          datatable
          *ngIf="loadTable"
          [dtOptions]="dtItemOptions"
          class="table table-sm row-border hover display"
        >
          <thead>
            <tr>
              <th>#</th>
              <th><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'Prod Code'">Prod Code</th>
              <th [matTooltip]="'Description'">Description</th>
              <th [matTooltip]="'Qty'">Qty</th>
              <th [matTooltip]="'Back Ordered Since'">Back Ordered Since</th>
              <th [matTooltip]="'Pat Qty on Hand'" class="text-right">
                Pat Qty on Hand
              </th>
              <th [matTooltip]="'Notes'">Notes</th>
              <th [matTooltip]="'Allowable'" class="text-right">Allowable</th>
              <th [matTooltip]="'Accessory'" class="text-right">Accessory</th>
              <!-- <th>Exchange</th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              [ngClass]="
                this.tblItemSelectedRowId === product.orderItemId
                  ? 'row-highlighted'
                  : ''
              "
              (click)="
                tblItemSelectedRow(
                  product.orderItemId,
                  product.hcpcList,
                  product
                )
              "
              *ngFor="let product of productDataArray"
            >
              <td></td>
              <!-- Accessing properties of each product object for table cells -->
              <td>
                <!-- [disabled]="true" -->
                <mat-checkbox
                  (change)="checkSelectedItems()"
                  [(ngModel)]="product.isSelected"
                ></mat-checkbox>
              </td>
              <td [matTooltip]="product?.productCode || '-'">
                {{ product.productCode }}
              </td>
              <td [matTooltip]="product?.productDescription || '-'">
                {{ product.productDescription }}
              </td>
              <!-- <td>{{ product.quantity }}</td> -->
              <td [matTooltip]="product?.quantity || '-'" class="text-right">
                <!-- readonly="readonly" -->
                <input
                  autocomplete="off"
                  maxlength="8"
                  class="textbox-border-class dd-text text-right"
                  [(ngModel)]="product.quantity"
                  #txtQuantity="ngModel"
                  required
                  (focusout)="QtySplit(product)"
                  (keypress)="onKeyPress($event)"
                  matInput
                  type="text"
                />
                <div
                  class="text-danger dd-text text-center"
                  *ngIf="!txtQuantity.valid && txtQuantity.touched"
                >
                  Required!
                </div>
                <div
                  class="text-danger dd-text text-center"
                  *ngIf="
                    txtQuantity.value === '0' ||
                    txtQuantity.value === null ||
                    txtQuantity.value === ''
                  "
                >
                  Enter Minimum Quantity!
                </div>
              </td>
              <td
                [matTooltip]="
                  product?.backOrderedSince
                    ? (product?.backOrderedSince
                      | date : 'MM/dd/yyyy h:mm a' : 'en_US')
                    : '-'
                "
              >
                {{
                  product.backOrderedSince
                    | date : "MM/dd/yyyy h:mm a" : "en_US"
                }}
              </td>
              <td
                [matTooltip]="product?.patientOnHand || '0'"
                class="text-right"
              >
                {{ product.patientOnHand }}
              </td>
              <td [matTooltip]="product?.notes || '-'">
                {{
                  product.notes === null || product.notes === ""
                    ? "-"
                    : product.notes
                }}
              </td>
              <td [matTooltip]="product?.allowable || '0'" class="text-right">
                {{ product.allowable }}
              </td>
              <td [matTooltip]="product?.accessory || '0'" class="text-right">
                {{ product.accessory }}
              </td>
              <!-- <td><mat-checkbox></mat-checkbox></td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- HCPC Table -->
    <div class="col-lg-1">
      <div class="card elevated-div card-body p-0 mb-3">
        <div class="table-container">
          <table class="table table-bordered mb-0">
            <thead>
              <tr>
                <th [matTooltip]="'HCPC'" class="custom-th">HCPC</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of hcpcTableData">
                <td [matTooltip]="data.hcpcCode">{{ data.hcpcCode }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
