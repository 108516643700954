<meta http-equiv="cache-control" content="no-cache, must-revalidate, post-check=0, pre-check=0">
<meta http-equiv="expires" content="0">
<meta http-equiv="pragma" content="no-cache">
<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-2">
          <div class="card elevated-div">
              <div class="card card-body p-0">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
                  <div class="card py-3 card-body">
                    <div class="d-flex justify-content-between">
                      <!-- <button mat-button (click)="check()">C</button> -->
                      <b (click)="TiffError === false && loadTotalDocument()"
                              class=" pl-2 ml-2 header-text-v1 cursor-pointer"
                              [ngClass]="{'selected-option': selectedOption === sotingSelectedCSS.Total,'no-drop':TiffError === true}">
                              NO OF PAGES :
                              <ng-container *ngIf="overAllPages === overAllPagesBG; else TshowAllPages">
                                {{ overAllPages || docOverallPages}}&nbsp;
                              </ng-container>
                              <ng-template #TshowAllPages >
                                <span class="header-text-v1 cursor-pointer" [ngClass]="{ 'blink-text': true, 'selected-option': selectedOption === sotingSelectedCSS.Total }">
                                {{ overAllPages || docOverallPages }}
                                </span>/{{ overAllPagesBG || docOverallPages }}&nbsp;
                              </ng-template>
                            </b>
                            <b (click)="PendingDocumentImageList?.length && loadPendingDocument()" class=" pl-2 ml-2 header-text-v1 "
                              [ngClass]="{'selected-option': selectedOption === sotingSelectedCSS.Pending}"
                              [ngClass]="{'cursor-pointer': PendingDocumentImageList?.length > 0 }"
                              >
                              UNSORTED : {{ PendingDocumentImageList?.length || 0 }}&nbsp;
                            </b>

                            <b (click)="SortedDocumentImageList?.length && loadSortedDocument()" class=" pl-2 ml-2 header-text-v1 cursor-pointer"
                              [ngClass]="{'selected-option': selectedOption === sotingSelectedCSS.Sorted}"
                              [ngClass]="{'cursor-pointer': SortedDocumentImageList?.length > 0 }"
                              >
                              SORTED : {{ SortedDocumentImageList?.length || 0 }}&nbsp;
                            </b>

                    </div>
                  </div>
                </div>

                <div class="card card-body p-0" [hidden]="isSortingStatus">
                  <div class="col-12 px-0">
                    <div class="card py-2 card-body" style="padding: 0.5rem;">
                      <form [formGroup]="mstDocumentBinForm">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex pl-0 align-items-center">

                          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-flex pl-0 align-items-center">
                            <b class="header-text-v1 cursor-pointer upperText text-font-size"
                              matTooltip="Add Document Bin">
                              Add Document Bin
                            </b>
                          </div>
                          <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0">
                            <div class="running-placeholder-container">

                              <input formControlName="txtBinName" class="textbox-border-class text-font-size" maxlength="50"
                                placeholder="Enter Document Bin Name" type="text" title="Enter Document Bin Name" oninput="this.value = this.value.replace(/[^a-zA-Z0-9-\s]+/g, ''); this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);"/>
                                <mat-error *ngIf="mstDocumentBinForm?.get('txtBinName')?.errors?.isDocumentBinExists" class="text-font-size">
                                  Bin Name is already taken!
                              </mat-error>
                              </div>
                          </div>
                          <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-1">
                            <button [disabled]="mstDocumentBinForm.invalid || DocumentBinSaveBtn" (click)="saveDocumentBin()" mat-icon-button
                              class="mr-2 buttonColor ml-4 d-flex align-items-center justify-content-center"
                              color="lightseagreen">
                              <mat-icon style="font-size: 20px">add</mat-icon>
                            </button>
                          </div>
                        </div>
                      </form>

                    </div>
                  </div>
                </div>

                <div class="card-body mb-0  scrollable-card p-0" style="max-height: 66vh">
                  <ng-container *ngIf="!isLoadSortingView">
                    <ng-container *ngFor="let document of documentTypes">
                      <mat-accordion>
                        <mat-expansion-panel [hidden]="document.shortCode == SortingShortCode.All" [hideToggle]="true" [disabled]="true" class="mt-1">
                        <mat-expansion-panel-header
                          class="customThemeClass custom-header-text-sorting custom-accordion-border">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex pl-0 align-items-center"
                            (click)="$event.stopPropagation();" style="overflow: hidden;">
                            <!-- [ngClass]="{'selected-option': selectedOption === sotingSelectedCSS.Bin && document_shortCode == document.shortCode}" -->
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-flex pl-0 pr-0 align-items-center"
                              (click)="document.pageCount===document.docPageContent?.length && loadBinDocument(document.shortCode,document.pageCount,document.documentTypeId)"
                              style="overflow: hidden;"

                              [ngClass]="{
                                'selected-option': selectedOption === sotingSelectedCSS.Bin && document_documentTypeId == document.documentTypeId,
                                'no-drop': document.pageCount !== document.docPageContent?.length
                              }"

                              >
                              {{ document.documentType | uppercase }}   ({{ (document.shortCode === SortingShortCode.SIGNED_DOCUMENT) ? hellosignPageCount : document.pageCount}})
                            </div>
                            <!-- style="overflow: hidden;" -->
                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 p-0"  >

                              <div class="running-placeholder-container">
                                <input [hidden]="isSortingStatus" #myInputRefs class="textbox-border-class text-font-size"
                                maxlength="20" (keypress)="numberOnly($event)"
                                placeholder="1 (or) 1-5 (or) 1,3,4 (or) leave it empty"
                                type="text"
                                title="Enter a single number or a range (e.g., 1, 1-5) or leave it empty"
                                [disabled]="document.shortCode == SortingShortCode.All || document.shortCode == SortingShortCode.SIGNED_DOCUMENT"  />
                                <!-- style="width: 105px;" -->
                              </div>
                            </div>
                            <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 ml-2 pl-0">
                              <button *ngIf="!isSortingStatus" (click)="document.shortCode != SortingShortCode.SIGNED_DOCUMENT && moveDocument(myInputRefs.value,document)"
                              mat-icon-button
                              [disabled]="document.shortCode == SortingShortCode.All || document.shortCode == SortingShortCode.SIGNED_DOCUMENT || isDisabled || TiffError || document_shortCode === document.shortCode || is011Clicked || !(isTotalAPIcallGoingOnCount > 1) || overAllPages != overAllPagesBG || overAllPagesBG === 0 || overAllPages !== SortedDocumentImageList.length+PendingDocumentImageList.length || (isInbound === false && defaultFaxShortCodeId !== SortingShortCode.All )"
                              [ngClass]="{
                              'mr-2': !document.isMedicareBin
                              }" class="buttonColor ml-4 d-flex align-items-center justify-content-center"
                              color="lightseagreen">
                              <mat-icon [hidden]="isSortingStatus" class="mat-icon-font-size">arrow_back</mat-icon>
                              </button>
                            </div>
                          </div>
                        </mat-expansion-panel-header>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="isLoadSortingView">
                    <div class="d-flex justify-content-center">
                      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
                    </div>
                  </ng-container>
                </div>
              </div>
            <div class="card-footer bg-transparent d-flex justify-content-end">

              <button *ngIf="!isSortingStatus"  [matTooltip]="'Save Sorting Documents'" class="defaultButton saveButton" mat-raised-button
                (click)="saveSortedDetails()" [disabled]="isDisabled || SortSaveError || saveDocumentDetails?.length === 0">
                <mat-icon  *ngIf="!isDisabled" >check</mat-icon>

                <mat-icon *ngIf="isDisabled">
                  <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
              </mat-icon>

              Save

              </button>


            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TIFF Viewer -->

    <div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">

      <div class="card card-body align-items-center justify-content-center p-0">
        <!-- <h1 class="fw-bolder" [ngClass]="{ 'blink-text': isLoading || TiffError }" *ngIf="isLoading || TiffError">
          {{ loadingMessage }}
        </h1> -->

        <div *ngIf="isLoading" class="d-flex justify-content-center">
          <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>

        <div *ngIf="!TiffError">
          <div class="zoom-buttons" *ngIf="!isLoading">

        <a [matTooltip]="'Clear All Annotation'" class="ml-2 mt-2" (click)="clearAllAnnotation()"
            [class.disabled]="this.textAnnotations.length === 0 || disableAnnotation" href="javascript:void(0)">
            <mat-icon>clear</mat-icon>
            <!-- restore -->
        </a>

        <a [matTooltip]="'Zoom In Page'" class="ml-2 mt-2" (click)="zoomIn()"
            [class.disabled]="isLoading || zoomLevel === MAX_ZOOM_LEVEL"
            href="javascript:void(0)">
            <mat-icon>add</mat-icon>
        </a>

        <a [matTooltip]="'Zoom Out Page'" class="ml-2 mt-2" (click)="zoomOut()"
            [class.disabled]="isLoading || zoomLevel === MIN_ZOOM_LEVEL"
            href="javascript:void(0)">
            <mat-icon>remove</mat-icon>
        </a>

        <a [matTooltip]="'Go to First Page'" class="ml-2 mt-2" (click)="firstImage()"
          [class.disabled]="isLoading || pageNumber === 1" href="javascript:void(0)">
          <mat-icon>first_page</mat-icon>
        </a>

        <a [matTooltip]="'Go to Previous Page'" class="ml-2 mt-2" (click)="previousImage()"
          [class.disabled]="isLoading ||  pageNumber === 1" href="javascript:void(0)">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </a>

        <input [matTooltip]="'Go to Custom Page'" matInput class="form-control custom-input" type="number"
                [(ngModel)]="pageNumber" min="1" max="{{totalPages}}" (focusout)="goToPage()"
                [readonly]="isLoading || disableAnnotation" />

        <a [matTooltip]="'Go to Next Page'" class="ml-2 mt-2" (click)="nextImage()"
                [class.disabled]="isLoading || pageNumber === existingDocumentImageList.length"
                href="javascript:void(0)">
                <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
        <a [matTooltip]="'Go to Last Page'" class="ml-2 mt-2" (click)="lastImage()"
                [class.disabled]="isLoading || pageNumber === existingDocumentImageList.length"
                href="javascript:void(0)">
                <mat-icon>last_page</mat-icon>
        </a>

            <span> of {{ totalPages }}</span>

          <a [matTooltip]="'Save Annotation'" class="ml-2 mt-2" (click)="saveAnnotation()"
            [class.disabled]="this.textAnnotations.length === 0 || disableAnnotation" href="javascript:void(0)">
            <mat-icon *ngIf="!disableAnnotation">save</mat-icon>

            <mat-icon *ngIf="disableAnnotation">
              <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
            </mat-icon>
          </a>


            <!-- <button *ngIf="!isSortingStatus" [matTooltip]="'Clear All Annotation'" mat-raised-button color="warn"
              (click)="clearAllAnnotation()"
              [disabled]="this.textAnnotations.length === 0 || disableAnnotation"><mat-icon>restore</mat-icon></button>
            <button [matTooltip]="'Zoom In Page'" mat-button color="primary" (click)="zoomIn()"
              [disabled]="isLoading || disableAnnotation">
              <mat-icon>add</mat-icon>
            </button>
            <button [matTooltip]="'Go to First Page'" mat-button color="primary" (click)="firstImage()"
              [disabled]="isLoading || disableAnnotation">
              <mat-icon>first_page</mat-icon>
            </button>
            <button [matTooltip]="'Go to Previous Page'" mat-button color="primary" (click)="previousImage()"
              [disabled]="isLoading || disableAnnotation">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <input [matTooltip]="'Go to Custom Page'" matInput class="form-control custom-input" type="number"
              [(ngModel)]="pageNumber" min="1" max="{{totalPages}}" (focusout)="goToPage()" [readonly]="isLoading || disableAnnotation" />

            <span> of {{ totalPages }}</span>
            <button [matTooltip]="'Go to Next Page'" mat-button color="primary" (click)="nextImage()"
              [disabled]="isLoading || disableAnnotation">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <button [matTooltip]="'Go to Last Page'" mat-button color="primary" (click)="lastImage()"
              [disabled]="isLoading || disableAnnotation">
              <mat-icon>last_page</mat-icon>
            </button>

            <button [matTooltip]="'Zoom Out Page'" mat-button color="primary" (click)="zoomOut()"
              [disabled]="isLoading || disableAnnotation">
              <mat-icon>remove</mat-icon>
            </button>
            <button *ngIf="!isSortingStatus" [matTooltip]="'Save Annotation'" class="defaultButton saveButton" mat-raised-button
              (click)="saveAnnotation()" [disabled]="this.textAnnotations.length === 0 || disableAnnotation">
              <mat-icon  *ngIf="!disableAnnotation" >save</mat-icon>

              <mat-icon *ngIf="disableAnnotation">
                <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
            </mat-icon>

            </button> -->

            <!-- <div class="button-container">
              <button [matTooltip]="'Save Annotation'" class="defaultButton saveButton" mat-raised-button
                (click)="saveAnnotation()" [disabled]="this.textAnnotations.length === 0 || disableAnnotation">
                <mat-icon *ngIf="disableAnnotation">save</mat-icon>
                <mat-spinner *ngIf="!disableAnnotation" diameter="30"></mat-spinner>
              </button>
            </div> -->

          </div>
        </div>
      </div>

      <div [hidden]="isHideTiffViewer" [ngClass]="{'isHideTiffViewer': isLoading,'d-flex': !isHideTiffViewer && !isLoading}"
        class="d-flex justify-content-center scrollable-card p-0 backgroundclr border-line">
        <div class="scrollable-card-image" [hidden]="disableAnnotation">
          <canvas #canvas (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"
            (mouseup)="onMouseUp($event)" (mouseleave)="onMouseLeave($event)" (dblclick)="deleteText($event)"
            (click)="addText($event)"></canvas>
          <input [(ngModel)]="tiffPageNo" type="hidden" name="tiffPageNo" />
        </div>
      </div>

      <div *ngIf="isHideTiffViewer" class="pdf-container pb-4">

        <ng-container *ngIf="showPdfViwer">
          <ngx-extended-pdf-viewer [base64Src]="strPdfString" [height]="pdfheight" [showSidebarButton]="false"
            [showFindButton]="true" [textLayer]="true" [showPagingButtons]="true" [showOpenFileButton]="false"
            [showHandToolButton]="false" [showScrollingButton]="false" [showSpreadButton]="false"
            [showPropertiesButton]="false" [showBookmarkButton]="false" [zoom]="'page-width'"
            [enableDragAndDrop]="false">
          </ngx-extended-pdf-viewer>
        </ng-container>

        <ng-container *ngIf="!showPdfViwer">
          <div class="d-flex justify-content-center">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
          </div>
        </ng-container>

      </div>


    </div>

    <!--Tiff Preview Viewer-->
    <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 p-0" [hidden]="isHideTiffViewer">
      <div class="card card-body align-items-center scrollable-card p-0" style="height: 77vh">
          <ng-container *ngFor="let image of existingDocumentImageList">
              <div class="card my-2 elevated-div custom-thumbnail-view">
                  <img class="card-img-top img-fluid custom-thumbnail-image" (click)="loadExistingImage(image)"
                      [ngClass]="{
              'custom-thumbnail-view-selected':
                selectedExistingDocument?.pageNo === image?.pageNo
            }" [src]="image?.src" [alt]="image?.pageNo" />

              </div>
          </ng-container>
      </div>
  </div> -->
  </div>
      <!-- <p class="align-end">Page No : {{ image.pageNo }}</p> -->
  <app-loader-component *ngIf="isOrderPageLoading"></app-loader-component>
