<h2 mat-dialog-title>
  <mat-toolbar class="buttonColor">
    <div class="align-right-end">Notifications List</div>
    <div *ngIf="notificationList?.length!==0" mat-subheader>
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button class="float-right" matTooltip="Mark All Notifications as Read" (click)="markAllread()"
          matTooltipPosition="before" mat-menu-item>
          <mat-icon>clear_all</mat-icon>Read All
        </button>
        <br>
        <button class="float-right" matTooltip="Mark All Notifications as Dismiss" (click)="markAllDismissed()"
          matTooltipPosition="before" mat-menu-item>
          <mat-icon>cancel</mat-icon>Dismiss All
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>
</h2>
<mat-dialog-content>

  <ng-container *ngIf="notificationList?.length!==0; else elseTemplate">
    <span>
      <span><b>Status :</b></span>
      <button *ngIf="intOutOfStock!==0" mat-button class="btn btn-sm danger">
        Out Of Stock
      </button>
      <button *ngIf="intNoStock!==0" mat-button class="btn btn-sm dangers">
        No Stock
      </button>
      <button *ngIf="intLowStock!==0" mat-button class="btn btn-sm warning">
        Low Stock
      </button>
      <button *ngIf="intReStocked!==0" mat-button class="btn btn-sm success">
        In Stock
      </button>
      <button *ngIf="intRead!==0" mat-button class="btn btn-sm read-message">
        Read
      </button>
    </span>
    <mat-list class="notify-list-width">
      <mat-list-item class="mt-1 pb-0" *ngFor="let notify of notificationList"
        [ngClass]="{ 'read-message':notify.readStatus,  'danger': notify?.productStatus === 'Out Of Stock' && !notify.readStatus ,'dangers': notify?.productStatus === 'No stock' && !notify.readStatus,'success':  notify?.productStatus  === 'In Stock' && !notify.readStatus  ,'warning':  notify?.productStatus === 'Low Stock' && !notify.readStatus }">
        <div id="{{notify?.id}}" mat-line class="m-0 ">
          <span>
            {{ notify?.hcpcCode }}
          </span>
          <span *ngIf="notify?.message">-</span>
          <span>
            {{ notify?.message }}
          </span>
          <span class="ml-3">
            <span matTooltip="Current Stock" matTooltipPosition="before">
              {{ notify?.currentQty }}
            </span>
            <span> / </span>
            <span matTooltip="Minimum Stock" matTooltipPosition="before">
              {{ notify?.minimumQty }}
            </span>
          </span>
        </div>
        <div mat-line>
          <span class="text-below-left">
            <span class="text-muted" matTooltip="Prdouct Category" matTooltipPosition="before"> {{ notify?.categoryName
              }}</span>
            <span *ngIf="notify?.brandName">-</span>
            <span class="text-muted" matTooltip="Prdouct Brand" matTooltipPosition="before"> {{ notify?.brandName }}
            </span>
            <span class="text-muted pl-5 date"> {{notify?.creationTime | date :'MM/dd/yyyy h:mm a' :'en_US'}} </span>
          </span>
        </div>
        <button mat-icon-button class="float-right mb-2 mr-2" (click)="readNotifyById(notify)" matTooltip="Mark As Read"
          matTooltipPosition="before">
          <mat-icon>clear_all</mat-icon>
        </button>
        <button mat-icon-button class="float-right mb-2" (click)="dismissNotifyById(notify)"
          matTooltip="Mark As Dismissed" matTooltipPosition="before">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="hstack gap-3">
      <mat-list class="notify-list-width">
        <mat-list-item role="listitem">
          <b> There Are No New Notifications Now...</b>
          <mat-icon>notifications_none</mat-icon>
        </mat-list-item>
      </mat-list>
    </div>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
