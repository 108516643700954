import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TableService } from 'projects/shared/src/app/table.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { ProductNotification } from '../dashboard/product-notification';
import { ReminderService } from '../dashboard/reminder.service';
import { ProductStatus } from '../warehouse-proxy/rcm/warehouse-management/warehouse/product-status.enum';


@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss']
})
export class NotificationMessageComponent implements OnInit {
  reminders: ProductNotification[] = [];
  private _subscription: Subscription;
  organizationUnitId = null;
  constructor(private tableService: TableService, private reminderService: ReminderService) { }
  patientName: string = "";
  reminderSubject: string = "";
  loginUserName: string = "";
  
  //! page initialization function
  ngOnInit(): void {
    this._subscription = this.reminderService._subject.subscribe(reminder => {
      this._addReminder(reminder);
    
      
    });
    this.tableService.getLoginUserName().subscribe(val => {
      this.loginUserName = val;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  close() {
    //  this.snackBar.dismiss();
  }
  //! Close toastr using class instance
  closeReminder(reminder: ProductNotification) {
    this.reminders = this.reminders?.filter(rem => rem?.id !== reminder?.id);
  }
  //! Add toastr using class instance
  _addReminder(reminder: ProductNotification) {
    reminder && this.reminders?.push(reminder);
    if (reminder?.timeout !== 0) {
      setTimeout(() => this.closeReminder(reminder), 1000000);
    }
  }
  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
  //! Style Setting toastr
  className(reminder: ProductNotification): string {
    let style: string;
    switch (reminder?.ProductNotification?.['productStatus']) {
      case ProductStatus.InStock: style = 'success'; break;
      case ProductStatus.LowStock: style = 'warning'; break;
      case ProductStatus.OutOfStock: style = 'error'; break;
      default: style = 'info'; break;
    }
    return style;
  }

}
