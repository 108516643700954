import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ProductCategoryDetailsService,
  Status,
} from '../../item-proxy/item-management/optimization';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subscription } from 'rxjs';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-product-category-table-list',
  templateUrl: './product-category-table-list.component.html',
  styleUrls: ['./product-category-table-list.component.scss'],
})
export class ProductCategoryTableListComponent implements OnInit {
  @Input() categoryTableData: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  loadTable: boolean = true;
  step: boolean = false;
  categoryTableDatas: any;

  categoryId: string;
  subscription$: Subscription[] = [];
  dtProductTableOptions: any = {
    dom: 'Bfrtip',
    responsive: true,
    paging: false,
    scrollX: true,
    scrollCollapse: true,
    scrollY: '400px',
    search: {
      smart: false,
    },
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [],
    buttons: [
      {
        extend: 'excel',
        text: 'Export', // Change the text to 'Export'
        filename: 'Product Category List',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
          // columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,16], // Specify the columns you want to export (0-indexed)
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
    ],
    columnDefs: [
      { targets: [0, 1, 2,  -1], className: 'dt-fixed-column' }, // Ad
    ],
  };
  constructor(
    private communicationService: CommunicationService,
    private productCategoryService: ProductCategoryDetailsService,
    private toastr: ToastrService,
    private datepipe : DatePipe
  ) {}

  ngOnInit() {
    this.dtProductTableOptions.buttons[0].filename =
    'Product Category List ' +  '-' + this.datepipe.transform(new Date(),'MM-dd-yyyy') ;
    this.calculateScrollY();
    window.addEventListener('resize', this.calculateScrollY);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.calculateScrollY);
    this.subscription$.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      // If dtInstance is not available yet, wait for a short time and try again
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  calculateScrollY = () => {
    const screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const availableSpacePercentage = 0.5; // Adjust the percentage as needed
    this.dtProductTableOptions.scrollY =
      screenHeight * availableSpacePercentage;
  };
  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');

      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();

      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  triggerToggle() {
    this.communicationService.triggerInsuranceAllowableToggle();
  }
  getInsAllowablesById(id: string) {
    this.communicationService.triggerProductCategoryGetMethodCall(id);
  }
  // statusToggleTrigger(toggle: MatSlideToggleChange, data: any) {
  //   this.communicationService.triggerStatusGetMethodCall(toggle, data);
  // }

  //Product Category Status change
  statusToggleTrigger(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Category will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!',
      }).then((result) => {
        if (result.value) {
          let productCategoryDetail = {
            accessories: data?.accessories ?? 0,
            productCategoryName: data?.productCategoryName,
            status: Status.Active,
            updatedDate: null,
          };

          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.productCategoryService
              .update(data?.id, productCategoryDetail)
              .subscribe(
                (response) => {
                  // Swal.fire({ title: 'Success', html: 'Activated Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
                  this.toastr.success('Activated Successfully', 'Success');
                  this.communicationService.triggerReloadProduct();
                  //this.getCategoryListV1();
                  data.status = 'Active';
                },
                (err) => {
                  this.communicationService.triggerReloadProduct();
                  const data: HttpErrorResponse = err;
                  event.source.checked = event.checked ? false : true;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          event.source.checked = event.checked ? false : true;
        }
      });
    } else if (event.checked == false) {
      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this Category  will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!',
      }).then((result) => {
        if (result.value) {
          let productCategoryDetails = {
            accessories: data?.accessories ?? 0,
            productCategoryName: data?.productCategoryName,
            status: Status.Inactive,
            updatedDate: null,
          };
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.productCategoryService
              .update(data?.id, productCategoryDetails)
              .subscribe(
                (response) => {
                  data.status = 'Inactive';
                  const index = this.categoryTableData.findIndex(
                    (obj) => obj.id === response?.id
                  );
                  if (index !== -1) {
                    this.categoryTableData[index] = response; //Replace the Object
                    //const objectToMove = this.categoryTableData.splice(index, 1)[0]; // Remove and get the object
                    //this.categoryTableData.unshift(objectToMove);
                  }
                  this.toastr.success('Deactivated Successfully', 'Success');
                  this.communicationService.triggerReloadProduct();
                },
                (err) => {
                  this.communicationService.triggerReloadProduct();
                  const data: HttpErrorResponse = err;
                  event.source.checked = event.checked ? false : true;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          event.source.checked = event.checked ? false : true;
        }
      });
    }
  }
}
