import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PatientSearchService } from 'projects/patient/src/app/patient-proxy/patient/patient-search.service';
import { PatientService } from 'projects/patient/src/app/patient-proxy/patient/patient.service';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';
import { PatientDTO } from '../admin-proxy/platform-app-management/rcm/patient-management/patient/dto/models';

import { DocumentUploadService, FileUploadService } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { ToastrService } from "ngx-toastr";
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';

@Component({
  selector: 'app-extraction-bot-modal',
  templateUrl: './extraction-bot-modal.component.html',
  styleUrls: ['./extraction-bot-modal.component.scss']
})
export class ExtractionBotModalComponent implements OnInit {
  ExPatientForm: FormGroup;
  location: string = "";
  drpLocationTypes: { id: string, organizationUnitName: string, tenantId: string }[] = [];
  tenantId: string = "";
  emailMask = emailMask ?? null;
  BirthdateMaxDate: Date | null = null;
  BirthdateMinDate: Date | null = null;
  organizationUnitId: string = "";
  SaveButtonDisable: boolean = false;
  organizationId : string=defaultGuid;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:
      {
        patientData: PatientDTO | null,
        batchId: string | null,
        fileId: string | null,
      },
    private matdialog: MatDialog,
    private branchService: OrganizationUnitService,
    private fb: FormBuilder,
    private textValidators: TextValidators,
    private patientService: PatientService,
    private patientSearchService: PatientSearchService,
    private fileUploadService: FileUploadService,
    private documentUploadService: DocumentUploadService,
    private toastr: ToastrService,
    public inboundDocUploadService: InboundDocumentService,


  ) { }

  ngOnInit(): void {
    this.organizationId = localStorage.getItem('organizationId') ?? defaultGuid;
    this.emailMask = emailMask;
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(this.BirthdateMinDate.getFullYear() - 120);
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    if (this.tenantId !== '' && this.tenantId !== undefined && this.tenantId !== null) {
      this.branchService.getOrganizationList(this.tenantId).subscribe(response => {
        response?.forEach(element => {
          this.drpLocationTypes.push({
            id: element?.id ?? "",
            organizationUnitName: element?.organizationUnitName ?? "",
            tenantId: element?.tenantId ?? "",
          });
        });
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }

    this.ExPatientForm = this.fb.group({
      txtChartId: new FormControl(""),
      drpLocation: new FormControl(null, Validators.required),
      txtFirstName: new FormControl("", [Validators.required, this.textValidators.isTextCheck]),
      txtLastName: new FormControl("", [Validators.required, this.textValidators.isTextCheck]),
      txtMiddleName: new FormControl("", [this.textValidators.isTextCheck]),
      txtBillingEmailAddress: new FormControl(""),
      txtBirthdate: new FormControl(null, [Validators.required]),
    })

    this.data !== null && this.data !== undefined && this.data.patientData && this.getPatientDetails(this.data?.patientData);
    this.tenantId !== "" && this.tenantId !== undefined && this.tenantId !== null && this.getBranchList();

  }
  //! Get Branch List Details

  getBranchList() {
    this.drpLocationTypes = [];
    this.branchService.getOrganizationList(this.tenantId).subscribe(response => {
      this.drpLocationTypes = [];

      response?.forEach(element => {
        this.drpLocationTypes?.push({ id: element?.id ?? "", organizationUnitName: element?.organizationUnitName ?? "", tenantId: element?.tenantId ?? ""});
      });
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }
  //! Patch patient extratced details
  getPatientDetails(value: PatientDTO) {
    if(this.organizationId==='null'||this.organizationId===null||this.organizationId===undefined){
      this.organizationId=defaultGuid;
    }
    const exractedPatient = value.personals;
    this.ExPatientForm.patchValue({
      txtChartId: value.chartId,
      drpLocation: exractedPatient?.locationType,
      txtFirstName: exractedPatient?.firstName,
      txtLastName: exractedPatient?.lastName,
      txtMiddleName: exractedPatient?.middleName,
      txtBillingEmailAddress: exractedPatient?.emailId,
      txtBirthdate: exractedPatient?.dateOfBirth === null ? "" : exractedPatient?.dateOfBirth,
    });
    if (value?.chartId === null || value?.chartId === "" && value) {
      this.inboundDocUploadService.generateChartId(this.organizationId).subscribe(response => {
        this.ExPatientForm.patchValue({ txtChartId: response })
      })
    }
  }


  //! check for  Atleast One Branch
  checkForBranchexisitAtleastOne(): boolean {
    if (this.drpLocationTypes.length === 0) {
      const messageToDisplay = "Please Add Atleast One Branch in Admin Settings"
      Swal.fire({
        icon: 'info',
        text: messageToDisplay,
      });
      return true;
    }
    return false;
  }


  //! Patient Personal Save Funtion
  savePersonal() {
    if (this.checkForBranchexisitAtleastOne()) {
      return
    };
    const selectedPatient = this.data?.patientData;
    const selectedPatientpersonals = this.data?.patientData?.personals;
    if (this.data && this.data?.patientData?.personals && this.data?.batchId && this.data?.fileId) {
      this.SaveButtonDisable = true

      const personalDetails: PatientDTO = {
        personals: {
          locationType: this.ExPatientForm.value.drpLocation,
          firstName: this.ExPatientForm.value.txtFirstName,
          lastName: this.ExPatientForm.value.txtLastName,
          middleName: this.ExPatientForm.value.txtMiddleName,
          dateOfBirth: this.ExPatientForm?.get("txtBirthdate")?.value,
          emailId: this.ExPatientForm.value.txtBillingEmailAddress.toLowerCase(),
          suffix: selectedPatientpersonals?.suffix,
          ssn: selectedPatientpersonals?.ssn,
          homePhoneNo: selectedPatientpersonals?.homePhoneNo,
          workPhoneNo: selectedPatientpersonals?.workPhoneNo,
          accountNumber: selectedPatientpersonals?.accountNumber,
          customerType: selectedPatientpersonals?.customerType,
          priorSystemKeyFacility: selectedPatientpersonals?.priorSystemKeyFacility,
          facility: selectedPatientpersonals?.facility,
          alternateIdType: selectedPatientpersonals?.alternateIdType,
          holdAccount: selectedPatientpersonals?.holdAccount ?? 0,
          holdBillingStatements: selectedPatientpersonals?.holdBillingStatements ?? 0,
          hippaSignatureOnFile: selectedPatientpersonals?.hippaSignatureOnFile ?? 0,
          discountPercentage: selectedPatientpersonals?.discountPercentage ?? 0,
          taxZone: selectedPatientpersonals?.taxZone,
          branchOffice: selectedPatientpersonals?.branchOffice,
          accountGroupType: selectedPatientpersonals?.accountGroupType,
          ptSecurityGroupType: selectedPatientpersonals?.ptSecurityGroupType,
          user1: selectedPatientpersonals?.user1,
          user2: selectedPatientpersonals?.user2,
          user3: selectedPatientpersonals?.user3,
          user4: selectedPatientpersonals?.user4,
          placeOfService: selectedPatientpersonals?.placeOfService,
          dateOfAdmission: selectedPatientpersonals?.dateOfAdmission,
          dateOfDischarge: selectedPatientpersonals?.dateOfDischarge,
          auditTrialCreatedBy: selectedPatientpersonals?.auditTrialCreatedBy,
          auditTrialDateCreated: selectedPatientpersonals?.auditTrialDateCreated,
          colorCodingId: selectedPatientpersonals?.colorCodingId,
          catogeryId: selectedPatientpersonals?.catogeryId,
          providerId: selectedPatientpersonals?.providerId,
          patientBalance: selectedPatientpersonals?.patientBalance ?? 0,
          insurnceBalance: selectedPatientpersonals?.insurnceBalance ?? 0,
          totalBalance: selectedPatientpersonals?.totalBalance ?? 0,
          // patientBalance: this.ExPatientForm?.get("txtPatientBalance").value === "" ? 0 : +this.ExPatientForm?.get("txtPatientBalance").value,
          // insurnceBalance: this.ExPatientForm?.get("txtInsuranceBalance").value === "" ? 0 : +this.ExPatientForm?.get("txtInsuranceBalance").value,
          // totalBalance: this.ExPatientForm?.get("txtTotalBalance").value === "" ? 0 : +this.ExPatientForm?.get("txtTotalBalance").value,
          patientAlerts: selectedPatientpersonals?.patientAlerts,
          statementTo: selectedPatientpersonals?.statementTo,
          isCollection: selectedPatientpersonals?.isCollection ?? 0,
          isDoNotCall: selectedPatientpersonals?.isDoNotCall ?? 0,
          isHoldClaims: selectedPatientpersonals?.isHoldClaims ?? 0,
          holdNotes: selectedPatientpersonals?.holdNotes,
          holdReason: selectedPatientpersonals?.holdReason,
          autoDunningId: selectedPatientpersonals?.autoDunningId,
          isAutoDunning: selectedPatientpersonals?.isAutoDunning ?? 0,
          isRecieveBill: selectedPatientpersonals?.isRecieveBill ?? 0,
          isPatientInterest: selectedPatientpersonals?.isPatientInterest ?? 0,
          fullName: selectedPatientpersonals?.fullName,
          isCashPayment: selectedPatientpersonals?.isCashPayment ?? 0,
          isStat: selectedPatientpersonals?.isStat ?? 0,
          gender: selectedPatientpersonals?.gender,
          maritalStatus: selectedPatientpersonals?.maritalStatus,
          ethinicity: selectedPatientpersonals?.ethinicity,
          race: selectedPatientpersonals?.race,
        },
        addresses: selectedPatient?.addresses ?? [],
        contacts: selectedPatient?.contacts ?? [],
        insurances: selectedPatient?.insurances ?? [],
        policies: selectedPatient?.policies,
        notes: selectedPatient?.notes,
        clinicals: selectedPatient?.clinicals,
        organizationUnitId: selectedPatient?.organizationUnitId,
        defaultPatientId: selectedPatient?.defaultPatientId,
        insuranceVerifications: selectedPatient?.insuranceVerifications,
        authorizations: selectedPatient?.authorizations,
        isAutoIntake: selectedPatient?.isAutoIntake ?? 0,
        autoIntakeDocumentDetails: selectedPatient?.autoIntakeDocumentDetails ?? [],
        chartId: (this.ExPatientForm.value.txtChartId)?.toLocaleUpperCase(),
        sleepStudyReport: selectedPatient?.sleepStudyReport,
        complainceReport: selectedPatient?.complainceReport,
        patientDocuments: selectedPatient?.patientDocuments,
        billingDetails: selectedPatient?.billingDetails,
        patientLock: selectedPatient?.patientLock,
        isRescheduled:selectedPatient?.isRescheduled,
        batchId: selectedPatient?.batchId,
        // fileId: selectedPatient?.fileId,
      }
      const batchId: string = this.data?.batchId;
      const fileId: string = this.data?.fileId;
      this.documentUploadService.updateExtractedDetailsOnFileByBatchIdAndFileIdAndExtractedDataInput(batchId, fileId, personalDetails).subscribe(() => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true })
        this.toastr.success('Saved Successfully','Success')
        this.matdialog.closeAll();
        this.SaveButtonDisable = false;
      }, error => {
        this.SaveButtonDisable = false;
        const data: HttpErrorResponse = error;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
    }


  }






  //! clear dropdown
  clearLocation() {
    this.location = '';
    this.ExPatientForm.patchValue({ drpLocation: '' });
  }
}
