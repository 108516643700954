import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
// import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Router, UrlTree } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { WorkInProgressService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress';
import {
  clsAuthSearchDTO,
  WIPList,
} from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/work-in-progress/dto';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { forkJoin, Observable, of, pipe, Subscription } from 'rxjs';
// import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import {
  OrderMasterDropdownService,
  PatientMasterDropdownService,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import {
  PatientMasterDropdownDTO,
  AuthplanTypesDTO,
  AuthStatusDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import {
  OrderMasterDropdownDTO,
  PlanTypeDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/order';
import { AddressService, PatientAuthorizationService } from '../patient-proxy/patient-optimization';
import { PayerDetailService } from '../patient-proxy/patient/payer-detail.service';
import { OrderDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns';
import { patientAuthSearchDTO } from '../patient-proxy/patient-optimization/dto';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { InsuranceType } from '../patient-proxy/patient/enumeration-data';
import { Title } from '@angular/platform-browser';
import { TableService } from 'projects/shared/src/app/table.service';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';

@Component({
  selector: 'app-patient-authorization-list',
  templateUrl: './patient-authorization-list.component.html',
  styleUrls: ['./patient-authorization-list.component.scss'],
})
export class PatientAuthorizationListComponent implements OnInit {
  activeTab: string = 'NewSetup';
  payorFilterValue: string[] = [];
  typeOfPlanFilterValue: string[] = [];
  authStatusFilterValue: string[] = [];
  insuranceTypeFilterValue: string[] = [];
  tableData: any[] = [];
  maxSelections = 5;
  selectedNewSetupAuthValidation: boolean = true;
  selectedSuppliesAuthValidation: boolean = true;
  checkedCount: number = 0;
  typeOfPlan: string = '';
  selectPayorList: any;
  zirmedPayor: string = '';
  InsuranceType: InsuranceType;
  drpAuthStatus: AuthStatusDTO[] = [];
  drpPlanType: PlanTypeDTO[] = [];
  authValueChanges: Observable<patientAuthSearchDTO>;
  authValueChangesV1: Observable<clsAuthSearchDTO>;
  public filteredSelectPayers: Observable<string[]> | undefined;
  public filteredSelectPayersSupplies: Observable<string[]> | undefined;
  public filteredPlanTypes: Observable<any[]> | undefined;
  public filteredAuthStatus: Observable<any[]> | undefined;
  newSetupSearchForm: FormGroup;
  newSetupFilterForm: FormGroup;
  isLoadingNewSeup: boolean = false;
  newSetupTableData: WIPList[] = [];
  suppliesTableData: WIPList[] = [];
  dataSourceNewSetup: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sortNewSetup: MatSort = new MatSort();
  // @ViewChild(MatPaginator, { static: false }) paginatorNewSetup: MatPaginator;
  suppliesSearchForm: FormGroup;
  suppliesFilterForm: FormGroup;
  isLoadingSupplies: boolean = false;
  dataSourceSupplies: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sortSupplies: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) PaginatorSupplies: MatPaginator;
  // @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginatorNewSetup') paginatorNewSetup: MatPaginator;
  selection: SelectionModel<any> = new SelectionModel<any>(false, [], true);
  @ViewChild('allPayorSelected') private allPayorSelected: MatOption;
  @ViewChild('payorSelect') payorSelect: MatSelect;
  @ViewChild('allPlanTypeSelected') private allPlanTypeSelected: MatOption;
  @ViewChild('planTypeSelect') planTypeSelect: MatSelect;
  @ViewChild('allAuthStatusSelected') private allAuthStatusSelected: MatOption;
  @ViewChild('authStatusSelect') authStatusSelect: MatSelect;
  @ViewChild('allInsuranceTypeSelected')
  private allInsuranceTypeSelected: MatOption;
  @ViewChild('insuranceTypeSelect') insuranceTypeSelect: MatSelect;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  branchValue : string[] =[];
  public brnachList: Observable<Branch[]> | undefined;
  public drpBranch: Branch[] = [];
  tenantId: string = "";
  branchForm: FormGroup;
  step: boolean = false;
  organizationUnitName: string;

  planNameFilterValue: AuthplanTypesDTO[] = [];
  subscription$: Subscription[] = [];
  checkedValues: any[] = [];
  arrDisplayedColumnsNewSetup: string[] = [
    'action',
    'wipId',
    'defaultPatientId',
    'patientName',
    'planName',
    'planType',
    'branch',
    'insuranceType',
    'csr',
    'agent',
    'wipStatus',
    'authStatus',
    'verificationCompletedDate',
    //'agingDays',
    'Stat',
  ];
  public arrDisplayedColumnsSupplies: string[] = [
    'action',
    'wipId',
    'defaultPatientId',
    'defaultSaleOrderId',
    'patientName',
    'itemName',
    'planName',
    'planType',
    'sbranch',
    'insuranceType',
    'csr',
    'agent',
    'wipStatus',
    'authStatus',
    'verificationCompletedDate',
    //'agingDays',
    'Stat',
  ];
  isUniq: boolean=true;
  payerDetails: Subscription;

  ngOnInit(): void {
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.title.setTitle('Qsecure | Authorization List');
    this.intializeForms();
    if (this.activeTab == 'NewSetup') {
      this.searchAuthNewSetup();
    }

    this.branchForm = this.fb.group({
      drpBranch : new FormControl(''),
      txtBranch : new FormControl(''),
    });

    this.getBranchList();
  }
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private title: Title,
    public workInProgressService: WorkInProgressService,
    private patientDropdownService: PatientMasterDropdownService,
    public authService: PatientAuthorizationService,
    private orderDropdownService: OrderMasterDropdownService,
    private payerDetailService: PayerDetailService,
    private toastr: ToastrService,
    private table: TableService,
    public PatientAddressService: AddressService,
    private renderer: Renderer2,
    private dateValidator: DateValidator,
    private branchService : OrganizationUnitService
  ) {}
  intializeForms() {
    this.newSetupFilterForm = this.fb.group({
      drpselectPayor: new FormControl(''),
      selectPayor: new FormControl(''),
      drpAuth: new FormControl(''),
      txtFilterAuthStatus: new FormControl(''),
      drpTypeOfPlan: new FormControl(''),
      txtFilterPlanType: new FormControl(''),
      drpInsuranceType: new FormControl(''),
      txtInsuranceType: new FormControl(''),

      // sltPlanType: new FormControl(''),
      // sltAuthStatus: new FormControl('')
    });
    this.newSetupSearchForm = this.fb.group({
      defaultPatientId: new FormControl(''),
      patientName: new FormControl(''),
      wipId: new FormControl(''),
      planType: new FormControl(''),
      planName: new FormControl(''),
      insuranceType: new FormControl(''),
      csr: new FormControl(''),
      agent: new FormControl(''),
      authStatus: new FormControl(''),
      verificationCompletedDate: new FormControl(
        '',
        this.dateValidator.dateVaidator
      ),
      wipStatus: new FormControl(''),
      //documentId: new FormControl(''),
      //agingDays: new FormControl(''),
      stat: new FormControl(''),
    });
    this.suppliesSearchForm = this.fb.group({
      defaultPatientId: new FormControl(''),
      defaultSaleOrderId: new FormControl(''),
      authType: new FormControl(''),
      patientName: new FormControl(''),
      itemName: new FormControl(''),
      authId: new FormControl(''),
      authStatus: new FormControl(''),
      wipId: new FormControl(''),
      items: new FormControl(''),
      status: new FormControl(''),
      csr: new FormControl(''),
      agent: new FormControl(''),
      verificationCompletedDate: new FormControl(
        '',
        this.dateValidator.dateVaidator
      ),
      wipStatus: new FormControl(''),
      insuranceType: new FormControl(''),
      planName: new FormControl(''),
      planType: new FormControl(''),
      orderIds: new FormControl(''),
    });
    this.suppliesFilterForm = this.fb.group({
      drpInsuranceType: new FormControl(''),
      txtInsuranceType: new FormControl(''),
      drpTypeOfPlan: new FormControl(''),
      drpselectPayor: new FormControl(''),
      selectPayor: new FormControl(''),
      drpAuth: new FormControl(''),
      txtFilterAuthStatus: new FormControl(''),
      txtFilterPlanType: new FormControl(''),
      planName: new FormControl(''),
    });
    this.getDropdown();

    // this.authorizationSearch();
  }
  changeTab(tab: string) {
    this.activeTab = tab;
    if (this.activeTab == 'NewSetup') {
      this.searchAuthNewSetup();
    } else if (this.activeTab == 'Supplies') {
      this.searchAuthSupplies();
    }
  }
  //Load Dropdowns
  getDropdown() {
    //Plan Types Dropdown
    const orderDropdownInput: OrderDropdowns[] = [OrderDropdowns.PlanTypes];
    const orderDropdown =
      this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput);
    const combinedObs = forkJoin([orderDropdown]);
    combinedObs.subscribe((response) => {
      const responseData: OrderMasterDropdownDTO = response?.[0];
      this.drpPlanType = responseData?.planTypes;

      this.filteredPlanTypes = this.newSetupFilterForm
        .get('txtFilterPlanType')
        ?.valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.drpPlanType?.filter((option) =>
              option?.planType
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
    });

    //Plan Names Dropdown
    const selectedPayor = 1; //Pverify

    this.payerDetailService
      .getdrpPayerByIModeV1(selectedPayor,'','')
      .subscribe((response) => {
        this.selectPayorList = response;
        this.selectPayorList = response.map((x) => {
          x['payerShortCodeList'] =
          ((x?.payerCode===null||x?.payerCode==="")?'-' :x?.payerCode)+ ' ' + '/' + ' ' + x?.payerName+ ' ' +'/' + ' '
          + ((x?.code===null||x?.code==="")?'-':x?.code);
          return x;
        });
        this.filteredSelectPayers = this.newSetupFilterForm

          .get('selectPayor')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.selectPayorList?.filter((option) =>
                option?.payerShortCodeList
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    // suppiles filter
    this.payerDetailService
    .getdrpPayerByIModeV1(selectedPayor,'','')
      .subscribe((response) => {
        this.selectPayorList = response;
        this.selectPayorList = response.map((x) => {
          x['payerShortCodeList'] =
          ((x?.payerCode===null||x?.payerCode==="")?'-' :x?.payerCode)+ ' ' + '/' + ' ' + x?.payerName+ ' ' +'/' + ' '
          + ((x?.code===null||x?.code==="")?'-':x?.code);
          return x;
        });
        this.filteredSelectPayersSupplies = this.suppliesFilterForm
          .get('selectPayor')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.selectPayorList?.filter((option) =>
                option?.payerShortCodeList
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    //Auth Status Dropdown
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.AuthStatus,
    ];
    const patientDropdownSubscription = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: PatientMasterDropdownDTO = stateResponse;
          this.drpAuthStatus = response?.lstAuthStatus ?? [];
          this.filteredAuthStatus = this.newSetupFilterForm
            .get('txtFilterAuthStatus')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpAuthStatus?.filter((option) =>
                  option?.authStatus
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientDropdownSubscription);
  }
  payerSearch(value){
    if (this.payerDetails) {
      this.payerDetails.unsubscribe();
    }
    const selectedPayor = 1;
    this.payerDetailService
      .getdrpPayerByIModeV1(selectedPayor,value,'')
      .subscribe((response) => {
        this.selectPayorList = response;
        this.selectPayorList = response.map((x) => {
          x['payerShortCodeList'] =
          ((x?.payerCode===null||x?.payerCode==="")?'-' :x?.payerCode)+ ' ' + '/' + ' ' + x?.payerName+' ' + '/' + ' '
          + ((x?.code===null||x?.code==="")?'-':x?.code);
          return x;
        });
        this.filteredSelectPayers = this.newSetupFilterForm

          .get('selectPayor')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.selectPayorList?.filter((option) =>
                option?.payerShortCodeList
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
  }

  //Search New Setup Table
  searchAuthNewSetup() {
    this.isLoadingNewSeup = true;
    const valueChanges = this.newSetupSearchForm.valueChanges
      .pipe(
        startWith({
          wipId: '',
          defaultPatientId: '',
          patientName: '',
          planType: '',
          planName: '',
          insuranceType: '',
          csr: '',
          agent: '',
          wipStatus: '',
          authStatus: '',
          isSupplies: 0,
          verificationCompletedDate: '',
        }),
        tap((x) => {
          this.isLoadingNewSeup = true;
          this.setTableDataNewSetup([]);
        }),
        filter((e) => e && this.newSetupSearchForm?.valid),
        debounceTime(300),
        map((response) => {

          this.isLoadingNewSeup = true;

          let status: any = null;
          switch (response?.wipStatus) {
            case 'Yet To Start':
              status = 'yetToStart';
              break;
            case 'Hold':
              status = 'hold';
              break;
            case 'In Process':
              status = 'inProcess';
              break;
            case 'Completed':
              status = 'completed';
              break;
            case 'New Setup Assigned':
              status = 'NewSetupAssigned';
              break;
            case 'New Setup Pending':
              status = 'NewSetupPending';
              break;
            case 'New Setup InProgress':
              status = 'NewSetupInprogress';
              break;
            case 'New Setup Completed':
              status = 'NewSetupCompleted';
              break;

            case 'Verification Assigned':
              status = 'VerificationAssigned';
              break;
            case 'Verification Pending':
              status = 'VerificationPending';
              break;
            case 'Verification InProgress':
              status = 'VerificationInprogress';
              break;

            case 'Verification Completed':
              status = 'VerificationCompleted';
              break;

            case ' Authorization Assigned':
              status = 'AuthorizationAssigned';
              break;

            case 'Authorization Pending':
              status = 'AuthorizationPending';
              break;

            case 'Authorization InProgress':
              status = 'AuthorizationInprogress';
              break;
            case 'Authorization Completed':
              status = 'AuthorizationCompleted';
              break;
            default:
              status = null;
          }

          let insuranceType: any = null;
          switch (response?.insuranceType.toLowerCase()) {
            case 'primary':
              insuranceType = 'Primary';
              break;
            case 'secondary':
              insuranceType = 'Secondary';
              break;
            case 'tertiary':
              insuranceType = 'Tertiary';
              break;
            default:
              insuranceType = null;
          }
          const sValue: clsAuthSearchDTO = {
            defaultWipId: this.newSetupSearchForm.value.wipId ?? '',
            defaultPatientId:
              this.newSetupSearchForm.value.defaultPatientId ?? '',
            patientName: this.newSetupSearchForm.value.patientName ?? '',
            planType: this.newSetupSearchForm.value.planType ?? '',
            planName: this.newSetupSearchForm.value.planName ?? '',
            insuranceType: insuranceType,
            csrName: this.newSetupSearchForm.value.csr ?? '',
            agentName: this.newSetupSearchForm.value.agent ?? '',
            wipStatus: status,
            authStatusName: this.newSetupSearchForm.value.authStatus ?? '',
            verificationDate:
              this.newSetupSearchForm.value.verificationCompletedDate ?? '',
            isSupplies: 0,
            authStatus:
              this.newSetupFilterForm.value.drpAuth == null ||
              this.newSetupFilterForm.value.drpAuth == ''
                ? []
                : this.newSetupFilterForm.value.drpAuth.filter(
                    (a) => a != 0 && a != undefined
                  ),
            lstPlans:
              this.newSetupFilterForm.value.drpselectPayor == null ||
              this.newSetupFilterForm.value.drpselectPayor == ''
                ? []
                : this.newSetupFilterForm.value.drpselectPayor.filter(
                    (a) => a != 0 && a != undefined
                  ),

            lstPlanType:
              this.newSetupFilterForm.value.drpTypeOfPlan == null ||
              this.newSetupFilterForm.value.drpTypeOfPlan == ''
                ? []
                : this.newSetupFilterForm.value.drpTypeOfPlan.filter(
                    (a) => a != 0 && a != undefined
                  ),

            lstInsType:
              this.newSetupFilterForm.value.drpInsuranceType == null ||
              this.newSetupFilterForm.value.drpInsuranceType == ''
                ? ['primary', 'secondary', 'tertiary']
                : this.newSetupFilterForm.value.drpInsuranceType.filter(
                    (a) => a != 0 && a != undefined
                  ),
          };


          return sValue;
        }),
        switchMap((sValue) =>
          this.workInProgressService.getAuthNewSetup(sValue)
        )
      )
      .subscribe(
        (response) => {
          this.isLoadingNewSeup = false;
          this.setTableDataNewSetup(response);
          this.validateNewSetupAllotedAuth(response);
        },
        (err) => {
          this.isLoadingNewSeup = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'error',
            text: data?.error?.error?.message,
          });
        }
      );

    this.subscription$.push(valueChanges);
  }
  validateNewSetupAllotedAuth(data: any) {
    const value = data.find((a) => a.isLocked == 2);

    this.selectedNewSetupAuthValidation =
      value == undefined || value == null ? true : false;
  }
  validateSuppliesAllotedAuth(data: any) {
    const value = data.find((a) => a.isLocked == 2);

    this.selectedSuppliesAuthValidation =
      value == undefined || value == null ? true : false;
  }
  //Search supplies Table
  searchAuthSupplies() {
    this.isLoadingSupplies = true;
    this.setTableDatas([]);
    const valueChanges = this.suppliesSearchForm.valueChanges
      .pipe(
        startWith({
          wipId: '',
          defaultPatientId: '',
          patientName: '',
          itemName: '',
          planType: '',
          planName: '',
          insuranceType: '',
          csr: '',
          agent: '',
          wipStatus: '',
          authStatus: '',
          isSupplies: 1,
          defaultSaleOrderId: '',
          verificationCompletedDate: '',
        }),
        tap((x) => {
          this.isLoadingSupplies = true;
          this.setTableDatas([]);
        }),
        filter((e) => e && this.suppliesSearchForm?.valid),
        debounceTime(300),
        map((response) => {
          this.isLoadingSupplies = true;
          let status: any = null;
          switch (response?.wipStatus) {
            case 'Yet To Start':
              status = 'yetToStart';
              break;
            case 'Hold':
              status = 'hold';
              break;
            case 'In Process':
              status = 'inProcess';
              break;
            case 'Completed':
              status = 'completed';
              break;
            case 'New Setup Assigned':
              status = 'NewSetupAssigned';
              break;
            case 'New Setup Pending':
              status = 'NewSetupPending';
              break;
            case 'New Setup InProgress':
              status = 'NewSetupInprogress';
              break;
            case 'New Setup Completed':
              status = 'NewSetupCompleted';
              break;

            case 'Verification Assigned':
              status = 'VerificationAssigned';
              break;
            case 'Verification Pending':
              status = 'VerificationPending';
              break;
            case 'Verification InProgress':
              status = 'VerificationInprogress';
              break;

            case 'Verification Completed':
              status = 'VerificationCompleted';
              break;

            case ' Authorization Assigned':
              status = 'AuthorizationAssigned';
              break;

            case 'Authorization Pending':
              status = 'AuthorizationPending';
              break;

            case 'Authorization InProgress':
              status = 'AuthorizationInprogress';
              break;
            case 'Authorization Completed':
              status = 'AuthorizationCompleted';
              break;
            default:
              status = null;
          }

          let insuranceType: any = null;
          switch (response?.insuranceType.toLowerCase()) {
            case 'primary':
              insuranceType = 'Primary';
              break;
            case 'secondary':
              insuranceType = 'Secondary';
              break;
            case 'tertiary':
              insuranceType = 'Tertiary';
              break;
            default:
              insuranceType = null;
          }

          const sValue: clsAuthSearchDTO = {
            defaultWipId: this.suppliesSearchForm.value.wipId ?? '',
            defaultPatientId:
              this.suppliesSearchForm.value.defaultPatientId ?? '',
            defaultSaleOrderId:
              this.suppliesSearchForm.value.defaultSaleOrderId ?? '',
            // itemName:
            // this.suppliesSearchForm.value.itemName == null ||
            // this.suppliesSearchForm.value.itemName == ''
            //   ? []
            //   : this.suppliesSearchForm.value.itemName.filter(
            //       (a) => a != 0 && a != undefined
            //     ),
            itemName: this.suppliesSearchForm.value.itemName ?? '',
            patientName: this.suppliesSearchForm.value.patientName ?? '',
            planType: this.suppliesSearchForm.value.planType ?? '',
            planName: this.suppliesSearchForm.value.planName ?? '',
            insuranceType: insuranceType,
            csrName: this.suppliesSearchForm.value.csr ?? '',
            agentName: this.suppliesSearchForm.value.agent ?? '',
            wipStatus: status,
            authStatusName: this.suppliesSearchForm.value.authStatus ?? '',
            verificationDate:
              this.suppliesSearchForm.value.verificationCompletedDate ?? '',
            isSupplies: 1,
            authStatus:
              this.suppliesSearchForm.value.drpAuth == null ||
              this.suppliesSearchForm.value.drpAuth == ''
                ? []
                : this.suppliesSearchForm.value.drpAuth.filter(
                    (a) => a != 0 && a != undefined
                  ),
            lstPlans:
              this.suppliesSearchForm.value.drpselectPayor == null ||
              this.suppliesSearchForm.value.drpselectPayor == ''
                ? []
                : this.suppliesSearchForm.value.drpselectPayor.filter(
                    (a) => a != 0 && a != undefined
                  ),

            lstPlanType:
              this.suppliesSearchForm.value.drpTypeOfPlan == null ||
              this.suppliesSearchForm.value.drpTypeOfPlan == ''
                ? []
                : this.suppliesSearchForm.value.drpTypeOfPlan.filter(
                    (a) => a != 0 && a != undefined
                  ),

            lstInsType:
              this.suppliesSearchForm.value.drpInsuranceType == null ||
              this.suppliesSearchForm.value.drpInsuranceType == ''
                ? ['primary', 'secondary', 'tertiary']
                : this.suppliesSearchForm.value.drpInsuranceType.filter(
                    (a) => a != 0 && a != undefined
                  ),
          };

          return sValue;
        }),
        switchMap((sValue) =>
          this.workInProgressService.getAuthNewSetup(sValue)
        )
      )
      .subscribe(
        (response) => {
          this.isLoadingSupplies = false;

          this.setTableDatas(response);
          this.validateSuppliesAllotedAuth(response);
        },
        (err) => {
          this.isLoadingSupplies = false;
          const data: HttpErrorResponse = err;

          Swal.fire({
            icon: 'error',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  //Filter supplies Table
  filterAuthSupplies() {
    this.isLoadingSupplies = true;
    this.setTableDatas([]);
    const sValue: clsAuthSearchDTO = {
      defaultWipId: '',
      defaultPatientId: '',
      patientName: '',
      planType: '',
      planName: '',
      csrName: '',
      agentName: '',
      wipStatus: null,
      authStatusName: '',
      isSupplies: 1,
      verificationDate: '',
      authStatus:
        this.suppliesFilterForm.value.drpAuth == null ||
        this.suppliesFilterForm.value.drpAuth == ''
          ? []
          : this.suppliesFilterForm.value.drpAuth.filter(
              (a) => a != 0 && a != undefined
            ),
      lstPlans:
        this.suppliesFilterForm.value.drpselectPayor == null ||
        this.suppliesFilterForm.value.drpselectPayor == ''
          ? []
          : this.suppliesFilterForm.value.drpselectPayor.filter(
              (a) => a != 0 && a != undefined
            ),
      lstPlanType:
        this.suppliesFilterForm.value.drpTypeOfPlan == null ||
        this.suppliesFilterForm.value.drpTypeOfPlan == ''
          ? []
          : this.suppliesFilterForm.value.drpTypeOfPlan.filter(
              (a) => a != 0 && a != undefined
            ),
      lstInsType:
        this.suppliesFilterForm.value.drpInsuranceType == null ||
        this.suppliesFilterForm.value.drpInsuranceType == ''
          ? ['primary', 'secondary', 'tertiary']
          : this.suppliesFilterForm.value.drpInsuranceType.filter(
              (a) => a != 0 && a != undefined
            ),
    };
    this.isLoadingSupplies = true;
    const filteredAuthList = this.workInProgressService
      .getAuthNewSetup(sValue)
      .subscribe(
        (response) => {
          this.isLoadingSupplies = false;
          this.setTableDatas(response);
          this.validateSuppliesAllotedAuth(response);
        },
        (err) => {
          this.isLoadingSupplies = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'error',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  //Filter New Setup Table
  filterAuthNewSetup() {
    this.isLoadingNewSeup = true;
    const sValue: clsAuthSearchDTO = {
      defaultWipId: '',
      defaultPatientId: '',
      patientName: '',
      planType: '',
      planName: '',
      csrName: '',
      agentName: '',
      wipStatus: null,
      authStatusName: '',
      verificationDate: '',
      isSupplies: 0,
      authStatus:
        this.newSetupFilterForm.value.drpAuth == null ||
        this.newSetupFilterForm.value.drpAuth == ''
          ? []
          : this.newSetupFilterForm.value.drpAuth.filter(
              (a) => a != 0 && a != undefined
            ),
      lstPlans:
        this.newSetupFilterForm.value.drpselectPayor == null ||
        this.newSetupFilterForm.value.drpselectPayor == ''
          ? []
          : this.newSetupFilterForm.value.drpselectPayor.filter(
              (a) => a != 0 && a != undefined
            ),
      lstPlanType:
        this.newSetupFilterForm.value.drpTypeOfPlan == null ||
        this.newSetupFilterForm.value.drpTypeOfPlan == ''
          ? []
          : this.newSetupFilterForm.value.drpTypeOfPlan.filter(
              (a) => a != 0 && a != undefined
            ),
      lstInsType:
        this.newSetupFilterForm.value.drpInsuranceType == null ||
        this.newSetupFilterForm.value.drpInsuranceType == ''
          ? ['primary', 'secondary', 'tertiary']
          : this.newSetupFilterForm.value.drpInsuranceType.filter(
              (a) => a != 0 && a != undefined
            ),
    };
    this.isLoadingNewSeup = true;
    const filteredAuthList = this.workInProgressService
      .getAuthNewSetup(sValue)
      .subscribe(
        (response) => {
          this.isLoadingNewSeup = false;
          this.setTableDataNewSetup(response);
          this.validateNewSetupAllotedAuth(response);
        },
        (err) => {
          this.isLoadingNewSeup = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'error',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  //New Setup Table Config
  setTableDataNewSetup(tableData: any[] = []) {
    this.newSetupTableData = tableData;
    this.dataSourceNewSetup = new MatTableDataSource(tableData);
    this.sortNewSetup.disableClear = true;
    this.dataSourceNewSetup.sort = this.sortNewSetup;
    this.dataSourceNewSetup.paginator = this.paginatorNewSetup;
  }

  //Select All Plan names
  toggleAllPlanNameSelection() {
    if (this.allPayorSelected.selected) {
      this.payorSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.payorSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }
  //Select All Plan Types
  toggleAllPlanTypesSelection() {
    if (this.allPlanTypeSelected.selected) {
      this.planTypeSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.planTypeSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }
  //Select All Auth Status
  toggleAllAuthStatusSelection() {
    if (this.allAuthStatusSelected.selected) {
      this.authStatusSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.authStatusSelect.options.forEach((item: MatOption) =>
        item.deselect()
      );
    }
  }
  //Select All Insurance Types
  toggleAllInsuranceTypes() {
    if (this.allInsuranceTypeSelected.selected) {
      this.insuranceTypeSelect.options.forEach((item: MatOption) =>
        item.select()
      );
    } else {
      this.insuranceTypeSelect.options.forEach((item: MatOption) =>
        item.deselect()
      );
    }
  }
  //Update Auth Lock
  updatePatientLock(event: any, wipId: string) {
    //Maximum Limit Validation for New Setup
    if (
      this.activeTab == 'NewSetup' &&
      this.newSetupTableData.filter((a) => a.isLocked === 2).length >=
        this.maxSelections &&
      event?.checked
    ) {
      this.toastr.error('Maximum Limit Reached!');
      this.searchAuthNewSetup();
    }
    //Maximum Limit Validation for Supplies
    else if (
      this.activeTab == 'Supplies' &&
      this.suppliesTableData.filter((a) => a.isLocked === 2).length >=
        this.maxSelections &&
      event?.checked
    ) {
      this.toastr.error('Maximum Limit Reached!');
      this.searchAuthSupplies();
    } else {
      this.workInProgressService.updateAuthNewSetupWipLock(wipId).subscribe(
        (response) => {
          this.toastr.success('Updated Successfully!');
          //New Setup
          if (this.activeTab == 'NewSetup') {
            let tableValue = this.newSetupTableData?.find(
              (a) => a.id == response.id
            );
            tableValue.wipStatus = response.wipStatus;
            tableValue.authStatusName = response.authStatusName;
            tableValue.processCompletedDate = response.processCompletedDate;
            tableValue.agentName = response.agentName;
            tableValue.isLocked = response.isLocked;
            this.newSetupTableData.sort(this.customSort);
            this.setTableDataNewSetup(this.newSetupTableData);
            this.validateNewSetupAllotedAuth(this.newSetupTableData);
          }
          //Supplies
          else if (this.activeTab == 'Supplies') {
            let tableValue = this.suppliesTableData?.find(
              (a) => a.id == response.id
            );
            tableValue.wipStatus = response.wipStatus;
            tableValue.authStatusName = response.authStatusName;
            tableValue.processCompletedDate = response.processCompletedDate;
            tableValue.agentName = response.agentName;
            tableValue.isLocked = response.isLocked;
            this.suppliesTableData.sort(this.customSort);
            this.setTableDatas(this.suppliesTableData);

            this.validateSuppliesAllotedAuth(this.suppliesTableData);
          }
        },
        (err) => {
          this.toastr.error(err.error.error.message);
          //New Setup
          if (this.activeTab == 'NewSetup') {
            if (err.error.error.message == 'Auth Locked By Another User') {
              let tableValue = this.newSetupTableData?.find(
                (a) => a.id == wipId
              );
              tableValue.isLocked = 1;
            }
            if (err.error.error.message == 'Auth is in Pending Status') {
              this.searchAuthNewSetup();
            }
          }
          //Supplies
          else if (this.activeTab == 'Supplies') {
            if (err.error.error.message == 'Auth Locked By Another User') {
              let tableValue = this.suppliesTableData?.find(
                (a) => a.id == wipId
              );
              tableValue.isLocked = 1;
            }
            if (err.error.error.message == 'Auth is in Pending Status') {
              this.searchAuthSupplies();
            }
          }
        }
      );
    }
  }
  //Sorting based on isLocked Column
  customSort(a: WIPList, b: WIPList) {
    const sortOrder = [2, 0, 1]; // Define your desired sort order
    const indexA = sortOrder.indexOf(a.isLocked);
    const indexB = sortOrder.indexOf(b.isLocked);
    return indexA - indexB;
  }

  //Unlock the Auth if the User is Admin
  unlockAuth(isAdmin: number, wipId: string) {
    if (isAdmin === 1) {
      this.updatePatientLock(null, wipId);
    } else {
      this.toastr.info('The Auth is Locked!');
    }
  }
  //New Setup Reset Form
  resetForm() {
    this.newSetupFilterForm.reset();
    this.searchAuthNewSetup();
  }

  setTableDatas(tableData: any[] = []) {
    this.suppliesTableData = tableData;
    this.dataSourceSupplies = new MatTableDataSource(tableData);
    this.sortSupplies.disableClear = true;
    this.dataSourceSupplies.sort = this.sortSupplies;
    this.dataSourceSupplies.paginator = this.PaginatorSupplies;
  }

  clearTypeOfPlan() {
    this.typeOfPlan = '';
    this.suppliesFilterForm.patchValue({ drpTypeOfPlan: '' });
  }
  getTooltip(data) {
    return data.itemCodes;
  }
  getFilterDropdowns() {
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.BillingReports,
      PatientDropdowns.ArAgingReports,
    ];
    this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          const reponse: PatientMasterDropdownDTO = stateResponse;
          this.planNameFilterValue = reponse?.lstArAgingReportRows ?? [];
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }

  allBranchSelect(){
    if(this.selectAllBranch.selected){
      this.selectBranch.options.forEach((item: MatOption) => item.select());
    }
    else{
      this.selectBranch.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getBranchList(){
    const getBranchList = this.branchService.getBranchList(this.tenantId).subscribe(response => {
      response?.forEach(element => {
        this.drpBranch.push({
          id: element.id,
          organizationUnitName: element.organizationUnitName,
        })
      });

      this.brnachList = this.branchForm
        ?.get('txtBranch')
        ?.valueChanges?.pipe(
          startWith(''),
          map((value) =>
            this.drpBranch?.filter((option) =>
              option?.organizationUnitName
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );


    }, err =>{
      const data : HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(getBranchList);
  }
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
  checkPayorUniqness(){
    let payerId=this.newSetupFilterForm.value.drpselectPayor;
    this.PatientAddressService.getPayorCheckAvailablity(payerId)
     .subscribe(
     (response) => {
           this.isUniq = response;
         },
         (err) => {
           const data: HttpErrorResponse = err;
           Swal.fire({
             icon: 'info',
             text: data?.error?.error?.message,
           });
         }
       );
  }
}

export interface Branch{
  id?: string;
  organizationUnitName?: string;
}
