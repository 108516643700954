import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import {
  defaultGuid,
  MyQueueClaimStatus,
  MyQueueClaimStatusText,
} from 'projects/shared/src/app/enums/allenums.enum';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { ViewPatientLedgerComponent } from '../../view-patient-ledger/view-patient-ledger.component';
import { ClaimViewTabComponent } from 'projects/billing/src/app/claim-view-tab/claim-view-tab.component';
import { PreviewDocumentComponent } from 'projects/order/src/app/preview-document/preview-document.component';
import { ErrorDetails277DTO } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization/dto/models';
import { ClearingHouseUpdateBatchesService } from 'projects/billing/src/app/claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization/clearing-house-update-batches.service';

@Component({
  selector: 'app-claim-bin-queue-table',
  templateUrl: './claim-bin-queue-table.component.html',
  styleUrls: ['./claim-bin-queue-table.component.scss'],
})
export class ClaimBinQueueTableComponent implements OnInit {
  @Input() inBoundTableData: any[];
  @Input() activeTabBin: any;

  @Input() currentPage: any = 1;
  @Input() totalPages: any = 1;
  @Input() visibleColumns: number[];
  @Output() nevigatePage? = new EventEmitter<number>();
  @ViewChild('tableContainer', { read: ViewContainerRef })
  tableContainer: ViewContainerRef;
  myTable2: DataTables.Api;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement2: DataTableDirective;
  MyQueueClaimStatus = MyQueueClaimStatus;
  MyQueueClaimStatusText = MyQueueClaimStatusText;

  dtDocumentClaimTableOptionsv1: any = {
    responsive: true,
    // stateSave: true,
    // searching: false,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    fixedHeader: true, // Fix the header
    fixedColumns: {
      leftColumns: 3, // Number of columns to fix on the left
      rightColumns: 1, // Number of columns to fix on the right
    },
    columnDefs: [
      { targets: [0], orderable: false },
      { targets: [12, 13, 14], visible: false },
      { targets: [0, 1, 2], className: 'dt-fixed-column' },
      {
        targets: [5, 10, 11],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
      { targets: [1, 3, 4, 10, 11, 14, 16], width: '1%' },
    ],
    search: {
      smart: false,
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Patient List',
        exportOptions: {
          columns: ':visible:not(:first-child) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      // {
      //   text: 'Show Default Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns(':not(:lt(3),:last-child)').visible(false);

      //   },
      // },
      // {
      //   text: 'Show All Column',
      //   action: function (e, dt, node, config) {
      //     dt.columns().visible(true);
      //   },
      // },
      // {
      //   text: '<',
      //   action: this.changePage(false),
      // },
      // {
      //   text: '>',
      //   action: this.changePage(true),
      // },
    ],
    initComplete: function (this: any) {
      const searchInput = document.querySelector(
        '.dataTables_wrapper .dataTables_filter'
      ) as HTMLElement;
      if (searchInput) {
        searchInput.style.float = 'left';
      }
    },
  };

  dtDocumentClaimTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    columnDefs: [{ targets: [8, 9, 10, 11], searchable: true, visible: false }],
    language: {
      search: '',
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [0],
    // ordering: false,
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: '',
        exportOptions: {
          columns: ':not(:first-child) ',
        },
      },
    ],
    initComplete: function (this: any) {
      const searchInput = document.querySelector(
        '.dataTables_wrapper .dataTables_filter'
      ) as HTMLElement;
      if (searchInput) {
        searchInput.style.float = 'left';
      }
    },
  };
  // dtDocumentClaimAutoPostingTable: any;
  dtDocumentClaimAutoPostingTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    // columnDefs: [{ targets: [8, 9, 10, 11], searchable: true, visible: false }],
    language: {
      search: '',
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [0],
    ordering: false,
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: '',
        exportOptions: {
          columns: ':not(:first-child) ',
        },
      },
    ],

    // buttons: [
    //   {
    //     extend: 'excel',
    //     text: 'Export',
    //     filename: '',
    //     exportOptions: {
    //       columns: ':not(:first-child)', // Adjust as needed
    //       format: {
    //         body: function (data, row, column) {
    //           // Check if the row is a child row and format accordingly
    //           const rowData = row.data();
    //           if (rowData && rowData.child) {
    //             // Return the child data or format it as needed
    //             return rowData.child; // Adjust this to access the specific child data
    //           }
    //           return data;
    //         },
    //       },
    //     },
    //   },
    // ],

    // buttons: [
    //   {
    //     extend: 'excel',
    //     text: 'Export',
    //     filename: '',
    //     exportOptions: {
    //       columns: ':not(:first-child)', // Adjust as needed
    //       format: {
    //         body: function (data, row, column) {
    //           // Access your DataTable instance
    //           const rowData = dtDocumentClaimAutoPostingTable.row(row).data(); // Use the instance

    //           // Check if the rowData indicates it's a child row and format accordingly
    //           if (rowData && rowData.child) {
    //             return rowData.child; // Adjust this to access the specific child data
    //           }
    //           return data;
    //         },
    //       },
    //     },
    //   },
    // ],

    // buttons: [
    //   {
    //     extend: 'excel',
    //     text: 'Export',
    //     filename: '',
    //     exportOptions: {
    //       columns: ':not(:first-child)',
    //       format: {
    //         body: (data, row, column) => {
    //           // Access your DataTable instance
    //           const rowData = this.dtDocumentClaimAutoPostingTable.row(row).data();

    //           // Check if the rowData indicates it's a child row and format accordingly
    //           if (rowData && rowData.child) {
    //             return rowData.child; // Adjust this to access the specific child data
    //           }
    //           return data;
    //         },
    //       },
    //     },
    //   },
    // ],

    initComplete: function (this: any) {
      const searchInput = document.querySelector(
        '.dataTables_wrapper .dataTables_filter'
      ) as HTMLElement;
      if (searchInput) {
        searchInput.style.float = 'left';
      }
    },
  };

  dtDocumentClaimPostingTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    columnDefs: [
      {
        targets: [ 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        searchable: true,
        visible: false,
      },
    ],
    language: {
      search: '',
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [0],
    // ordering: false,
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: '',
        exportOptions: {
          columns: ':not(:first-child) ',
        },
      },
    ],
    initComplete: function (this: any) {
      const searchInput = document.querySelector(
        '.dataTables_wrapper .dataTables_filter'
      ) as HTMLElement;
      if (searchInput) {
        searchInput.style.float = 'left';
      }
    },
  };

  dtDocumentClaimchecklistTableOptions: any = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    search: {
      smart: false,
    },
    columnDefs: [
      { targets: [7, 8, 9, 10, 11], searchable: true, visible: false },
    ],
    language: {
      search: '',
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [0],
    // ordering: false,
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: '',
        exportOptions: {
          columns: ':not(:first-child) ',
        },
      },
    ],
    initComplete: function (this: any) {
      const searchInput = document.querySelector(
        '.dataTables_wrapper .dataTables_filter'
      ) as HTMLElement;
      if (searchInput) {
        searchInput.style.float = 'left';
      }
    },
  };

  constructor(
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
    private commonService: CommonService,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private clearingHouseUpdatesService: ClearingHouseUpdateBatchesService,
  ) {}

  ngAfterViewInit(): void {
    if (this.datatableElement2 && this.datatableElement2.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }
  refreshDataTable(): void {
    this.datatableElement2.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      this.addPageNavigationControls();
      // Attach the event listener for filtering
      var visColumns = this.visibleColumns;
      var index = 0;
      dtInstance.columns().every(function () {
        const that = this;
        if (visColumns && visColumns.length > 0) {
          if (visColumns.indexOf(index) < 0)
            this.visible(false);
        }
        index++;
      });
    });
  }

  ngOnInit(): void {
    // this.dtDocumentClaimAutoPostingTable = $('#testByMani').DataTable(this.dtDocumentClaimAutoPostingTableOptions);
    this.dtDocumentClaimTableOptions.buttons[0].filename =
      this.activeTabBin +
      '-' +
      this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.dtDocumentClaimAutoPostingTableOptions.buttons[0].filename =
      this.activeTabBin +
      '-' +
      this.datepipe.transform(new Date(), 'MM-dd-yyyy');
    this.dtDocumentClaimPostingTableOptions.buttons[0].filename =
      this.activeTabBin +
      '-' +
      this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.dtDocumentClaimchecklistTableOptions.buttons[0].filename =
      this.activeTabBin +
      '-' +
      this.datepipe.transform(new Date(), 'MM-dd-yyyy');
  }

  addPageNavigationControls() {
    let buttonsElement = $(".dt-buttons")[0];

    $("<span> : <span>").appendTo(buttonsElement);
    if (this.currentPage == 1) {
      $("<button disabled title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button disabled class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
    }
    else {
      $("<button title='First Page' class='dt-button' id='btnFirstPage' type='button'><span>&lt;&lt;</span></button>").appendTo(buttonsElement);
      $("<button title='Previous Page' class='dt-button' id='btnPrevPage' type='button'><span>&lt;</span></button>").appendTo(buttonsElement);
    }

    $("<span> Page <span>").appendTo(buttonsElement);
    $("<input style='width:50px;' id='txtGotoPage' value='" + this.currentPage + "' type='text'/>").appendTo(buttonsElement);
    $("<span> of " + this.totalPages + " <span>").appendTo(buttonsElement);
    $("<button  class='dt-button' id='btnGoToPage'   type='button'><span>Go to</span></button>").appendTo(buttonsElement);

    if (this.currentPage >= this.totalPages) {
      $("<button disabled class='dt-button' id='btnNextPage'   type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
      $("<button disabled title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }
    else {
      $("<button title='Next Page'  class='dt-button' id='btnNextPage'  type='button'><span>&gt;</span></button>").appendTo(buttonsElement);
      $("<button title='Last Page'  class='dt-button' id='btnLastPage'  type='button'><span>&gt;&gt</span></button>").appendTo(buttonsElement);
    }

    $('#btnNextPage').on('click', (event) => this.changePage(true));
    $('#btnPrevPage').on('click', (event) => this.changePage(false));
    $('#btnGoToPage').on('click', (event) => this.fnGotoPage());
    $('#btnFirstPage').on('click', (event) => this.nevigatePage.emit(1));
    $('#btnLastPage').on('click', (event) => this.nevigatePage.emit(this.totalPages));

  }

  changePage(isNext: boolean) {
    if (isNext)
      this.currentPage++;
    else
      this.currentPage--;
    this.nevigatePage.emit(this.currentPage);
  }


  fnGotoPage() {
    let pageValue = $("#txtGotoPage").val();
    let pageno = parseInt(pageValue.toString());
    if (pageno > 0 && this.currentPage != pageno && pageno <= this.totalPages) {
      this.nevigatePage.emit(pageno);
    }
  }

  //#region DoubleClick Copy Text
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  //#endRegio DoubleClick Copy Text
  getContrastingTextColor(hexColor: string): string {
    if (!hexColor) return '#000000'; // default to black if no color provided

    // Remove the hash if present
    hexColor = hexColor.replace('#', '');

    // Convert to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // If luminance is high, return dark color (black), otherwise return light color (white)
    // return luminance > 0.5 ? '#000000' : '#FFFFFF';
    return luminance > 0.5 ? '#000000' : '#000000';
  }

  updateTextColor(colorCode: string) {
    if (this.isValidColorCode(colorCode)) {
      return this.getContrastingTextColor(colorCode);
    } else {
      return '#000000'; // default to black
    }
  }
  isValidColorCode(colorCode: string): boolean {
    // Check if colorCode is a valid hex color code
    const regex = /^#([0-9A-F]{3}){1,2}$/i;
    return regex.test(colorCode);
  }
  ltErrors: ErrorDetails277DTO[] = [];
  spinnerEnabled: boolean = false;
  showError(claimId: string) {
    this.spinnerEnabled = true;
    this.ltErrors = [];
    const data = this.clearingHouseUpdatesService
      .get277ErrorDetailsByClaimId(claimId)
      .subscribe(
        (response) => {
          this.ltErrors = response ?? [];
          this.spinnerEnabled = false;
        },
        (err) => {
          this.spinnerEnabled = false;
          this.ltErrors = [];
        }
      );
  }

  getTotalInboundData(event: Event, inboundData: any) {
    console.log(inboundData);
    const target = event.target as HTMLElement;
    const tr = target.closest('tr');
    const icon = target as HTMLElement;
    this.getDataTableData2(tr, inboundData, icon);
  }

  //#region History Tables

  //DataTable Data Get Method and Toggle Notes Row in Table
  async getDataTableData2(
    tr: any,
    inboundData: any,
    icon: HTMLElement
  ): Promise<void> {
    this.myTable2 = await this.datatableElement2.dtInstance;
    if (tr) {
      const row = this.myTable2.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        icon.innerText = 'add';
        // } else {
        //   // Open this row
        //   row.child(this.format2(row.data(), inboundData)).show();
        //   row.child().addClass('no-padding');
        //   icon.innerText = 'remove';
        // }
      } else {
        // Open this row
        let formattedData;
        switch (this.activeTabBin) {
          case MyQueueClaimStatusText.ClaimSubmission:
            formattedData = this.formatClaimSubmission(row.data(), inboundData);
            break;
          case MyQueueClaimStatusText.AutoPosting:
            formattedData = this.formatAutoPosting(
              row.data(),
              inboundData.itempaymentDetails
            );
            break;
          case MyQueueClaimStatusText.ClaimPosting:
            formattedData = this.formatClaimPosting(row.data(), inboundData);
            break;
          case MyQueueClaimStatusText.ClaimChecklist:
            formattedData = this.formatClaimChecklist(row.data(), inboundData);
            break;
        }
        if (formattedData) {
          row.child(formattedData).show();
          row.child().addClass('no-padding');
          icon.innerText = 'remove';
        }
      }
    }
  }

  //Add Dynamic Row
  format2(data: any, inboundData: any): HTMLElement {
    const table = this.renderer.createElement('table');
    const thead = this.renderer.createElement('thead');
    const tbody = this.renderer.createElement('tbody');

    const thRow = this.renderer.createElement('tr');
    const headers = [
      { text: 'Insurance Rank', key: 'insuranceRank' },
      { text: 'Insurance Name', key: 'insuranceName' },
      { text: 'Branch', key: 'location' },
      { text: 'Total Allowed ($)', key: 'totalAllowed' },
    ];

    const headerOrder = {
      [MyQueueClaimStatusText.ClaimSubmission]: [
        'insuranceRank',
        'insuranceName',
        'location',
        'totalAllowed',
      ],
    };

    const shouldSkipKey = (key: string): boolean => {
      const skipKeys = {
        [MyQueueClaimStatus.ClaimSubmission]: [],
        // [MyQueueBinStatus.ReadyforOrder]: ['docchecklist', 'checklistId', 'uploadtype', 'servicetype', 'prepared'],
        // [MyQueueBinStatus.Verification]: ['checklistId', 'prepared'],
        // [MyQueueBinStatus.Precert]: ['checklistId','prepared'],
      };
      return skipKeys[this.activeTabBin]?.includes(key);
    };

    const orderedHeaders = headerOrder[this.activeTabBin]
      .map((key) => headers.find((header) => header.key === key))
      .filter((header) => header);

    orderedHeaders.forEach((header) => {
      if (shouldSkipKey(header.key)) return;
      const th = this.renderer.createElement('th');
      this.renderer.appendChild(th, document.createTextNode(header.text));
      if (header.key === 'modifiedByandDate') {
        this.renderer.setStyle(th, 'width', '200px');
      }
      this.renderer.appendChild(thRow, th);
    });

    this.renderer.appendChild(thead, thRow);
    this.renderer.appendChild(table, thead);
    this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

    if (!Array.isArray(inboundData)) {
      inboundData = [inboundData];
    }
    inboundData.forEach((value: any) => {
      const trData = this.renderer.createElement('tr');
      const cells = [
        { text: value.insuranceRank || '-', key: 'insuranceRank' },
        { text: value.insuranceName || '-', key: 'insuranceName' },
        { text: value.location || '-', key: 'location' },
        { text: value.totalAllowed || '-', key: 'totalAllowed' },
      ];

      cells.forEach((cell) => {
        if (shouldSkipKey(cell.key)) return;
        const td = this.renderer.createElement('td');
        this.renderer.appendChild(td, document.createTextNode(cell.text));
        this.renderer.appendChild(trData, td);
      });

      this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
      this.renderer.appendChild(tbody, trData);
    });

    this.renderer.appendChild(table, tbody);
    return table;
  }

  //Add ClaimSubmission Row
  formatClaimSubmission(data: any, inboundData: any): HTMLElement {
    const table = this.renderer.createElement('table');
    const thead = this.renderer.createElement('thead');
    const tbody = this.renderer.createElement('tbody');

    const thRow = this.renderer.createElement('tr');
    const headers = [
      { text: 'Insurance Rank', key: 'payorlevel' },
      { text: 'Insurance Name', key: 'insuranceName' },
      { text: 'Branch', key: 'location' },
      { text: 'Total Allowed ($)', key: 'totalAllowed' },
    ];

    const headerOrder = {
      [MyQueueClaimStatusText.ClaimSubmission]: [
        'payorlevel',
        'insuranceName',
        'location',
        'totalAllowed',
      ],
    };

    const shouldSkipKey = (key: string): boolean => {
      const skipKeys = {
        [MyQueueClaimStatus.ClaimSubmission]: [],
      };
      return skipKeys[this.activeTabBin]?.includes(key);
    };

    const orderedHeaders = headerOrder[this.activeTabBin]
      .map((key) => headers.find((header) => header.key === key))
      .filter((header) => header);

    orderedHeaders.forEach((header) => {
      if (shouldSkipKey(header.key)) return;
      const th = this.renderer.createElement('th');
      this.renderer.appendChild(th, document.createTextNode(header.text));
      if (header.key === 'modifiedByandDate') {
        this.renderer.setStyle(th, 'width', '200px');
      }
      this.renderer.appendChild(thRow, th);
    });

    this.renderer.appendChild(thead, thRow);
    this.renderer.appendChild(table, thead);
    this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

    if (!Array.isArray(inboundData)) {
      inboundData = [inboundData];
    }
    inboundData.forEach((value: any) => {
      const trData = this.renderer.createElement('tr');
      const cells = [
        { text: value.payorlevel || '-', key: 'payorlevel' },
        { text: value.insuranceName || '-', key: 'insuranceName' },
        { text: value.location || '-', key: 'location' },
        { text: value.totalAllowed != null ?  value.totalAllowed : '0', key: 'totalAllowed' },
      ];

      cells.forEach((cell) => {
        if (shouldSkipKey(cell.key)) return;
        const td = this.renderer.createElement('td');
        this.renderer.appendChild(td, document.createTextNode(cell.text));
        td.setAttribute('title', cell.text); // Add tooltip
        this.renderer.appendChild(trData, td);
      });

      this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
      this.renderer.appendChild(tbody, trData);
    });

    this.renderer.appendChild(table, tbody);
    return table;
  }

  //Add AutoPosting Row
  formatAutoPosting(data: any, inboundData: any): HTMLElement {
    const table = this.renderer.createElement('table');
    const thead = this.renderer.createElement('thead');
    const tbody = this.renderer.createElement('tbody');

    const thRow = this.renderer.createElement('tr');
    const headers = [
      { text: 'Code', key: 'codeTypeText' },
      { text: 'Cpt', key: 'itemProcedureCode' },
      { text: 'DOS', key: 'dtDos' },
      { text: 'M1', key: 'modifier1' },
      { text: 'M2', key: 'modifier2' },
      { text: 'M3', key: 'modifier3' },
      { text: 'M4', key: 'modifier4' },
      { text: 'Unit', key: 'itemCost' },
      { text: 'Billed ($)', key: 'billed' },
      { text: 'Allowed ($)', key: 'allowed' },
      { text: 'Deduct ($)', key: 'deductAmount' },
      { text: 'Copay ($)', key: 'coPayAmount' },
      { text: 'Co ins ($)', key: 'coinsurance' },
      { text: 'Paid ($)', key: 'insTotalPaid' },
      { text: 'WrtOff ($)', key: 'writeOffAmount' },
      { text: 'Claim', key: 'Claim' },
    ];

    const headerOrder = {
      [MyQueueClaimStatusText.AutoPosting]: [
        'codeTypeText',
        'itemProcedureCode',
        'dtDos',
        'modifier1',
        'modifier2',
        'modifier3',
        'modifier4',
        'itemCost',
        'billed',
        'allowed',
        'deductAmount',
        'coPayAmount',
        'coinsurance',
        'insTotalPaid',
        'writeOffAmount',
        'Claim',
      ],
    };

    const shouldSkipKey = (key: string): boolean => {
      const skipKeys = {
        [MyQueueClaimStatus.AutoPosting]: [],
      };
      return skipKeys[this.activeTabBin]?.includes(key);
    };

    const orderedHeaders = headerOrder[this.activeTabBin]
      .map((key) => headers.find((header) => header.key === key))
      .filter((header) => header);

    orderedHeaders.forEach((header) => {
      if (shouldSkipKey(header.key)) return;
      const th = this.renderer.createElement('th');
      this.renderer.appendChild(th, document.createTextNode(header.text));
      if (header.key === 'modifiedByandDate') {
        this.renderer.setStyle(th, 'width', '200px');
      }
      this.renderer.appendChild(thRow, th);
    });

    this.renderer.appendChild(thead, thRow);
    this.renderer.appendChild(table, thead);
    this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

    if (!Array.isArray(inboundData)) {
      inboundData = [inboundData];
    }
    inboundData.forEach((value: any) => {
      const trData = this.renderer.createElement('tr');
      const cells = [
        { text: value.codeTypeText || '-', key: 'codeTypeText' },
        { text: value.itemProcedureCode || '-', key: 'itemProcedureCode' },
        { text: value.dtDos || '-', key: 'dtDos' },
        { text: value.modifier1 || '-', key: 'modifier1' },
        { text: value.modifier2 || '-', key: 'modifier2' },
        { text: value.modifier3 || '-', key: 'modifier3' },
        { text: value.modifier4 || '-', key: 'modifier4' },
        { text: value.itemCost != null ? value.itemCost : '-', key: 'itemCost' },
        { text: value.billed != null ? value.billed : '-', key: 'billed' },
        { text: value.allowed != null ? value.allowed : '-', key: 'allowed' },
        { text: value.deductAmount != null ? value.deductAmount : '-', key: 'deductAmount' },
        { text: value.coPayAmount != null ? value.coPayAmount : '-', key: 'coPayAmount' },
        { text: value.coinsurance != null ? value.coinsurance : '-', key: 'coinsurance' },
        { text: value.insTotalPaid != null ? value.insTotalPaid : '-', key: 'insTotalPaid' },
        { text: value.writeOffAmount != null ? value.writeOffAmount : '-', key: 'writeOffAmount' },
        { text: value.Claim || '-', key: 'Claim' },
      ];

      cells.forEach((cell) => {
        if (shouldSkipKey(cell.key)) return;
        const td = this.renderer.createElement('td');
        this.renderer.appendChild(td, document.createTextNode(cell.text));
        td.setAttribute('title', cell.text); // Add tooltip
        this.renderer.appendChild(trData, td);
      });

      this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
      this.renderer.appendChild(tbody, trData);
    });

    this.renderer.appendChild(table, tbody);
    return table;
  }

  //Add ClaimPosting Row
  formatClaimPosting(data: any, inboundData: any): HTMLElement {
    const table = this.renderer.createElement('table');
    const thead = this.renderer.createElement('thead');
    const tbody = this.renderer.createElement('tbody');

    const thRow = this.renderer.createElement('tr');
    const headers = [
      { text: 'Item Total($)', key: 'billedAmount' },
      { text: 'Write Off Amount($)', key: 'writeOffAmount' },
      { text: 'Recoup($)', key: 'recoopAmount' },
      { text: 'Check No', key: 'chequeNumber' },
      { text: 'Check Date', key: 'checkDate' },
      { text: 'Payer Name', key: 'payerName' },
      { text: 'Posting Count', key: 'postingCount' },
      { text: 'Clearing House', key: 'clearingHouse' },
      { text: 'Processed Date', key: 'processedDate' },
      { text: 'Provider Name', key: 'providerName' },
      { text: 'Settled Status', key: 'isCCSettled' },
      { text: 'Aging Days', key: 'agingDays' },
    ];

    const headerOrder = {
      [MyQueueClaimStatusText.ClaimPosting]: [
        'billedAmount',
        'writeOffAmount',
        'recoopAmount',
        'chequeNumber',
        'checkDate',
        'payerName',
        'postingCount',
        'clearingHouse',
        'processedDate',
        'providerName',
        'isCCSettled',
        'agingDays',
      ],
    };

    const shouldSkipKey = (key: string): boolean => {
      const skipKeys = {
        [MyQueueClaimStatus.ClaimPosting]: [],
      };
      return skipKeys[this.activeTabBin]?.includes(key);
    };

    const orderedHeaders = headerOrder[this.activeTabBin]
      .map((key) => headers.find((header) => header.key === key))
      .filter((header) => header);

    orderedHeaders.forEach((header) => {
      if (shouldSkipKey(header.key)) return;
      const th = this.renderer.createElement('th');
      this.renderer.appendChild(th, document.createTextNode(header.text));
      if (header.key === 'modifiedByandDate') {
        this.renderer.setStyle(th, 'width', '200px');
      }
      this.renderer.appendChild(thRow, th);
    });

    this.renderer.appendChild(thead, thRow);
    this.renderer.appendChild(table, thead);
    this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

    if (!Array.isArray(inboundData)) {
      inboundData = [inboundData];
    }
    inboundData.forEach((value: any) => {
      const trData = this.renderer.createElement('tr');
      const cells = [
        {
          text: value.billedAmount != null ? value.billedAmount : '-',
          key: 'billedAmount',
        },
        {
          text: value.writeOffAmount != null ? value.writeOffAmount : '-',
          key: 'writeOffAmount',
        },
        {
          text: value.recoopAmount != null ? value.recoopAmount : '-',
          key: 'recoopAmount',
        },
        { text: value.chequeNumber || '-', key: 'chequeNumber' },
        { text: value.checkDate || '-', key: 'checkDate' },
        { text: value.payerName || '-', key: 'payerName' },
        { text: value.postingCount != null ? value.postingCount: '-', key: 'postingCount' },
        { text: value.clearingHouse || '-', key: 'clearingHouse' },
        { text: value.processedDate || '-', key: 'processedDate' },
        { text: value.providerName || '-', key: 'providerName' },
        { text: value.isCCSettled || '-', key: 'isCCSettled' },
        { text: value.agingDays != null ? value.agingDays: '-', key: 'agingDays' },
      ];

      cells.forEach((cell) => {
        if (shouldSkipKey(cell.key)) return;
        const td = this.renderer.createElement('td');
        this.renderer.appendChild(td, document.createTextNode(cell.text));
        td.setAttribute('title', cell.text); // Add tooltip
        this.renderer.appendChild(trData, td);
      });

      this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
      this.renderer.appendChild(tbody, trData);
    });

    this.renderer.appendChild(table, tbody);
    return table;
  }

  //Add formatClaimChecklist Row
  formatClaimChecklist(data: any, inboundData: any): HTMLElement {
    const table = this.renderer.createElement('table');
    const thead = this.renderer.createElement('thead');
    const tbody = this.renderer.createElement('tbody');

    const thRow = this.renderer.createElement('tr');
    const headers = [
      { text: 'Clearing House', key: 'clearingHouse' },
      { text: 'Posting Date', key: 'postingDate' },
      { text: 'Billed Amount($)', key: 'billedAmount' },
      { text: 'Paid Amount($)', key: 'insurancePaid' },
      { text: 'Current Balance($)', key: 'currentBalance' },
    ];

    const headerOrder = {
      [MyQueueClaimStatusText.ClaimChecklist]: [
        'clearingHouse',
        'postingDate',
        'billedAmount',
        'insurancePaid',
        'currentBalance',
      ],
    };

    const shouldSkipKey = (key: string): boolean => {
      const skipKeys = {
        [MyQueueClaimStatus.ClaimChecklist]: [],
      };
      return skipKeys[this.activeTabBin]?.includes(key);
    };

    const orderedHeaders = headerOrder[this.activeTabBin]
      .map((key) => headers.find((header) => header.key === key))
      .filter((header) => header);

    orderedHeaders.forEach((header) => {
      if (shouldSkipKey(header.key)) return;
      const th = this.renderer.createElement('th');
      this.renderer.appendChild(th, document.createTextNode(header.text));
      if (header.key === 'modifiedByandDate') {
        this.renderer.setStyle(th, 'width', '200px');
      }
      this.renderer.appendChild(thRow, th);
    });

    this.renderer.appendChild(thead, thRow);
    this.renderer.appendChild(table, thead);
    this.renderer.setStyle(table, 'backgroundColor', '#fbfcfe');

    if (!Array.isArray(inboundData)) {
      inboundData = [inboundData];
    }
    inboundData.forEach((value: any) => {
      const trData = this.renderer.createElement('tr');
      const cells = [
        { text: value.clearingHouse || '-', key: 'clearingHouse' },
        { text: value.postingDate || '-', key: 'postingDate' },
        { text: value.billedAmount != null ? value.billedAmount : '-', key: 'billedAmount' },
        { text: value.insurancePaid != null ? value.insurancePaid : '-', key: 'insurancePaid' },
        { text: value.currentBalance != null ? value.currentBalance :'-', key: 'currentBalance' },
      ];

      cells.forEach((cell) => {
        if (shouldSkipKey(cell.key)) return;
        const td = this.renderer.createElement('td');
        this.renderer.appendChild(td, document.createTextNode(cell.text));
        td.setAttribute('title', cell.text); // Add tooltip
        this.renderer.appendChild(trData, td);
      });

      this.renderer.listen(trData, 'click', () => this.onButtonClick(value));
      this.renderer.appendChild(tbody, trData);
    });

    this.renderer.appendChild(table, tbody);
    return table;
  }

  getValueColor(value: number): string {
    if (value == 2) {
      return 'red';
    } else if (value == 1) {
      return 'green';
    } else {
      return 'black';
    }
  }
  onButtonClick(data: any) {}
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

  //View Patient Notes
  openViewNotesPopup(patientId: string, defaultFaxId: string, docId: string) {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      data: {
        patientId: patientId,
        pageType: 'Patient Note',
        isAddVisible: false,
        defaultFaxId: defaultFaxId,
        docId: docId,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {},
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }
  //View Patient Ledger
  viewPatientLedger(patientId: string) {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: patientId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  onFaxIdClick(docId: string, defaultFaxId: string) {
    const url =
      '/#/patient_preview_tiff_document/' + docId + '/' + defaultFaxId;
    window.open(url, '_blank');
  }

  //Open Claim Form
  openClaimForm(
    orderId: string,
    patientId: string,
    chartNo: string,
    patientName: string,
    defaultSaleOrderId: string,
    claimId: string,
    isClaimBatch: boolean,
    isResubmit?: boolean
  ) {
    const dialogRef = this.dialog.open(ClaimViewTabComponent, {
      disableClose: true,
      data: {
        orderId: orderId,
        patientId: patientId,
        defaultSaleOrderId: defaultSaleOrderId,
        claimId: claimId,
        isClaimBatch: isClaimBatch,
        fullName: patientName,
        chartNo: chartNo,
        isResubmit: isResubmit ?? false,
      },
    });

    // const closeDialog = dialogRef.afterClosed().subscribe(
    //   (claimDetails: any) => {
    //     if (isResubmit) {
    //       this.isLoadTbl.emit(true);
    //     }
    //   },
    //   (err) => {
    //   }
    // );
  }

  previewDocumet(data) {
    const dialogRef = this.dialog.open(PreviewDocumentComponent, {
      height: '75%',
      width: '80%',
      disableClose: true,
      data: {
        // Add any data you want to pass to the dialog here
        // For example, you can pass patientId and documentId

        documentData: data.blobName,

        isClaim: true,
      },
    });

    // Subscribe to the closed event
    dialogRef.afterClosed().subscribe(() => {
      // Call the addNotes function directly
      // dialogRef.componentInstance.addNotes();
    });
  }
}
