<div class="container-fluid">
  <div class="row bg" style="margin-top: -10px">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0 font-size-18"><b>Document Sorting</b></h3>
        <h3 class="mb-0 font-size-18">
          <b>Document Id:</b>&nbsp;<b class="text-danger">DOC9845454578</b>
        </h3>

        <h3 class="mb-0 font-size-18">
          <b>Document Name:</b>&nbsp;<b class="text-danger">testdocument.pdf</b>
        </h3>
        <div class="page-title-box page-title-right pb-0">
          <ol class="breadcrumb m-0" style="background-color: transparent">
            <li>
              <button
                mat-button
                class="buttonColor mr-2 font-size-14 pl-2"
                (click)="approveDocument()"
              >
                <i class="mdi mdi-plus mr-1"></i>Complete Document Process
              </button>
            </li>
            <li class="ml-2 mt-2 breadcrumb-item">Sorting</li>
            <li class="mt-2 breadcrumb-item muted">Processed Documents</li>
          </ol>
        </div>
      </div>
    </div>
  </div>


  <div class="col-lg-12">

    
  </div>



</div>
