import { Component, Input, OnInit } from '@angular/core';
import { DoctorService } from '../patient-proxy/patient/doctor.service';
import {
  DoctorDTO,
  NPIDoctorDTO,
  NPIDoctorDTOs,
} from '../patient-proxy/patient/dto/models';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { TableService } from 'projects/shared/src/app/table.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PatientDoctorComponent } from '../patient-doctor/patient-doctor.component';
import { HttpErrorResponse } from '@angular/common/http';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEmpty } from 'rxjs/operators';
@Component({
  selector: 'app-patient-npi-doctor-table',
  templateUrl: './patient-npi-doctor-table.component.html',
  styleUrls: ['./patient-npi-doctor-table.component.scss']
})
export class PatientNpiDoctorTableComponent implements OnInit {
  @Input() tableData: any;
  @Input() isMedicare: boolean;
  @Input() npiPracticeID: string;
  npitblPracticeID: string;
  checkmedicare: boolean;
  doctorTableData: any;
  dtOptions: any;
  constructor(
    private doctorService: DoctorService,
    private toastr: ToastrService,
    private Table: TableService,
    public dialog: MatDialogRef<PatientDoctorComponent>,
    private communicationService: CommunicationService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      responsive: true,
      paging: false,
      language: {
        emptyTable: 'No records',
        info: 'Total : _MAX_ records',
        infoEmpty: 'Total : _MAX_ records',
        infoFiltered: '(filtered : _TOTAL_ records)',
        loadingRecords: 'Loading...',
        zeroRecords: 'No matching records',
      },
      scrollCollapse: true,
      scrollY: '280px',
      scrollX: true,
      // info: false
    };
    this.doctorTableData = this.tableData;
    // console.log(this.doctorTableData)
    this.checkmedicare = this.isMedicare;
    this.npitblPracticeID = this.npiPracticeID;
  }

  doctorId: string = '';
  AddNpiDoctor(data) {
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');
    if(this.isEmpty(this.npiPracticeID)){
      Swal.fire({
        title: 'Are you sure you want to save?',
        text: 'Would You Like To Save the NPI Doctor Details',
        icon: 'question',
        showCancelButton: true,
        // showDenyButton: true,
        showCloseButton: false,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        // denyButtonColor: '#3085d6',
        buttonsStyling: true,
        confirmButtonText: 'Yes, Save!',
        cancelButtonText: 'No, Cancel',
        // denyButtonText: 'Save and Link with Referral!',
        allowOutsideClick: false,

      }).then((result) => {
        if (result.isConfirmed) {
          let npiDoctorDatas = data;
          if (npiDoctorDatas.MiddleName == null) {
            npiDoctorDatas.MiddleName = '';
          }
          npiDoctorDatas.MiddleName = npiDoctorDatas?.MiddleName?.replace(
            '.',
            ''
          );
          npiDoctorDatas.FirstName = npiDoctorDatas?.FirstName?.replace('.', '');
          npiDoctorDatas.LastName = npiDoctorDatas?.LastName?.replace('.', '');
          let npiDoctorNumber: string = npiDoctorDatas.TelephoneNumber;
          npiDoctorNumber = npiDoctorNumber.replace('#', '');

          let responeMobile = npiDoctorDatas.TelephoneNumber.replace('-', '');
          responeMobile = responeMobile
            .replace('(', '')
            .replace(')', '')
            .replace('#', '')
            .replace('-', '')
            .split(' ')
            .join('');
          let responeFaxno = npiDoctorDatas.FaxNumber.replace('-', '');
          responeFaxno = responeFaxno
            .replace('(', '')
            .replace(')', '')
            .replace('#', '')
            .replace('-', '')
            .split(' ')
            .join('');
          let responePhone = npiDoctorDatas.TelephoneNumber.replace('-', '');
          responePhone = responePhone
            .replace('(', '')
            .replace(')', '')
            .replace('#', '')
            .replace('-', '')
            .split(' ')
            .join('');
          let title: string = npiDoctorDatas.Prefix;

          let doctor: DoctorDTO = {
            id: this.doctorId,
            title: title?.trim(),
            lastName: npiDoctorDatas.LastName,
            firstName: npiDoctorDatas.FirstName,
            middleName: npiDoctorDatas.MiddleName,
            suffix: '',
            doctorGroup: '',
            // facility: '',
            // facilityId: this.npitblPracticeID ? this.npitblPracticeID : defaultGuid,
            npiNumber: npiDoctorDatas.NpiNumber,
            marketingRep: '',
            address: npiDoctorDatas.Address1,
            city: npiDoctorDatas.City,
            state: npiDoctorDatas.State,
            country: npiDoctorDatas.Country,
            postalCode: npiDoctorDatas.PostalCode,
            isPCOS: 0,
            phoneNo: responePhone,
            faxNo: responeFaxno,
            mobileNo: responeMobile,
            emailId: '',
            faxAttention: '',
            medicalLicenseNo: npiDoctorDatas.MedicalLicenseNo,
            expiration: '',
            deaNumber: '',
            upin: '',
            stateMedicalId: '',
            doctorNotes: '',
            userDeliveredUser1: '',
            userDeliveredUser2: '',
            excludeFromElectronicFax: 0,
            taxonomyCode: '',
            organizationUnitId: null,
            isMedicare: this.checkmedicare,
             practiceId: [],
          };
          this.doctorService.create(doctor).subscribe(
            (response) => {
              if (response) {
                // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
                this.toastr.success('Saved Successfully', 'Success');
                data.isExists= true;
                this.Table.setDoctorTable(response);
                // this.dialog.close();
                // this.SearchNpiDoctorsAbp();
                this.communicationService.triggerPhysicianList();
              } else {
              }
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
        }else{
        }
      });
    }
    else{
      Swal.fire({
        title: 'Are you sure you want to save?',
        text: 'Would You Like To Save the NPI Doctor Details',
        icon: 'question',
        showCancelButton: true,
        // showDenyButton: true,
         showCloseButton: false,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        // denyButtonColor: '#34c38f',
        buttonsStyling: true,
        confirmButtonText: 'Save and Link with Referral!',
        cancelButtonText: 'No, Cancel',
        // denyButtonText: 'Save and Link with Referral!',
        allowOutsideClick: false,

      }).then((result) => {
         if(result.isConfirmed){
          let npiDoctorDatas = data;
          if (npiDoctorDatas.MiddleName == null) {
            npiDoctorDatas.MiddleName = '';
          }
          npiDoctorDatas.MiddleName = npiDoctorDatas?.MiddleName?.replace(
            '.',
            ''
          );
          npiDoctorDatas.FirstName = npiDoctorDatas?.FirstName?.replace('.', '');
          npiDoctorDatas.LastName = npiDoctorDatas?.LastName?.replace('.', '');
          let npiDoctorNumber: string = npiDoctorDatas.TelephoneNumber;
          npiDoctorNumber = npiDoctorNumber.replace('#', '');

          let responeMobile = npiDoctorDatas.TelephoneNumber.replace('-', '');
          responeMobile = responeMobile
            .replace('(', '')
            .replace(')', '')
            .replace('#', '')
            .replace('-', '')
            .split(' ')
            .join('');
          let responeFaxno = npiDoctorDatas.FaxNumber.replace('-', '');
          responeFaxno = responeFaxno
            .replace('(', '')
            .replace(')', '')
            .replace('#', '')
            .replace('-', '')
            .split(' ')
            .join('');
          let responePhone = npiDoctorDatas.TelephoneNumber.replace('-', '');
          responePhone = responePhone
            .replace('(', '')
            .replace(')', '')
            .replace('#', '')
            .replace('-', '')
            .split(' ')
            .join('');
          let title: string = npiDoctorDatas.Prefix;

          let doctor: DoctorDTO = {
            id: this.doctorId,
            title: title?.trim(),
            lastName: npiDoctorDatas.LastName,
            firstName: npiDoctorDatas.FirstName,
            middleName: npiDoctorDatas.MiddleName,
            suffix: '',
            doctorGroup: '',
            // facility: '',
            // facilityId: this.npitblPracticeID ? this.npitblPracticeID : defaultGuid,
            npiNumber: npiDoctorDatas.NpiNumber,
            marketingRep: '',
            address: npiDoctorDatas.Address1,
            city: npiDoctorDatas.City,
            state: npiDoctorDatas.State,
            country: npiDoctorDatas.Country,
            postalCode: npiDoctorDatas.PostalCode,
            isPCOS: 0,
            phoneNo: responePhone,
            faxNo: responeFaxno,
            mobileNo: responeMobile,
            emailId: '',
            faxAttention: '',
            medicalLicenseNo: npiDoctorDatas.MedicalLicenseNo,
            expiration: '',
            deaNumber: '',
            upin: '',
            stateMedicalId: '',
            doctorNotes: '',
            userDeliveredUser1: '',
            userDeliveredUser2: '',
            excludeFromElectronicFax: 0,
            taxonomyCode: '',
            organizationUnitId: null,
            isMedicare: this.checkmedicare,
            practiceId:this.npitblPracticeID ? [this.npitblPracticeID] : null,
          };
          this.doctorService.create(doctor).subscribe(
            (response) => {
              if (response) {
                // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
                this.toastr.success('Saved Successfully', 'Success');
                data.isExists= true;
                this.Table.setDoctorTable(response);
                // this.dialog.close();
                // this.SearchNpiDoctorsAbp();
                this.communicationService.triggerPhysicianList();
              } else {
              }
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
        }else{

        }
      });
    }



  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
}
