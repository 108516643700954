import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AddNoteTabComponent } from '../add-note-tab/add-note-tab.component';
import { PatientNotesService } from '../patient-proxy/patient-optimization/patient-notes.service';
import {
  PatientNotesDTO,
  PatientNotesSearchDTO,
} from '../patient-proxy/patient-optimization/dto/models';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { MasterNotesService } from 'projects/order/src/app/order-proxy/order-management/inventory';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { crudPremission } from 'projects/shared/src/app/shared-table/shared-table.component';
import { noteTypeOptions } from 'projects/admin/src/app/warehouse-proxy/inventory/dto';
@Component({
  selector: 'app-patient-notes-tab',
  templateUrl: './patient-notes-tab.component.html',
  styleUrls: ['./patient-notes-tab.component.scss'],
})
export class PatientNotesTabComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  patientNotesForm: FormGroup;

  subscription$: Subscription[] = [];
  @Input() patientId: string = '';
  @Input() isAddVisible: boolean = true;
  isLoading: boolean;
  isShowProgress: boolean;
  arrTableData: PatientNotesDTO[] = [];
  crudPermisson: crudPremission = {
    create: false,
    read: false,
    update: false,
    delete: false,
  };
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource = new MatTableDataSource(this.arrTableData);
  isShownUpdateIconForNotes: boolean = true;

  public arrDisplayedColumns: string[] = [
    'options',
    'defaultNotesId',
    'customNoteType',
    'noteReason',
    'subject',
    'description',
    'createdBy',
    'status',
    'createdDate',
  
  ];

  constructor(
    private patientNotesService: PatientNotesService,
    private formbuilder: FormBuilder,
    private matDialog: MatDialog,
    private dateValidator: DateValidator,
    private notesService: MasterNotesService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { patientId: string } = { patientId: defaultGuid }
  ) {}
  //After View Load
  ngAfterViewInit(): void {
    this.getTableData();
  }

  //On Page Load
  ngOnInit(): void {
    this.patientId = this.patientId ?? defaultGuid;
    if (this.data?.patientId !== defaultGuid) {
      this.patientId = this.data?.patientId;
    }

    this.patientNotesForm = this.formbuilder.group({
      defaultNotesId: new FormControl(''),
      noteType: new FormControl(''),
      description: new FormControl(''),
      severity: new FormControl(''),
      subject: new FormControl(''),
      noteReason: new FormControl(''),
      createdBy: new FormControl(''),
      createdDate: new FormControl('', this.dateValidator.dateVaidator),
      actualDate: new FormControl('', this.dateValidator.dateVaidator),
      dateNeeded: new FormControl('', this.dateValidator.dateVaidator),
      status: new FormControl(''),
      dateComplete: new FormControl('', this.dateValidator.dateVaidator),
    });
  }

  //Get Notes List into Table
  getTableData() {
    this.isLoading = true;
    const valueChanges = this.patientNotesForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        startWith({
          defaultNotesId: '',
          noteType: '',
          noteReason: '',
          description: '',
          subject: '',
          createdBy: '',
          status: '',
          createdDate: null,
        }),
        filter((e) => e && this.patientNotesForm?.valid),
        map((response: any) => {
          //this.setPatientTable([]);
          //this.PATIENTDATA = [];
          this.isLoading = true;
          this.isShowProgress = true;
          const sValue: PatientNotesSearchDTO = {
            defaultNotesId: response?.defaultNotesId?.trim()?.toLowerCase(),
            noteType: response?.noteType?.trim()?.toLowerCase(),
            noteReason: response?.noteType?.trim()?.toLowerCase(),
            description: response?.description?.trim()?.toLowerCase(),
            subject: response?.subject?.trim()?.toLowerCase(),
            createdBy: response?.createdBy?.trim()?.toLowerCase(),
            status: response?.status?.trim()?.toLowerCase(),
            dateCreated: response?.createdDate?.trim()?.toLowerCase(),
          };
          return sValue;
        }),
        switchMap((sValue) =>

          this.patientNotesService.notesSearchByGPatientIdAndGOrderIdAndGClaimIdAndSNoteIdAndSPatientNameAndSDefaultPatientIdAndSNoteTypeAndSReasonAndSSubjectAndSDescriptionAndSCreatedByAndDtCreatedDateAndSStatusAndStart_DateAndEnd_Date(
            this.patientId,
            defaultGuid,
            defaultGuid,
            sValue?.defaultNotesId,
            '',
            '',
            sValue?.noteType,
            sValue?.noteReason,
            sValue?.subject,
            sValue?.description,
            sValue?.createdBy,
            sValue?.dateCreated,
            sValue?.status,
            '',
            '',
          )
        )
      )
      .subscribe(
        (response) => {

          response = response?.map((x, i = 1) => {
            const y = {
              sNo: ++i,
              customNoteType: noteTypeOptions?.filter(
                (a) => a?.key === x?.noteType?.toString()
              )?.[0]?.value,
              ...x,
            };
            return y;
          });
          response = response?.filter(
            (a) =>
              a?.noteType?.toString() != 'invoice' &&
              a?.noteType?.toString() != 'order'
          );
          response && response && this.setTableData(response);
          this.isShowProgress = false;
          this.isLoading = false;
        },
        (err) => {
          this.setTableData([]);
          this.isLoading = false;
          this.isShowProgress = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );

    this.subscription$.push(valueChanges);
  }

  //On Destroy Function
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  //To Open Note Modal
  openNoteModal(value: string) {

    const dialogRef = this.matDialog.open(AddNoteTabComponent, {
      disableClose: true,
      data: { patientId: this.patientId, notesId: value ?? defaultGuid

      },
    });
    const closeDialog$ = dialogRef.afterClosed().subscribe(
      (result) => {
        this.getTableData();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog$);
  }

  //Set Table Data
  setTableData(order: any[]) {
    this.arrTableData = order;
    this.dataSource = new MatTableDataSource(this.arrTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
