import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { dateFormatter } from 'projects/patient/src/app/user-task-list/user-task-list.component';
import { baseUrl } from 'projects/patient/src/environments/environment';
import { DocumentMaxandTypeLimits } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { defer, from } from 'rxjs';
import { concatMap, groupBy, map, mergeMap, takeLast, toArray } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { DocumentUploadService, FileUploadService } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload';
import { BatchDocumentUploadsDTO, CreateUpdateBatchDocumentUploadsDTO, CreateUpdateDocumentUploadsDTO, DocumentUploadsDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/dto';
import { UploadedFileStatus, UploadedFrom } from '../admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration';
import { UploadFilesService } from '../admin-proxy/platform-app-management/rcm/platform-management/upload-file';
import { CreateUploadFilesDTO, UpdateUploadFilesDTO, UploadFilesDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/upload-file/dto/models';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user';
import { IdentityUserDto } from '../admin-proxy/volo/abp/identity';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-add-document-botc',
  templateUrl: './add-document-botc.component.html',
  styleUrls: ['./add-document-botc.component.scss']
})
export class AddDocumentBotcComponent implements OnInit {
  //! Declaration and variable Initialization

  fileId: string = "";
  addDocumentbinModalTitle: string = "Upload Documents";
  strPageType: string = "selectedDocumentList";
  organizationUnitId: string = "";
  arrSelectedDocuments: any[] = [];
  arrUsersList: IdentityUserDto[] = [];
  documentBinForm: FormGroup = new FormGroup({});
  isShowSpinner: boolean = false;
  showErrorBasedOnSize: boolean = false;
  showErrorForFileType: boolean = false;
  showErrorForFileLength: boolean = false;
  userId: string = "";
  tenantId: string = "";
  chkdisableButton: boolean = false;
  selectedFileUploadsData: Partial<UploadFilesDTO> = null;
  constructor(
    private fb: FormBuilder,
    private fileUploadService: FileUploadService,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private table: TableService,
    public userService: UserService,
    public dialog: MatDialog,
    private documentUploadService: DocumentUploadService,
    private uploadFilesService: UploadFilesService,
    private toastr: ToastrService,


  ) {
    this.fileId && this.getDeatilsByBlobDocumentName(this.fileId);
  }
  //! Page Initialization Function

  ngOnInit(): void {

    this.resetDocumentBinForm();
    this.fileId = this.data ?? "";

    this.fileId && this.getDeatilsByBlobDocumentName(this.fileId);
    this.table.setSelectedDocumentList(this.arrSelectedDocuments);
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.organizationUnitId = localStorage.getItem("initialOrganizationUnitId") ?? "";

    this.documentBinForm = this.fb.group({
      documentFile: new FormControl(null),
      txtUser: new FormControl(null),
      chkStat: new FormControl(""),
      txtNote: new FormControl("")
    })

    this.userId = localStorage.getItem("userId") ?? "";
    this.getUsersList();

  }
  //! Get  Users List

  private getUsersList() {
    this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;
      this.fileId && this.getDeatilsByBlobDocumentName(this.fileId);
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }
  //! Get Single File Value Details By Document ID
  getDeatilsByBlobDocumentName(strDocumentId: string) {
    if (strDocumentId !== "" && strDocumentId !== null && strDocumentId !== undefined) {

      this.uploadFilesService.getUploadedFileByIdByFileId(this.fileId).subscribe(response => {
        const defaultGuid: string = "00000000-0000-0000-0000-000000000000";

        const responseBatchData: UploadFilesDTO = response;
        if (responseBatchData && strDocumentId) {
          this.documentBinForm.patchValue({
            txtUser: ((responseBatchData?.assignedTo === defaultGuid) ? null : (responseBatchData.assignedTo ?? "")),
            chkStat: responseBatchData?.isStat === 1 ? true : false,
            //txtNote: responseBatchData?.tags ?? "",
          })
          this.addDocumentbinModalTitle = "Edit Sub Batch Documents";
          this.selectedFileUploadsData = responseBatchData;
          this.selectedFileUploadsData.assignedToUserName = this.getUserName(responseBatchData?.assignedTo)??"";
          this.selectedFileUploadsData.uploadedOn = dateFormatter(responseBatchData?.lastModificationTime ?? responseBatchData.uploadedOn ?? "");
        }
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
  }
  //! Get  Users Name

  private getUserName(value: string):string {
    let username: string = "";
    if (value && typeof value === "string" && value?.length >= 36) {
      username = this.arrUsersList?.filter(u => u?.id === value)[0]?.userName ?? "";
    }
    return username
  }
  //! Check Batch Save Or Edit Document Details

  saveUploadedDocuments(files: any) {
    this.chkdisableButton = true;
    if (this.fileId === "") {
      if (files.length === 0) {
        return;
      }
      this.saveFunction();
    } else {
      this.editFunction();
    }
  }
  //! Reset Document Bin From On Click
  resetDocumentBinForm() {
    this.documentBinForm.reset({
      txtUser: null,
      chkStat: false,
    });
    this.selectedFileUploadsData = null;
    this.showErrorForFileType = false;
    this.showErrorForFileLength = false;
    this.showErrorBasedOnSize = false;
    this.arrSelectedDocuments = [];
    this.fileId = "";
    this.addDocumentbinModalTitle = "Upload Fax Documents";
    this.table.setSelectedDocumentList(this.arrSelectedDocuments);
  }
  //! Get Selected Files Details using Validations
  getSelectedFilesDetails(files: any) {
    this.arrSelectedDocuments = [];
    this.table.setSelectedDocumentList([]);
    let fileResult: { base64Value: string; fileName: string; fileType: string; fileSize: string; }[] = [];
    for (let index = 0; index < files.length; index++) {
      if (files[index].size > DocumentMaxandTypeLimits.documentFileSizeLimit) {
        this.showErrorBasedOnSize = true;
        this.showErrorForFileType = false;
        this.showErrorForFileLength = false;
        this.arrSelectedDocuments = [];

        return;
      };
      if (files[index].type !== DocumentMaxandTypeLimits.documentTypeLimit) {
        this.showErrorForFileType = true;
        this.showErrorBasedOnSize = false;
        this.showErrorForFileLength = false;
        this.arrSelectedDocuments = [];

        return;
      };

      if (files.length >= DocumentMaxandTypeLimits.documentMaxNoFileLimit) {
        this.showErrorForFileLength = true;
        this.showErrorForFileType = false;
        this.showErrorBasedOnSize = false;
        this.arrSelectedDocuments = [];

        return;
      };
      this.showErrorForFileType = false;
      this.showErrorBasedOnSize = false;
      this.showErrorForFileLength = false;
      let fileResultValue: string = "";
      const fileName: string = files[index].name;
      const m1fileName: string = fileName.replace(".pdf", "");
      const m2fileName: string = m1fileName.replace(".pdf", "");
      let reader = new FileReader();
      reader.readAsDataURL(files[index]);

      let modifiedElement: { base64Value: string, fileName: string, fileType: string, fileSize: string };
      reader.onload = () => {
        fileResultValue = reader?.result?.slice(28)?.toString() ?? "";
        modifiedElement = {
          base64Value: fileResultValue,
          fileName: m2fileName,
          fileType: files[index].type === "application/pdf" ? "PDF" : "",
          fileSize: this.formatFileSize(files[index].size, 3)
        };
        fileResult.push(modifiedElement);
      }

      this.arrSelectedDocuments = fileResult;
    }
  }

  //! Get File SIze In readble format
  formatFileSize(bytes: number, decimalPoint: number) {
    if (bytes == 0) return '0 Bytes';
    if (isNaN(bytes)) return '0 Bytes';
    const k = 1024;
    const dm = decimalPoint || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  //! Batch Save Document Details
  saveFunction() {
    this.isShowSpinner = true;
    const defaultGuid: string = "00000000-0000-0000-0000-000000000000";
    let FileUploadsInput: CreateUploadFilesDTO[] = [];
    const userId: string = this.documentBinForm.value.txtUser ?? defaultGuid;

    this.arrSelectedDocuments?.forEach(element => {
      FileUploadsInput.push({
        defaultFileId: "",
        fileContentAsBase64String: element?.base64Value,
        uploadedFrom: UploadedFrom.Application,
        uploadedBy: this.userId ?? defaultGuid,
        uploadedOn: new Date().toISOString(),
        fileName: element?.fileName ?? "",
        fileSize: element?.fileSize ?? "",
        numberOfPages: 0,
        fileStatus: UploadedFileStatus.New,
        isStat: this.documentBinForm.value.chkStat === true ? 1 : 0,
        assignedTo: userId === "" ? defaultGuid : userId,
      })
    });

    from(FileUploadsInput).pipe(
      groupBy(v => v.fileName),
      mergeMap(group => group.pipe(toArray())),
      mergeMap(group => from(group)
        .pipe(
          concatMap((v) => defer(() => this.uploadFilesService.uploadFileByOCreateFileUploads(v))))),
      takeLast(1)
    ).subscribe(value => {
      this.isShowSpinner = false;
      this.toastr.success('Saved Successfully','Success')
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).finally(() => {
        this.resetDocumentBinForm();
        //      value.blobName && window.open(baseUrl + '#/ViewDocumentPerviewByBlobId/' + value.blobName + '/' + 3, "_blank");
        this.dialog.closeAll();
      // });
      this.chkdisableButton = false;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.isShowSpinner = false;
      this.chkdisableButton = false;
    })

  }

  

  //! Batch-File Edit Document Details
  editFunction() {
    const defaultGuid: string = "00000000-0000-0000-0000-000000000000";
    const listFileUploadsData: Partial<UploadFilesDTO> = this.selectedFileUploadsData;
    const userId: string = this.documentBinForm.value.txtUser ?? defaultGuid;
    let fileUploadsInput: UpdateUploadFilesDTO = {
      assignedTo: userId === "" ? defaultGuid : userId,
      isStat: this.documentBinForm.value.chkStat === true ? 1 : 0,
    };
    this.uploadFilesService.updateFileDetailsByFileIdAndOUpdateFileUploads(this.fileId, fileUploadsInput).subscribe(value => {
      this.toastr.success('Saved Successfully','Success')
      // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true }).finally(() => {
        this.resetDocumentBinForm();
        this.dialog.closeAll();
      // });
      this.chkdisableButton = false;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
      this.chkdisableButton = false;
    })
  }
}

