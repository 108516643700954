import { ClaimProcessingService } from './../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-processing.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import {
  PatientSaleOrderService,
  SaleOrderItemService,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import {
  PayerService,
  DoctorService,
} from 'projects/patient/src/app/patient-proxy/patient';
import {
  PatientAuthorizationService,
  PatientClinicalService,
  PatientPersonalService,
  PatientPolicyService,
  PatientVerificationService,
} from 'projects/patient/src/app/patient-proxy/patient-optimization';
import {
  OrderDetailsDTO,
  SaleOrderItemDTO,
} from 'projects/order/src/app/order-proxy/order-management/order-optimization/dto';
import {
  DiagnosisCode10DTO,
  DiagnosisCode9DTO,
  GenderDTO,
  PatientMasterDropdownDTO,
  PayorLevelDTO,
  PlaceOfServiceDTO,
  RelationShipWithSelfDTO,
  SuffixDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import {
  RegionStatesDTO,
  RegionCitiesDTO,
  RegionCountriesDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  filter,
} from 'rxjs/operators';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { DoctorDTO } from 'projects/patient/src/app/patient-proxy/patient/dto';
import { PatientDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { PayerDTO } from '../claim-proxy/claim-processing-management/rcm/patient-management/patient/dto/models';
import { MasterProviderService } from 'projects/order/src/app/order-proxy/order-management/inventory/master-provider.service';
import { MasterProviderDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto/models';
import {
  PatientAuthorizationDTO,
  PatientClinicalDTO,
  PatientPersonalDTO,
  PatientPolicyDTO,
  PatientVerificationDTO,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { AddItemTabComponent } from 'projects/order/src/app/add-item-tab/add-item-tab.component';
import { ToastrService } from 'ngx-toastr';
import { PayerDetailService } from 'projects/patient/src/app/patient-proxy/patient/payer-detail.service';
import {
  CreateUpdateClaimFormDetailsDTO,
  CreateUpdateItemCodesListDTO,
} from '../claim-proxy/claim-processing-management/rcm/billing-management/dto';
import { ClaimFormDetailsService } from '../claim-proxy/claim-processing-management/rcm/billing-management/billing/claim-form-details.service';
import { MmModifierService } from '../claim-proxy/claim-processing-management/rcm/billing-management/billing/mm-modifier.service';
import { anyasdasd } from '../claim-proxy/claim-processing-management/system/reflection';
import { tr } from 'date-fns/locale';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Router } from '@angular/router';
import { baseUrl } from 'projects/patient/src/environments/environment';
import { ClearingHouseUpdateBatchesService } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization';
import { ErrorDetails277DTO } from '../claim-proxy/claim-processing-management/rcm/claim-processing-management/claim-optimization/dto';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { setImmediate } from 'timers';
import { PatientIcd9Component } from 'projects/patient/src/app/patient-icd9/patient-icd9.component';
import { PatientIcd10Component } from 'projects/patient/src/app/patient-icd10/patient-icd10.component';
@Component({
  selector: 'app-claim-view-tab',
  templateUrl: './claim-view-tab.component.html',
  styleUrls: ['./claim-view-tab.component.scss'],
})
export class ClaimViewTabComponent implements OnInit, OnDestroy {
  orderId: string = defaultGuid;
  patientId: string = defaultGuid;
  isSaveLoading: boolean = false;
  saveButtonHide: boolean = false;
  defaultSaleOrderId: string = '';
  claimForm: FormGroup;
  filteredOptions: any;
  filteredPOS: any;
  priPolicyId: any;
  isSelect: boolean = true;
  isLoadData: boolean = false;
  billingInfoArray: any = [];
  billingInfoArrayV1: any = [];
  searchControl = new FormControl();
  txtdrpProviderContron = new FormControl();
  txtInsGenderControl = new FormControl();
  submitted = false;
  totalItemPrice: string = '';
  totalOrderPrice: string = '';
  totalItemQty: string = '';
  tblPlaceOfService: string = '';
  tblProviderNpi: string = '';
  strGender: string = '';
  strBillingState: string = '';
  strBillingCity: string = '';
  countryPhoneCode: string = '';
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  drpRelationship: RelationShipWithSelfDTO[] = [];
  strRelationship: string = '';
  strResponsibleCity: string = '';
  strResponsibleState: string = '';
  strPayers: string = '';
  strReferringDoctor: string = '';
  processZirmedApiCall: Subscription;
  isLoadings$: Observable<boolean> = of(false);
  strClaimAddress: string = '';
  lstIcd10: string[] = [];
  tblSaleOrderItems: CustomSaleOrderItemDTO[] = [];
  drpGenderLoop: GenderDTO[] = [];
  drpResCities: RegionCitiesDTO[] = [];
  drpCountry: RegionCountriesDTO[] = [];
  filteredDoctorTypes: any;
  strPlaceOfService: string = '';
  strProvider: string = '';
  totalCharge: any;
  drpPlaceOfSerivce: PlaceOfServiceDTO[] = [];
  drpProviders: any;
  drpProvidersList: any;
  drpDoctors: any;
  ltErrors: ErrorDetails277DTO[] = [];
  drpActivePolicies: PatientPolicyDTO[] = [];
  public filteredResponsibleStates: Observable<RegionStatesDTO[]>;
  public filteredResponsibleCities: Observable<RegionCitiesDTO[]>;
  public filteredBillingCountries: Observable<RegionCountriesDTO[]>;
  public filteredBillingStates: Observable<RegionStatesDTO[]>;
  public filteredBillingCities: Observable<RegionCitiesDTO[]>;
  public filteredPayerTypes: any;
  filteredResponsibleCountries: Observable<RegionCountriesDTO[]>;
  drpBillingCities: RegionCitiesDTO[] = [];
  public filteredIcd9Types: Observable<DiagnosisCode9DTO[]>;
  public filteredIcd10Types: Observable<DiagnosisCode10DTO[]>;
  drpDiagnosisICD9: DiagnosisCode9DTO[] = [];
  drpDiagnosisICD10: DiagnosisCode10DTO[] = [];
  drpSuffixes: SuffixDTO[] = [];
  drpPayorLevel: PayorLevelDTO[] = [];
  drpPayers: any;
  drpInsuredStates: RegionStatesDTO[] = [];
  drpBillingStates: RegionStatesDTO[] = [];
  subscription$: Subscription[] = [];
  authId: string = defaultGuid;
  verificationId: string = defaultGuid;
  totalValue: string[] = [];
  messageValue: string = '';
  phoneCode: string;
  isLoading: boolean = false;
  dtClaimItemOptions: any = {
    responsive: true,
    paging: false,
    scrollX: true,
    scrollCollapse: true,
    // bFilter: false,
    // info: false,
    scrollY: '300px',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
  };
  tenantId: string;
  claimId: any;
  ltModifiers: any;
  ltPOS: any;
  txtRelationshipTypeControl = new FormControl();
  txtServiceInfoControl = new FormControl();
  filteredRelationShip: RelationShipWithSelfDTO[];
  filterServiceFacility: PlaceOfServiceDTO[];
  filterGender: GenderDTO[];
  ClaimStatus: any;
  chartNo: string;
  providerId: string;
  patientName: string;
  isClaimBatch: boolean = false;
  isSavebtn: boolean = false;
  doctorDetails: Subscription;
  isDocDrpdwnLoading: boolean = false;
  pos12: string = '';
  constructor(
    private formBuilder: FormBuilder,
    private payerService: PayerService,
    private doctorService: DoctorService,
    private regionDropdownService: RegionDropdownService,
    private patientDropdownService: PatientMasterDropdownService,
    private saleOrderService: PatientSaleOrderService,
    private patientService: PatientPersonalService,
    private patientPolicyService: PatientPolicyService,
    private patientVerificationService: PatientVerificationService,
    private masterProviderService: MasterProviderService,
    private patientClinicalService: PatientClinicalService,
    private patientAuthorizationService: PatientAuthorizationService,
    private saleOrderItemService: SaleOrderItemService,
    private payerDetailsService: PayerDetailService,
    private dialog: MatDialog,
    public claimViewModelRef: MatDialogRef<ClaimViewTabComponent>,
    private toastr: ToastrService,
    private claimProcessingService: ClaimProcessingService,
    private mmModifierservice: MmModifierService,
    private claimFormDetailsService: ClaimFormDetailsService,
    private patientMasterService: PatientMasterDropdownService,
    private clearingHouseUpdatesService: ClearingHouseUpdateBatchesService,
    private loaderService: LoaderService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      orderId: string;
      patientId: string;
      defaultSaleOrderId: string;
      claimId: string;
      isClaimBatch: boolean;
      chartNo: string;
      fullName: string;
      isResubmit?: boolean;
    } = {
      orderId: defaultGuid,
      patientId: defaultGuid,
      defaultSaleOrderId: defaultGuid,
      claimId: defaultGuid,
      isClaimBatch: false,
      chartNo: '-',
      fullName: '-',
      isResubmit: false,
    },
    @Inject(MAT_DIALOG_DATA) public datas: { reqData: any }
  ) {
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.filterPOS();
      });
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.filterModifier();
      });
    this.loaderService.getLoaderState().subscribe((isLoaded) => {
      this.isLoading = isLoaded;
    });
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.orderId = this.data?.orderId ?? defaultGuid;
    this.patientId = this.data?.patientId ?? defaultGuid;
    this.claimId = this.data?.claimId ?? defaultGuid;
    this.defaultSaleOrderId = this.data?.defaultSaleOrderId ?? '';
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.isClaimBatch = this.data?.isClaimBatch;
    this.createFormGroup();
    this.getDropDowns();
    this.loadModifire(0);
    this.loadPosDropDown();
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
    // this.claimForm.statusChanges.subscribe((x) => {
    //   this.isLoadings$ = of(x === 'INVALID');s
    // });
    // this.getOrderDetails();

    //Relationship aearch
    this.txtRelationshipTypeControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.filteredRelationShip = this.drpRelationship.filter((dx) =>
        dx.relationType.toLowerCase().includes(searchTerm)
      );
    });

    //Service facility search
    this.txtServiceInfoControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.filterServiceFacility = this.drpPlaceOfSerivce.filter((dx) =>
        dx.placeOfServiceType.toLowerCase().includes(searchTerm)
      );
    });
    //Gender search
    this.txtInsGenderControl.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.filterGender = this.drpGenderLoop.filter((dx) =>
        dx.genderName.toLowerCase().includes(searchTerm)
      );
    });
    //Gender search
    this.txtdrpProviderContron.valueChanges.subscribe((searchTerm) => {
      searchTerm = searchTerm.toLowerCase();
      this.drpProviders = this.drpProvidersList.filter((dx) =>
        dx.fullName.toLowerCase().includes(searchTerm)
      );
    });
    this.data.isResubmit && this.showError(this.claimId);
  }

  check() {
    console.log(this.claimForm);
    console.log(this.claimForm.invalid);
    console.log(this.saveButtonHide);
  }
  getDropDowns() {
    this.isLoadings$ = of(true);
    this.isLoading = true;
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns?.DiagnosisCode9,
      PatientDropdowns?.DiagnosisCode10,
      PatientDropdowns?.PayorLevels,
      PatientDropdowns?.Genders,
      PatientDropdowns?.RelationShipWithSelf,
      PatientDropdowns?.Suffixes,
      PatientDropdowns?.InsuranceTypes,
      PatientDropdowns?.PayorLevels,
      PatientDropdowns.PlaceOfServices,
      PatientDropdowns.Suffixes,
    ];

    const patientDropdowns = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (response) => {
          this.drpDiagnosisICD9 = response?.diagnosisCodes9 ?? [];
          this.drpDiagnosisICD10 = response?.diagnosisCodes10 ?? [];
          this.drpPayorLevel = response?.payorLevels ?? [];
          this.drpSuffixes = response?.suffixes ?? [];
          this.drpGenderLoop = response?.genders ?? [];
          this.drpRelationship = response?.relationShipWithSelves ?? [];
          this.drpPlaceOfSerivce = response?.placeOfServices ?? [];
          this.filteredRelationShip = this.drpRelationship;
          this.filterServiceFacility = this.drpPlaceOfSerivce.sort((a, b) =>
            a.placeOfServiceType.localeCompare(b.placeOfServiceType)
          );

          this.filterGender = this.drpGenderLoop;
          this.getOrderDetails();
          // this.filteredRelationShip = this.claimForm
          // .get('txtRelationshipType')
          // .valueChanges.pipe(
          //   startWith(''),
          //   map((value) =>
          //     this.drpRelationship?.filter((option) =>
          //       option?.relationShipCode
          //         ?.toLowerCase()
          //         ?.includes(value?.toLowerCase() ?? '')
          //     )
          //   )
          // );
          this.filteredIcd9Types = this.claimForm
            .get('txtIcd9Filter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpDiagnosisICD9?.filter((option) =>
                  option?.diagnosisCodeTypeData
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
          this.filteredIcd10Types = this.claimForm
            .get('txtIcd10Filter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpDiagnosisICD10?.filter(
                  (option) =>
                    option?.diagnosisCodeTypeData
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '') ||
                    option?.diagnosisCode
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.getOrderDetails();
        }
      );
    this.subscription$.push(patientDropdowns);
    const providerDetails = this.masterProviderService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpProviders = response?.items ?? [];
          this.drpProvidersList = this.drpProviders;
        },
        (err) => {}
      );
    this.subscription$.push(providerDetails);

    const regionDrpObs = this.regionDropdownService
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .pipe(map((e) => e?.items ?? []));
    // const payerDrpObs = this.payerService.getList(new PagedAndSortedResultRequestDto()).pipe(map((e) => e?.items ?? []));

    const payerDrpObs = this.payerDetailsService
      .getPayersByClearingHouse()
      .pipe(map((e) => e ?? []));
    const doctorDetails = this.doctorService
      .getPhysicianDropdown(defaultGuid, '')
      .subscribe(
        (response) => {
          this.drpDoctors = response;
          // response?.items?.map((x) => {
          //   x['NPIfullName'] = `${x?.npiNumber ?? ''} / ${x?.firstName ?? ''
          //     } ${x?.middleName ?? ''} ${x?.lastName ?? ''}`;
          //   return x;
          // }) ?? [];

          this.filteredDoctorTypes = this.claimForm
            .get('txtDoctorFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpDoctors?.filter((option) =>
                  option?.doctorName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(doctorDetails);

    // const combinedDrpSub = forkJoin([regionDrpObs, payerDrpObs]).subscribe(
    //   (response) => {

    //     const regionDrpResponse = response?.[0] ?? null;
    //     const payerDrpResponse = response?.[1] ?? null;

    //     this.drpCountry = regionDrpResponse ?? [];

    //     this.drpPayers =
    //       payerDrpResponse?.map((x) => {
    //         x['payerCodeName'] = `${x?.payerCode ?? ''} / ${x?.payerName ?? ''
    //           }`;
    //         return x;
    //       }) ?? [];
    //     this.filteredPayerTypes = this.claimForm
    //       .get('txtPayerFilter')
    //       ?.valueChanges.pipe(
    //         startWith(''),
    //         map((value) =>
    //           this.drpPayers?.filter((option) =>
    //             option?.payerCodeName
    //               ?.toLowerCase()
    //               ?.includes(value?.toLowerCase() ?? '')
    //           )
    //         )
    //       );
    //   },
    //   (err) => {
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({ icon: 'info', text: data?.error?.error?.message });
    //   }
    // );
    // this.subscription$.push(combinedDrpSub);

    const payerDetails = this.payerDetailsService
      .getdrpPayerByIMode(0)
      .subscribe(
        (response) => {
          this.drpPayers = response;

          this.drpPayers = response?.map((x) => {
            x['payerShortCodeList'] =
              (x?.payerCode === null || x?.payerCode === ''
                ? '-'
                : x?.payerCode) +
              ' ' +
              '/' +
              ' ' +
              x?.payerName +
              ' ' +
              '/' +
              ' ' +
              (x?.code === null ||
              x?.code === '' ||
              (x?.code).toLowerCase() == 'null'
                ? '-'
                : x?.code);
            return x;
          });
          this.filteredPayerTypes = this.claimForm
            .get('txtPayerFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpPayers?.filter((option) =>
                  option?.payerShortCodeList
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({ icon: 'info', text: data?.error?.error?.message });
        }
      );
    this.subscription$.push(payerDetails);
  }

  searchDoctor(fullName) {
    this.isDocDrpdwnLoading = true;
    if (this.doctorDetails) {
      this.doctorDetails.unsubscribe();
    }
    this.doctorDetails = this.doctorService
      .getPhysicianDropdown(defaultGuid, fullName)
      .subscribe(
        (response) => {
          this.drpDoctors = response;
          // response?.items?.map((x) => {
          //   x['NPIfullName'] = `${x?.npiNumber ?? ''} / ${x?.firstName ?? ''
          //     } ${x?.middleName ?? ''} ${x?.lastName ?? ''}`;
          //   return x;
          // }) ?? [];
          this.isDocDrpdwnLoading = false;
          this.filteredDoctorTypes = this.claimForm
            .get('txtDoctorFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpDoctors?.filter((option) =>
                  option?.doctorName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.isDocDrpdwnLoading = false;
        }
      );
  }
  createFormGroup() {
    this.claimForm = this.formBuilder.group({
      chkMedicare: new FormControl(''),
      chkMedicaid: new FormControl(''),
      chkChampva: new FormControl(''),
      chkTrciare: new FormControl(''),
      chkGroupPlan: new FormControl(''),
      chkFeca: new FormControl(''),
      chkOther: new FormControl(''),
      chkAutoAccident: new FormControl(''),
      txtPolicyNo: new FormControl('', Validators.required),
      txtFullName: new FormControl('', Validators.required),
      drpGender: new FormControl('', Validators.required),
      txtInsuredFullName: new FormControl('', Validators.required),
      txtReservedForNucc: new FormControl(''),
      txtInsReservedForNucc: new FormControl(''),
      txtReservedNucc: new FormControl(''),
      txtPolicyGroup: new FormControl(''),
      txtOtherInsuredPolicy: new FormControl(''),
      txtInsAddress: new FormControl(''),
      chkEmployment: new FormControl(''),
      chkOutsideLab: new FormControl(''),
      chkOtherAccident: new FormControl(''),
      chkClaimCodes: new FormControl(''),
      chkEin: new FormControl(''),
      chkSsn: new FormControl(''),
      txtSigned: new FormControl(''),
      txtInsSigned: new FormControl(''),
      txtDate: new FormControl(''),
      txtFromDate: new FormControl(''),
      txtToDate: new FormControl(''),
      txt17a: new FormControl(''),
      txtCharges: new FormControl(''),
      txtHosFromDate: new FormControl(''),
      txtHosToDate: new FormControl(''),
      txtAdditionalClaimInfo: new FormControl(''),
      txtIcdTen1: new FormControl(''),
      txtIcdTen2: new FormControl(''),
      txtIcdTen3: new FormControl(''),
      txtIcdTen4: new FormControl(''),
      txtIcdTen5: new FormControl(''),
      txtIcdTen6: new FormControl(''),
      txtIcdTen7: new FormControl(''),
      txtIcdTen8: new FormControl(''),
      txtIcdTen9: new FormControl(''),
      txtIcdTen10: new FormControl(''),
      txtIcdTen11: new FormControl(''),
      txtIcdTen12: new FormControl(''),
      txtResubmissionCode: new FormControl(''),
      txtOriginalRefNo: new FormControl(''),
      txtFederalTaxIdNumber: new FormControl(''),
      txtPriorNumber: new FormControl(''),
      txtPtAccountNumber: new FormControl(''),
      chkAcceptAssignment: new FormControl(''),
      txtTotalCharge: new FormControl(''),
      txtAmountPaid: new FormControl(''),
      txtRsvd: new FormControl(''),
      txtPhysicianSigned: new FormControl(''),
      txtPhysicianSignedDate: new FormControl(''),
      txtServiceInfo: new FormControl('', Validators.required),
      txtServiceInfoA: new FormControl(''),
      txtServiceInfoB: new FormControl(''),
      txtBillingProvider: new FormControl(''),
      txtBillingProviderA: new FormControl('', Validators.required),
      txtBillingProviderB: new FormControl(''),
      txtMiddleName: new FormControl(''),
      drpSuffix: new FormControl(''),
      txtTelephone: new FormControl(''),
      txtInsTelephone: new FormControl(''),
      drpCounty: new FormControl(''),
      txtBillingPostalCode: new FormControl('', Validators.required),
      txtBillingPhone: new FormControl(''),
      txtAddress: new FormControl('', Validators.required),
      txtSSN: new FormControl(''),
      drpBillingState: new FormControl(null, Validators.required),
      drpBillingCountry: new FormControl(null),
      drpBillingCity: new FormControl(null, Validators.required),
      drpBillingStateFilter: new FormControl(''),
      drpBillingCityFilter: new FormControl(''),
      txtInsBirthdate: new FormControl(''),
      drpInsGender: new FormControl(''),
      drpResponsibleCity: new FormControl(null),
      drpResponsibleState: new FormControl(null),
      txtRespPostalCode: new FormControl(null),
      txtResponsibleCountry: new FormControl(null),
      // txtInsuredPolicyGroup: new FormControl('', Validators.required),
      txtInsuredPolicyGroup: new FormControl(''),
      drpResponsibleStateFilter: new FormControl(''),
      drpResponsibleCityFilter: new FormControl(''),
      drpProvider: new FormControl('', Validators.required),
      drpDiag9: new FormControl(''),
      txtIcd9Filter: new FormControl(''),
      drpDiag10ID: new FormControl('', [Validators.required,this.maxSelectionValidator(12)]),
      txtIcd10Filter: new FormControl(''),
      txtBirthdate: new FormControl('', Validators.required),
      drpRelationshipType: new FormControl('', Validators.required),
      txtRelationshipType: new FormControl(''),
      txtRenderingDoctor: new FormControl(''),
      txtReferringProviderNPI: new FormControl('', Validators.required),
      drpProviderFilter: new FormControl(''),
      txtDoctorFilter: new FormControl(''),
      txtInsPlanName: new FormControl(null, Validators.required),
      txtPayerFilter: new FormControl(''),
      txtCurrentIllnessDate: new FormControl(''),
      txtOtherDate: new FormControl(''),
      drpReferringDoctor: new FormControl('', Validators.required),
      txtClaimAddress: new FormControl(''),
      drpServiceInfo: new FormControl(''),
      txtInsGender: new FormControl(''),
      txtdrpProvider: new FormControl(''),
    });
  }
  claimDetails: any;
  getOrderDetails() {
    this.isLoadData = false;
    this.isLoading = true;
    if (this.claimId != defaultGuid) {
      const claimProcessDetails = this.claimProcessingService
        .getPatientDetailsforClaimByOrderIdDetails(defaultGuid, this.claimId)
        .subscribe(
          (response) => {
            this.isLoading = false;
            this.claimDetails = response ?? null;
            this.ClaimStatus = response?.claimCurrentStatus;
            this.chartNo = response?.chartNo;
            this.providerId = response?.providerId ?? defaultGuid;
            this.patientName = `${response?.lastName ?? ''} ${
              response?.firstName ?? ''
            }${
              response?.middleName != null && response?.middleName != ''
                ? ''
                : ''
            }${response?.middleName ?? ''}`;
            this.billingInfoArray = response['ltItemCode'];
            for (let billingInfo of this.billingInfoArray) {
              if (billingInfo.pos === defaultGuid) {
                this.billingInfoArray.find(
                  (item) => item.pos === billingInfo.pos
                ).pos = this.pos12;
              }
            }
            // this.billingInfoArray=this.billingInfoArrayV1;
            this.isSaveButtonEnabled();
            const getDiag10ID = (y: string) =>
              this.drpDiagnosisICD10?.filter((x) => x?.diagnosisCode === y)?.[0]
                ?.diagnosisCode ?? '';
            const diag: string[] = response?.diagnosisCode10 ?? [];
            this.priPolicyId = response?.priPolicyId
              ? response?.priPolicyId
              : defaultGuid;
            this.claimForm.patchValue({
              txtPolicyNo: response?.priPolicyNo,
              // txtFullName: `${response?.lastName ?? ''},${
              //   response?.firstName ?? ''
              // }${
              //   response?.middleName != null && response.middleName != ''
              //     ? ','
              //     : ''
              // }${response?.middleName ?? ''}`,
              // txtInsuredFullName: `${response?.lastName ?? ''},${
              //   response?.firstName ?? ''
              // }${
              //   response?.middleName != null && response.middleName != ''
              //     ? ','
              //     : ''
              // }${response?.middleName ?? ''}`,
              txtFullName: this.data.fullName || '-',
              txtInsuredFullName: this.data.fullName || '-',
              txtBirthdate: response?.dateOfBirth ?? '',
              chkMedicare: response?.isMedicare ?? false,
              chkMedicaid: response?.isMedicaid ?? false,
              chkTrciare: response?.isTricare ?? false,
              chkChampva: response?.isChampva ?? false,
              chkGroupPlan: response?.isGroupHealthPlan ?? false,
              chkFeca: response?.isFecaBlkLung ?? false,
              chkOther: response?.isOther ?? false,
              chkEmployment: response?.isEmployment ?? false,
              chkAutoAccident: response?.isAutoAccident ?? false,
              chkOtherAccident: response?.isOtherAccident ?? false,
              chkClaimCodes: response?.isClaimCodes ?? false,
              txtSigned: response?.patientSignature ?? '',
              txtDate: response?.signatureDate ?? '',
              // txtSigned: response?.insuredSignature ?? '',
              txtInsSigned: response?.insuredSignature ?? '',
              txtCurrentIllnessDate: response?.dateOfCurrentIllness ?? '',
              txtFromDate: response?.workUnabledFromDate ?? '',
              txtToDate: response?.workUnabledToDate ?? '',
              txtHosFromDate: response?.hospitalizationFromDate ?? '',
              txtHosToDate: response?.hospitalizationToDate ?? '',
              txtAdditionalClaimInfo:
                response?.additionalClaimInformation ?? '',
              chkOutsideLab: response?.outSideLab ?? '',
              txtCharges: response?.charges ?? 0,
              chkSsn: response?.isSSN ?? false,
              chkEin: response?.isEIN ?? false,
              txtPtAccountNumber: response?.patientAccountNumber ?? '',
              chkAcceptAssignment: response?.isAcceptAssignment ?? false,
              txtAmountPaid: response?.amountPaid ?? 0,
              txtReservedForNucc: response?.rsvdforNUCCUse ?? '',
              txtPhysicianSignedDate: response?.physicianSignatureDate ?? '',
              txtOriginalRefNo: response?.originalRefNo ?? '',
              txtPriorNumber: response?.priorAuthorizationNumber ?? '',
              txtInsAddress: response?.insuredaddress ?? '',
              drpResponsibleState: response?.insuredstate ?? '',
              drpResponsibleCity: response?.insuredcity ?? '',
              txtRespPostalCode: response?.insuredpostalCode ?? '',
              txtInsTelephone:
                response?.insuredPhoneNumber === '' ||
                response?.insuredPhoneNumber === null
                  ? ''
                  : response?.insuredPhoneNumber,
              txtBillingProviderB: response?.billingProviderComments2 ?? '',
              txtOtherInsuredPolicy: response?.reservedforNUCCUse1 ?? '',
              txtReservedNucc: response?.reservedforNUCCUse2 ?? '',
              txtOtherDate: response?.otherDate ?? '',
              txtResubmissionCode: response?.resubmissionCode ?? '',
              txtInsBirthdate: response?.insuredDateofBirth ?? '',
              drpInsGender: response?.insuredGender ?? '',
              // txtBirthdate: response?.dateOfBirth ?? '',
              drpGender: response?.gender ?? '',
              txtBillingProvider: response?.providerName ?? '',
              txtBillingProviderA: response?.providerNPI ?? '',
              txtFederalTaxIdNumber: response?.providerTaxID ?? '',
              drpProvider: response?.providerShortCodeId ?? '',
              txtAddress: response?.address ?? '',
              txtTelephone:
                response?.homePhoneNo === '' || response?.homePhoneNo == null
                  ? null
                  : response?.homePhoneNo,
              txtBillingPostalCode: response?.postalCode,
              drpRelationshipType: response?.priRelationship ?? '',
              txtInsPlanName: response?.priPayerIdPolicyId ?? '',
              drpReferringDoctor:
                response?.referringProviderDoctorId === defaultGuid
                  ? null
                  : response?.referringProviderDoctorId,
              txt17a: response?.referringProviderDoctorName ?? '',
              txtReferringProviderNPI:
                response?.referringProviderDoctorNPI === defaultGuid
                  ? null
                  : response?.referringProviderDoctorNPI,
              txtServiceInfo:
                response?.serviceFacilityLocation === defaultGuid
                  ? this.pos12
                  : response?.serviceFacilityLocation,
              // txtServiceInfoA: response?.serviceFacilityComments1 ?? '',
              // txtServiceInfoB: response?.serviceFacilityComments2 ?? '',
              txtInsReservedForNucc: response?.otherInsuredName ?? '',
              txtPolicyGroup: response?.otherInsuredPolicyGroupNumber ?? '',
              txtInsuredPolicyGroup: response?.priGroupNo,
              txtRsvd: response?.rsvdforNUCCUse ?? '',
              drpDiag10ID: response?.diagnosisCode10 ?? [],
              txtTotalCharge: response?.totalCharge ?? null,
              // txtIcdTen1: (diag?.[0] && getDiag10ID(diag?.[0])) ?? '',
              txtIcdTen1: diag?.[0] ?? '',
              txtIcdTen2: diag?.[1] ?? '',
              txtIcdTen3: diag?.[2] ?? '',
              txtIcdTen4: diag?.[3] ?? '',
              txtIcdTen5: diag?.[4] ?? '',
              txtIcdTen6: diag?.[5] ?? '',
              txtIcdTen7: diag?.[6] ?? '',
              txtIcdTen8: diag?.[7] ?? '',
              txtIcdTen9: diag?.[8] ?? '',
              txtIcdTen10: diag?.[9] ?? '',
              txtIcdTen11: diag?.[10] ?? '',
              txtIcdTen12: diag?.[11] ?? '',
            });
            this.calculateToatalChargeSum();
            //Patch State Values
            if (response?.state) {
              response?.state && this.onChangeBillState(response?.country);
              this.claimForm.patchValue({
                drpBillingState: response?.state ?? '',
              });
              this.onChangeRespState(response?.country);
            } else {
              this.onChangeBillState();
              this.onChangeRespState();
            }
            //Patch Insured City Value
            response?.insuredcity &&
              this.onChangeResCity(response?.insuredstate, response?.country);
            this.claimForm.patchValue({
              drpResponsibleCity: response?.insuredcity ?? '',
            });

            //Patch City Values
            response?.city &&
              this.onChangeBillCity(response?.state, response?.country);
            this.claimForm.patchValue({
              drpBillingCity: response?.city ?? '',
            });
            if (
              this.drpPlaceOfSerivce !== undefined &&
              this.drpPlaceOfSerivce !== null &&
              this.drpPlaceOfSerivce.length > 0
            ) {
              const serviceFacility = this.drpPlaceOfSerivce.filter(
                (x) =>
                  x.id ==
                  (response?.serviceFacilityLocation === defaultGuid
                    ? this.pos12
                    : response?.serviceFacilityLocation)
              );
              this.claimForm.patchValue({
                txtServiceInfoA: serviceFacility?.[0].placeOfServiceType,
                txtServiceInfoB:
                  serviceFacility?.[0].placeOfServiceTypeShortCodeId,
              });
            }
            setTimeout(() => {
              this.isLoadData = true;
            }, 500);
          },
          (err) => {
            this.isLoadData = true;
            this.isLoading = false;
          }
        );
      this.subscription$.push(claimProcessDetails);
    } else {
      const claimProcessDetails = this.claimProcessingService
        .getPatientDetailsforClaimByOrderIdDetails(this.orderId, defaultGuid)
        .subscribe(
          (response) => {
            // this.isLoadData = true;
            this.isLoading = false;
            this.ClaimStatus = response?.claimCurrentStatus;
            this.billingInfoArray = response['ltItemCode'];
            for (let billingInfo of this.billingInfoArray) {
              if (billingInfo.pos === defaultGuid) {
                this.billingInfoArray.find(
                  (item) => item.pos === billingInfo.pos
                ).pos = this.pos12;
              }
            }
            // this.billingInfoArray=this.billingInfoArrayV1;
            this.isSaveButtonEnabled();
            const getDiag10ID = (y: string) =>
              this.drpDiagnosisICD10?.filter((x) => x?.diagnosisCode === y)?.[0]
                ?.diagnosisCode ?? '';
            const diag: string[] = response?.diagnosisCode10 ?? [];
            this.priPolicyId = response?.priPolicyId
              ? response?.priPolicyId
              : defaultGuid;
            this.claimForm.patchValue({
              txtPolicyNo: response?.priPolicyNo,
              // txtFullName: `${response?.lastName ?? ''},${
              //   response?.firstName ?? ''
              // }${
              //   response?.middleName != null && response.middleName != ''
              //     ? ','
              //     : ''
              // }${response?.middleName ?? ''}`,
              txtFullName: this.data.fullName || '-',
              txtInsuredFullName: `${response?.lastName ?? ''},${
                response?.firstName ?? ''
              }${
                response?.middleName != null && response?.middleName != ''
                  ? ','
                  : ''
              }${response?.middleName ?? ''}`,
              chkMedicare: response?.isMedicare ?? false,
              chkMedicaid: response?.isMedicaid ?? false,
              chkTrciare: response?.isTricare ?? false,
              chkChampva: response?.isChampva ?? false,
              chkGroupPlan: response?.isGroupHealthPlan ?? false,
              chkFeca: response?.isFecaBlkLung ?? false,
              chkOther: response?.isOther ?? false,
              chkEmployment: response?.isEmployment ?? false,
              chkAutoAccident: response?.isAutoAccident ?? false,
              chkOtherAccident: response?.isOtherAccident ?? false,
              chkClaimCodes: response?.isClaimCodes ?? false,
              txtSigned: response?.patientSignature ?? '',
              txtDate: response?.signatureDate ?? '',
              drpInsGender: response?.insuredGender ?? '',
              // txtSigned: response?.insuredSignature ?? '',
              txtInsSigned: response?.insuredSignature ?? '',
              txtCurrentIllnessDate: response?.dateOfCurrentIllness ?? '',
              txtFromDate: response?.workUnabledFromDate ?? '',
              txtToDate: response?.workUnabledToDate ?? '',
              txtHosFromDate: response?.hospitalizationFromDate ?? '',
              txtHosToDate: response?.hospitalizationToDate ?? '',
              txtAdditionalClaimInfo:
                response?.additionalClaimInformation ?? '',
              chkOutsideLab: response?.outSideLab ?? '',
              txtCharges: response?.charges ?? 0,
              chkSsn: response?.isSSN ?? false,
              chkEin: response?.isEIN ?? false,
              txtPtAccountNumber: response?.patientAccountNumber ?? '',
              chkAcceptAssignment: response?.isAcceptAssignment ?? false,
              txtAmountPaid: response?.amountPaid ?? 0,
              txtReservedForNucc: response?.rsvdforNUCCUse ?? '',
              txtPhysicianSignedDate: response?.physicianSignatureDate ?? '',
              txtOriginalRefNo: response?.originalRefNo ?? '',
              txtPriorNumber: response?.priorAuthorizationNumber ?? '',
              txtInsAddress: response?.insuredaddress ?? '',
              drpResponsibleState: response?.insuredstate ?? '',
              drpResponsibleCity: response?.insuredcity ?? '',
              txtRespPostalCode: response?.insuredpostalCode ?? '',
              txtInsTelephone:
                response?.insuredPhoneNumber == '' ||
                response?.insuredPhoneNumber == null
                  ? ''
                  : response?.insuredPhoneNumber,
              txtBillingProviderB: response?.billingProviderComments2 ?? '',
              txtOtherInsuredPolicy: response?.reservedforNUCCUse1 ?? '',
              txtReservedNucc: response?.reservedforNUCCUse2 ?? '',
              txtOtherDate: response?.otherDate ?? '',
              txtResubmissionCode: response?.resubmissionCode ?? '',
              txtInsBirthdate: response?.insuredDateofBirth ?? '',
              txtBirthdate: response?.dateOfBirth ?? '',
              drpGender: response?.gender ?? '',
              txtBillingProvider: response?.providerName ?? '',
              txtBillingProviderA: response?.providerNPI ?? '',
              txtFederalTaxIdNumber: response?.providerTaxID ?? '',
              drpProvider: response?.providerShortCodeId ?? '',
              txtAddress: response?.address ?? '',
              txtTelephone: response?.homePhoneNo,
              txtBillingPostalCode: response?.postalCode,
              drpRelationshipType: response?.priRelationship ?? '',
              txtInsPlanName: response?.priPayerIdPolicyId ?? '',
              drpReferringDoctor:
                response?.referringProviderDoctorId === defaultGuid
                  ? null
                  : response?.referringProviderDoctorId,
              txtReferringProviderNPI:
                response?.referringProviderDoctorNPI === defaultGuid
                  ? null
                  : response?.referringProviderDoctorNPI,
              txtServiceInfo:
                response?.serviceFacilityLocation === defaultGuid
                  ? this.pos12
                  : response?.serviceFacilityLocation,
              // txtServiceInfoA: response?.serviceFacilityComments1 ?? '',
              // txtServiceInfoB: response?.serviceFacilityComments2 ?? '',
              txtInsReservedForNucc: response?.otherInsuredName ?? '',
              txtPolicyGroup: response?.otherInsuredPolicyGroupNumber ?? '',
              txt17a: response?.referringProviderDoctorName ?? '',
              txtInsuredPolicyGroup: response?.priGroupNo,
              txtRsvd: response?.rsvdforNUCCUse ?? '',
              drpDiag10ID: response?.diagnosisCode10 ?? [],
              txtTotalCharge: response?.totalCharge ?? null,
              txtIcdTen1: (diag?.[0] && getDiag10ID(diag?.[0])) ?? '',
              txtIcdTen2: (diag?.[1] && getDiag10ID(diag?.[1])) ?? '',
              txtIcdTen3: (diag?.[2] && getDiag10ID(diag?.[2])) ?? '',
              txtIcdTen4: (diag?.[3] && getDiag10ID(diag?.[3])) ?? '',
              txtIcdTen5: (diag?.[4] && getDiag10ID(diag?.[4])) ?? '',
              txtIcdTen6: (diag?.[5] && getDiag10ID(diag?.[5])) ?? '',
              txtIcdTen7: (diag?.[6] && getDiag10ID(diag?.[6])) ?? '',
              txtIcdTen8: (diag?.[7] && getDiag10ID(diag?.[7])) ?? '',
              txtIcdTen9: (diag?.[8] && getDiag10ID(diag?.[8])) ?? '',
              txtIcdTen10: (diag?.[9] && getDiag10ID(diag?.[9])) ?? '',
              txtIcdTen11: (diag?.[10] && getDiag10ID(diag?.[10])) ?? '',
              txtIcdTen12: (diag?.[11] && getDiag10ID(diag?.[11])) ?? '',
            });
            if (
              this.drpPlaceOfSerivce !== undefined &&
              this.drpPlaceOfSerivce !== null &&
              this.drpPlaceOfSerivce.length > 0
            ) {
              const serviceFacility = this.drpPlaceOfSerivce.filter(
                (x) =>
                  x.id ==
                  (response?.serviceFacilityLocation === defaultGuid
                    ? this.pos12
                    : response?.serviceFacilityLocation)
              );
              this.claimForm.patchValue({
                txtServiceInfoA: serviceFacility?.[0].placeOfServiceType,
                txtServiceInfoB:
                  serviceFacility?.[0].placeOfServiceTypeShortCodeId,
              });
            }
            this.calculateToatalChargeSum();
            //Patch State Values
            if (response?.state) {
              response?.state && this.onChangeBillState(response?.country);
              this.claimForm.patchValue({
                drpBillingState: response?.state ?? '',
              });
              this.onChangeRespState(response?.country);
            } else {
              this.onChangeBillState();
              this.onChangeRespState();
            }
            //Patch Insured City Value
            response?.insuredcity &&
              this.onChangeResCity(response?.insuredstate, response?.country);
            this.claimForm.patchValue({
              drpResponsibleCity: response?.insuredcity ?? '',
            });
            //Patch City Values
            response?.city &&
              this.onChangeBillCity(response?.state, response?.country);
            this.claimForm.patchValue({
              drpBillingCity: response?.city ?? '',
            });
            setTimeout(() => {
              this.isLoadData = true;
            }, 500);
          },
          (err) => {
            this.isLoadData = true;
            this.isLoading = false;
          }
        );
      this.subscription$.push(claimProcessDetails);
    }
  }
  drpDownMessage() {
    if (this.isDocDrpdwnLoading === false) {
      return 'No Matches found';
    } else {
      return 'Loading...';
    }
  }
  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }

  onChangePolicyFeild(value: string = '') {
    const selPolicy =
      this.drpActivePolicies?.filter((x) => x?.id === value)?.[0] ?? null;
    if (selPolicy) {
      const zirmedPayor =
        this.drpPayers?.filter(
          (x) => x?.payerId === selPolicy?.zirmedPayorId
        )?.[0] ?? null;
      const fullName = `${selPolicy?.lastName ?? ''} , ${
        selPolicy?.firstName ?? ''
      }, ${selPolicy?.middleName ?? ''}`;
      this.claimForm.patchValue({
        drpPolicyNo: selPolicy?.id ?? '',
        txtInsuredFullName: fullName,
        txtInsBirthdate: selPolicy?.birthdate ?? '',
        drpInsGender: selPolicy?.gender ?? '',
        txtInsPlanName: zirmedPayor?.payerCodeName ?? '',
        txtRespPostalCode: selPolicy?.postalCode ?? '',
        txtInsTelephone: selPolicy?.phone ?? '',
        txtInsAddress: selPolicy?.address ?? '',
        drpRelationshipType: selPolicy?.relationship ?? '',
        chkEin: true, //default checked
      });
    }
  }
  onChangeProvidersUpdate(value) {
    const provider = this.drpProviders.filter((x) => x.shortCodeId == value);
    this.claimForm.patchValue({
      txtBillingProvider: provider[0].firstName,
      txtBillingProviderA: provider[0].npi,
      txtBillingProviderB: provider[0].lastName,
    });
  }
  // Method to calculate the Total Charge
  calculateToatalChargeSum() {
    this.totalCharge = this.billingInfoArray.reduce(
      (sum, item) => sum + parseFloat(item.charges || 0),
      0
    );
    // this.claimForm.patchValue({
    //   txtTotalCharge: this.totalCharge,
    // });
  }
  // billing  state, city
  onChangeBillState(value: string = defaultCountry.US) {
    this.drpInsuredStates = [];
    value &&
      this.regionDropdownService
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpBillingStates = response?.items ?? [];
            this.filteredBillingStates = this.claimForm
              .get('drpBillingStateFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpBillingStates?.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
  }
  // Responible state, city
  onChangeRespState(value: string = defaultCountry.US) {
    this.drpInsuredStates = [];
    value &&
      this.regionDropdownService
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpBillingStates = response?.items ?? [];

            this.filteredResponsibleStates = this.claimForm
              .get('drpResponsibleStateFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpBillingStates?.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
  }
  // Provider
  onChangeProvider(event) {
    const provider = this.drpDoctors.filter((x) => x.id == event.value);
    this.claimForm.patchValue({
      txt17a: provider[0].fullName,
      txtReferringProviderNPI: provider[0].npi,
    });
  }

  // Service Facolity
  onChangeServiceFacility(event) {
    const serviceFacility = this.drpPlaceOfSerivce.filter(
      (x) => x.id == event.value
    );
    this.claimForm.patchValue({
      txtServiceInfoA: serviceFacility[0].placeOfServiceType,
      txtServiceInfoB: serviceFacility[0].placeOfServiceTypeShortCodeId,
    });
  }
  // billing state, city
  onChangeBillCity(state: string = '', country: string = defaultCountry.US) {
    this.drpBillingCities = [];
    this.regionDropdownService
      .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
        country,
        state
      )
      .subscribe(
        (response) => {
          this.drpBillingCities = response?.items ?? [];
          this.filteredBillingCities = this.claimForm
            .get('drpBillingStateFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpBillingCities?.filter((option) =>
                  option?.cityName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  onChangeResCity(state: string = '', country: string = defaultCountry.US) {
    this.drpResCities = [];
    (state ?? '') !== '' &&
      this.regionDropdownService
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpResCities = response?.items ?? [];
            this.phoneCode = response?.items[0]?.countryPhoneCode;
            this.filteredResponsibleCities = this.claimForm
              .get('drpResponsibleCityFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpResCities?.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
  }

  saveClaimDetails() {
    this.saveButtonHide = true;
    this.isSaveLoading = true;
    // if (this.claimForm.valid){
    const itemdetails = this.billingInfoArray;

    const claimDetails: CreateUpdateClaimFormDetailsDTO = {
      tenantId: this.tenantId ? this.tenantId : defaultGuid,
      claimId:
        this.claimDetails?.claimNumber == null || this.claimDetails?.claimNumber
          ? 0
          : this.claimDetails?.claimNumber,
      patientId: this.patientId ? this.patientId : defaultGuid,
      defaultPatientId: '',
      isMedicare: this.claimForm.value.chkMedicare
        ? this.claimForm.value.chkMedicare
        : false,
      isMedicaid: this.claimForm.value.chkMedicaid
        ? this.claimForm.value.chkMedicaid
        : false,
      isTricare: this.claimForm.value.chkTrciare
        ? this.claimForm.value.chkTrciare
        : false,
      isChampva: this.claimForm.value.chkChampva
        ? this.claimForm.value.chkChampva
        : false,
      isGroupHealthPlan: this.claimForm.value.chkGroupPlan
        ? this.claimForm.value.chkGroupPlan
        : false,
      isFecaBlkLung: this.claimForm.value.chkFeca
        ? this.claimForm.value.chkFeca
        : false,
      isOther: this.claimForm.value.chkOther
        ? this.claimForm.value.chkOther
        : false,
      insuredIDNumber: this.claimForm.value.txtPolicyNo ?? '',
      patientName: this.claimForm.value.txtFullName ?? '',
      dateOfBirth: this.claimForm.value.txtBirthdate
        ? this.claimForm.value.txtBirthdate
        : null,
      gender: this.claimForm.value.drpGender ?? '',
      address: this.claimForm.value.txtAddress ?? '',
      city: this.claimForm.value.drpBillingCity
        ? this.claimForm.value.drpBillingCity
        : defaultGuid,
      state: this.claimForm.value.drpBillingState
        ? this.claimForm.value.drpBillingState
        : defaultGuid,
      postalCode: this.claimForm.value.txtBillingPostalCode ?? '',
      phone: this.claimForm.value.txtTelephone ?? '',
      relationship: this.claimForm.value.drpRelationshipType
        ? this.claimForm.value.drpRelationshipType
        : defaultGuid,
      insuredName: this.claimForm.value.txtInsuredFullName ?? '',
      insuredDateOfBirth: this.claimForm.value.txtInsBirthdate ?? '',
      insuredGender: this.claimForm.value.drpInsGender ?? '',
      insuredAddress: this.claimForm.value.txtInsAddress ?? '',
      insuredCity: this.claimForm.value.drpResponsibleCity
        ? this.claimForm.value.drpResponsibleCity
        : defaultGuid,
      insuredState: this.claimForm.value.drpResponsibleState
        ? this.claimForm.value.drpResponsibleState
        : defaultGuid,
      insuredPostalCode: this.claimForm.value.txtRespPostalCode ?? '',
      insuredPhone: this.claimForm.value.txtInsTelephone ?? '',
      reservedforNUCC: this.claimForm.value.txtReservedForNucc ?? '',
      otherInsuredName: this.claimForm.value.txtInsReservedForNucc ?? '',
      otherInsuredPolicyGroupNumber: this.claimForm.value.txtPolicyGroup ?? '',
      reservedforNUCCUse1: this.claimForm.value.txtOtherInsuredPolicy ?? '',
      reservedforNUCCUse2: this.claimForm.value.txtReservedNucc ?? '',
      InsurancePlanId: this.priPolicyId ? this.priPolicyId : defaultGuid,
      isEmployment: this.claimForm.value.chkEmployment
        ? this.claimForm.value.chkEmployment
        : false,
      isAutoAccident: this.claimForm.value.chkAutoAccident
        ? this.claimForm.value.chkAutoAccident
        : false,
      isOtherAccident: this.claimForm.value.chkOtherAccident
        ? this.claimForm.value.chkOtherAccident
        : false,
      otherDate: this.claimForm.value.txtOtherDate ?? null,
      isClaimCodes: this.claimForm.value.chkClaimCodes
        ? this.claimForm.value.chkClaimCodes
        : false,
      insuredPolicyNo: this.claimForm.value.txtInsPlanName
        ? this.claimForm.value.txtInsPlanName
        : defaultGuid,
      otherClaimId: this.claimForm.value.txtInsPlanName
        ? this.claimForm.value.txtInsPlanName
        : defaultGuid,
      insurancePlanOrProgName: this.claimForm.value.txtInsPlanName
        ? this.claimForm.value.txtInsPlanName
        : defaultGuid,
      healthBenefitPlan: this.claimForm.value.txtInsPlanName
        ? this.claimForm.value.txtInsPlanName
        : defaultGuid,
      patientSignature: this.claimForm.value.txtSigned ?? '',
      signatureDate: this.claimForm.value.txtDate ?? null,
      insuredSignature: this.claimForm.value.txtSigned ?? '',
      dateOfCurrentIllness: this.claimForm.value.txtCurrentIllnessDate
        ? this.claimForm.value.txtCurrentIllnessDate
        : null,
      workUnabledFromDate: this.claimForm.value.txtFromDate ?? '',
      workUnabledToDate: this.claimForm.value.txtToDate ?? '',
      referringProvider: this.claimForm.value.drpReferringDoctor
        ? this.claimForm.value.drpReferringDoctor
        : defaultGuid,
      referringProviderComments: this.claimForm.value.txt17a ?? '',
      referringProviderNPI: this.claimForm.value.txtReferringProviderNPI
        ? this.claimForm.value.txtReferringProviderNPI
        : defaultGuid,
      hospitalizationFromDate: this.claimForm.value.txtHosFromDate
        ? this.claimForm.value.txtHosFromDate
        : null,
      hospitalizationToDate: this.claimForm.value.txtHosToDate
        ? this.claimForm.value.txtHosToDate
        : null,
      additionalClaimInformation:
        this.claimForm.value.txtAdditionalClaimInfo ?? '',
      outSideLab: this.claimForm.value.chkOutsideLab ?? '',
      charges: this.claimForm.value.txtCharges
        ? this.claimForm.value.txtCharges
        : 0,
      diagnosisCode10: this.claimForm.value.drpDiag10ID
        ? this.claimForm.value.drpDiag10ID
        : defaultGuid,
      icdComments1: this.claimForm.value.txtIcdTen1 ?? '',
      icdComments2: this.claimForm.value.txtIcdTen2 ?? '',
      icdComments3: this.claimForm.value.txtIcdTen3 ?? '',
      icdComments4: this.claimForm.value.txtIcdTen4 ?? '',
      icdComments5: this.claimForm.value.txtIcdTen5 ?? '',
      icdComments6: this.claimForm.value.txtIcdTen6 ?? '',
      icdComments7: this.claimForm.value.txtIcdTen7 ?? '',
      icdComments8: this.claimForm.value.txtIcdTen8 ?? '',
      icdComments9: this.claimForm.value.txtIcdTen9 ?? '',
      icdComments10: this.claimForm.value.txtIcdTen10 ?? '',
      icdComments11: this.claimForm.value.txtIcdTen11 ?? '',
      icdComments12: this.claimForm.value.txtIcdTen12 ?? '',
      resubmissionCode: this.claimForm.value.txtResubmissionCode ?? '',
      originalRefNo: this.claimForm.value.txtOriginalRefNo ?? '',
      priorAuthorizationNumber: this.claimForm.value.txtPriorNumber ?? '',
      federalTaxIDNumber: this.claimForm.value.txtFederalTaxIdNumber ?? '',
      isSSN: this.claimForm.value.chkSsn ? this.claimForm.value.chkSsn : false,
      isEIN: this.claimForm.value.chkEin ? this.claimForm.value.chkEin : false,
      patientAccountNumber: this.claimForm.value.txtPtAccountNumber ?? '',
      isAcceptAssignment: this.claimForm.value.chkAcceptAssignment
        ? this.claimForm.value.chkAcceptAssignment
        : false,
      totalCharge: this.claimForm.value.txtTotalCharge
        ? this.claimForm.value.txtTotalCharge
        : 0,
      amountPaid: this.claimForm.value.txtAmountPaid
        ? this.claimForm.value.txtAmountPaid
        : 0,
      rsvdforNUCCUse: this.claimForm.value.txtRsvd ?? '',
      physicianSignature: this.claimForm.value.drpProvider
        ? this.claimForm.value.drpProvider
        : defaultGuid,
      physicianSignatureDate: this.claimForm.value.txtPhysicianSignedDate
        ? this.claimForm.value.txtPhysicianSignedDate
        : null,
      serviceFacilityLocation: this.claimForm.value.txtServiceInfo
        ? this.claimForm.value.txtServiceInfo
        : defaultGuid,
      serviceFacilityComments1: this.claimForm.value.txtServiceInfoA ?? '',
      serviceFacilityComments2: this.claimForm.value.txtServiceInfoB ?? '',
      billingProviderInfo: this.claimForm.value.txtBillingProvider
        ? this.claimForm.value.txtBillingProvider
        : '',
      billingProviderComments1: this.claimForm.value.txtBillingProviderA
        ? this.claimForm.value.txtBillingProviderA
        : '',
      billingProviderComments2: this.claimForm.value.txtBillingProviderB
        ? this.claimForm.value.txtBillingProviderB
        : '',
      claimAddress: '',
      ltItemCode: itemdetails,
      isActive: false,
      orderId: this.orderId ?? defaultGuid,
      defaultSaleOrderId: this.claimDetails?.defaultSaleOrderId ?? '',
      waystarClaimStatus: this.claimDetails?.waystarClaimStatus ?? '',
      waystarClaimStatusLastUpdatedTime:
        this.claimDetails?.waystarClaimStatusLastUpdatedTime ?? null,
      claimProcessedDate: this.claimDetails?.claimProcessedDate ?? null,
      resubmitCount: 0,
      POS: this.claimForm.value.txtServiceInfo ?? defaultGuid,
    };
    this.loaderService.showLoader();
    if (this.claimId == defaultGuid) {
      this.claimFormDetailsService.create(claimDetails).subscribe(
        (response) => {
          this.toastr.success('Claim Saved Successfully', 'Suceess');
          // this.dialog.closeAll()
          this.loaderService.hideLoader();
          this.claimViewModelRef.close(response);

          this.saveButtonHide = false;
          this.isSaveLoading = false;
        },
        (err) => {
          this.saveButtonHide = false;
          this.isSaveLoading = false;
          this.loaderService.hideLoader();
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else if (this.claimId != defaultGuid) {
      this.isSaveLoading = false;
      this.saveButtonHide = false;
      this.claimFormDetailsService.update(this.claimId, claimDetails).subscribe(
        (response) => {
          // this.toastr.success('Claim Updated Successfully', 'Suceess');
          // this.dialog.closeAll()
          this.data.isResubmit = this.data.isResubmit ?? false;
          if (this.data.isResubmit) {
            this.resubmitZirmedClaim();
          } else {
            this.toastr.success('Claim Updated Successfully', 'Suceess');
            this.loaderService.hideLoader();
            this.claimViewModelRef.close(response);
          }
        },
        (err) => {
          this.saveButtonHide = false;
          this.isSaveLoading = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.loaderService.hideLoader();
        }
      );
    }
    // }else{
    //  this.submitted = true;
    //  this.claimForm.markAsTouched();
    // }
  }
  isSaveButtonEnabled() {
    if (this.billingInfoArray) {
      const isSavebtn = this.billingInfoArray.filter(
        (x) =>
          x.pos === defaultGuid ||
          x.pos === null ||
          x.pos === '' ||
          x.modifiers1 === defaultGuid ||
          x.modifiers1 === null ||
          x.modifiers1 === ''
      );
      if (isSavebtn.length === 0) {
        this.isSavebtn = false;
      } else {
        this.isSavebtn = true;
      }
    }
  }

  onCloseClick(): void {
    // this.notesDialogRef.close([]);

    this.claimViewModelRef.close([]);
    // Add any additional logic you want here
  }

  filterModifier(): void {
    const searchValue = this.searchControl.value.toLowerCase();
    this.filteredOptions = this.ltModifiers.filter((option) =>
      option.mmRefId.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  loadModifire(no: number) {
    const productsList = this.mmModifierservice
      .getAllModifiersActiveList()
      .subscribe(
        (response) => {
          this.ltModifiers = response;
          this.filteredOptions = this.ltModifiers.sort((a, b) =>
            a.mmRefId.localeCompare(b.mmRefId)
          );
        },
        (err) => {}
      );
    this.subscription$.push(productsList);
    if (no === 1) {
      this.isSaveButtonEnabled();
    }
  }

  filterPOS(): void {
    const searchValue = this.searchControl.value.toLowerCase();
    this.filteredPOS = this.ltPOS.filter((option) =>
      option.placeOfServiceType
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );
  }
  loadPosDropDown() {
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.PlaceOfServices,
    ];
    this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe((response) => {
        this.ltPOS = response['placeOfServices'];
        this.filteredPOS = this.ltPOS.sort((a, b) =>
          a.placeOfServiceType.localeCompare(b.placeOfServiceType)
        );

        this.filteredPOS = [...this.ltPOS];
        this.pos12 = this.filteredPOS.find(
          (item) => item.placeOfServiceTypeShortCodeId === '12'
        ).id;
      });
    this.isSaveButtonEnabled();
  }

  onSelectionChange(event) {
    this.claimForm.patchValue({
      txtIcdTen1: event.value[0],
      txtIcdTen2: event.value[1],
      txtIcdTen3: event.value[2],
      txtIcdTen4: event.value[3],
      txtIcdTen5: event.value[4],
      txtIcdTen6: event.value[5],
      txtIcdTen7: event.value[6],
      txtIcdTen8: event.value[7],
      txtIcdTen9: event.value[8],
      txtIcdTen10: event.value[9],
      txtIcdTen11: event.value[10],
      txtIcdTen12: event.value[11],
    });
  }

  clearGender() {
    this.strGender = '';
    this.claimForm.patchValue({ drpGender: '' });
  }
  clearBillingState() {
    this.strBillingState = '';
    this.claimForm.patchValue({ drpBillingState: '' });
  }
  clearBillingCity() {
    this.strBillingCity = '';
    this.claimForm.patchValue({ drpBillingCity: '' });
  }
  clearRelationship() {
    this.strRelationship = '';
    this.claimForm.patchValue({ drpRelationshipType: '' });
  }
  clearResponsibleState() {
    this.strResponsibleState = '';
    this.claimForm.patchValue({ drpResponsibleState: '' });
  }
  clearResponsibleCity() {
    this.strResponsibleCity = '';
    this.claimForm.patchValue({ drpResponsibleCity: '' });
  }
  clearPayers() {
    this.claimForm.patchValue({ txtPolicyInfoPayor: '' });
  }
  clearInsGender() {
    this.strGender = '';
    this.claimForm.patchValue({ drpInsGender: '' });
  }
  clearReferringDoctor() {
    this.strReferringDoctor = '';
    this.claimForm.patchValue({ drpReferringDoctor: '' });
  }
  clearIcd10() {
    this.lstIcd10 = [];
    this.claimForm.patchValue({ drpDiag10ID: [],
      txtIcdTen1:   '',
      txtIcdTen2:  '',
      txtIcdTen3:  '',
      txtIcdTen4:  '',
      txtIcdTen5:  '',
      txtIcdTen6:  '',
      txtIcdTen7:  '',
      txtIcdTen8:  '',
      txtIcdTen9:  '',
      txtIcdTen10: '',
      txtIcdTen11: '',
      txtIcdTen12: '',

     });
  }
  clearInspolicyNo() {
    this.claimForm.patchValue({
      drpPolicyNo: '',
      txtInsuredFullName: '',
      txtInsBirthdate: '',
      drpInsGender: '',
      txtInsPlanName: '',
      txtRespPostalCode: '',
      txtInsTelephone: '',
      txtInsAddress: '',
    });
  }
  clearProvider() {
    this.strProvider = '';
    this.claimForm.patchValue({ drpProvider: '' });
  }
  clearPlaceOfService() {
    this.strPlaceOfService = '';
    this.claimForm.patchValue({
      txtServiceInfo: '',
    });
  }
  openPatientDetiles(patientId: string) {}
  captureErrorMsg(data: HttpErrorResponse) {
    try {
      if (typeof data?.error === 'object') {
        const valditionerror: { message: string }[] =
          data?.error?.error?.validationErrors ?? [];
        if (
          valditionerror &&
          valditionerror?.[0] &&
          valditionerror?.[0]?.message?.includes('is required')
        ) {
          this.totalValue = [];
          valditionerror?.forEach((val: { message: any }) => {
            this.messageValue = val.message;
            this.totalValue?.push(this.messageValue.concat('\r\n'));
          });
          Swal.fire({
            icon: 'info',
            html: `<pre style="text-align: -webkit-left;">${this.totalValue?.join(
              '\r\n'
            )}</pre>`,
          });
        }
        return;
      }
      if (typeof data?.error === 'string') {
        const strTrailEnded =
          'Your trial has ended! If you would like to continue using EdiFabric, please head on to https://www.edifabric.com/pricing.html to purchase it.';
        const strSubscriptionEnded =
          'Your subscription key has expired! Please use the renewal key or contact us at office@edifabric.com for assistance.';
        if (
          data?.error?.includes(strTrailEnded) ||
          data?.error?.includes(strSubscriptionEnded)
        ) {
          Swal.fire({
            icon: 'info',
            text: 'Your Subscription has ended!. Please contact admin to purchase EdiFabric.',
          });
          return;
        }
        const modifiedError = JSON.parse(data?.error);
        const valditionerror: { message: string }[] =
          modifiedError?.error?.validationErrors ?? [];
        if (valditionerror?.[0]?.message?.includes('is required')) {
          this.totalValue = [];
          valditionerror?.forEach((val: { message: any }) => {
            this.messageValue = val?.message;
            this.totalValue?.push(this.messageValue.concat('\r\n'));
          });
          Swal.fire({
            icon: 'info',
            html: `<pre style="text-align: -webkit-left;">${this.totalValue?.join(
              '\r\n'
            )}</pre>`,
          });
        }
        if (
          valditionerror?.[0]?.message?.includes('minimum') ||
          valditionerror?.[0]?.message?.includes('maximum')
        ) {
          this.totalValue = [];
          valditionerror?.forEach((val: { message: any }) => {
            this.messageValue = val?.message;
            this.totalValue?.push(this.messageValue.concat('\r\n'));
          });
          Swal.fire({
            icon: 'info',
            html: `<pre style="text-align: -webkit-left;">${this.totalValue?.join(
              '\r\n'
            )}</pre>`,
          });
        }
        return;
      }
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    } catch (error) {}
  }
  public findInvalidControls() {
    const invalid = [];
    const controls = this.claimForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        // invalid.push(name);
        invalid.push(
          document.getElementById(name)?.innerText?.replace('*', '')
        );
      }
    }
    return invalid;
  }
  // billingInfoArray = billingInfoArray;
  showError(claimId: string) {
    this.ltErrors = [];
    const data = this.clearingHouseUpdatesService
      .get277ErrorDetailsByClaimId(claimId)
      .subscribe(
        (response) => {
          this.ltErrors = response ?? [];
        },
        (err) => {
          this.ltErrors = [];
        }
      );
    this.subscription$.push(data);
  }
  //Open ICD9 Modal to save ICD9
  openIcd9Modal() {
    const dialogRef = this.dialog.open(PatientIcd9Component, {
      disableClose: true,
      data: {},
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.getDiagnosis();
        // this.icd9Dropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
  //Open ICD10 Modal to save ICD10
  openIcd10Modal() {
    const dialogRef = this.dialog.open(PatientIcd10Component, {
      disableClose: true,
      data: {},
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      () => {
        this.getDiagnosis();
        // this.icd10Dropdown();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(closeDialog);
  }
  getDiagnosis() {
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns?.DiagnosisCode9,
      PatientDropdowns?.DiagnosisCode10,
      PatientDropdowns?.PayorLevels,
      PatientDropdowns?.Genders,
      PatientDropdowns?.RelationShipWithSelf,
      PatientDropdowns?.Suffixes,
      PatientDropdowns?.InsuranceTypes,
      PatientDropdowns?.PayorLevels,
      PatientDropdowns.PlaceOfServices,
      PatientDropdowns.Suffixes,
    ];

    const patientDropdowns = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (response) => {
          this.drpDiagnosisICD10 = response?.diagnosisCodes10 ?? [];

          this.filteredIcd10Types = this.claimForm
            .get('txtIcd10Filter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpDiagnosisICD10?.filter(
                  (option) =>
                    option?.diagnosisCodeTypeData
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '') ||
                    option?.diagnosisCode
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
  }
  resubmitZirmedClaim() {
    // this.providerId

    try {
      if (this.processZirmedApiCall) {
        this.processZirmedApiCall.unsubscribe();
      }
      let claimIds = [this.claimId];
      this.processZirmedApiCall = this.claimFormDetailsService
        .zermidClaimProcessingByGClaimAndGProvider(claimIds, this.providerId)
        .subscribe(
          (response) => {
            this.toastr.success('Claim Resubmitted Successfully', 'Success');
            this.claimViewModelRef.close(response);
            this.loaderService.hideLoader();
          },
          (error) => {
            console.error(error);
            this.toastr.success('Claim Resubmission Error', 'error');
            this.loaderService.hideLoader();
          },
          () => {}
        );
    } catch (error) {
      console.error(error);
    } finally {
    }
  }
  maxSelectionValidator(max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedValues = control.value || [];
      return selectedValues.length > max ? { 'maxSelection': { value: control.value } } : null;
    };
  }
}
export interface CustomDoctorDTO extends DoctorDTO {
  NPIfullName?: string;
}
export interface CustomPayerDTO extends PayerDTO {
  payerCodeName?: string;
}

export interface CustomSaleOrderItemDTO extends SaleOrderItemDTO {
  totalItemPrice?: number;
  totalOrderPrice?: number;
}

export interface combinedRes {
  response:
    | PatientPersonalDTO
    | PatientPolicyDTO[]
    | PatientClinicalDTO
    | PatientVerificationDTO[]
    | OrderDetailsDTO
    | SaleOrderItemDTO[]
    | PatientAuthorizationDTO[]
    | PatientMasterDropdownDTO
    | RegionCountriesDTO[]
    | PayerDTO[]
    | DoctorDTO[];
}
const billingInfoArray: any[] = [
  {
    dosFrom: '2023-01-01',
    dosTo: '2023-01-10',
    drpPos: '00000000-0000-0000-0000-000000000000',
    emg: 'No',
    cptHcpcs: '12345',
    modifiers1: '00000000-0000-0000-0000-000000000000',
    modifiers2: '00000000-0000-0000-0000-000000000000',
    modifiers3: '00000000-0000-0000-0000-000000000000',
    modifiers4: '00000000-0000-0000-0000-000000000000',
    diagnosisPointer: 'A',
    charges: 150.0,
    daysOrUnits: 3,
    epsdtFamilyPlan: 'Yes',
    idQual: 'SSN',
    renderingProviderId: '123456789',
  },
  // Add more objects for additional data
];
