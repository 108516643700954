<div class="container-fluid">
  <div class="row bg">
    <div class="col-12">
      <div class="d-flex align-item-center justify-content-between m-0">
        <h4 class="mt-3 mb-0 font-size-18"><b>Inventory / Product Brand</b></h4>
        <div class="page-title-box page-title-right mt-1 mb-0">
          <ol class="breadcrumbs m-0">
            <button
              mat-button
              class="mr-2 buttonColor"
              [disabled]="
                dataSource && dataSource?.data && dataSource?.data?.length === 0
              "
              (click)="
              exporter.exportTable('xlsx', {
                fileName: 'Brand List',
                sheet: 'BrandList',
                Props: { Author: 'QSecure' }
              })"
            >
              Export
            </button>
            <!-- <li class="breadcrumb-item mt-2">Inventory</li>
            <li class="breadcrumb-item mt-2">Product Brand</li> -->
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6" style="margin-bottom: -32px">
      <div class="card card-body">
      <mat-accordion>
        <mat-expansion-panel [expanded]="step" (afterExpand)="toggle()" (closed)="toggleV2()">
          <mat-expansion-panel-header>
            <mat-panel-title class="customThemeClass">
              <ng-container *ngIf="brandId === ''; else elseTitleTemplate">
                <b class="pt-2">Add Product Brand</b> </ng-container><br /><br />
              <ng-template #elseTitleTemplate>
                <b>Edit Product Brand</b>
              </ng-template>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content>
            <form #pBrandForm [formGroup]="productBrandForm">
              <div class="row pb-4">
                <mat-form-field class="algin col-lg-4">
                  <mat-label
                    >Product Brand Name<span class="asterisk"
                      >*</span
                    ></mat-label
                  >
                  <input
                    autocomplete="new-txtBrandName"
                    matInput
                    maxlength="50"
                    formControlName="txtBrandName"
                    placeholder="Enter Product Brand Name"
                    type="text"

                  />
                  <mat-error
                    *ngIf="
                      productBrandForm?.get('txtBrandName')?.invalid &&
                      productBrandForm?.get('txtBrandName')?.errors?.required
                    "
                  >
                    Product Brand Name is a required field!
                  </mat-error>
                </mat-form-field>

                <div class="col-lg-6 text-lg-left">
                  <button
                    mat-button
                    (click)="saveProductBrand()"
                    class="buttonColor mb-1 mr-2"
                    [disabled]="
                      !(!productBrandForm?.invalid && productBrandForm?.dirty) || saveButtonHide
                    "
                  >
                    Save
                  </button>
                  <button
                    mat-button
                    (click)="resetForm()"
                    class="resetclr buttonColor mb-2 mr-2"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </mat-card-content>
        </mat-expansion-panel>
      </mat-accordion>
      </div>
    </div>

<div class="col-6">
  <div class="card card-body">
  <h4 class="customThemeClass ml-3 mt-2"><b>Product Brand List</b></h4>
  <div class="table table-responsive example-container mat-elevation-z4">
    <form [formGroup]="productBrandSearchForm">
      <div class="example-container mat-elevation-z4">
        <table
          mat-table
          [dataSource]="dataSource"
          class="pt-0 mt-0"
          matSort
          matTableExporter #exporter="matTableExporter"
          [hiddenColumns]="[4]"
        >
          <!--Serial No-->
          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef>S.No</th>
            <td mat-cell *matCellDef="let data; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>
          <!--Brand Name-->
          <ng-container matColumnDef="brandName">
            <th
              class="header"
              mat-sort-header
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <input
                  autocomplete="new-brandName"
                  matInput
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                  formControlName="brandName"
                />
                <mat-placeholder>Brand Name</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container>
                {{ data.productBrandDetailName }}
              </ng-container>
            </td>
          </ng-container>
          <!-- Created Date-->
          <ng-container matColumnDef="createdDate">
            <th
              class="header"
              mat-sort-header
              class="w-125"
              mat-header-cell
              *matHeaderCellDef
            >
              <mat-form-field class="filter">
                <mat-label> Created Date </mat-label>
                <input
                  autocomplete="new-createdDate"
                  matInput
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                  formControlName="createdDate"
                  placeholder="(MM/DD/YYYY)"
                />
                <mat-error
                  *ngIf="
                    productBrandSearchForm?.get('createdDate')?.errors
                      ?.dateVaidator
                  "
                >
                  Enter Valid date
                </mat-error>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.creationTime | date : "MM/dd/yyyy h:mm a" : "en_US" }}
            </td>
          </ng-container>
          <!--Status-->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field class="filter">
                <input
                  autocomplete="new-status"
                  matInput
                  (keydown.space)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                  formControlName="status"
                />
                <mat-placeholder>Status</mat-placeholder>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let data">
              <ng-container *ngIf="data?.status === 'Active'">
                <mat-slide-toggle (change)="statusChange($event, data)"  [checked]="true" class="ml-1">
                  {{ data?.status }}</mat-slide-toggle
                >
              </ng-container>
              <ng-container *ngIf="data?.status === 'Inactive'">
                <mat-slide-toggle (change)="statusChange($event, data)"  [checked]="false" class="ml-1">
                  {{ data?.status }}</mat-slide-toggle
                >
              </ng-container>
            </td>
          </ng-container>
          <!--Options-->
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
            <td mat-cell *matCellDef="let data">
              <!-- *ngIf="data?.status === 'Active'" -->
              <a *ngIf="data?.status === 'Active'" class="editBtnColor mr-2" (click)="getProductDetailsById(data?.id)">
                <i class="fa fa-pencil pointer"  (click)="toggleV2()" matTooltip="Click To Edit"></i>
              </a>
              <a class="deleteBtnColor" (click)="deletebrand(data?.id)">
                <i class="fa fa-trash-o" matTooltip="Click To Delete"></i>
            </a>
            </td>
          </ng-container>

          <!--No Records-->
          <ng-container matColumnDef="noRecords">
            <td
              class="text-align-center"
              mat-footer-cell
              *matFooterCellDef
              colspan="6"
            >
              <div *ngIf="!isShowSpinner">
                {{ "No Records Found" }}
              </div>
              <div [hidden]="!isShowSpinner">
                <mat-spinner [diameter]="220"></mat-spinner>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            [ngStyle]="{ 'background-color': row.colorCode }"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
        <div
          *ngIf="
            !(dataSource && dataSource?.data && dataSource?.data?.length !== 0)
          "
          class="text-align-center"
        >
          <div class="message py-3" *ngIf="!isShowSpinner">
            {{ "No Records Found" }}
          </div>
          <div class="py-3" [hidden]="!isShowSpinner">
            <mat-spinner [diameter]="220"></mat-spinner>
          </div>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 20, 50, 100]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </form>
  </div>
</div>
  </div>
  </div>
</div>
