<div class="row bg">
  <div class="col-12">
    <div class="d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18"><b>Sub Batch</b></h4>
      <ol class="breadcrumb m-0">
        <li>
          <button matTooltip="Click To Goback to Batch List" [routerLink]="'/uploadBin'" mat-button
            class="pl-2 pr-2 buttonColor font-size-10">
            <i class="fa fa-backward mr-1" ></i> Back To Batch List
          </button>
        </li>
        <li class="ml-2 mt-2 breadcrumb-item">Sub Batch</li>
        <li class="mt-2 breadcrumb-item muted">Sub Batch</li>
      </ol>
    </div>
    <mat-card-content>
      <form [formGroup]="subBatchUploadBinForm">
        <!-- <div [hidden]="!isLoading"
      style="display: flex; justify-content: center; align-items: center; background: transparent; color: lightseagreen;">
      <mat-progress-bar mode="indeterminate" value='66' diameter="45" class="lightseagreen">
      </mat-progress-bar>
    </div> -->
        <div class="table table-responsive">
          <table class="mat-elevation-z8" multiTemplateDataRows mat-table class="table" [dataSource]="dataSource"
            matSort>

            <ng-container matColumnDef="batchNo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-batchId" matInput formControlName="batchId" type="text" />
                  <mat-placeholder class="center">Batch Id</mat-placeholder>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  {{ element.defaultBatchId }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="stat">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Stat
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.isStat===true">
                  <mat-checkbox [disabled]="true" [checked]='true'> </mat-checkbox>
                </ng-container>
                <ng-container *ngIf="element.isStat===false">
                  <mat-checkbox [disabled]="true" [checked]='false'> </mat-checkbox>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="documentName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>

                <mat-form-field class="filter">
                  <input autocomplete="new-documentName" matInput formControlName="documentName" type="text" />
                  <mat-placeholder class="center"> Document Name</mat-placeholder>
                </mat-form-field>

              </th>
              <td mat-cell *matCellDef="let element">

                <span class="alert alert-success p-2" *ngIf="element.reasonForNotExtracted===''">
                  {{ element.uploadedFileName }}
                </span>
                <span class="alert alert-danger p-2" *ngIf="element.reasonForNotExtracted!==''">
                  {{ element.uploadedFileName }}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="assignedBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>

                <mat-form-field class="filter">
                  <input autocomplete="new-assignedBy" matInput formControlName="assignedBy" type="text" />
                  <mat-placeholder class="center">Assigned By</mat-placeholder>
                </mat-form-field>


              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  {{ element.assignedBy }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="assignedTo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-assignedTo" matInput formControlName="assignedTo" type="text" />
                  <mat-placeholder class="center">Assigned To</mat-placeholder>
                </mat-form-field>

              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  {{ element.assignedTo }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="assignedTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <mat-label> Assigned Date </mat-label>
                  <input autocomplete="new-assignedTime" matInput formControlName="assignedTime" type="text"   placeholder="(MM/DD/YYYY)"   />
                  <mat-error
                    *ngIf=" subBatchUploadBinForm?.get('assignedTime')?.touched && subBatchUploadBinForm?.get('assignedTime')?.errors?.dateVaidator">
                    Enter Valid date
                  </mat-error>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  {{ element.assignedTime }}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="uploadedFileStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <mat-form-field class="filter">
                  <input autocomplete="new-uploadedFileStatus" matInput formControlName="uploadedFileStatus" type="text" />
                  <mat-placeholder class="center">Status</mat-placeholder>
                </mat-form-field>

              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container>
                  {{ element.uploadedFileStatus }}
                </ng-container>
              </td>
            </ng-container>



            <ng-container matColumnDef="Options">
              <th class="verticalAlignColumn text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                Options
              </th>
              <td class="verticalAlignColumn text-center" mat-cell *matCellDef="let element">
                <a *ngIf="isShownViewButton " (click)="update(element.fileId)">
                  <i class="fa fa-pencil pointer editBtnColor mr-3" matTooltip="Click To Edit"></i>
                </a>

                <a *ngIf="isShownDeleteButton" class="deleteBtnColor" (click)="deleteFileByID(element.fileId)">
                  <i class="fa fa-trash pointer mr-3" matTooltip="Click To Delete" ></i>
                </a>
                <a *ngIf="element.reasonForNotExtracted!==''"
                  (click)="expandedElement = expandedElement === element ? null : element">
                  <mat-icon class="pointer"> {{expandedElement === element ?'expand_less' : 'expand_more'}}</mat-icon>
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="noRecords">
              <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="10">
                <div *ngIf="!isLoading">
                  {{"No Records Found"}}
                </div>
                <div *ngIf="isLoading">
                  {{"Processing..."}}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="paginator">
              <td mat-footer-cell *matFooterCellDef colspan="10">
                <mat-paginator class="table table-responsive mat-table-class" #MatPaginator
                  [length]="dataSource?.data?.length" [pageSize]="10" [pageIndex]="0" showFirstLastButtons
                  [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
              </td>
            </ng-container>


            <ng-container matColumnDef="expandedErrorDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="arrDisplayedColumns?.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="example-element-description">
                    {{element.reasonForNotExtracted}}
                  </div>
                </div>
              </td>
            </ng-container>


            <tr mat-footer-row *matFooterRowDef="['noRecords']"
              [ngClass]="{ active: dataSource && dataSource?.data && dataSource?.data?.length !== 0 }"></tr>
            <tr mat-header-row *matHeaderRowDef="arrDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: arrDisplayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['paginator']"></tr>

            <tr mat-row *matRowDef="let element; columns: ['expandedErrorDetail'];"
              [class.example-expanded-row]="expandedElement === element"></tr>
          </table>
        </div>
      </form>
    </mat-card-content>
  </div>
</div>
