<div class="container-fluid">

    <div class="row bg">
        <div class="col-12">
            <div class="d-flex align-item-center justify-content-between m-0">
                <h4 class="mt-2 mb-0 font-size-18"><b>Master / Modifier</b></h4>
                <div class=" page-title-box  page-title-right mt-1 mb-0">
                    <ol class="breadcrumbs m-0">
                        <div class="input-group mb-3">
                            <input autocomplete="new-daterange" type="text" class="form-control" placeholder="Username"
                                [ngStyle]="{'border-color':dateRangStatus == true ? '#bdb4b4' : 'white' }"
                                aria-label="Username" aria-describedby="basic-addon1" name="daterange" ngxDaterangepickerMd
                                [(ngModel)]="selectedRange" id="calendar-input" [showCustomRangeLabel]="true"
                                [alwaysShowCalendars]="true" [linkedCalendars]="true" [ranges]="ranges"
                                [showClearButton]="true" [locale]="calendarLocale" [placeholder]="calendarPlaceholder"
                                [keepCalendarOpeningWithRange]="true" [minDate]="minDate" [maxDate]="maxDate"
                                (datesUpdated)="datesUpdateds($event)">
                            <span class="input-group-text bg-color" id="basic-addon1"><label style="margin: auto;"
                                    for="calendar-input"><i class="fa fa-filter cursor"
                                        aria-hidden="true"></i></label></span>
    
                        </div>
    
                        <li class="breadcrumb-item">Master Pages</li>
                        <li class="breadcrumb-item">Master Modifier</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>

    <!--Loader Section-->
    <div class="col-12" *ngIf="isLoading">
        <div class="d-flex justify-content-center" *ngIf="isLoading">
            <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
        </div>
    </div>
    <app-master-modifiers [tableModifier]="tableMstModifry" *ngIf="!isLoading"></app-master-modifiers>
</div>