<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-lg-6">
      <h2>{{ addDocumentbinModalTitle }}</h2>
    </div>
    <div class="col-lg-6 text-right">
      <button class="text-right close" aria-label="Close" mat-dialog-close> <b> X </b>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="documentBinForm">
      <mat-card-content>
        <div class="row">
          <div class="col-2">
            <ng-container *ngIf="showErrorForFileType">
              <div class="row">
                <div class="col-12">
                  <span class="asterisk">{{ 'Select Only PDF`s' }}</span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="showErrorBasedOnSize">
              <div class="row">
                <div class="col-12">
                  <span class="asterisk">{{ 'Select each file`s size of 10mb or Less' }}</span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="showErrorForFileLength">
              <div class="row">
                <div class="col-12">
                  <span class="asterisk">{{ 'Select Only 1 file' }}</span>
                </div>
              </div>
            </ng-container>
            <label [hidden]="selectedFileUploadsData!==null" for="file-upload"
              class="btnclr waves-effect font-size-12 pt-2 pb-2 pr-4 pl-4">
              <i class="fa fa-cloud-upload"></i> Browse<span class="asterisk"> * </span>
            </label>
            <input autocomplete="new-documentFile" id="file-upload" formControlName="documentFile"
              (change)="getSelectedFilesDetails(uploadFileReference.files)" [multiple]="false" #uploadFileReference
              accept="application/pdf" type="file" />
          </div>

          <ng-select formControlName="txtUser" class="col-3" placeholder="User To Be Assigned" maxlength="40">
            <ng-option *ngFor="let user of arrUsersList" [value]="user.id + '/' + user.userName">
              {{ user.userName }}
            </ng-option>
          </ng-select>
          <div class="col-1">
            <br>
            <mat-checkbox formControlName="chkStat">Stat
            </mat-checkbox>
          </div>

          <mat-form-field *ngIf="selectedFileUploadsData===null" class="col-3">
            <mat-label>Notes</mat-label>
            <textarea formControlName="txtNote" rows="2" maxlength="5000" [(ngModel)]="notesText"
            (input)="updateCharacterCount()" matInput></textarea>
            <div class="char-count">
              {{ notesText?.length }} / 5000
            </div>
          </mat-form-field>

          <div *ngIf="selectedFileUploadsData===null" class="col-2">
            <button mat-button class="buttonColor mb-2 mr-2"
              [disabled]="chkdisableButton || documentBinForm?.get('documentFile')?.value===null && this.fileId ===''"
              (click)="saveUploadedDocuments(documentBinForm?.get('documentFile')?.value)">
              Save
            </button>
            <button mat-button class="resetclr mb-2 mr-2" (click)="resetDocumentBinForm()">
              Reset
            </button>
          </div>
          <div *ngIf="selectedFileUploadsData!==null" class="col-4">
            <button mat-button class="buttonColor mb-2 mr-2"
              [disabled]="chkdisableButton || documentBinForm?.get('documentFile')?.value===null && this.fileId ===''"
              (click)="saveUploadedDocuments(documentBinForm?.get('documentFile')?.value)">
              Save
            </button>
            <button mat-button class="resetclr mb-2 mr-2" (click)="resetDocumentBinForm()">
              Reset
            </button>
          </div>
        </div>
      </mat-card-content>
    </form>

    <div class="table-responsive" *ngIf="selectedFileUploadsData!==null">
      <table class="users table table-sm table-bordered ">
        <thead style="display: table-header-group;">
          <tr>
            <!-- <th class="table-light" scope="col">S.No</th> -->
            <th class="table-light" scope="col">Document Name</th>
            <th class="table-light" scope="col">Status</th>
            <th class="table-light" scope="col">Assigned To</th>
            <th class="table-light" scope="col">Assigned Date</th>
          </tr>
        </thead>
        <tbody style="display: table-row-group;">
          <tr scope="row">
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ 1 }} </span>
              </p>
            </td>
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ selectedFileUploadsData?.uploadedFileName }} </span>
              </p>
            </td>
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ selectedFileUploadsData?.uploadedFileStatus }} </span>
              </p>
            </td>
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ selectedFileUploadsData?.assignedTo }} </span>
              </p>
            </td>
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ selectedFileUploadsData?.assignedTime }} </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive" *ngIf="arrSelectedDocuments && arrSelectedDocuments?.length !== 0">
      <table class="users table table-sm table-bordered ">
        <thead style="display: table-header-group;">
          <tr>
            <!-- <th class="table-light" scope="col">S.No</th> -->
            <th class="table-light" scope="col">Document Name</th>
            <th class="table-light" scope="col">Document Type</th>
            <th class="table-light" scope="col">Document Size</th>
          </tr>
        </thead>
        <tbody style="display: table-row-group;">
          <tr scope="row" *ngFor="let document of arrSelectedDocuments;let i=index">
            <!-- <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ i+1}} </span>
              </p>
            </td> -->
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ document.fileName }} </span>
              </p>
            </td>
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ document.fileType }} </span>
              </p>
            </td>
            <td class="table-light">
              <p class="text-muted mb-0">
                <span> {{ document.fileSize }} </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</mat-dialog-content>
