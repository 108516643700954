import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { dtInboundOptions, dtNotesTableOptions, pdfString } from '../admin-dashboard/dashboard-data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { element } from 'protractor';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
import { InboundStatusShortCode } from 'projects/shared/src/app/data/common-data';
import { CommunicationService } from '../../../../shared/src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
export const defaultGuid: string = "00000000-0000-0000-0000-000000000000";
@Component({
  selector: 'app-join-fax',
  templateUrl: './join-fax.component.html',
  styleUrls: ['./join-fax.component.scss']
})
export class JoinFaxComponent implements OnInit {

  joinTableData: any;
  isLoaded = false;
  branchName:string;
  isNewSetupV1:boolean = false;
  // dtJoinTblOptions: any = {
  //   responsive: true,
  //   scrollCollapse: true,
  //   scrollY: '1000px',
  //   pageLength: 10,
  //   lengthMenu: [10, 25, 50, 100, 200]
  // };
  // dtJoinTblOptions: any = {
  //   responsive: true,
  //   paging: false,
  //   scrollCollapse: true,
  //   scrollY: '500px',
  //   info: false
  // };
  allCheckboxdisabled:boolean=false;
  selection = new SelectionModel<any>(true, []);
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  dtJoinTblOptions: any = {
    responsive: true,
    paging: false,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '500px',
    scrollX: true,
    fixedHeader: true, // Fix the header
    fixedColumns: {
      leftColumns: 3, // Number of columns to fix on the left
      rightColumns: 1, // Number of columns to fix on the right
    },
    columnDefs: [
      { targets: [0], orderable: false },
      { targets: [0, 1, 2, -1], className: 'dt-fixed-column' }, // Add class to make the first three and last column fixed
    ],
    search: {
      smart: false,
    }
  };

  // dtJoinTblOptions: any = {
  //   responsive: true,
  //   paging: false,
  //   language: {
  //     emptyTable: 'No records',
  //     info: 'Total : _MAX_ records',
  //     infoEmpty: 'Total : _MAX_ records',
  //     infoFiltered: '(filtered : _TOTAL_ records)',
  //     loadingRecords: 'Loading...',
  //     zeroRecords: 'No matching records',
  //   },
  //   scrollCollapse: true,
  //   scrollY: '500px',
  //   scrollX: true,
  //   search: {
  //     smart: false, // Set to "none" for full exact match
  //   }
  // };
  isSpinnerDisabled: { [spinnerId: string]: boolean } = {};
  isButtonDisabled: boolean = false;
  iRecordCounts : number =0;
  iTableRow : number = 0;
  isShowSpinner: boolean = false;
  isFormValid:boolean=false;
  joinForm: FormGroup;
  faxId : string;
  joinFaxID: string;
  docId: string;
  branchId: string;
  tenantId: string;
  // inboundTableData: any;
  inboundStatusIds = InboundStatusShortCode;
  dtInboundOptions = dtInboundOptions;
  drpJoinId: any[] = [];
  @Input() tableData: any;
  selectJoinList: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  myTable: DataTables.Api;
  faxDetails:any;
  pdfString = pdfString;
   filterSelectFax: any;
  selectedUserList: any;
  lisDocIds: any=[];
  checkAll: boolean=false;
  isMultipleSpinnerDisabled: boolean=false;
  selectedOption:number;
  isSigleJoin:boolean=false;
  constructor(
    private communicationService: CommunicationService,
    private snackBar: MatSnackBar,
    private toaster: ToastrService,
    private fb : FormBuilder,
    private inboundDocService : InboundDocumentService,
    public policyDialogRef: MatDialogRef<JoinFaxComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { faxID: string;
    docID: string,
    branchID: string, selectedNode?:string;
    // lname?:string,dob?:string
    docList:any,
    isNewSetup: boolean,
  } = {
      faxID: defaultGuid,
      docID: defaultGuid,
      branchID: defaultGuid,
      selectedNode:'',
      // lname:'',
      // dob:'',
      docList:null,
      isNewSetup:false,
    }
  ) { }

  ngOnInit(): void {
    // this.inboundTableData = this.tableData;
    this.isNewSetupV1=this.data?.isNewSetup;
    this.faxId = this.data?.faxID ?? defaultGuid;
    this.docId = this.data?.docID ?? defaultGuid;
    this.branchId = this.data?.branchID ?? defaultGuid;
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    if(this.data?.docList !==null && this.data?.docList !==undefined){
    this.joinTableData=this.data?.docList;
    this.faxDetails = this.data?.docList;
      this.filterSelectFax = this.faxDetails;
      this.iRecordCounts = this.data?.docList.length;
      this.isLoaded = true;
    }else{
    this.loadJoinData();
    }

    this.selectedOption=0;
    this.isMultipleSpinnerDisabled=true;
    this.isSigleJoin=true;

  }
  loadJoinData() {
    this.isLoaded = false;
    const drpData = this.inboundDocService
    .getDetailsWithBranchIdByGBranchIdAndGDocumentId(this.branchId, this.docId)
    .subscribe(response => {
      // this.branchName=response?.branchName[0];
      this.joinTableData = response;
      this.dataSource=new MatTableDataSource(this.joinTableData);
      // console.log(this.dataSource)
      // console.log('this.dataSource')
      if (response && response.length > 0) {
        this.branchName = response[0].branchName ?? 'N/A';
      } else {
        this.branchName = '';
      }
      this.faxDetails = response;
      this.filterSelectFax = this.faxDetails;
      this.iRecordCounts = response.length;
      this.isLoaded = true;
    },(err) => {
    const data: HttpErrorResponse = err;
    Swal.fire({
      icon: 'info',
      text: data?.error?.error?.message,
     });
     this.policyDialogRef.close();
    });
  }
  getFormattedDate(date: any): string {
    return date
      ? new Date(date).toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      : '-';
  }

  //US Format Date and Time 
  getFormattedDateTimeZone(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: 'numeric', minute: '2-digit',
        hour12: true,
        timeZone: 'America/Chicago'
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }

  getFormattedDateV1(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }
  // loadExistDoc(){
  //   const checkDateExist = this.inboundDocService
  //   .checkExistDocument(this.data?.lname, this.data?.dob)
  //   .subscribe(
  //     (response) => {

  //     },
  //     (err) => {
  //       const data: HttpErrorResponse = err;
  //       Swal.fire({
  //         icon: 'info',
  //         text: data?.error?.error?.message,
  //       });
  //     }
  //   );
  // }
  singleJoin(){

    setTimeout(() => {
      this.isLoaded=false;
    }, 100);
    this.isMultipleSpinnerDisabled=true;
    this.isSigleJoin=true;
    this.lisDocIds=[];
    this.clearAllId();
    this.checkAll=false;
    setTimeout(() => {
      this.isLoaded=true;
    }, 150);
  }
  multiJoin(){
    this.isMultipleSpinnerDisabled=false;
    this.isSigleJoin=false;
  }
  onSelectFax(event){
    this.joinFaxID = event.value.faxId;
    this.checkFormValidity();
  }

  checkFormValidity(): void {
    this.isFormValid = this.joinForm.valid;
  }
  multipleJoin(){
    this.isMultipleSpinnerDisabled=true;
    Swal.fire({
      title: 'Do you want to join fax?',
      // text: 'By Changing Status, this branch will be deactivated',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes'
    }).then(result => { ;
      if (result.value) {
       this.inboundDocService.multipleJoinPreviousDocument(this.docId, this.lisDocIds).subscribe(
      (response) => {
        if(response["statusCode"] == 200){
          this.toaster.success(response["message"], 'Success');
          if(this.isNewSetupV1===true){
            this.communicationService.triggerMoveToFunction();
            }
          this.isButtonDisabled = false;
          this.isMultipleSpinnerDisabled=false;
          this.policyDialogRef.close(response);
          this.communicationService.triggerFunctionCall(this.data.selectedNode);
        }else if(response["statusCode"] == 207){
          Swal.fire({
            title: response["message"],
            // text: 'By Changing Status, this branch will be deactivated',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#34c38f',
            cancelButtonColor: '#f46a6a',
            confirmButtonText: 'Yes'
          }).then(result => { ;
            if (result.value) {
              this.inboundDocService.multipleJoinPreviousDocument(this.docId, this.lisDocIds,true).subscribe(
                (response) => {
                  if(response["statusCode"] == 200){
                    this.toaster.success(response["message"], 'Success');
                    if(this.isNewSetupV1===true){
                      this.communicationService.triggerMoveToFunction();
                      }
                    this.isButtonDisabled = false;
                    this.isMultipleSpinnerDisabled=false;
                    this.policyDialogRef.close(response);
                    this.communicationService.triggerFunctionCall(this.data.selectedNode);
                  }else{
                    this.toaster.warning(response["message"], 'Warning');
                     this.isButtonDisabled = false;
                     this.isMultipleSpinnerDisabled=false;
                  }
                },
                (err) => {
                  this.toaster.warning(response["message"], 'Warning');
                  this.isButtonDisabled = false;
                  this.isMultipleSpinnerDisabled=false;
                });
              }
        else{
          this.isMultipleSpinnerDisabled=false;
        }
        },
      (err) => {
        this.isMultipleSpinnerDisabled=false;
        const data: HttpErrorResponse = err;
        this.toaster.warning(data?.error?.error?.message, 'Warning');

      })
        }else{
          this.toaster.warning(response["message"], 'Warning');
        this.isMultipleSpinnerDisabled=false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        this.toaster.warning(data?.error?.error?.message, 'Warning');
        this.isMultipleSpinnerDisabled=false;
      })
     }else{
      this.isMultipleSpinnerDisabled=false;
     }
    });
  }



  joinFax(masterDoId: string, faxId:string,tableRow: number){
  Swal.fire({
    title: 'Do you want to join '+this.faxId+' fax with '+faxId+' fax?',
    // text: 'By Changing Status, this branch will be deactivated',
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#34c38f',
    cancelButtonColor: '#f46a6a',
    confirmButtonText: 'Yes'
  }).then(result => { ;
    if (result.value) {
    this.isSpinnerDisabled[tableRow] = true;
    this.isButtonDisabled = true;

    this.isFormValid = false;
    this.inboundDocService.joinPreviousDocumentByGMasterInbounDocIdAndGNewFaxInboundDocId(masterDoId,this.docId).subscribe(
    (response) => {
      if(response["statusCode"] == 200){
        this.toaster.success(response["message"], 'Success');
        if(this.isNewSetupV1===true){
          this.communicationService.triggerMoveToFunction();
          }
        this.isSpinnerDisabled[tableRow] = false;
        this.isButtonDisabled = false;
        this.policyDialogRef.close(response);
        this.communicationService.triggerFunctionCall(this.data.selectedNode);
      }else if(response["statusCode"] == 207){
        Swal.fire({
          title: response["message"],
          // text: 'By Changing Status, this branch will be deactivated',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes'
        }).then(result => { ;
          if (result.value) {
            this.inboundDocService.joinPreviousDocumentByGMasterInbounDocIdAndGNewFaxInboundDocId(masterDoId, this.docId,true).subscribe(
              (response) => {
                if(response["statusCode"] == 200){
                  this.toaster.success(response["message"], 'Success');
                  if(this.isNewSetupV1===true){
                    this.communicationService.triggerMoveToFunction();
                    }
                  this.isSpinnerDisabled[tableRow] = false;
                  this.isButtonDisabled = false;
                  this.policyDialogRef.close(response);
                  this.communicationService.triggerFunctionCall(this.data.selectedNode);
                }else{
                  this.toaster.warning(response["message"], 'Warning');
                  this.isSpinnerDisabled[tableRow] = false;
                  this.isButtonDisabled = false;
                  this.checkFormValidity();
                }
              },
              (err) => {
                const data: HttpErrorResponse = err;
                this.toaster.warning(data?.error?.error?.message, 'Warning');
                this.isSpinnerDisabled[tableRow] = true;
                this.isButtonDisabled = false;
                this.checkFormValidity();
              });
          }else{
            this.isSpinnerDisabled[tableRow] = true;
                this.isButtonDisabled = false;
                this.checkFormValidity();
          }
        });
      } else{
        this.toaster.warning(response["message"], 'Warning');
        this.isSpinnerDisabled[tableRow] = false;
        this.isButtonDisabled = false;
        this.checkFormValidity();
      }
      },
    (err) => {
      const data: HttpErrorResponse = err;
      this.toaster.warning(data?.error?.error?.message, 'Warning');
      this.isSpinnerDisabled[tableRow] = true;
      this.isButtonDisabled = false;
      this.checkFormValidity();
    })
  }
  });
  }
  // ngAfterViewInit(): void {
  //   // this.dtInboundOptions = {
  //   //   responsive: true,
  //   //   pageLength: 5,
  //   //   lengthMenu: [5, 10, 25, 50, 100],
  //   // };

  //   this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     dtInstance.columns().every(function () {
  //       const that = this;
  //       $('input', this.footer()).on('keyup change', function () {
  //         if (that.search() !== this['value']) {
  //           that.search(this['value']).draw();
  //         }
  //       });
  //     });
  //   });
  // }
  // Row Click Event UnProcessed Table
  handleTableCellClick(e: Event, notesData: any): void {

    const target = e.target as HTMLElement;
    const tr = target.closest('tr');
    this.getDataTableData(tr, notesData);
  }
  //DataTable Data Get Method and Toggle Notes Row in UnProcessed Table
  async getDataTableData(tr: any, notesData: any): Promise<void> {
    this.myTable = await this.datatableElement.dtInstance;

    if (tr) {
      const row = this.myTable.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        row.child(this.format(row.data(), notesData)).show();
      }
    }
    // Now you can use the dataTable object to get data, for example:
    // const data = this.myTable.rows().data().toArray();

  }
  //Add Dynamic Notes Row in UnProcessed Table
  format(d: any, notesData: any) {
    let returnString: string = '';
    notesData?.forEach(function (value) {
      returnString += '<tr class="border-bottom"><td>' + value + '</td></tr>';
    });
    return returnString;
    // (
    //   '<tr class="border-bottom"><td>' +
    //   'There is a language course organised at the training centre. Your unit can send one soldier to attend this course. You have been tasked with giving a briefing presenting two possible candidates' +
    //   '</td></tr>' +
    //   '<tr class="border-bottom"><td>' +
    //   'Let me start with Candidate A and his strengths. Captain Nowak is a very experienced officer who has participated in a few missions abroad and cooperated with our foreign allies. Therefore, he has plenty of practical experience in real life communication in English and working in this language' +
    //   '</td></tr>'
    // );
  }
  //Copy Text When Double click On Row
  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
   //To check the total selected count on checkbox change
   onCheckboxChange(event: any,docId:string) {
    if (event.checked === true) {
      this.lisDocIds.push(docId);
      if( this.lisDocIds.length=== this.joinTableData.length){
        this.checkAll=true;
     }
    } else {
      const index = this.lisDocIds.indexOf(docId);
      if (index !== -1) {
        this.lisDocIds.splice(index, 1);
      }
      if( this.lisDocIds.length !== this.joinTableData.length){
        this.checkAll=false;
     }
    }
  }


  onUserSelectionChange(event: MatSelectChange): void {
    this.selectedUserList = event.value;
    this.selectedUserList = this.joinTableData.map(doc => doc.documentId);

    }
      /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
   const numRows = this.dataSource.data.length;
    return numSelected === numRows;

  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // if there is a selection then clear that selection
    if (this.isSomeSelected()) {
      this.clearAllId();
    } else {
      this.isAllSelected()
        ? this.clearAllId()
        : this.getAllId();
    }
  }

  isSomeSelected() {
    return this.selection.selected.length > 0;
  }
  getAllId(){
    this.dataSource.data.forEach(row => this.selection.select(row));
    this.lisDocIds=[];
    const ids=this.dataSource.data.forEach(row =>  this.lisDocIds.push(row.documentId));
    }
    clearAllId(){
      this.selection.clear() ;
      this.lisDocIds=[];
    }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }

}
