<table
  datatable
  [dtOptions]="dtExchangeItemOptions"
  class="row-border hover w-100 display"
>
  <thead>
    <tr>
      <th rowspan="2">Items</th>
      <th rowspan="2">Chart Id</th>
      <th rowspan="2">Patient Name</th>
      <th rowspan="2">Exchange Date</th>
      <th colspan="5" class="text-center left-border">Original Order</th>
      <th colspan="5" class="text-center left-border">New Order</th>
    </tr>
    <tr>
      <th class="left-border">Ticket Id</th>
      <th>Order Date</th>
      <th>DOS</th>
      <th>Created By</th>
      <th>Created Date</th>

      <th class="left-border">Ticket Id</th>
      <th>Order Date</th>
      <th>DOS</th>
      <th>Created By</th>
      <th>Created Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of ltExchangeOrders">
      <td matTooltip="Click To View Items" class="text-center">
        <!-- <a (click)="getExchangeItemsList(data.exchangeId)" type="button"
          ><mat-icon class="fc-lightseagreen">remove_red_eye</mat-icon></a
        > -->

        <mat-icon
          mat-icon-button
          class="fc-lightseagreen"
          type="button"
          (click)="getExchangeItemsList(data.originalOrderId, data.newOrderId,data.originalOrderNo,data.newOrderNo)"
        >
          list_alt</mat-icon
        >
      </td>
      <!-- Chart No -->
      <td [matTooltip]="data?.chartId || '-'">
        <a
          class="preview-link"
          [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)"
          [routerLink]="
            '/patientCreateEditTab/' + data.patientId + '/' + data.documentId
          "
          target="_blank"
          >{{ data?.chartId ? data?.chartId : "-" }}
        </a>
      </td>
      <!-- Patient Name -->
      <td [matTooltip]="data?.patientName || '-'">{{ data.patientName }}</td>
      <!-- Exchange Date -->
      <td [matTooltip]="data?.exchangeDate || '-'">{{ data.exchangeDate }}</td>
      <!-- Original Ticket ID-->
      <td class="left-border" [matTooltip]="data?.originalOrderNo || '-'">
        <a
          class="preview-link"
          [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)"
          [routerLink]="
            '/orders/saleOrder/' +
            data.patientId +
            '/' +
            data.originalOrderId +
            '/' +
            '0'
          "
          target="_blank"
          >{{ data?.originalOrderNo ? data?.originalOrderNo : "-" }}
        </a>
      </td>
      <!-- Original Order Date -->
      <td [matTooltip]="data?.originalOrderDate || '-'">
        {{ data?.originalOrderDate ? data?.originalOrderDate : "-" }}
      </td>

      <!-- Original DOS -->
      <td [matTooltip]="data?.originalOrderDOS || '-'">
        {{ data?.originalOrderDOS ? data?.originalOrderDOS : "-" }}
      </td>

      <!-- Original Order Created By -->
      <td [matTooltip]="data?.originalOrderCreatedByName || '-'">
        {{
          data?.originalOrderCreatedByName
            ? data?.originalOrderCreatedByName
            : "-"
        }}
      </td>

      <!-- Original Order Created Date -->
      <td [matTooltip]="data?.originalOrderCreatedDate || '-'">
        {{
          data?.originalOrderCreatedDate ? data?.originalOrderCreatedDate : "-"
        }}
      </td>

      <!-- New Order no -->
      <td class="left-border" [matTooltip]="data?.newOrderNo || '-'">
        <a
          class="preview-link"
          [class.disabled]="isEmpty(data.chartId) || isEmpty(data.patientId)"
          [routerLink]="
            '/orders/saleOrder/' +
            data.patientId +
            '/' +
            data.newOrderId +
            '/' +
            '0'
          "
          target="_blank"
        >
          {{ data?.newOrderNo ? data?.newOrderNo : "-" }}
        </a>
      </td>

      <!-- New Order Date -->
      <td [matTooltip]="data?.newOrderDate || '-'">
        {{ data?.newOrderDate ? data?.newOrderDate : "-" }}
      </td>

      <!-- New Order DOS -->
      <td [matTooltip]="data?.newOrderDOS || '-'">
        {{ data?.newOrderDOS ? data?.newOrderDOS : "-" }}
      </td>

      <!-- New Order Created By -->
      <td [matTooltip]="data?.newOrderCreatedByName || '-'">
        {{ data?.newOrderCreatedByName ? data?.newOrderCreatedByName : "-" }}
      </td>

      <!-- New Order Created Date -->
      <td [matTooltip]="data?.newOrderCreatedDate || '-'">
        {{ data?.newOrderCreatedDate ? data?.newOrderCreatedDate : "-" }}
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th>
        <!-- <input
        matInput
        class="textbox-border-class"
        type="text"
        placeholder="Chart No"
      /> -->
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Chart No"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Patient Name"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Exchange Date"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="text-center textbox-border-class"
          placeholder="Ticket ID"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Order Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="DOS"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
          name="search-Created By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created Date"
          name="search-Created Date"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="text-center textbox-border-class"
          placeholder="Ticket ID"
        />
      </th>
      <th>
        <input
          matInput
          type="text"
          class="textbox-border-class"
          placeholder="Order Date"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="DOS"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created By"
          name="search-Created By"
        />
      </th>
      <th>
        <input
          matInput
          class="textbox-border-class"
          type="text"
          placeholder="Created Date"
          name="search-Created Date"
        />
      </th>
    </tr>
  </tfoot>
</table>

