import type { CreateUpdateDiagnosisCode10DTO, DiagnosisCode10DTO } from './dto/patient/models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { DiagnosisCode9DTO } from '../../rcm/dropdown-management/dropdowns/dto/patient/models';

@Injectable({
  providedIn: 'root',
})
export class DiagnosisCode10Service {
  apiName = 'DropdownManagement';

  create = (input: CreateUpdateDiagnosisCode10DTO) =>
    this.restService.request<any, DiagnosisCode10DTO>({
      method: 'POST',
      url: '/api/DropdownApp/diagnosis-code10',
      body: input,
    },
      { apiName: this.apiName, skipHandleError: true });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/DropdownApp/diagnosis-code10/${id}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  get = (id: string) =>
    this.restService.request<any, DiagnosisCode10DTO>({
      method: 'GET',
      url: `/api/DropdownApp/diagnosis-code10/${id}`,
    },
      { apiName: this.apiName, skipHandleError: true });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<DiagnosisCode10DTO>>({
      method: 'GET',
      url: '/api/DropdownApp/diagnosis-code10',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
      { apiName: this.apiName, skipHandleError: true });

  searchdiagnosiscode9 = (sInput: string) =>
    this.restService.request<any, PagedResultDto<DiagnosisCode9DTO>>({
      method: 'POST',
      url: `/api/DropdownApp/diagnosis-code9/search-diagnosis-code9`,
      params: { sInput},

    },
      { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) { }
}
