import type { WarehouseDashboardCountDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WarehouseDashboardService {
  apiName = 'WarehouseManagement';

  getDashboardCount = () =>
    this.restService.request<any, WarehouseDashboardCountDTO>({
      method: 'GET',
      url: '/api/WarehouseApp/warehouse-dashboard/get-dashboard-count',
    },
    { apiName: this.apiName,skipHandleError:true });

  constructor(private restService: RestService) {}
}
