import type { ChatDTO, CreateUpdateChatDTO } from './dto/models';
import { RestService } from '@abp/ng.core';
import { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

import { OnInit } from '@angular/core';
import * as signalR from '@microsoft/signalr';          // import signalR
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { PlatformUrl } from 'projects/patient/src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  apiName = 'platformManagement';

  create = (input: CreateUpdateChatDTO) =>
    this.restService.request<any, ChatDTO>({
      method: 'POST',
      url: '/api/PlatformApp/chat',
      body: input,
    },
      { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/PlatformApp/chat/${id}`,
    },
      { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, ChatDTO>({
      method: 'GET',
      url: `/api/PlatformApp/chat/${id}`,
    },
      { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ChatDTO>>({
      method: 'GET',
      url: '/api/PlatformApp/chat',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, sorting: input.sorting },
    },
      { apiName: this.apiName });

  update = (id: string, input: CreateUpdateChatDTO) =>
    this.restService.request<any, ChatDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/chat/${id}`,
      body: input,
    },
      { apiName: this.apiName });

  sendMessageToMultiUserByLstInput = (lstInput: CreateUpdateChatDTO[]) =>
    this.restService.request<any, ChatDTO[]>({
      method: 'POST',
      url: '/api/PlatformApp/chat/send-message-to-multi-user',
      body: lstInput,
    },
      { apiName: this.apiName });


  getchatdata = (id: string, ) =>
    this.restService.request<any, any>({
      method: 'GET',
      url: `/api/PlatformApp/chat/get-chat-data/${id}`,
    },
      { apiName: this.apiName });







  constructor(private restService: RestService,

    private http: HttpClient) {
  }
}
