import {
  ListService,
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MasterProviderService } from 'projects/admin/src/app/warehouse-proxy/inventory';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import {
  concatMap,
  map,
  mergeMap,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns';
import {
  RegionCitiesDTO,
  RegionCountriesDTO,
  RegionStatesDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { DashboardService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dashboard/dashboard.service';
import {
  AccountGroupDTO,
  AlternateCPTSDTO,
  AlternatePOSDTO,
  AlternateTOSDTO,
  AssignmentDTO,
  BranchOfficeDTO,
  ClaimFillingCodeDTO,
  ClaimFillingIndicatorDTO,
  ColorCodingDTO,
  CustomerTypeDTO,
  DunningMessageDTO,
  GenderDTO,
  HoldReasonDTO,
  InsEditsDTO,
  InsuranceGroupDTO,
  MaritalStatusDTO,
  PatientMasterDropdownDTO,
  PaymentCodeDTO,
  PayorLevelDTO,
  PrimaryFormDTO,
  PTSecurityGroupDTO,
  RelationShipWithoutSelfDTO,
  SecondaryDTO,
  SuffixDTO,
  WriteOffCodeDTO,
  ZoneDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { RegionDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import {
  PatientPersonalService,
  PatientContactService,
  AddressService,
} from '../patient-proxy/patient-optimization';
import {
  PatientAddressDTO,
  PatientPersonalDTO,
  CreateUpdatePatientPersonalDTO,
  CreateUpdatePatientContactDTO,
  CreateUpdatePatientAddressDTO,
  PatientContactDTO,
} from '../patient-proxy/patient-optimization/dto';
import { drpContactType } from '../patient-proxy/patient/enumeration-data';
import { PatientSearchService } from '../patient-proxy/patient/patient-search.service';
import emailMask from 'text-mask-addons/dist/emailMask';
import { ColorCodingService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dropdowns/color-coding.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PatientAddressListComponent } from 'projects/shared/src/app/components/patient-address-list/patient-address-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { Title } from '@angular/platform-browser';
import { InboundDocumentService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { CommonService } from 'projects/shared/src/app/services/common.service';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-patients-tab',
  templateUrl: './patients-tab.component.html',
  styleUrls: ['./patients-tab.component.scss'],
  providers: [ListService],
})
export class PatientsTabComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() patientIdEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() patientAddressDataEmitter: EventEmitter<PatientAddressDTO> =
    new EventEmitter<PatientAddressDTO>();
  @Input() clientId: string = '';
  drpProviderlist: any[]=[];
  newPatientId: string=defaultGuid;
  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
        let formattedValue = input.value.replace(/[^0-9]/g, '');

        if (formattedValue.length > 5) {
            formattedValue = formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
        }

        input.value = formattedValue;
    }
}
  personalTabForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  drpAccountGroups: AccountGroupDTO[] = [];
  drpAccountGroupslist:AccountGroupDTO[] = [];
  txtAccountGroupFilter=new FormControl();
  dtxtEmergencyRelationshipTypeFilter= new FormControl();
  txtDeliveryCitiesFilter=new FormControl();
  accountGroup: string = '';
  drpBranchOffices: BranchOfficeDTO[] = [];
  branchOffice: string = '';
  drpGenderLoop: GenderDTO[] = [];
  gender: string = '';
  tenantId: string = defaultGuid;
  organizationId : string = defaultGuid;
  fileId: string = '';
  colorCodingName: string = '';
  drpProviders: any;
  txtProviderFilter= new FormControl();
  provider: string = '';
  drpZones: ZoneDTO[] = [];
  zone: string = '';
  selfRelationShipCode = 18;
  drpPTSecurityGroups: PTSecurityGroupDTO[] = [];
  ptSecurityGroup: string = '';
  drpCustomerTypes: CustomerTypeDTO[] = [];
  customerType: string = '';
  drpSuffixes: SuffixDTO[] = [];
  suffixName: string = '';
  drpmartailStatusLoop: MaritalStatusDTO[] = [];
  maritalStatus: string = '';
  drpClaimFillingCodes: ClaimFillingCodeDTO[] = [];
  // drpPlaceOfServices: PlaceOfServiceDTO[] = [];
  pos: string = '';
  facility: string = '';
  chartIdAddress: string = '';
  zones: string = '';
  drpColorCodings: ColorCodingDTO[] = [];
  drpDunnigMessages: DunningMessageDTO[] = [];
  drpDunnigMessageslist:DunningMessageDTO[] = [];
  dunningMessageFilter= new FormControl();
  dunningMessage: string = '';
  drpHoldReasons: HoldReasonDTO[] = [];
  holdReason: string = '';
  drpAssignment: AssignmentDTO[] = [];
  drpInsEdits: InsEditsDTO[] = [];
  drpPaymentCode: PaymentCodeDTO[] = [];
  drpWriteOffCode: WriteOffCodeDTO[] = [];
  drpAlternateCPTS: AlternateCPTSDTO[] = [];
  drpAlternatePOS: AlternatePOSDTO[] = [];
  drpAlternateTOS: AlternateTOSDTO[] = [];
  drpPrimaryForm: PrimaryFormDTO[] = [];
  drpSecondary: SecondaryDTO[] = [];
  drpClaimFillingIndicator: ClaimFillingIndicatorDTO[] = [];
  drpInsuranceGroup: InsuranceGroupDTO[] = [];
  payorLevel: PayorLevelDTO[] = [];
  drpColorCodes: ColorCodingDTO[] = [];
  drpLocationTypes: {
    id: string;
    organizationUnitName: string;
    tenantId: string;
  }[] = [];
  subscription$: Subscription[] = [];
  drpPolicies: any[] = [];
  isPatientPoliciesPresent: boolean = false;
  drpDeliCountry: RegionCountriesDTO[] = [];
  drpBillCountry: RegionCountriesDTO[] = [];
  drpBillStates: RegionStatesDTO[] = [];
  drpDeliStates: RegionStatesDTO[] = [];
  drpBillCities: any[] = [];
  drpDeliCities: any[] = [];
  showChart: boolean = false;
  emailMask: any;
  birthdate: Date;
  birthdateV1: string;
  //---------contact form fields-----------//
  relationShipWithoutSelves: RelationShipWithoutSelfDTO[] = [];
  relationShipWithoutSelveslist:RelationShipWithoutSelfDTO[] = [];
  drpEmerCountry: any[] = [];
  drpEmerStates: any[] = [];
  drpEmerCities: any[] = [];

  EmergencyCountry: string = '';
  EmergencyState: string = '';
  EmergencyCity: string = '';

  phoneEmerCode: string = '';
  relationship: string = '';
  panelOpenState: boolean = false;
  drpTitleLoop:any;
  txtdeliveryCountryFilter= new FormControl();
  public filteredDeliveryCountries: any;
  public filteredBillingStates: Observable<RegionStatesDTO[]> = of([]);
  public filteredBillingCities: Observable<RegionCitiesDTO[]> = of([]);
  public filteredDeliveryStates: Observable<RegionStatesDTO[]> = of([]);
  public filteredDeliveryCities: any;
  public filteredColorCodes: Observable<ColorCodingDTO[]> = of([]);
  public filteredEmergencyCountries:
    | Observable<RegionCountriesDTO[]>
    | undefined;
  public filteredEmergencyStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredEmergencyCities: Observable<RegionCitiesDTO[]> | undefined;

  @ViewChild('singleSelect') singleSelect: MatSelect;

  //to inject personalTabForm from personal tab page
  BirthdateMaxDate: Date;
  BirthdateMinDate: Date;
  @Input() patientId: string = defaultGuid;
  @Input() isButtonDisable:boolean;
  patientContactId: string = '';
  phoneDelCode: string = '';
  phoneCode: string = '';
  defaultGuid=defaultGuid;
  isShowSpinner: boolean = false;
  saveButtonHide: boolean = false;
  organizationUnitId = null;
  @Input() editPatientValue: any;
  @Input() ispersonalTabForm: boolean;
  @Output() nextTabMoveOnSaveEmitter: EventEmitter<string> =
    new EventEmitter<string>(null);
  @Output() savepersonalTabFormState: EventEmitter<any> =
    new EventEmitter<any>();
  location: string = '';
  isPatientUpdatePermission: boolean = false;
  isPatientCreatePermission: boolean = false;
  isShownUpdateIconForPatient: boolean = true;
  isShownSaveButton: boolean = true;
  isPatientLockedByCurrentUser: boolean = true;
  strLoginUserId: string = '';
  stateName: string = '';
  CountryName: string = '';
  DelstateName: string = '';
  DelCountryName: string = '';
  EmerstateName: string = '';
  EmerCountryName: string = '';
  showswal: boolean;
  public filteredBillingCountries: Observable<RegionCountriesDTO[]>;
  BillingCountry: string = '';
  BillingState: string = '';
  BillingCity: string = '';
  DeliveryCountry: string = '';
  DeliveryState: string = '';
  DeliveryCity: string = '';
  patientBAddressId: string = '';
  patientDAddressId: string = '';
  isEditButton: boolean = false;
  isNewPatient: any;

  constructor(
    private formBuilder: FormBuilder,
    private textValidators: TextValidators,
    private patientDropdownService: PatientMasterDropdownService,
    private Table: TableService,
    private providerList: MasterProviderService,
    private activatedRoute: ActivatedRoute,
    private region: RegionDropdownService,
    private ref: ChangeDetectorRef,
    private numberValidators: NumberValidators,
    private patientSearchService: PatientSearchService,
    private branchService: OrganizationUnitService,
    private organizationUnitService: OrganizationUnitService,
    private dashboardService: DashboardService,
    private patientPersonalService: PatientPersonalService,
    private patientContactService: PatientContactService,
    private patientAddressService: AddressService,
    private toastr: ToastrService,
    private colorCodingService: ColorCodingService,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private title:Title,
    private router: Router,
    private routerLocation: Location,
    public inboundDocUploadService: InboundDocumentService,
    private commonService : CommonService
  ) {
    const addressDetails =
      this.communicationService.addressFunctionCall$.subscribe(
        (addressDetails) => {
          this.loadAddressDetails(addressDetails);
        }
      );
    this.subscription$.push(addressDetails);
  }

  // On Page Destroy
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  ngOnInit(): void {

    // this.title.setTitle('Qsecure | Create Patient');
    this.tenantId = localStorage.getItem('tenantId') ?? defaultGuid;
    this.organizationId = localStorage.getItem('organizationId') ?? defaultGuid;
    const activatedRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.patientId = response.get('patientId')??defaultGuid;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(activatedRoute);
    this.emailMask = emailMask;
    if(this.patientId === defaultGuid|| this.patientId===null || this.patientId === undefined || this.patientId === ''){
      this.title.setTitle('Qsecure | Create Patient');
    }else{
      this.title.setTitle('Qsecure | View Patient');
    }

    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
    this.getdropdowns();
    // let data = this.patientPersonalService.getPatientData()

    //
    this.createpersonalTabForm();
    if(this.organizationId !== defaultGuid && this.patientId ===defaultGuid){
      this.getByChartId();
    }

    //this.billingCountryDropdown();
    //this.countryDeliDropdown();
    this.getCountryDropdown();

    this.getTitledrp();
    //  Automatically patch location if single content
    if (
      (this.patientId ?? defaultGuid) !== defaultGuid &&
      (this.tenantId ?? defaultGuid) !== defaultGuid
    ) {
      const organizationList = this.organizationUnitService
        .getOrganizationList(this.tenantId)
        .subscribe((response) => {
          response &&
            response.forEach((element) => {
              if (response.length == 1) {
                this.personalTabForm?.patchValue({
                  drpLocation: element?.organizationUnitName,
                });
              } else {
                this.personalTabForm?.patchValue({ drpLocation: '' });
              }
            });
        });
      this.subscription$.push(organizationList);
    }
    if(this.patientId !==defaultGuid){
      this.cookieService.set('patientStatus', 'false');

    }
    this.isNewPatient = this.cookieService.get('patientStatus');
    if (this.isNewPatient == 'true') {
      this.isEditButton = false;
      this.isButtonDisable=false;
    } else {
      this.isEditButton = true;
      this.personalTabForm.disable();
      this.isButtonDisable=true;
      // this.patientIdEmitter.emit();
    }
  }

  ngAfterViewInit() {}

  //GET Ttile Drop Down
  getTitledrp(){
    this.drpTitleLoop = [
      { titleName: 'Mr' },
      { titleName: 'Mrs' },
      { titleName: 'Ms'},
    ]
  }
  loadAddressDetails(addressDetails){
    if (addressDetails != null) {
      this.EmerstateName = addressDetails?.state;
      this.EmerCountryName = addressDetails?.country;
      (this.EmerstateName != null || this.EmerstateName != undefined) &&
        this.onChangeBillCity(this.EmerstateName, this.EmerCountryName);
      this.personalTabForm.patchValue({
        // chkVerified: addressDetails?.isGoogleValidated ?? false,
        // txtNameLine: addressDetails?.nameLine ?? '',
        // txtDescription: addressDetails?.description ?? '',
        txtBillingAddress: addressDetails?.address ?? '',
        drpBillingCountry: addressDetails?.country,
        txtBillingState: addressDetails?.state,
        txtBillingCity: addressDetails?.city,
        txtBillingPostalCode: addressDetails?.postalCode ?? '',
        txtBillingPhone: addressDetails?.phone ?? '',
        txtBillingEmailAddress: addressDetails?.emailId ?? '',
        txtBillingMobile: addressDetails?.mobile ?? '',
      });
    }
  }
  //! Form Creation and Few Validations
  createpersonalTabForm() {
    this.personalTabForm = this.formBuilder.group({
      drpTitle: new FormControl(null),
      txtEmergencyNo:new FormControl(''),
      txtSSN: new FormControl('', [Validators.minLength(4)]),
      txtAccountNumber: new FormControl('', [Validators.minLength(9)]),
      chkHipaaSignature: new FormControl(''),
      txtDeliveryPhone: new FormControl(''),
      txtChartId: new FormControl(null, Validators.required),
      drpLocation: new FormControl(null, Validators.required),
      txtFirstName: new FormControl('', [
        Validators.required,
        // this.textValidators.isTextCheck,
      ]),
      txtLastName: new FormControl('', [
        Validators.required,
        // this.textValidators.isTextCheck,
      ]),
      txtMiddleName: new FormControl(''),
      txtBirthdate: new FormControl(null, [Validators.required]),
      txtHomePhoneNo: new FormControl(''),
      txtWorkPhoneNo: new FormControl(''),
      txtCellNo:new FormControl(''),
      drpAccountGroup: new FormControl(null, Validators.required),
      txtProvider: new FormControl('', [Validators.required]),
      drpGender: new FormControl(null, [Validators.required]),
      drpColorCodes: new FormControl(''),
      txtColorCodeFilter: new FormControl(''),
      txtBillingEmailAddress: new FormControl(''),
      txtCatogery: new FormControl(''),
      drpColor: new FormControl(null),
      drpCustomerType: new FormControl(null),
      drpSuffix: new FormControl(null),
      drpBillingCountry: new FormControl(''),
      txtBillingCountryFilter: new FormControl(''),
      txtBillingStateFilter: new FormControl(''),
      txtBillingCityFilter: new FormControl(''),
      txtBillingAddress: new FormControl(''),
      txtBillingCity: new FormControl(''),
      drpBillingCounty: new FormControl('', this.textValidators.isTextCheck),
      txtBillingPostalCode: new FormControl(''),
      txtBillingPhone: new FormControl(''),
      txtBillingFax: new FormControl(''),
      drpBillingZone: new FormControl(null),
      txtBillingMobile: new FormControl(''),
      txtBillingState: new FormControl(null),
      txtDeliveryCountryFilter: new FormControl(''),
      txtDeliveryStateFilter: new FormControl(''),
      txtDeliveryCityFilter: new FormControl(''),
      txtDeliveryAddress: new FormControl(''),
      txtDeliveryCity: new FormControl(null),
      txtDeliveryState: new FormControl(null),
      drpDeliveryCounty: new FormControl(null, this.textValidators.isTextCheck),
      drpDeliveryCountry: new FormControl(null),
      txtDeliveryPostalCode: new FormControl(''),
      txtDeliveryFax: new FormControl(''),
      txtDeliveryMobile: new FormControl(''),
      txtDeliveryEmailAddress: new FormControl(''),
      drpZone: new FormControl(null),
      chkCashPayment: new FormControl(''),
      chkStat: new FormControl(''),
      txtPatientBalance: new FormControl(
        '',
        this.numberValidators.isNumberCheck
      ),
      txtInsuranceBalance: new FormControl(
        '',
        this.numberValidators.isNumberCheck
      ),
      txtTotalBalance: new FormControl('', this.numberValidators.isNumberCheck),
      txtAlerts: new FormControl(''),
      drpHoldReason: new FormControl(''),
      chkCollection: new FormControl(''),
      drpDunningMessage: new FormControl(''),
      chkAutoDunning: new FormControl(''),
      drpMarital: new FormControl(null),
      drpEmergencyRelationshipType: new FormControl(null),
      txtEmergencyFirstName: new FormControl(''),
      txtEmergencyMiddleName: new FormControl(''),
      txtEmergencyLastName: new FormControl(''),
      txtEmergencyAddress: new FormControl(''),
      txtEmergencyCity: new FormControl(null),
      txtEmergencyState: new FormControl(null),
      txtEmergencyCounty: new FormControl('', this.textValidators.isTextCheck),
      txtEmergencyCountry: new FormControl(null),
      txtEmergencyPostalCode: new FormControl(''),
      txtEmergencyPhone: new FormControl(
        '',
        this.numberValidators.isNumberCheck
      ),
      txtEmergencyMobile: new FormControl(
        '',
        this.numberValidators.isNumberCheck
      ),
      txtEmergencyEmail: new FormControl(''),
      txtEmergencyFax: new FormControl('', this.numberValidators.isNumberCheck),
      txtEmergencyCountryFilter: new FormControl(''),
      txtEmergencyStateFilter: new FormControl(''),
      txtEmergencyCityFilter: new FormControl(''),
      phones: this.formBuilder.array([this.formBuilder.control(null)]),
    });

    const valueChanges = this.personalTabForm.valueChanges.subscribe(
      () => {
        let firstname: AbstractControl =
          this.personalTabForm.get('txtFirstName');
        if (firstname.dirty) {
          firstname?.value?.trim() === '' &&
            firstname?.setErrors({ required: true });
          firstname?.markAsDirty();
          firstname?.markAsTouched();
          // firstname.markAsPristine();
        }

        let lastname: AbstractControl = this.personalTabForm.get('txtLastName');
        if (lastname.dirty) {
          lastname?.value?.trim() === '' &&
            lastname?.setErrors({ required: true });
          lastname?.markAsDirty();
          lastname?.markAsTouched();
          //   firstname.markAsPristine();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(valueChanges);
    //
    this.BirthdateMaxDate = new Date();
    this.BirthdateMaxDate.setDate(this.BirthdateMaxDate.getDate());
    this.BirthdateMinDate = new Date();
    this.BirthdateMinDate.setFullYear(
      this.BirthdateMinDate.getFullYear() - 120
    );
    this.emailMask = this.emailMask;
    this.patientId === defaultGuid && this.getByChartId();
    this.phoneCode = '';
    this.phoneDelCode = '';
  }
  editForm() {
    this.isEditButton = false;
    this.personalTabForm.enable();
  }
  //! Perosnal Details Get By ID Functions
  getPatientDetailsByID() {
    if ((this.patientId ?? defaultGuid) !== defaultGuid) {
      const combinedRequests = forkJoin([
        this.patientPersonalService.getPatientByIdByGPatientId(this.patientId),
        this.patientAddressService.getAddressByPatientIdByPatientId(
          this.patientId
        ),
        this.patientContactService.getPatientContactByPatientIdByPatientId(
          this.patientId
        ),
      ]);

      if (this.isNewPatient == 'false') {
        this.personalTabForm.disable();
        this.personalTabForm.controls['txtHomePhoneNo'].disable();
        this.personalTabForm.controls['txtWorkPhoneNo'].disable();
        this.personalTabForm.controls['txtBillingPhone'].disable();
        this.personalTabForm.controls['txtBillingFax'].disable();
        this.personalTabForm.controls['txtBillingMobile'].disable();
      }

      const storeSubscription = combinedRequests?.subscribe(
        (response) => {
          const selectedPatientPersonal: PatientPersonalDTO =
            response?.[0] ?? null;
          const selectedPatientBAddress: PatientAddressDTO =
            response?.[1]?.[0] ?? null;
          const selectedPatientDAddress: PatientAddressDTO =
            response?.[1]?.[1] ?? null;
          const selectedContact: PatientContactDTO = response?.[2]?.[0] ?? null;
          this.patientContactId = selectedContact?.id ?? defaultGuid;
          this.patientBAddressId = selectedPatientBAddress?.id ?? defaultGuid;
          this.patientDAddressId = selectedPatientDAddress?.id ?? defaultGuid;
          this.stateName = selectedPatientBAddress?.state;
          this.CountryName = selectedPatientBAddress?.country;
          this.DelCountryName = selectedPatientDAddress?.country;
          this.DelstateName = selectedPatientDAddress?.state;
          this.EmerstateName = selectedContact?.state;
          this.EmerCountryName = selectedContact?.country;

          this.phoneCode = '';
          this.phoneDelCode = '';
          this.EmerCountryName != null &&
            this.EmerCountryName !== '' &&
            this.EmerCountryName != undefined &&
            this.onChangeEmerState(this.EmerCountryName);
          this.EmerstateName != null &&
            this.EmerstateName !== '' &&
            this.EmerstateName != undefined &&
            this.onChangeEmerCity(this.EmerstateName, this.EmerCountryName);
          this.CountryName != null &&
            this.CountryName !== '' &&
            this.CountryName != undefined &&
            this.onChangeBillState(this.CountryName) &&
            this.personalTabForm?.patchValue({
              drpBillingCountry: this.CountryName,
            });
          this.stateName != null &&
            this.stateName !== '' &&
            this.stateName != undefined &&
            this.onChangeBillCity(this.stateName, this.CountryName) &&
            this.personalTabForm?.patchValue({
              txtBillingState: this.stateName,
            });
          this.DelCountryName != null &&
            this.DelCountryName !== '' &&
            this.DelCountryName != undefined &&
            this.onChangedeliState(this.DelCountryName) &&
            this.personalTabForm?.patchValue({
              drpDeliveryCountry: this.DelCountryName,
            });
          this.DelstateName != null &&
            this.DelstateName !== '' &&
            this.DelstateName != undefined &&
            this.onChangeDeliCity(this.DelstateName, this.DelCountryName) &&
            this.personalTabForm?.patchValue({
              txtDeliveryState: this.DelstateName,
            });
            // if(selectedPatientPersonal?.dateOfBirth!==null){
            //   this.birthdate = new Date(selectedPatientPersonal?.dateOfBirth);
            //   this.birthdate.setDate(this.birthdate.getDate() );
            //   this.birthdateV1 = this.birthdate.toISOString().split('T')[0];
            // this.personalTabForm
            // .get('txtBirthdate')
            // .setValue(this.birthdateV1)
            // }
          this.colorCodingName =
            this.drpColorCodes?.filter(
              (c) => c?.id == selectedPatientPersonal?.colorCodingId
            )?.[0]?.colorCode ?? null;
          selectedPatientPersonal !== null &&
            this.personalTabForm?.patchValue({
              drpGender: selectedPatientPersonal?.gender,
              drpColorCodes:
                this.drpColorCodes?.filter(
                  (c) => c?.id == selectedPatientPersonal?.colorCodingId
                )?.[0]?.colorCode ?? null,

              txtProvider: selectedPatientPersonal?.providerId,
              drpLocation: selectedPatientPersonal?.locationId,
              drpTitle:selectedPatientPersonal?.title,
              txtFirstName: selectedPatientPersonal?.firstName,
              txtLastName: selectedPatientPersonal?.lastName,
              txtMiddleName: selectedPatientPersonal?.middleName,
              drpSuffix: selectedPatientPersonal?.suffix,
              txtSSN: selectedPatientPersonal?.ssn,
              txtHomePhoneNo: selectedPatientPersonal?.homePhoneNo,
              txtWorkPhoneNo: selectedPatientPersonal?.workPhoneNo,
              txtCellNo:selectedPatientPersonal?.cellNo,
              txtEmergencyNo:selectedPatientPersonal?.emergencyContact,
              txtAccountNumber: selectedPatientPersonal?.accountNumber,
              drpCustomerType: selectedPatientPersonal?.customerType,
              drpAccountGroup: selectedPatientPersonal?.accountGroupType,
              txtBillingAddress: selectedPatientBAddress?.address,
              txtBillingCity: selectedPatientBAddress?.city,
              drpBillingCounty: selectedPatientBAddress?.county,
              drpBillingCountry: selectedPatientBAddress?.country
                ? selectedPatientBAddress?.country
                : 'US',
              txtBillingPostalCode: selectedPatientBAddress?.postalCode,
              txtBillingPhone: selectedPatientBAddress?.phone,
              txtBillingFax: selectedPatientBAddress?.fax,
              drpBillingZone: selectedPatientBAddress?.zone,
              txtBillingMobile: selectedPatientBAddress?.mobile,
              txtBillingState: selectedPatientBAddress?.state,
              txtDeliveryAddress: selectedPatientDAddress?.address,
              txtDeliveryCity: selectedPatientDAddress?.city,
              txtDeliveryState: selectedPatientDAddress?.state,
              drpDeliveryCounty: selectedPatientDAddress?.county,
              drpDeliveryCountry: selectedPatientDAddress?.country,
              txtDeliveryPostalCode: selectedPatientDAddress?.postalCode,
              txtDeliveryPhone: selectedPatientDAddress?.phone,
              txtDeliveryFax: selectedPatientDAddress?.fax,
              txtDeliveryMobile: selectedPatientDAddress?.mobile,
              drpZone: selectedPatientDAddress?.zone,
              txtBillingEmailAddress: selectedPatientPersonal?.emailId,
              txtBirthdate : selectedPatientPersonal?.dateOfBirth,
              // txtBirthdate:
              //   selectedPatientPersonal?.dateOfBirth === null
              //     ? ''
              //     : selectedPatientPersonal?.dateOfBirth,
              txtChartId: selectedPatientPersonal?.chartId,
              drpMarital: selectedPatientPersonal?.maritalStatus,
              drpHoldReason: selectedPatientPersonal?.holdReason,
              drpDunningMessage: selectedPatientPersonal?.autoDunningId,
              chkAutoDunning: selectedPatientPersonal?.isAutoDunning,
              chkHipaaSignature:selectedPatientPersonal?.patientHippaSignatureOnLife ,
              chkCollection:selectedPatientPersonal?.patientCollection,
              txtAlerts:selectedPatientPersonal?.patientAlerts ,
              chkCashPayment: selectedPatientPersonal?.isCashPayment,
              chkStat: selectedPatientPersonal?.isStat,
              drpEmergencyRelationshipType: selectedContact?.relationshipId,
              txtEmergencyFirstName: selectedContact?.firstName,
              txtEmergencyMiddleName: selectedContact?.middleName,
              txtEmergencyLastName: selectedContact?.lastName,
              txtEmergencyFax: selectedContact?.fax,
              txtEmergencyPhone: selectedContact?.phone,
              txtEmergencyMobile: selectedContact?.mobile,
              txtEmergencyEmail: selectedContact?.emailAddress || '',
              txtEmergencyPostalCode: selectedContact?.postalCode,
              txtEmergencyCounty: selectedContact?.county,
              txtEmergencyAddress: selectedContact?.address,
              txtEmergencyCity: selectedContact?.city,
              txtEmergencyState: selectedContact?.state,
              txtEmergencyCountry: selectedContact?.country?? defaultCountry.US,
            });
            this.chartIdAddress=selectedPatientPersonal?.chartId;
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
      this.subscription$.push(storeSubscription);
    }
  }

  //! chartid get
  getByChartId() {
    // this.personalTabForm.controls['txtChartId'].disable();
    if(this.organizationId==='null'||this.organizationId===null||this.organizationId===undefined){
      this.organizationId=defaultGuid;
    }
    this.showChart = true;
    const generateChartId = this.inboundDocUploadService
      .generateChartId(this.organizationId)
      .subscribe((response) => {

        this.personalTabForm?.patchValue({ txtChartId: response });
      });
    this.subscription$.push(generateChartId);
  }

  //! Master Dropdown Funtion
  getdropdowns() {
    let patientDropdownInput: PatientDropdowns[];
    patientDropdownInput = [
      PatientDropdowns.AccountGroups,
      PatientDropdowns.BranchOffices,
      PatientDropdowns.Zones,
      PatientDropdowns.SecurityGroups,
      PatientDropdowns.CustomerTypes,
      PatientDropdowns.Suffixes,
      PatientDropdowns.Genders,
      PatientDropdowns.MaritalStatus,
      PatientDropdowns.ClaimFillingCodes,
      PatientDropdowns.PlaceOfServices,
      PatientDropdowns.Facilities,
      PatientDropdowns.ColorCodings,
      PatientDropdowns.Categories,
      PatientDropdowns.DunningMessages,
      PatientDropdowns.HoldReasons,
      PatientDropdowns.Assignments,
      PatientDropdowns.InsEdits,
      PatientDropdowns.PaymentCodes,
      PatientDropdowns.WriteOffCodes,
      PatientDropdowns.AlternateCpts,
      PatientDropdowns.AlternatePos,
      PatientDropdowns.AlternateTos,
      PatientDropdowns.PrimaryForm,
      PatientDropdowns.Secondary,
      PatientDropdowns.PlanCategories,
      PatientDropdowns.ClaimFillingIndicators,
      PatientDropdowns.InsuranceGroups,
      PatientDropdowns.PayorLevels,
      PatientDropdowns.RelationShipWithSelf,
      PatientDropdowns.RelationShipWithoutSelf,
    ];

    const patientDropdown = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          let response: PatientMasterDropdownDTO = stateResponse;
          this.drpBranchOffices = response?.branchOffices;
          this.drpAccountGroupslist = response?.accountGroups;
          this.drpZones = response?.zones;
          this.drpPTSecurityGroups = response?.ptSecurityGroups;
          this.drpCustomerTypes = response?.customerTypes;
          this.drpSuffixes = response?.suffixes;
          this.drpGenderLoop = response?.genders;
          this.drpmartailStatusLoop = response.maritalStatuses;
          this.drpClaimFillingCodes = response?.claimFillingCodes;
          this.drpColorCodings = response?.lstColorCodings;
          this.drpDunnigMessageslist = response?.lstDunnigMessages;
          this.drpHoldReasons = response?.lstHoldReasons;
          this.drpAssignment = response?.lstAssignment;
          this.drpInsEdits = response?.lstInsEdits;
          this.drpClaimFillingIndicator = response?.lstClaimFillingIndicator;
          this.drpInsuranceGroup = response?.lstInsuranceGroup;
          this.payorLevel = response?.payorLevels;
          this.relationShipWithoutSelveslist = response?.relationShipWithoutSelves;
          this.drpAccountGroups=this.drpAccountGroupslist;
          this.txtAccountGroupFilter.valueChanges.subscribe((dxTypeFilterText) => {
            dxTypeFilterText = dxTypeFilterText.toLowerCase();
            this.drpAccountGroups = this.drpAccountGroupslist.filter((dx) =>
              dx.accountGroup.toLowerCase().includes(dxTypeFilterText)
            );
          });
          this.relationShipWithoutSelves=this.relationShipWithoutSelveslist;
          this.dtxtEmergencyRelationshipTypeFilter.valueChanges.subscribe((dxTypeFilterText) => {
            dxTypeFilterText = dxTypeFilterText.toLowerCase();
            this.relationShipWithoutSelves = this.relationShipWithoutSelveslist.filter((dx) =>
              dx.relationType.toLowerCase().includes(dxTypeFilterText)
            );
          });
          this.drpDunnigMessages=this.drpDunnigMessageslist;
          this.dunningMessageFilter.valueChanges.subscribe((dxTypeFilterText) => {
            dxTypeFilterText = dxTypeFilterText.toLowerCase();
            this.drpDunnigMessages = this.drpDunnigMessageslist.filter((dx) =>
              dx.dunningMessage.toLowerCase().includes(dxTypeFilterText)
            );
          });

          (this.patientId ?? defaultGuid) !== defaultGuid &&
            this.getPatientDetailsByID();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientDropdown);
    const providerList = this.providerList
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe((response) => {
        response?.items?.forEach((element) => {
          this.drpProviderlist.push({
            id: element?.id,
            name: element?.fullName,
            shortCodeId: element?.shortCodeId,
            fullName: element?.fullName,
            npi: element?.npi,
          });
        });
       this.drpProviders=this.drpProviderlist;
       this.txtProviderFilter.valueChanges.subscribe((dxTypeFilterText) => {
        dxTypeFilterText = dxTypeFilterText.toLowerCase();
        this.drpProviders = this.drpProviderlist.filter((dx) =>
          dx.name.toLowerCase().includes(dxTypeFilterText)
        );
      });
      });
    this.subscription$.push(providerList);

    const userProfile = this.dashboardService
      .userProfile()
      .subscribe((response) => {
        this.clientId = response.tenantId;
        const organizationList = this.branchService
          .getOrganizationListV1(this.clientId,false)
          .subscribe(
            (response) => {
              response?.forEach((element) => {
                this.drpLocationTypes.push({
                  id: element?.id,
                  organizationUnitName: element?.organizationUnitName,
                  tenantId: element?.tenantId,
                });
              });
            },
            (err) => {
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
        this.subscription$.push(organizationList);
      });
    this.subscription$.push(userProfile);

    //Get Color Codes
    const colorCodes = this.colorCodingService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          response?.items?.forEach((element) => {
            this.drpColorCodes.push({
              id: element?.id,
              colorCode: element?.colorCode,
              colorCodingName: element?.colorCodingName,
            });
          });

          this.filteredColorCodes = this.personalTabForm
            ?.get('txtColorCodeFilter')
            ?.valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpColorCodes?.filter((option) =>
                  option?.colorCodingName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(colorCodes);
  }

  // billing Country Dropdown
  // billingCountryDropdown() {
  //   this.onChangeBillState(defaultCountry.US);
  //   this.onChangedeliState(defaultCountry.US);
  //   this.DeliveryCountry = defaultCountry.US;
  //   this.BillingCountry = defaultCountry.US;
  //   this.drpBillCountry = [];
  //   const countryDropdown = this.region
  //     .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
  //     .subscribe(
  //       (response) => {
  //         this.drpBillCountry = response?.items;
  //         this.personalTabForm?.patchValue({ "drpBillingCountry": defaultCountry.US })

  //         this.filteredBillingCountries = this.personalTabForm
  //           .get('txtBillingCountryFilter')
  //           .valueChanges?.pipe(
  //             startWith(''),
  //             map((value) =>
  //               this.drpBillCountry.filter((option) =>
  //                 option?.countryName
  //                   ?.toLowerCase()
  //                   ?.includes(value?.toLowerCase() ?? '')
  //               )
  //             )
  //           );
  //       },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'error',
  //           text: data?.error?.error?.message,
  //         });
  //       }
  //     );
  //   this.subscription$.push(countryDropdown);
  // }

  // delivery country //
  // countryDeliDropdown() {
  //   this.drpDeliCountry = [];
  //   const deliCountryDropdown = this.region
  //     .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
  //     .subscribe((response) => {
  //       this.drpDeliCountry = response?.items ?? [];
  //       this.personalTabForm?.patchValue({ "drpDeliveryCountry": defaultCountry.US })

  //       this.filteredDeliveryCountries = this.personalTabForm
  //         .get('txtDeliveryCountryFilter')
  //         .valueChanges?.pipe(
  //           startWith(''),
  //           map((value) =>
  //             this.drpDeliCountry.filter((option) =>
  //               option?.countryName
  //                 ?.toLowerCase()
  //                 ?.includes(value?.toLowerCase() ?? '')
  //             )
  //           )
  //         );
  //     });
  //   this.subscription$.push(deliCountryDropdown);
  // }

  // billing state city//
  onChangeBillState(value: string | null | undefined): boolean {
    this.personalTabForm?.patchValue({
      txtBillingState: null,
      txtBillingCity: null,
    });
    this.drpBillStates = [];
    this.drpBillCities = [];
    this.filteredBillingCities=null;
    this.filteredBillingStates=null;
    if (value !== null && value !== undefined) {
      const stateDropdown = this.region
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpBillStates = response?.items ?? [];
            this.ref.detach();
            if (response?.items?.length == 0) {
              this.personalTabForm.controls['txtHomePhoneNo'].disable();
              this.personalTabForm.controls['txtWorkPhoneNo'].disable();
              this.personalTabForm.controls['txtBillingPhone'].disable();
              this.personalTabForm.controls['txtBillingFax'].disable();
              this.personalTabForm.controls['txtBillingMobile'].disable();
              this.phoneCode = '';
            } else {
              // this.personalTabForm.controls['txtHomePhoneNo'].enable();
              // this.personalTabForm.controls['txtWorkPhoneNo'].enable();
              // this.personalTabForm.controls['txtBillingPhone'].enable();
              // this.personalTabForm.controls['txtBillingFax'].enable();
              // this.personalTabForm.controls['txtBillingMobile'].enable();
              this.phoneCode = response?.items[0]?.countryPhoneCode ??'1';
            }
            this.ref.reattach();
            this.filteredBillingStates = this.personalTabForm
              .get('txtBillingStateFilter')
              .valueChanges?.pipe(
                startWith(''),
                map((value) =>
                  this.drpBillStates?.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            this.phoneCode = '1';
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(stateDropdown);
    }

    return this.drpBillStates && this.drpBillStates?.length !== 0;
  }

  onChangeBillCity(state: string | null | undefined, country: string): boolean {
    this.personalTabForm?.patchValue({
      txtBillingCity: null,
    });
    country =
      country == ''
        ? this.personalTabForm.value.drpBillingCountry == (null || undefined)
          ? ''
          : this.personalTabForm.value.drpBillingCountry
        : country;
    this.drpBillCities = [];
    if (state !== null && state !== undefined) {
      const cityDropdown = this.region
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpBillCities = response.items;
            this.filteredBillingCities = this.personalTabForm
              .get('txtBillingCityFilter')
              .valueChanges?.pipe(
                startWith(''),
                map((value) =>
                  this.drpBillCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(cityDropdown);
    }
    return this.drpBillCities && this.drpBillCities?.length !== 0;
  }

  // deli state city
  onChangedeliState(value: string | null | undefined): boolean {
    this.personalTabForm?.patchValue({
      txtDeliveryState: null,
      txtDeliveryCity: null,
    });
    this.drpDeliStates = [];
    this.drpDeliCities = [];
    this.filteredDeliveryStates=null;
    this.filteredDeliveryCities=null;
    if (value !== null && value !== undefined) {
      const stateDropdown = this.region
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpDeliStates = response?.items ?? [];
            this.ref.detach();
            if (response?.items?.length == 0) {
              this.personalTabForm.controls['txtDeliveryPhone'].disable();
              this.personalTabForm.controls['txtDeliveryFax'].disable();
              this.personalTabForm.controls['txtDeliveryMobile'].disable();
              this.phoneDelCode = '';
            } else {
              // this.personalTabForm.controls['txtDeliveryPhone'].enable();
              // this.personalTabForm.controls['txtDeliveryFax'].enable();
              // this.personalTabForm.controls['txtDeliveryMobile'].enable();
              this.phoneDelCode = response?.items[0]?.countryPhoneCode??'1';
            }
            this.ref.reattach();
            this.filteredDeliveryStates = this.personalTabForm
              .get('txtDeliveryStateFilter')
              .valueChanges?.pipe(
                startWith(''),
                map((value) =>
                  this.drpDeliStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            this.phoneDelCode = '1';
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(stateDropdown);
    }

    return this.drpDeliStates && this.drpDeliStates?.length !== 0;
  }

  onChangeDeliCity(state: string | null | undefined, country: string): boolean {
    this.personalTabForm?.patchValue({
      txtDeliveryCity: null,
    });
    country =
      country == ''
        ? this.personalTabForm.value.drpDeliveryCountry == (null || undefined)
          ? ''
          : this.personalTabForm.value.drpDeliveryCountry
        : country;
    this.drpDeliCities = [];
    if (state !== null && state !== undefined) {
      const cityDropdown = this.region
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpDeliCities = response.items;
            this.filteredDeliveryCities = this.drpDeliCities;
              this.txtDeliveryCitiesFilter.valueChanges.subscribe((dxTypeFilterText) => {
                dxTypeFilterText = dxTypeFilterText.toLowerCase();
                this.filteredDeliveryCities = this.drpDeliCities.filter((dx) =>
                  dx.cityName.toLowerCase().includes(dxTypeFilterText)
                );
              });
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(cityDropdown);
    }
    return this.drpDeliCities && this.drpDeliCities?.length !== 0;
  }

  //! Copy Patient Address Info
  copyPatient() {
    this.phoneDelCode = this.phoneCode;
    const drpBillingCountry =
      this.personalTabForm.value.drpBillingCountry ?? '';
    const drpBillingState = this.personalTabForm.value.txtBillingState ?? '';

    drpBillingCountry != '' && this.onChangedeliveryState(drpBillingCountry);
    drpBillingCountry != '' &&
      drpBillingState != '' &&
      this.onChangeDeliCity(drpBillingState, drpBillingCountry);


    this.personalTabForm?.patchValue({
      txtDeliveryAddress: this.personalTabForm.get('txtBillingAddress').value,
      drpDeliveryCounty: this.personalTabForm.get('drpBillingCounty').value,
      drpDeliveryCountry: this.personalTabForm.get('drpBillingCountry').value,
      txtDeliveryState: this.personalTabForm.value.txtBillingState,
       txtDeliveryCity: this.personalTabForm.get('txtBillingCity').value,
      txtDeliveryPostalCode: this.personalTabForm.get('txtBillingPostalCode')
        .value,
      txtDeliveryPhone: this.personalTabForm.get('txtBillingPhone').value,
      txtDeliveryFax: this.personalTabForm.get('txtBillingFax').value,
      txtDeliveryMobile: this.personalTabForm.get('txtBillingMobile').value,
      drpZone: this.personalTabForm.get('drpBillingZone').value,
    });
  }
  //! Copy Patient Address Info
  copyBillingAddress() {
    const emergencyCountry = this.personalTabForm.value.drpBillingCountry ?? '';
    const emergencyState = this.personalTabForm.value.txtBillingState ?? '';
    if (emergencyCountry != '' && this.onChangeEmerState(emergencyCountry)) {
      this.personalTabForm?.patchValue({
        txtEmergencyState: emergencyState,
      });
      this.EmergencyState = this.BillingState;
    }
    if (
      emergencyState != '' &&
      this.onChangeEmerCity(emergencyState, emergencyCountry)
    ) {
      this.personalTabForm?.patchValue({
        txtEmergencyCity: this.personalTabForm.get('txtBillingCity').value,
      });
      this.EmergencyCity = this.BillingCity;
    }
    this.personalTabForm?.patchValue({
      txtEmergencyAddress: this.personalTabForm.get('txtBillingAddress').value,
      txtEmergencyCountry: this.personalTabForm.get('drpBillingCountry').value,
      txtEmergencyCounty: this.personalTabForm.get('drpBillingCounty').value,
      txtEmergencyPostalCode: this.personalTabForm.get('txtBillingPostalCode')
        .value,
      txtEmergencyPhone: this.personalTabForm.get('txtBillingPhone').value,
      txtEmergencyFax: this.personalTabForm.get('txtBillingFax').value,
      txtEmergencyMobile: this.personalTabForm.get('txtBillingMobile').value,
      txtEmergencyEmail: this.personalTabForm.get('txtBillingEmailAddress').value || '',
      txtEmergencyFirstName: this.personalTabForm.get('txtFirstName').value,
      txtEmergencyMiddleName: this.personalTabForm.get('txtMiddleName').value,
      txtEmergencyLastName: this.personalTabForm.get('txtLastName').value,
    });

    this.EmergencyCountry = this.BillingCountry;
    this.phoneEmerCode = this.phoneCode;
    this.panelOpenState = true;
  }

  onChangedeliveryState(value: string | null | undefined): boolean {
    this.personalTabForm?.patchValue({
      txtDeliveryState: null,
      txtDeliveryCity: null,
    });

    this.drpDeliStates = [];
    this.drpDeliCities = [];
    if (value !== null && value !== undefined) {
      const stateDropdown = this.region
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpDeliStates = response.items;
            this.filteredDeliveryStates = this.personalTabForm
              .get('txtDeliveryStateFilter')
              .valueChanges?.pipe(
                startWith(''),
                map((value) =>
                  this.drpDeliStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(stateDropdown);
    }
    return this.drpDeliStates && this.drpDeliStates?.length !== 0;
  }

  onChangeDeliveryCity(
    state: string | null | undefined,
    country: string
  ): boolean {
    this.personalTabForm?.patchValue({
      txtDeliveryCity: null,
    });
    country =
      country == ''
        ? this.personalTabForm.value.drpDeliveryCountry == (null || undefined)
          ? ''
          : this.personalTabForm.value.drpDeliveryCountry
        : country;
    this.drpDeliCities = [];
    if (state !== null && state !== undefined) {
      const cityDropdown = this.region
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpDeliCities = response.items;
            this.filteredDeliveryCities = this.personalTabForm
              .get('txtDeliveryCityFilter')
              .valueChanges?.pipe(
                startWith(''),
                map((value) =>
                  this.drpDeliCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(cityDropdown);
    }
    return this.drpDeliCities && this.drpDeliCities?.length !== 0;
  }

  // //Emergency Country Dropdown
  getCountryDropdown() {
    this.drpEmerCountry = [];
    this.drpDeliCountry = [];
    this.drpBillCountry = [];
    this.DeliveryCountry = defaultCountry.US;
    this.BillingCountry = defaultCountry.US;
    this.EmergencyCountry = defaultCountry.US;
    const getCountrySubscription = this.region
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .pipe(
        map((response) => {
          this.drpEmerCountry = response?.items ?? [];
          this.drpDeliCountry = response?.items ?? [];
          this.drpBillCountry = response?.items ?? [];
          this.phoneEmerCode =
            response?.items?.filter(
              (x) => x.countryShortName === defaultCountry.US
            )?.[0]?.countryPhoneCode ?? '1';
          return response;
        }),
        tap((x) => {
          this.DeliveryCountry = defaultCountry.US;
          this.BillingCountry = defaultCountry.US;
          this.EmergencyCountry = defaultCountry.US;
          this.personalTabForm?.patchValue(
            { txtEmergencyCountry: defaultCountry.US },
            { emitEvent: true }
          );
          this.personalTabForm?.patchValue(
            { drpDeliveryCountry: defaultCountry.US },
            { emitEvent: true }
          );
          this.personalTabForm?.patchValue(
            { drpBillingCountry: defaultCountry.US },
            { emitEvent: true }
          );
        }),
        map((x) => {
          this.onChangeBillState(defaultCountry.US);
          this.onChangeEmerState(defaultCountry.US);
          this.onChangedeliState(defaultCountry.US);
        })
      )
      .subscribe(
        (response) => {
          this.filteredEmergencyCountries = this.personalTabForm
            .get('txtEmergencyCountryFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpEmerCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

          this.filteredDeliveryCountries = this.drpDeliCountry
          this.txtdeliveryCountryFilter.valueChanges.subscribe((dxTypeFilterText) => {
            dxTypeFilterText = dxTypeFilterText.toLowerCase();
            this.filteredDeliveryCountries = this.drpDeliCountry.filter((dx) =>
              dx.countryName.toLowerCase().includes(dxTypeFilterText)
            );
          });

          this.filteredBillingCountries = this.personalTabForm
            .get('txtBillingCountryFilter')
            .valueChanges?.pipe(
              startWith(''),
              map((value) =>
                this.drpBillCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          this.phoneEmerCode='1'
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getCountrySubscription);
  }

  onChangeEmerState(value: string | null | undefined) {
    this.personalTabForm?.patchValue({
      txtEmergencyCity: null,
      txtEmergencyState: null,
    });
    this.drpEmerStates = [];
    this.drpEmerCities = [];
    this.filteredEmergencyCities=null;
    this.filteredEmergencyStates=null;
    if (value !== null && value !== undefined) {
      const emerStateSubscription = this.region
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpEmerStates = response?.items;
            if (response?.items?.length == 0) {
              this.phoneEmerCode = '';
              this.personalTabForm.controls['txtEmergencyFax'].disable();
              this.personalTabForm.controls['txtEmergencyPhone'].disable();
              this.personalTabForm.controls['txtEmergencyMobile'].disable();
            } else {
              // this.personalTabForm.controls['txtEmergencyFax'].enable();
              // this.personalTabForm.controls['txtEmergencyPhone'].enable();
              // this.personalTabForm.controls['txtEmergencyMobile'].enable();
              this.phoneEmerCode = response?.items[0]?.countryPhoneCode;
            }
            this.filteredEmergencyStates = this.personalTabForm
              .get('txtEmergencyStateFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpEmerStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(emerStateSubscription);
    }
    return of(this.drpEmerCountry && this.drpEmerCountry?.length !== 0);
  }

  onChangeEmerCity(state: string | null | undefined, country: string) {
    this.personalTabForm?.patchValue({
      txtEmergencyCity: null,
    });
    country =
      country == ''
        ? this.personalTabForm.value.txtEmergencyCountry == (null || undefined)
          ? ''
          : this.personalTabForm.value.txtEmergencyCountry
        : country;
    this.drpEmerCities = [];
    if (state !== null && state !== undefined) {
      const getEmercitySubscription = this.region
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpEmerCities = response?.items;

            this.filteredEmergencyCities = this.personalTabForm
              .get('txtEmergencyCityFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpEmerCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(getEmercitySubscription);
    }
    return of(this.drpEmerCities && this.drpEmerCities?.length !== 0);
  }
  copyPatientAddressDetails() {
    const relationshipValue =
      this.relationShipWithoutSelves.find((a) => a.relationType == 'Self')
        .relationShipCode || '';
    if (
      this.personalTabForm.value?.drpEmergencyRelationshipType ==
      relationshipValue
    ) {
      const patientEmail =
        this.personalTabForm.value.txtBillingEmailAddress.toLowerCase() ?? '';
      if (patientEmail != '') {
        this.personalTabForm?.patchValue({
          txtEmergencyEmail: patientEmail,
        });
      }
    }

    this.copyBillingAddress();
  }
  clearBillingCountry() {
    this.BillingCountry = '';
    this.personalTabForm?.patchValue({
      drpBillingCountry: '',
      txtBillingState: '',
      txtBillingCity: '',
    });
  }
  clearColorCode() {
    this.colorCodingName = '';
    this.personalTabForm?.patchValue({
      drpColorCodes: '',
    });
  }

  // clear dropdown
  clearLocation() {
    this.location = '';
    this.personalTabForm?.patchValue({ drpLocation: '' });
  }

  clearBillingState() {
    this.BillingState = '';
    this.personalTabForm?.patchValue({
      txtBillingState: '',
      txtBillingCity: '',
    });
  }
  clearBillingCity() {
    this.BillingCity = '';
    this.personalTabForm?.patchValue({ txtBillingCity: '' });
  }
  clearDeliveryCountry() {
    this.DeliveryCountry = '';
    this.phoneDelCode = '';
    this.personalTabForm?.patchValue({ drpDeliveryCountry: '' });
    this.personalTabForm.controls['txtDeliveryPhone'].disable();
    this.personalTabForm.controls['txtDeliveryFax'].disable();
    this.personalTabForm.controls['txtDeliveryMobile'].disable();
  }
  clearDeliveryState() {
    this.DeliveryState = '';
    this.personalTabForm?.patchValue({ txtDeliveryState: '' });
  }
  clearDeliveryCity() {
    this.DeliveryCity = '';
    this.personalTabForm?.patchValue({ txtDeliveryCity: '' });
  }

  clearBranchOffice() {
    this.branchOffice = '';
    this.personalTabForm?.patchValue({ drpBranchOffice: '' });
  }

  clearProvider() {
    this.provider = '';
    this.personalTabForm?.patchValue({ txtProvider: '' });
  }

  clearPtSecurityGroup() {
    this.ptSecurityGroup = '';
    this.personalTabForm?.patchValue({ drpPTSecurityGroup: '' });
  }

  clearCustomer() {
    this.customerType = '';
    this.personalTabForm?.patchValue({ drpCustomerType: '' });
  }

  clearSuffix() {
    this.suffixName = '';
    this.personalTabForm?.patchValue({ drpSuffix: '' });
  }
  clearMaritalStatus() {
    this.maritalStatus = '';
    this.personalTabForm?.patchValue({ drpMarital: '' });
  }

  // clear dropdown
  clearGender() {
    this.gender = '';
    this.personalTabForm?.patchValue({ drpGender: '' });
  }

  clearDunningMessage() {
    this.dunningMessage = '';
    this.personalTabForm?.patchValue({ drpDunningMessage: '' });
  }

  clearHoldReason() {
    this.holdReason = '';
    this.personalTabForm?.patchValue({ drpHoldReason: '' });
  }

  clearZone() {
    this.zone = '';
    this.personalTabForm?.patchValue({ drpBillingZone: '' });
  }

  clearZones() {
    this.zones = '';
    this.personalTabForm?.patchValue({ drpZone: '' });
  }

  //only number and alpha
  numberAndAlphaOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    var inp = String.fromCharCode(event.keyCode);
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode >= 15 &&
      charCode <= 64
    ) {
      return false;
    }
    return true;
  }

  clearAccountGroup() {
    this.accountGroup = '';
    this.personalTabForm?.patchValue({ drpAccountGroup: '' });
  }

  clearEmergencyCountry() {
    this.phoneEmerCode = '';
    this.personalTabForm?.patchValue({
      txtEmergencyCountry: '',
      txtEmergencyState: '',
      txtEmergencyCity: '',
    });
    this.EmergencyCountry = '';
  }

  clearEmergencyState() {
    this.EmergencyState = '';
    this.personalTabForm?.patchValue({
      txtEmergencyState: '',
      txtEmergencyCity: '',
    });
  }
  clearEmergencyCity() {
    this.EmergencyCity = '';
    this.personalTabForm?.patchValue({ txtEmergencyCity: '' });
  }

  getFormattedDate(date: string): string {
    if (!date) return null;

    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }


  savePersonal() {
    this.patientId = this.patientId ?? defaultGuid;
    if (!this.personalTabForm.invalid) {
      this.isShowSpinner = true;
      this.saveButtonHide = true;

      const personalValue = this.personalTabForm.value;
      this.organizationUnitId = localStorage.getItem(
        'initialOrganizationUnitId'
      );
      this.Table.getOrganizationUnitId().subscribe((value) => {
        this.organizationUnitId = value;
      });
      this.organizationUnitId = localStorage.getItem(
        'initialOrganizationUnitId'
      );
      this.organizationUnitId =
        this.organizationUnitId === 'null' ? null : this.organizationUnitId;
        let Billingfax=null;
        let BillingPhone=null;
        let BillingMobile=null;
        let deliveryFax=null;
        let deliveryMobile=null;
        let deliveryPhone=null;
        if(this.personalTabForm.value.txtBillingFax){
          Billingfax=this.personalTabForm.value.txtBillingFax.length===10?
         this.personalTabForm.value.txtBillingFax.slice(0,3)+"-"+this.personalTabForm.value.txtBillingFax.slice(3,6)+"-"+this.personalTabForm.value.txtBillingFax.slice(6,15):
         this.personalTabForm.value.txtBillingFax;
        }
        if(this.personalTabForm.value.txtBillingPhone){
          BillingPhone=this.personalTabForm.value.txtBillingPhone.length===10?
         this.personalTabForm.value.txtBillingPhone.slice(0,3)+"-"+this.personalTabForm.value.txtBillingPhone.slice(3,6)+"-"+this.personalTabForm.value.txtBillingPhone.slice(6,15):
         this.personalTabForm.value.txtBillingPhone;
        }
        if(this.personalTabForm.value.txtBillingMobile){
          BillingMobile=this.personalTabForm.value.txtBillingMobile.length===10?
         this.personalTabForm.value.txtBillingMobile.slice(0,3)+"-"+this.personalTabForm.value.txtBillingMobile.slice(3,6)+"-"+this.personalTabForm.value.txtBillingMobile.slice(6,15):
         this.personalTabForm.value.txtBillingMobile;
        }
        if(this.personalTabForm.value.txtDeliveryFax){
          deliveryFax=this.personalTabForm.value.txtDeliveryFax.length===10?
         this.personalTabForm.value.txtDeliveryFax.slice(0,3)+"-"+this.personalTabForm.value.txtDeliveryFax.slice(3,6)+"-"+this.personalTabForm.value.txtDeliveryFax.slice(6,15):
         this.personalTabForm.value.txtDeliveryFax;
        }
        if(this.personalTabForm.value.txtDeliveryMobile){
          deliveryMobile=this.personalTabForm.value.txtDeliveryMobile.length===10?
         this.personalTabForm.value.txtDeliveryMobile.slice(0,3)+"-"+this.personalTabForm.value.txtDeliveryMobile.slice(3,6)+"-"+this.personalTabForm.value.txtDeliveryMobile.slice(6,15):
         this.personalTabForm.value.txtDeliveryMobile;
        }
        if(this.personalTabForm.value.txtDeliveryPhone){
          deliveryPhone=this.personalTabForm.value.txtDeliveryPhone.length===10?
         this.personalTabForm.value.txtDeliveryPhone.slice(0,3)+"-"+this.personalTabForm.value.txtDeliveryPhone.slice(3,6)+"-"+this.personalTabForm.value.txtDeliveryPhone.slice(6,15):
         this.personalTabForm.value.txtDeliveryPhone;
        }
        //  let BillingMobile=this.personalTabForm.get('txtBillingMobile')?.value;
        //  let deliveryFax=this.personalTabForm.get('txtDeliveryFax')?.value;
        //  let deliveryMobile=this.personalTabForm.get('txtDeliveryMobile')?.value;
        const chkIsNewCity  =
      this.drpBillCities?.filter((x) => x?.cityName === this.personalTabForm.get('txtBillingCity')?.value)?.[0]
        ?.cityId ?? null;
      const billingAddress: CreateUpdatePatientAddressDTO = {
        addressType: 0,
        address: this.personalTabForm.get('txtBillingAddress')?.value,
        city: this.personalTabForm.get('txtBillingCity')?.value,
        isNewCity: chkIsNewCity==1?true: false,
        state:
          this.personalTabForm.get('txtBillingState')?.value ==
          (null || undefined)
            ? ''
            : this.personalTabForm.get('txtBillingState')?.value,
        country:
          personalValue?.drpBillingCountry == (null || undefined)
            ? ''
            : personalValue?.drpBillingCountry,
        county:
          personalValue?.drpBillingCounty == (null || undefined)
            ? ''
            : personalValue?.drpBillingCounty,
        postalCode: this.personalTabForm.get('txtBillingPostalCode')?.value,
        phone:BillingPhone,
        fax: Billingfax,
        mobile:BillingMobile,
        zone: this.personalTabForm.get('drpBillingZone')?.value,
        patientId: this.patientId,
        emailId:this.personalTabForm.get('txtBillingEmailAddress')?.value.toLowerCase(),
      };
      const DeliveryChkIsNewCity  =
      this.drpDeliCities?.filter((x) => x?.cityName === this.personalTabForm.get('txtDeliveryCity')?.value)?.[0]
        ?.cityId ?? null;

      const deliveryAddress: CreateUpdatePatientAddressDTO = {
        addressType: 1,
        isNewCity: DeliveryChkIsNewCity==1?true: false,
        address: this.personalTabForm.get('txtDeliveryAddress')?.value,
        city:
          this.personalTabForm.get('txtDeliveryCity')?.value ==
          (null || undefined)
            ? ''
            : this.personalTabForm.get('txtDeliveryCity').value,
        state:
          this.personalTabForm.get('txtDeliveryState')?.value ==
          (null || undefined)
            ? ''
            : this.personalTabForm.get('txtDeliveryState').value,
        country:
          personalValue?.drpDeliveryCountry == (null || undefined)
            ? ''
            : personalValue?.drpDeliveryCountry,
        county:
          personalValue?.drpDeliveryCounty == (null || undefined)
            ? ''
            : personalValue?.drpDeliveryCounty,
        postalCode: this.personalTabForm.get('txtDeliveryPostalCode')?.value,
        phone: deliveryPhone,
        fax:  deliveryFax,
        mobile:deliveryMobile,
        zone: this.personalTabForm.get('drpZone')?.value,
        patientId: this.patientId,
        emailId:this.personalTabForm.get('txtBillingEmailAddress')?.value.toLowerCase(),
      };
      const dateofBirthtxt = this.getFormattedDate(personalValue?.txtBirthdate);

      // let homePhoneNo=personalValue?.txtHomePhoneNo;
      let homePhoneNo=null;
      let workPhoneNo=null;
      let cellNo=null;
      let emergencyContact=null;
     if(personalValue?.txtHomePhoneNo){
       homePhoneNo=personalValue?.txtHomePhoneNo.length===10?
      personalValue?.txtHomePhoneNo.slice(0,3)+"-"+personalValue?.txtHomePhoneNo.slice(3,6)+"-"+personalValue?.txtHomePhoneNo.slice(6,15):
      personalValue?.txtHomePhoneNo;
     }
     if(personalValue?.txtWorkPhoneNo){
       workPhoneNo=personalValue?.txtWorkPhoneNo.length===10?
      personalValue?.txtWorkPhoneNo.slice(0,3)+"-"+personalValue?.txtWorkPhoneNo.slice(3,6)+"-"+personalValue?.txtWorkPhoneNo.slice(6,15):
      personalValue?.txtWorkPhoneNo;
     }
     if(personalValue?.txtCellNo){
       cellNo=personalValue?.txtCellNo.length===10?
      personalValue?.txtCellNo.slice(0,3)+"-"+personalValue?.txtCellNo.slice(3,6)+"-"+personalValue?.txtCellNo.slice(6,15):
      personalValue?.txtCellNo;
     }
     if(personalValue?.txtEmergencyNo){
       emergencyContact=personalValue?.txtEmergencyNo.length===10?
      personalValue?.txtEmergencyNo.slice(0,3)+"-"+personalValue?.txtEmergencyNo.slice(3,6)+"-"+personalValue?.txtEmergencyNo.slice(6,15):
      personalValue?.txtEmergencyNo;
     }
      // let workPhoneNo=personalValue?.txtWorkPhoneNo;

      // let workPhoneNo=personalValue?.txtWorkPhoneNo;
      // let cellNo=personalValue?.txtCellNo;
      // let emergencyContact=personalValue?.txtEmergencyNo;

      //to save personal details
      const personalDetails: CreateUpdatePatientPersonalDTO = {

        locationType: personalValue?.drpLocation,
        locationId: personalValue?.drpLocation,
        gender: personalValue?.drpGender,
        title:personalValue?.drpTitle,
        firstName: personalValue?.txtFirstName,
        middleName: personalValue?.txtMiddleName,
        lastName: personalValue?.txtLastName,
        // dateOfBirth: personalValue?.txtBirthdate,
        dateOfBirth: dateofBirthtxt,
        chartId: personalValue?.txtChartId,
        homePhoneNo: homePhoneNo,
        workPhoneNo:workPhoneNo,
        cellNo:cellNo,
        emergencyContact:emergencyContact,
        accountNumber:
          personalValue?.txtAccountNumber == (null || undefined)
            ? ''
            : personalValue?.txtAccountNumber,
        customerType:
          personalValue?.drpCustomerType == (null || undefined)
            ? ''
            : personalValue?.drpCustomerType,
        accountGroupType:
          personalValue?.drpAccountGroup == (null || undefined)
            ? ''
            : personalValue?.drpAccountGroup,
        providerId: this.personalTabForm.get('txtProvider')?.value,
        patientBalance: 0,
        totalBalance: 0,
        colorCodingId:
          this.drpColorCodes?.filter(
            (c) =>
              c?.colorCode == this.personalTabForm.get('drpColorCodes')?.value
          )?.[0]?.id ?? null,
        holdReason: this.personalTabForm.get('drpHoldReason')?.value,
        autoDunningId: this.personalTabForm.get('drpDunningMessage')?.value,
        patientAlerts :this.personalTabForm.get('txtAlerts')?.value,
        patientCollection:+this.personalTabForm.get('chkCollection')?.value,
        patientHippaSignatureOnLife :+this.personalTabForm.get('chkHipaaSignature')?.value,
        isAutoDunning: +this.personalTabForm.get('chkAutoDunning')?.value,
        fullName: '',
        emailId: this.personalTabForm.get('txtBillingEmailAddress')?.value.toLowerCase(),
        isStat: +this.personalTabForm.get('chkStat')?.value,
        isCashPayment: +this.personalTabForm.get('chkCashPayment')?.value,
        maritalStatus:
          personalValue?.drpMarital == (null || undefined)
            ? ''
            : personalValue?.drpMarital,
        tenantId: this.tenantId,
        suffix:
          personalValue?.drpSuffix == (null || undefined)
            ? ''
            : personalValue?.drpSuffix,
        ssn: personalValue?.txtSSN,
        patientId: this.patientId ?? '00000000-0000-0000-0000-000000000000',
        defaultPatientId: '',
        placeOfService: '',
        dateOfAdmission: '',
        dateOfDischarge: '',
        auditTrialCreatedBy: '',
        authNotes: '',
        authComments: '',
        patientinfo: '',
        inActiveDateTime: '',
        insuranceBalance: 0,
        auditTrialCreatedDate: '',
      };
      //  let contactFax=this.personalTabForm.get('txtEmergencyFax').value ?? '';
      // let contactmobile=this.personalTabForm.get('txtEmergencyMobile').value ?? '';
      let contactFax=null;
      let contactmobile=null;
      let contactPhone=null;
      if(this.personalTabForm.get('txtEmergencyFax').value)
      {
       contactFax=this.personalTabForm.get('txtEmergencyFax').value.length===10?
      this.personalTabForm.get('txtEmergencyFax').value.slice(0,3)+"-"+this.personalTabForm.get('txtEmergencyFax').value.slice(3,6)+"-"+this.personalTabForm.get('txtEmergencyFax').value.slice(6,15):
      this.personalTabForm.get('txtEmergencyFax').value;
      }
      if(this.personalTabForm.get('txtEmergencyMobile').value)
      {
       contactmobile=this.personalTabForm.get('txtEmergencyMobile').value.length===10?
      this.personalTabForm.get('txtEmergencyMobile').value.slice(0,3)+"-"+this.personalTabForm.get('txtEmergencyMobile').value.slice(3,6)+"-"+this.personalTabForm.get('txtEmergencyMobile').value.slice(6,15):
      this.personalTabForm.get('txtEmergencyMobile').value;
      }
      if(this.personalTabForm.get('txtEmergencyPhone').value){
       contactPhone=this.personalTabForm.get('txtEmergencyPhone').value.length===10?
      this.personalTabForm.get('txtEmergencyPhone').value.slice(0,3)+"-"+this.personalTabForm.get('txtEmergencyPhone').value.slice(3,6)+"-"+this.personalTabForm.get('txtEmergencyPhone').value.slice(6,15):
      this.personalTabForm.get('txtEmergencyPhone').value;
      }
      const contactInfoChkIsNewCity  =
      this.drpEmerCities?.filter((x) => x?.cityName === this.personalTabForm.get('txtEmergencyCity').value)?.[0]
        ?.cityId ?? null;

      const contactAddress: CreateUpdatePatientContactDTO = {
        contactType: drpContactType.Emergency,
        patientId: this.patientId,
        relationshipId:
          personalValue?.drpEmergencyRelationshipType == (null || undefined)
            ? ''
            : personalValue?.drpEmergencyRelationshipType,
        firstName:
          this.personalTabForm.get('txtEmergencyFirstName').value ?? '',
        middleName:
          this.personalTabForm.get('txtEmergencyMiddleName').value ?? '',
        lastName: this.personalTabForm.get('txtEmergencyLastName').value ?? '',
        address: this.personalTabForm.get('txtEmergencyAddress').value ?? '',
        city: this.personalTabForm.get('txtEmergencyCity').value ?? '',
        state: this.personalTabForm.get('txtEmergencyState').value ?? '',
        county: this.personalTabForm.get('txtEmergencyCounty').value ?? '',
        country: this.personalTabForm.get('txtEmergencyCountry').value ?? '',
        postalCode:
          this.personalTabForm.get('txtEmergencyPostalCode').value ?? '',
        phone:contactPhone,
        mobile: contactmobile,
        fax:  contactFax,
        emailAddress: this.personalTabForm.get('txtEmergencyEmail')?.value.toLowerCase() ?? '',
        isNewCity: contactInfoChkIsNewCity==1?true: false,
      };
      if (this.patientId !== defaultGuid) {
        //update api
        this.patientPersonalService
          .update(this.patientId, personalDetails)
          .pipe(
            switchMap((x) =>
              this.patientAddressService.update(
                this.patientBAddressId,
                billingAddress
              )
            ),
            switchMap((x) =>
              this.patientAddressService.update(
                this.patientDAddressId,
                deliveryAddress
              )
            ),
            switchMap((x) =>
              this.patientContactService.update(
                this.patientContactId,
                contactAddress
              )
            )
          )
          .subscribe(
            (response) => {
              this.isShowSpinner = false;
              this.saveButtonHide = false;
              // Swal.fire({
              //   title: 'Success',
              //   html: 'Updated Successfully',
              //   icon: 'success',
              //   timer: 3000,
              //   timerProgressBar: true,
              // });
              this.toastr.success('Updated Successfully', 'Success');
              // this.get();
              // this.resetAddItemForm();
              this.patientIdEmitter.emit(this.patientId);
            },
            (err) => {
              this.isShowSpinner = false;
              this.saveButtonHide = false;
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
      } else {
        this.patientPersonalService
          .create(personalDetails)
          .pipe(
            switchMap((x) => {
              billingAddress.patientId = x?.patientId;
              this.patientId = x?.patientId;
              return this.patientAddressService.create(billingAddress);
            }),
            tap((x) => {
              this.patientBAddressId = x.id;
            }),
            switchMap((x) => {
              deliveryAddress.patientId = x?.patientId;
              this.patientId = x?.patientId;
              return this.patientAddressService.create(deliveryAddress);
            }),
            tap((x) => {
              this.patientDAddressId = x.id;
            }),
            switchMap((x) => {
              contactAddress.patientId = x?.patientId;
              this.patientId = x?.patientId;
              return this.patientContactService.create(contactAddress);
            }),
            tap((x) => {
              this.patientContactId = x.id;
            })
          )
          .subscribe(
            (response) => {
              var lstSavedCity =this.drpBillCities?.filter((x) => x?.cityName === this.personalTabForm.get('txtBillingCity')?.value);
                if(lstSavedCity.length>0){
                  lstSavedCity.forEach(element => {
                    element.cityId=null;
                   });
                  }
              var lstSavedCity =this.drpDeliCities?.filter((x) => x?.cityName === this.personalTabForm.get('txtDeliveryCity')?.value);
              if(lstSavedCity.length>0){
                lstSavedCity.forEach(element => {
                  element.cityId=null;
                 });
                }
                var lstSavedCity =this.drpEmerCities?.filter((x) => x?.cityName === this.personalTabForm.get('txtEmergencyCity').value);
                if(lstSavedCity.length>0){
                  lstSavedCity.forEach(element => {
                    element.cityId=null;
                   });
                  }
              Swal.fire({
                title: 'Success',
                html: 'Saved Successfully',
                icon: 'success',
                timer: 3000,
                timerProgressBar: true,
              }).then(
                (result) => {
                  // if (result.value) {
                    //!change next tab
                    this.nextTabMoveOnSaveEmitter.emit('Personal');

                  // }
                  this.routerLocation.replaceState("/patientCreateEditTab/"+response.patientId);
                  window.location.reload();
                },
                (err) => {}
              );
              this.saveButtonHide = false;
              // this.resetAddItemForm();
              this.patientIdEmitter.emit(this.patientId);
              this.isShowSpinner = false;
            },
            (err) => {
              const data: HttpErrorResponse = err;
              this.isShowSpinner = false;
              this.saveButtonHide = false;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
      }
    }
  }

  viewAddressList() {
    const dialogRef = this.dialog.open(PatientAddressListComponent, {
      data: {
        patientId: this.patientId,
        chartId: this.chartIdAddress,
      },
      // height: '50%',
      width: '100%',
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(() => {});
  }
  addNewBillingCity(){
    if(this.personalTabForm.value.txtBillingCityFilter !== undefined && this.personalTabForm.value.txtBillingCityFilter !==null &&
      (this.personalTabForm.value.txtBillingCityFilter).trim() !=='' &&  (this.personalTabForm.value.txtBillingCityFilter).trim() .length<=40 )
    {
      // const objWithIdIndex = this.drpCities.findIndex((obj) => obj.cityId === 1);
      // if(objWithIdIndex !== -1){
      // this.drpCities.splice(objWithIdIndex, 1);
      // }
      const newCityName=this.personalTabForm.value.txtBillingCityFilter.trim().toLowerCase();
      var alreadyAdd=this.drpBillCities?.filter((obj) => (obj.cityName).trim().toLowerCase() === newCityName);
      if(alreadyAdd.length===0){
   let newCity = {
      cityName:this.personalTabForm.value.txtBillingCityFilter,
      cityId:1
    };

    this.drpBillCities?.push(newCity);
    this.personalTabForm.patchValue({ txtBillingCity: this.personalTabForm.value.txtBillingCityFilter });
  }
  }
  }
  billingCityDrpDownMessage(){
    if (this.personalTabForm.value.txtBillingState === ''||this.personalTabForm.value.txtBillingState === null || this.personalTabForm.value.txtBillingState === undefined) {
      return 'No Matches found';
    } else if( (this.personalTabForm.value.txtBillingCityFilter === ''||this.personalTabForm.value.txtBillingCityFilter === null || this.personalTabForm.value.txtBillingCityFilter === undefined)?
    (this.personalTabForm.value.txtBillingCityFilter !== ''&&this.personalTabForm.value.txtBillingCityFilter !== null && this.personalTabForm.value.txtBillingCityFilter !== undefined)
    : (this.personalTabForm.value.txtBillingCityFilter).trim().length>40){
      return 'maximum 40 characters only allowed';
    }else {
      return 'Enter a City Name';
    }
  }
  ContactInfocityDrpDownMessage(){
    if (this.personalTabForm.value.txtEmergencyState === ''||this.personalTabForm.value.txtEmergencyState === null || this.personalTabForm.value.txtEmergencyState === undefined) {
      return 'No Matches found';
    }else if( (this.personalTabForm.value.txtEmergencyCityFilter === ''||this.personalTabForm.value.txtEmergencyCityFilter === null || this.personalTabForm.value.txtEmergencyCityFilter === undefined)?
    (this.personalTabForm.value.txtEmergencyCityFilter !== ''&&this.personalTabForm.value.txtEmergencyCityFilter !== null && this.personalTabForm.value.txtEmergencyCityFilter !== undefined)
      :(this.personalTabForm.value.txtEmergencyCityFilter).trim().length>40){
      return 'maximum 40 characters only allowed';
    } else {
      return 'Enter a City Name';
    }
  }
  ContactInfoaddNewCity(){
    if(this.personalTabForm.value.txtEmergencyCityFilter !== undefined && this.personalTabForm.value.txtEmergencyCityFilter !==null &&
      (this.personalTabForm.value.txtEmergencyCityFilter).trim() !=='' && (this.personalTabForm.value.txtEmergencyCityFilter).trim().length<=40 )
    {
      const newCityName=this.personalTabForm.value.txtEmergencyCityFilter.trim().toLowerCase();
      var alreadyAdd=this.drpEmerCities?.filter((obj) => (obj.cityName).trim().toLowerCase() === newCityName);
      if(alreadyAdd.length===0){
      // const objWithIdIndex = this.drpCities.findIndex((obj) => obj.cityId === 1);
      // if(objWithIdIndex !== -1){
      // this.drpCities.splice(objWithIdIndex, 1);
      // }

   let newCity = {
      cityName:this.personalTabForm.value.txtEmergencyCityFilter,
      cityId:1
    };

    this.drpEmerCities?.push(newCity);
    this.personalTabForm.patchValue({ txtEmergencyCity: this.personalTabForm.value.txtEmergencyCityFilter });
  }
  }
  }

  DeliverycityDrpDownMessage(){
    if (this.personalTabForm.value.txtDeliveryState === ''||this.personalTabForm.value.txtDeliveryState === null || this.personalTabForm.value.txtDeliveryState === undefined) {
      return 'No Matches found';
    } else if( (this.personalTabForm.value.txtDeliveryCityFilter === ''||this.personalTabForm.value.txtDeliveryCityFilter === null || this.personalTabForm.value.txtDeliveryCityFilter === undefined)?
    (this.personalTabForm.value.txtDeliveryCityFilter !== ''&&this.personalTabForm.value.txtDeliveryCityFilter !== null && this.personalTabForm.value.txtDeliveryCityFilter !== undefined)
    :(this.personalTabForm.value.txtDeliveryCityFilter).trim().length>40){
      return 'maximum 40 characters only allowed';
    }else {
      return 'Enter a City Name';
    }
  }
  DeliveryaddNewCity(){
    if(this.personalTabForm.value.txtDeliveryCityFilter !== undefined && this.personalTabForm.value.txtDeliveryCityFilter !==null &&
      (this.personalTabForm.value.txtDeliveryCityFilter).trim() !=='' && (this.personalTabForm.value.txtDeliveryCityFilter).trim().length<=40 )
    {
      // const objWithIdIndex = this.drpCities.findIndex((obj) => obj.cityId === 1);
      // if(objWithIdIndex !== -1){
      // this.drpCities.splice(objWithIdIndex, 1);
      // }
      const newCityName=this.personalTabForm.value.txtDeliveryCityFilter.trim().toLowerCase();
      var alreadyAdd=this.drpDeliCities?.filter((obj) => (obj.cityName).trim().toLowerCase() === newCityName);
      if(alreadyAdd.length===0){

   let newCity = {
      cityName:this.personalTabForm.value.txtDeliveryCityFilter,
      cityId:1
    };

    this.drpDeliCities?.push(newCity);
    this.personalTabForm.patchValue({ txtDeliveryCity: this.personalTabForm.value.txtDeliveryCityFilter });
  }
  }
  }
}
