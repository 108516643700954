import type { SendMessageInput } from './dto/models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChatsService {
  apiName = 'platformManagement';

  sendChatMessage = (input: SendMessageInput) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/PlatformApp/chats/send-chat-message',
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
