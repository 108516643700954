<mat-dialog-content class="mat-typography">
  <div class="row">
    <!-- Header -->
    <div class="col-12 mb-2">
      <div class="d-flex justify-content-between">
        <div>
          <p class="header-text">Document Split - {{ data.faxId }}

            <button [hidden]="!TiffError" mat-raised-button class="buttonColor  mb-2 mr-1"
            [matTooltip]="TiffError ? 'Tiff has not converted - Refresh Document' : 'Document already loading successfully'"
            (click)="fnRefreshButtonClicked()" [disabled]="!TiffNotLoadingError || refreshButtonClicked">
            <mat-icon *ngIf="TiffNotLoadingError" [ngClass]="TiffError ? 'rotate-btn' : ''">refresh</mat-icon>
            <mat-icon *ngIf="!TiffNotLoadingError">done_outline</mat-icon>

          </button>
        </p>


          <!-- <button (click)="check()">Check</button> -->
        </div>
        <button mat-icon-button mat-dialog-close class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-lg-12 d-flex justify-content-center mb-1">
      <button mat-raised-button (click)="resetDocumentSplit()" class="mr-2" color="warn" type="button">
        Reset
      </button>


      <button class="btn mr-2 defaultButton" (click)="saveSplitDocument()" mat-raised-button type="button">
        Save
      </button>
    </div>
  </div> -->
  <!-- Document Viewers -->
  <div class="row" [hidden]="TiffError">
    <!-- Existing Doc View-->
    <div class="col-lg-2">
      <div class="card card-body align-items-center scrollable-card p-0" style="height: 80vh">
        <ng-container *ngFor="let image of existingDocumentImageList">
          <div class="card my-2 elevated-div custom-thumbnail-view">
            <!-- (dblclick)="dbClickMoveToSplitDocument()" -->
            <img class="card-img-top img-fluid custom-thumbnail-image user_select_none"
              (click)="loadExistingImage(image)" [ngClass]="{
                'custom-thumbnail-view-selected':
                  selectedExistingDocument?.pageNo === image?.pageNo
              }" [src]="image?.src" [alt]="image?.pageNo" />
            <p class="align-end user_select_none">Page No : {{ image.pageNo }}</p>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="col-lg-8">
      <div class="card">
        <div class="card card-body align-items-center justify-content-center p-0">
          <!--  -->
          <h1 class="header-text py-1 mb-0" *ngIf="isLoading || TiffError"
            [ngClass]="{ 'blink-text': isLoading || TiffError }">
            {{ loadingMessage }}
          </h1>

          <div *ngIf="!TiffError">
            <div class="zoom-buttons">

            <a [matTooltip]="'Rotate Clockwise'" class="ml-2 mt-2" (click)="rotateClockwise()"
              [class.disabled]="isLoading" href="javascript:void(0)">
              <mat-icon>rotate_right</mat-icon>
            </a>

            <a [matTooltip]="'Rotate Counter Clock Wise'" class="ml-2 mt-2" (click)="rotateCounterclockwise()"
              [class.disabled]="isLoading" href="javascript:void(0)">

              <mat-icon>rotate_left</mat-icon>
            </a>

              <a [matTooltip]="'Reset Document'" class="ml-2 mt-2 "
                [ngClass]="splitDocumentImageList?.length > 0 ? 'red' : '' " (click)="resetDocumentSplit()"
                [class.disabled]="splitDocumentImageList?.length === 0 " href="javascript:void(0)">
                <mat-icon>restore</mat-icon>
              </a>
              <a [matTooltip]="'Zoom In Page'" class="ml-2 mt-2" (click)="zoomIn()"
                [class.disabled]="isLoading || zoomLevel === MAX_ZOOM_LEVEL" href="javascript:void(0)">
                <mat-icon>add</mat-icon>
              </a>
              <a [matTooltip]="'Zoom Out Page'" class="ml-2 mt-2" (click)="zoomOut()"
                [class.disabled]="isLoading  || zoomLevel === MIN_ZOOM_LEVEL" href="javascript:void(0)">
                <mat-icon>remove</mat-icon>
              </a>
              <a [matTooltip]="'Return Original Documents - Page'" class="ml-2 mt-2" (click)="moveToExistingDocument()"
                [class.disabled]="selectedSplitDocument == null" href="javascript:void(0)">
                <mat-icon>fast_rewind</mat-icon>
              </a>
              <a [matTooltip]="'Go to First Page'" class="ml-2 mt-2" (click)="firstImage()"
                [class.disabled]="isLoading || pageNumber === 1" href="javascript:void(0)">
                <mat-icon>first_page</mat-icon>
              </a>
              <a [matTooltip]="'Go to Previous Page'" class="ml-2 mt-2" (click)="previousImage()"
                [class.disabled]="isLoading || pageNumber === 1" href="javascript:void(0)">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </a>
              <input [matTooltip]="'Go to Custom Page'" matInput class="form-control custom-input" type="number"
                [(ngModel)]="pageNumber" min="1" max="{{ totalPages }}" (change)="goToPage()" [readonly]="isLoading" />

              <span> of {{ totalPages }}</span>
              <a [matTooltip]="'Go to Next Page'" class="ml-2 mt-2" (click)="nextImage()"
                [class.disabled]="isLoading || pageNumber === existingDocumentImageList.length"
                href="javascript:void(0)">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </a>
              <a [matTooltip]="'Go to Last Page'" class="ml-2 mt-2" (click)="lastImage()"
                [class.disabled]="isLoading || pageNumber === existingDocumentImageList.length"
                href="javascript:void(0)">
                <mat-icon>last_page</mat-icon>
              </a>
              <a [matTooltip]="'Split Original Documents - Page'" class="ml-2 mt-2" (click)="moveToSplitDocument()"
                [class.disabled]="selectedExistingDocument == null" href="javascript:void(0)">
                <mat-icon>fast_forward</mat-icon>
              </a>
              <a [matTooltip]="'Save Split Documents'" class="ml-2 mt-2" (click)="saveSplitDocument()" [class.disabled]="
                  splitDocumentImageList.length === 0 || disableSaveButton
                " href="javascript:void(0)">
                <mat-icon>save</mat-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="card card-body align-items-center justify-content-center scrollable-card p-0 backgroundclr">
          <div class="scrollable-card-image">
            <canvas #canvas></canvas>
            <input [(ngModel)]="tiffPageNo" type="hidden" name="tiffPageNo" />
          </div>
        </div>
      </div>
    </div>

    <!-- Splitted Doc View-->
    <!-- user-select-none -->
    <div class="col-lg-2 ">
      <div class="card card-body mani align-items-center scrollable-card p-0" style="height: 80vh">
        <ng-container *ngFor="let image of splitDocumentImageList">
          <div class="card my-2 elevated-div custom-thumbnail-view">
            <!-- (click)="loadSplitImage(image)" -->
            <!-- (dblclick)="dbClickMoveToExistingDocument()" -->
            <img class="card-img-top img-fluid custom-thumbnail-image user_select_none" [ngClass]="{
                'custom-thumbnail-view-selected':
                  selectedSplitDocument?.pageNo === image?.pageNo
              }" [src]="image?.src" [alt]="image?.pageNo" (click)="loadSplitImage(image)" />
            <p class="align-end fw-bolder">Page No : {{ image.pageNo }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="row" [hidden]="!TiffError">
    <div class="col-lg-12">
      <div class="card">
        <ng-container *ngIf="showPdfViwer">
          <ngx-extended-pdf-viewer [base64Src]="strPdfString" [height]="pdfheight" [showSidebarButton]="false"
            [showFindButton]="true" [textLayer]="true" [showPagingButtons]="true" [showOpenFileButton]="false"
            [showHandToolButton]="false" [showScrollingButton]="false" [showSpreadButton]="false"
            [showPropertiesButton]="false" [showBookmarkButton]="false" [zoom]="'page-width'"
            [enableDragAndDrop]="false">
          </ngx-extended-pdf-viewer>
        </ng-container>
      </div>
    </div>

  </div>
</mat-dialog-content>
<app-loader-component *ngIf="isOrderPageLoading"></app-loader-component>
