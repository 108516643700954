
<div class="row align-items-center">
  <div class="col-md-10">
    <!-- <h2 class="customThemeClass font-weight-bolder">Add New Documents</h2> -->
    <h2 class="customThemeClass font-weight-bolder">Upload Manual Documents</h2>
  </div>
  <div class="col-md-2 text-right">
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
</div>
<mat-dialog-content class="mat-typography">

  <div class="card">
    <div class="card-body mt-1">
  <form [formGroup]="documentForm">
  <!-- <div class="modal-body"> -->

      <!-- <mat-card-content> -->
        <div class="row">
          <!-- <div class="col-4" *ngIf="data.isPatientList">
               <mat-form-field class="col-lg-12" id="newPatient">
                <mat-label>Patient <span class="asterisk">*</span></mat-label>
                <mat-select formControlName="drpSearchPatient" [disabled]="isDisabled">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    (keyup)="searchPatient($event.target.value)"
                      formControlName="txtPatientFilter"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                  *ngFor="let chart of lstChartNos"
                  [value]="chart.patientId"
                >
                  {{ chart.ddValue }}
                </mat-option>
                </mat-select>
              </mat-form-field>
          </div> -->
            <mat-form-field class="col-5" >
              <mat-label >Patient Search</mat-label>
              <mat-select  formControlName="drpPatient" #singleSelect [disabled]="data.isPatientDisable">
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" hideClearSearchButton="true" (keyup)="searchPatient($event.target.value,data.constructorpatientId)"
                    noEntriesFoundLabel="No Matches found"
                    formControlName="txtPatientFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let chart of lstPatientChartNos" [value]="chart.patientId">
                  {{ chart.ddValue }}
                </mat-option>
              </mat-select>
              <button mat-button matSuffix (click)="clearPatient($event)" [disabled]="isEmpty(this.documentForm.value.drpPatient)||data.isPatientDisable" mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-5" >
              <mat-label> Document Type <span class="asterisk">*</span> </mat-label>
              <mat-select maxlength="40" formControlName="drpDocumentType" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search Document Type"   [formControl]="documentTypeFilterControl" noEntriesFoundLabel="No Matches found"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let document of filteredDocumentTypeList" [value]="document.docTypeId">
                  {{ document.documentType }}
                </mat-option>
              </mat-select>
              <mat-error class="spanAlignment" *ngIf="
              documentForm?.get('drpWIPList')?.touched &&
              documentForm?.get('drpWIPList')?.errors?.required
                "> Document Type is a required field! </mat-error>
            </mat-form-field>

          <div class="col-2 mt-2 text-right">
            <button matTooltip="Click To Browse"  [matTooltipDisabled]="'Please Wait..'" mat-button class="buttonColor font-size-10 ml-3"
              (click)="uploadFile.click()" [disabled]="!isPdfLoad">Browse <span class="asterisk">*</span>
              <mat-icon>cloud_upload</mat-icon>
              <mat-icon *ngIf="!isPdfLoad">
                <mat-spinner class="spinner-border spinner-border-sm" diameter="20"></mat-spinner>
              </mat-icon>
            </button>

            <input autocomplete="new-documentFile" id="file-upload" type="file" class="hidden"
              (change)="onFileChange($event)" #uploadFile accept=".pdf" style="display: none;"
              formControlName="fileUpload" [disabled]="!isPdfLoad"/>


              <!-- <span class="red-bold-text ml-3" >{{ selectedFileName }}</span> -->
          </div>

        </div>
        <div class="row">
        <div class="col-12 mt-2 text-right">
          <span class="bold-text">{{ selectedFileName }}</span>
        </div>
      </div>
      <!-- </mat-card-content> -->



<!--
      <div class="pt-2" style="max-height: 300px; overflow-y: auto;">
        <div class="table-responsive" *ngIf="filesname && filesname?.length !== 0" >
          <table class="users table table-sm table-bordered">
            <thead style="display: table-header-group; position: sticky; top: 0; background-color: #f2f2f2;">
              <tr>
                <th class="table-light" scope="col">Document Name</th>
                <th class="table-light" scope="col">Option</th>
              </tr>
            </thead>
            <tbody style="display: table-row-group;">
              <tr scope="row" *ngFor="let document of filesname; let i = index">
                <td class="table-light">
                  <p class="text-muted mb-0">
                    <span> {{ document }} </span>
                  </p>
                </td>
                <td class="table-light">
                  <p >
                   <mat-icon style="color: red;cursor: pointer;" (click)="deleteDocument(document)">delete</mat-icon>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->

        <!--Extended Info-->
    <!-- <mat-accordion class="pt-2">
      <mat-expansion-panel [expanded]="panelOpenState">
        <mat-expansion-panel-header class="accordHeading">
          <mat-panel-title> Extended Info </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row pt-1">
          <mat-form-field class="col-3">
            <mat-label>Review Status</mat-label>
            <mat-select formControlName="drpReviewStatus" maxlength="40">
              <mat-option *ngFor="let reviewstatus of drpReviewStatusLoop"
                [value]="reviewstatus.reviewStatusShortCodeId">
                {{ reviewstatus.reviewStatus }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-3">
            <mat-label>Review Reason</mat-label>
            <mat-select formControlName="drpReviewReason" maxlength="40">
              <mat-option *ngFor="let reviewstatus of drpReviewReasonLoop"
                [value]="reviewstatus.reviewReasonShortCodeId">
                {{ reviewstatus.reviewReason }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="col-3">
            <mat-form-field class="col-12">
              <mat-label>
                Document Start Date
                <ng-container *ngIf="
                    documentForm?.get('drpDocumentType')?.value === 'RX' ||
                      documentForm?.get('drpDocumentType')?.value ===
                        'FACE TO FACE';
                    else elseStartDateReqTemplate
                  ">
                  <span class="asterisk">*</span>
                </ng-container>
                <ng-template #elseStartDateReqTemplate> </ng-template>
              </mat-label>
              <input autocomplete="new-txtDocumentStartDate" matInput [ngxMatDatetimePicker]="txtDocumentStartDate" placeholder="(MM/DD/YYYY)"
                formControlName="txtDocumentStartDate" />

              <mat-datepicker-toggle matSuffix [for]="txtDocumentStartDate"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #txtDocumentStartDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true">
                <ng-template>
                  <span>OK</span>
                </ng-template>
              </ngx-mat-datetime-picker>
            </mat-form-field>
            <ng-container *ngIf="
                documentForm?.get('drpDocumentType')?.value === 'RX' ||
                documentForm?.get('drpDocumentType')?.value === 'FACE TO FACE'
              ">
              <span>
                <mat-error class="spanAlignment" *ngIf="
                    documentForm?.get('txtDocumentStartDate')?.touched &&
                    documentForm?.get('txtDocumentStartDate')?.errors?.required
                  ">
                  Document Start Date is a required field!
                </mat-error>
              </span>
            </ng-container>
          </div>

          <div class="col-3">
            <mat-form-field class="col-12">
              <mat-label>Document End Date
                <ng-container *ngIf="
                    documentForm?.get('drpDocumentType')?.value === 'RX' ||
                      documentForm?.get('drpDocumentType')?.value ===
                        'FACE TO FACE';
                    else elseStartDateReqTemplate
                  ">
                  <span class="asterisk">*</span>
                </ng-container>
                <ng-template #elseStartDateReqTemplate> </ng-template>
              </mat-label>
              <input autocomplete="new-txtDocumentEndDate" matInput [ngxMatDatetimePicker]="txtDocumentEndDate"
              placeholder="(MM/DD/YYYY)"  [min]="documentForm.controls.txtDocumentStartDate.value"
                formControlName="txtDocumentEndDate" />

              <mat-datepicker-toggle [for]="txtDocumentEndDate" matSuffix></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #txtDocumentEndDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'" [enableMeridian]="true"
               >
                <ng-template>
                  <span>OK</span>
                </ng-template>
              </ngx-mat-datetime-picker>
              <mat-error class="spanAlignment" *ngIf="
              documentForm?.get('txtDocumentEndDate')?.touched &&
              documentForm?.get('txtDocumentEndDate')?.errors?.invalidDate||
              documentForm.controls.txtDocumentStartDate.value>documentForm.controls.txtDocumentEndDate.value
            ">
            Enter Valid and greater than the start date!
          </mat-error>
            </mat-form-field>

            <ng-container *ngIf="
                documentForm?.get('drpDocumentType')?.value !== 'RX' ||
                documentForm?.get('drpDocumentType')?.value !== 'FACE TO FACE'
              ">
              <span>
              </span>
            </ng-container>
          </div>

          <mat-form-field class="col-6">
            <mat-label>Description</mat-label>
            <textarea formControlName="txtNote" id="txtDescription" rows="1" matInput maxlength="400"></textarea>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
    </mat-accordion> -->
    <!--End Extended Info-->


  <!-- </div> -->
  <!-- <div class="modal-footer">
    <div class="col-12 text-center">
      <button mat-button class="buttonColor mb-2 mr-2" mat-raised-button (click)="saveNewDocument()">
        Save
      </button>

      <button mat-button class="resetclr mb-2 mr-2" (click)="resetDocumentForm()" >Reset</button>
    </div>
  </div> -->
</form>
  </div>
</div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions>
  <div class="col-12 text-center mt-2">
    <button class="buttonColor mb-2 mr-2" mat-raised-button (click)="saveNewDocument()" [disabled]="isShowSaveSpinner || !(!documentForm?.invalid && documentForm?.dirty) || !isSelectBrowse">
      Save
      <mat-icon *ngIf="isShowSaveSpinner">
        <mat-spinner class="spinner-border-sm" diameter="20"></mat-spinner>
    </mat-icon>
    </button>

    <button mat-button class="resetclr mb-2 mr-2" (click)="resetDocumentForm()" >Reset</button>
  </div>
</mat-dialog-actions>
