<br />
<div class="card card-body">
  <mat-accordion>
    <mat-expansion-panel [expanded]="isPanelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title class="customThemeClass">
          <ng-container *ngIf="roleId===''; else elseTitleTemplate">
            <b>Add Roles</b>
          </ng-container>
          <ng-template #elseTitleTemplate>
            <b>Edit Roles</b>
          </ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="executiveRolesForm">
        <div class="row">
          <mat-form-field class="col-6">
            <mat-label> Role Name <span class="asterisk">*</span></mat-label>
            <input autocomplete="new-txtRoleName" matInput maxlength="40" formControlName="txtRoleName" type="text" />
            <!-- <mat-error
              *ngIf="
                executiveRolesForm?.get('txtRoleName')?.touched &&
                executiveRolesForm?.get('txtRoleName')?.errors?.required
              "
            >
              Role Name is a required field!
            </mat-error> -->
          </mat-form-field>
          <div class="col-6">
            <div class="text-lg-left">
              <button mat-button class="buttonColor mt-1 mb-1 mr-2" [disabled]="
                  executiveRolesForm.invalid || executiveRolesForm?.get('txtRoleName')?.value === ''
                " (click)="saveExecutiveRoles()">
                Save
              </button>
              <button mat-button class="resetclr mb-1 mr-1" (click)="resetExecutiveRoles()">Reset</button>
            </div>
          </div>
        </div>
      </form>
      <br />
    </mat-expansion-panel>
  </mat-accordion>
  <div class="row">
    <div class="col-12 table-responsive">
      <app-table [strPageType]="strPageType" (viewButton)="ViewExecutiveRole($event)"
        (viewPermissionButton)="ViewPermissions($event)" (deleteButton)="deleteExecutiveRole($event)"></app-table>
    </div>
  </div>
</div>
