import { VerificationStatus } from "projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/inbound/verification-status.enum";
import { MedicareCheckListStatus } from "projects/patient/src/app/patient-proxy/patient/enumeration-data/medicare-check-list-status.enum";

export enum DocumentMaxandTypeLimits {
  documentMaxNoFileLimit = 100,
  documentFileSizeLimit = 20000000, // ! 10mb
  helloSignFileSizeLimit = 20971520, // 20MB
  documentTypeLimit = 'application/pdf',
  ExcelTypeLimit = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ManualUploadMaxSize = 199229440, //  190MB
  TemplateUploadMaxSize = 15728640, //  15MB
}
export enum DocumentUploadTypes {
  AutoUploadType = 'Auto Sorting',
  ManualUploadType = 'Manual Sorting',
  ExtractionUploadType = 'Extraction Upload',
  ConsolidatedUpload = 'ConsolidatedUpload',
  SplitUpload = 'SplitUpload',
}
export enum LogoMaxandTypeLimits {
  documentFileSizeLimit = 5000000, // ! 5mb
  logoFormats1 = 'image/jpeg',
  logoFormats2 = 'image/png',
}
export enum UploadFileStatus {
  NewFile = 'New',
  Processing = 'Processing',
  Extracted = 'Extraction Completed',
  Sorted = 'Sorting Completed',
  WaitingForApproval = 'Waiting for Approval',
  Approved = 'Approved',
  Rejected = 'Rejected',
  EditedApproved = 'Edited Approved',
  Added = 'Added',
  Updated = 'Updated',
  Completed = 'Completed',
  ExtractedAndSorted = 'Extraction and Sorting Completed',
}

export enum teamStatusType {
  intakeTeamType = 0,
  authTeamType = 1,
  verifyTeamType = 2,
  orderTeamType = 3,
  shippingTeamType = 4,
  wareHouseTeamType = 5,
  billingTeamType = 6,
  claimTeamType = 7,
}

export enum defaultCountry {
  US = 'US',
}

export const defaultGuid: string = '00000000-0000-0000-0000-000000000000';

export enum claimAcceptedStatus {
  readyToClaimProcess = 'Ready To Claim Process',
  processed = 'Claim Processed',
  YetToProcess = 'Yet To Process',
  PrimaryProcessed = 'Primary Processed',
  PrimaryPostingCompleted = 'Primary Posting Completed',
  MoveToSecondary = 'Move To Secondary',
  SecondaryProcessed = 'Secondary Processed',
  SecondaryPostingCompleted = 'Secondary Posting Completed',
  MoveToTertiary = 'Move To Tertiary',
  TertiaryProcessed = 'Tertiary Processed',
  TertiaryPostingCompleted = 'Tertiary Posting Completed',
}

export enum orderStatus {
  billingStatus = 'Processed',
  ReadyToShippingApproval = 'Ready To Shipping Approval',
  ReadyToApprove = 'Ready To Approve',
  ShippingApproved = 'Shipping Approved',
  ReadyToPack = 'Ready To Pack',
  ReadyToDelivery = 'Ready To Delivery',
  CollectedFromOffice = 'Collected From Office',
  Returned = 'Returned',
  ReturnApproved = 'Return Approved',
  ReturnProductReceived = 'Return Product Received',
}
export enum billingStatus {
  OrderPending = 'Order Pending',
  ReadyToBilling = 'Ready To Billing',
  ReadyToProcess = 'Ready To Process',
  claimProcessed = 'Claim Processed',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  PaymentPending = 'Payment Pending',
}
export enum OrderBillingStatus {
  OrderInProcess = '',
  OrderPending = 'Order Pending',
  ReadyToBilling = 'Ready To Billing',
  ReadyToShippingApproval = 'Ready To Shipping Approval',
  ReadyForOrderCompleteProcess = 'Ready For Order Complete Process',
  SecondaryVerificationPending = 'Secondary Verification Pending',
  TertiaryVerificationPending = 'Tertiary Verification Pending',
  PrimaryVerificationPending = 'Primary Verification Pending',
  PrimaryAuthPending = 'Primary Auth Pending',
  TertiaryAuthPending = 'Tertiary Auth Pending',
  SecondaryAuthPending = 'Secondary Auth Pending',
  ItemPending = 'Item Pending',
  StockPending = 'Stock Pending',
  PaymentPending = 'Payment Pending',
}

export enum AuthFollowUpReport {
  'All' = 'All',
  'Active' = 'Active',
  'AboutToExpire' = 'About To Expire',
  'Expired' = 'Expired',
}

export enum VerifyTypeList {
  manual,
  zirmed,
  pverify,
}

export enum SortingShortCode {
  All = '001',
  SIGNED_DOCUMENT = '008',
  FaceToFace = '002',
  SleepStudyReport = '003',
  ComplianceReport = '004',
  Rx = '005',
  Correspondence = '006',
  Demographics = '007',
}

export enum DocumentCheckListRows {
  Optional = 1,
  Vaild = 2,
  Type = 3,
  Pressure_Settings = 4,
  Date = 5,
  Code = 6,
  Doctor_Print_Name = 7,
  Signature = 8,
}

export const DocumentCheckListRowsText = {
  1: 'Optional',
  2: 'Valid',
  3: 'Type',
  4: 'Pressure Settings',
  5: 'Date',
  6: 'Code',
  7: 'Doctor Print Name',
  8: 'Signature',
} as const;

export const DocumentCheckListRowsTextForAPI = {
  1: 'optinal',
  2: 'valid',
  3: 'type',
  4: 'pressureSettings',
  5: 'date',
  6: 'code',
  7: 'doctorPrintName',
  8: 'signature',
} as const;

export const calender_status_color_code = {
  Scheduled: '#20b2aa',
  Rescheduled: '#FF7F50',
  Completed: '#006400',
  Cancelled: '#DC143C',
};

export const calender_status_code = {
  1: 'Scheduled',
  2: 'Rescheduled',
  3: 'Completed',
  4: 'Cancelled',
};

export enum MultipleVerificationFilter {
  FaxProcess = 1,
  OnDemand = 2,
  VerificationIssue = 3,
  Authorization = 4,
}

export enum SearchFaxFinder {
  FaxIdInput = 1,
  ChartNoInput = 2,
  PatientNameInput = 3,
  BirthDateInput = 4,
}

export enum InboundSortedfilter {
  All = 0,
  Sorted = 1,
  Unsorted = 2,
}

export const InboundSortedfilterText = {
  0: 'All',
  1: 'Sorted',
  2: 'Unsorted',
};

export interface TextAnnotation {
  text: string;
  x: number;
  y: number;
  rectWidth: number;
  rectHeight: number;
}

export enum sotingSelectedCSS {
  Total = 1,
  Sorted = 2,
  Pending = 3, // UnSorted
  Bin = 4,
  AllBin = 5,
}

export enum ClaimProcessQuickOptions {
  Today = 1,
  Yesterday = -1,
  Tomorrow = 2,
}

export enum PatientDetailsTab {
  Personal_Contact = 1,
  Clinical = 2,
  Document = 3,
  Sorting = 4,
  Insurance = 5,
  Authorization = 6,
  Order = 7,
  Claim = 8,
}

export enum postingType {
  AutoPosting = 1,
  ManualPosting = 2,
}

export enum claimStatusColorCode {
  YELLOW = '#fff380', //'#EEDB00',
  RED = '#ff8080', //'#FF0000',
  WHITE = '#E8DDDD', //'#FFFFFF',
  GREEN = '#CEFFCE',
}

export const ccPlan = {
  0: 'Patient Responsibility',
  1: 'Primary',
  2: 'Secondary',
  3: 'Tertiary',
};

export const medicarecheckBoxList = {
  CPAPF2F: 0,
  CPAPMedicareCoveredSleepTest: 1,
  CPAPADetailedWrittenOrder: 2,
  CPAPPickupTicket: 3,

  PAPRequalF2f: 4,
  PAPRequalMCST: 5,
  PAPRequalDWO: 6,
  PAPRequalRT: 7,

  RPAPMCST: 8,
  RPAPDWO: 9,
  RPAPFTF: 10,

  NIVF2F: 11,
  NIVDWO: 12,
  NIVMCST: 13,

  crF2F: 14,
  crMCST: 15,
  crDWO: 16,

  osOCEN: 17,
  osDWO: 18,
  osMCST: 19,
};

export const tiffPointerMode = {
  null: 0,
  crosshair: 1,
  text: 2,
  pointer: 3,
  help: 4,
  all_scroll: 5,
  grab: 6,
  grabbing: 7,
  none: 8,
};

export enum nikoBillingMenu {
  INVOICES = 1,
  PAYMENTS = 2,
  DENIALS = 3,
  EOB_ERA = 4,
  DEPOSITS = 5,
}
export enum nikoBillingMenuStatus {
  INVOICES = 'Invoices',
  PAYMENTS = 'Payments',
  DENIALS = 'Denials',
  EOB_ERA = 'EOB/ERA',
  DEPOSITS = 'Deposits',
}
export enum CommonDocumentViewerType {
  FaxDocument = 1,
  EOB = 2,
}
export enum MyQueueWizard {
  DOCUMENTBIN = 1,
  ORDERS = 2,
  CLAIMS = 3,
  DENIALMANAGEMENT = 4,
  INVOICE=5,
}

export enum MyQueueBinStatus {
  Unprocessed = 'Unprocessed',
  Verification = 'Verification',
  Precert = 'Precert',
  Checklist = 'Checklist',
  Schedule = 'Schedule',
  ReadyforOrder = 'Ready for Order',
  Resupply = 'Resupply',
  SaleOrder = 'Sale Order',
  Shipping = 'Shipping',
  Billing = 'Billing',
}

export enum MyQueueClaimStatus {
  ClaimSubmission = 1,
  AutoPosting = 2,
  ClaimPosting = 3,
  ClaimChecklist = 4,
}

export enum MyQueueClaimStatusText {
  ClaimSubmission = 'Claim Submission',
  AutoPosting = 'Auto Posting',
  ClaimPosting = 'Claim Posting',
  ClaimChecklist = 'Claim Checklist',
}

export enum TAtFilter
{
  greaterthanNegative72 = '(-72) + Hrs',
  negative72to48 = '(-72)-(-48) Hrs',
  negative48tozero = '(-48)-0 Hrs',
  zeroto48='0-48 Hrs',
  between48to72= '48-72 Hrs',
  greaterthan72 ='72+ Hrs'
}
export interface TAtFilterValues {
  tatValue?: string;
  tatId?: any;
}
export enum SortingFilter {
  All= 'All',
  Sorted  = 'Sorted',
  UnSorted = 'UnSorted'
}
export interface ScheduleStatusValues {
  scheValues?: string;
}
export interface InsuranceDTO {
  payerShortCodeList?: string;
  payerId?: string;
}
export interface VerificationOption {
  key: string;
  value: VerificationStatus;
}
export interface CheckListOption {
  key: number;
  value: string;
}