import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { TableService } from 'projects/shared/src/app/table.service';
import { ToastrService } from 'ngx-toastr';
import { TenantsService } from '../../admin-proxy/platform-app-management/rcm/platform-management/tenant/tenants.service';
import { RoleService } from '../../admin-proxy/platform-app-management/rcm/platform-management/role/role.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { defaultGuid } from 'projects/shared/src/app/enums/allenums.enum';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import {
  IdentityRoleDto,
  IdentityRoleUpdateDto,
} from '../../admin-proxy/platform-app-management/identity/models';
import { IdentityRoleCreateUsingTenantIdDto } from '../../admin-proxy/platform-app-management/rcm/platform-management/role/models';
import { PermissionV1Component } from '../../permission-v1/permission-v1.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DataTableDirective } from 'angular-datatables';
import { PermissionsModalListComponent } from '../../permissions-modal-list/permissions-modal-list.component';
import { DatePipe } from '@angular/common';
import { CommonService } from 'projects/shared/src/app/services/common.service';
@Component({
  selector: 'app-role-table',
  templateUrl: './role-table.component.html',
  styleUrls: ['./role-table.component.scss'],
})
export class RoleTableComponent implements OnInit {
  dtRoleTableOptions = {
    responsive: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    order:[],
    scrollY: '400px',
    scrollX: true,
    fixedHeader: true,
    columnDefs: [
      { targets: [0], orderable: false },
      { targets: [3,4,5], visible: false },
      // { targets: [1], orderable: false },
      // {
      //   targets: [2],
      //   type: 'date',
      //   render: function (data, type, row) {
      //     // Assuming data is in the format MM/DD/YYYY
      //     if (type === 'sort' || type === 'type') {
      //       var dateParts = data.split('/');
      //       return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
      //     } else {
      //       return data;
      //     }
      //   },
      // },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Role',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0),:eq(1),:eq(2), :eq(-1))',
      },
    ],
  };
  isLoading:boolean=false;
  step: boolean = false;
  roleForm: FormGroup;
  roleId: string = defaultGuid;
  emptyguid: string = defaultGuid;
  isShownSaveButton: boolean = true;
  isRolesCreatePermission: boolean = false;
  isShownclientPermission: boolean = true;
  isShowSpinner: boolean = false;
  isShownRolesTable: boolean = true;
  saveButtonHide: boolean;
  @Input() clientId: string = '';
  @Input() selectedOption:number;
  @Input() tableRole: any;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  roleResponse: IdentityRoleDto = null;
  roleTableData: any[] = [];
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
  constructor(
    private formBuilder: FormBuilder,
    private Table: TableService,
    public dialog: MatDialog,
    private officialService: TenantsService,
    private roleService: RoleService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
    private commonService:CommonService
  ) {}

  ngOnInit(): void {

    this.dtRoleTableOptions.buttons[0].filename =
    'Role' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.roleTableData = this.tableRole;

    this.Table.getRolesManagePermission().subscribe(
      (value) => {
        if (value == true) {
          this.isShownclientPermission = true;
        } else {
          this.isShownclientPermission = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.Table.getRolesCreate().subscribe(
      (value) => {
        this.isRolesCreatePermission = value;

        if (this.isRolesCreatePermission == true) {
          this.isShownSaveButton = true;
        } else {
          this.isShownSaveButton = false;
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        // Swal.fire({
        //   icon: 'error',
        //   text: data?.error?.error?.message,
        // });
      }
    );

    this.roleForm = this.formBuilder.group({
      txtRoleName: new FormControl('', Validators.required),
    });

    this.clientId != null &&
      this.clientId != '' &&
      this.clientId != undefined &&
      this.getInActive();
  }

  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Clear search input values
      $('input', dtInstance.table(0).footer()).val('');
      // Redraw the DataTable to load all records
      dtInstance.search('').columns().search('').draw();
      // Attach the event listener for filtering
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  //!buttonhide
  getInActive() {
    const isSupAdmin: number =
      Number(+localStorage.getItem('isAdmin') ?? 0) ?? 0;
    isSupAdmin &&
      this.officialService
        .getTenantInformationById(this.clientId)
        .subscribe((response) => {
          if (response.status == 'InActive') {
            this.isShownSaveButton = false;
            this.isShownRolesTable = false;
          }
        });
  }

  //! view role id for edit
  ViewRole(event: { id: any }) {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
    this.step = true;
    this.accordion.openAll();
    this.getRoleById(event?.id ?? defaultGuid);
  }
  //! edit role
  getRoleById(id: string = defaultGuid) {
    this.roleService.getRoleById(this.clientId, id).subscribe((response) => {
      this.roleId = response?.id ?? defaultGuid;
      this.roleResponse = response ?? null;
      this.roleForm.patchValue({
        txtRoleName: response?.name ?? '',
      });
    });
  }

  //! save role
  saveRole() {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    this.isLoading=true;
    // this.step = false;
    this.roleId = this.roleId ?? defaultGuid;
    if (this.roleId === defaultGuid) {
      let roles: IdentityRoleCreateUsingTenantIdDto = {
        name: this.roleForm.value.txtRoleName,
        isDefault: this.roleResponse?.isDefault ?? false,
        isPublic: this.roleResponse?.isPublic ?? true,
        extraProperties: this.roleResponse?.extraProperties ?? {},
        tenantId: this.clientId ?? defaultGuid,
      };
      this.roleService.createRole(roles).subscribe(
        (response) => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          this.roleId = defaultGuid;
          this.reset();
          // this.getRoleTable();--
          if(this.roleTableData.length===0){
            this.communicationService.triggerLoadRoleListv1();
           } else{
            if(this.selectedOption !== 2){
          //  this.roleTableData.push(response);
          const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.modifyTime);
          response.modifyTime = userTimezonelastModificationTime;
          const userTimezonecreationTime = this.commonService.getFormattedDateTimeZone(response.creationTime);
          response.creationTime = userTimezonecreationTime;
          this.roleTableData.unshift(response);

          // this.roleTableData = this.roleTableData.map((data) => {
          //   return {
          //     ...data,
          //     modifyTime: this.commonService.getFormattedDateTimeZone(
          //       data.modifyTime
          //     ),
          //     creationTime: this.commonService.getFormattedDateTimeZone(
          //       data.creationTime
          //     ),
          //   };
          // });
            }
           }
           this.toastr.success('Saved Successfully', 'Success');
           this.isLoading=false;
          // this.communicationService.triggerLoadRoleListv1();

        },
        (err) => {
          this.saveButtonHide = false;
          this.roleId = defaultGuid;
          this.isShowSpinner = false;
          this.isLoading=false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      let roles: IdentityRoleUpdateDto = {
        concurrencyStamp: this.roleResponse?.concurrencyStamp ?? '',
        name: this.roleForm.value.txtRoleName,
        isDefault: this.roleResponse?.isDefault,
        isPublic: this.roleResponse?.isPublic,
        extraProperties: this.roleResponse?.extraProperties ?? {},
      };
      this.roleService.updateRole(this.clientId, this.roleId, roles).subscribe(
        (response) => {

          this.roleId = defaultGuid;
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          this.step = false;
          this.reset();
          // this.getRoleTable();--
        //   let index = this.roleTableData.findIndex(d => d.id === response.id);
        // this.roleTableData.splice(index,1);
        // this.roleTableData.push(response);
        const index = this.roleTableData.findIndex(
          (obj) => obj.id === response?.id
        );
        if (index !== -1) {
          this.roleTableData[index] = response; //Replace the Object
          const objectToMove = this.roleTableData.splice(index, 1)[0]; // Remove and get the object
          const userTimezonelastModificationTime = this.commonService.getFormattedDateTimeZone(response.modifyTime);
          response.modifyTime = userTimezonelastModificationTime;
          const userTimezonecreationTime = this.commonService.getFormattedDateTimeZone(response.creationTime);
          response.creationTime = userTimezonecreationTime;
          this.roleTableData.unshift(objectToMove);
          // this.roleTableData = this.roleTableData.map((data) => {
          //   return {
          //     ...data,
          //     modifyTime: this.commonService.getFormattedDateTimeZone(
          //       data.modifyTime
          //     ),
          //     creationTime: this.commonService.getFormattedDateTimeZone(
          //       data.creationTime
          //     ),
          //   };
          // });
        }
        this.isLoading = false;
        this.toastr.success('Updated Successfully', 'Success');
         // this.communicationService.triggerLoadRoleListv1();
        },
        (err) => {
          this.step = false;
          this.isLoading=false;
          // this.roleId = defaultGuid;
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

  ViewPermissions(roleDisplayName: string, roleName?: any) {

    // const dialogRef = this.dialog.open(PermissionComponent, {
    const dialogRef = this.dialog.open(PermissionsModalListComponent, {
      disableClose: true,
      data: { roleName: roleName, roleDisplayName: roleDisplayName },
      width: '600px', // Set the width to your desired value
      // height: '300px', // Set the height to your desired value
      // max-width: '800px', /* Set your desired maximum width */
      // min-width: 600px; /* Set your desired minimum width */
      // max-height: 80vh;
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  reset() {
    this.roleForm.patchValue({
      txtRoleName: '',
    });
    this.saveButtonHide = false;
    this.isShowSpinner = false;
    this.roleResponse = null;
    this.roleId = defaultGuid;
    this.roleForm.reset();
    this.roleForm.markAsUntouched();
    this.roleForm.updateValueAndValidity();
  }

  statusChange(event: MatSlideToggleChange, data: any) {
    // this.status = event.checked == true ? "Active" : "Inactive";
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this role  will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!',
      }).then((result) => {
        if (result.value) {
          this.isLoading=true;
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.roleService.enableDisableStatusById(data?.id).subscribe(
              () => {
                this.isShownSaveButton = true;
                this.isShowSpinner = false;
                this.reset();
                // this.getRoleTable();--
                // this.communicationService.triggerLoadRoleListv1();
                if(this.selectedOption !==0){
                  let index = this.roleTableData.findIndex(d => d.id === data?.id);
                  this.roleTableData.splice(index,1);
                }else{
                  this.roleTableData.find(item => item.id == data?.id).status = 'Active';
                }
                this.toastr.success('Activated Successfully', 'Success')
                this.isLoading=false;
              },
              (err) => {
                // this.saveButtonHide = false;
                const data: HttpErrorResponse = err;
                this.isShowSpinner = false;
                this.isLoading=false;
                // this.saveButtonHide = false;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
                event.source.checked = false;
              }
            );
          }
        } else {
          event.source.checked = false;
        }
      });
    } else if (event.checked == false) {
      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this role will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!',
        // cancelButtonText: 'No, deactivate it!',
      }).then((result) => {
        if (result.value) {
          this.isLoading=true;
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.roleService.enableDisableStatusById(data?.id).subscribe(
              () => {
                this.isShownSaveButton = true;
                this.isShowSpinner = false;
                this.reset();
                // this.getRoleTable();--
                // this.communicationService.triggerLoadRoleListv1();
                if(this.selectedOption !==0){
                  let index = this.roleTableData.findIndex(d => d.id === data?.id);
                  this.roleTableData.splice(index,1);
                }else{
                  this.roleTableData.find(item => item.id == data?.id).status = 'Inactive';
                }
                this.isLoading=false;
                this.toastr.success('Deactivated Successfully', 'Success')
              },
              (err) => {
                const data: HttpErrorResponse = err;
                this.isShowSpinner = false;
                this.isLoading=false;
                event.source.checked = true;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }
            );
          }
        } else {
          event.source.checked = true;
        }
      });
    }
  }

  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
}
