import { itemList } from './../../../../shipping/src/app/shipping-ledger/shipping-ledger.component';
import { ProductDetailsService } from 'projects/inventory/src/app/item-proxy/item-management/optimization';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import {
  RegionCitiesDTO,
  RegionCountriesDTO,
  RegionStatesDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';
import { DataTableDirective } from 'angular-datatables';
import { PatientDetailsComponent } from 'projects/patient/src/app/patient-details/patient-details.component';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { CreateUpdatePatientAddressComponent } from 'projects/shared/src/app/components/create-update-patient-address/create-update-patient-address.component';
import { PatientAddressListComponent } from 'projects/shared/src/app/components/patient-address-list/patient-address-list.component';
import { MmOrderService } from '../order-proxy/order-management/order-optimization/mm-order.service';
import {
  CreateUpdateMMOrderDTO,
  CreateUpdateMmOrderItemSettingsDTO,
  FrequentlyOrderedItemDTO,
  ListofVerificationsDTO,
  machineSettingsDTO,
  MaskSizeDTO,
  OrderPatientNotesDTO,
  PatientAddressValidationDTO,
  PatientChartNoDTO,
  PatientInfoDTO,
  PrintTicketDetails,
} from '../order-proxy/order-management/order-optimization/dto';
import { ShippingTypesService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/shipping-types/shipping-types.service';
import { ShippingTypesDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/shipping-types/dto';
import { UserByRoleDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { UserService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/user';
import { OrganizationUnitDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { OrganizationUnitService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { MasterProviderService } from '../order-proxy/order-management/inventory/master-provider.service';
import {
  MasterProviderDTO,
  NoteType,
  NotesType,
} from '../order-proxy/order-management/inventory/dto';
import { PracticeManagementService } from 'projects/patient/src/app/patient-proxy/practice-management/practice-management.service';
import { PracticeManagementDTO } from 'projects/patient/src/app/patient-proxy/practice-management/dto';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MaskSizeService } from '../order-proxy/order-management/order-optimization/mask-size.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PatientManualVerificationTabComponent } from 'projects/patient/src/app/patient-manual-verification-tab/patient-manual-verification-tab.component';
import { PatientAddPolicyComponent } from 'projects/patient/src/app/patient-add-policy/patient-add-policy.component';
import { InventorySerialLotNoService } from 'projects/inventory/src/app/item-proxy/item-management/optimization/inventory-serial-lot-no.service';
import { ViewDocumentCheckListComponent } from 'projects/admin/src/app/view-document-check-list/view-document-check-list.component';
import { CreateUpdateMMOrderItemDTO } from 'projects/inventory/src/app/item-proxy/item-management/optimization/dto';
import { ViewPatientLedgerComponent } from 'projects/admin/src/app/view-patient-ledger/view-patient-ledger.component';
import { element } from 'protractor';
import { dtInboundOptions } from 'projects/admin/src/app/admin-dashboard/dashboard-data';
import { Title } from '@angular/platform-browser';
import { SaleorderShipping1Component } from '../saleorder-shipping1/saleorder-shipping1.component';
import { SaleorderPaymentprocessDialogComponent } from '../saleorder-paymentprocess-dialog/saleorder-paymentprocess-dialog.component';
import { RentalMonthlyCalculationComponent } from '../rental-monthly-calculation/rental-monthly-calculation.component';
import { DatePipe } from '@angular/common';
import { SaleOrderOverrideModalComponent } from '../sale-order-override-modal/sale-order-override-modal.component';
import { SaleOrderReturnModalComponent } from '../sale-order-return-modal/sale-order-return-modal.component';
import { PaymentMethodsModalComponent } from '../payment-methods-modal/payment-methods-modal.component';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import {
  AddressService,
  PatientAuthorizationService,
} from 'projects/patient/src/app/patient-proxy/patient-optimization';
import {
  PatientAddressDTO,
  activeAuthDTO,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { ShippingListService } from '../order-proxy/order-management/order-optimization/shipping-list.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientCommonNotesComponent } from 'projects/shared/src/app/components/patient-common-notes/patient-common-notes.component';
import { PatientAuthSaveUpdateFormComponent } from 'projects/patient/src/app/patient-auth-save-update-form/patient-auth-save-update-form.component';
import { CommonDoumentViewerForMedicareComponent } from 'projects/shared/src/app/components/common-doument-viewer-for-medicare/common-doument-viewer-for-medicare.component';
import { DoctorService } from 'projects/patient/src/app/patient-proxy/patient';
import { AddressType } from 'projects/patient/src/app/patient-proxy/patient/enumeration-data';
import { tableLayouts } from 'pdfmake/build/pdfmake';
import { MachineSettingsModalComponent } from '../machine-settings-modal/machine-settings-modal.component';
import { saleOrderStatus } from 'projects/shared/src/app/data/common-data';
import { CommonService } from 'projects/shared/src/app/services/common.service';
import { Timezone } from '@syncfusion/ej2-schedule';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { MmOrderItemSettingsService } from '../order-proxy/order-management/order-optimization/mm-order-item-settings.service';


@Component({
  selector: 'app-sale-order',
  templateUrl: './sale-order.component.html',
  styleUrls: ['./sale-order.component.scss'],
})
export class SaleOrderComponent implements OnInit {
  defauldGuid = defaultGuid;
  orderId: string = defaultGuid;
  copyOrderId: string = defaultGuid;
  patientId: string = defaultGuid;
  pageType: string = '0';
  userId: string = defaultGuid;
  tenantId: string = defaultGuid;
  isPatientSelected: boolean = false;
  isSaveBtnDisabled: boolean = false;
  isAddressDisabled: boolean = true;
  primaryVerificationStatus: boolean = false;
  secondaryVerificationStatus: boolean = false;
  isChecked: boolean = false;
  isLoad: boolean = true;
  chartId: string = '';
  orderForm: FormGroup;
  isRental: boolean = false;
  orderDetails: any;
  orderDetailsForm: FormGroup;
  totalAccessorySum: number = 0;
  totalAllowableSum: number = 0;
  totalPointSum: number = 0;
  itemCount: number = 0;
  nextTwelveMonths: {
    date: Date;
    balanceAmount: number;
    monthlyAmount: number;
  }[] = [];
  productDetails: any;
  productId: any; //Used for SO Items selection validation
  tempArray: any = [];
  isEstimateDueAmount = [];
  tempArrayQty: any = [];
  // isCashPayed = false;
  sizeId: any;
  isMinQty: any;
  isStockMinQty: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  lstChartNos: any[] = [];
  public filteredChartNos: Observable<PatientChartNoDTO[]> | undefined;
  lstShippingMethods: ShippingTypesDTO[] = [];
  public filteredShippingMethods: Observable<ShippingTypesDTO[]> | undefined;
  lstUsers: UserByRoleDTO[] = [];
  public filteredUsers: Observable<UserByRoleDTO[]> | undefined;
  lstAuthorizations: activeAuthDTO[] = [];
  public filteredAuth: Observable<activeAuthDTO[]> | undefined;
  lstVerifications: ListofVerificationsDTO[] = [];
  public filteredVerifications:
    | Observable<ListofVerificationsDTO[]>
    | undefined;
  lstLocation: OrganizationUnitDTO[] = [];
  public filteredLocations: Observable<OrganizationUnitDTO[]> | undefined;
  lstProviders: any[] = [];
  public filteredProviders: Observable<any[]> | undefined;
  lstPractices: any;
  public filteredPractices: Observable<PracticeManagementDTO[]> | undefined;
  lstMasks: any;
  public filteredMasks: Observable<PracticeManagementDTO[]> | undefined;
  patientDetails: PatientInfoDTO;
  shippingAddress: string = '';
  patientComments: string = '';
  shippingComments: string = '';
  billingComments: string = '';
  techComments: string = '';
  alertsText: string = '';
  filteredChartIds: any;
  BirthdateMaxDate = new Date();
  BirthdateMinDate: Date;
  orderDate = new Date();
  phoneCode: string = '';
  country: string = '';
  State: string = '';
  City: string = '';
  itemsList: any[] = [];
  productList: any[] = [];
  orderItems: any[] = [];
  CreateUpdateMMOrderItemDTO = [];
  ltproducts: any = [];
  frequentBoughtItems: FrequentlyOrderedItemDTO[] = [];
  filteredOptions: any;
  lstSerialNo: any;
  filteredSerialNo: any;
  filteredSize: any;
  primaryPolicyId: any;
  priVerificationId: any = defaultGuid;
  secVerificationId: any = defaultGuid;
  isDocumentSorted?: boolean;
  defaultFaxId: any;
  faxId: any;
  searchControl = new FormControl();
  searchSerialControl = new FormControl();
  searchSize = new FormControl();
  addressChanged: boolean = false;
  drpCountries: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: any[] = [];
  techFilterControl = new FormControl();
  statusFilterControl = new FormControl();
  ltExchangedCategories: string[] = [];
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  @ViewChild('itemTable') itemTable: ElementRef;
  @ViewChild('frequentItemsTable') frequentItemsTable: ElementRef;
  emailMask: any;
  subscription$: Subscription[] = [];
  defaultGuid = defaultGuid;
  orderStatusId: string;
  addressId: any;
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  machineSettingFields: machineSettingsDTO;
  dtItemTableOptions: any = {
    responsive: true,
    paging: false,
    scrollX: true,
    searching: false,
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    order: [[0, 'asc']],
    columnDefs: [
      { targets: [0], visible: false },
      { targets: [1], width: '5%' }, // Options
      { targets: [2], width: '10%' }, // Product Code
      { targets: [3], width: '10%' }, // Description
      { targets: [4] }, // Size
      { targets: [5], width: '10%' }, // Qty
      { targets: [6], width: '20%' }, // Serial No
      { targets: [7], width: '5%' }, // QtyOnHand
      { targets: [8], width: '5%' }, // AwaitDlvry
      { targets: [9], width: '5%' }, // PendpatOrder
      { targets: [10], width: '5%' }, // Allowable
      { targets: [11], width: '5%' }, // Accessory
      { targets: [12], width: '5%' }, // Points
      { targets: [13], width: '5%' }, // Discount %
      { targets: [14], width: '5%' }, // Exchange
      { targets: [15], width: '5%' }, // Return
    ],
  };
  addressDetails: PatientAddressDTO;
  isExchangeOrReturn: boolean = false; //The Original Order have exchange/return products
  isExchangedOrder: boolean = false; //The Exchange order created for the original order
  isCopyOrder: string;
  isOrderExchangeOrReturnable: boolean = false;
  isOrderExchangable: boolean = false;
  isOrderReturnable: boolean = false;
  isOrderPageLoading: any;
  isOrderEditable: boolean = true;
  isOrderNonEditable: boolean = true;
  physicianDropdown: Subscription;
  monthlyAmounts: any;
  dtFrequentItemTableOptions = dtInboundOptions;
  patientChartApiSubscription: Subscription;
  secondaryPolicyId: string;
  priPolicyId: any;
  secPolicyId: string;
  medicareChecklistId: string = defaultGuid;
  defaultShippingId: string;
  ltRXDocument: number[] = [];
  isloadFrequentBoughtItems: boolean = false;
  documentFaxId: string;
  isFormChanged: boolean = true;
  private loadFrequentBoughtItemsAPICall: Subscription;
  isexchange: boolean = false;
  initialFormValues: any;
  isMailSent: number = 0;
  rentalAllowable: number;
  nonRentalAllowable: number;
  isCashOrder: boolean = false;
  currentValue: number;
  savedOrderDate: string;
  oldRentalMonth: any;
  rentalCheckBoxWantToDisable: boolean = false;
  unitAllowable: number = 0;
  isDisableRemoveItem: boolean = false;
  isResupply:boolean=false;
  isSettingsView: boolean = false;
  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue =
          formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }
  funcListener: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authorizationService: PatientAuthorizationService,
    private loaderService: LoaderService,
    private fb: FormBuilder,
    private regionDropdownService: RegionDropdownService,
    private toastr: ToastrService,
    private productDetailsService: ProductDetailsService,
    private dialog: MatDialog,
    private title: Title,
    private mmOrderService: MmOrderService,
    private doctorService: DoctorService,
    private shippingMethodService: ShippingTypesService,
    private userService: UserService,
    private branchService: OrganizationUnitService,
    private providerService: MasterProviderService,
    private practiceManagementService: PracticeManagementService,
    private maskSizeService: MaskSizeService,
    private communicationService: CommunicationService,
    private serialLotNoService: InventorySerialLotNoService,
    private router: Router,
    private datepipe: DatePipe,
    private addressService: AddressService,
    private shippingListService: ShippingListService,
    private snackBar: MatSnackBar,
    private commonService: CommonService,
    private orderItemSettings: MmOrderItemSettingsService,
    private cdr: ChangeDetectorRef
  ) {
    this.loaderService.getLoaderState().subscribe((isOrderPageLoading) => {
      this.isOrderPageLoading = isOrderPageLoading;
    });
    this.funcListener = this.func.bind(this);
    window.addEventListener('storage', this.funcListener);
    this.emailMask = emailMask;
    // //To search the size inside the size dropdown in itemlist
    this.searchSize.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.filterSize();
      });
    //To search the products inisde the products dropdown
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.filterProducts();
      });

    const addressDetails =
      this.communicationService.addressFunctionCall$.subscribe(
        (addressDetails) => {
          this.loadAddressDetails(addressDetails);
        }
      );
    this.subscription$.push(addressDetails);
    const loadOrderDetails =
      this.communicationService.loadOrderDetailsMethodCall$.subscribe(
        (orderDetails) => {
          this.patientId = orderDetails?.patientId;
          this.orderId = orderDetails?.orderId;
          this.loadExistingOrderDetails();
        }
      );
    this.subscription$.push(loadOrderDetails);
  }
  checkPatient() {
    this.isPatientSelected = this.patientId === defaultGuid ? false : true;
  }
  ngOnInit() {
    this.emailMask = emailMask;
    this.tenantId = localStorage.getItem('tenantId') ?? '';
    this.userId = localStorage.getItem('userId') ?? '';
    const activateRoute = this.activatedRoute.paramMap.subscribe(
      (response) => {
        this.orderId = response?.get('orderId') ?? defaultGuid;
        this.patientId = response?.get('patientId') ?? defaultGuid;
        this.isCopyOrder = response?.get('isCopyOrder'); //Is the Order is a Copy of Existing Order Params
        this.pageType = response?.get('pageType') ?? '0';
        this.copyOrderId =
          this.isCopyOrder === 'true' ? this.orderId : this.copyOrderId;
        //Is Copy Order change the order id as empty
        this.orderId = this.isCopyOrder === 'true' ? defaultGuid : this.orderId;
        this.isResupply=  response?.get('isResupply') ==='true'?true:false;
        this.orderId == defaultGuid &&
          this.title.setTitle('Qsecure | Create Order');
        this.orderId != defaultGuid &&
          this.title.setTitle('Qsecure | Update Order');
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(activateRoute);

    this.initializeForms();
    this.loadDropdowns();
    this.loadMaskSize();

    this.loadProducts();
    this.loadFrequentBoughtItems();

    this.orderId != defaultGuid && this.loadExistingOrderDetails();
    this.loadChartNos('', this.patientId);
    this.patientId != defaultGuid &&
      this.orderId == defaultGuid &&
      this.loadPatientDetails(this.patientId);

    this.orderDetailsForm.value.txtBalance != ''
      ? (this.isRental = true)
      : (this.isRental = false);

    this.isCopyOrder === 'true' && this.copyOrder(this.copyOrderId);

    const canvas = document.createElement('canvas');

    const context = canvas.getContext('2d');
    const base_image = new Image();
    base_image.src = 'assets/images/rnd.jpg';

    base_image.onload = function () {
      canvas.width = base_image.width; // Set canvas width to match image width
      canvas.height = base_image.height; // Set canvas height to match image height
      context.drawImage(base_image, 0, 0, base_image.width, base_image.height);
      const dataString = canvas.toDataURL('image/jpeg');
      localStorage.setItem('LogoDataUrl', dataString);
    };


  }
  //After View In it function
  ngAfterViewInit(): void {
    this.checkPatient();
    this.itemsList.push(this.addEmptyObject());
    this.dtTrigger.next();
    this.cdr.detectChanges();
    //this.reloadDatatable();
  }

  ngOnDestroy(): void {
    // Unsubscribe from the DT events when the component is destroyed
    this.dtTrigger.unsubscribe();
    this.subscription$.forEach((subscription) => {
      subscription.unsubscribe();
    });

    if (this.loadFrequentBoughtItemsAPICall) {
      this.loadFrequentBoughtItemsAPICall.unsubscribe();
    }
  }

  //Method to initialize the page forms
  initializeForms() {
    this.orderForm = this.fb.group({
      drpChartId: new FormControl('', [Validators.required]),
      txtChartFilter: new FormControl(''),
      txtBirthdate: new FormControl('', [Validators.required]),
      chkVerified: new FormControl(false, [Validators.requiredTrue]),
      // chkVerified: new FormControl(false),
      txtLastName: new FormControl(''),
      txtFirstName: new FormControl(''),
      txtMiddleName: new FormControl(''),
      chkBillingAddress: new FormControl(''),
      txtNameLine: new FormControl({
        value: '',
        disabled: this.isAddressDisabled,
      }),
      txtDescription: new FormControl({
        value: '',
        disabled: this.isAddressDisabled,
      }),
      txtAddress: new FormControl({
        value: '',
        disabled: this.isAddressDisabled,
      }),
      // txtAddress: new FormControl(
      //   {
      //     value: '',
      //     disabled: this.isAddressDisabled,
      //   },
      //   [Validators.required]
      // ),

      //txtAddress: [{ value: '', disabled: this.isAddressDisabled }, { validators: [Validators.required, this.customValidator] }],
      txtCountry: new FormControl({
        value: '',
        disabled: this.isAddressDisabled,
      }),
      txtCountryFilter: new FormControl(''),
      txtState: new FormControl({
        value: '',
        disabled: this.isAddressDisabled,
      }),
      txtStateFilter: new FormControl(''),
      txtCity: new FormControl({
        value: '',
        disabled: this.isAddressDisabled,
      }),
      txtCityFilter: new FormControl(''),
      txtPostalCode: new FormControl({
        value: '',
        disabled: this.isAddressDisabled,
      }),
      txtHomeNo: new FormControl({
        value: '',
        disabled: this.isAddressDisabled,
      }),
      txtEmail: new FormControl({
        value: '',
        disabled: this.isAddressDisabled,
      }),
      txtCellNo: new FormControl({
        value: '',
        disabled: this.isAddressDisabled,
      }),
      // txtPrimaryInsurance: new FormControl(''),
      drpPrimaryInsurance: new FormControl('', [Validators.required]),
      txtPrimaryInsuranceFilter: new FormControl(''),
      txtSecondaryInsurance: new FormControl(''),
      txtChecklist: new FormControl(''),
      // txtAuthorization: new FormControl(''),
      drpAuthorization: new FormControl(''),
      txtAuthorizationFilter: new FormControl(''),
      txtRXDate: new FormControl('', [Validators.required]),
      txtOrderDate: new FormControl('', [Validators.required]),
      chkCashOrder: new FormControl(''),
      txtPatientComments: new FormControl(''),
      txtShippingComments: new FormControl(''),
      txtBillingComments: new FormControl(''),
      drpCSR: new FormControl('', [Validators.required]),
      txtCSRFilter: new FormControl(''),
      drpLocation: new FormControl('', [Validators.required]),
      txtLocationFilter: new FormControl(''),
      chkPAPTherapy: new FormControl(''),
      txtDOS: new FormControl(''),
      drpShippingMethod: new FormControl('', [Validators.required]),
      txtShippingMethodFilter: new FormControl(''),
      drpProvider: new FormControl('', [Validators.required]),
      txtProviderFilter: new FormControl(''),
      drpPracticeManagement: new FormControl(''), //, [Validators.required]
      txtPracticeFilter: new FormControl(''),
      txtTechComments: new FormControl(''),
      txtPreferredBrand: new FormControl(''),
      chkRental: new FormControl(''),
      chkResupply: new FormControl(''),
      txtmoths: new FormControl(''),
    });

    this.orderDetailsForm = this.fb.group({
      txtOrderNo: new FormControl(''),
      txtFaxID: new FormControl(''),
      txtStatus: new FormControl(''),
      txtPoints: new FormControl(''),
      txtAlerts: new FormControl(''),
      txtDeductible: new FormControl(''),
      txtCopay: new FormControl(''),
      txtEstimatedAllowable: new FormControl(''),
      txtEstimatedCopay: new FormControl(''),
      txtTotalAccessory: new FormControl(''),
      txtShippingFee: new FormControl(''),
      txtNetTotal: new FormControl(''),
      txtPastDueBalance: new FormControl(''),
      txtEstimateAmountDue: new FormControl(null),
      txtBalance: new FormControl(''),
      chkInvoiceSummary: new FormControl(''),
    });

    this.loadDefaultFields();
    this.orderId != defaultGuid
      ? this.removeMinDateValidator()
      : this.setMinDateValidator();
  }

  addressChanges() {
    this.orderForm
      .get('txtAddress')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.addressChanged = true;
      });
    this.orderForm
      .get('txtCountry')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.addressChanged = true;
      });
    this.orderForm
      .get('txtState')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.addressChanged = true;
      });
    this.orderForm
      .get('txtCity')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.addressChanged = true;
      });
    this.orderForm
      .get('txtPostalCode')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.addressChanged = true;
      });
    this.orderForm
      .get('txtHomeNo')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.addressChanged = true;
      });
    this.orderForm
      .get('txtEmail')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.addressChanged = true;
      });
    this.orderForm
      .get('txtCellNo')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.addressChanged = true;
      });
  }

  setMinDateValidator() {
    const dateControl = this.orderForm.get('txtOrderDate');
    dateControl.setValidators([
      Validators.required,
      Validators.min(this.orderDate.getTime()),
    ]);
    dateControl.updateValueAndValidity();
  }

  removeMinDateValidator() {
    const dateControl = this.orderForm.get('txtOrderDate');
    dateControl.clearValidators();
    dateControl.setValidators(Validators.required);
    dateControl.updateValueAndValidity();
  }
  getCurrentValue(currentValue: string) {
    this.currentValue = Number(currentValue);
  }
  //Load Default Values
  loadDefaultFields() {
    this.priVerificationId = defaultGuid;
    this.secVerificationId = defaultGuid;
    this.medicareChecklistId = defaultGuid;
    this.country = defaultCountry.US;
    this.orderForm.patchValue({
      txtCountry: defaultCountry.US,
      drpAuthorization: defaultGuid,
      // txtChecklist: ,
      txtSecondaryInsurance: 'None',
      txtPrimaryInsurance: 'None',
      txtOrderDate: this.ctDateNow(),
      txtDOS: this.ctDateNow(),
      drpShippingMethod: this.defaultShippingId,
    });
    this.orderDetailsForm.patchValue({
      txtShippingFee: 0,
    });
  }
  ctDateNow(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
    // const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const formattedDate = `${year}-${month}-${day}`;
    return dateObj;
  }
  searchPatient(event: KeyboardEvent, value: any, patientId: string) {
    if (this.isAlphanumeric(event.key) || event.key === 'Backspace') {
      this.loadChartNos(value, patientId);
    }
  }
  isAlphanumeric(key: string): boolean {
    const regex = /^[a-z0-9]$/i;
    return regex.test(key);
  }
  customValidator(control: AbstractControl): { [key: string]: any } | null {
    // Custom validation logic for the disabled field
    const value = control.value;

    if (!value && control.disabled) {
      // Perform custom validation for the disabled field
      return { requiredForDisabled: true };
    }

    return null; // Return null if validation passes
  }

  //#region Dropdowns
  //Method to load all the Dropdowns
  loadDropdowns() {
    this.loadPracticeManagements();
    this.getCountryDropdown();
    this.loadShippingMethods();
    this.loadCSRUsers();
    this.loadLocations();
    this.loadMaskSize();
    this.loadAuthIds();
    this.reloadPhysican('', false);
  }

  reloadPhysican(value, searchReferral) {
    if (searchReferral !== true) {
      this.orderForm.patchValue({
        drpProvider: '',
      });
    }
    if (this.physicianDropdown) {
      this.physicianDropdown.unsubscribe();
    }

    this.physicianDropdown = this.doctorService
      .getPhysicianDropdown('', value)
      .subscribe(
        (response) => {
          this.lstProviders = response;
          //Primary Doctor Dropdown
          this.filteredProviders = this.orderForm
            ?.get('txtProviderFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstProviders?.filter((option) =>
                  option?.doctorName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  //To Load the Chart No of the Patients
  loadChartNos(searchParameter: string, patientId: string) {
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    this.patientChartApiSubscription = this.mmOrderService
      .getPatientChartNoV3BySSearchParamsAndPatient(searchParameter, patientId)
      .subscribe(
        (response) => {
          this.lstChartNos = response;
          return this.lstChartNos;
        },
        (err) => {}
      );
  }
  //To Load the shipping methods in the dropdown
  loadShippingMethods() {
    //Load Shipping Methods
    const shippingMethodDetails = this.shippingMethodService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.lstShippingMethods = response?.items ?? [];

          this.filteredShippingMethods = this.orderForm
            .get('txtShippingMethodFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstShippingMethods?.filter((option) =>
                  option?.description
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );

          this.defaultShippingId =
            this.lstShippingMethods?.find((a) => a.shortCodeId == '009')?.id ??
            defaultGuid;

          this.orderForm.patchValue({
            drpShippingMethod: this.defaultShippingId,
          });
        },
        (err) => {}
      );
    this.subscription$.push(shippingMethodDetails);
  }

  //Method to load the users in CSR Field
  loadCSRUsers() {
    const csrUsersList = this.userService
      .getCSRUsersV1()
      .subscribe((response) => {
        this.lstUsers = response;

        this.filteredUsers = this.orderForm
          .get('txtCSRFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstUsers?.filter((option) =>
                option?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    this.subscription$.push(csrUsersList);
  }

  //Method to load the locations
  loadLocations() {
    //Branch List
    const getBranchList = this.branchService
      .getBranchListV1(this.tenantId, false)
      .subscribe((response) => {
        this.lstLocation = response;
        // filteredLocations
        this.filteredLocations = this.orderForm
          .get('txtLocationFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstLocation?.filter((option) =>
                option?.organizationUnitName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    this.subscription$.push(getBranchList);
  }
  //Method to load the Providers
  loadProviders() {
    const providerList = this.providerService
      .getListV1(new PagedAndSortedResultRequestDto())
      .subscribe((response) => {
        this.lstProviders = response?.items;
        this.filteredProviders = this.orderForm
          .get('txtProviderFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.lstProviders?.filter((option) =>
                option?.fullName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      });
    this.subscription$.push(providerList);
  }
  //Method to load the Practice Managements
  loadPracticeManagements() {
    const practiceList = this.practiceManagementService
      .getListV1(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.lstPractices = response;
          this.filteredPractices = this.orderForm
            .get('txtPracticeFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstPractices?.filter((option) =>
                  option?.practiceName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(practiceList);
  }

  //To Load the Auth Ids in the Authorization Dropdown
  loadAuthIds() {
    this.lstAuthorizations = [];
    this.filteredAuth = null;
    if (
      this.patientId != defaultGuid &&
      this.patientId != null &&
      this.patientId != ''
    ) {
      const authDropdowns = this.authorizationService
        .getActiveAuthorizationsByPatientIdByPatientId(this.patientId)
        .subscribe(
          (response) => {
            let emptyAuth: activeAuthDTO = {
              id: defaultGuid,
              authId: 'None',
            };

            this.lstAuthorizations = response;
            this.lstAuthorizations.unshift(emptyAuth);
            this.filteredAuth = this.orderForm
              .get('txtAuthorizationFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.lstAuthorizations?.filter((option) =>
                    option?.authId
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {}
        );

      this.subscription$.push(authDropdowns);
    } else {
      let emptyAuth: activeAuthDTO = {
        id: defaultGuid,
        authId: 'None',
      };
      this.lstAuthorizations.push(emptyAuth);
      this.filteredAuth = this.orderForm
        .get('txtAuthorizationFilter')
        .valueChanges.pipe(
          startWith(''),
          map((value) =>
            this.lstAuthorizations?.filter((option) =>
              option?.authId
                ?.toLowerCase()
                ?.includes(value?.toLowerCase() ?? '')
            )
          )
        );
    }
  }

  loadPrimaryVerifications(verificationId: string) {
    this.lstVerifications = [];
    this.filteredVerifications = null;
    const cVerifications = this.mmOrderService
      .getPatientVerificationsForOrderByPatientIdAndGVerificationId(
        this.patientId,
        this.priVerificationId
      )
      .subscribe(
        (response) => {
          this.lstVerifications = response ?? [];
          this.filteredVerifications = this.orderForm
            .get('txtPrimaryInsuranceFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.lstVerifications?.filter((option) =>
                  option?.patientId
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {}
      );
    this.subscription$.push(cVerifications);
  }

  getPayerName(verificationId: string): string {
    const val = this.lstVerifications?.find((a) => a.id == verificationId);
    return val?.payorName ?? '';
  }

  //#endregion

  generateNextTwelveMonths(months) {
    const startDateString = String(this.orderForm.value.txtOrderDate);
    const startDate = new Date(startDateString);
    this.monthlyAmounts =
      this.orderDetailsForm.value.txtBalance / this.orderForm.value.txtmoths;

    if (!isNaN(startDate.getTime())) {
      // Check if the conversion to Date was successful
      for (let i = 0; i < this.orderForm.value.txtmoths; i++) {
        const nextMonth = new Date(startDate);
        nextMonth.setMonth(startDate.getMonth() + i);

        const dataObject = {
          date: nextMonth,
          balanceAmount: this.orderDetailsForm.value.txtBalance,
          monthlyAmount: this.monthlyAmounts.toFixed(2),
        };

        this.nextTwelveMonths.push(dataObject);
      }
    } else {
    }
  }
  viewMonthlyAmount(data) {
    this.generateNextTwelveMonths(data);
    if (this.savedOrderDate !== this.orderForm.get('txtOrderDate').value) {
      const dialogRef = this.dialog.open(RentalMonthlyCalculationComponent, {
        disableClose: true,
        // maxWidth: '75%',
        minWidth: '52vw',
        minHeight: '50%',
        data: {
          // balanceAmount: this.orderDetailsForm.value.txtBalance,
          balanceAmount: this.rentalAllowable, // Allow only Main Product Added By Manikandan 14-08-2024 12.30pm
          monthlyAmount: this.monthlyAmounts,
          totalDate: this.nextTwelveMonths,
          orderId: defaultGuid,
          orderDate: this.datepipe.transform(
            // this.orderForm.value.txtOrderDate,
            this.orderForm.get('txtOrderDate').value,
            'MM/dd/yyyy'
          ),
          months: data,
        },
      });
      dialogRef.afterClosed().subscribe((a) => {
        this.nextTwelveMonths = [];
        // this.generateNextTwelveMonths(this.orderForm.value.txtmoths)
      });
    } else {
      // Generate 12 monthly dates
      const newOrderId =
        this.oldRentalMonth != data ? defaultGuid : this.orderId;
      const dialogRef = this.dialog.open(RentalMonthlyCalculationComponent, {
        disableClose: true,
        // maxWidth: '75%',
        minWidth: '52vw',
        minHeight: '50%',
        data: {
          // balanceAmount: this.orderDetailsForm.value.txtBalance,
          balanceAmount: this.rentalAllowable, // Allow only Main Product Added By Manikandan 14-08-2024 12.30pm
          monthlyAmount: this.monthlyAmounts,
          totalDate: this.nextTwelveMonths,
          orderId: newOrderId,
          orderDate: this.datepipe.transform(
            // this.orderForm.value.txtOrderDate,
            this.orderForm.get('txtOrderDate').value,
            'MM/dd/yyyy'
          ),
          months: data,
        },
      });
      dialogRef.afterClosed().subscribe((a) => {
        this.nextTwelveMonths = [];
        // this.generateNextTwelveMonths(this.orderForm.value.txtmoths)
      });
    }
  }
  //Method to load the frequently bought items in the table
  // loadFrequentBoughtItemsVo() {
  //   this.frequentBoughtItems = dataArray;
  // }

  onRowDoubleClick(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }

  loadFrequentBoughtItems() {
    if (!this.isEmpty(this.patientId)) {
      this.isloadFrequentBoughtItems = true;
      try {
        if (this.loadFrequentBoughtItemsAPICall) {
          this.loadFrequentBoughtItemsAPICall.unsubscribe();
        }
        this.loadFrequentBoughtItemsAPICall = this.mmOrderService
          .getFrequentlyOrderedItemsByGPatientId(this.patientId)
          .subscribe(
            (response) => {
              this.frequentBoughtItems = response.slice(0, 5);
              setTimeout(() => {
                this.isloadFrequentBoughtItems = false;
              }, 100);
            },
            (error) => {
              this.frequentBoughtItems = [];
              // this.reloadTable.emit('Mani');
              console.error('Error:', error);
              setTimeout(() => {
                this.isloadFrequentBoughtItems = false;
              }, 100);
            }
          );
      } catch (error) {
        console.error('Unexpected error:', error);
      } finally {
        // setTimeout(() => {
        //   this.isloadFrequentBoughtItems = false;
        // }, 100);
      }
    }
  }

  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid ||
      value === defaultGuid
    );
  }

  isDisCount(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === 0 ||
      value === '-'
    );
  }
  test() {
    console.log(this.orderForm);
    console.log(!this.productId);
    console.log(this.orderForm?.invalid);
    console.log(this.saveButtonEnabled);
    console.log(this.isSaveBtnDisabled);
    console.log(
      !this.productId ||
        this.orderForm?.invalid ||
        this.saveButtonEnabled ||
        this.addressValidation ||
        this.isSaveBtnDisabled ||
        this.itemfieldsValidation ||
        this.isAddressEdit ||
        this.orderForm.get('chkCashOrder').value === true ||
        this.itemsList[0].mmProductId === '' ||
        this.itemsList[0].mmProductId === null ||
        this.itemsList[0].mmProductId === undefined
    );
  }
  //Load Products
  loadProducts() {
    const productsList = this.mmOrderService
      .getProductDetailsByCategoryByLtCategoryIds(this.ltExchangedCategories)
      .subscribe(
        (response) => {
          this.ltproducts = response;
          this.filteredOptions = [...this.ltproducts];
          // const minQty = this.ltproducts.filter(x => x.sMinStock == 'true')
        },
        (err) => {}
      );
    this.subscription$.push(productsList);
  }
  //Load serial NUmber
  loadSerialNumber(data) {
    const serialNumber = this.serialLotNoService
      .getListActiveProductSerialList(data?.mmProductId)
      .subscribe(
        (response) => {
          this.lstSerialNo = response?.items;
          data.ltSerialLotNos = response?.items;

          this.filteredSerialNo = [...this.lstSerialNo];
        },
        (err) => {}
      );

    this.subscription$.push(serialNumber);
  }
  //Load Mask Size Dropdowns
  loadMaskSize() {
    const maskDetails = this.maskSizeService
      .getActiveList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.lstMasks = response?.items ?? [];
          this.filteredSerialNo = [...this.lstMasks];
          this.filterSize();
        },
        (err) => {}
      );
    this.subscription$.push(maskDetails);
  }

  //Load the Patient Commends
  loadComments() {
    this.mmOrderService
      .getNotesByPatientIdByPatientID(this.patientId)
      .subscribe((response) => {
        const comments = response;
        comments.forEach((element) => {
          if (element.noteType === NotesType.patient) {
            this.orderForm.patchValue({
              txtPatientComments: element.description,
            });
          } else if (element.noteType === NotesType.invoice) {
            this.orderForm.patchValue({
              txtBillingComments: element.description,
            });
          } else if (element.noteType === NotesType.shipping) {
            this.orderForm.patchValue({
              txtShippingComments: element.description,
            });
          }
        });
      });
  }

  setItemTableHeight(): void {
    // Set scrollY dynamically based on the content height
    const tableHeight = this.itemTable.nativeElement.offsetHeight;
    const maxHeight = 300; // Set your maximum height here

    if (tableHeight > maxHeight) {
      this.dtItemTableOptions.scrollY = `${maxHeight}px`;
    } else {
      this.dtItemTableOptions.scrollY = null; // Reset to default if not exceeding the limit
    }
  }

  isPatinetDropdownOpen = false;
  onPatinetDropdownOpenChange(isOpen: boolean) {
    this.isPatinetDropdownOpen = isOpen;
    if (this.isPatinetDropdownOpen && this.lstChartNos.length < 1) {
      this.loadChartNos('', this.patientId);
    }
  }
  onChartIdChange(event: any) {
    this.isFormChanged = false;
    this.rentalAllowable = 0;
    this.nonRentalAllowable = 0;
    this.showSettingsCount = 0;
    this.ltRXDocument = [];
    this.loadPatientDetails(event.value);
  }
  clearPatientdrp() {
    this.loadChartNos('', this.patientId);
  }

  //To Load the Patient Details based on the Chart No selected
  loadPatientDetails(patientId: any) {
    this.patientId = patientId;
    this.loaderService.showLoader();

    const patientDetails = this.mmOrderService
      .getPatientDetailsforChartIdByPatientId(patientId)
      .subscribe(
        (response) => {
          this.patientDetails = response;
          this.itemsList = [];
          this.CreateUpdateMMOrderItemDTO = [];
          this.itemsList.push(this.addEmptyObject());
          this.calculateTotals();
          this.setItemTableHeight();
          this.orderDetailsForm.patchValue({
            txtDeductible: 0,
            txtCopay: 0,
            txtEstimatedAllowable: 0,
            txtEstimatedCopay: 0,
            txtTotalAccessory: 0,
          });
          //  this.reloadDatatable();
          this.loadFrequentBoughtItems();
          var offset = new Date(
            this.patientDetails?.dateOfBirth
          ).getTimezoneOffset();

          var localDOB = new Date(
            new Date(this.patientDetails?.dateOfBirth).getTime() +
              offset * 60000
          );
          const date = this.datepipe.transform(localDOB, 'MM/dd/yyyy');

          this.patientId = patientId;
          this.isAddressEdit = false;
          this.chartId = this.patientDetails?.chartNo;
          this.primaryVerificationStatus =
            this.patientDetails.primaryVerificationStatus ?? false;
          this.secondaryVerificationStatus =
            this.patientDetails.secondaryVerificationStatus ?? false;
          //this.addressId = this.patientDetails?.addressId;
          this.checkPatient();
          this.loadAuthIds();
          this.priPolicyId = this.patientDetails?.priPolicyId ?? defaultGuid;
          this.secPolicyId = this.patientDetails?.secPolicyId ?? defaultGuid;
          this.defaultFaxId = this.patientDetails.defaultFaxId ?? '';
          this.faxId = this.patientDetails?.faxId ?? defaultGuid;
          this.documentFaxId = this.patientDetails?.faxId ?? defaultGuid;
          this.medicareChecklistId =
            this.patientDetails?.medicareCheckListId ?? defaultGuid;
          this.priVerificationId =
            this.patientDetails?.priVerificationId ?? defaultGuid;
          this.secVerificationId =
            this.patientDetails?.secVerificationId ?? defaultGuid;
          this.isDocumentSorted = this.patientDetails?.isSorted ?? false;
          this.country = this.patientDetails?.country;
          this.loadPrimaryVerifications(this.priVerificationId);
          (this.primaryPolicyId =
            this.patientDetails?.priPolicyId ?? defaultGuid),
            (this.secondaryPolicyId =
              this.patientDetails?.secPolicyId ?? defaultGuid),
            (this.State = this.patientDetails?.state);

          (this.State != null || this.State != undefined) &&
            this.onChangeCity(this.State, this.country);

          (this.State != null || this.State != undefined) &&
            this.onChangeState(this.country);
          this.orderForm.patchValue({
            drpChartId: patientId,
            drpCSR: this.patientDetails?.csrId ?? '',
            txtBirthdate: this.patientDetails?.dateOfBirth,
            txtLastName: this.patientDetails?.lastName ?? '',
            txtFirstName: this.patientDetails?.firstName ?? '',
            txtMiddleName: this.patientDetails?.middleName ?? '',
            txtPrimaryInsurance: this.patientDetails?.priVerificationName ?? '',
            txtChecklist: this.patientDetails?.medicareCheckListName ?? '',
            txtSecondaryInsurance:
              this.patientDetails?.secVerificationName ?? '',
            chkBillingAddress: false,
            drpAuthorization: defaultGuid,
            drpLocation: this.patientDetails?.branchId ?? '',
            txtRXDate: this.patientDetails?.rxDate,
            drpProvider:
              this.patientDetails?.referringProviderId == null ||
              this.patientDetails?.referringProviderId == defaultGuid
                ? ''
                : this.patientDetails?.referringProviderId,
            drpPracticeManagement: this.patientDetails?.referalPracticeId ?? '',
            txtTechComments: this.patientDetails?.commentsToTech ?? '',
          });
          let addressDetails: any = {
            id: this.patientDetails?.addressId ?? defaultGuid,
            nameLine: this.patientDetails?.nameLine ?? '',
            description: this.patientDetails?.description ?? '',
            address: this.patientDetails?.address,
            country: this.patientDetails?.country,
            state: this.patientDetails?.state,
            city: this.patientDetails?.city,
            postalCode: this.patientDetails?.postalCode ?? '',
            homePhoneNo: this.patientDetails?.homePhoneNo ?? '',
            emailId: this.patientDetails?.emailId ?? '',
            mobile: this.patientDetails?.mobile ?? '',
            isGoogleValidated: this.patientDetails?.isGoogleValidated ?? false,
          };

          this.orderId == defaultGuid &&
            this.isCopyOrder !== 'true' &&
            this.loadAddressDetails(addressDetails);
          this.orderDetailsForm.patchValue({
            txtFaxID: this.patientDetails?.defaultFaxId,
          });
          this.loadOrderInfo();
          this.loaderService.hideLoader();
          this.reloadDatatable();
        },
        (err) => {
          this.loaderService.hideLoader();
        }
      );
    this.subscription$.push(patientDetails);
  }
  //To load the new Order Info
  loadOrderInfo() {
    if (this.orderId == defaultGuid) {
      this.mmOrderService.getOrderNumberDetails().subscribe((response) => {
        this.orderDetailsForm.patchValue({
          txtOrderNo: response.orderNumber,
          txtStatus: response.status,
        });
        this.orderStatusId = response.orderStatusId;

        //Set Order Started or Pending Flag
        this.isOrderEditable =
          response.mmOrderStatusId === saleOrderStatus.pendingOrder ||
          response.mmOrderStatusId === saleOrderStatus.orderStarted ||
          response?.mmOrderStatusId ===
            saleOrderStatus.disapprovedByBillingShipping
            ? true
            : false;
      });
    }
  }

  //To Load the Product Details based on the dropdown selection
  loadProductDetails(
    patientId: any,
    data: any,
    productId: string,
    thisordervalue: string
  ) {
    this.isOrderPageLoading = true;
    this.isSaveBtnDisabled = true;
    data.mmProductId = productId;
    this.productId = data.mmProductId;
    const minQty = this.filteredOptions.filter(
      (x) => x.productId === data.mmProductId
    );
    this.isStockMinQty = minQty[0];
    this.isMinQty =
      this.isStockMinQty.sMinStock == true
        ? this.isStockMinQty.productId
        : defaultGuid;
    // let isCashOrder: boolean = this.orderForm?.value?.chkCashOrder ?? false;
    let locationId: string = this.orderForm?.value?.drpLocation ?? defaultGuid;

    this.searchControl.setValue('');
    if (
      !(this.itemsList.filter((a) => a.mmProductId === productId).length > 1)
    ) {
      this.itemsList.filter((a) => a.mmProductId === '').length == 0 &&
        this.itemsList.push(this.addEmptyObject());
      this.reloadDatatable();

      const productValue = this.mmOrderService
        .loadProductDetailsByGProductIdAndGLoctionIdAndGInsuranceGroupIdAndBCashOrder(
          this.orderId,
          patientId,
          productId,
          locationId,
          this.primaryPolicyId,
          this.isCashOrder
        )
        .subscribe(
          (response) => {
            // this.productDetails = response

            let value = response;
            this.tempArray.push(response);
            this.tempArrayQty.push(response);

            if (value != null && value != undefined) {
              (data.productDescription = value.productDescription),
                (data.categoryName = value.categoryName),
                (data.patientQtyOnHand = value.patientQtyOnHand),
                (data.primaryInsuranceId = value.primaryInsuranceId);
              (data.awaitDlvry = value.awaitDlvry),
                (data.allowable = value.allowable),
                (data.points_Cal = value.points_Cal);
              // data.qty = value.qty ?? 0;
              (data.mmProductId = value.mmProductId),
                (data.mmOrderId = this.orderId),
                // (data.serialLotNo = value.serialLotNo),
                (data.sizeId = value.sizeId),
                (data.accessory = value.accessory ?? 0);
              (data.discountPercent = value.discountPercent),
                (data.pendpalOrder = value.pendpalOrder),
                (data.exchange = value.exchange),
                (data.return = value.return);
              data.productCode = value.productCode;
              data.actualAccessory = value.actualAccessory;
              data.discountAccessory = value.discountAccessory;
              data.unitAccessory = value.unitAccessory;
              data.unitAllowable = value.unitAllowable;
              data.bIsNewSetup = value.bIsNewSetup;
              data.sMinStock = value.sMinStock;
              data.showSettings = value?.showSettings;
              if (data.showSettings) {
                // For Main Product max Value Must be one
                data.qty = 1;
              }
              data.minQuantity = value?.minQuantity;
              data.maximumQuantity = value?.maximumQuantity;
              data.orderedQnty = value?.orderedQnty;
              this.calculateAccessorySum();
              this.calculateAllowableSum();
              this.calculatePointsSum();
              this.estimateDueAmountCalculation();
              this.loadSerialNumber(data);
              // this.itemsList.filter((a) => a.mmProductId === '').length === 0 &&
              //   this.itemsList.push(this.addEmptyObject());

              this.productDetails = this.itemsList;
              this.setItemTableHeight();
              // this.reloadDatatable();
              this.orderForm.markAsDirty();
              // Update the form's validity based on the new values
              this.orderForm.updateValueAndValidity();
              this.isSaveBtnDisabled = false;

              if (value?.showSettings === 1) {
                this.getMachineSettings(data.mmProductId, data.categoryName);
              }
            }
            this.isOrderPageLoading = false;
          },
          (err) => {
            this.isOrderPageLoading = false;
            this.isSaveBtnDisabled = false;
            const datas: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: datas?.error?.error?.message,
            });
            // this.itemsList = this.itemsList.filter((x) => x !== x.mmProductId);
            this.itemsList = this.itemsList.filter(
              (a) => a.mmProductId != data.mmProductId
            );
            this.reloadDatatable();
            data = this.clearObjectValue(data);
          }
        );
    } else {
      this.isSaveBtnDisabled = false;
      data.mmProductId = null;
      data = this.clearObjectValue(data);
      this.toastr.warning('Product Already Added!');
      this.isOrderPageLoading = false;
    }
  }

  //To get the Machine Settings
  getMachineSettings(productId: string, categoryName: string) {
    const machineSettings = this.orderItemSettings
      .getMachineSettingsByProductIdByProductId(productId)
      .subscribe(
        (response) => {
          this.machineSettingFields = response ?? null;
          const allValuesFalse = Object.values(this.machineSettingFields).every(
            (value) => value === false
          );
          if (allValuesFalse) {
            this.toastr.warning('Machine Options are not available');
          } else {
            this.openMachineSettings(productId, categoryName);
          }
        },
        (err) => {
          this.toastr.error(err);
        }
      );
    this.subscription$.push(machineSettings);
  }

  toFixed(data) {
    if (data != null || data != undefined) {
      let value = data.toFixed(2);
      return value;
    } else {
      return data;
    }
  }
  clearObjectValue(data) {
    (data.mmProductId = ''),
      (data.productDescription = ''),
      (data.qtyOnHand = 0),
      (data.awaitDlvry = 0),
      (data.allowable = 0),
      (data.points_Cal = 0),
      (data.thisOrder = 0),
      (data.patientQtyOnHand = 0),
      (data.serialLotNo = ''),
      (data.sizeId = ''),
      (data.accessory = 0),
      (data.discountPercentage = 0),
      (data.pendPatOrder = 0),
      (data.showSettings = 0),
      (data.exchange = false),
      (data.return = false);
    return data;
  }

  selectShipping(shippingMethodId: any) {
    var shippingFee = this.lstShippingMethods.find(
      (a) => a.id === shippingMethodId
    )?.estShipping;
    this.orderDetailsForm.patchValue({
      txtShippingFee: shippingFee,
    });
    this.estimateDueAmountCalculation();
  }

  updateQty(data, changedValue) {
    if (Number(changedValue) === this.currentValue) {
      return false;
    }
    //#region Allowed Quantity validation
    //   if(data.minQuantity!==0 && data.maximumQuantity!==0){
    //   if(this.orderForm.get('chkCashOrder').value !=true&&(data.showSettings!=1) &&(Number(data.qty)<data.minQuantity || Number(data.qty)>data.maximumQuantity)){
    //   data.qty=0;
    //     this.toastr.warning(
    //       'Allowed Maximum limit exceeded in this product<br>' +
    //      '<span> Order Quantity: '+ (data.orderedQnty===null?0:(data.orderedQnty<=0?0:data.orderedQnty))+ '</span><br>'+
    //      '<span> Maximum Quantity: '+ (data.maximumQuantity)+ '</span><br>',
    //       '',
    //       { "closeButton": true, "timeOut": 5000, "extendedTimeOut": 2000,enableHtml: true  }
    //   );
    //   }
    // }
    //#endregion

    // this.toastr.warning('Shipping Address is Not Verified', '', {
    //   timeOut: 5000,
    // });
    // this.tempArray = this.itemsList
    this.isOrderPageLoading = true;
    const filterValue = this.tempArrayQty.filter(
      (x) => x.mmProductId == data.mmProductId
    );
    if (data.thisOrder != '') {
      const updateInputs: CreateUpdateMMOrderItemDTO = {
        tenantId: this.tenantId,
        mmReferenceOrderItemId: 0,
        bCashOrder:
          this.orderForm.value.chkCashOrder == '' ||
          this.orderForm.value.chkCashOrder == null
            ? false
            : this.orderForm.value.chkCashOrder,
        stockCount: 0,
        totalAmount: 0,
        minimumQuantity: 0,
        mmCategoryReferenceId: 0,
        mmOrderId: data.mmOrderId ?? defaultGuid,
        mmProductId: data.mmProductId,
        primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
        qty: Number(data.qty),
        allowable: data.allowable,
        accessory: Number(data.accessory),
        backOrderedSince: new Date().toLocalISOString(),
        lineItemNotes: '',
        productDescription: data.productDescription,
        patientQtyOnHand: data.patientQtyOnHand,
        points_Cal: data.points_Cal,
        sizeId: data.sizeId ?? defaultGuid,
        serialLotNo: defaultGuid,
        serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
        itemSettings: data?.itemSettings ?? null,
        itemCost: 0,
        exchange: data.exchange,
        return: data.return,
        fixedPoints: 0,
        unitCost: data.unitCost,
        unitAllowable: data.unitAllowable,
        unitAccessory: data.unitAccessory,
        mmOrderItemId: data.mmOrderItemId ?? defaultGuid,
        discountPercent: data.discountPercent,
        specialItem: 0,
        addOnFromSuggestions: 0,
        remoteTransferSuccessful: 0,
        status: 0,
        awaitDlvry: data.awaitDlvry,
        pendpalOrder: data.pendpalOrder,
        isInsuranceCategory: false,
        hcpcList: null,
        actualAccessory: data.actualAccessory,
        sMinStock: data.sMinStock,
        discountAccessory: data.discountAccessory,
        bIsNewSetup: data.bIsNewSetup,
      };

      filterValue.push(updateInputs);

      const updateQty = this.mmOrderService
        .updateAccessoryExchangeDetailsByInput(filterValue)
        .subscribe(
          (response) => {
            this.productDetails = response;
            this.isOrderPageLoading = false;
            let value = response;
            if (value != null && value != undefined) {
              (data.productDescription = value.productDescription),
                (data.patientQtyOnHand = value.patientQtyOnHand),
                (data.primaryInsuranceId = value.primaryInsuranceId);
              // data.awaitDlvry = value.qty,
              (data.allowable = value.allowable),
                (data.points_Cal = value.points_Cal);
              data.qty = value.qty ?? 0;
              // data.mmProductId = value.mmProductId,
              data.sizeId = value.sizeId;
              // (data.serialLotNo = value.serialLotNo),
              (data.sizeId = value.sizeId),
                (data.accessory = value.accessory ?? 0);
              (data.discountPercent = value.discountPercent),
                (data.pendpalOrder = value.pendpalOrder),
                (data.exchange = value.exchange),
                (data.return = value.return);
              data.actualAccessory = value.actualAccessory;
              data.discountAccessory = value.discountAccessory;
              data.unitAccessory = value.unitAccessory;
              data.unitAllowable = value.unitAllowable;
              data.bIsNewSetup = value.bIsNewSetup;
              data.sMinStock = value.sMinStock;
              this.calculateAccessorySum();
              this.calculateAllowableSum();
              this.calculatePointsSum();
              this.estimateDueAmountCalculation();

              // this.itemsList.filter((a) => a.mmProductId === '').length === 0 &&
              //   this.itemsList.push(this.addEmptyObject());
              this.setItemTableHeight();
              // this.reloadDatatable();
            }
          },
          (err) => {
            this.isOrderPageLoading = false;
          }
        );
    }
  }

  //#region CashOrder

  //To change the values when the cash order checkbox was toggled
  cashOrder(e: any) {
    if (e.checked) {
      this.isCashOrder = true;
      // this.orderForm.patchValue({
      //   txtPrimaryInsurance: '',
      //   drpPrimaryInsurance:defaultGuid,
      //   txtSecondaryInsurance: '',
      //   // txtChecklist: 'None',
      //   drpAuthorization: defaultGuid,
      // });
      this.orderForm.get('drpPrimaryInsurance').disable();
      this.orderForm.get('txtSecondaryInsurance').disable();
      this.itemsList = [];
      this.itemsList.filter((a) => a.mmProductId === '').length === 0 &&
        this.itemsList.push(this.addEmptyObject());
      this.productId =
        this.itemsList.filter((a) => a.mmProductId != '').length > 0
          ? this.productId
          : '';
      this.reloadDatatable();
      this.orderDetailsForm.patchValue({
        txtDeductible: 0,
        txtCopay: 0,
        txtEstimatedAllowable: 0,
        txtEstimatedCopay: 0,
        txtTotalAccessory: 0,
      });
      this.isEstimateDueAmount = [];
      this.estimateDueAmountCalculation();
      const cellControl = this.orderForm.get('txtCellNo');
      const emailControl = this.orderForm.get('txtEmail');
      if (
        this.orderForm.get('txtEmail').value === '' ||
        this.orderForm.get('txtEmail').value === null ||
        this.orderForm.get('txtEmail').value === undefined
      ) {
        // emailControl.setErrors({ required: true });
      }
      if (
        this.orderForm.get('txtCellNo').value === '' ||
        this.orderForm.get('txtCellNo').value === null ||
        this.orderForm.get('txtCellNo').value === undefined
      ) {
        // cellControl.setErrors({ required: true });
      }
      const primaryInsurance = this.orderForm.get('drpPrimaryInsurance');
      primaryInsurance.clearValidators();
      primaryInsurance.updateValueAndValidity();
    } else {
      this.isCashOrder = false;
      const cellControl = this.orderForm.get('txtCellNo');
      // // cellControl.clearValidators();
      // cellControl.updateValueAndValidity();
      const emailControl = this.orderForm.get('txtEmail');
      // // emailControl.clearValidators();
      // emailControl.updateValueAndValidity();
      if (
        this.orderForm.get('txtEmail').value === '' ||
        this.orderForm.get('txtEmail').value === null ||
        this.orderForm.get('txtEmail').value === undefined
      ) {
        // emailControl.setErrors({ required: false });
      }
      if (
        this.orderForm.get('txtCellNo').value === '' ||
        this.orderForm.get('txtCellNo').value === null ||
        this.orderForm.get('txtCellNo').value === undefined
      ) {
        // cellControl.setErrors({ required: false });
      }
      const primaryInsurance = this.orderForm.get('drpPrimaryInsurance');
      primaryInsurance.setValidators([Validators.required]);
      primaryInsurance.updateValueAndValidity();
      this.orderForm.get('drpPrimaryInsurance').enable();
      this.orderForm.get('txtSecondaryInsurance').enable();
      // this.orderForm.patchValue({
      //   txtPrimaryInsurance: this.patientDetails?.priVerificationName ?? '',
      //   drpPrimaryInsurance:  this.patientDetails?.priVerificationId ?? defaultGuid,
      //   txtSecondaryInsurance: this.patientDetails?.secVerificationName ?? '',
      //   // txtChecklist: defaultGuid,//'Medicare', //,
      //   // drpAuthorization: this.patientDetails?.authorizationId,
      // });

      this.itemsList = [];
      this.isEstimateDueAmount = [];
      this.itemsList.filter((a) => a.mmProductId === '').length === 0 &&
        this.itemsList.push(this.addEmptyObject());
      this.productId =
        this.itemsList.filter((a) => a.mmProductId != '').length > 0
          ? this.productId
          : '';
      this.reloadDatatable();

      this.estimateDueAmountCalculation();
    }
  }

  //#endregion

  //Re Initialize the Datatable
  reloadDatatable() {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      if (index === 0) {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.columns.adjust().draw();
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      }
    });
  }

  updateAccessory(data, changedValue) {
    if (
      Number(changedValue) === this.currentValue ||
      Number(changedValue) > 100
    ) {
      return false;
    }
    // this.tempArray = this.itemsList
    const filterValue = this.tempArray.filter(
      (x) => x.mmProductId == data.productId
    );
    this.isOrderPageLoading = true;
    // if (data.accessory != '') {
    const updateInputs: CreateUpdateMMOrderItemDTO = {
      tenantId: this.tenantId,
      mmReferenceOrderItemId: 0,
      bCashOrder:
        this.orderForm.value.chkCashOrder == '' ||
        this.orderForm.value.chkCashOrder == null
          ? false
          : this.orderForm.value.chkCashOrder,
      stockCount: 0,
      totalAmount: 0,
      minimumQuantity: 0,
      mmCategoryReferenceId: 0,
      mmOrderId: data.mmOrderId ?? defaultGuid,
      primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
      mmProductId: data.mmProductId,
      qty: Number(data.qty),
      allowable: data.allowable,
      accessory: Number(
        data.accessory == null || data.accessory == '' ? 0 : data.accessory
      ),
      backOrderedSince: new Date().toLocalISOString(),
      lineItemNotes: '',
      productDescription: data.productDescription,
      patientQtyOnHand: data.patientQtyOnHand,
      points_Cal: data.points_Cal,
      sizeId: data.sizeId ?? defaultGuid,
      serialLotNo: defaultGuid,
      serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
      itemSettings: data?.itemSettings ?? null,
      itemCost: 0,
      exchange: data.exchange,
      return: data.return,
      fixedPoints: 0,
      unitCost: data.unitCost,
      unitAllowable: data.unitAllowable,
      unitAccessory: data.unitAccessory,
      mmOrderItemId: data.mmOrderItemId ?? defaultGuid,
      discountPercent: Number(
        data.discountPercent == ''
          ? 0
          : data.discountPercent > 100
          ? 0
          : data.discountPercent
      ),
      specialItem: 0,
      addOnFromSuggestions: 0,
      remoteTransferSuccessful: 0,
      status: 0,
      awaitDlvry: data.awaitDlvry,
      pendpalOrder: data.pendpalOrder,
      hcpcList: null,
      isInsuranceCategory: false,
      actualAccessory: data.actualAccessory,
      discountAccessory: data.discountAccessory,
      sMinStock: data.sMinStock,
      bIsNewSetup: data.bIsNewSetup,
    };
    filterValue.push(updateInputs);
    const updateQty = this.mmOrderService
      .updateAccessoryExchangeDetailsByInput(filterValue)
      .subscribe(
        (response) => {
          this.productDetails = response;
          let value = response;
          if (value != null && value != undefined) {
            (data.productDescription = value.productDescription),
              (data.patientQtyOnHand = value.patientQtyOnHand),
              (data.primaryInsuranceId = value.primaryInsuranceId);
            // data.awaitDlvry = value.qty,
            (data.allowable = value.allowable),
              (data.points_Cal = value.points_Cal);
            data.qty = value.qty ?? 0;
            // data.productId = value.mmProductId,
            // (data.serialLotNo = value.serialLotNo),
            (data.sizeId = value.sizeId),
              (data.accessory = value.accessory ?? 0);
            (data.discountPercent = value.discountPercent),
              (data.pendpalOrder = value.pendpalOrder),
              (data.exchange = value.exchange ?? false),
              (data.return = value.return);
            data.actualAccessory = value.actualAccessory;
            data.discountAccessory = value.discountAccessory;
            data.unitAccessory = value.unitAccessory;
            data.unitAllowable = value.unitAllowable;
            data.bIsNewSetup = value.bIsNewSetup;
            data.sMinStock = value.sMinStock;
            this.calculateAccessorySum();
            this.calculateAllowableSum();
            this.calculatePointsSum();
            this.estimateDueAmountCalculation();

            this.itemsList.filter((a) => a.mmProductId === '').length === 0 &&
              this.itemsList.push(this.addEmptyObject());
            this.setItemTableHeight();
            this.reloadDatatable();
            this.isOrderPageLoading = false;
          }
        },
        (err) => {
          this.isOrderPageLoading = false;
        }
      );
    // }
  }
  showSettingsCount: number = 0;
  checkIsShowSettingsSelected() {
    this.showSettingsCount = this.itemsList.filter(
      (a) => a.showSettings === 1
    ).length;

    let mainItem = this.itemsList.filter((a) => a.showSettings === 1);
    this.unitAllowable = mainItem[0]?.unitAllowable;
    if (this.showSettingsCount === 0) {
      this.orderForm.patchValue({
        txtmoths: null,
        chkRental: null,
      });
    } else if (this.showSettingsCount === 1) {
    } else if (this.showSettingsCount === 2) {
      this.orderForm.patchValue({
        txtmoths: null,
        chkRental: null,
      });
    } else {
      this.orderForm.patchValue({
        txtmoths: null,
        chkRental: null,
      });
    }
    // this.rentalCheck();
  }

  patientValidation(): boolean {
    return this.patientId == defaultGuid ||
      this.patientId == null ||
      this.patientId == undefined ||
      this.patientId == ''
      ? true
      : false;
  }
  cashOrderValidation(): boolean {
    return this.patientId == defaultGuid ||
      this.patientId == null ||
      this.patientId == undefined ||
      this.patientId == '' ||
      this.isExchangedOrder
      ? true
      : false;
  }
  fnCheckMaxMonthVlaue() {
    let month = this.orderForm.get('txtmoths').value;
    if (month < 2) {
      this.orderForm.patchValue({
        txtmoths: 2,
      });
    } else if (month > 13) {
      this.orderForm.patchValue({
        txtmoths: 13,
      });
    }
  }

  estimateDueAmountCalculation() {
    this.checkIsShowSettingsSelected();
    let data = this.itemsList;
    data.filter((data) => {
      if (data.mmProductId !== '' || data.mmProductId !== data.mmProductId) {
        let indexData = this.itemsList.findIndex(
          (item) => item.mmProductId == data.mmProductId
        );
        if (indexData !== -1) {
          this.isEstimateDueAmount[indexData] = {
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
            // showSettings : this.showSettingsCount >= 1 ? 1 : 0,
            showSettings: data.showSettings || 0,
          };
        } else {
          this.isEstimateDueAmount.push({
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
            // showSettings : this.showSettingsCount >= 1 ? 1 : 0,
            showSettings: data.showSettings || 0,
          });
        }
      }
    });

    const saveDtails: CreateUpdateMMOrderDTO = {
      tenantId: this.tenantId,
      mmRefereceOrderId: this.orderDetailsForm.value.txtOrderNo ?? 0,
      authorizationId: this.orderForm.value.drpAuthorization,
      patientId: this.patientId,
      locationId: this.orderForm.value.drpLocation
        ? this.orderForm.value.drpLocation
        : defaultGuid,
      bUsePatientAddress: 0,
      addressId: this.addressId ?? defaultGuid,
      supplies: 0,
      lmn: 0,
      rx_MedForceScanId: 0,
      primaryVerificationId: this.priVerificationId ?? defaultGuid,
      secondaryVerificationId: this.secVerificationId ?? defaultGuid,
      deductible:
        this.orderDetailsForm.value.txtDeductible == null ||
        this.orderDetailsForm.value.txtDeductible === ''
          ? 0
          : this.orderDetailsForm.value.txtDeductible, //this.orderDetailsForm.value.txtDeductible ?? 0
      copay:
        this.orderDetailsForm.value.txtCopay == null ||
        this.orderDetailsForm.value.txtCopay === ''
          ? 0
          : this.orderDetailsForm.value.txtCopay,
      commentsToTech: this.orderForm.value.txtTechComments ?? '',
      commentsToShipping: this.orderForm.value.txtShippingComments ?? '',
      commentsToBilling: this.orderForm.value.txtBillingComments ?? '',
      commentsToPatient: this.orderForm.value.txtPatientComments ?? '',
      newSetup: 0,
      csRid: this.orderForm.value.drpCSR
        ? this.orderForm.value.drpCSR
        : defaultGuid,
      referralPracticeId: this.orderForm.value.drpPracticeManagement
        ? this.orderForm.value.drpPracticeManagement
        : defaultGuid,
      referralSourceId: this.orderForm.value.drpProvider
        ? this.orderForm.value.drpProvider
        : defaultGuid,
      shippingMethodId: this.orderForm.value.drpShippingMethod
        ? this.orderForm.value.drpShippingMethod
        : defaultGuid,
      shippingFee: this.orderDetailsForm.value.txtShippingFee ?? 0,
      paPtherapyUsedAndBeneficial: 0,
      previousOrderStatus: defaultGuid,
      cashOrder:
        this.orderForm.value.chkCashOrder == ''
          ? false
          : this.orderForm.value.chkCashOrder ?? false,
      faxId: this.defaultFaxId ?? '',
      // faxId: defaultGuid,
      defaultSaleOrderId: '',
      orderAmount: Number(this.orderDetailsForm.value.txtNetTotal) ?? 0,
      estDueAmount: this.orderDetailsForm.value.txtEstimateAmountDue ?? 0,
      estCopay: Number(this.orderDetailsForm.value.txtEstimatedCopay) ?? 0,
      estAllowable:
        Number(this.orderDetailsForm.value.txtEstimatedAllowable) ?? 0,
      totalAccessory:
        Number(this.orderDetailsForm.value.txtTotalAccessory) ?? 0,
      checkListId: this.medicareChecklistId
        ? this.medicareChecklistId
        : defaultGuid,
      preCertId: defaultGuid,
      rxDate:
        this.orderForm.value.txtRXDate !== null &&
        this.orderForm.value.txtRXDate !== ''
          ? this.datepipe.transform(
              this.orderForm.value.txtRXDate,
              'yyyy-MM-dd'
            )
          : null,
      lockedBy: defaultGuid,
      lockedOn: new Date().toLocalISOString(),
      lockedBySPID: 0,
      modifiedBy: defaultGuid,
      modifiedOn: new Date().toLocalISOString(),
      orderDate: this.orderForm.value.txtOrderDate ?? this.ctDateNow(),
      backOrderDate: new Date().toLocalISOString(),
      orderStatusSince: new Date().toLocalISOString(),
      setupDate: new Date().toLocalISOString(),
      suppliesDate: new Date().toLocalISOString(),
      shippingStatusId: defaultGuid,
      orderCreatedBy: defaultGuid,
      overrideBy: defaultGuid,
      overrideDt: new Date().toLocalISOString(),
      overrideNote: '',
      balOverrideBy: defaultGuid,
      balOverrideDt: new Date().toLocalISOString(),
      orderPointsCAL: this.orderDetailsForm.value.txtPoints
        ? this.orderDetailsForm.value.txtPoints
        : 0,
      pastDueBalance: this.isEmpty(
        this.orderDetailsForm.value.txtPastDueBalance
      )
        ? 0
        : this.orderDetailsForm.value.txtPastDueBalance,
      lastPaymentId: defaultGuid,
      paymentsPostedByUserId: defaultGuid,
      paymentsPostedOn: new Date().toLocalISOString(),
      billingApprovalNote: '',
      billingNote: this.orderForm.value.txtBillingComments ?? '',
      billingApprovalStatusId: defaultGuid,
      pendingAtBilling: 0,
      pendingAtBillingApproval: 0,
      orderCreatedOn: new Date().toLocalISOString(),
      functionalityQuNote: 0,
      verifiedPatientInfo: this.orderForm.value.chkVerified == true ? 1 : 0,
      downloadedCompliance: 0,
      mfs_ComplianceImageId: 0,
      ftf_Date: new Date().toLocalISOString(),
      mfs_FTFImageId: 0,
      finalBilling: new Date().toLocalISOString(),
      complianceReleaseChecklistId: 0,
      orderSentToShipping: new Date().toLocalISOString(),
      remoteVideoCall: 0,
      curbSidePU: 0,
      orderStatus: this.orderStatusId ?? defaultGuid,
      status: 0,
      orderItems: this.isEstimateDueAmount,
      isResupply: false,
      isTemplate: 0,
      rentalresupplySaleOrder: null,
      rental: false,
      months: 0,
      rxDocuments: this.ltRXDocument.length === 0 ? [] : this.ltRXDocument,
    };
    this.mmOrderService
      .computeOrderAmountByInput(saveDtails)
      .subscribe((response) => {
        const estCopay = this.calculateEstimatedCoPay(
          this.orderDetailsForm?.value?.txtEstimatedAllowable ?? 0,
          response?.deductible,
          response?.copay
        );
        const estDueAmnt = (
          Number(estCopay) +
          (Number(this.orderDetailsForm.value?.txtShippingFee) ?? 0) +
          (Number(this.orderDetailsForm.value?.txtTotalAccessory) ?? 0)
        ).toFixed(2);
        const netAmount = (
          Number(this.orderDetailsForm?.value?.txtEstimatedAllowable ?? 0) +
          (Number(this.orderDetailsForm.value?.txtTotalAccessory) ?? 0) +
          Number(this.orderDetailsForm.value?.txtShippingFee ?? 0)
        ).toFixed(2);
        this.rentalAllowable = response.rentalAllowable;
        this.nonRentalAllowable = response.nonRentalAllowable;

        this.orderDetailsForm.patchValue({
          txtEstimateAmountDue: estDueAmnt,
          txtBalance: estDueAmnt,
          //txtBalance: estDueAmnt + response.pastDueBalance,
          txtDeductible: response.deductible,
          txtCopay: response.copay,
          txtEstimatedCopay: estCopay,
          txtPastDueBalance: response.pastDueBalance,
          txtNetTotal: netAmount,
          // (
          //   (Math.sign(response.estDueAmount - response?.deductible) == 1
          //     ? response.estDueAmount - response?.deductible
          //     : 0) *
          //   (response?.copay / 100)
          // ).toFixed(2),
        });
        this.orderDetailsForm.value.txtBalance != ''
          ? (this.isRental = true)
          : (this.isRental = false);
      });
  }

  selectSize(data) {
    this.sizeId = data;
  }

  onOrderDateChange() {
    this.orderForm.patchValue({
      txtDOS: this.orderForm.value.txtOrderDate,
    });
  }

  //#region OrderSave
  saveOrder() {
    let data = this.itemsList;
    this.loaderService.showLoader();
    this.isSaveBtnDisabled = true;
    data.filter((data) => {
      if (data.mmProductId !== '' || data.mmProductId !== data.mmProductId) {
        // if ( data.mmProductId !== '') {
        let indexData = this.itemsList.findIndex(
          (item) => item.mmProductId == data.mmProductId
        );

        if (indexData !== -1) {
          this.CreateUpdateMMOrderItemDTO[indexData] = {
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
          };
        } else {
          this.CreateUpdateMMOrderItemDTO.push({
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid, //defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
          });
        }
      }
    });
    const filteredArray = this.CreateUpdateMMOrderItemDTO.filter((item) => {
      // Check if item is not null, undefined, empty object, or empty array
      if (item === null || item === undefined) return false;
      if (typeof item === 'object' && Object.keys(item).length === 0)
        return false;
      if (Array.isArray(item) && item.length === 0) return false;
      return true;
    });
    this.CreateUpdateMMOrderItemDTO = filteredArray;
    const saveDetails: CreateUpdateMMOrderDTO = {
      tenantId: this.tenantId,
      authorizationId: this.orderForm.value.drpAuthorization,
      mmRefereceOrderId: this.orderDetailsForm.value.txtOrderNo ?? 0,
      patientId: this.patientId,
      locationId: this.orderForm.value.drpLocation
        ? this.orderForm.value.drpLocation
        : defaultGuid,
      bUsePatientAddress: 0,
      addressId: this.addressId ?? defaultGuid,
      supplies: 0,
      lmn: 0,
      rx_MedForceScanId: 0,
      primaryVerificationId: this.priVerificationId ?? defaultGuid,
      // secondaryVerificationId: this.secVerificationId ?? defaultGuid,
      secondaryVerificationId: this.secVerificationId ?? defaultGuid,
      deductible:
        this.orderDetailsForm.value.txtDeductible == null ||
        this.orderDetailsForm.value.txtDeductible === ''
          ? 0
          : this.orderDetailsForm.value.txtDeductible,
      copay:
        this.orderDetailsForm.value.txtCopay == null ||
        this.orderDetailsForm.value.txtCopay === ''
          ? 0
          : this.orderDetailsForm.value.txtCopay,
      commentsToTech: this.orderForm.value.txtTechComments ?? '',
      commentsToShipping: this.orderForm.value.txtShippingComments ?? '',
      commentsToBilling: this.orderForm.value.txtBillingComments ?? '',
      commentsToPatient: this.orderForm.value.txtPatientComments ?? '',
      newSetup: 0,
      csRid: this.orderForm.value.drpCSR || defaultGuid,
      referralPracticeId: this.orderForm.value.drpPracticeManagement
        ? this.orderForm.value.drpPracticeManagement
        : defaultGuid,
      referralSourceId: this.orderForm.value.drpProvider
        ? this.orderForm.value.drpProvider
        : defaultGuid,
      shippingMethodId: this.orderForm.value.drpShippingMethod
        ? this.orderForm.value.drpShippingMethod
        : defaultGuid,
      shippingFee: this.orderDetailsForm.value.txtShippingFee ?? 0,
      paPtherapyUsedAndBeneficial: 0,
      previousOrderStatus: defaultGuid,
      cashOrder:
        this.orderForm.value.chkCashOrder == ''
          ? false
          : this.orderForm.value.chkCashOrder ?? false,
      faxId: this.defaultFaxId ?? '',
      // faxId: defaultGuid,
      defaultSaleOrderId: '',
      orderAmount: Number(this.orderDetailsForm.value.txtNetTotal) ?? 0,
      estDueAmount: this.orderDetailsForm.value.txtEstimateAmountDue ?? 0,
      estCopay: Number(this.orderDetailsForm.value.txtEstimatedCopay) ?? 0,
      estAllowable:
        Number(this.orderDetailsForm.value.txtEstimatedAllowable) ?? 0,
      totalAccessory:
        Number(this.orderDetailsForm.value.txtTotalAccessory) ?? 0,
      checkListId: this.medicareChecklistId
        ? this.medicareChecklistId
        : defaultGuid,
      preCertId: defaultGuid,
      rxDate:
        this.orderForm.value.txtRXDate !== null &&
        this.orderForm.value.txtRXDate !== ''
          ? this.datepipe.transform(
              this.orderForm.value.txtRXDate,
              'yyyy-MM-dd'
            )
          : null,
      // this.orderForm.value.txtRXDate ?? null,
      lockedBy: defaultGuid,
      lockedOn: new Date().toLocalISOString(),
      lockedBySPID: 0,
      modifiedBy: defaultGuid,
      modifiedOn: new Date().toLocalISOString(),
      orderDate:
        this.getFormattedDate(this.orderForm.value.txtOrderDate) ??
        this.getFormattedDate(new Date()),
      // this.commonService.getCSTDateTime(this.orderForm.value.txtOrderDate) ??
      // this.commonService.getCSTDateTime(new Date()),
      // this.getFormattedDate(this.orderForm.value.txtOrderDate) ??
      // new Date().toLocalISOString(),
      dos:
        this.getFormattedDate(this.orderForm.value.txtDOS) ??
        this.getFormattedDate(new Date()),
      // this.commonService.getCSTDateTime(this.orderForm.value.txtDOS) ??
      // this.commonService.getCSTDateTime(new Date()),
      // this.getFormattedDate(this.orderForm.value.txtDOS) ??
      // new Date().toLocalISOString(),
      backOrderDate: new Date().toLocalISOString(),
      orderStatusSince: new Date().toLocalISOString(),
      setupDate: new Date().toLocalISOString(),
      suppliesDate: new Date().toLocalISOString(),
      shippingStatusId: defaultGuid,
      orderCreatedBy: defaultGuid,
      overrideBy: defaultGuid,
      overrideDt: new Date().toLocalISOString(),
      overrideNote: '',
      balOverrideBy: defaultGuid,
      balOverrideDt: new Date().toLocalISOString(),
      orderPointsCAL: this.orderDetailsForm.value.txtPoints || 0,
      pastDueBalance: this.isEmpty(
        this.orderDetailsForm.value.txtPastDueBalance
      )
        ? 0
        : this.orderDetailsForm.value.txtPastDueBalance,
      lastPaymentId: defaultGuid,
      paymentsPostedByUserId: defaultGuid,
      paymentsPostedOn: new Date().toLocalISOString(),
      billingApprovalNote: '',
      billingNote: this.orderForm.value.txtBillingComments ?? '',
      billingApprovalStatusId: defaultGuid,
      pendingAtBilling: 0,
      pendingAtBillingApproval: 0,
      orderCreatedOn: new Date().toLocalISOString(),
      functionalityQuNote: 0,
      verifiedPatientInfo: this.orderForm.value.chkVerified == true ? 1 : 0,
      downloadedCompliance: 0,
      mfs_ComplianceImageId: 0,
      ftf_Date: new Date().toLocalISOString(),
      mfs_FTFImageId: 0,
      finalBilling: new Date().toLocalISOString(),
      complianceReleaseChecklistId: 0,
      // orderSentToShipping: new Date().toLocalISOString(),
      remoteVideoCall: 0,
      curbSidePU: 0,
      orderStatus: this.orderStatusId ?? defaultGuid,
      status: 0,
      rental: this.orderForm.value.chkRental
        ? this.orderForm.value.chkRental
        : false,
      months: this.orderForm.value.chkRental
        ? this.orderForm.value.txtmoths
          ? this.orderForm.value.txtmoths
          : 0
        : 0,
      orderItems: this.CreateUpdateMMOrderItemDTO,
      faxDocumentId: this.faxId,
      isResupply: false,
      isTemplate: 0,
      rentalresupplySaleOrder: null,
      rxDocuments: this.ltRXDocument.length === 0 ? [] : this.ltRXDocument,
      rentalAllowable: this.rentalAllowable || 0,
      nonRentalAllowable: this.nonRentalAllowable || 0,
    };
    //Save
    if (
      this.orderId === null ||
      this.orderId === '' ||
      this.orderId === defaultGuid
    ) {
      const saveData = this.mmOrderService.createV1(saveDetails).subscribe(
        (response) => {
          // this.orderId = response?.id ?? defaultGuid;
          // this.patientId=defaultGuid;
          // this.isPatientSelected=false;
          this.loaderService.hideLoader();
          // this.loadFrequentBoughtItems();
          this.toastr.success('Order Saved Successfully', 'Success');
          this.isSaveBtnDisabled = false;
          // this.orderDetailsForm.reset();
          // this.isAddressEdit = false;
          // this.addressChanged = false;
          // this.orderForm.reset();
          // this.ltRXDocument = [];
          this.navigateToOrderPage(defaultGuid, defaultGuid);
          this.itemsList = [];
          // this.itemsList = this.itemsList.filter(
          //   (item) => item.mmProductId === ''
          // );
          // this.reloadDatatable();
        },
        (err) => {
          this.loaderService.hideLoader();
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.isSaveBtnDisabled = false;
        }
      );
    }
    //Update
    else {
      this.mmOrderService.update(this.orderId, saveDetails).subscribe(
        (response) => {
          this.loadFrequentBoughtItems();
          this.toastr.success('Order Updated Successfully', 'Success');
          this.loaderService.hideLoader();
          this.ltRXDocument = [];
          this.isAddressEdit = false;
          this.addressChanged = false;
          this.loadOrderDetails(this.orderId);
          this.isSaveBtnDisabled = false;
          // this.orderDetailsForm.reset();
          // this.orderForm.reset();
          // this.itemsList = this.itemsList.filter(
          //   (item) => item.mmProductId === ''
          // );
        },
        (err) => {
          this.loaderService.hideLoader();
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
          this.isSaveBtnDisabled = false;
        }
      );
    }
  }

  //#endregion

  rentalCheck() {
    // this.checkIsShowSettingsSelected();
    // (!this.isRental) ||
    if (
      this.showSettingsCount != 1 ||
      this.isCashOrder ||
      this.patientValidation() ||
      this.rentalCheckBoxWantToDisable ||
      this.unitAllowable <= 0
    ) {
      this.orderForm.get('chkRental').disable();
      // this.orderForm.patchValue({
      //   txtmoths: null,
      //   chkRental: null,
      // });
      // console.log("disable");
    } else {
      this.orderForm.get('chkRental').enable();
      // this.fnCheckMaxMonthVlaue();
      // console.log("enable");
    }
  }
  //To navigate to the Order Page
  navigateToOrderPage(patientId: string, orderId: string) {
    this.isFormChanged = true;
    this.showSettingsCount = 0;
    this.isRental = false;
    this.isSaveBtnDisabled = false;
    this.patientId = patientId;
    this.orderId = orderId;
    this.primaryVerificationStatus = false;
    this.secondaryVerificationStatus = false;
    this.isDisableRemoveItem = false;
    // this.searchPatient('', patientId);
    this.loadChartNos('', patientId);
    this.orderDetailsForm.reset();
    this.orderForm.reset();
    this.orderDetailsForm.enable();
    this.orderForm.enable();
    this.orderForm.get('txtAddress').disable();
    this.orderForm.get('txtCountry').disable();
    this.orderForm.get('txtState').disable();
    this.orderForm.get('txtCity').disable();
    this.orderForm.get('txtPostalCode').disable();
    this.orderForm.get('txtHomeNo').disable();
    this.orderForm.get('txtEmail').disable();
    this.orderForm.get('txtCellNo').disable();
    // this.orderForm.get('chkRental').disable();
    this.isAddressDisabled = true;
    this.isAddressEdit = false;
    this.itemsList = [];
    this.rentalCheckBoxWantToDisable = false;
    this.itemsList.filter((a) => a.mmProductId === '').length === 0 &&
      this.itemsList.push(this.addEmptyObject());
    this.reloadDatatable();
    this.isCashOrder = false;
    this.router.navigate([
      '/orders/saleOrder/' + patientId + '/' + orderId + '/' + this.pageType,
    ]);
    this.loadDefaultFields();
    this.isPatientSelected = false;
    this.ltRXDocument = [];
  }

  //To View the Patient Ledger
  viewPatientLedgerPopup() {
    this.dialog.open(ViewPatientLedgerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        patientId: this.patientId,
        chartNo: this.chartId,
        // mmOrderId: data.mmOrderId,
        // mmOrderItemId: data.mmOrderItemId
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => {});
  }

  //Method to add the Empty Row to the Table
  addEmptyObject(): any {
    this.itemCount++;
    let value: any = {
      sortNumber: this.itemCount,
      productCode: '',
      productDescription: '',
      categoryName: '',
      sizeId: '',
      qty: 1,
      serialLotNo: '',
      ltSerialLotNos: [],
      primaryInsuranceId: defaultGuid,
      patientQtyOnHand: 0,
      awaitDlvry: 0,
      pendpalOrder: 0,
      mmProductId: '',
      allowable: 0,
      accessory: 0,
      points_Cal: 0,
      discountPercent: 0,
      exchange: false,
      return: false,
      actualAccessory: 0,
      discountAccessory: 0,
      bIsNewSetup: false,
      showSettings: 0,
      itemSettings: null,
    };
    return value;
  }

  //Update Character Count for TextArea
  updateCharacterCount(maxlength: number, field: string) {
    switch (field) {
      case 'address':
        if (this.shippingAddress?.length > maxlength) {
          this.shippingAddress = this.shippingAddress?.substr(0, maxlength);
        }
        break;
      case 'patientComments':
        if (this.patientComments?.length > maxlength) {
          this.shippingAddress = this.shippingAddress?.substr(0, maxlength);
        }
        break;
      case 'shippingComments':
        if (this.shippingComments?.length > maxlength) {
          this.shippingComments = this.shippingComments?.substr(0, maxlength);
        }
        break;
      case 'billingComments':
        if (this.billingComments?.length > maxlength) {
          this.billingComments = this.billingComments?.substr(0, maxlength);
        }
        break;
      case 'techComments':
        if (this.techComments?.length > maxlength) {
          this.techComments = this.techComments?.substr(0, maxlength);
        }
        break;
      case 'alertsText':
        if (this.alertsText?.length > maxlength) {
          this.alertsText = this.alertsText?.substr(0, maxlength);
        }
        break;
      default:
        break;
    }
  }

  //To View the Latest Verification
  openLastVerification(element) {
    if (element == 'Primary') {
      const dialogRef = this.dialog.open(
        PatientManualVerificationTabComponent,
        {
          disableClose: true,
          data: {
            patientId: this.patientId,
            verificationId: this.priVerificationId,
            policyId: this.priPolicyId,
            savehidden: true,
            isViewVerification: true,
          },
        }
      );
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result == 'Saved') {
          this.dialog.closeAll();
        }

        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.dialog.closeAll();
      });
      this.subscription$.push(closeDialog);
    } else if (element == 'secondary') {
      const dialogRef = this.dialog.open(
        PatientManualVerificationTabComponent,
        {
          disableClose: true,
          data: {
            patientId: this.patientId,
            policyId: this.secPolicyId,
            verificationId: this.secVerificationId,
            savehidden: true,
            isViewVerification: true,
          },
        }
      );
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result == 'Saved') {
          this.dialog.closeAll();
        }

        result !== '' &&
          result !== null &&
          result !== undefined &&
          this.dialog.closeAll();
      });
      this.subscription$.push(closeDialog);
    }
  }

  //Method To Search the Products in Dropdown
  filterProducts(): void {
    const searchValue = this.searchControl.value.toLowerCase();
    this.filteredOptions = this.ltproducts.filter((option) =>
      option.productName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  //Method To Search the serial No in Dropdown
  filterSerialNo(): void {
    const searchValue = this.searchSerialControl.value.toLowerCase();
    this.filteredSerialNo = this.lstSerialNo.filter((option) =>
      option.lotSerialNo.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  //Method to filter the Size
  filterSize(): void {
    const searchValue = this.searchSize?.value?.toLowerCase() ?? '';
    this.filteredSize = this.lstMasks?.filter((option) =>
      option.description?.toLowerCase().includes(searchValue?.toLowerCase())
    );
  }

  onKeyPress(event: KeyboardEvent) {
    const isNumber = /[0-9]/.test(event.key);
    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }

  //#region PatientDetails

  //To open the Patient Details in the Popup
  getPatientDetails() {
    const dialogRef = this.dialog.open(PatientDetailsComponent, {
      data: {
        patientId: this.patientId,
      },
      height: '100%',
      width: '100%',
    });

    dialogRef.afterClosed().subscribe(() => {});
  }
  //#endregion

  //#region Authorization
  viewAuthorization() {
    const dialogRef = this.dialog.open(PatientAuthSaveUpdateFormComponent);
    const componentInstance = dialogRef.componentInstance;
    componentInstance.patientId = this.patientId;
    componentInstance.verificationId = this.priVerificationId;
    componentInstance.docId = this.faxId;
    componentInstance.faxId = this.defaultFaxId;
    componentInstance.isView = false;
    componentInstance.isSupplies = true;
    componentInstance.authorizationId = this.orderForm.value.drpAuthorization; //this.patientDetails.authorizationId;
    componentInstance.chartId = this.chartId;
  }
  //#endregion

  //#region Delivery Address
  //Country Dropdown
  getCountryDropdown() {
    this.drpCountries = [];
    this.country = defaultCountry.US;
    const getCountrySubscription = this.regionDropdownService
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .pipe(
        map((response) => {
          this.drpCountries = response?.items ?? [];

          this.phoneCode =
            response?.items?.filter(
              (x) => x.countryShortName === defaultCountry.US
            )?.[0]?.countryPhoneCode ?? '';
          return response;
        }),
        tap((x) => {
          this.country = defaultCountry.US;

          this.orderForm?.patchValue(
            { txtCountry: defaultCountry.US },
            { emitEvent: true }
          );
        }),
        map((x) => {
          this.onChangeState(defaultCountry.US);
        })
      )
      .subscribe(
        (response) => {
          this.filteredCountries = this.orderForm
            .get('txtCountryFilter')
            .valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCountries.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(getCountrySubscription);
  }
  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.orderForm.patchValue({
      txtState: null,
      txtCity: null,
    });
    this.drpStates = [];
    this.drpCities = [];
    value !== null &&
      value !== undefined &&
      this.regionDropdownService
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response?.items ?? [];
            this.phoneCode = response?.items[0]?.countryPhoneCode;
            this.filteredStates = this.orderForm
              ?.get('txtStateFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    // return this.drpStates.length !== 0;
    return this.drpStates && this.drpStates?.length !== 0;
  }
  //On Change City Dropdown
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.orderForm.patchValue({
      txtCity: null,
    });
    this.drpCities = [];
    state !== null &&
      state !== undefined &&
      this.regionDropdownService
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response?.items;

            this.filteredCities = this.orderForm
              .get('txtCityFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return this.drpCities.length !== 0;
  }
  //Clear State dropdown
  clearState() {
    this.State = '';
    this.orderForm.patchValue({ txtState: '' });
  }
  //Clear City dropdown
  clearCity() {
    this.City = '';
    this.orderForm.patchValue({ txtCity: '' });
  }
  //Load the Patient Billing Address
  loadBillingAddress(e: MatCheckboxChange) {
    if (e.checked) {
      const billingAddressDetails = this.mmOrderService
        .getPatientBillingAddressDetailsByPatientId(this.patientId)
        .subscribe(
          (response) => {
            let addressDetails: any = {
              id: response?.addressId ?? defaultGuid,
              nameLine: response?.nameLine ?? '',
              addressType: response?.addressType ?? AddressType.Billing,
              description: response?.description ?? '',
              address: response?.address,
              country: response?.country,
              state: response?.state,
              city: response?.city,
              postalCode: response?.postalCode ?? '',
              homePhoneNo: response?.homePhoneNo ?? '',
              emailId: response?.emailId ?? '',
              mobile: response?.mobile ?? '',
            };
            this.loadAddressDetails(addressDetails);
            this.verifyAddress();
          },
          (err) => {
            this.toastr.error('Something Went Wrong!');
          }
        );

      this.subscription$.push(billingAddressDetails);
    } else if (!e.checked) {
      this.addressId = defaultGuid;
      this.orderForm.patchValue({
        chkVerified: false,
        txtNameLine: '',
        txtDescription: '',
        txtAddress: '',
        txtCountry: '',
        txtState: '',
        txtCity: '',
        txtPostalCode: '',
        txtHomeNo: '',
        txtEmail: '',
        txtCellNo: '',
      });
    }
  }
  isAddressEdit: boolean = false;
  editDeliveryAddress() {
    if (!this.isAddressEdit) {
      this.isAddressEdit = true;
      this.orderForm.get('txtAddress').enable();
      this.orderForm.get('txtCountry').enable();
      this.orderForm.get('txtState').enable();
      this.orderForm.get('txtCity').enable();
      this.orderForm.get('txtPostalCode').enable();
      this.orderForm.get('txtHomeNo').enable();
      this.orderForm.get('txtEmail').enable();
      this.orderForm.get('txtCellNo').enable();
      this.addressChanges();
    } else {
      const chkIsNewCity =
        this.drpCities?.filter(
          (x) => x?.cityName === this.orderForm.value?.txtCity
        )?.[0]?.cityId ?? null;
      let address: PatientAddressDTO = {
        patientId: this.patientId,
        addressType:
          this.addressDetails?.addressType === AddressType.Billing
            ? this.addressDetails?.addressType
            : AddressType.Delivery,
        address: this.orderForm.value.txtAddress.trim(),
        city: this.orderForm.value.txtCity,
        state: this.orderForm.value.txtState,
        country: this.orderForm.value.txtCountry,
        postalCode: this.orderForm.value.txtPostalCode.trim(),
        emailId: this.orderForm.value.txtEmail.toLowerCase().trim(),
        phone: this.orderForm.value.txtHomeNo,
        mobile: this.orderForm.value.txtCellNo,
        isNewCity: chkIsNewCity == 1 ? true : false,
        // nameLine: this.orderForm.value.txtNameLine.trim(),
        // description: this.orderForm.value.txtDescription.trim(),
      };

      if (this.addressChanged) {
        this.addressService
          .createAddressForOrder(address, this.orderId)
          .subscribe(
            (response) => {
              this.orderForm.get('txtAddress').disable();
              this.orderForm.get('txtCountry').disable();
              this.orderForm.get('txtState').disable();
              this.orderForm.get('txtCity').disable();
              this.orderForm.get('txtPostalCode').disable();
              this.orderForm.get('txtHomeNo').disable();
              this.orderForm.get('txtEmail').disable();
              this.orderForm.get('txtCellNo').disable();

              this.isAddressEdit = false;
              this.addressId = response.id;
              this.addressChanged = false;
              this.orderForm.markAsDirty();
              var lstSavedCity = this.drpCities?.filter(
                (x) =>
                  x?.cityName === this.orderForm?.get('txtCity')?.value?.trim()
              );
              if (lstSavedCity.length > 0) {
                lstSavedCity.forEach((element) => {
                  element.cityId = null;
                });
              }
              this.toastr.success('Shipping Address Added Successfully!');
            },
            (err) => {
              // this.toastr.error(err?.error?.error?.message);
              Swal.fire({
                icon: 'info',
                text: err?.error?.error?.message,
              });
            }
          );
      } else {
        this.isAddressEdit = false;

        this.orderForm.get('txtAddress').disable();
        this.orderForm.get('txtCountry').disable();
        this.orderForm.get('txtState').disable();
        this.orderForm.get('txtCity').disable();
        this.orderForm.get('txtPostalCode').disable();
        this.orderForm.get('txtHomeNo').disable();
        this.orderForm.get('txtEmail').disable();
        this.orderForm.get('txtCellNo').disable();

        this.orderForm.markAsDirty();
        this.addressChanged = false;
        var lstSavedCity = this.drpCities?.filter(
          (x) => x?.cityName === this.orderForm?.get('txtCity')?.value?.trim()
        );
        if (lstSavedCity.length > 0) {
          lstSavedCity.forEach((element) => {
            element.cityId = null;
          });
        }
        this.toastr.success('Shipping Address Updated Successfully!');
      }
    }
  }

  //To get the Address Details by Id
  getAddressDetails(addressId: string) {
    this.addressService.get(addressId).subscribe(
      (response) => {
        this.addressDetails = response;
        this.loadAddressDetails(response);
      },
      (err) => {}
    );
  }

  //To Patch the selected Address Details in the Shipping Address
  loadAddressDetails(addressDetails: PatientAddressDTO) {
    if (addressDetails != null) {
      this.addressId = addressDetails?.id ?? defaultGuid;
      this.country = addressDetails?.country;
      this.State = addressDetails?.state;
      (this.State != null || this.State != undefined) &&
        this.onChangeCity(this.State, this.country);

      (this.State != null || this.State != undefined) &&
        this.onChangeState(addressDetails?.country);

      this.orderForm.patchValue({
        chkVerified: addressDetails?.isGoogleValidated ?? false,
        txtNameLine: addressDetails?.nameLine ?? '',
        txtDescription: addressDetails?.description ?? '',
        txtAddress: addressDetails?.address ?? '',
        txtCountry: addressDetails?.country,
        txtState: addressDetails?.state,
        txtCity: addressDetails?.city,
        txtPostalCode: addressDetails?.postalCode ?? '',
        txtHomeNo: addressDetails?.homePhoneNo ?? '',
        txtEmail: addressDetails?.emailId ?? '',
        txtCellNo: addressDetails?.mobile ?? '',
      });
      if (addressDetails?.isGoogleValidated == false) {
        this.toastr.warning('Shipping Address is Not Verified', '', {
          timeOut: 5000,
        });
      }
      // (this.orderId === defaultGuid ||
      //   this.orderId === '' ||
      //   this.orderId === undefined) &&
      //   this.verifyAddress();
    }
  }

  //To View the Address List in Popup
  viewAddressList() {
    const dialogRef = this.dialog.open(PatientAddressListComponent, {
      data: {
        patientId: this.patientId,
        chartId: this.chartId,
      },
      // height: '50%',
      width: '100%',
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(() => {});
  }
  //Method to verify address using google api
  verifyAddress() {
    let deliveryAddress: PatientAddressValidationDTO = {
      address_line1: this.orderForm?.get('txtAddress')?.value?.trim() ?? '',
      city: this.orderForm?.get('txtCity')?.value?.trim() ?? '',
      state_province: this.orderForm?.get('txtState')?.value?.trim() ?? '',
      phone: this.orderForm?.get('txtCellNo')?.value?.trim() ?? '',
      postal_code: this.orderForm?.get('txtPostalCode')?.value?.trim() ?? '',
    };

    const addressVerify = this.mmOrderService
      .checkAddressValidation_GoogleV1ByAddressIdAndInput(
        this.addressId,
        deliveryAddress
      )
      .subscribe(
        (response) => {
          response?.isValid != null &&
            this.orderForm.patchValue({
              chkVerified: response?.isValid,
            });
          this.orderForm.get('chkVerified').markAsTouched();
          this.orderForm.markAsDirty();
          // Update the form's validity based on the new values
          this.orderForm.updateValueAndValidity();

          response?.isValid
            ? this.toastr.success('Address Verified Successfully!')
            : this.toastr.warning('Invalid Address!');
        },
        (err) => {
          this.toastr.warning('Invalid Address!');
        }
      );
    this.subscription$.push(addressVerify);
  }

  //#endregion

  removeProduct(productId: string, sortNumber: any) {

    if (productId != '') {
      this.itemsList = this.itemsList.filter((a) => a.mmProductId != productId);
      this.isEstimateDueAmount = this.isEstimateDueAmount.filter(
        (a) => a.mmProductId != productId
      );
      this.calculateTotals();
      this.setItemTableHeight();
      this.reloadDatatable();
      this.productId =
        this.itemsList.filter((a) => a.mmProductId != '').length > 0
          ? this.productId
          : '';
      this.toastr.success('Product Removed');
    } else if (
      productId === '' &&
      this.itemsList.filter((a) => a.mmProductId == '').length > 1
    ) {
      this.itemsList = this.itemsList.filter((a) => a.sortNumber != sortNumber);
      this.isEstimateDueAmount = this.isEstimateDueAmount.filter(
        (a) => a.mmProductId != productId
      );
      this.calculateTotals();
      this.setItemTableHeight();
      this.reloadDatatable();
      this.productId =
        this.itemsList.filter((a) => a.mmProductId != '').length > 0
          ? this.productId
          : '';
      this.toastr.success('Product Removed');
    } else {
      this.toastr.error('Cannot Remove Empty Row');
    }
  }

  //#region Calculations
  //Method to calculate the Accessory sum from Items Table
  calculateAccessorySum() {
    this.totalAccessorySum = this.itemsList.reduce(
      (sum, item) => sum + parseFloat(item.accessory || 0),
      0
    );
    this.orderDetailsForm.patchValue({
      txtTotalAccessory: this.totalAccessorySum.toFixed(2),
    });
  }

  // Method to calculate the Allowable sum from Items Table
  calculateAllowableSum() {
    this.totalAllowableSum = this.itemsList.reduce(
      (sum, item) => sum + parseFloat(item.allowable || 0),
      0
    );
    this.orderDetailsForm.patchValue({
      txtEstimatedAllowable: this.totalAllowableSum.toFixed(2),
    });
  }

  // Method to calculate the poits sum from Items Table
  calculatePointsSum() {
    this.totalPointSum = this.itemsList.reduce(
      (sum, item) => sum + parseFloat(item.points_Cal || 0),
      0
    );
    this.orderDetailsForm.patchValue({
      txtPoints: this.totalPointSum.toFixed(2),
    });
  }
  calculateEstimatedCoPay(estDueAmount, deductible, percentage): string {
    return (
      (Math.sign(estDueAmount - deductible) == 1
        ? estDueAmount - deductible
        : 0) *
      (percentage / 100)
    ).toFixed(2);
  }
  //Sum Method
  calculateTotals() {
    this.calculateAccessorySum();
    this.calculateAllowableSum();
    this.calculatePointsSum();
    this.estimateDueAmountCalculation();
  }
  //#endregion

  //#region ExistingOrders
  //To load the Existing Order Details
  loadExistingOrderDetails() {
    this.loadOrderDetails(this.orderId);
  }

  //To load the Order Details
  loadOrderDetails(orderId: string) {
    this.loaderService.showLoader();
    this.mmOrderService.getOrderData(orderId).subscribe(
      (response) => {
        this.documentFaxId = response?.faxDocumentId;
        this.rentalAllowable = response?.rentalAllowable || 0;
        this.nonRentalAllowable = response?.nonRentalAllowable || 0;
        this.orderDetails = response;
        this.fillOrderDetails(response);
        this.loaderService.hideLoader();
      },
      (err) => {
        this.loaderService.hideLoader();
      }
    );
  }

  fillOrderDetails(response: any) {
    this.loaderService.showLoader();
    //this.getAddressDetails(response.addressId);
    /*ChartId Dropdowns*/
    // this.isCashPayed = response.isCashPayed;
    let chartDDValues: PatientChartNoDTO = {
      patientId: response?.patientId,
      ddValue: response?.chartDDValue,
    };
    this.lstChartNos = [];
    this.lstChartNos.push(chartDDValues);
    this.orderForm.patchValue({
      drpChartId: response?.patientId ?? '',
    });
    this.isexchange = response?.exchanged;
    this.isMailSent = response?.isMailSent;
    /* Address Details */
    let addressDetails: PatientAddressDTO = response?.addressDTO;

    addressDetails
      ? (addressDetails.isGoogleValidated =
          response?.verifiedPatientInfo == 1 ? true : false)
      : false;
    this.loadAddressDetails(addressDetails);
    /*Right Side Card Details*/
    this.orderDetailsForm.patchValue({
      /*Summary Details*/
      txtOrderNo: response.mmRefereceOrderId,
      txtStatus: response.orderStatusName,
      txtFaxID: response?.faxId,
      txtPoints: response?.orderPointsCAL,
      // txtAlerts:
      /*Order Info*/
      txtDeductible: response?.deductible,
      txtCopay: response?.copay,
      txtEstimatedAllowable:
        response?.estAllowable ?? 0 /*sum of item  allowable*/,
      txtEstimatedCopay: response?.estCopay ?? 0,
      txtTotalAccessory:
        response?.totalAccessory ?? 0 /*sum of item accessory*/,
      txtShippingFee: response?.shippingFee?.toFixed(2),
      txtNetTotal: response?.orderAmount?.toFixed(2),
      /*Payment Info*/
      txtPastDueBalance: response?.pastDueBalance?.toFixed(2),
      txtEstimateAmountDue: response?.estDueAmount?.toFixed(2),
      txtBalance: response?.estDueAmount?.toFixed(2), //response?.totalBalance?.toFixed(2),
    });

    /*Page Content*/
    this.orderForm.patchValue({
      /* Patient Details */
      txtBirthdate: response?.dateOfBirth,
      txtLastName: response?.lastName,
      txtFirstName: response?.firstName,
      txtMiddleName: response?.middleName,
      /* Insurance Details */
      // chkVerified: response?.verifiedPatientInfo == 1 ? true : false,
      // txtPrimaryInsurance: response?.policyName,
      txtSecondaryInsurance: response?.secpolicyName,
      txtChecklist: response?.medicareChecklistName,
      //txtChecklist:'',
      //txtAuthorization:'',
      drpAuthorization: response?.authorizationId,
      txtRXDate: response?.rxDate,
      txtOrderDate:
        this.isCopyOrder === 'true'
          ? this.ctDateNow()
          : this.commonService.getCSTDateTime(response?.orderDate),
      txtDOS:
        this.isCopyOrder === 'true'
          ? this.ctDateNow()
          : this.commonService.getCSTDateTime(response?.dos),
      chkCashOrder: response?.cashOrder,
      txtPatientComments: response?.commentsToPatient,
      txtShippingComments: response?.commentsToShipping,
      txtBillingComments: response?.commentsToBilling,
      /*CSR Details*/
      drpCSR: response?.csRid,
      drpLocation: response?.locationId,
      chkPAPTherapy: response?.paPtherapyUsedAndBeneficial == 1 ? true : false,
      chkRental: response?.isRental,
      drpShippingMethod: response?.shippingMethodId,
      drpProvider:
        response?.referralSourceId == defaultGuid
          ? null
          : response?.referralSourceId,
      drpPracticeManagement: response?.referralPracticeId,
      txtPreferredBrand: response?.brandName,
      txtTechComments: response?.commentsToTech,
      txtmoths: response?.month,
    });

    this.oldRentalMonth = response?.month || 0;
    this.savedOrderDate = this.orderForm.get('txtOrderDate').value;
    this.priVerificationId = response.primaryVerificationId ?? defaultGuid;
    this.secVerificationId = response.secondaryVerificationId ?? defaultGuid;
    this.loadPrimaryVerifications(this.priVerificationId);
    this.isDocumentSorted = response?.isSorted ?? false;
    this.defaultFaxId = response?.faxDocumentId ?? defaultGuid;
    this.primaryPolicyId = response?.policyId ?? defaultGuid;
    this.secondaryPolicyId = response?.secpolicyId ?? defaultGuid;
    this.ltRXDocument = response?.rxDocuments ?? [];
    this.faxId = response?.faxDocumentId ?? defaultGuid;
    this.medicareChecklistId = response?.checkListId ?? defaultGuid;
    this.orderStatusId = response?.orderStatus ?? defaultGuid; //Order Status Guid
    this.isExchangedOrder = response?.isExchangedOrder ?? false;
    this.isExchangeOrReturn =
      response.exchanged || response.returned ? true : false;
    this.ltExchangedCategories = response?.ltExchangedCategories ?? [];

    if (this.ltExchangedCategories.length > 0) {
      this.loadProducts();
    }
    response.orderItems = response?.orderItems?.map((data, index) => ({
      sortNumber: index + 1,
      ...data,
    }));

    this.itemCount = response?.orderItems?.length;

    response?.orderItems?.forEach((item, index) => {
      item.serialLotNo = item?.serialLotNumber ?? '';
      if (response?.orderItems?.length - 1 == index) {
        this.productId = item.mmProductId;
      }
    });

    // ltSerialLotNos
    // response.orderItems.forEach((item) => {
    //   this.updateQuantity(item, item.thisOrder);
    // });
    this.itemsList = response?.orderItems;
    this.CreateUpdateMMOrderItemDTO = [];
    this.calculateAccessorySum();
    this.calculateAllowableSum();
    this.checkIsShowSettingsSelected();
    this.rentalCheck();
    //Flag to check the order is exchange or returnable
    this.isOrderExchangeOrReturnable =
      response.orderStatusNo == saleOrderStatus.orderCompleted ||
      response.orderStatusNo == saleOrderStatus.billingProcess
        ? true
        : false;

    //Flag to check if the order is exchangable
    this.isOrderExchangable =
      response.orderStatusNo == saleOrderStatus.orderCompleted ||
      response.orderStatusNo == saleOrderStatus.billingProcess
        ? true
        : false;
    //Flag to check if the order is returnable
    this.isOrderReturnable =
      response.orderStatusNo == saleOrderStatus.orderCompleted ? true : false;

    //Flag to check the order to not edit
    this.isOrderNonEditable =
      response.orderStatusNo === saleOrderStatus.orderCompleted ||
      response.orderStatusNo === saleOrderStatus.billingProcess;

    //Flag to check the order to edit
    this.isOrderEditable =
      response.orderStatusNo === saleOrderStatus.orderStarted ||
      response.orderStatusNo === saleOrderStatus.pendingOrder ||
      response.orderStatusNo === saleOrderStatus.disapprovedByBillingShipping ||
      response.orderStatusNo === null
        ? true
        : false;

    // !this.isOrderEditable  &&  this.orderForm.disable() ;

    //If Order is Editable
    if (this.isOrderEditable && response.isPaymentMailSent !== true) {
      this.isDisableRemoveItem = false;
      this.orderForm.enable();
      this.orderForm.get('txtAddress').disable();
      this.orderForm.get('txtCountry').disable();
      this.orderForm.get('txtState').disable();
      this.orderForm.get('txtCity').disable();
      this.orderForm.get('txtPostalCode').disable();
      this.orderForm.get('txtHomeNo').disable();
      this.orderForm.get('txtEmail').disable();
      this.orderForm.get('txtCellNo').disable();
      //add empty row to the table
      this.itemsList.push(this.addEmptyObject());
      //Load Auth Ids for the Patient
      this.loadAuthIds();
      this.orderForm.patchValue({
        drpAuthorization: response?.authorizationId,
      });
      this.orderForm.markAsDirty();
    } else {
      this.isDisableRemoveItem = true;
      this.rentalCheckBoxWantToDisable = true;
      this.orderForm.disable();
      this.orderForm.value.txtmoths = response?.month;
    }
    this.reloadDatatable();
    if (this.orderForm.get('chkCashOrder').value === true) {
      this.orderForm.get('drpPrimaryInsurance').disable();
      this.orderForm.get('txtSecondaryInsurance').disable();
    }
    this.loaderService.hideLoader();
  }
  //#endregion

  /* #region Exchange */

  //To save the exchange order items and create a new exchange order
  exchangeOrder() {
    const orderId = this.orderId;
    this.loaderService.showLoader();
    const itemIds = this.itemsList
      .filter((a) => a.mmProductId != '' && a.exchange === true)
      .map((b) => b.mmOrderItemId);
    this.mmOrderService
      .exchangeItemByGOrderIdAndGOrderItemId(orderId, itemIds)
      .subscribe(
        (response) => {
          this.orderDetailsForm.reset();
          this.orderForm.reset();
          this.loaderService.hideLoader();
          this.orderId = response?.id ?? defaultGuid;
          this.toastr.success('Exchange Order Created!');
          this.router.navigate(
            ['/orders/saleOrder', this.patientId, this.orderId, 0],
            { queryParamsHandling: 'merge' }
          );
          this.loadExistingOrderDetails();
        },
        (err) => {
          this.loaderService.hideLoader();
          this.toastr.error('Something Went Wrong');
        }
      );
  }

  /* #endregion */

  /* #region Button Validations */
  get saveButtonEnabled(): boolean {
    return !this.isOrderEditable;
  }
  get exchangeButtonEnabled(): boolean {
    return (
      this.isOrderExchangable &&
      !this.isExchangeOrReturn &&
      !this.isexchange &&
      this.itemsList.filter((a) => a.mmProductId != '' && a.exchange === true)
        .length > 0
    );
  }
  get returnButtonEnabled(): boolean {
    return (
      this.isOrderReturnable &&
      !this.isExchangeOrReturn &&
      this.itemsList.filter((a) => a.mmProductId != '' && a.return === true)
        .length > 0
    );
  }
  //To validate the address fields in the form
  get addressValidation(): boolean {
    return (
      this.orderForm.getRawValue().txtAddress === '' ||
      this.orderForm.getRawValue().txtAddress === null ||
      this.orderForm.getRawValue().txtAddress === undefined ||
      this.orderForm.getRawValue().txtCountry === '' ||
      this.orderForm.getRawValue().txtCountry === null ||
      this.orderForm.getRawValue().txtCountry === undefined ||
      this.orderForm.getRawValue().txtState === '' ||
      this.orderForm.getRawValue().txtState === null ||
      this.orderForm.getRawValue().txtState === undefined ||
      this.orderForm.getRawValue().txtCity === '' ||
      this.orderForm.getRawValue().txtCity === null ||
      this.orderForm.getRawValue().txtCity === undefined ||
      this.orderForm.getRawValue().txtPostalCode === '' ||
      this.orderForm.getRawValue().txtPostalCode === null ||
      this.orderForm.getRawValue().txtPostalCode === undefined ||
      this.addressValidationV1
      //   this.orderForm.getRawValue().txtEmail === '' ||
      //   this.orderForm.getRawValue().txtEmail === null ||
      //   this.orderForm.getRawValue().txtEmail === undefined ||
      //   this.orderForm.getRawValue().txtCellNo === '' ||
      //   this.orderForm.getRawValue().txtCellNo === null ||
      //   this.orderForm.getRawValue().txtCellNo === undefined
    );
  }
  get addressValidationV1(): boolean {
    if (this.orderForm.value.chkCashOrder) {
      return (
        this.orderForm.getRawValue().txtEmail === '' ||
        this.orderForm.getRawValue().txtEmail === null ||
        this.orderForm.getRawValue().txtEmail === undefined ||
        this.orderForm.getRawValue().txtCellNo === '' ||
        this.orderForm.getRawValue().txtCellNo === null ||
        this.orderForm.getRawValue().txtCellNo === undefined
      );
    } else {
      return false;
    }
  }
  get addressEditValidation(): boolean {
    return (
      this.addressId != defaultGuid &&
      this.addressId != '' &&
      this.addressId != undefined
    );
  }
  //To Validate the Process Payment Button
  get paymentValidation(): boolean {
    return (
      this.orderId === defaultGuid ||
      this.orderId === undefined ||
      this.orderId === ''
    );
  }
  get itemfieldsValidation(): boolean {
    return !this.itemsList
      .filter((a) => a.mmProductId !== '')
      .every((item) => item.qty > 0 && item.discountPercent <= 100);
  }
  checkDiscount(discount: string): boolean {
    return Number(discount) > 100;
  }
  numbersAndfloatOnly(event: any) {
    const allowedChars = '0123456789.';
    const inputChar = event.key;

    if (event.key === '.' && event.currentTarget.value.includes('.')) {
      // Allow only one decimal point
      event.preventDefault();
    } else if (allowedChars.indexOf(inputChar) === -1) {
      // Prevent input of characters other than numbers and dot
      event.preventDefault();
    }
  }
  //Decimal Paste Validation
  onDiscountPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData('text');
    const isNumber = /^\d*(\.\d+)?$/.test(pastedData); //Should contain only whole numbers

    if (!isNumber) {
      event.preventDefault(); // Prevent the input if the key is not a number
    }
  }
  /* #endregion*/

  /* #region Paypal Payment  */
  openPaymentOptions() {
    let data = this.itemsList;
    this.loaderService.showLoader();
    data.filter((data) => {
      if (data.mmProductId !== '' || data.mmProductId !== data.mmProductId) {
        let indexData = this.itemsList.findIndex(
          (item) => item.mmProductId == data.mmProductId
        );

        if (indexData !== -1) {
          this.CreateUpdateMMOrderItemDTO[indexData] = {
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
          };
        } else {
          this.CreateUpdateMMOrderItemDTO.push({
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid, //defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
          });
        }
      }
    });
    const saveDetails: CreateUpdateMMOrderDTO = {
      tenantId: this.tenantId,
      authorizationId: this.orderForm.value.drpAuthorization,
      mmRefereceOrderId: this.orderDetailsForm.value.txtOrderNo ?? 0,
      patientId: this.patientId,
      locationId: this.orderForm.value.drpLocation
        ? this.orderForm.value.drpLocation
        : defaultGuid,
      bUsePatientAddress: 0,
      addressId: this.addressId ?? defaultGuid,
      supplies: 0,
      lmn: 0,
      rx_MedForceScanId: 0,
      primaryVerificationId: this.priVerificationId ?? defaultGuid,
      secondaryVerificationId: this.secVerificationId ?? defaultGuid,
      deductible:
        this.orderDetailsForm.value.txtDeductible == null ||
        this.orderDetailsForm.value.txtDeductible === ''
          ? 0
          : this.orderDetailsForm.value.txtDeductible,
      copay:
        this.orderDetailsForm.value.txtCopay == null ||
        this.orderDetailsForm.value.txtCopay === ''
          ? 0
          : this.orderDetailsForm.value.txtCopay,
      commentsToTech: this.orderForm.value.txtTechComments ?? '',
      commentsToShipping: this.orderForm.value.txtShippingComments ?? '',
      commentsToBilling: this.orderForm.value.txtBillingComments ?? '',
      commentsToPatient: this.orderForm.value.txtPatientComments ?? '',
      newSetup: 0,
      csRid: this.orderForm.value.drpCSR || defaultGuid,
      referralPracticeId: this.orderForm.value.drpPracticeManagement
        ? this.orderForm.value.drpPracticeManagement
        : defaultGuid,
      referralSourceId: this.orderForm.value.drpProvider
        ? this.orderForm.value.drpProvider
        : defaultGuid,
      shippingMethodId: this.orderForm.value.drpShippingMethod
        ? this.orderForm.value.drpShippingMethod
        : defaultGuid,
      shippingFee: this.orderDetailsForm.value.txtShippingFee ?? 0,
      paPtherapyUsedAndBeneficial: 0,
      previousOrderStatus: defaultGuid,
      cashOrder:
        this.orderForm.value.chkCashOrder == ''
          ? false
          : this.orderForm.value.chkCashOrder ?? false,
      faxId: this.defaultFaxId ?? '',
      // faxId: defaultGuid,
      defaultSaleOrderId: '',
      orderAmount: Number(this.orderDetailsForm.value.txtNetTotal) ?? 0,
      estDueAmount: this.orderDetailsForm.value.txtEstimateAmountDue ?? 0,
      estCopay: Number(this.orderDetailsForm.value.txtEstimatedCopay) ?? 0,
      estAllowable:
        Number(this.orderDetailsForm.value.txtEstimatedAllowable) ?? 0,
      totalAccessory:
        Number(this.orderDetailsForm.value.txtTotalAccessory) ?? 0,
      checkListId: this.medicareChecklistId
        ? this.medicareChecklistId
        : defaultGuid,
      preCertId: defaultGuid,
      rxDate:
        this.orderForm.value.txtRXDate !== null &&
        this.orderForm.value.txtRXDate !== ''
          ? this.datepipe.transform(
              this.orderForm.value.txtRXDate,
              'yyyy-MM-dd'
            )
          : null,
      lockedBy: defaultGuid,
      lockedOn: new Date().toLocalISOString(),
      lockedBySPID: 0,
      modifiedBy: defaultGuid,
      modifiedOn: new Date().toLocalISOString(),
      orderDate:
        this.getFormattedDate(this.orderForm.value.txtOrderDate) ??
        this.getFormattedDate(new Date()),
      // this.commonService.getCSTDateTime(this.orderForm.value.txtOrderDate) ??
      // this.commonService.getCSTDateTime(new Date()),
      // this.getFormattedDate(this.orderForm.value.txtOrderDate) ??
      // new Date().toLocalISOString(),
      dos:
        this.getFormattedDate(this.orderForm.value.txtDOS) ??
        this.getFormattedDate(new Date()),
      // this.commonService.getCSTDateTime(this.orderForm.value.txtDOS) ??
      // this.commonService.getCSTDateTime(new Date()),
      // this.getFormattedDate(this.orderForm.value.txtDOS) ??
      // new Date().toLocalISOString(),
      backOrderDate: new Date().toLocalISOString(),
      orderStatusSince: new Date().toLocalISOString(),
      setupDate: new Date().toLocalISOString(),
      suppliesDate: new Date().toLocalISOString(),
      shippingStatusId: defaultGuid,
      orderCreatedBy: defaultGuid,
      overrideBy: defaultGuid,
      overrideDt: new Date().toLocalISOString(),
      overrideNote: '',
      balOverrideBy: defaultGuid,
      balOverrideDt: new Date().toLocalISOString(),
      orderPointsCAL: this.orderDetailsForm.value.txtPoints ?? 0,
      pastDueBalance: this.isEmpty(
        this.orderDetailsForm.value.txtPastDueBalance
      )
        ? 0
        : this.orderDetailsForm.value.txtPastDueBalance,
      lastPaymentId: defaultGuid,
      paymentsPostedByUserId: defaultGuid,
      paymentsPostedOn: new Date().toLocalISOString(),
      billingApprovalNote: '',
      billingNote: this.orderForm.value.txtBillingComments ?? '',
      billingApprovalStatusId: defaultGuid,
      pendingAtBilling: 0,
      pendingAtBillingApproval: 0,
      orderCreatedOn: new Date().toLocalISOString(),
      functionalityQuNote: 0,
      verifiedPatientInfo: this.orderForm.value.chkVerified == true ? 1 : 0,
      downloadedCompliance: 0,
      mfs_ComplianceImageId: 0,
      ftf_Date: new Date().toLocalISOString(),
      mfs_FTFImageId: 0,
      finalBilling: new Date().toLocalISOString(),
      complianceReleaseChecklistId: 0,
      // orderSentToShipping: new Date().toLocalISOString(),
      remoteVideoCall: 0,
      curbSidePU: 0,
      orderStatus: this.orderStatusId ?? defaultGuid,
      status: 0,
      rental: this.orderForm.value.chkRental
        ? this.orderForm.value.chkRental
        : false,
      months: this.orderForm.value.txtmoths ? this.orderForm.value.txtmoths : 0,
      orderItems: this.CreateUpdateMMOrderItemDTO,
      faxDocumentId: this.faxId,
      isResupply: false,
      isTemplate: 0,
      rentalresupplySaleOrder: null,
      rxDocuments: this.ltRXDocument.length === 0 ? [] : this.ltRXDocument,
    };

    //Save
    if (
      this.orderId === null ||
      this.orderId === '' ||
      this.orderId === defaultGuid
    ) {
      const saveData = this.mmOrderService.createV1(saveDetails).subscribe(
        (response) => {
          this.orderId = response.id;
          this.loaderService.hideLoader();
          let orderData = {
            orderNumber: response?.mmRefereceOrderId ?? -1,
            charge: this.orderDetailsForm?.value?.txtEstimateAmountDue ?? 0,
            totalDue: this.orderDetailsForm?.value?.txtBalance ?? 0,
          };
          const dialogRef = this.dialog.open(PaymentMethodsModalComponent, {
            data: {
              patientId: this.patientId,
              orderId: this.orderId,
              orderData: orderData,
            },
            disableClose: true,
            // You can pass data to your dialog if needed
          });
          this.removeMinDateValidator();
          this.orderForm?.updateValueAndValidity();
          // Handle the dialog result if needed
          dialogRef.afterClosed().subscribe((result) => {
            const control = this.orderForm.get('txtOrderDate');
            this.removeMinDateValidator();
            this.orderForm?.updateValueAndValidity();
            if (result) {
              this.orderForm?.controls.chkCashOrder.disable();
              this.isDisableRemoveItem = true;
              this.rentalCheckBoxWantToDisable = true;
              this.orderForm.disable();
              this.orderForm.value.txtmoths = response?.month;
            }
          });
          this.loadExistingOrderDetails();
        },
        (err) => {
          this.loaderService.hideLoader();
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
    //Update
    else {
      this.mmOrderService.update(this.orderId, saveDetails).subscribe(
        (response) => {
          this.loadFrequentBoughtItems();
          this.toastr.success('Order Updated Successfully', 'Success');
          this.loaderService.hideLoader();
          this.ltRXDocument = [];
          this.loadExistingOrderDetails();
          let orderData = {
            orderNumber: response?.mmRefereceOrderId ?? -1, //this.orderDetailsForm?.value?.txtOrderNo ?? -1,
            charge: this.orderDetailsForm?.value?.txtEstimateAmountDue ?? 0,
            totalDue: this.orderDetailsForm?.value?.txtBalance ?? 0,
          };

          const dialogRef = this.dialog.open(PaymentMethodsModalComponent, {
            data: {
              patientId: this.patientId,
              orderId: this.orderId,
              orderData: orderData,
            },
            disableClose: true,
            // You can pass data to your dialog if needed
          });
          // Handle the dialog result if needed
          dialogRef.afterClosed().subscribe((result) => {
            const control = this.orderForm.get('txtOrderDate');
            this.removeMinDateValidator();
            this.orderForm?.updateValueAndValidity();
            if (result) {
              this.orderForm?.controls.chkCashOrder.disable();
              this.isDisableRemoveItem = true;
              this.rentalCheckBoxWantToDisable = true;
              this.orderForm.disable();
              this.orderForm.value.txtmoths = response?.month;
            }
          });
        },
        (err) => {
          this.loaderService.hideLoader();
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }
  /* #endregion */

  /* #region Override & Shipping */
  //To open the modal for override & move to shipping
  overrideShippingModal(): void {
    this.isSaveBtnDisabled = true;
    this.loaderService.showLoader();
    let data = this.itemsList;

    data.filter((data) => {
      if (data.mmProductId !== '' || data.mmProductId !== data.mmProductId) {
        let indexData = this.itemsList.findIndex(
          (item) => item.mmProductId == data.mmProductId
        );

        if (indexData !== -1) {
          this.CreateUpdateMMOrderItemDTO[indexData] = {
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
          };
        } else {
          this.CreateUpdateMMOrderItemDTO.push({
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid, //defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
          });
        }
      }
    });

    const saveDetails: CreateUpdateMMOrderDTO = {
      tenantId: this.tenantId,
      authorizationId: this.orderForm.value.drpAuthorization,
      mmRefereceOrderId: this.orderDetailsForm.value.txtOrderNo ?? 0,
      patientId: this.patientId,
      locationId: this.orderForm.value.drpLocation
        ? this.orderForm.value.drpLocation
        : defaultGuid,
      bUsePatientAddress: 0,
      addressId: this.addressId ?? defaultGuid,
      supplies: 0,
      lmn: 0,
      rx_MedForceScanId: 0,
      primaryVerificationId: this.priVerificationId ?? defaultGuid,
      // secondaryVerificationId: this.secVerificationId ?? defaultGuid,
      secondaryVerificationId: this.secVerificationId ?? defaultGuid,
      deductible:
        this.orderDetailsForm.value.txtDeductible == null ||
        this.orderDetailsForm.value.txtDeductible === ''
          ? 0
          : this.orderDetailsForm.value.txtDeductible,
      copay:
        this.orderDetailsForm.value.txtCopay == null ||
        this.orderDetailsForm.value.txtCopay === ''
          ? 0
          : this.orderDetailsForm.value.txtCopay,
      commentsToTech: this.orderForm.value.txtTechComments ?? '',
      commentsToShipping: this.orderForm.value.txtShippingComments ?? '',
      commentsToBilling: this.orderForm.value.txtBillingComments ?? '',
      commentsToPatient: this.orderForm.value.txtPatientComments ?? '',
      newSetup: 0,
      csRid: this.orderForm.value.drpCSR ?? defaultGuid,
      referralPracticeId: this.orderForm.value.drpPracticeManagement
        ? this.orderForm.value.drpPracticeManagement
        : defaultGuid,
      referralSourceId: this.orderForm.value.drpProvider
        ? this.orderForm.value.drpProvider
        : defaultGuid,
      shippingMethodId: this.orderForm.value.drpShippingMethod
        ? this.orderForm.value.drpShippingMethod
        : defaultGuid,
      shippingFee: this.orderDetailsForm.value.txtShippingFee ?? 0,
      paPtherapyUsedAndBeneficial: 0,
      previousOrderStatus: defaultGuid,
      cashOrder:
        this.orderForm.value.chkCashOrder == ''
          ? false
          : this.orderForm.value.chkCashOrder ?? false,
      faxId: this.defaultFaxId ?? '',
      // faxId: defaultGuid,
      defaultSaleOrderId: '',
      orderAmount: Number(this.orderDetailsForm.value.txtNetTotal) ?? 0,
      estDueAmount: this.orderDetailsForm.value.txtEstimateAmountDue ?? 0,
      estCopay: Number(this.orderDetailsForm.value.txtEstimatedCopay) ?? 0,
      estAllowable:
        Number(this.orderDetailsForm.value.txtEstimatedAllowable) ?? 0,
      totalAccessory:
        Number(this.orderDetailsForm.value.txtTotalAccessory) ?? 0,
      checkListId: this.medicareChecklistId
        ? this.medicareChecklistId
        : defaultGuid,
      preCertId: defaultGuid,
      rxDate:
        this.orderForm.value.txtRXDate !== null &&
        this.orderForm.value.txtRXDate !== ''
          ? this.datepipe.transform(
              this.orderForm.value.txtRXDate,
              'yyyy-MM-dd'
            )
          : null,
      lockedBy: defaultGuid,
      lockedOn: new Date().toLocalISOString(),
      lockedBySPID: 0,
      modifiedBy: defaultGuid,
      modifiedOn: new Date().toLocalISOString(),
      orderDate:
        this.getFormattedDate(this.orderForm.value.txtOrderDate) ??
        this.getFormattedDate(new Date()),
      // this.commonService.getCSTDateTime(this.orderForm.value.txtOrderDate) ??
      // this.commonService.getCSTDateTime(new Date()),
      // this.getFormattedDate(this.orderForm.value.txtOrderDate) ??
      // new Date().toLocalISOString(),
      dos:
        this.getFormattedDate(this.orderForm.value.txtDOS) ??
        this.getFormattedDate(new Date()),
      // this.commonService.getCSTDateTime(this.orderForm.value.txtDOS) ??
      // this.commonService.getCSTDateTime(new Date()),
      // this.getFormattedDate(this.orderForm.value.txtDOS) ??
      // new Date().toLocalISOString(),
      backOrderDate: new Date().toLocalISOString(),
      orderStatusSince: new Date().toLocalISOString(),
      setupDate: new Date().toLocalISOString(),
      suppliesDate: new Date().toLocalISOString(),
      shippingStatusId: defaultGuid,
      orderCreatedBy: defaultGuid,
      overrideBy: defaultGuid,
      overrideDt: new Date().toLocalISOString(),
      overrideNote: '',
      balOverrideBy: defaultGuid,
      balOverrideDt: new Date().toLocalISOString(),
      orderPointsCAL: this.orderDetailsForm.value.txtPoints ?? 0, // this.orderDetailsForm.getRawValue().txtPoints, // this.orderDetailsForm.value.txtPoints ?? 0,
      pastDueBalance: this.isEmpty(
        this.orderDetailsForm.value.txtPastDueBalance
      )
        ? 0
        : this.orderDetailsForm.value.txtPastDueBalance,
      lastPaymentId: defaultGuid,
      paymentsPostedByUserId: defaultGuid,
      paymentsPostedOn: new Date().toLocalISOString(),
      billingApprovalNote: '',
      billingNote: this.orderForm.value.txtBillingComments ?? '',
      billingApprovalStatusId: defaultGuid,
      pendingAtBilling: 0,
      pendingAtBillingApproval: 0,
      orderCreatedOn: new Date().toLocalISOString(),
      functionalityQuNote: 0,
      verifiedPatientInfo: this.orderForm.value.chkVerified == true ? 1 : 0,
      downloadedCompliance: 0,
      mfs_ComplianceImageId: 0,
      ftf_Date: new Date().toLocalISOString(),
      mfs_FTFImageId: 0,
      finalBilling: new Date().toLocalISOString(),
      complianceReleaseChecklistId: 0,
      // orderSentToShipping: new Date().toUTCString(),
      remoteVideoCall: 0,
      curbSidePU: 0,
      orderStatus: this.orderStatusId ?? defaultGuid,
      status: 0,
      rental: this.orderForm.value.chkRental
        ? this.orderForm.value.chkRental
        : false,
      months: this.orderForm.value.txtmoths ? this.orderForm.value.txtmoths : 0,
      orderItems: this.CreateUpdateMMOrderItemDTO,
      faxDocumentId: this.faxId,
      isResupply: false,
      isTemplate: 0,
      rentalresupplySaleOrder: null,
      rxDocuments: this.ltRXDocument.length === 0 ? [] : this.ltRXDocument,
      isOverride: false,
      isToBilling: false,
      rentalAllowable: this.rentalAllowable || 0,
      nonRentalAllowable: this.nonRentalAllowable || 0,
    };

    if (
      (this.isEmpty(this.orderDetailsForm.value.txtPastDueBalance)
        ? 0
        : this.orderDetailsForm.value.txtPastDueBalance >= 5) &&
      !this.isExchangedOrder
    ) {
      this.loaderService.hideLoader();
      const dialogRef = this.dialog.open(SaleOrderOverrideModalComponent, {
        width: '750px',
        data: {
          orderId: this.orderId,
          patientId: this.patientId,
          orderDto: saveDetails,
          overrideType: this.isEmpty(
            this.orderDetailsForm.value.txtPastDueBalance
          )
            ? 0
            : this.orderDetailsForm.value.txtPastDueBalance,
        },
        disableClose: true,
      });
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.isSaveBtnDisabled = true;
        } else {
          this.isSaveBtnDisabled = false;
        }
      });
      this.subscription$.push(closeDialog);
    } else {
      if (
        this.orderId != null &&
        this.orderId != '' &&
        this.orderId != undefined &&
        this.orderId != defaultGuid
      ) {
        this.mmOrderService
          .updateOrderOverrideByIdAndInputData(this.orderId, saveDetails)
          .subscribe(
            (response) => {

              this.toastr.success('Order moved to Shipping Approval');
              this.patientId = response?.patientId;
              this.orderId = response?.id;
              this.loadExistingOrderDetails();
              this.loaderService.hideLoader();
            },
            (err) => {
              this.loaderService.hideLoader();
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
              this.isSaveBtnDisabled = false;
            }
          );
      } else {
        this.mmOrderService.createOverrideOrderByInput(saveDetails).subscribe(
          (response) => {
            this.toastr.success('Order Moved to Shipping Approval!');
            this.patientId = response?.patientId;
            this.orderId = response?.id;
            this.loadExistingOrderDetails();
            this.loaderService.hideLoader();
          },
          (err) => {
            this.loaderService.hideLoader();
            this.isSaveBtnDisabled = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    }

    // const dialogRef = this.dialog.open(SaleOrderOverrideModalComponent, {
    //   width: '750px',
    //   data: {
    //     orderId: this.orderId,
    //     patientId: this.patientId,
    //     orderDto: saveDetails,
    //   },
    //   disableClose: true,
    //   // You can pass data to your dialog if needed
    // });

    // const closeDialog = dialogRef.afterClosed().subscribe((result) => {
    //   // dialogRef?.close(result);
    //   this.loadFrequentBoughtItems();
    // });
    // this.subscription$.push(closeDialog);
  }
  /* #endregion*/

  /*#region Return*/

  //To open the return popup and send the existing order details
  returnOrder() {
    let data = this.itemsList;

    data.filter((data) => {
      if (data.mmProductId !== '' || data.mmProductId !== data.mmProductId) {
        let indexData = this.itemsList.findIndex(
          (item) => item.mmProductId == data.mmProductId
        );

        if (indexData !== -1) {
          this.CreateUpdateMMOrderItemDTO[indexData] = {
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
          };
        } else {
          this.CreateUpdateMMOrderItemDTO.push({
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid, //defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
          });
        }
      }
    });

    const saveDetails: CreateUpdateMMOrderDTO = {
      tenantId: this.tenantId,
      mmRefereceOrderId: this.orderDetailsForm.value.txtOrderNo ?? 0,
      patientId: this.patientId,
      locationId: this.orderForm.value.drpLocation
        ? this.orderForm.value.drpLocation
        : defaultGuid,
      bUsePatientAddress: 0,
      authorizationId: this.orderForm.value.drpAuthorization,
      addressId: this.addressId ?? defaultGuid,
      supplies: 0,
      lmn: 0,
      rx_MedForceScanId: 0,
      primaryVerificationId: this.priVerificationId ?? defaultGuid,
      secondaryVerificationId: this.secVerificationId ?? defaultGuid,
      deductible:
        this.orderDetailsForm.value.txtDeductible == null ||
        this.orderDetailsForm.value.txtDeductible === ''
          ? 0
          : this.orderDetailsForm.value.txtDeductible,
      copay:
        this.orderDetailsForm.value.txtCopay == null ||
        this.orderDetailsForm.value.txtCopay === ''
          ? 0
          : this.orderDetailsForm.value.txtCopay,
      commentsToTech: this.orderForm.value.txtTechComments ?? '',
      commentsToShipping: this.orderForm.value.txtShippingComments ?? '',
      commentsToBilling: this.orderForm.value.txtBillingComments ?? '',
      commentsToPatient: this.orderForm.value.txtPatientComments ?? '',
      newSetup: 0,
      csRid: this.orderForm.value.drpCSR ?? defaultGuid,
      referralPracticeId: this.orderForm.value.drpPracticeManagement
        ? this.orderForm.value.drpPracticeManagement
        : defaultGuid,
      referralSourceId: this.orderForm.value.drpProvider
        ? this.orderForm.value.drpProvider
        : defaultGuid,
      shippingMethodId: this.orderForm.value.drpShippingMethod
        ? this.orderForm.value.drpShippingMethod
        : defaultGuid,
      shippingFee: this.orderDetailsForm.value.txtShippingFee ?? 0,
      paPtherapyUsedAndBeneficial: 0,
      previousOrderStatus: defaultGuid,
      cashOrder:
        this.orderForm.value.chkCashOrder == ''
          ? false
          : this.orderForm.value.chkCashOrder ?? false,

      faxId: this.defaultFaxId ?? '',
      // faxId: defaultGuid,
      defaultSaleOrderId: '',
      orderAmount: Number(this.orderDetailsForm.value.txtNetTotal) ?? 0,
      estDueAmount: this.orderDetailsForm.value.txtEstimateAmountDue ?? 0,
      estCopay: Number(this.orderDetailsForm.value.txtEstimatedCopay) ?? 0,
      estAllowable:
        Number(this.orderDetailsForm.value.txtEstimatedAllowable) ?? 0,
      totalAccessory:
        Number(this.orderDetailsForm.value.txtTotalAccessory) ?? 0,
      checkListId: this.medicareChecklistId
        ? this.medicareChecklistId
        : defaultGuid,
      preCertId: defaultGuid,
      rxDate:
        this.orderForm.value.txtRXDate !== null &&
        this.orderForm.value.txtRXDate !== ''
          ? this.datepipe.transform(
              this.orderForm.value.txtRXDate,
              'yyyy-MM-dd'
            )
          : null,
      lockedBy: defaultGuid,
      lockedOn: new Date().toLocalISOString(),
      lockedBySPID: 0,
      modifiedBy: defaultGuid,
      modifiedOn: new Date().toLocalISOString(),
      orderDate:
        this.getFormattedDate(this.orderForm.value.txtOrderDate) ??
        this.getFormattedDate(new Date()),
      // this.commonService.getCSTDateTime(this.orderForm.value.txtOrderDate) ??
      // this.commonService.getCSTDateTime(new Date()),
      // this.getFormattedDate(this.orderForm.value.txtOrderDate) ??
      // new Date().toLocalISOString(),
      dos:
        this.getFormattedDate(this.orderForm.value.txtDOS) ??
        this.getFormattedDate(new Date()),
      // this.commonService.getCSTDateTime(this.orderForm.value.txtDOS) ??
      // this.commonService.getCSTDateTime(new Date()),
      // this.getFormattedDate(this.orderForm.value.txtDOS) ??
      // new Date().toLocalISOString(),
      backOrderDate: new Date().toLocalISOString(),
      orderStatusSince: new Date().toLocalISOString(),
      setupDate: new Date().toLocalISOString(),
      suppliesDate: new Date().toLocalISOString(),
      shippingStatusId: defaultGuid,
      orderCreatedBy: defaultGuid,
      overrideBy: defaultGuid,
      overrideDt: new Date().toLocalISOString(),
      overrideNote: '',
      balOverrideBy: defaultGuid,
      balOverrideDt: new Date().toLocalISOString(),
      orderPointsCAL: this.orderDetailsForm.value.txtPoints ?? 0,
      pastDueBalance: this.isEmpty(
        this.orderDetailsForm.value.txtPastDueBalance
      )
        ? 0
        : this.orderDetailsForm.value.txtPastDueBalance,
      lastPaymentId: defaultGuid,
      paymentsPostedByUserId: defaultGuid,
      paymentsPostedOn: new Date().toLocalISOString(),
      billingApprovalNote: '',
      billingNote: this.orderForm.value.txtBillingComments ?? '',
      billingApprovalStatusId: defaultGuid,
      pendingAtBilling: 0,
      pendingAtBillingApproval: 0,
      orderCreatedOn: new Date().toLocalISOString(),
      functionalityQuNote: 0,
      verifiedPatientInfo: this.orderForm.value.chkVerified == true ? 1 : 0,
      downloadedCompliance: 0,
      mfs_ComplianceImageId: 0,
      ftf_Date: new Date().toLocalISOString(),
      mfs_FTFImageId: 0,
      finalBilling: new Date().toLocalISOString(),
      complianceReleaseChecklistId: 0,
      // orderSentToShipping: new Date().toLocalISOString(),
      remoteVideoCall: 0,
      curbSidePU: 0,
      orderStatus: this.orderStatusId ?? defaultGuid,
      status: 0,
      rental: this.orderForm.value.chkRental
        ? this.orderForm.value.chkRental
        : false,
      months: this.orderForm.value.txtmoths ? this.orderForm.value.txtmoths : 0,
      orderItems: this.CreateUpdateMMOrderItemDTO,
      faxDocumentId: this.faxId,
      isResupply: false,
      isTemplate: 0,
      rentalresupplySaleOrder: null,
      rxDocuments: this.ltRXDocument.length === 0 ? [] : this.ltRXDocument,
      rentalAllowable: this.rentalAllowable || 0,
      nonRentalAllowable: this.nonRentalAllowable || 0,
    };

    this.dialog.open(SaleOrderReturnModalComponent, {
      width: '750px',
      data: {
        orderId: this.orderId,
        patientId: this.patientId,
        orderDto: saveDetails,
      },
      disableClose: true,
      // You can pass data to your dialog if needed
    });
  }

  /* #endregion */

  /* #region Copy Order */
  //To copy the exisiting order and fill all the existing order fields
  copyOrder(orderId: string) {
    this.loaderService.showLoader();
    if(this.isResupply===true){
      const mmOrderDetails = this.mmOrderService
      .resupplyCopyOrderDetails(orderId)
      .subscribe(
        (response) => {
          // this.orderForm.patchValue({
          //   drpChartId: response.patientId,
          // });
          // this.loadPatientDetails(response.patientId);
          this.orderDetails = response;
          this.fillOrderDetails(response);
          // this.loaderService.hideLoader();
        },
        (err) => {
          this.loaderService.hideLoader();
          this.toastr.error('Something Went Wrong!');
        }
      );
    this.subscription$.push(mmOrderDetails);
    }else{
    const mmOrderDetails = this.mmOrderService
      .copyOrderDetailsV1(orderId)
      .subscribe(
        (response) => {
          // this.orderForm.patchValue({
          //   drpChartId: response.patientId,
          // });
          // this.loadPatientDetails(response.patientId);
          this.orderDetails = response;
          this.fillOrderDetails(response);
          // this.loaderService.hideLoader();
        },
        (err) => {
          this.loaderService.hideLoader();
          this.toastr.error('Something Went Wrong!');
        }
      );
    this.subscription$.push(mmOrderDetails);
    }
  }
  /* #endregion */

  /* #region Rem Requisition */
  remRequisition() {
    let data = this.itemsList;

    data.filter((data) => {
      if (data.mmProductId !== '' || data.mmProductId !== data.mmProductId) {
        let indexData = this.itemsList.findIndex(
          (item) => item.mmProductId == data.mmProductId
        );

        if (indexData !== -1) {
          this.CreateUpdateMMOrderItemDTO[indexData] = {
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
          };
        } else {
          this.CreateUpdateMMOrderItemDTO.push({
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid, //defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
          });
        }
      }
    });

    const saveDetails: CreateUpdateMMOrderDTO = {
      tenantId: this.tenantId,
      mmRefereceOrderId: this.orderDetailsForm.value.txtOrderNo ?? 0,
      authorizationId: this.orderForm.value.drpAuthorization,
      patientId: this.patientId,
      locationId: this.orderForm.value.drpLocation
        ? this.orderForm.value.drpLocation
        : defaultGuid,
      bUsePatientAddress: 0,
      addressId: this.addressId ?? defaultGuid,
      supplies: 0,
      lmn: 0,
      rx_MedForceScanId: 0,
      primaryVerificationId: this.priVerificationId ?? defaultGuid,
      secondaryVerificationId: this.secVerificationId ?? defaultGuid,
      deductible:
        this.orderDetailsForm.value.txtDeductible == null ||
        this.orderDetailsForm.value.txtDeductible === ''
          ? 0
          : this.orderDetailsForm.value.txtDeductible,
      copay:
        this.orderDetailsForm.value.txtCopay == null ||
        this.orderDetailsForm.value.txtCopay === ''
          ? 0
          : this.orderDetailsForm.value.txtCopay,
      commentsToTech: this.orderForm.value.txtTechComments ?? '',
      commentsToShipping: this.orderForm.value.txtShippingComments ?? '',
      commentsToBilling: this.orderForm.value.txtBillingComments ?? '',
      commentsToPatient: this.orderForm.value.txtPatientComments ?? '',
      newSetup: 0,
      csRid: this.orderForm.value.drpCSR ?? defaultGuid,
      referralPracticeId: this.orderForm.value.drpPracticeManagement
        ? this.orderForm.value.drpPracticeManagement
        : defaultGuid,
      referralSourceId: this.orderForm.value.drpProvider
        ? this.orderForm.value.drpProvider
        : defaultGuid,
      shippingMethodId: this.orderForm.value.drpShippingMethod
        ? this.orderForm.value.drpShippingMethod
        : defaultGuid,
      shippingFee: this.orderDetailsForm.value.txtShippingFee ?? 0,
      paPtherapyUsedAndBeneficial: 0,
      previousOrderStatus: defaultGuid,
      cashOrder:
        this.orderForm.value.chkCashOrder == ''
          ? false
          : this.orderForm.value.chkCashOrder ?? false,
      faxId: this.defaultFaxId ?? '',
      // faxId: defaultGuid,
      defaultSaleOrderId: '',
      orderAmount: Number(this.orderDetailsForm.value.txtNetTotal) ?? 0,
      estDueAmount: this.orderDetailsForm.value.txtEstimateAmountDue ?? 0,
      estCopay: Number(this.orderDetailsForm.value.txtEstimatedCopay) ?? 0,
      estAllowable:
        Number(this.orderDetailsForm.value.txtEstimatedAllowable) ?? 0,
      totalAccessory:
        Number(this.orderDetailsForm.value.txtTotalAccessory) ?? 0,
      checkListId: this.medicareChecklistId
        ? this.medicareChecklistId
        : defaultGuid,
      preCertId: defaultGuid,
      rxDate:
        this.orderForm.value.txtRXDate !== null &&
        this.orderForm.value.txtRXDate !== ''
          ? this.datepipe.transform(
              this.orderForm.value.txtRXDate,
              'yyyy-MM-dd'
            )
          : null,
      lockedBy: defaultGuid,
      lockedOn: new Date().toLocalISOString(),
      lockedBySPID: 0,
      modifiedBy: defaultGuid,
      modifiedOn: new Date().toLocalISOString(),
      orderDate:
        this.getFormattedDate(this.orderForm.value.txtOrderDate) ??
        this.getFormattedDate(new Date()),
      // this.commonService.getCSTDateTime(this.orderForm.value.txtOrderDate) ??
      // this.commonService.getCSTDateTime(new Date()),
      // this.getFormattedDate(this.orderForm.value.txtOrderDate) ??
      // new Date().toLocalISOString(),
      dos:
        this.getFormattedDate(this.orderForm.value.txtDOS) ??
        this.getFormattedDate(new Date()),
      // this.commonService.getCSTDateTime(this.orderForm.value.txtDOS) ??
      // this.commonService.getCSTDateTime(new Date()),
      // this.getFormattedDate(this.orderForm.value.txtDOS) ??
      // new Date().toLocalISOString(),
      backOrderDate: new Date().toLocalISOString(),
      orderStatusSince: new Date().toLocalISOString(),
      setupDate: new Date().toLocalISOString(),
      suppliesDate: new Date().toLocalISOString(),
      shippingStatusId: defaultGuid,
      orderCreatedBy: defaultGuid,
      overrideBy: defaultGuid,
      overrideDt: new Date().toLocalISOString(),
      overrideNote: '',
      balOverrideBy: defaultGuid,
      balOverrideDt: new Date().toLocalISOString(),
      orderPointsCAL: this.orderDetailsForm.value.txtPoints ?? 0,
      pastDueBalance: this.isEmpty(
        this.orderDetailsForm.value.txtPastDueBalance
      )
        ? 0
        : this.orderDetailsForm.value.txtPastDueBalance,
      lastPaymentId: defaultGuid,
      paymentsPostedByUserId: defaultGuid,
      paymentsPostedOn: new Date().toLocalISOString(),
      billingApprovalNote: '',
      billingNote: this.orderForm.value.txtBillingComments ?? '',
      billingApprovalStatusId: defaultGuid,
      pendingAtBilling: 0,
      pendingAtBillingApproval: 0,
      orderCreatedOn: new Date().toLocalISOString(),
      functionalityQuNote: 0,
      verifiedPatientInfo: this.orderForm.value.chkVerified == true ? 1 : 0,
      downloadedCompliance: 0,
      mfs_ComplianceImageId: 0,
      ftf_Date: new Date().toLocalISOString(),
      mfs_FTFImageId: 0,
      finalBilling: new Date().toLocalISOString(),
      complianceReleaseChecklistId: 0,
      // orderSentToShipping: new Date().toLocalISOString(),
      remoteVideoCall: 0,
      curbSidePU: 0,
      orderStatus: this.orderStatusId ?? defaultGuid,
      status: 0,
      rental: this.orderForm.value.chkRental
        ? this.orderForm.value.chkRental
        : false,
      months: this.orderForm.value.txtmoths ? this.orderForm.value.txtmoths : 0,
      isRemRequisitionPlaced: true,
      orderItems: this.CreateUpdateMMOrderItemDTO,
      faxDocumentId: this.faxId,
      isResupply: false,
      isTemplate: 0,
      rentalresupplySaleOrder: null,
      rxDocuments: this.ltRXDocument.length === 0 ? [] : this.ltRXDocument,
    };

    const saveData = this.mmOrderService
      .remReqByInputValue(saveDetails)
      .subscribe(
        (response) => {
          this.toastr.success('Requisition Placed', 'Success');
          this.router.navigate(['inventory/requisition-viewer']);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          const message = JSON.parse(data?.error);
          Swal.fire({
            icon: 'info',
            text: message?.error?.message,
          });
        }
      );
  }
  /* #endregion */

  /* #region BackToOrder */
  backToOrder() {
    if (this.pageType == '0') {
      this.router.navigate(['patient/order-list']);
    } else if (this.pageType == '1') {
      this.router.navigate(['patient/my-orders']);
    } else if (this.pageType == '2') {
      this.router.navigate(['resupply']);
    }

    //  this.router.navigate(['patient/my-orders']);
  }
  /*#endregion */

  /* #region PRINT TICKET */
  printTicketData: PrintTicketDetails;
  //Method to call the Print Ticket Data Details
  getPrintTicketDetails() {
    const shippingTicketDetails = this.shippingListService
      .orderTicketDetailsV1ByOrderId(this.orderId)
      .subscribe(
        (response) => {
          this.printTicketData = response ?? null;
          this.getPrintTicket(this.printTicketData);
        },
        (err) => {}
      );

    this.subscription$.push(shippingTicketDetails);
  }
  getPrintTicket(printTicketData: PrintTicketDetails) {
    const tbodyStyle = {
      fontSize: 10,
      margin: [0, 2],
    };

    this.printTicketData.productDetailsDTO =
      this.printTicketData?.productDetailsDTO?.map((a) => ({
        ...a,
        style: tbodyStyle,
      }));

    let docDefinition = {
      /* #region HEADER */
      header: function (currentPage, pageCount, pageSize) {
        return {
          columns: [
            // {
            //   image: localStorage.getItem('LogoDataUrl'),
            //   width: 100, // Adjust the width as needed
            //   alignment: 'left', // Adjust the alignment as needed
            // },
            // {
            //   width: '10%',
            //   text:{

            //       text: '844498797',
            //       fontSize: 18,
            //       bold: true,
            //       alignment: 'center',
            //       color: '#000000',

            //   }
            // },
            {
              width: '80%',
              text: [
                { text: '\n' },
                {
                  // margin:[15,0,0,0],
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 16,
                  bold: true,
                  alignment: 'left',
                  color: '#000000',
                },
                {
                  text: '\t\t\t\t\t\t\t\t\t\tConfirm receipt of shipment online at www.monitormedical.com\n\t\t\t\t',
                  fontSize: 10,
                  bold: true,
                  alignment: 'center',
                  color: '#91ab5b',
                },
                {
                  text: '\t\t\t\t\t\tPATIENT CARE DELIVERY TICKET',
                  fontSize: 16,
                  bold: true,
                  alignment: 'center',
                  color: '#800000',
                },
              ],
              margin: [15, 0, 0, 0],
            },
            {
              image: localStorage.getItem('LogoDataUrl'),
              width: 100, // Adjust the width as needed
              // alignment: 'right', // Adjust the alignment as needed
              margin: [0, 10, 0, 0],
            },
          ],
        };
      },

      /* #endregion */

      /* #region FOOTER */
      footer: (currentPage, pageCount, pageSize) => {
        return {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 3,
              lineColor: '#800000',
            },
          ],
          columns: [
            {
              width: '70%',
              text: [
                {
                  text: 'Patient Signature:',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: '_____________________________________________________________________\n',
                  fontSize: 10,
                  italics: true,
                },
                {
                  text: 'Monitor Medical, Inc. \t',
                  fontSize: 16,
                  italics: true,
                  bold: true,
                },
                {
                  text: 'The C-PAP Co. \t',
                  fontSize: 12,
                  italics: true,
                },
                {
                  text: 'Toll Free: 866-634-0177 \n',
                  fontSize: 10,
                  italics: true,
                  bold: true,
                },
                {
                  text: printTicketData?.mmRefOrderId ?? '',
                  fontSize: 14,
                  italics: true,
                  alignment: 'right',
                  bold: true,
                  color: '#000000',
                },
              ],
              // ,
              bold: true,
              color: '#800000',
            },
            {
              width: '30%',
              text: [
                // {
                //   text: '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ \n\n',
                //   fontSize: 15,
                //   bold: true,
                //   italics: true,
                //   alignment: 'left',
                // },
                { text: 'Date:', fontSize: 10, italics: true },
                { text: '___________________________\n' },
                {
                  text:
                    this.datepipe.transform(new Date(), 'fullDate') + '\n\n',
                  alignment: 'center',
                  fontSize: 8,
                  italics: true,
                  bold: false,
                },
                {
                  text:
                    printTicketData.shippingMethodName +
                    '\t' +
                    printTicketData.location,
                  fontSize: 8,
                  italics: true,
                  alignment: 'center',
                  color: '#000000',
                },
              ],
              fontSize: 12,
              bold: true,
              alignment: 'left',
              color: '#800000',
            },

            // {
            //   text:
            //     printTicketData.shippingMethodName +
            //     ' ' +
            //     printTicketData.location,
            //   fontSize: 8,
            //   italics: true,
            // },
          ],
          margin: [15, 0, 15, 0],
          alignment: 'left',
          // fontSize: 10,
        };
      },
      /*#endregion*/

      /* #region CONTENT */

      content: [
        /* #region Section 1 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          columns: [
            {
              width: '33%',
              text: 'Order No:\t' + printTicketData.mmRefOrderId ?? '',
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'INS:\t' + printTicketData.insuranceName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'H:\t' + this.formatPhoneNumber(printTicketData?.homePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 10, 0, 3], // [left, top, right, bottom]
        },
        //Row 2
        {
          columns: [
            {
              width: '33%',
              text: 'Account No:\tB9DE9073TX',
              bold: true,
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'DOB:\t' +
                (printTicketData?.dateOfBirth != null
                  ? this.datepipe.transform(
                      printTicketData?.dateOfBirth,
                      'MM/dd/yyyy'
                    )
                  : ''),
              fontSize: 10,
              style: 'columnHeader',
            },
            {
              width: '33%',
              text:
                'W:\t' + this.formatPhoneNumber(printTicketData?.workPhone) ??
                '',
              fontSize: 10,
              style: 'columnHeader',
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Patient Name:\t' + printTicketData.patientName ?? '',
              fontSize: 10,
            },
            {
              width: '33%',
              text: 'e-mail:\t' + (printTicketData?.emailId ?? ''),
              fontSize: 10,
            },
            {
              width: '33%',
              text:
                'M:\t' + this.formatPhoneNumber(printTicketData?.mobilePhone) ??
                '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 3],
        },
        //Row 4
        {
          columns: [
            {
              width: '50%',
              text: 'Address:\t' + printTicketData?.fullAddress ?? '',
              fontSize: 10,
            },
          ],
          margin: [0, 0, 0, 10],
        },

        /* #endregion*/
        /* #region Section 2 */
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        //Row 1
        {
          text:
            'Contact ' + printTicketData?.technicianName + ' at 281-637-2286',
          fontSize: 16,
          bold: true,
          alignment: 'center',
          color: '#800000',
          margin: [0, 5],
        },
        //Row 2
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 565,
              y2: 0,
              lineWidth: 2,
              lineColor: '#800000',
            },
          ],
        },
        /* #endregion*/
        /* #region Section 3 */
        //Row 1
        {
          width: '33%',
          text: 'Pressure Settings',
          italics: true,
          fontSize: 8,
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        //Row 2
        {
          table: {
            headerRows: 1,
            widths: [
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
              '*',
            ],
            body: [
              [
                {
                  text: 'Min Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Max Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Setting',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'IPAP', fontSize: 6, alignment: 'center', bold: true },
                { text: 'EPAP', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'Ramp Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Ramp Time',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'BiFlex',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'Set Press',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'CFlex', fontSize: 6, alignment: 'center', bold: true },
                {
                  text: 'RR/BPM',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Min',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                {
                  text: 'IPAP Max',
                  fontSize: 6,
                  alignment: 'center',
                  bold: true,
                },
                { text: 'SetVt', fontSize: 6, alignment: 'center', bold: true },
                { text: 'Rate', fontSize: 6, alignment: 'center', bold: true },
              ],
              [
                {
                  text:
                    this.printTicketData?.settingsDetails?.minPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.maxPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.settlingTime ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.epap ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.rampPressure ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: this.printTicketData?.settingsDetails?.rampTime ?? '',
                  fontSize: 6,
                  alignment: 'center',
                  margin: [0, 5],
                },
                {
                  text: this.printTicketData?.settingsDetails?.biFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text:
                    this.printTicketData?.settingsDetails?.setPressure ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.cFlex ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.rrbpm ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipaPmin ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.ipaPmax ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.setVT ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
                {
                  text: this.printTicketData?.settingsDetails?.rate ?? '',
                  fontSize: 6,
                  margin: [0, 5],
                  alignment: 'center',
                },
              ],
            ],
          },
        },
        //Row 3
        {
          columns: [
            {
              width: '33%',
              text: 'Technician: ' + printTicketData?.technicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text: 'Physician: ' + printTicketData?.physicianName ?? '',
              fontSize: 8,
              alignment: 'center',
            },
            {
              width: '33%',
              text:
                'Referral Practice: ' + printTicketData?.referralPractice ?? '',
              fontSize: 8,
              alignment: 'center',
            },
          ],
          margin: [0, 5, 0, 5], // [left, top, right, bottom]
        },
        /* #endregion */

        /*#region Section 4 */
        {
          table: {
            headerRows: 1,
            // widths: ['15%', '5%', '10%', '45%', '10%', '15%'],
            widths: ['10%', '12%', '44%', '10%', '9%', '15%'],
            body: [
              [
                // {
                //   text: 'Location',
                //   bold: true,
                //   fontSize: 10,
                //   color: '#800000',
                // },
                { text: 'Qty', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: 'Product Code',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Product Description',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'HCPC',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                // { text: 'Size', bold: true, fontSize: 10, color: '#800000' },
                {
                  text: printTicketData?.isOrderComplete ? 'Allowable' : 'Exch',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
                {
                  text: 'Serial #/Lot #',
                  bold: true,
                  fontSize: 10,
                  color: '#800000',
                },
              ],
              ...this.printTicketData?.productDetailsDTO?.map((item) => [
                // item.location,
                item.quantity,
                item.productCode,
                item.productName,
                // item.sizeName,
                item.hcpc,
                printTicketData?.isOrderComplete
                  ? item.allowables
                  : {
                      image:
                        'data:image/png;base64,' +
                        this.getSquareImageBase64(item.exchange),
                      width: 10,
                      height: 10,
                      alignment: 'center',
                    },
                item.serialLotNo,
              ]),
            ],
          },
          style: {
            fontSize: 10,
            margin: [0, 2],
          },
          margin: [0, 5, 0, 0],
        },
        /* #endregion */

        /* #region Section 5 */
        {
          columns: [
            {
              width: '60%',
              text: [
                {
                  text: '\nComments to Patients:\n',
                  color: '#800000',
                  fontSize: 12,
                },
                {
                  text: printTicketData?.commentsToPatient ?? '',
                  fontSize: 8,
                },
              ],
              fontSize: 14,
              bold: true,
              alignment: 'left',
            },

            {
              width: '40%',
              table: {
                headerRows: 1,
                // widths: ['80%', '20%'],
                widths: ['72%', '28%'],
                body: [
                  [
                    {
                      text: 'Est Allowable:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAllowable ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      border: [false, false, false, false],
                      alignment: 'left',
                    },
                  ],
                  [
                    {
                      text: 'Est Copay:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estCopay ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Total Accessory:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.totalAccessory ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Shipping Fee:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.shippingFee ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],

                  [
                    {
                      text: 'Est Amount Due:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.estAmountDue ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: 'Amount Paid:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, true],
                    },
                    {
                      text: '$' + printTicketData.amountPaid ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, true],
                    },
                  ],
                  [
                    {
                      text: 'Balance:',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: '$' + printTicketData.patientBalance ?? '0',
                      fontSize: 10,
                      margin: [0, 0],
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 4 || i === 6 ? 1 : 0; // Thickness of the dotted line for the second row bottom border
                },
                hLineColor: function (i, node) {
                  return '#800000';
                },
                hLineStyle: function (i, node) {
                  if (i === 4 || i === 6) {
                    return { dash: { length: 5, space: 5 } }; // Dotted style for the second row bottom border
                  }
                  return null;
                },
              },
            },
          ],
        },
        /* #endregion*/
      ],
      /* #endregion */

      // pageMargins: [15, 50, 15, 40],
      pageMargins: [15, 60, 15, 60],
      defaultStyle: {
        font: 'Roboto',
      },
    };

    this.displayPdf(docDefinition, 'Ticket');
  }

  private getSquareImageBase64(excahngeItem): string {
    if (excahngeItem === true) {
      // Convert the image to Base64 format (using a base64 string or FileReader)
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');

      // Draw outer border with light sea green color
      ctx.fillStyle = '#000000'; // Light Sea Green color
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with light sea green

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // White color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas

      // Draw check mark (using a simple path)
      ctx.strokeStyle = '#20B2AA'; // Black color
      ctx.beginPath();
      ctx.moveTo(2, 4);
      ctx.lineTo(4, 6);
      ctx.lineTo(8, 2);
      ctx.stroke();

      return canvas.toDataURL('image/png').split(',')[1];
    } else {
      const canvas = document.createElement('canvas');
      canvas.width = 10;
      canvas.height = 10;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#000000'; // Border color
      ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with black

      // Draw inner white square (to create border effect)
      ctx.fillStyle = '#FFFFFF'; // Square color
      ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas
      return canvas.toDataURL('image/png').split(',')[1];
    }
  }

  // private getSquareImageBase64(): string {
  //   // Convert the image to Base64 format (using a base64 string or FileReader)
  //   const canvas = document.createElement('canvas');
  //   canvas.width = 10;
  //   canvas.height = 10;
  //   const ctx = canvas.getContext('2d');
  //   ctx.fillStyle = '#000000'; // Border color
  //   ctx.fillRect(0, 0, 12, 12); // Fill entire canvas with black

  //   // Draw inner white square (to create border effect)
  //   ctx.fillStyle = '#FFFFFF'; // Square color
  //   ctx.fillRect(1, 1, 8, 8); // Leave 1 pixel border around the canvas
  //   return canvas.toDataURL('image/png').split(',')[1];
  // }
  //Method to display the PDF Content
  displayPdf(docDefinition: any, filename: string): void {
    try {
      const pdfMake = window['pdfMake'];
      const pdfDocGenerator = pdfMake.createPdf(docDefinition); //.download(filename);

      // Open PDF in a new tab
      pdfDocGenerator.getBlob((blob: Blob) => {
        const dataUri = URL.createObjectURL(blob);
        window.open(dataUri, '_blank');
        URL.revokeObjectURL(dataUri);
      });

      // Optionally, you can add print and download buttons in your HTML
      // and trigger these actions using pdfMake functions:
      // pdfMake.createPdf(docDefinition).print();
      // pdfMake.createPdf(docDefinition).open();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Error creating PDF',
      });
    }
  }
  private formatPhoneNumber(value: string): string {
    if (value === null) return '';
    const phoneNumberRegex = /^(\d{3})(\d{3})(\d{4})$/;
    const matches = phoneNumberRegex.exec(value);

    if (!matches) return value;

    // Format the phone number with hyphens
    const formattedNumber = `${matches[1]}-${matches[2]}-${matches[3]}`;
    return formattedNumber;
  }
  /* #endregion */

  //View Patient Note
  openViewNotesPopup() {
    const config: MatDialogConfig = {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '40vw',
      maxHeight: '40vw',
      data: {
        patientId: this.patientId,
        pageType: 'Patient Note',
        isAddVisible: false,
        defaultFaxId: this.defaultFaxId,
        docId: this.faxId,
      },
    };
    const dialogRef = this.dialog.open(PatientCommonNotesComponent, config);

    dialogRef.afterClosed().subscribe(
      () => {},
      (err: HttpErrorResponse) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  //View Patient Document
  viewPatientDocument() {
    const url = '/#/view_document_tiff/' + [this.documentFaxId] + '/' + true;
    window.open(url, '_blank');
  }

  /* #region RX Document Selection */
  selectRXDocument() {
    const config: MatDialogConfig = {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        previewList: this.ltRXDocument,
        docId: this.faxId,
        isMedicare: false,
        isView: false,
      },
    };
    const dialogRef = this.dialog.open(
      CommonDoumentViewerForMedicareComponent,
      config
    );

    const closeDialog = dialogRef
      .afterClosed()
      .subscribe((selectedFileDetails: any) => {
        this.ltRXDocument = selectedFileDetails;
      });
    this.subscription$.push(closeDialog);
  }
  /* #endregion */

  //To get the Formatted Date for the Order Date
  getFormattedDate(value): any {
    const formattedDate = this.datepipe.transform(value, 'yyyy-MM-dd');
    return formattedDate;
  }

  /* #region Billing */

  moveOrderToBilling() {
    this.loaderService.showLoader();
    this.isSaveBtnDisabled = true;
    let data = this.itemsList;

    data.filter((data) => {
      if (data.mmProductId !== '' || data.mmProductId !== data.mmProductId) {
        let indexData = this.itemsList.findIndex(
          (item) => item.mmProductId == data.mmProductId
        );

        if (indexData !== -1) {
          this.CreateUpdateMMOrderItemDTO[indexData] = {
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
          };
        } else {
          this.CreateUpdateMMOrderItemDTO.push({
            tenantId: this.tenantId,
            mmReferenceOrderItemId: 0,
            mmOrderId: data.mmOrderId ?? defaultGuid,
            sizeId: data.sizeId ? data.sizeId : defaultGuid,
            primaryInsuranceId: data.primaryInsuranceId ?? defaultGuid,
            mmProductId: data.mmProductId ? data.mmProductId : defaultGuid,
            qty: data.qty ?? 0,
            allowable: data.allowable ?? 0,
            accessory: Number(data.accessory) ?? 0,
            backOrderedSince: new Date().toLocalISOString(),
            lineItemNotes: '',
            productDescription: data.productDescription ?? '',
            patientQtyOnHand: data.patientQtyOnHand ?? 0,
            points_Cal: data.points_Cal ?? 0,
            serialLotNo: defaultGuid,
            serialLotNumber: data.serialLotNo ? data.serialLotNo : '',
            itemSettings: data?.itemSettings ?? null,
            itemCost: 0,
            exchange: data.exchange,
            return: data.return,
            fixedPoints: 0,
            unitCost: data.unitCost ?? 0,
            unitAllowable: data.unitAllowable ?? 0,
            unitAccessory: data.unitAccessory ?? 0,
            mmOrderItemId: data.mmOrderItemId ?? defaultGuid, //defaultGuid,
            discountPercent: data.discountPercent,
            specialItem: 0,
            addOnFromSuggestions: 0,
            remoteTransferSuccessful: 0,
            status: 0,
            awaitDlvry: data.awaitDlvry ?? 0,
            pendpalOrder: data.pendpalOrder ?? 0,
            actualAccessory: data.actualAccessory ?? 0,
            discountAccessory: data.discountAccessory ?? 0,
            bIsNewSetup: data.bIsNewSetup ?? false,
          });
        }
      }
    });

    const saveDetails: CreateUpdateMMOrderDTO = {
      tenantId: this.tenantId,
      authorizationId: this.orderForm.value.drpAuthorization,
      mmRefereceOrderId: this.orderDetailsForm.value.txtOrderNo ?? 0,
      patientId: this.patientId,
      locationId: this.orderForm.value.drpLocation
        ? this.orderForm.value.drpLocation
        : defaultGuid,
      bUsePatientAddress: 0,
      addressId: this.addressId ?? defaultGuid,
      supplies: 0,
      lmn: 0,
      rx_MedForceScanId: 0,
      primaryVerificationId: this.priVerificationId ?? defaultGuid,
      // secondaryVerificationId: this.secVerificationId ?? defaultGuid,
      secondaryVerificationId: this.secVerificationId ?? defaultGuid,
      deductible:
        this.orderDetailsForm.value.txtDeductible == null ||
        this.orderDetailsForm.value.txtDeductible === ''
          ? 0
          : this.orderDetailsForm.value.txtDeductible,
      copay:
        this.orderDetailsForm.value.txtCopay == null ||
        this.orderDetailsForm.value.txtCopay === ''
          ? 0
          : this.orderDetailsForm.value.txtCopay,
      commentsToTech: this.orderForm.value.txtTechComments ?? '',
      commentsToShipping: this.orderForm.value.txtShippingComments ?? '',
      commentsToBilling: this.orderForm.value.txtBillingComments ?? '',
      commentsToPatient: this.orderForm.value.txtPatientComments ?? '',
      newSetup: 0,
      csRid: this.orderForm.value.drpCSR ?? defaultGuid,
      referralPracticeId: this.orderForm.value.drpPracticeManagement
        ? this.orderForm.value.drpPracticeManagement
        : defaultGuid,
      referralSourceId: this.orderForm.value.drpProvider
        ? this.orderForm.value.drpProvider
        : defaultGuid,
      shippingMethodId: this.orderForm.value.drpShippingMethod
        ? this.orderForm.value.drpShippingMethod
        : defaultGuid,
      shippingFee: this.orderDetailsForm.value.txtShippingFee ?? 0,
      paPtherapyUsedAndBeneficial: 0,
      previousOrderStatus: defaultGuid,
      cashOrder:
        this.orderForm.value.chkCashOrder == ''
          ? false
          : this.orderForm.value.chkCashOrder ?? false,
      faxId: this.defaultFaxId ?? '',
      // faxId: defaultGuid,
      defaultSaleOrderId: '',
      orderAmount: Number(this.orderDetailsForm.value.txtNetTotal) ?? 0,
      estDueAmount: this.orderDetailsForm.value.txtEstimateAmountDue ?? 0,
      estCopay: Number(this.orderDetailsForm.value.txtEstimatedCopay) ?? 0,
      estAllowable:
        Number(this.orderDetailsForm.value.txtEstimatedAllowable) ?? 0,
      totalAccessory:
        Number(this.orderDetailsForm.value.txtTotalAccessory) ?? 0,
      checkListId: this.medicareChecklistId
        ? this.medicareChecklistId
        : defaultGuid,
      preCertId: defaultGuid,
      rxDate:
        this.orderForm.value.txtRXDate !== null &&
        this.orderForm.value.txtRXDate !== ''
          ? this.datepipe.transform(
              this.orderForm.value.txtRXDate,
              'yyyy-MM-dd'
            )
          : null,
      lockedBy: defaultGuid,
      lockedOn: new Date().toLocalISOString(),
      lockedBySPID: 0,
      modifiedBy: defaultGuid,
      modifiedOn: new Date().toLocalISOString(),
      orderDate:
        this.getFormattedDate(this.orderForm.value.txtOrderDate) ??
        this.getFormattedDate(new Date()),
      // this.commonService.getCSTDateTime(this.orderForm.value.txtOrderDate) ??
      // this.commonService.getCSTDateTime(new Date()),
      // this.getFormattedDate(this.orderForm.value.txtOrderDate) ??
      // new Date().toLocalISOString(),
      dos:
        this.getFormattedDate(this.orderForm.value.txtDOS) ??
        this.getFormattedDate(new Date()),
      // this.commonService.getCSTDateTime(this.orderForm.value.txtDOS) ??
      // this.commonService.getCSTDateTime(new Date()),
      // this.getFormattedDate(this.orderForm.value.txtDOS) ??
      // new Date().toLocalISOString(),
      backOrderDate: new Date().toLocalISOString(),
      orderStatusSince: new Date().toLocalISOString(),
      setupDate: new Date().toLocalISOString(),
      suppliesDate: new Date().toLocalISOString(),
      shippingStatusId: defaultGuid,
      orderCreatedBy: defaultGuid,
      overrideBy: defaultGuid,
      overrideDt: new Date().toLocalISOString(),
      overrideNote: '',
      balOverrideBy: defaultGuid,
      balOverrideDt: new Date().toLocalISOString(),
      orderPointsCAL: this.orderDetailsForm.value.txtPoints ?? 0, // this.orderDetailsForm.getRawValue().txtPoints, // this.orderDetailsForm.value.txtPoints ?? 0,
      pastDueBalance: this.isEmpty(
        this.orderDetailsForm.value.txtPastDueBalance
      )
        ? 0
        : this.orderDetailsForm.value.txtPastDueBalance,
      lastPaymentId: defaultGuid,
      paymentsPostedByUserId: defaultGuid,
      paymentsPostedOn: new Date().toLocalISOString(),
      billingApprovalNote: '',
      billingNote: this.orderForm.value.txtBillingComments ?? '',
      billingApprovalStatusId: defaultGuid,
      pendingAtBilling: 0,
      pendingAtBillingApproval: 0,
      orderCreatedOn: new Date().toLocalISOString(),
      functionalityQuNote: 0,
      verifiedPatientInfo: this.orderForm.value.chkVerified == true ? 1 : 0,
      downloadedCompliance: 0,
      mfs_ComplianceImageId: 0,
      ftf_Date: new Date().toLocalISOString(),
      mfs_FTFImageId: 0,
      finalBilling: new Date().toLocalISOString(),
      complianceReleaseChecklistId: 0,
      // orderSentToShipping: new Date().toLocalISOString(),
      remoteVideoCall: 0,
      curbSidePU: 0,
      orderStatus: this.orderStatusId ?? defaultGuid,
      status: 0,
      rental: this.orderForm.value.chkRental
        ? this.orderForm.value.chkRental
        : false,
      months: this.orderForm.value.txtmoths ? this.orderForm.value.txtmoths : 0,
      orderItems: this.CreateUpdateMMOrderItemDTO,
      faxDocumentId: this.faxId,
      isResupply: false,
      isTemplate: 0,
      rentalresupplySaleOrder: null,
      rxDocuments: this.ltRXDocument.length === 0 ? [] : this.ltRXDocument,
      isOverride: false,
      isToBilling: true,
      rentalAllowable: this.rentalAllowable || 0,
      nonRentalAllowable: this.nonRentalAllowable || 0,
    };
    if (
      this.isEmpty(this.orderDetailsForm.value.txtPastDueBalance)
        ? 0
        : this.orderDetailsForm.value.txtPastDueBalance >= 5
    ) {
      this.loaderService.hideLoader();
      const dialogRef = this.dialog.open(SaleOrderOverrideModalComponent, {
        width: '750px',
        data: {
          orderId: this.orderId,
          patientId: this.patientId,
          orderDto: saveDetails,
          overrideType: this.isEmpty(
            this.orderDetailsForm.value.txtPastDueBalance
          )
            ? 0
            : this.orderDetailsForm.value.txtPastDueBalance,
        },
        disableClose: true,
      });
      const closeDialog = dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.isSaveBtnDisabled = true;
        } else {
          this.isSaveBtnDisabled = false;
        }
      });
      this.subscription$.push(closeDialog);
    } else {
      if (
        this.orderId != null &&
        this.orderId != '' &&
        this.orderId != undefined &&
        this.orderId != defaultGuid
      ) {
        this.mmOrderService
          .updateOrderToBillingByIdAndInputData(this.orderId, saveDetails)
          .subscribe(
            (response) => {
              this.toastr.success('Order moved to Billing!');
              this.patientId = response?.patientId;
              this.orderId = response?.id;
              this.loadExistingOrderDetails();
              this.loaderService.hideLoader();
            },
            (err) => {
              this.loaderService.hideLoader();
              this.isSaveBtnDisabled = false;
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            }
          );
      } else {
        this.mmOrderService.createOrderToBillingByInput(saveDetails).subscribe(
          (response) => {
            this.toastr.success('Order Moved to Billing!');
            this.patientId = response?.patientId;
            this.orderId = response?.id;
            this.loadExistingOrderDetails();
            this.loaderService.hideLoader();
          },
          (err) => {
            this.loaderService.hideLoader();
            this.isSaveBtnDisabled = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    }
  }

  /* //#endregion */

  /* #region Machine Settings */

  openMachineSettings(productId: string, categoryName: string) {
    // MachineSettingsModalComponent
    this.isSettingsView = this.isDisableRemoveItem === true ? true : false;
    const machineSettings = this.itemsList.find(
      (a) => a.mmProductId == productId
    ).itemSettings;

    const config: MatDialogConfig = {
      disableClose: true,
      data: {
        productId: productId,
        machineSetting: machineSettings,
        categoryName: categoryName,
        isSettingsView: this.isSettingsView,
      },
    };
    const dialogRef = this.dialog.open(MachineSettingsModalComponent, config);

    const closeDialog = dialogRef
      .afterClosed()
      .subscribe((machineSettings: any) => {
        const product =
          this.itemsList?.find((a) => a.mmProductId == productId) ?? null;
        if (product != null) {
          product.itemSettings = machineSettings;
        }
      });
    this.subscription$.push(closeDialog);
  }
  /* #endregion */
  //Format Time
  getFormattedDateTimeZone(date: string): string {
    if (date) {
      const formattedDate = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: 'America/Chicago',
      };
      return formattedDate.toLocaleString('en-US', options);
    } else {
      return '-';
    }
  }
  addNewCity() {
    if (
      this.orderForm.value.txtCityFilter !== undefined &&
      this.orderForm.value.txtCityFilter !== null &&
      this.orderForm.value.txtCityFilter.trim() !== '' &&
      this.orderForm.value.txtCityFilter.trim().length <= 40
    ) {
      // const objWithIdIndex = this.drpCities.findIndex((obj) => obj.cityId === 1);
      // if(objWithIdIndex !== -1){
      // this.drpCities.splice(objWithIdIndex, 1);
      // }
      const newCityName = this.orderForm.value.txtCityFilter
        .trim()
        .toLowerCase();
      var alreadyAdd = this.drpCities?.filter(
        (obj) => obj.cityName.trim().toLowerCase() === newCityName
      );
      if (alreadyAdd.length === 0) {
        let newCity = {
          cityName: this.orderForm.value.txtCityFilter,
          cityId: 1,
        };

        this.drpCities?.push(newCity);
        this.orderForm.patchValue({
          txtCity: this.orderForm.value.txtCityFilter,
        });
      }
    }
  }
  cityDrpDownMessage() {
    if (
      this.orderForm.value.txtState === '' ||
      this.orderForm.value.txtState === null ||
      this.orderForm.value.txtState === undefined
    ) {
      return 'No Matches found';
    } else if (
      this.orderForm.value.txtCityFilter === '' ||
      this.orderForm.value.txtCityFilter === null ||
      this.orderForm.value.txtCityFilter === undefined
        ? this.orderForm.value.txtCityFilter !== '' &&
          this.orderForm.value.txtCityFilter !== null &&
          this.orderForm.value.txtCityFilter !== undefined
        : this.orderForm.value.txtCityFilter.trim().length > 40
    ) {
      return 'maximum 40 characters only allowed';
    } else {
      return 'Enter a City Name';
    }
  }
  openDoSorting() {
    const url = '/#new_setup/' + this.faxId + '/0';
    window.open(url, '_blank');
  }
  func(event): void {
    if (event.key == 'checkisSortingData') {
      if (event.newValue == 'NewisSorting') {
        this.isDocumentSorted = true;
      } else {
      }
    }
  }

  redirect(id: any) {
    // this.router.navigate(['/orders/saleOrder', this.patientId, id, 0], {
    //   queryParamsHandling: 'merge',
    // });
    this.orderDetailsForm.reset();
    this.orderForm.reset();
    this.loaderService.hideLoader();
    this.orderId = id ?? defaultGuid;

    this.router.navigate(
      ['/orders/saleOrder', this.patientId, this.orderId, 0],
      { queryParamsHandling: 'merge' }
    );
    this.loadExistingOrderDetails();
  }
}
