import type { CreateUpdateMailTemplateMasterDTO, GetMailcontentDTO, MailTemplateMasterDTO, MailcontentDTO } from './dto/models';
import type { MailTemplateMaster } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ActiveStatus } from '../localization/enumeration/active-status.enum';

@Injectable({
  providedIn: 'root',
})
export class MailTemplateMasterService {
  apiName = 'platformManagement';

  create = (input: CreateUpdateMailTemplateMasterDTO) =>
    this.restService.request<any, MailTemplateMasterDTO>({
      method: 'POST',
      url: '/api/PlatformApp/mail-template-master',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/PlatformApp/mail-template-master/${id}`,
    },
    { apiName: this.apiName });

  enableDisableMailTemplateByIdAndInput = (Id: string, input: number) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: `/api/PlatformApp/mail-template-master/enable-disable-mail-template/${Id}`,
      params: { input },
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, MailTemplateMasterDTO>({
      method: 'GET',
      url: `/api/PlatformApp/mail-template-master/${id}`,
    },
    { apiName: this.apiName });

    getMailContentByDTO = (DTO: GetMailcontentDTO) =>
    this.restService.request<any, MailcontentDTO>({
      method: 'GET',
      url: '/api/PlatformApp/mail-template-master/mail-content',
      params: { templateId: DTO.templateId, patientId: DTO.patientId },
    },
    { apiName: this.apiName });

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<MailTemplateMasterDTO>>({
      method: 'GET',
      url: '/api/PlatformApp/mail-template-master',
      params: { sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  getMailContentByTemplateIdAndPatientId = (templateId: string, patientId: string) =>
    this.restService.request<any, MailcontentDTO>({
      method: 'GET',
      url: '/api/PlatformApp/mail-template-master/mail-content',
      params: { templateId, patientId },
    },
    { apiName: this.apiName });

  getStatusBasedMailTemplateList = (statusFilter: ActiveStatus) =>
    this.restService.request<any, MailTemplateMasterDTO[]>({
      method: 'GET',
      url: '/api/PlatformApp/mail-template-master/status-based-mail-template-list',
      params: { statusFilter },
    },
    { apiName: this.apiName });

  update = (id: string, input: CreateUpdateMailTemplateMasterDTO) =>
    this.restService.request<any, MailTemplateMasterDTO>({
      method: 'PUT',
      url: `/api/PlatformApp/mail-template-master/${id}`,
      body: input,
    },
    { apiName: this.apiName });

    updateMailContentByTempIdAndMailcontent = (tempId: string, mailcontent: string) =>
    this.restService.request<any, MailTemplateMaster>({
      method: 'PUT',
      url: `/api/PlatformApp/mail-template-master/mail-content/${tempId}`,
      body: mailcontent,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
