import { Component, OnInit, ViewChild } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import {
  ProductDetailsService,
  ProductStockDetailsService,
} from '../item-proxy/item-management/optimization';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import {
  ProductStockDetailStatus,
  stockDetailsStatusOptions,
} from '../item-proxy/item-management/optimization/dto';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { MatDialog } from '@angular/material/dialog';
import { AddStockComponent } from '../add-stock/add-stock.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zero-stock-list',
  templateUrl: './zero-stock-list.component.html',
  styleUrls: ['./zero-stock-list.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          transform: 'translateX(0)',
        })
      ),
      state(
        'closed',
        style({
          transform: 'translateX(-100%)',
        })
      ),
      transition('open <=> closed', [animate('0.3s')]),
    ]),
  ],
}) 
export class ZeroStockListComponent implements OnInit {
  step: boolean = false;
  stockForm: FormGroup;
  tableData: any[] = [];
  stockSearchForm: FormGroup;
  dataSource: any;
  product: string = '';
  drpProduct: any;
  filterMainProducts: any;
  status: string = '';
  drpStatus: { value: string; key: string }[] = [];
  filterStatus: any;
  displayedColumns: string[] = [

    'productName',
    // 'brandName',
    'categoryName',
    'pricePerUnit',
    'lotNo',
    'stockCount',
    'createdDate',
  ];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('allProducts') private allProducts: MatOption;
  @ViewChild('selectProduct') selectProduct: MatSelect;
  @ViewChild('allStatus') private allStatus: MatOption;
  @ViewChild('selectStatus') selectStatus: MatSelect;
  subscription$: Subscription[] = [];
  isShowSpinner: boolean = false;
  showFilter = true;

  constructor(
    private formBuilder: FormBuilder,
    private stockDetailsService: ProductStockDetailsService,
    private productDetailsService: ProductDetailsService,
    private dateValidator: DateValidator,
    public dialog: MatDialog,
    public router: Router,
  ) {}

  //Page Load
  ngOnInit(): void {
    this.initializeForms();
    this.loadDropdowns();
  }

  //Toggle Filter Div
  toggleFilter() {
    this.showFilter = !this.showFilter;
  }
  //Method for Product Multi Select Dropdown all options
  toggleAllSelection() {
    if (this.allProducts.selected) {
      this.selectProduct.options.forEach((item: MatOption) => item.select());
    } else {
      this.selectProduct.options.forEach((item: MatOption) => item.deselect());
    }
  }
 
  openProductModal() {
    const dialogRef = this.dialog.open(AddStockComponent, {
      disableClose: true,

    });
  }


  //Method to Initialize the Forms
  initializeForms() {
    this.stockForm = this.formBuilder.group({
      drpProduct: new FormControl(''),
      txtMainProductFilter: new FormControl(''),
      drpStatus: new FormControl(''),
      txtStatusFilter: new FormControl(''),
      txtCreatedate: new FormControl('',),
    });

    this.stockSearchForm = this.formBuilder.group({
      productName: new FormControl(''),
      brandName: new FormControl(''),
      categoryName: new FormControl(''),
      pricePerUnit: new FormControl(''),
      lotNo: new FormControl(''),
      //txtStockCount: new FormControl(''),
      balanceStock: new FormControl(''),
      createdDate: new FormControl('', this.dateValidator.dateVaidator),
    });
  }

  //Dropdowns load function
  loadDropdowns() {
    this.getProducts();
    this.getStockStatus();
  }
  //Get Stock Status and patch in Status Dropdown
  getStockStatus() {
    this.filterStatus = stockDetailsStatusOptions?.map((e) => {
      let x = { value: e.value, key: e.key };
      return x;
    });
  }
  //To get the All Products List and to patch in Dropdown
  getProducts() {
    
    const productList = this.productDetailsService.getZeroProductsList().subscribe(
      (response) => {
        this.setTableData(response);
        this.drpProduct = response;
        this.filterMainProducts = this.stockForm
          ?.get('txtMainProductFilter')
          .valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.drpProduct.filter((option) =>
                option?.mainProductName
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },
      (err) => {
        const data: HttpErrorResponse = err;
      }
    );

    this.subscription$.push(productList);
  }

  //Set Table Data
  setTableData(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

 
  //method to clear the product field
  clearProduct() {
    this.product = '';
    this.stockForm.patchValue({ drpProduct: '' });
  }
}
