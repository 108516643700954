
import { DatePipe } from '@angular/common';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DocumentsService } from 'projects/patient/src/app/patient-proxy/controllers/document.service';
import { DocumentService } from 'projects/patient/src/app/patient-proxy/patient/document.service';
import { AutoIntakeDocumentDetailsDTO, PatientDTO, SavePatientDocumentBlobDTO } from 'projects/patient/src/app/patient-proxy/patient/dto/models';
import { PatientSearchService } from 'projects/patient/src/app/patient-proxy/patient/patient-search.service';
import { getPatient } from 'projects/patient/src/app/patient-store/patient.action';
import { baseUrl } from 'projects/patient/src/environments/environment';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import {  Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { BatchFileUploadsDTO, IdentifiedPatientsDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/dto/models';
import { FileUploadService } from '../admin-proxy/platform-app-management/rcm/platform-management/file-upload/file-upload.service';
import { OrganizationUnitDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto/models';
import { OrganizationUnitService } from '../admin-proxy/platform-app-management/rcm/platform-management/organization-units/organization-unit.service';
import { IdentityUserDto } from '../admin-proxy/platform-app-management/volo/abp/identity/models';
import { ExtractionModalComponent } from '../extraction-modal/extraction-modal.component';
import { ToastrService } from "ngx-toastr";
@Component({
  selector: 'app-approval-list',
  templateUrl: './approval-list.component.html',
  styleUrls: ['./approval-list.component.scss'],
  providers: [DatePipe],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ])],
})
export class ApprovalListComponent implements OnInit {
  step: boolean = false;
  isLoading: boolean = true;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  patientData: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) tableRef: MatTable<any>;
  CreateNewPatientWindow: any = 0;
  @ViewChild(MatSort, { static: true }) Patientsort: MatSort;
  @ViewChild(MatPaginator, { static: true }) patientPaginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) PatienttableRef: MatTable<any>;
  displayedColumns: string[] = [
    'Name',
    'Patient Id',
    'Email Address',
    'Date Of Birth',
    'LockStatus',
  ];

  expandedElement: any | null;
  arrUsersList: IdentityUserDto[] = [];
  tenantId: string = '';
  arrDisplayedColumns: string[] = [];

  arrTableData: any[] = [];
  batchId: string = '';
  chkdisableButton: boolean;
  addButtonDisable: boolean = false;
  branchListData: OrganizationUnitDTO[] = [];
  strSelectedPdfPath: string = '';
  personalForm: FormGroup;
  subscription$: Subscription[] = [];

  constructor(
    private fileUploadService: FileUploadService,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private store: Store,
    private documentService: DocumentService,
    private documentsService: DocumentsService,
    private dialog: MatDialog,
    private branchService: OrganizationUnitService,
    private dateValidator: DateValidator,
    private formBuilder: FormBuilder,
    private patientSearchService: PatientSearchService,
    private datepipe: DatePipe,
    private router: Router,
    private toastr: ToastrService,
  ) { }
  ngOnInit(): void {
    this.personalForm = this.formBuilder.group({
      Name: '',
      PatientId: '',
      Address: '',
      SSN: '',
      AccountNumber: '',
      EmailAddress: '',
      DateOfBirth: new FormControl('', this.dateValidator.dateVaidator),
      Moblie: '',
      LockStatus: '',
      currStatus: ''
    });
    const valueChanges: Subscription = this.personalForm.valueChanges.subscribe(
      (value: Patient) => {
        let filter: Patient = {
          PatientId: value.PatientId?.trim()?.toLowerCase(),
          Name: value.Name?.trim()?.toLowerCase(),
          Address: value.Address?.trim()?.toLowerCase(),
          SSN: value.SSN?.trim()?.toLowerCase(),
          AccountNumber: value.AccountNumber?.trim()?.toLowerCase(),
          EmailAddress: value.EmailAddress?.trim()?.toLowerCase(),
          DateOfBirth: value.DateOfBirth?.trim()?.toLowerCase(),
          Moblie: value.Moblie?.trim()?.toLowerCase(),
          LockStatus: value.LockStatus?.trim()?.toLowerCase(),
          currStatus: value.currStatus?.trim()?.toLowerCase(),
        };
        if (this.personalForm.valid) {
          this.patientData.filter = JSON.stringify(filter);
          this.filtertable(filter);
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(valueChanges);
    this.arrDisplayedColumns = [
      
      'batchNo',
      'stat',
      'documentName',
      'status',
      'Options',
    ];
    this.tenantId = localStorage.getItem('tenantId') ?? '';

    this.activatedRoute.paramMap.subscribe((response) => {
      this.batchId = response.get('id') ?? '';
    });
    this.title.setTitle('Qsecure | Approval List');
    this.batchId !== '' &&
      this.batchId !== undefined &&
      this.batchId !== null &&
      this.getbatchTableData();

    this.tenantId !== '' &&
      this.tenantId !== undefined &&
      this.tenantId !== null &&
      this.getBranchTable();
  }
  //! Get Branch List Details

  getBranchTable() {
    this.branchListData = [];
    this.branchService.getOrganizationList(this.tenantId).subscribe(
      (response) => {
        this.branchListData = response ?? [];
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  PATIENTDATA: Patient[] = [];

  // filter
  filtertable(filter: Patient) {
    let PatientName = filter.Name;
    let DefaultPatientId = filter.PatientId;
    let SSN = filter.SSN;
    let AccountNumber = filter.AccountNumber;
    let EmailId = filter.EmailAddress;
    let Mobile = filter.Moblie;
    let Fax = '';
    let Address = filter.Address;
    let dobDay = filter.DateOfBirth;
    let currStatus = filter.currStatus;
    let lockStatus = filter.LockStatus;
    this.PATIENTDATA = [];
    var today = new Date(dobDay);
    var date =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();
    this.patientSearchService
      .searchPatientBySPatientNameAndSDefaultPatientIdAndSSSNAndSAccountNumberAndSEmailIdAndSMobileAndSFaxAndSAddressAndDtDOBAndSLockStatusAndSSearchNameAndSCurrentStatus(
        PatientName,
        DefaultPatientId,
        SSN,
        AccountNumber,
        EmailId,
        Mobile,
        Fax,
        Address,
        dobDay,
        lockStatus,
        'uploadSearch',
        currStatus
      )
      .subscribe(
        (data) => {
          this.PATIENTDATA = [];
          data &&
            data?.items?.forEach((element) => {
              if (
                element && element?.addresses?.length !== 0
              ) {
                const lastDocumentBlobDetails: AutoIntakeDocumentDetailsDTO =
                  element.autoIntakeDocumentDetails.slice(
                    element.autoIntakeDocumentDetails.length - 1,
                    element.autoIntakeDocumentDetails.length
                  )[0] || null;
                const dateOfBirth =
                  element?.personals?.dateOfBirth === null
                    ? ''
                    : this.datepipe.transform(
                      element?.personals?.dateOfBirth,
                      'MM/dd/yyyy'
                    ) ?? '';
                this.PATIENTDATA.push({
                  id: element?.id,
                  PatientId: element?.defaultPatientId?.toString(),
                  Address:
                    element?.addresses[0]?.address === null || undefined
                      ? ''
                      : element?.addresses[0]?.address,
                  Name:
                    element?.personals?.firstName +
                    ' ' +
                    element?.personals?.middleName +
                    ' ' +
                    element?.personals?.lastName,
                  DateOfBirth: dateOfBirth,
                  SSN:
                    element?.personals?.ssn === null || undefined
                      ? ''
                      : element?.personals?.ssn,
                  AccountNumber:
                    element?.personals?.accountNumber === null || undefined
                      ? ''
                      : element?.personals?.accountNumber,
                  EmailAddress:
                    element?.personals?.emailId === null || undefined
                      ? ''
                      : element?.personals?.emailId,
                  Moblie:
                    element?.addresses[0]?.mobile === null || undefined
                      ? ''
                      : element?.addresses[0]?.mobile,
                  isAutoIntake: element.isAutoIntake,
                  autoIntakeDocumentDetails: lastDocumentBlobDetails ?? null,
                  LockStatus: element?.patientLock?.status,
                  LockStatusWithName:
                    element?.patientLock?.status,
                  currStatus: element?.currentStatus

                });

              }
              this.patientData = new MatTableDataSource(this.PATIENTDATA);
              this.patientData.sort = this.Patientsort;
              this.patientData.paginator = this.patientPaginator;
            });
          // this.patientData = ne*w MatTableDataSource(this.PATIENTDATA);
          // this.patientData.sort = this.Patientsort;
          // this.patientData.paginator = this.patientPaginator;
        },
        (error) => {
        }
      );

    this.patientData = new MatTableDataSource(this.PATIENTDATA);
    this.patientData.sort = this.Patientsort;
    this.patientData.paginator = this.patientPaginator;
  }

  //! Get Sub Batch Inital Table Details
  getbatchTableData() {
    this.isLoading = true;
    this.arrTableData = [];
    this.dataSource = new MatTableDataSource(this.arrTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    let arrTableData: appprovalDetails[] = [];
    this.fileUploadService.getFileBatchByIdByBatchId(this.batchId).subscribe(
      (response) => {
        const responseBatchData: BatchFileUploadsDTO = response;
        arrTableData = this.extractSubBatchTableDetails(responseBatchData);
        this.arrTableData = [...arrTableData];
        this.dataSource = new MatTableDataSource(this.arrTableData.reverse());
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  //! Extract Sub Batch Table Details
  private extractSubBatchTableDetails(
    batchDataTable: BatchFileUploadsDTO
  ): appprovalDetails[] {
    let arrTableData: Partial<appprovalDetails[]> = [];
    try {
      if (
        batchDataTable &&
        batchDataTable?.listFileUploads?.length !== 0 &&
        batchDataTable !== null
      ) {
        const listFileUploads = batchDataTable.listFileUploads;
        listFileUploads.forEach((element) => {
          //  if (element.uploadedFileStatus === UploadFileStatus.ExtractedAndSorted) {
          // const extractedData: PatientDTO | null =
          //   element?.extractedData?.extractedfields ?? null;
          // const lastDocumentBlobDetails: AutoIntakeDocumentDetailsDTO | null =
          //   extractedData?.autoIntakeDocumentDetails?.slice(
          //     extractedData?.autoIntakeDocumentDetails?.length - 1,
          //     extractedData?.autoIntakeDocumentDetails?.length
          //   )[0] || null;
          // const modifedExtractedData = {
          //   fullName: extractedData?.personals?.fullName ?? '',
          //   dateOfBirth:
          //     extractedData?.personals?.dateOfBirth === '' ||
          //       extractedData?.personals?.dateOfBirth === undefined ||
          //       extractedData?.personals?.dateOfBirth === null
          //       ? ''
          //       : new Date(
          //         extractedData?.personals?.dateOfBirth
          //       )?.toLocaleDateString("en-US") ?? '',
          //   emailId: extractedData?.personals?.emailId ?? '',
          //   lastDocumentBlobDetails: lastDocumentBlobDetails ?? null,
          // };
          // let data: Partial<appprovalDetails> = {
          //   defaultBatchId: batchDataTable?.defaultBatchId ?? '',
          //   fileId: element?.fileId ?? '',
          //   uploadedFileName: element?.uploadedFileName ?? '',
          //   isStat: element?.isStat === 1 ? true : false,
          //   blobName: element?.blobName ?? '',
          //   listIdentifiedPatients:
          //     element?.listIdentifiedPatients?.map((e) => {
          //       e.dateOfBirth =
          //         e?.dateOfBirth === '' ||
          //           e?.dateOfBirth === undefined ||
          //           e?.dateOfBirth === null
          //           ? ''
          //           : new Date(e?.dateOfBirth)?.toLocaleDateString("en-US") ?? '';
          //       return e;
          //     }) ?? [],
          //   // extractedfields:
          //   //   element?.extractedData?.extractedfields ?? undefined,
          //   // extractedData: modifedExtractedData ?? undefined,
          //   // status:
          //   //   element?.listIdentifiedPatients !== [] &&
          //   //     element?.listIdentifiedPatients !== null &&
          //   //     element?.listIdentifiedPatients !== undefined &&
          //   //     element?.listIdentifiedPatients?.length !== 0
          //   //     ? 'Existing Patient'
          //   //     : 'New Patient',
          // };

          //  arrTableData.push(data);
          //   }
        });
      }
      return arrTableData;
    } catch (error) {
      return [];
    }
  }
  //! Document Viewer with Feilds side by side
  documnetViewerSideBySide(fileId: string) {
    if (fileId && this.batchId) {
      const url = '/#/ViewDocumentDetailsAndApprove/' + [fileId.toString()] + '/' + [this.batchId.toString()];
      window.open(url, '_blank');
    }
  }

  //! Approval Patient Id Update onClick
  approvalUpdatePatientId(value1: string, value2: string) {
    if (this.checkForBranchexisitAtleastOne()) {
      return;
    }
    this.addButtonDisable = true;

    const fileId: string = value1;
    const patientId: string = value2;
    if (
      fileId &&
      patientId &&
      fileId !== '' &&
      patientId !== '' &&
      typeof fileId === 'string' &&
      typeof patientId === 'string'
    ) {
      Swal.fire({
        title: 'Are you sure you want to Approve?',
        text: "You won't be able to revert this change!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, approve it!',
      }).then((result) => {
        if (result.value) {
          this.fileUploadService
            .updateApprovedPatientIdByBatchIdAndFileIdAndPatientId(
              this.batchId,
              fileId,
              patientId
            )
            .subscribe(
              (response) => {
                // Swal.fire({
                //   title: 'Success',
                //   html: 'Approved Successfully',
                //   icon: 'success',
                //   timer: 3000,
                //   timerProgressBar: true,
                // });
                this.toastr.success('Approved Successfully','Success')
                const newPatientData: BatchFileUploadsDTO = response;
                this.getOldPatientDataFromAPI(
                  patientId,
                  newPatientData,
                  fileId
                );
              },
              (error) => {
                this.addButtonDisable = false;
                const data: HttpErrorResponse = error;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              }
            );
        }
      });
    }
  }
  //! get Old Patient Data From API

  getOldPatientDataFromAPI(
    patientId: string,
    batchData: BatchFileUploadsDTO,
    fileId: string
  ) {
    this.store.dispatch(new getPatient(patientId)).subscribe(
      (response) => {
        const patientData: PatientDTO = response.patientState.selectedPatient;
        this.checkifExistingPatientAndUpdateData(
          patientData,
          batchData,
          fileId,
          patientId
        );
      },
      (error) => {
        this.addButtonDisable = false;
        const data: HttpErrorResponse = error;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  //! check if Existing Patient And Update Data
  private checkifExistingPatientAndUpdateData(
    oldPatientData: PatientDTO,
    response: BatchFileUploadsDTO,
    fileId: string,
    patientId: string
  ) {
    // try {
    //   const newPatientData: PatientDTO | null =
    //     response?.listFileUploads?.filter((e) => e?.fileId === fileId)[0]
    //       ?.extractedData?.extractedfields;
    //   const modifiedPatientData: PatientDTO =
    //     {
    //       personals: {
    //         //! New Patient Extarction Data
    //         locationType:
    //           oldPatientData?.personals?.locationType ??
    //           this.branchListData?.[0]?.organizationUnitName,
    //         firstName: newPatientData?.personals?.firstName,
    //         dateOfBirth: newPatientData?.personals?.dateOfBirth,
    //         emailId: newPatientData?.personals?.emailId,

    //         //! Old Patient From Manaul Data

    //         lastName: oldPatientData?.personals?.lastName,
    //         middleName: oldPatientData?.personals?.middleName,
    //         suffix: oldPatientData?.personals?.suffix,
    //         homePhoneNo: oldPatientData?.personals?.homePhoneNo,
    //         workPhoneNo: oldPatientData?.personals?.workPhoneNo,
    //         ssn: oldPatientData?.personals?.ssn,
    //         accountNumber: oldPatientData?.personals?.accountNumber,
    //         customerType: oldPatientData?.personals?.customerType,
    //         priorSystemKeyFacility:
    //           oldPatientData?.personals?.priorSystemKeyFacility,
    //         alternateIdType: oldPatientData?.personals?.alternateIdType,
    //         facility: oldPatientData?.personals?.facility,
    //         holdAccount: oldPatientData?.personals?.holdAccount,
    //         holdBillingStatements:
    //           oldPatientData?.personals?.holdBillingStatements,
    //         hippaSignatureOnFile:
    //           oldPatientData?.personals?.hippaSignatureOnFile,
    //         discountPercentage: oldPatientData?.personals?.discountPercentage,
    //         taxZone: oldPatientData?.personals?.taxZone,
    //         branchOffice: oldPatientData?.personals?.branchOffice,
    //         accountGroupType: oldPatientData?.personals?.accountGroupType,
    //         ptSecurityGroupType: oldPatientData?.personals?.ptSecurityGroupType,
    //         user1: oldPatientData?.personals?.user1,
    //         user2: oldPatientData?.personals?.user2,
    //         user3: oldPatientData?.personals?.user3,
    //         user4: oldPatientData?.personals?.user4,
    //         placeOfService: oldPatientData?.personals?.placeOfService,
    //         dateOfAdmission: oldPatientData?.personals?.dateOfAdmission,
    //         dateOfDischarge: oldPatientData?.personals?.dateOfDischarge,
    //         auditTrialCreatedBy: oldPatientData?.personals?.auditTrialCreatedBy,
    //         auditTrialDateCreated:
    //           oldPatientData?.personals?.auditTrialDateCreated,
    //         colorCodingId: oldPatientData?.personals?.colorCodingId,
    //         catogeryId: oldPatientData?.personals?.catogeryId,
    //         providerId: oldPatientData?.personals?.providerId,
    //         patientBalance: oldPatientData?.personals?.patientBalance,
    //         insurnceBalance: oldPatientData?.personals?.insurnceBalance,
    //         totalBalance: oldPatientData?.personals?.totalBalance,
    //         patientAlerts: oldPatientData?.personals?.patientAlerts,
    //         statementTo: oldPatientData?.personals?.statementTo,
    //         isCollection: oldPatientData?.personals?.isCollection,
    //         isDoNotCall: oldPatientData?.personals?.isDoNotCall,
    //         isHoldClaims: oldPatientData?.personals?.isHoldClaims,
    //         holdNotes: oldPatientData?.personals?.holdNotes,
    //         holdReason: oldPatientData?.personals?.holdReason,
    //         autoDunningId: oldPatientData?.personals?.autoDunningId,
    //         isRecieveBill: oldPatientData?.personals?.isRecieveBill,
    //         isPatientInterest: oldPatientData?.personals?.isPatientInterest,
    //         isAutoDunning: oldPatientData?.personals?.isAutoDunning,
    //         fullName: oldPatientData?.personals?.fullName,
    //         isCashPayment: oldPatientData?.personals?.isCashPayment,
    //         isStat: oldPatientData?.personals?.isStat,
    //         gender: oldPatientData?.personals?.gender,
    //         maritalStatus: oldPatientData?.personals?.maritalStatus,
    //         ethinicity: oldPatientData?.personals?.ethinicity,
    //         race: oldPatientData?.personals?.race,
    //         authNotes: oldPatientData?.personals?.authNotes,
    //         authComments: oldPatientData?.personals?.authComments,
    //       },
    //       addresses: oldPatientData?.addresses,
    //       contacts: oldPatientData?.contacts,
    //       clinicals: oldPatientData?.clinicals,
    //       insurances: oldPatientData?.insurances,
    //       notes: oldPatientData?.notes,
    //       policies: oldPatientData?.policies,
    //       patientDocuments: oldPatientData?.patientDocuments,
    //       insuranceVerifications: oldPatientData?.insuranceVerifications,
    //       authorizations: oldPatientData?.authorizations,
    //       defaultPatientId: oldPatientData?.defaultPatientId,
    //       isAutoIntake: oldPatientData?.isAutoIntake,
    //       autoIntakeDocumentDetails: oldPatientData?.autoIntakeDocumentDetails,
    //       tenantId: oldPatientData?.tenantId,
    //       organizationUnitId: oldPatientData?.organizationUnitId,
    //       chartId: oldPatientData?.chartId,
    //       billingDetails: oldPatientData?.billingDetails,
    //       patientLock: oldPatientData?.patientLock,
    //       batchId: oldPatientData?.batchId,
    //        fileId: oldPatientData?.fileId,
    //     } ?? null;
    //   this.updatePatientWhenApproved(
    //     patientId,
    //     modifiedPatientData,
    //     response,
    //     fileId
    //   );
    // } catch (error) { }
  }

  //! Update Patient When Approved
  updatePatientWhenApproved(
    patientId: string,
    oldPatientData: PatientDTO,
    batchFileUploadsResponse: BatchFileUploadsDTO,
    fileId: string
  ) {
    let documentArrayToBeSaved: {
      patientDocumentBlobDTO: SavePatientDocumentBlobDTO;
      patientObjectID: string;
    }[] = [];
    // let newPatientData: PatientDTO = {
    //   personals: oldPatientData?.personals,
    //   addresses: oldPatientData?.addresses,
    //   contacts: oldPatientData?.contacts,
    //   clinicals: oldPatientData?.clinicals,
    //   insurances: oldPatientData?.insurances,
    //   notes: oldPatientData?.notes,
    //   policies: oldPatientData?.policies,
    //   patientDocuments: oldPatientData?.patientDocuments,
    //   insuranceVerifications: oldPatientData?.insuranceVerifications,
    //   authorizations: oldPatientData?.authorizations,
    //   defaultPatientId: oldPatientData?.defaultPatientId,
    //   chartId: oldPatientData?.chartId,
    //   isAutoIntake: oldPatientData?.isAutoIntake,
    //   autoIntakeDocumentDetails: oldPatientData?.autoIntakeDocumentDetails,
    //   sleepStudyReport: oldPatientData?.sleepStudyReport,
    //   complainceReport: oldPatientData?.complainceReport,
    //   tenantId: oldPatientData?.tenantId,
    //   organizationUnitId: oldPatientData?.organizationUnitId,
    //   billingDetails: oldPatientData?.billingDetails,
    //   patientLock: oldPatientData?.patientLock,
    //   // patientLockDetails: oldPatientData?.patientLockDetails,
    //   // // isSorted: oldPatientData?.isSorted,
    //   saleOrderId: oldPatientData?.saleOrderId,
    //   batchId: this.batchId,
    //   // fileId: [fileId],
    // }
    // this.store.dispatch(new updatePatient(patientId, newPatientData)).subscribe(
    //   (stateResponse) => {
    //     let patientSaved: PatientDTO =
    //       stateResponse?.patientState?.selectedPatient;
    //     setTimeout(() => {
    //       if (
    //         batchFileUploadsResponse != null &&
    //         batchFileUploadsResponse.listFileUploads.length !== 0
    //       ) {
    //         let sortedData: SortedDataDTO[] =
    //           batchFileUploadsResponse.listFileUploads.filter(
    //             (e) => e?.fileId === fileId
    //           )[0]?.sortedData;
    //         if (sortedData != null && sortedData.length !== 0) {
    //           sortedData.forEach((sortedDataResponse: SortedDataDTO) => {
    //             let fileContent: string;
    //             //get patient doc by blob name
    //             this.documentService
    //               .getPatientDocumentByBlobIDByBlobName(
    //                 sortedDataResponse?.blobName ?? ''
    //               )
    //               .subscribe((blobResponse) => {
    //                 if (typeof blobResponse === 'string' && blobResponse) {
    //                   fileContent = '' + blobResponse;
    //                   if (fileContent && blobResponse) {
    //                     let savePatientDocumentBlobDTO: SavePatientDocumentBlobDTO =
    //                     {
    //                       patientID: patientSaved?.id ?? '',
    //                       fileName: sortedDataResponse?.fileName ?? '',
    //                       fileContentAsBase64String: fileContent ?? '',
    //                       documentTypeId:
    //                         sortedDataResponse?.documentTypeId ?? '',
    //                       documentUploadType:
    //                         sortedDataResponse?.documentUploadType ?? '',
    //                       parentPatientDocumentID:
    //                         [patientSaved?.autoIntakeDocumentDetails[(patientSaved?.autoIntakeDocumentDetails.length - 1) | 0]?.autoIntakePatientDocumentId],
    //                       documentOrderNumber: 0,
    //                       documentDate:
    //                         batchFileUploadsResponse?.uploadedOn ?? '',
    //                       documentType: sortedDataResponse.documentType ?? '',
    //                       reviewStatus: '',
    //                       reviewReason: '',
    //                       note: '',
    //                       isVerified: 0,
    //                       fileSize: sortedDataResponse?.fileName ?? '',
    //                       isSorted: 1,
    //                     };
    //                     let documentToBeSaved = {
    //                       patientDocumentBlobDTO: savePatientDocumentBlobDTO,
    //                       patientObjectID: patientSaved?.id ?? '',
    //                     };
    //                     documentArrayToBeSaved.push(documentToBeSaved);
    //                     if (
    //                       documentArrayToBeSaved.length === sortedData.length
    //                     ) {
    //                       from(documentArrayToBeSaved)
    //                         .pipe(
    //                           groupBy((v) => v.patientObjectID),
    //                           mergeMap((group) => group.pipe(toArray())),
    //                           mergeMap((group) =>
    //                             from(group).pipe(
    //                               concatMap((v) =>
    //                                 defer(() =>
    //                                   this.documentsService.fileUploadByPatientDocumentBlobDTOAndPatientObjectID(
    //                                     v.patientDocumentBlobDTO,
    //                                     v.patientObjectID
    //                                   )
    //                                 )
    //                               )
    //                             )
    //                           ),
    //                           map((v) => {
    //                             // this.parentPatientDocumentID = "";
    //                             // this.getDocumentsByPatientId();
    //                           })
    //                         )
    //                         .subscribe((val) => {
    //                           this.dialog.closeAll();
    //                         });
    //                     }
    //                   }
    //                 }
    //               });
    //           });
    //         }
    //       }
    //     }, 3000);
    //     this.getbatchTableData();
    //     this.addButtonDisable = false;
    //   },
    //   (error) => {
    //     this.addButtonDisable = false;
    //     const data: HttpErrorResponse = error;
    //     Swal.fire({
    //       icon: 'error',
    //       text: data?.error?.error?.message,
    //     });
    //   }
    // );
  }

  //! Add New Patient When Approved
  addNewPatientWhenApproved(value: appprovalDetails) {
    this.isLoading = true;
    if (this.checkForBranchexisitAtleastOne()) {
      return;
    }
    this.addButtonDisable = true;

    let documentArrayToBeSaved: {
      patientDocumentBlobDTO: SavePatientDocumentBlobDTO;
      patientObjectID: string;
    }[] = [];

    // let newPatientData: PatientDTO = {
    //   personals: value?.extractedfields?.personals,
    //   addresses: value?.extractedfields?.addresses,
    //   contacts: value?.extractedfields?.contacts,
    //   clinicals: value?.extractedfields?.clinicals,
    //   insurances: value?.extractedfields?.insurances,
    //   notes: value?.extractedfields?.notes,
    //   policies: value?.extractedfields?.policies,
    //   patientDocuments: value?.extractedfields?.patientDocuments,
    //   insuranceVerifications: value?.extractedfields?.insuranceVerifications,
    //   authorizations: value?.extractedfields?.authorizations,
    //   defaultPatientId: value?.extractedfields?.defaultPatientId,
    //   chartId: value?.extractedfields?.chartId,
    //   isAutoIntake: value?.extractedfields?.isAutoIntake,
    //   autoIntakeDocumentDetails: value?.extractedfields?.autoIntakeDocumentDetails,
    //   sleepStudyReport: value?.extractedfields?.sleepStudyReport,
    //   complainceReport: value?.extractedfields?.complainceReport,
    //   tenantId: value?.extractedfields?.tenantId,
    //   organizationUnitId: value?.extractedfields?.organizationUnitId,
    //   billingDetails: value?.extractedfields?.billingDetails,
    //   patientLock: value?.extractedfields?.patientLock,
    //   // patientLockDetails: value?.extractedfields?.patientLockDetails,
    //   // isSorted: value?.extractedfields?.isSorted,
    //   saleOrderId: value?.extractedfields?.saleOrderId,
    //   batchId: this.batchId,
    //   // fileId: [value?.fileId],
    // }

    // this.store.dispatch(new addPatient(newPatientData)).subscribe(
    //   (stateResponse) => {
    //     let patientSaved: PatientDTO =
    //       stateResponse?.patientState?.selectedPatient;
    //     setTimeout(() => {
    //       patientSaved != null &&
    //         patientSaved != undefined &&
    //         this.fileUploadService
    //           .updateApprovedPatientIdByBatchIdAndFileIdAndPatientId(
    //             this.batchId,
    //             value?.fileId ?? '',
    //             patientSaved?.id ?? ''
    //           )
    //           .subscribe((batchFileUploadsResponse: BatchFileUploadsDTO) => {
    //             if (
    //               batchFileUploadsResponse != null &&
    //               batchFileUploadsResponse.listFileUploads.length !== 0
    //             ) {
    //               let sortedData: SortedDataDTO[] =
    //                 batchFileUploadsResponse.listFileUploads.filter(
    //                   (e) => e?.fileId === value?.fileId
    //                 )[0]?.sortedData ?? [];
    //               if (sortedData != null && sortedData.length !== 0) {
    //                 sortedData.forEach((sortedDataResponse: SortedDataDTO) => {
    //                   let fileContent: string;
    //                   //get patient doc by blob name
    //                   this.documentService
    //                     .getPatientDocumentByBlobIDByBlobName(
    //                       sortedDataResponse?.blobName ?? ''
    //                     )
    //                     .subscribe((blobResponse) => {
    //                       fileContent = '' + blobResponse;
    //                       if (fileContent && blobResponse) {
    //                         let savePatientDocumentBlobDTO: SavePatientDocumentBlobDTO =
    //                         {
    //                           patientID: patientSaved?.id ?? '',
    //                           fileName: sortedDataResponse?.fileName ?? '',
    //                           fileContentAsBase64String: fileContent ?? '',
    //                           documentTypeId:
    //                             sortedDataResponse?.documentTypeId ?? '',
    //                           documentType:
    //                             sortedDataResponse.documentType ?? '',
    //                           documentUploadType:
    //                             sortedDataResponse?.documentUploadType ?? '',

    //                           parentPatientDocumentID: [patientSaved?.autoIntakeDocumentDetails[(patientSaved?.autoIntakeDocumentDetails.length - 1) | 0]?.autoIntakePatientDocumentId],
    //                           documentOrderNumber: 0,
    //                           documentDate:
    //                             batchFileUploadsResponse?.uploadedOn ?? '',
    //                           reviewStatus: '',
    //                           reviewReason: '',
    //                           note: '',
    //                           isVerified: 0,
    //                           fileSize: sortedDataResponse?.fileName ?? '',
    //                           isSorted: 1,
    //                         };
    //                         let documentToBeSaved = {
    //                           patientDocumentBlobDTO:
    //                             savePatientDocumentBlobDTO,
    //                           patientObjectID: patientSaved?.id,
    //                         };
    //                         documentArrayToBeSaved.push(documentToBeSaved);
    //                         if (
    //                           documentArrayToBeSaved.length ===
    //                           sortedData.length
    //                         ) {
    //                           from(documentArrayToBeSaved)
    //                             .pipe(
    //                               groupBy((v) => v.patientObjectID),
    //                               mergeMap((group) => group.pipe(toArray())),
    //                               mergeMap((group) =>
    //                                 from(group).pipe(
    //                                   concatMap((v) =>
    //                                     defer(() =>
    //                                       this.documentsService.fileUploadByPatientDocumentBlobDTOAndPatientObjectID(
    //                                         v.patientDocumentBlobDTO,
    //                                         v.patientObjectID
    //                                       )
    //                                     )
    //                                   )
    //                                 )
    //                               ),
    //                               map((v) => { }), takeLast(1)
    //                             )
    //                             .subscribe((val) => {
    //                               this.isLoading = false;
    //                               Swal.fire({
    //                                 title: 'Success',
    //                                 html: 'Approved Successfully, Uploaded Document is merged and added these record in Existing Patient List',
    //                                 icon: 'success',
    //                                 // timer: 3000,
    //                                 timerProgressBar: true,

    //                               }).then(result => {
    //                                 this.router.navigate(['patientViewEdit/' + patientSaved.id])

    //                               })

    //                             },

    //                               (error) => {
    //                                 this.isLoading = false;
    //                                 this.addButtonDisable = false;
    //                                 const data: HttpErrorResponse = error;
    //                                 Swal.fire({
    //                                   icon: 'error',
    //                                   text: data?.error?.error?.message,
    //                                 });
    //                               });
    //                         }
    //                       }
    //                     });
    //                 });
    //               }
    //             }
    //             this.dialog.closeAll();
    //             this.getbatchTableData();
    //           });
    //     }, 3000);
    //   },
    //   (error) => {
    //     this.addButtonDisable = false;
    //     const data: HttpErrorResponse = error;
    //     Swal.fire({
    //       icon: 'error',
    //       text: data?.error?.error?.message,
    //     });
    //   }
    // );

    // from(documentArrayToBeSaved)
    //       .pipe(
    //          groupBy(v => v.patientObjectID),
    //          mergeMap(group => group.pipe(toArray())),
    //         mergeMap(group => from(group)
    //           .pipe(
    //             concatMap((v) => defer(() => this.documentsService.fileUploadByPatientDocumentBlobDTOAndPatientObjectID(v.patientDocumentBlobDTO, v.patientObjectID))))),
    //         takeLast(1),
    //         map(v => {
    //           // this.parentPatientDocumentID = "";

    //           // this.getDocumentsByPatientId();
    //         })
    //       ).subscribe(val => {
    //       });
  }
  //! Update Extracted Pateint Data through Modal
  updateExtractionPatientData(value: appprovalDetails) {
    if (value && value?.extractedfields && value?.fileId && this.batchId) {
      const dialogRef = this.dialog.open(ExtractionModalComponent, {
        data: {
          patientData: value?.extractedfields,
          batchId: this.batchId,
          fileId: value.fileId,
        },
        minHeight: '80%',
        minWidth: '80%',
      });

      dialogRef.afterClosed().subscribe(() => {
        this.getbatchTableData();
      });
    }
  }

  //! check for  Atleast One Branch
  checkForBranchexisitAtleastOne(): boolean {
    if (this.branchListData.length === 0) {
      const messageToDisplay =
        'Please Add Atleast One Branch in Admin Settings';
      Swal.fire({
        icon: 'info',
        text: messageToDisplay,
      });
      return true;
    }
    return false;
  }

  //! download AutoUpload Last Document
  downloadAutoUploadLastDocument(
    lastAutoDocumentBlob: AutoIntakeDocumentDetailsDTO
  ) {
    if (
      typeof lastAutoDocumentBlob?.blobName === 'string' &&
      lastAutoDocumentBlob?.blobName
    ) {
      this.documentService
        .getPatientDocumentByBlobIDByBlobName(lastAutoDocumentBlob.blobName)
        .subscribe(
          (response) => {
            if (typeof response === 'string' && response && response?.length !== 0) {
              let filelocation: String = String(
                'data:application/pdf;base64,' + response
              );
              let link = document.createElement('a');
              link.download = lastAutoDocumentBlob?.fileName + '.pdf';
              link.href = filelocation.toString();
              link.click();
            }
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          },
          () => { }
        );
    }
  }
  previewDocumentById(lastAutoDocumentBlob: AutoIntakeDocumentDetailsDTO) {
    lastAutoDocumentBlob?.blobName && lastAutoDocumentBlob && window.open(baseUrl + '#/ViewDocumentPerviewByBlobId/' + lastAutoDocumentBlob?.blobName + '/' + 1, "_blank");
  }
}

export interface appprovalDetails {
  defaultBatchId?: string;
  fileId?: string;
  uploadedFileName?: string;
  isStat?: boolean;
  blobName?: string;
  listIdentifiedPatients?: IdentifiedPatientsDTO[];
  extractedfields?: PatientDTO;
  extractedData?: {
    fullName?: string;
    dateOfBirth?: string;
    emailId?: string;
    lastDocumentBlobDetails?: AutoIntakeDocumentDetailsDTO;
  };
  status?: string;
}
export interface Patient {
  id?: string;
  Name?: string;
  PatientId?: string;
  Address?: string;
  SSN?: string;
  AccountNumber?: string;
  EmailAddress?: string;
  DateOfBirth?: string;
  Moblie?: string;
  isAutoIntake?: number;
  autoIntakeDocumentDetails?: AutoIntakeDocumentDetailsDTO | null;
  LockStatus?: string;
  LockStatusWithName?: string;
  UserId?: string;
  highlighted?: boolean;
  currStatus: string

}
