<div class="notifications">
  <div *ngFor="let reminder of reminders" class="notification" [ngClass]="className(reminder)">
    <ng-container *ngTemplateOutlet="remindersTpl;context:{reminder:reminder}"></ng-container>
  </div>
</div>

<ng-template #remindersTpl let-reminder="reminder">
  <div class="row title">
    <div class="col-10 mt-2">{{reminder?.titleMsg}}</div>
    <button mat-icon-button class="col-2" (click)="closeReminder(reminder)" matTooltip="Close the Reminder">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
  <div class="message">
    <div id="{{reminder?.localExpiryNotification?.defaultId}}" mat-line class="m-0 ">
      <span class="float-left">
        <span>
          {{ reminder?.localExpiryNotification?.defaultId }}
        </span>
        <span *ngIf="reminder?.localExpiryNotification?.name">-</span>
        <span>
          {{ reminder?.localExpiryNotification?.name }}
        </span>
      </span>
    </div>
    <br>
    <div mat-line>
      <span class="float-left">
        <span class="text-muted"> {{ reminder?.localExpiryNotification?.msg }}
        </span>
      </span>
    </div>
  </div>
</ng-template>