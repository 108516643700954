<br />
<div class="card card-body">
  <ul ngbNav #nav="ngbNav" justify="justified" class="alert alert-success nav nav-pills nav-fill">
    <li ngbNavItem="Branch" id="Branch">
      <a ngbNavLink class="nav-link" (click)="showTab(0)">Branch</a>
    </li>
    <li ngbNavItem="FAX/Mail" id="FAX/Mail">
      <a ngbNavLink class="nav-link" (click)="showTab(1)">FAX/Email</a>
    </li>
    <li ngbNavItem="FTP" id="FTP">
      <a ngbNavLink class="nav-link" (click)="showTab(2)">FTP</a>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
  <!-- Branch -->
  <div class="card card-body" [hidden]="!branchTab">
    <mat-accordion #firstAccordion="matAccordion">
      <!-- <mat-expansion-panel [expanded]="panelOpenState"> -->
      <mat-expansion-panel (toggle)="(panelOpenState)" [expanded]="panelOpenState" (afterExpand)="toggle()"
        (closed)="toggleV2()">
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <ng-container *ngIf="branchId===''; else elseTitleTemplate">
              <b>Add Branch</b>
            </ng-container>
            <ng-template #elseTitleTemplate>
              <b>Edit Branch</b>
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-lg-12">
            <form [formGroup]="BranchForm">
              <br />
              <mat-form-field class="col-3">
                <mat-label> Branch Name <span class="asterisk">*</span></mat-label>
                <!-- [class.disabled]="isAlreadyUsed" [readonly]="isAlreadyUsed" -->
                <input autocomplete="new-txtBranchName" matInput maxlength="40" formControlName="txtBranchName"
                  type="text"
                  oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]+/g, ''); this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);" />
                <mat-error
                  *ngIf="(BranchForm?.get('txtBranchName')?.touched &&
                          BranchForm?.get('txtBranchName')?.errors?.required)||BranchForm?.get('txtBranchName')?.errors ">
                  Branch Name is a required field!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3">
                <mat-label>Phone Number <span class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtPhoneNumber" matInput prefix="+1-" mask="(000)-000-0000" maxlength="40"
                  placeholder="Enter Phone Number" formControlName="txtPhoneNumber" type="text">
                <mat-error *ngIf="
                              BranchForm?.get('txtPhoneNumber')?.touched &&
                              BranchForm?.get('txtPhoneNumber')?.errors
                                ">
                  Enter Minimum 10 Digits!
                </mat-error>
                <mat-error *ngIf="BranchForm?.get('txtPhoneNumber')?.touched &&
                          BranchForm?.get('txtPhoneNumber')?.errors?.required ">
                  <!-- Phone Number is a required field! -->
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label>Address -1 <span class="asterisk">*</span> </mat-label>
                <input autocomplete="new-txtAddress" maxlength="100" formControlName="txtAddress" rows="3" matInput
                  oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');">
                <mat-error *ngIf="(BranchForm?.get('txtAddress')?.touched &&
                          BranchForm?.get('txtAddress')?.errors?.required)||BranchForm?.get('txtAddress')?.errors ">
                  Address -1 is a required field!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label>Address -2</mat-label>
                <input autocomplete="new-txtAddress2" maxlength="100" formControlName="txtAddress2" rows="3" matInput
                oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1); this.value = this.value.replace('^[a-zA-Z0-9_-]+(?:\s+[a-zA-Z0-9_-]+)*$', '').replace(/(\..*?)\..*/g, '$1').replace(/^(.*\.\d\d).*$|(\..*)$/g, '$1$2');">
              </mat-form-field>


              <mat-form-field class="col-3 readonly" [hidden]="hidecountry">
                <mat-label>Country <span class="asterisk">*</span></mat-label>
                <mat-select formControlName="drpCountry" (selectionChange)='onChangeState($event.value)'
                  [(value)]="Country">
                  <mat-option *ngFor="let country of drpCountry" [value]="country.countryShortName">
                    {{ country.countryName }}
                  </mat-option>
                </mat-select>
                <button mat-button (click)="Country='';$event.stopPropagation()" *ngIf="Country" matSuffix
                  mat-icon-button>
                </button>
                <mat-error *ngIf="BranchForm?.get('drpCountry')?.touched &&
                          BranchForm?.get('drpCountry')?.errors?.required ">
                  Country is a required field!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label>State <span class="asterisk">*</span></mat-label>
                <mat-select formControlName="drpState" (selectionChange)='onChangeCity($event.value,"")'
                  [(value)]="State" #singleSelect>
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                      formControlName="drpStateFilter">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let state of filteredStates | async" [value]="state.stateShortName">
                    {{ state.stateName }}
                  </mat-option>
                </mat-select>
                <!-- <button mat-button (click)="State='';$event.stopPropagation()" *ngIf="State" (click)="clearState()"
                matSuffix mat-icon-button>
                <mat-icon>close</mat-icon>
              </button> -->
                <mat-error *ngIf="BranchForm?.get('drpState')?.touched &&
                          BranchForm?.get('drpState')?.errors?.required ">
                  State is a required field!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label>City <span class="asterisk">*</span></mat-label>
                <mat-select formControlName="drpCity" [(value)]="City" #singleSelect>
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                      formControlName="drpCityFilter">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let city of filteredCities | async" [value]="city.cityName">
                    {{ city.cityName }}
                  </mat-option>
                </mat-select>
                <!-- <button mat-button (click)="City='';$event.stopPropagation()" *ngIf="City" matSuffix (click)="clearCity()"
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button> -->
                <mat-error *ngIf="BranchForm?.get('drpCity')?.touched &&
                          BranchForm?.get('drpCity')?.errors?.required ">
                  City is a required field!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label> Zip Code <span class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtPostalCode" matInput maxlength="10" formControlName="txtPostalCode"
                  pattern="[0-9-]*" type="text" class="zip-code-input" />
                <mat-error
                  *ngIf=" BranchForm?.get('txtPostalCode')?.touched && BranchForm?.get('txtPostalCode')?.errors?.pattern">
                  Enter only Numbers!
                </mat-error>
                <mat-error *ngIf="BranchForm?.get('txtPostalCode')?.touched &&
                          BranchForm?.get('txtPostalCode')?.errors?.required ">
                  <!-- ZipCode is a required field! -->
                </mat-error>
              </mat-form-field>
              <!-- [class.disabled]="isAlreadyUsed" -->
                  <!-- [readonly]="isAlreadyUsed" -->
              <mat-form-field class="col-3">
                <mat-label>Chart Suffix<span class="asterisk">*</span> </mat-label>
                <input autocomplete="new-txtChartSuffix" maxlength="3" oninput="this.value = this.value.toUpperCase()"
                  formControlName="txtChartSuffix" pattern="[A-Za-z]*" matInput >
                <mat-error
                  *ngIf="(BranchForm?.get('txtChartSuffix')?.touched &&
                          BranchForm?.get('txtChartSuffix')?.errors?.required)||BranchForm?.get('txtChartSuffix')?.errors ">
                  <!-- Chart Suffix is a required field! -->
                </mat-error>
                <mat-error
                  *ngIf=" BranchForm?.get('txtChartSuffix')?.touched && BranchForm?.get('txtChartSuffix')?.errors?.pattern">
                  Enter only characters!
                </mat-error>
                <mat-error *ngIf="BranchForm?.get('txtChartSuffix')?.errors?.isChartSuffixExists">
                  Chart Suffix is already taken!
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-3">
                <mat-label>Max Inventory Limit($) <span class="asterisk">*</span> </mat-label>
                <input autocomplete="new-txtMaxInventoryLimit" step="1" min="1" type="number" maxlength="10"
                  pattern="^\d{1,10}(\.\d{1,2})?$" formControlName="txtMaxInventoryLimit" matInput
                  (keydown.ArrowUp)="$event.preventDefault()" (keydown.ArrowDown)="$event.preventDefault()">
                <mat-error *ngIf="BranchForm?.get('txtMaxInventoryLimit')?.touched &&
                          BranchForm?.get('txtMaxInventoryLimit')?.errors?.required">
                  Max Inventory Limit is a required field!
                </mat-error>
                <mat-error
                  *ngIf="(BranchForm?.get('txtMaxInventoryLimit')?.touched &&
                          BranchForm?.get('txtMaxInventoryLimit')?.errors?.pattern)||
                          BranchForm?.get('txtMaxInventoryLimit')?.value?.length > 10||
                          (BranchForm?.get('txtMaxInventoryLimit')?.value===0||BranchForm?.get('txtMaxInventoryLimit')?.value>9999999999.99)">
                  Enter only ten digits or enter two digits only after the decimal!
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-3" >
                <!-- [class.disabled]="isAlreadyUsed" [readonly]="isAlreadyUsed" -->
                <mat-label> Fax Queue Name <span *ngIf="BranchForm?.get('chkFaxQueue')?.value===true" class="asterisk">*</span></mat-label>
                <input autocomplete="new-txtFaxQueueName" matInput maxlength="20" formControlName="txtFaxQueueName"
                  type="text"
                  oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]+/g, ''); this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1);" />
                <mat-error
                  *ngIf="(BranchForm?.get('txtFaxQueueName')?.touched &&
                          BranchForm?.get('txtFaxQueueName')?.errors?.required)||BranchForm?.get('txtFaxQueueName')?.errors ">
                  Fax Queue Name is a required field!
                </mat-error>
              </mat-form-field>
              <!-- [class.disabled]="isAlreadyUsed" -->
              <mat-checkbox class="paddingCls" (change)="faxqueueEvent($event)" formControlName="chkFaxQueue">Fax Queue
              </mat-checkbox>

              <mat-checkbox class="paddingCls" formControlName="chkisAppoinment"> Schedule Appointment
              </mat-checkbox>
              <!--
            <mat-form-field class="col-3">
              <mat-label>NPI Id<span class="asterisk">*</span> </mat-label>
              <input autocomplete="new-txtNPI" type="text" formControlName="txtNPI" pattern="[0-9\.]*" matInput  maxlength="10">
              <mat-error *ngIf="BranchForm?.get('txtNPI')?.touched &&
                          BranchForm?.get('txtNPI')?.errors?.required ">
                NPI   is a required field!
              </mat-error>
              <mat-error *ngIf="BranchForm?.get('txtNPI')?.touched &&
              BranchForm?.get('txtNPI')?.errors?.pattern ">
              Enter only Numbers!
             </mat-error>
            </mat-form-field> -->

              <!-- <mat-form-field class="col-3">
              <mat-label>Tax Id <span class="asterisk">*</span> </mat-label>
              <input autocomplete="new-txtTaxId" type="text" formControlName="txtTaxId" pattern="[a-zA-Z0-9]+" matInput>
              <mat-error *ngIf="BranchForm?.get('txtTaxId')?.touched &&
                              BranchForm?.get('txtTaxId')?.errors?.required ">
                Tax Id is a required field!
              </mat-error>
            </mat-form-field> -->



              <!-- <mat-form-field class="col-3">
              <mat-label>Fax Path </mat-label>
              <input autocomplete="new-txtFaxPath"  formControlName="txtFaxPath" matInput>
              <mat-error *ngIf="BranchForm?.get('txtFaxPath')?.touched &&
                          BranchForm?.get('txtFaxPath')?.errors?.required ">
                  Fax Path  is a required field!
              </mat-error>
            </mat-form-field> -->

              <!-- <mat-form-field class="col-3">
              <mat-label>Fax Queue Name</mat-label>
              <input autocomplete="new-txtFaxQueueName"  formControlName="txtFaxQueueName" matInput>
              <mat-error *ngIf="BranchForm?.get('txtFaxQueueName')?.touched &&
                          BranchForm?.get('txtFaxQueueName')?.errors?.required ">
                  Fax Queue Name  is a required field!
              </mat-error>
            </mat-form-field> -->

            </form>
            <br />
            <div class="row">
              <div class="col-sm-12">
                <div class="text-lg-center">
                  <!-- <button mat-button class="buttonColor mr-2" *ngIf="branchId===''" (click)="openAddWorkingModel()">
                  Add Branch hours</button> -->
                  <button *ngIf="inActiveHide"
                    [disabled]="saveBranchButtonHide||BranchForm.invalid||(BranchForm?.get('txtMaxInventoryLimit')?.value===0||BranchForm?.get('txtMaxInventoryLimit')?.value>9999999999.99)"
                    mat-button class="buttonColor mr-2" (click)="saveBranch()">
                    Save
                    <mat-icon *ngIf='isShowSpinner'>
                      <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                    </mat-icon>
                  </button>
                  <!-- <button (click)="confirmBranchLocation()"*ngIf="branchId===''" mat-button class="buttonColor mb-2 mr-2">
                  [disabled]="!(!BranchForm.invalid)"
                  Confirm location

                </button> -->
                  <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="reset()">Reset</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12" *ngIf="isConfirmBranchLocation " style="margin-top: 10px;">
            <h2>Confirm {{branchName}} branch location</h2>
            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="17" [disableDefaultUI]="false"
              [zoomControl]="true" (mapClick)="mapClicked($event)" [usePanning]='true'>

              <agm-marker [latitude]="latitude" [longitude]="longitude">
                <agm-info-window>
                  <strong>InfoWindow content</strong>
                </agm-info-window>
              </agm-marker>
            </agm-map>

            <div class="row float-right mt-3">
              <div class="col-11 customThemeClass mb-2">
                <button mat-button class="buttonColor" [disabled]="saveButtonHide||BranchForm.invalid"
                  (click)="saveBranch()">
                  Confirm location
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="card card-body mt-3">
      <!-- <div class="radio-buttons"> -->
      <div class="d-flex flex-row-reverse">
      <mat-radio-group [(ngModel)]="selectedOption">
        <mat-radio-button class="active" (click)="branchStatusFilterV1(0)" value="All"><b>All</b></mat-radio-button>
        <mat-radio-button class="active p-2" (click)="branchStatusFilterV1(1)" value="Active"><b>Active</b></mat-radio-button>
        <mat-radio-button class="active" (click)="branchStatusFilterV1(2)" value="InActive"><b>Inactive</b></mat-radio-button>
      </mat-radio-group>
      </div>
      <br />
      <div class="d-flex justify-content-center" *ngIf="isLoading">
        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
      </div>
      <form [formGroup]="ontimeBranchForm">
        <div class="col-lg-12 table table-responsive p-0" *ngIf="!isLoading">
          <!-- <table mat-table class="table ml-4"style=" margin-left: 3px !important; border: 1px solid #cdc7c7" matSort [dataSource]="dataSource"> -->
          <table id="tblBranch" datatable [dtOptions]="dtBranchListOptions"
            class="tbl hover row-border hover w-100 display">
            <thead>
              <tr>
                <!-- <th class="text-center">#</th> -->
                <th><mat-icon>apps</mat-icon></th>
                <th [matTooltip]="'Status'">Status</th>
                <th [matTooltip]="'Branch Name'">Branch Name</th>
                <!-- <th class="text-center">NPI Id </th> -->
                <th class="text-right" [matTooltip]="'Inventory Limit ($)'">Inventory Limit ($)</th>
                <th class="text-right" [matTooltip]="'Phone Number'">Phone Number</th>
                <th [matTooltip]="'Fax Queue Name'">Fax Queue Name</th>
                <th [matTooltip]="'Fax Queue'">Fax Queue </th>
                <th [matTooltip]="'Schedule Appointment'">Schedule Appointment </th>
                <th [matTooltip]="'Address 1'">Address 1</th>
                <th [matTooltip]="'Address 2'">Address 2</th>
                <th [matTooltip]="'City'">City</th>
                <th [matTooltip]="'State'">State</th>
                <th class="text-right" [matTooltip]="'Zip Code'">Zip Code</th>
                <th [matTooltip]="'Chart Suffix'">Chart Suffix</th>
                <th [matTooltip]="'Full Address'">Full Address</th>
                <th [matTooltip]="'Created By'">Created By</th>
                <th class="text-right" [matTooltip]="'Creation Time'">Creation Time</th>
                <th [matTooltip]="'Modify By'">Modify By</th>
                <th class="text-right" [matTooltip]="'Modified Date'">Modified Date</th>
              </tr>
            </thead>
            <tbody *ngIf="!searchLoading">
              <tr *ngFor="let data of branchDetails" [ngClass]="{stat_highlight:data.isStat == true}">
                <!-- <td class="dt-control text-center" (click)="handleTableCellClick($event, data)"></td> -->
                <td>
                  <a class="eyeCursorclass" target="_blank" [matTooltip]="'Edit Branch'" [ngClass]="{'disabled-link': data?.isDefault}"><i
                      *ngIf="data?.status==='Active'" (click)="ViewClient(data)"
                      class="fa fa-pencil editBtnColor mr-1"></i>
                  </a>
                </td>
                <td>
                  <ng-container *ngIf="data?.isAlreadyInUse==false && data?.status=='Active'">
                    <mat-slide-toggle (change)="statusChange($event, data)" [checked]='true' class="ml-2">
                     <span [matTooltip]="data?.status"> {{data?.status}} </span></mat-slide-toggle>
                  </ng-container>
                  <ng-container *ngIf="data?.isAlreadyInUse==false && data?.status=='Inactive'">
                    <mat-slide-toggle (change)="statusChange($event, data)" [checked]='false' class="ml-2">
                      <span [matTooltip]="data?.status"> {{data?.status}} </span></mat-slide-toggle>
                  </ng-container>
                  <ng-container *ngIf="data?.isAlreadyInUse==true">
                    <span [matTooltip]="'In Use'">In Use</span>
                  </ng-container>
                </td>
                <td [matTooltip]="data.organizationUnitName || '-'"
                  (dblclick)="onRowDoubleClick(data?.organizationUnitName || '-')">{{ data.organizationUnitName || '-' }}</td>
                <td class="text-right" [matTooltip]="data.maxInventoryValue || '-'"
                  (dblclick)="onRowDoubleClick(data?.maxInventoryValue || '-')"> {{data.maxInventoryValue || '-'}}</td>
                <td class="text-right" [matTooltip]="data.phoneNumber || '-'" (dblclick)="onRowDoubleClick(data?.phoneNumber || '-')">
                 <span *ngIf="(data.phoneNumber != '' && data.phoneNumber != null && data.phoneNumber != 'string')">+1-</span>
                  {{(data.phoneNumber == '' || data.phoneNumber == null||data.phoneNumber == 'string') ? "-" : data.phoneNumber}}
                </td>
                <td [matTooltip]="data.faxQueueName || '-'" (dblclick)="onRowDoubleClick(data?.faxQueueName || '-')">
                {{(data.faxQueueName == '' || data.faxQueueName == null) ? "-" : data.faxQueueName}}
                 </td>
                 <td [matTooltip]="data.faxQueue || '-'" (dblclick)="onRowDoubleClick(data?.faxQueue || '-')">
                   {{(data.faxQueue == '' || data.faxQueue == null) ? "-" : data.faxQueue}}
                 </td>
                 <td [matTooltip]="data.bScheduleAppointment || 'False'" (dblclick)="onRowDoubleClick(data?.bScheduleAppointment || 'False')">
                  {{(data.bScheduleAppointment == '' || data.bScheduleAppointment == null) ? "False" : data.bScheduleAppointment}}
                </td>
                <td [matTooltip]="data.address1 || '-'" (dblclick)="onRowDoubleClick(data?.address1 || '-')">
                   {{(data.address1 == '' || data.address1 == null) ? "-" : data.address1}}
                 </td>
                 <td [matTooltip]="data.address2 || '-'" (dblclick)="onRowDoubleClick(data?.address2 || '-')">
                  {{(data.address2 == '' || data.address2 == null) ? "-" : data.address2}}
                </td>
                <td [matTooltip]="data.city || '-'" (dblclick)="onRowDoubleClick(data?.city || '-')">
                  {{(data.city == '' || data.city == null) ? "-" : data.city}}
                </td>
                <td [matTooltip]="data.state || '-'" (dblclick)="onRowDoubleClick(data?.state || '-')">
                  {{(data.state == '' || data.state == null) ? "-" : data.state}}
                </td>
                <td class="text-right" [matTooltip]="data.zipcode || '-'" (dblclick)="onRowDoubleClick(data?.zipcode || '-')">
                  {{(data.zipcode == '' || data.zipcode == null) ? "-" : data.zipcode}}
                </td>
                <td [matTooltip]="data.chartSuffix || '-'" (dblclick)="onRowDoubleClick(data?.chartSuffix || '-')">
                  {{(data.chartSuffix == '' || data.chartSuffix == null) ? "-" : data.chartSuffix}}
                </td>
                <td [matTooltip]="data.fullAddress || '-'" (dblclick)="onRowDoubleClick(data?.fullAddress || '-')">
                  {{(data.fullAddress == '' || data.fullAddress == null) ? "-" : data.fullAddress}}
                </td>
                <td [matTooltip]="data.createdBy || '-'" (dblclick)="onRowDoubleClick(data?.createdBy || '-')">
                  {{(data.createdBy == '' || data.createdBy == null) ? "-" : data.createdBy}}
                </td>
                <!-- <td >- </td> -->
                <!-- <td class="text-center">{{ data?.lastModificationTime?(data?.lastModificationTime | date : "MM/dd/yyyy"
                  :
                  "en_US"):'_' }}
                </td> -->
                <td [matTooltip]="data?.creationTime"
                (dblclick)="onRowDoubleClick(data?.creationTime)" class="text-right">
                {{ data?.creationTime }}
              </td>
              <td [matTooltip]="data.modifiedBy || '-'" (dblclick)="onRowDoubleClick(data?.modifiedBy || '-')">
                {{(data.modifiedBy == '' || data.modifiedBy == null) ? "-" : data.modifiedBy}}
              </td>
                <td [matTooltip]="data?.lastModificationTime"
                  (dblclick)="onRowDoubleClick(data?.lastModificationTime)" class="text-right">
                  {{ data?.lastModificationTime }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><mat-icon>apps</mat-icon></th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Status"
                    name="search-Status" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Branch Name" name="search-BranchName" />
                </th>
                <th class="text-right">
                  <input matInput class="textbox-border-class" type="text" placeholder="Inventory Limit ($)"
                    name="search-InventoryLimit($)" />
                </th>
                <th class="text-right">
                  <input matInput type="text" class="textbox-border-class" placeholder="Phone Number" name="search-PhoneNumber" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Fax Queue Name" name="search-FaxQueueName" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Fax Queue" name="search-FaxQueue" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Schedule Appointment " name="search-appointmentScheduling" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Address 1" name="search-Address1" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Address 2" name="search-Address2" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="City" name="search-City" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="State" name="search-State" />
                </th>
                <th class="text-right">
                  <input matInput type="text" class="textbox-border-class" placeholder="Zip Code" name="search-Zip Code" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Chart Suffix" name="search-Chart Suffix" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Full Address" name="search-Full Address" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="CreatedBy"
                    name="search-CreatedBy" />
                </th>
                <th class="text-right">
                  <input matInput type="text" class="textbox-border-class" placeholder="creationTime"
                    name="search-creationTime" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="ModifyBy"
                    name="search-ModifyBy" />
                </th>
                <th class="text-right">
                  <input matInput type="text" class="textbox-border-class" placeholder="Created/Modified Date"
                    name="search-Created/Modified Date" />
                </th>

              </tr>
            </tfoot>
          </table>
        </div>
      </form>
    </div>
  </div>
  <br />
  <!-- FAX/Mail -->
  <div class="card card-body" [hidden]="!FaxTab">
  <div class="d-flex justify-content-center" *ngIf="isLoading">
        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
  </div>
    <app-fax-email-table [clientId]="clientId" [tableFax]="faxDetails" [faxStatusView]="faxStatus" *ngIf="!isLoading"></app-fax-email-table>
    <!-- <mat-accordion #secondAccordion="matAccordion">
      <mat-expansion-panel [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title class="customThemeClass">
            <ng-container *ngIf="faxid===''; else elseTitleTemplate1">
              <b>Add Fax/Email</b>
            </ng-container>
            <ng-template #elseTitleTemplate1>
              <b>Edit Fax/Email</b>
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="FaxForm">
          <div class="row">

            <mat-form-field class="col-3">
              <mat-label>Branch<span class="asterisk">*</span></mat-label>
              <mat-select #selectBranch formControlName="drpBranchType" [(ngModel)]="seletedBranchValue">
                <mat-option *ngFor="let branch of filteredBranchTypeList" [value]="branch.id">
                  {{ branch.organizationUnitName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Fax/Email<span class="asterisk">*</span></mat-label>
              <mat-select #selectBranch formControlName="drpFaxType" [(ngModel)]="seletedFaxValue"
                (selectionChange)="onReferenceChange()">
                <mat-option [value]=0>Email</mat-option>
                <mat-option [value]=1>Fax</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-3" [hidden]="seletedFaxValue !== 1">
              <mat-label>Fax Number <span class="asterisk">*</span></mat-label>
              <input formControlName="txtFaxNumber" matInput maxlength="40" prefix="+1-" mask="(000)-000-0000"
                type="text" autocomplete="new-txtFaxNumber" />
              <mat-error
                *ngIf="FaxForm?.get('txtFaxNumber')?.touched && FaxForm?.get('txtFaxNumber')?.errors?.required">
                Enter Minimum 10 Digits!
              </mat-error>
              <mat-error *ngIf="FaxForm?.get('txtFaxNumber')?.errors?.isFaxNumberExists">
                Fax Number is already taken!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3" [hidden]="seletedFaxValue !== 1">
              <mat-label>Fax Name <span class="asterisk">*</span></mat-label>

              <input autocomplete="new-txtFaxName" matInput maxlength="40" formControlName="txtFaxName" type="text"
                pattern="[a-zA-Z 0-9]*" />
              <mat-error *ngIf="FaxForm?.get('txtFaxName')?.touched && FaxForm?.get('txtFaxName')?.errors?.required">
                Enter Fax Name!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Email Id <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtEmail" matInput maxlength="40" formControlName="txtEmail" type="text"
                pattern="[a-z0-9._!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                [textMask]="{ mask: emailMask }" />

              <mat-error *ngIf="FaxForm?.get('txtEmail')?.touched &&
              FaxForm?.get('txtEmail')?.errors?.required ">
                Email Address is a required field!
              </mat-error>
              <mat-error *ngIf="
                            FaxForm?.get('txtEmail')?.touched &&
                            FaxForm?.get('txtEmail')?.errors?.pattern
                            ">
                Please Enter a Valid Email Address
              </mat-error>
              <mat-error *ngIf="FaxForm?.get('txtEmail')?.errors?.isEmailAddressExists">
                Email is already taken!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Email Password <span class="asterisk">*</span></mat-label>
              <input matInput maxlength="40" [type]="hide ? 'password' : 'text'" formControlName="txtPassword"
                autocomplete="new-password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[$@$#!%*?^()&])(?=.*[A-Z]).{8,}"
                type="text" />
              <a class="font-size-20" mat-icon-button matSuffix (click)="hide = !hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </a>
              <mat-error *ngIf="
                    FaxForm?.get('txtPassword')?.touched &&
                    FaxForm?.get('txtPassword')?.errors?.required
                    ">
                Password is a required field!
              </mat-error>

              <mat-error *ngIf="
                    FaxForm?.get('txtPassword')?.touched &&
                    FaxForm?.get('txtPassword')?.errors?.pattern
                  ">
                Passwords must contain at least one non alphanumeric character,one uppercase,one digit
                and at
                least 8 or more characters
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Port Number <span class="asterisk">*</span></mat-label>
              <input formControlName="txtEmailPort" matInput maxlength="5" pattern="[0-9]*" type="text"
                autocomplete="new-txtEmailPort" />
              <mat-error
                *ngIf="FaxForm?.get('txtEmailPort')?.touched && FaxForm?.get('txtEmailPort')?.errors?.required">
                Port Number is a required field!
              </mat-error>
              <mat-error
                *ngIf="FaxForm?.get('txtEmailPort')?.touched && FaxForm?.get('txtEmailPort')?.errors?.required && FaxForm?.get('txtEmailPort')?.errors?.pattern">
                Enter only Numbers
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label> Host Name <span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtEmailHost" matInput maxlength="50" formControlName="txtEmailHost"
                type="text" />
              <mat-error
                *ngIf="FaxForm?.get('txtEmailHost')?.touched && FaxForm?.get('txtEmailHost')?.errors?.required">
                Host Name is a required field!
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-3">
              <mat-label>Server Protocol <span class="asterisk">*</span></mat-label>

              <input autocomplete="new-txtEmailProtocol" matInput maxlength="50" formControlName="txtEmailProtocol"
                type="text" pattern="[A-Za-z0-9\.]*" />
              <mat-error
                *ngIf="FaxForm?.get('txtEmailProtocol')?.touched && FaxForm?.get('txtEmailProtocol')?.errors?.required">
                Server Protocol a required field!
              </mat-error>
            </mat-form-field>

          </div>
        </form>
        <br />
        <div class="row">
          <div class="col-sm-12">
            <div class="text-lg-center">
              <button *ngIf="inActiveHide" [disabled]="saveButtonHide||FaxForm.invalid ||(seletedFaxValue===1&&FaxForm.get('txtFaxNumber').value==='')||(seletedFaxValue===1&&FaxForm.get('txtFaxName').value==='')
            ||(seletedFaxValue===1&&FaxForm.get('txtFaxName').value===null)" mat-button class="buttonColor mr-2"
                (click)="saveFax()">
                Save
                <mat-icon *ngIf='isShowSpinner'>
                  <mat-spinner class="spinner-border spinner-border-sm" diameter="20"> </mat-spinner>
                </mat-icon>
              </button>

              <button mat-button class="resetclr buttonColor mb-2 mr-2" (click)="faxReset()">Reset</button>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="card card-body mt-3">
      <mat-radio-group [(ngModel)]="selectedOption" class="radio-buttons">
        <mat-radio-button class="Inactive" (click)="FaxStatusFilter(0)" value="All"><b>All</b></mat-radio-button>
        <mat-radio-button class="Inactive p-2" (click)="FaxStatusFilter(1)" value="Active"
          [checked]="true"><b>Active</b></mat-radio-button>
        <mat-radio-button class="Inactive" (click)="FaxStatusFilter(2)"
          value="InActive"><b>Inactive</b></mat-radio-button>
      </mat-radio-group>
      <br />
      <div class="d-flex justify-content-center" *ngIf="isLoading">
        <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
      </div>
      <form [formGroup]="ontimeBranchForm">
        <div class="col-lg-12 content table-responsive p-0">
          <table id="tbl" datatable [dtOptions]="dtFaxEmailListOptions" *ngIf="!isLoading"
            class="tbl hover row-border hover w-100 display">
            <thead>
              <tr>
                <th><mat-icon>apps</mat-icon></th>
                <th [matTooltip]="'Branch Name'">Branch Name</th>
                <th [matTooltip]="'Fax Name'">Fax Name</th>
                <th [matTooltip]="'Fax Number'">Fax Number</th>
                <th [matTooltip]="'Email'">Email</th>
                <th [matTooltip]="'Port Number'">Port Number</th>
                <th [matTooltip]="'Server Protocol'">Server Protocol</th>
                <th [matTooltip]="'Host Name'">Host Name</th>
                <th [matTooltip]="'Created/Modified Date'">Created/Modified Date</th>
                <th [matTooltip]="'isFax/Email'">isFax/Email</th>
                <th [matTooltip]="'Status'">Status</th>

              </tr>
            </thead>
            <tbody *ngIf="!searchLoading">
              <tr *ngFor="let fax of faxDetails" [ngClass]="{stat_highlight:fax.isStat == true}">
                <td>
                  <a class="eyeCursorclass" [matTooltip]="'Edit Fax/Email'"><i *ngIf="fax?.isActive===1"
                      (click)="ViewFax(fax)" class="fa fa-pencil editBtnColor mr-1"></i>
                  </a>
                </td>
                <td [matTooltip]="fax.branchName || '-'" (dblclick)="onRowDoubleClick(fax?.branchName || '-')">{{
                  getOrganizationUnitName(fax.branchId,fax.branchName) }}</td>
                <td [matTooltip]="fax.faxName || '-'" (dblclick)="onRowDoubleClick(fax?.faxName || '-')"> {{ fax.faxName
                  == null || fax.faxName == "" ? '-' : fax.faxName }}</td>
                <td [matTooltip]="fax.faxNumber || '-'" (dblclick)="onRowDoubleClick(fax?.faxNumber || '-')"> {{
                  fax.faxNumber == null || fax.faxNumber == "" ? '-' : fax.faxNumber }}</td>
                <td [matTooltip]="fax.mailId || '-'" (dblclick)="onRowDoubleClick(fax?.mailId || '-')">{{ fax.mailId ==
                  null || fax.mailId == "" ? '-' :fax.mailId}}</td>
                <td [matTooltip]="fax.portNumber || '-'" (dblclick)="onRowDoubleClick(fax?.portNumber || '-')">
                  {{fax.portNumber== null || fax.portNumber == "" ? '-' :fax.portNumber}}</td>
                <td [matTooltip]="fax.serverProtocol || '-'" (dblclick)="onRowDoubleClick(fax?.serverProtocol || '-')">
                  {{fax.serverProtocol== null ? '-' :fax.serverProtocol}}</td>
                <td [matTooltip]="fax.hostName || '-'" (dblclick)="onRowDoubleClick(fax?.hostName || '-')">
                  {{fax.hostName== null || fax.hostName == "" ? '-' :fax.hostName}}</td>
                <td [matTooltip]="getFormattedDate(fax?.lastModificationTime)"
                  (dblclick)="onRowDoubleClick(getFormattedDate(fax?.lastModificationTime))" class="text-center">
                  {{ getFormattedDate(fax?.lastModificationTime) }}
                </td>
                <td [matTooltip]="fax?.isFax ? 'Fax' : 'Email' || '-'" (dblclick)="onRowDoubleClick(fax?.isFax ? 'Fax' : 'Email')">{{ fax.isFax === 1 ? 'Fax' : 'Email' }}</td>
                <td [matTooltip]="fax?.isActive ? 'Active' : 'Inactive' || '-'">
                  <ng-container *ngIf="fax?.isActive===1">
                    <mat-slide-toggle (change)="faxStatusChange($event, fax)" [checked]='true' class="ml-2">
                    </mat-slide-toggle>
                  </ng-container>
                  <ng-container *ngIf="fax?.isActive===0">
                    <mat-slide-toggle (change)="faxStatusChange($event, fax)" [checked]='false' class="ml-2">
                    </mat-slide-toggle>
                  </ng-container>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th><mat-icon>apps</mat-icon></th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Branch Name" name="search-BranchName" />
                </th>
                <th>
                  <input matInput class="textbox-border-class" type="text" placeholder="Fax Name"
                    name="search-FaxName" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Fax Number" name="search-FaxNumber" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Email"
                    name="search-Email" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Port Number"
                    name="search-PortNumber" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Server Protocol"
                    name="search-ServerProtocol" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Host Name"
                    name="search-HostName" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Created/Modified Date"
                    name="search-Created/Modified Date" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="isFax/Email"
                    name="search-isFax/Email" />
                </th>
                <th>
                  <input matInput type="text" class="textbox-border-class" placeholder="Status"
                    name="search-Status" />
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </form>
    </div>
  </div> -->
  </div>
  <br />
  <!-- FTP -->
  <div class="card card-body" [hidden]="!FTPTab">
    <div class="d-flex justify-content-center" *ngIf="isLoading">
      <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
  </div>
  <app-ftp-table [clientId]="clientId" [tableFtp]="FTPDetails" [ftpStatusView]="ftStatus" *ngIf="!isLoading"></app-ftp-table>
  </div>
