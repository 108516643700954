<div class="container-fluid">
  <!-- Headers -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <h3 class="font-weight-bolder mb-2 mt-2">
        <b class="customThemeClass">Inventory Management / Product</b>
      </h3>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mt-3">
      <!-- Input Fields -->
      <div class="card">
        <div class="card-header header-text small-text">Product Details</div>
        <div class="card-body">
          <form [formGroup]="productForm">
            <div class="row">
              <!-- Manufacturer -->
              <mat-form-field class="col-3 dd-text">
                <mat-label>
                  Manufacturer<span class="asterisk">*</span></mat-label
                >
                <mat-select formControlName="drpManufacturer">
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtManufacturerFilter"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngFor="let vendors of filteredVendorDetails | async"
                    [value]="vendors.id"
                  >
                    {{ vendors.vendorName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Category -->
              <mat-form-field class="col-3 dd-text">
                <mat-label> Category<span class="asterisk">*</span></mat-label>
                <mat-select formControlName="drpCategory">
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtCategoryFilter"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option
                    *ngFor="let categories of filteredCategoryDetails | async"
                    [value]="categories.id"
                  >
                    {{ categories.productCategoryName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- Product Code -->
              <mat-form-field class="col-3 dd-text">
                <mat-label
                  >Product Code<span class="asterisk">*</span></mat-label
                >
                <input
                  matInput
                  maxlength="15"
                  formControlName="txtProductCode"
                  type="text"
                />
                <mat-error
                  class="spanAlignment"
                  *ngIf="
                    productForm?.get('txtProductCode')?.touched &&
                    productForm?.get('txtProductCode')?.errors?.required
                  "
                >
                  Product Code is a required field!
                </mat-error>
              </mat-form-field>
              <!-- Description -->
              <mat-form-field class="col-3 dd-text">
                <mat-label
                  >Description<span class="asterisk">*</span></mat-label
                >
                <input
                  matInput
                  maxlength="100"
                  formControlName="txtDescription"
                  type="text"
                />
                <mat-error
                  class="spanAlignment"
                  *ngIf="
                    productForm?.get('txtDescription')?.touched &&
                    productForm?.get('txtDescription')?.errors?.required
                  "
                >
                  Description is a required field!
                </mat-error>
              </mat-form-field>

              <!-- Unit Cost -->
              <mat-form-field class="col-1 dd-text">
                <mat-label>Unit Cost ($)</mat-label>
                <input
                  maxlength="10"
                  matInput
                  (keypress)="numbersAndfloatOnly($event)"
                  (paste)="onPaste($event)"
                  type="text"
                  formControlName="txtUnitCost"
                />
                <mat-error
                  *ngIf="
                    productForm.get('txtUnitCost').hasError('invalidDecimal')
                  "
                  >Invalid Unit Cost</mat-error
                >
              </mat-form-field>

              <!-- Cash -->
              <mat-form-field class="col-1 dd-text">
                <mat-label>Cash ($)</mat-label>
                <input
                  matInput
                  formControlName="txtCash"

                  (keypress)="numbersAndfloatOnly($event)"
                  (paste)="onPaste($event)"
                  maxlength="10"
                  type="text"
                />
                <mat-error
                  *ngIf="productForm.get('txtCash').hasError('invalidDecimal')"
                  >Invalid Cash Value</mat-error
                >
              </mat-form-field>

              <mat-form-field class="col-2 dd-text">
                <mat-label
                  >Minimum Quantity<span class="asterisk">*</span></mat-label
                >
                <input
                  (paste)="onKeyPress($event)"
                  (keypress)="onKeyPress($event)"
                  matInput
                  maxlength="10"
                  type="text"
                  formControlName="txtMiniQty"
                />
              </mat-form-field>

              <!-- Size -->
              <mat-form-field class="col-2 dd-text">
                <mat-label>Size<span class="asterisk">*</span></mat-label>
                <mat-select
                  #drpSize
                  formControlName="txtSizeId"
                  placeholder="Size"
                  class="custom-dropdown"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      placeholderLabel="Search"
                      noEntriesFoundLabel="No Matches found"
                      formControlName="txtSizeIdFileter"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let mask of filteredSizeId | async"
                    [value]="mask.id"
                    >{{ mask.description }}</mat-option
                  >
                </mat-select>
              </mat-form-field>

              <!-- Location Bin -->
              <mat-form-field class="col-2 dd-text">
                <mat-label>Location Bin</mat-label>
                <input
                  matInput
                  maxlength="15"
                  type="text"
                  formControlName="txtLocationBin"
                />
              </mat-form-field>

              <!-- Ticket Extra Note -->
              <mat-form-field class="col-4 dd-text">
                <mat-label>Ticket Extra Note </mat-label>
                <input
                  matInput
                  type="text"
                  maxlength="100"
                  formControlName="txtTicketExtraNote"
                />
              </mat-form-field>
              <!-- <div class="col-1 dd-text"></div> -->
              <!-- Fulfillment-->
              <mat-checkbox
                formControlName="chkFulfillment"
                class="col-2 mt-1 pl-3 p-1 dd-text"
              >
                <p class="text-wrap mb-0">Fulfillment</p>
              </mat-checkbox>

              <!-- CDS Price Verify-->
              <mat-checkbox
                formControlName="chkCDSPriceVerify"
                class="col-2 mt-1 pl-3 p-1"
              >
                <p class="text-wrap mb-0 dd-text">CDS Price Verify</p>
              </mat-checkbox>

              <!-- CDS Part Verify-->
              <mat-checkbox
                formControlName="chkCDSPartVerify"
                class="col-2 mt-1 pl-3 p-1"
              >
                <p class="text-wrap mb-0 dd-text">CDS Part Verify</p>
              </mat-checkbox>

              <!-- Has PPM Part -->
              <mat-checkbox
                formControlName="chkHasPPMPart"
                class="col-2 mt-1 pl-3 p-1"
              >
                <p class="text-wrap mb-0 dd-text">Has PPM Part</p>
              </mat-checkbox>

              <!-- Has CDS Part -->
              <mat-checkbox
                formControlName="chkHasCDSPart"
                class="col-2 mt-1 pl-3 p-1"
              >
                <p class="text-wrap mb-0 dd-text">Has CDS Part</p>
              </mat-checkbox>

              <!-- is Hide -->
              <mat-checkbox
                formControlName="chkIsHide"
                class="col-2 mt-1 pl-3 p-1"
              >
                <p class="text-wrap mb-0 dd-text">Is Hide</p>
              </mat-checkbox>

              <!-- Notes -->
              <!-- <mat-form-field class="col-6">
                <mat-label>Notes</mat-label>
                <textarea
                  matInput
                  rows="4"
                  autocomplete="new-txtComments"
                  maxlength="5000"
                  formControlName="txtComments"
                  [(ngModel)]="notesText"
                  (input)="updateCharacterCount(5000)"
                  type="text"
                ></textarea>
                <div class="char-count">{{ notesText?.length }} / 5000</div>
              </mat-form-field> -->
              <div class="col-9"></div>
              <div class="col-3">
                <div class="card">
                  <div class="card-header p-2 text-center">
                    <mat-form-field class="mr-3 dd-text">
                      <input
                        matInput
                        type="text"
                        maxlength="15"
                        placeholder="HCPC Code"
                        formControlName="txtHCPCCode"
                      />
                      <!-- <mat-error class="text-danger" *ngIf="true">
                        HCPC Code already Exists!
                      </mat-error> -->
                    </mat-form-field>
                    <button
                      type="button"
                      mat-raised-button
                      (click)="addHCPC()"
                      class="buttonColor"
                    >
                      <mat-icon>add</mat-icon>
                      Add
                    </button>
                  </div>
                  <div *ngIf="ltHCPC.length > 0" class="p-0 card-body">
                    <div class="table table-responsive mb-0 table-container">
                      <table class="table table-bordered mb-0 small-text">
                        <thead>
                          <tr>
                            <th
                              class="text-center pt-2 m-0 small-text custom-th"
                            >
                              Codes
                            </th>
                            <th
                              class="text-center pt-2 m-0 small-text custom-th"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let data of ltHCPC"
                            class="p-0 m-0 small-text"
                          >
                            <td class="text-center p-0 pt-2 m-0 dd-text">
                              {{ data.hcpcCode }}
                            </td>
                            <td class="text-center p-0 m-0 dd-text">
                              <button type="button" mat-icon-button>
                                <mat-icon
                                  (click)="removeHCPC(data.hcpcCode)"
                                  matTooltip="Click To Remove"
                                  [style.font-size]="'medium'"
                                  >close</mat-icon
                                >
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center mt-2">
                <button
                  type="button"
                  (click)="saveUpdateProductDetails()"
                  [disabled]="productForm?.invalid || isSaveBtnDisabled"
                  [hidden]="this.productId !== defaultGuid &&
                 this.productId !== '' &&
                this.productId !== undefined"
                  mat-raised-button
                  class="buttonColor mr-2"
                >
                  Save
                </button>
                <button
                type="button"
                (click)="saveUpdateProductDetails()"
                [disabled]="productForm?.invalid || isSaveBtnDisabled"
                [hidden]="this.productId === defaultGuid ||
                 this.productId === '' ||
                this.productId === undefined"
                mat-raised-button
                class="buttonColor mr-2"
              >
                Update
              </button>
                <button
                  type="button"
                  (click)="resetForm()"
                  mat-raised-button
                  class="resetclr"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- Table Card -->
      <div class="mt-2 card">
        <div class="card-header header-text">Product List</div>
        <div class="card-body">
          <div class="d-flex justify-content-center" *ngIf="!loadItemTable">
            <img
              class="img-responsive pb-2 mr-1"
              src="assets/gifs/Infinity-loader.gif"
            />
          </div>

          <div class="table table-responsive" *ngIf="loadItemTable">
            <app-product-table-list
              [productListValue]="productList"
            ></app-product-table-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
