import { Component, OnInit } from '@angular/core';
import { ModifierService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/modifier.service';
import { Subscription } from 'rxjs';
import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { CreateUpdateModifierDTO, ModifierDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { DatePipe } from '@angular/common';
import { Moment } from 'moment';
import moment from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
@Component({
  selector: 'app-master-modifier-v1',
  templateUrl: './master-modifier-v1.component.html',
  styleUrls: ['./master-modifier-v1.component.scss']
})
export class MasterModifierV1Component implements OnInit {
  dateRangStatus: boolean = false;
  minDate: Moment;
  maxDate: Moment;
  isLoading: boolean;
  tableData$: Subscription;
  ranges:any;
  fromDate: any;
  toDate: any;
  calendarLocale: LocaleConfig;
  calendarPlaceholder: string = '';
  selectedRange: { startDate: Moment, endDate: Moment };
  tableMstModifry: ModifierDTO[] = [];
  drpModifiers: ModifierDTO[] = [];
  subscription$: Subscription[] = [];
  constructor( private mstModifryService: ModifierService,
    private communicationService: CommunicationService,
    private datepipe: DatePipe,
    ) {  

      this.communicationService.functionReloadModifierTable$.subscribe(() => {
        this.getMasterModifierTable(null,null,null,null);
    });

    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
    };
  }

  ngOnInit(): void {

    this.getMasterModifierTable(null,null,null,null);
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  getMasterModifierTable(sModifierName:string,sdtCreationDate:string,dtStartDate:string,dtEndDate:string) {
    this.isLoading = true;
    this.tableData$?.unsubscribe();
    const getModifiersTable = this.mstModifryService.searchModifierBySModifierNameAndSdtCreationDateAndDtStartDateAndDtEndDate(sModifierName,sdtCreationDate,dtStartDate,dtEndDate).subscribe(
      (response) => {
        this.tableMstModifry = response?.items?.reverse() ?? [];
        // this.drpModifiers = response?.items ?? [];
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'error',
          text: data?.error?.error?.message,
        });
      }
    );
    this.tableData$ = getModifiersTable;
  }




  datesUpdateds(e) {
    if (e.startDate != null || e.endDate != null) {
      this.dateRangStatus = true
      const startDate = new Date(e.startDate);
      const endDate = new Date(e.endDate);
      this.fromDate = this.datepipe.transform(startDate, "MM/dd/yyyy");
      this.toDate = this.datepipe.transform(endDate, "MM/dd/yyyy");

      this.getMasterModifierTable(null,null,this.fromDate,this.toDate);
      // this.getModifierBySearch()

    } else {
      this.dateRangStatus = false
      this.fromDate = null,
        this.toDate = null
     this.getMasterModifierTable(null,null,this.fromDate,this.toDate);
      // this.getModifierBySearch()
    }
  }
}
