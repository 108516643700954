import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  LogoMaxandTypeLimits,
  defaultCountry,
  defaultGuid,
} from 'projects/shared/src/app/enums/allenums.enum';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import emailMask from 'text-mask-addons/dist/emailMask';
const defaultUrl = '../../../assets/images/user.png';
import {
  RegionCitiesDTO,
  RegionCountriesDTO,
  RegionStatesDTO,
} from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { ToastrService } from 'ngx-toastr';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
// import { DatePipe } from '@angular/common';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatRadioChange } from '@angular/material/radio';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { OrganizationUnitDTO } from '../../admin-proxy/platform-app-management/rcm/platform-management/organization-units/dto';
import { RoleService_RoleDTO } from '../../admin-proxy/platform-app-management/rcm/platform-management/role/models';
import { RoleService } from '../../admin-proxy/platform-app-management/rcm/platform-management/role';
import { IdentityUserCreateUsingTenantIdDto, UserService } from '../../admin-proxy/platform-app-management/rcm/platform-management/user';
import { OrganizationUnitService } from '../../admin-proxy/platform-app-management/rcm/platform-management/organization-units';
import { TenantsService } from '../../admin-proxy/platform-app-management/rcm/platform-management/tenant';
import { UserResetPasswordComponent } from '../../user-reset-password/user-reset-password.component';
import { PasswordValidation } from '../../confirmedvalidator';
import { UsernameEmailValidationDto } from '../../admin-proxy/platform-app-management/rcm/platform-management/users/dto';
import { IdentityUserUpdateDto } from '../../admin-proxy/platform-app-management/identity';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-user-tableList',
  templateUrl: './user-tableList.component.html',
  styleUrls: ['./user-tableList.component.scss']
})
export class UserTableListComponent implements OnInit {
  public strPageType: string = 'SettingUsersList';
  @Input() clientId: string = '';
  @Input() userStatusId:number;
  @Input() tableData: any; @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  @Output() getUserId = new EventEmitter<boolean>();
  userTableDataList: any;
  isPanelOpenState: boolean = false;
  isImageValid: boolean = true;
  sltMainBranchNameValue = '';
  userId: string = '';
  roleForm: FormGroup;
  isShownSaveButton: boolean = true;
  // isSaveDisable : false;
  isShowSpinner: boolean = false;
  isShownUsersTable: boolean = true;
  // userIdlist:
  showErrorForFileType: boolean = false;
  showErrorBasedOnSize: boolean = false;
  fileAs64Value: string = defaultUrl;
  role: string;
  drpRoles: RoleService_RoleDTO[] = [];
  drpRolesList: RoleService_RoleDTO[] = [];
  sltMainBranchNameFilter=new FormControl();
  sltRemoteBranchNameFilter= new FormControl();
  emptyguid: string = defaultGuid;
  emailMask: any;
  drpOrganization: OrganizationUnitDTO[] = [];
  drpOrganizationList: OrganizationUnitDTO[] = [];
  RemoteSelectAllOption = false;
  hide = true;
  isMandatory = false;
  userIdlist: string[] = [];
  phoneCode: string = '';
  drpCountry: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  CountryName = '';
  stateName = '';
  Country: string = '';
  State: string = '';
  City: string = '';
  RemoteBranch = '';
  public filteredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredCities: Observable<RegionCitiesDTO[]> | undefined;
  public filteredCountries: Observable<RegionCountriesDTO[]> | undefined;
  public lat: number;
  public long: number;
  branch: string;
  roleDropdownValue: string;
  public latitude: number;
  public longitude: number;
  userTableData: any[] = [];
  subscription$: Subscription[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  @ViewChild('selectBranch') selectBranch: MatSelect;
  @ViewChild('selectAllBranch') private selectAllBranch: MatOption;
  IdentityUserService: any;

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue = formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }

  dtUserTableOptions:any = {
    responsive: true,
    // stateSave: true,
    paging: false,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    scrollCollapse: true,
    scrollY: '380px',
    scrollX: true,
    fixedHeader: true,
    order:[],
    columnDefs: [
      { targets: [0], orderable: false },
      { targets: [8,9,10], visible: false },
      {
        targets: [7],
        type: 'date',
        render: function (data, type, row) {
          // Assuming data is in the format MM/DD/YYYY
          if (type === 'sort' || type === 'type') {
            var dateParts = data.split('/');
            return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
          } else {
            return data;
          }
        },
      },
    ],
    search: {
      smart: false, // Set to "none" for full exact match
    },
    buttons: [
      {
        extend: 'excel',
        text:'Export',
        filename: 'User List',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(2), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      }
    ],
  };
  txtRoleFilter=new FormControl();
  isLoading: boolean = false;
  // step: boolean = false;
  // panelOpenState = false;
  addWorkingId: string;
  concurrencyStamp: string = '';
  isUpdateMode: boolean = false;
  // dtOptions: DataTables.Settings = {};
  dtOptions: any;
  tenantId: string;
  tableData$: Subscription;
  //Accordion Toggle Methods
  toggle() {
    this.isPanelOpenState = true;
  }
  toggleV2() {
    this.isPanelOpenState = false;
  }
  constructor(
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private userService: UserService,
    private branchService: OrganizationUnitService,
    private regionDropdownService: RegionDropdownService,
    private officialService: TenantsService,
    private toastr: ToastrService,
    private datepipe: DatePipe,
    private dateValidator: DateValidator,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
  ) { }

  ngOnInit() {

    this.dtUserTableOptions.buttons[0].filename =
    'User List' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.emailMask = emailMask;
    this.tenantId = localStorage.getItem("tenantId") ?? "";
    this.roleForm = this.formBuilder.group(
      {
        txtRole: new FormControl('', Validators.required),
        txtUserName: new FormControl(
          '',
          Validators.required,
          this.checkUserUniqness(),

        ),
        txtEmail: new FormControl(
          '',
          [Validators.required, Validators.email],
          [this.checkEmailUniqness()]
        ),
        sltMainBranchName: new FormControl('', Validators.required),
        sltRemoteBranchName: new FormControl(''),
        txtPassword: new FormControl(
          '',
          this.isUpdateMode ? null : Validators.required
        ),
        txtConfirmPassword: new FormControl(
          '',
          this.isUpdateMode ? null : Validators.required
        ),
        txtPhnNo: new FormControl(''),
        txtAddress1: new FormControl(''),
        txtAddress2: new FormControl(''),
        drpCountry: new FormControl(''),
        drpState: new FormControl(''),
        drpStateFilter: new FormControl(''),
        drpCity: new FormControl(''),
        drpCityFilter: new FormControl(''),
        txtPostalCode: new FormControl(''),
      },
      {
        validator: PasswordValidation.MatchPassword,
      }
    );
    // this.filterFormInitiate();
    this.loadRoleDropdown();
    this.countryDropdown();
    this.loadOrganizationDropdown();
    // this.getTableData(this.userStatusId);

    this.userTableDataList = this.tableData;

    this.txtRoleFilter.valueChanges.subscribe((dxTypeFilterText) => {
      dxTypeFilterText = dxTypeFilterText.toLowerCase();
      this.drpRoles = this.drpRolesList.filter((dx) =>
        dx.roleDisplayName.toLowerCase().includes(dxTypeFilterText)
      );
    });
    this.sltMainBranchNameFilter.valueChanges.subscribe((dxTypeFilterText) => {
      dxTypeFilterText = dxTypeFilterText.toLowerCase();
      this.drpOrganization = this.drpOrganizationList.filter((dx) =>
        dx.organizationUnitName.toLowerCase().includes(dxTypeFilterText)
      );
    });
    this.sltRemoteBranchNameFilter.valueChanges.subscribe((dxTypeFilterText) => {
      dxTypeFilterText = dxTypeFilterText.toLowerCase();
      this.drpOrganization = this.drpOrganizationList.filter((dx) =>
        dx.organizationUnitName.toLowerCase().includes(dxTypeFilterText)
      );
    });
  }

  ngOnDestroy(): void {
    this.tableData$?.unsubscribe();
    this.dtOptions = {};
  }

   //After View Method
   ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }
  updatePasswordValidators() {
    this.roleForm.get('txtPassword').setValidators([Validators.required]);
    this.roleForm.get('txtPassword').updateValueAndValidity();
    this.roleForm
      .get('txtConfirmPassword')
      .setValidators([Validators.required]);
    this.roleForm.get('txtConfirmPassword').updateValueAndValidity();
  }

  clearPasswordValidators() {
    this.roleForm.controls['txtPassword'].clearValidators();
    this.roleForm.controls['txtPassword'].updateValueAndValidity();
    this.roleForm.controls['txtConfirmPassword'].clearValidators();
    this.roleForm.controls['txtConfirmPassword'].updateValueAndValidity();
  }

  // checkUserUniqness(): AsyncValidatorFn {
  //   return (
  //     control: AbstractControl
  //   ): Observable<{ [key: string]: any } | null> => {
  //     const gUser = this.userId == '' ? this.emptyguid : this.userId;
  //     const input: UsernameEmailValidationDto = {
  //       username:
  //         String(control?.value ?? '')
  //           ?.toLowerCase()
  //           ?.trim() ?? '',
  //       emailAddress: '',
  //       isUserNameExists: false,
  //       isEmailAddressExists: false,
  //       userId: gUser,
  //     };
  //     return this.userService.checkEmailIfExistsByInput(input).pipe(
  //       tap((x) => control?.setErrors({ isUserNameExists: true })),
  //       debounceTime(300),
  //       map((response) =>
  //         response?.isUserNameExists === true
  //           ? { isUserNameExists: true }
  //           : control?.setErrors(null) == null
  //           ? null
  //           : null
  //       )
  //     );
  //   };
  // }
  // getTableData(userStatusId: number) {
  //   this.userTableData = [];
  //   this.isLoading = true;
  //   this.tableData$?.unsubscribe();
  //   const getInboundTableData =
  //       this.userService.getUsersByStatusByUserStatusAndGTenantV1(
  //         userStatusId, this.tenantId,this.userFilterForm.value.txtRoleFilter,
  //         this.userFilterForm.value.sltMainBranchNameFilter,
  //         this.userFilterForm.value.txtEmailFilter,
  //         this.userFilterForm.value.txtUserNameFilter,
  //       ).subscribe(
  //         (response) => {
  //          this.userTableData=response;
  //          const arrTableData = this.userTableData;
  //          this.isLoading = false;
  //        },
  //       (err) => {
  //         const data: HttpErrorResponse = err;
  //         Swal.fire({
  //           icon: 'info',
  //           text: data?.error?.error?.message,
  //         });
  //         const arrTableData = [];
  //         this.isLoading = false;
  //       }
  //     );
  //   this.tableData$ = getInboundTableData;
  // }
  checkUserUniqness(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      // const gUser = userId === '' ? this.emptyguid : userId;
      const gUser = this.userId == '' ? this.emptyguid : this.userId;

      const input: UsernameEmailValidationDto = {
        username: String(control?.value ?? '')?.toLowerCase()?.trim() ?? '',
        emailAddress: '',
        isUserNameExists: false,
        isEmailAddressExists: false,
        userId: gUser,
      };

      return this.userService.checkEmailIfExistsByInput(input).pipe(
        debounceTime(300),
        switchMap((response) => {

          if (response?.isUserNameExists === true) {
            return of({ isUserNameExists: true });
          } else {
            control?.setErrors(null);
            return of(null);
          }
        }),
        catchError((error) => {
          console.error('Error in asynchronous validator:', error);
          // Show an alert or any other error handling logic here
          alert('An error occurred while checking user uniqueness. Please try again.');
          return of(null); // Return null to avoid breaking the observable chain
        })
      );
    };
  }

  resetPassword(userId: string) {

    this.dialog.open(UserResetPasswordComponent, {
      minHeight: '20vh',
      minWidth: '50vw',
      disableClose: true,
      data: {
        userId: userId,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => { });
  }
  multiUserResetPassword() {
    this.dialog.open(UserResetPasswordComponent, {
      minHeight: '20vh',
      minWidth: '50vw',
      disableClose: true,
      data: {
        userId: defaultGuid,
      },
    });
    this.dialog.afterAllClosed.subscribe((resposne) => { });
  }
  onRadioChange(event: MatRadioChange): void {
    this.isLoading=true;
    this.userStatusId = event.value;
    this.communicationService.triggerLoadUSerList(this.userStatusId);
    this.isLoading=false;
  //  this.getTableData(this.userStatusId); // userStatus
  }
  checkEmailUniqness(): AsyncValidatorFn {
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      const gUser = this.userId == '' ? this.emptyguid : this.userId;
      // const userId = await getUserIdAsync();
      const input: UsernameEmailValidationDto = {
        username: '',
        emailAddress:
          String(control?.value ?? '')
            ?.toLowerCase()
            ?.trim() ?? '',
        isUserNameExists: false,
        isEmailAddressExists: false,
        // userId:"4472e4c3-968b-b08b-c11a-3a0d4e4e48f2",
        userId: gUser,
      };
      return this.userService.checkEmailIfExistsByInput(input).pipe(
        tap((x) => control?.setErrors({ isEmailAddressExists: true })),
        debounceTime(300),
        map((response) =>
          response?.isEmailAddressExists === true
            ? { isEmailAddressExists: true }
            : control?.setErrors(null) == null
              ? null
              : null
        )
      );
    };
  }

  onFileSelected(value: File) {
    if (value.size >= LogoMaxandTypeLimits.documentFileSizeLimit) {
      this.showErrorForFileType = false;
      this.showErrorBasedOnSize = true;
      this.isImageValid = false;
      return;
    }
    if ('image/jpeg' !== value.type) {
      this.showErrorForFileType = true;
      this.showErrorBasedOnSize = false;
      this.isImageValid = false;
      return;
    }
    this.showErrorForFileType = false;
    this.showErrorBasedOnSize = false;
    this.isImageValid = true;
    let reader = new FileReader();
    this.fileAs64Value = '';
    reader.readAsDataURL(value);
    reader.onload = () => {
      this.fileAs64Value = reader?.result?.toString() ?? '';
      this.showErrorBasedOnSize = false;
      this.showErrorForFileType = false;
    };
  }

  // clear dropdown from ontime
  clearCountry() {
    // this.userForm2.controls['txtPhnNo'].disable();
    this.Country = '';
    this.roleForm.patchValue({ drpCountry: '' });
  }

  clearState() {
    this.State = '';
    this.roleForm.patchValue({ drpState: '' });
  }

  clearCity() {
    this.City = '';
    this.roleForm.patchValue({ drpCity: '' });
  }

  clearRole() {
    this.role = '';
    this.roleForm.patchValue({ txtRole: '' });
    // this.userForm.patchValue({ txtRole: '' });
  }

  clearBranch() {
    this.branch = '';
    this.roleForm.patchValue({ txtBranchName: '' });
  }

  clearConfirmPassword() {
    this.roleForm.patchValue({ txtConfirmPassword: '' });
  }

  clearRemoteBranch() {
    this.RemoteSelectAllOption = false;
    this.RemoteBranch = '';
    this.roleForm.patchValue({ sltRemoteBranchName: '' });
  }

  ngOnChanges(userId) {
    userId = this.userId;
  }

  //! load role dropdown
  loadRoleDropdown() {
    this.roleService.getActiveRolesByGTenantId(this.clientId).subscribe(
      (response) => {
        this.drpRoles = [];
        this.drpRolesList = response;
        this.drpRoles=this.drpRolesList;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }

  onSelectionChange(event: any) {
    const selectedOptions = this.roleForm.get('sltRemoteBranchName').value;

    if (selectedOptions.includes('selectAll')) {
      this.RemoteSelectAllOption = !this.RemoteSelectAllOption;

      if (this.RemoteSelectAllOption) {
        // If "Select All" is selected, set all other options
        this.roleForm
          .get('sltRemoteBranchName')
          .patchValue([...this.drpOrganization.map((org) => org.id).filter((option) => option !== this.sltMainBranchNameValue)]);
      } else {
        // If "Select All" is deselected, clear all other options
        this.roleForm.get('sltRemoteBranchName').patchValue([]);
      }
    } else {
      // If an individual option is selected, check if all options are selected and update "Select All"
      this.RemoteSelectAllOption =
        selectedOptions.length === this.drpOrganization.length;

      // If an individual option is deselected, deselect "Select All"
      if (
        selectedOptions.length < this.drpOrganization.length &&
        this.RemoteSelectAllOption
      ) {
        this.RemoteSelectAllOption = false;
        this.roleForm
          .get('sltRemoteBranchName')
          .patchValue(
            selectedOptions.filter((option) => option !== 'selectAll')
          );
      }
    }
    this.RemoteBranch = 'RemoteBranch'
  }

  loadOrganizationDropdown() {
    this.branchService
      .getOrganizationList(this.clientId)
      .subscribe((response) => {
        this.drpOrganization = [];
        response &&
          response?.forEach((element) => {
            if (element?.status == 'Active') {
              this.drpOrganizationList.push(element);

              //this.drpOrganization = response ?? [];
            }
          });
          this.drpOrganization= this.drpOrganizationList;
      });
  }

  // Get Country Dropdown
  countryDropdown() {
    this.roleForm.patchValue({ drpCountry: defaultCountry.US });
    this.onChangeState(defaultCountry.US);
    this.Country = defaultCountry.US;
    this.drpCountry = [];
    this.regionDropdownService
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.drpCountry =
            response?.items.filter((x) => x.countryShortName == 'US') ?? [];
          this.filteredCountries = this.roleForm
            ?.get('txtCountryFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
  }
  // On Change State Dropdown
  onChangeState(value: string | null | undefined): boolean {
    this.roleForm.patchValue({
      drpState: null,
      drpCity: null,
    });
    this.drpStates = [];
    this.drpCities = [];
    value !== null &&
      value !== undefined &&
      this.regionDropdownService
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response?.items ?? [];
            if (response?.items?.length === 0) {
              this.phoneCode = '';
              this.roleForm.controls['txtPhnNo'].disable();
            } else {
              this.roleForm.controls['txtPhnNo'].enable();
              this.phoneCode = this.drpStates[0]?.countryPhoneCode ?? '';
            }
            this.filteredStates = this.roleForm
              ?.get('drpStateFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return this.drpStates.length !== 0;
  }
  // On Change City Dropdown
  onChangeCity(state: string | null | undefined, country: string): boolean {
    this.City = '';
    this.roleForm.patchValue({
      drpCity: null,
    });
    country =
      country == ''
        ? this.roleForm.value.drpCountry == (null || undefined)
          ? ''
          : this.roleForm.value.drpCountry
        : country;
    this.drpCities = [];
    state !== null &&
      state !== undefined &&
      this.regionDropdownService
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response?.items ?? [];
            this.City = response?.totalCount == 0 ? '' : 'City';
            this.filteredCities = this.roleForm
              ?.get('drpCityFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    return this.drpCities.length !== 0;
  }

  //! get official name in super admin
  getBranch() {
    const isSupAdmin: number =
      Number(+localStorage.getItem('isAdmin') ?? 0) ?? 0;
    isSupAdmin &&
      this.officialService
        .getTenantInformationById(this.clientId)
        .subscribe((response) => {
          if (response?.status == 'InActive') {
            this.isShownSaveButton = false;
            this.isShownUsersTable = false;
          }
        });
  }
  //! Load Organization Dropdown Function

  onRoleChange(role) {
    this.roleDropdownValue = role.value;
    this.isMandatory = false;
  }

  /// save user functionality
  saveOnTimeUser() {
    let sltRemoteBranchName = this.roleForm.value.sltRemoteBranchName;
    let joinedValues = '';
    this.isLoading=true;
    // Check if sltRemoteBranchName is an array and not null
    if (Array.isArray(sltRemoteBranchName) && sltRemoteBranchName.length > 0) {
      // Use join(',') only if sltRemoteBranchName is an array with at least one element
      joinedValues = sltRemoteBranchName.join(',');
    }
    this.isShowSpinner = true;
    this.isShownSaveButton = false;
    if (this.userId == null || this.userId == '' || this.userId == undefined) {
      let user: IdentityUserCreateUsingTenantIdDto = {
        tenantId: this.clientId,
        userName: this.roleForm.value.txtUserName.replace(/ /g, ''),
        name: this.roleForm.value.txtUserName,
        surname: '',
        email: this.roleForm.value.txtEmail.toLowerCase(),
        phoneNumber: this.roleForm.value.txtPhnNo,
        lockoutEnabled: true,
        roleNames: [this.roleForm.value.txtRole],
        password: this.roleForm.value.txtPassword,
        extraProperties: {
          organizationUnitId: this.roleForm.value.sltMainBranchName,
          profileImageAsBase64String:
            this.fileAs64Value === defaultUrl
              ? ''
              : this.fileAs64Value?.slice(23)?.toString() ?? '',
          status: 'Active',
          RemoteLocationsId: joinedValues.toString(),
          address1: this.roleForm.value.txtAddress1,
          address2: this.roleForm.value.txtAddress2,
          country: this.roleForm.value.drpCountry,
          state: this.roleForm.value.drpState,
          city: this.roleForm.value.drpCity,
          zipCode: this.roleForm.value.txtPostalCode,
          userWorkingHoursId: this.emptyguid,
          latitude: this.latitude,
          longitude: this.longitude,
        },
      };
      this.userService.createUser(user).subscribe(
        (response) => {

          this.isShownSaveButton = true;
          this.isShowSpinner = false;
          this.reset();
          // this.communicationService.triggerLoadUSerList(this.userStatusId);
          if(this.userTableDataList.length===0){
            this.communicationService.triggerLoadBranchList(this.userStatusId);
           } else{
             if(this.userStatusId !== 3){
          //  this.userTableDataList.push(response);
          this.userTableDataList.unshift(response);
             }
           }
           this.toastr.success('Saved Successfully', 'Success');
           this.isLoading=false;
          // this.getTableData(this.userStatusId);
        },
        (err) => {
          this.isShowSpinner = false;
          this.isShownSaveButton = true;
          this.isPanelOpenState = true;
          this.isLoading=false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      let user: IdentityUserUpdateDto = {
        userName: this.roleForm.value.txtUserName.replace(/ /g, ''),
        name: this.roleForm.value.txtUserName,
        surname: '',
        email: this.roleForm.value.txtEmail.toLowerCase(),
        phoneNumber: this.roleForm.value.txtPhnNo,
        lockoutEnabled: true,
        roleNames: [this.roleForm.value.txtRole],
        extraProperties: {
          organizationUnitId: this.roleForm.value.sltMainBranchName,
          profileImageAsBase64String:
            this.fileAs64Value === defaultUrl
              ? ''
              : this.fileAs64Value?.slice(23)?.toString() ?? '',
          status: 'Active',
          RemoteLocationsId: joinedValues.toString(),
          address1: this.roleForm.value.txtAddress1,
          address2: this.roleForm.value.txtAddress2,
          country: this.roleForm.value.drpCountry,
          state: this.roleForm.value.drpState,
          city: this.roleForm.value.drpCity,
          zipCode: this.roleForm.value.txtPostalCode,
          userWorkingHoursId: this.emptyguid,
          latitude: this.latitude,
          longitude: this.longitude,
        },
      };

      this.userService.updateUser(this.clientId, this.userId, user).subscribe(
        (response) => {
          this.isShownSaveButton = true;
          this.isShowSpinner = false;
          this.reset();
          // this.communicationService.triggerLoadUSerList(this.userStatusId);
          // let index = this.userTableDataList.findIndex(d => d.id === response.id);
          // this.userTableDataList.splice(index,1);
          // this.userTableDataList.push(response);
          const index = this.userTableDataList.findIndex(
            (obj) => obj.id === response?.id
          );
          if (index !== -1) {
            this.userTableDataList[index] = response; //Replace the Object
            const objectToMove = this.userTableDataList.splice(index, 1)[0]; // Remove and get the object
            this.userTableDataList.unshift(objectToMove);
          }
          this.isLoading = false;
          // this.getTableData(this.userStatusId);
          this.isPanelOpenState = false;
          this.toastr.success('Updated Successfully', 'Success');
          this.isLoading=false;
        },
        (err) => {
          this.isShowSpinner = false;
          this.isShownSaveButton = true;
          this.isLoading=false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
    this.updatePasswordValidators();
  }

  fnDisableRemoteBranches() {
    this.sltMainBranchNameValue = this.roleForm.value.sltMainBranchName;
    this.clearRemoteBranch()
  }
  fnDisableRemoteBranchesV1() {
    this.sltMainBranchNameValue = this.roleForm.value.sltMainBranchName;
  }

  reset() {
    this.roleForm.reset();
    this.userId = '';
    this.isUpdateMode = false;
    this.fileAs64Value = '../../../assets/images/user.png';
    this.showErrorForFileType = false;
    this.showErrorBasedOnSize = false;
    this.updatePasswordValidators();
  }



  statusChange(event: MatSlideToggleChange, data: any) {
    // this.status = event.checked == true ? "Active" : "Inactive";
    if (event.checked == true) {
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this User will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!',
      }).then((result) => {
        if (result.value) {
          this.isLoading=true;
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.userService
              .updateUserStatusByGUserId(data?.id)
              .subscribe(
                () => {
                  this.isShownSaveButton = true;
                  this.isShowSpinner = false;
                  this.reset();
                  // this.communicationService.triggerLoadUSerList(this.userStatusId);
                  // this.getTableData(this.userStatusId);
                  if(this.userStatusId !==0){
                    let index = this.userTableDataList.findIndex(d => d.id === data?.id);
                    this.userTableDataList.splice(index,1);
                  }else{
                    this.userTableDataList.find(item => item.id == data?.id).status = 'Active';
                  }
                  this.isLoading=false;
                  this.toastr.success('Activated Successfully', 'Success')

                },
                (err) => {
                  // this.saveButtonHide = false;
                  const data: HttpErrorResponse = err;
                  this.isShowSpinner = false;
                  this.isLoading=false;
                  // this.saveButtonHide = false;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                  event.source.checked = false;
                }
              );
          }
        } else {
          event.source.checked = false;
        }
      });
    } else if (event.checked == false) {
      Swal.fire({
        title: 'Do you want to deactivate?',
        text: 'By Changing Status, this User will be deactivated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, deactivate it!',
        //cancelButtonText: 'No, deactivate it!',
      }).then((result) => {
        if (result.value) {
          this.isLoading=true;
          if (
            data?.id &&
            data?.id !== '' &&
            data?.id !== null &&
            data?.id !== undefined
          ) {
            this.userService
              .updateUserStatusByGUserId(data?.id)
              .subscribe(
                () => {
                  this.isShownSaveButton = true;
                  this.isShowSpinner = false;
                  this.reset();
                  // this.getTableData(this.userStatusId);
                  // this.communicationService.triggerLoadUSerList(this.userStatusId);
                  if(this.userStatusId !==0){
                    let index = this.userTableDataList.findIndex(d => d.id === data?.id);
                    this.userTableDataList.splice(index,1);
                  }else{
                    this.userTableDataList.find(item => item.id == data?.id).status = 'Inactive';
                  }
                  this.isLoading=false;
                  this.toastr.success('Deactivated Successfully', 'Success')

                },
                (err) => {
                  const data: HttpErrorResponse = err;
                  this.isShowSpinner = false;
                  this.isLoading=false;
                  event.source.checked = true;
                  Swal.fire({
                    icon: 'info',
                    text: data?.error?.error?.message,
                  });
                }
              );
          }
        } else {
          event.source.checked = true;
        }
      });
    }
  }

  ViewUser(event) {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
    this.isPanelOpenState = true;
    this.getUserById(event.id);
    this.isUpdateMode = true;
    this.clearPasswordValidators();
  }

  // delete user function
  deleteUser(event: { id: string }) {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.IdentityUserService.delete(event.id).subscribe(
          () => {
            this.toastr.success('Deleted Successfully', 'Success');
            this.isShownSaveButton = true;
            this.isShowSpinner = false;
            this.reset();
            // this.getTableData(this.userStatusId);
            this.communicationService.triggerLoadUSerList(this.userStatusId);
          },
          (err) => {
            const data: HttpErrorResponse = err;
            this.isShownSaveButton = true;
            this.isShowSpinner = false;
            this.reset();
          }
        );
      }
    });
  }
  getUserById(id) {
    this.userId = id;
    this.userService.getUserById(this.clientId, id).subscribe(
      (response) => {
        this.addWorkingId = response?.extraProperties.userWorkingHoursId;
        // this.userId = response?.id;
        this.stateName = response?.extraProperties?.state;
        this.CountryName = response?.extraProperties?.country;

        this.CountryName != null &&
          this.CountryName !== '' &&
          this.CountryName != undefined &&
          this.onChangeState(this.CountryName) &&
          this.roleForm.patchValue({
            drpCountry: this.CountryName,
          });

        this.stateName != null &&
          this.stateName !== '' &&
          this.stateName != undefined &&
          this.onChangeCity(this.stateName, this.CountryName) &&
          this.roleForm.patchValue({
            drpState: this.stateName,
            drpCity: this.City,
          });

        const profileUrl =
          (response?.extraProperties?.profileImageAsBase64String ?? '') === ''
            ? defaultUrl
            : String(
              'data:image/jpeg;base64,' +
              response?.extraProperties?.profileImageAsBase64String
            );
        this.fileAs64Value = profileUrl;
        this.concurrencyStamp = response?.concurrencyStamp ?? '';
        let splitValues = [];
        if (response?.extraProperties && response.extraProperties.RemoteLocationsId) {
          // Use split only if RemoteLocationsId is not null
          splitValues = response.extraProperties.RemoteLocationsId.split(',');
        }
        this.roleForm.patchValue({
          txtRole: response?.roleName,
          txtUserName:
            response?.name != '' ? response?.name : response?.userName,
          txtEmail: response?.email,
          sltMainBranchName: response?.extraProperties.organizationUnitId,
          sltRemoteBranchName: splitValues,
          drpCountry: response?.extraProperties.country,
          drpState: response?.extraProperties.state,
          drpCity: response?.extraProperties.city,
          txtAddress1: response?.extraProperties.address1,
          txtAddress2: response?.extraProperties.address2,
          txtPhnNo: response?.phoneNumber,
          txtPostalCode: response?.extraProperties.zipCode,
          statusToggle:
            response?.extraProperties.status == 'Inactive' ? false : true,
        });
        this.latitude = response?.extraProperties.latitude;
        this.longitude = response?.extraProperties.longitude;
        this.fileAs64Value = profileUrl;
        this.concurrencyStamp = response?.concurrencyStamp ?? '';
        this.fnDisableRemoteBranchesV1();
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }


  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

  // getFormattedDateAndTime(date: any): string {
  //   return date
  //     ? new Date(date).toLocaleString('en-US', {
  //       month: '2-digit',
  //       day: '2-digit',
  //       year: 'numeric',
  //       hour: 'numeric',
  //       minute: 'numeric',
  //       hour12: true,
  //     })
  //     : '-';
  // }

//US Format Date and Time 
getFormattedDateAndTime(date: string): string {
  if (date) {
	const formattedDate = new Date(date);
	const options: Intl.DateTimeFormatOptions = {
	  year: 'numeric', month: '2-digit', day: '2-digit',
	  hour: 'numeric', minute: '2-digit',
	  hour12: true,
	  timeZone: 'America/Chicago'
	};
	return formattedDate.toLocaleString('en-US', options);
  } else {
	return '-';
  }
}
}
