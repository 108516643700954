import {
  VerificationStatus,
  VerificationStatusV1,
} from './../patient-proxy/patient/enumeration-data/verification-status.enum';
import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { defaultCountry } from 'projects/shared/src/app/enums/allenums.enum';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import {
  RegionCitiesDTO,
  RegionCountriesDTO,
  RegionStatesDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto';
import {
  GenderDTO,
  InsuranceTypeDTO,
  PatientMasterDropdownDTO,
  PayorLevelDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { PatientMasterDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { PatientDropdowns } from '../dropdown-proxy/dropdown-management/dropdowns/patient-dropdowns.enum';
import { RegionDropdownService } from '../dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { RelationShipWithSelfDTO } from '../dropdown-proxy/rcm/dropdown-management/dropdowns/dto/patient';
import { PatientAddPolicyComponent } from '../patient-add-policy/patient-add-policy.component';
import { PatientEligibilityListTabComponent } from '../patient-eligibility-list-tab/patient-eligibility-list-tab.component';
import { PatientManualVerificationTabComponent } from '../patient-manual-verification-tab/patient-manual-verification-tab.component';
import { PayerService, PverifyPayersService } from '../patient-proxy/patient';
import {
  AddressService,
  PatientPersonalService,
  PatientPolicyService,
} from '../patient-proxy/patient-optimization';
import {
  CreateUpdatePatientPolicyDTO,
  PatientPersonalDTO,
  PatientPolicyDTO,
  VerificationData,
  GetNPI_TaxId,
} from '../patient-proxy/patient-optimization/dto';
import { PayerDTO, PverifyPayersDTO } from '../patient-proxy/patient/dto';
import {
  AddressType,
  InsuranceType,
} from '../patient-proxy/patient/enumeration-data';
import { PatientPverifyTabComponent } from '../patient-pverify-tab/patient-pverify-tab.component';
import { ToastrService } from 'ngx-toastr';
import { PayerDetailService } from '../patient-proxy/patient/payer-detail.service';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { truncate } from 'fs';
import { LoaderService } from 'projects/shared/src/app/services/loader-service';
import { error } from 'console';

@Component({
  selector: 'app-patient-add-policy-tab',
  templateUrl: './patient-add-policy-tab.component.html',
  styleUrls: ['./patient-add-policy-tab.component.scss'],
  providers: [ListService, DatePipe],
})
export class PatientAddPolicyTabComponent implements OnInit, OnDestroy {
  initialFormValues: any;
  @Output() reload? = new EventEmitter<string>();
  @Output() patientIdEmitter: EventEmitter<PatientPersonalDTO> =
    new EventEmitter<PatientPersonalDTO>();
  isUniq: boolean = true;
  payerDetails: Subscription;
  formChanged: boolean;
  isFormChanged: boolean = false;
  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue =
          formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }
  isPayorLoading:boolean=false;
  policyModalTitle: string = '';
  sLoginUserName: string = '';
  payorLevel: PayorLevelDTO[] = [];
  drpGenderLoop: GenderDTO[] = [];
  drpInsurance: InsuranceTypeDTO[] = [];
  policyForm: FormGroup;
  drpRelationShip: RelationShipWithSelfDTO[] = [];
  birthdate: Date;
  birthdateV1: string;
  dob: Date;
  dobV1: string;
  @Input() patientId: string = defaultGuid;
  @Input() isMstVerification: boolean = false;
  @Input() MasterVerifyPolicyId: string;
  @Input() MasterVerificationId: string = defaultGuid;
  @Input() MasterDocId: string = defaultGuid;
  policyId: string = defaultGuid;
  documentId: string = null;
  selectedNode: any = null;
  drpCountry: RegionCountriesDTO[] = [];
  drpStates: RegionStatesDTO[] = [];
  drpCities: RegionCitiesDTO[] = [];
  BirthdateMaxDate: Date;
  tblPolicesReponse: any[] = [];
  phoneCode: string = '';
  lstZirmedPayor: customPayerDTO[] = [];
  lstPverifyPayors: customPverifyPayersDTO[] = [];
  BirthdateMinDate: Date;
  saveButtonHide: boolean;
  isShowSpinner: boolean = false;
  isShownButton: boolean = true;
  isShownEligibilityButtton: boolean = true;
  statusType: string = '';
  lastChecked: string = '';
  checkedBy: string = '';
  verifiedType: string = '';
  method: string = '';
  sPolicyAddedName: string = '';
  gender: string = '';
  pVerifyPayor: string = '';
  selectPayor: any;
  selectPayorList: any=[];
  zirmedPayor: string = '';
  payorlevel: string = '';
  insuredCountry: string = '';
  insuredState: string = '';
  insuredCity: string = '';
  payorId: any;
  payerId: any;
  EligibilityPayor: any;
  payorName: any;
  eligibilityMode: any = 4;
  isDoCheckListLoading: any;
  isPolicyLoading:boolean=false;
  isEligibility: boolean = false;
  subscription$: Subscription[] = [];
  verificationId: string;
  // GetNPI_TaxId:GetNPI_TaxId[] =[];
  npi: any = '';
  mmTaxId: any = '';
  lstVerificationSttaus = VerificationStatus;
  lstVerificationSttausV1 = VerificationStatusV1;
  lastVerificationData: VerificationData = null;
  defaultFaxId: string;
  public filteredSelectPayers: any | undefined;
  public filteredPverifypayers: Observable<PverifyPayersDTO[]> | undefined;
  public filteredZirmedPayers: Observable<PayerDTO[]> | undefined;
  public filteredInsuredCountries: Observable<RegionCountriesDTO[]> | undefined;
  public filteredInsuredStates: Observable<RegionStatesDTO[]> | undefined;
  public filteredInsuredCities: Observable<RegionCitiesDTO[]> | undefined;
  tenantId: string = defaultGuid;
  policyIsActiveStatus: boolean = true;
  // previousFormValue:any;
  dataPayorMode: any;
  PayorlevelControl = new FormControl();
  drpPayorLevel: any;

  GenderControl = new FormControl();
  drpGendertype: any;

  RelationControl = new FormControl();
  drpRelationtype: any;

  PayorModeControl = new FormControl();
  drpPayorSearch: any;

  constructor(
    public patientPersonalService: PatientPersonalService,
    public PatientAddressService: AddressService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private textValidators: TextValidators,
    private communicationService: CommunicationService,
    private payerService: PayerService,
    private planService: PverifyPayersService,
    public policyDialogRef: MatDialogRef<PatientAddPolicyComponent>,
    private region: RegionDropdownService,
    private patientPolicyService: PatientPolicyService,
    private toastr: ToastrService,
    private patientDropdownService: PatientMasterDropdownService,
    private payerDetailService: PayerDetailService,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientId: string;
      policyId?: string;
      wipId?: string;
      documentId?: string;
      selectedNode?: string;
      isNewVerify: boolean;
      verificationId?: string;
      verificationStatus?: string;
      isDashboard?:boolean;
      defaultFaxId?:string;
    } = {
      patientId: defaultGuid,
      policyId: defaultGuid,
      wipId: defaultGuid,
      documentId: null,
      selectedNode: null,
      isNewVerify: false,
      verificationId: defaultGuid,
      verificationStatus: null,
      isDashboard:false,
      defaultFaxId:'-'
    }
  ) {
    this.loaderService.getLoaderState().subscribe((isDoCheckListLoading) => {
      this.isDoCheckListLoading = isDoCheckListLoading;
    });
  }

  ngOnInit(): void {
    this.patientId = this.data?.patientId ?? defaultGuid;
    this.policyId = this.data?.policyId ?? defaultGuid;
    this.documentId = this.data?.documentId ?? null;
    this.selectedNode = this.data?.selectedNode ?? null;
    this.isPolicyLoading=true;
    // this.verificationId=this.data?.verificationId??defaultGuid;
    //Form Builder
    this.policyForm = this.formBuilder.group({
      drpInsuranceType: new FormControl(null, [Validators.required]),
      // drpPverifyPayor: new FormControl(null, [Validators.required]),
      selectEligibility: new FormControl(4, [Validators.required]),
      txtPolicyNo: new FormControl('', Validators.required),
      txtGroupNo: new FormControl(''),
      // drpZirmedPayor: new FormControl(null, Validators.required),
      txtPverifyFilter: new FormControl(''),
      drpselectPayor: new FormControl('', [Validators.required]),
      // txtZirmedPayorFilter: new FormControl(''),
      selectPayor: new FormControl(''),
      chkCopypatientinfo: new FormControl(0),
      txtInsuredSsn: new FormControl(''),
      txtInsuredPostalCode: new FormControl(''),
      txtInsuredPhone: new FormControl(''),
      txtInsuredMobile: new FormControl(''),
      txtInsuredCity: new FormControl(null),
      txtInsuredState: new FormControl(null),
      txtInsuredCountry: new FormControl(''),
      txtInsuredCountryFilter: new FormControl(''),
      txtInsuredStateFilter: new FormControl(''),
      txtInsuredCityFilter: new FormControl(''),
      txtInsuredLastName: new FormControl('', [
        Validators.required,
        // this.textValidators.isTextCheck,
      ]),
      txtInsuredFirst: new FormControl('', [
        Validators.required,
        // this.textValidators.isTextCheck,
      ]),
      txtInsuredMiddle: new FormControl(''),
      txtAddress: new FormControl(''),
      drpGender: new FormControl(null),
      txtGender: new FormControl(''),
      txtRelation: new FormControl(''),
      txtPayorLevel: new FormControl(''),
      txtInsuredBirthDate: new FormControl(''),
      txtPolicyInfoEmployerdId: new FormControl(''),
      txtNote: new FormControl(null),
      drpRelationship: new FormControl('', Validators.required),
    });
    this.getPayorModedrp();
    this.getPolicyDropdown();
    this.countryDropdown();

    if (this.isMstVerification === true) {
      this.getPolicyById(this.MasterVerifyPolicyId);
      this.documentId = this.MasterDocId;
      if (this.isEmpty(this.MasterVerifyPolicyId) === false) {
        this.isEligibility = true;
      }
    } else {
      this.getNPI();
    }
    // this.previousFormValue= this.policyForm.value;
    // this.policyForm.valueChanges.subscribe((newValue) => {
    //   // Compare each form control's previous value with new value
    //   Object.keys(newValue).forEach(key => {
    //     if (this.previousFormValue[key] !== newValue[key]) {
    //       this.formChanged = true;
    //      alert();
    //     }
    //   });

    //   // Update previousFormValue with new value
    //   this.previousFormValue = newValue;
    // });

    //Payor Level DropDowm Search
    this.PayorlevelControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.payorLevel = this.drpPayorLevel.filter((dx) =>
        dx.payorLevelName.toLowerCase().includes(FilterText)
      );
    });

    //Gender DropDowm Serch
    this.GenderControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpGenderLoop = this.drpGendertype.filter((dx) =>
        dx.genderName.toLowerCase().includes(FilterText)
      );
    });

    //Relatationship DropDowm Serch
    this.RelationControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.drpRelationShip = this.drpRelationtype.filter((dx) =>
        dx.relationType.toLowerCase().includes(FilterText)
      );
    });

    //Payor Mode  DropDowm Serch
    this.PayorModeControl.valueChanges.subscribe((FilterText) => {
      FilterText = FilterText.toLowerCase();
      this.dataPayorMode = this.drpPayorSearch.filter((dx) =>
        dx.payorName.toLowerCase().includes(FilterText)
      );
    });
  }
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  ngAfterViewInit(): void {
    // this.eligibilityMode = 4;
    // this.policyForm.patchValue({
    //   selectEligibility: this.eligibilityMode,
    // });
    this.getPayorList(this.eligibilityMode);
  }
  //Select Payor Mode DropDown
  getPayorModedrp() {
    this.dataPayorMode = [
      { payorName: 'pVerify', payorID: 1 },
      { payorName: 'Zermid', payorID: 2 },
      { payorName: 'ChangeHealthCare', payorID: 3 },
      { payorName: 'Manual', payorID: 4 },
      { payorName: 'Office Ally', payorID: 5 },
    ];
    this.drpPayorSearch = this.dataPayorMode.sort((a, b) =>
      a.payorName.localeCompare(b.payorName)
    );
  }

  // Country Dropdown
  countryDropdown() {
    this.drpCountry = [];
    const countryDropdownSubscription = this.region
      .getAllCountriesByInput(new PagedAndSortedResultRequestDto())
      .pipe(
        map((response) => {
          this.drpCountry = response?.items ?? [];
          this.phoneCode =
              response?.items?.filter(
                (x) => x.countryShortName === defaultCountry.US
              )?.[0]?.countryPhoneCode ?? '';
          return response;
        }),
        // tap((x) => {
        //   this.country = defaultCountry.US;

        //   this.demoForm?.patchValue(
        //     { txtCountry: defaultCountry.US },
        //     { emitEvent: true }
        //   );
        // }),
        map((x) => {
          this.onChangeState(defaultCountry.US);
        })
      )
      .subscribe(
        (response) => {
          // this.drpCountry = response?.items ?? [];

          this.filteredInsuredCountries = this.policyForm
            .get('txtInsuredCountryFilter')
            ?.valueChanges.pipe(
              startWith(''),
              map((value) =>
                this.drpCountry.filter((option) =>
                  option?.countryName
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase() ?? '')
                )
              )
            );
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(countryDropdownSubscription);
  }

  // state city
  onChangeCountry(value: string | null | undefined): boolean {
    this.policyForm.patchValue({
      txtInsuredCity: null,
      txtInsuredState: null,
    });
    this.drpStates = [];
    this.drpCities = [];
    if (value !== null && value !== undefined) {
      const stateSubscription = this.region
        .getAllStatesByCountryNameByCountryShortName(value)
        .subscribe(
          (response) => {
            this.drpStates = response?.items ?? [];
            // this.phoneCode = response?.items[0]?.countryPhoneCode;
            // if (this.drpCountry) {
            //  let countryCode = this.drpCountry?.filter(
            //     (x) => x?.countryShortName === value
            //   );
            //   this.phoneCode =countryCode?.[0].countryPhoneCode;
            // } else {
            //   this.phoneCode = '';
            // }
            this.filteredInsuredStates = this.policyForm
              .get('txtInsuredStateFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpStates.filter((option) =>
                    option?.stateName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(stateSubscription);
    }
    return this.drpStates && this.drpStates?.length !== 0;
  }
  onChangeState(state: string = '', country: string = ''): boolean {
    this.drpCities = [];
    this.policyForm.patchValue({
      txtInsuredCity: null,
    });
    country =
      country == ''
        ? this.policyForm.value.txtInsuredCountry == (null || undefined)
          ? ''
          : this.policyForm.value.txtInsuredCountry
        : country;
    if (state !== null && state !== undefined) {
      const stateSubscription = this.region
        .getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(
          country,
          state
        )
        .subscribe(
          (response) => {
            this.drpCities = response?.items ?? [];
            this.filteredInsuredCities = this.policyForm
              .get('txtInsuredCityFilter')
              ?.valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.drpCities.filter((option) =>
                    option?.cityName
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(stateSubscription);
    }
    return this.drpCities && this.drpCities?.length !== 0;
  }
  changePhoneCode(value: string | null | undefined){

             let countryCode = this.drpCountry?.filter(
                (x) => x?.countryShortName === value
              );
              this.phoneCode =countryCode?.[0].countryPhoneCode;
              console.log(this.phoneCode)
  }

  getPolicyDropdown() {
    const patientDropdownInput: PatientDropdowns[] = [
      PatientDropdowns.PayorLevels,
      PatientDropdowns.RelationShipWithSelf,
      PatientDropdowns.ClaimFillingCodes,
      PatientDropdowns.Genders,
      PatientDropdowns.InsuranceTypes,
      PatientDropdowns.Assignments,
    ];
    const patientDropdownSubscription = this.patientDropdownService
      .getPatientDropdownsByInput(patientDropdownInput)
      .subscribe(
        (stateResponse) => {
          const response: PatientMasterDropdownDTO = stateResponse;
          this.payorLevel = response?.payorLevels ?? [];
          this.drpGenderLoop = response?.genders ?? [];
          this.drpInsurance = response?.insuranceTypes ?? [];
          this.drpRelationShip = response?.relationShipWithSelves ?? [];
          this.drpPayorLevel = this.payorLevel;
          this.drpGendertype = this.drpGenderLoop;
          this.drpRelationtype = this.drpRelationShip;
          this.GetPayorDropdown();
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(patientDropdownSubscription);
    this.policyId !== defaultGuid && this.getVerificationStatus();
  }
  //! get last verification status by policy id
  getVerificationStatus() {
    this.isEligibility = true;
    const lastVerificationDataSub = this.patientPolicyService
      .getLastVerificationDataByPolicyId(this.policyId)
      .subscribe(
        (response) => {
          this.lastVerificationData = response ?? null;
          setTimeout(() => {
            this.initialFormValues = this.policyForm.value;
            this.isFormChanged = !this.areFormValuesEqual(
              this.policyForm.value,
              this.initialFormValues
            );
            this.policyForm.valueChanges.subscribe(() => {
              this.isFormChanged = !this.areFormValuesEqual(
                this.policyForm.value,
                this.initialFormValues
              );
            });
          }, 1000);
        },
        (err) => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.subscription$.push(lastVerificationDataSub);
  }

  getNPI() {
    const NPI_TaxID = this.patientPersonalService
      .getNPIList(this.patientId)
      .subscribe(
        (response) => {
          // Perform a null check on the response
          const GetNPI_TaxId: GetNPI_TaxId = response ?? null;

          // If response is not null, extract the properties
          if (GetNPI_TaxId) {
            this.mmTaxId = GetNPI_TaxId.mmTaxId;
            this.npi = GetNPI_TaxId.npi;
          } else {
            this.mmTaxId = ' - ';
            this.npi = ' - ';
          }
        },
        (err) => {
          // Handle error and display message
          //const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'info',
          //   text: data?.error?.error?.message,
          // });

          this.mmTaxId = ' - ';
          this.npi = ' - ';
        }
      );
  }

  //To Save the Policy
  savepolicy() {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    const policyFormValue = this.policyForm.value;
    // this.previousFormValue = this.policyForm.value;
    // this.payorId = splitValue[1].trim();
    this.payerId =this.policyForm.value.drpselectPayor; //Guid
    let splitValue = this.selectPayorList.filter(item => item.payerId ===  this.payerId);
    // this.payorName = splitValue[2].trim();
    this.payorId = splitValue[0].payerCode;
    this.payorName = splitValue[0].payerName;
    const selpVerifyPayor: customPverifyPayersDTO =
      this.lstPverifyPayors?.filter(
        (x) => x?.shortCodeId === policyFormValue?.drpPverifyPayor
      )[0] ?? null;
    const selZirmedPayor: customPayerDTO =
      this.lstZirmedPayor?.filter(
        (x) => x?.payerShortCodeId === policyFormValue?.drpZirmedPayor
      )[0] ?? null;
    const policyDetails: CreateUpdatePatientPolicyDTO = {
      patientId: this.patientId ?? defaultGuid,
      docId: this.data.documentId ?? defaultGuid,
      policyId: this.policyId ?? defaultGuid,
      wipId: this.data.wipId ?? defaultGuid,
      payorLevel: policyFormValue?.drpInsuranceType ?? '',
      policyNo: policyFormValue?.txtPolicyNo ?? '',
      groupNo: policyFormValue?.txtGroupNo ?? '',
      relationship: policyFormValue?.drpRelationship ?? '',
      note: policyFormValue?.txtNote ?? '',
      lastName: policyFormValue?.txtInsuredLastName ?? '',
      firstName: policyFormValue?.txtInsuredFirst ?? '',
      middleName: policyFormValue?.txtInsuredMiddle ?? '',
      birthdate: policyFormValue?.txtInsuredBirthDate,
      gender: policyFormValue?.drpGender ?? '',
      ssn: policyFormValue?.txtInsuredSsn ?? '',
      address: policyFormValue?.txtAddress ?? '',
      city: policyFormValue?.txtInsuredCity ?? '',
      state: policyFormValue?.txtInsuredState ?? '',
      country: policyFormValue?.txtInsuredCountry ?? '',
      postalCode: policyFormValue?.txtInsuredPostalCode ?? '',
      phone: policyFormValue?.txtInsuredPhone ?? '',
      eligibilityMode: policyFormValue?.selectEligibility  ?? '4',
      payorId: this.payorId ?? '',
      payerId: this.payerId ?? defaultGuid,
      payorName: this.payorName ?? '',
      mobile: policyFormValue?.txtInsuredMobile ?? '',
      employerId: policyFormValue?.txtPolicyInfoEmployerdId ?? '',
      pVerifyPayorId: selpVerifyPayor?.payerCode ?? '',
      pVerifyPayorName: selpVerifyPayor?.payerName ?? '',
      zirmedPayorId: selZirmedPayor?.payerId ?? '',
      zirmedPayorName: selZirmedPayor?.payerName ?? '',
      tenantId: this.tenantId ?? defaultGuid,
      isActive: this.policyIsActiveStatus,
    };

    //To save the policy
    if (policyDetails?.policyId === defaultGuid) {
      this.patientPolicyService.create(policyDetails).subscribe(
        (response) => {
          if (this.data.isNewVerify === true) {
            this.policyDialogRef.close();
          } else {
            this.policyId = response?.id ?? defaultGuid;
            this.toastr.success(
              'Saved Successfully and Verification Initiated',
              'Success'
            );
            this.getNPI();
            this.getVerificationStatus();
            this.communicationService.triggerFunctionCall(
              this.data.selectedNode
            );
            this.isEligibility = true;
            this.saveButtonHide = false;
            this.isShowSpinner = false;
          }
          this.data.verificationStatus = response.verificationStatus;
          this.data.verificationId = response.lastVerificationId;
          this.isFormChanged = false;
        },
        (err) => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
    //To Update the Policy
    else {
      this.patientPolicyService.update(this.policyId, policyDetails).subscribe(
        (response) => {
          if (this.data.isNewVerify === true) {
            this.policyDialogRef.close();
            // this.isLoadPage.emit();
          } else {
            this.toastr.success(
              'Saved Successfully and Verification Initiated',
              'Success'
            );
            setTimeout(() => {
              this.initialFormValues = this.policyForm.value;
              this.policyForm.valueChanges.subscribe(() => {
                this.isFormChanged = !this.areFormValuesEqual(
                  this.policyForm.value,
                  this.initialFormValues
                );
              });
            }, 1000);
            this.isEligibility = true;
            this.saveButtonHide = false;
            this.isShowSpinner = false;
            // this.isLoadPage.emit();
            this.data.verificationStatus = response.verificationStatus;
            this.data.verificationId = response.lastVerificationId;
          }
          this.isFormChanged = false;
        },
        (err) => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

  getPolicyById(policyId: string): void {
    this.isPolicyLoading=true;
    this.loaderService.showLoader();
    let selectedPolicy: PatientPolicyDTO = null;
    const policyDetails$ = this.patientPolicyService.get(policyId).subscribe(
      (response) => {
        selectedPolicy = response;
        this.isPolicyLoading=false;
        this.policyModalTitle = 'Edit Policy';
        this.policyId = selectedPolicy?.id ?? '';
        this.onChangeCountry(selectedPolicy?.country ?? defaultCountry.US) &&
          this.policyForm.patchValue({
            txtInsuredCountry: selectedPolicy?.country ?? defaultCountry.US,
          });
        this.onChangeState(selectedPolicy?.state, selectedPolicy?.country) &&
          this.policyForm.patchValue({
            txtInsuredState: selectedPolicy?.state ?? '',
            txtInsuredCity: selectedPolicy?.city ?? '',
          });
        let mstPayorLevel: InsuranceType;
        if (
          String(selectedPolicy?.payorLevel).toLocaleLowerCase() ===
          String(InsuranceType.Primary).toLocaleLowerCase()
        ) {
          mstPayorLevel = InsuranceType.Primary;
        }
        if (
          String(selectedPolicy?.payorLevel).toLocaleLowerCase() ===
          String(InsuranceType.Secondary).toLocaleLowerCase()
        ) {
          mstPayorLevel = InsuranceType.Secondary;
        }
        if (
          String(selectedPolicy?.payorLevel).toLocaleLowerCase() ===
          String(InsuranceType.Tertiary).toLocaleLowerCase()
        ) {
          mstPayorLevel = InsuranceType.Tertiary;
        }
        this.eligibilityMode = selectedPolicy?.eligibilityMode;

        this.getPayorList(this.eligibilityMode);
        const selpVerifyPayor: PverifyPayersDTO =
          this.lstPverifyPayors?.filter(
            (x) => x?.payerCode === selectedPolicy?.pVerifyPayorId
          )[0] ?? null;
        const selZirmedPayor: PayerDTO =
          this.lstZirmedPayor?.filter(
            (x) => x?.payerId === selectedPolicy?.zirmedPayorId
          )[0] ?? null;
        this.payerId = selectedPolicy.payerId;
        this.selectPayor =
          selectedPolicy.payerId ;
          // ' ' +
          // '/' +
          // ' ' +
          // selectedPolicy.payorId +
          // ' ' +
          // '/' +
          // ' ' +
          // selectedPolicy.payorName+
          // ' ' +
          // '/' +
          // ' ' +
          // selectedPolicy.code;
        this.getPayorList(this.eligibilityMode);
        this.tenantId = response?.tenantId ?? defaultGuid;
        this.policyIsActiveStatus = response?.isActive ?? true;
        if (
          selectedPolicy?.birthdate !== null &&
          selectedPolicy?.birthdate !== undefined
        ) {
          this.birthdate = new Date(selectedPolicy?.birthdate);
          this.birthdate.setDate(this.birthdate.getDate());
          this.birthdateV1 = this.birthdate.toISOString().split('T')[0];
          this.policyForm.get('txtInsuredBirthDate').setValue(this.birthdateV1);
        }
        this.payerSearch('', selectedPolicy.payerId);
        this.policyForm.patchValue({
          drpPverifyPayor: selpVerifyPayor?.shortCodeId,
          drpZirmedPayor: selZirmedPayor?.payerShortCodeId,

          selectEligibility: selectedPolicy?.eligibilityMode??4,
          drpselectPayor: this.selectPayor,
          drpInsuranceType: mstPayorLevel,
          txtPolicyInfoEmployerdId: selectedPolicy?.employerId ?? '',
          txtPolicyNo: selectedPolicy?.policyNo ?? '',
          txtGroupNo: selectedPolicy?.groupNo ?? '',
          txtInsuredLastName: selectedPolicy?.lastName ?? '',
          txtInsuredFirst: selectedPolicy?.firstName ?? '',
          txtInsuredMiddle: selectedPolicy?.middleName ?? '',
          // txtInsuredBirthDate: selectedPolicy?.birthdate ?? '',
          drpGender: selectedPolicy?.gender ?? '',
          txtInsuredSsn: selectedPolicy?.ssn ?? '',
          txtAddress: selectedPolicy?.address ?? '',
          txtInsuredCity: selectedPolicy?.city ?? '',
          txtInsuredState: selectedPolicy?.state ?? '',
          txtInsuredCountry: selectedPolicy?.country ?? defaultCountry.US,
          txtInsuredPostalCode: selectedPolicy?.postalCode ?? '',
          txtInsuredPhone: selectedPolicy?.phone ?? '',
          txtInsuredMobile: selectedPolicy?.mobile ?? '',
          txtNote: selectedPolicy?.note ?? '',
          drpRelationship: selectedPolicy?.relationId ?? '009',
        });
        if (this.isMstVerification === true) {
          this.patientId = response.patientId;
          this.policyId = response.policyId;
          //  this.verificationId=response.lastVerificationId;
          //  this.data.verificationId=this.MasterVerificationId;
          //  this.documentId=response.docId
          this.lastVerificationData = selectedPolicy?.verificationData;
        }
        // this.loaderService.hideLoader();
        setTimeout(() => {
          this.loaderService.hideLoader();
          // this.policyForm.valueChanges.subscribe(() => {

          //   this.isFormChanged = true;
          // });
          this.initialFormValues = this.policyForm.value;
          this.isFormChanged = !this.areFormValuesEqual(
            this.policyForm.value,
            this.initialFormValues
          );
          this.policyForm.valueChanges.subscribe(() => {
            this.isFormChanged = !this.areFormValuesEqual(
              this.policyForm.value,
              this.initialFormValues
            );
          });
        }, 100);
      },
      (err) => {
        this.isPolicyLoading=false;
        this.loaderService.hideLoader();
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );

    this.subscription$.push(policyDetails$);
  }

  private areFormValuesEqual(currentValues: any, initialValues: any): boolean {
    return JSON.stringify(currentValues) === JSON.stringify(initialValues);
  }

  GetPayorDropdown(): void {
    //this.lstPverifyPayors = [];
    //this.lstZirmedPayor = [];
    // const payorsDrpSub$ = forkJoin([
    //   this.planService.getList(new PagedAndSortedResultRequestDto()),
    //  this.payerService.getList(new PagedAndSortedResultRequestDto()),
    // ]).subscribe(
    //   (response) => {
    //     this.lstPverifyPayors = response?.[0]?.items ?? [];
    //     this.lstPverifyPayors =
    //       this.lstPverifyPayors?.map((x) => {
    //         x.payerNameCode = x?.payerCode + '/' + x?.payerName;
    //         return x;
    //       }) ?? [];
    //     this.lstZirmedPayor = response?.[1]?.items ?? [];
    //     this.lstZirmedPayor =
    //       this.lstZirmedPayor?.map((x) => {
    //         x.payerNameCode = x?.payerId + '/' + x?.payerName;
    //         return x;
    //       }) ?? [];
    !this.isEmpty(this.data.policyId) && this.getPolicyById(this.policyId);
    this.policyModalTitle = 'Add Policy';
    this.isPolicyLoading=false;
    this.policyForm.patchValue({
      txtInsuredCountry: defaultCountry.US,
    });
    // this.getPayorList(this.eligibilityMode)
    //       this.onChangeCountry(defaultCountry.US);
    //       this.filteredPverifypayers = this.policyForm
    //         .get('txtPverifyFilter')
    //         ?.valueChanges.pipe(
    //           startWith(''),
    //           map((value) =>
    //             this.lstPverifyPayors.filter((option) =>
    //               option?.payerNameCode
    //                 ?.toLowerCase()
    //                 ?.includes(value?.toLowerCase() ?? '')
    //             )
    //           )
    //         );
    //       this.filteredZirmedPayers = this.policyForm
    //         .get('txtZirmedPayorFilter')
    //         ?.valueChanges.pipe(
    //           startWith(''),
    //           map((value) =>
    //             this.lstZirmedPayor?.filter((option) =>
    //               option?.payerNameCode
    //                 ?.toLowerCase()
    //                 ?.includes(value?.toLowerCase() ?? '')
    //             )
    //           )
    //         );
    //     },
    //     (err) => {
    //       const data: HttpErrorResponse = err;
    //       Swal.fire({
    //         icon: 'info',
    //         text: data?.error?.error?.message,
    //       });
    //     }
    //   );
    //  this.subscription$.push(payorsDrpSub$);
    if (this.isMstVerification !== true) {
      if (this.isEmpty(this.data.policyId)) {
        this.copyPatient();
      }
    }
  }

  onSelect(event: any) {
    // const  selectValue = event.value?event.value: this.policyForm.value.selectEligibility
    this.eligibilityMode = event.value
      ? event.value
      : this.policyForm.value.selectEligibility;
    this.policyForm.patchValue({
      drpselectPayor: '',
    });
    this.getPayorList(this.eligibilityMode);
  }
  getPayorList(selectedPayor) {

    if (this.payerDetails) {
      this.payerDetails.unsubscribe();
    }
    this.isPayorLoading=true;
    this.payerDetails =
    this.payerDetailService
      .getdrpPayerByIModeV1(selectedPayor, '', '')
      .subscribe((response) => {
        this.isPayorLoading=false;
        this.selectPayorList = response.sort((a, b) =>
          a.payerName.localeCompare(b.payerName)
        );
        this.selectPayorList = response.map((x) => {
          x['payerShortCodeList'] =
          ((x?.payerCode===null||x?.payerCode===""||(x?.payerCode).toLowerCase()=='null')?'-' :x?.payerCode)+ ' ' + '/' + ' ' + x?.payerName+' ' + '/' + ' '
          + ((x?.code===null||x?.code===""||(x?.code).toLowerCase()=="null")?'-':x?.code);
          return x;
        });
        this.filteredSelectPayers = this.policyForm
          .get('selectPayor')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.selectPayorList?.filter((option) =>
                option?.payerShortCodeList
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },(error) =>{
        this.isPayorLoading=false;
      }
    );
  }
  payerSearch(value, payerID) {
    if (this.payerDetails) {
      this.payerDetails.unsubscribe();
    }
    this.isPayorLoading=true;
    this.payerDetails = this.payerDetailService
      .getdrpPayerByIModeV1(this.eligibilityMode, value, payerID)
      .subscribe((response) => {
        this.isPayorLoading=false;
        this.selectPayorList = response.sort((a, b) =>
          a.payerName.localeCompare(b.payerName)
        );
        this.selectPayorList = response.map((x) => {
          x['payerShortCodeList'] =
          ((x?.payerCode===null||x?.payerCode===""||(x?.payerCode).toLowerCase()=='null')?'-' :x?.payerCode)+ ' ' + '/' + ' ' + x?.payerName+' ' + '/' + ' '
          + ((x?.code===null||x?.code===""||(x?.code).toLowerCase()=="null")?'-':x?.code);
          return x;
        });
        this.filteredSelectPayers = this.policyForm
          .get('selectPayor')
          ?.valueChanges.pipe(
            startWith(''),
            map((value) =>
              this.selectPayorList?.filter((option) =>
                option?.payerShortCodeList
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase() ?? '')
              )
            )
          );
      },error=>{
        this.isPayorLoading=false;
      }
    );
  }
  onSelectPayor(event: any) {
    const payorList = event.value;
    let splitValue = payorList.split('/');
    this.payorId = splitValue[1];
    this.payerId = splitValue[0];
    this.payorName = splitValue[2];
    const v = this.checkPayorUniqness();
  }

  openEligibilityHistoryModal() {
    const dialogRef = this.dialog.open(PatientEligibilityListTabComponent, {
      disableClose: true,
      data: {
        patientId: this.patientId,
        policyId: this.policyId,
        verificationStatus: this.data.verificationStatus,
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      (result ?? '') !== '' && this.policyDialogRef.close(result);

      this.policyId !== defaultGuid && this.getVerificationStatus();
    });
    this.subscription$.push(closeDialog);
  }
  openVerificationModal() {
    if (this.isMstVerification) {
      this.data.verificationId = this.MasterVerificationId;
    }
    const dialogRef = this.dialog.open(PatientManualVerificationTabComponent, {
      disableClose: true,
      data: {
        patientId: this.patientId,
        policyId: this.policyId,
        documentId: this.documentId,
        selectedNode: this.selectedNode,
        verificationId: this.data.verificationId,
        verificationStatus: this.data.verificationStatus,
        payerId: this.payerId,
        defaultFaxId:this.data?.defaultFaxId
      },
    });

    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      if (this.isMstVerification === true) {
        if (result == 'Saved') {
          this.reload.emit(result.verificationId);
        }
      }
      if (result == 'Saved') {
        this.policyDialogRef.close(result);
      }

      result !== '' &&
        result !== null &&
        result !== undefined &&
        this.policyDialogRef.close(result);

      this.policyId !== defaultGuid && this.getVerificationStatus();
    });
    this.subscription$.push(closeDialog);
  }
  openPverifyModal() {
    const dialogRef = this.dialog.open(PatientPverifyTabComponent, {
      disableClose: true,
      data: {
        patientId: this.patientId,
        documentId: this.documentId,
        policyId: this.policyId,
        payerId: this.payerId,
        selectedNode: this.selectedNode,
      },
    });

    const closeDialog = dialogRef.afterClosed().subscribe((result) => {
      if (this.isMstVerification === true) {
        if (result === true) {
          this.reload.emit(result.verificationId);
        }
      }
      this.policyId !== defaultGuid && this.getVerificationStatus();
    });
    this.subscription$.push(closeDialog);
  }

  // only number and alpha
  numberAndAlphaOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    const inp = String.fromCharCode(event.keyCode);
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode >= 15 &&
      charCode <= 64
    ) {
      return false;
    }
    return true;
  }
  clearPatientDetails() {
    this.policyForm.patchValue({
      txtInsuredFirst: '',
      txtInsuredMiddle: '',
      txtInsuredLastName: '',
      // txtInsuredSuffix: '',
      txtInsuredSsn: '',
      txtInsuredBirthDate: '',
      drpGender: '',
      drpRelationship: '',
      txtAddress: '',
      txtInsuredCountry: '',
      txtInsuredState: '',
      txtInsuredCity: '',
      txtInsuredPostalCode: '',
      txtInsuredPhone: '',
    });
  }

  // Copy Patient Info when clicking checkbox
  copyPatient() {
    this.loaderService.showLoader();
    this.patientId = this.patientId ?? '';
    if (this.patientId !== '') {
      //To path the Personal Details
      const personal = this.patientPersonalService
        .get(this.patientId)
        .subscribe(
          (response) => {
            const patientPersonalDetails: PatientPersonalDTO = response ?? null;
            if (patientPersonalDetails?.dateOfBirth) {
              this.dob = new Date(patientPersonalDetails?.dateOfBirth);
              this.dob.setDate(this.dob.getDate());
              this.dobV1 = this.dob.toISOString().split('T')[0];
              this.policyForm.get('txtInsuredBirthDate').setValue(this.dobV1);
            }
            patientPersonalDetails !== null &&
              this.policyForm.patchValue({
                txtInsuredFirst: patientPersonalDetails?.firstName,
                txtInsuredMiddle: patientPersonalDetails?.middleName,
                txtInsuredLastName: patientPersonalDetails?.lastName,
                txtInsuredSuffix: patientPersonalDetails?.suffix,
                txtInsuredSsn: patientPersonalDetails?.ssn,
                // txtInsuredBirthDate: patientPersonalDetails?.dateOfBirth ?? '',
                drpGender: patientPersonalDetails?.gender,
                drpRelationship: '009',
              });
            this.loaderService.hideLoader();
          },
          (err) => {
            this.loaderService.hideLoader();
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(personal);

      //To patch the address details
      const address =
        this.PatientAddressService.getAddressByPatientIdByPatientId(
          this.patientId
        ).subscribe(
          (response) => {
            this.onChangeCountry(response[0]?.country ?? defaultCountry.US);
            this.onChangeState(response?.[0]?.state, response?.[0]?.country);

            this.policyForm.patchValue({
              txtAddress: response?.[0]?.address,
              txtInsuredCountry: response?.[0]?.country ?? defaultCountry.US,
              txtInsuredState: response?.[0]?.state,
              txtInsuredCity: response?.[0]?.city,
              txtInsuredPostalCode: response?.[0]?.postalCode,
              txtInsuredPhone: response?.[0]?.phone,
            });

            // let patientBilllingAddressDetails: any[];
            // let patientDeliveryAddressDetails: any[];
            // patientBilllingAddressDetails = response?.filter(
            //   (a) => a.addressType === AddressType.Billing
            // );

            // patientDeliveryAddressDetails = response?.filter(
            //   (a) => a.addressType === AddressType.Delivery
            // );

            // patientBilllingAddressDetails?.[0]?.country != null &&
            //   patientBilllingAddressDetails?.[0]?.country !== '' &&
            //   patientBilllingAddressDetails?.[0]?.country != undefined &&
            //   this.onChangeCountry(patientBilllingAddressDetails[0]?.country);

            // patientBilllingAddressDetails?.[0]?.state != null &&
            //   patientBilllingAddressDetails?.[0]?.state !== '' &&
            //   patientBilllingAddressDetails?.[0]?.state != undefined &&
            //   this.onChangeState(
            //     patientBilllingAddressDetails?.[0]?.state,
            //     patientBilllingAddressDetails?.[0]?.country
            //   );

            // this.policyForm.patchValue({
            //   txtAddress: patientBilllingAddressDetails?.[0]?.address,
            //   txtInsuredCountry: patientBilllingAddressDetails?.[0]?.country,
            //   txtInsuredState: patientBilllingAddressDetails?.[0]?.state,
            //   txtInsuredCity: patientBilllingAddressDetails?.[0]?.city,
            //   txtInsuredPostalCode:
            //   patientBilllingAddressDetails?.[0]?.postalCode,
            //   txtInsuredPhone: patientBilllingAddressDetails?.[0]?.phone,
            // });
          },
          (err) => {
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      this.subscription$.push(address);
      // const contactDetails=this.PatientContactService.getPatientContactByPatientIdByPatientId(
      //   this.patientId
      // ).subscribe(
      //   (response) => {

      //     const v = response?.relationshipId;
      //     this.policyForm.patchValue({
      //       drpRelationship: response?.relationshipId
      //     });
      //   },
      //   (err) => {
      //     const data: HttpErrorResponse = err;
      //     Swal.fire({
      //       icon: 'info',
      //       text: data?.error?.error?.message,
      //     });
      //   }
      // );

      // this.subscription$.push(contactDetails);
    }
  }
  clearRelationship() {
    this.policyForm.patchValue({
      drpRelationship: '',
    });
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
  // checkPayorUniqness(): AsyncValidatorFn {
  //   let input=this.policyForm.value.drpselectPayor.split('/')[0];
  //   return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
  //     return this.PatientAddressService.getPayorCheckAvailablity(input)
  //       .pipe(
  //         tap(x => control?.setErrors({ 'isPayorExists': true })),
  //         debounceTime(300),
  //         map(response => response === true ? { 'isPayorExists': true } : (control?.setErrors(null) == null) ? null : null));
  //    }
  // }
  checkPayorUniqness() {
    let input = this.policyForm.value;
    this.PatientAddressService.getPayorCheckAvailablity(
      input.drpselectPayor.split('/')[0]
    ).subscribe(
      (response) => {
        this.isUniq = response;
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
  }
  viewFaxDocument(){
  const url = '/#/patient_preview_tiff_document/' + this.data.defaultFaxId + '/' + this.documentId;
      window.open(url, '_blank');
    }
}
export interface customPverifyPayersDTO extends PverifyPayersDTO {
  payerNameCode?: string;
}
export interface customPayerDTO extends PayerDTO {
  payerNameCode?: string;
}
