import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import { MmOrderService } from 'projects/order/src/app/order-proxy/order-management/order-optimization/mm-order.service';
import { Observable, Subscription, of } from 'rxjs';
import { UploadEOBComponent } from './claim-status-list-table/upload-eob/upload-eob.component';
import { MatDialog } from '@angular/material/dialog';
import { ClaimBatchService } from '../claim-proxy/claim-processing-management/rcm/billing-management/optimization/claim/claim-batch.service';
import {
  ClaimColorCodeDTO,
  ClaimStatusListDTO,
} from '../claim-proxy/claim-processing-management/rcm/billing-management/optimization/dto';
import { ClaimStatusesService } from '../claim-proxy/claim-processing-management/rcm/billing-management/optimization/claim/claim-statuses.service';
import { ResponseclaimstatusDrpDTO } from '../claim-proxy/claim-processing-management/rcm/billing-management/claim-status/models';
import { map, startWith } from 'rxjs/operators';
import { ClaimStatusService } from '../claim-proxy/claim-processing-management/rcm/billing-management/optimization/claim/claim-status.service';
import {
  claimStatusColorCode,
  defaultGuid,
} from 'projects/shared/src/app/enums/allenums.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'projects/shared/src/app/services/common.service';

@Component({
  selector: 'app-claim-status-list',
  templateUrl: './claim-status-list.component.html',
  styleUrls: ['./claim-status-list.component.scss'],
})
export class ClaimStatusListComponent implements OnInit {
  patientId: string = '';
  claimStatusForm: FormGroup;
  selectedDate: any;
  filteredOrderStatusList: Observable<ResponseclaimstatusDrpDTO[]>;
  defaultStatusValue = 0;
  lstOrderStatusList: any;
  filteredTicketList: any;
  lstPatientChartNos: any;
  @ViewChild('picker') picker: MatDatepicker<Date>;
  fromDate: any;
  toDate: any;
  filterValue: boolean = false;
  iscolorCode: boolean = false;
  patientChartApiSubscription: Subscription;
  isLoadClaimStatusTableData: boolean = false;
  private loadClaimStatusTableDataAPICall: Subscription;
  claimDataArray: ClaimStatusListDTO[];
  isLoadClaimStatusDropdownData: boolean = false;
  private loadClaimStatusDropdownDataAPICall: Subscription;
  dOrderFromDate: Date;
  dOrderToDate: Date;
  chkDateRequired: boolean = true;
  sTicketId: any = '';
  OnlyTicketIDBased: boolean = false;
  isloadColorCodeData: boolean = false;
  private loadColorCodeAPICall: Subscription;
  lstColorList: ClaimColorCodeDTO;
  lstStaticColorList = [
    { status: 'Manual Posting', colorCode: claimStatusColorCode.RED },
    { status: 'Auto Posting', colorCode: claimStatusColorCode.WHITE },
    { status: 'Claim Denial', colorCode: claimStatusColorCode.YELLOW },
    { status: 'Patient Responsibility', colorCode: claimStatusColorCode.GREEN },
  ];
  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private title: Title,
    private mmOrderService: MmOrderService,
    private claimBatchService: ClaimBatchService,
    private claimStatusService: ClaimStatusService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private communicationService: CommunicationService,
    private commonService: CommonService,
  ) {
    this.communicationService.loadClaimStatusList$.subscribe(() => {
      this.loadClaimStatusTableData();
    });
  }

  ngOnInit() {
    this.title.setTitle('QSecure | Claim Status List');
    this.initializeForms();
    this.loadColorCode();
    this.dOrderFromDate = this.getCTStartOfMonth();
    this.dOrderToDate = this.ctDateNow();
    this.loadClaimStatusDropdown();
    this.loadClaimStatusTableData();
    this.searchPatient('');
  }

  ngOnDestroy(): void {
    // this.subscription$?.forEach((sub) => {
    //   sub && sub?.unsubscribe();
    // });
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    if (this.loadClaimStatusTableDataAPICall) {
      this.loadClaimStatusTableDataAPICall.unsubscribe();
    }
    if (this.loadClaimStatusDropdownDataAPICall) {
      this.loadClaimStatusDropdownDataAPICall.unsubscribe();
    }
    if (this.loadColorCodeAPICall) {
      this.loadColorCodeAPICall.unsubscribe();
    }
  }

  selectCheckBox(event) {
    if (event.checked) {
      this.claimStatusForm?.controls?.txtStartDateValue?.setValidators([
        Validators.required,
      ]);
      this.claimStatusForm?.controls?.txtStartDateValue?.updateValueAndValidity();
      this.claimStatusForm?.controls?.txtEndDateValue?.setValidators([
        Validators.required,
      ]);
      this.claimStatusForm?.controls?.txtEndDateValue?.updateValueAndValidity();

      this.dOrderFromDate = this.getCTStartOfMonth();
      this.dOrderToDate = this.ctDateNow();
      this.chkDateRequired = true;
    } else {
      this.claimStatusForm?.controls?.txtStartDateValue?.setValidators([
        Validators.nullValidator,
      ]);
      this.claimStatusForm?.controls?.txtStartDateValue?.updateValueAndValidity();
      this.claimStatusForm?.controls?.txtEndDateValue?.setValidators([
        Validators.nullValidator,
      ]);
      this.claimStatusForm?.controls?.txtEndDateValue?.updateValueAndValidity();
      // this.claimStatusForm.patchValue({
      //   dtDOSTo: '',
      // });
      this.dOrderFromDate = null;
      this.dOrderToDate = null;
      this.chkDateRequired = false;
    }
    this.loadClaimStatusTableData();
  }

  clearPatient(event: Event) {
    event.stopPropagation();
    this.claimStatusForm?.patchValue({
      drpPatient: '',
    });
    this.loadPatientChartNos('');
    // this.loadClaimStatusTableData();
  }

  getCTStartOfMonth(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    dateObj.setDate(1); // Set to the first day of the month
    dateObj.setHours(0, 0, 0, 0);
    return dateObj;
  }

  ctDateNow(): Date {
    let usaTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Chicago',
    });
    const dateObj = new Date(usaTime);
    return dateObj;
  }

  //To initialize the Forms
  initializeForms() {
    this.claimStatusForm = this.fb.group(
      {
        // chkDateRequired: new FormControl(true),
        txtStartDateValue: new FormControl('', [Validators.required]),
        txtEndDateValue: new FormControl('', [Validators.required]),
        txtTicketNo: new FormControl(''),
        chkAllOrders: new FormControl(''),
        drpPatient: new FormControl(''),
        txtPatientFilter: new FormControl(''),
        txtCSRFilter: new FormControl(''),
        drpOrderStatus: new FormControl(0),
        txtStatusFilter: new FormControl(''),
      }
      // ,
      // { validator: this.dateRangeValidator }
    );
  }

  loadColorCode() {
    this.isloadColorCodeData = true;
    try {
      if (this.loadColorCodeAPICall) {
        this.loadColorCodeAPICall.unsubscribe();
      }
      this.loadColorCodeAPICall = this.claimStatusService
        .getClaimStatusColorCode()
        .subscribe(
          (response) => {
            this.lstColorList = response;
            setTimeout(() => {
              this.isloadColorCodeData = false; // Enable the checkbox
            }, 1000);
          },
          (error) => {
            const data: HttpErrorResponse = error.error;
            this.toastr.error(data.error.message, 'Error');
            setTimeout(() => {
              this.isloadColorCodeData = false; // Enable the checkbox
            }, 1000);
          }
        );
    } catch (error) {
      const data: HttpErrorResponse = error.error;
      this.toastr.error(data.error.message, 'Error');
      setTimeout(() => {
        this.isloadColorCodeData = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }

  // Custom validator function to check if from date is greater than to date
  dateRangeValidator: ValidatorFn = (
    control: AbstractControl
  ): { [key: string]: any } | null => {
    const from = control.get('txtStartDateValue')?.value;
    const to = control.get('txtEndDateValue')?.value;
    if (from && to && from > to) {
      return { dateRangeError: true };
    }
    return null;
  };

  sanitizeInput(event: any, controlName: string) {
    const inputValue = event.target.value;
    const newValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters

    // Check if the new value matches the desired pattern
    const isValidInput = /^(?!0)\d{1,10}$/.test(newValue);

    if (!isValidInput) {
      this.claimStatusForm.get(controlName)?.setValue(newValue.slice(0, -1)); // Revert to the previous valid value
    } else {
      this.claimStatusForm.get(controlName)?.setValue(newValue); // Update the form value
    }
  }

  handleInputChange(event: any) {
    const inputValue = event.target.value;
    if (this.sTicketId !== inputValue) {
      this.sTicketId = inputValue;
      this.loadClaimStatusTableData();
    }

    this.OnlyTicketIDBased = inputValue === '' ? false : true;
  }

  onStatusChange() {
    this.loadClaimStatusTableData();
  }

  openEOBModel() {
    let isSaveEnable: boolean = true;
    let cliamData: any;
    const dialogRef = this.dialog.open(UploadEOBComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'fullscreen-dialog-panel',
      disableClose: true,
      data: {
        cliamData: cliamData,
        isSaveEnable: isSaveEnable,
      },
    });
    const closeDialog = dialogRef.afterClosed().subscribe(
      (isSaved: any) => {
        if (isSaved) {
          this.reloadClaimTable();
        }
      },
      (err) => {
        const data: HttpErrorResponse = err;
        console.warn(data?.error?.error?.message);
      }
    );
  }

  OpenColorDiv() {
    this.iscolorCode = !this.iscolorCode;
  }

  // onDateRangeChange() {
  //   const startDate = this.claimStatusForm.get('txtDateValue').value;
  //   const endDate = this.claimStatusForm.get('txtDate').value;
  //   this.fromDate = this.datepipe.transform(startDate, 'MM/dd/yyyy');
  //   this.toDate = this.datepipe.transform(endDate, 'MM/dd/yyyy');
  //   if (startDate != null && endDate != null) {
  //   }
  // }

  filterChange(event: boolean) {
    //Patient - True
    //Others - False
    this.filterValue = event;
    if (this.filterValue === true) {
      this.claimStatusForm.patchValue({
        drpPatient: '',
      });
      this.searchPatient('');
    } else {
      this.loadClaimStatusTableData();
    }
  }

  searchPatient(value: any) {
    this.loadPatientChartNos(value);
  }

  loadPatientChartNos(searchParameter) {
    if (this.patientChartApiSubscription) {
      this.patientChartApiSubscription.unsubscribe();
    }
    // this.patientChartApiSubscription = this.mmOrderService
    //   .getPatientChartNoV2BySSearchParams(searchParameter)
    //   .subscribe(
    //     (response) => {
    //       this.lstPatientChartNos = response;
    //       return this.lstPatientChartNos;
    //     },
    this.patientChartApiSubscription = this.mmOrderService
      .getPatientChartNoV3BySSearchParamsAndPatient(
        searchParameter,
        defaultGuid
      )
      .subscribe(
        (response) => {
          this.lstPatientChartNos = response;
          return this.lstPatientChartNos;
        },
        (err) => {}
      );
  }

  addSlashToDate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue
      .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
      .replace(/^(\d{2}\/\d{2})(\d+)/, '$1/$2')
      .replace(/[^\d\/]/g, '');
  }

  convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (
      this.claimStatusForm.value.txtStartDateValue &&
      this.claimStatusForm.value.txtEndDateValue
    ) {
      this.loadClaimStatusTableData();
    }
  }

  loadClaimStatusDropdown() {
    this.isLoadClaimStatusDropdownData = true;
    try {
      if (this.loadClaimStatusDropdownDataAPICall) {
        this.loadClaimStatusDropdownDataAPICall.unsubscribe();
      }
      this.loadClaimStatusDropdownDataAPICall = this.claimStatusService
        .getdrpclaimStaus()
        .subscribe(
          (response) => {
            this.lstOrderStatusList = response;
            this.filteredOrderStatusList = this.claimStatusForm
              .get('txtStatusFilter')
              .valueChanges.pipe(
                startWith(''),
                map((value) =>
                  this.lstOrderStatusList?.filter((option) =>
                    option?.claimStatus
                      ?.toLowerCase()
                      ?.includes(value?.toLowerCase() ?? '')
                  )
                )
              );
            setTimeout(() => {
              this.isLoadClaimStatusDropdownData = false; // Enable the checkbox
            }, 1000);
          },
          (error) => {
            console.error('Error fetching claim details:', error);
            setTimeout(() => {
              this.isLoadClaimStatusDropdownData = false; // Enable the checkbox
            }, 1000);
          }
        );
    } catch (error) {
      console.error('Unexpected error:', error);
      setTimeout(() => {
        this.isLoadClaimStatusDropdownData = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }

  onChartIdChange(e: any) {
    this.patientId = e.value;
    this.loadClaimStatusTableData();
  }

  reloadClaimTable() {
    this.loadClaimStatusTableData();
  }

  loadClaimStatusTableData() {
    this.isLoadClaimStatusTableData = true;
    try {
      if (this.loadClaimStatusTableDataAPICall) {
        this.loadClaimStatusTableDataAPICall.unsubscribe();
      }

      let fromDate: string = this.dOrderFromDate
          ? this.dOrderFromDate.toString()
          : null,
        toDate: string = this.dOrderToDate
          ? this.dOrderToDate.toString()
          : null,
        orderId: number = null,
        gClaimBatchStatusId: string = null,
        patientId: string = null;
      if (fromDate) {
        fromDate = this.convert(fromDate).toString();
      } else {
        fromDate = null;
      }
      if (toDate) {
        toDate = this.convert(toDate).toString();
      } else {
        toDate = null;
      }
      if (!this.filterValue) {
        orderId = this.claimStatusForm.value.txtTicketNo;
        if (orderId) {
          fromDate = null;
          toDate = null;
          gClaimBatchStatusId = null;
        } else {
          gClaimBatchStatusId =
            this.claimStatusForm.value.drpOrderStatus === 0
              ? null
              : this.claimStatusForm.value.drpOrderStatus;
        }
      } else {
        fromDate = null;
        toDate = null;
        orderId = null;
        gClaimBatchStatusId = null;
        patientId = this.claimStatusForm.value.drpPatient;
      }

      this.loadClaimStatusTableDataAPICall = this.claimBatchService
        .getClaimStatusListDetailsByFromDateAndToDateAndOrderIdAndGClaimBatchStatusIdAndPatientId(
          fromDate,
          toDate,
          orderId,
          gClaimBatchStatusId,
          patientId
        )
        .subscribe(
          (response) => {
            this.claimDataArray = response;
            this.claimDataArray = this.claimDataArray.map((claim) => {
              return {
                ...claim,
                checkDate: this.commonService.getFormattedDateZone(
                  claim.checkDate
                ),
                processedDate: this.commonService.getFormattedDateZone(
                  claim.processedDate
                ),
              };
            });
            setTimeout(() => {
              this.isLoadClaimStatusTableData = false; // Enable the checkbox
            }, 1000);
          },
          (error) => {
            const data: HttpErrorResponse = error.error;
            this.toastr.error(data.error.message, 'Error');
            setTimeout(() => {
              this.isLoadClaimStatusTableData = false; // Enable the checkbox
            }, 1000);
          }
        );
    } catch (error) {
      const data: HttpErrorResponse = error.error;
      this.toastr.error(data.error.message, 'Error');
      setTimeout(() => {
        this.isLoadClaimStatusTableData = false; // Enable the checkbox
      }, 1000);
    } finally {
    }
  }
  isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      value === defaultGuid
    );
  }
}
