import { ListService, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { BillingDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/billing-dropdowns.enum';
import { CreateUpdateModifierDTO, ModifierDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/billing';
import { BillingMasterDropdownService, ModifierService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { Moment } from 'moment';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableDirective } from 'angular-datatables';
import { CommunicationService } from 'projects/shared/src/app/services/communication.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-master-modifiers',
  templateUrl: './master-modifiers.component.html',
  styleUrls: ['./master-modifiers.component.scss'],
  providers: [ListService, DatePipe],
})
export class MasterModifiersComponent implements OnInit, OnDestroy {

  @Input() tableModifier: any;
  isActiveStatus:number;
  modifierTbldata: any;
  mstModifierId: string = '';
  mstModifyrForm: FormGroup;
  modifierSearchForm: FormGroup;
  selectedRange: { startDate: Moment, endDate: Moment };
  ranges: any;
  minDate: Moment;
  maxDate: Moment;
  fromDate: any;
  toDate: any
  calendarLocale: LocaleConfig;
  dateRangStatus: boolean = false;
  calendarPlaceholder: string = '';
  alwaysShowCalendars: boolean;
  isShown: boolean = false;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // displayedColumns: string[] = [
  //   'Options',
  //   'modifierName',
  //   'creationTime',

  // ];
  isShowSpinner: boolean;
  saveButtonHide: boolean;
  drpModifiers: ModifierDTO[] = [];
  step: boolean = false;
  txtModifier: string = '';
  tableMstModifry: ModifierDTO[] = [];
  public filteredModifiers: Observable<any[]>;
  subscription$: Subscription[] = [];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  dtModifierTableOptions: any = {
    responsive: true,
    paging: false,
    scrollCollapse: true,
    scrollY: '410px',
    scrollX: true,
    dom: 'Bfrtip',
    language: {
      emptyTable: 'No records',
      info: 'Total : _MAX_ records',
      infoEmpty: 'Total : _MAX_ records',
      infoFiltered: '(filtered : _TOTAL_ records)',
      loadingRecords: 'Loading...',
      zeroRecords: 'No matching records',
    },
    fixedHeader: true,
    columnDefs: [
      { targets: [2,4,5], visible: false },
    ],
    buttons: [
      {
        extend: 'colvis',
        columns: ':not(:eq(0), :eq(1), :eq(-1))', // Exclude first three and last columns from the column visibility toggle
      },
      {
        extend: 'excel',
        text: 'Export',
        filename: 'Master Modifier',
        exportOptions: {
          columns: ':visible:not(:first-child,:eq(0)) ',
        },
      },
    ]
  };

  constructor(
    private formBuilder: FormBuilder,
    private datepipe: DatePipe,
    public title: Title,
    public list: ListService,
    private mstModifryService: ModifierService,
    private billingMasterDropdownService: BillingMasterDropdownService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private communicationService: CommunicationService,
  ) {

    this.calendarLocale = {
      customRangeLabel: 'Pick a date...',
      applyLabel: 'Apply',
      clearLabel: 'Clear',
      format: 'DD/MM/YYYY',
      daysOfWeek: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1
    };

    this.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
    };
  }

  ngOnInit(): void {

    this.dtModifierTableOptions.buttons[0].filename =
    'Master Modifier' + '-' + this.datepipe.transform(new Date(), 'MM-dd-yyyy');

    this.title.setTitle('Qsecure | Master Modifiers');
    this.mstModifyrForm = this.formBuilder.group({
      drpMstModifyr: new FormControl(''),
      drpMstModifyrFilter: new FormControl(''),
      txtNewMstModifyr: new FormControl('', Validators.required),
    });
    this.getMasterModifierDrp();
    // this.modifierSearchForm = this.formBuilder.group({
    //   modifierName: new FormControl(''),
    //   createdDate: new FormControl(''),

    // });
    // this.getMasterModifierTable();
    // this.getModifierBySearch();
    this.modifierTbldata = this.tableModifier;
  }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }
  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
  //Select Mofifier Drop Down

  // getgrpdown() {
  //   this.drpModifiers = this.drpModifier;
  //   this.filteredModifiers = this.mstModifyrForm?.get("drpMstModifyrFilter").valueChanges
  //     .pipe(
  //       startWith(''),
  //       map(value => this.drpModifiers.filter(option => option?.modifierName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
  //     );
  // }

  //After View Method
  ngAfterViewInit(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.refreshDataTable();
    } else {
      setTimeout(() => this.refreshDataTable(), 500);
    }
  }


  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that.search(this['value']).draw();
          }
        });
      });
    });
  }

  //Get Master modifier dropdown
  getMasterModifierDrp() {
    const getModifiersDrp = this.mstModifryService.getList(new PagedAndSortedResultRequestDto()).subscribe(
      (response) => {
        this.drpModifiers = response?.items ?? [];
        this.filteredModifiers = this.mstModifyrForm?.get("drpMstModifyrFilter").valueChanges
        .pipe(
          startWith(''),
          map(value => this.drpModifiers.filter(option => option?.modifierName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
        );
      },
      (err) => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'error',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(getModifiersDrp);
  }

  //! get Master modifier tables data & dropdown
  // getMasterModifierTable() {
  //   this.isShowSpinner = true;
  //   this.tableMstModifry = [];
  //   const getModifiersTable = this.mstModifryService.getList(new PagedAndSortedResultRequestDto()).subscribe(
  //     (response) => {
  //       this.tableMstModifry = response?.items?.reverse() ?? [];
  //       this.drpModifiers = response?.items ?? [];
  //       this.filteredModifiers = this.mstModifyrForm?.get("drpMstModifyrFilter").valueChanges
  //         .pipe(
  //           startWith(''),
  //           map(value => this.drpModifiers.filter(option => option?.modifierName?.toLowerCase()?.includes(value?.toLowerCase() ?? "")))
  //         );
  //       this.isShowSpinner = false;
  //       this.dataSource = new MatTableDataSource(this.tableMstModifry);
  //       this.dataSource.sort = this.sort;
  //       this.dataSource.paginator = this.paginator;
  //     },
  //     (err) => {
  //       this.isShowSpinner = false;
  //       this.dataSource = new MatTableDataSource(this.tableMstModifry);
  //       this.dataSource.sort = this.sort;
  //       this.dataSource.paginator = this.paginator;
  //       const data: HttpErrorResponse = err;
  //       // Swal.fire({
  //       //   icon: 'error',
  //       //   text: data?.error?.error?.message,
  //       // });
  //     }
  //   );
  //   this.subscription$.push(getModifiersTable);
  // }

  // datesUpdateds(e) {
  //   if (e.startDate != null || e.endDate != null) {
  //     this.dateRangStatus = true
  //     const startDate = new Date(e.startDate);
  //     const endDate = new Date(e.endDate);
  //     this.fromDate = this.datepipe.transform(startDate, "MM/dd/yyyy");
  //     this.toDate = this.datepipe.transform(endDate, "MM/dd/yyyy");

  //     this.getModifierBySearch()

  //   } else {
  //     this.dateRangStatus = false
  //     this.fromDate = null,
  //       this.toDate = null
  //     this.getModifierBySearch()
  //   }
  // }
  setTableData(tableData: ModifierDTO[] = []) {
    this.dataSource = new MatTableDataSource(tableData);
    this.sort.disableClear = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  // getModifierBySearch() {
  //   const valueChanges = this.modifierSearchForm.valueChanges
  //     .pipe(
  //       startWith({
  //         modifierName: "",
  //         createdDate: ""

  //       }),
  //       tap((x) => {
  //         this.isShowSpinner = true;
  //         // this.setTableData([]);
  //       }),
  //       filter((e) => e && this.modifierSearchForm?.valid),
  //       debounceTime(300),
  //       map((response) => {
  //         this.isShowSpinner = true;
  //         const sValue = {
  //           sModifierName: response?.modifierName?.trim()?.toLowerCase(),
  //           sdtCreationDate: response?.createdDate?.trim()?.toLowerCase(),
  //           dtStartDate: this.fromDate?.trim()?.toLowerCase(),
  //           dtEndDate: this.toDate?.trim()?.toLowerCase(),


  //         };
  //         return sValue;
  //       }),


  //       switchMap((sValue) =>
  //         this.mstModifryService.searchModifierBySModifierNameAndSdtCreationDateAndDtStartDateAndDtEndDate(
  //           sValue?.sModifierName,
  //           sValue?.sdtCreationDate,
  //           sValue?.dtStartDate,
  //           sValue?.dtEndDate,
  //         )
  //       )
  //     )
  //     .subscribe(
  //       (response) => {
  //         this.isShowSpinner = false
  //         const responseData: ModifierDTO[] = response.items ?? [];
  //         this.setTableData(responseData);
  //       },
  //       (err) => {
  //         this.isShowSpinner = false;
  //         const data: HttpErrorResponse = err;
  //       }
  //     );

  // }


  // Clear Mst Modifiers feild
  clearMstModifier() {
    this.txtModifier = '';
    this.mstModifyrForm.patchValue({
      drpMstModifyr: "",
      txtNewMstModifyr: ""
    });
  }

  //! get Master Modifier form Instance
  getMstModifyrForm(): FormGroup {
    return this.mstModifyrForm;
  }
  //! View Mst Modifier By ID
  viewMstModifier(value: string) {
    this.mstModifyrForm.markAsDirty();
    this.saveButtonHide = true;

    if (value) {
      const modifiersGet = this.mstModifryService.get(value).subscribe(
        (resposne) => {
          this.saveButtonHide = false;
          this.getMstModifyrForm().patchValue({
            txtNewMstModifyr: resposne?.modifierName ?? ""
          });
          this.mstModifierId = resposne?.id ?? '';
          this.step = true;
        },
        (err: HttpErrorResponse) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
      this.subscription$.push(modifiersGet);
    }

  }
  //! Delete Mst Modifier By ID

  // deleteMstModifier(value: string) {
  //   Swal.fire({
  //     title: 'Are you sure you want to Delete?',
  //     text: "You won't be able to retrieve this data!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#34c38f',
  //     cancelButtonColor: '#f46a6a',
  //     confirmButtonText: 'Yes, delete it!',
  //   }).then((result) => {
  //     if (result.value) {
  //       if (value) {
  //         const deleteModifiers = this.mstModifryService.delete(value).subscribe(
  //           (resposne) => {
  //             this.resetMstModifier();
  //             // this.getMasterModifierTable();
  //             this.communicationService.triggerReloadModifier();
  //           },
  //           (err) => {
  //             const data: HttpErrorResponse = err;
  //             Swal.fire({
  //               icon: 'info',
  //               text: data?.error?.error?.message,
  //             });
  //           }
  //         );
  //         this.subscription$.push(deleteModifiers);
  //       }

  //     }
  //   });
  // }
  // Save or Update By Id Master Modifiers
  saveMstModifier() {
    this.saveButtonHide = true;
    let input: CreateUpdateModifierDTO = {
      shortCodeId: '',
      modifierName: this.mstModifyrForm.value.txtNewMstModifyr ?? '',
      isActive: 1,

    };
    this.mstModifierId = this.mstModifierId ?? '';
    if (this.mstModifierId === '') {
      this.mstModifryService.create(input).subscribe(
        (response) => {
          this.mstModifierId = '';
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Saved Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Saved Successfully', 'Success')
          this.saveButtonHide = false;
          this.resetMstModifier();
          // this.getMasterModifierTable();
          this.communicationService.triggerReloadModifier();
        },
        (err) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    } else {
      this.mstModifryService.update(this.mstModifierId, input).subscribe(
        (response) => {
          this.mstModifierId = '';
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Updated Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Updated Successfully', 'Success')
          this.resetMstModifier();
          // this.getMasterModifierTable();
          this.communicationService.triggerReloadModifier();
          this.saveButtonHide = false;
        },
        (err) => {
          this.saveButtonHide = false;
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }
  //! Reset Mst Modifier Form
  resetMstModifier() {
    this.getMstModifyrForm().patchValue({
      drpMstModifyr: "",
      txtNewMstModifyr: "",
    });
    this.mstModifierId = '';
    this.getMstModifyrForm()?.markAsUntouched();
    this.getMstModifyrForm()?.updateValueAndValidity();
    this.getMstModifyrForm()?.reset();
  }

  //Copy Text While Duble on table Row
  onRowDoubleClickV1(textToCopy) {
    this.copyTextToClipboard(textToCopy);
    this.showNotification('Text copied: ' + textToCopy);
  }

  showNotification(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'end', // Positioning the notification horizontally
      verticalPosition: 'bottom', // Positioning the notification vertically
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
  getFormattedDate(date: string): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '-';
  }

   //Status Change Actice And Inactive
   statusChange(event: MatSlideToggleChange, data: any) {
    if (event.checked == true) {
      let inputCheck: CreateUpdateModifierDTO = {
        shortCodeId: data?.shortCodeId,
        modifierName: data?.modifierName ?? '',
        isActive: 1,
      };
      Swal.fire({
        title: 'Do you want to activate?',
        text: 'By Changing Status, this Master Modifier will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, Activate it!'
      }).then(result => {
        if (result.value) {
          if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
            this.mstModifryService.update(data?.id,inputCheck).subscribe(response => {
              this.toastr.success('Activated Successfully', 'Success')
              this.communicationService.triggerReloadModifier();
            }, (err: HttpErrorResponse) => {
              this.communicationService.triggerReloadModifier();
              this.saveButtonHide = false;
              const data: HttpErrorResponse = err;
              Swal.fire({
                icon: 'info',
                text: data?.error?.error?.message,
              });
            });
          }
        }
        else {
          event.source.checked = false;
        }
      });
    }
    else if (event.checked == false) {
      let inputUnCheck: CreateUpdateModifierDTO = {
        shortCodeId: data?.shortCodeId,
        modifierName: data?.modifierName ?? '',
        isActive: 0,
      };
        Swal.fire({
          title: 'Do you want to deactivate?',
          text: 'By Changing Status, this Master Modifier will be deactivated',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#34c38f',
          cancelButtonColor: '#f46a6a',
          confirmButtonText: 'Yes, deactivate it!'
        }).then(result => {
          if (result.value) {
            if (data?.id && data?.id !== "" && data?.id !== null && data?.id !== undefined) {
              this.mstModifryService.update(data?.id,inputUnCheck).subscribe(response => {
                this.toastr.success('Deactivated Successfully', 'Success');
                this.communicationService.triggerReloadModifier();
              },(err: HttpErrorResponse) => {
                this.communicationService.triggerReloadModifier();
                this.saveButtonHide = false;
                const data: HttpErrorResponse = err;
                Swal.fire({
                  icon: 'info',
                  text: data?.error?.error?.message,
                });
              });
            }
          }
          else {
            event.source.checked = true;
          }
        });
      }
  }
}
