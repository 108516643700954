<div class="row">
  <div class="col-xl-12">
    <h2>{{ policyModalTitle }}</h2>
  </div>
</div>
<mat-dialog-content class="mat-typography" style="overflow-x: hidden">
  <div class="row">
    <div class="col-xl-12">
      <form [formGroup]="policyForm">
        <mat-card-content>
          <div class="row">
            <div class="col-xl-12">
              <h4 class="customThemeClass"><b>Payor</b></h4>
            </div>
          </div>
          <div class="row">
            <mat-form-field class="col-xl-4">
              <mat-label>Payor Level <span class="asterisk">*</span></mat-label>
              <mat-select formControlName="drpPayor" [(value)]="payorlevel">
                <mat-option *ngFor="let payor of payorLevel" [value]="payor.payorLevelName">
                  {{ payor.payorLevelName }}
                </mat-option>
              </mat-select>
              <button mat-button (click)="payorlevel=undefined;$event.stopPropagation()" *ngIf="payorlevel" matSuffix
                (click)="clearPayorlevel()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="policyForm?.get('drpPayor')?.touched &&policyForm?.get('drpPayor')?.errors?.required">
                Payor Level is a required field!
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-xl-8">
              <mat-label>Insurance<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="drpInsurance" [(value)]="Insurance" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtInsuranceFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let insurance of filteredInsuranceTypes | async" [value]="insurance.name">
                  {{insurance.name}}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="policyForm?.get('drpInsurance')?.touched &&policyForm?.get('drpInsurance')?.errors?.required">
                Insurance is a required field!
              </mat-error>
              <button mat-button (click)="Insurance=undefined;$event.stopPropagation()" *ngIf="Insurance" matSuffix
                (click)="clearInsurance()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="col-xl-8">
              <mat-label>Payer Id<span class="asterisk">*</span></mat-label>
              <mat-select formControlName="txtPolicyInfoPayorId" [(value)]="Payers" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                    formControlName="txtPayerFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let insurance of filteredPayerTypes | async" [value]="insurance.name">
                  {{insurance.name}}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="policyForm?.get('txtPolicyInfoPayorId')?.touched &&policyForm?.get('txtPolicyInfoPayorId')?.errors?.required">
                Payer Id is a required field!
              </mat-error>
              <button mat-button (click)="Payers=undefined;$event.stopPropagation()" *ngIf="Payers" matSuffix
                (click)="clearPayers()" mat-icon-button >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field class="col-xl-4">
              <mat-label>Policy No<span class="asterisk">*</span></mat-label>
              <input autocomplete="new-txtPolicyInfoPolicyNo" maxlength="40" formControlName="txtPolicyInfoPolicyNo" matInput type="text"
                pattern="[a-zA-Z0-9]*" />
              <mat-error *ngIf="
                  policyForm?.get('txtPolicyInfoPolicyNo')?.touched &&
                  policyForm?.get('txtPolicyInfoPolicyNo')?.errors?.pattern
                ">
                Enter Only Alphanumeric Only!
              </mat-error>
              <mat-error
                *ngIf="policyForm?.get('txtPolicyInfoPolicyNo')?.touched &&policyForm?.get('txtPolicyInfoPolicyNo')?.errors?.required">
                Policy No is a required field!
              </mat-error>
            </mat-form-field>
          </div>

             <div class="row">
            <mat-form-field class="col-xl-12">
              <mat-label>Note</mat-label>
              <input autocomplete="new-txtNote" maxlength="1000" formControlName="txtNote" matInput type="text" />
            </mat-form-field>
          </div>
        </mat-card-content>
      </form>
    </div>
  </div>
</mat-dialog-content>


<mat-dialog-actions align="end">
  <button  mat-button class="buttonColor" [disabled]="!(!policyForm.invalid && (policyForm.dirty ))"
    (click)="getPatientDetailsForPolicySave()">Save</button>
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
