<div class=container-fluid>
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <h3 class="font-weight-bolder mb-0 mt-1">
        <b class="customThemeClass">List / Orders / Rental List</b>
      </h3>
      <!-- <div class="page-title-box page-title-right mt-3">
        <ol class="breadcrumbs m-0 align-items-center">
          <form [formGroup]="rentalForm">
            <mat-form-field>
              <mat-label>Select Date Range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate matInput placeholder="Start date" formControlName="txtDateValue" />
                <input matEndDate matInput placeholder="End date" formControlName="txtDate"
                  (dateChange)="openDatePicker($event, 'date')" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

          </form>

        </ol>
      </div> -->

    </div>
  </div>
  <!-- [hidden]="true" -->

  <div class="card card-body ">
    <form [formGroup]="rentalListFilterForm">
      <div class="row">
        <mat-form-field class="col-md-3 col-1_5 pr-0">
          <mat-label>Ticket Id</mat-label>
          <input matInput type="number" min="1" maxlength="15" formControlName="txtTicketId" placeholder="Ticket Id"
            autocomplete="off" (ngModelChange)="onKeyPressSearch()" (focusout)="checkTicketIdLessthan0()">
        </mat-form-field>
        <mat-form-field class="col-md-3 col-1_5 pr-0">
          <mat-label>Chart No</mat-label>
          <input matInput type="text" maxlength="15" formControlName="txtChartId" placeholder="Chart No"
            autocomplete="off" (ngModelChange)="onKeyPressSearch()">
        </mat-form-field>

        <mat-form-field class="col-md-3 col-1_5 pr-0">
          <mat-label>Patient Name</mat-label>
          <input matInput type="text" maxlength="40" formControlName="txtPatientName" placeholder="Patient Name"
            pattern="^[^0-9]*$" autocomplete="off" (ngModelChange)="onKeyPressSearch()">
        </mat-form-field>


        <mat-form-field class="col-md-3 col-1_5  pr-0">
          <mat-label>DOS</mat-label>
          <input autocomplete="off" formControlName="txtDOSdate" matInput maxlength="10" type="text"
            placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDOSdate" (keydown)="addSlashToDate($event)"
            (ngModelChange)="onKeyPressSearch()" />

          <mat-datepicker-toggle matSuffix [for]="txtDOSdate">
          </mat-datepicker-toggle>
          <mat-datepicker #txtDOSdate> </mat-datepicker>
        </mat-form-field>
        <div class="col-md-4  pr-0">
          <!-- <div class="radio-font-size"> -->
          <mat-radio-group class="radio-font-size" formControlName="rStatus">
            <mat-radio-button class="active" (click)="fnRentalListStatusOnChange(0)" value="0">All</mat-radio-button>
            <mat-radio-button class="active p-2" checked="true" (click)="fnRentalListStatusOnChange(5)"
              value="5">Pending</mat-radio-button>
            <mat-radio-button class="active" (click)="fnRentalListStatusOnChange(4)"
              value="4">Completed</mat-radio-button>
          </mat-radio-group>
          <!-- </div> -->

          <i class="fa fa-search search align-items-center mr-2 ml-2" (click)="fnSearchPatient()"
            [class.disabled]="(!this.rentalListFilterForm.valid || !this.areFieldsNotEmpty())"></i>

            <i class="fa fa-refresh reset align-items-center" (click)="resetFilter()"></i>

        </div>


        <!-- <div class=" all-icon mr-2" matTooltip="Search">
          <i class="fa fa-search search align-items-center" (click)="fnSearchPatient()"
            [class.disabled]="(!this.rentalListFilterForm.valid || !this.areFieldsNotEmpty())"></i>

        </div>
        <div class=" all-icon" matTooltip="Reset">
          <i class="fa fa-refresh reset align-items-center" (click)="resetFilter()"></i>
        </div> -->
      </div>
    </form>
  </div>


  <!-- First Table -->

  <div class="row">
    <div class="col-12 mt-2">
      <div class="row">
        <!-- Shipping List Table -->
        <div class="col-12">
          <div class="card card-body">
            <!-- <div class="d-flex justify-content-center" *ngIf="!isLoaded">
              <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div> -->

            <div *ngIf="isLoaded" class="table table-responsive">
              <table datatable [dtOptions]="dtRentalListOptions" class="row-border hover w-100 display">
                <thead>
                  <tr>
                    <!-- class="text-right" -->
                    <th [matTooltip]="'Chart No'">Chart No</th>
                    <th [matTooltip]="'Patient Name'">Patient Name</th>
                    <th [matTooltip]="'Ticket Id'" class="text-right" >Ticket Id</th>
                    <th [matTooltip]="'DOS'">DOS</th>
                    <th [matTooltip]="'Total Month'" class="text-right" >Total Months</th>
                    <th [matTooltip]="'Total Amount($)'" class="text-right" >Total Amount($)</th>
                    <th [matTooltip]="'Status'">Status</th>

                  </tr>
                </thead>
                <tbody>
                  <tr [ngClass]="
                      this.tblShpSelectedRowId === data.rentalId
                        ? 'row-highlighted'
                        : ''
                    " (click)="tblShpSelectedRow(data.rentalId, data)" *ngFor="let data of rentalList">

                    <td [matTooltip]="data?.chartNo || '-'"> <a class="preview-link" [class.disabled]="
                          isEmpty(data.chartId) || isEmpty(data.patientId)
                        " [routerLink]="
                          '/patientCreateEditTab/' +
                          data.patientId +
                          '/' +
                          data.documentId
                        " target="_blank">{{ data?.chartNo ? data?.chartNo : "-" }}
                      </a></td>
                    <td [matTooltip]="data?.fullName || '-'">{{data.fullName ? data.fullName : '-'}}</td>
                    <td [matTooltip]="data?.mmRefereceOrderId || '-'" class="text-right"> <a class="preview-link"
                        [class.disabled]="
                            isEmpty(data.chartId) || isEmpty(data.patientId)
                          " [routerLink]="
                            '/orders/saleOrder/' +
                            data.patientId +
                            '/' +
                            data.mmOrderId+'/'+'0'
                          " target="_blank">{{
                        data?.mmRefereceOrderId
                        ? data?.mmRefereceOrderId
                        : "-"
                        }}
                      </a> </td>
                    <!-- <td [matTooltip]="data?.dosDate ? (data?.dosDate | date : 'MM/dd/yyyy' : 'en_US'):'-' ">
                      {{data.dosDate ? (data.dosDate | date : "MM/dd/yyyy" : "en_US"): '-'}}</td> -->
                      <td [matTooltip]="data?.dosDate" >{{  data?.dosDate }}</td>

                    <td [matTooltip]="data?.totalmonths || '-'" class="text-right">{{data.totalmonths ?
                      data.totalmonths : '-'}}</td>
                    <td [matTooltip]="data?.totalAmount || '-'" class="text-right">{{data.totalAmount ?
                      data.totalAmount : '-'}}</td>
                    <td [matTooltip]="data?.rentalStatus || '-'">{{data.rentalStatus ? data.rentalStatus : '-'}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Notes Details -->

      </div>
    </div>
  </div>

  <!-- Second Table -->

  <div class="row">
    <div class="col-12 mt-4">
      <div class="row">
        <!-- Shipping List Table -->
        <div class="col-12">
          <div class="card card-body">
            <div class="d-flex justify-content-center" *ngIf="!isTableLoaded">
              <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
            </div>

            <div *ngIf="isTableLoaded" class="table table-responsive">
              <table datatable [dtOptions]="dtRentalItemListOptions" class="row-border hover w-100 display">
                <thead>
                  <tr>
                    <!-- class="text-right" -->
                    <th [matTooltip]="'Ticket Id'" class="text-right">Ticket Id</th>
                    <th [matTooltip]="'Months'">Months</th>
                    <th [matTooltip]="'Supplies Amount ($)'" class="text-right">Supplies Amount ($)</th>
                    <th [matTooltip]="'Monthly Amount ($)'" class="text-right">Monthly Amount($)</th>
                    <th [matTooltip]="'Total Amount ($)'" class="text-right">Total Amount ($)</th>
                    <th [matTooltip]="'Received Date'">Received Date</th>
                    <th [matTooltip]="'Status'">Status</th>
                    <th [matTooltip]="'Waystar Status'">Waystar Status</th>

                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let item of rentalItemList">
                    <td [matTooltip]="item?.orderNo || '-'" class="text-right">
                      <a class="preview-link" [class.disabled]="
                          isEmpty(selectedRentalData.mmOrderId) || isEmpty(selectedRentalData.patientId)
                        " [routerLink]="
                          '/orders/saleOrder/' +
                          selectedRentalData.patientId +
                          '/' +
                          selectedRentalData.mmOrderId+'/'+'0'
                        " target="_blank">{{ item.orderNo ? item.orderNo :
                        '-' }}
                      </a>

                    </td>
                    <td [matTooltip]="item?.dosDate">{{
                      item.dosDate }}</td>

<!-- <td [matTooltip]="item?.suppliesAmount || '-'" class="text-right">
  {{ item.suppliesAmount ? (item.suppliesAmount | number:'1.2-2') : '-' }}
</td>
<td [matTooltip]="item?.orderAmount || '-'" class="text-right">
  {{ item.orderAmount ? (item.orderAmount | number:'1.2-2') : '-' }}
</td>
<td class="text-right" [matTooltip]="(item.suppliesAmount + item.orderAmount) || '-'">
  {{ (typeof item.orderAmount === 'number' && typeof item.suppliesAmount === 'number')
    ? ((item.orderAmount + item.suppliesAmount) | number:'1.2-2')
    : '-' }}
</td> -->

<td [matTooltip]="item?.suppliesAmount || '-'" class="text-right">{{item.suppliesAmount ?
  item.suppliesAmount :'-'}}</td>
<td [matTooltip]="item?.orderAmount || '-'" class="text-right">{{item.orderAmount ?
  item.orderAmount :'-'}}</td>
<td class="text-right" [matTooltip]="item.suppliesAmount + item.orderAmount || '-'" >
  <!-- {{
      (typeof item.orderAmount === 'number' && typeof item.suppliesAmount === 'number')
      ? (item.orderAmount + item.suppliesAmount)
      : '-'
    }} -->
      {{ (item.suppliesAmount + item.orderAmount )| number:'1.2-2' || '-' }}
</td>


                    <td [matTooltip]="item?.receiveDate ">
                      {{ item.receiveDate }}</td>
                    <td [matTooltip]="item?.rentalStatusName || '-'"> {{item.rentalStatusName ?
                      item.rentalStatusName : '-'}}</td>
                  <td [matTooltip]="item?.waystarStatus || '-'"> {{item.waystarStatus ?
                    item.waystarStatus : '-'}}</td>

                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Notes Details -->

      </div>
    </div>
  </div>
</div>
<app-loader-component *ngIf="!isLoaded"></app-loader-component>
