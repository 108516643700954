import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DateValidator } from 'projects/shared/src/app/validations/date-validator';
import Swal from 'sweetalert2';
import { UploadFilesService } from '../admin-proxy/platform-app-management/rcm/platform-management/upload-file';
import { SearchUploadFilesDTO, UploadFilesDTO } from '../admin-proxy/platform-app-management/rcm/platform-management/upload-file/dto/models';
import { batchDetails } from '../upload-bin-botc/upload-bin-botc.component';
import { dateFormatter } from '../sub-batch-list/sub-batch-list.component';
import { IdentityUserDto } from '../admin-proxy/volo/abp/identity/models';
import { UserService } from '../admin-proxy/platform-app-management/rcm/platform-management/user/user.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-archive-bot-doc',
  templateUrl: './archive-bot-doc.component.html',
  styleUrls: ['./archive-bot-doc.component.scss']
})
export class ArchiveBotDocComponent implements OnInit {
  isLoading: boolean = true;
  batchUploadBinForm: FormGroup;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) tableRef: MatTable<any>;
  arrDisplayedColumns: string[] = [];
  arrUsersList: IdentityUserDto[] = [];  
  arrTableData: any[] = [];

  constructor(
    public fb: FormBuilder,
    public dateValidators: DateValidator,
    private router: Router,
    private uploadFilesService: UploadFilesService,
    public userService: UserService,
  ) { }

  ngOnInit(): void {
    this.isLoading = false;
    this.batchUploadBinForm = this.fb.group({
      documentName: new FormControl(""),
      documentType: new FormControl(""),
      uploadedDate: new FormControl("", [this.dateValidators.dateVaidator]),
      uploadedBy: new FormControl(""),
      defaultBatchId: new FormControl(""),
      assignedByUserName: new FormControl(""),
      fileName: new FormControl(""),
      fileSize: new FormControl(""),
      numberOfPages: new FormControl(""),
      fileStatus: new FormControl(""),
      assignedToUserName: new FormControl("")
    });
    this.arrDisplayedColumns = ["defaultBatchId", "fileName", "%completed", "isStat", "fileStatus", "fileSize", "numberOfPages", "uploadedDate", "uploadedBy", "Options"];
    this.batchUploadBinForm.valueChanges.subscribe(value => {
      const sfrmvalue = this.batchUploadBinForm.value;
      if (this.batchUploadBinForm?.valid) {
        const searchValue: SearchUploadFilesDTO = {
          defaultFileId: sfrmvalue?.defaultBatchId ?? null,
          fileName: sfrmvalue?.fileName ?? null,
          fileStatus: sfrmvalue?.fileStatus ?? null,
          fileSize: sfrmvalue?.fileSize ?? null,
          numberOfPages: sfrmvalue?.numberOfPages ?? null,
          uploadedOn: sfrmvalue?.uploadedDate ?? null,
          uploadedBy: sfrmvalue?.uploadedBy ?? null,
          assignedBy: sfrmvalue?.assignedByUserName ?? null,
          assignedTo: sfrmvalue?.assignedToUserName ?? null,
          uploadedFrom: ""
        }
        this.searchByInputs(searchValue);
      }
    })
    this.getUsersList();
  }

  // get uploaded file list details
  loadTableData() {
    this.isLoading = true;
    this.arrTableData = [];
    this.dataSource = new MatTableDataSource(this.arrTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    let arrTableData: batchDetails[] = [];
    this.uploadFilesService.getUploadFilesByArchiveStatusByArchiveStatus(true).subscribe(response => {
    //this.uploadFilesService.getUploadedFileList().subscribe(response => {
      const faxBinDataTable: UploadFilesDTO[] = response;
      arrTableData = this.extractBatchTableDetails(faxBinDataTable);
      this.arrTableData = [...arrTableData];
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

   searchByInputs(searchValue: SearchUploadFilesDTO) {
    this.arrTableData = [];
    this.dataSource = new MatTableDataSource(this.arrTableData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.uploadFilesService.searchFilesBasedOnStatusByArchiveStatusAndOSearchUploadFiles(true,searchValue).subscribe(response => {
      let arrTableData: batchDetails[] = [];
      const faxBinDataTable: batchDetails[] = this.extractBatchTableDetails(response);
      this.arrTableData = [...faxBinDataTable];
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, err => {
      this.dataSource = new MatTableDataSource(this.arrTableData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
  }

  unarchiveFileById(id: string){
    Swal.fire({
      title: 'Are you sure you want to unarchive?',
      //text: "You won't be able to retrieve this data!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes',
    }).then(result => {
      if (result.value) {
        id && this.uploadFilesService.updateArchiveStatusByFileIdAndArchiveStatus(id,false).subscribe(response => {
            this.loadTableData(); 
        }, err => {
          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        });
      }
    });
  }

  // get extreaction deatils
  private extractBatchTableDetails(faxBinDataTable: UploadFilesDTO[]): batchDetails[] {
    let arrTableData: batchDetails[] = [];
    try {
      faxBinDataTable.forEach(v => {
        if (v && v !== null && v?.archived && !v?.isDeleted) {
          let data: batchDetails = {
            id: v?.id ?? "",
            defaultBatchId: v?.defaultFileId ?? "",
            uploadedBy: this.getUserName(v?.uploadedBy ?? "") ?? "",
            // uploadedFrom: v?.uploadedFrom ?? "",
            uploadedOn: dateFormatter(v?.uploadedOn ?? "") ?? "",
            fileSize: v?.fileSize ?? "",
            fileStatus: v?.fileStatus ?? "",
            fileName: v?.fileName ?? "",
            isStat: v?.isStat ?? 0,
            numberOfPages: v?.numberOfPages ?? 0,
            assignedBy: v?.assignedBy ?? "",
            assignedTo: v?.assignedTo ?? "",
            blobName: v?.blobName ?? "",
            fileId: v?.id ?? "",
            assignedToUserName: v?.assignedToUserName ?? "",
            assignedByUserName: v?.assignedByUserName ?? "",
            percentageOfCompletion: v?.percentageOfCompletion ?? 0,
            sortingStatus: v?.sortingStatus ?? "",
            patientsId: v?.patientsId ?? "",
            overallStatus: v?.overallStatus ?? "",
            identifiedPatientList: of(v?.identifiedPatientList) ?? of([]),
            etrPtDetails: v?.extractedPatientDetails,
            reason4AppOrRej: v?.reasonForRejected ?? ""
          };

          arrTableData.push(data);
        }
      });
    } catch (error) {
      arrTableData = [];
    }
    return arrTableData;
  }

  // get Username by ID
  private getUserName(value: string) {
    if (value && typeof value === "string" && value?.length >= 36) {
      return this.arrUsersList?.filter(u => u?.id === value)[0]?.userName ?? "";
    }
    return ""
  }

  // get Users name list
  private getUsersList() {

    this.userService.getIdentityUser().subscribe((response: IdentityUserDto[]) => {
      this.arrUsersList = response;
      this.loadTableData();
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
  }

  backToUploadDoc(){
    this.router.navigate(['uploadBinC']);
  }
}
