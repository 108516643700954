import type {
  CreateUpdateProductStockDetailsDTO,
  ProductStockDetailStatus,
  ProductStockDetailsByBranchDTO,
  ProductStockDetailsDTO,
  SearchProductStockDetailsDTO,
  UploadProductStockSerialNumbersDTO,
  ZeroStockProductDetailsDTO,
} from './dto/models';
import { RestService } from '@abp/ng.core';
import type {
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductStockDetailsService {
  apiName = 'ItemManagement';

  checkProductStockByProductIdAndOrderingQuantity = (
    productId: string,
    orderingQuantity: number
  ) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/ItemApp/product-stock-details/check-product-stock/${productId}`,
        params: { orderingQuantity },
      },
      { apiName: this.apiName }
    );

  create = (input: CreateUpdateProductStockDetailsDTO) =>
    this.restService.request<any, ProductStockDetailsDTO>(
      {
        method: 'POST',
        url: '/api/ItemApp/product-stock-details',
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/ItemApp/product-stock-details/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, ProductStockDetailsDTO>(
      {
        method: 'GET',
        url: `/api/ItemApp/product-stock-details/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: PagedAndSortedResultRequestDto) =>
    this.restService.request<any, PagedResultDto<ProductStockDetailsDTO>>(
      {
        method: 'GET',
        url: '/api/ItemApp/product-stock-details',
        params: {
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          sorting: input.sorting,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CreateUpdateProductStockDetailsDTO) =>
    this.restService.request<any, ProductStockDetailsDTO>(
      {
        method: 'PUT',
        url: `/api/ItemApp/product-stock-details/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getSearchStocksByLotNoAndPricePerUnitAndQuantityAndSoldQuantityAndProductType =
    (
      productName: string,
      categoryName: string,
      brandName: string,
      lotNo: string,
      pricePerUnit: string,
      quantity: string,
      soldQuantity: string,
      balanceQuantity: string,
      productType: string,
      status: ProductStockDetailStatus,
      createdDate: string
    ) =>
      this.restService.request<any, ProductStockDetailsDTO[]>(
        {
          method: 'GET',
          url: '/api/ItemApp/product-stock-details/get-search-stocks',
          params: {
            productName,
            categoryName,
            brandName,
            lotNo,
            pricePerUnit,
            quantity,
            soldQuantity,
            balanceQuantity,
            productType,
            status,
            createdDate,
          },
        },
        { apiName: this.apiName }
      );
  getSearchStocksBasedonFilter = (
    productName: string[],
    status: string[],
    fromDate: string,
    toDate: string
  ) =>
    this.restService.request<any, SearchProductStockDetailsDTO[]>(
      {
        method: 'GET',
        url: '/api/ItemApp/product-stock-details/get-search-stocks-basedon-filters',
        params: { productName, status, fromDate, toDate },
      },
      { apiName: this.apiName }
    );

  importProductStockSerialNumbers = (
    input: UploadProductStockSerialNumbersDTO
  ) =>
    this.restService.request<any, ProductStockDetailsDTO>(
      {
        method: 'POST',
        url: '/api/ItemApp/product-stock-details/import-product-stock-serial-numbers',
        body: input,
      },
      { apiName: this.apiName }
    );

  getProductStock = (productId: string) =>
    this.restService.request<any, SearchProductStockDetailsDTO[]>(
      {
        method: 'POST',
        url: `/api/ItemApp/product-stock-details/product-stock-details/${productId}`,
      },
      { apiName: this.apiName }
    );

  branchProductDetailsByGBranchIdAndGProductId = (
    gBranchId: string,
    gProductId: string
  ) =>
    this.restService.request<any, SearchProductStockDetailsDTO[]>(
      {
        method: 'POST',
        url: '/api/ItemApp/product-stock-details/branch-product-details',
        params: { gBranchId, gProductId },
      },
      { apiName: this.apiName }
    );

  productStockDetailsBranchWiseByGBranchId = (gBranchId: string) =>
    this.restService.request<any, SearchProductStockDetailsDTO[]>(
      {
        method: 'POST',
        url: `/api/ItemApp/product-stock-details/product-stock-details-branch-wise/${gBranchId}`,
      },
      { apiName: this.apiName }
    );

  getProductStockDetailsByBranch = () =>
    this.restService.request<any, ProductStockDetailsByBranchDTO[]>(
      {
        method: 'GET',
        url: '/api/ItemApp/product-stock-details/product-stock-details-by-branch',
      },
      { apiName: this.apiName }
    );
  getProductStockByBranchandProducts = (
    productId: string,
    lstBranches: string[]
  ) =>
    this.restService.request<any, SearchProductStockDetailsDTO[]>(
      {
        method: 'POST',
        url: `/api/ItemApp/product-stock-details/product-stock-details/${productId}`,
        body: lstBranches,
      },
      { apiName: this.apiName }
    );

  productStockDetailsForAllBranchByProductId = (productId: string) =>
    this.restService.request<any, SearchProductStockDetailsDTO[]>(
      {
        method: 'POST',
        url: `/api/ItemApp/product-stock-details/product-stock-details-for-all-branch/${productId}`,
      },
      { apiName: this.apiName }
    );

  productStockDetailsForAllBranchV1ByLastReceivedFromDateAndLastReceivedToDateAndGManufacturerAndGCategoryAndGBranchIdsAndProductIdAndIsProductFilter =
    (
      lastReceivedFromDate: string,
      lastReceivedToDate: string,
      gManufacturer: string,
      gCategory: string,
      gBranchIds: string[],
      productId: string,
      isProductFilter: boolean,
      pageNo: Number,
      pageOffset: Number
    ) =>
      this.restService.request<any, SearchProductStockDetailsDTO[]>(
        {
          method: 'POST',
          url: `/api/ItemApp/product-stock-details/product-stock-details-for-all-branch-v1/${productId}`,
          params: {
            lastReceivedFromDate,
            lastReceivedToDate,
            gManufacturer,
            gCategory,
            isProductFilter,
            pageNo,
            pageOffset
          },
          body: gBranchIds,
        },
        { apiName: this.apiName }
      );

  productStockDetailsV1ByGBranchIdsAndProductId = (
    gBranchIds: string[],
    productId: string,
    pageNo: number,
    pageOffset : number
  ) =>
    this.restService.request<any, SearchProductStockDetailsDTO[]>(
      {
        method: 'POST',
        url: `/api/ItemApp/product-stock-details/product-stock-details-v1/${productId}`,
        params: {
          pageNo,
          pageOffset
        },
        body: gBranchIds,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
