<form [formGroup]="eobSearchForm" >
  <div class="row" id="dropdown-container">
     <div class="col-12 d-flex">
          <!-- Payer -->
          <mat-form-field class="col-3" appearance="none">
            <mat-label class="drpLabel">Payer</mat-label>
            <mat-select
              multiple
              (closed)="buildFilterParams()"
              formControlName="drpPayer"
            >
              <mat-option style="font-size: 12px;">
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  hideClearSearchButton="true"
                  (keyup)="searchPayor($event.target.value)"
                  formControlName="txtPayerFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option style="font-size: 12px;"
                [value]="data.payerId"
                *ngFor="let data of filteredPayors | async"
                >{{ data.payerName }}</mat-option
              >
            </mat-select>
            <button
              mat-button
              *ngIf="
                eobSearchForm.get('drpPayer').value != '' &&
                eobSearchForm.get('drpPayer').value != null &&
                eobSearchForm.get('drpPayer').value != undefined
              "
              (click)="
                clearPayer(); $event.stopPropagation(); buildFilterParams()
              "
              matSuffix
              mat-icon-button
            >
            <mat-icon style="font-size: 16px !important;">close</mat-icon>
            </button>
          </mat-form-field>
           <!-- Status-->
           <mat-form-field class="col-2" appearance="none">
            <mat-label class="drpLabel">Status</mat-label>
            <mat-select
              multiple
              (closed)="buildFilterParams()"
              formControlName="drpStatus"
            >
              <mat-option style="font-size: 12px;">
                <ngx-mat-select-search
                  placeholderLabel="Search"
                  noEntriesFoundLabel="No Matches found"
                  hideClearSearchButton="true"
                  formControlName="txtStatusFilter"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option style="font-size: 12px;"
                *ngFor="let data of filteredStatuses | async"
                [value]="data.statusId"
                >{{ data.statusName }}</mat-option
              >
            </mat-select>
            <button
              mat-button
              *ngIf="
                eobSearchForm.get('drpStatus').value != '' &&
                eobSearchForm.get('drpStatus').value != null &&
                eobSearchForm.get('drpStatus').value != undefined
              "
              (click)="
                clearStatus(); $event.stopPropagation(); buildFilterParams()
              "
              matSuffix
              mat-icon-button
            >
            <mat-icon style="font-size: 16px !important;">close</mat-icon>
            </button>
          </mat-form-field>


          <!-- EFT/Check Number -->
          <mat-form-field class="col-2" class="drpLabel">
            <input
              matInput
              (change)="buildFilterParams()"
              formControlName="txtCheckNumber"
              placeholder="EFT/Check Number"
              maxlength="75"
          /></mat-form-field>

          <!-- Amount -->
          <mat-form-field class="col-1" class="drpLabel" style="padding-left: 15px;">
            <input
              matInput
              (change)="buildFilterParams()"
              formControlName="txtAmount"
              placeholder="Amount"
              type="number"
              maxlength="75"
          /></mat-form-field>


          <!-- Remittance Date -->
          <mat-form-field class="col-2" appearance="none">
            <mat-label class="drpLabel">Remittance Date</mat-label>
            <mat-date-range-input [rangePicker]="pickerRemittance">
              <input
                matStartDate
                (keydown)="addSlashToDate($event)"
                matInput
                formControlName="txtRemittanceStartDate"
                style="font-size: 12px;"
                placeholder="Start Date"
                maxlength="10"
              />

              <input
                matEndDate
                matInput
                (dateChange)="onRemittanceDateChange($event)"
                (keydown)="addSlashToDate($event)"
                formControlName="txtRemittanceEndDate"
                style="font-size: 12px;"
                placeholder="End date"
                maxlength="10"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerRemittance" style="font-size: 12px !important;"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #pickerRemittance></mat-date-range-picker>
            <button
              mat-button
              *ngIf="
                (eobSearchForm.get('txtRemittanceStartDate').value != '' &&
                  eobSearchForm.get('txtRemittanceStartDate').value != null &&
                  eobSearchForm.get('txtRemittanceStartDate').value !=
                    undefined) ||
                (eobSearchForm.get('txtRemittanceEndDate').value != '' &&
                  eobSearchForm.get('txtRemittanceEndDate').value != null &&
                  eobSearchForm.get('txtRemittanceEndDate').value != undefined)
              "
              (click)="
                clearRemittanceDate();
                $event.stopPropagation();
                buildFilterParams()
              "
              matSuffix
              mat-icon-button
            >
            <mat-icon style="font-size: 16px !important;">close</mat-icon>
            </button>
          </mat-form-field>

          <!-- Created Date -->
          <mat-form-field class="col-2" appearance="none">
            <mat-label class="drpLabel">Created Date</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                matInput
                (keydown)="addSlashToDate($event)"
                placeholder="Start date"
                formControlName="txtCreatedStartDate"
              />

              <input
                matEndDate
                (dateChange)="onCreatedDateChange($event)"
                (keydown)="addSlashToDate($event)"
                matInput
                placeholder="End date"
                formControlName="txtCreatedEndDate"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker" style="font-size: 12px !important;"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <button
              mat-button
              *ngIf="
                (eobSearchForm.get('txtCreatedStartDate').value != '' &&
                  eobSearchForm.get('txtCreatedStartDate').value != null &&
                  eobSearchForm.get('txtCreatedStartDate').value != undefined) ||
                (eobSearchForm.get('txtCreatedEndDate').value != '' &&
                  eobSearchForm.get('txtCreatedEndDate').value != null &&
                  eobSearchForm.get('txtCreatedEndDate').value != undefined)
              "
              (click)="
                clearCreatedDate(); $event.stopPropagation(); buildFilterParams()
              "
              matSuffix
              mat-icon-button
            >
            <mat-icon style="font-size: 16px !important;">close</mat-icon>
            </button>
          </mat-form-field>
     </div>
  </div>
</form>
<div class="d-flex justify-content-center" *ngIf="isLoading">
  <img class="img-responsive pb-2 mr-1" src="assets/gifs/Infinity-loader.gif" />
</div>

      <table
      *ngIf="!isLoading"
          datatable
          [dtOptions]="eobTable"
          class="row-border hover w-100 display"
        >
          <thead>
            <tr>
              <th [matTooltip]="'Options'"><mat-icon>apps</mat-icon></th>
              <th [matTooltip]="'EFT/Check Number'">EFT/Check Number</th>
              <th [matTooltip]="'Payer ID'">Payer ID</th>
              <th [matTooltip]="'Payer'">Payer</th>
              <th [matTooltip]="'Amount($)'">Amount($)</th>
              <th [matTooltip]="'Payment Method'">Payment Method</th>
              <th [matTooltip]="'Remittance Date'">Remittance Date</th>
              <th [matTooltip]="'Created By'">Created By</th>
              <th [matTooltip]="'Created Date'">Created Date</th>
              <th [matTooltip]="'Last Modified By'">Last Modified By</th>
              <th [matTooltip]="'Last Modified Date'">Last Modified Date</th>
              <th [matTooltip]="'Status'">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inBoundTableData">
              <td>
                <mat-icon class="pointer toggle-icon" (click)="getTotalInboundData($event, data)">add</mat-icon>

                <mat-icon style="cursor: pointer !important" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-menu class="custom-mat-menu" #menu="matMenu">
                    <button (click)="openViewNotesPopup(data.patientId)"
                        [disabled]="isEmpty(data.patientId)" mat-menu-item>
                        View Patient Notes
                    </button>
                    <button mat-menu-item (click)="viewPatientLedger(data?.patientId)"
                        [disabled]="isEmpty(data.patientId)">
                        View Patient Ledger
                    </button>
                </mat-menu>
              </td>
              <td (dblclick)="onRowDoubleClick(data.sCheckNumber || '-')" [matTooltip]="data.sCheckNumber || '-'">
                {{ data.sCheckNumber || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.sPayerCode || '-')" [matTooltip]="data.sPayerCode || '-'">
                {{ data.sPayerCode || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.sPayerName || '-')" [matTooltip]="data.sPayerName || '-'">
                {{ data.sPayerName || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data.dCheckAmount || '-')" class="text-right" [matTooltip]="data.dCheckAmount || '-'">
                {{ data.dCheckAmount || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.paymentMethodName || '-')" [matTooltip]="data.paymentMethodName || '-'">
                {{ data.paymentMethodName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.dtRemitance || '-')" [matTooltip]="data.dtRemitance || '-'">
                {{ data.dtRemitance || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.createdByName || '-')" [matTooltip]="data.createdByName || '-'">
                {{ data.createdByName || "-" }}
              </td>
              <td (dblclick)="onRowDoubleClick(data.dtCreated || '-')" [matTooltip]="data.dtCreated || '-'">
                {{ data.dtCreated || "-" }}
              </td>
              <td [matTooltip]="data.modifiedByName || '-'">
                {{ data.modifiedByName || "-" }}
              </td>
              <td [matTooltip]="data.dtModified || '-'">
                {{ data.dtModified || "-" }}
              </td>

              <td (dblclick)="onRowDoubleClick(data.eobStatusName || '-')"[matTooltip]="data.eobStatusName" class="text-center">
                <div class="d-flex align-items-center">
                  <span
                    *ngIf="data.eobStatusName === 'Open'"
                    [ngStyle]="{
                      'background-color': '#16aeff',
                      color: '#ffffff'
                    }"
                    class="rounded-border ml-1 w-75 p-1 claim-status-label"
                  >
                    {{ data.eobStatusName }}
                  </span>
                  <span
                    *ngIf="data.eobStatusName === 'Processed'"
                    [ngStyle]="{
                      'background-color': '#7e7474',
                      color: '#ffffff'
                    }"
                    class="rounded-border ml-1 w-75 p-1 claim-status-label"
                  >
                    {{ data.eobStatusName }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
      </table>
