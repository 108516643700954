import { Component, OnInit } from '@angular/core';
import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import {
  ColorCodingDTO,
  CreateUpdateColorCodingDTO,
} from '../dropdown-proxy/dropdown-management/dropdowns/dto/patient';
import { defaultGuid } from 'projects/admin/src/app/document-perviewer/document-perviewer.component';
import { ColorCodingService } from 'projects/admin/src/app/admin-proxy/platform-app-management/rcm/platform-management/dropdowns/color-coding.service';
import { noWhitespaceValidator } from '../add-note-tab/add-note-tab.component';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ViewChild } from '@angular/core';
@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})

export class TimerComponent implements OnInit {
  step: boolean = false;
  AppontmentForm: FormGroup;
  isShowSpinner: boolean;
  saveButtonHide: boolean;
  bgcolor: string;
  colorCodeId: string = defaultGuid;
  defaultGuid = defaultGuid;
  txtStatusName: string = '';
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  public disabled = false;
  public color: ThemePalette = 'primary';
  public touchUi = false;
  dataSource: MatTableDataSource<any>;
  subscription$: Subscription[] = [];
  tableData: CustomColorCodingDTO[] = [];
  colorCodingDetails: CreateUpdateColorCodingDTO;
  displayedColumns: string[] = [
    'options',
    'Timer',
    'Worker',
    'creationTime',
  ];
  durationShow: boolean = false;
  //colorCtr: AbstractControl = new FormControl(null);
  constructor(
    private formBuilder: FormBuilder,
    private colorCodingService: ColorCodingService,
    private toastr: ToastrService,
    public title: Title
  ) {}

  ngOnInit(): void {
    this.step = true;
    this.AppontmentForm = this.formBuilder.group({
      txtTime: new FormControl('', Validators.required),
      txtStatusName: new FormControl('', [
        Validators.required,
        //noWhitespaceValidator,
      ]),
    });
    this.getColorCodesList();

    this.AppontmentForm.get('txtTime').valueChanges.subscribe((a) => {
      this.bgcolor = a;
    });
    this.title.setTitle('Qsecure | Color Codes');
  }

  //To get the Color Codes List
  getColorCodesList() {
    this.isShowSpinner = true;
    const colorCodesList = this.colorCodingService
      .getList(new PagedAndSortedResultRequestDto())
      .subscribe(
        (response) => {
          this.tableData = response?.items?.map((x, i = 1) => {
            const y = {
              sNo: ++i,
              ...x,
            };
            return y;
          });
          this.isShowSpinner = false;
          this.tableData = this.tableData.slice(0, 10);
          this.dataSource = new MatTableDataSource(this.tableData);
        
         // this.dataSource.sort = this.sort;
         // this.dataSource.paginator = this.paginator;
        },
        (err) => {
          this.saveButtonHide = false;
          this.isShowSpinner = false;
          const data: HttpErrorResponse = err;
          // Swal.fire({
          //   icon: 'error',
          //   text: data?.error?.error?.message,
          // });
        }
      );
    this.subscription$.push(colorCodesList);
  }

  //To Save the Color Code
  saveStatusColorCode() {
    // let colorCodingDetails: CreateUpdateColorCodingDTO;

    if (this.colorCodeId !== defaultGuid) {
      this.colorCodingDetails = {
        colorCodingName: this.AppontmentForm.value.txtStatusName
          .toString()
          .trim(),
        colorCode: '#' + this.AppontmentForm.value.txtTime.hex,
      };

      this.colorCodingService
        .update(this.colorCodeId, this.colorCodingDetails)
        .subscribe(
          (response) => {
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Color Code Updated Successfully',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Color Code Updated Successfully', 'Success');
            this.resetColorCodes();
            this.getColorCodesList();
          },
          (err) => {
            this.saveButtonHide = false;
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
    } else if (this.colorCodeId === defaultGuid) {
      this.colorCodingDetails = {
        colorCodingName: this.AppontmentForm.value.txtStatusName
          .toString()
          .trim(),
        colorCode: '#' + this.AppontmentForm.value.txtTime.hex,
      };

      this.colorCodingService.create(this.colorCodingDetails).subscribe(
        (response) => {
          // Swal.fire({
          //   title: 'Success',
          //   html: 'Color Code Added Successfully',
          //   icon: 'success',
          //   timer: 3000,
          //   timerProgressBar: true,
          // });
          this.toastr.success('Color Code Added Successfully', 'Success');
          this.resetColorCodes();
          this.getColorCodesList();
        },
        (err) => {
          this.saveButtonHide = false;

          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    }
  }

  //View Color Code
  viewColorCode(id: string) {
    if (this.step == false) {
      this.step = true;
    }
    const colorCodes = this.colorCodingService.get(id).subscribe(
      (response) => {
        this.AppontmentForm.patchValue({
          txtTime: response.colorCode,
          txtStatusName: response.colorCodingName,
        });
        this.colorCodeId = response?.id ?? '';
        // this.colorCodingDetails={
        //   colorCodingName: response.colorCodingName,
        //   colorCode:  response.colorCode,
        // }
      },
      (err) => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      }
    );
    this.subscription$.push(colorCodes);
  }

  //Delete functionality for colorcode
  deleteColorCode(id: string) {
    // const colorCodeDet = this.colorCodingService.delete(id).subscribe(
    //   (response) => {
    //     Swal.fire({
    //       title: 'Success',
    //       html: 'Color Code Deleted',
    //       icon: 'success',
    //       timer: 3000,
    //       timerProgressBar: true,
    //     });
    //     this.getColorCodesList();
    //   },
    //   (err) => {
    //     this.saveButtonHide = false;
    //     this.isShowSpinner = false;
    //     const data: HttpErrorResponse = err;
    //     Swal.fire({
    //       icon: 'error',
    //       text: data?.error?.error?.message,
    //     });
    //   }
    // );

    Swal.fire({
      title: 'Are you sure you want to Delete?',
      text: "You won't be able to retrieve this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        const colorCodeDet = this.colorCodingService.delete(id).subscribe(
          (response) => {
            // Swal.fire({
            //   title: 'Success',
            //   html: 'Color Code Deleted',
            //   icon: 'success',
            //   timer: 3000,
            //   timerProgressBar: true,
            // });
            this.toastr.success('Color Code Deleted', 'Success');
            this.getColorCodesList();
          },
          (err) => {
            this.saveButtonHide = false;
            this.isShowSpinner = false;
            const data: HttpErrorResponse = err;
            Swal.fire({
              icon: 'info',
              text: data?.error?.error?.message,
            });
          }
        );
      }
    });
  }

  //Reset Color Codes
  resetColorCodes() {
    this.txtStatusName = '';
    this.AppontmentForm.reset();
    this.AppontmentForm.patchValue({
      txtTime: '',
      txtStatusName: '',
    });
    this.colorCodeId = defaultGuid;
  }

  //On Destroy Function
  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }

  toggle() {
    this.step = true;
  }
  toggleV2() {
    this.step = false;
  }
}
export interface CustomColorCodingDTO extends ColorCodingDTO {
  sNo: number;
}
