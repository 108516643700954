import type { ApprovalStatus } from '../approval-status.enum';
import type { AuditedEntityDto, FullAuditedEntityDto } from '@abp/ng.core';
import type { ShippingApprovalStatus } from '../shipping-approval-status.enum';
import { RentalTypes } from '../rental-types.enum';
import {
  PatientPersonalDTO,
  PatientAddressDTO,
  NoteType,
} from 'projects/patient/src/app/patient-proxy/patient-optimization/dto';
import { InsuranceType } from '../../../patient/enumeration-data/insurance-type.enum';
import {
  CreateUpdateMasterProviderDTO,
  MasterProviderDTO,
  NotesType,
} from '../../inventory/dto';
import { proudctPriceType } from '../proudct-price-type.enum';
import { RentalOrderJobStatus } from '../rental-order-job-status.enum';
import { QuitStatus } from '../quit-status.enum';
import { UserByRoleDTO } from 'projects/admin/src/app/admin-proxy/platform-app-management/identity';
import { AddressType } from '../../rcm/patient-management/patient/enumeration-data/address-type.enum';
import { Status } from 'projects/inventory/src/app/item-proxy/item-management/items';
import {} from '../rental-types.enum';
import { mmRentalTypes } from '../mm-rental-types.enum';
import { PrintLabel } from '../print-label.enum';
import {
  VerificationStatus,
  VerificationStatusV2,
} from 'projects/patient/src/app/patient-proxy/patient/enumeration-data/verification-status.enum';
import { FullAuditedAggregateRoot } from 'projects/admin/src/app/admin-proxy/platform-app-management/domain/entities/auditing';
import { SupplyInactivationType } from '../supply-inactivation-type.enum';
import { WonaceOrderTab } from '../wonace-order-tab.enum';

export interface CreateUpdateOrderDetailsDTO {
  patientId?: string;
  defaultSaleorderId?: string;
  scheduledDate?: string;
  scheduledTime?: string;
  actualDate?: string;
  actualTime?: string;
  address?: string;
  city?: string;
  state?: string;
  county?: string;
  country?: string;
  postalCode?: string;
  phone?: string;
  mobile?: string;
  orderNote?: string;
  deliveryNote?: string;
  placeOfService?: string;
  isCompleted: boolean;
  status?: string;
  isCashPayment: number;
  payPalPaymentMethodType?: string;
  payPalPaymentStatus?: string;
  payPalMailResendCount: number;
  tenantId?: string;
  isTemplate: number;
}

export interface CreateUpdateReminderDetailDTO {
  patientId: string;
  saleOrderId?: string;
  tenantId?: string;
  assignedTo: string[];
  reminderDate?: string;
  subject: string;
  isCompleted: number;
  isDontShowAgain: number;
}

export interface CreateUpdateSaleOrderItemDTO {
  patientId: string;
  saleOrderId: string;
  itemId?: string;
  itemProcedureCode?: string;
  itemName?: string;
  insuranceName?: string;
  description?: string;
  location?: string;
  note?: string;
  isSpecialPricing: number;
  isTaxable: number;
  isPriceOverride: number;
  isSpecialTaxRate: number;
  isAcceptAssignment: number;
  isManualConvertToPurchase: number;
  isAbn: number;
  isAbnUpgrade: number;
  isCbOverride: number;
  itemSize?: string;
  modifiers1?: string;
  modifiers2?: string;
  modifiers3?: string;
  modifiers4?: string;
  charge: number;
  allow?: string;
  orderingQuantity: number;
  billingQuantity: number;
  isPrimary: number;
  coverageType?: string;
  claimSubmission?: string;
  isSecondary: number;
  isTertiary: number;
  isPatient: number;
  isDaysOfSupplyOverride: number;
  dosFrom?: string;
  dosTo?: string;
  nextPeriod?: string;
  isSpanDateSplitBillingEnabled: number;
  isFollowupNeeded: boolean;
  isReturned: boolean;
  followUpDuration: number;
  productId?: string;
  isTemplate: number;
  billedType: proudctPriceType;
  rentalresupplySaleOrder: CreateUpdateRentalSaleOrderTemplateDTO;
}

export interface CreateUpdateShippingApprovalDTO {
  orderId?: string;
  defaultSaleOrderId?: string;
  patientId?: string;
  defaultPatientId?: string;
  patientName?: string;
  dos?: string;
  userName?: string;
  documentApproval: ApprovalStatus;
  verificationApproval: ApprovalStatus;
  authorizationApproval: ApprovalStatus;
  tenantId?: string;
}
export interface ShippingApprovalDTO extends AuditedEntityDto<string> {
  orderId?: string;
  defaultSaleOrderId?: string;
  patientId?: string;
  defaultPatientId?: string;
  patientName?: string;
  dos?: string;
  userName?: string;
  documentApproval: ApprovalStatus;
  verificationApproval: ApprovalStatus;
  authorizationApproval: ApprovalStatus;
  status: ShippingApprovalStatus;
  tenantId?: string;
}
export interface CreateUpdateInvoiceDTO {
  invoiceCode?: string;
  patientId?: string;
  saleorderId?: string;
  claimId?: string;
  invoiceType?: InsuranceType;

  amountDue: number;
  amountPaid: number;
  balance: number;
  isApproved?: boolean;
  invoiceDate?: string;
}
export interface RentalSaleOrderTemplateDTO extends AuditedEntityDto<string> {
  templateCode?: string;
  saleOrderId?: string;
  rentalTypes: RentalTypes;
  startDate?: string;
  endPeriod: number;
  lastRun?: string;
  nextRun?: string;
  periodsRun: number;
  isEnabled: number;
  templateSummary?: string;
  monthDay: number;
  lstMonths: number[];
  weeknumber: number;
  yearNumber: number;
  lstSaleOrderIds: string[];
  lstWeeks: number[];
  lstYears: string[];
  status: RentalOrderJobStatus;
  statusType?: QuitStatus;
}
export interface CreateUpdateRentalSaleOrderTemplateDTO {
  templateCode?: string;
  saleOrderId?: string;
  rentalTypes: RentalTypes;
  startDate?: string;
  endPeriod: number;
  nextRun?: string;
  periodsRun: number;
  isEnabled: number;
  templateSummary?: string;
  monthDay: number;
  lstMonths: number[];
  weeknumber: number;
  yearNumber: number;
  lstWeeks: number[];
  lstYears: string[];
  status: RentalOrderJobStatus;
  statusType?: QuitStatus;
}
export interface InvoiceDTO extends AuditedEntityDto<string> {
  invoiceCode?: string;
  id: string;
  patientId?: string;
  sPatientId?: string;
  sSaleorderId?: string;
  sPatientName?: string;
  saleorderId?: string;
  claimId?: string;
  invoiceType: InsuranceType;
  amountDue: number;
  amountPaid: number;
  balance: number;
  isApproved?: boolean;
  tenantId?: string;
  invoiceDate?: string;
}

export interface InvoiceItemViewDTO {
  insurancePaymentId?: string;
  claimId?: string;
  claimNumber?: string;
  description?: string;
  dosFrom?: string;
  dosTo?: string;
  planType?: string;
  checkNo?: string;
  checkDate?: string;
  itemProcedureCode?: string;
  billed?: string;
  allowed?: string;
  coinsurance?: string;
  deductAmount?: string;
  checkAmount?: string;
  postingDate?: string;
  insurancePaid?: string;
  currentBalance?: string;
  adjustments: number;
  eobBlobName?: string;
  ediBlobName?: string;
  postingStatus?: string;
  id?: string;
}

export interface InvoiceViewDTO {
  invoiceCode?: string;
  patientId?: string;
  saleorderId?: string;
  claimId?: string;
  invoiceType?: string;
  amountDue: number;
  amountPaid: number;
  balance: number;
  isApproved: boolean;
  tenantId?: string;
  invoiceDate?: string;
  sProviderName?: string;
  provider?: CreateUpdateMasterProviderDTO;
  personalDetails: PatientPersonalDTO;
  addressDetails: PatientAddressDTO;
  sCity?: string;
  sState?: string;
  sCountry?: string;
  sPincode?: string;
  ltItems: InvoiceItemViewDTO[];
}

export interface OrderDetailsDTO extends FullAuditedEntityDto<string> {
  patientId?: string;
  defaultSaleOrderId?: string;
  scheduledDate?: string;
  scheduledTime?: string;
  actualDate?: string;
  actualTime?: string;
  address?: string;
  city?: string;
  state?: string;
  county?: string;
  country?: string;
  postalCode?: string;
  phone?: string;
  mobile?: string;
  orderNote?: string;
  deliveryNote?: string;
  placeOfService?: string;
  isCompleted: boolean;
  status?: string;
  isCashPayment: number;
  payPalPaymentMethodType?: string;
  payPalPaymentStatus?: string;
  payPalMailResendCount: number;
  tenantId?: string;
  isTemplate: number;
}

export interface OrderValidationStatus {
  authRequiredStatus: boolean;
  noItemsStatus: boolean;
}

export interface ReminderDetailDTO extends FullAuditedEntityDto<string> {
  patientId?: string;
  saleOrderId?: string;
  tenantId?: string;
  assignedTo: string[];
  reminderDate?: string;
  subject?: string;
  isCompleted: number;
  isDontShowAgain: number;
}

export interface SaleOrderItemDTO extends FullAuditedEntityDto<string> {
  patientId?: string;
  saleOrderId?: string;
  itemId?: string;
  productId?: string;
  itemProcedureCode?: string;
  itemName?: string;
  insuranceName?: string;
  description?: string;
  location?: string;
  note?: string;
  isSpecialPricing: number;
  isTaxable: number;
  isPriceOverride: number;
  isSpecialTaxRate: number;
  isAcceptAssignment: number;
  isManualConvertToPurchase: number;
  isAbn: number;
  isAbnUpgrade: number;
  isCbOverride: number;
  itemSize?: string;
  modifiers1?: string;
  modifiers2?: string;
  modifiers3?: string;
  modifiers4?: string;
  charge: number;
  allow?: string;
  orderingQuantity: number;
  billingQuantity: number;
  isPrimary: number;
  coverageType?: string;
  claimSubmission?: string;
  isSecondary: number;
  isTertiary: number;
  isPatient: number;
  isDaysOfSupplyOverride: number;
  dosFrom?: string;
  dosTo?: string;
  nextPeriod?: string;
  isSpanDateSplitBillingEnabled: number;
  isReturned: boolean;
  isFollowupNeeded: boolean;
  followUpDuration: number;
  billedType: proudctPriceType;
  rentalresupplysaleOrder: RentalSaleOrderTemplateDTO;
}

export interface orderSearchDTO extends AuditedEntityDto {
  orderId?: string;
  patientId?: string;
  defaultPatientId?: string;
  saleOrderId?: string;
  patientName?: string;
  userName?: string;
  dateOfService?: string;
  status?: string;
  items: string[];
  totalQuantity: number;
  totalCharge: number;
  isCompleted: boolean;
  isOrderComplete?: boolean;
}
export interface ExchangeItemsDto {
  orderId: string;
  itemId: string;
  exchangeId: string;
  dos: string;
  itemProcedureCode: string;
  itemName: string;
  charge: string;
  quantity: string;
}

export interface PickUpExchangeDTO extends FullAuditedEntityDto<string> {
  patientId?: string;
  saleorderId?: string;
  claimId?: string;
  defaultPickupExchangeId?: string;
  pickupStatus: PickupExchangeStatus;
  itemDetailsList: ItemDetailsListDTO[];
  returnCompletedDate?: string;
  tenantId?: string;
  fileContentAsBase64String?: string;
  blobName?: string;
}

export interface PickupUpdateRequestDTO {
  pickupExchangeId?: string;
  fileContentAsBase64String?: string;
  returnCompletedDate?: string;
  blobName: string;
}

export interface CreateUpdatePickUpExchangeDTO {
  patientId?: string;
  saleorderId: string;
  claimId?: string;
  defaultPickupExchangeId: string;
  pickupStatus: PickupExchangeStatus;
  ItemDetailsList?: ItemDetailsListDTO[];
  returnCompletedDate: string;
  fileContentAsBase64String: string;
  blobName: string;
  saleOrderItem: CreateUpdateSaleOrderItemDTO;
  TenantId?: string;
}
export interface PickUpExchangeListDTO {
  pickupId: string;
  patientId?: string;
  orderId: string;
  claimId?: string;
  defaultPatientId: string;
  defaultSaleOrderId: string;
  defaultClaimId: string;
  exchangedItemId: string;
  exchangedItem: string;
  newItemId: string;
  newItem: string;
  exchangeDate: string;
  defaultPickupExchangeId: string;
  pickupOrderedDate: string;
  pickupCompletedDate: string;
  patientname: string;
  pickupStatus: PickupExchangeStatus;
  ItemDetailsList?: ItemDetailsListDTO[];
  returnCompletedDate: string;
  tenantId?: string;
}
export interface ItemDetailsListDTO {
  id: string;
  exchangedItemId: string;
  itemId: string;
  pickupExchangeType: PickupExchangeType;
  pickupReason: string;
  initiatedDate: string;
}
export interface ReturnAllItemsInputDTO {
  orderId: string;
  pickupReason: string;
  initiatedDate: string;
}
export interface PickupSaleOrderItemDTO {
  itemId: string;
  itemName: string;
  itemCode: string;
  pickupExchangeType: PickupExchangeType;
  pickupType: string;
  quantity: string;
  initiatedDate: string;
  pickupReason: string;
}

export enum PickupExchangeStatus {
  ReturnInitiated,
  ReturnCompleted,
  ReturnCancelled,
}
export enum PickupExchangeType {
  ItemReturn,
  ItemExchange,
}
export interface PickUpExchangeSearchDTO {
  PickupExchangeId: string;
  PatientId: string;
  orderidId: string;
  Patientname: string;
  Orderdate: string;
  Pickupstatus: string;
  Completeddate: string;
}
export interface OrderCompletedList {
  csrDetails: UserByRoleDTO;
  completedOrderDetails: OrderDetailsDTO[];
  LastCompletedITemDetails: SaleOrderItemDTO[];
}
// export interface CreateUpdateMMOrderDTO {
//   tenantId?: string;
//   mmRefereceOrderId: number;
//   patientId?: string;
//   locationId?: string;
//   bUsePatientAddress: number;
//   addressId?: string;
//   defaultSaleOrderId?: string;
//   supplies: number;
//   lmn: number;
//   rx_MedForceScanId: number;
//   primaryVerificationId?: string;
//   secondaryVerificationId?: string;
//   estCopay?: number;
//   estAllowable?: number;
//   totalAccessory?: number;
//   deductible: number;
//   copay: number;
//   commentsToTech?: string;
//   commentsToShipping?: string;
//   commentsToBilling?: string;
//   commentsToPatient?: string;
//   newSetup: number;
//   csRid?: string;
//   referralPracticeId?: string;
//   referralSourceId?: string;
//   shippingMethodId?: string;
//   paPtherapyUsedAndBeneficial: number;
//   previousOrderStatus?: string;
//   cashOrder: boolean;
//   faxId?: string;
//   checkListId?: string;
//   preCertId?: string;
//   rxDate?: string;
//   lockedBy?: string;
//   lockedOn?: string;
//   lockedBySPID?: number;
//   modifiedBy?: string;
//   modifiedOn?: string;
//   orderDate?: string;
//   backOrderDate?: string;
//   orderStatusSince?: string;
//   setupDate?: string;
//   suppliesDate?: string;
//   shippingStatusId?: string;
//   orderCreatedBy?: string;
//   shippingFee?: number;
//   overrideBy?: string;
//   overrideDt?: string;
//   overrideNote?: string;
//   overrideDisapproveNote?: string;
//   balOverrideBy?: string;
//   balOverrideDt?: string;
//   orderPointsCAL?: number;
//   pastDueBalance: number;
//   lastPaymentId?: string;
//   paymentsPostedByUserId?: string;
//   paymentsPostedOn?: string;
//   billingApprovalNote?: string;
//   billingNote?: string;
//   billingApprovalStatusId?: string;
//   pendingAtBilling: number;
//   pendingAtBillingApproval: number;
//   orderCreatedOn?: string;
//   functionalityQuNote: number;
//   verifiedPatientInfo: number;
//   downloadedCompliance: number;
//   mfs_ComplianceImageId: number;
//   ftf_Date?: string;
//   mfs_FTFImageId: number;
//   finalBilling?: string;
//   complianceReleaseChecklistId: number;
//   orderSentToShipping?: string;
//   remoteVideoCall: number;
//   curbSidePU: number;
//   orderStatus?: string;
//   status: number;
//   orderAmount: number;
//   estDueAmount: number;
//   brandName?: string;
//   orderItems: CreateUpdateMMOrderItemDTO[];
//   returned?: boolean;
//   exchanged?: boolean;
//   newOrderId?: string;
//   overrideType?: string;
//   rental: boolean;
//   months: number;
//   isOverride?: boolean;
//   orderStatusNo?: number;
//   disapprovedBy?: string;
//   disapprovedDate?: string;
//   disapprovedComment?: string;
//   isResupply: boolean;
//   isTemplate: number;
//   rentalresupplySaleOrder: CreateUpdatemmResupplyOrderTemplateDTO;
//   isRemRequisitionPlaced?: boolean;
//   faxDocumentId?: string;
//   authorizationId?: string;
//   rxDocuments: number[];
//   dos?: string;
//   isToBilling?: boolean;
// }

export interface CreateUpdateMMOrderDTO {
  tenantId?: string;
  mmRefereceOrderId: number;
  patientId?: string;
  locationId?: string;
  bUsePatientAddress: number;
  addressId?: string;
  isFromSchedule?: boolean;
  defaultSaleOrderId?: string;
  supplies: number;
  lmn: number;
  rx_MedForceScanId: number;
  primaryVerificationId?: string;
  secondaryVerificationId?: string;
  estCopay?: number;
  estAllowable?: number;
  totalAccessory?: number;
  deductible: number;
  copay: number;
  commentsToTech?: string;
  commentsToShipping?: string;
  commentsToBilling?: string;
  commentsToPatient?: string;
  newSetup: number;
  csRid?: string;
  referralPracticeId?: string;
  referralSourceId?: string;
  shippingMethodId?: string;
  paPtherapyUsedAndBeneficial: number;
  previousOrderStatus?: string;
  cashOrder: boolean;
  isToBilling?: boolean;
  faxId?: string;
  checkListId?: string;
  preCertId?: string;
  rxDate?: string;
  lockedBy?: string;
  lockedOn?: string;
  lockedBySPID?: number;
  modifiedBy?: string;
  modifiedOn?: string;
  orderDate?: string;
  backOrderDate?: string;
  orderStatusSince?: string;
  dos?: string;
  setupDate?: string;
  suppliesDate?: string;
  shippingStatusId?: string;
  orderCreatedBy?: string;
  shippingFee?: number;
  overrideBy?: string;
  overrideDt?: string;
  overrideNote?: string;
  overrideDisapproveNote?: string;
  balOverrideBy?: string;
  balOverrideDt?: string;
  orderPointsCAL?: number;
  pastDueBalance: number;
  lastPaymentId?: string;
  paymentsPostedByUserId?: string;
  paymentsPostedOn?: string;
  billingApprovalNote?: string;
  billingNote?: string;
  billingApprovalStatusId?: string;
  pendingAtBilling: number;
  pendingAtBillingApproval: number;
  orderCreatedOn?: string;
  functionalityQuNote: number;
  verifiedPatientInfo: number;
  downloadedCompliance: number;
  mfs_ComplianceImageId: number;
  ftf_Date?: string;
  mfs_FTFImageId: number;
  finalBilling?: string;
  complianceReleaseChecklistId: number;
  orderSentToShipping?: string;
  remoteVideoCall: number;
  curbSidePU: number;
  orderStatus?: string;
  status: number;
  orderAmount: number;
  transactionFee?: number;
  estDueAmount: number;
  brandName?: string;
  orderItems: CreateUpdateMMOrderItemDTO[];
  returned?: boolean;
  exchanged?: boolean;
  newOrderId?: string;
  overrideType?: string;
  rental: boolean;
  months: number;
  isOverride?: boolean;
  orderStatusNo?: number;
  disapprovedBy?: string;
  disapprovedDate?: string;
  disapprovedComment?: string;
  isResupply: boolean;
  isTemplate: number;
  rentalresupplySaleOrder: CreateUpdatemmResupplyOrderTemplateDTO;
  isRemRequisitionPlaced?: boolean;
  faxDocumentId?: string;
  authorizationId?: string;
  rxDocuments: number[];
  rentalAllowable?: number;
  nonRentalAllowable?: number;
}

export interface RentalListDTO {
  rentalId?: string;
  mmOrderId?: string;
  mmRefereceOrderId: number;
  patientId?: string;
  chartNo?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  fullName?: string;
  totalmonths: number;
  totalAmount: number;
  monthAmount: number;
  dosDate?: string;
  rentalStatus?: string;
}

export interface mmRentalOrderDTO extends FullAuditedEntityDto<string> {
  tenantId?: string;
  mmOrderId?: string;
  orderNo: number;
  orderAmount: number;
  dosDate?: string;
  receiveDate?: string;
  rentalStatusName: string;
  rentalStatus: mmRentalTypes;
}
// export interface CreateUpdateMMOrderItemDTO {
//   tenantId?: string;
//   mmReferenceOrderItemId: number;
//   mmOrderId: string;
//   mmProductId: string;
//   qty: number;
//   sizeId?: string;
//   allowable: number;
//   accessory: number;
//   backOrderedSince?: string;
//   lineItemNotes?: string;
//   productDescription?: string;
//   patientQtyOnHand: number;
//   points_Cal: number;
//   serialLotNo: string; //Serial Lot Number Guid Field
//   itemCost: number;
//   fixedPoints: number;
//   unitCost: number;
//   unitAllowable: number;
//   unitAccessory: number;
//   exchange: boolean;
//   return: boolean;
//   discountPercent: number;
//   specialItem: number;
//   addOnFromSuggestions: number;
//   remoteTransferSuccessful: number;
//   status: number;
//   mmOrderItemId?: string;
//   awaitDlvry: number;
//   pendpalOrder: number;
//   serialLotNumber?: string; //Serial lot Number Manual Entry Field
//   showSettings?: number;
//   categoryName?: string;
// }

export interface CreateUpdateMMOrderItemDTO {
  tenantId?: string;
  primaryInsuranceId?: string;
  mmReferenceOrderItemId: number;
  mmOrderId: string;
  mmProductId: string;
  qty: number;
  sizeId?: string;
  allowable: number;
  accessory: number;
  backOrderedSince?: string;
  lineItemNotes?: string;
  productCode?: string;
  serialLotName?: string;
  sizeName?: string;
  trackingNumber?: string;
  productDescription?: string;
  patientQtyOnHand: number;
  points_Cal: number;
  serialLotNo: string;
  itemCost: number;
  fixedPoints: number;
  unitCost: number;
  unitAllowable: number;
  unitAccessory: number;
  exchange: boolean;
  return: boolean;
  discountPercent: number;
  specialItem: number;
  addOnFromSuggestions: number;
  remoteTransferSuccessful: number;
  status: number;
  mmOrderItemId?: string;
  awaitDlvry: number;
  pendpalOrder: number;
  hcpcList: OrderProductHcpcCode[];
  itemSettings: CreateUpdateMmOrderItemSettingsDTO;
  mmCategoryReferenceId: number;
  bCashOrder: boolean;
  bIsNewSetup: boolean;
  isInsuranceCategory: boolean;
  isStockadded?: number;
  packedby?: string;
  actualAccessory: number;
  discountAccessory: number;
  mmCategoryId?: string;
  showSettings?: number;
  minimumQuantity: number;
  sMinStock: boolean;
  stockCount: number;
  shippingDate?: string;
  locationId?: string;
  totalAmount: number;
  serialLotNumber?: string;
  categoryName?: string;
}

export interface MmOrderDTO extends FullAuditedEntityDto<string> {
  tenantId?: string;
  mmRefereceOrderId: number;
  hellosignStatus?: string;
  defaultSaleOrderId?: string;
  patientId?: string;
  locationId?: string;
  estCopay?: number;
  estAllowable?: number;
  totalAccessory?: number;
  bUsePatientAddress: number;
  addressId?: string;
  supplies: number;
  lmn: number;
  rx_MedForceScanId: number;
  primaryVerificationId?: string;
  secondaryVerificationId?: string;
  deductible: number;
  copay: number;
  commentsToTech?: string;
  csrStatus?: string;
  commentsToShipping?: string;
  commentsToBilling?: string;
  commentsToPatient?: string;
  newSetup: number;
  csRid?: string;
  referralPracticeId?: string;
  referralSourceId?: string;
  shippingMethodId?: string;
  paPtherapyUsedAndBeneficial: number;
  faxDocumentId?: string;
  previousOrderStatus?: string;
  cashOrder: boolean;
  faxId?: string;
  checkListId?: string;
  preCertId?: string;
  rxDate?: string;
  lockedBy?: string;
  lockedOn?: string;
  lockedBySPID?: number;
  modifiedBy?: string;
  modifiedOn?: string;
  orderDate?: string;
  backOrderDate?: string;
  orderStatusSince?: string;
  setupDate?: string;
  suppliesDate?: string;
  supplyDate?: string;
  shippingStatusId?: string;
  orderCreatedBy?: string;
  shippingFee?: number;
  overrideBy?: string;
  overrideDt?: string;
  overrideNote?: string;
  overrideDisapproveNote?: string;
  balOverrideBy?: string;
  balOverrideDt?: string;
  orderPointsCAL: number;
  pastDueBalance: number;
  lastPaymentId?: string;
  paymentsPostedByUserId?: string;
  paymentsPostedOn?: string;
  billingApprovalNote?: string;
  billingNote?: string;
  billingApprovalStatusId?: string;
  pendingAtBilling: number;
  pendingAtBillingApproval: number;
  orderCreatedOn?: string;
  functionalityQuNote: number;
  verifiedPatientInfo: number;
  downloadedCompliance: number;
  mfs_ComplianceImageId: number;
  ftf_Date?: string;
  mfs_FTFImageId: number;
  finalBilling?: string;
  complianceReleaseChecklistId: number;
  orderSentToShipping?: string;
  remoteVideoCall: number;
  curbSidePU: number;
  orderStatus?: string;
  orderStatusName?: string;
  patientName?: string;
  policyName?: string;
  chartNo?: string;
  csrName?: string;
  insuranceCategoryName?: string;
  lastMaskProcessDate?: string;
  insuranceCategoryId?: string;
  status: number;
  orderAmount: number;
  estDueAmount?: number;
  weight?: number;
  width?: number;
  length?: number;
  depth?: number;
  barcode?: string;
  brandName?: string;
  orderItems: CreateUpdateMMOrderItemDTO[];
  exchanged?: boolean;
  returned?: boolean;
  exchangeOrReturn?: string;
  newOrderId?: string;
  overrideType?: string;
  isOverride?: boolean;
  mmNewOrderId?: number;
  isRental: boolean;
  orderStatusNo?: number;
  month: number;
  disapprovedBy?: string;
  disapprovedDate?: string;
  disapprovedComment?: string;
  paymentMethod?: string;
  paymentStatus?: string;
  invoiceId?: string;
  patientEmail?: string;
  phoneNumber?: string;
  nextCallDate?: string;
  isResupply: boolean;
  isTemplate: number;
  secpolicyId?: string;
  policyId?: string;
  modifiedUser?: string;
  createdBy?: string;
  isRxMailSent?: boolean;
  isClaimProcessed?: boolean;
  authorizationId?: string;
  providerName?: string;
  rxDocuments: number[];
  isSorted?: boolean;
  dos?: string;
  isExchangeOrder?: boolean;
}

export interface CreateUpdatemmResupplyOrderTemplateDTO {
  templateCode?: string;
  saleOrderId?: string;
  rentalTypes: RentalTypes;
  startDate?: string;
  endPeriod: number;
  nextRun?: string;
  periodsRun: number;
  isEnabled: number;
  templateSummary?: string;
  monthDay: number;
  lstMonths: number[];
  weeknumber: number;
  yearNumber: number;
  lstWeeks: number[];
  lstYears: string[];
  status: RentalOrderJobStatus;
  statusType?: QuitStatus;
  orderItems: string[];
  saleOrderItemId: string[];
}

export interface PatientChartNoDTO {
  patientId?: string;
  chartId?: string;
  patientName?: string;
  dob?: string;
  lastModificationTime?: string;
  ddValue?: string;
}
export interface BillingApprovedProductListDTO {
  orderId?: string;
  orderItemId?: string;
  productId?: string;
  productCode?: string;
  productDescription?: string;
  quantity: number;
  backOrderedSince?: string;
  notes?: string;
  patientOnHand: number;
  allowable: number;
  accessory: number;
  hcpcList: OrderProductHcpcCode[];
}
export interface HellosignSignatureDTO extends FullAuditedEntityDto<string> {
  tenantId?: string;
  patientId?: string;
  orderId?: string;
  inboundDocumentId?: string;
  signaturerequestid?: string;
  patientEmail?: string;
  documentSentOn?: string;
  documentReceivedOn?: string;
  signedOn?: string;
  expireDate?: string;
  viewedOn?: string;
  revertedOn?: string;
  revertedBy?: string;
  expireStatus?: string;
  helloSignStatus?: string;
  status?: boolean;
  fileName: string[];
  blobName: string[];
  isResend?: boolean;
  resendCount?: number;
  patientName?: string;
  chartNumber?: string;
  createdBy?: string;
  lastModifyBy?: string;
  orderNumber: number;
  orderDate: string;
  csrName: string;
  documentDetails: HellosignDocumentDetails[];
}

export interface HellosignDocumentDetails {
  fileName?: string;
  blobName?: string;
  fileContent?: string;
}

export interface CreateUpdateHellosignSignatureDTO {
  tenantId?: string;
  patientId: string;
  orderId: string;
  inboundDocumentId?: string;
  signaturerequestid?: string;
  documentSentOn?: string;
  documentReceivedOn?: string;
  signedOn?: string;
  expireDate?: string;
  expireStatus?: string;
  helloSignStatus?: string;
  patientEmail: string;
  status?: boolean;
  fileName: string[];
  blobName: string[];
  isResend?: boolean;
  resendCount?: number;
  documentDetails: HellosignDocumentDetails[];
  filePath: string[];
}

export interface HelloSignHistoryDetail {
  signatureId?: string;
  patientName?: string;
  patientChartId?: string;
  orderNumber: number;
  isResend?: boolean;
  resendStatus?: string;
  signatureDate?: string;
  viewDate?: string;
  documentReceiveOn?: string;
  documentSendOn?: string;
  modifyOn?: string;
  resendOn?: string;
  revertedOn?: string;
  revertedBy?: string;
  recevertedByName?: string;
  documentSendbyName?: string;
  modifyByName?: string;
  hellosignStatus?: string;
}

export interface helloSignDashboardDTO {
  overallHelloSignCount: number;
  pendingHelloSignCount: number;
  signedCount: number;
}
export interface RXDateReportDTO {
  orderId?: string;
  chartId?: string;
  patientId?: string;
  patientName?: string;
  dob?: string;
  practitionerId?: string;
  practitionerName?: string;
  rxDate?: string;
  csrId?: string;
  csrName?: string;
  lastMaskDate?: string;
  orderDate?: string;
  rxExpiryDate?: string;
  expiryDays: number;
  status?: string;
}
export interface BillingApprovalListDTO {
  mmOrderId?: string;
  mmRefereceOrderId: number;
  patientId?: string;
  chartNo?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  fullName?: string;
  shippingStatusId?: string;
  shippingStatus?: string;
  policyId?: string;
  priVerificationId?: string;
  secVerificationId?: string;
  insuranceName?: string;
  suppliesDate?: string;
  comments?: string;
  shippingComments?: string;
  emailId?: string;
  shippingMethodId?: string;
  shippingMethod?: string;
  patientBalance: number;
  insurancePending: number;
  totalBalance: number;
  userId?: string;
  username?: string;
  backOrderSince?: string;
  state?: string;
  newSetup: number;
  secpolicyId?: string;
  faxDocumentId?: string;
  referenceOrderId?: number;
  isCashOrder?: boolean;
}

export interface PatientInfoDTO {
  patientId?: string;
  chartNo?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  dateOfBirth?: string;
  homePhoneNo?: string;
  workPhoneNo?: string;
  mobile?: string;
  emailId?: string;
  addressId?: string;
  address?: string;
  city?: string;
  state?: string;
  stateName?: string;
  country?: string;
  countryName?: string;
  postalCode?: string;
  priVerificationId?: string;
  secVerificationId?: string;
  terVerificationId?: string;
  authorizationId?: string;
  priPolicyId?: string;
  secPolicyId?: string;
  terPolicyId?: string;
  nameLine?: string;
  description?: string;
  priVerificationName?: string;
  secVerificationName?: string;
  terVerificationName?: string;
  defaultFaxId: string;
  faxId?: string;
  csrId?: string;
  isGoogleValidated?: boolean;
  providerId?: string;
  referalPracticeId?: string;
  referringProviderId?: string;
  branchId?: string;
  rxDate?: string;
  primaryVerificationStatus: boolean;
  secondaryVerificationStatus: boolean;
  medicareCheckListId?: string;
  medicareCheckListName?: string;
  rxDocuments: number[];
  isSorted?: boolean;
  commentsToTech?: string;
  listofVerificationsDTOs: ListofVerificationsDTO[];
}
export interface ListofVerificationsDTO {
  id?: string;
  patientId?: string;
  verifiedOn?: string;
  payorName: string;
  payorNameandDate: string;
}
export interface PatientShippingAddressDTO {
  id: string;
  patientId?: string;
  chartNo?: string;
  addressType: AddressType;
  address?: string;
  city?: string;
  emailId?: string;
  state?: string;
  country?: string;
  county?: string;
  postalCode?: string;
  phone?: string;
  fax?: string;
  mobile?: string;
  zone?: string;
  firstOrderDate?: string;
  lastOrderDate?: string;
  orderCount: number;
  isGoogleValidated?: boolean;
}
export interface OrderPatientNotesDTO {
  notesId?: string;
  orderId?: string;
  claimId?: string;
  patientId?: string;
  defaultNotesId?: string;
  noteType: NotesType;
  noteReasonId?: string;
  noteReason?: string;
  createdBy?: string;
  dateCreated?: string;
  actualDate?: string;
  dateNeeded?: string;
  severity?: string;
  status?: string;
  dateComplete?: string;
  subject?: string;
  description?: string;
  modifiedBy?: string;
  createdByName?: string;
  modifiedDate?: string;
  tenantId?: string;
  assignedTo?: string;
  assignedDate?: string;
  assignedToName?: string;
  isCallNote?: boolean;
}

export interface CreateUpdateMaskSizeDTO {
  maskSizeId: number;
  description: string;
  ddOrder: number;
  isActive: boolean;
  tenantId?: string;
}
export interface MaskSizeDTO extends AuditedEntityDto<string> {
  maskSizeId: number;
  description?: string;
  ddOrder: number;
  isActive: boolean;
  tenantId?: string;
}
export interface OrderPatientDetailsDTO {
  patientId?: string;
  policyId?: string;
  chartNo?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  suffix?: string;
  dateOfBirth?: string;
  gender?: string;
  faxNumber?: string;
  ssn?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  homephone?: string;
  workphone?: string;
  mobile?: string;
  emailId?: string;
  modifiedBy?: string;
  modifiedOn?: string;
  isActive?: boolean;
  isNeverCall?: boolean;
  notes?: string;
}
export interface PatientOrderInsuranceDTO {
  patientId?: string;
  payorId?: string;
  payorName?: string;
  planOrder?: number;
  planOrderName?: string;
  relationship?: string;
  relationshipName?: string;
  policyNo?: string;
  groupNo?: string;
  coverageBegins?: string;
  coverageEnds?: string;
}
export interface CompletedOrderDTO {
  locationId?: string;
  orderId?: string;
  faxDocumentId?: string;
  isSorted?: boolean;
  location?: string;
  createdBy?: string;
  mmRefereceOrderId: number;
  patientId?: string;
  chartno?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  state?: string;
  stateName?: string;
  fullName?: string;
  priVerificationId?: string;
  policyId?: string;
  secpolicyId?: string;
  secVerificationId?: string;
  priPlanName?: string;
  secPlanName?: string;
  suppliesdate?: string;
  orderCreatedBy?: string;
  createdByName?: string;
  csrName?: string;
  enteredon?: string;
  disapprovedBy?: string;
  shippingDisapproveNotes?: string;
  disapproveddate?: string;
  createdDate?: string;
  modifiedDate?: string;
  modifyByName?: string;
  medicareCheckList?: string;
  authorizationId?: string;
  isCashOrder?: boolean;
  isExchangeOrder?: boolean;
}

export interface ShippingProductListDTO {
  orderId?: string;
  productId?: string;
  productCode?: string;
  productDescription?: string;
  quantity: number;
  hcpcList: OrderProductHcpcCode[];
}
export interface OrderProductHcpcCode {
  productId?: string;
  hcpcCode?: string;
}
export interface ShippingProductListDTO {
  orderId?: string;
  productId?: string;
  productCode?: string;
  productDescription?: string;
  quantity: number;
  hcpcList: OrderProductHcpcCode[];
}
export interface ShippingPatientBalanceNotesDTO {
  patientId?: string;
  patientBalance: number;
  insBalance: number;
  totalBalance: number;
  billingNotes?: string;
  shippingNotes?: string;
}
export interface SInvTransactionDTO {
  transtype?: string;
  logId: number;
  locationName?: string;
  prodCode?: string;
  description?: string;
  ssId?: string;
  qtyChange: number;
  userId?: string;
  userName?: string;
  transactionDate?: string;
  unitCost: number;
  requisitionItemId?: string;
  reqNumber?: string;
  reqSerialLotNo?: string;
  oiSerialLotNo?: string;
  orderItemId?: string;
  orderId?: string;
  patientId?: string;
  chartNo?: string;
  inventoryAdjustmentItem?: string;
  inventoryTransferItemId?: string;
}

export interface ShippingApprovalListDTO {
  mmOrderId?: string;
  mmRefereceOrderId: number;
  faxDocumentId?: string;
  isSorted?: boolean;
  shippingStatusId?: string;
  shippingStatus?: string;
  suppliesDate?: string;
  patientId?: string;
  chartNo?: string;
  csrStatus?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  fullName?: string;
  policyId?: string;
  secpolicyId?: string;
  priVerificationId?: string;
  secVerificationId?: string;
  insuranceName?: string;
  shippingMethodId?: string;
  shippingMethod?: string;
  userId?: string;
  createdByName?: string;
  csrName?: string;
  backOrderSince?: string;
  state?: string;
  earlyShip?: string;
  createdDate?: string;
  modifiedDate?: string;
  modifyByName?: string;
  medicareCheckList?: string;
  authorizationId?: string;
  isExchangeOrder?: string;
}

export interface ShippingApprovedNotesDTO {
  patientId?: string;
  pAddress?: string;
  patientNotes?: string;
  shippingNotes?: string;
  billingNotes?: string;
}

export interface ShippingApprovedProductListDTO {
  barCode?: string;
  weight?: string;
  orderId?: string;
  orderItemId: string;
  productId?: string;
  productCode?: string;
  productDescription?: string;
  quantity: number;
  sizeId?: string;
  size?: string;
  ssId?: string;
  serialLotNo?: string;
  backOrderedSince?: string;
  inventory?: string;
  ordered?: string;
}
export interface StockLotNoQty {
  qty: number;
  lotSerialNo?: string;
}

export interface StockSerialLotNoDTO {
  productId?: string;
  quantity: number;
  lotSerialNo?: string;
  lotSSID?: string;
  mmReferrenceSSID: number;
}
export interface mmShippingListOptionsDTO extends AuditedEntityDto<string> {
  mmReferenceId: number;
  sameDayShippingTime?: string;
  shippingCutOffTime?: string;
  tenantId?: string;
}
export interface CreateUpdateMmShippingWeightDTO {
  weightId: number;
  weightOZ?: string;
  description?: string;
  tenantId?: string;
  isActive: boolean;
}
export interface MmShippingWeightDTO extends AuditedEntityDto<string> {
  weightId: number;
  weightOZ?: string;
  description?: string;
  tenantId?: string;
  isActive: boolean;
}
export interface CreateUpdateMmUPSLabelDTO {
  upsLabelId: number;
  description?: string;
  tenantId?: string;
  isActive: boolean;
}
export interface MmUPSLabelDTO extends AuditedEntityDto<string> {
  upsLabelId: number;
  description?: string;
  tenantId?: string;
  isActive: boolean;
}
export interface CreateUpdateOrderShippingStatusDTO {
  mmOrderShippingStatusId?: number;
  description: string;
  status?: Status;
  tenantId?: string;
}
export interface OrderShippingStatusDTO extends AuditedEntityDto<string> {
  mmOrderShippingStatusId: number;
  description?: string;
  status: Status;
  tenantId?: string;
}

export interface PrintTicketDetails {
  mmRefOrderId: number;
  orderId?: string;
  patientId?: string;
  patientAccountNo?: string;
  patientName?: string;
  shippingMethodId?: string;
  shippingMethodName?: string;
  fullAddress?: string;
  insuranceName?: string;
  dateOfBirth?: string;
  homePhone?: string;
  workPhone?: string;
  mobilePhone?: string;
  emailId?: string;
  technicianName?: string;
  physicianName?: string;
  referralPractice?: string;
  commentsToPatient?: string;
  productDetailsDTO: RequisitionProductDetailsDTO[];
  settingsDetails: MmOrderItemSettingsDTO;
  estAllowable: number;
  location?: string;
  totalAccessory: number;
  shippingFee: number;
  estAmountDue: number;
  estCopay?: number;
  amountPaid: number;
  patientBalance: number;
  orderDate?: string;
  isOrderComplete?: boolean;
}

export interface RequisitionProductDetailsDTO {
  location?: string;
  quantity: number;
  productId?: string;
  productCode?: string;
  productName?: string;
  sizeId?: string;
  sizeName?: string;
  exchange?: boolean;
  serialLotNo?: string;
  allowables?: number;
  hcpc?: string;
}
export interface InboundDocumentDataDTO {
  documentId?: string;
  blobName?: string;
}
export interface ExchangeListDTO {
  exchangeId?: string;
  mmOrderId?: string;
  mmRefereceOrderId: number;
  patientId?: string;
  chartNo?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  fullName?: string;
  exchangeDate?: string;
  csrId?: string;
  csrName?: string;
  exchangeOrderSuppliesDate?: string;
  newOrderSuppliesDate?: string;
}
export interface ExchangeItemsListDTO {
  exchangeId?: string;
  originalItemList: ProductDetailsListDTO[];
  newItemList: ProductDetailsListDTO[];
}
export interface ProductDetailsListDTO {
  orderId?: string;
  mmRefOrderId: number;
  productID?: string;
  productCode?: string;
  productDescription?: string;
  sizeId?: string;
  size?: string;
  serialLotNo?: string;
  quantity: number;
}
export interface PatientAddressValidationDTO {
  address_line1?: string;
  city?: string;
  state_province?: string;
  phone?: string;
  postal_code?: string;
}
export interface GooglePlacesMatechedDTO {
  pincode: boolean;
  stateName: boolean;
  cityName: boolean;
  isValid: boolean;
}
export interface OverrideListDTO {
  mmOrderId?: string;
  mmRefereceOrderId: number;
  patientId?: string;
  chartNo?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  fullName?: string;
  orderedDate?: string;
  csrId?: string;
  csrName?: string;
  overrideDate?: string;
  overrideNote?: string;
}
export interface CreateUpdateMmOrderReturnExchangeItemConditionDTO {
  mOrderReturnExchangeItemConditionId?: number;
  description: string;
  sortOrder: number;
  status: Status;
  tenantId?: string;
}
export interface MmOrderReturnExchangeItemConditionDTO
  extends FullAuditedEntityDto<string> {
  mOrderReturnExchangeItemConditionId?: number;
  description?: string;
  sortOrder: number;
  status: Status;
  tenantId?: string;
}
export interface CreateUpdateMmOrderReturnExchangeReasonDTO {
  mmOrderReturnExchangeReasonId?: number;
  description: string;
  sortOrder?: number;
  status: Status;
  tenantId?: string;
}
export interface MmOrderReturnExchangeReasonDTO
  extends FullAuditedEntityDto<string> {
  mmOrderReturnExchangeReasonId?: number;
  description?: string;
  sortOrder?: number;
  status: Status;
  tenantId?: string;
}
export interface CreateUpdateMmOrderItemReturnDTO {
  orderId?: string;
  orderItemId?: string;
  userId?: string;
  returnReasonId?: string;
  reasonDescription?: string;
  createdDate?: string;
  tenantId?: string;
  status: Status;
  qty?: number;
  returnExchangeId?: string;
}
export interface MmOrderItemReturnDTO extends FullAuditedEntityDto<string> {
  orderId?: string;
  orderItemId?: string;
  userId?: string;
  returnReasonId?: string;
  reasonDescription?: string;
  createdDate?: string;
  tenantId?: string;
  status: Status;
  qty?: number;
  returnExchangeId?: string;
}
export interface ReturnListDTO {
  returnId?: string;
  mmOrderId?: string;
  mmRefereceOrderId: number;
  patientId?: string;
  chartNo?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  fullName?: string;
  orderedDate?: string;
  csrId?: string;
  csrName?: string;
  returnDate?: string;
  status?: string;
}
export interface ReturnProductDetailsListDTO {
  returnId?: string;
  orderId?: string;
  orderItemId?: string;
  mmRefOrderId: number;
  productID?: string;
  productCode?: string;
  productDescription?: string;
  sizeId?: string;
  size?: string;
  serialLotNo?: string;
  quantity: number;
  balQuantity: number;
  recQuantity: number;
  reasonId?: string;
  reason?: string;
  itemCondition?: string;
  itemConditionId?: string;
  refItemCondition?: number;
}
export interface DazzlePrintingLabelParamsDto {
  orderId?: string;
  addressId?: string;
  printLabel: PrintLabel;
  upsLabel?: string;
  isReturnLabel: boolean;
  weight: number;
  width?: string;
  length?: string;
  height?: string;
  signatureConfirmation: boolean;
}
export interface ComplianceStatusDTO extends FullAuditedEntityDto<string> {
  complianceName?: string;
  status: Status;
  tenantId?: string;
}

export interface CreateUpdateComplianceStatusDTO {
  complianceName: string;
  status: Status;
  tenantId?: string;
}

export interface CreateUpdateMmOrderComplianceDTO {
  mmRefOrderId: number;
  orderId?: string;
  nonCompliantQueue: boolean;
  nonCompliantDT?: string;
  nonCompliantUser?: string;
  complainceStatusId?: string;
  tenantId?: string;
}
export interface MmOrderComplianceDTO extends AuditedEntityDto<string> {
  mmRefOrderId: number;
  orderId?: string;
  nonCompliantQueue: boolean;
  nonCompliantDT?: string;
  nonCompliantUser?: string;
  previousComplainceStatusId?: string;
  complainceStatusId?: string;
}
export interface MMOrderItemLookupDTO {
  tenantId?: string;
  primaryInsuranceId?: string;
  mmProductId?: string;
  qty: number;
  sizeId?: string;
  allowable: number;
  allowableDays: number;
  actualCost: number;
  accessory: number;
  unitAccessory: number;
  productCode?: string;
  sizeName?: string;
  trackingNumber?: string;
  productDescription?: string;
  points_Cal: number;
  itemCost: number;
  unitCost: number;
  unitAllowable: number;
  discountPercent: number;
  mmCategoryReferenceId: number;
  patientOrderCount: number;
  allowableQuantity: number;
  patientId?: string;
  insuranceId?: string;
}
export interface PendingOrderStatusDTO {
  orderId?: string;
  newSetup: number;
}
export interface MmOrderDataDetailsDTO {
  rentalAllowable?: number;
  nonRentalAllowable?: number;
  gOrderId?: string;
  tenantId?: string;
  mmRefereceOrderId: number;
  patientId?: string;
  locationId?: string;
  locationName?: string;
  bUsePatientAddress: number;
  addressDTO: PatientAddressDTO;
  verifiedPatientInfo?: number;
  estCopay?: number;
  estAllowable?: number;
  totalAccessory?: number;
  addressId?: string;
  deductible: number;
  copay: number;
  commentsToTech?: string;
  commentsToShipping?: string;
  commentsToBilling?: string;
  commentsToPatient?: string;
  newSetup: number;
  csRid?: string;
  referralPracticeId?: string;
  referralPracticeName?: string;
  referralSourceId?: string;
  referralSourceName?: string;
  shippingMethodId?: string;
  paPtherapyUsedAndBeneficial: number;
  faxDocumentId?: string;
  previousOrderStatus?: string;
  cashOrder: boolean;
  faxId?: string;
  checkListId?: string;
  preCertId?: string;
  rxDate?: string;
  lockedBy?: string;
  lockedOn?: string;
  lockedBySPID: number;
  modifiedBy?: string;
  modifiedOn?: string;
  orderDate?: string;
  backOrderDate?: string;
  orderStatusSince?: string;
  setupDate?: string;
  suppliesDate?: string;
  shippingStatusId?: string;
  orderCreatedBy?: string;
  shippingFee: number;
  orderPointsCAL: number;
  pastDueBalance: number;
  billingApprovalNote?: string;
  billingNote?: string;
  orderStatus?: string;
  orderStatusName?: string;
  status: number;
  orderAmount: number;
  estDueAmount?: number;
  brandName?: string;
  orderItems: CreateUpdateMMOrderItemDTO[];
  exchanged?: boolean;
  returned?: boolean;
  exchangeOrReturn?: string;
  newOrderId?: string;
  overrideType?: string;
  isOverride?: boolean;
  mmNewOrderId?: number;
  isRental: boolean;
  orderStatusNo?: number;
  month: number;
  disapprovedBy?: string;
  disapprovedDate?: string;
  disapprovedComment?: string;
  paymentMethod?: string;
  paymentStatus?: string;
  invoiceId?: string;
  isResupply: boolean;
  isTemplate: number;
  policyName?: string;
  secpolicyName?: string;
  insuranceCategoryName?: string;
  secpolicyId?: string;
  policyId?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  dateOfBirth?: string;
  chartDDValue?: string;
  patientEmail?: string;
  phoneNumber?: string;
  nextCallDate?: string;
  patientName?: string;
  chartNo?: string;
  csrName?: string;
  lastMaskProcessDate?: string;
  primaryVerificationId?: string;
  secondaryVerificationId?: string;
  authorizationId?: string;
  medicareChecklistName?: string;
  rxDocuments: number[];
  isSorted?: boolean;
  totalBalance?: number;
  isPaymentMailSent?: boolean;
  originalOrderId?: string;
  originalMMOrderId?: number;
  isExchangedOrder?: boolean;
  ltExchangedCategories: string[];
}

export interface CreateUpdateDiscountPercentDTO {
  discountPercentage?: number;
  fromDate?: string;
  toDate?: string;
  tenantId?: string;
  isActive?: boolean;
}

export interface DiscountPercentDTO extends FullAuditedEntityDto<string> {
  discountPercentage: number;
  fromDate?: string;
  toDate?: string;
  tenantId?: string;
  isActive?: boolean;
}

export interface FrequentlyOrderedItemDTO {
  patientId?: string;
  productId?: string;
  productCode?: string;
  productName?: string;
  orderedCount: number;
  unitCost: number;
}
export interface CardTypeDTO extends AuditedEntityDto<string> {
  cardTypeId: number;
  description?: string;
  status: boolean;
}
export interface CreateUpdateCardTypeDTO {
  cardTypeId: number;
  description?: string;
  status: boolean;
}
export interface CreateUpdateCreditCardDetailsDTO {
  creditCardId: number;
  orderID?: string;
  defaultSaleOrderId?: string;
  totalDue?: number;
  charge?: number;
  cardTypeID?: string;
  cardType?: string;
  cardNo?: string;
  expiryMonth: number;
  expiryYear: number;
  cvv: number;
  tenantId?: string;
  isActive: boolean;
  orderNumber?: number;
  paymentStatus?: string;
}
export interface CreditCardDetailsDTO extends AuditedEntityDto<string> {
  creditCardId: number;
  orderID?: string;
  defaultSaleOrderId?: string;
  totalDue?: number;
  charge?: number;
  cardTypeID?: string;
  cardType?: string;
  cardNo?: string;
  expiryMonth: number;
  expiryYear: number;
  cvv: number;
  tenantId?: string;
  isActive: boolean;
  createdId?: string;
  createdStatus?: string;
  orderNumber?: number;
  paymentStatus?: string;
  orderPayments: MmOrderPaymentsDTO;
}
export interface MmOrderPaymentsDTO extends FullAuditedEntityDto<string> {
  mmReferencePaymentId: number;
  mmOrderId?: string;
  paymentType?: string;
  amount: number;
  approvalNo?: string;
  userId?: string;
  createdDate?: string;
  settledById?: string;
  settledByName?: string;
  settledDt?: string;
  paymentProcessorId: number;
  paymentProcess?: string;
  isActive: boolean;
  tenantId?: string;
}
export interface CreateUpdateMmOrderItemSettingsDTO {
  tenantId?: string;
  orderItemId?: string;
  mmOrderItemId: number;
  minPressure?: number;
  maxPressure?: number;
  settlingTime?: number;
  ipap?: number;
  epap?: number;
  rampPressure?: number;
  rampTime?: number;
  biFlex?: number;
  setPressure?: number;
  cFlex?: number;
  rrbpm?: number;
  ipaPmin?: number;
  ipaPmax?: number;
  eep?: number;
  setVT?: number;
  rate?: number;
  status: boolean;
}
export interface MmOrderItemSettingsDTO extends FullAuditedEntityDto<string> {
  tenantId?: string;
  orderItemId?: string;
  mmOrderItemId: number;
  minPressure?: number;
  maxPressure?: number;
  settlingTime?: number;
  ipap?: number;
  epap?: number;
  rampPressure?: number;
  rampTime?: number;
  biFlex?: number;
  setPressure?: number;
  cFlex?: number;
  rrbpm?: number;
  ipaPmin?: number;
  ipaPmax?: number;
  eep?: number;
  setVT?: number;
  rate?: number;
  status: boolean;
}
export interface machineSettingsDTO {
  showMinPressure?: boolean;
  showMaxPressure?: boolean;
  showSettlingTime?: boolean;
  showIPAP?: boolean;
  showEPAP?: boolean;
  showRampPressure?: boolean;
  showRampTime?: boolean;
  showBiFlex?: boolean;
  showSetPressure?: boolean;
  showCFlex?: boolean;
  showRRBPM?: boolean;
  showIPAPmin?: boolean;
  showIPAPmax?: boolean;
  showEEP?: boolean;
  showSetVT?: boolean;
  showRate?: boolean;
}
export interface aprovalNoAndAmt {
  approvalNo?: string;
  amount?: number;
  approvalDate?: string;
}
export interface TechnicianDropDown {
  technicianId?: string;
  technicianName?: string;
}
export interface CreateUpdateOrderHistory {
  tenantId?: string;
  orderId?: string;
  mmRefereceOrderId?: number;
  orderStatusId?: string;
  orderStatusName?: string;
  updatedBy?: string;
  isActive?: boolean;
  patientId?: string;
  patientName?: string;
  chartNo?: string;
}
export interface OrderHistoryDTO extends AuditedEntityDto<string> {
  tenantId?: string;
  orderId?: string;
  mmRefereceOrderId?: number;
  orderStatusId?: string;
  orderStatusName?: string;
  isActive?: boolean;
  updatedBy?: string;
  patientId?: string;
  patientName?: string;
  chartNo?: string;
  createdDateNew?: string;
  creationTime?: string;
}

export interface completedandnoncashorderlistDTO {
  documentId?: string;
  chartId?: string;
  patientname?: string;
  orderNo?: number;
  cellNo?: string;
  dos?: string;
  nextCallDate?: string;
  nextOrderDate?: string;
  allowableAmt?: number;
  patientId?: string;
  orderId?: string;
  locationId?: string;
  faxId?: string;
  agingDays?: number;
  primaryVerification?: string;
  verificationStatus?: string;
  priVerExpireDate?: string;
  secVerification?: string;
  secVerificationStatus?: string;
  orderDate?: string;
  suppliesDate?: string;
  lastMaskDate?: string;
  createdDate?: string;
  modifiedDate?: string;
  exchangeOrReturn?: string;
  paymentStatus?: string;
  helloSignStatus?: string;
  createdby?: string;
  billingOrderType?: string;
  modifiedBy?: string;
  orderStatus?: string;
  orderStatusName?: string;
  primaryInsId?: string;
  primaryPayerId?: string;
  secondaryPayerId?: string;
  tertiaryPayerId?: string;
  primaryInsName?: string;
  secondaryInsId?: string;
  secondaryInsName?: string;
  authorizationId?: string;
  authStatus?: string;
  medicareId?: string;
  medicareStatus?: string;
  compianceStatus?: string;
  rXvalidityDays: number;
  rXmailStatus?: string;
  rxSmsStatus?: string;
  isAuthExpire: boolean;
  isInsuranceDone: boolean;
  isMedicare: boolean;
  expireDate?: string;
  inActiveSuppliesDate?: string;
  inActiveSupplyStatus?: string;
  suppliesInactiveDate?: string;
  csrId?: string;
  csrName?: string;
}

export interface clsSaleOrderDTOforBilling {
  patientId?: string;
  chartId?: string;
  age?: string;
  patientFullName?: string;
  dateOfBirth?: string;
  addressId?: string;
  addressDescription?: string;
  phoneNo?: string;
  faxNo?: string;
  referringDoctorId?: string;
  referringDoctorName?: string;
  referringDoctorAddress?: string;
  referringDoctorPhoneNo?: string;
  referringDoctorFaxNo?: string;
  referringDoctoreMailId?: string;
  referringNPINo?: string;
  insuranceId?: string;
  insuranceName?: string;
  policNo?: string;
  verificationId?: string;
  verificationDate?: string;
  verifiedBy?: string;
  scheduleId?: string;
  scheduleName?: string;
  scheduleDate?: string;
  orderId?: string;
  orderNo: number;
  orderDate?: string;
  serviceLocation?: string;
  providerNPINo?: string;
  deliveryType?: string;
  ordercreateddate?: string;
  orderstatusId?: string;
  orderStatus?: string;
  isRental: boolean;
  rentalAllowable?: number;
  rentalMonth: number;
  newSetup: number;
  exchanged?: boolean;
  returned?: boolean;
  gender?: string;
  patientemail?: string;
  secondarypolicyname?: string;
  secondaryPolicyId?: string;
  secondaryPolicyNo?: string;
  secondaryVerificationId?: string;
  secondaryVerificationVerifiedBy?: string;
  secondaryVerificationVerifiedOn?: string;
  rxDate?: string;
  rxDocuments: number[];
  dos?: string;
  authId?: string;
  authNo?: string;
  medicareChecklistId?: string;
  medicareName?: string;
  csrName?: string;
  location?: string;
  practiceName?: string;
  practiceFax?: string;
  practiceEmail?: string;
  commentsToPatient?: string;
  commentsToShipping?: string;
  commentsToBilling?: string;
  commentsToTech?: string;
  orderAmount?: number;
  allowedAmount?: number;
  accessory?: number;
  estcopay?: number;
  estAmountDue?: number;
  modifiedBy?: string;
  createdBy?: string;
  modifiedDate?: string;
  cashOrder: boolean;
  totDiscount: number;
  rentalDetails: RentalDTO[];
  ltOrderItemDetails: SaleOrderItemforBilingDTO[];
  createdDate?: string;
  inboundId?: string;
  defaultFaxId?: string;
  newOrderId?: string;
  newOrderMmReferenceId?: number;
  phoneNumber?: string;
  providerNpi?: string;
  provideraddress?: string;
  providerName?: string;
  isSecondaryVerificationDone?: string;
  isPrimaryVerificationDone?: string;
  shippingFee?: string;
}

export interface SaleOrderItemforBilingDTO {
  orderItemId: string;
  orderId: string;
  itemId: string;
  prodCode: string;
  itemName: string;
  quantity: number;
  Allowable: number;
  manufactuureId?: string;
  manufactuureName: string;
  hcpcCode: string;
}
export interface CreateUpdateResupplyRemainderSettingDTO {
  insCatId?: string;
  productCatId?: string;
  quantity: number;
  dueDays: number;
  isActive?: boolean;
}
export interface ResupplyRemainderSettingDTO extends AuditedEntityDto<string> {
  insCatName?: string;
  insCatId?: string;
  productCatName?: string;
  productCatId?: string;
  quantity: number;
  dueDays: number;
  createdBy?: string;
  modifiedBy?: string;
  isActive: boolean;
}
export interface ResupplyRemainderSetting
  extends FullAuditedAggregateRoot<string> {
  insCatId?: string;
  productCatId?: string;
  quantity: number;
  dueDays: number;
  isActive: boolean;
}

export interface CreateUpdateResupplyStatusInactiveSuppliesDTO {
  fromDate?: string;
  toDate?: string;
  chartID?: string;
  patientName?: string;
  csr: string[];
}

export interface SupplyArchiveItemsDTO {
  patientId?: string;
  orderId?: string;
  isCallNote?: boolean;
  noteType?: string;
  noteTypeId?: string;
  description?: string;
  inactivationType: SupplyInactivationType;
  nextCallDate?: string;
}
export interface RentalDTO {
  id?: string;
  mmOrderId?: string;
  rentalAllowable?: number;
  orderAmount?: number;
  dosDate?: string;
  rentalStatus?: mmRentalTypes;
}
export interface FilterResupply {
  chartNo?: string;
  patientName?: string;
  fromDate?: string;
  toDate?: string;
  csrId: string[];
}
export interface ExchangeOrdersListDTO {
  patientId?: string;
  chartId?: string;
  patientName?: string;
  originalOrderId?: string;
  originalOrderNo: number;
  newOrderId?: string;
  newOrderNo: number;
  originalOrderDate?: string;
  originalOrderDOS?: string;
  newOrderDate?: string;
  newOrderDOS?: string;
  originalOrderCreatedBy?: string;
  originalOrderCreatedByName?: string;
  originalOrderCreatedDate?: string;
  originalOrderModifiedBy?: string;
  originalOrderModifiedByName?: string;
  originalOrderModifiedDate?: string;
  newOrderCreatedBy?: string;
  newOrderCreatedByName?: string;
  newOrderCreatedDate?: string;
  newOrderModifiedBy?: string;
  newOrderModifiedByName?: string;
  newOrderModifiedDate?: string;
  exchangeDate?: string;
}
export interface OrderTabResultDTO {
  orderCount?: number;
  shippingCount?: number;
  billingCount?: number;
  resupplyCount?: number;
  order: MmOrderDTO[];
  shipping: CompletedOrderDTO[];
  billing: BillingApprovalListDTO[];
  resupply: completedandnoncashorderlistDTO[];
}
export interface OrderTabResultDTO {
  orderCount?: number;
  shippingCount?: number;
  billingCount?: number;
  resupplyCount?: number;
  order: MmOrderDTO[];
  shipping: CompletedOrderDTO[];
  billing: BillingApprovalListDTO[];
  resupply: completedandnoncashorderlistDTO[];
}
export interface CountsOutputForWonace {
  patientEntryCount: number;
  orderCount: number;
  claimCount: number;
  denialCount: number;
}
export interface requestDTO {
  shortCode: WonaceOrderTab;
  faxIds: string[];
  patientIds: string[];
  locationIds: string[];
  orderIds: string[];
  priPolicyIds: string[];
  secPolicyIds: string[];
  terPolicyIds: string[];
  dFromSuppliesDate?: string;
  dToSuppliesDate?: string;
  dFromOrderDate?: string;
  dToOrderDate?: string;
  csrIds: string[];
  orderStatus: string[];
  resupply?: string;
  agingdays?: string;
  filter?: string;
  pageNo: number;
  pageOffset: number;
}
export interface InputOrderListDto {
  iSearch?: number;
  orderIds: string[];
}
export interface OrderListDto {
  mmRefereceOrderId: number;
  orderId?: string;
}
export interface InputOrderStatusListDto {
  sSearch?: string;
  gOrderIds: string[];
}
export interface OrderStatusListDto {
  isActive?: boolean;
  description?: string;
  orderStatusId?: string;
}
