import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { inboundDocumentStatus, inboundDocumentStatusOptions } from '../admin-proxy/platform-app-management/rcm/platform-management/localization/enumeration/inbound-document-status.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { InboundDocumentService } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound';
import { Subscription } from 'rxjs';
import { InboundDocsList } from '../admin-proxy/platform-app-management/rcm/platform-management/inbound/dto';
import { debounceTime, distinct, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-archived-inbound-list',
  templateUrl: './archived-inbound-list.component.html',
  styleUrls: ['./archived-inbound-list.component.scss']
})
export class ArchivedInboundListComponent implements OnInit {
  isLoading: boolean = false;
  inboundDocumentStatusForm: FormGroup;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ["defaultDocumentId", "documentName", "documentStatus", "uploadedDate", "uploadedByName",];;
  arrTableData: any[] = [];
  inboundDocumentStatus: { key: string, value: string }[] = [];
  @ViewChild(MatSort, { static: false }) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  $subscription: Subscription[] = [];
  inboundStatus: string = "";
  txtInboundStatus: string = "";
  constructor(
    public fb: FormBuilder,
    private router: Router,
    public inboundService: InboundDocumentService,

  ) {
    this.inboundDocumentStatus = inboundDocumentStatusOptions?.map(z => {
      let x = {
        value: z?.value,
        key: z?.key
      }
      return x;
    })
    this.txtInboundStatus = inboundDocumentStatus.assigned; //Default the value
  }

  // initialize funtions
  ngOnInit(): void {


    this.inboundDocumentStatusForm = this.fb.group({
      txtInboundStatus: new FormControl(this.inboundDocumentStatus?.[0]?.key),
      //  chkArchived: new FormControl(true)
    })


    //  this.inboundDocumentStatusForm.patchValue({ txtInboundStatus: this.inboundDocumentStatus?.[0].key });


    this.getInboundDocumentsList();
  }
  // back to inbound page
  backToInboundDoc() {
    this.router.navigate(['inboundDocs']);
  }

  onDropdownChange(event: { value: string }) {
    // const selectedValue = (event.target as HTMLSelectElement).value;
    // Do something with the selected value, such as updating a property or making an API call
    //this.getInboundDocumentsList();
  }

  setTableData(data: any = []) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.sort.disableClear = true;
    this.dataSource.paginator = this.paginator;
  }

  getInboundDocumentsList() {
    //this.inboundStatus = this.inboundDocumentStatusForm.get('txtInboundStatus').value;
    this.isLoading = true;
    const filter: inboundDocumentStatus = <inboundDocumentStatus> this.inboundDocumentStatusForm.value.txtInboundStatus;

    const inboundList = this.inboundDocumentStatusForm.valueChanges.pipe(
      tap(x => {
        this.isLoading = true;
        this.setTableData([]);
      }),
      startWith({
        txtInboundStatus: this.inboundDocumentStatus?.[0]?.key,
        //  chkArchived: true
      }),

      distinct(),
      debounceTime(300),

      // /x.chkArchived,
      switchMap(x => this.inboundService.getInboundDocsListByStatus(x.txtInboundStatus))).subscribe(
        (response) => {
          response = response?.map((x: InboundDocsList) => {
            x.documentStatus = <inboundDocumentStatus> this.inboundDocumentStatus?.filter(y => y.key === x.documentStatus)?.[0]?.value;
            return x;
          })
          this.isLoading = false;
          this.setTableData(response);
        },
        (err) => {
          this.setTableData([]);
          this.isLoading = false;

          const data: HttpErrorResponse = err;
          Swal.fire({
            icon: 'info',
            text: data?.error?.error?.message,
          });
        }
      );
    this.$subscription.push(inboundList);
  }




}
