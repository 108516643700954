import { PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { MasterProviderService } from 'projects/order/src/app/order-proxy/order-management/inventory';
import { CreateUpdateMasterProviderDTO } from 'projects/order/src/app/order-proxy/order-management/inventory/dto';
import { DefaultPracticeDTO, OrderMasterDropdownDTO } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/dto/order/models';
import { OrderMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/order-master-dropdown.service';
import { PatientMasterDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/master-dropdown-service/patient-master-dropdown.service';
import { OrderDropdowns } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/order-dropdowns.enum';
import { RegionDropdownService } from 'projects/patient/src/app/dropdown-proxy/dropdown-management/dropdowns/region-dropdown-service';
import { TableService } from 'projects/shared/src/app/table.service';
import { NumberValidators } from 'projects/shared/src/app/validations/number-validator';
import { TextValidators } from 'projects/shared/src/app/validations/text-validator';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import emailMask from 'text-mask-addons/dist/emailMask';
import { ToastrService } from "ngx-toastr";
import { noConflict } from 'jquery';
import { noWhitespaceValidator } from 'projects/patient/src/app/add-note-tab/add-note-tab.component';

@Component({
  selector: 'app-inventory-provider-details',
  templateUrl: './inventory-provider-details.component.html',
  styleUrls: ['./inventory-provider-details.component.scss']
})
export class InventoryProviderDetailsComponent implements OnInit , OnDestroy {

  providerForm: FormGroup;
  // drpCountries: CountryDTO[];
  drpCity: any = [];
  drpState: any = [];

  isShowSpinner: boolean = false;
  notesModalTitle: string = '';
  isSave:string='Save';
  providerId: string = "";
  emailMask: any;
  defaultPractice: string;
  saveButtonHide: boolean;
  drpDefaultPractice: DefaultPracticeDTO[];
  subscription$: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private textValidators: TextValidators,
    private numberValidators: NumberValidators,
    public dialog: MatDialog,
    private providerService: MasterProviderService,
    private store: Store,
    public Table: TableService,
    public regionService: RegionDropdownService,
    private orderDropdownService: OrderMasterDropdownService,
    private patientDropdownService: PatientMasterDropdownService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InventoryProviderDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { providerId: string }) { }

  ngOnDestroy(): void {
    this.subscription$?.forEach((sub) => {
      sub && sub?.unsubscribe();
    });
  }


  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Check if the input field has the class 'zip-code-input'
    if (input.classList.contains('zip-code-input')) {
      let formattedValue = input.value.replace(/[^0-9]/g, '');

      if (formattedValue.length > 5) {
        formattedValue = formattedValue.slice(0, 5) + '-' + formattedValue.slice(5);
      }

      input.value = formattedValue;
    }
  }

  ngOnInit(): void {
    this.createProviderForm();
    this.getDropdown();
    this.getDropdownForDefaultPractices();

    if (this.data?.providerId !== null && this.data?.providerId !== undefined && this.data?.providerId !== "" && this.data?.providerId !== '00000000-0000-0000-0000-000000000000') {
      this.providerId = this.data?.providerId;
      this.getNoteById(this.providerId);
      this.notesModalTitle = 'Edit Provider Details';
      this.isSave='Update';
    }
    else {
      this.providerId = "";
      this.notesModalTitle = 'Add Provider Details';
      this.isSave='Save';
    }

    this.drpCity

  }

  ///forms value
  createProviderForm() {
    this.providerForm = this.formBuilder.group({
      txtCode: new FormControl(""),
      txtPrefixCode: new FormControl("",[this.textValidators.isTextCheck,Validators.required,noWhitespaceValidator]),
      drpDefaultPractice: new FormControl(""),
      txtFirstName: new FormControl("", [ Validators.required]),
      // txtLastName: new FormControl("", [ Validators.required]),
      txtLastName: new FormControl(""),
      txtMI: new FormControl(""),
      txtHomeAddress: new FormControl(""),
      drpCountry: new FormControl(null),
      drpState: new FormControl(null),
      drpCity: new FormControl(null),
      txtZip: new FormControl(""),
      txtFax: new FormControl("",),
      txtMobile: new FormControl(""),
      txtEmail: new FormControl(""),
      txtCreditials: new FormControl(""),
      txtChargeProv: new FormControl(""),
      txtInsuranceName: new FormControl("", this.textValidators.isTextCheck),
      txtLolaFax: new FormControl(""),
      txtPtan: new FormControl(""),
      txtMmAddress: new FormControl(""),
      txtNpi: new FormControl("", [this.numberValidators.isNumberCheck, Validators.required]),
      txtCallBack: new FormControl(""),
      txtMmCharlotteAddress: new FormControl(""),
      txtPayorId: new FormControl(""),
      txtMmTaxId: new FormControl("",[Validators.required]),
      chkAppointments: new FormControl(""),
      chkBilllingProvider: new FormControl(""),
      chkSignatureOnFile: new FormControl(""),
      chkRenderingProvider: new FormControl(""),
      chkMaster: new FormControl(""),
      txtPhoneNumber: new FormControl(""),
      chkActive: new FormControl(true),
      txtBcBsGroupId: new FormControl(""),
      txtBcBsProviderId: new FormControl(""),
      txtDefaultProviderId: new FormControl(""),
      txtFacilityProviderId: new FormControl(""),
      txtMedicaidGroupId: new FormControl(""),
      txtMedicaidProviderId: new FormControl(""),
      txtMedicareGroupId: new FormControl(""),
      txtMedicareProviderId: new FormControl(""),
      txtNpiProviderId: new FormControl(""),
      txtNPIGroupId: new FormControl(""),
      txtUser2: new FormControl(""),
      txtSignatureLine: new FormControl(""),
      txtTaxonomyCode: new FormControl(""),
      txtWorkersCompGroupId: new FormControl(""),
      txtWorkersCompProviderId: new FormControl("")
    })
    this.emailMask = emailMask;
  }
  clearDefaultPractice() {
    this.defaultPractice = '';
    this.providerForm.patchValue({ drpDefaultPractice: '' });
  }
  /// get by id
  getNoteById(providerId: string) {
    const providerGet = this.providerService.get(providerId).subscribe(response => {
      this.providerForm.patchValue({
        txtCode: response?.code,
        drpDefaultPractice: response?.defaultPractice,
        txtPrefixCode: response?.prefixCode,
        txtFirstName: response?.firstName,
        txtLastName: response?.lastName,
        txtMI: response?.mi,
        txtHomeAddress: response?.homeAddress,
        // drpCountry: response.country,
        drpState: response?.state,
        drpCity: response?.city,
        txtZip: response?.zip,
        txtFax: response?.fax,
        txtMobile: response?.mobile,
        txtEmail: response?.email,
        txtCreditials: response?.credentials,
        txtChargeProv: response?.charlotteAddress,
        txtInsuranceName: response?.insranceName,
        txtLolaFax: response?.lolaFax,
        txtPtan: response?.ptan,
        txtMmAddress: response?.mmAddress,
        txtNpi: response?.npi,
        txtCallBack: response?.callBack,
        txtMmCharlotteAddress: response?.charlotteAddress,
        txtPayorId: response?.payorId,
        txtMmTaxId: response?.mmTaxId,
        chkAppointments: response?.isAppoinments,
        chkBilllingProvider: response?.isBillingProvider,
        chkSignatureOnFile: response?.isSignatureOnFile,
        chkRenderingProvider: response?.isRenderingProvider,
        // chkMaster: response.master,
        txtPhoneNumber: response?.phone,
        chkActive: response?.isActive,
        txtBcBsGroupId: response?.description?.bcbsGroupId,
        txtBcBsProviderId: response?.description?.providerId,
        txtDefaultProviderId: response?.description?.defaultProviderId,
        txtFacilityProviderId: response?.description?.facilityProviderId,
        txtMedicaidGroupId: response?.description?.medicaidGroupId,
        txtMedicaidProviderId: response?.description?.medicaidProviderId,
        txtMedicareGroupId: response?.description?.medicaidGroupId,
        txtMedicareProviderId: response?.description?.medicareProviderId,
        txtNpiProviderId: response?.description?.npiProviderId,
        txtNPIGroupId: response?.description?.npiGroupId,
        // txtUser2: response.description.use
        txtSignatureLine: response?.description?.signatureLine,
        txtTaxonomyCode: response?.description?.taxanomyCode,
        txtWorkersCompGroupId: response?.description?.workerCompGroupId,
        txtWorkersCompProviderId: response?.description?.workersCompProviderId

      });
      this.providerForm.markAsDirty();
      this.providerId = response.id;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(providerGet);
  }

  /// get all dropdowns
  getDropdown() {
    // let patientDropdownInput : PatientDropdowns[];
    // patientDropdownInput = [

    // ]
    // this.patientDropdownService.getPatientDropdownsByInput().subscribe((stateResponse) => {
    //   let reponse: PatientMasterDropdownDTO = stateResponse;
    //   this.drpCountries = reponse?.countries;
    //   this.drpCity = reponse?.cities;
    //   this.drpState = reponse?.states;
    // }, err => {
    //   const data: HttpErrorResponse = err;
    //   Swal.fire({
    //     icon: 'error',
    //     text: data?.error?.error?.message,
    //   });
    // });
    this.countryDropdown()
  }


  drpCountry: any[] = [];
  drpStates: any[] = [];
  drpCities: any[] = [];
  /// country dropdown
  countryDropdown() {
    this.drpCountry = [];
    const countryDropdown = this.regionService.getAllCountriesByInput(new PagedAndSortedResultRequestDto).subscribe(response => {
      this.drpCountry = response.items;
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    });
    this.subscription$.push(countryDropdown);
  }
  /// dropdown for default practice
  getDropdownForDefaultPractices() {
    let orderDropdownInput: OrderDropdowns[];
    orderDropdownInput = [
      OrderDropdowns.lstDefaultPractices
    ]
    const orderDropdown = this.orderDropdownService.getOrderDropdownsByInput(orderDropdownInput).subscribe((stateResponse) => {
      let response: OrderMasterDropdownDTO = stateResponse;
      this.drpDefaultPractice = response.lstDefaultPractices
    }, err => {
      const data: HttpErrorResponse = err;
      Swal.fire({
        icon: 'info',
        text: data?.error?.error?.message,
      });
    })
    this.subscription$.push(orderDropdown);
  }

  onChangeState(value: string | null | undefined) {
    this.providerForm.patchValue({
      drpState: null,
      drpCity: null
    })
    this.drpStates = [];
    this.drpCity = [];
    if (value !== null && value !== undefined) {
      const stateDropdown = this.regionService.getAllStatesByCountryNameByCountryShortName(value).subscribe(response => {
        this.drpStates = response?.items ?? [];
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      })
      this.subscription$.push(stateDropdown);
    }
  }
  onChangeCity(value: string | null | undefined, country: string) {
    this.providerForm.patchValue({
      drpCity: null
    });
    country = (country == "") ? (this.providerForm.value.drpCountry == (null || undefined) ? "" : (this.providerForm.value.drpCountry)) : country;
    this.drpCity = [];
    if (value !== null && value !== undefined) {
      const cityDropdown = this.regionService.getAllCitiesByCountryAndStateNameByCountryShortNameAndStateShortName(country, value).subscribe(response => {
        this.drpCities = response?.items ?? [];
      }, err => {
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
      this.subscription$.push(cityDropdown);
    }
  }

  organizationUnitId = null;
  //save provider
  saveProvider() {
    this.isShowSpinner = true;
    this.saveButtonHide = true;
    // this.organizationUnitId = localStorage.getItem('initialOrganizationUnitId');

    let providerDetails: CreateUpdateMasterProviderDTO = {
      //id: this.insuranceId,
      prefixCode: this.providerForm.value.txtPrefixCode,
      code: this.providerForm.value.txtCode,
      defaultPractice: this.providerForm.value.drpDefaultPractice,
      firstName: this.providerForm.value.txtFirstName,
      lastName: this.providerForm.value.txtLastName,
      mi: this.providerForm.value.txtMI,
      homeAddress: this.providerForm.value.txtHomeAddress,
      state: this.providerForm.value.drpState,
      city: this.providerForm.value.drpCity,
      zip: this.providerForm.value.txtZip,
      mobile: this.providerForm.value.txtMobile,
      email: ((this.providerForm.value.txtEmail ===null ||this.providerForm.value.txtEmail ===undefined)?
      this.providerForm.value.txtEmail:this.providerForm.value.txtEmail.toLowerCase()),
      credentials: this.providerForm.value.txtCreditials,
      apptChargeProv: this.providerForm.value.txtChargeProv,
      insranceName: this.providerForm.value.txtInsuranceName,
      lolaFax: this.providerForm.value.txtLolaFax,
      ptan: this.providerForm.value.txtPtan,
      mmAddress: this.providerForm.value.txtMmAddress,
      npi: this.providerForm.value.txtNpi,
      callBack: this.providerForm.value.txtCallBack,
      charlotteAddress: this.providerForm.value.txtMmCharlotteAddress,
      payorId: this.providerForm.value.txtPayorId,
      mmTaxId: this.providerForm.value.txtMmTaxId,
      isAppoinments: +this.providerForm.value.chkAppointments,
      isBillingProvider: + this.providerForm.value.chkBilllingProvider,
      isSignatureOnFile: + this.providerForm.value.chkSignatureOnFile,
      isRenderingProvider: + this.providerForm.value.chkRenderingProvider,
      //isMaster:this.providerForm.value.chkMaster ,
      phone: this.providerForm.value.txtPhoneNumber,
      isActive: +this.providerForm.value.chkActive,
      // organizationUnitId: this.organizationUnitId,
      description: {
        bcbsGroupId: this.providerForm.value.txtBcBsGroupId,
        providerId: this.providerForm.value.txtBcBsProviderId,
        defaultProviderId: this.providerForm.value.txtDefaultProviderId,
        facilityProviderId: this.providerForm.value.txtFacilityProviderId,
        medicaidGroupId: this.providerForm.value.txtMedicaidGroupId,
        medicaidProviderId: this.providerForm.value.txtMedicaidProviderId,
        medicareGroupId: this.providerForm.value.txtMedicareGroupId,
        medicareProviderId: this.providerForm.value.txtMedicareProviderId,
        npiGroupId: this.providerForm.value.txtNPIGroupId,
        npiProviderId: this.providerForm.value.txtNpiProviderId,
        signatureLine: this.providerForm.value.txtSignatureLine,
        taxanomyCode: this.providerForm.value.txtTaxonomyCode,
        workerCompGroupId: this.providerForm.value.txtWorkersCompGroupId,
        workersCompProviderId: this.providerForm.value.txtWorkersCompProviderId,
      }
    }
    if (this.providerId === "" || this.providerId == null || this.providerId == undefined) {
      this.providerService.create(providerDetails).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Saved Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Saved Successfully','Success')
        // this.resetInsurance();
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        this.dialogRef.close();
        this.providerId = "00000000-0000-0000-0000-000000000000";
        // this.loadInsuranceTable();
      }, err => {
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }
    else {
      this.providerService.update(this.providerId, providerDetails).subscribe(response => {
        // Swal.fire({ title: 'Success', html: 'Updated Successfully', icon: 'success', timer: 3000, timerProgressBar: true });
        this.toastr.success('Updated Successfully','Success')
        // this.resetInsurance();
        this.dialogRef.close();
        this.providerId = "00000000-0000-0000-0000-000000000000";
        this.saveButtonHide = false;
        this.isShowSpinner = false;
        // this.loadInsuranceTable();
      }, err => {
        this.isShowSpinner = false;
        this.saveButtonHide = false;
        const data: HttpErrorResponse = err;
        Swal.fire({
          icon: 'info',
          text: data?.error?.error?.message,
        });
      });
    }

  }

  resetInsurance() {
    this.providerForm.patchValue({
      txtCode: '',
      txtPrefixCode: '',
      drpDefaultPractice: '',
      txtFirstName: '',
      txtLastName: '',
      txtMI: '',
      txtHomeAddress: '',
      drpState: '',
      drpCity: '',
      txtZip: '',
      txtFax: '',
      txtMobile: '',
      txtEmail: '',
      txtCreditials: '',
      txtChargeProv: '',
      txtInsuranceName: '',
      txtLolaFax: '',
      txtPtan: '',
      txtMmAddress: '',
      txtNpi: '',
      txtCallBack: '',
      txtMmCharlotteAddress: '',
      txtPayorId: '',
      txtMmTaxId: '',
      chkAppointments: '',
      chkBilllingProvider: '',
      chkSignatureOnFile: '',
      chkRenderingProvider: '',
      chkMaster: '',
      txtPhoneNumber: '',
      chkActive: 'true',
      txtBcBsGroupId: '',
      txtBcBsProviderId: '',
      txtDefaultProviderId: '',
      txtFacilityProviderId: '',
      txtMedicaidGroupId: '',
      txtMedicaidProviderId: '',
      txtMedicareGroupId: '',
      txtMedicareProviderId: '',
      txtNpiProviderId: '',
      txtNPIGroupId: '',
      txtUser2: '',
      txtSignatureLine: '',
      txtTaxonomyCode: '',
      txtWorkersCompGroupId: '',
      txtWorkersCompProviderId: ''

    })

    this.providerForm.markAsUntouched();
    this.providerForm.updateValueAndValidity();
  }


}
