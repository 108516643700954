<div class="container-fluid">
  <div class="card card-body mt-1">
    <form [formGroup]="PreviewExtractionForm">
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <button *ngIf="strFileId" type="button" class="btn btn-success">
                <span class="p-0 text-wrap d-flex align-items-center text-wrap">
                  {{ strFileId }}
                </span>
              </button>
              <button mat-button class="buttonColor ml-2" [disabled]="isPdfLoadSpinner" (click)="toggleTreeView()">
                View Doc
              </button>
            </div>
          </div>
        </div>
        <div class="col-6"></div>
      </div>
      <div class="row">
        <div *ngIf="isTreeViewEnabled" class="col-lg-2">
          <mat-selection-list (selectionChange)="selectionChange($event)" #sortDrp [multiple]="false">
            <ng-container *ngFor="let sortdata of mergedDocumentDetails">
              <ng-container *ngIf="sortdata?.documentType === 'Unprocessed'">
                <mat-list-option class="unprocessed" [ngClass]="{
                    success:
                      null === sortDrp?.selectedOptions?.selected[0]?.value
                  }" [selected]="
                    sortDrp?.selectedOptions?.selected[0]?.value === null
                  " [value]="null">
                  {{ sortdata?.documentType }}
                </mat-list-option>
              </ng-container>
              <ng-container *ngIf="sortdata?.documentType !== 'Unprocessed'">
                <mat-list-option class="processed" [class.success]="
                    sortdata?.blobName ===
                    sortDrp?.selectedOptions?.selected[0]?.value
                  " [value]="sortdata?.blobName" [selected]="
                    sortdata?.blobName ===
                    sortDrp?.selectedOptions?.selected[0]?.value
                  ">
                  {{ " " + " " + " " + " " + "-" + sortdata?.documentType }}({{
                  sortdata?.numberOfPages
                  }})
                </mat-list-option>
              </ng-container>
            </ng-container>
          </mat-selection-list>
        </div>
        <div *ngIf="isTreeViewEnabled" class="col-lg-6">
          <mat-spinner class="center" mode="indeterminate" *ngIf="isPdfLoadSpinner" diameter="50">
          </mat-spinner>
          <ng-container *ngIf="!isPdfLoadSpinner">
            <ngx-extended-pdf-viewer [enablePrint]="false" [showOpenFileButton]="false" [showPropertiesButton]="false"
              [showSpreadButton]="false" [showRotateButton]="false" [showHandToolButton]="false"
              [showScrollingButton]="true" pageViewMode="single" (pageChange)="getRenderedPageNumber($event)"
              [(page)]="pageNumber" [textLayer]="true" [src]="strSelectedPdfPath" (pdfDownloaded)="downloadClicked()"
              [zoom]="'page-width'" [height]="pdfHeight" [useBrowserLocale]="false">
            </ngx-extended-pdf-viewer>
          </ng-container>
        </div>
        <div *ngIf="!isTreeViewEnabled" class="col-lg-8">
          <ng-container>
            <div class="row mt-3">
              <mat-form-field class="col-6">
                <mat-label>Document Type</mat-label>
                <mat-select formControlName="drpDocumentType">
                  <mat-option *ngFor="let document of drpDocumentTypeLoop" [value]="document.documentType">
                    {{ document.documentType }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span class="col-6">
                <button [disabled]="
                    PreviewExtractionForm?.get('drpDocumentType')?.value === ''
                  " mat-button class="buttonColor mr-2" (click)="updateDocumentType()">
                  Save
                </button>
                <button mat-button class="buttonColor" (click)="viewAllPatientsListData()">
                  View All
                </button>
                <!-- <button mat-button class="buttonColor lightSeaGreen-dots" *ngIf="hideNotificatiaonButton"
                  (click)="clearNotificationData()">
                  Clear All Notification
                </button> -->
              </span>
            </div>
          </ng-container>
          <mat-spinner class="center" mode="indeterminate" *ngIf="isPdfLoadSpinner" diameter="50">
          </mat-spinner>
          <ng-container *ngIf="strSelectedPdfPath">
            <ngx-extended-pdf-viewer [enablePrint]="false" (pdfLoaded)="pdfLoadCompleted($event)"
              [showOpenFileButton]="false" [showPropertiesButton]="false" [showSpreadButton]="false"
              [showRotateButton]="false" [showHandToolButton]="false" [showScrollingButton]="true" pageViewMode="single"
              (pageChange)="getRenderedPageNumber($event)" [(page)]="pageNumber" [textLayer]="true"
              [src]="strSelectedPdfPath" (pdfDownloaded)="downloadClicked()" [zoom]="'page-width'" [height]="pdfHeight"
              [useBrowserLocale]="false">
            </ngx-extended-pdf-viewer>
          </ng-container>
        </div>
        <div class="col-lg-4">
          <ng-container>
            <div class="row mt-2" (click)="clearNotificationData()">
              <div class="col-12">
                <mat-progress-bar mode="determinate" [ngClass]="{
                    progress_success: percentageOfCompletion === 100,
                    progress_danger: percentageOfCompletion !== 100
                  }" matTooltip="{{ percentageOfCompletion + ' % Of Completion' }}" [value]="percentageOfCompletion">
                </mat-progress-bar>
              </div>
              <div class="col-12" [style.text-align]="'-webkit-center'">
                <button type="button" *ngIf="percentageOfCompletion === 100" class="mt-3 btn btn-success">
                  {{ percentageOfCompletion + " % Of Completion" }}
                </button>
                <button type="button" *ngIf="percentageOfCompletion !== 100" class="mt-3 btn btn-danger">
                  {{ percentageOfCompletion + " % Of Completion" }}
                </button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <br />
                <mat-checkbox formControlName="chkIsRulesNeeded">Is Rules Needed
                </mat-checkbox>
              </div>
              <mat-form-field class="col-12">
                <mat-label>DX Type</mat-label>
                <mat-select formControlName="drpDXType" (selectionChange)="
                    checkRulesForFeildHighlight(
                      rulesValues,
                      PreviewExtractionForm?.get('drpPrdSetupType')?.value,
                      PreviewExtractionForm?.get('drpDXType')?.value
                    )
                  ">
                  <mat-option *ngFor="let dx of drpDXType" [value]="dx.dxType">
                    {{ dx.dxType }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-12" *ngIf="
                  PreviewExtractionForm?.get('drpDXType')?.value !==
                    'CPAP to BIPAP' &&
                  PreviewExtractionForm?.get('drpDXType')?.value !== 'CompSA'
                ">
                <mat-label>Product Setup Type</mat-label>
                <mat-select formControlName="drpPrdSetupType" (selectionChange)="
                    checkRulesForFeildHighlight(
                      rulesValues,
                      PreviewExtractionForm?.get('drpPrdSetupType')?.value,
                      PreviewExtractionForm?.get('drpDXType')?.value
                    )
                  ">
                  <mat-option *ngFor="let setup of drpPrdSetupType" [value]="setup?.setupType">
                    {{ setup?.setupType }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <mat-accordion #firstAccordion="matAccordion">
                  <mat-expansion-panel id="DEMOGRAPHICS" #mep="matExpansionPanel"
                    (opened)="currentlyOpenAcc('DEMOGRAPHICS')" *ngFor="
                      let index of lstIndex
                        | filter: { documentType: 'DEMOGRAPHICS' }
                    " [expanded]="false">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="customThemeClass mt-3">
                        <ng-container>
                          <b>Demographics </b>
                          <!-- <i [ngClass]="{hide:demoHide==true}"
                            *ngIf="demo1st && index?.documentType=='DEMOGRAPHICS'" class="fa fa-plus ml-5"
                            (click)="addAccordion()" ></i>  -->
                          <i *ngIf="demographicsPendingValidationCount === 0" class="fa fa-check-circle-o ml-5"></i>
                          <span *ngIf="demographicsPendingValidationCount !== 0" [ngClass]="{
                              ' deleteBtnColor ml-5':
                                demographicsPendingValidationCount !== 0
                            }" matBadge="{{ demographicsPendingValidationCount }}" matBadgeSize="small"></span>
                        </ng-container>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                      <div *ngFor="let ticket of t.controls; let i = index" class="list-group list-group-flush">
                        <div class="list-group-item">
                          <h4 class="card-title">
                            <b>Demographics {{ i + 1 }}</b>
                          </h4>
                          <ng-container *ngIf="!!patientsId && fileStatus !== 'Approved'">
                            <p>Extracted Patient</p>
                            <div class="table-responsive">
                              <table class="table table-bordered table-sm">
                                <thead>
                                  <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">DOB</th>
                                    <th scope="col">Options</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr [ngClass]="{
                                      patient_highlight:
                                        patientId === approvedPatientId &&
                                        approvedPatientId === ''
                                    }">
                                    <td>
                                      {{ extraPtData?.patientName }}
                                    </td>
                                    <td>
                                      {{
                                      extraPtData?.dateOfBirth
                                      | date: "MM/dd/yyyy":"en_US"
                                      }}
                                    </td>
                                    <td>
                                      <input  *ngIf="fileStatus !== 'Approved'" class="customThemeClass"
                                        style="width: 60px; height: 20px" type="radio"
                                        matTooltip="Click To Select Patient" (click)="patientId = ''" target="_blank"
                                        [checked]="
                                          patientId === approvedPatientId &&
                                          approvedPatientId === ''
                                        " />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="
                              identifiedPatientList !== [] &&
                              identifiedPatientList?.length !== 0 &&
                              fileStatus !== 'Approved'
                            ">
                            <p>Identical Patient</p>
                            <div class="table-responsive">
                              <table class="table table-bordered table-sm">
                                <thead>
                                  <tr>
                                    <th scope="col">Patient Id</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">DOB</th>
                                    <th scope="col">Mail Id</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Options</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <ng-container *ngFor="
                                      let ipatient of identifiedPatientList
                                    ">
                                    <tr [ngClass]="{
                                        patient_highlight:
                                          patientId ===
                                          ipatient?.identifiedPatientId
                                      }">
                                      <th scope="row">
                                        {{ ipatient?.defaultPatientId }}
                                      </th>
                                      <td>{{ ipatient?.patientName }}</td>
                                      <td>
                                        {{
                                        ipatient?.dateOfBirth
                                        | date: "MM/dd/yyyy":"en_US"
                                        }}
                                      </td>
                                      <td>{{ ipatient?.mailId }}</td>
                                      <td>{{ ipatient?.patientStatus }}</td>
                                      <td>
                                        <input *ngIf="fileStatus !== 'Approved'" class="customThemeClass"
                                          style="width: 60px; height: 20px" type="radio"
                                          matTooltip="Click To Select Patient" (click)="
                                            patientId =
                                              ipatient?.identifiedPatientId
                                          " target="_blank" [checked]="
                                            patientId ===
                                            ipatient?.identifiedPatientId
                                          " />
                                        <!--
                                        <a *ngIf="fileStatus!=='Approved'" class="eyeCursorclass customThemeClass"
                                          matTooltip="Click To Select Patient" (click)="
                                              patientId =
                                                ipatient?.identifiedPatientId
                                            " target="_blank"><i class="fa fa-pencil"></i></a> -->
                                      </td>
                                    </tr>
                                  </ng-container>
                                </tbody>
                              </table>
                            </div>
                          </ng-container>
                          <div [formGroup]="ticket" class="form-row">
                            <mat-form-field class="col-12">
                              <mat-label> First Name </mat-label>
                              <input autocomplete="new-txtFirstName" matInput maxlength="40" formControlName="txtFirstName" type="text" />
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtFirstName')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtFirstName')
                                    ?.errors?.sAlphabets
                                ">
                                Enter only Alphabets!
                              </mat-error>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtFirstName')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtFirstName')
                                    ?.errors?.required
                                ">
                                First Name is a required field!
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label> Middle Name </mat-label>
                              <input autocomplete="new-txtMiddleName" matInput maxlength="40" formControlName="txtMiddleName" type="text" />
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtMiddleName')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtMiddleName')
                                    ?.errors?.sAlphabets
                                ">
                                Enter only Alphabets!
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label> Last Name </mat-label>
                              <input autocomplete="new-txtLastName" matInput maxlength="40" formControlName="txtLastName" type="text" />
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtLastName')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtLastName')
                                    ?.errors?.sAlphabets
                                ">
                                Enter only Alphabets!
                              </mat-error>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtLastName')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtLastName')
                                    ?.errors?.required
                                ">
                                Last Name is a required field!
                              </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-12">
                              <mat-label>Gender</mat-label>
                              <mat-select formControlName="drpGeneralGender" [(value)]="gender" maxlength="40">
                                <mat-option *ngFor="let test of drpGenderLoop" [value]="test.genderShortCode">
                                  {{ test.genderName }}
                                </mat-option>
                              </mat-select>
                              <button mat-button (click)="gender = ''; $event.stopPropagation()" *ngIf="gender"
                                matSuffix (click)="clearGender()" mat-icon-button >
                                <mat-icon>close</mat-icon>
                              </button>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('drpGeneralGender')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('drpGeneralGender')
                                    ?.errors?.required
                                ">
                                Gender is a required field!
                              </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-12">
                              <mat-label> Birth Date </mat-label>
                              <input autocomplete="new-txtDOB" formControlName="txtDOB" matInput maxlength="40" type="text"
                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDOB" />
                              <mat-datepicker-toggle matSuffix [for]="txtDOB">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtDOB> </mat-datepicker>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtDOB')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtDOB')?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtDOB')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtDOB')?.errors
                                    ?.required
                                ">
                                Birth Date is a required field!
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="mt-4 col-12">
                              <mat-label> Reason </mat-label>
                              <input autocomplete="new-txtReason" matInput maxlength="40" formControlName="txtReason" type="text" />
                            </mat-form-field>
                          </div>
                          <div *ngIf="fileStatus !== 'Approved'" class="row">
                            <div class="col-12">
                              <button [disabled]="!patientsId" mat-button class="float-right resetclr" (click)="
                                  aprveOrDisAprvePat(
                                    fileApprovalStatus?.Rejected
                                  )
                                ">
                                Disapprove
                              </button>
                              <button [disabled]="!patientsId" mat-button class="float-right buttonColor mr-2" (click)="
                                  aprveOrDisAprvePat(
                                    fileApprovalStatus?.Approved
                                  )
                                ">
                                Approve
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <i *ngIf="demo2nd" [ngClass]="{ hide: demoHide == true }" class="fa fa-plus customThemeClass"
                        matTooltip="Click To Add Demographics" style="
                          cursor: pointer;
                          margin-left: 362px;
                          font-size: x-large;
                        " (click)="addAccordion()"></i>
                    </div>
                    <app-patient-search *ngIf="!!patientsId && fileStatus !== 'Approved'" [patientId]="patientId"
                      (selPatientId)="getPatientIdOnselect($event)">
                    </app-patient-search>
                  </mat-expansion-panel>
                  <mat-expansion-panel id="FACE TO FACE"
                    (opened)="currentlyOpenAcc('FACE TO FACE')" *ngFor="
                      let index of lstIndex
                        | filter: { documentType: 'FACE TO FACE' }
                    " [expanded]="false">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="customThemeClass mt-3">
                        <ng-container>
                          <b>Initial face to face </b>

                          <i *ngIf="
                              initialFaceToFacePendingValidationCount === 0
                            " class="fa fa-check-circle-o ml-5"></i>
                          <span *ngIf="
                              initialFaceToFacePendingValidationCount !== 0
                            " [ngClass]="{
                              ' deleteBtnColor ml-5':
                                initialFaceToFacePendingValidationCount !== 0
                            }" matBadge="{{
                              initialFaceToFacePendingValidationCount
                            }}" matBadgeSize="small"></span>
                        </ng-container>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                      <div *ngFor="let ticket of b.controls; let i = index" class="list-group list-group-flush">
                        <div class="list-group-item">
                          <h4 class="card-title">
                            <b>Initial Face To Face {{ i + 1 }}</b>
                          </h4>
                          <div [formGroup]="ticket" class="form-row">
                            <mat-form-field class="col-12">
                              <mat-label> Patient Name </mat-label>
                              <input autocomplete="new-txtFobPatientName" matInput maxlength="40" formControlName="txtFobPatientName" type="text" />
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get(
                                    'txtFobPatientName'
                                  )?.touched &&
                                  PreviewExtractionForm?.get(
                                    'txtFobPatientName'
                                  )?.errors?.sAlphabets
                                ">
                                Enter only Alphabets!
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label> Date of Birth </mat-label>
                              <input autocomplete="new-txtIobDob" formControlName="txtIobDob" matInput maxlength="40" type="text"
                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtIobDob" />
                              <mat-datepicker-toggle matSuffix [for]="txtIobDob">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtIobDob> </mat-datepicker>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtIobDob')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtIobDob')
                                    ?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label [ngClass]="{
                                  warning:
                                    ruleColor?.erStatus13 ||
                                    ruleColor?.erStatus15 ||
                                    ruleColor?.erStatus29 ||
                                    ruleColor?.erStatus40 ||
                                    ruleColor?.erStatus43
                                }">
                                Face To Face Date
                              </mat-label>
                              <input autocomplete="new-txtIobFadeToFace" formControlName="txtIobFadeToFace" matInput matTooltip="{{
                                  ruleColor?.errValue13 ||
                                    ruleColor?.errValue15 ||
                                    ruleColor?.errValue29 ||
                                    ruleColor?.errValue40 ||
                                    ruleColor?.errValue43
                                }}" maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                                [matDatepicker]="txtIobFadeToFace" />
                              <mat-datepicker-toggle matSuffix [for]="txtIobFadeToFace">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtIobFadeToFace>
                              </mat-datepicker>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtIobFadeToFace')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtIobFadeToFace')
                                    ?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label> Date of Visit </mat-label>
                              <input autocomplete="new-txtDateofVisit" [max]="txtDateofVisit" formControlName="txtDateofVisit" matInput maxlength="40"
                                type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDateofVisit"
                                [min]="txtDateofVisit" />
                              <mat-datepicker-toggle matSuffix [for]="txtDateofVisit">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtDateofVisit> </mat-datepicker>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtDateofVisit')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtDateofVisit')
                                    ?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>

                            <div class="col-md-12 text-right">
                              <button type="button" class="buttonColor btn-sm btn-circle" (click)="openDoctorModal()">
                                <i class="fa fa-plus" matTooltip="Click to Add Doctor"></i>
                              </button>
                            </div>
                            <mat-form-field class="col-12">
                              <mat-label>Doctor Name</mat-label>
                              <mat-select formControlName="txtDoctor" (valueChange)="
                                  doctorValueChange('f2f', singleSelect)
                                " [(value)]="OrderingDoctor" #singleSelect>
                                <mat-option *ngFor="let doctor of drpDoctor" [value]="doctor.name">
                                  {{ doctor.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label>NPI Number</mat-label>
                              <input autocomplete="new-txtNpiNumber" maxlength="13" mask="000-000-0000" formControlName="txtNpiNumber" matInput
                                type="text" />
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtNpiNumber')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtNpiNumber')
                                    ?.errors?.number
                                ">
                                Enter only Numbers!
                              </mat-error>
                            </mat-form-field>
                            <!-- <mat-form-field class="col-6">
                              <mat-label> Signature </mat-label>
                              <input matInput maxlength="40" formControlName="txtSignature" type="text" />
                            </mat-form-field> -->
                            <mat-form-field class="col-12">
                              <mat-label> Signed date </mat-label>
                              <input autocomplete="new-txtSignedDate" formControlName="txtSignedDate" matInput maxlength="40" type="text"
                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtSignedDate1" />
                              <mat-datepicker-toggle matSuffix [for]="txtSignedDate1">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtSignedDate1> </mat-datepicker>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtSignedDate')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtSignedDate')
                                    ?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                            <div class="col-12">
                              <br />
                              <mat-checkbox formControlName="chkPrevioushistory">Previous history – declaration
                              </mat-checkbox>
                            </div>
                            <div class="col-12">
                              <br />
                              <mat-checkbox aria-labelledby="Sleep Test Recommended" [ngClass]="{
                                  warning:
                                    ruleColor?.erStatus2 ||
                                    ruleColor?.erStatus21
                                }" matTooltip="{{
                                  ruleColor?.errValue2 || ruleColor?.errValue21
                                }}" formControlName="chkSleepTestRecommended">Sleep Test Recommended
                              </mat-checkbox>
                            </div>
                            <div class="col-12">
                              <br />
                              <mat-checkbox [ngClass]="{
                                  warning:
                                    ruleColor?.erStatus3 ||
                                    ruleColor?.erStatus17 ||
                                    ruleColor?.erStatus22 ||
                                    ruleColor?.erStatus32
                                }" matTooltip="{{
                                  ruleColor?.errValue3 ||
                                    ruleColor?.errValue17 ||
                                    ruleColor?.errValue22 ||
                                    ruleColor?.errValue32
                                }}" formControlName="chkSleepDoctorSigned">Doctor Signed
                              </mat-checkbox>
                            </div>
                            <div class="col-12">
                              <br />

                              <mat-checkbox matTooltip="{{
                                  ruleColor?.errValue16 || ruleColor?.errValue30
                                }}" [ngClass]="{
                                  warning:
                                    ruleColor?.erStatus16 ||
                                    ruleColor?.erStatus30
                                }" formControlName="chkSleepPatientBenifited">
                                Patient Benifits and Usage
                              </mat-checkbox>
                            </div>

                            <mat-form-field class="col-12">
                              <mat-label [ngClass]="{
                                  warning:
                                    ruleColor?.erStatus1 ||
                                    ruleColor?.erStatus20
                                }">Symptoms
                              </mat-label>
                              <mat-select multiple matTooltip="{{
                                  ruleColor?.errValue1 || ruleColor?.errValue20
                                }}" formControlName="drpSymptoms">
                                <mat-option *ngFor="let sysm of drpSymptoms" [value]="sysm.symptom">
                                  {{ sysm.symptom }}
                                </mat-option>
                              </mat-select>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('drpSymptoms')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('drpSymptoms')
                                    ?.errors?.required
                                ">
                                Symptoms is a required field!
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <i *ngIf="face2nd" [ngClass]="{ hide: faceHide == true }" class="fa fa-plus ml-8 customThemeClass"
                        matTooltip="Click To Add Face To Face" style="
                          cursor: pointer;
                          margin-left: 362px;
                          font-size: x-large;
                        " (click)="addFaceAccordion()"></i>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel id="SLEEP STUDY REPORT"
                    (opened)="currentlyOpenAcc('SLEEP STUDY REPORT')" *ngFor="
                      let index of lstIndex
                        | filter: { documentType: 'SLEEP STUDY REPORT' }
                    " [expanded]="false">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="customThemeClass mt-3">
                        <ng-container>
                          <b>Sleep study report
                            <ng-container *ngIf="
                                index?.documentType !== 'SLEEP STUDY REPORT'
                              ">
                            </ng-container>
                          </b>
                          <i *ngIf="sleepStudyPendingValidationCount === 0" class="fa fa-check-circle-o ml-5"></i>
                          <span *ngIf="sleepStudyPendingValidationCount !== 0" [ngClass]="{
                              ' deleteBtnColor ml-5':
                                sleepStudyPendingValidationCount !== 0
                            }" matBadge="{{ sleepStudyPendingValidationCount }}" matBadgeSize="small"></span>
                        </ng-container>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                      <div *ngFor="let ticket of d.controls; let i = index" class="list-group list-group-flush">
                        <div class="list-group-item">
                          <h4 class="card-title">
                            <b>Sleep study report {{ i + 1 }}</b>
                          </h4>
                          <div [formGroup]="ticket" class="form-row">
                            <mat-form-field class="col-12">
                              <mat-label> Patient Name </mat-label>
                              <input autocomplete="new-txtPatientName1" matInput maxlength="40" formControlName="txtPatientName1" type="text" />
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtPatientName1')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtPatientName1')
                                    ?.errors?.sAlphabets
                                ">
                                Enter only Alphabets!
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label> Birth Date </mat-label>
                              <input autocomplete="new-txtDob" formControlName="txtDob" matInput maxlength="40" type="text"
                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDob" />
                              <mat-datepicker-toggle matSuffix [for]="txtDob">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtDob> </mat-datepicker>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtDob')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtDob')?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label> Study date </mat-label>
                              <input autocomplete="new-txtStudyDate" formControlName="txtStudyDate" matInput maxlength="40" type="text"
                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtStudyDate" />
                              <mat-datepicker-toggle matSuffix [for]="txtStudyDate">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtStudyDate> </mat-datepicker>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtStudyDate')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtStudyDate')
                                    ?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label>Pressure Setting </mat-label>
                              <input autocomplete="new-txtPressureSetting" matInput formControlName="txtPressureSetting" pattern="^\d{0,10}"
                                (keypress)="numberOnly($event)" maxlength="40" type="text" />
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label>REI</mat-label>
                              <input autocomplete="new-txtREI" maxlength="5" formControlName="txtREI" (keypress)="keyPressNumbersDecimal($event)"
                                matInput type="text" />
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label>RDI </mat-label>
                              <input autocomplete="new-txtRDI" maxlength="5" formControlName="txtRDI" (keypress)="keyPressNumbersDecimal($event)"
                                matInput type="text" />
                            </mat-form-field>
                            <div>
                              <p class="col-12">
                                <br />
                                <b> Diagnostic Sleep Study </b>
                              </p>
                              <mat-form-field class="col-6">
                                <mat-label [ngClass]="{ warning: ruleColor?.erStatus5 }">Total Sleep Minutes</mat-label>
                                <input autocomplete="new-txtSleepMinutes" matInput formControlName="txtSleepMinutes"
                                  matTooltip="{{ ruleColor?.errValue5 }}" pattern="^\d{0,10}"
                                  (keypress)="numberOnly($event)" maxlength="5" type="text" />
                              </mat-form-field>
                              <mat-form-field class="col-6">
                                <mat-label [ngClass]="{
                                    warning:
                                      ruleColor?.erStatus6 ||
                                      ruleColor?.erStatus24 ||
                                      ruleColor?.erStatus32
                                  }">
                                  AHI</mat-label>
                                <input autocomplete="new-txtAHI" type="text" maxlength="5" (keypress)="keyPressNumbersDecimal($event)"
                                  formControlName="txtAHI" matInput matTooltip="{{
                                    ruleColor?.errValue6 ||
                                      ruleColor?.errValue24 ||
                                      ruleColor?.errValue32
                                  }}" />
                              </mat-form-field>
                              <div class="col-6">
                                <br />
                                <mat-checkbox matTooltip="{{
                                    ruleColor?.errValue7 ||
                                      ruleColor?.errValue18 ||
                                      ruleColor?.errValue23 ||
                                      ruleColor?.errValue26 ||
                                      ruleColor?.errValue35
                                  }}" [ngClass]="{
                                    warning:
                                      ruleColor?.erStatus7 ||
                                      ruleColor?.erStatus18 ||
                                      ruleColor?.erStatus23 ||
                                      ruleColor?.erStatus26 ||
                                      ruleColor?.erStatus35
                                  }" formControlName="chkSleepStudyDoctorSigned">Doctor Signed
                                </mat-checkbox>
                              </div>
                              <mat-form-field class="col-6">
                                <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Central Apneas</mat-label>
                                <input autocomplete="new-txtSSCentralApneas" type="text" maxlength="5" matTooltip="{{ ruleColor?.errValue27 }}"
                                  (keypress)="keyPressNumbersDecimal($event)" formControlName="txtSSCentralApneas"
                                  matInput />
                              </mat-form-field>
                              <mat-form-field class="col-6">
                                <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Central Hyponeas</mat-label>
                                <input autocomplete="new-txtSSCentralHyponeas" type="text" maxlength="5" matTooltip="{{ ruleColor?.errValue27 }}"
                                  (keypress)="keyPressNumbersDecimal($event)" formControlName="txtSSCentralHyponeas"
                                  matInput />
                              </mat-form-field>

                              <mat-form-field class="col-6">
                                <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Total Apnea And Hypopnea
                                </mat-label>
                                <input autocomplete="new-txtSSApneaAndHypopnea" type="text" maxlength="5" matTooltip="{{ ruleColor?.errValue27 }}"
                                  (keypress)="keyPressNumbersDecimal($event)" formControlName="txtSSApneaAndHypopnea"
                                  matInput />
                              </mat-form-field>
                              <mat-form-field class="col-6">
                                <mat-label [ngClass]="{
                                    warning:
                                      ruleColor?.erStatus4 ||
                                      ruleColor?.erStatus23 ||
                                      ruleColor?.erStatus14
                                  }">
                                  Diagnosis Sleep Study date
                                </mat-label>
                                <input autocomplete="new-txtDiagnosisStudyDate" formControlName="txtDiagnosisStudyDate" matInput maxlength="40" type="text"
                                  matTooltip="{{
                                    ruleColor?.errValue4 ||
                                      ruleColor?.errValue23 ||
                                      ruleColor?.errValue14
                                  }}" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDiagnosisStudyDate" />
                                <mat-datepicker-toggle matSuffix [for]="txtDiagnosisStudyDate">
                                </mat-datepicker-toggle>
                                <mat-datepicker #txtDiagnosisStudyDate>
                                </mat-datepicker>
                                <mat-error *ngIf="
                                    PreviewExtractionForm?.get(
                                      'txtDiagnosisStudyDate'
                                    )?.touched &&
                                    PreviewExtractionForm?.get(
                                      'txtDiagnosisStudyDate'
                                    )?.errors
                                  ">
                                  Enter Valid date
                                </mat-error>
                              </mat-form-field>
                            </div>
                            <div>
                              <p class="col-12">
                                <br />
                                <b> Titration Sleep Study </b>
                              </p>
                              <mat-form-field class="col-6">
                                <mat-label [ngClass]="{
                                    warning:
                                      ruleColor?.erStatus37 ||
                                      ruleColor?.erStatus45
                                  }">Sleep Study Date
                                </mat-label>
                                <input autocomplete="new-txtTitrationSleepStudyDate" formControlName="txtTitrationSleepStudyDate" matInput maxlength="40" type="text"
                                  placeholder="(MM/DD/YYYY)" matTooltip="{{
                                    ruleColor?.errValue37 ||
                                      ruleColor?.errValue45
                                  }}" [matDatepicker]="txtTitrationSleepStudyDate" />
                                <mat-datepicker-toggle matSuffix [for]="txtTitrationSleepStudyDate">
                                </mat-datepicker-toggle>
                                <mat-datepicker #txtTitrationSleepStudyDate>
                                </mat-datepicker>
                                <mat-error *ngIf="
                                    PreviewExtractionForm?.get(
                                      'txtTitrationSleepStudyDate'
                                    )?.touched &&
                                    PreviewExtractionForm?.get(
                                      'txtTitrationSleepStudyDate'
                                    )?.errors
                                  ">
                                  Enter Valid date
                                </mat-error>
                              </mat-form-field>
                              <mat-form-field class="col-6">
                                <mat-label [ngClass]="{
                                    warning:
                                      ruleColor?.erStatus6 ||
                                      ruleColor?.erStatus24 ||
                                      ruleColor?.erStatus32
                                  }">
                                  AHI</mat-label>
                                <input autocomplete="new-txtTAHI" type="text" maxlength="5" (keypress)="keyPressNumbersDecimal($event)"
                                  formControlName="txtTAHI" matTooltip="{{
                                    ruleColor?.errValue6 ||
                                      ruleColor?.errValue24 ||
                                      ruleColor?.errValue32
                                  }}" matInput />
                              </mat-form-field>
                              <mat-form-field class="col-6">
                                <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Central Apneas</mat-label>
                                <input autocomplete="new-txtTCentralApneas" type="text" matTooltip="{{ ruleColor?.errValue27 }}" maxlength="5"
                                  (keypress)="keyPressNumbersDecimal($event)" formControlName="txtTCentralApneas"
                                  matInput />
                              </mat-form-field>
                              <mat-form-field class="col-6">
                                <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Central Hyponeas</mat-label>
                                <input autocomplete="new-txtTCentralHyponeas" type="text" maxlength="5" matTooltip="{{ ruleColor?.errValue27 }}"
                                  (keypress)="keyPressNumbersDecimal($event)" formControlName="txtTCentralHyponeas"
                                  matInput />
                              </mat-form-field>
                              <mat-form-field class="col-6">
                                <mat-label [ngClass]="{ warning: ruleColor?.erStatus27 }">Total Apnea And Hypopnea
                                </mat-label>
                                <input autocomplete="new-txtTApneaAndHypopnea" type="text" maxlength="5" matTooltip="{{ ruleColor?.errValue27 }}"
                                  (keypress)="keyPressNumbersDecimal($event)" formControlName="txtTApneaAndHypopnea"
                                  matInput />
                              </mat-form-field>
                              <div class="row m-0 p-0">
                                <div class="col-6">
                                  <br />
                                  <mat-checkbox [ngClass]="{
                                      warning:
                                        ruleColor?.erStatus8 ||
                                        ruleColor?.erStatus38
                                    }" matTooltip="{{
                                      ruleColor?.errValue8 ||
                                        ruleColor?.errValue38
                                    }}" formControlName="chkCPAPTriedAndFail">
                                    CPAP Tried And Fail
                                  </mat-checkbox>
                                </div>
                                <div class="col-6">
                                  <br />
                                  <mat-checkbox [ngClass]="{
                                      warning:
                                        ruleColor?.erStatus10 ||
                                        ruleColor?.erStatus39
                                    }" matTooltip="{{
                                      ruleColor?.errValue10 ||
                                        ruleColor?.errValue39
                                    }}" formControlName="chkRespiratoryBreakdownAvailable">
                                    Respiratory Breakdown Available
                                  </mat-checkbox>
                                </div>
                              </div>
                              <div class="row m-0 p-0">
                                <div class="col-6">
                                  <br />
                                  <mat-checkbox [ngClass]="{
                                      warning: ruleColor?.erStatus3
                                    }" matTooltip="{{ ruleColor?.errValue3 }}"
                                    formControlName="chkTitrationSleepStudyDoctorSigned">
                                    Doctor Signed
                                  </mat-checkbox>
                                </div>
                                <div class="col-6">
                                  <br />
                                  <mat-checkbox formControlName="chkisCPAPReturned">
                                    CPAP Returned
                                  </mat-checkbox>
                                </div>
                              </div>
                              <div class="row m-0 p-0">
                                <div class="col-6">
                                  <br />
                                  <mat-checkbox [ngClass]="{
                                      warning: ruleColor?.erStatus41
                                    }" matTooltip="{{ ruleColor?.errValue41 }}" formControlName="chkTSSBaselineFile">
                                    Base line File Available
                                  </mat-checkbox>
                                </div>
                                <mat-form-field class="col-6">
                                  <mat-label [ngClass]="{
                                      warning: ruleColor?.erStatus49
                                    }">Obstructive Apnea
                                  </mat-label>
                                  <input autocomplete="new-txtObstructiveApnea" type="text" maxlength="5" matTooltip="{{ ruleColor?.errValue49 }}"
                                    (keypress)="keyPressNumbersDecimal($event)" formControlName="txtObstructiveApnea"
                                    matInput />
                                </mat-form-field>
                              </div>
                              <div class="col-md-12 text-right">
                                <button type="button" class="buttonColor btn-sm btn-circle" (click)="openIcd10Modal()">
                                  <i class="fa fa-plus" matTooltip="Click to Add DiagnosisCodes-10"></i>
                                </button>
                              </div>
                              <mat-form-field class="col-12">
                                <mat-label>Diagnosis Code-ICD-10</mat-label>
                                <mat-select multiple formControlName="txtdiagnosisCode10Id" #singleSelect>
                                  <mat-option *ngFor="let diagnosis of drpDiagnosisICD10" [value]="
                                      diagnosis.diagnosisCode10ShortCodeId
                                    ">
                                    {{ diagnosis.diagnosisCodeTypeData }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div *ngIf="
                                PreviewExtractionForm?.get('drpDXType')
                                  ?.value !== 'CSA'
                              "></div>
                          </div>
                        </div>
                      </div>
                      <i *ngIf="sleep2nd" [ngClass]="{ hide: sleepHide == true }" class="fa fa-plus customThemeClass"
                        matTooltip="Click To Add Sleep Study Report" style="
                          cursor: pointer;
                          margin-left: 362px;
                          font-size: x-large;
                        " (click)="addSleepAccordion()"></i>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel  *ngFor="
                      let index of lstIndex | filter: { documentType: 'RX' }
                    " id="RX" (opened)="currentlyOpenAcc('RX')" [expanded]="false">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="customThemeClass mt-3">
                        <ng-container>
                          <b>RX</b>

                          <i *ngIf="prescriptionPendingValidationCount === 0" class="fa fa-check-circle-o ml-5"></i>
                          <span *ngIf="prescriptionPendingValidationCount !== 0" [ngClass]="{
                              ' deleteBtnColor ml-5':
                                prescriptionPendingValidationCount !== 0
                            }" matBadge="{{ prescriptionPendingValidationCount }}" matBadgeSize="small"></span>
                        </ng-container>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                      <div *ngFor="let ticket of h.controls; let i = index" class="list-group list-group-flush">
                        <div class="list-group-item">
                          <h4 class="card-title">
                            <b>Prescription {{ i + 1 }}</b>
                          </h4>
                          <div [formGroup]="ticket" class="form-row">
                            <mat-form-field class="col-12">
                              <mat-label> Patient Name </mat-label>
                              <input autocomplete="new-txtPatientName2" matInput maxlength="40" formControlName="txtPatientName2" type="text" />
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtPatientName2')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtPatientName2')
                                    ?.errors?.sAlphabets
                                ">
                                Enter only Alphabets!
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label> Birth Date </mat-label>
                              <input autocomplete="new-txtDOB2" [max]="txtDOB2" formControlName="txtDOB2" matInput maxlength="40" type="text"
                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDOB2" [min]="txtDOB2" />
                              <mat-datepicker-toggle matSuffix [for]="txtDOB2">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtDOB2> </mat-datepicker>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtDOB2')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtDOB2')?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-md-6">
                              <mat-label [ngClass]="{
                                  warning:
                                    ruleColor?.erStatus12 ||
                                    ruleColor?.erStatus19 ||
                                    ruleColor?.erStatus28 ||
                                    ruleColor?.erStatus36
                                }">
                                Prescription Date
                              </mat-label>
                              <input autocomplete="new-txtPrescriptionDate" formControlName="txtPrescriptionDate" matTooltip="{{
                                  ruleColor?.errValue12 ||
                                    ruleColor?.errValue19 ||
                                    ruleColor?.errValue28 ||
                                    ruleColor?.errValue36
                                }}" matInput maxlength="40" type="text" placeholder="(MM/DD/YYYY)"
                                [matDatepicker]="txtPrescriptionDate" />
                              <mat-datepicker-toggle matSuffix [for]="txtPrescriptionDate">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtPrescriptionDate>
                              </mat-datepicker>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get(
                                    'txtPrescriptionDate'
                                  )?.touched &&
                                  PreviewExtractionForm?.get(
                                    'txtPrescriptionDate'
                                  )?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>

                            <div class="col-md-12 text-right">
                              <button type="button" class="buttonColor btn-sm btn-circle" (click)="openDoctorModal()">
                                <i class="fa fa-plus" matTooltip="Click to Add Doctor"></i>
                              </button>
                            </div>
                            <mat-form-field class="col-12">
                              <mat-label>Doctor Name</mat-label>
                              <mat-select formControlName="txtDoctor" (valueChange)="
                                  doctorValueChange('p2p', doctorSelect)
                                " #doctorSelect>
                                <mat-option *ngFor="let doctor of drpDoctor" [value]="doctor.name">
                                  {{ doctor.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field class="col-6">
                              <mat-label>Machine Details</mat-label>
                              <mat-select formControlName="drpMachineDetails" maxlength="40">
                                <mat-option *ngFor="let machine of drpMachines" [value]="machine.machineType">
                                  {{ machine.machineType }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label> Pressure Level </mat-label>
                              <input autocomplete="new-txtPressureLevel" matInput maxlength="40" formControlName="txtPressureLevel"
                                (keypress)="numberOnly($event)" pattern="^\d{0,10}" type="text" />
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label> Length of need </mat-label>
                              <input autocomplete="new-txtLengthOfNeed" matInput maxlength="40" formControlName="txtLengthOfNeed" type="text" />
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label>NPI Number</mat-label>
                              <input autocomplete="new-txtNpiNumber" maxlength="13" mask="000-000-0000" formControlName="txtNpiNumber" matInput
                                type="text" />
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtNpiNumber')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtNpiNumber')
                                    ?.errors?.number
                                ">
                                Enter only Numbers!
                              </mat-error>
                            </mat-form-field>
                            <div class="col-6">
                              <br />
                              <mat-checkbox formControlName="chkDoctorSigned">Is Doctor Signed
                              </mat-checkbox>
                            </div>
                            <mat-form-field class="col-6">
                              <mat-label> Doctor Signed Date </mat-label>
                              <input autocomplete="new-txtSignedDate" [max]="txtSignedDate" formControlName="txtSignedDate" matInput maxlength="40"
                                type="text" placeholder="(MM/DD/YYYY)" [matDatepicker]="txtSignedDate"
                                [min]="txtSignedDate" />
                              <mat-datepicker-toggle matSuffix [for]="txtSignedDate">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtSignedDate> </mat-datepicker>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtSignedDate')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtSignedDate')
                                    ?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>

                            <mat-form-field class="col-12">
                              <mat-label>Diagnosis Code-ICD-10</mat-label>
                              <mat-select multiple formControlName="txtdiagnosisCode10Id1" #singleSelect>
                                <mat-option *ngFor="let diagnosis of drpDiagnosisICD10"
                                  [value]="diagnosis.diagnosisCode10ShortCodeId">
                                  {{ diagnosis.diagnosisCodeTypeData }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <i *ngIf="prescription2nd" [ngClass]="{ hide: presHide == true }"
                        class="fa fa-plus customThemeClass" matTooltip="Click To Add Prescription" style="
                          cursor: pointer;
                          margin-left: 362px;
                          font-size: x-large;
                        " (click)="addPrescriptionAccordion()"></i>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel  *ngFor="
                      let index of lstIndex
                        | filter: { documentType: 'COMPLIANCE REPORT' }
                    " id="COMPLIANCE REPORT" (opened)="currentlyOpenAcc('COMPLIANCE REPORT')" [expanded]="false"
                    class="collapsed">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="customThemeClass mt-3">
                        <ng-container>
                          <b>Compliance report</b>

                          <i *ngIf="compliancePendingValidationCount === 0" class="fa fa-check-circle-o ml-5"></i>
                          <span *ngIf="compliancePendingValidationCount !== 0" [ngClass]="{
                              ' deleteBtnColor ml-5':
                                compliancePendingValidationCount !== 0
                            }" matBadge="{{ compliancePendingValidationCount }}" matBadgeSize="small"></span>
                        </ng-container>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                      <div *ngFor="let ticket of j.controls; let i = index" class="list-group list-group-flush">
                        <div class="list-group-item">
                          <h4 class="card-title">
                            <b>Compliance report {{ i + 1 }}</b>
                          </h4>
                          <div [formGroup]="ticket" class="form-row">
                            <mat-form-field class="col-6">
                              <mat-label> Patient Name </mat-label>
                              <input autocomplete="new-txtPatientName3" matInput maxlength="40" formControlName="txtPatientName3" type="text" />
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtPatientName3')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtPatientName3')
                                    ?.errors?.sAlphabets
                                ">
                                Enter only Alphabets!
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label> Birth Date </mat-label>
                              <input autocomplete="new-txtDOB3" formControlName="txtDOB3" matInput maxlength="40" type="text"
                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtDOB3" />
                              <mat-datepicker-toggle matSuffix [for]="txtDOB3">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtDOB3> </mat-datepicker>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtDOB3')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtDOB3')?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label> Study Start date </mat-label>
                              <input autocomplete="new-txtStudyStartDate" formControlName="txtStudyStartDate" matInput maxlength="40" type="text"
                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtStudyStartDate" />
                              <mat-datepicker-toggle matSuffix [for]="txtStudyStartDate">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtStudyStartDate>
                              </mat-datepicker>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get(
                                    'txtStudyStartDate'
                                  )?.touched &&
                                  PreviewExtractionForm?.get(
                                    'txtStudyStartDate'
                                  )?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label>Compliance percentage</mat-label>
                              <input autocomplete="new-txtCompliancePercentage" maxlength="40" formControlName="txtCompliancePercentage" matInput type="text"
                                pattern="[a-zA-Z0-9]*" />
                            </mat-form-field>
                            <mat-form-field class="col-12">
                              <mat-label> Study End date </mat-label>
                              <input autocomplete="new-txtStudyEndDate" formControlName="txtStudyEndDate" matInput maxlength="40" type="text"
                                placeholder="(MM/DD/YYYY)" [matDatepicker]="txtStudyEndDate" />
                              <mat-datepicker-toggle matSuffix [for]="txtStudyEndDate">
                              </mat-datepicker-toggle>
                              <mat-datepicker #txtStudyEndDate>
                              </mat-datepicker>
                              <mat-error *ngIf="
                                  PreviewExtractionForm?.get('txtStudyEndDate')
                                    ?.touched &&
                                  PreviewExtractionForm?.get('txtStudyEndDate')
                                    ?.errors
                                ">
                                Enter Valid date
                              </mat-error>
                            </mat-form-field>
                            <div class="col-12 mt-2">
                              <label for="rbnComplianceMet">
                                Compliance Met
                              </label>
                              <mat-radio-group id="rbnComplianceMet" formControlName="rbnComplianceMet"
                                class="col-sm-7 radio mt-2 mr-2 txt-left">
                                <mat-radio-button class="mr-2" value="1">Yes</mat-radio-button>
                                <mat-radio-button class="mr-2" value="0">No</mat-radio-button>
                              </mat-radio-group>
                            </div>
                            <mat-form-field class="col-6">
                              <mat-label> Usage days</mat-label>
                              <input autocomplete="new-txtUsagedays" maxlength="40" formControlName="txtUsagedays" matInput type="text"
                                pattern="[a-zA-Z0-9]*" />
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label>out of</mat-label>
                              <input autocomplete="new-txtUsagedaysOutOf" maxlength="40" formControlName="txtUsagedaysOutOf" matInput type="text"
                                pattern="[a-zA-Z0-9]*" />
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label>Greater than 4 hours</mat-label>
                              <input autocomplete="new-txtGreater4Hours" maxlength="40" formControlName="txtGreater4Hours" matInput type="text"
                                pattern="[a-zA-Z0-9]*" />
                            </mat-form-field>
                            <mat-form-field class="col-6">
                              <mat-label>Less than 4 hours</mat-label>
                              <input autocomplete="new-txtLess4hours" maxlength="40" formControlName="txtLess4hours" matInput type="text"
                                pattern="[a-zA-Z0-9]*" />
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <i [ngClass]="{ hide: complHide == true }" *ngIf="complience2nd"
                        class="fa fa-plus customThemeClass" matTooltip="Click To Add Complience Report" style="
                          cursor: pointer;
                          margin-left: 362px;
                          font-size: x-large;
                        " (click)="addComplienceAccordion()"></i>
                    </div>
                  </mat-expansion-panel>

                  <mat-expansion-panel *ngFor="
                      let index of lstIndex
                        | filter: { documentType: 'INSURANCE VERIFICATION' }
                    " id="INSURANCE VERIFICATION" (opened)="currentlyOpenAcc('INSURANCE VERIFICATION')"
                    (closed)="isInsuranceVerification = false" [expanded]="isInsuranceVerification">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="customThemeClass mt-3">
                        <ng-container>
                          <b>Insurance </b>
                          <i *ngIf="verificationPendingValidationCount === 0" class="fa fa-check-circle-o ml-5"></i>
                          <span *ngIf="verificationPendingValidationCount !== 0" [ngClass]="{
                              ' deleteBtnColor ml-5':
                                verificationPendingValidationCount !== 0
                            }" matBadge="{{ verificationPendingValidationCount }}" matBadgeSize="small"></span>
                        </ng-container>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row">
                      <h4 class="ml-2 col-12 card-title"><b>Insurance </b></h4>
                      <mat-form-field class="col-6">
                        <mat-label>Payor Level<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="drpPayorLevel" (selectionChange)="onPayorsChanges($event)">
                          <mat-option *ngFor="let payor of payorLevel" [value]="payor.payorLevelName">
                            {{ payor.payorLevelName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="col-6">
                        <mat-label>Verify Type<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="drpVerifyType">
                          <mat-option *ngFor="let type of verifyType" [value]="type.type">
                            {{ type.type }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <!-- ZERMID -->
                      <mat-form-field class="col-xl-6">
                        <mat-label>Payer Id<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="drpPayorName" [(value)]="payor" #singleSelect>
                          <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                              formControlName="txtPayorFilter"></ngx-mat-select-search>
                          </mat-option>

                          <mat-option *ngFor="let payor of filterPayor | async" [value]="payor.name">
                            {{ payor.name }}
                          </mat-option>
                        </mat-select>

                        <button mat-button (click)="payor = undefined; $event.stopPropagation()" *ngIf="payor" matSuffix
                          (click)="clearPayor()" mat-icon-button >
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>

                      <mat-form-field class="col-6">
                        <mat-label>Provider <span class="asterisk">*</span></mat-label>
                        <mat-select maxlength="40" [(value)]="provider" formControlName="drpProvider">
                          <mat-option *ngFor="let providers of drpProviders" [value]="providers.shortCodeId">
                            {{ providers.name }}
                          </mat-option>
                        </mat-select>
                        <button mat-button (click)="provider = ''; $event.stopPropagation()" *ngIf="provider" matSuffix
                          (click)="clearProvider()" mat-icon-button >
                          <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="
                            PreviewExtractionForm?.get('drpProvider')
                              ?.touched &&
                            PreviewExtractionForm?.get('drpProvider')?.errors
                              ?.required
                          ">
                          Provider is a required field!
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field class="col-6">
                        <mat-label>
                          Member Id <span class="asterisk">*</span>
                        </mat-label>
                        <input autocomplete="new-txtMemberId" matInput formControlName="txtMemberId" maxlength="40" type="text" />
                      </mat-form-field>
                      <!-- PVERIFY -->

                      <mat-form-field class="col-xl-6">
                        <mat-label>Insurance<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="drpInsurance" [(value)]="insurance" #singleSelect>
                          <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No Matches found"
                              formControlName="txtInsFilter"></ngx-mat-select-search>
                          </mat-option>

                          <mat-option *ngFor="let insurance of filterIns | async" [value]="insurance.name">
                            {{ insurance.name }}
                          </mat-option>
                        </mat-select>

                        <button mat-button (click)="
                            insurance = undefined; $event.stopPropagation()
                          " *ngIf="insurance" matSuffix (click)="clearInsurance()" mat-icon-button >
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                      <div class="col-7 float-left">
                        <button [disabled]="
                            saveButtonHide ||
                            !PreviewExtractionForm?.get('drpPayorLevel')
                              ?.value ||
                              !PreviewExtractionForm?.get('drpPayorName')
                                ?.value ||
                              !PreviewExtractionForm?.get('txtMemberId')
                                ?.value ||
                              !PreviewExtractionForm?.get('drpInsurance')
                                ?.value ||
                              !PreviewExtractionForm?.get('drpProvider')
                                ?.value ||
                              !PreviewExtractionForm?.get('drpVerifyType')
                                ?.value
                          " mat-button class="buttonColor mr-2 mt-2" (click)="vPatient()">
                          Verify Patient
                          <mat-icon *ngIf="isShowSpinner">
                            <mat-spinner class="spinner-border spinner-border-sm" diameter="20">
                            </mat-spinner>
                          </mat-icon>
                        </button>
                      </div>

                      <div class="col-5 float-right">
                        <button mat-button class="buttonColor mr-2 mt-2" (click)="openEligibilityHistoryModal()">
                          Eligibility History
                        </button>
                      </div>
                    </div>
                  </mat-expansion-panel>

                  <mat-expansion-panel *ngFor="
                      let index of lstIndex
                        | filter: { documentType: 'AUTHORIZATION' }
                    " id="AUTHORIZATION" (opened)="currentlyOpenAcc('AUTHORIZATION')"
                    [expanded]="false">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="customThemeClass mt-3">
                        <ng-container>
                          <b>Authorization</b>
                          <i *ngIf="authorizationPendingValidationCount === 0" class="fa fa-check-circle-o ml-5"></i>
                          <span *ngIf="authorizationPendingValidationCount !== 0" [ngClass]="{
                              ' deleteBtnColor ml-5':
                                authorizationPendingValidationCount !== 0
                            }" matBadge="{{ authorizationPendingValidationCount }}" matBadgeSize="small"></span>
                        </ng-container>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                      <h4 class="ml-2 col-12 card-title">
                        <b>Authorizaion </b>
                      </h4>
                      <mat-form-field class="col-6">
                        <mat-label>Plan</mat-label>
                        <mat-select formControlName="drpAuthInsurance" (selectionChange)="onAuthPayorsChanges($event)">
                          <mat-option *ngFor="let payor of payorLevel" [value]="payor.payorLevelName">
                            {{ payor.payorLevelName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="col-6">
                        <mat-label> Atuh Id </mat-label>
                        <input autocomplete="new-txtAuthId" matInput maxlength="40" formControlName="txtAuthId" type="text" />
                      </mat-form-field>
                      <mat-form-field class="col-6">
                        <mat-label> Atuh Visits </mat-label>
                        <mat-select formControlName="drpAuthVisit">
                          <mat-option *ngFor="let visit of drpAuthVisit" [value]="visit.authVisitType">
                            {{ visit.authVisitType }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="col-6">
                        <mat-label>Auth Amount(s)</mat-label>
                        <input autocomplete="new-drpAuthAmount" matInput maxlength="40" formControlName="drpAuthAmount" type="text" />
                      </mat-form-field>

                      <mat-form-field class="col-6">
                        <mat-label>Start Date</mat-label>
                        <input autocomplete="new-txtStartDate" formControlName="txtStartDate" placeholder="(MM/DD/YYYY)" matInput maxlength="40"
                          [formControl]="
                            PreviewExtractionForm.controls.txtStartDate
                          " [ngxMatDatetimePicker]="txtStartDate" />
                        <mat-datepicker-toggle matSuffix [for]="txtStartDate"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #txtStartDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                          [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'"
                          [enableMeridian]="true">
                          <ng-template>
                            <span>OK</span>
                          </ng-template>
                        </ngx-mat-datetime-picker>
                      </mat-form-field>

                      <mat-form-field class="col-6">
                        <mat-label>End Date</mat-label>
                        <input autocomplete="new-txtEndDate" formControlName="txtEndDate" placeholder="(MM/DD/YYYY)" matInput maxlength="40"
                          [formControl]="
                            PreviewExtractionForm.controls.txtEndDate
                          " [min]="
                            PreviewExtractionForm.controls.txtStartDate.value
                          " [ngxMatDatetimePicker]="txtEndDate" />
                        <mat-datepicker-toggle matSuffix [for]="txtEndDate"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #txtEndDate [showSpinners]="true" [showSeconds]="true" [stepHour]="1"
                          [stepMinute]="1" [stepSecond]="1" [touchUi]="false" [color]="'primary'"
                          [enableMeridian]="true">
                          <ng-template>
                            <span>OK</span>
                          </ng-template>
                        </ngx-mat-datetime-picker>
                      </mat-form-field>

                      <mat-form-field class="col-6">
                        <mat-label>Note</mat-label>
                        <input autocomplete="new-txtNote" matInput maxlength="40" formControlName="txtNote" type="text" />
                      </mat-form-field>

                      <mat-form-field class="col-6">
                        <mat-label> Category </mat-label>
                        <mat-select formControlName="drpCategory">
                          <mat-option *ngFor="let category of drpCategory" [value]="category.authCategoryType">
                            {{ category.authCategoryType }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-checkbox class="col-6 pt-3" formControlName="chkCompleted">Completed</mat-checkbox>

                      <mat-form-field class="col-6">
                        <mat-label>Completed By</mat-label>
                        <input autocomplete="new-txtCompletedBy" matInput maxlength="40" formControlName="txtCompletedBy" type="text" />
                      </mat-form-field>
                      <mat-form-field class="col-6">
                        <mat-label> Units</mat-label>
                        <input autocomplete="new-txtUnits" matInput formControlName="txtUnits" maxlength="15" type="text" />
                      </mat-form-field>
                      <mat-form-field class="col-6">
                        <mat-label> Used </mat-label>
                        <input autocomplete="new-txtUsed" matInput formControlName="txtUsed" maxlength="15" type="text" />
                      </mat-form-field>
                      <mat-form-field class="col-6">
                        <mat-label>Sale/Rent</mat-label>
                        <mat-select maxlength="40" [(value)]="sale" formControlName="txtSaleOrRent">
                          <mat-option *ngFor="let data of drpSaleOrRents" [value]="data.saleOrRent">
                            {{ data.saleOrRent }}
                          </mat-option>
                        </mat-select>
                        <button mat-button (click)="sale = undefined; $event.stopPropagation()" *ngIf="sale" matSuffix
                          (click)="clearSale()" mat-icon-button >
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                      <mat-form-field class="col-6">
                        <mat-label>Codes</mat-label>
                        <mat-select maxlength="40" [(value)]="itemCodes" formControlName="txtItemCodes">
                          <mat-option *ngFor="let data of drpItemCdoes" [value]="data">
                            {{ data }}
                          </mat-option>
                        </mat-select>
                        <button mat-button (click)="
                            itemCodes = undefined; $event.stopPropagation()
                          " *ngIf="itemCodes" matSuffix (click)="clearItemCodes()" mat-icon-button >
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                      <mat-checkbox class="col-6 pt-3" formControlName="txtExpired">Expired</mat-checkbox>
                      <mat-checkbox class="col-3 pt-3" formControlName="chkFollowUp">FollowUp</mat-checkbox>
                      <mat-label class="col-12 pt-3">Auth Status :
                        <span [ngClass]="{
                            'make-red': expiryStatus === 'Expired',
                            'make-black': expiryStatus === 'Not Mentioned',
                            'make-green': expiryStatus === 'Active'
                          }">
                          {{ expiryStatus }}</span>
                      </mat-label>
                    </div>
                  </mat-expansion-panel>

                  <mat-expansion-panel *ngFor="
                      let index of lstIndex
                        | filter: { documentType: 'SALEORDER' }
                    " id="SALEORDER" (opened)="currentlyOpenAcc('SALEORDER')"
                    [expanded]="false">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="customThemeClass mt-3">
                        <ng-container>
                          <b>Sale Order</b>
                          <i *ngIf="authorizationPendingValidationCount === 0" class="fa fa-check-circle-o ml-5"></i>
                          <span *ngIf="authorizationPendingValidationCount !== 0" [ngClass]="{
                              ' deleteBtnColor ml-5':
                                authorizationPendingValidationCount !== 0
                            }" matBadge="{{ authorizationPendingValidationCount }}" matBadgeSize="small"></span>
                        </ng-container>
                      </mat-panel-title>
                    </mat-expansion-panel-header>


                    <div class="row" style="margin-top: -18px">
                      <div class="col-4"></div>
                      <button [ngClass]="{
                          clrcheck:
                            insuranceAfterSave?.verifications
                              ?.primaryInsuranceVerifies !== null
                        }" mat-button class="col-4 buttonColor lightSeaGreen-dot"
                        (click)="openInsuranceVerification()">
                        Verification Check
                      </button>
                      <button [ngClass]="{
                          clrcheck:
                            insuranceAfterSave?.orders &&
                            insuranceAfterSave?.orders?.items &&
                            insuranceAfterSave?.orders?.items?.length !== 0
                        }" (click)="openAddItemModal()" mat-button class="col-3 buttonColor lightSeaGreen-dot">
                        Item Add
                      </button>
                      <!-- for change the values -->
                      <mat-form-field class="col-6" *ngIf="fieldHide">
                        <mat-label>Place Of Service </mat-label>
                        <mat-select maxlength="40" formControlName="drpGeneralPlaceOfService"
                          [(value)]="generalPlaceOfService">
                          <mat-option *ngFor="let pos of drpPlace" [value]="pos.placeOfServiceCode">
                            {{ pos.placeOfServiceType }}
                          </mat-option>
                        </mat-select>
                        <button mat-button (click)="clearGeneralPlaceOfService()" (click)="
                            generalPlaceOfService = ''; $event.stopPropagation()
                          " *ngIf="generalPlaceOfService" matSuffix mat-icon-button >
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                      <mat-checkbox *ngIf="fieldHide" class="col-6 mt-3" style="padding-left: initial"
                        (change)="isCompeltedConfirmation()" formControlName="chkWorkProgressCompleted">
                        <b>Completed</b>
                      </mat-checkbox>

                      <!-- For readonly -->
                      <mat-form-field class="col-6 readonly" *ngIf="!fieldHide">
                        <mat-label>Place Of Service </mat-label>
                        <mat-select maxlength="40" formControlName="drpGeneralPlaceOfService"
                          [(value)]="generalPlaceOfService">
                          <mat-option *ngFor="let pos of drpPlace" [value]="pos.placeOfServiceCode">
                            {{ pos.placeOfServiceType }}
                          </mat-option>
                        </mat-select>
                        <button mat-button (click)="clearGeneralPlaceOfService()" (click)="
                            generalPlaceOfService = ''; $event.stopPropagation()
                          " *ngIf="generalPlaceOfService" matSuffix mat-icon-button >
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                      <mat-checkbox *ngIf="!fieldHide" class="col-6 mt-3 readonly" style="padding-left: initial"
                        (change)="isCompeltedConfirmation()" formControlName="chkWorkProgressCompleted">
                        <b>Completed</b>
                      </mat-checkbox>
                      <!-- <button mat-button class="buttonColor mr-2 mt-2" (click)="openAddItemModal()">
                          Add Item
                        </button> -->
                      <!-- </div> -->
                    </div>
                  </mat-expansion-panel>

                  <button mat-button class="float-right buttonColor mr-2 mt-2" (click)="onSubmit()">
                    Check Rules
                  </button>
                </mat-accordion>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Notification Design -->
<div *ngIf="selectedRules && selectedRules !== []">
  <div *ngIf="hideFullNotificataion" class="notifications color">
    <ng-container *ngFor="let srule of selectedRules">
      <ng-container *ngFor="let rule of rulesValues; first as isFirst">
        <ng-container class="text-color" *ngIf="srule === rule?.id">
          <div class="title">
            <span>
              <div class="row titles">
                <div class="col-10 mt-2" *ngIf="isFirst">
                  <b>
                    <h3 class="blinking text-color title">
                      {{ rule?.dxType }}
                      <span *ngIf="rule?.setupType">
                        - {{ rule?.setupType }}
                      </span>
                    </h3>
                  </b>
                </div>
                <div *ngIf="isFirst" class="col-2">
                  <button mat-icon-button (click)="closeAllRule()">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </div>
              </div>
              <div class="ml-2 mr-2 message text-color">
                {{ rule?.shortCodeId }} - {{ rule?.ruleName }}
              </div>
            </span>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- <div class="notifications">
  <ng-container *ngFor="let srule of selectedRules">
    <ng-container *ngFor="let rule of rulesValues">
      <ng-container *ngIf="srule===rule?.id">
        <div class="notification" [ngClass]="{'success':true}">
          <ng-container *ngTemplateOutlet="ruleTpl;context:{rule:rule}"></ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<ng-template #ruleTpl let-rule="rule">
  <div class="row title">
    <div class="col-10 mt-2">
      {{ rule?.dxType }}
      <span *ngIf="rule?.setupType"> - {{ rule?.setupType }} </span>
    </div>
    <button mat-icon-button class="col-2" (click)="closeRule(rule)">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
  <div class="message">
    <div mat-line class="m-0">
      <span class="float-left">
        <span>
          {{ rule?.ruleName }}
        </span>
      </span>
    </div>
    <br/>
  </div>
</ng-template>  -->
