<h2 matDialogTitle class="pl-2 customThemeClass"> Task List For Doc ID:
  <span style="text-align: right" class="headStyle text-danger">{{selDefaultDocId}}
  </span>
</h2>


  <mat-dialog-content>
    <div class="example-container mb-2 mat-elevation-z4">
      <table mat-table [dataSource]="dataSource" matTableExporter matSort #exporter="matTableExporter"
        class="mat-elevation-z8">
        <!-- WIP ID-->
        <ng-container matColumnDef="wipId">
          <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
            WIP Id
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.wipId }}
          </td>
        </ng-container>
        <!--Assigned To Name-->
        <ng-container matColumnDef="assignedToName">
          <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
            Assigned To
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.assignedToName }}
          </td>
        </ng-container>
        <!-- Assigned By-->
        <ng-container matColumnDef="assignedByName">
          <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
            <!-- <mat-checkbox #isAssinged></mat-checkbox> -->
            Assigned By
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.assignedByName }}
          </td>
        </ng-container>
        <!-- Assigned Date -->
        <ng-container matColumnDef="assignedDate">
          <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
            Assigned Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.assignedDate | date : "MM/dd/yyyy h:mm a" : "en_US" }}
          </td>
        </ng-container>
        <!--WIP Status-->
        <ng-container matColumnDef="wipStatus">
          <th class="header" mat-header-cell mat-sort-header *matHeaderCellDef>
            WIP Status
          </th>
          <td mat-cell *matCellDef="let element">
            <div [ngSwitch]="element?.wipStatus">
              <div *ngSwitchCase="'yetToStart'">Yet To Start</div>
              <div *ngSwitchCase="'hold'">Hold</div>
              <div *ngSwitchCase="'inProcess'">In Process</div>
              <div *ngSwitchCase="'completed'">Completed</div>
              <div *ngSwitchCase="'reAssigned'">Re-Assigned</div>
              <div *ngSwitchDefault></div>
            </div>
          </td>
        </ng-container>
        <!--Options-->

        <ng-container matColumnDef="noRecords">
          <td class="text-align-center" mat-footer-cell *matFooterCellDef colspan="6">
            <div *ngIf="!isLoading">
              {{ "No Records Found" }}
            </div>
            <div [hidden]="!isLoading">
              <mat-spinner [diameter]="220"></mat-spinner>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="arrWipDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: arrWipDisplayedColumns"></tr>
      </table>
      <div *ngIf="
            !(dataSource && dataSource?.data && dataSource?.data?.length !== 0)
          " class="text-align-center">
        <div class="message py-3" *ngIf="!isLoading">
          {{ "No Records Found" }}
        </div>
        <div class="py-3" [hidden]="!isLoading">
          <mat-spinner [diameter]="220"></mat-spinner>
        </div>
      </div>
      <mat-paginator #wipPaginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="buttonCancelColor" mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
